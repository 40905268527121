import * as React from "react";
const IconCircleDashed = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M15.3 9.5c-.3-.4-1-.5-1.4-.2-3.4 2.7-6.2 6.2-8 10.1-.2.5 0 1.1.5 1.3.1.1.3.1.4.1q.6 0 .9-.6c1.7-3.6 4.3-6.9 7.5-9.4.3-.2.4-.8.1-1.3M26 58.3c-3.9-.9-7.7-2.7-10.8-5.2-.4-.3-1.1-.3-1.4.2s-.3 1.1.2 1.4c3.4 2.7 7.4 4.6 11.6 5.6h.2c.5 0 .9-.3 1-.8s-.2-1-.8-1.2M5 32.1c0-.6-.4-1-1-1s-1 .4-1 1c0 4.4 1 8.6 2.9 12.6q.3.6.9.6c.1 0 .3 0 .4-.1.5-.2.7-.8.5-1.3C5.9 40.1 5 36.2 5 32.1M48.8 53.1c-3.2 2.5-6.9 4.3-10.8 5.2-.5.1-.9.7-.8 1.2s.5.8 1 .8h.2c4.2-1 8.2-2.9 11.6-5.6.4-.3.5-1 .2-1.4-.3-.5-.9-.5-1.4-.2M60 30.9c-.6 0-1 .4-1 1v.1q0 6.15-2.7 11.7c-.2.5 0 1.1.5 1.3.1.1.3.1.4.1q.6 0 .9-.6C60 40.6 61 36.4 61 32v-.1c0-.6-.4-1-1-1M39.1 4.9c.1-.5-.2-1.1-.8-1.2-4.2-.9-8.7-.9-12.9 0-.5.1-.9.7-.8 1.2s.5.8 1 .8h.2c3.9-.9 8.1-.9 12 0 .7.1 1.2-.3 1.3-.8M57.2 20.8c.1 0 .3 0 .4-.1.5-.2.7-.8.5-1.3-1.9-4-4.7-7.4-8.1-10.1-.4-.3-1.1-.3-1.4.2-.3.4-.3 1.1.2 1.4 3.2 2.5 5.8 5.7 7.5 9.4.2.2.5.5.9.5" />
  </svg>
);
export default IconCircleDashed;
