import React, { useState, useEffect } from 'react';
import { BringzzAccordion, BringzzButton, BringzzInput, BringzzPromoBanner, BringzzStarRating, BringzzText } from "@bringzz/components";
import { useNavigation } from 'context/NavigationContext';
import {
    BringzzPageContainer,
    BringzzIcon,
    BringzzBasketProduct,
    BringzzProgressBar
} from "@bringzz/components";
import BikeImage from 'assets/images/Bike.png'
import RocketImage from 'assets/images/Rocket.png'
import BringzzFeedback from 'components/BringzzFeedback';

const MatchmakingCollectFinishedPage = () => {
    const { backPress, navigate } = useNavigation();
    const [activeStep, setActiveStep] = useState(8)

    return (
        <BringzzPageContainer
            className="bg-white"
            header={{
                left: (
                    <BringzzIcon
                        size='22'
                        icon='IconClose'
                        folder='LineIcons'
                        className='cursor-pointer'
                        onClick={() => navigate('/home')}
                    />
                ),
            }}
            authenticated={true}
            footer={
                <div className='p-6 space-y-4 text-center'>
                    <BringzzButton size='lg' className='bg-magic-lilac w-full flex justify-center' onClick={() => navigate('/home')}>Continue</BringzzButton>
                </div>
            }
        >
            <div className='space-y-4 flex flex-col items-center justify-center pt-44'>
                <BringzzIcon folder='CustomIcons' icon='IconMountain' size='100' className='text-gray-400' />
                <BringzzText tag='h1' className='text-center'>Thank you!</BringzzText>
                <BringzzText tag='h4' className='w-64 text-center font-normal'>Odio lacus at proin mauris nisi adipiscing ultrices. Vestibulum in tincidunt volutpat posuere nulla in. Id viverra eu velit nullam tincidunt non.</BringzzText>
            </div>
        </BringzzPageContainer >
    );
};

export default MatchmakingCollectFinishedPage;
