import * as React from "react";
const IconPauseCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M17.5 7.3H7c-2.8 0-5 2.2-5 5v39.5c0 2.8 2.2 5 5 5h10.5c2.8 0 5-2.2 5-5V12.3c0-2.8-2.3-5-5-5m3 44.4c0 1.7-1.3 3-3 3H7c-1.7 0-3-1.3-3-3V12.3c0-1.7 1.3-3 3-3h10.5c1.7 0 3 1.3 3 3zM57 7.3H46.5c-2.8 0-5 2.2-5 5v39.5c0 2.8 2.2 5 5 5H57c2.8 0 5-2.2 5-5V12.3c0-2.8-2.2-5-5-5m3 44.4c0 1.7-1.3 3-3 3H46.5c-1.7 0-3-1.3-3-3V12.3c0-1.7 1.3-3 3-3H57c1.7 0 3 1.3 3 3z" />
  </svg>
);
export default IconPauseCopy;
