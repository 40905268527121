import * as React from "react";
const IconTriangleRuler = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.5 53.1 10.9 3.5C9.4 2 7.2 1.6 5.2 2.4S2 5.1 2 7.2v49.6C2 59.7 4.3 62 7.2 62h49.6c2.1 0 4-1.2 4.8-3.2s.4-4.2-1.1-5.7m-.8 4.9c-.4 1-1.4 2-2.9 2H7.2C5.4 60 4 58.6 4 56.8V7.2c0-1.6 1-2.6 2-2.9.3-.2.8-.3 1.2-.3.8 0 1.6.3 2.2.9L59 54.5c1.2 1.2 1.1 2.6.7 3.5" />
    <path d="M17.9 26.5c-.8-.8-1.9-1-2.9-.6s-1.6 1.4-1.6 2.4v19.1c0 1.4 1.2 2.6 2.6 2.6h19.1c1.1 0 2-.6 2.4-1.6s.2-2.1-.6-2.9zm17.8 21.2c0 .1-.2.4-.6.4H16c-.3 0-.6-.3-.6-.6V28.4c0-.4.3-.5.4-.6.1 0 .1-.1.3-.1.1 0 .3 0 .4.2l19 19.1c.3.3.2.6.2.7" />
  </svg>
);
export default IconTriangleRuler;
