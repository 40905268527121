import * as React from "react";
const IconAw = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 363 242"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 0h363v242H0z" />
    <path
      
      d="M57 96.9 14.7 78.2 57 59.6l18.6-42.2 18.6 42.2 42.3 18.6-42.3 18.7-18.6 42.2z"
    />
    <path
      
      d="m75.6 40.6 11.5 26.1 26.1 11.5-26.1 11.5-11.5 26.1-11.5-26.1L38 78.2l26.1-11.5z"
    />
    <path  d="M0 152.2h363v15.7H0zm0 31.4h363v15.7H0z" />
  </svg>
);
export default IconAw;
