import * as React from "react";
const IconFlatsAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.6 2H36.7c-7.2 0-13 5.8-13 13v3.8c-2.6-1.9-5.8-3-9.3-3h-4c-2.9 0-5.3 2.4-5.3 5.3v35.6c0 2.9 2.4 5.3 5.3 5.3h43.1c2.9 0 5.3-2.4 5.3-5.3V7.3c.1-2.9-2.3-5.3-5.2-5.3M24.8 60H10.4c-1.8 0-3.3-1.5-3.3-3.3V21.1c0-1.8 1.5-3.3 3.3-3.3h4c7.8 0 14.1 6.3 14.1 14.1V60zm32.1-3.3c0 1.8-1.5 3.3-3.3 3.3h-23V31.8c0-4.5-1.8-8.5-4.8-11.4V15c0-6 4.9-11 11-11h16.8c1.8 0 3.3 1.5 3.3 3.3z" />
    <path d="M46.3 15.7h-8.2c-.6 0-1 .4-1 1s.4 1 1 1h8.2c.6 0 1-.4 1-1s-.4-1-1-1M46.3 25.7h-8.2c-.6 0-1 .4-1 1s.4 1 1 1h8.2c.6 0 1-.4 1-1s-.4-1-1-1M46.3 35.7h-8.2c-.6 0-1 .4-1 1s.4 1 1 1h8.2c.6 0 1-.4 1-1s-.4-1-1-1M46.3 45.7h-8.2c-.6 0-1 .4-1 1s.4 1 1 1h8.2c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconFlatsAlt2;
