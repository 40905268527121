import * as React from "react";
const IconMw = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 342"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 0h512v114H0z" />
    <path  d="M0 114h512v114H0z" />
    <path  d="M0 228h512v114H0z" />
    <circle cx={256} cy={125} r={95}  />
  </svg>
);
export default IconMw;
