import * as React from "react";
const IconServiceAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 45.6h-5.1C55.4 33.2 45.4 23.2 33 22.7v-5.4c0-.6-.4-1-1-1s-1 .4-1 1v5.4c-12.4.5-22.4 10.5-22.9 22.9H3c-.6 0-1 .4-1 1s.4 1 1 1h58c.6 0 1-.4 1-1s-.4-1-1-1M32 24.7c11.8 0 21.4 9.3 21.9 20.9H10.1C10.6 34 20.2 24.7 32 24.7" />
  </svg>
);
export default IconServiceAlt;
