import * as React from "react";
const IconCelsiusAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
    <path d="M24.6 17.6c-2.9 0-5.3 2.4-5.3 5.3s2.4 5.3 5.3 5.3 5.3-2.4 5.3-5.3-2.4-5.3-5.3-5.3m0 8.6c-1.8 0-3.3-1.5-3.3-3.3s1.5-3.3 3.3-3.3 3.3 1.5 3.3 3.3-1.5 3.3-3.3 3.3M43.9 44.4c-1.3 1.2-3.1 1.9-4.9 1.9-4 0-7.3-3.3-7.3-7.3s3.3-7.3 7.3-7.3c1.4 0 2.8.4 4 1.2.5.3 1.1.2 1.4-.3s.2-1.1-.3-1.4c-1.5-1-3.2-1.5-5-1.5-5.1 0-9.3 4.2-9.3 9.3s4.2 9.3 9.3 9.3c2.3 0 4.5-.8 6.2-2.4.4-.4.4-1 .1-1.4-.4-.4-1.1-.4-1.5-.1" />
  </svg>
);
export default IconCelsiusAlt;
