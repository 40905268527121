import * as React from "react";
const IconTurn = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m59.2 27.8-23-23C35.1 3.7 33.6 3 32 3s-3.1.6-4.2 1.8l-23 23C3.7 28.9 3 30.4 3 32s.6 3.1 1.8 4.2l23 23C29 60.4 30.5 61 32 61s3.1-.6 4.2-1.8l23-23c2.3-2.3 2.3-6.1 0-8.4m-1.4 7-23 23c-1.6 1.6-4.1 1.6-5.7 0l-23-23C5.5 34.1 5 33.1 5 32s.4-2.1 1.2-2.8l23-23C29.9 5.5 30.9 5 32 5s2.1.4 2.8 1.2l23 23c1.6 1.5 1.6 4.1 0 5.6" />
    <path d="M33.3 23.1c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l6.5 6.5H23.3c-.6 0-1 .4-1 1v8.2c0 .6.4 1 1 1s1-.4 1-1V33h14.1l-6.5 6.5c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l8.2-8.2c.4-.4.4-1 0-1.4z" />
  </svg>
);
export default IconTurn;
