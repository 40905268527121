import * as React from "react";
const IconFlagAltCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m51.4 34.2-3.7-8.8 3.7-8.8c.1-.1.1-.3.1-.4 0-.6-.4-1-1-1H33v-4.3c0-.6-.4-1-1-1H14.5V3c0-.6-.4-1-1-1s-1 .4-1 1v58c0 .6.4 1 1 1s1-.4 1-1V30.4H31v3.2c0 1.2 1 2.1 2.1 2.1h17.3c.6 0 1-.4 1-1 .1-.2 0-.3 0-.5m-36.9-5.8V11.9H31v16.4H14.5zM33 33.5V17.2h16l-3.5 8.2 3.5 8.2H33.1s-.1 0-.1-.1" />
  </svg>
);
export default IconFlagAltCopy;
