import * as React from "react";
const IconWatermelon = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 65 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M41.9 26.7h.3c.5-.1.8-.7.7-1.2s-.7-.8-1.2-.7h-.1c-.5.1-.8.7-.7 1.2s.6.7 1 .7M29.3 28.8h.3c.5-.1.8-.7.7-1.2s-.7-.8-1.2-.7H29c-.5.1-.8.7-.7 1.2.1.4.5.7 1 .7M37.3 36.3h.3c.5-.1.8-.7.7-1.2s-.7-.8-1.2-.7H37c-.5.1-.8.7-.7 1.2.1.4.5.7 1 .7" />
    <path d="M60.8 15.3c-.2-.8-.6-1.4-1.3-1.9-.7-.4-1.5-.6-2.3-.3L11 25.6l-5.4 1.5c-.8.2-1.5.7-1.8 1.5-.4.7-.4 1.5-.2 2.3 2.3 6.8 7 12.6 13.3 16.2 4.6 2.7 9.7 4 14.8 4 2.6 0 5.2-.3 7.7-1 7.7-2.1 14.1-7 18.1-14 3.5-6.3 4.7-13.7 3.3-20.8M10.9 28c.1-.2.3-.4.6-.5l40.8-11c.3-.1.5 0 .7.1.1.1.3.2.4.5.9 5.2 0 10.5-2.6 15-3 5.2-7.8 8.9-13.5 10.4s-11.6.7-16.7-2.2c-4.5-2.6-7.9-6.8-9.7-11.7-.1-.2-.1-.5 0-.6m44.7 7c-3.7 6.5-9.7 11.1-16.8 13s-14.6.9-21-2.8C12 41.9 7.6 36.5 5.4 30.1c-.1-.2-.1-.5.1-.7.1-.2.3-.3.5-.4l2.8-.8c0 .4 0 .7.2 1.1 1.9 5.4 5.7 9.9 10.6 12.7 5.6 3.2 12.1 4.1 18.3 2.4s11.4-5.7 14.6-11.3c2.8-4.9 3.8-10.7 2.9-16.4-.1-.4-.2-.7-.4-1l2.8-.8c.3-.1.5 0 .7.1.1.1.3.3.4.5 1.2 6.8.1 13.7-3.3 19.5" />
    <path d="M21.3 31h.3c.5-.1.8-.7.7-1.2s-.7-.8-1.2-.7L21 29c-.5.1-.8.7-.7 1.2s.5.8 1 .8" />
  </svg>
);
export default IconWatermelon;
