import * as React from "react";
const IconBlisterPillsOvalX1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      d="M27.071 11.414a2 2 0 0 0-2.828 0l-2.829 2.829a2 2 0 0 0 2.829 2.828l2.828-2.828a2 2 0 0 0 0-2.829M18 25a1 1 0 1 0 0-2 1 1 0 0 0 0 2M23 24a1 1 0 1 1-2 0 1 1 0 0 1 2 0M26 25a1 1 0 1 0 0-2 1 1 0 0 0 0 2M31 24a1 1 0 1 1-2 0 1 1 0 0 1 2 0"
    />
    <path
      
      fillRule="evenodd"
      d="M12 8a4 4 0 0 1 4-4h16a4 4 0 0 1 4 4v32a4 4 0 0 1-4 4H16a4 4 0 0 1-4-4zm2 0a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v15a1 1 0 1 0 0 2v15a2 2 0 0 1-2 2H16a2 2 0 0 1-2-2V25a1 1 0 1 0 0-2z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconBlisterPillsOvalX1;
