import * as React from "react";
const IconCloudySun = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M39.4 13.6c1.2 0 2.3-1 2.3-2.3v-4c0-1.2-1-2.3-2.3-2.3s-2.3 1-2.3 2.3v4.1c0 1.2 1.1 2.2 2.3 2.2M55.3 11.8c-.9-.9-2.3-.9-3.2 0l-2.6 2.6c-.9.9-.9 2.3 0 3.2.4.4 1 .7 1.6.7s1.1-.2 1.6-.6l2.6-2.6c.9-1 .9-2.4 0-3.3M60 26.7h-4.1c-1.2 0-2.3 1-2.3 2.3s1 2.3 2.3 2.3H60c1.2 0 2.3-1 2.3-2.3s-1.1-2.3-2.3-2.3M25.3 17.9c.4.4 1 .6 1.6.6s1.2-.2 1.6-.7c.9-.9.9-2.3 0-3.2l-2.9-2.9c-.9-.9-2.3-.9-3.2 0s-.9 2.3 0 3.2zM49.4 28.2c0-6.1-5-11.1-11.1-11.1-4.8 0-9 3.2-10.5 7.6-.7-.1-1.4-.2-2.1-.2-7.4 0-13.5 5.4-14.2 12.4-5.5.9-9.7 5.5-9.7 11 0 6.1 5.1 11 11.4 11.1h21.5c8.2 0 14.9-6.4 14.9-14.2 0-3.2-1.2-6.3-3.3-8.9 1.9-2.1 3.1-4.8 3.1-7.7m-11.1-6.5c3.6 0 6.6 2.9 6.6 6.6 0 1.8-.7 3.4-1.9 4.6-1.6-1-3.5-1.7-5.4-2.1-1.4-2-3.3-3.6-5.4-4.7.8-2.6 3.3-4.4 6.1-4.4m-3.6 32.7H13.3c-3.9 0-7-2.9-7-6.6s3.1-6.6 7-6.6h.4c1.2 0 2.3-1 2.3-2.3v-.8c0-5.1 4.4-9.2 9.8-9.2 1.2 0 2.2.2 3.2.5 2.3.7 4.2 2.3 5.4 4.4.4.6 1 1 1.7 1.1 2.5.3 4.8 1.4 6.4 3.1q2.7 2.85 2.7 6.6c-.1 5.5-4.8 9.8-10.5 9.8" />
  </svg>
);
export default IconCloudySun;
