import * as React from "react";
const IconWifiSlash = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M21 28.9c-.2-.5-.8-.7-1.3-.5-3 1.4-5.7 3.3-8 5.6-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3c2.2-2.2 4.7-4 7.4-5.3.5-.1.7-.7.5-1.2M50.9 35.5c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4-4.8-4.8-11.2-7.8-18-8.3-.6 0-1 .4-1.1.9s.4 1 .9 1.1c6.4.4 12.3 3.2 16.8 7.7M30 38.4c-.1-.5-.6-.9-1.2-.8-3.4.6-6.5 2.3-8.9 4.7-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3c2.2-2.2 4.9-3.6 7.9-4.2.6 0 .9-.5.8-1.1M12.4 20c-.3-.5-.9-.6-1.4-.3-2.8 1.7-5.4 3.7-7.7 6.1-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3c2.2-2.2 4.7-4.2 7.4-5.8.4-.3.6-.9.3-1.4M60.7 25.7c-9.8-9.8-23.7-13.9-37.2-11-.5.1-.9.5-.9 1 0 .6.5 1 1 1h.2c12.9-2.8 26.1 1.1 35.5 10.4.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4M32 47.3c-1.4 0-2.8.6-3.8 1.6s-1.6 2.3-1.6 3.8c0 1.4.6 2.8 1.6 3.8s2.4 1.6 3.8 1.6 2.7-.5 3.8-1.6c1-1 1.6-2.3 1.6-3.8 0-1.4-.6-2.8-1.6-3.8s-2.4-1.6-3.8-1.6m2.3 7.7a3.32 3.32 0 0 1-4.7 0c-.6-.6-1-1.5-1-2.3s.3-1.7 1-2.3c.6-.6 1.5-1 2.3-1s1.7.3 2.3 1 1 1.5 1 2.3-.2 1.7-.9 2.3M43.9 43.9c.1 0 .1-.1.2-.1.4-.4.4-1 0-1.4-1.5-1.5-3.2-2.7-5.1-3.5L7.6 6.3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l45.4 47c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4z" />
  </svg>
);
export default IconWifiSlash;
