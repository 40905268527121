import * as React from "react";
const IconAlarm4 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M46.6 44c-.3-.5-.4-.9-.4-1.4V29.4c0-3.6-1.5-7-4.3-9.5-2.4-2.2-5.5-3.4-8.8-3.6V13c0-.6-.4-1-1-1s-1 .4-1 1v3.3h-.7c-7.1.8-12.4 6.5-12.4 13.3v12.9c-.1.6-.2 1-.4 1.3L16.2 46c-.3.5-.3 1.1 0 1.6s.8.7 1.4.7H31V51c0 .6.4 1 1 1s1-.4 1-1v-2.7h13.5c.6 0 1.1-.3 1.3-.7.3-.5.3-1.1 0-1.6zm-28.2 2.3.8-1.4q.45-.9.6-2.1V29.7c0-5.8 4.6-10.6 10.7-11.3 3.7-.4 7.4.7 10 3.1 2.4 2.1 3.7 5 3.7 8v13.2c0 .9.2 1.7.7 2.5l.8 1.2H18.4zM7.1 25.5c-.4-.4-1-.4-1.4 0C3.3 27.7 2 30.8 2 34.1s1.3 6.3 3.7 8.7c.2.1.5.2.7.2.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4-2-1.9-3.1-4.5-3.1-7.2s1.1-5.3 3.1-7.2c.4-.4.4-1 0-1.4M11.7 29.8c-.4-.4-1-.4-1.4 0-1.2 1.1-1.8 2.7-1.8 4.3s.7 3.1 1.8 4.3c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4-.8-.8-1.2-1.8-1.2-2.9s.4-2.1 1.2-2.9c.3-.3.3-1 0-1.4M58.3 25.4c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4c2 1.9 3.1 4.5 3.1 7.2s-1.1 5.3-3.1 7.2c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3 2.4-2.3 3.7-5.4 3.7-8.7 0-3.1-1.3-6.2-3.7-8.5M52.3 29.8c-.4.4-.4 1 0 1.4.8.8 1.2 1.8 1.2 2.9s-.4 2.1-1.2 2.9c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3 1.2-1.1 1.8-2.7 1.8-4.3s-.7-3.1-1.8-4.3c-.3-.4-1-.4-1.4 0" />
  </svg>
);
export default IconAlarm4;
