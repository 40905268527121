import * as React from "react";
const IconSeoCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.4 7.8H8.6C5 7.8 2 10.8 2 14.4v23.2c0 3.6 3 6.6 6.6 6.6H31v9.9H18.8c-.6 0-1 .4-1 1s.4 1 1 1h26.4c.6 0 1-.4 1-1s-.4-1-1-1H33v-9.9h22.4c3.6 0 6.6-3 6.6-6.6V14.4c0-3.6-3-6.6-6.6-6.6M60 37.6c0 2.5-2.1 4.6-4.6 4.6H8.6c-2.5 0-4.6-2.1-4.6-4.6V14.4c0-2.5 2.1-4.6 4.6-4.6h46.8c2.5 0 4.6 2.1 4.6 4.6z" />
    <path d="M16.1 19.1h4c.6 0 1-.4 1-1s-.4-1-1-1h-4c-2.7 0-5 2.2-5 5s2.2 5 5 5c1.6 0 3 1.3 3 3 0 1.6-1.3 3-3 3h-4c-.6 0-1 .4-1 1s.4 1 1 1h4c2.7 0 5-2.2 5-5s-2.2-5-5-5c-1.6 0-3-1.3-3-3s1.4-3 3-3M31.9 19.1h4c.6 0 1-.4 1-1s-.4-1-1-1h-4c-2.7 0-5 2.2-5 5V30c0 2.7 2.2 5 5 5h4c.6 0 1-.4 1-1s-.4-1-1-1h-4c-1.6 0-3-1.3-3-3v-3h6.9c.6 0 1-.4 1-1s-.4-1-1-1H29v-3c0-1.6 1.3-2.9 2.9-2.9M48.9 17.1h-2.3c-2.1 0-3.8 1.7-3.8 3.8v10.2c0 2.1 1.7 3.8 3.8 3.8h2.3c2.1 0 3.8-1.7 3.8-3.8V20.9c0-2.1-1.7-3.8-3.8-3.8m1.8 14c0 1-.8 1.8-1.8 1.8h-2.3c-1 0-1.8-.8-1.8-1.8V20.9c0-1 .8-1.8 1.8-1.8h2.3c1 0 1.8.8 1.8 1.8z" />
  </svg>
);
export default IconSeoCopy;
