import * as React from "react";
const IconRubleDown = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M11.8 25.8c.6 0 1-.4 1-1C12.8 13.9 21.7 5 32.7 5s19.9 8.9 19.9 19.8c0 6-2.7 11.6-7.4 15.4-.4.3-.5 1-.2 1.4.2.2.5.4.8.4.2 0 .4-.1.6-.2 5.2-4.2 8.1-10.3 8.1-16.9 0-12-9.8-21.8-21.9-21.8s-21.9 9.8-21.9 21.8c.1.4.6.9 1.1.9M49.5 48.6c-.6 0-1 .4-1 1v8.3L34.9 48c-.8-.5-1.6-.8-2.6-.8H19.6c-.7 0-1.4-.3-1.9-.8l-6.5-6.7c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l6.5 6.7c.8.9 2.1 1.4 3.4 1.4h12.7c.5 0 1 .2 1.4.4l13 9.5H39c-.6 0-1 .4-1 1s.4 1 1 1h10.4c.6 0 1-.4 1-1V49.6c.1-.6-.4-1-.9-1" />
    <path d="M37.1 23.7c2.6 0 4.7-2.1 4.7-4.7s-2.1-4.7-4.7-4.7h-6.3c-.6 0-1 .4-1 1v13.9h-5.3c-.6 0-1 .4-1 1s.4 1 1 1h5.3v5.3c0 .6.4 1 1 1s1-.4 1-1v-5.3h5.3c.6 0 1-.4 1-1s-.4-1-1-1h-5.3v-5.5zm-5.2-7.4h5.3c1.5 0 2.7 1.2 2.7 2.7s-1.2 2.7-2.7 2.7h-5.3z" />
  </svg>
);
export default IconRubleDown;
