import * as React from "react";
const IconMilkBottle = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 65 66"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M45.7 26c.6-.7.9-1.6.9-2.6 0-2.3-1.8-4.1-4-4.3v-.5c0-.7 0-1.9-.2-3.2-.4-2.2-1.3-3.9-2.6-5.1q-1.95-1.95-3.3-4.2c-1-1.8-2.4-2.8-4.1-2.8s-3.1.9-4.1 2.8q-1.2 2.25-3.3 4.2c-1.3 1.2-2.2 2.9-2.6 5.1-.2 1.3-.2 2.4-.2 3.2v.5c-2.3.1-4.1 2-4.1 4.3 0 1 .4 1.9.9 2.6-1.9.7-3.3 2.5-3.3 4.7v26.9c0 2.8 2.2 5 5 5h23c2.8 0 5-2.2 5-5V30.7c.3-2.1-1.1-3.9-3-4.7M24.5 15.8c.3-1.7 1-3.1 2-4 1.5-1.4 2.7-3 3.6-4.7.8-1.5 1.7-1.7 2.4-1.7s1.5.2 2.4 1.7c.9 1.7 2.2 3.3 3.6 4.7 1 .9 1.6 2.3 2 4 .2 1.1.2 2.1.2 2.8v.5H24.4c0-.2-.1-.4-.1-.5 0-.7 0-1.7.2-2.8m-1.9 5.3h19.7c1.3 0 2.3 1 2.3 2.3s-1 2.3-2.3 2.3H22.6c-1.3 0-2.3-1-2.3-2.3s1.1-2.3 2.3-2.3M47 57.6c0 1.7-1.3 3-3 3H21c-1.7 0-3-1.3-3-3v-4.4h7.5c.6 0 1-.4 1-1s-.4-1-1-1H18v-6h7.5c.6 0 1-.4 1-1s-.4-1-1-1H18v-6h7.5c.6 0 1-.4 1-1s-.4-1-1-1H18v-4.5c0-1.7 1.3-3 3-3h23c1.7 0 3 1.3 3 3z" />
  </svg>
);
export default IconMilkBottle;
