import * as React from "react";
const IconBittorrent = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 65 64"
    {...props}
  >
    <path
      
      d="M33.278 3.936h.24l-.757.074c4.707.026 9.419 1.172 13.547 3.45 5.353 2.92 9.707 7.635 12.171 13.217 1.61 3.642 2.46 7.631 2.374 11.618.023 6.47-2.404 12.885-6.578 17.814-6.028.764-12.271 1.227-18.21-.352-3.524-.877-6.657-3.15-8.663-6.167-2.037-3.606-1.34-8.596 1.748-11.398 2.15-1.95 5.254-2.786 8.092-2.152 2.511.513 4.743 2.15 6.031 4.361 1.259-.662 2.508-1.34 3.769-1.998-.888-1.494-1.984-2.883-3.354-3.963-2.304-1.824-5.225-2.814-8.154-2.896-2.778-.033-5.564.797-7.85 2.384-4.642 3.155-6.938 9.415-5.333 14.807 1.025 3.66 3.713 6.666 6.85 8.705 2.494 1.604 5.384 2.473 8.282 2.998 3.981.704 8.046.444 12.05.103-.402.414-1.006.39-1.535.484-4.272.634-8.679.654-12.901-.337-4.05-.938-7.908-2.936-10.785-5.97-4.228-4.567-5.218-11.987-1.896-17.33 1.735-2.846 4.28-5.293 7.444-6.455 3.455-1.289 7.39-1.276 10.828.068.565-1.31 1.18-2.599 1.734-3.913-5.668-2.317-12.44-1.716-17.587 1.613-2.818 1.822-5.137 4.37-6.823 7.264-1.88 3.328-2.488 7.298-1.913 11.06.758 5.071 3.529 9.818 7.592 12.951 1.976 1.56 4.312 2.612 6.716 3.322 3.107.923 6.349 1.325 9.583 1.4.458.007.918.025 1.374.083-.65.337-1.405.235-2.111.255-3.843.08-7.715-.517-11.319-1.862-2.741-1.033-5.33-2.573-7.363-4.701-2.133-2.12-3.928-4.627-4.967-7.463-1.222-3.357-1.604-7.048-.95-10.568.794-4.187 3.185-7.952 6.316-10.796 3.756-3.43 8.84-5.38 13.93-5.296.002-1.438-.002-2.879.004-4.317-4.393.002-8.788 1.15-12.586 3.366-5.02 2.92-8.965 7.611-11.058 13.02-1.536 4.053-2.11 8.568-1.067 12.815 1.194 5.186 4.488 9.802 8.9 12.753.687.504 1.473.855 2.148 1.38-.312-.021-.629-.058-.909-.206-1.422-.68-2.75-1.539-4.013-2.48-2.272-1.719-4.415-3.663-6.041-6.02-2.033-2.823-3.637-5.983-4.523-9.359-.842-3.121-1.032-6.387-.81-9.603.536-6.896 3.783-13.525 8.872-18.205 4.139-3.849 9.463-6.401 15.064-7.182 1.456-.233 2.935-.21 4.397-.376"
    />
  </svg>
);
export default IconBittorrent;
