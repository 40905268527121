import * as React from "react";
const IconBitbucketCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M21.8 12.1c0 2.8 5.3 4 10.2 4s10.2-1.3 10.2-4c0-2.8-5.3-4-10.2-4s-10.2 1.2-10.2 4m18.4 0c0 .5-2.8 2-8.2 2s-8.2-1.5-8.2-2 2.8-2 8.2-2 8.2 1.5 8.2 2M32 23.1c-3 0-5.4 2.4-5.4 5.5 0 3 2.4 5.5 5.4 5.5s5.4-2.4 5.4-5.5c0-3-2.4-5.5-5.4-5.5m0 8.9c-1.9 0-3.4-1.5-3.4-3.5 0-1.9 1.5-3.5 3.4-3.5s3.4 1.5 3.4 3.5S33.9 32 32 32" />
    <path d="M52 4.4C46.9 2.2 38.1 1 32 1S17.1 2.2 12 4.4c-.7.3-1 1-1 1.6l2.7 27.9c.1.5.4 1 .8 1.2 1.7.9 10.6 5.1 17.4 5.1 7.2 0 13.8-2 17.7-5.3.3-.3.5-.6.5-1L53 6c0-.7-.3-1.3-1-1.6m-3.7 29.2c-3.5 2.9-9.7 4.7-16.3 4.7-6.1 0-14.2-3.8-16.3-4.8L13.1 6.1C18 4.1 26.2 3 32 3s14 1.1 18.9 3.1zM48.5 41.9c-.4-.3-1-.4-1.5-.2l-.2.1c-2.9 1.3-8.8 3.9-14.7 3.8-6.5-.1-12.4-2.7-15-3.9-.5-.2-1.1-.2-1.5.2-.5.3-.7.9-.6 1.5l1.7 15.1c.1.6.4 1.2 1 1.5 3.9 2 9 3.1 14.4 3.1S42.6 62 46.5 60c.6-.3.9-.8 1-1.5l1.7-15.1c0-.6-.2-1.2-.7-1.5m-3.1 16.3C41.8 60 37 61 32 61s-9.8-1-13.4-2.8l-1.7-14.3c2.9 1.3 8.7 3.6 15 3.7 6 .1 11.9-2.3 15.1-3.7z" />
  </svg>
);
export default IconBitbucketCopy;
