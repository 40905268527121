import * as React from "react";
const IconChatAlt10 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M52.2 11.5c-6.1-6.1-14.5-9-23.1-8.1-13.2 1.4-23.8 12-25.2 25.2-.7 6.3.7 12.5 4 17.8l-4.2 11c-.4 1-.1 2 .6 2.7.5.4 1.1.6 1.7.6.4 0 .8-.1 1.1-.3l9.8-4.9c5.4 3.4 11.6 4.9 18 4.3C48.3 58.6 59 48 60.4 34.6c.8-8.6-2.2-17-8.2-23.1m6.2 22.9c-1.2 12.5-11.2 22.4-23.7 23.5-6.2.6-12.1-.9-17.2-4.3-.3-.2-.7-.2-1-.1L6.2 58.7c-.3.1-.5 0-.5-.1-.1-.1-.2-.3-.1-.5L10 46.7c0-.3 0-.7-.2-.9-3.2-5-4.6-10.9-4-17C7.2 16.5 17 6.7 29.3 5.4c.9-.1 1.9-.1 2.8-.1 7 0 13.7 2.7 18.7 7.7 5.6 5.6 8.4 13.4 7.6 21.4" />
    <path d="M17.7 29c-1.6 0-3 1.3-3 3s1.4 3 3 3c1.7 0 3-1.3 3-3s-1.3-3-3-3m.1 4c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.5 1-1 1M32 29c-1.6 0-3 1.3-3 3s1.4 3 3 3c1.7 0 3-1.3 3-3s-1.3-3-3-3m0 4c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1M46.2 29c-1.6 0-3 1.3-3 3s1.4 3 3 3c1.7 0 3-1.3 3-3s-1.3-3-3-3m.1 4c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.5 1-1 1" />
  </svg>
);
export default IconChatAlt10;
