import * as React from "react";
const IconShare = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M29.5 42.6c1.2 0 2.3-1 2.3-2.3v-15c0-5.4 4.4-9.8 9.8-9.8h11.9L47.7 21c-.9.9-.9 2.3-.1 3.2.4.5 1 .7 1.6.7q.9 0 1.5-.6l9.2-8.7c.6-.6 1-1.5 1-2.4s-.4-1.7-1-2.3l-9.2-8.5c-.9-.8-2.3-.8-3.2.1-.8.9-.8 2.3.1 3.2l5.8 5.4h-12c-7.9 0-14.3 6.4-14.3 14.3v15c.2 1.2 1.2 2.2 2.4 2.2" />
    <path d="M59 38.1c-1.2 0-2.3 1-2.3 2.3v14.5c0 1.6-1.3 2.9-2.9 2.9H10.2c-1.6 0-2.9-1.3-2.9-2.9V40.3c0-1.2-1-2.3-2.3-2.3s-2.3 1-2.3 2.3v14.5c0 4.1 3.3 7.4 7.4 7.4h43.7c4.1 0 7.4-3.3 7.4-7.4V40.3c.1-1.2-.9-2.2-2.2-2.2" />
  </svg>
);
export default IconShare;
