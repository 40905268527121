import * as React from "react";
const IconCloudComputing = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56 43.8c-3 0-5.4 2.2-5.9 5.1H47V35.1c.2.3.5.5.9.5 5.5 0 10.1-4.4 10.1-9.8S53.5 16 47.9 16c-1.3 0-2.5.2-3.7.7.1-.6.1-1.2.1-1.9 0-6.6-5.5-12-12.3-12s-12.3 5.4-12.3 12c0 .6.1 1.3.1 1.9-1.2-.4-2.4-.7-3.7-.7C10.6 16 6 20.4 6 25.8s4.5 9.8 10.1 9.8c.3 0 .6-.2.8-.4V49h-3.1c-.5-2.9-2.9-5.1-5.9-5.1-3.3 0-6 2.7-6 6.1s2.7 6.1 6 6.1c3 0 5.4-2.2 5.9-5.1H18c.6 0 1-.4 1-1V29.5c0-.6-.4-1-1-1s-1 .4-1 1V34c-.2-.3-.5-.4-.8-.4-4.4 0-8.1-3.5-8.1-7.8s3.6-7.8 8.1-7.8c1.7 0 3.3.5 4.7 1.5q.6.3 1.2 0c.6-.3.5-.7.3-1.1-.4-1.1-.6-2.3-.6-3.5 0-5.5 4.6-10 10.3-10s10.3 4.5 10.3 10c0 1.2-.2 2.4-.6 3.5-.2.4 0 .9.3 1.1.3.3.8.3 1.2 0 1.4-.9 3-1.5 4.7-1.5 4.4 0 8.1 3.5 8.1 7.8s-3.6 7.8-8.1 7.8c-.4 0-.7.2-.9.5v-4.6c0-.6-.4-1-1-1s-1 .4-1 1V50c0 .6.4 1 1 1h4.1c.5 2.9 2.9 5.1 5.9 5.1 3.3 0 6-2.7 6-6.1s-2.8-6.2-6.1-6.2M8 54.1c-2.2 0-4-1.8-4-4.1s1.8-4.1 4-4.1 4 1.8 4 4.1-1.8 4.1-4 4.1m48 0c-2.2 0-4-1.8-4-4.1s1.8-4.1 4-4.1 4 1.8 4 4.1-1.8 4.1-4 4.1" />
    <path d="M33 49.1V29.5c0-.6-.4-1-1-1s-1 .4-1 1v19.6c-2.8.5-5 3-5 6 0 3.4 2.7 6.1 6 6.1s6-2.7 6-6.1c0-3-2.2-5.6-5-6m-1 10.1c-2.2 0-4-1.8-4-4.1s1.8-4.1 4-4.1 4 1.8 4 4.1-1.8 4.1-4 4.1" />
  </svg>
);
export default IconCloudComputing;
