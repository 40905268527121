import React from 'react';
import { BringzzText, BringzzSwitch } from "@bringzz/components";
import { checkNotificationPermission, requestNotificationPermission } from 'notifications';
import useRequest from 'hooks/useRequest';

export const NotificationsSwitch = () => {
    const { sendRequest, loading } = useRequest();

    return (
        <div className='flex py-2 justify-between items-center'>
            <div>
                <BringzzText tag='h4' className="font-bold">Get notifications</BringzzText>
                <BringzzText tag='h4' className="font-normal text-midnight-blue/60">We’ll be sending you lorem ipsum dolor.</BringzzText>
            </div>
            <div>
                <BringzzSwitch
                    defaultValue={checkNotificationPermission()}
                    onEnable={async () => {
                        const status = requestNotificationPermission()
                        await sendRequest({
                            method: 'POST',
                            url: `/settings/app/notifications`,
                            data: { getNotification: status }
                        }).then((response) => {
                            if (response.status === 'success') {
                                // TODO: Toast
                            }
                        }).catch((error) => {
                            console.error(error);
                        })
                        return status
                    }}
                    onDisable={async () => {
                        console.log("dsadasd");
                        await sendRequest({
                            method: 'POST',
                            url: `/settings/app/notifications`,
                            data: { getNotification: false }
                        }).then((response) => {
                            if (response.status === 'success') {
                                // TODO: Toast
                            }
                        }).catch((error) => {
                            console.error(error);
                        })
                        return false
                    }}
                />
            </div>
        </div>
    )
}