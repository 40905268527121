import React, { useState } from 'react';
import { useNavigation } from 'context/NavigationContext';
import { BringzzPageContainer, BringzzIcon, BringzzText, BringzzButton, BringzzModal } from "@bringzz/components";

const DeleteAccountPage = () => {
    const { backPress, navigate } = useNavigation();
    const [modalOpen, setModalOpen] = useState(false);

    return (
        <BringzzPageContainer
            header={{
                left: <BringzzIcon
                    size="22"
                    icon="IconChevronLeft"
                    folder="LineIcons"
                    className="cursor-pointer"
                    onClick={backPress}
                />,
                title: <BringzzText tag='h2'>Delete account</BringzzText>

            }}
            footer={<div className='pb-28 px-6 space-y-2'>
                <BringzzButton
                    size='lg'
                    className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
                    onClick={() => {
                        setModalOpen(true)
                    }}
                >
                    Delete account
                </BringzzButton>
                <BringzzButton
                    size='lg'
                    className='border border-black w-full flex justify-center text-midnight-blue'
                    onClick={() => {
                        backPress()
                    }}
                >
                    Cancel
                </BringzzButton>
            </div>}
        >

            <div className='p-4 space-y-8'>
                <BringzzText tag='h2'>Are you sure you want to delete your account and data?</BringzzText>
                <div className='space-y-1'>
                    <BringzzText tag='h3'>Account deactivation</BringzzText>
                    <BringzzText tag="h4" className={"font-normal"}>Dis sapien egestas in cras mattis semper eu quisque. Eget felis vel ornare ut viverra viverra habitant. Montes tortor lacinia scelerisque egestas eget massa. Iaculis habitant viverra nibh sed magna dapibus. Proin urna posuere tortor varius id varius in nec viverra. Rhoncus egestas eget consequat odio ut sed consectetur laoreet quis. Viverra.</BringzzText>
                </div>
                <div className='space-y-1'>
                    <BringzzText tag='h3'>Data deletion</BringzzText>
                    <BringzzText tag="h4" className={"font-normal"}>Dis sapien egestas in cras mattis semper eu quisque. Eget felis vel ornare ut viverra viverra habitant. Montes tortor lacinia scelerisque egestas eget massa. Iaculis habitant viverra nibh sed magna dapibus. Proin urna posuere tortor varius id varius in nec viverra. Rhoncus egestas eget consequat odio ut sed consectetur laoreet quis. Viverra.</BringzzText>
                </div>
                {modalOpen && (
                    <BringzzModal
                        title={`Are you sure you want to delete your account?`}
                        description="This action cannot be undone. Lorem ipsum dolor sit amet est adiscibitur."
                        onCancel={() => setModalOpen(false)}
                        onConfirm={async () => {
                            navigate("/settings/app/delete/verify", false)
                        }}
                        confirmButtonText="Delete"
                    />
                )}
            </div>
        </BringzzPageContainer>

    )
}

export default DeleteAccountPage;