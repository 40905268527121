import * as React from "react";
const IconBridgeCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 32.3h-7.7C50.8 23.2 42.2 16.5 32 16.5s-18.8 6.7-21.3 15.9H3c-.6 0-1 .4-1 1s.4 1 1 1h6.9v12.2c0 .6.4 1 1 1s1-.4 1-1v-8.7c0-1.2.1-2.4.4-3.5h39.5c.2 1.1.4 2.3.4 3.5v8.7c0 .6.4 1 1 1s1-.4 1-1V34.3H61c.6 0 1-.4 1-1s-.4-1-1-1m-30 0h-8.5V20.8c2.6-1.3 5.5-2.1 8.5-2.3zm2-13.8c3.1.1 6 .9 8.5 2.3v11.5H33zM20.5 22v10.3h-7.7c1.3-4.2 4-7.8 7.7-10.3m23 10.3V22c3.6 2.5 6.4 6.1 7.7 10.3z" />
  </svg>
);
export default IconBridgeCopy;
