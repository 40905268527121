import * as React from "react";
const IconHierchy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M12.8 52.2c3.4 0 6.1-2.8 6.1-6.1 0-3.4-2.7-6.1-6.1-6.1s-6.1 2.8-6.1 6.1 2.7 6.1 6.1 6.1m0-10.3c2.3 0 4.1 1.9 4.1 4.1s-1.8 4.1-4.1 4.1-4.1-1.8-4.1-4.1 1.8-4.1 4.1-4.1M12.9 55.9c-4 0-7.9 1.6-10.6 4.4-.4.4-.4 1 0 1.4s1 .4 1.4 0c2.3-2.4 5.7-3.8 9.1-3.8s6.8 1.4 9.1 3.8c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4-2.5-2.8-6.4-4.4-10.4-4.4M44.9 46c0 3.4 2.7 6.1 6.1 6.1s6.1-2.8 6.1-6.1c0-3.4-2.7-6.1-6.1-6.1s-6.1 2.8-6.1 6.1m10.2 0c0 2.3-1.8 4.1-4.1 4.1s-4.1-1.9-4.1-4.1 1.8-4.1 4.1-4.1 4.1 1.9 4.1 4.1M61.7 60.3c-2.7-2.8-6.6-4.4-10.6-4.4s-7.9 1.6-10.6 4.4c-.4.4-.4 1 0 1.4s1 .4 1.4 0c2.3-2.4 5.7-3.8 9.1-3.8 3.5 0 6.8 1.4 9.1 3.8.2.2.5.3.7.3.3 0 .5-.1.7-.3.5-.4.6-1 .2-1.4M25.4 20.3h11.2c4.2 0 7.5-3.4 7.5-7.5 0-1.9-.7-3.7-2-5.1-1.1-1.2-2.6-2-4.2-2.3-.9-1.4-2.2-2.4-3.7-3-.7-.3-1.5-.4-2.3-.4-3.8 0-7 3-7.2 6.7-2.8.4-5 2.8-5 5.8-.1 3.2 2.5 5.8 5.7 5.8m0-9.6h.2c.6 0 1-.4 1-1v-.4C26.6 6.4 29 4 31.9 4c.6 0 1.2.1 1.7.3 1.2.4 2.2 1.3 2.9 2.5.2.3.4.5.8.5 1.2.1 2.4.7 3.3 1.7 1 1 1.5 2.4 1.5 3.8 0 3-2.5 5.5-5.5 5.5H25.4c-2.1 0-3.8-1.7-3.8-3.8s1.7-3.8 3.8-3.8M12.9 35.5c.6 0 1-.4 1-1v-3.9h36.3v3.9c0 .6.4 1 1 1s1-.4 1-1v-4.9c0-.6-.4-1-1-1H33v-2.9c0-.6-.4-1-1-1s-1 .4-1 1v2.9H12.9c-.6 0-1 .4-1 1v4.9c0 .5.4 1 1 1" />
  </svg>
);
export default IconHierchy;
