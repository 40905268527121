import * as React from "react";
const IconCorner = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 40.8h-2.6c-.7 0-1.4.2-2 .6-3-3.7-14.2-17.4-16.6-20.1.3-.6.5-1.2.5-2v-2.6c0-2.2-1.8-4-4-4h-2.6c-2.2 0-4 1.8-4 4v2.6q0 1.2.6 2.1l-16.5 20c-.6-.4-1.3-.6-2-.6H6c-2.2 0-4 1.8-4 4v2.6c0 2.2 1.8 4 4 4h2.6c2.2 0 4-1.8 4-4v-2.6c0-.7-.2-1.4-.5-2l16.6-20.1c.6.3 1.2.5 2 .5h2.6q1.2 0 2.1-.6C37.4 25 46 35.4 52 42.7c-.3.6-.5 1.3-.5 2v2.6c0 2.2 1.8 4 4 4H58c2.2 0 4-1.8 4-4v-2.6c0-2.1-1.8-3.9-4-3.9m-47.4 6.6c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2v-2.6c0-1.1.9-2 2-2h2.6c1.1 0 2 .9 2 2zm22.7-26.2h-2.6c-1.1 0-2-.9-2-2v-2.6c0-1.1.9-2 2-2h2.6c1.1 0 2 .9 2 2v2.6c0 1.1-.9 2-2 2M60 47.4c0 1.1-.9 2-2 2h-2.6c-1.1 0-2-.9-2-2v-2.6c0-1.1.9-2 2-2H58c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconCorner;
