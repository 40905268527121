import * as React from "react";
const IconIdeaReload = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M20.8 32.5c0 3.9 2 7.5 5.3 9.5.6.4 1.1 1.2 1.1 2v2c0 2.4 2 4.4 4.4 4.4h1c2.4 0 4.4-2 4.4-4.4v-2c0-.4.1-.8.3-1.1s.4-.6.7-.8c3.2-2 5.2-5.4 5.3-9.2.1-3-1-5.8-3.1-8s-4.9-3.5-7.8-3.5c-3-.1-5.9 1.1-8.1 3.2-2.3 2-3.5 4.8-3.5 7.9M34.9 46c0 1.3-1.1 2.4-2.4 2.4h-1c-1.3 0-2.4-1.1-2.4-2.4v-2.6H35V46zM32 23.3h.2c2.4.1 4.7 1.1 6.4 2.9s2.6 4.2 2.6 6.6c-.1 3.1-1.7 5.9-4.3 7.6-.5.3-.9.7-1.2 1.1h-7.4c-.3-.4-.7-.8-1.2-1.1a9.14 9.14 0 0 1-4.3-7.8c0-2.5 1-4.9 2.8-6.6 1.7-1.8 4-2.7 6.4-2.7" />
    <path d="M50.5 14c-.4-.4-1-.3-1.4.1s-.3 1 .1 1.4c5.2 4.8 8.2 11.6 8.2 18.6 0 14-11.4 25.3-25.3 25.3-14 0-25.3-11.4-25.3-25.3 0-10.7 6.7-20.2 16.7-23.8l-2.8 6.8c-.2.5 0 1.1.5 1.3.1.1.3.1.4.1.4 0 .8-.2.9-.6l2.9-7.1q.6-1.5 0-3c-.6-1.5-1.2-1.8-2.2-2.2l-7.1-2.9c-.5-.2-1.1 0-1.3.5s0 1.1.5 1.3l7.1 2.9c.4.2.7.4.9.8C12.2 11.9 4.7 22.3 4.7 34.1 4.7 49.2 17 61.4 32 61.4s27.3-12.3 27.3-27.3c0-7.6-3.2-14.9-8.8-20.1" />
  </svg>
);
export default IconIdeaReload;
