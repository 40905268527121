import * as React from "react";
const IconNetworkCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M31.9 17.1c3.5 0 6.3-2.8 6.3-6.3s-2.8-6.3-6.3-6.3-6.3 2.8-6.3 6.3 2.8 6.3 6.3 6.3m0-10.6c2.4 0 4.3 1.9 4.3 4.3s-1.9 4.3-4.3 4.3-4.3-1.9-4.3-4.3 1.9-4.3 4.3-4.3M22.7 27.7c2.4-2.6 5.8-4.1 9.3-4.1s6.9 1.5 9.3 4.1c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 .1-1.4a14.757 14.757 0 0 0-21.6 0c-.4.4-.3 1 .1 1.4s1 .4 1.4 0M13.4 48.9c3.5 0 6.3-2.8 6.3-6.3s-2.8-6.3-6.3-6.3-6.3 2.8-6.3 6.3 2.8 6.3 6.3 6.3m0-10.6c2.4 0 4.3 1.9 4.3 4.3s-1.9 4.3-4.3 4.3-4.3-1.9-4.3-4.3 1.9-4.3 4.3-4.3M13.1 53.1c-4.1 0-8 1.7-10.8 4.7-.4.4-.3 1 .1 1.4s1 .3 1.4-.1c2.4-2.6 5.8-4.1 9.3-4.1s6.9 1.5 9.3 4.1c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 .1-1.4-2.8-2.9-6.7-4.6-10.8-4.6M50.4 48.9c3.5 0 6.3-2.8 6.3-6.3s-2.8-6.3-6.3-6.3-6.3 2.8-6.3 6.3 2.9 6.3 6.3 6.3m0-10.6c2.4 0 4.3 1.9 4.3 4.3s-1.9 4.3-4.3 4.3-4.3-1.9-4.3-4.3 2-4.3 4.3-4.3M61.7 57.8a14.757 14.757 0 0 0-21.6 0c-.4.4-.3 1 .1 1.4s1 .4 1.4-.1c2.4-2.6 5.8-4.1 9.3-4.1s6.9 1.5 9.3 4.1c.2.2.5.3.7.3s.5-.1.7-.3c.5-.3.5-.9.1-1.3M37.6 39.1 32.9 37v-5c0-.6-.4-1-1-1s-1 .4-1 1v4.8l-4.7 2.3c-.5.2-.7.8-.5 1.3s.8.7 1.3.5l4.7-2.2 5 2.3c.1.1.3.1.4.1q.6 0 .9-.6c.3-.6.1-1.2-.4-1.4" />
  </svg>
);
export default IconNetworkCopy;
