import * as React from "react";
const IconArchway = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.9 17.7h-1.3v-7.4c0-2.8-2.3-5.1-5.1-5.1h-7.9c-2.8 0-5.1 2.3-5.1 5.1v7.4H26.4v-7.4c0-2.8-2.3-5.1-5.1-5.1h-7.9c-2.8 0-5.1 2.3-5.1 5.1v7.4H7.1C4.3 17.7 2 20 2 22.8v31c0 2.8 2.3 5.1 5.1 5.1h12.1c2.8 0 5.1-2.3 5.1-5.1v-7.4c0-4.3 3.5-7.7 7.7-7.7 2.1 0 4 .8 5.5 2.3s2.3 3.4 2.3 5.5v7.4c0 2.8 2.3 5.1 5.1 5.1H57c2.8 0 5.1-2.3 5.1-5.1v-31c-.1-3-2.4-5.2-5.2-5.2m-17.3-7.5c0-1.7 1.4-3.1 3.1-3.1h7.9c1.7 0 3.1 1.4 3.1 3.1v7.4h-14v-7.4zm-29.2 0c0-1.7 1.4-3.1 3.1-3.1h7.9c1.7 0 3.1 1.4 3.1 3.1v7.4h-14v-7.4zM60 53.8c0 1.7-1.4 3.1-3.1 3.1H44.8c-1.7 0-3.1-1.4-3.1-3.1v-7.4c0-2.6-1-5-2.8-6.9-1.8-1.8-4.3-2.8-6.9-2.8-5.4 0-9.7 4.4-9.7 9.7v7.4c0 1.7-1.4 3.1-3.1 3.1H7.1c-1.7 0-3.1-1.4-3.1-3.1v-31c0-1.7 1.4-3.1 3.1-3.1H57c1.7 0 3.1 1.4 3.1 3.1v31z" />
  </svg>
);
export default IconArchway;
