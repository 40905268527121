import React, { useState } from 'react';
import { useNavigation } from '../../context/NavigationContext';
import { BringzzPageContainer, BringzzIcon, BringzzText, BringzzInput, BringzzButton, BringzzCheckbox } from "@bringzz/components"

const PharmacyPostProduct1 = () => {
    const { backPress } = useNavigation();
    const [isChecked1, setChecked1] = useState(false)
    const [isChecked2, setChecked2] = useState(false)

    return (
        <BringzzPageContainer
            header={{
                left: <BringzzIcon
                    size="22"
                    icon="IconChevronLeft"
                    folder="LineIcons"
                    className="cursor-pointer"
                    onClick={backPress}
                />,
                title: <BringzzText tag='h2'>Produkt Post</BringzzText>,
            }}>

            <div className='p-4'>
                <div className='space-y-4'>
                    <BringzzInput label="Headline (max. XXX char)" placeholder="Your posting headline" tooltip="something" />
                    <BringzzInput label="Description (max. XXX char)" type="textarea" placeholder="Your posting description" tooltip="something" />
                </div>
                <div className='flex p-3 space-x-3'>
                    <BringzzButton className="border border-black" icon={{ variant: "IconPlus", folder: "LineIcons", size: "16" }}>Add Image</BringzzButton>
                    <BringzzButton className="border border-black" icon={{ variant: "IconPlus", folder: "LineIcons", size: "16" }}>Add Product</BringzzButton>
                </div>

                <div className='space-y-2 pl-1'>
                    <BringzzCheckbox
                        id="example-checkbox1"
                        label="Custom post validity"
                        checked={isChecked1}
                        onChange={(isChecked1) => setChecked1(isChecked1)}
                    />
                    <BringzzCheckbox
                        id="example-checkbox2"
                        label="Hide likes & comments"
                        checked={isChecked2}
                        onChange={(isChecked2) => setChecked2(isChecked2)}
                    />
                </div>

                <div className='flex space-x-3 mt-96'>
                    <BringzzButton className="border w-full border-black justify-center">Save</BringzzButton>
                    <BringzzButton className="bg-magic-lilac w-full justify-center">Share</BringzzButton>
                </div>
            </div>
        </BringzzPageContainer>

    )
}

export default PharmacyPostProduct1;