import * as React from "react";
const IconMeta1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 65 64"
    {...props}
  >
    <path
      
      d="M25.504 27.039h1.994l3.391 6.134 3.391-6.134h1.951v10.08h-1.627v-7.726l-2.973 5.35h-1.526l-2.974-5.35v7.725h-1.627zM41.48 37.298q-1.13 0-1.987-.5a3.5 3.5 0 0 1-1.336-1.386q-.48-.885-.479-2.03 0-1.16.468-2.052a3.5 3.5 0 0 1 1.3-1.397q.831-.504 1.911-.504 1.072 0 1.847.508.774.507 1.191 1.421.418.915.418 2.146v.446h-5.522q.151.922.745 1.451.594.53 1.501.53.728 0 1.253-.217.525-.216.986-.655l.864 1.059q-1.289 1.18-3.16 1.18m1.188-5.98q-.512-.52-1.34-.521-.805 0-1.35.533-.543.533-.687 1.433h3.96q-.072-.922-.584-1.444M46.786 30.941H45.29v-1.332h1.497v-2.203h1.57v2.203h2.275v1.332h-2.275v3.377q0 .842.288 1.202t.986.36q.31 0 .526-.025a10 10 0 0 0 .475-.069v1.318q-.296.085-.601.13a5 5 0 0 1-.702.05q-2.542 0-2.542-2.78zM58.81 37.118h-1.54v-1.051a2.8 2.8 0 0 1-1.045.91 3.15 3.15 0 0 1-1.44.321q-.993 0-1.76-.508a3.5 3.5 0 0 1-1.206-1.396q-.44-.89-.439-2.034 0-1.152.446-2.038.447-.885 1.235-1.39.79-.503 1.81-.503.771 0 1.383.299c.403.194.751.485 1.015.846v-.965h1.541zm-1.57-4.881a2.14 2.14 0 0 0-.795-1.012 2.18 2.18 0 0 0-1.256-.37q-1.009 0-1.606.676t-.597 1.829q0 1.16.576 1.836.575.677 1.562.677.726 0 1.296-.375.568-.374.82-1.008zM17.642 26.698c-1.308 0-2.331.985-3.257 2.237-1.272-1.62-2.336-2.237-3.61-2.237-2.596 0-4.585 3.379-4.585 6.955 0 2.237 1.083 3.649 2.896 3.649 1.305 0 2.244-.616 3.912-3.532l1.174-2.075q.252.406.53.875l.783 1.317c1.524 2.55 2.373 3.415 3.912 3.415 1.767 0 2.75-1.431 2.75-3.715 0-3.745-2.034-6.889-4.505-6.889m-5.916 6.282c-1.353 2.12-1.821 2.596-2.574 2.596-.775 0-1.236-.68-1.236-1.894 0-2.596 1.294-5.25 2.837-5.25.836 0 1.534.482 2.604 2.013-1.016 1.558-1.631 2.535-1.631 2.535m5.106-.267-.936-1.56q-.38-.618-.73-1.138c.844-1.301 1.54-1.95 2.366-1.95 1.719 0 3.094 2.53 3.094 5.639 0 1.185-.388 1.872-1.192 1.872-.77 0-1.139-.51-2.602-2.863"
    />
  </svg>
);
export default IconMeta1;
