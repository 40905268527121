import * as React from "react";
const IconPadlockLove = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 32.4c-.9-.5-2-.8-3-.8-1.6 0-3.1.6-4.3 1.7s-1.9 2.7-2 4.4c0 1.8.7 3.6 2 4.9l5.6 5.6c.5.5 1.1.7 1.7.7s1.2-.2 1.7-.7l5.8-5.8c1.2-1.2 1.9-2.8 1.9-4.5s-.7-3.3-1.9-4.5c-2-1.9-5.1-2.3-7.5-1m6.1 8.7-5.8 5.8q-.3.3-.6 0L26 41.3c-.9-.9-1.4-2.2-1.4-3.5 0-1.2.5-2.3 1.3-3s1.8-1.1 2.9-1.1c.9 0 1.7.3 2.5.8.3.2.8.2 1.1 0 1.7-1.2 4-1 5.5.5.8.8 1.3 1.9 1.3 3.1.1 1.1-.3 2.2-1.1 3" />
    <path d="M52 19.1h-8.2v-5.3C43.8 7.3 38.5 2 32 2S20.2 7.3 20.2 13.8v5.3H12c-3.3 0-6 2.7-6 6V56c0 3.3 2.7 6 6 6h40c3.3 0 6-2.7 6-6V25.1c0-3.3-2.7-6-6-6m-29.8-5.3C22.2 8.4 26.6 4 32 4s9.8 4.4 9.8 9.8v5.3H22.2zM56 56c0 2.2-1.8 4-4 4H12c-2.2 0-4-1.8-4-4V25.1c0-2.2 1.8-4 4-4h40c2.2 0 4 1.8 4 4z" />
  </svg>
);
export default IconPadlockLove;
