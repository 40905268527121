import * as React from "react";
const IconDollarValueDown = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M16.8 33.9h6c.6 0 1-.4 1-1s-.4-1-1-1h-2.7v-1.8c0-.6-.4-1-1-1s-1 .4-1 1v1.8h-1.3c-2.3 0-4.3 1.9-4.3 4.2s1.9 4.2 4.3 4.2h2.8c1.2 0 2.3 1 2.3 2.2s-1 2.2-2.3 2.2h-6c-.6 0-1 .4-1 1s.4 1 1 1h4.6v1.8c0 .6.4 1 1 1s1-.4 1-1v-1.8c2.1-.3 3.7-2 3.7-4.2 0-2.3-1.9-4.2-4.3-4.2h-2.8c-1.2 0-2.3-1-2.3-2.2s1.1-2.2 2.3-2.2" />
    <path d="M18.2 23.2C9.3 23.2 2 30.5 2 39.4s7.3 16.2 16.2 16.2 16.2-7.3 16.2-16.2-7.3-16.2-16.2-16.2m0 30.4C10.4 53.6 4 47.2 4 39.4s6.4-14.2 14.2-14.2 14.2 6.4 14.2 14.2S26 53.6 18.2 53.6M61.7 42.3c-.4-.4-1-.4-1.4 0l-8 8v-31c0-6-4.9-10.9-10.9-10.9h-.5c-.6 0-1 .4-1 1s.4 1 1 1h.5c4.9 0 8.9 4 8.9 8.9v31l-8-8c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l9.7 9.7c.2.2.4.3.7.3s.5-.1.7-.3l9.7-9.7c.4-.4.4-1 0-1.4" />
  </svg>
);
export default IconDollarValueDown;
