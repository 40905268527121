import * as React from "react";
const IconSmartCloud = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M14.903 27.13c0-9.472 7.658-17.13 17.128-17.13 1.857 0 3.749.264 5.528.945 3.935 1.36 7.114 4.134 9.137 7.6 4.15.571 7.904 2.601 10.55 5.507C60.189 27.26 62 31.482 62 36.175 62 46.025 54.088 54 44.112 54H15.33A13.336 13.336 0 0 1 2 40.602C2 33.32 7.69 27.47 14.903 27.214zM15.397 52h28.715C52.984 52 60 44.92 60 36.175c0-4.163-1.603-7.91-4.23-10.773l-.001-.003c-2.429-2.667-5.937-4.513-9.803-4.93a1 1 0 0 1-.77-.515c-1.789-3.276-4.699-5.882-8.304-7.123l-.034-.013C35.354 12.24 33.71 12 32.031 12c-8.366 0-15.128 6.762-15.128 15.13v1.075a1 1 0 0 1-1 1h-.506A11.337 11.337 0 0 0 4 40.602 11.337 11.337 0 0 0 15.397 52m16.915-21.433c-3.942 0-7.751 1.466-10.713 4.128a1 1 0 0 1-1.337-1.488 18.02 18.02 0 0 1 12.05-4.64c4.44 0 8.724 1.651 12.05 4.64a1 1 0 0 1-1.337 1.488 16.02 16.02 0 0 0-10.713-4.128m.043 5.483c-2.528 0-4.98.903-6.926 2.557a1 1 0 1 1-1.295-1.525 12.7 12.7 0 0 1 8.22-3.032c3.007 0 5.916 1.075 8.221 3.032a1 1 0 1 1-1.294 1.525 10.7 10.7 0 0 0-6.926-2.557m-.038 5.483a5.37 5.37 0 0 0-3.152 1.026 1 1 0 0 1-1.173-1.62 7.37 7.37 0 0 1 4.325-1.406 7.37 7.37 0 0 1 4.325 1.406 1 1 0 1 1-1.174 1.62 5.37 5.37 0 0 0-3.151-1.026m-1.006 4.482a1 1 0 0 1 1-1h.011a1 1 0 1 1 0 2h-.01a1 1 0 0 1-1-1"
      clipRule="evenodd"
    />
  </svg>
);
export default IconSmartCloud;
