import * as React from "react";
const IconGrowHelp = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.2 47.4c-1.1-3.5-4.5-5.4-7.8-4.5-4.1 1.2-10 3-13.5 4 1.2-1.9 1.1-3.3.7-4.2-.7-1.8-2.8-2.6-4.5-2.6h-9.7c-1.1 0-2.2-.5-3.2-1.4-.9-.8-2-1.2-3.1-1.2H9.3c-2.8 0-5.1 2.4-5.1 5.4v12.4c0 3 2.4 5.5 5.3 5.5h9.4q1.8 0 3.3-1.2l.7.3c2.6 1.4 5.5 2.2 8.5 2.2 1.8 0 3.6-.3 5.2-.8L54.1 56c4.3-1.8 5-5.6 4.1-8.6M9.5 58.7c-1.8 0-3.3-1.6-3.3-3.5V42.8c0-1.9 1.4-3.4 3.1-3.4h7.8c.7 0 1.3.2 1.8.7q.6.6 1.2.9v17.3c-.4.2-.9.3-1.3.3H9.5zM53.3 54l-17.4 5.3c-1.5.5-3 .7-4.6.7-2.6 0-5.2-.7-7.5-1.9l-1.3-.7-.3-.1V41.9c.4.1.9.2 1.3.2h9.7c1.1 0 2.3.5 2.7 1.4.4 1-.4 2.7-2.4 4.5l-.6.5.4.7c.4.8.4.8 9.5-1.9 4.1-1.2 8.3-2.4 8.3-2.4 2.2-.6 4.6.7 5.3 3.1.3 1.3.7 4.5-3.1 6M44 31.7h.7v3.5c0 .6.4 1 1 1s1-.4 1-1V17h.7c2.7 0 5.8-1.5 8.3-4 3.1-3.1 4.6-7.1 3.8-10.1-.1-.3-.4-.6-.7-.7-3-.9-7 .6-10.1 3.8-2.6 2.6-4.1 5.8-4 8.6v8.5c-.6-.8-1.2-1.7-2-2.4-3.1-3.1-7.1-4.6-10.1-3.8-.3.1-.6.4-.7.7-.9 3 .6 7 3.8 10.1 2.5 2.5 5.6 4 8.3 4m6.2-24.3c2.3-2.3 5.4-3.7 7.6-3.4.3 2.2-1 5.3-3.4 7.6C52 14 49 15.3 46.8 15c-.3-2.2 1-5.2 3.4-7.6M33.7 18.7c2.2-.3 5.3 1 7.6 3.4 2.3 2.3 3.7 5.4 3.4 7.6-2.2.3-5.3-1-7.6-3.4s-3.7-5.4-3.4-7.6" />
  </svg>
);
export default IconGrowHelp;
