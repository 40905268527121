import * as React from "react";
const IconConsultingCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M26.1 23.6c0 3.4 2.8 6.2 6.2 6.2s6.2-2.8 6.2-6.2-2.8-6.2-6.2-6.2-6.2 2.7-6.2 6.2m10.5 0c0 2.3-1.9 4.2-4.2 4.2s-4.2-1.9-4.2-4.2 1.9-4.2 4.2-4.2c2.3-.1 4.2 1.8 4.2 4.2" />
    <path d="M8.2 28.1c.2 0 .4-.1.6-.2l7.5-5.1c.5-.3.6-.9.3-1.4s-.9-.6-1.4-.3L9.4 25c2.7-10.5 12.1-17.9 23-17.9s20.5 7.4 23.2 18.1c.1.5.7.9 1.2.7.5-.1.9-.7.7-1.2-3-11.4-13.3-19.5-25.1-19.5-11.7 0-21.8 7.8-24.9 19L4 18.6c-.3-.5-.9-.6-1.4-.3s-.6.9-.3 1.4l5 7.9c.2.2.4.4.7.4.1.1.1.1.2.1M61.1 47l-3.4-8.7c-.1-.3-.3-.5-.5-.6-.3-.1-.5-.1-.8 0L48 41.3c-.5.2-.7.8-.5 1.3s.8.7 1.3.5l6.1-2.7c-2 5.9-6.2 10.7-11.4 13.5v-11c0-4.6-3.4-8.3-7.5-8.3h-7.7c-4.2 0-7.5 3.7-7.5 8.4v10.8a23.5 23.5 0 0 1-11-13.3c-.2-.5-.7-.8-1.3-.6-.5.1-.8.6-.6 1.2 3.5 10.6 13.3 17.7 24.5 17.7 11 0 20.8-7 24.4-17.4l2.5 6.3q.3.6.9.6c.1 0 .2 0 .4-.1.5-.1.7-.7.5-1.2m-38.3 7.8V42.9c0-3.5 2.5-6.4 5.5-6.4h2.8v7.9c0 .6.4 1 1 1s1-.4 1-1v-7.9H36c3 0 5.5 2.8 5.5 6.3V55c-2.8 1.2-5.9 1.8-9.2 1.8s-6.6-.7-9.5-2" />
  </svg>
);
export default IconConsultingCopy;
