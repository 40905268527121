import * as React from "react";
const IconBillboardAlt3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 4.4H6c-2.2 0-4 1.8-4 4v33.8c0 2.2 1.8 4 4 4h17.6v12.4c0 .6.4 1 1 1s1-.4 1-1V46.2h12.9v12.4c0 .6.4 1 1 1s1-.4 1-1V46.2H58c2.2 0 4-1.8 4-4V8.4c0-2.2-1.8-4-4-4m2 37.8c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V8.4c0-1.1.9-2 2-2h9V13h-3.5c-.6 0-1 .4-1 1s.4 1 1 1h9.1c.6 0 1-.4 1-1s-.4-1-1-1H17V6.4h14V13h-3.5c-.6 0-1 .4-1 1s.4 1 1 1h9.1c.6 0 1-.4 1-1s-.4-1-1-1H33V6.4h14V13h-3.5c-.6 0-1 .4-1 1s.4 1 1 1h9.1c.6 0 1-.4 1-1s-.4-1-1-1H49V6.4h9c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconBillboardAlt3;
