import * as React from "react";
const IconYinYang = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2M4 32C4 16.6 16.6 4 32 4q1.95 0 3.9.3L30.1 8c-4.4 2.8-6.8 7.6-6.5 12.9.4 5.2 3.4 9.6 8.2 11.8 4.3 2 7.1 6.2 7.1 11 0 4.1-2 7.7-5.4 10l-8.3 5.5C13.1 56.1 4 45.1 4 32m28 28c-1.3 0-2.7-.1-4-.3l6.6-4.4c4-2.7 6.3-6.9 6.3-11.7 0-5.6-3.1-10.5-8.2-12.8-4.1-1.9-6.7-5.7-7-10.1-.3-4.5 1.8-8.6 5.5-11l7.5-4.8C50.9 7.8 60 18.9 60 32c0 15.4-12.6 28-28 28" />
    <path d="M26.9 46.4c0-2.6-2.1-4.7-4.7-4.7s-4.7 2.1-4.7 4.7 2.1 4.7 4.7 4.7 4.7-2.1 4.7-4.7m-7.5 0c0-1.5 1.2-2.7 2.7-2.7s2.7 1.2 2.7 2.7-1.2 2.7-2.7 2.7-2.7-1.2-2.7-2.7M38.9 13.6c-2.6 0-4.7 2.1-4.7 4.7s2.1 4.7 4.7 4.7 4.7-2.1 4.7-4.7-2.1-4.7-4.7-4.7m0 7.5c-1.5 0-2.7-1.2-2.7-2.7s1.2-2.7 2.7-2.7 2.7 1.2 2.7 2.7-1.2 2.7-2.7 2.7" />
  </svg>
);
export default IconYinYang;
