import * as React from "react";
const IconBlender = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M48.8 9.9h-4.9l.6-5.8c0-.3-.1-.6-.3-.8s-.4-.3-.7-.3H11.2c-.3 0-.6.1-.7.3-.2.2-.3.5-.3.8l3.9 39-2.7 11.7c-.3 1.5 0 3 1 4.2s2.4 1.9 3.9 1.9h22.3c1.5 0 3-.7 3.9-1.9s1.3-2.7 1-4.2l-2.7-11.7 1.1-11H49c2.8 0 5-2.2 5-5V14.9c-.2-2.8-2.4-5-5.2-5M42.4 5l-3.7 37.2H15.9l-1.6-16.6h11c.6 0 1-.4 1-1s-.4-1-1-1H14.1l-.8-7.9h11.9c.6 0 1-.4 1-1s-.4-1-1-1H13.1L12.3 5zm-1.6 52.9c-.6.7-1.4 1.1-2.3 1.1H16.2c-.9 0-1.8-.4-2.3-1.1-.6-.7-.8-1.6-.6-2.5l2.6-11.1h23l2.6 11.1c.1.8-.1 1.7-.7 2.5m11-30.8c0 1.7-1.3 3-3 3h-6.9l1.8-18.3h5.1c1.7 0 3 1.3 3 3z" />
    <path d="M27.3 48.6c-1.7 0-3 1.4-3 3s1.4 3 3 3 3-1.4 3-3-1.3-3-3-3m0 4c-.6 0-1-.5-1-1s.5-1 1-1 1 .5 1 1-.4 1-1 1" />
  </svg>
);
export default IconBlender;
