import * as React from "react";
const IconFileUploadAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54 17.5 40.2 3.9c-.5-.6-1.3-.9-2.1-.9h-24c-2.8 0-5 2.2-5 5v48c0 2.8 2.2 5 5 5h35.7c2.8 0 5-2.2 5-5V19.6c.1-.8-.3-1.6-.8-2.1m-2.3.5H39.9V6.4zm1.2 38c0 1.7-1.3 3-3 3H14.1c-1.7 0-3-1.3-3-3V8c0-1.7 1.3-3 3-3h23.8v14c0 .6.4 1 1 1h13.9v36z" />
    <path d="M20.8 47.9V44c0-.6-.4-1-1-1s-1 .4-1 1v3.9c0 1.5 1.2 2.7 2.7 2.7h21.1c1.5 0 2.7-1.2 2.7-2.7V44c0-.6-.4-1-1-1s-1 .4-1 1v3.9c0 .4-.3.7-.7.7H21.4c-.3 0-.6-.3-.6-.7M31.3 26.4 25 32.5c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3l4.6-4.4v10.9c0 .6.4 1 1 1s1-.4 1-1V29.5l4.6 4.5c.4.4 1 .4 1.4 0s.4-1 0-1.4l-6.2-6.1c-.5-.4-1.1-.4-1.5-.1" />
  </svg>
);
export default IconFileUploadAlt;
