import * as React from "react";
const IconChair = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M49 33.2c0-1.7-.7-3.3-1.9-4.4V7.5c0-3-2.5-5.5-5.5-5.5H22.4c-3 0-5.5 2.5-5.5 5.5v21.3c-1.2 1.1-1.9 2.7-1.9 4.4V61c0 .6.4 1 1 1s1-.4 1-1V45.4h7.8V53c0 .6.4 1 1 1s1-.4 1-1v-7.6h10.6V53c0 .6.4 1 1 1s1-.4 1-1v-7.6H47V61c0 .6.4 1 1 1s1-.4 1-1V33.2M18.9 7.5c0-1.9 1.6-3.5 3.5-3.5h19.2c1.9 0 3.5 1.6 3.5 3.5v20.1c-.6-.2-1.3-.4-2.1-.4H21c-.7 0-1.4.1-2.1.4zM47 43.3H17V33.2c0-2.2 1.8-4 4-4h22c2.2 0 4 1.8 4 4z" />
    <path d="M28.2 16.7h7.6c.6 0 1-.4 1-1s-.4-1-1-1h-7.6c-.6 0-1 .4-1 1s.5 1 1 1" />
  </svg>
);
export default IconChair;
