import * as React from "react";
const IconLightStand = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M43.9 16.6 41.4 6.5c-.7-2.6-3-4.5-5.7-4.5h-7.2c-2.7 0-5.1 1.8-5.7 4.5l-2.5 10.1c-.4 1.8-.1 3.6 1.1 5.1 1.1 1.4 2.8 2.3 4.7 2.3H31v36h-3.7c-.6 0-1 .4-1 1s.4 1 1 1h9.4c.6 0 1-.4 1-1s-.4-1-1-1H33V24h5.1c1.8 0 3.5-.8 4.7-2.3 1.1-1.4 1.5-3.3 1.1-5.1m-2.7 3.9c-.7 1-1.9 1.5-3.1 1.5H25.9c-1.2 0-2.3-.5-3.1-1.5-.7-1-1-2.2-.7-3.4L24.6 7c.4-1.7 2-3 3.8-3h7.2c1.8 0 3.4 1.2 3.8 3l2.5 10.1c.3 1.2.1 2.4-.7 3.4" />
  </svg>
);
export default IconLightStand;
