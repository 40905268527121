import * as React from "react";
const IconCalculatorPaper = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M45.5 12.3h-5.4V7.5c0-.3-.1-.5-.3-.7l-4.4-4.4c-.2-.3-.4-.4-.7-.4h-9.9c-.6 0-1 .4-1 1v9.3h-5.4c-3 0-5.4 2.4-5.4 5.4v39c0 3 2.4 5.3 5.4 5.3h27.1c3 0 5.3-2.4 5.3-5.3v-39c.1-3-2.3-5.4-5.3-5.4M25.8 4h8.5l3.9 3.9v11.8H25.8zm23.1 52.7c0 1.8-1.5 3.3-3.3 3.3H18.5c-1.8 0-3.4-1.5-3.4-3.3v-39c0-1.8 1.5-3.4 3.4-3.4h5.4v6.4c0 .6.4 1 1 1h14.3c.6 0 1-.4 1-1v-6.4h5.4c1.8 0 3.3 1.5 3.3 3.4z" />
    <path d="M41.4 28.2H22.6c-.6 0-1 .4-1 1s.4 1 1 1h18.8c.6 0 1-.4 1-1s-.4-1-1-1M25.1 37.6h-.2c-.6 0-1 .4-1 1s.4 1 1 1h.2c.6 0 1-.4 1-1s-.5-1-1-1M25.1 44.8h-.2c-.6 0-1 .4-1 1s.4 1 1 1h.2c.6 0 1-.4 1-1s-.5-1-1-1M32.1 37.6h-.2c-.6 0-1 .4-1 1s.4 1 1 1h.2c.6 0 1-.4 1-1s-.4-1-1-1M32.1 44.8h-.2c-.6 0-1 .4-1 1s.4 1 1 1h.2c.6 0 1-.4 1-1s-.4-1-1-1M39.1 37.6h-.2c-.6 0-1 .4-1 1s.4 1 1 1h.2c.6 0 1-.4 1-1s-.4-1-1-1M39.1 44.8h-.2c-.6 0-1 .4-1 1s.4 1 1 1h.2c.6 0 1-.4 1-1s-.4-1-1-1M25.1 51.9h-.2c-.6 0-1 .4-1 1s.4 1 1 1h.2c.6 0 1-.4 1-1s-.5-1-1-1M32.1 51.9h-.2c-.6 0-1 .4-1 1s.4 1 1 1h.2c.6 0 1-.4 1-1s-.4-1-1-1M39.1 51.9h-.2c-.6 0-1 .4-1 1s.4 1 1 1h.2c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconCalculatorPaper;
