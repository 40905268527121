import * as React from "react";
const IconMountains2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59 45c1.9-4 3-8.4 3-13C62 15.5 48.5 2 32 2S2 15.5 2 32c0 5.2 1.3 10.1 3.7 14.4 0 0 0 .1.1.1C10.9 55.8 20.7 62 32 62c5.5 0 10.6-1.5 15.1-4.1 0 0 .1 0 .1-.1 5.1-2.9 9.2-7.4 11.8-12.8M32 4c15.4 0 28 12.6 28 28 0 3.7-.7 7.3-2.1 10.5L43.3 22.2c-1-1.4-2.5-2.1-4.2-2.1s-3.2.8-4.2 2.1l-6.2 8.5-2-2.8c-1-1.4-2.5-2.1-4.2-2.1s-3.2.8-4.2 2.1L6.7 44.1C5 40.4 4 36.3 4 32 4 16.6 16.6 4 32 4M7.8 46 20 29.1c.6-.8 1.5-1.3 2.5-1.3s1.9.5 2.5 1.3l2.8 3.9L45 56.7c-3.8 2.1-8.3 3.3-13 3.3-10.3 0-19.4-5.6-24.2-14m39 9.7L30 32.5l6.6-9.1c.6-.8 1.5-1.3 2.5-1.3s1.9.5 2.5 1.3L57 44.6c-2.3 4.6-5.8 8.4-10.2 11.1" />
  </svg>
);
export default IconMountains2;
