import * as React from "react";
const IconCloudSlash = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M5 39.3c0-5.8 4.6-10.5 10.6-10.7l-.1-2C8.5 26.8 3 32.4 3 39.3 3 46.4 8.7 51.9 15.9 52h25v-2h-25C9.8 49.9 5 45.2 5 39.3M53.2 49.2c.4-.2.7-.5 1.1-.8 4.3-3.2 6.8-8.1 6.8-13.4 0-4.2-1.6-8.3-4.6-11.5-2.6-2.8-6.3-4.7-10.2-5.2-2-3.4-5.2-5.9-8.8-7.2q-2.4-.9-5.4-.9c-4.9 0-9.6 2.1-12.7 5.8-.3.4-.6.8-.9 1.1L10 9.6c-.4-.4-1-.3-1.4.1s-.3 1 .1 1.4l47.1 43.3c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.3-1-.1-1.4zM20.8 17.5c2.8-3.3 6.9-5.1 11.2-5.1 1.7 0 3.3.3 4.7.8 3.4 1.1 6.2 3.5 8 6.7.2.3.4.5.8.5 3.6.4 7.1 2.1 9.4 4.7 2.6 2.8 4.1 6.4 4.1 10.1 0 4.7-2.2 9-5.9 11.8-.5.3-.9.7-1.4.9L19.9 18.7c.3-.4.6-.9.9-1.2" />
  </svg>
);
export default IconCloudSlash;
