import * as React from "react";
const IconPlaceholderEuro = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2C18.7 2 7.9 12.8 7.9 26.1c0 18.8 22.5 35.1 23.5 35.7.2.1.4.2.6.2s.4-.1.6-.2c1-.7 23.5-16.9 23.5-35.7C56.1 12.8 45.3 2 32 2m0 57.8C28.5 57.1 9.9 42.3 9.9 26.1 9.9 13.9 19.8 4 32 4s22.1 9.9 22.1 22.1c0 16.2-18.6 31-22.1 33.7" />
    <path d="M37.6 16c.6 0 1-.4 1-1s-.4-1-1-1c-5.3 0-10 3.4-11.9 8h-4c-.6 0-1 .4-1 1s.4 1 1 1h3.4c-.3 1-.4 2-.4 3 0 1.4.2 2.7.7 4h-3.7c-.6 0-1 .4-1 1s.4 1 1 1h4.5c2.2 4.1 6.5 7 11.4 7 .6 0 1-.4 1-1s-.4-1-1-1c-3.7 0-7.1-2-9.1-5h6.6c.6 0 1-.4 1-1s-.4-1-1-1h-7.6c-.5-1.2-.8-2.6-.8-4 0-1 .2-2 .4-2.9h8c.6 0 1-.4 1-1s-.4-1-1-1h-7.2c1.8-3.6 5.6-6.1 9.7-6.1" />
  </svg>
);
export default IconPlaceholderEuro;
