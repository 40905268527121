import * as React from "react";
const IconUserCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 35.6c7.8 0 14.2-6.4 14.2-14.2S39.8 7.3 32 7.3s-14.2 6.4-14.2 14.2S24.2 35.6 32 35.6m0-26.3c6.7 0 12.2 5.5 12.2 12.2S38.7 33.7 32 33.7s-12.2-5.5-12.2-12.2c0-6.8 5.5-12.2 12.2-12.2M61.6 55C53.5 48 42.9 44.2 32 44.2S10.5 48 2.4 55c-.4.4-.5 1-.1 1.4s1 .5 1.4.1c7.8-6.6 17.9-10.3 28.4-10.3s20.5 3.7 28.4 10.3c.2.2.4.2.6.2.3 0 .6-.1.8-.4s.2-1-.3-1.3" />
  </svg>
);
export default IconUserCopy;
