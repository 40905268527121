import * as React from "react";
const IconArrowLongDownE = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M43.8 48.3c-.4-.4-1-.4-1.4 0L33 58V15h3.2c1.1 0 2-.9 2-2V4.5c0-1.1-.9-2-2-2h-8.5c-1.1 0-2 .9-2 2V13c0 1.1.9 2 2 2H31v43l-9.4-9.7c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l11.1 11.4c.2.2.4.3.7.3s.5-.1.7-.3l11.1-11.4c.4-.4.4-1 0-1.4m-16-43.8h8.5V13h-8.5z" />
  </svg>
);
export default IconArrowLongDownE;
