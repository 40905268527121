import * as React from "react";
const IconMedicalMask = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M37.7 32.2h-4.8v-4.8c0-.6-.4-1-1-1s-1 .4-1 1v4.8h-4.8c-.6 0-1 .4-1 1s.4 1 1 1h4.8V39c0 .6.4 1 1 1s1-.4 1-1v-4.8h4.8c.6 0 1-.4 1-1 0-.5-.4-1-1-1" />
    <path d="M62 30.1c0-4-3.3-7.3-7.3-7.3H51l-.8-3.2c-.4-1.4-1.2-2.5-2.5-3.2s-2.8-.8-4.2-.4c-9.4 3-18.8 1.1-23.5-.3-1.4-.4-2.8-.2-4 .5s-2.1 1.9-2.4 3.2l-.8 3.4H9.3c-4 0-7.3 3.3-7.3 7.3 0 3.9 3.1 7.1 7 7.3L8.6 39c-.7 2.6.8 5.3 3.3 6.2 7 2.4 13.7 3.3 19.7 3.3 8.6 0 15.8-1.8 20.2-3.3 2.6-.9 4.1-3.6 3.4-6.2l-.4-1.6c4-.1 7.2-3.3 7.2-7.3M9.3 35.4C6.4 35.4 4 33 4 30.1s2.4-5.3 5.3-5.3h2.9L9.5 35.4zm41.9 7.9c-7.4 2.5-22.2 5.7-38.7 0-1.5-.5-2.4-2.2-2-3.8l5-19.5c.2-.9.7-1.6 1.5-2 .5-.3 1-.4 1.6-.4.3 0 .6 0 .9.1 5 1.4 14.8 3.4 24.7.2.9-.3 1.8-.2 2.6.2s1.3 1.1 1.5 2l5 19.4c.4 1.6-.5 3.3-2.1 3.8m3.5-7.9h-.4l-2.8-10.6h3.1c2.9 0 5.3 2.4 5.3 5.3.1 3-2.3 5.3-5.2 5.3" />
  </svg>
);
export default IconMedicalMask;
