import React, { useState, useEffect, useRef } from 'react';
import { useNavigation } from 'context/NavigationContext';
import { useParams } from 'react-router-dom';
import { BringzzButton, BringzzIcon, BringzzBottomDrawer, BringzzInput, BringzzOverlay, BringzzText } from '@bringzz/components';
import CameraStream from 'components/CameraStream';
import CameraModeOverlay from 'components/CameraModeOverlay';
import CameraControlPanel from 'components/CameraControlPanel';

// Main Camera Page Component
const ScanPage = () => {
    const { backPress, navigate } = useNavigation();
    const [failDrawerOpened, setFailDrawerOpened] = useState(false);
    const [idCode, setIdCode] = useState('');
    const { mode, type } = useParams(); // type: pickup/collect, mode: collect/confirm/handover
    const [selectedMode, setSelectedMode] = useState(mode ? mode : 'handover');
    const [selectedOption, setSelectedOption] = useState('QR-Code'); // State to track selected option
    const [detectedText, setDetectedText] = useState('')
    const cameraStreamRef = useRef();

    const handleBackPress = () => {
        // Call stopCamera before navigating back
        if (cameraStreamRef.current) {
            cameraStreamRef.current.stopCamera();
        }
        backPress();  // Then navigate back
    };

    const handleSwitchCamera = () => {
        if (cameraStreamRef.current) {
            cameraStreamRef.current.switchCamera();
        }
    };

    const handleDetectValue = (result) => {
        console.log(result.text);
        setDetectedText(result.text)
    }

    return (
        <div className="fixed inset-0">
            {/* Camera Stream */}
            <CameraStream ref={cameraStreamRef} setDetectedText={handleDetectValue} />

            {/* Mode Overlay */}
            <CameraModeOverlay mode={selectedMode} selectedOption={selectedOption} text={detectedText} />

            {/* Top-left content area */}
            <div className="absolute top-0 left-0 p-5 flex justify-between w-full">
                <BringzzIcon
                    size="16"
                    icon="IconClose"
                    folder="LineIcons"
                    className="cursor-pointer text-white bg-black/80 rounded-full p-3"
                    onClick={handleBackPress}
                />
                <BringzzIcon
                    size="24"
                    icon="IconThunderbolt"
                    folder="LineIcons"
                    className="cursor-pointer text-white bg-black/80 rounded-full p-2"
                    onClick={() => { }}
                />
            </div>

            {/* Bottom-center control area */}
            <CameraControlPanel
                mode={mode}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                onSwitchCamera={handleSwitchCamera}
                navigate={navigate}
            />

            {/* Fail Drawer for entering ID Code */}
            {/* <BringzzBottomDrawer
                isOpen={failDrawerOpened}
                close={() => setFailDrawerOpened(false)}
                title="Enter ID-Code"
            >
                <div className="px-4 py-6">
                    <BringzzInput
                        label="Id-Code"
                        placeholder="ID-Code"
                        onChange={(e, newIsValid, newValue) => setIdCode(newValue)}
                    />
                </div>
                <div className="text-center mb-12 px-4">
                    <BringzzButton
                        className="bg-magic-lilac w-full flex justify-center"
                        onClick={() => handleApply(mode, type, idCode, navigate, backPress)}
                        disabled={idCode.length === 0}
                    >
                        Apply
                    </BringzzButton>
                </div>
            </BringzzBottomDrawer> */}
        </div>
    );
};

// Helper function for handling ID code apply logic
const handleApply = (mode, type, idCode, navigate, backPress) => {
    if (mode === 'handover') {
        navigate(`/matchmaking/${type}/6`);
    } else if (mode === 'confirm' || mode === 'collect') {
        navigate(`/matchmaking/${type}/finished`);
    } else {
        backPress();
    }
};

export default ScanPage;
