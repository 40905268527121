import * as React from "react";
const IconMountains1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 47.3h-2.7L46.2 20.8c-.1-.3-.4-.5-.7-.6q-.45-.15-.9.3l-6.4 6.3-7.1-11.5c-.2-.3-.5-.5-.9-.5-.3 0-.7.2-.9.5L20 30.9l-3.2-.9c-.4-.1-.9.1-1.1.5L6.1 47.3H3c-.6 0-1 .4-1 1s.4 1 1 1h58c.6 0 1-.4 1-1s-.4-1-1-1M30.2 17.6l6.9 11.2c.2.3.4.4.7.5.3 0 .6-.1.8-.3l6.3-6.1L56 47.3H34.3L26.8 33c-.1-.2-.4-.4-.6-.5l-4.1-1.1zM17.1 32.1l8.1 2.3 6.9 13H8.4z" />
  </svg>
);
export default IconMountains1;
