import * as React from "react";
const IconVirusPatient = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M17 18c3.867 0 7-3.133 7-7s-3.133-7-7-7-7 3.133-7 7 3.133 7 7 7m5-7c0 2.763-2.237 5-5 5s-5-2.237-5-5 2.237-5 5-5 5 2.237 5 5"
      clipRule="evenodd"
    />
    <path
      
      d="M6 25.333c0-.472.258-.992.946-1.556s1.692-1.073 2.888-1.498C12.222 21.429 15.108 21 17 21s4.778.428 7.166 1.279q.504.18.959.377.932-.71 1.982-1.247a14.5 14.5 0 0 0-2.27-1.014C22.227 19.465 19.113 19 17 19s-5.227.465-7.837 1.395c-1.304.464-2.548 1.067-3.485 1.836C4.74 22.999 4 24.03 4 25.333V31h16.153q.16-1.03.474-2H6z"
    />
    <path
      
      fillRule="evenodd"
      d="M30 23a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-1v2.07a6.97 6.97 0 0 1 3.192 1.324L38.586 26l-.293-.293a1 1 0 0 1 1.414-1.414l2 2a1 1 0 0 1-1.414 1.414L40 27.414l-1.394 1.394A6.97 6.97 0 0 1 39.93 32H42v-1a1 1 0 1 1 2 0v4a1 1 0 1 1-2 0v-1h-2.07a7 7 0 0 1-.922 2.594L41 38.586l.293-.293a1 1 0 0 1 1.414 1.414l-2 2a1 1 0 0 1-1.414-1.414l.293-.293-1.848-1.847A6.98 6.98 0 0 1 34 39.929V42h1a1 1 0 1 1 0 2h-4a1 1 0 1 1 0-2h1v-2.07a6.97 6.97 0 0 1-3.192-1.324L27.414 40l.293.293a1 1 0 0 1-1.414 1.414l-2-2a1 1 0 0 1 1.414-1.414l.293.293 1.394-1.394A6.97 6.97 0 0 1 26.07 34H24v1a1 1 0 1 1-2 0v-4a1 1 0 1 1 2 0v1h2.07a6.97 6.97 0 0 1 1.324-3.192L26 27.414l-.293.293a1 1 0 0 1-1.414-1.414l2-2a1 1 0 0 1 1.414 1.414l-.293.293 1.394 1.394A6.97 6.97 0 0 1 32 26.07V24h-1a1 1 0 0 1-1-1m-2 10a5 5 0 1 0 10 0 5 5 0 0 0-10 0"
      clipRule="evenodd"
    />
  </svg>
);
export default IconVirusPatient;
