import * as React from "react";
const IconSignRent = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M40.8 17.8c-.6 0-1 .4-1 1v7.3l-4.1-7.7c-.2-.4-.7-.6-1.1-.5s-.8.5-.8 1v11.3c0 .6.4 1 1 1s1-.4 1-1v-7.3l4.1 7.7c.2.3.5.5.9.5h.2c.4-.1.8-.5.8-1V18.8c0-.5-.4-1-1-1M27.8 19.8c.6 0 1-.4 1-1s-.4-1-1-1h-4.5c-.6 0-1 .4-1 1v11.3c0 .6.4 1 1 1h4.5c.6 0 1-.4 1-1s-.4-1-1-1h-3.5v-3.7h1.2c.6 0 1-.4 1-1s-.4-1-1-1h-1.2v-3.7h3.5zM16.3 17.8h-5.9c-.6 0-1 .4-1 1v11.3c0 .6.4 1 1 1s1-.4 1-1v-4.7h.2c0 .1.1.3.2.4l3.7 4.9c.2.3.5.4.8.4.2 0 .4-.1.6-.2.4-.3.5-1 .2-1.4L14 25.4h2.3c.6 0 1-.4 1-1v-5.6c0-.5-.5-1-1-1m-1 5.6h-3.9v-3.6h3.9zM53.6 17.8h-7c-.6 0-1 .4-1 1s.4 1 1 1h2.5v10.3c0 .6.4 1 1 1s1-.4 1-1V19.8h2.5c.6 0 1-.4 1-1s-.4-1-1-1" />
    <path d="M58 11.9H33V3.2c0-.6-.4-1-1-1s-1 .4-1 1v8.7H6c-2.2 0-4 1.8-4 4V33c0 2.2 1.8 4 4 4h25v23.7c0 .6.4 1 1 1s1-.4 1-1V37.1h25c2.2 0 4-1.8 4-4V15.9c0-2.2-1.8-4-4-4m2 21.2c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V15.9c0-1.1.9-2 2-2h52c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconSignRent;
