import * as React from "react";
const IconSpatula = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M26.9 3.2H6.6c-1.9 0-3.5 1.6-3.5 3.5v18.6c0 1.5.9 2.7 2.2 3.3l10.5 4.2v11h-2.1c-1.7 0-3 1.3-3 3V58c0 1.7 1.3 3 3 3h6.1c1.7 0 3-1.3 3-3V46.8c0-1.7-1.3-3-3-3h-2.1v-11l10.5-4.2c1.4-.5 2.2-1.8 2.2-3.3V6.7c.1-1.9-1.5-3.5-3.5-3.5m-6 43.6V58c0 .6-.4 1-1 1h-6.1c-.6 0-1-.4-1-1V46.8c0-.6.4-1 1-1h6.1c.5 0 1 .4 1 1m7.6-21.5c0 .6-.4 1.2-1 1.4L16.8 31 6.1 26.7c-.6-.2-1-.8-1-1.4V6.7c0-.8.7-1.5 1.5-1.5h20.3c.8 0 1.5.7 1.5 1.5v18.6zM59.9 3c-.6 0-1 .4-1 1v12.9c0 5-4.1 9.1-9.1 9.1s-9.1-4.1-9.1-9.1V4c0-.6-.4-1-1-1s-1 .4-1 1v12.9c0 5.8 4.5 10.6 10.1 11.1v15.8h-2.1c-1.7 0-3 1.3-3 3V58c0 1.7 1.3 3 3 3h6.1c1.7 0 3-1.3 3-3V46.8c0-1.7-1.3-3-3-3h-2.1V28c5.7-.5 10.1-5.3 10.1-11.1V4c.1-.6-.4-1-.9-1m-6.1 43.8V58c0 .6-.4 1-1 1h-6.1c-.6 0-1-.4-1-1V46.8c0-.6.4-1 1-1h6.1c.6 0 1 .4 1 1" />
    <path d="M11.7 10.8c-.6 0-1 .4-1 1v7.6c0 .6.4 1 1 1s1-.4 1-1v-7.6c0-.5-.4-1-1-1M21.9 10.8c-.6 0-1 .4-1 1v7.6c0 .6.4 1 1 1s1-.4 1-1v-7.6c0-.5-.5-1-1-1" />
  </svg>
);
export default IconSpatula;
