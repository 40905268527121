import * as React from "react";
const IconWriteCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.9 10.9h-.2c-1.2 0-2.3 0-3.4-.1V8.7c0-3.7-3-6.7-6.7-6.7H15.1c-3.7 0-6.7 3-6.7 6.7v33.9c0 1 .4 2 1.1 2.7l15.2 15.6h.1v.1h.1c.7.6 1.6 1 2.6 1h18.9c2.1 0 3.8-1.7 3.8-3.8v-1.3l5.1-10.3c.1-.1.1-.3.1-.4l.1-33.6c0-.9-.7-1.7-1.6-1.7m-.4 8.4H45v-6.5c2.9-.1 5.6 0 8.5.1zM11.8 44.7h12.9V58zM46.4 60H27.5c-.2 0-.5 0-.7-.1V44.4c0-.9-.7-1.6-1.6-1.6H10.5V8.7c0-2.6 2.1-4.7 4.7-4.7h28.4c2.6 0 4.7 2.1 4.7 4.7v2.1h-3.4c-1 0-1.8.7-1.8 1.7L43 46.1c0 .2 0 .3.1.5l5.2 10.3v1.3c0 1-.9 1.8-1.9 1.8m2.8-5.6L45 45.9V21.3h8.5v24.6z" />
  </svg>
);
export default IconWriteCopy;
