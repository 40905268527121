import * as React from "react";
const IconQuoteAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M24.5 8.6c0-2.2-1.8-4-4-4H6c-2.2 0-4 1.8-4 4v14.5c0 2.2 1.8 4 4 4h16.5v10.7c0 10.8-8.8 19.5-19.5 19.5-.6 0-1 .4-1 1s.4 1 1 1c11.9 0 21.5-9.7 21.5-21.5V8.6m-2 16.5H6c-1.1 0-2-.9-2-2V8.6c0-1.1.9-2 2-2h14.5c1.1 0 2 .9 2 2zM62 8.6c0-2.2-1.8-4-4-4H43.5c-2.2 0-4 1.8-4 4v14.5c0 2.2 1.8 4 4 4H60v10.7c0 10.8-8.8 19.5-19.5 19.5-.6 0-1 .4-1 1s.4 1 1 1c11.9 0 21.5-9.7 21.5-21.5V8.6M41.5 23.1V8.6c0-1.1.9-2 2-2H58c1.1 0 2 .9 2 2v16.5H43.5c-1.1 0-2-.9-2-2" />
  </svg>
);
export default IconQuoteAlt;
