import * as React from "react";
const IconWebsiteBrowser = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 4H6C3.8 4 2 5.8 2 8v48c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V8c0-2.2-1.8-4-4-4M6 6h52c1.1 0 2 .9 2 2v8.9H4V8c0-1.1.9-2 2-2m52 52H6c-1.1 0-2-.9-2-2V18.9h56V56c0 1.1-.9 2-2 2" />
    <path d="M40.4 26.2H23.6c-2.2 0-4 1.8-4 4v7.9c0 2.2 1.8 4 4 4h16.7c2.2 0 4-1.8 4-4v-7.9c.1-2.2-1.7-4-3.9-4m2 12c0 1.1-.9 2-2 2H23.6c-1.1 0-2-.9-2-2v-7.9c0-1.1.9-2 2-2h16.7c1.1 0 2 .9 2 2v7.9zM39.9 49.1H24.1c-.6 0-1 .4-1 1s.4 1 1 1H40c.6 0 1-.4 1-1s-.5-1-1.1-1" />
  </svg>
);
export default IconWebsiteBrowser;
