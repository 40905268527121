import * as React from "react";
const IconUserAlt3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 16.7c-4.8 0-8.8 3.8-8.8 8.5s3.9 8.5 8.8 8.5c4.8 0 8.8-3.8 8.8-8.5s-4-8.5-8.8-8.5m0 15c-3.7 0-6.8-2.9-6.8-6.5s3-6.5 6.8-6.5 6.8 2.9 6.8 6.5-3.1 6.5-6.8 6.5" />
    <path d="M32 3C16 3 3 16 3 32s13 29 29 29 29-13 29-29S48 3 32 3M18.5 55.4v-8.9c0-3.9 3.1-7 7-7h13c3.9 0 7 3.1 7 7v8.9c-4 2.3-8.6 3.6-13.5 3.6s-9.5-1.3-13.5-3.6m29-1.3v-7.6c0-5-4-9-9-9h-13c-5 0-9 4-9 9v7.6C9.6 49.2 5 41.1 5 32 5 17.1 17.1 5 32 5s27 12.1 27 27c0 9.1-4.6 17.2-11.5 22.1" />
  </svg>
);
export default IconUserAlt3;
