import * as React from "react";
const IconImageCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M39.6 30.5c4 0 7.3-3.3 7.3-7.3s-3.3-7.3-7.3-7.3-7.3 3.3-7.3 7.3 3.2 7.3 7.3 7.3m0-12.6c2.9 0 5.3 2.4 5.3 5.3s-2.4 5.3-5.3 5.3-5.3-2.4-5.3-5.3c0-3 2.3-5.3 5.3-5.3" />
    <path d="M52.7 3H11.3c-2.8 0-5 2.2-5 5v48c0 2.8 2.2 5 5 5h41.3c2.8 0 5-2.2 5-5V8c.1-2.8-2.2-5-4.9-5M11.3 5h41.3c1.7 0 3 1.3 3 3v37.6l-9.3-7.7a4.11 4.11 0 0 0-5.4.2L32 47 20.5 36.8c-1.5-1.4-3.8-1.4-5.4 0L8.2 43V8c.1-1.7 1.5-3 3.1-3m41.4 54H11.3c-1.7 0-3-1.3-3-3V45.8l8.2-7.5c.8-.7 1.9-.7 2.7 0l12.1 10.8c.2.2.4.3.7.3s.5-.1.7-.3l9.7-9.6c.7-.7 1.9-.8 2.7-.1l10.6 8.8V56c0 1.7-1.4 3-3 3" />
  </svg>
);
export default IconImageCopy;
