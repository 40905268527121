import * as React from "react";
const IconScalpel = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.7 5.6c-1.9-1.6-4.4-2.4-6.9-2.2S47 4.9 45.4 6.8L23.9 33.6l-21.6 24c-.3.3-.3.6-.3.9s.3.6.6.7c.2.1 3.4 1.4 7.6 1.4 2.3 0 4.9-.4 7.5-1.7 4.6-2.3 7.7-6.8 9.5-13.4l31.7-25.7c2.1-1.8 3.2-4.4 3.2-7.1-.1-2.8-1.3-5.4-3.4-7.1m-42 51.5c-4.6 2.3-9.6 1.4-12 .7l15.5-17.2 4.8 4.6c-1.5 5.9-4.3 10-8.3 11.9m9.6-13.5-4.7-4.4 3.1-3.5 5.2 5zm31.2-25.4L31.4 39.4 26 34.2 46.9 8.1c1.2-1.6 3-2.5 5-2.7s3.9.4 5.4 1.7C59 8.5 60 10.5 60 12.7s-.9 4.2-2.5 5.5" />
  </svg>
);
export default IconScalpel;
