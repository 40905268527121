import * as React from "react";
const IconGameCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M43.2 34.9c-1.3 0-2.3 1-2.3 2.3s1 2.3 2.3 2.3 2.3-1 2.3-2.3c0-1.2-1.1-2.3-2.3-2.3" />
    <path d="M59.4 25.8C57.7 24 55.3 23 52.8 23H33V5.6c0-.6-.4-1-1-1s-1 .4-1 1V23H11.2C6.1 23 2 27.1 2 32.1v18c0 5 4.1 9.1 9.2 9.1 5 0 9.1-3.9 9.2-8.7h23.1c.2 4.9 4.2 8.8 9.2 8.8 5.1 0 9.2-4.1 9.2-9.1v-18c.1-2.4-.8-4.6-2.5-6.4m.6 24.5c0 3.9-3.2 7.1-7.2 7.1s-7.2-3.2-7.2-7.1c0-1-.7-1.7-1.7-1.7H20.2c-1 0-1.7.7-1.7 1.7 0 3.9-3.3 7-7.2 7S4 54.1 4 50.2v-18c0-3.9 3.3-7.1 7.2-7.1h41.6c1.9 0 3.8.8 5.2 2.2 1.3 1.3 2 3.1 2 4.9z" />
    <path d="M20.7 36.3h-3.6v-3.6c0-.6-.4-1-1-1s-1 .4-1 1v3.6h-3.6c-.6 0-1 .4-1 1s.4 1 1 1h3.6v3.5c0 .6.4 1 1 1s1-.4 1-1v-3.5h3.6c.6 0 1-.4 1-1s-.4-1-1-1M52.5 34.9c-1.3 0-2.3 1-2.3 2.3s1 2.3 2.3 2.3 2.3-1 2.3-2.3c0-1.2-1-2.3-2.3-2.3M47.8 34.9c1.3 0 2.3-1 2.3-2.3s-1-2.3-2.3-2.3-2.3 1-2.3 2.3 1 2.3 2.3 2.3M47.8 39.6c-1.3 0-2.3 1-2.3 2.3s1 2.3 2.3 2.3 2.3-1 2.3-2.3c.1-1.3-1-2.3-2.3-2.3" />
  </svg>
);
export default IconGameCopy;
