import * as React from "react";
const IconSpeechBubble28 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.6 31.2-7.5-5.7v-11c0-2.8-2.3-5.1-5.1-5.1H7.1C4.3 9.3 2 11.6 2 14.5v35c0 2.8 2.3 5.1 5.1 5.1H49c2.8 0 5.1-2.3 5.1-5.1V38.3l7.5-5.5c.3-.2.4-.5.4-.8s-.1-.6-.4-.8M52.5 37c-.3.2-.4.5-.4.8v11.7c0 1.7-1.4 3.1-3.1 3.1H7.1c-1.7 0-3.1-1.4-3.1-3.1v-35c0-1.7 1.4-3.1 3.1-3.1H49c1.7 0 3.1 1.4 3.1 3.1v11.6c0 .3.1.6.4.8l6.8 5.1z" />
    <path d="M23.8 23.9h8.4c.6 0 1-.4 1-1s-.4-1-1-1h-8.4c-.6 0-1 .4-1 1s.4 1 1 1M36.6 31.1H19.4c-.6 0-1 .4-1 1s.4 1 1 1h17.2c.6 0 1-.4 1-1s-.5-1-1-1M32.2 40.3h-8.4c-.6 0-1 .4-1 1s.4 1 1 1h8.4c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconSpeechBubble28;
