import * as React from "react";
const IconTable1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62 20c0-6.5-13.2-11.6-30-11.6S2 13.5 2 20c0 6.4 12.7 11.4 29 11.6v17.7c-1 1.3-3.8 4.3-5.8 4.3-.6 0-1 .4-1 1s.4 1 1 1c2.7 0 5.5-2.8 6.8-4.3 1.4 1.6 4.1 4.3 6.8 4.3.6 0 1-.4 1-1s-.4-1-1-1c-2 0-4.7-2.9-5.8-4.3V31.6c16.3-.2 29-5.2 29-11.6M4 20c0-4.7 11.3-9.6 28-9.6s28 5 28 9.6-11.3 9.6-28 9.6c-16.7.1-28-4.9-28-9.6" />
  </svg>
);
export default IconTable1;
