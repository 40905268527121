import * as React from "react";
const IconArrowLongLeftC = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.2 25.8c-3.1 0-5.6 2.2-6.1 5.2h-43l9.7-9.4c.4-.4.4-1 0-1.4s-1-.4-1.4 0L2.9 31.3c-.2.2-.3.4-.3.7s.1.5.3.7l11.4 11.1c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4L6.1 33h43c.5 2.9 3 5.2 6.1 5.2 3.4 0 6.2-2.8 6.2-6.2s-2.8-6.2-6.2-6.2m0 10.4c-2.3 0-4.2-1.9-4.2-4.2s1.9-4.2 4.2-4.2 4.2 1.9 4.2 4.2-1.9 4.2-4.2 4.2" />
  </svg>
);
export default IconArrowLongLeftC;
