import * as React from "react";
const IconRocketEarth = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M35.8 36v-.6l9.1-9.1C55 16.2 56 8.8 55.7 3c0-.5-.4-.9-.9-.9-5.8-.4-13.2.7-23.3 10.8L22.4 22H12c-.3 0-.5.1-.7.3l-6.2 6.1c-.2.2-.3.4-.3.7s.1.5.3.7l7 7c-.8.3-1.6.8-2.2 1.5C7.5 40.7 6.2 49.5 6 50.6c0 .3.1.6.3.8s.4.3.7.3h.1c1-.1 9.9-1.4 12.3-3.9.6-.6 1.1-1.4 1.5-2.2l7 7c.2.2.5.3.7.3.3 0 .5-.1.7-.3l1.2-1.2c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-.5.5L7.2 29.1l5.2-5.1h10.3c.3 0 .5-.1.7-.3l9.4-9.4C41.8 5.2 48.2 3.8 53.7 4c.2 5.5-1.2 11.8-10.2 20.9l-9.4 9.4c-.2.2-.3.4-.3.7v1c0 .6.4 1 1 1s1-.4 1-1M18.1 46.4c-1.3 1.3-6.3 2.5-9.8 3.1.6-3.6 1.8-8.5 3.1-9.8.7-.7 1.5-1.1 2.4-1.3l5.6 5.6c-.2.9-.7 1.7-1.3 2.4M59.2 50.1c0-6.6-5.3-11.9-11.9-11.9s-11.9 5.3-11.9 11.9S40.7 62 47.3 62c3.1 0 5.9-1.2 8.1-3.2l.1-.1c2.2-2.1 3.7-5.2 3.7-8.6m-11.9-9.9c.5 0 1 .1 1.5.1l-1.2 3.8c-.5 1.7-2.3 2.7-4 2.3l-4.8-1.2c1.6-3 4.8-5 8.5-5m-9.9 9.9c0-1.1.2-2.1.5-3l5.2 1.2c.4.1.8.1 1.3.1 2.3 0 4.4-1.5 5.2-3.7l1.2-3.8v-.1c3.8 1.4 6.5 5 6.5 9.3 0 2.3-.8 4.5-2.2 6.2-.7-1.2-1.9-2.1-3.3-2.4-1.8-.4-3.6.1-5 1.3l-4 3.7c-3.3-1.7-5.4-5-5.4-8.8m7.4 9.6 3.3-3.1c.9-.8 2-1.1 3.1-.8s1.9 1 2.3 2c-1.7 1.4-3.9 2.2-6.3 2.2q-1.2 0-2.4-.3" />
  </svg>
);
export default IconRocketEarth;
