import * as React from "react";
const IconMushroomCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.1 15.7C49 7.9 40.9 3 32 3S15 7.9 10.9 15.7C9.1 19 8.2 22.3 8.1 25.8v.2c0 .1 0 .3.1.4 2.1 4.7 8.3 8.3 16.1 9.7l-1.7 16.3c-.2 2.2.5 4.3 1.9 5.9s3.5 2.5 5.7 2.5h3.7c2.2 0 4.3-.9 5.7-2.5s2.2-3.8 1.9-6L39.8 36c7.7-1.5 13.8-5.1 16-9.7.1-.1.1-.3.1-.4-.1-3.5-1.1-7-2.8-10.2m-13.5 37c.2 1.6-.3 3.2-1.4 4.4S35.6 59 34 59h-3.7c-1.6 0-3.1-.7-4.2-1.9a5.48 5.48 0 0 1-1.4-4.4l1.7-16.2c1.7.3 3.6.5 5.6.5s4-.1 5.8-.4zM32 35c-10.2 0-19.3-3.8-21.9-9.1.1-3.2.9-6.2 2.5-9.2C16.4 9.5 23.8 5 32 5s15.6 4.5 19.4 11.7c1.5 2.9 2.4 6 2.5 9.2C51.3 31.2 42.1 35 32 35" />
    <circle cx={21.9} cy={24.4} r={2.2} />
    <circle cx={42.2} cy={24.4} r={2.2} />
    <circle cx={32} cy={14.2} r={2.2} />
  </svg>
);
export default IconMushroomCopy;
