import * as React from "react";
const IconServer3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62 23.6V10.4c0-2.8-2.2-5-5-5H7c-2.8 0-5 2.2-5 5v13.2c0 1.6.8 3.1 2 4-1.2.9-2 2.4-2 4v13.2c0 2.8 2.2 5 5 5h35.3c.6 0 1-.4 1-1s-.4-1-1-1H7c-1.7 0-3-1.3-3-3V31.6c0-1.7 1.3-3 3-3h50c1.7 0 3 1.3 3 3v9.6c0 .6.4 1 1 1s1-.4 1-1v-9.6c0-1.6-.8-3.1-2-4 1.2-.9 2-2.3 2-4m-2 0c0 1.7-1.3 3-3 3H7c-1.7 0-3-1.3-3-3V10.4c0-1.7 1.3-3 3-3h50c1.7 0 3 1.3 3 3z" />
    <path d="M15.6 15.8c-.6 0-1 .4-1 1v.2c0 .6.4 1 1 1s1-.4 1-1v-.2c0-.6-.5-1-1-1M50.8 15.9H44c-.6 0-1 .4-1 1s.4 1 1 1h6.7c.6 0 1-.4 1-1s-.4-1-.9-1M14.6 38v.2c0 .6.4 1 1 1s1-.4 1-1V38c0-.6-.4-1-1-1s-1 .4-1 1M51.8 38c0-.6-.4-1-1-1H44c-.6 0-1 .4-1 1s.4 1 1 1h6.7c.6 0 1.1-.5 1.1-1M58.6 44.5h-9.4c-1.9 0-3.4 1.5-3.4 3.4v6.6c0 1.9 1.5 3.4 3.4 3.4h9.4c1.9 0 3.4-1.5 3.4-3.4v-6.6c0-1.8-1.5-3.4-3.4-3.4m-10.8 3.4c0-.8.6-1.4 1.4-1.4h9.4c.8 0 1.4.6 1.4 1.4v4l-3.1-1.6c-.3-.2-.6-.1-.9 0l-8.2 4.4v-6.8m10.8 8h-8.8l6.7-3.6 3.4 1.7h.1v.4c0 .9-.6 1.5-1.4 1.5" />
    <path d="M50.8 50.3c.6 0 1-.4 1-1v-.1c0-.6-.4-1-1-1s-1 .5-1 1 .4 1.1 1 1.1" />
  </svg>
);
export default IconServer3;
