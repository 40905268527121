import * as React from "react";
const IconDesert2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M10.6 21.6c3.1 0 5.7-2.5 5.7-5.7s-2.5-5.7-5.7-5.7c-3.1 0-5.7 2.5-5.7 5.7s2.6 5.7 5.7 5.7m0-9.4c2 0 3.7 1.6 3.7 3.7s-1.6 3.7-3.7 3.7-3.7-1.6-3.7-3.7 1.7-3.7 3.7-3.7M61.3 41.8c-.3-.1-8.3-2.1-20.4 1.1v-6.5h2.4c2.3 0 4.2-1.9 4.2-4.2v-3.9c0-.6-.4-1-1-1s-1 .4-1 1v3.9c0 1.2-1 2.2-2.2 2.2h-2.4V18.2c0-.6-.4-1-1-1s-1 .4-1 1V30h-4.7C33 30 32 29 32 27.8V24c0-.6-.4-1-1-1s-1 .4-1 1v3.9c0 2.3 1.9 4.2 4.2 4.2h4.7v11.4c-1.9.6-3.8 1.3-5.6 2-17.4-8.1-29.9-4.9-30.5-4.8-.5.1-.8.7-.7 1.2s.7.8 1.2.7c.2 0 17.9-4.5 40.1 10.9.2.1.4.2.6.2.3 0 .6-.1.8-.4.3-.5.2-1.1-.3-1.4-3-2.1-6-3.8-8.8-5.3q2.25-.9 4.5-1.5c12.2-3.5 20.5-1.4 20.6-1.4.5.1 1.1-.2 1.2-.7s-.2-1.1-.7-1.2" />
  </svg>
);
export default IconDesert2;
