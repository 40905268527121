import * as React from "react";
const IconDollarDown = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 34.8c.6 0 1-.4 1-1v-1.1h.9c2.6 0 4.7-2.3 4.7-5.1s-2.1-5.1-4.7-5.1h-3.8c-1.5 0-2.7-1.4-2.7-3.1s1.2-3.1 2.7-3.1h6.1c.6 0 1-.4 1-1s-.4-1-1-1H33v-1.1c0-.6-.4-1-1-1s-1 .4-1 1v1.1h-.9c-2.6 0-4.7 2.3-4.7 5.1s2.1 5.1 4.7 5.1h3.8c1.5 0 2.7 1.4 2.7 3.1s-1.2 3.1-2.7 3.1h-6c-.6 0-1 .4-1 1s.4 1 1 1H31v1.1c0 .6.4 1 1 1" />
    <path d="M12.6 24.4c.6 0 1-.4 1-1C13.6 13.2 21.8 5 32 5s18.4 8.2 18.4 18.4c0 5.6-2.5 10.8-6.8 14.3-.4.3-.5 1-.2 1.4.2.2.5.4.8.4.2 0 .4-.1.6-.2 4.8-3.9 7.6-9.7 7.6-15.8C52.4 12.1 43.3 3 32 3s-20.4 9.1-20.4 20.4c0 .5.4 1 1 1M48.9 48.9c-.6 0-1 .4-1 1v7.3l-12.6-8.6c-.7-.5-1.6-.7-2.4-.7H21.1c-.7 0-1.3-.3-1.7-.7l-6.1-6.3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l6 6.2c.8.9 2 1.4 3.2 1.4h11.8c.5 0 .9.1 1.3.4l12.3 8.3h-7.3c-.6 0-1 .4-1 1s.4 1 1 1h9.7c.6 0 1-.4 1-1v-9.7c0-.5-.4-1-1-1" />
  </svg>
);
export default IconDollarDown;
