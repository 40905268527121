import * as React from "react";
const IconAxis = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 53.3v-.4l-5.5-14.3c-.2-.5-.8-.8-1.3-.6s-.8.8-.6 1.3l4.6 12-25.2-12V7.4l8.6 9.1c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4L32.6 4.2c-.4-.4-1.1-.4-1.5 0L20.8 15.1c-.4.4-.4 1 0 1.4s1 .4 1.4 0l8.6-9.1v31.9L5.8 51l4.8-11.9c.2-.5 0-1.1-.6-1.3-.5-.2-1.1 0-1.3.6L3.1 52.6v.5c0 .1 0 .2.1.2 0 .1.1.2.2.3l.2.2 13.5 6.1c.1.1.3.1.4.1q.6 0 .9-.6c.2-.5 0-1.1-.5-1.3L6.4 52.9 31.9 41l25.7 12.2-11.5 5c-.5.2-.7.8-.5 1.3q.3.6.9.6c.1 0 .3 0 .4-.1l13.6-5.9c.1 0 .2-.1.2-.2h.1c.1-.1.1-.2.2-.3 0-.1 0-.2.1-.2-.1 0-.1-.1-.1-.1" />
  </svg>
);
export default IconAxis;
