import * as React from "react";
const IconGraphAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 52.3h-1.9V28.2c0-1.7-1.3-3-3-3h-5.7c-1.7 0-3 1.3-3 3v24.2H38V38.1c0-1.7-1.3-3-3-3h-5.7c-1.7 0-3 1.3-3 3v14.2h-9.4V12.7c0-1.7-1.3-3-3-3h-6c-1.7 0-3 1.3-3 3v39.7H3c-.6 0-1 .4-1 1s.4 1 1 1h58c.6 0 1-.4 1-1s-.4-1.1-1-1.1m-54.1 0V12.7c0-.6.4-1 1-1h5.7c.6 0 1 .4 1 1v39.7H6.9zm21.2 0V38.1c0-.6.4-1 1-1h5.7c.6 0 1 .4 1 1v14.2zm21.2 0V28.2c0-.6.4-1 1-1H56c.6 0 1 .4 1 1v24.2h-7.7z" />
  </svg>
);
export default IconGraphAlt2;
