import * as React from "react";
const IconSpeechBubble23 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.5 12.5h-5.1V4.4c0-1-.6-1.8-1.5-2.2s-1.8-.2-2.5.5l-9.8 9.8H21.1c-2.8 0-5.1 2.3-5.1 5.1v5.2H7.5c-2.8 0-5.1 2.3-5.1 5.1v18.6c0 2.8 2.3 5.1 5.1 5.1h5.1v8.1c0 1 .6 1.8 1.5 2.2.3.1.6.2.9.2.6 0 1.2-.2 1.7-.7l9.8-9.8h16.4c2.8 0 5.1-2.3 5.1-5.1v-5.2h8.5c2.8 0 5.1-2.3 5.1-5.1V17.6c0-2.8-2.3-5.1-5.1-5.1M45.9 46.4c0 1.7-1.4 3.1-3.1 3.1H26c-.3 0-.5.1-.7.3L15.2 59.9c-.1.1-.3.1-.4.1s-.2-.1-.2-.3v-9.1c0-.6-.4-1-1-1H7.5c-1.7 0-3.1-1.4-3.1-3.1V27.8c0-1.7 1.4-3.1 3.1-3.1h35.3c1.7 0 3.1 1.4 3.1 3.1v11.8c0 .1 0 .2.1.3 0 .1-.1.2-.1.3zm13.7-10.2c0 1.7-1.4 3.1-3.1 3.1H48V27.8c0-2.8-2.3-5.1-5.1-5.1H18.1v-5.2c0-1.7 1.4-3.1 3.1-3.1H38c.3 0 .5-.1.7-.3l10.1-10c.1-.1.3-.1.4-.1s.2.1.2.3v9.1c0 .6.4 1 1 1h6.1c1.7 0 3.1 1.4 3.1 3.1z" />
    <path d="M14.4 35.4h-.1c-1.1 0-1.9.9-1.9 2s.9 2 2 2 2-.9 2-2-.9-2-2-2M25.3 35.5h-.1c-1.1 0-1.9.9-1.9 2s.9 2 2 2 2-.9 2-2-.9-2-2-2M36.1 35.5H36c-1.1 0-1.9.9-1.9 2s.9 2 2 2 2-.9 2-2-.9-2-2-2" />
  </svg>
);
export default IconSpeechBubble23;
