import * as React from "react";
const IconSharing = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.3 41.9c-1.2-3.8-4.9-5.9-8.5-4.9-4.8 1.4-11.3 3.4-15.1 4.4 1.4-2.1 1.3-3.7.9-4.8-.8-1.9-3-2.8-4.9-2.8H23.1c-1.2 0-2.4-.5-3.6-1.6-.9-.9-2.1-1.3-3.4-1.3H7.6C4.5 31 2 33.6 2 36.8v13.6c0 3.3 2.6 6 5.7 6H18c1.3 0 2.5-.5 3.6-1.4l.8.4c2.8 1.6 6 2.4 9.3 2.4q3 0 5.7-.9L56.6 51c4.9-1.7 5.6-5.8 4.7-9.1M7.7 54.4c-2 0-3.7-1.8-3.7-4V36.8C4 34.7 5.6 33 7.6 33h8.6c.8 0 1.5.3 2 .8.5.4.9.8 1.4 1.1V54c-.5.2-1 .4-1.6.4zM56 49.2l-19.2 5.9c-1.6.5-3.3.8-5.1.8-2.9 0-5.8-.7-8.3-2.1l-1.5-.8-.4-.1V35.7c.5.1 1 .2 1.5.2h10.6c1.2 0 2.6.5 3 1.6.5 1.2-.5 3-2.6 5.1l-.6.5.4.7c.4.8.4.8 10.3-2.1 4.6-1.3 9.1-2.7 9.1-2.7 2.5-.7 5.2.8 6 3.5.6 1.5 1.1 5.1-3.2 6.7M61 16.1h-8.9v-9c0-.6-.4-1-1-1s-1 .4-1 1V16h-8.9c-.6 0-1 .4-1 1s.4 1 1 1h8.9v9c0 .6.4 1 1 1s1-.4 1-1v-8.9H61c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconSharing;
