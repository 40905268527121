import * as React from "react";
const IconCream1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.1 17h-2.4V6c0-2.2-1.8-4-4-4H14.3c-2.2 0-4 1.8-4 4v11H7.9c-2.2 0-4 1.8-4 4v37c0 2.2 1.8 4 4 4h48.3c2.2 0 4-1.8 4-4V21c-.1-2.3-1.9-4-4.1-4M12.3 6c0-1.1.9-2 2-2h35.4c1.1 0 2 .9 2 2v11H12.3zM5.9 31.9h20.7c1.1 0 2 .9 2 2v11.2c0 1.1-.9 2-2 2H5.9zM58.1 58c0 1.1-.9 2-2 2H7.9c-1.1 0-2-.9-2-2v-8.9h20.7c2.2 0 4-1.8 4-4V33.9c0-2.2-1.8-4-4-4H5.9V21c0-1.1.9-2 2-2h48.2c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconCream1;
