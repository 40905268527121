import * as React from "react";
const IconWhiteboard = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 10.8H43.9l-1.1-8c-.1-.5-.6-.9-1.1-.9-.5.1-.9.6-.9 1.1l1 7.7H22.1l1-7.7c.1-.5-.3-1.1-.9-1.1-.5-.1-1.1.3-1.1.9l-1.1 8H7c-2.8 0-5 2.2-5 5v20.5c0 2.8 2.2 5 5 5h9l-2.6 19.5c-.1.5.3 1.1.9 1.1h.1c.5 0 .9-.4 1-.9L18 41.4h28l2.7 19.8c.1.5.5.9 1 .9h.1c.5-.1.9-.6.9-1.1L48 41.4h9c2.8 0 5-2.2 5-5V15.8c0-2.7-2.2-5-5-5m3 25.6c0 1.7-1.3 3-3 3H7c-1.7 0-3-1.3-3-3V15.8c0-1.7 1.3-3 3-3h50c1.7 0 3 1.3 3 3z" />
  </svg>
);
export default IconWhiteboard;
