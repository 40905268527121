import * as React from "react";
const IconSwimmingPoolAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m58.5 51.5-7 5.1c-1 .7-2.3.7-3.2 0l-4.8-3.5c-1.7-1.2-3.9-1.2-5.6 0L33 56.6c-1 .7-2.3.7-3.2 0L25 53.1c-1.7-1.2-3.9-1.2-5.6 0l-4.8 3.5c-1 .7-2.3.7-3.2 0l-7-5.1c-.4-.3-1.1-.2-1.4.2s-.2 1.1.2 1.4l7 5.1c1.7 1.2 3.9 1.2 5.6 0l4.8-3.5c1-.7 2.3-.7 3.2 0l4.8 3.5c1.7 1.2 3.9 1.2 5.6 0l4.8-3.5c1-.7 2.3-.7 3.2 0l4.8 3.5c.8.6 1.8.9 2.8.9s1.9-.3 2.8-.9l7-5.1c.4-.3.5-.9.2-1.4s-.9-.5-1.3-.2M61 19.2H40.8v-5c0-5.2-4.2-9.4-9.4-9.4S22 9.1 22 14.3v5H3c-.6 0-1 .4-1 1s.4 1 1 1h19v18.5c-.9 0-1.9.3-2.6.9l-4.8 3.5c-1 .7-2.3.7-3.2 0l-7-5.1c-.4-.3-1.1-.2-1.4.2s-.2 1.1.2 1.4l7 5.1c1.7 1.2 3.9 1.2 5.6 0l4.8-3.5c1-.7 2.3-.7 3.2 0l4.8 3.5c1.7 1.2 3.9 1.2 5.6 0l4.8-3.5c1-.7 2.3-.7 3.2 0l4.8 3.5c.8.6 1.8.9 2.8.9s1.9-.3 2.8-.9l7-5.1c.4-.3.5-.9.2-1.4s-.9-.5-1.4-.2l-7 5.1c-1 .7-2.3.7-3.2 0l-4.8-3.5c-.8-.6-1.7-.9-2.6-.9V21.2H61c.6 0 1-.4 1-1s-.4-1-1-1m-37-4.9c0-4.1 3.3-7.4 7.4-7.4s7.4 3.3 7.4 7.4v5H24zM38.8 34H24v-5.4h14.7V34zM33 44.1c-1 .7-2.3.7-3.2 0L25 40.6c-.3-.2-.6-.4-.9-.5V36h14.7v4.1c-.3.1-.6.3-.9.5zm5.8-17.5H24v-5.4h14.7v5.4z" />
  </svg>
);
export default IconSwimmingPoolAlt;
