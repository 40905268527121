import * as React from "react";
const IconDropperAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M39.7 12.2h-2.3V7.5c0-1.5-.6-2.8-1.6-3.9-1-1-2.4-1.6-3.9-1.6-3 0-5.5 2.5-5.5 5.5v4.7h-2.3c-2.3 0-4.2 1.9-4.2 4.2v2.4c0 2.3 1.9 4.2 4.2 4.2h2.3v33.5c0 3 2.5 5.5 5.5 5.5s5.5-2.5 5.5-5.5V23.1h2.3c2.3 0 4.2-1.9 4.2-4.2v-2.4c.1-2.4-1.8-4.3-4.2-4.3M28.5 7.5C28.5 5.6 30.1 4 32 4c.9 0 1.8.4 2.5 1 .7.7 1 1.5 1 2.5v4.7h-6.9V7.5zm7 49c0 1.9-1.6 3.5-3.5 3.5s-3.5-1.6-3.5-3.5V23.1h6.9v33.4zM42 18.8c0 1.2-1 2.2-2.2 2.2H24.3c-1.2 0-2.2-1-2.2-2.2v-2.4c0-1.2 1-2.2 2.2-2.2h15.5c1.2 0 2.2 1 2.2 2.2z" />
  </svg>
);
export default IconDropperAlt2;
