import * as React from "react";
const IconCheckBoxCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.7 8.9c-.4-.4-1-.4-1.4 0l-6 6v-5c0-2.2-1.8-4-4-4H6c-2.2 0-4 1.8-4 4v44.3c0 2.2 1.8 4 4 4h44.3c2.2 0 4-1.8 4-4V17.8l7.4-7.5c.4-.4.4-1 0-1.4m-9.4 45.2c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V9.9c0-1.1.9-2 2-2h44.3c1.1 0 2 .9 2 2V17L40.4 29 33 21.5c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l8.1 8.1c.1.1.1.1.2.1l.1.1c.1 0 .2.1.4.1s.3 0 .4-.1c.1 0 .1-.1.1-.1.1 0 .1-.1.2-.1l11.2-11.2z" />
  </svg>
);
export default IconCheckBoxCopy;
