import React, { useState } from 'react';
import BringzzText from '../BringzzText';

const BringzzDropdown = ({ options, defaultValue, onChange, className, label }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (value) => {
    setSelectedValue(value);
    setIsOpen(false);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <div className={`relative inline-block w-full ${className}`}>
      {label && (
        <div className="flex items-center">
          <label>
            <BringzzText tag="h4" className="font-bold">
              {label}
            </BringzzText>
          </label>
        </div>
      )}
      <div
        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        onClick={handleToggle}
      >
        {options.find(option => option.value === selectedValue)?.label || 'Select an option'}
      </div>
      {isOpen && (
        <ul className="absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg max-h-48 overflow-auto">
          {options.map((option, index) => (
            <li
              key={index}
              className="cursor-pointer px-4 py-2 hover:bg-gray-200"
              onClick={() => handleSelect(option.value)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default BringzzDropdown;