import * as React from "react";
const IconOfficeCabinet = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M47 2H17c-2.2 0-4 1.8-4 4v55c0 .6.4 1 1 1s1-.4 1-1v-5.3h34V61c0 .6.4 1 1 1s1-.4 1-1V6c0-2.2-1.8-4-4-4M15 21.8c.1 0 .2.1.3.1h33.4c.1 0 .2 0 .3-.1v15c-.1 0-.2-.1-.3-.1H15.3c-.1 0-.2 0-.3.1zM17 4h30c1.1 0 2 .9 2 2v13.9c-.1 0-.2-.1-.3-.1H15.3c-.1 0-.2 0-.3.1V6c0-1.1.9-2 2-2m-2 34.7c.1 0 .2.1.3.1h33.4c.1 0 .2 0 .3-.1v15H15z" />
    <path d="M31.6 12.8h.7c.6 0 1-.4 1-1s-.4-1-1-1h-.7c-.6 0-1 .4-1 1s.5 1 1 1M31.6 30.1h.7c.6 0 1-.4 1-1s-.4-1-1-1h-.7c-.6 0-1 .4-1 1s.5 1 1 1M32.4 44.9h-.7c-.6 0-1 .4-1 1s.4 1 1 1h.7c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconOfficeCabinet;
