import * as React from "react";
const IconMagnetAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.9 2.8c-3.3 0-6.1 2.7-6.1 6.1v21.5c0 9.9-7.5 17.9-17 18.4-4.9.2-9.6-1.5-13.2-4.9s-5.5-8-5.5-12.9V9c0-3.3-2.7-6.1-6.1-6.1s-6 2.6-6 6v22C2 47.2 15 61 30.9 61.6H32c7.8 0 15.2-3 20.8-8.4 5.9-5.7 9.2-13.4 9.2-21.6V8.9c0-3.4-2.7-6.1-6.1-6.1m0 2c2.2 0 4.1 1.8 4.1 4.1v6h-8.1v-6c0-2.3 1.8-4.1 4-4.1m-47.8 0c2.2 0 4.1 1.8 4.1 4.1v6H4v-6c0-2.3 1.8-4.1 4.1-4.1M4 30.9v-14h8.1v14c0 5.5 2.2 10.6 6.1 14.4 3.5 3.3 8 5.2 12.7 5.5v8.9C16.1 59 4 46.2 4 30.9m47.4 20.8c-5 4.9-11.5 7.6-18.4 7.8v-8.8c10.6-.5 18.9-9.5 18.9-20.4V16.9H60v14.7c0 7.7-3 14.8-8.6 20.1" />
    <path d="M29.4 27.8c.3 0 .6-.1.8-.4l4.5-5.4c1.7-2.1 1.6-5.1-.3-7l-1.6-1.6c-1.2-1.2-1.2-3.1-.1-4.4l4.4-4.9c.4-.4.3-1-.1-1.4s-1-.3-1.4.1l-4.4 4.9c-1.8 2-1.7 5.2.2 7.1l1.6 1.6c1.2 1.2 1.2 3.1.2 4.3l-4.5 5.4c-.4.4-.3 1.1.1 1.4q.3.3.6.3" />
  </svg>
);
export default IconMagnetAlt;
