import * as React from "react";
const IconZoomOutCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.5 53.1 45.1 36.8c-.7-.7-1.9-.7-2.6 0L40.3 39l-4.7-4.7c3.2-3.6 5-8.1 5-12.9 0-5.2-2-10-5.7-13.7-3.6-3.7-8.4-5.8-13.6-5.8s-10 2-13.7 5.7C4 11.3 1.9 16.1 1.9 21.3s2 10 5.7 13.7 8.5 5.7 13.7 5.7c4.8 0 9.4-1.8 12.9-5l4.7 4.7-2.2 2.2c-.7.7-.7 1.9 0 2.6l16.4 16.4c.4.4.8.5 1.3.5s1-.2 1.3-.5l5.7-5.7c.8-.9.8-2 .1-2.8M21.3 38.7c-4.6 0-9-1.8-12.3-5.1S3.9 26 3.9 21.3 5.8 12.3 9 9s7.6-5.1 12.3-5.1 9 1.8 12.3 5.1 5.1 7.6 5.1 12.3-1.8 9-5.1 12.3-7.7 5.1-12.3 5.1M54.5 60 38.3 43.8l5.5-5.5L60 54.5z" />
    <path d="M26.6 20.3H16.1c-.6 0-1 .4-1 1s.4 1 1 1h10.5c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconZoomOutCopy;
