import * as React from "react";
const IconRocketTarget = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M46.5 6.6c0-.5-.4-.9-.9-.9-5.2-.3-11.6.5-20.5 9.4L17.3 23H8.4c-.3 0-.5.1-.7.3l-5.4 5.3c-.2.1-.3.4-.3.7s.1.5.3.7l6 6q-1.05.45-1.8 1.2C4.3 39.3 3.2 47.1 3 48c0 .3.1.6.3.8s.5.3.7.3h.1c.9-.1 8.6-1.3 10.8-3.4q.75-.75 1.2-1.8l6 6c.2.2.5.3.7.3.3 0 .5-.1.7-.3l1-1c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-.3.3L4.4 29.3 8.8 25h8.9c.3 0 .5-.1.7-.3l8.1-8.1c7.8-7.8 13.3-9 18-8.9.1 4.7-1.1 10.2-8.9 18l-8.1 8.2c-.2.2-.3.4-.3.7v.9c0 .6.4 1 1 1s1-.4 1-1V35l7.8-7.9c8.9-9 9.8-15.4 9.5-20.5m-33 37.7c-1.1 1.1-5.2 2.1-8.3 2.6.5-3.1 1.5-7.2 2.6-8.3.5-.5 1.2-.9 2-1.1l4.8 4.8c-.2.7-.5 1.4-1.1 2M50.6 46.4c-.6 0-1 .4-1 1 0 5-4 9-9 9s-9-4-9-9 4-9 9-9c.9 0 1.7.1 2.6.4.5.2 1.1-.1 1.2-.7.2-.5-.1-1.1-.7-1.2-1-.3-2.1-.5-3.1-.5-6.1 0-11 4.9-11 11s4.9 11 11 11 11-4.9 11-11c0-.6-.4-1-1-1" />
    <path d="M61 34.2h-5.4v-4.8c0-.6-.4-1-1-1s-1 .4-1 1v5.3L41 45.8c-.4.4-.5 1-.1 1.4.2.2.5.3.7.3s.5-.1.7-.3l12.5-11.1H61c.6 0 1-.4 1-1s-.4-.9-1-.9M37.6 47.4c0-.6-.4-1-1-1s-1 .4-1 1c0 2.8 2.2 5 5 5 .6 0 1-.4 1-1s-.4-1-1-1c-1.6 0-3-1.4-3-3" />
  </svg>
);
export default IconRocketTarget;
