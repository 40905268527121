import * as React from "react";
const IconScythe1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M15 62c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l30.2-28.7c-12-11.2-26.6-19-32.5-19-.8 0-1.5.1-1.8.4-.9.8-2 1.1-3 .8-1-.4-1.7-1.2-2-2.2-.2-1 .1-2 .8-2.7C11.8 3.2 17.4 2 24 2c10.3 0 27.4 10.4 33.7 15.2.7.5 1.1 1.3 1.2 2.2 0 .9-.3 1.7-.9 2.3l-42.2 40c-.3.2-.6.3-.8.3m-3-51.4c6.4 0 21.4 7.9 34 19.7l10.5-9.9c.2-.2.3-.5.3-.8 0-.2-.1-.5-.4-.7C49.7 13.6 33.2 4 24 4c-7.4 0-12 1.7-16.5 6.2-.2.2-.3.6-.2.9 0 .2.2.5.7.7.3.1.7-.1 1-.4.6-.5 1.6-.8 3-.8" />
  </svg>
);
export default IconScythe1;
