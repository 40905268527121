import * as React from "react";
const IconCarAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m60.3 25.5-10.3-9c-.2-.2-.4-.3-.7-.3H27.8c-.3 0-.5.1-.7.3l-8 8.5-12.8 2.2c-2.5.4-4.3 2.5-4.3 5.1v1.4c0 1.4.5 2.7 1.5 3.6 1 1 2.3 1.5 3.6 1.5H10c-.3.7-.4 1.5-.4 2.3a6.7 6.7 0 0 0 13.4 0c0-.8-.2-1.6-.4-2.3l19.1-.1c-.3.8-.5 1.6-.5 2.4a6.7 6.7 0 0 0 13.4 0c0-.9-.2-1.7-.5-2.5h2.5c2.8 0 5.1-2.3 5.1-5.1v-4c.3-1.6-.3-3-1.4-4m-3.8-.6h-18v-6.7h10.4zm-28.3-6.7h8.3v6.7H21.9zM16.4 45.8c-2.6 0-4.7-2.1-4.7-4.7s2.1-4.7 4.7-4.7 4.7 2.1 4.7 4.7c0 2.5-2.1 4.7-4.7 4.7m31.7 0c-2.6 0-4.7-2.1-4.7-4.7s2.1-4.7 4.7-4.7 4.7 2.1 4.7 4.7c.1 2.5-2.1 4.7-4.7 4.7M60 33.4c0 1.7-1.4 3.1-3.1 3.1h-3.8c-1.2-1.4-3-2.2-5-2.2s-3.8.9-5.1 2.3l-21.5.1c-1.2-1.5-3.1-2.4-5.2-2.4s-4 1-5.2 2.5h-4c-.9 0-1.6-.3-2.2-.9S4 34.5 4 33.7v-1.4c0-1.5 1.1-2.8 2.6-3.1L19.7 27H58c.2 0 .4-.1.5-.2l.3.3c.7.6 1.1 1.5 1.1 2.4v3.9z" />
  </svg>
);
export default IconCarAlt1;
