import * as React from "react";
const IconLemon = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M19.6 61c-1.1 0-2.2-.3-3.1-.9l-.2-.1c-1.2-.5-2.8-1.7-3.4-4.6-.2-.9-.3-2-.5-3.2V52c-.1-1-.3-2.1-.5-3.1-.3-1.3-.6-2.3-1-3.2-2.6-5.2-3.2-14.2 1.2-22.7 3.1-6 9.8-13.7 24.3-16.5h.2c.9 0 1.8-.4 2.5-.7.6-.3 1.3-.7 1.8-1l.4-.2c1.1-.6 1.9-1 2.6-1.3 2-.6 3.6-.6 4.8.1 1 .5 1.8 1.8 2.3 3.7.2.7.2 1.6.2 2.7v.2c0 .7.1 1.5.2 2.3.1.6.2 1.6.7 2.3l.1.1c5.4 13.2 2.4 22.9-1.1 28.7l-.1.1C45.9 51.7 37.8 56 32 56.7c-2 .2-4.4 1.3-6.2 2.1l-.2.1c-1.3.6-2.2 1-3 1.3-1.1.6-2 .8-3 .8m-1.9-2.5c.5.3 1.2.5 2 .5.7 0 1.4-.1 2.1-.4h.1c.8-.3 1.6-.6 2.8-1.2l.3-.1c2-.9 4.5-2 6.8-2.3 5.2-.6 12.8-4.6 17.5-12.2l.1-.1c3.3-5.4 6.2-14.4 1.1-26.9-.6-1.1-.8-2.2-.9-3-.1-.9-.2-1.8-.2-2.5v-.2c0-1.2-.1-1.9-.2-2.3-.4-1.6-1-2.2-1.2-2.4h-.1c-.7-.4-1.8-.4-3.2 0-.5.2-1.2.6-2.2 1.2l-.3.2c-.5.3-1.2.7-1.9 1-.8.4-1.9.8-3.2.9-14 2.5-20.3 9.7-23.2 15.3-4.1 7.8-3.5 16.2-1.2 20.8.5 1 .9 2.1 1.2 3.7.2 1.1.4 2.2.5 3.2v.2c.1 1.2.3 2.2.4 3 .4 1.6 1.2 2.7 2.3 3.2zm15.4-9.1c-.2 0-.5 0-.7-.1-.5-.1-.9-.6-.9-1.1.1-.5.6-.9 1.1-.9.4.1.8 0 1 0 .2-.1.3-.1.3-.2.4-.4 1-.4 1.4 0s.4 1 0 1.4c-.3.3-.7.5-1.1.6-.3.2-.7.3-1.1.3m-9.7-24.6c-.2 0-.3 0-.5-.1-.5-.3-.7-.9-.4-1.3 1.1-2.1 3.5-4.9 8.7-5.9.5-.1 1.1.3 1.2.8s-.3 1.1-.8 1.2c-4.4.9-6.4 3.1-7.3 4.8-.1.3-.5.5-.9.5" />
  </svg>
);
export default IconLemon;
