import * as React from "react";
const IconBitcoinChip = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M40.8 27.8v-.4c-.1-2.9-2.6-5.3-5.6-5.4v-1.7c0-.6-.4-1-1-1s-1 .4-1 1V22h-4v-1.7c0-.6-.4-1-1-1s-1 .4-1 1V22h-3.1c-.6 0-1 .4-1 1s.4 1 1 1h1v15.9h-1c-.6 0-1 .4-1 1s.4 1 1 1h3.1v1.7c0 .6.4 1 1 1s1-.4 1-1v-1.7h4v1.7c0 .6.4 1 1 1s1-.4 1-1v-1.8c3-.1 5.5-2.5 5.6-5.4V36c-.1-1.7-1-3.1-2.3-4.1 1.4-.9 2.3-2.4 2.3-4.1M35.3 33c1.8 0 3.4 1.4 3.5 3.1v.3c-.1 1.9-1.8 3.5-3.8 3.5h-7.7V33zm3.5-5.2c-.1 1.8-1.6 3.2-3.5 3.2h-8.1v-7H35c2.1 0 3.8 1.6 3.8 3.5z" />
    <path d="M61 33c.6 0 1-.4 1-1s-.4-1-1-1h-8.1V20.2H61c.6 0 1-.4 1-1s-.4-1-1-1h-8.1v-3c0-2.2-1.8-4-4-4h-3V3c0-.6-.4-1-1-1s-1 .4-1 1v8.1H33V3c0-.6-.4-1-1-1s-1 .4-1 1v8.1H20.2V3c0-.6-.4-1-1-1s-1 .4-1 1v8.1h-3c-2.2 0-4 1.8-4 4v3H3c-.6 0-1 .4-1 1s.4 1 1 1h8.1V31H3c-.6 0-1 .4-1 1s.4 1 1 1h8.1v10.8H3c-.6 0-1 .4-1 1s.4 1 1 1h8.1v3c0 2.2 1.8 4 4 4h3V61c0 .6.4 1 1 1s1-.4 1-1v-8.1H31V61c0 .6.4 1 1 1s1-.4 1-1v-8.1h10.8V61c0 .6.4 1 1 1s1-.4 1-1v-8.1h3c2.2 0 4-1.8 4-4v-3H61c.6 0 1-.4 1-1s-.4-1-1-1h-8.1V33zM50.9 48.9c0 1.1-.9 2-2 2H15.1c-1.1 0-2-.9-2-2V15.1c0-1.1.9-2 2-2h33.7c1.1 0 2 .9 2 2v33.8z" />
  </svg>
);
export default IconBitcoinChip;
