import * as React from "react";
const IconPawSample = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M23.3 22.6h.4c3.3-.3 5.5-4.4 5.1-9.4-.2-2.3-1-4.4-2.1-6-1.3-1.7-2.9-2.5-4.6-2.4-1.7.2-3.1 1.3-4 3.2-.9 1.7-1.2 4-1 6.2.4 4.8 3.1 8.4 6.2 8.4M19.8 8.9c.6-1.3 1.5-2 2.4-2.1h.2c1.2 0 2.1 1 2.6 1.6.9 1.3 1.6 3 1.7 4.9.3 3.7-1.2 7-3.3 7.2s-4.2-2.8-4.5-6.5c0-1.9.2-3.7.9-5.1M10.9 35.1c.7 0 1.4-.2 1.9-.5 1.3-.7 2-2.2 2-4 0-1.7-.5-3.5-1.5-5.3-1-1.7-2.4-3.1-3.9-3.9-1.6-.9-3.2-.9-4.5-.2s-2 2.2-2 4c0 1.7.5 3.5 1.5 5.3 1 1.7 2.4 3.1 3.9 3.9 1 .4 1.8.7 2.6.7m-4.7-5.6C5.4 28.1 5 26.6 5 25.3c0-.7.2-1.8 1-2.4.3-.2.6-.2.9-.2.6 0 1.3.3 1.6.5 1.2.6 2.3 1.7 3.1 3.1s1.3 2.9 1.2 4.2c0 .7-.2 1.8-1 2.4-.9.5-2 .1-2.6-.2-1.1-.7-2.2-1.8-3-3.2M57.6 21.1c-2.8-.7-5.9 2-6.9 6.2-.5 1.9-.5 3.9 0 5.5.6 1.8 1.6 2.9 3.1 3.3.3.1.6.1.9.1 2.5 0 5.1-2.6 6-6.3 1-4.2-.3-8.1-3.1-8.8m1.1 8.3c-.8 3-2.9 5.2-4.5 4.8-1-.3-1.5-1.3-1.7-2-.4-1.3-.4-2.8 0-4.4.7-2.7 2.5-4.8 4.1-4.8.1 0 .3 0 .4.1 1.7.3 2.5 3.3 1.7 6.3M40.2 21.4c.3.1.6.1.9.1 2.6 0 5.2-2.6 6.2-6.5.5-2 .5-4 0-5.6-.6-1.8-1.7-3-3.1-3.4-2.9-.7-6 2.1-7.1 6.4s.2 8.3 3.1 9M39 12.9c.7-2.9 2.6-5 4.3-5 .2 0 .3 0 .5.1 1 .3 1.5 1.4 1.7 2.1.4 1.3.4 2.9 0 4.5-.8 3.1-3 5.4-4.7 4.9C39 19 38.2 16 39 12.9M50.6 43.5l-2.5-.3c-1.3-.2-2.4-.9-3.2-2.2l-4.3-6.6-.1-.1c-2.1-2.5-5.2-3.9-8.4-3.9s-6.3 1.4-8.4 3.9L19.3 41c-.8 1.2-2 2-3.2 2.2l-2.5.3c-3.4.4-6 3.8-6 7.8 0 4.3 3 7.9 6.8 7.9h35.7c3.7 0 6.8-3.5 6.8-7.9-.3-4-2.9-7.3-6.3-7.8m-.7 13.7H14.2c-2.6 0-4.8-2.6-4.8-5.9 0-3 1.8-5.5 4.3-5.8l2.5-.3c1.8-.2 3.5-1.3 4.7-3l4.3-6.6c1.7-2 4.2-3.1 6.8-3.1s5.1 1.1 6.8 3.1l4.3 6.6c1.1 1.7 2.8 2.8 4.7 3l2.5.3c2.4.3 4.3 2.8 4.3 5.8 0 3.3-2.1 5.9-4.7 5.9" />
  </svg>
);
export default IconPawSample;
