import * as React from "react";
const IconCu = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 513 342"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0-40.8v422.9-211.4z" />
    <path  d="M0 0h513v342H0z" />
    <path
      
      d="M0 0h513v68.3H0zm0 136.5h513v68.3H0zm0 136.6h513v68.3H0z"
    />
    <path  d="M256 170.7 0 342V0z" />
    <path
      
      d="m86.5 111.4 12.7 39.2h41.3l-33.4 24.2 12.8 39.3-33.4-24.2-33.4 24.2 12.8-39.3-33.4-24.2h41.2z"
    />
  </svg>
);
export default IconCu;
