import * as React from "react";
const IconPackage = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m62 21.3-4.5-14c-.8-2.6-3.3-4.4-6-4.4H13.1c-2.7 0-5.1 1.7-6 4.2l-5 14.1c-.2.6-.3 1.2-.3 1.9v30.3c0 4.3 3.5 7.7 7.7 7.7h45.1c4.3 0 7.7-3.5 7.7-7.7V23c0-.5-.1-1.1-.3-1.7M53.2 8.7l3.7 11.6H34.3V7.4h17.2c.7 0 1.5.5 1.7 1.3m-41.9-.1c.3-.7 1-1.2 1.7-1.2h16.7v12.9H7.2zm43.2 48h-45c-1.8 0-3.2-1.4-3.2-3.2V24.8h51.5v28.6c0 1.8-1.5 3.2-3.3 3.2" />
  </svg>
);
export default IconPackage;
