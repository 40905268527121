import * as React from "react";
const IconThermometer = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M40.4 38.4V10.1c0-4.5-3.6-8.1-8.1-8.1h-.6c-4.5 0-8.1 3.6-8.1 8.1v28.3c-3.2 2.6-5 6.6-4.9 10.7.2 6.9 6 12.7 12.9 12.9h.4c3.5 0 6.8-1.3 9.3-3.8 2.6-2.5 4-5.9 4-9.5 0-4-1.8-7.8-4.9-10.3m-.5 18.4c-2.2 2.1-5.1 3.3-8.2 3.2-5.9-.2-10.8-5.1-11-11-.1-3.7 1.6-7.2 4.5-9.4.3-.2.4-.5.4-.8V10.1c0-3.4 2.7-6.1 6.1-6.1h.6c3.4 0 6.1 2.7 6.1 6.1v28.8c0 .3.1.6.4.8 2.9 2.2 4.5 5.5 4.5 9 0 3.1-1.2 6-3.4 8.1" />
    <path d="M33 44.4V18.2c0-.6-.4-1-1-1s-1 .4-1 1v26.2c-2.4.5-4.3 2.6-4.3 5.2 0 2.9 2.4 5.3 5.3 5.3s5.3-2.4 5.3-5.3c0-2.6-1.9-4.7-4.3-5.2m-1 8.4c-1.8 0-3.3-1.5-3.3-3.3s1.5-3.3 3.3-3.3 3.3 1.5 3.3 3.3c0 1.9-1.5 3.3-3.3 3.3" />
  </svg>
);
export default IconThermometer;
