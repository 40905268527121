import * as React from "react";
const IconDoor = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M48.3 2H15.7c-2.8 0-5 2.2-5 5v50c0 2.8 2.2 5 5 5h32.5c2.8 0 5-2.2 5-5V7c.1-2.8-2.2-5-4.9-5m3 55c0 1.7-1.3 3-3 3H15.7c-1.7 0-3-1.3-3-3V7c0-1.7 1.3-3 3-3h32.5c1.7 0 3 1.3 3 3v50z" />
    <path d="M42.9 27c-2.4 0-4.4 2-4.4 4.4s2 4.4 4.4 4.4 4.4-2 4.4-4.4-2-4.4-4.4-4.4m0 6.9c-1.3 0-2.4-1.1-2.4-2.4s1.1-2.4 2.4-2.4 2.4 1.1 2.4 2.4-1.1 2.4-2.4 2.4" />
  </svg>
);
export default IconDoor;
