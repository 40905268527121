import * as React from "react";
const IconHospitalAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.3 19.7H46.2v-14c0-2.1-1.7-3.7-3.7-3.7H21.6c-2.1 0-3.7 1.7-3.7 3.7v14H5.7c-2.1 0-3.7 1.7-3.7 3.7v34.8C2 60.3 3.7 62 5.7 62h52.5c2.1 0 3.7-1.7 3.7-3.7V23.5c.1-2.1-1.6-3.8-3.6-3.8M25.6 59.8v-14c0-.6.4-1 1-1h10.8c.6 0 1 .4 1 1v14zM60 58.3c0 1-.8 1.7-1.7 1.7H40.4V45.8c0-1.7-1.3-3-3-3H26.6c-1.7 0-3 1.3-3 3V60H5.7c-1 0-1.7-.8-1.7-1.7V23.5c0-1 .8-1.7 1.7-1.7h13.1c.6 0 1-.4 1-1v-15c0-1 .8-1.7 1.7-1.7h20.9c1 0 1.7.8 1.7 1.7v15c0 .6.4 1 1 1h13.1c1 0 1.7.8 1.7 1.7v34.8z" />
    <path d="M36.1 14.3H33v-3.1c0-.6-.4-1-1-1s-1 .4-1 1v3.1h-3.1c-.6 0-1 .4-1 1s.4 1 1 1H31v3.1c0 .6.4 1 1 1s1-.4 1-1v-3.1h3.1c.6 0 1-.4 1-1s-.4-1-1-1M16.7 31h-5c-.6 0-1 .4-1 1s.4 1 1 1h5c.6 0 1-.4 1-1s-.5-1-1-1M34.5 31h-5c-.6 0-1 .4-1 1s.4 1 1 1h5c.6 0 1-.4 1-1s-.4-1-1-1M52.4 31h-5c-.6 0-1 .4-1 1s.4 1 1 1h5c.6 0 1-.4 1-1s-.5-1-1-1M16.7 43.7h-5c-.6 0-1 .4-1 1s.4 1 1 1h5c.6 0 1-.4 1-1s-.5-1-1-1M52.4 43.7h-5c-.6 0-1 .4-1 1s.4 1 1 1h5c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconHospitalAlt1;
