import * as React from "react";
const IconBloodDonation = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M45.6 14.6h-5.5v-3.4c0-2.1-1.7-3.7-3.7-3.7H33V3c0-.6-.4-1-1-1s-1 .4-1 1v4.5h-3.4c-2.1 0-3.7 1.7-3.7 3.7v3.4h-5.5c-2.2 0-4 1.8-4 4v22.9c0 6.4 4.6 11.7 10.7 12.8V61c0 .6.4 1 1 1s1-.4 1-1v-6.6h5V57c0 1.2.5 2.3 1.3 3.2.9.9 2 1.3 3.2 1.3 2.5 0 4.5-2 4.5-4.5v-3.5c4.8-1.9 8.3-6.6 8.3-12.1V18.6c.2-2.2-1.6-4-3.8-4m-19.7-3.4c0-.9.8-1.7 1.7-1.7h8.7c1 0 1.7.8 1.7 1.7v3.4H25.9zM39.3 57c0 1.4-1.1 2.5-2.5 2.5-.7 0-1.3-.3-1.8-.7-.5-.5-.7-1.1-.7-1.8v-2.6h2.4q1.35 0 2.7-.3V57zm8.3-15.6c0 6.1-4.9 11-11 11h-9.1c-6.1 0-11-4.9-11-11V18.6c0-1.1.9-2 2-2h27.2c1.1 0 2 .9 2 2v22.8z" />
    <path d="M39.1 32.3H33v-6.1c0-.6-.4-1-1-1s-1 .4-1 1v6.1h-6.1c-.6 0-1 .4-1 1s.4 1 1 1H31v6.1c0 .6.4 1 1 1s1-.4 1-1v-6.1h6.1c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconBloodDonation;
