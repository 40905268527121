import * as React from "react";
const IconRocketMoney = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.5 3c0-.5-.4-.9-.9-.9-5.9-.3-13.3.7-23.4 10.8L24 22H13.6c-.3 0-.5.1-.7.3l-6.2 6.2c-.2.2-.3.4-.3.7s.1.5.3.7l7.1 7.1c-.8.3-1.6.8-2.2 1.5-2.4 2.4-3.7 11.4-3.9 12.4 0 .3.1.6.3.8s.4.3.7.3h.1c1-.1 9.9-1.5 12.4-3.9.7-.7 1.1-1.4 1.5-2.2l7 7c.2.2.5.3.7.3.3 0 .5-.1.7-.3l1.2-1.2c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-.5.5L8.9 29.2l5.2-5.2h10.4c.3 0 .5-.1.7-.3l9.4-9.4C43.6 5.2 50 3.8 55.5 4c.2 5.5-1.2 11.9-10.3 21l-9.4 9.4c-.2.2-.3.4-.3.7v1c0 .6.4 1 1 1s1-.4 1-1v-.6l9.1-9.1C56.9 16.2 57.9 8.8 57.5 3M19.7 46.6c-1.3 1.3-6.3 2.5-9.9 3.1.6-3.6 1.8-8.6 3.1-9.9.7-.7 1.5-1.1 2.4-1.3l5.6 5.6c-.1 1-.5 1.8-1.2 2.5M46.4 43h7.3c.6 0 1-.4 1-1s-.4-1-1-1h-3.5v-2.3c0-.6-.4-1-1-1s-1 .4-1 1V41h-1.8c-2.7 0-4.9 2.2-4.9 4.9s2.2 4.9 4.9 4.9h3.4c1.6 0 2.9 1.3 2.9 2.9s-1.3 2.9-2.9 2.9h-7.3c-.6 0-1 .4-1 1s.4 1 1 1h5.7V61c0 .6.4 1 1 1s1-.4 1-1v-2.4c2.5-.2 4.5-2.3 4.5-4.9 0-2.7-2.2-4.9-4.9-4.9h-3.4c-1.6 0-2.9-1.3-2.9-2.9s1.3-2.9 2.9-2.9" />
  </svg>
);
export default IconRocketMoney;
