export function checkAppTrackingPermission() {
    // TODO: Define tracking
    return Promise.resolve(localStorage.getItem('appTracking') === 'true');
}

export function requestAppTrackingPermission() {

    return new Promise((resolve) => {
        // Simulate user granting permission
        localStorage.setItem('appTracking', 'true');
        resolve(true);
    });
}

export function disableAppTracking() {
    return new Promise((resolve) => {
        localStorage.setItem('appTracking', 'false');
        resolve(false);
    });
}