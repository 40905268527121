import * as React from "react";
const IconPerfume1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M45.4 15.6h-6V6c0-2.2-1.8-4-4-4h-6.8c-2.2 0-4 1.8-4 4v9.6h-6c-2.2 0-4 1.8-4 4V58c0 2.2 1.8 4 4 4h26.8c2.2 0 4-1.8 4-4V19.6c0-2.2-1.8-4-4-4M26.6 6c0-1.1.9-2 2-2h6.8c1.1 0 2 .9 2 2v9.6H26.6zm20.8 52c0 1.1-.9 2-2 2H18.6c-1.1 0-2-.9-2-2V19.6c0-1.1.9-2 2-2h26.8c1.1 0 2 .9 2 2z" />
    <path d="M32 27.9c-3.1 0-5.4 4.4-5.4 10.2s2.3 10.2 5.4 10.2 5.4-4.4 5.4-10.2-2.3-10.2-5.4-10.2m0 18.4c-1.6 0-3.4-3.5-3.4-8.2s1.8-8.2 3.4-8.2 3.4 3.5 3.4 8.2-1.8 8.2-3.4 8.2" />
  </svg>
);
export default IconPerfume1;
