import * as React from "react";
const IconSecureShopping = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 4.1h-8c-.5 0-.9.3-1 .8l-3.3 13.6H35.4c1.2-4.3 1.2-7.7 1.2-8 0-.5-.3-.9-.8-1-3.9-.8-9.7-5-9.8-5q-.6-.45-1.2 0c-.1 0-5.9 4.3-9.8 5-.5.1-.8.5-.8 1 0 .3 0 3.7 1.2 8H3c-.3 0-.6.1-.8.4-.2.2-.2.5-.2.8l5 21.4c.1.5.5.9 1 .9h36.3c.5 0 .9-.3 1-.8l8.5-35.1H61c.6 0 1-.4 1-1s-.4-1-1-1M25.4 6.6c1.5 1.1 5.7 3.8 9.1 4.7-.2 2.9-1.3 13.4-9.1 19-7.8-5.6-9-16.1-9.1-19 3.5-.9 7.6-3.7 9.1-4.7M43.5 40H8.8L4.3 20.5h11.799999999999999c1.4 4.2 4.1 8.8 8.8 11.9.2.1.4.2.6.2s.4-.1.6-.2c4.7-3.1 7.4-7.7 8.8-11.9h13.4zM38.6 49.2h-25c-3 0-5.4 2.4-5.4 5.4s2.4 5.4 5.4 5.4 5.4-2.4 5.4-5.4c0-1.3-.5-2.4-1.2-3.4h16.7c-.8.9-1.2 2.1-1.2 3.4 0 3 2.4 5.4 5.4 5.4s5.4-2.4 5.4-5.4-2.6-5.4-5.5-5.4M17 54.5c0 1.9-1.5 3.4-3.4 3.4s-3.4-1.5-3.4-3.4 1.5-3.4 3.4-3.4 3.4 1.6 3.4 3.4m21.6 3.4c-1.9 0-3.4-1.5-3.4-3.4s1.5-3.4 3.4-3.4 3.4 1.5 3.4 3.4-1.6 3.4-3.4 3.4" />
  </svg>
);
export default IconSecureShopping;
