import * as React from "react";
const IconIcicles = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 62c-1.4 0-2.6-1-2.8-2.4l-2.9-23.9-2.2 9c-.3 1.2-1.4 2.1-2.7 2.1s-2.4-.9-2.6-2.2l-2.3-11.4-4.1 16.2c-.3 1.3-1.5 2.2-2.8 2.1S7.2 50.4 7 49L1.8 8c-.2-1.5.3-3 1.3-4.2S5.5 2 7 2h50c1.5 0 3 .7 4 1.8C62 5 62.4 6.5 62.2 8L57 46.7c-.2 1.3-1.3 2.3-2.6 2.4-1.4.1-2.5-.9-2.8-2.2L49.8 39l-4.6 15.2c-.4 1.2-1.4 2-2.6 2s-2.3-.8-2.6-2L36.5 43l-1.7 16.6c-.2 1.3-1.4 2.4-2.8 2.4m-5.3-32.8q.15 0 0 0c.5 0 .9.4 1 .9l3.5 29.2c0 .4.4.7.8.7s.7-.3.8-.7L35 37.8c0-.5.4-.8.9-.9.5 0 .9.2 1.1.7l4.9 15.9c.1.3.4.5.7.5s.6-.2.7-.5L49 34.8c.1-.4.5-.7 1-.7s.8.3.9.8l2.6 11.7c.1.4.4.6.8.6s.7-.3.7-.7l5.2-38.7c.1-.9-.2-1.9-.8-2.6C58.8 4.4 57.9 4 57 4H7c-.9 0-1.8.4-2.4 1.1s-1 1.7-.8 2.6L9 48.8c0 .4.3.6.7.7.4 0 .7-.2.8-.6l5.1-20.5c.1-.5.5-.8 1-.8s.9.3 1 .8l3.2 15.8c.1.4.4.6.7.6s.7-.2.8-.6L25.8 30c.1-.5.5-.8.9-.8" />
  </svg>
);
export default IconIcicles;
