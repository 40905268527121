import * as React from "react";
const IconSpeechBubble11 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.3 26.6-3.5-5-.1-6.1c0-1.1-.7-2.1-1.8-2.5l-5.7-2.1L47 6c-.6-.9-1.6-1.4-2.6-1.2l-5.9.8-5.1-3.2c-.9-.5-2-.5-2.9 0l-5.1 3.2-5.9-.8c-1-.1-2.1.3-2.6 1.2l-3.2 5.1L8 13.2c-1 .4-1.7 1.3-1.8 2.5l-.1 6.1-3.5 5c-.7.9-.7 2.2 0 3.1l3.5 5 .1 6.1c0 1.1.7 2.1 1.8 2.5l4.4 1.6L10 59.4c-.2 1 .3 2 1.2 2.5q.6.3 1.2.3c.5 0 1.1-.2 1.5-.6l12.3-10.1 4.2 2.6c.9.5 2 .5 2.9 0l5.1-3.2 5.9.8c1 .1 2.1-.3 2.6-1.2l3.2-5.1 5.7-2.1c1-.4 1.7-1.3 1.8-2.5l.1-6.1 3.5-5c.8-.9.8-2.2.1-3.1m-1.6 2L56 33.8c-.1.2-.2.4-.2.6l-.2 6.4c0 .3-.2.5-.5.6l-6 2.2c-.2.1-.4.2-.5.4l-3.4 5.4c-.1.2-.4.3-.7.3l-6.3-.8c-.2 0-.5 0-.7.1l-5.4 3.3c-.2.1-.5.1-.7 0l-4.8-3c-.1-.3-.6-.3-.9 0L12.8 59.9c-.2.2-.4.1-.5 0s-.3-.2-.2-.4l2.6-15.2c.1-.5-.2-.9-.6-1.1l-5.2-1.9c-.3-.1-.4-.3-.5-.6l-.2-6.4c0-.2-.1-.4-.2-.6l-3.7-5.2c-.2-.2-.2-.6 0-.8L8 22.6c.1-.2.2-.4.2-.6l.2-6.4c0-.3.2-.5.5-.6l6-2.2c.2-.1.4-.2.5-.4L18.8 7q.15-.3.6-.3h.1l6.3.8c.2 0 .5 0 .7-.1L31.9 4c.2-.1.5-.1.7 0L38 7.4c.2.1.4.2.7.1l6.3-.8c.3 0 .5.1.7.3l3.4 5.4c.1.2.3.3.5.4l6 2.2c.3.1.4.3.5.6l.2 6.4c0 .2.1.4.2.6l3.7 5.2c-.3.2-.3.5-.5.8" />
    <path d="M40.4 18.6H23.6c-.6 0-1 .4-1 1s.4 1 1 1h16.8c.6 0 1-.4 1-1s-.4-1-1-1M40.4 27.8H23.6c-.6 0-1 .4-1 1s.4 1 1 1h16.8c.6 0 1-.4 1-1s-.4-1-1-1M40.4 36.9H23.6c-.6 0-1 .4-1 1s.4 1 1 1h16.8c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconSpeechBubble11;
