import * as React from "react";
const IconMessageLaptop = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M19.3 23.6h25.6c1.5 0 2.7-1.2 2.7-2.7V5.2c0-1.5-1.2-2.7-2.7-2.7H19.3c-1.5 0-2.7 1.2-2.7 2.7V21c0 1.4 1.2 2.6 2.7 2.6m25.5-2H19.3c-.4 0-.7-.3-.7-.7V5.8L30.5 13c.5.3 1 .4 1.6.4.5 0 1.1-.1 1.6-.4l11.9-7.2V21c-.1.3-.4.6-.8.6M20.4 4.5h23.4l-11.2 6.8c-.3.2-.7.2-1 0z" />
    <path d="m61.9 57.3-3.3-17.8h-.1q.3-.75.3-1.5V15.6c0-1.9-1.6-3.5-3.5-3.5-.6 0-1 .4-1 1s.4 1 1 1c.8 0 1.5.7 1.5 1.5V38c0 .8-.7 1.5-1.5 1.5H8.6c-.8 0-1.5-.7-1.5-1.5V15.6c0-.8.7-1.5 1.5-1.5.6 0 1-.4 1-1s-.4-1-1-1c-1.9 0-3.5 1.6-3.5 3.5V38q0 .75.3 1.5h-.1L2.1 57.3c-.2 1 .1 2.1.8 2.9s1.6 1.2 2.6 1.2h53.1c1 0 2-.5 2.6-1.2.7-.8.9-1.8.7-2.9M59.6 59c-.3.3-.7.5-1.1.5h-53c-.4 0-.8-.2-1.1-.5-.3-.4-.4-.8-.3-1.3l3-16.2H57l3 16.2c.1.4-.1.9-.4 1.3" />
  </svg>
);
export default IconMessageLaptop;
