import React, { useEffect, useState } from 'react';
import {
	BringzzPageContainer,
	BringzzIcon,
	BringzzText,
	BringzzTabs,
	BringzzTab,
	BringzzTabPanel,
	BringzzImage,
	BringzzGallery,
} from '@bringzz/components';
import { useNavigation } from 'context/NavigationContext';
import { useLocation } from 'react-router-dom';
import useRequest from 'hooks/useRequest';
import { useAuth } from 'context/AuthContext';

const Posts = () => {
	const { navigate, backPress } = useNavigation();
	const { sendRequest, loading } = useRequest();
	const [posts, setPosts] = useState([])
	const { user } = useAuth();

	const fetchPosts = async () => {
		await sendRequest({
			method: 'POST',
			url: `/module/post/getPosts`,
			data: { userId: user.id }
		}).then((response) => {
			if (response.status === 'success') {
				console.log(response.data);
				setPosts(response.data)
			}
		}).catch((error) => {
			console.error(error);
		})
	}

	useEffect(() => {
		fetchPosts()
	}, [])

	return (
		<BringzzPageContainer
			header={{
				left: (
					<BringzzIcon
						size='22'
						icon='IconChevronLeft'
						folder='LineIcons'
						className='cursor-pointer'
						onClick={backPress}
					/>
				),
				right: (
					<div className='flex flex-row space-x-4'>
						<BringzzIcon
							size='22'
							icon='IconUpload'
							folder='LineIcons'
							className='cursor-pointer'
						/>
						<BringzzIcon
							size='22'
							icon='IconMore'
							folder='LineIcons'
							className='rotate-90 cursor-pointer'
						/>
					</div>
				),
				title: <BringzzText tag='h2'>Posts</BringzzText>
			}}
		>
			<div className='p-4 space-y-4'>
				<BringzzTabs defaultActiveTab='tab1'>
					<BringzzTab id='tab1'>
						<BringzzText tag='h4' className='font-bold'>
							Published
						</BringzzText>
					</BringzzTab>
					<BringzzTabPanel id='tab1'>
						<BringzzGallery>
							{posts.map(post => post.published ? post : false).filter(Boolean).map((post, index) => (
								<div className='cursor-pointer' onClick={() => navigate(`/settings/posts/detail?id=${post.id}`)} key={index} >
									<BringzzImage className='h-32 w-full' src={post.images[0].content} alt='' />
								</div>
							))}
						</BringzzGallery>
					</BringzzTabPanel>

					<BringzzTab id='tab2'>
						<BringzzText tag='h4' className='font-bold'>
							Unpublished
						</BringzzText>
					</BringzzTab>
					<BringzzTabPanel id='tab2'>
						<BringzzGallery>
							{posts.map(post => !post.published ? post : false).filter(Boolean).map((post, index) => (
								<div className='cursor-pointer' onClick={() => navigate(`/settings/posts/detail?id=${post.id}`)} key={index}>
									<div className='relative'>
										<BringzzImage className='h-32 w-full' src={post.images[0].content} alt='' />
										{!post.published && post.release &&
											<div className='absolute bottom-0 right-0 text-white p-2'>
												<BringzzIcon
													folder='LineIcons'
													icon='IconClockAlt1'
													size='24'
													className='text-white'
												></BringzzIcon>
											</div>}
									</div>
								</div>
							))}
						</BringzzGallery>
					</BringzzTabPanel>
				</BringzzTabs>
			</div>
		</BringzzPageContainer>
	)
};

export default Posts;
