import * as React from "react";
const IconVideoPlayback = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 4.3H6c-2.2 0-4 1.8-4 4v29.8c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V8.3c0-2.2-1.8-4-4-4m2 33.8c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V8.3c0-1.1.9-2 2-2h52c1.1 0 2 .9 2 2z" />
    <path d="M44 22 26 11.4c-.3-.2-.7-.2-1 0s-.5.5-.5.9v21.2c0 .4.2.7.5.9.2.1.3.1.5.1s.4 0 .5-.1l18-10.6c.3-.2.5-.5.5-.9s-.2-.7-.5-.9m-17.5 9.8V14l15 8.9zM58.5 52.4H32.6c-.5-3-3.1-5.3-6.3-5.3s-5.8 2.3-6.3 5.3H5.5c-.6 0-1 .4-1 1s.4 1 1 1h14.6c.5 3 3.1 5.3 6.3 5.3s5.8-2.3 6.3-5.3h25.8c.6 0 1-.4 1-1s-.5-1-1-1m-32.1 5.3c-2.4 0-4.3-1.9-4.3-4.3s1.9-4.3 4.3-4.3 4.3 1.9 4.3 4.3-1.9 4.3-4.3 4.3" />
  </svg>
);
export default IconVideoPlayback;
