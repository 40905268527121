import * as React from "react";
const IconBlisterPillsOvalX16 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      d="M19.179 9.836a1.18 1.18 0 0 0-1.672 0l-1.671 1.671a1.182 1.182 0 1 0 1.671 1.672l1.672-1.672a1.18 1.18 0 0 0 0-1.671M21.84 9.836a1.182 1.182 0 0 1 1.672 1.671l-1.671 1.672a1.182 1.182 0 1 1-1.672-1.672zM27.846 9.836a1.18 1.18 0 0 0-1.672 0l-1.672 1.671a1.182 1.182 0 1 0 1.672 1.672l1.672-1.672a1.18 1.18 0 0 0 0-1.671M30.507 9.836a1.182 1.182 0 1 1 1.672 1.671l-1.672 1.672a1.182 1.182 0 0 1-1.671-1.672zM19.179 15.836a1.18 1.18 0 0 0-1.672 0l-1.671 1.671a1.182 1.182 0 1 0 1.671 1.672l1.672-1.672a1.18 1.18 0 0 0 0-1.671M21.84 15.836a1.182 1.182 0 1 1 1.672 1.671l-1.671 1.672a1.182 1.182 0 1 1-1.672-1.672zM27.846 15.836a1.18 1.18 0 0 0-1.672 0l-1.672 1.671a1.182 1.182 0 1 0 1.672 1.672l1.672-1.672a1.18 1.18 0 0 0 0-1.671M30.507 15.836a1.182 1.182 0 1 1 1.672 1.671l-1.672 1.672a1.182 1.182 0 0 1-1.671-1.672zM19.179 28.836a1.18 1.18 0 0 0-1.672 0l-1.671 1.671a1.182 1.182 0 1 0 1.671 1.672l1.672-1.672a1.18 1.18 0 0 0 0-1.671M21.84 28.836a1.182 1.182 0 1 1 1.672 1.671l-1.671 1.672a1.182 1.182 0 1 1-1.672-1.672zM27.846 28.836a1.18 1.18 0 0 0-1.672 0l-1.672 1.671a1.182 1.182 0 1 0 1.672 1.672l1.672-1.672a1.18 1.18 0 0 0 0-1.671M30.507 28.836a1.182 1.182 0 1 1 1.672 1.671l-1.672 1.672a1.182 1.182 0 0 1-1.671-1.672zM19.179 34.836a1.18 1.18 0 0 0-1.672 0l-1.671 1.671a1.182 1.182 0 1 0 1.671 1.672l1.672-1.672a1.18 1.18 0 0 0 0-1.671M21.84 34.836a1.182 1.182 0 1 1 1.672 1.671l-1.671 1.672a1.182 1.182 0 1 1-1.672-1.672zM27.846 34.836a1.18 1.18 0 0 0-1.672 0l-1.672 1.671a1.182 1.182 0 1 0 1.672 1.672l1.672-1.672a1.18 1.18 0 0 0 0-1.671M30.507 34.836a1.182 1.182 0 1 1 1.672 1.671l-1.672 1.672a1.182 1.182 0 0 1-1.671-1.672zM19 24a1 1 0 1 1-2 0 1 1 0 0 1 2 0M22 25a1 1 0 1 0 0-2 1 1 0 0 0 0 2M27 24a1 1 0 1 1-2 0 1 1 0 0 1 2 0M30 25a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
    />
    <path
      
      fillRule="evenodd"
      d="M12 8a4 4 0 0 1 4-4h16a4 4 0 0 1 4 4v32a4 4 0 0 1-4 4H16a4 4 0 0 1-4-4zm2 0a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v15a1 1 0 1 0 0 2v15a2 2 0 0 1-2 2H16a2 2 0 0 1-2-2V25a1 1 0 1 0 0-2z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconBlisterPillsOvalX16;
