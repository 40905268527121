import * as React from "react";
const IconSwap = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 65 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.9 25.2 44.5 8.8c-.6-.6-1.5-.8-2.2-.4-.8.3-1.3 1.1-1.3 1.9v9.9h-8.5c-.6 0-1 .4-1 1s.4 1 1 1H42c.6 0 1-.4 1-1l.1-10.9 16.4 16.4L43 42.9v-11c0-.6-.4-1-1-1H24V21c0-.8-.5-1.6-1.3-1.9s-1.6-.1-2.2.4L4.1 35.9q-.6.6-.6 1.5t.6 1.5l16.4 16.3c.4.4.9.6 1.4.6.3 0 .5-.1.8-.2.8-.3 1.3-1.1 1.3-1.9v-9.9h8.5c.6 0 1-.4 1-1s-.4-1-1-1H23c-.6 0-1 .4-1 1l-.1 10.9L5.5 37.4 22 21.1V32c0 .6.4 1 1 1h18v10c0 .8.5 1.6 1.3 1.9s1.6.1 2.2-.4l16.4-16.3q.6-.6.6-1.5c0-.6-.2-1.2-.6-1.5" />
  </svg>
);
export default IconSwap;
