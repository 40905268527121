import * as React from "react";
const IconTornadoAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60 3H4c-.6 0-1 .4-1 1s.4 1 1 1h56c.6 0 1-.4 1-1s-.4-1-1-1M55.9 17h-43c-.6 0-1 .4-1 1s.4 1 1 1h43c.6 0 1-.4 1-1s-.4-1-1-1M53 31H20.7c-.6 0-1 .4-1 1s.4 1 1 1H53c.6 0 1-.4 1-1s-.4-1-1-1M42.7 45H16.4c-.6 0-1 .4-1 1s.4 1 1 1h26.2c.6 0 1-.4 1-1s-.4-1-.9-1M39.2 59H19.9c-.6 0-1 .4-1 1s.4 1 1 1h19.2c.6 0 1-.4 1-1s-.4-1-.9-1" />
  </svg>
);
export default IconTornadoAlt;
