import * as React from "react";
const IconTideAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.3 42.6c-1.8-1.3-4.3-1.3-6.1 0l-2.6 1.9c-1.1.8-2.6.8-3.8 0l-2.6-1.9c-1.8-1.4-4.3-1.4-6.1 0l-2.6 1.9c-1.1.8-2.6.8-3.8 0l-2.6-1.9c-1.8-1.3-4.3-1.3-6.1 0l-5.1 3.8c-.4.3-.5 1-.2 1.4s1 .5 1.4.2l5.1-3.8c1.1-.8 2.6-.8 3.8 0l2.6 1.9c1.8 1.4 4.3 1.3 6.1 0l2.6-1.9c1.1-.8 2.6-.8 3.8 0l2.6 1.9c1.8 1.4 4.3 1.4 6.1 0l2.6-1.9c1.1-.8 2.6-.8 3.8 0l5.1 3.8c.2.1.4.2.6.2.3 0 .6-.1.8-.4.3-.4.2-1.1-.2-1.4zM50.3 55.4c-1.8-1.3-4.3-1.3-6.1 0l-2.6 1.9c-1.1.8-2.6.8-3.8 0l-2.6-1.9c-1.8-1.4-4.3-1.4-6.1 0l-2.6 1.9c-1.1.8-2.6.8-3.8 0l-2.6-1.9c-1.8-1.4-4.3-1.4-6.1 0l-5.1 3.8c-.4.3-.5 1-.2 1.4s1 .5 1.4.2l5.1-3.8c1.1-.8 2.6-.8 3.8 0l2.6 1.9c1.8 1.4 4.3 1.4 6.1 0l2.6-1.9c1.1-.8 2.6-.8 3.8 0l2.6 1.9c1.8 1.4 4.3 1.4 6.1 0l2.6-1.9c1.1-.8 2.6-.8 3.8 0l5.1 3.8c.2.1.4.2.6.2.3 0 .6-.1.8-.4.3-.4.2-1.1-.2-1.4zM24.7 12.8 31 6.4v13.2c0 .6.4 1 1 1s1-.4 1-1V6.4l6.3 6.4c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-8-8.1c-.4-.4-1-.4-1.4 0l-8 8.1c-.4.4-.4 1 0 1.4s1 .4 1.4 0M9.8 34.9l5.1-3.8c1.1-.8 2.6-.8 3.8 0l2.6 1.9c1.8 1.4 4.3 1.4 6.1 0l2.6-1.9c1.1-.8 2.6-.8 3.8 0l2.6 1.9c1.8 1.4 4.3 1.4 6.1 0l2.6-1.9c1.1-.8 2.6-.8 3.8 0l5.1 3.8c.2.1.4.2.6.2.3 0 .6-.1.8-.4.3-.4.2-1.1-.2-1.4l-5.1-3.8c-1.8-1.3-4.3-1.3-6.1 0l-2.6 1.9c-1.1.8-2.6.8-3.8 0L35 29.5c-1.8-1.4-4.3-1.4-6.1 0l-2.6 1.9c-1.1.8-2.6.8-3.8 0l-2.6-1.9c-1.8-1.4-4.3-1.4-6.1 0l-5.1 3.8c-.4.3-.5 1-.2 1.4.2.4.8.5 1.3.2" />
  </svg>
);
export default IconTideAlt;
