import * as React from "react";
const IconTelegram = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.8 10.2C48.1 4.2 40.3 1 32 1s-16.1 3.2-21.8 9.2S1 23.7 1 32s3.2 16.1 9.2 21.8S23.7 63 32 63s16.1-3.2 21.8-9.2S63 40.3 63 32s-3.2-16.1-9.2-21.8M32 60C16.5 60 4 47.5 4 32S16.5 4 32 4s28 12.7 28 28-12.5 28-28 28" />
    <path d="M46.7 17.6c-1.1-.7-2.7-.1-3.1.1L14 29.3c-1.1.4-2.3 1.3-2 2.7.1 1.6 1.8 2.1 2 2.3l6.9 2.4c.5 2 1.2 4 1.8 6 .2.7.5 1.4.7 2.1.2.6.3 1.2.7 1.7.2.2.4.4.6.5.4.1.8.2 1.2.2.3 0 .8-.1 1-.4l3.9-3.7 6.6 5.1c.1.1.1.1.3.1.6.3 1.1.3 1.4.3s.8-.1 1.1-.1c1.1-.4 1.7-1.5 1.7-1.7 0-.1.1-.1.1-.1l5.4-26.9v-.1c.1-1.4-.5-1.8-.7-2.1m-20.1 26 .4-3.7 2 1.4zm13.3 2.1c-.1.3-.3.5-.6.5-.2 0-.3-.1-.5-.2-3.7-2.9-7.3-5.7-11-8.6 4.2-3.6 8.3-7.3 12.4-11.1.5-.4.9-.8 1.2-1.4s.3-1.3-.1-1.7-1-.6-1.6-.4c-.6.1-1.1.4-1.6.7-5.5 3.5-10.9 7-16.4 10.4l-.6.3c-.2 0-.4 0-.6-.1-1.1-.4-2.3-.7-3.4-1.1s-2.3-.6-3.3-1.2c10.3-4.1 20.6-8.1 31-12.2-1.6 8.5-3.2 17-4.8 25.6 0 .1-.1.3-.1.5" />
  </svg>
);
export default IconTelegram;
