import * as React from "react";
const IconDeliveryTruck3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.3 20.1H41.8v-.5c0-3.1-2.5-5.6-5.6-5.6H7.6C4.5 14 2 16.5 2 19.6v20.9c0 3.1 2.5 5.6 5.6 5.6h8.7c.7 2.3 2.8 4 5.4 4s4.7-1.7 5.4-4h18.5c.7 2.3 2.8 4 5.4 4s4.7-1.7 5.4-4H61c.6 0 1-.4 1-1V28.8c0-4.8-3.9-8.7-8.7-8.7M4 40.5v-21c0-2 1.6-3.6 3.6-3.6h28.6c2 0 3.6 1.6 3.6 3.6V44H27.3c-.2-2.9-2.6-5.2-5.6-5.2s-5.4 2.3-5.6 5.2H7.6c-2 .1-3.6-1.5-3.6-3.5m17.7 7.6c-2 0-3.6-1.6-3.6-3.6s1.6-3.6 3.6-3.6 3.6 1.6 3.6 3.6-1.6 3.6-3.6 3.6m29.2 0c-2 0-3.6-1.6-3.6-3.6s1.6-3.6 3.6-3.6 3.6 1.6 3.6 3.6-1.6 3.6-3.6 3.6m9.1-4h-3.5c-.2-2.9-2.6-5.2-5.6-5.2s-5.4 2.3-5.6 5.2h-3.5v-22h11.5c3.7 0 6.7 3 6.7 6.7z" />
    <path d="M31.8 31.5c.6 0 1-.4 1-1V23c0-.6-.4-1-1-1H12.4c-.6 0-1 .4-1 1v7.5c0 .6.4 1 1 1zm-18.4-7.6h17.4v5.5H13.4z" />
  </svg>
);
export default IconDeliveryTruck3;
