import * as React from "react";
const IconGridCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 33c.6 0 1-.4 1-1s-.4-1-1-1h-8.6V16.8H61c.6 0 1-.4 1-1s-.4-1-1-1h-8.6V8.4c0-.6-.4-1-1-1s-1 .4-1 1v6.4H33V8.4c0-.6-.4-1-1-1s-1 .4-1 1v6.4H13.6V8.4c0-.6-.4-1-1-1s-1 .4-1 1v6.4H3c-.6 0-1 .4-1 1s.4 1 1 1h8.6V31H3c-.6 0-1 .4-1 1s.4 1 1 1h8.6v14.2H3c-.6 0-1 .4-1 1s.4 1 1 1h8.6v6.4c0 .6.4 1 1 1s1-.4 1-1v-6.4H31v6.4c0 .6.4 1 1 1s1-.4 1-1v-6.4h17.4v6.4c0 .6.4 1 1 1s1-.4 1-1v-6.4H61c.6 0 1-.4 1-1s-.4-1-1-1h-8.6V33zM50.4 16.8V31H33V16.8zm-36.8 0H31V31H13.6zm0 30.4V33H31v14.2zm36.8 0H33V33h17.4z" />
  </svg>
);
export default IconGridCopy;
