import * as React from "react";
const IconGr = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 513 342"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 0h513v342H0z" />
    <g >
      <path d="M0 0h513v38H0zm0 76h513v38H0zm0 76h513v38H0zm0 76h513v38H0zm0 76h513v38H0z" />
      <path d="M0 0h190v190H0z" />
    </g>
    <g >
      <path d="M0 76h190v38H0z" />
      <path d="M76 0h38v190H76z" />
    </g>
  </svg>
);
export default IconGr;
