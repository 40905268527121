import React from 'react';
import { useNavigation } from '../../context/NavigationContext';
import { BringzzPageContainer, BringzzIcon, BringzzText, BringzzSwitch } from "@bringzz/components";
import AvatarExample from 'assets/images/AvatarExample.png';
import PostExample1 from 'assets/images/PostExample1.png';

const FaqPage = () => {
    const { backPress, navigate } = useNavigation();

    const items = [
        {
            label: "General",
            right: {
                icon: "IconChevronRight",
                folder: "LineIcons"
            },
            path: "/faq/general"
        },
        {
            label: "Return Products",
            right: {
                icon: "IconChevronRight",
                folder: "LineIcons"
            },
            path: "/faq/general"
        },
        {
            label: "About Bringzz",
            right: {
                icon: "IconChevronRight",
                folder: "LineIcons"
            },
            path: "/faq/general"
        },
        {
            label: "Terms of service",
            right: {
                icon: "IconChevronRight",
                folder: "LineIcons"
            },
            path: "/faq/general"
        },
        {
            label: "Privacy Policy",
            right: {
                icon: "IconChevronRight",
                folder: "LineIcons"
            },
            path: "/faq/general"
        },
        {
            label: "Imprint",
            right: {
                icon: "IconChevronRight",
                folder: "LineIcons"
            },
            path: "/faq/general"
        },
    ]

    return (
        <BringzzPageContainer
            header={{
                left: <BringzzIcon
                    size="22"
                    icon="IconChevronLeft"
                    folder="LineIcons"
                    className="cursor-pointer"
                    onClick={backPress}
                />,
                title: <BringzzText tag='h2'>FAQ & Support</BringzzText>
            }}>

            <div className='p-4 divide-y divide-solid'>
                {items.map((item, index) => {
                    return <div key={index} className='flex items-center justify-between py-4 cursor-pointer' onClick={() => item.path && navigate(item.path)}>
                        <div className='flex space-x-3'>
                            {item.left && <BringzzIcon
                                size="22"
                                icon={item.left.icon}
                                folder={item.left.folder}
                            />}
                            <BringzzText tag="h4" className="font-gt-maru">{item.label}</BringzzText>
                        </div>
                        {item.right && <BringzzIcon
                            size="18"
                            icon={item.right.icon}
                            folder={item.right.folder}
                        />}
                    </div>
                })}
            </div>
        </BringzzPageContainer>

    )
}

export default FaqPage;