import React from "react"
import classnames from "classnames"

import BringzzText from '../BringzzText'

const BringzzStatCard = ({ className, title, value, ...props }) => {
    return <div {...props} className={classnames('flex flex-col border rounded-lg items-center justify-center p-5', className)}>
        <BringzzText className="text-[19px] font-gt-maru font-medium">{value}</BringzzText>
        <BringzzText tag="h5" className="font-normal">{title}</BringzzText>
    </div>
}

export default BringzzStatCard