import * as React from "react";
const IconTruckReload = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.8 40.3-6.4-9.2c-.2-.3-.5-.4-.8-.4H44.1v-2.8c0-2.7-2.2-4.8-4.8-4.8h-7.1c-.6 0-1 .4-1 1s.4 1 1 1h7.1c1.6 0 2.8 1.3 2.8 2.8v26.5H32c-.3-2.4-2.3-4.3-4.8-4.3s-4.5 1.9-4.8 4.3h-6.8c-1.6 0-2.8-1.3-2.8-2.8V39.3c0-.6-.4-1-1-1s-1 .4-1 1v12.2c0 2.7 2.2 4.8 4.8 4.8h7.1c.6 1.9 2.4 3.3 4.5 3.3s3.9-1.4 4.6-3.3h15.100000000000001c.6 1.9 2.4 3.3 4.6 3.3s3.9-1.4 4.6-3.3h5c.6 0 1-.4 1-1V40.8c-.1-.2-.2-.4-.3-.5m-7.7-7.7 5.4 7.8H44.1v-7.8zm-26.8 25c-1.6 0-2.8-1.3-2.8-2.8 0-1.6 1.3-2.8 2.8-2.8s2.8 1.3 2.8 2.8-1.3 2.8-2.8 2.8m24.1 0c-1.6 0-2.8-1.3-2.8-2.8 0-1.6 1.3-2.8 2.8-2.8s2.8 1.3 2.8 2.8-1.2 2.8-2.8 2.8m4.8-3.3c-.3-2.4-2.3-4.3-4.8-4.3s-4.5 1.9-4.8 4.3H44V42.5h16v11.8zM24.9 19.6c0-1.4-.3-2.9-.8-4.2-.2-.5-.8-.8-1.3-.6s-.8.8-.6 1.3c.4 1.1.7 2.3.7 3.5 0 5.2-4.2 9.4-9.4 9.4S4 24.8 4 19.6s4.2-9.4 9.4-9.4l-2.1 2.1c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l3.8-3.8c.4-.4.4-1 0-1.4l-3.8-3.8c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l2.1 2.1C7.1 8.2 2 13.3 2 19.6S7.1 31 13.4 31s11.5-5.1 11.5-11.4" />
  </svg>
);
export default IconTruckReload;
