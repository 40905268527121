import * as React from "react";
const IconVentilatorAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M18 26c0-1.306.835-2.418 2-2.83V20h-5V6h18v14h-5v3.17c1.165.412 2 1.524 2 2.83v.12c.32.026.667.082 1.011.197.553.183 1.116.523 1.528 1.126.408.595.604 1.352.604 2.26 0 .36.165.644.534.881.397.256.992.416 1.644.416.653 0 1.248-.16 1.645-.416.369-.237.534-.521.534-.882V18.855A3.5 3.5 0 0 1 35 15.5v-4a3.5 3.5 0 1 1 7 0v4a3.5 3.5 0 0 1-2.5 3.355v10.847c0 1.171-.63 2.036-1.453 2.565-.794.51-1.79.733-2.726.733s-1.93-.222-2.725-.733c-.824-.53-1.453-1.394-1.453-2.565 0-.625-.134-.955-.254-1.13-.114-.167-.275-.28-.508-.357a2 2 0 0 0-.384-.085A3 3 0 0 1 27 31h-6a3 3 0 0 1-3-3h-.5c-.958 0-1.703.13-2.195.457-.407.271-.805.79-.805 2.043 0 .983-.334 1.81-.918 2.395a2.94 2.94 0 0 1-2.082.855c-.747 0-1.507-.28-2.082-.855-.584-.585-.918-1.412-.918-2.395v-7.645A3.5 3.5 0 0 1 6 19.5v-4a3.5 3.5 0 1 1 7 0v4a3.5 3.5 0 0 1-2.5 3.355V30.5c0 .517.166.815.332.98a.94.94 0 0 0 .668.27.94.94 0 0 0 .668-.27c.166-.165.332-.463.332-.98 0-1.747.602-2.978 1.695-3.707C15.203 26.121 16.458 26 17.5 26zm6.683-16.793-2.44 3.079H17V8h14v10H17v-3.714h6.21l1.183-1.493 2.21 3.962 2.787-3.326H31v-2h-2.544l-1.52 1.815zM11 19.5a1.5 1.5 0 0 1-3 0zM9.5 14a1.5 1.5 0 0 1 1.5 1.5v2H8v-2A1.5 1.5 0 0 1 9.5 14M26 28a1 1 0 1 0 0-2 1 1 0 0 0 0 2m-3-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0m15.5-17a1.5 1.5 0 0 1 1.5 1.5v4a1.5 1.5 0 0 1-3 0v-4a1.5 1.5 0 0 1 1.5-1.5M22 23h4v-3h-4z"
      clipRule="evenodd"
    />
    <path
      
      d="M27 32c.35 0 .687-.06 1-.17V36h10v2H10v-2h10v-4.17c.313.11.65.17 1 .17h1v4h4v-4zM14.5 42a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3M33.5 42a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3"
    />
  </svg>
);
export default IconVentilatorAlt;
