import * as React from "react";
const IconCactus1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.9 21.9h-4.8c-.4-3.8-1.8-6.9-3.9-9.3 0 0 .1 0 .1-.1l3.4-3.7c.4-.4.3-1-.1-1.4s-1-.4-1.4.1l-3.4 3.7c-2.5-2.3-5.7-3.7-8.8-3.9V3c0-.6-.4-1-1-1s-1 .4-1 1v4.2c-3.2.2-6.3 1.6-8.8 3.9l-3.4-3.7c-.4-.4-1-.4-1.4-.1-.4.4-.4 1-.1 1.4l3.4 3.7s.1 0 .1.1c-2 2.4-3.5 5.6-3.9 9.3h-4.8c-.6 0-1 .4-1 1s.4 1 1 1h4.7v.3c0 3.9 1 7.8 3 11.5h-2.1c-2.2 0-4 1.8-4 4v3.1c0 1.6 1 3 2.4 3.7l2.3 11.3c.5 2.5 2.7 4.3 5.2 4.3h16.8c2.5 0 4.7-1.8 5.2-4.3l2.3-11.3c1.4-.6 2.4-2 2.4-3.7v-3.1c0-2.2-1.8-4-4-4h-2.1c2-3.6 3-7.6 3-11.5v-.3h4.7c.6 0 1-.4 1-1s-.4-.9-1-.9m-8.2 35.4c-.4 1.6-1.7 2.7-3.3 2.7H23.6c-1.6 0-3-1.1-3.3-2.7l-2.1-10.6h27.5zm4.6-17.6v3.1c0 1.1-.9 2-2 2H17.7c-1.1 0-2-.9-2-2v-3.1c0-1.1.9-2 2-2h28.5c1.2 0 2.1.8 2.1 2m-6.4-4.1H22.1c-2.2-3.6-3.3-7.5-3.3-11.5 0-9.4 6.7-15 13.2-15s13.2 5.6 13.2 15c0 4.9-1.7 8.9-3.3 11.5" />
  </svg>
);
export default IconCactus1;
