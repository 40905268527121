import * as React from "react";
const IconHousePlanAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M34.8 27.2c-1.6-1.4-3.9-1.4-5.5 0l-4.5 4c-.9.8-1.4 1.9-1.4 3.2v6c0 2.3 1.9 4.2 4.2 4.2h9.1c2.3 0 4.2-1.9 4.2-4.2v-6c0-1.2-.5-2.4-1.4-3.2zm3.9 13.1c0 1.2-1 2.2-2.2 2.2h-9.1c-1.2 0-2.2-1-2.2-2.2v-6c0-.6.3-1.2.8-1.7l4.5-4c.4-.4.9-.5 1.4-.5s1 .2 1.4.5l4.5 4c.5.4.8 1 .8 1.7v6z" />
    <path d="M54.5 4.2c-3.9 0-7.2 3-7.5 6.9H17c-.3-3.8-3.6-6.9-7.5-6.9C5.4 4.2 2 7.6 2 11.7v43c0 2.8 2.3 5.1 5.1 5.1h49.7c2.8 0 5.1-2.3 5.1-5.1V11.8c.1-4.2-3.3-7.6-7.4-7.6M4 14.6v-2.8c0-3 2.5-5.5 5.5-5.5S15 8.8 15 11.8v30c-1.4-1.5-3.3-2.4-5.5-2.4s-4.1.9-5.5 2.4zm56 40.1c0 1.7-1.4 3.1-3.1 3.1H7.1c-1.7 0-3.1-1.4-3.1-3.1v-7.9c0-3 2.5-5.5 5.5-5.5s5.5 2.5 5.5 5.5v2.3c0 .6.4 1 1 1s1-.4 1-1v-36h29.9v36.1c0 .6.4 1 1 1s1-.4 1-1v-2.3c0-3 2.5-5.5 5.5-5.5s5.5 2.5 5.5 5.5v7.8zm-5.5-15.4c-2.2 0-4.2.9-5.5 2.4V11.8c0-3 2.5-5.5 5.5-5.5S60 8.7 60 11.7v30c-1.4-1.4-3.3-2.4-5.5-2.4" />
  </svg>
);
export default IconHousePlanAlt1;
