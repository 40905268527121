import * as React from "react";
const IconCornerRightUp = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.7 21.3 43.9 4.5c-.2-.2-.4-.3-.7-.3s-.5.1-.7.3L25.7 21.3c-.4.4-.4 1 0 1.4s1 .4 1.4 0L42.2 7.6v43.9c0 3.5-2.8 6.3-6.3 6.3H4c-.6 0-1 .4-1 1s.4 1 1 1h31.9c4.6 0 8.3-3.7 8.3-8.3V7.6l15.1 15.1c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4" />
  </svg>
);
export default IconCornerRightUp;
