import * as React from "react";
const IconBoilerAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.5 56.4-2.2-33.5c-.1-3.4-6.1-5.2-11.8-5.2s-11.7 1.8-11.8 5.2l-.8 12.5h-5.7V26c0-.6-.4-1-1-1s-1 .4-1 1v9.4h-6.8v-6.9c0-.6-.4-1-1-1s-1 .4-1 1v6.9h-6.8V31c0-.6-.4-1-1-1s-1 .4-1 1v4.4H7.8c-2.9 0-5.3 2.4-5.3 5.3v16.2c0 2.9 2.4 5.3 5.3 5.3h48.5c1.4 0 2.8-.6 3.8-1.7 1-1.2 1.5-2.7 1.4-4.1m-14-36.8c6 0 9.8 2 9.8 3.3s-3.8 3.3-9.8 3.3-9.8-2-9.8-3.3 3.8-3.3 9.8-3.3M7.8 60c-1.8 0-3.3-1.5-3.3-3.3V40.6c0-1.8 1.5-3.3 3.3-3.3h27L33.3 60zm50.8-1c-.6.7-1.5 1-2.4 1H35.3l2.3-34.2c2.2 1.6 6.2 2.4 10 2.4s7.7-.8 10-2.4l2 30.7c-.1.9-.4 1.8-1 2.5M33.7 4h3.2c1.5 0 2.8 1.2 2.8 2.8v4.1c0 .6.4 1 1 1s1-.4 1-1V6.8c0-2.6-2.1-4.8-4.8-4.8h-3.2c-.6 0-1 .4-1 1s.4 1 1 1M46.3 4h3.2c1.5 0 2.8 1.2 2.8 2.8v4.1c0 .6.4 1 1 1s1-.4 1-1V6.8c0-2.6-2.1-4.8-4.8-4.8h-3.2c-.6 0-1 .4-1 1s.5 1 1 1" />
  </svg>
);
export default IconBoilerAlt;
