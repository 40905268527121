import React from "react";
import BringzzText from "../BringzzText"
import PropTypes from "prop-types";
import BringzzIcon from "../BringzzIcon";

export default function BringzzArticle({
    className,
    title,
    subTitle,
    likesCount,
    commentsCount,
    children,
    interactionBar = true,
    ...props
}) {

    return (
        <div className="relative">
            <div className={className} {...props}>
                <div className="space-y-3 p-4">
                    <BringzzText tag="h1">{title}</BringzzText>
                    <BringzzText tag="h4" className="font-normal">{subTitle}</BringzzText>
                </div>
                {children}
            </div>
            {interactionBar && <div className="fixed flex items-center drop-shadow-md bg-white py-2 px-6 rounded-full bottom-28 left-1/2 transform -translate-x-1/2 space-x-6">
                <div>
                    <BringzzIcon
                        icon="IconHeadphone"
                        folder="LineIcons"
                        size="23"
                    ></BringzzIcon>
                </div>
                <div className="flex items-center space-x-1">
                    <BringzzIcon
                        icon="IconHeart"
                        folder="LineIcons"
                        size="23"
                    ></BringzzIcon>
                    <BringzzText tag="h4" className={"font-normal"}>{likesCount}</BringzzText>
                </div>
                <div className="flex items-center space-x-1">
                    <BringzzIcon
                        icon="IconSpeechBubble16"
                        folder="LineIcons"
                        size="23"
                    ></BringzzIcon>
                    <BringzzText tag="h4" className={"font-normal"}>{commentsCount}</BringzzText>

                </div>
                <div>
                    <BringzzIcon
                        icon="IconUploadAlt"
                        folder="LineIcons"
                        size="23"
                    ></BringzzIcon>
                </div>
                <div>
                    <BringzzIcon
                        icon="IconBookmark"
                        folder="LineIcons"
                        size="23"
                    ></BringzzIcon>
                </div>
            </div>}
        </div>

    )
}

BringzzArticle.propTypes = {
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string,
    interactionBar: PropTypes.bool,
    likesCount: PropTypes.number.isRequired,
    commentsCount: PropTypes.number.isRequired,
};