import * as React from "react";
const IconPaintRollerAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M52.1 2H11.9c-1.7 0-3 1.3-3 3v9.8c0 1.7 1.3 3 3 3h37.8L32.8 32.9c-.8.7-1.3 1.8-1.3 2.9v4.6h-2.4c-1.7 0-3 1.3-3 3V59c0 1.7 1.3 3 3 3h6.8c1.7 0 3-1.3 3-3V43.3c0-1.7-1.3-3-3-3h-2.4v-4.6c0-.5.2-1 .6-1.4l18.7-16.7c1.3-.3 2.3-1.5 2.3-2.9V5c0-1.7-1.3-3-3-3M36.9 43.3V59c0 .6-.4 1-1 1h-6.8c-.6 0-1-.4-1-1V43.3c0-.6.4-1 1-1h6.8c.6 0 1 .5 1 1m-26-28.5V5c0-.6.4-1 1-1h40.2c.6 0 1 .4 1 1v9.8c0 .6-.4 1-1 1H11.9c-.6 0-1-.5-1-1" />
  </svg>
);
export default IconPaintRollerAlt1;
