import * as React from "react";
const IconDollarSymbol = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M36.6 38.7h-7.2c-.6 0-1 .4-1 1s.4 1 1 1h5.7V43c0 .6.4 1 1 1s1-.4 1-1v-2.4c2.5-.2 4.5-2.3 4.5-4.8 0-2.7-2.2-4.9-4.9-4.9h-3.3c-1.6 0-2.9-1.3-2.9-2.9s1.3-2.9 2.9-2.9h7.2c.6 0 1-.4 1-1s-.4-1-1-1h-3.5V21c0-.6-.4-1-1-1s-1 .4-1 1v2.3h-1.8c-2.7 0-4.9 2.2-4.9 4.9s2.2 4.9 4.9 4.9h3.3c1.6 0 2.9 1.3 2.9 2.9s-1.3 2.7-2.9 2.7" />
    <path d="M34.3 4.7c-7.6 0-14.9 3.2-20.1 8.8-.4.4-.3 1 .1 1.4s1 .3 1.4-.1c4.8-5.2 11.6-8.2 18.6-8.2 14 0 25.3 11.4 25.3 25.3 0 14-11.4 25.3-25.3 25.3-11 0-20.7-7-24.1-17.5 0-.1-.1-.1-.1-.1.1 0 .2 0 .2.1l7.1 2.9c.5.2 1.1 0 1.3-.5s0-1.1-.5-1.3l-7.1-2.9q-1.5-.6-3 0c-1 .4-1.7 1.2-2.1 2.1l-2.9 7.1c-.2.5 0 1.1.5 1.3.1.1.3.1.4.1.4 0 .8-.2.9-.6l2.9-7.1c.1-.3.3-.5.4-.7v.3c3.7 11.3 14.1 18.9 26 18.9C49.3 59.3 61.5 47 61.5 32S49.4 4.7 34.3 4.7" />
  </svg>
);
export default IconDollarSymbol;
