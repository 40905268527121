import * as React from "react";
const IconTable4 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 3.6H6c-2.2 0-4 1.8-4 4v10.2c0 2.2 1.8 4 4 4h6l18.6 18.3-18.9 18.6c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3L32 41.5l18.9 18.6c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4L33.4 40.1 52 21.8h6c2.2 0 4-1.8 4-4V7.6c0-2.2-1.8-4-4-4M32 38.7 14.8 21.8h34.3zm28-20.9c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V7.6c0-1.1.9-2 2-2h52c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconTable4;
