import * as React from "react";
const IconMoneyEnvelope = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61 23.4-8.2-5.1c-.5-2.5-2.7-4.3-5.3-4.3H46L32.5 5.6c-.3-.2-.7-.2-1.1 0L18 14h-1.5c-2.6 0-4.8 1.8-5.3 4.3L3 23.4c-.3.2-.5.5-.5.8v28.9c0 3 2.4 5.5 5.5 5.5h48c3 0 5.5-2.4 5.5-5.5V24.3c0-.4-.2-.7-.5-.9m-1.5 5.7L53 31.5V20.7l6.5 4.1zM32 7.6 42.2 14H21.8zM16.5 16h31c1.7 0 3.2 1.3 3.4 3v13.3l-19.4 7.1L13 32.6V19c.3-1.7 1.7-3 3.5-3M11 20.7v11.1l-6.5-2.4v-4.7zm45 35.9H8c-1.9 0-3.5-1.6-3.5-3.5V31.6l7.1 2.6 19.6 7.1c.1 0 .2.1.3.1s.2 0 .3-.1l27.6-10.2V53c.1 2-1.5 3.6-3.4 3.6" />
    <path d="M32 32.7c3.2 0 5.9-2.6 5.9-5.9S35.2 21 32 21s-5.9 2.6-5.9 5.9 2.7 5.8 5.9 5.8m0-9.7c2.1 0 3.9 1.7 3.9 3.9s-1.7 3.9-3.9 3.9c-2.1 0-3.9-1.7-3.9-3.9S29.9 23 32 23" />
  </svg>
);
export default IconMoneyEnvelope;
