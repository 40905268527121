import * as React from "react";
const IconCottage = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.4 28.2 35.6 3.6c-1-1-2.2-1.6-3.6-1.6s-2.6.6-3.6 1.6L5.6 28.2c-.3.3-.3.7-.2 1.1q.3.6.9.6h4.1V57c0 2.8 2.2 5 5 5h33c2.8 0 5-2.2 5-5V29.9h4.1c.4 0 .8-.2.9-.6.3-.3.2-.8 0-1.1M38.2 60H25.8v-7.1c0-3.4 2.8-6.2 6.2-6.2s6.2 2.8 6.2 6.2zm14.3-32.1c-.6 0-1 .4-1 1V57c0 1.7-1.3 3-3 3h-8.3v-7.1c0-4.5-3.7-8.2-8.2-8.2s-8.2 3.7-8.2 8.2V60h-8.3c-1.7 0-3-1.3-3-3V28.9c0-.6-.4-1-1-1H8.7l21.2-23Q30.8 4 32 4t2.1.9l21.2 23z" />
  </svg>
);
export default IconCottage;
