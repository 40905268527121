import * as React from "react";
const IconSvelte = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 65 64"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M29.493 2.849c8.484-5.406 20.224-2.885 26.166 5.62v.01a18.2 18.2 0 0 1 3.115 13.781 17.2 17.2 0 0 1-2.557 6.386A18.1 18.1 0 0 1 58.274 37c.001 1.07-.095 2.14-.287 3.193a17.06 17.06 0 0 1-7.723 11.458l-14.891 9.5c-8.458 5.406-20.224 2.885-26.167-5.62A18.23 18.23 0 0 1 6.081 41.75a17.2 17.2 0 0 1 2.558-6.39 18.1 18.1 0 0 1-2.084-8.365c.002-1.073.098-2.143.287-3.198a17.1 17.1 0 0 1 7.744-11.448zm-4.438 53.865c1.005-.001 2.006-.13 2.98-.38A11 11 0 0 0 31.064 55l14.88-9.495a10.3 10.3 0 0 0 4.647-6.89q.173-.962.172-1.938c0-2.284-.715-4.511-2.042-6.37A11.845 11.845 0 0 0 36.014 25.6c-1.07.286-2.091.734-3.027 1.328L27.3 30.573a3.1 3.1 0 0 1-.917.401 3.561 3.561 0 0 1-3.823-1.417 3.3 3.3 0 0 1-.614-1.922q.011-.278.068-.552a3.12 3.12 0 0 1 1.4-2.083l14.907-9.495c.281-.183.591-.318.916-.4q.435-.127.886-.136A3.57 3.57 0 0 1 43.05 16.5c.4.565.615 1.24.615 1.932v.287l-.053.552.553.172c2.096.633 4.069 1.619 5.833 2.916l.76.558.282-.855c.15-.454.27-.92.359-1.39q.165-.954.167-1.922a10.96 10.96 0 0 0-2.016-6.375 11.84 11.84 0 0 0-9.755-5.083c-1.005 0-2.006.125-2.98.375-1.07.288-2.09.738-3.025 1.333l-14.907 9.495a10.32 10.32 0 0 0-4.645 6.89 10.964 10.964 0 0 0 1.875 8.287 11.84 11.84 0 0 0 12.703 4.708c1.07-.287 2.09-.735 3.026-1.328l5.677-3.625c.28-.181.588-.317.911-.401q.441-.116.896-.115a3.57 3.57 0 0 1 2.932 1.532c.406.559.626 1.23.63 1.922a3.13 3.13 0 0 1-1.453 2.65l-14.89 9.548c-.283.18-.593.315-.917.4a3.5 3.5 0 0 1-.896.115 3.55 3.55 0 0 1-2.927-1.562 3.3 3.3 0 0 1-.614-1.927v-.287l.052-.552-.552-.167a19.3 19.3 0 0 1-5.834-2.916l-.76-.578-.281.854q-.226.684-.36 1.39c-.11.633-.166 1.275-.166 1.917a10.94 10.94 0 0 0 2.041 6.37 11.82 11.82 0 0 0 9.724 5.094"
      clipRule="evenodd"
    />
  </svg>
);
export default IconSvelte;
