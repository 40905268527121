import * as React from "react";
const IconPlantPotAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M45.4 36.9H33v-8.8c4.3 2 8.6 1.6 12.8-1.2.6-.4.9-1 .9-1.7s-.3-1.4-.9-1.8c-.9-.6-1.9-1.1-2.9-1.5 2-2.3 3.3-5.4 3.8-9.2.1-.6-.1-1.3-.6-1.7-.5-.5-1.1-.7-1.7-.6-2.8.4-5.2 1.2-7.2 2.5-.3-3.4-1.4-6.7-3.4-10-.4-.6-1-1-1.8-1-.7 0-1.4.4-1.8 1-2 3.3-3.1 6.6-3.4 10-2-1.3-4.5-2.2-7.2-2.5-.6-.1-1.3.1-1.8.6-.5.4-.7 1.1-.6 1.7.5 3.7 1.7 6.8 3.8 9.2-1 .4-1.9.9-2.9 1.5q-.9.6-.9 1.8c0 .7.3 1.3.9 1.7 2.5 1.6 5 2.5 7.5 2.5 1.8 0 3.6-.4 5.3-1.3v8.8H18.6c-2.2 0-4 1.8-4 4v2.7c0 1.6.9 2.9 2.3 3.6L19 57.9c.5 2.4 2.6 4.1 5 4.1h16c2.4 0 4.5-1.7 5-4.1l2.2-10.8c1.3-.6 2.3-2 2.3-3.6v-2.7c-.1-2.1-1.9-3.9-4.1-3.9M19.3 25.2q0-.15 0 0c1.3-.9 2.6-1.5 3.9-1.9.4-.1.6-.4.7-.7.1-.4 0-.7-.3-1-2.3-2.1-3.8-5.2-4.2-9.2q4.8.6 7.8 3.3c.3.3.7.3 1.1.1q.6-.3.6-.9c-.1-3.6 1-7.3 3.2-10.9H32c2.2 3.6 3.2 7.3 3.2 10.9 0 .4.2.8.6.9.4.2.8.1 1.1-.1 2-1.8 4.6-2.9 7.9-3.2-.5 3.9-1.9 7-4.3 9.1-.3.2-.4.6-.3 1s.4.6.7.7c1.3.3 2.6.9 3.8 1.8v.1c-4 2.7-8.1 2.8-12.2.3-.3-.2-.7-.2-1 0-4.1 2.5-8.1 2.4-12.2-.3M43 57.5c-.3 1.4-1.6 2.5-3 2.5H24c-1.5 0-2.8-1.1-3.1-2.5l-2-10h26zm4.4-14c0 1.1-.9 2-2 2H18.6c-1.1 0-2-.9-2-2v-2.7c0-1.1.9-2 2-2h26.8c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconPlantPotAlt1;
