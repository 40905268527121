import * as React from "react";
const IconFlagCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.6 10.9c-.5-.6-1.3-1-2.1-1H12.8V3c0-.6-.4-1-1-1s-1 .4-1 1v58c0 .6.4 1 1 1s1-.4 1-1V35.6h33.6c1.3 0 2.5-1 2.7-2.2l3.1-20.5c.1-.7-.2-1.5-.6-2m-4.5 22.2c0 .3-.4.5-.7.5H12.8V11.9h36.7c.2 0 .5.1.6.3.1.1.2.2.1.4z" />
  </svg>
);
export default IconFlagCopy;
