import * as React from "react";
const IconUpAlign2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M14.1 5h35.8c.6 0 1-.4 1-1s-.4-1-1-1H14.1c-.6 0-1 .4-1 1s.5 1 1 1M44.8 13.6H19.2c-3 0-5.4 2.4-5.4 5.4v1.9c0 3 2.4 5.4 5.4 5.4h25.6c3 0 5.3-2.4 5.3-5.4V19c.1-3-2.3-5.4-5.3-5.4m3.4 7.3c0 1.8-1.5 3.4-3.3 3.4H19.2c-1.8 0-3.4-1.5-3.4-3.4V19c0-1.8 1.5-3.4 3.4-3.4h25.6c1.8 0 3.3 1.5 3.3 3.4v1.9z" />
    <path d="M61 3.9c-.6 0-1 .4-1 1v50.8c0 1.8-1.5 3.3-3.3 3.3H7.4C5.5 59 4 57.5 4 55.7V4.9c0-.6-.4-1-1-1s-1 .4-1 1v50.8c0 3 2.4 5.3 5.4 5.3h49.3c3 0 5.3-2.4 5.3-5.3V4.9c0-.5-.4-1-1-1" />
  </svg>
);
export default IconUpAlign2;
