import * as React from "react";
const IconCartDown = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.4 12.6c-.5-.2-1.1 0-1.3.5l-12 25.4c-.5 1-1.6 1.7-2.7 1.7H16.5c-1.6 0-2.9-1.2-3-2.7L10.7 8c-.1-1.2-1.1-2.1-2.3-2.1H3c-.6 0-1 .4-1 1s.4 1 1 1h5.3q.3 0 .3.3l2.8 29.5c.2 2.6 2.4 4.5 5 4.5h28.8c1.9 0 3.7-1.1 4.5-2.9l12-25.4c.4-.4.2-1-.3-1.3" />
    <path d="M32.9 28.4c.2.2.4.3.7.3s.5-.1.7-.3l7.7-7.8c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-6 6.1V5.4c0-.6-.4-1-1-1s-1 .4-1 1v19.9l-6-6.1c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4zM42.9 47.6c-3.3 0-6 2.7-6 6s2.7 6 6 6 6-2.7 6-6-2.6-6-6-6m0 10c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.7 4-4 4M20.2 47.6c-3.3 0-6 2.7-6 6s2.7 6 6 6 6-2.7 6-6-2.6-6-6-6m0 10c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.7 4-4 4" />
  </svg>
);
export default IconCartDown;
