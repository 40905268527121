import * as React from "react";
const IconBrackets = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M10 25.9V12.8C10 8 13.9 4 18.6 4h3.2c.6 0 1-.4 1-1s-.4-1-1-1h-3.2C12.8 2 8 6.9 8 12.8v12.7l-5.1 5.8c-.3.4-.3.9 0 1.3L8 38.5v12.6c0 6 4.8 10.8 10.7 10.8h3.2c.6 0 1-.4 1-1s-.4-1-1-1h-3.2c-4.8 0-8.7-4-8.7-8.8v-13c0-.2-.1-.5-.2-.7L4.9 32l4.8-5.4c.2-.2.3-.4.3-.7M61.2 31.3 56 25.6V12.8C56 6.9 51.2 2 45.4 2h-3.2c-.6 0-1 .4-1 1s.4 1 1 1h3.2c4.8 0 8.7 4 8.7 8.8v13.1c0 .2.1.5.3.7l4.8 5.4-4.8 5.5c-.2.2-.2.4-.2.7v13c0 4.9-3.9 8.8-8.7 8.8h-3.2c-.6 0-1 .4-1 1s.4 1 1 1h3.2C51.2 62 56 57.1 56 51.2V38.5l5.1-5.9c.4-.3.4-.9.1-1.3" />
  </svg>
);
export default IconBrackets;
