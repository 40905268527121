import * as React from "react";
const IconCheckSign = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m59 7.4-.4-.2c-2.6-1.2-5.8-.1-7 2.5L50 13.2l-6.7 14.4h-36C4.4 27.6 2 30 2 32.9V52c0 2.9 2.4 5.3 5.3 5.3h39.3c2.9 0 5.3-2.4 5.3-5.3V35.2l9.7-20.8c1.1-2.6 0-5.8-2.6-7m-5.6 3.2c.4-.8 1-1.4 1.8-1.7s1.7-.2 2.5.1l.4.2c1.6.8 2.3 2.7 1.6 4.3L58.5 16l-6.3-3zm-4.8 26.8-5.8 4.3-.5-7.2L51.4 15l6.3 3zm-2.1 17.9H7.3C5.5 55.3 4 53.8 4 52V32.8c0-1.8 1.5-3.3 3.3-3.3h35l-2 4.3c-.1.2-.1.3-.1.5l.6 9.3c0 .4.2.7.6.8.1.1.3.1.4.1.2 0 .4-.1.6-.2l7.4-5.4v13c0 1.9-1.5 3.4-3.3 3.4" />
    <path d="M30.7 37.3H11.8c-.6 0-1 .4-1 1s.4 1 1 1h18.9c.6 0 1-.4 1-1s-.5-1-1-1M27.8 45.8h-16c-.6 0-1 .4-1 1s.4 1 1 1h16.1c.6 0 1-.4 1-1s-.5-1-1.1-1" />
  </svg>
);
export default IconCheckSign;
