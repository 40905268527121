import * as React from "react";
const IconD = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M11 12a3 3 0 0 1 3-3h8c8.284 0 15 6.716 15 15s-6.716 15-15 15h-8a3 3 0 0 1-3-3zm3-1a1 1 0 0 0-1 1v24a1 1 0 0 0 1 1h8c7.18 0 13-5.82 13-13s-5.82-13-13-13zm1 3a1 1 0 0 1 1-1h6c6.075 0 11 4.925 11 11s-4.925 11-11 11h-6a1 1 0 0 1-1-1zm2 1v18h5a9 9 0 1 0 0-18z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconD;
