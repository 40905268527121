import * as React from "react";
const IconArrowDoubleRight = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m60 31.2-11.4-11c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l9.7 9.4H37.6c-.6 0-1 .4-1 1s.4 1 1 1h19.2l-9.6 9.4c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3L60 32.7c.2-.2.3-.4.3-.7s-.1-.6-.3-.8M15.7 20.2c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l9.6 9.4H4.7c-.6 0-1 .4-1 1s.4 1 1 1H24l-9.7 9.4c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3l11.4-11.1c.2-.2.3-.4.3-.7s-.1-.5-.3-.7z" />
  </svg>
);
export default IconArrowDoubleRight;
