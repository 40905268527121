import * as React from "react";
const IconApartmentsAlt6 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.9 2.8H32.3c-2.8 0-5.1 2.3-5.1 5.1v9.3l-1.7-1.4c-1.9-1.5-4.6-1.5-6.5 0l-9.4 7.6c-1.2 1-1.9 2.4-1.9 4v31.9H3c-.6 0-1 .4-1 1s.4 1 1 1h53.8c2.8 0 5.1-2.3 5.1-5.1V7.9c.1-2.8-2.2-5.1-5-5.1M18 59.2V49c0-1.1.9-2 2-2h4.4c1.1 0 2 .9 2 2v10.2zm10.5 0V49c0-2.2-1.8-4-4-4H20c-2.2 0-4 1.8-4 4v10.2H9.7V27.4c0-.9.4-1.8 1.2-2.4l9.4-7.6c1.1-.9 2.8-.9 3.9 0l9.4 7.6c.7.6 1.2 1.5 1.2 2.4v31.9h-6.3zM60 56.1c0 1.7-1.4 3.1-3.1 3.1H36.8V27.4c0-1.5-.7-3-1.9-4l-5.7-4.6V7.9c0-1.7 1.4-3.1 3.1-3.1h24.5c1.7 0 3.1 1.4 3.1 3.1v48.2z" />
  </svg>
);
export default IconApartmentsAlt6;
