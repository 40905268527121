import * as React from "react";
const IconPassport = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 30.4c4.9 0 8.9-4 8.9-8.9s-4-8.9-8.9-8.9-8.9 4-8.9 8.9 4 8.9 8.9 8.9m0-15.8c3.8 0 6.9 3.1 6.9 6.9s-3.1 6.9-6.9 6.9-6.9-3.1-6.9-6.9 3.1-6.9 6.9-6.9M37.3 46.8H26.7c-.6 0-1 .4-1 1s.4 1 1 1h10.5c.6 0 1-.4 1-1s-.4-1-.9-1" />
    <path d="M47.9 2H14.1c-1.7 0-3 1.3-3 3v54c0 1.7 1.3 3 3 3h33.7c2.8 0 5-2.2 5-5V7c.1-2.8-2.2-5-4.9-5m3 55c0 1.7-1.3 3-3 3H14.1c-.6 0-1-.4-1-1V5c0-.6.4-1 1-1h33.7c1.7 0 3 1.3 3 3v50z" />
    <path d="M39.9 39.2H24.1c-.6 0-1 .4-1 1s.4 1 1 1h15.8c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconPassport;
