import * as React from "react";
const IconCradle = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 9.7c-.6 0-1 .4-1 1v10.5H4V10.7c0-.6-.4-1-1-1s-1 .4-1 1v42.6c0 .6.4 1 1 1s1-.4 1-1V43h56v10.3c0 .6.4 1 1 1s1-.4 1-1V10.7c0-.6-.4-1-1-1M33.1 23.1h13.3V41H33.1zm-2 17.9H17.8V23.1h13.3zM4 23.1h11.8V41H4zM48.3 41V23.1H60V41z" />
  </svg>
);
export default IconCradle;
