import * as React from "react";
const IconCommercialMachine = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m53.7 44.4-1.4-20.3c-.2-2.9-2.7-5.2-5.6-5.2h-3.4v-6.5H49c1.7 0 3-1.3 3-3V5c0-1.7-1.3-3-3-3H34c-1.7 0-3 1.3-3 3v4.4c0 1.7 1.3 3 3 3h7.3v6.5h-24c-2.9 0-5.4 2.3-5.6 5.2l-1.4 20.3c-2.1.8-3.5 2.8-3.5 5.2v6.8c0 3.1 2.5 5.6 5.6 5.6h39.2c3.1 0 5.6-2.5 5.6-5.6v-6.8c0-2.3-1.5-4.4-3.5-5.2M33 9.4V5c0-.6.4-1 1-1h15c.6 0 1 .4 1 1v4.4c0 .6-.4 1-1 1H34c-.6 0-1-.4-1-1M13.8 24.2c.1-1.9 1.7-3.3 3.6-3.3h29.3c1.9 0 3.4 1.5 3.6 3.3L51.6 44H12.4zm41.4 32.2c0 2-1.6 3.6-3.6 3.6H12.4c-2 0-3.6-1.6-3.6-3.6v-6.8c0-2 1.6-3.6 3.6-3.6h39.2c2 0 3.6 1.6 3.6 3.6z" />
    <path d="M34.4 29.8h10.1c.6 0 1-.4 1-1s-.4-1-1-1H34.4c-.6 0-1 .4-1 1s.5 1 1 1M19.3 32.2h.2c.6 0 1-.4 1-1s-.4-1-1-1h-.2c-.6 0-1 .4-1 1s.5 1 1 1M26.1 32.2h.2c.6 0 1-.4 1-1s-.4-1-1-1h-.2c-.6 0-1 .4-1 1s.4 1 1 1M19.3 39.2h.2c.6 0 1-.4 1-1s-.4-1-1-1h-.2c-.6 0-1 .4-1 1s.5 1 1 1M26.1 39.2h.2c.6 0 1-.4 1-1s-.4-1-1-1h-.2c-.6 0-1 .4-1 1s.4 1 1 1" />
  </svg>
);
export default IconCommercialMachine;
