import * as React from "react";
const IconApartmentsAlt3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.9 2.8H32.3c-2.8 0-5.1 2.3-5.1 5.1v11.8l-15.6 4c-2.3.6-3.9 2.6-3.9 5v30.6H3c-.6 0-1 .4-1 1s.4 1 1 1h53.8c2.8 0 5.1-2.3 5.1-5.1V7.9c.1-2.8-2.2-5.1-5-5.1M26.5 59.2H18V49c0-1.1.9-2 2-2h4.4c1.1 0 2 .9 2 2v10.2zm2 0V49c0-2.2-1.8-4-4-4H20c-2.2 0-4 1.8-4 4v10.2H9.7V28.6c0-1.4 1-2.7 2.4-3l18.8-4.8c.9-.2 1.9 0 2.7.6s1.2 1.5 1.2 2.5v35.4h-6.3zM60 56.1c0 1.7-1.4 3.1-3.1 3.1H36.8V23.8c0-1.6-.7-3.1-2-4-1.3-1-2.9-1.3-4.4-.9l-1.2.3V7.9c0-1.7 1.4-3.1 3.1-3.1h24.5c1.7 0 3.1 1.4 3.1 3.1v48.2z" />
  </svg>
);
export default IconApartmentsAlt3;
