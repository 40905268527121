import * as React from "react";
const IconBj = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 85.333 513 342"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 85.333h513v342H0z" />
    <path  d="M196.666 85.333H513v171H196.666z" />
    <path  d="M196.666 256H513v171H196.666z" />
  </svg>
);
export default IconBj;
