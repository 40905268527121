import * as React from "react";
const IconPalletCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m55.1 44.8-.1-.1c-.7-.4-1.3-.8-3.4-1.3-1-.2-2.2-.7-2.4-1l-.2-.1c-.7-.7-1-1.6-1-2.7s.4-2 1-2.7l3.9-3.9c6.4-6.4 6.4-16.5 0-23-4.3-4.6-10.4-7-17.1-7-7.6 0-15.3 3.3-21.1 9-5.5 5.6-8.4 12.6-8.4 20.3 0 7.8 3 14.9 8.5 20.2C20.2 58.1 27.2 61 35 61c7.6 0 14.8-3 20.2-8.5 1-1 1.6-2.4 1.6-3.8 0-1.6-.6-3-1.7-3.9m-1.3 6.3C48.7 56.2 42 59 35 59c-7.2 0-13.7-2.7-18.9-7.8-5.1-4.9-7.9-11.6-7.9-18.8s2.7-13.8 7.8-18.9C21.5 8.1 28.7 5 35.8 5c6.2 0 11.8 2.2 15.8 6.2 5.7 5.8 5.7 14.5 0 20.2l-4 4c-1 1.1-1.6 2.6-1.6 4.1 0 1.6.6 3.1 1.6 4.1.9 1 3.3 1.6 3.5 1.6 1.7.4 2.3.7 2.8 1 .5.5.9 1.4.9 2.3 0 1-.4 2-1 2.6" />
    <path d="M36.8 35.7c-2 0-3.5 1.6-3.5 3.5s1.6 3.5 3.5 3.5 3.5-1.6 3.5-3.5-1.5-3.5-3.5-3.5m0 5.1c-.9 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5c.9 0 1.5.7 1.5 1.5s-.6 1.5-1.5 1.5M41.6 22.5c-2.7 0-4.8 2.2-4.8 4.8s2.2 4.8 4.8 4.8 4.8-2.2 4.8-4.8-2.1-4.8-4.8-4.8m0 7.6c-1.6 0-2.8-1.3-2.8-2.8 0-1.6 1.3-2.8 2.8-2.8s2.8 1.3 2.8 2.8c0 1.6-1.2 2.8-2.8 2.8M36.1 16.7c0-3.4-2.7-6.1-6.1-6.1s-6.1 2.7-6.1 6.1 2.7 6.1 6.1 6.1 6.1-2.7 6.1-6.1M30 20.8c-2.3 0-4.1-1.8-4.1-4.1s1.8-4.1 4.1-4.1 4.1 1.8 4.1 4.1-1.9 4.1-4.1 4.1" />
  </svg>
);
export default IconPalletCopy;
