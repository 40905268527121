import * as React from "react";
const IconSmartHomeAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 54.8h-3.9v-8l1.2 1c.2.2.4.2.6.2.3 0 .6-.1.8-.4.4-.4.3-1.1-.1-1.4L49.8 38c-.4-.3-.9-.3-1.3 0l-7 5.9-2.8 2.3c-.4.4-.5 1-.1 1.4s1 .5 1.4.1l1.2-1v8H22.9v-8l1.2 1c.2.2.4.2.6.2.3 0 .6-.1.8-.4.4-.4.3-1.1-.1-1.4L15.5 38c-.4-.3-.9-.3-1.3 0l-7 5.9-2.8 2.3c-.4.4-.5 1-.1 1.4s1 .5 1.4.1l1.2-1v8H3c-.6 0-1 .4-1 1s.4 1 1 1h57.99999999999999c.6 0 1-.4 1-1s-.4-.9-1-.9m-52.1 0v-9.7l6-5 6 5v9.7zm34.2 0v-9.7l6-5 6 5v9.7zM44.9 22.8c.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4-7.5-7.5-19.7-7.5-27.3 0-.4.4-.4 1 0 1.4s1 .4 1.4 0c6.7-6.7 17.7-6.7 24.4 0 .3.2.6.3.8.3M38.7 29c.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4-2-2-4.6-3.1-7.4-3.1s-5.5 1.1-7.4 3.1c-.4.4-.4 1 0 1.4s1 .4 1.4 0c1.6-1.6 3.7-2.5 6-2.5s4.4.9 6 2.5c.2.2.5.3.7.3" />
    <path d="M14 16.7c9.9-9.9 26.1-9.9 36.1 0 .2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4-10.7-10.7-28.2-10.7-38.9 0-.4.4-.4 1 0 1.4s1 .4 1.4 0M27.7 34.2c0 2.4 1.9 4.3 4.3 4.3s4.3-1.9 4.3-4.3-1.9-4.3-4.3-4.3-4.3 1.9-4.3 4.3m6.6 0c0 1.3-1.1 2.3-2.3 2.3s-2.3-1.1-2.3-2.3 1.1-2.3 2.3-2.3 2.3 1 2.3 2.3" />
  </svg>
);
export default IconSmartHomeAlt;
