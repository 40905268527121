import * as React from "react";
const IconMedicalProtection = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.6 10.9 34.1 2.4c-1.3-.6-2.8-.6-4.2 0l-19.5 8.5c-2 .9-3.3 2.9-3.1 5.1l.9 13.5c.8 13.6 9.6 25.7 23.4 32.4.1.1.3.1.4.1s.3 0 .4-.1C46.2 55.2 55 43.1 55.9 29.6l.9-13.5c.1-2.2-1.2-4.3-3.2-5.2m.3 18.6c-.8 12.7-9 24-21.9 30.4-12.9-6.4-21-17.7-21.9-30.4l-.8-13.6c-.1-1.4.7-2.6 1.9-3.2l19.5-8.5c.4-.1.9-.2 1.3-.2s.9.1 1.3.3l19.5 8.5c1.2.5 2 1.8 1.9 3.2z" />
    <path d="M36.8 21.2c-1.5 0-2.8.5-4 1.4-.3.2-.5.4-.8.7-.2-.3-.5-.5-.8-.7-1.2-.9-2.6-1.4-4-1.4-2 0-3.8.8-5.1 2.2s-2 3.3-2 5.3c0 2.1.8 4 2.4 5.9 1.4 1.6 3.3 3.3 5.5 5.2l.1.1c.7.6 1.5 1.3 2.4 2q.6.6 1.5.6c.9 0 1.1-.2 1.5-.6.9-.8 1.7-1.5 2.5-2.1 2.2-1.9 4.2-3.5 5.5-5.2 1.6-2 2.4-3.8 2.4-5.9 0-2-.7-3.9-2-5.3s-3.1-2.2-5.1-2.2M40 33.3c-1.3 1.5-3.1 3.1-5.3 4.9-.8.6-1.6 1.4-2.5 2.1-.1.1-.3.1-.4 0-.8-.7-1.6-1.4-2.4-2l-.1-.1c-2.2-1.8-4-3.4-5.3-4.9-1.3-1.6-1.9-3-1.9-4.6 0-1.5.5-2.9 1.5-3.9.9-1 2.2-1.6 3.6-1.6 1 0 2 .3 2.8 1 .3.3.7.6.9.9.5.6 1.6.6 2.1 0q.45-.6.9-.9c.8-.6 1.8-1 2.8-1 1.4 0 2.7.6 3.6 1.6s1.5 2.4 1.5 3.9c.1 1.6-.5 3-1.8 4.6" />
  </svg>
);
export default IconMedicalProtection;
