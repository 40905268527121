import * as React from "react";
const IconPlaceholderHouse = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 58.3h-3.9v-8l1.2 1c.2.2.4.2.6.2.3 0 .6-.1.8-.4.4-.4.3-1.1-.1-1.4l-9.8-8.2c-.4-.3-.9-.3-1.3 0l-7 5.9-2.8 2.3c-.4.4-.5 1-.1 1.4s1 .5 1.4.1l1.2-1v8H22.9v-8l1.2 1c.2.2.4.2.6.2.3 0 .6-.1.8-.4.4-.4.3-1.1-.1-1.4l-9.8-8.2c-.4-.3-.9-.3-1.3 0l-7 5.9-2.8 2.3c-.4.4-.5 1-.1 1.4s1 .5 1.4.1l1.2-1v8H3c-.6 0-1 .4-1 1s.4 1 1 1h57.99999999999999c.6 0 1-.4 1-1s-.4-.8-1-.8m-52.1 0v-9.7l6-5 6 5v9.7zm34.2 0v-9.7l6-5 6 5v9.7zM32.8 33.9c.2.2.4.2.6.2s.5-.1.6-.2c.5-.4 12.3-10.3 12.3-17.3 0-7.1-5.8-12.9-12.9-12.9S20.5 9.5 20.5 16.6c0 7 11.8 16.9 12.3 17.3m.6-28.2c6 0 10.9 4.9 10.9 10.9 0 4.7-7.4 12.1-10.9 15.2-3.6-3.1-10.9-10.5-10.9-15.2 0-6 4.9-10.9 10.9-10.9" />
    <path d="M38.8 16.2c0-3-2.4-5.4-5.4-5.4S28 13.2 28 16.2s2.4 5.4 5.4 5.4 5.4-2.4 5.4-5.4m-8.8 0c0-1.9 1.5-3.4 3.4-3.4s3.4 1.5 3.4 3.4-1.5 3.4-3.4 3.4c-1.8 0-3.4-1.5-3.4-3.4" />
  </svg>
);
export default IconPlaceholderHouse;
