import * as React from "react";
const IconClockAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32.3 33.6V16.5c0-.6-.4-1-1-1s-1 .4-1 1V34c0 .3.1.5.3.7l11.5 11.6c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4z" />
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
  </svg>
);
export default IconClockAlt1;
