import * as React from "react";
const IconSling = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M24 22a8 8 0 1 0 0-16 8 8 0 0 0 0 16m0-2a6 6 0 1 0 0-12 6 6 0 0 0 0 12M41 33a9 9 0 0 0-9-9H16a9 9 0 1 0 0 18h8a4 4 0 0 0 .883-7.902L28.173 26H32a7 7 0 1 1 0 14v-4a3 3 0 1 0 0-6h-2v12h2a9 9 0 0 0 9-9m-25-7a7 7 0 0 0-5.184 11.704l2.7-3.023A3 3 0 0 1 16 30h1.694l3.572-4zm8.11 10.003L22.485 40H24a2 2 0 0 0 .11-3.997M20.326 40l5.687-14h-2.066l-11.59 12.98A6.97 6.97 0 0 0 16 40zM33 33a1 1 0 0 1-1 1v-2a1 1 0 0 1 1 1m-17.094-.996A1 1 0 0 0 15 33v.018z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconSling;
