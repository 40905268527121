import * as React from "react";
const IconBookmarkCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M46.1 2H17.9c-2.9 0-5.3 2.4-5.3 5.3v51c0 1.5.8 2.7 2.1 3.3q.75.3 1.5.3 1.35 0 2.4-.9l11.5-10c1.2-.9 2.8-.9 4 0l11.4 10c1.1 1 2.6 1.2 3.9.6s2.1-1.9 2.1-3.3v-51c0-2.9-2.4-5.3-5.4-5.3m3.4 56.2c0 .7-.4 1.2-1 1.5s-1.3.2-1.8-.3l-11.3-9.9-.2-.1c-1.9-1.5-4.6-1.5-6.5 0L17.3 59.6c-.5.4-1.2.5-1.8.3-.6-.3-1-.8-1-1.5v-51C14.5 5.5 16 4 17.9 4h28.3c1.8 0 3.3 1.5 3.3 3.3z" />
  </svg>
);
export default IconBookmarkCopy;
