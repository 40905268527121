import * as React from "react";
const IconFamilyAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M15.1 36.4c3.8 0 6.9-3.1 6.9-6.9s-3.1-6.9-6.9-6.9-6.9 3.1-6.9 6.9 3.1 6.9 6.9 6.9m0-11.9c2.7 0 4.9 2.2 4.9 4.9s-2.2 4.9-4.9 4.9-4.9-2.2-4.9-4.9 2.2-4.9 4.9-4.9M57.6 43.9c-2.3-2.3-5.4-3.6-8.7-3.6-5 0-9.5 3.1-11.4 7.6-1.6-1.3-3.6-2.1-5.9-2.1-2 0-3.9.7-5.4 1.8-.6-1.4-1.5-2.6-2.5-3.6-2.3-2.3-5.3-3.6-8.5-3.6S9 41.6 6.6 43.9c-2.4 2.4-3.8 5.7-3.8 9.1v4c0 2.7 2.2 5 5 5h48.4c2.7 0 5-2.2 5-5v-4.5c0-3.2-1.3-6.3-3.6-8.6m-18.8 11V60H24.5v-5.1c0-3.9 3.2-7.1 7.1-7.1s7.2 3.2 7.2 7.1M7.8 60c-1.6 0-3-1.3-3-3v-4.1C4.8 50 6 47.2 8 45.2c2-1.9 4.5-3 7.1-3 2.7 0 5.2 1.1 7.2 3 1 1 1.8 2.3 2.3 3.7-1.4 1.6-2.2 3.7-2.2 5.9V60zm51.4-3c0 1.6-1.3 3-3 3H40.8v-5.1c0-2-.7-3.8-1.7-5.3 1.3-4.3 5.3-7.3 9.8-7.3 2.8 0 5.3 1.1 7.3 3 1.9 1.9 3 4.5 3 7.3zM48.9 36.4c3.8 0 6.9-3.1 6.9-6.9s-3.1-6.9-6.9-6.9-6.9 3.1-6.9 6.9c-.1 3.8 3.1 6.9 6.9 6.9m0-11.9c2.7 0 4.9 2.2 4.9 4.9s-2.2 4.9-4.9 4.9-4.9-2.2-4.9-4.9c-.1-2.7 2.2-4.9 4.9-4.9" />
    <path d="M26.6 37.4c0 2.8 2.3 5.1 5.1 5.1s5.1-2.3 5.1-5.1-2.3-5.1-5.1-5.1c-2.8.1-5.1 2.3-5.1 5.1m8.1 0c0 1.7-1.4 3.1-3.1 3.1s-3.1-1.4-3.1-3.1 1.4-3.1 3.1-3.1 3.1 1.4 3.1 3.1M31.3 19.3c.2.2.5.3.7.3.3 0 .5-.1.7-.3l7.6-7.6c1.1-1.1 1.6-2.5 1.7-4C42 5.8 41 4 39.5 3c-2.2-1.5-5.3-1.2-7.3.8L32 4l-.3-.3c-1.1-1.1-2.5-1.7-4-1.7-1.4 0-2.8.5-3.8 1.5-1.1 1-1.7 2.4-1.8 4 0 1.6.6 3.2 1.8 4.4zM25.2 5c.7-.6 1.6-1 2.5-1 1 0 1.9.4 2.6 1.1l1 1c.4.4 1 .4 1.4 0l.9-.9c1.3-1.3 3.3-1.6 4.8-.6 1 .7 1.6 1.8 1.6 3 0 1-.4 1.9-1.1 2.6l-6.9 7-6.8-6.8c-.8-.7-1.2-1.8-1.2-2.9.1-1 .5-1.9 1.2-2.5" />
  </svg>
);
export default IconFamilyAlt;
