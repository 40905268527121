import * as React from "react";
const IconBed5 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 21.2c-.6 0-1 .4-1 1v19.2h-7.4V29.9c0-.6-.4-1-1-1H23.2c.1-.1.2-.3.2-.4l1.3-2.6c.6-1.2.7-2.7.2-4s-1.4-2.4-2.6-2.9l-11.4-5.4c-1.3-.6-2.7-.7-4-.2-1.2.4-2.2 1.3-2.8 2.5V9.4c0-.6-.4-1-1-1s-1 .4-1 1v45.2c0 .6.4 1 1 1s1-.4 1-1V43.3H16v7.4c0 .6.4 1 1 1h34.7c.6 0 1-.4 1-1v-7.4H60v11.3c0 .6.4 1 1 1s1-.4 1-1V22.2c0-.6-.4-1-1-1M4.4 19.4l1.3-2.6c.4-.8 1-1.3 1.8-1.6s1.7-.2 2.4.1l11.4 5.4c.8.4 1.3 1 1.6 1.8s.2 1.7-.1 2.4l-1.3 2.6c-.3.5-.6 1-1.1 1.3h-3.5L6 23.6c-1.6-.7-2.3-2.6-1.6-4.2M4 41.3V24.7c.3.3.7.5 1.1.7l10.8 5.1v10.8zm46.6 8.5H17.9V30.9h32.7z" />
  </svg>
);
export default IconBed5;
