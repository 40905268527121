import * as React from "react";
const IconMedicalBriefcase = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54 20.1H43.1C42.7 14.3 37.9 9.7 32 9.7s-10.6 4.7-11 10.4H10c-4.4 0-8 3.6-8 8v19.1c0 4.4 3.6 8 8 8h44c4.4 0 8-3.6 8-8V28.1c0-4.4-3.6-8-8-8m-21.9-8.4c4.8 0 8.7 3.7 9.1 8.4H23c.4-4.6 4.3-8.4 9.1-8.4M60 47.3c0 3.3-2.7 6-6 6H10c-3.3 0-6-2.7-6-6V28.1c0-3.3 2.7-6 6-6h44c3.3 0 6 2.7 6 6z" />
    <path d="M37.4 37H33v-4.4c0-.6-.4-1-1-1s-1 .4-1 1V37h-4.4c-.6 0-1 .4-1 1s.4 1 1 1H31v4.4c0 .6.4 1 1 1s1-.4 1-1V39h4.4c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconMedicalBriefcase;
