import * as React from "react";
const IconTruckGlobal = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M27.1 18.9c0-6.8-5.5-12.3-12.3-12.3S2.5 12 2.5 18.9 8 31.2 14.8 31.2s12.3-5.5 12.3-12.3M14.8 29.2c-1.4 0-2.7-.3-3.9-.8l9.7-7 4.5-1.7c-.5 5.4-4.9 9.5-10.3 9.5m0-20.7c1.9 0 3.7.5 5.2 1.4l-4.7 5.6c-.8 1-2.2 1.1-3.2.4l-1-.7-4.4-2.4c-.1 0-.1 0-.2-.1 1.8-2.5 4.8-4.2 8.3-4.2M4.5 18.9c0-1.6.4-3.1 1-4.4l.2.2L10 17l.9.6c.8.5 1.6.8 2.5.8 1.3 0 2.5-.5 3.4-1.6l4.8-5.7c1.8 1.6 3.1 3.9 3.4 6.5l-5.3 2c-.1 0-.2.1-.2.1L8.9 27.4c-2.7-1.9-4.4-5-4.4-8.5" />
    <path d="m61.4 38.6-6.2-9c-.2-.3-.5-.4-.8-.4H44.1v-2.7c0-2.6-2.1-4.7-4.7-4.7h-6.9c-.6 0-1 .4-1 1s.4 1 1 1h6.9c1.5 0 2.7 1.2 2.7 2.7v25.8h-9.8c-.3-2.4-2.2-4.2-4.7-4.2-2.4 0-4.4 1.8-4.7 4.2h-6.7c-1.5 0-2.7-1.2-2.7-2.7v-12c0-.6-.4-1-1-1s-1 .4-1 1v11.9c0 2.6 2.1 4.7 4.7 4.7h6.9c.6 1.9 2.4 3.2 4.5 3.2s3.8-1.4 4.5-3.2h14.7c.6 1.9 2.4 3.2 4.5 3.2s3.8-1.4 4.5-3.2h4.9c.6 0 1-.4 1-1v-14c-.2-.2-.2-.4-.3-.6m-7.6-7.4 5.2 7.6H44v-7.6zM27.6 55.5c-1.5 0-2.7-1.2-2.7-2.7s1.2-2.7 2.7-2.7 2.7 1.2 2.7 2.7-1.2 2.7-2.7 2.7m23.6 0c-1.5 0-2.7-1.2-2.7-2.7s1.2-2.7 2.7-2.7 2.7 1.2 2.7 2.7-1.2 2.7-2.7 2.7m4.7-3.2c-.3-2.4-2.2-4.2-4.7-4.2s-4.4 1.8-4.7 4.2H44V40.8h15.5v11.5z" />
  </svg>
);
export default IconTruckGlobal;
