import * as React from "react";
const IconExpandAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.5 2h-47C4.9 2 2 4.9 2 8.5v18.3c0 .6.4 1 1 1s1-.4 1-1V8.5C4 6 6 4 8.5 4h47.1C58 4 60 6 60 8.5v47.1c0 2.5-2 4.5-4.5 4.5H38.6c-.6 0-1 .4-1 1s.4 1 1 1h16.9c3.6 0 6.5-2.9 6.5-6.5V8.5C62 4.9 59.1 2 55.5 2" />
    <path d="M49.1 16.4v8.1c0 .6.4 1 1 1s1-.4 1-1V14c0-.2-.1-.5-.3-.7s-.4-.3-.7-.3H39.6c-.6 0-1 .4-1 1s.4 1 1 1h8.1L26.2 36.4H7c-2.8 0-5 2.2-5 5V57c0 2.8 2.2 5 5 5h15.6c2.8 0 5-2.2 5-5V37.8zM25.6 57c0 1.7-1.3 3-3 3H7c-1.7 0-3-1.3-3-3V41.4c0-1.7 1.3-3 3-3h18.6z" />
  </svg>
);
export default IconExpandAlt1;
