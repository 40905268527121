import * as React from "react";
const IconClearFormat = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M33.1 40.8h-5.4V6.4H38c1.4 0 2.5 1.1 2.5 2.5v5c0 .6.4 1 1 1s1-.4 1-1V9c0-2.5-2-4.5-4.5-4.5H6.4c-2.5 0-4.5 2-4.5 4.5v5c0 .6.4 1 1 1s1-.4 1-1V9c0-1.4 1.1-2.5 2.5-2.5h10.3v34.3h-5.4c-.6 0-1 .4-1 1s.4 1 1 1h21.8c.6 0 1-.4 1-1s-.5-1-1-1M18.7 6.4h7v34.3h-7zM61 40.9l-5-5c-1.6-1.6-4.1-1.6-5.7 0L42.2 44l-3.7 3.7c-1.6 1.6-1.6 4.1 0 5.7l5 5c.8.8 1.8 1.2 2.8 1.2s2-.4 2.8-1.2L61 46.5c1.6-1.5 1.6-4.1 0-5.6M47.7 57c-.8.8-2 .8-2.8 0l-5-5c-.8-.8-.8-2 0-2.8l3-3 7.8 7.8zm11.9-11.9-7.4 7.4-7.8-7.8 7.4-7.4c.8-.8 2.1-.8 2.8 0l5 5c.8.8.8 2 0 2.8" />
  </svg>
);
export default IconClearFormat;
