import * as React from "react";
const IconIslandAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.8 44.9h-9.2c.1-.2.2-.4.2-.6 0-5-6-9-13.9-9.3.1-.2.1-.5 0-.8l-.3-.6c0-.1-3-6.6-2.1-11.7.2-1 .6-2.2 1.1-3.3.4-1.2.9-2.4 1.2-3.7.1-.1.2-.3.2-.4 0-.2.1-.5.2-.7 1.6.6 4 2.7 4.6 5.1.1.4.5.7 1 .7h.3c.5-.1.9-.7.7-1.2-.9-2.8-3.4-5.4-5.8-6.4 2-2.7 6-4.1 10.2-3.4.5.1 1.1-.3 1.2-.8s-.3-1.1-.8-1.2c-4.7-.9-9.3.7-11.8 3.7-.4-2.1-1.2-4.1-2.2-5.6-.8-1.1-2-2.5-3.6-2.8-.5-.1-1.1.3-1.2.8s.3 1.1.8 1.2c.7.1 1.5.9 2.3 2 .8 1.2 1.6 3 1.9 4.8-2.7-1.7-6.3-1.9-9.1-.1-.5.3-.6.9-.3 1.4s.9.6 1.4.3c2.7-1.7 6.2-1.2 8.2 1.1 0 .2 0 .4-.1.7v.1c-.2 1.2-.7 2.4-1.1 3.6-.5 1.3-.9 2.5-1.1 3.7-1 5.7 2.1 12.6 2.3 12.9l.3.6c-7.7.3-13.8 4.6-13.9 9.9h-9c-.6 0-1 .4-1 1s.4 1 1 1h47.7c.6 0 1-.4 1-1s-.7-1-1.3-1M32 37c7.1 0 12.8 3.3 12.8 7.3 0 .2.1.4.2.6H19.2C19.2 40.5 25 37 32 37M15.4 52.5h-5.2c-.6 0-1 .4-1 1s.4 1 1 1h5.2c.6 0 1-.4 1-1s-.5-1-1-1M37.8 52.5h-7.7c-.6 0-1 .4-1 1s.4 1 1 1h7.7c.6 0 1-.4 1-1s-.4-1-1-1M47.8 60h-5.2c-.6 0-1 .4-1 1s.4 1 1 1h5.2c.6 0 1-.4 1-1s-.4-1-1-1M26.8 60h-5.2c-.6 0-1 .4-1 1s.4 1 1 1h5.2c.6 0 1-.4 1-1s-.5-1-1-1M51.5 52.5H49c-.6 0-1 .4-1 1s.4 1 1 1h2.5c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconIslandAlt2;
