import * as React from "react";
const IconUiZoomOut = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path  d="M33 22H21v-2h12z" />
    <path
      
      fillRule="evenodd"
      d="M42 21c0 8.284-6.716 15-15 15-3.782 0-7.238-1.4-9.876-3.71l-1.828 1.828.004 2.825-6.472 6.471-4.242-4.242 6.524-6.524 2.707.12 1.893-1.892A14.94 14.94 0 0 1 12 21c0-8.284 6.716-15 15-15s15 6.716 15 15m-2 0c0 7.18-5.82 13-13 13s-13-5.82-13-13S19.82 8 27 8s13 5.82 13 13M8.828 40.586l-1.414-1.414 4.486-4.487 1.397.063.002 1.367z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconUiZoomOut;
