// ProgressOverlay.js
import React, { useState } from 'react';
import { BringzzIcon, BringzzText, BringzzProgressBar } from '@bringzz/components';
import { useAuth } from 'context/AuthContext';
import classNames from 'classnames';
import { useNavigation } from 'context/NavigationContext';
import { useOrder } from 'context/OrderContext';
import { collectSegments, packageSegments, pickupSegments } from 'constants/orderSegments';

const ProgressOverlay = () => {
    const { activeStep, getCurrentPage, orderDetails, orderType } = useOrder();
    const { navigate, isActive } = useNavigation();
    const [isMinimized, setIsMinimized] = useState(false); // State to handle minimized/maximized state
    const { user } = useAuth();
    console.log(orderType);

    const steps = orderType === 'package' ? packageSegments : pickupSegments;
    // const steps = pickupSegments

    // If orderDetails is null, don't render the component
    if (!orderDetails) return null;

    const handleMinimize = () => {
        setIsMinimized(true);
    };

    const handleMaximize = () => {
        setIsMinimized(false);
    };

    return (
        <>
            {!isMinimized ? (
                <div
                    className={classNames(
                        'fixed w-full max-w-xl p-6 z-50 flex justify-center items-center bottom-24 md:!bottom-0'
                    )}
                >
                    <div className="bg-magic-lilac w-full max-w-xl rounded-lg shadow-lg relative">
                        <BringzzIcon
                            size="16"
                            icon="IconClose"
                            folder="LineIcons"
                            className="cursor-pointer text-white absolute top-4 right-4"
                            onClick={handleMinimize} // Instead of closing, we minimize the overlay
                        />

                        <div className="p-4">
                            <BringzzText tag="h4" className="font-normal text-white">
                                Order #{orderDetails.id}
                            </BringzzText>
                            <BringzzText tag="h3" className="text-white">
                                {steps[activeStep].status}
                            </BringzzText>
                            <BringzzText tag="h5" className="font-light mb-3 text-white">
                                {steps[activeStep].subStatus}
                            </BringzzText>
                            <BringzzProgressBar state={Number(activeStep) + 1} mode="custom" segments={steps} />
                        </div>
                        <hr />
                        <div
                            className="flex justify-center py-3 items-center space-x-2 cursor-pointer"
                            onClick={() => {
                                const nextStep = getCurrentPage(activeStep)
                                console.log("nextStep", nextStep);
                                console.log("activeStep", activeStep);

                                navigate(nextStep);
                            }}
                        >
                            <BringzzText tag="h4" className="text-white font-normal">
                                View details
                            </BringzzText>
                            <BringzzIcon icon="IconChevronDown" folder="LineIcons" size="12" className="text-white" />
                        </div>
                    </div>
                </div>
            ) : (
                <div
                    className={classNames(
                        'fixed right-4 z-50 shadow-lg rounded-full',
                        user.userRole !== 1 ? 'bottom-28' : 'bottom-40'
                    )}
                >
                    <div
                        className="bg-magic-lilac text-white p-2 rounded-full cursor-pointer shadow-lg"
                        onClick={handleMaximize}
                    >
                        <BringzzIcon icon="IconBasket" size="22" folder="CustomIcons" className="text-white" />
                    </div>
                </div>
            )}
        </>
    );
};

export default ProgressOverlay;
