import * as React from "react";
const IconHackerNews = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M1 1v62h62V1zm3 27.3s0-.1 0 0c0-.1 0 0 0 0m30.1 7.5v13.9h-4.4V35.5l-11-21.3h5.1c7.3 13.5 6.8 14.1 8.2 17.3 1.7-3.8.7-3.4 8.5-17.3h4.8z" />
  </svg>
);
export default IconHackerNews;
