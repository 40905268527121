import * as React from "react";
const IconBookmarkAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M11.6 61c-.2 0-.3 0-.5-.1-.3-.2-.5-.5-.5-.9V8c0-2.8 2.2-5 5-5h32.9c2.8 0 5 2.2 5 5v51.9c0 .4-.2.7-.5.9s-.7.2-1 0L32 49.3 12.1 60.9c-.2.1-.4.1-.5.1m4-56c-1.7 0-3 1.3-3 3v50.3l18.9-11c.3-.2.7-.2 1 0l18.9 10.9V8c0-1.7-1.3-3-3-3z" />
  </svg>
);
export default IconBookmarkAlt1;
