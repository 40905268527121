import * as React from "react";
const IconBroom = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.7 2.3c-.4-.4-1-.4-1.4 0L38.1 24.5c-5.2-4.6-12.9-5-18.6-.8L4.1 35c-1.2.9-1.9 2.2-2.1 3.7-.1 1.5.4 2.9 1.5 4l17.8 17.8c1 1 2.3 1.5 3.6 1.5h.4c1.5-.1 2.8-.9 3.7-2.1l11.3-15.4c4.2-5.7 3.8-13.4-.8-18.6L61.7 3.7c.4-.4.4-1 0-1.4M27.4 58.7c-.5.7-1.4 1.2-2.3 1.3s-1.8-.3-2.4-.9L4.9 41.3c-.6-.6-1-1.5-.9-2.4s.5-1.7 1.3-2.3l12.4-9.1 18.8 18.8zm11.3-15.4-1.1 1.4-18.4-18.3 1.4-1.1c5.2-3.8 12.2-3.2 16.7 1.3 4.7 4.5 5.2 11.5 1.4 16.7" />
  </svg>
);
export default IconBroom;
