import * as React from "react";
const IconHouseCancelled = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.2 40.4c-.4-.4-1-.4-1.4 0l-3 3-3-3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l3 3-3 3c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l3-3 3 3c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-3-3 3-3c.4-.4.4-1.1 0-1.4" />
    <path d="M48.3 29.7v-9.3c0-.3-.2-.7-.5-.9L25.7 6c-.3-.2-.7-.2-1 0l-7.8 4.7v-1C16.8 6.6 14.2 4 11 4h-.9C7 4 4.4 6.6 4.4 9.8v8.5l-1.9 1.2c-.3.2-.5.5-.5.8v21.5c0 3.2 2.6 5.7 5.7 5.7h12c.6 0 1-.4 1-1V33.1h8.4v13.5c0 .6.4 1 1 1h1.7c1.4 7 7.6 12.4 15 12.4C55.2 60 62 53.1 62 44.8c0-7.9-6-14.4-13.7-15.1M6.4 9.8C6.4 7.7 8.1 6 10.2 6h.8c2.1 0 3.7 1.7 3.7 3.7V12l-8.4 5.1V9.8zm25.3 35.8h-.5V32.1c0-.6-.4-1-1-1H19.8c-.6 0-1 .4-1 1v13.5h-11c-2.1 0-3.7-1.7-3.7-3.7v-21l21-12.8 21.1 12.8v8.7c-8.1.3-14.6 7-14.6 15.2 0 .2 0 .5.1.8M46.8 58c-7.3 0-13.2-5.9-13.2-13.2s5.9-13.2 13.2-13.2S60 37.5 60 44.8 54.1 58 46.8 58" />
  </svg>
);
export default IconHouseCancelled;
