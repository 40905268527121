import * as React from "react";
const IconBasketballCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.4 14.6c-3.8-5.3-9.2-9.3-15.3-11.2-4.1-1.3-8.4-1.7-12.8-1.2-5.2.7-9.8 2.5-13.8 5.3-4.1 3-7.4 6.8-9.5 11.3-1.9 4-3 8.3-3 12.4-.1 6.6 1.8 12.9 5.6 18.2 3.8 5.4 9.1 9.3 15.3 11.2 3 .9 6 1.4 9.1 1.4 1.2 0 2.4-.1 3.7-.2 5.2-.7 9.8-2.5 13.8-5.3 4.1-3 7.4-6.8 9.5-11.3 1.9-4 3-8.3 3-12.4.1-6.6-1.8-12.8-5.6-18.2M60 32.7c0 1.7-.3 3.5-.6 5.3-6.3.6-12.2 2.7-17.5 6.2l-8.4-11.8 21.9-15.6c3.1 4.7 4.7 10.2 4.6 15.9M54.2 15 32.3 30.7l-8.4-11.8c5-3.8 9-8.9 11.6-14.6 1.7.2 3.3.6 4.9 1.1C45.9 7 50.7 10.5 54.2 15M28.6 4.2c1-.1 2.1-.2 3.2-.2.5 0 1.1 0 1.6.1-2.4 5.2-6.1 9.7-10.7 13.2l-6.2-8.7c3.6-2.3 7.6-3.8 12.1-4.4M14.9 9.8l6.2 8.7c-4.8 3.2-10.3 5.2-16 5.8.4-1.5 1-3.1 1.7-4.6q2.85-5.85 8.1-9.9M4 31.2c0-1.6.2-3.3.6-4.9 6.3-.6 12.4-2.7 17.7-6.2l8.4 11.8-22 15.6C5.5 42.7 3.9 37.1 4 31.2m5.9 17.9 21.9-15.7 8.4 11.8c-5 3.8-9 8.8-11.6 14.5-1.7-.2-3.4-.6-5.1-1.1-5.4-1.6-10.1-4.9-13.6-9.5m25.5 10.7c-1.5.2-3.1.2-4.6.2 2.4-5.1 6.1-9.6 10.6-13l6 8.4c-3.5 2.3-7.5 3.8-12 4.4m13.7-5.6-6-8.4c4.7-3.2 10.1-5.1 15.8-5.7-.4 1.4-1 2.9-1.6 4.3-2 3.8-4.7 7.1-8.2 9.8" />
  </svg>
);
export default IconBasketballCopy;
