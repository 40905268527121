import * as React from "react";
const IconElectricity = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path  d="m25 12-6 9h4v6l6-9h-4z" />
    <path
      
      fillRule="evenodd"
      d="M37.91 19.273c-.3 2.567-1.334 4.999-2.983 7.015C33.965 27.464 31.52 30.82 31 33H17c-.52-2.182-2.968-5.539-3.93-6.715a13.57 13.57 0 0 1-2.98-7.017 13.47 13.47 0 0 1 1.292-7.494 13.8 13.8 0 0 1 5.166-5.67A14.2 14.2 0 0 1 24.002 4c2.638 0 5.222.73 7.454 2.107a13.8 13.8 0 0 1 5.164 5.671 13.47 13.47 0 0 1 1.29 7.495M29.583 31H18.416c-.426-.983-.995-1.968-1.525-2.805a35 35 0 0 0-2.272-3.175 11.57 11.57 0 0 1-2.542-5.983 11.47 11.47 0 0 1 1.1-6.382 11.8 11.8 0 0 1 4.42-4.848A12.2 12.2 0 0 1 24.002 6c2.27 0 4.49.629 6.405 1.81a11.8 11.8 0 0 1 4.418 4.848 11.47 11.47 0 0 1 1.098 6.383 11.57 11.57 0 0 1-2.544 5.981c-.53.646-1.429 1.844-2.271 3.175-.53.837-1.098 1.82-1.524 2.803"
      clipRule="evenodd"
    />
    <path
      
      d="M17 36a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2H18a1 1 0 0 1-1-1"
    />
    <path
      
      fillRule="evenodd"
      d="M17 39h14v3a2 2 0 0 1-2 2H19a2 2 0 0 1-2-2zm2 2h10v1H19z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconElectricity;
