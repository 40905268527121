import * as React from "react";
const IconTShirtAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.5 11 49.7 4.3c-.8-.5-1.7-.7-2.6-.7h-4.8c-.5 0-.9.3-1 .8-.9 4.4-4.8 7.6-9.3 7.6s-8.4-3.2-9.3-7.6c-.1-.5-.5-.8-1-.8h-4.8c-.9 0-1.8.2-2.6.7L2.5 11c-.4.2-.6.7-.5 1.1l3.2 11.2c.1.5.7.8 1.2.7l6.4-1.6c.3-.1.7 0 .9.2.3.2.4.5.4.9v30.4c0 3.6 2.9 6.5 6.5 6.5h22.6c3.6 0 6.5-2.9 6.5-6.5V23.6c0-.3.2-.7.4-.9.3-.2.6-.3.9-.2l6.4 1.6c.5.1 1.1-.2 1.2-.7L62 12.2c.1-.5-.1-.9-.5-1.2m-4.4 10.9-5.5-1.4c-.9-.2-1.9 0-2.7.6s-1.2 1.5-1.2 2.4v30.4c0 2.5-2 4.5-4.5 4.5H20.7c-2.5 0-4.5-2-4.5-4.5V23.6c0-1-.4-1.9-1.2-2.4-.6-.4-1.2-.7-1.9-.7-.3 0-.5 0-.7.1L6.9 22l-2.7-9.5L15.3 6c.5-.3 1-.4 1.6-.4h4C22.3 10.5 26.8 14 32 14s9.7-3.5 11.1-8.4h4c.6 0 1.1.1 1.6.4l11.1 6.4z" />
  </svg>
);
export default IconTShirtAlt;
