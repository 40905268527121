import * as React from "react";
const IconCommunityHealthworker = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M20.533 10.5a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0m-2 0a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0"
      clipRule="evenodd"
    />
    <path
      
      d="m32.5 7.086 7.207 7.207-1.414 1.414L37 14.414V20h-3v-2.5a1.5 1.5 0 0 0-3 0V20h-3v-5.586l-1.293 1.293-1.414-1.414z"
    />
    <path
      
      fillRule="evenodd"
      d="M17.821 16h.379v.014c.37.028.829.106 1.315.305 2.823 1.16 3.539 4.082 3.906 5.58l.002.01c.2.812.204.835.318 1.352.109.492.32 1.453.917 4.073a3 3 0 0 1-3.265 3.648l.6 3.04c.179.908-.299 1.893-1.277 2.203-1.005.319-2.854.775-5.216.775s-4.211-.456-5.217-.775c-.978-.31-1.455-1.295-1.276-2.203l.599-3.04a3 3 0 0 1-3.264-3.647c.594-2.612.806-3.574.915-4.068l.002-.006c.114-.517.119-.54.318-1.353l.002-.01c.367-1.497 1.083-4.42 3.906-5.578a4.4 4.4 0 0 1 1.693-.32zM16.2 18h-3.021c-.26 0-.594.03-.935.17-1.772.727-2.327 2.59-2.724 4.214-.196.798-.199.812-.306 1.295l-.001.007-.001.006c-.11.497-.323 1.46-.92 4.086a1 1 0 0 0 1.723.887l1.208-5.847 1.959.405-1.222 5.91.045.017-1.024 5.198c.884.272 2.484.652 4.519.652s3.635-.38 4.518-.652l-1.024-5.2.045-.017-1.22-5.908 1.958-.404 1.208 5.845a1 1 0 0 0 1.723-.886c-.598-2.625-.81-3.59-.92-4.086l-.003-.013c-.106-.483-.11-.497-.305-1.295-.398-1.624-.952-3.487-2.724-4.215a2.5 2.5 0 0 0-.934-.169l-1.622.002z"
      clipRule="evenodd"
    />
    <path
      
      d="M11.859 41.91a1.5 1.5 0 0 1-1.26-1.481v-2.405c1.334.158 2.574.259 3.785.304l-.862 2.577a1.5 1.5 0 0 1-1.663 1.004M17.489 40.938l-.934-2.587a45 45 0 0 0 3.845-.238v2.316a1.5 1.5 0 0 1-2.911.509M33 25.586l8.707 8.707-1.414 1.414-1.755-1.754v7.201h-3.692v-3.077a1.846 1.846 0 1 0-3.692 0v3.077H27.46v-7.201l-1.754 1.754-1.414-1.414z"
    />
  </svg>
);
export default IconCommunityHealthworker;
