import * as React from "react";
const IconRocketLaunch1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M47.6 28.5h-1.3c-.6 0-1 .4-1 1s.4 1 1 1h1.3c1.3 0 2.4 1.1 2.4 2.4v13.5H14V32.9c0-1.3 1.1-2.4 2.4-2.4h.7c.6 0 1-.4 1-1s-.4-1-1-1h-.7c-2.4 0-4.4 2-4.4 4.4v19c0 1.8 1.5 3.3 3.3 3.3h12.5l-.9 5.6c0 .3 0 .6.2.8s.5.4.8.4h8.2c.3 0 .6-.1.8-.4.2-.2.3-.5.2-.8l-.9-5.6h12.5c1.8 0 3.3-1.5 3.3-3.3v-19c0-2.4-2-4.4-4.4-4.4M34.9 60h-5.8l.8-4.8h4.2zm13.8-6.8H15.3c-.7 0-1.3-.6-1.3-1.3v-3.6h36V52c0 .7-.6 1.2-1.3 1.2" />
    <path d="M20 26.1h.1l4.7-.5-.4 1c-.2.5 0 1.1.6 1.3l1.4.6q-1.2.75-1.8 2.1c-.8 2 .5 7.1.7 8.1.1.3.3.5.6.7.1 0 .2.1.4.1s.4 0 .5-.1c.9-.5 5.3-3.3 6.1-5.3.4-.9.4-1.8.2-2.8l1.4.6c.1 0 .2.1.4.1.1 0 .3 0 .4-.1.2-.1.4-.3.5-.5l.4-1 3 3.7c.2.2.5.4.8.4h.2c.3-.1.6-.3.8-.6l1.8-4.5c.7-1.7.4-3.7-.8-5.1l-2.2-2.7 1.2-2.9c3.3-8.2 2.1-12.7.5-15.9-.2-.5-.7-.7-1.2-.5-3.4 1-7.3 3.3-10.7 11.6l-1.2 2.9-3.4.4c-1.9.2-3.4 1.4-4.1 3.1l-1.8 4.5c-.1.3-.1.7.1 1 .2.2.5.3.8.3m11.2 7.1c-.3.8-2.4 2.4-4.2 3.6-.5-2.1-.8-4.6-.5-5.5.3-.6.7-1.1 1.3-1.4.3-.1.6-.2 1-.2.3 0 .6.1.9.2 1.4.5 2 2 1.5 3.3m9.9-4.7-1.2 2.9-2.7-3.2 1.9-4.6 1.5 1.8c.7.8.9 2 .5 3.1m-.9-24.2c1.3 2.9 1.8 6.7-.9 13.5l-4.8 11.8-7.8-3.1 2.8-6.9.6-1.4 1.4-3.5c2.8-6.9 5.8-9.2 8.7-10.4M22.8 21.1c.4-1 1.4-1.8 2.5-1.9l2.3-.3-1.9 4.6-4.1.5z" />
  </svg>
);
export default IconRocketLaunch1;
