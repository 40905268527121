import * as React from "react";
const IconPregnant0812w = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M11.892 34.459a1 1 0 1 1-1.514 1.307 18 18 0 1 1 29.935-4.159 1 1 0 1 1-1.812-.845 16 16 0 0 0-22.824-20.427l.322.414.002.002.046.06.323.416c.423.55.856 1.14 1.169 1.736.311.592.56 1.291.49 2.041-.076.792-.488 1.474-1.171 2.036l-.748.615c-.262.215-.337.407-.355.53a.57.57 0 0 0 .106.41c.19.28.684.576 1.44.375.5-.134 1.163-.281 1.765.045.298.162.493.391.627.58.118.167.23.366.328.54l.042.074.122.272a3 3 0 0 1 5.83-.224l.406 1.44.648-.07a3 3 0 0 1 3.123 1.918c.492 1.296.32 2.62-.033 3.64-.37 1.066-1.024 2.078-1.89 2.93-1.78 1.75-4.494 2.875-7.845 2.57a3 3 0 0 1-2.525-1.9l-1.032-2.654-.878.249a3 3 0 0 1-1.633-5.774l3.502-.99q.299-.084.598-.106l-.18-.396a9 9 0 0 0-.162-.28c-.069.012-.165.033-.298.068-1.494.398-2.893-.132-3.607-1.18a2.57 2.57 0 0 1-.432-1.837c.102-.675.473-1.29 1.063-1.775l.747-.614c.362-.299.435-.523.45-.68.019-.197-.04-.487-.268-.921-.227-.431-.566-.904-.985-1.449l-.335-.432-.413-.533-.026.021a16 16 0 0 0-2.118 22.957M24.09 20.8a1 1 0 1 0-1.925.543l1.093 3.878a1 1 0 0 0 .991.728 1 1 0 0 1 .6 1.822c-.677.468-1.571.832-2.689.935a1 1 0 0 1-1.024-.633l-1.529-3.933a1 1 0 0 0-1.204-.6l-3.503.99a1 1 0 1 0 .545 1.925l1.753-.496a1 1 0 0 1 1.204.6l1.361 3.502a1 1 0 0 0 .842.633c2.785.253 4.915-.68 6.261-2.005.666-.654 1.143-1.408 1.403-2.16.255-.737.327-1.553.054-2.273a1 1 0 0 0-1.042-.64l-1.487.16a1 1 0 0 1-1.07-.724z"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M34.942 19.81a3.819 3.819 0 1 1-6.763-3.548 3.819 3.819 0 0 1 6.763 3.547m-1.77-.93a1.819 1.819 0 1 1-3.222-1.689 1.819 1.819 0 0 1 3.221 1.69M19.862 38.5c.273-.34.438-.77.438-1.25 0-1.172-.99-2.05-2.12-2.05h-.86c-1.13 0-2.12.878-2.12 2.05 0 .48.165.91.438 1.25-.273.34-.438.77-.438 1.25 0 1.172.99 2.05 2.12 2.05h.86c1.13 0 2.12-.878 2.12-2.05 0-.48-.165-.91-.438-1.25m-2.542-1.7c-.329 0-.52.242-.52.45s.191.45.52.45h.86c.329 0 .52-.242.52-.45s-.191-.45-.52-.45zm1.38 2.95c0-.208-.191-.45-.52-.45h-.86c-.329 0-.52.242-.52.45s.191.45.52.45h.86c.329 0 .52-.242.52-.45"
      clipRule="evenodd"
    />
    <path
      
      d="M32.2 37.25c0-.072-.033-.17-.152-.27a.86.86 0 0 0-.548-.18h-1c-.391 0-.62.207-.678.346a.8.8 0 1 1-1.473-.625c.353-.832 1.236-1.321 2.151-1.321h1c1.127 0 2.3.787 2.3 2.05 0 .658-.342 1.21-.808 1.563L31.269 40.2H33a.8.8 0 0 1 0 1.6h-4a.8.8 0 0 1-.502-1.423l3.5-2.818.024-.019c.14-.104.178-.214.178-.29M26.348 35.26a.8.8 0 0 1 .494.74v5a.8.8 0 0 1-1.6 0v-3.196a.8.8 0 0 1-.808-1.328l1.042-1.042a.8.8 0 0 1 .872-.173M35.21 36.132a.8.8 0 0 1 1.58-.264l.463 2.785.552-.967a.8.8 0 0 1 1.39 0l.552.967.464-2.785a.8.8 0 0 1 1.578.264l-.833 5a.8.8 0 0 1-1.484.265l-.972-1.701-.972 1.7a.8.8 0 0 1-1.484-.264zM21.2 39a.8.8 0 0 1 .8-.8h1a.8.8 0 0 1 0 1.6h-1a.8.8 0 0 1-.8-.8"
    />
  </svg>
);
export default IconPregnant0812w;
