import * as React from "react";
const IconCourtAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.8 21h-10C50.6 10.5 42 2 31.5 2c-10.6 0-19.2 8.5-19.4 19H3.2c-.6 0-1 .4-1 1s.4 1 1 1h7.1v37H7.1c-.6 0-1 .4-1 1s.4 1 1 1h49.7c.6 0 1-.4 1-1s-.4-1-1-1H54V23h6.9c.6 0 1-.4 1-1s-.5-1-1.1-1M31.5 4c9.5 0 17.2 7.6 17.4 17H14.1c.2-9.4 7.9-17 17.4-17m6.9 19v37H25.9V23zm-26.1 0h11.6v37H12.3zM52 60H40.4V23H52z" />
  </svg>
);
export default IconCourtAlt1;
