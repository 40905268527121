import * as React from "react";
const IconBalloonsWedding = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.6 17c-3.3-2.3-6.8-2.1-10 .5.5-4.5-.2-10-5.3-13.5-2.6-1.8-5.4-2.4-8.1-1.8-4.5 1.1-7.7 5.2-9.2 7.5-1.5-2.2-4.8-6.4-9.2-7.5-2.8-.6-5.6 0-8.2 1.8-10.2 7.1-3.1 21.6-3 21.7.2.4 5.1 10.4 14.6 16.1l-1.3 7.7c-.1.3.1.7.3.9s.4.2.7.2c.1 0 .2 0 .3-.1l5.1-1.7.3 8.5c0 .5.5 1 1 1 .6 0 1-.5 1-1l-.3-8.5 5.1 1.4c.3.1.7 0 .9-.2.3-.2.4-.6.3-.9l-1.1-7.7c.5-.3 1-.7 1.5-1 1.7 1.9 3.5 3.6 5.4 4.9l-1.3 7.8c-.1.3.1.7.3.9s.4.2.6.2c.1 0 .2 0 .3-.1l5.1-1.8.6 8.7c0 .5.5 1 1 1 .6 0 1-.5 1-1l-.3-8.5 5.2 1.3c.3.1.7 0 .9-.2.3-.2.4-.6.3-.9L50.9 45c6-4.4 9-10.9 9.1-11.1 0-.1 5.5-11.4-2.4-16.9m-32 29.6h-.7l-4.8 1.6 1.2-6.8V41c0-.1-.1-.1-.1-.2 0 0 0-.1-.1-.1 0-.1-.1-.1-.2-.1l-.1-.1C11.5 35.2 6.4 25 6.4 24.9 6.3 24.7 0 11.7 8.7 5.7c2.1-1.5 4.3-2 6.5-1.5 5.2 1.3 8.8 7.9 8.8 7.9.2.3.5.5.9.5s.7-.2.9-.5c0-.1 3.6-6.7 8.8-7.9 2.2-.5 4.3 0 6.5 1.5 8.7 6.1 2.4 19.1 2.4 19.2s-3.7 8-10.9 13.4c-.8.6-1.7 1.2-2.6 1.8-.3.1-.5.4-.5.7v.3l1 6.8zM58.2 33c0 .1-3 6.4-8.8 10.6-.3.1-.5.4-.6.8v.3l1 6.8-5-1.3h-.3c-.1 0-.2 0-.3.1l-4.8 1.6 1.1-6.8v-.4c0-.1-.1-.2-.1-.2v-.1c-.1-.1-.1-.2-.2-.2q-2.85-1.95-5.4-4.8c7-5.7 10.4-13.1 10.6-13.5 0-.1 1-2 1.7-4.8 3.1-3.7 6.3-4.5 9.5-2.3 6.3 4.4 1.8 13.8 1.6 14.2" />
  </svg>
);
export default IconBalloonsWedding;
