import * as React from "react";
const IconTc = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 85.333 512 341.333"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 85.333h512V426.67H0z" />
    <path
      
      d="M332.058 191.996v78.221c0 38.103 51.942 49.779 51.942 49.779s51.942-11.675 51.942-49.779v-78.221z"
    />
    <path
      
      d="M376.579 220.44c0 7.855-6.644 28.445-14.84 28.445s-14.84-20.589-14.84-28.445 14.84-14.222 14.84-14.222 14.84 6.367 14.84 14.222"
    />
    <path
      
      d="M415.961 235.93c2.394-5.6 4.257-13.785 4.257-17.86 0-6.546-8.904-11.852-8.904-11.852s-8.904 5.306-8.904 11.852c0 4.075 1.862 12.26 4.257 17.86l-5.141 11.123a26.9 26.9 0 0 0 9.788 1.831c3.463 0 6.766-.654 9.788-1.831z"
    />
    <path
      
      d="M372.87 270.217s-7.421 14.222-7.421 28.445h37.101c0-14.222-7.421-28.445-7.421-28.445l-11.13-7.111z"
    />
    <path
      
      d="M395.13 270.217v-3.555c0-5.891-4.983-10.666-11.13-10.666s-11.13 4.776-11.13 10.666v3.555z"
    />
    <path
      
      d="M256 85.333v30.553l-45.167 25.099H256v59.359h-59.103L256 233.179v22.817h-26.68l-73.494-40.826v40.826h-55.652v-48.573l-87.43 48.573H0v-30.554l45.167-25.098H0v-59.359h59.103L0 108.139V85.333h26.68l73.494 40.825V85.333h55.652v48.572l87.43-48.572z"
    />
    <path
      
      d="M144 85.33h-32v69.332H0v32h112v69.334h32v-69.334h112v-32H144z"
    />
    <path
      
      d="M155.826 200.344 256 255.996v-15.737l-71.847-39.915z"
    />
    <path
      
      d="M155.826 200.344 256 255.996v-15.737l-71.847-39.915z"
    />
    <path
      
      d="M155.826 200.344 256 255.996v-15.737l-71.847-39.915zm-83.98 0L0 240.259v15.737l100.174-55.652z"
    />
    <path  d="M100.174 140.982 0 85.33v15.737l71.847 39.915z" />
    <path  d="M100.174 140.982 0 85.33v15.737l71.847 39.915z" />
    <path
      
      d="M100.174 140.982 0 85.33v15.737l71.847 39.915zm83.98 0L256 101.067V85.33l-100.174 55.652z"
    />
  </svg>
);
export default IconTc;
