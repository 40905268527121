import React, { createContext, useContext, useState, useEffect } from 'react';

const BasketContext = createContext();

export const BasketProvider = ({ children }) => {
    const [basketItems, setBasketItems] = useState([]);
    const deliveryCost = 2

    useEffect(() => {
        // Load basket items from localStorage when the component mounts
        const items = JSON.parse(localStorage.getItem('basket')) || [];
        setBasketItems(items);
    }, []);

    const addToBasket = (product) => {
        setBasketItems(prevItems => {
            const existingItemIndex = prevItems.findIndex(item => item.id === product.id);
            let newItems;

            if (existingItemIndex >= 0) {
                // If item exists, increment quantity
                newItems = prevItems.map((item, index) => {
                    if (index === existingItemIndex) {
                        return { ...item, quantity: (item.quantity || 1) + 1 };
                    }
                    return item;
                });
            } else {
                // If item doesn't exist, add it with quantity 1
                newItems = [...prevItems, { ...product, quantity: 1 }];
            }

            // Update localStorage
            localStorage.setItem('basket', JSON.stringify(newItems));
            return newItems;
        });
    };

    const removeFromBasket = (productId) => {
        setBasketItems(prevItems => {
            const newItems = prevItems.filter(item => item.id !== productId);
            localStorage.setItem('basket', JSON.stringify(newItems));
            return newItems;
        });
    };

    const updateQuantity = (productId, quantity) => {
        setBasketItems(prevItems => {
            const newItems = prevItems.map(item => {
                if (item.id === productId) {
                    return { ...item, quantity: quantity };
                }
                return item;
            });
            localStorage.setItem('basket', JSON.stringify(newItems));
            return newItems;
        });
    };

    const clearBasket = () => {
        setBasketItems([]);
        localStorage.removeItem('basket');
    };

    const getBasketTotal = () => {
        return (basketItems.reduce((total, item) => total + (item.price * item.quantity), 0) + deliveryCost).toFixed(2);
    };

    const getBasketSubTotal = () => {
        return basketItems.reduce((total, item) => total + (item.price * item.quantity), 0).toFixed(2);
    };

    const getDeliveryCost = () => {
        return deliveryCost.toFixed(2);
    };

    return (
        <BasketContext.Provider value={{
            basketItems,
            addToBasket,
            removeFromBasket,
            updateQuantity,
            clearBasket,
            getBasketTotal,
            getBasketSubTotal,
            getDeliveryCost
        }}>
            {children}
        </BasketContext.Provider>
    );
};

export const useBasket = () => useContext(BasketContext);
