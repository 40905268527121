import * as React from "react";
const IconCalculatorAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M18.7 34.6H21v2.3c0 .6.4 1 1 1s1-.4 1-1v-2.3h2.3c.6 0 1-.4 1-1s-.4-1-1-1H23v-2.3c0-.6-.4-1-1-1s-1 .4-1 1v2.3h-2.3c-.6 0-1 .4-1 1s.5 1 1 1M45 30.4c-.4-.4-1-.4-1.4 0l-1.8 1.8-1.8-1.8c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l1.8 1.8-1.8 1.8c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3l1.8-1.8 1.8 1.8c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-1.8-1.8 1.8-1.8c.4-.4.4-1 0-1.4M24.5 50.1h-4.9c-.6 0-1 .4-1 1s.4 1 1 1h4.9c.6 0 1-.4 1-1s-.4-1-1-1M43.6 47.9l-4.9 4.9c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3l4.9-4.9c.4-.4.4-1 0-1.4s-1-.4-1.4 0" />
    <path d="M48.1 3H15.9C13.2 3 11 5.2 11 7.9v48.3c0 2.7 2.2 4.9 4.9 4.9H48c2.7 0 4.9-2.2 4.9-4.9V7.9c0-2.7-2.2-4.9-4.8-4.9M15.9 5H48c1.6 0 2.9 1.3 2.9 2.9V23H13.1V7.9c0-1.6 1.3-2.9 2.8-2.9m-2.8 51.1V25H31v16.7H13.9v2H31V59H15.9c-1.5 0-2.8-1.3-2.8-2.9m35 2.9H33V43.7h17.1v-2H33V25h17.9v31.1c0 1.6-1.3 2.9-2.8 2.9" />
    <path d="M18.8 19h26.4c.6 0 1-.4 1-1v-6.8c0-.6-.4-1-1-1H18.8c-.6 0-1 .4-1 1V18c0 .6.4 1 1 1m1-6.8h24.4V17H19.8z" />
  </svg>
);
export default IconCalculatorAlt1;
