import * as React from "react";
const IconTriangle7 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M19.6 38.9c-1.1-1.8-2.9-2.9-5-2.9s-4 1.1-5 2.9L2.8 50.5c-1.1 1.8-1.1 4 0 5.8s2.9 2.9 5 2.9h13.4c2.1 0 4-1.1 5-2.9 1.1-1.8 1.1-4 0-5.8zm4.9 16.4c-.7 1.2-1.9 1.9-3.3 1.9H7.8c-1.4 0-2.6-.7-3.3-1.9a3.7 3.7 0 0 1 0-3.8l6.7-11.6c.7-1.2 1.9-1.9 3.3-1.9s2.6.7 3.3 1.9l6.7 11.6c.7 1.2.7 2.6 0 3.8M61.2 50.5l-6.7-11.6c-1.1-1.8-2.9-2.9-5-2.9s-4 1.1-5 2.9l-6.7 11.6c-1.1 1.8-1.1 4 0 5.8s2.9 2.9 5 2.9h13.4c2.1 0 4-1.1 5-2.9 1.1-1.8 1.1-4 0-5.8m-1.7 4.8c-.7 1.2-1.9 1.9-3.3 1.9H42.8c-1.4 0-2.6-.7-3.3-1.9a3.7 3.7 0 0 1 0-3.8l6.7-11.6c.7-1.2 1.9-1.9 3.3-1.9s2.6.7 3.3 1.9l6.7 11.6c.7 1.2.7 2.6 0 3.8M25.3 28h13.4c2.1 0 4-1.1 5-2.9 1.1-1.8 1-4 0-5.8L37 7.7c-1.1-1.8-2.9-2.9-5-2.9s-4 1.1-5 2.9l-6.7 11.6c-1.1 1.8-1.1 4 0 5.8 1 1.8 2.9 2.9 5 2.9M22 20.3l6.7-11.6c.7-1.2 1.9-1.9 3.3-1.9s2.6.7 3.3 1.9L42 20.3c.7 1.2.7 2.6 0 3.8S40.1 26 38.7 26H25.3c-1.4 0-2.6-.7-3.3-1.9a3.7 3.7 0 0 1 0-3.8" />
  </svg>
);
export default IconTriangle7;
