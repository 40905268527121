import * as React from "react";
const IconInjury = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m59.9 41.9-10-9.9 8.6-8.6c1.7-1.7 2.6-3.9 2.6-6.3s-.9-4.6-2.6-6.3l-5.4-5.4c-1.7-1.7-3.9-2.6-6.3-2.6s-4.6.9-6.3 2.6L32 14.1l-9.9-9.9c-2.7-2.7-7.2-2.7-9.9 0l-8 8c-2.7 2.7-2.7 7.2 0 9.9l9.9 9.9-8.6 8.6c-1.7 1.7-2.6 3.9-2.6 6.3s.9 4.6 2.6 6.3l5.4 5.4c1.7 1.7 3.9 2.6 6.3 2.6s4.6-.9 6.3-2.6l8.6-8.6 9.9 9.9c1.4 1.4 3.2 2 4.9 2 1.8 0 3.6-.7 5-2l8-8c1.3-1.3 2.1-3.1 2.1-4.9q-.15-3-2.1-5.1M42 6.9c1.3-1.3 3-2 4.9-2s3.6.7 4.9 2l5.4 5.4c1.3 1.3 2 3 2 4.9 0 1.8-.7 3.6-2 4.9l-8.6 8.6-15.2-15.2zM22 57.1c-1.3 1.3-3 2-4.9 2s-3.6-.7-4.9-2l-5.4-5.4c-1.3-1.3-2-3-2-4.9 0-1.8.7-3.6 2-4.9l8.6-8.6 15.1 15.1zm36.5-6.7-8 8c-1.9 1.9-5.1 1.9-7.1 0L5.5 20.7c-1.9-1.9-1.9-5.1 0-7.1l8-8c.9-.9 2.2-1.5 3.5-1.5s2.6.5 3.5 1.5l37.8 37.8c.9.9 1.5 2.2 1.5 3.5.1 1.3-.4 2.6-1.3 3.5" />
    <path d="M28.8 20.6c-.4-.4-1-.4-1.4 0l-6.7 6.7c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l6.7-6.7c.4-.4.4-1 0-1.4M36.2 28c-.4-.4-1-.4-1.4 0l-6.7 6.7c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l6.7-6.7c.4-.4.4-1 0-1.4M42.2 35.3 35.5 42c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l6.7-6.7c.4-.4.4-1 0-1.4s-1-.3-1.4 0" />
  </svg>
);
export default IconInjury;
