import * as React from "react";
const IconBat = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 70 60"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M64.2 15.8c-.1-.4-.4-.6-.8-.7s-.8.1-1 .4c-.9 1.3-1.9 2.6-3.1 3.5-1 .7-2.6 1-3.7.3-.3-.2-.6-.4-.9-.7-.8-.7-1.8-1.6-3.1-1.3-.9.1-1.5.7-2 1.2l-.1.1c-.8.7-1.4 1.1-2 1.2-.7.1-1.3-.1-1.7-.5-.4-.5-.4-1.2-.4-2V5.6c0-.4-.2-.8-.6-.9-.4-.2-.8-.1-1.1.2l-4.4 4.4c-2.5-1.8-6.1-1.8-8.6 0l-4.4-4.4c-.3-.3-.7-.4-1.1-.2q-.6.3-.6.9l.1 10.5c0 .9 0 1.8-.3 2.5-.1.2-.2.4-.3.5-.3.4-.8.6-1.3.6-1 .1-2.2-.4-3.1-1.2l-.1-.1c-.4-.3-.8-.7-1.4-.9-1.5-.5-2.7.5-3.7 1.3-.4.4-.9.7-1.3.9-1.1.5-2.4-.2-3.2-1.1-.9-1.1-1.3-2.7-1.7-4.1-.1-.4-.5-.7-.9-.7s-.8.2-1 .6c-.1.3-.2.5-.3.8-3.4 9.5 1.3 20.9 10.6 25.4 2 1 4.1 1.6 6.2 2.2 1.4.4 2.9.9 4.3 1.4 2 .8 3.4 1.6 4.3 2.7 1.8 2.1 2.2 5.3 2.6 7.8.1.5.5.9 1 .9s.9-.4 1-.9c.4-3.3.9-6.4 2.9-8.3 1.2-1.1 2.9-1.8 4.5-2.3.8-.3 1.6-.5 2.4-.8 3.6-1.2 7.3-2.4 10.5-4.6 7-5.1 10.3-14.6 7.8-22.9m-9 21.1c-2.9 2-6.5 3.2-9.9 4.3-.8.3-1.6.5-2.5.8-1.8.6-3.7 1.4-5.2 2.8-1.3 1.2-2 2.7-2.5 4.3-.4-1.3-1.1-2.6-2-3.7-1.5-1.7-3.6-2.7-5.1-3.3s-3-1-4.4-1.4c-2.1-.6-4-1.2-5.9-2.1C9.9 34.9 5.7 26 7.4 18c.3.6.6 1.2 1.1 1.8 1.2 1.5 3.5 2.5 5.5 1.6.7-.3 1.2-.8 1.7-1.2.8-.7 1.3-1 1.7-.9.2.1.4.3.7.5l.2.1c1.3 1.2 3 1.8 4.6 1.8 1.1-.1 2-.5 2.7-1.3.3-.3.5-.6.6-1 .5-1 .5-2.2.5-3.3V8l3.3 3.3c.4.4 1 .4 1.4 0 1.9-1.8 5.4-1.8 7.4 0 .4.4 1 .4 1.4 0L43.4 8v9.2c0 .9 0 2.2.8 3.3.8 1 2.2 1.5 3.6 1.2 1-.2 1.9-.7 3-1.7l.1-.1c.4-.3.7-.6 1-.7.4-.1.8.3 1.5.9.3.3.7.6 1.1.9 1.6 1 3.9.9 5.9-.4.9-.6 1.7-1.4 2.4-2.2 1.1 6.9-1.9 14.5-7.6 18.5" />
    <path d="M32 15.8c-1.1 0-1.9.9-1.9 1.9s.9 1.9 1.9 1.9 1.9-.9 1.9-1.9-.8-1.9-1.9-1.9M38 15.8c-1.1 0-1.9.9-1.9 1.9s.9 1.9 1.9 1.9 1.9-.9 1.9-1.9-.8-1.9-1.9-1.9" />
  </svg>
);
export default IconBat;
