import * as React from "react";
const IconInpatient = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M16.096 11.105a3.77 3.77 0 0 0-5.333-.002l-.704.704a3.95 3.95 0 0 0-3.892 1.021 4.034 4.034 0 0 0 0 5.676l.833.839V30H4v2h2.05a3.5 3.5 0 1 0 4.899 0h26.102a3.5 3.5 0 1 0 4.899 0H44v-2h-4v-3.113a3.96 3.96 0 0 0 3-3.854c0-2.19-1.761-3.967-3.934-3.967H18.11a.3.3 0 0 1-.218-.09l-.366-.37.369-.367a3.77 3.77 0 0 0 .001-5.333zm.02 6.083.365-.364a1.77 1.77 0 0 0 0-2.504l-1.8-1.8a1.77 1.77 0 0 0-2.504-.002l-.35.35zM41 23.033c0-1.102-.882-1.967-1.934-1.967H18.11a2.3 2.3 0 0 1-1.636-.68l-6.106-6.148a1.953 1.953 0 0 0-2.782 0 2.034 2.034 0 0 0 0 2.857L15.438 25h23.628C40.12 25 41 24.135 41 23.033m-26.725 3.634c.212.213.498.333.797.333H38v3H9v-8.644zM10 34.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0M39.5 36a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3"
      clipRule="evenodd"
    />
  </svg>
);
export default IconInpatient;
