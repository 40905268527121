import * as React from "react";
const IconBadge3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.9 43.3c1.6-3.5 2.5-7.3 2.5-11.3s-.9-7.9-2.5-11.3l1.1-2.8c.1-.3.1-.6-.1-.9s-.5-.4-.8-.5l-2.7-.2c-4.5-6.4-11.7-10.9-20-11.6l-1.7-2.3c-.4-.5-1.3-.5-1.6 0l-1.7 2.3c-8.2.7-15.5 5.2-20 11.6l-2.7.2c-.3 0-.6.2-.8.5s-.1.6.1.9l1.1 2.8C5.5 24.1 4.6 28 4.6 32s.9 7.9 2.5 11.3L6 46.1c-.2.3-.1.7 0 .9.2.3.5.4.8.5l2.7.2c4.5 6.4 11.7 10.9 20 11.6l1.7 2.3c.2.3.5.4.8.4s.6-.2.8-.4l1.7-2.3c8.2-.7 15.5-5.2 20-11.6l2.7-.2c.3 0 .6-.2.8-.5s.2-.6.1-.9zM32 57.4h-1.4L23.1 47c-.2-.2-.5-.4-.8-.4l-11.7-.7c-.5-.8-1-1.6-1.4-2.5l4.3-10.9c.1-.2.1-.5 0-.7L9.3 20.7c.4-.9.9-1.7 1.4-2.5l11.7-.7c.3 0 .6-.2.8-.4l7.5-10.4h2.8L41 17.1c.2.2.5.4.8.4l11.7.7c.5.8 1 1.6 1.4 2.5l-4.3 10.9c-.1.2-.1.5 0 .7l4.3 10.9c-.4.9-.9 1.7-1.4 2.5l-11.7.7c-.3 0-.6.2-.8.4l-7.5 10.4c-.6.2-1 .2-1.5.2M57.4 32c0 3-.5 6-1.5 8.7L52.4 32l3.5-8.7c.9 2.7 1.5 5.7 1.5 8.7m-5.6-15.8-9.6-.6L36 7c6.4 1 12 4.4 15.8 9.2M28 6.9l-6.2 8.6-9.6.6C16 11.4 21.6 8 28 6.9M6.6 32c0-3 .5-6 1.5-8.7l3.4 8.7-3.4 8.7C7.2 38 6.6 35 6.6 32m5.6 15.8 9.6.6L28 57c-6.4-1-12-4.4-15.8-9.2M36 57.1l6.2-8.6 9.6-.6C48 52.6 42.4 56 36 57.1" />
    <path d="M32 23.1c-4.9 0-8.9 4-8.9 8.9s4 8.9 8.9 8.9 8.9-4 8.9-8.9-4-8.9-8.9-8.9m0 15.8c-3.8 0-6.9-3.1-6.9-6.9s3.1-6.9 6.9-6.9 6.9 3.1 6.9 6.9-3.1 6.9-6.9 6.9" />
  </svg>
);
export default IconBadge3;
