import * as React from "react";
const IconCornerDoubleLeftUp = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54 59H28.9c-2.6 0-4.7-2.1-4.7-4.7v-34l11.5 11.5c.4.4 1 .4 1.4 0s.4-1 0-1.4L23.9 17.1c-.4-.4-1-.4-1.4 0L9.3 30.3c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l11.5-11.5v34c0 3.7 3 6.7 6.7 6.7H54c.6 0 1-.4 1-1s-.4-.9-1-.9" />
    <path d="M10 18.2c.3 0 .5-.1.7-.3L23.2 5.4l12.5 12.5c.4.4 1 .4 1.4 0s.4-1 0-1.4L23.9 3.3c-.4-.4-1-.4-1.4 0L9.3 16.5c-.4.4-.4 1 0 1.4.2.2.4.3.7.3" />
  </svg>
);
export default IconCornerDoubleLeftUp;
