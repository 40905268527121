import * as React from "react";
const IconPlusCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 31H33V3c0-.6-.4-1-1-1s-1 .4-1 1v28H3c-.6 0-1 .4-1 1s.4 1 1 1h28v28c0 .6.4 1 1 1s1-.4 1-1V33h28c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconPlusCopy;
