import * as React from "react";
const IconFactoryAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m58.9 24.1-15.2-6.4c-1.2-.5-2.7-.5-3.9 0l-16.5 6.9-2.8-17.5c-.4-2.4-2.5-4.2-5-4.2h-5.1C8 2.9 5.9 4.6 5.4 7L2 25.2v30.9c0 2.8 2.3 5 5 5h24c2.8 0 5-2.3 5-5v-4.3c0-1.7 1.4-3 3-3h5.8c1.7 0 3 1.4 3 3v4.3c0 2.8 2.3 5 5 5h4c2.8 0 5-2.3 5-5V28.7c.2-2-1-3.8-2.9-4.6M7 59.1c-1.7 0-3-1.4-3-3V25.5L7.4 7.3c.3-1.4 1.5-2.5 3-2.5h5.1c1.5 0 2.8 1.1 3 2.5L21.6 26v33H7zm53-3c0 1.7-1.4 3-3 3h-4c-1.7 0-3-1.4-3-3v-4.3c0-2.8-2.3-5-5-5h-5.8c-2.8 0-5 2.3-5 5v4.3c0 1.7-1.4 3-3 3h-7.5V26.7l17-7.2c.7-.3 1.6-.3 2.4 0l15.2 6.4c1.1.5 1.9 1.6 1.9 2.8v27.4z" />
  </svg>
);
export default IconFactoryAlt;
