import * as React from "react";
const IconTagCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.1 16.4c0-1.6-1.3-2.9-2.9-2.9l-9.6-.2C40.1 7.6 33.2.5 21.5 2.3c-.5.1-.9.6-.8 1.1s.6.9 1.1.8c10-1.6 16.1 4 19.5 8.9l-8.9-.1c-.8 0-1.6.3-2.2.9L9 35.1c-.9.9-1.5 2.2-1.5 3.5S8 41.2 9 42.2l18.3 18.3c1 1 2.3 1.5 3.5 1.5s2.6-.5 3.5-1.5l21.2-21.2c.6-.6.9-1.4.9-2.2zm-1.9 21.5L33 59.1c-1.2 1.2-3.1 1.2-4.2 0L10.4 40.8q-.9-.9-.9-2.1c0-1.2.3-1.5.9-2.1l21.2-21.2c.2-.2.4-.3.7-.3l10.2.2c.9 1.6 1.5 3 1.9 3.9-1.8.6-3.1 2.3-3.1 4.3 0 2.5 2.1 4.6 4.6 4.6s4.6-2.1 4.6-4.6c0-2.3-1.7-4.2-4-4.5-.3-.7-.8-2.1-1.7-3.7l8.4.2c.5 0 1 .4 1 1l.4 20.8c-.1.1-.2.4-.4.6m-8.4-17c1.4 0 2.6 1.2 2.6 2.6s-1.2 2.6-2.6 2.6-2.6-1.2-2.6-2.6 1.2-2.6 2.6-2.6" />
  </svg>
);
export default IconTagCopy;
