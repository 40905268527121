import * as React from "react";
const IconIronTable = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 18.4H3c-.6 0-1 .4-1 1s.4 1 1 1h8.2L30.1 32 11 43.7c-.5.3-.6.9-.3 1.4.2.3.5.5.9.5.2 0 .4 0 .5-.1l20-12.2 20 12.2c.2.1.3.1.5.1.3 0 .7-.2.9-.5.3-.5.1-1.1-.3-1.4L33.9 32l18.9-11.6H61c.6 0 1-.4 1-1s-.4-1-1-1M32 30.8 15 20.4h34z" />
  </svg>
);
export default IconIronTable;
