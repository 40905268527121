import * as React from "react";
const IconMonitorHeartAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M17 26.1h5.5c.2 0 .3.1.4.2l3.3 7.8c.2.5.7.8 1.2.8q.9 0 1.2-.9l5.6-14.3 5.1 11.8c.4.9 1.2 1.4 2.2 1.4H47c.6 0 1-.4 1-1s-.4-1-1-1h-5.4c-.2 0-.3-.1-.4-.2l-5.7-13.2c-.2-.5-.7-.9-1.3-.8-.6 0-1.1.4-1.3.9l-5.5 14.2-2.7-6.3c-.4-.9-1.2-1.4-2.2-1.4H17c-.6 0-1 .4-1 1s.5 1 1 1" />
    <path d="M55 6.6H9c-3.9 0-7 3.1-7 7v36.8c0 3.9 3.1 7 7 7h46c3.8 0 7-3.1 7-7V13.6c0-3.9-3.1-7-7-7m-46 2h46c2.7 0 5 2.2 5 5v27.7H4V13.6c0-2.8 2.2-5 5-5m46 46.8H9c-2.7 0-5-2.2-5-5v-7.1h56v7.1c0 2.8-2.2 5-5 5" />
    <path d="M22 48.1h-4.8c-.6 0-1 .4-1 1s.4 1 1 1H22c.6 0 1-.4 1-1s-.5-1-1-1M34.4 48.1h-4.8c-.6 0-1 .4-1 1s.4 1 1 1h4.8c.6 0 1-.4 1-1s-.4-1-1-1M46.8 48.1H42c-.6 0-1 .4-1 1s.4 1 1 1h4.8c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconMonitorHeartAlt;
