import * as React from "react";
const IconMobileCoin = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M44.1 21.9h-3.9c-1.6 0-2.9-1.1-2.9-2.5s1.3-2.5 2.9-2.5h7.7c.6 0 1-.4 1-1s-.4-1-1-1h-3.7v-1.4c0-.6-.4-1-1-1s-1 .4-1 1v1.4h-2c-2.7 0-4.9 2-4.9 4.5s2.2 4.5 4.9 4.5h3.9c1.6 0 2.9 1.1 2.9 2.5S45.7 29 44.1 29h-8c-.6 0-1 .4-1 1s.4 1 1 1h6.1v1.4c0 .6.4 1 1 1s1-.4 1-1V31c2.6 0 4.8-2.1 4.8-4.5-.1-2.5-2.2-4.6-4.9-4.6M34.6 44.2c-.6 0-1 .4-1 1v11.7c0 1.7-1.4 3.2-3.2 3.2H7.2C5.4 60 4 58.6 4 56.8V17.7c0-1.7 1.4-3.2 3.2-3.2h16.9c.6 0 1-.4 1-1s-.4-1-1-1H7.2c-2.8 0-5.2 2.3-5.2 5.2v39.1C2 59.7 4.3 62 7.2 62h23.3c2.8 0 5.2-2.3 5.2-5.2V45.2c-.1-.6-.5-1-1.1-1" />
    <path d="M56.8 8c-3.4-3.7-8.1-5.8-13.1-6-2.8-.1-5.5.4-8 1.5-.5.2-.7.8-.5 1.3s.8.7 1.3.5c2.3-1 4.7-1.4 7.1-1.3 4.5.2 8.6 2.1 11.7 5.4 3.2 3.4 4.8 7.9 4.7 12.6-.2 5.9-3.3 11.3-8.4 14.4-.5.3-.6.9-.3 1.4.2.3.5.5.9.5.2 0 .4 0 .5-.1 5.6-3.4 9.1-9.4 9.3-16 .2-5.4-1.7-10.4-5.2-14.2M21.5 20.4h-5.3c-.6 0-1 .4-1 1s.4 1 1 1h5.3c.6 0 1-.4 1-1s-.5-1-1-1M18.8 49.4c-2 0-3.6 1.6-3.6 3.6s1.6 3.6 3.6 3.6 3.6-1.6 3.6-3.6-1.6-3.6-3.6-3.6m0 5.3c-.9 0-1.6-.7-1.6-1.6s.7-1.6 1.6-1.6 1.6.7 1.6 1.6-.7 1.6-1.6 1.6" />
  </svg>
);
export default IconMobileCoin;
