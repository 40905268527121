import * as React from "react";
const IconAmexCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.7 14.9H6.3c-2.9 0-5.3 2.4-5.3 5.3v23.7c0 2.9 2.4 5.3 5.3 5.3h51.5c2.9 0 5.3-2.4 5.3-5.3V20.1c-.1-2.9-2.5-5.2-5.4-5.2m3.3 29c0 1.8-1.5 3.3-3.3 3.3H6.3c-1.8 0-3.3-1.5-3.3-3.3V20.1c0-1.8 1.5-3.3 3.3-3.3h51.5c1.8 0 3.3 1.5 3.3 3.3v23.8z" />
    <path d="m26.9 35.6-3-10.3h-3.7v12.4l-3.1-12.4h-4.2L9.7 38.5h2.1l.8-2.8h5l.7 2.8h4.2V27.8h.3l3.1 10.1h2.2l3-10.1h.3v10.7h2.1V25.3h-3.6zm-14-1.7 1.6-6.7h1l1.6 6.7zM52 25.3l-2.9 5.1-2.8-5.1H35.4v13.2h11l2.7-5 2.8 5h2.4L50.5 32l3.8-6.7zm-7 11.3h-7.5v-3.8h5V31h-4.9v-3.6H45l2.8 4.9z" />
  </svg>
);
export default IconAmexCopy;
