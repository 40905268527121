import * as React from "react";
const IconPopcorn = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M52.1 23.1c.7-1.3 1.2-2.7 1.2-4.2 0-4.5-3.7-8.3-8.3-8.3h-.5v-.5c0-4.5-3.7-8.3-8.3-8.3-2.6 0-5 1.2-6.5 3.2-.7-.2-1.4-.3-2.2-.3-3.7 0-6.8 2.4-7.9 5.8h-.8c-4.5 0-8.3 3.7-8.3 8.3q0 2.25 1.2 4.2c-.2 0-.4.1-.5.3-.2.2-.3.5-.3.8l2.6 34.3c.1.9.5 1.7 1 2.3.7.7 1.7 1.2 2.8 1.2h28.8c2 0 3.7-1.5 3.8-3.5l2.6-34.3c0-.3-.1-.6-.3-.8.3 0 .1-.1-.1-.2M15.8 58.4l-1-13.5 19.8-19.8h14.6zm-2.6-33.3h18.5L14.5 42.3zM19 12.7c.5 0 .9 0 1.3.1.3.1.5 0 .8-.1s.4-.4.4-.7c.5-3 3.1-5.2 6.1-5.2.8 0 1.5.1 2.2.4.4.2 1 0 1.2-.4C32.2 5.1 34.2 4 36.3 4c3.4 0 6.3 2.8 6.3 6.3 0 .5-.1 1-.2 1.4-.1.3 0 .7.3 1s.6.4 1 .3.9-.2 1.4-.2c3.4 0 6.3 2.8 6.3 6.3 0 1.4-.4 2.7-1.2 3.7-.1.1-.1.3-.2.4H14.1c0-.1-.1-.3-.2-.4-.8-1.1-1.2-2.4-1.2-3.7 0-3.6 2.8-6.4 6.3-6.4m-1.9 47.2 33.6-33.6-1.2 15.8L31.6 60h-14c-.2 0-.4 0-.5-.1m31.1-1.6c-.1.9-.9 1.7-1.8 1.7h-12l14.8-14.8z" />
  </svg>
);
export default IconPopcorn;
