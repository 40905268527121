import * as React from "react";
const IconGiftAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.3 15.2H43.2c3.7-.9 7.3-2.3 8.2-4.7.7-1.8-.1-3.7-2.2-5.8-2.4-2.3-4.8-3.1-7.3-2.5-5.4 1.4-8.6 9.9-9.5 12.3-.8-2.4-4.1-10.9-9.5-12.3-2.4-.6-4.9.2-7.3 2.5-2.1 2.1-2.9 4-2.2 5.8.9 2.4 4.4 3.8 8.2 4.7H8.7c-2.1 0-3.7 1.7-3.7 3.7v7.6c0 2.1 1.7 3.7 3.7 3.7h1.5v26.6c0 2.8 2.3 5.1 5.1 5.1h34c2.8 0 5.1-2.3 5.1-5.1V30.2h.8c2.1 0 3.7-1.7 3.7-3.7v-7.6c.1-2-1.6-3.7-3.6-3.7m-13-11.1c.4-.1.7-.1 1.1-.1 1.4 0 2.8.7 4.3 2.2 1.5 1.4 2.1 2.7 1.7 3.6-1 2.8-9.2 4.2-14.9 4.6 1.3-3.4 4.2-9.3 7.8-10.3m-27 5.7c-.4-.9.2-2.2 1.7-3.6C18.5 4.7 20 4 21.4 4c.3 0 .7 0 1 .1 3.6.9 6.5 6.8 7.8 10.3-5.8-.4-13.9-1.9-14.9-4.6m37.2 47.1c0 1.7-1.4 3.1-3.1 3.1h-34c-1.7 0-3.1-1.4-3.1-3.1V30.2h40.2zm4.4-30.4c0 1-.8 1.7-1.7 1.7H8.7c-1 0-1.7-.8-1.7-1.7v-7.6c0-1 .8-1.7 1.7-1.7h46.5c1 0 1.7.8 1.7 1.7z" />
  </svg>
);
export default IconGiftAlt;
