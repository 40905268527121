import * as React from "react";
const IconRight = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2.8C16 2.8 3 15.9 3 32s13 29.2 29 29.2S61 48.1 61 32 48 2.8 32 2.8m0 56.4c-2.7 0-5.2-.4-7.6-1.1V33.5c0-.4.3-.8.7-1l14.5-4.7-3.4 8.8c-.2.5.1 1.1.6 1.3.1 0 .2.1.4.1.4 0 .8-.2.9-.6.1-.3.3-.7.4-1l.9-2.4 1.2-3c.4-.9.7-1.8 1.1-2.7.1-.3.3-.6.4-1 .2-.4.4-.8.3-1.3-.2-.6-.8-.8-1.3-1-.7-.3-1.4-.5-2.1-.8-.9-.4-1.8-.7-2.7-1.1s-1.8-.7-2.7-1.1c-.6-.3-1.3-.5-1.9-.8-.2-.1-.4-.2-.6-.2-.5-.2-1.1 0-1.3.6s0 1.1.6 1.3l8.1 3.2-13.9 4.5c-1.2.4-2.1 1.5-2.1 2.9v23.9C12.2 53.5 5 43.6 5 32 5 17 17.1 4.8 32 4.8S59 17 59 32 46.9 59.2 32 59.2" />
  </svg>
);
export default IconRight;
