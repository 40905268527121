import * as React from "react";
const IconWindSun = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M33.4 19c-1.2-.3-2.4-.5-3.7-.5-7.4 0-13.4 6-13.4 13.4s6 13.4 13.4 13.4q1.35 0 2.7-.3c.5-.1.9-.6.8-1.2-.1-.5-.6-.9-1.2-.8-.8.2-1.5.2-2.3.2-6.3 0-11.4-5.1-11.4-11.4s5.1-11.4 11.4-11.4c1.1 0 2.1.1 3.1.4.5.2 1.1-.2 1.2-.7.2-.4-.1-1-.6-1.1M28.9 11.7c.6 0 1-.4 1-1V5.8c0-.6-.4-1-1-1s-1 .4-1 1v4.9c0 .6.4 1 1 1M28.9 52.3c-.6 0-1 .4-1 1v4.9c0 .6.4 1 1 1s1-.4 1-1v-4.9c0-.6-.5-1-1-1M13.1 46.3l-3.5 3.5c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3l3.5-3.5c.4-.4.4-1 0-1.4-.3-.4-1-.4-1.4 0M7.9 31H3c-.6 0-1 .4-1 1s.4 1 1 1h4.9c.6 0 1-.4 1-1s-.4-1-1-1M11.1 12.8c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l3.5 3.5c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4zM56.7 15.7c-.6 0-1 .4-1 1s.4 1 1 1c1.8 0 3.3 1.5 3.3 3.3s-1.5 3.3-3.3 3.3H41.6c-.6 0-1 .4-1 1s.4 1 1 1h15.1c2.9 0 5.3-2.4 5.3-5.3 0-3-2.4-5.3-5.3-5.3M53.9 40.8H41.6c-.6 0-1 .4-1 1s.4 1 1 1h12.3c1.8 0 3.3 1.5 3.3 3.3s-1.5 3.3-3.3 3.3c-.6 0-1 .4-1 1s.4 1 1 1c2.9 0 5.3-2.4 5.3-5.3s-2.4-5.3-5.3-5.3M56.9 33.6c0-.6-.4-1-1-1H37.6c-.6 0-1 .4-1 1s.4 1 1 1h18.3c.6 0 1-.5 1-1" />
  </svg>
);
export default IconWindSun;
