import * as React from "react";
const IconThermometer = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M23.995 44.077a7.96 7.96 0 0 0 3.963-1.053l.05.05.198-.197a8 8 0 0 0 3.782-6.806 7.99 7.99 0 0 0-3.056-6.283 5 5 0 0 0 .05-.715l-.016-20a5 5 0 0 0-10 .008l.016 20q0 .365.051.715a8 8 0 0 0 4.961 14.282m-3.716-12.716-.006.004q-.323.256-.609.552l8.49 8.477q.3-.289.557-.615zm-2.071 3.1q.125-.446.312-.86l7.952 7.94q-.413.188-.858.313zm-.122 2.704a6.01 6.01 0 0 0 4.824 4.816zm2.9-7.924 8.709 8.694q.15-.46.224-.947l-8.938-8.923.001 1.015q0 .081.005.161m-.007-4.004 8.551 8.537a6 6 0 0 0-1.835-2.415 2 2 0 0 1-.749-1.336l-5.953-5.943h-.015zm2.842-1.16 3.16 3.155-.001-1.172-1.987-1.983zm-2.844-1.997 6-.005-.002-2-2 .002a1 1 0 1 1-.002-2l2-.002-.002-2-2 .002a1 1 0 0 1-.001-2l2-.002-.002-2-2 .002a1 1 0 0 1-.001-2l2-.002-.001-1a3 3 0 0 0-6 .005z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconThermometer;
