import * as React from "react";
const IconDiagnosis = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M48 11.8h-3.7V9.6c0-1.1-.9-2-2-2H33V3c0-.6-.4-1-1-1s-1 .4-1 1v4.6h-9.2c-1.1 0-2 .9-2 2v2.1H16c-2.8 0-5.1 2.3-5.1 5.1v40c0 2.8 2.3 5.1 5.1 5.1h32c2.8 0 5.1-2.3 5.1-5.1v-40c0-2.7-2.3-5-5.1-5M21.8 9.6h20.5v6.3H21.8zm29.3 47.3c0 1.7-1.4 3.1-3.1 3.1H16c-1.7 0-3.1-1.4-3.1-3.1v-40c0-1.7 1.4-3.1 3.1-3.1h3.7v2.1c0 1.1.9 2 2 2h20.5c1.1 0 2-.9 2-2v-2.1H48c1.7 0 3.1 1.4 3.1 3.1z" />
    <path d="M38.2 36.7H33v-5.4c0-.6-.4-1-1-1s-1 .4-1 1v5.4h-5.2c-.6 0-1 .4-1 1s.4 1 1 1H31v5.4c0 .6.4 1 1 1s1-.4 1-1v-5.4h5.2c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconDiagnosis;
