import * as React from "react";
const IconPavedRoadAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      d="M33 7a1 1 0 0 1 2 0v34a1 1 0 1 1-2 0zM13 7a1 1 0 0 1 2 0v34a1 1 0 1 1-2 0zM23 11a1 1 0 0 1 2 0v4a1 1 0 1 1-2 0zM23 22a1 1 0 0 1 2 0v4a1 1 0 1 1-2 0zM23 33a1 1 0 0 1 2 0v4a1 1 0 1 1-2 0z"
    />
  </svg>
);
export default IconPavedRoadAlt;
