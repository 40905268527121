import * as React from "react";
const IconCandleAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 25.7h-1.3v-6c3-.7 5.3-4.3 5.3-8.8 0-5-2.8-8.9-6.3-8.9s-6.3 3.9-6.3 8.9c0 4.5 2.2 8.1 5.3 8.8v6h-1.3c-2.2 0-4 1.8-4 4V58c0 2.2 1.8 4 4 4H58c2.2 0 4-1.8 4-4V29.7c0-2.2-1.8-4-4-4m-6.5-14.8c0-3.7 2-6.9 4.3-6.9C58 4 60 7.2 60 10.9s-2 6.9-4.3 6.9-4.2-3.1-4.2-6.9m2 16.8H58c1.1 0 2 .9 2 2V34l-8.5 4.3v-8.6c0-1.1.9-2 2-2M58 60h-4.5c-1.1 0-2-.9-2-2V40.5l8.5-4.3V58c0 1.1-.9 2-2 2M14.5 10.9c0-5-2.8-8.9-6.3-8.9C4.8 2 2 5.9 2 10.9c0 4.5 2.2 8.1 5.3 8.8v6H6c-2.2 0-4 1.8-4 4V58c0 2.2 1.8 4 4 4h4.5c2.2 0 4-1.8 4-4V29.7c0-2.2-1.8-4-4-4H9.3v-6c3-.7 5.2-4.3 5.2-8.8m-4 49.1H6c-1.1 0-2-.9-2-2v-4.3l8.5-4.3V58c0 1.1-.9 2-2 2m2-30.3v17.5L4 51.5V29.7c0-1.1.9-2 2-2h4.5c1.1 0 2 .9 2 2M4 10.9C4 7.2 6 4 8.3 4s4.3 3.2 4.3 6.9-2 6.9-4.3 6.9S4 14.7 4 10.9M38.3 10.9c0-5-2.8-8.9-6.3-8.9s-6.3 3.9-6.3 8.9c0 4.5 2.2 8.1 5.3 8.8v6h-1.3c-2.2 0-4 1.8-4 4V58c0 2.2 1.8 4 4 4h4.5c2.2 0 4-1.8 4-4V29.7c0-2.2-1.8-4-4-4H33v-6c3-.7 5.3-4.3 5.3-8.8m-2 18.8V58c0 1.1-.9 2-2 2h-4.5c-1.1 0-2-.9-2-2V29.7c0-1.1.9-2 2-2h4.5c1.1 0 2 .9 2 2m-8.6-18.8c0-3.7 2-6.9 4.3-6.9s4.3 3.2 4.3 6.9-2 6.9-4.3 6.9-4.3-3.1-4.3-6.9" />
  </svg>
);
export default IconCandleAlt;
