import * as React from "react";
const IconThumbsUpCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.3 26.9c-2.3-5.2-10.9-5-16-4.8h-1.6l.1-.4c1-5.4 1.8-12.6.6-14.7-1-1.9-2.8-3.1-5-3.3s-4.2.8-5.4 2.8C27 15.8 21.3 22 19.1 24.3c-.3.3-.7.5-1.2.5H5.6c-2 0-3.6 1.6-3.6 3.6v23.7c0 2 1.6 3.7 3.7 3.7h9.6l.9.6c1.8 1.2 6.1 2.8 16.2 3.7 1.2.1 2.3.1 3.5.1 6.8 0 13.2-1.7 17.6-4.6 8-5.2 10.5-20.4 6.8-28.7M4 52.1V28.4c0-.9.7-1.6 1.6-1.6h6.9v27H5.7c-.9 0-1.7-.8-1.7-1.7M52.4 54c-4.1 2.7-10.1 4.3-16.5 4.3-1.2 0-2.1 0-3.3-.1-10.2-.9-14-2.5-15.3-3.4l-1.1-.8c-.2-.1-.4-.2-.6-.2h-1.1v-27h3.4c1 0 1.9-.4 2.6-1.1 2.2-2.3 8.1-8.6 14.1-18.1.8-1.3 2.1-2 3.6-1.8 1.5.1 2.8.9 3.4 2.2.5 1 .6 5.8-.8 13.4l-.4 1.6c-.1.3 0 .6.2.8s.5.4.8.4h2.9c4.6-.1 12.3-.3 14.1 3.6 3.4 7.4 1.1 21.5-6 26.2" />
  </svg>
);
export default IconThumbsUpCopy;
