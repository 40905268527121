import * as React from "react";
const IconParachuteParcel = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2C19.6 2 9.5 12.1 9.5 24.5c0 .6.4 1 1 1h2.7l5.7 12.3c-1.7.5-2.9 2-2.9 3.8V58c0 2.2 1.8 4 4 4h24c2.2 0 4-1.8 4-4V41.6c0-1.8-1.2-3.4-2.9-3.8l5.7-12.3h2.7c.6 0 1-.4 1-1C54.5 12.1 44.4 2 32 2m-.8 35.6-5.6-12.2h12.8l-5.6 12.2zm-7.7-12.1v.2l5.5 12h-8l-5.6-12.2zM44 60H20c-1.1 0-2-.9-2-2V47h28v11c0 1.1-.9 2-2 2m2-18.4V45H18v-3.4c0-1.1.9-2 2-2h24c1.1 0 2 .9 2 2m-3-4h-8l5.5-12v-.2h8zm7.2-14.1H11.5C12.1 12.7 21 4 32 4s19.9 8.7 20.5 19.5z" />
    <path d="M39.6 53.7h-3.4c-.6 0-1 .4-1 1s.4 1 1 1h3.4c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconParachuteParcel;
