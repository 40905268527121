import * as React from "react";
const IconTruckLoad = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.7 27.7-5.6-10.2c-.4-.6-1-1-1.8-1h-7.7c-1.3 0-2.3 1-2.3 2.3v8.7h-4.5c-2.7-2.5-6.1-5.6-9.2-8.3-1.5-1.4-3-2.7-4.3-3.9-.7-.7-1.6-1-2.4-.8-.6.1-1.1.5-1.5.8-.5.5-1 1-1.4 1.5-.6.7-1.2 1.4-1.9 1.8-.6.3-1.3.4-2.1.4-.2 0-.4-.1-.7-.1-.8-.1-1.8-.3-2.8.2-.9.5-1.6 1.2-2.2 1.9l-.2.3c-1.3 1.5-2.6 3.2-4 5.5l-.2.2c-.1.1-.1.3-.1.4h-.4c-1 0-2 .7-2.2 1.7l-2 7.5c-.2.7 0 1.5.5 2.1l3.8 4.5c.4.5 1.1.8 1.8.8h2.4c.5 3.1 3.2 5.5 6.4 5.5 2.4 0 4.5-1.3 5.6-3.2 1.1 1.9 3.2 3.2 5.6 3.2 3.2 0 5.9-2.4 6.4-5.5h6.9c.5 3.1 3.2 5.5 6.4 5.5s5.9-2.4 6.4-5.5h2.7c.6 0 1.2-.2 1.5-.6l2.6-2.3c.5-.4.8-1.1.8-1.7V28.8c0-.4-.1-.8-.3-1.1m-15-9.3h7.7l4.9 9H46.4v-8.7c0-.1.1-.3.3-.3m-34.1 4.2.3-.3c.5-.6 1-1.2 1.6-1.4.4-.2.9-.2 1.6 0 .3 0 .5.1.8.1 1.1.1 2.3-.2 3.3-.7 1-.6 1.7-1.4 2.4-2.2.4-.5.8-.9 1.2-1.3.2-.2.4-.3.5-.3.2 0 .4.1.7.3 1.3 1.2 2.8 2.5 4.3 3.9 2.5 2.2 5.2 4.7 7.6 6.9H9c1.3-2.1 2.4-3.7 3.6-5m4.3 25c-2.5 0-4.5-2-4.5-4.5s2-4.5 4.5-4.5 4.5 2 4.5 4.5-2 4.5-4.5 4.5m11.2 0c-2.5 0-4.5-2-4.5-4.5s2-4.5 4.5-4.5 4.5 2 4.5 4.5-2 4.5-4.5 4.5m19.8 0c-2.5 0-4.5-2-4.5-4.5s2-4.5 4.5-4.5 4.5 2 4.5 4.5-2 4.5-4.5 4.5m12-7.9L57.3 42s-.1.1-.2.1h-2.7c-.5-3.1-3.2-5.5-6.4-5.5s-5.9 2.4-6.4 5.5h-6.9c-.5-3.1-3.2-5.5-6.4-5.5-2.4 0-4.5 1.3-5.6 3.2a6.47 6.47 0 0 0-5.6-3.2c-3.2 0-5.9 2.4-6.4 5.5H8.1c-.1 0-.2 0-.2-.1l-3.8-4.5c-.1-.1-.1-.2-.1-.3l2-7.5c0-.1.2-.2.3-.2H60v10c0 .1 0 .2-.1.2" />
  </svg>
);
export default IconTruckLoad;
