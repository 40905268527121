import * as React from "react";
const IconHighHeel3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.8 44.4c-.6-1.6-2.5-2.9-5.2-3.6l-.4-.1c-4.2-.8-6.9-1.5-8.1-2.2-3.3-1.7-5.4-4.6-7.1-7.2-5-7.4-9.1-15-12.5-23.2q-.3-.6-.9-.6h-21c-.3-.1-.6 0-.8.2-.2.3-.3.5-.2.8 0 .1.6 7.2-3.1 17.8-1 2.9-.3 5.3.7 6.9v22.2c0 .6.4 1 1 1h11.1c.6 0 1-.4 1-1V38.1c4.9 1.5 9.9 3.3 11.5 4.9 1.8 2.1 2.3 4.9 2.6 7.6l.1.8c.3 2.2.5 4 1.7 5.1.2.2.4.2.7.2H61c.5 0 1-.4 1-1v-.1c.1-10.5-.1-11-.2-11.2m-47.5-6.3v16.3H5.2V34.5c1.3.5 3.3 1.1 6.6 2.1.9.3 1.8.5 2.7.8-.1.3-.2.5-.2.7M60 54.6H33.2c-.5-.7-.7-2.1-.8-3.6l-.1-.7c-.3-3-.9-6.2-3.1-8.8-2.5-2.5-10.5-4.9-16.9-6.8-3.2-1-6.9-2.1-7.4-2.5-.8-1-1.4-3-.6-5.3 3-8.7 3.2-15.1 3.2-17.6h19.3c3.4 8.1 7.4 15.6 12.4 22.9 1.8 2.8 4.1 6 7.8 7.9 1.8 1 5.8 1.9 8.6 2.4l.4.1c2.1.5 3.4 1.5 3.7 2.3.3.7.3 3.5.3 9.7" />
  </svg>
);
export default IconHighHeel3;
