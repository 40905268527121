import * as React from "react";
const IconCellphoneVr = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.3 25.7H54v-2c0-3.1-2.5-5.7-5.7-5.7h-9c-3.1 0-5.7 2.5-5.7 5.7v2h-2.3c-3.1 0-5.7 2.5-5.7 5.7v9.9c0 3.1 2.5 5.7 5.7 5.7H39c.3 0 .5-.1.7-.3l4.1-4.1 4.1 4.1c.2.2.4.3.7.3h7.6c3.1 0 5.7-2.5 5.7-5.7v-9.9c.1-3.2-2.4-5.7-5.6-5.7m-16.9-5.6h9c2 0 3.7 1.6 3.7 3.7v2H35.7v-2c0-2.1 1.7-3.7 3.7-3.7m16.9 24.8h-7.2l-4.6-4.5c-.4-.4-1-.4-1.4 0l-4.6 4.5h-7.2c-2 0-3.7-1.6-3.7-3.7v-5.1h4.8c.6 0 1-.4 1-1s-.4-1-1-1h-4.8v-2.8c0-2 1.6-3.7 3.7-3.7h24.9c2 0 3.7 1.6 3.7 3.7v2.8h-4.8c-.6 0-1 .4-1 1s.4 1 1 1H60v5.1c0 2.1-1.6 3.7-3.7 3.7" />
    <path d="M35.9 52.2c-.6 0-1 .4-1 1 0 2.7-2.2 4.9-4.9 4.9H8.9c-2.7 0-4.9-2.2-4.9-4.9V10.8c0-2.7 2.2-4.9 4.9-4.9h21.2c2.7 0 4.9 2.2 4.9 4.9v1.5c0 .6.4 1 1 1s1-.4 1-1v-1.5c0-3.8-3.1-6.9-6.9-6.9H8.9C5.1 3.9 2 7 2 10.8v42.5c0 3.8 3.1 6.9 6.9 6.9h21.2c3.8 0 6.9-3.1 6.9-6.9-.1-.6-.5-1.1-1.1-1.1" />
    <path d="M17.9 13h3c.6 0 1-.4 1-1s-.4-1-1-1h-3c-.6 0-1 .4-1 1s.5 1 1 1M16.2 51.5c0 .6.4 1 1 1h4.5c.6 0 1-.4 1-1s-.4-1-1-1h-4.5c-.6 0-1 .4-1 1" />
  </svg>
);
export default IconCellphoneVr;
