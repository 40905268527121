import React from "react";
import BringzzImage from "../BringzzImage";
import BringzzText from "../BringzzText";

const BringzzCheckoutCard = ({
  onClick,
  imageUrl,
  imageSize,
  title,
  description,
  tooltip,
  price,
  className,
}) => {
  return (
    <div
      className={`hover:cursor-pointer flex flex-col w-[150px] min-w-[150px] items-center justify-center rounded-lg p-3 ${className}`}
      onClick={() => {
        onClick();
      }}
    >
      <BringzzImage src={imageUrl} width={imageSize}></BringzzImage>
      <BringzzText type={{ tag: "span", classes: "font-semibold text-sm" }}>
        {title}
      </BringzzText>
      <BringzzText className="font-regular text-[10px]">
        {description}
      </BringzzText>
      <BringzzText type={{ tag: "span", classes: "font-bold text-sm" }}>
        € {price}
      </BringzzText>
    </div>
  );
};

export default BringzzCheckoutCard;
