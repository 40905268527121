import * as React from "react";
const IconDollarsGift = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M27.6 36.4h-3c-1.4 0-2.5-1.1-2.5-2.5s1.1-2.5 2.5-2.5h6.6c.6 0 1-.4 1-1s-.4-1-1-1h-3v-2c0-.6-.4-1-1-1s-1 .4-1 1v2h-1.5c-2.5 0-4.5 2-4.5 4.5s2 4.5 4.5 4.5h3c1.4 0 2.5 1.1 2.5 2.5s-1.1 2.5-2.5 2.5H21c-.6 0-1 .4-1 1s.4 1 1 1h5v2c0 .6.4 1 1 1s1-.4 1-1v-2.1c2.3-.2 4-2.1 4-4.5.1-2.4-1.9-4.4-4.4-4.4" />
    <path d="M61.8 15.6c.4-1.4.2-2.8-.4-3.9s-1.7-1.9-3.2-2.3c-2.1-.6-4.7-.1-7 1.2-2.2 1.3-3.7 4.2-4.6 6.5-.8-2.2-2.3-5.2-4.6-6.5-4-2.3-8.4-1.8-10.1 1.1-1.4 2.4-.3 5.8 2.4 8.1H6c-2.2 0-4 1.8-4 4v27c0 2.2 1.8 4 4 4h50.2c2.2 0 4-1.8 4-4v-27c0-1.5-.8-2.7-1.9-3.4 1.7-1.3 3-3 3.5-4.8m-9.6-3.3c1.8-1 3.9-1.4 5.4-1 .6.2 1.5.5 1.9 1.4.5.8.4 1.8.2 2.4-.4 1.6-1.8 3.2-3.6 4.2-.4.2-1 .4-1.5.5H50c-.9-.1-1.6-.3-2.2-.4.8-2.1 2.3-5.9 4.4-7.1m-15.1 7c-2.9-1.7-4.4-4.7-3.4-6.6 1.1-1.9 4.5-2.1 7.4-.4 2.1 1.2 3.6 5 4.2 7.1-.6.1-1.3.3-2.2.4h-4.6c-.4-.1-.9-.3-1.4-.5M4 50.9v-27c0-1.1.9-2 2-2h39.7v31H6c-1.1 0-2-.9-2-2m54.2 0c0 1.1-.9 2-2 2h-8.5v-31h8.5c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconDollarsGift;
