import * as React from "react";
const IconHeart = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    
    viewBox="0 0 24 24"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M8.962 19.469C6.019 17.214 2 13.489 2 9.967 2 4.083 7.5 1.886 12 6.43c4.5-4.545 10-2.348 10 3.536 0 3.522-4.02 7.247-6.962 9.502C13.706 20.489 13.04 21 12 21s-1.706-.51-3.038-1.531"
      clipRule="evenodd"
    />
  </svg>
);
export default IconHeart;
