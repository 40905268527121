import * as React from "react";
const IconHighHeels = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M44.2 55c-5.7 0-11.5-2.2-16-6.3l-10.4-9.5c-.4.3-1.1 1.1-1.7 2.9-1.1 3.3-1.5 8.2-1.5 8.3v1.4c0 1-.6 2.5-2.6 2.7H6.9c-2.7 0-4.9-2.2-4.9-4.9v-24c0-.8-.4-9.9 9.4-15 .1-.1 3.2-1.6 7.1-1.6.5 0 1.6.1 2.3 1.1.5.8 16.5 23.9 19.2 27.7.7-.2 1.9-.7 2.4-1.9 0 0 .3-.8.3-1.9 0-1.8 1.5-2.5 2.2-2.6h.1c.2 0 6.2-.2 11.2 1.9s5.8 5.5 5.8 7.4c0 .1-.2 11.1-13.4 13.9-1.5.2-3 .4-4.4.4M17.9 37c.2 0 .5.1.7.3l10.9 10c5.2 4.7 12.1 6.7 18.6 5.4C59.9 50.2 60 40.8 60 40.7c0-1.7-.8-4-4.5-5.6-4.3-1.8-9.5-1.8-10.2-1.8-.2.1-.5.2-.5.7 0 1.6-.5 2.6-.5 2.7-1.2 2.8-4.5 3.2-4.6 3.2-.4 0-.7-.1-.9-.4-.8-1.1-19-27.4-19.5-28.2-.3-.3-.5-.3-.5-.3h-.1c-3.5 0-6.3 1.4-6.3 1.4C3.5 17 4 25.2 4 25.5v24.1c0 1.6 1.3 2.9 2.9 2.9H12c.1 0 .7-.1.7-.7v-1.5c0-.3.4-5.3 1.6-8.8 1.3-3.9 3.4-4.4 3.6-4.5-.1 0 0 0 0 0" />
  </svg>
);
export default IconHighHeels;
