import * as React from "react";
const IconMackup = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M20 40.7v-7.5c7.2-.5 13-6.6 13-13.9 0-7.7-6.3-14-14-14s-14 6.3-14 14c0 7.4 5.7 13.4 13 13.9v7.5c-9 .3-16 4.1-16 9 0 5 7.4 9 17 9s17-3.9 17-9c-.1-4.9-7-8.7-16-9M7 19.3c0-6.6 5.4-12 12-12s12 5.4 12 12-5.4 12-12 12-12-5.4-12-12m12 37.4c-8.1 0-15-3.2-15-7s6.8-7 15-7 15 3.2 15 7-6.9 7-15 7M61.5 8.1c-.5-.6-1.1-.9-1.9-.9H48.1c-.7 0-1.4.3-1.9.9s-.6 1.3-.4 2l2.9 11.2c.1.4.5.8 1 .8h3.2v34.4c0 .6.4 1 1 1s1-.4 1-1V22.1h3.2c.5 0 .9-.3 1-.8L62 10.1c.1-.6 0-1.4-.5-2m-4.2 12h-6.9L47.7 9.7c0-.2 0-.3.1-.3s.1-.1.3-.1h11.6c.2 0 .2.1.3.1v.3z" />
  </svg>
);
export default IconMackup;
