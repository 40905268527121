import * as React from "react";
const IconWf = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 150 100"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 0h150v100H0z" />
    <path  d="M0 0h20v44.33H0z" />
    <path  d="M20 0h20v44.33H20z" />
    <path   strokeWidth={3} d="M0 44.33h62.75V0" />
    <path
      
      d="M108.08 43.29 87.96 23.17h40.25zm-6.7 6.71L81.25 29.88v40.25zm6.7 6.71L87.96 76.83h40.25zm6.71-6.71 20.13-20.13v40.25z"
    />
  </svg>
);
export default IconWf;
