import * as React from "react";
const IconBriefcaseCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 13.9H43.9V6.7c0-2.2-1.8-4-4-4H24.1c-2.2 0-4 1.8-4 4v7.1H6c-2.2 0-4 1.8-4 4v39.4c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V17.9c0-2.2-1.8-4-4-4M22.1 6.7c0-1.1.9-2 2-2H40c1.1 0 2 .9 2 2v7.1H22.1zM60 57.3c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V17.9c0-1.1.9-2 2-2h52c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconBriefcaseCopy;
