import * as React from "react";
const IconSearchHome = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.7 58.3-8.8-8.8c-.2-.2-.4-.3-.7-.3 2.2-2.5 3.5-5.6 3.5-9 0-3.6-1.4-7-4-9.6-.3-.3-.7-.6-1-.9v-8.6c0-.3-.2-.7-.5-.9L26.9 6.1c-.3-.2-.7-.2-1 0l-8.3 5.1V10c0-3.3-2.7-6-6-6h-.9c-3.3 0-6 2.7-6 6v8.3c0 .2.1.4.2.6l-2.3 1.4c-.4.1-.6.4-.6.8v22.7c0 3.3 2.7 6 6 6h12.7c.6 0 1-.4 1-1V34.5H30c-.8 1.8-1.3 3.7-1.3 5.7 0 3.6 1.4 7 4 9.6s6.1 4 9.6 4c3.2 0 6.4-1.2 9-3.5 0 .2.1.5.3.7l8.8 8.8c.2.2.5.3.7.3s.5-.1.7-.3c.3-.5.3-1.1-.1-1.5M6.5 10c0-2.2 1.8-4 4-4h.9c2.2 0 4 1.8 4 4v2.4l-8.9 5.4zm14.2 22.5c-.6 0-1 .4-1 1v14.3H8c-2.2 0-4-1.8-4-4V21.7L26.3 8.1l22.3 13.6v6.7c-5.1-2.8-11.7-2.1-16.1 2.3q-.9.9-1.5 1.8zM34 48.3c-2.2-2.2-3.4-5.1-3.4-8.1 0-3.1 1.2-6 3.4-8.1 2.2-2.2 5.2-3.4 8.1-3.4s5.9 1.1 8.1 3.4c2.2 2.2 3.4 5.1 3.4 8.1 0 3.1-1.2 6-3.4 8.1-4.4 4.5-11.7 4.5-16.2 0" />
  </svg>
);
export default IconSearchHome;
