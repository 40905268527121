import * as React from "react";
const IconPoliceBadge = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.3 28.2c-5.5-8.4-.8-16.4 1.5-19.5.3-.4.3-.9-.1-1.3l-5-5.1c-.4-.4-.9-.4-1.3-.1-3.2 2.4-6.9 3.7-10.8 3.9-3.3 0-6.5-1.3-8.9-3.6-.4-.4-1-.4-1.4 0-2.4 2.3-5.6 3.5-8.9 3.6-3.9-.2-7.7-1.5-10.9-3.9-.4-.3-1-.3-1.3.1l-5 5.1c-.3.4-.4.9-.1 1.3 2.3 3 7 11 1.5 19.5-3.2 4.9-3.2 12.2-.1 18.6C10.8 55.5 20.1 61 31.9 62h.2c11.9-1 21.1-6.5 25.4-15.2 3.1-6.4 3-13.7-.2-18.6m-1.7 17.7C51.7 53.9 43.1 59 32 60c-11.1-1-19.7-6.1-23.6-14.1-2.8-5.8-2.8-12.3-.1-16.6 5.9-9.1 1.2-17.8-1.1-21.1L11 4.3c3.3 2.3 7.2 3.6 11.3 3.7h.1c3.5 0 6.9-1.2 9.6-3.5C34.7 6.8 38 8 41.6 8c4.1-.2 7.9-1.5 11.3-3.7l3.8 3.9c-2.6 3.8-6.8 12.2-1.1 21.1 2.9 4.3 2.9 10.8 0 16.6" />
    <path d="m38.6 30.9-4-.6-1.7-3.6c-.3-.7-1.5-.7-1.8 0l-1.7 3.6-4 .6c-.4.1-.7.3-.8.7s0 .8.3 1l2.9 2.8-.7 4c-.1.4.1.8.4 1 .2.1.4.2.6.2s.3 0 .5-.1l3.6-1.9 3.6 1.9c.3.2.7.1 1.1-.1.3-.2.5-.6.4-1l-.7-4 2.8-2.8c.3-.3.4-.7.2-1-.3-.4-.6-.7-1-.7m-4 3.4c-.2.2-.3.5-.3.7v.2l.4 2.5-2.3-1.2q-.45-.3-.9 0l-2.3 1.2.4-2.5c.1-.3-.1-.7-.3-.9l-1.8-1.8 2.1-.3c.6-.1 1.1-.4 1.3-1l.9-1.9.9 1.9c.3.5.7.9 1.3.9l2.2.3z" />
    <path d="M32 17.3c-9.1 0-16.4 7.4-16.4 16.4 0 9.1 7.4 16.4 16.4 16.4 9.1 0 16.4-7.4 16.4-16.4S41.1 17.3 32 17.3m0 30.9c-8 0-14.4-6.5-14.4-14.4 0-8 6.5-14.4 14.4-14.4 8 0 14.4 6.5 14.4 14.4S40 48.2 32 48.2" />
  </svg>
);
export default IconPoliceBadge;
