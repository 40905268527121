import React, { useState } from 'react';
import { useNavigation } from 'context/NavigationContext';
import {
    BringzzPageContainer, BringzzButton, BringzzIcon, BringzzText, BringzzInput, BringzzCountryDropdown
} from "@bringzz/components";
import useUser from 'hooks/useUser';
import useRequest from 'hooks/useRequest';
import { useAuth } from 'context/AuthContext';

let state = {
    checked: (
        <BringzzIcon
            icon="IconCheck"
            library="Custom"
            className="h-5 w-5 p-1 bg-magic-lilac text-light rounded-full"
            size="12"
        />
    ),
    unChecked: (
        <div></div>
    ),
};

const EditPhoneNumberView = () => {
    const { backPress, navigate } = useNavigation();
    const [isValidInput, setIsValidInput] = useState(false)
    const [inputValue, setInputValue] = useState('');

    const { sendRequest, data, error, loading } = useRequest();

    const { user } = useAuth()

    const [country, setCountry] = useState({
        name: 'Austria',
        dial_code: '+43',
        code: 'AT'
    });

    return (
        <BringzzPageContainer
            header={{
                left: <BringzzIcon
                    size="22"
                    icon="IconChevronLeft"
                    folder="LineIcons"
                    className="cursor-pointer"
                    onClick={backPress}
                />,
                title: <BringzzText tag='h2'>Update phone number</BringzzText>,
            }}
            footer={
                <div className='pb-28 px-6'>
                    <BringzzButton
                        size='lg'
                        className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
                        onClick={async () => {
                            await sendRequest({
                                method: 'POST',
                                url: `/module/profile/changePhone`,
                                data: { phone: inputValue }
                            }).then((response) => {
                                if (response.status === 'success')
                                    navigate(`settings/account/mobile/verify?value=${inputValue}&countrypart=${country.dial_code.substring(1)}`, false);
                            }).catch((error) => {
                                console.log(error);
                            })
                        }}
                        disabled={!isValidInput || loading}
                    >
                        Save
                    </BringzzButton>
                </div>
            }
        >
            <div className='p-6 space-y-4'>
                <BringzzText
                    tag='h4'
                    className='text-midnight-blue/50 font-normal text-center'
                >
                    {"if you change to a new number, we'll take you through a verification process to confirm this."}
                </BringzzText>

                <BringzzInput
                    containerClassname="w-full"
                    className="!pl-16"
                    type='text'
                    placeholder="123456789"
                    onChange={(e, isValid, newValue) => {
                        setIsValidInput(isValid);
                        setInputValue(newValue);
                    }}
                    pattern='^\+?[0-9\s.-]{8,12}$'
                    defaultValue={user.phoneNumber}
                    icon={{
                        left: <BringzzCountryDropdown
                            className="!rounded-r-none"
                            mode={'modal'}
                            onCountryChange={result => {
                                setCountry(result);
                            }}
                        />
                    }}>
                </BringzzInput>
            </div>
        </BringzzPageContainer>
    );
}

export default EditPhoneNumberView;
