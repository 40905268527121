import * as React from "react";
const IconPlateAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M34.3 25.8c-3.4 0-6.2 2.8-6.2 6.2s2.8 6.2 6.2 6.2 6.2-2.8 6.2-6.2-2.8-6.2-6.2-6.2m0 10.4c-2.3 0-4.2-1.9-4.2-4.2s1.9-4.2 4.2-4.2 4.2 1.9 4.2 4.2-1.9 4.2-4.2 4.2" />
    <path d="M34.3 18.1c-7.7 0-13.9 6.3-13.9 13.9s6.2 14 13.9 14 14-6.3 14-14-6.3-13.9-14-13.9m0 25.9c-6.6 0-11.9-5.4-11.9-11.9s5.4-11.9 11.9-11.9 12 5.2 12 11.8-5.4 12-12 12M61 10.6h-1c-4.4 0-7.9 3.5-7.9 7.9v13.3c0 1.1.9 2 2 2H60v19.5c0 .6.4 1 1 1s1-.4 1-1V11.6c0-.6-.4-1-1-1m-6.9 21.2V18.5c0-3.2 2.6-5.9 5.9-5.9v19.2zM19.4 9.6c-.6 0-1 .4-1 1v4c0 3.6-2.7 6.6-6.2 7.1V10.6c0-.6-.4-1-1-1s-1 .4-1 1v11.1C6.7 21.2 4 18.2 4 14.6v-4c0-.6-.4-1-1-1s-1 .4-1 1v4c0 4.7 3.6 8.6 8.2 9.1v29.6c0 .6.4 1 1 1s1-.4 1-1V23.7c4.6-.5 8.2-4.4 8.2-9.1v-4c0-.5-.5-1-1-1" />
  </svg>
);
export default IconPlateAlt;
