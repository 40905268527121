import React, { useState } from 'react';
import { BringzzButton, BringzzText } from "@bringzz/components";
import { useNavigation } from 'context/NavigationContext';
import {
    BringzzPageContainer,
    BringzzIcon,
    BringzzRadioList,
    BringzzCountryDropdown,
    BringzzInput
} from "@bringzz/components";
import { useLocation } from 'react-router-dom';
import { requestBiometricAuthentication, checkWebAuthnSupport, isBiometricAuthEnabled, disableBiometricAuthentication } from 'webauthn';

const AllowFaceIDPage = () => {
    const { backPress } = useNavigation();

    const { navigate } = useNavigation();

    return (
        <BringzzPageContainer
            className="bg-white"
            steps={true}
            activeStep={9}
            header={{
                left: (
                    <BringzzIcon
                        size='22'
                        icon='IconChevronLeft'
                        folder='LineIcons'
                        className='cursor-pointer'
                        onClick={backPress}
                    />
                ),
                title: "Join Us",
                right: <BringzzButton size='sm' className="border border-black" onClick={() => {
                    navigate('/register/consumer/interest');
                }}>Skip</BringzzButton>
            }}
            footer={
                <div className='pb-8 px-6'>
                    <BringzzButton
                        size='lg'
                        className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
                        onClick={async () => {
                            const result = await requestBiometricAuthentication("", "");
                            if (result)
                                navigate('/register/consumer/interest')
                        }}
                    >
                        Enable Face ID
                    </BringzzButton>
                </div>
            }
            authenticated={false}
        >
            <div className='relative flex justify-center bg-magic-lilac/20'>
                <div className='relative bg-white w-full bg-cover bg-center flex flex-col'>
                    {/* Content */}
                    <div className='flex-grow flex flex-col py-8 px-4'>
                        <div className='z-10 flex flex-col '>

                            <div className='pl-2 flex flex-col space-y-6 md:max-w-lg lg:max-w-xl xl:max-w-2xl'>
                                <BringzzText tag='h1' className='pl-2 text-midnight-blue font-bold'>
                                    Enable Face ID
                                </BringzzText>
                                <BringzzText tag="h5" className="font-normal">bringzz will use FaceID as a additional safety measure, e.g. before we forwarded you to the check-out page or to change certain setting changes.</BringzzText>
                            </div>
                            <div className='flex mt-20 flex-col text-center items-center'>
                                <BringzzIcon
                                    icon="IconMountain"
                                    library="Custom"
                                    className="flex items-center justify-center h-52 w-52 bg-gray-100 rounded-full"
                                    size="110"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </BringzzPageContainer >
    );
};

export default AllowFaceIDPage;
