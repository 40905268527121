import * as React from "react";
const IconGw = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 513 342"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 0h512v342H0z" />
    <path  d="M0 0h512v171H0z" />
    <path  d="M0 0h182v342H0z" />
    <path d="m98.3 109.8 15.1 46.5h48.9L122.8 185l15.1 46.5-39.6-28.7-39.5 28.7L73.9 185l-39.6-28.7h48.9z" />
  </svg>
);
export default IconGw;
