import * as React from "react";
const IconLaptopAltMedical = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 51.3h-5.5V25c0-2.5-1.9-4.5-4.4-4.6-.5 0-1 .4-1.1.9 0 .6.4 1 .9 1.1 1.4.1 2.5 1.2 2.5 2.6v26.3H10.5V25c0-1.5 1.2-2.6 2.7-2.6.6 0 1-.4 1-1s-.5-1-1-1c-2.6 0-4.6 2.1-4.6 4.6v26.3H3c-.6 0-1 .4-1 1s.4 1 1 1h58c.6 0 1-.4 1-1s-.4-1-1-1" />
    <path d="M22.2 40c.2 0 .3 0 .5-.1 1.4-.8 2.9-1.8 4.7-3.1q2.7-1.95 4.8-3.9c2.7 2.5 5.7 4.7 8.9 6.9l.1.1c.5.3 1.1.2 1.4-.3s.2-1.1-.3-1.4l-.1-.1c-3-2.1-5.9-4.3-8.5-6.6.8-.8 1.6-1.7 2.2-2.5 2.3-2.9 3.7-6 4.2-9.1q.3-2.55-.3-4.5c-1-2.9-4-4.9-7.5-4.8-2.3.1-4.4.9-5.7 2.4-1.2 1.2-1.9 3-2.1 5-.3 4 1.3 7.9 4.7 11.8.5.6 1.1 1.2 1.7 1.7-1.3 1.2-2.8 2.5-4.5 3.7s-3.1 2.2-4.5 3c-.5.3-.6.9-.4 1.4.1.3.4.4.7.4m8.4-11.6c-3.1-3.4-4.5-6.9-4.2-10.3.1-1.5.7-2.9 1.5-3.8 1-1.1 2.6-1.7 4.4-1.7h.2c2 0 4.6.9 5.4 3.4.3 1 .4 2.2.2 3.6-.4 2.7-1.6 5.5-3.7 8.1-.6.8-1.4 1.6-2.1 2.4-.7-.5-1.2-1.1-1.7-1.7" />
  </svg>
);
export default IconLaptopAltMedical;
