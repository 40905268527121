import * as React from "react";
const IconHeight = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.8 48.2 46.5 14c-.4-1-.7-1.2-1.7-1.2h-7.7c-.7 0-1.3 0-1.9 1.2L20.8 48.5c-.6 1.2.3 2.6 1.7 2.6h5c1 0 1.7-.7 1.9-1.2l2.4-6h17.6l2.2 6c.3 1 1.2 1.2 1.9 1.2h5.3c2-.1 2.6-1.5 2-2.9M35.5 36.8l5.2-13.4 5 13.4zM12.9 20.7c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.3-1-.1-1.4l-5.3-4.8c-.4-.3-1-.3-1.3 0l-5.3 4.8c-.4.4-.4 1-.1 1.4.4.4 1 .4 1.4.1l3.6-3.3v29.2l-3.6-3.3c-.4-.4-1-.3-1.4.1s-.3 1 .1 1.4l5.3 4.8c.2.2.4.3.7.3s.5-.1.7-.3l5.3-4.8c.4-.4.4-1 .1-1.4-.4-.4-1-.4-1.4-.1l-3.6 3.3V17.4z" />
  </svg>
);
export default IconHeight;
