import * as React from "react";
const IconArrowAlignV = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M43.4 31H20.6c-.6 0-1 .4-1 1s.4 1 1 1h22.7c.6 0 1-.4 1-1s-.4-1-.9-1M20.6 16.7c.3 0 .5-.1.7-.3l9.7-10v16.8c0 .6.4 1 1 1s1-.4 1-1V6.5l9.7 9.9c.4.4 1 .4 1.4 0s.4-1 0-1.4L32.7 3.3c-.2-.2-.5-.3-.8-.3s-.5.1-.7.3L19.9 15c-.4.4-.4 1 0 1.4.2.2.4.3.7.3M42.7 47.6 33 57.5V40.8c0-.6-.4-1-1-1s-1 .4-1 1v16.8l-9.7-10c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l11.3 11.7c.2.2.4.3.7.3s.5-.1.7-.3L44.1 49c.4-.4.4-1 0-1.4s-1-.4-1.4 0" />
  </svg>
);
export default IconArrowAlignV;
