import * as React from "react";
const IconUnlockCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51 20.4h-8.4l.9-4c1.4-6.5-2.7-12.8-9-14.2-3.1-.7-6.3-.1-8.9 1.6s-4.5 4.4-5.1 7.5c-.1.5.2 1.1.8 1.2.5.1 1.1-.2 1.2-.8.5-2.6 2.1-4.8 4.3-6.2s4.8-1.9 7.4-1.4c5.3 1.1 8.6 6.4 7.5 11.8l-1 4.4H13c-3.2 0-5.8 2.5-5.8 5.5v18c0 10 8.6 18.1 19.1 18.1h11.4c10.5 0 19.1-8.2 19.1-18.4V26c0-3.1-2.6-5.6-5.8-5.6m3.8 23.2c0 9-7.7 16.4-17.1 16.4H26.3c-9.4 0-17.1-7.2-17.1-16.1V26c0-1.9 1.7-3.5 3.8-3.5h38c2.1 0 3.8 1.6 3.8 3.5z" />
    <path d="M36.3 41.5h-8.6c-1.1 0-2 .9-2 2V52c0 1.1.9 2 2 2h8.6c1.1 0 2-.9 2-2v-8.5c0-1.1-.9-2-2-2m0 10.6h-8.6v-8.5h8.6zm0 1v-1z" />
  </svg>
);
export default IconUnlockCopy;
