import * as React from "react";
const IconFlagCorner = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M48.3 19 28 7.2V4c0-.6-.4-1-1-1s-1 .4-1 1v47.4c0 .6.4 1 1 1s1-.4 1-1V32.6l20.4-11.9c.3-.2.5-.5.5-.9s-.3-.6-.6-.8M28 30.3V9.5l17.9 10.4zM37.7 53.1c-.6 0-1 .4-1 1 0 2.7-4.5 4.9-9.8 4.9s-9.8-2.3-9.8-4.9c0-.6-.4-1-1-1s-1 .4-1 1C15.2 58 20.4 61 27 61s11.8-3 11.8-6.9c-.1-.6-.5-1-1.1-1" />
  </svg>
);
export default IconFlagCorner;
