import * as React from "react";
const IconIncognitoBrowser = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 4H6C3.8 4 2 5.8 2 8v48c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V8c0-2.2-1.8-4-4-4M6 6h52c1.1 0 2 .9 2 2v8.9H4V8c0-1.1.9-2 2-2m52 52H6c-1.1 0-2-.9-2-2V18.9h56V56c0 1.1-.9 2-2 2" />
    <path d="M43 33.9c-.4-.3-1.1-.2-1.4.2s-.2 1.1.2 1.4c1.5 1.1 2.7 2.2 3.3 2.8-1.2 1.2-4.1 3.8-7.8 5.3-.5.2-.8.8-.6 1.3q.3.6.9.6c.1 0 .2 0 .4-.1 5.3-2.1 9.1-6.3 9.2-6.5.3-.4.3-.9 0-1.3 0 0-1.6-1.9-4.2-3.7M37.7 27.1c-.5-.3-1.1-.1-1.4.4l-9.1 16.2c-3.7-1.4-6.7-4.1-8-5.4 1.7-1.7 6.9-6.3 12.9-6.3.6 0 1-.4 1-1s-.4-1-1-1c-8.3 0-14.8 7.4-15 7.7-.3.4-.3.9 0 1.3.2.2 3.8 4.3 9.1 6.5L24.9 48c-.3.5-.1 1.1.4 1.4.2.1.3.1.5.1.4 0 .7-.2.9-.5l11.5-20.5c.2-.5 0-1.2-.5-1.4" />
  </svg>
);
export default IconIncognitoBrowser;
