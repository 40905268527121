import * as React from "react";
const IconBackwardCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.8 4.4c-1.8-1-3.9-.9-5.7.2L8.3 31.2V3c0-.6-.4-1-1-1s-1 .4-1 1v58c0 .6.4 1 1 1s1-.4 1-1V32.8l40.8 26.6c.9.6 2 .9 3 .9.9 0 1.8-.2 2.7-.7 1.8-1 2.9-2.8 2.9-4.8V9.2c0-2-1.1-3.8-2.9-4.8m.9 50.4c0 1.3-.7 2.4-1.8 3.1-1.2.6-2.5.6-3.6-.1L10.8 32 50.2 6.3c1.1-.7 2.5-.8 3.6-.1 1.1.6 1.8 1.8 1.8 3.1v45.5z" />
  </svg>
);
export default IconBackwardCopy;
