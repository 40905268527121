import * as React from "react";
const IconMoveUp = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32.6 2.9c-.4-.3-.9-.3-1.3 0l-14 11.8c-.4.4-.5 1-.1 1.4s1 .5 1.4.1L31 5.9v53.9c0 .6.4 1 1 1s1-.4 1-1V5.9l12.4 10.4c.2.2.4.2.6.2.3 0 .6-.1.8-.4.4-.4.3-1.1-.1-1.4zM55.3 27.5c-.6 0-1 .4-1 1v30.9c0 .6.4 1 1 1s1-.4 1-1V28.5c0-.6-.5-1-1-1M8.7 27.5c-.6 0-1 .4-1 1v30.9c0 .6.4 1 1 1s1-.4 1-1V28.5c0-.6-.4-1-1-1" />
  </svg>
);
export default IconMoveUp;
