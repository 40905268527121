import * as React from "react";
const IconCalenderMinus = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.2 7.3h-5.9V3.6c0-.6-.4-1-1-1s-1 .4-1 1v3.7H15.8V3.6c0-.6-.4-1-1-1s-1 .4-1 1v3.7h-6C4.6 7.3 2 9.9 2 13.1v28.8c0 3.2 2.6 5.8 5.8 5.8h22.5c.6 0 1-.4 1-1s-.4-1-1-1H7.8C5.7 45.7 4 44 4 41.9V22.6h56v12.2c0 .6.4 1 1 1s1-.4 1-1V13.1c0-3.2-2.6-5.8-5.8-5.8M4 20.6v-7.5C4 11 5.7 9.3 7.8 9.3h5.9v2.4c0 .6.4 1 1 1s1-.4 1-1V9.3h32.5v2.4c0 .6.4 1 1 1s1-.4 1-1V9.3h5.9c2.1 0 3.8 1.7 3.8 3.8v7.5z" />
    <path d="M48.9 37.5c-6.6 0-11.9 5.3-11.9 11.9s5.3 11.9 11.9 11.9S60.8 56 60.8 49.5s-5.4-12-11.9-12m0 21.9c-5.5 0-9.9-4.5-9.9-9.9 0-5.5 4.5-9.9 9.9-9.9 5.5 0 9.9 4.5 9.9 9.9s-4.5 9.9-9.9 9.9" />
    <path d="M52.1 48.5h-6.4c-.6 0-1 .4-1 1s.4 1 1 1h6.4c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconCalenderMinus;
