import * as React from "react";
const IconCameraWedding = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.5 20.1c-1-1-2.3-1.5-3.7-1.5h-7c-1.1 0-2-.9-2-2v-4.4c0-2.5-2.2-2.5-3-2.5H18.9c-.8 0-2.8 0-2.8 2.5v4.4c0 1.1-.9 2-2 2H7.3C4.4 18.6 2 21 2 23.9v25.3c0 2.9 2.4 5.3 5.3 5.3h49.4c2.9 0 5.3-2.4 5.3-5.3V23.9c0-1.5-.5-2.8-1.5-3.8m-.5 29c0 1.8-1.5 3.3-3.3 3.3H7.3c-1.8 0-3.3-1.5-3.3-3.3V23.8c0-1.8 1.5-3.3 3.3-3.3h6.9c2.2 0 4-1.8 4-4v-4.4c0-.4.1-.5.1-.5.1-.1.5-.1.7-.1h25.8c1 0 1 .1 1 .5v4.4c0 2.2 1.8 4 4 4h7c.9 0 1.7.3 2.3 1 .6.6 1 1.4 1 2.3z" />
    <path d="M31.6 17.6c-8.4 0-15.3 6.9-15.3 15.4S23 48.1 31.6 48.1c8.4 0 15.3-6.8 15.3-15.1-.1-8.3-7.1-15.4-15.3-15.4m0 28.6c-7.4 0-13.3-5.8-13.3-13.1 0-7.4 6-13.4 13.3-13.4 7.2 0 13.3 6.1 13.3 13.4-.1 7.2-6 13.1-13.3 13.1" />
    <path d="M32 27.5c-.7-.4-1.5-.5-2.2-.5-1.2 0-2.4.4-3.3 1.3-1 .9-1.5 2.1-1.5 3.4 0 1.4.5 2.8 1.5 3.8l4.1 4.1c.4.4.9.6 1.4.6s1-.2 1.4-.6l4.3-4.3c.9-.9 1.4-2.2 1.4-3.5s-.5-2.5-1.4-3.5c-1.6-1.5-3.9-1.8-5.7-.8m4.2 6.4L32 38.1 27.8 34c-.6-.6-1-1.5-.9-2.3 0-.8.3-1.5.9-2 .5-.5 1.2-.8 1.9-.8.6 0 1.1.2 1.6.5.3.2.8.2 1.1 0 1.1-.8 2.7-.7 3.7.3q.9.9.9 2.1c.1.8-.2 1.5-.8 2.1" />
  </svg>
);
export default IconCameraWedding;
