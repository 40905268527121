import * as React from "react";
const IconMaze = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.7 2H7.4C4.4 2 2 4.4 2 7.4v10.3c0 .6.4 1 1 1s1-.4 1-1V7.4C4 5.5 5.5 4 7.4 4h49.3C58.5 4 60 5.5 60 7.4v11.3h-9.1v-.3c0-3-2.4-5.4-5.3-5.4h-10c-.6 0-1 .4-1 1s.4 1 1 1h10c1.8 0 3.3 1.5 3.3 3.4v27.2c0 1.8-1.5 3.3-3.3 3.3h-1.1c-.6 0-1 .4-1 1s.4 1 1 1h1.1c3 0 5.3-2.4 5.3-5.3V20.7H60v10.8c0 .6.4 1 1 1s1-.4 1-1V7.4c0-3-2.4-5.4-5.3-5.4" />
    <path d="M61 43.5c-.6 0-1 .4-1 1v12.1c0 1.8-1.5 3.3-3.3 3.3H7.4C5.5 60 4 58.5 4 56.7V41.9h9.1v3.7c0 3 2.4 5.3 5.4 5.3H34c.6 0 1-.4 1-1s-.4-1-1-1H18.4c-1.8 0-3.4-1.5-3.4-3.3v-15c0-.6-.4-1-1-1s-1 .4-1 1v9.3H4V29.1c0-.6-.4-1-1-1s-1 .4-1 1v27.6c0 3 2.4 5.3 5.4 5.3h49.3c3 0 5.3-2.4 5.3-5.3V44.5c0-.5-.4-1-1-1M26.6 13.1h-8.2c-3 0-5.4 2.4-5.4 5.4v1.3c0 .6.4 1 1 1s1-.4 1-1v-1.3c0-1.8 1.5-3.4 3.4-3.4h8.2c.6 0 1-.4 1-1s-.5-1-1-1" />
    <path d="M40.9 29.6c.6 0 1-.4 1-1v-1.1c0-3-2.4-5.4-5.3-5.4h-9.1c-3 0-5.4 2.4-5.4 5.4v9.1c0 3 2.4 5.3 5.4 5.3h9.1c3 0 5.3-2.4 5.3-5.3 0-.6-.4-1-1-1s-1 .4-1 1c0 1.8-1.5 3.3-3.3 3.3h-9.1c-1.8 0-3.4-1.5-3.4-3.3v-9.1c0-1.8 1.5-3.4 3.4-3.4h9.1c1.8 0 3.3 1.5 3.3 3.4v1.1c0 .5.4 1 1 1" />
  </svg>
);
export default IconMaze;
