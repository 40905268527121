import * as React from "react";
const IconDislike = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.9 32-3.2-20C58 6.5 54 3.2 48 3.2H23.1c-1.7 0-3.5.5-4.9 1.5V3.5c0-.6-.4-1-1-1H6c-2.2 0-4 1.8-4 4V37c0 2.2 1.8 4 4 4h11.2c.6 0 1-.4 1-1v-1.2c2.1.8 8.3 3.6 8.3 9.7v10.2c0 1.1.7 2 1.7 2.3.1 0 1.6.5 3.7.5 2.2 0 4.2-.6 5.7-1.7 2.5-1.8 3.7-4.8 3.7-9v-8.1h11.6c2.1 0 4.2-.8 5.8-2.2 2.5-2 3.7-5.3 3.2-8.5m-45.7 7H6c-1.1 0-2-.9-2-2V6.5c0-1.1.9-2 2-2h10.2zm41.3 0c-1.3 1.1-2.9 1.7-4.5 1.7H40.4c-.6 0-1 .4-1 1v9.1c0 3.5-1 6-2.9 7.4-1.2.9-2.7 1.3-4.5 1.3-1.7 0-3.1-.4-3.1-.4-.2-.1-.3-.2-.3-.4V48.5c0-8.3-9.3-11.5-10.3-11.8V7.2c1.3-1.3 3.1-2 4.9-2H48c5 0 8.1 2.5 8.6 7.1l3.2 20c.5 2.5-.4 5.1-2.3 6.7" />
  </svg>
);
export default IconDislike;
