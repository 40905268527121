import * as React from "react";
const IconWeddingVideoCamera = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M40 24H10.8v-2.7s0-2.4 2-4.2c1.5-1.4 3.8-2 6.8-1.8.1 2.2 2 3.9 4.2 3.9h7c2.3 0 4.2-1.9 4.2-4.2v-1.5c0-2.3-1.9-4.2-4.2-4.2h-7c-2.3 0-4.1 1.8-4.2 4-3.5-.2-6.2.6-8.1 2.3-2.6 2.3-2.7 5.5-2.7 5.7V24H6c-2.2 0-4 1.8-4 4v22.8c0 2.2 1.8 4 4 4h34c2.2 0 4-1.8 4-4V28c0-2.2-1.8-4-4-4M21.6 13.5c0-1.2 1-2.2 2.2-2.2h7c1.2 0 2.2 1 2.2 2.2V15c0 1.2-1 2.2-2.2 2.2h-7c-1.2 0-2.2-1-2.2-2.2zM42 50.7c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V28c0-1.1.9-2 2-2h34c1.1 0 2 .9 2 2zM59.8 27.9c-1.4-.9-3.1-1-4.5-.3l-3.6 1.7c-1.7.9-2.7 2.5-2.7 4.3V45c0 1.8 1 3.4 2.6 4.2l3.6 1.8c.7.3 1.4.5 2.1.5.9 0 1.7-.2 2.5-.7 1.4-.9 2.2-2.4 2.2-4V31.9c0-1.6-.8-3.1-2.2-4m.2 18.9c0 .9-.5 1.8-1.3 2.3s-1.8.5-2.6.1l-3.6-1.8C51.6 46.9 51 46 51 45V33.6c0-1 .6-2 1.5-2.4l3.6-1.7q.6-.3 1.2-.3c.5 0 1 .1 1.4.4.8.5 1.3 1.4 1.3 2.3z" />
    <path d="M16.3 45.8h-6.2c-.6 0-1 .4-1 1s.4 1 1 1h6.2c.6 0 1-.4 1-1s-.4-1-1-1M29.5 31.4c-.7-.4-1.4-.5-2.2-.5h-.1c-1.2 0-2.4.5-3.3 1.3-1 .9-1.5 2.1-1.5 3.5s.5 2.8 1.5 3.8l4.2 4.2c.4.4.9.6 1.4.6s1.1-.2 1.4-.6l4.3-4.3c.9-.9 1.5-2.2 1.4-3.5 0-1.3-.5-2.6-1.5-3.5-1.4-1.6-3.7-2-5.6-1m4.3 6.5-4.4 4.3-4.2-4.2c-.6-.6-1-1.5-.9-2.4 0-.8.3-1.5.9-2 .5-.5 1.2-.8 2-.8.6 0 1.2.2 1.7.5.3.2.8.2 1.1 0 1.2-.8 2.7-.7 3.8.3q.9.9.9 2.1c0 .9-.3 1.7-.9 2.2" />
  </svg>
);
export default IconWeddingVideoCamera;
