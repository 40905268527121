import * as React from "react";
const IconTruckLocation = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.8 37.3-7.3-10.5c-.2-.3-.5-.4-.8-.4H41.5V23c0-3-2.4-5.4-5.4-5.4H28c-.6 0-1 .4-1 1s.4 1 1 1h8.1c1.9 0 3.4 1.5 3.4 3.4v30.4H27.7c-.2-2.8-2.5-4.9-5.3-4.9s-5.1 2.2-5.3 4.9h-8c-1.9 0-3.4-1.5-3.4-3.4V36c0-.6-.4-1-1-1s-1 .4-1 1v14c0 3 2.4 5.4 5.4 5.4h8.2c.7 2.2 2.7 3.8 5.1 3.8s4.4-1.6 5.1-3.8h17.4c.7 2.2 2.7 3.8 5.1 3.8s4.4-1.6 5.1-3.8H61c.6 0 1-.4 1-1V37.9c0-.2-.1-.4-.2-.6m-8.6-8.9 6.4 9.2H41.5v-9.2zM22.4 57.2c-1.9 0-3.4-1.5-3.4-3.4s1.5-3.4 3.4-3.4 3.4 1.5 3.4 3.4-1.5 3.4-3.4 3.4m27.6 0c-1.9 0-3.4-1.5-3.4-3.4s1.5-3.4 3.4-3.4 3.4 1.5 3.4 3.4-1.5 3.4-3.4 3.4m5.3-3.8c-.2-2.8-2.5-4.9-5.3-4.9s-5.1 2.2-5.3 4.9h-3.2V39.6H60v13.8z" />
    <path d="M10.8 29.2c.2.2.4.3.7.3.2 0 .5-.1.7-.3.4-.3 8.8-8.4 8.8-14.9 0-5.2-4.2-9.5-9.5-9.5S2 9.1 2 14.3c0 6.3 8.4 14.6 8.8 14.9m.7-22.4c4.1 0 7.5 3.3 7.5 7.5 0 4.7-5.5 10.8-7.5 12.8C9.5 25 4 18.8 4 14.3c0-4.1 3.3-7.5 7.5-7.5" />
    <path d="M12.5 15.5c0-.6-.4-1-1-1s-1 .4-1 1 .4 1 1 1 1-.5 1-1" />
  </svg>
);
export default IconTruckLocation;
