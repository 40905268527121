import * as React from "react";
const IconMedicalSearch = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M24 24h-4v-6h4v-4h6v4h4v6h-4v4h-6zm-2-2v-2h4v-4h2v4h4v2h-4v4h-2v-4z"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M27 36c8.284 0 15-6.716 15-15S35.284 6 27 6s-15 6.716-15 15c0 3.782 1.4 7.238 3.71 9.876l-1.893 1.893-2.707-.121-6.524 6.524 4.242 4.242 6.472-6.471-.004-2.825 1.828-1.828A14.94 14.94 0 0 0 27 36m0-2c7.18 0 13-5.82 13-13S34.18 8 27 8s-13 5.82-13 13 5.82 13 13 13M8.828 40.586l-1.414-1.414 4.486-4.487 1.397.063.002 1.367z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconMedicalSearch;
