import * as React from "react";
const IconCheckBoxAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M52 18.3c-.6 0-1 .4-1 1v31.1c0 1.7-1.3 3-3 3H11.1c-1.7 0-3-1.3-3-3V13.6c0-1.7 1.3-3 3-3H45c.6 0 1-.4 1-1s-.4-1-1-1H11.1c-2.8 0-5 2.2-5 5v36.9c0 2.8 2.2 5 5 5H48c2.8 0 5-2.2 5-5V19.3c0-.5-.5-1-1-1" />
    <path d="M57 4.6c-.4-.4-1-.3-1.4.1L28.5 35 18 25.4c-.4-.4-1-.3-1.4.1s-.3 1 .1 1.4l11.2 10.3c.2.2.4.3.7.3s.5-.1.7-.3L57.1 6c.3-.4.3-1-.1-1.4" />
  </svg>
);
export default IconCheckBoxAlt;
