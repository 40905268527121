import * as React from "react";
const IconHierchyAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.2 49.9c-.5 0-1 .1-1.5.2l-5.3-10.8h.1c1 0 1.8-.8 1.8-1.8V32c0-5.4-4.4-9.7-9.7-9.7h-7c-5.4 0-9.7 4.4-9.7 9.7v5.5c0 1 .8 1.8 1.8 1.8h.1l-5.3 10.8c-.5-.1-1-.2-1.5-.2-3.1 0-5.6 2.5-5.6 5.6s2.5 5.6 5.6 5.6 5.6-2.5 5.6-5.6c0-1.8-.9-3.5-2.3-4.5L23 39.3h8V51c-2.6.5-4.6 2.7-4.6 5.5 0 3.1 2.5 5.6 5.6 5.6s5.6-2.5 5.6-5.6c0-2.7-2-5-4.6-5.5V39.3h8.2L46.9 51c-1.4 1-2.3 2.6-2.3 4.5 0 3.1 2.5 5.6 5.6 5.6s5.6-2.5 5.6-5.6-2.5-5.6-5.6-5.6m-36.4 9.2c-2 0-3.6-1.6-3.6-3.6s1.6-3.6 3.6-3.6 3.6 1.6 3.6 3.6-1.6 3.6-3.6 3.6m21.8-2.7c0 2-1.6 3.6-3.6 3.6s-3.6-1.6-3.6-3.6 1.6-3.6 3.6-3.6 3.6 1.7 3.6 3.6M20.8 32c0-4.3 3.5-7.7 7.7-7.7h7c4.3 0 7.7 3.5 7.7 7.7v5.3H20.7V32zm29.4 27.1c-2 0-3.6-1.6-3.6-3.6s1.6-3.6 3.6-3.6 3.6 1.6 3.6 3.6-1.6 3.6-3.6 3.6M32 16.6c4.2 0 7.5-3.3 7.5-7.3S36.2 2 32 2s-7.5 3.3-7.5 7.3 3.3 7.3 7.5 7.3M32 4c3.1 0 5.5 2.4 5.5 5.3S35 14.6 32 14.6c-3.1 0-5.5-2.4-5.5-5.3S28.9 4 32 4" />
  </svg>
);
export default IconHierchyAlt1;
