import * as React from "react";
const IconSuitcaseAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M46.9 9.7h-7.5V7c0-2.8-2.3-5-5-5h-4.7c-2.8 0-5 2.3-5 5v2.7h-7.5c-2.8 0-5 2.3-5 5v39.4c0 2.1 1.3 3.9 3.1 4.7V61c0 .6.4 1 1 1s1-.4 1-1v-1.7h29.6V61c0 .6.4 1 1 1s1-.4 1-1v-2.1c1.8-.8 3.1-2.6 3.1-4.7V14.8c-.1-2.8-2.3-5.1-5.1-5.1M26.6 7c0-1.7 1.4-3 3-3h4.7c1.7 0 3 1.4 3 3v2.7H26.6zm23.3 47.2c0 1.7-1.4 3-3 3H17.1c-1.7 0-3-1.4-3-3V14.8c0-1.7 1.4-3 3-3h29.8c1.7 0 3 1.4 3 3z" />
    <path d="M22.4 27.6c-.6 0-1 .4-1 1v11.1c0 .6.4 1 1 1s1-.4 1-1V28.6c0-.6-.4-1-1-1M32 23.8c-.6 0-1 .4-1 1v18.7c0 .6.4 1 1 1s1-.4 1-1V24.8c0-.6-.4-1-1-1M41.6 27.6c-.6 0-1 .4-1 1v11.1c0 .6.4 1 1 1s1-.4 1-1V28.6c0-.6-.5-1-1-1" />
  </svg>
);
export default IconSuitcaseAlt1;
