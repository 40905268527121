import * as React from "react";
const IconArrowLongRightC = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.8 31.6 49.4 20.2c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l9.7 9.7H15.2c-.3-3.1-3-5.5-6.1-5.5-3.4 0-6.2 2.8-6.2 6.2s2.8 6.2 6.2 6.2c3 0 5.4-2.1 6-4.9h42.6l-9.1 9.1c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3L60.8 33c.2-.2.3-.4.3-.7s-.1-.5-.3-.7M9 36.2c-2.3 0-4.2-1.9-4.2-4.2s1.9-4.2 4.2-4.2 4.2 1.9 4.2 4.2-1.8 4.2-4.2 4.2" />
  </svg>
);
export default IconArrowLongRightC;
