import * as React from "react";
const IconMediumAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M64 32C64 14.337 49.662 0 32 0S0 14.338 0 32c0 17.66 14.338 31.999 32 31.999s32-14.339 32-32m-28.787 0c0 7.865-6.333 14.242-14.145 14.242-7.813 0-14.146-6.378-14.146-14.242s6.333-14.244 14.146-14.244c7.812 0 14.145 6.378 14.145 14.244m15.518 0c0 7.404-3.167 13.408-7.073 13.408S36.585 39.404 36.585 32c0-7.405 3.166-13.41 7.073-13.41 3.906 0 7.073 6.003 7.073 13.41m6.347 0c0 6.632-1.113 12.011-2.487 12.011S52.104 38.634 52.104 32c0-6.633 1.113-12.011 2.487-12.011 1.373 0 2.487 5.377 2.487 12.012"
      clipRule="evenodd"
    />
  </svg>
);
export default IconMediumAlt;
