import * as React from "react";
const IconSpeechBubble33 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.6 3.6c-.9-1-2.3-1.6-3.7-1.6H13.1c-1.4 0-2.7.6-3.7 1.6C8.5 4.6 8 6 8.1 7.3l2.5 39.9c.2 2.6 2.4 4.7 5 4.7h10.2l5.4 9.5c.2.3.5.5.9.5s.7-.2.9-.5l5.4-9.5h10.2c2.6 0 4.8-2.1 5-4.7l2.5-39.9c-.1-1.3-.6-2.7-1.5-3.7m-3.1 43.5c-.1 1.6-1.4 2.8-3 2.8H37.7c-.4 0-.7.2-.9.5L32 59l-4.8-8.5c-.2-.3-.5-.5-.9-.5H15.5c-1.6 0-2.9-1.2-3-2.8l-2.4-40c-.1-.8.2-1.6.8-2.2s1.4-1 2.2-1h37.8c.8 0 1.6.3 2.2 1 .6.6.9 1.4.8 2.3z" />
    <path d="M32 29.7c.6 0 1-.4 1-1V17.3c0-.6-.4-1-1-1s-1 .4-1 1v11.4c0 .6.4 1 1 1M32 37.4c-1.1 0-2 .9-2 2v.3c0 1.1.9 2 2 2s2-.9 2-2v-.3c0-1.1-.9-2-2-2" />
  </svg>
);
export default IconSpeechBubble33;
