import * as React from "react";
const IconSmartwatchMedical = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M47.5 45.6c-.6 0-1 .4-1 1 0 1.7-1.4 3.1-3.1 3.1H14.6c-1.2 0-2.2-1-2.2-2.2V16.4c0-1.2 1-2.2 2.2-2.2h28.7c1.7 0 3.1 1.4 3.1 3.1 0 .6.4 1 1 1s1-.4 1-1c0-2.8-2.3-5.1-5.1-5.1h-1.6l-1.3-6.1C40.1 3.7 38 2 35.5 2H23.4c-2.4 0-4.5 1.7-5 4.1l-1.3 6.1h-2.5c-2.3 0-4.2 1.9-4.2 4.2v31.2c0 2.3 1.9 4.2 4.2 4.2h2.5l1.3 6.1c.5 2.4 2.6 4.1 5 4.1h12.1c2.4 0 4.5-1.7 5-4.1l1.3-6.1h1.6c2.8 0 5.1-2.3 5.1-5.1 0-.6-.4-1.1-1-1.1M20.3 6.5c.3-1.4 1.6-2.5 3.1-2.5h12.1c1.5 0 2.8 1.1 3.1 2.5l1.2 5.7H19.2zm18.3 51c-.3 1.4-1.6 2.5-3.1 2.5H23.4c-1.5 0-2.8-1.1-3.1-2.5l-1.2-5.7h20.6z" />
    <path d="M52.2 31.3h-3.7v-3.7c0-.6-.4-1-1-1s-1 .4-1 1v3.7h-3.7c-.6 0-1 .4-1 1s.4 1 1 1h3.7V37c0 .6.4 1 1 1s1-.4 1-1v-3.7h3.7c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconSmartwatchMedical;
