import * as React from "react";
const IconBridgeAlt3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.3 32.9H61c.6 0 1-.4 1-1V19.1c0-2.8-2.3-5.1-5.1-5.1H7.1C4.3 14 2 16.3 2 19.1v12.8c0 .6.4 1 1 1h1.7c4.1 0 7.5 3.4 7.5 7.5v5.2c-1.3-.5-2.8-.5-4.1.1l-5.5 2.5c-.5.2-.7.8-.5 1.3q.3.6.9.6c.1 0 .3 0 .4-.1L9 47.5c.8-.4 1.8-.4 2.6 0l3.8 1.7c1.4.6 2.9.6 4.3 0l3.8-1.7c.8-.4 1.8-.4 2.6 0l3.8 1.7c1.4.6 2.9.6 4.3 0l3.8-1.7c.8-.4 1.8-.4 2.6 0l3.8 1.7c1.4.6 2.9.6 4.3 0l3.8-1.7c.8-.4 1.8-.4 2.6 0l5.5 2.5c.5.2 1.1 0 1.3-.5s0-1.1-.5-1.3l-5.5-2.5c-1.3-.6-2.8-.6-4.1-.1v-5.2c0-4.1 3.4-7.5 7.5-7.5M37.1 45.7l-3.8 1.7c-.8.4-1.8.4-2.6 0l-3.8-1.7c-.8-.3-1.6-.5-2.4-.4v-4.8c0-4.1 3.4-7.5 7.5-7.5s7.5 3.4 7.5 7.5v4.8c-.8-.1-1.6 0-2.4.4m10.7 1.7c-.8.4-1.8.4-2.6 0l-3.8-1.7c0-.1.1-.2.1-.4v-4.9c0-5.2-4.3-9.5-9.5-9.5s-9.5 4.3-9.5 9.5v4.9c0 .1 0 .3.1.4l-3.8 1.7c-.8.4-1.8.4-2.6 0l-2.1-.9v-6.1c0-5.2-4.3-9.5-9.5-9.5H4V19.1C4 17.4 5.4 16 7.1 16h49.7c1.7 0 3.1 1.4 3.1 3.1v11.8h-.7c-5.2 0-9.5 4.3-9.5 9.5v6.1z" />
    <path d="M40.2 22.1h-4.7c-.6 0-1 .4-1 1s.4 1 1 1h4.7c.6 0 1-.4 1-1s-.4-1-1-1M28.5 22.1h-4.6c-.6 0-1 .4-1 1s.4 1 1 1h4.6c.6 0 1-.4 1-1s-.5-1-1-1M16.8 22.1h-4.7c-.6 0-1 .4-1 1s.4 1 1 1h4.7c.6 0 1-.4 1-1s-.5-1-1-1M52.9 23.1c0-.6-.4-1-1-1h-4.6c-.6 0-1 .4-1 1s.4 1 1 1h4.6c.6 0 1-.5 1-1" />
  </svg>
);
export default IconBridgeAlt3;
