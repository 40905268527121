import * as React from "react";
const IconLineDotted = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M8.7 29.8H4c-1.2 0-2.3 1-2.3 2.3 0 1.2 1 2.3 2.3 2.3h4.7c1.2 0 2.3-1 2.3-2.3-.1-1.3-1.1-2.3-2.3-2.3M34.3 29.8h-4.7c-1.2 0-2.3 1-2.3 2.3 0 1.2 1 2.3 2.3 2.3h4.7c1.2 0 2.3-1 2.3-2.3s-1-2.3-2.3-2.3M60 29.8h-4.7c-1.2 0-2.3 1-2.3 2.3 0 1.2 1 2.3 2.3 2.3H60c1.2 0 2.3-1 2.3-2.3s-1.1-2.3-2.3-2.3" />
  </svg>
);
export default IconLineDotted;
