import * as React from "react";
const IconShoppingBag9 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M35.3 21.8c-.3-2.6-2.5-4.5-5.1-4.5h-2.4v-.4c0-4.2-3.4-7.6-7.6-7.6s-7.6 3.4-7.6 7.6v.4h-2.4c-2.6 0-4.8 1.9-5.1 4.5L2 49.1c-.2 1.4.3 2.9 1.3 4s2.4 1.7 3.8 1.7h26.3c1.5 0 2.8-.6 3.8-1.7s1.4-2.5 1.3-4zm-20.6-5c0-3.1 2.5-5.6 5.6-5.6s5.6 2.5 5.6 5.6v.4H14.7zm21 34.9c-.6.7-1.4 1-2.3 1H7.1c-.9 0-1.7-.4-2.3-1-.6-.7-.9-1.5-.8-2.4L7.2 22c.2-1.6 1.5-2.7 3.1-2.7h20c1.6 0 2.9 1.2 3.1 2.7l3.2 27.3c0 .9-.3 1.8-.9 2.4M49.4 23.5c0-.6-.4-1-1-1h-6.3c-.6 0-1 .4-1 1s.4 1 1 1h6.3c.5 0 1-.5 1-1M55 34.8c0-.6-.4-1-1-1h-9.1c-.6 0-1 .4-1 1s.4 1 1 1H54c.5 0 1-.4 1-1M61 45.2H47.7c-.6 0-1 .4-1 1s.4 1 1 1H61c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconShoppingBag9;
