import * as React from "react";
const IconTac = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      d="M38.214 31.353A15.9 15.9 0 0 0 40 24c0-8.837-7.163-16-16-16S8 15.163 8 24c0 2.651.645 5.151 1.786 7.353L8.73 33.535A17.9 17.9 0 0 1 6 24c0-9.941 8.059-18 18-18s18 8.059 18 18c0 3.502-1 6.77-2.73 9.535z"
    />
    <path
      
      fillRule="evenodd"
      d="M24 17a5 5 0 1 0 0 10 5 5 0 0 0 0-10m-3 5a3 3 0 1 1 6 0 3 3 0 0 1-6 0"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M14.058 25.253q.063-.147.135-.287A10 10 0 0 1 14 23c0-5.523 4.477-10 10-10s10 4.477 10 10q-.001 1.01-.193 1.966.072.14.135.287l4.727 11.19C39.783 39.082 37.847 42 34.984 42H13.016c-2.863 0-4.799-2.92-3.685-5.556zM24 15a8 8 0 0 0-7.997 8.208c.464-.224.974-.36 1.505-.391.033.698.177 1.369.414 1.993h-.179a2 2 0 0 0-1.842 1.221l-4.727 11.191A2 2 0 0 0 13.016 40h.322a3.5 3.5 0 0 1-.055-2.879l2.48-5.788A5.5 5.5 0 0 1 20.82 28h6.362a5.5 5.5 0 0 1 5.055 3.333l2.48 5.788c.413.96.361 2.004-.054 2.88h.322a2 2 0 0 0 1.843-2.779L32.1 26.032a2 2 0 0 0-1.843-1.222h-.18a6.5 6.5 0 0 0 .415-1.993c.531.03 1.041.167 1.505.39Q32 23.106 32 23a8 8 0 0 0-8-8m8.09 24.879a1.5 1.5 0 0 1-.564.121h-.051a1.5 1.5 0 0 1-.97-.376 1 1 0 0 0-.113-.087l-1.963-4.908a1 1 0 0 0-1.857.742L28.423 40h-8.846l1.851-4.629a1 1 0 0 0-1.857-.742l-1.963 4.908q-.06.039-.113.087c-.268.236-.613.37-.97.376h-.05a1.5 1.5 0 0 1-1.353-2.091l2.48-5.788A3.5 3.5 0 0 1 20.819 30h6.362a3.5 3.5 0 0 1 3.217 2.121l2.48 5.788a1.5 1.5 0 0 1-.787 1.97"
      clipRule="evenodd"
    />
    <path
      
      d="M19.376 12.381a12 12 0 0 1 9.184.065l.779-1.842a14 14 0 0 0-10.715-.076z"
    />
  </svg>
);
export default IconTac;
