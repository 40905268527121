import * as React from "react";
const IconTh = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 85.333 512 341.333"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 85.334h512V426.66H0z" />
    <path  d="M0 194.056h512v123.882H0z" />
    <path  d="M0 85.334h512v54.522H0zm0 286.809h512v54.522H0z" />
  </svg>
);
export default IconTh;
