import * as React from "react";
const IconCompassAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 3c-3.4 0-6.8.6-10 1.8-.5.2-.8.8-.6 1.3s.8.8 1.3.6C25.7 5.6 28.8 5 32 5c14.9 0 27 12.1 27 27S46.9 59 32 59 5 46.9 5 32c0-3.6.7-7.1 2-10.3.2-.5 0-1.1-.5-1.3s-1.1 0-1.3.5C3.7 24.4 3 28.2 3 32c0 16 13 29 29 29s29-13 29-29S48 3 32 3" />
    <path d="M17.1 46.9c.2.2.5.3.7.3s.5-.1.7-.3l6.8-6.8c.2.1.5.3.7.4l14.4 6.1c.6.3 1.3.4 1.9.4 1.2 0 2.4-.5 3.3-1.4 1.4-1.4 1.8-3.4 1-5.2L40.5 26c-.1-.3-.2-.5-.4-.7l6.8-6.8c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-6.8 6.8c-.2-.1-.5-.3-.7-.4l-14.4-6.1c-1.8-.8-3.8-.4-5.2 1s-1.8 3.4-1 5.2L23.5 38c.1.3.2.5.4.7l-6.8 6.8c-.4.4-.4 1 0 1.4m27.7-5.7c.5 1.3.1 2.4-.6 3-.6.6-1.8 1.1-3 .6l-14.4-6.1 11.9-11.9zm-19.5-4-6.1-14.4c-.5-1.3-.1-2.4.6-3 .4-.4 1.1-.8 1.9-.8.3 0 .7.1 1.1.2l14.4 6.1zM12.1 13.6c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4L8.3 6.8c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4z" />
  </svg>
);
export default IconCompassAlt1;
