import * as React from "react";
const IconSyringeCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.7 15.6 48.4 2.3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l5.9 5.9-6.9 7-1.9-1.9a6.64 6.64 0 0 0-9.4 0L15.9 33.3c-1.7 1.7-2.3 4.2-1.7 6.5l-3 3c-2.5 2.5-2.6 6.4-.4 9l-8.5 8.5c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3l8.5-8.5c1.2 1 2.7 1.5 4.3 1.5 1.8 0 3.4-.7 4.7-2l3-3c.6.2 1.2.2 1.7.2 1.7 0 3.4-.6 4.7-1.9l18.7-18.7c1.3-1.3 1.9-2.9 1.9-4.7s-.7-3.4-1.9-4.7L47.4 18l6.9-6.9 5.9 5.9c.2.2.5.3.7.3s.5-.1.7-.3c.5-.4.5-1 .1-1.4M19.8 51.4c-.9.9-2 1.4-3.3 1.4-1.2 0-2.4-.5-3.3-1.4l-.6-.6c-1.8-1.8-1.8-4.8 0-6.6l2.5-2.5c.2.4.5.7.8 1l5.3 5.3c.3.3.7.6 1 .8zm29.5-26.8c0 1.2-.5 2.4-1.4 3.3L29.3 46.6c-1.8 1.8-4.8 1.8-6.6 0l-5.3-5.3c-1.8-1.8-1.8-4.8 0-6.6L36.1 16c1.8-1.8 4.8-1.8 6.6 0l5.3 5.3c.8.9 1.3 2.1 1.3 3.3" />
  </svg>
);
export default IconSyringeCopy;
