import React, { useState } from 'react';
import { BringzzAccordion, BringzzButton, BringzzText } from "@bringzz/components";
import { useNavigation } from 'context/NavigationContext';
import {
    BringzzPageContainer,
    BringzzIcon,
    BringzzRadioList,
    BringzzRadio,
    BringzzBottomDrawer,
    BringzzInput,
    BringzzMultiSelect,
    BringzzCheck
} from "@bringzz/components";
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import useRequest from 'hooks/useRequest';
import { useAuth } from 'context/AuthContext';

const ConsumerInsurancePage = () => {
    const { backPress } = useNavigation();
    const { user, refreshUserData } = useAuth()
    const { navigate } = useNavigation();

    const [drawerOpened, setDrawerOpened] = useState(false)
    const [privateInsuranceOpened, setPrivateInsuranceOpened] = useState(false)
    const [selected, setSelected] = useState(null)
    const defaultInsruanceCompanies = ["Allianz", "Donau Versicherung.", "Generali", "GRAWE", "MERKUR", "Muki", "Uniqa", "Wiener Städtische"]
    const [insuranceCompanies, setInsuranceCompanies] = useState(defaultInsruanceCompanies)
    const [selectedInsuranceCompanies, setSelectedInsuranceCompanies] = useState([user.privateInsuranceCompanyname] || [])
    const [insuranceNumber, setInsuranceNumber] = useState(user.privateInsuranceId || '')
    const [insuranceConfirmed, setInsuranceConfirmed] = useState(false)
    const { sendRequest, data, error, loading } = useRequest();

    let iconNo = (
        <div className={classNames('rounded-l flex items-center justify-center p-1', selected == "no" ? "bg-magic-lilac" : "bg-sand")}>
            <BringzzIcon
                icon="IconShield"
                folder="LineIcons"
                className="flex items-center justify-center h-12 w-12 rounded-full"
                size="38"
            />
        </div>
    );

    let iconYes = (
        <div className={classNames('rounded-l flex items-center justify-center p-1', selected == "yes" ? "bg-magic-lilac" : "bg-sand")}>
            <BringzzIcon
                icon="IconShieldBlack"
                folder="CustomIcons"
                className="flex items-center justify-center h-12 w-12 rounded-full"
                size="38"
            />
        </div>
    );

    let state = {
        checked: (
            <div></div>
        ),
        unChecked: (
            <div></div>
        ),
    };

    let insuranceState = {
        checked: (
            <BringzzIcon
                icon="IconCheck"
                library="Custom"
                className="h-5 w-5 p-1 bg-magic-lilac text-light rounded-full"
                size="12"
            />
        ),
        unChecked: (
            <div></div>
        ),
    };

    const handleMultiSelectChange = (selectedValues) =>
        setSelectedInsuranceCompanies(selectedValues)

    const submit = async () => {
        const result = await sendRequest({
            method: 'POST',
            url: `/module/profile/changeInsurance`,
            data: {
                privateInsuranceCompanyname: selectedInsuranceCompanies.toString(),
                privateInsuranceId: insuranceNumber
            }
        })
            .then(response => {
                if (response.status === 'success') {
                    refreshUserData()
                    return response
                };
            })
            .catch(error => {
                console.error(error);
                return false;
            });

        if (result) navigate(`/register/consumer/payment`)
    }

    return (
        <BringzzPageContainer
            className="bg-white"
            steps={true}
            activeStep={3}
            header={{
                left: (
                    <BringzzIcon
                        size='22'
                        icon='IconChevronLeft'
                        folder='LineIcons'
                        className='cursor-pointer'
                        onClick={backPress}
                    />
                ),
                title: "Join Us",
                right: <BringzzButton size='sm' className="border border-black" onClick={() => {
                    navigate('/register/consumer/payment');
                }}>Skip</BringzzButton>
            }}
            footer={
                <div className='pb-8 px-6'>
                    <BringzzButton
                        size='lg'
                        className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
                        onClick={() => {
                            if (selected === "yes") {
                                if (insuranceConfirmed) submit()
                                else setDrawerOpened(true)
                            } else {
                                navigate("/register/consumer/payment")
                            }
                        }}
                        disabled={!selected || loading}
                    >
                        Continue
                    </BringzzButton>
                </div>
            }
            authenticated={false}
        >
            <div className='relative flex justify-center bg-magic-lilac/20'>
                <div className='relative bg-white w-full bg-cover bg-center flex flex-col'>
                    {/* Content */}
                    <div className='flex-grow flex flex-col py-8 px-4'>
                        <div className='z-10 flex flex-col '>
                            <div className='pl-2 flex flex-col space-y-6 md:max-w-lg lg:max-w-xl xl:max-w-2xl'>
                                <BringzzText tag='h1' className='pl-2 text-midnight-blue font-bold'>
                                    Private Insurance
                                </BringzzText>
                                <BringzzText tag="h3" className="font-normal">Let us know if you are privately insured, it could benefit the pricing of your basket</BringzzText>
                            </div>
                            <div className='p-4'>
                                <div className='mt-6'>
                                    <BringzzRadioList
                                        onChange={(newValue) => {
                                            if (newValue == "no") {
                                                setInsuranceConfirmed(false)
                                            }
                                            setSelected(newValue)
                                        }}
                                        state={state}
                                        className="space-y-6"
                                    >
                                        <BringzzRadio left={iconNo} leftClassname="flex items-stretch" className={classNames("items-stretch border-2 rounded-lg", selected == "no" ? "border-magic-lilac" : "border-sand")} value="no">
                                            <div className='-ml-2 py-1'>
                                                <BringzzText tag="h2">No, I don’t have<br></br>private insurance.</BringzzText>
                                                <BringzzText tag="h5" className="font-normal">Lorem ipsum dolor sit amet consectetur. Nascetur egestas imperdiet amet nulla ultricies.</BringzzText>
                                            </div>
                                        </BringzzRadio>
                                        <BringzzRadio left={iconYes} leftClassname="flex items-stretch" className={classNames("items-stretch border-2 rounded-lg", selected == "yes" ? "border-magic-lilac" : "border-sand")} value="yes">
                                            <div className='-ml-2 py-1'>
                                                <BringzzText tag="h2">Yes, I have<br></br>private insurance.</BringzzText>
                                                <BringzzText tag="h5" className="font-normal">Lorem ipsum dolor sit amet consectetur. Nascetur egestas imperdiet amet nulla ultricies.</BringzzText>
                                            </div>
                                        </BringzzRadio>
                                    </BringzzRadioList>
                                </div>
                                {insuranceConfirmed &&
                                    <BringzzAccordion title="Private Insurnace Information" className='mt-5 border-t' defaultValue={true}><div className='p-4 space-y-4'>
                                        <BringzzInput
                                            label="Private Insurance Company"
                                            placeholder={selectedInsuranceCompanies ? selectedInsuranceCompanies.join(', ') : "- select -"}
                                            type="text"
                                            readOnly={true}
                                            className='cursor-pointer'
                                            containerClassname='w-full'
                                            icon={{
                                                right: <BringzzIcon
                                                    icon="IconChevronDown"
                                                    library="Custom"
                                                    className="h-5 w-5 p-1 text-midnight-blue rounded-full"
                                                    size="12"
                                                />
                                            }}
                                            onClick={() => setPrivateInsuranceOpened(true)} />
                                        <BringzzInput label="Insurance Policy Number" defaultValue={insuranceNumber} placeholder="Insurance Policy Number" onChange={(e, isValid, newValue) => {
                                            console.log(newValue);
                                            setInsuranceNumber(newValue)
                                        }} />
                                    </div></BringzzAccordion>
                                }
                            </div>
                        </div>
                    </div>
                    <BringzzBottomDrawer isOpen={drawerOpened} title={"Private Insurance"} close={() => setDrawerOpened(false)}>
                        <div className='p-4 space-y-4'>
                            <BringzzInput
                                label="Private Insurance Company"
                                placeholder={selectedInsuranceCompanies ? selectedInsuranceCompanies.join(', ') : "- select -"}
                                type="text"
                                readOnly={true}
                                className='cursor-pointer'
                                containerClassname='w-full'
                                icon={{
                                    right: <BringzzIcon
                                        icon="IconChevronDown"
                                        library="Custom"
                                        className="h-5 w-5 p-1 text-midnight-blue rounded-full"
                                        size="12"
                                    />
                                }}
                                onClick={() => setPrivateInsuranceOpened(true)} />
                            <BringzzInput label="Insurance Policy Number" defaultValue={insuranceNumber} placeholder="Insurance Policy Number" onChange={(e, isValid, newValue) => {
                                setInsuranceNumber(newValue)
                            }} />
                            <div className='flex w-full text-center gap-2'>
                                <BringzzButton className="border border-black w-full flex justify-center" onClick={() => setDrawerOpened(false)}>Cancel</BringzzButton>
                                <BringzzButton className="bg-magic-lilac w-full flex justify-center"
                                    onClick={() => {
                                        setInsuranceConfirmed(true)
                                        setDrawerOpened(false)
                                    }}
                                    disabled={(!selectedInsuranceCompanies || insuranceNumber == "")}
                                >Confirm</BringzzButton>
                            </div>
                        </div>
                    </BringzzBottomDrawer>
                    <BringzzBottomDrawer isOpen={privateInsuranceOpened} title={"Private Insurance"} close={() => setPrivateInsuranceOpened(false)}>
                        <div className='p-4 space-y-3'>
                            <BringzzMultiSelect
                                onChange={handleMultiSelectChange}
                                state={{ checked: insuranceState.checked, unChecked: insuranceState.unChecked }}
                                className="divide-y"
                            >
                                {insuranceCompanies.map((specialty, index) => {
                                    return <BringzzCheck key={index} value={specialty}>{specialty}</BringzzCheck>
                                })}

                            </BringzzMultiSelect>
                            <BringzzInput onEnterPress={(newValue) => setInsuranceCompanies([newValue, ...insuranceCompanies])} placeholder='other Insurance Company'></BringzzInput>
                            <BringzzText tag='h5'>In case your Company is not listed, please write their names into the Input Field. We will add your Insurance into our List.</BringzzText>
                            <BringzzButton
                                className='bg-magic-lilac w-full flex justify-center'
                                onClick={() => {
                                    setPrivateInsuranceOpened(false)
                                }}
                                disabled={!selectedInsuranceCompanies}
                            >
                                Confirm
                            </BringzzButton>
                        </div>
                    </BringzzBottomDrawer>
                </div>
            </div>
        </BringzzPageContainer>
    );
};

export default ConsumerInsurancePage;
