import * as React from "react";
const IconCowAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62 3c0-.6-.4-1-1-1s-1 .4-1 1c0 8.6-7 15.6-15.6 15.6H19.6C11 18.6 4 11.6 4 3c0-.6-.4-1-1-1s-1 .4-1 1c0 8.2 5.6 15.1 13.2 17l4 30.3v.7h.1l.7 5.4c.4 3.2 3.2 5.7 6.5 5.7h12.2c3.4 0 6.2-2.5 6.5-5.9L48.9 20C56.4 18 62 11.1 62 3M43.3 55.9c-.3 2.3-2.2 4.1-4.5 4.1H26.5c-2.3 0-4.2-1.7-4.5-3.9l-.6-5.1h22.4zm3.5-35.3L44 49H21.1l-3.8-28.4h29.5" />
    <path d="M25.5 31.3c1.5 0 2.8-1.2 2.8-2.8 0-1.5-1.2-2.8-2.8-2.8-1.5 0-2.8 1.2-2.8 2.8s1.2 2.8 2.8 2.8M39.3 31.3c1.5 0 2.8-1.2 2.8-2.8 0-1.5-1.2-2.8-2.8-2.8-1.5 0-2.8 1.2-2.8 2.8s1.2 2.8 2.8 2.8" />
  </svg>
);
export default IconCowAlt;
