import * as React from "react";
const IconAbstract = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.2 2H44.9C37.3 2 31 8.3 31 15.9V31H15.9C8.3 31 2 37.3 2 44.9v11.2C2 59.4 4.6 62 7.8 62H19c7.7 0 14-6.3 14-13.9V33h15.1C55.7 33 62 26.7 62 19.1V7.8C62 4.6 59.4 2 56.2 2M31 48.1C31 54.6 25.6 60 19.1 60H7.8C5.7 60 4 58.3 4 56.2V44.9C4 38.4 9.4 33 15.9 33H31zm29-29C60 25.6 54.6 31 48.1 31H33V15.9C33 9.4 38.4 4 44.9 4h11.2C58.3 4 60 5.7 60 7.8z" />
  </svg>
);
export default IconAbstract;
