import * as React from "react";
const IconSidebar = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.2 2.1H8.8C5.1 2.1 2 5.1 2 8.9v46.4c0 3.7 3.1 6.8 6.8 6.8h46.4c3.7 0 6.8-3.1 6.8-6.8V8.9c0-3.8-3.1-6.8-6.8-6.8M4 55.3V8.9c0-2.6 2.2-4.8 4.8-4.8h33.8v56H8.8c-2.6 0-4.8-2.2-4.8-4.8m56 0c0 2.6-2.2 4.8-4.8 4.8H44.6v-56h10.6c2.6 0 4.8 2.2 4.8 4.8z" />
  </svg>
);
export default IconSidebar;
