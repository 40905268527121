import * as React from "react";
const IconNe = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 85.333 512 341.333"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 85.337h512v341.326H0z" />
    <path  d="M0 85.337h512v113.775H0z" />
    <path  d="M0 312.888h512v113.775H0z" />
    <circle cx={256} cy={256} r={32}  />
  </svg>
);
export default IconNe;
