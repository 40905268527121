import * as React from "react";
const IconScan = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M3 16.1c.6 0 1-.4 1-1V6.4c0-1 .8-1.8 1.8-1.8h8.6c.6 0 1-.4 1-1s-.4-1-1-1H5.8C3.7 2.7 2 4.3 2 6.4v8.7c0 .6.4 1 1 1M58.2 2.7h-8.6c-.6 0-1 .4-1 1s.4 1 1 1h8.6c1 0 1.8.8 1.8 1.8v8.7c0 .6.4 1 1 1s1-.4 1-1V6.4c0-2.1-1.7-3.7-3.8-3.7M61 47.9c-.6 0-1 .4-1 1v8.7c0 1-.8 1.8-1.8 1.8h-8.6c-.6 0-1 .4-1 1s.4 1 1 1h8.6c2.1 0 3.8-1.7 3.8-3.8v-8.7c0-.6-.4-1-1-1M14.4 59.3H5.8c-1 0-1.8-.8-1.8-1.8v-8.7c0-.6-.4-1-1-1s-1 .4-1 1v8.7c0 2.1 1.7 3.8 3.8 3.8h8.6c.6 0 1-.4 1-1s-.5-1-1-1M51.1 32c0-.6-.2-1.2-.7-1.7-2.7-2.7-9.9-8.9-18.4-8.9-8.6 0-15.8 6.2-18.4 8.9-.5.4-.7 1-.7 1.7s.2 1.2.7 1.7c2.7 2.7 9.9 8.9 18.4 8.9 8.6 0 15.8-6.2 18.4-8.9.5-.5.7-1.1.7-1.7m-2.1.3c-2.5 2.5-9.2 8.3-17 8.3s-14.5-5.8-17-8.3c-.1-.1-.1-.2-.1-.3s0-.2.1-.3c2.5-2.5 9.2-8.3 17-8.3s14.5 5.8 17 8.3c.1.1.1.2.1.3s0 .2-.1.3" />
    <path d="M32 28.4c-2 0-3.6 1.6-3.6 3.6s1.6 3.6 3.6 3.6 3.6-1.6 3.6-3.6-1.6-3.6-3.6-3.6" />
  </svg>
);
export default IconScan;
