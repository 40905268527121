import * as React from "react";
const IconChestOfDrawers6 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.6 2H13.4c-2.8 0-5 2.2-5 5v50c0 2.8 2.2 5 5 5h37.2c2.8 0 5-2.2 5-5V7c0-2.8-2.2-5-5-5M10.4 38.3h43.2v9.4H10.4zM53.6 7v29.3H33V4h17.6c1.7 0 3 1.3 3 3M13.4 4H31v32.3H10.4V7c0-1.7 1.3-3 3-3m37.2 56H13.4c-1.7 0-3-1.3-3-3v-7.3h43.2V57c0 1.7-1.3 3-3 3" />
    <path d="M43.1 22.7c.6 0 1-.4 1-1v-2.6c0-.6-.4-1-1-1s-1 .4-1 1v2.6c0 .6.5 1 1 1M20.9 22.7c.6 0 1-.4 1-1v-2.6c0-.6-.4-1-1-1s-1 .4-1 1v2.6c0 .6.4 1 1 1" />
  </svg>
);
export default IconChestOfDrawers6;
