import * as React from "react";
const IconYenDown = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M38.2 11.5c-.5-.3-1.1-.1-1.4.3l-4.8 8-4.8-8c-.3-.5-.9-.6-1.4-.3s-.6.9-.3 1.4l5.3 8.8h-3.3c-.6 0-1 .4-1 1s.4 1 1 1H31v4.8h-3.5c-.6 0-1 .4-1 1s.4 1 1 1H31V34c0 .6.4 1 1 1s1-.4 1-1v-3.5h3.5c.6 0 1-.4 1-1s-.4-1-1-1H33v-4.8h3.5c.6 0 1-.4 1-1s-.4-1-1-1h-3.3l5.3-8.8c.3-.5.1-1.1-.3-1.4" />
    <path d="M32 4c10.6 0 19.1 8.5 19.1 19 0 5.8-2.6 11.2-7.1 14.8-.4.3-.5 1-.2 1.4.2.2.5.4.8.4.2 0 .4-.1.6-.2 5-4 7.9-10 7.9-16.4 0-11.6-9.5-21-21.1-21s-21.1 9.4-21.1 21c0 .6.4 1 1 1s1-.4 1-1c0-10.5 8.5-19 19.1-19M52.1 49.9c-.6 0-1 .4-1 1v7.8L38 49.3c-.7-.5-1.6-.8-2.5-.8H23.3c-.7 0-1.4-.3-1.8-.8l-6.3-6.5c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l6.3 6.5c.8.9 2 1.4 3.3 1.4h12.2c.5 0 1 .1 1.4.4L49.4 60H42c-.6 0-1 .4-1 1s.4 1 1 1h10.1c.6 0 1-.4 1-1V50.9c0-.5-.4-1-1-1" />
  </svg>
);
export default IconYenDown;
