import * as React from "react";
const IconPullUp = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 57.2h-6.2V5.8c0-.6-.4-1-1-1s-1 .4-1 1V16H11.2V5.8c0-.6-.4-1-1-1s-1 .4-1 1v51.4H3c-.6 0-1 .4-1 1s.4 1 1 1h58c.6 0 1-.4 1-1s-.4-1-1-1m-49.8 0V18h41.6v39.2z" />
  </svg>
);
export default IconPullUp;
