import * as React from "react";
const IconMailServer = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62 40.7v-8.9c0-2.2-1.8-4-4-4h-3.2V9.6c0-1-.3-2-.8-2.8v-.1c-.9-1.4-2.5-2.3-4.3-2.3H7.2C4.3 4.4 2 6.7 2 9.6v27.1c0 2.9 2.3 5.2 5.2 5.2h12.1q.3 1.05 1.2 1.8c-.8.7-1.4 1.8-1.4 3v8.9c0 2.2 1.8 4 4 4H58c2.2 0 4-1.8 4-4v-8.9c0-1.2-.5-2.3-1.4-3 .9-.7 1.4-1.8 1.4-3M49.6 6.4q1.05 0 1.8.6L29.9 18.8c-1 .5-2.1.5-3.1 0L5.4 7c.5-.4 1.2-.6 1.8-.6zM4 36.7V9.6c0-.3.1-.7.2-1l21.7 12c.8.4 1.6.6 2.5.6s1.7-.2 2.5-.6l21.7-12c.1.3.2.6.2 1v18.2H23.1c-2.2 0-4 1.8-4 4v8.1H7.2c-1.8 0-3.2-1.4-3.2-3.2m56 10v8.9c0 1.1-.9 2-2 2H23.1c-1.1 0-2-.9-2-2v-8.9c0-1.1.9-2 2-2H58c1.1 0 2 .9 2 2m-36.9-4c-1.1 0-2-.9-2-2v-8.9c0-1.1.9-2 2-2H58c1.1 0 2 .9 2 2v8.9c0 1.1-.9 2-2 2z" />
    <path d="M28.9 34.1c-1.1 0-2 .9-2 2v.1c0 1.1.9 2 2 2s2-.9 2-2v-.1c0-1.1-.9-2-2-2M53.8 35.1H49c-.6 0-1 .4-1 1s.4 1 1 1h4.8c.6 0 1-.4 1-1s-.5-1-1-1M28.9 53.1c1.1 0 2-.9 2-2V51c0-1.1-.9-2-2-2s-2 .9-2 2v.1c0 1.1.9 2 2 2M49 52.1h4.8c.6 0 1-.4 1-1s-.4-1-1-1H49c-.6 0-1 .4-1 1s.5 1 1 1" />
  </svg>
);
export default IconMailServer;
