import * as React from "react";
const IconStripe = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 64 65"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M63 32.93c0-4.408-2.136-7.887-6.217-7.887-4.1 0-6.58 3.479-6.58 7.853 0 5.184 2.929 7.802 7.13 7.802 2.05 0 3.6-.465 4.771-1.12v-3.444c-1.17.585-2.514.947-4.219.947-1.67 0-3.152-.586-3.341-2.618h8.422c0-.224.034-1.12.034-1.532m-8.508-1.636c0-1.946 1.189-2.755 2.274-2.755 1.05 0 2.17.81 2.17 2.755zM43.556 25.043c-1.688 0-2.773.792-3.375 1.343l-.224-1.068h-3.79V45.4l4.306-.912.018-4.874c.62.447 1.532 1.085 3.048 1.085 3.083 0 5.89-2.48 5.89-7.94-.017-4.994-2.859-7.715-5.873-7.715m-1.033 11.866c-1.016 0-1.619-.362-2.032-.81l-.018-6.39c.448-.498 1.068-.843 2.05-.843 1.567 0 2.652 1.757 2.652 4.013 0 2.308-1.068 4.03-2.652 4.03M30.243 24.027l4.323-.93V19.6l-4.323.912z"
      clipRule="evenodd"
    />
    <path  d="M34.566 25.336h-4.323v15.07h4.323z" />
    <path
      
      fillRule="evenodd"
      d="m25.61 26.61-.275-1.274h-3.72v15.07h4.306V30.191c1.016-1.326 2.738-1.085 3.272-.895v-3.961c-.551-.207-2.566-.586-3.583 1.274M17 21.598l-4.203.896-.017 13.795c0 2.549 1.912 4.426 4.46 4.426 1.413 0 2.446-.258 3.015-.568V36.65c-.552.223-3.273 1.016-3.273-1.533v-6.114h3.273v-3.668h-3.273zM5.357 29.71c0-.672.551-.93 1.464-.93 1.309 0 2.962.396 4.271 1.102v-4.047c-1.43-.568-2.841-.792-4.27-.792C3.324 25.043 1 26.868 1 29.917c0 4.753 6.544 3.995 6.544 6.045 0 .792-.688 1.05-1.653 1.05-1.43 0-3.255-.585-4.702-1.378v4.1c1.602.688 3.221.98 4.702.98 3.582 0 6.045-1.773 6.045-4.856-.017-5.132-6.579-4.22-6.579-6.148"
      clipRule="evenodd"
    />
  </svg>
);
export default IconStripe;
