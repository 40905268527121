import * as React from "react";
const IconMinusFront = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.2 60h-8.1c-.6 0-1 .4-1 1s.4 1 1 1h8.1c.6 0 1-.4 1-1s-.5-1-1-1M35.8 60H28c-.6 0-1 .4-1 1s.4 1 1 1h7.8c.6 0 1-.4 1-1s-.4-1-1-1M50.8 17.6h-1.4V7.1c0-2.8-2.3-5.1-5.1-5.1H7.1C4.3 2 2 4.3 2 7.1v37.1c0 2.8 2.3 5.1 5.1 5.1h10.2v1.2c0 .6.4 1 1 1s1-.4 1-1V22.7c0-1.7 1.4-3.1 3.1-3.1h28.3c.6 0 1-.4 1-1s-.4-1-.9-1m-33.4 5.1v24.7H7.1C5.4 47.4 4 46 4 44.2V7.1C4 5.4 5.4 4 7.1 4h37.1c1.7 0 3.1 1.4 3.1 3.1v10.4H22.5c-2.8.1-5.1 2.4-5.1 5.2M57.1 19.4c1.6 0 2.9 1.3 2.9 2.9 0 .6.4 1 1 1s1-.4 1-1c0-2.7-2.2-4.9-4.9-4.9-.6 0-1 .4-1 1s.4 1 1 1M61 27.6c-.6 0-1 .4-1 1v7.8c0 .6.4 1 1 1s1-.4 1-1v-7.8c0-.6-.4-1-1-1M61 41.7c-.6 0-1 .4-1 1v7.8c0 .6.4 1 1 1s1-.4 1-1v-7.8c0-.5-.4-1-1-1M61 56.1c-.6 0-1 .4-1 1 0 1.6-1.3 2.9-2.9 2.9-.6 0-1 .4-1 1s.4 1 1 1c2.7 0 4.9-2.2 4.9-4.9 0-.6-.4-1-1-1M22.3 60c-1.6 0-2.9-1.3-2.9-2.9 0-.6-.4-1-1-1s-1 .4-1 1c0 2.7 2.2 4.9 4.9 4.9.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconMinusFront;
