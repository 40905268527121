import * as React from "react";
const IconWebsiteAltCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m17.5 28.8-3 4.4-2.7-3.9q-.45-.6-1.2-.6t-1.2.6l-2.7 3.9-3-4.4c-.3-.5-.9-.6-1.4-.3s-.6.9-.3 1.4l3.5 5.2c.5.7 1.8.7 2.3 0l2.7-3.9 2.7 3.9q.45.6 1.2.6c.75 0 .9-.2 1.2-.6l3.5-5.2c.3-.5.2-1.1-.3-1.4-.4-.3-1-.2-1.3.3M38.8 28.8l-3 4.4-2.7-3.9c-.5-.7-1.8-.7-2.3 0l-2.7 3.9-3-4.4c-.3-.5-.9-.6-1.4-.3s-.6.9-.3 1.4l3.5 5.2c.5.7 1.8.7 2.3 0l2.7-3.9 2.7 3.9q.45.6 1.2.6t1.2-.6l3.5-5.2c.3-.5.2-1.1-.3-1.4-.4-.3-1.1-.2-1.4.3M61.6 28.5c-.5-.3-1.1-.2-1.4.3l-3 4.4-2.7-3.9c-.5-.7-1.8-.7-2.3 0l-2.7 3.9-3-4.4c-.3-.5-.9-.6-1.4-.3s-.6.9-.3 1.4l3.5 5.2c.5.8 1.8.7 2.3 0l2.7-3.9 2.7 3.9q.45.6 1.2.6t1.2-.6l3.5-5.2c.2-.5.1-1.1-.3-1.4" />
  </svg>
);
export default IconWebsiteAltCopy;
