import * as React from "react";
const IconLaptopAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 42.5h-4.2V15.3c0-3.7-3-6.6-6.6-6.6H13.8c-3.7 0-6.6 3-6.6 6.6v27.2H3c-.6 0-1 .4-1 1V48c0 4.1 3.3 7.4 7.4 7.4h45.2c4.1 0 7.4-3.3 7.4-7.4v-4.4c0-.6-.4-1.1-1-1.1M9.2 15.3c0-2.6 2.1-4.6 4.6-4.6h36.4c2.6 0 4.6 2.1 4.6 4.6v27.2H9.2zM60 48c0 3-2.4 5.4-5.4 5.4H9.4C6.4 53.4 4 51 4 48v-3.4h56z" />
    <path d="M34 16.7h-4c-.6 0-1 .4-1 1s.4 1 1 1h4c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconLaptopAlt;
