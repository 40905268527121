import * as React from "react";
const IconWristwatch = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M47.9 31.7c0-3-.8-5.8-2.2-8.2v-.1L42 6c-.5-2.5-2.8-4.3-5.3-4.3h-10c-2.5 0-4.8 1.8-5.3 4.3l-3.7 17.6c-1.4 2.4-2.2 5.2-2.2 8.1 0 3 .8 5.7 2.2 8.1l3.7 17.5c.5 2.5 2.8 4.3 5.3 4.3h10c2.5 0 4.8-1.8 5.3-4.3l3.7-17.5c1.4-2.3 2.2-5.1 2.2-8.1M23.3 6.4c.3-1.6 1.7-2.7 3.3-2.7h10c1.6 0 3 1.1 3.3 2.7l3 13.6c-2.9-2.8-6.9-4.6-11.2-4.6-4.4 0-8.3 1.7-11.2 4.6zm8.4 11.1c7.8 0 14.2 6.4 14.2 14.2S39.5 46 31.7 46s-14.2-6.4-14.2-14.2 6.3-14.3 14.2-14.3M40 57c-.3 1.6-1.7 2.7-3.3 2.7h-10c-1.6 0-3-1.1-3.3-2.7l-2.9-13.6c2.9 2.8 6.9 4.6 11.3 4.6 4.3 0 8.3-1.7 11.2-4.5z" />
  </svg>
);
export default IconWristwatch;
