import * as React from "react";
const IconTruckStar = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.8 33.9-7.2-10.4c-.2-.3-.5-.4-.8-.4H41.7v-3.3c0-2.9-2.4-5.3-5.3-5.3h-8c-.6 0-1 .4-1 1s.4 1 1 1h8c1.8 0 3.3 1.5 3.3 3.3v30H28c-.2-2.7-2.5-4.9-5.3-4.9s-5.1 2.2-5.3 4.9H9.5c-1.8 0-3.3-1.5-3.3-3.3V32.7c0-.6-.4-1-1-1s-1 .4-1 1v13.9c0 2.9 2.4 5.3 5.3 5.3h8.1c.7 2.2 2.7 3.8 5.1 3.8s4.4-1.6 5.1-3.8H45c.7 2.2 2.7 3.8 5.1 3.8s4.4-1.6 5.1-3.8H61c.6 0 1-.4 1-1V34.5c0-.2-.1-.4-.2-.6M53.3 25l6.3 9.2H41.7V25zM22.7 53.6c-1.8 0-3.3-1.5-3.3-3.3s1.5-3.3 3.3-3.3 3.3 1.5 3.3 3.3-1.5 3.3-3.3 3.3m27.4 0c-1.8 0-3.3-1.5-3.3-3.3s1.5-3.3 3.3-3.3 3.3 1.5 3.3 3.3-1.5 3.3-3.3 3.3m5.3-3.7c-.2-2.7-2.5-4.9-5.3-4.9s-5.1 2.2-5.3 4.9h-3.2V36.2H60v13.7z" />
    <path d="M5 24.5c0 .3.2.6.4.8s.6.2.9.1l4.6-1.4 4.6 1.4h.3c.2 0 .4-.1.6-.2.3-.2.4-.5.4-.8l.1-4.8 2.8-3.9c.2-.3.2-.6.1-.9s-.3-.5-.6-.6l-4.6-1.5-2.9-3.9c-.4-.5-1.2-.5-1.6 0l-2.9 3.9-4.6 1.5q-.45.15-.6.6c-.15.45 0 .6.1.9l2.9 4zm3.2-10.1c.2-.1.4-.2.5-.4l2.3-3 2.3 3c.1.2.3.3.5.4l3.6 1.2-2.2 3.1c-.1.2-.2.4-.2.6v3.8L11.2 22h-.6L7 23.1v-3.8c0-.2-.1-.4-.2-.6l-2.2-3.1z" />
  </svg>
);
export default IconTruckStar;
