import * as React from "react";
const IconBlackboardAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.6 9.7H33V4c0-.6-.4-1-1-1s-1 .4-1 1v5.7H8.4c-3 0-5.4 2.4-5.4 5.4v22.2c0 3 2.4 5.4 5.4 5.4h19.8l-7.9 16.8c-.2.5 0 1.1.5 1.3.1.2.2.2.4.2q.6 0 .9-.6l8.3-17.7h3.1l8.4 17.7q.3.6.9.6c.1 0 .3 0 .4-.1.5-.2.7-.8.5-1.3l-8-16.8h19.8c3 0 5.4-2.4 5.4-5.4V15.2c.1-3-2.3-5.5-5.3-5.5M59 37.4c0 1.9-1.5 3.4-3.4 3.4H8.4c-1.9 0-3.4-1.5-3.4-3.4V15.2c0-1.9 1.5-3.4 3.4-3.4h47.1c1.9 0 3.4 1.5 3.4 3.4v22.2z" />
  </svg>
);
export default IconBlackboardAlt;
