import * as React from "react";
const IconLaptopStar1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 45.9h-5.1V23.2c0-3.9-3.2-7.1-7.1-7.1H15.2c-3.9 0-7.1 3.2-7.1 7.1v22.7H3c-.6 0-1 .4-1 1s.4 1 1 1h58c.6 0 1-.4 1-1s-.4-1-1-1m-8.8 0H10.1V23.2c0-2.8 2.3-5.1 5.1-5.1h33.6c2.8 0 5.1 2.3 5.1 5.1v22.7z" />
    <path d="m40.9 29.1-5.5-.8-2.5-5c-.3-.7-1.5-.7-1.8 0l-2.5 5-5.5.8c-.4.1-.7.3-.8.7s0 .8.3 1l4 3.9-.9 5.5c-.1.4.1.8.4 1 .2.1.4.2.6.2s.3 0 .5-.1l4.9-2.6 4.9 2.6c.3.2.7.1 1.1-.1.3-.2.5-.6.4-1l-.9-5.5 4-3.9c.3-.3.4-.7.3-1-.4-.4-.7-.7-1-.7m-5.2 4.5c-.2.2-.3.6-.3.9l.7 4-3.6-1.9c-.1-.1-.3-.1-.5-.1s-.3 0-.5.1l-3.6 1.9.7-4c.1-.3-.1-.7-.3-.9l-2.9-2.8 4-.6c.3 0 .6-.3.8-.5L32 26l1.8 3.6c.1.3.4.5.8.5l4 .6z" />
  </svg>
);
export default IconLaptopStar1;
