import * as React from "react";
const IconToolbox = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55 15.2H43.5v-4.3c0-3.5-2.8-6.3-6.3-6.3H26.7c-3.5 0-6.3 2.8-6.3 6.3v4.3H9c-3.9 0-7 3.1-7 7V32c0 .6.4 1 1 1h1.6v19.7c0 3.7 3 6.7 6.7 6.7h41.4c3.7 0 6.7-3 6.7-6.7V33H61c.6 0 1-.4 1-1v-9.8c0-3.9-3.1-7-7-7m-32.5-4.3c0-2.4 1.9-4.3 4.3-4.3h10.5c2.4 0 4.3 1.9 4.3 4.3v4.3H22.5zm34.9 41.8c0 2.6-2.1 4.7-4.7 4.7H11.3c-2.6 0-4.7-2.1-4.7-4.7V33h13.8v6.9c0 .6.4 1 1 1s1-.4 1-1V33h19.1v6.9c0 .6.4 1 1 1s1-.4 1-1V33h13.8v19.7zM60 31H4v-8.8c0-2.8 2.2-5 5-5h46c2.8 0 5 2.2 5 5z" />
  </svg>
);
export default IconToolbox;
