import * as React from "react";
const IconMuted1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
    <path d="M43 40.3h-3v-3c0-.6-.4-1-1-1s-1 .4-1 1v3h-5v-3c0-.6-.4-1-1-1s-1 .4-1 1v3h-5v-3c0-.6-.4-1-1-1s-1 .4-1 1v3h-3c-.6 0-1 .4-1 1s.4 1 1 1h3v3c0 .6.4 1 1 1s1-.4 1-1v-3h5v3c0 .6.4 1 1 1s1-.4 1-1v-3h5v3c0 .6.4 1 1 1s1-.4 1-1v-3h3c.6 0 1-.4 1-1s-.4-1-1-1M25 22.8c0-1.9-1.6-3.5-3.5-3.5S18 20.9 18 22.8s1.6 3.5 3.5 3.5 3.5-1.6 3.5-3.5M42.6 19.3c-1.9 0-3.5 1.6-3.5 3.5s1.6 3.5 3.5 3.5 3.5-1.6 3.5-3.5-1.6-3.5-3.5-3.5" />
  </svg>
);
export default IconMuted1;
