import * as React from "react";
const IconCreditCard = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M7 38a3 3 0 0 1-3-3V13a3 3 0 0 1 3-3h34a3 3 0 0 1 3 3v22a3 3 0 0 1-3 3zM6 13a1 1 0 0 1 1-1h34a1 1 0 0 1 1 1v3H6zm1 23a1 1 0 0 1-1-1V24h36v11a1 1 0 0 1-1 1z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconCreditCard;
