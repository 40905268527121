import * as React from "react";
const IconFolder = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 19.6v-3.3c0-3.4-2.7-6.1-6.1-6.1H32.7l-.3-.8c-.7-1.8-2.4-2.9-4.3-2.9H7.9c-3.4 0-6.1 2.7-6.1 6.1v38.9c0 3.4 2.7 6.1 6.1 6.1h48.3c3.4 0 6.1-2.7 6.1-6.1V22.7c0-1.2-.5-2.3-1.3-3.1m-6.1-5c.9 0 1.6.7 1.6 1.6v1.9H35.9l-1.4-3.5zm2.9 36.9c0 .9-.7 1.6-1.6 1.6H7.9c-.9 0-1.6-.7-1.6-1.6v-39c0-.9.7-1.6 1.6-1.6l20.3.1 4.1 10.2c.3.9 1.2 1.4 2.1 1.4h23.3s.1 0 .1.1z" />
  </svg>
);
export default IconFolder;
