import * as React from "react";
const IconCakeSlice = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M49.2 8.6c-1.6-1.3-3.9-1.5-5.7-.4l-38 22.3c-1.6.9-2.5 2.6-2.5 4.3v17.4c0 2.8 2.2 5 5 5h48c2.8 0 5-2.2 5-5V32.5C61 20 53.6 12.2 49.2 8.6M5 34.8c0-.5.1-.9.3-1.3H59v9.4H5zM44.6 9.9c.5-.3 1-.4 1.5-.4.7 0 1.3.2 1.9.7 4 3.2 10.6 10.2 11 21.3H7.7zM56 55.2H8c-1.7 0-3-1.3-3-3v-7.3h54v7.3c0 1.7-1.3 3-3 3" />
  </svg>
);
export default IconCakeSlice;
