import * as React from "react";
const IconSafebox1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 4.2H6c-2.2 0-4 1.8-4 4v37.5c0 2.2 1.8 4 4 4h2.2v9c0 .6.4 1 1 1s1-.4 1-1v-9h43.5v9c0 .6.4 1 1 1s1-.4 1-1v-9H58c2.2 0 4-1.8 4-4V8.2c0-2.2-1.8-4-4-4m2 41.5c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V8.2c0-1.1.9-2 2-2h52c1.1 0 2 .9 2 2z" />
    <path d="M14.6 18.9c-.6 0-1 .4-1 1v14.8c0 .6.4 1 1 1s1-.4 1-1V19.9c0-.6-.4-1-1-1M41.2 13.6c-7.4 0-13.4 6-13.4 13.4s6 13.4 13.4 13.4 13.4-6 13.4-13.4-6-13.4-13.4-13.4m0 24.8c-6.3 0-11.4-5.1-11.4-11.4s5.1-11.4 11.4-11.4S52.6 20.7 52.6 27s-5.1 11.4-11.4 11.4" />
    <path d="M41.2 21.8c-2.9 0-5.2 2.3-5.2 5.2s2.3 5.2 5.2 5.2 5.2-2.3 5.2-5.2-2.3-5.2-5.2-5.2m0 8.4c-1.8 0-3.2-1.4-3.2-3.2s1.4-3.2 3.2-3.2 3.2 1.4 3.2 3.2-1.4 3.2-3.2 3.2" />
  </svg>
);
export default IconSafebox1;
