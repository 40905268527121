import * as React from "react";
const IconHematologyLaboratory = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M39 8H9a1 1 0 0 0-1 1v30a1 1 0 0 0 1 1h30a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1M9 6a3 3 0 0 0-3 3v30a3 3 0 0 0 3 3h30a3 3 0 0 0 3-3V9a3 3 0 0 0-3-3z"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M20.8 16v.993-.005l-.005.01c-.01.032-.043.132-.146.314-.132.233-.322.499-.532.762a13 13 0 0 1-.812.92l-.012.013-.002.002-.291.293V31.5A5.5 5.5 0 0 0 29.793 33h-2.13A3.5 3.5 0 0 1 21 31.5V20.113l.018-.02c.179-.194.42-.467.665-.774.24-.302.5-.659.705-1.019.05-.09.105-.19.157-.3h3.843c.235.636.645 1.17.963 1.529.234.265.466.487.649.651V21h2v-1.784l-.396-.299h-.001l-.009-.007-.043-.035a6.096 6.096 0 0 1-.702-.672c-.426-.482-.649-.918-.649-1.203v-1z"
      clipRule="evenodd"
    />
    <path
      
      d="M32 28.09c0 1.608-1.343 2.91-3 2.91s-3-1.302-3-2.91c0-2.544 3-5.09 3-5.09s3 2.546 3 5.09M20 10h9v4h-9z"
    />
  </svg>
);
export default IconHematologyLaboratory;
