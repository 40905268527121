import * as React from "react";
const IconCopperIud = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      d="M23 6h-4.132l1-2H23a1 1 0 0 1 1 1 1 1 0 0 1 1-1h3.132l1 2H25v10.257l-2-1zM10 5a1 1 0 0 0 1 1h1.632l1-2H11a1 1 0 0 0-1 1M14.868 6l1-2h1.764l-1 2z"
    />
    <path
      
      fillRule="evenodd"
      d="M20 40a4 4 0 0 1 3-3.874V25.493l2 1v9.633A4.002 4.002 0 0 1 24 44a4 4 0 0 1-4-4m4-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"
      clipRule="evenodd"
    />
    <path
      
      d="m25 20.257-2-1v-1.764l2 1zM25 22.493l-2-1v1.764l2 1zM38 5a1 1 0 0 0-1-1h-2.632l1 2H37a1 1 0 0 0 1-1M31.368 6l-1-2h1.764l1 2z"
    />
  </svg>
);
export default IconCopperIud;
