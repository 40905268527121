import * as React from "react";
const IconGiftCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M52.3 15.8h-9.6l.2-.2c1.4-1.4 2.2-3.2 2.4-5 .1-1.9-.5-3.6-1.7-4.9-1.3-1.3-3-1.9-4.9-1.7-1.8.1-3.6 1-5 2.4-.8.8-1.3 1.9-1.6 3.1-.3-1.2-.8-2.3-1.6-3.1-2.9-2.9-7.3-3.2-9.9-.6s-2.3 7 .6 9.9l.2.2h-9.6c-2.2 0-4 1.8-4 4v9.2c0 .6.4 1 1 1h1.9V58c0 2.2 1.8 4 4 4h34.8c2.2 0 4-1.8 4-4V30.1h1.9c.6 0 1-.4 1-1v-9.2c-.1-2.3-1.9-4.1-4.1-4.1M35 7.8c1-1 2.4-1.7 3.7-1.8s2.5.3 3.3 1.2 1.3 2 1.2 3.3c-.1 1.4-.7 2.7-1.8 3.7-1.6 1.6-5.7 1.7-8 1.5 0-2.2.1-6.4 1.6-7.9m-13.1-.6c.8-.8 1.9-1.2 3-1.2 1.4 0 2.9.6 4.1 1.8 1.6 1.6 1.7 5.7 1.5 8-2.2.1-6.4 0-8-1.5-2.1-2.2-2.4-5.3-.6-7.1M51.4 58c0 1.1-.9 2-2 2H14.6c-1.1 0-2-.9-2-2V30.1h38.8zm2.9-29.9H9.7v-8.2c0-1.1.9-2 2-2h40.6c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconGiftCopy;
