import * as React from "react";
const IconQuoteAlt3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 36.9H41.5V26.1c0-10.8 8.8-19.5 19.5-19.5.6 0 1-.4 1-1s-.4-1-1-1c-11.9 0-21.5 9.7-21.5 21.5v29.2c0 2.2 1.8 4 4 4H58c2.2 0 4-1.8 4-4V40.9c0-2.2-1.8-4-4-4m2 18.5c0 1.1-.9 2-2 2H43.5c-1.1 0-2-.9-2-2V38.9H58c1.1 0 2 .9 2 2zM23.5 6.6c.6 0 1-.4 1-1s-.4-1-1-1C11.7 4.6 2 14.3 2 26.1v29.2c0 2.2 1.8 4 4 4h14.5c2.2 0 4-1.8 4-4V40.9c0-2.2-1.8-4-4-4H4V26.1C4 15.4 12.8 6.6 23.5 6.6m-1 34.3v14.5c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V38.9h16.5c1.1 0 2 .9 2 2" />
  </svg>
);
export default IconQuoteAlt3;
