import * as React from "react";
const IconLinkAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M52.3 17.4H32.8c-5.3 0-9.7 4.3-9.7 9.7v.5c0 5.3 4.3 9.7 9.7 9.7h1.6v-2h-1.6c-4.2 0-7.7-3.4-7.7-7.7V27c0-4.2 3.4-7.7 7.7-7.7h19.5c4.2 0 7.7 3.4 7.7 7.7v.5c0 4.2-3.4 7.7-7.7 7.7h-7.8v2h7.8c5.3 0 9.7-4.3 9.7-9.7V27c0-5.3-4.3-9.6-9.7-9.6" />
    <path d="M31.2 26.8h-1.7v2h1.7c4.2 0 7.7 3.4 7.7 7.7v.5c0 4.2-3.4 7.7-7.7 7.7H11.7C7.4 44.6 4 41.2 4 37v-.5c0-4.2 3.4-7.7 7.7-7.7h7.6v-2h-7.6c-5.3 0-9.7 4.3-9.7 9.7v.5c0 5.3 4.3 9.7 9.7 9.7h19.5c5.3 0 9.7-4.3 9.7-9.7v-.5c-.1-5.4-4.4-9.7-9.7-9.7" />
  </svg>
);
export default IconLinkAlt2;
