import * as React from "react";
const IconPieReport = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 28.6c-5.9 0-10.6 4.8-10.6 10.6 0 3.2 1.4 6 3.6 8v.1h.1c1.9 1.6 4.3 2.6 6.9 2.6 5.9 0 10.6-4.8 10.6-10.6S37.9 28.6 32 28.6m8.6 9.6H33v-7.6c4 .5 7.1 3.7 7.6 7.6m-17.2 1c0-4.4 3.3-8.1 7.6-8.6v8.2L25.6 45c-1.4-1.5-2.2-3.5-2.2-5.8m8.6 8.7c-1.8 0-3.5-.6-4.9-1.5l5.4-6.1h8.1c-.5 4.2-4.2 7.6-8.6 7.6" />
    <path d="M46 9.9h-2.9V7.5c0-1.6-1.3-2.9-2.8-2.9H33V3c0-.6-.4-1-1-1s-1 .4-1 1v1.6h-7.3c-1.6 0-2.8 1.3-2.8 2.9v2.4H18c-2.6 0-4.6 2.2-4.6 4.8v42.5c0 2.6 2.1 4.8 4.6 4.8h28c2.6 0 4.6-2.2 4.6-4.8V14.7c.1-2.6-2-4.8-4.6-4.8M22.9 7.5c0-.5.4-.9.8-.9h16.6c.4 0 .8.4.8.9v6.8c0 .5-.4.9-.8.9H23.7c-.4 0-.8-.4-.8-.9zm25.8 49.7c0 1.5-1.2 2.8-2.6 2.8H18c-1.5 0-2.6-1.3-2.6-2.8V14.7c0-1.5 1.2-2.8 2.6-2.8h2.9v2.4c0 1.6 1.3 2.9 2.8 2.9h16.6c1.6 0 2.8-1.3 2.8-2.9v-2.4H46c1.5 0 2.6 1.3 2.6 2.8v42.5z" />
  </svg>
);
export default IconPieReport;
