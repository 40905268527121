import React from "react";

/**
 * Displays a gallery of images with a consistent height and width.
 *
 * @param {object} props - The properties of the gallery.
 * @param {React.ReactNode} props.children - The BringzzImage components to display in the gallery.
 * @returns {JSX.Element} JSX markup for the gallery.
 */
export default function BringzzGallery({ children }) {
    return (
        <div className="grid grid-cols-3 gap-0.5">
            {React.Children.map(children, (child) =>
                React.cloneElement(child, {
                    className: "w-full h-32 object-cover " + child.props.className,
                })
            )}
        </div>
    );
}
