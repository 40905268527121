import * as React from "react";
const IconCreditCardLock = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.4 45h-3.1v-4.5c0-3.5-2.9-6.4-6.4-6.4s-6.4 2.9-6.4 6.4V45h-3.1c-.6 0-1 .4-1 1v11c0 .6.4 1 1 1h18.9c.6 0 1-.4 1-1V46c.1-.5-.4-1-.9-1m-13.8-4.5c0-2.4 2-4.4 4.4-4.4s4.4 2 4.4 4.4V45h-8.7v-4.5zM57.4 56H40.5v-9h16.9z" />
    <path d="M56 6H8c-3.3 0-6 2.7-6 6v29.1c0 3.3 2.7 6 6 6h17.6c.6 0 1-.4 1-1s-.4-1-1-1H8c-2.2 0-4-1.8-4-4V27.2h56V31c0 .6.4 1 1 1s1-.4 1-1V12c0-3.3-2.7-6-6-6M8 8h48c2.2 0 4 1.8 4 4v3.7H4V12c0-2.2 1.8-4 4-4M4 25.2v-7.6h56v7.6z" />
  </svg>
);
export default IconCreditCardLock;
