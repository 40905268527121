import * as React from "react";
const IconNetwork1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2C20.9 2 9 5.1 9 10.8v27.5c0 5.4 11 8.7 22 8.9v5.2c-1.9.4-3.3 1.9-3.7 3.7H17.1c-.6 0-1 .4-1 1s.4 1 1 1h10.2c.5 2.2 2.4 3.8 4.7 3.8s4.2-1.6 4.7-3.8h10.2c.6 0 1-.4 1-1s-.4-1-1-1H36.7c-.4-1.9-1.9-3.3-3.7-3.7v-5.2c11-.2 22-3.5 22-8.9V10.8C55 5.1 43.1 2 32 2m0 2c12.3 0 21 3.6 21 6.8s-8.6 6.8-21 6.8-21-3.6-21-6.8S19.7 4 32 4m2.8 53.2c0 1.6-1.3 2.8-2.8 2.8s-2.8-1.3-2.8-2.8 1.3-2.8 2.8-2.8 2.8 1.2 2.8 2.8m-2.8-12c-12 0-20.9-3.6-20.9-6.9v-5.1c3.8 3.3 12.6 5.1 21 5.1s17.1-1.8 21-5.1v5.1C53 41.6 44 45.2 32 45.2m0-8.8c-12.3 0-21-3.6-21-6.8v-5c3.8 3.3 12.6 5.1 21 5.1s17.1-1.8 21-5.1v5c0 3.2-8.7 6.8-21 6.8m0-8.9c-12.3 0-21-3.6-21-6.8v-6.1c3.8 3.3 12.6 5.1 21 5.1s17.1-1.8 21-5.1v6.1c0 3.2-8.7 6.8-21 6.8" />
  </svg>
);
export default IconNetwork1;
