import * as React from "react";
const IconGb = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 513 342"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <g >
      <path d="M0 0h513v341.3H0z" />
      <path d="M311.7 230 513 341.3v-31.5L369.3 230zM200.3 111.3 0 0v31.5l143.7 79.8z" />
    </g>
    <path
      
      d="M393.8 230 513 295.7V230zm-82.1 0L513 341.3v-31.5L369.3 230zm146.9 111.3-147-81.7v81.7zM90.3 230 0 280.2V230zm110 14.2v97.2H25.5zm-82.1-132.9L0 45.6v65.7zm82.1 0L0 0v31.5l143.7 79.8zM53.4 0l147 81.7V0zm368.3 111.3L513 61.1v50.2zm-110-14.2V0h174.9z"
    />
    <g >
      <path d="M288 0h-64v138.7H0v64h224v138.7h64V202.7h224v-64H288z" />
      <path d="M311.7 230 513 341.3v-31.5L369.3 230zm-168 0L0 309.9v31.5L200.3 230zm56.6-118.7L0 0v31.5l143.7 79.8zm168 0L513 31.5V0L311.7 111.3z" />
    </g>
  </svg>
);
export default IconGb;
