import * as React from "react";
const IconFolderNetworking1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.5 20.7c-1-1.3-2.4-2.1-4-2.2v-4.3c0-2.9-2.3-5.2-5.2-5.2H27.5l-2-5.1c-.5-1.2-1.6-2-2.9-2h-10C9.8 2 7.5 4.3 7.5 7.2v36.2c0 1.8 1.4 3.3 3.1 3.3h17.1v4.8c-2.1.4-3.8 2.1-4.2 4.3H11.7c-.6 0-1 .4-1 1s.4 1 1 1h11.7c.5 2.5 2.6 4.3 5.2 4.3s4.8-1.9 5.2-4.3h11.7c.6 0 1-.4 1-1s-.4-1-1-1H33.9c-.4-2.1-2.1-3.8-4.2-4.3v-4.8h15.7c2.2 0 4.2-1.4 4.9-3.5l6-17.7c.5-1.7.2-3.4-.8-4.8M32 56.6q0 .15 0 0c0 1.9-1.5 3.4-3.3 3.4s-3.3-1.5-3.3-3.3 1.5-3.3 3.3-3.3 3.3 1.4 3.3 3.2M10.2 44.5c-.4-.2-.7-.6-.7-1.2V7.2c0-1.7 1.4-3.2 3.2-3.2h10c.4 0 .8.3 1 .7l2.3 5.7q.3.6.9.6h19.5c1.8 0 3.2 1.4 3.2 3.2v4.3H24.1c-2.1 0-4 1.3-4.8 3.2zm44.1-19.7-6 17.7c-.4 1.3-1.7 2.2-3 2.2h-33l8.9-22.1c.5-1.2 1.6-2 3-2h27.2c1 0 2 .5 2.6 1.3.5.8.7 1.9.3 2.9" />
  </svg>
);
export default IconFolderNetworking1;
