import { monthsListLong } from "../utilities";
import BringzzIcon from "../../BringzzIcon";
import React from "react";
import BringzzText from "../../BringzzText";

const MonthPicker = ({ onChange, closePicker, description }) => {
  const handleMonthSelect = (selectedMonth) => {
    // Create a date with a fixed time to avoid time zone issues
    const newDate = new Date(
      new Date().getFullYear(),
      selectedMonth,
      1,
      12,
      0,
      0
    );
    onChange(newDate);
  };

  return (
    <div>
      <div className="mb-4 px-2 flex justify-between">
      <BringzzText tag="h4"><span className="mr-1">&bull;</span>{description || "Select a date"}</BringzzText>

        <div>
          {" "}
          <BringzzIcon
            icon="IconClose"
            folder="LineIcons"
            size="18"
            className="hover:text-magic-lilac cursor-pointer"
            onClick={() => {
              closePicker();
            }}
          ></BringzzIcon>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-4">
        {monthsListLong.map((monthName, index) => (
          <button
            key={monthName}
            className="p-2 rounded bg-gray-200 hover:bg-magic-lilac hover:text-white"
            onClick={() => handleMonthSelect(index)}
          >
            {monthName}
          </button>
        ))}
      </div>
    </div>
  );
};
export default MonthPicker;
