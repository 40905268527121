import { BringzzButton, BringzzIcon, BringzzText } from '@bringzz/components';
import React from 'react';

// ControlPanel Component (handles buttons like switching camera and navigation options)
const CameraControlPanel = ({ mode, selectedOption, setSelectedOption, onSwitchCamera, navigate }) => {
    return (
        <div className="absolute flex flex-col bottom-0 py-6 w-full bg-black/80">
            {/* Option Switcher */}
            <div className="flex justify-evenly mb-6">
                {['QR-Code', 'Prescription', 'Product', 'Barcode'].map(option => (
                    <BringzzText
                        key={option}
                        tag="h4"
                        className={`text-white cursor-pointer ${selectedOption === option ? 'underline' : ''}`}
                        onClick={() => setSelectedOption(option)}
                    >
                        {option}
                    </BringzzText>
                ))}
            </div>
            {/* Camera Control */}
            <div className="flex items-center justify-around">
                <BringzzIcon folder="LineIcons" icon="IconGallery" size="28" className="text-white cursor-pointer" />
                <div className="border rounded-full p-1 cursor-pointer">
                    <div className="h-14 w-14 bg-white rounded-full" />
                </div>
                <BringzzIcon
                    folder="LineIcons"
                    icon="IconSwitch"
                    size="24"
                    className="text-white cursor-pointer"
                    onClick={onSwitchCamera}
                />
            </div>

            {/* Continue Button for "collect" mode */}
            {mode === 'collect' && (
                <div className="absolute bottom-0 left-1/2 w-full px-6 transform -translate-x-1/2 mb-6">
                    <BringzzButton size="lg" className="bg-magic-lilac flex w-full justify-center" onClick={() => navigate('/camera/collect/confirm')}>
                        Continue
                    </BringzzButton>
                </div>
            )}
        </div>
    );
};

export default CameraControlPanel