import * as React from "react";
const IconMedal3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M37.8 28.5c.6 0 1-.4 1-1v-5.7h3.8c2.7 0 4.9-2.2 4.9-4.9v-10c0-2.7-2.2-4.9-4.9-4.9H21.4c-2.7 0-4.9 2.2-4.9 4.9V17c0 2.7 2.2 4.9 4.9 4.9h3.8v5.7c0 .6.4 1 1 1H31v5.9l-3.1-.5c-.3 0-.6.1-.8.3l-3 2.9-3.7 1.8c-.3.1-.5.4-.5.7l-.7 4.1-1.8 3.7q-.15.45 0 .9l1.8 3.7.7 4.1c.1.3.2.6.5.7l3.7 1.9 3 2.9c.2.2.5.3.8.3l4.1-.6 4.1.6h.1c.3 0 .5-.1.7-.3l3-2.9 3.7-1.9c.3-.1.5-.4.5-.7l.7-4.1 1.8-3.7q.15-.45 0-.9l-1.8-3.7-.7-4.1c-.1-.3-.2-.6-.5-.7l-3.7-1.9-3-2.9c-.2-.2-.5-.3-.8-.3l-3.1.5v-5.9zm.8 10.1.2.2 3.4 1.8.6 3.7c0 .1 0 .2.1.3l1.7 3.4-1.7 3.4c0 .1-.1.2-.1.3l-.6 3.7-3.4 1.8c-.1 0-.2.1-.2.2L35.9 60l-3.7-.5H32l-3.7.5-2.7-2.6-.2-.2-3.4-1.8-.6-3.7c0-.1 0-.2-.1-.3L19.4 48l1.7-3.4c0-.1.1-.2.1-.3l.6-3.7 3.4-1.8c.1 0 .2-.1.2-.2l2.7-2.6 3.7.5h.3l3.7-.5zM28 19.8V4h8v15.8zM45.5 6.9V17c0 1.6-1.3 2.9-2.9 2.9H38V4h4.6c1.6 0 2.9 1.3 2.9 2.9M18.5 17V6.9c0-1.6 1.3-2.9 2.9-2.9H26v15.8h-4.6c-1.6 0-2.9-1.3-2.9-2.8m8.7 4.8h9.6v4.7h-9.6z" />
    <path d="M26 48c0 3.3 2.7 6 6 6s6-2.7 6-6-2.7-6-6-6c-3.3-.1-6 2.6-6 6m10 0c0 2.2-1.8 4-4 4s-4-1.8-4-4 1.8-4 4-4 4 1.7 4 4" />
  </svg>
);
export default IconMedal3;
