import * as React from "react";
const IconSoup = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.1 29.1c-.7-.7-1.6-1.1-2.6-1.1h-53c-1 0-1.9.4-2.6 1.1-.6.7-1 1.7-.9 2.6.9 9.4 6.3 17.8 14.5 22.5v1c0 2.6 2.1 4.7 4.7 4.7h21.6c2.6 0 4.7-2.1 4.7-4.7v-1c2.2-1.3 4.3-2.8 6.1-4.7 4.8-4.8 7.8-11.1 8.4-17.8.1-.9-.2-1.9-.9-2.6m-8.9 19c-1.8 1.8-3.9 3.4-6.2 4.6-.3.2-.5.5-.5.9v1.5c0 1.5-1.2 2.7-2.7 2.7H21.2c-1.5 0-2.7-1.2-2.7-2.7v-1.5c0-.4-.2-.7-.5-.9-5.2-2.9-9.3-7.3-11.7-12.5h51.3c-1.3 2.9-3.1 5.6-5.4 7.9m6.3-9.9h-53c-.8-2.1-1.3-4.4-1.5-6.7 0-.4.1-.8.4-1.1s.7-.4 1.1-.4h53.1c.4 0 .8.2 1.1.5s.4.7.4 1.1c-.3 2.2-.8 4.5-1.6 6.6M26.8 21.3c7.5 0 13.5-3.7 13.8-3.9L56.6 6c.5-.3.6-.9.2-1.4-.3-.5-.9-.6-1.4-.2L43.3 13c-.2-.1-.4-.2-.6-.2l-29.2-.1c-.3 0-.5.1-.7.3s-.3.4-.3.7c.1 4.8 5.1 7.6 14.3 7.6m14-6.6-1.4 1c-.1 0-5.8 3.6-12.7 3.6-4.2 0-11.1-.6-12.1-4.7z" />
  </svg>
);
export default IconSoup;
