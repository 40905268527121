import * as React from "react";
const IconServer = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62 25.3V12.1c0-2.8-2.2-5-5-5H7c-2.8 0-5 2.2-5 5v13.2c0 1.6.8 3.1 2 4-1.2.9-2 2.4-2 4v13.2c0 2.8 2.2 5 5 5h35.3c.6 0 1-.4 1-1s-.4-1-1-1H7c-1.7 0-3-1.3-3-3V33.3c0-1.7 1.3-3 3-3h50c1.7 0 3 1.3 3 3v9.6c0 .6.4 1 1 1s1-.4 1-1v-9.6c0-1.6-.8-3.1-2-4 1.2-.9 2-2.4 2-4m-2 0c0 1.7-1.3 3-3 3H7c-1.7 0-3-1.3-3-3V12.1c0-1.7 1.3-3 3-3h50c1.7 0 3 1.3 3 3z" />
    <path d="M15.6 17.5c-.6 0-1 .4-1 1v.2c0 .6.4 1 1 1s1-.4 1-1v-.2c0-.6-.5-1-1-1M50.8 17.6H44c-.6 0-1 .4-1 1s.4 1 1 1h6.7c.6 0 1-.4 1-1s-.4-1-.9-1M14.6 39.7v.2c0 .6.4 1 1 1s1-.4 1-1v-.2c0-.6-.4-1-1-1s-1 .4-1 1M51.8 39.7c0-.6-.4-1-1-1H44c-.6 0-1 .4-1 1s.4 1 1 1h6.7c.6 0 1.1-.5 1.1-1M53.2 51.2c0-.6-.4-1-1-1h-2.6c.8-1.2 2.3-2 3.9-2s3 .7 3.8 1.9c.3.5.9.6 1.4.3s.6-.9.3-1.4c-1.2-1.8-3.3-2.8-5.5-2.8-3 0-5.6 2-6.4 4.8-.1.3 0 .6.2.9s.5.4.8.4h4.1c.5-.1 1-.6 1-1.1M58.9 53.2h-3.7c-.6 0-1 .4-1 1s.4 1 1 1h2.2c-.8 1.4-2.3 2.3-4 2.3-1.3 0-2.5-.5-3.4-1.5-.4-.4-1-.4-1.4-.1-.4.4-.4 1-.1 1.4 1.3 1.4 3 2.2 4.9 2.2 3 0 5.7-2.1 6.5-5 .1-.3 0-.6-.2-.9s-.5-.4-.8-.4" />
  </svg>
);
export default IconServer;
