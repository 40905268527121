import * as React from "react";
const IconNatureShield = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m50.8 8.3-16.9-6c-1.2-.4-2.5-.4-3.7 0l-16.9 6c-2.2.8-3.7 2.9-3.7 5.3v11.9c0 19.6 2.6 26.7 19.8 35.9.8.4 1.8.7 2.7.7s1.8-.2 2.7-.7C52 52.2 54.6 45.1 54.6 25.5V13.6c-.1-2.4-1.6-4.5-3.8-5.3m1.7 17.2c0 18.9-2.3 25.3-18.8 34.1-1.1.6-2.4.6-3.4 0-16.4-8.8-18.8-15.2-18.8-34.1V13.6c0-1.5 1-2.9 2.4-3.4l16.9-6c.4-.1.8-.2 1.2-.2s.8.1 1.2.2l16.9 6c1.4.5 2.4 1.9 2.4 3.4z" />
    <path d="M37.5 16c-.2-.1-.4-.2-.7-.2-4.7.2-9.2 4.3-10.9 9.8-1.7 5.3-.5 10.8 2.7 13.8l-2.5 7.9c-.2.5.1 1.1.7 1.3h.3c.4 0 .8-.3 1-.7l2.5-7.9c4.4-.6 8.5-4.5 10.2-9.8 1.7-5.5.4-11.4-3.3-14.2m1.4 13.7c-1.5 4.6-5 8-8.7 8.4-2.8-2.5-3.8-7.3-2.3-11.9s5-8 8.7-8.4c2.8 2.4 3.7 7.2 2.3 11.9" />
  </svg>
);
export default IconNatureShield;
