import * as React from "react";
const IconPpeApron = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="m20.05 5.963.292.658a4.001 4.001 0 0 0 7.316 0l.292-.658.716.068A7 7 0 0 1 35 13v12a3 3 0 0 1-3 3h-1v11.72l-3.521 1.174a11 11 0 0 1-6.957 0L17 39.721V28h-1a3 3 0 0 1-3-3V13a7 7 0 0 1 6.334-6.969zM33 25a1 1 0 0 1-1 1h-1V15h-2v7H19v-7h-2v11h-1a1 1 0 0 1-1-1V13a5 5 0 0 1 3.878-4.874A6 6 0 0 0 24 11a6 6 0 0 0 5.122-2.874A5 5 0 0 1 33 13zm-6.154 13.997L29 38.28V24H19v14.28l2.154.718a9 9 0 0 0 5.692 0"
      clipRule="evenodd"
    />
  </svg>
);
export default IconPpeApron;
