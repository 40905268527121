import * as React from "react";
const IconFlaskAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M42 28.1c-.3-.2-.5-.5-.5-.9V4h2.7c.6 0 1-.4 1-1s-.4-1-1-1H19.7c-.6 0-1 .4-1 1s.4 1 1 1h2.7v23.2c0 .4-.2.7-.5.9-6 3.9-9.2 10.7-8.3 17.9 1.1 8.3 7.9 14.9 16.3 15.9.6.1 1.4.1 2.1.1 4.6 0 9.1-1.7 12.5-4.7 3.9-3.5 6.1-8.5 6.1-13.7 0-6.3-3.2-12.1-8.6-15.5m1.1 27.7c-3.6 3.2-8.2 4.7-13.1 4.1-7.5-.8-13.6-6.8-14.5-14.1-.8-6.4 2.1-12.5 7.5-16 .9-.6 1.4-1.5 1.4-2.6V4h15v23.2c0 1.1.5 2 1.4 2.6 4.8 3 7.7 8.2 7.7 13.8.1 4.6-1.9 9.1-5.4 12.2" />
  </svg>
);
export default IconFlaskAlt2;
