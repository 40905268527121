import * as React from "react";
const IconMirror = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.7 60.3 42.9 41.5c3.3-3.7 4.7-8.8 4-14.4-.7-5.9-3.7-11.8-8.4-16.5C28.7.8 14.5-.9 6.9 6.8c-7.7 7.7-6 21.9 3.8 31.6 4.7 4.7 10.6 7.7 16.5 8.4 1 .1 1.9.2 2.8.2 4.5 0 8.5-1.4 11.6-4.2l18.8 18.8c.2.2.5.3.7.3s.5-.1.7-.3c.3-.3.3-.9-.1-1.3M27.3 44.9c-5.4-.7-10.9-3.5-15.3-7.9C3 28 1.3 15.1 8.2 8.2 11.1 5.4 15 4 19.2 4c6 0 12.6 2.8 17.8 8 4.4 4.4 7.2 9.8 7.9 15.3.7 5.4-.8 10.2-4.1 13.5s-8.1 4.8-13.5 4.1" />
  </svg>
);
export default IconMirror;
