import * as React from "react";
const IconTreeMoney = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.4 24.4c-.1-.4-.4-.7-.9-.8-3.7-.5-7.9.8-11 3.3-3 2.4-4.6 5.7-4.3 8.6L32.9 40V21.3c2.3-.2 4.1-2.2 4.1-4.5 0-2.5-2.1-4.6-4.6-4.6h-3.1c-1.4 0-2.6-1.2-2.6-2.6S27.9 7 29.3 7H36c.6 0 1-.4 1-1s-.4-1-1-1h-3.1V3c0-.6-.4-1-1-1s-1 .4-1 1v2.1h-1.6c-2.5 0-4.6 2-4.6 4.6s2.1 4.6 4.6 4.6h3.1c1.4 0 2.6 1.2 2.6 2.6s-1.2 2.6-2.6 2.6h-6.7c-.6 0-1 .4-1 1s.4 1 1 1h5.2V40l-5.1-4.4c.3-2.9-1.3-6.2-4.3-8.6-3.1-2.5-7.4-3.8-11-3.3-.5-.1-.8.3-.9.7-.6 3.1 1 6.6 4.2 9.2 2.7 2.2 6.2 3.4 9.5 3.4.4 0 .7 0 1.1-.1l6.5 5.5v1.8h-4.5c-1.6 0-3.1.7-4.1 1.9S21 49 21.2 50.5l1.3 7.1c.5 2.5 2.7 4.4 5.2 4.4h9.1c2.6 0 4.8-1.8 5.2-4.4l1.3-7.1c.3-1.6-.1-3.1-1.1-4.3s-2.5-1.9-4.1-1.9h-5.3v-1.6l6.7-5.7c.4 0 .7.1 1.1.1 3.3 0 6.8-1.2 9.5-3.4 3.3-2.7 4.9-6.2 4.3-9.3M15.1 32c-2.4-1.9-3.7-4.3-3.6-6.5 3-.1 6.3.9 8.8 3 2.4 1.9 3.7 4.3 3.6 6.5-3 .1-6.3-1-8.8-3m25.6 15.4c.6.8.9 1.7.7 2.7l-1.3 7.1c-.3 1.6-1.7 2.7-3.2 2.7h-9.1c-1.6 0-3-1.1-3.2-2.7l-1.3-7.1c-.2-1 .1-2 .7-2.7s1.6-1.2 2.5-1.2h11.7c1 .1 1.9.5 2.5 1.2M48.9 32c-2.5 2-5.8 3.1-8.8 3-.1-2.2 1.2-4.6 3.6-6.5 2.5-2 5.8-3.1 8.8-3 .1 2.2-1.2 4.6-3.6 6.5" />
  </svg>
);
export default IconTreeMoney;
