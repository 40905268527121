import * as React from "react";
const IconNightCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M35.4 4.3c-1.7-.6-3.4-1-5.2-1.3h-.4c-1.1 0-2.2.7-2.6 1.8s-.1 2.2.7 3c7.2 6.6 9.8 16.5 6.9 25.6C31.5 43.7 22 50.8 11 51.2c-1.2 0-2.3.8-2.6 2s.2 2.4 1.2 3.1c2.5 1.5 5 2.7 7.5 3.4 2.8.9 5.8 1.3 8.8 1.3 13.3 0 25.2-8.8 28.7-21.4 4.2-14.8-4.2-30.4-19.2-35.3m17.3 34.8C49.2 51.2 38 59 25.9 59c-2.7 0-5.5-.4-8.2-1.2-2.5-.8-4.9-1.9-7-3.2-.6-.4-.4-1.4.4-1.4 11.5-.4 22-7.8 25.6-19.1 3.2-10.2 0-20.8-7.5-27.7-.5-.5-.1-1.4.5-1.4h.1c1.6.3 3.3.6 4.9 1.1 14 4.7 21.9 19.2 18 33" />
  </svg>
);
export default IconNightCopy;
