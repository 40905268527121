import * as React from "react";
const IconVolumeHighCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.2 19.5c-.4-.4-1-.4-1.4-.1-.4.4-.4 1-.1 1.4 5.7 6.2 5.7 16.2 0 22.4-.4.4-.3 1 .1 1.4.2.2.4.3.7.3s.5-.1.7-.3c6.4-7 6.4-18.2 0-25.1" />
    <path d="M50.2 23.5c-.4.4-.4 1-.1 1.4 3.6 3.9 3.6 10.2 0 14.1-.4.4-.3 1 .1 1.4.2.2.4.3.7.3s.5-.1.7-.3c4.3-4.6 4.3-12.2 0-16.8-.4-.4-1-.4-1.4-.1M44.5 27.7c-.4.4-.4 1-.1 1.4 1.5 1.6 1.5 4.2 0 5.9-.4.4-.3 1 .1 1.4.2.2.4.3.7.3s.5-.1.7-.3c2.2-2.4 2.2-6.2 0-8.6-.3-.5-1-.5-1.4-.1M33.8 13.4c-1.2-.7-2.6-.6-3.7.1l-10.8 6.7c-.3.2-.6.2-.9.2H5.6C3.6 20.4 2 22 2 24v15.8c0 2 1.6 3.6 3.6 3.6h12.8c.3 0 .6.1.9.2l10.8 6.7c.6.4 1.3.6 1.9.6s1.2-.2 1.8-.5c1.2-.7 1.9-1.8 1.9-3.2V16.5c-.1-1.3-.8-2.5-1.9-3.1m-.2 34.1c0 .6-.3 1.1-.8 1.4s-1.1.3-1.7 0l-10.8-6.7c-.6-.4-1.2-.6-1.9-.6H5.6c-.9 0-1.6-.7-1.6-1.6V24.1c0-.9.7-1.6 1.6-1.6h12.8c.7 0 1.3-.2 1.9-.6l10.8-6.7c.5-.3 1.1-.3 1.7 0 .5.3.8.8.8 1.4z" />
  </svg>
);
export default IconVolumeHighCopy;
