import * as React from "react";
const IconScale = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 2H6C3.8 2 2 3.8 2 6v52c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V6c0-2.2-1.8-4-4-4m2 56c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2h19c-4.9 2.5-8.2 7.6-8.2 13.5 0 8.4 6.8 15.2 15.2 15.2s15.2-6.8 15.2-15.2c0-5.9-3.3-11-8.2-13.5h19c1.1 0 2 .9 2 2zM32 4.3c7.3 0 13.2 5.9 13.2 13.2S39.3 30.7 32 30.7s-13.2-5.9-13.2-13.2S24.7 4.3 32 4.3" />
    <path d="M32 20.1c.6 0 1-.4 1-1v-6.4c0-.6-.4-1-1-1s-1 .4-1 1v6.4c0 .6.4 1 1 1" />
  </svg>
);
export default IconScale;
