import * as React from "react";
const IconVectorAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.6 50.2c0-2-.4-9-5.3-14.1-3.4-3.6-8.3-5.4-14.4-5.5v-2.4c0-1.1-.9-2-2-2h-7.8c-1.1 0-2 .9-2 2V31c-5.3-.2-9.5-1.8-12.5-4.9-4-4.2-4.6-10-4.7-12.2h.4c2.5 0 4.5-2 4.5-4.5V6.5c0-2.5-2-4.5-4.5-4.5H6.5C4 2 2 4 2 6.5v2.8c0 2.5 2 4.5 4.5 4.5h.4c.1 2.5.7 8.8 5.2 13.6 3.3 3.5 8 5.3 13.9 5.5v3c0 1.1.9 2 2 2h7.8c1.1 0 2-.9 2-2v-3.4c5.6.1 9.9 1.7 12.9 4.9 4.3 4.6 4.7 11 4.7 12.7h-.9c-2.5 0-4.5 2-4.5 4.5v2.8c0 2.5 2 4.5 4.5 4.5h2.8c2.5 0 4.5-2 4.5-4.5v-2.8c.2-2.4-1.8-4.4-4.2-4.4M4 9.3V6.5C4 5.1 5.1 4 6.5 4h2.8c1.4 0 2.5 1.1 2.5 2.5v2.8c0 1.4-1.1 2.5-2.5 2.5H6.5C5.1 11.8 4 10.7 4 9.3m24.1 26.6v-7.8h7.8v7.8zM60 57.5c0 1.4-1.1 2.5-2.5 2.5h-2.8c-1.4 0-2.5-1.1-2.5-2.5v-2.8c0-1.4 1.1-2.5 2.5-2.5h2.8c1.4 0 2.5 1.1 2.5 2.5z" />
  </svg>
);
export default IconVectorAlt;
