import * as React from "react";
const IconLiveStreamAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 6.6H6c-2.2 0-4 1.8-4 4v30.3c0 2.2 1.8 4 4 4h25v10.6h-5.1c-.6 0-1 .4-1 1s.4 1 1 1h12.2c.6 0 1-.4 1-1s-.4-1-1-1H33V44.9h25c2.2 0 4-1.8 4-4V10.6c0-2.2-1.8-4-4-4m2 34.3c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V10.6c0-1.1.9-2 2-2h52c1.1 0 2 .9 2 2z" />
    <path d="M24.5 18.2c-.6 0-1 .4-1 1v14.4c0 .6.4 1 1 1s1-.4 1-1V19.2c0-.6-.4-1-1-1M39.8 18.2c-.5 0-1 .4-1.1.9l-1.1 13.6h-3.5L33 19.1c0-.6-.5-1-1.1-.9-.6 0-1 .5-.9 1.1l1.2 14.5c0 .5.5.9 1 .9h5.3c.5 0 1-.4 1-.9l1.2-14.5c0-.6-.4-1.1-.9-1.1M17.7 32.6l-5.8.1V19.2c0-.6-.4-1-1-1s-1 .4-1 1v13.5c0 1.1.9 1.9 1.9 1.9h5.8c.6 0 1-.4 1-1s-.4-1-.9-1M53.1 20.2c.6 0 1-.4 1-1s-.4-1-1-1h-5.3c-.6 0-1 .4-1 1v14.5c0 .6.4 1 1 1h5.3c.6 0 1-.4 1-1s-.4-1-1-1h-4.3v-5.2h4.3c.6 0 1-.4 1-1s-.4-1-1-1h-4.3v-5.2h4.3z" />
  </svg>
);
export default IconLiveStreamAlt;
