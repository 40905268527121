import * as React from "react";
const IconTrashCanAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M49.4 9.3h-9.2V7.2C40.2 4.3 37.9 2 35 2h-6c-2.9 0-5.2 2.3-5.2 5.2v2.1h-9.2c-2.3 0-4.2 1.9-4.2 4.2v2.9c0 2 1.4 3.6 3.2 4.1v27.9c0 7.5 6.1 13.6 13.6 13.6h9.6c7.5 0 13.6-6.1 13.6-13.6V20.5c1.8-.5 3.2-2.1 3.2-4.1v-2.9c0-2.3-1.9-4.2-4.2-4.2M25.8 7.2C25.8 5.4 27.2 4 29 4h6c1.8 0 3.2 1.4 3.2 3.2v2.1H25.8zm11 52.8h-9.6c-6.4 0-11.6-5.2-11.6-11.6V20.6h32.9v27.8C48.4 54.8 43.2 60 36.8 60m14.8-43.6c0 1.2-1 2.2-2.2 2.2H14.6c-1.2 0-2.2-1-2.2-2.2v-2.9c0-1.2 1-2.2 2.2-2.2h34.8c1.2 0 2.2 1 2.2 2.2z" />
    <path d="M32 32.7c-.6 0-1 .4-1 1v13.2c0 .6.4 1 1 1s1-.4 1-1V33.7c0-.5-.4-1-1-1M40.4 34.7c-.6 0-1 .4-1 1V45c0 .6.4 1 1 1s1-.4 1-1v-9.3c0-.5-.4-1-1-1M23.6 34.7c-.6 0-1 .4-1 1V45c0 .6.4 1 1 1s1-.4 1-1v-9.3c0-.5-.5-1-1-1" />
  </svg>
);
export default IconTrashCanAlt;
