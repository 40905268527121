import React, { useState } from 'react';
import { BringzzButton, BringzzText, BringzzInput, BringzzIcon, BringzzPageContainer } from "@bringzz/components";
import { useNavigation } from 'context/NavigationContext';
import { useLocation } from 'react-router-dom';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const ResetPasswordPage = () => {
	const { backPress, navigate } = useNavigation();
	const [show, setShow] = useState(false);
	const [password, setPassword] = useState('');
	const [repeatPassword, setRepeatPassword] = useState('');
	const [passwordValid, setPasswordValid] = useState(false);
	const [repeatPasswordValid, setRepeatPasswordValid] = useState(false);

	const isButtonEnabled = password === repeatPassword && passwordValid && repeatPasswordValid;

	const handlePasswordChange = (e, isValid, newValue) => {
		setPassword(newValue);
		setPasswordValid(isValid);
	};

	const handleRepeatPasswordChange = (e, isValid, newValue) => {
		setRepeatPassword(newValue);
		setRepeatPasswordValid(isValid && newValue === password);
	};

	return (
		<BringzzPageContainer
			className='bg-white'
			header={{
				left: (
					<BringzzIcon
						size='22'
						icon='IconClose'
						folder='LineIcons'
						className='cursor-pointer'
						onClick={backPress}
					/>
				),
				title: <BringzzText tag='h3'>{"Reset Password"}</BringzzText>
			}}
			authenticated={false}
			footer={
				<div className='pb-8 px-6'>
					<BringzzButton
						size='lg'
						className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
						onClick={() => navigate('/reset-password/success', false)}
						disabled={!isButtonEnabled}
					>
						Update Password
					</BringzzButton>
				</div>
			}
		>
			<div className='relative flex justify-center bg-magic-lilac/20'>
				<div className='relative bg-white h-full w-full bg-cover bg-center flex flex-col md:max-w-2xl '>
					<div className='flex-grow flex flex-col justify-between py-8 px-4'>
						<div className='z-10 flex flex-col '>
							<div className='flex flex-col space-y-6 mx-auto md:max-w-lg lg:max-w-xl xl:max-w-2xl'>
								<BringzzText tag='h1' className='text-midnight-blue'>
									Update Password
								</BringzzText>
								<BringzzText
									tag='h4'
									className='text-midnight-blue font-normal'
								>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum consequat nisl eget ultricies mollis. Praesent eu sodales libero, sit amet commodo ante.
								</BringzzText>
							</div>
							<div className='mt-6'>
								<BringzzInput
									type={show ? 'text' : 'password'}
									hint='At least 9 letters, containing a character and a number'
									label={'Password'}
									pattern='^(?=.*[A-Za-z])(?=.*\d)(?=.*[!?@#$%^&*()-_+=])[A-Za-z\d!?@#$%^&*()-_+=]{9,}$'
									icon={{
										right: (
											<BringzzIcon
												icon={show ? 'IconEye' : 'IconEyeSlash'}
												folder='LineIcons'
												className='cursor-pointer'
												size='16'
												onClick={() => setShow(!show)}
											/>
										)
									}}
									onChange={handlePasswordChange}
								></BringzzInput>
							</div>
							<div className='mt-6'>
								<BringzzInput
									type={show ? 'text' : 'password'}
									label={'Repeat Password'}
									pattern='^(?=.*[A-Za-z])(?=.*\d)(?=.*[!?@#$%^&*()-_+=])[A-Za-z\d!?@#$%^&*()-_+=]{9,}$'
									icon={{
										right: (
											<BringzzIcon
												icon={show ? 'IconEye' : 'IconEyeSlash'}
												folder='LineIcons'
												className='cursor-pointer'
												size='16'
												onClick={() => setShow(!show)}
											/>
										)
									}}
									onChange={handleRepeatPasswordChange}
								></BringzzInput>
							</div>
						</div>
					</div>
				</div>
			</div>
		</BringzzPageContainer>
	);
};

export default ResetPasswordPage;
