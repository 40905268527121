import * as React from "react";
const IconChair4 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M43.8 34.9h-1.5l2.4-19.6c.2-3.5-1.1-6.8-3.5-9.4C38.8 3.4 35.5 2 32 2s-6.8 1.4-9.2 3.9-3.7 5.9-3.5 9.5l2.4 19.5h-1.5c-2.7 0-4.9 2.2-4.9 4.9v4.3c0 1.4.6 2.7 1.6 3.6V61c0 .6.4 1 1 1s1-.4 1-1V48.8c.4.1.8.2 1.3.2h4.9v9.2c0 .6.4 1 1 1s1-.4 1-1V49h9.8v9.2c0 .6.4 1 1 1s1-.4 1-1V49h4.9c.4 0 .9-.1 1.3-.2V61c0 .6.4 1 1 1s1-.4 1-1V47.7c1-.9 1.6-2.2 1.6-3.6v-4.3c0-2.7-2.2-4.9-4.9-4.9M21.3 15.2c-.1-3 .9-5.8 3-7.9C26.3 5.2 29 4 32 4s5.7 1.2 7.8 3.3c2 2.1 3.1 4.9 3 7.8l-2.4 19.8H23.7zM20.2 47c-.6 0-1.1-.2-1.6-.5 0 0-.1-.1-.2-.1-.7-.5-1.2-1.4-1.2-2.3v-4.3c0-1.6 1.3-2.9 2.9-2.9h23.6c1.6 0 2.9 1.3 2.9 2.9v4.3c0 .9-.5 1.8-1.2 2.3-.1 0-.1.1-.2.1-.5.3-1 .5-1.6.5z" />
  </svg>
);
export default IconChair4;
