import * as React from "react";
const IconGo = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 65 64"
    {...props}
  >
    <path
      
      d="M5.69 27.285c-.125 0-.156-.063-.094-.156l.654-.842c.063-.093.219-.155.343-.155h11.125c.124 0 .155.093.093.186l-.53.81c-.062.094-.218.188-.311.188zM.985 30.151c-.125 0-.156-.062-.094-.155l.654-.842c.063-.093.219-.156.343-.156h14.21c.124 0 .186.094.155.187l-.25.748c-.03.125-.155.187-.28.187zM8.526 33.018c-.125 0-.156-.093-.094-.187l.436-.779c.063-.093.187-.187.312-.187h6.232c.125 0 .187.094.187.218l-.062.748c0 .125-.125.218-.219.218zM40.87 26.724c-1.963.498-3.303.872-5.235 1.37-.467.125-.498.156-.904-.31-.467-.53-.81-.873-1.464-1.185-1.963-.966-3.864-.686-5.64.467-2.119 1.371-3.21 3.397-3.178 5.92.03 2.494 1.744 4.55 4.206 4.893 2.12.28 3.895-.467 5.297-2.056.281-.343.53-.717.842-1.153H28.78c-.655 0-.81-.406-.592-.935.405-.966 1.153-2.587 1.589-3.397.093-.187.312-.498.779-.498h11.342c-.062.841-.062 1.682-.187 2.524-.342 2.243-1.184 4.3-2.555 6.107-2.243 2.96-5.172 4.799-8.88 5.297-3.054.405-5.89-.187-8.383-2.056-2.306-1.745-3.614-4.051-3.957-6.918-.405-3.396.592-6.45 2.649-9.13 2.212-2.898 5.141-4.736 8.725-5.39 2.929-.53 5.733-.188 8.257 1.526 1.652 1.09 2.836 2.587 3.615 4.394.187.28.062.436-.312.53"
    />
    <path
      
      d="M51.184 43.955c-2.836-.062-5.422-.872-7.603-2.742-1.839-1.589-2.992-3.614-3.366-6.014-.56-3.52.406-6.637 2.524-9.41 2.275-2.992 5.017-4.55 8.726-5.204 3.178-.56 6.17-.25 8.88 1.59 2.462 1.682 3.989 3.956 4.394 6.948.53 4.207-.686 7.634-3.584 10.563-2.056 2.088-4.58 3.397-7.478 3.989-.841.156-1.683.187-2.493.28M58.6 31.367c-.03-.406-.03-.717-.093-1.029-.561-3.085-3.397-4.83-6.357-4.144-2.898.654-4.767 2.493-5.453 5.422-.561 2.43.623 4.892 2.867 5.89 1.714.747 3.427.654 5.079-.188 2.462-1.277 3.801-3.272 3.957-5.951"
    />
  </svg>
);
export default IconGo;
