import * as React from "react";
const IconVectorCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.5 23.1c.6 2.6 2.9 4.6 5.7 4.6 3.2 0 5.8-2.6 5.8-5.8s-2.6-5.8-5.8-5.8c-3 0-5.4 2.2-5.8 5.1H37.8c-.4-2.9-2.8-5.1-5.8-5.1s-5.4 2.2-5.8 5.1H13.6c-.4-2.9-2.8-5.1-5.8-5.1-3.2 0-5.8 2.6-5.8 5.8s2.6 5.8 5.8 5.8c2.8 0 5.2-2 5.7-4.6v.1h9.1C17 26.1 13 31.6 12.2 38H9.7c-1.3 0-2.4 1.1-2.4 2.4v5.1c0 1.3 1.1 2.4 2.4 2.4h7.7c1.3 0 2.4-1.1 2.4-2.4v-5.1c0-1.3-1.1-2.4-2.4-2.4h-3.2c1-6.7 5.8-12.3 12.3-14.2.8 2.3 3 4 5.5 4s4.7-1.6 5.5-3.8c6.2 2.1 10.8 7.6 11.7 14.1h-2.6c-1.3 0-2.4 1.1-2.4 2.4v5.1c0 1.3 1.1 2.4 2.4 2.4h7.7c1.3 0 2.4-1.1 2.4-2.4v-5.1c0-1.3-1.1-2.4-2.4-2.4h-3.1c-.9-6.4-4.8-11.9-10.4-14.8zm5.7-5c2.1 0 3.8 1.7 3.8 3.8s-1.7 3.8-3.8 3.8-3.8-1.7-3.8-3.8 1.7-3.8 3.8-3.8M7.8 25.8C5.7 25.8 4 24 4 21.9s1.7-3.8 3.8-3.8 3.8 1.7 3.8 3.8-1.7 3.9-3.8 3.9m10 14.6v5.1c0 .2-.2.4-.4.4H9.7c-.2 0-.4-.2-.4-.4v-5.1c0-.2.2-.4.4-.4h7.7c.2 0 .4.2.4.4M32 25.8c-2.1 0-3.8-1.7-3.8-3.8s1.7-3.8 3.8-3.8 3.8 1.7 3.8 3.8-1.7 3.8-3.8 3.8m22.7 14.6v5.1c0 .2-.2.4-.4.4h-7.7c-.2 0-.4-.2-.4-.4v-5.1c0-.2.2-.4.4-.4h7.7c.2 0 .4.2.4.4" />
  </svg>
);
export default IconVectorCopy;
