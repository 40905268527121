import React, { useState } from "react";
import "./BringzzPinCode.css";
import { twMerge } from 'tailwind-merge';

const DEFAULT_INPUT_CLASSNAMES =
  "bg-[#F4F4F4] rounded-md w-20 h-20 text-center text-midnight-blue text-lg focus:border-none outline-2 caret-magic-lilac font-bold focus:outline outline-magic-lilac";
const DEFAULT_INPUT_CONTAINER_CLASSNAMES = "flex justify-center";

function BringzzPinCode({
  length,
  className = "",
  inputClasses = DEFAULT_INPUT_CLASSNAMES,
  inputContainerClasses = DEFAULT_INPUT_CONTAINER_CLASSNAMES,
  upperChildContent,
  lowerChildContent,
  spacing = "space-x-4",
  defaultValue,
  disabled,
  onChange,
  onValidate,
  onSuccess,
  onError,
  ...props
}) {
  const [isSuccess, setIsSuccess] = useState(false)
  let sanitizedDefaultValue = "";

  if (defaultValue) {
    sanitizedDefaultValue = defaultValue.replace(/[^\d]/g, "").slice(0, length);

    if (
      sanitizedDefaultValue.length !== length &&
      sanitizedDefaultValue.length !== 0
    ) {
      throw new Error(
        `Default value length should match the specified 'length' prop.`
      );
    }
  }

  const triggerChangeCallback = () => {
    const fullPin = Array.from(
      { length },
      (_, i) => document.getElementById(`pinInput-${i}`)?.value || ""
    ).join("");
    if (onChange) {
      setIsSuccess(false)
      onChange(fullPin);
    }
  };

  const triggerValidation = async () => {
    const fullPin = Array.from(
      { length },
      (_, i) => document.getElementById(`pinInput-${i}`)?.value || ""
    ).join("");
    if (fullPin.length === length && onValidate) {
      const isValid = await onValidate(fullPin);
      if (isValid && onSuccess) {
        setIsSuccess(true)
        onSuccess();
      } else if (!isValid && onError) {
        onError();
      }
    }
  };

  const handleInput = async (index, event) => {
    const inputValue = event.target.value;
    const numericInput = inputValue.replace(/[^\d]/g, "");

    if (numericInput.length >= 2) {
      event.target.value = numericInput[1];
      const nextInput = document.getElementById(`pinInput-${index + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    }

    if (inputValue !== numericInput) {
      event.preventDefault();
      event.target.value = "";
    } else if (inputValue.length === 0 && index > 0) {
      const prevInput = document.getElementById(`pinInput-${index - 1}`);
      if (prevInput) {
        prevInput.focus();
      }
    } else if (inputValue.length === 1 && index < length - 1) {
      const nextInput = document.getElementById(`pinInput-${index + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    }
    triggerChangeCallback();
    triggerValidation();
  };

  const handleKeyDown = (index, event) => {
    const inputValue = event.currentTarget.value;
    if (event.key === "Backspace" && inputValue.length === 0 && index > 0) {
      const prevInput = document.getElementById(`pinInput-${index - 1}`);
      if (prevInput) {
        event.preventDefault();
        prevInput.value = "";
        prevInput.focus();
      }
    }
  };

  const handlePaste = (index, event) => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData("text");

    // Check if pasted data is numbers only and its length is within the limit.
    if (/^\d+$/.test(pastedData) && pastedData.length <= length) {
      // Clear existing values starting from the index of the current input to the end.
      for (let i = index; i < length; i++) {
        const inputElement = document.getElementById(`pinInput-${i}`);
        if (inputElement) {
          inputElement.value = ""; // Clear the input before pasting new value.
        }
      }

      // Paste new values starting from the current input.
      for (let i = 0; i < length; i++) {
        let char = "";
        if (i < pastedData.length) {
          char = pastedData[i];
        }

        const inputElement = document.getElementById(`pinInput-${i}`);
        if (inputElement) {
          inputElement.value = char;
        }
      }

      // focus next viable input
      if (pastedData.length > 0) {
        let inputElement = null;
        // Pasted full pincode; focus last input.
        if (pastedData.length === length) {
          inputElement = document.getElementById(`pinInput-${length - 1}`);
        }
        // Pasted part pincode; focus next input
        else {
          inputElement = document.getElementById(
            `pinInput-${pastedData.length}`
          );
        }
        inputElement?.focus();
      }

      // Trigger change event manually for the first input to handle the state update.
      const firstInput = document.getElementById(`pinInput-${index}`);
      if (firstInput && onChange) {
        const fullPin = Array.from(
          { length },
          (_, i) => document.getElementById(`pinInput-${i}`)?.value || ""
        ).join("");
        onChange(fullPin);
      }

      triggerChangeCallback();
      triggerValidation();
    }
  };

  const renderInputs = () => {
    const inputs = [];

    for (let i = 0; i < length; i++) {
      const value = sanitizedDefaultValue[i] || "";

      inputs.push(
        <input
          type="text"
          key={i}
          maxLength={2}
          id={`pinInput-${i}`}
          onChange={(e) => handleInput(i, e)}
          onKeyDown={(e) => handleKeyDown(i, e)}
          onPaste={(e) => handlePaste(i, e)}
          className={twMerge(inputClasses, isSuccess && "bg-[#BBEDB4]")}
          defaultValue={value}
          inputMode="numeric"
          readOnly={disabled}
        />
      );
    }

    return inputs;
  };

  return (
    <div {...props} className={twMerge(className, "bringzz-pincode")}>
      {upperChildContent && <div>{upperChildContent}</div>}

      <div className={twMerge("flex", spacing, inputContainerClasses)}>
        {renderInputs()}
      </div>

      {lowerChildContent && <div>{lowerChildContent}</div>}
    </div>
  );
}

export default BringzzPinCode;
