import * as React from "react";
const IconRocketGrowth1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.7 37.9-4.8-4.8v-8.5c0-9.7-3.1-13.8-6.1-16.4-.4-.3-.9-.3-1.3 0-3 2.6-6.1 6.6-6.1 16.4v8.5l-4.8 4.8c-.2.2-.3.4-.3.7v5.8c0 .6.4 1 1 1h6.2c-.1.5-.2.9-.2 1.4 0 2.4 3.4 7 4.1 7.9.2.3.5.4.8.4s.6-.1.8-.4c.7-.9 4.1-5.5 4.1-7.9 0-.5-.1-1-.2-1.4H61c.6 0 1-.4 1-1v-5.8c0-.3-.1-.5-.3-.7M53 46.8c0 1-1.5 3.6-2.9 5.6-1.4-2-2.9-4.5-2.9-5.6 0-.5.1-1 .4-1.4h5c.3.5.4.9.4 1.4m7-3.4H40.4V39l4.8-4.8c.2-.2.3-.4.3-.7v-8.9c0-8.3 2.2-11.9 4.7-14.3 2.5 2.5 4.7 6.1 4.7 14.3v8.9c0 .3.1.5.3.7L60 39zM27.2 24.5h-3.8c-2.2 0-4 1.8-4 4V52c0 2.2 1.8 4 4 4h3.8c2.2 0 4-1.8 4-4V28.5c0-2.3-1.8-4-4-4m2 27.5c0 1.1-.9 2-2 2h-3.8c-1.1 0-2-.9-2-2V28.5c0-1.1.9-2 2-2h3.8c1.1 0 2 .9 2 2zM9.8 36.9H6c-2.2 0-4 1.8-4 4V52c0 2.2 1.8 4 4 4h3.8c2.2 0 4-1.8 4-4V40.9c0-2.2-1.8-4-4-4m2 15.1c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V40.9c0-1.1.9-2 2-2h3.8c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconRocketGrowth1;
