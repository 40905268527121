import * as React from "react";
const IconChatAlt7 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M43.6 10.8H20.4C10.8 10.8 3 18.6 3 28.1v20.3c0 2.6 2.1 4.8 4.8 4.8h35.9c9.6 0 17.4-7.8 17.4-17.4v-7.7c-.1-9.5-7.9-17.3-17.5-17.3M59 35.9c0 8.5-6.9 15.4-15.4 15.4H7.8C6.2 51.2 5 50 5 48.5V28.1c0-8.5 6.9-15.4 15.4-15.4h23.3c8.5 0 15.4 6.9 15.4 15.4v7.8z" />
    <path d="M18.3 29c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.4-3-3-3m0 4c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.5 1-1 1M32 29c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3m0 4c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1M45.8 29c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.4-3-3-3m0 4c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.5 1-1 1" />
  </svg>
);
export default IconChatAlt7;
