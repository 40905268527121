import * as React from "react";
const IconStairs = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.3 25.8h-4.1v-21h6.3c.6 0 1-.4 1-1s-.4-1-1-1h-7.3c-.1 0-.2 0-.4.1 0 0-.1 0-.1.1 0 0-.1 0-.1.1L6.5 37.7c-.4.3-.5 1-.2 1.4.2.3.5.4.8.4.2 0 .4-.1.6-.2l10.1-8v14.6c0 .1 0 .2.1.3h-3.2c-.6 0-1 .4-1 1v12.1H3c-.6 0-1 .4-1 1s.4 1 1 1h53.3c3.1 0 5.7-2.6 5.7-5.7v-24c0-3.2-2.6-5.8-5.7-5.8m-11.5 0c-.6 0-1 .4-1 1V37H36V17L50.2 5.8v20zM19.9 45.9V29.7L34 18.6V37h-4.1c-.6 0-1 .4-1 1v8.1h-9.1s.1-.1.1-.2M60 55.5c0 2-1.7 3.7-3.7 3.7H15.8v-11H30c.6 0 1-.4 1-1V39h13.9c.6 0 1-.4 1-1V27.8h10.5c2 0 3.7 1.7 3.7 3.7v24z" />
  </svg>
);
export default IconStairs;
