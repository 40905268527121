import * as React from "react";
const IconBow1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M44.1 62c-.9 0-1.6-.5-2.1-1.2l-9.5-16.1c-3.4.1-6.9-1.1-9.5-3.7s-3.9-6.1-3.7-9.5L3.2 22c-.7-.4-1.2-1.2-1.2-2s.4-1.6 1.1-2.1l9-5.8 5.8-9c.4-.7 1.2-1.1 2-1.1s1.6.5 2 1.2l9.5 16.1c3.4-.1 6.9 1.1 9.5 3.7s3.9 6.1 3.7 9.5L60.8 42c.7.4 1.2 1.2 1.2 2s-.4 1.6-1.1 2.1l-9 5.8-5.8 9c-.4.7-1.2 1.1-2 1.1m-9.4-17.6 9.1 15.4c.1.2.2.2.3.2s.2 0 .4-.2l5.9-9.2.3-.3 9.2-5.9c.2-.1.2-.3.2-.4s0-.2-.2-.3l-15.4-9.1c-.6 2.4-1.7 4.6-3.5 6.4s-4 2.9-6.3 3.4M32 21.3c-2.7 0-5.5 1-7.6 3.1-4.2 4.2-4.2 11 0 15.2 2 2 4.7 3.1 7.6 3.1s5.6-1.1 7.6-3.1 3.1-4.7 3.1-7.6-1.1-5.6-3.1-7.6c-2.1-2.1-4.9-3.1-7.6-3.1M19.9 4c-.1 0-.2 0-.3.2l-5.9 9.2-.3.3-9.2 5.9c-.2.1-.2.2-.2.3s0 .2.2.3l15.4 9.1c.5-2.3 1.6-4.5 3.4-6.3s4-2.9 6.3-3.4L20.2 4.2c-.1-.2-.2-.2-.3-.2" />
  </svg>
);
export default IconBow1;
