import * as React from "react";
const IconHospitalLocationAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.4 42.9h-3v-4.1c0-.6-.4-1-1-1s-1 .4-1 1v4.1h-3c-.6 0-1 .4-1 1s.4 1 1 1h3V49c0 .6.4 1 1 1s1-.4 1-1v-4.1h3c.6 0 1-.4 1-1s-.5-1-1-1M23.6 17.2c-5.2 0-9.5 4.2-9.5 9.5s4.2 9.5 9.5 9.5 9.5-4.2 9.5-9.5-4.2-9.5-9.5-9.5m0 17c-4.1 0-7.5-3.3-7.5-7.5 0-4.1 3.3-7.5 7.5-7.5 4.1 0 7.5 3.3 7.5 7.5 0 4.1-3.3 7.5-7.5 7.5" />
    <path d="M47.1 29c-.7 0-1.4.1-2 .2.1-.8.2-1.6.2-2.3 0-12.9-8.7-21.6-21.6-21.6C9.9 5.2 2 13.1 2 26.8 2 42.4 22.2 56.4 23.1 57c.2.1.4.2.6.2s.5-.1.7-.2c0 0 4.1-3.6 8.6-8.5 2 6 7.6 10.3 14.2 10.3 8.2 0 14.9-6.7 14.9-14.9S55.3 29 47.1 29M23.6 55C20.2 52.4 4 39.9 4 26.8 4 9.8 16.3 7.2 23.6 7.2c11.9 0 19.6 7.7 19.6 19.6 0 .8-.1 1.7-.4 2.7-6.2 1.8-10.7 7.5-10.7 14.3 0 .8.1 1.5.2 2.3-3.7 4.2-7.3 7.6-8.7 8.9m23.5 1.8c-7.1 0-12.9-5.8-12.9-12.9S40 31 47.1 31 60 36.8 60 43.9s-5.8 12.9-12.9 12.9" />
  </svg>
);
export default IconHospitalLocationAlt1;
