import * as React from "react";
const IconHomeDocuments = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M25.4 12.3c-.4-.3-.9-.3-1.2 0l-9.4 7.2c-.4.3-.5 1-.2 1.4s1 .5 1.4.2l2.6-2v7.7c0 .6.4 1 1 1h10.3c.6 0 1-.4 1-1v-7.7l2.6 2c.2.1.4.2.6.2.3 0 .6-.1.8-.4.3-.4.3-1.1-.2-1.4zm3.5 5.7v7.8h-8.3V18c0-.1 0-.3-.1-.4l4.2-3.2 4.2 3.2c.1.1 0 .2 0 .4M17.8 40.7H27c.6 0 1-.4 1-1s-.4-1-1-1h-9.3c-.6 0-1 .4-1 1s.5 1 1.1 1M31.7 48.1H17.8c-.6 0-1 .4-1 1s.4 1 1 1h13.9c.6 0 1-.4 1-1s-.4-1-1-1" />
    <path d="M56.8 34.8h-8.9V7.7c0-3.1-2.6-5.7-5.7-5.7H8.9C5.8 2 3.2 4.6 3.2 7.7v48.6c0 3.1 2.6 5.7 5.7 5.7h46.7c2.8 0 5.1-2.3 5.1-5.1V38.8c.1-2.3-1.7-4-3.9-4M5.2 56.3V7.7c0-2 1.7-3.7 3.7-3.7h33.2c2 0 3.7 1.7 3.7 3.7V57c0 1.2.4 2.3 1.1 3.1h-38c-2-.1-3.7-1.8-3.7-3.8m53.6.6c0 1.7-1.4 3.1-3.1 3.1H51c-1.7 0-3.1-1.4-3.1-3.1V36.8h8.9c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconHomeDocuments;
