import * as React from "react";
const IconDiploma2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62 39.9c0-3.4-2.1-6.3-5-7.5V11.3c0-.4-.3-.8-.7-.9-2.1-.7-3.7-2.3-4.4-4.4-.1-.4-.5-.7-.9-.7H8c-.4 0-.8.3-.9.7-.7 2-2.4 3.6-4.4 4.3-.4.2-.7.5-.7 1v30.2c0 .4.3.8.7.9 2.1.7 3.7 2.3 4.4 4.4.1.4.5.7.9.7h40.2v10.3c0 .3.2.6.4.8.3.2.6.2.9.1l4.3-1.4 4.3 1.4h.3c.2 0 .4-.1.6-.2.3-.2.4-.5.4-.8V45.8c1.6-1.5 2.6-3.6 2.6-5.9m-2 0c0 3.4-2.8 6.1-6.1 6.1-3.4 0-6.1-2.8-6.1-6.1s2.8-6.1 6.1-6.1c.6 0 1.2.1 1.7.2h.1c2.5.9 4.3 3.2 4.3 5.9m-56 .9V12c2.1-.9 3.8-2.6 4.7-4.7h41.6c.9 2.1 2.6 3.8 4.7 4.7v19.9c-.4 0-.7-.1-1.1-.1-4.5 0-8.1 3.6-8.1 8.1 0 2.1.8 4.1 2.2 5.5H8.7c-.9-2-2.6-3.7-4.7-4.6m53.5 15.6-3.3-1.1q-.3-.15-.6 0l-3.3 1.1v-8.9h.6c1 .4 2 .6 3.1.6q1.95 0 3.6-.9v9.2z" />
    <path d="M16.8 16.6h25.4c.6 0 1-.4 1-1s-.4-1-1-1H16.8c-.6 0-1 .4-1 1s.4 1 1 1M46.6 23.8c0-.6-.4-1-1-1H13.4c-.6 0-1 .4-1 1s.4 1 1 1h32.1c.6 0 1.1-.5 1.1-1M43.7 31.3H12.2c-.6 0-1 .4-1 1s.4 1 1 1h31.5c.6 0 1-.4 1-1 0-.5-.4-1-1-1" />
  </svg>
);
export default IconDiploma2;
