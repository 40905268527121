import * as React from "react";
const IconMachinery = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M29.712 24.189c.371-.125.763-.19 1.158-.19h1.112a.25.25 0 0 0 .25-.266l-.45-6.518a.48.48 0 0 0-.499-.453c-4.266.216-7.694 3.386-7.957 7.734l-.035.579a.5.5 0 0 0 .616.516zm.188-5.193c-2.08.572-3.69 2.099-4.311 4.131l3.578-.864q.463-.147.944-.212z"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M10.5 6a3.5 3.5 0 0 0-3.114 5.1l-1.16 13.276-.004.064-.082 2.32a4.875 4.875 0 0 0 4.296 5.013l.18.022a4.88 4.88 0 0 0 4.8-2.405l2.195-3.806c.495-.857-.278-1.889-1.24-1.655l-1.681.408-2.548-.165-1.066-.127 1.692-11.222c1.815.562 3.607 1.158 5.153 2.002 1.495.817 2.688 1.829 3.417 3.198a11.95 11.95 0 0 0-2.218 6.311l-.311 5.61A1 1 0 0 0 19.807 31h21.65a.5.5 0 0 0 .498-.541l-.59-7.084A1.5 1.5 0 0 0 39.87 22h-3.826a.05.05 0 0 1-.05-.043l-1.004-7.232A2 2 0 0 0 33.009 13h-1.907c-1.817 0-3.543.405-5.091 1.131-2.914-4.408-8.012-5.65-12.649-6.646A3.5 3.5 0 0 0 10.5 6M9 9.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0m1.977 3.468a3.54 3.54 0 0 1-1.971-.302L7.971 24.503l-.082 2.319a3.125 3.125 0 0 0 2.754 3.213l.18.022a3.13 3.13 0 0 0 3.077-1.541l1.466-2.542-.356.086q-.166.04-.338.03l-2.69-.175-1.753-.209a1.125 1.125 0 0 1-.98-1.285zm7.903.102c-1.633-.892-3.481-1.521-5.22-2.064.194-.407.313-.857.336-1.332 4.254.962 8.036 2.158 10.278 5.457a12 12 0 0 0-1.563 1.29c-.967-1.469-2.336-2.534-3.831-3.35m2.237 11.375A10 10 0 0 1 31.102 15h1.907l1.004 7.232A2.05 2.05 0 0 0 36.043 24h3.367l.417 5H20.864z"
      clipRule="evenodd"
    />
    <path
      
      d="M23.5 39a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3M31 37.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0M35.5 39a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3"
    />
    <path
      
      fillRule="evenodd"
      d="M21.5 33a4.5 4.5 0 1 0 0 9h16a4.5 4.5 0 1 0 0-9zm16 2h-16a2.5 2.5 0 0 0 0 5h16a2.5 2.5 0 0 0 0-5"
      clipRule="evenodd"
    />
  </svg>
);
export default IconMachinery;
