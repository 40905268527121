import * as React from "react";
const IconSignPole = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.1 10.2H33V3c0-.6-.4-1-1-1s-1 .4-1 1v7.2H12.9c-2.2 0-4 1.8-4 4v15.1c0 2.2 1.8 4 4 4H31V61c0 .6.4 1 1 1s1-.4 1-1V33.2h18.1c2.2 0 4-1.8 4-4v-15c0-2.2-1.8-4-4-4m2 19c0 1.1-.9 2-2 2H12.9c-1.1 0-2-.9-2-2v-15c0-1.1.9-2 2-2H51c1.1 0 2 .9 2 2v15z" />
  </svg>
);
export default IconSignPole;
