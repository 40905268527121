import * as React from "react";
const IconGynecology = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M39 8H9a1 1 0 0 0-1 1v30a1 1 0 0 0 1 1h30a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1M9 6a3 3 0 0 0-3 3v30a3 3 0 0 0 3 3h30a3 3 0 0 0 3-3V9a3 3 0 0 0-3-3z"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M11.981 18.284c-.208-.37-.121-.816.273-1.214.192-.194.395-.302.531-.337l.047-.01c.973.859 1.657 1.272 2.405 1.276.172.001.319-.02.42-.036l.103-.017c.048-.008.078-.013.12-.018a.84.84 0 0 1 .355.036l.53-1.928a2.8 2.8 0 0 0-1.114-.095c-.098.012-.205.03-.269.04l-.04.007a1 1 0 0 1-.094.011h-.002q-.001 0-.016-.003a1 1 0 0 1-.16-.073c-.187-.105-.485-.317-.964-.743-.565-.503-1.274-.525-1.82-.383-.547.14-1.055.464-1.453.865-.784.792-1.387 2.193-.595 3.601.453.808.966 1.477 1.412 1.985-.014-.445.105-.845.368-1.134.24-.264.566-.4.935-.42a9.7 9.7 0 0 1-.972-1.41"
      clipRule="evenodd"
    />
    <path
      
      d="M16.313 21.442c1.142 1.095 1.46 2.59.712 3.34-.75.751-2.283.472-3.425-.622-1.143-1.094-1.461-2.59-.712-3.34s2.283-.472 3.425.622"
    />
    <path
      
      fillRule="evenodd"
      d="M36.266 18.284c.208-.37.121-.816-.273-1.214a1.25 1.25 0 0 0-.531-.337l-.046-.01c-.973.859-1.658 1.272-2.406 1.276a2.5 2.5 0 0 1-.42-.036l-.103-.017c-.048-.008-.078-.013-.12-.018a.84.84 0 0 0-.354.036l-.531-1.928c.454-.125.81-.13 1.114-.095.098.012.205.03.27.04l.04.007c.067.011.086.011.093.011h.003q0 .001.015-.004a1 1 0 0 0 .16-.072c.187-.105.485-.317.964-.743.566-.503 1.275-.525 1.821-.383.546.14 1.054.464 1.452.865.784.792 1.387 2.193.596 3.601a12 12 0 0 1-1.412 1.985c.013-.445-.105-.845-.368-1.134-.24-.264-.567-.4-.936-.42.32-.388.665-.863.972-1.41"
      clipRule="evenodd"
    />
    <path
      
      d="M31.934 21.442c-1.142 1.094-1.46 2.59-.711 3.34s2.282.472 3.425-.622 1.46-2.59.711-3.34c-.75-.75-2.282-.472-3.425.622M24.11 14c-10.834 0-6.771 8.043-4.063 8.043 2.247 0 1.007 8.918 2.453 11.957.297.624 1.072 0 1.61 0s1.115.624 1.39 0c1.34-3.039-.026-11.957 2.221-11.957 2.71 0 7.223-8.043-3.61-8.043"
    />
  </svg>
);
export default IconGynecology;
