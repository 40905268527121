import * as React from "react";
const IconIpoFlag = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M52.4 9.1H9.6V3c0-.6-.4-1-1-1s-1 .4-1 1v58c0 .6.4 1 1 1s1-.4 1-1V40.6h42.8c2.2 0 4-1.8 4-4V13.1c0-2.2-1.8-4-4-4m2 27.5c0 1.1-.9 2-2 2H9.6V11.1h42.8c1.1 0 2 .9 2 2z" />
    <path d="M28.9 15.8h-5.7c-.6 0-1 .4-1 1v15.5c0 .6.4 1 1 1s1-.4 1-1v-6.8c.2.1.3.2.5.2h4.2c2.7 0 4.9-2.2 4.9-5 0-2.6-2.2-4.9-4.9-4.9m0 7.9h-4.2c-.2 0-.4.1-.5.2v-6h4.7c1.6 0 2.9 1.3 2.9 3 0 1.5-1.3 2.8-2.9 2.8M43.9 15.8h-.4c-2.7 0-5 2.2-5 5v7.6c0 2.7 2.2 5 5 5h.4c2.7 0 5-2.2 5-5v-7.6c0-2.7-2.2-5-5-5m3 12.6c0 1.6-1.3 3-3 3h-.4c-1.6 0-3-1.3-3-3v-7.6c0-1.6 1.3-3 3-3h.4c1.6 0 3 1.3 3 3zM16.1 15.8c-.6 0-1 .4-1 1v15.5c0 .6.4 1 1 1s1-.4 1-1V16.8c0-.5-.4-1-1-1" />
  </svg>
);
export default IconIpoFlag;
