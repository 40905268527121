import * as React from "react";
const IconWarmingUp = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 16.2c3.9 0 7.1-3.2 7.1-7.1S35.9 2 32 2s-7.1 3.2-7.1 7.1 3.2 7.1 7.1 7.1M32 4c2.8 0 5.1 2.3 5.1 5.1s-2.3 5.1-5.1 5.1-5.1-2.3-5.1-5.1S29.2 4 32 4" />
    <path d="M43.4 22.1H20.6c-3.2 0-5.8-2.6-5.8-5.8V3.8c0-.6-.4-1-1-1s-1 .4-1 1v12.5c0 4.3 3.5 7.8 7.8 7.8h2v21.2c0 .2.1.4.2.5-2.5 1.6-4.1 4.4-4.1 7.6V61c0 .6.4 1 1 1s1-.4 1-1v-7.6c0-3.9 3.2-7.1 7.1-7.1h8.5c3.9 0 7.1 3.2 7.1 7.1V61c0 .6.4 1 1 1s1-.4 1-1v-7.6c0-3.2-1.6-6-4.1-7.6.1-.2.2-.3.2-.5V24.1h2c3.2 0 5.8 2.6 5.8 5.8v12.6c0 .6.4 1 1 1s1-.4 1-1V29.9c-.1-4.3-3.6-7.8-7.9-7.8m-3.9 22.8c-1-.4-2.1-.6-3.2-.6h-8.5c-1.1 0-2.2.2-3.2.6V24.1h14.9z" />
  </svg>
);
export default IconWarmingUp;
