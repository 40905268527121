import * as React from "react";
const IconScrollH = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 42.6c-5.9 0-10.6-4.8-10.6-10.6 0-5.9 4.8-10.6 10.6-10.6 5.9 0 10.6 4.8 10.6 10.6 0 5.9-4.7 10.6-10.6 10.6m0-19.2c-4.8 0-8.6 3.9-8.6 8.6s3.9 8.6 8.6 8.6 8.6-3.9 8.6-8.6-3.8-8.6-8.6-8.6M14.5 43.7c-.3 0-.5-.1-.7-.3L3.3 32.7c-.4-.4-.4-1 0-1.4l10.5-10.7c.4-.4 1-.4 1.4 0s.4 1 0 1.4L5.4 32l9.8 10c.4.4.4 1 0 1.4-.2.2-.5.3-.7.3M49.5 43.7c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l9.8-10-9.8-10c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l10.5 10.7c.4.4.4 1 0 1.4L50.2 43.4c-.2.2-.4.3-.7.3" />
  </svg>
);
export default IconScrollH;
