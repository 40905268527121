import * as React from "react";
const IconMedalAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.2 29c0-5.1-2-9.7-5.2-13.1V7.2C46 4.3 43.7 2 40.8 2H23.2C20.3 2 18 4.3 18 7.2v8.7c-3.2 3.4-5.2 8-5.2 13.1s2 9.7 5.2 13.1v18c0 .7.4 1.3.9 1.6.3.2.6.3 1 .3.3 0 .6-.1.8-.2l10.5-5.4q.75-.3 1.5 0l10.5 5.4c.6.3 1.3.3 1.8 0 .6-.3.9-1 .9-1.6v-18c3.3-3.5 5.3-8.1 5.3-13.2m-28-25h17.5C42.6 4 44 5.4 44 7.2V14c-3.3-2.6-7.5-4.2-12-4.2s-8.7 1.6-12 4.2V7.2C20 5.4 21.4 4 23.2 4m8.8 7.8c9.5 0 17.2 7.7 17.2 17.2S41.5 46.2 32 46.2 14.8 38.5 14.8 29 22.5 11.8 32 11.8m1.6 42.8c-1-.5-2.2-.5-3.3 0L20 59.9V44c3.3 2.6 7.5 4.2 12 4.2s8.7-1.6 12-4.2v16zM32 28v2z" />
    <path d="M32 37.2c4.5 0 8.2-3.7 8.2-8.2s-3.7-8.2-8.2-8.2-8.2 3.7-8.2 8.2 3.7 8.2 8.2 8.2m0-14.4c3.4 0 6.2 2.8 6.2 6.2s-2.8 6.2-6.2 6.2-6.2-2.8-6.2-6.2 2.8-6.2 6.2-6.2" />
  </svg>
);
export default IconMedalAlt;
