import * as React from "react";
const IconAdobe = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M40 6.1h23v51.6zm-16 0H1v51.6zm8 19.3L46.4 58h-9.7l-4.2-10.4H21.9z" />
  </svg>
);
export default IconAdobe;
