import * as React from "react";
const IconIdeaSetting = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.2 37.8q0-.15 0 0l-3.4-3.1c.1-.9.1-1.8.1-2.7s0-1.8-.1-2.7l3.4-3.1c1.6-1.5 1.9-3.8.9-5.7L56 13.4c-1-1.9-3.3-2.8-5.4-2.1l-4.3 1.4c-1.4-1.1-3-2-4.6-2.7l-1-4.4C40.1 3.5 38.3 2 36.1 2h-8.2c-2.1 0-4 1.5-4.5 3.6l-1 4.5c-1.6.7-3.1 1.6-4.6 2.7l-4.3-1.4c-2.1-.7-4.4.2-5.4 2.1L4 20.6c-1.1 1.9-.7 4.2.9 5.7l3.3 3.1c-.1.8-.2 1.7-.2 2.6s0 1.8.1 2.7l-3.4 3.1c-1.6 1.5-1.9 3.8-.9 5.7l4.1 7.1c1.1 1.9 3.3 2.8 5.4 2.1l4.3-1.4c1.4 1.1 3 2 4.6 2.7l1 4.4c.5 2.1 2.4 3.6 4.5 3.6h8.2c2.1 0 4-1.5 4.5-3.6l1-4.5c1.6-.7 3.1-1.6 4.6-2.7l4.3 1.4c2.1.6 4.4-.2 5.4-2.1l4.1-7.1c1.3-1.8.9-4.2-.6-5.6m-.9 4.6-4.1 7.1c-.6 1-1.9 1.5-3.1 1.2l-4.8-1.5c-.3-.1-.7 0-.9.2-1.6 1.2-3.3 2.2-5 2.9-.3.1-.5.4-.6.7l-1.1 5c-.3 1.2-1.3 2-2.5 2H28c-1.2 0-2.3-.9-2.5-2.1L24.2 53c-.1-.3-.3-.6-.6-.7-1.8-.7-3.5-1.7-5-2.9-.2-.1-.4-.2-.6-.2h-.3l-4.8 1.5c-1.2.4-2.5-.1-3.1-1.2l-4.1-7.1c-.6-1.1-.4-2.4.5-3.3l3.7-3.4c.2-.2.4-.5.3-.9-.1-1-.2-2-.2-2.9 0-1 .1-1.9.2-2.9q0-.45-.3-.9l-3.7-3.4c-.9-.8-1.1-2.2-.5-3.3l4.1-7.1c.6-1 1.9-1.5 3.1-1.2l4.8 1.5c.3.1.7 0 .9-.2 1.6-1.2 3.3-2.2 5-2.9.3-.1.5-.4.6-.7l1.1-5c.3-1.2 1.3-2 2.5-2H36c1.2 0 2.3.9 2.5 2.1l1.1 4.9c.1.3.3.6.6.7 1.8.7 3.5 1.7 5 2.9.3.2.6.3.9.2l4.8-1.5c1.2-.4 2.5.1 3.1 1.2l4.1 7.1c.6 1.1.4 2.4-.5 3.3l-3.7 3.4c-.2.2-.4.5-.3.9.1 1 .2 2 .2 2.9s-.1 1.9-.2 2.9q0 .45.3.9l3.7 3.4c1.1 1 1.3 2.3.7 3.3" />
    <path d="M32.3 18c-3-.1-5.7 1-7.8 3.1s-3.3 4.8-3.3 7.8c0 3.8 1.9 7.2 5.1 9.2.6.4 1 1.1 1 1.9v1.9c0 2.3 1.9 4.3 4.3 4.3h1c2.3 0 4.3-1.9 4.3-4.3V40c0-.8.4-1.5 1-1.9 3.1-1.9 5-5.3 5.1-8.9.1-2.9-1-5.6-3-7.7-2.2-2.3-4.9-3.5-7.7-3.5m2.5 23.8c0 1.2-1 2.3-2.3 2.3h-1c-1.2 0-2.3-1-2.3-2.3v-2.5h5.6v2.5m1.9-5.5c-.4.3-.8.6-1.1 1.1h-7.1c-.3-.4-.7-.8-1.1-1.1-2.6-1.6-4.2-4.4-4.2-7.5 0-2.4.9-4.7 2.7-6.3 1.7-1.6 3.9-2.5 6.2-2.5h.2c2.3.1 4.5 1 6.2 2.8 1.7 1.7 2.5 4 2.5 6.3-.2 2.9-1.8 5.6-4.3 7.2" />
  </svg>
);
export default IconIdeaSetting;
