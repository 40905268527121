import * as React from "react";
const IconIncognitoBrowserAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56 5H8c-2.8 0-5 2.2-5 5v44c0 2.8 2.2 5 5 5h48c2.8 0 5-2.2 5-5V10c0-2.8-2.2-5-5-5M8 7h48c1.7 0 3 1.3 3 3v7.4H5V10c0-1.7 1.3-3 3-3m48 50H8c-1.7 0-3-1.3-3-3V19.4h54V54c0 1.7-1.3 3-3 3" />
    <path d="M50.4 36.4h-5.6l-2.5-10.1c-.1-.3-.2-.5-.5-.6-.2-.1-.5-.2-.8-.1l-9 2.3-9-2.3c-.3-.1-.5 0-.8.1-.2.1-.4.4-.5.6l-2.5 10.1h-5.6c-.6 0-1 .4-1 1s.4 1 1 1h5.8c-1.1 1.2-1.8 2.7-1.8 4.4 0 3.5 2.9 6.4 6.4 6.4s6.4-2.9 6.4-6.4c0-1.7-.7-3.3-1.8-4.4h6.6c-1.1 1.2-1.8 2.7-1.8 4.4 0 3.5 2.9 6.4 6.4 6.4s6.4-2.9 6.4-6.4c0-1.7-.7-3.3-1.8-4.4h5.8c.6 0 1-.4 1-1s-.3-1-.8-1m-27-8.7 8.3 2.1h.5l8.3-2.1 2.2 8.7H21.3zm5.1 15.1c0 2.4-2 4.4-4.4 4.4s-4.4-2-4.4-4.4 2-4.4 4.4-4.4 4.4 2 4.4 4.4m11.4 4.5c-2.4 0-4.4-2-4.4-4.4s2-4.4 4.4-4.4 4.4 2 4.4 4.4-1.9 4.4-4.4 4.4" />
  </svg>
);
export default IconIncognitoBrowserAlt;
