import * as React from "react";
const IconCandyCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m59.9 7-3-3c-1.5-1.5-3.6-2.3-5.7-2-2.1.2-4 1.4-5.2 3.2l-4 6.1c-6.3-2.3-13.6-.9-18.6 4.1l-7.9 7.9c-3.4 3.4-5.2 7.8-5.2 12.6 0 2.1.4 4.2 1.1 6.2l-6 3.8c-1.8 1.2-3 3.1-3.2 5.2s.5 4.3 2 5.8l3 3c1.3 1.3 3.1 2.1 5 2.1h.7c2.1-.2 4-1.4 5.2-3.2l4-6.2c1.9.7 3.9 1 6 1 4.6 0 9.1-1.7 12.6-5.2l2.9-2.9 5-4.9c3.4-3.4 5.2-7.8 5.2-12.6 0-2.2-.4-4.3-1.1-6.2l6-3.8c1.8-1.2 3-3.1 3.3-5.2s-.6-4.3-2.1-5.8M16.4 57.7c-.8 1.3-2.2 2.1-3.7 2.3s-3-.4-4.1-1.5l-3-3c-1.1-1.1-1.6-2.6-1.5-4.1s1-2.9 2.3-3.7l5.8-3.7q1.2 2.55 3.3 4.5c1.4 1.4 3 2.5 4.7 3.4zm22.8-10.6c-6.2 6.1-16.2 6.1-22.4 0-3-3-4.6-6.9-4.6-11.1s1.6-8.2 4.6-11.1l2.3-2.3c1.4 1.1 2.6 1.4 3.8 1.6s2.2.4 3.5 1.7 1.5 2.3 1.7 3.5c.3 1.3.5 2.8 2.2 4.5s3.2 2 4.5 2.2c1.2.2 2.2.4 3.5 1.7s1.5 2.3 1.7 3.5c.2 1.1.5 2.3 1.6 3.7zm8-7.9L43 43.4c-.7-.9-.9-1.8-1-2.7-.3-1.3-.5-2.8-2.2-4.5s-3.2-2-4.5-2.2c-1.2-.2-2.2-.4-3.5-1.7S30.3 30 30 28.8c-.3-1.3-.5-2.8-2.2-4.5s-3.2-2-4.5-2.2c-.9-.2-1.8-.3-2.7-1l4.2-4.2c3.1-3.1 7.1-4.6 11.2-4.6s8.1 1.5 11.2 4.6c3 3 4.6 6.9 4.6 11.1s-1.7 8.2-4.6 11.2m12.7-26.6c-.2 1.5-1 2.9-2.3 3.7l-5.8 3.6q-1.2-2.4-3.3-4.5c-1.4-1.4-3-2.5-4.7-3.3l3.8-5.8c.9-1.3 2.3-2.1 3.8-2.3s3 .4 4.1 1.5l3 3c1.1 1 1.6 2.5 1.4 4.1" />
  </svg>
);
export default IconCandyCopy;
