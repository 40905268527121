import * as React from "react";
const IconSpeechBubble32 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.3 4.3c-1-1.5-2.7-2.3-4.5-2.3H20.1c-2.4 0-4.5 1.5-5.2 3.8l-3 9c-.6 1.7-.3 3.5.8 5 1 1.5 2.7 2.3 4.5 2.3h25.7l-3.8 10h-28c-2.4 0-4.5 1.5-5.2 3.8l-3 9c-.6 1.7-.3 3.5.8 5s2.7 2.3 4.5 2.3h19L24 60.7c-.1.4 0 .9.3 1.1.2.1.4.2.6.2s.4-.1.6-.2L39.4 52h4.4c2.4 0 4.5-1.5 5.2-3.8l3-9c.6-1.7.3-3.5-.8-5-1-1.5-2.7-2.3-4.5-2.3h-5.6L45 22h7.9c2.4 0 4.5-1.5 5.2-3.8l3-9c.6-1.7.3-3.5-.8-4.9M49.7 35.5c.7.9.8 2.1.5 3.2l-3 9c-.5 1.4-1.8 2.4-3.3 2.4h-4.7c-.2 0-.4.1-.6.2L27 58.4l2.5-7c.1-.3.1-.6-.1-.9s-.5-.4-.8-.4H8.1c-1.1 0-2.2-.5-2.8-1.5-.7-.9-.8-2.1-.5-3.2l3-9C8.3 35 9.6 34 11.1 34h35.7c1.2 0 2.2.6 2.9 1.5m9.5-26.9-3 9c-.5 1.4-1.8 2.4-3.3 2.4H17.1c-1.1 0-2.2-.5-2.8-1.5-.7-.9-.8-2.1-.5-3.2l3-9C17.3 5 18.6 4 20.1 4h35.7c1.1 0 2.2.5 2.8 1.5.8.9 1 2 .6 3.1" />
    <path d="M29.1 11h-3.2c-.6 0-1 .4-1 1s.4 1 1 1h3.2c.6 0 1-.4 1-1s-.5-1-1-1M44.1 11h-3.2c-.6 0-1 .4-1 1s.4 1 1 1h3.2c.6 0 1-.4 1-1s-.4-1-1-1M21.6 41h-3.2c-.6 0-1 .4-1 1s.4 1 1 1h3.2c.6 0 1-.4 1-1s-.5-1-1-1M36.6 41h-3.2c-.6 0-1 .4-1 1s.4 1 1 1h3.2c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconSpeechBubble32;
