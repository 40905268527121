import * as React from "react";
const IconHouseRating = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m25.8 20.9-1.1 6.5c-.1.4.1.8.4 1s.7.3 1.1.1l5.9-3.1 5.9 3.1c.1.1.3.1.5.1s.4-.1.6-.2c.3-.2.5-.6.4-1l-1.1-6.5 4.8-4.6c.3-.3.4-.7.3-1-.1-.4-.4-.6-.8-.7l-6.6-1-2.9-6c-.3-.7-1.5-.7-1.8 0l-2.9 6-6.6 1c-.4.1-.7.3-.8.7s0 .8.3 1zm3.2-5.4c.3 0 .6-.3.8-.5l2.3-4.6 2.3 4.6c.1.3.4.5.8.5l5.1.7-3.7 3.6c-.2.2-.3.6-.3.9l.9 5.1-4.6-2.4c-.1-.1-.3-.1-.5-.1s-.3 0-.5.1L27 25.8l.9-5.1c.1-.3-.1-.7-.3-.9l-3.7-3.6zM61 54.9h-3.9v-8l1.2 1c.2.2.4.2.6.2.3 0 .6-.1.8-.4.4-.4.3-1.1-.1-1.4l-9.8-8.2c-.4-.3-.9-.3-1.3 0l-7 5.9-2.8 2.3c-.4.4-.5 1-.1 1.4s1 .5 1.4.1l1.2-1v8H22.9v-8l1.2 1c.2.2.4.2.6.2.3 0 .6-.1.8-.4.4-.4.3-1.1-.1-1.4L15.6 38c-.4-.3-.9-.3-1.3 0l-7 5.9-2.8 2.3c-.4.4-.5 1-.1 1.4s1 .5 1.4.1l1.2-1v8H3c-.6 0-1 .4-1 1s.4 1 1 1h57.99999999999999c.6 0 1-.4 1-1s-.4-.8-1-.8m-52.1 0v-9.7l6-5 6 5v9.7zm34.2 0v-9.7l6-5 6 5v9.7z" />
  </svg>
);
export default IconHouseRating;
