import * as React from "react";
const IconMessageSearch = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.7 52.6-6.3-5.9c2.6-3.3 2.4-8.2-.7-11.3-1.6-1.6-3.8-2.5-6-2.5s-4.4.9-6 2.5c-3.3 3.3-3.3 8.7 0 12.1 1.6 1.6 3.8 2.5 6 2.5 2 0 3.8-.7 5.3-1.9l6.3 6c.2.2.4.3.7.3s.5-.1.7-.3c.4-.5.4-1.2 0-1.5M44 46c-2.5-2.5-2.5-6.7 0-9.2 1.2-1.2 2.9-1.9 4.6-1.9s3.4.7 4.6 1.9c2.5 2.5 2.5 6.7 0 9.2-1.2 1.2-2.9 1.9-4.6 1.9s-3.3-.7-4.6-1.9" />
    <path d="M34 48.4H7c-1.6 0-3-1.3-3-3V14.7c0-.4.1-.7.2-1l24.4 14.8c.8.5 1.8.8 2.7.8s1.9-.3 2.7-.8l24.4-14.8c.1.3.2.7.2 1v14.1c0 .6.4 1 1 1s1-.4 1-1V14.7c0-2.7-2.2-5-5-5H7c-2.8 0-5 2.2-5 5v30.7c0 2.7 2.2 5 5 5h27c.6 0 1-.4 1-1s-.5-1-1-1m21.8-36.7c.5 0 1 .2 1.5.4L33.1 26.8c-1 .6-2.4.6-3.4 0L5.5 12.1c.4-.3.9-.4 1.5-.4z" />
  </svg>
);
export default IconMessageSearch;
