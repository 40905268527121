import * as React from "react";
const IconMoveRight = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.8 31.4 49 17.3c-.4-.4-1-.5-1.4-.1s-.5 1-.1 1.4L57.9 31H4c-.6 0-1 .4-1 1s.4 1 1 1h53.9L47.5 45.4c-.4.4-.3 1.1.1 1.4.2.2.4.2.6.2.3 0 .6-.1.8-.4l11.8-14c.3-.3.3-.9 0-1.2M35.2 54.3H4.3c-.6 0-1 .4-1 1s.4 1 1 1h30.9c.6 0 1-.4 1-1s-.4-1-1-1M4.3 9.7h30.9c.6 0 1-.4 1-1s-.4-1-1-1H4.3c-.6 0-1 .4-1 1s.4 1 1 1" />
  </svg>
);
export default IconMoveRight;
