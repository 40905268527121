import * as React from "react";
const IconRemoveUser = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M20.6 34.6c5 0 9-4 9-9s-4-9-9-9-9 4-9 9 4.1 9 9 9m0-16c3.9 0 7 3.1 7 7s-3.1 7-7 7-7-3.1-7-7 3.2-7 7-7M20.6 39c-6.7 0-13.2 2.4-18.3 6.6-.4.4-.5 1-.1 1.4s1 .5 1.4.1c4.7-4 10.7-6.2 17-6.2s12.3 2.2 17 6.2c.2.2.4.2.6.2.3 0 .6-.1.8-.4.4-.4.3-1.1-.1-1.4-5.1-4.1-11.5-6.5-18.3-6.5M61 38H50.4c-.6 0-1 .4-1 1s.4 1 1 1H61c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconRemoveUser;
