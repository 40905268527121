import * as React from "react";
const IconSlice = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.3 51.9-12-12L18.6 9.3C17.1 7.8 15.1 7 13 7s-4.1.8-5.6 2.3l-3.3 3.3C1 15.7 1 20.8 4.1 23.9l30.7 30.7c.4.4 1 .7 1.6.7s1.2-.2 1.6-.7l1.5-1.5 3.5 3.3c.4.4 1 .6 1.5.6h14.8c1.3 0 2.4-.8 2.8-2 .4-1.1 0-2.3-.8-3.1m-54-36.1 3.3-3.3c.6-.6 1.5-1 2.4-1s1.8.4 2.4 1l2.3 2.3L9.5 23l-2.3-2.3c-1.3-1.3-1.3-3.5.1-4.9m5.4 10.4 2.5-2.5 7 7c.4.4 1 .7 1.6.7s1.2-.2 1.6-.7c.9-.9.9-2.3 0-3.2l-7-7 2.5-2.5 23.7 23.7-8.2 8.2zm32.7 26.3-2.7-2.6 5.1-5.1 7.7 7.7z" />
  </svg>
);
export default IconSlice;
