import * as React from "react";
const IconPyramid = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 46.7 36.7 10.9c-1.1-1.6-2.8-2.5-4.7-2.5s-3.7.9-4.7 2.5L3 46.7c-1.2 1.8-1.3 4-.3 5.9s2.9 3 5 3h48.6c2.2 0 4-1.1 5-3s.9-4.1-.3-5.9M17.9 28.3h28.3L54.7 41H9.3zm11-16.3c.7-1 1.8-1.6 3.1-1.6s2.4.6 3.1 1.6l9.7 14.4H19.2zm30.7 39.7c-.7 1.2-1.9 2-3.3 2H7.7c-1.4 0-2.6-.7-3.3-2-.7-1.2-.6-2.7.2-3.8L8 43h48l3.3 4.9c.8 1.1.9 2.5.3 3.8" />
  </svg>
);
export default IconPyramid;
