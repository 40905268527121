import * as React from "react";
const IconCalendarWedding = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 11.6h-7V8.1c0-.6-.4-1-1-1s-1 .4-1 1v3.4H16V8.1c0-.6-.4-1-1-1s-1 .4-1 1v3.4H7c-2.8 0-5 2.2-5 5v35.3c0 2.8 2.2 5 5 5h50c2.8 0 5-2.2 5-5V16.6c0-2.8-2.2-5-5-5m-50 2h7v3.3c0 .6.4 1 1 1s1-.4 1-1v-3.3h32v3.3c0 .6.4 1 1 1s1-.4 1-1v-3.3h7c1.7 0 3 1.3 3 3v8H4v-8c0-1.7 1.3-3 3-3m50 41.3H7c-1.7 0-3-1.3-3-3V26.6h56v25.3c0 1.6-1.3 3-3 3" />
    <path d="M32 32.4c-.9-.5-1.9-.7-2.9-.7-1.5 0-3 .6-4.1 1.6-1.2 1.1-1.9 2.6-1.9 4.3 0 1.8.7 3.5 1.9 4.8l5.4 5.4c.5.5 1.1.7 1.6.7.6 0 1.2-.2 1.7-.7l5.6-5.6c1.2-1.2 1.8-2.7 1.8-4.3s-.6-3.2-1.8-4.3c-2-2.1-5-2.5-7.3-1.2m5.8 8.3-5.6 5.6c-.1.1-.3.1-.5 0l-5.4-5.4c-.8-.9-1.3-2.1-1.3-3.3 0-1.1.5-2.1 1.3-2.9.7-.7 1.7-1.1 2.7-1.1.8 0 1.6.2 2.3.7.3.2.8.2 1.1 0 1.6-1.1 3.8-.9 5.3.5.8.8 1.2 1.8 1.2 2.9.1 1.2-.3 2.2-1.1 3" />
  </svg>
);
export default IconCalendarWedding;
