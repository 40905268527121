import * as React from "react";
const IconCocktailAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.1 5.9c.6-.7.7-1.7.3-2.5C54 2.5 53.2 2 52.3 2H11.7c-.9 0-1.7.5-2.1 1.4-.4.8-.3 1.8.3 2.5l21.2 26.6V60h-7.9c-.6 0-1 .4-1 1s.4 1 1 1H41c.6 0 1-.4 1-1s-.4-1-1-1h-7.9V32.2zM20.9 16.5l2.5-2.2c1.4-1.2 3.4-1.2 4.7 0 2.1 1.9 5.2 1.9 7.4 0 1.4-1.2 3.3-1.2 4.7 0l2.7 2.4L32 30.4zM11.4 4.2c0-.1.1-.2.4-.2h40.5c.2 0 .3.1.4.2 0 .1.1.3 0 .4l-8.4 10.5-2.7-2.3c-2.1-1.9-5.2-1.9-7.4 0-1.4 1.2-3.4 1.2-4.7 0-2.1-1.9-5.2-1.9-7.4 0L19.7 15 11.4 4.6c-.1-.1-.1-.3 0-.4" />
  </svg>
);
export default IconCocktailAlt1;
