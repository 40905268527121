import React, { useState } from 'react';
import { BringzzButton, BringzzText } from "@bringzz/components";
import { useNavigation } from 'context/NavigationContext';
import { BringzzInput, BringzzPageContainer, BringzzIcon } from "@bringzz/components";
import { useLocation } from 'react-router-dom';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const RegisterShopperPage = () => {
	const { backPress } = useNavigation();

	const { navigate } = useNavigation();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	let query = useQuery();
	let phoneNumber = query.get('number');

	return (
		<BringzzPageContainer
			header={{
				left: (
					<BringzzIcon
						size='22'
						icon='IconChevronLeft'
						folder='LineIcons'
						className='cursor-pointer'
						onClick={backPress}
					/>
				)
			}}
			authenticated={false}
		>
			<div className='relative flex justify-center bg-magic-lilac/20'>
				<div className='relative bg-white h-screen w-full bg-cover bg-center flex flex-col md:max-w-2xl '>
					{/* Content */}
					<div className='flex-grow flex flex-col justify-between py-8 px-4'>
						{/* Bottom part */}
						<div className='z-10 flex flex-col '>
							<div className='flex flex-col text-center space-y-6 mx-auto md:max-w-lg lg:max-w-xl xl:max-w-2xl'>
								<BringzzText tag='h1' className='text-midnight-blue'>
									Create a shopper account
								</BringzzText>
								<BringzzText
									tag='h4'
									className='text-midnight-blue/25 font-normal'
								>
									Lorem ipsum dolor sit amet consectetur.
								</BringzzText>
							</div>
							<div className='mt-6'>
								<BringzzInput
									type='text'
									label={'Email'}
									placeholder='Email'
									onChange={(e, newValue) => {
										setEmail(newValue);
									}}
								></BringzzInput>
							</div>
							<div className='mt-6'>
								<BringzzInput
									type='password'
									label={'Password'}
									placeholder='Password'
									hint='At least 9 letters, containing a character and a number'
									onChange={(e, newValue) => {
										setPassword(newValue);
									}}
								></BringzzInput>
							</div>
							<div
								className=' border rounded-md p-4 mt-12'
								onClick={() => navigate('/register/role-switcher')}
							>
								<BringzzText
									tag='h4'
									className='text-midnight-blue/40 hover:cursor-pointer'
								>
									Not registering as a shopper?{' '}
									<span className='text-magic-lilac underline'>
										Switch here
									</span>
								</BringzzText>
							</div>
						</div>
						<div className='flex justify-center space-x-2 mt-8'>
							<BringzzButton
								size='lg'
								className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
								onClick={() => {
									navigate(
										`/register/otp?email=${email}&redirect=/register/consumer/profile`
									);
								}}
							>
								Create Account
							</BringzzButton>
						</div>
					</div>
				</div>
			</div>
		</BringzzPageContainer>
	);
};

export default RegisterShopperPage;
