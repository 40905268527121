import * as React from "react";
const IconHousePlanAlt4 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M45.8 15.1 26 6c-.9-.4-2-.4-2.9 0L6.3 13.5l-3.7 1.7c-.5.2-.7.8-.5 1.3.2.3.5.5.9.5.1 0 .3 0 .4-.1l2.3-1v21.6c0 2.7 2.2 4.9 4.9 4.9H38c2.7 0 4.9-2.2 4.9-4.9V15.9l2.2 1c.5.2 1.1 0 1.3-.5.2-.4 0-1-.6-1.3m-38.1 11h5.6c1.6 0 2.9 1.3 2.9 2.9v1.4c0 1.6-1.3 2.9-2.9 2.9H7.7zM38 40.4H10.6c-1.6 0-2.9-1.3-2.9-2.9v-2.3h5.6c2.7 0 4.9-2.2 4.9-4.9v-1.4c0-2.7-2.2-4.9-4.9-4.9H7.7v-5.1h33.1v5.2h-5.6c-2.7 0-4.9 2.2-4.9 4.9v1.4c0 2.7 2.2 4.9 4.9 4.9h5.6v2.2c0 1.6-1.2 2.9-2.8 2.9m2.8-7.1h-5.6c-1.6 0-2.9-1.3-2.9-2.9V29c0-1.6 1.3-2.9 2.9-2.9h5.6zm0-16.4H7.7V15l16.2-7.2c.4-.2.9-.2 1.3 0L40.8 15zM41.8 47.8c-.6 0-1 .4-1 1V52H8.9v-3.2c0-.6-.4-1-1-1s-1 .4-1 1v8.5c0 .6.4 1 1 1s1-.4 1-1v-3.2h31.9v3.2c0 .6.4 1 1 1s1-.4 1-1v-8.5c0-.5-.4-1-1-1M61 40.4h-3.2V10.9H61c.6 0 1-.4 1-1s-.4-1-1-1h-8.5c-.6 0-1 .4-1 1s.4 1 1 1h3.2v29.5h-3.2c-.6 0-1 .4-1 1s.4 1 1 1H61c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconHousePlanAlt4;
