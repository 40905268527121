import * as React from "react";
const Icon247 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 24.3H45c0-.2-.1-.3-.2-.5L35.9 15c.5-.7.7-1.6.7-2.5 0-2.6-2.1-4.7-4.7-4.7s-4.7 2.1-4.7 4.7c0 .9.3 1.8.7 2.5l-8.8 8.8c-.1.1-.2.3-.2.5H6c-2.2 0-4 1.8-4 4v23.8c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V28.3c0-2.2-1.8-4-4-4M32 9.9c1.5 0 2.7 1.2 2.7 2.7 0 .7-.3 1.4-.8 1.9s-1.1.8-1.9.8c-.7 0-1.4-.3-1.9-.8s-.8-1.1-.8-1.9c0-1.5 1.2-2.7 2.7-2.7m-2.5 6.6c.7.5 1.6.7 2.5.7s1.8-.3 2.5-.7l7.8 7.8H21.7zM60 52.1c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V28.3c0-1.1.9-2 2-2h52c1.1 0 2 .9 2 2z" />
    <path d="M17.1 33.3h-5.9c-.6 0-1 .4-1 1s.4 1 1 1h4.9v5h-4.9c-.6 0-1 .4-1 1v5.6c0 .6.4 1 1 1h5.9c.6 0 1-.4 1-1s-.4-1-1-1h-4.9v-3.6h4.9c.6 0 1-.4 1-1v-7c0-.6-.4-1-1-1M29.4 33.4c-.6 0-1 .4-1 1v5.2h-4.5v-5.2c0-.6-.4-1-1-1s-1 .4-1 1v6.2c0 .6.4 1 1 1h5.5v5.3c0 .6.4 1 1 1s1-.4 1-1V34.4c0-.6-.4-1-1-1M52.2 33.9h-6.5c-.6 0-1 .4-1 1s.4 1 1 1h5.2L48 47c-.1.5.2 1.1.7 1.2h.3c.4 0 .8-.3 1-.7l3.3-12.3c.1-.3 0-.6-.2-.9s-.6-.4-.9-.4M40.5 40.1h-3.6c-.6 0-1 .4-1 1s.4 1 1 1h3.6c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default Icon247;
