import * as React from "react";
const IconGel = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M47.9 14.8a5.54 5.54 0 0 0-4.1-1.8H33.1V4h4.5l7.1 2.6c.1 0 .2.1.3.1.4 0 .8-.3.9-.7.2-.5-.1-1.1-.6-1.3L38 2H24.6c-.6 0-1 .4-1 1s.4 1 1 1h6.5v9H20.2c-1.6 0-3.1.7-4.1 1.8-1.1 1.2-1.6 2.7-1.4 4.3L18.4 57c.3 2.9 2.7 5.1 5.6 5.1h16.2c2.9 0 5.3-2.2 5.6-5.1l3.7-37.9c0-1.6-.5-3.1-1.6-4.3m-4.2 42c-.2 1.9-1.7 3.2-3.6 3.2H23.9c-1.9 0-3.4-1.4-3.6-3.2l-3.7-37.9c-.1-1 .2-2 .9-2.8q1.05-1.2 2.7-1.2h23.6c1 0 2 .4 2.7 1.2s1 1.7.9 2.8z" />
  </svg>
);
export default IconGel;
