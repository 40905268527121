import { isDateAllowed } from "../utilities";
import BringzzIcon from "../../BringzzIcon";
import { monthsListLong } from "../utilities";
import React from "react";
import BringzzText from "../../BringzzText";

const MonthYearPicker = ({
  year,
  setYear,
  minDate,
  maxDate,
  onChange,
  closePicker,
  description,
}) => {
  const handleMonthYearSelect = (selectedMonth) => {
    // Create a date with a fixed time to avoid time zone issues
    const newDate = new Date(year, selectedMonth, 1, 12, 0, 0);
    onChange(newDate);
  };

  return (
    <div>
      <div className="mb-4 px-2 flex justify-between">
      <BringzzText tag="h4"><span className="mr-1">&bull;</span>{description || "Select a date"}</BringzzText>

        <div>
          {" "}
          <BringzzIcon
            icon="IconClose"
            folder="LineIcons"
            size="18"
            className="hover:text-magic-lilac cursor-pointer"
            onClick={() => {
              closePicker();
            }}
          ></BringzzIcon>
        </div>
      </div>
      <div className="flex justify-between items-center mb-4">
        <BringzzIcon
          icon="IconChevronLeft"
          folder="LineIcons"
          className={` ${isDateAllowed(year - 1, 11, 31, maxDate, minDate)
              ? "cursor-pointer "
              : "cursor-not-allowed opacity-50"
            } `}
          size="28"
          onClick={() => {
            if (isDateAllowed(year - 1, 11, 31, maxDate, minDate))
              setYear(year - 1);
          }}
        ></BringzzIcon>
        <span className="font-bold">{year}</span>
        <BringzzIcon
          icon="IconChevronRight"
          folder="LineIcons"
          className={`  ${isDateAllowed(year + 1, 0, 1, maxDate, minDate)
              ? "cursor-pointer "
              : "cursor-not-allowed opacity-50"
            } `}
          size="28"
          onClick={() => {
            if (isDateAllowed(year + 1, 0, 1, maxDate, minDate))
              setYear(year + 1);
          }}
        ></BringzzIcon>{" "}
      </div>
      <div className="grid grid-cols-3 gap-4">
        {monthsListLong.map((monthName, index) => (
          <button
            key={monthName}
            className={`p-2 rounded bg-gray-200 hover:bg-magic-lilac hover:text-white ${isDateAllowed(year, index, 1, maxDate, minDate)
                ? " cursor-pointer"
                : "cursor-not-allowed opacity-50"
              }`}
            onClick={() => {
              if (isDateAllowed(year, index, 1, maxDate, minDate)) {
                handleMonthYearSelect(index);
              }
            }}
          >
            {monthName}
          </button>
        ))}
      </div>
    </div>
  );
};
export default MonthYearPicker;
