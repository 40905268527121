import * as React from "react";
const IconTreesAlt5 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.6 12.7C49.2 10.3 46.1 9 42.7 9c-2.8 0-5.5.9-7.7 2.6C34.7 6.3 30.2 2 24.7 2c-5.8 0-10.5 4.7-10.5 10.5 0 2.5.9 5 2.6 6.9-4.9 2.8-8 8.1-8 13.8 0 8.4 6.6 15.4 14.9 15.9v12c0 .6.4 1 1 1s1-.4 1-1V49c3.2-.2 6.2-1.4 8.7-3.2 2.3 2.1 5.3 3.3 8.4 3.3h.4V61c0 .6.4 1 1 1s1-.4 1-1V48.8c5.8-1.1 10.1-6.2 10.1-12.3v-15c0-3.3-1.3-6.4-3.7-8.8M24.7 27c-.6 0-1 .4-1 1v19c-7.2-.5-12.9-6.5-12.9-13.9 0-5.4 3.2-10.4 8.1-12.6.3-.1.5-.4.6-.8 0-.3-.1-.7-.3-.9-1.8-1.6-2.9-3.9-2.9-6.4C16.2 7.8 20 4 24.7 4s8.5 3.8 8.5 8.5c0 2.2-.8 4.2-2.3 5.8l-.1.1c-.2.2-.4.4-.6.5-.3.2-.4.6-.3.9 0 .3.3.6.6.8 4.9 2.3 8.1 7.2 8.1 12.6 0 7.3-5.7 13.4-12.9 13.9V28c0-.5-.5-1-1-1m28.6 9.6c0 5-3.5 9.2-8.1 10.2v-5.3c0-.6-.4-1-1-1s-1 .4-1 1v5.6h-.4c-2.6 0-5-.9-6.9-2.6 2.9-2.9 4.7-6.9 4.7-11.3 0-5.7-3.1-11-8-13.8q1.8-2.1 2.4-4.8c2-2.2 4.8-3.5 7.8-3.5 2.8 0 5.5 1.1 7.4 3.1 2 2 3.1 4.6 3.1 7.4z" />
  </svg>
);
export default IconTreesAlt5;
