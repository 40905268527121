import * as React from "react";
const IconCartEmpty = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.4 12.1c-.5-.2-1.1 0-1.3.5L47.9 38.1c-.4.9-1.3 1.5-2.3 1.5H16.1c-1.3 0-2.4-1-2.6-2.3L10.7 7.4c-.1-1.1-1-2-2.2-2H3c-.6 0-1 .4-1 1s.4 1 1 1h5.5c.1 0 .2.1.2.2l2.9 29.8c.2 2.4 2.2 4.2 4.6 4.2h29.5c1.8 0 3.4-1 4.1-2.6L62 13.5c.1-.5-.1-1.1-.6-1.4M18.3 50.1c-2.6 0-4.7 2.1-4.7 4.8 0 2.6 2.1 4.8 4.7 4.8s4.7-2.1 4.7-4.8c.1-2.7-2.1-4.8-4.7-4.8m0 7.5c-1.5 0-2.7-1.2-2.7-2.8 0-1.5 1.2-2.8 2.7-2.8s2.7 1.2 2.7 2.8c.1 1.5-1.2 2.8-2.7 2.8M42.4 50.1c-2.6 0-4.7 2.1-4.7 4.8 0 2.6 2.1 4.8 4.7 4.8s4.7-2.1 4.7-4.8-2.1-4.8-4.7-4.8m0 7.5c-1.5 0-2.7-1.2-2.7-2.8 0-1.5 1.2-2.8 2.7-2.8s2.7 1.2 2.7 2.8c0 1.5-1.2 2.8-2.7 2.8" />
  </svg>
);
export default IconCartEmpty;
