import * as React from "react";
const IconStockOut = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      d="M27.707 7.707a1 1 0 0 0-1.414-1.414L24 8.586l-2.293-2.293a1 1 0 1 0-1.414 1.414L22.586 10l-2.293 2.293a1 1 0 0 0 1.414 1.414L24 11.414l2.293 2.293a1 1 0 1 0 1.414-1.415L25.414 10z"
    />
    <path
      
      fillRule="evenodd"
      d="M6.684 26.449 10 27.554V36a1 1 0 0 0 .673.945l12.992 4.497c.199.071.421.08.637.011l.014-.004.015-.005 12.996-4.499A1 1 0 0 0 38 36v-8.446l3.316-1.105a1 1 0 0 0 .465-1.574l-4-5a1 1 0 0 0-.456-.32l-12.998-4.5a1 1 0 0 0-.654 0l-12.998 4.5a1 1 0 0 0-.456.32l-4 5a1 1 0 0 0 .465 1.574m14.635 4.124 1.681-2.4v10.923l-11-3.808V28.22l8.184 2.728a1 1 0 0 0 1.135-.376M14.057 20.5 24 23.942l9.943-3.442L24 17.058zm12.624 10.074L25 28.172v10.924l11-3.808V28.22l-8.184 2.728a1 1 0 0 1-1.135-.376M11.34 21.676l-2.663 3.329 5.511 1.837 5.92 1.973 2.313-3.303-.135-.047zm27.983 3.329-2.663-3.33-11.081 3.837 2.313 3.303z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconStockOut;
