import * as React from "react";
const IconTruckBox = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.8 36.9 55 27.1c-.2-.3-.5-.4-.8-.4H42.9v-3.1c0-2.8-2.3-5.1-5.1-5.1h-7.5c-.6 0-1 .4-1 1s.4 1 1 1h7.5c1.7 0 3.1 1.4 3.1 3.1v28.2H30.1c-.2-2.6-2.4-4.6-5-4.6s-4.8 2-5 4.6h-7.3c-1.7 0-3.1-1.4-3.1-3.1v-13c0-.6-.4-1-1-1s-1 .4-1 1v13c0 2.8 2.3 5.1 5.1 5.1h7.5c.7 2 2.5 3.5 4.8 3.5 2.2 0 4.1-1.5 4.8-3.5H46c.7 2 2.5 3.5 4.8 3.5s4.1-1.5 4.8-3.5H61c.6 0 1-.4 1-1V37.5c0-.2-.1-.4-.2-.6m-8.1-8.2 5.8 8.4H42.9v-8.4zM25.1 55.4c-1.7 0-3.1-1.4-3.1-3.1s1.4-3.1 3.1-3.1 3.1 1.4 3.1 3.1-1.4 3.1-3.1 3.1m25.7 0c-1.7 0-3.1-1.4-3.1-3.1s1.4-3.1 3.1-3.1 3.1 1.4 3.1 3.1-1.4 3.1-3.1 3.1m5-3.5c-.2-2.6-2.4-4.6-5-4.6s-4.8 2-5 4.6H43V39.2h17v12.7z" />
    <path d="M24.8 28.8V15c0-.1 0-.2-.1-.3L22 7.3c-.1-.4-.5-.7-.9-.7H5.7c-.4 0-.8.3-.9.7l-2.7 7.4c-.1.1-.1.2-.1.3v13.8c0 .6.4 1 1 1h20.8c.5 0 1-.4 1-1M6.4 8.6h14l2.4 6.6v.3H4v-.3zM4 27.8V17.5h18.8v10.3z" />
  </svg>
);
export default IconTruckBox;
