import * as React from "react";
const IconTooth = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M16.4 62c-1.1 0-2.8-1.9-4.2-7.3-1.3-4.9-2-11.6-2-18.3 0-3-1.1-6.3-2.3-9.8-2.3-6.5-4.6-13.2-.7-19.1C10.3 2.6 12.8 2 14.9 2s4.5.8 7 1.7c3 1 6.3 2.2 9.9 2.2s6.9-1.1 9.8-2.2c2.5-.9 4.9-1.7 7-1.7s4.7.6 8.1 5.5c4 5.7 1.7 12.5-.5 19.1v.1C55 30.2 54 33.5 54 36.4c0 6.8-.7 13.4-1.9 18.1-1.2 4.6-2.8 7.4-4.4 7.4-3.1 0-4.2-3.1-5.8-10.6-1.3-6.2-3-13.9-9.9-13.9-7 0-9.1 8.2-10.8 14.8-1.4 5.7-2.6 9.8-4.8 9.8M14.9 4c-1.5 0-3.5.5-6 4.5-3.4 5.2-1.2 11.4.9 17.4 1.3 3.7 2.4 7.1 2.4 10.5 0 6.5.7 13 2 17.8 1 3.8 2 5.2 2.4 5.7.9-1 2.2-6.2 2.7-8.2 1.6-6.5 4.1-16.3 12.7-16.3 8.5 0 10.5 9.3 11.8 15.5 1.6 7.3 2.5 8.9 3.7 9 .3-.2 1.4-1.3 2.6-5.8 1.1-4.6 1.8-11 1.8-17.6 0-3.2 1.1-6.6 2.4-10.2v-.1c2-6.1 4.2-12.5.7-17.4-2.8-4.1-4.9-4.6-6.5-4.6-1.8 0-4 .8-6.3 1.6h-.1C39 6.9 35.6 8.1 31.6 8.1S24.1 6.9 21 5.8c-2.1-1-4.3-1.8-6.1-1.8" />
  </svg>
);
export default IconTooth;
