import * as React from "react";
const IconCrownAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 19c-2.7 0-5 2.2-5 5 0 1.6.7 2.9 1.9 3.8l-8.5 9.3c-.4.5-1 .7-1.6.6s-1.1-.5-1.4-1l-8.3-16.3c1.7-.8 2.8-2.5 2.8-4.5 0-2.7-2.2-5-5-5s-5 2.2-5 5c0 2 1.2 3.7 2.8 4.5l-8.3 16.3c-.3.6-.8.9-1.4 1s-1.2-.1-1.6-.6l-8.5-9.3c1.1-.9 1.9-2.3 1.9-3.8 0-2.7-2.2-5-5-5-2.7 0-5 2.2-5 5 0 2.7 2.2 5 5 5h.1l3.4 23.3c.1.5.5.9 1 .9h41.1c.5 0 .9-.4 1-.9L56.8 29h.1c2.7 0 5-2.2 5-5 .1-2.8-2.1-5-4.9-5m-25-6c1.6 0 3 1.3 3 3 0 1.6-1.3 3-3 3-1.6 0-3-1.3-3-3s1.4-3 3-3M4 23.9c0-1.6 1.3-3 3-3 1.6 0 3 1.3 3 3 0 1.6-1.3 3-3 3s-3-1.3-3-3M51.7 51H12.3L9.2 29.8l7.9 8.7c.7.8 1.8 1.3 2.8 1.3h.5c1.3-.2 2.3-1 2.9-2.1L31.8 21h.2l8.5 16.7c.6 1.1 1.7 1.9 2.9 2.1h.5c1.1 0 2.1-.4 2.8-1.3l7.9-8.7zM57 26.9c-1.6 0-3-1.3-3-3 0-1.6 1.3-3 3-3s3 1.3 3 3-1.3 3-3 3" />
  </svg>
);
export default IconCrownAlt2;
