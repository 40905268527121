import * as React from "react";
const IconDonut = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62 33.2V32C62 15.5 48.5 2 32 2S2.1 15.4 2 31.9v.1c0 16.5 13.5 30 30 30 15.8 0 28.7-12.2 29.9-27.7v-.5c.1-.2.1-.4.1-.6M32 4c15.4 0 28 12.6 28 28v2.1c-.5 4.7-4.6 8.4-9.4 8.4-5.1 0-9.5-4.7-9.5-10.1v-.5c0-5-4-9-9-9-4.9 0-8.9 4-9 8.9 0 5.1-4.3 9.3-9.5 9.3C8.3 41.2 4 37 4 31.9 4 16.5 16.6 4 32 4m0 21c3.9 0 7 3.1 7 7s-3.1 7-7 7-7-3.1-7-7 3.1-7 7-7m0 35C19.2 60 8.3 51.3 5 39.5c2.1 2.3 5.1 3.7 8.5 3.7 4.8 0 8.8-2.9 10.6-6.9 1.5 2.8 4.5 4.7 7.9 4.7 3.3 0 6.2-1.8 7.7-4.4 1.6 4.6 5.9 7.9 10.8 7.9 3.1 0 5.8-1.2 7.9-3.2C54.5 52.2 44.2 60 32 60" />
    <path d="M13.3 32.7c.2.2.4.2.6.2.3 0 .6-.1.8-.4.4-.4.3-1.1-.1-1.4-.8-.7-1.3-1.7-1.3-2.9s.4-2.5 1.3-3.5c.4-.4.3-1.1-.1-1.4-.4-.4-1.1-.3-1.4.1-1.2 1.4-1.8 3.1-1.7 4.9-.1 1.7.6 3.3 1.9 4.4M31 15.5c.8-.8 1.8-1.2 3-1.1s2.5.6 3.4 1.6c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4-1.3-1.3-3-2-4.7-2.1-1.8-.1-3.4.5-4.6 1.7-.4.4-.4 1 0 1.4.5.3 1.1.3 1.5-.1M47.2 30h.1c.5 0 .9-.4 1-.9.3-1.8 2.2-3 4.4-2.7.5.1 1.1-.3 1.1-.8.1-.5-.3-1.1-.8-1.1-3.2-.5-6.2 1.5-6.6 4.4-.2.5.2 1 .8 1.1" />
  </svg>
);
export default IconDonut;
