import * as React from "react";
const IconWhistle = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M19.8 23.3C15 23.3 11 27.2 11 32s3.9 8.7 8.7 8.7 8.7-3.9 8.7-8.7-3.8-8.7-8.6-8.7m0 15.4a6.7 6.7 0 1 1 0-13.4 6.7 6.7 0 0 1 0 13.4" />
    <path d="M57 14.2H19.8c-4.9 0-9.4 1.9-12.8 5.4s-5.1 8.1-5 13c.3 9.2 8 16.8 17.2 17.1h.5c7.9 0 15-5.3 17.1-12.9l22.5-12.4c1.6-.9 2.6-2.6 2.6-4.4v-.8c.1-2.7-2.2-5-4.9-5m3 5.9c0 1.1-.6 2.1-1.6 2.7L35.5 35.3c-.2.1-.4.4-.5.6C33.2 43 26.6 48 19.3 47.8c-8.1-.2-15-7-15.3-15.2-.2-4.3 1.4-8.4 4.4-11.5s7-4.8 11.3-4.8H57c1.7 0 3 1.4 3 3z" />
  </svg>
);
export default IconWhistle;
