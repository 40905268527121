import * as React from "react";
const IconCpuAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M43.9 2H20.1c-2.2 0-4 1.8-4 4v52c0 2.2 1.8 4 4 4h23.8c2.2 0 4-1.8 4-4V6c0-2.2-1.8-4-4-4m2 56c0 1.1-.9 2-2 2H20.1c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2h23.8c1.1 0 2 .9 2 2z" />
    <path d="M28 15.6h8c.6 0 1-.4 1-1s-.4-1-1-1h-8c-.6 0-1 .4-1 1s.5 1 1 1M28 26.6h8c.6 0 1-.4 1-1s-.4-1-1-1h-8c-.6 0-1 .4-1 1s.5 1 1 1M37.4 42.7c-.5.1-.9.7-.7 1.2.1.4.1.8.1 1.1 0 2.6-2.2 4.8-4.8 4.8s-4.8-2.2-4.8-4.8c0-.4.1-.9.2-1.3.2-.5-.2-1.1-.7-1.2-.5-.2-1.1.2-1.2.7-.2.6-.3 1.2-.3 1.9 0 3.7 3 6.8 6.8 6.8s6.8-3 6.8-6.8c0-.5-.1-1.1-.2-1.6-.1-.6-.7-.9-1.2-.8" />
    <path d="M31 35.5V43c0 .6.4 1 1 1s1-.4 1-1v-7.5c0-.6-.4-1-1-1s-1 .4-1 1" />
  </svg>
);
export default IconCpuAlt;
