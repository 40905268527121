import * as React from "react";
const IconConeTestOnNets = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M19.867 8.403a4 4 0 0 0-.63-.046c-2.26 0-4.09 1.759-4.09 3.929 0 1.64 1.046 3.045 2.533 3.633l-5.54 18.469q-.139.3-.14.612c0 2.21 5.373 4 12 4s12-1.79 12-4q-.001-.313-.14-.612l-5.512-18.374c1.626-.52 2.798-1.993 2.798-3.728 0-2.17-1.831-3.929-4.09-3.929q-.322 0-.63.046C27.584 6.97 25.983 6 24.146 6c-1.836 0-3.438.969-4.28 2.403m.376 5.694.6.357c.368.217 1.559.546 3.303.546 1.745 0 2.936-.329 3.303-.546l.6-.357.693.096q.152.021.313.021c1.232 0 2.091-.939 2.091-1.928 0-.99-.859-1.929-2.09-1.929q-.174 0-.337.025l-1.335.198-.683-1.165C26.218 8.591 25.272 8 24.146 8c-1.125 0-2.07.591-2.554 1.415l-.684 1.165-1.335-.198a2 2 0 0 0-.336-.025c-1.232 0-2.09.939-2.09 1.929s.858 1.928 2.09 1.928q.162 0 .314-.021zM24 19c-2.274 0-3.796-1.35-3.981-2.72.874.433 2.396.72 4.127.72 1.539 0 2.912-.227 3.812-.581C27.692 17.74 26.197 19 24 19m-9.183 13.425C17.017 31.554 20.315 31 24 31s6.982.554 9.184 1.425L29.066 18.7C27.963 20.129 26.022 21 24 21s-3.963-.872-5.066-2.301zm17.036 1.644c.953.318 1.588.653 1.948.931-.36.278-.995.613-1.948.931C29.935 36.571 27.159 37 24 37s-5.935-.43-7.853-1.069c-.953-.318-1.588-.653-1.948-.931.36-.278.995-.613 1.948-.931C18.065 33.429 20.841 33 24 33s5.935.43 7.853 1.069"
      clipRule="evenodd"
    />
    <path
      
      d="M39 37a1 1 0 1 1-2 0 1 1 0 0 1 2 0M39 31a1 1 0 1 0 0-2 1 1 0 0 0 0 2M27 41a1 1 0 1 1-2 0 1 1 0 0 1 2 0M41 39a1 1 0 1 1-2 0 1 1 0 0 1 2 0M12 40a1 1 0 1 1 0-2 1 1 0 0 1 0 2M7 39a1 1 0 1 0 2 0 1 1 0 0 0-2 0M41 33a1 1 0 1 0 0-2 1 1 0 0 0 0 2M8 30a1 1 0 1 0 2 0 1 1 0 0 0-2 0M34 42a1 1 0 1 0 0-2 1 1 0 0 0 0 2M17 41a1 1 0 1 0 2 0 1 1 0 0 0-2 0M38 42a1 1 0 1 0 0-2 1 1 0 0 0 0 2M13 41a1 1 0 1 0 2 0 1 1 0 0 0-2 0M10 42a1 1 0 1 1 0-2 1 1 0 0 1 0 2M42 36a1 1 0 1 1-2 0 1 1 0 0 1 2 0M10 38a1 1 0 1 1 0-2 1 1 0 0 1 0 2M8 34a1 1 0 1 0 2 0 1 1 0 0 0-2 0M7 33a1 1 0 1 1 0-2 1 1 0 0 1 0 2M6 36a1 1 0 1 0 2 0 1 1 0 0 0-2 0M36 40a1 1 0 1 0 0-2 1 1 0 0 0 0 2M31 41a1 1 0 1 1-2 0 1 1 0 0 1 2 0M22 42a1 1 0 1 1 0-2 1 1 0 0 1 0 2M40 34a1 1 0 1 1-2 0 1 1 0 0 1 2 0"
    />
  </svg>
);
export default IconConeTestOnNets;
