import * as React from "react";
const IconCornerDoubleLeftDown = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.7 18.6 47.3 5.2c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l11.7 11.7H23.2c-3.7 0-6.8 3-6.8 6.8v30.6L4.7 44c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l13.4 13.4c.2.2.4.3.7.3s.5-.1.7-.3l13.4-13.4c.4-.4.4-1 0-1.4s-1-.4-1.4 0L18.4 55.7V25.1c0-2.6 2.2-4.8 4.8-4.8h34.4L45.9 31.9c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3L60.7 20c.2-.2.3-.4.3-.7s-.1-.6-.3-.7" />
  </svg>
);
export default IconCornerDoubleLeftDown;
