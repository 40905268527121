import * as React from "react";
const IconBone = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.4 11.6c-1.3-1.6-3.1-2.7-5.1-2.9-.3-2.7-2-5-4.6-6.1-2.9-1.2-6-.6-8.2 1.6-1.7 1.7-2 3.6-2.2 5.3-.3 2.2-.6 4.5-3.7 7.6L17.1 36.5c-2.3 2.3-5.1 3.7-7.9 3.8-4 .1-7.2 3.5-7.2 7.6 0 2 .8 3.9 2.2 5.4 1.2 1.2 2.7 1.9 4.4 2.1.2 1.7 1 3.2 2.2 4.4 1.4 1.4 3.3 2.2 5.4 2.2 1.7 0 3.3-.6 4.6-1.6 2.1-1.6 3.2-4.2 2.9-6.8-.3-2.9 1.1-4.4 8.1-11 4-3.8 9.4-9 16.9-16.9 1.4-1.4 3.2-2.1 4.9-1.9 3 .3 5.9-1.1 7.4-3.8 1.5-2.8 1.3-6-.6-8.4m-1.1 7.3c-1.1 2-3.2 3.1-5.4 2.8-2.3-.2-4.7.7-6.5 2.5-7.5 7.9-12.9 13.1-16.9 16.9-7 6.7-9.1 8.7-8.7 12.7.2 1.9-.6 3.8-2.1 5-.9.7-2.2 1.2-3.4 1.2-1.5 0-2.9-.6-3.9-1.6s-1.6-2.5-1.6-3.9c0-.5-.3-.9-.8-1h-.3c-1.9 0-3.1-.9-3.9-1.6-1.2-1.2-1.8-2.6-1.8-4 0-3 2.3-5.5 5.3-5.6 3.2-.1 6.5-1.7 9.2-4.4l19.4-19.4c3.6-3.6 4-6.4 4.3-8.7.2-1.6.4-2.9 1.7-4.2C45.1 4.4 46.4 4 47.7 4c.8 0 1.6.2 2.2.4 1.7.7 3.5 2.4 3.5 5.2 0 .3.1.5.3.7s.5.3.7.3c1.7 0 3.4.8 4.4 2.2 1.4 1.8 1.6 4.1.5 6.1" />
  </svg>
);
export default IconBone;
