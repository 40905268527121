import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import io from 'socket.io-client';
import { useAuth } from './AuthContext';

const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);
    const { user } = useAuth();
    const [error, setError] = useState(null);

    useEffect(() => {
        if (user) {
            try {
                const newSocket = io(process.env.REACT_APP_SOCKET_URL, {
                    query: { userId: user.id },
                    transports: ['websocket'], // fallback to websocket to avoid long-polling issues
                    reconnection: true,
                    reconnectionAttempts: 5,
                    reconnectionDelay: 2000,
                });

                newSocket.on('connect_error', (err) => {
                    console.error('Socket connection error:', err);
                    setError('Connection error, please try again.');
                });

                newSocket.on('disconnect', (reason) => {
                    if (reason === 'io server disconnect') {
                        newSocket.connect();
                    } else {
                        console.warn('Socket disconnected:', reason);
                    }
                });

                setSocket(newSocket);

                return () => {
                    newSocket.close();
                };
            } catch (err) {
                console.error('Error while initializing socket:', err);
                setError('Failed to connect to socket.');
            }
        } else {
            setSocket(null); // Reset socket if user is null
        }
    }, [user]);

    const sendMessage = (message) => {
        if (socket) {
            try {
                socket.emit('sendMessage', message);
            } catch (err) {
                console.error('Error while sending message:', err);
                setError('Failed to send message.');
            }
        } else {
            setError('No active socket connection.');
        }
    };

    const onRefetchData = useCallback((callback) => {
        if (socket) {
            try {
                socket.on('refetchData', callback);
                return () => socket.off('refetchData', callback);
            } catch (err) {
                console.error('Error while handling refetchData:', err);
                setError('Failed to set up refetch data listener.');
            }
        }
    }, [socket]);

    const value = {
        socket,
        sendMessage,
        onRefetchData,
        error, // expose error state for handling in components
    };

    return (
        <SocketContext.Provider value={value}>
            {children}
            {error && <div className="socket-error">{error}</div>}
        </SocketContext.Provider>
    );
};
