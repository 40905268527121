import * as React from "react";
const IconGy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 900 600"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 0h900v600H0z" />
    <path  d="m0 0 947 300L0 600z" />
    <path  d="M0 26.1 870 300 0 573.9z" />
    <path  d="m0 0 450 300L0 600z" />
    <path  d="m0 35 397.5 265L0 565z" />
  </svg>
);
export default IconGy;
