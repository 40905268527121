import * as React from "react";
const IconCloudySunCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M44.9 35.9c2.3-1.9 3.7-4.7 3.7-7.8 0-5.6-4.5-10.1-10.1-10.1-4.8 0-8.9 3.5-9.9 8-1-.2-2-.4-3.2-.4-7.4 0-13.4 5.7-13.5 12.7-5.5.3-9.9 4.8-9.9 10.1C2 54 6.7 58.5 12.5 58.6h22.2c7.8 0 14.1-6 14.1-13.4 0-3.4-1.3-6.6-3.8-9.1.1-.1 0-.1-.1-.2M38.5 20c4.5 0 8.1 3.6 8.1 8.1 0 2.6-1.2 5-3.3 6.5-1.9-1.4-4.1-2.3-6.5-2.6-1.5-2.4-3.8-4.3-6.4-5.3.8-3.9 4.2-6.7 8.1-6.7m-3.7 36.6H12.6C7.8 56.6 4 53 4 48.4s3.8-8.1 8.6-8.1h.4c.6 0 1-.4 1-1v-.8c0-6 5.1-10.9 11.5-10.9 1.4 0 2.6.2 3.7.6 2.7.9 4.9 2.7 6.3 5.1.2.3.4.5.8.5 2.4.2 4.7 1.2 6.4 2.7h.1l.9.9c2.1 2.1 3.2 4.9 3.2 7.7 0 6.4-5.5 11.5-12.1 11.5M39.7 11.6c.6 0 1-.4 1-1V6.4c0-.6-.4-1-1-1s-1 .4-1 1v4.2c0 .6.4 1 1 1M53.8 12l-2.7 2.6c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3l2.7-2.6c.4-.4.4-1 0-1.4-.4-.3-1-.3-1.4 0M61 27.9h-4.3c-.6 0-1 .4-1 1s.4 1 1 1H61c.6 0 1-.4 1-1s-.4-1-1-1M25.9 16.4c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-3-3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4z" />
  </svg>
);
export default IconCloudySunCopy;
