import * as React from "react";
const IconClouds = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62.5 27.7c0-4.4-1.7-8.7-4.8-11.9-2.7-2.9-6.5-4.9-10.6-5.4C45 6.9 41.8 4.2 38 2.9c-1.6-.6-3.5-.9-5.5-.9C23 2 15.4 9.5 15.4 18.8v.1c-7.2.2-13 6.1-13 13.2 0 2.5.7 4.9 2.1 7-1.9 2.5-3 5.5-3 8.6C1.5 55.6 8 62 16.1 62h23.1c6 0 10.9-4.8 10.9-10.8 0-2.4-.8-4.5-2.1-6.3 8.4-1.5 14.5-8.6 14.5-17.2M39.1 60h-23C9.2 60 3.5 54.5 3.5 47.8c0-3.1 1.2-6 3.3-8.3 2-2.1 4.8-3.5 7.8-3.8.3 0 .6-.2.8-.5 1.5-2.6 3.8-4.6 6.6-5.5 1.1-.4 2.4-.6 3.8-.6 6.6 0 11.9 5.2 11.9 11.7v.8c0 .6.4 1 1 1h.4c5 0 9 3.8 9 8.8 0 4.8-4 8.6-9 8.6m7.2-16.9c-1.8-1.5-4.1-2.5-6.6-2.6-.1-7.5-6.3-13.6-13.9-13.6-1.7 0-3.2.3-4.5.8-3.1 1-5.7 3.2-7.4 6-3 .4-5.9 1.8-8 3.9-1-1.7-1.5-3.5-1.5-5.5 0-6.3 5-11.2 11.4-11.2h.5c.6 0 1-.4 1-1v-1.1C17.4 10.7 24.1 4 32.5 4c1.8 0 3.4.3 4.9.8 3.5 1.2 6.5 3.7 8.3 7 .2.3.4.5.8.5 3.8.4 7.3 2.2 9.8 4.8 2.7 2.9 4.2 6.7 4.2 10.6 0 8-6.1 14.6-14.2 15.4" />
  </svg>
);
export default IconClouds;
