import * as React from "react";
const IconPear = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M46.4 31.3c-.3-.4-.6-.7-.9-1-.8-.9-1.5-1.7-1.9-2.7-.7-1.5-.6-3.2-.6-5 0-1.2 0-2.5-.2-3.8-.7-4.5-4.7-8.3-9.2-9 .3-.8.6-1.5 1.1-2.2 1-1.4 2.4-2 4.5-2.4.5-.1.9-.7.8-1.2s-.7-.9-1.2-.8c-2 .4-4.2 1.1-5.7 3.3-.7 1-1.1 2.2-1.5 3.2-.7 0-1.5.1-2.2.3-4.2 1.2-7.5 4.8-8.1 9-.2 1.2-.1 2.4-.1 3.6 0 1.6.1 3.1-.4 4.5-.5 1.3-1.4 2.5-2.4 3.7-.4.5-.8 1-1.2 1.6-2.5 3.4-3.7 7.8-3.4 12 .6 9.2 8.7 16.6 18.3 16.6 1.3 0 2.5-.1 3.7-.4 6.1-1.3 11.3-5.9 13.4-11.8 2.2-5.8 1-12.7-2.8-17.5m1 16.9c-1.9 5.2-6.5 9.3-11.9 10.5q-1.65.3-3.3.3c-8.6 0-15.8-6.6-16.4-15-.3-3.7.8-7.6 3-10.6l1.2-1.5c1.1-1.3 2.2-2.6 2.7-4.3.6-1.7.5-3.5.5-5.2 0-1.1-.1-2.2.1-3.2.5-3.4 3.2-6.4 6.6-7.3q1.05-.3 2.1-.3h.1c4.2 0 8.1 3.3 8.8 7.5.2 1.1.2 2.2.1 3.5 0 2-.1 4 .8 5.9.6 1.2 1.4 2.2 2.2 3.2.3.3.6.7.8 1 3.5 4.1 4.5 10.3 2.6 15.5" />
  </svg>
);
export default IconPear;
