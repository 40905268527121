import * as React from "react";
const IconChefHatAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.6 15.4c-2 0-4 .5-5.7 1.6v-.2c0-7.4-5.8-13.4-13-13.4s-13 6-13 13.4v.1c-1.7-.9-3.5-1.4-5.4-1.4C7.1 15.4 2 20.7 2 27.2 2 33.3 6.6 38.4 12.4 39v16.6c0 2.9 2.3 5.2 5.2 5.2h28.7c2.9 0 5.2-2.3 5.2-5.2V39c5.9-.6 10.5-5.7 10.5-11.8 0-6.5-5.1-11.8-11.4-11.8m-4.3 43.3H17.7c-1.8 0-3.2-1.4-3.2-3.2v-5.2h35.1v5.2c0 1.8-1.5 3.2-3.3 3.2M50.6 37c-.6 0-1 .4-1 1v10.4h-8.2l1.7-7.1c.1-.5-.2-1.1-.7-1.2s-1.1.2-1.2.7l-1.8 7.6H33V40c0-.6-.4-1-1-1s-1 .4-1 1v8.4h-6.3l-1.8-7.6c-.1-.5-.7-.9-1.2-.7-.5.1-.9.7-.7 1.2l1.7 7.1h-8.2V38c0-.6-.4-1-1-1C8.2 37 4 32.6 4 27.2s4.2-9.8 9.4-9.8c2.2 0 4.2.8 5.9 2.2.3.3.8.3 1.1.1.4-.2.6-.6.5-1-.1-.6-.2-1.2-.2-1.9 0-6.3 4.9-11.4 11-11.4s11 5.1 11 11.4c0 .8-.1 1.5-.2 2.2-.1.4.1.9.5 1.1s.8.2 1.2-.1c1.7-1.6 3.9-2.5 6.2-2.5 5.2 0 9.4 4.4 9.4 9.8.2 5.3-4 9.7-9.2 9.7" />
  </svg>
);
export default IconChefHatAlt1;
