import * as React from "react";
const IconBridgeAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 33h-2l-8.6-15c0-.1-.1-.1-.1-.2-.2-.2-.4-.3-.7-.3H14c-.3 0-.6.1-.7.3 0 0-.1.1-.1.2L4.5 33H3c-.6 0-1 .4-1 1s.4 1 1 1h11.3v9.6h-2.6c-.6 0-1 .4-1 1s.4 1 1 1h7.1c.6 0 1-.4 1-1s-.4-1-1-1h-2.6V35h31.3v9.6h-2.6c-.6 0-1 .4-1 1s.4 1 1 1H52c.6 0 1-.4 1-1s-.4-1-1-1h-2.6V35H61c.6 0 1-.4 1-1s-.4-1-1-1m-18.6 0 7.2-12.5L56.7 33zM31.8 20.5 38.9 33H24.6zM22.9 32l-7.2-12.5H30zm10.6-12.5h14.3L40.7 32zm-19.5 1L21.2 33H6.8z" />
  </svg>
);
export default IconBridgeAlt;
