import * as React from "react";
const IconRupeeCard = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.3 43.5h-4.1c-.1-1.9-.7-3.6-1.8-4.8h5.7c.6 0 1-.4 1-1s-.4-1-1-1H40.8c-.6 0-1 .4-1 1s.4 1 1 1h6.4c2.5 0 3.9 2.3 4 4.8H41c-.6 0-1 .4-1 1s.4 1 1 1h9.9c-1.1 2-4.2 3.4-6.4 3.4-.4 0-.8.2-.9.6-.2.4-.1.8.2 1.1l8.2 9.2c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 .1-1.4l-6.9-7.8c2.7-.7 5.7-2.5 6.6-5.1h4.3c.6 0 1-.4 1-1s-.6-1-1.2-1" />
    <path d="M55.2 3.9H8.8C5.1 3.9 2 6.8 2 10.2v29c0 3.5 3.1 6.3 6.8 6.3h23.1c.6 0 1-.4 1-1s-.4-1-1-1H8.8c-2.6 0-4.8-1.9-4.8-4.3V25.1h56v8.7c0 .6.4 1 1 1s1-.4 1-1V10.2c0-3.4-3.1-6.3-6.8-6.3m-46.4 2h46.4c2.6 0 4.8 1.9 4.8 4.3v4.3H4v-4.3c0-2.3 2.2-4.3 4.8-4.3M4 23.1v-6.6h56v6.6z" />
  </svg>
);
export default IconRupeeCard;
