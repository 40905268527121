import * as React from "react";
const IconEarthquake = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 65 65"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.6 25 35.8 5.4c-1.9-1.6-4.7-1.6-6.6 0L5.4 25c-1.2 1-1.9 2.5-1.9 4v26.5c0 2.9 2.3 5.2 5.2 5.2h47.6c2.9 0 5.2-2.3 5.2-5.2V29c0-1.5-.7-3-1.9-4m-.1 30.6c0 1.8-1.4 3.2-3.2 3.2H32.2l3.2-4.5c.2-.3.2-.8 0-1.2l-3.8-5.3 3.8-5.3c.2-.3.2-.8 0-1.2L31.6 36l3.8-5.3c.3-.4.2-1.1-.2-1.4-.5-.3-1.1-.2-1.4.2l-4.2 5.9c-.2.3-.2.8 0 1.2l3.8 5.3-3.8 5.3c-.2.3-.2.8 0 1.2l3.8 5.3-3.7 5.1h-21c-1.8 0-3.2-1.4-3.2-3.2V29c0-1 .4-1.9 1.2-2.5L30.5 6.9c.6-.5 1.3-.7 2-.7s1.5.2 2 .7l23.8 19.6c.7.6 1.2 1.5 1.2 2.5z" />
  </svg>
);
export default IconEarthquake;
