import * as React from "react";
const IconWeddingCake = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.2 45.5v-11c0-2.2-1.8-4-4-4h-.5V19.4c0-2.2-1.8-4-4-4H33.5l4.5-4.5c1-1 1.5-2.3 1.5-3.7S39 4.5 38 3.5c-1.6-1.6-4.1-2-6.1-.9-.7-.4-1.5-.6-2.3-.6h-.1c-1.3 0-2.5.5-3.5 1.4s-1.6 2.2-1.6 3.7.6 3 1.6 4l4.3 4.3H13.2c-2.2 0-4 1.8-4 4v11.1h-.5c-2.2 0-4 1.8-4 4v11c-1.5.6-2.6 2-2.6 3.7V58c0 2.2 1.8 4 4 4h51.7c2.2 0 4-1.8 4-4v-8.8c.1-1.7-1-3.1-2.6-3.7M26.4 7.1c0-.9.4-1.7 1-2.2.6-.6 1.4-.9 2.2-.9.6 0 1.3.2 1.8.6.3.2.8.2 1.1 0 1.3-.9 3-.7 4.1.4.6.6.9 1.4.9 2.3s-.3 1.7-.9 2.3L32 14.1h-.1l-4.4-4.4c-.7-.7-1.1-1.7-1.1-2.6M13.2 17.4h37.6c1.1 0 2 .9 2 2v2l-2.7 1.3-2.6.9c-2.5.6-4.4-.1-4.5-.1h-.1l-1.8-.5c-3.4-.7-6.3.4-6.4.4h-.1s-.4.2-1.8.4c-1.3.2-2.7-.2-3-.3-.3-.2-1.4-.5-3.6-.7-2.7-.3-5.3.7-5.4.7 0 0-1.3.5-3.5.2-1.8-.2-4.9-1.7-6.1-2.3v-1.9c0-1.2.9-2.1 2-2.1m-2 6.2c1.6.7 4.1 1.8 5.8 2 2.7.4 4.4-.3 4.5-.3 0 0 2.3-.9 4.5-.6 2.2.2 2.9.6 2.9.6.1 0 .1.1.2.1s1.5.4 3 .4c.3 0 .6 0 .9-.1 1.4-.2 2.1-.4 2.4-.5s2.7-.9 5.2-.3l1.7.4c.4.2 2.7.9 5.7.2l2.8-.9h.1l1.9-.9v6.9H11.2zM6.7 34.5c0-1.1.9-2 2-2h46.5c1.1 0 2 .9 2 2v10.8H6.7zM59.9 58c0 1.1-.9 2-2 2H6.1c-1.1 0-2-.9-2-2v-8.8c0-1.1.9-2 2-2h51.7c1.1 0 2 .9 2 2V58z" />
  </svg>
);
export default IconWeddingCake;
