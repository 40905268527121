import * as React from "react";
const IconBackpackAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M49.5 10.7h-7.9V9c0-3.9-3.1-7-7-7h-5.3c-3.9 0-7 3.1-7 7v1.7h-7.9c-4.4 0-8 3.6-8 8V28c0 1.7 1.3 3 3 3h.4v24c0 3.9 3.1 7 7 7h30.3c3.9 0 7-3.1 7-7V30.9h.4c1.7 0 3-1.3 3-3v-9.3c0-4.3-3.5-7.9-8-7.9M24.3 9c0-2.8 2.2-5 5-5h5.3c2.8 0 5 2.2 5 5v1.7H24.3zm22.9 51H16.8c-2.8 0-5-2.2-5-5v-3.8h40.4V55c0 2.8-2.3 5-5 5m-6.1-10.8H22.9v-3c0-1.7 1.3-3 3-3h12.2c1.7 0 3 1.3 3 3zm11.1 0h-9.1v-3c0-2.8-2.2-5-5-5H25.9c-2.8 0-5 2.2-5 5v3h-9.1V30.9h40.3v18.3zm3.3-21.3c0 .6-.4 1-1 1h-45c-.6 0-1-.4-1-1v-9.3c0-3.3 2.7-6 6-6h35.1c3.3 0 6 2.7 6 6v9.3z" />
    <path d="M37.8 20.3H26.2c-.6 0-1 .4-1 1s.4 1 1 1h11.5c.6 0 1-.4 1-1s-.4-1-.9-1" />
  </svg>
);
export default IconBackpackAlt;
