import * as React from "react";
const IconChartBarStacked = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M12 24a1 1 0 0 0-1 1v15H8V7H6v34a1 1 0 0 0 1 1h34v-2h-2V13a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v27h-2V21a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v19h-2V25a1 1 0 0 0-1-1zm21 16h4V26h-4zm4-16h-4V14h4zm-14 6v10h4V30zm4-2h-4v-6h4zm-14 6v6h4v-6zm4-2h-4v-6h4z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconChartBarStacked;
