import * as React from "react";
const IconTargetCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 31h-7.3C53.2 19.4 44.2 10.1 33 9.6V3c0-.6-.4-1-1-1s-1 .4-1 1v6.6c-11.2.5-20.2 9.8-20.7 21.4H3c-.6 0-1 .4-1 1s.4 1 1 1h7.3c.5 11.6 9.5 20.9 20.7 21.4V61c0 .6.4 1 1 1s1-.4 1-1v-6.6c11.2-.5 20.2-9.8 20.7-21.4H61c.6 0 1-.4 1-1s-.4-1-1-1m-9.3 0h-9c-.5-5.3-4.5-9.5-9.7-10v-9.4c10.1.6 18.2 8.9 18.7 19.4M32 41.1c-4.8 0-8.7-4.1-8.7-9.1s3.9-9 8.7-9.1c4.8 0 8.7 4.1 8.7 9.1s-3.9 9.1-8.7 9.1m-1-29.5V21c-5.1.5-9.2 4.7-9.7 10h-9c.5-10.5 8.6-18.8 18.7-19.4M12.3 33h9c.5 5.3 4.5 9.5 9.7 10v9.4c-10.1-.6-18.2-8.9-18.7-19.4M33 52.4V43c5.1-.5 9.2-4.7 9.7-10h9c-.5 10.5-8.6 18.8-18.7 19.4" />
  </svg>
);
export default IconTargetCopy;
