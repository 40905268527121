import * as React from "react";
const IconStarDollar = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.4 30.6c1.5-1.7 2-3.9 1.3-6.1-.7-2.1-2.5-3.7-4.7-4.1l-11.4-2.1c-1.2-.2-2.3-1-2.9-2.1l-5.4-10c-1.1-2-3.1-3.2-5.4-3.2s-4.3 1.2-5.4 3.2l-5.4 10c-.6 1.1-1.6 1.9-2.9 2.1L7 20.5c-2.2.4-4 1.9-4.7 4.1-.7 2.1-.2 4.4 1.3 6.1l7.8 8.4c.8.9 1.2 2.1 1.1 3.3l-1.4 11.8c-.3 2.2.6 4.3 2.4 5.6 1.7 1.3 4 1.6 6 .8l11-4.5c1-.4 2.1-.4 3.1 0l11 4.5c.8.3 1.5.5 2.3.5 1.3 0 2.6-.4 3.7-1.3 1.7-1.3 2.6-3.4 2.4-5.6l-1.4-11.8c-.1-1.2.2-2.4 1.1-3.3zm-9.3 7.1c-1.2 1.3-1.8 3.1-1.6 4.9l1.4 11.8c.2 1.5-.4 2.9-1.6 3.7-1.2.9-2.7 1.1-4 .5l-11-4.5c-.7-.3-1.5-.5-2.3-.5s-1.6.2-2.3.5l-11 4.5c-1.4.6-2.9.4-4-.5-1.2-.9-1.8-2.3-1.6-3.7l1.4-11.8c.2-1.8-.4-3.6-1.6-4.9l-7.8-8.4c-1-1.1-1.4-2.6-.9-4.1.5-1.4 1.7-2.5 3.2-2.7l11.4-2.1c1.8-.3 3.4-1.5 4.3-3.1l5.4-10c.7-1.4 2.1-2.2 3.6-2.2s2.9.8 3.6 2.2l5.4 10c.9 1.6 2.4 2.8 4.3 3.1l11.4 2.1c1.5.3 2.7 1.3 3.2 2.7s.1 3-.9 4.1z" />
    <path d="M30.3 26.6h7.6c.6 0 1-.4 1-1s-.4-1-1-1h-3.7v-2.5c0-.6-.4-1-1-1s-1 .4-1 1v2.5h-1.9c-2.8 0-5.1 2.3-5.1 5 0 2.8 2.3 5 5.1 5h3.5c1.7 0 3.1 1.4 3.1 3s-1.4 3-3.1 3h-7.6c-.6 0-1 .4-1 1s.4 1 1 1h6v2.5c0 .6.4 1 1 1s1-.4 1-1v-2.5c2.6-.2 4.7-2.4 4.7-5 0-2.8-2.3-5-5.1-5h-3.5c-1.7 0-3.1-1.4-3.1-3s1.4-3 3.1-3" />
  </svg>
);
export default IconStarDollar;
