import React, { useState, useEffect } from 'react';
import { BringzzButton, BringzzText } from "@bringzz/components";
import { useNavigation } from 'context/NavigationContext';
import {
	BringzzInput, BringzzPageContainer,
	BringzzIcon
} from "@bringzz/components";
import { useLocation } from 'react-router-dom';
import { useAuth } from 'context/AuthContext';
import useUser from 'hooks/useUser';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}
const methodTitle = {
	phone: 'Phone number',
	email: 'Email address'
};
const LoginPage = () => {
	const { backPress } = useNavigation();
	const { navigate } = useNavigation();
	const { login } = useAuth();
	const { getUser } = useUser()
	const user = getUser()

	const [show, setShow] = useState(false);
	const [inputDisabled, setInputDisabled] = useState(true);
	const [inputValue, setInputValue] = useState('');
	const [password, setPassword] = useState('');
	const [isValidInput, setIsValidInput] = useState(false);
	const [localLoading, setLocalLoading] = useState(false);

	let query = useQuery();
	let method = query.get('method');
	let methodValue = query.get('value');
	let { id, firstName, lastSignupStep } = user

	useEffect(() => { setInputValue(methodValue) }, [methodValue]);

	const handleLogin = async () => {
		const credentials = {
			id,
			username: inputValue,
			password: password
		};
		setLocalLoading(true)
		const success = await login(credentials);
		setLocalLoading(false)
		if (success) {
			// if (lastSignupStep == "validation") {
			// 	navigate(`/register/otp?method=${method}&userId=${id}&value=${methodValue}&redirect=/register/consumer/profile`, false)
			// } else if (lastSignupStep == "profile") {
			// 	navigate(`/register/consumer/profile?method=${method}&userId=${id}&value=${methodValue}`);
			// } else if (lastSignupStep == "picture") {
			// 	navigate(`/register/consumer/picture?userId=${id}`);
			// } else {
			navigate('/home');
			// }
		} else {
			alert('Login failed. Please check your credentials and try again.');
		}
	};

	return (
		<BringzzPageContainer
			className='bg-white'
			header={{
				left: (
					<BringzzIcon
						size='22'
						icon='IconChevronLeft'
						folder='LineIcons'
						className='cursor-pointer'
						onClick={backPress}
					/>
				),
				title: <BringzzText tag='h3'>{'Sign In'}</BringzzText>
			}}
			authenticated={false}
			footer={
				<div className='pb-8 px-6'>
					<BringzzButton
						size='lg'
						className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
						onClick={handleLogin}
						disabled={!isValidInput || localLoading}
					>
						Log in
					</BringzzButton>
				</div>
			}
		>
			<div className='flex justify-center h-full w-full'>
				<div className='relative bg-white w-full bg-cover bg-center py-8 px-4 flex flex-col'>
					<div className='z-10 flex flex-col '>
						<div className='pl-2 flex flex-col space-y-6 w-full'>
							<BringzzText tag='h1' className='font-bold text-midnight-blue'>
								{firstName ? `Welcome back, ${firstName}!` : `Welcome back!`}
							</BringzzText>
						</div>
						<div className='mt-8'>
							<BringzzInput
								inputProps={{
									disabled: inputDisabled
								}}
								icon={{
									right: (
										<BringzzText
											className='font-thin text-black underline cursor-pointer'
											onClick={backPress}
										>
											edit
										</BringzzText>
									)
								}}
								type='text'
								className="!bg-white"
								defaultValue={(method == "phone" ? "+" : "") + methodValue}
								label={methodTitle[method]}
							/>
						</div>
						<div className='mt-6'>
							<BringzzInput
								icon={{
									right: (
										<BringzzIcon
											icon={show ? 'IconEye' : 'IconEyeSlash'}
											folder='LineIcons'
											className='cursor-pointer'
											size='16'
											onClick={() => setShow(!show)}
										/>
									)
								}}
								type={show ? "text" : "password"}
								label={'Password'}
								pattern="^.{9,}$"
								onChange={(e, isValid, newValue) => {
									setIsValidInput(isValid);
									setPassword(newValue);
								}}
							/>
						</div>
						<div className='flex items-center gap-x-4 mt-2'>
							<BringzzText
								tag='h4'
								className='text-black underline cursor-pointer'
								onClick={() =>
									navigate(
										`/login/otp?method=${method}&value=${inputValue}&redirect=/reset-password`,
										false
									)
								}
							>
								Forgot your password?
							</BringzzText>
						</div>
					</div>
				</div>
			</div>
		</BringzzPageContainer>
	);
};

export default LoginPage;
