import * as React from "react";
const IconComb1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.7 60.3 45.2 43.8c1.5-1.8 2.3-4.1 2.3-6.5 0-2.7-1.1-5.3-3-7.2L41 26.6l5.3-5.3c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-5.3 5.3-7.4-7.4 5.3-5.3c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-5.3 5.3L23.4 9l5.3-5.3c.4-.4.4-1 0-1.4s-1-.4-1.4 0L22 7.6c-4-3.7-10.3-3.7-14.2.2S3.9 18 7.6 22l-5.3 5.3c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3L9 23.4l7.4 7.4-5.3 5.2c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l5.3-5.3 7.4 7.4-5.3 5.3c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l5.3-5.3 3.6 3.6c1.9 1.9 4.5 3 7.2 3 2.4 0 4.7-.8 6.5-2.3l16.5 16.5c.2.2.5.3.7.3s.5-.1.7-.3c.3-.4.3-1-.1-1.4M31.5 43.1 9.7 21.3l-.4-.4C6 17.6 6 12.4 9.2 9.2c1.6-1.6 3.7-2.4 5.8-2.4s4.2.8 5.8 2.4l.4.4 21.9 21.9c1.5 1.5 2.4 3.6 2.4 5.8s-.9 4.2-2.4 5.8-3.6 2.4-5.8 2.4c-2.1 0-4.2-.8-5.8-2.4" />
  </svg>
);
export default IconComb1;
