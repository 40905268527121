import * as React from "react";
const IconICertificatePaper = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      d="M18 11a1 1 0 0 1 1-1h10a1 1 0 1 1 0 2H19a1 1 0 0 1-1-1M15 16a1 1 0 1 0 0 2h18a1 1 0 1 0 0-2zM14 21a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H15a1 1 0 0 1-1-1M15 24a1 1 0 1 0 0 2h18a1 1 0 1 0 0-2z"
    />
    <path
      
      fillRule="evenodd"
      d="M38 36a4 4 0 0 1-4 4h-3v4l-3-1.5-3 1.5v-4H14a4 4 0 0 1-4-4V8a4 4 0 0 1 4-4h20a4 4 0 0 1 4 4zM14 6a2 2 0 0 0-2 2v28a2 2 0 0 0 2 2h11v-2.354a4 4 0 1 1 6 0V38h3a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2zm15 30.874a4 4 0 0 1-2 0v3.89l1-.5 1 .5zM28 35a2 2 0 1 0 0-4 2 2 0 0 0 0 4"
      clipRule="evenodd"
    />
  </svg>
);
export default IconICertificatePaper;
