import React, { useState } from 'react';
import { useNavigation } from '../../context/NavigationContext';
import { BringzzIcon, BringzzRadio, BringzzRadioList, BringzzBottomDrawer } from "@bringzz/components";
import classnames from 'classnames';
import './navbar.css';
import { useAuth } from 'context/AuthContext';
import { returnMenuList, handleMenuNavigation } from 'utils';

const state = {
	checked: <div></div>,
	unChecked: <div></div>
};

const Navbar = () => {
	const { navigate, isActive } = useNavigation();
	const { user } = useAuth()
	if (!user) return null
	const [pickerDrawerOpened, setPickerDrawerOpened] = useState(null);

	const handlePickerClick = async choice => {
		if (choice == "external_link") {
			navigate('/posts/add/external-link')
		} else if (choice == "product_post") {
			navigate('/posts/add/product-post')
		} else if (choice == "article_post") {
			navigate('/posts/add/article')
		}
	}

	return (
		<div className='fixed text-sm bottom-0 left-0 pb-4 w-full bg-white flex justify-around items-center h-24 shadow-top z-30'>
			{returnMenuList(user.userRole).map((item, index) => {
				const active = isActive(item.url);

				return (
					<div
						key={index}
						onClick={() => handleMenuNavigation(item, navigate, setPickerDrawerOpened)}
						className={classnames(
							'flex flex-col items-center cursor-pointer',
							active ? 'text-black' : 'text-slate-500'
						)}
					>
						<BringzzIcon icon={item.icon} folder={item.folder} size='24' />
						{active ? (
							<span className='text-magic-lilac text-[7px] h-2'>&#9679;</span>
						) : (
							<span className='text-magic-lilac text-[7px] h-2'></span>
						)}
					</div>
				);
			})}

			<BringzzBottomDrawer
				isOpen={pickerDrawerOpened}
				title={'Select post type'}
				close={() => setPickerDrawerOpened(false)}
			>
				<div className='px-4 py-6'>
					<BringzzRadioList
						state={state}
						className='rounded-xl w-full text-center'
						onChange={choice => {
							handlePickerClick(choice);
							setPickerDrawerOpened(false);
						}}
					>
						<BringzzRadio
							value='external_link'
							className='p-2 py-4 hover:bg-slate- border-1 border-t border-gray-400 flex'
						>
							<div>External Link</div>
						</BringzzRadio>
						<BringzzRadio
							value='product_post'
							className='p-2 py-4 hover:bg-slate- border-1 border-t border-gray-400'
						>
							<div>Product Post</div>
						</BringzzRadio>
						<BringzzRadio
							className='p-2 py-4 hover:bg-slate- border-1 border-t border-gray-400'
							value='article_post'
						>
							<div>Article Post</div>
						</BringzzRadio>
					</BringzzRadioList>
				</div>
			</BringzzBottomDrawer>
		</div>
	);
};

export default Navbar;
