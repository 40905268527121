import * as React from "react";
const Icon360Degree = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62 17.6c0-4-5.5-7.4-15-9.3-.5-.1-1.1.2-1.2.8-.1.5.2 1.1.8 1.2 8.1 1.6 13.4 4.5 13.4 7.3 0 2.1-3 4.3-8.1 5.9v-5.3c0-.6-.4-1-1-1s-1 .4-1 1v5.9C45.2 25.4 39 26.2 32 26.2s-13.2-.8-17.9-2.1v-5.9c0-.6-.4-1-1-1s-1 .4-1 1v5.3C7 21.9 4 19.7 4 17.6c0-2.8 5.3-5.7 13.4-7.3.5-.1.9-.6.8-1.2s-.7-.9-1.2-.7c-9.5 1.9-15 5.2-15 9.2V45c0 6.9 15.5 10.6 30 10.6S62 51.9 62 45zM12.2 51l7.4-8.5c1.1-1.3 3-1.4 4.3-.4l2.2 1.7c2.1 1.6 5.2 1.3 7-.7l3.7-4.2c.6-.7 1.5-1.1 2.4-1.1s1.8.4 2.4 1.1l10 12.2c-4.9 1.5-11.6 2.6-19.5 2.6-8.1 0-15-1.1-19.9-2.7m41.3-.6L43 37.7c-1-1.2-2.4-1.9-3.9-1.9s-3 .6-4 1.7l-3.7 4.2c-1.1 1.2-3 1.4-4.3.4l-2.2-1.7c-2.2-1.7-5.2-1.3-7 .7L10 50.2c-3.7-1.5-6-3.4-6-5.2V21.6c4.6 4.3 16.6 6.6 28 6.6s23.4-2.3 28-6.6V45c0 1.9-2.4 3.8-6.5 5.4" />
  </svg>
);
export default Icon360Degree;
