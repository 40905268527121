import * as React from "react";
const IconChatVet = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 2.4H7c-2.8 0-5 2.2-5 5v38c0 2.8 2.2 5 5 5h5.3v9.9c0 .5.3 1 .8 1.2.2.1.3.1.5.1.3 0 .7-.1.9-.4l10.8-10.8H57c2.8 0 5-2.2 5-5v-38c0-2.8-2.2-5-5-5m3 43c0 1.6-1.3 3-3 3H24.9c-.3 0-.5.1-.7.3l-10 10v-9.2c0-.3-.1-.5-.3-.7s-.4-.3-.7-.3H7c-1.6 0-3-1.3-3-3v-38c0-1.6 1.3-3 3-3h50c1.6 0 3 1.3 3 3z" />
    <path d="M31.9 24.9c-10.7.8-11.4 10.4-11.5 11.3 0 .3-.1 1.5.9 2.8 1.7 2.1 5.2 3.2 10.6 3.4h1.2c4.2 0 7.2-.9 8.9-2.7 1.5-1.6 1.3-3.4 1.3-3.5-.7-11.2-11.2-11.3-11.4-11.3m8.6 13.5c-1 1-3.2 2.2-8.5 2-6.1-.2-8.3-1.7-9-2.6-.6-.7-.6-1.3-.6-1.4v-.1c0-.4.3-8.7 9.6-9.4.4 0 8.7.1 9.3 9.4.1.1.1 1.1-.8 2.1" />
    <ellipse
      cx={26.8}
      cy={16.2}
      rx={4}
      ry={6.5}
      transform="rotate(-4.651 26.78 16.17)"
    />
    <path d="M20.8 21.9c-1.5-2.6-4.1-4-5.7-3.1s-1.7 3.9-.2 6.5 4.1 4 5.7 3.1c1.7-.9 1.8-3.8.2-6.5M48 18.3c-1.8-.5-3.9 1.5-4.7 4.4s.1 5.7 1.9 6.2 3.9-1.5 4.7-4.4 0-5.7-1.9-6.2M37 22.3c1.9.5 4.1-1.6 4.9-4.6s-.1-5.9-1.9-6.4c-1.9-.5-4.1 1.6-4.9 4.6-.9 3 0 5.9 1.9 6.4" />
  </svg>
);
export default IconChatVet;
