import * as React from "react";
const IconRightAlign = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M3 33h18.3c.6 0 1-.4 1-1s-.4-1-1-1H3c-.6 0-1 .4-1 1s.4 1 1 1M3.3 14.2c.6 0 1-.4 1-1V7.5c0-1.8 1.5-3.3 3.3-3.3h5.8c.6 0 1-.4 1-1s-.4-1-1-1H7.6c-2.9 0-5.3 2.4-5.3 5.3v5.8c0 .5.4.9 1 .9M13.4 59.9H7.6c-1.8 0-3.3-1.5-3.3-3.3v-5.8c0-.6-.4-1-1-1s-1 .4-1 1v5.8c0 2.9 2.4 5.3 5.3 5.3h5.8c.6 0 1-.4 1-1s-.5-1-1-1M56.7 2.1h-5.8c-.6 0-1 .4-1 1s.4 1 1 1h5.8c1.8 0 3.3 1.5 3.3 3.3V31H43c-.6 0-1 .4-1 1s.4 1 1 1h17.099999999999998v23.6c0 1.8-1.5 3.3-3.3 3.3H51c-.6 0-1 .4-1 1s.4 1 1 1h5.8c2.9 0 5.3-2.4 5.3-5.3V7.5c-.1-3-2.5-5.4-5.4-5.4M2.3 41.9v1.4c0 .6.4 1 1 1s1-.4 1-1v-1.4c0-.6-.4-1-1-1s-1 .5-1 1M3.3 22.7c.6 0 1-.4 1-1v-1.4c0-.6-.4-1-1-1s-1 .4-1 1v1.4c0 .6.4 1 1 1M22 2h-1.4c-.6 0-1 .4-1 1s.4 1 1 1H22c.6 0 1-.4 1-1s-.4-1-1-1M42.2 4h1.4c.6 0 1-.4 1-1s-.4-1-1-1h-1.4c-.6 0-1 .4-1 1s.5 1 1 1M22 59.7h-1.4c-.6 0-1 .4-1 1s.4 1 1 1H22c.6 0 1-.4 1-1s-.4-1-1-1M43.7 59.7h-1.4c-.6 0-1 .4-1 1s.4 1 1 1h1.4c.6 0 1-.4 1-1s-.5-1-1-1M32.1 1.9c-.6 0-1 .4-1 1v18.3c0 .6.4 1 1 1s1-.4 1-1V2.9c0-.6-.4-1-1-1M32.1 41.8c-.6 0-1 .4-1 1v18.3c0 .6.4 1 1 1s1-.4 1-1V42.8c0-.5-.4-1-1-1" />
  </svg>
);
export default IconRightAlign;
