import * as React from "react";
const IconRupee = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M15 12a3 3 0 0 1 3-3h12a3 3 0 1 1 0 6 3 3 0 1 1 0 6h-1.512a9.02 9.02 0 0 1-5.276 5.41l5.284 7.926a3 3 0 0 1-4.992 3.328l-8-12A3 3 0 0 1 18 21a3 3 0 0 1 0-6 3 3 0 0 1-3-3m3-1a1 1 0 1 0 0 2h2a5 5 0 0 1 4.33 2.5 1 1 0 0 1-.865 1.5H18a1 1 0 1 0 0 2h5.465a1 1 0 0 1 .866 1.5A5 5 0 0 1 20 23h-2a1 1 0 0 0-.832 1.555l8 12a1 1 0 0 0 1.664-1.11l-6.037-9.056a1 1 0 0 1 .63-1.534 7.01 7.01 0 0 0 5.354-5.104 1 1 0 0 1 .969-.751H30a1 1 0 1 0 0-2h-2.252a1 1 0 0 1-.969-.75 7 7 0 0 0-.715-1.75 1 1 0 0 1 .866-1.5H30a1 1 0 1 0 0-2z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconRupee;
