import * as React from "react";
const IconYacht1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.9 45.8h-27V40h18.4c.4 0 .7-.2.9-.5s.2-.7 0-1l-19.4-36c-.3-.4-.8-.6-1.2-.5s-.8.5-.8 1v7.8l-15 27.7c-.2.3-.2.7 0 1s.5.5.9.5h14.1v5.9H6.1c-.6 0-1 .4-1 1V53c0 5 4 9 9 9h35.7c5 0 9-4 9-9v-6.2c.1-.5-.4-1-.9-1M30.8 7l16.7 31H30.8V7m-2 8v23H16.4zm28.1 38c0 3.9-3.1 7-7 7H14.1c-3.9 0-7-3.1-7-7v-5.2h49.7V53z" />
  </svg>
);
export default IconYacht1;
