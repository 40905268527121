import * as React from "react";
const IconHappy3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
    <path d="M28.9 21c0-.6-.4-1-1-1s-1 .4-1 1c0 2.9-2.3 5.2-5.2 5.2s-5.2-2.3-5.2-5.2c0-.6-.4-1-1-1s-1 .4-1 1c0 4 3.2 7.2 7.2 7.2s7.2-3.2 7.2-7.2M48.5 20c-.6 0-1 .4-1 1 0 2.9-2.3 5.2-5.2 5.2s-5.2-2.3-5.2-5.2c0-.6-.4-1-1-1s-1 .4-1 1c0 4 3.2 7.2 7.2 7.2s7.2-3.2 7.2-7.2c0-.5-.5-1-1-1M46.1 40.6c-.6 0-1 .4-1 1 0 3.3-2.7 6.1-6 6.1s-6-2.7-6-6.1c0-.6-.4-1-1-1s-1 .4-1 1c0 3.3-2.7 6.1-6 6.1s-6-2.7-6-6.1c0-.6-.4-1-1-1s-1 .4-1 1c0 4.4 3.6 8.1 8 8.1 3 0 5.7-1.7 7-4.2 1.4 2.5 4 4.2 7 4.2 4.4 0 8-3.6 8-8.1 0-.6-.5-1-1-1" />
  </svg>
);
export default IconHappy3;
