import * as React from "react";
const IconCupid = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.7 2h-7.4c-1.6 0-3 .6-4.1 1.7s-1.7 2.5-1.7 4.1c0 2.3 1.3 4.2 3.2 5.2L36.5 26.2C23.9 5.8 2.9 13.8 2.7 13.9c-.3.1-.5.4-.6.7s0 .7.3.9l22.4 22.4-8.6 8.6-1-.3-4-1.3c-1.2-.4-2.1.2-2.5.6 0 0-2.7 2.8-3.3 3.3-.8.7-.9 1.4-1 1.8-.1 1.1.6 1.8.7 1.9.2.2 5.4 5.6 6.5 6.5.5.5 1.1.6 1.6.6.7 0 1.3-.3 1.7-.7l3.6-3.6c.9-.9.8-2 .6-2.5l-1.3-4-.3-.8 8.7-8.7 22.4 22.4c.2.2.4.3.7.3h.2c.3-.1.6-.3.7-.6.1-.2 8-21.1-12.3-33.7l13.2-13.2c.3.5.6.9 1 1.3 1 1 2.4 1.6 3.8 1.7h.2c1.4 0 2.8-.6 3.9-1.6 1.2-1.2 1.9-2.8 1.9-4.4V4.2c0-1.2-1-2.2-2.2-2.2M35 27.7l-8.8 8.8L5 15.2c4.8-1.3 20.1-4.1 30 12.5M6.4 50.8c0-.1.1-.3.3-.5.7-.6 3.3-3.3 3.5-3.5 0 0 .1-.1.4-.1l4 1.3L9 53.6c-1-1-1.9-2-2.5-2.5 0 0-.1-.2-.1-.3m10.8 2.8s.1.2-.1.3l-3.6 3.6c-.1.1-.3.2-.6-.1-.4-.3-1.3-1.3-2.5-2.4l5.5-5.5zm31.7 5.5L27.6 37.9l8.8-8.8C53 39 50.2 54.3 48.9 59.1m11-47.7c0 1.1-.5 2.2-1.3 3-.7.7-1.7 1.1-2.7 1-1 0-1.8-.4-2.5-1.1-.5-.5-.9-1.2-1-2-.1-.4-.4-.7-.8-.8-1.8-.3-3.1-1.9-3.1-3.7 0-1 .4-2 1.1-2.7S51.2 4 52.3 4h7.4c.1 0 .2.1.2.2z" />
  </svg>
);
export default IconCupid;
