import * as React from "react";
const IconSocialWork = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      d="M32.774 36.007c.387-.533.8-.95 1.216-1.372.547-.555 1.1-1.115 1.61-1.95q.194-.314.384-.606c.455-.706.86-1.332.934-1.972.096-.816.156-5.716-.108-6.458-.265-.741-1.79-1.157-2.317.503-.26.819-.342 1.872-.416 2.828-.077.98-.145 1.858-.39 2.272-.483.818-2.038 2.853-2.038 2.853l1.342-2.547s.204-.352.097-1.021c-.108-.67-.917-1.606-1.297-.862-.38.743-1.045 1.414-1.045 1.414l-2.348 2.829s-.765.992-.786 1.536c-.007.182-.12.586-.25 1.043-.145.516-.31 1.1-.362 1.51V37h5.773zM14.177 36.007c-.389-.533-.803-.95-1.22-1.372-.55-.555-1.104-1.115-1.616-1.95-.128-.21-.259-.411-.385-.607-.457-.705-.862-1.33-.937-1.97-.097-.817.202-5.717.467-6.459.266-.741 1.796-1.157 2.325.503.26.819.343 1.872.418 2.828.076.98.145 1.858.39 2.272.485.818 2.045 2.853 2.045 2.853l-1.346-2.547s-.205-.352-.097-1.021c.108-.67.92-1.606 1.301-.862.382.743 1.049 1.414 1.049 1.414l2.355 2.829s.768.992.789 1.536c.015.387.187 1.78.285 2.553V37h-5.823zM19.781 11C17.018 11 15 13.986 15 17.207 15 24.397 24 29 24 29s9-4.9 9-11.793C33 13.987 30.982 11 28.219 11c-1.918 0-3.34 1.34-4.219 3.24-.878-1.9-2.301-3.24-4.219-3.24"
    />
    <path
      
      fillRule="evenodd"
      d="M6 39a3 3 0 0 0 3 3h30a3 3 0 0 0 3-3V9a3 3 0 0 0-3-3H9a3 3 0 0 0-3 3zM8 9a1 1 0 0 1 1-1h30a1 1 0 0 1 1 1v30a1 1 0 0 1-1 1h-6v-1a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v1h-7v-1a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v1H9a1 1 0 0 1-1-1z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconSocialWork;
