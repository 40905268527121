import * as React from "react";
const IconPumpkin = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M45.5 16.1c-1.8 0-3.5.3-5.1 1-1-.9-2.2-1.6-3.5-2.1l.4-5.2c.1-1.4-.4-2.7-1.3-3.7s-2.3-1.6-3.6-1.6h-.5c-1.4 0-2.7.6-3.6 1.6S26.9 8.5 27 9.8l.4 5.2c-1.3.5-2.5 1.2-3.5 2.1-1.6-.7-3.4-1-5.1-1C9.3 16.1 2 25.6 2 37.8s7.3 21.7 16.5 21.7c2.4 0 4.8-.7 6.9-1.9 2 1.3 4.3 1.9 6.6 1.9s4.6-.6 6.6-1.9c2.1 1.2 4.4 1.9 6.9 1.9C54.8 59.5 62 50 62 37.8s-7.3-21.7-16.5-21.7M28.8 9.7c-.1-.8.2-1.6.8-2.2s1.3-.9 2.2-.9h.5c.8 0 1.6.3 2.2.9s.8 1.4.8 2.2l-.3 4.7c-1.1-.3-2-.4-3-.4s-1.9.1-2.9.4zm16.7 47.8c-2.1 0-4.1-.6-5.9-1.6-.6-.4-1.4-.4-2.1 0-3.4 2.1-7.7 2.1-11.1 0-.3-.2-.7-.3-1-.3-.4 0-.7.1-1 .3-1.8 1-3.8 1.6-5.8 1.6C10.4 57.5 4 48.8 4 37.8s6.4-19.7 14.5-19.7c1.5 0 3 .3 4.4.9.7.3 1.5.2 2.1-.3 2-1.7 4.5-2.6 7-2.6 2.6 0 5.1 1 7.1 2.6.6.5 1.4.6 2 .3 1.4-.6 2.9-.9 4.4-.9 8.2 0 14.5 8.7 14.5 19.7s-6.4 19.7-14.5 19.7" />
    <path d="M38.5 41H26.4c-2.6 0-4.7 2.1-4.7 4.7s2.1 4.7 4.7 4.7h12.1c2.6 0 4.7-2.1 4.7-4.7S41.1 41 38.5 41m0 7.5H26.4c-1.5 0-2.7-1.2-2.7-2.7s1.2-2.7 2.7-2.7h12.1c1.5 0 2.7 1.2 2.7 2.7s-1.2 2.7-2.7 2.7M44.1 26.1c-.6-1.3-2.5-1.3-3.1 0l-2.6 5.1c-.6 1.2.3 2.5 1.5 2.5H45c1.3 0 2.1-1.4 1.5-2.5zM25.6 31.2 23 26.1c-.6-1.3-2.5-1.3-3.1 0l-2.6 5.1c-.6 1.2.3 2.5 1.5 2.5H24c1.3 0 2.1-1.4 1.6-2.5" />
  </svg>
);
export default IconPumpkin;
