import * as React from "react";
const IconBox9 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m59.1 17.1-11.5-13c-1.2-1.4-3-2.1-4.8-2.1H21.2c-1.8 0-3.6.8-4.8 2.1l-11.5 13c-1.1 1.2-1.6 2.7-1.6 4.3v34.2c0 3.5 2.9 6.4 6.4 6.4h44.7c3.5 0 6.4-2.9 6.4-6.4V21.4c0-1.6-.6-3.2-1.7-4.3M5.9 57.9c-.4-.7-.7-1.5-.7-2.4V21.4c0-.4.1-.7.1-1.1H55zM46.1 5.5l11.5 12.8H33V4h9.8c1.2 0 2.4.5 3.3 1.5m-28.2 0c.8-.9 2-1.5 3.3-1.5H31v14.3H6.5zm40.9 50.1c0 2.4-2 4.4-4.4 4.4H9.7c-.8 0-1.6-.2-2.3-.7l50.9-39.1h.3c.1.4.2.7.2 1.1z" />
  </svg>
);
export default IconBox9;
