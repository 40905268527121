import * as React from "react";
const IconMediumBars = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M32 9a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3v30a3 3 0 0 1-3 3h-4a3 3 0 0 1-3-3zm3-1a1 1 0 0 0-1 1v30a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1zM19 21a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3v18a3 3 0 0 1-3 3h-4a3 3 0 0 1-3-3zm5-1-3 3v2.172l5.159-5.16A1 1 0 0 0 26 20zm3 2-6 6v2.172l6-6zm0 5-6 6v2.172l6-6zm0 5-6 6v1c0 .32.15.605.384.788L27 34.172zm0 5-3 3h2a1 1 0 0 0 1-1zM6 33a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H9a3 3 0 0 1-3-3zm3-1a1 1 0 0 0-1 1v2.172L11.172 32zm4.707.293L8 38v1c0 .32.15.605.384.788L14 34.172V33a1 1 0 0 0-.293-.707M14 37l-3 3h2a1 1 0 0 0 1-1z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconMediumBars;
