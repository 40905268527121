import * as React from "react";
const IconDropperAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M8.9 61.1c-1.8 0-3.5-.7-4.9-2-1.3-1.3-2-3-2-4.8s.7-3.5 2-4.8l33-32-1.3-1.2c-1.3-1.2-2-2.9-2-4.7s.7-3.5 2-4.7l.4-.4c2.5-2.4 6.6-2.4 9.1 0L46.7 8l3.4-3.3c2.7-2.6 7.1-2.6 9.8 0 1.3 1.3 2 3 2 4.8s-.7 3.5-2 4.8l-3.3 3.2 1.3 1.2c1.3 1.2 2 2.9 2 4.7s-.7 3.5-2 4.7l-.4.4c-2.5 2.4-6.6 2.4-9.1 0L46.9 27l-33 32c-1.4 1.5-3.2 2.1-5 2.1M38.4 19l-33 32c-.9.9-1.4 2.1-1.4 3.4s.5 2.5 1.4 3.4c1.9 1.9 5.1 1.9 7 0l33-32zm2.3-12.2c-1.1 0-2.3.4-3.2 1.3l-.4.4c-.9.9-1.4 2-1.4 3.3s.5 2.4 1.4 3.3l12.6 12.3c1.7 1.7 4.6 1.7 6.3 0l.4-.4c.9-.9 1.4-2 1.4-3.3 0-1.2-.5-2.4-1.4-3.3L43.8 8c-.8-.8-2-1.2-3.1-1.2m7.5 2.7 7 6.8 3.4-3.3c.9-.9 1.4-2.1 1.4-3.4s-.5-2.5-1.4-3.4c-1.9-1.9-5.1-1.9-7 0z" />
  </svg>
);
export default IconDropperAlt;
