import * as React from "react";
const IconPaintRollerAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 14.4h-3.5v-7c0-2.2-1.8-4-4-4h-36c-2.2 0-4 1.8-4 4v6.5H4.2v-3.2c0-.6-.4-1-1-1s-1 .4-1 1v8.2c0 .6.4 1 1 1s1-.4 1-1v-3h5.2v6.5c0 2.2 1.8 4 4 4h36c2.2 0 4-1.8 4-4v-6H57c1.7 0 3 1.4 3 3V29c0 1.3-.8 2.4-1.9 2.8L29.9 42.9H29c-2.3 0-4.3 1.9-4.3 4.3v9.2c0 2.3 1.9 4.3 4.3 4.3h2.3c2.3 0 4.3-1.9 4.3-4.3v-9.2c0-1.5-.8-2.8-1.9-3.5l25.2-9.9c1.9-.8 3.2-2.6 3.2-4.7v-9.5c-.1-2.9-2.4-5.2-5.1-5.2m-5.5 8c0 1.1-.9 2-2 2h-36c-1.1 0-2-.9-2-2v-15c0-1.1.9-2 2-2h36c1.1 0 2 .9 2 2zm-18 34c0 1.2-1 2.3-2.3 2.3h-2.3c-1.2 0-2.3-1-2.3-2.3v-9.2c0-1.2 1-2.3 2.3-2.3h2.3c1.2 0 2.3 1 2.3 2.3z" />
  </svg>
);
export default IconPaintRollerAlt2;
