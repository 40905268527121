import * as React from "react";
const IconKissLip = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62 31.8c0-.2-.1-1-.8-1.7-.5-.5-1.1-.7-1.8-.7h-.1s-.3-.1-.8-.7c-.6-.6-1.8-2.3-2.2-2.9-.1-.1-1.6-2.4-2.7-3.9C52.3 20.2 50 18 48 17s-3.8-1.1-5.3-1.1c-2.3 0-4.7.6-6.9 1.6-1.7.8-3 2-3.8 2.8-.5-.5-1.1-1-1.1-1.1-1.5-1.3-3.5-2.3-5.6-2.9-.1 0-3.3-.9-6.3-.4-2.7.5-4.2 1.6-5.4 2.5l-.2.2c-1.3 1-3.2 3.5-3.2 3.5-.2.3-1.7 2.5-2 2.9l-1.3 2s-.8 1.1-1.5 1.9c-.4.4-.6.4-.7.5h-.2c-1.2.2-1.8.8-2.1 1.3-.6 1-.3 2.2-.2 2.4C4.9 39.8 12.6 43.8 13 44c5.6 2.8 12 4.2 18.9 4.2q2.85 0 5.7-.3c.3 0 .5-.1.6-.1 1.4-.1 5-.9 5.2-.9.1 0 2.4-.6 4.2-1.3 1.2-.4 1.9-.8 2.7-1.2.4-.2.8-.4 1.3-.6 1.6-.7 4-2.6 4-2.6.8-.6 2.5-2.2 2.6-2.4.5-.4 1.5-1.8 1.7-2 .4-.4 1.1-1.6 1.2-1.8.3-.6.8-1.6.9-1.6.1-.9 0-1.6 0-1.6m-2.1.6c-.1.3-.5 1.1-.8 1.5-.3.5-.8 1.3-1 1.6-.4.6-1.3 1.6-1.6 1.9-.6.6-1.8 1.8-2.4 2.2 0 0-2.3 1.8-3.7 2.4-.5.2-.9.4-1.3.6-.7.4-1.4.7-2.4 1.1-1.7.6-4 1.2-4 1.2-1 .2-3.8.8-4.8.9-.1 0-.4 0-.7.1-8.7.9-16.6-.3-23.4-3.7-.1 0-7.3-3.9-9.8-9.8 0-.1-.1-.5 0-.6.1-.2.4-.3.6-.3.4 0 1.1-.2 2-1 .9-.9 1.8-2.1 1.8-2.2l1.2-1.8c.7-1 1.9-2.7 2-2.9.5-.6 2-2.5 2.9-3.1l.2-.2c1-.8 2.3-1.7 4.5-2.1 2.5-.4 5.4.3 5.5.4 1.8.4 3.4 1.3 4.7 2.4.3.3 1.3 1.1 1.6 1.7.2.3.5.4.8.4s.6-.2.8-.4c0 0 1.5-2 3.8-3.2 2.5-1.2 4.7-1.4 6.1-1.4 1.5 0 2.9.1 4.4.9 1.6.8 3.7 2.7 4.7 4.1 1.1 1.5 2.7 3.8 2.7 3.8.1.1 1.5 2.3 2.4 3.2s1.4 1.1 1.8 1.2c.1 0 .4.1.6.1s.3.1.5.2c.2.2.2.4.2.4.2-.1.2.1.1.4" />
  </svg>
);
export default IconKissLip;
