import * as React from "react";
const IconPlusMinusSymbol = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M12 24h19v19c0 .6.4 1 1 1s1-.4 1-1V24h19c.6 0 1-.4 1-1s-.4-1-1-1H33V3c0-.6-.4-1-1-1s-1 .4-1 1v19H12c-.6 0-1 .4-1 1s.5 1 1 1M52 60H12c-.6 0-1 .4-1 1s.4 1 1 1h40c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconPlusMinusSymbol;
