import * as React from "react";
const IconBookmarkAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.5 22.1H29.2v-7c0-3.2-2.6-5.8-5.8-5.8h-8.2c-3.2 0-5.8 2.6-5.8 5.8v7H7.5c-3.2 0-5.8 2.6-5.8 5.8v21c0 3.2 2.6 5.8 5.8 5.8h48.9c3.2 0 5.8-2.6 5.8-5.8v-21c.1-3.2-2.5-5.8-5.7-5.8m-42.6-6.9c0-.7.6-1.3 1.3-1.3h8.2c.7 0 1.3.6 1.3 1.3v14.7l-2.8-2.2-.2-.1c-1.4-1-3.3-1-4.7 0L14 30V15.2zm43.9 33.6c0 .7-.6 1.3-1.3 1.3h-49c-.7 0-1.3-.6-1.3-1.3v-21c0-.7.6-1.3 1.3-1.3h1.9v5.3c0 1.4.8 2.6 2 3.2.5.2 1 .4 1.5.4.8 0 1.5-.3 2.2-.8l4.2-3.3 4.1 3.3c1.1.8 2.5 1 3.7.4s2-1.8 2-3.2v-5.2h27.3c.7 0 1.3.6 1.3 1.3v20.9z" />
  </svg>
);
export default IconBookmarkAlt;
