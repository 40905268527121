import React from 'react';
import { BringzzTopBar, BringzzMap } from "@bringzz/components";
import BringzzLogo from 'assets/BringzzLogo.png';
import { useNavigation } from '../../context/NavigationContext';

const OrderRequest6 = () => {
	const { navigate } = useNavigation();

	let zoneData = [
		{
			zipcode: 1100,
			count: 24
		},
		{
			zipcode: 1010,
			count: 2
		},
		{
			zipcode: 1040,
			count: 12
		}
	];

	function addOpacityAttribute() {
		// Determine opacity based on the count
		function determineOpacity(count) {
			if (count >= 1 && count <= 5) {
				return 0.2;
			} else if (count >= 6 && count <= 10) {
				return 0.4;
			} else if (count >= 11 && count <= 20) {
				return 0.6;
			} else if (count >= 21) {
				return 0.8;
			}
		}

		// Loop through each item in the overlay array and add an opacity attribute
		for (let index = 0; index < zoneData.length; index++) {
			zoneData[index].opacity = determineOpacity(zoneData[index].count);
		}
	}

	addOpacityAttribute();

	return (
		<div className='flex flex-col h-full'>
			<BringzzTopBar
				logo={BringzzLogo}
				title={'Select address'}
				sections={[
					{ name: 'logo', alignment: 'start', value: BringzzLogo },
					{ name: 'location', alignment: 'center', value: 'Wien' },
					{
						name: 'notification',
						alignment: 'end',
						value: true,
						onClick: () => navigate('/notifications')
					}
				]}
			/>
			<div className='grow'>
				<BringzzMap mode={2} overlayData={zoneData} />
			</div>
		</div>
	);
};

export default OrderRequest6;
