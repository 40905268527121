import * as React from "react";
const IconWrite = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.1 10.3h-2.3V8c0-3.4-2.8-6.3-6.3-6.3H14.3C10.8 1.8 8 4.6 8 8v34.2c0 1.3.5 2.5 1.4 3.5l14.6 15c.9 1 2.2 1.5 3.6 1.5h18.3c2.7 0 5-2.2 5-5v-.9l4.8-9.7c.2-.3.2-.6.2-1l.1-32.4c0-1.5-1.3-2.8-2.9-2.9m-1.6 8.1h-5.6v-3.7h5.6zm-7.1-3.6q.15 0 0 0 .15 0 0 0M23.8 54l-8.2-8.4h8.2zm22.1 3.8H28.3V43.9c0-1.6-1.3-2.8-2.8-2.8h-13V8c0-1 .8-1.8 1.8-1.8h30.4c1 0 1.8.8 1.8 1.8v2.2h-2c-1.7 0-3 1.3-3 2.9l-.1 32.5c0 .4.1.7.2 1l4.9 9.7v.9c-.1.3-.3.6-.6.6m2.7-7-2.8-5.7V22.9h5.6v22.2z" />
  </svg>
);
export default IconWrite;
