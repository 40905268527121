import * as React from "react";
const IconSwitchAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M2 32c0-7.734 6.285-14 14.032-14h31.936C55.715 18 62 24.266 62 32s-6.285 14-14.032 14H16.032C8.285 46 2 39.734 2 32m14.032-12C9.385 20 4 25.375 4 32s5.385 12 12.032 12h31.936C54.615 44 60 38.625 60 32s-5.385-12-12.032-12zm31.015 4.98c-3.89 0-7.04 3.145-7.04 7.02s3.15 7.02 7.04 7.02 7.04-3.145 7.04-7.02-3.15-7.02-7.04-7.02M38.007 32c0-4.984 4.05-9.02 9.04-9.02s9.04 4.036 9.04 9.02-4.05 9.02-9.04 9.02-9.04-4.036-9.04-9.02"
      clipRule="evenodd"
    />
  </svg>
);
export default IconSwitchAlt;
