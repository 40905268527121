import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import BringzzImage from "../BringzzImage";
import BringzzText from "../BringzzText";
import BringzzIcon from "../BringzzIcon";

/**
 * Component for uploading images with file size validation.
 * Displays an image preview, and provides an option to remove the selected image.
 *
 * @component
 * @param {Object} props
 * @param {Function} props.imageHandler - Callback function to handle the image data.
 * @param {number} [props.maxSizeInMB=5] - Maximum file size in MB for the image.
 * @param {boolean} [props.isRequired=false] - Specifies if the image upload is required.
 * @param {string} [props.tooltip="Upload an image"] - Text displayed as a tooltip or helper text.
 */
const BringzzImageUpload = ({ banner, imageHandler, maxSizeInMB }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [error, setError] = useState("");
  const fileInputRef = useRef(null);

  /**
   * Checks if the file size is within the specified limit.
   * @param {File} file - The file to be checked.
   * @returns {boolean} - True if file size is valid, false otherwise.
   */
  const isValidFileSize = (file) => file.size / 1024 / 1024 < maxSizeInMB;

  /**
   * Handles changes to the image input.
   * Sets the image source for preview and calls the imageHandler callback.
   * @param {Event} e - The event object from the file input.
   */
  const onImageChange = (e) => {
    const file = e.target.files[0];
    if (file && isValidFileSize(file)) {
      setImageSrc(URL.createObjectURL(file));
      setError("");
      if (imageHandler) {
        imageHandler(file);
      }
    } else if (file) {
      setError(`File size should be less than ${maxSizeInMB} MB`);
    }
  };

  /**
   * Triggers the file input click event programmatically.
   */
  const handleClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="p-4">
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        onChange={onImageChange}
        style={{ display: "none" }} // Hide the input element
      />
      <div className="relative">
        <BringzzImage className="w-full" src={banner}></BringzzImage>
        <div
          onClick={handleClick}
          className="cursor-pointer absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2"
        >
          {imageSrc && !error ? (
            <div className="flex justify-center items-center flex-col">
              {/* Uploaded image preview */}
              <div className="relative">
                <BringzzImage
                  src={imageSrc}
                  alt="Uploaded"
                  className="w-[80px] h-[80px]  rounded-full mt-2"
                />
                <div className="bg-magic-lilac absolute bottom-0 right-0 rounded-full w-7 h-7 flex items-center justify-center">
                  {/* default state image */}
                  <BringzzIcon
                    folder="LineIcons"
                    icon="IconCamera"
                    size="15"
                    className=""
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="bg-magic-lilac rounded-full p-4">
              {/* default state image */}
              <BringzzIcon
                folder="LineIcons"
                icon="IconCamera"
                size="24"
                className=""
              />
            </div>
          )}
        </div>
      </div>

      {/* Error message */}
      {error && (
        <div className="text-center">
          <BringzzText
            type={{ tag: "span", classes: "text-sm text-red-500  font-bold" }}
          >
            {error}
          </BringzzText>{" "}
        </div>
      )}
    </div>
  );
};

// Props definition
BringzzImageUpload.propTypes = {
  imageHandler: PropTypes.func.isRequired,
  maxSizeInMB: PropTypes.number,
  tooltip: PropTypes.string,
  isRequired: PropTypes.bool,
};

// Props default values
BringzzImageUpload.defaultProps = {
  maxSizeInMB: 5,
  tooltip: "Upload an image",
};

export default BringzzImageUpload;
