import * as React from "react";
const IconTimeShare = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.2 45.9c-1.1-3.6-4.7-5.7-8.2-4.7-4.6 1.4-10.8 3.2-14.4 4.2 1.3-2 1.2-3.5.8-4.5-.8-1.9-2.9-2.7-4.7-2.7H22.4c-1.2 0-2.3-.5-3.4-1.5-.9-.8-2.1-1.3-3.3-1.3H7.4c-3 0-5.4 2.5-5.4 5.7v13.1C2 57.4 4.5 60 7.5 60h9.9c1.2 0 2.5-.5 3.5-1.3l.7.4c2.7 1.5 5.8 2.3 8.9 2.3 1.9 0 3.8-.3 5.5-.8l18.5-5.7c4.8-1.8 5.5-5.8 4.7-9m-51.7 12c-1.9 0-3.5-1.7-3.5-3.8V41c0-2 1.5-3.7 3.4-3.7h8.3c.7 0 1.4.3 1.9.8.5.4.9.8 1.4 1.1h-.1v18.4c-.5.2-1 .4-1.5.4H7.5zm46.5-5-18.5 5.6c-1.5.5-3.2.7-4.9.7-2.8 0-5.5-.7-8-2.1l-1.4-.7-.3-.1V40c.5.1 1 .2 1.4.2h10.2c1.1 0 2.5.5 2.9 1.5.5 1.1-.5 2.9-2.5 4.8l-.5.5.4.7c.4.8.4.8 10-2 4.4-1.3 8.7-2.6 8.7-2.6 2.4-.7 4.9.8 5.7 3.4.4 1.4.9 4.9-3.2 6.4M47.8 31C55.6 31 62 24.7 62 16.8 62 9 55.6 2.7 47.8 2.7S33.7 9 33.7 16.8C33.7 24.7 40 31 47.8 31m0-26.3c6.7 0 12.2 5.5 12.2 12.2S54.5 29 47.8 29s-12.2-5.5-12.2-12.2c.1-6.7 5.5-12.1 12.2-12.1" />
    <path d="M49.2 21.6c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-2.4-2.4v-4.2c0-.6-.4-1-1-1s-1 .4-1 1v4.6c0 .3.1.5.3.7z" />
  </svg>
);
export default IconTimeShare;
