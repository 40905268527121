import * as React from "react";
const IconPropertyBalance = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M14.4 22.9H6.8c-.6 0-1 .4-1 1s.4 1 1 1h5.9v2.4c0 .6.4 1 1 1s1-.4 1-1v-2.4c2.5-.1 4.6-2.2 4.6-4.8v-.4c0-2.6-2.2-4.8-4.8-4.8h-3.9c-1.5 0-2.8-1.3-2.8-2.8v-.4c0-1.5 1.3-2.8 2.8-2.8h7.7c.6 0 1-.4 1-1s-.4-1-1-1h-3.6V4.5c0-.6-.4-1-1-1s-1 .4-1 1v2.4h-2.1c-2.6 0-4.8 2.2-4.8 4.8v.4c0 2.6 2.2 4.8 4.8 4.8h3.9c1.5 0 2.8 1.3 2.8 2.8v.4c-.1 1.5-1.3 2.8-2.9 2.8" />
    <path d="M60.6 39.1h-17l-10.8-2.9V16.8c2.8-.4 5-2.8 5-5.8 0-3.2-2.6-5.8-5.8-5.8s-5.8 2.6-5.8 5.8c0 2.8 2 5.2 4.7 5.7v18.9l-10.4-2.8H3c-.6 0-1 .4-1 1s.4 1 1 1h17l10.8 2.9v12.6H20.6c-2.2 0-4 1.8-4 4v2.3c0 2.2 1.8 4 4 4H43c2.2 0 4-1.8 4-4v-2.3c0-2.2-1.8-4-4-4H32.8v-12L43.3 41h17.4c.6 0 1-.4 1-1s-.5-.9-1.1-.9M28.2 11c0-2.1 1.7-3.8 3.8-3.8s3.8 1.7 3.8 3.8-1.7 3.8-3.8 3.8c-2.1.1-3.8-1.6-3.8-3.8M45 54.3v2.3c0 1.1-.9 2-2 2H20.6c-1.1 0-2-.9-2-2v-2.3c0-1.1.9-2 2-2H43c1.1 0 2 .9 2 2" />
    <path d="M61.7 20.1 54 13.4c-.4-.3-.9-.3-1.3 0L45 20.1c-.2.2-.3.5-.3.8v10.3c0 .6.4 1 1 1H61c.6 0 1-.4 1-1V20.8c0-.3-.1-.6-.3-.7m-1.7 10H46.7v-8.8l6.6-5.8 6.6 5.8v8.8z" />
  </svg>
);
export default IconPropertyBalance;
