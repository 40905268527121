import * as React from "react";
const IconBanCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2M4 32C4 16.6 16.6 4 32 4c6.2 0 12 2 16.6 5.5L13.7 53.2C7.8 48 4 40.5 4 32m28 28c-6.3 0-12.1-2.1-16.7-5.6l34.9-43.7c6 5.1 9.8 12.8 9.8 21.2C60 47.4 47.4 60 32 60" />
  </svg>
);
export default IconBanCopy;
