import * as React from "react";
const IconListAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M11.7 3.8H5c-1.7 0-3 1.3-3 3v6.7c0 1.7 1.3 3 3 3h6.7c1.7 0 3-1.3 3-3V6.8c0-1.7-1.4-3-3-3m1 9.7c0 .6-.4 1-1 1H5c-.6 0-1-.4-1-1V6.8c0-.6.4-1 1-1h6.7c.6 0 1 .4 1 1zM25.5 11.2H61c.6 0 1-.4 1-1s-.4-1-1-1H25.5c-.6 0-1 .4-1 1s.4 1 1 1M11.7 25.1H5c-1.7 0-3 1.3-3 3v6.7c0 1.7 1.3 3 3 3h6.7c1.7 0 3-1.3 3-3v-6.7c0-1.7-1.4-3-3-3m1 9.7c0 .6-.4 1-1 1H5c-.6 0-1-.4-1-1v-6.7c0-.6.4-1 1-1h6.7c.6 0 1 .4 1 1zM61 30.4H25.5c-.6 0-1 .4-1 1s.4 1 1 1H61c.6 0 1-.4 1-1s-.4-1-1-1M11.7 47.5H5c-1.7 0-3 1.3-3 3v6.7c0 1.7 1.3 3 3 3h6.7c1.7 0 3-1.3 3-3v-6.7c0-1.7-1.4-3-3-3m1 9.7c0 .6-.4 1-1 1H5c-.6 0-1-.4-1-1v-6.7c0-.6.4-1 1-1h6.7c.6 0 1 .4 1 1zM61 52.8H25.5c-.6 0-1 .4-1 1s.4 1 1 1H61c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconListAlt;
