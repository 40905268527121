import * as React from "react";
const IconSeeds = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M28.9 26.1c-2.7 0-4.9-.8-6.5-2.4-2.1-2.1-2.9-5.4-2.2-9.2.6-3.1 2.1-6.2 4.1-8.2C26.9 3.8 31.2 2 35.1 2c2.7 0 4.9.8 6.5 2.4 2.1 2.1 2.9 5.4 2.2 9.2-.6 3.1-2.1 6.2-4.1 8.2-2.6 2.6-6.9 4.3-10.8 4.3M35.1 4c-3.4 0-7.1 1.5-9.4 3.8-1.7 1.7-3 4.4-3.6 7.1-.6 3.1 0 5.7 1.6 7.4 1.2 1.2 3 1.8 5.1 1.8 3.4 0 7.1-1.5 9.4-3.8 1.7-1.7 3-4.4 3.6-7.1.6-3.1 0-5.7-1.6-7.4C39 4.6 37.2 4 35.1 4M53 58.4c-3.8 0-8.2-1.8-10.8-4.4-2-2-3.5-5-4.1-8.2-.7-3.8.1-7 2.2-9.2 1.6-1.6 3.8-2.4 6.5-2.4 3.8 0 8.2 1.8 10.8 4.4 2 2 3.5 5 4.1 8.2.7 3.8-.1 7-2.2 9.2-1.5 1.5-3.8 2.4-6.5 2.4m-6.2-22.2c-2.1 0-3.9.6-5.1 1.8-1.6 1.6-2.2 4.3-1.6 7.4.5 2.7 1.8 5.4 3.6 7.1 2.3 2.3 6 3.8 9.4 3.8 2.1 0 3.9-.6 5.1-1.8 1.6-1.6 2.2-4.3 1.6-7.4-.5-2.7-1.8-5.4-3.6-7.1-2.2-2.2-6-3.8-9.4-3.8M13.7 62c-2.3 0-4.5-.4-6.3-1.3-2.3-1-4-2.6-4.8-4.6-1.1-2.8-.6-6.1 1.5-9.3 1.7-2.7 4.3-4.9 6.9-6 1.6-.7 3.6-1 5.6-1 2.3 0 4.5.4 6.3 1.3 2.3 1 4 2.6 4.8 4.6 1.1 2.8.6 6.1-1.5 9.3-1.7 2.7-4.3 4.9-6.9 6-1.6.6-3.5 1-5.6 1m2.9-20.2c-1.8 0-3.5.3-4.9.9-2.2.9-4.5 2.9-6 5.2-1.7 2.7-2.2 5.3-1.3 7.4.6 1.5 1.9 2.7 3.8 3.6 1.6.7 3.5 1.1 5.5 1.1 1.8 0 3.5-.3 4.9-.9 2.2-.9 4.5-2.9 6-5.2 1.7-2.7 2.2-5.3 1.3-7.4-.6-1.5-1.9-2.7-3.8-3.6-1.5-.7-3.5-1.1-5.5-1.1" />
  </svg>
);
export default IconSeeds;
