import * as React from "react";
const IconSpeechBubble1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.3 27.5H42.6V8.2c0-3.1-2.6-5.7-5.7-5.7H7.7C4.6 2.6 2 5.1 2 8.2V37c0 .4.2.8.6.9.4.2.8.1 1.1-.2l4.7-4.6c.1-.1.2-.2.4-.2h12.6v17.7c0 3.1 2.6 5.7 5.7 5.7h28.2c.1 0 .3.1.4.2l4.7 4.6c.2.2.4.3.7.3.1 0 .3 0 .4-.1q.6-.3.6-.9V33.2c-.1-3.2-2.6-5.7-5.8-5.7m-34.5 3.6h-13c-.7 0-1.3.3-1.8.7l-3 2.9V8.2c0-2 1.7-3.7 3.7-3.7h29.2c2 0 3.7 1.7 3.7 3.7v19.2H27.1c-2.4.1-4.5 1.6-5.3 3.7m38.2 27-3-2.9c-.5-.5-1.1-.7-1.8-.7H27.1c-2 0-3.7-1.7-3.7-3.7V33.2c0-2 1.7-3.7 3.7-3.7h29.3c2 0 3.7 1.7 3.7 3.7v24.9z" />
    <path d="M15.5 13.4h13.8c.6 0 1-.4 1-1s-.4-1-1-1H15.5c-.6 0-1 .4-1 1s.4 1 1 1M30.3 20.4c0-.6-.4-1-1-1H15.5c-.6 0-1 .4-1 1s.4 1 1 1h13.8c.5 0 1-.4 1-1M49 36.5H35.2c-.6 0-1 .4-1 1s.4 1 1 1H49c.6 0 1-.4 1-1s-.5-1-1-1M49 45.1H35.2c-.6 0-1 .4-1 1s.4 1 1 1H49c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconSpeechBubble1;
