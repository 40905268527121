import * as React from "react";
const IconTable5 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.9 10.1H10.1c-4.4 0-8.1 3.6-8.1 8.1 0 4.4 3.6 8.1 8.1 8.1h.4V53c0 .6.4 1 1 1s1-.4 1-1V26.2H19v17c0 .6.4 1 1 1s1-.4 1-1v-17h22v17c0 .6.4 1 1 1s1-.4 1-1v-17h6.5v26.7c0 .6.4 1 1 1s1-.4 1-1V26.2h.4c4.4 0 8.1-3.6 8.1-8.1 0-4.4-3.6-8-8.1-8m0 14.1H10.1c-3.3 0-6.1-2.7-6.1-6.1S6.7 12 10.1 12H54c3.3 0 6.1 2.7 6.1 6.1s-2.8 6.1-6.2 6.1" />
  </svg>
);
export default IconTable5;
