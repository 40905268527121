import * as React from "react";
const IconMoveLeft = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60 31H6.1l10.4-12.4c.4-.4.3-1.1-.1-1.4-.4-.4-1.1-.3-1.4.1l-11.8 14c-.3.4-.3.9 0 1.3l11.8 14c.2.2.5.4.8.4.2 0 .5-.1.6-.2.4-.4.5-1 .1-1.4L6.1 33H60c.6 0 1-.4 1-1s-.4-1-1-1M28.8 9.7h30.9c.6 0 1-.4 1-1s-.4-1-1-1H28.8c-.6 0-1 .4-1 1s.4 1 1 1M59.7 54.3H28.8c-.6 0-1 .4-1 1s.4 1 1 1h30.9c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconMoveLeft;
