import * as React from "react";
const IconHome5 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 52.5h-4.3V35.2h1.4c.6 0 1-.4 1-1s-.4-1-1-1h-8.5V22c0-.3-.2-.7-.5-.9L32.5 11c-.3-.2-.7-.2-1 0L26 14.4v-.3c0-2.5-2-4.6-4.6-4.6h-.7c-2.5 0-4.6 2-4.6 4.6v6.2l-1.3.8c-.3.2-.5.5-.5.9v11.2H5.9c-.6 0-1 .4-1 1s.4 1 1 1h1.4v17.3H3c-.6 0-1 .4-1 1s.4 1 1 1h58c.6 0 1-.4 1-1s-.4-1-1-1m-6.3-17.3v17.3h-5.1V35.2zM18.2 14.1c0-1.4 1.1-2.6 2.6-2.6h.7c1.4 0 2.6 1.1 2.6 2.6v1.5l-5.8 3.5v-5zm-1.8 8.4L32 13.1l15.6 9.5v10.6H16.4zM9.3 35.2h5.1v17.3H9.3zm25.4 17.3H29v-8.9h5.8v8.9zm2 0v-9.9c0-.6-.4-1-1-1H28c-.6 0-1 .4-1 1v9.9H16.4V35.2h31.2v17.3z" />
    <path d="M32 20.8c-2.4 0-4.4 2-4.4 4.4s2 4.4 4.4 4.4 4.4-2 4.4-4.4-2-4.4-4.4-4.4m0 6.7c-1.3 0-2.4-1.1-2.4-2.4s1.1-2.4 2.4-2.4 2.4 1.1 2.4 2.4-1.1 2.4-2.4 2.4" />
  </svg>
);
export default IconHome5;
