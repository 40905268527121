import * as React from "react";
const IconGiftHeart = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.1 13H41.7c.5-.2 1-.5 1.3-.8 2.3-2.3 2.3-6.1 0-8.4s-6.1-2.3-8.4 0c-2.2 2.2-1.3 8.1-1.2 8.8l.1.5h-3l.1-.5c.1-.7 1.1-6.5-1.2-8.8s-6.1-2.3-8.4 0-2.3 6.1 0 8.4c.4.4.8.6 1.3.8H7.9c-2.8 0-5 2.2-5 5v9c0 1.1.9 2 2 2h.5v30.9c0 1.1.9 2 2 2h48.9c1.1 0 2-.9 2-2V29.1h.6c1.1 0 2-.9 2-2v-9c.2-2.9-2.1-5.1-4.8-5.1M36 5.2c1.5-1.5 4.1-1.5 5.6 0s1.5 4 0 5.6c-1 1-4.2 1-6.3.7-.2-2-.3-5.3.7-6.3m-13.6 0c.7-.8 1.8-1.2 2.8-1.2s2 .4 2.8 1.2c1 1 1 4.2.7 6.3-2 .2-5.3.3-6.3-.7-1.6-1.6-1.6-4.1 0-5.6m34 33H43c-.2-2-1.1-3.7-2.7-4.8-1-.7-2-1-3.1-1v-3.3h19.3zM28.7 51.5c.8.5 1.7 1 2.6 1.3.2.1.5.1.7.1s.5 0 .7-.1q1.2-.45 2.4-1.2v8.3h-6.4zm-5.8-12.3c0-1.8.6-3.2 1.9-4.1.9-.6 1.8-.8 2.6-.6 1.2.3 2.2 1.4 2.9 2.2.4.5 1 .8 1.6.8s1.2-.3 1.6-.8c.6-.8 1.7-1.9 2.9-2.2q1.35-.45 2.7.6c1.3.9 2 2.3 1.9 4.1 0 2.1-1 4.1-1 4.1s-1 2.1-2.8 4.1c-.5.6-1.1 1.1-1.7 1.6-1.1.9-2.3 1.6-3.5 2-1.3-.5-2.5-1.2-3.7-2.1q-.75-.6-1.5-1.5c-1.9-2.1-2.9-4.1-2.9-4.1 0-.1-1-2-1-4.1m3.8-6.8c-1 0-2.1.3-3.1 1q-2.4 1.65-2.7 4.8H7.5v-9.1h19.2zM7.5 40.2H21c.3 2.1 1.1 3.9 1.1 3.9 0 .1.7 1.3 1.9 2.9H7.5zm34.3 3.9c0-.1.9-1.8 1.1-3.9h13.5v6.9H40c1.2-1.6 1.8-2.9 1.8-3m-6.6-11.2c-1.4.6-2.5 1.8-3.2 2.7-.7-.9-1.8-2.1-3.2-2.7V29h6.4zm-27.7 27V49.1h18.2c.3.4.7.7 1.1 1V60zm29.7 0v-9.8c.4-.4.8-.7 1.2-1.1h18.1v10.9zM4.9 27v-9c0-1.7 1.3-3 3-3H56c1.7 0 3 1.3 3 3v9z" />
  </svg>
);
export default IconGiftHeart;
