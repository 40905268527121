import * as React from "react";
const IconMicrosoft = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M1 30.6h29.6V1H1zM63 30.6V1H33.4v29.6zM1 63h29.6V33.4H1zM33.4 63H63V33.4H33.4z" />
  </svg>
);
export default IconMicrosoft;
