import * as React from "react";
const IconApartmentsAlt12 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.5 60H55V21.5h3.5c.6 0 1-.4 1-1s-.4-1-1-1h-10V4h6.6c.6 0 1-.4 1-1s-.4-1-1-1H8.9c-.6 0-1 .4-1 1s.4 1 1 1h6.6v15.5h-10c-.6 0-1 .4-1 1s.4 1 1 1H9V60H5.5c-.6 0-1 .4-1 1s.4 1 1 1h53c.6 0 1-.4 1-1s-.4-1-1-1m-41-56h29v15.5h-29zM11 60V21.5h42V60H39V45.5c0-2.8-2.3-5-5-5h-4c-2.8 0-5 2.3-5 5V60zm26.1 0H26.9V45.5c0-1.7 1.4-3 3-3h4c1.7 0 3 1.4 3 3V60z" />
  </svg>
);
export default IconApartmentsAlt12;
