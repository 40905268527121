import * as React from "react";
const IconBackhoe = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.8 27.3 54 17.8c1.9-1.6 3.1-4 3.1-6.6 0-4.8-3.9-8.8-8.8-8.8s-8.8 3.9-8.8 8.8q0 1.8.6 3.3l-11.6 7.7v-.1c-.1-.4-.5-.7-1-.7H21c-2.7 0-5 2.2-5 5v16.4H9.4C5.3 42.7 2 46 2 50.1v4.2c0 4.1 3.3 7.4 7.4 7.4h43.3c4.1 0 7.4-3.3 7.4-7.4v-4.2c0-4.1-3.3-7.4-7.4-7.4H34.4v-.1l-2-7c.1 0 .3-.1.4-.2l16.8-15.6c1-.1 1.9-.4 2.8-.9l2.7 3.3c-.1.1-.1.1-.1.2l-7.3 13.4c-.2.3-.2.7 0 1s.5.5.9.5H61c.6 0 1-.4 1-1v-8.4c0-.2-.1-.5-.2-.6M48.3 4.4c3.7 0 6.8 3 6.8 6.8 0 3.7-3 6.8-6.8 6.8-3.7 0-6.8-3-6.8-6.8s3-6.8 6.8-6.8m9.8 45.7v4.2c0 3-2.4 5.4-5.4 5.4H9.4c-3 0-5.4-2.4-5.4-5.4v-4.2c0-3 2.4-5.4 5.4-5.4h43.3c3 0 5.4 2.4 5.4 5.4m-40-7.4V26.3c0-1.6 1.3-3 3-3h5.7l.2.5 5.3 18.8H18.1zm13.7-9.2-2.7-9.4 12-7.9c1.3 1.8 3.3 3.1 5.5 3.6zM60 35.3h-9.9l6.3-11.5 3.7 4.4v7.1z" />
    <circle cx={19} cy={52} r={2} />
    <circle cx={29.5} cy={52} r={2} />
    <circle cx={40} cy={52} r={2} />
  </svg>
);
export default IconBackhoe;
