import * as React from "react";
const IconBarn = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M35.3 14.6h-6.5c-1.7 0-3 1.3-3 3v6.5c0 1.7 1.3 3 3 3h6.5c1.7 0 3-1.3 3-3v-6.5c0-1.6-1.4-3-3-3m1 9.5c0 .6-.4 1-1 1h-6.5c-.6 0-1-.4-1-1v-6.5c0-.6.4-1 1-1h6.5c.6 0 1 .4 1 1z" />
    <path d="m59 26-6-14c-.4-1-1.2-1.7-2.1-2.1L33.7 2.5c-1-.4-2.1-.4-3.1 0L13.1 9.8c-1 .4-1.7 1.2-2.1 2.1L5 26c-.4.9-.3 2 .3 2.8s1.5 1.3 2.5 1.3h1.8v27.7c0 2.2 1.8 4 4 4h36.8c2.2 0 4-1.8 4-4l.2-27.7h1.8c1 0 1.9-.5 2.5-1.3s.5-1.8.1-2.8M24.3 59.9l7.7-9.6 7.7 9.6zm9-11.2 7.5-9.3V58zM32 47.1l-7.6-9.4c.2-.1.5-.2.8-.2h13.5c.3 0 .6.1.8.2zm-8.8-7.6v-.1l7.5 9.3-7.5 9.3zm33.9-11.7c-.2.3-.5.4-.8.4h-2.8c-.5 0-1 .4-1 1l-.2 28.7c0 1.1-.9 2-2 2h-7.5V39.5c0-2.2-1.8-4-4-4H25.2c-2.2 0-4 1.8-4 4v20.4h-7.7c-1.1 0-2-.9-2-2V29.2c0-.6-.4-1-1-1H7.7c-.3 0-.6-.2-.8-.4-.2-.3-.2-.6-.1-.9l6-14.1c.2-.5.6-.9 1.1-1.1l17.5-7.4c.2-.1.5-.2.8-.2s.5.1.8.2l17.2 7.4c.5.2.8.6 1.1 1.1l6 14.1c0 .2 0 .6-.2.9" />
  </svg>
);
export default IconBarn;
