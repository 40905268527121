import * as React from "react";
const IconRepeatOne = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62.2 39.1c-.3-.4-1-.5-1.4-.2l-3.6 2.7c1.1-3.1 1.7-6.3 1.7-9.6C58.8 16.2 46 3.4 30.2 3.4 14.5 3.4 1.6 16.2 1.6 32s12.8 28.6 28.6 28.6c3.8 0 7.5-.7 10.9-2.2.5-.2.8-.8.5-1.3-.2-.5-.8-.8-1.3-.5-3.2 1.3-6.6 2-10.2 2C15.6 58.6 3.6 46.7 3.6 32s12-26.6 26.6-26.6c14.7 0 26.6 11.9 26.6 26.6q0 4.95-1.8 9.6l-2.5-4.5c-.3-.5-.9-.6-1.4-.4-.5.3-.7.9-.4 1.4l3.7 6.6c.1.2.4.4.7.5h.2c.2 0 .4-.1.6-.2l6-4.5c.5-.3.6-.9.3-1.4" />
    <path d="M31.4 44.9c.6 0 1-.4 1-1V20.1q0-.6-.6-.9c-.4-.2-.8-.2-1.1.1l-7.9 6.5c-.4.4-.5 1-.1 1.4s1 .5 1.4.1l6.2-5.2v21.8c.1.6.5 1 1.1 1" />
  </svg>
);
export default IconRepeatOne;
