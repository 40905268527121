import * as React from "react";
const IconPhonePause = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 3H41.5c-2.8 0-5 2.2-5 5v18.2c0 2.8 2.2 5 5 5H57c2.8 0 5-2.2 5-5V8c0-2.7-2.2-5-5-5m3 23.2c0 1.7-1.3 3-3 3H41.5c-1.7 0-3-1.3-3-3V8c0-1.7 1.3-3 3-3H57c1.7 0 3 1.3 3 3z" />
    <path d="M45.3 13.3c-.6 0-1 .4-1 1v5.2c0 .6.4 1 1 1s1-.4 1-1v-5.2c0-.5-.4-1-1-1M53.2 13.3c-.6 0-1 .4-1 1v5.2c0 .6.4 1 1 1s1-.4 1-1v-5.2c0-.5-.5-1-1-1M58 45.5l-8.7-5.9c-1.6-1.1-3.6-.5-4.8 1.3L42.3 44c-.4.5-1.1.7-1.7.4-11.9-7.4-19.3-17.8-22-21.9-.4-.6-.2-1.3.3-1.7l3.5-2.5c1.5-1 1.9-3.1.9-4.6L17.4 5c-1-1.5-2.9-1.9-4.5-1L4.6 8.9s-.1.1-.2.1c-3.1 3.1-3.2 8.5-.5 15.4 2.6 6.6 7.7 13.9 14.2 20.4S31.8 56.4 38.5 59c3.2 1.3 6.2 1.9 8.7 1.9 2.8 0 5.1-.8 6.7-2.4l.2-.2L59 50c.9-1.5.4-3.5-1-4.5m-.7 3.5-4.9 8.2c-2.5 2.3-7.3 2.3-13.2 0-6.4-2.5-13.4-7.4-19.7-13.7S8.3 30.1 5.8 23.7c-2.4-5.9-2.4-10.7 0-13.2L14 5.7c.6-.4 1.4-.2 1.8.4l5.9 8.7c.4.6.2 1.4-.3 1.8l-3.5 2.5c-1.5 1-1.8 3-.9 4.5 2.7 4.2 10.4 14.9 22.6 22.6 1.5.9 3.4.5 4.4-.9l2.2-3.1c.6-.9 1.4-1.2 2.1-.7l8.7 5.9c.4.2.6 1 .3 1.6" />
  </svg>
);
export default IconPhonePause;
