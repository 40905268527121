import * as React from "react";
const IconPlayStore = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      
      d="M59.15 30.009a4 4 0 0 0-1.356-1.16 445 445 0 0 1-6.224-3.571c-1.395-.79-2.751-1.58-3.756-2.175l-.624-.361c-.224-.137-.43-.254-.585-.342L22.85 8.675a6717 6717 0 0 1-10.73-6.185l-1.424-.829-.088-.039c-.556-.312-1.151-.527-1.688-.585a3 3 0 0 0-.946 0l-.078.02s-.039.01-.049.01H7.82c-.43.067-.79.233-1.044.448C5.683 2.149 5 3.368 5 4.705v54.92c0 1.092.478 2.126 1.249 2.721.39.42.946.654 1.482.654.108 0 .205-.01.303-.02h.117c.731 0 1.424-.185 2.01-.526.555-.322 6.525-3.775 12.349-7.14l23.305-13.472c.068-.03.126-.059.136-.078l.098-.04.185-.038.185-.176c.888-.517 10.058-5.785 11.355-6.536s2.029-1.892 2.01-3.131a3.1 3.1 0 0 0-.635-1.834m-13.716 7.306-5.502-5.414 5.463-5.423c.536.312 1.346.78 2.38 1.375l1.717.995c2.01 1.161 4.048 2.341 5.287 3.044-1.102.643-2.897 1.687-4.672 2.721-1.62.937-3.21 1.864-4.234 2.459zm-25.997-25.84c2.098 1.21 4.253 2.458 6.165 3.56l16.096 9.297-4.682 4.653L19.29 11.397zM9.146 56.659V7.134L34.099 31.9zm16.573-7.98c-1.97 1.142-4.214 2.43-6.409 3.698l17.706-17.608 4.72 4.692z"
    />
  </svg>
);
export default IconPlayStore;
