import * as React from "react";
const IconSaw = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 12.5c-.6 0-1 .4-1 1v12.2H4V13.5c0-.6-.4-1-1-1s-1 .4-1 1v36.9c0 .6.4 1 1 1s1-.4 1-1v-8.3l4.1 3.8c.4.3 1 .3 1.3 0l5.1-4.7 5.1 4.7c.4.3 1 .3 1.3 0l5.1-4.7 5.1 4.7c.2.2.4.3.7.3.2 0 .5-.1.7-.3l5.1-4.7 5.1 4.7c.4.3 1 .3 1.3 0l5.1-4.7 5.1 4.7c.4.3 1 .3 1.3 0l4.1-3.8v8.3c0 .6.4 1 1 1s1-.4 1-1V13.5c.4-.5 0-1-.6-1m-5.8 31.3L50 39.2c-.4-.3-1-.3-1.3 0l-5.1 4.7-5.1-4.7c-.4-.3-1-.3-1.3 0L32 43.8l-5.1-4.7c-.4-.3-1-.3-1.3 0l-5.1 4.7-5.1-4.7c-.4-.3-1-.3-1.3 0L9 43.8l-5-4.3V27.7h56v11.7z" />
  </svg>
);
export default IconSaw;
