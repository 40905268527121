import * as React from "react";
const IconIot = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 23.8c-.6 0-1 .4-1 1v6c0 .8-.6 1.4-1.4 1.4H47.4v-6.4h4c.6 0 1-.4 1-1s-.4-1-1-1h-4v-1c0-2.8-2.2-5-5-5h-1v-5c0-.6-.4-1-1-1s-1 .4-1 1v5H33V5.6c0-1.9-1.5-3.4-3.4-3.4h-6c-.6 0-1 .4-1 1s.4 1 1 1h6c.8 0 1.4.6 1.4 1.4v12.2h-6.4v-5c0-.6-.4-1-1-1s-1 .4-1 1v5h-1c-2.8 0-5 2.2-5 5v1h-4c-.6 0-1 .4-1 1s.4 1 1 1h4v6.4H5.4c-1.9 0-3.4 1.5-3.4 3.4v6c0 .6.4 1 1 1s1-.4 1-1v-6c0-.8.6-1.4 1.4-1.4h11.2v6.4h-4c-.6 0-1 .4-1 1s.4 1 1 1h4v1c0 2.8 2.2 5 5 5h1v3.8c0 .6.4 1 1 1s1-.4 1-1v-3.8H31v9.8c0 1.9 1.5 3.4 3.4 3.4h6c.6 0 1-.4 1-1s-.4-1-1-1h-6c-.8 0-1.4-.6-1.4-1.4v-9.8h6.4v3.8c0 .6.4 1 1 1s1-.4 1-1v-3.8h1c2.8 0 5-2.2 5-5v-1h4c.6 0 1-.4 1-1s-.4-1-1-1h-4v-6.4h11.2c1.9 0 3.4-1.5 3.4-3.4v-6c0-.6-.4-1-1-1M45.4 43.6c0 1.7-1.3 3-3 3H21.6c-1.7 0-3-1.3-3-3V22.8c0-1.7 1.3-3 3-3h20.8c1.7 0 3 1.3 3 3z" />
    <path d="M34.8 27.4h-5.6c-1.7 0-3 1.3-3 3V36c0 1.7 1.3 3 3 3h5.6c1.7 0 3-1.3 3-3v-5.6c0-1.7-1.3-3-3-3m1 8.6c0 .6-.4 1-1 1h-5.6c-.6 0-1-.4-1-1v-5.6c0-.6.4-1 1-1h5.6c.6 0 1 .4 1 1z" />
  </svg>
);
export default IconIot;
