import * as React from "react";
const IconBowlAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.1 27.5c-.7-.7-1.6-1.1-2.6-1.1H39L33 17l6.4-9.8c.3-.5.2-1.1-.3-1.4s-1.1-.2-1.4.3l-5.9 9.1-5.9-9.1c-.3-.5-.9-.6-1.4-.3s-.6.9-.3 1.4l6.4 9.8-6.1 9.3h-19c-1 0-1.9.4-2.6 1.1-.6.7-1 1.7-.9 2.6.9 9.4 6.3 17.8 14.5 22.5v2.2c0 1.9 1.5 3.4 3.4 3.4H44c1.9 0 3.4-1.5 3.4-3.4v-2.2c2.2-1.3 4.3-2.8 6.1-4.7 4.8-4.8 7.8-11.2 8.4-17.9.2-.7-.1-1.7-.8-2.4m-29.3-8.6 4.9 7.5h-9.8zm20.4 27.7c-1.8 1.8-3.9 3.4-6.2 4.6-.3.2-.5.5-.5.9v2.8c0 .8-.6 1.4-1.4 1.4H20c-.8 0-1.4-.6-1.4-1.4v-2.8c0-.4-.2-.7-.5-.9-5.2-2.9-9.3-7.3-11.7-12.5h51.3c-1.4 2.9-3.2 5.6-5.5 7.9m6.3-10h-53c-.8-2.1-1.3-4.4-1.5-6.7 0-.4.1-.8.4-1.1s.7-.5 1.1-.5h53.1c.4 0 .8.2 1.1.5s.4.7.4 1.1c-.3 2.3-.8 4.6-1.6 6.7" />
  </svg>
);
export default IconBowlAlt;
