import * as React from "react";
const IconCloudSearch = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50 21.6c-1.5 0-3.1.3-4.5.9 0-8.2-6.7-14.8-14.8-14.8-5.7 0-10.8 3.3-13.3 8.4h-.5C8.6 16.1 2 22.8 2 31s6.6 14.9 14.8 14.9c.6 0 1-.4 1-1s-.4-1-1-1C9.7 43.9 4 38.1 4 31s5.7-12.9 12.8-12.9h1c.4 0 .8-.2 1-.6 2-4.7 6.7-7.8 11.8-7.8 7.1 0 12.8 5.8 12.8 12.9 0 .6 0 1-.1 1.4s.1.8.4 1 .7.2 1.1 0c1.6-.9 3.4-1.4 5.1-1.4 5.5 0 10 4.6 10 10.2S55.4 44 49.9 44h-2.1c-.6 0-1 .4-1 1s.4 1 1 1H50c6.6 0 12-5.5 12-12.2s-5.4-12.2-12-12.2" />
    <path d="M38.7 48.7c1.4-1.6 2.2-3.6 2.2-5.8 0-2.3-.9-4.5-2.6-6.2-3.5-3.4-9.1-3.4-12.6 0-1.7 1.7-2.6 3.9-2.6 6.2s.9 4.5 2.6 6.2 4 2.6 6.3 2.6c1.8 0 3.7-.6 5.2-1.7l6.1 6c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4zm-11.6-1c-1.3-1.3-2-3-2-4.8s.7-3.5 2-4.8 3.1-2 4.9-2 3.5.7 4.9 2c1.3 1.3 2 3 2 4.8s-.7 3.5-2 4.8c-2.7 2.7-7.1 2.7-9.8 0" />
  </svg>
);
export default IconCloudSearch;
