import * as React from "react";
const IconTrophy3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51 60h-8v-3.3c0-1.8-1.2-3.2-2.7-3.8V44c0-2.2-1.8-4-4-4H33V25l6.3 2.6c.1 0 .2.1.4.1s.4-.1.6-.2c.3-.2.4-.5.4-.9l-.6-7.9 5.1-6.1c.2-.3.3-.6.2-1s-.4-.6-.7-.7L37 9.2l-4.2-6.7c-.4-.6-1.3-.6-1.7 0L27 9.2l-7.7 1.9c-.3.1-.6.3-.7.7-.1.3 0 .7.2 1l5.1 6.1-.6 7.9c0 .3.1.7.4.9s.6.2 1 .1l6.3-2.6v15h-3.3c-2.2 0-4 1.8-4 4V53c-1.6.5-2.7 2-2.7 3.8V60h-8c-.6 0-1 .4-1 1s.4 1 1 1h38c.6 0 1-.4 1-1s-.4-1-1-1M25.6 17.8l-4.3-5.1 6.5-1.6c.3-.1.5-.2.6-.4L32 4.9l3.6 5.7c.1.2.4.4.6.4l6.5 1.6-4.3 5.1c-.2.2-.3.5-.2.7l.5 6.7-6.2-2.5c-.1 0-.2-.1-.4-.1-.1 0-.3 0-.4.1l-6.2 2.5.5-6.7c-.1-.2-.2-.4-.4-.6m.1 26.3c0-1.1.9-2 2-2h8.5c1.1 0 2 .9 2 2v8.6H25.7zM23 60v-3.3c0-1.1.9-2 2-2h14c1.1 0 2 .9 2 2V60z" />
  </svg>
);
export default IconTrophy3;
