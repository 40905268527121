import React, {Fragment, useEffect, useState} from "react";

import Component from "./components/Component";

import { getUserLanguages } from "./helpers/user";
import useRequest from "hooks/useRequest";

export default function CmsPage({
    ...props
}) {
    const [pageData, setPageData] = useState(null);
    const { sendRequest, data, error, loading } = useRequest();
    
    const setMetaData = (page) => {
        if (page.metaKeywords){
            let metaKeywords = ('meta[name="keywords"]');
            if(metaKeywords.length)
                metaKeywords.attr('content', page.metaKeywords);
            else 
                (`<meta name="keywords" content="${page.metaKeywords}"/>`).insertAfter('title');
        }

        if (page.metaDescription){
            let metaDescription = ('meta[name="description"]');
            if(metaDescription.length) 
                metaDescription.attr('content', page.metaDescription);
            else 
            (`<meta name="description" content="${page.metaDescription}"/>`).insertAfter('title');
        }

        if (page.metaTitle){
            let metaTitle = ('meta[name="title"]');
            if(metaTitle.length) 
                metaTitle.attr('content', page.metaTitle);
            else 
            (`<meta name="title" content="${page.metaTitle}"/>`).insertAfter('title');
        }

        if(page.noIndex == 1)
            ('<meta name="robots" content="noindex, nofollow"/>').insertAfter('title');
        
    }
    useEffect(() => {
        if(!pageData){
            sendRequest({
                method: 'POST',
                url: `/module/getPageInFrontend/cmspage`,
                data: {lang: getUserLanguages(), slug: window.location.pathname ?? '/'}
            }).then(data => {
                    if (data.data && data.status === 'success') {
                        if(data.message == 'doccheck') {
                            return window.location.href = data.data.redirect;
                        }
                        let page = data.data;
                        document.title = `${page.title ?? document.title}`; 
                        setPageData(page);
                        setMetaData(page);
                        
                    } else {
                        alert("Page not found")
                        // window.location.href = '/404';
                    }
                });
        }
    }, [])

    return (
        <Fragment>
            {
                pageData &&
                <div className='frontend-text cms-page' style={{ backgroundColor: pageData.backgroundColor }}>
                    {
                        pageData.components.map(component =>
                        <Component
                            key={component.uuid}
                            component={component}
                            hideHeader={pageData.hideHeader == 1}
                            hideFooter={pageData.hideFooter == 1}
                            {...props} />
                    )}
                </div>
            }
        </Fragment>
    )
}