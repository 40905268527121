import * as React from "react";
const IconPostcardCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 12.5H7c-2.8 0-5 2.2-5 5v29c0 2.8 2.2 5 5 5h50c2.8 0 5-2.2 5-5v-29c0-2.7-2.2-5-5-5m3 34c0 1.7-1.3 3-3 3H7c-1.7 0-3-1.3-3-3v-29c0-1.7 1.3-3 3-3h50c1.7 0 3 1.3 3 3z" />
    <path d="M32 21.8c-.6 0-1 .4-1 1v15.8c0 .6.4 1 1 1s1-.4 1-1V22.8c0-.6-.4-1-1-1M10.9 31.7h9.2c.6 0 1-.4 1-1s-.4-1-1-1h-9.2c-.6 0-1 .4-1 1s.5 1 1 1M52.7 21.8h-9.8c-.8 0-1.4.6-1.4 1.4V33c0 .8.6 1.4 1.4 1.4h9.8c.8 0 1.4-.6 1.4-1.4v-9.8c0-.8-.6-1.4-1.4-1.4m-.6 10.5h-8.5v-8.5h8.5zM22.7 37.6H10.9c-.6 0-1 .4-1 1s.4 1 1 1h11.8c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconPostcardCopy;
