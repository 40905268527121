import * as React from "react";
const IconResize = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 2H19.8c-2.2 0-4 1.8-4 4v23.1H6c-2.2 0-4 1.8-4 4V58c0 2.2 1.8 4 4 4h24.9c2.2 0 4-1.8 4-4v-9.8H58c2.2 0 4-1.8 4-4V6c0-2.2-1.8-4-4-4M32.9 58c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V33.1c0-1.1.9-2 2-2h9.8v13.1c0 2.2 1.8 4 4 4h13.1zm0-11.8H19.8c-1.1 0-2-.9-2-2V31.1h13.1c1.1 0 2 .9 2 2zm27.1-2c0 1.1-.9 2-2 2H34.9V33.1c0-2.2-1.8-4-4-4H17.8V6c0-1.1.9-2 2-2H58c1.1 0 2 .9 2 2z" />
    <path d="M51.6 12.4s-.1 0 0 0c-.4-.4-.9-.6-1.4-.6h-11c-.6 0-1 .4-1 1s.4 1 1 1h9.5l-9.5 9.5c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l9.5-9.5v9.6c0 .6.4 1 1 1s1-.4 1-1V13.9c.1-.6-.1-1.1-.5-1.5" />
  </svg>
);
export default IconResize;
