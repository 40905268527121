import * as React from "react";
const IconIntersection = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M49.7 60h-7.9c-.6 0-1 .4-1 1s.4 1 1 1h7.9c.6 0 1-.4 1-1s-.4-1-1-1M35.6 60H28c-.6 0-1 .4-1 1s.4 1 1 1h7.7c.6 0 1-.4 1-1s-.5-1-1.1-1M50.3 18.4h-3.9v-5.2c0-.6-.4-1-1-1s-1 .4-1 1v5.2H22.6c-2.8 0-5.1 2.3-5.1 5.1v20.1h-3.9c-.6 0-1 .4-1 1s.4 1 1 1h3.9v5.2c0 .6.4 1 1 1s1-.4 1-1v-5.2h21.8c2.8 0 5.1-2.3 5.1-5.1V20.4h3.9c.6 0 1-.4 1-1s-.4-1-1-1m-5.9 22.1c0 1.7-1.4 3.1-3.1 3.1H19.6V23.5c0-1.7 1.4-3.1 3.1-3.1h21.8v20.1zM56.5 20.2c1.6 0 2.8 1.3 2.8 2.8 0 .6.4 1 1 1s1-.4 1-1c0-2.7-2.2-4.8-4.8-4.8-.6 0-1 .4-1 1s.4 1 1 1M60.3 28.2c-.6 0-1 .4-1 1v7.7c0 .6.4 1 1 1s1-.4 1-1v-7.7c0-.5-.4-1-1-1M60.3 42.1c-.6 0-1 .4-1 1v7.7c0 .6.4 1 1 1s1-.4 1-1v-7.7c0-.6-.4-1-1-1M60.3 56.2c-.6 0-1 .4-1 1 0 1.6-1.3 2.8-2.8 2.8-.6 0-1 .4-1 1s.4 1 1 1c2.7 0 4.8-2.2 4.8-4.8 0-.6-.4-1-1-1M22.4 60c-1.6 0-2.8-1.3-2.8-2.8 0-.6-.4-1-1-1s-1 .4-1 1c0 2.7 2.2 4.8 4.8 4.8.6 0 1-.4 1-1s-.4-1-1-1M14.3 4h7.9c.6 0 1-.4 1-1s-.4-1-1-1h-7.9c-.6 0-1 .4-1 1s.4 1 1 1M28.4 4H36c.6 0 1-.4 1-1s-.4-1-1-1h-7.7c-.6 0-1 .4-1 1s.5 1 1.1 1M7.5 43.8c-1.6 0-2.8-1.3-2.8-2.8 0-.6-.4-1-1-1s-1 .4-1 1c0 2.7 2.2 4.8 4.8 4.8.6 0 1-.4 1-1s-.4-1-1-1M3.7 35.8c.6 0 1-.4 1-1v-7.7c0-.6-.4-1-1-1s-1 .4-1 1v7.7c0 .5.4 1 1 1M3.7 21.9c.6 0 1-.4 1-1v-7.7c0-.6-.4-1-1-1s-1 .4-1 1v7.7c0 .6.4 1 1 1M7.5 2C4.8 2 2.7 4.2 2.7 6.8c0 .6.4 1 1 1s1-.4 1-1C4.7 5.3 6 4 7.5 4c.6 0 1-.4 1-1s-.4-1-1-1M41.6 4c1.6 0 2.8 1.3 2.8 2.8 0 .6.4 1 1 1s1-.4 1-1c0-2.7-2.2-4.8-4.8-4.8-.6 0-1 .4-1 1s.4 1 1 1" />
  </svg>
);
export default IconIntersection;
