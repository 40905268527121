import * as React from "react";
const Icon4x4 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      d="M16.837 22.654a1.5 1.5 0 1 1-2.898-.776 1.5 1.5 0 0 1 2.898.776M32.386 28.374a1.5 1.5 0 1 0 .777-2.898 1.5 1.5 0 0 0-.777 2.898"
    />
    <path
      
      fillRule="evenodd"
      d="m13.207 14.952 1.448.389-.132.495-1.685 1.202a3 3 0 0 0-1.154 1.665l-1.29 4.813a3 3 0 0 0 1.331 3.336l-.904 3.376a1 1 0 0 0 .707 1.225l3.864 1.035a1 1 0 0 0 1.224-.707l.873-3.258 9.515 2.55h.002l1.296.347-.873 3.258a1 1 0 0 0 .707 1.225l3.863 1.035a1 1 0 0 0 1.225-.707l.955-3.564a3 3 0 0 0 2.295-2.163l1.32-4.926a3 3 0 0 0-.05-1.723l-.718-2.158a3 3 0 0 0-.159-.387l.005-.017 1.449.389a2 2 0 0 0 2.449-1.415l.906-3.38a2 2 0 0 0-1.414-2.45l-1.49-.399a3 3 0 0 0-2.21-3.18l-16.42-4.4a3 3 0 0 0-3.505 1.649l-1.49-.4a2 2 0 0 0-2.449 1.415l-.906 3.38a2 2 0 0 0 1.415 2.45M14.63 9.64l1.449.388-.906 3.38-1.449-.388zM28.488 29.4l4.83 1.294a1 1 0 0 0 1.224-.707l1.32-4.927a1 1 0 0 0-.017-.574l-.717-2.159a1 1 0 0 0-.69-.65l-17.47-4.682a1 1 0 0 0-.84.152L14 18.667a1 1 0 0 0-.384.554l-1.29 4.813a1 1 0 0 0 .707 1.225l4.83 1.294 1.294-4.83a1 1 0 0 1 1.224-.707l8.694 2.33a1 1 0 0 1 .707 1.224zm-8.693-2.33 2.414.647.669-2.494a1 1 0 0 1 1.932.518l-.669 2.494 2.415.647 1.035-3.864-6.761-1.811zm18.5-11.09 1.449.389-.906 3.38-1.449-.387zm-1.544-1.966a1 1 0 0 0-.707-1.224l-16.42-4.4a1 1 0 0 0-1.225.706l-1.553 5.796 18.353 4.918z"
      clipRule="evenodd"
    />
    <path
      
      d="M11 34a5 5 0 0 0-5 5v2a1 1 0 1 0 2 0v-2a3 3 0 1 1 6 0v1a1 1 0 1 0 2 0 2 2 0 1 1 4 0 1 1 0 1 0 2 0 1 1 0 0 1 2 0v1a1 1 0 0 0 1.25.968q.12.032.25.032h9a1 1 0 1 0 0-2H26a3 3 0 0 0-4.8-2.4 4 4 0 0 0-5.68-.738A5 5 0 0 0 11 34"
    />
  </svg>
);
export default Icon4x4;
