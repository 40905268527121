import * as React from "react";
const IconPulley = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.7 12.4c-.4-.4-1-.4-1.4 0L48.7 24c-.8-1.9-2-3.7-3.5-5.2s-3.3-2.7-5.2-3.5L51.6 3.7c.4-.4.4-1 0-1.4s-1-.4-1.4 0L37.9 14.6c-1.4-.4-2.9-.6-4.3-.6-4.4 0-8.5 1.7-11.6 4.8-7.7 7.7-6.4 24.6-6 27.9l-3.8 3.8c-1.2 1.2-1.6 3-1 4.7.6 1.6-.1 3.4-1.6 4.3-1.1.7-2.6.7-3.8 0-1.9-1.2-2.2-3.4-1.3-4.9.3-.5.1-1.1-.4-1.4s-1.1-.1-1.4.4c-1.5 2.7-.6 6 2.1 7.6.9.5 1.9.8 2.9.8s2.1-.3 3-.8c2.3-1.4 3.3-4.2 2.4-6.7-.3-1-.1-2 .5-2.6l3.8-3.8c3.1.3 20.2 1.7 27.9-6 4.3-4.3 5.7-10.5 4.2-16l12.3-12.3c.3-.4.3-1-.1-1.4M43.8 40.7c-6.7 6.7-22.2 5.8-25.9 5.5-.3-3.7-1.2-19.2 5.5-25.9 2.7-2.7 6.4-4.2 10.2-4.2 3.9 0 7.5 1.5 10.2 4.2 5.6 5.6 5.6 14.7 0 20.4" />
    <path d="M28.3 24.2c-3.2 3.2-3.2 8.4 0 11.5 1.6 1.6 3.7 2.4 5.8 2.4s4.2-.8 5.8-2.4c3.2-3.2 3.2-8.4 0-11.5-3.3-3.2-8.4-3.2-11.6 0m10.1 10.1c-2.4 2.4-6.3 2.4-8.7 0s-2.4-6.3 0-8.7c1.2-1.2 2.8-1.8 4.4-1.8s3.2.6 4.4 1.8c2.3 2.4 2.3 6.3-.1 8.7" />
  </svg>
);
export default IconPulley;
