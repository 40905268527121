import * as React from "react";
const IconUserShield = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.3 41c-1-.9-4.2-4-4.8-7.2-.1-.5-.5-.8-1-.8H41c-1.1-1.1-2-1.9-3.2-2.7-2.6-1.8-5.4-3-8.2-3.5-7.7-1.4-15.2 1.4-20.4 7.6-4.3 5.1-6.7 12.2-6.9 19.9q0 .6.6.9C5.6 56.6 15.4 61 25.8 61c.9 0 1.8 0 2.7-.1 2-.1 4-.4 6-.8 1-.2 2-.5 3.1-.9l3.3-1.2.4.4c2.9 2.5 5.8 3.3 6.6 3.5h.4c.8-.2 3.7-1 6.6-3.5 2-1.7 3.5-3.9 4.6-6.3q1.95-4.5 2.1-10.5c.1-.1-.1-.4-.3-.6M36.9 57.5c-1 .4-1.9.7-2.8.8-1.9.4-3.9.6-5.7.7-.9.1-1.8.1-2.6.1-9.3 0-18.2-3.8-21.4-5.3.2-7 2.5-13.4 6.4-18 4.8-5.7 11.5-8.2 18.5-7 2.5.4 5 1.5 7.4 3.2 1.1.8 2 1.5 3 2.5-.8 2.9-3.7 5.7-4.6 6.5-.2.2-.3.5-.3.8.1 4 .7 7.5 2.1 10.5.7 1.5 1.6 3 2.7 4.3zm20.9-6.1c-.9 2.1-2.3 4-4.1 5.6-2.3 2-4.7 2.8-5.5 3-.9-.2-3.2-1-5.5-3-1.7-1.5-3.1-3.4-4.1-5.6-1.2-2.6-1.8-5.7-1.9-9.3 1.3-1.3 3.7-3.8 4.7-6.6.1 0 .3-.1.4-.2s.2-.2.3-.4h12.7c.9 3.1 3.5 5.8 4.9 7.2-.1 3.6-.8 6.7-1.9 9.3" />
    <path d="M25.8 26.4C32.5 26.4 38 21 38 14.2 38 7.5 32.5 2 25.8 2S13.6 7.5 13.6 14.2c0 6.8 5.4 12.2 12.2 12.2m0-22.4C31.4 4 36 8.6 36 14.2s-4.6 10.2-10.2 10.2-10.2-4.6-10.2-10.2S20.1 4 25.8 4" />
  </svg>
);
export default IconUserShield;
