import * as React from "react";
const IconTriangle = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.2 57H7.8C4.6 57 2 54.4 2 51.2V12.8c0-2.2 1.3-4.2 3.3-5.2s4.4-.7 6.1.7l48.4 38.4c2 1.6 2.7 4.1 1.9 6.4-.9 2.4-3 3.9-5.5 3.9M7.8 9c-.6 0-1.1.1-1.7.4C4.8 10 4 11.3 4 12.8v38.4C4 53.3 5.7 55 7.8 55h48.4c2 0 3.1-1.3 3.6-2.5.4-1.2.3-3-1.2-4.2L10.1 9.8C9.4 9.3 8.6 9 7.8 9" />
  </svg>
);
export default IconTriangle;
