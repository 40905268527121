import * as React from "react";
const IconLineiconsSymbolAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      
      d="M32 1c-8.56 0-16.31 3.47-21.92 9.08S1 23.44 1 32s3.47 16.31 9.08 21.92S23.44 63 32 63s16.31-3.47 21.92-9.08S63 40.56 63 32C63 14.88 49.12 1 32 1m-.54 16.82a2.746 2.746 0 0 1 3.91 0l7.61 7.69a2.83 2.83 0 0 1 0 3.95 2.734 2.734 0 0 1-3.9 0l-7.62-7.7a2.8 2.8 0 0 1 0-3.94m1.37 28.36a2.734 2.734 0 0 1-3.9 0l-7.62-7.69a2.813 2.813 0 0 1 0-3.95 2.746 2.746 0 0 1 3.91 0l7.61 7.7a2.8 2.8 0 0 1 0 3.94m10.65-2.72a2.764 2.764 0 0 1-3.91 0L20.81 24.5a2.813 2.813 0 0 1 0-3.95 2.75 2.75 0 0 1 3.9 0l18.77 18.96a2.83 2.83 0 0 1 0 3.95"
    />
  </svg>
);
export default IconLineiconsSymbolAlt2;
