import * as React from "react";
const IconMedal2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M46.4 2H17.6c-.6 0-1 .4-1 1v17.8c0 .6.4 1 1 1h7.7v5.7c0 .6.4 1 1 1H31v5.4c-7.3.5-13.1 6.6-13.1 14C17.9 55.7 24.3 62 32 62s14.1-6.3 14.1-14.1c0-7.4-5.8-13.5-13.1-14v-5.4h4.8c.6 0 1-.4 1-1v-5.7h7.7c.6 0 1-.4 1-1V3c-.1-.6-.5-1-1.1-1m-2.3 45.9C44.1 54.6 38.6 60 32 60s-12.1-5.4-12.1-12.1 5.5-12 12.1-12 12.1 5.4 12.1 12M28 19.8V4h8v15.8zM18.6 4H26v15.8h-7.4zm8.6 22.5v-4.7h9.6v4.7zm18.2-6.7H38V4h7.4z" />
    <path d="M26 47.9c0 3.3 2.7 6 6 6s6-2.7 6-6-2.7-6-6-6-6 2.7-6 6m10 0c0 2.2-1.8 4-4 4s-4-1.8-4-4 1.8-4 4-4 4 1.8 4 4" />
  </svg>
);
export default IconMedal2;
