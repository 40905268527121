import * as React from "react";
const IconWheelbarrowAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62 32.6V32c0-.1 0-.2-.1-.2 0 0 0-.1-.1-.1-.1-.1-.1-.2-.2-.2h-.1c-.1-.1-.2-.1-.3-.1h-3.1L42.6 15.5c-.8-.8-2.1-.8-2.9 0l-6.1 6h-.1l-1-1c-.8-.8-2.1-.8-2.9 0l-5.7 5.6-5.1-15.2c-.2-.5-.6-1-1.1-1.2L3.4 3c-.5-.3-1.1 0-1.3.5s0 1.1.5 1.3L17 11.5l5.4 16.2-3.5 3.5h-6.7c-.6 0-1 .4-1 1s.4 1 1 1h3.1c.2 8.4 4.8 16 12.2 20l-1.9 6.7c-.2.5.2 1.1.7 1.2h.3c.4 0 .8-.3 1-.7l1.8-6.4c3 1.3 6.1 2 9.4 2 .7 0 1.3 0 1.9-.1 1.4 3.1 4.5 5.2 8.1 5.2 4.9 0 8.8-4 8.8-8.8 0-1.6-.4-3-1.1-4.3 3.4-4.3 5.5-9.8 5.5-15.4M31 21.9h.1l1 1c.8.8 2.1.8 2.9 0l6.1-6h.1l14.1 14.2H21.7zM38.6 54c-3.4 0-6.6-.8-9.6-2.2-7.2-3.6-11.6-10.7-11.9-18.6H60c-.1 4.8-1.9 9.5-5 13.2-1.6-1.8-3.9-2.9-6.5-2.9-4.9 0-8.8 4-8.8 8.8 0 .6.1 1.1.2 1.7zm9.9 5.1c-3.8 0-6.8-3.1-6.8-6.8s3.1-6.8 6.8-6.8 6.8 3.1 6.8 6.8-3 6.8-6.8 6.8" />
    <path d="M48.5 50.4c-1 0-1.9.8-1.9 1.9 0 1 .8 1.9 1.9 1.9 1 0 1.9-.8 1.9-1.9-.1-1.1-.9-1.9-1.9-1.9" />
  </svg>
);
export default IconWheelbarrowAlt;
