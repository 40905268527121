import * as React from "react";
const IconGe = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22.5 15"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M.8 0h21v15H.8z" />
    <path  d="M9.8 6H0v3h9.8v6h3V9h9.8V6h-9.8V0h-3z" />
    <path
      
      d="m17.3 2.7-.2-1.2h1l-.2 1.2 1.2-.2v1l-1.2-.2.2 1.2h-1l.2-1.2-1.2.2v-1zm-12.7 0-.2-1.2h1l-.2 1.2 1.2-.2v1l-1.2-.2.2 1.2h-1l.2-1.2-1.2.2v-1zm0 9-.2-1.2h1l-.2 1.2 1.2-.2v1l-1.2-.2.2 1.2h-1l.2-1.2-1.2.2v-1zm12.7 0-.2-1.2h1l-.2 1.2 1.2-.2v1l-1.2-.2.2 1.2h-1l.2-1.2-1.2.2v-1z"
    />
  </svg>
);
export default IconGe;
