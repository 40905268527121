import * as React from "react";
const IconUnlinkCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M20.4 5.2C16.2 1 9.4 1 5.2 5.2c-2 2-3.2 4.7-3.2 7.6s1.1 5.6 3.2 7.6l8.3 8.3c2.1 2.1 4.9 3.1 7.6 3.1 2.8 0 5.5-1 7.6-3.1 2-2 3.2-4.7 3.2-7.6s-1.1-5.6-3.1-7.6zm7.3 21.6L24 23.1c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l3.6 3.6c-3.4 2.5-8.3 2.2-11.4-.9l-8.3-8.3C4.9 17.3 4 15.1 4 12.8s.9-4.5 2.6-6.2S10.5 4 12.8 4c2.2 0 4.4.9 6.2 2.6l8.3 8.3c1.6 1.7 2.5 3.9 2.5 6.2 0 2.1-.7 4.1-2.1 5.7M58.9 43.7l-8.4-8.3c-4.2-4.2-11-4.2-15.2 0-2 2-3.2 4.7-3.2 7.6s1.1 5.6 3.2 7.6l8.3 8.3c2.1 2.1 4.8 3.1 7.6 3.1s5.5-1 7.6-3.1c2-2 3.1-4.7 3.2-7.5 0-3-1.1-5.7-3.1-7.7m-1.5 13.7c-3.4 3.4-9 3.4-12.4 0l-8.3-8.3c-1.7-1.7-2.6-3.9-2.6-6.2 0-1.9.6-3.7 1.7-5.2l3.5 3.6c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-3.6-3.6c1.6-1.4 3.7-2.1 5.7-2.1 2.2 0 4.5.9 6.2 2.6l8.3 8.3a9.1 9.1 0 0 1 2.5 6.3c.2 2.2-.7 4.4-2.4 6M39.9 14.5c.6 0 1-.4 1-1V5.6c0-.6-.4-1-1-1s-1 .4-1 1v7.9c0 .6.5 1 1 1M49.5 24.1c0 .6.4 1 1 1h7.9c.6 0 1-.4 1-1s-.4-1-1-1h-7.9c-.6 0-1 .4-1 1M43.8 19.5c.3 0 .5-.1.7-.3l6.7-6.8c.4-.4.4-1 0-1.4s-1-.4-1.4 0L43 17.8c-.4.4-.4 1 0 1.4.2.2.5.3.8.3M24.1 49.5c-.6 0-1 .4-1 1v7.9c0 .6.4 1 1 1s1-.4 1-1v-7.9c0-.5-.4-1-1-1M11.9 39.9c0-.6-.4-1-1-1H5.6c-.6 0-1 .4-1 1s.4 1 1 1h5.3c.6 0 1-.4 1-1M17.3 43.6l-6.7 6.7c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3l6.7-6.7c.4-.4.4-1 0-1.4s-1-.4-1.4 0" />
  </svg>
);
export default IconUnlinkCopy;
