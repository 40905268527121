import * as React from "react";
const IconStairsAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 5.4H45.9c-.8 0-1.6.3-2.3.8L9.1 33.1l-6.7 5.2c-.4.3-.5 1-.2 1.4s1 .5 1.4.2l5.1-4v10.8H6.4c-.1 0-.3 0-.4.1q-.6.3-.6.9v5.7c0 2.8 2.3 5 5 5H57c2.8 0 5-2.3 5-5V10.5c0-2.8-2.3-5.1-5-5.1m3 5.1v8.1H49.8V7.4H57c1.6 0 3 1.4 3 3.1M44.8 7.8c.3-.2.7-.4 1-.4h1.9v11.2H45c-.6 0-1 .4-1 1v8.6h-7.6V14.4zM31.7 28.2c-.6 0-1 .4-1 1v8.3h-7.2V24.4L34.3 16v12.3h-2.6zm-21 6.2L21.5 26v11.6h-3c-.6 0-1 .4-1 1v8.3h-6.8zm29.6 22.2V41.5c0-1.1.9-2 2-2H48c1.1 0 2 .9 2 2v6.4h-1.2c-.6 0-1 .4-1 1s.4 1 1 1H50v6.7zm16.7 0h-5V41.5c0-2.2-1.8-4-4-4h-5.8c-2.2 0-4 1.8-4 4v15.1H10.5c-1.7 0-3-1.4-3-3v-4.7h11.1c.6 0 1-.4 1-1v-8.3h12.2c.6 0 1-.4 1-1v-8.3H45c.6 0 1-.4 1-1v-8.6h14v32.9c0 1.6-1.4 3-3 3" />
  </svg>
);
export default IconStairsAlt;
