import * as React from "react";
const IconOfficeDesk = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 32.1H42v-8.5h11.5c3.2 0 5.8-2.6 5.8-5.8V9.4c0-3.2-2.6-5.8-5.8-5.8h-25c-3.2 0-5.8 2.6-5.8 5.8v8.4c0 3.2 2.6 5.8 5.8 5.8H40v8.5H3c-.6 0-1 .4-1 1v26.3c0 .6.4 1 1 1s1-.4 1-1V45.2h56v14.2c0 .6.4 1 1 1s1-.4 1-1V33.1c0-.6-.4-1-1-1M24.7 17.8V9.4c0-2.1 1.7-3.8 3.8-3.8h25c2.1 0 3.8 1.7 3.8 3.8v8.4c0 2.1-1.7 3.8-3.8 3.8h-25c-2.1 0-3.8-1.7-3.8-3.8M4 34.1h56v9.1H4z" />
    <path d="M4.6 23.6h8.1c1.4 0 2.6-1.2 2.6-2.6V6.2c0-1.4-1.2-2.6-2.6-2.6H4.6C3.2 3.6 2 4.8 2 6.2V21c0 1.4 1.2 2.6 2.6 2.6M4 6.2c0-.3.3-.6.6-.6h8.1c.3 0 .6.3.6.6V21c0 .3-.3.6-.6.6H4.6c-.3 0-.6-.3-.6-.6z" />
  </svg>
);
export default IconOfficeDesk;
