import * as React from "react";
const IconPenAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m60.4 6.1-2.5-2.5c-1.1-1.1-2.5-1.6-4-1.6s-2.9.6-4 1.6l-4.7 4.7L41.9 5c-1.1-1.1-2.5-1.6-4-1.6S35 4 33.9 5L23.4 15.7c-.4.4-.4 1 0 1.4s1 .4 1.4 0L35.4 6.5c1.4-1.4 3.8-1.4 5.1 0l3.3 3.3L14.6 39c-.7.7-1.1 1.6-1.1 2.6 0 .5.1.9.2 1.3L9 47.6c-.6.7-1 1.6-1 2.6s.4 1.9 1.1 2.6l.3.3-7.1 7.1c-.4.4-.4 1 0 1.4.2.3.4.4.7.4s.5-.1.7-.3l7.1-7.1.3.3c.7.7 1.7 1.1 2.6 1.1s1.9-.4 2.6-1.1l4.7-4.7c.4.2.9.2 1.3.2.9 0 1.9-.4 2.6-1.1l29.9-29.9 5.5-5.5c1.1-1.1 1.6-2.5 1.6-4s-.5-2.7-1.5-3.8M15 53.5c-.7.7-1.7.7-2.4 0l-2.1-2.1c-.3-.3-.5-.8-.5-1.2 0-.5.2-.9.5-1.2l4.5-4.5 2.2 2.2 2.2 2.2zm8.6-5.5c-.7.7-1.7.7-2.4 0l-2.6-2.6-2.6-2.6c-.3-.3-.5-.7-.5-1.2s.2-.9.5-1.2l29.2-29.2 7.6 7.6zm35.3-35.4-4.7 4.7-7.6-7.6L51.3 5c1.4-1.4 3.8-1.4 5.1 0l2.5 2.5c.7.7 1.1 1.6 1.1 2.6s-.4 1.9-1.1 2.5" />
  </svg>
);
export default IconPenAlt;
