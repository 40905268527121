import * as React from "react";
const IconPinAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m54.5 35.5-7-11.1V7c0-2.8-2.2-5-5-5h-21c-2.8 0-5 2.2-5 5v17.3l-7 11.1c-.8 1.2-.8 2.7-.1 3.9s1.9 2 3.4 2h13.3-.2L31 61.2c.1.4.5.8 1 .8s.9-.3 1-.8l5.1-19.9h-.2 13.3c1.4 0 2.7-.7 3.4-2 .6-1.2.6-2.6-.1-3.8M18.5 7c0-1.7 1.3-3 3-3h21c1.7 0 3 1.3 3 3v16.6h-27zM32 57l-4-15.6h8zm20.8-18.6c-.3.6-.9 1-1.6 1H12.8c-.7 0-1.3-.4-1.6-1s-.3-1.3 0-1.9L18 25.6h27.9l6.8 10.9c.4.6.4 1.3.1 1.9" />
  </svg>
);
export default IconPinAlt;
