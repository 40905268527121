import * as React from "react";
const IconSprayCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M20.6 23.8H19v-3.2h1.3c1.1 0 2-.9 2-2v-1.8c0-1.1-.9-2-2-2h-4.7c-1.1 0-2 .9-2 2v1.8c0 1.1.9 2 2 2h1.3v3.2h-1.6C9 23.8 3.8 28.9 3.8 35.2v24.2C3.8 60.9 5 62 6.4 62h23.1c1.4 0 2.5-1.1 2.5-2.5V35.2c0-6.3-5.1-11.4-11.4-11.4M29.5 60H6.4c-.3 0-.5-.2-.5-.5V38.3H30v21.2c0 .3-.2.5-.5.5m.5-23.7H5.8v-1.1c0-5.2 4.2-9.5 9.5-9.5h5.3c5.2 0 9.5 4.2 9.5 9.5v1.1zM37.3 15.2h-1.9c-.6 0-1 .4-1 1V18c0 .6.4 1 1 1h1.9c.6 0 1-.4 1-1v-1.9c0-.5-.5-.9-1-.9M58.2 15.2h-1.9c-.6 0-1 .4-1 1V18c0 .6.4 1 1 1h1.9c.6 0 1-.4 1-1v-1.9c0-.5-.5-.9-1-.9M58.2 27.2h-1.9c-.6 0-1 .4-1 1v1.9c0 .6.4 1 1 1h1.9c.6 0 1-.4 1-1v-1.9c0-.5-.5-1-1-1M58.2 3h-1.9c-.6 0-1 .4-1 1v1.9c0 .6.4 1 1 1h1.9c.6 0 1-.4 1-1V4c0-.6-.5-1-1-1M46.3 23.4h-1.9c-.6 0-1 .4-1 1v1.9c0 .6.4 1 1 1h1.9c.6 0 1-.4 1-1v-1.9c0-.6-.5-1-1-1M46.3 6.9h-1.9c-.6 0-1 .4-1 1v1.9c0 .6.4 1 1 1h1.9c.6 0 1-.4 1-1V7.9c0-.6-.5-1-1-1" />
  </svg>
);
export default IconSprayCopy;
