import * as React from "react";
const IconMicrosoftTeams = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      
      d="M61.559 15.254a6.49 6.49 0 0 1-6.49 6.491 6.483 6.483 0 0 1-6.482-6.49c0-3.585 2.906-6.492 6.481-6.492a6.497 6.497 0 0 1 6.491 6.491M44.256 12.368c0 5.183-4.194 9.378-9.368 9.378h-.242v-3.614a4.24 4.24 0 0 0-4.243-4.243h-4.766a9 9 0 0 1-.126-1.521C25.51 7.195 29.706 3 34.888 3a9.363 9.363 0 0 1 9.368 9.368M63 27.365v14.59c0 5.56-4.505 10.075-10.065 10.075h-.049c-1.53 0-2.984-.34-4.292-.96-2.48 5.532-7.973 9.446-14.434 9.61-6.937-.174-12.759-4.669-14.939-10.85h11.18a4.24 4.24 0 0 0 4.243-4.243V24.633h25.614A2.73 2.73 0 0 1 63 27.365"
    />
    <path
      
      d="M32.67 18.113a3 3 0 0 0-.069-.262 2 2 0 0 0-.116-.29 1 1 0 0 0-.077-.155c-.01-.02-.01-.03-.02-.049a3 3 0 0 0-.416-.562 2.67 2.67 0 0 0-1.89-.794H3.645A2.64 2.64 0 0 0 1 18.646v26.428a2.64 2.64 0 0 0 2.645 2.644h26.438a2.64 2.64 0 0 0 2.635-2.644V18.646a3 3 0 0 0-.049-.533m-8.855 7.924h-5.377v14.396h-3.275V26.037h-5.26v-2.79h13.912z"
    />
  </svg>
);
export default IconMicrosoftTeams;
