import React, { useEffect, useRef, useState } from "react";
import BringzzIcon from "../BringzzIcon";
import BringzzImage from "../BringzzImage";
import BringzzText from "../BringzzText";
import { twMerge } from "tailwind-merge";

const BringzzOrder = ({ mode, orderDetails, className, ...props }) => {
  function formatTimeHHMM(date) {
    let hours = date.getHours().toString().padStart(2, "0");
    let minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  }
  return (
    <>
      {mode == 1 && (
        <div className="flex flex-col items-center mr-2">
          <div>
            {orderDetails?.avatar ? (
              <BringzzImage
                width={"40px"}
                className="rounded-full"
                src={orderDetails.avatar}
              />
            ) : (
              <div className="w-[40px] h-[40px] bg-midnight-blue flex items-center justify-center rounded-full">
                <BringzzText className="text-white text-xl">
                  {orderDetails.name[0].toUpperCase()}
                </BringzzText>
              </div>
            )}
          </div>
          <div className="text-center mt-2">
            <div>
              <BringzzText tag="h5" className="text-nowrap">
                {orderDetails.name}
              </BringzzText>
            </div>
            <div>
              <BringzzText className="text-[10px] font-regular">
                {formatTimeHHMM(orderDetails.date)}
              </BringzzText>
            </div>
          </div>
        </div>
      )}
      {mode == 2 && (
        <div
          className={twMerge(
            `flex flex-row items-center gap-x-4 p-3 rounded-md`,
            orderDetails.status == "ready" && "border border-approval-green",
            orderDetails.status == "inProgress" && "border border-attention-orange"
          )}
        >
          <div>
            {orderDetails?.avatar ? (
              <BringzzImage
                width={"40px"}
                className="rounded-full"
                src={orderDetails.avatar}
              />
            ) : (
              <div className="w-[40px] h-[40px] bg-midnight-blue flex items-center justify-center rounded-full">
                <BringzzText className="text-white text-xl">
                  {orderDetails.name[0].toUpperCase()}
                </BringzzText>
              </div>
            )}
          </div>
          <div className="min-w-[90px]">
            <div>
              <BringzzText tag="h5">{orderDetails.name}</BringzzText>
            </div>
            <div>
              <BringzzText className="text-[10px] font-regular">
                {formatTimeHHMM(orderDetails.date)}
              </BringzzText>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BringzzOrder;
