import * as React from "react";
const IconLaptopTimer = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 35.3c7 0 12.8-5.7 12.8-12.8 0-7-5.7-12.8-12.8-12.8s-12.8 5.7-12.8 12.8c0 7 5.8 12.8 12.8 12.8m0-23.5c5.9 0 10.8 4.8 10.8 10.8 0 5.9-4.8 10.8-10.8 10.8-5.9 0-10.8-4.8-10.8-10.8S26.1 11.8 32 11.8" />
    <path d="M33.1 26.5c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-2.2-2.2v-3.7c0-.6-.4-1-1-1s-1 .4-1 1v4.1c0 .3.1.5.3.7z" />
    <path d="M61 52.2h-3.7V26c0-2.5-2-4.5-4.5-4.5H50c-.6 0-1 .4-1 1s.4 1 1 1h2.7c1.4 0 2.5 1.1 2.5 2.5v26.2H8.7V26c0-1.4 1.1-2.5 2.5-2.5H14c.6 0 1-.4 1-1s-.4-1-1-1h-2.7c-2.5 0-4.5 2-4.5 4.5v26.2H3c-.6 0-1 .4-1 1s.4 1 1 1h58c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconLaptopTimer;
