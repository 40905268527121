import * as React from "react";
const IconInventionCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M42.8 22.8c-3.1-2.9-7.2-4.4-11.6-4.2-8.1.5-14.5 7.1-14.5 15.1-.1 5.3 2.5 10.1 6.8 13v7.8c0 3 2.5 5.4 5.5 5.4h6.2c3 0 5.5-2.4 5.5-5.4v-7.8c4.4-2.8 7-7.6 7-12.8-.1-4.2-1.8-8.1-4.9-11.1m-7.7 35.1h-6.2c-1.9 0-3.5-1.5-3.5-3.4v-7.4h13.2v7.4c0 1.9-1.6 3.4-3.5 3.4m4.2-12.7H33v-3.9000000000000004c0-.6-.4-1-1-1s-1 .4-1 1v3.9000000000000004h-6.3c-3.9-2.5-6.3-6.7-6.2-11.4.1-6.9 5.6-12.7 12.7-13.1h.7c3.6 0 6.9 1.3 9.4 3.7 2.7 2.6 4.2 6 4.2 9.7.1 4.4-2.3 8.6-6.2 11.1M32 11.8c.6 0 1-.4 1-1V5.1c0-.6-.4-1-1-1s-1 .4-1 1v5.8c0 .5.4.9 1 .9M57.1 14.1c-.4-.4-1-.4-1.4 0l-4 3.9c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3l4-3.9c.4-.4.4-1 0-1.4M8.3 14.1c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l3.9 3.9c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4zM8.8 37.2H3c-.6 0-1 .4-1 1s.4 1 1 1h5.8c.6 0 1-.4 1-1s-.5-1-1-1M61 37.2h-5.8c-.6 0-1 .4-1 1s.4 1 1 1H61c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconInventionCopy;
