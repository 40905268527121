import * as React from "react";
const IconTabCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M49.3 2H14.7C12 2 9.8 4.2 9.8 6.8v50.4c0 2.6 2.2 4.8 4.8 4.8h34.7c2.6 0 4.8-2.2 4.8-4.8V6.8C54.2 4.2 52 2 49.3 2m2.9 55.2c0 1.5-1.3 2.8-2.8 2.8H14.7c-1.5 0-2.8-1.3-2.8-2.8V6.8c0-1.5 1.3-2.8 2.8-2.8h34.7c1.5 0 2.8 1.3 2.8 2.8z" />
    <path d="M33 11.6c0-.6-.4-1-1-1h-7.9c-.6 0-1 .4-1 1s.4 1 1 1H32c.6 0 1-.4 1-1" />
    <circle cx={40.2} cy={11.6} r={1.5} />
    <circle cx={32} cy={50.9} r={3} />
  </svg>
);
export default IconTabCopy;
