import * as React from "react";
const IconApartmentsAlt13 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.2 28h-8.6V15.7c0-1.3-.6-2.6-1.6-3.5s-2.3-1.3-3.6-1.1l-5.2.7V6.6c0-2.5-2.1-4.6-4.6-4.6h-11C16.1 2 14 4.1 14 6.6v7.9l-3.8.5c-2.3.3-4 2.3-4 4.6v37.9c0 2.5 2.1 4.6 4.6 4.6h42.4c2.5 0 4.6-2.1 4.6-4.6V32.6c0-2.6-2.1-4.6-4.6-4.6M16 6.6C16 5.2 17.2 4 18.6 4h11c1.4 0 2.6 1.2 2.6 2.6v5.5L16 14.2zM30.4 60H10.8c-1.4 0-2.6-1.2-2.6-2.6V19.5c0-1.3 1-2.4 2.3-2.6L39.7 13h.3c.6 0 1.2.2 1.7.6.6.5.9 1.2.9 2v41.7c0 1.4-1.2 2.6-2.6 2.6h-9.6zm25.4-2.6c0 1.4-1.2 2.6-2.6 2.6h-9.4c.5-.7.8-1.6.8-2.6V30h8.6c1.4 0 2.6 1.2 2.6 2.6z" />
    <path d="M20.8 22.8H18c-.6 0-1 .4-1 1v2.8c0 .6.4 1 1 1h2.8c.6 0 1-.4 1-1v-2.8c0-.6-.4-1-1-1M32.8 22.8H30c-.6 0-1 .4-1 1v2.8c0 .6.4 1 1 1h2.8c.6 0 1-.4 1-1v-2.8c0-.6-.4-1-1-1M20.8 34.8H18c-.6 0-1 .4-1 1v2.8c0 .6.4 1 1 1h2.8c.6 0 1-.4 1-1v-2.8c0-.6-.4-1-1-1M32.8 34.8H30c-.6 0-1 .4-1 1v2.8c0 .6.4 1 1 1h2.8c.6 0 1-.4 1-1v-2.8c0-.6-.4-1-1-1M20.8 46.8H18c-.6 0-1 .4-1 1v2.8c0 .6.4 1 1 1h2.8c.6 0 1-.4 1-1v-2.8c0-.6-.4-1-1-1M32.8 46.8H30c-.6 0-1 .4-1 1v2.8c0 .6.4 1 1 1h2.8c.6 0 1-.4 1-1v-2.8c0-.6-.4-1-1-1M51.6 34.1h-2.8c-.6 0-1 .4-1 1v2.8c0 .6.4 1 1 1h2.8c.6 0 1-.4 1-1v-2.8c0-.5-.4-1-1-1M51.6 46.1h-2.8c-.6 0-1 .4-1 1v2.8c0 .6.4 1 1 1h2.8c.6 0 1-.4 1-1v-2.8c0-.5-.4-1-1-1" />
  </svg>
);
export default IconApartmentsAlt13;
