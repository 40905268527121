import * as React from "react";
const IconFlatsAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.6 2H29.1c-2.9 0-5.3 2.4-5.3 5.3v8.4H10.4c-2.9 0-5.3 2.4-5.3 5.3v35.7c0 2.9 2.4 5.3 5.3 5.3h43.1c2.9 0 5.3-2.4 5.3-5.3V7.3c.1-2.9-2.3-5.3-5.2-5.3M7.1 56.7V44.5H16c1.1 0 2 .9 2 2V60h-7.5c-1.9 0-3.4-1.5-3.4-3.3M24.8 60H20V46.5c0-2.2-1.8-4-4-4H7.1V21.1c0-1.8 1.5-3.3 3.3-3.3h14.8c1.8 0 3.3 1.5 3.3 3.3V60zm32.1-3.3c0 1.8-1.5 3.3-3.3 3.3h-23V21.1c0-2.8-2.1-5-4.8-5.3V7.3c0-1.8 1.5-3.3 3.3-3.3h24.4c1.8 0 3.3 1.5 3.3 3.3v49.4z" />
    <path d="M45.6 17.3h-5c-.6 0-1 .4-1 1s.4 1 1 1h5c.6 0 1-.4 1-1s-.5-1-1-1M45.6 31.8h-5c-.6 0-1 .4-1 1s.4 1 1 1h5c.6 0 1-.4 1-1s-.5-1-1-1M45.6 46.3h-5c-.6 0-1 .4-1 1s.4 1 1 1h5c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconFlatsAlt1;
