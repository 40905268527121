import * as React from "react";
const IconMergeVertical = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 49c-.6 0-1-.4-1-1V16c0-.6.4-1 1-1s1 .4 1 1v32c0 .5-.4 1-1 1M4.3 47.3c-.2 0-.5-.1-.6-.2-.4-.4-.5-1-.1-1.4L15 32 3.5 18.4c-.4-.4-.3-1.1.1-1.4.4-.4 1.1-.3 1.4.1l12 14.3c.3.4.3.9 0 1.3L5 47c-.1.2-.4.3-.7.3M59.7 47.3c-.3 0-.6-.1-.8-.4l-12-14.3c-.3-.4-.3-.9 0-1.3l12-14.3c.4-.4 1-.5 1.4-.1s.5 1 .1 1.4L49 32l11.5 13.6c.4.4.3 1.1-.1 1.4-.2.2-.5.3-.7.3" />
  </svg>
);
export default IconMergeVertical;
