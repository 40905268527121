import * as React from "react";
const IconUserMoney = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M25.8 26.9c-6.4 0-12.4 2.9-16.8 8.1S2.2 47.3 2 55.2q0 .6.6.9C5.3 57.5 15.2 62 25.8 62c5.6 0 10.9-.9 11.1-1 .5-.1.9-.6.8-1.2-.1-.5-.6-.9-1.2-.8-.1 0-5.3.9-10.8.9-9.4.1-18.4-3.7-21.7-5.3.3-7.2 2.5-13.7 6.5-18.3 4-4.8 9.4-7.4 15.3-7.4 5.8 0 11.3 2.6 15.3 7.4.4.4 1 .5 1.4.1s.5-1 .1-1.4c-4.4-5.3-10.4-8.1-16.8-8.1M25.8 26.8c6.8 0 12.4-5.6 12.4-12.4S32.6 2 25.8 2 13.4 7.6 13.4 14.4s5.5 12.4 12.4 12.4m0-22.8c5.7 0 10.4 4.7 10.4 10.4s-4.7 10.4-10.4 10.4-10.4-4.7-10.4-10.4S20 4 25.8 4M56.4 47.5h-6.2c-2 0-3.6-1.5-3.6-3.4s1.6-3.4 3.6-3.4h9.1c.6 0 1-.4 1-1s-.4-1-1-1h-4.2V37c0-.6-.4-1-1-1s-1 .4-1 1v1.7h-2.9c-3.1 0-5.6 2.4-5.6 5.4s2.5 5.4 5.6 5.4h6.2c2 0 3.6 1.5 3.6 3.4s-1.6 3.4-3.6 3.4H46c-.6 0-1 .4-1 1s.4 1 1 1h7.2V60c0 .6.4 1 1 1s1-.4 1-1v-1.7h1.3c3.1 0 5.6-2.4 5.6-5.4s-2.6-5.4-5.7-5.4" />
  </svg>
);
export default IconUserMoney;
