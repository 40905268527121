import * as React from "react";
const IconChickenLegAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.1 6.9C54 3.7 49.8 2 45.3 2s-8.6 1.7-11.8 4.9c-3 3-6.5 8.6-9 14.4l-.3.6c-2.9 6.8-5.7 13.2-3.8 17.1.1.1.6 1.4.9 2.2l-11 11.1-7-2.9c-.5-.2-1.1 0-1.3.5s0 1.1.5 1.3l7.2 3 3 7.2q.3.6.9.6c.1 0 .3 0 .4-.1.5-.2.8-.8.5-1.3l-2.8-6.9 11.1-11.1c.3.2.8.3 1.2.5.8.4 1.1.5 1.2.5.4.2 1 .2 1.7.2 3.8 0 11.3-2.3 15.9-4.4 5.8-2.5 11.4-6.1 14.4-9 3.1-3.2 4.9-7.4 4.9-11.8-.1-4.3-1.8-8.5-5-11.7m-1.4 22.2c-2.8 2.8-8.2 6.2-13.8 8.6-7.2 3.2-14.7 4.7-16.1 4.1h-.1l-1-.5c-.7-.3-1.1-.5-1.4-.6-.4-1-1.1-2.5-1.1-2.5-1.5-3.1 1.3-9.7 3.9-15.5l.3-.6c2.4-5.6 5.8-11 8.6-13.8C37.7 5.5 41.4 4 45.3 4s7.6 1.5 10.4 4.3S60 14.8 60 18.7s-1.5 7.7-4.3 10.4" />
  </svg>
);
export default IconChickenLegAlt;
