import * as React from "react";
const IconIndentIncreaseCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M7.7 4h47.4c.6 0 1-.4 1-1s-.4-1-1-1H7.7c-.6 0-1 .4-1 1s.4 1 1 1M55.1 60H7.7c-.6 0-1 .4-1 1s.4 1 1 1h47.4c.6 0 1-.4 1-1s-.4-1-1-1M56.6 30.4l-10.4-8.9c-.5-.4-1.2-.5-1.8-.3-.7.3-1.1 1-1.1 1.7v18q.15 1.05 1.2 1.5c.2.1.5.1.7.1.4 0 .8-.1 1.1-.4l10.3-8.9c.4-.3.7-.9.7-1.5 0-.4-.2-1-.7-1.3M45.3 40.5V23.4l10 8.5zM7.7 18.5h22.6c.6 0 1-.4 1-1s-.4-1-1-1H7.7c-.6 0-1 .4-1 1s.4 1 1 1M7.7 33h22.6c.6 0 1-.4 1-1s-.4-1-1-1H7.7c-.6 0-1 .4-1 1s.4 1 1 1M7.7 47.5h22.6c.6 0 1-.4 1-1s-.4-1-1-1H7.7c-.6 0-1 .4-1 1s.4 1 1 1" />
  </svg>
);
export default IconIndentIncreaseCopy;
