import * as React from "react";
const IconDress1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M49.5 37.9c-1.3-6.4-2.6-12.5-1.2-18.5v-.1c.2-.8.4-1.6.1-2.5-.3-1.2-1.3-1.9-1.9-2.4l-2.4-1.8c-.5-.3-1.1-.8-1.8-1V3c0-.6-.4-1-1-1s-1 .4-1 1v8.7c-.7.3-1.4.7-1.8 1.1l-4.7 3.5c-.8.6-1.5 1.1-2.1 1-.6 0-1.2-.5-1.9-1L25.7 13c-.6-.5-1.3-1-2.2-1.2-.2-.1-.4-.1-.7-.1V3c0-.6-.4-1-1-1s-1 .4-1 1v9c-1 .4-1.9 1-2.5 1.5-1 .8-2.5 1.9-2.9 3.6-.2 1.1.1 2.2.3 3.1l.1.4c.8 3 .8 6.4-.1 10.8-.3 1.6-.7 3.3-1.1 4.9-.5 2-1 4.1-1.4 6.2-.8 4.4-.9 8.9-.3 13.4.5 3.7 2.2 5.6 5.5 6 .8.1 25.4.1 25.6.1 1.3 0 3.1 0 4.5-1 1.4-1.1 2-2.8 2.3-4.2 1.4-6.3 0-12.7-1.3-18.9M17.8 20.1l-.1-.4c-.2-.8-.4-1.5-.3-2.2.2-.9 1.1-1.7 2.1-2.5 1.1-.8 2.3-1.7 3.4-1.4.5.1 1 .5 1.5.9l4.3 3.3c.7.6 1.8 1.4 3.1 1.4 1.4 0 2.6-.8 3.4-1.4l4.7-3.5c.6-.5 1.3-1 1.9-.9.4 0 .8.3 1.3.7.8.6 1.6 1.2 2.3 1.8.6.4 1.1.9 1.2 1.4.1.4 0 .9-.2 1.5v.1c-1.4 5.6-.5 11.4.7 17-4.2.3-9 .5-14.1.5-5.8 0-11.5-.3-16.2-.7.3-1.3.6-2.6.9-4 1-4.5 1-8.3.1-11.6m31.1 36.3q-.45 2.25-1.5 3c-.8.6-2 .6-3.4.6-8.4 0-24.3 0-25.3-.1-2.4-.3-3.4-1.5-3.8-4.3-.6-4.2-.5-8.5.3-12.7.3-1.7.7-3.4 1.1-5.1 4.8.5 10.6.7 16.6.7 5.2 0 10.2-.2 14.5-.5 0 .1.1.2.1.4 1.3 5.9 2.6 12.1 1.4 18" />
  </svg>
);
export default IconDress1;
