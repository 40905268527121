import * as React from "react";
const IconWindowMaximize = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60 28.6c-.6 0-1 .4-1 1V56c0 1.7-1.3 3-3 3H8c-1.7 0-3-1.3-3-3V8c0-1.7 1.3-3 3-3h26.5c.6 0 1-.4 1-1s-.4-1-1-1H8C5.2 3 3 5.2 3 8v48c0 2.8 2.2 5 5 5h48c2.8 0 5-2.2 5-5V29.6c0-.5-.4-1-1-1" />
    <path d="M60 3.1H46c-.6 0-1 .4-1 1s.4 1 1 1h11.5L31.3 31.3c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3L59 6.4V18c0 .6.4 1 1 1s1-.4 1-1V4.1c0-.5-.4-1-1-1" />
  </svg>
);
export default IconWindowMaximize;
