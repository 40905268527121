import * as React from "react";
const IconJumpRope = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.4 5.5H13.6C10.5 5.5 8 8 8 11.1v16.8H6c-2.2 0-4 1.8-4 4v22.6c0 2.2 1.8 4 4 4h6.1c2.2 0 4-1.8 4-4V31.9c0-2.2-1.8-4-4-4h-2V11.1c0-2 1.6-3.6 3.6-3.6h42.8c2 0 3.6 1.6 3.6 3.6v39.7c0 3.2-2.6 5.8-5.8 5.8s-5.8-2.6-5.8-5.8V26c0-5-4.1-9.1-9.1-9.2h-.1c-2.4 0-4.7.9-6.5 2.7-1.8 1.7-2.7 4.1-2.7 6.5v1.9h-2c-2.2 0-4 1.8-4 4v22.6c0 2.2 1.8 4 4 4h6.1c2.2 0 4-1.8 4-4V31.9c0-2.2-1.8-4-4-4h-2V26c0-1.9.8-3.7 2.1-5.1 1.4-1.4 3.2-2.1 5.1-2.1 3.9 0 7.1 3.3 7.1 7.2v24.8c0 4.3 3.5 7.8 7.8 7.8S62 55 62 50.8V11.1c0-3.1-2.5-5.6-5.6-5.6M14.1 31.9v22.6c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V31.9c0-1.1.9-2 2-2h6.1c1.1 0 2 .9 2 2m22 0v22.6c0 1.1-.9 2-2 2H28c-1.1 0-2-.9-2-2V31.9c0-1.1.9-2 2-2h6.1c1.1 0 2 .9 2 2" />
  </svg>
);
export default IconJumpRope;
