import * as React from "react";
const IconFenceAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 12.7h-6.5c-2.2 0-4 1.8-4 4v9.5h-7.3v-9.5c0-2.2-1.8-4-4-4h-6.5c-2.2 0-4 1.8-4 4v9.5h-7.3v-9.5c0-2.2-1.8-4-4-4H7c-2.2 0-4 1.8-4 4v30.7c0 2.2 1.8 4 4 4h6.5c2.2 0 4-1.8 4-4v-9.5h7.3v9.5c0 2.2 1.8 4 4 4h6.5c2.2 0 4-1.8 4-4v-9.5h7.3v9.5c0 2.2 1.8 4 4 4H57c2.2 0 4-1.8 4-4V16.7c0-2.2-1.8-4-4-4M15.5 47.3c0 1.1-.9 2-2 2H7c-1.1 0-2-.9-2-2V16.7c0-1.1.9-2 2-2h6.5c1.1 0 2 .9 2 2zm2-11.5v-7.7h7.3v7.7zm19.8 11.5c0 1.1-.9 2-2 2h-6.5c-1.1 0-2-.9-2-2V16.7c0-1.1.9-2 2-2h6.5c1.1 0 2 .9 2 2zm2-11.5v-7.7h7.3v7.7zM59 47.3c0 1.1-.9 2-2 2h-6.5c-1.1 0-2-.9-2-2V16.7c0-1.1.9-2 2-2H57c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconFenceAlt;
