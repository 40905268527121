import * as React from "react";
const IconDiploma = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m27 27.3-.8 4.4c-.1.4.1.8.4 1s.7.3 1.1.1l4-2.1 4 2.1c.1.1.3.1.5.1s.4-.1.6-.2c.3-.2.5-.6.4-1l-.8-4.4 3.2-3.1c.3-.3.4-.7.3-1-.1-.4-.4-.6-.8-.7l-4.4-.6-2-4c-.2-.3-.5-.6-.9-.6s-.7.2-.9.6l-2 4-4.4.6c-.4.1-.7.3-.8.7s0 .8.3 1zm2.6-3.5c.3 0 .6-.3.8-.5l1.3-2.7 1.3 2.7c.1.3.4.5.8.5l2.9.4-2.1 2.1c-.2.2-.3.6-.3.9l.5 2.9-2.6-1.4q-.45-.3-.9 0l-2.6 1.4.5-2.9c.1-.3-.1-.7-.3-.9l-2.1-2.1z" />
    <path d="M58 9.4H6c-2.2 0-4 1.8-4 4v37.3c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V13.4c0-2.2-1.8-4-4-4M4 20.5c.8.3 1.7.4 2.5.4 4.4 0 7.9-3.6 7.9-7.9 0-.6-.1-1.1-.2-1.6H49c-.1.5-.2 1.1-.2 1.6 0 4.4 3.6 7.9 7.9 7.9 1.1 0 2.2-.2 3.2-.7v22.4c-1-.4-2.1-.7-3.2-.7-4.4 0-7.9 3.6-7.9 7.9 0 1 .2 1.9.5 2.8H13.9c.3-.9.5-1.8.5-2.8 0-4.4-3.6-7.9-7.9-7.9-.9 0-1.7.2-2.5.4zm56-7.1V18c-.1 0-.1 0-.2.1-.9.5-1.9.8-3 .8-3.3 0-5.9-2.7-5.9-5.9 0-.6.1-1.1.3-1.6H58c1.1 0 2 .9 2 2m-54-2h6.2c.2.5.3 1.1.3 1.6 0 3.3-2.7 5.9-5.9 5.9-.9 0-1.8-.2-2.5-.6v-5c-.1-1 .8-1.9 1.9-1.9M4 50.6v-6.1c.8-.4 1.6-.6 2.5-.6 3.3 0 5.9 2.7 5.9 5.9 0 .8-.1 1.5-.4 2.2-.1.2-.1.4 0 .5H6c-1.1.1-2-.8-2-1.9m54 2h-6.6v-.5c-.3-.7-.4-1.5-.4-2.2 0-3.3 2.7-5.9 5.9-5.9 1.1 0 2.1.3 3 .8.1 0 .1 0 .2.1v5.8c-.1 1-1 1.9-2.1 1.9" />
    <path d="M17.8 40.6h27.7c.6 0 1-.4 1-1s-.4-1-1-1H17.8c-.6 0-1 .4-1 1s.5 1 1 1M38.9 45.1H24.5c-.6 0-1 .4-1 1s.4 1 1 1h14.3c.6 0 1-.4 1-1s-.4-1-.9-1" />
  </svg>
);
export default IconDiploma;
