import * as React from "react";
const IconApartmentsAlt10 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.7 9.2c-1.5-.9-3.3-1-4.8-.2l-12.4 6.2c-1.7.8-2.7 2.5-2.7 4.4v2.7H24.3V8.5c0-1.7-.9-3.3-2.3-4.2s-3.3-1-4.8-.2L4.7 10.3C3 11.1 2 12.8 2 14.7v44.8c0 .6.4 1 1 1h58c.6 0 1-.4 1-1v-46c0-1.8-.9-3.4-2.3-4.3M47.3 58.5h-9.1V44.2c0-.6-.4-1-1-1H26.9c-.6 0-1 .4-1 1v14.3h-8.7V27.3c0-1.6 1.3-2.9 2.9-2.9h24.1c1.6 0 2.9 1.3 2.9 2.9v31.2zm-19.4 0V45.2h8.3v13.3zM4 14.7c0-1.1.6-2.1 1.6-2.6L18 5.9c1-.5 2-.5 2.9.1.9.5 1.4 1.5 1.4 2.5v13.9h-2.2c-2.7 0-4.9 2.2-4.9 4.9v31.1H4zm56 43.8H49.3V27.3c0-2.7-2.2-4.9-4.9-4.9h-2.6v-2.7c0-1.1.6-2.1 1.6-2.6l12.4-6.2c.9-.5 2-.4 2.9.1s1.4 1.5 1.4 2.5v45z" />
  </svg>
);
export default IconApartmentsAlt10;
