import React, { useEffect, useState } from 'react';
import { BringzzBoxSlider, BringzzButton, BringzzOrder, BringzzText } from "@bringzz/components";
import { useNavigation } from 'context/NavigationContext';
import {
    BringzzPageContainer,
    BringzzIcon,
    BringzzOrderCard,
    BringzzBottomDrawer,
    BringzzCheck,
    BringzzMultiSelect,
    BringzzBasketProduct
} from "@bringzz/components";
import useRequest from 'hooks/useRequest';

let itemState = {
    checked: (
        <BringzzIcon
            icon="IconCheck"
            library="Custom"
            className="h-5 w-5 p-1 bg-magic-lilac text-light rounded-full"
            size="12"
        />
    ),
    unChecked: (
        <div></div>
    ),
};

const ConsumerOrdersPage = () => {
    const { backPress } = useNavigation();

    const { navigate } = useNavigation();
    const { sendRequest, loading } = useRequest();
    const [orders, setOrders] = useState([])

    const [products, setProducts] = useState([
        {
            name: 'Product Name 1',
            manufacturer: 'Manufacturer',
            amount: '84 Stk | Kapseln',
            quantity: '10',
            imgUrl: '/product.png',
            price: 9,
            inhalt: ""
        },
        {
            name: 'Product Name 2',
            manufacturer: 'Manufacturer 2',
            amount: '12 Stk | Kapseln',
            quantity: '3',
            imgUrl: '/product.png',
            price: 8,
            inhalt: "Prescription attached"
        }])

    const [reorderDrawerOpen, setReorderDrawerOpen] = useState(false)
    const [selectedItems, setSelectedItems] = useState([])

    const handleMultiSelectChange = (selectedValues) =>
        setSelectedItems(selectedValues)

    const fetchOrders = async () => {
        await sendRequest({
            method: 'GET',
            url: `/orders`,
        }).then((response) => {
            if (response.status === 'success') {
                setOrders(response.data)
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    useEffect(() => {
        fetchOrders()
    }, [])

    return (
        <BringzzPageContainer
            className="bg-white pb-44"
            header={{
                left: (
                    <BringzzIcon
                        size='22'
                        icon='IconChevronLeft'
                        folder='LineIcons'
                        className='cursor-pointer'
                        onClick={backPress}
                    />
                ),
                title: "Orders"
            }}

            authenticated={false}
        >
            <div>
                {orders.accepted &&
                    <div className='px-6 pb-2'>
                        <div className='flex justify-between items-center'>
                            <BringzzText tag='h3' className={"my-4"}>Active Orders</BringzzText>
                        </div>
                        <div className='space-y-4'>
                            {orders.accepted.map(((order, index) => {
                                return <div key={index}>
                                    <BringzzOrderCard
                                        orderDetails={{
                                            name: order.pharmacyName,
                                            date: new Date(order.orderdate),
                                            price: order.price,
                                            itemsCount: order.products.length
                                        }}
                                        onClick={() => navigate('/consumer/orders/view')}
                                    ></BringzzOrderCard>
                                </div>
                            }))}
                        </div>
                    </div>
                }
                {orders.completed &&
                    <div className='px-6 pb-8'>
                        <div className='flex justify-between items-center'>
                            <BringzzText tag='h3' className={"my-4"}>Past Orders</BringzzText>
                        </div>
                        <div className='space-y-4'>
                            {orders.completed.map(((order, index) => {
                                return <div key={index}>
                                    <BringzzOrderCard
                                        orderDetails={{
                                            name: order.pharmacyName,
                                            date: new Date(order.orderdate),
                                            price: order.price,
                                            itemsCount: order.products.length
                                        }}
                                        mode='reorder'
                                        onClick={() => setReorderDrawerOpen(true)}
                                    ></BringzzOrderCard>
                                </div>
                            }))}
                        </div>
                    </div>
                }
                <BringzzBottomDrawer
                    isOpen={reorderDrawerOpen}
                    close={() => setReorderDrawerOpen(false)}
                    title='Select items'
                >
                    <BringzzMultiSelect
                        onChange={handleMultiSelectChange}
                        state={{ checked: itemState.checked, unChecked: itemState.unChecked }}
                        className="divide-y px-6"
                    >
                        {products.map((product, index) => {
                            return <BringzzCheck mode='custom' key={index} value={product.name}>
                                <BringzzBasketProduct
                                    key={index + 'product'}
                                    product={product}
                                    mode="select"
                                />
                            </BringzzCheck>
                        })}
                    </BringzzMultiSelect>
                    <div className='w-full px-6 py-2'>
                        <BringzzButton disabled={selectedItems.length == 0} size='lg' className='w-full bg-magic-lilac flex justify-center' onClick={() => {
                            setReorderDrawerOpen(false)
                        }}>Add items to cart</BringzzButton>
                    </div>
                </BringzzBottomDrawer>
            </div>
        </BringzzPageContainer >
    );
};

export default ConsumerOrdersPage;
