import * as React from "react";
const IconContraceptivePatch = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M34 8H14a6 6 0 0 0-6 6v20a6 6 0 0 0 6 6h20a6 6 0 0 0 6-6V14a6 6 0 0 0-6-6M14 6a8 8 0 0 0-8 8v20a8 8 0 0 0 8 8h20a8 8 0 0 0 8-8V14a8 8 0 0 0-8-8z"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M29.9 16.1H18.1a2 2 0 0 0-2 2v11.8a2 2 0 0 0 2 2h11.8a2 2 0 0 0 2-2V18.1a2 2 0 0 0-2-2m-11.8-2a4 4 0 0 0-4 4v11.8a4 4 0 0 0 4 4h11.8a4 4 0 0 0 4-4V18.1a4 4 0 0 0-4-4z"
      clipRule="evenodd"
    />
    <path
      
      d="M21.3 18.6a.9.9 0 1 1-1.8 0 .9.9 0 0 1 1.8 0M23.1 21.3a.9.9 0 1 1-1.8 0 .9.9 0 0 1 1.8 0M19.5 21.3a.9.9 0 1 1-1.8 0 .9.9 0 0 1 1.8 0M21.3 24a.9.9 0 1 1-1.8 0 .9.9 0 0 1 1.8 0M23.1 26.7a.9.9 0 1 1-1.8 0 .9.9 0 0 1 1.8 0M19.5 26.7a.9.9 0 1 1-1.8 0 .9.9 0 0 1 1.8 0M21.3 29.4a.9.9 0 1 1-1.8 0 .9.9 0 0 1 1.8 0M24.9 18.6a.9.9 0 1 1-1.8 0 .9.9 0 0 1 1.8 0M26.7 21.3a.9.9 0 1 1-1.8 0 .9.9 0 0 1 1.8 0M24.9 24a.9.9 0 1 1-1.8 0 .9.9 0 0 1 1.8 0M26.7 26.7a.9.9 0 1 1-1.8 0 .9.9 0 0 1 1.8 0M24.9 29.4a.9.9 0 1 1-1.8 0 .9.9 0 0 1 1.8 0M28.5 18.6a.9.9 0 1 1-1.8 0 .9.9 0 0 1 1.8 0M30.3 21.3a.9.9 0 1 1-1.8 0 .9.9 0 0 1 1.8 0M28.5 24a.9.9 0 1 1-1.8 0 .9.9 0 0 1 1.8 0M30.3 26.7a.9.9 0 1 1-1.8 0 .9.9 0 0 1 1.8 0M28.5 29.4a.9.9 0 1 1-1.8 0 .9.9 0 0 1 1.8 0"
    />
  </svg>
);
export default IconContraceptivePatch;
