import * as React from "react";
const IconSpeechBubble12 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M49.5 41.7c0-1.1-.2-2.2-.6-3.2 3.4-4 5.3-9.1 5.3-14.3C54.2 11.9 44.2 2 32 2S9.8 11.9 9.8 24.2c0 6.1 2.4 11.7 6.8 15.9 4.2 4 9.6 6.2 15.4 6.2h.2c.6 1.1 1.5 2.1 2.5 2.8 1.6 1.2 3.6 1.9 5.6 1.9 1.4 0 2.8-.3 4.2-1 3-1.6 5-4.8 5-8.3m-13.7 5.8c-.9-.7-1.7-1.6-2.2-2.7-.2-.3-.5-.6-.9-.6-5.5.3-10.7-1.7-14.7-5.6-4-3.8-6.2-9-6.2-14.5C11.8 13 20.9 4 32 4s20.2 9 20.2 20.2c0 5-1.8 9.8-5.2 13.5-.3.3-.3.7-.2 1.1.4.9.6 2 .6 3 0 2.8-1.5 5.2-4 6.5-2.5 1.2-5.4.9-7.6-.8M48.5 52.8c-2.5 0-4.6 2.1-4.6 4.6S46 62 48.5 62s4.6-2.1 4.6-4.6c0-2.6-2-4.6-4.6-4.6m0 7.2c-1.4 0-2.6-1.2-2.6-2.6s1.2-2.6 2.6-2.6 2.6 1.2 2.6 2.6S50 60 48.5 60" />
    <path d="M40.2 15.1H24.7c-.6 0-1 .4-1 1s.4 1 1 1h15.5c.6 0 1-.4 1-1s-.4-1-1-1M40.2 23.5H24.7c-.6 0-1 .4-1 1s.4 1 1 1h15.5c.6 0 1-.4 1-1s-.4-1-1-1M40.2 31.9H24.7c-.6 0-1 .4-1 1s.4 1 1 1h15.5c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconSpeechBubble12;
