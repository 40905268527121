import * as React from "react";
const IconSquares1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M23.6 2H7.1C4.3 2 2 4.3 2 7.1v16.4c0 2.8 2.3 5.1 5.1 5.1h16.4c2.8 0 5.1-2.3 5.1-5.1V7.1c.1-2.8-2.2-5.1-5-5.1m3.1 21.6c0 1.7-1.4 3.1-3.1 3.1H7.1c-1.7 0-3.1-1.4-3.1-3.1V7.1C4 5.4 5.4 4 7.1 4h16.4c1.7 0 3.1 1.4 3.1 3.1v16.5zM56.9 2H40.4c-2.8 0-5.1 2.3-5.1 5.1v16.4c0 2.8 2.3 5.1 5.1 5.1h16.4c2.8 0 5.1-2.3 5.1-5.1V7.1c.1-2.8-2.2-5.1-5-5.1M60 23.6c0 1.7-1.4 3.1-3.1 3.1H40.4c-1.7 0-3.1-1.4-3.1-3.1V7.1c0-1.7 1.4-3.1 3.1-3.1h16.4C58.6 4 60 5.4 60 7.1zM23.6 35.3H7.1c-2.8 0-5.1 2.3-5.1 5.1v16.4C2 59.7 4.3 62 7.1 62h16.4c2.8 0 5.1-2.3 5.1-5.1V40.4c.1-2.8-2.2-5.1-5-5.1m3.1 21.6c0 1.7-1.4 3.1-3.1 3.1H7.1C5.4 60 4 58.6 4 56.9V40.4c0-1.7 1.4-3.1 3.1-3.1h16.4c1.7 0 3.1 1.4 3.1 3.1v16.5z" />
  </svg>
);
export default IconSquares1;
