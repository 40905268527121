import * as React from "react";
const IconTableFan = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m47.5 53-10.2-2.6-1.3-7.1c0-.2-.2-.4-.3-.5 9.6-1.8 16.9-10.2 16.9-20.2C52.6 11.2 43.3 2 32 2s-20.6 9.2-20.6 20.6c0 9.9 7 18.1 16.3 20.1-.2.1-.4.4-.4.6L26 50.5 15.8 53c-.4.1-.8.5-.8 1v7c0 .6.4 1 1 1h31.2c.6 0 1-.4 1-1v-7c.1-.5-.3-.9-.7-1M13.4 22.6C13.4 12.3 21.8 4 32 4s18.6 8.3 18.6 18.6c0 10.2-8.3 18.6-18.6 18.6-10.2 0-18.6-8.4-18.6-18.6M46.3 60H17.1v-5.2l10.1-2.5c.4-.1.7-.4.7-.8l1.4-7.8c0-.3 0-.5-.2-.8 1 .1 1.9.2 2.9.2.7 0 1.5 0 2.2-.1-.1.2-.2.4-.1.6l1.4 7.8c.1.4.4.7.7.8l10.1 2.5z" />
    <path d="M32.9 35.5s.1 0 0 0c.6 0 1-.5 1-1.1l-.4-6.8c1-.3 2-1 2.7-1.9.8-1.1 1.2-2.5 1-3.9-.2-1-.6-2-1.3-2.7l5.8-3.1c.5-.3.7-.9.4-1.4s-.9-.7-1.4-.4l-6.2 3.3q-.3.15-.3.3c-1-.5-2-.6-3.1-.5-1.1.2-2.1.7-2.9 1.5l-6.7-2.5c-.5-.2-1.1.1-1.3.6s.1 1.1.6 1.3l6.3 2.4c-.9 2.2-.2 4.8 1.8 6.3.8.6 1.8.9 2.7 1l.3 6.7c.1.5.5.9 1 .9m-4.2-12.4q-.15-1.35.6-2.4c.5-.7 1.3-1.2 2.2-1.3h.5c.7 0 1.4.2 1.9.6.7.5 1.2 1.3 1.3 2.2q.15 1.35-.6 2.4c-1.1 1.5-3.1 1.8-4.6.7-.7-.6-1.1-1.3-1.3-2.2" />
  </svg>
);
export default IconTableFan;
