import * as React from "react";
const IconInformationCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <circle cx={31.2} cy={6.9} r={3.9} />
    <path d="M38.7 60H33V20.5c0-1.8-1.5-3.3-3.2-3.3h-4.5c-.6 0-1 .4-1 1s.4 1 1 1h4.5c.7 0 1.2.6 1.2 1.3V60h-5.7c-.6 0-1 .4-1 1s.4 1 1 1h13.5c.6 0 1-.4 1-1s-.5-1-1.1-1" />
  </svg>
);
export default IconInformationCopy;
