import * as React from "react";
const IconVaccine = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.7 16.5c4 0 7.2-3.3 7.2-7.2S58.7 2 54.7 2s-7.2 3.3-7.2 7.2c0 1.6.6 3.1 1.5 4.4l-4.5 4.5-.1-.1c-1-.6-2.1-.9-3.2-.9.4-.9.7-1.9.7-3 0-4-3.3-7.2-7.2-7.2s-7.2 3.3-7.2 7.2 3.3 7.2 7.2 7.2h.1l-3.3 3.3c-1.5-.9-3.5-.7-4.8.6L9.9 42A6.42 6.42 0 0 0 8 46.6c0 1.7.7 3.4 1.9 4.6l.8.8-8.3 8.3c-.4.4-.4 1 0 1.4.1.2.4.3.6.3s.5-.1.7-.3l8.3-8.3.8.8c1.3 1.3 2.9 1.9 4.6 1.9s3.3-.6 4.6-1.9l16.7-16.7c.8-.8 1.2-1.8 1.2-2.8 0-.8-.2-1.5-.6-2.2l3.2-3.2v.1c0 4 3.3 7.2 7.2 7.2s7.2-3.3 7.2-7.2c0-4-3.3-7.2-7.2-7.2q-1.65 0-3 .6v-.1c0-1.2-.3-2.3-1-3.2l4.5-4.5c1.4.9 2.9 1.5 4.5 1.5m0-12.5C57.6 4 60 6.4 60 9.2s-2.4 5.2-5.2 5.2-5.2-2.4-5.2-5.2S51.8 4 54.7 4M43.2 19.7c1 .7 1.6 1.8 1.6 3 0 1-.4 1.8-1 2.5L38 31.1 32.9 26l5.7-5.7c1.3-1.2 3.3-1.5 4.6-.6m-13.9-5.5c0-2.9 2.4-5.2 5.2-5.2 2.9 0 5.2 2.4 5.2 5.2 0 2.9-2.4 5.2-5.2 5.2s-5.2-2.3-5.2-5.2m8.1 21.9L20.7 52.8c-1.8 1.8-4.6 1.8-6.4 0l-1.5-1.5-1.5-1.5c-.9-.9-1.3-2-1.3-3.2s.5-2.4 1.3-3.2L28 26.7c.8-.8 2-.8 2.8 0l6.6 6.6c.4.4.6.9.6 1.4s-.2 1-.6 1.4M55 29.5c0 2.9-2.4 5.2-5.2 5.2s-5.2-2.4-5.2-5.2 2.4-5.2 5.2-5.2 5.2 2.3 5.2 5.2" />
  </svg>
);
export default IconVaccine;
