import * as React from "react";
const IconSynergistInsecticideBioassays = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M14 24a5 5 0 1 1 0 10 5 5 0 0 1 0-10m0 2a3 3 0 0 1 2.708 4.293l-4.001-4c.391-.188.83-.293 1.293-.293m-2.708 1.707a3 3 0 0 0 4.001 4.001z"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M11 6a1 1 0 0 0-1 1v3.333a1 1 0 0 0 1 1h2V13H9a1 1 0 1 0 0 2h.063l-2.895 6.617a2 2 0 0 0-.168.801V41a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V22.418a2 2 0 0 0-.168-.801L18.937 15H19a1 1 0 1 0 0-2h-4v-1.667h2a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1zm.245 9L8 22.418V40h12V22.418L16.755 15zM14 10a1.333 1.333 0 1 0 0-2.667A1.333 1.333 0 0 0 14 10"
      clipRule="evenodd"
    />
    <path
      
      d="M36.677 31.486a1 1 0 1 0-1.781-.91l-1.29 2.523q-.066.134-.107.273a1.5 1.5 0 0 0 .694 1.709l1.435.828-.134 2.222a9 9 0 0 0-.432.086 1 1 0 0 0-.243-.683l-2.225-2.577a1.5 1.5 0 0 0-1.135-.52h-.937l-1.65-2.928a1 1 0 1 0-1.743.982l1.866 3.31a1.25 1.25 0 0 0 1.089.636h1.146l2.066 2.393a4.2 4.2 0 0 0-.957.625c-.268-.86-1.509-1.316-2.841-1.026-.968.211-1.727.76-2.027 1.387l-.04.026c-.291.202-.6.197-.814.059C26.404 39.762 26 39.3 26 38a1 1 0 1 0-2 0c0 1.667.528 2.93 1.53 3.58.903.584 1.96.515 2.778.068.525.216 1.208.273 1.917.119.834-.182 1.512-.614 1.876-1.131C32.403 41.429 33.505 42 37 42c4.375 0 5-.895 5-2 0-.388-.758-1.001-1.936-1.447l1.13-1.043a1.5 1.5 0 0 0 .271-1.868l-.928-1.562 1.21-2.151a1 1 0 0 0-1.744-.98l-1.422 2.528c-.22.39-.214.867.015 1.252l.943 1.587-1.853 1.711-.181-.012.137-2.295a1.5 1.5 0 0 0-.747-1.389l-1.291-.745z"
    />
    <path
      
      fillRule="evenodd"
      d="M41 19.8c.616.338 1 .985 1 1.69V26H32v-4.51c0-.705.384-1.352 1-1.69v-7.6c-.616-.338-1-.985-1-1.69V6h10v4.51c0 .705-.384 1.352-1 1.69zm-6-7.644c0-.705-.384-1.352-1-1.69v-.695h6v.696c-.616.337-1 .984-1 1.689v7.688c0 .705.384 1.352 1 1.69v.695h-6v-.696c.616-.337 1-.984 1-1.689z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconSynergistInsecticideBioassays;
