import * as React from "react";
const IconPieChartAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M39.2 6.9c-1.3-.2-2.6-.4-3.9-.4V3c0-.6-.5-1-1.1-1C18.7 2 5.4 13.1 2.6 28.4c-.3 1.9-.5 3.8-.5 5.8 0 .6.4 1 1 1h3.5c.4 13 9.8 24 22.7 26.3 1.7.3 3.3.5 5 .5 5.6 0 11-1.7 15.7-5 6.1-4.2 10.2-10.6 11.5-17.9 2.7-15-7.3-29.4-22.3-32.2M4.6 28.8C7.1 14.7 19.1 4.5 33.2 4v29.2H4.1c0-1.5.2-3 .5-4.4m54.9 10c-1.2 6.8-5 12.7-10.7 16.6s-12.5 5.4-19.2 4.2C17.7 57.4 9 47.2 8.6 35.2h25.7c.6 0 1-.4 1-1V8.5c1.2 0 2.4.2 3.6.4 13.8 2.5 23.1 16 20.6 29.9" />
  </svg>
);
export default IconPieChartAlt1;
