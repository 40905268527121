import * as React from "react";
const IconOutpatient = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M23.889 9.5a4.502 4.502 0 0 0-8.889 1 4.5 4.5 0 0 0 8.888 1H24a1 1 0 0 0 .123-.008c.23.887.76 1.508 1.377 1.508.828 0 1.5-1.12 1.5-2.5S26.328 8 25.5 8c-.617 0-1.147.62-1.377 1.507A1 1 0 0 0 24 9.5zm-2.123 2.059a2.5 2.5 0 0 1-4.733-.655c1.628.72 3.029.716 4.732.655m-4.219-2.62a2.5 2.5 0 0 1 4.264.606l-.026.001c-1.842.063-2.852.097-4.238-.607M8.627 19.499C10.28 17.518 12.76 16 15.76 16a3 3 0 0 1 2.864 2.107l1.275 4.09H21a3 3 0 0 1 0 6h-3.309a3 3 0 0 1-2.849-2.062A14.5 14.5 0 0 0 14 29.618V39a3 3 0 1 1-6 0v-6.988c-.193-.306-.43-.701-.672-1.143a14.5 14.5 0 0 1-.834-1.785C6.266 28.48 6 27.617 6 26.69c0-2.628.988-5.225 2.627-7.191M15.76 18c-2.286 0-4.24 1.153-5.597 2.78C8.812 22.4 8 24.548 8 26.69c0 .57.169 1.169.365 1.688.202.536.466 1.07.719 1.534a20 20 0 0 0 .755 1.265 1 1 0 0 1 .161.544V39a1 1 0 1 0 2 0v-9.435q0-.053.005-.105c.268-2.53 1.153-4.804 2.149-6.582a1 1 0 0 1 1.827.192l.756 2.424a1 1 0 0 0 .954.702H21a1 1 0 1 0 0-2h-1.837a1 1 0 0 1-.955-.702l-1.493-4.792A1 1 0 0 0 15.76 18M37.5 18a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9m0-2a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M34.379 17.77a3 3 0 0 0-3.335-1.71l-.035.008h-.003l-.015.004-.021.005h-.003a4 4 0 0 0-.511.15 7 7 0 0 0-.936.41c-.727.383-1.611 1-2.461 1.977C25.32 20.609 24 23.78 24 28.667c0 .51.099 2.273 1.854 3.137q.256.125.492.196H16v10h2v-7h11v7h2v-8.46a35.822 35.822 0 0 1 2.546 1.947q.118.187.301.494c.276.462.62 1.056.977 1.674l.505.88.003.005.027.046v.002h.001c.5.871.984 1.715 1.182 2.025a3 3 0 0 0 5.059-3.226 84 84 0 0 1-.986-1.697l-.002-.003-.59-1.027c-.358-.62-.723-1.25-1.024-1.754a31 31 0 0 0-.418-.683l-.005-.009c-.084-.132-.274-.432-.485-.675a5 5 0 0 0-.492-.48l-.018-.016a24 24 0 0 0-.53-.446 42 42 0 0 0-1.984-1.53H39a3 3 0 0 0 0-6h-2.239zm-2.85 13.716a1 1 0 0 0-.121-.067 1 1 0 0 0-.111-.081c-.583-.369-1.168-.7-1.684-.916-.801-.336-1.358-.303-1.806-.277l-.04.003a4 4 0 0 1-.397.01 1.55 1.55 0 0 1-.633-.148c-.652-.32-.737-.974-.737-1.343 0-4.519 1.215-7.186 2.567-8.74a6.6 6.6 0 0 1 1.884-1.52 5 5 0 0 1 .793-.334q.182-.003.355-.072a1 1 0 0 1 .956.589l2.648 5.887a1 1 0 0 0 .912.59H39a1 1 0 1 1 0 2h-4.179a1 1 0 0 1-.912-.59l-1.023-2.275a1 1 0 0 0-1.837.031 15.2 15.2 0 0 0-.953 3.403 1 1 0 0 0 .568 1.065c1.188.549 2.547 1.476 3.613 2.267.59.438 1.114.853 1.505 1.174.195.16.36.3.484.408l.01.009c.041.036.097.085.157.14.062.059.113.11.145.147.046.053.101.128.161.214.059.085.11.167.154.234.11.174.243.392.389.637.293.49.65 1.108 1.008 1.727l.59 1.028.002.003.034.06c.463.805.855 1.488.999 1.714a1 1 0 1 1-1.687 1.075 86 86 0 0 1-1.163-1.997v-.001c-.169-.293-.34-.593-.508-.883-.357-.619-.708-1.225-.992-1.7a27 27 0 0 0-.417-.678 1 1 0 0 0-.18-.206l-.016-.015a20 20 0 0 0-.44-.37 38 38 0 0 0-1.428-1.114 31 31 0 0 0-1.556-1.088"
      clipRule="evenodd"
    />
  </svg>
);
export default IconOutpatient;
