import * as React from "react";
const IconSpeechBubble3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.1 29.8h-3.7V8.7c0-2.7-2.2-4.9-4.9-4.9H20.8c-2.7 0-4.9 2.2-4.9 4.9v10.1h-9C4.2 18.8 2 21 2 23.7v15c0 2.7 2.2 4.9 4.9 4.9h2.2v4.6c0 .4.2.7.5.9.1.1.3.1.5.1s.4-.1.6-.2l7.4-5.4h6.4v6.1c0 2.7 2.2 4.9 4.9 4.9h16.4l7.4 5.4c.2.1.4.2.6.2s.3 0 .5-.1c.3-.2.5-.5.5-.9v-4.6H57c2.7 0 4.9-2.2 4.9-4.9v-15c.1-2.7-2.1-4.9-4.8-4.9M17.9 8.7c0-1.6 1.3-2.9 2.9-2.9h27.6c1.6 0 2.9 1.3 2.9 2.9v21.1H39.5v-6.1c0-2.7-2.2-4.9-4.9-4.9H17.9zm-.1 32.9c-.2 0-.4.1-.6.2l-6.1 4.4v-3.6c0-.6-.4-1-1-1H6.9c-1.6 0-2.9-1.3-2.9-2.9v-15c0-1.6 1.3-2.9 2.9-2.9h27.6c1.6 0 2.9 1.3 2.9 2.9v15c0 1.6-1.3 2.9-2.9 2.9zM60 49.7c0 1.6-1.3 2.9-2.9 2.9h-3.2c-.6 0-1 .4-1 1v3.6l-6.1-4.4c-.2-.1-.4-.2-.6-.2H29.5c-1.6 0-2.9-1.3-2.9-2.9v-6.1h8c2.7 0 4.9-2.2 4.9-4.9v-6.9h17.6c1.6 0 2.9 1.3 2.9 2.9z" />
    <path d="M11.7 29.7h-.1c-1.1 0-2 .9-2 2s.9 2 2 2h.1c1.1 0 2-.9 2-2s-.9-2-2-2M21.2 29.7h-.1c-1.1 0-2 .9-2 2s.9 2 2 2h.1c1.1 0 2-.9 2-2s-.9-2-2-2M30.8 29.7h-.1c-1.1 0-2 .9-2 2s.9 2 2 2h.1c1.1 0 2-.9 2-2s-.9-2-2-2M24.8 11.4h-.1c-1.1 0-2 .9-2 2s.9 2 2 2h.1c1.1 0 2-.9 2-2s-.9-2-2-2M36.3 13.4c0-1.1-.9-2-2-2h-.1c-1.1 0-2 .9-2 2s.9 2 2 2h.1c1.1 0 2-.9 2-2M43.7 15.4h.1c1.1 0 2-.9 2-2s-.9-2-2-2h-.1c-1.1 0-2 .9-2 2s.9 2 2 2M43.4 40.2h-.1c-1.1 0-2 .9-2 2s.9 2 2 2h.1c1.1 0 2-.9 2-2s-.9-2-2-2M53 40.2h-.1c-1.1 0-2 .9-2 2s.9 2 2 2h.1c1.1 0 2-.9 2-2s-.9-2-2-2" />
  </svg>
);
export default IconSpeechBubble3;
