import * as React from "react";
const IconFreshJuiceCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.5 33.7c-1.7-.3-3.4.1-4.8 1.2-.9.7-1.9 1-2.9 1.2.1-2.7 3.7-7.2 5.1-8.7.4-.4.3-1-.1-1.4s-1-.3-1.4.1c-.6.6-5.5 6.1-5.6 10.1-1-.2-2-.6-2.9-1.2-1.5-1-3.2-1.4-4.8-1.1-3.6.6-5.9 3.9-5.4 7.5 1.5 10 4.5 13.4 6.2 14.6 1.2.8 2.4 1.1 4 .9 1.1-.2 1.6-.6 1.9-1 .5-.5 1.3-.9 2-.9s1.4.3 2 .9c.3.3.9.9 2 1 .3 0 .7.1 1 .1 1.1 0 2.1-.3 3-1 1.6-1.2 4.6-4.7 6.1-14.6.6-3.7-1.9-7.1-5.4-7.7m3.5 7.2c-1.5 9.5-4.2 12.4-5.3 13.2-.7.5-1.5.7-2.5.5-.5 0-.6-.2-.8-.4-.9-.9-2.2-1.4-3.4-1.4s-2.5.5-3.4 1.4c-.1.1-.3.3-.9.4-1.1.1-1.8 0-2.5-.5-1.1-.8-3.9-3.6-5.3-13.2-.4-2.6 1.2-4.8 3.8-5.3.2 0 .4-.1.7-.1.9 0 1.8.3 2.6.9 1.5 1.1 3.3 1.7 5.1 1.7h.4c1.8-.1 3.3-.7 4.7-1.7.9-.7 2.1-1 3.3-.8 2.1.5 3.8 2.9 3.5 5.3M29.4 7.2h-8.7c-2.1 0-3.9 1.5-4.4 3.5l-1.5 6.6v.4H6.1c-1.1 0-2.2.5-3 1.3S1.9 21 2 22.1L4.6 53c.2 2.1 2 3.7 4.1 3.7h14.2c2.1 0 3.9-1.6 4.1-3.7l2.6-30.8c.1-1.1-.3-2.3-1.1-3.1s-1.9-1.3-3-1.3h-8.7l1.5-6.6c.2-1.1 1.3-1.9 2.4-1.9h8.7c.6 0 1-.4 1-1s-.5-1.1-1-1.1M25 52.8c-.1 1.1-1 1.9-2.1 1.9H8.7c-1.1 0-2-.8-2.1-1.9L4.6 29H27zm2-32.4c.4.4.6 1 .5 1.6l-.4 5H4.4L4 22c0-.6.1-1.1.5-1.6.5-.4 1-.6 1.6-.6h19.4q.9 0 1.5.6" />
  </svg>
);
export default IconFreshJuiceCopy;
