import * as React from "react";
const IconHome10 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.5 22.9 34.8 8c-1.6-.9-3.6-.9-5.2 0L2.5 22.9c-.5.3-.7.9-.4 1.4.2.3.5.5.9.5.2 0 .3 0 .5-.1l7.3-4c-.1.1-.1.3-.1.4v30.3c0 3 2.4 5.4 5.4 5.4h10.8c.6 0 1-.4 1-1V45.3c0-1.8 1.5-3.3 3.3-3.3h1.6c1.8 0 3.3 1.5 3.3 3.3v10.4c0 .6.4 1 1 1h10.8c3 0 5.4-2.4 5.4-5.4V21c0-.2-.1-.3-.2-.5l7.4 4.1c.5.3 1.1.1 1.4-.4.2-.4.1-1-.4-1.3M51.3 51.3c0 1.8-1.5 3.4-3.4 3.4h-9.8v-9.4c0-3-2.4-5.3-5.3-5.3h-1.6c-2.9 0-5.3 2.4-5.3 5.3v9.4h-9.8c-1.8 0-3.4-1.5-3.4-3.4V22h38.5v29.3zM11.9 20 30.6 9.8c1-.6 2.2-.6 3.2 0L52.2 20z" />
  </svg>
);
export default IconHome10;
