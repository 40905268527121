import * as React from "react";
const IconUniform = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 3.5H41.5c-.6 0-1 .5-1 1.1 0 3.7-2.4 7-5.7 8.1-2.8.9-5.6.4-7.9-1.2-2.2-1.6-3.5-4.2-3.5-6.9 0-.3-.1-.5-.3-.7s-.4-.3-.7-.3H6c-2.2 0-4 1.8-4 4v13.8c0 2.2 1.8 4 4 4h4.1v31.2c0 2.2 1.8 4 4 4h35.8c2.2 0 4-1.8 4-4V25.3H58c2.2 0 4-1.8 4-4V7.5c0-2.2-1.8-4-4-4m-8.1 55H33v-8.7c0-.6-.4-1-1-1s-1 .4-1 1v8.7H14.1c-1.1 0-2-.9-2-2v-15h39.8v15c0 1.1-.9 2-2 2M60 21.3c0 1.1-.9 2-2 2h-5.1c-.6 0-1 .4-1 1v15.3H12.1V24.3c0-.6-.4-1-1-1H6c-1.1 0-2-.9-2-2V7.5c0-1.1.9-2 2-2h15.5c.3 3 1.8 5.7 4.2 7.5 2.8 2 6.3 2.6 9.6 1.5 3.9-1.2 6.7-4.9 7.1-9.1H58c1.1 0 2 .9 2 2z" />
    <path d="M33.1 19.8c-.4-.2-.8-.1-1.1.2l-3.6 3.6c-.4.4-.4 1 0 1.4s1 .4 1.4 0l1.9-1.9v8.3c0 .6.4 1 1 1s1-.4 1-1V20.7q0-.6-.6-.9" />
  </svg>
);
export default IconUniform;
