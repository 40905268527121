import * as React from "react";
const IconZipCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.1 10.9h-6.9V8.6C50.2 5 47.3 2 43.6 2H10C6.4 2 3.4 4.9 3.4 8.6v31.8c0 1 .4 2 1.2 2.8l19 17.8c.7.7 1.6 1 2.6 1h17.4c3.6 0 6.6-2.9 6.6-6.6V34.6h6.9c1.5 0 2.6-1.2 2.6-2.6V13.5c.1-1.4-1.1-2.6-2.6-2.6m-50.9 31h16.5c1 0 1.8.8 1.8 1.8v15.4zm42 13.5c0 2.5-2.1 4.6-4.6 4.6H26.5V43.6c0-2.1-1.7-3.8-3.8-3.8H5.4V8.6C5.4 6.1 7.5 4 10 4h33.6c2.5 0 4.6 2.1 4.6 4.6v2.3H17.6c-1.5 0-2.6 1.2-2.6 2.6V32c0 1.5 1.2 2.6 2.6 2.6h30.6zm-9.5-36.6v7.9c0 1.5-1.2 2.6-2.6 2.6s-2.6-1.2-2.6-2.6v-7.9c0-1.5 1.2-2.6 2.6-2.6s2.6 1.2 2.6 2.6m-7.9 7.9c0 1.5-1.2 2.6-2.6 2.6h-4.4c-1 0-1.8-.5-2.3-1.3s-.4-1.9.1-2.7l2.4-3.9h-1c-1.5 0-2.6-1.2-2.6-2.6s1.2-2.6 2.6-2.6h5.7c1 0 1.8.5 2.3 1.3s.4 1.9-.1 2.7l-2.4 3.9c1.2.1 2.3 1.3 2.3 2.6m18.4 0h-2.6c0 1.5-1.2 2.6-2.6 2.6s-2.6-1.2-2.6-2.6v-7.9c0-1.5 1.2-2.6 2.6-2.6h5.3c2.9 0 5.3 2.4 5.3 5.3-.1 2.9-2.5 5.2-5.4 5.2" />
  </svg>
);
export default IconZipCopy;
