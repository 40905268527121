import * as React from "react";
const IconFaq = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m59.1 49.8-6.8-3.2c3.9-4.8 6.1-10.9 6.1-17.2C58.4 14.3 46.1 2 31 2S3.6 14.3 3.6 29.4 15.9 56.8 31 56.8c4.8 0 9.5-1.3 13.7-3.7l3.5 7.6c.4.8 1.1 1.3 2 1.3h.4c1-.2 1.7-1 1.8-2.1l.3-5.4c0-.1.1-.2.2-.2l5.4-.3c1-.1 1.9-.8 2.1-1.8s-.3-2-1.3-2.4m-28.1 5c-14 0-25.4-11.4-25.4-25.4S17 4 31 4s25.4 11.4 25.4 25.4c0 6-2.1 11.8-6 16.3l-6.2-2.9c-.8-.4-1.8-.2-2.5.4s-.8 1.6-.4 2.5l2.6 5.5c-4 2.4-8.4 3.6-12.9 3.6M58.2 52l-5.4.3c-1.1.1-2 1-2.1 2.1l-.3 5.4s0 .2-.2.2-.2-.1-.2-.1l-7-15s-.1-.1 0-.2l.1-.1h.1l15 7s.1.1.1.2c.1.2 0 .2-.1.2" />
    <path d="M31 40.1c-.6 0-1 .4-1 1v.3c0 .6.4 1 1 1s1-.4 1-1v-.3c0-.5-.4-1-1-1M31 16.4c-3 0-5.7 1.9-6.7 4.8-.3.8-.4 1.6-.4 2.4 0 .6.4 1 1 1s1-.4 1-1 .1-1.2.3-1.7c.7-2.1 2.7-3.4 4.9-3.4 2.8 0 5.2 2.3 5.2 5.2s-2.3 5.2-5.2 5.2c-.6 0-1 .4-1 1v6.5c0 .6.4 1 1 1s1-.4 1-1v-5.6c3.5-.5 6.2-3.5 6.2-7.1-.2-4.1-3.4-7.3-7.3-7.3" />
  </svg>
);
export default IconFaq;
