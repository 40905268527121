import * as React from "react";
const IconDirectionCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m58.3 17.8-5-6.4c-.7-.9-1.8-1.5-3-1.5H25.4V3c0-.6-.4-1-1-1s-1 .4-1 1v6.9H8.7c-2.1 0-3.8 1.7-3.8 3.8v12.9c0 2.1 1.7 3.8 3.8 3.8h14.7V61c0 .6.4 1 1 1s1-.4 1-1V30.4h24.8c1.2 0 2.3-.5 3-1.5l5-6.4c1.1-1.4 1.1-3.3.1-4.7m-1.6 3.4-5 6.4c-.3.4-.9.7-1.4.7H8.7c-1 0-1.8-.8-1.8-1.8V13.7c0-1 .8-1.8 1.8-1.8h41.5c.6 0 1.1.3 1.4.7l5 6.4c.6.7.6 1.6.1 2.2" />
  </svg>
);
export default IconDirectionCopy;
