import React from 'react';
import { useNavigation } from '../../context/NavigationContext';
import { BringzzPageContainer, BringzzIcon, BringzzText } from "@bringzz/components";

const DemosPage = () => {
	const { navigate } = useNavigation();

	return (
		<div className='flex flex-col'>
			<button onClick={() => navigate('/demos/ProfilePharmacy2')}>
				ProfilePharmacy2
			</button>
			<button onClick={() => navigate('/demos/ProfilePharmacyViews5')}>
				ProfilePharmacyViews5
			</button>
			<button onClick={() => navigate('/demos/MatchingProcessChangeShopper2')}>
				MatchingProcessChangeShopper2
			</button>
			<button onClick={() => navigate('/demos/AccountPharmacyChat5')}>
				AccountPharmacyChat5
			</button>
			<button onClick={() => navigate('/demos/MapZipcodesDemo')}>
				MapAllZipcodesDemo
			</button>
			<button onClick={() => navigate('/demos/OrderRequest6')}>
				OrderRequest6
			</button>
			<button onClick={() => navigate('/demos/OrderRequest2')}>
				OrderRequest2
			</button>
			<button onClick={() => navigate('/demos/AccountConsumerChat9')}>
				AccountConsumerChat9
			</button>
			<button onClick={() => navigate('/demos/MatchingProcessShopper3')}>
				MatchingProcessShopper3
			</button>
			<button onClick={() => navigate('/demos/Article')}>ArticleExample</button>
			<button onClick={() => navigate('/demos/PharmacyPostProduct1')}>
				PharmacyPostProduct1
			</button>
			<button onClick={() => navigate('/demos/PharmacyPostArticle3')}>
				PharmacyPostArticle3
			</button>
			<button onClick={() => navigate('/demos/DeliveryHandoverShipping9')}>
				DeliveryHandoverShipping9
			</button>
			<button
				onClick={() => navigate('/demos/DeliveryHandoverViewPrescription3')}
			>
				DeliveryHandoverViewPrescription3
			</button>
			<button onClick={() => navigate('/demos/AccountPharmacyPost10')}>AccountPharmacyPost10</button>
			<button onClick={() => navigate('/demos/AccountPharmacyOrder1')}>
				AccountPharmacyOrder1
			</button>
		</div>
	);
};

export default DemosPage;
