import * as React from "react";
const IconBadge2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m56.5 38.1-4.1-14.3c-1.4-5-.4-10.3 2.9-14.3.3-.4.3-1-.1-1.3l-5.8-5.8c-.4-.4-.9-.4-1.3-.1-2.2 1.7-4.9 2.6-7.7 2.6-2.9 0-5.5-.9-7.8-2.6-.4-.3-.9-.3-1.2 0-2.2 1.7-4.9 2.6-7.8 2.6-2.8 0-5.5-.9-7.7-2.6-.4-.3-.9-.3-1.3.1L8.8 8.2c-.3.3-.4.9-.1 1.3 3.2 4 4.3 9.4 2.9 14.3L7.5 38.1c-1.4 4.7-.4 9.9 2.6 13.8s7.7 6.3 12.6 6.3c2.2 0 4.3.6 6.1 1.8l2.7 1.8c.2.1.4.2.6.2s.4-.1.6-.2l2.7-1.8c1.8-1.2 3.9-1.8 6.1-1.8 4.9 0 9.6-2.3 12.6-6.3 2.8-3.9 3.7-9.1 2.4-13.8m-4.2 12.6c-2.6 3.4-6.7 5.5-11 5.5a13 13 0 0 0-7.2 2.2L32 59.8l-2.1-1.4a13 13 0 0 0-7.2-2.2c-4.3 0-8.4-2-11-5.5-2.6-3.4-3.4-7.9-2.2-12.1l4.1-14.3c1.5-5.3.5-11-2.7-15.4l4.6-4.6c2.4 1.6 5.2 2.5 8.2 2.5s5.9-.9 8.4-2.6c2.5 1.7 5.4 2.6 8.4 2.6s5.8-.8 8.2-2.5L53.2 9C50 13.4 49 19.1 50.5 24.4l4.1 14.3c1.1 4.1.3 8.6-2.3 12" />
    <path d="m42 28.7-6.3-.9-2.8-5.8c-.3-.7-1.5-.7-1.8 0l-2.8 5.7-6.3 1c-.4.1-.7.3-.8.7s0 .8.3 1l4.6 4.4-1.1 6.3c-.1.4.1.8.4 1s.7.3 1.1.1l5.6-3 5.6 3c.1.1.3.1.5.1s.4-.1.6-.2c.3-.2.5-.6.4-1L38 34.8l4.6-4.4c.3-.3.4-.7.3-1-.2-.4-.5-.7-.9-.7m-5.8 5.1c-.2.2-.3.5-.2.8l.8 4.8-4.3-2.3c-.1-.1-.3-.1-.5-.1s-.3 0-.5.1l-4.3 2.3.8-4.8c.1-.3-.1-.7-.3-.9l-3.5-3.4 4.8-.7c.3 0 .6-.3.8-.5l2.2-4.4 2.2 4.4c.1.3.4.5.8.5l4.8.7z" />
  </svg>
);
export default IconBadge2;
