import * as React from "react";
const IconArrowsExchangeAltV = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M43.1 45.3c-.6 0-1-.4-1-1V4q0-.6.6-.9c.4-.2.8-.1 1.1.1l9.1 7.7c.4.4.5 1 .1 1.4s-1 .5-1.4.1l-7.5-6.3v38.1c0 .6-.4 1.1-1 1.1M20.9 61c-.2 0-.5-.1-.6-.2l-9.1-7.7c-.4-.4-.5-1-.1-1.4s1-.5 1.4-.1l7.5 6.3V19.7c0-.6.4-1 1-1s1 .4 1 1V60q0 .6-.6.9c-.3.1-.4.1-.5.1" />
  </svg>
);
export default IconArrowsExchangeAltV;
