import * as React from "react";
const IconPlugCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M45.8 12.5h-4.9V3c0-.6-.4-1-1-1s-1 .4-1 1v9.5H25.1V3c0-.6-.4-1-1-1s-1 .4-1 1v9.5h-4.9c-1.7 0-3 1.3-3 3v13.3c0 6.7 5.4 12.1 12.1 12.1H31V61c0 .6.4 1 1 1s1-.4 1-1V40.9h3.8c6.7 0 12.1-5.4 12.1-12.1V15.5c-.1-1.6-1.4-3-3.1-3m1 16.3c0 5.6-4.5 10.1-10.1 10.1h-9.5c-5.6 0-10.1-4.5-10.1-10.1V15.5c0-.6.4-1 1-1h27.6c.6 0 1 .4 1 1v13.3z" />
  </svg>
);
export default IconPlugCopy;
