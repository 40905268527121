import * as React from "react";
const IconTimerCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M33 12.6V4h6.9c.6 0 1-.4 1-1s-.4-1-1-1H24.1c-.6 0-1 .4-1 1s.4 1 1 1H31v8.6C17.8 13.1 7.3 24 7.3 37.3 7.3 50.9 18.4 62 32 62s24.7-11.1 24.7-24.7C56.7 24 46.2 13.1 33 12.6M32 60C19.5 60 9.3 49.8 9.3 37.3S19.5 14.6 32 14.6s22.7 10.2 22.7 22.7S44.5 60 32 60" />
    <path d="M45.2 36.3H33V24.1c0-.6-.4-1-1-1s-1 .4-1 1v12.2h-4.3c-.6 0-1 .4-1 1s.4 1 1 1H31v4.3c0 .6.4 1 1 1s1-.4 1-1v-4.3h12.2c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconTimerCopy;
