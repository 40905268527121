import * as React from "react";
const IconReloadMedical = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.5 26.9c-.1-.5-.6-.9-1.2-.8-.5.1-.9.6-.8 1.2.3 1.6.4 3.2.4 4.8 0 15.4-12.6 28-28 28S4 47.4 4 32 16.6 4 32 4c8.8 0 17.1 4.2 22.4 11.2l-5.5-1.7c-.5-.2-1.1.1-1.3.7-.2.5.1 1.1.7 1.3l8.5 2.6h.3c.2 0 .4-.1.6-.2.3-.2.4-.5.4-.8V8.8c0-.6-.4-1-1-1s-1 .4-1 1V14C50.3 6.5 41.4 2 32 2 15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30c0-1.7-.2-3.4-.5-5.1" />
    <path d="M32 43.8c.6 0 1-.4 1-1v-8.5h8.5c.6 0 1-.4 1-1s-.4-1-1-1H33v-8.5c0-.6-.4-1-1-1s-1 .4-1 1v8.5h-8.5c-.6 0-1 .4-1 1s.4 1 1 1H31v8.5c0 .6.4 1 1 1" />
  </svg>
);
export default IconReloadMedical;
