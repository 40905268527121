import * as React from "react";
const IconHospitalBed = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 26.3H4V10c0-.6-.4-1-1-1s-1 .4-1 1v44c0 .6.4 1 1 1s1-.4 1-1V38.8h56V54c0 .6.4 1 1 1s1-.4 1-1V30.3c0-2.2-1.8-4-4-4m-54 2h54c1.1 0 2 .9 2 2v6.5H4z" />
  </svg>
);
export default IconHospitalBed;
