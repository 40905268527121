import * as React from "react";
const IconSecurityWorker = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path  d="M24 13a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
    <path
      
      fillRule="evenodd"
      d="m23.816 6.017-.008.002-.023.004-.083.016-.31.062c-.266.054-.645.134-1.098.237-.905.206-2.12.507-3.34.884-1.208.373-2.473.837-3.454 1.38-.488.27-.956.587-1.313.96-.355.369-.687.886-.687 1.535 0 1.745.49 3.023 1.008 3.88.255.422.514.737.718.953q.013.173.053.384c.101.526.354 1.196.934 1.847a8 8 0 1 0 15.575 0c.579-.651.832-1.32.933-1.847q.04-.21.053-.384a5.6 5.6 0 0 0 .718-.953c.519-.857 1.008-2.135 1.008-3.88 0-.649-.332-1.166-.687-1.535-.357-.373-.825-.69-1.313-.96-.981-.543-2.246-1.007-3.455-1.38a47 47 0 0 0-3.339-.884 51 51 0 0 0-1.408-.3l-.083-.015-.023-.004-.008-.002L24 5.983zM24 7l.184-.983zm-.184-.983L24 7zM24 18.5c2.207 0 3.689-.286 4.68-.658.839-.315 1.328-.691 1.61-1.008q.153-.173.248-.334H17.462q.095.162.247.334c.284.317.772.693 1.61 1.008.992.372 2.474.658 4.681.658m7.372-4H16.628a3.7 3.7 0 0 1-.409-.558c-.343-.567-.715-1.484-.719-2.824a.7.7 0 0 1 .13-.172c.162-.169.436-.372.839-.594.8-.443 1.91-.859 3.076-1.22a45 45 0 0 1 3.192-.843A49 49 0 0 1 24 8.019q.086.016.208.041c.254.052.618.13 1.055.229.876.199 2.037.487 3.192.844 1.166.36 2.276.776 3.076 1.219.403.222.677.425.84.594.09.095.12.151.129.172-.004 1.34-.376 2.257-.72 2.824-.148.247-.295.431-.408.558M18 20q0-.27.023-.534c1.338.627 3.254 1.034 5.977 1.034 2.345 0 4.089-.3 5.382-.785q.315-.117.595-.25Q30 19.73 30 20a6 6 0 0 1-12 0m-2.503-8.873v-.003z"
      clipRule="evenodd"
    />
    <path
      
      d="M33 34c.667.667 2 1.333 2 1.333S34.302 38 33 38s-2-2.667-2-2.667 1.333-.666 2-1.333"
    />
    <path
      
      fillRule="evenodd"
      d="M20.5 31a2 2 0 0 1 2-2h3a2 2 0 0 1 2 2v1.382a2 2 0 0 1-.873 1.652l.113.566 3.432-4.167a1 1 0 0 1 .974-.344c2.564.532 5.187 1.338 7.195 2.444C40.283 33.603 42 35.156 42 37.298V41a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-3.702c0-2.13 1.75-3.673 3.692-4.734 2.016-1.103 4.64-1.915 7.173-2.454a1 1 0 0 1 .98.343l3.43 4.166.108-.578a2 2 0 0 1-.883-1.66zm4.02 2.696.579 2.897L24 37.927l-1.064-1.292.547-2.953a1 1 0 0 0-.536-1.077l-.447-.223V31h3v1.382l-.447.224a1 1 0 0 0-.534 1.09M24.883 40l6.444-7.825c2.26.506 4.427 1.217 6.048 2.11C39.204 35.292 40 36.333 40 37.298V40zm-8.194-7.803L23.116 40H8v-2.702c0-.934.8-1.967 2.651-2.98 1.633-.893 3.804-1.608 6.04-2.121"
      clipRule="evenodd"
    />
  </svg>
);
export default IconSecurityWorker;
