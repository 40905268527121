import * as React from "react";
const IconDog = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 65 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55 61h-7.4c-.8 0-1.5-.3-2-.8s-.8-1.2-.8-2v-8.8c0-1.7-1.3-3-3-3H31c-1.7 0-3 1.3-3 3v8.8c0 .8-.3 1.5-.8 2s-1.3.8-2 .8h-7.4c-.4 0-.7-.1-1.1-.2q-.6-.15-.9-.6-.45-.45-.6-.9c-.1-.3-.2-.7-.2-1.1V33.3l-.1-6.6c0-.9-.8-1.7-1.7-1.7H12c-1.1 0-2.2-.2-3.2-.6s-2-1-2.8-1.8-1.4-1.7-1.9-2.7c-.4-1-.7-2.1-.7-3.2v-3.6c0-.4.1-.8.2-1.1.2-.4.4-.7.7-.9.3-.3.6-.5 1-.6s.7-.2 1.1-.2h6.8l.5-1.1c.4-.8 1-1.4 1.7-1.9s1.6-.7 2.5-.7h5.8l2.9-2.8c.4-.4.9-.7 1.5-.8s1.1-.1 1.6.2c.5.2 1 .6 1.3 1 .3.5.5 1 .5 1.6v20.9l1.5-.5h20c.7 0 1.4-.3 1.9-.8s.8-1.1.8-1.8c0-1.2.5-2.4 1.4-3.3s2.1-1.4 3.4-1.4c.6 0 1 .4 1 1s-.4 1-1 1c-.7 0-1.5.3-2 .8s-.8 1.2-.8 1.9l.1 34.5c0 .8-.3 1.5-.8 2-.6.6-1.3.9-2 .9M31 44.4h10.6c2.8 0 5 2.2 5 5v8.8c0 .2.1.4.2.6.2.2.4.2.6.2H55c.2 0 .5-.1.6-.2.2-.2.2-.4.2-.6l-.1-30.8q-1.2.9-2.7.9H33.5l-2.5.8L16.9 34v24.2c0 .1 0 .2.1.3 0 .1.1.2.2.3s.2.1.3.2c.1 0 .2.1.3.1h7.4c.2 0 .5-.1.6-.2.2-.2.2-.4.2-.6v-8.8c0-2.8 2.3-5.1 5-5.1M13.1 23c2 0 3.6 1.6 3.7 3.6l.1 5.2 12.8-4.5V5.8c0-.2-.1-.3-.1-.4-.1-.1-.2-.2-.4-.3s-.4-.1-.6-.1-.3.1-.4.2l-3.5 3.4H18c-.5 0-1 .1-1.4.4s-.8.6-1 1.1l-1.1 2.2h-8c-.2 0-.3 0-.4.1-.1 0-.2.1-.3.2s-.1.2-.2.3c0 .1-.1.2-.1.3v3.6c0 .8.2 1.6.5 2.4s.8 1.5 1.4 2c.6.6 1.3 1 2.1 1.4.8.3 1.6.5 2.5.5h1.1z" />
  </svg>
);
export default IconDog;
