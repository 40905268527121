import * as React from "react";
const IconDove = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.1 13.4c-.6-.7-1.5-1-2.3-.7L42 17.9c-1.7-2-5.4-4.7-8.9-5.9-3.3-1.2-5.1-3-5.1-3l-.1-.1-7.2-6c-.5-.4-1.6-1-2.9-.4s-1.6 1.8-1.5 2.4v5.5L6.7 2.6c-.5-.4-1.6-.9-2.8-.4-1.2.6-1.5 1.8-1.5 2.5v12.2c0 3.2.2 6 2.1 11.3 2.1 5.8 6.3 9.5 9.3 12.2 2.5 2.2 6.4 3.7 8.4 4.4-.9 1-2.5 2.7-4.2 4.4-1.2 1.3-4.8 4.3-4.8 4.3-1.2.9-4.4 3.4-4.7 3.6-.9.7-1.3 1.7-1 2.9.3 1.4 1.7 1.9 2.5 1.9.1 0 2.8.1 6.4.1 3.1 0 6.9-.1 10-.2 7.2-.5 13.4-4.5 16.8-6.7 4.5-2.9 9.4-9.9 11.3-15.9 1.7-5.4 2.4-13.6 2.5-15.1l4.4-8.2c.4-.8.3-1.8-.3-2.5M18.2 4.9s0-.4.4-.6c.3-.2.6 0 .7.1l7.3 6c.3.3 2.3 2.2 5.9 3.4 2.9 1 6.1 3.2 7.7 4.9-1.6 1.3-3.2 3.4-4.1 4.7-1.6-1.8-4-4-6.3-5.5-.5-.3-.9-.6-1.2-.8-1.8-1.2-3.1-2-5.7-2.6-2.2-.5-4.2-2.1-4.8-2.6.1-.2.2-.4.2-.6zm41.4 10-4.5 8.4c-.1.1-.1.3-.1.4s-.7 9.2-2.4 14.8c-1.8 5.5-6.5 12.2-10.5 14.8-4.4 2.9-9.6 5.9-15.8 6.3-6.4.4-16.1.2-16.2.2 0 0-.6-.1-.7-.4-.1-.6.1-.7.2-.8.4-.3 4.4-3.3 4.8-3.7.1-.1 3.6-3.1 5-4.5 5.1-5.2 5.3-5.8 5.4-6.1s.1-.6-.1-.8-.4-.4-.6-.5c-.1 0-5.9-1.6-9-4.4-2.9-2.6-6.8-6.1-8.8-11.4-1.8-5-2-7.7-2-10.7V4.6s0-.4.4-.6H5c.2 0 .4.1.5.1l11.1 9.1c.1.1 2.7 2.5 5.9 3.2 2.3.5 3.3 1.2 5.1 2.4.4.3.8.5 1.3.8 2.6 1.7 5.3 4.4 6.7 6.1.2.3.5.4.9.4.3 0 .6-.2.8-.5.8-1.3 3.1-4.7 5-5.8l17.3-5.3s.1 0 .2.1c-.1.2-.2.3-.2.3" />
    <path d="M47.4 21.6c-1.5 0-2.7 1.2-2.7 2.7s1.2 2.7 2.7 2.7 2.7-1.2 2.7-2.7-1.2-2.7-2.7-2.7" />
  </svg>
);
export default IconDove;
