import * as React from "react";
const IconSpeechBubble13 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.9 7.8H7.1C4.3 7.8 2 10.1 2 12.9v29C2 44.7 4.3 47 7.1 47h6.5v8.1c0 .4.2.7.5.9.1.1.3.1.5.1s.4-.1.6-.2l12.3-9h29.3c2.8 0 5.1-2.3 5.1-5.1v-29c.1-2.7-2.2-5-5-5M60 41.9c0 1.7-1.4 3.1-3.1 3.1H27.2c-.2 0-.4.1-.6.2l-11 8V46c0-.6-.4-1-1-1H7.1C5.4 45 4 43.6 4 41.9v-29c0-1.7 1.4-3.1 3.1-3.1h49.7c1.7 0 3.1 1.4 3.1 3.1v29z" />
    <path d="M42.9 16.7H21.1c-.6 0-1 .4-1 1s.4 1 1 1h21.8c.6 0 1-.4 1-1s-.5-1-1-1M42.9 25.5H21.1c-.6 0-1 .4-1 1s.4 1 1 1h21.8c.6 0 1-.4 1-1s-.5-1-1-1M42.9 34.3H21.1c-.6 0-1 .4-1 1s.4 1 1 1h21.8c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconSpeechBubble13;
