import * as React from "react";
const IconRotate = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 31H32.3V8.6C32.3 5 29.4 2 25.7 2H8.6C4.9 2 2 4.9 2 8.6v27.7c0 3.6 2.9 6.6 6.6 6.6h11.7V57c0 2.8 2.2 5 5 5H57c2.8 0 5-2.2 5-5V36c0-2.8-2.2-5-5-5M8.6 40.8c-2.5 0-4.6-2.1-4.6-4.6V8.6C4 6.1 6.1 4 8.6 4h17.2c2.5 0 4.6 2.1 4.6 4.6V31h-5.1c-2.8 0-5 2.2-5 5v4.8zM60 57c0 1.7-1.3 3-3 3H25.3c-1.7 0-3-1.3-3-3V36c0-1.7 1.3-3 3-3H57c1.7 0 3 1.3 3 3zM39.8 7.3c8.6 0 15.7 7 15.7 15.7v.1l-3.8-3.5c-.4-.4-1-.4-1.4.1-.4.4-.4 1 .1 1.4l5.1 4.7c.2.2.4.3.7.3s.5-.1.7-.3l5-4.6c.4-.4.4-1 .1-1.4-.4-.4-1-.4-1.4-.1l-2.9 2.7c-.3-9.4-8.1-17-17.6-17-.6 0-1 .4-1 1s.1.9.7.9" />
  </svg>
);
export default IconRotate;
