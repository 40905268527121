import * as React from "react";
const IconCottageAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.4 28.2 35.6 3.6c-1-1-2.2-1.6-3.6-1.6s-2.6.6-3.6 1.6L5.6 28.2c-.3.3-.3.7-.2 1.1q.3.6.9.6h4.1v23.9c0 4.5 3.7 8.2 8.2 8.2h26.5c4.5 0 8.2-3.7 8.2-8.2V29.9h4.1c.4 0 .8-.2.9-.6.4-.3.3-.8.1-1.1M38.2 60H25.8v-7.1c0-3.4 2.8-6.2 6.2-6.2s6.2 2.8 6.2 6.2zm13.3-6.2c0 3.4-2.8 6.2-6.2 6.2h-5v-7.1c0-4.5-3.7-8.2-8.2-8.2s-8.2 3.7-8.2 8.2V60h-5c-3.4 0-6.2-2.8-6.2-6.2V29.9h39v23.9zM8.7 27.9l21.2-23Q30.8 4 32 4t2.1.9l21.2 23z" />
  </svg>
);
export default IconCottageAlt1;
