import * as React from "react";
const IconSpeechBubble22 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.3 2H9.7C6.8 2 4.5 4.3 4.5 7.1v13.5c0 2.8 2.3 5.1 5.1 5.1h2.7v3.7c0 1 .6 1.9 1.5 2.3.3.2.7.2 1 .2.6 0 1.2-.2 1.7-.7l6.2-5.6h31.5c2.8 0 5.1-2.3 5.1-5.1V7.1c.2-2.8-2.1-5.1-5-5.1m3.2 18.7c0 1.7-1.4 3.1-3.1 3.1h-32c-.2 0-.5.1-.7.3l-6.5 5.8c-.2.2-.5.1-.6.1s-.3-.2-.3-.5v-4.7c0-.6-.4-1-1-1H9.7c-1.7 0-3.1-1.4-3.1-3.1V7.1C6.5 5.4 7.9 4 9.7 4h44.7c1.7 0 3.1 1.4 3.1 3.1zM54.3 38.2h-2.7v-3.7c0-1-.6-1.9-1.5-2.3s-2-.3-2.7.4l-6.3 5.6H9.7c-2.8 0-5.1 2.3-5.1 5.1v13.5c0 2.8 2.3 5.1 5.1 5.1h44.7c2.8 0 5.1-2.3 5.1-5.1V43.3c0-2.8-2.3-5.1-5.2-5.1m3.2 18.7c0 1.7-1.4 3.1-3.1 3.1H9.7c-1.7 0-3.1-1.4-3.1-3.1V43.3c0-1.7 1.4-3.1 3.1-3.1h31.8c.2 0 .5-.1.7-.3l6.6-5.8c.2-.2.5-.1.6-.1s.3.2.3.5v4.7c0 .6.4 1 1 1h3.7c1.7 0 3.1 1.4 3.1 3.1z" />
  </svg>
);
export default IconSpeechBubble22;
