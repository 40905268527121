import * as React from "react";
const IconManager = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.9 28.4c0-3.4-2.8-6.2-6.2-6.2h-17c-.7 0-1.4.3-1.9.8l-1.5 1.5-1.6-1.5c-.5-.5-1.2-.8-1.9-.8h-1.9c-2.3 0-4.2 1.7-4.5 4L12.8 42c-.1.8.2 1.5.7 2.1s1.3.9 2 .9h1l1 12.4c.2 2.5 2.4 4.5 4.9 4.5h3.7c2.5 0 4.6-1.9 4.9-4.4l2.2-22.9h11.6c3.3 0 6.1-2.7 6.1-6.2m-18.7 4.2c-.5 0-.9.4-1 .9L29 57.4c-.1 1.5-1.4 2.6-2.9 2.6h-3.7c-1.5 0-2.8-1.2-2.9-2.6L18.4 44c0-.5-.5-.9-1-.9h-1.9c-.2 0-.4-.1-.5-.2-.1-.2-.2-.4-.2-.6l1.6-15.8c.1-1.3 1.2-2.3 2.5-2.3h1.9c.2 0 .4.1.5.2l2.2 2.2c.4.4 1 .4 1.4 0l2.3-2.3c.1-.1.3-.2.5-.2h17c2.3 0 4.2 1.9 4.2 4.3 0 2.3-1.9 4.2-4.2 4.2z" />
    <path d="M46.9 50.8h-2.8c-1.2 0-2.3-1-2.3-2.2s1-2.2 2.3-2.2h6c.6 0 1-.4 1-1s-.4-1-1-1h-2.7v-1.8c0-.6-.4-1-1-1s-1 .4-1 1v1.8h-1.3c-2.3 0-4.3 1.9-4.3 4.2s1.9 4.2 4.3 4.2h2.8c1.2 0 2.3 1 2.3 2.2s-1 2.2-2.3 2.2h-6c-.6 0-1 .4-1 1s.4 1 1 1h4.6V61c0 .6.4 1 1 1s1-.4 1-1v-1.8c2.1-.3 3.7-2 3.7-4.2 0-2.3-1.9-4.2-4.3-4.2M24.8 17.9c4.4 0 8-3.6 8-8s-3.6-8-8-8-8 3.6-8 8 3.6 8 8 8m0-13.9c3.3 0 6 2.7 6 6s-2.7 6-6 6-6-2.7-6-6 2.8-6 6-6" />
  </svg>
);
export default IconManager;
