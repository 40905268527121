import * as React from "react";
const IconPn = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 85.333 512 341.333"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 85.333h512V426.67H0z" />
    <path
      
      d="M400.696 219.822 384 225.387l-16.696-5.565-5.565-13.913 5.565-13.913h33.392l-5.566 13.913z"
    />
    <path
      
      d="M345.043 219.822v61.217c0 29.821 38.957 38.957 38.957 38.957s38.957-9.137 38.957-38.957v-61.217z"
    />
    <path
      
      d="M348.555 295.541C358.131 313.927 384 319.996 384 319.996s25.869-6.069 35.445-24.455L384 236.518z"
    />
    <path
      
      d="M422.957 280.421 384 219.822l-38.957 60.599v.618c0 5.518 1.337 10.328 3.512 14.503L384 240.405l35.445 55.137c2.175-4.175 3.512-8.983 3.512-14.503z"
    />
    <path
      
      d="M256 85.333v30.553l-45.167 25.099H256v59.359h-59.103L256 233.179v22.817h-26.68l-73.494-40.826v40.826h-55.652v-48.573l-87.43 48.573H0v-30.554l45.167-25.098H0v-59.359h59.103L0 108.139V85.333h26.68l73.494 40.825V85.333h55.652v48.572l87.43-48.572z"
    />
    <path
      
      d="M144 85.33h-32v69.332H0v32h112v69.334h32v-69.334h112v-32H144z"
    />
    <path
      
      d="M155.826 200.344 256 255.996v-15.737l-71.847-39.915z"
    />
    <path
      
      d="M155.826 200.344 256 255.996v-15.737l-71.847-39.915z"
    />
    <path
      
      d="M155.826 200.344 256 255.996v-15.737l-71.847-39.915zm-83.98 0L0 240.259v15.737l100.174-55.652z"
    />
    <path  d="M100.174 140.982 0 85.33v15.737l71.847 39.915z" />
    <path  d="M100.174 140.982 0 85.33v15.737l71.847 39.915z" />
    <path
      
      d="M100.174 140.982 0 85.33v15.737l71.847 39.915zm83.98 0L256 101.067V85.33l-100.174 55.652z"
    />
  </svg>
);
export default IconPn;
