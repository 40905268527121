import * as React from "react";
const IconVrBug = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M24.8 33.4c-.4-.4-1-.4-1.4 0l-5.7 5.7h-9C6.1 39.1 4 37 4 34.3v-6.6h6.2c.6 0 1-.4 1-1s-.4-1-1-1H4V22c0-2.6 2.1-4.8 4.8-4.8h30.6c2.6 0 4.8 2.1 4.8 4.8 0 .6.4 1 1 1s1-.4 1-1c0-3.7-3-6.8-6.8-6.8h-3v-2.7c0-3.7-3-6.8-6.8-6.8h-11c-3.7 0-6.8 3-6.8 6.8v2.7h-3c-3.7 0-6.8 3-6.8 6.8v12.1c0 3.7 3 6.8 6.8 6.8h9.4c.3 0 .5-.1.7-.3l5.3-5.3 5.3 5.3c.4.4 1 .4 1.4 0s.4-1 0-1.4zM18.6 7.9h11.1c2.6 0 4.8 2.1 4.8 4.8v2.7H13.8v-2.7c0-2.7 2.1-4.8 4.8-4.8M61 46.3h-4.7c0-1.3-.2-2.5-.6-3.7l3.7-3.3c.4-.4.5-1 .1-1.4s-1-.5-1.4-.1l-3.2 2.8c-.7-1.5-1.8-2.8-3-3.7.5-.2 1-.5 1.4-.9.6-.7.9-1.6.8-2.5-.3-3.4-3-5.9-6.3-5.9s-6 2.5-6.4 5.9c-.1.9.2 1.9.8 2.5.4.4.9.7 1.4.9-1.2.9-2.2 2.1-2.9 3.6l-3.1-2.7c-.4-.4-1-.3-1.4.1s-.3 1 .1 1.4l3.6 3.2c-.4 1.2-.6 2.5-.6 3.8h-4.6c-.6 0-1 .4-1 1s.4 1 1 1h4.6c.1 1.1.3 2.2.7 3.2l-3.7 3.3c-.4.4-.5 1-.1 1.4.2.2.5.3.8.3.2 0 .5-.1.7-.3l3.3-2.9c1.5 2.8 4 4.7 6.8 4.7s5.4-1.9 6.9-4.8l3.3 3c.2.2.4.3.7.3s.6-.1.7-.3c.4-.4.3-1-.1-1.4l-3.8-3.4c.3-1 .5-2 .7-3.1H61c.6 0 1-.4 1-1s-.4-1-1-1M43.5 33.8c.2-2.4 2.1-4.1 4.4-4.1s4.1 1.8 4.3 4.1c0 .4-.1.7-.3 1-.3.3-.6.4-.9.4h-6.3c-.3 0-.7-.1-.9-.4s-.4-.7-.3-1m4.4 22.3c-3.6 0-6.5-4.2-6.5-9.3s2.9-9.3 6.5-9.3c2.5 0 4.7 2.1 5.7 5 .4 1.3.7 2.7.7 4.2v.6c-.2 5-3 8.8-6.4 8.8" />
  </svg>
);
export default IconVrBug;
