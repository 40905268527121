import * as React from "react";
const IconHouseAlt4 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 54.9h-4v-26c0-2.2-1.8-4-4-4h-.7v-9.5l3.2 1.8c.5.3 1.1.1 1.4-.4s.1-1.1-.4-1.4L42.9 7.8a5.1 5.1 0 0 0-4.8 0l-13.8 7.7c-.5.3-.7.9-.4 1.4.2.3.5.5.9.5.2 0 .3 0 .5-.1l3.2-1.8V25H11.2c-2.2 0-4 1.8-4 4v26H3c-.6 0-1 .4-1 1s.4 1 1 1h58c.6 0 1-.4 1-1s-.4-1.1-1-1.1M30.5 14.3l8.6-4.8c.9-.5 2-.5 2.8 0l8.4 4.7v10.6H30.5V14.3m-2.6 40.6V40c0-1.1.9-2 2-2H35c1.1 0 2 .9 2 2v14.9zm11.1 0V40c0-2.2-1.8-4-4-4h-5.1c-2.2 0-4 1.8-4 4v14.9H9.2v-26c0-1.1.9-2 2-2H53c1.1 0 2 .9 2 2v26z" />
  </svg>
);
export default IconHouseAlt4;
