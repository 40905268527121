import * as React from "react";
const IconLayoutAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 2H7C4.2 2 2 4.2 2 7v19.5c0 2.8 2.2 5 5 5h50c2.8 0 5-2.2 5-5V7c0-2.8-2.2-5-5-5m3 24.5c0 1.7-1.3 3-3 3H7c-1.7 0-3-1.3-3-3V7c0-1.7 1.3-3 3-3h50c1.7 0 3 1.3 3 3zM21.6 40.3H7c-2.8 0-5 2.2-5 5V57c0 2.8 2.2 5 5 5h14.6c2.8 0 5-2.2 5-5V45.3c0-2.7-2.2-5-5-5m3 16.7c0 1.7-1.3 3-3 3H7c-1.7 0-3-1.3-3-3V45.3c0-1.7 1.3-3 3-3h14.6c1.7 0 3 1.3 3 3zM57 40.3H42.4c-2.8 0-5 2.2-5 5V57c0 2.8 2.2 5 5 5H57c2.8 0 5-2.2 5-5V45.3c0-2.7-2.2-5-5-5M60 57c0 1.7-1.3 3-3 3H42.4c-1.7 0-3-1.3-3-3V45.3c0-1.7 1.3-3 3-3H57c1.7 0 3 1.3 3 3z" />
  </svg>
);
export default IconLayoutAlt1;
