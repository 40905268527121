import * as React from "react";
const IconBrushAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.4 5.8c-1.3-1.3-2.9-2-4.7-2-1.7 0-3.3.7-4.5 1.8L15.5 38.7c-1.2 0-2.5.2-3.9.8-2.3 1-3.3 2.8-4.3 4.5-.9 1.6-1.9 3.3-4.2 5-1 .8-1.5 2-1.4 3.2s.8 2.3 1.9 2.9C8.2 57.6 13.9 60 18.7 60c2.1 0 4-.5 5.6-1.7 2.3-1.8 3.5-4.1 3.5-6.8V51l32.7-36.1c2.4-2.4 2.3-6.6-.1-9.1M21.6 54.9c-2.2 1.6-7.9.4-14.7-3.1 2.3-2 3.4-4 4.3-5.5s1.3-2.2 2.2-2.6c2.3-1 4.2-.1 4.4.1 2.7 1.9 5.4 5.2 5.4 8 .1 1.2-.4 2.2-1.6 3.1M57.2 12l-31 34.2c-1.4-2.5-3.6-4.7-5.6-6.1L54.3 8.9c.8-.8 2.1-.8 2.9 0 .7.9.8 2.3 0 3.1" />
  </svg>
);
export default IconBrushAlt;
