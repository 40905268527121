import * as React from "react";
const IconShirt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.7 17.1 50 5.4l-1.1-1.1c-.2-.2-.5-.3-.7-.3H15.8c-.3 0-.5.1-.7.3L14 5.4 2.3 17.1c-.3.3-.4.7-.2 1l4.5 13.1q.15.45.6.6c.2.2.6.2.8 0l6.2-4.4V59c0 .3.2.6.4.8.2.1.4.2.6.2.1 0 .2 0 .4-.1 9.6-3.8 16.1-14.8 16.1-27.3C31.7 20.8 25.8 10.2 17 6h29.8c-8.7 4.3-14.6 14.9-14.6 26.6 0 12.5 6.5 23.4 16.1 27.3.3.1.7.1.9-.1.3-.2.4-.5.4-.8V27.3l6.2 4.4c.3.2.6.2.9.1q.45-.15.6-.6l4.5-13.1c.3-.3.2-.7-.1-1M29.8 32.6c0 11.1-5.4 20.8-13.5 24.8v-32c0-.4-.2-.7-.5-.9-.1-.1-.3-.1-.5-.1s-.4.1-.6.2L8 29.4 4.1 18.1 15 7.3c8.7 3.4 14.8 13.8 14.8 25.3M56 29.4l-6.7-4.8c-.3-.2-.7-.2-1-.1-.3.2-.5.5-.5.9v32c-8.1-4-13.5-13.7-13.5-24.8 0-11.5 6-21.9 14.7-25.4L59.8 18z" />
  </svg>
);
export default IconShirt2;
