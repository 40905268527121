import * as React from "react";
const IconCancel = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M44 24c0 11.046-8.954 20-20 20S4 35.046 4 24 12.954 4 24 4s20 8.954 20 20m-7.272 12.728A17.94 17.94 0 0 1 24 42c-9.941 0-18-8.059-18-18 0-4.97 2.015-9.47 5.272-12.728zm1.336-1.492-25.3-25.3A17.92 17.92 0 0 1 24 6c9.941 0 18 8.059 18 18 0 4.25-1.473 8.156-3.936 11.236"
      clipRule="evenodd"
    />
  </svg>
);
export default IconCancel;
