import * as React from "react";
const IconRingAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m34.9 23.4 4.9-4.6c.3-.3.4-.7.2-1.1l-2-5.2c-.1-.4-.5-.6-.9-.6H26.8c-.4 0-.8.3-.9.6l-2 5.2c-.1.4 0 .8.2 1.1l4.9 4.6c-9.1 1.4-16 9.2-16 18.7.1 10.4 8.6 18.9 19 18.9s18.9-8.5 18.9-18.9c0-9.5-6.9-17.3-16-18.7m-7.4-9.5h8.9l1.5 3.9-6 5.6-6-5.6zM32 59c-9.3 0-16.9-7.6-16.9-16.9 0-9 7-16.3 15.9-16.9l.3.3c.2.2.4.3.7.3s.5-.1.7-.3l.3-.3c9 .5 15.9 7.8 15.9 16.9C48.9 51.4 41.3 59 32 59M32 7.7c.6 0 1-.4 1-1V4c0-.6-.4-1-1-1s-1 .4-1 1v2.7c0 .6.4 1 1 1M23.9 9.1c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 .1-1.4l-1.8-2c-.4-.4-1-.4-1.4-.1-.4.4-.4 1-.1 1.4zM39.3 9.5c.3 0 .5-.1.7-.3l1.8-2c.4-.4.3-1-.1-1.4s-1-.3-1.4.1l-1.8 2c-.4.4-.3 1 .1 1.4.2.1.5.2.7.2" />
  </svg>
);
export default IconRingAlt1;
