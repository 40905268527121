import * as React from "react";
const IconSelectCursorCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m58.7 48.3-14.9-5.6c-.5-.2-1.1-.1-1.5.3s-.5 1-.3 1.5l5.6 14.9c.1.4.5.6.8.6h.1c.4 0 .7-.2.9-.5l3-5.1 3.7 3.7c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4L53.8 53l5.1-3c.3-.2.5-.6.5-.9-.1-.4-.3-.7-.7-.8m-7 3.6c-.1.1-.3.2-.4.4l-2.6 4.4L44.4 45l11.7 4.4zM40.3 46.7h-3c-.6 0-1 .4-1 1s.4 1 1 1h3c.6 0 1-.4 1-1s-.4-1-1-1M30 46.7h-4.8c-.6 0-1 .4-1 1s.4 1 1 1H30c.6 0 1-.4 1-1s-.4-1-1-1M18 46.7h-3c-.6 0-1 .4-1 1s.4 1 1 1h3c.6 0 1-.4 1-1s-.5-1-1-1M8.3 46.7H6.6V45c0-.6-.4-1-1-1s-1 .4-1 1v2.7c0 .6.4 1 1 1h2.7c.6 0 1-.4 1-1s-.5-1-1-1M8.3 4H5.6c-.6 0-1 .4-1 1v2.7c0 .6.4 1 1 1s1-.4 1-1V6h1.7c.6 0 1-.4 1-1s-.5-1-1-1M45.6 6h1.7v1.7c0 .6.4 1 1 1s1-.4 1-1V5c0-.6-.4-1-1-1h-2.7c-.6 0-1 .4-1 1s.5 1 1 1M40.3 4h-3c-.6 0-1 .4-1 1s.4 1 1 1h3c.6 0 1-.4 1-1s-.4-1-1-1M30 4h-4.8c-.6 0-1 .4-1 1s.4 1 1 1H30c.6 0 1-.4 1-1s-.4-1-1-1M18 4h-3c-.6 0-1 .4-1 1s.4 1 1 1h3c.6 0 1-.4 1-1s-.5-1-1-1M48.3 35.2c-.6 0-1 .4-1 1v3c0 .6.4 1 1 1s1-.4 1-1v-3c0-.6-.4-1-1-1M49.3 24.1c0-.6-.4-1-1-1s-1 .4-1 1v4.8c0 .6.4 1 1 1s1-.4 1-1zM49.3 13.8c0-.6-.4-1-1-1s-1 .4-1 1v3c0 .6.4 1 1 1s1-.4 1-1zM5.6 39.5c.6 0 1-.4 1-1v-3c0-.6-.4-1-1-1s-1 .4-1 1v3c0 .6.5 1 1 1M5.6 29.3c.6 0 1-.4 1-1v-4.8c0-.6-.4-1-1-1s-1 .4-1 1v4.8c0 .5.5 1 1 1M5.6 17.2c.6 0 1-.4 1-1v-3c0-.6-.4-1-1-1s-1 .4-1 1v3c0 .5.5 1 1 1" />
  </svg>
);
export default IconSelectCursorCopy;
