import * as React from "react";
const IconCommentsAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.6 3.7H32c-7.6 0-13.9 6.2-13.9 13.9 0 1.9.4 3.7 1.1 5.4H7.7c-2.3 0-4.2 1.9-4.2 4.2v30.6c0 1 .6 1.9 1.5 2.3.4.2.7.2 1.1.2.6 0 1.2-.2 1.6-.6l7.1-5.9h20.6c8.5 0 15.5-6.9 15.5-15.4 0-2.5-.6-4.9-1.7-7h1.1l6.3 5.2c.4.4 1 .5 1.5.5.3 0 .7-.1 1-.2.8-.4 1.4-1.2 1.4-2.2V7.6c0-2.2-1.7-3.9-3.9-3.9M35.5 51.8h-21c-.2 0-.5.1-.6.2l-7.4 6.1c-.3.2-.5.1-.6.1s-.3-.2-.3-.5V27.2c0-1.2 1-2.2 2.2-2.2h27.7C42.9 25 49 31 49 38.4s-6.1 13.4-13.5 13.4m23-17c0 .2-.1.3-.2.4-.1 0-.3.1-.4 0l-6.6-5.5c-.2-.1-.4-.2-.6-.2H48c-2.8-3.9-7.4-6.5-12.5-6.5h-14c-.9-1.7-1.3-3.5-1.3-5.4C20.1 11 25.4 5.7 32 5.7h24.6c1 0 1.9.8 1.9 1.9z" />
  </svg>
);
export default IconCommentsAlt;
