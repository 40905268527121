import * as React from "react";
const IconVrLaptop = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M20.4 33.7h7.1c.3 0 .5-.1.7-.3l3.8-3.8 3.8 3.8c.2.2.4.3.7.3h7.1c3 0 5.4-2.4 5.4-5.4V19c0-3-2.4-5.4-5.4-5.4h-2.1V12c0-3-2.4-5.4-5.4-5.4h-8.4c-3 0-5.4 2.4-5.4 5.4v1.8h-2.1c-3 0-5.4 2.4-5.4 5.4v9.3c.2 2.8 2.6 5.2 5.6 5.2m7.4-25.1h8.4c1.9 0 3.4 1.5 3.4 3.4v1.8H24.4V12c0-1.9 1.5-3.4 3.4-3.4m-7.4 7.1h23.3c1.9 0 3.4 1.5 3.4 3.4v2.5h-4.4c-.6 0-1 .4-1 1s.4 1 1 1H47v4.7c0 1.9-1.5 3.4-3.4 3.4h-6.7l-4.2-4.2c-.4-.4-1-.4-1.4 0l-4.2 4.2h-6.7c-1.9 0-3.4-1.5-3.4-3.4v-4.7h4.4c.6 0 1-.4 1-1s-.4-1-1-1H17v-2.5c0-1.8 1.5-3.4 3.4-3.4" />
    <path d="M61 55.4h-5.1V26.5c0-.6-.4-1-1-1s-1 .4-1 1v28.8H10.1V26.5c0-.6-.4-1-1-1s-1 .4-1 1v28.8H3c-.6 0-1 .4-1 1s.4 1 1 1h58c.6 0 1-.4 1-1s-.4-.9-1-.9" />
  </svg>
);
export default IconVrLaptop;
