import * as React from "react";
const IconCellphoneVr1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.2 25.6h-2.4v-2c0-3.2-2.6-5.8-5.8-5.8h-9.2c-3.2 0-5.8 2.6-5.8 5.8v2h-2.4c-3.2 0-5.8 2.6-5.8 5.8v10.1c0 3.2 2.6 5.8 5.8 5.8h7.8c.3 0 .5-.1.7-.3l4.3-4.2 4.3 4.2c.2.2.4.3.7.3h7.8c3.2 0 5.8-2.6 5.8-5.8V31.4c0-3.2-2.6-5.8-5.8-5.8m-17.3-5.8h9.2c2.1 0 3.8 1.7 3.8 3.8v2H35.1v-2c0-2.1 1.7-3.8 3.8-3.8m17.3 25.5h-7.4l-4.7-4.6c-.4-.4-1-.4-1.4 0L38 45.4h-7.4c-2.1 0-3.8-1.7-3.8-3.8v-5.3h4.9c.6 0 1-.4 1-1s-.4-1-1-1H27v-2.9c0-2.1 1.7-3.8 3.8-3.8h25.5c2.1 0 3.8 1.7 3.8 3.8v2.9h-4.9c-.6 0-1 .4-1 1s.4 1 1 1H60v5.3c0 2-1.7 3.7-3.8 3.7" />
    <path d="M36.7 52.7c-.6 0-1 .4-1 1 0 2.8-2.2 5-5 5H9c-2.8 0-5-2.2-5-5V10.3c0-2.8 2.2-5 5-5h3.4v3.1c0 2.2 1.8 4 4 4H24c2.2 0 4-1.8 4-4V5.3h2.7c2.8 0 5 2.2 5 5v1.5c0 .6.4 1 1 1s1-.4 1-1v-1.5c0-3.9-3.1-7-7-7H9c-3.9 0-7 3.1-7 7v43.4c0 3.9 3.1 7 7 7h21.7c3.9 0 7-3.1 7-7 0-.5-.5-1-1-1M26 8.4c0 1.1-.9 2-2 2h-7.6c-1.1 0-2-.9-2-2V5.3H26z" />
    <path d="M17.5 50.9c-.6 0-1 .4-1 1s.4 1 1 1h4.6c.6 0 1-.4 1-1s-.4-1-1-1z" />
  </svg>
);
export default IconCellphoneVr1;
