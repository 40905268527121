import * as React from "react";
const IconZw = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 85.333 512 341.333"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M0 85.333h512V426.67H0z" />
    <path  d="M0 134.196h512v48.868H0zm0 195.472h512v48.868H0z" />
    <path  d="M0 85.333h512v48.868H0z" />
    <path  d="M0 183.069h512v48.868H0zm0 97.737h512v48.868H0z" />
    <path  d="M0 378.542h512v48.128H0z" />
    <path  d="M276.992 255.996 106.329 426.659H0V85.333h106.329z" />
    <path d="M256 255.996 85.334 426.662h20.987l170.667-170.666L106.321 85.33H85.334z" />
    <path
      
      d="m102.465 202.57 13.259 40.812h42.917l-34.718 25.226 13.26 40.814-34.718-25.224-34.72 25.224 13.262-40.814-34.718-25.226h42.915z"
    />
    <path
      
      d="m138.94 259.335-34.559-12.243s-2.553-23.955-2.708-24.766c-1.173-6.18-6.603-10.851-13.123-10.851-7.376 0-13.357 5.98-13.357 13.357 0 1.223.178 2.402.486 3.528l-9.689 9.755h17.229c0 17.882-13.344 17.882-13.344 35.691l7.402 17.809h44.522l7.422-17.809h-.004a17.8 17.8 0 0 0 1.381-5.231c6.397-2.589 8.342-9.24 8.342-9.24"
    />
  </svg>
);
export default IconZw;
