import * as React from "react";
const IconDropAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32.3 62c-6 0-12.2-2.7-16.5-7.4-5-5.4-6.5-12.4-4.3-19.7 1-3.3 2.7-6.2 4.2-8.6.6-1 1.3-2 1.9-3L31.2 2.5c.4-.6 1.3-.6 1.7 0l12.7 19.7c.4.7.9 1.4 1.4 2.1 2.5 3.7 5 7.5 6 11.9 1.2 5.6.2 11.4-2.8 16-3.6 5.4-9.5 9.1-15.9 9.7-.7.1-1.3.1-2 .1M32 4.8 19.3 24.4c-.6 1-1.3 2-1.9 3-1.4 2.3-3 5-4 8.2-2 6.5-.7 12.8 3.8 17.7C21.5 58 28 60.6 34 59.9c5.8-.6 11.2-3.9 14.4-8.8 2.7-4.1 3.6-9.5 2.5-14.5-.9-4-3.3-7.7-5.7-11.2-.5-.7-.9-1.4-1.4-2.1zM16.6 26.9" />
  </svg>
);
export default IconDropAlt;
