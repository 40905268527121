import * as React from "react";
const IconPneumonia = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      d="M33.974 19a2 2 0 1 1-4 .003 2 2 0 0 1 4-.003M36.99 37.997a2 2 0 1 0-.004-4 2 2 0 0 0 .004 4M33.986 34a2 2 0 1 1-4 .003 2 2 0 0 1 4-.003M35.984 30.998a2 2 0 1 0-.004-4 2 2 0 0 0 .004 4M33.979 25a2 2 0 1 1-4 .003 2 2 0 0 1 4-.003M15.975 21.015a2 2 0 1 1-.003-4 2 2 0 0 1 .003 4M8.988 36.02a2 2 0 1 0 4-.003 2 2 0 0 0-4 .003M15.988 36.015a2 2 0 1 1-.004-4 2 2 0 0 1 .004 4M9.982 29.02a2 2 0 1 0 4-.004 2 2 0 0 0-4 .004M15.98 27.015a2 2 0 1 1-.003-4 2 2 0 0 1 .003 4"
    />
    <path
      
      fillRule="evenodd"
      d="M24 6.001h1v14.496c0 1.397.564 1.942 1.004 2.198q.074.044.147.078l-.003-.373c-.038-3.528-.112-10.4 5.327-10.4 7.45 0 12.773 26.007 9.58 28.66-3.194 2.654-9.58 1.062-12.773-2.123-2.578-2.57-2.355-8.578-2.195-12.891q.016-.396.028-.773a4.3 4.3 0 0 1-1.118-.45A3.8 3.8 0 0 1 24 23.59c-.297.353-.64.627-.997.835-.4.233-.793.37-1.119.45l.028.77c.16 4.313.384 10.321-2.194 12.892-3.193 3.185-9.579 4.777-12.772 2.123S9.074 12 16.525 12c5.439 0 5.365 6.872 5.328 10.4v.049l-.004.327q.075-.036.148-.078C22.437 22.44 23 21.897 23 20.5V6zM31.475 14c-.954 0-1.49.3-1.867.687-.433.446-.795 1.164-1.049 2.178-.445 1.775-.426 3.817-.41 5.465q.003.428.004.816c0 .76-.033 1.67-.069 2.616v.002l-.04 1.127c-.044 1.375-.07 2.815-.004 4.208.067 1.4.224 2.685.517 3.76.297 1.09.694 1.82 1.137 2.262 1.279 1.275 3.297 2.304 5.355 2.704 2.035.394 3.673.102 4.633-.628l.016-.04a3.7 3.7 0 0 0 .19-.788c.117-.775.147-1.856.068-3.188-.157-2.65-.728-6.012-1.63-9.32-.904-3.321-2.109-6.46-3.474-8.722-.683-1.133-1.357-1.96-1.982-2.485-.618-.52-1.077-.654-1.395-.654m-14.95 0c.954 0 1.49.3 1.867.687.433.446.795 1.164 1.05 2.178.444 1.775.425 3.817.41 5.465q-.004.428-.005.816c0 .76.034 1.67.07 2.616l.04 1.129c.044 1.375.07 2.815.004 4.208-.067 1.4-.224 2.685-.517 3.76-.296 1.09-.694 1.82-1.137 2.262-1.278 1.275-3.296 2.304-5.355 2.704-2.035.394-3.673.102-4.632-.628l-.017-.04a3.7 3.7 0 0 1-.19-.788c-.116-.775-.147-1.856-.068-3.188.157-2.65.727-6.012 1.629-9.32.905-3.321 2.11-6.46 3.474-8.722.683-1.132 1.357-1.96 1.982-2.485.618-.52 1.077-.654 1.395-.654"
      clipRule="evenodd"
    />
  </svg>
);
export default IconPneumonia;
