import React, { useState } from 'react';
import { BringzzButton, BringzzImage, BringzzText } from "@bringzz/components";
import { useNavigation } from 'context/NavigationContext';
import {
    BringzzPageContainer,
    BringzzIcon,
    BringzzRadioList,
    BringzzRadio
} from "@bringzz/components";
import { useLocation } from 'react-router-dom';
import SOFORT_IMAGE from "assets/methods/sofort.png"
import KLARNA_IMAGE from "assets/methods/klarna.png"
import GOOGLEPAY_IMAGE from "assets/methods/googlepay.png"
import APPLEPAY_IMAGE from "assets/methods/applepay.png"

function useQuery() {
    KLARNA_IMAGE
    return new URLSearchParams(useLocation().search);
}

const ConsumerPaymentPage = () => {
    const { backPress } = useNavigation();

    const { navigate } = useNavigation();

    let state = {
        checked: (
            <BringzzIcon
                icon="IconCheck"
                library="Custom"
                className="h-5 w-5 p-1 bg-magic-lilac text-light rounded-full"
                size="12"
            />
        ),
        unChecked: (
            <div className="h-5 w-5 rounded-full border border-neutral-400"></div>
        ),
    };

    const [selectedMethod, setSelectedMethod] = useState(null)

    return (
        <BringzzPageContainer
            className="bg-white"
            steps={true}
            activeStep={4}
            header={{
                left: (
                    <BringzzIcon
                        size='22'
                        icon='IconChevronLeft'
                        folder='LineIcons'
                        className='cursor-pointer'
                        onClick={backPress}
                    />
                ),
                title: "Join Us",
                right: <BringzzButton size='sm' className="border border-black" onClick={() => {
                    navigate('/register/consumer/address');
                }}>Skip</BringzzButton>
            }}
            footer={
                <div className='pb-8 px-6'>
                    <BringzzButton
                        size='lg'
                        className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
                        onClick={() => {
                            if (selectedMethod == "card")
                                navigate("/register/consumer/card")
                            else
                                navigate("/register/consumer/address")
                        }}
                        disabled={!selectedMethod}
                    >
                        Continue
                    </BringzzButton>
                </div>
            }
            authenticated={false}
        >
            <div className='relative flex justify-center bg-magic-lilac/20'>
                <div className='relative bg-white w-full bg-cover bg-center flex flex-col'>
                    {/* Content */}
                    <div className='flex-grow flex flex-col py-8 px-4'>
                        <div className='z-10 flex flex-col '>
                            <div className='pl-2 flex flex-col space-y-6 md:max-w-lg lg:max-w-xl xl:max-w-2xl'>
                                <BringzzText tag='h1' className='pl-2 text-midnight-blue font-bold'>
                                    Payment Method
                                </BringzzText>
                                <BringzzText tag="h3" className="font-normal">Let us know if you are privately insured, it could benefit the pricing of your basket</BringzzText>
                            </div>
                            <div className='mt-8'>
                                <BringzzRadioList
                                    onChange={(newValue) => setSelectedMethod(newValue)}
                                    state={state}
                                    className="divide-y border-t"
                                >
                                    <BringzzRadio left={<BringzzIcon
                                        icon="IconCreditCard"
                                        folder="LineIcons"
                                        className="flex items-center w-10 justify-center rounded-full"
                                        src={SOFORT_IMAGE}
                                        size='28'
                                    />} leftClassname='w-12' className="p-2" value="card">
                                        <div>
                                            <BringzzText tag="h3">Credit Card</BringzzText>
                                        </div>
                                    </BringzzRadio>
                                    <BringzzRadio left={<BringzzImage
                                        className="flex items-center justify-center"
                                        src={SOFORT_IMAGE}
                                    />} leftClassname='w-12' className="p-2" value="sofor">
                                        <div>
                                            <BringzzText tag="h3">Sofortüberweisung</BringzzText>
                                        </div>
                                    </BringzzRadio>
                                    <BringzzRadio className="p-2" value="klarna" left={<BringzzImage
                                        className="flex items-center justify-center"
                                        src={KLARNA_IMAGE}
                                    />} leftClassname='w-12'>
                                        <div>
                                            <BringzzText tag="h3">Klarna</BringzzText>
                                        </div>
                                    </BringzzRadio>
                                    <BringzzRadio className="p-2" value="google" left={<BringzzImage
                                        className="flex items-center justify-center"
                                        src={GOOGLEPAY_IMAGE}
                                    />} leftClassname='w-12'>
                                        <div>
                                            <BringzzText tag="h3">Google Pay</BringzzText>
                                        </div>
                                    </BringzzRadio>
                                    <BringzzRadio className="p-2" value="apple" left={<BringzzImage
                                        className="flex items-center justify-center"
                                        src={APPLEPAY_IMAGE}
                                    />} leftClassname='w-12'>
                                        <div>
                                            <BringzzText tag="h3">Apple Pay</BringzzText>
                                        </div>
                                    </BringzzRadio>
                                </BringzzRadioList>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BringzzPageContainer>
    );
};

export default ConsumerPaymentPage;
