import * as React from "react";
const IconDropper = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M42.8 15.8h-4.4V8.4C38.4 4.9 35.5 2 32 2s-6.4 2.9-6.4 6.4v7.3h-4.4c-.6 0-1 .4-1 1s.4 1 1 1h4.4v25.4c0 .2.1.4.2.6l5.2 7.9V61c0 .6.4 1 1 1s1-.4 1-1v-9.4l5.2-7.9c.1-.2.2-.4.2-.6V17.8h4.4c.6 0 1-.4 1-1s-.4-1-1-1M27.6 8.4C27.6 6 29.6 4 32 4s4.4 2 4.4 4.4v7.3h-8.8zm8.8 34.4L32 49.5l-4.4-6.6V17.8h8.8z" />
  </svg>
);
export default IconDropper;
