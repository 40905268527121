import * as React from "react";
const IconRoadCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.9 53.9 50.8 9c-.3-1-1.2-1.8-2.2-1.8H15.4c-1.1 0-2 .7-2.2 1.8L2.1 53.9c-.2.7 0 1.4.4 2s1.1.9 1.8.9h55.4c.7 0 1.4-.3 1.8-.9.5-.6.6-1.3.4-2M32 48.1c-.6 0-1 .4-1 1V54c0 .3.1.6.3.7H12.1L22.8 9.3h18.4l10.7 45.4H32.7c.2-.2.3-.4.3-.7v-4.9c0-.6-.4-1-1-1M4.1 54.6s-.1-.1-.1-.3L15.1 9.5c0-.1.2-.2.3-.2h5.3L10 54.7H4.3c-.1 0-.2-.1-.2-.1m55.8 0s-.1.1-.2.1H54L43.3 9.3h5.3c.1 0 .3.1.3.2L60 54.4c0 .1 0 .2-.1.2" />
    <path d="M32 16.6c.6 0 1-.4 1-1v-4.9c0-.6-.4-1-1-1s-1 .4-1 1v4.9c0 .5.4 1 1 1M32 29.4c.6 0 1-.4 1-1v-4.9c0-.6-.4-1-1-1s-1 .4-1 1v4.9c0 .5.4 1 1 1M33 36.3c0-.6-.4-1-1-1s-1 .4-1 1v4.9c0 .6.4 1 1 1s1-.4 1-1z" />
  </svg>
);
export default IconRoadCopy;
