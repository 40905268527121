import * as React from "react";
const IconFlask = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.5 51.6 40.4 28.3V4h4.4c.6 0 1-.4 1-1s-.4-1-1-1H20.2c-.6 0-1 .4-1 1s.4 1 1 1h3.4v24.3L9.5 51.6c-1.3 2.1-1.3 4.7-.1 6.9s3.5 3.5 6 3.5h33.3c2.5 0 4.7-1.3 6-3.5 1.1-2.2 1.1-4.8-.2-6.9m-1.6 5.9c-.9 1.6-2.5 2.5-4.2 2.5H15.3c-1.8 0-3.4-.9-4.2-2.5-.9-1.5-.8-3.4.1-4.9L25.5 29c.1-.2.1-.3.1-.5V4h12.7v24.6c0 .2 0 .4.1.5l14.3 23.6c1 1.5 1 3.3.2 4.8" />
  </svg>
);
export default IconFlask;
