import * as React from "react";
const IconPancake = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.9 49.8h-5c1.2-1.2 1.9-2.9 1.9-4.6 0-2.4-1.3-4.5-3.2-5.6l1.2-.9c1.3-1.3 1.9-2.9 1.9-4.7 0-2.4-1.3-4.5-3.2-5.6l1.2-.9c1.3-1.3 1.9-2.9 1.9-4.7 0-3.7-3-6.6-6.6-6.6H34.8c.7-.9 1.1-1.9 1.1-3.1 0-2.3-1.5-4.2-3.6-4.8 1.1-2.2 3.1-3.7 5.1-4 .5-.1.9-.6.8-1.1s-.6-1.3-1.1-1.2c-2.9.5-5.5 2.7-6.9 5.8-2.5.3-4.4 2.4-4.4 5 0 1.2.4 2.2 1.1 3.1h-13c-1.8 0-3.4.7-4.7 1.9-1.3 1.3-1.9 2.9-1.9 4.7 0 2.4 1.3 4.5 3.2 5.6l-1.2.9c-1.3 1.3-1.9 2.9-1.9 4.7 0 2.4 1.3 4.5 3.2 5.6l-1.2.9c-1.3 1.3-1.9 2.9-1.9 4.7s.7 3.4 1.9 4.6H4.1c-.6 0-1 .4-1 1v2.3c0 4.9 4 8.9 8.9 8.9h40c4.9 0 8.9-4 8.9-8.9v-2.3c0-.3-.5-.7-1-.7m-5.2-16c0 1.2-.5 2.4-1.4 3.3s-2 1.4-3.3 1.4h-4.6c.1-.4.1-.8.1-1.3v-8H50c2.6 0 4.7 2.1 4.7 4.6m-26.9-21c0-1.7 1.4-3.1 3.1-3.1s3.1 1.4 3.1 3.1-1.3 3-3 3.1h-.1c-1.8 0-3.1-1.4-3.1-3.1M9.3 22.6c0-1.2.5-2.4 1.4-3.3s2-1.4 3.3-1.4h36.3c2.6 0 4.6 2.1 4.6 4.6 0 1.2-.5 2.4-1.4 3.3s-2 1.4-3.3 1.4h-5.5c-.6 0-1 .4-1 1v9c0 .7-.1 1.3-.5 1.8-.2.3-.4.7-.7.9-.7.7-1.7 1.1-2.8 1.1-1.5 0-2.8-.8-3.5-2.1-.3-.5-.4-1.1-.4-1.8v-9c0-.6-.4-1-1-1H13.9c-2.5.1-4.6-2-4.6-4.5m0 11.2c0-1.2.5-2.4 1.4-3.3s2-1.4 3.3-1.4h19.8v8c0 .4.1.9.1 1.3h-20c-2.5.1-4.6-2-4.6-4.6m0 11.3c0-1.2.5-2.4 1.4-3.3s2-1.4 3.3-1.4h20.8c1.1 1.6 2.9 2.6 4.9 2.6 1.6 0 3.1-.6 4.2-1.7.3-.3.5-.6.7-.9h5.5c2.6 0 4.6 2.1 4.6 4.6 0 1.2-.5 2.4-1.4 3.3s-2 1.3-3.2 1.4H13.9c-2.6.1-4.6-2-4.6-4.6m49.6 8c0 3.8-3.1 6.9-6.9 6.9H12c-3.8 0-6.9-3.1-6.9-6.9v-1.3h53.7v1.3z" />
  </svg>
);
export default IconPancake;
