import * as React from "react";
const IconAbstract1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.7 15.5 34 2.4c-1.2-.7-2.8-.7-4 0L7.3 15.5c-1.2.7-2 2-2 3.5v26.2c0 1.4.8 2.8 2 3.5L30 61.6c.6.4 1.3.5 2 .5s1.4-.2 2-.5l22.7-13.1c1.2-.7 2-2 2-3.5V18.9c0-1.4-.8-2.7-2-3.4M7.3 18.9c0-.7.4-1.4 1-1.7L31 4.1c.3-.2.7-.3 1-.3s.7.1 1 .3l22.2 12.8L41.9 25c-.4-.4-.8-.8-1.2-1l-6.1-3.6c-1.6-.9-3.6-.9-5.2 0L23.3 24c-1.6.9-2.5 2.6-2.5 4.4v7.3c0 .6.1 1.1.3 1.6L7.3 45.1V18.9m33.9 9.4v7.3c0 1.1-.6 2.1-1.5 2.7l-6.1 3.6c-1 .6-2.2.6-3.1 0l-6.1-3.6c-.9-.6-1.5-1.6-1.5-2.7v-7.3c0-1.1.6-2.1 1.5-2.7l6.1-3.6c.5-.3 1-.4 1.6-.4.5 0 1.1.1 1.6.4l6.1 3.6c.8.6 1.4 1.7 1.4 2.7m15.5 16.8c0 .7-.4 1.4-1 1.7L33 59.9c-.6.4-1.4.4-2 0L8.4 46.8 22.1 39c.4.4.8.8 1.3 1.1l6.1 3.6c.8.5 1.7.7 2.6.7s1.8-.2 2.6-.7l6.1-3.6c1.6-.9 2.5-2.6 2.5-4.4v-7.3c0-.6-.1-1.1-.3-1.6l13.7-8.3c0 .2.1.3.1.5v26.1z" />
  </svg>
);
export default IconAbstract1;
