import * as React from "react";
const IconCrying1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
    <path d="M40.1 40.8H28.8c-3.2 0-5.8 2.6-5.8 5.8 0 .6.4 1 1 1s1-.4 1-1c0-2.1 1.7-3.8 3.8-3.8h11.3c.6 0 1-.4 1-1s-.5-1-1-1M46.4 17.9h-10c-.6 0-1 .4-1 1s.4 1 1 1h4v9.5c0 .6.4 1 1 1s1-.4 1-1v-9.5h4c.6 0 1-.4 1-1s-.5-1-1-1M23.6 29.4v-9.5h4c.6 0 1-.4 1-1s-.4-1-1-1h-10c-.6 0-1 .4-1 1s.4 1 1 1h4v9.5c0 .6.4 1 1 1s1-.4 1-1" />
  </svg>
);
export default IconCrying1;
