import * as React from "react";
const IconNotepad = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.4 4.2c-1.6-1.6-3.6-2.4-5.8-2.4h-24c-3.4 0-6.3 2.8-6.3 6.3V12h-2.8c-1.2 0-2.3 1-2.3 2.3s1 2.3 2.3 2.3h2.8v13.3h-2.8c-1.2 0-2.3 1-2.3 2.3 0 1.2 1 2.3 2.3 2.3h2.8v13.3h-2.8c-1.2 0-2.3 1-2.3 2.3s1 2.3 2.3 2.3h2.8V56c0 3.4 2.8 6.3 6.3 6.3h24c4.5 0 8.2-3.7 8.3-8.2v-44c-.1-2.3-.9-4.4-2.5-5.9M49.3 54c0 2.1-1.7 3.7-3.8 3.7h-24c-1 0-1.8-.8-1.8-1.8V52h5.4c1.2 0 2.3-1 2.3-2.3s-1-2.3-2.3-2.3h-5.4V34.3h5.4c1.2 0 2.3-1 2.3-2.3 0-1.2-1-2.3-2.3-2.3h-5.4V16.4h5.4c1.2 0 2.3-1 2.3-2.3s-1-2.3-2.3-2.3h-5.4V8c0-1 .8-1.8 1.8-1.8h24c1 0 1.9.4 2.7 1.1.7.7 1.1 1.7 1.1 2.7z" />
  </svg>
);
export default IconNotepad;
