import * as React from "react";
const IconPerfume = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.3 42.7v-.5l-7.1-23.1 2.6.3h.1c.3 0 .6-.1.8-.3s.3-.6.2-.9L55 11l1.8-7.1c.1-.3 0-.7-.2-.9s-.5-.4-.9-.3L38.1 4.4C36.5 2.9 34.4 2 32 2s-4.5.9-6.1 2.4L8.2 2.7c-.3-.1-.6.1-.8.3s-.3.6-.2.9L9 11l-1.8 7.1c-.1.3 0 .7.2.9s.5.3.8.3h.1l2.6-.3-7.2 22.3v.5c0 .1 0 .1.1.2v.1l9.6 16.6c1.2 2 3.4 3.3 5.7 3.3H45c2.3 0 4.5-1.2 5.6-3.2L60.2 43v-.1c0-.1.1-.2.1-.2M53 11.3l1.5 6-14.9-1.5c.9-1.4 1.4-3 1.4-4.8 0-1.7-.5-3.4-1.4-4.8l14.9-1.5-1.5 6zM39 11c0 3.9-3.1 7-7 7s-7-3.1-7-7 3.1-7 7-7 7 3.1 7 7m-28-.2-1.5-6 14.9 1.5c-.9 1.4-1.4 3-1.4 4.7s.5 3.4 1.4 4.8l-15 1.4 1.5-6c.1-.1.1-.3.1-.4m14.9 6.8c1.6 1.5 3.8 2.4 6.1 2.4 2.4 0 4.5-.9 6.1-2.4L51 18.9 57.5 40c-14.9-9.5-36.6-9.9-51-.9L13 18.9zm23 40.2c-.8 1.4-2.3 2.2-3.9 2.2H19.1c-1.6 0-3.2-.9-4-2.3L6 41.9c14-9.9 37.4-9.5 52 .8z" />
  </svg>
);
export default IconPerfume;
