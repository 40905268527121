import * as React from "react";
const IconApartmentsAlt11 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.1 29.3H39V6.7c0-2.2-1.8-4-4-4H6c-2.2 0-4 1.8-4 4v50.7c0 2.2 1.8 4 4 4h51.1c2.7 0 4.9-2.2 4.9-4.9V34.2c0-2.7-2.2-4.9-4.9-4.9M4 23.4h15.5V39H4zm17.5 0H37V39H21.5zM37 6.7v14.7H21.5V4.7H35c1.1 0 2 .8 2 2m-31-2h13.5v16.7H4V6.7c0-1.2.9-2 2-2M4 57.3V41h15.5v18.4H6c-1.1-.1-2-.9-2-2.1m17.5 2V41H37v18.4H21.5zM60 56.4c0 1.6-1.3 2.9-2.9 2.9H39v-28h18.1c1.6 0 2.9 1.3 2.9 2.9z" />
  </svg>
);
export default IconApartmentsAlt11;
