import * as React from "react";
const IconRupeeCoin = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M43.7 18.9c0-.6-.4-1-1-1H22.6c-.6 0-1 .4-1 1s.4 1 1 1h5.7c3.2.1 5 3 5.3 6.1h-11c-.6 0-1 .4-1 1s.4 1 1 1h10.8c-1 2.9-5.7 5.2-8.8 5.2q-.6 0-.9.6c-.2.4-.1.8.1 1.1l11.3 13.8c.2.2.5.4.8.4.2 0 .4-.1.6-.2.4-.4.5-1 .1-1.4L26.7 35c3.8-.7 8.2-3.3 8.9-7h7.1c.6 0 1-.4 1-1s-.4-1-1-1h-7c-.2-2.3-1-4.5-2.6-6.1h9.5c.6 0 1.1-.4 1.1-1" />
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
  </svg>
);
export default IconRupeeCoin;
