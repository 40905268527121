import * as React from "react";
const IconHeadphoneAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.4 13.2c-5.8-5.7-13.7-8.8-21.9-8.6C15.3 4.8 2.2 17.9 2 33.7v13.2c0 1.1.8 1.9 1.9 2l22.2 10.6H32c.6 0 1-.4 1-1s-.4-1-1-1h-5.5l-18-8.6h1.3c1.1 0 2-.9 2-2V35.7c0-1.1-.9-2-2-2H4C4.2 18.9 16.4 6.8 31.5 6.6c7.8-.2 15 2.7 20.5 8.1 5.2 5.1 8 11.8 8 18.9v.2h-5.9c-1.1 0-2 .9-2 2V47c0 1.1.9 2 2 2H60c1.1 0 2-.9 2-2V33.5c0-7.6-3.1-14.8-8.6-20.3M9.9 46.9H4.3c-.1 0-.2-.1-.3-.1V35.7h5.9zm50.1 0h-5.9V35.7H60v11.2" />
  </svg>
);
export default IconHeadphoneAlt;
