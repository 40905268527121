import * as React from "react";
const IconInboxFull = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 20c0-6.4-5.2-11.6-11.6-11.6-6.4.1-11.6 5.3-11.6 11.6 0 1.1.2 2.2.5 3.2H7.1c-2.3 0-4.1 1.9-4.1 4.1v24c0 2.3 1.9 4.1 4.1 4.1h39.2c2.3 0 4.1-1.9 4.1-4.1V31.6C56.3 31.1 61 26.1 61 20m-21.9 5.3c.6 1.2 1.5 2.4 2.5 3.3l-13.8 8.6c-.7.4-1.5.4-2.2 0L6.4 25.4c.2-.1.5-.1.7-.1zM5 27.4c0-.2 0-.3.1-.5l16.8 10.3L5.4 52.6c-.2-.3-.4-.7-.4-1.2zm18.7 10.9.9.5q1.05.6 2.1.6c.7 0 1.5-.2 2.2-.6l.8-.5 16 15.2H7.4zm24.1 14.6c-.1-.1-.1-.2-.2-.3L31.5 37.2l11.8-7.4c1.5.9 3.2 1.6 5.1 1.7v19.8c0 .7-.2 1.2-.6 1.6m1.6-23.3c-5.3 0-9.6-4.3-9.6-9.6s4.3-9.6 9.6-9.6S59 14.8 59 20s-4.3 9.6-9.6 9.6" />
    <path d="M49.4 18.7c-.6 0-1 .4-1 1v4.6c0 .6.4 1 1 1s1-.4 1-1v-4.6c0-.5-.4-1-1-1M49.4 15.3c-.6 0-1 .5-1 1s.4 1 1 1 1-.4 1-1v-.1c0-.5-.4-.9-1-.9" />
  </svg>
);
export default IconInboxFull;
