import * as React from "react";
const IconCornerUpRight = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.5 20.1 42.7 3.3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l15.1 15.1H12.5c-4.6 0-8.3 3.7-8.3 8.3V60c0 .6.4 1 1 1s1-.4 1-1V28.1c0-3.5 2.8-6.3 6.3-6.3h43.9L41.3 36.9c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l16.8-16.8c.4-.4.4-1 0-1.4" />
  </svg>
);
export default IconCornerUpRight;
