import * as React from "react";
const IconCalculatorAltCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M48.2 2H15.8c-3 0-5.5 2.5-5.5 5.5v49.1c0 3 2.5 5.5 5.5 5.5h32.3c3 0 5.5-2.5 5.5-5.5V7.5c0-3-2.4-5.5-5.4-5.5m3.4 54.5c0 1.9-1.6 3.5-3.5 3.5H15.8c-1.9 0-3.5-1.6-3.5-3.5v-49c0-1.9 1.6-3.5 3.5-3.5h32.3c1.9 0 3.5 1.6 3.5 3.5z" />
    <path d="M42.1 10.4H21.9c-.6 0-1 .4-1 1v14.2c0 .6.4 1 1 1h20.3c.6 0 1-.4 1-1V11.4c-.1-.5-.5-1-1.1-1m-1 14.2H22.9V12.4h18.3v12.2zM22.9 34.7h-.3c-.6 0-1 .4-1 1s.4 1 1 1h.3c.6 0 1-.4 1-1s-.4-1-1-1M22.9 42.9h-.3c-.6 0-1 .4-1 1s.4 1 1 1h.3c.6 0 1-.4 1-1s-.4-1-1-1M32.1 34.7h-.3c-.6 0-1 .4-1 1s.4 1 1 1h.3c.6 0 1-.4 1-1s-.4-1-1-1M32.1 42.9h-.3c-.6 0-1 .4-1 1s.4 1 1 1h.3c.6 0 1-.4 1-1s-.4-1-1-1M41.4 34.7h-.3c-.6 0-1 .4-1 1s.4 1 1 1h.3c.6 0 1-.4 1-1s-.5-1-1-1M41.4 42.9h-.3c-.6 0-1 .4-1 1s.4 1 1 1h.3c.6 0 1-.4 1-1s-.5-1-1-1M22.9 51.9h-.3c-.6 0-1 .4-1 1s.4 1 1 1h.3c.6 0 1-.4 1-1s-.4-1-1-1M32.1 51.9h-.3c-.6 0-1 .4-1 1s.4 1 1 1h.3c.6 0 1-.4 1-1s-.4-1-1-1M41.4 51.9h-.3c-.6 0-1 .4-1 1s.4 1 1 1h.3c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconCalculatorAltCopy;
