import * as React from "react";
const IconToungeCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
    <path d="M44.5 39.2c-3.4 3.2-7.8 4.9-12.5 4.9s-9.1-1.7-12.5-4.9c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4c3.8 3.5 8.7 5.4 13.8 5.4 1.8 0 3.6-.2 5.3-.7v5.1c0 1.4 1.2 2.5 2.6 2.5 1.5 0 2.6-1.1 2.6-2.5V43c1.2-.7 2.3-1.5 3.3-2.5.4-.4.4-1 0-1.4-.2-.2-.8-.2-1.2.1" />
    <circle cx={20.1} cy={22.8} r={4} />
    <circle cx={43.9} cy={22.8} r={4} />
  </svg>
);
export default IconToungeCopy;
