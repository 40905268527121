import * as React from "react";
const IconQuarterMoon = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m-1 58a27.5 27.5 0 0 1-16.7-6.3L31 44.3zm0-56v10.4L4.1 29.5C5.3 15.6 16.8 4.5 31 4M4 31.9l27-15.2v11.5L6 42.3C4.7 39.1 4 35.6 4 32zm2.8 12.3L31 30.5V42L12.7 52.3c-2.4-2.3-4.4-5.1-5.9-8.1M33 60V4c15 .5 27 12.9 27 28S48 59.4 33 60" />
  </svg>
);
export default IconQuarterMoon;
