import * as React from "react";
const IconFire = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 65 65"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M38.7 61.4c-.3 0-.6-.1-.8-.4-.3-.3-.3-.8-.1-1.2 1.5-2.1 3.2-7.3-.6-12.1-.2-.3-.4-.6-.7-.8-1-1.2-2.3-2.7-1.7-6.4-5.1 3-11.3 9.8-7.4 19.5.2.4.1.8-.2 1.1s-.7.4-1.1.2c-5-2.2-11-7.1-12.9-14.3-1.8-6.7.3-14 6.1-21.8 2.7-3.6 6.9-10.9 5.4-19.8-.1-.4.1-.8.4-1s.7-.2 1.1-.1c4 2.2 13.1 8.9 11.1 23.7-.1.7.1 1.3.5 1.6.7.4 1.8.3 2.8-.2.8-.4 2.6-1.8 2.4-5.1 0-.4.2-.8.6-1s.9 0 1.1.3c4.8 5.8 9.2 15 7.2 23.5-1.4 6.1-5.8 10.8-13 14.2.1.1-.1.1-.2.1M27.1 7.2c.7 8.7-3.4 15.7-6 19.2-5.4 7.2-7.4 14-5.8 20.1 1.4 5.5 5.6 9.6 9.6 11.9-3-11 5.8-18.2 11.1-20.6.4-.2.8-.1 1.1.2s.4.7.3 1c-1.3 4.2-.3 5.4.7 6.7.3.3.5.6.7.9 3.3 4.1 3.1 8.5 2 11.5 5.1-2.9 8.2-6.8 9.3-11.5 1.6-6.9-1.6-14.5-5.3-19.8-.5 1.9-1.6 3.4-3.1 4.3-1.7 1-3.6 1-4.9.1-1.1-.7-1.6-2-1.4-3.5 1.6-11.4-3.9-17.5-8.3-20.5" />
  </svg>
);
export default IconFire;
