import * as React from "react";
const IconRocketLaunch = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M15.7 24.9c.1 0 .3 0 .4-.1l1.4-.6-.5 6c0 .4.1.7.4.9.2.1.4.2.6.2.1 0 .3 0 .4-.1l5.3-2.4c2-.9 3.3-2.8 3.5-4.9l.3-4.2 3.6-1.6C40.7 13.8 43.4 9 44.7 4.9c.2-.5-.1-1-.5-1.2C40.3 1.9 35 .6 25.3 4.9l-3.6 1.6-3.4-2.6c-1.7-1.3-4-1.6-6-.7L7 5.6c-.3.1-.5.4-.5.8s.1.7.4.9l4.8 3.7-1.4.6c-.2.1-.4.3-.5.6-.1.2-.1.5 0 .8l5.1 11.4c0 .3.4.5.8.5m9.5-1.1c-.1 1.4-1 2.7-2.3 3.2l-3.7 1.7.4-5.4 5.8-2.6zM9.4 6.7 13.1 5c1.3-.6 2.8-.4 3.9.5l2.5 1.9-5.8 2.6zm4.6 5.4 7.9-3.5L26 6.7C34.3 3 38.9 3.6 42.5 5.1c-1.3 3.7-4 7.5-12.2 11.2l-4.1 1.8-7.9 3.5-2.1.9-4.3-9.5zM15.7 55.6c-.6 0-1 .4-1 1V61c0 .6.4 1 1 1s1-.4 1-1v-4.4c0-.6-.4-1-1-1M28.3 51.8c-.6 0-1 .4-1 1V61c0 .6.4 1 1 1s1-.4 1-1v-8.2c0-.5-.5-1-1-1M40.9 46.8c-.6 0-1 .4-1 1V61c0 .6.4 1 1 1s1-.4 1-1V47.8c0-.6-.5-1-1-1M53.4 41.8c-.6 0-1 .4-1 1V61c0 .6.4 1 1 1s1-.4 1-1V42.8c0-.6-.4-1-1-1M57.6 24.9v-.4c0-.1-.1-.1-.1-.2l-.1-.1-.1-.1c-.1 0-.1-.1-.2-.1l-8-2.9c-.5-.2-1.1.1-1.3.6s.1 1.1.6 1.3l5.6 2.1-42.9 18.1c-.5.2-.7.8-.5 1.3q.3.6.9.6c.1 0 .3 0 .4-.1l42.9-18.3-2.1 5.6c-.2.5.1 1.1.6 1.3.1 0 .2.1.3.1.4 0 .8-.3.9-.7l2.9-7.8v-.1c.1 0 .2-.1.2-.2" />
  </svg>
);
export default IconRocketLaunch;
