import * as React from "react";
const IconSpeechBubble46 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.1 39.2c-.2-2.3-2.1-4-4.4-4-1.3 0-2.3-1-2.5-2.2L48.6 6c-.2-2.3-2.1-4-4.4-4H8.3C5.8 2 3.8 4 3.8 6.5v26.2c0 2.5 2 4.5 4.5 4.5h27.8c1 0 2 .6 2.3 1.6l2.9 7.7c.8 2.1 3.1 3.3 5.3 2.8l1.2-.3c1-.3 2.1.2 2.6 1l7.9 11.6c.2.3.5.4.8.4.1 0 .2 0 .3-.1.4-.2.7-.6.7-1zm-6 9.7c-.8-1.2-2.2-1.9-3.7-1.9-.4 0-.7 0-1.1.1l-1.3.3c-1.2.3-2.5-.4-2.9-1.5l-2.9-7.7c-.7-1.7-2.3-2.9-4.2-2.9H8.3c-1.4 0-2.5-1.1-2.5-2.5V6.5C5.8 5.1 6.9 4 8.3 4h35.9c1.3 0 2.3 1 2.5 2.2l2.6 26.9c.2 2.3 2.1 4 4.4 4 1.3 0 2.3 1 2.5 2.2l1.7 17.8z" />
    <path d="M15.8 16.7h-.2c-1.1 0-2.2.7-2.5 1.8-.4 1.6.8 3 2.3 3h.2c1.1 0 2.2-.7 2.5-1.8.4-1.6-.8-3-2.3-3M26.1 16.7h-.2c-1.1 0-2.2.7-2.5 1.8-.4 1.6.8 3 2.3 3h.3c1.1 0 2.2-.7 2.5-1.8.4-1.6-.8-3-2.4-3M36.5 16.7h-.2c-1.1 0-2.2.7-2.5 1.8-.4 1.6.8 3 2.3 3h.2c1.1 0 2.2-.7 2.5-1.8.4-1.6-.8-3-2.3-3" />
  </svg>
);
export default IconSpeechBubble46;
