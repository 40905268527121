import * as React from "react";
const IconPowerSwitchCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M43.8 16.2c-.5-.3-1.1-.1-1.4.4s-.1 1.1.4 1.4c7.2 3.9 11.6 11.5 11.6 19.6C54.4 50 44.4 60 32 60S9.6 50 9.6 37.6c0-8 4.3-15.5 11.3-19.5.5-.3.6-.9.4-1.4-.3-.5-.9-.6-1.4-.4-7.6 4.4-12.3 12.6-12.3 21.3C7.6 51.1 18.5 62 32 62s24.4-10.9 24.4-24.4c0-8.9-4.8-17.1-12.6-21.4" />
    <path d="M31.7 28.7c.6 0 1-.4 1-1V3c0-.6-.4-1-1-1s-1 .4-1 1v24.7c0 .6.4 1 1 1" />
  </svg>
);
export default IconPowerSwitchCopy;
