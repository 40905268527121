import * as React from "react";
const IconKeyAlt3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62 23.9 56.9 7c-.1-.3-.4-.6-.7-.7L39.9 2c-.3-.1-.7 0-1 .3L27 14.1q-.3.3-.3.6l-1.3 9.7L2.8 46.8c-.5.5-.8 1.2-.8 2v10.4C2 60.7 3.3 62 4.8 62h10.4c.8 0 1.5-.3 2-.8l2.6-2.6c.1-.1.2-.2.2-.4l1.8-5.5 5.7-2q.45-.15.6-.6l1.9-5.5 5-1.4c.2 0 .3-.1.4-.3l4.5-4.4 9.3-1.6c.2 0 .4-.1.5-.3l11.9-11.8c.4-.1.5-.5.4-.9M48.6 35.1l-9.3 1.6c-.2 0-.4.1-.5.3l-4.5 4.5-5.3 1.3c-.3.1-.6.3-.7.6L26.5 49l-5.7 2q-.45.15-.6.6l-1.9 5.7-2.4 2.4c-.2.2-.4.2-.6.2H4.8c-.4.1-.8-.3-.8-.7V48.8c0-.2.1-.4.2-.6L27 25.5q.3-.3.3-.6l1.3-9.7L39.9 4.1l15.2 4 4.8 15.8z" />
    <path d="M39.2 16.5c-1.1 1.1-1.7 2.5-1.7 4.1s.6 3 1.7 4.1 2.6 1.7 4.1 1.7 3-.6 4.1-1.7 1.7-2.5 1.7-4.1-.6-3-1.7-4.1c-2.3-2.2-5.9-2.2-8.2 0m6.8 6.8c-1.5 1.5-3.9 1.5-5.4 0-.7-.7-1.1-1.7-1.1-2.7s.4-2 1.1-2.7 1.7-1.1 2.7-1.1 2 .4 2.7 1.1 1.1 1.7 1.1 2.7-.4 2-1.1 2.7" />
  </svg>
);
export default IconKeyAlt3;
