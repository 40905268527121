import * as React from "react";
const IconLockAltCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M46.2 20.5v-3.8c0-7.6-5.7-14-12.9-14.7-4.1-.4-7.9 1-10.9 3.7-2.9 2.6-4.5 6.4-4.5 10.3v4.5c-5.9.6-10.5 5.6-10.5 11.6V51c0 6.1 5 11 11 11h27.3c6.1 0 11.1-5 11.1-11.1V32c-.1-5.9-4.7-10.8-10.6-11.5M23.7 7.2c2.6-2.3 5.9-3.5 9.4-3.2 6.2.5 11.1 6.1 11.1 12.7v3.7H19.8V16c0-3.3 1.4-6.5 3.9-8.8m31.1 43.7c0 5-4.1 9.1-9.1 9.1H18.3c-5 0-9-4.1-9-9V32.2c0-5.4 4.4-9.7 9.7-9.7h25.9c5.4 0 9.9 4.3 9.9 9.5z" />
    <path d="M32 36.3c-.6 0-1 .4-1 1v10.5c0 .6.4 1 1 1s1-.4 1-1V37.3c0-.6-.4-1-1-1" />
  </svg>
);
export default IconLockAltCopy;
