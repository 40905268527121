import React from "react";
import PropTypes from "prop-types";
import classname from "classnames";

const BringzzAnimatedContainer = ({ isOpen, children, className }) => {
  return (
    <div
      className={classname(
        className,
        `transition-transform duration-300  ${isOpen ? "translate-y-0" : "translate-y-full"}`
      )}
    >
      {children}
    </div>
  );
};

BringzzAnimatedContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default BringzzAnimatedContainer;
