import * as React from "react";
const IconAtom = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.8 24.2c2.3-7.8 1.3-14.3-2.7-18.3C52.6.4 42.5 1 32 6.4 21.5 1 11.4.4 5.9 5.9S1 21.5 6.4 32C1 42.5.4 52.6 5.9 58.1c2.6 2.6 6.2 3.9 10.6 3.9q3.6 0 7.8-1.2c2.6-.7 5.2-1.8 7.8-3.2 5.5 2.8 10.9 4.3 15.5 4.3 4.3 0 7.9-1.2 10.5-3.8 5.5-5.5 4.9-15.6-.5-26.1 1.4-2.6 2.5-5.2 3.2-7.8M47.5 4c3.7 0 6.9 1.1 9.2 3.3 3.5 3.5 4.2 9.3 2.2 16.3-.6 2.1-1.4 4.2-2.4 6.2-2.5-4.3-5.8-8.6-9.8-12.6s-8.3-7.3-12.6-9.8C38.9 5.2 43.5 4 47.5 4m7.9 28c-2.5 4.6-6 9.1-10.1 13.3-4.2 4.2-8.7 7.6-13.3 10.1-4.5-2.5-9-5.9-13.3-10.1-4.2-4.2-7.6-8.8-10.1-13.3 2.5-4.5 5.9-9.1 10.1-13.3S27.5 11.1 32 8.6c4.5 2.5 9.1 5.9 13.3 10.1s7.6 8.8 10.1 13.3M7.3 7.3C9.6 5 12.8 4 16.5 4c4 0 8.6 1.2 13.4 3.6-4.3 2.5-8.6 5.8-12.6 9.8S10 25.7 7.5 30C3 20.7 2.6 12 7.3 7.3m16.4 51.6c-7.1 2-12.9 1.3-16.3-2.2C2.6 52 3 43.3 7.5 34.1c2.5 4.3 5.8 8.6 9.8 12.6s8.3 7.3 12.6 9.8c-2.1 1-4.2 1.8-6.2 2.4m33-2.2c-4.7 4.7-13.4 4.3-22.6-.2 4.4-2.5 8.6-5.9 12.6-9.8 3.9-3.9 7.2-8.2 9.8-12.6 4.5 9.2 4.9 17.9.2 22.6" />
    <path d="M32 25.1c-3.8 0-6.9 3.1-6.9 6.9s3.1 6.9 6.9 6.9 6.9-3.1 6.9-6.9-3.1-6.9-6.9-6.9m0 11.8c-2.7 0-4.9-2.2-4.9-4.9s2.2-4.9 4.9-4.9 4.9 2.2 4.9 4.9-2.2 4.9-4.9 4.9" />
  </svg>
);
export default IconAtom;
