import * as React from "react";
const IconBowtie = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.6 22.8c-.9-.6-2-.8-3-.4l-17.3 5.8c-1.5-3.1-4.6-5.3-8.3-5.3s-6.9 2.2-8.3 5.3L6.3 22.3c-1-.3-2.1-.2-3 .4-.8.7-1.3 1.7-1.3 2.7v13.2c0 1.1.5 2 1.4 2.7.6.4 1.2.6 1.9.6.4 0 .7-.1 1.1-.2l17.3-5.8c1.5 3.1 4.6 5.3 8.3 5.3s6.9-2.2 8.3-5.3l17.3 5.8c.3.1.7.2 1.1.2.7 0 1.4-.2 1.9-.6.9-.6 1.4-1.6 1.4-2.7V25.4c0-1-.5-2-1.4-2.6m-54.9 17c-.4.1-.8.1-1.2-.2-.3-.2-.5-.6-.5-1V25.4c0-.4.2-.8.5-1s.8-.3 1.2-.2L23 30c-.1.6-.2 1.3-.2 2s.1 1.3.2 2zm26.3-.6c-4 0-7.2-3.2-7.2-7.2s3.2-7.2 7.2-7.2 7.2 3.2 7.2 7.2-3.2 7.2-7.2 7.2m28-.6c0 .4-.2.8-.5 1s-.8.3-1.2.2L41 34c.1-.6.2-1.3.2-2s-.1-1.3-.2-2l17.3-5.8c.4-.1.8-.1 1.2.2.3.2.5.6.5 1z" />
  </svg>
);
export default IconBowtie;
