import * as React from "react";
const IconFileAltMedical = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.8 2H13.3c-2.2 0-4 1.8-4 4v52c0 2.2 1.8 4 4 4h37.5c2.2 0 4-1.8 4-4V6c0-2.2-1.8-4-4-4m2 56c0 1.1-.9 2-2 2H13.3c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2h37.5c1.1 0 2 .9 2 2z" />
    <path d="M40.9 23.8c-.9-4-4.7-7-8.9-7.2-4.2.1-8 3.2-8.9 7.2-.6 2.8.3 6 2.6 9 1.5 2 3.2 3.9 4.9 5.6l-1.5 1.5L28 41l-4.6 4.6c-.4.4-.4 1 0 1.4s1 .4 1.4 0c1.5-1.5 3.1-3 4.6-4.6l1.1-1.1c.5-.5 1-1 1.6-1.5l1.6 1.6 1.3 1.3c1.5 1.5 2.9 2.9 4.4 4.3.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4-1.5-1.4-2.9-2.9-4.4-4.3L35 40c-.5-.5-1.1-1-1.6-1.6 1.7-1.7 3.4-3.5 4.9-5.6 2.3-3 3.2-6.2 2.6-9M32 37c-1.6-1.6-3.3-3.4-4.7-5.4-1.1-1.5-2.9-4.4-2.2-7.4.7-3.1 3.7-5.5 6.9-5.6 3.3.1 6.3 2.5 7 5.6.6 3-1.1 5.9-2.2 7.4-1.5 2-3.2 3.8-4.8 5.4" />
  </svg>
);
export default IconFileAltMedical;
