import * as React from "react";
const IconJoomlaOriginal = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M12.2 32.1 11.1 31c-3.7-3.7-4.8-8.9-3.5-13.7-3.7-.8-6.5-4.2-6.5-8.2C1.1 4.6 4.8.9 9.3.9c4.2 0 7.6 3 8.2 6.9 4.5-1.1 9.3.1 12.8 3.7l.4.4-6.1 6.1-.4-.4c-2-2-5.2-2-7.2 0s-2 5.2 0 7.2l13.7 13.7-6.1 6.1-6.5-6.2z" />
    <path d="m19.1 25.4 6.5-6.5 6.1-6.1 1.1-1.1c3.7-3.7 8.9-4.8 13.5-3.5.6-4.1 4.1-7 8.2-7 4.5 0 8.2 3.7 8.2 8.2 0 4.2-3.1 7.6-7.2 8.2 1.4 4.6.1 9.9-3.5 13.4l-.4.4-5.9-6.1.4-.4c2-2 2-5.2 0-7.2s-5.2-2-7.2 0l-1 1.3-6.1 6-6.5 6.5z" />
    <path d="M46.6 56.2c-4.8 1.4-10 .3-13.7-3.4l-.4-.4 6.1-6.1.4.4c2 2 5.2 2 7.2 0s2-5.2 0-7.2l-1.3-1.1-6.1-6.2-6.5-6.5 6.1-6.1 13.8 13.8c3.5 3.5 4.8 8.5 3.7 13 4.1.6 7 4.1 7 8.2 0 4.5-3.7 8.2-8.2 8.2-3.9.1-7.3-2.9-8.1-6.6" />
    <path d="m44.3 39-6.5 6.5-6.1 6.1-1.1 1.1c-3.5 3.5-8.5 4.8-13 3.7-1 3.8-4.2 6.6-8.2 6.6-4.5 0-8.2-3.7-8.2-8.2 0-3.8 2.7-7.2 6.3-8-1.1-4.7 0-9.6 3.5-13.1l.4-.4 6.1 6.1-.4.4c-2 2-2 5.2 0 7.2s5.2 2 7.2 0l1.1-1.1 6.1-6.1 6.5-6.5z" />
  </svg>
);
export default IconJoomlaOriginal;
