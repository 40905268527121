import * as React from "react";
const IconCloudSyncAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.5 23.2q-2.4 0-4.5.9c.1-.8.2-1.6.2-2.5C46.2 14 39.8 7.7 32 7.7s-14.2 6.2-14.2 13.9c0 .8.1 1.7.2 2.5q-2.1-.9-4.5-.9C7.2 23.2 2 28.3 2 34.6s5.2 11.3 11.5 11.3h2.6c.6 0 1-.4 1-1s-.4-1-1-1h-2.6c-5.3 0-9.5-4.2-9.5-9.3s4.3-9.3 9.5-9.3c2 0 3.9.6 5.5 1.7.4.3.8.2 1.2 0 .3-.3.5-.7.3-1.1-.5-1.3-.8-2.7-.8-4.1 0-6.6 5.5-11.9 12.2-11.9s12.2 5.3 12.2 11.9c0 1.4-.3 2.8-.8 4.1-.2.4 0 .9.3 1.1.3.3.8.3 1.2 0 1.6-1.1 3.5-1.7 5.5-1.7 5.3 0 9.5 4.2 9.5 9.3s-4.3 9.3-9.5 9.3h-2.6c-.6 0-1 .4-1 1s.4 1 1 1h2.6c6.4 0 11.5-5.1 11.5-11.3s-5-11.4-11.3-11.4" />
    <path d="M39.9 33.9c-.6 0-1 .4-1 1V39c-1.6-2.4-4.3-3.8-7.2-3.8-3.3 0-6.3 1.8-7.8 4.7-.3.5-.1 1.1.4 1.4.2.1.3.1.5.1.4 0 .7-.2.9-.5 1.2-2.2 3.5-3.6 6.1-3.6 2.4 0 4.5 1.1 5.7 3.1h-2.8c-.6 0-1 .4-1 1s.4 1 1 1h4.8c.8 0 1.4-.7 1.4-1.4v-6c0-.6-.4-1.1-1-1.1M38 50.6c-1.2 1.8-3.3 2.9-5.6 2.9-2 0-3.9-.9-5.2-2.4h3.2c.6 0 1-.4 1-1s-.4-1-1-1h-4.8c-.8 0-1.4.7-1.4 1.4v4.7c0 .6.4 1 1 1s1-.4 1-1v-2.4c1.6 1.7 3.9 2.7 6.3 2.7 2.9 0 5.7-1.4 7.3-3.8.3-.5.2-1.1-.3-1.4s-1.2-.1-1.5.3" />
  </svg>
);
export default IconCloudSyncAlt;
