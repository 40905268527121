import * as React from "react";
const IconVirusAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M49.8 37.4h-5.7V26.7h5.7c.6 0 1-.4 1-1s-.4-1-1-1h-5.7v-4.4c0-2.3-.6-4.4-1.7-6.3h5.4c.6 0 1-.4 1-1s-.4-1-1-1h-6.9c-1.3-1.4-2.9-2.5-4.7-3.2l.1-.1 2.2-5.4c.2-.5 0-1.1-.5-1.3s-1.1 0-1.3.5L34.4 8c-.1.1-.1.2-.1.4-.8-.1-1.5-.2-2.3-.2s-1.6.1-2.3.2c0-.1 0-.2-.1-.4l-2.2-5.4c-.2-.5-.8-.8-1.3-.5-.5.2-.8.8-.5 1.3l2.2 5.4.1.1c-1.8.7-3.4 1.8-4.7 3.2h-6.9c-.6 0-1 .4-1 1s.4 1 1 1h5.4c-1.1 1.8-1.7 4-1.7 6.3v4.4h-5.7c-.6 0-1 .4-1 1s.4 1 1 1H20v10.7h-5.7c-.6 0-1 .4-1 1s.4 1 1 1H20v4.3c0 2.3.7 4.5 1.8 6.4h-5.5c-.6 0-1 .4-1 1s.4 1 1 1H23c.1 0 .2 0 .3-.1 1.3 1.4 2.9 2.4 4.7 3.1l-.1.1-2.2 5.4c-.2.5 0 1.1.5 1.3.1.1.3.1.4.1.4 0 .8-.2.9-.6l2.2-5.4c.1-.1.1-.2.1-.4.8.1 1.5.2 2.3.2s1.6-.1 2.3-.2c0 .1 0 .2.1.4l2.2 5.4q.3.6.9.6c.1 0 .3 0 .4-.1.5-.2.8-.8.5-1.3l-2.2-5.4-.1-.1c1.8-.7 3.4-1.7 4.7-3.1.1 0 .2.1.3.1h6.7c.6 0 1-.4 1-1s-.4-1-1-1h-5.5c1.1-1.9 1.8-4 1.8-6.4v-4.3h5.7c.6 0 1-.4 1-1s-.5-1.1-1.1-1.1M32 53.9c-5.6 0-10.2-4.6-10.2-10.2V20.3c0-5.6 4.6-10.2 10.2-10.2s10.2 4.6 10.2 10.2v23.4c0 5.6-4.6 10.2-10.2 10.2" />
  </svg>
);
export default IconVirusAlt;
