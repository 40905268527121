import * as React from "react";
const IconOpthalmology = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      d="M27.5 24.4a3.5 3.5 0 1 1-1.224-2.658 1.05 1.05 0 1 0 .382.382c.525.612.842 1.407.842 2.276"
    />
    <path
      
      fillRule="evenodd"
      d="M24.037 16C30.3 16 38 24.4 38 24.4s-7.7 8.4-13.963 8.4S10 24.4 10 24.4 17.774 16 24.037 16m-11.202 8.4a41 41 0 0 1 3.055-2.615q.62-.479 1.276-.936A7.7 7.7 0 0 0 16.3 24.4c0 1.28.313 2.488.866 3.55a32 32 0 0 1-1.276-.934 41 41 0 0 1-3.055-2.616m18.865 0c0-1.3-.322-2.526-.892-3.6.457.317.903.647 1.334.98a40 40 0 0 1 3.036 2.62 40 40 0 0 1-3.036 2.62c-.43.333-.877.663-1.334.98.57-1.074.892-2.3.892-3.6M24 30.1a5.7 5.7 0 1 0 0-11.4 5.7 5.7 0 0 0 0 11.4"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M6 9a3 3 0 0 1 3-3h30a3 3 0 0 1 3 3v30a3 3 0 0 1-3 3H9a3 3 0 0 1-3-3zm2 0a1 1 0 0 1 1-1h30a1 1 0 0 1 1 1v30a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconOpthalmology;
