import * as React from "react";
const IconScythe = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m57.9 50.2-9.3-9.3c-2.2-2.2-5.7-2.2-7.9 0l-.7.7-7.7-7.7c-.2-.2-.3-.4-.3-.7s.1-.5.3-.7l4-4c6.1-6.1 6.1-16.1-.1-22.3l-.2-.3C33.2 3 29.3 1.4 25.2 1.4S17.3 3 14.4 5.9l-9.7 9.7c-.4.4-.4 1 0 1.4s1 .4 1.4 0l9.7-9.7c2.5-2.5 5.8-3.9 9.4-3.9s6.9 1.4 9.4 3.9l.3.3c5.4 5.4 5.4 14.1.1 19.5l-4 4q-.9.9-.9 2.1c0 .8.3 1.6.9 2.1l7.7 7.7-.7.5c-2.2 2.2-2.2 5.7 0 7.9l9.3 9.3c1.1 1.1 2.5 1.6 3.9 1.6s2.9-.5 3.9-1.6l2.7-2.7c1.1-1.1 1.6-2.5 1.6-3.9.2-1.5-.4-2.9-1.5-3.9m-1.4 6.5-2.7 2.7c-1.4 1.4-3.7 1.4-5.1 0L39.5 50c-.7-.7-1-1.6-1-2.5 0-1 .4-1.9 1-2.5l2.7-2.7c.7-.7 1.6-1 2.5-1s1.8.3 2.5 1l9.3 9.3c.7.7 1 1.6 1 2.5.1 1-.3 1.9-1 2.6" />
  </svg>
);
export default IconScythe;
