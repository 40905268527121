import * as React from "react";
const IconChildCognition = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      d="m14.782 20.411 6.115-.957-3.97-4.396zM20.275 15.837l1.81-3.364 3.428 1.777-1.81 3.364z"
    />
    <path
      
      fillRule="evenodd"
      d="M28.144 20.544a3.63 3.63 0 0 1-2.076.654c-.56 0-.724-.13-1.035-.378-.1-.08-.216-.172-.365-.276-.171.186-.25.355-.321.505-.106.226-.192.41-.543.547-.624.314-1.149.8-1.516 1.402a3.85 3.85 0 0 0-.56 2.007V29H16.85a5.84 5.84 0 0 0-.838-2.406 5.7 5.7 0 0 0-1.774-1.802 3.7 3.7 0 0 1-1.551-1.274 3.83 3.83 0 0 1-.681-1.908A3.8 3.8 0 0 1 11 19.026a3.76 3.76 0 0 1 .757-2.29 3.5 3.5 0 0 1-.13-.999 3.8 3.8 0 0 1 .722-2.243l1.89-1.365a3.75 3.75 0 0 1 1.36-1.627 3.64 3.64 0 0 1 2.008-.61q.443.002.875.106A3.66 3.66 0 0 1 20.695 9l4.972.285c.886.223 1.66.771 2.176 1.539h.262l2.32.828a3.78 3.78 0 0 1 1.296 2.128 3.82 3.82 0 0 1 1.85 1.758c.401.784.526 1.686.353 2.553a3.88 3.88 0 0 1-1.302 2.21l-2.376.883a3.64 3.64 0 0 1-2.102-.64m1.91-.815a2.5 2.5 0 0 1-1.145-.359l-.771-.527c-.222.171-.741.548-1.039.683-.371.17-1.19.22-1.19.22s-.614-.513-1.405-1.025c-.116.126-.423.455-.719.771a2.9 2.9 0 0 0-.526.794c-.264.134-.897.517-1.318.971-.527.568-1.49 1.767-1.49 3.72v2.578h-2.588c-.25-.892-.987-2.637-2.724-3.837l.004-.003a7 7 0 0 0-.276-.186l-.08-.052-.087-.037a2.4 2.4 0 0 1-1.012-.825 2.27 2.27 0 0 1-.41-1.133l-.04-.545-.35-.378a2.26 2.26 0 0 1-.61-1.535v-.012a2.2 2.2 0 0 1 .45-1.34l.463-.612-.216-.766a1.9 1.9 0 0 1-.07-.538v-.026c-.001-.397.106-.8.324-1.161l1.997-1.443.158-.362a2.36 2.36 0 0 1 .86-1.02 2.48 2.48 0 0 1 1.972-.336l.61.147.477-.454c.376-.356.87-.594 1.415-.656l4.767.273c.569.168 1.038.524 1.341.977l.384.57h.698l1.913.683c.345.332.567.745.66 1.172l.157.729.618.273c.545.242.963.654 1.21 1.135.243.477.312 1.007.212 1.512-.09.45-.316.885-.673 1.236z"
      clipRule="evenodd"
    />
    <path
      
      d="M33.978 23.963a1.5 1.5 0 1 1-3 .003 1.5 1.5 0 0 1 3-.003M16.985 32.477l4-.003-.002-2-4 .003zM20.988 36.474l-4 .003-.001-2 4-.003zM16.992 40.477l4-.003-.002-2-4 .003z"
    />
    <path
      
      fillRule="evenodd"
      d="M26.943 38.804c-1.198-.144-2-.35-2-.35l.003 3.432s-.834 2.737-5.573 2.74c-2.23.185-6.05-1.318-7.065-3.44l-.006-8.494c-3.57-2.747-6.324-8.448-6.328-13.25A14.9 14.9 0 0 1 10.456 8.8a15.48 15.48 0 0 1 10.836-4.415 21.6 21.6 0 0 1 8.99 1.934 14.1 14.1 0 0 1 5.647 4.236 12.15 12.15 0 0 1 2.166 6.618c.012.534.006 1.087 0 1.65-.024 2.037-.05 4.208.815 6.05 0 0 2.538 2.35 2.205 4.239s-3.636 2.41-3.636 2.41c.007 7.593-6.995 7.71-10.536 7.282M37.1 25.722l.165.354.283.262h.001l.009.009.021.02.034.034a8.603 8.603 0 0 1 .902 1.053c.24.332.426.653.536.931.07.178.088.288.093.342a1 1 0 0 1-.06.058c-.136.12-.368.262-.69.398a6 6 0 0 1-1.159.35l-.04.008-.025.005-.01.002-1.68.266v1.71c.002 2.096-.625 3.24-1.339 3.925-.766.734-1.88 1.174-3.218 1.371-1.326.196-2.7.126-3.776-.006a17 17 0 0 1-1.613-.276l-.08-.018-.011-.004h-.002l-2.5-.644.005 5.536a2 2 0 0 1-.308.327c-.394.337-1.309.89-3.265.892h-.082l-.08.006c-.675.056-1.84-.157-2.998-.683-.947-.43-1.579-.926-1.904-1.328l-.007-8.916-.78-.6c-1.477-1.136-2.88-2.97-3.917-5.133-1.033-2.156-1.628-4.488-1.63-6.532a12.9 12.9 0 0 1 3.884-9.214 13.48 13.48 0 0 1 9.435-3.842h.002a19.6 19.6 0 0 1 8.16 1.755l.032.015.032.013a12.1 12.1 0 0 1 4.804 3.58 10.15 10.15 0 0 1 1.773 5.474c.011.495.005 1.008-.001 1.574l-.003.267c-.008.655-.012 1.369.02 2.09.064 1.432.272 3.046.987 4.57"
      clipRule="evenodd"
    />
  </svg>
);
export default IconChildCognition;
