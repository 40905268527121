import * as React from "react";
const IconContest = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.4 8.3h-8.8V2.5c0-.6-.4-1-1-1h-25c-.6 0-1 .4-1 1v5.8h-9c-.6 0-1 .4-1 1v6.3c0 7.8 5.4 14.5 12.9 16.1 2.1 5.2 5.4 10 9.5 13.9v12.9c0 .6.4 1 1 1s1-.4 1-1V45.6c4.3-3.9 7.5-8.7 9.7-14 7.4-1.7 12.8-8.5 12.8-16.2V9.3c-.1-.6-.5-1-1.1-1m-43.8 7.3v-5.3h8v6.5c0 4.3.7 8.6 2.1 12.6-6-1.8-10.1-7.4-10.1-13.8M32 43.8c-7.3-6.9-11.5-16.7-11.5-27V3.5h23.1v13.2c-.1 10.5-4.2 20.1-11.6 27.1m21.4-28.4c0 6.3-4.1 11.9-9.9 13.9 1.3-4 2-8.2 2.1-12.5v-6.5h7.8z" />
    <path d="M33.1 11.7c-.4-.2-.8-.1-1.1.2l-3.3 3.3c-.4.4-.4 1 0 1.4s1 .4 1.4 0l1.6-1.6v7.5c0 .6.4 1 1 1s1-.4 1-1v-9.9c0-.4-.3-.8-.6-.9M44.5 59.5h-25c-.6 0-1 .4-1 1s.4 1 1 1h25.1c.6 0 1-.4 1-1s-.5-1-1.1-1" />
  </svg>
);
export default IconContest;
