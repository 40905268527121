import * as React from "react";
const IconDollarEye = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.9 31.5C55.4 20.5 44 13.6 32 13.6S8.6 20.5 2.1 31.5c-.2.3-.2.7 0 1C8.6 43.5 20 50.4 32 50.4s23.4-6.8 29.9-17.9c.1-.3.1-.7 0-1M32 48.4c-11.1 0-21.7-6.3-27.8-16.4C10.3 21.9 20.9 15.6 32 15.6S53.7 21.9 59.8 32C53.7 42.1 43.1 48.4 32 48.4" />
    <path d="M30.5 26.1H37c.6 0 1-.4 1-1s-.4-1-1-1h-3v-2c0-.6-.4-1-1-1s-1 .4-1 1v2h-1.5c-2.5 0-4.5 2-4.5 4.5s2 4.5 4.5 4.5h3c1.4 0 2.5 1.1 2.5 2.5s-1.1 2.5-2.5 2.5H27c-.6 0-1 .4-1 1s.4 1 1 1h5v2c0 .6.4 1 1 1s1-.4 1-1v-2c2.2-.3 4-2.1 4-4.4 0-2.5-2-4.5-4.5-4.5h-3c-1.4 0-2.5-1.1-2.5-2.5s1.1-2.6 2.5-2.6" />
  </svg>
);
export default IconDollarEye;
