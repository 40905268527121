import React, { useEffect, useState } from 'react';
import {
    BringzzPageContainer,
    BringzzIcon,
    BringzzText,
    BringzzInput,
    BringzzButton,
    BringzzCheckbox,
    BringzzBottomDrawer,
    BringzzRadioList,
    BringzzRadio,
    BringzzImage,
    BringzzDate,
    Mode
} from '@bringzz/components';
import { useNavigation } from 'context/NavigationContext';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import usePageState from 'hooks/usePageState';
import { formatDateToDDMMYYYY, parseToMySQLDateTime } from 'utils';
import useRequest from 'hooks/useRequest';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

let state = {
    checked: <div></div>,
    unChecked: <div></div>
};

let products = [
    {
        productId: 1,
        name: 'Product Name 1',
        manufacturer: 'Manufacturer',
        type: 'Pills',
        quantity: '64 St',
        dosage: '200mg',
        imgUrl: '/product.png',
        price: 9
    },
    {
        productId: 2,
        name: 'Product Name 2',
        manufacturer: 'Manufacturer',
        type: 'Pills',
        quantity: '12 St',
        dosage: '200mg',
        imgUrl: '/product.png',
        price: 8
    },
    {
        productId: 3,
        name: 'Product Name 3',
        manufacturer: 'Manufacturer',
        type: 'Pills',
        quantity: '12 St',
        dosage: '200mg',
        imgUrl: '/product.png',
        price: 8
    },
    {
        productId: 4,
        name: 'Product Name 4',
        manufacturer: 'Manufacturer',
        type: 'Pills',
        quantity: '12 St',
        dosage: '200mg',
        imgUrl: '/product.png',
        price: 8
    }
];

const AddPostPage = () => {
    let query = useQuery();
    const { backPress } = useNavigation();
    const [isChecked1, setChecked1] = useState(false);
    const [isChecked2, setChecked2] = useState(false);
    const [headline, setHeadline] = useState(null);
    const [description, setDescription] = useState(null);
    const [addPhotoDrawer, setAddPhotoDrawer] = useState(false);
    const [photo, setPhoto] = useState(null); // State to manage the photo
    const { navigate } = useNavigation();
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredProducts, setFilteredProducts] = useState(products);
    const [productDrawer, setAddProductDrawer] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState([]); // State for multiple selected products
    const [viewingProduct, setViewingProduct] = useState(null); // State for the product currently being viewed
    const [validFrom, setValidFrom] = useState(formatDateToDDMMYYYY(new Date()))
    const [validUntil, setValidUntil] = useState(null)
    const { sendRequest, loading } = useRequest();

    useEffect(() => {
        const lowerCaseQuery = searchQuery.toLowerCase();
        setFilteredProducts(
            products.filter(product =>
                product.name.toLowerCase().includes(lowerCaseQuery) ||
                product.manufacturer.toLowerCase().includes(lowerCaseQuery)
            )
        );
    }, [searchQuery]);

    const handlePickerClick = async choice => {
        try {
            let base64Image = '';

            if (choice === 'camera') {
                // Handling camera input
                const stream = await navigator.mediaDevices.getUserMedia({
                    video: true
                });
                const video = document.createElement('video');
                video.srcObject = stream;
                video.play();

                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');
                video.onloadedmetadata = () => {
                    canvas.width = video.videoWidth;
                    canvas.height = video.videoHeight;
                    context.drawImage(video, 0, 0, canvas.width, canvas.height);
                    base64Image = canvas.toDataURL('image/jpeg');
                    // Stop the video stream
                    stream.getTracks().forEach(track => track.stop());
                    setPhoto(base64Image);
                    console.log(base64Image);
                };
            } else if (choice === 'photos') {
                // Handling gallery input
                const input = document.createElement('input');
                input.type = 'file';
                input.accept = 'image/*';
                input.onchange = async event => {
                    const file = event.target.files[0];
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        base64Image = reader.result;
                        setPhoto(base64Image);
                        console.log(base64Image);
                    };
                    reader.readAsDataURL(file);
                };
                input.click();
            }
        } catch (error) {
            console.error('Error handling picker choice:', error);
        }
    };

    const handleRemovePhoto = () => {
        setPhoto(null); // Only reset the photo state
    };

    const handleProductSelect = product => {
        setViewingProduct(product); // Set the product to be viewed in the drawer
    };

    const handleCancelSelection = () => {
        setViewingProduct(null); // Reset the viewing product
        setAddProductDrawer(false);
    };

    const handleApplyProduct = () => {
        if (viewingProduct) {
            setSelectedProducts([...selectedProducts, viewingProduct]); // Add the viewing product to the selected products
        }
        setViewingProduct(null); // Reset the viewing product
        setAddProductDrawer(false);
    };

    const handleRemoveProduct = (productId) => {
        setSelectedProducts(selectedProducts.filter(product => product.id !== productId)); // Remove the selected product by its ID
    };

    const handleAddDiscount = (productId) => {
        const updatedProducts = selectedProducts.map(product =>
            product.id === productId
                ? { ...product, discount: true }
                : product
        );

        setSelectedProducts(updatedProducts);
    }

    const handleRemoveDiscount = (productId) => {
        const updatedProducts = selectedProducts.map(product =>
            product.id === productId
                ? { ...product, discount: false }
                : product
        );

        setSelectedProducts(updatedProducts);
    }

    const handleDiscountSelection = (productId, type) => { // EUR, %
        console.log(productId);
        console.log(type);
        const updatedProducts = selectedProducts.map(product =>
            product.id === productId
                ? { ...product, discountType: type }
                : product
        );
        console.log(updatedProducts);

        setSelectedProducts(updatedProducts);
    }

    const formData = usePageState()

    const populateForm = (formData) => {
        setHeadline(formData.headline || '')
        setPhoto(formData.photo || '')
        setDescription(formData.description || '')
        setSelectedProducts(formData.products || [])
        setChecked2(formData.likesHidden || false)
        setChecked1(formData.validUntil && true || null)
        setValidFrom(formData.validFrom || null)
        setValidUntil(formData.validUntil || null)
    }

    useEffect(() => {
        if (formData) {
            populateForm(formData)
        }
    }, [formData]);

    const onSubmit = () => {
        navigate(`/posts/preview/product-post`, {
            state: {
                headline,
                description,
                products: selectedProducts,
                photo,
                validFrom,
                validUntil,
                likesHidden: isChecked2,
                commentsHidden: isChecked2
            }
        })
    }

    const onSave = async () => {
        const data = {
            "type": 3,
            "headline": headline,
            "images": [
                {
                    "content": photo
                }
            ],
            "description": description,
            "release": "",
            "products": selectedProducts,
            "likesHidden": isChecked2,
            "commentsHidden": isChecked2,
        }

        if (validFrom) {
            data["validFrom"] = parseToMySQLDateTime(validFrom)
        }

        if (validUntil) {
            data["validUntil"] = parseToMySQLDateTime(validUntil)
        }

        await sendRequest({
            method: 'POST',
            url: `/module/post/createPost`,
            data
        }).then((response) => {
            if (response.status === 'success')
                navigate(`/posts/saved/product-post`)
        }).catch((error) => {
            console.log(error);
        })
    }

    return ((
        <BringzzPageContainer
            header={{
                left: (
                    <BringzzIcon
                        size="22"
                        icon="IconChevronLeft"
                        folder="LineIcons"
                        className="cursor-pointer"
                        onClick={backPress}
                    />
                ),
                title: <BringzzText tag="h2">Product Post</BringzzText>
            }}
            footer={
                <div className="pb-28 px-6 flex flex-row space-x-4">
                    <BringzzButton
                        disabled={!headline || !description || !photo || !selectedProducts.length != 0 || loading}
                        size="md"
                        onClick={onSave}
                        className="border w-full border-black justify-center"
                    >
                        Save
                    </BringzzButton>
                    <BringzzButton
                        onClick={onSubmit}
                        size="md"
                        disabled={!headline || !description || !photo || !selectedProducts.length != 0}
                        className="bg-magic-lilac w-full flex justify-center text-midnight-blue"
                    >
                        Share
                    </BringzzButton>
                </div>
            }
        >
            <div className="p-4">
                <div className="space-y-4">
                    <BringzzInput
                        label="Headline (max. XXX char)"
                        tooltip="something"
                        placeholder='Your posting headline'
                        defaultValue={headline}
                        required={true}
                        onChange={(e, valid, value) => {
                            if (valid)
                                setHeadline(value)
                        }}
                    />
                    <BringzzInput
                        label="Description (max. XXX char)"
                        type="textarea"
                        placeholder="Your posting description"
                        tooltip="something"
                        defaultRows={5}
                        defaultValue={description}
                        required={true}
                        onChange={(e, valid, value) => {
                            if (valid)
                                setDescription(value)
                        }}
                    />
                </div>
                <div className="flex flex-col space-y-4 mt-4">
                    <div className="w-full">
                        <label className="block text-sm font-medium text-gray-700 mb-1">Post Image</label>
                        <div
                            className={`relative w-full ${photo ? 'h-48' : 'h-12'
                                } border border-gray-300 rounded-md flex items-center justify-center bg-white transition-all duration-300 cursor-pointer`}
                            onClick={() => !photo && setAddPhotoDrawer(true)}
                        >
                            {photo ? (
                                <div className="relative w-full h-full">
                                    <img src={photo} alt="Post" className="w-full h-full object-cover rounded-md" />
                                    <BringzzIcon
                                        size="24"
                                        icon="IconTrashCan"
                                        folder="LineIcons"
                                        className="absolute text-white top-2 right-2 cursor-pointer"
                                        onClick={handleRemovePhoto}
                                    />
                                </div>
                            ) : (
                                <div
                                    className="flex items-center justify-between w-full px-3"
                                >
                                    <BringzzText tag="h4" className="text-gray-500">Add image</BringzzText>
                                    <BringzzIcon
                                        size="24"
                                        icon="IconCirclePlus"
                                        folder="LineIcons"
                                        className="cursor-pointer text-gray-500"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="w-full">
                        <label className="block text-sm font-medium text-gray-700 mb-1">Products</label>
                        {selectedProducts.map(product => (
                            <div key={product.id} className='border border-gray-300 rounded-md mb-2'>
                                <div className="relative w-full flex items-center justify-between bg-white p-3">
                                    <div className="flex items-center">
                                        <div className="w-12 h-12 bg-gray-100 rounded-md mr-4">
                                            <BringzzImage src={product.imgUrl} className="w-full h-full object-cover" />
                                        </div>
                                        <div>
                                            <BringzzText tag="h3" className="font-semibold">{product.name}</BringzzText>
                                            <BringzzText tag="h5" className="text-gray-500">
                                                {product.manufacturer} | {product.type} | {product.quantity} | {product.dosage}
                                            </BringzzText>
                                            {!product.discount && <BringzzText
                                                tag="h4"
                                                className="text-magic-lilac block mt-1 cursor-pointer"
                                                onClick={() => handleAddDiscount(product.id)}
                                            >Add Discount</BringzzText>}
                                        </div>
                                    </div>
                                    <BringzzIcon
                                        size="24"
                                        icon="IconTrashCan"
                                        folder="LineIcons"
                                        className="cursor-pointer text-gray-500"
                                        onClick={() => handleRemoveProduct(product.id)}
                                    />

                                </div>
                                {product.discount && (
                                    <div className='flex justify-around items-center bg-slate-200 py-2 px-3'>
                                        <div></div>
                                        <div className='flex items-stretch h-full border border-gray-300 rounded-md'>
                                            <div className="flex-1 flex h-full">
                                                <BringzzInput className='bg-transparent text-center h-8 border-none rounded-none rounded-l-md' placeholder='00,00' />
                                            </div>
                                            <div
                                                className={classNames(
                                                    'flex-1 cursor-pointer flex justify-center items-center h-8 border-l border-l-gray-300',
                                                    product.discountType === "EUR" && "bg-magic-lilac"
                                                )}
                                                onClick={() => handleDiscountSelection(product.id, "EUR")}
                                            >
                                                <BringzzText tag='h4'>EUR</BringzzText>
                                            </div>
                                            <div
                                                className={classNames(
                                                    'flex-1 cursor-pointer flex justify-center items-center h-8 border-l border-l-gray-300',
                                                    product.discountType === "%" && "bg-magic-lilac rounded-r-md"
                                                )}
                                                onClick={() => handleDiscountSelection(product.id, "%")}
                                            >
                                                <BringzzText tag='h4'>%</BringzzText>
                                            </div>
                                        </div>
                                        <div>
                                            <BringzzIcon
                                                size="24"
                                                icon="IconCirclePlus"
                                                folder="LineIcons"
                                                className="cursor-pointer text-gray-500 rotate-45"
                                                onClick={() => handleRemoveDiscount(product.id)}
                                            />
                                        </div>
                                    </div>
                                )}

                            </div>
                        ))}
                        <div
                            className="relative w-full h-12 border border-gray-300 rounded-md flex items-center justify-center bg-white cursor-pointer"
                            onClick={() => setAddProductDrawer(true)}
                        >
                            <div className="flex items-center justify-between w-full px-3">
                                <BringzzText tag="h4" className="text-gray-500">Add product</BringzzText>
                                <BringzzIcon
                                    size="24"
                                    icon="IconCirclePlus"
                                    folder="LineIcons"
                                    className="cursor-pointer text-gray-500"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="space-y-2 pl-1 mt-4">
                    <BringzzCheckbox
                        id="example-checkbox1"
                        label="Custom post validity"
                        checked={isChecked1}
                        onChange={setChecked1}
                    />
                    {isChecked1 &&
                        <div className='flex space-x-2'>
                            <div>
                                <BringzzText tag='h4' className='font-normal'>Valid from</BringzzText>
                                <BringzzDate
                                    placeholder={validFrom}
                                    defaultValue={new Date()}
                                    format="dd/MM/yyyy"
                                    onChange={(newDate) => setValidFrom(newDate)}
                                    mode={Mode.DAY}
                                    minDate={new Date()}
                                ></BringzzDate>
                            </div>
                            <div>
                                <BringzzText tag='h4' className='font-normal'>Valid until</BringzzText>
                                <BringzzDate
                                    placeholder={validUntil}
                                    format="dd/MM/yyyy"
                                    onChange={(newDate) => setValidUntil(newDate)}
                                    mode={Mode.DAY}
                                    minDate={new Date()}
                                ></BringzzDate>
                            </div>
                        </div>}
                    <BringzzCheckbox
                        id="example-checkbox2"
                        label="Hide likes & comments"
                        checked={isChecked2}
                        onChange={setChecked2}
                    />
                </div>
            </div>
            <BringzzBottomDrawer
                isOpen={addPhotoDrawer}
                title={'Upload product photo'}
                close={() => setAddPhotoDrawer(false)}
            >
                <div className="px-4 py-6">
                    <BringzzRadioList
                        state={state}
                        className="rounded-xl w-full text-center"
                        onChange={choice => {
                            handlePickerClick(choice);
                            setAddPhotoDrawer(false);
                        }}
                    >
                        <BringzzRadio
                            value="camera"
                            className="p-2 py-4 hover:bg-slate- border-1 border-t border-gray-400 flex"
                        >
                            <div>Camera</div>
                        </BringzzRadio>
                        <BringzzRadio
                            value="photos"
                            className="p-2 py-4 hover:bg-slate- border-1 border-t border-gray-400"
                        >
                            <div>Photos</div>
                        </BringzzRadio>
                    </BringzzRadioList>
                </div>
            </BringzzBottomDrawer>
            <BringzzBottomDrawer
                isOpen={productDrawer}
                title={viewingProduct ? 'Product Details' : 'Find Product'}
                close={() => setAddProductDrawer(false)}
            >
                <div className="px-4 py-6">
                    {viewingProduct ? (
                        <div className="space-y-4">
                            <div className="flex items-center border border-gray-300 rounded-md p-3 space-x-4">
                                <div className="w-16 h-16 bg-gray-100 rounded-md">
                                    <BringzzImage src={viewingProduct.imgUrl} className="w-full h-full object-cover" />
                                </div>
                                <div>
                                    <BringzzText tag="h3" className="font-semibold">{viewingProduct.name}</BringzzText>
                                    <BringzzText tag="h5" className="text-gray-500">{viewingProduct.manufacturer}</BringzzText>
                                </div>
                            </div>
                            <div className="flex justify-center py-2 items-center space-x-4">
                                <div className="flex w-full border border-gray-300 p-3 space-y-1 rounded-md flex-col items-center">
                                    <div className="w-12 h-12 bg-gray-100 rounded-full"></div>
                                    <BringzzText tag="h4">{viewingProduct.type}</BringzzText>
                                    <BringzzText tag="h5" className="text-blue-500">Change</BringzzText>
                                </div>
                                <div className="flex w-full border border-gray-300 p-3 space-y-1 rounded-md flex-col items-center">
                                    <div className="w-12 h-12 bg-gray-100 rounded-full"></div>
                                    <BringzzText tag="h4">{viewingProduct.dosage}</BringzzText>
                                    <BringzzText tag="h5" className="text-blue-500">Change</BringzzText>
                                </div>
                                <div className="flex w-full border border-gray-300 p-3 space-y-1 rounded-md flex-col items-center">
                                    <div className="w-12 h-12 bg-gray-100 rounded-full"></div>
                                    <BringzzText tag="h4">{viewingProduct.quantity}</BringzzText>
                                    <BringzzText tag="h5" className="text-blue-500">Change</BringzzText>
                                </div>
                            </div>
                            <div className="flex justify-around space-x-4">
                                <BringzzButton
                                    size="md"
                                    className="border w-full border-black justify-center"
                                    onClick={handleCancelSelection}
                                >
                                    Cancel
                                </BringzzButton>
                                <BringzzButton
                                    size="md"
                                    className="bg-magic-lilac w-full flex justify-center text-midnight-blue"
                                    onClick={handleApplyProduct}
                                >
                                    Apply
                                </BringzzButton>
                            </div>
                        </div>
                    ) : (
                        <>
                            <BringzzInput
                                icon={{
                                    left: (
                                        <BringzzIcon
                                            icon="IconSearchAlt"
                                            folder="LineIcons"
                                            className="h-6 w-6"
                                            size="24"
                                        />
                                    )
                                }}
                                placeholder="Search here..."
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            {filteredProducts &&
                                filteredProducts.length > 0 &&
                                filteredProducts.map((product, index) => (
                                    <div
                                        key={product.id || index}
                                        className="py-4 px-6 flex items-center w-full border-b border-gray-200 cursor-pointer"
                                        onClick={() => handleProductSelect(product)}
                                    >
                                        <div className="flex items-center justify-center w-12 h-12 bg-gray-100 rounded-md mr-4">
                                            <BringzzImage src={product.imgUrl} className="w-full h-full object-cover" />
                                        </div>
                                        <div className="flex flex-col">
                                            <BringzzText tag="h4" className="text-base font-semibold text-gray-800">
                                                {product.name || 'Product Name'}
                                            </BringzzText>
                                            <BringzzText tag="h5" className="text-sm text-gray-500">
                                                {product.manufacturer || 'Manufacturer'} / {product.amount}
                                            </BringzzText>
                                        </div>
                                    </div>
                                ))}
                        </>
                    )}
                </div>
            </BringzzBottomDrawer>
        </BringzzPageContainer>
    )
    );
};

export default AddPostPage;
