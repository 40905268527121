import * as React from "react";
const IconFluid = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.7 34.8c-4.1-.6-7.6.4-9.8 2.8-1.7 1.8-3.4 5.1-2.2 11 1.2 6.1-1.2 8.8-3.5 9.9-3 1.5-9.5 2.5-13.8-.3-2.6-1.7-3.4-4.3-3.6-5.7h.9c3.1 0 5.6-2.5 5.6-5.6v-2.2h.1c4.1 0 7.4-3.3 7.4-7.4V17.1c0-4.1-3.3-7.4-7.4-7.4h-.6v-1a6.7 6.7 0 0 0-13.4 0v1h-.6c-4.1 0-7.4 3.3-7.4 7.4v20.3c0 4.1 3.3 7.4 7.3 7.4V47c0 3.1 2.5 5.6 5.6 5.6h.5c.2 1.7 1.1 5.2 4.6 7.4 2.4 1.5 5.2 2.1 7.9 2.1 3.1 0 6-.8 7.9-1.7 2.3-1.2 6.1-4.4 4.5-12.1-.8-4-.2-7.3 1.7-9.3 1.8-1.9 4.6-2.6 8.1-2.2.5.1 1.1-.3 1.1-.9.1-.6-.3-1.1-.9-1.1M17.4 8.7c0-2.6 2.1-4.7 4.7-4.7s4.7 2.1 4.7 4.7v1h-9.4zm-8 28.6V17.1c0-3 2.4-5.4 5.4-5.4h14.6c3 0 5.4 2.4 5.4 5.4V20h-7.4c-.6 0-1 .4-1 1s.4 1 1 1h7.4v6.1h-4.7c-.6 0-1 .4-1 1s.4 1 1 1h4.7v7.2c0 3-2.4 5.4-5.4 5.4H14.8c-3 0-5.4-2.4-5.4-5.4m7.3 9.6v-2.2h10.5v2.2c0 2-1.6 3.6-3.6 3.6h-3.3c-2 0-3.6-1.6-3.6-3.6" />
  </svg>
);
export default IconFluid;
