import * as React from "react";
const IconCodingAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M24.5 18.5c-.4-.4-1-.4-1.4-.1l-5.6 5c-.2.2-.3.5-.3.7s.1.6.3.7l5.6 5c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.3-1-.1-1.4l-4.7-4.3 4.7-4.3c.5-.2.5-.9.1-1.3M47 23.5l-5.6-5c-.4-.4-1-.3-1.4.1s-.3 1 .1 1.4l4.7 4.3-4.7 4.3c-.4.4-.4 1-.1 1.4.2.2.5.3.7.3s.5-.1.7-.3l5.6-5c.2-.2.3-.5.3-.7s-.1-.6-.3-.8M34.6 15.2c-.5-.1-1.1.2-1.2.7L29 32.1c-.1.5.2 1.1.7 1.2h.3c.4 0 .8-.3 1-.7l4.4-16.2c.1-.5-.3-1-.8-1.2" />
    <path d="M53.9 24.9C53.9 12.8 44.1 3 32 3s-21.9 9.8-21.9 21.9c0 8.8 5.2 16.4 12.8 19.9v11.4c0 2.7 2.2 4.9 4.9 4.9h9c2.7 0 4.9-2.2 4.9-4.9V44.5c7.1-3.5 12.2-11 12.2-19.6M39.6 46v10.2c0 1.6-1.3 2.9-2.9 2.9h-9c-1.6 0-2.9-1.3-2.9-2.9V46h1.4c1.8.5 3.7.8 5.7.8s3.9-.3 5.7-.8zM32 44.7c-11 0-19.9-8.9-19.9-19.9C12.1 13.9 21 5 32 5s19.9 8.9 19.9 19.9c0 10.9-8.9 19.8-19.9 19.8" />
  </svg>
);
export default IconCodingAlt1;
