import * as React from "react";
const IconSpeechBubble36 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M11.1 18.7H11c-1.1 0-2 .9-2 2s1 2 2.1 2 2-.9 2-2-.9-2-2-2M20.3 18.7h-.1c-1.1 0-2 .9-2 2s1 2 2.1 2 2-.9 2-2-.9-2-2-2M29.5 18.7h-.1c-1.1 0-2 .9-2 2s1 2 2.1 2 2-.9 2-2-.9-2-2-2" />
    <path d="M44.9 21.8c-1.9 0-3.8.3-5.6.9V12.5c0-2.7-2.2-4.9-4.9-4.9H6.9C4.2 7.6 2 9.8 2 12.5v17.6C2 32.8 4.2 35 6.9 35h1.2c-.3 1.1-.6 2.3-1 3.4-.6 1.9-1.2 3.2-1.7 3.9-.2.4-.2.9.1 1.2.2.2.5.4.8.4.1 0 .3 0 .4-.1 3-1.3 8.7-5.8 11-8.8h10.6c-.9 4-.4 8.3 1.6 12l-2.5 8.1c-.1.4 0 .7.3 1 .2.2.4.3.7.3.1 0 .2 0 .3-.1l7.9-2.6c5.3 3 11.5 3 16.8 0 5.4-3.1 8.7-8.7 8.7-14.9-.1-9.4-7.8-17-17.2-17M17.1 33c-.3 0-.7.2-.8.5-1.3 2-4.9 5.1-7.8 7 .2-.4.3-.9.5-1.4.5-1.6 1-3.2 1.3-4.4l.1-.3c.1-.3 0-.6-.2-.9s-.5-.5-.8-.5H6.9C5.3 33 4 31.7 4 30.1V12.5c0-1.6 1.3-2.9 2.9-2.9h27.5c1.6 0 2.9 1.3 2.9 2.9v11.1c-1.7.8-3.2 1.9-4.5 3.2-1.8 1.8-3.1 3.9-4 6.2zm35.2 19c-4.8 2.7-10.5 2.7-15.2-.2-.2-.1-.3-.1-.5-.1-.1 0-.2 0-.3.1l-6.5 2.1 2.1-6.7c.1-.3 0-.6-.1-.8-3.4-5.9-2.4-13.4 2.4-18.2 2.9-2.9 6.7-4.4 10.7-4.4 8.3 0 15.1 6.8 15.1 15.1 0 5.5-2.9 10.4-7.7 13.1" />
  </svg>
);
export default IconSpeechBubble36;
