import * as React from "react";
const IconBaby0203m = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M24 17a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11m0-2a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m-6 13.94q0 .184.033.393C16.18 30.923 15 33.253 15 35.857c0 .957.19 2.389.737 3.62C16.278 40.697 17.32 42 19.112 42c1.745 0 3.222-1.373 3.222-3.143 0-.753-.303-1.403-.508-1.844l-.03-.064c-.235-.51-.351-.798-.351-1.092 0-.289.166-.771.526-1.324.21-.32.448-.608.674-.835.469.181.93.302 1.355.302.427 0 .887-.12 1.356-.302.226.227.464.514.674.835.36.553.526 1.035.526 1.324 0 .286-.116.58-.354 1.1l-.03.067c-.204.442-.505 1.096-.505 1.833 0 1.77 1.477 3.143 3.222 3.143 1.787 0 2.818-1.389 3.35-2.59.55-1.239.762-2.666.762-3.553 0-2.604-1.18-4.933-3.033-6.524q.032-.208.032-.393v-5.139q.595-.117 1.207-.255a58 58 0 0 0 2.589-.653 3 3 0 1 0-1.59-5.786c-3.365.925-5.82 1.317-8.193 1.307-2.384-.01-4.85-.426-8.253-1.316a3 3 0 0 0-1.519 5.804c1.323.346 2.565.64 3.76.873zm15.028-7.91.238-.066a1 1 0 0 0-.53-1.928c-3.465.952-6.112 1.39-8.731 1.378-2.623-.01-5.274-.472-8.751-1.381a1 1 0 0 0-.507 1.934c1.598.419 3.05.75 4.426.991a1 1 0 0 1 .828.985V26h8v-3.03a1 1 0 0 1 .828-.985 47 47 0 0 0 4.2-.956M17.001 35.856c0-1.766.71-3.38 1.885-4.584a5.05 5.05 0 0 0 1.865 1.544q-.244.302-.454.623c-.442.676-.852 1.554-.852 2.417 0 .776.307 1.436.515 1.886l.022.047c.24.519.352.793.352 1.067 0 .597-.513 1.143-1.222 1.143-.621 0-1.136-.41-1.547-1.335-.405-.91-.565-2.05-.565-2.808m10.703-2.417a8 8 0 0 0-.454-.623 5.05 5.05 0 0 0 1.865-1.544c1.175 1.205 1.886 2.818 1.886 4.584 0 .652-.17 1.796-.59 2.743-.438.986-.963 1.4-1.522 1.4-.71 0-1.222-.546-1.222-1.143 0-.261.111-.538.354-1.069l.022-.047c.207-.453.513-1.12.513-1.884 0-.863-.41-1.741-.852-2.417m-1.56-2.087c1.4-.533 1.856-1.644 1.856-2.077V27.95h-8v1.326c0 .433.456 1.544 1.857 2.077.16.06.34.137.502.206l.095.04c.199.084.391.163.586.234.406.147.723.217.96.217s.554-.07.96-.217c.195-.07.387-.15.586-.234l.095-.04m.503-.206c-.16.06-.34.137-.503.206z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconBaby0203m;
