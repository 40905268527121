import * as React from "react";
const IconHousingAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 22.8H47.1v-5.5c0-2-1.2-3.8-3-4.6L30.5 6.9c-1.5-.7-3.3-.5-4.7.4s-2.3 2.5-2.3 4.2v2.2H6c-2.2 0-4 1.8-4 4v35.9c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V26.8c0-2.2-1.8-4-4-4M4 53.6V17.7c0-1.1.9-2 2-2h17.6v39.9H6c-1.1 0-2-.9-2-2m21.6-38.9v-3.2c0-1 .5-1.9 1.4-2.5s1.9-.7 2.8-.2l13.6 5.9c1.1.5 1.8 1.6 1.8 2.7v38.3H25.6zM60 53.6c0 1.1-.9 2-2 2H47.1V24.8H58c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconHousingAlt;
