import * as React from "react";
const IconHeadIdeaSetting = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.5 37.7s-.1 0 0 0l-3.4-3.1c.1-.9.1-1.8.1-2.7s0-1.8-.1-2.7l3.4-3.1c1.6-1.5 1.9-3.8.9-5.7l-4.1-7.1c-1.1-1.9-3.3-2.8-5.4-2.1l-4.3 1.4c-1.4-1.1-3-2-4.6-2.7l-1-4.5C41.4 3.5 39.5 2 37.4 2h-4.3C24 1.6 15.8 6.5 11.5 14.9 9.7 18.4 9.4 22.3 9.3 25c0 1.2-.4 2.5-1.1 3.5l-5.7 9c-.3.6-.5 1.2-.5 1.8 0 1.9 1.5 3.4 3.4 3.4h3.8V49c0 3.2 2.6 5.8 5.8 5.8.7 0 1.3-.1 1.7-.3l4.5-1.4v6.7c0 1.2 1 2.2 2.2 2.2h13.9c2.1 0 4-1.5 4.5-3.6l1-4.5c1.6-.7 3.2-1.6 4.6-2.7l4.3 1.4c2.1.6 4.3-.3 5.4-2.1l4.1-7.1c1.2-1.8.8-4.2-.7-5.7M23.4 59.8v-8c0-.3-.1-.6-.4-.8-.2-.1-.4-.2-.6-.2h-.3l-5.9 1.8c-.3.1-.7.2-1.1.2-2.1 0-3.8-1.7-3.8-3.8v-7.3c0-.6-.4-1-1-1H5.5c-.8 0-1.4-.6-1.4-1.4 0-.3.1-.5.2-.8l5.7-9c.8-1.3 1.3-2.9 1.4-4.5.1-3.9.7-6.8 2-9.2C17 8.4 24.2 4 32.1 4h.2v55.9h-8.7c-.1.1-.2 0-.2-.1m36.2-17.4-4.1 7.1c-.6 1-1.9 1.5-3 1.2l-4.8-1.5c-.3-.1-.7 0-.9.2-1.5 1.2-3.2 2.2-5 2.9-.3.1-.5.4-.6.7l-1.1 5c-.3 1.2-1.3 2-2.5 2h-3.1V4h3.1c1.2 0 2.3.9 2.5 2l1.1 5c.1.3.3.6.6.7 1.8.7 3.5 1.7 5 2.9.3.2.6.3.9.2l4.8-1.5c1.2-.4 2.5.1 3.1 1.2l4.1 7.1c.6 1.1.4 2.4-.5 3.3l-3.7 3.4c-.2.2-.4.5-.3.9.1.9.2 1.9.2 2.9s-.1 2-.2 2.9q0 .45.3.9l3.7 3.4c.8.7 1 2.1.4 3.1" />
  </svg>
);
export default IconHeadIdeaSetting;
