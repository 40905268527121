import * as React from "react";
const IconQuotationCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M15.3 15.1h-.2C9 15.1 3.5 19 2.3 24.3c-.8 3.2-.1 6.5 1.9 9.2 2.1 2.9 5.5 4.8 9.3 5.2l3.7 9c.3.6.9 1 1.6 1s1.3-.4 1.6-1c.6-1.5 1.3-3.1 2-4.6 1.6-3.8 3.3-7.7 4.7-11.6 1.3-3.9.8-8.2-1.5-11.4-2.3-3.1-6-4.9-10.3-5m9.8 15.8c-1.3 3.8-3 7.7-4.6 11.5-.6 1.3-1.2 2.6-1.7 4L14.9 37h-.6c-3.4-.2-6.6-1.9-8.5-4.4-1.6-2.2-2.2-4.9-1.6-7.6 1.1-4.4 5.7-7.7 10.9-7.7h.1c3.7 0 6.7 1.5 8.6 4.2 2 2.5 2.4 6.1 1.3 9.4M59.8 20.1c-2.2-3.2-5.9-4.9-10.2-5h-.2c-6.1 0-11.6 4-12.8 9.3-.8 3.3-.1 6.5 1.9 9.2 2.2 2.9 5.5 4.8 9.3 5.2l3.7 9c.3.6.9 1 1.6 1s1.3-.4 1.6-1c.6-1.5 1.3-3.1 2-4.6 1.6-3.8 3.3-7.7 4.7-11.6 1.2-4 .7-8.2-1.6-11.5m-.4 10.8c-1.3 3.8-3 7.7-4.6 11.5-.6 1.3-1.2 2.6-1.7 4l-3.9-9.5h-.6c-3.5-.2-6.6-1.8-8.5-4.4-1.6-2.2-2.2-4.9-1.6-7.6 1.1-4.4 5.7-7.7 10.9-7.7h.1c3.7 0 6.7 1.5 8.6 4.2 2 2.6 2.4 6.2 1.3 9.5" />
  </svg>
);
export default IconQuotationCopy;
