import * as React from "react";
const IconSimCard = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M47 2H26.9c-1.5 0-2.9.6-3.9 1.6L13 13.7c-1 1-1.6 2.4-1.6 3.8v38.9c0 3 2.5 5.5 5.5 5.5h30c3 0 5.5-2.5 5.5-5.5v-49C52.5 4.5 50 2 47 2m3.5 54.5c0 1.9-1.6 3.5-3.5 3.5H17c-1.9 0-3.5-1.6-3.5-3.5V17.6c0-.9.4-1.8 1-2.4L24.5 5c.7-.7 1.6-1 2.5-1h20c1.9 0 3.5 1.6 3.5 3.5z" />
    <path d="M38.5 24.5h-13c-2.2 0-4 1.8-4 4v19.1c0 2.2 1.8 4 4 4h12.9c2.2 0 4-1.8 4-4V28.5c.1-2.3-1.7-4-3.9-4m2 4V37H33V26.7c0-.1 0-.2-.1-.3h5.5c1.2.1 2.1.9 2.1 2.1m-15-2H31c0 .1-.1.2-.1.3V37h-7.5v-8.5c.1-1.2 1-2 2.1-2m-2 21V39H31v10.5h-5.5c-1.1 0-2-.9-2-2m15 2H33V39h7.5v8.5c0 1.1-.9 2-2 2" />
  </svg>
);
export default IconSimCard;
