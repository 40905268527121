import * as React from "react";
const IconTelemedicine = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M8 6a2 2 0 0 0-2 2v26a2 2 0 0 0 2 2h15v4h-7v2h16v-2h-7v-4h15a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2zm4 25.38v-.666c0-2.518 3.73-4.885 7.333-5.706.585-.133.519.71.667.992h7.833c.152-.289.499-1.068.834-.992.753.172 1.511.411 2.24.705l.023-.011.007.014.005.01C33.685 26.842 36 28.732 36 30.714V34h3V10H9v24h3zm3.495-2.54c.533-.39 1.164-.749 1.846-1.058a6.71 6.71 0 0 0 .25 1.51l.006.018a2 2 0 1 0 1.304-.296l-.03-.096a5.4 5.4 0 0 1-.204-1.474l.126.239H29.04l.23-.437q.268.087.534.184c.005.22-.013.472-.048.73a5.4 5.4 0 0 1-.182.84h-.907a.67.67 0 0 0-.597.369l-.666 1.333a.7.7 0 0 0-.07.298v1.333c0 .369.298.667.666.667h1.333v-1.333h-.666v-.51l.412-.824h1.842l.412.824v.51h-.666V33H32a.667.667 0 0 0 .667-.667V31a.7.7 0 0 0-.07-.298l-.667-1.334a.67.67 0 0 0-.597-.368h-.377a7 7 0 0 0 .16-1c.811.41 1.507.879 2.02 1.357.732.683.864 1.158.864 1.356V34H14v-3.287c0-.274.264-.975 1.495-1.873M19.333 31a.67.67 0 0 1-.666.677A.67.67 0 0 1 18 31c0-.383.308-.677.667-.677s.666.294.666.677M24 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M24 22a4 4 0 1 0 0-8 4 4 0 0 0 0 8m0 2a6 6 0 1 0 0-12 6 6 0 0 0 0 12"
      clipRule="evenodd"
    />
  </svg>
);
export default IconTelemedicine;
