import * as React from "react";
const IconTshirt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M46.1 62H17.9c-3 0-5.4-2.4-5.4-5.3V22.9c0-.2-.2-.5-.5-.5H7.4C4.4 22.4 2 20 2 17V7.4C2 4.4 4.4 2 7.4 2h11.8c2.3 0 4.3 1.4 5 3.5 1.1 3.4 4.2 5.6 7.8 5.6 3.5 0 6.7-2.3 7.8-5.6.7-2.1 2.7-3.5 5-3.5h10.7C60 2 62 4.7 62 7.4v9.8c0 3-2.4 5.4-5.3 5.4H52c-.2 0-.5.2-.5.5v33.7c0 2.8-2.4 5.2-5.4 5.2M7.4 4C5.5 4 4 5.5 4 7.4v9.8c0 1.8 1.5 3.4 3.4 3.4h4.7c1.4 0 2.5 1.1 2.5 2.5v33.7c0 1.8 1.5 3.3 3.4 3.3h28.2c1.8 0 3.3-1.5 3.3-3.3V22.9c0-1.4 1.1-2.5 2.5-2.5h4.7c1.8 0 3.3-1.5 3.3-3.4V7.4c0-2-1.8-3.4-4.5-3.4H44.8c-1.4 0-2.7.9-3.1 2.2-1.4 4.2-5.3 7-9.7 7s-8.3-2.8-9.7-7C21.9 4.9 20.6 4 19.2 4z" />
  </svg>
);
export default IconTshirt;
