import * as React from "react";
const IconCreditCardDelete = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M52.4 39c-.4-.4-1-.4-1.4 0l-2 2-2-2c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l2 2-2 2c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l2-2 2 2c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-2-2 2-2c.4-.4.4-1.1 0-1.4" />
    <path d="M50.1 29.4V13.7c0-2.8-2.2-5-5-5H7c-2.8 0-5 2.2-5 5v23.1c0 2.8 2.2 5 5 5h29.1v.5c0 7.1 5.8 12.9 12.9 12.9s13-5.7 13-12.9c0-6.8-5.3-12.4-11.9-12.9M4 18.4h44.1V24H4zm3-7.7h38c1.7 0 3 1.3 3 3v2.7H4v-2.7c0-1.6 1.3-3 3-3m0 29.1c-1.7 0-3-1.3-3-3V26h44.1v3.4c-5.8.5-10.6 4.8-11.7 10.4zm42.1 13.5c-6 0-10.9-4.9-10.9-10.9s4.9-10.9 10.9-10.9S60 36.3 60 42.3c0 6.1-4.9 11-10.9 11" />
  </svg>
);
export default IconCreditCardDelete;
