import * as React from "react";
const IconMicrowave = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 6.8H6c-2.2 0-4 1.8-4 4v42.5c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V10.8c0-2.3-1.8-4-4-4m-52 2h52c1.1 0 2 .9 2 2v8.6H4v-8.6c0-1.2.9-2 2-2m52 46.4H6c-1.1 0-2-.9-2-2V21.3h56v31.9c0 1.2-.9 2-2 2" />
    <path d="M32 28.8H16.4c-2.2 0-4 1.8-4 4v11.3c0 2.2 1.8 4 4 4H32c2.2 0 4-1.8 4-4V32.8c0-2.2-1.8-4-4-4m2 15.3c0 1.1-.9 2-2 2H16.4c-1.1 0-2-.9-2-2V32.8c0-1.1.9-2 2-2H32c1.1 0 2 .9 2 2zM49.1 41.4c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4m0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2M49.1 38.2c.6 0 1-.4 1-1V30c0-.6-.4-1-1-1s-1 .4-1 1v7.2c0 .6.4 1 1 1" />
  </svg>
);
export default IconMicrowave;
