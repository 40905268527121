import * as React from "react";
const IconMicrophoneSlash = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M37 41.9c-1.6.5-3.3.8-5 .8-4.2 0-8.2-1.6-11.1-4.6-3-2.9-4.6-6.9-4.6-11 0-.6-.4-1-1-1s-1 .4-1 1c0 4.7 1.8 9.1 5.2 12.5 3.1 3.1 7.2 4.9 11.5 5.1V60c0 .6.4 1 1 1s1-.4 1-1V44.7c1.6-.1 3.1-.4 4.6-.9.5-.2.8-.7.6-1.3-.1-.5-.7-.8-1.2-.6M56.2 51.1 44.1 39.9c3.6-3.3 5.7-7.9 5.7-12.8 0-.6-.4-1-1-1s-1 .4-1 1c0 4.4-1.9 8.5-5.1 11.5l-4.4-4.1c2.4-1.9 3.9-4.8 3.9-7.9V13.1c0-2.7-1-5.2-3-7.1-2-2-4.5-3-7.2-3-5.6 0-10.1 4.5-10.1 10.1v6.5L9.1 7.8c-.4-.4-1-.3-1.4.1s-.3 1 .1 1.4l47.1 43.3c.2.2.4.3.7.3s.5-.1.7-.3c.4-.5.4-1.1-.1-1.5m-32.3-38C23.9 8.6 27.6 5 32 5c2.2 0 4.2.8 5.7 2.4 1.5 1.5 2.4 3.5 2.4 5.7v13.5c0 2.6-1.3 5.1-3.4 6.6L23.9 21.4z" />
  </svg>
);
export default IconMicrophoneSlash;
