import * as React from "react";
const IconUserGlobe = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.7 35.4c-3-5.1-8.3-8.2-14.1-8.3h-.5c-3.4 0-6.6 1.1-9.3 3-3.2-3.1-7.2-4.9-11.4-5.3 5.2-.8 9.3-5.3 9.3-10.7 0-6-4.9-10.8-10.8-10.8S11.8 8.2 11.8 14.2c0 5.4 4 9.9 9.3 10.7-5 .4-9.6 2.9-13 7-3.8 4.4-6 10.7-6.1 17.5q0 .6.6.9c2.4 1.2 11 5.1 20.1 5.1 2.9 0 6-.3 9.1-1 .1 0 .2-.1.3-.1 3 3.9 7.5 6.2 12.4 6.4h.9c5.3-.1 10.1-2.6 13.3-6.9 2.1-2.9 3.2-6.3 3.2-9.9.1-3-.7-5.9-2.2-8.5m.3 8.5c0 2.3-.5 4.6-1.6 6.7-2.3-.7-5.3-1.4-7.8-1.7.3-1.6.4-3.3.4-4.9 0-1.7-.2-3.3-.4-5q4.05-.45 7.8-1.8c1 2 1.6 4.4 1.6 6.7M44.9 58.4c-1.4-2.3-2.5-4.8-3.2-7.4 0-.1-.1-.2-.1-.4h.2c2.1-.2 4.3-.2 6.4 0 0 .1-.1.3-.1.4-.7 2.5-1.8 5-3.2 7.4m-12.8-7.7c-1.1-2.1-1.6-4.4-1.6-6.8s.6-4.7 1.7-6.8c2.3.8 4.6 1.4 7 1.7-.3 1.7-.5 3.4-.5 5.1s.2 3.4.4 5c-2.2.4-4.7 1-6.9 1.7 0 0 0 .1-.1.1m4.1-18.5q.15 0 0 0c.1 0 .1-.1 0 0 1.9-1.5 4.1-2.4 6.4-2.8q-2.1 3.6-3 7.5c-2.2-.3-4.3-.8-6.4-1.5.9-1.2 1.9-2.3 3-3.2m12 5c-2.2.2-4.4.2-6.5-.1.7-2.7 1.8-5.2 3.3-7.7 1.4 2.5 2.5 5.1 3.2 7.8m-7.5 6.7c0-1.6.2-3.2.4-4.8 1.3.1 2.7.2 4.1.2 1.1 0 2.3-.1 3.4-.2.3 1.6.4 3.2.4 4.7 0 1.6-.2 3.2-.4 4.7-2.3-.2-4.7-.2-7 0h-.4c-.3-1.4-.5-3-.5-4.6m16.6-8.5c-2.3.8-4.7 1.3-7.1 1.6-.7-2.7-1.7-5.2-3.1-7.7 4.1.5 7.8 2.7 10.2 6.1M13.8 14.2c0-4.9 4-8.8 8.8-8.8s8.8 4 8.8 8.8-4 8.8-8.8 8.8-8.8-4-8.8-8.8M4 48.8c.2-6.1 2.2-11.7 5.6-15.7 3.5-4.1 8.1-6.3 13.1-6.3 4.2 0 8.2 1.6 11.4 4.6-1.3 1.1-2.4 2.5-3.3 4-1.5 2.6-2.3 5.5-2.3 8.5 0 3.1.8 6 2.4 8.6-2.8.6-5.5.9-8.2.9-8.1 0-15.8-3.2-18.7-4.6m29.3 3.8c-.1-.1-.1-.1-.1-.2 2.1-.6 4.3-1.2 6.4-1.5.1.2.1.4.2.6.6 2.4 1.6 4.7 2.9 6.9-3.8-.6-7.1-2.7-9.4-5.8m13.8 6c1.3-2.2 2.3-4.6 2.9-7 .1-.2.1-.5.2-.7 2.3.3 5 .9 7.2 1.6-.1.1-.1.2-.2.3-2.5 3.2-6.1 5.3-10.1 5.8" />
  </svg>
);
export default IconUserGlobe;
