import * as React from "react";
const IconKayak = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.4 53.6-7.7-7.7c-.4-.4-1-.4-1.4 0l-2.5 2.5-6.1-6.1 6.1-6.1c6.4-6.4 8.9-16.4 7-28.1-.1-.4-.4-.8-.8-.8-11.7-1.8-21.7.7-28.1 7l-6.1 6.1-6.1-6.1 2.5-2.5c.4-.4.4-1 0-1.4l-7.7-7.7C8.9 1 6.3 1 4.8 2.6L2.6 4.8C1 6.3 1 8.9 2.6 10.4l7.7 7.7c.2.2.5.3.7.3s.5-.1.7-.3l2.5-2.5 6.1 6.1-6.1 6.3c-6.4 6.4-8.8 16.3-7 28 .1.4.4.8.8.8 2.5.4 5 .6 7.3.6 8.5 0 15.7-2.6 20.7-7.6l6.2-6.2 6.1 6.1-2.5 2.5c-.4.4-.4 1 0 1.4l7.7 7.7c.8.8 1.8 1.2 2.8 1.2s2-.4 2.8-1.2l2.2-2.2c1.7-1.4 1.7-4 .1-5.5M11 16 4 9c-.8-.8-.8-2 0-2.8L6.2 4c.4-.4.9-.6 1.4-.6s1 .2 1.4.6l7 7zm18.3-.4C35.1 9.9 44.2 7.5 55 9.1c1.5 10.7-.8 19.8-6.6 25.6l-6.1 6.1-5-5c-.1-.1-.2-.2-.4-.2 1.2-1.4 2-3 2.4-4.6.6-2.2.2-4.1-1-5.3s-3.1-1.6-5.3-1c-1.5.4-3.1 1.2-4.6 2.4 0-.1-.1-.3-.2-.4l-5-5zm-.7 14.1c1.5-1.5 3.2-2.6 4.9-3 .4-.1.9-.2 1.4-.2.7 0 1.4.2 2 .7.9.9.7 2.5.5 3.4-.4 1.7-1.5 3.4-3 4.9s-3.2 2.6-4.9 3c-.9.2-2.4.4-3.4-.5-.9-.9-.7-2.5-.5-3.4.4-1.7 1.5-3.4 3-4.9m6 18.7c-5.8 5.8-14.8 8.1-25.6 6.5-1.5-10.7.8-19.8 6.5-25.6l6.2-6.2 5 5c.1.1.2.1.3.2-1.7 1.7-2.9 3.7-3.4 5.7-.6 2.2-.2 4.1 1 5.3.8.8 2 1.3 3.4 1.3.6 0 1.2-.1 1.9-.3 2-.5 4-1.7 5.7-3.4 0 .1.1.2.2.3l5 5zM60 57.8 57.8 60c-.8.8-2 .8-2.8 0l-7-7 5-5 7 7c.8.8.8 2 0 2.8" />
  </svg>
);
export default IconKayak;
