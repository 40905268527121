import * as React from "react";
const IconArrowsExpandRightAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.5 3.5h-11c-.6 0-1 .4-1 1s.4 1 1 1h8.6L41.6 21c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3L58.5 6.9v8.6c0 .6.4 1 1 1s1-.4 1-1v-11c0-.6-.4-1-1-1M21 41.6 5.5 57.1v-8.6c0-.6-.4-1-1-1s-1 .4-1 1v11c0 .1 0 .3.1.4.1.2.3.4.5.5.1.1.3.1.4.1h11c.6 0 1-.4 1-1s-.4-1-1-1H6.9L22.4 43c.4-.4.4-1 0-1.4s-1-.4-1.4 0M22.6 21.2c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l20.3 20.3c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4z" />
  </svg>
);
export default IconArrowsExpandRightAlt;
