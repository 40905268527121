import * as React from "react";
const IconSchoolBenchCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m32.3 27.1-29-10.5c-.5-.2-1.1.1-1.3.6s.1 1.1.6 1.3l4.6 1.7v26.4c0 .6.4 1 1 1s1-.4 1-1V20.8l16.5 6v19.7c0 .6.4 1 1 1s1-.4 1-1V27.6l3.9 1.4c.1 0 .2.1.3.1.4 0 .8-.3.9-.7.3-.5.1-1.1-.5-1.3M61 16.5c-.6 0-1 .4-1 1V35H48c-2.1 0-3.8 1.7-3.8 3.8v7.7c0 .6.4 1 1 1s1-.4 1-1v-7.7c0-1 .8-1.8 1.8-1.8h12v9.5c0 .6.4 1 1 1s1-.4 1-1v-29c0-.6-.4-1-1-1" />
  </svg>
);
export default IconSchoolBenchCopy;
