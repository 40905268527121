import * as React from "react";
const IconCupAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M49.5 31h6.1c3.5 0 6.4-2.9 6.4-6.4s-2.9-6.4-6.4-6.4h-5.4v-1.6c0-2.1-1.7-3.8-3.8-3.8H14.5c-2.1 0-3.8 1.7-3.8 3.8v9.2c0 6 2.5 11.5 6.9 15.3H3c-.6 0-1 .4-1 1v2.3c0 3.7 3 6.7 6.7 6.7h43.5c3.7 0 6.7-3 6.7-6.7v-2.3c0-.6-.4-1-1-1H43.3c3.1-2.6 5.2-6.2 6.2-10.1m6.1-10.8c2.4 0 4.4 2 4.4 4.4S58 29 55.6 29h-5.7c.1-1 .2-1.9.2-2.9v-5.9zm1.3 24.3c0 2.6-2.1 4.7-4.7 4.7H8.7c-2.6 0-4.7-2.1-4.7-4.7v-1.3h52.8v1.3zm-36-3.3c-5.1-3.4-8.1-9.1-8.1-15.3v-9.2c0-1 .8-1.8 1.8-1.8h31.9c1 0 1.8.8 1.8 1.8V26c-.1 6.2-3.1 11.8-8.1 15.1H20.9z" />
  </svg>
);
export default IconCupAlt;
