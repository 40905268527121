import * as React from "react";
const IconSpeechBubble8 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54 15.3C50.3 7.2 41.8 2 32.6 2 20.1 2 9.4 11 7.7 23c-1.9 12.7 6 24.5 18.4 27.5l3.7 10c.3.9 1.2 1.5 2.2 1.5s1.9-.6 2.2-1.5l3.7-10c7-1.7 12.9-6.5 16.1-13 3.5-6.9 3.5-14.8 0-22.2m-1.7 21.3c-3 6.2-8.6 10.6-15.3 12.1-.3.1-.6.3-.7.6l-3.9 10.5c-.1.2-.3.2-.4.2s-.3 0-.3-.2l-3.9-10.5c-.1-.3-.4-.5-.7-.6C15.4 46 7.9 35.1 9.6 23.2 11.2 12.3 21.1 4 32.6 4c8.5 0 16.2 4.8 19.6 12.1 3.2 6.9 3.2 14.1.1 20.5" />
    <path d="M40 16.4H24c-.6 0-1 .4-1 1s.4 1 1 1h16c.6 0 1-.4 1-1s-.4-1-1-1M40 25.1H24c-.6 0-1 .4-1 1s.4 1 1 1h16c.6 0 1-.4 1-1s-.4-1-1-1M40 33.3H24c-.6 0-1 .4-1 1s.4 1 1 1h16c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconSpeechBubble8;
