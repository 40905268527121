import * as React from "react";
const IconBandAid = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54 18.6H10c-3.9 0-7 3.1-7 7v12.7c0 3.9 3.1 7 7 7h44c3.9 0 7-3.1 7-7V25.6c0-3.8-3.1-7-7-7m-12.6 2v22.7H22.6V20.6zM5 38.4V25.6c0-2.8 2.2-5 5-5h10.6v22.7H10c-2.8.1-5-2.2-5-4.9m54 0c0 2.8-2.2 5-5 5H43.4V20.6H54c2.8 0 5 2.2 5 5z" />
    <path d="M27.6 29.8c1.5 0 2.7-1.2 2.7-2.7s-1.2-2.7-2.7-2.7-2.7 1.2-2.7 2.7 1.2 2.7 2.7 2.7m0-3.5c.4 0 .7.3.7.7s-.3.7-.7.7-.7-.3-.7-.7.3-.7.7-.7M36.4 29.8c1.5 0 2.7-1.2 2.7-2.7s-1.2-2.7-2.7-2.7-2.7 1.2-2.7 2.7 1.1 2.7 2.7 2.7m0-3.5c.4 0 .7.3.7.7s-.3.7-.7.7-.7-.3-.7-.7.3-.7.7-.7M36.4 39.7c1.5 0 2.7-1.2 2.7-2.7s-1.2-2.7-2.7-2.7-2.7 1.2-2.7 2.7 1.1 2.7 2.7 2.7m0-3.5c.4 0 .7.3.7.7s-.3.7-.7.7-.7-.3-.7-.7.3-.7.7-.7M27.6 34.2c-1.5 0-2.7 1.2-2.7 2.7s1.2 2.7 2.7 2.7 2.7-1.2 2.7-2.7-1.1-2.7-2.7-2.7m0 3.5c-.4 0-.7-.3-.7-.7s.3-.7.7-.7.7.3.7.7-.3.7-.7.7" />
  </svg>
);
export default IconBandAid;
