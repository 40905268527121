import * as React from "react";
const IconUserSearch = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M33 57.3c-1.2.1-2.3.2-3.5.2-9 0-17.6-3.7-20.8-5.2.2-6.8 2.4-13 6.2-17.5s9-7 14.6-7c4.2 0 8.3 1.4 11.7 4.2.4.3 1.1.3 1.4-.2.3-.4.3-1.1-.2-1.4-3.7-3-8.1-4.6-12.8-4.6 6.5-.1 11.8-5.4 11.8-11.9C41.5 7.3 36.1 2 29.6 2S17.7 7.3 17.7 13.9c0 6.5 5.3 11.8 11.7 11.9-6.1 0-11.8 2.8-16 7.8S6.9 45.4 6.7 53q0 .6.6.9c2.7 1.3 12.2 5.7 22.2 5.7 1.2 0 2.4-.1 3.7-.2.5 0 1-.5.9-1.1 0-.6-.5-1-1.1-1M19.7 13.9c0-5.5 4.4-9.9 9.9-9.9s9.9 4.4 9.9 9.9-4.4 9.9-9.9 9.9-9.9-4.4-9.9-9.9" />
    <path d="m57 60.4-4.5-6.2c1.8-1.6 3-3.7 3.4-6.2.4-2.7-.2-5.5-1.8-7.8-3.4-4.7-9.9-5.7-14.5-2.3-2.3 1.6-3.7 4-4.2 6.8-.4 2.7.2 5.5 1.8 7.8 2 2.8 5.2 4.3 8.5 4.3 1.8 0 3.6-.5 5.3-1.4l4.5 6.2c.2.3.5.4.8.4.2 0 .4-.1.6-.2.4-.3.5-.9.1-1.4m-18.2-9.1c-2.7-3.8-1.9-9 1.9-11.8a8.2 8.2 0 0 1 4.9-1.6c2.6 0 5.2 1.2 6.8 3.5 1.3 1.8 1.8 4 1.5 6.3-.4 2.2-1.6 4.2-3.4 5.5-3.7 2.7-9 1.9-11.7-1.9" />
  </svg>
);
export default IconUserSearch;
