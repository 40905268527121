import * as React from "react";
const IconServer8 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.5 18.3V7c0-2.8-2.2-5-5-5h-45c-2.8 0-5 2.2-5 5v11.3c0 1.6.8 3.1 2 4-1.2.9-2 2.4-2 4v11.3c0 1.6.8 3.1 2 4-1.2.9-2 2.4-2 4V57c0 2.8 2.2 5 5 5h45c2.8 0 5-2.2 5-5V45.7c0-1.6-.8-3.1-2-4 1.2-.9 2-2.4 2-4V26.3c0-1.6-.8-3.1-2-4 1.2-.9 2-2.3 2-4m-2 27.4V57c0 1.7-1.3 3-3 3h-45c-1.7 0-3-1.3-3-3V45.7c0-1.7 1.3-3 3-3h45c1.6 0 3 1.3 3 3m0-19.4v11.3c0 1.7-1.3 3-3 3h-45c-1.7 0-3-1.3-3-3V26.3c0-1.7 1.3-3 3-3h45c1.6 0 3 1.4 3 3m0-8c0 1.7-1.3 3-3 3h-45c-1.7 0-3-1.3-3-3V7c0-1.7 1.3-3 3-3h45c1.7 0 3 1.3 3 3z" />
    <path d="M17 11.5c-.6 0-1 .4-1 1v.2c0 .6.4 1 1 1s1-.4 1-1v-.2c0-.6-.4-1-1-1M49.2 11.5H43c-.6 0-1 .4-1 1s.4 1 1 1h6.2c.6 0 1-.4 1-1s-.5-1-1-1M17 33c.6 0 1-.4 1-1v-.2c0-.6-.4-1-1-1s-1 .4-1 1v.2c0 .5.4 1 1 1M43 32.9h6.2c.6 0 1-.4 1-1s-.4-1-1-1H43c-.6 0-1 .4-1 1s.5 1 1 1M17 52.3c.6 0 1-.4 1-1v-.2c0-.6-.4-1-1-1s-1 .4-1 1v.2c0 .5.4 1 1 1M43 52.2h6.2c.6 0 1-.4 1-1s-.4-1-1-1H43c-.6 0-1 .4-1 1s.5 1 1 1" />
  </svg>
);
export default IconServer8;
