import * as React from "react";
const IconLolipop2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.6 21.9c0-11-8.9-19.9-19.9-19.9-5.2 0-10 1.9-13.7 5.4-9.7.4-17.6 8.4-17.6 18.2 0 3.2.8 6.2 2.3 8.8l.1.1c3 5.3 8.5 8.9 14.9 9.2V61c0 .6.4 1 1 1s1-.4 1-1V43.7c2.8-.2 5.5-.9 7.8-2.3 1 .2 2.1.4 3.2.4V61c0 .6.4 1 1 1s1-.4 1-1V41.8c10.5-.5 18.9-9.2 18.9-19.9M10.7 33.8c2.3-7.1 5.2-11.1 8.4-11.6 4.1-.6 7.8 4.6 7.9 4.7.3.5.9.6 1.4.2.5-.3.6-.9.3-1.4-.2-.3-4.4-6.3-9.8-5.5-3.7.6-6.8 4.3-9.3 11-.7-1.8-1-3.7-1-5.7 0-8.9 7.3-16.2 16.2-16.2S41 16.6 41 25.5s-7.3 16.2-16.2 16.2c-6.1.1-11.3-3.2-14.1-7.9m26.9 6.1c-.6 0-1.1 0-1.7-.1 4.2-3.3 6.9-8.5 6.9-14.2 0-9.2-6.9-16.9-15.9-18C30 5.3 33.7 4 37.6 4c9.9 0 17.9 8.1 17.9 17.9s-8 18-17.9 18" />
  </svg>
);
export default IconLolipop2;
