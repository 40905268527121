import * as React from "react";
const IconCannabis = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M24.86 5.488a1 1 0 0 0-1.72 0C21.149 8.834 20 12.78 20 17q0 .611.032 1.213-.48-.561-1.011-1.092A19.18 19.18 0 0 0 9.325 11.9a1 1 0 0 0-1.183 1.183 19.18 19.18 0 0 0 7.94 11.954A16 16 0 0 0 15 25c-3.531 0-6.819 1.176-9.588 3.191a1 1 0 0 0 0 1.618C8.182 31.824 11.469 33 15 33a15.7 15.7 0 0 0 3.14-.316l-.006.012c-1.215 2.105-1.644 4.466-1.396 6.801a1 1 0 0 0 1.4.809c1.96-.87 3.657-2.24 4.862-4.074V41h2v-4.768c1.205 1.834 2.902 3.203 4.862 4.074a1 1 0 0 0 1.4-.809c.248-2.335-.18-4.696-1.396-6.801l-.007-.012A15.7 15.7 0 0 0 33 33c3.532 0 6.819-1.176 9.588-3.191a1 1 0 0 0 0-1.618C39.818 26.176 36.532 25 33 25q-.545 0-1.083.037a19.175 19.175 0 0 0 7.94-11.954 1 1 0 0 0-1.182-1.183 19.18 19.18 0 0 0-10.707 6.313Q28 17.611 28 17c0-4.22-1.148-8.166-3.14-11.512M24 25.791a19.4 19.4 0 0 1 1.345-3.597C25.772 20.539 26 18.798 26 17c0-3.196-.72-6.213-2-8.884-1.28 2.671-2 5.688-2 8.884 0 1.798.228 3.539.655 5.194A19.4 19.4 0 0 1 24 25.79m9.222-4.427a17.1 17.1 0 0 1-6.895 4.204 22 22 0 0 0 .9-2.666c.8-1.58 1.855-3.055 3.166-4.367a17.1 17.1 0 0 1 7.097-4.267 17.1 17.1 0 0 1-4.268 7.096m-12.448 1.538q.369 1.374.899 2.666a17.1 17.1 0 0 1-6.895-4.204 17.1 17.1 0 0 1-4.268-7.096 17.1 17.1 0 0 1 7.097 4.267 17 17 0 0 1 3.167 4.367m1.985 8.072a17 17 0 0 1-1.172.59 8.9 8.9 0 0 0-1.72 2.132 8.95 8.95 0 0 0-1.184 4.05 8.95 8.95 0 0 0 2.915-3.05 8.9 8.9 0 0 0 1.161-3.722M15 27c2.464 0 4.804.656 6.901 1.837q-.741.458-1.404 1.022A13.8 13.8 0 0 1 15 31c-2.576 0-5.015-.716-7.183-2 2.168-1.284 4.607-2 7.183-2m12.503 2.859q-.662-.565-1.404-1.022C28.196 27.656 30.536 27 33 27c2.576 0 5.015.716 7.183 2-2.168 1.284-4.607 2-7.183 2-1.927 0-3.779-.401-5.497-1.141m-2.262 1.115a8.9 8.9 0 0 0 1.16 3.722 8.95 8.95 0 0 0 2.916 3.05 8.95 8.95 0 0 0-1.183-4.05 8.9 8.9 0 0 0-1.72-2.132 17 17 0 0 1-1.173-.59"
      clipRule="evenodd"
    />
  </svg>
);
export default IconCannabis;
