import * as React from "react";
const IconFileLock = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M37.9 33.3h-1.1v-3.1c0-2.8-2.2-5.1-4.8-5.1-2.7 0-4.8 2.3-4.8 5.1v3.1H26c-2.2 0-4 1.8-4 4V45c0 2.2 1.8 4 4 4h11.9c2.2 0 4-1.8 4-4v-7.7c0-2.2-1.7-4-4-4m-8.8-3.1c0-1.7 1.3-3.1 2.8-3.1s2.8 1.4 2.8 3.1v3.1H29v-3.1zM39.9 45c0 1.1-.9 2-2 2H26c-1.1 0-2-.9-2-2v-7.7c0-1.1.9-2 2-2h11.9c1.1 0 2 .9 2 2z" />
    <path d="M58.2 13.6H32.4l-3.1-9C28.7 3 27.2 2 25.6 2H5.8C3.6 2 1.9 3.7 1.9 5.9v52.3c0 2.1 1.7 3.9 3.9 3.9h52.5c2.1 0 3.9-1.7 3.9-3.9V17.5c-.1-2.2-1.8-3.9-4-3.9m1.9 44.5c0 1-.8 1.9-1.9 1.9H5.8c-1 0-1.9-.8-1.9-1.9V5.9c0-1 .8-1.9 1.9-1.9h19.8c.8 0 1.5.5 1.8 1.3l3.3 9.7c.1.4.5.7.9.7h26.6c1 0 1.9.8 1.9 1.9z" />
  </svg>
);
export default IconFileLock;
