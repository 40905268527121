import * as React from "react";
const IconBarrierAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 13.4H6c-2.2 0-4 1.8-4 4v10.2c0 2.2 1.8 4 4 4h6.8v17.2H9.9c-.6 0-1 .4-1 1s.4 1 1 1h7.8c.6 0 1-.4 1-1s-.4-1-1-1h-2.9v-6.4h34.3v6.4h-2.9c-.6 0-1 .4-1 1s.4 1 1 1H54c.6 0 1-.4 1-1s-.4-1-1-1h-2.9V31.6H58c2.2 0 4-1.8 4-4V17.4c0-2.2-1.8-4-4-4M30.9 29.6l4.2-14.2h14l-4.2 14.2zM33 15.4l-4.2 14.2h-14L19 15.4zM4 27.6V17.4c0-1.1.9-2 2-2h11l-4.2 14.2H6c-1.1 0-2-.9-2-2m45.1 12.6H14.8v-8.7h34.3zM60 27.6c0 1.1-.9 2-2 2H47l4.2-14.2H58c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconBarrierAlt;
