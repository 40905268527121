import * as React from "react";
const IconPackageCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.8 21.4 57.1 6.8c-.7-2.2-2.7-3.6-5-3.6H12.4c-2.2 0-4.2 1.4-4.9 3.5L2.3 21.3q-.3.75-.3 1.5v31.4c0 3.7 3 6.7 6.7 6.7h46.7c3.7 0 6.7-3 6.7-6.7V22.7c-.1-.4-.2-.9-.3-1.3M55.2 7.5l4.4 13.7H33v-16h19.1c1.5 0 2.7.9 3.1 2.3M9.4 7.4c.5-1.3 1.7-2.2 3.1-2.2H31v16H4.4zm45.9 51.4H8.7c-2.6 0-4.7-2.1-4.7-4.7v-31h56v31c0 2.6-2.1 4.7-4.7 4.7" />
  </svg>
);
export default IconPackageCopy;
