import * as React from "react";
const IconFirefox = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M43.5 3.7c-1.8-.5-3.7.5-4.2 2.3v.1c-.3 1.3.1 2.7 1.2 3.6l.2.1c2.4 1.6 4.3 5.1 5.4 8.8-.7-.4-1.4-.5-2.2-.4-.9.2-1.7.7-2.1 1.3-.9 1.2-.9 2.8 0 4.1 1.7 2.2 2.3 4.9 1.9 7.6s-1.9 5.1-4.1 6.7c-1.9 1.3-4.1 2-6.3 2h-.1c.2-.4.4-.8.6-1 .2-.3.7-.5 1.2-.8.5-.2 1-.5 1.4-.9.4-.3.7-.5 1-.8s.6-.5.8-.6c.1 0 .2 0 .2-.1.3-.1.8-.2 1.3-.5l.1-.1c1.2-.8 1.7-2.3 1.3-3.7-.3-1.1-1-2-2-2.5-1.1-.6-2.3-.8-3.4-.9-1.5-.1-2.7.1-3.7.5-.8.3-1.4.8-2 1.2-.2.1-.4.2-.5.3-.5.3-1.2.3-2.3.3h-1.6c.3-.2.5-.3.6-.5.6-.6 1-1.2 1.2-2.1.2-.7.6-1.2 1-1.5l4.2-2.8c1.2-.9 1.8-2.3 1.5-3.6-.3-1.5-1.7-2.6-3.3-2.6h-2.5c-1.1 0-1.8-.1-2.3-.2.3-.7 1.1-1.8 2.3-3 1-1 1.3-2.4.8-3.6-.5-1.3-1.8-2.2-3.2-2.2h-.2c-3.3.3-6.3 1.8-8.4 4.3h-3.1l-.1-.1c-.6-1-1-2.2-1.2-3.4a3.4 3.4 0 0 0-3.4-3.2c-1 0-1.9.4-2.5 1.1-2.8 3-3.8 7.2-2.6 11.1C1.1 24.7.5 32.3 2.7 39.4 5.1 47 10.4 53.3 17.6 57c4.3 2.2 9.2 3.4 14 3.4 17 0 30.3-12.5 30.3-28.4v-2.3c.4-15.1-9.3-23.3-18.4-26m14.3 28.4C57.8 45.5 46.5 56 32 56c-4.1 0-8.3-1-12-2.9C13.9 50 9.4 44.7 7.3 38.2c-2-6.3-1.3-13 1.8-18.9l.5-1-.4-1c-.8-1.8-.7-3.7 0-5.4.3.9.7 1.8 1.1 2.6s.9 1.4 1.2 1.9l.8.9 1.2-.1c1.8-.2 3.4-.2 4.8-.1l1.3.1.8-1.1c.6-.9 1.5-1.7 2.4-2.3-1.1 1.6-1.8 3.6-1.1 5.3.7 1.6 2.5 2.5 5.5 2.6l-1.1.7c-1.3.9-2.3 2.3-2.8 3.8-1 0-1.9.3-2.6.7-.2.1-.4.2-.6.4-1 .8-1.7 2-1.8 3.2-.1.9.2 1.9.8 2.5 1.1 1.2 2.8 1.5 4.2 1.6 1.3.1 2.5.1 3.8.1h.3c1.4 0 3 0 4.7-1 .2-.2.5-.3.7-.5.4-.3.7-.5 1.1-.6l.1-.1c.2-.1.6-.2 1.3-.2-.2.2-.4.3-.6.5s-.4.3-.7.5-.6.4-1 .6c-.7.4-1.7.9-2.4 1.7s-1.2 1.8-1.6 2.6c-.2.4-.4.8-.6 1.1-.5.8-.6 1.9-.2 2.8s1.2 1.6 2.2 1.8h.1c.9.1 1.9.3 2.9.2 3.2 0 6.2-1 8.9-2.8 3.2-2.3 5.4-5.8 5.9-9.7.3-1.8.2-3.5-.2-5.2.6.3 1.2.3 1.8.2 1.1-.3 1.9-1.3 1.9-2.5v-.2c-.4-4.6-1.9-10-4.6-14 5.7 3.3 10.6 9.7 10.6 19.6v2.6z" />
  </svg>
);
export default IconFirefox;
