import * as React from "react";
const IconShopify = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M40.401 7.996c1.195-.401 1.997-.401 1.997-.401L40.008 64 4.006 57.199S8.802 20.804 8.802 19.6c0-1.596 0-1.596 1.997-2.398.174 0 .65-.151 1.393-.388.97-.309 2.394-.762 4.205-1.216C17.6 10.402 21.602 0 29.999 0c1.203 0 2.398.393 3.2 1.997h.401c3.601 0 5.598 2.799 6.801 5.999m-11.336 3.638c1.402-.427 2.852-.87 4.134-1.641 0-2.398-.401-4.003-.802-5.607-1.997.802-4.395 3.2-5.598 8.004.715-.284 1.483-.518 2.266-.756M30.8 2.799c-.401-.401-.802-.401-1.203-.401-5.607 0-9.2 7.595-10.402 12.407.802-.2 1.602-.501 2.402-.802.8-.3 1.6-.601 2.402-.802 1.203-6.4 4.403-9.199 6.801-10.402m-4.403 25.993c2.398 0 4.796 1.203 4.796 1.203l1.595-7.194S31.194 22 27.993 22c-8.797 0-12.8 5.598-12.8 11.597 0 4.036 2.268 5.807 4.258 7.363 1.557 1.216 2.945 2.3 2.945 4.233 0 .794-.401 2.398-2.398 2.398-2.8 0-6-2.799-6-2.799l-1.603 5.598s3.2 4.002 9.6 4.002c5.196 0 9.199-4.002 9.199-10 0-4.898-3.301-7.247-5.86-9.068-1.617-1.151-2.938-2.092-2.938-3.332 0-.802 0-3.2 4.002-3.2m7.996-24.406c.4 1.203.802 2.808.802 4.804v.401c.601 0 1.1-.1 1.6-.2.499-.1.998-.2 1.6-.2-.803-2.407-2.006-4.805-4.002-4.805M52.39 11.99c.401 0 .803 0 .803.401 0 .202 1.718 12.13 3.428 23.993 1.69 11.737 3.373 23.411 3.373 23.606l-19.2 4.003L43.19 7.996h.401l3.602 3.592s4.795.401 5.196.401"
      clipRule="evenodd"
    />
  </svg>
);
export default IconShopify;
