import * as React from "react";
const IconMashroom = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.8 14.7C50.3 6.2 41.6 1 32 1S13.7 6.2 9.2 14.7c-1.9 3.5-2.8 7.1-2.9 10.9v.3c0 .3.1.6.2.8 2.3 5 8.5 8.8 16.5 10.5l-1.7 16c-.3 2.5.5 5 2.2 6.9s4.1 2.9 6.6 2.9H34c2.5 0 5-1.1 6.6-3 1.7-1.9 2.5-4.4 2.2-6.9l-1.7-15.9c7.9-1.7 14.1-5.5 16.4-10.5.1-.3.2-.6.2-.9-.1-3.9-1.1-7.7-2.9-11.1M38.9 53.5c.2 1.4-.3 2.8-1.2 3.8-1 1.1-2.3 1.7-3.7 1.7h-3.9c-1.4 0-2.7-.6-3.6-1.6s-1.4-2.4-1.2-3.8L27 37.8q2.4.3 5.1.3c1.8 0 3.5-.1 5.2-.3zM32 34.1c-10 0-19-3.6-21.7-8.7.1-3.1.9-6 2.5-8.9C16.5 9.4 23.9 5 32 5s15.5 4.4 19.2 11.6c1.5 2.8 2.3 5.8 2.4 8.9-2.6 5-11.6 8.6-21.6 8.6" />
    <path d="M31.6 8.9c-.1 0-.2.1-.4.1-.1 0-.2.1-.4.2-.1.1-.2.1-.3.2-.4.4-.6.9-.6 1.4s.2 1 .6 1.4.9.6 1.4.6 1-.2 1.4-.6.6-.9.6-1.4-.2-1-.6-1.4c-.4-.5-1-.7-1.7-.5M20.6 21.7c-.4.4-.6.9-.6 1.4s.2 1.1.6 1.4c.4.4.9.6 1.4.6s1-.2 1.4-.6.6-.9.6-1.4-.2-1-.6-1.4c-.7-.8-2-.8-2.8 0M44.2 22.3c0-.1-.1-.2-.2-.3s-.1-.2-.2-.3c-.5-.5-1.1-.7-1.8-.5-.1 0-.2.1-.4.1-.1.1-.2.1-.3.2s-.2.2-.3.2c-.1.1-.2.2-.2.3-.1.1-.1.2-.2.3s-.1.2-.1.4v.8c0 .1.1.2.1.4 0 .1.1.2.2.4.1.1.1.2.2.3.4.4.9.6 1.4.6s1-.2 1.4-.6c.1-.1.2-.2.2-.3.1-.1.1-.2.2-.4 0-.1.1-.2.1-.4v-.8c0-.1 0-.3-.1-.4" />
  </svg>
);
export default IconMashroom;
