import * as React from "react";
const IconAccidentAndEmergency = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M39 8H9a1 1 0 0 0-1 1v30a1 1 0 0 0 1 1h30a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1M9 6a3 3 0 0 0-3 3v30a3 3 0 0 0 3 3h30a3 3 0 0 0 3-3V9a3 3 0 0 0-3-3z"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M20.489 16.33c-1.643.284-2.84 1.676-3.036 3.332L16 32h16l-1.456-12.355c-.194-1.648-1.38-3.037-3.014-3.323-2.45-.429-4.492-.433-7.041.008m1.241 4.318a1 1 0 0 0-1.984-.248l-.905 7.238a1 1 0 1 0 1.984.248z"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M22.474 18.077a3 3 0 0 1 1.24 2.819l-.904 7.238c-.094.75-.456 1.4-.98 1.866h7.92l-1.192-10.121c-.102-.87-.704-1.47-1.372-1.587-1.671-.292-3.11-.367-4.712-.215m8.07 1.568c-.194-1.648-1.38-3.037-3.014-3.323-2.45-.429-4.492-.433-7.041.008-1.643.284-2.84 1.676-3.036 3.332L16 32h16zm-8.814 1.003-.905 7.238a1 1 0 1 1-1.984-.248l.905-7.238a1 1 0 1 1 1.984.248M33 36H15v-2h18z"
      clipRule="evenodd"
    />
    <path
      
      d="M13 24q0 .505.045 1H10v-2h3.045Q13 23.495 13 24M15.012 17.656a11 11 0 0 0-1.001 1.732l-2.635-1.522 1-1.732zM19.388 14.01q-.916.426-1.732 1.002l-1.522-2.636 1.732-1zM24 13q-.505 0-1 .045V10h2v3.045A11 11 0 0 0 24 13M30.344 15.012a11 11 0 0 0-1.732-1.001l1.522-2.635 1.732 1zM33.99 19.388a11 11 0 0 0-1.002-1.732l2.636-1.522 1 1.732zM35 24q0-.505-.045-1H38v2h-3.045q.045-.495.045-1"
    />
  </svg>
);
export default IconAccidentAndEmergency;
