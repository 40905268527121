import * as React from "react";
const IconComb3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 6c-3.2-3.2-7.9-4.6-13.1-3.8S34.6 5.6 30.5 9.8c-6.2 6.1-8.8 14.2-7.4 20.7L3.9 47.8C2.7 48.9 2 50.4 2 52s.6 3.1 1.7 4.3l4 4c1.1 1.1 2.6 1.7 4.2 1.7h.1c1.6 0 3.1-.7 4.2-1.9L33.5 41c1.1.2 2.3.4 3.5.4 5.7 0 12.1-2.7 17.2-7.8 4.1-4.1 6.8-9.3 7.6-14.4.8-5.3-.6-10-3.8-13.2M14.7 58.7c-.7.8-1.7 1.3-2.8 1.3s-2.1-.4-2.8-1.1l-4-4c-.7-.8-1.1-1.8-1.1-2.8 0-1.1.5-2.1 1.3-2.8l18.4-16.7c.7 1.8 1.6 3.4 3 4.7 1.3 1.3 2.9 2.3 4.7 3zm45.1-39.9c-.7 4.7-3.2 9.4-7 13.3-7.9 7.9-18.9 9.6-24.7 3.8S24 19.1 31.9 11.2c3.8-3.8 8.6-6.3 13.3-7 .8-.1 1.7-.2 2.5-.2 3.6 0 6.7 1.2 8.9 3.4 2.7 2.8 3.9 6.8 3.2 11.4" />
    <circle cx={44.8} cy={12.4} r={2.4} />
    <path d="M49.9 17.4c-1 1-1 2.5 0 3.5s2.5 1 3.5 0 1-2.5 0-3.5c-1-.9-2.5-.9-3.5 0" />
    <circle cx={39.2} cy={18} r={2.4} />
    <path d="M44.2 23.1c-1 1-1 2.5 0 3.5s2.5 1 3.5 0 1-2.5 0-3.5-2.5-1-3.5 0" />
    <circle cx={33.5} cy={23.7} r={2.4} />
    <circle cx={40.3} cy={30.5} r={2.4} />
  </svg>
);
export default IconComb3;
