import * as React from "react";
const IconLolipop = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.9 21.9C51.9 10.9 43 2 32 2s-19.9 8.9-19.9 19.9c0 10.7 8.4 19.4 19 19.9V61c0 .6.4 1 1 1s1-.4 1-1V41.8c10.5-.5 18.8-9.2 18.8-19.9M32 39.9c-9.9 0-17.9-8.1-17.9-17.9S22.1 4 32 4s17.9 8.1 17.9 17.9-8 18-17.9 18" />
    <path d="M32 13c-4.9 0-8.9 4-8.9 8.9s4 8.9 8.9 8.9 8.9-4 8.9-8.9-4-8.9-8.9-8.9m0 15.9c-3.8 0-6.9-3.1-6.9-6.9s3.1-6.9 6.9-6.9 6.9 3.1 6.9 6.9-3.1 6.9-6.9 6.9" />
  </svg>
);
export default IconLolipop;
