import * as React from "react";
const IconDrilling = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.8 10.5h-6.5v-.9c0-4.2-3.4-7.6-7.6-7.6H23.3c-4.2 0-7.6 3.4-7.6 7.6v.9H9.2c-.6 0-1 .4-1 1s.4 1 1 1h6.5v.9c0 3.9 3 7.2 6.8 7.6v2.2c0 4.9 3.7 8.9 8.5 9.5v7.2l-3.9 1.9c-.5.2-.7.8-.4 1.3q.3.6.9.6c.1 0 .3 0 .4-.1l3-1.5v5.3l-3.9 1.9c-.5.2-.7.8-.4 1.3q.3.6.9.6c.1 0 .3 0 .4-.1l3-1.5v5.3l-3.9 1.9c-.5.2-.7.8-.4 1.3q.3.6.9.6c.1 0 .3 0 .4-.1l3-1.5V61c0 .6.4 1 1 1s1-.4 1-1v-4.9l4-2c.5-.2.7-.8.4-1.3-.2-.5-.8-.7-1.3-.4L33 53.8v-5.3l4-2c.5-.2.7-.8.4-1.3-.2-.5-.8-.7-1.3-.4L33 46.3V41l4-2c.5-.2.7-.8.4-1.3-.2-.5-.8-.7-1.3-.4L33 38.8v-6.2c4.8-.5 8.6-4.5 8.6-9.5V21c3.8-.4 6.8-3.6 6.8-7.6v-.9h6.5c.6 0 1-.4 1-1s-.6-1-1.1-1M32 30.7c-4.1 0-7.5-3.4-7.5-7.5V21h15v2.1c0 4.2-3.4 7.6-7.5 7.6m14.3-17.3c0 3.1-2.5 5.6-5.6 5.6H23.3c-3.1 0-5.6-2.5-5.6-5.6V9.6c0-3.1 2.5-5.6 5.6-5.6h17.4c3.1 0 5.6 2.5 5.6 5.6z" />
    <path d="M36.4 10.5h-8.7c-.6 0-1 .4-1 1s.4 1 1 1h8.7c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconDrilling;
