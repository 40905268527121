import * as React from "react";
const IconTestTubeAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m57.9 41.4-32.2-33q-.3-.3 0-.6l4-4.1c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-4 4.1c-.9.9-.9 2.4 0 3.4l22.2 22.7c-2.2 2.5-7 7.1-10.1 6.2-4.1-1.1-7 1.8-8.3 3.4l-17.7-18c-.5-.5-1.1-.7-1.7-.7-.7 0-1.3.3-1.7.7l-4 4.1c-.4.4-.4 1 0 1.4s1 .4 1.4 0l4-4.1c.1-.1.2-.1.3-.1s.2 0 .3.1l32.2 33c2.2 2.3 5.2 3.5 8.4 3.5s6.2-1.3 8.4-3.5c4.5-4.7 4.5-12.4-.1-17.1m-1.4 15.7c-1.9 1.9-4.4 2.9-7 2.9s-5.1-1-7-2.9L29.4 43.6c.9-1.2 3.3-3.8 6.4-3 4.4 1.2 10-4.4 12-6.7l8.6 8.8c3.9 4 3.9 10.4.1 14.4" />
  </svg>
);
export default IconTestTubeAlt;
