import * as React from "react";
const IconMetaverse6 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 65"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 19.6c-.6-5.8-15.2-9-29-9s-28.4 3.1-29 9V45c0 5.2 6.8 9.3 15.5 9.3.6 0 1-.4 1-1V41.7c9.7-1.5 18.7-.8 24.9.3v11.4c0 .6.4 1 1 1 8.7 0 15.5-4.1 15.5-9.3V19.8c.1-.1.1-.1.1-.2M17.5 52.3C10.6 52 5 48.8 5 45V21.8c3.1 2.1 7.6 4 12.5 4.3zm27.9-28.2c-.5 0-1 .5-1 1v14.8c-3.7-.6-8.4-1.1-13.6-1.1-3.6 0-7.4.2-11.4.8V25.1c0-.5-.4-1-1-1C13 24 8.1 21.7 5.1 19.4 6.2 16.1 17 12.6 32 12.6s25.8 3.4 26.9 6.8c-3 2.3-7.9 4.6-13.5 4.7M59 45c0 3.8-5.6 7-12.5 7.3V26.1c5-.3 9.5-2.3 12.5-4.3z" />
    <path d="M38.8 24.9 29 18.2c-.3-.2-.7-.2-1-.1-.3.2-.5.5-.5.9v13.4c0 .4.2.7.5.9.1.1.3.1.5.1s.4-.1.6-.2l9.8-6.7c.3-.2.4-.5.4-.8s-.2-.6-.5-.8m-9.4 5.6v-9.6l7.1 4.8z" />
  </svg>
);
export default IconMetaverse6;
