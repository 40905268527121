import * as React from "react";
const IconSock = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m48.5 42.2-7.9-10.3c-.6-.8-.9-1.7-.9-2.6v-21c0-3.5-2.8-6.3-6.3-6.3H19.2c-3.5 0-6.3 2.8-6.3 6.3v26.3c0 1.4.5 2.8 1.4 3.9l15 18.8c2.3 3 5.8 4.6 9.6 4.6 4.7 0 8.9-2.6 11-6.9 2-4.1 1.5-9-1.4-12.8M19.2 4h14.2c2.4 0 4.3 1.9 4.3 4.3V14H14.9V8.3c0-2.4 1.9-4.3 4.3-4.3M48 54.3c-1.8 3.6-5.2 5.7-9.2 5.7-3.2 0-6.1-1.4-8-3.9l-15-18.8q-.9-1.2-.9-2.7V16h22.8v13.3c0 1.4.5 2.8 1.3 3.9l8 10.2c2.4 3.2 2.8 7.3 1 10.9" />
  </svg>
);
export default IconSock;
