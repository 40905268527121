import * as React from "react";
const IconLeftArrowBox = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56 3H8C5.2 3 3 5.2 3 8v48c0 2.8 2.2 5 5 5h48c2.8 0 5-2.2 5-5V8c0-2.8-2.2-5-5-5m3 53c0 1.7-1.3 3-3 3H8c-1.7 0-3-1.3-3-3V8c0-1.7 1.3-3 3-3h48c1.7 0 3 1.3 3 3z" />
    <path d="M43.7 31H22.5l6.8-8.1c.4-.4.3-1.1-.1-1.4-.4-.4-1.1-.3-1.4.1l-8.2 9.7c-.3.4-.3.9 0 1.3l8.2 9.7c.2.2.5.4.8.4.2 0 .5-.1.6-.2.4-.4.5-1 .1-1.4L22.5 33h21.2c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconLeftArrowBox;
