import * as React from "react";
const IconVote = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 65 65"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.2 31.8-6-6c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l5.2 5.3-26.5 26.6L5.9 32.5 32.5 5.9l12.9 12.9c.4.4 1 .4 1.4 0s.4-1 0-1.4L33.2 3.8c-.4-.4-1-.4-1.4 0l-28 28c-.4.4-.4 1 0 1.4l28 28c.2.2.5.3.7.3s.5-.1.7-.3l28-28c.4-.4.4-1 0-1.4" />
    <path d="M25.2 33.5c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l7.4 7.4c.2.2.4.3.7.3s.5-.1.7-.3L58.1 16c.4-.4.4-1 0-1.4s-1-.4-1.4 0L31.9 40.2z" />
  </svg>
);
export default IconVote;
