import * as React from "react";
const IconArrowsShrinkV = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M42.8 21.3c.3 0 .6-.1.8-.4.4-.4.3-1.1-.1-1.4l-10.8-9.1c-.4-.3-.9-.3-1.3 0l-10.8 9.1c-.4.4-.5 1-.1 1.4s1 .5 1.4.1l9.2-7.7v37.2l-9.2-7.7c-.4-.4-1.1-.3-1.4.1s-.3 1.1.1 1.4l10.8 9.1c.2.2.4.2.6.2s.5-.1.6-.2l10.8-9.1c.4-.4.5-1 .1-1.4s-1-.5-1.4-.1L33 50.6V13.4l9.2 7.7c.1.2.4.2.6.2M20.1 5h23.7c.6 0 1-.4 1-1s-.4-1-1-1H20.1c-.6 0-1 .4-1 1s.5 1 1 1M43.9 59H20.1c-.6 0-1 .4-1 1s.4 1 1 1h23.7c.6 0 1-.4 1-1s-.4-1-.9-1" />
  </svg>
);
export default IconArrowsShrinkV;
