import * as React from "react";
const IconCookies = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.7 34.3c-4.7-1.3-8.4-5-9.6-9.7L42 24l-.6-.1c-4.8-1.2-8.5-5-9.7-9.7l-.1-.5-9.6-3-.3.1c-4.6 1.7-8.6 4.6-11.7 8.5-7.3 9.3-7.1 22.8.3 32 4.7 5.8 11.4 9.2 18.8 9.6h1.4c6.8 0 13.4-2.7 18.3-7.6 2.7-2.7 4.7-5.9 6-9.5l.1-.3-2.6-9.3h-.6zM47.4 52c-4.8 4.8-11.4 7.3-18.1 7-6.8-.4-13-3.5-17.4-8.9-6.9-8.5-7-20.9-.3-29.5 2.7-3.5 6.4-6.2 10.5-7.8l7.8 2.4c1.5 5 5.4 9 10.5 10.5 1.5 5 5.4 8.9 10.5 10.5l2 7.4c-1.2 3.1-3.1 6-5.5 8.4" />
    <path d="M19.4 27.7c-2.5 0-4.6 2.1-4.6 4.6s2.1 4.6 4.6 4.6 4.6-2.1 4.6-4.6-2.1-4.6-4.6-4.6m0 7.2c-1.4 0-2.6-1.2-2.6-2.6s1.2-2.6 2.6-2.6 2.6 1.2 2.6 2.6-1.2 2.6-2.6 2.6M37.4 39.1c-1.7 0-3.2 1.4-3.2 3.2s1.4 3.2 3.2 3.2 3.2-1.4 3.2-3.2-1.4-3.2-3.2-3.2m0 4.3c-.6 0-1.2-.5-1.2-1.2s.5-1.2 1.2-1.2 1.2.5 1.2 1.2-.5 1.2-1.2 1.2M40.1 10.3c2 0 3.7-1.6 3.7-3.7S42.1 3 40.1 3s-3.7 1.6-3.7 3.7 1.6 3.6 3.7 3.6m0-5.3c.9 0 1.7.7 1.7 1.7s-.8 1.6-1.7 1.6-1.7-.7-1.7-1.7.8-1.6 1.7-1.6M53 21.4c-2 0-3.7 1.6-3.7 3.7s1.6 3.7 3.7 3.7 3.7-1.6 3.7-3.7-1.7-3.7-3.7-3.7m0 5.4c-.9 0-1.7-.7-1.7-1.7s.7-1.7 1.7-1.7 1.7.7 1.7 1.7-.8 1.7-1.7 1.7M55.7 6.4C53.7 6.4 52 8 52 10s1.6 3.7 3.7 3.7c2 0 3.7-1.6 3.7-3.7s-1.7-3.6-3.7-3.6m0 5.3c-.9 0-1.7-.7-1.7-1.7s.7-1.7 1.7-1.7c.9 0 1.7.7 1.7 1.7s-.8 1.7-1.7 1.7" />
  </svg>
);
export default IconCookies;
