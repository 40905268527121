import * as React from "react";
const IconEthereum = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 65 64"
    {...props}
  >
    <path  d="M14.86 32.556 32.852 2l17.996 30.556-17.996 10.887z" />
    <path  d="M14.86 36.05 32.852 62l18.006-25.95-18.006 10.88z" />
  </svg>
);
export default IconEthereum;
