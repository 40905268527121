import * as React from "react";
const IconUserIdea = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M33.8 29.2c.3-.5.2-1.1-.3-1.4-2.7-1.8-5.8-2.8-8.9-3.1 5.4-.7 9.6-5.3 9.6-10.9 0-6.1-4.9-11-11-11s-11 4.9-11 11c0 5.6 4.2 10.2 9.6 10.9-5.1.4-9.9 2.9-13.4 7.1-3.8 4.6-6 10.9-6.2 17.9q0 .6.6.9c2.5 1.2 11.2 5.2 20.5 5.2 1 0 2.1 0 3.2-.1.6 0 1-.5.9-1.1 0-.5-.5-1-1.1-.9-1 .1-2 .1-3 .1-8.2 0-16.1-3.3-19-4.7.2-6.3 2.2-11.9 5.7-16 3.5-4.2 8.3-6.5 13.4-6.5 3.3 0 6.5 1 9.3 2.8.1.4.8.3 1.1-.2M14 13.8c0-5 4.1-9 9-9s9 4.1 9 9-4.1 9-9 9-9-4-9-9M49.6 37.8c-1.9-1.9-4.5-2.8-7.2-2.7-5 .3-9 4.6-9 9.6 0 3.3 1.5 6.3 4.1 8.1v4.6c0 2 1.6 3.7 3.7 3.7h3.7c2 0 3.7-1.7 3.7-3.7V53c2.6-1.8 4.2-4.8 4.2-8-.3-2.8-1.3-5.3-3.2-7.2m-4.9 21.4H41c-.9 0-1.7-.8-1.7-1.7v-4h7v4c.1 1-.7 1.7-1.6 1.7m2.2-7.6H39c-2.3-1.4-3.6-3.9-3.6-6.7 0-4 3.2-7.4 7.2-7.7h.4c2 0 3.9.7 5.3 2.1 1.5 1.5 2.4 3.5 2.4 5.6-.2 2.8-1.5 5.2-3.8 6.7M42.9 31.1c.6 0 1-.4 1-1v-2c0-.6-.4-1-1-1s-1 .4-1 1v2c0 .6.4 1 1 1M56.1 33.4l-1.7 1.7c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3l1.7-1.7c.4-.4.4-1 0-1.4-.4-.3-1-.3-1.4 0" />
    <path d="M29.6 33.4c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l1.7 1.7c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4zM26.7 46.5h-2c-.6 0-1 .4-1 1s.4 1 1 1h2c.6 0 1-.4 1-1s-.5-1-1-1M61 46.5h-2c-.6 0-1 .4-1 1s.4 1 1 1h2c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconUserIdea;
