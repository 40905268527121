import * as React from "react";
const IconGraphAlt4 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 60h-9.2V28.9c0-.6-.4-1-1-1s-1 .4-1 1V60H35V38.7c0-.6-.4-1-1-1s-1 .4-1 1V60H17.6V48.6c0-.6-.4-1-1-1s-1 .4-1 1V60H8.5C6 60 4 58 4 55.5V3c0-.6-.4-1-1-1s-1 .4-1 1v52.5C2 59.1 4.9 62 8.5 62H61c.6 0 1-.4 1-1s-.4-1-1-1" />
    <path d="M16 38c3.6 0 6.5-2.9 6.5-6.5 0-1.1-.3-2.1-.7-2.9l5.5-3.5.3-.3c1.2 1.5 3 2.4 5 2.4 3.6 0 6.5-2.9 6.5-6.5q0-1.5-.6-2.7l7.2-4c1.2 1.5 3 2.5 5.1 2.5 3.6 0 6.5-2.9 6.5-6.5s-2.9-6.5-6.5-6.5-6.5 2.9-6.5 6.5c0 .8.1 1.5.4 2.2l-7.3 4c-1.2-1.3-2.9-2.1-4.8-2.1-3.6 0-6.5 2.9-6.5 6.5 0 .9.2 1.8.5 2.6-.2 0-.3 0-.4.1L20.6 27a6.42 6.42 0 0 0-4.6-1.9c-3.6 0-6.5 2.9-6.5 6.5s3 6.4 6.5 6.4M50.8 5.6c2.5 0 4.5 2 4.5 4.5s-2 4.5-4.5 4.5-4.5-2-4.5-4.5 2.1-4.5 4.5-4.5M32.7 16.2c2.5 0 4.5 2 4.5 4.5s-2 4.5-4.5 4.5-4.5-2-4.5-4.5 2-4.5 4.5-4.5M16 27.1c2.5 0 4.5 2 4.5 4.5s-2 4.5-4.5 4.5-4.5-2-4.5-4.5 2.1-4.5 4.5-4.5" />
  </svg>
);
export default IconGraphAlt4;
