import * as React from "react";
const IconBw = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 513 342"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 0h513v342H0z" />
    <path  d="M0 238h513v104H0zM0 0h513v104H0z" />
    <path d="M0 125.5h513v89.656H0z" />
  </svg>
);
export default IconBw;
