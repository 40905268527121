import React, { useState } from 'react';
import { BringzzButton, BringzzText } from "@bringzz/components";
import { useNavigation } from 'context/NavigationContext';
import {
    BringzzPageContainer,
    BringzzIcon,
    BringzzRadio,
    BringzzBottomDrawer,
    BringzzRadioList
} from "@bringzz/components";
import { useLocation } from 'react-router-dom';
import useRequest from 'hooks/useRequest';
import { useAuth } from 'context/AuthContext';

let state = {
    checked: (
        <div></div>
    ),
    unChecked: (
        <div></div>
    ),
};

const ConsumerPicturePage = () => {
    const { backPress } = useNavigation();
    const { navigate } = useNavigation();
    const { user, refreshUserData } = useAuth()

    const [pickerDrawerOpened, setPickerDrawerOpened] = useState(false);
    const [profileImage, setProfileImage] = useState(user.userPic || null);

    const { sendRequest, data, error, loading } = useRequest();

    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setProfileImage(reader.result);
            postImage(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const postImage = async (base64Image) => {
        // Post base64Image to the server (this is a placeholder function)
        const result = await sendRequest({
            method: 'POST',
            url: `/module/profile/changeProfilePicture`,
            data: {
                base64Image
            }
        })
            .then(response => {
                if (response.status === 'success') {
                    refreshUserData()
                    return response
                };
            })
            .catch(error => {
                console.error(error);
                return false;
            });

        // TODO: Handle correctly
    };

    return (
        <BringzzPageContainer
            className="bg-white"
            steps={true}
            activeStep={2}
            header={{
                left: (
                    <BringzzIcon
                        size='22'
                        icon='IconChevronLeft'
                        folder='LineIcons'
                        className='cursor-pointer'
                        onClick={backPress}
                    />
                ),
                title: "Join Us",
                right: <BringzzButton size='sm' className="border border-black" onClick={() => {
                    navigate(`/register/consumer/insurance`);
                }}>Skip</BringzzButton>
            }}
            footer={
                <div className='pb-8 px-6'>
                    <BringzzButton
                        size='lg'
                        className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
                        onClick={() => {
                            navigate(`/register/consumer/insurance`);
                        }}
                        disabled={!profileImage || loading || error}
                    >
                        Continue
                    </BringzzButton>
                </div>
            }
            authenticated={false}
        >
            <div className='relative flex justify-center bg-magic-lilac/20'>
                <div className='relative bg-white w-full bg-cover bg-center flex flex-col'>
                    {/* Content */}
                    <div className='flex-grow flex flex-col py-8 px-4'>
                        <div className='z-10 flex flex-col '>
                            <div className='pl-2 flex flex-col space-y-6 md:max-w-lg lg:max-w-xl xl:max-w-2xl'>
                                <BringzzText tag='h1' className='pl-2 text-midnight-blue font-bold'>
                                    <div className='flex flex-col'><div>Choose</div><div>your Profile Picture</div></div>
                                </BringzzText>
                            </div>
                            <div className='space-y-6 mt-12 flex justify-center'>
                                <div className='space-y-4 relative'>
                                    {profileImage ? (
                                        <img src={profileImage} alt="Profile" className='rounded-full border-8 border-magic-lilac h-48 w-48 object-contain' />
                                    ) : (
                                        <div className='flex justify-center border-8 border-magic-lilac text-white font-bold text-4xl items-center rounded-full bg-midnight-blue h-48 w-48'>P</div>
                                    )}
                                    <div className='bg-magic-lilac h-12 w-12 absolute bottom-0 rounded-full right-2' onClick={() => setPickerDrawerOpened(true)}>
                                        <BringzzIcon
                                            size='24'
                                            icon='IconPlus'
                                            folder='LineIcons'
                                            className='cursor-pointer flex h-full flex-col items-center justify-center'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <BringzzBottomDrawer isOpen={pickerDrawerOpened} title={"Upload your Profile Picture"} close={() => setPickerDrawerOpened(false)}>
                        <div className='px-4 py-6'>
                            <BringzzRadioList
                                state={state}
                                className="rounded-xl w-full text-center"
                                onChange={() => setPickerDrawerOpened(false)}
                            >
                                <BringzzRadio value="Camera"
                                    className="p-2 py-4 hover:bg-slate- border-1 border-t border-gray-400 flex"
                                    onClick={() => {
                                        setPickerDrawerOpened(false);
                                        document.getElementById('fileInput').click();
                                    }}
                                >
                                    <div>
                                        <BringzzText tag='h3'>Camera</BringzzText>
                                    </div>
                                </BringzzRadio>
                                <BringzzRadio value="Photos"
                                    className="p-2 py-4 hover:bg-slate- border-1 border-t border-gray-400"
                                    onClick={() => {
                                        setPickerDrawerOpened(false);
                                        document.getElementById('fileInput').click();
                                    }}
                                >
                                    <div>
                                        <BringzzText tag='h3'>Photos</BringzzText>

                                    </div>
                                </BringzzRadio>
                                <BringzzRadio className="p-2 py-4 hover:bg-slate- border-1 border-t border-gray-400" value="File"
                                    onClick={() => {
                                        setPickerDrawerOpened(false);
                                        document.getElementById('fileInput').click();
                                    }}
                                >
                                    <div>
                                        <BringzzText tag='h3'>File</BringzzText>
                                    </div>
                                </BringzzRadio>
                            </BringzzRadioList>
                            <input
                                type="file"
                                id="fileInput"
                                accept="image/*"
                                style={{ display: 'none' }}
                                onChange={handleFileSelect}
                            />
                        </div>
                    </BringzzBottomDrawer>
                </div>
            </div>
        </BringzzPageContainer>
    );
};

export default ConsumerPicturePage;
