import * as React from "react";
const IconMobileCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <circle cx={32} cy={51.8} r={2.5} />
    <path d="M35 10h-6c-.6 0-1 .4-1 1s.4 1 1 1h6c.6 0 1-.4 1-1s-.5-1-1-1" />
    <path d="M45 2H19c-2.8 0-5 2.2-5 5v50c0 2.8 2.2 5 5 5h26c2.8 0 5-2.2 5-5V7c0-2.8-2.2-5-5-5m3 55c0 1.7-1.3 3-3 3H19c-1.7 0-3-1.3-3-3V7c0-1.7 1.3-3 3-3h26c1.7 0 3 1.3 3 3z" />
  </svg>
);
export default IconMobileCopy;
