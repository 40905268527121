import * as React from "react";
const IconElegantTable3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 30.9H42.9V13.1c0-2.9-2.4-5.2-5.2-5.2H26.4c-2.9 0-5.2 2.4-5.2 5.2v17.8H3c-.6 0-1 .4-1 1s.4 1 1 1h5v22.3c0 .6.4 1 1 1s1-.4 1-1V33.4l7.8 4.7v11.7c0 .6.4 1 1 1s1-.4 1-1V39.3l13.8 8.3c.8.5 1.8.7 2.7.7 1.2 0 2.4-.4 3.4-1.2l4.7-3.9v6.6c0 .6.4 1 1 1s1-.4 1-1v-8.3L54 35v20.2c0 .6.4 1 1 1s1-.4 1-1V33.3l.5-.5H61c.6 0 1-.4 1-1 0-.5-.4-.9-1-.9M23.1 13.1c0-1.8 1.5-3.2 3.2-3.2h11.3c1.8 0 3.2 1.5 3.2 3.2v17.8h-7.9c0-.1.1-.2.1-.3V20.3c0-.6-.4-1-1-1s-1 .4-1 1v10.2c0 .1 0 .2.1.3h-7.9V13.1zm15.2 32.5c-1.1.9-2.6 1-3.8.3l-21.6-13h40.5z" />
  </svg>
);
export default IconElegantTable3;
