import * as React from "react";
const IconBookshelf = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M52 2H12C9.8 2 8 3.8 8 6v52c0 2.2 1.8 4 4 4h40c2.2 0 4-1.8 4-4V6c0-2.2-1.8-4-4-4m2 4v36.5h-7.7l3.7-9.9c.2-.5-.1-1.1-.6-1.3s-1.1.1-1.3.6l-3.9 10.3v.3h-5.9l4.8-11.9c.2-.5 0-1.1-.6-1.3-.5-.2-1.1 0-1.3.6l-5 12.5v.2H33V4h19c1.1 0 2 .9 2 2M10 25.4h21v17H10zM12 4h19v19.4h-5.9V12.6c0-.6-.4-1-1-1s-1 .4-1 1v10.9h-5.3V12.6c0-.6-.4-1-1-1s-1 .4-1 1v10.9H10V6c0-1.1.9-2 2-2m-2 54V44.5h21V60H12c-1.1 0-2-.9-2-2m42 2H33V44.5h21V58c0 1.1-.9 2-2 2" />
    <path d="M25 51.3c0-.6-.4-1-1-1s-1 .5-1 1v2.6c0 .6.4 1 1 1s1-.5 1-1zM40 50.3c-.6 0-1 .5-1 1v2.6c0 .6.4 1 1 1s1-.5 1-1v-2.6c0-.6-.5-1-1-1" />
  </svg>
);
export default IconBookshelf;
