import * as React from "react";
const IconTrowelAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M11.5 62.3c-1.5 0-2.9-.6-4-1.7-1.6-1.6-2-3.9-1.2-6l10.6-27c.7-1.8 2.2-3.1 4.1-3.4 1.9-.4 3.8.2 5.1 1.6l6.7 6.9v-6.4c0-.3.1-.5.3-.7l6.1-6.1-1.3-1.3c-.8-.8-1.2-1.8-1.2-2.8s.4-2.1 1.2-2.8L47.5 3c.8-.8 1.8-1.2 2.8-1.2s2.1.4 2.8 1.2l3.6 3.6c1.6 1.6 1.6 4.1 0 5.7l-9.6 9.6c-1.6 1.6-4.1 1.6-5.7 0l-.9-.9-5.9 5.9V35l7.9 8.1c1.3 1.4 1.9 3.3 1.5 5.1-.4 1.9-1.7 3.4-3.5 4l-27 9.7c-.6.3-1.3.4-2 .4m10.7-36.2c-.2 0-.5 0-.7.1-1.2.2-2.2 1.1-2.6 2.2l-10.6 27c-.6 1.6 0 3 .8 3.8s2.2 1.4 3.8.9L40.1 50c1.1-.4 2-1.4 2.3-2.6s-.1-2.4-.9-3.3l-7.6-7.8-4.9 4.8c-.4.4-1 .4-1.4 0s-.4-1 0-1.4l4.8-4.8-7.6-7.8c-.7-.6-1.7-1-2.6-1M49.1 4.3l-9.6 9.6c-.4.4-.6.9-.6 1.4s.2 1 .6 1.4l3.6 3.6c.8.8 2 .8 2.8 0l9.6-9.6c.8-.8.8-2 0-2.8l-3.6-3.6c-.8-.8-2.1-.8-2.8 0" />
  </svg>
);
export default IconTrowelAlt1;
