import * as React from "react";
const IconBellWedding = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m53.3 52.7-3.2-6.5c-.1-.2-.1-.4-.1-.5V29.4c0-5.3-2.4-10.1-6.1-13.4.3 0 .6-.1.9-.2 4.6-1.3 7.5-5.4 6.4-9.1s-5.7-5.6-10.3-4.3c-3.6 1-7.5 6.3-8.9 8.3-1.4-2.1-5.4-7.3-8.9-8.3-4.6-1.3-9.2.6-10.3 4.3s1.8 7.8 6.4 9.1c.3.1.6.1.9.2-3.7 3.3-6.1 8.1-6.1 13.4v16.1l-3.4 7.2c-.3.6-.2 1.3.1 1.9.4.6 1 .9 1.7.9H24c.8 3.7 4 6.5 7.9 6.5s7.2-2.8 7.9-6.5h11.6c.7 0 1.3-.4 1.7-.9.5-.6.5-1.3.2-1.9M41.5 4.3c3.5-1 7 .3 7.8 2.9s-1.5 5.6-5.1 6.6c-.8.2-1.8.2-3.1 0q-3.3-1.95-7.2-2.4c1.7-2.3 5-6.3 7.6-7.1m-21.7 9.6c-3.5-1-5.8-4-5.1-6.6.8-2.6 4.3-3.9 7.8-2.9 2.6.7 5.9 4.7 7.6 7.2-2.6.3-5 1.1-7.1 2.4-1.3.1-2.4.1-3.2-.1M32 60c-2.8 0-5.1-1.9-5.9-4.5h11.7c-.7 2.6-3 4.5-5.8 4.5m-19.5-6.5 3.6-7.6V29.4c0-8.8 7.2-16 16-16s16 7.2 16 16v16.3c0 .1 0 .7.3 1.4l3.2 6.5z" />
  </svg>
);
export default IconBellWedding;
