import * as React from "react";
const IconButterfly = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M45.2 15.4c-5.2 0-9.8 2.8-12.2 7V12.1C33 7.6 36.6 4 41.1 4h9.6c.6 0 1-.4 1-1s-.4-1-1-1h-9.6c-4 0-7.5 2.4-9.1 5.8C30.4 4.4 26.9 2 22.9 2h-9.6c-.6 0-1 .4-1 1s.4 1 1 1h9.6c4.5 0 8.1 3.6 8.1 8.1v10.2c-2.5-4.2-7-7-12.2-7-7.8 0-14.2 6.4-14.2 14.2 0 5.1 2.7 9.7 7 12.3v9.4c0 5.9 4.8 10.7 10.7 10.7 4.3 0 8-2.6 9.7-6.2 1.7 3.7 5.4 6.2 9.7 6.2 5.9 0 10.7-4.8 10.7-10.7v-9.4c4.3-2.6 7-7.2 7-12.3.1-7.7-6.3-14.1-14.2-14.1M22.3 60c-4.8 0-8.7-3.9-8.7-8.7v-10c0-.4-.2-.7-.5-.9-4-2.1-6.5-6.3-6.5-10.8 0-6.8 5.5-12.2 12.2-12.2 6.8 0 12.2 5.5 12.2 12.2v21.7c0 4.8-3.9 8.7-8.7 8.7M51 40.4c-.3.2-.5.5-.5.9v10c0 4.8-3.9 8.7-8.7 8.7s-8.7-3.9-8.7-8.7V29.6c0-6.8 5.5-12.2 12.2-12.2s12.2 5.5 12.2 12.2c0 4.5-2.5 8.7-6.5 10.8" />
  </svg>
);
export default IconButterfly;
