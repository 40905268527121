import * as React from "react";
const IconGl = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 513 342"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 0h513v342H0z" />
    <path  d="M513 0v171H0V0z" />
    <circle cx={185.8} cy={171.2} r={117.8}  />
    <path
      
      d="M68 171c0-65.1 52.8-117.8 117.8-117.8 65.1 0 117.8 52.8 117.8 117.8"
    />
  </svg>
);
export default IconGl;
