import * as React from "react";
const IconMoneyBag = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 13H7c-2.8 0-5 2.2-5 5v28c0 2.8 2.2 5 5 5h50c2.8 0 5-2.2 5-5V18c0-2.8-2.2-5-5-5m3 25.9H48.9c-1 0-1.8-.8-1.8-1.8V26.9c0-1 .8-1.8 1.8-1.8H60zM57 49H7c-1.7 0-3-1.3-3-3V18c0-1.7 1.3-3 3-3h50c1.7 0 3 1.3 3 3v5.1H48.9c-2.1 0-3.8 1.7-3.8 3.8v10.3c0 2.1 1.7 3.8 3.8 3.8H60v5c0 1.7-1.3 3-3 3" />
    <path d="M54.1 34c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2c.1 1.1 1 2 2 2" />
  </svg>
);
export default IconMoneyBag;
