import * as React from "react";
const IconCarAlt3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m58.9 19.7-.6-.6c-2-2-4.7-3.1-7.5-3.1H35.4c-7.3 0-14.1 2.8-19.3 8l-.4.4q-.75.75-1.8.9l-3.9.5c-4.5.6-8 4.6-8 9.2v2.4c0 .6.4 1 1 1h7.9c-.3.8-.5 1.7-.5 2.6 0 3.8 3.1 7 7 7 3.8 0 7-3.1 7-7 0-.9-.2-1.8-.5-2.6h17.3c-.3.8-.5 1.7-.5 2.6 0 3.8 3.1 7 7 7s7-3.1 7-7c0-.9-.2-1.8-.5-2.6h2.7c2.8 0 5.1-2.3 5.1-5.1v-6c0-2.9-1.1-5.5-3.1-7.6M17.4 46c-2.7 0-5-2.2-5-5s2.2-5 5-5 5 2.2 5 5-2.3 5-5 5m30.3 0c-2.7 0-5-2.2-5-5s2.2-5 5-5 5 2.2 5 5-2.3 5-5 5M60 33.3c0 1.7-1.4 3.1-3.1 3.1H53C51.6 35 49.8 34 47.7 34s-4 .9-5.3 2.4H22.6C21.4 35 19.5 34 17.4 34s-4 .9-5.3 2.4H4V35c0-3.6 2.7-6.8 6.3-7.2l3.9-.5c1.1-.1 2.2-.7 3-1.5l.4-.4c4.8-4.8 11.1-7.4 17.8-7.4h15.3c2.3 0 4.5.9 6.1 2.5l.6.6c1.6 1.6 2.5 3.8 2.5 6.1v6.1z" />
  </svg>
);
export default IconCarAlt3;
