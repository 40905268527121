import * as React from "react";
const IconWalletCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.4 22.3h-1.7V16c0-2.1-1-4-2.7-5.3-1.7-1.2-3.9-1.5-5.9-.8L8.2 22.3c-3.5.1-6.2 3-6.2 6.4v19.4c0 3.6 2.9 6.5 6.5 6.5h45.9c3.6 0 6.5-2.9 6.5-6.5V28.7c-.1-3.5-3-6.4-6.5-6.4m-9.7-10.6c1.4-.5 2.9-.3 4 .6 1.2.8 1.9 2.2 1.9 3.6v6.3H14.4zm9.7 40.8H8.5C6 52.5 4 50.5 4 48V28.7c0-2.5 2-4.5 4.5-4.5h45.9c2.5 0 4.5 2 4.5 4.5v3.4H46.4c-3.5 0-6.4 2.9-6.4 6.4s2.9 6.4 6.4 6.4h12.4v3.2c0 2.4-2 4.4-4.4 4.4m4.4-18.3V43H46.4C44 43 42 41 42 38.6s2-4.4 4.4-4.4z" />
  </svg>
);
export default IconWalletCopy;
