import * as React from "react";
const IconRecycle = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M26 43.5H15.4l3.6-6.4 3.5 2.1c.4.2.8.2 1.1-.1s.4-.7.3-1.1l-4.5-13.1c-.2-.5-.6-.7-1.1-.7l-14 2.1c-.4.1-.8.4-.8.8-.1.4.1.8.5 1.1l3.3 2-5.2 9.6c-.2.3-.2.7 0 1 0 .1 4.6 8.3 7.6 13.1 1.4 2.3 3.4 3.6 5.4 3.6H26c.6 0 1-.4 1-1v-12c0-.5-.4-1-1-1m-1 11.9h-9.9c-1.3 0-2.7-1-3.7-2.7C8.8 48.6 5.1 42 4.1 40.2l5.4-9.9c.3-.5.1-1.1-.4-1.3l-1.7-1 10.4-1.6 3.4 9.7-2.1-1.2c-.2-.1-.5-.2-.8-.1s-.5.2-.6.5L12.9 44c-.2.3-.2.7 0 1s.5.5.9.5h11.3v9.9zM15.2 18.1l10.3 5.8c.2.1.3.1.5.1h.3c.3-.1.5-.2.6-.5l5.8-10.3 3.8 6.3-3.3 1.9c-.4.2-.6.7-.5 1.1s.5.7.9.8l12.6.7c.4 0 .8-.2.9-.5l6-11.3c.2-.4.1-.9-.2-1.2s-.8-.4-1.2-.1l-3.3 1.9-5.8-9.2c-.2-.3-.5-.5-.8-.5-.1 0-9.4-.1-15.2.2-2.7.1-4.8 1.2-5.8 2.9l-5.9 10.6c-.3.4-.1 1 .3 1.3m7.3-11c.6-1.1 2.2-1.8 4.1-1.9C31.5 5 39.1 5 41.2 5l6 9.6c.3.5.9.6 1.3.3l1.2-.7-4.1 7.8-8.5-.5 1.3-.7c.2-.1.4-.4.5-.6.1-.3 0-.5-.1-.8l-5.3-8.6c-.2-.3-.5-.5-.9-.5s-.7.2-.9.5l-6.1 10.9-8.6-4.9zM61.4 36.3l-4.6-8.8c-.1-.2-.3-.4-.6-.5s-.5-.1-.8.1L45 32.7c-.5.3-.7.9-.4 1.3l4.5 8.6-7.4-.1.1-3.5c0-.4-.3-.8-.7-1-.4-.1-.9 0-1.1.4l-7.2 9.9c-.2.3-.3.7-.1 1.1l6.4 11.1c.2.3.5.5.9.5h.2c.4-.1.7-.5.8-.9l.2-3.8h10.9c.3 0 .7-.2.8-.5.1-.1 5.1-8 7.9-13 1.3-2.4 1.6-4.8.6-6.5m-2.3 5.5c-2.4 4.3-6.4 10.6-7.6 12.4H40.2c-.5 0-1 .4-1 1l-.1 1.4-4.4-7.5 4.9-6.8v1.3c0 .3.1.5.3.7s.4.3.7.3l10.1.1c.4 0 .7-.2.9-.5s.2-.7 0-1L46.8 34l8.7-4.7 4.1 7.9c.7 1.1.5 2.8-.5 4.6" />
  </svg>
);
export default IconRecycle;
