import * as React from "react";
const IconCalculatorAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M13.1 6.25c-.957 0-1.75.793-1.75 1.75v48c0 .957.793 1.75 1.75 1.75h37.8c.957 0 1.75-.793 1.75-1.75V8c0-.957-.793-1.75-1.75-1.75zM6.85 8c0-3.443 2.807-6.25 6.25-6.25h37.8c3.443 0 6.25 2.807 6.25 6.25v48c0 3.443-2.807 6.25-6.25 6.25H13.1c-3.443 0-6.25-2.807-6.25-6.25zm13.4 6.15v7h23.5v-7zm-4.5-.25A4.256 4.256 0 0 1 20 9.65h24a4.256 4.256 0 0 1 4.25 4.25v7.5c0 1.111-.38 2.212-1.209 3.041S45.111 25.65 44 25.65H20a4.256 4.256 0 0 1-4.25-4.25zM20 29.75a2.25 2.25 0 0 0 0 4.5h1a2.25 2.25 0 0 0 0-4.5zM28.75 32A2.25 2.25 0 0 1 31 29.75h1a2.25 2.25 0 0 1 0 4.5h-1A2.25 2.25 0 0 1 28.75 32M42 29.75a2.25 2.25 0 0 0 0 4.5h1a2.25 2.25 0 0 0 0-4.5zM17.75 42A2.25 2.25 0 0 1 20 39.75h1a2.25 2.25 0 0 1 0 4.5h-1A2.25 2.25 0 0 1 17.75 42M31 39.75a2.25 2.25 0 0 0 0 4.5h1a2.25 2.25 0 0 0 0-4.5zM39.75 42A2.25 2.25 0 0 1 42 39.75h1a2.25 2.25 0 0 1 0 4.5h-1A2.25 2.25 0 0 1 39.75 42M20 49.75a2.25 2.25 0 0 0 0 4.5h1a2.25 2.25 0 0 0 0-4.5zM28.75 52A2.25 2.25 0 0 1 31 49.75h1a2.25 2.25 0 0 1 0 4.5h-1A2.25 2.25 0 0 1 28.75 52M42 49.75a2.25 2.25 0 0 0 0 4.5h1a2.25 2.25 0 0 0 0-4.5z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconCalculatorAlt;
