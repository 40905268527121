import React, { useState } from 'react';
import { useNavigation } from 'context/NavigationContext';
import {
    BringzzPageContainer, BringzzCountryDropdown,
    BringzzButton, BringzzIcon, BringzzText, BringzzInput
} from "@bringzz/components";
import useUser from 'hooks/useUser';

let state = {
    checked: (
        <BringzzIcon
            icon="IconCheck"
            library="Custom"
            className="h-5 w-5 p-1 bg-magic-lilac text-light rounded-full"
            size="12"
        />
    ),
    unChecked: (
        <div></div>
    ),
};

const EditAddressView = () => {
    const { backPress, navigate } = useNavigation();
    const [selectedBirthday, setSelectedBirthday] = useState(null)
    const [selectedGender, setSelectedGender] = useState(null)
    const [genderDrawerOpened, setGenderDrawerOpened] = useState(false)
    const [isValidInput, setIsValidInput] = useState(false)
    const [inputValue, setInputValue] = useState('');

    const [country, setCountry] = useState({
        name: 'Austria',
        dial_code: '+43',
        code: 'AT'
    });

    const { getUser } = useUser();
    const user = getUser();

    return (
        <BringzzPageContainer
            header={{
                left: <BringzzIcon
                    size="22"
                    icon="IconChevronLeft"
                    folder="LineIcons"
                    className="cursor-pointer"
                    onClick={backPress}
                />,
                title: <BringzzText tag='h2'>Edit address</BringzzText>,
            }}
            footer={
                <div className='pb-28 px-6'>
                    <BringzzButton
                        size='lg'
                        className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
                        onClick={() => {
                            navigate('/register/consumer/picture');
                        }}
                        disabled={true}
                    >
                        Save
                    </BringzzButton>
                </div>
            }
        >
            <div className='p-6 space-y-4'>
                <BringzzInput id='street' label="Street name and house number" containerClassname="w-full" />
                <div className='flex flex-1 gap-3'>
                    <BringzzInput id='entrance' label="Entrance (opt.)" containerClassname="w-full" placeholder="Entrance" />
                    <BringzzInput id='floor' label="Floopr (opt.)" type='number' containerClassname="w-full" placeholder="Floor" />
                    <BringzzInput id='door' label="Door (opt.)" type='number' containerClassname="w-full" placeholder="Door" />
                </div>
                <div className='flex flex-1 gap-3'>
                    <BringzzInput id='postalcode' label="Postal Code" containerClassname="w-full" />
                    <BringzzInput id='city' label="City" containerClassname="w-full" />
                </div>

                <div className=''>
                    <BringzzText tag="h4" className="font-bold">
                        Country
                    </BringzzText>
                    <BringzzCountryDropdown
                        className="!rounded-r-none p-2 bg-[#F4F4F4]"
                        mode={'flag-name'}
                        onCountryChange={result => {
                            setCountry(result);
                        }}
                    />
                </div>
            </div>
        </BringzzPageContainer>
    );
}

export default EditAddressView;
