import * as React from "react";
const IconCompassAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.7 2.4c-.4-.4-1-.4-1.4 0L58 4.8c-1-.7-2.3-1.2-3.7-1.2-3.5 0-6.3 2.8-6.3 6.3 0 .5.1 1 .2 1.4L27 24l-4.3-4.3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l4 4L2.5 38.8c-.5.3-.6.9-.3 1.4.2.3.5.5.9.5.2 0 .4 0 .5-.1l23.2-14 4.6 4.6-3.4 3.1c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l3.3-3.3 4.7 4.7-14.1 23.2c-.3.5-.1 1.1.3 1.4.2.1.3.1.5.1.3 0 .7-.2.9-.5l13.8-22.7 3.9 3.9c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-4.2-4.2 12.8-21c.5.1 1.1.2 1.6.2 3.5 0 6.3-2.8 6.3-6.3 0-1.4-.4-2.6-1.2-3.7l2.3-2.3c.4-.3.4-1 0-1.4m-23.3 33L34 31l3.3-3.3c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-3.3 3.3-4.2-4.2L49 13.1c.5.8 1.1 1.4 1.9 1.9zm15.9-21.3c-2.4 0-4.3-1.9-4.3-4.3s1.9-4.3 4.3-4.3 4.3 1.9 4.3 4.3-1.9 4.3-4.3 4.3" />
  </svg>
);
export default IconCompassAlt;
