import * as React from "react";
const IconPuzzle = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.9 16.6c-.4-3.7-3.4-6.8-7.1-7.3-1.5-.2-3 0-4.3.5V7.4c0-3-2.4-5.4-5.4-5.4H19.4c-3 0-5.4 2.4-5.4 5.4v31.2q-1.95-.6-4.2-.3c-3.2.6-5.9 3.1-6.6 6.4-.5 2.5.1 5.1 1.7 7 1.6 2 3.9 3.1 6.5 3.1.9 0 1.8-.1 2.6-.4v2.3c0 3 2.4 5.3 5.4 5.3h24.8c3 0 5.4-2.4 5.4-5.3v-3.6c0-.3-.2-.7-.5-.8-.3-.2-.7-.2-1-.1-1.3.6-2.8.8-4.2.5-2.3-.5-4.2-2.5-4.7-4.8-.4-1.9.1-3.8 1.3-5.3s3-2.3 4.9-2.3q1.5 0 2.7.6c.3.2.7.1 1 0s.5-.5.5-.9V25.2c1 .4 2 .6 3.1.6 2.3 0 4.6-1 6.2-2.7 1.5-1.8 2.2-4.1 2-6.5M47.5 38.5c-.7-.2-1.4-.3-2.2-.3-2.5 0-4.8 1.1-6.4 3s-2.2 4.5-1.7 7c.6 3.1 3.1 5.7 6.2 6.4 1.4.3 2.8.3 4.1-.1v2.1c0 1.8-1.5 3.3-3.4 3.3H19.4c-1.8 0-3.4-1.5-3.4-3.3v-3.8c0-.4-.2-.7-.5-.9-.2-.1-.3-.1-.5-.1s-.3 0-.5.1c-.9.5-2 .8-3.1.8-1.9 0-3.7-.9-4.9-2.3S4.8 47 5.2 45.1c.5-2.4 2.5-4.4 5-4.8 1.5-.3 3 0 4.3.7.3.2.7.2 1 0s.5-.5.5-.9V33h9.4c.4 0 .7-.2.9-.5s.2-.7 0-1c-.5-.9-.8-2-.8-3.1 0-1.9.8-3.7 2.3-4.9s3.4-1.7 5.3-1.3c2.4.5 4.4 2.4 4.9 4.9.3 1.6.1 3.1-.7 4.5-.2.3-.2.7 0 1s.5.5.9.5h9.4v5.4zm9.8-16.8c-1.2 1.3-2.9 2.1-4.7 2.1-1.3 0-2.5-.4-3.6-1.1-.3-.2-.7-.2-1-.1-.3.2-.5.5-.5.9V31h-7.8c.5-1.4.5-2.9.2-4.4-.7-3.2-3.3-5.8-6.5-6.4-2.5-.5-5 .2-6.9 1.7-1.9 1.6-3 3.9-3 6.4 0 .9.1 1.8.4 2.6H16V7.4C16 5.5 17.5 4 19.4 4h24.8c1.8 0 3.4 1.5 3.4 3.4v4.1c0 .4.2.7.5.9s.7.2 1-.1c1.3-.9 2.9-1.2 4.5-1 2.8.4 5.1 2.7 5.4 5.6.1 1.7-.5 3.5-1.7 4.8" />
  </svg>
);
export default IconPuzzle;
