import * as React from "react";
const IconFence = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 33c.6 0 1-.4 1-1s-.4-1-1-1h-4.3V17H61c.6 0 1-.4 1-1s-.4-1-1-1h-4.3V8c0-.4-.2-.7-.5-.9L48.3 3q-.45-.15-.9 0l-7.5 3.8L32.4 3q-.45-.15-.9 0L24 6.8 16.5 3q-.45-.15-.9 0l-8 4.1c-.3.2-.5.6-.5.9v7H3c-.6 0-1 .4-1 1s.4 1 1 1h4.1v14H3c-.6 0-1 .4-1 1s.4 1 1 1h4.1v14H3c-.6 0-1 .4-1 1s.4 1 1 1h4.1v6.2c0 3.2 2.6 5.8 5.8 5.8H51c3.2 0 5.8-2.6 5.8-5.8V49H61c.6 0 1-.4 1-1s-.4-1-1-1h-4.3V33zM9.1 55.2V8.6L16 5.1l6.9 3.5V59h-10c-2.1 0-3.8-1.7-3.8-3.8M24.9 8.6l6.9-3.5 6.9 3.5V59H24.9zm29.8 46.6c0 2.1-1.7 3.8-3.8 3.8H40.8V8.6l6.9-3.5 6.9 3.5v46.6z" />
  </svg>
);
export default IconFence;
