import * as React from "react";
const IconBitcoinCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M46 31.9c4.9-1.9 8.4-6.5 8.6-11.9v-1c-.2-7.6-6.9-13.8-14.8-13.8h-5.1V2c0-.6-.4-1-1-1s-1 .4-1 1v3.3h-7V2c0-.6-.4-1-1-1s-1 .4-1 1v3.3H10.5c-.6 0-1 .4-1 1s.4 1 1 1h3.3v49.1h-3.3c-.6 0-1 .4-1 1s.4 1 1 1h13.2V62c0 .6.4 1 1 1s1-.4 1-1v-3.5h7V62c0 .6.4 1 1 1s1-.4 1-1v-3.5h5c7.9 0 14.6-6.2 14.8-13.8v-1.1c-.2-5.2-3.7-9.8-8.5-11.7M15.7 7.3h24c6.9 0 12.6 5.3 12.8 11.8v.8c-.2 6-5.5 10.9-11.8 10.9h-25zm36.8 37.3c-.2 6.5-6 11.8-12.8 11.8h-24V32.9h25c6.3 0 11.5 4.8 11.7 10.8.1.3.1.6.1.9" />
  </svg>
);
export default IconBitcoinCopy;
