import * as React from "react";
const IconLawBook = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.3 6.2c0-2.3-1.9-4.2-4.2-4.2H10.9C8.6 2 6.7 3.9 6.7 6.2v50.6c.2 2.9 2.6 5.2 5.5 5.2h44.1c.6 0 1-.4 1-1s-.4-1-1-1H12.2c-2 0-3.5-1.6-3.5-3.5s1.6-3.5 3.5-3.5h44.1c.6 0 1-.4 1-1V6.2M18.9 4h7.8v14.7l-3.4-1.8c-.3-.2-.7-.2-.9 0L19 18.6V4.2c0-.1-.1-.1-.1-.2M8.7 52.2v-46C8.7 5 9.7 4 10.9 4H17v16.3c0 .4.2.7.5.9s.7.2 1 0l4.4-2.4 4.4 2.4c.1.1.3.1.5.1s.4 0 .5-.1c.3-.2.5-.5.5-.9V4h24.4c1.2 0 2.2 1 2.2 2.2v44.7H12.2c-1.3 0-2.5.5-3.5 1.3" />
  </svg>
);
export default IconLawBook;
