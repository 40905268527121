import * as React from "react";
const IconLineSpacingCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M37.4 5.6h23.3c.6 0 1-.4 1-1s-.4-1-1-1H37.4c-.6 0-1 .4-1 1s.4 1 1 1M60.7 21.9H37.4c-.6 0-1 .4-1 1s.4 1 1 1h23.3c.6 0 1-.4 1-1s-.5-1-1-1M60.7 40.1H37.4c-.6 0-1 .4-1 1s.4 1 1 1h23.3c.6 0 1-.4 1-1s-.5-1-1-1M60.7 58.3H37.4c-.6 0-1 .4-1 1s.4 1 1 1h23.3c.6 0 1-.4 1-1s-.5-1-1-1M23.5 13c.4.4 1 .4 1.4 0s.4-1 0-1.4l-8.4-8.4c-1.5-1.5-3.9-1.5-5.3 0l-8.5 8.4c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3l8.5-8.4c.1-.1.2-.1.3-.2v55.400000000000006l-.3-.3L4.1 51c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l8.4 8.5c.7.7 1.7 1.1 2.7 1.1s2-.4 2.7-1.1l8.5-8.5c.4-.4.4-1 0-1.4s-1-.4-1.4 0L15 59.5c-.1.1-.2.1-.2.2V4.2c.1.1.2.1.3.2z" />
  </svg>
);
export default IconLineSpacingCopy;
