import * as React from "react";
const IconSalineAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M47.8 18.5h-6V11c0-.1 0-.2-.1-.3h5.9c.6 0 1-.4 1-1s-.4-1-1-1H14.4V3c0-.6-.4-1-1-1s-1 .4-1 1v58c0 .6.4 1 1 1s1-.4 1-1V10.8h25.4c0 .1-.1.2-.1.3v7.5h-6c-2.1 0-3.8 1.7-3.8 3.8v13.2c0 1.4.8 2.7 2 3.4l7.8 4.3v6.2c0 .6.4 1 1 1s1-.4 1-1v-6.2l7.8-4.3c1.2-.7 2-2 2-3.4V22.4c.1-2.1-1.6-3.9-3.7-3.9m-14 2h14c1 0 1.8.8 1.8 1.8v3.8H31.9v-3.8c0-.9.9-1.8 1.9-1.8m14.8 16.6-7.9 4.3-7.9-4.3c-.6-.3-.9-.9-.9-1.6v-7.3h17.7v7.3c0 .7-.4 1.3-1 1.6" />
  </svg>
);
export default IconSalineAlt1;
