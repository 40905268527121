import * as React from "react";
const IconMushroomAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2C18.4 2 7.3 14.8 7.3 30.5c0 .4.2.8.6.9 5.8 2.6 11.9 4.1 18.3 4.6l-1.3 18.6c-.1 2 .6 3.8 1.9 5.3C28.2 61.2 30 62 32 62s3.8-.8 5.2-2.2 2-3.3 1.9-5.3l-1.3-18.7c6-.6 12.1-2.1 18.2-4.4.4-.1.7-.5.7-.9C56.7 14.8 45.6 2 32 2m16.7 10.5c-.6 1.5-2 2.5-3.6 2.5-2.1 0-3.9-1.7-3.9-3.9 0-1.5.9-2.9 2.2-3.5 2 1.3 3.7 3 5.3 4.9m-35.3 2.7c1.7.4 3 1.9 3 3.8 0 2.1-1.7 3.9-3.9 3.9-.8 0-1.6-.3-2.2-.7.8-2.6 1.9-4.9 3.1-7m23.7 39.4c.1 1.4-.4 2.7-1.4 3.8-1 1-2.3 1.6-3.7 1.6s-2.8-.6-3.7-1.6c-1-1-1.5-2.4-1.4-3.8l1.3-18.5c.9 0 1.8.1 2.8.1 1.6 0 3.3-.1 4.9-.2zM9.3 29.8c0-2 .3-3.8.7-5.7.8.4 1.7.7 2.7.7 3.2 0 5.9-2.6 5.9-5.9 0-2.5-1.6-4.7-3.9-5.5C18.8 7.7 25 4 32 4c3.4 0 6.6.9 9.5 2.4-1.4 1.1-2.3 2.8-2.3 4.7 0 3.2 2.6 5.9 5.9 5.9 2 0 3.9-1 4.9-2.7 2.9 4.3 4.6 9.7 4.8 15.5-16.5 5.9-31.8 5.9-45.5 0" />
    <path d="M32.3 18c-3.2 0-5.9 2.6-5.9 5.9s2.6 5.9 5.9 5.9 5.9-2.6 5.9-5.9-2.7-5.9-5.9-5.9m0 9.7c-2.1 0-3.9-1.7-3.9-3.9s1.7-3.9 3.9-3.9 3.9 1.7 3.9 3.9-1.8 3.9-3.9 3.9" />
  </svg>
);
export default IconMushroomAlt;
