import * as React from "react";
const IconCactus = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.2 36.5c-.6 0-1 .4-1 1V43c0 1.5-1.3 2.8-2.8 2.8H42V22.2c.9 0 7-.1 9.9-2.9 3-3 3-9.6 3-9.8 0-.5-.4-1-1-1-.3 0-7 0-10 2.9-.9.8-1.5 2-1.9 3.2v-3.5c0-5-4.1-9.1-9.1-9.1h-3.8c-5 0-9.1 4.1-9.1 9.1v24.4H9.6c-1.5 0-2.8-1.3-2.8-2.8V21c0-.6-.4-1-1-1s-1 .4-1 1v11.7c0 2.6 2.2 4.8 4.8 4.8h10.5V60h-6.3c-.6 0-1 .4-1 1s.4 1 1 1h34.5c.6 0 1-.4 1-1s-.4-1-1-1H42V47.8h12.4c2.6 0 4.8-2.2 4.8-4.8v-5.5c0-.5-.4-1-1-1M45.3 12.8c1.7-1.7 5.4-2.2 7.6-2.3-.1 2.1-.7 5.7-2.4 7.4s-5.4 2.2-7.6 2.3c.1-2.1.7-5.8 2.4-7.4M32 60V29.8c0-.6-.4-1-1-1s-1 .4-1 1V60h-7.9V11.1c0-3.9 3.2-7.1 7.1-7.1H33c3.9 0 7.1 3.2 7.1 7.1V60z" />
  </svg>
);
export default IconCactus;
