import * as React from "react";
const IconSpeechBubble37 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.2 4.8c-6.5-2.7-13.6-1.1-18.7 4-.3.3-.7.3-1 0-5.1-5.1-12.2-6.6-18.7-4C6.2 7.5 2 14.1 2 21.8c0 6.3 3 13 8.9 19.9-1.8 3.3-2.2 7-1 10.6 1.2 3.8 4.1 6.7 7.8 8.1.3.1.6.2.9.2.7 0 1.4-.3 1.9-.8.8-.8 1-1.9.6-2.8-.7-1.8-.6-3.7 0-5.4 6.8 4.9 16.2 4.7 22.7-.8 8.3-6.9 18.2-17.5 18.2-29 0-7.7-4.2-14.3-10.8-17m-8.6 44.4c-6.1 5.1-15 5.1-21.1 0-.2-.2-.5-.3-.8-.2s-.5.2-.7.5c-1.4 2.5-1.7 5.4-.7 8.1.1.4-.1.6-.2.7s-.4.3-.7.2c-3.1-1.1-5.5-3.6-6.6-6.8-1-3.2-.6-6.6 1.3-9.4.2-.4.2-.9-.1-1.2-6-6.8-9-13.3-9-19.3C4 14.9 7.7 9 13.6 6.6c1.9-.8 3.8-1.1 5.7-1.1 3.9 0 7.8 1.6 10.8 4.7 1 1 2.8 1 3.8 0 4.5-4.5 10.8-5.9 16.5-3.5C56.3 9.1 60 15 60 21.9c0 10.6-9.5 20.7-17.4 27.3" />
    <path d="M39.2 20.2H24.8c-.6 0-1 .4-1 1s.4 1 1 1h14.3c.6 0 1-.4 1-1s-.4-1-.9-1M39.2 28.5H24.8c-.6 0-1 .4-1 1s.4 1 1 1h14.3c.6 0 1-.4 1-1s-.4-1-.9-1M39.2 36.8H24.8c-.6 0-1 .4-1 1s.4 1 1 1h14.3c.6 0 1-.4 1-1s-.4-1-.9-1" />
  </svg>
);
export default IconSpeechBubble37;
