import * as React from "react";
const IconHospitalAlt4 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 20.9H45.8V6c0-2.2-1.8-4-4-4H22.3c-2.2 0-4 1.8-4 4v9.8H6c-2.2 0-4 1.8-4 4V57c0 2.8 2.2 5 5 5h50c2.8 0 5-2.2 5-5V24.9c0-2.2-1.8-4-4-4M18.2 60H7c-1.7 0-3-1.3-3-3V19.8c0-1.1.9-2 2-2h12.2zm2.1-54c0-1.1.9-2 2-2h19.5c1.1 0 2 .9 2 2v54h-5.5V44.5c0-.6-.4-1-1-1H26.7c-.6 0-1 .4-1 1V60h-5.5V6zm7.4 54V45.5h8.6V60zM60 57c0 1.7-1.3 3-3 3H45.8V22.9H58c1.1 0 2 .9 2 2z" />
    <path d="M27.3 17.8H31v3.7c0 .6.4 1 1 1s1-.4 1-1v-3.7h3.7c.6 0 1-.4 1-1s-.4-1-1-1H33v-3.7c0-.6-.4-1-1-1s-1 .4-1 1v3.7h-3.7c-.6 0-1 .4-1 1s.4 1 1 1" />
  </svg>
);
export default IconHospitalAlt4;
