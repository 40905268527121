import * as React from "react";
const IconBackwardAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.3 12.1c-1.7-.8-3.6-.7-5.1.4L33.1 28V16.5c0-1.9-1-3.5-2.7-4.4-1.7-.8-3.6-.7-5.1.4L4 28c-1.3.9-2 2.4-2 4s.7 3 2 4l21.2 15.5c.9.6 1.9 1 2.9 1 .8 0 1.5-.2 2.2-.5 1.7-.8 2.7-2.5 2.7-4.4V36l21.1 15.5c.9.6 1.9 1 2.9 1 .8 0 1.5-.2 2.2-.5 1.7-.8 2.7-2.5 2.7-4.4V16.5c.1-1.9-.9-3.6-2.6-4.4M31.1 47.5c0 1.1-.6 2.1-1.6 2.6s-2.1.4-3-.2L5.2 34.4C4.4 33.8 4 32.9 4 32s.4-1.8 1.2-2.4l21.2-15.5c.9-.7 2-.8 3-.2 1 .5 1.6 1.5 1.6 2.6v31zm28.9 0c0 1.1-.6 2.1-1.6 2.6s-2.1.4-3-.2L33.1 33.6v-3.1l22.3-16.3c.9-.7 2-.8 3-.2 1 .5 1.6 1.5 1.6 2.6z" />
  </svg>
);
export default IconBackwardAlt;
