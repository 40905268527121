import * as React from "react";
const IconBowtieAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60 19c-1.3-1-2.9-1.5-4.5-1.1L39 21.1c-.7-.5-1.6-.8-2.5-.8h-9.1c-.9 0-1.8.3-2.5.7L8.5 17.8C6.9 17.5 5.3 18 4 19s-2 2.6-2 4.2v17.6c0 1.6.7 3.2 2 4.2 1 .8 2.2 1.2 3.5 1.2.4 0 .7 0 1.1-.1L24.4 43c.8.6 1.8 1 2.9 1h9.1c1.1 0 2.1-.4 2.9-1.1l16.2 3.2c.4.1.7.1 1.1.1 1.3 0 2.5-.4 3.5-1.2 1.3-1 2-2.6 2-4.2V23.2C62 21.6 61.3 20 60 19M8.1 44.2c-1 .2-2.1-.1-2.9-.7-.7-.7-1.2-1.7-1.2-2.7V23.2c0-1 .5-2 1.3-2.7s1.8-.9 2.9-.7l15.1 3c-.4.7-.6 1.4-.6 2.2v6.2c-.9-.9-2.3-1.9-4.1-2.7-.1 0-2.9-1.1-5.1-1.6-.5-.1-1.1.2-1.2.8-.1.5.2 1.1.8 1.2 2 .4 4.8 1.5 4.8 1.5 3.2 1.4 4.6 3.4 4.9 3.9v5.2c0 .7.1 1.3.4 1.8zM36.4 42h-9.1c-1.5 0-2.6-1.2-2.6-2.6V25c0-1.5 1.2-2.6 2.6-2.6h9.1c1.5 0 2.6 1.2 2.6 2.6v14.4c0 1.5-1.1 2.6-2.6 2.6M60 40.8c0 1-.5 2-1.3 2.7s-1.8.9-2.9.7l-15.2-3c.2-.6.4-1.2.4-1.8v-4.8l.2-.2s1.3-2.4 5-4.1c0 0 2.8-1.1 4.8-1.5.5-.1.9-.6.8-1.2-.1-.5-.6-.9-1.2-.8-2.2.5-5 1.6-5.2 1.6-2 .9-3.4 2-4.4 3V25c0-.8-.2-1.5-.5-2.1l15.4-3c1-.2 2.1.1 2.9.7.8.7 1.3 1.6 1.3 2.7v17.5z" />
  </svg>
);
export default IconBowtieAlt;
