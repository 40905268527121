import * as React from "react";
const IconCreditCard = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 11.7H7c-2.8 0-5 2.2-5 5v30.7c0 2.8 2.2 5 5 5h50c2.8 0 5-2.2 5-5V16.7c0-2.8-2.2-5-5-5m-50 2h50c1.7 0 3 1.3 3 3v7.1H4v-7.1c0-1.7 1.3-3 3-3m53 12.1v6.6H4v-6.6zm-3 24.5H7c-1.7 0-3-1.3-3-3v-13h56v13c0 1.7-1.3 3-3 3" />
  </svg>
);
export default IconCreditCard;
