import * as React from "react";
const IconAtmAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.3 13.6h-8.6c-4-6-10.7-9.5-17.9-9.5s-13.9 3.6-17.9 9.5H6.7c-2.6 0-4.7 2.1-4.7 4.7v21.8c0 2.6 2.1 4.7 4.7 4.7h10.2c5.9 7.6 13 12.7 13.3 12.9.2.1.4.2.6.2s.4-.1.6-.2c.3-.2 7.4-5.3 13.3-12.9h12.7c2.6 0 4.7-2.1 4.7-4.7V18.3c-.1-2.6-2.2-4.7-4.8-4.7M30.8 6.1c6.1 0 11.7 2.8 15.4 7.5H15.3c3.7-4.7 9.4-7.5 15.5-7.5m0 49.6c-1.7-1.3-6.8-5.3-11.3-10.9h22.6c-4.6 5.5-9.6 9.6-11.3 10.9M60 40.1c0 1.5-1.2 2.7-2.7 2.7H6.7c-1.5 0-2.7-1.2-2.7-2.7V18.3c0-1.5 1.2-2.7 2.7-2.7h50.6c1.5 0 2.7 1.2 2.7 2.7z" />
    <path d="M18.9 22h-6.7c-.6 0-1 .4-1 1v13.1c0 .6.4 1 1 1s1-.4 1-1v-6.3h4.7v6.3c0 .6.4 1 1 1s1-.4 1-1V23c0-.5-.5-1-1-1m-5.7 2h4.7v3.8h-4.7zM53.8 22.1c-.4-.1-.8 0-1.1.3l-2 2.3-3.1 3.5-5.2-5.8c-.3-.3-.7-.4-1.1-.3s-.6.5-.6.9v13.1c0 .6.4 1 1 1s1-.4 1-1V25.7l4.2 4.6c.2.2.5.3.7.3.3 0 .6-.1.7-.3l4.2-4.6v10.5c0 .6.4 1 1 1s1-.4 1-1V23c-.1-.4-.3-.7-.7-.9M35.5 22h-9.9c-.6 0-1 .4-1 1s.4 1 1 1h4v12.1c0 .6.4 1 1 1s1-.4 1-1V24h4c.6 0 1-.4 1-1s-.6-1-1.1-1" />
  </svg>
);
export default IconAtmAlt1;
