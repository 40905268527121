import * as React from "react";
const IconMp = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 750 500"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 0h750v500H0z" />
    <g   strokeWidth={1.9}>
      <circle cx={375} cy={245} r={225}  />
      <circle cx={375} cy={245} r={165}  />
    </g>
    <g >
      <path
        
        strokeWidth={1.9}
        d="M444.7 450c.7 11-7.8 20-18.8 20H323.6c-11 0-19.5-9-19-20l16.7-325c.6-11 10-20 21-20h61.4c11 0 20.5 9 21.2 20z"
      />
      <path
        
        strokeLinejoin="round"
        strokeWidth={2}
        d="m373 114 30 93h97l-78 56 29 92-78-56-78 57 30-93-79-57h97z"
      />
    </g>
  </svg>
);
export default IconMp;
