import * as React from "react";
const IconApartmentsAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 21.5H39.4V7.2c0-2.8-2.2-5-5-5H7c-2.8 0-5 2.2-5 5v49.5c0 2.8 2.2 5 5 5h28.9c.6 0 1-.4 1-1V50.6c0-3.3 2.7-6 6-6s6 2.7 6 6v10.2c0 .6.4 1 1 1H57c2.8 0 5-2.2 5-5V26.5c0-2.7-2.2-5-5-5m-33.2 5v33.2H7c-1.6 0-3-1.3-3-3V7.2c0-1.6 1.3-3 3-3h27.4c1.6 0 3 1.3 3 3v14.3h-8.6c-2.8 0-5 2.3-5 5M60 56.8c0 1.6-1.3 3-3 3h-6.2v-9.2c0-4.4-3.6-8-8-8s-8 3.6-8 8v9.2h-9.1V26.5c0-1.6 1.3-3 3-3H57c1.6 0 3 1.3 3 3z" />
  </svg>
);
export default IconApartmentsAlt;
