import * as React from "react";
const IconArrowsHorizontalCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.7 31.3 50.6 21c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l8.5 8.7H6.4l8.5-8.7c.4-.4.4-1 0-1.4s-1-.4-1.4 0L3.3 31.3c-.4.4-.4 1 0 1.4L13.4 43c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4L6.4 33h51.2l-8.5 8.6c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3l10.2-10.3c.4-.4.4-1 0-1.4" />
  </svg>
);
export default IconArrowsHorizontalCopy;
