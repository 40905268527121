import React from 'react';
import { useNavigation } from '../../context/NavigationContext';
import { useNotification } from '../../context/NotificationContext';

import { BringzzPageContainer, BringzzIcon, BringzzText, BringzzList } from "@bringzz/components"

const NotificationsPage = () => {
    const { backPress } = useNavigation();
    const { notifications } = useNotification()

    return (
        <BringzzPageContainer
            header={{
                right: <BringzzIcon
                    size="18"
                    icon="IconClose"
                    folder="LineIcons"
                    className="cursor-pointer"
                    onClick={backPress}
                />,
                title: <BringzzText tag='h2'>Notifications</BringzzText>,
            }}>
            {notifications &&
                <BringzzList type="notifications" items={notifications.map(notification => { return { id: notification.id, title: notification.title, body: notification.content } })}></BringzzList>
            }
            {!notifications && <BringzzText className='text-center mt-4' tag='h5'>Nothing to see here now. Come back later maybe?</BringzzText>}

        </BringzzPageContainer>

    )
}

export default NotificationsPage;