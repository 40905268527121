import * as React from "react";
const IconChessQueen = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M44.7 49.3H42l-2.3-13.9h2.9c.6 0 1-.4 1-1s-.4-1-1-1h-3l5.6-18.3c.1-.4 0-.9-.4-1.1s-.9-.2-1.2.1l-7.9 6.5-1.9-8.7c1.8-.7 3.1-2.5 3.1-4.5 0-2.7-2.2-4.9-4.9-4.9s-4.9 2.2-4.9 4.9c0 2.1 1.3 3.8 3.1 4.5l-1.9 8.6-7.9-6.4c-.3-.3-.8-.3-1.2-.1s-.5.7-.4 1.1l5.6 18.3h-3c-.6 0-1 .4-1 1s.4 1 1 1h2.9L22 49.3h-2.7c-1.7 0-3 1.3-3 3v6.1c0 1.7 1.3 3 3 3h25.4c1.7 0 3-1.3 3-3v-6.1c0-1.6-1.3-3-3-3M29.1 7.4c0-1.6 1.3-2.9 2.9-2.9s2.9 1.3 2.9 2.9-1.3 2.9-2.9 2.9-2.9-1.3-2.9-2.9m-7.4 10.4 6.6 5.4c.3.2.6.3 1 .2.3-.1.6-.4.6-.7l2.1-9.5 2.1 9.5c.1.3.3.6.6.7s.7 0 1-.2l6.6-5.4-4.8 15.7h-11zm4.6 17.8v-.2h11.5v.2L40 49.3H24zm19.4 22.9c0 .6-.4 1-1 1H19.3c-.6 0-1-.4-1-1v-6.1c0-.6.4-1 1-1h25.4c.6 0 1 .4 1 1z" />
  </svg>
);
export default IconChessQueen;
