import * as React from "react";
const IconChildProgram = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M15 18a4 4 0 1 0 0-8 4 4 0 0 0 0 8m0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4"
      clipRule="evenodd"
    />
    <path
      
      d="M18.268 23.402 12.568 21l5.7 2.404zM18.268 23.402l.172.073-.172-.26z"
    />
    <path
      
      fillRule="evenodd"
      d="M9.82 19.99c.563-.427 1.512-.99 2.749-.99h4.996c1.363 0 2.38.718 2.875 1.118.58.469 1.082 1.033 1.47 1.515.619.766 1.19 1.635 1.516 2.128l.007.01q.117.18.184.278c.207.305.35.636.433.974.073-.388.226-.767.465-1.114q.148-.216.357-.53l.011-.018c.38-.572.893-1.343 1.4-1.974.36-.449.832-.977 1.403-1.414.543-.416 1.468-.973 2.678-.973h4.829c1.333 0 2.324.71 2.8 1.099.562.459 1.046 1.01 1.421 1.48.597.749 1.15 1.597 1.463 2.078l.004.007.003.004.006.009q.11.17.172.262a2.915 2.915 0 0 1-2.564 4.535c.288 1.043.565 1.991.765 2.659.314 1.053-.317 2.191-1.432 2.424-3.61.755-6.164.731-10.044-.01a1.92 1.92 0 0 1-1.466-2.457c.206-.663.488-1.592.776-2.618a2.9 2.9 0 0 1-1.836-.509 2.9 2.9 0 0 1-1.174-1.696 3 3 0 0 1-1.268 1.949 3 3 0 0 1-2.51.402l.006.05.667 6a3 3 0 0 1-5.941.825L15 35.248l-.04.245a3 3 0 0 1-5.942-.825l.518-4.655.049-1.337a3 3 0 0 1-3.046-4.658q.147-.213.353-.52l.028-.041c.392-.585.923-1.375 1.448-2.02.374-.46.863-1.001 1.452-1.448M12.57 21c-.618 0-1.144.283-1.542.584-.406.308-.778.71-1.107 1.114-.458.564-.939 1.277-1.342 1.877l-.014.02c-.139.207-.271.404-.384.567a1 1 0 1 0 1.64 1.143l1.956-2.81-.244 6.665-.526 4.73a1 1 0 0 0 1.98.274l.994-5.962 1.026-.228 1.016.238.992 5.952a1 1 0 0 0 1.98-.275l-.667-6a1 1 0 0 0-.027-.144l-.032-.125v-5.216l.173.073 1.66 2.51.206.308a1 1 0 1 0 1.655-1.123l-.194-.292-3.327-1.403-.001-.002 3.326 1.402-.004-.005c-.329-.5-.855-1.298-1.408-1.984-.349-.432-.748-.873-1.17-1.214-.394-.318-.958-.674-1.619-.674zm16.284.497c.386-.295.902-.577 1.511-.577h4.829c.652 0 1.205.355 1.586.666.41.335.797.767 1.134 1.19.536.671 1.045 1.452 1.363 1.94l.003.005c.072.11.138.211.19.288a.995.995 0 0 1-1.65 1.113.96.96 0 0 0-1.725.773c.457 1.816 1 3.685 1.329 4.786-3.325.694-5.617.678-9.27-.018l.001-.003a92 92 0 0 0 1.376-4.865.96.96 0 0 0-1.724-.773l-.072.106a.995.995 0 0 1-1.638-1.13c.109-.158.237-.35.371-.552l.005-.007c.391-.589.86-1.293 1.307-1.849.318-.396.679-.79 1.074-1.093M33 18a4 4 0 1 0 0-8 4 4 0 0 0 0 8m0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4"
      clipRule="evenodd"
    />
    <path
      
      d="M29.8 37.987a1.5 1.5 0 0 1-1.3-1.487v-1.236a36 36 0 0 0 3.317.274l-.37 1.357a1.5 1.5 0 0 1-1.646 1.092M34.553 36.895l-.372-1.362a38 38 0 0 0 3.319-.283v1.25a1.5 1.5 0 0 1-2.947.395"
    />
  </svg>
);
export default IconChildProgram;
