import * as React from "react";
const IconSs = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 85.333 512 341.333"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 85.337h512v341.326H0z" />
    <path  d="M0 322.783h512v103.88H0z" />
    <path d="M0 85.337h512v104.515H0z" />
    <path  d="M0 210.877h512v89.656H0z" />
    <path  d="M256 256.006 0 426.668V85.331z" />
    <path
      
      d="m73.178 209.188 20.831 29.067 34.084-10.83-21.207 28.795 20.83 29.069-33.939-11.271-21.208 28.794.234-35.762-33.94-11.273 34.083-10.83z"
    />
  </svg>
);
export default IconSs;
