import * as React from "react";
const IconIt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 513 342"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M342 0H0v341.3h512V0z" />
    <path  d="M0 0h171v342H0z" />
    <path  d="M342 0h171v342H342z" />
  </svg>
);
export default IconIt;
