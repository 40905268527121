import * as React from "react";
const IconTaco = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.9 32.7c-.5-1.7-1.9-2.8-3.2-3.9-.9-.7-1.8-1.4-2.2-2.2-.5-.8-.6-1.9-.8-3.1-.3-1.7-.5-3.5-1.8-4.7-1.2-1.2-3-1.5-4.7-1.8-1.2-.2-2.3-.4-3.1-.8s-1.5-1.3-2.2-2.2c-1.1-1.4-2.2-2.8-3.9-3.2-1.6-.4-3.3.2-4.9.8-1.1.5-2.1.9-3.1.9-.9 0-2-.4-3.1-.9-1.6-.6-3.2-1.3-4.9-.8s-2.8 1.9-3.9 3.2c-.7.9-1.4 1.8-2.2 2.2-.8.5-1.9.6-3.1.8-1.7.3-3.5.5-4.7 1.8-1.2 1.2-1.5 3-1.8 4.7-.2 1.2-.4 2.3-.8 3.1s-1.3 1.5-2.2 2.2C4 29.9 2.6 31 2.1 32.7c-.4 1.6.6 3.6 1.7 5.8.3.5.5 1 .8 1.5-.3 1.5-.5 3-.5 4.6v6.7c0 1.1.9 2 2 2H58c1.1 0 2-.9 2-2v-6.7c0-1.6-.2-3.1-.5-4.6.2-.5.5-1 .7-1.5 1.1-2.1 2.1-4.1 1.7-5.8M6.1 51.3v-6.7c0-11 9-20 20-20H38c11 0 20 9 20 20v6.7zm52.5-14.1c-3.1-8.5-11.2-14.5-20.7-14.5H26.1c-9.5 0-17.6 6.1-20.7 14.5-.7-1.4-1.6-3.1-1.3-3.9.3-1.1 1.4-1.9 2.5-2.9 1-.8 2.1-1.7 2.7-2.8.7-1.1.9-2.5 1.1-3.8.2-1.5.4-2.8 1.2-3.6s2.1-1 3.6-1.2c1.3-.2 2.7-.4 3.8-1.1 1.1-.6 1.9-1.7 2.8-2.7.9-1.2 1.8-2.3 2.9-2.5 1-.3 2.3.2 3.6.7s2.5 1 3.9 1c1.3 0 2.6-.5 3.9-1s2.6-1 3.6-.8c1.1.3 1.9 1.4 2.9 2.5.8 1 1.7 2.1 2.8 2.7s2.5.9 3.8 1.1c1.4.2 2.8.4 3.6 1.2s1 2.1 1.2 3.6c.2 1.3.4 2.7 1.1 3.8.6 1.1 1.7 1.9 2.7 2.8 1.2.9 2.3 1.8 2.5 2.9-.1.9-.9 2.5-1.7 4" />
    <path d="M21.5 38.1c-.9-.2-1.9 0-2.7.6-.8.5-1.3 1.3-1.5 2.3-.4 1.9.9 3.8 2.8 4.2.2 0 .4.1.7.1 1.7 0 3.2-1.2 3.5-2.9.2-.9 0-1.9-.6-2.7-.4-.9-1.3-1.4-2.2-1.6m.9 3.8c-.2.8-1 1.4-1.8 1.2s-1.4-1-1.2-1.8c.1-.4.3-.8.7-1q.45-.3.9-.3h.3c.4.1.8.3 1 .7.1.4.2.8.1 1.2M27.5 29.1c-1.9-.4-3.8.9-4.2 2.8-.2.9 0 1.9.6 2.7.5.8 1.3 1.3 2.3 1.5.2 0 .4.1.7.1.7 0 1.4-.2 2-.6.8-.5 1.3-1.3 1.5-2.3.2-.9 0-1.9-.6-2.7s-1.4-1.3-2.3-1.5m.9 3.8c-.1.4-.3.8-.7 1-.3.2-.8.3-1.2.2s-.8-.3-1-.7c-.2-.3-.3-.8-.2-1.2.1-.8.8-1.3 1.5-1.3h.3c.4.1.8.3 1 .7.3.5.4.9.3 1.3M40 35.5c-1.9-.4-3.8.9-4.2 2.8s.9 3.8 2.8 4.2c.2 0 .4.1.7.1 1.7 0 3.2-1.2 3.5-2.9.4-1.9-.9-3.8-2.8-4.2m.9 3.8c-.2.8-1 1.4-1.8 1.2-.4-.1-.8-.3-1-.7-.2-.3-.3-.8-.2-1.2.1-.8.8-1.3 1.5-1.3h.3c.8.4 1.3 1.2 1.2 2M49.5 41.4c-.9-.2-1.9 0-2.7.6-.8.5-1.3 1.3-1.5 2.3-.2.9 0 1.9.6 2.7.5.8 1.3 1.3 2.3 1.5.2 0 .4.1.7.1 1.7 0 3.2-1.2 3.5-2.9.3-2-.9-3.9-2.9-4.3m.9 3.8c-.2.8-1 1.4-1.8 1.2-.4-.1-.8-.3-1-.7-.2-.3-.3-.8-.2-1.2s.3-.8.7-1q.45-.3.9-.3h.3c.4.1.8.3 1 .7.1.5.2.9.1 1.3" />
  </svg>
);
export default IconTaco;
