import * as React from "react";
const IconUxCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M45.9 37.4h2.8c1.3 0 2.3-1 2.3-2.3v-7.6c0-1.3-1-2.3-2.3-2.3H41c-1.3 0-2.3 1-2.3 2.3v2.8H25.3v-2.8c0-1.3-1-2.3-2.3-2.3h-2.8V14.3H23c1.3 0 2.3-1 2.3-2.3V4.3C25.3 3 24.2 2 23 2h-7.6C14 2 13 3 13 4.3V12c0 1.3 1 2.3 2.3 2.3h2.8v10.9h-2.8c-1.3 0-2.3 1-2.3 2.3v7.6c0 1.3 1 2.3 2.3 2.3H23c1.3 0 2.3-1 2.3-2.3v-2.8h13.4v2.8c0 1.3 1 2.3 2.3 2.3h2.8v11.9c0 .1 0 .3.1.4H41c-1.3 0-2.3 1-2.3 2.3v7.6c0 1.3 1 2.3 2.3 2.3h7.6c1.3 0 2.3-1 2.3-2.3V52c0-1.3-1-2.3-2.3-2.3h-2.9c.1-.1.1-.3.1-.4V37.4zM15 12V4.3q0-.3.3-.3H23q.3 0 .3.3V12q0 .3-.3.3h-7.6c-.3 0-.4-.2-.4-.3m8.3 23.1q0 .3-.3.3h-7.6q-.3 0-.3-.3v-7.6q0-.3.3-.3H23q.3 0 .3.3zm17.4 0v-7.6q0-.3.3-.3h7.6q.3 0 .3.3v7.6q0 .3-.3.3H41c-.1 0-.3-.1-.3-.3M49 52v7.6q0 .3-.3.3H41q-.3 0-.3-.3V52q0-.3.3-.3h7.6c.3 0 .4.2.4.3" />
  </svg>
);
export default IconUxCopy;
