import * as React from "react";
const IconBurgerAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.4 23.6C55.9 14.5 48.2 7.3 39 7.3H25c-4.7 0-9.1 1.8-12.3 5.1-3 3-4.8 7-5.1 11.3C4.3 24.9 2 28 2 31.6v.8c0 3.6 2.3 6.7 5.6 7.9.5 9.1 8.1 16.4 17.4 16.4h14c4.7 0 9.1-1.8 12.3-5.1 3.1-3 4.8-7 5.1-11.3 3.3-1.2 5.6-4.3 5.6-7.9v-.7c0-3.7-2.3-6.8-5.6-8.1M14 13.8c3-2.9 6.8-4.5 11-4.5h14c8 0 14.6 6.1 15.4 13.9-.3 0-.6-.1-1-.1H10.8c-.4 0-.8 0-1.2.1.4-3.6 1.9-6.9 4.4-9.4m-3.2 11.3h42.6c3.5 0 6.4 2.8 6.6 6.3H4c.1-3.5 3.1-6.3 6.8-6.3M50 50.2c-2.9 2.9-6.8 4.5-10.9 4.5H25c-8 0-14.6-6.1-15.4-13.9.4.1.8.1 1.2.1h8.4c1.1 0 1.9.9 1.9 1.9 0 2.2 1.8 3.9 3.9 3.9h2.4c2.2 0 3.9-1.8 3.9-3.9v-.6c0-1.1.9-1.9 1.9-1.9h1.6c1.1 0 1.9.9 1.9 1.9v4.2c0 2.2 1.8 3.9 3.9 3.9h.5c2.2 0 3.9-1.8 3.9-3.9v-3.6c0-.5.2-1 .6-1.4s.8-.6 1.3-.6h6.2c.4 0 .8 0 1.2-.1-.2 3.7-1.8 7-4.3 9.5m3.2-11.3H47c-1 0-2 .4-2.8 1.1-.7.7-1.2 1.7-1.2 2.8v3.6c0 1.1-.9 1.9-1.9 1.9h-.5c-1.1 0-1.9-.9-1.9-1.9v-4.2c0-2.2-1.8-3.9-3.9-3.9h-1.6c-2.2 0-3.9 1.8-3.9 3.9v.6c0 1.1-.9 1.9-1.9 1.9H25c-1.1 0-1.9-.9-1.9-1.9 0-2.2-1.8-3.9-3.9-3.9h-8.4c-3.4 0-6.2-2.4-6.7-5.5h55.8c-.5 3.1-3.3 5.5-6.7 5.5" />
  </svg>
);
export default IconBurgerAlt2;
