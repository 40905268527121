import * as React from "react";
const IconYogaMat = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 12.8H7c-2.8 0-5 2.2-5 5v28.4c0 2.8 2.2 5 5 5h50c2.8 0 5-2.2 5-5V17.8c0-2.7-2.2-5-5-5m-11.3 2v34.4H18.3V14.8zM4 46.2V17.8c0-1.7 1.3-3 3-3h9.3v34.4H7c-1.7 0-3-1.4-3-3m56 0c0 1.7-1.3 3-3 3h-9.3V14.8H57c1.7 0 3 1.3 3 3z" />
  </svg>
);
export default IconYogaMat;
