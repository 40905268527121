import * as React from "react";
const IconWardrobe = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.5 4.5h-49C4.5 4.5 2 7 2 10v48.5c0 .6.4 1 1 1s1-.4 1-1v-9.8h56v9.8c0 .6.4 1 1 1s1-.4 1-1V10c0-3-2.5-5.5-5.5-5.5m-49 2h30.3v20.2H4V10c0-1.9 1.6-3.5 3.5-3.5M4 28.7h33.8v18H4zm35.8 18V6.5h16.6c2 0 3.5 1.6 3.5 3.5v36.7z" />
    <path d="M51.1 25.7h-3c-.6 0-1 .4-1 1s.4 1 1 1h3c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconWardrobe;
