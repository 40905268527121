import React from 'react';
import PropTypes from 'prop-types';
import BringzzText from '../BringzzText';
import BringzzButton from '../BringzzButton';

const BringzzModal = ({ title, description = '', onCancel, onConfirm, confirmButtonText }) => {
  return (
    <div className="fixed inset-0 p-6 flex items-center justify-center bg-gray-900 bg-opacity-50 z-90">
      <div className="bg-white rounded-lg w-full max-w-sm shadow-lg">
        <div className="p-8">
          <BringzzText tag="h3" className="text-center font-semibold text-lg mb-2">
            {title}
          </BringzzText>
          {description && (
            <BringzzText tag="p" className="text-center text-sm text-gray-600 mb-4">
              {description}
            </BringzzText>
          )}
        </div>
        <div className="w-full border-t border-gray-300">
          <div className="flex justify-between">
            <BringzzButton className="w-full py-3 justify-center" onClick={onCancel}>
              <BringzzText className="text-blue-500 text-center">Cancel</BringzzText>
            </BringzzButton>
            <div className='border-r border-gray-300' ></div>
            <BringzzButton className="w-full py-3 justify-center" onClick={onConfirm}>
              <BringzzText className="text-blue-500 text-center">{confirmButtonText}</BringzzText>
            </BringzzButton>
          </div>
        </div>
      </div>
    </div>
  );
};

BringzzModal.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  confirmButtonText: PropTypes.string.isRequired,
};

export default BringzzModal;