import * as React from "react";
const IconAlarm2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.8 49.4 55.7 40c-.5-1.2-.8-2.5-.8-3.9v-8.2c0-5.7-2.5-11.1-6.9-15.1-4.1-3.6-9.4-5.7-15-6V3c0-.6-.4-1-1-1s-1 .4-1 1v3.9c-.6 0-1.2.1-1.8.1C17.7 8.3 9.1 17.4 9.1 28.3v7.9c0 1.3-.3 2.7-.8 3.9l-4.1 9.4c-.9 2-.7 4.3.5 6.1s3.2 2.9 5.4 2.9H31v2.6c0 .6.4 1 1 1s1-.4 1-1v-2.6h20.8c2.2 0 4.2-1.1 5.4-2.9 1.2-2 1.4-4.2.6-6.2m-2.2 5c-.8 1.3-2.2 2-3.7 2H10.2c-1.5 0-2.9-.8-3.7-2-.8-1.3-1-2.8-.4-4.2l4.1-9.4c.6-1.5 1-3.1 1-4.7v-7.9c0-9.8 7.9-18.1 18.3-19.3.9-.1 1.7-.1 2.6-.1 5.5 0 10.7 1.9 14.7 5.5s6.3 8.5 6.3 13.6v8.2c0 1.6.3 3.2 1 4.7l4.1 9.4c.3 1.4.2 2.9-.6 4.2" />
  </svg>
);
export default IconAlarm2;
