import * as React from "react";
const IconBasket1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.2 23.5H46.4L36.3 13.4c.6-.8.9-1.8.9-2.9 0-2.9-2.4-5.2-5.2-5.2-2.9 0-5.2 2.4-5.2 5.2 0 1.1.3 2.1.9 2.9L17.6 23.5H5.8c-2.1 0-3.8 1.7-3.8 3.8v2.5c0 2.1 1.7 3.8 3.8 3.8L12.2 58c.1.4.5.7 1 .7h37.6c.5 0 .9-.3 1-.7l6.5-24.5c2.1 0 3.8-1.7 3.8-3.8v-2.5c-.1-2-1.8-3.7-3.9-3.7M32 7.2c1.8 0 3.2 1.5 3.2 3.2 0 .9-.4 1.7-.9 2.3-.6.6-1.4.9-2.3.9s-1.7-.4-2.3-.9c-.6-.6-.9-1.4-.9-2.3 0-1.7 1.4-3.2 3.2-3.2m-2.9 7.6c.8.6 1.8.9 2.9.9s2.1-.3 2.9-.9l8.7 8.7H20.4zm24.3 29.4H33V33.6h23.2zM31 33.6v10.6H10.6L7.8 33.6zM11.2 46.2H31v10.6H14zM50 56.8H33V46.2h19.8zm10-27c0 1-.8 1.8-1.8 1.8H5.8c-1 0-1.8-.8-1.8-1.8v-2.5c0-1 .8-1.8 1.8-1.8h52.4c1 0 1.8.8 1.8 1.8z" />
  </svg>
);
export default IconBasket1;
