import * as React from "react";
const IconGithubCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M63 31C62.3 15.1 49.3 2.1 33.4 1.5c-8.6-.3-16.7 2.7-22.8 8.7C4.4 16 1 24 1 32.5c0 14 9.4 26.4 23 29.9.3.1.5.1.8.1q1.05 0 1.8-.6c.7-.6 1.2-1.4 1.2-2.4V56c0-1 .1-5.4.1-5.9.1-.9.4-1.5.8-2.1.3-.4.4-1 .1-1.5-.2-.4-.7-.7-1.2-.8-.8-.1-1.5-.2-2.2-.4-.9-.3-1.8-.6-2.7-1.1-.8-.4-1.5-1-2.2-1.8-.5-.7-1-1.7-1.4-2.9-.4-1.4-.6-2.8-.6-4.4q0-3.3 2.1-5.7l.4-.5-.3-.6c-.6-1.4-.6-3 0-5 .3 0 .8.1 1.6.5 1.5.6 2.1.9 2.4 1.1.4.2.7.4 1 .6l.7.4.4-.1c1.8-.5 3.8-.8 5.7-.8 2 0 3.8.3 5.7.8l.4.1 1.6-.9c.9-.5 1.7-.9 2.7-1.3.7-.3 1.2-.3 1.5-.4.6 1.8.7 3.5.1 5l-.2.6.4.5q2.1 2.25 2.1 5.7c0 1.7-.2 3.1-.6 4.3s-.8 2.2-1.5 3c-.5.6-1.3 1.3-2.2 1.8-1 .5-1.9.9-2.8 1.1-.6.2-1.4.3-2.2.4-.5.1-.9.4-1.1.9-.2.4-.1.9.1 1.3.6.8.9 1.9.9 3.2v8c0 1 .5 1.9 1.2 2.4.8.6 1.7.7 2.7.5C54.8 58 63.7 45.2 63 31M40.6 60.3c-.3.1-.6 0-.9-.2s-.4-.5-.4-.8v-8c0-1.4-.3-2.6-.8-3.6.6-.1 1.1-.2 1.6-.4 1-.3 2-.7 3.2-1.3 1.2-.7 2.1-1.5 2.8-2.3.9-1.1 1.4-2.3 1.9-3.6.5-1.5.7-3.1.7-5 0-2.5-.8-4.7-2.3-6.6.6-2 .4-4.1-.5-6.4l-.2-.4-.4-.1c-.8-.3-1.9-.2-3.2.3-1 .4-2 .9-3 1.4l-.8.5c-1.9-.5-3.8-.7-5.8-.7s-3.9.2-5.8.7c-.3-.2-.6-.4-.9-.5-.5-.4-1.4-.8-2.7-1.3-1.4-.6-2.5-.8-3.4-.5l-.5.2-.2.4c-.9 2.4-1 4.5-.4 6.4-1.5 1.8-2.3 4-2.3 6.5 0 1.7.2 3.4.7 5q.75 2.25 1.8 3.6c.8 1 1.7 1.8 2.8 2.3 1 .6 2.1 1 3.1 1.3.5.2 1.1.3 1.6.4-.3.7-.6 1.4-.7 2.3v3.5c-3-1-5.4-2.2-7.4-4.2-1.3-1.5-2.8-2.9-3.5-3.3-1.5-.7-2.5.8-2.2 1.6.3 1.1 1.9 1.7 2.7 2.5.9 1 1.1 2.3 1.9 3.3 1.2 1.7 5.6 3.9 8.5 3.1v3.1c0 .4-.2.7-.4.8-.3.2-.6.3-.9.2C11.8 57.2 3 45.6 3 32.5c0-8 3.2-15.4 8.9-20.9C17.7 6 25.3 3.2 33.3 3.5 48.2 4 60.4 16.2 61 31.1c.6 13.3-7.7 25.3-20.4 29.2" />
  </svg>
);
export default IconGithubCopy;
