import * as React from "react";
const IconVirtualRealityAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.3 28h-3.2v-7.6c0-2.8-2.3-5.1-5.1-5.1h-1.8c-.6 0-1 .4-1 1s.4 1 1 1H48c1.7 0 3.1 1.4 3.1 3.1V28h-4.5c-.6 0-1 .4-1 1s.4 1 1 1h9.7c1.7 0 3.1 1.4 3.1 3.1V42h-8.7c-.6 0-1 .4-1 1s.4 1 1 1h8.7v12.8c0 1.7-1.4 3.1-3.1 3.1H40.5l-7.8-7.7c-.4-.4-1-.4-1.4 0L23.5 60H7.7c-1.7 0-3.1-1.4-3.1-3.1V44.1h8.7c.6 0 1-.4 1-1s-.4-1-1-1H4.5v-8.9C4.5 31.4 6 30 7.7 30h9.4c.6 0 1-.4 1-1s-.4-1-1-1h-4.2v-7.6c0-1.7 1.4-3.1 3.1-3.1h1.8c.6 0 1-.4 1-1s-.4-1-1-1H16c-2.8 0-5.1 2.3-5.1 5.1V28H7.7c-2.8 0-5.1 2.3-5.1 5.1v23.7c0 2.8 2.3 5.1 5.1 5.1H24c.3 0 .5-.1.7-.3l7.4-7.3 7.4 7.3c.2.2.4.3.7.3h16.3c2.8 0 5.1-2.3 5.1-5.1V33.2c-.1-2.9-2.4-5.2-5.3-5.2" />
    <path d="m20.6 23.8 10.4 6c.3.2.7.3 1 .3s.7-.1 1-.3l10.4-6c.6-.4 1-1 1-1.7V8.8c0-.8-.4-1.5-1.1-1.8L32.8 2.2c-.5-.2-1.1-.2-1.7 0L20.7 7c-.7.3-1.2 1-1.2 1.8v13.3c.1.7.4 1.4 1.1 1.7m1-1.7V9.8l9.4 4.3v13.4zM33 27.6V14.1l9.4-4.3v12.3zM32 4l9.1 4.2-9.1 4.2-9.1-4.2z" />
  </svg>
);
export default IconVirtualRealityAlt;
