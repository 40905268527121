import * as React from "react";
const IconShipLocked = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M41.1 57.1c-.3-.2-.5-.4-.8-.6l4.5-16c.7-2.5-.6-5.1-3-6.1L38.6 33v-4.8c0-.6-.4-1-1-1s-1 .4-1 1v4l-9.7-4c-1.3-.5-2.7-.5-4 0l-9.7 4v-7.3c0-1.7 1.4-3.1 3.1-3.1H30c.6 0 1-.4 1-1s-.4-1-1-1H18.2v-5.9c0-1.7 1.4-3.1 3.1-3.1h8.100000000000001c.6 0 1-.4 1-1s-.4-1-1-1H26V3.4c0-.6-.4-1-1-1s-1 .4-1 1v5.2h-2.6c-2.8 0-5.1 2.3-5.1 5.1v5.9c-2.7.1-4.9 2.4-4.9 5.1V33l-3.2 1.3c-2.4 1-3.7 3.6-3 6.1l4.5 16c-.3.2-.5.3-.8.6l-3.7 3.2c-.4.4-.5 1-.1 1.4s1 .5 1.4.1l3.7-3.2c1.2-1 2.9-1 4.1 0l1 .8c1.9 1.7 4.8 1.7 6.7 0l1-.8c1.2-1 2.9-1 4.1 0l1 .8c1.9 1.7 4.8 1.7 6.7 0l1-.8c1.2-1 2.9-1 4.1 0l3.7 3.2c.2.2.4.2.7.2s.6-.1.8-.3c.4-.4.3-1-.1-1.4zm-6.7 0-1 .8c-1.2 1-2.9 1-4.1 0l-1-.8c-1.9-1.7-4.8-1.7-6.7 0l-1 .8c-1.2 1-2.9 1-4.1 0l-1-.8c-1.1-1-2.6-1.4-4-1.2L7 40c-.4-1.5.4-3.1 1.8-3.8l3.8-1.6h.1L23.8 30c.8-.3 1.7-.3 2.4 0l11 4.6h.1l3.8 1.6c1.5.6 2.2 2.2 1.8 3.8l-4.5 15.9c-1.4-.2-2.9.2-4 1.2M58.2 10.9h-2.3V7.4c0-3-2.4-5.4-5.4-5.4S45 4.4 45 7.4v3.5h-2.4c-.6 0-1 .4-1 1V21c0 .6.4 1 1 1h15.5c.6 0 1-.4 1-1v-9.1c.1-.5-.4-1-.9-1M47 7.4C47 5.5 48.6 4 50.4 4c1.9 0 3.4 1.5 3.4 3.4v3.5H47zM57.2 20H43.7v-7.1h13.5z" />
  </svg>
);
export default IconShipLocked;
