import * as React from "react";
const IconWeightCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.4 2H9.6c-2.8 0-5 2.2-5 5v50c0 2.8 2.2 5 5 5h44.7c2.8 0 5-2.2 5-5V7c.1-2.8-2.2-5-4.9-5m2.9 55c0 1.7-1.3 3-3 3H9.6c-1.7 0-3-1.3-3-3V7c0-1.7 1.3-3 3-3h44.7c1.7 0 3 1.3 3 3z" />
    <path d="M31.9 14.6c-7.2 0-13.3 6-14.3 14-.1.5.1.9.4 1.3.3.3.7.5 1.1.5h25.6c.4 0 .8-.2 1.1-.5s.4-.8.4-1.3c-1-8-7.1-14-14.3-14m.8 13.8-1.5-4.3c-.2-.5-.7-.8-1.3-.6-.5.2-.8.7-.6 1.3l1.3 3.7H19.7c1-6.7 6.2-11.7 12.2-11.7s11.2 5 12.2 11.7H32.7z" />
  </svg>
);
export default IconWeightCopy;
