import * as React from "react";
const IconDashboardCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 15.2c-16.5 0-30 14.7-30 32.7 0 .6.4 1 1 1h58c.6 0 1-.4 1-1 0-18.1-13.5-32.7-30-32.7m3.9 31.6-3-9.4c-.3-.9-1.5-.9-1.8 0l-3 9.4H4c.5-16.5 12.9-29.7 28-29.7s27.5 13.2 28 29.7z" />
    <path d="M32 26c-.6 0-1 .4-1 1v2.6c0 .6.4 1 1 1s1-.4 1-1V27c0-.6-.4-1-1-1M47.9 33.2l-1.6 1.7c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3l1.6-1.7c.4-.4.4-1 0-1.4s-1.1-.4-1.4 0M16.2 33.2c-.4-.4-1-.4-1.4-.1s-.4 1-.1 1.4l1.5 1.7c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 .1-1.4z" />
  </svg>
);
export default IconDashboardCopy;
