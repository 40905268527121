import * as React from "react";
const IconMoreCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 25.7c-3.5 0-6.3 2.8-6.3 6.3s2.8 6.3 6.3 6.3 6.3-2.8 6.3-6.3-2.8-6.3-6.3-6.3m0 10.6c-2.4 0-4.3-1.9-4.3-4.3s1.9-4.3 4.3-4.3 4.3 1.9 4.3 4.3-1.9 4.3-4.3 4.3M55.7 25.7c-3.5 0-6.3 2.8-6.3 6.3s2.8 6.3 6.3 6.3S62 35.5 62 32s-2.8-6.3-6.3-6.3m0 10.6c-2.4 0-4.3-1.9-4.3-4.3s1.9-4.3 4.3-4.3S60 29.6 60 32s-1.9 4.3-4.3 4.3M8.3 25.7C4.8 25.7 2 28.5 2 32s2.8 6.3 6.3 6.3 6.3-2.8 6.3-6.3-2.9-6.3-6.3-6.3m0 10.6C5.9 36.3 4 34.4 4 32s1.9-4.3 4.3-4.3 4.3 1.9 4.3 4.3c-.1 2.4-2 4.3-4.3 4.3" />
  </svg>
);
export default IconMoreCopy;
