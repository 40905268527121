import * as React from "react";
const IconNodejs = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      
      d="M31.974 64c-.826 0-1.755-.103-2.477-.723l-7.845-4.645c-1.136-.722-.62-.826-.104-1.032 1.549-.413 1.859-.723 3.51-1.548.103-.104.413-.104.62.103l5.986 3.613c.104.103.413.103.723 0L55.82 46.245c.104-.103.413-.413.413-.62V18.478c0-.31-.103-.413-.413-.619L32.284 4.542c-.103-.103-.413-.103-.723 0L8.232 18.064c-.103.104-.413.413-.413.62v26.942c0 .31.104.413.413.62l6.4 3.612c3.51 1.755 5.678-.31 5.678-2.477V20.955c0-.413.31-.723.722-.723h2.994c.413 0 .722.31.722.723v26.632c0 4.645-2.477 7.226-6.812 7.226-1.446 0-2.271 0-5.368-1.445l-6.09-3.407C5.031 49.136 4 47.484 4 45.626V18.477c0-1.754.826-3.303 2.477-4.335L29.703.619c1.445-.825 3.51-.825 4.955 0L58.09 14.245c1.445.826 2.478 2.478 2.478 4.336v26.942c0 1.754-.826 3.303-2.478 4.335l-23.329 13.42c-1.032.619-1.858.722-2.787.722m18.89-26.632c0-5.058-3.303-6.4-10.632-7.433s-7.948-1.445-7.948-3.2c0-1.445.62-3.303 5.987-3.303 4.748 0 6.503 1.033 7.226 4.336.103.31.31.413.722.413h2.994c.103 0 .413-.104.413-.104s.103-.31.103-.413c-.413-5.677-4.232-8.154-11.561-8.154-6.71 0-10.736 2.787-10.736 7.535 0 5.058 4.026 6.503 10.323 7.123 7.639.722 8.258 1.858 8.258 3.303 0 2.58-2.065 3.613-6.916 3.613-6.194 0-7.536-1.549-7.949-4.645-.103-.31-.31-.62-.722-.62h-2.787a.705.705 0 0 0-.723.723c0 3.922 2.065 8.67 12.284 8.67 7.432.207 11.664-2.683 11.664-7.844"
    />
  </svg>
);
export default IconNodejs;
