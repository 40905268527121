import * as React from "react";
const IconPaginationCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.5 30.8 50.7 20c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4L59.9 32 49.3 42.6c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3l10.8-10.9c.7-.6.7-1.6 0-2.3M14.7 20c-.4-.4-1-.4-1.4 0L2.5 30.8c-.6.6-.6 1.7 0 2.3L13.3 44c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4L4.1 32l10.6-10.6c.4-.4.4-1 0-1.4M21.5 29.2c-1.6 0-2.8 1.3-2.8 2.8s1.3 2.8 2.8 2.8c1.6 0 2.8-1.3 2.8-2.8s-1.2-2.8-2.8-2.8" />
    <circle cx={32.3} cy={32} r={2.8} />
    <circle cx={43.3} cy={32} r={2.8} />
  </svg>
);
export default IconPaginationCopy;
