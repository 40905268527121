import * as React from "react";
const IconMeta2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 65 64"
    {...props}
  >
    <path
      
      d="M4.901 23.635h3.252l5.53 10.003 5.53-10.003h3.18v16.436h-2.652V27.474l-4.849 8.723h-2.489l-4.848-8.723v12.597H4.9zM30.952 40.365q-1.844 0-3.24-.816a5.7 5.7 0 0 1-2.178-2.26q-.78-1.444-.78-3.31 0-1.89.762-3.347a5.7 5.7 0 0 1 2.12-2.277q1.355-.822 3.116-.822 1.749 0 3.012.828 1.26.828 1.942 2.318t.681 3.499v.728h-9.004q.247 1.503 1.215 2.365t2.448.863q1.185 0 2.043-.352a5.2 5.2 0 0 0 1.608-1.068l1.409 1.725q-2.102 1.926-5.154 1.926m1.937-9.75q-.834-.852-2.184-.851-1.314 0-2.2.868-.888.87-1.122 2.337h6.457q-.117-1.503-.951-2.354M39.605 29.998h-2.441v-2.172h2.441v-3.592h2.56v3.592h3.71v2.172h-3.71v5.506q0 1.374.47 1.961.468.587 1.608.587.504 0 .857-.041.352-.041.774-.112v2.149a7.707 7.707 0 0 1-2.125.294q-4.144 0-4.144-4.532zM59.212 40.071h-2.513v-1.714a4.54 4.54 0 0 1-1.702 1.485q-1.034.522-2.348.523-1.62 0-2.87-.828t-1.967-2.278-.716-3.316q0-1.878.728-3.323.727-1.444 2.013-2.265 1.285-.822 2.953-.822 1.256 0 2.254.487a4.46 4.46 0 0 1 1.655 1.38v-1.574h2.513zm-2.56-7.96a3.5 3.5 0 0 0-1.297-1.65q-.886-.603-2.049-.604-1.643 0-2.618 1.104t-.974 2.982q0 1.89.94 2.994.938 1.103 2.547 1.103 1.185 0 2.113-.61a3.45 3.45 0 0 0 1.338-1.644z"
    />
  </svg>
);
export default IconMeta2;
