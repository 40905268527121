import React, { useEffect, useState } from 'react';
import {
	BringzzPageContainer,
	BringzzIcon,
	BringzzText,
	BringzzInput,
	BringzzButton,
	BringzzCheckbox,
	BringzzBottomDrawer,
	BringzzRadioList,
	BringzzRadio,
	BringzzImage,
	BringzzOrderCard
} from '@bringzz/components';
import { useNavigation } from 'context/NavigationContext';
import { useLocation } from 'react-router-dom';
import useUser from 'hooks/useUser';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

let state = {
	checked: <div></div>,
	unChecked: <div></div>
};

const ShopperDeliveryDetail = () => {
	let query = useQuery();
	const { backPress } = useNavigation();
	const { getUser } = useUser();
	let id = query.get('id');
	const [delivery, setDelivery] = useState(null); // State to manage the photo
	const { navigate } = useNavigation();

	useEffect(() => {
		if (id) {
			getUser().deliveries.forEach(deliverie => {
				if (deliverie.uuid == id) {
					setDelivery(deliverie);
				}
			});
		}
	}, [id]);

	return (
		delivery && (
			<BringzzPageContainer
				header={{
					left: (
						<BringzzIcon
							size='22'
							icon='IconChevronLeft'
							folder='LineIcons'
							className='cursor-pointer'
							onClick={backPress}
						/>
					),
					title: <BringzzText tag='h2'>Order #{delivery.uuid}</BringzzText>
				}}
			>
				<div className='p-6 space-y-4'>
					<PaymentDetails delivery={delivery} navigate={navigate} />
				</div>
			</BringzzPageContainer>
		)
	);
};

const PaymentDetails = ({ delivery, navigate }) => {
	return (
		<div className='p-4 bg-white max-w-md'>
			{/* Payment Summary */}
			<div className='text-center mb-4'>
				<BringzzText tag='h2' className='text-2xl font-semibold'>
					€XX.00
				</BringzzText>
				<BringzzText tag='p' className='text-sm text-gray-500'>
					Payment incl. tip
				</BringzzText>
			</div>

			{/* Details Section */}
			<div className='space-y-4'>
				<div className='flex items-center justify-between pb-4 border-b border-gray-200'>
					<div className='flex items-center'>
						<BringzzIcon
							size='24'
							icon='IconUser'
							folder='LineIcons'
							className='text-gray-400'
						/>
						<div className='ml-2'>
							<BringzzText tag='span' className='text-gray-500'>
								Customer
							</BringzzText>
							<BringzzText tag='p' className='font-medium'>
								{delivery.customerFirstName +
									' ' +
									delivery.customerLastName.slice(0, 1)}
								.
							</BringzzText>
						</div>
					</div>
					<BringzzButton
						size='sm'
						className='text-magic-lilac border border-magic-lilac bg-white rounded-md'
					>
						Chat history
					</BringzzButton>
				</div>

				<DetailItem
					icon='IconMapMarker'
					label='Total distance'
					value={`${delivery.totalDistance} km`}
				/>
				<DetailItem
					icon='IconClockAlt1'
					label='Total time'
					value={`${delivery.totalTime}min`}
				/>
				<DetailItem
					icon='IconCoinsAlt'
					label='Earned amount'
					value={`€${delivery.earnedAmount}`}
				/>
				<DetailItem icon='IconWallet' label='Tip' value='€XX.00' />
				<DetailItem
					icon='IconGift'
					label='Earned points'
					value={`${delivery.earnedPoints}`}
				/>
				<DetailItem
					icon='IconStar'
					label='Rating'
					isRating
					value={
						<div className='flex space-x-1'>
							{[...Array(5)].map((_, i) => (
								<BringzzIcon
									key={i}
									size='24'
									icon='IconStar'
									folder='LineIcons'
									className='text-magic-lilac'
								/>
							))}
						</div>
					}
				/>
				<div className='flex items-center justify-between pb-4 border-b border-gray-200'>
					<div className='flex items-center'>
						<BringzzIcon
							size='24'
							icon='IconDocument'
							folder='LineIcons'
							className='text-gray-400'
						/>
						<div className='ml-2'>
							<BringzzText tag='span' className='text-gray-500'>
								Invoice
							</BringzzText>
							<BringzzText tag='p' className='font-medium'>
								Invoice #2023-123456
							</BringzzText>
						</div>
					</div>
					<BringzzButton
						onClick={() =>
							navigate(
								`/settings/account/shopper/deliveries/invoice?id=${delivery.uuid}`
							)
						}
						size='sm'
						className='text-magic-lilac border border-magic-lilac bg-white rounded-md'
					>
						View
					</BringzzButton>
				</div>
			</div>

			{/* Bottom Action Button */}
			<div className='text-center mt-4'>
				<BringzzButton
					size='lg'
					className='text-magic-lilac border border-magic-lilac bg-white rounded-md'
				>
					Problem with payment?
				</BringzzButton>
			</div>
		</div>
	);
};

const DetailItem = ({ icon, label, value, isRating }) => (
	<div
		className={`flex items-center ${isRating ? 'justify-between' : ''} pb-4 border-b border-gray-200`}
	>
		<div className='flex items-center'>
			<BringzzIcon
				size='24'
				icon={icon}
				folder='LineIcons'
				className='text-gray-400'
			/>
			<div className='ml-2 flex flex-col'>
				<BringzzText tag='span' className='text-gray-500'>
					{label}
				</BringzzText>
				{!isRating && (
					<BringzzText tag='p' className='font-medium'>
						{value}
					</BringzzText>
				)}
			</div>
		</div>
		{isRating && <div className='ml-auto flex space-x-1'>{value}</div>}
	</div>
);

export default ShopperDeliveryDetail;
