import * as React from "react";
const IconDollarValueUp = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M16.5 33.9h5.9c.6 0 1-.4 1-1s-.4-1-1-1h-2.6v-1.7c0-.6-.4-1-1-1s-1 .4-1 1v1.7h-1.3c-2.3 0-4.2 1.9-4.2 4.2s1.9 4.2 4.2 4.2h2.7c1.2 0 2.2 1 2.2 2.2s-1 2.2-2.2 2.2h-5.9c-.6 0-1 .4-1 1s.4 1 1 1h4.5v1.7c0 .6.4 1 1 1s1-.4 1-1v-1.8c2-.3 3.6-2 3.6-4.1 0-2.3-1.9-4.2-4.2-4.2h-2.7c-1.2 0-2.2-1-2.2-2.2s1-2.2 2.2-2.2" />
    <path d="M17.9 23.4C9.1 23.4 2 30.5 2 39.2s7.1 15.9 15.9 15.9c8.7 0 15.9-7.1 15.9-15.9s-7.2-15.8-15.9-15.8m0 29.7C10.2 53.1 4 46.9 4 39.2c0-7.6 6.2-13.9 13.9-13.9s13.9 6.2 13.9 13.9c-.1 7.7-6.3 13.9-13.9 13.9M61.7 18.7l-9.5-9.5c-.2-.2-.4-.3-.7-.3s-.5.1-.7.3l-9.5 9.5c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l7.8-7.8v30.2c0 4.8-3.9 8.7-8.7 8.7h-.5c-.6 0-1 .4-1 1s.4 1 1 1h.5c5.9 0 10.7-4.8 10.7-10.7V12.3l7.8 7.8c.4.4 1 .4 1.4 0s.4-1 0-1.4" />
  </svg>
);
export default IconDollarValueUp;
