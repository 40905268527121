import * as React from "react";
const IconCow = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.8 20.7h-7c-.3 0-.5-.2-.5-.5V14c0-.2-.1-.3-.2-.5 1.3-.8 7.7-5.1 7.7-10.5 0-.6-.4-1-1-1s-1 .4-1 1c0 4.7-6.9 8.9-7 8.9s-.1.1-.2.2C42.2 9.1 36 7 32 7s-10.2 2.1-13.8 5l-.2-.2c-.1 0-7-4.2-7-8.9 0-.6-.4-1-1-1s-1 .4-1 1c0 5.4 6.5 9.7 7.7 10.5-.1.2-.2.3-.2.5v6.2c0 .3-.2.5-.5.5H9c-.6 0-1.3.3-1.7.7-.5.5-.7 1.1-.7 1.7 0 5.8 4.3 10.6 9.9 11.4.1.5.2 1.1.3 1.7.6 3.7 1.4 8.7 2.5 11.8-1.3 1.5-2 3.4-2 5.5 0 4.7 3.8 8.5 8.5 8.5H38c4.7 0 8.5-3.8 8.5-8.5 0-2.1-.8-4-2-5.5 1-3.1 1.9-8.1 2.5-11.8.1-.6.2-1.2.3-1.7 5.6-.8 9.9-5.7 9.9-11.4 0-1.2-1.1-2.3-2.4-2.3M38.1 60H25.9c-3.6 0-6.5-2.9-6.5-6.5s2.9-6.5 6.5-6.5h12.2c3.6 0 6.5 2.9 6.5 6.5S41.7 60 38.1 60m8.3-27.4c-.5 0-.8.4-.9.8-.1.7-.3 1.5-.4 2.4-.5 3.3-1.3 7.6-2.1 10.6q-2.1-1.5-4.8-1.5H25.9c-1.8 0-3.5.6-4.8 1.5-.9-3-1.6-7.4-2.1-10.6-.2-.9-.3-1.8-.4-2.4-.1-.5-.5-.8-.9-.8-5-.4-8.8-4.5-8.8-9.5 0-.2.1-.3.1-.3s.1-.1.3-.1h7c1.4 0 2.5-1.1 2.5-2.5v-5.8C21.3 11.6 27.6 9 32 9s10.7 2.6 13.3 5.3v5.8c0 1.4 1.1 2.5 2.5 2.5h7c.2 0 .4.2.4.4 0 5.1-3.9 9.3-8.8 9.6" />
  </svg>
);
export default IconCow;
