import * as React from "react";
const IconSthethoscopeCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.2 2h-6.6c-.6 0-1 .4-1 1v2.6c0 .6.4 1 1 1s1-.4 1-1V4h5.6q.3 0 .3.3v19c0 7.1-5.8 12.9-12.9 12.9h-1.1c-7.1 0-12.9-5.8-12.9-12.9v-19q0-.3.3-.3h5.6v1.6c0 .6.4 1 1 1s1-.4 1-1V3c0-.6-.4-1-1-1h-6.6c-1.3 0-2.3 1-2.3 2.3v19c0 8.2 6.7 14.9 14.9 14.9h.9v14.4c0 4-3.1 7.3-7 7.3H21.1c-4 0-7.3-3.3-7.3-7.3V43c3.6-.4 6.5-3.6 6.5-7.3 0-4.1-3.3-7.4-7.4-7.4s-7.4 3.3-7.4 7.4c0 3.7 2.7 6.8 6.3 7.3v9.6c0 5.1 4.2 9.3 9.3 9.3h15.3c4.9 0 9-4.2 9-9.3V38.2c7.4-.9 13.2-7.2 13.2-14.8v-19C58.5 3 57.5 2 56.2 2M7.5 35.7c0-3 2.4-5.4 5.4-5.4s5.4 2.4 5.4 5.4-2.4 5.4-5.4 5.4-5.4-2.4-5.4-5.4" />
  </svg>
);
export default IconSthethoscopeCopy;
