import * as React from "react";
const IconCrab = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.6 25.3v-6.2c1.2-.4 2.3-1.1 3.2-2 3.3-3.3 3.3-8.7 0-12L55 3.3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l1.8 1.8c2.5 2.5 2.5 6.7 0 9.2-1.2 1.2-2.9 1.9-4.6 1.9s-3.4-.7-4.6-1.9l-1.8-1.8c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l1.8 1.8c1.6 1.6 3.7 2.5 6 2.5h.9v5.1l-9.3 4.7c-2.2-3.5-6.1-5.9-10.5-5.9s-8.3 2.4-10.5 5.9l-9.3-4.7v-5.2c.5.1 1 .1 1.4.1 2.2 0 4.4-.8 6-2.5l1.8-1.8c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-1.8 1.8c-2.5 2.5-6.7 2.5-9.2 0C7.7 14.5 7 12.8 7 11.1s.7-3.4 1.9-4.6l1.8-1.8c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-2 1.8c-1.6 1.6-2.5 3.7-2.5 6s.9 4.4 2.5 6c.8.8 1.6 1.3 2.6 1.8v6.4q0 .6.6.9l9.8 4.9c-.6 1.5-1 3.1-1 4.8v2.9h-9.2c-.6 0-1 .4-1 1s.4 1 1 1h9.2v2.9c0 1.2.2 2.3.5 3.3l-9.4 4.7c-.3.2-.6.5-.6.9V60c0 .6.4 1 1 1s1-.4 1-1v-6.8l8.7-4.3c2 4.3 6.3 7.2 11.3 7.2S41 53.2 43 49l8.5 4.3V60c0 .6.4 1 1 1s1-.4 1-1v-7.4q0-.6-.6-.9l-9.2-4.6c.3-1.1.5-2.2.5-3.4v-2.9h9.2c.6 0 1-.4 1-1s-.4-1-1-1h-9.2v-2.9c0-1.7-.3-3.3-1-4.8l9.8-4.9c.3-.2.6-.5.6-.9M31.7 54.1c-5.8 0-10.4-4.7-10.4-10.4V36c0-5.8 4.7-10.4 10.4-10.4S42.1 30.3 42.1 36v7.7c0 5.7-4.6 10.4-10.4 10.4" />
  </svg>
);
export default IconCrab;
