import * as React from "react";
const IconWeddingSongAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M38.5 57.4c-8.9 2.3-18.6-.4-25.1-6.9C7 44.1 4.3 35 6.3 26.2c.1-.5-.2-1.1-.8-1.2-.5-.1-1.1.2-1.2.8-2.1 9.4.8 19.2 7.7 26.1 5.4 5.4 12.7 8.3 20.1 8.3q3.45 0 6.9-.9c.5-.1.9-.7.7-1.2s-.7-.8-1.2-.7M52 11.8c-7-7-17.4-9.9-27.1-7.4-.5.1-.9.7-.7 1.2.1.5.7.9 1.2.7 9-2.3 18.6.4 25.2 6.9C57.4 20 60 30 57.2 39.3c-.2.5.1 1.1.7 1.2h.3c.4 0 .8-.3 1-.7 2.9-9.9.2-20.6-7.2-28M11.9 23.3c.4.4 1 .7 1.6.7s1.2-.2 1.6-.7l5.3-5.3c1.1-1.1 1.7-2.6 1.7-4.2s-.6-3.1-1.7-4.2a5.79 5.79 0 0 0-6.9-1c-.9-.5-1.8-.7-2.8-.7-1.4 0-2.9.5-4 1.5C5.6 10.5 5 12 5 13.6c0 1.7.6 3.4 1.8 4.6zM8.1 10.9c.7-.7 1.6-1 2.6-1 .7 0 1.5.2 2.2.7.3.2.8.2 1.1 0 1.5-1.1 3.6-.9 4.9.4.7.7 1.1 1.7 1.1 2.8 0 1-.4 2-1.1 2.7l-5.3 5.3c-.1.1-.3.1-.4 0L8 16.6c-.8-.8-1.3-1.9-1.2-3.1.2-1 .6-1.9 1.3-2.6M49.1 45.1c-.9-.5-1.8-.7-2.8-.7-1.5 0-2.9.5-4 1.5s-1.8 2.5-1.8 4.1c0 1.7.6 3.4 1.8 4.6l5.2 5.2c.4.4 1 .7 1.6.7s1.2-.2 1.6-.7l5.3-5.3c1.1-1.1 1.7-2.6 1.7-4.2s-.6-3.1-1.7-4.2c-1.8-1.8-4.7-2.2-6.9-1m5.5 8-5.3 5.3c-.1.1-.3.1-.4 0l-5.2-5.2c-.8-.8-1.3-1.9-1.2-3.1q0-1.65 1.2-2.7c.7-.7 1.6-1 2.6-1h.1c.8 0 1.5.2 2.2.7.3.2.8.2 1.1 0 1.5-1.1 3.6-.9 4.9.4.7.7 1.1 1.7 1.1 2.8s-.4 2.1-1.1 2.8M41.6 31.8c0-5.3-4.3-9.6-9.6-9.6s-9.6 4.3-9.6 9.6 4.3 9.6 9.6 9.6 9.6-4.3 9.6-9.6m-17.2 0c0-4.2 3.4-7.6 7.6-7.6s7.6 3.4 7.6 7.6-3.4 7.6-7.6 7.6-7.6-3.4-7.6-7.6" />
  </svg>
);
export default IconWeddingSongAlt;
