import * as React from "react";
const IconRssFeedCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M5 2c-.6 0-1 .4-1 1s.4 1 1 1c30.3 0 55 24.4 55 54.4 0 .6.4 1 1 1s1-.4 1-1C62 27.3 36.4 2 5 2" />
    <path d="M5 15.9c-.6 0-1 .4-1 1s.4 1 1 1c22.9 0 41.5 18.2 41.5 40.5 0 .6.4 1 1 1s1-.4 1-1C48.4 34.9 29 15.9 5 15.9" />
    <path d="M5 28.1c-.6 0-1 .4-1 1s.4 1 1 1c15.9 0 28.8 12.7 28.8 28.2 0 .6.4 1 1 1s1-.4 1-1C35.8 41.7 22 28.1 5 28.1M5 40.7c-.6 0-1 .4-1 1s.4 1 1 1c8.7 0 15.8 7 15.8 15.6 0 .6.4 1 1 1s1-.4 1-1c0-9.7-8-17.6-17.8-17.6M7.5 51c-3 0-5.5 2.5-5.5 5.5S4.5 62 7.5 62s5.5-2.5 5.5-5.5S10.5 51 7.5 51m0 9C5.6 60 4 58.4 4 56.5S5.6 53 7.5 53s3.5 1.6 3.5 3.5S9.4 60 7.5 60" />
  </svg>
);
export default IconRssFeedCopy;
