import * as React from "react";
const IconWebsiteSeo = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M15.7 29.2h3.1c.6 0 1-.4 1-1s-.4-1-1-1h-3.1c-2.3 0-4.1 1.8-4.1 4.1v3.1c0 2.3 1.8 4.1 4.1 4.1 1.2 0 2.1.9 2.1 2.1v3.1c0 1.2-.9 2.1-2.1 2.1h-3.1c-.6 0-1 .4-1 1s.4 1 1 1h3.1c2.3 0 4.1-1.8 4.1-4.1v-3.1c0-2.3-1.8-4.1-4.1-4.1-1.2 0-2.1-.9-2.1-2.1v-3.1c0-1.2.9-2.1 2.1-2.1M30.8 29.2h4.4c.6 0 1-.4 1-1s-.4-1-1-1h-4.4c-2.3 0-4.1 1.8-4.1 4.1v12.4c0 2.3 1.8 4.1 4.1 4.1h4.4c.6 0 1-.4 1-1s-.4-1-1-1h-4.4c-1.2 0-2.1-.9-2.1-2.1v-5.2h4c.6 0 1-.4 1-1s-.4-1-1-1h-4v-5.2c-.1-1.2.9-2.1 2.1-2.1M48.3 27.2h-2.4c-2.3 0-4.2 1.9-4.2 4.2v12.3c0 2.3 1.9 4.2 4.2 4.2h2.4c2.3 0 4.2-1.9 4.2-4.2V31.4c-.1-2.3-2-4.2-4.2-4.2m2.1 16.5c0 1.2-1 2.2-2.2 2.2h-2.4c-1.2 0-2.2-1-2.2-2.2V31.4c0-1.2 1-2.2 2.2-2.2h2.4c1.2 0 2.2 1 2.2 2.2z" />
    <path d="M58 6.1H6c-2.2 0-4 1.8-4 4v43.8c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V10.1c0-2.2-1.8-4-4-4m-52 2h52c1.1 0 2 .9 2 2v9H4v-9c0-1.1.9-2 2-2m52 47.8H6c-1.1 0-2-.9-2-2V21.1h56v32.8c0 1.1-.9 2-2 2" />
    <path d="M36.7 14.5h.1c.6 0 1-.4 1-1s-.4-1-1-1h-.1c-.6 0-1 .4-1 1s.4 1 1 1M43.6 14.5h.1c.6 0 1-.4 1-1s-.4-1-1-1h-.1c-.6 0-1 .4-1 1s.4 1 1 1M50.5 14.5h.1c.6 0 1-.4 1-1s-.4-1-1-1h-.1c-.6 0-1 .4-1 1s.5 1 1 1" />
  </svg>
);
export default IconWebsiteSeo;
