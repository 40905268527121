import * as React from "react";
const IconFlightSearch = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m30.5 18.9-7.8-2v-3.4c0-.6-.4-1-1-1s-1 .4-1 1v3.4l-7.8 2c-.5.1-.9.7-.7 1.2.1.5.5.8 1 .8h.2l7.3-1.9v8.4l-4.1.9c-.5.1-.9.7-.8 1.2s.5.8 1 .8h.2l4.7-1 4.7 1h.2c.5 0 .9-.3 1-.8s-.2-1.1-.8-1.2l-4.1-.9V19l7.3 1.9h.2c.4 0 .9-.3 1-.8.2-.6-.2-1.1-.7-1.2" />
    <path d="M60.5 51.9 48.3 39.7c-1-1-2.3-1.5-3.7-1.5-1.3 0-2.6.5-3.6 1.4l-4.8-4.8c7-7.7 6.8-19.7-.7-27.1C31.9 4 27 2 21.7 2S11.5 4 7.8 7.8C4 11.5 2 16.4 2 21.7s2 10.2 5.8 13.9c3.8 3.8 8.9 5.8 13.9 5.8 4.7 0 9.5-1.7 13.2-5.1l4.8 4.8c-.9 1-1.4 2.2-1.4 3.6s.5 2.7 1.5 3.7L52 60.6c1 1 2.3 1.5 3.7 1.5s2.7-.5 3.7-1.5l1.2-1.2c1-1 1.5-2.3 1.5-3.7s-.6-2.8-1.6-3.8M9.2 34.2C5.8 30.9 4 26.4 4 21.7s1.8-9.2 5.2-12.5S17 4 21.7 4s9.2 1.8 12.5 5.2c6.9 6.9 6.9 18.1 0 25s-18.1 6.9-25 0m49.9 23.6L57.9 59c-1.2 1.2-3.3 1.2-4.5 0L41.2 46.9c-.6-.6-.9-1.4-.9-2.2s.3-1.6.9-2.2l1.2-1.2c.6-.6 1.4-.9 2.2-.9s1.6.3 2.2.9L59 53.5c.6.6.9 1.4.9 2.2s-.2 1.5-.8 2.1" />
  </svg>
);
export default IconFlightSearch;
