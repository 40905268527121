import * as React from "react";
const IconNotOk = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      d="M14.807 23.233c-.683-.438-1.183-1.145-1.064-1.883a.5.5 0 0 1 .668-.389c1.818.675 3.846.256 5.642-1.448a.5.5 0 0 1 .818.203c.231.689.024 1.618-.48 2.382a3.8 3.8 0 0 1-2.654 1.665c-1.198.177-2.216-.073-2.93-.53M32.754 23.233c.683-.438 1.184-1.145 1.065-1.883a.5.5 0 0 0-.668-.389c-1.818.675-3.846.256-5.642-1.448a.5.5 0 0 0-.818.203c-.232.689-.024 1.618.48 2.382a3.8 3.8 0 0 0 2.653 1.665c1.199.177 2.216-.073 2.93-.53"
    />
    <path
      
      fillRule="evenodd"
      d="M17.396 31.17c-.186.285-.334.65-.431 1.006a3.7 3.7 0 0 0-.136 1.214c.03.4.172.99.699 1.377.55.403 1.212.363 1.772.187l.008-.002.008-.003c2.437-.81 3.598-1.134 4.713-1.127 1.12.006 2.254.343 4.648 1.125.507.177 1.134.215 1.656-.195.47-.368.602-.912.64-1.268a3.6 3.6 0 0 0-.075-1.123 3.5 3.5 0 0 0-.357-.973 4 4 0 0 0-.278-.403l-.018-.023C28.94 29.139 26.538 28 23.892 28c-2.742 0-5.228 1.225-6.496 3.17m1.436 2.132.002.006q0 0-.003-.01zm.062-.598q-.045.162-.06.298c2.288-.76 3.736-1.189 5.207-1.18 1.421.008 2.807.426 4.93 1.117a2 2 0 0 0-.028-.153 1.6 1.6 0 0 0-.137-.403c-.019-.033-.029-.046-.053-.078-.024-.03-.062-.078-.133-.178C27.75 30.913 26.005 30 23.892 30c-2.195 0-3.987.983-4.821 2.263a1.8 1.8 0 0 0-.177.44m10.08.628-.002.008.004-.01z"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M42 24c0 9.941-8.059 18-18 18S6 33.941 6 24 14.059 6 24 6s18 8.059 18 18m-2 0c0 8.837-7.163 16-16 16S8 32.837 8 24 15.163 8 24 8s16 7.163 16 16"
      clipRule="evenodd"
    />
  </svg>
);
export default IconNotOk;
