import * as React from "react";
const IconVrMonitor = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.6 19.2c-.6 0-1 .4-1 1v22.2H8.4V20.2c0-.6-.4-1-1-1s-1 .4-1 1v29.1c0 2.2 1.8 3.9 3.9 3.9H27l-1.3 7.6c0 .3 0 .6.2.8s.5.4.8.4h10.6c.3 0 .6-.1.8-.4.2-.2.3-.5.2-.8L37 53.2h16.6c2.2 0 3.9-1.8 3.9-3.9V20.2c.1-.5-.4-1-.9-1M36.1 60h-8.3l1.1-6.8h6zm17.5-8.8H10.4c-1.1 0-1.9-.9-1.9-1.9v-4.9h47.1v4.9c0 1.1-.9 1.9-2 1.9" />
    <path d="M20.3 29.4h7.2c.3 0 .5-.1.7-.3l3.9-3.9 3.9 3.9c.2.2.4.3.7.3h7.2c3 0 5.4-2.4 5.4-5.4v-9.4c0-3-2.4-5.4-5.4-5.4h-2.1V7.4c0-3-2.4-5.4-5.4-5.4h-8.5c-3 0-5.4 2.4-5.4 5.4v1.8h-2.1c-3 0-5.4 2.4-5.4 5.4V24c-.1 2.9 2.3 5.4 5.3 5.4M27.8 4h8.5c1.9 0 3.4 1.5 3.4 3.4v1.8H24.4V7.4c0-1.9 1.5-3.4 3.4-3.4m-7.5 7.2h23.5c1.9 0 3.4 1.5 3.4 3.4v2.6h-4.5c-.6 0-1 .4-1 1s.4 1 1 1h4.5V24c0 1.9-1.5 3.4-3.4 3.4H37l-4.3-4.3c-.4-.4-1-.4-1.4 0L27 27.4h-6.7c-1.9 0-3.4-1.5-3.4-3.4v-4.8h4.5c.6 0 1-.4 1-1s-.4-1-1-1h-4.5v-2.6c0-1.9 1.5-3.4 3.4-3.4" />
  </svg>
);
export default IconVrMonitor;
