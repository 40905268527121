import * as React from "react";
const IconServer9 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.5 37.7V26.4c0-1.1-.4-2.2-1-3L51.8 6.5c-1-2.8-3.6-4.5-6.5-4.5H18.7c-2.9 0-5.5 1.7-6.5 4.5L5.6 23.4c-.6.8-1 1.9-1 3v11.3c0 1.6.8 3.1 2 4-1.2.9-2 2.4-2 4V57c0 2.8 2.2 5 5 5h44.9c2.8 0 5-2.2 5-5V45.7c0-1.6-.8-3.1-2-4 1.2-.9 2-2.4 2-4M14 7.2c.8-2 2.6-3.2 4.7-3.2h26.6c2.1 0 3.9 1.2 4.7 3.2l5.6 14.3c-.4-.1-.7-.1-1.1-.1h-45c-.4 0-.7.1-1.1.1zm43.5 38.5V57c0 1.7-1.3 3-3 3h-45c-1.7 0-3-1.3-3-3V45.7c0-1.7 1.3-3 3-3h44.9c1.7 0 3.1 1.3 3.1 3m0-8c0 1.7-1.3 3-3 3h-45c-1.7 0-3-1.3-3-3V26.4c0-1.7 1.3-3 3-3h44.9c1.7 0 3 1.3 3 3v11.3z" />
    <path d="M17 30.8c-.6 0-1 .4-1 1v.2c0 .6.4 1 1 1s1-.4 1-1v-.2c0-.5-.4-1-1-1M49.1 30.9H43c-.6 0-1 .4-1 1s.4 1 1 1h6.2c.6 0 1-.4 1-1s-.5-1-1.1-1M17 52.3c.6 0 1-.4 1-1v-.2c0-.6-.4-1-1-1s-1 .4-1 1v.2c0 .6.5 1 1 1M43 52.2h6.2c.6 0 1-.4 1-1s-.4-1-1-1H43c-.6 0-1 .4-1 1s.4 1 1 1" />
  </svg>
);
export default IconServer9;
