import * as React from "react";
const IconTargetAchievement = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.3 48.2c3.7 0 6.6-3 6.6-6.6s-3-6.6-6.6-6.6-6.6 3-6.6 6.6 2.9 6.6 6.6 6.6m0-11.3c2.6 0 4.6 2.1 4.6 4.6s-2.1 4.6-4.6 4.6-4.6-2.1-4.6-4.6 2-4.6 4.6-4.6M61.7 56.9c-2.9-3-7-4.7-11.3-4.7s-8.4 1.7-11.3 4.7c-.4.4-.4 1 0 1.4s1 .4 1.4 0c2.5-2.6 6.1-4.1 9.9-4.1s7.4 1.5 9.9 4.1c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.3.4-1 0-1.4M30.6 27c-.2-.4-.2-.9 0-1.3l4-9c.5-1.1.4-2.4-.3-3.5s-1.8-1.7-3.1-1.7H4V6.4c0-.6-.4-1-1-1s-1 .4-1 1v51.099999999999994c0 .6.4 1 1 1s1-.4 1-1V41.2h27.3c1.2 0 2.4-.6 3.1-1.7s.8-2.3.3-3.5zm2 11.5c-.3.5-.8.8-1.4.8H4V13.5h27.3c.6 0 1.1.3 1.4.8s.4 1.1.1 1.6l-4 9c-.4.9-.4 2 0 3l4 9c.2.5.2 1.1-.2 1.6" />
    <path d="M17.4 25.9h-1.7c-.8 0-1.5-.8-1.5-1.7s.7-1.7 1.5-1.7h3.4c.6 0 1-.4 1-1s-.4-1-1-1h-1.7v-2c0-.6-.4-1-1-1s-1 .4-1 1v2c-1.8.2-3.2 1.7-3.2 3.6 0 2 1.6 3.7 3.5 3.7h1.7c.8 0 1.5.8 1.5 1.7s-.7 1.7-1.5 1.7H14c-.6 0-1 .4-1 1s.4 1 1 1h1.4v1.3c0 .6.4 1 1 1 .5 0 1-.4 1-1v-1.3c1.9 0 3.5-1.6 3.5-3.7 0-2-1.6-3.6-3.5-3.6" />
  </svg>
);
export default IconTargetAchievement;
