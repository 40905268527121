import * as React from "react";
const IconAddGroup = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M37.1 45.3h-1.7v-1.7c0-.6-.4-1-1-1s-1 .4-1 1v1.7h-1.7c-.6 0-1 .4-1 1s.4 1 1 1h1.7V49c0 .6.4 1 1 1s1-.4 1-1v-1.7h1.7c.6 0 1-.4 1-1s-.4-1-1-1" />
    <path d="M56.7 30.9c-2.8-3.3-6.5-5.4-10.5-6 4.2-1 7.3-4.8 7.3-9.2 0-5.2-4.3-9.5-9.5-9.5s-9.5 4.3-9.5 9.5c0 4.5 3.1 8.2 7.3 9.2-4 .6-7.7 2.6-10.5 6-.5.6-.9 1.2-1.3 1.8-2.3-1.7-4.9-2.8-7.6-3.2 4.2-1 7.3-4.8 7.3-9.2 0-5.2-4.3-9.5-9.5-9.5s-9.5 4.3-9.5 9.5c0 4.5 3.1 8.2 7.3 9.2-4 .6-7.7 2.6-10.5 6C4 39.4 2.1 44.8 2 50.7q0 .6.6.9c2.1 1 9.5 4.4 17.5 4.4 2.4 0 4.9-.3 7.4-.8 1.9 1.6 4.4 2.5 7 2.5 4.4 0 8.2-2.6 10-6.3 8.4-.1 15.2-3.4 17.1-4.4.3-.2.5-.5.5-.9-.2-5.8-2.1-11.3-5.4-15.2M36.5 15.7c0-4.1 3.4-7.5 7.5-7.5s7.5 3.4 7.5 7.5-3.4 7.5-7.5 7.5-7.5-3.3-7.5-7.5m-24 4.6c0-4.1 3.4-7.5 7.5-7.5s7.5 3.4 7.5 7.5-3.4 7.5-7.5 7.5-7.5-3.4-7.5-7.5M4 50.1c.2-5.2 1.9-10 4.8-13.4 3-3.5 6.9-5.4 11.2-5.4 4 0 7.8 1.7 10.7 4.9-4.3 1.5-7.4 5.6-7.4 10.5 0 2.6.9 5 2.4 6.9-1.9.3-3.8.5-5.7.5-6.9 0-13.4-2.7-16-4m30.4 5.7c-5 0-9.1-4.1-9.1-9.1s4.1-9.1 9.1-9.1 9.1 4.1 9.1 9.1-4 9.1-9.1 9.1m10.8-6.3c.2-.9.4-1.8.4-2.8 0-6.1-5-11.1-11.1-11.1-.5 0-1 0-1.5.1l-.2-.2c-.4-.5-.9-1-1.4-1.4.4-.6.8-1.2 1.3-1.8 3-3.5 6.9-5.4 11.2-5.4s8.3 1.9 11.2 5.4c2.9 3.4 4.6 8.2 4.8 13.4-2.2 1.1-7.8 3.5-14.7 3.8" />
  </svg>
);
export default IconAddGroup;
