import * as React from "react";
const IconZm = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 85.333 512 341.333"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 85.331h512v341.337H0z" />
    <path
      
      d="M490.668 195.476h-48c0-8.836-7.164-16-16-16s-16 7.164-16 16h-48c0 8.836 7.697 16 16.533 16h-.533c0 8.836 7.162 16 16 16 0 8.836 7.162 16 16 16h32c8.836 0 16-7.164 16-16 8.836 0 16-7.164 16-16h-.533c8.837 0 16.533-7.164 16.533-16"
    />
    <path  d="M341.337 255.994h56.888v170.663h-56.888z" />
    <path  d="M455.112 255.994H512v170.663h-56.888z" />
    <path d="M398.225 255.994h56.888v170.663h-56.888z" />
  </svg>
);
export default IconZm;
