import * as React from "react";
const IconTaka = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M42.8 37.9c-.5-.2-1.1 0-1.3.5s0 1.1.5 1.3c3.6 1.5 5.9 4.9 5.9 8.7 0 5.2-4.3 9.5-9.6 9.5s-9.6-4.3-9.6-9.5V23.7h19.1c.6 0 1-.4 1-1s-.4-1-1-1H28.7v-11C28.7 7 25.7 4 22 4h-1.3c-3.7 0-6.6 3-6.6 6.6 0 .6.4 1 1 1s1-.4 1-1C16.1 8 18.2 6 20.7 6H22c2.6 0 4.6 2.1 4.6 4.6v11h-9c-.6 0-1 .4-1 1s.4 1 1 1h9v24.8c0 6.3 5.2 11.5 11.6 11.5s11.6-5.2 11.6-11.5c0-4.5-2.7-8.7-7-10.5" />
  </svg>
);
export default IconTaka;
