import * as React from "react";
const IconFactory = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.2 37.1 44.8 24.6c-1.4-1.2-3.3-1.4-5-.8l-23.4 9.5V8.6c0-2.8-2.2-5-5-5H8.2c-2.8 0-5 2.2-5 5v30.1l-.5.2c-.5.1-.7.5-.7.9v15.6c0 2.8 2.2 5 5 5h50c2.8 0 5-2.2 5-5V40.9c0-1.5-.7-2.9-1.8-3.8M5.2 8.6c0-1.6 1.3-3 3-3h3.3c1.6 0 3 1.3 3 3v25.5l-9.3 3.8zM60 55.4c0 1.6-1.3 3-3 3H7c-1.6 0-3-1.3-3-3V40.5l36.6-14.8c1-.4 2.2-.2 3 .5l15.3 12.5c.7.6 1.1 1.4 1.1 2.3z" />
    <path d="M17.6 46.8h-4.3c-.6 0-1 .4-1 1s.4 1 1 1h4.3c.6 0 1-.4 1-1s-.4-1-1-1M34.9 46.8h-4.3c-.6 0-1 .4-1 1s.4 1 1 1h4.3c.6 0 1-.4 1-1s-.4-1-1-1M51.4 46.8H47c-.6 0-1 .4-1 1s.4 1 1 1h4.3c.6 0 1-.4 1-1s-.4-1-.9-1" />
  </svg>
);
export default IconFactory;
