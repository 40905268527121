import * as React from "react";
const IconFlagsAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m59 23.2-2.8-.7 1.1-1.8c.7-1.1.8-2.5.3-3.7s-1.5-2.1-2.8-2.4L42 11.2c-1.1-.3-2.2-.1-3.1.4-1 .5-1.6 1.4-1.9 2.5l-1.7 6.2L32 32.7l-1.9-7L27 14.1c-.3-1.1-1-1.9-1.9-2.5-1-.5-2.1-.7-3.1-.4L9.2 14.6c-1.3.3-2.3 1.2-2.8 2.4s-.4 2.6.3 3.7l1.1 1.8-2.8.7c-1.1.3-1.9 1-2.5 1.9s-.7 2.1-.4 3.1l2 7.6c.3 1.1 1 1.9 1.9 2.5q.9.6 2.1.6c.4 0 .7 0 1.1-.1L20 35.9c1.3-.3 2.3-1.2 2.8-2.4s.4-2.6-.3-3.7L21.4 28l6.8-1.8L31 36.5l-4.1 15.2c-.1.5.2 1.1.7 1.2h.3c.4 0 .8-.3 1-.7L32 40.4l3.2 11.8c.1.4.5.7 1 .7h.3c.5-.1.8-.7.7-1.2L33 36.5l2.8-10.4 6.8 1.8-1.1 1.8c-.7 1.1-.8 2.5-.3 3.7s1.5 2.1 2.8 2.4l10.8 2.9c.4.1.7.1 1.1.1q1.05 0 2.1-.6c1-.5 1.6-1.4 1.9-2.5l2-7.6c.5-2-.8-4.3-2.9-4.9m-38.3 7.6c.4.6.4 1.3.2 1.9-.3.6-.8 1.1-1.4 1.3L8.7 36.9c-.5.1-1.1.1-1.6-.2s-.8-.7-1-1.3l-2-7.6c-.1-.5-.1-1.1.2-1.6s.7-.8 1.3-1l3.3-.9 2.1 3.5c.8 1.2 2.1 2 3.5 2 .4 0 .7 0 1.1-.1l3.7-1zm-5.6-3.2c-.9.2-1.9-.1-2.4-.9l-4.3-7c-.4-.6-.4-1.3-.2-1.9s.8-1.1 1.4-1.3l12.8-3.4c.2 0 .4-.1.6-.1.4 0 .7.1 1.1.3.5.3.8.7 1 1.3l.2.9 2.3 8.7zm22.5-8 1.1-4.1.2-.9c.1-.5.5-1 1-1.3s1.1-.4 1.6-.2l12.8 3.4c.6.2 1.2.6 1.4 1.3.3.6.2 1.3-.2 1.9l-4.3 7c-.5.8-1.5 1.2-2.4.9l-12.6-3.4zm22.3 8.1-2 7.6c-.1.5-.5 1-1 1.3s-1.1.4-1.6.2L44.5 34c-.6-.2-1.2-.6-1.4-1.3-.3-.6-.2-1.3.2-1.9l1.4-2.3 3.7 1c.3.1.7.1 1.1.1 1.4 0 2.8-.7 3.5-2l2.1-3.5 3.4.9c1.1.4 1.7 1.6 1.4 2.7" />
  </svg>
);
export default IconFlagsAlt;
