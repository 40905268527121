import * as React from "react";
const IconUiFolderFamily = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      d="M18.5 24a3 3 0 1 1-6 0 3 3 0 0 1 6 0M32.5 27a3 3 0 1 0 0-6 3 3 0 0 0 0 6M31 29a3 3 0 0 0-3 3v2h9v-2a3 3 0 0 0-3-3zM14 29a3 3 0 0 0-3 3v2h9v-2a3 3 0 0 0-3-3zM26 26.5a2 2 0 1 1-4 0 2 2 0 0 1 4 0M24 30a3 3 0 0 0-3 3v1h6v-1a3 3 0 0 0-3-3"
    />
    <path
      
      fillRule="evenodd"
      d="M24.712 10.555 27.154 15H39a3 3 0 0 1 3 3v18a3 3 0 0 1-3 3H9a3 3 0 0 1-3-3V12a3 3 0 0 1 3-3h13.083a3 3 0 0 1 2.629 1.555M9 37a1 1 0 0 1-1-1V17h31a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1zm15.872-22-1.913-3.482a1 1 0 0 0-.876-.518H9a1 1 0 0 0-1 1v3z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconUiFolderFamily;
