import * as React from "react";
const IconBedWedding = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m60.5 38.2-3.8-3.5V22c0-2.6-2.1-4.6-4.6-4.6h-41c-2.6 0-4.6 2.1-4.6 4.6v12.7l-3.3 3.5c-.8.9-1.2 2-1.2 3.1v15.5c0 2.6 2.1 4.6 4.6 4.6h4.1c2.3 0 4.2-1.6 4.6-3.9.2-1.3 1.3-2.2 2.6-2.2h29.7c1.3 0 2.4.9 2.6 2.3.4 2.2 2.3 3.9 4.6 3.9h2.6c2.6 0 4.6-2.1 4.6-4.6V41.6c0-1.3-.5-2.5-1.5-3.4M11.1 19.4H52c1.5 0 2.6 1.2 2.6 2.6v12.1h-3.4v-4.2c0-2.6-2.1-4.6-4.6-4.6h-7c-2.6 0-4.6 2.1-4.6 4.6v4.2h-6.3v-4.2c0-2.6-2.1-4.6-4.6-4.6h-7c-2.6 0-4.6 2.1-4.6 4.6v4.2h-4V22c0-1.4 1.2-2.6 2.6-2.6m26 14.7v-4.2c0-1.5 1.2-2.6 2.6-2.6h7c1.5 0 2.6 1.2 2.6 2.6v4.2zm-22.6 0v-4.2c0-1.5 1.2-2.6 2.6-2.6h7c1.5 0 2.6 1.2 2.6 2.6v4.2zm-9.8 5.5 3.2-3.5h47.4l3.8 3.5c.5.5.9 1.2.9 1.9V46H4v-4.7c0-.5.2-1.2.7-1.7m52.7 19.9h-2.6c-1.3 0-2.4-.9-2.6-2.3-.4-2.2-2.3-3.9-4.6-3.9H17.9c-2.3 0-4.2 1.6-4.6 3.9-.2 1.3-1.3 2.2-2.6 2.2H6.6c-1.5 0-2.6-1.2-2.6-2.6V48h56v8.8c0 1.5-1.2 2.7-2.6 2.7M30.5 15c.4.4.9.6 1.4.6s1-.2 1.4-.6l4.2-4.2c1-.9 1.5-2.1 1.5-3.4s-.5-2.5-1.4-3.5C36.1 2.4 33.8 2 31.9 3c-.7-.4-1.4-.5-2.2-.5-1.2 0-2.4.4-3.3 1.3-1 .9-1.5 2.1-1.5 3.4 0 1.4.5 2.8 1.5 3.8zm-2.7-9.8c.5-.5 1.2-.7 1.9-.7.6 0 1.1.2 1.6.5.3.2.8.2 1.1 0 1.1-.8 2.7-.7 3.7.3.5.5.8 1.3.8 2 0 .8-.3 1.5-.8 2l-4.2 4.2-4.1-4.1c-.6-.6-.9-1.5-.9-2.3 0-.7.3-1.4.9-1.9" />
  </svg>
);
export default IconBedWedding;
