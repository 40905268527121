import * as React from "react";
const IconPlaneCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.4 35.6 56 30.2c-.7-.7-1.9-.9-2.8-.3l-7.5 4.7q-.15.15-.3 0L37 26v-.2s0-.1.1-.2l15.7-12.9c.5-.4.8-1 .8-1.6s-.2-1.2-.6-1.7l-6.7-6.7c-.7-.7-1.9-.9-2.8-.3L25.8 14.2q-.15.15-.3 0l-5-5.1c-1.5-1.5-3.4-2.3-5.6-2.3-2.1 0-4.1.9-5.6 2.4-3.2 3.1-3.3 8.2-.2 11.3l5.1 5q.15.15 0 .3L2.4 43.5c-.6.9-.5 2.1.3 2.8L9.4 53c.4.4 1 .7 1.7.6.6 0 1.2-.3 1.6-.8L25.6 37c.1-.1.2-.1.2-.1s.1 0 .2.1l8.5 8.4q.15.15 0 .3l-4.6 7.4c-.5.9-.4 2 .3 2.8l5.4 5.4c.4.4 1 .6 1.6.6h.3c.7-.1 1.3-.5 1.7-1.2l7.7-13.9 13.9-7.7c.6-.4 1.1-1 1.2-1.7.1-.6-.2-1.3-.6-1.8m-1.5 1.8-14.4 8-8 14.4c-.1.2-.2.2-.2.2s-.1 0-.2-.1l-5.4-5.4q-.15-.15 0-.3l4.6-7.4c.6-.9.4-2-.3-2.8l-8.5-8.4c-.4-.4-1-.6-1.6-.6h-.1c-.6 0-1.2.3-1.6.8L11.1 51.6c-.1.1-.2.1-.2.1s-.1 0-.2-.1L4 44.9v-.3l11.8-17.7c.6-.9.5-2.1-.3-2.8l-5.1-5c-2.3-2.3-2.2-6.1.1-8.4 1.1-1.1 2.6-1.8 4.2-1.8 1.6-.1 3.1.6 4.2 1.7l5 5.1c.7.8 1.9.9 2.8.3L44.6 4q.15-.15.3 0l6.7 6.7c.1.1.1.1.1.2 0 0 0 .1-.1.2l-15.8 13c-.5.4-.8 1-.8 1.6s.2 1.2.6 1.7l8.3 8.6c.7.8 1.9.9 2.8.3l7.5-4.7q.15-.15.3 0l5.4 5.4c.1.1.1.1.1.2s0 .2-.1.2" />
  </svg>
);
export default IconPlaneCopy;
