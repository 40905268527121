import * as React from "react";
const IconShoppingCart2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.8 2.7c-.2-.5-.8-.8-1.3-.6l-7.6 2.8c-.4.1-.7.5-.7.9v28.6c0 2.5-2 4.5-4.5 4.5h-31c-2.5 0-4.5-2-4.5-4.5v-15c0-.6-.4-1-1-1s-1 .4-1 1v14.9c0 3.6 2.9 6.5 6.5 6.5h31c1.8 0 3.3-.7 4.5-1.8v6.8c0 2.5-2 4.5-4.5 4.5H14.5c-3.2 0-5.8 2.6-5.8 5.8s2.6 5.8 5.8 5.8 5.8-2.6 5.8-5.8c0-1.5-.5-2.8-1.4-3.8h15c-.9 1-1.4 2.4-1.4 3.8 0 3.2 2.6 5.8 5.8 5.8s5.8-2.6 5.8-5.8c0-1.5-.5-2.8-1.4-3.8h1.1c3.6 0 6.5-2.9 6.5-6.5V6.5L57.2 4c.5-.2.8-.8.6-1.3M18.3 56.2c0 2.1-1.7 3.8-3.8 3.8s-3.8-1.7-3.8-3.8 1.7-3.8 3.8-3.8 3.8 1.7 3.8 3.8m23.8 0c0 2.1-1.7 3.8-3.8 3.8s-3.8-1.7-3.8-3.8 1.7-3.8 3.8-3.8c2.1-.1 3.8 1.7 3.8 3.8" />
    <path d="M29.1 26.8h-7.8c-.6 0-1 .4-1 1s.4 1 1 1h6.2v2.6c0 .6.4 1 1 1s1-.4 1-1v-2.6c2.7-.2 4.8-2.4 4.8-5.1 0-2.8-2.3-5.2-5.2-5.2h-3.6c-1.8 0-3.2-1.4-3.2-3.2 0-1.7 1.4-3.2 3.2-3.2h7.8c.6 0 1-.4 1-1s-.4-1-1-1h-3.8V7.6c0-.6-.4-1-1-1s-1 .4-1 1v2.6h-2c-2.9 0-5.2 2.3-5.2 5.2s2.3 5.2 5.2 5.2h3.6c1.8 0 3.2 1.4 3.2 3.2s-1.5 3-3.2 3" />
  </svg>
);
export default IconShoppingCart2;
