import * as React from "react";
const IconWeddingArch = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.9 24.6c-.1-11-10.5-18.2-11-18.4-.1-.1-3.4-2.3-8.3-3.5-5.9-1.4-11.7-.7-17 2C8.7 10.8 7.3 22 7.1 24.7v32.4c0 2.7 2.2 4.9 4.9 4.9h6.7c2.7 0 4.9-2.2 4.9-4.9V36.5c0-.3.4-7-6.1-10.6l-1.2-.6c3.2-.6 6.8-1.9 9.8-4.5l.2.2 4.4 4.4q.6.6 1.5.6c.9 0 1.1-.2 1.5-.6l4.5-4.5.3-.3c2.9 2.4 6.3 3.6 9.3 4.1-.7.3-1.2.6-1.2.6-5.8 4-5.9 10.8-5.9 11.1V57c0 2.7 2.2 4.9 4.9 4.9h6.8c2.7 0 4.9-2.2 4.9-4.8-.3-8.7-.3-24.6-.4-32.5M21.5 36.5v20.6c0 1.6-1.3 2.9-2.9 2.9h-6.7C10.3 60 9 58.7 9 57.1v-31c1-.1 2.1 0 3.4.2 1.4.3 2.9.8 3.9 1.4 5.5 3 5.2 8.5 5.2 8.8M32 24.1h-.1l-4.4-4.4c-.7-.7-1-1.6-1-2.5s.4-1.6 1-2.2c.6-.5 1.3-.8 2.1-.8q.9 0 1.8.6c.3.2.8.2 1.1 0 1.2-.9 2.9-.7 4 .4.6.6.9 1.4.9 2.2s-.3 1.6-.9 2.2zm5.9-10.4c-1.6-1.6-4-2-6-.9-.7-.4-1.5-.5-2.3-.6-1.3 0-2.5.5-3.5 1.3-1 .9-1.6 2.2-1.6 3.6 0 .7.1 1.4.3 2-5.3 4.8-13 4.8-15.7 4.7.4-3.5 2.5-12.3 12.3-17.3 5-2.5 10.3-3.1 15.7-1.9 4.6 1.1 7.6 3.2 7.7 3.2.1.1 9 6.2 10 15.4-2.6.2-10.2.3-15.6-4.3.1-.5.2-1 .2-1.5.1-1.4-.5-2.7-1.5-3.7m17 43.5c0 1.6-1.3 2.8-2.9 2.8h-6.8c-1.6 0-2.9-1.3-2.9-2.9V36.5c0-.2.1-6 5-9.4 0 0 2.7-1.7 6.6-1.7h.9c.2 8.1.2 23.2.1 31.8" />
  </svg>
);
export default IconWeddingArch;
