import * as React from "react";
const IconFolderCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.7 20.2v-5.1c0-1.9-1.5-3.4-3.4-3.4H31.1l-.9-2.3c-.4-.9-1.3-1.5-2.2-1.5H8.2C5.3 7.8 3 10.2 3 13v38c0 2.9 2.3 5.2 5.2 5.2h47.6c2.9 0 5.2-2.3 5.2-5.2V22.3c0-.9-.5-1.7-1.3-2.1m-3.4-6.5c.8 0 1.4.6 1.4 1.4v4.8H34.4l-2.5-6.2zM59 51c0 1.8-1.4 3.2-3.2 3.2H8.2C6.4 54.2 5 52.7 5 51V13c0-1.8 1.4-3.2 3.2-3.2H28c.2 0 .3.1.4.3l4.2 10.6c.3.8 1 1.3 1.9 1.3h24.2c.2 0 .4.2.4.4V51z" />
  </svg>
);
export default IconFolderCopy;
