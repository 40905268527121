import * as React from "react";
const IconTaxi = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M15.6 41.2h6.1c1.2 0 2.2-1 2.2-2.2s-1-2.2-2.2-2.2h-6.1c-1.2 0-2.2 1-2.2 2.2s1 2.2 2.2 2.2M41.1 41.2h6.1c1.2 0 2.2-1 2.2-2.2s-1-2.2-2.2-2.2h-6.1c-1.2 0-2.2 1-2.2 2.2s1 2.2 2.2 2.2" />
    <path d="M60 24.6h-3.9l-4.5-11.5c-.6-1.8-2.4-3-4.4-3h-5.1l-.7-2.3c-.7-2.1-2.6-3.6-4.8-3.6h-9.2c-2.2 0-4.2 1.4-4.8 3.6l-.7 2.3h-5.7c-1.9 0-3.6 1.2-4.4 3L7.2 24.6H4c-1.2 0-2.2 1-2.2 2.2S2.8 29 4 29h1.5v15.8c0 2.6 1.9 4.7 4.3 5.2v3.5c0 3.4 2.8 6.2 6.2 6.2h1.7c3.4 0 6.2-2.8 6.2-6.2v-3.4h14.8v3.4c0 3.4 2.8 6.2 6.2 6.2h1.7c3.4 0 6.2-2.8 6.2-6.2V50c2.7-.2 4.8-2.5 4.8-5.2V29H60c1.2 0 2.2-1 2.2-2.2s-1-2.2-2.2-2.2M26.9 9.1c.1-.2.3-.4.5-.4h9.2c.2 0 .5.2.5.4l.3 1H26.6zm-11 5.6s.1-.2.2-.2h31.1c.1 0 .2.1.2 0v.1L52.3 27H11.1zm3.6 38.8c0 1-.8 1.8-1.8 1.8H16c-1 0-1.8-.8-1.8-1.8v-3.4h5.2v3.4zm29 0c0 1-.8 1.8-1.8 1.8H45c-1 0-1.8-.8-1.8-1.8v-3.4h5.2v3.4zm4.9-8.6c0 .4-.3.8-.8.8H10.8c-.4 0-.8-.3-.8-.8V31.5h43.4z" />
  </svg>
);
export default IconTaxi;
