import * as React from "react";
const IconSexualReproductiveHealth = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M17.546 15.48a7 7 0 1 0 4.372 5.454 1 1 0 1 1 1.977-.304 9 9 0 1 1-5.622-7.014 1 1 0 1 1-.727 1.863"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M14 39v-8a1 1 0 1 1 2 0v8a1 1 0 1 1-2 0"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M11 34a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2h-6a1 1 0 0 1-1-1M28.902 15.263a7 7 0 0 0-8.853 7.562 1 1 0 1 1-1.986.236 9 9 0 1 1 5.375 7.204 1 1 0 0 1 .791-1.837 7 7 0 1 0 4.673-13.165"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M32.793 17.207a1 1 0 0 1 0-1.414l7.5-7.5a1 1 0 1 1 1.414 1.414l-7.5 7.5a1 1 0 0 1-1.414 0"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M40.924 15a1 1 0 0 1-.987-1.013l.05-3.974-3.974.05a1 1 0 1 1-.026-2l6.026-.076-.076 6.026a1 1 0 0 1-1.013.987"
      clipRule="evenodd"
    />
  </svg>
);
export default IconSexualReproductiveHealth;
