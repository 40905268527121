import * as React from "react";
const IconDatabase2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M44.8 38.3c-3.1 0-5.6 2.5-5.6 5.6s2.5 5.6 5.6 5.6 5.6-2.5 5.6-5.6-2.5-5.6-5.6-5.6m0 9.1c-2 0-3.6-1.6-3.6-3.6s1.6-3.6 3.6-3.6 3.6 1.6 3.6 3.6-1.6 3.6-3.6 3.6" />
    <path d="m61.3 47.7-3.4-1.9c.1-.7.2-1.4.2-2.1 0-.8-.1-1.5-.2-2.1l3.5-2c.6-.4.8-1.1.6-1.8l-3.3-5.6c-.4-.6-1.1-.8-1.7-.6l-3.6 2c-.2-.2-.4-.4-.6-.5-.6-.5-1.2-1-2.1-1.4-.2-.1-.3-.2-.3-.4v-2.2c0-1.4-1.1-2.5-2.5-2.5h-3.2V11.1C44.5 5.8 33.6 3 23.3 3S2.1 5.8 2 11.1v25.4c0 5.2 10.8 8.3 21.2 8.3 1.9 0 3.7-.1 5.5-.3.5-.1.9-.5.9-1.1-.1-.5-.6-.9-1.1-.9-1.7.2-3.5.3-5.3.3-11 0-19.2-3.3-19.2-6.3V32c3.6 3 11.6 4.6 19.3 4.6 1.8 0 3.7-.1 5.4-.3l-.8 1.4c-.2.3-.2.7-.1 1.1s.3.7.7.8l3.4 1.9c-.1.6-.2 1.3-.2 2.1 0 .7 0 1.4.1 2l-3.5 2c-.6.4-.8 1.1-.6 1.8L31 55c.4.6 1.1.8 1.7.6l3.6-2c.2.2.4.4.6.5.6.5 1.3 1.1 2.1 1.5.2.1.3.2.3.4v2.5c0 1.4 1.1 2.5 2.5 2.5h5.9c1.4 0 2.5-1.1 2.5-2.5V56c0-.2.1-.3.3-.4.8-.4 1.4-.9 2-1.5.2-.2.4-.3.6-.5l3.3 1.8c1.1.6 1.8.2 2.1-.4l3.3-5.5c.2-.3.2-.7.1-1.1-.1-.3-.3-.6-.6-.7M4 14.8c3.6 3 11.6 4.6 19.3 4.6 7.6 0 15.6-1.6 19.2-4.6v5.5c-.1 2.9-7.9 6.2-19.2 6.2C11.9 26.5 4 23.2 4 20.3zM23.3 5c11.3 0 19.2 3.3 19.2 6.2-.1 2.9-7.9 6.2-19.2 6.2C11.9 17.4 4 14.1 4 11.2S11.9 5 23.3 5m0 29.7C11.9 34.7 4 31.4 4 28.4v-4.5c3.6 3 11.6 4.6 19.3 4.6 7.6 0 15.6-1.6 19.2-4.6v2.7h-.6c-1.4 0-2.5 1.1-2.5 2.5v2.3c0 .2-.1.3-.3.4-.9.4-1.4.9-2.1 1.5-.2.2-.4.3-.6.5L33.2 32c-1.1-.6-1.8-.2-2.1.4L30 34.3c-2.2.2-4.5.4-6.7.4m33.8 18.8-3-1.7c-.7-.4-1.5-.3-2.1.2-.3.2-.5.4-.7.6-.6.5-1 .9-1.6 1.2-.8.4-1.4 1.3-1.4 2.2v2.6c0 .3-.2.5-.5.5h-5.9c-.3 0-.5-.2-.5-.5v-2.5c0-.9-.6-1.8-1.4-2.2-.6-.3-1.1-.7-1.7-1.2-.2-.2-.5-.4-.7-.6-.3-.3-.8-.4-1.2-.4-.3 0-.6.1-.9.2l-3 1.7-2.6-4.4 3-1.7c.7-.4 1.1-1.2 1-2-.1-.6-.1-1.2-.1-1.8 0-.7 0-1.2.1-1.7.1-.9-.2-1.7-1-2.1L30 38.3l2.6-4.4 3 1.7c.7.4 1.5.3 2.1-.2.2-.2.5-.4.7-.6.6-.5 1.1-.9 1.7-1.2.9-.4 1.4-1.3 1.4-2.2v-2.3c0-.3.2-.5.5-.5h5.9c.3 0 .5.2.5.5v2.2c0 .9.6 1.8 1.4 2.2.6.3 1.1.7 1.6 1.2.2.2.5.4.8.6.6.5 1.4.5 2.1.2l3-1.7 2.6 4.4-2.9 1.6c-.7.4-1.1 1.2-1 2.1v1.8c0 .6 0 1.2-.1 1.8-.1.8.3 1.7 1 2.1l2.9 1.6z" />
  </svg>
);
export default IconDatabase2;
