import * as React from "react";
const IconNightWind = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M44.5 57.2c-2 1-4.1 1.7-6.3 2.2-15 3.3-29.6-5.8-33.2-20.7-.4-1.8-.7-3.6-.8-5.4-.5-10.1 4.8-20 13.5-25.2 5-3 10.5-4.4 16.2-4-4 4-6.6 9.2-7.4 14.8-.1.5.3 1.1.8 1.1.5.1 1.1-.3 1.1-.8.7-5.1 3.2-10 6.8-13.6.6-.6.8-1.4.5-2.1s-1-1.2-1.8-1.3c-6.1-.4-12 1.1-17.3 4.3-9.3 5.6-15 16.2-14.4 27 .1 2 .4 3.9.9 5.8C6.4 52.9 18.5 62 32 62c2.2 0 4.4-.2 6.6-.7 2.4-.5 4.6-1.3 6.8-2.4.5-.2.7-.8.5-1.3-.3-.5-.9-.7-1.4-.4" />
    <path d="M52.7 21.3c2.2 0 3.9 1.8 3.9 3.9s-1.8 3.9-3.9 3.9H24.4c-.6 0-1 .4-1 1s.4 1 1 1h28.3c3.3 0 5.9-2.7 5.9-5.9 0-3.3-2.7-5.9-5.9-5.9-.6 0-1 .4-1 1s.4 1 1 1M30.1 37.5c-.6 0-1 .4-1 1s.4 1 1 1h19.4c.6 0 1-.4 1-1s-.4-1-1-1zM56 45.9H27.7c-.6 0-1 .4-1 1s.4 1 1 1H56c2.2 0 3.9 1.8 3.9 3.9 0 1.1-.5 2.1-1.3 2.9-.4.4-.4 1-.1 1.4.2.2.5.3.7.3s.5-.1.7-.3c1.2-1.1 1.9-2.7 1.9-4.4.1-3.1-2.6-5.8-5.8-5.8" />
  </svg>
);
export default IconNightWind;
