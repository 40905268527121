import * as React from "react";
const IconSpinner7 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M35.5 1.5h-7c-.8 0-1.6.3-2.1.9s-.8 1.4-.7 2.2l.7 7.2c.1 1.5 1.4 2.6 2.8 2.6h5.5c1.5 0 2.7-1.1 2.8-2.6l.7-7.2c.1-.8-.2-1.6-.7-2.2-.4-.6-1.2-.9-2-.9M34.8 49.5h-5.5c-1.5 0-2.7 1.1-2.8 2.6l-.7 7.2c-.1.8.2 1.6.7 2.2s1.3.9 2.1.9h7c.8 0 1.6-.3 2.1-.9s.8-1.4.7-2.2l-.7-7.2c-.2-1.4-1.5-2.6-2.9-2.6M61.6 26.4c-.6-.5-1.4-.8-2.2-.7l-7.2.7c-1.5.1-2.6 1.4-2.6 2.8v5.5c0 1.5 1.1 2.7 2.6 2.8l7.2.7h.3c.7 0 1.4-.3 1.9-.7.6-.5.9-1.3.9-2.1v-7c0-.7-.3-1.5-.9-2M14 34.8v-5.5c0-1.2-.9-2.2-2.1-2.4l-7.2-.7c-.7-.1-1.3.2-1.8.6-.6.4-.9 1.1-.9 1.7v7c0 .7.3 1.3.8 1.8.4.4 1 .6 1.6.6h.2l7.2-.7C13 37 14 36 14 34.8m-9.6 1c-.1 0-.2-.1-.3-.1 0 0-.1-.1-.1-.3v-7c0-.1.1-.2.1-.3 0 0 .1-.1.2-.1l7.2.7c.2 0 .3.2.3.4v5.5c0 .2-.1.3-.3.4zM50.2 42.3c-1.1-.9-2.8-.9-3.8.2l-3.9 3.9c-1 1-1.1 2.7-.2 3.8l4.6 5.6c.5.6 1.3 1 2.1 1h.1c.8 0 1.5-.3 2-.8l4.9-4.9c.6-.6.9-1.4.8-2.2 0-.8-.4-1.6-1-2.1zM16.7 8.5c-.4-.5-1-.8-1.7-.9-.7 0-1.3.2-1.8.7l-4.9 4.9c-.5.5-.7 1.1-.7 1.8s.3 1.3.9 1.7l5.6 4.6c.4.4 1 .5 1.5.5.6 0 1.2-.2 1.7-.7l3.9-3.9c.9-.9.9-2.2.2-3.2zm3.1 7.4-3.9 3.9c-.1.1-.3.1-.5 0l-5.6-4.6c-.1-.1-.1-.2-.1-.3s0-.2.1-.3l4.9-4.9c.1-.1.2-.1.3-.1s.2 0 .3.1l4.6 5.6c0 .2 0 .4-.1.6M17.3 42.8c-.9-.9-2.2-.9-3.2-.2l-5.6 4.6c-.5.4-.8 1-.9 1.7 0 .7.2 1.3.7 1.8l4.9 4.9c.4.4 1 .7 1.7.7h.1c.7 0 1.3-.3 1.7-.9l4.6-5.6c.8-.9.7-2.3-.2-3.2zm2.5 5.8-4.6 5.6c-.1.1-.2.1-.3.1s-.2 0-.3-.1l-4.9-4.9c-.1-.1-.1-.2-.1-.3s0-.2.1-.3l5.6-4.6c.1-.1.1-.1.2-.1s.2 0 .3.1l3.9 3.9c.2.3.2.5.1.6M46.4 21.6c.6.6 1.3.8 2 .8.6 0 1.3-.2 1.8-.7l5.6-4.6c.6-.5 1-1.3 1-2.1s-.3-1.6-.8-2.2L51.1 8c-.6-.6-1.4-.9-2.2-.8-.8 0-1.6.4-2.1 1l-4.6 5.6c-.9 1.1-.9 2.8.2 3.8z" />
  </svg>
);
export default IconSpinner7;
