import * as React from "react";
const IconVideoCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M17.3 23.1c3.3 0 6-2.8 6-6.2s-2.7-6.2-6-6.2-6 2.8-6 6.2 2.7 6.2 6 6.2m0-10.3c2.2 0 4 1.9 4 4.2s-1.8 4.2-4 4.2-4-1.9-4-4.2c0-2.4 1.8-4.2 4-4.2M31.6 23.1c3.7 0 6.8-3.1 6.8-7s-3-7-6.8-7c-3.7 0-6.8 3.1-6.8 7s3.1 7 6.8 7m0-12c2.6 0 4.8 2.2 4.8 5s-2.1 5-4.8 5-4.8-2.2-4.8-5 2.2-5 4.8-5M60.9 30.3c-.7-.6-1.6-.8-2.5-.6l-9.9 2.2v-.6c0-3.3-2.7-6-6-6H9c-3.3 0-6 2.7-6 6V49c0 3.3 2.7 6 6 6h33.4c3.3 0 6-2.7 6-6v-1.8l9.9 2.2c.2 0 .4.1.7.1.7 0 1.3-.2 1.9-.7.7-.6 1.1-1.4 1.1-2.3V32.6c0-.9-.4-1.8-1.1-2.3M46.4 48.9c0 2.2-1.8 4-4 4H9c-2.2 0-4-1.8-4-4V31.3c0-2.2 1.8-4 4-4h33.4c2.2 0 4 1.8 4 4zM60 46.4c0 .3-.1.6-.4.8-.2.2-.5.3-.8.2l-10.4-2.3V33.9l10.4-2.3c.3-.1.6 0 .8.2s.4.5.4.8z" />
  </svg>
);
export default IconVideoCopy;
