import * as React from "react";
const IconBurgerAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M3 27.6h58c.6 0 1-.4 1-1C62 13 51 2 37.4 2H26.6C13 2 2 13 2 26.6c0 .5.4 1 1 1M26.6 4h10.9c12.1 0 22 9.6 22.5 21.6H4C4.5 13.6 14.5 4 26.6 4M61 36.4H3c-.6 0-1 .4-1 1C2 51 13 62 26.6 62h10.9C51 62 62 51 62 37.4c0-.5-.4-1-1-1M37.4 60H26.6C14.5 60 4.5 50.4 4 38.4h56C59.5 50.4 49.5 60 37.4 60" />
  </svg>
);
export default IconBurgerAlt;
