import * as React from "react";
const IconCaravanCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M17.8 20.5h-5.9c-1.1 0-2 .9-2 2v5.9c0 1.1.9 2 2 2h5.9c1.1 0 2-.9 2-2v-5.9c0-1.2-.9-2-2-2m-5.9 7.9v-5.9h5.9v5.9z" />
    <path d="M61 41.6h-6.9V26.7q0-.45-.3-.9l-7.7-12.4c-.3-.5-.9-.8-1.4-.8h-41c-.9 0-1.7.8-1.7 1.7v27.6c0 .9.8 1.7 1.7 1.7h3.8c-.1.5-.2 1.1-.2 1.6 0 3.5 2.8 6.3 6.3 6.3s6.3-2.8 6.3-6.3c0-.6-.1-1.1-.2-1.6H61c.6 0 1-.4 1-1s-.4-1-1-1m-47.5 7.9c-2.4 0-4.3-1.9-4.3-4.3s1.9-4.3 4.3-4.3 4.3 1.9 4.3 4.3-1.9 4.3-4.3 4.3m25.4-7.9H33V25.1h5.9zm13.2 0H40.9V24.5c0-.8-.6-1.4-1.4-1.4h-7.1c-.8 0-1.4.6-1.4 1.4v17.1H18.8v.3c-1.1-1.7-3.1-2.9-5.3-2.9-2.1 0-4 1-5.1 2.6H4v-27h40.5l7.6 12.2z" />
  </svg>
);
export default IconCaravanCopy;
