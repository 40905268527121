import * as React from "react";
const IconTaxiCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M14.5 40.7H21c.6 0 1-.4 1-1s-.4-1-1-1h-6.5c-.6 0-1 .4-1 1s.5 1 1 1M41.7 40.7h6.5c.6 0 1-.4 1-1s-.4-1-1-1h-6.5c-.6 0-1 .4-1 1s.5 1 1 1" />
    <path d="M61 25.8h-4.2l-5.2-13.1c-.5-1.4-1.9-2.3-3.4-2.3h-6.5v-.2l-1-3.3c-.5-1.7-2.1-2.8-3.8-2.8h-9.8c-1.8 0-3.3 1.1-3.8 2.8l-1 3.2v.2h-7.2c-1.5 0-2.8.9-3.4 2.3L6.5 25.8H3c-.6 0-1 .4-1 1s.4 1 1 1h2.7L5.2 29c0 .1-.1.2-.1.3v16.9c0 2.2 1.8 4 4 4h.6v6.3c0 2.2 1.8 4 4 4H18c2.2 0 4-1.8 4-4v-6.3h18.7v6.3c0 2.2 1.8 4 4 4H49c2.2 0 4-1.8 4-4v-6.3h1.2c2.2 0 4-1.8 4-4V29.4c0-.2-.1-.4-.2-.6l-.4-1H61c.6 0 1-.4 1-1s-.5-1-1-1M25.2 7.5c.3-.8 1-1.4 1.9-1.4h9.8c.9 0 1.7.6 1.9 1.4l.9 2.8H24.3zm-11.7 5.9c.3-.6.9-1 1.5-1h33.2c.7 0 1.3.4 1.5 1l5.9 15h-48zm6.6 43.2c0 1.1-.9 2-2 2h-4.3c-1.1 0-2-.9-2-2v-6.3h8.3zm31 0c0 1.1-.9 2-2 2h-4.3c-1.1 0-2-.9-2-2v-6.3h8.3zm5.1-10.3c0 1.1-.9 2-2 2H9.1c-1.1 0-2-.9-2-2V30.4h49.1z" />
  </svg>
);
export default IconTaxiCopy;
