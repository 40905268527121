import * as React from "react";
const IconTransform = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59 16.9c1.7 0 3-1.3 3-3V5c0-1.7-1.3-3-3-3h-9.4c-1.7 0-3 1.3-3 3v3.1H17.4V5c0-1.7-1.3-3-3-3H5C3.3 2 2 3.3 2 5v8.9c0 1.7 1.3 3 3 3h3.4v30.2H5c-1.7 0-3 1.3-3 3V59c0 1.7 1.3 3 3 3h9.4c1.7 0 3-1.3 3-3v-2.9h29.2V59c0 1.7 1.3 3 3 3H59c1.7 0 3-1.3 3-3v-8.9c0-1.7-1.3-3-3-3h-3.4V16.9zM48.6 5c0-.6.4-1 1-1H59c.6 0 1 .4 1 1v8.9c0 .6-.4 1-1 1h-9.4c-.6 0-1-.4-1-1zM4 13.9V5c0-.6.4-1 1-1h9.4c.6 0 1 .4 1 1v8.9c0 .6-.4 1-1 1H5c-.6 0-1-.5-1-1M14.4 60H5c-.6 0-1-.4-1-1v-8.9c0-.6.4-1 1-1h9.4c.6 0 1 .4 1 1V59c0 .6-.5 1-1 1M60 50.1V59c0 .6-.4 1-1 1h-9.4c-.6 0-1-.4-1-1v-8.9c0-.6.4-1 1-1H59c.6 0 1 .5 1 1m-6.4-3h-3.9c-1.7 0-3 1.3-3 3v4H17.4v-4c0-1.7-1.3-3-3-3h-3.9V16.9h3.9c1.7 0 3-1.3 3-3v-3.8h29.2v3.8c0 1.7 1.3 3 3 3h3.9v30.2z" />
  </svg>
);
export default IconTransform;
