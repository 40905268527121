import * as React from "react";
const IconDeskAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 11.6H3c-.6 0-1 .4-1 1s.4 1 1 1h3.3v37.7c0 .6.4 1 1 1s1-.4 1-1V24.4h6.6v21.4c0 .6.4 1 1 1s1-.4 1-1V24.4h18v17.1c0 .6.4 1 1 1h11.2v3.3c0 .6.4 1 1 1s1-.4 1-1v-3.3h6.6v8.9c0 .6.4 1 1 1s1-.4 1-1V13.6H61c.6 0 1-.4 1-1s-.4-1-1-1M36.9 40.5V24.4h18.8v16.1zm18.8-18.1H8.3v-8.8h47.4z" />
    <path d="M42.1 30.9c-.6 0-1 .4-1 1V33c0 .6.4 1 1 1s1-.4 1-1v-1.1c0-.5-.5-1-1-1" />
  </svg>
);
export default IconDeskAlt1;
