import * as React from "react";
const IconPinCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M46.2 16.2C46.2 8.4 39.8 2 32 2S17.8 8.4 17.8 16.2c0 7.5 5.8 13.6 13.2 14.1V61c0 .6.4 1 1 1s1-.4 1-1V30.3c7.4-.5 13.2-6.6 13.2-14.1M32 28.4c-6.7 0-12.2-5.5-12.2-12.2S25.3 4 32 4s12.2 5.5 12.2 12.2S38.7 28.4 32 28.4" />
    <path d="M34.6 10c-2 0-3.5 1.6-3.5 3.5s1.6 3.5 3.5 3.5c2 0 3.5-1.6 3.5-3.5S36.6 10 34.6 10" />
  </svg>
);
export default IconPinCopy;
