import * as React from "react";
const IconFrogAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.6 27.9c.6-1.5.6-3.1 0-4.6s-1.6-2.7-3.1-3.5c-3-1.5-6.1-2.8-9.2-3.9-.6-2.4-2.3-4.5-4.6-5.6C41.3 9 38.4 9 36 10.2c-2.2 1.1-3.9 3.1-4.5 5.5-7.7.9-14.8 4.5-20.1 10.3C5.9 31.9 3 39.6 3 47.6c0 1.9.8 3.7 2.1 5 1.4 1.3 3.1 2.1 5 2.1h30.6c.6 0 1-.4 1-1s-.4-1-1-1h-9.1l2.3-3.1c1.3-2 1.6-4.5.9-6.7-.7-2.3-2.4-4.1-4.6-5-2.7-1-5.7-.6-8 1.2L19 41.5c-.4.3-.5 1-.2 1.4s1 .5 1.4.2l3.2-2.4c1.7-1.3 4-1.7 6-.9 1.6.7 2.9 2 3.4 3.7s.3 3.5-.6 5L29 52.8H10c-1.4 0-2.7-.5-3.7-1.5S4.8 49 4.8 47.7c0-7.5 2.8-14.7 7.8-20.3 5.1-5.6 12-9 19.5-9.7.4 0 .8-.4.9-.8.4-2.1 1.7-3.9 3.6-4.8s4.1-.9 6 0 3.2 2.7 3.6 4.8c.1.4.3.7.7.8 3.2 1.1 6.4 2.4 9.4 3.9 1 .5 1.7 1.4 2.1 2.5.4 1 .4 2.2 0 3.2s-1.1 1.9-2.2 2.5l-15 9.4c-.2.1-.4.4-.4.7s0 .5.2.8l9.8 13.8c.2.3.5.4.8.4h7.2c.6 0 1-.4 1-1s-.4-1-1-1h-6.7l-8.9-12.5 14-8.8c1.8-1 2.8-2.2 3.4-3.7" />
  </svg>
);
export default IconFrogAlt;
