import * as React from "react";
const IconHarddriveCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M47.5 2h-31c-3.6 0-6.6 2.9-6.6 6.6v46.8c0 3.6 2.9 6.6 6.6 6.6h31.1c3.6 0 6.6-2.9 6.6-6.6V8.6c-.1-3.7-3-6.6-6.7-6.6m4.6 53.4c0 2.5-2.1 4.6-4.6 4.6h-31c-2.5 0-4.6-2.1-4.6-4.6V8.6C11.9 6.1 14 4 16.5 4h31.1c2.5 0 4.6 2.1 4.6 4.6v46.8z" />
    <path d="M32.1 21.2c-5.9 0-10.8 4.8-10.8 10.8 0 2.6 1 5.1 2.6 7-2.3 1.5-4.7 3.2-5.4 4.5-.9 1.5-1.1 3.3-.7 5s1.5 3.1 3 4c1 .6 2.2.9 3.3.9.6 0 1.1-.1 1.7-.2 1.7-.4 3.1-1.5 4-3 .8-1.4 1.2-4.5 1.3-7.5h.9c5.9 0 10.8-4.8 10.8-10.8 0-5.8-4.8-10.7-10.7-10.7m-4 28c-.6 1.1-1.6 1.8-2.8 2.1s-2.4.1-3.5-.5-1.8-1.6-2.1-2.8-.1-2.4.5-3.5c.9-1.5 5.5-4.4 8.9-6.3.2 4-.1 9.4-1 11m4-8.4h-.9c0-2 0-3.7-.1-4.1 0-.3-.2-.7-.5-.8-.3-.2-.7-.2-1 0-.4.2-2.1 1.1-4 2.2-1.5-1.6-2.4-3.7-2.4-6 0-4.8 3.9-8.8 8.8-8.8s8.8 3.9 8.8 8.8c0 4.7-3.9 8.7-8.7 8.7M44 48.8c-1.5 0-2.7 1.2-2.7 2.7s1.2 2.7 2.7 2.7 2.7-1.2 2.7-2.7-1.2-2.7-2.7-2.7" />
  </svg>
);
export default IconHarddriveCopy;
