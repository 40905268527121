import * as React from "react";
const IconAnalyticsAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 2H6C3.8 2 2 3.8 2 6v52c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V6c0-2.2-1.8-4-4-4m2 56c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V38.5l9.6-8.2c.9.7 1.9 1.2 3.1 1.2 1.8 0 3.3-1 4.2-2.4l6 3.6c-.2.5-.3 1.1-.3 1.7 0 2.7 2.2 4.9 4.9 4.9s4.9-2.2 4.9-4.9c0-.3 0-.5-.1-.8l8.3-7.6c.9.9 2.2 1.5 3.6 1.5 2.7 0 4.9-2.2 4.9-4.9s-2.2-4.9-4.9-4.9-4.9 2.2-4.9 4.9c0 .6.1 1.1.3 1.6l-8 7.4c-.9-1.3-2.4-2.2-4.1-2.2-1.4 0-2.6.6-3.5 1.5l-6.3-3.8c0-.2.1-.4.1-.6 0-2.7-2.2-4.9-4.9-4.9S12 23.8 12 26.5c0 .8.2 1.5.5 2.2L4 35.9V6c0-1.1.9-2 2-2h52c1.1 0 2 .9 2 2zM16.8 29.5c-1.6 0-2.9-1.3-2.9-2.9s1.3-2.9 2.9-2.9 2.9 1.3 2.9 2.9-1.3 2.9-2.9 2.9m17.6 5c0 1.6-1.3 2.9-2.9 2.9s-2.9-1.3-2.9-2.9 1.3-2.9 2.9-2.9 2.9 1.2 2.9 2.9m10.9-11.8c0-1.6 1.3-2.9 2.9-2.9s2.9 1.3 2.9 2.9-1.3 2.9-2.9 2.9-2.9-1.3-2.9-2.9" />
  </svg>
);
export default IconAnalyticsAlt1;
