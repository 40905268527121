import * as React from "react";
const IconTestTube = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 62c-6.7 0-12.2-5.3-12.2-11.9V4h-6.3c-.6 0-1-.4-1-1s.4-1 1-1h7.3c.6 0 1 .4 1 1v47.1c0 5.4 4.6 9.9 10.2 9.9s10.2-4.4 10.2-9.9V3c0-.6.4-1 1-1h7.3c.6 0 1 .4 1 1s-.4 1-1 1h-6.3v46.1C44.2 56.7 38.7 62 32 62" />
  </svg>
);
export default IconTestTube;
