import * as React from "react";
const IconForrestAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60 30.1c-.7-.9-1.6-1.6-2.6-2.2.2-.9.3-1.7.3-2.6 0-2.8-1-5.6-2.9-7.7-1.4-1.6-3.2-2.8-5.3-3.5-.4-1.9-1.3-3.5-2.7-4.9C45 7.4 42.6 6.4 40 6.4s-5 1-6.8 2.8c-.5.5-1 1.1-1.3 1.6-.4-.6-.8-1.1-1.3-1.6-1.8-1.8-4.2-2.8-6.8-2.8s-5 1-6.8 2.8c-1.4 1.3-2.3 3-2.7 4.9-2 .7-3.8 1.9-5.3 3.5-1.8 2.1-2.9 4.9-2.9 7.7 0 .9.1 1.8.3 2.6-1 .6-1.9 1.3-2.6 2.2-1.3 1.6-2 3.6-2 5.7 0 5 4.1 9.1 9.1 9.1h11.7v10.7H17c-.6 0-1 .4-1 1s.4 1 1 1h30.3c.6 0 1-.4 1-1s-.4-1-1-1h-6.2V44.9h11.7c2.4 0 4.7-1 6.4-2.7s2.7-4 2.7-6.4c.1-2.1-.6-4.1-1.9-5.7M11.1 42.9c-3.9 0-7.1-3.2-7.1-7.1 0-1.6.6-3.2 1.6-4.5.7-.9 1.6-1.6 2.6-2 .5-.2.7-.7.5-1.2-.3-.9-.4-1.9-.4-2.9 0-2.4.8-4.6 2.4-6.4 1.3-1.5 3-2.6 4.9-3.1.4-.1.7-.4.7-.8.2-1.7 1-3.2 2.2-4.4 1.4-1.4 3.4-2.2 5.4-2.2s4 .8 5.4 2.2c1.2 1.2 2 2.7 2.2 4.4.1.4.3.7.7.8 1.9.5 3.6 1.6 4.9 3.1 1.5 1.8 2.4 4.1 2.4 6.4 0 1-.1 1.9-.4 2.9-.1.5.1 1 .5 1.2 1 .5 1.9 1.2 2.6 2 1 1.3 1.6 2.8 1.6 4.5 0 3.9-3.1 7.1-7 7.1H25v-4.8l5-3.1c.5-.3.6-.9.3-1.4s-.9-.6-1.4-.3l-4.1 2.5v-5.3l5.1-3.1c.5-.3.6-.9.3-1.4s-.9-.6-1.4-.3l-4 2.4c0-.1-.1-.2-.1-.3v-4.4c0-.6-.4-1-1-1s-1 .4-1 1v7.7l-5.4-1.8c-.5-.2-1.1.1-1.3.6s.1 1.1.6 1.3l6 2V43H11.1zm28 12.7H24.9V44.9h14.2zm18.8-14.8c-1.3 1.4-3.1 2.1-5 2.1H42.3c2.1-1.7 3.4-4.2 3.4-7.1 0-2.1-.7-4.1-2-5.7-.7-.9-1.6-1.6-2.6-2.2.2-.9.3-1.7.3-2.6 0-2.8-1-5.6-2.9-7.7-1.4-1.6-3.2-2.9-5.3-3.5-.1-.3-.2-.6-.3-1 .4-.9.9-1.8 1.7-2.5C36 9.2 38 8.4 40 8.4s4 .8 5.4 2.2c1.2 1.2 2 2.7 2.2 4.4.1.4.3.7.7.8 1.9.5 3.6 1.6 4.9 3.1 1.5 1.8 2.4 4.1 2.4 6.4 0 1-.1 1.9-.4 2.9-.1.5.1 1 .5 1.2 1 .5 1.9 1.2 2.6 2 1 1.3 1.6 2.8 1.6 4.5.1 1.8-.6 3.6-2 4.9" />
  </svg>
);
export default IconForrestAlt1;
