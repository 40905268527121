import * as React from "react";
const IconHospice = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M41.708 23.794 24.058 6.086 6.294 23.792a1 1 0 1 0 1.412 1.416L10 22.922V41a1 1 0 0 0 1 1h26a1 1 0 0 0 1-1V22.907l2.292 2.3a1 1 0 1 0 1.416-1.413M24.053 8.914 36 20.9V40H12V20.928z"
      clipRule="evenodd"
    />
    <path
      
      d="M16 25.517C16 22.655 17.794 20 20.25 20c1.704 0 2.97 1.191 3.75 2.88.78-1.689 2.045-2.88 3.75-2.88 2.456 0 4.25 2.655 4.25 5.517C32 31.645 24 36 24 36s-5.389-2.756-7.32-7.278a9 9 0 0 1-.36-1 7.9 7.9 0 0 1-.32-2.205"
    />
  </svg>
);
export default IconHospice;
