import React, { useEffect, useState } from 'react';
import { BringzzBoxSlider, BringzzButton, BringzzOrder, BringzzText } from "@bringzz/components";
import { useNavigation } from 'context/NavigationContext';
import {
    BringzzPageContainer,
    BringzzIcon,
    BringzzOrderCard
} from "@bringzz/components";
import useRequest from 'hooks/useRequest';

const CompletedOrdersPage = () => {
    const { backPress } = useNavigation();

    const { navigate } = useNavigation();
    const { sendRequest, loading } = useRequest();
    const [orders, setOrders] = useState([])

    const fetchOrders = async () => {
        await sendRequest({
            method: 'GET',
            url: `/orders`,
        }).then((response) => {
            if (response.status === 'success') {
                setOrders(response.data)
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    useEffect(() => {
        fetchOrders()
    }, [])

    return (
        <BringzzPageContainer
            className="bg-white"
            header={{
                left: (
                    <BringzzIcon
                        size='22'
                        icon='IconChevronLeft'
                        folder='LineIcons'
                        className='cursor-pointer'
                        onClick={backPress}
                    />
                ),
                title: "Completed Orders"
            }}
            authenticated={true}
        >
            <div>
                {orders.completed &&
                    <div className='px-6 pb-8'>
                        <div className='py-4 space-y-4'>
                            {orders.completed.map(((order, index) => {
                                return <div key={index}>
                                    <BringzzOrderCard
                                        orderDetails={{
                                            name: order.customerName,
                                            date: new Date(order.orderdate),
                                            status: 'Completed',
                                            price: order.price,
                                            itemsCount: order.products.length
                                        }}
                                        onClick={() => navigate('/settings/pharmacy/orders/view')}
                                    ></BringzzOrderCard>
                                </div>
                            }))}
                        </div>
                    </div>
                }
            </div>
        </BringzzPageContainer >
    );
};

export default CompletedOrdersPage;
