import * as React from "react";
const IconKeyWedding = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m60.6 52.1-.8-.8.7-.7c1.6-1.6 1.6-4.3 0-5.9q-1.2-1.2-3-1.2c-1.8 0-2.2.4-3 1.2l-.7.7-4.3-4.4.7-.7q1.2-1.2 1.2-3c0-1.8-.4-2.2-1.2-3l-.1-.1c-1.6-1.6-4.3-1.6-5.9 0l-.7.8-6.6-6.6-.1-.1-.5-.4V14.7c0-3.3-1.3-6.5-3.7-8.8-2.3-2.4-5.5-3.7-8.8-3.7-5.8 0-10.8 4-12.1 9.6-2.3.5-4.3 1.7-5.9 3.3-2.2 2.2-3.5 5.1-3.7 8.3-.1 3.3 1.1 6.4 3.4 8.9 2.5 2.6 6 4.1 9.6 4.1h13.1l1.1 1 23 23c.9.9 2.1 1.4 3.3 1.4s2.4-.5 3.3-1.4l1.6-1.6c2-1.8 2-4.8.1-6.7M6.9 30.9c-2-2.1-3-4.7-2.9-7.4.1-2.6 1.2-5.1 3.1-6.9 1.5-1.5 3.4-2.5 5.5-2.9.4-.1.7-.4.8-.8.9-5 5.2-8.6 10.3-8.6 2.8 0 5.5 1.1 7.4 3.1 2 2 3.1 4.6 3.1 7.4v17.7c0 1.1-.9 2-2 2H15.1c-3.1-.1-6.1-1.4-8.2-3.6m52.3 26.5L57.6 59c-1 1-2.8 1-3.8 0L31.1 36.4h1.1c2.2 0 4-1.8 4-4v-1.8l6.6 6.6c.4.4 1 .4 1.4 0l1.4-1.4c.8-.8 2.2-.8 3.1 0l.1.1c.4.4.6 1 .6 1.5s-.2 1.1-.6 1.5l-1.4 1.4c-.4.4-.4 1 0 1.4l5.8 5.8c.4.4 1 .4 1.4 0l1.4-1.4c.8-.8 2.3-.8 3.1 0s.8 2.2 0 3.1l-1.4 1.4c-.4.4-.4 1 0 1.4l1.5 1.5c1.1 1.1 1.1 2.8 0 3.9" />
  </svg>
);
export default IconKeyWedding;
