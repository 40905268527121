import * as React from "react";
const IconChocolateBar = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M47.4 29.1v-22c0-2.8-2.3-5.1-5.1-5.1H21.8c-2.8 0-5.1 2.3-5.1 5.1v22c-2.7.2-4.8 2.4-4.8 5.1V61c0 .4.2.7.5.9s.7.1 1-.1l4.2-2.9 4.2 2.9c.3.2.8.2 1.1 0l4.2-2.9 4.2 2.9c.2.1.4.2.6.2s.4-.1.6-.2l4.2-2.9 4.2 2.9c.3.2.8.2 1.1 0l4.2-2.9 4.2 2.9c.3.2.7.2 1 .1.3-.2.5-.5.5-.9V34.2c.2-2.7-1.9-4.9-4.5-5.1M18.6 17.3H31v11.8H18.6zm14.4 0h12.4v11.8H33zM45.4 7.1v8.2H33V4h9.2c1.8 0 3.2 1.4 3.2 3.1M21.8 4H31v11.3H18.6V7.1C18.6 5.4 20 4 21.8 4M17 31.1h30c1.7 0 3.1 1.4 3.1 3.1v10.9l-36.3-8v-2.9c.1-1.7 1.5-3.1 3.2-3.1m29.9 25.8c-.3-.2-.8-.2-1.1 0l-4.2 2.9-4.2-2.9c-.2-.1-.4-.2-.6-.2s-.4.1-.6.2L32 59.8l-4.2-2.9c-.3-.2-.8-.2-1.1 0l-4.2 2.9-4.2-2.9c-.3-.2-.8-.2-1.1 0L14 59.1V39.2l36.3 8v11.9z" />
  </svg>
);
export default IconChocolateBar;
