import * as React from "react";
const IconSearchCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.1 51.1 49.5 40.4c-2.2-2.2-5.8-2.5-8.3-.6l-7-7c6.5-7.3 6.3-18.4-.7-25.4C26.3.2 14.6.2 7.4 7.4s-7.2 18.9 0 26.1C11 37.2 15.8 39 20.5 39c4.4 0 8.8-1.6 12.3-4.7l7 7c-1.8 2.5-1.6 6 .6 8.3l10.7 10.7c1.2 1.2 2.8 1.9 4.5 1.9s3.3-.7 4.5-1.9 1.9-2.8 1.9-4.5c0-1.9-.7-3.5-1.9-4.7m-51.3-19c-6.4-6.4-6.4-16.9 0-23.3C12 5.6 16.3 4 20.5 4s8.4 1.6 11.7 4.8c6.4 6.4 6.4 16.9 0 23.3-6.5 6.5-16.9 6.5-23.4 0m49.9 26.6c-.8.8-1.9 1.3-3.1 1.3s-2.3-.5-3.1-1.3L41.8 48c-1.7-1.7-1.7-4.5 0-6.2.9-.9 2-1.3 3.1-1.3s2.2.4 3.1 1.3l10.7 10.7c.8.8 1.3 1.9 1.3 3.1s-.5 2.3-1.3 3.1" />
  </svg>
);
export default IconSearchCopy;
