import * as React from "react";
const IconFlowerCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.2 21.7c-2.2 0-5.8 1-11.1 3.2 2.2-5.3 3.2-8.9 3.2-11.1C42.3 7.3 37.7 2 32 2S21.7 7.3 21.7 13.8c0 2.2 1 5.8 3.2 11.1-5.3-2.2-8.9-3.2-11.1-3.2C7.3 21.7 2 26.3 2 32s5.3 10.3 11.8 10.3c2.2 0 5.8-1 11.1-3.2-2.2 5.3-3.2 8.9-3.2 11.1C21.7 56.7 26.3 62 32 62s10.3-5.3 10.3-11.8c0-2.2-1-5.8-3.2-11.1 5.3 2.2 8.9 3.2 11.1 3.2C56.7 42.3 62 37.7 62 32s-5.3-10.3-11.8-10.3M13.8 40.3C8.4 40.3 4 36.6 4 32s4.4-8.3 9.8-8.3c2 0 5.7 1.1 11 3.3-1 1.4-1.6 3.1-1.6 5s.6 3.6 1.6 5c-5.3 2.2-9 3.3-11 3.3m9.9-26.5C23.7 8.4 27.4 4 32 4s8.3 4.4 8.3 9.8c0 2-1.1 5.7-3.3 11-1.4-1-3.1-1.6-5-1.6s-3.6.6-5 1.6c-2.2-5.3-3.3-9-3.3-11M38.8 32c0 3.7-3 6.8-6.8 6.8s-6.8-3-6.8-6.8 3-6.8 6.8-6.8 6.8 3.1 6.8 6.8m1.5 18.2c0 5.4-3.7 9.8-8.3 9.8s-8.3-4.4-8.3-9.8c0-2 1.1-5.7 3.3-11 1.4 1 3.1 1.6 5 1.6s3.6-.6 5-1.6c2.2 5.3 3.3 9 3.3 11m9.9-9.9c-2 0-5.7-1.1-11-3.3 1-1.4 1.6-3.1 1.6-5s-.6-3.6-1.6-5c5.3-2.2 9-3.3 11-3.3 5.4 0 9.8 3.7 9.8 8.3s-4.4 8.3-9.8 8.3" />
  </svg>
);
export default IconFlowerCopy;
