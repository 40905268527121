import * as React from "react";
const IconLolipop3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.2 12.5H48C45.3 6.1 39 2 32 2S18.7 6.1 16 12.5h-2.2c-2.2 0-4 1.8-4 4v5.9c0 2.2 1.8 4 4 4H16c2.6 6.1 8.4 10.1 15 10.5V61c0 .6.4 1 1 1s1-.4 1-1V36.9c6.6-.4 12.4-4.4 15-10.5h2.2c2.2 0 4-1.8 4-4v-5.9c0-2.2-1.8-4-4-4M32 4c5.9 0 11.2 3.3 13.8 8.5H18.2C20.8 7.3 26.1 4 32 4m0 30.9c-5.9 0-11.2-3.3-13.8-8.5h27.7c-2.7 5.2-8 8.5-13.9 8.5m20.2-12.5c0 1.1-.9 2-2 2H13.8c-1.1 0-2-.9-2-2v-5.9c0-1.1.9-2 2-2h36.3c1.1 0 2 .9 2 2v5.9z" />
  </svg>
);
export default IconLolipop3;
