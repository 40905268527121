import * as React from "react";
const IconCloset5 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 5.4H6c-2.2 0-4 1.8-4 4v45.3c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V9.4c0-2.2-1.8-4-4-4M4 54.6V9.4c0-1.1.9-2 2-2h25v49.3H6c-1.1-.1-2-1-2-2.1m56 0c0 1.1-.9 2-2 2H33V7.4h25c1.1 0 2 .9 2 2z" />
    <path d="M25.9 28.7c0-.6-.4-1-1-1s-1 .5-1 1V31c0 .6.4 1 1 1s1-.5 1-1zM39 27.7c-.6 0-1 .5-1 1V31c0 .6.4 1 1 1s1-.5 1-1v-2.3c0-.6-.4-1-1-1" />
  </svg>
);
export default IconCloset5;
