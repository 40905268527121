import * as React from "react";
const IconRocketLaunch4 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M48.7 25.1h-1.4c-.6 0-1 .4-1 1s.4 1 1 1h1.4c1.5 0 2.6 1.2 2.6 2.6v20.4c0 .8-.6 1.4-1.4 1.4H14.1c-.8 0-1.4-.6-1.4-1.4V29.7c0-1.5 1.2-2.6 2.6-2.6h.8c.6 0 1-.4 1-1s-.4-1-1-1h-.8c-2.6 0-4.6 2.1-4.6 4.6v20.4c0 1.9 1.5 3.4 3.4 3.4h13.5l-.9 6.5h-5.3c-.6 0-1 .4-1 1s.4 1 1 1h21.1c.6 0 1-.4 1-1s-.4-1-1-1h-5.3l-.9-6.4h13.5c1.9 0 3.4-1.5 3.4-3.4V29.7c.1-2.5-2-4.6-4.5-4.6M35.2 60h-6.5l.9-6.4h4.6z" />
    <path d="M21.5 38.5h6c-.1.4-.2.9-.2 1.4 0 2.3 3.3 6.8 4 7.7.2.3.5.4.8.4s.6-.1.8-.4c.7-.9 4-5.4 4-7.7 0-.5-.1-.9-.2-1.4h6c.6 0 1-.4 1-1v-5.7c0-.3-.1-.5-.3-.7l-4.7-4.7v-8.2c0-9.5-3-13.4-5.9-16-.4-.3-.9-.3-1.3 0-2.9 2.6-5.9 6.4-5.9 16v8.2l-4.7 4.7c-.2.2-.3.4-.3.7v5.7c-.1.5.3 1 .9 1m13.3 1.3c0 1-1.4 3.4-2.8 5.4-1.3-1.9-2.8-4.4-2.8-5.4 0-.5.1-.9.4-1.4h4.8c.2.5.4.9.4 1.4m-12.3-7.6 4.7-4.7c.2-.2.3-.4.3-.7v-8.6c0-8 2.1-11.5 4.6-13.9 2.5 2.4 4.6 5.9 4.6 13.9v8.6c0 .3.1.5.3.7l4.7 4.7v4.3h-19v-4.3z" />
  </svg>
);
export default IconRocketLaunch4;
