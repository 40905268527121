import * as React from "react";
const IconPushUp = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M45.1 3H18.9c-.6 0-1 .4-1 1s.4 1 1 1h26.2c.6 0 1-.4 1-1s-.5-1-1-1M32.7 13.9c-.4-.4-1-.4-1.4 0L20.6 24.4c-.4.4-.4 1 0 1.4s1 .4 1.4 0l9-8.8v43c0 .6.4 1 1 1s1-.4 1-1V17l9 8.8c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4z" />
  </svg>
);
export default IconPushUp;
