import * as React from "react";
const IconBuildingsAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M42.5 61c-.3 0-.5-.1-.7-.3s-.3-.4-.3-.7v-9.9c0-5.2-4.3-9.5-9.5-9.5s-9.5 4.3-9.5 9.5V60c0 .6-.4 1-1 1H6.9C4.2 61 2 58.7 2 56V18.5c0-2.7 2.2-4.9 4.9-4.9h10.9l10.6-9.3c1.8-1.6 4.6-1.7 6.4-.1L46 13.6h10.9c2.7 0 4.9 2.2 4.9 4.9V56c0 2.7-2.2 4.9-4.9 4.9zM32 38.6c6.3 0 11.5 5.2 11.5 11.5V59h13.6c1.6 0 2.9-1.3 2.9-2.9V18.5c0-1.6-1.3-2.9-2.9-2.9H45.8c-.2 0-.5-.1-.6-.2L33.6 5.7c-1.1-.9-2.7-.9-3.8 0l-10.9 9.6c-.2.2-.4.2-.7.2H6.9c-1.6 0-2.9 1.3-2.9 2.9V56c0 1.6 1.3 3 2.9 3h13.5v-8.9c.1-6.3 5.3-11.5 11.6-11.5" />
  </svg>
);
export default IconBuildingsAlt1;
