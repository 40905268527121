import React, { useState } from 'react';
import { BringzzButton, BringzzText } from "@bringzz/components";
import { useNavigation } from 'context/NavigationContext';
import {
    BringzzPageContainer,
    BringzzIcon,
    BringzzRadioList,
    BringzzCountryDropdown,
    BringzzInput
} from "@bringzz/components";
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const ShopperBankPage = () => {
    const { backPress } = useNavigation();

    const { navigate } = useNavigation();

    const [selected, setSelected] = useState(null)

    return (
        <BringzzPageContainer
            className="bg-white"
            header={{
                left: (
                    <BringzzIcon
                        size='22'
                        icon='IconChevronLeft'
                        folder='LineIcons'
                        className='cursor-pointer'
                        onClick={backPress}
                    />
                )
            }}
            footer={
                <div className='px-4 mb-4'>
                    <BringzzButton
                        size='lg'
                        className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
                        onClick={() => {
                            navigate('/home');
                        }}
                    >
                        Continue
                    </BringzzButton>
                </div>
            }
            authenticated={false}
        >
            <div className='relative flex justify-center bg-magic-lilac/20'>
                <div className='relative bg-white w-full bg-cover bg-center flex flex-col'>
                    {/* Content */}
                    <div className='flex-grow flex flex-col py-8 px-4'>
                        <div className='z-10 flex flex-col '>
                            <div className='flex flex-col text-center space-y-6 mx-auto md:max-w-lg lg:max-w-xl xl:max-w-2xl'>
                                <BringzzText tag='h1' className='text-midnight-blue'>
                                    Bank Details
                                </BringzzText>
                                <BringzzText
                                    tag='h4'
                                    className='text-midnight-blue/25 font-normal'
                                >
                                    Let us know where your shopper payments should be made to. Lorem ipsum dolor sit amet est.
                                </BringzzText>
                            </div>
                            <div className='mt-6'>
                                <div className='space-y-4 mt-4'>
                                    <BringzzInput label="Account Holder Name" placeholder="Account Holder Name" />
                                    <BringzzInput label="IBAN" placeholder="IBAN" hint="We only allow Austrian and German bank accounts." />
                                    <BringzzInput label="BIC" placeholder="BIC" />

                                    <div className='flex items-center gap-x-4 mt-6'>
                                        <div className='grow bg-midnight-blue/20 h-[1px]'></div>
                                        <div>
                                            <BringzzText
                                                tag='h4'
                                                className='text-midnight-blue/40 font-normal'
                                            >
                                                or
                                            </BringzzText>
                                        </div>
                                        <div className='grow bg-midnight-blue/20 h-[1px]'></div>
                                    </div>

                                    <BringzzButton className="border border-black w-full flex justify-center">Register with Stripe</BringzzButton>

                                    <div className='bg-gray-100 p-4'>
                                        <BringzzText className="text-xs font-normal">For verification reasons we will transfer you a small amount. Please enter the PIN from the transfer reference later under Settings.</BringzzText>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </BringzzPageContainer >
    );
};

export default ShopperBankPage;
