import * as React from "react";
const IconLamp = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m52.2 28.8-9.7-12.6c-.2-.2-.5-.4-.8-.4H33V3c0-.6-.4-1-1-1s-1 .4-1 1v12.9h-8.7c-.3 0-.6.1-.8.4l-9.7 12.6c-.3.4-.3 1 .1 1.4l9.7 8.4h.1c.1 5.6 4.7 10.1 10.3 10.1s10.2-4.5 10.3-10.1h.1l9.7-8.4c.4-.4.4-1 .1-1.5M32 46.8c-4.4 0-8.1-3.5-8.3-7.9h16.6c-.2 4.4-3.9 7.9-8.3 7.9m9.3-9.9H22.7L14 29.3l8.8-11.5h18.4L50 29.3zM32 53.8c-.6 0-1 .4-1 1V61c0 .6.4 1 1 1s1-.4 1-1v-6.2c0-.6-.4-1-1-1M19.4 49.7 15 54.1c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l4.4-4.4c.4-.4.4-1 0-1.4s-1.1-.4-1.4 0M44.6 49.7c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l4.4 4.4c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4z" />
  </svg>
);
export default IconLamp;
