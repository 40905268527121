import * as React from "react";
const IconBrushAltCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.4 5.8c-1-1.1-2.4-1.6-3.9-1.6-1.4 0-2.8.5-3.8 1.5L15.4 40.3c-1.2-.1-2.5.1-4 .8-2 .9-2.9 2.4-3.8 4.1-1 1.7-2.1 3.6-4.6 5.6-.7.5-1 1.3-1 2.2s.6 1.6 1.3 2c3.9 2.1 10 4.9 15 4.9 1.9 0 3.6-.4 4.9-1.4 2.1-1.6 3.1-3.6 3.1-6 0-.3 0-.6-.1-1l34.3-37.9c2-2.2 2-5.7-.1-7.8M22 56.8c-2.9 2.2-9.5.8-17.8-3.7-.1-.1-.2-.3-.2-.4s0-.3.2-.4c2.8-2.2 4.1-4.4 5.1-6.1.9-1.5 1.6-2.7 2.9-3.3q1.5-.6 2.7-.6c1.7 0 3 .7 3.1.8 2.9 2.1 6.1 5.9 6.2 9.4.1 1.7-.6 3.1-2.2 4.3m37.1-44.6-33.5 37c-1.2-3-3.8-5.9-6.4-7.7-.1 0-.6-.4-1.4-.6L54.1 7.2c1.3-1.3 3.5-1.3 4.9.1 1.3 1.3 1.3 3.5.1 4.9" />
  </svg>
);
export default IconBrushAltCopy;
