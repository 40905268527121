import * as React from "react";
const Icon24HoursTruck = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.8 40.3 56.1 32c-.2-.3-.5-.4-.8-.4H46v-2.4c0-2.4-2-4.4-4.4-4.4h-6.4c-.6 0-1 .4-1 1s.4 1 1 1h6.4c1.3 0 2.4 1.1 2.4 2.4v23.6h-8.8c-.3-2.2-2.1-3.9-4.4-3.9-2.2 0-4.1 1.7-4.4 3.9h-6c-1.3 0-2.4-1.1-2.4-2.4v-11c0-.6-.4-1-1-1s-1 .4-1 1v10.9c0 2.4 2 4.4 4.4 4.4h6.2c.6 1.7 2.2 3 4.2 3 1.9 0 3.5-1.2 4.2-3h13.3c.6 1.7 2.2 3 4.2 3 1.9 0 3.5-1.2 4.2-3H61c.6 0 1-.4 1-1V40.8c0-.2-.1-.4-.2-.5m-7-6.7 4.7 6.8H46v-6.8zm-24 22.1c-1.3 0-2.4-1.1-2.4-2.4s1.1-2.4 2.4-2.4 2.4 1.1 2.4 2.4-1.1 2.4-2.4 2.4m21.6 0c-1.3 0-2.4-1.1-2.4-2.4s1.1-2.4 2.4-2.4 2.4 1.1 2.4 2.4-1.1 2.4-2.4 2.4m4.4-2.9c-.3-2.2-2.1-3.9-4.4-3.9-2.2 0-4.1 1.7-4.4 3.9h-2V42.4h14v10.4z" />
    <path d="M31.5 21c0-8.1-6.6-14.7-14.7-14.7C8.6 6.3 2 12.9 2 21s6.6 14.7 14.7 14.7c8.2 0 14.8-6.6 14.8-14.7M4 21C4 14 9.7 8.3 16.7 8.3S29.5 14 29.5 21s-5.7 12.7-12.7 12.7S4 28 4 21" />
    <path d="M14.4 15.2h-3.5c-.6 0-1 .4-1 1s.4 1 1 1h2.5v3.5h-2.5c-.6 0-1 .4-1 1v5.5c0 .6.4 1 1 1h3.5c.6 0 1-.4 1-1s-.4-1-1-1h-2.5v-3.5h2.5c.6 0 1-.4 1-1v-5.5c0-.5-.4-1-1-1M18.9 22.8H23v4c0 .6.4 1 1 1s1-.4 1-1v-4.3c0-.1 0-.2-.1-.3 0-.1.1-.2.1-.3v-5.5c0-.6-.4-1-1-1s-1 .4-1 1v4.5h-3.1v-4.5c0-.6-.4-1-1-1s-1 .4-1 1v5.5c0 .4.4.9 1 .9" />
  </svg>
);
export default Icon24HoursTruck;
