import React, { useState } from 'react';
import {
    BringzzPageContainer,
    BringzzIcon,
    BringzzText,
    BringzzInput,
    BringzzButton,
    BringzzBottomDrawer,
    BringzzRadioList,
    BringzzRadio
} from '@bringzz/components';
import { useNavigation } from 'context/NavigationContext';
import usePageState from 'hooks/usePageState';

let state = {
    checked: <div></div>,
    unChecked: <div></div>
};

const BuildArticlePage = () => {
    const { navigate, backPress } = useNavigation();
    const formData = usePageState();
    const [blocks, setBlocks] = useState(formData.blocks || []);
    const [addBlockDrawer, setAddBlockDrawer] = useState(false);

    const handlePickerClick = (choice) => {
        const newBlock = {
            id: Date.now(), // Unique ID to prevent key issues
            component: choice,
            content: '',
        };
        setBlocks([...blocks, newBlock]);
    };

    const updateBlockContent = (index, newContent) => {
        const updatedBlocks = [...blocks];
        updatedBlocks[index].content = newContent;
        setBlocks(updatedBlocks);
    };

    const removeBlock = (index) => {
        const updatedBlocks = blocks.filter((_, i) => i !== index);
        setBlocks(updatedBlocks);
    };

    const moveBlock = (index, direction) => {
        const updatedBlocks = [...blocks];
        const targetIndex = index + direction;

        if (targetIndex < 0 || targetIndex >= blocks.length) return; // Prevent out-of-bounds moves

        const temp = updatedBlocks[targetIndex];
        updatedBlocks[targetIndex] = updatedBlocks[index];
        updatedBlocks[index] = temp;

        setBlocks(updatedBlocks);
    };

    // Check if the Next button should be enabled
    const isNextDisabled = blocks.length === 0 || blocks.some(block => block.content.trim() === '');

    return (
        <BringzzPageContainer
            header={{
                left: (
                    <BringzzIcon
                        size="22"
                        icon="IconChevronLeft"
                        folder="LineIcons"
                        className="cursor-pointer"
                        onClick={backPress}
                    />
                ),
                title: <BringzzText tag="h2">Article Body</BringzzText>
            }}
            footer={
                <div className="pb-28 px-6 flex flex-row space-x-4">
                    <BringzzButton
                        disabled={isNextDisabled}
                        size="md"
                        onClick={() => {
                            navigate('/posts/preview/article', {
                                state: {
                                    ...formData,
                                    blocks
                                }
                            })
                        }}
                        className={'bg-magic-lilac w-full flex justify-center text-midnight-blue'}
                    >
                        Next
                    </BringzzButton>
                </div>
            }
        >
            <div className="p-4">
                <div
                    className="flex border cursor-pointer border-gray-300 rounded-md p-3 items-center justify-between w-full"
                    onClick={() => setAddBlockDrawer(true)}
                >
                    <BringzzText tag="h4" className="text-gray-500">Add content block</BringzzText>
                    <BringzzIcon
                        size="24"
                        icon="IconCirclePlus"
                        folder="LineIcons"
                        className="cursor-pointer text-gray-500"
                    />
                </div>
                {/* Render blocks */}
                <div className="mt-4 space-y-4">
                    {blocks.map((block, index) => (
                        <div key={block.id} className='border border-gray-300 rounded-md'>
                            <div className='flex justify-between items-center p-3 border-b'>
                                <div><BringzzText className={'font-normal'} tag='h3'>{block.component === 'inline_heading' ? 'Inline Heading' : 'Body Copy'}</BringzzText></div>
                                <div className='flex items-center'>
                                    <BringzzIcon folder='LineIcons' icon='IconChevronDown' size='22' className='pr-1 cursor-pointer'
                                        onClick={() => moveBlock(index, 1)} // Move block down
                                    />
                                    <BringzzIcon folder='LineIcons' icon='IconChevronUp' size='22' className='pr-2 cursor-pointer'
                                        onClick={() => moveBlock(index, -1)} // Move block up
                                    />
                                    <BringzzIcon folder='LineIcons' icon='IconTrashCan' size='24' className='border-l pl-1 cursor-pointer'
                                        onClick={() => removeBlock(index)} // Remove block
                                    />
                                </div>
                            </div>
                            <div key={index} className="p-2">
                                {block.component === 'inline_heading' ? (
                                    <BringzzInput
                                        value={block.content}
                                        onChange={(e) => updateBlockContent(index, e.target.value)}
                                        placeholder="Enter heading content"
                                    />
                                ) : (
                                    <BringzzInput
                                        value={block.content}
                                        type='textarea'
                                        defaultRows={5}
                                        onChange={(e) => updateBlockContent(index, e.target.value)}
                                        className="w-full border rounded-md p-2"
                                        placeholder="Enter body content"
                                    />
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <BringzzBottomDrawer
                isOpen={addBlockDrawer}
                title={'Select content block'}
                close={() => setAddBlockDrawer(false)}
            >
                <div className="px-4 py-6">
                    <BringzzRadioList
                        state={state}
                        className="rounded-xl w-full text-center"
                        onChange={choice => {
                            handlePickerClick(choice);
                            setAddBlockDrawer(false);
                        }}
                    >
                        <BringzzRadio
                            value="inline_heading"
                            className="p-2 py-4 hover:bg-slate- border-1 border-t border-gray-400 flex"
                        >
                            <div>Inline heading</div>
                        </BringzzRadio>
                        <BringzzRadio
                            value="body_copy"
                            className="p-2 py-4 hover:bg-slate- border-1 border-t border-gray-400"
                        >
                            <div>Body Copy</div>
                        </BringzzRadio>
                    </BringzzRadioList>
                </div>
            </BringzzBottomDrawer>
        </BringzzPageContainer>
    );
};

export default BuildArticlePage;
