import * as React from "react";
const IconDeskAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.6 2H3.4c-.6 0-1 .4-1 1v11.6c0 .6.4 1 1 1h.3V61c0 .6.4 1 1 1s1-.4 1-1v-5.7h52.6V61c0 .6.4 1 1 1s1-.4 1-1V15.6h.3c.6 0 1-.4 1-1V3c0-.6-.5-1-1-1M4.4 4h55.1v9.6H4.4zm53.9 49.3H5.7V15.6h52.6z" />
    <path d="M14 31.3h12.4c1.1 0 2-.9 2-2v-5.5c0-1.1-.9-2-2-2H14c-1.1 0-2 .9-2 2v5.5c0 1.1.9 2 2 2m0-7.5h12.4v5.5H14zM14 46.2h36c1.1 0 2-.9 2-2v-5.5c0-1.1-.9-2-2-2H14c-1.1 0-2 .9-2 2v5.5c0 1.1.9 2 2 2m0-7.5h36v5.5H14zM37.6 31.2H50c1.1 0 2-.9 2-2v-5.5c0-1.1-.9-2-2-2H37.6c-1.1 0-2 .9-2 2v5.5c0 1.1.9 2 2 2m0-7.5H50v5.5H37.6z" />
  </svg>
);
export default IconDeskAlt;
