import * as React from "react";
const IconBitcoinCash = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 65 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M38.7 28.2c0-2.7-2.1-4.8-4.8-4.8h-7.5c-.6 0-1 .4-1 1v15.3c0 .6.4 1 1 1H34c2.6 0 4.8-2.2 4.8-4.8 0-1.6-.7-2.9-1.9-3.8 1.1-1 1.8-2.4 1.8-3.9M34 38.6h-6.5V33H34c1.5 0 2.8 1.3 2.8 2.8s-1.3 2.8-2.8 2.8m0-7.6h-6.5v-5.6H34c1.5 0 2.8 1.3 2.8 2.8-.1 1.5-1.3 2.8-2.8 2.8M9.6 52.1H5.1V11.9h4.5c.6 0 1-.4 1-1s-.4-1-1-1H4.1c-.6 0-1 .4-1 1v42.2c0 .6.4 1 1 1h5.5c.6 0 1-.4 1-1s-.5-1-1-1M60.1 9.9h-5.5c-.6 0-1 .4-1 1s.4 1 1 1h4.5v40.2h-4.5c-.6 0-1 .4-1 1s.4 1 1 1h5.5c.6 0 1-.4 1-1V10.9c0-.6-.4-1-1-1" />
    <path d="M32.1 9.7c-12.1 0-22 10-22 22.3s9.8 22.3 22 22.3c12.1 0 22-10 22-22.3s-9.9-22.3-22-22.3m0 42.6c-11 0-20-9.1-20-20.3s9-20.3 20-20.3 20 9.1 20 20.3-9 20.3-20 20.3" />
  </svg>
);
export default IconBitcoinCash;
