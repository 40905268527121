import * as React from "react";
const IconHospitalAlt6 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M36.3 10.8c-.6 0-1 .4-1 1v3.6h-6.6v-3.6c0-.6-.4-1-1-1s-1 .4-1 1V21c0 .6.4 1 1 1s1-.4 1-1v-3.6h6.6V21c0 .6.4 1 1 1s1-.4 1-1v-9.3c0-.5-.5-.9-1-.9M36.1 28.4h-8.2c-.6 0-1 .4-1 1s.4 1 1 1h8.2c.6 0 1-.4 1-1s-.4-1-1-1M36.1 35.6h-8.2c-.6 0-1 .4-1 1s.4 1 1 1h8.2c.6 0 1-.4 1-1s-.4-1-1-1" />
    <path d="M59.2 28.7 48.1 23V6.2c0-2.2-1.8-4-4-4H19.9c-2.2 0-4 1.8-4 4V23L4.8 28.7c-1.7.9-2.8 2.6-2.8 4.6v23.4c0 2.8 2.3 5.1 5.1 5.1h49.8c2.8 0 5.1-2.3 5.1-5.1V33.3c0-2-1.1-3.7-2.8-4.6M7.1 59.8c-1.7 0-3.1-1.4-3.1-3.1V33.3c0-1.2.7-2.2 1.7-2.8l10.2-5.2v34.5zm31.1 0H25.8v-8.6c0-3.4 2.8-6.2 6.2-6.2s6.2 2.8 6.2 6.2zm7.9 0h-5.9v-8.6c0-4.5-3.7-8.2-8.2-8.2s-8.2 3.7-8.2 8.2v8.6h-5.9V6.2c0-1.1.9-2 2-2h24.2c1.1 0 2 .9 2 2zM60 56.7c0 1.7-1.4 3.1-3.1 3.1h-8.8V25.3l10.2 5.2c1 .5 1.7 1.6 1.7 2.8z" />
  </svg>
);
export default IconHospitalAlt6;
