import * as React from "react";
const IconUserAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 26.5c7 0 12.7-5.5 12.7-12.3S39 2 32 2 19.3 7.5 19.3 14.3 25 26.5 32 26.5M32 4c5.9 0 10.7 4.6 10.7 10.3S37.9 24.5 32 24.5s-10.7-4.6-10.7-10.3S26.1 4 32 4M38.3 33.2H25.7c-9.1 0-16.5 7.4-16.5 16.5v9.9c0 1.3 1.1 2.4 2.4 2.4h40.9c1.3 0 2.4-1.1 2.4-2.4v-9.9c-.1-9.1-7.5-16.5-16.6-16.5m14.5 26.4c0 .2-.2.4-.4.4H11.6c-.2 0-.4-.2-.4-.4v-9.9c0-8 6.5-14.5 14.5-14.5h12.6c8 0 14.5 6.5 14.5 14.5z" />
  </svg>
);
export default IconUserAlt;
