import * as React from "react";
const IconPlate = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
    <path d="M32 14.5c-9.7 0-17.5 7.9-17.5 17.5S22.3 49.5 32 49.5 49.5 41.7 49.5 32 41.7 14.5 32 14.5m0 33c-8.5 0-15.5-7-15.5-15.5s7-15.5 15.5-15.5 15.5 7 15.5 15.5-7 15.5-15.5 15.5" />
  </svg>
);
export default IconPlate;
