import * as React from "react";
const IconGraphAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 48.7h-1.9v-23c0-1.7-1.3-3-3-3h-5.7c-1.7 0-3 1.3-3 3v23H38V16.3c0-1.7-1.3-3-3-3h-5.7c-1.7 0-3 1.3-3 3v32.4h-9.4v-19c0-1.7-1.3-3-3-3h-6c-1.7 0-3 1.3-3 3v19H3c-.6 0-1 .4-1 1s.4 1 1 1h58c.6 0 1-.4 1-1s-.4-1-1-1m-54.1 0v-19c0-.6.4-1 1-1h5.7c.6 0 1 .4 1 1v19zm21.2 0V16.3c0-.6.4-1 1-1h5.7c.6 0 1 .4 1 1v32.4zm21.2 0v-23c0-.6.4-1 1-1H56c.6 0 1 .4 1 1v23z" />
  </svg>
);
export default IconGraphAlt1;
