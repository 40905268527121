import * as React from "react";
const IconFingerShopping = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M39 11.2h-2.8c-.5 0-.9.3-1 .8l-1.1 4.3H18.5c-.3 0-.6.1-.8.4-.2.2-.3.6-.2.8l1.8 7.6c.1.5.5.8 1 .8h12.9c.5 0 .9-.3 1-.8L37 13.2h2c.6 0 1-.4 1-1s-.4-1-1-1m-6.7 12.7H21l-1.3-5.6h13.9zM22.2 27.4c-2 0-3.6 1.6-3.6 3.6s1.6 3.6 3.6 3.6 3.6-1.6 3.6-3.6-1.6-3.6-3.6-3.6m0 5.2c-.9 0-1.6-.7-1.6-1.6s.7-1.6 1.6-1.6 1.6.7 1.6 1.6-.7 1.6-1.6 1.6M31.1 27.4c-2 0-3.6 1.6-3.6 3.6s1.6 3.6 3.6 3.6 3.6-1.6 3.6-3.6-1.7-3.6-3.6-3.6m0 5.2c-.9 0-1.6-.7-1.6-1.6s.7-1.6 1.6-1.6 1.6.7 1.6 1.6-.8 1.6-1.6 1.6" />
    <path d="M59.4 39.5c-.4-2.8-1.9-5.1-3.9-6.8V8.5c0-2.4-2-4.4-4.4-4.4H6.4C4 4.1 2 6.1 2 8.5v28.8c0 2.4 2 4.4 4.4 4.4h31.1c0 .4.1.8.1 1.3l2.6 16.1c.1.5.5.8 1 .8h.2c.5-.1.9-.6.8-1.1l-2.6-16.1c-.1-.7-.1-1.3-.1-1.9.2-4.1 3.3-7.7 7.6-8.4 4.9-.8 9.5 2.6 10.3 7.5l2.6 16c.1.5.6.9 1.1.8s.9-.6.8-1.1zm-12.6-9.2c-4.9.8-8.5 4.7-9.2 9.4H6.4c-1.3 0-2.4-1.1-2.4-2.4V8.5c0-1.3 1.1-2.4 2.4-2.4H51c1.3 0 2.4 1.1 2.4 2.4v22.9c-1.9-1-4.2-1.4-6.6-1.1" />
    <path d="M47.8 37.1c-2.5.4-4.1 2.7-3.7 5.2L45 48c.1.5.5.8 1 .8h.2l6.9-1.1c.3 0 .5-.2.7-.4s.2-.5.2-.7l-1-5.8c-.4-2.4-2.7-4.1-5.2-3.7m-1 9.5-.7-4.6c-.2-1.4.7-2.7 2.1-2.9h.4c1.2 0 2.3.9 2.5 2.1l.8 4.7z" />
  </svg>
);
export default IconFingerShopping;
