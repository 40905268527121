import * as React from "react";
const IconMentalDisorders = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      d="M34.723 25.5c0 .828-.685 1.5-1.532 1.5-.846 0-1.531-.672-1.531-1.5s.686-1.5 1.531-1.5c.847 0 1.532.672 1.532 1.5M17.362 34h4.085v-2h-4.085zM21.447 38h-4.085v-2h4.085z"
    />
    <path
      
      fillRule="evenodd"
      d="M27.292 22.146c.5 0 .582-.091.741-.266.093-.1.21-.23.45-.385a4.1 4.1 0 0 0 2.24.636l2.532-.878a3.82 3.82 0 0 0 1.388-2.2 3.69 3.69 0 0 0-.376-2.543 3.96 3.96 0 0 0-1.972-1.75 3.73 3.73 0 0 0-1.382-2.12l-2.472-.824h-.278a3.97 3.97 0 0 0-2.32-1.532L21.565 10c-.88.07-1.711.42-2.358.994a4.08 4.08 0 0 0-3.073.502 3.8 3.8 0 0 0-1.45 1.62l-2.012 1.358a3.64 3.64 0 0 0-.77 2.234c-.005.337.042.672.14.995a3.6 3.6 0 0 0-.808 2.28 3.65 3.65 0 0 0 1.072 2.572c.055.685.306 1.342.726 1.9.42.56.99.998 1.653 1.27a5.9 5.9 0 0 1 1.89 1.793c.482.725.68 1.544.787 2.396h4.203v-3.978c0-.706.206-1.398.597-1.998q.089-.137.169-.265c.3-.478.542-.866 1.106-1.13a4.46 4.46 0 0 0 1.642-1.048 4.06 4.06 0 0 0 2.212.65m1.535-2.826.773.495c.243.155.524.257.819.294l1.768-.613c.233-.246.389-.543.456-.858.086-.402.025-.82-.177-1.19a1.94 1.94 0 0 0-.966-.852l-.965-.4-.232-1a1.7 1.7 0 0 0-.437-.798l-1.763-.588h-1l-.61-.848a1.9 1.9 0 0 0-.99-.702l-3.898-.258a1.96 1.96 0 0 0-1.026.468l-.79.701-1.039-.233a2.004 2.004 0 0 0-1.523.242 1.8 1.8 0 0 0-.684.761l-.235.502-2.154 1.455c-.139.249-.21.524-.21.802v.032c-.002.134.017.27.056.4l.3.994-.658.813c-.243.3-.368.662-.365 1.026v.015c0 .428.17.85.494 1.177l.512.516.059.718c.025.31.139.613.335.874.197.263.47.475.796.608l.143.059.133.08q.456.27.869.598c.355.273.797.672 1.37 1.388.6.75.928 1.589 1.017 1.914h.523v-.875q0-.132-.004-.309c-.01-.574-.027-1.457.152-2.152.215-.836.658-1.652 1.212-2.43.555-.777 1.393-1.32 1.708-1.437s.634-.238.945-.543c.25-.244.94-.945 1.253-1.265l1.4.918c.268.175.623.273.766.3.039-.03.16-.125.335-.254.176-.128.866-.576 1.19-.785z"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M40.56 25.366s2.652 2.336 2.302 4.21-3.803 2.39-3.803 2.39c0 7.573-7.386 7.091-11.072 6.379-1.226-.237-2.042-.5-2.042-.5v1.424s-.077.238-.314.574c-.563.797-2.029 2.142-5.515 2.142-2.333.181-6.326-1.314-7.384-3.422v-6.449c-3.73-2.73-6.604-6.407-6.604-11.174 0-3.963 1.688-7.763 4.694-10.564S17.905 6 22.156 6a23.6 23.6 0 0 1 9.399 1.929c2.32.91 4.352 2.36 5.9 4.21 1.416 1.943 2.2 4.226 2.26 6.573.012.54.005 1.052-.003 1.547-.026 1.747-.05 3.286.848 5.107m-1.665 1.224-.175-.355c-1.129-2.29-1.088-4.416-1.054-6.217.008-.44.016-.859.007-1.257a9.7 9.7 0 0 0-1.84-5.406c-1.321-1.559-3.052-2.79-5.038-3.57l-.033-.013-.033-.014A21.6 21.6 0 0 0 22.16 8h-.003c-3.74 0-7.31 1.386-9.925 3.824-2.613 2.435-4.06 5.717-4.06 9.116 0 3.882 2.319 7.037 5.784 9.572l.82.6v6.87c.34.397 1.002.891 1.996 1.32 1.219.527 2.454.746 3.184.69l.08-.007h.081c2.072 0 3.049-.557 3.47-.899.145-.117.247-.228.316-.315v-3.686l2.676.86h.003l.015.005.086.026q.121.036.362.101c.32.085.785.199 1.345.307 1.136.22 2.584.4 3.98.31 1.419-.09 2.557-.442 3.317-1.075.683-.569 1.33-1.589 1.33-3.652v-1.718l1.727-.258.011-.002.071-.013a6.701 6.701 0 0 0 1.23-.353c.343-.137.591-.28.738-.403l.053-.048a1.3 1.3 0 0 0-.09-.294 4.4 4.4 0 0 0-.555-.92 8.7 8.7 0 0 0-1-1.1l-.008-.005v-.001l-.002-.001-.001-.002zm1.988 2.54v.002l-.003.002z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconMentalDisorders;
