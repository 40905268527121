import * as React from "react";
const IconMedal1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M42.2 2H21.8c-2.6 0-4.7 2.1-4.7 4.7v18.6c0 1.7 1 3.3 2.5 4.2l5.5 2.9c.1.1.2.2.4.3 0 .1-.1.2-.1.3v5c0 3 2.1 5.6 4.9 6.3l-1.9 3.8-6 .9c-.4.1-.7.3-.8.7s0 .8.3 1l4.3 4.2-1 6c-.1.4.1.8.4 1s.7.3 1.1.1l5.3-2.8 5.3 2.8c.1.1.3.1.5.1s.4-.1.6-.2c.3-.2.5-.6.4-1l-1-6 4.3-4.2c.3-.3.4-.7.3-1-.1-.4-.4-.6-.8-.7l-6-.9-1.9-3.8c2.8-.8 4.9-3.3 4.9-6.3v-5c0-.1 0-.2-.1-.3.2-.1.3-.1.4-.3l5.5-2.9c1.5-.8 2.5-2.4 2.5-4.2V6.7c0-2.6-2.1-4.7-4.7-4.7m-8.9 31.1c-.8.4-1.8.4-2.5 0L26.9 31V4h10.4v27.1zm-14.2-7.8V6.7c0-1.5 1.2-2.7 2.7-2.7h3v26l-4.3-2.3c-.9-.5-1.4-1.4-1.4-2.4M34.8 50l4.5.7-3.3 3.1c-.2.2-.3.6-.3.9l.8 4.5-4-2.1c-.1-.1-.3-.1-.5-.1s-.3 0-.5.1l-4 2.1.8-4.5c.1-.3-.1-.7-.3-.9l-3.3-3.2 4.5-.7c.3 0 .6-.3.8-.5l2-4.1 2 4.1c.2.3.4.5.8.6m-2-7.5c-.4-.4-1.1-.4-1.5 0-2.2-.4-3.8-2.2-3.8-4.5v-4.3l2.3 1.3c.7.4 1.5.6 2.2.6s1.5-.2 2.2-.6l2.3-1.3V38c.1 2.2-1.6 4.1-3.7 4.5m12.1-17.2c0 1-.5 1.9-1.4 2.4L39.2 30V4h3c1.5 0 2.7 1.2 2.7 2.7z" />
  </svg>
);
export default IconMedal1;
