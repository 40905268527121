import * as React from "react";
const IconHome4 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 32.8h-1l-8-15.9c-.2-.3-.5-.6-.9-.6H28v-12c0-.6-.4-1-1-1H16.4c-.6 0-1 .4-1 1v12.1h-2.5q-.6 0-.9.6L4 32.8H3c-.6 0-1 .4-1 1s.4 1 1 1h.7v25c0 .6.4 1 1 1h20.7c.6 0 1-.4 1-1V52c0-3.1 2.5-5.6 5.6-5.6s5.6 2.5 5.6 5.6v7.7c0 .6.4 1 1 1h20.7c.6 0 1-.4 1-1v-25h.7c.6 0 1-.4 1-1s-.4-.9-1-.9M17.4 5.3H26v11.1h-8.7V5.3zm-3.9 13h37l7.2 14.5H6.3zm44.8 40.4H39.6V52c0-4.2-3.4-7.6-7.6-7.6s-7.6 3.4-7.6 7.6v6.7H5.7v-24h52.7v24z" />
  </svg>
);
export default IconHome4;
