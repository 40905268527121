import * as React from "react";
const IconAtm = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.3 13.9H6.7C4.1 13.9 2 16 2 18.6v26.7C2 47.9 4.1 50 6.7 50h50.6c2.6 0 4.7-2.1 4.7-4.7V18.6c0-2.6-2.1-4.7-4.7-4.7M60 45.4c0 1.5-1.2 2.7-2.7 2.7H6.7c-1.5 0-2.7-1.2-2.7-2.7V18.6c0-1.5 1.2-2.7 2.7-2.7h50.6c1.5 0 2.7 1.2 2.7 2.7z" />
    <path d="M18.9 24.8h-6.7c-.6 0-1 .4-1 1v13.1c0 .6.4 1 1 1s1-.4 1-1v-6.3h4.7v6.3c0 .6.4 1 1 1s1-.4 1-1V25.8c0-.5-.5-1-1-1m-5.7 2h4.7v3.8h-4.7zM53.8 24.9c-.4-.1-.8 0-1.1.3L47.5 31l-5.2-5.8c-.3-.3-.7-.4-1.1-.3s-.6.5-.6.9v13.1c0 .6.4 1 1 1s1-.4 1-1V28.5l4.2 4.6c.2.2.5.3.7.3.3 0 .6-.1.7-.3l4.2-4.6V39c0 .6.4 1 1 1s1-.4 1-1V25.8q0-.6-.6-.9M35.5 24.8h-9.9c-.6 0-1 .4-1 1s.4 1 1 1h4v12.1c0 .6.4 1 1 1s1-.4 1-1V26.8h4c.6 0 1-.4 1-1s-.6-1-1.1-1" />
  </svg>
);
export default IconAtm;
