import * as React from "react";
const IconThunder = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.3 19.6c-2.7-2.9-6.4-4.9-10.3-5.6-2.2-3.5-5.4-6.1-9.1-7.4-1.7-.7-3.7-1-5.8-1-9.5 0-17.2 7.4-17.8 16.7-7 .9-12.5 6.8-12.5 14.1C1.8 44.3 8 50.6 16 50.6c1.2 0 2.2-1 2.3-2.2 0-1.2-1-2.3-2.2-2.3-5.4 0-9.7-4.3-9.7-9.7s4.3-9.7 9.7-9.7h.5c1.2 0 2.3-1 2.3-2.3v-1c0-7.3 6-13.3 13.3-13.3 1.6 0 3 .2 4.3.7 3.1 1.1 5.7 3.3 7.3 6.3.4.7 1 1.1 1.7 1.2 3.3.3 6.4 1.9 8.7 4.3 2.4 2.6 3.8 6 3.8 9.5 0 5.8-3.7 11.1-9.2 13.1-1.2.4-1.8 1.7-1.3 2.9.4 1.2 1.7 1.8 2.9 1.3 7.3-2.6 12.1-9.6 12.1-17.3-.2-4.5-2-9-5.2-12.5" />
    <path d="M48.9 32.3c-.6-1.3-1.8-2-3.2-2h-3.2l.4-.7c.8-1.5.8-3.4-.1-4.9s-2.5-2.4-4.2-2.4h-7.7c-1.9 0-3.7 1.1-4.5 2.9l-5.6 12.5c-.5 1.1-.4 2.4.3 3.4s1.8 1.6 3 1.6h4.5l-3.3 11c-.5 1.7.2 3.4 1.8 4.2.5.3 1.1.4 1.7.4 1 0 2.1-.5 2.8-1.3l17-20.9c.7-1.1.9-2.5.3-3.8m-18 18.2 2.8-9.4c.2-.7.1-1.4-.3-2s-1.1-.9-1.8-.9h-6.1l5-11.2c.1-.2.2-.2.4-.2h7.7c.2 0 .3.1.4.2s.1.3 0 .4l-2.2 4c-.4.7-.4 1.5 0 2.2s1.1 1.1 1.9 1.1h5z" />
  </svg>
);
export default IconThunder;
