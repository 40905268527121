import * as React from "react";
const IconAt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.6 24.9C58.3 11.4 46.3 2 32.4 2c-16.5 0-30 13.5-30 30s13.5 30 30 30c4.4 0 8.6-.9 12.5-2.7.5-.2.7-.8.5-1.3s-.8-.7-1.3-.5c-3.7 1.7-7.6 2.5-11.7 2.5-15.4 0-28-12.6-28-28S17 4 32.4 4c13 0 24.1 8.8 27.2 21.3.1.5.7.9 1.2.7.6-.1.9-.6.8-1.1" />
    <path d="M57.9 42.3c-1.4 1.5-3.3 2.3-5.3 2.3-1.9 0-3.7-.7-5.1-2.1s-2.1-3.2-2.1-5.1V32c0-7.2-5.9-13.1-13.1-13.1S19.2 24.8 19.2 32s5.9 13.1 13.1 13.1c4.7 0 8.9-2.5 11.2-6.3.3 1.9 1.2 3.7 2.6 5.2 1.7 1.7 4 2.7 6.5 2.7 2.6 0 5-1.1 6.7-3 .4-.4.3-1-.1-1.4s-.9-.4-1.3 0m-25.5.8c-6.1 0-11.1-5-11.1-11.1s5-11.1 11.1-11.1 11.1 5 11.1 11.1-5 11.1-11.1 11.1" />
  </svg>
);
export default IconAt;
