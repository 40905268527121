import * as React from "react";
const IconWeathercock = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.8 52.7v-.2l4.1-4.1c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-4.6 4.6H34.3V40c9.2-.3 16.7-7.8 16.7-17.1v-7.8c0-1.5 1.3-2.8 2.8-2.8.6 0 1-.4 1-1s-.4-1-1-1c-2.6 0-4.8 2.2-4.8 4.8v5.6H30v-7.5c0-3-1.9-5.5-4.6-6.3L29 3.2c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-4.2 4.4-4.2-4.4c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l3.6 3.7c-2.5.8-4.3 3-4.6 5.7h-3.9c-.6 0-1 .4-1 1s.4 1 1 1h3.9v8.3c0 8.9 6.9 16.3 15.6 17v11.5h-20l3.2-3.2c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-4.9 4.9c-.2.2-.3.4-.3.7s.1.5.3.7l4.9 4.9c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-3.2-3.2h20v8c0 .6.4 1 1 1s1-.4 1-1v-8h14.5l4.6 4.6c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4zM18.7 22.9v-9.7c0-2.6 2.1-4.7 4.7-4.7s4.7 2.1 4.7 4.7v8.5c0 .3.1.5.3.7s.4.3.7.3H49v.2C49 31.2 42.2 38 33.9 38c-8.4.1-15.2-6.7-15.2-15.1" />
  </svg>
);
export default IconWeathercock;
