import * as React from "react";
const IconBrushCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.2 19.6 44.5 3.9c-2.4-2.4-6.4-2.4-8.8 0L20.6 19c-2.3 2.3-3.5 5.2-3.5 8.4 0 2.9 1 5.5 2.8 7.7l-16 16.1C2.7 52.3 2 53.9 2 55.6s.6 3.3 1.9 4.5c1.2 1.2 2.9 1.8 4.5 1.8s3.2-.6 4.4-1.8l16-16.1c2.2 1.9 5 2.9 7.8 2.9 3.1 0 6.1-1.2 8.5-3.5l15.1-15c1.2-1.2 1.8-2.8 1.8-4.4s-.7-3.3-1.8-4.4M11.3 58.7c-1.6 1.6-4.4 1.6-6.1 0-.8-.9-1.2-2-1.2-3.1s.5-2.2 1.3-3l16-16 6.1 6.1zM43.7 42c-3.9 3.8-10.2 3.8-14.1 0L22 34.5c-1.9-1.9-2.9-4.4-2.9-7s1-5.1 2.9-7l3.5-3.5 21.7 21.6zm15.1-15L48.6 37.1 27 15.5 37.1 5.4c1.7-1.7 4.4-1.7 6 0L58.8 21c.8.8 1.2 1.9 1.2 3s-.4 2.2-1.2 3" />
    <path d="M27.5 25.7c-1.5 0-2.7 1.2-2.7 2.7s1.2 2.7 2.7 2.7 2.7-1.2 2.7-2.7-1.2-2.7-2.7-2.7" />
    <circle cx={35.6} cy={36.5} r={2.7} />
  </svg>
);
export default IconBrushCopy;
