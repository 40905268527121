import * as React from "react";
const IconMessageShare = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.5 45.8c-1.8 0-3.3.9-4.3 2.2l-3.6-1.7c.2-.5.3-1.1.3-1.7 0-.3 0-.6-.1-.9l4.1-2.1c1 .9 2.3 1.5 3.7 1.5 2.9 0 5.3-2.4 5.3-5.2s-2.4-5.2-5.3-5.2-5.3 2.4-5.3 5.2c0 .7.2 1.4.4 2.1L48 41.9c-.9-1.5-2.6-2.5-4.5-2.5-2.9 0-5.3 2.4-5.3 5.2s2.4 5.2 5.3 5.2c1.6 0 3-.7 4-1.8l3.7 1.8c-.1.4-.2.8-.2 1.2 0 2.9 2.4 5.2 5.3 5.2s5.3-2.4 5.3-5.2-2.1-5.2-5.1-5.2m.2-11.2c1.8 0 3.3 1.5 3.3 3.2S58.5 41 56.7 41s-3.3-1.5-3.3-3.2 1.5-3.2 3.3-3.2m-13 13.3c-1.8 0-3.3-1.5-3.3-3.2 0-1.8 1.5-3.2 3.3-3.2S47 43 47 44.7s-1.5 3.2-3.3 3.2m12.8 6.4c-1.8 0-3.3-1.5-3.3-3.2 0-1.8 1.5-3.2 3.3-3.2s3.3 1.5 3.3 3.2-1.4 3.2-3.3 3.2" />
    <path d="M33.9 46.3H7c-1.6 0-3-1.3-3-3V12.7c0-.4.1-.7.2-1l24.4 14.8c.8.5 1.8.8 2.7.8s1.9-.3 2.7-.8l24.4-14.8c.1.3.2.7.2 1v14c0 .6.4 1 1 1s1-.4 1-1v-14c0-2.7-2.2-5-5-5H7c-2.7 0-5 2.2-5 5v30.6c0 2.7 2.2 5 5 5h26.9c.6 0 1-.4 1-1s-.5-1-1-1M55.6 9.7c.5 0 1 .2 1.5.4L33 24.7c-1 .6-2.3.6-3.4 0L5.5 10.1c.4-.2.9-.4 1.5-.4z" />
  </svg>
);
export default IconMessageShare;
