import * as React from "react";
const IconOrange = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M37.9 17.4c2.2-.3 4.5-1.1 6.6-2.4 4.1-2.5 6.7-6.3 7-9.9 0-.4-.2-.8-.6-1-3.4-1.4-7.9-.6-12 1.9-2.7 1.7-4.8 3.9-5.9 6.3V5.8c0-.4-.2-.7-.5-.9l-4.9-2.8c-.5-.3-1.1-.1-1.4.4-.2.5-.1 1.1.4 1.4L31 6.4v10.2c-12.1.6-21.7 10.5-21.7 22.7C9.3 51.8 19.5 62 32 62s22.7-10.2 22.7-22.7c0-10.5-7.1-19.3-16.8-21.9M40 7.6c3.2-2 6.8-2.7 9.5-2-.5 2.7-2.8 5.7-6 7.6-3.2 2-6.8 2.7-9.5 2 .6-2.7 2.8-5.6 6-7.6M32 60c-11.4 0-20.7-9.3-20.7-20.7S20.6 18.6 32 18.6s20.7 9.3 20.7 20.7S43.4 60 32 60" />
  </svg>
);
export default IconOrange;
