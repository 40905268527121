import * as React from "react";
const IconVideoWedding = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 9.2H6c-2.2 0-4 1.8-4 4v37.5c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V13.2c0-2.2-1.8-4-4-4M13.2 31H4V20.7h9.2zM4 33h9.2v10.3H4zm11.2-21.8h33.5v41.5H15.2zM60 31h-9.3V20.7H60zm-9.3 2H60v10.3h-9.3zM60 13.2v5.5h-9.3v-7.5H58c1.1 0 2 .9 2 2m-54-2h7.2v7.5H4v-5.5c0-1.1.9-2 2-2M4 50.8v-5.5h9.2v7.5H6c-1.1 0-2-.9-2-2m54 2h-7.3v-7.5H60v5.5c0 1.1-.9 2-2 2" />
    <path d="M37.9 26.6c-1.6-1.6-4-2-6-.9-.7-.4-1.5-.5-2.3-.6-1.3 0-2.5.5-3.5 1.3-1 .9-1.6 2.2-1.6 3.6 0 1.5.5 2.9 1.6 4l4.4 4.4q.6.6 1.5.6c.5 0 1.1-.2 1.5-.6l4.5-4.5c1-1 1.5-2.3 1.5-3.6-.1-1.5-.6-2.8-1.6-3.7m-1.4 5.8L32 36.9h-.1l-4.4-4.4c-.7-.7-1-1.6-1-2.5s.4-1.6 1-2.2c.6-.5 1.3-.8 2.1-.8.7 0 1.2.2 1.8.6.3.2.8.2 1.1 0 1.2-.9 2.9-.7 4 .4.6.6.9 1.4.9 2.2s-.3 1.6-.9 2.2" />
  </svg>
);
export default IconVideoWedding;
