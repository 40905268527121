import * as React from "react";
const IconA = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M21.23 10.846a3 3 0 0 1 5.54 0l7.478 17.95q.023.05.043.102l2.478 5.948a3 3 0 1 1-5.538 2.308L29.5 33H18.5l-1.73 4.154a3 3 0 1 1-5.54-2.308L13.71 28.9l.043-.104zM24 11a1 1 0 0 0-.923.615l-7.483 17.96-.009.02-.017.042-.009.021-2.482 5.957a1 1 0 1 0 1.846.77l1.987-4.77a1 1 0 0 1 .923-.615h12.334a1 1 0 0 1 .923.615l1.987 4.77a1 1 0 0 0 1.846-.77l-2.482-5.957-.009-.021-.009-.023-.008-.019-.01-.02-7.482-17.96A1 1 0 0 0 24 11m0 5.2a1 1 0 0 1 .923.615l4.5 10.8A1 1 0 0 1 28.5 29h-9a1 1 0 0 1-.923-1.385l4.5-10.8A1 1 0 0 1 24 16.2M21 27h6l-3-7.2z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconA;
