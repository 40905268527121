import * as React from "react";
const IconLitecoin = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 65 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32.5 3c-16 0-29 13-29 29s13 29 29 29 29-13 29-29-13-29-29-29m0 56c-14.9 0-27-12.1-27-27s12.1-27 27-27 27 12.1 27 27-12.1 27-27 27" />
    <path d="M45.1 43.6H26.2l3.5-10H41c.6 0 1-.4 1-1s-.4-1-1-1H30.4l4.1-11.8c.2-.5-.1-1.1-.6-1.3s-1.1.1-1.3.6l-4.4 12.4h-8.3c-.6 0-1 .4-1 1s.4 1 1 1h7.6l-3.8 10.7c-.1.3-.1.6.1.9s.5.4.8.4h20.3c.6 0 1-.4 1-1s-.3-.9-.8-.9" />
  </svg>
);
export default IconLitecoin;
