import * as React from "react";
const IconCornerDoubleRightDown = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.7 44c-.4-.4-1-.4-1.4 0L47.6 55.7V25.1c0-3.7-3-6.8-6.8-6.8H6.4L18.1 6.6c.4-.4.4-1 0-1.4s-1-.4-1.4 0L3.3 18.6c-.4.4-.4 1 0 1.4l13.4 13.4c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4L6.4 20.3h34.4c2.6 0 4.8 2.2 4.8 4.8v30.6L34 44c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4L46 58.8c.2.2.4.3.7.3s.5-.1.7-.3l13.4-13.4c.3-.3.3-1-.1-1.4" />
  </svg>
);
export default IconCornerDoubleRightDown;
