import * as React from "react";
const IconPiAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M46.4 39.2c-.6 0-1 .4-1 1 0 .1 0 1.5-.8 3-.4.9-1 1.6-1.7 2.1q-1.35.9-3.3.9c-1.3 0-2.4-.4-3.1-1.3-.7-.8-1.2-2-1.3-3.5-.1-1 0-1.8 0-2 0-.3 1.3-14.1 1.5-17h5.1c1 0 2-.5 2.6-1.3l2.8-3.7c.3-.4.2-1.1-.2-1.4s-1.1-.2-1.4.2L42.7 20c-.2.3-.6.5-1 .5H24.2c-1 0-1.9.4-2.5 1.2l-4.8 5.9c-.4.4-.3 1.1.1 1.4.2.2.4.2.6.2.3 0 .6-.1.8-.4l4.8-5.9c.2-.3.6-.5 1-.5h3.5l-.1.9c-.2 4.1-.6 9.7-1.5 14.4-.5 2.6-1.2 4.6-2 6-1 1.7-2.1 2.5-3.5 2.5-.6 0-1 .4-1 1s.4 1 1 1c1.5 0 3.5-.6 5.2-3.5.9-1.6 1.6-3.7 2.2-6.6 1-4.8 1.3-10.5 1.6-14.7l.1-1h4.9c-.3 3.3-1.5 16.5-1.5 16.8s-.1 1.2 0 2.4c.2 1.9.8 3.5 1.8 4.6 1.1 1.3 2.7 2 4.6 2 1.7 0 3.2-.4 4.4-1.3 1-.7 1.8-1.7 2.4-2.8.9-1.8 1-3.6 1-3.8.1-.6-.4-1.1-.9-1.1" />
    <path d="M58 2H6C3.8 2 2 3.8 2 6v52c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V6c0-2.2-1.8-4-4-4m2 56c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2h52c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconPiAlt1;
