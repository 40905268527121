import * as React from "react";
const IconCutter = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.1 8.5c-.7-2-2.1-3.5-4-4.4l-3.6-1.7c-1.9-.9-4-1-6-.3s-3.5 2.1-4.4 4l-1.6 3.5-15.4 33.2c-.2.4-.1.8.2 1.1l2.1 2.3-3.8 8.1c-.1.3-.1.6 0 .8l2.8 6.6q.3.6.9.6h.3l6.9-2.1c.3-.1.5-.3.6-.5l3.8-8.1 3.1.1q.6 0 .9-.6L48.3 18l1.6-3.5c.8-1.9.9-4.1.2-6M23.3 58.4l-5.6 1.7-2.3-5.4 3.2-6.9 2.9 3.2c.2.2.4.3.7.3l4.3.1zm8-8.8-2.9-.1h-.3l-5.2-.2-3.5-3.9c-.1-.1-.1-.2-.2-.2l-2-2.2 14.7-31.6L46 18zm16.7-36-1.2 2.6-7-3.3-7-3.3L34 7c.7-1.4 1.8-2.5 3.3-3s3-.5 4.4.2l3.6 1.7c1.4.7 2.5 1.8 3 3.3.5 1.4.4 3-.3 4.4" />
    <path d="m32.6 21.4 1.7.8-4.4 9.5c-.2.5 0 1.1.5 1.3.1.1.3.1.4.1q.6 0 .9-.6l4.4-9.5 1.7.8c.1.1.3.1.4.1q.6 0 .9-.6c.2-.5 0-1.1-.5-1.3L36 20.8l-2.6-1.2c-.5-.2-1.1 0-1.3.5s0 1.1.5 1.3" />
  </svg>
);
export default IconCutter;
