import * as React from "react";
const IconBillboard = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.1 6.4H24.7c-2.8 0-5 2.2-5 5v3.4H5.9V3c0-.6-.4-1-1-1s-1 .4-1 1v58c0 .6.4 1 1 1s1-.4 1-1V49.2h13.8v3.4c0 2.8 2.2 5 5 5h30.4c2.8 0 5-2.2 5-5V11.4c0-2.7-2.3-5-5-5M5.9 47.2V16.8h13.8v30.4zm52.2 5.4c0 1.7-1.3 3-3 3H24.7c-1.7 0-3-1.3-3-3V11.4c0-1.7 1.3-3 3-3h30.4c1.7 0 3 1.3 3 3z" />
    <path d="M41.3 13.6c-.3-.7-1.5-.7-1.8 0l-7 14.3c-.2.5 0 1.1.5 1.3s1.1 0 1.3-.5l6.1-12.4 6.1 12.4q.3.6.9.6c.1 0 .3 0 .4-.1.5-.2.7-.8.5-1.3zM39.1 34.2h-3.4c-.6 0-1 .4-1 1v14.6c0 .6.4 1 1 1h3.4c3.9 0 7.1-3.2 7.1-7.1v-2.4c0-3.9-3.2-7.1-7.1-7.1m5.1 9.5c0 2.8-2.3 5.1-5.1 5.1h-2.4V36.2h2.4c2.8 0 5.1 2.3 5.1 5.1z" />
  </svg>
);
export default IconBillboard;
