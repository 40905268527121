import * as React from "react";
const IconMagnifyingGlass = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.4 10.6C43.3.5 26.9.5 16.8 10.6 12 15.5 9.3 22 9.3 28.9c0 6.6 2.4 12.7 6.9 17.5L3.3 59.2c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3l12.9-12.8c4.9 4.6 11.3 6.9 17.6 6.9 6.6 0 13.2-2.5 18.3-7.5 4.9-4.9 7.6-11.4 7.6-18.2-.1-7-2.8-13.5-7.7-18.4M18.3 12C23 7.4 29.1 5 35.2 5c5.8 0 11.5 2.1 16.1 6.3l-7.4 6.1c-2.5 2.1-6.3 1.9-8.7-.5-.8-.8-2-1.1-2.8-.9-.4.1-1.6.3-2.3 1.5l-1.4 2.2c-2 3-5.9 3.8-8.9 1.9-1.5-.9-2.8-1.8-4.1-2.6L14 18h-.1c1.1-2.2 2.6-4.2 4.4-6m7.4 38.7c-2.7-1.2-5.2-2.8-7.4-5-2.8-2.8-4.8-6.2-6-9.9l7.9-1.1c3.8-.6 7.6 1.6 9 5.2 1.2 3.2.4 6.8-2 9.2zm2.1.8.9-.9c3-3 3.9-7.4 2.4-11.4-1.9-4.5-6.5-7.2-11.2-6.5l-8.1 1.2c-.3-1.6-.5-3.3-.5-5 0-3.2.6-6.2 1.8-9.1l1.4.9c1.3.8 2.7 1.7 4.1 2.6s3 1.3 4.5 1.3c2.8 0 5.4-1.3 7.1-3.8l1.4-2.2c.2-.4.6-.5 1-.6.1 0 .6 0 1 .4 3.2 3.1 8.1 3.4 11.4.6l7.6-6.2c2.1 2.3 3.7 4.9 4.8 7.7h-.1L48.5 25c-4.4 2.3-7.2 6.9-7.2 11.8 0 3.5 1.4 6.9 3.9 9.4.5.5 2.1 1.6 3.3 2.4-6.1 4.1-13.8 5.1-20.7 2.9M52 45.7c-.6.6-1.1 1.1-1.7 1.6-1.3-.8-3.2-2.1-3.6-2.5-2.2-2.2-3.4-5-3.4-8 0-4.2 2.3-8 6.1-10l8.6-4.4c.6 2.1.9 4.3.9 6.5.1 6.3-2.4 12.3-6.9 16.8" />
  </svg>
);
export default IconMagnifyingGlass;
