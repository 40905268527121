import * as React from "react";
const IconMicrosoftEdge = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.1 48.1c-.1-.1-.3-.1-.4 0-.8.4-1.8.8-2.7 1.1-3 1.1-5.9 1.7-9 1.7-5.9 0-11.4-2-15.8-5.5-4.4-3.7-6.8-8.5-6.8-13.5 0-1.1.3-2.4.7-3.4-7.5 6.5-8.9 17.8-2.8 25.9q2.1 3.15 5.4 5.1c3.9 2.4 8.5 3.2 13 2.4.7-.1 1.4-.3 2-.6l.3-.1c6.6-2.3 12.4-6.9 16.1-12.8.3-.1.3-.3 0-.3" />
    <path d="M26.3 60.6c-.1-.1-.3-.1-.4-.3-2.1-1.4-3.9-3.1-5.4-5.2-3.2-4.4-4.5-9.9-3.7-15.4s3.8-10.1 8.2-13.4c0 0 .1-.1.3-.1.4-.3 1-.7 1.6-1 .4-.3.8-.4 1.3-.7h.1c.8-.4 2.3-1 3.9-1 2.3 0 4.4 1 5.9 2.4-.1-.3-.3-.4-.4-.7-1.3-2.1-3.1-4.8-7.3-7-4.4-2.3-9.2-2.4-10.1-2.4-9.6 0-17.6 5.9-19 13.7-.3.7-.3 1.4-.3 2.1 0 4.7 1 9.3 3 13.5 4.8 10.3 14.8 16.8 25.5 17.8.8.1 1.7.1 2.5.1h.1c-.7-.1-1.3-.3-2-.6-1.3-.4-2.6-1.1-3.8-1.8" />
    <path d="m60.5 17.1-.1-.3C55.3 7.1 44.4 1 32 1 18.4 1 6.7 10 2.6 22.3c-.1.4-.3 1-.4 1.4.4-.7 1-1.4 1.5-2.1 1-1 2-2 3.1-2.8 3.5-2.5 8.2-4.2 13.2-4.2 1.4 0 6.2.1 10.7 2.5s6.6 5.2 7.9 7.5c.6 1 1 2.1 1.3 3.1.1.7.3 1.4.4 2.1.1 1 .1 1.8.1 2.3v.3c0 1.5-.8 3.7-2.1 5.4l-.3.3c-.3.3-.4.4-.4.7 0 .4.3.8.8 1.3 3.2 2.3 9.6 2.1 9.9 2 2.5 0 4.9-.7 7.2-2 4.5-2.5 7.2-7.3 7.2-12.5.3-5.3-1.4-8.8-2.2-10.5" />
  </svg>
);
export default IconMicrosoftEdge;
