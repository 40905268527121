import * as React from "react";
const IconSize = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 2c-.6 0-1 .4-1 1v2H16.8V3c0-.6-.4-1-1-1s-1 .4-1 1v6c0 .6.4 1 1 1s1-.4 1-1V7H60v2c0 .6.4 1 1 1s1-.4 1-1V3c0-.6-.4-1-1-1M57 14.7H20.2c-2.8 0-5 2.2-5 5V57c0 2.8 2.2 5 5 5H57c2.8 0 5-2.2 5-5V19.7c0-2.7-2.2-5-5-5M60 57c0 1.7-1.3 3-3 3H20.2c-1.7 0-3-1.3-3-3V19.7c0-1.7 1.3-3 3-3H57c1.7 0 3 1.3 3 3zM9 16.8c.6 0 1-.4 1-1s-.4-1-1-1H3c-.6 0-1 .4-1 1s.4 1 1 1h2V60H3c-.6 0-1 .4-1 1s.4 1 1 1h6c.6 0 1-.4 1-1s-.4-1-1-1H7V16.8z" />
  </svg>
);
export default IconSize;
