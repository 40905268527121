import * as React from "react";
const IconFolderNetworking2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.4 13.7V7.2c0-.6-.4-1-1-1h-21c-.1 0-.2 0-.2.1l-.2-.4C30.3 4.2 28.6 3 26.7 3H11.2C8.6 3 6.5 5.1 6.5 7.6v33.2c0 2.6 2.1 4.6 4.6 4.6h17.7v5c-2.4.4-4.3 2.3-4.7 4.7H11c-.6 0-1 .4-1 1s.4 1 1 1h13.1c.5 2.7 2.9 4.8 5.7 4.8 2.9 0 5.2-2.1 5.7-4.8h13.1c.6 0 1-.4 1-1s-.4-1-1-1h-13c-.4-2.4-2.3-4.3-4.7-4.7v-5h22c2.6 0 4.6-2.1 4.6-4.6V18.2c0-2.3-1.8-4.3-4.1-4.5M33.6 56.2c0 2.1-1.7 3.8-3.8 3.8S26 58.3 26 56.2s1.7-3.8 3.8-3.8 3.8 1.7 3.8 3.8m17.8-48v5.4H37.3c-1.9 0-3.6-1.2-4.3-2.9l-1-2.5zm4.1 32.6c0 1.4-1.2 2.6-2.6 2.6H11.2c-1.4 0-2.6-1.2-2.6-2.6V7.6C8.5 6.2 9.7 5 11.2 5h15.6c1.1 0 2 .6 2.4 1.7l1.9 4.8c1 2.5 3.4 4.2 6.2 4.2h15.6c1.4 0 2.6 1.2 2.6 2.6z" />
  </svg>
);
export default IconFolderNetworking2;
