import React, { useEffect, useState } from 'react';
import { useNavigation } from 'context/NavigationContext';
import { BringzzPageContainer, BringzzIcon, BringzzText, BringzzInput } from "@bringzz/components";
import useRequest from 'hooks/useRequest';
import BringzzChatRow from 'components/BringzzChatRow';
import useUser from 'hooks/useUser';

const NewChatPage = () => {
    const { backPress, navigate } = useNavigation();

    const [searchQuery, setSearchQuery] = useState("");
    const { sendRequest, data, error, loading } = useRequest();
    const [newChats, setNewChats] = useState([])
    const { getUser } = useUser();
    const user = getUser();

    useEffect(() => {
        const fetchNewChats = async () => {
            await sendRequest({
                method: 'POST',
                url: `/module/chat/getChatList`,
                data: { listType: "new", userId: user.id }
            })
                .then(response => {
                    if (response.status === "success") {
                        setNewChats(response.data)
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }

        fetchNewChats()
    }, [])

    const createChat = async (recipientId) => {
        await sendRequest({
            method: 'POST',
            url: `/module/chat/createChat`,
            data: {
                "user_1_id": user.id,
                "user_2_id": recipientId
            }
        })
            .then(response => {
                if (response.status === "success") {
                    console.log("dsadasd");
                    console.log(response);
                    navigate(`/settings/chats/${response.data}`)
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    return (
        <BringzzPageContainer
            className='pb-24'
            header={{
                left: <BringzzIcon
                    size="22"
                    icon="IconChevronLeft"
                    folder="LineIcons"
                    className="cursor-pointer"
                    onClick={backPress}
                />,
                title: <BringzzText tag='h2'>New Chat</BringzzText>,
            }}
            footer={
                <div className='p-4 flex space-x-2'>
                    <div className='w-full'>
                        <BringzzInput
                            className='shadow-xl border-slate-200 border bg-gray-100'
                            placeholder='Search...'
                            onChange={(e, newIsValid, newValue) => setSearchQuery(newValue)}
                            icon={{
                                left: <BringzzIcon
                                    size="20"
                                    icon="IconSearchAlt"
                                    folder="LineIcons"
                                    className="cursor-pointer"
                                    onClick={() => {
                                    }}
                                />,
                            }}
                            onEnterPress={() => {
                            }}
                        />
                    </div>
                </div>
            }
        >
            <div>
                {newChats.length == 0 &&
                    <BringzzText tag='h4' className='text-center mt-4'>
                        No new chats too add..
                    </BringzzText>
                }
                {newChats.map((chat, index) => (
                    <BringzzChatRow key={index} chat={chat.recipient} new={true} onClick={() => createChat(chat.recipient.id)} />
                ))}
            </div>
        </BringzzPageContainer>

    )
}

export default NewChatPage;