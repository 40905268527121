import * as React from "react";
const IconDolphin = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M4.4 58.2c-.8 0-1.5-.3-1.9-.8S2 56.3 2 55.7c0-3.1 1.2-6.2 3.3-8.5l.1-.2c.9-1 1.3-1.5 1.3-2.4 0-.6-.1-1.1-.2-1.7-.1-.7-.2-1.4-.2-2.2-.1-2.7.2-5.4.8-8 1.2-5.1 3.8-9.9 7.4-13.5 1.3-1.3 2.8-2.8 2.4-4.2-.2-.7-.9-1.3-1.6-1.9l-.6-.6c-1.2-1.1-2.2-3-1.3-4.7.6-1.1 1.8-1.5 2.6-1.7 3.6-1 7.6-.6 10.9.9l.6.3c.6.3 1.2.6 1.9.7.9.2 1.9 0 3-.1l.6.2c4.7-.7 9.6-.4 14.2.8 3.7 1 6.4 2.4 8.2 4.4.5.5 1 1.1 1.4 1.7.6.8 1.1 1.5 1.8 2.1.3.2.5.4.8.6l.2.1c.5.4 1 .8 1.5 1.4.8 1.1 1.1 2.5.9 3.9-.3 1.4-1.1 2.7-2.3 3.3-1.4.8-3.1.8-4.6.9h-.2c-4.2 0-8.5.5-12.6 1.5-1 .2-2 .5-2.7 1.1-.6.4-1 1-1.5 1.7-.2.3-.4.5-.6.7-2.4 3-6.1 4.9-10 5-1.5 0-2.6-.4-3.2-1.3-.8-1.2-.5-2.8.7-4.3.1-.2.3-.3.4-.5s.3-.3.4-.5c-1.5.4-2.8 1.2-3.9 2q-2.55 1.8-3.9 4.5c-1 2-1.1 4.1-.3 5.4.5.7 1.2 1.3 2 1.9.4.3.9.7 1.3 1.1 2.2 2 3.3 5 3.1 8-.1 1.4-.6 2.4-1.4 3-1.4 1.1-3.3.6-4.6-.2-.4-.2-.8-.5-1.1-.8-.6-.4-1.2-.9-1.8-1.1-1.2-.4-2.7-.1-4.7 1-.3.2-.6.4-.9.5-1.4.9-2.9 1.7-4.6 2-.2.2-.4.2-.6.2M20.2 7.8c-1.2 0-2.4.2-3.6.5-.5.1-1.2.4-1.4.8-.3.6.2 1.6.9 2.2.2.2.4.3.6.5.9.8 1.8 1.6 2.2 2.9.7 2.5-1.4 4.6-2.9 6.1-3.3 3.3-5.7 7.8-6.8 12.6-.6 2.4-.8 4.9-.7 7.4 0 .7.1 1.3.2 2 .1.6.1 1.2.2 1.8.1 1.8-.9 2.9-1.8 3.8l-.2.2C5 50.5 4 53.1 4 55.7c0 .3 0 .4.1.5 0 0 .2.1.6 0 1.3-.2 2.6-.9 3.9-1.7l.9-.6q3.6-2.1 6.3-1.2c.9.3 1.7.8 2.4 1.4.3.2.7.5 1 .7.8.5 1.8.7 2.4.3.4-.3.6-1.1.6-1.6.2-2.3-.7-4.7-2.4-6.3-.4-.3-.7-.6-1.2-.9-.9-.7-1.8-1.4-2.5-2.4-1.2-1.9-1.1-4.6.2-7.3 1-2 2.6-3.9 4.5-5.2 1.3-.9 3-2 5-2.4.8-.2 1.7-.3 2.2.4s.2 1.5.1 1.8c-.3.7-.8 1.2-1.2 1.7l-.4.4c-.5.6-.9 1.4-.6 1.8.2.3.7.4 1.5.4 3.3-.1 6.4-1.6 8.5-4.2.2-.2.3-.5.5-.7.5-.7 1.1-1.5 1.9-2.1 1-.8 2.3-1.1 3.4-1.4 4.3-1 8.7-1.6 13.1-1.6h.2c1.3 0 2.6 0 3.6-.6.7-.4 1.2-1.1 1.3-2s0-1.7-.5-2.3c-.3-.4-.7-.7-1.1-1l-.2-.1c-.3-.2-.6-.5-.9-.7-.8-.7-1.4-1.5-2-2.3-.4-.6-.8-1.1-1.3-1.5-1.6-1.7-4-3-7.3-3.9-4.3-1.2-8.9-1.4-13.4-.7l-.4.1c-1.2.2-2.5.4-3.8.1-.9-.2-1.6-.5-2.3-.9l-.6-.3c-1.7-1.2-3.8-1.6-5.9-1.6" />
  </svg>
);
export default IconDolphin;
