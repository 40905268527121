import * as React from "react";
const IconFortress = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.6 28H30.9c-1.9 0-3.4-1.5-3.4-3.4v-8.5c0-1.4-.5-2.8-1.5-3.8l-7-7.1c-2-2.1-5.5-2.2-7.6-.1l-7.7 7.3c-1.1 1-1.7 2.5-1.7 3.9V55c0 3 2.4 5.4 5.4 5.4h49.1c3 0 5.4-2.4 5.4-5.4V33.5c.1-3-2.3-5.5-5.3-5.5M27.2 58.4v-10c0-1.9 1.5-3.4 3.4-3.4h4.6c1.9 0 3.4 1.5 3.4 3.4v10.1H27.2zM60 55c0 1.9-1.5 3.4-3.4 3.4h-16v-10c0-3-2.4-5.4-5.4-5.4h-4.6c-3 0-5.4 2.4-5.4 5.4v10.1H7.4C5.5 58.4 4 56.9 4 55V16.3c0-.9.4-1.8 1.1-2.5l7.7-7.3c1.4-1.3 3.5-1.2 4.8.1l6.9 7.1c.6.6 1 1.5 1 2.4v8.5c0 3 2.4 5.4 5.4 5.4h25.7c1.9 0 3.4 1.5 3.4 3.4z" />
    <path d="M19.2 17.7h-9c-.6 0-1 .4-1 1s.4 1 1 1h9c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconFortress;
