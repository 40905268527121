import * as React from "react";
const IconFinance = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m25.5 39.6 15.7-4.5-2.2 4c-.3.5-.1 1.1.4 1.4.2.1.3.1.5.1.4 0 .7-.2.9-.5l3.5-6.2c.1-.2.2-.5.1-.8s-.2-.5-.5-.6l-6.5-3.6c-.5-.3-1.1-.1-1.4.4s-.1 1.1.4 1.4l4.5 2.5L25 37.7c-.5.2-.8.7-.7 1.2s.7.8 1.2.7M33.4 19.2h-6.2c-.6 0-1 .4-1 1s.4 1 1 1H32v1.9c0 .6.4 1 1 1s1-.4 1-1v-1.9c2.2-.3 3.8-2.1 3.8-4.3 0-2.4-2-4.3-4.4-4.3h-2.9c-1.3 0-2.4-1-2.4-2.3S29.2 8 30.5 8h6.2c.6 0 1-.4 1-1s-.4-1-1-1H34V4c0-.6-.4-1-1-1s-1 .4-1 1v1.9h-1.4c-2.4 0-4.4 1.9-4.4 4.3s2 4.3 4.4 4.3h2.9c1.3 0 2.4 1 2.4 2.3s-1.2 2.4-2.5 2.4" />
    <path d="M53.5 11.4h-8.6c-.6 0-1 .4-1 1s.4 1 1 1h8.6c1.5 0 2.8 1.2 2.8 2.8v27.4c0 1.5-1.2 2.8-2.8 2.8h-43c-1.5 0-2.8-1.2-2.8-2.8V16.2c0-1.5 1.2-2.8 2.8-2.8h8.6c.6 0 1-.4 1-1s-.4-1-1-1h-8.6c-2.6 0-4.8 2.1-4.8 4.8v27.4c0 2.6 2.1 4.8 4.8 4.8H31V59h-8.3c-.6 0-1 .4-1 1s.4 1 1 1h18.7c.6 0 1-.4 1-1s-.4-1-1-1H33V48.4h20.5c2.6 0 4.8-2.1 4.8-4.8V16.2c0-2.6-2.2-4.8-4.8-4.8" />
  </svg>
);
export default IconFinance;
