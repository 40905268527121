import * as React from "react";
const IconExpectorate = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      d="M24 40q.643 0 1.27-.05c.166.655.426 1.32.83 1.929q-1.035.12-2.1.121c-9.941 0-18-8.059-18-18S14.059 6 24 6s18 8.059 18 18q0 .88-.083 1.737a4.8 4.8 0 0 0-1.95-.699Q40 24.523 40 24c0-8.837-7.163-16-16-16S8 15.163 8 24s7.163 16 16 16"
    />
    <path
      
      fillRule="evenodd"
      d="M25 35a5 5 0 1 0 0-10 5 5 0 0 0 0 10m0-2a3 3 0 1 0 0-6 3 3 0 0 0 0 6M39.605 31.985c-1.12.108-2.298-.372-3.213-.893-.263-.15-.504-.302-.716-.446-.568-.385-.926-.703-.926-.703s.285-.375.76-.857c.183-.185.394-.386.628-.588.794-.685 1.852-1.377 2.969-1.485 1.454-.14 2.743.86 2.881 2.232s-.93 2.6-2.383 2.74m-.306-2.981c.465-.045.682.268.699.44.017.166-.125.506-.585.55-.381.037-.902-.09-1.49-.36.518-.372.998-.594 1.376-.63M27.196 36.5s-.133.629-.18 1.499q-.012.236-.016.493c-.01 1.09.15 2.406.816 3.354.85 1.211 2.468 1.518 3.614.686 1.147-.833 1.386-2.49.536-3.701-.66-.94-1.815-1.517-2.823-1.863a10 10 0 0 0-.556-.173c-.794-.223-1.39-.295-1.39-.295m1.819 2.561c.046.72.2 1.297.438 1.636.257.367.627.343.802.216.198-.143.344-.549.074-.933-.23-.327-.69-.648-1.314-.919M40.164 34.412c1.752 1.017 2.379 3.21 1.4 4.895-.98 1.687-3.194 2.229-4.946 1.212-2.776-1.612-3.778-6.278-3.778-6.278s4.548-1.441 7.324.17m-1.004 1.73c.86.499 1.063 1.492.674 2.161-.389.67-1.352.985-2.212.486-.818-.475-1.547-1.547-2.101-2.814l-.1-.235.253-.03c1.375-.146 2.668-.044 3.486.431"
      clipRule="evenodd"
    />
    <path
      
      d="M17.241 18.349a1 1 0 0 1 1.314-.181l3 2a1 1 0 0 1 0 1.664l-3 2a1 1 0 0 1-1.267-1.534c.325-.33.757-.659 1.087-.91.112-.085.212-.162.292-.226q.1-.08.173-.144a6 6 0 0 0-.201-.167l-.236-.184c-.342-.265-.8-.618-1.141-.992a1 1 0 0 1-.02-1.326M33.445 18.168a1 1 0 0 1 1.293 1.507c-.342.374-.8.727-1.14.991l-.237.185a6 6 0 0 0-.2.167q.071.064.172.144c.08.064.18.14.291.225.33.252.763.581 1.088.91a1 1 0 0 1-1.267 1.535l-3-2a1 1 0 0 1 0-1.664z"
    />
  </svg>
);
export default IconExpectorate;
