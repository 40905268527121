import * as React from "react";
const IconFriends = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.6 31.9c-2.9-3.4-6.7-5.6-10.8-6.1 4.3-1 7.5-4.8 7.5-9.4 0-5.3-4.3-9.7-9.7-9.7s-9.7 4.3-9.7 9.7c0 4.6 3.2 8.4 7.5 9.4-4.2.6-7.9 2.7-10.8 6.1q-.45.45-.9 1.2c-2.2-1.5-4.6-2.4-7.1-2.8 4.3-1 7.5-4.8 7.5-9.4 0-5.3-4.3-9.7-9.7-9.7s-9.7 4.3-9.7 9.7c0 4.6 3.2 8.4 7.5 9.4-4.1.6-7.9 2.7-10.8 6.1C4 40.3 2.1 45.9 2 51.9q0 .6.6.9c2.1 1.1 9.7 4.5 17.8 4.5 8.8 0 15.9-3.5 17.9-4.5.2-.1.3-.2.4-.4 1.7.3 3.4.4 5 .4 8.8 0 15.9-3.5 17.9-4.5.3-.2.5-.5.5-.9-.2-6-2.1-11.5-5.5-15.5M35.9 16.3c0-4.2 3.5-7.7 7.7-7.7s7.7 3.5 7.7 7.7-3.4 7.7-7.7 7.7-7.7-3.4-7.7-7.7m-23.2 4.5c0-4.2 3.5-7.7 7.7-7.7s7.7 3.5 7.7 7.7-3.5 7.7-7.7 7.7c-4.3 0-7.7-3.5-7.7-7.7m7.7 34.6c-7.2 0-14.1-3-16.4-4 .2-5.4 1.9-10.3 4.9-13.8C11.9 34 16 32 20.4 32s8.5 2 11.5 5.6c3 3.5 4.7 8.4 4.9 13.7-2.5 1.3-8.8 4.1-16.4 4.1m23.2-4.5c-1.6 0-3.2-.2-4.9-.4-.4-5.5-2.2-10.5-5.3-14.2q-1.05-1.2-2.1-2.1c.3-.4.6-.8.8-1.1 3-3.6 7.1-5.6 11.5-5.6s8.5 2 11.5 5.6c3 3.5 4.7 8.4 4.9 13.7-2.4 1.3-8.8 4.1-16.4 4.1" />
  </svg>
);
export default IconFriends;
