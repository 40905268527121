import * as React from "react";
const IconRabbit = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62 32.6c0-3.5-3.2-6.3-5.8-8.1-4-2.6-7.9-3.8-9.9-4.2-1.1-4.6-2.7-8.9-4.6-11.9-2.7-4.3-5-4.7-6.6-4.3-1.1.3-2.4 1.2-3.1 3.6-2.8-1.9-5-2.3-6.8-1.1-.7.5-1.6 1.6-1.7 3.6C23.4 15 28 23 32.8 28.5h-7.7C16.9 28.5 10 34.4 9 42.1c-3.8-.2-7 2.9-7 6.7 0 3.7 3 6.7 6.6 6.7 1.4 0 2.7-.4 3.8-1.2 3 3.6 7.4 5.7 12.1 5.7h13.8c1.6 0 3.1-.9 3.8-2.3H52c3 0 5.5-2.5 5.5-5.5S55 46.7 52 46.7h-1.1c.6-2.3.8-4.7.4-7.1h3.8c3.8 0 6.9-3.1 6.9-7M35.6 6.1c1.3-.3 2.8.9 4.4 3.4 1.6 2.6 3.1 6.3 4.1 10.2-.9-.4-1.6-.9-2.2-1.6l-8.1-8.9c.2-1.3.7-2.8 1.8-3.1M50 37.6c-.3 0-.6.1-.8.4s-.3.5-.2.8c.6 2.8.4 5.8-.6 8.5-.1.3-.1.7.1.9.2.3.5.4.8.4h2.5c1.9 0 3.5 1.6 3.5 3.5s-1.6 3.5-3.5 3.5h-9.3v-.1c0-2.4-2-4.4-4.4-4.4h-2.7c-.5-5.1-4.8-9.2-10.1-9.2H23c-.6 0-1 .4-1 1s.4 1 1 1h2.5c4.2 0 7.6 3.1 8.1 7.2h-4.2c-.6 0-1 .4-1 1s.4 1 1 1h8.9c1.3 0 2.4 1.1 2.4 2.4S39.6 58 38.3 58H24.5c-4.4 0-8.6-2.1-11.1-5.7-.2-.2-.4-.4-.7-.4s-.6.1-.8.3c-.9.8-2 1.3-3.2 1.3-2.5 0-4.6-2.1-4.6-4.7 0-2.9 2.7-5.2 5.6-4.6.3.1.6 0 .8-.2s.4-.4.4-.7c.5-7.2 6.8-12.8 14.3-12.8h10c.4 0 .8-.2.9-.6.2-.4.1-.8-.2-1.1-5.5-5.5-10.4-14.3-10.3-18.5 0-.9.3-1.6.9-2 1.5-1.1 4.1.7 5.6 2l8.4 9.2c1.1 1.2 2.4 2 4 2.5l.9.2h.2c1.6.3 5.6 1.3 9.7 4 2.2 1.5 4.9 3.8 4.9 6.4 0 2.7-2.2 4.9-4.9 4.9H50z" />
  </svg>
);
export default IconRabbit;
