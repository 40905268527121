import * as React from "react";
const IconCertificateAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m53.1 11.8-9.8-8.4c-1-.9-2.4-1.4-3.7-1.4h-25c-3.2 0-5.7 2.6-5.7 5.7v48.6c0 3.2 2.6 5.7 5.7 5.7h12.9c.6 0 1-.4 1-1s-.4-1-1-1H14.6c-2 0-3.7-1.7-3.7-3.7V7.7c0-2 1.7-3.7 3.7-3.7h25q1.35 0 2.4.9l9.8 8.4c.8.7 1.3 1.7 1.3 2.8v42.2c0 .9-.8 1.7-1.7 1.7-.6 0-1 .4-1 1s.4 1 1 1c2 0 3.7-1.7 3.7-3.7V16.1c0-1.7-.7-3.2-2-4.3" />
    <path d="M19.3 14.6h13.8c.6 0 1-.4 1-1s-.4-1-1-1H19.3c-.6 0-1 .4-1 1s.4 1 1 1M19.3 23.4h23.6c.6 0 1-.4 1-1s-.4-1-1-1H19.3c-.6 0-1 .4-1 1s.4 1 1 1M19.3 32.1h23.6c.6 0 1-.4 1-1s-.4-1-1-1H19.3c-.6 0-1 .4-1 1s.4 1 1 1M39.2 38.2c-3.9 0-7.1 3.2-7.1 7.1 0 2.2 1 4.2 2.7 5.6V61c0 .4.2.7.5.9s.7.2 1 0l2.9-1.7 2.9 1.7c.2.1.3.1.5.1s.3 0 .5-.1c.3-.2.5-.5.5-.9V50.8c1.6-1.3 2.7-3.3 2.7-5.6 0-3.9-3.2-7-7.1-7m2.5 21.1-1.9-1.1c-.3-.2-.7-.2-1 0l-1.9 1.1V52c.8.3 1.6.4 2.4.4.9 0 1.7-.2 2.4-.4zm-2.5-8.9c-2.8 0-5.1-2.3-5.1-5.1s2.3-5.1 5.1-5.1 5.1 2.3 5.1 5.1-2.3 5.1-5.1 5.1" />
  </svg>
);
export default IconCertificateAlt;
