import * as React from "react";
const IconCalenderAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 11.6h-7V8.1c0-.6-.4-1-1-1s-1 .4-1 1v3.4H16V8.1c0-.6-.4-1-1-1s-1 .4-1 1v3.4H7c-2.8 0-5 2.2-5 5v35.3c0 2.8 2.2 5 5 5h50c2.8 0 5-2.2 5-5V16.6c0-2.8-2.2-5-5-5m-50 2h7v3.3c0 .6.4 1 1 1s1-.4 1-1v-3.3h32v3.3c0 .6.4 1 1 1s1-.4 1-1v-3.3h7c1.7 0 3 1.3 3 3v8H4v-8c0-1.7 1.3-3 3-3m50 41.3H7c-1.7 0-3-1.3-3-3V26.6h56v25.3c0 1.6-1.3 3-3 3" />
    <path d="M32.6 35.6c-.8-.4-1.7-.4-2.4.1l-2.6 1.7c-.5.3-.6.9-.3 1.4s.9.6 1.4.3l2.6-1.7c.1-.1.3-.1.3 0 .1 0 .2.1.2.3v8.5c0 .6.4 1 1 1s1-.4 1-1v-8.5c0-.9-.4-1.7-1.2-2.1" />
  </svg>
);
export default IconCalenderAlt;
