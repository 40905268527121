import * as React from "react";
const IconDerrickAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.1 6.6H38c-.4-1.8-2-3.2-3.9-3.2h-4.2c-1.9 0-3.6 1.4-3.9 3.2H6.9C4.2 6.6 2 8.8 2 11.5v44.3c0 2.7 2.2 4.9 4.9 4.9h9.6c.6 0 1-.4 1-1s-.4-1-1-1h-4.2l2.9-41h10.7v.6c0 2.2 1.8 4 4 4H31V33h-6.5c-2.2 0-4 1.8-4 4v7.6c0 2.2 1.8 4 4 4h14.7c2.2 0 4-1.8 4-4V37c0-2.2-1.8-4-4-4H33V22.2h1.1c2.2 0 4-1.8 4-4v-.6H49l2.9 41h-3.8c-.6 0-1 .4-1 1s.4 1 1 1h9.1c2.7 0 4.9-2.2 4.9-4.9V11.5c-.1-2.7-2.3-4.9-5-4.9M41.2 37v7.6c0 1.1-.9 2-2 2H24.5c-1.1 0-2-.9-2-2V37c0-1.1.9-2 2-2h14.7c1.1 0 2 .9 2 2M10.3 58.6H6.9c-1.6 0-2.9-1.3-2.9-2.9v-38h9.2zM4 15.7v-4.2c0-1.6 1.3-2.9 2.9-2.9h19v7.1zm32.1 2.5c0 1.1-.9 2-2 2h-4.2c-1.1 0-2-.9-2-2V7.4c0-1.1.9-2 2-2h4.2c1.1 0 2 .9 2 2zm2-9.6h19c1.6 0 2.9 1.3 2.9 2.9v4.2H38.1zm19 50h-3.3l-2.9-41h9v38.1c.1 1.7-1.2 2.9-2.8 2.9" />
  </svg>
);
export default IconDerrickAlt;
