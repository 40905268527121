import * as React from "react";
const IconBookCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M39.9 52.1H24.1c-.6 0-1 .4-1 1s.4 1 1 1h15.8c.6 0 1-.4 1-1s-.4-1-1-1M23.8 33h16.5c1.7 0 3-1.3 3-3v-6.2c0-1.7-1.3-3-3-3H23.8c-1.7 0-3 1.3-3 3V30c0 1.7 1.3 3 3 3m-1-9.2c0-.6.4-1 1-1h16.5c.6 0 1 .4 1 1V30c0 .6-.4 1-1 1H23.8c-.6 0-1-.4-1-1z" />
    <path d="M16.4 4h36.7c.6 0 1-.4 1-1s-.4-1-1-1H16.4c-3.5 0-6.3 2.7-6.5 6.2V57c0 2.8 2.4 5 5.4 5h34.1c2.2 0 3.9-1.8 3.9-3.9V16.5c0-2.2-1.8-3.9-3.9-3.9H16.2c-2.5 0-4.2-1.6-4.2-4C11.9 6 13.9 4 16.4 4m-.3 10.5q.15 0 0 0h33.3c1.1 0 1.9.9 1.9 1.9V58c0 1.1-.9 1.9-1.9 1.9H15.3c-1.9 0-3.4-1.4-3.4-3V13.1c1.1.9 2.5 1.4 4.2 1.4" />
  </svg>
);
export default IconBookCopy;
