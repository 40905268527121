import * as React from "react";
const IconUsersCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M21.5 35.6c6.4 0 11.5-5.2 11.5-11.5s-5.2-11.5-11.5-11.5S9.9 17.7 9.9 24.1s5.2 11.5 11.6 11.5m0-21.1c5.3 0 9.5 4.3 9.5 9.5 0 5.3-4.3 9.5-9.5 9.5S12 29.2 12 24c-.1-5.2 4.2-9.5 9.5-9.5M21.5 41.5c-7.1 0-13.9 2.9-19.2 8.2-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3c4.9-4.9 11.2-7.6 17.7-7.6s12.8 2.7 17.7 7.6c.4.4 1 .4 1.4 0s.4-1 0-1.4c-5.2-5.2-12-8.2-19-8.2M47.8 35.6c3.5 0 6.3-2.8 6.3-6.3S51.3 23 47.8 23s-6.3 2.8-6.3 6.3 2.9 6.3 6.3 6.3m0-10.5c2.4 0 4.3 1.9 4.3 4.3s-1.9 4.3-4.3 4.3-4.3-1.9-4.3-4.3 2-4.3 4.3-4.3M61.7 47.1c-5.1-4.8-12.3-6.7-19.4-4.8-.5.1-.9.7-.7 1.2.1.5.7.8 1.2.7 6.4-1.7 12.9 0 17.5 4.4.2.2.4.3.7.3s.5-.1.7-.3c.4-.5.4-1.1 0-1.5" />
  </svg>
);
export default IconUsersCopy;
