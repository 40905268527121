import * as React from "react";
const IconSearchLocation = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 65 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32.5 3C18.6 3 7.2 14.3 7.2 28.3c0 17.2 23.7 32 24.7 32.6.2.1.3.1.5.1s.4-.1.5-.2c1-.6 24.7-15.7 24.7-32.6C57.8 14.3 46.4 3 32.5 3m0 55.8C29 56.5 9.2 43 9.2 28.3 9.2 15.4 19.7 5 32.5 5s23.3 10.4 23.3 23.3c0 14.5-19.8 28.2-23.3 30.5" />
    <path d="M37.6 34.1c1.6-1.8 2.5-4.1 2.5-6.5 0-2.6-1-5.1-2.9-6.9-3.9-3.8-10.1-3.8-14 0-1.9 1.8-2.9 4.3-2.9 6.9s1 5.1 2.9 6.9c1.9 1.9 4.5 2.8 7 2.8 2.1 0 4.1-.6 5.9-1.9l6.9 6.8c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4zm-13-1.1c-1.5-1.5-2.3-3.4-2.3-5.5s.8-4 2.3-5.5 3.6-2.3 5.6-2.3 4.1.8 5.6 2.3 2.3 3.4 2.3 5.5-.8 4-2.3 5.5c-3.1 3.1-8.1 3.1-11.2 0" />
  </svg>
);
export default IconSearchLocation;
