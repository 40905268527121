import * as React from "react";
const IconSymbolsFemale = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 2H6C3.8 2 2 3.8 2 6v52c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V6c0-2.2-1.8-4-4-4m2 56c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2h52c1.1 0 2 .9 2 2z" />
    <path d="M43.6 26.3c0-6.4-5.2-11.6-11.6-11.6s-11.6 5.2-11.6 11.6c0 6.1 4.7 11 10.6 11.6v5.2h-6.7c-.6 0-1 .4-1 1s.4 1 1 1H31v5.8c0 .6.4 1 1 1s1-.4 1-1v-5.8h6.7c.6 0 1-.4 1-1s-.4-1-1-1H33v-5.2c5.9-.5 10.6-5.5 10.6-11.6m-21.2 0c0-5.3 4.3-9.6 9.6-9.6s9.6 4.3 9.6 9.6-4.3 9.6-9.6 9.6-9.6-4.3-9.6-9.6" />
  </svg>
);
export default IconSymbolsFemale;
