import React, { useState } from 'react';
import { BringzzButton, BringzzText } from "@bringzz/components";
import { useNavigation } from 'context/NavigationContext';
import { twMerge } from 'tailwind-merge';
import {
	BringzzInput,
	BringzzDate,
	BringzzPageContainer,
	BringzzIcon,
	BringzzCountryDropdown,
	BringzzImageUpload,
	BringzzBottomDrawer,
	BringzzCheckbox,
} from "@bringzz/components";
import { useLocation } from 'react-router-dom';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const AddressPage = () => {
	const { backPress } = useNavigation();

	const { navigate } = useNavigation();

	const [selectedAddress, setSelectedAdress] = useState(0);
	return (
		<BringzzPageContainer
			header={{
				left: (
					<BringzzIcon
						size='22'
						icon='IconChevronLeft'
						folder='LineIcons'
						className='cursor-pointer'
						onClick={backPress}
					/>
				)
			}}
			footer={
				<div className='px-4 mb-4'>
					<BringzzButton
						size='lg'
						className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
						onClick={() => {
							navigate('/home/');
						}}
					>
						Continue
					</BringzzButton>
				</div>
			}
			authenticated={false}
		>
			<div className='relative flex justify-center bg-magic-lilac/20'>
				<div className='relative bg-white w-full bg-cover bg-center flex flex-col'>
					{/* Content */}
					<div className='flex-grow flex flex-col py-8 px-4'>
						<div className='z-10 flex flex-col '>
							<div className='flex flex-col text-center space-y-6 mx-auto md:max-w-lg lg:max-w-xl xl:max-w-2xl'>
								<BringzzText tag='h1' className='text-midnight-blue'>
									Your Address
								</BringzzText>
								<BringzzText
									tag='h4'
									className='text-midnight-blue/25 font-normal'
								>
									Terdum quis nibh sit sed amet tortor faucibus lorem. In morbi
									tellus laoreet risus. Tempus orci et.
								</BringzzText>
							</div>
							<div className='space-y-6 mt-6'>
								<div className='space-y-4'>
									<BringzzInput
										label='Straßenname und Hausnummer'
										placeholder='Account Holder Name'
										tooltip='lorem'
									/>
									<div className='flex gap-x-4'>
										<div>
											<BringzzInput
												label='Eingang'
												placeholder='Eingang'
												tooltip='lorem'
											/>
										</div>
										<div>
											<BringzzInput
												label='Etage'
												placeholder='Etage'
												tooltip='lorem'
											/>
										</div>
										<div>
											<BringzzInput
												label='Tür'
												placeholder='Tür'
												tooltip='lorem'
											/>
										</div>
									</div>
									<div className='flex gap-x-4'>
										<div className='w-1/4 space-y-1'>
											<BringzzText tag='h4' className='font-bold'>
												Land
											</BringzzText>
											<BringzzCountryDropdown
												mode={'modal'}
												onCountryChange={result => {
													console.log(result);
												}}
											/>
										</div>
										<div className='w-1/4'>
											<BringzzInput
												label='PLZ'
												placeholder='1100'
												tooltip='lorem'
											/>
										</div>
										<div className='w-1/2'>
											<BringzzInput
												label='Stadt*'
												placeholder='Wien'
												tooltip='lorem'
											/>
										</div>
									</div>
									<BringzzInput
										label='Notiz'
										placeholder='Notiz'
										tooltip='lorem'
									/>

									<div className='mt-4'>
										<BringzzText tag='h4' className='font-normal'>
											Address label (optional)
										</BringzzText>
										<div className='flex gap-x-8 mt-4'>
											<div
												className='flex flex-col items-center gap-y-2 cursor-pointer'
												onClick={() => {
													setSelectedAdress(0);
												}}
											>
												<BringzzIcon
													size='22'
													icon='IconHome'
													folder='LineIcons'
													className={twMerge(
														'cursor-pointer rounded-md p-2',
														selectedAddress === 0
															? 'bg-magic-lilac'
															: 'bg-midnight-blue/10'
													)}
												/>
												<BringzzText tag='h4' className='font-normal'>
													Home
												</BringzzText>
											</div>
											<div
												className='flex flex-col items-center gap-y-2 cursor-pointer'
												onClick={() => {
													setSelectedAdress(1);
												}}
											>
												<BringzzIcon
													size='22'
													icon='IconBriefcase'
													folder='LineIcons'
													className={twMerge(
														'cursor-pointer rounded-md p-2',
														selectedAddress === 1
															? 'bg-magic-lilac'
															: 'bg-midnight-blue/10'
													)}
												/>
												<BringzzText tag='h4' className='font-normal'>
													Work
												</BringzzText>
											</div>

											<div
												className='flex flex-col items-center gap-y-2 cursor-pointer'
												onClick={() => {
													setSelectedAdress(2);
												}}
											>
												<BringzzIcon
													size='22'
													icon='IconHeart'
													folder='LineIcons'
													className={twMerge(
														'cursor-pointer rounded-md p-2',
														selectedAddress === 2
															? 'bg-magic-lilac'
															: 'bg-midnight-blue/10'
													)}
												/>
												<BringzzText tag='h4' className='font-normal'>
													Partner
												</BringzzText>
											</div>
											<div
												className='flex flex-col items-center gap-y-2 cursor-pointer'
												onClick={() => {
													setSelectedAdress(3);
												}}
											>
												<BringzzIcon
													size='22'
													icon='IconCirclePlus'
													folder='LineIcons'
													className={twMerge(
														'cursor-pointer rounded-md p-2',
														selectedAddress === 3
															? 'bg-magic-lilac'
															: 'bg-midnight-blue/10'
													)}
												/>
												<BringzzText tag='h4' className=''>
													Other
												</BringzzText>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</BringzzPageContainer>
	);
};

export default AddressPage;
