import * as React from "react";
const IconPlaylistAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61 51.2-16.2-8.9c-.3-.2-.7-.2-1 0s-.5.5-.5.9V61c0 .4.2.7.5.9.2.1.3.1.5.1s.3 0 .5-.1l16.2-9c.3-.2.5-.5.5-.9s-.2-.6-.5-.8m-15.7 8.1V44.8l13.2 7.3zM33.3 44.9H3.1c-.6 0-1 .4-1 1s.4 1 1 1h30.2c.6 0 1-.4 1-1s-.4-1-1-1M33.3 59.2H3.1c-.6 0-1 .4-1 1s.4 1 1 1h30.2c.6 0 1-.4 1-1s-.4-1-1-1M60.9 30.6H3.3c-.6 0-1 .4-1 1s.4 1 1 1h57.6c.6 0 1-.4 1-1s-.5-1-1-1M60.9 16.3H3.3c-.6 0-1 .4-1 1s.4 1 1 1h57.6c.6 0 1-.4 1-1s-.5-1-1-1M3.3 4h57.6c.6 0 1-.4 1-1s-.4-1-1-1H3.3c-.6 0-1 .4-1 1s.4 1 1 1" />
  </svg>
);
export default IconPlaylistAlt;
