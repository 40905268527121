import * as React from "react";
const IconLayersAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 65 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m4 34.4 26.3 13.9c.7.4 1.5.6 2.3.6s1.5-.2 2.3-.6L61 34.6c.5-.3.7-.9.4-1.4s-.9-.7-1.4-.4L34 46.6c-.8.4-1.8.4-2.6 0L5 32.7c-.5-.3-1.1-.1-1.4.4-.2.5-.1 1.1.4 1.3" />
    <path d="M60 45 34 58.7c-.8.4-1.8.4-2.6 0L5 44.8c-.5-.3-1.1-.1-1.4.4s-.1 1.1.4 1.4l26.3 13.9c.7.4 1.5.6 2.3.6s1.5-.2 2.3-.6L61 46.7c.5-.3.7-.9.4-1.4-.3-.4-.9-.6-1.4-.3M6.8 23.7l23.7 12.5c.7.3 1.4.5 2.1.5s1.4-.2 2.1-.5l23.5-12.4c1.5-.8 2.4-2.3 2.4-3.9 0-1.7-.9-3.2-2.4-3.9L34.5 3.5c-1.3-.7-2.9-.7-4.2 0L6.8 15.9c-1.5.8-2.4 2.3-2.4 3.9 0 1.7 1 3.2 2.4 3.9m1-6.1L31.2 5.3q.6-.3 1.2-.3c.4 0 .8.1 1.1.3l23.7 12.5c.8.4 1.3 1.2 1.3 2.2 0 .9-.5 1.7-1.3 2.2L33.8 34.5c-.7.4-1.6.4-2.3 0L7.8 22c-.8-.4-1.3-1.2-1.3-2.2-.1-.9.4-1.7 1.3-2.2" />
  </svg>
);
export default IconLayersAlt2;
