import * as React from "react";
const IconSonography = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M39 8H9a1 1 0 0 0-1 1v30a1 1 0 0 0 1 1h30a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1M9 6a3 3 0 0 0-3 3v30a3 3 0 0 0 3 3h30a3 3 0 0 0 3-3V9a3 3 0 0 0-3-3z"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M10.302 28.441c-.403 1.051.139 2.233 1.217 2.557A43.3 43.3 0 0 0 24 32.832c4.33 0 8.518-.642 12.481-1.834 1.078-.324 1.62-1.506 1.217-2.557L32.21 14.154c-.371-.967-1.419-1.483-2.432-1.265-1.864.4-3.798.611-5.779.611s-3.914-.21-5.779-.611c-1.013-.218-2.06.298-2.432 1.265zM33 21.793c0 2.188-1.823 3.962-4.071 3.962q-.257 0-.506-.03c-.153.205-.316.486-.505.809-.81 1.389-2.078 3.56-4.918 3.966-2.217.32-3.75-1.547-4.767-3.536-.128-.25-.253-.491-.37-.714l-.534.24c-.467.209-.924.414-1.142.499-.928.36-1.981-.079-2.352-.982s.08-1.927 1.009-2.288c.132-.051.416-.18.789-.347.25-.113.542-.244.855-.382a28 28 0 0 1 1.024-.434 6 6 0 0 1 .467-.166 3 3 0 0 1 .328-.08c.068-.011.181-.027.32-.03.107-.002.4 0 .736.133a1.85 1.85 0 0 1 .707.496c.07.08.123.152.151.192.06.085.115.174.157.244.089.147.193.335.303.536.132.244.525 1.028.714 1.409a.162.162 0 0 0 .3-.12l-.578-1.903c-.154-.514-.309-1.03-.436-1.442a35 35 0 0 0-.219-.683q-.007-.021-.001-.007c-.376-.902.07-1.928.997-2.293.926-.366 1.981.069 2.357.97l.875 2.972c.02.071.09.116.163.106a.14.14 0 0 0 .115-.174 4 4 0 0 1-.11-.923c0-1.626.714-2.738 2.22-3.414 1.174-.526 2.567-.685 3.716-.108C32.104 18.93 33 20.26 33 21.793M36 12v2a1 1 0 1 0 2 0v-2.5a1.5 1.5 0 0 0-1.5-1.5H34a1 1 0 1 0 0 2zM12 12h2a1 1 0 1 0 0-2h-2.5a1.5 1.5 0 0 0-1.5 1.5V14a1 1 0 1 0 2 0zM36 36v-2a1 1 0 1 1 2 0v2.5a1.5 1.5 0 0 1-1.5 1.5H34a1 1 0 1 1 0-2zM12 36h2a1 1 0 1 1 0 2h-2.5a1.5 1.5 0 0 1-1.5-1.5V34a1 1 0 1 1 2 0z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconSonography;
