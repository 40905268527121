import * as React from "react";
const IconMessagePeopleAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.1 2.4H34.6c-1.6 0-2.9 1.3-2.9 2.8v18c0 1 .5 1.9 1.4 2.5.4.3.9.4 1.4.4s1-.1 1.4-.4l5.8-3.3c.1-.1.3-.1.4-.1H59c1.6 0 2.9-1.3 2.9-2.8V5.2c.1-1.5-1.2-2.8-2.8-2.8m.9 17c0 .5-.4.8-.9.8H42.2c-.5 0-1 .1-1.4.4L35 23.9c-.4.2-.7.1-.9 0-.1-.1-.4-.3-.4-.7v-18c0-.5.4-.8.9-.8h24.5c.5 0 .9.4.9.8zM14.3 40.5c3.9 0 7-3.1 7-6.8s-3.2-6.8-7-6.8-7 3.1-7 6.8 3.1 6.8 7 6.8m0-11.7c2.8 0 5 2.2 5 4.8 0 2.7-2.3 4.8-5 4.8s-5-2.2-5-4.8 2.2-4.8 5-4.8M17.5 45.8h-6.4c-5 0-9.1 4.1-9.1 9.1v5c0 1 .8 1.7 1.7 1.7h21.1c1 0 1.7-.8 1.7-1.7v-5c.1-5.1-4-9.1-9-9.1m7.1 13.8H4v-4.7c0-3.9 3.2-7.1 7.1-7.1h6.4c3.9 0 7.1 3.2 7.1 7.1z" />
  </svg>
);
export default IconMessagePeopleAlt;
