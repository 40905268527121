import * as React from "react";
const IconLayoutCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.8 2.1H7.1c-2.8 0-5 2.2-5 5v49.6c0 2.8 2.2 5 5 5h49.6c2.8 0 5-2.2 5-5V7.1c.1-2.7-2.2-5-4.9-5m-49.7 2h49.6c1.7 0 3 1.3 3 3v10.6H4.1V7.1c0-1.6 1.4-3 3-3m12.7 15.6h40v19.1h-40zm-15.7 37v-37h13.7v40H7.1c-1.6 0-3-1.3-3-3m52.7 3h-37V40.8h40v15.9c0 1.7-1.4 3-3 3" />
  </svg>
);
export default IconLayoutCopy;
