import * as React from "react";
const IconCodeAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56 7.4H8c-3.4 0-6.3 2.8-6.3 6.3v22.6c0 3.4 2.8 6.3 6.3 6.3h21.8v9.5h-6.6c-1.2 0-2.3 1-2.3 2.3s1 2.3 2.3 2.3H41c1.2 0 2.3-1 2.3-2.3s-1-2.3-2.3-2.3h-6.6v-9.5H56c3.4 0 6.3-2.8 6.3-6.3V13.7c0-3.5-2.9-6.3-6.3-6.3m1.8 28.9c0 1-.8 1.8-1.8 1.8H8c-1 0-1.8-.8-1.8-1.8V13.7c0-1 .8-1.8 1.8-1.8h48c1 0 1.8.8 1.8 1.8z" />
    <path d="M45.1 17.6c-.9-.9-2.3-.9-3.2 0s-.9 2.3 0 3.2l3.7 3.7-3.6 3.7c-.9.9-.9 2.3 0 3.2.4.4 1 .7 1.6.7s1.2-.2 1.6-.7l5-5c1-1 1-2.6 0-3.6zM18.3 24.9l3.6-3.6c.9-.9.9-2.3 0-3.2s-2.3-.9-3.2 0l-5 5c-1 1-1 2.6 0 3.6l5.1 5.1c.4.4 1 .7 1.6.7s1.2-.2 1.6-.7c.9-.9.9-2.3 0-3.2zM35.1 17.1c-1.2-.5-2.5.1-2.9 1.3L27.6 30c-.5 1.2.1 2.5 1.3 2.9.3.1.5.2.8.2.9 0 1.7-.5 2.1-1.4L36.4 20c.4-1.1-.1-2.5-1.3-2.9" />
  </svg>
);
export default IconCodeAlt;
