import * as React from "react";
const IconStrategy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.7 2H12.3c-2.8 0-5 2.2-5 5v50c0 2.8 2.2 5 5 5h39.3c2.8 0 5-2.2 5-5V7c.1-2.8-2.2-5-4.9-5m3 55c0 1.7-1.3 3-3 3H12.3c-1.7 0-3-1.3-3-3V7c0-1.7 1.3-3 3-3h39.3c1.7 0 3 1.3 3 3v50z" />
    <path d="M41.9 10.2c-.2-.2-.5-.3-.7-.3-.1 0-.2 0-.4.1-.1 0-.2.1-.2.2 0 0-.1 0-.1.1l-6.3 6.3c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l4.7-4.7v11c0 5.1-4.2 9.3-9.3 9.3-6.3 0-11.3 5.1-11.3 11.3v9.3c0 .6.4 1 1 1s1-.4 1-1v-9.3c0-5.1 4.2-9.3 9.3-9.3 6.3 0 11.3-5.1 11.3-11.3V13.5l4.4 4.4c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4zM24.2 21.4c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4L23 17.5l2.6-2.5c.4-.4.4-1 0-1.4s-1-.4-1.4 0L21.6 16 19 13.5c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l2.6 2.5-2.5 2.6c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3l2.6-2.5zM45.1 44.6c-.4-.4-1-.4-1.4 0l-2.6 2.5-2.6-2.5c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l2.6 2.5-2.6 2.5c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l2.6-2.5 2.6 2.5c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-2.6-2.5 2.6-2.5c.4-.4.4-1 0-1.4" />
  </svg>
);
export default IconStrategy;
