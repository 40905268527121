import * as React from "react";
const IconDeliveryTruck2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.1 21.2H43.9v-4.5c0-.6-.4-1-1-1H10.4c-.6 0-1 .4-1 1s.4 1 1 1h31.5v25H30.8c-.2-2.6-2.4-4.7-5.1-4.7s-4.9 2.1-5.1 4.7H6.2c-.6 0-1 .4-1 1s.4 1 1 1h14.6c.7 2.1 2.6 3.6 4.9 3.6s4.2-1.5 4.9-3.6h16.5c.7 2.1 2.6 3.6 4.9 3.6s4.2-1.5 4.9-3.6H61c.6 0 1-.4 1-1V29.1c0-4.4-3.5-7.9-7.9-7.9M25.7 46.3c-1.7 0-3.1-1.4-3.1-3.1s1.4-3.1 3.1-3.1 3.1 1.4 3.1 3.1-1.3 3.1-3.1 3.1m26.3 0c-1.7 0-3.1-1.4-3.1-3.1s1.4-3.1 3.1-3.1 3.1 1.4 3.1 3.1-1.4 3.1-3.1 3.1m8-3.6h-2.9c-.2-2.6-2.4-4.7-5.1-4.7s-4.9 2.1-5.1 4.7H44V23.2h10.2c3.3 0 5.9 2.6 5.9 5.9v13.6z" />
    <path d="M21.5 30.6c0-.6-.4-1-1-1H3c-.6 0-1 .4-1 1s.4 1 1 1h17.5c.6 0 1-.5 1-1" />
  </svg>
);
export default IconDeliveryTruck2;
