import * as React from "react";
const IconChevronDown = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 45.6c-.6 0-1.1-.2-1.6-.6l-23-22.6c-.9-.9-.9-2.3 0-3.2s2.3-.9 3.2 0L32 40.1 53.4 19c.9-.9 2.3-.9 3.2 0s.9 2.3 0 3.2l-23 22.6c-.5.5-1 .8-1.6.8" />
  </svg>
);
export default IconChevronDown;
