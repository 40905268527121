import * as React from "react";
const IconCustomer = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.21 47v-.06A7.49 7.49 0 0 0 51 41.7c-3.41 1-8.09 2.4-11.62 3.42a4.68 4.68 0 0 0-.09-3.12c-.9-2.25-3.41-3.26-5.51-3.26H24a3.94 3.94 0 0 1-2.58-1.2 5.65 5.65 0 0 0-3.84-1.5H9.67a6.34 6.34 0 0 0-6.21 6.46v12.59a6.46 6.46 0 0 0 6.32 6.58h9.51a6.13 6.13 0 0 0 3.42-1.06h.1A18.75 18.75 0 0 0 31.87 63a18.5 18.5 0 0 0 5.56-.84l17.66-5.39h.13A7.74 7.74 0 0 0 60.21 47M9.78 57.67a2.46 2.46 0 0 1-2.32-2.58V42.54a2.34 2.34 0 0 1 2.21-2.46h7.9a1.63 1.63 0 0 1 1.14.45 11 11 0 0 0 .94.75v16.35a2 2 0 0 1-.36 0ZM53.86 53l-17.62 5.34a14.3 14.3 0 0 1-4.37.66 14.8 14.8 0 0 1-7.16-1.85l-1.06-.57V42.76h10.129999999999999a2.08 2.08 0 0 1 1.8.75c.14.36-.12 1.55-2.15 3.48l-1.15 1.11.79 1.37c.81 1.4 1.6 1.17 4.94.22 1.58-.45 3.69-1.06 5.79-1.68 4.18-1.22 8.36-2.47 8.36-2.47a3.53 3.53 0 0 1 4.21 2.53A3.76 3.76 0 0 1 53.86 53M50.55 15.61a7.31 7.31 0 1 0-7.31-7.3 7.32 7.32 0 0 0 7.31 7.3m0-10.61a3.31 3.31 0 1 1-3.31 3.31A3.31 3.31 0 0 1 50.55 5M48.18 37.38h3.3a7.26 7.26 0 0 0 7.25-7.25v-5.69a7.26 7.26 0 0 0-7.25-7.25h-3a7.26 7.26 0 0 0-7.25 7.25v5.66a7.22 7.22 0 0 0 6.95 7.28m-2.94-12.94a3.25 3.25 0 0 1 3.25-3.25h3a3.26 3.26 0 0 1 3.25 3.25v5.69a3.26 3.26 0 0 1-3.25 3.25h-3.3a3.2 3.2 0 0 1-2.94-3.32Z" />
  </svg>
);
export default IconCustomer;
