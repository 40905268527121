import * as React from "react";
const IconWalrus = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.2 10.2H53c-5 0-9.7 2.4-12.6 6.4l-4.2 5.8c-1.9 2.6-4.8 4.1-8 4.1h-1.7C16 26.5 7.4 35 7.4 45.6v.3l-4.2 2.8c-1.1.7-1.5 1.9-1.1 3.2s1.4 2 2.7 2h10.9c2.1 0 4.1-1 5.3-2.7h17.4c2 1.8 4.6 2.7 7.2 2.7h10c1.4 0 2.5-.9 2.7-2.2s-.3-2.7-1.6-3.2l-7.9-3.6c3.8-5.3 5.9-11.8 5.9-18.4v-1.3c1.1-.4 3.1-1.3 4.7-2.1-.1 1.7-.1 3.4-.1 5.1 0 .6.4 1 1 1s1-.4 1-1c0-2.2.1-4.5.2-6.6v-.1c.1-1.3.1-2.5.2-3.6.2-1.9.3-3.8.3-4.9 0-1.6-1.3-2.8-2.8-2.8m.6 7.7c-.1.9-.1 2-.2 3-2.7 1.2-5.6 2.5-6.1 2.6s-.8.5-.8 1v2c0 6.6-2.2 13-6.3 18.2-.2.2-.3.6-.2.9s.3.5.6.7l9 4.1c.5.2.5.8.5.9 0 .2-.2.6-.8.6h-10c-2.3 0-4.5-.9-6.1-2.4-.2-.2-.4-.3-.7-.3H20.4c-.4 0-.7.2-.9.5-.8 1.4-2.3 2.2-3.8 2.2H4.8c-.6 0-.7-.4-.8-.6s-.1-.6.3-.9l4.8-3.2c.4-.3.6-.8.4-1.2 0-.1-.1-.2-.1-.3 0-9.4 7.7-17.1 17.1-17.1h1.7c3.8 0 7.4-1.8 9.6-4.9l4.2-5.8c2.5-3.5 6.6-5.6 10.9-5.6h6.2c.4 0 .8.4.8.8.1 1 0 2.8-.1 4.8" />
  </svg>
);
export default IconWalrus;
