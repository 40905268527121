import * as React from "react";
const IconClipboardAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M48.5 11.9h-3.9v-.1c0-2-1.6-3.6-3.6-3.6h-2.3C38.4 4.7 35.5 2 32 2s-6.4 2.7-6.7 6.1H23c-2 0-3.6 1.6-3.6 3.6v.1h-3.9c-2.7 0-4.9 2.2-4.9 4.9V57c0 2.7 2.2 4.9 4.9 4.9h33.1c2.7 0 4.9-2.2 4.9-4.9V16.8c0-2.7-2.2-4.9-5-4.9m-27.1-.1c0-.9.7-1.6 1.6-1.6h3.3c.6 0 1-.4 1-1v-.5C27.3 6.1 29.4 4 32 4s4.7 2.1 4.7 4.7v.5c0 .6.4 1 1 1H41c.9 0 1.6.7 1.6 1.6v2.4c0 .9-.7 1.6-1.6 1.6H23c-.9 0-1.6-.7-1.6-1.6zm30.1 45.3c0 1.6-1.3 2.9-2.9 2.9H15.5c-1.6 0-2.9-1.3-2.9-2.9V16.8c0-1.6 1.3-2.9 2.9-2.9h3.9v.4c0 2 1.6 3.6 3.6 3.6h18c2 0 3.6-1.6 3.6-3.6v-.4h3.9c1.6 0 2.9 1.3 2.9 2.9v40.3z" />
    <path d="M46 40.9h-5.3l-5.4-10.8c-.2-.5-.7-.8-1.3-.8-.5 0-1 .3-1.2.8l-5.1 11.3-2.8-5.7c-.2-.3-.5-.6-.9-.6h-6c-.6 0-1 .4-1 1s.4 1 1 1h5.4l3.1 6.4c.2.5.7.7 1.2.7s1-.3 1.2-.8L34.1 32l5.1 10.1c.2.3.5.6.9.6H46c.6 0 1-.4 1-1s-.5-.8-1-.8" />
  </svg>
);
export default IconClipboardAlt;
