import * as React from "react";
const IconPaintRoller = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.9 9.4h-.3V8c0-3.4-2.8-6.2-6.2-6.2H20.7c-3.4 0-6.2 2.8-6.2 6.2v1.4H11c-2.3 0-4.2 1.9-4.2 4.2v14c0 2.3 1.9 4.2 4.2 4.2h26.4V35c-2.9.5-5.1 3.1-5.1 6.1V56c0 3.4 2.8 6.2 6.2 6.2h2.2c3.4 0 6.2-2.8 6.2-6.2V41.1c0-3-2.2-5.6-5.1-6.1v-3.6c0-2.3-1.9-4.2-4.2-4.2H11.3V13.9h3.1v1.4c0 3.4 2.8 6.2 6.2 6.2h27.6c3.4 0 6.2-2.8 6.2-6.2v-1.4h.3c1.2 0 2.2-1 2.2-2.2s-.7-2.3-2-2.3M42.5 41.1V56c0 1-.8 1.8-1.8 1.8h-2.2c-1 0-1.8-.8-1.8-1.8V41.1c0-1 .8-1.8 1.8-1.8h2.2c1 0 1.8.8 1.8 1.8m7.6-25.8c0 1-.8 1.8-1.8 1.8H20.7c-1 0-1.8-.8-1.8-1.8V8c0-1 .8-1.8 1.8-1.8h27.6c1 0 1.8.8 1.8 1.8z" />
  </svg>
);
export default IconPaintRoller;
