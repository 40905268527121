import * as React from "react";
const IconUmbrella = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M23.99 4a1 1 0 0 1 1 1v1.005c8.438.467 15.33 6.732 16.76 14.877a18 18 0 0 1 .245 2.141c.016.293-.34.343-.557.147l-.125-.11a7.2 7.2 0 0 0-1.404-.946 7.2 7.2 0 0 0-3.3-.798c-2.172 0-4.118.958-5.442 2.474a.66.66 0 0 1-.497.233.66.66 0 0 1-.454-.187 9 9 0 0 0-5.24-2.463V39.5a2.5 2.5 0 0 0 5 0V38a1 1 0 1 1 2 0v1.5a4.5 4.5 0 1 1-9 0V21.37a9 9 0 0 0-5.28 2.476.62.62 0 0 1-.43.177.63.63 0 0 1-.471-.22 7.2 7.2 0 0 0-5.451-2.487 7.2 7.2 0 0 0-4.83 1.854c-.218.196-.573.146-.557-.147a18 18 0 0 1 .245-2.14C7.633 12.727 14.54 6.458 22.99 6.002V5a1 1 0 0 1 1-1m0 4q-.108 0-.209-.022c-7.301.087-13.43 5.051-15.28 11.785a9.2 9.2 0 0 1 2.843-.447c2.287 0 4.381.835 5.991 2.213a10.98 10.98 0 0 1 6.626-2.213c2.493 0 4.795.83 6.641 2.225a9.2 9.2 0 0 1 6.006-2.225 9.2 9.2 0 0 1 2.842.447C37.603 13.037 31.486 8.077 24.197 7.98A1 1 0 0 1 23.99 8"
      clipRule="evenodd"
    />
  </svg>
);
export default IconUmbrella;
