import React from "react";
import PropTypes from "prop-types"; // Import PropTypes
import BringgzText from "../BringzzText";
import BringzzIcon from "../BringzzIcon";
import classnames from "classnames";

const DEFAULT_CATEGORYBOX_CLASSES =
  "flex justify-center p-2 gap-x-1 items-center rounded-lg cursor-pointer";
const DEFAULT_CATEGORYBOX_ICON_CLASSES = "";

/**
 * @typedef {Object} IconProps
 * @property {string} className - CSS classes for the icon.
 * @property {string} icon - The icon to display.
 * @property {string} folder - The folder where the icon is located.
 * @property {string} size - The size of the icon.
 */

/**
 * @typedef {Object} BringzzCategoryBoxProps
 * @property {IconProps} [left] - IconProps for the left icon.
 * @property {IconProps} [right] - IconProps for the right icon.
 * @property {string} [iconClasses] - Additional CSS classes for the icon.
 * @property {string} [labelClasses] - Additional CSS classes for the label.
 * @property {string} [label] - The label text for the category box.
 * @property {string} [className] - Additional CSS classes for styling.
 * @property {Function} [onClick] - Optional click handler for the category box.
 */

/**
 * A component representing a category box.
 * @param {BringzzCategoryBoxProps} props - The component props.
 * @returns {JSX.Element} - JSX for the category box component.
 */
export default function BringzzCategoryBox({
  left,
  right,
  iconClasses = DEFAULT_CATEGORYBOX_ICON_CLASSES,
  labelClasses = "",
  label,
  className,
  onClick,
  ...props
}) {
  return (
    <div
      {...props}
      className={classnames(DEFAULT_CATEGORYBOX_CLASSES, className)}
      onClick={onClick}
    >
      {left && (
        <BringzzIcon
          className={left.className}
          icon={left.icon}
          folder={left.folder}
          size={left.size || "18"}
        />
      )}

      {label && (
        <BringgzText
          className={`font-bold break-normal truncate ${labelClasses}`}
          tag="h4"
        >
          {label}
        </BringgzText>
      )}

      {right && (
        <BringzzIcon
          className={right.className}
          icon={right.icon}
          folder={right.folder}
          size={right.size || "18"}
        />
      )}
    </div>
  );
}

// Defining PropTypes for the component
BringzzCategoryBox.propTypes = {
  left: PropTypes.shape({
    className: PropTypes.string,
    icon: PropTypes.string.isRequired,
    folder: PropTypes.string,
    size: PropTypes.number,
  }),
  right: PropTypes.shape({
    className: PropTypes.string,
    icon: PropTypes.string.isRequired,
    folder: PropTypes.string,
    size: PropTypes.number,
  }),
  iconClasses: PropTypes.string,
  labelClasses: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};
