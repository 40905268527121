import * as React from "react";
const IconShoppingBasketCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.3 20.5h-3.5l-7-17.8c-.2-.5-.8-.8-1.3-.6s-.8.8-.6 1.3l6.8 17.1H12.3L19 3.4c.2-.5 0-1.1-.6-1.3-.5-.2-1.1 0-1.3.6l-7 17.8H6.7c-1.1 0-2 .9-2 2V31c0 1.1.9 2 2 2h.8l2.7 23.7c.3 3 2.9 5.3 6 5.3h31.7c3 0 5.6-2.3 6-5.3L56.5 33h.8c1.1 0 2-.9 2-2v-8.5c0-1.1-.8-2-2-2m-5.5 36c-.2 2-1.9 3.5-4 3.5H16.1c-2 0-3.7-1.5-4-3.5L9.5 33h45zM6.7 31v-8.5h50.7V31z" />
    <path d="M21.5 50.1c.6 0 1-.4 1-1v-7.7c0-.6-.4-1-1-1s-1 .4-1 1v7.7c0 .5.4 1 1 1M42.5 50.1c.6 0 1-.4 1-1v-7.7c0-.6-.4-1-1-1s-1 .4-1 1v7.7c0 .5.5 1 1 1" />
  </svg>
);
export default IconShoppingBasketCopy;
