import * as React from "react";
const IconSafeDeposit = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 2H6C3.8 2 2 3.8 2 6v52c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V6c0-2.2-1.8-4-4-4M4 22.6h27v18.7H4.4c-.1 0-.3 0-.4.1zm29 0h26.6c.1 0 .3 0 .4-.1v18.8H33zM60 6v14.7c-.1-.1-.3-.1-.4-.1H33V4h25c1.1 0 2 .9 2 2M6 4h25v16.6H4V6c0-1.1.9-2 2-2M4 58V43.3c.1.1.3.1.4.1H31V60H6c-1.1 0-2-.9-2-2m54 2H33V43.4h27V58c0 1.1-.9 2-2 2" />
    <path d="M11.4 12h5.8c.6 0 1-.4 1-1s-.4-1-1-1h-5.8c-.6 0-1 .4-1 1s.4 1 1 1M39.3 12h5.8c.6 0 1-.4 1-1s-.4-1-1-1h-5.8c-.6 0-1 .4-1 1s.4 1 1 1M11.4 30.2h5.8c.6 0 1-.4 1-1s-.4-1-1-1h-5.8c-.6 0-1 .4-1 1s.4 1 1 1M45.1 28.2h-5.8c-.6 0-1 .4-1 1s.4 1 1 1h5.8c.6 0 1-.4 1-1s-.5-1-1-1M17.1 49h-5.8c-.6 0-1 .4-1 1s.4 1 1 1h5.8c.6 0 1-.4 1-1s-.4-1-1-1M45.1 49h-5.8c-.6 0-1 .4-1 1s.4 1 1 1h5.8c.6 0 1-.4 1-1s-.5-1-1-1M54 15.3h.6c.6 0 1-.4 1-1s-.4-1-1-1H54c-.6 0-1 .4-1 1s.4 1 1 1M54.6 33.1H54c-.6 0-1 .4-1 1s.4 1 1 1h.6c.6 0 1-.4 1-1s-.4-1-1-1M54.6 52.3H54c-.6 0-1 .4-1 1s.4 1 1 1h.6c.6 0 1-.4 1-1s-.4-1-1-1M24.8 15.3h.6c.6 0 1-.4 1-1s-.4-1-1-1h-.6c-.6 0-1 .4-1 1s.4 1 1 1M24.8 35.1h.6c.6 0 1-.4 1-1s-.4-1-1-1h-.6c-.6 0-1 .4-1 1s.4 1 1 1M25.4 52.3h-.6c-.6 0-1 .4-1 1s.4 1 1 1h.6c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconSafeDeposit;
