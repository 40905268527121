import React, { useState } from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";
import classNames from "classnames";

/**
 * @typedef {object} BringzzRadioProps
 * @property {React.ReactNode} [left] - Optional left element, usually an icon.
 * @property {{ checked: React.ReactNode, unChecked: React.ReactNode }} state - State icons for checked/unchecked states.
 * @property {function} [onChange] - Function to call when the radio value changes.
 * @property {boolean} [defaultChecked] - Whether the radio is checked by default.
 * @property {string || object} [value] - The value of the radio button.
 * @property {boolean} [controlled] - Whether the radio button is controlled by parent.
 * @property {React.ReactNode} [children] - The children nodes of the radio button.
 */

/**
 * Custom radio button component with optional icon and flexible content.
 * @param {BringzzRadioProps} props - The component props.
 * @returns {JSX.Element} - JSX for the custom radio button component.
 */
export default function BringzzRadio({
  className,
  left,
  state,
  onChange,
  defaultChecked,
  value,
  controlled = false,
  leftClassname,
  children,
  ...props
}) {
  const [isChecked, setIsChecked] = useState(defaultChecked || false);

  const handleOnChange = () => {
    if (!controlled) {
      setIsChecked(!isChecked);
    }
    if (onChange) {
      onChange(value);
    }
  };

  // Determine checked state based on whether it's controlled
  const checkedState = controlled ? props.checked : isChecked;

  return (
    <div
      className={twMerge(
        "flex items-center space-x-6 cursor-pointer",
        className
      )}
      onClick={handleOnChange}
      {...props}
    >
      {left && <div className={classNames("flex-shrink-0", leftClassname)}>{left}</div>}
      <div className="flex-grow">{children}</div>
      <div className="flex-shrink-0">
        {checkedState ? state.checked : state.unChecked}
      </div>
      <input type="radio" className="hidden" checked={checkedState} readOnly />
    </div>
  );
}

BringzzRadio.propTypes = {
  left: PropTypes.node,
  leftClassname: PropTypes.string,
  state: PropTypes.shape({
    checked: PropTypes.node,
    unChecked: PropTypes.node,
  }),
  onChange: PropTypes.func,
  defaultChecked: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  controlled: PropTypes.bool,
  children: PropTypes.node,
};

BringzzRadio.displayName = 'BringzzRadio';
