import * as React from "react";
const IconSitemap = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M23.353 14.774c0-2.624 2.103-4.774 4.725-4.774h7.451c2.622 0 4.726 2.15 4.726 4.774v7.548c0 2.624-2.104 4.774-4.726 4.774h-2.725v3.334h19.249c2.107 0 3.794 1.727 3.794 3.83v2.644h2.428c2.622 0 4.725 2.15 4.725 4.774v7.548C63 51.85 60.897 54 58.275 54h-7.451c-2.622 0-4.726-2.15-4.726-4.774v-7.548c0-2.624 2.104-4.774 4.726-4.774h3.023V34.26c0-1.023-.816-1.83-1.794-1.83h-19.25v4.474h2.726c2.622 0 4.726 2.15 4.726 4.774v7.548c0 2.624-2.104 4.774-4.726 4.774h-7.45c-2.623 0-4.726-2.15-4.726-4.774v-7.548c0-2.624 2.103-4.774 4.725-4.774h2.726V32.43h-19.25c-.978 0-1.793.808-1.793 1.83v2.645h3.415c2.622 0 4.726 2.15 4.726 4.774v7.548c0 2.624-2.104 4.774-4.726 4.774h-7.45C3.103 54 1 51.85 1 49.226v-7.548c0-2.624 2.104-4.774 4.725-4.774h2.036V34.26c0-2.103 1.686-3.83 3.794-3.83h19.249v-3.334h-2.726c-2.622 0-4.725-2.15-4.725-4.774zM28.078 12c-1.493 0-2.725 1.23-2.725 2.774v7.548c0 1.544 1.232 2.774 2.725 2.774h7.451c1.493 0 2.726-1.23 2.726-2.774v-7.548c0-1.544-1.233-2.774-2.726-2.774zM5.725 38.904C4.232 38.904 3 40.134 3 41.678v7.548C3 50.77 4.232 52 5.725 52h7.451c1.493 0 2.726-1.23 2.726-2.774v-7.548c0-1.544-1.232-2.774-2.726-2.774zm22.353 0c-1.493 0-2.725 1.23-2.725 2.774v7.548c0 1.544 1.232 2.774 2.725 2.774h7.451c1.493 0 2.726-1.23 2.726-2.774v-7.548c0-1.544-1.233-2.774-2.726-2.774zm22.746 0c-1.493 0-2.726 1.23-2.726 2.774v7.548c0 1.544 1.233 2.774 2.726 2.774h7.45C59.769 52 61 50.77 61 49.226v-7.548c0-1.544-1.232-2.774-2.725-2.774z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconSitemap;
