import * as React from "react";
const IconItalic = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.2 1.8H22.8c-1.2 0-2.3 1-2.3 2.3s1 2.3 2.3 2.3h11.5l-9.2 51.5H12.8c-1.2 0-2.3 1-2.3 2.3s1 2.3 2.3 2.3h30.4c1.2 0 2.3-1 2.3-2.3s-1-2.3-2.3-2.3H29.7l9.2-51.5h12.3c1.2 0 2.3-1 2.3-2.3s-1-2.3-2.3-2.3" />
  </svg>
);
export default IconItalic;
