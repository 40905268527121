import * as React from "react";
const IconSpeaker = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M45.5 3H19.2c-2.8 0-5 2.2-5 5v48c0 2.8 2.2 5 5 5h26.3c2.8 0 5-2.2 5-5V8c0-2.8-2.2-5-5-5m3 53c0 1.7-1.3 3-3 3H19.2c-1.7 0-3-1.3-3-3V8c0-1.7 1.3-3 3-3h26.3c1.7 0 3 1.3 3 3z" />
    <path d="M32.4 34.2c-5.1 0-9.3 4.2-9.3 9.3s4.2 9.3 9.3 9.3 9.3-4.2 9.3-9.3-4.2-9.3-9.3-9.3m0 16.6c-4 0-7.3-3.3-7.3-7.3s3.3-7.3 7.3-7.3 7.3 3.3 7.3 7.3-3.3 7.3-7.3 7.3M32.4 25.7c3.3 0 5.9-2.7 5.9-5.9s-2.7-5.9-5.9-5.9-5.9 2.7-5.9 5.9 2.6 5.9 5.9 5.9m0-9.9c2.2 0 3.9 1.8 3.9 3.9 0 2.2-1.8 3.9-3.9 3.9s-3.9-1.8-3.9-3.9c-.1-2.1 1.7-3.9 3.9-3.9" />
  </svg>
);
export default IconSpeaker;
