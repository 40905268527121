import * as React from "react";
const IconPresentation = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 5.9H6c-2.2 0-4 1.8-4 4v6.8c0 .6.4 1 1 1h1.5v22.5c0 2.2 1.8 4 4 4H31v7.7l-5.4 4.5c-.4.4-.5 1-.1 1.4s1 .5 1.4.1l5.1-4.2 5.1 4.2c.2.2.4.2.6.2.3 0 .6-.1.8-.4.4-.4.3-1.1-.1-1.4L33 51.8v-7.7h22.5c2.2 0 4-1.8 4-4V17.7H61c.6 0 1-.4 1-1V9.9c0-2.2-1.8-4-4-4m-.5 34.3c0 1.1-.9 2-2 2h-47c-1.1 0-2-.9-2-2V17.7h51zM60 15.7H4V9.9c0-1.1.9-2 2-2h52c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconPresentation;
