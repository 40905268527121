import * as React from "react";
const IconBringzz = (props) => (
  <svg
    height={props.size || "19"}
    width={props.size || "21"}
    viewBox="0 0 19 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.65413 20.4308C0.7479 20.4308 0.273438 19.7137 0.273438 19.0068C0.273438 18.2999 0.7479 17.5815 1.65413 17.5815L14.2695 17.5624H14.5927C15.294 17.5624 16.0197 17.0462 16.0197 16.1841C16.0197 15.1008 15.3025 14.6164 14.5927 14.6164H14.2695L6.77076 14.6012C4.47407 14.6012 2.60428 12.6533 2.60428 10.2591C2.60428 7.86491 4.47285 5.91702 6.77076 5.91702L14.2695 5.88778C14.5927 5.88778 14.5927 5.88651 14.5927 5.88651C15.5196 5.88651 16.0051 5.14524 16.0051 4.41288C16.0051 3.68051 15.5148 2.93924 14.5768 2.93924H14.2682L1.65291 2.91636C0.7479 2.91381 0.273438 2.19798 0.273438 1.48977C0.273438 0.781561 0.7479 0.0644531 1.65413 0.0644531H14.4036C16.7003 0.0644531 18.5689 2.01489 18.5689 4.4116C18.5689 6.80832 16.7003 8.75367 14.4036 8.75367H14.2695L6.59635 8.78291C5.65962 8.78291 5.16808 9.52418 5.16808 10.2565C5.16808 10.9889 5.6584 11.7314 6.59635 11.7314L14.4036 11.7264C16.7003 11.7264 18.5689 13.6832 18.5689 16.0888C18.5689 18.4944 16.7003 20.4308 14.4036 20.4308H1.65413Z"
      fill="currentColor"
    />
  </svg>
);
export default IconBringzz;
