import * as React from "react";
const IconShieldCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M52.3 7.5 33.6 2.2c-1.1-.3-2.3-.3-3.4 0L11.7 7.5c-2.8.7-4.7 3.2-4.7 6v15.1c0 14.6 9 27.5 22.8 33 .7.3 1.5.4 2.2.4.8 0 1.5-.1 2.2-.4C48 56.1 57 43.2 57 28.7V13.6c0-2.9-1.9-5.4-4.7-6.1m2.5 21.2c0 13.7-8.2 25.9-21.2 31.1q-1.5.6-3 0C17.5 54.6 9 42.4 9 28.7V13.5c0-1.9 1.3-3.6 3.1-4.2l18.5-5.2c.8-.2 1.6-.2 2.3 0l18.7 5.2c1.9.5 3.2 2.2 3.2 4.2z" />
  </svg>
);
export default IconShieldCopy;
