import * as React from "react";
const IconCalculatorCard = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M45.6 16.7h-4.1V3c0-.6-.4-1-1-1h-17c-.6 0-1 .4-1 1v13.7h-4.1c-2.7 0-4.9 2.2-4.9 4.9V57c0 2.7 2.2 4.9 4.9 4.9h27.2c2.7 0 4.9-2.2 4.9-4.9V21.7c.1-2.8-2.2-5-4.9-5m-6.1 0H31V4h8.5zM24.5 4H29v12.7h-4.5zm24.1 53.1c0 1.6-1.3 2.9-2.9 2.9H18.4c-1.6 0-2.9-1.3-2.9-2.9V21.7c0-1.6 1.3-2.9 2.9-2.9h27.2c1.6 0 2.9 1.3 2.9 2.9v35.4z" />
    <path d="M24 45.5h-.3c-.6 0-1 .4-1 1s.4 1 1 1h.3c.6 0 1-.4 1-1s-.5-1-1-1M24 52.8h-.3c-.6 0-1 .4-1 1s.4 1 1 1h.3c.6 0 1-.4 1-1s-.5-1-1-1M32.1 45.5h-.3c-.6 0-1 .4-1 1s.4 1 1 1h.3c.6 0 1-.4 1-1s-.4-1-1-1M32.1 52.8h-.3c-.6 0-1 .4-1 1s.4 1 1 1h.3c.6 0 1-.4 1-1s-.4-1-1-1M40.3 45.5H40c-.6 0-1 .4-1 1s.4 1 1 1h.3c.6 0 1-.4 1-1s-.5-1-1-1M40.3 52.8H40c-.6 0-1 .4-1 1s.4 1 1 1h.3c.6 0 1-.4 1-1s-.5-1-1-1M36.3 24.1h-8.7c-2.7 0-4.9 2.2-4.9 4.9v4.6c0 2.7 2.2 4.9 4.9 4.9h8.7c2.7 0 4.9-2.2 4.9-4.9V29c.1-2.6-2.1-4.9-4.9-4.9m3 9.6c0 1.6-1.3 2.9-2.9 2.9h-8.7c-1.6 0-2.9-1.3-2.9-2.9v-4.6c0-1.6 1.3-2.9 2.9-2.9h8.7c1.6 0 2.9 1.3 2.9 2.9z" />
  </svg>
);
export default IconCalculatorCard;
