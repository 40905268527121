import * as React from "react";
const IconCondominium1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M33.6 11.1h-3.1c-.6 0-1 .4-1 1v3.1c0 .6.4 1 1 1h3.1c.6 0 1-.4 1-1v-3.1c0-.5-.5-1-1-1M42.5 11.1h-3.1c-.6 0-1 .4-1 1v3.1c0 .6.4 1 1 1h3.1c.6 0 1-.4 1-1v-3.1c0-.5-.5-1-1-1M33.6 24.3h-3.1c-.6 0-1 .4-1 1v3.1c0 .6.4 1 1 1h3.1c.6 0 1-.4 1-1v-3.1c0-.5-.5-1-1-1M42.5 24.3h-3.1c-.6 0-1 .4-1 1v3.1c0 .6.4 1 1 1h3.1c.6 0 1-.4 1-1v-3.1c0-.5-.5-1-1-1M33.6 38.4h-3.1c-.6 0-1 .4-1 1v3.1c0 .6.4 1 1 1h3.1c.6 0 1-.4 1-1v-3.1c0-.5-.5-1-1-1M42.5 38.4h-3.1c-.6 0-1 .4-1 1v3.1c0 .6.4 1 1 1h3.1c.6 0 1-.4 1-1v-3.1c0-.5-.5-1-1-1" />
    <path d="M61 56.2h-9.6V34.7h2.3c.6 0 1-.4 1-1s-.4-1-1-1h-2.3V21.9h2.3c.6 0 1-.4 1-1s-.4-1-1-1h-2.3V7.8h2.3c.6 0 1-.4 1-1s-.4-1-1-1H19.1c-.6 0-1 .4-1 1s.4 1 1 1h2.3v12.1h-2.3c-.6 0-1 .4-1 1s.4 1 1 1h2.3v10.8h-2.3c-.6 0-1 .4-1 1s.4 1 1 1h2.3v21.5H11.2V49c2.3 0 4.2-1.9 4.2-4.2V43c0-2.3-1.9-4.3-4.3-4.3H9.3C7 38.7 5 40.6 5 43v1.8C5 47.1 6.9 49 9.2 49v7.2H3c-.6 0-1 .4-1 1s.4 1 1 1h58c.6 0 1-.4 1-1s-.4-1-1-1M7 44.8V43c0-1.2 1-2.3 2.3-2.3h1.8c1.2 0 2.3 1 2.3 2.3v1.8c0 1.2-1 2.3-2.3 2.3H9.3C8 47 7 46 7 44.8m16.5-37h26v12.1h-26zm0 14.1h26v10.8h-26zM33 56.1v-6.9h6.9v6.9zm8.9.1v-8c0-.6-.4-1-1-1H32c-.6 0-1 .4-1 1v8h-7.5V34.7h26v21.5z" />
  </svg>
);
export default IconCondominium1;
