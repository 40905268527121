import * as React from "react";
const IconPrayerRug = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M37.4 23.8c-1.9-1.3-3.2-2.1-3.2-5.5 0-.6-.4-1-1-1h-2.5c-.6 0-1 .4-1 1 0 3.4-1.3 4.3-3.2 5.5-1.8 1.2-4.2 2.8-4.2 6.8 0 1.4.5 2.7 1.3 3.7-.8 1-1.3 2.3-1.3 3.7v3.4c0 2.8 2.3 5 5 5h9.2c2.8 0 5-2.3 5-5V38c0-1.4-.5-2.7-1.3-3.7.8-1 1.3-2.3 1.3-3.7.1-4-2.3-5.6-4.1-6.8m.9 11.2c.8.7 1.3 1.8 1.3 3v3.4c0 1.7-1.4 3-3 3h-9.2c-1.7 0-3-1.4-3-3V38c0-1.1.5-2.2 1.3-3 .2-.2.3-.5.3-.8s-.1-.6-.3-.8c-.8-.7-1.3-1.8-1.3-2.9 0-2.9 1.6-4 3.3-5.1 1.8-1.2 3.7-2.5 4-6.2h.5c.3 3.7 2.2 5 4 6.2 1.7 1.1 3.3 2.2 3.3 5.1 0 1.1-.5 2.2-1.3 2.9-.1.3-.2.6-.2.9s.1.6.3.7" />
    <path d="M49.9 2c-.6 0-1 .4-1 1v4.7H39V3c0-.6-.4-1-1-1s-1 .4-1 1v4.7H27V3c0-.6-.4-1-1-1s-1 .4-1 1v4.7h-9.9V3c0-.6-.4-1-1-1s-1 .4-1 1v58c0 .6.4 1 1 1s1-.4 1-1v-5H25v5c0 .6.4 1 1 1s1-.4 1-1v-5h10v5c0 .6.4 1 1 1s1-.4 1-1v-5h9.9v5c0 .6.4 1 1 1s1-.4 1-1V3c0-.6-.5-1-1-1M15.1 9.7h33.7V54H15.1z" />
  </svg>
);
export default IconPrayerRug;
