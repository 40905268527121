import React, { Children, isValidElement, Fragment } from "react";
import classnames from "classnames";

export default function BringzzBoxSlider({
  behavior = {},
  children,
  className = "space-x-2",
  firstItemDivider,
  ...props
}) {
  // Function to check if all children are of the same type
  const validateChildren = () => {
    const childrenArray = Children.toArray(children);

    // Check if there is at least one child
    if (childrenArray.length === 0) {
      console.error("BringzzBoxSlider requires at least one child.");
      return false;
    }

    if (childrenArray.length === 1) {
      return true;
    }
    const firstChildType = childrenArray[0].type;
    return childrenArray.every(
      (child) => isValidElement(child) && child.type === firstChildType
    );
  };

  const areChildrenValid = validateChildren();

  if (!areChildrenValid) {
    console.error(
      "All children of BringzzBoxSlider should be of the same component type."
    );
    return null;
  }

  // Function to generate responsive classes based on behavior
  const generateResponsiveClasses = () => {
    let responsiveClasses = "";
    if (behavior.xl) {
      if (behavior.xl === "right") {
        responsiveClasses += " xl:justify-end";
      } else if (behavior.xl === "left") {
        responsiveClasses += " xl:justify-start";
      } else if (behavior.xl === "center") {
        responsiveClasses += " xl:justify-center";
      }
    }

    if (behavior.m) {
      if (behavior.m === "right") {
        responsiveClasses += " md:justify-end";
      } else if (behavior.m === "left") {
        responsiveClasses += " md:justify-start";
      } else if (behavior.m === "center") {
        responsiveClasses += " md:justify-center";
      }
    }

    return responsiveClasses;
  };

  const responsiveClass = generateResponsiveClasses();

  const modifiedChildren = firstItemDivider
    ? Children.map(children, (child, index) =>
        index === 0 ? (
          <Fragment>
            {child}
            <div
              className={classnames(
                "w-[1px] h-full",
                firstItemDivider.className
              )}
              aria-hidden="true"
            ></div>
          </Fragment>
        ) : (
          child
        )
      )
    : children;

  return (
    <div
      className={classnames(
        "flex pb-3 overflow-x-auto",
        className,
        responsiveClass
      )}
      {...props}
    >
      {modifiedChildren}
    </div>
  );
}
