import * as React from "react";
const IconPrinterCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.4 15.2h-1.3V6c0-2.2-1.8-4-4-4H13.9c-2.2 0-4 1.8-4 4v9.2H8.6c-2.2 0-4 1.8-4 4v15.1c0 2.2 1.8 4 4 4h1.3V58c0 2.2 1.8 4 4 4h36.2c2.2 0 4-1.8 4-4V38.3h1.3c2.2 0 4-1.8 4-4V19.2c0-2.2-1.8-4-4-4M11.9 6c0-1.1.9-2 2-2h36.2c1.1 0 2 .9 2 2v9.2H11.9zm40.2 52c0 1.1-.9 2-2 2H13.9c-1.1 0-2-.9-2-2V38.3h40.2zm5.3-23.7c0 1.1-.9 2-2 2H8.6c-1.1 0-2-.9-2-2V19.2c0-1.1.9-2 2-2h46.8c1.1 0 2 .9 2 2z" />
    <path d="M24.2 46.2h15.5c.6 0 1-.4 1-1s-.4-1-1-1H24.2c-.6 0-1 .4-1 1s.5 1 1 1M24.2 54.1h15.5c.6 0 1-.4 1-1s-.4-1-1-1H24.2c-.6 0-1 .4-1 1s.5 1 1 1" />
    <circle cx={50.5} cy={24.1} r={2.6} />
  </svg>
);
export default IconPrinterCopy;
