import * as React from "react";
const IconBank = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.8 49.7V22.3c.9-.1 1.6-.7 1.8-1.6.3-1-.1-2-.9-2.5L33.2 2.4c-.7-.5-1.7-.5-2.4 0L6.2 18.2c-.8.5-1.2 1.5-.9 2.5.3.9 1 1.5 1.9 1.6v27.4c-1.5.6-2.6 2-2.6 3.7V58c0 2.2 1.8 4 4 4h46.9c2.2 0 4-1.8 4-4v-4.6c0-1.7-1.2-3.2-2.7-3.7M23.1 22.3v27.1H9.2V22.3zm2 0H39v27.1H25.1zm15.8 0h13.9v27.1H40.9zM7.3 19.9 31.8 4h.2l24.5 15.9s.1.1.1.3-.2.2-.2.2h-49s-.2 0-.2-.2.1-.3.1-.3M57.5 58c0 1.1-.9 2-2 2h-47c-1.1 0-2-.9-2-2v-4.6c0-1.1.9-2 2-2h46.9c1.1 0 2 .9 2 2V58z" />
  </svg>
);
export default IconBank;
