import * as React from "react";
const IconSpeechBubble35 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M45.7 11.5H18.3C9.3 11.5 2 18.8 2 27.8 2 36.2 8.5 43.1 16.7 44c-.8 1.1-1.8 1.9-3 2.5-1 .4-1.6 1.4-1.6 2.5s.6 2 1.6 2.5q2.55 1.2 5.4 1.2c1.8 0 3.6-.4 5.3-1.2 3.2-1.4 5.6-4.1 6.8-7.3h14.5c9 0 16.3-7.3 16.3-16.3 0-9.1-7.3-16.4-16.3-16.4m0 30.5H30.5c-.4 0-.8.3-1 .7-.9 3-3.1 5.5-5.9 6.8-2.9 1.3-6.2 1.3-9 0-.4-.2-.4-.5-.4-.6s0-.5.4-.6c2.1-1 3.8-2.7 4.8-4.8.1-.3.1-.7-.1-.9s-.6-.6-.9-.6C10.5 42 4 35.6 4 27.8c0-7.9 6.4-14.3 14.3-14.3h27.4c7.9 0 14.3 6.4 14.3 14.3C60 35.6 53.6 42 45.7 42" />
    <path d="M18.2 26.3h-.4c-1.1 0-2 .9-2 2s.9 2 2 2h.4c1.1 0 2-.9 2-2s-.9-2-2-2M32 26.3h-.4c-1.1 0-2 .9-2 2s.9 2 2 2h.4c1.1 0 2-.9 2-2s-.9-2-2-2M45.8 26.3h-.4c-1.1 0-2 .9-2 2s.9 2 2 2h.4c1.1 0 2-.9 2-2s-.9-2-2-2" />
  </svg>
);
export default IconSpeechBubble35;
