import * as React from "react";
const IconDollarCard = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.2 5.5H8.8C5.1 5.5 2 8.4 2 11.8v29c0 3.5 3.1 6.3 6.8 6.3h23.1c.6 0 1-.4 1-1s-.4-1-1-1H8.8c-2.6 0-4.8-1.9-4.8-4.3V26.7h56v8.7c0 .6.4 1 1 1s1-.4 1-1V11.8c0-3.4-3.1-6.3-6.8-6.3m-46.4 2h46.4c2.6 0 4.8 1.9 4.8 4.3v4.3H4v-4.3c0-2.3 2.2-4.3 4.8-4.3M4 24.7v-6.6h56v6.6z" />
    <path d="M49.6 45.3h-4.1c-1.7 0-3-1.6-3-3.5s1.4-3.5 3-3.5H52c.6 0 1-.4 1-1s-.4-1-1-1h-3.5v-1.2c0-.6-.4-1-1-1s-1 .4-1 1v1.2h-1.1c-2.8 0-5 2.5-5 5.5s2.3 5.5 5 5.5h4.1c1.7 0 3 1.6 3 3.5s-1.4 3.5-3 3.5H43c-.6 0-1 .4-1 1s.4 1 1 1h3.5v1.2c0 .6.4 1 1 1s1-.4 1-1v-1.2h1c2.8 0 5-2.5 5-5.5.1-3-2.1-5.5-4.9-5.5" />
  </svg>
);
export default IconDollarCard;
