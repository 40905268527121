import * as React from "react";
const IconDatabase9 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m60.9 28-10.1-3.3V11.3c0-6-12.5-9.3-24.2-9.3S2.4 5.3 2.4 11.3v29.1c0 5.2 10.6 9.3 24.2 9.3 1.8 0 3.6-.1 5.3-.2 1.6 5.1 5.3 8.4 13.9 12.4.1.1.2.1.4.1.1 0 .3 0 .4-.1 13.2-6.2 15-10.3 15-23.7V29c0-.5-.3-.8-.7-1M26.6 4c13.1 0 22.2 3.8 22.2 7.3-.1 3.4-9.2 7.2-22.2 7.2-13.1 0-22.2-3.8-22.2-7.3C4.4 7.8 13.5 4 26.6 4M4.4 15.2c4 3.5 13.3 5.4 22.2 5.4s18.2-1.9 22.2-5.4V24l-2.3-.8q-.3-.15-.6 0L31.4 28c-.4.1-.6.4-.7.8-1.4.1-2.8.1-4.1.1-13.1 0-22.2-3.8-22.2-7.3zm0 10.4c4 3.5 13.3 5.4 22.2 5.4 1.3 0 2.7-.1 4.1-.1v7.3c-1.4.1-2.8.2-4.1.2-13.1 0-22.2-3.8-22.2-7.3zm0 14.8v-5.5c4 3.5 13.3 5.4 22.2 5.4 1.4 0 2.8-.1 4.1-.2.1 3.1.3 5.4.6 7.3-1.6.1-3.2.2-4.8.2-12.6.1-22.1-3.8-22.1-7.2m55.2-2.2c0 12.4-1.2 15.9-13.4 21.7C38.1 56 34.9 53 33.6 48.2c-.6-2.2-.8-4.9-.8-9v-9.4l13.4-4.4 13.4 4.4z" />
  </svg>
);
export default IconDatabase9;
