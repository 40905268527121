import * as React from "react";
const IconVisaCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M24.8 39.6h4l2.4-15.2h-3.9zM39.2 30.5c-1.4-.7-2.2-1.2-2.2-1.9 0-.6.7-1.3 2.3-1.3 1.3 0 2.2.3 3 .6l.4.1.5-3.2c-.8-.3-2-.6-3.5-.6-3.9 0-6.7 2-6.7 5 0 2.2 1.9 3.5 3.5 4.2 1.5.7 2.1 1.2 2.1 1.9 0 1-1.2 1.5-2.4 1.5-1.6 0-2.5-.2-3.8-.8l-.5-.2-.6 3.5c.9.3 2.6.7 4.3.7 4.2.1 6.9-2 6.9-5.2-.1-1.8-1.1-3.2-3.3-4.3M50.2 24.4c-.9 0-1.7.3-2.1 1.3l-5.9 13.9h4v.1s.6-1.9.9-2.3h5c.1.5.5 2.3.5 2.3h3.7l-3.2-15.2h-2.9zm-2 9.9c.3-.9 1.6-4.2 1.6-4.2 0 .1.4-.9.5-1.4l.3 1.3s.8 3.7.9 4.4c-.6-.1-2.5-.1-3.3-.1M21.6 24.4l-3.9 10.5-2.1-10.5H7.7l4.4 3.3 3.2 11.9h4.1l6.4-15.2z" />
    <path d="M57 13.8H7c-3.3 0-6 2.7-6 6v24.3c0 3.3 2.7 6 6 6h50c3.3 0 6-2.7 6-6V19.8c0-3.3-2.7-6-6-6m4 30.4c0 2.2-1.8 4-4 4H7c-2.2 0-4-1.8-4-4V19.8c0-2.2 1.8-4 4-4h50c2.2 0 4 1.8 4 4z" />
  </svg>
);
export default IconVisaCopy;
