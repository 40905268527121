import * as React from "react";
const IconConfetti = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M3 6.6c2.2 0 3.6 4.5 4.8 8.5C9.3 20 10.9 25 14.5 25s5.2-5 6.8-9.9c1.3-4 2.7-8.5 4.8-8.5 2.2 0 3.6 4.5 4.8 8.5C32.4 20 34 25 37.7 25c3.6 0 5.2-5 6.8-9.9 1.3-4 2.7-8.5 4.9-8.5s3.6 4.5 4.9 8.5c1.5 4.9 3.1 9.9 6.8 9.9.6 0 1-.4 1-1s-.4-1-1-1c-2.2 0-3.6-4.5-4.9-8.5-1.5-4.9-3.1-9.9-6.8-9.9s-5.2 5-6.8 9.9c-1.3 4-2.7 8.5-4.9 8.5s-3.6-4.5-4.8-8.5c-1.5-4.9-3.1-9.9-6.8-9.9-3.6 0-5.2 5-6.7 9.9-1.3 4-2.7 8.5-4.8 8.5-2.2 0-3.6-4.5-4.8-8.5C8.2 9.7 6.6 4.6 3 4.6c-.6 0-1 .4-1 1s.4 1 1 1M61 57.4c-2.2 0-3.6-4.5-4.9-8.5C54.6 44 53 39 49.3 39s-5.2 5-6.8 9.9c-1.3 4-2.7 8.5-4.9 8.5s-3.6-4.5-4.8-8.5C31.3 44 29.7 39 26 39c-3.6 0-5.2 5-6.7 9.9-1.3 4-2.7 8.5-4.8 8.5-2.2 0-3.6-4.5-4.8-8.5-1.5-4.9-3.1-10-6.7-10-.6 0-1 .4-1 1s.4 1 1 1c2.2 0 3.6 4.5 4.8 8.5 1.5 4.9 3.1 9.9 6.7 9.9s5.2-5 6.8-9.9c1.3-4 2.7-8.5 4.8-8.5 2.2 0 3.6 4.5 4.8 8.5 1.5 4.9 3.1 9.9 6.8 9.9 3.6 0 5.2-5 6.8-9.9 1.3-4 2.7-8.5 4.9-8.5s3.6 4.5 4.9 8.5c1.5 4.9 3.1 9.9 6.8 9.9.6 0 1-.4 1-1s-.5-.9-1.1-.9" />
    <circle cx={13.5} cy={5.6} r={2.6} />
    <path d="M37.3 8.3c1.5 0 2.6-1.2 2.6-2.6 0-1.5-1.2-2.6-2.6-2.6-1.5 0-2.6 1.2-2.6 2.6-.1 1.4 1.1 2.6 2.6 2.6M24.1 24.1c0 1.5 1.2 2.6 2.6 2.6 1.5 0 2.6-1.2 2.6-2.6 0-1.5-1.2-2.6-2.6-2.6s-2.6 1.1-2.6 2.6" />
    <circle cx={50.5} cy={24.1} r={2.6} />
    <circle cx={13.5} cy={39.9} r={2.6} />
    <path d="M34.6 39.9c0 1.5 1.2 2.6 2.6 2.6 1.5 0 2.6-1.2 2.6-2.6 0-1.5-1.2-2.6-2.6-2.6s-2.6 1.2-2.6 2.6M26.7 55.7c-1.5 0-2.6 1.2-2.6 2.6 0 1.5 1.2 2.6 2.6 2.6 1.5 0 2.6-1.2 2.6-2.6.1-1.4-1.1-2.6-2.6-2.6M50.5 55.7c-1.5 0-2.6 1.2-2.6 2.6 0 1.5 1.2 2.6 2.6 2.6 1.5 0 2.6-1.2 2.6-2.6s-1.2-2.6-2.6-2.6" />
  </svg>
);
export default IconConfetti;
