import * as React from "react";
const IconBabyChicken = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.8 20.9H46c-.2 0-.4-.2-.5-.4C44 11.1 35.9 4.2 26.4 4.2c-10 0-18.4 7.4-19.3 16.7H2.5c-.6 0-1 .4-1 1s.4 1 1 1H7V32c0 9.4 4.6 17.9 12.5 23.2 2.9 2 6.3 3.4 9.8 4 1.8.3 3.6.5 5.4.5 8.1 0 15.8-3.5 21.2-9.8 4.3-5 6.6-11.6 6.6-18.5v-7.8c0-1.5-1.2-2.7-2.7-2.7m.7 10.6c0 6.5-2.2 12.6-6.1 17.2-6.1 7.2-15.4 10.4-24.7 8.6-3.2-.6-6.4-1.9-9.1-3.7C13.2 48.6 9 40.8 9 32V22c.2-8.7 8-15.7 17.3-15.7 8.6 0 15.8 6.1 17.2 14.6.2 1.2 1.2 2.1 2.4 2.1h13.7c.4 0 .7.3.7.7v7.8z" />
  </svg>
);
export default IconBabyChicken;
