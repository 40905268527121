import * as React from "react";
const IconPizza = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62 36.3c-2.2-6.9-7-14.2-13.5-20.8C41.9 9 34.6 4.2 27.7 2c-2.7-.9-5.6.5-6.7 3.2L2.1 55c-.8 2-.3 4.2 1.2 5.7 1 1 2.4 1.6 3.8 1.6.6 0 1.3-.1 1.9-.4l32.9-12.5h.1L58.8 43c2.7-1.1 4.1-4 3.2-6.7M7.4 57.7c-.5.2-.8 0-.9-.2-.1-.1-.4-.5-.2-.9L9.9 47c1.2 1.7 3.2 2.8 5.4 2.8 3.6 0 6.6-2.9 6.6-6.6 0-3.6-2.9-6.6-6.6-6.6-.5 0-1 .1-1.5.2l6.9-18.1c.8.5 1.7 1.1 2.6 1.7-1.3 1.2-2.1 2.9-2.1 4.8 0 3.6 2.9 6.6 6.6 6.6 2.4 0 4.5-1.3 5.7-3.3 4.2 4 8.4 8.8 12 14.6l-1 .4c-.4-3.3-3.2-5.8-6.5-5.8-3.6 0-6.6 2.9-6.6 6.6 0 1.4.4 2.7 1.2 3.8zm5.8-14.4c0-1.1.9-2.1 2.1-2.1 1.1 0 2.1.9 2.1 2.1 0 1.1-.9 2.1-2.1 2.1-1.1 0-2.1-1-2.1-2.1m14-19.9c.8.7 1.7 1.3 2.6 2.1 0 1.1-.9 2-2.1 2-1.1 0-2.1-.9-2.1-2.1 0-1 .7-1.8 1.6-2m10 23c-.8-.3-1.4-1-1.4-2 0-1.1.9-2.1 2.1-2.1 1.1 0 2.1.9 2.1 2.1 0 .4-.1.7-.3 1zm20-7.7-7.6 2.9c-4.8-7.9-10.5-14.2-16.2-19-.1 0-.1-.1-.2-.1-1.4-1.2-2.8-2.3-4.1-3.3-2.4-1.8-4.6-3.3-6.8-4.6l2.9-7.7c.1-.3.5-.6.8-.6h.3c6.1 1.9 13 6.4 18.9 12.4 5.9 5.9 10.5 12.9 12.4 18.9.3.5 0 1-.4 1.1" />
  </svg>
);
export default IconPizza;
