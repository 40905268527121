import * as React from "react";
const IconIslandAlt3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60 57.8c-1.3 0-2-.7-2.8-1.6-.9-1-2.1-2.3-4.3-2.3s-3.4 1.3-4.3 2.3c-.8.9-1.5 1.6-2.8 1.6s-2-.7-2.8-1.6c-.9-1-2.1-2.3-4.3-2.3s-3.4 1.3-4.3 2.3c-.8.9-1.5 1.6-2.8 1.6s-2-.7-2.8-1.6c-.9-1-2.1-2.3-4.3-2.3s-3.4 1.3-4.3 2.3c-.8.9-1.4 1.6-2.7 1.6l-.2-.3c0-.1-4.3-9.3-2.9-16.4.3-1.5.9-3 1.5-4.7s1.2-3.4 1.6-5.2c.1-.1.2-.3.3-.5.1-.5.2-.9.4-1.4 2.4.8 6.1 4 7 7.5.1.4.5.7 1 .7h.3c.5-.1.8-.7.7-1.2-1-3.7-4.8-7.5-8-8.7 2.7-4.1 8.6-6.3 14.7-5.2.5.1 1.1-.3 1.2-.8s-.3-1.1-.8-1.2c-7-1.4-13.6 1.1-16.8 5.6-.4-3.2-1.7-6.3-3.2-8.5-1.4-2.1-3.1-3.5-4.7-3.7-.5-.1-1.1.3-1.2.8s.3 1.1.8 1.2c1 .2 2.3 1.2 3.4 2.9 1.3 1.9 2.5 4.8 2.9 7.8-3.6-2.8-8.9-3.2-13-.6-.5.3-.6.9-.3 1.4s.9.6 1.4.3c3.9-2.5 9.2-1.7 12 1.7 0 .4-.1.8-.2 1.2v.2c-.4 1.7-1 3.4-1.6 5-.7 1.8-1.3 3.4-1.6 5.1-1.1 5.7 1.1 12.4 2.3 15.7l-.2-.2c-.9-1-2.1-2.3-4.3-2.3s-3.4 1.3-4.3 2.3C5 57 4.3 57.8 3 57.8c-.6 0-1 .4-1 1s.4 1 1 1c2.2 0 3.4-1.3 4.3-2.3.8-.9 1.5-1.6 2.8-1.6s2 .7 2.8 1.6 1.8 1.9 3.5 2.2c.1 0 .2.1.3.1h.5c2.2 0 3.4-1.3 4.3-2.3.8-.9 1.5-1.6 2.8-1.6s2 .7 2.8 1.6c.9 1 2.1 2.3 4.3 2.3s3.4-1.3 4.3-2.3c.8-.9 1.5-1.6 2.8-1.6s2 .7 2.8 1.6c.9 1 2.1 2.3 4.3 2.3s3.4-1.3 4.3-2.3c.8-.9 1.5-1.6 2.8-1.6s2 .7 2.8 1.6c.9 1 2.1 2.3 4.3 2.3.6 0 1-.4 1-1s-.3-1-.8-1M62 18c-.2-.5-.7-.8-1.3-.6-.2.1-4.8 1.6-6.9 4.7-2.1-3.1-6.7-4.6-6.9-4.7-.5-.2-1.1.1-1.3.6s.1 1.1.6 1.3c1.6.5 6 2.5 6.6 5.5.1.5.5.8 1 .8s.9-.3 1-.8c.7-3.5 6.5-5.4 6.6-5.5.4-.2.7-.8.6-1.3M43.4 6.2c1.1.4 4 1.7 4.4 3.6.1.5.5.8 1 .8s.9-.3 1-.8c.4-1.9 3.3-3.2 4.4-3.6.5-.2.8-.7.6-1.3-.2-.5-.7-.8-1.3-.6-.2.1-3.1 1-4.7 3.1-1.6-2-4.6-3-4.7-3.1-.5-.2-1.1.1-1.3.6-.3.6 0 1.1.6 1.3" />
  </svg>
);
export default IconIslandAlt3;
