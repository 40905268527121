import * as React from "react";
const IconSeeds1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 23.3h-7.4L36.9 8c-2.7-2.4-6.7-2.4-9.4 0L10.8 23.3H3c-.6 0-1 .4-1 1s.4 1 1 1h2.5v.1c-1.1 3.9-1.7 7.8-1.7 10.9 0 5.9 3.1 11.5 8.7 15.5 5.3 3.9 12.3 6 19.7 6s14.4-2.1 19.7-6c5.6-4.1 8.7-9.6 8.7-15.5 0-3.1-.6-7-1.7-10.9v-.1H61c.6 0 1-.4 1-1s-.4-1-1-1M28.8 9.5c1.9-1.7 4.8-1.7 6.7 0l15.1 13.8H13.7zM57 25.9c1 3.7 1.6 7.5 1.6 10.4 0 5.3-2.8 10.2-7.9 13.9-5 3.6-11.5 5.6-18.5 5.6s-13.6-2-18.5-5.6c-5.1-3.7-7.9-8.6-7.9-13.9 0-2.9.6-6.7 1.6-10.4.1-.3.4-.6.8-.6h48c.4 0 .7.3.8.6" />
  </svg>
);
export default IconSeeds1;
