import * as React from "react";
const IconWalker = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m51.6 48-4.4-32.8C46.2 7.7 39.7 2 32.1 2S18 7.7 17 15.2L12.5 48c-3.8.1-6.9 3.2-6.9 7 0 3.9 3.1 7 7 7s7-3.1 7-7c0-3.2-2.2-5.9-5.1-6.7l1.2-9.3h32.6l1.2 9.2c-3 .8-5.2 3.5-5.2 6.8 0 3.9 3.1 7 7 7s7-3.1 7-7c0-3.8-3-6.9-6.7-7M32.1 4c6.6 0 12.3 4.9 13.1 11.5l1.2 9.1H17.7l1.2-9.1C19.8 8.9 25.4 4 32.1 4M17.7 55c0 2.8-2.2 5-5 5s-5-2.2-5-5 2.2-5 5-5 5 2.3 5 5M16 37l1.4-10.4h29.3L48.1 37zm35.3 23c-2.8 0-5-2.2-5-5s2.2-5 5-5 5 2.2 5 5-2.2 5-5 5" />
  </svg>
);
export default IconWalker;
