import * as React from "react";
const IconTentAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.7 51.7 47.1 11.8V6.4c0-.6-.4-1-1-1s-1 .4-1 1v4h-26v-4c0-.6-.4-1-1-1s-1 .4-1 1v4.9L2.3 51.7c-.6 1.5-.3 3.3.6 4.7 1 1.4 2.5 2.2 4.2 2.2h49.7c1.7 0 3.2-.8 4.2-2.2s1.3-3.2.7-4.7m-27.5 4.9H19.1V15.4zM4.6 55.2c-.6-.8-.7-1.9-.4-2.9l12.9-35.2v39.4h-10c-1 .1-1.9-.4-2.5-1.3m54.8 0c-.6.8-1.5 1.3-2.6 1.3H36.3l-16.1-44h25q0 .3.3.6l14.4 39.3c.3.9.1 2-.5 2.8" />
  </svg>
);
export default IconTentAlt1;
