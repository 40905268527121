import * as React from "react";
const IconMoreAltCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <circle cx={10.3} cy={32} r={8.3} />
    <circle cx={32} cy={32} r={8.3} />
    <circle cx={53.8} cy={32} r={8.3} />
  </svg>
);
export default IconMoreAltCopy;
