import * as React from "react";
const IconVideoCameraAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 22.7c-.6-.4-1.4-.6-2.2-.3l-11.3 4.1v-3.9c0-2.8-2.2-5-5-5H7c-2.8 0-5 2.2-5 5v18.9c0 2.8 2.2 5 5 5h35.5c2.8 0 5-2.2 5-5v-3.9l11.3 4.1c.3.1.5.1.8.1.5 0 1-.1 1.4-.4.6-.4 1-1.2 1-1.9V24.6c0-.8-.4-1.5-1-1.9M42.5 44.4H7c-1.7 0-3-1.3-3-3V22.6c0-1.7 1.3-3 3-3h35.5c1.7 0 3 1.3 3 3v18.7c0 1.8-1.3 3.1-3 3.1m17.5-5c0 .2-.1.3-.2.3s-.2.1-.3 0l-12-4.3v-6.9l12-4.3c.2-.1.3 0 .3 0 .1 0 .2.1.2.3z" />
  </svg>
);
export default IconVideoCameraAlt;
