import * as React from "react";
const IconStarEmpty = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      
      d="m32 8.5 3.402-1.6-1.4-2.6c-.4-.8-1.2-1.3-2-1.3s-1.6.5-2 1.2l-1.4 2.6zM37.537 11.038l-4.025 2.01 3.083 6.174 4.026-2.012zM39 23.9c.3.7 1 1.1 1.7 1.2l3 .4.6-4.5-1.8-.2-.8-1.6-4 2.1zM48.98 21.722l-.628 4.456 7.03.992.629-4.456zM63.1 25.1c-.3-.8-1-1.4-1.8-1.6l-3-.5-.3 4 2.4 2.4 2.2-2c.6-.6.8-1.5.5-2.3M53.935 29.335l-5.02 4.733L52 37.343l5.02-4.734zM48.9 40.2l1.3-1.3-3.1-3.2-2.1 2.1c-.5.5-.8 1.3-.7 2l.5 2.9 4.4-.7zM50.056 46.448l-4.427.806 1.236 6.788 4.427-.806zM51.602 55.4 47 56l-.898 3.3 2.7 1.4c.3.2.7.2 1 .2.5 0 1-.2 1.3-.4.7-.5 1-1.4.9-2.2zM37.84 50.01l-2.047 4.008 6.233 3.185 2.048-4.008zM31 47.6 28.3 49l2.1 4 1.6-.9 1.7.9 2-4-2.7-1.4c-.6-.3-1.4-.3-2 0M24.098 51.082l-6.246 3.158 2.03 4.016 6.247-3.158zM16 56l-3.7-.5-.5 2.9c-.1.8.2 1.7.9 2.2.4.3.9.4 1.3.4s.7-.1 1-.3l2.7-1.4zM14.374 44.202l-1.151 6.702 4.435.762 1.151-6.702zM19.7 39.8c.1-.7-.1-1.5-.6-2l-2.2-2.1-3.1 3.2 1.3 1.3-.3 1.7 4.4.8zM8.482 27.716 5.395 30.99l5.02 4.734 3.087-3.275zM6.302 26.7l-.6-3.7-3.1.5c-.9.1-1.6.7-1.8 1.6s0 1.7.6 2.3l2.3 2zM17.34 21.327l-6.93.987.635 4.456 6.93-.988zM21.6 20.8l-1.9.2.6 4.5 3.1-.4c.7-.1 1.4-.5 1.7-1.2l1.4-2.6-4-2.1zM27.606 8.952l-3.137 6.146 4.008 2.046 3.137-6.146z"
    />
  </svg>
);
export default IconStarEmpty;
