import * as React from "react";
const IconZen = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M33.7 14.1c4.3 0 7.9-2 7.9-4.4 0-2.5-3.6-4.6-7.9-4.6s-7.9 2.1-7.9 4.6c0 2.4 3.6 4.4 7.9 4.4m0-7c3.6 0 5.9 1.7 5.9 2.6 0 .8-2.3 2.4-5.9 2.4s-5.9-1.6-5.9-2.4c0-.9 2.3-2.6 5.9-2.6M22.6 24.4c2.5 1.1 5.9 1.7 9.4 1.7 3.6 0 6.9-.6 9.4-1.7 1.1-.5 3.6-1.8 3.6-3.6s-2.5-3.1-3.6-3.6c-2.5-1.1-5.9-1.7-9.4-1.7-3.6 0-6.9.6-9.4 1.7-1.1.5-3.6 1.8-3.6 3.6s2.5 3.1 3.6 3.6m.8-5.3c2.3-1 5.3-1.5 8.6-1.5s6.4.5 8.6 1.5c1.7.8 2.4 1.6 2.4 1.8s-.7 1-2.4 1.8c-2.3 1-5.3 1.5-8.6 1.5s-6.4-.5-8.6-1.5c-1.8-.9-2.4-1.7-2.4-1.9s.6-1 2.4-1.7M33.7 41.7c10.7 0 18.2-3.8 18.2-7.1 0-1.7-1.9-3.5-5.1-4.8-3.5-1.4-8.1-2.2-13.1-2.2s-9.6.8-13.1 2.2c-3.2 1.3-5.1 3.1-5.1 4.8 0 3.4 7.5 7.1 18.2 7.1M21.4 31.6c3.3-1.3 7.6-2.1 12.3-2.1s9.1.7 12.3 2.1c2.8 1.1 3.9 2.4 3.9 3 0 1.5-5.7 5.1-16.2 5.1s-16.2-3.6-16.2-5.1c0-.6 1.1-1.8 3.9-3M61 56.9H47.3c.5-.2 1-.3 1.5-.5 4.2-1.5 6.6-3.5 6.6-5.4s-2.4-3.9-6.6-5.4C44.3 44 38.3 43.2 32 43.2s-12.3.8-16.8 2.4C11 47.1 8.6 49.1 8.6 51s2.4 3.9 6.6 5.4c.5.2 1 .3 1.5.5H3c-.6 0-1 .4-1 1s.4 1 1 1h58c.6 0 1-.4 1-1s-.4-1-1-1m-45.1-2.3c-4.3-1.5-5.2-3.1-5.2-3.5s1-2 5.2-3.5c4.3-1.5 10-2.4 16.1-2.4s11.8.8 16.1 2.4c4.3 1.5 5.2 3.1 5.2 3.5s-1 2-5.2 3.5C43.8 56.1 38.1 57 32 57s-11.8-.9-16.1-2.4" />
  </svg>
);
export default IconZen;
