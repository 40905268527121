import * as React from "react";
const IconLike3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M22.4 26.5c0-.6-.4-1-1-1H10c-.6 0-1 .4-1 1s.4 1 1 1h11.4c.5 0 1-.5 1-1M54 25.5H42.6c-.6 0-1 .4-1 1s.4 1 1 1H54c.6 0 1-.4 1-1s-.4-1-1-1M31.9 15.4c.6 0 1-.4 1-1V3c0-.6-.4-1-1-1s-1 .4-1 1v11.4c0 .6.4 1 1 1M40.5 19.6c.3 0 .5-.1.7-.3l8.1-8.1c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-8.1 8.1c-.4.4-.4 1 0 1.4.2.2.5.3.7.3M22.8 19.3c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-8.1-8.1c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4zM49.1 36.2c-1.2-1-2.6-1.5-4.1-1.5h-7.3v-5c0-2.9-.9-5-2.6-6.2-1.1-.8-2.4-1.2-4-1.2-1.4 0-2.5.4-2.5.4-.8.2-1.4 1-1.4 1.9v6.7c0 3.5-3.2 5.3-4.8 5.9v-.3c0-.6-.4-1-1-1H15c-2.2 0-4 1.8-4 4V58c0 2.2 1.8 4 4 4h6.4c.6 0 1-.4 1-1v-.2c.9.5 1.9.7 2.9.7h16.5c4.1 0 6.9-2.3 7.4-6.1l2.1-13.3c.3-2.2-.5-4.5-2.2-5.9M20.3 60h-5.4c-1.1 0-2-.9-2-2V39.8c0-1.1.9-2 2-2h5.4zm29-18.2-2.1 13.3c-.3 2.8-2.3 4.4-5.4 4.4H25.3c-1.1 0-2.1-.4-2.9-1.2V39.2c1.3-.5 6.8-2.7 6.8-8.1v-6.7s.8-.3 1.9-.3 2.1.3 2.8.8c1.2.9 1.8 2.4 1.8 4.6v6c0 .6.4 1 1 1H45c1 0 2 .4 2.8 1 1.2 1.2 1.7 2.8 1.5 4.3" />
  </svg>
);
export default IconLike3;
