import * as React from "react";
const IconBookmarkAltCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.3 23.1H27.7v-8.5c0-2.6-2.1-4.7-4.7-4.7h-8.5c-2.6 0-4.7 2.1-4.7 4.7v8.5H6.7c-2.6 0-4.7 2.1-4.7 4.7v21.7c0 2.6 2.1 4.7 4.7 4.7h50.7c2.6 0 4.7-2.1 4.7-4.7V27.7c-.1-2.5-2.2-4.6-4.8-4.6m-45.4-8.5c0-1.5 1.2-2.7 2.7-2.7h8.5c1.5 0 2.7 1.2 2.7 2.7v17.3c0 .2-.1.3-.2.3s-.2.1-.3 0l-4.6-3.6-.1-.1c-1-.7-2.4-.7-3.4 0l-4.7 3.7c-.1.1-.3.1-.3 0-.1 0-.2-.1-.2-.3V14.6zM60 49.4c0 1.5-1.2 2.7-2.7 2.7H6.7c-1.5 0-2.7-1.2-2.7-2.7V27.7C4 26.2 5.2 25 6.7 25H10v6.8c0 .9.5 1.7 1.3 2.1.3.2.7.2 1 .2.5 0 1-.2 1.4-.5l4.6-3.7c.3-.2.8-.2 1.1 0l4.6 3.6c.7.6 1.6.7 2.4.3s1.3-1.2 1.3-2.1V25h29.6c1.5 0 2.7 1.2 2.7 2.7z" />
  </svg>
);
export default IconBookmarkAltCopy;
