import * as React from "react";
const IconBriefcase = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56 13.2H44.8V7.7c0-2.9-2.4-5.3-5.3-5.3H24.4c-2.9 0-5.3 2.4-5.3 5.3v5.5H8c-3.4 0-6.3 2.8-6.3 6.3v35.8c0 3.4 2.8 6.3 6.3 6.3h48c3.4 0 6.3-2.8 6.3-6.3V19.5c-.1-3.5-2.9-6.3-6.3-6.3M23.7 7.7c0-.4.3-.7.7-.7h15.1c.4 0 .8.3.8.8v5.5H23.7zm34 47.6c0 1-.8 1.8-1.8 1.8H8c-1 0-1.8-.8-1.8-1.8V19.5c0-1 .8-1.8 1.8-1.8h48c1 0 1.8.8 1.8 1.8v35.8z" />
  </svg>
);
export default IconBriefcase;
