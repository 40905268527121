import * as React from "react";
const IconChatAlt12 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M18.8 18.7h18.8c.6 0 1-.4 1-1s-.4-1-1-1H18.8c-.6 0-1 .4-1 1s.5 1 1 1M45.2 25.8H18.8c-.6 0-1 .4-1 1s.4 1 1 1h26.3c.6 0 1-.4 1-1s-.4-1-.9-1M32 34.9H18.8c-.6 0-1 .4-1 1s.4 1 1 1H32c.6 0 1-.4 1-1s-.4-1-1-1" />
    <path d="M57.1 7.7H6.9C4.2 7.7 2 9.9 2 12.6v28.9c0 2.7 2.2 4.9 4.9 4.9h15.5l8.7 9.6c.2.2.5.3.7.3.3 0 .5-.1.7-.3l8.9-9.6H57c2.7 0 4.9-2.2 4.9-4.9V12.6c.1-2.7-2.1-4.9-4.8-4.9M60 41.5c0 1.6-1.3 2.9-2.9 2.9H41c-.3 0-.5.1-.7.3l-8.4 9.1-8.2-9.1c-.2-.2-.5-.3-.7-.3H7c-1.6 0-2.9-1.3-2.9-2.9V12.6C4 11 5.3 9.7 6.9 9.7h50.2c1.6 0 2.9 1.3 2.9 2.9z" />
  </svg>
);
export default IconChatAlt12;
