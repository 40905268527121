import * as React from "react";
const IconCrossCountryMotorcycle = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M28 10a1 1 0 0 1 .907.58L30.492 14h5.133a1 1 0 0 1 .753.341l4.375 5A1 1 0 0 1 40 21h-6.264l1.97 4.25a6.5 6.5 0 1 1-1.814.842l-1.493-3.22-3.235 2.875a1 1 0 0 1-.164.119V30a3 3 0 0 1-3 3h-4a3 3 0 0 1-1.659-.5h-3.417A6.502 6.502 0 0 1 4 31.5a6.5 6.5 0 0 1 12.924-1h2.117A3 3 0 0 1 19 30v-3c0-.566.157-1.095.429-1.546l-.35-.492-14.182-1.467A1 1 0 0 1 4 22.5V19a1 1 0 0 1 1-1h10.6l.08-.031 4.42-1.495A9 9 0 0 1 22.982 16H29v-.464L27.361 12H23v-2zm3.42 6 1.39 3h4.986l-2.625-3zM21 27a1 1 0 0 1 1-1h5v4a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1zm10.521-6.023L28.12 24h-7.271l-3.254-4.567 3.145-1.064A7 7 0 0 1 22.983 18h7.159zM17.532 22.79 15.544 20H6v1.598zm17.216 5.148a4.5 4.5 0 1 0 1.814-.841l1.845 3.982a1 1 0 0 1-1.814.84zM10.5 36a4.5 4.5 0 1 1 4.389-5.5H10.5a1 1 0 1 0 0 2h4.389A4.5 4.5 0 0 1 10.5 36"
      clipRule="evenodd"
    />
  </svg>
);
export default IconCrossCountryMotorcycle;
