import * as React from "react";
const IconGhost = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 3.8C18.8 3.8 8.1 14.5 8.1 27.7v28.9c0 .4.2.8.6.9l7.2 3.1c1.8.8 3.7 1.2 5.6 1.2 2.1 0 4.1-.5 6-1.4l.5-.3c3.3-1.6 7-1.6 10.3 0 4 1.9 8.7 1.8 12.5-.3l4.6-2.4c.3-.2.5-.5.5-.9V27.7C55.9 14.5 45.2 3.8 32 3.8M53.9 56l-4 2.2c-3.3 1.8-7.3 1.9-10.7.2-3.8-1.8-8.2-1.8-12 0l-.5.3c-3.1 1.5-6.7 1.6-9.9.2L10.2 56V27.7c0-12.1 9.8-21.9 21.9-21.9S54 15.6 54 27.7V56z" />
    <path d="M28.7 24.8c0-2.4-2-4.4-4.4-4.4s-4.4 2-4.4 4.4 2 4.4 4.4 4.4 4.4-1.9 4.4-4.4m-4.4 2.5c-1.3 0-2.4-1.1-2.4-2.4s1.1-2.4 2.4-2.4 2.4 1.1 2.4 2.4-1.1 2.4-2.4 2.4M39.7 20.4c-2.4 0-4.4 2-4.4 4.4s2 4.4 4.4 4.4 4.4-2 4.4-4.4-2-4.4-4.4-4.4m0 6.9c-1.3 0-2.4-1.1-2.4-2.4s1.1-2.4 2.4-2.4 2.4 1.1 2.4 2.4-1.1 2.4-2.4 2.4M32 34.7c-3.4 0-6.1 1.9-6.1 4.4s2.7 4.4 6.1 4.4 6.1-1.9 6.1-4.4-2.7-4.4-6.1-4.4m0 6.8c-2.2 0-4.1-1.1-4.1-2.4s1.9-2.4 4.1-2.4 4.1 1.1 4.1 2.4-1.9 2.4-4.1 2.4" />
  </svg>
);
export default IconGhost;
