import * as React from "react";
const IconApartmentsAlt8 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.2 34.2H36.9V6c0-2.2-1.8-4-4-4H26C12.6 2 1.8 12.9 1.8 26.3V58c0 2.2 1.8 4 4 4h52.5c2.2 0 4-1.8 4-4V38.2c-.1-2.3-1.9-4-4.1-4M34.9 60H5.8c-1.1 0-2-.9-2-2V26.3C3.8 14 13.7 4 26 4h6.8c1.1 0 2 .9 2 2v54zm10.9 0v-6.7c0-1.8 1.5-3.3 3.3-3.3s3.3 1.5 3.3 3.3V60zm14.4-2c0 1.1-.9 2-2 2h-4v-6.7c0-2.9-2.4-5.3-5.3-5.3s-5.3 2.4-5.3 5.3V60h-6.9V36.2h21.4c1.1 0 2 .9 2 2V58z" />
    <path d="M21 21.2h-3.2c-.6 0-1 .4-1 1s.4 1 1 1H21c.6 0 1-.4 1-1s-.4-1-1-1M21 33.8h-3.2c-.6 0-1 .4-1 1s.4 1 1 1H21c.6 0 1-.4 1-1s-.4-1-1-1M21 46.4h-3.2c-.6 0-1 .4-1 1s.4 1 1 1H21c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconApartmentsAlt8;
