import React, { useEffect } from 'react';
import BringzzLogo from 'assets/BringzzLogo.png';
import BringzzLogoMini from 'assets/BringzzLogoMini.png';
import { useNavigation } from 'context/NavigationContext';

const SplashPage = () => {
	const { navigate } = useNavigation();

	useEffect(() => {
		const timer = setTimeout(() => {
			navigate('/onboarding');
		}, 2000);

		return () => clearTimeout(timer);
	}, [navigate]);

	return (
		<div className='h-screen bg-midnight-blue flex justify-center items-center'>
		</div>
	);
};

export default SplashPage;
