import * as React from "react";
const IconGlassesAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.8 30.4 47.7 13.1c-.2-.2-.5-.4-.8-.4s-.6.1-.8.3l-4.9 5.8c-.4.4-.3 1.1.1 1.4.4.4 1 .3 1.4-.1l4.2-4.9 12 14.8H5.1l12-14.8 4.2 4.9c.4.4 1 .5 1.4.1s.5-1 .1-1.4L17.9 13c-.2-.2-.4-.4-.8-.4-.3 0-.6.1-.8.4L2.2 30.4c-.2.3-.3.7-.1 1.1.1.3.4.5.7.5l1.5 18.4c0 .5.5.9 1 .9h19.5c.5 0 1-.4 1-.9l1.5-18.3h9.3l1.6 18.4c0 .5.5.9 1 .9h19.5c.5 0 1-.4 1-.9L61.2 32c.3-.1.6-.3.7-.5.2-.4.1-.8-.1-1.1M23.9 49.3H6.3L4.8 32.1h20.5zm33.8 0H40.1L38.6 32h20.6z" />
  </svg>
);
export default IconGlassesAlt1;
