import * as React from "react";
const IconDoors = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 9.3H6c-2.2 0-4 1.8-4 4v37.4c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V13.3c0-2.2-1.8-4-4-4m-19.5 2v41.4H25.4V11.3zM4 50.7V13.3c0-1.1.9-2 2-2h17.4v41.4H6c-1.1 0-2-.9-2-2m56 0c0 1.1-.9 2-2 2H40.5V11.3H58c1.1 0 2 .9 2 2z" />
    <path d="M17.4 29.7c-.6 0-1 .4-1 1v2.7c0 .6.4 1 1 1s1-.4 1-1v-2.7c0-.6-.4-1-1-1M46.6 29.7c-.6 0-1 .4-1 1v2.7c0 .6.4 1 1 1s1-.4 1-1v-2.7c0-.6-.5-1-1-1" />
  </svg>
);
export default IconDoors;
