import * as React from "react";
const IconTextAlignCenter = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M11.6 8.9h40.7c1.2 0 2.3-1 2.3-2.3s-1-2.3-2.3-2.3H11.6c-1.2 0-2.3 1-2.3 2.3s1.1 2.3 2.3 2.3M7.8 25.8h48.4c1.2 0 2.3-1 2.3-2.3s-1-2.3-2.3-2.3H7.8c-1.2 0-2.3 1-2.3 2.3s1.1 2.3 2.3 2.3M15.5 38.2c-1.2 0-2.3 1-2.3 2.3s1 2.3 2.3 2.3h33.1c1.2 0 2.3-1 2.3-2.3s-1-2.3-2.3-2.3zM60 55.1H4c-1.2 0-2.3 1-2.3 2.3s1 2.3 2.3 2.3h56c1.2 0 2.3-1 2.3-2.3s-1.1-2.3-2.3-2.3" />
  </svg>
);
export default IconTextAlignCenter;
