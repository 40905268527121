import * as React from "react";
const IconHospitalSign = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 10H24c-2.8 0-5 2.2-5 5v4.8L4 21.5v-9.9c0-.6-.4-1-1-1s-1 .4-1 1v40.7c0 .6.4 1 1 1s1-.4 1-1v-9.9l15 1.8V49c0 2.8 2.2 5 5 5h33c2.8 0 5-2.2 5-5V15c0-2.8-2.2-5-5-5M4 40.4V23.6l15-1.8v20.5zM60 49c0 1.7-1.3 3-3 3H24c-1.7 0-3-1.3-3-3V15c0-1.7 1.3-3 3-3h33c1.7 0 3 1.3 3 3z" />
    <path d="M49.2 31h-7.7v-7.7c0-.6-.4-1-1-1s-1 .4-1 1V31h-7.7c-.6 0-1 .4-1 1s.4 1 1 1h7.7v7.7c0 .6.4 1 1 1s1-.4 1-1V33h7.7c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconHospitalSign;
