import * as React from "react";
const IconJellyfish = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.2 31.6c-.6 0-1 .4-1 1v10.8c0 2-1.6 3.6-3.6 3.6S47 45.4 47 43.4V14.2C47.1 7.5 41.6 2 34.9 2h-5.8c-6.7 0-12.2 5.5-12.2 12.2v29.2c0 2-1.6 3.6-3.6 3.6s-3.6-1.6-3.6-3.6V32.6c0-.6-.4-1-1-1s-1 .4-1 1v10.8c0 3.1 2.5 5.6 5.6 5.6s5.6-2.5 5.6-5.6v-6.3H24v11.3l-4.8 12.3c-.2.5.1 1.1.6 1.3.1 0 .2.1.4.1.4 0 .8-.2.9-.6L26 48.9V37.1h5V61c0 .6.4 1 1 1s1-.4 1-1V37.1h5.5v11.7l4.9 12.6q.3.6.9.6c.1 0 .2 0 .4-.1.5-.2.8-.8.6-1.3l-4.8-12.3V37.1h4.6v6.3c0 3.1 2.5 5.6 5.6 5.6s5.6-2.5 5.6-5.6V32.6c-.1-.5-.5-1-1.1-1m-36.3 3.5V14.2C18.9 8.6 23.5 4 29.1 4h5.8c5.6 0 10.2 4.6 10.2 10.2v20.9z" />
  </svg>
);
export default IconJellyfish;
