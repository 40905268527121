import * as React from "react";
const IconSymptom = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M24 13a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9m2.5-4.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0M12 21h2.354a3.99 3.99 0 0 0 3.146.97v6.944999999999999a5 5 0 0 1-.093.407 21 21 0 0 1-.445 1.415 66 66 0 0 1-.997 2.658 4 4 0 0 0-.957 2.342 189 189 0 0 1-1.557 3.613c-.281.634-.48 1.407-.371 2.183.115.826.57 1.579 1.43 2.071a3 3 0 0 0 3.938-.87l1.5-2.118q.061-.085.116-.177l.446-.734a4 4 0 0 0 2.473-4.073l1.267-2.087 4.186 6.894q.054.09.116.177l1.5 2.118a3 3 0 0 0 3.938.87c.86-.492 1.315-1.245 1.43-2.071.108-.776-.09-1.549-.371-2.183a194 194 0 0 1-2.022-4.736 80 80 0 0 1-1.489-3.877 21 21 0 0 1-.445-1.415l-.016-.064V26.19A4.5 4.5 0 0 0 33 22.5c0-.526-.09-1.03-.256-1.5H36a3 3 0 1 0 0-6H19.646A3.99 3.99 0 0 0 17 14c-1.014 0-1.94.378-2.646 1H12a3 3 0 1 0 0 6m1.126-4H12a1 1 0 1 0 0 2h1.126a4 4 0 0 1 0-2m6.374 8.993v-4.87A3.99 3.99 0 0 0 20.874 17H36a1 1 0 1 1 0 2h-4.671a4.5 4.5 0 1 0-2.252 7.963v2.417a1 1 0 0 0 .027.232l.056.222c.114.431.281.96.488 1.558a81 81 0 0 0 1.527 3.98 180 180 0 0 0 2.046 4.789c.192.432.257.816.218 1.096-.032.23-.134.435-.443.611a1 1 0 0 1-1.312-.29l-1.5-2.118-.039-.059-5.04-8.302a1 1 0 0 0-1.71 0l-1.38 2.273a3.99 3.99 0 0 0-3.385-1.355q.117-.321.222-.625c.207-.598.374-1.127.488-1.558a6 6 0 0 0 .14-.639A1 1 0 0 0 19.5 29v-3.007m-3.714 13.014.176-.405a4 4 0 0 0 2.058 1.277l-1.204 1.699a1 1 0 0 1-1.313.29c-.308-.176-.41-.38-.442-.61-.04-.281.026-.665.218-1.097.123-.276.297-.672.507-1.154M17 20a2 2 0 1 0 0-4 2 2 0 0 0 0 4m11.5 5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5M19 38a2 2 0 1 0 0-4 2 2 0 0 0 0 4"
      clipRule="evenodd"
    />
    <path
      
      d="M29.931 7C36.958 9.452 42 16.137 42 24a17.96 17.96 0 0 1-6.404 13.768l1.258 1.555C41.223 35.654 44 30.151 44 24c0-8.736-5.601-16.164-13.408-18.888zM27.398 41.68a18.158 18.158 0 0 1-5.963.139l-.278 1.98a20.2 20.2 0 0 0 6.6-.151zM6 24c0 5.777 2.722 10.92 6.953 14.213L11.75 39.81C7.035 36.152 4 30.43 4 24 4 15.264 9.601 7.836 17.408 5.112L18.068 7C11.043 9.452 6 16.137 6 24"
    />
  </svg>
);
export default IconSymptom;
