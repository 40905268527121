import React, { useEffect, useState } from 'react';
import {
	BringzzPageContainer,
	BringzzIcon,
	BringzzText,
	BringzzImage,
	BringzzPostingCard,
	BringzzButton,
	BringzzModal,
	BringzzBottomDrawer,
	BringzzRotatingSelect,
	BringzzDate,
	Mode
} from '@bringzz/components';
import { useNavigation } from 'context/NavigationContext';
import { useLocation } from 'react-router-dom';
import useRequest from 'hooks/useRequest';
import AvatarExample from 'assets/images/AvatarExample.png';
import PostExample1 from 'assets/images/PostExample1.png';

import useUser from 'hooks/useUser';
function useQuery() {
	return new URLSearchParams(useLocation().search);
}
let state = {
	checked: <div></div>,
	unChecked: <div></div>
};
const PostPreview = () => {
	const { backPress } = useNavigation();
	let query = useQuery();
	const { sendRequest } = useRequest();
	const { getUser } = useUser();
	const { navigate } = useNavigation();
	let id = query.get('id');
	const [post, setPost] = useState(null);
	const [modalOpen, setModalOpen] = useState(false);
	const [currentHours, setCurrentHours] = useState(null);
	const [currentMinutes, setCurrentMinutes] = useState(null);
	const [selectingTime, setSelectingTime] = useState(false);
	const [scheduledTime, setScheduledTime] = useState(null);
	const postsArray = [
		{
			uuid: '1',
			type: 3,
			headline: 'In elit diam tellus neque diam mattis nam. Tristique eleitend euismod est.',
			description: 'nutun laue ous phareud ac nund ulancorper door vesticuun pellentesque. Inter dum lorem st ulamcorper dienissim consequatpharetra id cact lacus. Ad piscna.',
			content: 'string',
			release: new Date(),
			likesHidden: false,
			viewsHidden: false,
			commentsHidden: false,
			PostImg: '',
			PostImgAlt: '',
			validFrom: new Date(), // -> for type 1
			validTilpost: new Date(), // -> for type 1
			products: [], // -> for type 1
			link: 'string', // -> for type 2
			img_caption: 'string' // -> for type 3
		},
		{
			uuid: '2',
			type: 3,
			headline: 'In elit diam tellus neque diam mattis nam. Tristique eleitend euismod est.',
			description: 'nutun laue ous phareud ac nund ulancorper door vesticuun pellentesque. Inter dum lorem st ulamcorper dienissim consequatpharetra id cact lacus. Ad piscna.',
			content: 'string',
			release: new Date(),
			likesHidden: false,
			viewsHidden: false,
			commentsHidden: false,
			PostImg: '',
			PostImgAlt: '',
			validFrom: new Date(), // -> for type 1
			validTilpost: new Date(), // -> for type 1
			products: [], // -> for type 1
			link: 'string', // -> for type 2
			img_caption: 'string' // -> for type 3
		},
		{
			uuid: '3',
			type: 3,
			headline: 'In elit diam tellus neque diam mattis nam. Tristique eleitend euismod est.',
			description: 'nutun laue ous phareud ac nund ulancorper door vesticuun pellentesque. Inter dum lorem st ulamcorper dienissim consequatpharetra id cact lacus. Ad piscna.',
			content: 'string',
			release: new Date(),
			likesHidden: false,
			viewsHidden: false,
			commentsHidden: false,
			PostImg: '',
			PostImgAlt: '',
			validFrom: new Date(), // -> for type 1
			validTilpost: new Date(), // -> for type 1
			products: [], // -> for type 1
			link: 'string', // -> for type 2
			img_caption: 'string' // -> for type 3
		},
		{
			uuid: '4',
			type: 2,
			headline: 'In elit diam tellus neque diam mattis nam. Tristique eleitend euismod est.',
			description: 'nutun laue ous phareud ac nund ulancorper door vesticuun pellentesque. Inter dum lorem st ulamcorper dienissim consequatpharetra id cact lacus. Ad piscna.',
			content: 'string',
			release: new Date(),
			likesHidden: false,
			viewsHidden: false,
			commentsHidden: false,
			PostImg: '',
			PostImgAlt: '',
			validFrom: new Date(), // -> for type 1
			validTilpost: new Date(), // -> for type 1
			products: [], // -> for type 1
			link: 'string', // -> for type 2
			img_caption: 'string' // -> for type 3
		},
		{
			uuid: '5',
			type: 2,
			headline: 'In elit diam tellus neque diam mattis nam. Tristique eleitend euismod est.',
			description: 'nutun laue ous phareud ac nund ulancorper door vesticuun pellentesque. Inter dum lorem st ulamcorper dienissim consequatpharetra id cact lacus. Ad piscna.',
			content: 'string',
			release: new Date(),
			likesHidden: false,
			viewsHidden: false,
			commentsHidden: false,
			PostImg: '',
			PostImgAlt: '',
			validFrom: new Date(), // -> for type 1
			validTilpost: new Date(), // -> for type 1
			products: [], // -> for type 1
			link: 'string', // -> for type 2
			img_caption: 'string' // -> for type 3
		},
		{
			uuid: '6',
			type: 2,
			headline: 'In elit diam tellus neque diam mattis nam. Tristique eleitend euismod est.',
			description: 'nutun laue ous phareud ac nund ulancorper door vesticuun pellentesque. Inter dum lorem st ulamcorper dienissim consequatpharetra id cact lacus. Ad piscna.',
			content: 'string',
			release: new Date(),
			likesHidden: false,
			viewsHidden: false,
			commentsHidden: false,
			PostImg: '',
			PostImgAlt: '',
			validFrom: new Date(), // -> for type 1
			validTilpost: new Date(), // -> for type 1
			products: [], // -> for type 1
			link: 'string', // -> for type 2
			img_caption: 'string' // -> for type 3
		},
		{
			uuid: '7',
			type: 1,
			headline: 'In elit diam tellus neque diam mattis nam. Tristique eleitend euismod est.',
			description: 'nutun laue ous phareud ac nund ulancorper door vesticuun pellentesque. Inter dum lorem st ulamcorper dienissim consequatpharetra id cact lacus. Ad piscna.',
			content: 'string',
			release: new Date(),
			likesHidden: false,
			viewsHidden: false,
			commentsHidden: false,
			PostImg: '',
			PostImgAlt: '',
			validFrom: new Date(), // -> for type 1
			validTilpost: new Date(), // -> for type 1
			products: [], // -> for type 1
			link: 'string', // -> for type 2
			img_caption: 'string' // -> for type 3
		},
		{
			uuid: '8',
			type: 1,
			headline: 'In elit diam tellus neque diam mattis nam. Tristique eleitend euismod est.',
			description: 'nutun laue ous phareud ac nund ulancorper door vesticuun pellentesque. Inter dum lorem st ulamcorper dienissim consequatpharetra id cact lacus. Ad piscna.',
			content: 'string',
			release: new Date(),
			likesHidden: false,
			viewsHidden: false,
			commentsHidden: false,
			PostImg: '',
			PostImgAlt: '',
			validFrom: new Date(), // -> for type 1
			validTilpost: new Date(), // -> for type 1
			products: [], // -> for type 1
			link: 'string', // -> for type 2
			img_caption: 'string' // -> for type 3
		},
		{
			uuid: '9',
			type: 1,
			headline: 'In elit diam tellus neque diam mattis nam. Tristique eleitend euismod est.',
			description: 'nutun laue ous phareud ac nund ulancorper door vesticuun pellentesque. Inter dum lorem st ulamcorper dienissim consequatpharetra id cact lacus. Ad piscna.',
			content: 'string',
			release: new Date(),
			likesHidden: false,
			viewsHidden: false,
			commentsHidden: false,
			PostImg: '',
			PostImgAlt: '',
			validFrom: new Date(), // -> for type 1
			validTilpost: new Date(), // -> for type 1
			products: [], // -> for type 1
			link: 'string', // -> for type 2
			img_caption: 'string' // -> for type 3
		}
	]

	useEffect(() => {
		if (id) {
			postsArray.map(post => {
				if (post.uuid == id) {
					setPost(post);
				}
			});
		}
	}, [id]);

	const hoursOptions = [
		{ label: '00', value: 0 },
		{ label: '1', value: 1 },
		{ label: '2', value: 2 },
		{ label: '3', value: 3 },
		{ label: '4', value: 4 },
		{ label: '5', value: 5 },
		{ label: '6', value: 6 },
		{ label: '7', value: 7 },
		{ label: '8', value: 8 },
		{ label: '9', value: 9 },
		{ label: '10', value: 10 },
		{ label: '11', value: 11 },
		{ label: '12', value: 12 },
		{ label: '13', value: 13 },
		{ label: '14', value: 14 },
		{ label: '15', value: 15 },
		{ label: '16', value: 16 },
		{ label: '17', value: 17 },
		{ label: '18', value: 18 },
		{ label: '19', value: 19 },
		{ label: '20', value: 20 },
		{ label: '21', value: 21 },
		{ label: '22', value: 22 },
		{ label: '23', value: 23 },
		{ label: '24', value: 24 }
	];

	const changeHours = result => {
		setCurrentHours(result.value);
	};
	const changeMinutes = result => {
		setCurrentMinutes(result.value);
	};
	const closeTimeSelection = () => {
		const time = `${currentHours}:${currentMinutes.toString().padStart(2, '0')}`;
		setScheduledTime(time);
		setSelectingTime(false);
	};
	const generateMinutesOptions = () => {
		let array = [];
		for (let index = 0; index < 60; index++) {
			array.push({ value: index, label: index.toString().padStart(2, '0') });
		}
		return array;
	};
	const minutesOptions = generateMinutesOptions();
	return (
		<BringzzPageContainer
			header={{
				left: (
					<BringzzIcon
						size='22'
						icon='IconChevronLeft'
						folder='LineIcons'
						className='cursor-pointer'
						onClick={backPress}
					/>
				),

				title: <BringzzText tag='h2'>Published Post</BringzzText>
			}}
			footer={
				<div className='pb-28 px-6 flex flex-row space-x-2'>
					<BringzzButton
						onClick={() => {
							navigate(`/settings/posts/success?id=${post.uuid}`)
						}}
						size='md'
						className='bg-magic-lilac w-full flex justify-center text-midnight-blue'

					>
						Share {scheduledTime ? `| ${scheduledTime}` : ''}
					</BringzzButton>
					<div className='flex space-x-1'>
						<BringzzIcon
							folder='LineIcons'
							className='bg-magic-lilac text-midnight-blue p-2 rounded-full'
							size='20'
							icon='IconClockAlt1'
							onClick={() => setSelectingTime(true)}
						/>
						{/* <BringzzDate
							// placeholder={validFrom ? validFrom : "06/05/2024"}
							format="dd/MM/yyyy"
							// onChange={(newDate) => setValidFrom(newDate)}
							mode={Mode.DAY}
							className=''
							minDate={new Date()}
						></BringzzDate> */}
					</div>
				</div>
			}
		>
			<div className='p-4 space-y-4'>
				<BringzzPostingCard
					avatar={AvatarExample}
					name='Apotheke Mustermann'
					header={
						<BringzzImage
							className='w-full h-auto'
							src={PostExample1}
							alt='post image'
						></BringzzImage>
					}
					likesCount={1200}
					commentsCount={40}
					title='Fall time is cold time: How to prepare for the cold season'
					description='Turpis rutrum amet vitae ac integer et pharetra gravida. Praesent
					laoreet amet Dui amet felis gravida convallis nisi suspendisse sed.'
					action={{
						label: 'View products',
						icon: 'IconEbook',
						iconFolder: 'LineIcons',
						onClick: () => console.log('text')
					}}
				></BringzzPostingCard>
			</div>
			{modalOpen && (
				<BringzzModal
					title={'Delete this post?'}
					description={`Sed pellentesque porttitor ipsum non libero lacinia. Fermentum aliquam nibh in.`}
					onCancel={() => setModalOpen(false)}
					onConfirm={async () => {
						await sendRequest({
							method: 'DELETE',
							url: `/settings/posts/detail`,
							data: {
								uuid: post.id
							}
						})
							.then(response => {
								if (response.status === 'success')
									navigate(`/settings/posts`, false);
							})
							.catch(error => {
								console.log(error);
							});
					}}
					confirmButtonText='Delete'
				/>
			)}
			<BringzzBottomDrawer
				isOpen={selectingTime}
				close={closeTimeSelection}
				title='Schedule Post'
			>
				<div className='flex items-center relative gap-x-4 justify-center'>
					<div className='h-12 absolute top-1/2 right-0 w-full border-y -translate-y-1/2'></div>
					<div>
						<BringzzRotatingSelect
							options={hoursOptions}
							onSelect={changeHours}
						/>
					</div>
					<div>
						<BringzzRotatingSelect
							options={minutesOptions}
							onSelect={changeMinutes}
						/>
					</div>
				</div>

				<div className='flex justify-center items-center py-6'>
					<BringzzButton
						size='lg'

						className='bg-magic-lilac '
						onClick={closeTimeSelection}
					>
						Apply
					</BringzzButton>
				</div>
			</BringzzBottomDrawer>
		</BringzzPageContainer>

	);
};

export default PostPreview;
