import * as React from "react";
const IconTreesAlt6 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.3 10.7c-3.6 0-6.8 1.8-8.8 4.6v-.6C42.6 8.7 37.8 4 31.9 4S21.3 8.7 21.3 14.6v6.7c-1.9-2.7-5.1-4.5-8.6-4.5C6.8 16.8 2 21.6 2 27.5v10.8c0 5.5 4.2 10.1 9.6 10.6V59c0 .6.4 1 1 1s1-.4 1-1V48.9c3.8-.4 7.1-2.8 8.6-6.1 1.6 3.4 4.8 5.8 8.6 6.1V59c0 .6.4 1 1 1s1-.4 1-1V48.9c3.9-.4 7.2-2.8 8.7-6.2 1.5 3.4 4.8 5.9 8.7 6.2V59c0 .6.4 1 1 1s1-.4 1-1V48.9c5.4-.5 9.7-5.1 9.7-10.6v-17c.1-5.9-4.7-10.6-10.6-10.6M13.6 46.9v-9.7c0-.6-.4-1-1-1s-1 .4-1 1v9.7C7.4 46.4 4 42.7 4 38.3V27.5c0-4.8 3.9-8.6 8.6-8.6s8.6 3.9 8.6 8.6v10.8c.1 4.4-3.3 8.1-7.6 8.6m27-8.6c0 4.4-3.4 8.1-7.7 8.6v-9.7c0-.6-.4-1-1-1s-1 .4-1 1v9.7c-4.3-.5-7.6-4.2-7.6-8.6V14.6c0-4.8 3.9-8.6 8.6-8.6 4.8 0 8.6 3.9 8.6 8.6v23.7zm19.4 0c0 4.4-3.4 8.1-7.7 8.6v-9.7c0-.6-.4-1-1-1s-1 .4-1 1v9.7c-4.3-.5-7.6-4.2-7.6-8.6v-17c0-4.8 3.9-8.6 8.6-8.6s8.7 3.9 8.7 8.6z" />
  </svg>
);
export default IconTreesAlt6;
