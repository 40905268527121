import * as React from "react";
const IconFactoryAlt4 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.1 12.6c-1.5-1.1-3.5-1.3-5.3-.6l-.3.1V3c0-.6-.4-1-1-1s-1 .4-1 1v9.3c0 .2.1.3.2.5l-8.6 3.3c-.3-1.4-1.1-2.7-2.3-3.5-1.5-1.1-3.5-1.3-5.3-.6l-10.8 4.2c-.3-1.4-1.1-2.7-2.3-3.5-1.6-1.1-3.5-1.3-5.3-.6l-9 3.5c-2.2.8-3.7 3-3.7 5.3v35.5c0 3.1 2.6 5.7 5.7 5.7h45.8c3.1 0 5.7-2.6 5.7-5.7v-39c0-1.9-.9-3.7-2.5-4.8M5.4 56.3V20.8c0-1.5 1-2.9 2.4-3.5l9-3.5c.4-.2.9-.3 1.3-.3.7 0 1.5.2 2.1.7 1 .7 1.6 1.8 1.6 3.1V60H9.1c-2.1 0-3.7-1.7-3.7-3.7m18.4-38 11.4-4.4c1.2-.4 2.4-.3 3.4.4s1.6 1.8 1.6 3.1V60H23.8zm34.8 38c0 2-1.7 3.7-3.7 3.7H42.2V18.3l11.4-4.4c1.2-.4 2.4-.3 3.4.4s1.6 1.8 1.6 3.1z" />
  </svg>
);
export default IconFactoryAlt4;
