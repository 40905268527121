import * as React from "react";
const IconBuildingsAlt3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m60.5 7.8-.4-.3C52 .1 39.7.1 31.6 7.5l-.4.3c-.1.2-.2.5-.2.8v18.2H7.6c-2.5 0-4.5 2-4.5 4.5v26.2c0 2.5 2 4.5 4.5 4.5h47.9c3 0 5.4-2.4 5.4-5.4v-48c0-.3-.2-.6-.4-.8M5.1 57.5V31.3c0-1.4 1.1-2.5 2.5-2.5H31V60H7.6c-1.3 0-2.5-1.1-2.5-2.5M42.3 60v-7.6c0-2.2 1.8-3.9 3.9-3.9 2.2 0 3.9 1.8 3.9 3.9V60zm16.6-3.4c0 1.9-1.5 3.4-3.4 3.4h-3.4v-7.6c0-3.3-2.7-5.9-5.9-5.9-3.3 0-5.9 2.7-5.9 5.9V60H33V9c7.4-6.7 18.4-6.7 25.8 0v47.6z" />
  </svg>
);
export default IconBuildingsAlt3;
