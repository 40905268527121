import * as React from "react";
const IconIExamQualification = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      d="M15 32a1 1 0 1 0 0 2h18a1 1 0 1 0 0-2zM14 37a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2H15a1 1 0 0 1-1-1"
    />
    <path
      
      fillRule="evenodd"
      d="M20.923 15.615a1 1 0 0 0-1.846 0l-3.742 8.98-.017.042-1.241 2.978a1 1 0 0 0 1.846.77L16.917 26h6.166l.994 2.385a1 1 0 0 0 1.846-.77l-1.241-2.978-.017-.042zM20 18.6l2.25 5.4h-4.5z"
      clipRule="evenodd"
    />
    <path
      
      d="M30 21a1 1 0 0 1 1 1v2h2a1 1 0 1 1 0 2h-2v2a1 1 0 1 1-2 0v-2h-2a1 1 0 1 1 0-2h2v-2a1 1 0 0 1 1-1"
    />
    <path
      
      fillRule="evenodd"
      d="M38 15 28 4H14a4 4 0 0 0-4 4v32a4 4 0 0 0 4 4h20a4 4 0 0 0 4-4zm-11 0a1 1 0 0 0 1 1h8v24a2 2 0 0 1-2 2H14a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h13zm7.388-1L29 8.073V14z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconIExamQualification;
