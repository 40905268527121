import * as React from "react";
const IconNfc = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M26.5 52.7h-8.6c-.6 0-1 .4-1 1s.4 1 1 1h8.6c.6 0 1-.4 1-1s-.4-1-1-1M21.4 11.3H23c.6 0 1-.4 1-1s-.4-1-1-1h-1.6c-.6 0-1 .4-1 1s.4 1 1 1M30.2 32.4c-4.9-2.9-11-3-16-.4-.5.3-.7.9-.4 1.3.3.5.9.7 1.3.4 4.4-2.3 9.8-2.2 14.1.4.2.1.3.1.5.1.3 0 .7-.2.9-.5.3-.4.1-1-.4-1.3M17 39.2c-.5.3-.6.9-.3 1.4s.9.6 1.4.3c2.5-1.6 5.7-1.4 8 .4.2.1.4.2.6.2.3 0 .6-.1.8-.4.3-.4.3-1.1-.2-1.4-2.9-2.3-7.1-2.5-10.3-.5M33.1 25.2c-6.8-3.5-14.9-3.7-21.8-.4-.5.2-.7.8-.5 1.3s.8.7 1.3.5c6.3-3 13.8-2.9 20 .3.1.1.3.1.5.1.4 0 .7-.2.9-.5.3-.4.1-1-.4-1.3" />
    <path d="M55.7 8.8H40V6c0-2.2-1.8-4-4-4H8.3c-2.2 0-4 1.8-4 4v52c0 2.2 1.8 4 4 4H36c2.2 0 4-1.8 4-4v-2.8h15.6c2.2 0 4-1.8 4-4V12.8c.1-2.2-1.7-4-3.9-4M40 18.1h10.6v5.7H40zM38 58c0 1.1-.9 2-2 2H8.3c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2H36c1.1 0 2 .9 2 2v52m19.7-6.8c0 1.1-.9 2-2 2H40V25.9h11.6c.6 0 1-.4 1-1v-7.7c0-.6-.4-1-1-1H40v-5.3h15.6c1.1 0 2 .9 2 2v38.3z" />
    <path d="M51.1 33.7h-1.4c-.6 0-1 .4-1 1s.4 1 1 1h1.4c.6 0 1-.4 1-1s-.4-1-1-1M51.1 43.5h-1.4c-.6 0-1 .4-1 1s.4 1 1 1h1.4c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconNfc;
