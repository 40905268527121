import * as React from "react";
const IconMushroomsAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.6 30.1c-2.2-2.4-5.3-3.7-8.6-3.7-3.2 0-6.4 1.4-8.6 3.7-1.5 1.6-1.8 3.8-1 5.7.9 2 2.7 3.2 4.9 3.2H51v9.8c0 1.8-1.5 3.3-3.3 3.3H33.2v-31h10.3c1.8 0 3.4-.9 4.4-2.3s1.2-3.3.6-4.9C45.9 7.3 39.4 2.8 32.2 2.8S18.4 7.3 15.9 14l-.3.9q-1.8-.6-3.6-.6c-3.2 0-6.4 1.4-8.6 3.7-1.5 1.6-1.8 3.8-1 5.7.9 2 2.7 3.2 4.9 3.2H11V38c0 2.9 2.4 5.3 5.3 5.3h14.9v16.8c0 .6.4 1 1 1s1-.4 1-1v-6h14.5c2.9 0 5.3-2.4 5.3-5.3V39h3.6c2.2 0 4-1.2 4.9-3.2.9-1.9.5-4.1-.9-5.7M17.7 14.7c2.3-5.9 8.1-9.9 14.4-9.9 6.4 0 12.2 4 14.5 9.9.4 1 .3 2.2-.4 3.1-.6.9-1.6 1.4-2.7 1.4H21.4c-.2-.4-.5-.8-.8-1.2-.9-.9-1.9-1.7-3-2.3 0-.3 0-.7.1-1M4.3 23c-.4-1-.5-2.4.6-3.6 1.8-2 4.4-3.1 7.1-3.1s5.3 1.1 7.1 3.1c1.1 1.2 1 2.6.6 3.6s-1.5 2-3.1 2H7.3c-1.6 0-2.6-1-3-2m12 18.5c-1.8 0-3.3-1.5-3.3-3.3V27h3.6c2.2 0 4-1.2 4.9-3.2.4-.8.5-1.7.4-2.6h9.3v20.2H16.3zm43.4-6.4c-.4 1-1.4 2-3.1 2h-9.3c-1.6 0-2.6-1-3.1-2-.4-1-.5-2.4.6-3.6 1.8-2 4.4-3.1 7.1-3.1s5.3 1.1 7.1 3.1c1.2 1.2 1.1 2.6.7 3.6" />
  </svg>
);
export default IconMushroomsAlt1;
