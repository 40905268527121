import * as React from "react";
const IconConsulting = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M25.1 23.6c0 4 3.2 7.2 7.2 7.2s7.2-3.2 7.2-7.2-3.2-7.2-7.2-7.2c-3.9-.1-7.2 3.2-7.2 7.2m10.5 0c0 1.8-1.5 3.2-3.2 3.2s-3.2-1.5-3.2-3.2 1.5-3.2 3.2-3.2 3.2 1.4 3.2 3.2" />
    <path d="M8.1 31.7c.5 0 .9-.2 1.2-.4l7.1-5.6c.9-.7 1-1.9.3-2.8s-1.9-1-2.8-.3l-3.6 2.9C12.9 15.3 21.9 8.2 32.4 8.2S52 15.3 54.6 25.5c.3 1.1 1.4 1.7 2.4 1.4 1.1-.3 1.7-1.4 1.4-2.4-3-12-13.8-20.4-26.1-20.4-12.2 0-22.8 8.2-25.9 20L4.1 21c-.7-.9-1.9-1.1-2.8-.4S.2 22.5.9 23.4l5.6 7.5c.3.4.8.7 1.3.8zM62.8 44.8l-3.9-8.5c-.2-.5-.6-.9-1.2-1.1-.5-.2-1.1-.1-1.6.1l-8.1 4c-1 .5-1.4 1.7-.9 2.7s1.7 1.4 2.7.9l3.9-1.9a22.9 22.9 0 0 1-9.2 11.3v-9.4c0-5.1-3.8-9.3-8.5-9.3h-7.7c-4.7 0-8.5 4.2-8.5 9.4v9c-4.2-2.8-7.4-6.9-9-11.9-.3-1-1.5-1.6-2.5-1.3S6.7 40.3 7 41.3c3.6 11 13.8 18.4 25.4 18.4 11.1 0 21-6.8 25-17.1l1.7 3.8c.3.7 1.1 1.2 1.8 1.2.3 0 .6-.1.8-.2 1.1-.4 1.5-1.6 1.1-2.6m-39 9.3V42.9c0-3 2-5.4 4.5-5.4h1.8v6.9c0 1.1.9 2 2 2s2-.9 2-2v-6.9H36c2.5 0 4.5 2.4 4.5 5.3v11.4c-2.6 1-5.3 1.5-8.2 1.5-3 .1-5.9-.5-8.5-1.6" />
  </svg>
);
export default IconConsulting;
