import * as React from "react";
const IconSafebox2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.6 2H6.4c-2.2 0-4 1.8-4 4v44.4c0 2.2 1.8 4 4 4h4.3V61c0 .6.4 1 1 1s1-.4 1-1v-6.6h38.6V61c0 .6.4 1 1 1s1-.4 1-1v-6.6h4.3c2.2 0 4-1.8 4-4V6c0-2.2-1.8-4-4-4m2 48.4c0 1.1-.9 2-2 2H6.4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2h51.2c1.1 0 2 .9 2 2z" />
    <path d="M41.4 37.7c1.8-2.2 3-5 3-8 0-6.5-5-11.8-11.4-12.4v-6.8c0-.6-.4-1-1-1s-1 .4-1 1v6.8c-6.4.5-11.4 5.9-11.4 12.4 0 3.1 1.1 5.9 3 8l-4.8 4.8c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l4.8-4.8c2.2 1.8 5 3 8 3s5.9-1.1 8-3l4.8 4.8c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4zm-19.8-8c0-5.7 4.7-10.4 10.4-10.4S42.4 24 42.4 29.7 37.7 40.1 32 40.1s-10.4-4.7-10.4-10.4M20.5 11.4c0-.6-.4-1-1-1h-7.8c-.6 0-1 .4-1 1s.4 1 1 1h7.8c.6 0 1-.4 1-1" />
  </svg>
);
export default IconSafebox2;
