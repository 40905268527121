import * as React from "react";
const IconNeuroSurgery = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="m7.725 25.976.171-.355c1.012-2.096.984-3.913.96-5.487v-.093c-.007-.458-.014-.89-.003-1.34a8.84 8.84 0 0 1 1.618-4.858 11.2 11.2 0 0 1 4.45-3.219l.033-.013.032-.014a18.8 18.8 0 0 1 6.276-1.55A11.5 11.5 0 0 0 21 11.5C21 17.851 26.149 23 32.5 23c.768 0 1.52-.075 2.246-.22-.635 2.557-2.423 4.726-4.871 6.556l-.803.6v6.32c-.292.326-.846.745-1.692 1.119-1.078.475-2.159.667-2.778.618l-.079-.007h-.079c-2.25 0-3.02-.758-3.225-.998l-.006-.007v-3.55l-2.62.86h-.003l-.01.004-.064.02q-.093.028-.282.08a17 17 0 0 1-1.242.292c-1.578.312-3.639.496-5.202-.014-.731-.24-1.272-.603-1.644-1.114-.37-.51-.704-1.344-.704-2.756v-1.718l-1.69-.258-.009-.002-.06-.01A6 6 0 0 1 6.61 28.5a2.5 2.5 0 0 1-.588-.319 1 1 0 0 1 .05-.147 4 4 0 0 1 .479-.806 8 8 0 0 1 .878-.985l.006-.006.001-.001zM22.613 7q.46.683.896 1.322l.031.011-.008.023c3.853 5.648 6.507 8.91 13.468 12.339 0 4.37-2.58 7.74-5.928 10.243v5.911c-.95 1.933-4.534 3.303-6.628 3.137-2.779 0-4.128-.972-4.748-1.702a3 3 0 0 1-.202-.261c-.212-.308-.281-.527-.281-.527v-1.304s-.647.212-1.637.418l-.363.072c-3.36.633-9.77.948-9.77-5.88 0 0-3.1-.473-3.414-2.19-.314-1.719 2.066-3.86 2.066-3.86.805-1.669.784-3.08.76-4.681a37 37 0 0 1-.001-1.418 10.84 10.84 0 0 1 2.027-6.026 13.2 13.2 0 0 1 5.297-3.86A20.8 20.8 0 0 1 22.613 7M23 11.5q0-.196.008-.388c1.364 1.934 2.685 3.641 4.204 5.189 1.696 1.726 3.608 3.226 6.067 4.668q-.386.03-.779.031a9.5 9.5 0 0 1-9.5-9.5M38.13 38v-9c0-.97.46-1.832 1.175-2.38a2.5 2.5 0 0 1-1.174-2.12v-14a2.5 2.5 0 1 1 5 0v14c0 .697-.285 1.327-.745 1.78 1.04.485 1.786 1.555 1.58 2.869a12.8 12.8 0 0 1-2.385 5.697 12 12 0 0 1-1.259 1.437 9 9 0 0 1-.534.473l-.037.029-.013.01-.004.003h-.002c0 .001-.001.002-.601-.798l.6.8zm2-16.414v-2.172l1-1v2.172zm0-11.086v6.086l1-1V10.5a.5.5 0 0 0-1 0m1 12.914V24.5a.5.5 0 0 1-1 0v-.086zm-1 10.026a10.84 10.84 0 0 0 1.86-4.601c.06-.378-.256-.839-.86-.839a1 1 0 0 0-1 1z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconNeuroSurgery;
