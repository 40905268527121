import * as React from "react";
const IconSuitcaseAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M44.1 15.4h-3V8.6C41 5 38.1 2 34.5 2h-4.9C25.9 2 23 5 23 8.6v6.8h-3c-2.8 0-5 2.2-5 5V50c0 2.8 2.2 5 5 5h.4v6c0 .6.4 1 1 1s1-.4 1-1v-6h19.5v6c0 .6.4 1 1 1s1-.4 1-1v-6h.4c2.8 0 5-2.2 5-5V20.4c-.2-2.7-2.5-5-5.2-5M25 8.6C25 6.1 27 4 29.5 4h4.9C37 4 39 6.1 39 8.6v6.8H25zM47.1 50c0 1.7-1.3 3-3 3H19.9c-1.7 0-3-1.3-3-3V20.4c0-1.7 1.3-3 3-3h24.2c1.7 0 3 1.3 3 3z" />
    <path d="M37.4 24.9H26.6c-.6 0-1 .4-1 1s.4 1 1 1h10.7c.6 0 1-.4 1-1s-.4-1-.9-1" />
  </svg>
);
export default IconSuitcaseAlt;
