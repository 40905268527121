import * as React from "react";
const IconHanger = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.5 47 32.4 33.9v-7.8c3.4-.5 6-3.4 6-6.9 0-3.8-3.1-7-7-7-3.2 0-6 2.2-6.8 5.4-.1.5-.2 1.1-.2 1.6 0 .6.4 1 1 1s1-.4 1-1c0-.4 0-.8.1-1.2.5-2.3 2.5-3.8 4.8-3.8 2.7 0 5 2.2 5 5s-2.2 5-5 5c-.6 0-1 .4-1 1v8.6L3.4 47c-1.1.5-1.6 1.7-1.3 2.8.3 1.2 1.3 1.9 2.5 1.9h55c1.2 0 2.2-.8 2.5-2 .1-1.1-.5-2.2-1.6-2.7m-.5 2.4c0 .1-.1.4-.5.4h-55c-.4 0-.5-.3-.5-.4s0-.4.3-.6l27-13.2 28.4 13.2c.3.2.3.5.3.6" />
  </svg>
);
export default IconHanger;
