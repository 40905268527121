import * as React from "react";
const IconShorts = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.5 62H44.3c-.4 0-.8-.2-.9-.6L32.1 34.6 20.6 61.4q-.3.6-.9.6H8.5C4.9 62 2 59.1 2 55.5v-47C2 4.9 4.9 2 8.5 2h47C59.1 2 62 4.9 62 8.5v47c0 3.6-2.9 6.5-6.5 6.5m-10.6-2h10.6c2.5 0 4.5-2 4.5-4.5v-47C60 6 58 4 55.5 4h-47C6 4 4 6 4 8.5v47C4 58 6 60 8.5 60h10.6l12.1-28.4q.3-.6.9-.6c.4 0 .8.2.9.6z" />
  </svg>
);
export default IconShorts;
