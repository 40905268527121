import * as React from "react";
const IconMedicalBriefcase1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M48 18.6h-4.9C42.7 12.8 37.9 8.2 32 8.2s-10.6 4.6-11 10.4h-5c-7.7 0-14 6.3-14 14v16.2c0 4.4 3.6 8 8 8h44c4.4 0 8-3.6 8-8V32.6c0-7.7-6.3-14-14-14m-15.9-8.4c4.8 0 8.7 3.7 9.1 8.4H23c.4-4.7 4.3-8.4 9.1-8.4M16 20.6h32c6.6 0 12 5.4 12 12v2.8h-.1c-.5 2.6-2.8 4.5-5.5 4.5H9.6c-2.6 0-5-1.9-5.5-4.5H4v-2.8c0-6.6 5.4-12 12-12m38 34.2H10c-3.3 0-6-2.7-6-6v-9.5c1.4 1.5 3.4 2.5 5.6 2.5h44.8c2.2 0 4.2-.9 5.6-2.5v9.5c0 3.3-2.7 6-6 6" />
    <path d="M28.2 31.6H31v2.8c0 .6.4 1 1 1s1-.4 1-1v-2.8h2.8c.6 0 1-.4 1-1s-.4-1-1-1H33v-2.8c0-.6-.4-1-1-1s-1 .4-1 1v2.8h-2.8c-.6 0-1 .4-1 1s.5 1 1 1" />
  </svg>
);
export default IconMedicalBriefcase1;
