import * as React from "react";
const IconHandshakeAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m27.4 35.7H55c-1.1 0-2-1.1-2-2.4v-13c0-1.4.9-2.5 2-2.5h2.2C59 23.4 60 27.6 60 32q0 3-.6 5.7M32 4c10.2 0 19.2 5.5 24.1 13.8H55c-1.6 0-2.9 1-3.6 2.5H39.5v.1l-1.4-1.2c-4.4-3.8-10.9-4.2-15.5-.9-2 1.5-3.5 3.3-4.1 4.2-.1.2-.3.3-.5.3h-2.5v-.6c0-2.4-1.8-4.4-4-4.4H7.9C12.8 9.5 21.8 4 32 4m16.1 31.2c-.8 0-1.5.3-2 .8 0 0-.1.1-.1.2l-9.4 13.9-3.7-1-2-3.1c-.1-.2-.3-.4-.6-.4l-3-.8-1.3-2.9c-.1-.3-.4-.5-.6-.5l-3.1-.8-1.3-1.8 8.7-9c1-1 2.5-1.1 3.7-.2l.3.3c2 1.5 4.9 1.3 6.6-.6l1.5-1.6c.7-.7 1.1-1.7 1-2.7 0-1-.5-1.9-1.2-2.7H51v13h-2.9zm-27.8 1.5c-1.2-1-2.6-1.5-4.1-1.5h-.6V24.7H18c.9 0 1.7-.4 2.2-1.1.6-.8 1.8-2.4 3.7-3.8 3.8-2.8 9.3-2.5 13 .8l2.9 2.6c.6.6 1 1.2 1 1.8 0 .5-.1.9-.5 1.3l-1.5 1.6c-1 1.1-2.7 1.3-3.9.3l-.3-.2c-1.9-1.4-4.6-1.3-6.3.4zm-13.5-17h4.7c1.1 0 2 1.1 2 2.4v13c0 1.4-.9 2.5-2 2.5H4.6C4.2 35.9 4 34 4 32c0-4.4 1-8.6 2.8-12.3M32 60C19.2 60 8.4 51.4 5.1 39.7h6.4c1.6 0 2.9-1 3.6-2.5h1.1c1 0 2 .3 2.8 1-.2.3-.3.8 0 1.2l2 2.7c.1.2.3.3.5.4l3 .8 1.3 2.8c.1.3.4.5.6.5l3 .8 1.9 3.1c.1.2.3.4.6.4l4.8 1.3h.3c.3 0 .6-.2.8-.4l9.7-14.4c.2-.1.4-.2.6-.2h3.3c.6 1.5 2 2.5 3.6 2.5h4C55.6 51.4 44.8 60 32 60" />
  </svg>
);
export default IconHandshakeAlt;
