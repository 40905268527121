import * as React from "react";
const IconHair = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.8 16.9c-1.9-4.5-5.2-8.6-9.3-11.4-3-2.1-6.1-3.3-9.2-3.5-3.3-.2-6.6.8-8.8 2.8-2.5-1.5-5.8-2-9-1.4q-4.5.75-8.7 4.2c-6.3 5-10.5 12.9-11.1 21.2-.2 4.7.6 9.6 2.4 14.2 1.6 4 4.1 9 6.8 9.8.8.2 1.6.1 2.3-.4 1-.7 1.6-2.1 1.5-3.6-.1-.9-.3-1.7-.6-2.5-.1-.3-.1-.5-.2-.7-1.2-4.1-.7-8.9 1.5-12.8 2-3.5 5.4-6.4 9.3-7.9C29 31.4 35.5 35.4 41.3 39c1.5 1 2.8 1.8 3.4 3 .8 1.6.5 3.5.2 5.5-.1.5-.2 1-.2 1.5-.3 2.3 0 4.9.9 7.6 1.7 5.2 6.6 5.4 7.3 5.4 1.5 0 2.8-.6 3.4-1.7.9-1.5.3-3.1-.2-4.5-.1-.4-.3-.8-.4-1.2-.6-2.5.6-5 1.8-7.7.3-.6.6-1.2.9-1.9 1.5-3.5 2.4-7.5 2.7-12.2.4-6-.4-11.3-2.3-15.9m.4 15.9c-.3 4.5-1.1 8.3-2.5 11.5-.3.6-.5 1.2-.8 1.8-1.4 2.9-2.8 5.9-2 9.1.1.4.3.9.4 1.3.5 1.3.7 2.1.3 2.8-.3.6-1.3.7-1.6.7-.5 0-4.1-.1-5.4-4-.8-2.4-1.1-4.7-.8-6.7.1-.5.1-.9.2-1.4.3-2.2.7-4.6-.4-6.8-.9-1.8-2.7-2.9-4.1-3.8-5.8-3.6-12.3-7.6-15.2-14q-.3-.6-.9-.6h-.3c-4.7 1.5-8.8 4.9-11.2 9.1-2.5 4.3-3.1 9.7-1.7 14.3.1.2.1.5.2.7.2.7.4 1.4.5 2 .1.8-.2 1.5-.6 1.8-.2.1-.4.2-.6.1-1.4-.4-3.6-3.8-5.5-8.6-2-4.1-2.7-8.7-2.4-13.2.5-7.8 4.4-15.2 10.3-19.8 2.6-2 5.2-3.3 7.9-3.8 3-.5 6 0 8.2 1.5.4.3.9.2 1.3-.1 1.9-1.9 4.8-2.9 7.8-2.7 2.7.2 5.4 1.3 8.2 3.2 3.7 2.6 6.8 6.3 8.6 10.5 1.7 4.3 2.4 9.3 2.1 15.1" />
  </svg>
);
export default IconHair;
