import * as React from "react";
const IconAngleDoubleUp = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M33.6 25.6c-.9-.9-2.3-.9-3.2 0l-23 22.6c-.9.9-.9 2.3 0 3.2s2.3.9 3.2 0l21.4-21 21.4 21.1c.4.4 1 .6 1.6.6s1.2-.2 1.6-.7c.9-.9.9-2.3 0-3.2z" />
    <path d="m10.6 38.4 21.4-21 21.4 21.1c.4.4 1 .6 1.6.6s1.2-.2 1.6-.7c.9-.9.9-2.3 0-3.2l-23-22.6c-.9-.9-2.3-.9-3.2 0l-23 22.6c-.9.9-.9 2.3 0 3.2s2.3.9 3.2 0" />
  </svg>
);
export default IconAngleDoubleUp;
