import React, { useState } from 'react';
import classnames from "classnames"
import PropTypes from 'prop-types';

const BringzzTab = ({ id, isActive, onActivate, children, nbrTabs }) => {
    return (<button
        className={classnames(
            'focus:outline-none py-3 px-5',
            isActive && "text-magic-lilac",
            isActive && "border-b-2 border-magic-lilac",
            nbrTabs === 2 ? "" : "inline-block "
        )}
        onClick={onActivate}
    >
        {children}
    </button>)
};

BringzzTab.propTypes = {
    id: PropTypes.string.isRequired,
    isActive: PropTypes.bool,
    onActivate: PropTypes.func,
    children: PropTypes.node,
    nbrTabs: PropTypes.number
};


export default BringzzTab