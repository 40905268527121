import * as React from "react";
const IconCameraCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.5 20.7c-1-1-2.3-1.5-3.7-1.5h-7c-1.1 0-2-.9-2-2v-4.4c0-2.5-2.2-2.5-3-2.5H18.9c-.8 0-2.8 0-2.8 2.5v4.4c0 1.1-.9 2-2 2H7.3c-2.9 0-5.3 2.4-5.3 5.3v24.1c0 2.9 2.4 5.3 5.3 5.3h49.4c2.9 0 5.3-2.4 5.3-5.3V24.5c0-1.5-.5-2.8-1.5-3.8M60 48.5c0 1.8-1.5 3.3-3.3 3.3H7.3c-1.8 0-3.3-1.5-3.3-3.3V24.4c0-1.8 1.5-3.3 3.3-3.3h6.9c2.2 0 4-1.8 4-4v-4.4c0-.4.1-.5.1-.5.1-.1.5-.1.7-.1h25.8c1 0 1 .1 1 .5V17c0 2.2 1.8 4 4 4h7c.9 0 1.7.3 2.3 1 .6.6 1 1.4 1 2.3z" />
    <path d="M31.6 25.1c-4.6 0-8.4 3.8-8.4 8.5s3.7 8.3 8.4 8.3c4.6 0 8.4-3.7 8.4-8.3s-3.9-8.5-8.4-8.5m0 14.8c-3.6 0-6.4-2.8-6.4-6.3 0-3.6 2.9-6.5 6.4-6.5s6.4 3 6.4 6.5c0 3.4-2.9 6.3-6.4 6.3M12.5 26.1c-1.6 0-3 1.3-3 3 0 1.6 1.3 3 3 3s3-1.3 3-3-1.3-3-3-3" />
  </svg>
);
export default IconCameraCopy;
