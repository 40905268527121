import * as React from "react";
const IconMouseCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2c-10.8 0-19.6 9.7-19.6 21.6v16.8C12.3 52.3 21.1 62 32 62c5 0 9.7-2 13.4-5.7 4.1-4.1 6.3-9.9 6.3-16V23.6C51.6 11.7 42.8 2 32 2m17.6 21.6v2.5H33.2v-22c9.1.7 16.4 9.2 16.4 19.5M31.2 4v22H14.3v-2.5c0-10.4 7.5-19 16.9-19.5m12.7 50.9C40.6 58.2 36.4 60 32 60c-9.7 0-17.6-8.8-17.6-19.6V28.1h35.3v12.3c0 5.5-2.1 10.8-5.8 14.5" />
  </svg>
);
export default IconMouseCopy;
