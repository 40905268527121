import * as React from "react";
const IconVideoCameraAlt3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 31.5c-.6-.4-1.5-.6-2.2-.3l-11.3 4.1v-4.9c0-2.2-1.8-4-4-4H39c2.4-1.8 3.9-4.6 3.9-7.8 0-5.4-4.4-9.8-9.8-9.8s-9.7 4.3-9.8 9.6c-.1-5.3-4.5-9.6-9.8-9.6-5.4 0-9.8 4.4-9.8 9.8 0 3.2 1.5 6 3.9 7.8H6c-2.2 0-4 1.8-4 4v20.9c0 2.2 1.8 4 4 4h37.5c2.2 0 4-1.8 4-4v-4.9l11.3 4.1c.3.1.5.1.8.1.5 0 1-.1 1.4-.4.6-.4 1-1.2 1-2V33.5c0-.8-.4-1.5-1-2M33.1 10.7c4.3 0 7.8 3.5 7.8 7.8s-3.5 7.8-7.8 7.8-7.8-3.5-7.8-7.8 3.5-7.8 7.8-7.8m-5.9 15.7h-7.8c2.3-1.8 3.8-4.5 3.9-7.6.1 3.1 1.6 5.8 3.9 7.6M5.6 18.6c0-4.3 3.5-7.8 7.8-7.8s7.8 3.5 7.8 7.8-3.5 7.8-7.8 7.8-7.8-3.5-7.8-7.8m37.9 34.7H6c-1.1 0-2-.9-2-2V30.4c0-1.1.9-2 2-2h37.5c1.1 0 2 .9 2 2v20.8c0 1.2-.9 2.1-2 2.1M60 48.2c0 .2-.1.3-.2.3s-.2.1-.4 0l-11.9-4.3v-6.9L59.4 33c.2-.1.3 0 .4 0s.2.1.2.3z" />
    <path d="M24.8 33.8c-3.9 0-7.1 3.2-7.1 7.1s3.2 7.1 7.1 7.1 7.1-3.2 7.1-7.1-3.2-7.1-7.1-7.1m0 12.1c-2.8 0-5.1-2.3-5.1-5.1s2.3-5.1 5.1-5.1 5.1 2.3 5.1 5.1-2.3 5.1-5.1 5.1" />
  </svg>
);
export default IconVideoCameraAlt3;
