import * as React from "react";
const IconMedicineAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M15.8 2C8.2 2 2 8.2 2 15.8v32.4C2 55.8 8.2 62 15.8 62s13.8-6.2 13.8-13.8V15.8C29.6 8.2 23.4 2 15.8 2m11.8 46.2c0 6.5-5.3 11.8-11.8 11.8S4 54.7 4 48.2V33h23.6zm0-17.2H4V15.8C4 9.3 9.3 4 15.8 4s11.8 5.3 11.8 11.8z" />
    <path d="M11.1 17.3c-.6 0-1 .4-1 1v4.1c0 .6.4 1 1 1s1-.4 1-1v-4.1c0-.5-.5-1-1-1M11.1 46.6c.6 0 1-.4 1-1v-4.1c0-.6-.4-1-1-1s-1 .4-1 1v4.1c0 .6.4 1 1 1M58.4 23.3c-4.8-4.8-12.6-4.8-17.4 0s-4.8 12.6 0 17.4c2.4 2.4 5.5 3.6 8.7 3.6s6.3-1.2 8.7-3.6c4.8-4.8 4.8-12.6 0-17.4m-16 16c-3.8-3.8-4-9.8-.7-13.8L56.2 40c-4 3.3-10 3.1-13.8-.7m15.3-.8L43.2 24c1.9-1.6 4.2-2.3 6.5-2.3 2.6 0 5.3 1 7.3 3 3.8 3.8 4 9.8.7 13.8" />
  </svg>
);
export default IconMedicineAlt;
