import * as React from "react";
const IconLetrinaAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M9 6a3 3 0 0 0-3 3v16h6v3c0 1.5 1.753 2 2.5 2v12H29v-5.06c6.7-.54 12.055-4.733 12.888-11.94q.111-.965.112-2H18V9a3 3 0 0 0-3-3zm7 3a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1v1h8zm-2 18.848V25h25.873c-.365 2.76-1.486 4.918-3.057 6.502-1.95 1.967-4.727 3.182-7.977 3.445l-.66.053H23.5v2H27v3H16.5V28h-1.996l-.05-.005a1.4 1.4 0 0 1-.454-.147M16 12H8v11h8z"
      clipRule="evenodd"
    />
    <path
      
      d="M20 20a1 1 0 0 1 1-1h20a1 1 0 1 1 0 2H21a1 1 0 0 1-1-1"
    />
  </svg>
);
export default IconLetrinaAlt;
