import * as React from "react";
const IconAngleDoubleLeft = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m30.4 32 21.1-21.4c.9-.9.9-2.3 0-3.2s-2.3-.9-3.2 0l-22.6 23c-.9.9-.9 2.3 0 3.2l22.6 23c.4.4 1 .7 1.6.7s1.1-.2 1.6-.6c.9-.9.9-2.3 0-3.2z" />
    <path d="m17.4 32 21.1-21.4c.9-.9.9-2.3 0-3.2s-2.3-.9-3.2 0l-22.6 23c-.9.9-.9 2.3 0 3.2l22.6 23c.4.4 1 .7 1.6.7s1.1-.2 1.6-.6c.9-.9.9-2.3 0-3.2z" />
  </svg>
);
export default IconAngleDoubleLeft;
