import * as React from "react";
const IconMagnifyingGlassChart = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.7 59.3 43.8 42.4c8.5-9.2 8.3-23.6-.7-32.5C38.7 5.4 32.8 3 26.5 3S14.3 5.4 9.9 9.9C5.4 14.3 3 20.2 3 26.5s2.4 12.2 6.9 16.6c4.6 4.6 10.6 6.9 16.6 6.9 5.7 0 11.4-2.1 15.9-6.2l16.9 16.9c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4M11.3 41.8C7.2 37.7 5 32.3 5 26.5s2.2-11.2 6.3-15.2S20.8 5 26.5 5c5.8 0 11.2 2.2 15.2 6.3 8.4 8.4 8.4 22.1 0 30.4-8.3 8.4-22 8.4-30.4.1" />
    <path d="M37.9 35.7H18.6V16.4c0-.6-.4-1-1-1s-1 .4-1 1v20.3c0 .6.4 1 1 1h20.3c.6 0 1-.4 1-1s-.5-1-1-1" />
    <path d="M25.8 30.4c.6 0 1-.4 1-1v-7.2c0-.6-.4-1-1-1s-1 .4-1 1v7.2c0 .6.5 1 1 1M33.6 30.4c.6 0 1-.4 1-1V17.5c0-.6-.4-1-1-1s-1 .4-1 1v11.9c0 .6.5 1 1 1" />
  </svg>
);
export default IconMagnifyingGlassChart;
