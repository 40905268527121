import * as React from "react";
const IconChevronDown = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 14 8"
    {...props}
  >
    <path
      
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m1 1 6 6 6-6"
    />
  </svg>
);
export default IconChevronDown;
