import * as React from "react";
const IconInsecticideResistance = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M24 4a1 1 0 0 1 1 1v6.317a2.6 2.6 0 0 1 .66.664l1.7-1.416a1 1 0 0 1 .422-.208l3-.667a1 1 0 0 1 .434 1.953l-2.763.614-1.813 1.511a1 1 0 0 1-.147.102l.02.067 11.172 1.287.196.618c.25.794.15 1.92-.783 2.81-.894.853-2.406 1.348-4.683 1.348-2.383 0-4.014-.74-5.049-1.802a5 5 0 0 1-.368-.423 19 19 0 0 1-.031 1.34q.053.03.105.065l2.248 1.57a1 1 0 0 1 .241.238l1.212 1.696 2.018.506a1 1 0 0 1-.488 1.94l-2.374-.596a1 1 0 0 1-.57-.389l-1.325-1.854-1.352-.944q-.12.591-.284 1.101l1.618 1.355a1 1 0 0 1 .337.971l-.473 2.26 1.259 1.259a1 1 0 1 1-1.414 1.414l-1.641-1.641a1 1 0 0 1-.272-.912l.46-2.202-.812-.68c-.33.375-.817.728-1.46.728-.599 0-1.061-.305-1.39-.65l-.483.521.477 2.283a1 1 0 0 1-.272.912l-1.64 1.641a1 1 0 1 1-1.415-1.414l1.259-1.259-.472-2.26a1 1 0 0 1 .246-.885l1.303-1.403a11 11 0 0 1-.263-.983l-.948.76-1.345 1.882a1 1 0 0 1-.57.389l-2.374.596a1 1 0 1 1-.487-1.94l2.017-.506 1.212-1.696a1 1 0 0 1 .188-.198L21 19.22l.037-.028A19 19 0 0 1 21 17.775a5 5 0 0 1-.369.423C19.599 19.259 17.968 20 15.585 20c-2.277 0-3.79-.495-4.683-1.348-.934-.89-1.034-2.016-.783-2.81l.195-.619 11.174-1.286.019-.067a1 1 0 0 1-.147-.102l-1.813-1.511-2.764-.614a1 1 0 0 1 .434-1.953l3 .667a1 1 0 0 1 .424.208l1.7 1.416q.046-.066.096-.129c.148-.188.336-.38.563-.534V5a1 1 0 0 1 1-1m11.87 13.028q-.06.087-.152.177c-.354.338-1.248.795-3.303.795-1.95 0-3.026-.593-3.616-1.198a3 3 0 0 1-.476-.643zm-23.74 0q.058.087.15.177c.355.338 1.25.795 3.304.795 1.95 0 3.026-.593 3.616-1.198.202-.207.358-.427.476-.643zM23 18c0-1.58.214-2.967.534-3.926.159-.477.326-.795.466-.975.139.18.306.498.465.975.32.959.535 2.347.535 3.926s-.215 2.968-.535 3.926c-.159.477-.326.795-.465.975-.14-.18-.307-.498-.466-.975C23.214 20.968 23 19.58 23 18"
      clipRule="evenodd"
    />
    <path
      
      d="M23 40.586 28.586 35H25v-2h7v7h-2v-3.586L24.414 42H36v2H12v-2h9.586l-7.293-7.293 1.414-1.414z"
    />
  </svg>
);
export default IconInsecticideResistance;
