import * as React from "react";
const IconTimerAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m47.8 15.1 5-5.9c.4-.4.3-1.1-.1-1.4s-1-.3-1.4.1l-5.1 6c-3.8-2.5-8.3-4-13.1-4.1V4H41c.6 0 1-.4 1-1s-.4-1-1-1H23.1c-.6 0-1 .4-1 1s.4 1 1 1H31v5.8c-13.9.5-25.1 12-25.1 26.1C5.9 50.3 17.6 62 32 62s26.1-11.7 26.1-26.1c0-8.5-4.1-16-10.3-20.8M32 60C18.7 60 7.9 49.2 7.9 35.9S18.7 11.8 32 11.8s24.1 10.8 24.1 24.1S45.3 60 32 60" />
    <path d="M33 36V23.5c0-.6-.4-1-1-1s-1 .4-1 1v12.9c0 .3.1.5.3.7l7.6 7.6c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4z" />
  </svg>
);
export default IconTimerAlt;
