import * as React from "react";
const IconBoardingPassAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.7 2H10.3c-1.7 0-3 1.3-3 3v54c0 1.7 1.3 3 3 3h41.5c2.8 0 5-2.2 5-5V7c-.1-2.8-2.3-5-5.1-5m3 55c0 1.7-1.3 3-3 3H10.3c-.6 0-1-.4-1-1V5c0-.6.4-1 1-1h41.5c1.7 0 3 1.3 3 3v50z" />
    <path d="M29.4 41.5H18.8c-.6 0-1 .4-1 1s.4 1 1 1h10.5c.6 0 1-.4 1-1s-.4-1-.9-1M29.4 49.5H18.8c-.6 0-1 .4-1 1s.4 1 1 1h10.5c.6 0 1-.4 1-1s-.4-1-.9-1M43.9 22.5c.6 0 1-.4 1-1s-.4-1-1-1h-4.6l-2.5-7.2c-.2-.5-.7-.8-1.3-.6-.5.2-.8.7-.6 1.3l2.2 6.6H26l-2.4-4.7c-.3-.5-.9-.7-1.3-.4-.5.3-.7.9-.4 1.3l2.5 4.8-2.5 4.8c-.3.5-.1 1.1.4 1.3.1.1.3.1.5.1.4 0 .7-.2.9-.5l2.4-4.7h11.2L35 29c-.2.5.1 1.1.6 1.3.1 0 .2.1.3.1.4 0 .8-.3.9-.7l2.5-7.2zM45.2 41.5h-5.3c-.6 0-1 .4-1 1s.4 1 1 1h5.3c.6 0 1-.4 1-1s-.5-1-1-1M45.2 49.5h-5.3c-.6 0-1 .4-1 1s.4 1 1 1h5.3c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconBoardingPassAlt;
