import React, { useState } from 'react';
import { BringzzButton, BringzzText } from "@bringzz/components";
import { useNavigation } from 'context/NavigationContext';
import {
	BringzzInput,
	BringzzDate,
	BringzzPageContainer,
	BringzzIcon,
	BringzzImageUpload,
	BringzzBottomDrawer,
	BringzzCheckbox
} from "@bringzz/components";
import { useLocation } from 'react-router-dom';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const InventoryControlPage = () => {
	const { backPress } = useNavigation();
	const [verified, setVerified] = useState(false);
	const { navigate } = useNavigation();

	return (
		<BringzzPageContainer
			header={{
				left: (
					<BringzzIcon
						size='22'
						icon='IconChevronLeft'
						folder='LineIcons'
						className='cursor-pointer'
						onClick={backPress}
					/>
				)
			}}
			footer={
				<div className='px-4 mb-4'>
					<BringzzButton
						size='lg'
						className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
						onClick={() => {
							navigate('/register/pharmacy/complete');
						}}
						disabled={!verified}
					>
						Save
					</BringzzButton>
				</div>
			}
			authenticated={false}
		>
			<div className='relative flex justify-center bg-magic-lilac/20'>
				<div className='relative bg-white w-full bg-cover bg-center flex flex-col'>
					{/* Content */}
					<div className='flex-grow flex flex-col py-8 px-4'>
						<div className='z-10 flex flex-col '>
							<div className='flex flex-col text-center space-y-6 mx-auto md:max-w-lg lg:max-w-xl xl:max-w-2xl'>
								<BringzzText tag='h1' className='text-midnight-blue'>
									Your inventory control system
								</BringzzText>
								<BringzzText
									tag='h4'
									className='text-midnight-blue/25 font-normal'
								>
									Adipiscing libero purus ac mi tincidunt nulla. Sagittis eget
									non lacus feugiat fringilla adipiscing lectus.
								</BringzzText>
							</div>
							{!verified && (
								<div className='space-y-6'>
									<div className='space-y-4'>
										<BringzzInput label='IP Address' placeholder='Ip address' />
										<BringzzInput
											label='User'
											placeholder='User'
											hint='We only allow Austrian and German bank accounts.'
										/>
										<BringzzInput
											label='password'
											placeholder='password'
											type='password'
										/>

										<div className='p-4 bg-magic-lilac/20'>
											<BringzzText
												tag='h4'
												className='font-light text-midnight-blue/40'
											>
												Not sure where to find this data?
												<span className='font-medium text-magic-lilac underline'>
													Read our instructions/FAQ
												</span>
											</BringzzText>
										</div>

										<div className='px-4 mb-4'>
											<BringzzButton
												size='lg'
												className='bg-white border border-magic-lilac w-full flex justify-center text-midnight-blue'
												onClick={() => {
													setVerified(true);
												}}
												disable={!verified}
											>
												Verify
											</BringzzButton>
										</div>
									</div>
								</div>
							)}
							{verified && (
								<div className='bg-magic-lilac/20 p-4 mt-12'>
									<div className='flex items-center gap-x-4 pb-4 border-b border-b-midnight-blue/40'>
										<BringzzIcon
											icon='IconCheckmarkCircle'
											folder='LineIcons'
											size='20px'
											className='text-magic-lilac'
										/>{' '}
										<BringzzText tag='h3' className='text-magic-lilac'>
											Verification was successful
										</BringzzText>
									</div>
									<div className='mt-6'>
										<BringzzText
											tag='h3'
											className='text-midnight-blue font-light'
										>
											IP Address
										</BringzzText>
										<BringzzText
											tag='h3'
											className='text-midnight-blue font-light'
										>
											192.168.0.1
										</BringzzText>
									</div>
									<div className='mt-6'>
										<BringzzText
											tag='h3'
											className='text-midnight-blue font-light'
										>
											User
										</BringzzText>
										<BringzzText
											tag='h3'
											className='text-midnight-blue font-light'
										>
											maxmustermann
										</BringzzText>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</BringzzPageContainer>
	);
};

export default InventoryControlPage;
