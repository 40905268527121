import * as React from "react";
const IconPulseCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 44.2h-8.3c-.6 0-1.2-.3-1.5-.9L47 35.2c-.7-1.2-1.9-1.8-3.2-1.7-1.3.2-2.4 1-2.9 2.2l-3.6 10.1c-.3.8-1 1.1-1.6 1.1s-1.3-.3-1.5-1.1l-9.1-28.3c-.5-1.3-1.7-2.2-3.1-2.2-1.4-.1-2.8.8-3.2 2L8.5 40.6c-.3.6-.8 1-1.5 1H3c-.6 0-1 .4-1 1s.4 1 1 1h4c1.4 0 2.8-.9 3.3-2.2L20.7 18v-.1c.1-.3.6-.7 1.2-.7s1.1.3 1.2.9l9.1 28.2c.5 1.5 1.8 2.5 3.4 2.5h.1c1.6 0 2.9-.9 3.4-2.4l3.6-10c.2-.5.7-.9 1.2-.9s1 .3 1.2.7l4.2 8.1c.6 1.2 1.9 2 3.2 2H61c.6 0 1-.4 1-1s-.4-1.1-1-1.1" />
  </svg>
);
export default IconPulseCopy;
