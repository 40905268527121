import * as React from "react";
const IconStraight = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 3C16 3 3 16 3 32s13 29 29 29 29-13 29-29S48 3 32 3m1 56V33l9.3 9.7c.4.4 1 .4 1.4 0s.4-1 0-1.4l-11-11.5c-.4-.4-1.1-.4-1.4 0L20.2 41.3c-.4.4-.4 1 0 1.4s1 .4 1.4 0L31 33v26C16.6 58.4 5 46.6 5 32 5 17.1 17.1 5 32 5s27 12.1 27 27c0 14.6-11.6 26.4-26 27" />
  </svg>
);
export default IconStraight;
