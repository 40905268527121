import * as React from "react";
const IconEiffelTower = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m54.4 60.4-2.1-2.6c-10.5-13.1-16-28.9-16-45.7v-1.6c0-.6-.4-1-1-1H33V3c0-.6-.4-1-1-1s-1 .4-1 1v6.4h-2.2c-.6 0-1 .4-1 1V12c0 16.8-5.5 32.6-16 45.7l-2.1 2.6c-.2.3-.3.7-.1 1.1.1.4.4.6.8.6H26c.6 0 1-.4 1-1v-1.6c0-2.7 2.2-5 5-5 2.7 0 5 2.2 5 5V61c0 .6.4 1 1 1h15.6q.6 0 .9-.6c.2-.3.1-.7-.1-1M29.8 12.1v-.6h4.5v.6c0 9.7 1.8 19.1 5.3 27.8H24.5c3.5-8.8 5.3-18.1 5.3-27.8M39 60v-.6c0-3.8-3.1-7-7-7-3.8 0-7 3.1-7 7v.6H12.5l.8-1c4.2-5.3 7.7-11 10.3-17.1h16.8C43 48 46.5 53.7 50.7 59l.8 1z" />
  </svg>
);
export default IconEiffelTower;
