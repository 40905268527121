import * as React from "react";
const IconTriangle4 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M37.1 23.1c-1.1-1.9-3-3-5.1-3s-4.1 1.1-5.1 3L20 35c-1.1 1.9-1.1 4.1 0 5.9 1.1 1.9 3 3 5.1 3h13.7c2.1 0 4.1-1.1 5.1-3 1.1-1.9 1.1-4.1 0-5.9zm5.2 16.8c-.7 1.2-2 2-3.4 2H25.1c-1.4 0-2.7-.7-3.4-2s-.7-2.7 0-3.9l6.9-11.9c.7-1.2 2-2 3.4-2s2.7.7 3.4 2L42.3 36c.7 1.2.7 2.7 0 3.9" />
    <path d="M57 2H7C4.2 2 2 4.2 2 7v50c0 2.8 2.2 5 5 5h50c2.8 0 5-2.2 5-5V7c0-2.8-2.2-5-5-5m3 55c0 1.7-1.3 3-3 3H7c-1.7 0-3-1.3-3-3V7c0-1.7 1.3-3 3-3h50c1.7 0 3 1.3 3 3z" />
  </svg>
);
export default IconTriangle4;
