import * as React from "react";
const IconFirecracker = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M41.9 25.4H33V16c0-.6-.4-1-1-1s-1 .4-1 1v9.4h-8.9c-2.2 0-4 1.8-4 4V58c0 2.2 1.8 4 4 4h19.8c2.2 0 4-1.8 4-4V29.4c0-2.2-1.8-4-4-4M20.1 38.9h23.8v9.5H20.1zm2-11.5h19.8c1.1 0 2 .9 2 2V37H20.1v-7.6c0-1.1.9-2 2-2M41.9 60H22.1c-1.1 0-2-.9-2-2v-7.6h23.8V58c0 1.1-.9 2-2 2M29.9 8.2h4.2c.3 0 .5-.2.5-.5V3.5c0-.3-.2-.5-.5-.5h-4.2c-.3 0-.5.2-.5.5v4.2c0 .2.2.5.5.5M40.2 13.3h4.2c.3 0 .5-.2.5-.5V8.7c0-.3-.2-.5-.5-.5h-4.2c-.3 0-.5.2-.5.5v4.2c0 .2.3.4.5.4M19.6 13.3h4.2c.3 0 .5-.2.5-.5V8.7c0-.3-.2-.5-.5-.5h-4.2c-.3 0-.5.2-.5.5v4.2c0 .2.2.4.5.4" />
  </svg>
);
export default IconFirecracker;
