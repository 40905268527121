import * as React from "react";
const IconSwap3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 65 65"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.1 31.8c-.4-.4-1-.4-1.4 0L44 40.3V4.5c0-.6-.4-1-1-1s-1 .4-1 1v35.8L22.8 21.7c-.4-.4-1-.4-1.4 0L10.9 31.8c-.4.4-.4 1 0 1.4s1 .4 1.4 0l8.7-8.5v35.8c0 .6.4 1 1 1s1-.4 1-1V24.7l19.2 18.6c.2.2.4.3.7.3s.5-.1.7-.3L54 33.2c.5-.4.5-1 .1-1.4" />
  </svg>
);
export default IconSwap3;
