import * as React from "react";
const IconLockAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M47 19.8v-2.6c0-8-6-14.8-13.6-15.4-4.2-.4-8.4 1.1-11.5 3.9S17 12.5 17 16.6v3.2C11.2 20.9 6.8 26 6.8 32.2v18.1c0 6.6 5.4 11.9 11.9 11.9h26.4c6.6 0 12-5.4 12-12V32c.1-6-4.3-11-10.1-12.2M24.9 9C27.1 7 30 6 33 6.3c5.3.5 9.5 5.3 9.5 10.9v2.3h-21v-3c0-2.8 1.2-5.5 3.4-7.5m27.8 41.3c0 4.1-3.4 7.5-7.5 7.5H18.8c-4.1 0-7.4-3.3-7.4-7.4V32.2c0-4.5 3.6-8.1 8.1-8.1h25c4.5 0 8.2 3.6 8.2 7.9z" />
    <path d="M32 34.5c-1.2 0-2.3 1-2.3 2.3v10.5c0 1.2 1 2.3 2.3 2.3 1.2 0 2.3-1 2.3-2.3V36.7c0-1.2-1.1-2.2-2.3-2.2" />
  </svg>
);
export default IconLockAlt;
