import * as React from "react";
const IconShipDeliveryBox = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M39.5 57c-.3-.2-.5-.4-.8-.6l4.5-16.1c.7-2.5-.6-5.2-3-6.2L37 32.8v-4.9c0-.6-.4-1-1-1s-1 .4-1 1v4l-9.7-4.1c-1.3-.5-2.7-.5-4 0L11.5 32v-7.4c0-1.8 1.4-3.2 3.2-3.2h13.6c.6 0 1-.4 1-1s-.4-1-1-1H16.5v-6c0-1.8 1.4-3.2 3.2-3.2h8c.6 0 1-.4 1-1s-.4-1-1-1h-3.4V3c0-.6-.4-1-1-1s-1 .4-1 1v5.3h-2.6c-2.9 0-5.2 2.3-5.2 5.2v6c-2.8.1-5 2.4-5 5.2v8.2l-3.2 1.3c-2.4 1-3.7 3.7-3 6.2l4.5 16.1c-.3.1-.6.3-.8.5l-3.7 3.2c-.4.4-.5 1-.1 1.4.2.3.5.4.7.4s.5-.1.7-.2l3.7-3.2c1.2-1 3-1 4.2 0l1 .8c1.9 1.7 4.8 1.7 6.8 0l1-.8c1.2-1 3-1 4.2 0l1 .8c1.9 1.7 4.8 1.7 6.8 0l1-.8c1.2-1 3-1 4.2 0l3.7 3.2c.4.4 1 .3 1.4-.1s.3-1-.1-1.4zm-6.8 0-1 .8c-1.2 1-3 1-4.2 0l-1-.8c-1.9-1.7-4.8-1.7-6.8 0l-1 .8c-1.2 1-3 1-4.2 0l-1-.8c-1.1-1-2.6-1.4-4.1-1.2l-4.5-16c-.2-1.5.6-3.2 2.1-3.8l3.8-1.6h.1L22 29.8c.8-.3 1.7-.3 2.4 0l11.1 4.6h.1l3.8 1.6c1.5.6 2.3 2.2 1.8 3.8l-4.5 16c-1.3-.1-2.8.2-4 1.2M57.1 2H41.7c-2.2 0-4 1.8-4 4v12.4c0 2.2 1.8 4 4 4h15.4c2.2 0 4-1.8 4-4V6c0-2.2-1.8-4-4-4M41.7 4h15.4c1.1 0 2 .9 2 2v3.2H39.7V6c0-1.1.9-2 2-2m15.4 16.4H41.7c-1.1 0-2-.9-2-2v-7.2h19.4v7.2c0 1.1-.9 2-2 2" />
  </svg>
);
export default IconShipDeliveryBox;
