import * as React from "react";
const IconArrowLongLeftI = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.6 25.6c-.6 0-1 .4-1 1v4.1H6.8l9.1-9.1c.4-.4.4-1 0-1.4s-1-.4-1.4 0L3.7 31c-.4.4-.4 1 0 1.4l11.4 11.4c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-9.7-9.7h51.9v4.7c0 .6.4 1 1 1s1-.4 1-1V26.6c-.1-.5-.5-1-1.1-1" />
  </svg>
);
export default IconArrowLongLeftI;
