import * as React from "react";
const IconRocketCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.5 20 53 11.5l-.2-.1c-.4-.2-.7-.3-1.2-.4l-13.7 1.1C28.3 3.7 15.8.1 5.5 2.8c-1.3.4-2.2 1.4-2.6 2.6-2.8 10.3.8 22.8 9.3 32.5L11 51.7c0 .4.1.8.4 1.1l8.6 8.7c.3.3.8.5 1.2.5.2 0 .3 0 .5-.1.6-.2 1.1-.7 1.2-1.3l1.5-12.9c1.9 1.1 3.9 2 5.9 2.7.4.1.8.2 1.2.2.9 0 1.9-.4 2.6-1.1l15.4-15.4c1-1 1.3-2.5.9-3.8-.7-2-1.6-4-2.7-5.9L60.5 23h.1c.6-.1 1.1-.6 1.3-1.2.2-.7 0-1.3-.4-1.8M21 59.7l-8-8 1-11.8.2.2c2.5 2.5 5.3 4.7 8.2 6.5zm27.1-27L32.7 48.1c-.5.5-1.1.6-1.7.4-5.6-2-10.9-5.4-15.3-9.8C6.3 29.2 2 16.4 4.9 6c.1-.6.5-1 1.1-1.2 2.1-.5 4.4-.8 6.7-.8 8.9 0 18.6 4.2 26 11.7 4.4 4.4 7.8 9.7 9.8 15.3.2.6 0 1.2-.4 1.7m-1.5-10.2c-1.8-3-4-5.7-6.5-8.3-.1 0-.1-.1-.2-.2l11.8-.9 8 8zM55.4 41.7c-.2-.1-.7-.3-1.2.1-.8.6-4.6 4.4-6.8 6.5-6.1 6.1-6.1 6.2-5.9 6.9 0 .2.1.3.3.5 3 3 7.7 3.7 11.2 3.7 2.8 0 4.9-.4 5.1-.5.4-.1.7-.4.8-.8s2.1-10.9-3.3-16.3c0 0-.1-.1-.2-.1m1.7 15.4c-2.2.3-9.1 1-13.1-2.2 2-2.2 8.8-8.9 10.9-10.9 3.2 4 2.5 10.9 2.2 13.1" />
    <path d="M25.9 18.6c-4 0-7.3 3.3-7.3 7.3s3.3 7.3 7.3 7.3 7.3-3.3 7.3-7.3-3.3-7.3-7.3-7.3m0 12.6c-2.9 0-5.3-2.4-5.3-5.3s2.4-5.3 5.3-5.3 5.3 2.4 5.3 5.3-2.4 5.3-5.3 5.3" />
  </svg>
);
export default IconRocketCopy;
