import * as React from "react";
const IconDollarBundle = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M34.9 18h-6.2c-.6 0-1 .4-1 1s.4 1 1 1h4.7v1.8c0 .6.4 1 1 1s1-.4 1-1v-1.9c2.1-.3 3.8-2 3.8-4.2 0-2.4-1.9-4.3-4.3-4.3H32c-1.3 0-2.3-1-2.3-2.3s1-2.3 2.3-2.3h6.2c.6 0 1-.4 1-1s-.4-1-1-1h-2.8V3c0-.6-.4-1-1-1s-1 .4-1 1v1.8H32c-2.4 0-4.3 1.9-4.3 4.3s1.9 4.3 4.3 4.3h2.8c1.3 0 2.3 1 2.3 2.3s-1 2.3-2.2 2.3M60.5 50.8c-.4-.4-1-.4-1.4 0L49.9 60H33.2v-6.7h14.6c.3 0 .5-.1.7-.3l11.2-11.2c.4-.4.4-1 0-1.4s-1-.4-1.4 0L47.4 51.3H33.2v-6.7h12.7c.3 0 .5-.1.7-.3l12.6-12.4c.3-.3.4-.7.2-1.1q-.3-.6-.9-.6H16.8c-.3 0-.5.1-.7.3L3.5 42.9c-.3.3-.4.7-.2 1.1q.3.6.9.6H20v6.7H4.2c-.6 0-1 .4-1 1s.4 1 1 1H20V60H4.2c-.6 0-1 .4-1 1s.4 1 1 1h46.1c.3 0 .5-.1.7-.3l9.5-9.5c.4-.4.4-1.1 0-1.4m-15-8.2h-11l9.7-10.4H56zm-4-10.4-9.7 10.4h-8.4L33 32.2zm-24.3 0h13.1l-9.7 10.4h-14zM22 60V44.6h9.2V60z" />
  </svg>
);
export default IconDollarBundle;
