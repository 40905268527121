import * as React from "react";
const IconBench = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.1 12.7h-1.4c-2.1 0-3.9 1.7-3.9 3.9v2.3h-8.7V9.4c0-.6-.4-1-1-1s-1 .4-1 1v9.4H21.8V9.4c0-.6-.4-1-1-1s-1 .4-1 1v9.4h-8.7v-2.3c0-2.1-1.7-3.9-3.9-3.9H5.9c-2.1 0-3.9 1.7-3.9 3.9v8.2c0 2.1 1.7 3.9 3.9 3.9h1.4c2.1 0 3.9-1.7 3.9-3.9v-3.9h8.7v23.8h-3.1c-2.2 0-4 1.8-4 4v3c0 2.2 1.8 4 4 4h30.4c2.2 0 4-1.8 4-4v-3c0-2.2-1.8-4-4-4h-3.1V20.8h8.7v3.9c0 2.1 1.7 3.9 3.9 3.9h1.4c2.1 0 3.9-1.7 3.9-3.9v-8.2c0-2.1-1.7-3.8-3.9-3.8m-49 12c0 1-.8 1.9-1.9 1.9H5.9c-1 0-1.9-.8-1.9-1.9v-8.2c0-1 .8-1.9 1.9-1.9h1.4c1 0 1.9.8 1.9 1.9v8.2zm40.1 23.9v3c0 1.1-.9 2-2 2H16.8c-1.1 0-2-.9-2-2v-3c0-1.1.9-2 2-2h30.5c1 0 1.9.9 1.9 2m-7-4H21.8V20.8h20.3v23.8zM60 24.7c0 1-.8 1.9-1.9 1.9h-1.4c-1 0-1.9-.8-1.9-1.9v-8.2c0-1 .8-1.9 1.9-1.9h1.4c1 0 1.9.8 1.9 1.9z" />
  </svg>
);
export default IconBench;
