import * as React from "react";
const IconCalculator = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M25.3 31.3h-1v-1c0-1.2-1-2.3-2.3-2.3s-2.3 1-2.3 2.3v1h-1c-1.2 0-2.3 1-2.3 2.3s1 2.3 2.3 2.3h1v1c0 1.2 1 2.3 2.3 2.3s2.3-1 2.3-2.3v-1h1c1.2 0 2.3-1 2.3-2.3s-1-2.3-2.3-2.3M45 33.6l.9-.9c.9-.9.9-2.3 0-3.2s-2.3-.9-3.2 0l-.9.9-.8-.9c-.9-.9-2.3-.9-3.2 0s-.9 2.3 0 3.2l.9.9-.9.9c-.9.9-.9 2.3 0 3.2.4.4 1 .7 1.6.7s1.1-.2 1.6-.7l.9-.9.9.9c.4.4 1 .7 1.6.7s1.2-.2 1.6-.7c.9-.9.9-2.3 0-3.2zM24.5 48.8h-4.9c-1.2 0-2.3 1-2.3 2.3s1 2.3 2.3 2.3h4.9c1.2 0 2.3-1 2.3-2.3s-1.1-2.3-2.3-2.3M42.7 47l-4.9 4.9c-.9.9-.9 2.3 0 3.2.4.4 1 .7 1.6.7s1.1-.2 1.6-.7l4.9-4.9c.9-.9.9-2.3 0-3.2-.9-.8-2.3-.8-3.2 0" />
    <path d="M48.1 1.8H15.9c-3.4 0-6.1 2.7-6.1 6.1v48.3c0 3.4 2.7 6.1 6.1 6.1H48c3.4 0 6.1-2.7 6.1-6.1V7.9c.1-3.4-2.7-6.1-6-6.1M15.9 6.3H48c.9 0 1.6.7 1.6 1.6v13.9H14.3V7.9c0-.9.7-1.6 1.6-1.6m-1.6 20h15.4v14.2H14.3zm20 0h15.4v14.2H34.3zm-20 29.8V45h15.4v12.8H15.9c-.9 0-1.6-.8-1.6-1.7m33.8 1.7H34.3V45h15.4v11.1c0 .9-.7 1.7-1.6 1.7" />
    <path d="M18.8 20.3h26.4c1.2 0 2.3-1 2.3-2.3v-6.8c0-1.2-1-2.3-2.3-2.3H18.8c-1.2 0-2.3 1-2.3 2.3V18c0 1.2 1 2.3 2.3 2.3m2.2-6.9h22v2.3H21z" />
  </svg>
);
export default IconCalculator;
