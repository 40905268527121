import * as React from "react";
const IconItalicCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54 2H20.4c-.6 0-1 .4-1 1s.4 1 1 1H36L26 60H10c-.6 0-1 .4-1 1s.4 1 1 1h33.6c.6 0 1-.4 1-1s-.4-1-1-1H28L38 4h16c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconItalicCopy;
