import * as React from "react";
const IconPointerTopCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.3 30.5c0-1.5 0-3.2-.6-4.8-.7-1.8-2.2-3.3-4.1-4-1.5-.5-3.1-.5-4.5 0-1.9-2.5-5.3-3.5-8.2-2.5-1.9-2.1-4.9-2.9-7.5-2 0-.4 0-.9.1-1.3.1-1.7.2-3.5 0-5.3-.2-2.6-1.2-4.9-2.6-6.5-.5-.4-.9-.8-1.4-1.1C29 2 27 1.7 25 2.3c-2.1.6-3.7 2-4.6 3.9-.8 1.7-.9 3.6-.9 5.5-.1 4.8-.1 9.6 0 14.3-1.1-1-2.3-1.9-3.6-2.6-2.4-1.2-4.8-1.6-6.9-.9-2.6.8-4.4 3.3-4.3 5.8 0 2.4 1.5 4.2 2.8 5.9 2.6 3.4 5.3 7.3 8.4 12.3.1.2.3.7.6 1.2 1.4 2.9 4.4 9 8.8 11.4 3.7 2 8.9 2.7 13.3 2.7 1.3 0 2.5-.1 3.6-.2 5.6-.6 10.1-2.7 12.8-6 3.5-4.2 4.1-10.1 4.3-14.8.1-3.4.1-6.9 0-10.3m-2 10.4c-.2 4.4-.8 9.9-3.9 13.7-3.1 3.7-7.9 4.9-11.5 5.3-4.7.5-11.3 0-15.7-2.3-3.8-2-6.6-7.7-7.9-10.5-.3-.6-.5-1.1-.7-1.4-3.1-5.1-5.8-9.1-8.5-12.6-1.2-1.5-2.3-3-2.4-4.7 0-1.7 1.3-3.3 3-3.9.6-.2 1.1-.3 1.7-.3 1.5 0 2.9.6 3.7 1 1.8.9 3.4 2.4 4.9 3.8.3.3.7.3 1.1.2q.6-.3.6-.9c-.1-5.4-.1-11 0-16.5.1-1.8.1-3.4.8-4.7.6-1.3 1.8-2.4 3.3-2.8 1.2-.5 2.6-.3 3.6.4.3.2.7.5 1 .8 1.1 1.2 1.8 3.1 2.1 5.3.2 1.6.1 3.2-.1 5-.1 1-.1 2-.1 3 0 .4.2.7.5.9s.7.2 1 0c2.2-1.4 5.4-.7 6.9 1.4.3.4.8.5 1.3.3 2.3-1.2 5.5-.2 6.8 2.1.1.2.3.4.6.5s.5 0 .8-.1c1.1-.6 2.4-.7 3.7-.2 1.4.5 2.5 1.5 3 2.8.5 1.2.5 2.7.5 4.1 0 3.3 0 6.8-.1 10.3" />
  </svg>
);
export default IconPointerTopCopy;
