import * as React from "react";
const IconFirecrackersAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53 15.1h-5.1C47.9 7.9 42 2 34.7 2h-4.5C23 2 17 7.9 17 15.2v7.7c0 .6.4 1 1 1s1-.4 1-1v-7.7C19 9 24.1 4 30.2 4h4.5c6.2 0 11.2 5 11.2 11.1h-5.1c-2.2 0-4 1.8-4 4V58c0 2.2 1.8 4 4 4H53c2.2 0 4-1.8 4-4V19.1c0-2.2-1.8-4-4-4m-14.2 12H55v23H38.8zm2-10H53c1.1 0 2 .9 2 2V25H38.8v-5.9c0-1.1.9-2 2-2M53 60H40.8c-1.1 0-2-.9-2-2v-5.9H55V58c0 1.1-.9 2-2 2M18 29c-.6 0-1 .4-1 1v3.6c0 .6.4 1 1 1s1-.4 1-1V30c0-.6-.4-1-1-1" />
    <path d="M29.4 26.7h-3.6c-.6 0-1 .4-1 1s.4 1 1 1h3.6c.6 0 1-.4 1-1s-.4-1-1-1M11.6 26.7H8c-.6 0-1 .4-1 1s.4 1 1 1h3.6c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconFirecrackersAlt2;
