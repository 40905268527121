import * as React from "react";
const IconChromeCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62.1 39.2c3.3-13.8-3.3-28.7-16.6-35.1-3.3-1.6-6.6-2.5-10-2.9h-.1C22.7-.2 10 6.4 4.1 18.5-1.1 29.2.4 41.4 7 50.4c0 .1.1.1.1.1 2.9 3.9 6.7 7.1 11.3 9.4C22.8 62 27.4 63 32 63c11.5 0 22.6-6.4 27.9-17.5 1-2 1.7-4.1 2.2-6.3q0 .15 0 0m-1.6-1.7-22-14.3c-.3-.2-.6-.4-1-.6l-.1-.1h-.1c-.2-.1-.3-.2-.5-.3-3.3-1.6-7.1-1.4-10.1.3L36 3.4c3 .4 5.9 1.3 8.7 2.6 12 5.8 18.2 18.9 15.8 31.5m-20.4-1.6c-2.2 4.5-7.6 6.3-12 4.2-4.5-2.2-6.3-7.6-4.2-12 1.6-3.2 4.8-5.1 8.1-5.1 1.3 0 2.7.3 3.9.9.2.1.4.2.5.3l1 .7c3.4 2.5 4.6 7.1 2.7 11M5.9 19.3C11.2 8.4 22.4 2.3 33.8 3L22.1 27.2c-2.6 5.5-.4 12 5.1 14.7.5.2.9.4 1.4.6L8.3 48.7c-5.9-8.4-7.1-19.6-2.4-29.4m52.2 25.4c-7 14.4-24.4 20.4-38.7 13.4-3.9-1.9-7.2-4.6-9.7-7.7l26-8c.1 0 .1-.1.2-.1 2.6-.9 4.9-2.8 6.2-5.5 1.4-2.9 1.4-6.1.3-8.9L60 39.5c-.5 1.8-1.1 3.5-1.9 5.2" />
  </svg>
);
export default IconChromeCopy;
