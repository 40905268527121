import * as React from "react";
const IconCf = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 513 342"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 0h513v342H0z" />
    <path  d="M0 171h211.5v85.3H0zm300.5 0H512v85.3H300.5z" />
    <path  d="M0 0v85.3h211.5V0h89v85.3H513V0z" />
    <path  d="M300.5 85.3H512v85.3H300.5zM0 85.3h211.5v85.3H0z" />
    <path
      
      d="M300.5 342h-89v-86H0v86h513v-86H300.5zM105.7 8.6l8.6 26.2h27.5L119.5 51l8.6 26.2L105.7 61 83.4 77.2 91.9 51 69.6 34.8h27.6z"
    />
  </svg>
);
export default IconCf;
