import * as React from "react";
const IconMobileTarget1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M33.5 46.5c-.6 0-1 .4-1 1v.2H4V12.4C4 10 6 8 8.4 8H28c2.4 0 4.4 2 4.4 4.4v1.4c0 .6.4 1 1 1s1-.4 1-1v-1.4C34.4 8.9 31.5 6 28 6H8.4C4.9 5.9 2 8.8 2 12.4v39.3c0 3.5 2.9 6.4 6.4 6.4H28c3.5 0 6.4-2.9 6.4-6.4v-4.2c.1-.6-.4-1-.9-1m-5.4 9.6H8.4c-2.4 0-4.4-2-4.4-4.4v-2h28.5v2c0 2.4-2 4.4-4.4 4.4" />
    <path d="M50 30.1c-.6 0-1 .4-1 1 0 5.3-4.3 9.6-9.6 9.6s-9.6-4.3-9.6-9.6 4.3-9.6 9.6-9.6c.9 0 1.9.1 2.7.4.5.2 1.1-.1 1.2-.7.2-.5-.1-1.1-.7-1.2-1.1-.3-2.2-.5-3.3-.5-6.4 0-11.6 5.2-11.6 11.6S33 42.7 39.4 42.7 51 37.5 51 31.1c0-.5-.5-1-1-1" />
    <path d="M61 17.2h-5.8V12c0-.6-.4-1-1-1s-1 .4-1 1v5.6L39.9 29.5c-.4.4-.5 1-.1 1.4.2.2.5.3.7.3s.5-.1.7-.3l13.3-11.8H61c.6 0 1-.4 1-1s-.4-.9-1-.9M36.2 31.1c0-.6-.4-1-1-1s-1 .4-1 1c0 2.9 2.4 5.2 5.2 5.2.6 0 1-.4 1-1s-.4-1-1-1c-1.8.1-3.2-1.4-3.2-3.2" />
  </svg>
);
export default IconMobileTarget1;
