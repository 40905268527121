import * as React from "react";
const IconBulldozer = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.3 41.3 50.1 29.9c-.7-.7-1.6-.9-2.5-.5s-1.4 1.2-1.4 2.1v11.8h-8.9V25.2c0-.6-.4-1-1-1h-4.6v-7.3c0-2-1.6-3.6-3.6-3.6H16.7c-2 0-3.6 1.6-3.6 3.6v7.3H5.6c-2 0-3.6 1.6-3.6 3.6v16.5c0 .6.4 1 1 1h1.9c.5 3.1 3.2 5.4 6.5 5.4s6-2.4 6.5-5.4h3.7c.5 3.1 3.2 5.4 6.5 5.4s6-2.4 6.5-5.4h25.3c.9 0 1.7-.5 2.1-1.4.2-.9 0-1.9-.7-2.6m-50 7.4c-2.5 0-4.6-2-4.6-4.4s2-4.4 4.6-4.4 4.6 2 4.6 4.4-2.1 4.4-4.6 4.4m16.7 0c-2.5 0-4.6-2-4.6-4.4s2-4.4 4.6-4.4 4.6 2 4.6 4.4-2.1 4.4-4.6 4.4m0-10.9c-3.3 0-6 2.4-6.5 5.4h-3.7c-.5-3.1-3.2-5.4-6.5-5.4s-6 2.4-6.5 5.4H4V27.8c0-.9.7-1.6 1.6-1.6h8.5c.6 0 1-.4 1-1v-8.3c0-.9.7-1.6 1.6-1.6h11.5c.9 0 1.6.7 1.6 1.6v8.3c0 .6.4 1 1 1h4.6v17.1h-.9c-.5-3.1-3.3-5.5-6.5-5.5m32 5.3c0 .1-.1.2-.3.2H48.2V31.5c0-.2.1-.2.2-.3.1 0 .2 0 .3.1l11.2 11.5c.1.1.1.2.1.3" />
  </svg>
);
export default IconBulldozer;
