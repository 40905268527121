import * as React from "react";
const IconRocketSearch = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M38 39.3v-.7l10-10C59.1 17.4 60.2 9.4 59.8 3c0-.5-.4-.9-.9-.9-6.4-.4-14.5.7-25.6 11.8l-10 10H11.9c-.3 0-.5.1-.7.3L4.4 31c-.2.2-.3.4-.3.7s.1.5.3.7l7.8 7.8c-1 .4-1.8.9-2.6 1.6-2.6 2.7-4 12.4-4.1 13.5 0 .3.1.6.3.8s.4.3.7.3h.1c1.1-.2 10.8-1.6 13.5-4.2.7-.7 1.3-1.6 1.6-2.6l7.8 7.8c.2.2.5.3.7.3.3 0 .5-.1.7-.3l1.3-1.3c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-.6.6L6.5 31.6l5.8-5.8h11.4c.3 0 .5-.1.7-.3l10.3-10.3C44.7 5.3 51.8 3.8 57.9 4c.2 6-1.2 13.1-11.3 23.2L36.3 37.5c-.2.2-.3.4-.3.7v1.1c0 .6.4 1 1 1s1-.5 1-1M18.7 50.8c-1.6 1.6-7.6 2.9-11 3.5.7-4 2-9.5 3.5-11 .8-.8 1.7-1.2 2.8-1.4l6.2 6.2q-.45 1.5-1.5 2.7" />
    <path d="M50.8 54.8c2.2-2.9 2-7.1-.7-9.8-1.4-1.4-3.3-2.2-5.3-2.2s-3.8.8-5.2 2.2c-2.9 2.9-2.9 7.6 0 10.5 1.4 1.4 3.3 2.2 5.3 2.2q2.55 0 4.5-1.5l5.5 5.5c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4zm-5.9.9c-1.5 0-2.8-.6-3.9-1.6-2.1-2.1-2.1-5.6 0-7.7 1-1 2.4-1.6 3.9-1.6s2.8.6 3.9 1.6c2.1 2.1 2.1 5.6 0 7.7-1.1 1-2.5 1.6-3.9 1.6" />
  </svg>
);
export default IconRocketSearch;
