import * as React from "react";
const IconSalineAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M45.5 9H33V3c0-.6-.4-1-1-1s-1 .4-1 1v6H18.5c-1.7 0-3 1.3-3 3v24.6c0 6.6 5.3 12.1 11.8 12.3v6.4c0 .6.4 1 1 1H31V61c0 .6.4 1 1 1s1-.4 1-1v-4.7h2.6c.6 0 1-.4 1-1V49c6.6-.3 11.8-5.7 11.8-12.3V12c.1-1.6-1.3-3-2.9-3m-27 2h26.9c.6 0 1 .4 1 1v7.1H17.5V12c0-.5.5-1 1-1m10.9 43.3V49h5.2v5.3zm6.7-7.3h-8.2c-5.3 0-9.7-4-10.3-9.2h4.1c.6 0 1-.4 1-1s-.4-1-1-1h-4.2v-6.9h6.1c.6 0 1-.4 1-1s-.4-1-1-1h-6.1v-5.8h28.9v15.6c.1 5.7-4.6 10.3-10.3 10.3" />
  </svg>
);
export default IconSalineAlt;
