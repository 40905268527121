import * as React from "react";
const IconPieChartAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M38.8 2c-.6 0-1 .4-1 1v20.7c0 .6.4 1 1 1H61c.3 0 .5-.1.7-.3s.3-.5.3-.8C61.2 11.5 51 2 38.8 2m1 20.7V4c10.3.5 18.9 8.4 20.1 18.7z" />
    <path d="M55.5 32.1H31.1V8.6c0-.5-.4-1-1-1h-.9C14.2 7.6 2 19.8 2 34.8S14.2 62 29.3 62s27.3-12.2 27.3-27.2c0-.6 0-1.1-.1-1.7 0-.5-.4-1-1-1M29.3 60C15.3 60 4 48.7 4 34.8S15.2 9.7 29.1 9.6v23.6c0 .6.4 1 1 1h24.5v.6C54.6 48.7 43.2 60 29.3 60" />
  </svg>
);
export default IconPieChartAlt;
