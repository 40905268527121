import * as React from "react";
const IconRing1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m35 18.4 3.6-2.1c.3-.2.5-.5.5-.9v-7c0-.4-.2-.7-.5-.9l-6-3.5c-.3-.2-.7-.2-1 0l-6 3.5c-.4.3-.6.6-.6 1v7c0 .4.2.7.5.9l3.6 2.1c-10.7 1.5-19 10.6-19 21.7 0 12 9.8 21.8 21.9 21.8s21.9-9.8 21.9-21.9c0-11.1-8.2-20.3-18.9-21.7m-8-9.3 5-2.9 5 2.9v5.8l-5 2.9-5-2.9zM32 60c-11 0-19.9-8.9-19.9-19.9S21 20.2 32 20.2s19.9 8.9 19.9 19.9S43 60 32 60" />
  </svg>
);
export default IconRing1;
