import * as React from "react";
const IconMicrophoneCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M44.6 2C35 2 27.2 9.8 27.2 19.4c0 1.9.3 3.8.9 5.5L4.2 48.8C2.8 50.3 2 52.2 2 54.3s.8 4 2.2 5.5C5.8 61.2 7.7 62 9.7 62s4-.7 5.5-2.2L39 36v-.2c1.8.6 3.7 1 5.6 1C54.2 36.8 62 29 62 19.4S54.2 2 44.6 2M5.7 58.3c-1.1-1-1.7-2.5-1.7-4s.6-3 1.7-4.1L6.9 49l8.1 8.1-1.2 1.2c-2.2 2.2-5.9 2.2-8.1 0m10.7-2.5-8.1-8.1L29 26.9c1.7 3.5 4.6 6.4 8.1 8.1zm12.8-36.4q0-3.15 1.2-6l20.1 20.2c-1.8.8-3.8 1.2-5.9 1.2-8.5 0-15.4-6.9-15.4-15.4m23.2 13.3-21-21.1C34 7.1 39 4 44.6 4 53.1 4 60 10.9 60 19.4c0 5.7-3.1 10.6-7.6 13.3" />
  </svg>
);
export default IconMicrophoneCopy;
