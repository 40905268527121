import * as React from "react";
const IconMountains3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 50.2h-3L48.9 37c-.2-.3-.5-.4-.8-.4s-.6.2-.8.5l-2.6 4.1-10.2-14.6c-.2-.3-.5-.4-.8-.4s-.6.2-.8.4l-8.8 12.9-5.3-8.6c-.2-.3-.5-.5-.9-.5s-.7.2-.9.5L5.9 50.2H3c-.6 0-1 .4-1 1s.4 1 1 1h58c.6 0 1-.4 1-1s-.4-1-1-1M17.9 33.4l5.2 8.5c.2.3.5.5.8.5s.7-.2.8-.4l8.9-12.9 10.1 14.6c.2.3.5.4.8.4s.6-.2.8-.5l2.6-4.1 7.5 10.8H8.2zM52 24.8c3.6 0 6.5-2.9 6.5-6.5s-2.9-6.5-6.5-6.5-6.5 2.9-6.5 6.5 2.9 6.5 6.5 6.5m0-11c2.5 0 4.5 2 4.5 4.5s-2 4.5-4.5 4.5-4.5-2-4.5-4.5 2-4.5 4.5-4.5" />
  </svg>
);
export default IconMountains3;
