import * as React from "react";
const IconArrowsBreakH = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m60.8 31.4-9.4-11.2c-.4-.4-1-.5-1.4-.1s-.5 1-.1 1.4l8 9.5h-19v-9.8c0-.6-.4-1-1-1s-1 .4-1 1v21.6c0 .6.4 1 1 1s1-.4 1-1V33h19l-8 9.5c-.4.4-.3 1.1.1 1.4.2.2.4.2.6.2.3 0 .6-.1.8-.4l9.4-11.2c.3-.2.3-.8 0-1.1M26.2 20.2c-.6 0-1 .4-1 1V31h-19l8-9.5c.4-.4.3-1.1-.1-1.4-.4-.4-1.1-.3-1.4.1L3.2 31.4c-.3.4-.3.9 0 1.3l9.4 11.2c.2.2.5.4.8.4.2 0 .5-.1.6-.2.4-.4.5-1 .1-1.4l-8-9.5h19V43c0 .6.4 1 1 1s1-.4 1-1V21.2c.1-.5-.4-1-.9-1" />
  </svg>
);
export default IconArrowsBreakH;
