import * as React from "react";
const IconBackspace = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.4 14.1H20.9c-1.2 0-2.3.4-3.2 1.3L3.5 28.6C2.5 29.5 2 30.7 2 32s.5 2.5 1.5 3.4l14.2 13.3c.9.8 2 1.3 3.2 1.3h36.5c2.6 0 4.6-2.1 4.6-4.6V18.7c0-2.6-2.1-4.6-4.6-4.6M60 45.3c0 1.4-1.2 2.6-2.6 2.6H20.9c-.7 0-1.3-.3-1.8-.7L4.8 33.9c-.5-.5-.8-1.2-.8-1.9s.3-1.4.8-1.9L19 16.8c.5-.5 1.1-.7 1.8-.7h36.5c1.5 0 2.6 1.2 2.6 2.6v26.6z" />
    <path d="M43.8 24.4c-.4-.4-1-.4-1.4 0l-6.2 6.1-6.2-6.1c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l6.2 6.1-6.2 6.1c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3l6.2-6.1 6.2 6.1c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-6.2-6 6.2-6.1c.4-.4.4-1.1 0-1.5" />
  </svg>
);
export default IconBackspace;
