import * as React from "react";
const IconBrokenBone = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.4 11.9c-1.3-1.6-3.1-2.7-5.1-2.9-.3-2.7-2-4.9-4.6-6-2.9-1.2-6.1-.6-8.3 1.6-1.7 1.7-2 3.5-2.2 5.3-.3 2.2-.6 4.4-3.7 7.5l-5.3 5.2c-.2.1-.3.4-.2.7 0 .3.2.6.5.7l4.1 2.5 4.3 6.5c.2.2.4.4.7.4h.1c.3 0 .5-.1.7-.3 2.4-2.4 4.4-4.4 7.3-7.4 1.4-1.4 3.2-2.1 4.9-1.9 3.1.3 5.9-1.2 7.4-3.9 1.5-2.6 1.3-5.7-.6-8M59.3 19c-.9 1.6-2.8 3.1-5.5 2.8-2.3-.2-4.7.7-6.5 2.5-2.6 2.7-4.4 4.5-6.4 6.5l-3.8-5.6-.3-.3-3.2-1.9 4.3-4.3c3.6-3.5 4-6.4 4.3-8.6.2-1.6.4-2.9 1.7-4.1 1.9-1.9 4.4-1.8 6.1-1.1s3.4 2.4 3.4 5.1c0 .6.5 1 1 1 1.7 0 3.4.8 4.4 2.2 1.4 1.6 1.6 3.9.5 5.8M34.8 37.6l-4.3-1.5-4.3-7c-.2-.3-.4-.4-.7-.5-.3 0-.6.1-.8.3l-7.6 7.5c-2.3 2.3-5.1 3.6-7.9 3.7-4 .2-7.2 3.5-7.2 7.5 0 2 .8 3.9 2.2 5.3.9.9 2.3 1.9 4.4 2.1.2 1.6 1 3.2 2.1 4.3 1.4 1.4 3.3 2.2 5.4 2.2 1.7 0 3.3-.6 4.6-1.6 2.1-1.6 3.2-4.1 2.9-6.7-.3-2.8-.2-3 5.9-8.8 1.5-1.4 3.3-3.1 5.6-5.3.2-.2.4-.6.3-.9 0-.2-.3-.5-.6-.6m-6.6 5.5c-6.2 5.8-7 6.6-6.5 10.4.2 1.9-.6 3.7-2.1 4.9-.9.7-2.2 1.1-3.4 1.1-1.5 0-2.9-.6-4-1.6-1-1-1.6-2.4-1.6-3.8 0-.5-.3-.9-.8-1h-.3c-1.9 0-3.1-.9-3.9-1.6-1.1-1-1.6-2.4-1.6-3.9 0-3 2.3-5.4 5.3-5.5 3.2-.1 6.5-1.7 9.2-4.3l6.7-6.6 3.8 6.2c.1.2.3.3.5.4l3.1 1.1c-1.7 1.7-3.2 3.1-4.4 4.2" />
  </svg>
);
export default IconBrokenBone;
