import * as React from "react";
const IconBlisterPillsRoundX1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      d="M19 24a1 1 0 1 1-2 0 1 1 0 0 1 2 0M22 25a1 1 0 1 0 0-2 1 1 0 0 0 0 2M27 24a1 1 0 1 1-2 0 1 1 0 0 1 2 0M30 25a1 1 0 1 0 0-2 1 1 0 0 0 0 2M24 17a3 3 0 1 0 0-6 3 3 0 0 0 0 6"
    />
    <path
      
      fillRule="evenodd"
      d="M12 8a4 4 0 0 1 4-4h16a4 4 0 0 1 4 4v32a4 4 0 0 1-4 4H16a4 4 0 0 1-4-4zm20-2a2 2 0 0 1 2 2v15a1 1 0 1 0 0 2v15a2 2 0 0 1-2 2H16a2 2 0 0 1-2-2V25a1 1 0 1 0 0-2V8a2 2 0 0 1 2-2z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconBlisterPillsRoundX1;
