import * as React from "react";
const IconExcelLogo = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M15 8c0-1.105.806-2 1.8-2h23.4c.994 0 1.8.895 1.8 2v32c0 1.105-.806 2-1.8 2H16.8c-.994 0-1.8-.895-1.8-2v-6H7.8c-.994 0-1.8-.895-1.8-2V16c0-1.105.806-2 1.8-2H15zm13 6V8H17v6zm2-6v6h10V8zm-2 8h-4v7h4zm2 7v-7h10v7zm-2 2h-4v7h4zm2 7v-7h10v7zm-2 2H17v6h11zm2 6v-6h10v6zm-8-24v16H7.8V16zm-10.774 3h2.147l1.743 3.754L16.957 19h2.006L16.2 24l2.827 5H16.91l-1.899-3.93-1.89 3.93h-2.148l2.874-5.018z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconExcelLogo;
