import * as React from "react";
const IconTrashCanAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M49.6 9.3h-9.4V6.1c0-2.3-1.8-4.1-4.1-4.1h-8.2c-2.3 0-4.1 1.8-4.1 4.1v3.2h-9.4c-2.2 0-4 1.8-4 4v3.3c0 1.9 1.4 3.6 3.2 3.9l1.8 37.7c.1 2.1 1.8 3.8 3.9 3.8h25.3c2.1 0 3.8-1.7 3.9-3.8l1.8-37.7c1.8-.4 3.2-2 3.2-3.9v-3.3c.1-2.2-1.7-4-3.9-4M25.8 6.1c0-1.2.9-2.1 2.1-2.1h8.2c1.2 0 2.1.9 2.1 2.1v3.2H25.8zm20.8 52c0 1-.9 1.9-1.9 1.9H19.3c-1 0-1.9-.8-1.9-1.9l-1.8-37.6h32.8zm5-41.5c0 1.1-.9 2-2 2H14.4c-1.1 0-2-.9-2-2v-3.3c0-1.1.9-2 2-2h35.2c1.1 0 2 .9 2 2z" />
    <path d="M32 32.4c-.6 0-1 .4-1 1v16c0 .6.4 1 1 1s1-.4 1-1v-16c0-.6-.4-1-1-1M41.5 34.4c-.5 0-1 .4-1.1.9l-.6 12c0 .6.4 1 .9 1.1h.1c.5 0 1-.4 1-.9l.6-12c.1-.6-.3-1.1-.9-1.1M22.4 34.4c-.6 0-1 .5-.9 1.1l.7 12c0 .5.5.9 1 .9h.1c.6 0 1-.5.9-1.1l-.7-12c-.1-.5-.6-.9-1.1-.9" />
  </svg>
);
export default IconTrashCanAlt1;
