import * as React from "react";
const IconSvg = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M15.179 5C14.024 5 13 6.018 13 7.23v49.54c0 1.212 1.024 2.23 2.179 2.23h33.87C50.11 59 51 58.12 51 57V24.058q0-.11-.006-.212H35.046c-2.289 0-4.046-1.81-4.046-4.096V5zM33 5.88v13.87c0 1.213.892 2.096 2.046 2.096h14.66zM11 7.23C11 4.945 12.89 3 15.179 3h16.026c.993 0 2.062.332 2.82 1.094l17.712 16.927c.95.793 1.263 1.928 1.263 3.037V57c0 2.194-1.754 4-3.952 4h-33.87C12.89 61 11 59.056 11 56.77zm26.906 29.033c0-1.641 1.314-3 2.968-3h4.896a1 1 0 1 1 0 2h-4.896a.984.984 0 0 0-.968 1v6.89c0 .568.449 1 .968 1h2.951c.52 0 .968-.432.968-1v-.89c0-.568-.449-1-.968-1h-.97a1 1 0 0 1 0-2h.97c1.654 0 2.968 1.358 2.968 3v.89c0 1.641-1.313 3-2.968 3h-2.95c-1.655 0-2.969-1.359-2.969-3zm-2.702-2.5a1 1 0 0 1 .739 1.206l-2.023 8.42c-.741 3.082-5.089 3.043-5.78-.048l-1.873-8.387a1 1 0 1 1 1.952-.436l1.874 8.387c.229 1.026 1.637 1.035 1.882.016l2.023-8.419a1 1 0 0 1 1.206-.739m-18.613 3.538c0-1.912 1.53-3.49 3.45-3.49h3.267a1 1 0 1 1 0 2H20.04c-.785 0-1.45.652-1.45 1.49s.665 1.49 1.45 1.49h.899c1.875 0 3.368 1.54 3.368 3.407s-1.493 3.407-3.368 3.407H17.59a1 1 0 1 1 0-2h3.349c.74 0 1.368-.615 1.368-1.407s-.628-1.407-1.368-1.407h-.899c-1.92 0-3.45-1.578-3.45-3.49"
      clipRule="evenodd"
    />
  </svg>
);
export default IconSvg;
