import * as React from "react";
const IconDiploma1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M52.3 43.9c0-3.3-2-6.1-4.8-7.2V7.8c0-.4-.3-.8-.7-.9-2-.7-3.5-2.2-4.2-4.2-.2-.4-.5-.7-1-.7h-24c-.4 0-.8.3-.9.7-.7 2-2.2 3.5-4.2 4.2-.5.1-.8.5-.8.9v37.6c0 .4.3.8.7.9 2 .7 3.5 2.2 4.2 4.2.1.4.5.7.9.7H39V61c0 .3.2.6.4.8s.6.2.9.2l4.1-1.3 4.1 1.3h.3c.2 0 .4-.1.6-.2.3-.2.4-.5.4-.8V50.2c0-.2-.1-.4-.2-.5 1.7-1.4 2.7-3.5 2.7-5.8m-2 0c0 3.2-2.6 5.8-5.8 5.8s-5.8-2.6-5.8-5.8 2.6-5.8 5.8-5.8c.6 0 1.1.1 1.6.2h.1c2.3.8 4.1 3 4.1 5.6m-36.6.8V8.5c2-.9 3.6-2.5 4.5-4.5h22.7c.9 2 2.5 3.6 4.5 4.5v27.6c-.3 0-.7-.1-1-.1-4.3 0-7.8 3.5-7.8 7.8 0 2 .8 3.9 2 5.3H18.2c-.8-1.9-2.5-3.5-4.5-4.4m34.2 14.9-3.1-1q-.3-.15-.6 0l-3.1 1v-8.5h.5q1.35.6 3 .6c1.2 0 2.4-.3 3.4-.8v8.7z" />
    <path d="M26 12.9h7.3c.6 0 1-.4 1-1s-.4-1-1-1H26c-.6 0-1 .4-1 1s.4 1 1 1M22.7 20.8h13.7c.6 0 1-.4 1-1s-.4-1-1-1H22.7c-.6 0-1 .4-1 1s.5 1 1 1M38.6 28c0-.6-.4-1-1-1h-16c-.6 0-1 .4-1 1s.4 1 1 1h16c.5 0 1-.4 1-1" />
  </svg>
);
export default IconDiploma1;
