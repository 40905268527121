import * as React from "react";
const IconEvent = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M13.999 5.852a1 1 0 0 1 1 1v5.327h34V6.852a1 1 0 1 1 2 0v5.327H57c2.756 0 5 2.223 5 4.977v35.72c0 2.754-2.244 4.976-5 4.976H7c-2.756 0-5-2.222-5-4.976v-35.72c0-2.754 2.244-4.977 5-4.977h5.999V6.852a1 1 0 0 1 1-1m-1 8.327H7c-1.662 0-3 1.339-3 2.977v7.892h56v-7.892c0-1.638-1.338-2.977-3-2.977h-6.001v2.345a1 1 0 1 1-2 0v-2.345h-34v2.345a1 1 0 1 1-2 0zM60 27.048H4v25.828c0 1.638 1.338 2.976 3 2.976h50c1.662 0 3-1.338 3-2.976zm-29.845 6.36c.685-1.637 3.005-1.637 3.69 0l1.42 3.394 3.674.293c1.777.142 2.495 2.36 1.138 3.516l-2.79 2.377.853 3.56c.415 1.729-1.463 3.099-2.982 2.176L32 46.807l-3.157 1.917c-1.52.923-3.398-.447-2.983-2.176l.854-3.56-2.791-2.377c-1.357-1.155-.639-3.374 1.138-3.516l3.674-.293zm1.845.773-1.42 3.393a2 2 0 0 1-1.686 1.222l-3.674.293 2.79 2.376a2 2 0 0 1 .649 1.99l-.855 3.56 3.158-1.918a2 2 0 0 1 2.076 0l3.158 1.918-.855-3.56a2 2 0 0 1 .648-1.99l2.791-2.376-3.674-.293a2 2 0 0 1-1.686-1.222z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconEvent;
