import React, { useState, useRef, useEffect } from "react";
import classnames from "classnames";
import { format as formatDateFn } from "date-fns";
import PropTypes from "prop-types";
import BringzzIcon from "../BringzzIcon";
import BringzzInput from "../BringzzInput";
import DayPicker from "./Components/DayPicker";
import MonthPicker from "./Components/MonthPicker";
import DayMonthPicker from "./Components/DayMonthPicker";
import YearPicker from "./Components/YearPicker";
import MonthYearPicker from "./Components/MonthYearPicker";
import { default as Mode } from "../../utils/DateModes";
import BringzzBottomDrawer from "../BringzzBottomDrawer";

// TODO: Add multi language
/**
 * CustomDatePicker is a flexible date picker component that allows users to select dates, months, or years.
 * It supports different modes such as day, month, year, month-year, and day-month selection.
 *
 * @param {Object} props - The props for the CustomDatePicker component.
 * @param {string} [props.className] - Additional CSS classes to apply to the date picker input.
 * @param {function} [props.onChange] - Callback function that is called when the date is changed.
 * @param {function} [props.onClose] - Callback function that is called when the date picker is closed.
 * @param {string} [props.format="dd/MM/yyyy"] - The format of the date displayed in the input field.
 * @param {Mode} props.mode - The mode of the date picker, determining what options are available (day, month, year, month-year, day-month).
 * @param {Date} [props.maxDate] - The maximum date that can be selected.
 * @param {Date} [props.minDate=new Date(1990, 0, 1)] - The minimum date that can be selected.
 * @param {number} [props.maxYear] - The maximum year that can be selected.
 * @param {number} [props.minYear] - The minimum year that can be selected.
 */

const CustomDatePicker = ({
  className,
  onChange,
  onClose,
  format = "dd/MM/yyyy",
  mode,
  maxDate,
  minDate = new Date(1990, 0, 0),
  maxYear,
  minYear,
  defaultValue = '',
  ...props
}) => {
  const [selectedDate, setSelectedDate] = useState(defaultValue || new Date());
  const [showPicker, setShowPicker] = useState(false);
  const [year, setYear] = useState(selectedDate.getFullYear());
  const [month, setMonth] = useState(selectedDate.getMonth());
  const [day, setDay] = useState(selectedDate.getDate());

  // standardize maxDate and minDate to midday to avoid timezone issue
  // if (maxDate) {
  //   maxDate.setHours(12, 0, 0, 0);
  // }
  // minDate.setHours(12, 0, 0, 0);

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
    if (onChange) {
      onChange(formatDateFn(newDate, format));
    }
  };

  const closePicker = () => {
    setShowPicker(false);
    if (onClose) onClose();
  };

  // Render the appropriate UI based on the mode
  const renderCalendar = () => {
    switch (mode) {
      case Mode.DAY:
        return (
          <DayPicker
            closePicker={closePicker}
            selectedDate={selectedDate}
            maxDate={maxDate}
            minDate={minDate}
            onChange={handleDateChange}
            year={year}
            month={month}
            setYear={setYear}
            setMonth={setMonth}
          />
        );
      case Mode.MONTH:
        return (
          <MonthPicker
            closePicker={closePicker}
            selectedDate={selectedDate}
            onChange={handleDateChange}
          />
        );
      case Mode.YEAR:
        return (
          <YearPicker
            closePicker={closePicker}
            selectedDate={selectedDate}
            onChange={handleDateChange}
            minYear={minYear}
            maxYear={maxYear}
          />
        );
      case Mode.MONTH_YEAR:
        return (
          <MonthYearPicker
            closePicker={closePicker}
            year={year}
            setYear={setYear}
            maxDate={maxDate}
            minDate={minDate}
            onChange={handleDateChange}
          />
        );
      case Mode.DAY_MONTH:
        return (
          <DayMonthPicker
            closePicker={closePicker}
            day={day}
            setDay={setDay}
            month={month}
            setMonth={setMonth}
            selectedDate={selectedDate}
            onChange={handleDateChange}
          />
        );
      default:
        return (
          <DayPicker
            closePicker={closePicker}
            selectedDate={selectedDate}
            maxDate={maxDate}
            minDate={minDate}
            onChange={handleDateChange}
            year={year}
            month={month}
            setYear={setYear}
            setMonth={setMonth}
          />
        );
    }
  };

  return (
    <div>
      <BringzzInput
        type="text"
        className={classnames(
          "form-input ignore-click-outside text-sm w-full outline-none cursor-pointer",
          className
        )}
        defaultValue={formatDateFn(selectedDate, format)}
        readOnly
        onClick={(e) => {
          e.stopPropagation();
          setShowPicker(!showPicker);
        }}
        icon={{
          right: (
            <BringzzIcon
              icon="IconCalendar"
              folder="CustomIcons"
              className="h-6 w-6 text-[#8E8E93]"
              size="24"
            />
          ),
        }}
        {...props}
      />
      <div className="absolute inset-y-0 right-0 pr-2 flex items-center"></div>
      <BringzzBottomDrawer
        isOpen={showPicker}
        close={closePicker}
        header={false}
      >
        <div className="p-6">
          {renderCalendar()}
        </div>
      </BringzzBottomDrawer>

    </div>
  );
};

CustomDatePicker.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  format: PropTypes.string,
  mode: PropTypes.oneOf([
    Mode.DAY,
    Mode.MONTH,
    Mode.YEAR,
    Mode.MONTH_YEAR,
    Mode.DAY_MONTH,
  ]).isRequired,
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  defaultValue: PropTypes.instanceOf(Date),
  maxYear: PropTypes.number,
  minYear: PropTypes.number,
};

// Calculate the date 100 years ago
let currentDate = new Date();
currentDate.setFullYear(currentDate.getFullYear() - 100);
let minDate = currentDate;

CustomDatePicker.defaultProps = {
  className: "",
  onChange: null,
  onClose: null,
  format: "dd/MM/yyyy",
  minDate
};

export default CustomDatePicker;
