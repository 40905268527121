import * as React from "react";
const IconSc = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 85.333 512 341.333"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 85.337h512v341.326H0z" />
    <path  d="M235.454 85.337 0 426.663 427.345 85.337z" />
    <path  d="M512 329.393 0 426.663h512z" />
    <path  d="M512 85.337h-84.655L0 426.663l512-204.512z" />
    <path  d="M0 85.337v341.326L235.454 85.337z" />
  </svg>
);
export default IconSc;
