import * as React from "react";
const IconAmbulance2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.8 26.5 49.9 13.8c-1-1.1-2.4-1.8-3.9-1.8H7.1C4.3 12 2 14.3 2 17.2v24.6c0 2.8 2.3 5.1 5.1 5.1h2.4c.5 2.8 3 5 5.9 5s5.5-2.2 5.9-5H44c.5 2.8 3 5 5.9 5s5.5-2.2 5.9-5h1c2.8 0 5.1-2.3 5.1-5.1v-12c.1-1.2-.3-2.4-1.1-3.3M15.5 50c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4m34.4 0c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.7 4-4 4M60 41.8c0 1.7-1.4 3.1-3.1 3.1h-1c-.5-2.9-3-5-5.9-5s-5.5 2.2-5.9 5H21.5c-.5-2.9-3-5-5.9-5s-5.5 2.2-5.9 5H7.1c-1.7 0-3.1-1.4-3.1-3.1V17.2C4 15.4 5.4 14 7.1 14H46c.9 0 1.8.4 2.4 1.1l10.9 12.7c.5.6.8 1.3.8 2v12z" />
    <path d="M27.3 25.8h-4.8V21c0-.6-.4-1-1-1s-1 .4-1 1v4.8h-4.8c-.6 0-1 .4-1 1s.4 1 1 1h4.8v4.8c0 .6.4 1 1 1s1-.4 1-1v-4.8h4.8c.6 0 1-.4 1-1s-.5-1-1-1M52 29.2h-4.5c-1.7 0-3.1-1.4-3.1-3.1v-5.3c0-.6-.4-1-1-1s-1 .4-1 1v5.3c0 2.8 2.3 5.1 5.1 5.1H52c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconAmbulance2;
