import * as React from "react";
const IconQ = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M9 24c0-8.284 6.716-15 15-15s15 6.716 15 15c0 3.056-.915 5.9-2.485 8.272l1.606 1.607a3 3 0 1 1-4.242 4.242l-1.607-1.606A14.93 14.93 0 0 1 24 39c-8.284 0-15-6.716-15-15m15-13c-7.18 0-13 5.82-13 13s5.82 13 13 13c2.921 0 5.615-.962 7.785-2.588l.694-.52 2.814 2.815a1 1 0 0 0 1.414-1.414l-2.814-2.814.52-.694A12.94 12.94 0 0 0 37 24c0-7.18-5.82-13-13-13m0 4a9 9 0 1 0 0 18c1.39 0 2.706-.315 3.88-.877l-2.001-2.002a3 3 0 1 1 4.242-4.242l2.002 2.001A9 9 0 0 0 33 24a9 9 0 0 0-9-9m-11 9c0-6.075 4.925-11 11-11s11 4.925 11 11c0 2.24-.67 4.327-1.823 6.067l-.676 1.02-3.794-3.794a1 1 0 0 0-1.414 1.414l3.794 3.794-1.02.676A10.95 10.95 0 0 1 24 35c-6.075 0-11-4.925-11-11"
      clipRule="evenodd"
    />
  </svg>
);
export default IconQ;
