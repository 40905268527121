import * as React from "react";
const IconIntestine = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M24 4a1 1 0 0 1 1 1v3.455c0 .34-.058.679-.173.999s-.287.62-.511.878a2.5 2.5 0 0 1-.811.62 2.3 2.3 0 0 1-1.005.23H20.24c-1.231-.025-2.453.474-3.425 1.446-.974.974-1.623 2.352-1.79 3.88-.07.837.012 1.68.24 2.474a6.2 6.2 0 0 0 1.076 2.13 5.1 5.1 0 0 0 1.683 1.404c.226.116.732.247 1.384.345.62.094 1.235.139 1.593.139a1 1 0 1 1 0 2c-.479 0-1.196-.056-1.892-.161-.663-.1-1.454-.266-1.994-.541a7.1 7.1 0 0 1-2.345-1.95 8.2 8.2 0 0 1-1.427-2.815 8.9 8.9 0 0 1-.31-3.206l.003-.021c.21-1.95 1.04-3.767 2.364-5.092 1.326-1.325 3.058-2.065 4.87-2.032H22.5q.067.001.144-.034a.5.5 0 0 0 .162-.128.8.8 0 0 0 .139-.243A1 1 0 0 0 23 8.455V5a1 1 0 0 1 1-1"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M14.114 23.702A6.34 6.34 0 0 1 17 23a1 1 0 1 1 0 2c-.675 0-1.348.163-1.976.484a5.1 5.1 0 0 0-1.684 1.404 6.2 6.2 0 0 0-1.076 2.13 6.9 6.9 0 0 0-.24 2.473c.167 1.529.816 2.907 1.79 3.88.409.41 1.139.791 2.024 1.065.87.269 1.763.395 2.4.382H22.5c.35 0 .692.08 1.005.23.312.148.586.362.81.62.225.259.397.558.512.878s.173.66.173 1V43a1 1 0 1 1-2 0v-3.455a1 1 0 0 0-.055-.322.8.8 0 0 0-.14-.243.5.5 0 0 0-.161-.128.3.3 0 0 0-.144-.034h-4.232c-.884.016-1.987-.152-3.02-.471-1.023-.316-2.108-.82-2.849-1.561-1.324-1.325-2.155-3.142-2.364-5.092l-.002-.021a8.9 8.9 0 0 1 .309-3.206 8.2 8.2 0 0 1 1.427-2.816 7.1 7.1 0 0 1 2.345-1.949M31.003 4a1 1 0 0 1 1 1v5.182c0 1.02-.174 2.034-.516 2.983A8 8 0 0 1 30 15.724q-.263.302-.59.613c1.66.096 3.452.387 4.97 1.172 1.031.533 1.953 1.303 2.61 2.398C37.646 21 38 22.354 38 24c0 1.64-.343 2.992-.982 4.084-.64 1.095-1.54 1.867-2.555 2.403-1.537.813-3.363 1.098-5.044 1.185q.306.292.578.606c1.294 1.49 2.002 3.485 2.002 5.54V43a1 1 0 1 1-2 0v-5.182c0-1.608-.556-3.128-1.512-4.228-1.01-1.165-2.284-1.883-3.487-1.884a1 1 0 0 1 0-2h2.997c1.978 0 4.013-.184 5.531-.987.738-.39 1.34-.92 1.764-1.645.425-.728.708-1.715.708-3.074 0-1.354-.29-2.338-.725-3.065-.435-.724-1.054-1.257-1.814-1.65-1.563-.808-3.647-.99-5.646-.99h-2.997a1 1 0 0 1 0-2h.003c.635-.001 1.268-.145 1.864-.43.569-.27 1.308-.88 1.805-1.453a6.1 6.1 0 0 0 1.116-1.924 6.8 6.8 0 0 0 .397-2.306V5a1 1 0 0 1 1-1"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M16 24a1 1 0 0 1 1-1h13a1 1 0 1 1 0 2H17a1 1 0 0 1-1-1M22 17.294a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2h-2a1 1 0 0 1-1-1M17 30.706a1 1 0 0 1 1-1h7a1 1 0 1 1 0 2h-7a1 1 0 0 1-1-1"
      clipRule="evenodd"
    />
  </svg>
);
export default IconIntestine;
