import * as React from "react";
const IconHexagons1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m45.4 21.6-11.1-6.4c-1.4-.8-3.2-.8-4.6 0l-11.1 6.4c-1.4.8-2.3 2.4-2.3 4v12.7c0 1.7.9 3.2 2.3 4l11.1 6.4c.7.4 1.5.6 2.3.6s1.6-.2 2.3-.6l11.1-6.4c1.4-.8 2.3-2.4 2.3-4V25.6c0-1.6-.9-3.1-2.3-4m.3 16.8c0 .9-.5 1.8-1.3 2.3L33.3 47c-.8.5-1.8.5-2.6 0l-11.1-6.4c-.8-.5-1.3-1.3-1.3-2.3V25.6c0-.9.5-1.8 1.3-2.3L30.7 17c.4-.2.9-.3 1.3-.3s.9.1 1.3.3l11.1 6.4c.8.5 1.3 1.3 1.3 2.3z" />
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
  </svg>
);
export default IconHexagons1;
