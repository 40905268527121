import * as React from "react";
const IconWindmill = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m42.5 28.8-2-10c-.2-.8-.5-1.5-.9-2.2L55.4 3.8c.4-.3.5-1 .1-1.4-.3-.4-1-.5-1.4-.1L38.5 15c-1.6-1.8-3.9-2.9-6.3-2.9-2.6 0-4.9 1.1-6.5 3L9.9 2.2c-.5-.3-1.1-.3-1.5.2-.3.4-.2 1 .2 1.4l15.9 12.9c-.3.7-.6 1.4-.8 2.2l-2 9.7L8.6 39.3c-.4.3-.5 1-.1 1.4.1.2.4.3.7.3.2 0 .4-.1.6-.2L21 31.7l-5.8 27.4c-.1.7 0 1.4.5 2s1.1.9 1.9.9h28.7c.7 0 1.4-.3 1.9-.9s.6-1.3.5-2l-5.5-27.2 11 8.9c.2.2.4.2.6.2.3 0 .6-.1.8-.4.3-.4.3-1.1-.1-1.4zm4.2 31.1c0 .1-.1.1-.3.1H17.6c-.2 0-.3-.1-.3-.1 0-.1-.1-.2-.1-.3l6.4-30.3 2.1-10c.6-3 3.3-5.2 6.4-5.2 3 0 5.7 2.2 6.4 5.2l8.2 40.3c.1.1 0 .2 0 .3" />
  </svg>
);
export default IconWindmill;
