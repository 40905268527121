import * as React from "react";
const IconInfiniteCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.8 23.5c-2.4-2.8-6-4.4-9.7-4.4-4.5 0-9.1 4.7-11.9 7.6L32 31.9l-7.8-7.8c-2.4-2.4-4.9-4.9-9.3-4.9-3.7 0-7.2 1.6-9.7 4.4-2.5 2.7-3.6 6.4-3.1 10.1.6 4.8 4 9 8.6 10.5 3.4 1.1 6.9.8 10-.8 1.9-1 6.6-4.1 9.3-6.8l1.9-1.9 2.6 2.6q3.45 3.45 7.8 5.7l.8.4c1.8 1 3.9 1.4 5.9 1.4 1.4 0 2.8-.2 4.1-.7 4.6-1.5 8-5.6 8.6-10.5.7-3.6-.4-7.3-2.9-10.1M28.7 35.2c-2.5 2.5-7.1 5.5-8.8 6.4-2.6 1.3-5.6 1.6-8.5.6-3.9-1.3-6.8-4.7-7.3-8.8-.4-3.2.5-6.3 2.6-8.7 2.1-2.3 5-3.7 8.2-3.7 3.6 0 5.6 2 7.9 4.3l7.8 7.8zm31.2-1.7c-.5 4.1-3.4 7.5-7.3 8.8-2.9.9-5.9.7-8.5-.6l-.8-.4c-2.7-1.4-5.2-3.2-7.3-5.3l-2.6-2.6 5.2-5.2c2.6-2.6 6.9-7 10.5-7 3.1 0 6.1 1.3 8.2 3.7 2.1 2.3 3 5.4 2.6 8.6" />
  </svg>
);
export default IconInfiniteCopy;
