import * as React from "react";
const IconBullhornCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.9 2h-8.7c-2.8 0-5 2.2-5 5v.6l-26.4 2.7H7.3c-2.8 0-5.1 2.2-5.1 4.9v17.1c0 2.1 1.4 3.9 3.4 4.6l3.7 20.7c.6 2.6 3.1 4.5 5.9 4.5 1.9 0 3.7-.8 4.8-2.3 1-1.2 1.4-2.8 1.1-4.3L18 37.4l25.3 4.9v.9c0 2.8 2.2 5 5 5H57c2.8 0 5-2.2 5-5V7c-.1-2.8-2.4-5-5.1-5M4.1 32.2v-17c0-1.6 1.4-2.9 3.1-2.9h8.6v22.8H7.3c-1.8 0-3.2-1.3-3.2-2.9m14.3 26.3c-.8.9-2 1.5-3.3 1.5-1.9 0-3.5-1.3-3.9-2.9l-3.6-20h8.2L19 55.9c.3.9 0 1.8-.6 2.6m-.6-23.2V12.2l25.3-2.6v30.6zm42.1 7.8c0 1.7-1.3 3-3 3h-8.7c-1.7 0-3-1.3-3-3V7c0-1.7 1.3-3 3-3h8.7c1.7 0 3 1.3 3 3z" />
  </svg>
);
export default IconBullhornCopy;
