import * as React from "react";
const IconSurfboardAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.4 4.4-.3-.9c-.1-.3-.4-.6-.7-.7l-.9-.3c-.2-.1-4.3-1.2-10.8 0-3.7.7-7.4 2.1-11 4.1-2 1.1-3.9 2.3-5.9 3.8h-.1c-2.4 1.8-4.9 3.9-7.2 6.2-9.1 9.1-14.6 18.1-17.7 24-3.4 6.5-4.5 10.9-4.6 11.1L2 53.4c-.1.5.1 1 .7 1.2l5.1 1.7 1.7 5.1c.1.3.5.6.9.6h.3l1.5-.4c.2 0 4.6-1.2 11.1-4.6 4.7-2.4 11.2-6.3 18.2-12.3 1.9-1.6 3.8-3.4 5.8-5.3 4.2-4.2 7.6-8.6 10-13.1 2-3.6 3.3-7.4 4.1-11 1.3-6.6.1-10.7 0-10.9M26.1 18.1c1.9-1.9 3.9-3.6 5.8-5.2l7.8 30.6c-5 4.3-9.8 7.4-13.8 9.7L19 26c2.1-2.6 4.4-5.2 7.1-7.9m-3.7 37.1c-6.3 3.3-10.5 4.4-10.7 4.4l-.7.2-1.5-4.6q-.15-.45-.6-.6L4.2 53l.2-.6c0-.2 1.2-4.4 4.4-10.7 1.9-3.6 4.7-8.4 8.6-13.6l6.7 26.2c-.6.3-1.2.6-1.7.9m37.1-40.4c-.7 3.5-2 7-3.9 10.4-2.3 4.3-5.6 8.6-9.7 12.7-1.5 1.5-3 2.9-4.4 4.2l-7.8-30.4c1.7-1.2 3.4-2.3 5.1-3.2 3.5-1.9 7-3.2 10.4-3.9 4.5-.9 7.6-.5 9-.2l-4.4 4.4c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l4.4-4.4c.4 1.3.7 4.5-.1 9" />
  </svg>
);
export default IconSurfboardAlt2;
