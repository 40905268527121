import * as React from "react";
const IconMoneySecurity = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M35.1 27h4.6c.6 0 1-.4 1-1s-.4-1-1-1h-1.8v-1.1c0-.6-.4-1-1-1s-1 .4-1 1V25h-.8c-1.9 0-3.5 1.5-3.5 3.4s1.6 3.4 3.5 3.4h2.1c.8 0 1.5.6 1.5 1.4s-.7 1.4-1.5 1.4h-4.6c-.6 0-1 .4-1 1s.4 1 1 1h3.2v1.1c0 .6.4 1 1 1s1-.4 1-1v-1.2c1.6-.3 2.8-1.7 2.8-3.4 0-1.9-1.6-3.4-3.5-3.4H35c-.8 0-1.5-.6-1.5-1.4s.8-1.3 1.6-1.3" />
    <path d="M41.1 44.9H16.2c-1.4 0-2.6-1.2-2.6-2.6V19.5c0-1.4 1.2-2.6 2.6-2.6h40c1.4 0 2.6 1.2 2.6 2.6v13.7c0 .6.4 1 1 1s1-.4 1-1V19.5c0-2.5-2.1-4.6-4.6-4.6h-5v-2.6c0-2.5-2.1-4.6-4.6-4.6h-40C4.1 7.7 2 9.8 2 12.3v22.8c0 2.5 2.1 4.6 4.6 4.6h5v2.6c0 2.5 2.1 4.6 4.6 4.6h24.9c.6 0 1-.4 1-1s-.5-1-1-1M6.6 37.7c-1.4 0-2.6-1.2-2.6-2.6V12.3c0-1.4 1.2-2.6 2.6-2.6h40c1.4 0 2.6 1.2 2.6 2.6v2.6h-33c-2.5 0-4.6 2.1-4.6 4.6v18.2z" />
    <path d="M61 46.4h-1.9v-2.9c0-2.7-2.2-4.8-4.8-4.8s-4.8 2.2-4.8 4.8v2.9h-1.9c-.6 0-1 .4-1 1v7.9c0 .6.4 1 1 1H61c.6 0 1-.4 1-1v-7.9c0-.5-.4-1-1-1m-9.6-2.9c0-1.6 1.3-2.8 2.8-2.8S57 42 57 43.5v2.9h-5.7v-2.9zM60 54.3H48.5v-5.9H60z" />
  </svg>
);
export default IconMoneySecurity;
