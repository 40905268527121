import * as React from "react";
const IconBoxOpen = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.5 10.9c-.5-.3-1.1-.1-1.4.4l-7.4 13.8H11.3L3.9 11.4c-.3-.5-.9-.7-1.4-.4s-.7.9-.4 1.4l7.6 14.1V48c0 3 2.4 5.3 5.4 5.3H49c3 0 5.3-2.4 5.3-5.3V26.4l7.6-14.1c.2-.5.1-1.1-.4-1.4M49 51.2H15c-1.8 0-3.4-1.5-3.4-3.3V27.1h40.6v20.7c.1 1.9-1.4 3.4-3.2 3.4" />
    <path d="M44.4 42.9h-3.6c-.6 0-1 .4-1 1s.4 1 1 1h3.6c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconBoxOpen;
