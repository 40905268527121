import * as React from "react";
const IconChineseYuanRound = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M36.6 23.3 32 28.9l-4.6-5.6c-.4-.4-1-.5-1.4-.1s-.5 1-.1 1.4l5 6.1h-3.1c-.6 0-1 .4-1 1s.4 1 1 1H31v7.4c0 .6.4 1 1 1s1-.4 1-1v-7.4h3.2c.6 0 1-.4 1-1s-.4-1-1-1h-3.1l5-6.1c.4-.4.3-1.1-.1-1.4-.4-.4-1-.3-1.4.1" />
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m19.2 9.7-5.1 5.1c-3.7-3.4-8.7-5.6-14.1-5.6s-10.4 2.1-14.1 5.6l-5.1-5.1C17.8 6.9 24.6 4 32 4s14.2 2.9 19.2 7.7M32 50.8c-10.3 0-18.8-8.4-18.8-18.8S21.7 13.2 32 13.2 50.8 21.6 50.8 32 42.3 50.8 32 50.8M11.4 13.1l5.1 5.1c-3.3 3.7-5.3 8.5-5.3 13.8s2 10.2 5.4 13.9L11.5 51C6.8 46 4 39.3 4 32s2.8-13.9 7.4-18.9m1.5 39.3 5.1-5.1c3.7 3.4 8.6 5.5 14 5.5 5.2 0 9.9-1.9 13.5-5.1l5.1 5.1C45.7 57.3 39.2 60 32 60c-7.4 0-14.1-2.9-19.1-7.6m39.2-.9L47 46.3c3.6-3.7 5.8-8.8 5.8-14.3 0-5.3-2-10.1-5.3-13.8l5.1-5.1c4.6 5 7.4 11.6 7.4 18.9 0 7.6-3 14.4-7.9 19.5" />
  </svg>
);
export default IconChineseYuanRound;
