import * as React from "react";
const IconDatabaseCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M52.3 38.6V13.5C52.3 7.1 43.4 2 32 2S11.7 7.1 11.7 13.5v37C11.7 56.8 20.8 62 32 62s20.3-5.2 20.3-11.5V38.6M32 4c9.9 0 18.3 4.4 18.3 9.5S41.9 23 32 23s-18.3-4.4-18.3-9.5S22.1 4 32 4m0 21.1c8.2 0 15-2.6 18.3-6.5v7.6c-.1 2.2-1.8 3.9-3.2 4.9-3.4 2.6-9 4.1-15.1 4.1-6 0-11.6-1.5-15.1-4.1-1.4-1-3-2.7-3.2-4.8v-7.6c3.3 3.8 10.1 6.4 18.3 6.4m-16.3 7.6c3.8 2.8 9.9 4.5 16.3 4.5 6.5 0 12.5-1.7 16.3-4.5.8-.6 1.5-1.2 2-1.8v8c-.3 4.9-8.5 9-18.3 9s-18-4.1-18.3-9V31c.6.5 1.2 1.1 2 1.7m34.6 17.8c0 5.2-8.4 9.5-18.3 9.5-9.7 0-18.3-4.5-18.3-9.5v-6.9c3.2 3.8 10.1 6.3 18.3 6.3s15-2.5 18.3-6.3z" />
  </svg>
);
export default IconDatabaseCopy;
