import * as React from "react";
const IconHeartrateMonitor = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.6 40.9V15.2c0-2.2-1.8-4-4-4H9.4c-2.2 0-4 1.8-4 4v25.6c-1.9.4-3.4 2-3.4 4v3.9c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4v-3.9c0-2-1.5-3.6-3.4-3.9M7.4 28.7h10.8c.3 0 .6-.2.8-.4l3.6-5L28 33c.2.3.5.5.8.5s.6-.2.8-.4L33 28h23.3v13H7.4zm2-15.5h45.1c1.1 0 2 .9 2 2v10.6H32.7c-.3 0-.6.2-.8.4l-2.8 4.2-5.4-9.6c-.2-.3-.5-.5-.8-.5s-.7.1-.9.4l-4.2 5.9H7.4V15.2c0-1.1.9-2 2-2M60 48.8c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2v-3.9c0-1.1.9-2 2-2h52c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconHeartrateMonitor;
