import * as React from "react";
const IconBox4 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 12.8 34.6 2.6c-1.7-.8-3.6-.8-5.3 0L7 12.8c-1.1.5-2 1.3-2.7 2.3-.6 1-1 2.2-1 3.4v25.9c0 2.4 1.3 4.6 3.5 5.7l22.3 11.2c.9.5 1.9.7 2.9.7s2-.2 2.9-.7l22.3-11.2c2.2-1.1 3.5-3.3 3.5-5.7V18.6c0-2.5-1.5-4.8-3.7-5.8M30.2 4.4c.6-.3 1.2-.4 1.8-.4s1.2.1 1.8.4l22.3 10.2c.4.2.7.4 1 .6l-11.2 5.5L21.3 8.4zM7.9 14.6 19 9.5l24.6 12.3L32 27.5 6.9 15.2c.3-.2.6-.4 1-.6m-.2 33.7c-1.5-.7-2.4-2.2-2.4-3.9V18.6c0-.6.1-1.2.3-1.7L31 29.3v30.6c-.3-.1-.6-.2-1-.3zm51-3.9c0 1.7-.9 3.1-2.4 3.9L34 59.5c-.3.2-.6.3-1 .3V29.7c0-.1 0-.3-.1-.4l11.9-5.8v10.6c0 .6.4 1 1 1s1-.4 1-1V22.5l11.5-5.6c.2.5.3 1.1.3 1.7v25.8z" />
  </svg>
);
export default IconBox4;
