import * as React from "react";
const IconSpeechBubble5 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M49.9 20.8h-7c-.8-6.2-6.2-11.1-12.7-11.1H14.1c-3.2 0-6.3 1.3-8.5 3.5C3.3 15.6 2 18.6 2 21.8c0 4.4 2.3 8.3 6.1 10.5V42c0 .4.3.8.6.9.1.1.2.1.4.1.3 0 .6-.1.7-.3l7.8-8.8H21c.5 6.2 5.7 11.1 12 11.1h10.7l7.9 8.9c.2.2.5.3.7.3.1 0 .2 0 .4-.1.4-.1.6-.5.6-.9v-8.8c1.9-.6 3.7-1.6 5.1-3 2.2-2.3 3.5-5.4 3.5-8.5.1-6.6-5.3-12.1-12-12.1M17.1 31.9c-.3 0-.6.1-.7.3l-6.3 7.1v-7.7c0-.4-.2-.7-.5-.9-3.4-1.7-5.5-5.2-5.5-9 0-2.7 1-5.2 3-7.1 1.9-1.9 4.4-3 7.1-3h16.2c5.9 0 10.8 4.8 10.8 10.8 0 5.2-4.2 9.4-9.4 9.4H17.1zM57 40c-1.3 1.3-3 2.3-4.9 2.7-.5.1-.8.5-.8 1v6.9l-6.5-7.3c-.2-.2-.5-.3-.7-.3h-11c-5.2 0-9.5-4-10-9.1h8.6c6.2 0 11.2-4.9 11.4-11.1H50c5.5 0 10.1 4.5 10.1 10.1-.1 2.7-1.2 5.3-3.1 7.1" />
    <path d="M12 19.8h-.1c-1.1 0-2 .9-2 2s.9 2 2 2h.1c1.1 0 2-.9 2-2s-.9-2-2-2M22.1 19.8H22c-1.1 0-2 .9-2 2s.9 2 2 2h.1c1.1 0 2-.9 2-2s-.9-2-2-2M32.1 23.8c1.1 0 2-.9 2-2s-.9-2-2-2H32c-1.1 0-2 .9-2 2s.9 2 2 2zM43.8 32.2h-.1c-1.1 0-2 .9-2 2s.9 2 2 2h.1c1.1 0 2-.9 2-2s-.9-2-2-2M53.8 32.2h-.1c-1.1 0-2 .9-2 2s.9 2 2 2h.1c1.1 0 2-.9 2-2s-.9-2-2-2" />
  </svg>
);
export default IconSpeechBubble5;
