import * as React from "react";
const IconDashboardAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M52.9 19.8c-5.3-5.5-12.7-9-20.9-9-16 0-29 13-29 29 0 4.5 1 8.9 3 12.9.1.2.2.3.4.4h.1c.1 0 .1.1.2.1H7c.1 0 .2 0 .2-.1h.2l4.1-2c.5-.2.7-.8.5-1.3s-.8-.7-1.3-.5l-3.2 1.6C5.8 47.4 5 43.7 5 39.8v-1.1h5.5c.6 0 1-.4 1-1s-.4-1-1-1H5.2c.6-5.6 3-10.8 6.6-14.8l4.6 4.6c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-4.6-4.6c4.6-4.5 10.9-7.4 17.8-7.6v6.4c0 .6.4 1 1 1s1-.4 1-1v-6.4c6.9.3 13.2 3.1 17.8 7.6l-3.7 3.7c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l3.7-3.7c3.6 4 6 9.2 6.6 14.8h-5.3c-.6 0-1 .4-1 1s.4 1 1 1H59v1.1q0 5.85-2.4 11.1l-3.2-1.6c-.5-.2-1.1 0-1.3.5s0 1.1.5 1.3l4.1 2c.1.1.2.1.3.1h.3q.6 0 .9-.6c2-4 3-8.4 3-12.9C61 32 57.9 25 52.9 19.8" />
    <path d="m38.1 34.4-6.7 5c-1.2.2-2.3.9-3 1.9-.1.2-.2.3-.3.5l-.2.2h.1c-1.1 2.1-.5 4.7 1.5 6.2 2 1.4 4.7 1.2 6.3-.5h.1l.1-.2c.1-.1.3-.3.4-.5.8-1 1-2.3.9-3.5l2.6-8c.3-.9-.9-1.7-1.8-1.1" />
  </svg>
);
export default IconDashboardAlt1;
