import * as React from "react";
const IconBellSleep = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M43 50.4q-.6-1.05-.6-2.1V34.8c0-.6-.4-1-1-1s-1 .4-1 1v13.5c0 1.1.3 2.1.9 3.2l1.4 2.2H4.2l1.4-2.4c.4-.8.7-1.6.8-2.8V30.7c0-8 6.4-14.8 14.8-15.8 4.5-.5 9 .6 12.5 3.2.4.3 1.1.2 1.4-.2s.2-1.1-.2-1.4c-3.1-2.2-6.7-3.4-10.6-3.6V8c0-.6-.4-1-1-1s-1 .4-1 1v4.8c-.4 0-.9 0-1.3.1-9.5 1-16.6 8.7-16.6 17.7v17.6c-.1.9-.3 1.5-.6 2L2.1 53q-.6.9 0 1.8c.3.5.9.8 1.5.8h18.6v4.1c0 .6.4 1 1 1s1-.4 1-1v-4.1H43c.6 0 1.2-.3 1.5-.8.3-.4.4-1.1 0-1.8zM51.8 26.7H45l7.5-7.3c.3-.3.4-.7.2-1.1q-.3-.6-.9-.6h-9.2c-.6 0-1 .4-1 1s.4 1 1 1h6.8L41.9 27c-.3.3-.4.7-.2 1.1q.3.6.9.6h9.2c.6 0 1-.4 1-1s-.5-1-1-1M61 10.2h-4.7L61.7 5c.3-.3.4-.7.2-1.1q-.3-.6-.9-.6h-7.1c-.6 0-1 .4-1 1s.4 1 1 1h4.7l-5.4 5.3c-.3.3-.4.7-.2 1.1q.3.6.9.6H61c.6 0 1-.4 1-1s-.4-1.1-1-1.1" />
  </svg>
);
export default IconBellSleep;
