import * as React from "react";
const IconTreesAlt9 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M27 24.1h1.5c.8 0 1.4-.4 1.8-1.1.3-.7.3-1.5-.2-2.1L20.3 7.6c-.8-1-2.5-1-3.2 0L7.2 20.9c-.4.6-.5 1.4-.2 2.1s1 1.1 1.8 1.1h2.4L2.3 38.4c-.4.6-.4 1.4-.1 2 .4.6 1 1 1.8 1h13.6v15.1c0 .6.4 1 1 1s1-.4 1-1V41.4h13.7c.7 0 1.4-.4 1.7-1s.4-1.4 0-2zM4 39.4l8.9-14.3c.4-.6.4-1.4.1-2-.4-.6-1-1-1.8-1H8.8l9.8-13.3 9.8 13.3H27c-.7 0-1.4.4-1.7 1s-.4 1.4 0 2l8.2 14.4zM62 26.6c0-9.5-5-20.1-12.1-20.1S37.8 17.1 37.8 26.6c0 9.6 4.9 17.5 11.1 18.3v11.6c0 .6.4 1 1 1s1-.4 1-1V44.9c6.2-.8 11.1-8.7 11.1-18.3M50.9 42.9v-6.4c0-.6-.4-1-1-1s-1 .4-1 1v6.4c-5.1-.8-9.1-7.8-9.1-16.3 0-9.5 4.8-18.1 10.1-18.1S60 17.1 60 26.6c0 8.5-4 15.5-9.1 16.3" />
  </svg>
);
export default IconTreesAlt9;
