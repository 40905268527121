import * as React from "react";
const IconBuildingsAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 16.8H16.1c-.6 0-1 .4-1 1s.4 1 1 1H32c.6 0 1-.4 1-1s-.4-1-1-1M32 30.3H16.1c-.6 0-1 .4-1 1s.4 1 1 1H32c.6 0 1-.4 1-1s-.4-1-1-1" />
    <path d="M55.4 8.8H44.5V7.1c0-2.8-2.3-5.1-5.1-5.1H8.6c-2.8 0-5 2.3-5 5.1V57c0 2.8 2.3 5.1 5.1 5.1h30.8c2.8 0 5.1-2.3 5.1-5.1v-2.1h10.8c2.8 0 5.1-2.3 5.1-5.1v-36c-.1-2.8-2.3-5-5.1-5M42.5 56.9c0 1.7-1.4 3.1-3.1 3.1H8.6c-1.7 0-3.1-1.4-3.1-3.1V7.1C5.6 5.4 7 4 8.6 4h30.8c1.7 0 3.1 1.4 3.1 3.1zm15.9-7.1c0 1.7-1.4 3.1-3.1 3.1H44.5V39.6h6.1c.6 0 1-.4 1-1s-.4-1-1-1h-6.1V26h6.1c.6 0 1-.4 1-1s-.4-1-1-1h-6.1V10.8h10.8c1.7 0 3.1 1.4 3.1 3.1z" />
    <path d="M32 43.9H16.1c-.6 0-1 .4-1 1s.4 1 1 1H32c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconBuildingsAlt;
