import * as React from "react";
const IconMirror2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M47.7 60H33v-6.7c9.2-.7 16.6-10.3 16.6-22.1C49.6 19.3 42.2 9.6 33 9V3c0-.6-.4-1-1-1s-1 .4-1 1v6c-9.2.7-16.6 10.3-16.6 22.1S21.7 52.6 31 53.2V60H16.3c-.6 0-1 .4-1 1s.4 1 1 1h31.3c.6 0 1-.4 1-1s-.4-1-.9-1M16.4 31.1c0-11.1 7-20.2 15.6-20.2S47.6 20 47.6 31.1s-7 20.2-15.6 20.2-15.6-9.1-15.6-20.2" />
  </svg>
);
export default IconMirror2;
