import React, { Fragment } from "react";
import { generateCSSString } from "../../../../../helpers/style";

function getRelativePath(url) {
    url = url?.toLowerCase() ?? '';
    let locationOrigin = window.location.origin.toLowerCase();
    if (url.includes(locationOrigin))
        return url.split(locationOrigin).pop();
    return url;
}

function comparePaths(navLink, pageLink) {
    navLink = navLink.split("/");
    pageLink = pageLink.split("/");

    for (let i = 0; i < navLink.length; i++)
        if (navLink[i] !== pageLink[i]) return false;

    return true;
}

export default function MenuLinks({
    mobile = false,
    // pageURL,
    component,
    children,
}) {

    return (
        <Fragment>
            {
                !mobile ?
                    <ul className={`flex justify-between items-center ${generateCSSString(component, ['links_gap'])} p-0`}>
                        {
                            React.Children.map(
                                children, (child) => {
                                    if (child.props.component.link_linkType !== 'button')
                                        return (
                                            <a href={child.props.component.link_} target={child.props.component.link_target} className={`underline-offset-4 hover:underline ${comparePaths(child.props.component.link_, window.location.pathname) ? "underline" : ""}`}>
                                                {
                                                    React.cloneElement(child, {
                                                        component: {
                                                            ...child.props.component,
                                                            text_colorClass: component.linkText_colorClass,
                                                            hoverText_colorClass: component.linkHoverText_colorClass,
                                                            text_textTag: component.link_textTag,
                                                            textText_: child.props.component.label_
                                                        }
                                                    })}
                                            </a>
                                        );

                                    return (
                                        <a href={child.props.component.link_} target={child.props.component.link_target}>
                                            {
                                                React.cloneElement(child, {
                                                    component: {
                                                        ...child.props.component,
                                                        btnText_colorClass: component.btnText_colorClass,
                                                        btnHoverText_colorClass: component.btnHoverText_colorClass,
                                                        btnBackground_colorClass: component.btnBackground_colorClass,
                                                        btnHoverBackground_colorClass: component.btnHoverBackground_colorClass,
                                                        btnBorder_colorClass: component.btnBorder_colorClass,
                                                        btnHoverBorder_colorClass: component.btnHoverBorder_colorClass,
                                                        btn_btnSize: component.btn_btnSize,
                                                        btnText_: child.props.component.label_
                                                    }
                                                })
                                            }
                                        </a>
                                    );
                                }
                            )
                        }
                    </ul>
                    :
                    <div className="flex flex-col">
                        {
                            React.Children.map(
                                children, (child) => {
                                    if (child.props.component.link_linkType !== 'button')
                                        return (
                                            <div className={"flex h-20 items-center p-4 relative overflow-hidden border-b cursor-pointer"}>
                                                <div className={`flex w-full pr-2`}>
                                                    <div className="flex-grow flex flex-col justify-center">
                                                        <a href={child.props.component.link_} target={child.props.component.link_target} className={`underline-offset-4 hover:underline ${comparePaths(child.props.component.link_, window.location.pathname) ? "underline" : ""}`}>
                                                            {
                                                                React.cloneElement(child, {
                                                                    component: {
                                                                        ...child.props.component,
                                                                        text_colorClass: component.linkText_colorClass,
                                                                        hoverText_colorClass: component.linkHoverText_colorClass,
                                                                        text_textTag: component.link_textTag,
                                                                        textText_: child.props.component.label_
                                                                    }
                                                                })}
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        );

                                    return (
                                        <div className={"flex h-20 items-center p-4 relative overflow-hidden border-b cursor-pointer"}>
                                            <div className={`flex w-full pr-2`}>
                                                <div className="flex-grow flex flex-col justify-center">
                                                    <a href={child.props.component.link_} target={child.props.component.link_target}>
                                                        {
                                                            React.cloneElement(child, {
                                                                component: {
                                                                    ...child.props.component,
                                                                    btnText_colorClass: component.btnText_colorClass,
                                                                    btnHoverText_colorClass: component.btnHoverText_colorClass,
                                                                    btnBackground_colorClass: component.btnBackground_colorClass,
                                                                    btnHoverBackground_colorClass: component.btnHoverBackground_colorClass,
                                                                    btnBorder_colorClass: component.btnBorder_colorClass,
                                                                    btnHoverBorder_colorClass: component.btnHoverBorder_colorClass,
                                                                    btn_btnSize: component.btn_btnSize,
                                                                    btnText_: child.props.component.label_
                                                                }
                                                            })
                                                        }
                                                    </a>
                                                </div>

                                            </div>
                                        </div>
                                    );
                                }
                            )
                        }
                    </div>
            }
        </Fragment>
    )

}