import * as React from "react";
const IconFiles = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56 4.4H34.2c-3.4 0-6.3 2.8-6.3 6.3v7.1H8c-3.4 0-6.3 2.8-6.3 6.3v19.2c0 1.2.5 2.4 1.4 3.3L15 58.3c.9.9 2.1 1.4 3.3 1.4h11.5c3.4 0 6.2-2.8 6.2-6.3V39.9l5.1 5.1c.9.9 2.1 1.4 3.3 1.4H56c3.4 0 6.3-2.8 6.3-6.3V10.6c0-3.4-2.9-6.2-6.3-6.2M9.4 46.3h5.5c.1 0 .2.1.2.2V52zm22.2 7.1c0 1-.8 1.8-1.7 1.8H19.6v-8.6c0-2.6-2.1-4.7-4.7-4.7H6.3v-18c0-1 .8-1.8 1.8-1.8h23.4c.1 0 .2.1.2.2v31.1zm4.5-19.9V33h5c.1 0 .2.1.2.2v5.5zm21.7 6.6c0 1-.8 1.8-1.8 1.8H45.7v-8.6c0-2.6-2.1-4.7-4.7-4.7h-5v-6.2c0-2.2-1.6-4.1-3.6-4.5v-7.2c0-1 .8-1.8 1.8-1.8H56c1 0 1.8.8 1.8 1.8z" />
  </svg>
);
export default IconFiles;
