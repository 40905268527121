import * as React from "react";
const IconCross = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M33.5 62h-2.8c-2.8 0-5.2-2.3-5.2-5.2V30.5h-9c-2.8 0-5.2-2.3-5.2-5.2v-4.1c0-2.8 2.3-5.2 5.2-5.2h8.9V7.2c0-2.8 2.3-5.2 5.2-5.2h2.8c2.8 0 5.2 2.3 5.2 5.2V16h8.9c2.8 0 5.2 2.3 5.2 5.2v4.1c0 2.8-2.3 5.2-5.2 5.2h-8.8v26.4c-.1 2.8-2.4 5.1-5.2 5.1M16.6 18.1c-1.7 0-3.2 1.4-3.2 3.2v4.1c0 1.7 1.4 3.2 3.2 3.2h10c.6 0 1 .4 1 1v27.3c0 1.7 1.4 3.2 3.2 3.2h2.8c1.7 0 3.2-1.4 3.2-3.2V29.5c0-.6.4-1 1-1h9.8c1.7 0 3.2-1.4 3.2-3.2v-4.1c0-1.7-1.4-3.2-3.2-3.2h-9.9c-.6 0-1-.4-1-1V7.2c0-1.7-1.4-3.2-3.2-3.2h-2.8c-1.7 0-3.2 1.4-3.2 3.2v9.9c0 .6-.4 1-1 1z" />
  </svg>
);
export default IconCross;
