import * as React from "react";
const IconMathmetics = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56 2.1H8c-3.3 0-6 2.7-6 6v47.8c0 3.3 2.7 6 6 6h48c3.3 0 6-2.7 6-6V8.1c0-3.3-2.7-6-6-6m4 53.8c0 2.2-1.8 4-4 4H8c-2.2 0-4-1.8-4-4V8.1c0-2.2 1.8-4 4-4h48c2.2 0 4 1.8 4 4z" />
    <path d="M22.8 17.9h-4.3v-4.3c0-.6-.4-1-1-1s-1 .4-1 1v4.3h-4.3c-.6 0-1 .4-1 1s.4 1 1 1h4.3v4.3c0 .6.4 1 1 1s1-.4 1-1v-4.3h4.3c.6 0 1-.4 1-1s-.4-1-1-1M51.2 12.9c-.4-.4-1-.4-1.4 0l-3.3 3.2-3.3-3.2c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l3.3 3.2-3.3 3.2c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3l3.3-3.2 3.3 3.2c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-3.3-3.2 3.3-3.2c.4-.4.4-1 0-1.4M21.5 44.1h-7.9c-.6 0-1 .4-1 1s.4 1 1 1h7.9c.6 0 1-.4 1-1s-.5-1-1-1M50.1 41.6h-7.9c-.6 0-1 .4-1 1s.4 1 1 1h7.9c.6 0 1-.4 1-1s-.4-1-1-1M50.1 48.2h-7.9c-.6 0-1 .4-1 1s.4 1 1 1h7.9c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconMathmetics;
