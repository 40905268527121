import * as React from "react";
const IconPdf = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M15.179 5C14.024 5 13 6.018 13 7.23v49.54c0 1.212 1.024 2.23 2.179 2.23h33.87C50.11 59 51 58.12 51 57V24.058q0-.11-.006-.212H35.046c-2.289 0-4.046-1.81-4.046-4.096V5zM33 5.88v13.87c0 1.213.892 2.096 2.046 2.096h14.66zM11 7.23C11 4.945 12.89 3 15.179 3h16.026c.993 0 2.062.332 2.82 1.094l17.712 16.927c.95.793 1.263 1.928 1.263 3.037V57c0 2.194-1.754 4-3.952 4h-33.87C12.89 61 11 59.056 11 56.77zm9.721 28.073a.984.984 0 0 0-.968 1v1.89h3.919c.52 0 .968-.432.968-1v-.89c0-.567-.449-1-.968-1zm-.968 4.89h3.919c1.654 0 2.968-1.358 2.968-3v-.89c0-1.641-1.314-3-2.968-3h-2.951c-1.654 0-2.968 1.359-2.968 3v8.89a1 1 0 1 0 2 0zm8.823-5.804a1 1 0 0 1 1-1h3.978c2.197 0 3.951 1.806 3.951 4v4.718c0 2.194-1.754 4-3.951 4h-3.979a1 1 0 0 1-1-1zm2 1v8.718h2.978c1.062 0 1.951-.88 1.951-2v-4.718c0-1.12-.889-2-1.951-2zm8.865 1c0-1.641 1.314-3 2.968-3h3.978a1 1 0 0 1 0 2H42.41a.984.984 0 0 0-.968 1v1.55h3.343a1 1 0 1 1 0 2h-3.343v5.168a1 1 0 0 1-2 0z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconPdf;
