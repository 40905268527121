import * as React from "react";
const IconTriangleRuler1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M6 13.8c-.7-.7-1.7-.9-2.6-.5S2 14.5 2 15.4v34.9c0 1.3 1.1 2.3 2.3 2.3h34.9c1 0 1.8-.6 2.2-1.4.4-.9.2-1.9-.5-2.6zm33.6 36.6c0 .1-.1.2-.3.2h-35c-.2 0-.3-.2-.3-.3V15.4c0-.2.1-.3.2-.3h.1c.1 0 .2 0 .2.1l34.9 34.9c.2.1.2.3.2.3" />
    <path d="M14.1 32.1c-.7-.7-1.7-.9-2.6-.5S10 32.8 10 33.8V42c0 1.3 1.1 2.3 2.3 2.3h8.2c1 0 1.8-.6 2.2-1.5s.2-1.9-.5-2.6zm6.8 10c0 .1-.1.2-.3.2h-8.2c-.2 0-.3-.2-.3-.3v-8.2c0-.2.1-.3.2-.3h.1c.1 0 .2 0 .2.1l8.2 8.2c.2 0 .2.2.1.3M60 11.3H49.2c-1.1 0-2 .9-2 2v37.3c0 1.1.9 2 2 2H60c1.1 0 2-.9 2-2V13.3c0-1.1-.9-2-2-2M49.2 50.7V13.3H60v6.4h-3.5c-.6 0-1 .4-1 1s.4 1 1 1H60v5.5h-1.6c-.6 0-1 .4-1 1s.4 1 1 1H60v5.5h-3.5c-.6 0-1 .4-1 1s.4 1 1 1H60v5.5h-1.6c-.6 0-1 .4-1 1s.4 1 1 1H60v6.4H49.2z" />
  </svg>
);
export default IconTriangleRuler1;
