import * as React from "react";
const IconWindow = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 53.8h-4.1V19.9c0-6.4-5.2-11.7-11.7-11.7H18.7C12.3 8.2 7 13.4 7 19.9v33.9H3c-.6 0-1 .4-1 1s.4 1 1 1h58c.6 0 1-.4 1-1s-.4-1-1-1M9.1 19.9c0-5.3 4.3-9.7 9.7-9.7h26.6c5.3 0 9.7 4.3 9.7 9.7v33.9h-6.3V20.3c0-2.2-1.8-4-4-4H19.3c-2.2 0-4 1.8-4 4v33.5H9.1zM33 18.3h11.7c1.1 0 2 .9 2 2V35H33zM31 35H17.3V20.3c0-1.1.9-2 2-2H31zm-13.7 2H31v16.5c0 .1 0 .2.1.3H17.3zm15.6 16.8c0-.1.1-.2.1-.3V37h13.7v16.8z" />
  </svg>
);
export default IconWindow;
