import * as React from "react";
const IconHearts = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.7 3.5c-2-1.4-4.1-1.8-6.2-1.3-3.1.8-5.5 3.6-6.7 5.3-1.2-1.7-3.6-4.5-6.7-5.3-2.1-.5-4.2-.1-6.2 1.3-6.1 4.3-4.2 12.5-2.3 16.3 0 0 .4.9 1.2 2.1-.8.9-1.4 1.9-1.9 2.6-1.6-2.4-5.2-7-10.1-8.2-2.9-.7-5.9-.1-8.7 1.9-11 7.6-3.3 23.4-3.3 23.5.3.6 7.7 15.7 22 20.2h.6c14.2-4.6 21.3-19.6 21.5-20.2 0-.1 3.1-6.5 2.6-13.1 4.3-4 6.4-8.6 6.5-8.8 1.9-3.7 3.8-12-2.3-16.3m-8.6 37.4c-.1.1-6.8 14.6-20 19.1C14.9 55.5 7.7 41 7.6 40.8c-.1-.1-7-14.3 2.6-21 1.7-1.2 3.5-1.8 5.3-1.8.6 0 1.2.1 1.8.2 5.7 1.4 9.6 8.6 9.7 8.7.3.6 1.4.6 1.8 0 0-.1 4-7.3 9.7-8.7 2.4-.6 4.8 0 7.1 1.6 9.5 6.7 2.6 20.9 2.5 21.1M57.2 19s-1.7 3.7-5.1 7.2c-.7-3-2.3-5.8-5.5-8-2.8-2-5.8-2.6-8.7-1.9-2.7.6-5 2.3-6.8 4.1-.5-.9-.8-1.4-.8-1.4-.2-.4-4.6-9.5 1.7-13.8 1.5-1.1 3-1.4 4.6-1 3.7.9 6.3 5.7 6.3 5.7.2.3.5.5.9.5s.7-.2.9-.5c0 0 2.6-4.8 6.3-5.7 1.5-.4 3 0 4.6 1 6.2 4.3 1.8 13.4 1.6 13.8" />
  </svg>
);
export default IconHearts;
