import * as React from "react";
const IconPhoneIncoming = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50 26.3c.6.5 1.3.8 2.1.8s1.5-.3 2.1-.8l7.4-7.1c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-7.1 6.8V3c0-.6-.4-1-1-1s-1 .4-1 1v21.5L44 17.8c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4zM60.5 45.9l-9.1-6.1c-1.6-1.1-3.7-.6-5 1.3L44 44.4c-.4.6-1.2.8-1.8.4-12.4-7.8-20.2-18.7-23-23-.4-.6-.2-1.5.4-1.9l3.7-2.6c1.5-1.1 1.9-3.2.9-4.7l-6.1-9.1c-1-1.5-3-2-4.6-1.1L4.7 7.6c-.1 0-.1.1-.2.2-6.5 6.5-.2 22.9 14.3 37.4 6.8 6.8 14.4 12 21.3 14.8 3.4 1.3 6.4 2 9.1 2 2.9 0 5.3-.8 7-2.5l.2-.2 5.1-8.7c.9-1.6.5-3.7-1-4.7m-.7 3.6-5.1 8.6c-2.6 2.5-7.6 2.5-13.9 0-6.7-2.7-14-7.8-20.6-14.4C7.1 30.6.5 14.8 5.9 9.3l8.6-5.1c.6-.4 1.5-.2 1.9.4l6.1 9.1c.4.6.3 1.5-.4 1.9l-3.7 2.6c-1.5 1-1.9 3.1-.9 4.6 2.8 4.4 10.8 15.6 23.6 23.6 1.5 1 3.5.5 4.5-1l2.3-3.3c.7-.9 1.5-1.3 2.2-.8l9.1 6.1c.8.6 1 1.5.6 2.1" />
  </svg>
);
export default IconPhoneIncoming;
