import * as React from "react";
const IconProgrammer = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M19.8 43.9c4.5 0 8.2-3.7 8.2-8.2s-3.7-8.2-8.2-8.2-8.2 3.7-8.2 8.2 3.7 8.2 8.2 8.2m0-14.4c3.4 0 6.2 2.8 6.2 6.2s-2.8 6.2-6.2 6.2-6.2-2.8-6.2-6.2 2.8-6.2 6.2-6.2M19.8 47.6c-6.1 0-11.9 2.1-16.4 6-.4.4-.5 1-.1 1.4s1 .5 1.4.1c4.2-3.5 9.6-5.5 15.1-5.5 5.6 0 11 2 15.1 5.5.2.2.4.2.6.2.3 0 .6-.1.8-.4.4-.4.3-1.1-.1-1.4-4.5-3.8-10.4-5.9-16.4-5.9M36.7 12.2c-.4-.4-1-.4-1.4-.1l-5.9 5.4c-.3.2-.4.5-.4.8s.1.6.3.7l5.9 5.4c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.3-1-.1-1.4l-5.1-4.6 5.1-4.6c.5-.6.6-1.2.2-1.6M60.7 17.5l-5.9-5.4c-.4-.4-1-.3-1.4.1s-.3 1 .1 1.4l5.1 4.6-5.1 4.6c-.4.4-.4 1-.1 1.4.2.2.5.3.7.3s.5-.1.7-.3l5.9-5.4c.2-.2.3-.5.3-.7s-.1-.4-.3-.6M47.5 8.7c-.5-.1-1.1.2-1.2.7l-4.7 17.3c-.1.5.2 1.1.7 1.2h.3c.4 0 .8-.3 1-.7l4.7-17.3c0-.5-.3-1.1-.8-1.2" />
  </svg>
);
export default IconProgrammer;
