import React from 'react';
import { useNavigation } from '../../context/NavigationContext';
import { BringzzPageContainer, BringzzIcon, BringzzText, BringzzQrCode } from "@bringzz/components"

const DeliveryHandoverViewPrescription3 = () => {
    const { backPress } = useNavigation();

    const notifications = [
        { id: 1, title: 'Apotheke Mustermann', body: 'Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolorLorem ipsu Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolorLorem ipsu Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolorLorem ipsu ', subTitle: '28.02.2024, 14:45', enabled: true },
        { id: 2, title: 'Please complete your profile for a smooth checkout experience', subTitle: '28.02.2024, 14:45', enabled: false },
        { id: 3, title: 'Welcome to the Bringzz community! Great to have you here.', subTitle: '28.02.2024, 14:45', enabled: true },
    ];

    return (
        <BringzzPageContainer
            header={{
                right: <BringzzIcon
                    size="18"
                    icon="IconClose"
                    folder="LineIcons"
                    className="cursor-pointer"
                    onClick={backPress}
                />,
                title: <BringzzText tag='h2'>Rezept QR Code</BringzzText>,
            }}>

            <div className='flex justify-center items-center w-full h-full pt-52'>
                <BringzzQrCode value="123456789" />
            </div>
        </BringzzPageContainer>

    )
}

export default DeliveryHandoverViewPrescription3;