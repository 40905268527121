import * as React from "react";
const IconMushrooms = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M26.2 29.5c4.3-.5 8.7-1.6 13.1-3.2.4-.1.7-.5.7-.9 0-12-8.5-21.9-19-21.9S2 13.3 2 25.3c0 .4.2.8.6.9 4.1 1.8 8.5 3 13.1 3.4l-1.3 23.9c-.1 1.8.5 3.5 1.8 4.9 1.3 1.3 2.9 2.1 4.8 2.1s3.5-.7 4.8-2.1c1.3-1.3 1.9-3 1.8-4.9zM21 5.5c9.1 0 16.6 8.5 16.9 19.2C25.6 29 14.2 29 4 24.7 4.3 14 11.8 5.5 21 5.5m3.3 51.6c-.9.9-2 1.4-3.3 1.4s-2.4-.5-3.3-1.4-1.3-2.1-1.2-3.4l1.3-23.9c.8 0 1.6.1 2.4.1 1.3 0 2.7-.1 4.1-.2l1.3 24c0 1.3-.5 2.5-1.3 3.4M50.6 26.6c-6.3 0-11.4 5.9-11.4 13.1 0 .4.2.8.6.9 2.3 1 4.8 1.7 7.3 2L46.4 56c-.1 1.2.3 2.3 1.1 3.1.8.9 1.9 1.3 3.1 1.3s2.3-.5 3.1-1.3c.8-.9 1.2-2 1.1-3.1l-.7-13.6c2.4-.3 4.8-.9 7.3-1.9.4-.1.7-.5.7-.9-.1-7.2-5.2-13-11.5-13m2.2 29.5c0 .6-.2 1.2-.6 1.6-.8.9-2.4.9-3.2 0-.4-.4-.6-1-.6-1.7l.7-13.5h1c.6 0 1.3 0 2-.1zM41.2 39c.3-5.8 4.4-10.4 9.4-10.4S59.7 33.2 60 39c-6.8 2.3-13.1 2.3-18.8 0" />
  </svg>
);
export default IconMushrooms;
