import * as React from "react";
const IconChatAlt5 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.2 8.5H7.8C5.1 8.5 3 10.6 3 13.3v27.9C3 43.8 5.1 46 7.8 46h15l8.4 9.2c.2.2.5.3.7.3.3 0 .5-.1.7-.3l8.6-9.3h15.1c2.6 0 4.8-2.1 4.8-4.8V13.3c-.1-2.7-2.2-4.8-4.9-4.8M59 41.1c0 1.5-1.2 2.8-2.8 2.8H40.7c-.3 0-.5.1-.7.3L31.9 53 24 44.2c-.2-.2-.5-.3-.7-.3H7.8c-1.5 0-2.8-1.2-2.8-2.8V13.3c0-1.5 1.2-2.8 2.8-2.8h48.4c1.5 0 2.8 1.2 2.8 2.8z" />
    <path d="M18.3 23.8c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.4-3-3-3m0 4c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.5 1-1 1M32 23.8c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3m0 4c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1M45.8 23.8c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.4-3-3-3m0 4c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.5 1-1 1" />
  </svg>
);
export default IconChatAlt5;
