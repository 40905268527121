import * as React from "react";
const IconHatAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.2 20.6h-.3C50.1 8.4 45.7 2.3 40.5 2c-7.2-.4-12.4 11-12.6 11.5L8.5 60H4.4c-.6 0-1 .4-1 1s.4 1 1 1h48.8c.6 0 1-.4 1-1s-.4-1-1-1h-4.1L29.9 14c1.5-3.2 5.9-10.2 10.5-10 2.9.2 7.2 3.3 11.5 17a6.3 6.3 0 0 0-4.1 5.9c0 3.5 2.8 6.3 6.3 6.3s6.3-2.8 6.3-6.3-2.7-6.3-6.2-6.3M10.7 60l18.1-43.5L46.9 60zm43.5-28.7c-2.4 0-4.3-1.9-4.3-4.3s1.9-4.3 4.3-4.3 4.3 1.9 4.3 4.3-1.9 4.3-4.3 4.3" />
  </svg>
);
export default IconHatAlt;
