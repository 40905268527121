import * as React from "react";
const IconLolipop1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.9 21.9C51.9 10.9 43 2 32 2s-19.9 8.9-19.9 19.9c0 3.5.9 6.8 2.5 9.6 0 .1.1.1.1.1 3.3 5.8 9.4 9.8 16.4 10.1V61c0 .6.4 1 1 1s1-.4 1-1V41.8c10.5-.5 18.8-9.2 18.8-19.9M32 39.9c-6.6 0-12.3-3.6-15.5-8.9 2.5-7.9 5.8-12.3 9.3-12.9 4.6-.7 8.7 5.1 8.8 5.2.3.5.9.6 1.4.2.5-.3.6-.9.3-1.4-.2-.3-4.9-6.9-10.7-6-4.1.6-7.5 4.8-10.3 12.3-.8-2-1.2-4.2-1.2-6.5C14.1 12.1 22.1 4 32 4s17.9 8.1 17.9 17.9-8 18-17.9 18" />
  </svg>
);
export default IconLolipop1;
