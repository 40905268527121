import * as React from "react";
const IconUsers2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M20.5 27.8c5.7 0 10.4-4.5 10.4-10s-4.6-10-10.4-10-10.4 4.5-10.4 10 4.7 10 10.4 10m0-18c4.6 0 8.4 3.6 8.4 8s-3.8 8-8.4 8-8.4-3.6-8.4-8 3.8-8 8.4-8M46.5 32.7c4.8 0 8.7-3.8 8.7-8.5s-3.9-8.5-8.7-8.5-8.7 3.8-8.7 8.5 3.9 8.5 8.7 8.5m0-14.9c3.7 0 6.7 2.9 6.7 6.5s-3 6.5-6.7 6.5-6.7-2.9-6.7-6.5 3-6.5 6.7-6.5M47 36.5h-.9c-3.2 0-6.4 1-8.9 2.9-2.3-4-6.6-6.7-11.6-6.7H15.4C8 32.8 2 38.8 2 46.2v7.9c0 1.2 1 2.1 2.1 2.1h55.699999999999996c1.2 0 2.3-1 2.3-2.3v-2.5C62 43.2 55.3 36.5 47 36.5M33.3 54.3H4.1c-.1 0-.1-.1-.1-.1v-7.9C4 40 9.1 34.9 15.4 34.9h10.1c6.3 0 11.4 5.1 11.4 11.4v7.9c0 .1-.1.1-.1.1zM60 54c0 .1-.1.3-.3.3H39v-8c0-1.7-.3-3.4-1-4.9 2.3-1.8 5.1-2.8 8-2.8h1c7.2 0 13 5.8 13 13z" />
  </svg>
);
export default IconUsers2;
