import * as React from "react";
const IconUserLove = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.6 37.3c-1.6-1.7-3.7-2.6-6-2.6-1.8 0-3.4.5-4.8 1.6-.4.3-.7.6-1.1 1-.3-.4-.7-.7-1.1-1-1.4-1.1-3-1.6-4.8-1.6-.6 0-1.2.1-1.8.2 0-.1 0-.1-.1-.2-4-4.7-9.3-7.3-15-7.6 6.1-.4 10.9-5.4 10.9-11.6 0-6.4-5.2-11.6-11.6-11.6S12.6 9.1 12.6 15.5c0 6.2 4.8 11.2 10.9 11.6-5.7.2-11.1 2.9-15 7.6-4.1 4.9-6.4 11.6-6.5 19q0 .6.6.9c2.6 1.3 11.9 5.5 21.7 5.5 5.3 0 10.2-.9 10.4-.9.5-.1.9-.6.8-1.2-.1-.5-.6-.9-1.2-.8 0 0-5 .9-10 .9-8.8 0-17.2-3.6-20.3-5 .2-6.7 2.4-12.7 6-17 3.7-4.4 8.8-6.9 14.2-6.9 5.3 0 10.2 2.3 13.9 6.5-.8.4-1.6 1-2.3 1.7-1.5 1.6-2.4 3.9-2.4 6.3 0 2.5.9 4.7 2.9 7 1.7 2 4 4 6.7 6.3l.1.1c.9.7 1.9 1.6 2.9 2.5.5.4 1.1.6 1.7.6s1.2-.2 1.7-.6c1.1-.9 2.1-1.8 3-2.6 2.7-2.3 5-4.3 6.7-6.3 2-2.3 2.9-4.6 2.9-7 0-2.5-.8-4.8-2.4-6.4m-45-21.7c0-5.3 4.3-9.6 9.6-9.6s9.6 4.3 9.6 9.6-4.3 9.6-9.6 9.6-9.6-4.3-9.6-9.6m43 33.8c-1.5 1.9-3.8 3.8-6.5 6-.9.8-2 1.6-3 2.6-.2.2-.5.2-.7 0-1-.9-2-1.7-2.9-2.5l-.1-.1c-2.6-2.2-4.9-4.1-6.4-6-1.6-2-2.4-3.8-2.4-5.8 0-1.9.7-3.7 1.8-4.9 1.2-1.3 2.8-2 4.6-2q1.95 0 3.6 1.2.6.45 1.2 1.2c.5.7 1.7.7 2.2 0 .4-.4.8-.8 1.2-1.1q1.65-1.2 3.6-1.2c1.8 0 3.4.7 4.6 2s1.8 3 1.8 4.9c-.2 1.9-1 3.7-2.6 5.7" />
  </svg>
);
export default IconUserLove;
