import { BringzzIcon } from "@bringzz/components";

export const alphabets = ['All', '#', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

export const categories = [
    {
        name: "Recommendations by our Pharmacists",
        shortName: "Recommendation",
        icon: <BringzzIcon
            size="34"
            icon="IconPrescriptionDocument"
            folder="HealthIcons"
        />,
        items: [
            {
                label: "Mucosolvan * Sanofi"
            },
            {
                label: "Nasivin * Procter & Gamble"
            },
            {
                label: "Stepsils * Reckitt Benchiser"
            },
            {
                label: "Mucosolvan * Sanofi"
            },
            {
                label: "Nasivin * Procter & Gamble"
            },
            {
                label: "-Mucosolvan * Sanofi"
            },
            {
                label: "#Nasivin * Procter & Gamble"
            },
            {
                label: "*Stepsils * Reckitt Benchiser"
            },
            {
                label: "Stepsils * Reckitt Benchiser"
            },
            {
                label: "Mucosolvan * Sanofi"
            },
            {
                label: "Nasivin * Procter & Gamble"
            },
            {
                label: "Stepsils * Reckitt Benchiser"
            },
            {
                label: "Mucosolvan * Sanofi"
            },
            {
                label: "Nasivin * Procter & Gamble"
            },
            {
                label: "Stepsils * Reckitt Benchiser"
            },
            {
                label: "Mucosolvan * Sanofi"
            },
            {
                label: "Nasivin * Procter & Gamble"
            },
            {
                label: "Stepsils * Reckitt Benchiser"
            },
        ]
    },
    {
        name: "Companies",
        shortName: "Companies",
        icon: <BringzzIcon
            size="34"
            icon="IconTelemedicine"
            folder="HealthIcons"
        />,
        items: [
            {
                label: "Something1 * Sanofi"
            },
            {
                label: "Nasivin * Procter & Gamble"
            },
            {
                label: "Stepsils * Reckitt Benchiser"
            },
            {
                label: "Mucosolvan * Sanofi"
            },
            {
                label: "Nasivin * Procter & Gamble"
            },
            {
                label: "Stepsils * Reckitt Benchiser"
            },
            {
                label: "Mucosolvan * Sanofi"
            },
            {
                label: "Nasivin * Procter & Gamble"
            },
            {
                label: "Stepsils * Reckitt Benchiser"
            },
            {
                label: "Mucosolvan * Sanofi"
            },
            {
                label: "Nasivin * Procter & Gamble"
            },
            {
                label: "Stepsils * Reckitt Benchiser"
            },
            {
                label: "Mucosolvan * Sanofi"
            },
            {
                label: "Nasivin * Procter & Gamble"
            },
            {
                label: "Stepsils * Reckitt Benchiser"
            },
        ]
    },
    {
        name: "Local Pharmacy Specialties",
        shortName: "Local Pharmacies",
        icon: <BringzzIcon
            size="34"
            icon="IconTelemedicine"
            folder="HealthIcons"
        />,
        items: [
            {
                label: "Something2 * Sanofi"
            },
            {
                label: "Nasivin * Procter & Gamble"
            },
            {
                label: "Stepsils * Reckitt Benchiser"
            },
            {
                label: "Mucosolvan * Sanofi"
            },
            {
                label: "Nasivin * Procter & Gamble"
            },
            {
                label: "Stepsils * Reckitt Benchiser"
            },
            {
                label: "Mucosolvan * Sanofi"
            },
            {
                label: "Nasivin * Procter & Gamble"
            },
            {
                label: "Stepsils * Reckitt Benchiser"
            },
            {
                label: "Mucosolvan * Sanofi"
            },
            {
                label: "Nasivin * Procter & Gamble"
            },
            {
                label: "Stepsils * Reckitt Benchiser"
            },
            {
                label: "Mucosolvan * Sanofi"
            },
            {
                label: "Nasivin * Procter & Gamble"
            },
            {
                label: "Stepsils * Reckitt Benchiser"
            },
            {
                label: "Mucosolvan * Sanofi"
            },
            {
                label: "Nasivin * Procter & Gamble"
            },
            {
                label: "Stepsils * Reckitt Benchiser"
            },
            {
                label: "Mucosolvan * Sanofi"
            },
            {
                label: "Nasivin * Procter & Gamble"
            },
            {
                label: "Stepsils * Reckitt Benchiser"
            },
        ]
    },
    {
        name: "Travel Remedies",
        shortName: "Travel Remedies",
        icon: <BringzzIcon
            size="34"
            icon="IconTelemedicine"
            folder="HealthIcons"
        />,
        items: [
            {
                label: "Something3 * Sanofi"
            },
            {
                label: "Nasivin * Procter & Gamble"
            },
            {
                label: "Stepsils * Reckitt Benchiser"
            },
            {
                label: "Mucosolvan * Sanofi"
            },
            {
                label: "Nasivin * Procter & Gamble"
            },
            {
                label: "Stepsils * Reckitt Benchiser"
            },
            {
                label: "Mucosolvan * Sanofi"
            },
            {
                label: "Nasivin * Procter & Gamble"
            },
            {
                label: "Stepsils * Reckitt Benchiser"
            },
            {
                label: "Mucosolvan * Sanofi"
            },
            {
                label: "Nasivin * Procter & Gamble"
            },
            {
                label: "Stepsils * Reckitt Benchiser"
            },
            {
                label: "Mucosolvan * Sanofi"
            },
            {
                label: "Nasivin * Procter & Gamble"
            },
            {
                label: "Stepsils * Reckitt Benchiser"
            },
        ]
    },
    {
        name: "Men & Women",
        shortName: "Men & Women",
        icon: <BringzzIcon
            size="34"
            icon="IconTelemedicine"
            folder="HealthIcons"
        />,
        items: [
            {
                label: "Mucosolvan * Sanofi"
            },
            {
                label: "Nasivin * Procter & Gamble"
            },
            {
                label: "Stepsils * Reckitt Benchiser"
            },
            {
                label: "Mucosolvan * Sanofi"
            },
            {
                label: "Nasivin * Procter & Gamble"
            },
            {
                label: "Stepsils * Reckitt Benchiser"
            },
            {
                label: "Mucosolvan * Sanofi"
            },
            {
                label: "Nasivin * Procter & Gamble"
            },
            {
                label: "Stepsils * Reckitt Benchiser"
            },
            {
                label: "Mucosolvan * Sanofi"
            },
            {
                label: "Nasivin * Procter & Gamble"
            },
            {
                label: "Stepsils * Reckitt Benchiser"
            },
            {
                label: "Mucosolvan * Sanofi"
            },
            {
                label: "Nasivin * Procter & Gamble"
            },
            {
                label: "Stepsils * Reckitt Benchiser"
            },
        ]
    },
    {
        name: "Babys & Kids",
        shortName: "Babys & Kids",
        icon: <BringzzIcon
            size="34"
            icon="IconTelemedicine"
            folder="HealthIcons"
        />,
        items: [
            {
                label: "Mucosolvan * Sanofi"
            },
            {
                label: "Nasivin * Procter & Gamble"
            },
            {
                label: "Stepsils * Reckitt Benchiser"
            },
            {
                label: "Mucosolvan * Sanofi"
            },
            {
                label: "Nasivin * Procter & Gamble"
            },
            {
                label: "Stepsils * Reckitt Benchiser"
            },
            {
                label: "Mucosolvan * Sanofi"
            },
            {
                label: "Nasivin * Procter & Gamble"
            },
            {
                label: "Stepsils * Reckitt Benchiser"
            },
            {
                label: "Mucosolvan * Sanofi"
            },
            {
                label: "Nasivin * Procter & Gamble"
            },
            {
                label: "Stepsils * Reckitt Benchiser"
            },
            {
                label: "Mucosolvan * Sanofi"
            },
            {
                label: "Nasivin * Procter & Gamble"
            },
            {
                label: "Stepsils * Reckitt Benchiser"
            },
        ]
    },
]

export const groupsBy = [
    {
        label: "Product Groups",
        items: [
            {
                name: "Prescription Medicine",
                shortName: "Prescription Medicine",
                icon: <BringzzIcon
                    size="34"
                    icon="IconPrescriptionDocument"
                    folder="HealthIcons"
                />
            },
            {
                name: "Prescription Medicine",
                shortName: "Prescription Medicine",
                icon: <BringzzIcon
                    size="34"
                    icon="IconPrescriptionDocument"
                    folder="HealthIcons"
                />
            },
            {
                name: "Prescription Medicine",
                shortName: "Prescription Medicine",
                icon: <BringzzIcon
                    size="34"
                    icon="IconPrescriptionDocument"
                    folder="HealthIcons"
                />
            },
        ]
    },
    {
        label: "Products by Activity",
        items: [
            {
                name: "Prescription Medicine",
                shortName: "Prescription Medicine",
                icon: <BringzzIcon
                    size="34"
                    icon="IconPrescriptionDocument"
                    folder="HealthIcons"
                />
            },
            {
                name: "Prescription Medicine",
                shortName: "Prescription Medicine",
                icon: <BringzzIcon
                    size="34"
                    icon="IconPrescriptionDocument"
                    folder="HealthIcons"
                />
            },
            {
                name: "Prescription Medicine",
                shortName: "Prescription Medicine",
                icon: <BringzzIcon
                    size="34"
                    icon="IconPrescriptionDocument"
                    folder="HealthIcons"
                />
            },
        ]
    },
    {
        label: "Products by Organes",
        items: [
            {
                name: "Prescription Medicine",
                shortName: "Prescription Medicine",
                icon: <BringzzIcon
                    size="34"
                    icon="IconPrescriptionDocument"
                    folder="HealthIcons"
                />
            },
            {
                name: "Prescription Medicine",
                shortName: "Prescription Medicine",
                icon: <BringzzIcon
                    size="34"
                    icon="IconPrescriptionDocument"
                    folder="HealthIcons"
                />
            },
            {
                name: "Prescription Medicine",
                shortName: "Prescription Medicine",
                icon: <BringzzIcon
                    size="34"
                    icon="IconPrescriptionDocument"
                    folder="HealthIcons"
                />
            },
        ]
    },
    {
        label: "Product by Age Groups",
        items: [
            {
                name: "Prescription Medicine",
                shortName: "Prescription Medicine",
                icon: <BringzzIcon
                    size="34"
                    icon="IconPrescriptionDocument"
                    folder="HealthIcons"
                />
            },
            {
                name: "Prescription Medicine",
                shortName: "Prescription Medicine",
                icon: <BringzzIcon
                    size="34"
                    icon="IconPrescriptionDocument"
                    folder="HealthIcons"
                />
            },
            {
                name: "Prescription Medicine",
                shortName: "Prescription Medicine",
                icon: <BringzzIcon
                    size="34"
                    icon="IconPrescriptionDocument"
                    folder="HealthIcons"
                />
            },
        ]
    },
    {
        label: "Products by Diseases",
        items: [
            {
                name: "Prescription Medicine",
                shortName: "Prescription Medicine",
                icon: <BringzzIcon
                    size="34"
                    icon="IconPrescriptionDocument"
                    folder="HealthIcons"
                />
            },
            {
                name: "Prescription Medicine",
                shortName: "Prescription Medicine",
                icon: <BringzzIcon
                    size="34"
                    icon="IconPrescriptionDocument"
                    folder="HealthIcons"
                />
            },
            {
                name: "Prescription Medicine",
                shortName: "Prescription Medicine",
                icon: <BringzzIcon
                    size="34"
                    icon="IconPrescriptionDocument"
                    folder="HealthIcons"
                />
            },
        ]
    },
]