import * as React from "react";
const IconCourtBuilding = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.9 26.6c0-.1.1-.1.1-.2v-.5C60.1 14.2 47.2 5.3 32 5.3S3.9 14.2 2 25.9v.5c0 .1 0 .1.1.2 0 .1.1.1.1.2l.1.1s.1.1.2.1h7.4v29.6H7.7c-.6 0-1 .4-1 1s.4 1 1 1h17c.6 0 1-.4 1-1s-.4-1-1-1h-2.2V27.1h19.3v29.6h-2.4c-.6 0-1 .4-1 1s.4 1 1 1h17c.6 0 1-.4 1-1s-.4-1-1-1h-2V27.1h6.9c.1 0 .1 0 .2-.1.1 0 .1-.1.2-.1s.1-.1.1-.1c0-.1 0-.2.1-.2M32 7.3c13.7 0 25.4 7.6 27.8 17.8H4.2C6.6 14.9 18.3 7.3 32 7.3M20.5 56.7H12V27.1h8.5zm31.8 0h-8.5V27.1h8.5z" />
    <path d="M26.3 19h11.5c.6 0 1-.4 1-1s-.4-1-1-1H26.3c-.6 0-1 .4-1 1s.4 1 1 1" />
  </svg>
);
export default IconCourtBuilding;
