import * as React from "react";
const IconMessageOutgoing = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.4 39.2c-.6-.8-1.5-1.3-2.6-1.3h-8.7c-.6 0-1 .4-1 1s.4 1 1 1H58l-13.1 12c-.4.4-.4 1-.1 1.4.2.2.5.3.7.3s.5-.1.7-.3l12.9-11.6v8.1c0 .6.4 1 1 1s1-.4 1-1v-8.7c0-.7-.2-1.4-.7-1.9" />
    <path d="M56.9 10.4H7.1c-2.8 0-5.1 2.3-5.1 5.1v31.4C2 49.7 4.3 52 7.1 52h27.6c.6 0 1-.4 1-1s-.4-1-1-1H7.1C5.4 50 4 48.6 4 46.9V15.5c0-.4.1-.8.2-1.1l25 15.2c.9.5 1.8.8 2.8.8s1.9-.3 2.8-.8l25-15.2c.1.3.2.7.2 1.1v14.4c0 .6.4 1 1 1s1-.4 1-1V15.4c0-2.8-2.3-5-5.1-5M33.8 27.8c-1.1.7-2.4.7-3.5 0l-24.7-15c.5-.3 1-.4 1.6-.4H57c.6 0 1.1.2 1.6.4z" />
  </svg>
);
export default IconMessageOutgoing;
