import * as React from "react";
const IconForwardCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.7 2c-.6 0-1 .4-1 1v28.2L14.9 4.6c-1.7-1.1-3.9-1.2-5.7-.2S6.3 7.2 6.3 9.2v45.6c0 2 1.1 3.8 2.9 4.8.8.5 1.7.7 2.7.7s2.1-.3 3-.9l40.8-26.6V61c0 .6.4 1 1 1s1-.4 1-1V3c0-.6-.5-1-1-1M13.8 57.7c-1.1.7-2.5.8-3.6.1-1.1-.6-1.8-1.8-1.8-3.1V9.2c0-1.3.7-2.4 1.8-3.1.5-.3 1.1-.4 1.7-.4.7 0 1.3.2 1.9.6L53.2 32z" />
  </svg>
);
export default IconForwardCopy;
