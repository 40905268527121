import * as React from "react";
const IconSaveAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.6 2h-41c-.6 0-1.1.2-1.6.5L4.7 12c-.5.5-.7 1-.7 1.7v44.2C4 60.2 5.8 62 8 62h48c2.2 0 4.1-1.8 4.1-4.1V4.3C60 3 59 2 57.6 2M22.2 4h25v10.5h-25zM58 57.9c0 1.1-.9 2.1-2.1 2.1H8c-1.1 0-2-.9-2-2.1V13.7c0-.2 0-.2.1-.3L16.3 4h3.9v11.5c0 .6.4 1 1 1h27c.6 0 1-.4 1-1V4h8.4c.2 0 .4.1.4.3z" />
    <path d="M33.4 23.6c-7.3 0-13.2 5.9-13.2 13.1s5.9 13.1 13.2 13.1 13.2-5.9 13.2-13.1-6-13.1-13.2-13.1m0 24.2c-6.2 0-11.2-5-11.2-11.1s5-11.1 11.2-11.1 11.2 5 11.2 11.1c-.1 6.1-5.1 11.1-11.2 11.1" />
  </svg>
);
export default IconSaveAlt;
