import * as React from "react";
const IconGame = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.4 25.1c-1.9-1.9-4.6-3.1-7.3-3.1H34.2V6.6c0-1.2-1-2.3-2.3-2.3s-2.3 1-2.3 2.3v15.5H12c-5.6 0-10.2 4.5-10.2 10.1v17.4c0 5.6 4.6 10.1 10.2 10.1 5.1 0 9.3-3.7 10.1-8.4h20c.8 4.8 5 8.5 10.1 8.5 5.6 0 10.2-4.5 10.2-10.1V32.2c-.1-2.6-1.1-5.1-3-7.1m-1.7 24.5c0 3.1-2.5 5.6-5.7 5.6s-5.7-2.5-5.7-5.6c0-1.6-1.3-2.9-2.9-2.9H20.6c-1.6 0-2.9 1.3-2.9 2.9 0 3-2.6 5.5-5.7 5.5-3.2 0-5.7-2.5-5.7-5.6V32.2c0-3.1 2.6-5.6 5.7-5.6h40.1c1.5 0 3 .6 4.1 1.7 1 1 1.6 2.4 1.6 3.9v17.4z" />
    <path d="M21.1 34.8h-2.2v-2.1c0-1.2-1-2.3-2.3-2.3s-2.3 1-2.3 2.3v2.1h-2.2c-1.2 0-2.3 1-2.3 2.3s1 2.3 2.3 2.3h2.2v2.1c0 1.2 1 2.3 2.3 2.3s2.3-1 2.3-2.3v-2.1h2.2c1.2 0 2.3-1 2.3-2.3s-1.1-2.3-2.3-2.3M49 35h.4c.1 0 .3-.1.4-.1.1-.1.3-.1.4-.2l.3-.3c.4-.4.7-1 .7-1.6s-.2-1.2-.7-1.6l-.3-.3c-.1-.1-.3-.1-.4-.2s-.3-.1-.4-.1c-.7-.1-1.5.1-2 .6-.4.4-.7 1-.7 1.6s.2 1.2.7 1.6c.4.3 1 .6 1.6.6M46.2 35.5l-.3-.3c-.1-.1-.3-.1-.4-.2s-.3-.1-.4-.1c-.7-.1-1.5.1-2 .6-.4.4-.7 1-.7 1.6s.2 1.2.7 1.6c.2.2.5.4.7.5.3.1.6.2.9.2h.4c.2 0 .3-.1.4-.1s.3-.1.4-.2l.3-.3c.4-.4.7-1 .7-1.6 0-.7-.3-1.3-.7-1.7M47.4 40.3c-.4.4-.7 1-.7 1.6s.2 1.2.7 1.6l.3.3c.1.1.3.2.4.2.1.1.3.1.4.1h.8c.1 0 .3-.1.4-.1.1-.1.3-.1.4-.2l.3-.3c.4-.4.7-1 .7-1.6s-.2-1.2-.7-1.6c-.6-.9-2.1-.9-3 0M55.4 36.2c-.1-.1-.1-.3-.2-.4l-.3-.3c-.5-.5-1.3-.8-2-.6-.1 0-.3.1-.4.1s-.3.1-.4.2l-.3.3-.3.3c-.1.1-.2.3-.2.4-.1.1-.1.3-.1.4v.4c0 .6.2 1.2.7 1.6.4.4 1 .7 1.6.7s1.2-.2 1.6-.7c.4-.4.7-1 .7-1.6v-.4c-.3-.1-.3-.2-.4-.4" />
  </svg>
);
export default IconGame;
