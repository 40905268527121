import * as React from "react";
const IconAnchorCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.1 48.7 48 43.3c-.4-.4-1-.4-1.4-.1L41.3 48c-.4.4-.4 1-.1 1.4.4.4 1 .4 1.4.1l3.6-3.2C45.8 53 40.1 58.5 32.9 59V24.8h6.8c.6 0 1-.4 1-1s-.4-1-1-1h-6.8v-5.9c3.4-.5 6-3.4 6-6.9 0-3.8-3.1-7-7-7s-7 3.1-7 7c0 3.5 2.6 6.4 6 6.9v5.9h-6.8c-.6 0-1 .4-1 1s.4 1 1 1h6.8V59c-7.2-.5-13-6-13.3-12.8l3.8 3.4c.4.4 1 .3 1.4-.1s.3-1-.1-1.4l-5.5-4.9c-.4-.4-1-.3-1.4.1l-4.9 5.3c-.4.4-.4 1 .1 1.4.2.2.4.3.7.3s.5-.1.7-.3l3.2-3.5c.5 8.1 7.6 14.5 16.3 14.5s15.9-6.5 16.3-14.6l3.4 3.7c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.5-1 .1-1.4M26.9 10c0-2.7 2.2-5 5-5 2.7 0 5 2.2 5 5s-2.2 5-5 5c-2.7 0-5-2.3-5-5" />
  </svg>
);
export default IconAnchorCopy;
