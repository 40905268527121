import * as React from "react";
const IconMoneyProtectionCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.1 7.9 33 2.1c-.7-.1-1.3-.1-2 0L9.9 7.9c-1.6.4-2.7 1.9-2.7 3.5v15.4c0 15.5 9.2 29.2 23.4 34.9.4.2.9.3 1.4.3s.9-.1 1.4-.3c14.2-5.8 23.4-19.5 23.4-35V11.4c-.1-1.6-1.2-3.1-2.7-3.5m.6 18.8c0 14.5-8.9 27.8-22.1 33.1-.4.2-.9.2-1.3 0-13.4-5.4-22-18.3-22-33V11.4c0-.7.5-1.4 1.2-1.6L31.6 4h.4c.1 0 .3 0 .4.1l21.1 5.8c.7.2 1.2.9 1.2 1.6z" />
    <path d="M35.6 28.3h-7.3c-2.4 0-4.3-1.9-4.3-4.3s1.9-4.3 4.3-4.3h11.6c.6 0 1-.4 1-1s-.4-1-1-1H33v-2.9c0-.6-.4-1-1-1s-1 .4-1 1v2.9h-2.6c-3.5 0-6.3 2.8-6.3 6.3s2.8 6.3 6.3 6.3h7.3c2.4 0 4.3 1.9 4.3 4.3s-1.9 4.3-4.3 4.3H24.1c-.6 0-1 .4-1 1s.4 1 1 1H31v2.9c0 .6.4 1 1 1s1-.4 1-1V41h2.6c3.5 0 6.3-2.8 6.3-6.3s-2.8-6.4-6.3-6.4" />
  </svg>
);
export default IconMoneyProtectionCopy;
