import * as React from "react";
const IconHammerCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M10.1 26.7h.2l24-.8v.5c0 .4.3.8.7 1l3.7 1.1v.8c0 2.8 2.3 5.1 5.1 5.1h5.1c2.8 0 5.1-2.3 5.1-5.1 0-5.1-.1-13.1-.1-13.1 0-.1-.1-10.3-10.6-14.1-.3-.1-.6-.1-.9.1s-.3.5-.3.8v9l-1.5 1.7h-5.4c-.6 0-1 .4-1 1v2.2L10.3 16c-1.4 0-2.7.5-3.8 1.4-1 1-1.6 2.3-1.6 3.7v.4c0 1.4.6 2.7 1.6 3.7q1.5 1.5 3.6 1.5m26.1-11h4.9c.3 0 .6-.1.8-.3l2-2.3c.2-.2.2-.4.2-.7V4.5C51.9 8.1 52 15.8 52 16.2c0 0 .1 7.9.1 13.1 0 1.7-1.4 3.1-3.1 3.1h-5.1c-1.7 0-3.1-1.4-3.1-3.1v-1.5c0-.4-.3-.8-.7-1l-3.7-1.1v-10zM7 21.2c0-.9.3-1.7 1-2.3.6-.6 1.4-.9 2.3-.9l24 .9v5l-24 .8c-.9 0-1.7-.3-2.3-.9s-1-1.4-1-2.3zM58 49.2H47.5v-5.5h4.8c.6 0 1-.4 1-1s-.4-1-1-1H41.8c-.6 0-1 .4-1 1s.4 1 1 1h3.7v5.5H18.4v-8.4h4.4c.6 0 1-.4 1-1s-.4-1-1-1H12.2c-.6 0-1 .4-1 1s.4 1 1 1h4.2v8.4H6c-2.2 0-4 1.8-4 4V58c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4v-4.8c0-2.2-1.8-4-4-4m2 8.8c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2v-4.8c0-1.1.9-2 2-2h52c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconHammerCopy;
