import * as React from "react";
const Icon3d = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.1 47.7c0-.1.1-.1 0 0 .1-.2.1-.3.1-.3V16.7c0-.4-.2-.7-.5-.9L32.5 2.1h-.7s-.1 0-.1.1h-.1L6.3 15.8c-.3.2-.5.5-.5.9v31c0 .1 0 .1.1.1 0 0 0 .1.1.1 0 .1.1.1.1.1s.1 0 .1.1l.1.1 25.2 13.7c.2.1.3.1.5.1s.3 0 .5-.1l25.2-13.7.1-.1s.1 0 .1-.1l.1-.1s0-.1.1-.1zm-1.9-2.1L33 33V4.7l23.2 12.6zM7.8 17.3 31 4.7V33L7.8 45.6zM32 59.9 8.9 47.3 32 34.8l23.1 12.5z" />
  </svg>
);
export default Icon3d;
