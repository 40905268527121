import * as React from "react";
const IconOralContraceptionPillsx21 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M29.656 8.442 8.443 29.656a2 2 0 0 0 0 2.828l7.07 7.071a2 2 0 0 0 2.83 0l21.212-21.213a2 2 0 0 0 0-2.828l-7.07-7.072a2 2 0 0 0-2.83 0m-22.628 19.8a4 4 0 0 0 0 5.656L14.1 40.97a4 4 0 0 0 5.656 0L40.97 19.756a4 4 0 0 0 0-5.657l-7.071-7.07a4 4 0 0 0-5.657 0z"
      clipRule="evenodd"
    />
    <path
      
      d="M16.928 24.706a1.25 1.25 0 1 1-1.768 1.768 1.25 1.25 0 0 1 1.768-1.768M19.403 22.231A1.25 1.25 0 1 1 17.635 24a1.25 1.25 0 0 1 1.768-1.768M21.878 19.756a1.25 1.25 0 1 1-1.768 1.768 1.25 1.25 0 0 1 1.768-1.768M24.353 17.281a1.25 1.25 0 1 1-1.769 1.768 1.25 1.25 0 0 1 1.769-1.768M26.827 14.806a1.25 1.25 0 1 1-1.767 1.768 1.25 1.25 0 0 1 1.767-1.768M29.302 12.332a1.25 1.25 0 1 1-1.767 1.767 1.25 1.25 0 0 1 1.767-1.767M32.484 15.514a1.25 1.25 0 1 1-1.768 1.767 1.25 1.25 0 0 1 1.768-1.767M14.453 27.18a1.25 1.25 0 1 1-1.768 1.769 1.25 1.25 0 0 1 1.768-1.768M11.625 30.01l-.53 2.297-1.768-1.767zM23.292 31.07a1.25 1.25 0 1 1-1.768 1.768 1.25 1.25 0 0 1 1.768-1.768M25.767 28.595a1.25 1.25 0 1 1-1.768 1.768 1.25 1.25 0 0 1 1.768-1.768M28.242 26.12a1.25 1.25 0 1 1-1.768 1.768 1.25 1.25 0 0 1 1.768-1.768M30.717 23.645a1.25 1.25 0 1 1-1.768 1.768 1.25 1.25 0 0 1 1.767-1.768M33.191 21.17a1.25 1.25 0 1 1-1.767 1.768 1.25 1.25 0 0 1 1.767-1.768M35.666 18.696a1.25 1.25 0 1 1-1.768 1.767 1.25 1.25 0 0 1 1.768-1.768M31.777 9.857a1.25 1.25 0 1 1-1.768 1.768 1.25 1.25 0 0 1 1.768-1.768M38.141 16.22a1.25 1.25 0 1 1-1.767 1.768 1.25 1.25 0 0 1 1.767-1.767M34.96 13.039a1.25 1.25 0 1 1-1.769 1.767 1.25 1.25 0 0 1 1.768-1.767M20.817 33.545a1.25 1.25 0 1 1-1.767 1.767 1.25 1.25 0 0 1 1.767-1.767M18.342 36.02a1.25 1.25 0 1 1-1.768 1.767 1.25 1.25 0 0 1 1.768-1.767M15.16 32.838a1.25 1.25 0 1 1-1.768 1.767 1.25 1.25 0 0 1 1.768-1.767M17.635 30.363a1.25 1.25 0 1 1-1.768 1.768 1.25 1.25 0 0 1 1.768-1.768"
    />
  </svg>
);
export default IconOralContraceptionPillsx21;
