import * as React from "react";
const IconNutrition = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M5.027 12.004c.15 2.691.916 4.357 2.038 5.384a1.9 1.9 0 0 0-.749 1.832c.572 3.635 2.003 11.323 4.937 19.989a1.827 1.827 0 0 0 3.494 0c2.933-8.666 4.365-16.354 4.937-19.989.116-.74-.201-1.425-.749-1.832 1.122-1.027 1.888-2.693 2.038-5.384a.954.954 0 0 0-.974-.998c-1.629.024-2.922.123-3.93.462-.417-1.099-1.258-2.136-2.523-3.082a.91.91 0 0 0-1.092 0c-1.255.939-2.093 1.966-2.513 3.055-.98-.289-2.262-.404-3.937-.433a.954.954 0 0 0-.977.996m9.854 1.973-.683-1.8c-.189-.499-.558-1.06-1.198-1.65-.634.584-1.002 1.14-1.193 1.634l-.663 1.72-1.768-.522c-.522-.154-1.234-.256-2.235-.311.297 1.928 1.012 2.743 1.663 3.164.932.603 2.29.786 4.166.788h.06c1.877-.002 3.234-.185 4.165-.788.652-.421 1.368-1.237 1.665-3.17-.97.051-1.651.152-2.154.321zM8.306 19h9.388c-.287 1.807-.784 4.6-1.57 8H12v2h3.645c-.69 2.782-1.56 5.882-2.645 9.13a115 115 0 0 1-.995-3.13H13v-2h-1.582a126 126 0 0 1-2.197-9H12v-2H8.827a107 107 0 0 1-.521-3"
      clipRule="evenodd"
    />
    <path
      
      d="M38.242 28.03a1 1 0 0 1 .728 1.212 6.43 6.43 0 0 1-4.728 4.728 1 1 0 0 1-.485-1.94 4.43 4.43 0 0 0 3.273-3.273 1 1 0 0 1 1.212-.727"
    />
    <path
      
      fillRule="evenodd"
      d="M32.65 21.027c-1.108-2.593-.916-5.347 1.057-7.32l-1.414-1.414c-2.33 2.33-2.734 5.377-1.953 8.178C25.764 18.4 21 22.208 21 28c0 5.523 4.925 10 11 10s11-4.477 11-10c0-6.22-5.495-10.151-10.35-6.973M32 24.061l-1.25-1c-1.858-1.486-3.688-1.403-5.074-.547C24.21 23.419 23 25.357 23 28c0 4.243 3.846 8 9 8s9-3.757 9-8c0-2.642-1.21-4.58-2.676-5.486-1.386-.856-3.217-.939-5.075.548z"
      clipRule="evenodd"
    />
    <path  d="M34 18c3 0 5-2 5-5-3 0-5 2-5 5" />
  </svg>
);
export default IconNutrition;
