import * as React from "react";
const IconSlimCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M9 28.5c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l3.5 3.5H3c-.6 0-1 .4-1 1s.4 1 1 1h8.1L7.6 39c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l5.2-5.2c.4-.4.4-1 0-1.4zM32 29.2c1.5 0 2.6-1.2 2.6-2.6S33.4 24 32 24s-2.6 1.2-2.6 2.6 1.2 2.6 2.6 2.6M61 33.5h-8.1l3.5-3.5c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-5.2 5.2c-.4.4-.4 1 0 1.4l5.2 5.2c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-3.5-3.5H61c.6 0 1-.4 1-1s-.4-1-1-1" />
    <path d="M43.4 27.4c-1.3-5.8 1.8-14.9 1.9-15 .2-.5-.1-1.1-.6-1.3s-1.1.1-1.3.6c-.1.4-3.3 9.8-1.9 16.1.3 1.3.6 2.5.9 3.7-1.2.1-2.5.3-3.9.5-2.1.3-4.3.6-6.5.6-2.3 0-4.5-.3-6.7-.7-1.4-.2-2.8-.4-4.1-.5q.45-1.65.9-3.6c1.4-6.3-1.8-15.7-1.9-16.1-.2-.5-.7-.8-1.3-.6-.5.2-.8.7-.6 1.3 0 .1 3.1 9.2 1.9 15-.6 2.6-1.2 4.8-1.8 6.7-.8 2.4-1.4 4.4-1.4 6.1 0 2.9 2.9 11.1 3.2 12 .1.4.5.7.9.7.1 0 .2 0 .3-.1.5-.2.8-.8.6-1.3-.6-1.6-1.8-5.2-2.5-8.1.2-.3 1.6-1.1 5.5-1.1 3.1 0 5.8 1.9 5.8 2.6v5.3c0 .6.4 1 1 1s1-.4 1-1V45c0-.9 1.8-2.6 4.6-2.6 4.3 0 6.1.7 6.4 1.1-.7 2.8-1.9 6.4-2.5 8.1-.2.5.1 1.1.6 1.3.1 0 .2.1.3.1.4 0 .8-.3.9-.7.3-.9 3.2-9 3.2-12 0-1.8-.6-3.7-1.4-6.1-.2-2-.9-4.1-1.5-6.8m-5.7 13c-2.6 0-4.7 1.1-5.8 2.4-1.5-1.4-4.3-2.4-6.7-2.4-2.8 0-4.8.3-6 1-.1-.4-.1-.8-.1-1.1 0-1.4.6-3.3 1.3-5.5.1-.4.3-.9.4-1.3.1.1.2.1.4.1 1.3.1 2.6.3 4 .5 2.2.3 4.5.7 7 .7 2.4 0 4.6-.3 6.8-.7 1.4-.2 2.7-.4 3.9-.5.1 0 .1 0 .2-.1.1.4.3.9.4 1.3.7 2.3 1.3 4.1 1.3 5.5 0 .3 0 .7-.1 1.2-1.6-.7-3.9-1.1-7-1.1" />
  </svg>
);
export default IconSlimCopy;
