import * as React from "react";
const IconSend = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.5 30.4q-.45-.75-1.2-1.2L11.2 3.4c-.6-.3-1.2-.5-1.9-.4-.6.1-1.2.3-1.7.8-.5.4-.8 1-1 1.6s-.2 1.2.1 1.9l8.7 24.4c.1.2.1.5 0 .8L6.7 56.7c-.2.6-.2 1.3-.1 1.9s.5 1.2 1 1.6 1.1.7 1.7.7h.3c.5 0 1.1-.1 1.5-.4l46.1-25.8q.75-.45 1.2-1.2c.3-.5.4-1 .4-1.6q.15-.75-.3-1.5M8.5 6.6c-.1-.2-.1-.5 0-.7s.2-.4.4-.6q.3-.3.6-.3h.1c.2 0 .4 0 .6.1L56.3 31H17.2v-.1zm1.7 52.3c-.2.1-.4.1-.7.1-.2 0-.5-.1-.7-.3s-.3-.4-.4-.6-.1-.5 0-.7l8.7-24.3V33h39.1-.1z" />
  </svg>
);
export default IconSend;
