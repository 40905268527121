import * as React from "react";
const IconScrewAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.7 10.6c-3.3-4.9-8.5-7.9-14.2-8.5-5.8-.5-11.4 1.5-15.5 5.6-.4.4-.8.9-1.2 1.3-.8 1-.7 2.4.2 3.3l5.4 5.4L21 29.3l-10 9.4L4.4 45c-1 .9-1.6 2.1-1.7 3.4L2 56.1c-.1 1.6.4 3.1 1.6 4.3 1 1 2.4 1.6 3.8 1.6h.5l7.7-.7c.8-.1 1.5-.3 2.1-.7.1 0 .2-.1.3-.2.3-.2.7-.5 1-.8l8.6-9.1c.2-.1.3-.2.4-.4l18.3-19.4 5.4 5.4c.5.5 1.1.7 1.7.7s1.1-.2 1.6-.6c.2-.2.4-.3.6-.5 7.2-6.5 8.5-17.1 3.1-25.1m-31.2 37-4.8-17.2 8-7.6 4.6 16.5zM15.4 59.3l-7.7.7c-1 .1-2-.3-2.7-1s-1.1-1.7-1-2.7l.7-7.7c.1-.8.4-1.6 1-2.2l5.4-5.1 5 17.8c-.2.1-.5.1-.7.2m2.5-1.4-5.1-18.1 8.3-7.8L26 49.3zM37 37.6l-4.6-16.3 2.3-2.2 10.1 10.1zm17.2-3.4c-.2.2-.3.3-.5.4-.2.2-.5.1-.6 0L29.3 10.9q-.3-.3 0-.6c.3-.4.7-.8 1.1-1.2 3.7-3.7 8.7-5.5 13.9-5 5.1.5 9.8 3.3 12.8 7.6 4.8 7.2 3.6 16.7-2.9 22.5" />
  </svg>
);
export default IconScrewAlt;
