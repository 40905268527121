import * as React from "react";
const IconImageCrop = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 53.1h-5.4V10.9H61c.6 0 1-.4 1-1s-.4-1-1-1h-5.4V3c0-.6-.4-1-1-1s-1 .4-1 1v5.9H10.4V3c0-.6-.4-1-1-1s-1 .4-1 1v5.9H3c-.6 0-1 .4-1 1s.4 1 1 1h5.4V53H3c-.6 0-1 .4-1 1s.4 1 1 1h5.4v6c0 .6.4 1 1 1s1-.4 1-1v-5.9h43.2V61c0 .6.4 1 1 1s1-.4 1-1v-5.9H61c.6 0 1-.4 1-1s-.4-1-1-1M10.4 10.9h43.2V34L48 28.3c-1.4-1.4-3.7-1.5-5.3-.2L35.1 35c-.4.3-.9.5-1.4.5-.5-.1-1-.3-1.3-.7l-8.3-11c-.7-.9-1.7-1.5-2.9-1.5-1.1 0-2.2.4-3 1.3l-7.9 8.8V10.9zm0 42.2V35.2l9.4-10.4c.4-.4.9-.6 1.4-.6s1 .3 1.4.7l8.3 11c.6.9 1.6 1.4 2.7 1.5h.4c.9 0 1.8-.3 2.5-1l7.6-6.9c.7-.6 1.8-.6 2.5.1l7 7.1v16.2H10.4z" />
  </svg>
);
export default IconImageCrop;
