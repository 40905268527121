import * as React from "react";
const IconVectorPen = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M49.1 49.2h-1.8l2.6-12c.1-.5 0-1.1-.2-1.6L34.2 3.4C33.7 2.5 32.9 2 32 2s-1.8.5-2.2 1.4L14.3 35.6c-.2.5-.3 1-.2 1.6l2.6 12h-1.8c-2.2 0-4 1.8-4 4V58c0 2.2 1.8 4 4 4H49c2.2 0 4-1.8 4-4v-4.8c.1-2.2-1.7-4-3.9-4M32 27c3 0 5.4 2.4 5.4 5.4S35 37.7 32 37.7s-5.4-2.4-5.4-5.4S29 27 32 27m0-23c.1 0 .2 0 .3.1-.2 0-.2-.1-.3-.1s-.2.1-.3.1c.1-.1.2-.1.3-.1M16.1 36.8v-.3L31 5.4V25c-3.6.5-6.4 3.6-6.4 7.3 0 4.1 3.3 7.4 7.4 7.4s7.4-3.3 7.4-7.4c0-3.7-2.8-6.8-6.4-7.3V5.6l14.9 30.9c0 .1.1.2 0 .3l-2.7 12.4H18.8zm35 21.2c0 1.1-.9 2-2 2H14.9c-1.1 0-2-.9-2-2v-4.8c0-1.1.9-2 2-2h34.2c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconVectorPen;
