import * as React from "react";
const IconPrevious = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.7 4.9c-1.3-.7-2.8-.6-4.1.2L18.4 30.3V6c0-2.2-1.8-4-4-4H6.3c-2.2 0-4 1.8-4 4v52c0 2.2 1.8 4 4 4h8.2c2.2 0 4-1.8 4-4V33.7l37.2 25.2c.7.5 1.4.7 2.2.7.6 0 1.3-.2 1.8-.5 1.3-.7 2.1-2 2.1-3.5V8.4c-.1-1.5-.9-2.8-2.1-3.5M16.4 58c0 1.1-.9 2-2 2H6.3c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2h8.2c1.1 0 2 .9 2 2v52zm43.3-2.4c0 .7-.4 1.4-1 1.7s-1.4.3-2-.1L19.4 32 56.7 6.8c.6-.4 1.4-.4 2-.1s1 1 1 1.7z" />
  </svg>
);
export default IconPrevious;
