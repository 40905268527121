import * as React from "react";
const IconDhis2Logo = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      d="m24.959 15.301 9.329 5.905-1.74 1.266-8.623-5.458-8.539 5.456-1.728-1.27 9.227-5.894a1.92 1.92 0 0 1 2.074-.005"
    />
    <path
      
      fillRule="evenodd"
      d="M6.835 20.913c-1.158-.85-1.102-2.649.106-3.42L22.885 7.305a1.92 1.92 0 0 1 2.074-.005l16.093 10.187c1.217.77 1.273 2.58.105 3.43L25.066 32.627c-.686.5-1.601.497-2.286-.005zm1.183-1.736L23.925 9.014l16.057 10.164.005.003.001.003a.1.1 0 0 1 .012.051.1.1 0 0 1-.01.055q-.001.005-.002.005l-.007.006-16.055 11.681L8.019 19.301l-.007-.006-.003-.006A.1.1 0 0 1 8 19.235a.1.1 0 0 1 .012-.051l.001-.003z"
      clipRule="evenodd"
    />
    <path
      
      d="m8.237 24.664 1.728 1.27-1.947 1.243-.005.004-.001.003a.1.1 0 0 0-.012.05.1.1 0 0 0 .011.059l.001.002.007.006 15.907 11.681 16.055-11.681.007-.006.001-.002.002-.003a.1.1 0 0 0 .009-.055.1.1 0 0 0-.012-.051l-.001-.003-.005-.003-1.98-1.254 1.74-1.265 1.31.829c1.217.77 1.273 2.58.105 3.43L25.066 40.627c-.686.5-1.601.497-2.286-.005L6.835 28.912c-1.158-.85-1.102-2.648.106-3.42z"
    />
  </svg>
);
export default IconDhis2Logo;
