import * as React from "react";
const IconSpeechBubble44 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m53.8 21.8-8.4-5.6c-.2-.1-.3-.3-.3-.4l-3.2-9.5c-.7-2-2.2-3.4-4.2-4s-4.1-.2-5.7 1.1l-7.9 6.3c-.2.1-.3.2-.5.2H13.5c-2 0-3.9 1-5.1 2.7s-1.4 3.8-.7 5.7l3.5 9.4q.15.3 0 .6l-3 9.6c-.6 1.9-.2 4.1 1 5.7 1.3 1.6 3.2 2.5 5.2 2.4l2.9-.1q-3 6.45-7.8 11.4c-.8.8-1 1.9-.5 2.9.4 1 1.4 1.6 2.5 1.6 1.4 0 10.1-2.2 18.9-12.4l2.9 2.1c1.7 1.2 3.8 1.5 5.7.8 2-.7 3.4-2.2 3.9-4.2l2.7-9.7c.1-.2.2-.3.3-.5l8.1-6c1.7-1.2 2.6-3.1 2.5-5.2.1-1.9-.9-3.7-2.7-4.9m-.9 8.7-8.1 6c-.5.4-.9.9-1.1 1.5L41 47.7c-.4 1.4-1.4 2.4-2.7 2.9s-2.8.3-3.9-.5l-3.6-2.6c-.4-.3-1-.2-1.3.2-9.2 11-17.9 12.3-18 12.3-.4 0-.6-.3-.6-.4-.1-.1-.2-.5.1-.8 3.7-3.8 6.7-8.4 8.9-13.5.1-.3.1-.7-.1-1-.2-.2-.5-.3-.8-.3l-4.5.2c-1.4.1-2.7-.5-3.6-1.7-.9-1.1-1.1-2.5-.7-3.9l3-9.6c.2-.6.2-1.3-.1-1.9l-3.5-9.4c-.5-1.3-.3-2.7.5-3.9s2.1-1.8 3.5-1.9l10.1-.1c.6 0 1.3-.2 1.8-.6l7.9-6.3c1.1-.9 2.5-1.2 3.9-.8s2.4 1.4 2.8 2.7l3.2 9.5c.2.6.6 1.1 1.1 1.5l8.4 5.6c1.2.8 1.9 2 1.9 3.4 0 1.5-.6 2.8-1.8 3.7" />
    <path d="M21.3 25.4H21c-1.1 0-2 .9-2 2s.9 2 2 2h.3c1.1 0 2-.9 2-2s-.9-2-2-2M31.7 25.4h-.3c-1.1 0-2 .9-2 2s.9 2 2 2h.3c1.1 0 2-.9 2-2s-.9-2-2-2M42 25.4h-.3c-1.1 0-2 .9-2 2s.9 2 2 2h.3c1.1 0 2-.9 2-2s-.9-2-2-2" />
  </svg>
);
export default IconSpeechBubble44;
