import * as React from "react";
const IconPaste = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.9 32.9 47 24.2c-.4-.4-1-.7-1.6-.7h-6.8V13.2c0-2.3-1.8-4.1-4-4.1h-2.2V7.2c0-1.4-1.1-2.6-2.5-2.6H24V3.5c0-.6-.4-1-1-1s-1 .4-1 1v1.2h-5.8c-1.4 0-2.5 1.2-2.5 2.6V9h-2.2c-2.2 0-4 1.9-4 4.1v35.1c0 2.3 1.8 4.1 4 4.1h15v5.6c0 2 1.6 3.6 3.6 3.6h23c2 0 3.6-1.6 3.6-3.6V34.5c-.2-.6-.4-1.2-.8-1.6m-2.6.2H47v-6.2zM15.7 7.2c0-.3.2-.6.5-.6h13.7c.3 0 .5.3.5.6v5.6c0 .3-.2.6-.5.6H16.2c-.3 0-.5-.3-.5-.6zm-4.2 43.2c-1.1 0-2-1-2-2.1V13.2c0-1.2.9-2.1 2-2.1h2.2v1.8c0 1.4 1.1 2.6 2.5 2.6h13.7c1.4 0 2.5-1.2 2.5-2.6V11h2.2c1.1 0 2 1 2 2.1v10.3H30c-2 0-3.6 1.6-3.6 3.6v23.3H11.5zM53 59.5H30c-.9 0-1.6-.7-1.6-1.6V27.1c0-.9.7-1.6 1.6-1.6h14.9v8.6c0 .6.4 1 1 1h8.6v22.8c0 .9-.7 1.6-1.5 1.6" />
  </svg>
);
export default IconPaste;
