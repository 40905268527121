import * as React from "react";
const IconFreeTransform = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 12.9c2.2 0 4-1.8 4-4V6c0-2.2-1.8-4-4-4h-2.9c-2.2 0-4 1.8-4 4v.5H37.3V6c0-2.2-1.8-4-4-4h-2.9c-2.2 0-4 1.8-4 4v.5H12.9V6c0-2.2-1.8-4-4-4H6C3.8 2 2 3.8 2 6v2.9c0 2.2 1.8 4 4 4h.5v13.6H6c-2.2 0-4 1.8-4 4v2.9c0 2.2 1.8 4 4 4h.5V51H6c-2.2 0-4 1.8-4 4v3c0 2.2 1.8 4 4 4h2.9c2.2 0 4-1.8 4-4v-.5h13.5v.5c0 2.2 1.8 4 4 4h2.9c2.2 0 4-1.8 4-4v-.5H51v.5c0 2.2 1.8 4 4 4h3c2.2 0 4-1.8 4-4v-2.9c0-2.2-1.8-4-4-4h-.5V37.5h.5c2.2 0 4-1.8 4-4v-2.9c0-2.2-1.8-4-4-4h-.5V12.9zM53.1 6c0-1.1.9-2 2-2H58c1.1 0 2 .9 2 2v2.9c0 1.1-.9 2-2 2h-2.9c-1.1 0-2-.9-2-2zM28.4 6c0-1.1.9-2 2-2h2.9c1.1 0 2 .9 2 2v2.9c0 1.1-.9 2-2 2h-2.9c-1.1 0-2-.9-2-2zM4 8.9V6c0-1.1.9-2 2-2h2.9c1.1 0 2 .9 2 2v2.9c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2m0 24.6v-2.9c0-1.1.9-2 2-2h2.9c1.1 0 2 .9 2 2v2.9c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2M10.9 58c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2v-2.9c0-1.1.9-2 2-2h2.9c1.1 0 2 .9 2 2zm24.4 0c0 1.1-.9 2-2 2h-2.9c-1.1 0-2-.9-2-2v-2.9c0-1.1.9-2 2-2h2.9c1.1 0 2 .9 2 2zM60 55.1V58c0 1.1-.9 2-2 2h-2.9c-1.1 0-2-.9-2-2v-2.9c0-1.1.9-2 2-2H58c1.1 0 2 .9 2 2m0-24.6v2.9c0 1.1-.9 2-2 2h-2.9c-1.1 0-2-.9-2-2v-2.9c0-1.1.9-2 2-2H58c1.1 0 2 .9 2 2m-4.5-4H55c-2.2 0-4 1.8-4 4v2.9c0 2.2 1.8 4 4 4h.5V51H55c-2.2 0-4 1.8-4 4v.5H37.3V55c0-2.2-1.8-4-4-4h-2.9c-2.2 0-4 1.8-4 4v.5H12.9V55c0-2.2-1.8-4-4-4h-.4V37.5H9c2.2 0 4-1.8 4-4v-2.9c0-2.2-1.8-4-4-4h-.5V12.9H9c2.2 0 4-1.8 4-4v-.4h13.5V9c0 2.2 1.8 4 4 4h2.9c2.2 0 4-1.8 4-4v-.5h13.7V9c0 2.2 1.8 4 4 4h.5v13.5z" />
    <path d="M37.2 22.8H26.8c-2.2 0-4 1.8-4 4v10.3c0 2.2 1.8 4 4 4h10.3c2.2 0 4-1.8 4-4V26.8c.1-2.2-1.7-4-3.9-4m2 14.4c0 1.1-.9 2-2 2H26.8c-1.1 0-2-.9-2-2V26.8c0-1.1.9-2 2-2h10.3c1.1 0 2 .9 2 2v10.4z" />
  </svg>
);
export default IconFreeTransform;
