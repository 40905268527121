import * as React from "react";
const IconUiSettings = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M19.134 5.603h9.732v1.663c.147 1.605.371 2.811.688 3.685.318.877.694 1.326 1.083 1.555.387.228.952.336 1.857.18.905-.155 2.053-.558 3.51-1.235l1.496-.864 4.866 8.429-1.207.696c-1.384.963-2.365 1.788-2.997 2.523-.634.738-.845 1.304-.85 1.766-.004.458.193 1.017.812 1.746.617.729 1.588 1.547 2.97 2.503l1.272.735-.519.899-4.347 7.53-1.56-.901c-1.485-.677-2.64-1.065-3.538-1.196-.892-.13-1.435.006-1.808.264-.39.27-.767.792-1.074 1.786-.307.992-.51 2.344-.63 4.123h-.024v.908h-9.732v-2.076c-.159-1.541-.397-2.678-.723-3.488-.324-.805-.7-1.201-1.094-1.397-.407-.203-1.008-.28-1.956-.087-.941.193-2.123.634-3.61 1.341l-1.251.722-4.866-8.428 1.822-1.052c1.215-.88 2.068-1.638 2.611-2.314s.722-1.2.718-1.636c-.004-.437-.191-.965-.751-1.646s-1.432-1.44-2.674-2.325l-1.726-.996 4.866-8.429 1.048.605c1.521.727 2.736 1.188 3.708 1.398.98.213 1.609.147 2.034-.05.405-.189.786-.572 1.114-1.361.33-.797.572-1.925.73-3.465zm-7.882 29.113c1.394-.645 2.626-1.103 3.707-1.325 1.185-.243 2.291-.222 3.25.256.972.484 1.619 1.352 2.058 2.44.433 1.076.696 2.447.862 4.083l.005.05v.178h5.837c.13-1.416.33-2.626.638-3.621.374-1.21.94-2.213 1.847-2.84.925-.64 2.033-.774 3.234-.599 1.174.171 2.528.648 4.073 1.352l2.89-5.006c-1.293-.916-2.326-1.784-3.055-2.644-.796-.938-1.296-1.948-1.285-3.059.01-1.108.528-2.113 1.332-3.05.727-.846 1.745-1.702 3.012-2.607l-2.898-5.02c-1.483.685-2.788 1.158-3.926 1.354-1.18.202-2.275.123-3.21-.428-.934-.55-1.54-1.47-1.949-2.597-.39-1.077-.632-2.428-.785-4.03h-5.755v.211l-.005.05c-.165 1.639-.433 3.01-.878 4.082-.453 1.089-1.122 1.944-2.118 2.407-.976.454-2.099.453-3.3.192-1.047-.227-2.234-.667-3.57-1.278l-2.897 5.017.076.044.038.027c1.32.936 2.37 1.825 3.098 2.712.734.892 1.198 1.85 1.207 2.9s-.44 2.01-1.159 2.905c-.713.888-1.746 1.777-3.04 2.712l-.042.03-.178.103z"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M24 27a3 3 0 1 0 0-6 3 3 0 0 0 0 6m0 2a5 5 0 1 0 0-10 5 5 0 0 0 0 10"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M24 31a7 7 0 1 0 0-14 7 7 0 0 0 0 14m0 2a9 9 0 1 0 0-18 9 9 0 0 0 0 18"
      clipRule="evenodd"
    />
  </svg>
);
export default IconUiSettings;
