import * as React from "react";
const IconArrowsExpandUpRight = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M19.4 61H7.2c-2.2 0-4-1.8-4-4V44.8c0-2.2 1.8-4 4-4h12.2c2.2 0 4 1.8 4 4V57c0 2.2-1.8 4-4 4M7.2 42.8c-1.1 0-2 .9-2 2V57c0 1.1.9 2 2 2h12.2c1.1 0 2-.9 2-2V44.8c0-1.1-.9-2-2-2zM33.5 31.5c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4L57.4 5H43.6c-.6 0-1-.4-1-1s.4-1 1-1h16.2c.1 0 .2 0 .3.1.1 0 .2.1.3.2s.2.2.2.3.1.2.1.4v16.2c0 .6-.4 1-1 1s-1-.4-1-1V6.4L34.3 31.2c-.2.2-.5.3-.8.3" />
  </svg>
);
export default IconArrowsExpandUpRight;
