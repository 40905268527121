import * as React from "react";
const IconIceCream = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.5 15.1c0-4.9-4-8.9-9-8.9-2.3 0-4.4.9-6 2.3C38.8 4.6 34.8 2 30.3 2c-5.1 0-9.4 3.4-10.7 8.1-1.1-.6-2.3-1-3.7-1-4.1 0-7.5 3.3-7.5 7.4 0 2.9 1.7 5.4 4.1 6.6l14.6 35.2c.9 2.2 2.9 3.5 5.3 3.5s4.4-1.4 5.3-3.6l15.2-36.7q.15-.3 0-.6c1.7-1.3 2.6-3.4 2.6-5.8m-9-6.9c3.9 0 7 3.1 7 6.9s-3.1 6.9-7 6.9c-2.7 0-5.2-1.6-6.3-4 .8-1.5 1.2-3.2 1.2-5 0-.8-.1-1.5-.3-2.3 1.4-1.5 3.4-2.5 5.4-2.5M30.3 4c4.1 0 7.8 2.8 8.8 6.7.2.8.3 1.5.3 2.3q0 2.4-1.2 4.5c-1.6 2.8-4.6 4.5-7.9 4.5-2.8 0-5.4-1.3-7.1-3.5.2-.6.3-1.3.3-2 0-2-.8-3.8-2.1-5.2.7-4.1 4.5-7.3 8.9-7.3M10.5 16.6c0-3 2.5-5.4 5.5-5.4s5.5 2.4 5.5 5.4S19 22 16 22s-5.5-2.4-5.5-5.4M36 57.7c-.7 1.7-2.3 2.3-3.5 2.3s-2.8-.6-3.5-2.3L15 24c.3 0 .6.1.9.1q1.95 0 3.6-.9c1.1-.6 2-1.5 2.7-2.5 1 1 2.2 1.9 3.5 2.4l.1.1h.1c1.4.6 2.8.9 4.4.9 1.5 0 3-.3 4.4-.9.1 0 .1-.1.2-.1 1.6-.7 3-1.8 4.1-3.1.8 1.3 2 2.4 3.4 3.1.1.1.1.1.2.1 1.2.6 2.6.9 3.9.9s2.6-.3 3.8-.8z" />
  </svg>
);
export default IconIceCream;
