import * as React from "react";
const IconTombstone1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.8 60h-4.6V11c0-5-4-9-9-9H21.8c-5 0-9 4-9 9v49H8.2c-.6 0-1 .4-1 1s.4 1 1 1h47.7c.6 0 1-.4 1-1s-.5-1-1.1-1m-41-49c0-3.9 3.1-7 7-7h20.4c3.9 0 7 3.1 7 7v49H14.8z" />
    <path d="m38.8 28.1 2.3-3.9c.2-.3.2-.7 0-1s-.5-.5-.9-.5h-4.6L32.8 18c-.2-.3-.5-.5-.9-.5s-.7.2-.9.5l-2.7 4.7h-4.6c-.4 0-.7.2-.9.5s-.2.7 0 1l2.3 3.9-2.3 3.9c-.2.3-.2.7 0 1s.5.5.9.5h4.6l2.8 4.7c.2.3.5.5.9.5s.7-.2.9-.5l2.7-4.7h4.6c.4 0 .7-.2.9-.5s.2-.7 0-1zm-.3-3.4-.8 1.4-.8-1.4zm-2 3.4-2 3.4h-4.9l-2-3.4 2-3.4h4.9zM32 20.5l1.3 2.2h-2.6zm-6.5 4.2h1.6l-.8 1.4zm0 6.7.8-1.4.8 1.4zm6.5 4.2-1.3-2.2h2.6zm4.8-4.2.8-1.4.8 1.4z" />
  </svg>
);
export default IconTombstone1;
