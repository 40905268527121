import * as React from "react";
const IconMedal = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.2 29c0-5.1-2-9.7-5.2-13.1V7.2C46 4.3 43.7 2 40.8 2H23.2C20.3 2 18 4.3 18 7.2v8.7c-3.2 3.4-5.2 8-5.2 13.1s2 9.7 5.2 13.1v18c0 .7.4 1.3.9 1.6.3.2.6.3 1 .3.3 0 .6-.1.8-.2l10.5-5.4q.75-.3 1.5 0l10.5 5.4c.6.3 1.3.3 1.8 0 .6-.3.9-1 .9-1.6v-18c3.3-3.5 5.3-8.1 5.3-13.2m-28-25h17.5C42.6 4 44 5.4 44 7.2V14c-3.3-2.6-7.5-4.2-12-4.2s-8.7 1.6-12 4.2V7.2C20 5.4 21.4 4 23.2 4m8.8 7.8c9.5 0 17.2 7.7 17.2 17.2S41.5 46.2 32 46.2 14.8 38.5 14.8 29 22.5 11.8 32 11.8m1.6 42.8c-1-.5-2.2-.5-3.3 0L20 59.9V44c3.3 2.6 7.5 4.2 12 4.2s8.7-1.6 12-4.2v16z" />
    <path d="m24 30 .7.5c.5.4.8 1.1.6 1.7l-.3.9c-.5 1.5 0 3 1.3 3.9s2.9.9 4.1 0l.7-.5c.5-.4 1.3-.4 1.8 0l.7.5c.6.5 1.3.7 2.1.7.7 0 1.4-.2 2.1-.7 1.3-.9 1.8-2.5 1.3-3.9l-.3-.8c-.2-.6 0-1.3.6-1.7l.6-.6c1.3-.9 1.8-2.5 1.3-3.9-.5-1.5-1.8-2.4-3.3-2.4h-.9c-.7 0-1.2-.4-1.4-1l-.3-.8c-.5-1.5-1.8-2.4-3.3-2.4-1.6 0-2.9 1-3.3 2.4l-.3.8c-.2.6-.8 1.1-1.4 1.1h-.9c-1.6 0-2.9 1-3.3 2.4-.7 1.4-.2 2.9 1.1 3.8m.6-3.3c.2-.5.6-1 1.4-1h.9c1.5 0 2.9-1 3.3-2.4l.3-.8c.3-.8.9-1 1.4-1s1.2.3 1.4 1l.3.8c.5 1.5 1.8 2.4 3.3 2.4h.9c.8 0 1.3.5 1.4 1 .2.5.1 1.2-.6 1.7l-.7.5c-1.2.9-1.8 2.5-1.3 3.9l.3.8c.3.8-.1 1.4-.6 1.7-.4.3-1.1.5-1.8 0l-.7-.5c-.6-.5-1.3-.7-2.1-.7-.7 0-1.4.2-2.1.7l-.7.5c-.7.5-1.4.3-1.8 0s-.8-.9-.6-1.7l.3-.8c.5-1.5 0-3-1.3-3.9l-.7-.5c-.3-.5-.3-1.2-.2-1.7" />
  </svg>
);
export default IconMedal;
