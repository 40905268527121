import * as React from "react";
const IconMetaverse4 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 65"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.6 18.5c0-.1-.1-.2-.1-.3s-.1-.1-.1-.2l-.2-.2L32.5 3.6c-.3-.2-.7-.2-1 0L5.9 17.8h-.1c-.1 0-.1.1-.2.2s-.1.1-.1.2-.1.2-.1.3v28.7c0 .4.2.7.5.9l25.7 13.4c.1 0 .2.1.2.1h.4c.1 0 .2-.1.3-.1l25.7-15.4c.3-.2.5-.5.5-.9V18.7c0-.1 0-.2-.1-.2m-1.9 26L33 58.7v-9.6c0-.6-.4-1-1-1s-1 .4-1 1v9.7L7.3 46.5V20.4l10.2 5.4c.5.3 1.1.1 1.4-.4s.1-1.1-.4-1.4L8.4 18.7 32 5.6l23.6 13.1-9.8 5.3c-.5.3-.7.9-.4 1.4s.9.7 1.4.4l9.9-5.4z" />
    <path d="M36.5 33.5h-9c-1.8 0-3.2 1.4-3.2 3.2v2.7c0 1.9 1.6 3.5 3.5 3.5h8.4c1.9 0 3.5-1.6 3.5-3.5v-2.7c0-1.7-1.5-3.2-3.2-3.2m1.2 5.9c0 .8-.7 1.5-1.5 1.5h-8.4c-.8 0-1.5-.7-1.5-1.5v-2.7c0-.7.5-1.2 1.2-1.2h9c.7 0 1.2.5 1.2 1.2zM32 30c3.1 0 5.6-2.5 5.6-5.6s-2.5-5.6-5.6-5.6-5.6 2.5-5.6 5.6c0 3 2.5 5.6 5.6 5.6m0-9.3c2 0 3.6 1.6 3.6 3.6S34 27.9 32 27.9s-3.6-1.6-3.6-3.6 1.6-3.6 3.6-3.6" />
  </svg>
);
export default IconMetaverse4;
