import * as React from "react";
const IconEraserAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m60 56.8-36.2-.1 4.8-4.8c1.3 0 2.5-.5 3.5-1.5l25.2-25.2c.9-.9 1.5-2.2 1.5-3.5s-.5-2.6-1.5-3.5L45.8 6.7c-.9-.9-2.2-1.5-3.5-1.5s-2.6.5-3.5 1.5L13.5 31.9c-.9.9-1.5 2.2-1.5 3.5l-5.9 5.9c-1.2 1.2-1.2 3.1 0 4.2l11.1 11.1H4c-.6 0-1 .4-1 1s.4 1 1 1l56 .2c.6 0 1-.4 1-1s-.4-1-1-1M14.9 33.3 40.1 8.1q.9-.9 2.1-.9c1.2 0 1.6.3 2.1.9l11.5 11.5q.9.9.9 2.1c0 .8-.3 1.6-.9 2.1L30.7 49c-1.2 1.2-3.1 1.2-4.2 0L14.9 37.5c-1.1-1.1-1.1-3 0-4.2m-7.3 9.4 5-5c.2.5.5.9.9 1.3L25 50.4c.4.4.8.7 1.3.9l-5 5c-.4.4-1 .4-1.4 0L7.6 44.1c-.4-.4-.4-1.1 0-1.4" />
  </svg>
);
export default IconEraserAlt;
