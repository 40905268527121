import * as React from "react";
const IconContraceptiveInjection = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      d="M25.304 23.323c.991-1.122 2.465-1.44 3.292-.71s.694 2.233-.298 3.355c-.648.735-1.504 1.124-2.25 1.102l-.002.004c-.207.347-.302.762-.384 1.176l-.01.045c-.074.38-.18.884-.672 1.394-.504.523-1.431.707-2.398.872-.641.11-.973.213-1.135.281-.133.056-.263.351-.442.756-.292.662-.713 1.617-1.49 2.302-.11-.993.203-2.722.38-3.655a1.7 1.7 0 0 1 .606-.753c.473-.338 1.175-.532 2.08-.686.835-.142 1.06-.321 1.115-.378.047-.05.086-.11.174-.553l.01-.047c.08-.406.198-1.005.586-1.656a4 4 0 0 1 .1-.161c-.268-.784-.008-1.844.738-2.688"
    />
    <path
      
      fillRule="evenodd"
      d="M24 38c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10m6.32-5.094A8 8 0 0 0 19.095 21.68l4.112 4.112-1.414 1.414-4.113-4.113A8 8 0 0 0 28.906 34.32l-3.113-3.112 1.414-1.414z"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M17.266 5.474A2 2 0 0 0 16 7.334V12h1.596l1.21 1.585-5.362 1.756A5 5 0 0 0 10 20.092V39a5 5 0 0 0 5 5h18a5 5 0 0 0 5-5V20.092a5 5 0 0 0-3.444-4.751l-5.362-1.756L30.404 12H32V7.334a2 2 0 0 0-1.266-1.86L27.708 4.28A4 4 0 0 0 26.239 4h-4.478a4 4 0 0 0-1.469.28zM29.894 10H30V7.334L26.973 6.14A2 2 0 0 0 26.24 6h-4.478a2 2 0 0 0-.734.14L18 7.334V10h11.895m-9.499 2.371L20.112 12h7.776l-.283.371a2 2 0 0 0 .967 3.114l5.362 1.756A3 3 0 0 1 36 20.092V39a3 3 0 0 1-3 3H15a3 3 0 0 1-3-3V20.092a3 3 0 0 1 2.066-2.85l5.362-1.757a2 2 0 0 0 .967-3.114"
      clipRule="evenodd"
    />
  </svg>
);
export default IconContraceptiveInjection;
