import * as React from "react";
const IconUmbrellaRainAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M33 17.1v-2.3c0-.6-.4-1-1-1s-1 .4-1 1v2.3c-13.1.5-23.6 11.1-23.6 24.1 0 .4.2.7.5.9s.7.2 1 0l5.4-3.2c1.2-.7 2.7-.7 4 0l2.9 1.7c1.9 1.1 4.2 1.1 6 0l2.9-1.7c.3-.2.6-.3 1-.4V61c0 .6.4 1 1 1s1-.4 1-1V38.4c.3.1.7.2 1 .4l2.9 1.7c1.9 1.1 4.2 1.1 6 0l2.9-1.7c1.2-.7 2.7-.7 4 0l5.4 3.2c.2.1.3.1.5.1s.3 0 .5-.1c.3-.2.5-.5.5-.9-.2-12.9-10.7-23.5-23.8-24m17.7 20c-1.9-1.1-4.2-1.1-6 0l-2.9 1.7c-1.2.7-2.7.7-4 0L35 37.1c-1.9-1.1-4.2-1.1-6 0l-2.9 1.7c-1.2.7-2.7.7-4 0l-2.9-1.7c-.9-.6-2-.8-3-.8s-2.1.3-3 .8l-3.8 2.3C10.4 28 20.2 19.1 32 19.1s21.6 9 22.5 20.3zM3 2c-.6 0-1 .4-1 1v4.9c0 .6.4 1 1 1s1-.4 1-1V3c0-.6-.4-1-1-1M17.5 8.9c.6 0 1-.4 1-1V3c0-.6-.4-1-1-1s-1 .4-1 1v4.9c0 .6.4 1 1 1M32 8.9c.6 0 1-.4 1-1V3c0-.6-.4-1-1-1s-1 .4-1 1v4.9c0 .6.4 1 1 1M46.5 8.9c.6 0 1-.4 1-1V3c0-.6-.4-1-1-1s-1 .4-1 1v4.9c0 .6.4 1 1 1M61 2c-.6 0-1 .4-1 1v4.9c0 .6.4 1 1 1s1-.4 1-1V3c0-.6-.4-1-1-1" />
  </svg>
);
export default IconUmbrellaRainAlt;
