import * as React from "react";
const IconSunCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 19.2c-7 0-12.8 5.7-12.8 12.8 0 7 5.7 12.8 12.8 12.8 7 0 12.8-5.7 12.8-12.8 0-7-5.8-12.8-12.8-12.8m0 23.6c-5.9 0-10.8-4.8-10.8-10.8 0-5.9 4.8-10.8 10.8-10.8 5.9 0 10.8 4.8 10.8 10.8 0 5.9-4.9 10.8-10.8 10.8M32 9.3c.6 0 1-.4 1-1V3c0-.6-.4-1-1-1s-1 .4-1 1v5.3c0 .5.4 1 1 1M32 54.7c-.6 0-1 .4-1 1V61c0 .6.4 1 1 1s1-.4 1-1v-5.3c0-.5-.4-1-1-1M51.4 11.2l-3.3 3.3c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l3.3-3.3c.4-.4.4-1 0-1.4s-1-.4-1.4 0M14.5 48.1l-3.3 3.3c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l3.3-3.3c.4-.4.4-1 0-1.4s-1-.4-1.4 0M61 31h-5.3c-.6 0-1 .4-1 1s.4 1 1 1H61c.6 0 1-.4 1-1s-.4-1-1-1M8.3 31H3c-.6 0-1 .4-1 1s.4 1 1 1h5.3c.6 0 1-.4 1-1s-.5-1-1-1M49.5 48.1c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l3.3 3.3c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4zM12.6 11.2c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l3.3 3.3c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4z" />
  </svg>
);
export default IconSunCopy;
