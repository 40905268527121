import * as React from "react";
const IconCrying2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M21.6 19.9v9.5c0 .6.4 1 1 1s1-.4 1-1v-9.5h4c.6 0 1-.4 1-1s-.4-1-1-1h-10c-.6 0-1 .4-1 1s.4 1 1 1zM46.4 17.9h-10c-.6 0-1 .4-1 1s.4 1 1 1h4v9.5c0 .6.4 1 1 1s1-.4 1-1v-9.5h4c.6 0 1-.4 1-1s-.5-1-1-1" />
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
    <path d="m46.5 43-2.8-1.7c-2-1.2-4.6-1.2-6.6 0-1.4.8-3.2.8-4.6 0-2-1.2-4.6-1.2-6.6 0-1.4.8-3.2.8-4.6 0l-2.8-1.7c-.5-.3-1.1-.1-1.4.3s-.1 1.1.4 1.4l2.8 1.7c2 1.2 4.6 1.2 6.6 0 1.4-.8 3.2-.8 4.6 0 2 1.2 4.6 1.2 6.6 0 1.4-.8 3.2-.8 4.6 0l2.8 1.7c.2.1.3.1.5.1.3 0 .7-.2.9-.5.2-.4.1-1.1-.4-1.3" />
  </svg>
);
export default IconCrying2;
