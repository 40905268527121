import * as React from "react";
const IconBuildingAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 58.7h-4.4v-6.8h3c.6 0 1-.4 1-1V40.4c0-2.3-1.8-4.1-4.1-4.1h-1.8c-2.3 0-4.1 1.8-4.1 4.1v10.5c0 .6.4 1 1 1h3v6.8h-9.1V7.3c0-2.2-1.8-4-4-4H6c-2.2 0-4 1.8-4 4v49.4c0 2.2 1.8 4 4 4h55c.6 0 1-.4 1-1s-.4-1-1-1m-8.4-18.3c0-1.2.9-2.1 2.1-2.1h1.8c1.2 0 2.1.9 2.1 2.1v9.5h-6zM4 56.7V7.3c0-1.1.9-2 2-2h35.5c1.1 0 2 .9 2 2v51.4H31.7V47.5c0-4.4-3.6-8-8-8s-8 3.6-8 8v11.2H6c-1.1 0-2-.9-2-2m25.7 2h-12V47.5c0-3.3 2.7-6 6-6s6 2.7 6 6z" />
    <path d="M11.9 20.4h7c1.1 0 2-.9 2-2v-5.7c0-1.1-.9-2-2-2h-7c-1.1 0-2 .9-2 2v5.7c0 1.1.9 2 2 2m0-7.7h7v5.7h-7zM28.7 20.4h7c1.1 0 2-.9 2-2v-5.7c0-1.1-.9-2-2-2h-7c-1.1 0-2 .9-2 2v5.7c0 1.1.9 2 2 2m0-7.7h7v5.7h-7zM18.8 34.7c1.1 0 2-.9 2-2V27c0-1.1-.9-2-2-2h-7c-1.1 0-2 .9-2 2v5.7c0 1.1.9 2 2 2zM11.9 27h7v5.7h-7zM28.7 34.7h7c1.1 0 2-.9 2-2V27c0-1.1-.9-2-2-2h-7c-1.1 0-2 .9-2 2v5.7c0 1.1.9 2 2 2m0-7.7h7v5.7h-7z" />
  </svg>
);
export default IconBuildingAlt;
