import * as React from "react";
const IconTailwindcss = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 65 64"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M17.86 26q3-12 15-12c12 0 13.5 9 19.5 10.5q6 1.5 10.5-4.5-3 12-15 12c-12 0-13.5-9-19.5-10.5q-6-1.5-10.5 4.5m-15 18q3-12 15-12c12 0 13.5 9 19.5 10.5q6 1.5 10.5-4.5-3 12-15 12c-12 0-13.5-9-19.5-10.5q-6-1.5-10.5 4.5"
      clipRule="evenodd"
    />
  </svg>
);
export default IconTailwindcss;
