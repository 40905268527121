import * as React from "react";
const IconRingAlt3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 25.3c-7.5 0-13.7 6.1-13.7 13.7S24.5 52.7 32 52.7 45.7 46.6 45.7 39 39.5 25.3 32 25.3m0 25.4c-6.4 0-11.7-5.2-11.7-11.7 0-6.4 5.2-11.7 11.7-11.7 6.4 0 11.7 5.2 11.7 11.7 0 6.4-5.3 11.7-11.7 11.7" />
    <path d="m33.9 16 4.6-4.6c1-1 1.6-2.4 1.6-3.9s-.6-2.9-1.6-3.9c-1.7-1.7-4.4-2.1-6.5-1-.8-.4-1.7-.6-2.6-.6-1.4 0-2.7.5-3.7 1.4-1.1 1-1.7 2.4-1.7 3.9 0 1.6.6 3.2 1.7 4.3l4.4 4.4C18.3 17 9 26.9 9 39c0 12.7 10.3 23 23 23s23-10.3 23-23c0-12.1-9.3-22-21.1-23m-8-8.6c0-1 .4-1.8 1.1-2.5q1.05-.9 2.4-.9c.7 0 1.4.2 2 .6.3.2.8.2 1.1 0 1.4-1 3.3-.8 4.5.4.7.7 1 1.6 1 2.5s-.4 1.8-1 2.5l-4.9 5q-.15.15-.3 0L27 10.2c-.7-.7-1.1-1.8-1.1-2.8M32 60c-11.6 0-21-9.4-21-21s9.4-21 21-21 21 9.4 21 21-9.4 21-21 21" />
  </svg>
);
export default IconRingAlt3;
