import * as React from "react";
const IconAws = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M18.5 26.9c0 2.2 1.1 3.2 1.1 3.8 0 .3-.1.4-.4.6l-1.3.8c-.1.1-.4.1-.6.1 0 0-.8.1-2-2.5-1.4 1.8-3.7 3-6 2.8-1.5.1-5.8-.8-5.6-5.5-.1-3.7 3.2-6 6.9-5.8.7 0 2.1 0 4.5.6v-1.5c.3-2.5-1.4-4.5-4.4-4.2-.1 0-1.8 0-4.5 1-.7.4-.7.4-1 .4-.7 0-.4-2-.3-2.4.4-.6 3.5-1.7 6.3-1.7 2-.1 3.9.4 5.5 1.7 1.3 1.4 1.8 3.2 1.7 5.1zM10 30c3.1 0 4.5-2 4.8-3 .1-1 .1-1.5.1-2.7-1-.1-2.4-.4-3.9-.4-1.4-.1-4.2.6-4.1 3.1.2 1.8 1.3 3.1 3.1 3m16.6 2.2c-.8.1-1.1-.4-1.3-1l-4.8-15.9c-.1-.3-.1-.6-.1-.8 0-.3.1-.4.4-.4h2.1c.8-.1 1.1.6 1.3 1l3.5 13.6L31 15.1c.1-.3.3-1.1 1.3-1H34c.1 0 1.1 0 1.3 1l3.2 13.8L42 15.1c0-.1.3-1.1 1.3-1h2c.1 0 .6-.1.4.8 0 .1.3-1.1-5.1 16.4-.1.6-.4 1.1-1.3 1h-1.8c-1.1.1-1.3-1-1.3-1.1L32.7 18l-3.2 13.2c0 .1-.1 1.1-1.3 1.1h-1.5v-.1zm26.4.6c-.6 0-3.2 0-5.5-1.1-.4-.1-.8-.7-.8-1.1v-1.1c0-.8.6-.7.8-.6 1 .4 1.5.7 2.8 1 3.5.8 5.1-.1 5.5-.4 1.3-.8 1.4-2.5.4-3.4-1-.8-1.4-.8-5.1-2-.3-.2-4.1-1.4-4.1-5.1-.1-2.7 2.4-5.5 6.7-5.5 1.3 0 4.5.4 5.5 1.5.1.1.1.4.1.7v1c0 .4-.1.7-.4.7-.8-.1-2-1.1-4.8-1.1-.7 0-3.9.1-3.7 2.4 0 1.8 2.7 2.5 3 2.7 3.5 1.1 4.8 1.3 6 3 1.7 2.1.8 4.6.4 5.3-1.9 3.5-6.6 3.1-6.8 3.1M57 43c-6.7 4.9-16.6 7.6-24.9 7.6-11.4.2-22.4-4-30.8-11.6-.7-.6-.1-1.4.7-.8 9.3 5.2 19.8 8 30.6 8 8 0 15.9-1.7 23.3-4.8 1.2-.5 2.2.7 1.1 1.6m2.8-3.2c-.8-1.1-5.8-.4-7.9-.3-.7.1-.8-.4-.1-.8 3.9-2.7 10.3-2 11-1.1.8.8-.1 7.3-3.9 10.4-.6.4-1.1.1-.8-.4.7-2.2 2.5-6.7 1.7-7.8" />
  </svg>
);
export default IconAws;
