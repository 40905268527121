import * as React from "react";
const IconDeliveryCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.2 12.2H26.9c-2.1 0-3.8 1.7-3.8 3.8v1.4H8.6c-.6 0-1.2.4-1.5.9l-5 10.1c0 .3-.1.6-.1.8v15.6c0 .6.4 1 1 1h3.6c.2 3.5 3.1 6.3 6.6 6.3s6.5-2.8 6.6-6.3h22.8c.2 3.5 3.1 6.3 6.6 6.3 3.6 0 6.5-2.8 6.6-6.3h4.4c.9 0 1.7-.8 1.7-1.7V16c.1-2.1-1.6-3.8-3.7-3.8M8.8 19.5h14.3V28H4.6zm4.4 30.7c-2.6 0-4.7-2.1-4.7-4.7s2.1-4.7 4.7-4.7 4.7 2.1 4.7 4.7-2.1 4.7-4.7 4.7m0-11.3c-3.1 0-5.7 2.1-6.4 5H4V30h19.1v13.8h-3.4c-.8-2.8-3.4-4.9-6.5-4.9m36.1 11.3c-2.5 0-4.4-1.9-4.6-4.3v-.7c.2-2.4 2.2-4.2 4.6-4.2 2.6 0 4.7 2.1 4.7 4.7-.1 2.4-2.1 4.5-4.7 4.5M60 43.9h-4.3c-.7-2.9-3.3-5-6.4-5s-5.7 2.1-6.4 5H25.1V16c0-1 .8-1.8 1.8-1.8h31.3c1 0 1.8.8 1.8 1.8z" />
  </svg>
);
export default IconDeliveryCopy;
