import * as React from "react";
const IconVrAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.4 26.2h-2.6v-2.1c0-2.9-2.3-5.2-5.2-5.2H13.4c-2.9 0-5.2 2.3-5.2 5.2v2.1H5.6c-2 0-3.6 1.6-3.6 3.6v4.5c0 2 1.6 3.6 3.6 3.6h2.6V40c0 2.9 2.3 5.2 5.2 5.2h11.1c.4 0 .7-.1 1.1-.2l4.2-1.9c.7-.3 1.4-.5 2.2-.5s1.5.2 2.2.5l4.2 1.9c.3.2.7.2 1.1.2h11.1c2.9 0 5.2-2.3 5.2-5.2v-2.1h2.6c2 0 3.6-1.6 3.6-3.6v-4.5c0-2-1.6-3.6-3.6-3.6M5.6 35.8c-.9 0-1.6-.7-1.6-1.6v-4.5c0-.9.7-1.6 1.6-1.6h2.6v7.7zm48.2 4.1c0 1.8-1.4 3.2-3.2 3.2H39.4c-.1 0-.2 0-.3-.1L35 41.1c-1-.4-2-.7-3-.7s-2.1.2-3 .7L24.8 43c-.1 0-.2.1-.3.1H13.4c-1.8 0-3.2-1.4-3.2-3.2V24.1c0-1.8 1.4-3.2 3.2-3.2h37.2c1.8 0 3.2 1.4 3.2 3.2zm6.2-5.7c0 .9-.7 1.6-1.6 1.6h-2.6v-7.7h2.6c.9 0 1.6.7 1.6 1.6z" />
  </svg>
);
export default IconVrAlt1;
