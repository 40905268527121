import * as React from "react";
const IconArrowUpCircle = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M33.6 19.1c-.9-.9-2.3-.9-3.2 0l-9.9 9.7c-.9.9-.9 2.3 0 3.2.4.4 1 .7 1.6.7s1.1-.2 1.6-.6l6.1-6v17.3c0 1.2 1 2.2 2.2 2.2s2.2-1 2.2-2.2V26l6.1 6c.9.9 2.3.9 3.2 0s.9-2.3 0-3.2z" />
    <path d="M32 1.7C15.3 1.7 1.8 15.3 1.8 32S15.3 62.3 32 62.3 62.2 48.7 62.2 32 48.7 1.7 32 1.7m0 56.1C17.8 57.8 6.2 46.2 6.2 32S17.8 6.2 32 6.2 57.8 17.8 57.8 32 46.2 57.8 32 57.8" />
  </svg>
);
export default IconArrowUpCircle;
