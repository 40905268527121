import * as React from "react";
const IconCottonBud = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M11 2C8.4 2 6.3 4.1 6.3 6.7v2.9c0 2.2 1.6 4.1 3.7 4.6v35.6c-2.1.5-3.7 2.3-3.7 4.6v2.9c0 2.6 2.1 4.7 4.7 4.7s4.7-2.1 4.7-4.7v-2.9c0-2.2-1.6-4.1-3.7-4.6V14.2c2.1-.5 3.7-2.3 3.7-4.6V6.7C15.7 4.1 13.6 2 11 2m2.7 52.4v2.9c0 1.5-1.2 2.7-2.7 2.7s-2.7-1.2-2.7-2.7v-2.9c0-1.5 1.2-2.7 2.7-2.7s2.7 1.2 2.7 2.7m0-44.8c0 1.5-1.2 2.7-2.7 2.7s-2.7-1.2-2.7-2.7V6.7C8.3 5.2 9.5 4 11 4s2.7 1.2 2.7 2.7zM32 2c-2.6 0-4.7 2.1-4.7 4.7v2.9c0 2.2 1.6 4.1 3.7 4.6v35.6c-2.1.5-3.7 2.3-3.7 4.6v2.9c0 2.6 2.1 4.7 4.7 4.7s4.7-2.1 4.7-4.7v-2.9c0-2.2-1.6-4.1-3.7-4.6V14.2c2.1-.5 3.7-2.3 3.7-4.6V6.7C36.7 4.1 34.6 2 32 2m2.7 52.4v2.9c0 1.5-1.2 2.7-2.7 2.7s-2.7-1.2-2.7-2.7v-2.9c0-1.5 1.2-2.7 2.7-2.7s2.7 1.2 2.7 2.7m0-44.8c0 1.5-1.2 2.7-2.7 2.7s-2.7-1.2-2.7-2.7V6.7C29.3 5.2 30.5 4 32 4s2.7 1.2 2.7 2.7zM57.7 9.6V6.7C57.7 4.1 55.6 2 53 2s-4.7 2.1-4.7 4.7v2.9c0 2.2 1.6 4.1 3.7 4.6v35.6c-2.1.5-3.7 2.3-3.7 4.6v2.9c0 2.6 2.1 4.7 4.7 4.7s4.7-2.1 4.7-4.7v-2.9c0-2.2-1.6-4.1-3.7-4.6V14.2c2.1-.5 3.7-2.3 3.7-4.6m-7.4 0V6.7C50.3 5.2 51.5 4 53 4s2.7 1.2 2.7 2.7v2.9c0 1.5-1.2 2.7-2.7 2.7s-2.7-1.2-2.7-2.7m5.4 44.8v2.9c0 1.5-1.2 2.7-2.7 2.7s-2.7-1.2-2.7-2.7v-2.9c0-1.5 1.2-2.7 2.7-2.7s2.7 1.2 2.7 2.7" />
  </svg>
);
export default IconCottonBud;
