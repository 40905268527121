import * as React from "react";
const IconCalenderAlt4 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56 11.2h-5.8V7.5c0-.6-.4-1-1-1s-1 .4-1 1v3.6H15.8V7.5c0-.6-.4-1-1-1s-1 .4-1 1v3.6H8c-3.3 0-6 2.7-6 6v34.3c0 3.3 2.7 6 6 6h48c3.3 0 6-2.7 6-6V17.2c0-3.3-2.7-6-6-6m-48 2h5.8v2.3c0 .6.4 1 1 1s1-.4 1-1v-2.3h32.3v2.3c0 .6.4 1 1 1s1-.4 1-1v-2.3H56c2.2 0 4 1.8 4 4v7.3H4v-7.3c0-2.2 1.8-4 4-4m48 42.3H8c-2.2 0-4-1.8-4-4v-25h56v25c0 2.2-1.8 4-4 4" />
    <path d="m40 34.5-9.9 9.9-3.9-3.9c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l4.6 4.6c.2.2.5.3.7.3s.5-.1.7-.3l10.6-10.6c.4-.4.4-1 0-1.4s-1-.4-1.4 0" />
  </svg>
);
export default IconCalenderAlt4;
