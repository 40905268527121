import * as React from "react";
const IconSoundcloudCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.4 25.8c-1.9-2.2-4.7-3.7-7.9-4.2-1.6-2.6-4.1-4.6-7-5.6-1.5-.5-2.9-.7-4.1-.7h-.6c-.9 0-1.6.8-1.6 1.7v29.8c0 1 .7 1.7 1.7 1.7h9.7c7.5 0 13.5-6.1 13.5-13.5-.1-3.5-1.4-6.7-3.7-9.2m-10 20.8h-9.3V17.4h.3c1 0 2.2.2 3.5.6 2.6.9 4.8 2.7 6.2 5.1l.3.4.5.1c2.9.3 5.4 1.6 7.1 3.6 2 2 3.1 4.8 3.1 7.8-.1 6.4-5.3 11.6-11.7 11.6M26.1 16.9c-.6 0-1 .4-1 1v28.2c0 .6.4 1 1 1s1-.4 1-1V17.9c0-.5-.5-1-1-1M13.6 18.6c-.6 0-1 .4-1 1v24.7c0 .6.4 1 1 1s1-.4 1-1V19.6c0-.5-.4-1-1-1M2 22.2c-.6 0-1 .4-1 1v17.5c0 .6.4 1 1 1s1-.4 1-1V23.2c0-.5-.4-1-1-1" />
  </svg>
);
export default IconSoundcloudCopy;
