import * as React from "react";
const IconFireExtinguisher = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.5 4.7c-.3-.2-.6-.2-.9-.1l-17 6.1H26.5V4c0-.6-.4-1-1-1s-1 .4-1 1v6.7H14.2c-5.6 0-10.1 4.5-10.1 10.1 0 .6.4 1 1 1s1-.4 1-1c0-4.5 3.6-8.1 8.1-8.1h10.3v5.7h-1c-5.5 0-10.1 4.5-10.1 10.1V56c0 2.8 2.2 5 5 5h14.2c2.8 0 5-2.2 5-5V28.5c0-5.5-4.5-10.1-10.1-10.1h-1v-5.7h15.1l17 6.1c.1 0 .2.1.3.1.2 0 .4-.1.6-.2.3-.2.4-.5.4-.8V5.6c0-.4-.1-.7-.4-.9M35.6 47.8H15.4v-9.6h20.2zm-3 11.2H18.4c-1.7 0-3-1.3-3-3v-6.2h20.2V56c0 1.7-1.4 3-3 3m3-30.5v7.8H15.4v-7.8c0-4.4 3.6-8.1 8.1-8.1h4.1c4.4.1 8 3.7 8 8.1m22.3-12-13.2-4.7L57.9 7z" />
  </svg>
);
export default IconFireExtinguisher;
