import * as React from "react";
const IconArc = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 2.1h-3.5c-2.2 0-4 1.8-4 4v.8c-13 1.8-23.4 7.1-31.6 16.5-10 11.2-11.8 24-12.1 27H6c-2.2 0-4 1.8-4 4v3.5c0 2.2 1.8 4 4 4h3.5c2.2 0 4-1.8 4-4v-3.5c0-2.2-1.8-4-4-4h-.7c.4-3.1 2.3-15.3 11.6-25.7 7.9-8.9 17.7-14.1 30.1-15.8v.6c0 2.2 1.8 4 4 4H58c2.2 0 4-1.8 4-4V6.1c0-2.2-1.8-4-4-4M11.5 54.4v3.5c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2v-3.5c0-1.1.9-2 2-2h3.5c1.1 0 2 .9 2 2M60 9.6c0 1.1-.9 2-2 2h-3.5c-1.1 0-2-.9-2-2V6.1c0-1.1.9-2 2-2H58c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconArc;
