import * as React from "react";
const IconUpload = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60 44c-1.2 0-2.3 1-2.3 2.3v8.9c0 .9-.7 1.6-1.6 1.6H7.9c-.9 0-1.6-.7-1.6-1.6v-8.9C6.3 45 5.2 44 4 44s-2.3 1-2.3 2.3v8.9c0 3.4 2.7 6.1 6.1 6.1h48.3c3.4 0 6.1-2.7 6.1-6.1v-8.9c.1-1.3-1-2.3-2.2-2.3" />
    <path d="m19.1 20.7 10.7-10.4v34.6c0 1.2 1 2.3 2.3 2.3 1.2 0 2.3-1 2.3-2.3V10.3l10.7 10.4c.4.4 1 .6 1.6.6s1.2-.2 1.6-.7c.9-.9.8-2.3 0-3.2l-14.7-14c-.9-.8-2.3-.8-3.1 0L15.9 17.5c-.9.9-.9 2.3 0 3.2.9.8 2.3.9 3.2 0" />
  </svg>
);
export default IconUpload;
