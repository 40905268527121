import * as React from "react";
const IconNfcSymbol = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M39.5 47.5c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4 7.6-7.6 7.6-20 0-27.7-.4-.4-.4-1 0-1.4s1-.4 1.4 0c8.4 8.4 8.4 22.1 0 30.5-.2.2-.5.3-.7.3M51.4 53.7c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4 11-11 11-29 0-40-.4-.4-.4-1 0-1.4s1-.4 1.4 0c11.8 11.8 11.8 31 0 42.9-.2.1-.4.2-.7.2M27.6 41.4c-.2 0-.5-.1-.6-.2L7.6 25C5.9 27 5 29.4 5 32c0 2.9 1.1 5.7 3.2 7.7.4.4.4 1 0 1.4s-1 .4-1.4 0C4.3 38.7 3 35.4 3 32s1.3-6.7 3.8-9.1c.4-.4 1-.4 1.3-.1L27.5 39c1.7-2 2.6-4.4 2.6-7 0-2.9-1.1-5.7-3.2-7.7-.4-.4-.4-1 0-1.4s1-.4 1.4 0c2.4 2.4 3.8 5.7 3.8 9.1s-1.3 6.7-3.8 9.1c-.2.2-.4.3-.7.3" />
  </svg>
);
export default IconNfcSymbol;
