import * as React from "react";
const IconCodingAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m55.3 15.6-20.7-12c-1.6-.9-3.5-.9-5.1 0l-20.7 12c-1.6.9-2.5 2.6-2.5 4.4v24c0 1.8 1 3.5 2.5 4.4l20.7 12c.8.5 1.7.7 2.5.7s1.8-.2 2.5-.7l20.7-12c1.6-.9 2.5-2.6 2.5-4.4V20c.1-1.8-.9-3.5-2.4-4.4m.5 28.4c0 1.1-.6 2.1-1.5 2.7l-20.7 12c-1 .6-2.1.6-3.1 0l-20.7-12c-1-.5-1.5-1.6-1.5-2.7V20c0-1.1.6-2.1 1.5-2.7l20.7-12c.4-.2 1-.3 1.5-.3s1.1.1 1.5.4l20.7 12c1 .6 1.5 1.6 1.5 2.7V44z" />
    <path d="M24.3 26.2c-.4-.4-1-.4-1.4-.1l-5.6 5c-.2.2-.3.5-.3.7s.1.6.3.7l5.6 5c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.3-1-.1-1.4l-4.7-4.3 4.7-4.3c.4-.2.4-.9.1-1.3M41.2 26.2c-.4-.4-1-.3-1.4.1s-.3 1 .1 1.4l4.7 4.3-4.7 4.3c-.4.4-.4 1-.1 1.4.2.2.5.3.7.3s.5-.1.7-.3l5.6-5c.2-.2.3-.5.3-.7s-.1-.6-.3-.7zM34.3 22.9c-.5-.1-1.1.2-1.2.7l-4.4 16.2c-.1.5.2 1.1.7 1.2h.3c.4 0 .8-.3 1-.7L35 24.2c.2-.6-.1-1.1-.7-1.3" />
  </svg>
);
export default IconCodingAlt;
