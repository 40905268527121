import * as React from "react";
const IconScaleAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M48.2 2H15.8c-3.6 0-6.5 2.9-6.5 6.5v46.9c0 3.6 2.9 6.5 6.5 6.5h32.4c3.6 0 6.5-2.9 6.5-6.5V8.5c.1-3.6-2.9-6.5-6.5-6.5m4.6 53.5c0 2.5-2 4.5-4.5 4.5H15.8c-2.5 0-4.5-2-4.5-4.5v-47c0-2.5 2-4.5 4.5-4.5h32.4c2.5 0 4.5 2 4.5 4.5v47z" />
    <path d="M32 10.5c-7.8 0-14.1 6.3-14.1 14.1 0 .5 0 .9.1 1.4v.5c0 .1.1.1.1.2l.1.1c.1.1.1.1.2.1l.1.1c.1 0 .2.1.4.1H45c.1 0 .3 0 .4-.1 0 0 .1 0 .1-.1.1 0 .1-.1.2-.1l.1-.1c0-.1.1-.1.1-.2v-.4c0-.5.1-1 .1-1.5.1-7.8-6.2-14.1-14-14.1M44.1 25h-11l3.1-5.2c.3-.5.1-1.1-.3-1.4-.5-.3-1.1-.1-1.4.3L30.8 25H19.9v-.4c0-6.7 5.4-12.1 12.1-12.1s12.1 5.4 12.1 12.1zM41.6 32.4H22.4c-2.2 0-4 1.8-4 4v12.9c0 2.2 1.8 4 4 4h19.3c2.2 0 4-1.8 4-4V36.4c-.1-2.2-1.9-4-4.1-4m2 16.9c0 1.1-.9 2-2 2H22.4c-1.1 0-2-.9-2-2V36.4c0-1.1.9-2 2-2h19.3c1.1 0 2 .9 2 2v12.9z" />
  </svg>
);
export default IconScaleAlt;
