import * as React from "react";
const IconDress3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.5 51 46.6 27.5l4.5-24.3c.1-.3 0-.6-.2-.8-.1-.3-.4-.4-.7-.4h-12c-.6 0-1 .4-1 1v3.7c0 2.9-2.3 5.2-5.2 5.2s-5.2-2.4-5.2-5.2V3c0-.6-.4-1-1-1H13.9c-.3 0-.6.1-.8.4-.2.2-.3.5-.2.8l4.5 24.1L4.5 50.9c-.2.4-.1.9.2 1.2C12.4 58.5 22.1 62 32 62s19.6-3.5 27.2-9.8c.4-.3.5-.8.3-1.2M15.1 4h9.7v2.7c0 4 3.2 7.2 7.2 7.2s7.2-3.2 7.2-7.2V4H49l-4.2 22.4H19.3zM32 60c-9.2 0-18.2-3.1-25.3-8.9l12.4-22.7h25.8l12.4 22.8C50.1 56.9 41.2 60 32 60" />
  </svg>
);
export default IconDress3;
