import * as React from "react";
const IconMedicines = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      d="M37.084 15.735a1 1 0 1 0 .448-1.949l-6.616-1.52a1 1 0 1 0-.448 1.948z"
    />
    <path
      
      fillRule="evenodd"
      d="M34 22a8 8 0 1 0 0-16 8 8 0 0 0 0 16m0-2a6 6 0 1 0 0-12 6 6 0 0 0 0 12M16.778 9.245c-.647-1.532-2.813-1.686-3.682-.263L6.292 20.115c-.785 1.284.092 2.924 1.623 3.033l11.866.846c1.53.11 2.644-1.388 2.06-2.77zm-1.967.768-.009.012L8.01 21.14l.002.002.01.004a.1.1 0 0 0 .036.007l11.867.846q.03.001.038 0a.06.06 0 0 0 .029-.015l-5.056-11.96-.004-.01a.1.1 0 0 0-.054-.014.13.13 0 0 0-.062.009l-.003.001v.001zM35.385 36.36a6 6 0 0 0-5.071-10.876l-10.876 5.07a6 6 0 1 0 5.071 10.877zm-15.102-3.992a4 4 0 1 0 3.381 7.25l4.466-2.083-3.38-7.25zm9.66 4.322-3.381-7.25 4.597-2.144a4 4 0 1 1 3.38 7.25z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconMedicines;
