import * as React from "react";
const IconTap = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M21.8 16.5c.5-.1.9-.6.9-1.1-.3-2.4.3-4.7 1.6-6.7 3.1-4.8 9.5-6.2 14.2-3.1 3.3 2.1 5.1 5.9 4.7 9.8-.1.5.3 1 .9 1.1h.1c.5 0 .9-.4 1-.9.5-4.7-1.6-9.2-5.6-11.7-5.7-3.6-13.4-2-17 3.7-1.5 2.4-2.2 5.2-1.9 8 0 .6.5 1 1.1.9" />
    <path d="M49.5 40.2c-4.9-6.2-11.3-9-13.5-9.8V18.1c0-1.8-1.4-3.3-3.2-3.5-1.8-.1-3.3 1.1-3.5 3l-.3 2.6c-.8 5.2-1.7 10.5-2.6 15.6-.2.7-.3 1.2-.6 1.6-.3.5-.6.7-.7.8-.3-.5-.8-1.4-1.2-2.3-1-2-1.5-2.9-1.9-3.2-.8-.9-2-2.1-3.8-2.4-.9-.2-2.1 0-2.8.5-.8.5-1.3 1-1.4 1.7-.1.6 0 1.3.5 2 1.3 2 2.4 4.3 3.3 7 .1.4.3.9.5 1.4.2.6.5 1.3.6 1.8.4 1.5 1.1 2.7 2.3 4.1 0 0 3.5 4.2 4.8 9.4.6 2.2 2.6 3.8 4.9 3.8l11-.1c1.4-.1 2.6-1.2 2.8-2.6l.3-1.9.1-.5c.2-.7.4-1.5.8-2.4 2.3-3.9 3.5-8.7 4.1-12.1.2-.6 0-1.4-.5-2.2M48.1 42c-.1 0-.1.1 0 0-.9 4.7-2.3 8.8-3.9 11.6 0 0 0 .1-.1.1-.4 1-.7 2-.9 2.7l-.2.5v.1l-.3 2c-.1.5-.5.8-.9.9l-11 .1c-1.4 0-2.6-.9-2.9-2.2-1.4-5.6-5-10-5.2-10.2-1-1.2-1.5-2.2-1.9-3.3-.2-.6-.4-1.4-.7-2-.2-.5-.3-.9-.5-1.3-1-2.9-2.1-5.4-3.5-7.5-.2-.3-.2-.4-.2-.5s.1-.2.5-.4c.2-.1.7-.2 1.1-.2h.3c1.2.2 2 1.1 2.7 1.9.3.3 1 1.8 1.5 2.8.5.9.9 1.8 1.3 2.4.5.9 1.2 1.1 1.7 1.1 1.1 0 1.9-1 2.5-1.8.5-.7.7-1.6.9-2.3 1-5.2 1.8-10.6 2.6-15.7l.4-2.7c.1-.8.7-1.3 1.4-1.3.8.1 1.3.7 1.3 1.5v13c0 .4.3.8.7 1 .1 0 7.8 2.5 13.2 9.4.1.1.1.2.1.3" />
  </svg>
);
export default IconTap;
