import * as React from "react";
const IconHospitalAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 21.7H33.4V7c0-2.8-2.2-5-5-5H7C4.2 2 2 4.2 2 7v54c0 .6.4 1 1 1h58c.6 0 1-.4 1-1V26.7c0-2.8-2.2-5-5-5M4 7c0-1.7 1.3-3 3-3h21.4c1.7 0 3 1.3 3 3v53h-8.7V44.2c0-.6-.4-1-1-1h-8c-.6 0-1 .4-1 1V60H4zm10.7 53V45.2h6V60zM60 60H33.4V23.7H57c1.7 0 3 1.3 3 3z" />
    <path d="M51.4 33.8h-3.7v-3.7c0-.6-.4-1-1-1s-1 .4-1 1v3.7H42c-.6 0-1 .4-1 1s.4 1 1 1h3.7v3.7c0 .6.4 1 1 1s1-.4 1-1v-3.7h3.7c.6 0 1-.4 1-1s-.5-1-1-1M13.7 19h8c.6 0 1-.4 1-1v-8c0-.6-.4-1-1-1h-8c-.6 0-1 .4-1 1v8c0 .5.5 1 1 1m1-8h6v6h-6zM13.7 35.2h8c.6 0 1-.4 1-1v-8c0-.6-.4-1-1-1h-8c-.6 0-1 .4-1 1v8c0 .5.5 1 1 1m1-8h6v6h-6z" />
  </svg>
);
export default IconHospitalAlt2;
