import * as React from "react";
const IconGlassesAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.6 31.8 46.8 12.7c-.2-.3-.5-.4-.8-.5-.3 0-.6.1-.8.3l-5 5.9c-.4.4-.3 1.1.1 1.4.4.4 1.1.3 1.4-.1l4.1-4.8 8.8 14.2c-1.3-.5-2.8-.8-4.3-.8-6.5 0-11.7 5.3-11.7 11.7v1l-6.1-3.8c-.3-.2-.7-.2-1.1 0l-6 3.8v-1c0-6.5-5.3-11.7-11.7-11.7-1.5 0-3 .3-4.3.8L18.2 15l4.1 4.8c.4.4 1 .5 1.4.1s.5-1 .1-1.4l-5-5.9c-.2-.2-.5-.4-.8-.3-.3 0-.6.2-.8.5l-11.8 19C3.3 33.9 2 36.8 2 40c0 6.5 5.3 11.7 11.7 11.7 5.2 0 9.6-3.4 11.1-8.1.1 0 .1-.1.2-.1l6.9-4.3 6.9 4.3c.1 0 .1.1.2.1 1.5 4.7 5.9 8.1 11.1 8.1C56.7 51.7 62 46.5 62 40c0-3.2-1.3-6.1-3.4-8.2M13.7 49.7C8.4 49.7 4 45.4 4 40s4.4-9.7 9.7-9.7 9.7 4.4 9.7 9.7-4.3 9.7-9.7 9.7m36.6 0c-5.4 0-9.7-4.4-9.7-9.7s4.4-9.7 9.7-9.7S60 34.6 60 40s-4.4 9.7-9.7 9.7" />
  </svg>
);
export default IconGlassesAlt;
