import * as React from "react";
const IconDrone = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54 44.2c-2.2 0-4.2 1-5.5 2.5l-6.2-5.3c.3-1 .5-2.1.5-3.2V25.9c0-1.1-.2-2.2-.5-3.2l6.2-5.3c1.4 1.5 3.3 2.5 5.5 2.5 4.1 0 7.4-3.3 7.4-7.4S58.1 4.9 54 4.9s-7.4 3.3-7.4 7.4c0 1.2.3 2.3.8 3.3l-5.9 5.1c-1.8-3.3-5.4-5.6-9.4-5.6s-7.6 2.3-9.4 5.6l-5.9-5.1c.5-1 .8-2.1.8-3.3 0-4.1-3.3-7.4-7.4-7.4s-7.4 3.3-7.4 7.4 3.3 7.4 7.4 7.4c2.2 0 4.2-1 5.5-2.5l6.2 5.3c-.3 1-.5 2.1-.5 3.2v12.2c0 1.1.2 2.2.5 3.2l-6.2 5.3c-1.4-1.5-3.3-2.5-5.5-2.5-4.1 0-7.4 3.3-7.4 7.4s3.3 7.4 7.4 7.4 7.4-3.3 7.4-7.4c0-1.2-.3-2.3-.8-3.3l5.9-5.1c1.1 2 2.7 3.5 4.7 4.5V51c0 2.2 1.8 4 4 4h1.4c2.2 0 4-1.8 4-4v-3.6c2-1 3.7-2.6 4.7-4.5l5.9 5.1c-.5 1-.8 2.1-.8 3.3 0 4.1 3.3 7.4 7.4 7.4s7.4-3.3 7.4-7.4-3.3-7.1-7.4-7.1m0-37.3c3 0 5.4 2.4 5.4 5.4S57 17.7 54 17.7s-5.4-2.4-5.4-5.4c0-2.9 2.4-5.4 5.4-5.4M10 17.8c-3 0-5.4-2.4-5.4-5.4S7 7 10 7s5.4 2.4 5.4 5.4-2.4 5.4-5.4 5.4m0 39.3c-3 0-5.4-2.4-5.4-5.4S7 46.3 10 46.3s5.4 2.4 5.4 5.4c0 2.9-2.4 5.4-5.4 5.4m24.7-5.7c0 1.1-.9 2-2 2h-1.4c-1.1 0-2-.9-2-2v-2.9c.9.2 1.8.4 2.7.4s1.8-.1 2.7-.4zM32 46.9c-4.8 0-8.8-3.9-8.8-8.8V25.9c0-4.8 3.9-8.8 8.8-8.8s8.8 3.9 8.8 8.8v12.2c0 4.8-4 8.8-8.8 8.8m22 10.2c-3 0-5.4-2.4-5.4-5.4s2.4-5.4 5.4-5.4 5.4 2.4 5.4 5.4c.1 2.9-2.4 5.4-5.4 5.4" />
    <path d="M33.7 23.2h-3.4c-.6 0-1 .4-1 1s.4 1 1 1h3.4c.6 0 1-.4 1-1s-.5-1-1-1M33.7 30.2h-3.4c-.6 0-1 .4-1 1s.4 1 1 1h3.4c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconDrone;
