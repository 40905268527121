import * as React from "react";
const IconSkippingRopeCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.4 44.2h-7.2c-3.2 0-5.9 2.3-6.5 5.3H18.9c-4.5 0-8.2-3.7-8.2-8.2s3.7-8.2 8.2-8.2h26.2c5.6 0 10.2-4.6 10.2-10.2s-4.6-10.2-10.2-10.2H22.5v-1.3c0-3.6-3-6.6-6.6-6.6H8.6C5 4.6 2 7.6 2 11.3v1.9c0 3.6 3 6.6 6.6 6.6h7.2c3.2 0 5.9-2.3 6.5-5.3h22.8c4.5 0 8.2 3.7 8.2 8.2S49.6 31 45.1 31H18.9c-5.6 0-10.2 4.6-10.2 10.2s4.6 10.2 10.2 10.2h22.7v1.3c0 3.6 3 6.6 6.6 6.6h7.2c3.6 0 6.6-3 6.6-6.6v-1.9c0-3.6-3-6.6-6.6-6.6m-35-31c0 2.5-2.1 4.6-4.6 4.6H8.6c-2.5 0-4.6-2.1-4.6-4.6v-1.9c0-2.5 2.1-4.6 4.6-4.6h7.2c2.5 0 4.6 2.1 4.6 4.6zM60 52.7c0 2.5-2.1 4.6-4.6 4.6h-7.2c-2.5 0-4.6-2.1-4.6-4.6v-1.9c0-2.5 2.1-4.6 4.6-4.6h7.2c2.5 0 4.6 2.1 4.6 4.6z" />
  </svg>
);
export default IconSkippingRopeCopy;
