import * as React from "react";
const IconHighHeel2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.2 13.4c-1.4-4.9-4.5-9-8.1-10.7l-.2-.1c-.7-.3-2.2-1-4.2-.3-1.9.7-2.7 2.4-3.1 3.3l-.3.4c-2.5 6.2-9.5 22.9-11.6 25.4-2.7 3.4-5.6 5.5-7.8 5.7-.1 0-.1-.1-.2-.1-1.9-1.2-4.1-2.4-5.7-2.4-1.5 0-17.9 5.5-17.9 13.2 0 7.4 8 14 17.2 14.1h.7c.8 0 1.9.1 3.3.1 4 0 9.9-.4 13.8-2.9 3.1-2 15-21 20.5-30v31.5c0 .6.4 1 1 1s1-.4 1-1V25.8c.3-.5.5-.9.7-1.1 1.8-2.8 2.1-6.9.9-11.3m-2.6 10.2s0 .1 0 0c-7.7 12.6-20 32-22.6 33.7-4.7 3-13.1 2.7-15.9 2.5h-.8C11.2 59.7 4.1 54 4.1 47.7c0-5.9 14.6-11.1 15.9-11.2.8 0 2.4.7 4.7 2.1.5.3.7.4 1 .4 2.9 0 6.4-2.3 9.6-6.4C38 29.4 46.8 7.7 47.2 6.8c0-.1.1-.2.2-.3.4-.7.9-1.9 2-2.3 1.2-.4 2.1-.1 2.7.2.1 0 .2.1.2.1 3.1 1.4 5.7 5.1 7 9.5 1 3.7.8 7.4-.7 9.6" />
  </svg>
);
export default IconHighHeel2;
