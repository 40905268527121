import * as React from "react";
const IconEye = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 52.7C12.2 52.7 2.4 33.8 2 33c-.3-.6-.3-1.4 0-2 .4-.8 10.2-19.6 30-19.6S61.6 30.2 62 31c.3.6.3 1.4 0 2-.4.8-10.2 19.7-30 19.7M6.6 32C8.8 35.7 17.4 48.2 32 48.2S55.2 35.7 57.4 32C55.2 28.3 46.6 15.8 32 15.8S8.8 28.3 6.6 32" />
    <path d="M32 40.5c-4.7 0-8.5-3.8-8.5-8.5s3.8-8.5 8.5-8.5 8.5 3.8 8.5 8.5-3.8 8.5-8.5 8.5M32 28c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4" />
  </svg>
);
export default IconEye;
