import React from "react";

export default function Container({
    classNameWrapper,
    classNameContainer,
    component,
    children,
}) {

    return (
        <div
            id={`${component.anchor_ ? component.anchor_ : 'Container-' + component.uuid}`}
            className={`${classNameWrapper} flex grow border-solid overflow-hidden`}
            style={{
                backgroundImage: `${component.image_ ? 'url("' + component.image_ + '")' : 'unset'}`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundColor: `${component.containerBackground_color ? component.containerBackground_color : 'unset'}`,
                backgroundPosition: component.image_backgroundPosition,
                borderColor: `${component.containerBorder_color}`
            }}>

            <div className={`overflow-hidden w-full flex flex-wrap ${classNameContainer}`}
                style={{
                    background: `${component.image_overlay ?? 'unset'}`,
                }}>
                {children}
            </div>
        </div>
    )
}