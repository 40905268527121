import * as React from "react";
const IconGrammarly = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M32 63c17.12 0 31-13.88 31-31S49.12 1 32 1 1 14.88 1 32s13.88 31 31 31m6.61-22.732c-1.04 0-2.002-.686-2.186-1.687a2.04 2.04 0 0 1 .452-1.71 2.1 2.1 0 0 1 .73-.553c.281-.13.588-.197.9-.196H50v11.804a2.05 2.05 0 0 1-.203.883c-.133.276-.326.52-.566.714a2.13 2.13 0 0 1-1.742.441c-1.023-.18-1.724-1.123-1.724-2.139v-1.97l.4-2.183a18.2 18.2 0 0 1-6.222 4.557 18.5 18.5 0 0 1-7.589 1.595c-10.496 0-18.932-8.5-18.323-18.914.526-8.947 7.92-16.265 17.055-16.865a18.64 18.64 0 0 1 9.515 1.868 18.24 18.24 0 0 1 7.215 6.373c.266.447.348.978.231 1.483a2.04 2.04 0 0 1-.862 1.239c-.962.652-2.285.308-2.95-.633-2.511-3.453-6.888-6.313-11.881-6.313-9.58 0-16.93 9.405-13.07 19.258 1.703 4.34 5.733 7.45 10.426 8.17 5.764.885 11.034-1.12 14.07-5.48l-1.897.258z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconGrammarly;
