import * as React from "react";
const IconIndentDecreaseCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M8.9 4h47.4c.6 0 1-.4 1-1s-.4-1-1-1H8.9c-.6 0-1 .4-1 1s.4 1 1 1M56.3 60H8.9c-.6 0-1 .4-1 1s.4 1 1 1h47.4c.6 0 1-.4 1-1s-.4-1-1-1M17.7 42.3c.3.3.7.4 1.1.4.2 0 .5 0 .7-.1.6-.3 1.1-.8 1.2-1.5v-18c0-.8-.4-1.4-1.1-1.7-.6-.3-1.3-.2-1.8.3L7.4 30.4c-.4.3-.7.9-.7 1.5s.3 1.1.7 1.4zm1-18.9v17.1l-10-8.6zM56.3 16.5H33.7c-.6 0-1 .4-1 1s.4 1 1 1h22.6c.6 0 1-.4 1-1s-.4-1-1-1M56.3 31H33.7c-.6 0-1 .4-1 1s.4 1 1 1h22.6c.6 0 1-.4 1-1s-.4-1-1-1M56.3 45.5H33.7c-.6 0-1 .4-1 1s.4 1 1 1h22.6c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconIndentDecreaseCopy;
