import * as React from "react";
const IconShoppingCart11 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.8 2.7c-.2-.5-.8-.8-1.3-.6l-7.6 2.8c-.4.1-.7.5-.7.9v12.7H11.9c-3.1 0-5.7 2.6-5.7 5.7v11.3c0 3 2.3 5.5 5.3 5.7l36.8 2.7v1.9c0 2.5-2 4.5-4.5 4.5H14.5c-3.2 0-5.8 2.6-5.8 5.8s2.6 5.8 5.8 5.8 5.8-2.6 5.8-5.8c0-1.5-.5-2.8-1.4-3.8h15c-.9 1-1.4 2.4-1.4 3.8 0 3.2 2.6 5.8 5.8 5.8s5.8-2.6 5.8-5.8c0-1.5-.5-2.8-1.4-3.8h1.1c3.6 0 6.5-2.9 6.5-6.5V6.5L57.2 4c.5-.2.8-.8.6-1.3m-9.6 39.2-36.6-2.7c-1.9-.1-3.4-1.8-3.4-3.7V24.1c0-2 1.7-3.7 3.7-3.7h36.4v21.5zM18.3 56.2c0 2.1-1.7 3.8-3.8 3.8s-3.8-1.7-3.8-3.8 1.7-3.8 3.8-3.8 3.8 1.7 3.8 3.8m23.8 0c0 2.1-1.7 3.8-3.8 3.8s-3.8-1.7-3.8-3.8 1.7-3.8 3.8-3.8c2.1-.1 3.8 1.7 3.8 3.8" />
  </svg>
);
export default IconShoppingCart11;
