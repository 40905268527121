import * as React from "react";
const IconBitcoinCloud = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.2 24.2c-2.7-2.9-6.5-4.9-10.6-5.4-2.1-3.5-5.3-6.2-9.1-7.4-1.6-.6-3.5-.9-5.5-.9-9.4 0-17.1 7.5-17.1 16.8v.1C7.7 27.5 2 33.3 2 40.4c0 7.2 6 13.1 13.3 13.2h28.8C54 53.6 62 45.7 62 36.1c0-4.4-1.7-8.6-4.8-11.9M44.1 51.6H15.4C9 51.6 4 46.7 4 40.4s5-11.2 11.4-11.2h.5c.6 0 1-.4 1-1v-1.1c0-8.2 6.8-14.8 15.1-14.8 1.8 0 3.4.3 4.9.8 3.5 1.2 6.5 3.7 8.3 7 .2.3.4.5.8.5 3.7.4 7.3 2.2 9.8 4.8 2.7 2.9 4.2 6.7 4.2 10.5 0 8.7-7.1 15.7-15.9 15.7" />
    <path d="M42.2 30v-.4c-.1-3.1-2.8-5.7-6.1-5.7h-.5v-1.8c0-.6-.4-1-1-1s-1 .4-1 1v1.8h-2.8v-1.8c0-.6-.4-1-1-1s-1 .4-1 1v1.8h-4c-.6 0-1 .4-1 1s.4 1 1 1h1.1v16.8h-1.1c-.6 0-1 .4-1 1s.4 1 1 1h4v1.9c0 .6.4 1 1 1s1-.4 1-1v-1.9h2.8v1.9c0 .6.4 1 1 1s1-.4 1-1v-1.9h.5c3.3 0 6-2.5 6.1-5.7v-.5c-.1-1.8-1-3.3-2.4-4.3 1.3-.8 2.3-2.4 2.4-4.2m-5.7 5.4c2 0 3.6 1.5 3.7 3.4v.3c-.1 2.1-1.9 3.8-4.1 3.8h-8.2v-7.4h8.6zm0-2h-8.6V26h8.2c2.2 0 4 1.7 4.1 3.8v.3c-.1 1.7-1.7 3.3-3.7 3.3" />
  </svg>
);
export default IconBitcoinCloud;
