import React from 'react';
import { BringzzText, BringzzIcon, BringzzButton } from "@bringzz/components";
import { useNavigation } from '../../context/NavigationContext';

const AddressSelect = ({ close, setAddress, className, ...props }) => {
	const { navigate } = useNavigation();

	return (
		<div className={`py-6 ${className}`}>
			<div className='flex flex-col px-4 gap-y-4 mt-4'>
				<div
					className='flex items-center justify-start gap-x-4 border rounded-md p-4 min-h-[75px] hover:cursor-pointer'
					onClick={() => {
						setAddress('Musterstrasse 10, 1040 Wien');
					}}
				>
					<BringzzIcon folder='LineIcons' icon='IconBriefcase' size='20' />
					<div className='flex flex-col'>
						<BringzzText tag='h4' className='font-bold'>
							Work
						</BringzzText>
						<BringzzText
							tag='h4'
							className='font-regular text-midnight-blue/80'
						>
							Musterstrasse 10, 1040 Wien
						</BringzzText>
					</div>
				</div>
				<div
					className='flex items-center justify-start gap-x-4 border rounded-md p-4 min-h-[75px] hover:cursor-pointer'
					onClick={() => {
						console.log('Selected current address');
					}}
				>
					<BringzzIcon folder='LineIcons' icon='IconMap' size='20' />
					<div className='flex flex-col'>
						<BringzzText tag='h4' className='font-bold'>
							Use current location
						</BringzzText>
						<BringzzText
							tag='h4'
							className='font-regular text-midnight-blue/80'
						>
							Musterstrasse 10, 1040 Wien
						</BringzzText>
					</div>
				</div>
				<div
					className='flex items-center justify-start gap-x-4 border rounded-md p-4 min-h-[75px] hover:cursor-pointer'
					onClick={() => {
						navigate('/address');
					}}
				>
					<BringzzIcon folder='LineIcons' icon='IconPlus' size='20' />
					<div className='flex flex-col'>
						<BringzzText tag='h4' className='font-bold'>
							Add new address
						</BringzzText>
					</div>
				</div>
			</div>
		</div>
	);
};
export default AddressSelect;
