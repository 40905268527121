import * as React from "react";
const IconThumbsUp = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.5 26.6c-2.6-5.8-11.4-5.6-16.6-5.4.6-3.6 1.7-11.3.3-13.9-1.1-2.3-3.3-3.7-5.9-3.9s-5 1-6.4 3.3c-5.7 8.9-11.2 14.8-13.2 17 0 0-.1.1-.2.1h-12c-2.6 0-4.8 2.2-4.8 4.8v22.9c0 2.7 2.2 4.8 4.8 4.8h8.9l.5.3s.1 0 .1.1c2.7 1.8 8.2 3.1 16.2 3.8 1.3.1 2.3.1 3.5.1 6.8 0 13.3-1.7 17.7-4.7 8.5-5.5 10.9-20.7 7.1-29.3M6.3 51.4V28.5q0-.3.3-.3H12v23.5H6.6c-.2.1-.3-.1-.3-.3m44.7.8c-3.8 2.5-9.3 3.9-15.3 3.9-1.1 0-2 0-3-.1-9.8-.9-13.1-2.4-14.1-3.1l-1-.7c-.3-.2-.7-.4-1.1-.4V28.2h2c1.3 0 2.6-.5 3.5-1.5 2.2-2.2 7.9-8.4 13.8-17.7.4-.8 1.2-1.2 2.2-1.1.7.1 1.7.3 2.2 1.4.3.7.5 4.8-.9 12.1l-.4 1.5c-.2.7 0 1.4.4 1.9s1.1.8 1.8.8h2.8c3.9-.1 11.1-.3 12.4 2.8 3.1 6.8 1.1 19.6-5.3 23.8" />
  </svg>
);
export default IconThumbsUp;
