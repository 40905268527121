import * as React from "react";
const IconAeroplaneDelivery = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m56.8 49.1-6-11.7 4.8-4.7c1.1-1.1 1.7-2.5 1.7-4.1 0-1.5-.6-3-1.7-4.1s-2.5-1.7-4.1-1.7c-1.5 0-3 .6-4.1 1.7l-4.7 4.7-4.6-2.2c.2-.5.4-1.1.4-1.7V11.4c0-1.5-.9-2.9-2.3-3.5l-12-5.5c-1-.5-2.2-.5-3.2 0L9 7.8c-.7.4-1.3.9-1.7 1.5s-.6 1.3-.6 2v13.9c0 1.5.8 2.8 2.1 3.5l12 6c.5.3 1.1.4 1.7.4s1.2-.1 1.7-.4l4.7-2.4 6.4 4.6-7.3 7.7-3.4-2.2c-1.6-1.1-4-.8-5.3.5-.9.9-1.4 2.2-1.2 3.5s.9 2.4 2 3.1l6.5 3.9 3.9 6.5c.8 1.3 2.2 2 3.6 2 1.1 0 2.2-.4 3-1.2 1.4-1.4 1.7-3.7.6-5.3L35.5 52l7.5-7.4 6.6 9c.8 1.1 2.1 1.7 3.4 1.7 1.1 0 2.2-.4 3-1.2 1.3-1.4 1.6-3.4.8-5m-35-44.9c.3-.1.6-.2.8-.2.3 0 .5.1.8.2l11.7 5.4-4.9 2.4h-.4L17.9 6zm.8 11.5L10.1 9.5 15.6 7l12.3 6.1zM9.8 27c-.6-.3-1-1-1-1.7V11.2l12.8 6.3V33zm13.8 5.9V17.4l5.4-2.7v4.9c0 .6.4 1 1 1s1-.4 1-1v-5.9l5.4-2.7v14.1c0 .7-.4 1.4-1 1.7zm31 19.7c-.9.9-2.6.8-3.4-.3l-6.6-8.9c-.3-.5-.8-.8-1.4-.8-.6-.1-1.1.2-1.5.6l-8.2 8c-.3.3-.4.9-.1 1.3l2.7 4.1c.6.9.5 2.1-.3 2.8-.4.4-1 .7-1.6.7-.8 0-1.5-.4-1.9-1.1l-4.1-6.7c-.1-.1-.2-.3-.3-.3l-6.7-4c-.6-.4-1-1-1.1-1.6-.1-.7.2-1.4.6-1.9.7-.7 2-.9 2.8-.3l4.1 2.7c.4.3.9.2 1.3-.1l8.1-8.1q.6-.6.6-1.5c0-.6-.3-1.1-.8-1.4l-5.6-4.1 5.3-2.6c.1-.1.3-.1.4-.2l5.1 2.6c.7.4 1.7.2 2.3-.4l4.7-4.8c.7-.7 1.6-1.1 2.6-1.1s1.9.4 2.7 1.1c.7.7 1.1 1.7 1.1 2.7s-.4 2-1.1 2.7L49.4 36c-.6.6-.8 1.5-.4 2.3L55 50c.4.8.3 1.9-.4 2.6" />
  </svg>
);
export default IconAeroplaneDelivery;
