import * as React from "react";
const IconMoneyLocationCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 1.7C18.2 1.7 6.9 12.3 6.9 25.3c0 10.4 15.6 28 22.3 35.1.7.8 1.7 1.2 2.8 1.2s2.1-.4 2.8-1.2c6.7-7.1 22.3-24.7 22.3-35.1 0-13-11.3-23.6-25.1-23.6m1.3 57.4c-.4.4-.8.6-1.3.6s-1-.2-1.3-.6C25.6 53.7 8.9 35.3 8.9 25.3 8.9 13.4 19.3 3.7 32 3.7s23.1 9.7 23.1 21.6c0 10-16.7 28.4-21.8 33.8" />
    <path d="M34.7 26.1h-5.3c-1.6 0-2.9-1.3-2.9-2.9s1.3-2.9 2.9-2.9h8.4c.6 0 1-.4 1-1s-.4-1-1-1H33v-1.9c0-.6-.4-1-1-1s-1 .4-1 1v1.9h-1.7c-2.7 0-4.9 2.2-4.9 4.9s2.2 4.9 4.9 4.9h5.3c1.6 0 2.9 1.3 2.9 2.9s-1.3 2.9-2.9 2.9h-8.4c-.6 0-1 .4-1 1s.4 1 1 1H31v1.9c0 .6.4 1 1 1s1-.4 1-1v-1.9h1.7c2.7 0 4.9-2.2 4.9-4.9-.1-2.7-2.3-4.9-4.9-4.9" />
  </svg>
);
export default IconMoneyLocationCopy;
