import { Wrapper, Status } from "@googlemaps/react-wrapper";
// import BasicMap from "../BasicMap";
import React, { useEffect, useRef, useState } from "react";
import osmtogeojson from "osmtogeojson";
import BringzzIcon from "../BringzzIcon";
// import ATgeoJson from "./ZipCodeATgeoJson.json";

const BringzzMap = ({
  center = { lat: -34.397, lng: 150.644 },
  zoom = 12,
  mode = 1,
  overlayData,
  onSelect,
}) => {
  const BasicMap = () => {
    const ref = useRef();
    const [map, setMap] = useState(null);
    const overlayColor = "#b080f7";

    const findCorrectOpacity = (zip) => {
      const item = overlayData.find((item) => {
        return item.zipcode == zip;
      });
      return item.opacity;
    };

    // const distinctZipcodes = allATzipcodes.reduce((acc, current) => {
    //   if (!acc.includes(current.zipcode)) {
    //     acc.push(current.zipcode);
    //   }
    //   return acc;
    // }, []);

    // console.log(distinctZipcodes);
    // const colors = ["red", "red", "blue"];
    function formatZipCodesForRegex(zipCodes) {
      return "^" + zipCodes.join("|") + "$";
    }

    // function generateRandomColors(count) {
    //   const colors = [];
    //   for (let i = 0; i < count; i++) {
    //     const color =
    //       "#" +
    //       Math.floor(Math.random() * 16777215)
    //         .toString(16)
    //         .padStart(6, "0");
    //     colors.push(color);
    //   }
    //   return colors;
    // }

    // function downloadResult(result) {
    //   const jsonString = JSON.stringify(result, null, 2); // Pretty print with 2 spaces

    //   // Create a Blob from the JSON string
    //   const blob = new Blob([jsonString], { type: "application/json" });

    //   // Create a URL for the blob
    //   const url = URL.createObjectURL(blob);

    //   // Create a temporary anchor element and trigger the download
    //   const a = document.createElement("a");
    //   a.href = url;
    //   a.download = "data.json"; // Name of the downloaded file
    //   document.body.appendChild(a); // Required for Firefox
    //   a.click(); // Trigger the download

    //   // Cleanup
    //   document.body.removeChild(a);
    //   URL.revokeObjectURL(url);
    // }

    function processFeature(feature, bounds) {
      let geometry = feature.getGeometry();
      geometry.forEachLatLng(function (latlng) {
        bounds.extend(latlng);
      });
    }

    useEffect(() => {
      if (!ref.current) return; // Exit if the ref isn't set yet

      if (mode == 2) {
        const zipCodes = overlayData.map((item) => {
          return item.zipcode;
        });
        const url = "https://overpass-api.de/api/interpreter";

        // Define the Overpass query
        const query = `
        [out:json];
        area["ISO3166-1"="AT"][boundary=administrative];
        (
          relation(area)["postal_code"~"^(${formatZipCodesForRegex(zipCodes)})$"]["boundary"="administrative"];
        );
        out body;
        >;
        out skel qt;
        `;

        // Make the HTTP request using fetch
        fetch(url, {
          method: "POST", // Overpass API supports GET and POST; POST is preferred for long queries
          body: new URLSearchParams({ data: query }),
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
          .then((response) => response.json()) // Parse the JSON response
          .then((data) => {
            // Assuming `osmData` is the JSON you received from the Overpass API
            const osmData = data; // The JSON data fetched from the Overpass API
            const result = osmtogeojson(osmData);
            console.log(result);
            // Download geoJson data

            // filter results
            result.features = result.features.filter(
              (feature) =>
                feature.geometry.type === "Polygon" ||
                feature.geometry.type === "MultiPolygon"
            );

            // downloadResult(result);

            // Check if GeoJSON data is provided and add it to the map
            if (result) {
              const map = new window.google.maps.Map(ref.current, {
                disableDoubleClickZoom: true,
                fullscreenControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                styles: [
                  {
                    featureType: "poi",
                    stylers: [{ visibility: "off" }], // This line hides points of interest
                  },
                ],
              });
              let bounds = new google.maps.LatLngBounds();

              const features = map.data.addGeoJson(result);

              // const colors = generateRandomColors(features.length);

              // Set opacities and extend map bounds
              features.forEach((feature, index) => {
                map.data.overrideStyle(feature, {
                  fillColor: overlayColor, // Example style, customize as needed
                  strokeWeight: 1,
                  strokeColor: overlayColor,
                  fillOpacity: findCorrectOpacity(feature.Gg.postal_code),
                });
                processFeature(feature, bounds);
              });

              // Adjust the map to fit
              map.fitBounds(bounds);

              // map.data.setStyle({
              //   fillColor: "red", // Example style, customize as needed
              //   strokeWeight: 0,
              //   fillOpacity: 0.4,
              // });

              setMap(map);
            }
          })
          .catch((error) => console.error("Error fetching data:", error));
      } else {
        const map = new window.google.maps.Map(ref.current, {
          center,
          zoom,
          disableDoubleClickZoom: true,
          fullscreenControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          styles: [
            {
              featureType: "poi",
              stylers: [{ visibility: "off" }], // This line hides points of interest
            },
          ],
        });

        setMap(map);
      }
    }, [overlayData]); // Rerun this effect if center, zoom, or GeoJSON data changes

    return (
      <>
        {/* <button
        onClick={() => {
          console.log(map.data.getStyle());
        }}
      >
        click
      </button> */}

        <div ref={ref} className="min-h-[500px] h-full relative" />
        {mode === 1 && (
          <>
            <div className="absolute left-1/2 top-3/4 -translate-x-1/2  z-40 flex flex-col items-center">
              <div
                className="bg-magic-lilac p-4 h-16 w-16 rounded-full flex justify-center items-center hover:cursor-pointer"
                onClick={() => {
                  let mapCenter = map?.getCenter();

                  let geocoder = new window.google.maps.Geocoder();
                  geocoder.geocode(
                    { location: mapCenter },
                    function (results, status) {
                      if (status === google.maps.GeocoderStatus.OK) {
                        if (results[0]) {
                          // console.log(results[0].formatted_address);
                          // Add location name to the returned object
                          const position = {
                            lng: mapCenter.lng(),
                            lat: mapCenter.lat(),
                            locationName: "",
                          };
                          position.locationName = results[0].formatted_address;
                          onSelect(position);
                        } else {
                          console.log("No results found");
                          onSelect(null);
                        }
                      } else {
                        console.log("Geocoder failed due to: " + status);
                        onSelect(null);
                      }
                    }
                  );
                }}
              >
                <span className="text-white font-bold text-2xl">GO</span>
              </div>
            </div>
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-40 flex flex-col items-center">
              <BringzzIcon
                icon="IconMapMarker"
                folder="LineIcons"
                className="text-magic-lilac translate-y-1.5"
                size="24"
              ></BringzzIcon>
              <div className="w-4 h-4 rounded-full bg-black/20"></div>
            </div>
          </>
        )}
      </>
    );
  };
  const render = (status) => {
    switch (status) {
      case Status.LOADING:
        return <span>loading</span>;
      case Status.FAILURE:
        return <span>Error</span>;
      case Status.SUCCESS:
        return (
          <BasicMap
            center={center}
            zoom={zoom}
            mode={mode}
            overlayData={overlayData}
          />
        );
    }
  };
  return (
    <Wrapper
      apiKey={"AIzaSyAtms-7qsst_vD187mM3n6QVRNGrk1xC_4"}
      render={render}
    />
  );
};

export default BringzzMap;
