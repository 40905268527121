import * as React from "react";
const IconFilm = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M22.4 16.9c-3.4 0-6.2 2.8-6.2 6.2s2.8 6.2 6.2 6.2 6.2-2.8 6.2-6.2-2.7-6.2-6.2-6.2m0 10.4c-2.3 0-4.2-1.9-4.2-4.2s1.9-4.2 4.2-4.2 4.2 1.9 4.2 4.2-1.8 4.2-4.2 4.2M41.6 29.3c3.4 0 6.2-2.8 6.2-6.2s-2.8-6.2-6.2-6.2-6.2 2.8-6.2 6.2 2.7 6.2 6.2 6.2m0-10.4c2.3 0 4.2 1.9 4.2 4.2s-1.9 4.2-4.2 4.2-4.2-1.9-4.2-4.2 1.8-4.2 4.2-4.2M22.4 34.7c-3.4 0-6.2 2.8-6.2 6.2s2.8 6.2 6.2 6.2 6.2-2.8 6.2-6.2-2.7-6.2-6.2-6.2m0 10.4c-2.3 0-4.2-1.9-4.2-4.2s1.9-4.2 4.2-4.2 4.2 1.9 4.2 4.2-1.8 4.2-4.2 4.2M41.6 47.1c3.4 0 6.2-2.8 6.2-6.2s-2.8-6.2-6.2-6.2-6.2 2.8-6.2 6.2 2.7 6.2 6.2 6.2m0-10.4c2.3 0 4.2 1.9 4.2 4.2s-1.9 4.2-4.2 4.2-4.2-1.9-4.2-4.2 1.8-4.2 4.2-4.2" />
    <path d="M61 60H42.8C54 55.7 62 44.8 62 32 62 15.5 48.5 2 32 2S2 15.5 2 32s13.5 30 30 30h29c.6 0 1-.4 1-1s-.4-1-1-1M4 32C4 16.6 16.6 4 32 4s28 12.6 28 28-12.6 28-28 28S4 47.4 4 32" />
  </svg>
);
export default IconFilm;
