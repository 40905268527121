import * as React from "react";
const IconTruckTimer = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.8 39-6.1-8.9c-.2-.3-.5-.4-.8-.4H44.7V27c0-2.6-2.1-4.7-4.7-4.7h-6.8c-.6 0-1 .4-1 1s.4 1 1 1H40c1.5 0 2.7 1.2 2.7 2.7v25.5h-9.6c-.3-2.3-2.2-4.2-4.6-4.2s-4.4 1.8-4.6 4.2h-6.6c-1.5 0-2.7-1.2-2.7-2.7V38c0-.6-.4-1-1-1s-1 .4-1 1v11.8c0 2.6 2.1 4.7 4.7 4.7H24c.6 1.9 2.4 3.2 4.4 3.2 2.1 0 3.8-1.3 4.4-3.2h14.4c.6 1.9 2.4 3.2 4.4 3.2s3.8-1.3 4.4-3.2h5c.6 0 1-.4 1-1v-14c0-.2-.1-.4-.2-.5m-7.5-7.3 5.2 7.5H44.7v-7.5zm-25.9 24c-1.5 0-2.7-1.2-2.7-2.7s1.2-2.7 2.7-2.7 2.7 1.2 2.7 2.7-1.2 2.7-2.7 2.7m23.3 0c-1.5 0-2.7-1.2-2.7-2.7s1.2-2.7 2.7-2.7 2.7 1.2 2.7 2.7-1.2 2.7-2.7 2.7m4.7-3.2c-.3-2.3-2.2-4.2-4.6-4.2s-4.4 1.8-4.6 4.2h-2.4V41.2H60v11.3z" />
    <path d="M28.2 19.4c0-7.2-5.9-13.1-13.1-13.1S2 12.2 2 19.4s5.9 13.1 13.1 13.1 13.1-5.9 13.1-13.1M4 19.4C4 13.3 9 8.3 15.1 8.3s11.1 5 11.1 11.1-5 11.1-11.1 11.1S4 25.5 4 19.4" />
    <path d="M19.4 23.9c.3 0 .6-.1.8-.4.3-.4.2-1.1-.2-1.4l-3.9-2.8v-4.8c0-.6-.5-1-1-1-.6 0-1 .5-1 1v5.4c0 .3.2.6.4.8l4.3 3.1c.2.1.4.1.6.1" />
  </svg>
);
export default IconTruckTimer;
