import * as React from "react";
const IconColumns = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.2 21.6H8.8c-3.7 0-6.8 3.1-6.8 6.8v21.8C2 53.9 5.1 57 8.8 57h46.4c3.7 0 6.8-3.1 6.8-6.8V28.4c0-3.8-3.1-6.8-6.8-6.8M4 50.1V28.4c0-2.6 2.2-4.8 4.8-4.8H31v31.3H8.8c-2.6 0-4.8-2.1-4.8-4.8m56 0c0 2.6-2.2 4.8-4.8 4.8H33V23.6h22.2c2.6 0 4.8 2.2 4.8 4.8zM3 9.1h58c.6 0 1-.4 1-1s-.4-1-1-1H3c-.6 0-1 .4-1 1s.4 1 1 1" />
  </svg>
);
export default IconColumns;
