import * as React from "react";
const IconKettlebell = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M52.7 29.5c0-.1.1-.2.1-.3l1.5-6.7c.1-.6 0-1.3-.4-1.8s-1-.8-1.7-.8H39.9c-.7 0-1.3.3-1.7.8s-.6 1.2-.4 1.8l1.5 6.7c0 .1.1.2.1.2l-1.2.6c-2-2.7-4.7-4.9-7.7-6.4l1.9-8.6c.1-.6 0-1.3-.4-1.8s-1-.8-1.7-.8H13.8c-.7 0-1.3.3-1.7.8s-.6 1.2-.4 1.8l1.9 8.6C6.8 26.9 2 33.8 2 41.9 2 45.5 3 49 4.8 52c.2.3.5.5.9.5h53.4c.4 0 .7-.2.9-.5 1.4-2.4 2.2-5.2 2.2-8.1-.2-6.3-4-11.9-9.5-14.4m-13-7.4V22l.1-.1h12.3c.1 0 .1 0 .1.1v.1l-1.5 6.7c-1.5-.5-3.1-.8-4.8-.8s-3.3.3-4.8.8zm-26.1-7.4v-.1l.1-.1h16.5c.1 0 .1 0 .1.1v.1L28.6 23c-2.1-.7-4.3-1.1-6.6-1.1s-4.5.4-6.6 1.1zM4 41.9c0-10 8.1-18.1 18.1-18.1s18.1 8.1 18.1 18.1c0 3-.8 6-2.2 8.6H6.2C4.8 47.9 4 44.9 4 41.9m54.4 8.6H40.1c1.3-2.7 2-5.6 2-8.6 0-3.7-1-7.1-2.8-10.1 2-1.1 4.4-1.7 6.7-1.7 7.7-.1 14 6.2 14 13.9 0 2.3-.6 4.5-1.6 6.5" />
  </svg>
);
export default IconKettlebell;
