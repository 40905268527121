import * as React from "react";
const IconCloudflare = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M9 37.3H7.6v4.9h3.7V41H9zM28 37.3h-1.8v4.8H28c1.7 0 2.7-1 2.7-2.5 0-1.3-1-2.3-2.7-2.3m1.4 2.5c0 .8-.5 1.2-1.3 1.2h-.5v-2.5h.5c.7.1 1.3.5 1.3 1.3M3.6 38.5c.5 0 1 .4 1.2.8h1.4c-.3-1.2-1.3-2.1-2.6-2.1C2 37.2 1 38.3 1 39.8c0 1.4 1.2 2.5 2.6 2.5 1.3 0 2.3-.8 2.6-1.9H4.7c-.2.4-.6.6-1.1.6-.8 0-1.3-.6-1.3-1.3.1-.6.5-1.2 1.3-1.2M22.6 40c0 .6-.3 1-.8 1s-.9-.4-.9-1v-2.7h-1.4V40c0 1.5.9 2.2 2.2 2.2 1.4 0 2.3-.6 2.3-2.2v-2.7h-1.4zM15.3 37.3c-1.6 0-2.6 1.2-2.6 2.6s1.2 2.5 2.6 2.5 2.6-1.2 2.6-2.6c0-1.5-1-2.5-2.6-2.5m1.3 2.5c0 .6-.5 1.3-1.3 1.3s-1.3-.7-1.3-1.3.5-1.3 1.2-1.3c.9 0 1.4.6 1.4 1.3M32.6 37.3v4.9h1.5v-1.8h2.2v-1h-2.2v-.9h2.4v-1.2zM58.2 40.3h2.3v-1.1h-2.3v-.7h2.6v-1.2h-3.9v4.9h3.9V41h-2.6zM55 39c0-.5-.1-.9-.4-1.2-.4-.4-.8-.5-1.6-.5h-2.3v4.8H52v-1.4h.6l1 1.4h1.5L54 40.5c.6-.2 1-.7 1-1.5m-1.4.1c0 .4-.3.5-.8.5h-.9v-1.2h.9c.5.1.8.3.8.7M62.5 37.4c-.3 0-.5.3-.5.5s.3.5.5.5c.3 0 .5-.3.5-.5s-.3-.5-.5-.5m0 .9c-.3 0-.4-.1-.4-.4s.1-.4.4-.4.4.1.4.4-.2.4-.4.4" />
    <path d="M62.7 37.8c0-.1-.1-.1-.3-.1h-.3v.5h.1v-.3h.1l.1.3h.1l-.1-.3c.3 0 .3 0 .3-.1m-.2.1h-.1v-.1h.1c.1-.1.1-.1 0 .1.1-.1.1 0 0 0M39.8 37.3h-1.3v4.9h3.6V41h-2.3zM45.6 37.3l-2.1 4.9h1.4l.4-.9h1.8l.4.9h1.4l-2.1-4.9zm.1 3 .5-1.4.5 1.4zM52.3 34.8l.1-.5c.1-.6.1-1.2-.1-1.6-.3-.4-.8-.6-1.3-.6L40.4 32c-.1 0-.1 0-.1-.1v-.1c0-.1.1-.1.3-.1l10.7-.1c1.3 0 2.7-1 3.1-2.3l.6-1.5v-.3c-.8-3.4-3.6-5.7-6.8-5.7-3.1 0-5.7 1.9-6.6 4.8-.6-.4-1.4-.6-2.2-.6-1.4.1-2.7 1.3-2.8 2.8 0 .4 0 .8.1 1-2.5.1-4.4 2.1-4.4 4.5v.6c0 .1.1.1.3.1h19.6c-.1 0 .1 0 .1-.2M55.9 27.5h-.3c-.1 0-.1 0-.1.1l-.4 1.4c-.1.6-.1 1.2.1 1.6.3.4.8.6 1.3.6l2.3.1c.1 0 .1 0 .1.1v.1c0 .1-.1.1-.3.1l-2.3.4c-1.3 0-2.7 1-3.1 2.3l-.1.4c0 .1 0 .1.1.1h8.1c.1 0 .1 0 .3-.1.1-.5.3-1 .3-1.5-.2-3.1-2.8-5.7-6-5.7" />
  </svg>
);
export default IconCloudflare;
