import * as React from "react";
const IconQuarterAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M37.7 54c-.6 0-1 .4-1 1v6c0 .6.4 1 1 1s1-.4 1-1v-6c0-.5-.5-1-1-1M24.7 49.2l-4.2 4.2c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l4.2-4.2c.4-.4.4-1 0-1.4s-1-.4-1.4 0M50 26.3c.3 0 .5-.1.7-.3l4.2-4.2c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-4.2 4.2c-.4.4-.4 1 0 1.4.2.2.5.3.7.3M61.1 36.6h-6c-.6 0-1 .4-1 1s.4 1 1 1h6c.6 0 1-.4 1-1s-.5-1-1-1M24.7 26c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-4.2-4.2c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4zM50.7 49.2c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l4.2 4.2c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4z" />
    <path d="M37.7 2C18 2 1.9 18.1 1.9 37.8c0 .6.4 1 1 1h34.8c.6 0 1-.4 1-1V3c0-.6-.5-1-1-1m-1 34.8H3.9C4.4 19 18.9 4.5 36.7 4z" />
  </svg>
);
export default IconQuarterAlt1;
