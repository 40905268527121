import * as React from "react";
const IconBluetoothAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M30.7 23.1c-2.1 2.1-2.1 5.6 0 7.7 1.1 1.1 2.5 1.6 3.9 1.6s2.8-.5 3.9-1.6c1-1 1.6-2.4 1.6-3.9s-.6-2.8-1.6-3.9c-2.2-2-5.7-2-7.8.1m6.3 6.3c-1.3 1.3-3.5 1.3-4.9 0-1.3-1.3-1.3-3.5 0-4.9.7-.7 1.6-1 2.4-1s1.8.3 2.4 1c.7.7 1 1.5 1 2.4.1 1-.2 1.9-.9 2.5M22.4 37.8 14 46.2c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l8.4-8.4c.4-.4.4-1 0-1.4s-1.1-.4-1.4 0" />
    <path d="M57.7 8.7C53.9 4.9 48.3 3.5 43.1 5c-.5.2-.8.7-.7 1.2.2.5.7.8 1.2.7 4.5-1.3 9.3-.1 12.6 3.2 5 5 5 13.1 0 18-2.6 2.6-6.1 3.9-9.8 3.7.6-1.5 1-3.1 1.1-4.7.1-3.6-1.2-6.9-3.7-9.4S38 13.9 34.4 14s-6.8 1.7-9.2 4.3L4.7 41.8C.9 46 1.2 52.5 5.1 56.4c2.1 2.1 4.8 3.1 7.6 3.1 2.5 0 5-.9 7.1-2.7l23.5-20.6c.9-.8 1.6-1.6 2.2-2.5.6.1 1.2.1 1.8.1 3.9 0 7.6-1.5 10.4-4.3 5.7-5.7 5.7-15 0-20.8M41.9 34.8 18.5 55.4c-3.4 3-8.7 2.9-11.9-.4-3.2-3.2-3.4-8.5-.4-11.9l20.6-23.5c2-2.3 4.7-3.6 7.7-3.7s5.8 1 8 3.1c2.1 2.1 3.2 4.9 3.1 8s-1.4 5.8-3.7 7.8" />
  </svg>
);
export default IconBluetoothAlt;
