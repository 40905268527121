import * as React from "react";
const IconDiamondShapeCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.1 24.4 50 3.8C49.6 2.7 48.5 2 47.3 2H16.7c-1.2 0-2.2.7-2.7 1.8L4.9 24.4c-.4 1-.3 2.2.3 3.1l24.4 33.3Q30.5 62 32 62c.9 0 1.8-.4 2.4-1.2l24.4-33.3c.6-.9.7-2.1.3-3.1m-1.9 1.9L32.8 59.6c-.2.3-.5.4-.8.4s-.6-.1-.8-.4L6.8 26.3c-.2-.3-.3-.7-.1-1.1l9.1-20.6c.1-.4.5-.6.9-.6h30.6q.6 0 .9.6l9.1 20.6c.1.4.1.8-.1 1.1" />
  </svg>
);
export default IconDiamondShapeCopy;
