import * as React from "react";
const IconAppleBrandCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.4 48.5c-1.7-.9-3.1-2.2-4.3-4-1.7-2.6-2.5-5.3-2.5-8.2 0-2.7.8-5.2 2.4-7.6.7-.9 1.6-2 2.7-3 .2-.2.3-.4.3-.7s-.1-.5-.3-.7c-.8-.8-1.5-1.4-2.2-1.9q-3-2.1-6.9-2.1c-1.6 0-3.5.4-5.7 1.1-2.4.8-4.1 1.2-5.2 1.2-.5 0-1.5 0-5-1-2.4-.7-4.3-1-5.9-1-3.7 0-6.6 1.5-9.1 4.6C10.3 28.3 9 32.5 9 37.6c0 5.3 1.7 11 5 16.9 3.3 5.6 6.5 8.5 9.6 8.5 1.2 0 2.5-.4 4.2-1.1 1.9-.8 3.6-1.2 5.2-1.2s3.5.4 5.5 1.2c2.5.9 3.8 1.1 4.5 1.1 2.6 0 5.3-2.2 8.1-6.4 1.5-2.2 2.7-4.5 3.6-6.8.4-.6.2-1.1-.3-1.3m-4.7 6.9c-2.3 3.6-4.6 5.5-6.5 5.5-.3 0-1.3-.1-3.8-1-2.3-.8-4.4-1.3-6.2-1.3-1.9 0-3.8.4-6 1.4-1.4.6-2.6.9-3.5.9-2.3 0-5.1-2.7-7.9-7.5-3.1-5.6-4.7-11-4.7-15.9 0-4.6 1.1-8.4 3.3-11.1 2.1-2.6 4.5-3.9 7.5-3.9 1.3 0 3.1.3 5.3.9 3.3 1 4.7 1.1 5.6 1.1 1.4 0 3.3-.4 5.9-1.3 2-.7 3.7-1 5.1-1 2.2 0 4.1.6 5.7 1.7.4.3.7.6 1.2.9-.9.9-1.6 1.8-2.2 2.6q-2.7 4.05-2.7 8.7c0 3.3 1 6.5 2.9 9.4 1.2 1.8 2.6 3.2 4.3 4.2q-1.5 3-3.3 5.7M32.9 15.2c.2 0 .4 0 .6-.1 2.2-.4 5.3-1.3 7.4-3 2.1-2 2.9-5.3 3.2-7.7.1-.9-.2-1.9-.9-2.5-.7-.7-1.6-1-2.5-.8-2.4.4-5.6 1.2-7.7 3.2-1.2 1.2-2.2 3.7-3 7.4-.2 1 .1 2 .8 2.7.5.5 1.3.8 2.1.8m-1-3.2c.7-3.2 1.5-5.5 2.4-6.4C36.1 4.1 38.9 3.3 41 3h.1c.2 0 .5.1.7.3s.3.5.3.8c-.4 3-1.3 5.3-2.6 6.5-1.7 1.4-4.4 2.2-6.4 2.6-.3.1-.7 0-.9-.3q-.3-.3-.3-.9" />
  </svg>
);
export default IconAppleBrandCopy;
