import * as React from "react";
const IconWifiAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.7 20.3c0-.6-.4-1-1-1-21.7 0-39.3 17.6-39.3 39.3 0 .6.4 1 1 1s1-.4 1-1c0-20.6 16.7-37.3 37.3-37.3.5 0 1-.5 1-1M58.7 36.4c0-.6-.4-1-1-1-12.8 0-23.2 10.4-23.2 23.2 0 .6.4 1 1 1s1-.4 1-1c0-11.7 9.5-21.2 21.2-21.2.5 0 1-.4 1-1" />
    <path d="M57.7 5c.6 0 1-.4 1-1s-.4-1-1-1C27.1 3 2.1 27.9 2.1 58.6c0 .6.4 1 1 1s1-.4 1-1C4.1 29 28.2 5 57.7 5M54.9 47.1c-3.8 0-7 3.1-7 7s3.1 7 7 7 7-3.1 7-7-3.1-7-7-7m0 11.9c-2.7 0-5-2.2-5-5s2.2-5 5-5 5 2.2 5 5-2.2 5-5 5" />
  </svg>
);
export default IconWifiAlt1;
