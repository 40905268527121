import * as React from "react";
const IconOm = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 513 342"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 0h513v342H0z" />
    <path  d="M138 0h377v114H138z" />
    <path  d="M138 230h377v114H138z" />
    <g   strokeMiterlimit={2} strokeWidth={10}>
      <path d="M40.3 35.2s37.1 48 50.8 54.5 17.1 5.7 17.1 5.7" />
      <path d="M100 35.2S62.9 80.6 53.3 87.6s-18.7 7.8-18.7 7.8m17.1-30.1h37.9M70.7 35.2v30.1" />
    </g>
  </svg>
);
export default IconOm;
