import React from "react";
import BringzzImage from "../BringzzImage";
import BringzzText from "../BringzzText";
import BringzzIcon from "../BringzzIcon";

const BringzzBasketProduct = ({ product, showSelect, mode, ...props }) => {
  return (
    <div className="py-4 flex item-center justify-center gap-6 border-b ">
      <div className="flex items-center">
        <BringzzImage src={product.imgUrl} className="w-[75px]"></BringzzImage>
      </div>
      <div className="flex flex-grow justify-between mt-1">
        <div className="flex flex-col">
          <div className="text-xs">
            <BringzzText tag="h5" className="font-regular">
              {product.manufacturer || "Manufacturer"}
            </BringzzText>{" "}
          </div>
          <div className="">
            <BringzzText tag="h4" className="font-regular">
              {product.name || "Product Name"}
            </BringzzText>{" "}
          </div>
          <div className="">
            <BringzzText tag="h5" className="font-regular">
              {product.amount || "84 Stk | Kapseln"}
            </BringzzText>
          </div>
          {!mode == "view" && <div className="flex items-center justify-start gap-2 ">
            <BringzzText tag="h5" className="font-regular">
              Menge:
            </BringzzText>
            <button
              className="px-2 py-1 rounded-md text-xs border border-midnight-blue  flex items-center gap-x-2"
              onClick={(e) => showSelect(e)}
            >
              <span>{product.quantity}</span>{" "}
              <BringzzIcon
                icon="IconChevronDown"
                folder="LineIcons"
                size="12"
                className="p-0"
              ></BringzzIcon>
            </button>
          </div>}
          {mode == "select" && <BringzzText tag="h3">
            € {(product.quantity * product.price).toFixed(2)}
          </BringzzText>}
          {mode == "view" &&
            <div>
              <BringzzText tag="h5" className={"font-normal"}>{product.inhalt}</BringzzText>
            </div>}
        </div>
        {mode !== "select" && <div>
          <BringzzText tag="h3">
            € {(product.quantity * product.price).toFixed(2)}
          </BringzzText>
        </div>}
      </div>
    </div>
  );
};

export default BringzzBasketProduct;
