import * as React from "react";
const IconMessagePlus = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 44h-6.3v-6.3c0-.6-.4-1-1-1s-1 .4-1 1V44h-6.3c-.6 0-1 .4-1 1s.4 1 1 1h6.3v6.3c0 .6.4 1 1 1s1-.4 1-1V46H61c.6 0 1-.4 1-1s-.4-1-1-1" />
    <path d="M34.4 50H7c-1.7 0-3-1.4-3-3V15.7c0-.4.1-.7.2-1.1L29 29.7c.9.5 1.8.8 2.8.8s1.9-.3 2.8-.8l24.8-15.1c.1.3.2.7.2 1.1V30c0 .6.4 1 1 1s1-.4 1-1V15.7c0-2.8-2.3-5-5-5H7.1C4.3 10.7 2 13 2 15.8V47c0 2.8 2.3 5 5 5h27.4c.6 0 1-.4 1-1s-.4-1-1-1m22.2-37.3c.6 0 1.1.2 1.5.4L33.5 28c-1.1.6-2.4.6-3.5 0L5.5 13.1c.5-.3 1-.4 1.5-.4z" />
  </svg>
);
export default IconMessagePlus;
