import * as React from "react";
const IconChatMinus = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.8 10.7H7.2C4.3 10.7 2 13.1 2 16v32c0 1.9 1 3.6 2.6 4.5.8.5 1.7.7 2.6.7s1.8-.2 2.6-.7l8.8-5.1c.5-.3 1-.4 1.6-.4h36.5c2.9 0 5.2-2.3 5.2-5.2V16c.1-2.9-2.2-5.3-5.1-5.3M60 41.8c0 1.8-1.4 3.2-3.2 3.2H20.3c-.9 0-1.8.2-2.6.7l-8.8 5.1c-1 .6-2.2.6-3.2 0C4.6 50.2 4 49.2 4 48V16c0-1.8 1.4-3.2 3.2-3.2h49.6c1.8 0 3.2 1.4 3.2 3.2z" />
    <path d="M39.1 28.4H24.9c-.6 0-1 .4-1 1s.4 1 1 1h14.2c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconChatMinus;
