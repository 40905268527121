import * as React from "react";
const IconMilkBottole = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m49.3 27.2-5.9-10.4v-5.3h2.3c.6 0 1-.4 1-1s-.4-1-1-1h-2.3V7.2C43.3 4.3 41 2 38.2 2H25.8c-2.8 0-5.2 2.3-5.2 5.2v2.3h-2.3c-.6 0-1 .4-1 1s.4 1 1 1h2.3v5.3l-5.9 10.4c-.1.2-.1.3-.1.5v29.2c0 2.8 2.3 5.2 5.2 5.2h24.5c2.8 0 5.2-2.3 5.2-5.2V27.7c-.1-.2-.1-.4-.2-.5m-26.6-20c0-1.7 1.4-3.2 3.2-3.2h12.3c1.7 0 3.2 1.4 3.2 3.2v2.3H22.7zm-.2 10.4c.1-.2.1-.3.1-.5v-5.6h18.7v5.6c0 .2 0 .3.1.5l4.4 7.7-4.6 1.2c-2.8.7-5.8.5-8.4-.7-3.6-1.6-7.8-1.7-11.5-.2l-4.5 1.8zm24.9 39.2c0 1.7-1.4 3.2-3.2 3.2H19.8c-1.7 0-3.2-1.4-3.2-3.2V29.7h.2l5.4-2.2c3.2-1.3 6.8-1.2 9.9.2s6.5 1.6 9.8.8l5.1-1.4.5.9v28.8z" />
  </svg>
);
export default IconMilkBottole;
