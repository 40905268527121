import * as React from "react";
const IconCiCdAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.1 20.1H39.3l5.7-5.3c.4-.4.4-1 .1-1.4-.4-.4-1-.4-1.4-.1l-7.3 6.8c-.2.2-.3.4-.3.7s.1.5.3.7l7.3 7.3c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-5.3-5.3h10.4c5.5 0 9.9 4.4 9.9 9.9s-4.4 9.9-9.9 9.9c-6.5 0-12.5-3.2-16.1-8.6l-2.4-3.6c-3.9-6-10.6-9.5-17.7-9.5C7.3 20.1 2 25.4 2 32s5.3 11.9 11.9 11.9h11l-5.7 5.3c-.4.4-.4 1-.1 1.4.2.2.5.3.7.3s.5-.1.7-.3l7.3-6.8c.2-.2.3-.4.3-.7s-.1-.5-.3-.7l-7.3-7.3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l5.3 5.3H13.9C8.4 41.9 4 37.5 4 32s4.4-9.9 9.9-9.9c6.5 0 12.5 3.2 16.1 8.6l2.4 3.6c3.9 6 10.6 9.5 17.7 9.5C56.7 43.9 62 38.6 62 32s-5.3-11.9-11.9-11.9" />
  </svg>
);
export default IconCiCdAlt;
