import * as React from "react";
const IconPlayCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m56.8 29.5-46.4-27c-1-.6-2.2-.6-3.2-.1-.9.5-1.5 1.5-1.5 2.6v53.9c0 1.1.6 2.1 1.6 2.6.5.3 1 .4 1.6.4s1.2-.2 1.6-.5l46.4-27c.9-.6 1.4-1.5 1.4-2.5 0-.9-.5-1.9-1.5-2.4m-1 3.3-46.4 27q-.6.3-1.2 0c-.2-.1-.5-.4-.5-.9V5c0-.5.4-.8.5-.9.2-.1.4-.1.6-.1s.4.1.6.2l46.4 27c.3.2.5.5.5.8s-.2.6-.5.8" />
  </svg>
);
export default IconPlayCopy;
