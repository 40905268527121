import * as React from "react";
const IconSpeechBubble41 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.7 35.2C57 30.6 55.8 23.8 58.5 18q.15-.45 0-.9c-.15-.45-.4-.5-.8-.5-6.9-.7-12.7-5.3-14.6-11.4q-.15-.45-.6-.6c-.3-.1-.6-.1-.9.1-5.8 3.7-13.5 3.7-19.2 0-.3-.2-.6-.2-.9-.1q-.45.15-.6.6C19 11.3 13.2 15.9 6.3 16.6c-.3 0-.6.2-.8.5q-.3.45 0 .9C8.2 23.8 7 30.6 2.3 35.2c-.2.2-.3.5-.3.8.1.3.3.6.6.7 6.1 2.8 9.8 8.8 9.4 15.2 0 .3.1.6.4.8.2.2.6.3.9.2 6.7-1.6 14 .9 18 6.1.2.2.5.4.8.4s.6-.1.8-.4c4-5.3 11.3-7.7 18-6.1.3.1.6 0 .9-.2.2-.2.4-.5.4-.8-.4-6.4 3.3-12.4 9.4-15.2.3-.1.5-.4.6-.7-.2-.3-.3-.6-.5-.8M50 50.8c-6.7-1.1-13.7 1.2-18 6.1-3.6-4.1-9.1-6.4-14.7-6.4q-1.65 0-3.3.3c-.1-6.3-3.6-12.1-9.3-15.2 4.2-4.8 5.4-11.3 3.2-17.2 6.6-1.1 12.2-5.5 14.5-11.4 5.9 3.2 13.3 3.2 19.2 0 2.3 5.9 7.9 10.3 14.5 11.4-2.2 5.9-1 12.4 3.2 17.2-5.7 3.1-9.2 8.9-9.3 15.2" />
    <path d="M23.7 22.9h13.6c.6 0 1-.4 1-1s-.4-1-1-1H23.7c-.6 0-1 .4-1 1s.4 1 1 1M42.7 30.3h-19c-.6 0-1 .4-1 1s.4 1 1 1h19c.6 0 1-.4 1-1s-.5-1-1-1M41.3 39.7H23.7c-.6 0-1 .4-1 1s.4 1 1 1h17.6c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconSpeechBubble41;
