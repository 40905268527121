import * as React from "react";
const IconMedicineBall = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.6 20.3c-1.7-4-4.4-7.7-7.6-10.6l-.4-.4C46.1 4.6 39.2 2 32 2c-7.4 0-14.5 2.7-20 7.7-3.3 2.9-5.9 6.6-7.6 10.6Q2 25.85 2 32c0 1.5.1 3.1.3 4.6 1.1 7.2 4.8 13.5 10 18 .1.2.3.3.5.4 5.3 4.4 12 7 19.2 7 7.3 0 14-2.7 19.3-7.1.2-.1.4-.2.5-.4 5.1-4.5 8.8-10.8 9.9-17.9.2-1.5.3-3.1.3-4.6q0-6.15-2.4-11.7M49.2 9.9C45 12.2 38.4 14.5 32 14.5c-5.6 0-12.2-1.8-17.3-4.5 4.9-3.9 11-6 17.3-6 6.2 0 12.3 2.1 17.2 5.9M15.1 54.2c4.5-2.4 10.9-4.7 16.9-4.7 6.3 0 12.9 2.3 17.1 4.6C44.3 57.8 38.4 60 32 60c-6.3 0-12.2-2.1-16.9-5.8m44.6-17.9c-1 6.5-4.3 12.2-8.9 16.4-4.5-2.5-11.8-5.2-18.8-5.2-6.7 0-13.9 2.7-18.6 5.4-4.7-4.2-8.1-10-9.1-16.6-.2-1.4-.3-2.9-.3-4.3 0-3.8.7-7.5 2.2-11 1.6-3.6 3.9-7 6.9-9.7 5.5 3.2 12.7 5.1 18.9 5.1 7.1 0 14.4-2.7 18.8-5.2 3 2.7 5.4 6.1 7 9.8 1.5 3.5 2.2 7.2 2.2 11 0 1.4-.1 2.9-.3 4.3" />
  </svg>
);
export default IconMedicineBall;
