import * as React from "react";
const IconIsland = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M52.4 22.8c5.5 0 9.9-4.4 9.9-9.9S57.8 3 52.4 3s-9.9 4.4-9.9 9.9 4.4 9.9 9.9 9.9m0-15.3c3 0 5.4 2.4 5.4 5.4s-2.4 5.4-5.4 5.4-5.4-2.4-5.4-5.4 2.4-5.4 5.4-5.4M60 56.5c-.7 0-1-.2-1.8-1.2-1-1.1-2.4-2.7-5.2-2.7-2.7 0-4.2 1.6-5.2 2.7-.9 1-1.1 1.2-1.8 1.2s-1-.2-1.8-1.2c-1-1.1-2.4-2.7-5.2-2.7-2.7 0-4.2 1.6-5.2 2.7-.9 1-1.1 1.2-1.8 1.2s-1-.2-1.8-1.2c-1-1.1-2.4-2.7-5.2-2.7s-4.2 1.6-5.2 2.7c-.4.5-.7.8-1 1-.9-2.1-3.6-9.2-2.5-14.8.2-1.3.8-2.8 1.4-4.4.6-1.7 1.3-3.5 1.7-5.4 0-.1.1-.2.1-.4 1.8 1 4.2 3.3 5 6 .3 1 1.2 1.7 2.2 1.7.2 0 .4 0 .6-.1 1.2-.3 1.9-1.6 1.6-2.8-1-3.6-4-7.1-7.2-8.8 2.7-2.8 7.5-4.2 12.3-3.3 1.2.2 2.4-.6 2.6-1.8s-.6-2.4-1.8-2.6c-6.1-1.1-12 .6-15.8 4-.6-2.4-1.7-4.5-2.8-6.2-1.2-1.7-3-3.8-5.5-4.2-1.2-.2-2.4.6-2.6 1.8s.6 2.4 1.8 2.6c.5.1 1.5.9 2.5 2.3.8 1.2 1.6 2.9 2.1 4.6-3.7-1.7-8.2-1.7-11.8.6-1.1.7-1.4 2.1-.7 3.1s2.1 1.4 3.1.7c3-1.9 7.3-1.3 9.8 1.3v.1c-.3 1.6-.9 3.3-1.6 5-.6 1.7-1.3 3.5-1.6 5.2-.8 4.2.1 8.7 1.1 12.3-.6-.2-1.2-.3-1.9-.3-2.7 0-4.2 1.6-5.2 2.7-.9 1-1.2 1.2-1.8 1.2-1.2 0-2.3 1-2.3 2.3S2.8 61 4 61c2.7 0 4.2-1.6 5.2-2.7.9-1 1.2-1.2 1.8-1.2.7 0 1 .2 1.8 1.2.8.9 2 2.2 4 2.5.2.1.5.1.7.1h.5c2.7 0 4.2-1.6 5.2-2.7.9-1 1.2-1.2 1.8-1.2s1 .2 1.8 1.2c1 1.1 2.4 2.7 5.2 2.7 2.7 0 4.2-1.6 5.2-2.7.9-1 1.2-1.2 1.8-1.2.7 0 1 .2 1.8 1.2 1 1.1 2.4 2.7 5.2 2.7 2.7 0 4.2-1.6 5.2-2.7.9-1 1.2-1.2 1.8-1.2s1 .2 1.8 1.2c1 1.1 2.4 2.7 5.2 2.7 1.2 0 2.3-1 2.3-2.3s-1.1-2.1-2.3-2.1" />
  </svg>
);
export default IconIsland;
