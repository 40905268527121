import * as React from "react";
const IconYenNote = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 13.8H6c-2.2 0-4 1.8-4 4v28.4c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V17.8c0-2.2-1.8-4-4-4m2 32.4c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V17.8c0-1.1.9-2 2-2h52c1.1 0 2 .9 2 2z" />
    <path d="M15.1 21.4h-3.5c-.6 0-1 .4-1 1s.4 1 1 1h3.5c.6 0 1-.4 1-1s-.5-1-1-1M52.5 40.7H49c-.6 0-1 .4-1 1s.4 1 1 1h3.5c.6 0 1-.4 1-1s-.5-1-1-1M39.6 37h-6.5v-4.5h6.1c.6 0 1-.4 1-1s-.4-1-1-1h-6.1v-2.7l4.9-4.9c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-4.4 4.4-4.4-4.4c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l4.9 4.9v2.7h-5.9c-.6 0-1 .4-1 1s.4 1 1 1h5.9V37H25c-.6 0-1 .4-1 1s.4 1 1 1h6.3v3.5c0 .6.4 1 1 1s1-.4 1-1V39h6.5c.6 0 1-.4 1-1s-.7-1-1.2-1" />
  </svg>
);
export default IconYenNote;
