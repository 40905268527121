import React from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";
import BringzzButton from "../BringzzButton";
import BringzzInput from "../BringzzInput";

const BringzzForm = ({ children, onSubmit, className,buttonClass }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const formValues = Object.fromEntries(formData.entries());
    onSubmit(formValues);
  };
   // Check if all children are BringzzInput components
   const checkChildren = () => {
    Children.forEach(children, (child) => {
      if (child.type !== BringzzInput || child.type !== BringzzButton) {
        throw new Error("All children of BringzzForm must be BringzzInput components.");
      }
    });
  };

  checkChildren();

  return (
    <form className={twMerge("space-y-4", className)} onSubmit={handleSubmit}>
      {children}
    </form>
  );
};

BringzzForm.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
  className: PropTypes.string,
};

BringzzForm.defaultProps = {
  className: "",
};

export default BringzzForm;