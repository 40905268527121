import * as React from "react";
const IconDisplaySlash = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.1 10.6H16.7l-7.9-8c-.4-.4-1-.4-1.4 0-.4.3-.4 1 0 1.3l8.4 8.7L55.1 53c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-8.2-8.5h7.8c2.7 0 4.9-2.2 4.9-4.9V15.5c0-2.7-2.2-4.9-4.9-4.9M59 38.3c0 1.6-1.3 2.9-2.9 2.9h-9.7L18.6 12.6h37.5c1.6 0 2.9 1.3 2.9 2.9zM42.2 51.4H33v-8.2h4.3c.6 0 1-.4 1-1s-.4-1-1-1H7.9c-1.6 0-2.9-1.3-2.9-2.9V15.5c0-1.6 1.3-2.9 2.9-2.9.6 0 1-.4 1-1s-.4-1-1-1c-2.7 0-4.9 2.2-4.9 4.9v22.8c0 2.7 2.2 4.9 4.9 4.9H31v8.2h-9.2c-.6 0-1 .4-1 1s.4 1 1 1h20.4c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconDisplaySlash;
