import * as React from "react";
const IconColumns2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.2 2H8.8C5.1 2 2 5.1 2 8.8v46.4C2 58.9 5.1 62 8.8 62h46.4c3.7 0 6.8-3.1 6.8-6.8V8.8C62 5.1 58.9 2 55.2 2m-14 2v56H22.9V4zM4 55.2V8.8C4 6.2 6.2 4 8.8 4h12.1v56H8.8C6.2 60 4 57.8 4 55.2m56 0c0 2.6-2.2 4.8-4.8 4.8h-12V4h12C57.8 4 60 6.2 60 8.8z" />
  </svg>
);
export default IconColumns2;
