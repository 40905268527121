import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { useSocket } from './SocketContext';
import { collectSegments, packageSegments, pickupSegments } from 'constants/orderSegments';
import OrderRequest from 'components/OrderRequest';
import ProgressOverlay from 'components/ProgressOverlay'; // Import your ProgressOverlay component

// Create the OrderContext
const OrderContext = createContext();
const LOCAL_STORAGE_KEY = 'orderContext';

// Create a provider component
export const OrderProvider = ({ children }) => {
    // State variables
    const [currentStep, setCurrentStep] = useState(0);
    const [orderDetails, setOrderDetails] = useState(null);
    const [orderType, setOrderType] = useState('pickup'); // pickup, collect, package
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const [overlayContent, setOverlayContent] = useState(null);
    const [activeStep, setActiveStep] = useState(0);

    const { sendMessage, onRefetchData } = useSocket();

    // Load state from localStorage on mount
    useEffect(() => {
        console.log("MOUNTED: useEffect executed");
        const savedState = localStorage.getItem(LOCAL_STORAGE_KEY);
        console.log("Saved State:", savedState);
        if (savedState) {
            const parsedState = JSON.parse(savedState);
            setCurrentStep(parsedState.currentStep || 0);
            setOrderDetails(parsedState.orderDetails || null);
            setOrderType(parsedState.orderType || 'pickup');
            setActiveStep(parsedState.activeStep || 0);
        }
    }, []);

    // Save state to localStorage whenever it changes
    useEffect(() => {
        const stateToSave = {
            currentStep,
            orderDetails,
            orderType,
            activeStep,
        };
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(stateToSave));
    }, [currentStep, orderDetails, orderType, activeStep]);

    // Callback functions
    const showOverlayContent = useCallback((content) => {
        setOverlayContent(content);
        setIsOverlayVisible(true);
    }, []);

    const hideOverlayContent = useCallback(() => {
        setOverlayContent(null);
        setIsOverlayVisible(false);
    }, []);

    const showOverlay = useCallback((initialStep = 0) => {
        setActiveStep(initialStep);
        setIsOverlayVisible(true);
    }, []);

    const hideOverlay = useCallback(() => {
        setIsOverlayVisible(false);
        setOverlayContent(null);
    }, []);

    const nextStep = useCallback(() => {
        setActiveStep((prevStep) => prevStep + 1);
    }, []);

    const resetSteps = useCallback(() => {
        setActiveStep(0);
    }, []);

    const startMatchingProcess = useCallback(
        (order, type = 'collect') => {
            setOrderDetails(order);
            setOrderType(type);
            setIsOverlayVisible(false);
        },
        []
    );

    const proceedToNextStep = useCallback(() => {
        nextStep();
        setCurrentStep((prevStep) => prevStep + 1);
    }, [nextStep]);

    const stopMatchingProcess = useCallback(() => {
        resetSteps();
        hideOverlay();
        setCurrentStep(0);
        setOrderDetails(null);
        setOrderType('collect');
    }, [resetSteps, hideOverlay]);

    const getCurrentSegment = useCallback(() => {
        switch (orderType) {
            case 'pickup':
                return pickupSegments[currentStep];
            case 'collect':
                return collectSegments[currentStep];
            case 'package':
                return packageSegments[currentStep];
            default:
                return null;
        }
    }, [currentStep, orderType]);

    const getOrderSegments = useCallback(() => {
        switch (orderType) {
            case 'pickup':
                return pickupSegments;
            case 'collect':
                return collectSegments;
            case 'package':
                return packageSegments;
            default:
                return null;
        }
    }, [currentStep, orderType]);

    const getCurrentPage = useCallback((currentStep = 0) => {
        if (orderType === 'pickup' || orderType === 'collect') {
            if (currentStep === 0) return '/matchmaking/pickup/0';
            if (currentStep === 1) return '/matchmaking/pickup/1';
        } else if (orderType === 'package') {
            if (currentStep === 0) return '/matchmaking/package/0';
        }
        return null; // Default fallback if no match
    }, [orderType]);

    const resetOrderContext = useCallback(() => {
        setCurrentStep(0);
        setOrderDetails(null);
        setOrderType('pickup');
        setIsOverlayVisible(false);
        setOverlayContent(null);
        setActiveStep(0);
        localStorage.removeItem(LOCAL_STORAGE_KEY);
    }, []);

    useEffect(() => {
        const cleanupRefetchData = onRefetchData((data) => {
            if (data) {
                console.log({ data });
                if (data.notificationType.includes('ORDER_PROGRESS_')) {
                    setActiveStep(Number(data.notificationType.split('_')[2]));
                }
                if (data.notificationType.includes('ORDER_REQUEST')) {
                    const { id, uuid, type, total, consumer, items } = data.notificationContent;
                    showOverlayContent(
                        <OrderRequest id={id} uuid={uuid} type={type} total={total} consumer={consumer} items={items} />
                    );
                }
                if (data.notificationType.includes('ORDER_CANCELED')) {
                    alert(data.notificationContent)
                    stopMatchingProcess()
                }
            }
        });

        return () => {
            if (cleanupRefetchData) {
                cleanupRefetchData();
            }
        };
    }, [onRefetchData, showOverlayContent]);

    // Function to get overlay content based on the current state
    const getOverlayContent = () => {
        if (overlayContent) {
            return overlayContent;
        }
        // Do not return ProgressOverlay here
        return null;
    };

    // Provide state and functions to all components in the tree
    return (
        <OrderContext.Provider
            value={{
                orderDetails,
                currentStep,
                orderType,
                isOverlayVisible,
                activeStep,
                resetOrderContext,
                showOverlay,
                hideOverlay,
                proceedToNextStep,
                resetSteps,
                startMatchingProcess,
                stopMatchingProcess,
                getCurrentSegment,
                getCurrentPage,
                getOrderSegments,
                showOverlayContent,
                hideOverlayContent,
            }}
        >
            {children}
            {isOverlayVisible && overlayContent && (
                <div className="fixed inset-0 bg-black/85 flex items-center justify-center z-50">
                    <div className="relative w-full h-full flex items-center justify-center">
                        {overlayContent}
                    </div>
                </div>
            )}
        </OrderContext.Provider>
    );
};

/**
 * @typedef {Object} OrderContextValue
 * @property {Object|null} orderDetails - Details of the current order.
 * @property {number} currentStep - The current step in the order process.
 * @property {string} orderType - The type of the order ('pickup', 'collect', or 'package').
 * @property {boolean} isOverlayVisible - Whether the overlay is visible.
 * @property {number} activeStep - The current active step in the overlay.
 * @property {Function} showOverlay - Function to show the overlay.
 * @property {Function} hideOverlay - Function to hide the overlay.
 * @property {Function} proceedToNextStep - Function to move to the next step.
 * @property {Function} resetSteps - Function to reset the steps.
 * @property {Function} startMatchingProcess - Function to start the matching process.
 * @property {Function} stopMatchingProcess - Function to stop the matching process.
 * @property {Function} getCurrentSegment - Function to get the current segment based on the order type.
 * @property {Function} getCurrentPage - Function to get the current page URL based on the order type and step.
 * @property {Function} getOrderSegments - Function to get all segments for the current order type.
 * @property {Function} showOverlayContent - Function to show custom overlay content.
 * @property {Function} hideOverlayContent - Function to hide custom overlay content.
 * @property {Function} resetOrderContext - Function to reset all states (for debugging).
*/

/**
 * Custom hook to use the OrderContext
 * @returns {OrderContextValue} The value provided by the OrderContext.
 */
export const useOrder = () => {
    const context = useContext(OrderContext);
    if (!context) {
        throw new Error('useOrder must be used within an OrderProvider');
    }
    return context;
};
