import * as React from "react";
const IconWebsiteMoney = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 8.1H6c-2.2 0-4 1.8-4 4V52c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V12.1c0-2.2-1.8-4-4-4m-52 2h52c1.1 0 2 .9 2 2v7.4H4v-7.4c0-1.1.9-2 2-2m52 43.8H6c-1.1 0-2-.9-2-2V21.5h56V52c0 1-.9 1.9-2 1.9" />
    <path d="M30.6 33.1h5.6c.6 0 1-.4 1-1s-.4-1-1-1h-2.3v-1.5c0-.6-.4-1-1-1s-1 .4-1 1v1.5h-1.2c-2.1 0-3.8 1.7-3.8 3.8v.3c0 2.1 1.7 3.8 3.8 3.8h2.8c1 0 1.8.8 1.8 1.8v.3c0 1-.8 1.8-1.8 1.8h-5.6c-.6 0-1 .4-1 1s.4 1 1 1h4v1.5c0 .6.4 1 1 1s1-.4 1-1v-1.5c1.9-.2 3.3-1.8 3.3-3.7v-.3c0-2.1-1.7-3.8-3.8-3.8h-2.8c-1 0-1.8-.8-1.8-1.8V35c0-1.1.8-1.9 1.8-1.9" />
  </svg>
);
export default IconWebsiteMoney;
