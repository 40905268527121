export const isDateAllowed = (year, month, day, maxDate, minDate) => {
  const date = new Date(year, month, day, 12, 0, 0);
  if (
    (maxDate &&( date > maxDate)) ||
   ( date < minDate)
  ) {
    return false;
  } else {
    return true;
  }
};

export const daysInMonth = (year, month) =>
  new Date(year, month + 1, 0).getDate();
export const startDayOfMonth = (year, month) =>
  new Date(year, month, 1).getDay();
export const daysInPreviousMonth = (year, month) =>
  new Date(year, month, 0).getDate();
export const getDaysInMonth = (month, year) => {
  return new Date(year, month + 1, 0).getDate();
};
export const monthsListLong = Array.from({ length: 12 }, (_, i) =>
  new Date(0, i).toLocaleString("default", { month: "long" })
);
export const monthsListShort = Array.from({ length: 12 }, (_, i) =>
  new Date(0, i).toLocaleString("default", { month: "short" })
);
