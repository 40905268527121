import * as React from "react";
const IconIdeaHead1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.2 21.1c-1.3-9.7-9.3-17.7-19-18.9C38 2 36.9 2 35.8 2h-.1c-6.1 0-11.8 2.4-16.1 6.7s-6.7 10-6.7 16.1c0 1.3-.4 2.6-1.1 3.7l-5.7 9c-.3.6-.5 1.2-.5 1.8 0 1.9 1.5 3.4 3.4 3.4h3.8V49c0 3.2 2.6 5.8 5.8 5.8.7 0 1.3-.1 1.7-.3l4.5-1.4v6.7c0 1.2 1 2.2 2.2 2.2h22.3c.6 0 1-.4 1-1V49.7c0-4.9 1.3-9.3 3.8-12.9 3.4-4.5 4.8-10.1 4.1-15.7m-5.5 14.6c-2.8 3.9-4.2 8.7-4.2 14V60H27.1c-.1 0-.2-.1-.2-.2v-8c0-.3-.1-.6-.4-.8-.2-.1-.4-.2-.6-.2h-.3l-5.9 1.8c-.3.1-.7.2-1.1.2-2.1 0-3.8-1.7-3.8-3.8v-7.3c0-.6-.4-1-1-1H9.1c-.8 0-1.4-.6-1.4-1.4 0-.3.1-.5.2-.8l5.7-9c.9-1.4 1.4-3.1 1.4-4.8 0-5.5 2.2-10.8 6.1-14.7s9-6 14.5-6.1h.2c1.1 0 2.2 0 3.2.2 8.9 1.1 16.1 8.3 17.3 17.2.5 5.2-.7 10.2-3.6 14.4" />
    <path d="M35.2 15.1c-3.2-.1-6.3 1.1-8.6 3.4S23 23.8 23 27c0 4.1 2.1 7.9 5.6 10.1.7.4 1.1 1.3 1.1 2.2v2.1c0 2.5 2.1 4.6 4.6 4.6h1.1c2.5 0 4.6-2.1 4.6-4.6v-2.1c0-.4.1-.9.3-1.2.2-.4.5-.7.8-.9 3.4-2.1 5.5-5.8 5.6-9.7.1-3.1-1.1-6.1-3.3-8.5-2.1-2.5-5.1-3.8-8.2-3.9M38 41.4c0 1.4-1.2 2.6-2.6 2.6h-1.1c-1.4 0-2.6-1.2-2.6-2.6v-2.1c0-.2 0-.5-.1-.7H38c0 .2-.1.4-.1.7v2.1zm2.1-6q-.75.45-1.2 1.2h-8q-.45-.75-1.2-1.2c-2.9-1.8-4.6-5-4.6-8.4 0-2.7 1.1-5.2 3-7.1 1.9-1.8 4.3-2.8 6.9-2.8h.3c2.6.1 5 1.2 6.9 3.1 1.8 1.9 2.8 4.4 2.8 7-.3 3.4-2.1 6.4-4.9 8.2" />
  </svg>
);
export default IconIdeaHead1;
