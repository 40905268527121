import * as React from "react";
const IconHospitalLocationAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.5 37.2c-.6 0-1 .4-1 1v4.7h-6.9v-4.7c0-.6-.4-1-1-1s-1 .4-1 1v11.4c0 .6.4 1 1 1s1-.4 1-1v-4.7h6.9v4.7c0 .6.4 1 1 1s1-.4 1-1V38.2c0-.6-.4-1-1-1M23.6 17.2c-5.2 0-9.5 4.2-9.5 9.5s4.2 9.5 9.5 9.5 9.5-4.2 9.5-9.5-4.2-9.5-9.5-9.5m0 17c-4.1 0-7.5-3.3-7.5-7.5 0-4.1 3.3-7.5 7.5-7.5 4.1 0 7.5 3.3 7.5 7.5 0 4.1-3.3 7.5-7.5 7.5" />
    <path d="M47.1 29c-.7 0-1.4.1-2 .2.1-.8.2-1.6.2-2.3 0-11.9-9.7-21.6-21.6-21.6C11.7 5.2 2 14.9 2 26.8 2 38.6 22.1 56.2 23 57c.2.2.4.2.7.2s.5-.1.7-.2c0 0 4.1-3.6 8.6-8.5 2 6 7.6 10.3 14.2 10.3 8.2 0 14.9-6.7 14.9-14.9S55.3 29 47.1 29M23.6 54.9C20.2 51.7 4 36.6 4 26.8 4 16 12.8 7.2 23.6 7.2S43.2 16 43.2 26.8c0 .8-.1 1.7-.4 2.7-6.2 1.8-10.7 7.5-10.7 14.3 0 .8.1 1.5.2 2.3-3.6 4.2-7.2 7.5-8.7 8.8m23.5 1.9c-7.1 0-12.9-5.8-12.9-12.9S40 31 47.1 31 60 36.8 60 43.9s-5.8 12.9-12.9 12.9" />
  </svg>
);
export default IconHospitalLocationAlt;
