import * as React from "react";
const IconCheckDatabase = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M44.8 26.6h-.3V11.2C44.5 5.9 33.6 3 23.3 3S2 5.9 2 11.2v25.4c0 5.2 10.8 8.3 21.2 8.3 1.5 0 3-.1 4.4-.2.5 9.1 8 16.3 17.2 16.3C54.3 61 62 53.3 62 43.8s-7.7-17.2-17.2-17.2M4 14.9c3.6 3 11.6 4.6 19.3 4.6 7.6 0 15.6-1.6 19.2-4.6v5.5c-.1 2.9-7.9 6.2-19.2 6.2C11.9 26.6 4 23.3 4 20.3zM23.3 5c11.3 0 19.2 3.3 19.3 6.2-.1 2.9-7.9 6.2-19.2 6.2C11.9 17.5 4 14.2 4 11.2S11.9 5 23.3 5M4 24c3.6 3 11.6 4.6 19.3 4.6 7.6 0 15.6-1.6 19.2-4.6v2.8c-5 .7-9.3 3.5-12 7.5-2.3.3-4.8.5-7.3.5C11.9 34.7 4 31.4 4 28.5zm0 12.6v-4.5c3.6 3 11.6 4.6 19.3 4.6 2 0 4.1-.1 6-.3-.9 1.9-1.5 4.1-1.6 6.3-1.4.1-2.9.2-4.4.2C12.2 42.9 4 39.6 4 36.6M44.8 59c-8.4 0-15.2-6.8-15.2-15.2s6.8-15.2 15.2-15.2S60 35.4 60 43.8 53.2 59 44.8 59" />
    <path d="m50.6 39.2-7.5 7.5-4.1-4.1c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l4.9 4.9c.2.2.4.3.7.3s.5-.1.7-.3l8.2-8.2c.4-.4.4-1 0-1.4s-1.1-.5-1.5-.1" />
  </svg>
);
export default IconCheckDatabase;
