import * as React from "react";
const IconFemaleCondom = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M23 38H9a1 1 0 1 0 0 2h14a1 1 0 1 0 0-2M9 36a3 3 0 1 0 0 6h14a3 3 0 1 0 0-6z"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M11.455 30.998V36h9.09v-5.002c0-.647.247-1.267.378-1.586a23 23 0 0 1 .44-.977l.097-.207c.18-.388.326-.717.428-1.004.086-.242.106-.362.11-.39v-.004a2.3 2.3 0 0 0-.11-.39 12 12 0 0 0-.428-1.003l-.098-.208c-.144-.309-.31-.662-.44-.976-.13-.32-.377-.94-.377-1.587v-.417c0-.685.27-1.295.415-1.605.137-.295.313-.621.458-.889l.093-.17c.178-.333.312-.597.402-.824.061-.156.08-.24.085-.267a2.3 2.3 0 0 0-.11-.386 12 12 0 0 0-.428-1.004l-.098-.207c-.145-.31-.31-.662-.44-.977-.13-.319-.377-.939-.377-1.586v-1.25c0-1.872-.634-3.104-1.432-3.872C18.292 8.421 17.165 8 16 8s-2.29.42-3.113 1.212c-.799.768-1.433 2-1.433 3.872v1.25c0 .647-.246 1.267-.377 1.586-.129.315-.294.668-.44.977l-.097.207c-.18.387-.326.717-.428 1.004a2.3 2.3 0 0 0-.11.386c.005.027.024.11.085.267.09.227.223.49.402.823l.093.171c.145.268.321.594.458.889.145.31.415.92.415 1.605v.417c0 .648-.247 1.268-.378 1.587-.129.314-.294.667-.44.976l-.097.208c-.18.387-.326.716-.428 1.003a2.3 2.3 0 0 0-.11.392v.002c.004.028.024.148.11.39.102.287.248.616.428 1.003l.098.208c.145.31.31.662.44.977.13.319.377.939.377 1.586m11.09 0c0-.374.294-1.001.618-1.692.396-.845.837-1.786.837-2.474s-.44-1.629-.837-2.474c-.324-.69-.617-1.317-.617-1.692v-.417c0-.374.293-.917.617-1.515.396-.732.837-1.546.837-2.234s-.44-1.629-.837-2.474c-.324-.69-.617-1.318-.617-1.692v-1.25c0-9.445-13.091-9.445-13.091 0v1.25c0 .374-.294 1.001-.618 1.692C8.441 16.87 8 17.812 8 18.5s.44 1.502.837 2.234c.324.598.618 1.14.618 1.515v.417c0 .375-.294 1.002-.618 1.692-.396.845-.837 1.786-.837 2.474s.44 1.629.837 2.474c.324.69.618 1.318.618 1.692V38h13.09z"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M17 12a2 2 0 0 0-2 2 1 1 0 1 1-2 0 4 4 0 0 1 4-4 1 1 0 1 1 0 2M34 24a6 6 0 1 0 0-12 6 6 0 0 0 0 12m0 2a8 8 0 1 0 0-16 8 8 0 0 0 0 16"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M33 36V25h2v11a1 1 0 1 1-2 0"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M29 30a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2h-8a1 1 0 0 1-1-1"
      clipRule="evenodd"
    />
    <path
      
      d="M17 12a2 2 0 0 0-2 2 1 1 0 1 1-2 0 4 4 0 0 1 4-4 1 1 0 1 1 0 2"
    />
    <path
      
      fillRule="evenodd"
      d="M9.455 36v-5.002c0-.374-.294-1.001-.618-1.692C8.441 28.461 8 27.52 8 26.832s.44-1.629.837-2.474c.324-.69.618-1.317.618-1.692v-.417c0-.374-.294-.917-.618-1.515C8.441 20.002 8 19.188 8 18.5s.44-1.629.837-2.474c.324-.69.618-1.318.618-1.692v-1.25c0-9.445 13.09-9.445 13.09 0v1.25c0 .374.294 1.001.618 1.692.396.845.837 1.786.837 2.474s-.44 1.502-.837 2.234c-.324.598-.617 1.14-.617 1.515v.417c0 .375.293 1.002.617 1.692.396.845.837 1.786.837 2.474s-.44 1.629-.837 2.474c-.324.69-.617 1.318-.617 1.692V36H23a3 3 0 1 1 0 6H9a3 3 0 1 1 0-6zm2 0v-5.002c0-.647-.247-1.267-.378-1.586a23 23 0 0 0-.44-.977l-.002-.005-.095-.203c-.18-.387-.326-.716-.428-1.003a2.3 2.3 0 0 1-.11-.39v-.004c.004-.027.024-.147.11-.39.102-.287.248-.616.428-1.003l.098-.208c.145-.309.31-.662.44-.976.13-.32.377-.94.377-1.587v-.417c0-.685-.27-1.295-.415-1.605-.137-.295-.313-.621-.458-.889l-.093-.17a8 8 0 0 1-.402-.824 1.6 1.6 0 0 1-.085-.267c.005-.033.027-.153.11-.386.102-.287.248-.617.428-1.004l.096-.205.002-.002c.145-.31.31-.662.44-.977.13-.319.377-.939.377-1.586v-1.25c0-1.872.633-3.104 1.431-3.872C13.71 8.421 14.836 8 16 8s2.29.42 3.113 1.212c.799.768 1.433 2 1.433 3.872v1.25c0 .647.246 1.267.377 1.586.129.315.294.668.44.977v.001l.097.206c.18.387.326.717.428 1.004.083.233.104.353.11.386-.005.027-.024.11-.085.267-.09.227-.224.49-.402.823l-.093.171c-.145.268-.321.594-.458.889-.145.31-.415.92-.415 1.605v.417c0 .648.247 1.268.378 1.587.129.314.295.667.44.976l.097.208c.18.387.326.716.428 1.003a2.3 2.3 0 0 1 .11.392v.002a2.3 2.3 0 0 1-.11.39c-.102.287-.248.616-.428 1.003l-.098.208a23 23 0 0 0-.44.977c-.13.319-.377.939-.377 1.586V36zM9 38a1 1 0 1 0 0 2h14a1 1 0 1 0 0-2zM42 18a8 8 0 0 1-7 7.938V29h3a1 1 0 1 1 0 2h-3v5a1 1 0 1 1-2 0v-5h-3a1 1 0 1 1 0-2h3v-3.062A8.001 8.001 0 0 1 34 10a8 8 0 0 1 8 8m-2 0a6 6 0 1 1-12 0 6 6 0 0 1 12 0"
      clipRule="evenodd"
    />
  </svg>
);
export default IconFemaleCondom;
