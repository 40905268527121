import * as React from "react";
const IconSpeechBubble21 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 8.8c-16.8 0-30 8.3-30 18.9C2 38 14 44.4 25.3 46l4.7 8c.4.7 1.2 1.2 2.1 1.2q1.35 0 2.1-1.2l4.7-8c6.1-.9 11.7-2.9 15.8-5.8 4.8-3.4 7.5-7.9 7.5-12.6C62 17.1 48.8 8.8 32 8.8m21.3 29.8c-3.9 2.8-9.4 4.8-15.3 5.5-.3 0-.6.2-.7.5l-5 8.4c-.1.2-.2.2-.3.2s-.2 0-.3-.2l-4.9-8.4c-.2-.3-.4-.4-.7-.5C15.4 42.7 4 37 4 27.6c0-9.3 12.6-16.9 28-16.9s28 7.6 28 16.9c0 4.1-2.4 7.9-6.7 11" />
    <path d="M22 24.2h18.3c.6 0 1-.4 1-1s-.4-1-1-1H22c-.6 0-1 .4-1 1s.5 1 1 1M44.7 30.7H22c-.6 0-1 .4-1 1s.4 1 1 1h22.7c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconSpeechBubble21;
