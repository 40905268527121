import * as React from "react";
const IconRulerCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M41.6 2H22.4c-2.8 0-5 2.2-5 5v50c0 2.8 2.2 5 5 5h19.1c2.8 0 5-2.2 5-5V7c.1-2.8-2.2-5-4.9-5m3 55c0 1.7-1.3 3-3 3H22.4c-1.7 0-3-1.3-3-3v-5.1h14.3c.6 0 1-.4 1-1s-.4-1-1-1H19.4v-7.8h9c.6 0 1-.4 1-1s-.4-1-1-1h-9v-7.8h14.3c.6 0 1-.4 1-1s-.4-1-1-1H19.4v-7.8h9c.6 0 1-.4 1-1s-.4-1-1-1h-9v-7.8h14.3c.6 0 1-.4 1-1s-.4-1-1-1H19.4V7c0-1.7 1.3-3 3-3h19.1c1.7 0 3 1.3 3 3v50z" />
  </svg>
);
export default IconRulerCopy;
