import * as React from "react";
const IconDumpTruck = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.8 30.3 56.7 23c-1-1.3-2.4-2-3.9-2h-5.6c-2.6 0-4.7 2.1-4.7 4.7V44h-6.8V16.7h8.4c.6 0 1-.4 1-1s-.4-1-1-1H28.3c-.3 0-.6.1-.8.3l-6.3 7.2H6.7C4.1 22.3 2 24.4 2 27v14.3C2 44 4.1 46 6.7 46h1.5c.1 0 .2 0 .4-.1.6 1.9 2.4 3.3 4.5 3.3s3.8-1.4 4.5-3.2H21c.6 1.9 2.4 3.2 4.5 3.2s3.8-1.4 4.5-3.2h17.2c.6 1.9 2.4 3.2 4.5 3.2s3.8-1.4 4.5-3.2h5c.6 0 1-.4 1-1V30.9c-.2-.2-.3-.4-.4-.6m-14.6-7.4h5.6c.9 0 1.7.4 2.3 1.2l4.4 6.4h-15v-4.8c0-1.6 1.2-2.8 2.7-2.8M8.4 44.1H6.7C5.2 44 4 42.9 4 41.3V27c0-1.6 1.2-2.7 2.7-2.7h15c.3 0 .6-.1.8-.3l6.3-7.2h4.9V44h-3.5c-.3-2.4-2.2-4.2-4.7-4.2-2.4 0-4.4 1.9-4.7 4.2h-3.1c-.3-2.4-2.2-4.2-4.7-4.2-2.4 0-4.4 1.9-4.6 4.3m4.7 3.2c-1.5 0-2.7-1.2-2.7-2.7s1.2-2.7 2.7-2.7 2.7 1.2 2.7 2.7-1.2 2.7-2.7 2.7m12.4 0c-1.5 0-2.7-1.2-2.7-2.7s1.2-2.7 2.7-2.7 2.7 1.2 2.7 2.7-1.2 2.7-2.7 2.7m26.1 0c-1.3 0-2.3-.9-2.6-2.1V45c0-.2 0-.3-.1-.4 0-1.5 1.2-2.7 2.7-2.7s2.7 1.2 2.7 2.7-1.2 2.7-2.7 2.7m4.6-3.3c-.3-2.4-2.2-4.2-4.7-4.2-2.4 0-4.4 1.9-4.7 4.2h-2.4V32.5H60V44z" />
    <path d="M10.8 29.1c-.6 0-1 .4-1 1v4.6c0 .6.4 1 1 1s1-.4 1-1v-4.6c0-.5-.4-1-1-1M20.8 35.7c.6 0 1-.4 1-1v-4.6c0-.6-.4-1-1-1s-1 .4-1 1v4.6c0 .6.4 1 1 1" />
  </svg>
);
export default IconDumpTruck;
