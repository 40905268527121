import * as React from "react";
const IconCutleryAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m43.1 36.1 18.2-18c.5-.5.7-1.1.7-1.7s-.3-1.2-.7-1.7l-1.8-1.8c-2-2-5.1-2-7.7-.1L34.4 27.5c-.2.2-.3.4-.4.7 0 .3.1.6.3.8l6.6 6.5-5.8 5.7-9.7-9.6c1.7-1.7 2.6-3.9 2.6-6.2 0-2.2-.9-4.3-2.5-5.9L14.8 10c-.4-.4-1-.3-1.4.1s-.3 1.1.1 1.4l10.8 9.3c1.2 1.2 1.8 2.8 1.8 4.5 0 1.8-.7 3.5-2 4.8H24L9.3 15.5c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l14.7 14.6c-2.7 2.6-6.9 2.7-9.4.3L3.8 21.1c-.4-.4-1-.5-1.4-.1s-.5 1-.1 1.4l9.5 10.8c1.6 1.6 3.8 2.4 5.9 2.4 2.3 0 4.6-.9 6.3-2.6l.1-.1 9.7 9.6-10.1 10c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3l10.1-10 10.1 10c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-10.1-10zm-6.5-7.8L53 14.4c1.4-1 3.5-1.6 5.1-.1l1.8 1.8c.1.1.1.2.1.3s0 .2-.1.3L49.4 27l-7.1 7z" />
  </svg>
);
export default IconCutleryAlt1;
