import * as React from "react";
const IconLink = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m58.9 42.3-8.1-8.1c-4.1-4.1-10.5-4.5-15.1-1.2l-4.8-4.8c1.4-1.9 2.2-4.2 2.2-6.7 0-3.1-1.2-6.1-3.4-8.3l-8.1-8.1C17.1.6 9.7.6 5.1 5.1 3 7.4 1.8 10.3 1.8 13.4s1.2 6 3.4 8.2l8 8.1c2.3 2.3 5.3 3.4 8.2 3.4 2.2 0 4.3-.6 6.2-1.8l5 5c-1.2 1.8-1.8 4-1.8 6.2 0 3.1 1.2 6 3.4 8.2l8 8.1c2.3 2.3 5.3 3.4 8.2 3.4 3 0 6-1.1 8.2-3.4 2.2-2.2 3.4-5.1 3.4-8.2.3-3.1-.9-6-3.1-8.3M16.4 26.5l-8-8.1C7 17 6.3 15.2 6.3 13.3S7 9.6 8.4 8.2s3.2-2.1 5.1-2.1c1.8 0 3.7.7 5.1 2.1l8.1 8c1.3 1.4 2.1 3.2 2.1 5.1 0 1.3-.3 2.4-.9 3.5l-2.5-2.5c-.9-.9-2.3-.9-3.2 0s-.9 2.3 0 3.2l2.3 2.3c-2.8 1.4-6 .9-8.1-1.3m39.2 29.1c-2.8 2.8-7.4 2.8-10.1 0l-8-8.1c-1.4-1.4-2.1-3.2-2.1-5.1 0-1 .2-2 .6-2.9l2.3 2.2c.4.4 1 .7 1.6.7s1.2-.2 1.6-.7c.9-.9.9-2.3 0-3.2L39 36.3c1.1-.6 2.3-.9 3.5-.9 1.8 0 3.7.7 5.1 2.1l8.1 8c1.3 1.4 2.1 3.2 2.1 5.1-.1 1.9-.8 3.7-2.2 5" />
  </svg>
);
export default IconLink;
