import React from "react";
import Barcode from 'react-barcode';
import BringzzImage from "../BringzzImage";
import BringzzText from "../BringzzText";
import BringzzIcon from "../BringzzIcon";
import PropTypes from "prop-types";
import QRCode from "react-qr-code";

export default function BringzzQrCode({ value, className, ...props }) {
    return (
        <div {...props} className={className}>
            <QRCode
                size={270}
                style={{ height: "auto", maxWidth: "270px", width: "270px" }}
                value={value}
                viewBox={`0 0 270 270`}
            />
            <BringzzText tag="h4" className="font-normal mt-2">Med QR-Code</BringzzText>
            <BringzzText tag="h4" className="font-normal">{value}</BringzzText>
        </div>
    )
}

BringzzQrCode.propTypes = {
    value: PropTypes.string.isRequired
};
