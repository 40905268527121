import * as React from "react";
const IconBloodDrop = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M24 42c6.663 0 12-5.29 12-11.737 0-5.357-3.178-11.486-6.645-16.502A73 73 0 0 0 24 6.95q-.311.35-.672.769a73 73 0 0 0-4.683 6.042C15.178 18.777 12 24.906 12 30.263 12 36.709 17.337 42 24 42M22.64 5.47C19.122 9.409 10 20.56 10 30.263 10 37.85 16.268 44 24 44s14-6.15 14-13.737C38 20.56 28.878 9.409 25.36 5.47 24.52 4.53 24 4 24 4s-.52.53-1.36 1.47"
      clipRule="evenodd"
    />
  </svg>
);
export default IconBloodDrop;
