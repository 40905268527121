import * as React from "react";
const IconShoppingCart7 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.8 2.7c-.2-.5-.8-.8-1.3-.6l-7.6 2.8c-.4.1-.7.5-.7.9v28.6c0 2.5-2 4.5-4.5 4.5h-31c-2.5 0-4.5-2-4.5-4.5v-15c0-.6-.4-1-1-1s-1 .4-1 1v14.9c0 3.6 2.9 6.5 6.5 6.5h31c1.8 0 3.3-.7 4.5-1.8v6.8c0 2.5-2 4.5-4.5 4.5H14.5c-3.2 0-5.8 2.6-5.8 5.8s2.6 5.8 5.8 5.8 5.8-2.6 5.8-5.8c0-1.5-.5-2.8-1.4-3.8h15c-.9 1-1.4 2.4-1.4 3.8 0 3.2 2.6 5.8 5.8 5.8s5.8-2.6 5.8-5.8c0-1.5-.5-2.8-1.4-3.8h1.1c3.6 0 6.5-2.9 6.5-6.5V6.5L57.2 4c.5-.2.8-.8.6-1.3M18.3 56.2c0 2.1-1.7 3.8-3.8 3.8s-3.8-1.7-3.8-3.8 1.7-3.8 3.8-3.8 3.8 1.7 3.8 3.8m23.8 0c0 2.1-1.7 3.8-3.8 3.8s-3.8-1.7-3.8-3.8 1.7-3.8 3.8-3.8c2.1-.1 3.8 1.7 3.8 3.8" />
    <path d="M21.9 27c.2.2.5.3.7.3s.5-.1.7-.3l12.4-12.4c.4-.4.4-1 0-1.4s-1-.4-1.4 0L21.9 25.6c-.4.4-.4 1 0 1.4M22.8 18.2c1.1 0 2.1-.4 2.9-1.2s1.2-1.8 1.2-2.9-.4-2.1-1.2-2.9-1.8-1.2-2.9-1.2-2.1.4-2.9 1.2c-1.6 1.6-1.6 4.2 0 5.8.7.8 1.8 1.2 2.9 1.2m-1.5-5.6q.6-.6 1.5-.6c.9 0 1.1.2 1.5.6s.6.9.6 1.5-.2 1.1-.6 1.5c-.8.8-2.2.8-3 0-.9-.9-.9-2.2 0-3M32.4 23.7c-.8.8-1.2 1.8-1.2 2.9s.4 2.1 1.2 2.9 1.9 1.2 2.9 1.2 2.1-.4 2.9-1.2 1.2-1.8 1.2-2.9-.4-2.1-1.2-2.9c-1.6-1.6-4.2-1.6-5.8 0m5.1 2.9q0 .9-.6 1.5c-.8.8-2.2.8-3 0q-.6-.6-.6-1.5c0-.9.2-1.1.6-1.5s1-.6 1.5-.6 1.1.2 1.5.6c.3.4.6.9.6 1.5" />
  </svg>
);
export default IconShoppingCart7;
