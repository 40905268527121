import * as React from "react";
const IconBookshelf2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M47.4 2H16.6c-3 0-5.4 2.4-5.4 5.4V61c0 .6.4 1 1 1s1-.4 1-1V44.4h37.5V61c0 .6.4 1 1 1s1-.4 1-1V7.4c.1-3-2.3-5.4-5.3-5.4M16.6 4h30.8c1.8 0 3.4 1.5 3.4 3.4v15.9h-8V12.2c0-.6-.4-1-1-1s-1 .4-1 1v11.1h-5.6V12.2c0-.6-.4-1-1-1s-1 .4-1 1v11.1h-20V7.4c0-1.9 1.5-3.4 3.4-3.4m-3.4 21.3H31v17.2H13.2zM33 42.4V25.3h17.8v17.2H33z" />
  </svg>
);
export default IconBookshelf2;
