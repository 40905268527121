import * as React from "react";
const IconNailPolish2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M39.9 26.9V7.3c0-2.9-2.4-5.3-5.3-5.3h-5.2c-2.9 0-5.3 2.4-5.3 5.3v19.6c-8.6 3-14.8 10.5-14.8 19.4 0 5.7 2.6 11.2 7.2 15.1.4.4 1 .6 1.6.6h27.7c.6 0 1.1-.2 1.6-.6 4.6-3.9 7.2-9.4 7.2-15.1.1-8.9-6.1-16.4-14.7-19.4M26.1 7.3c0-1.8 1.5-3.3 3.3-3.3h5.2c1.8 0 3.3 1.5 3.3 3.3v19c-1.9-.4-3.9-.7-5.9-.7s-4 .3-5.9.7zm15.1 39c0 5.2-1.1 10.2-3 13.7H25.8c-1.9-3.6-3-8.5-3-13.7 0-10.1 4.2-18.7 9.2-18.7s9.2 8.6 9.2 18.7M17.8 59.9c-4.1-3.5-6.5-8.5-6.5-13.6 0-8.5 6.4-15.7 15.1-18-3.4 3.5-5.6 10.2-5.6 18 0 5.1 1 10 2.8 13.7h-5.4c-.2 0-.3 0-.4-.1m28.4 0c-.1.1-.2.1-.3.1h-5.4c1.8-3.7 2.8-8.6 2.8-13.7 0-7.8-2.2-14.4-5.6-18 8.7 2.2 15.1 9.4 15.1 18-.1 5.1-2.5 10.1-6.6 13.6" />
  </svg>
);
export default IconNailPolish2;
