import * as React from "react";
const IconStarOfDavid1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m44.6 32 3.7-6.3c.4-.7.4-1.6 0-2.4-.4-.7-1.2-1.2-2.1-1.2h-7.5l-4.7-8c-.4-.7-1.2-1.2-2-1.2-.9 0-1.6.4-2 1.2l-4.7 8h-7.5q-1.35 0-2.1 1.2c-.75 1.2-.4 1.6 0 2.4l3.7 6.3-3.7 6.3c-.4.7-.4 1.6 0 2.4s1.2 1.2 2.1 1.2h7.5l4.7 8c.4.7 1.2 1.2 2 1.2.9 0 1.6-.4 2-1.2l4.7-8h7.5q1.35 0 2.1-1.2.6-1.2 0-2.4zm1.7-7.9c.2 0 .3.1.3.2s.1.2 0 .4L43.4 30l-3.5-6h6.4zm-4 7.9-4.6 7.9H26.4L21.7 32l4.6-7.9h11.2zM31.6 15.1c.1-.2.2-.1.3-.2.1 0 .2 0 .3.2l4.1 7h-8.9zm-14.2 9.6c-.1-.2 0-.3 0-.4s.1-.2.3-.2H24L20.6 30zm.3 15.2c-.2 0-.3-.1-.3-.2s-.1-.2 0-.4l3.2-5.4 3.5 6zm14.7 9c-.1.2-.2.2-.3.2s-.2 0-.3-.2l-4.1-7h8.9zm14.2-9.2c0 .1-.1.2-.3.2H40l3.5-5.9 3.2 5.4c0 .1-.1.2-.1.3" />
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
  </svg>
);
export default IconStarOfDavid1;
