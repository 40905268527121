import * as React from "react";
const IconWhatsapp = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54 9.9c-5.8-5.8-13.7-9-21.8-9-17 0-30.9 13.8-30.9 30.8 0 5.5 1.4 10.7 4.1 15.5L1 63.1l16.5-4.2c4.5 2.4 9.6 3.8 14.8 3.8 16.9-.1 30.7-13.9 30.7-31 0-8.2-3.2-15.9-9-21.8M32.1 57.4c-4.5 0-9.2-1.3-13.1-3.7l-1-.6-9.7 2.5 2.7-9.4-.6-1c-2.5-4.1-3.9-8.9-3.9-13.7C6.5 17.4 17.9 6 32.1 6c6.8 0 13.2 2.7 18 7.5s7.5 11.3 7.5 18.2c.2 14.3-11.4 25.7-25.5 25.7m14.1-19.2c-.8-.4-4.5-2.3-5.4-2.4-.7-.3-1.3-.4-1.7.4s-2 2.4-2.4 3c-.4.4-.8.6-1.7.1-.8-.4-3.2-1.1-6.2-3.9-2.3-2-3.9-4.5-4.2-5.4-.4-.8-.1-1.1.4-1.6.4-.4.8-.8 1.1-1.4.4-.4.4-.8.8-1.3.4-.4.1-1-.1-1.4-.3-.4-1.7-4.2-2.4-5.8-.6-1.6-1.3-1.3-1.7-1.3h-1.4c-.4 0-1.4.1-2 1-.7.8-2.7 2.7-2.7 6.5s2.7 7.3 3.2 8c.4.4 5.5 8.3 13.1 11.7 1.8.8 3.2 1.3 4.4 1.7 1.8.6 3.5.4 4.8.3 1.5-.1 4.5-1.8 5.2-3.7.6-1.7.6-3.4.4-3.7-.2-.2-.8-.5-1.5-.8" />
  </svg>
);
export default IconWhatsapp;
