import * as React from "react";
const IconDrupalCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M39.5 12.8c-1-.3-2-1.1-2.7-2-.4-.5-.8-1.1-1.3-1.8l-4.6-6.7c-.6-.9-1.8-1.4-2.8-1.2-1.1.2-2 1.1-2.2 2.1l-1.5 7.3c-.4 1.6-1.5 2.8-3.1 3.5-9.4 4-15.4 13.2-15.4 23.3C5.9 51.5 17.6 63 32 63s26.1-11.5 26.1-25.7c0-11.1-7.6-21.2-18.6-24.5M32 61C18.7 61 7.9 50.4 7.9 37.3c0-9.4 5.6-17.8 14.2-21.5 2.2-.9 3.7-2.6 4.3-4.9L28 3.5q.15-.45.6-.6h.1c.3 0 .5.1.7.3L34 9.9c.5.8 1 1.4 1.3 1.9.9 1.3 2.3 2.3 3.8 2.7 9.9 3.3 17 12.6 17 22.8C56.1 50.4 45.3 61 32 61" />
    <path d="M45.4 28.9c-.9-.6-2-.9-3.3-.8-.9.1-1.7.2-2.5.6-.9.4-1.6.8-2.3 1.3l-.5.3c-.2.2-.5.3-.7.4H36s-.1 0-.1.1c-.1 0-.2-.1-.5-.3-.2-.1-.3-.2-.5-.3l-.4-.3c-.5-.4-1-.8-1.6-1-3-1.5-6.3-1.9-9.1-1.1-1.9.6-3.5 1.8-4.5 3.5-.9 1.6-1.1 3.6-.6 5.5.6 2.1 1.8 3.9 3.5 4.9 1.1.6 2.3 1 3.6 1q.9 0 2.1-.3c3.1-.8 5.4-2.8 7.5-4.7l.8-.7c.1.2.2.3.3.4l.3.5c.2.4.5.9.8 1.3 1.6 2 4.6 3.4 7.3 2 1.2-.6 2.8-2 3.2-5.3.2-1.4.1-2.5-.1-3.4-.6-1.6-1.4-2.9-2.6-3.6m.5 6.7c-.2 1.3-.7 3.1-2.2 3.8-1.7.9-3.7-.1-4.8-1.4-.2-.3-.5-.7-.7-1l-.2-.7c-.5-.9-.9-1.4-1.4-1.7l-.2-.1h-.2c-.4 0-.7.2-1.1.5l-1.2 1c-2 1.8-4 3.6-6.6 4.3-.6.1-1.2.2-1.6.2-.9 0-1.8-.2-2.6-.7-1.2-.7-2.2-2-2.6-3.6-.3-1.4-.2-2.9.4-4 .7-1.2 1.9-2.2 3.3-2.6.8-.2 1.6-.3 2.5-.3 1.7 0 3.5.4 5.3 1.3.5.2.9.5 1.3.8l.4.3c.2.2.4.3.6.4q1.05.75 1.8.6c.3 0 .6-.2.8-.3l.1-.1c.3-.2.6-.3.9-.5l.5-.3c.6-.4 1.2-.8 1.9-1.1.6-.2 1.2-.4 1.9-.4.8 0 1.5.1 2.1.5.8.5 1.3 1.3 1.6 2.3.2.8.2 1.6 0 2.8M37.6 53c-.5.3-1 .5-1.6.7-1.1.4-2.3.5-3.4.5-1.1-.1-2.3-.4-3.4-.9-.5-.2-1.1 0-1.3.5s0 1.1.5 1.3c1.4.6 2.7.9 4 1h.8c1.2 0 2.3-.2 3.5-.6.7-.2 1.3-.5 2-.9.5-.3.6-.9.3-1.4-.4-.3-1-.5-1.4-.2M38.1 45.4c-1.7 0-3.4.8-4.6 2-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3c.8-.9 2-1.4 3.2-1.4.9 0 1.7.3 2.2.8.4.4 1 .4 1.4 0s.4-1 0-1.4c-.9-1-2.2-1.5-3.6-1.4" />
  </svg>
);
export default IconDrupalCopy;
