import * as React from "react";
const IconCctv = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.1 11.1c-.6-.4-1.3-.5-2-.3l-9.4 3.4v-2.5c0-2.8-2.2-5-5-5h-29c-2.8 0-5 2.2-5 5v14.9c0 2.8 2.2 5 5 5h15.9l-7.7 14.3H12.7v-2.7c0-2.2-1.8-4-4-4H6c-2.2 0-4 1.8-4 4v10.1c0 2.2 1.8 4 4 4h2.7c2.2 0 4-1.8 4-4v-5.4h11.8c.4 0 .7-.2.9-.5l8.5-15.8h10.8c2.8 0 5-2.2 5-5v-2.5l9.4 3.4c.2.1.5.1.7.1.4 0 .9-.1 1.3-.4.6-.4.9-1.1.9-1.8V12.9c0-.7-.3-1.4-.9-1.8M10.7 53.3c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V43.2c0-1.1.9-2 2-2h2.7c1.1 0 2 .9 2 2zm34-23.7h-29c-1.7 0-3-1.3-3-3V11.7c0-1.7 1.3-3 3-3h29c1.7 0 3 1.3 3 3v14.9c0 1.6-1.3 3-3 3M60 25.4c0 .1 0 .1-.1.2 0 0-.1.1-.2 0l-10-3.6v-5.6l10-3.6h.2s.1.1.1.2z" />
  </svg>
);
export default IconCctv;
