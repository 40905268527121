import * as React from "react";
const IconBowtieAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.1 18c-1.2-1-2.7-1.4-4.2-1.1-5.7 1.1-10.7 3.4-14.5 6.7H22.6c-4.8-4.1-10.5-5.9-14.5-6.7-1.5-.3-3.1.1-4.2 1.1C2.7 19 2 20.4 2 22v20.1c0 1.5.7 3 1.9 4 .9.8 2.1 1.2 3.2 1.2.3 0 .6 0 .9-.1 6-1.1 11.2-3.6 15.1-7.1 0 0 0-.1.1-.1h17.7s0 .1.1.1c4.9 4.4 10.9 6.3 15 7.1.3.1.6.1 1 .1q1.8 0 3.3-1.2c1.2-1 1.9-2.4 1.9-4V21.9c-.2-1.5-.9-3-2.1-3.9M23.4 32v-6.4h17.1v12.3H23.4zM7.7 45.2c-.9.2-1.8-.1-2.5-.7C4.4 43.9 4 43 4 42.1V21.9c0-.9.4-1.8 1.1-2.4.6-.5 1.3-.7 2-.7.2 0 .4 0 .6.1 3.8.7 9.3 2.4 13.7 6.3V31h-2.8c-.6 0-1 .4-1 1s.4 1 1 1h2.8v5.8c-3.5 3.1-8.3 5.3-13.7 6.4M60 42.1c0 .9-.4 1.8-1.1 2.4s-1.6.8-2.6.7c-3.8-.7-9.3-2.4-13.7-6.3V33h3.3c.6 0 1-.4 1-1s-.4-1-1-1h-3.3v-5.8c3.6-3.1 8.3-5.3 13.8-6.3.9-.2 1.8.1 2.5.7s1.2 1.5 1.2 2.4v20.1z" />
  </svg>
);
export default IconBowtieAlt2;
