import * as React from "react";
const IconDialogflow = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 65 64"
    {...props}
  >
    <path
      
      d="m56.972 15.278-24.105 13.92-24.119-13.92V43.12l12.06 6.955V64l36.164-20.88z"
    />
    <path  d="M32.867 27.842 8.748 13.922 32.867 0l24.105 13.921z" />
    <path
      
      d="M32.44 27.418 8.9 13.83l-.152.091 24.119 13.921 24.105-13.92-.566-.337z"
    />
    <path  d="M32.867 28.015v-.173L8.9 14.002l-.153.092z" />
  </svg>
);
export default IconDialogflow;
