import * as React from "react";
const IconSmartwatchAltHeartMonitor = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.5 38.6h-5.7L45 25.1c-.2-.5-.7-.9-1.3-.9s-1.1.4-1.3.9l-5.5 14.3-3-7.2q-.3-.6-.9-.6h-6.5c-.6 0-1 .4-1 1s.4 1 1 1h5.8l3.4 8c.2.5.7.8 1.2.8s1-.4 1.2-.9l5.6-14.4L49.2 40q.3.6.9.6h6.3c.6 0 1-.4 1-1s-.4-1-.9-1" />
    <path d="M43.7 45.6c-.6 0-1 .4-1 1 0 1.7-1.4 3.1-3.1 3.1H10.7c-1.2 0-2.2-1-2.2-2.2V17.4c0-1.7 1.4-3.1 3.1-3.1h27.8c1.7 0 3.1 1.4 3.1 3.1 0 .6.4 1 1 1s1-.4 1-1c0-2.8-2.3-5.1-5.1-5.1H38l-1.3-6.1c-.5-2.4-2.6-4.1-5-4.1H19.5c-2.4 0-4.5 1.7-5 4.1l-1.3 6.1h-1.6c-2.8 0-5.1 2.3-5.1 5.1v30.2c0 2.3 1.9 4.2 4.2 4.2h2.5l1.3 6.1c.5 2.4 2.6 4.1 5 4.1h12.1c2.4 0 4.5-1.7 5-4.1l1.3-6.1h1.6c2.8 0 5.1-2.3 5.1-5.1.1-.6-.4-1.1-.9-1.1M16.5 6.5c.3-1.4 1.6-2.5 3-2.5h12.1c1.5 0 2.8 1.1 3.1 2.5l1.2 5.7H15.3zm18.2 51c-.3 1.5-1.6 2.5-3.1 2.5H19.5c-1.5 0-2.8-1.1-3.1-2.5l-1.2-5.7h20.6z" />
  </svg>
);
export default IconSmartwatchAltHeartMonitor;
