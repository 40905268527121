import * as React from "react";
const IconBiCycleCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M12.3 37.6C6.6 37.6 2 42.2 2 47.9s4.6 10.3 10.3 10.3 10.3-4.6 10.3-10.3S18 37.6 12.3 37.6m0 18.6c-4.6 0-8.3-3.7-8.3-8.3s3.7-8.3 8.3-8.3 8.3 3.7 8.3 8.3-3.7 8.3-8.3 8.3M51.8 37.6c-5.7 0-10.2 4.6-10.2 10.2S46.2 58 51.8 58 62 53.4 62 47.8s-4.6-10.2-10.2-10.2m0 18.5c-4.5 0-8.2-3.7-8.2-8.2s3.7-8.2 8.2-8.2 8.2 3.7 8.2 8.2-3.7 8.2-8.2 8.2M47.8 29.6c.3.4 1 .5 1.4.2s.5-1 .2-1.4l-8.8-11.2-.1-.1c-.6-.6-1.4-.7-2.2-.3l-11.9 6.9c-.5.3-.8.8-.9 1.3s.1 1.1.5 1.4l4 4.6c.8.9 1.2 2 1.2 3.2v6.9c0 .6.4 1 1 1s1-.4 1-1v-6.9c0-1.7-.6-3.3-1.7-4.5l-3.8-4.4 11.5-6.7zM49.2 15.8c2.8 0 5-2.2 5-5s-2.3-5-5-5-5 2.2-5 5 2.2 5 5 5m0-8c1.7 0 3 1.4 3 3 0 1.7-1.4 3-3 3s-3-1.4-3-3 1.3-3 3-3" />
  </svg>
);
export default IconBiCycleCopy;
