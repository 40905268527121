import * as React from "react";
const IconTwitterCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M20.6 57.1c-7 0-13.6-2-19.2-5.7-.4-.3-.5-.8-.4-1.2.2-.4.6-.7 1.1-.6.9.1 1.8.2 2.7.2 4.4 0 8.7-1.2 12.4-3.5-4.4-1-8.1-4.3-9.6-8.8-.1-.3 0-.7.2-1s.6-.4 1-.3c.3.1.6.1.9.2C5.9 34 3.4 29.8 3.4 25v-.1c0-.4.2-.7.5-.9s.7-.2 1 0c.5.3 1.1.5 1.7.7-2-2.3-3.1-5.3-3.1-8.5q0-3.6 1.8-6.6c.1-.2.4-.4.7-.4s.6.1.9.4C12.6 16.6 21 21 30 21.8c-.1-.6-.1-1.1-.1-1.7 0-7.3 5.9-13.2 13.2-13.2 3.4 0 6.7 1.3 9.2 3.8 1.7-.4 3.3-1.5 4.8-2.5.6-.4 1.3-.8 1.9-1.2.3-.2.7-.2 1 0s.5.6.4.9c-.1.6-.4 3.1-1.3 5.2 1.2.1 2.1.2 2.7.1.4-.1.8.1 1 .4s.2.8 0 1.1c-1.4 2-3.4 3.4-5.4 4.8-.4.3-.8.6-1.2.8v1.1c.1 17.6-13.3 35.7-35.6 35.7M6 51.7c4.5 2.2 9.4 3.4 14.6 3.4 21.1 0 33.7-17.1 33.7-33.6 0-.5 0-1-.1-1.4 0-.4.1-.7.4-.9.6-.4 1.1-.8 1.7-1.2 1.2-.8 2.4-1.7 3.5-2.7-.9-.1-1.8-.2-2.4-.3-.3-.1-.6-.3-.8-.6-.1-.3-.1-.7.1-1 .6-.8 1-2.1 1.4-3.5-1.8 1.2-3.8 2.4-6 2.8-.3.1-.7-.1-.9-.3-2.2-2.3-5.1-3.5-8.2-3.5-6.2 0-11.2 5-11.2 11.2 0 .8.1 1.6.3 2.5.1.3 0 .6-.2.9-.2.2-.5.4-.8.4-9.5-.5-18.5-4.8-24.8-11.9-.6 1.3-.8 2.8-.8 4.3 0 3.7 1.9 7.2 5 9.3.4.2.5.7.4 1.1s-.5.7-1 .7c-1.5 0-3-.4-4.4-.9.6 4.7 4.2 8.5 8.8 9.5.5.1.8.5.8 1s-.3.9-.8 1c-1.5.4-3 .5-4.2.4 1.8 3.7 5.6 6.1 9.8 6.2.4 0 .8.3.9.7s0 .8-.3 1.1c-4.2 3.3-9.2 5.1-14.5 5.3" />
  </svg>
);
export default IconTwitterCopy;
