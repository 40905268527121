import * as React from "react";
const IconLeafAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.4 10c-.5-.2-12.5-4-19.9-.1-4.2 2.2-7.4 5.3-9 8.7-.6-3.2-2.3-6.5-4.9-9.5C19.2 2.9 6.6 2 6.1 2c-.6 0-1 .4-1.1.9 0 .5-1.1 12.9 4.4 19.2 3.9 4.4 8.7 6.9 12.7 6.9 1.8 0 3.4-.5 4.7-1.5-1.5 4.8-3.6 16-.2 33.7.1.5.5.8 1 .8h.2c.5-.1.9-.6.8-1.2-4.1-21 0-32.6.9-34.8 1.5 2.7 4.7 4.1 8.6 4.1 3 0 6.4-.8 9.7-2.6 7.4-3.8 11.1-15.7 11.2-16.2.2-.6-.1-1.1-.6-1.3M10.9 20.8c-4.1-4.6-4.1-13.7-4-16.7 3 .3 12.1 1.7 16.2 6.3 3.1 3.5 4.8 7.8 4.6 11.2-.1 1.7-.8 3.1-1.8 4-3.4 2.9-10.1.7-15-4.8m36 4.9c-6.5 3.4-13.7 3-15.8-.9-.7-1.2-.7-2.7-.2-4.4 1-3.3 4.3-6.7 8.5-8.8 2.1-1.1 4.7-1.5 7.3-1.5 4.2 0 8.3 1 10.1 1.5-1 2.9-4.4 11.3-9.9 14.1" />
  </svg>
);
export default IconLeafAlt;
