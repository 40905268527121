import * as React from "react";
const IconUndoAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.7 12.6c-6.8-6.8-17.5-7-24-.6L14.5 30.3l-2.8-2.8a5.13 5.13 0 0 0-5.4-1.2c-1.9.7-3.2 2.3-3.4 4.3L2 38.4c-.2 1.6.4 3.1 1.5 4.2 1 1 2.3 1.5 3.7 1.5h.6l7.8-.8c2-.2 3.7-1.5 4.3-3.4.7-1.9.2-4-1.2-5.4l-2.8-2.8 18.3-18.3c5.6-5.7 15.1-5.4 21.1.6s6.3 15.5.6 21.2L36.1 55c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l19.9-19.9c6.4-6.4 6.1-17.2-.7-23.9M18 39.2c-.4 1.2-1.4 2-2.7 2.1l-7.8.8c-1 .1-1.9-.2-2.6-.9s-1-1.6-.9-2.6l.8-7.8c.2-1.3 1-2.3 2.2-2.7.4-.1.7-.2 1.1-.2.8 0 1.6.3 2.2.9l3.5 3.5 3.5 3.5c.8 1 1.1 2.2.7 3.4" />
  </svg>
);
export default IconUndoAlt;
