import * as React from "react";
const IconBacon = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M49.2 40.8c-1.7-1.9-2.7-5.3-2.1-7.8.6-3.1-.5-7.1-2.6-9.5-1.7-1.9-2.7-5.3-2.1-7.8.6-3.1-.5-7.1-2.6-9.5l-3.4-3.8c-.4-.4-.8-.5-1.1-.4l-7.6 2-7.2 1.9h-.1l-7.6 2c-.4.1-.7.4-.8.7s0 .7.2 1l3.4 3.8c1.7 1.9 2.6 5.3 2.1 7.8-.6 3.1.5 7.1 2.6 9.5 1.7 1.9 2.7 5.3 2.1 7.8-.6 3.1.5 7.1 2.6 9.5 1.7 1.9 2.6 5.3 2.1 7.8l-1 4.9c-.1.5.2 1.1.8 1.2h.2c.1 0 .2 0 .3-.1h.1l7.5-2 7.2-1.9h.1l7.6-2c.4-.1.7-.4.7-.8l1-4.9c.9-2.9-.2-7-2.4-9.4M35 53.9l-.9 4.3-5.5 1.4.7-3.3c.6-3.1-.5-7.1-2.6-9.5-1.7-1.9-2.6-5.3-2.1-7.8.6-3.1-.5-7.1-2.6-9.5-1.7-1.9-2.7-5.3-2.1-7.8.6-3.1-.5-7.1-2.6-9.5L15 9.6 20.3 8l3 3.3c1.7 1.9 2.6 5.3 2.1 7.8-.6 3.1.5 7.1 2.6 9.5 1.7 1.9 2.7 5.3 2.1 7.8-.6 3.1.5 7.1 2.6 9.5 1.8 2.1 2.8 5.5 2.3 8m7.2-1.9-.9 4.3-5.1 1.3.7-3.3c.6-3.1-.5-7.1-2.6-9.5-1.7-1.9-2.7-5.3-2.1-7.8.6-3.1-.5-7.1-2.6-9.5-1.7-1.9-2.7-5.3-2.1-7.8.6-3.1-.5-7.1-2.6-9.5l-2.3-2.6 5.1-1.3 3 3.3c1.7 1.9 2.7 5.3 2.1 7.8-.6 3.1.5 7.1 2.6 9.5 1.7 1.9 2.7 5.3 2.1 7.8-.6 3.1.5 7.1 2.6 9.5 1.7 1.9 2.6 5.3 2.1 7.8m7.7-2-.9 4.3-5.5 1.4.7-3.3c.6-3.1-.5-7.1-2.6-9.5-1.7-1.9-2.6-5.3-2.1-7.8.6-3.1-.5-7.1-2.6-9.5-1.7-1.9-2.7-5.3-2.1-7.8.6-3.1-.5-7.1-2.6-9.5l-2.3-2.6 5.5-1.4 3 3.3c1.7 1.9 2.6 5.3 2.1 7.8-.6 3.1.5 7.1 2.6 9.5 1.7 1.9 2.7 5.3 2.1 7.8-.6 3.1.5 7.1 2.6 9.5 1.6 1.9 2.6 5.3 2.1 7.8" />
  </svg>
);
export default IconBacon;
