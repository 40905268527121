import * as React from "react";
const IconResistanceBand = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.1 50.7-8.8-8.8V12.6C52.2 6.8 47.5 2 41.6 2 35.8 2 31 6.8 31 12.6v38.8c0 4.7-3.9 8.6-8.6 8.6s-8.6-3.9-8.6-8.6V21.8l8.8-8.8c.3-.3.4-.7.2-1.1q-.3-.6-.9-.6H3.7c-.4 0-.8.2-.9.6-.2.4-.1.8.2 1.1l8.8 8.8v29.6c0 5.9 4.8 10.6 10.6 10.6S33 57.2 33 51.4V12.6C33 7.9 36.9 4 41.6 4s8.6 3.9 8.6 8.6v29.3l-8.8 8.8c-.3.3-.4.7-.2 1.1q.3.6.9.6h18.2c.4 0 .8-.2.9-.6.2-.3.1-.8-.1-1.1M19.5 13.3 12.8 20l-6.7-6.7zm25 37.2 6.7-6.7 6.7 6.7z" />
  </svg>
);
export default IconResistanceBand;
