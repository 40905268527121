import * as React from "react";
const IconSe = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 85.333 512 341.333"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 85.333h512V426.67H0z" />
    <path
      
      d="M192 85.33h-64v138.666H0v64h128v138.666h64V287.996h320v-64H192z"
    />
  </svg>
);
export default IconSe;
