import * as React from "react";
const IconFigma = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M42.4 21.5c5.8 0 10.4-4.6 10.4-10.4S48 1 42.4 1H21.6c-5.8 0-10.4 4.6-10.4 10.4s4.6 10.4 10.4 10.4c-5.8 0-10.4 4.6-10.4 10.4s4.6 10.4 10.4 10.4c-5.8 0-10.4 4.6-10.4 10.4s4.7 10 10.4 10S32 58.4 32 52.6V21.8h10.4zm0 0c-5.8 0-10.4 4.6-10.4 10.4s4.6 10.4 10.4 10.4 10.4-4.6 10.4-10.4c-.3-5.8-4.8-10.4-10.4-10.4" />
  </svg>
);
export default IconFigma;
