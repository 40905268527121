import * as React from "react";
const IconTruckSearch = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.8 33.5-7.5-10.8c-.2-.3-.5-.4-.8-.4H40.9v-3.5c0-3-2.5-5.5-5.5-5.5H27c-.6 0-1 .4-1 1s.4 1 1 1h8.4c1.9 0 3.5 1.6 3.5 3.5v31.4H26.7c-.2-2.8-2.6-5.1-5.5-5.1s-5.3 2.2-5.5 5.1H7.5c-1.9 0-3.5-1.6-3.5-3.5V32.2c0-.6-.4-1-1-1s-1 .4-1 1v14.4c0 3 2.5 5.5 5.5 5.5h8.4c.7 2.3 2.8 3.9 5.3 3.9s4.6-1.6 5.3-3.9h18c.7 2.3 2.8 3.9 5.3 3.9s4.6-1.6 5.3-3.9H61c.6 0 1-.4 1-1v-17c0-.2-.1-.4-.2-.6M53 24.2l6.6 9.6H40.9v-9.6zM21.2 54.1c-1.9 0-3.5-1.6-3.5-3.5s1.6-3.5 3.5-3.5 3.5 1.6 3.5 3.5-1.6 3.5-3.5 3.5m28.5 0c-1.9 0-3.5-1.6-3.5-3.5s1.6-3.5 3.5-3.5 3.5 1.6 3.5 3.5-1.6 3.5-3.5 3.5m5.5-4c-.2-2.8-2.6-5.1-5.5-5.1s-5.3 2.2-5.5 5.1h-3.3V35.8H60v14.3z" />
    <path d="M9.6 22.6c1.6 0 3.1-.5 4.4-1.5l4.8 4.8c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-4.8-4.8c2.2-2.9 2-7-.7-9.6-2.8-2.8-7.5-2.8-10.3 0s-2.8 7.5 0 10.3c1.4 1.4 3.3 2.2 5.2 2.2M5.8 11.5c1-1 2.4-1.6 3.8-1.6s2.7.5 3.8 1.6c2.1 2.1 2.1 5.4 0 7.5S8 21.1 5.9 19s-2.2-5.4-.1-7.5" />
  </svg>
);
export default IconTruckSearch;
