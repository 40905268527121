import * as React from "react";
const IconIdeaAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M43.9 19.5c-3.4-3.3-8-5-12.9-4.8-8.9.6-16 8.1-16.1 17.1-.1 6 2.8 11.5 7.6 14.7v9c0 3.3 2.7 6 6 6h6.9c3.3 0 6-2.7 6-6v-9c4.9-3.2 7.8-8.6 7.8-14.6 0-4.6-1.9-9-5.3-12.4m-8.4 40.1h-6.9c-2.2 0-4-1.8-4-4v-8.3h14.9v8.3c-.1 2.2-1.9 4-4 4m4.4-14.3-.2.1H24.4l-.3-.2c-4.5-2.8-7.2-7.8-7.1-13.3.1-8 6.3-14.6 14.2-15.1h.8c4 0 7.7 1.5 10.6 4.2 3 3 4.7 6.9 4.7 11.2-.1 5.3-2.8 10.2-7.4 13.1M32 9.6c.6 0 1-.4 1-1V3.4c0-.6-.4-1-1-1s-1 .4-1 1v5.3c0 .5.4.9 1 .9M55 12.3c-.4-.4-1-.4-1.4 0L50 15.8c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3l3.6-3.6c.4-.3.4-.9 0-1.3M10.4 12.3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l3.6 3.6c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4zM8.3 33.3H3c-.6 0-1 .4-1 1s.4 1 1 1h5.3c.6 0 1-.4 1-1s-.5-1-1-1M61 33.3h-5.3c-.6 0-1 .4-1 1s.4 1 1 1H61c.6 0 1-.4 1-1s-.4-1-1-1" />
    <path d="M34.1 30.8H30c-1.1 0-2.1-.9-2.1-1.9S28.8 27 30 27h6.1c.6 0 1-.4 1-1s-.4-1-1-1h-2.5v-.8c0-.6-.4-1-1-1s-1 .4-1 1v.8H30c-2.2 0-4.1 1.8-4.1 3.9 0 2.2 1.8 3.9 4.1 3.9h4.1c1.1 0 2.1.9 2.1 1.9 0 1.1-.9 1.9-2.1 1.9h-6.9c-.6 0-1 .4-1 1s.4 1 1 1h4.4v.8c0 .6.4 1 1 1s1-.4 1-1v-.8h.5c2.2 0 4.1-1.8 4.1-3.9s-1.8-3.9-4.1-3.9" />
  </svg>
);
export default IconIdeaAlt;
