import * as React from "react";
const IconElegantTable1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M23.5 23.9h17c.3 0 .6-.1.8-.4.2-.2.3-.5.2-.8-.9-4.1-4.2-6.9-8.5-7.3V3c0-.6-.4-1-1-1s-1 .4-1 1v12.4c-4.3.4-7.5 3.1-8.5 7.3-.1.3 0 .6.2.8.2.3.5.4.8.4m8.5-6.5c3.3 0 6 1.7 7.1 4.6H24.9c1.1-2.9 3.8-4.6 7.1-4.6M58.5 32.8h-53c-.6 0-1 .4-1 1s.4 1 1 1h5.3V61c0 .6.4 1 1 1s1-.4 1-1V34.8h5.6v16c0 .6.4 1 1 1s1-.4 1-1v-16h23.2v16c0 .6.4 1 1 1s1-.4 1-1v-16h5.6V61c0 .6.4 1 1 1s1-.4 1-1V34.8h5.3c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconElegantTable1;
