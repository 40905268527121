import * as React from "react";
const IconQuoteAlt4 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M23.7 10.3H7.1c-2.8 0-5.1 2.3-5.1 5.1V32c0 2.8 2.3 5.1 5.1 5.1h1.6c1.1 0 2.1.6 2.7 1.5s.6 2.1.1 3.1l-2.3 4.6c-.8 1.6-.7 3.5.2 5s2.6 2.4 4.4 2.4H17c2 0 3.7-1.1 4.6-2.8l6.7-13.4c.4-.7.5-1.5.5-2.3V15.4c.1-2.8-2.3-5.1-5.1-5.1m3.2 24.9c0 .5-.1 1-.3 1.4L19.8 50c-.5 1.1-1.6 1.7-2.8 1.7h-3.2c-1.1 0-2.1-.6-2.7-1.5s-.6-2.1-.1-3.1l2.3-4.6c.8-1.6.7-3.5-.2-5s-2.6-2.4-4.4-2.4H7.1C5.4 35.1 4 33.7 4 32V15.4c0-1.7 1.4-3.1 3.1-3.1h16.6c1.7 0 3.1 1.4 3.1 3.1v19.8zM56.9 10.3H40.3c-2.8 0-5.1 2.3-5.1 5.1V32c0 2.8 2.3 5.1 5.1 5.1h1.6c1.1 0 2.1.6 2.7 1.5s.6 2.1.1 3.1l-2.3 4.6c-.8 1.6-.7 3.5.2 5s2.6 2.4 4.4 2.4h3.2c2 0 3.7-1.1 4.6-2.8l6.7-13.4c.4-.7.5-1.5.5-2.3V15.4c0-2.8-2.3-5.1-5.1-5.1M60 35.2c0 .5-.1 1-.3 1.4L53 50c-.5 1.1-1.6 1.7-2.8 1.7H47c-1.1 0-2.1-.6-2.7-1.5s-.6-2.1-.1-3.1l2.3-4.6c.8-1.6.7-3.5-.2-5s-2.6-2.4-4.4-2.4h-1.6c-1.7 0-3.1-1.4-3.1-3.1V15.4c0-1.7 1.4-3.1 3.1-3.1h16.6c1.7 0 3.1 1.4 3.1 3.1z" />
  </svg>
);
export default IconQuoteAlt4;
