import * as React from "react";
const IconPointerLeft = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M35.3 59.7h-4.8c-1.6 0-3.3 0-5.1-.7-2.1-.8-3.8-2.5-4.6-4.8-.5-1.5-.6-3-.3-4.4-2.1-2.1-3.1-5.2-2.4-8-1.5-1.7-2.2-4-2.1-6.2-1.6.1-3.2.2-4.9 0-2.8-.3-5.3-1.3-7-2.8-.5-.5-.9-1-1.3-1.5-1.2-1.8-1.5-4.1-.9-6.4.7-2.4 2.3-4.3 4.5-5.2 1.9-.8 3.8-1 5.8-1 3.7-.1 7.5-.1 11.2-.1-.3-.5-.7-1-.9-1.6-1.4-2.6-1.7-5.3-.9-7.7 1-3 3.9-5.1 6.9-5.1 2.7 0 4.8 1.7 6.5 3 3.3 2.5 7 5.1 11.8 8 .1.1.6.3 1.1.5 3 1.4 9.1 4.4 11.6 9.1 2.6 4.9 3.2 12.1 2.6 17-.6 5.8-2.8 10.3-6.3 13.2-4.3 3.6-10.4 4.3-15.1 4.5-1.8.2-3.6.2-5.4.2M19.2 31c.8 0 1.6.4 2 1.2.4.7.4 1.6-.1 2.3-1 1.5-.5 3.8 1 4.9.9.7 1.2 1.9.7 2.9-.9 1.6-.1 3.9 1.5 4.9.5.3.9.8 1.1 1.4s.1 1.2-.2 1.7c-.4.7-.5 1.6-.1 2.5.3 1 1.1 1.7 1.9 2.1 1 .4 2.3.4 3.5.4 3.3 0 6.6.1 9.9-.1 4-.2 9.1-.7 12.4-3.4 3.2-2.7 4.3-7 4.7-10.2.5-4.2 0-10.5-2.1-14.4-1.8-3.3-7.2-6-9.5-7.1-.7-.3-1.1-.5-1.4-.7-5-3-8.8-5.7-12.2-8.3-1.3-1-2.6-2-3.8-2s-2.2 1-2.5 2c-.4 1.2-.2 2.7.6 4.2.8 1.6 2.2 3 3.5 4.4.6.7.8 1.6.4 2.4s-1.2 1.3-2.1 1.3c-5.2-.1-10.6-.1-15.9 0-1.6 0-3 .1-4.1.6-.9.4-1.7 1.3-2 2.4-.3 1-.2 1.9.3 2.6.2.2.4.5.6.7 1 .9 2.6 1.5 4.4 1.7 1.5.1 3 0 4.6-.1.9-.3 1.9-.3 2.9-.3" />
  </svg>
);
export default IconPointerLeft;
