import * as React from "react";
const IconPhone = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M8.889 8A.894.894 0 0 0 8 8.889C8 26.073 21.927 40 39.111 40a.894.894 0 0 0 .889-.889V32.52a.894.894 0 0 0-.889-.889c-2.449 0-4.84-.395-7.057-1.127l-.01-.004a.88.88 0 0 0-.896.215l-4.67 4.67-.648-.332c-5.531-2.834-10.067-7.348-12.883-12.884l-.33-.647 4.67-4.67a.9.9 0 0 0 .226-.913 22.5 22.5 0 0 1-1.124-7.05A.894.894 0 0 0 15.5 8zM6 8.889A2.894 2.894 0 0 1 8.889 6H15.5a2.894 2.894 0 0 1 2.889 2.889c0 2.254.36 4.415 1.026 6.43l.002.006.002.007c.315 1.001.085 2.13-.719 2.934l-3.634 3.635A27.62 27.62 0 0 0 26.1 32.933l3.633-3.633a2.88 2.88 0 0 1 2.953-.694 20.6 20.6 0 0 0 6.424 1.024A2.894 2.894 0 0 1 42 32.519v6.592A2.894 2.894 0 0 1 39.111 42C20.822 42 6 27.178 6 8.889"
      clipRule="evenodd"
    />
  </svg>
);
export default IconPhone;
