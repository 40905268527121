import * as React from "react";
const IconCry = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M42.9 30.9c-1.9 2.2-2.9 4-2.9 5.2 0 2.3 1.9 4.2 4.2 4.2s4.2-1.9 4.2-4.2c0-1.2-1-3-2.9-5.2-.7-.7-2-.7-2.6 0m1.3 7.4c-1.2 0-2.2-1-2.2-2.2 0-.5.7-1.8 2.2-3.6 1.5 1.8 2.2 3.1 2.2 3.6-.1 1.2-1 2.2-2.2 2.2" />
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
    <path d="M32 41.9c-4.7 0-9.1 1.8-12.5 4.9-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3c3-2.8 7-4.4 11.2-4.4s8.1 1.6 11.2 4.4c.4.4 1 .4 1.4 0s.4-1 0-1.4c-3.6-3.2-8.1-4.9-12.7-4.9M21.8 26.2c1.9 0 3.4-1.5 3.4-3.4s-1.5-3.4-3.4-3.4-3.4 1.5-3.4 3.4 1.5 3.4 3.4 3.4M42.2 26.2c1.9 0 3.4-1.5 3.4-3.4s-1.5-3.4-3.4-3.4-3.4 1.5-3.4 3.4 1.5 3.4 3.4 3.4" />
  </svg>
);
export default IconCry;
