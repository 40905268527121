import React from "react";

/**
 * Displays an image with specified properties.
 *
 * @param {object} props - The properties of the image.
 * @param {string} props.className - Additional CSS classes for the image.
 * @param {number|string} props.width - The width of the image.
 * @param {number|string} props.height - The height of the image.
 * @param {string} props.src - The source URL of the image.
 * @param {string} props.alt - Alternative text for the image.
 * @param {'lazy'|'eager'|'auto'} [props.loading] - Loading strategy for the image.
 * @param {object} props.props - Additional properties to spread onto the image element.
 * @returns {JSX.Element} JSX markup for the image.
 */
export default function BringzzImage({
  // eslint-disable-next-line react/prop-types
  className,
  width,
  height,
  src,
  alt,
  loading,
  ...props
}) {
  return (
    <img
      src={src || "/image-upload.png"}
      alt={alt || "Image alt"}
      className={className}
      width={width}
      height={height}
      loading={loading}
      {...props}
    />
  );
}
