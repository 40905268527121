import * as React from "react";
const IconPortfolio2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.2 55.2H9.8c-2.6 0-4.8-2.1-5-4.7L3 26.3c-.1-1.4.4-2.8 1.3-3.8.9-.9 2.1-1.5 3.4-1.6v-7.1c0-2.8 2.3-5 5-5h7.7c.3 0 .5.1.7.3L26 14h25.1c2.8 0 5 2.3 5 5v1.9c1.3.1 2.5.7 3.4 1.6 1 1 1.4 2.4 1.3 3.8L59 50.6c0 2.6-2.2 4.6-4.8 4.6M8 22.9c-.9 0-1.6.3-2.2 1s-.9 1.4-.8 2.2l1.8 24.3c.1 1.6 1.4 2.8 3 2.8h44.4c1.6 0 2.9-1.2 3-2.8L59 26.1c.1-.9-.2-1.7-.8-2.3s-1.4-1-2.2-1H8zm1.7-2h44.5V19c0-1.7-1.4-3-3-3H25.7c-.3 0-.5-.1-.7-.3l-4.9-4.9h-7.3c-1.7 0-3 1.4-3 3v7.1z" />
  </svg>
);
export default IconPortfolio2;
