import * as React from "react";
const IconFactoryAlt3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.9 2.4H39.6c-2.8 0-5.1 2.3-5.1 5.1v11.3c0 1.3-.8 2.4-2 2.9L21 26.4V9.1c0-.6-.4-1-1-1s-1 .4-1 1v18l-9 3.6V14.1c0-.6-.4-1-1-1s-1 .4-1 1v17.4l-2.8 1.1c-2 .8-3.2 2.7-3.2 4.8v19c0 2.8 2.3 5.1 5.1 5.1h49.7c2.8 0 5.1-2.3 5.1-5.1V7.6a5 5 0 0 0-5-5.2M40.2 59.6V46.7c0-1.1.9-2 2-2h6.1c1.1 0 2 .9 2 2v12.9zM60 56.4c0 1.7-1.4 3.1-3.1 3.1h-4.5V46.7c0-2.2-1.8-4-4-4h-6.1c-2.2 0-4 1.8-4 4v12.9H7.1c-1.7 0-3.1-1.4-3.1-3.1v-19c0-1.3.8-2.4 2-2.9l27.2-10.8c2-.8 3.2-2.7 3.2-4.8V7.6c0-1.7 1.4-3.1 3.1-3.1h17.3c1.7 0 3.1 1.4 3.1 3.1v48.8z" />
  </svg>
);
export default IconFactoryAlt3;
