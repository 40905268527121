import React from "react";
import PropTypes from "prop-types";
import BringzzText from "../BringzzText";
import BringzzIcon from "../BringzzIcon";
import classnames from "classnames";
import { useTranslation } from 'react-i18next';

/**
 * @typedef BringzzPromoCardProps
 * @property {string} [className] - Additional CSS classes to apply to the promo card component.
 * @property {string} backgroundImage - URL of the background image for the promo card.
 * @property {React.ReactNode} title - Title text of the promo card.
 * @property {React.ReactNode} subTitle - Subtitle text of the promo card.
 */

const BringzzPromoCard = ({
  className,
  backgroundImage,
  title,
  subTitle,
  ...props
}) => {
  const { t } = useTranslation();


  return (
    <div className="px-4 py-8">
      <div
        className={classnames("relative overflow-hidden rounded-xl", className)}
        style={{ maxHeight: "530px" }}
      >
        {/* Overlay */}
        <div className="absolute w-full h-full bg-gradient-to-t from-black/25 to-transparent"></div>
        {/* Background Image */}
        <img
          className="w-full h-auto object-cover"
          src={backgroundImage}
          alt="promo background"
          style={{ maxHeight: "530px" }}
        />
        {/* Top part */}
        <div className="absolute font-inter top-0 left-0 mt-6 ml-6 bg-black/60 text-white/50 p-2 text-xs">
          {t("sponsored_label")}
        </div>
        {/* Shadow */}

        <div className="absolute bottom-0 pl-6 pr-8 py-8 w-full box-border">
          {/* Text part */}
          <div className="flex flex-col space-y-1">
            <BringzzText
              tag="h4"
              className={'font-bold text-white'}
            >
              {title}
            </BringzzText>
            <BringzzText
              tag="h4"
              className={"text-white font-normal"}
            >
              {subTitle}
            </BringzzText>
          </div>
          <div className="flex justify-between text-white mt-6">
            <BringzzIcon icon="IconBookmark" folder="LineIcons" size="18" />
            <BringzzIcon icon="IconUpload" folder="LineIcons" size="18" />
          </div>
        </div>
      </div>
    </div>
  );
};

BringzzPromoCard.propTypes = {
  className: PropTypes.string,
  backgroundImage: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired,
  subTitle: PropTypes.node,
};

export default BringzzPromoCard;
