import * as React from "react";
const IconShoppingMall = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 21.4H43.8v-5c0-5.3-4.3-9.6-9.6-9.6h-4.5c-5.3 0-9.6 4.3-9.6 9.6v5H6c-2.2 0-4 1.8-4 4v27.8c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V25.4c0-2.2-1.8-4-4-4m-35.8-5c0-4.2 3.4-7.6 7.6-7.6h4.5c4.2 0 7.6 3.4 7.6 7.6v13.4H22.2V16.4M4 53.2V25.4c0-1.1.9-2 2-2h14.2v31.8H6c-1.1 0-2-.9-2-2m18.2-21.5h19.6v23.5h-4.3v-9.5c0-2.2-1.8-4-4-4h-3c-2.2 0-4 1.8-4 4v9.5h-4.3zm6.3 23.5v-9.5c0-1.1.9-2 2-2h3c1.1 0 2 .9 2 2v9.5zm31.5-2c0 1.1-.9 2-2 2H43.8V23.4H58c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconShoppingMall;
