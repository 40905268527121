import * as React from "react";
const IconEbook = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M43.4 13.7H20.6c-.6 0-1 .4-1 1s.4 1 1 1h22.8c.6 0 1-.4 1-1s-.5-1-1-1M43.4 23.5H20.6c-.6 0-1 .4-1 1s.4 1 1 1h22.8c.6 0 1-.4 1-1s-.5-1-1-1M36.8 33.2H20.6c-.6 0-1 .4-1 1s.4 1 1 1h16.2c.6 0 1-.4 1-1s-.4-1-1-1M32 43.1c-2.9 0-5.3 2.4-5.3 5.3s2.4 5.3 5.3 5.3 5.3-2.4 5.3-5.3-2.4-5.3-5.3-5.3m0 8.7c-1.8 0-3.3-1.5-3.3-3.3s1.5-3.3 3.3-3.3 3.3 1.5 3.3 3.3-1.5 3.3-3.3 3.3" />
    <path d="M46.4 2H17.6c-5 0-9 4-9 9v42c0 5 4 9 9 9h28.8c5 0 9-4 9-9V11c0-5-4-9-9-9m7 51c0 3.9-3.1 7-7 7H17.6c-3.9 0-7-3.1-7-7V11c0-3.9 3.1-7 7-7h28.8c3.9 0 7 3.1 7 7z" />
  </svg>
);
export default IconEbook;
