import * as React from "react";
const IconSpinner9 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 62c-.6 0-1-.4-1-1s.4-1 1-1c3 0 5.9-.5 8.6-1.4.5-.2 1.1.1 1.3.6s-.1 1.1-.6 1.3Q36.8 62 32 62m-8.9-1.4h-.3c-3-1-5.8-2.4-8.4-4.3-.4-.3-.5-1-.2-1.4s1-.5 1.4-.2c2.4 1.7 5 3.1 7.8 4 .5.2.8.7.6 1.3-.1.3-.5.6-.9.6m26-4.1c-.3 0-.6-.1-.8-.4-.3-.4-.2-1.1.2-1.4 2.4-1.7 4.5-3.8 6.2-6.2.3-.4.9-.5 1.4-.2.4.3.5 1 .2 1.4-1.9 2.5-4.1 4.8-6.6 6.6-.2.1-.4.2-.6.2M8.6 50.1c-.3 0-.6-.1-.8-.4-1.9-2.6-3.3-5.4-4.3-8.4-.2-.5.1-1.1.6-1.3s1.1.1 1.3.6c.9 2.8 2.3 5.4 4 7.8.3.4.2 1.1-.2 1.4q-.3.3-.6.3m51-8.1h-.3c-.5-.2-.8-.7-.6-1.3.8-2.8 1.3-5.7 1.3-8.7v-.1c0-.6.4-1 1-1s1 .4 1 1v.1q0 4.8-1.5 9.3c-.1.4-.5.7-.9.7M3 33c-.6 0-1-.4-1-1 0-3.1.5-6.2 1.4-9.2.2-.5.7-.8 1.3-.6.5.2.8.7.6 1.3C4.5 26.2 4 29.1 4 32c0 .6-.4 1-1 1m56.6-9.1c-.4 0-.8-.3-1-.7-.9-2.8-2.3-5.4-4-7.8-.3-.4-.2-1.1.2-1.4s1.1-.2 1.4.2c1.9 2.5 3.3 5.4 4.3 8.3.2.5-.1 1.1-.6 1.3-.1.1-.2.1-.3.1M8.5 16c-.2 0-.4-.1-.6-.2-.4-.3-.5-.9-.2-1.4 1.8-2.6 4.1-4.8 6.6-6.6.4-.3 1.1-.2 1.4.2s.2 1.1-.2 1.4c-2.4 1.7-4.5 3.8-6.2 6.2-.2.3-.5.4-.8.4M49 9.5c-.2 0-.4-.1-.6-.2-2.4-1.7-5-3.1-7.8-4-.5-.2-.8-.7-.6-1.3s.7-.8 1.3-.6q4.5 1.5 8.4 4.2c.3.4.4 1 .1 1.5-.2.2-.5.4-.8.4M23 5.4c-.4 0-.8-.3-1-.7-.2-.5.1-1.1.6-1.3q4.5-1.5 9.3-1.5c.6 0 1 .4 1 1s-.4 1-1 1c-3 0-5.9.5-8.6 1.4-.1.1-.2.1-.3.1" />
  </svg>
);
export default IconSpinner9;
