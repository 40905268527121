import * as React from "react";
const IconCommentsCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56 9.2H8c-2.8 0-5 2.2-5 5v37.7c0 1.1.6 2.2 1.7 2.7.4.2.8.3 1.3.3.7 0 1.3-.2 1.9-.7l8.8-7.3H56c2.8 0 5-2.2 5-5V14.2c0-2.8-2.2-5-5-5m3 32.6c0 1.7-1.3 3-3 3H16.3c-.2 0-.5.1-.6.2l-9.1 7.6c-.4.3-.9.2-1 .1-.2 0-.6-.3-.6-.8V14.2c0-1.7 1.3-3 3-3h48c1.7 0 3 1.3 3 3z" />
    <path d="M15.8 22c-2.7 0-4.9 2.2-4.9 4.9s2.2 4.9 4.9 4.9 4.9-2.2 4.9-4.9c-.1-2.7-2.3-4.9-4.9-4.9m0 7.7c-1.6 0-2.9-1.3-2.9-2.9s1.3-2.9 2.9-2.9 2.9 1.3 2.9 2.9-1.4 2.9-2.9 2.9M32 22c-2.7 0-4.9 2.2-4.9 4.9s2.2 4.9 4.9 4.9 4.9-2.2 4.9-4.9S34.7 22 32 22m0 7.7c-1.6 0-2.9-1.3-2.9-2.9S30.4 24 32 24s2.9 1.3 2.9 2.9-1.3 2.8-2.9 2.8M48.2 22c-2.7 0-4.9 2.2-4.9 4.9s2.2 4.9 4.9 4.9 4.9-2.2 4.9-4.9-2.2-4.9-4.9-4.9m0 7.7c-1.6 0-2.9-1.3-2.9-2.9s1.3-2.9 2.9-2.9 2.9 1.3 2.9 2.9-1.3 2.9-2.9 2.9" />
  </svg>
);
export default IconCommentsCopy;
