import * as React from "react";
const IconGiftBox = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 3.5H6c-2.2 0-4 1.8-4 4v12.2c0 .6.4 1 1 1h2.6v35.8c0 2.2 1.8 4 4 4h44.9c2.2 0 4-1.8 4-4V20.7H61c.6 0 1-.4 1-1V7.5c0-2.2-1.8-4-4-4m-50.4 53V20.7H31v37.8H9.6c-1.1 0-2-.9-2-2m48.8 0c0 1.1-.9 2-2 2H33V20.7h23.4zM60 18.7H4V7.5c0-1.1.9-2 2-2h52c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconGiftBox;
