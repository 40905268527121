import * as React from "react";
const IconCopyright = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 65 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M46.4 39c-.5-.3-1.1-.1-1.4.4-2.6 4.4-7.4 7.2-12.6 7.2-8 0-14.6-6.5-14.6-14.6 0-8 6.5-14.6 14.6-14.6 5 0 9.5 2.5 12.2 6.6.3.5.9.6 1.4.3s.6-.9.3-1.4c-3.1-4.7-8.3-7.5-13.9-7.5-9.2 0-16.6 7.4-16.6 16.6s7.4 16.6 16.6 16.6c5.8 0 11.3-3.1 14.3-8.1.3-.6.2-1.2-.3-1.5" />
    <path d="M32.5 3c-16 0-29 13-29 29s13 29 29 29 29-13 29-29-13-29-29-29m0 56c-14.9 0-27-12.1-27-27s12.1-27 27-27 27 12.1 27 27-12.2 27-27 27" />
  </svg>
);
export default IconCopyright;
