import * as React from "react";
const IconPunchingBag = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M33 15.2V4h7.3c.6 0 1-.4 1-1s-.4-1-1-1H23.7c-.6 0-1 .4-1 1s.4 1 1 1H31v11.2C28.3 17.6 12 31.9 12 42c0 11 9 20 20 20s20-9 20-20c0-10.1-16.3-24.4-19-26.8M32 60c-9.9 0-18-8.1-18-18 0-8.8 14.7-22.1 18-25 3.3 2.9 18 16.2 18 25 0 9.9-8.1 18-18 18" />
  </svg>
);
export default IconPunchingBag;
