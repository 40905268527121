import React, { useState } from 'react';
import { BringzzButton, BringzzText } from "@bringzz/components";
import { useNavigation } from 'context/NavigationContext';
import {
	BringzzInput,
	BringzzDate,
	BringzzPageContainer,
	BringzzIcon,
	BringzzImageUpload,
	BringzzBottomDrawer,
	BringzzCheckbox
} from "@bringzz/components";
import { useLocation } from 'react-router-dom';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const NightDutyPage = () => {
	const { backPress } = useNavigation();

	const { navigate } = useNavigation();

	return (
		<BringzzPageContainer
			header={{
				left: (
					<BringzzIcon
						size='22'
						icon='IconChevronLeft'
						folder='LineIcons'
						className='cursor-pointer'
						onClick={backPress}
					/>
				)
			}}
			footer={
				<div className='px-4 mb-4'>
					<BringzzButton
						size='lg'
						className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
						onClick={() => {
							navigate('/register/pharmacy/bank-details');
						}}
					>
						Continue
					</BringzzButton>
				</div>
			}
			authenticated={false}
		>
			<div className='relative flex justify-center bg-magic-lilac/20'>
				<div className='relative bg-white w-full bg-cover bg-center flex flex-col'>
					{/* Content */}
					<div className='flex-grow flex flex-col py-8 px-4'>
						<div className='z-10 flex flex-col '>
							<div className='flex flex-col text-center space-y-6 mx-auto md:max-w-lg lg:max-w-xl xl:max-w-2xl'>
								<BringzzText tag='h1' className='text-midnight-blue'>
									Night Duty
								</BringzzText>
								<BringzzText
									tag='h4'
									className='text-midnight-blue/25 font-normal'
								>
									Lorem ipsum dolor sit amet consectetur. Pellentesque faucibus
									mattis fermentum sollicitudin turpis ut ut porta. Eget a sed
									quisque dignissim.
								</BringzzText>
							</div>
							<div className='space-y-6 mt-12'>
								<BringzzDate
									format='dd/MM/yyyy'
									mode={'day'}
									label='Next night duty'
									maxDate={new Date()}
								></BringzzDate>
								<BringzzDate
									format='dd/MM/yyyy'
									mode={'day'}
									label='Night duty after that'
									maxDate={new Date()}
								></BringzzDate>
								<BringzzDate
									format='dd/MM/yyyy'
									mode={'day'}
									label='Night duty after that'
									maxDate={new Date()}
								></BringzzDate>

								<div className='p-4 border rounded-md mt-8'>
									<BringzzText
										tag='h4'
										className='font-regular text-midnight-blue/40'
									>
										Something about the calculated night duty interval of every{' '}
										<span className='font-bold'>X days</span> (displayed after 2
										inputs). You can change this later.
									</BringzzText>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</BringzzPageContainer>
	);
};

export default NightDutyPage;
