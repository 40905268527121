import * as React from "react";
const IconMessageForward = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.2 43-5.1-5c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l4.7 4.6H42.2c-.6 0-1 .4-1 1s.4 1 1 1h17.1l-4.7 4.6c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3l5.1-5.1c.5-.5.8-1.2.8-2 .1-.7-.2-1.4-.7-1.9" />
    <path d="M34.1 50.5H7c-1.7 0-3-1.3-3-3V16.6c0-.4.1-.7.2-1.1l24.6 14.9c.8.5 1.8.8 2.8.8s1.9-.3 2.8-.8L59 15.5c.1.3.2.7.2 1v14.2c0 .6.4 1 1 1s1-.4 1-1V16.5c0-2.8-2.2-5-5-5H7c-2.8 0-5 2.3-5 5v30.9c0 2.8 2.2 5 5 5h27.1c.6 0 1-.4 1-1s-.4-.9-1-.9m22-37c.5 0 1.1.2 1.5.4L33.3 28.7c-1.1.6-2.4.6-3.4 0L5.5 14c.4-.3 1-.4 1.5-.4h49.1z" />
  </svg>
);
export default IconMessageForward;
