import * as React from "react";
const IconArrowLeftCircleCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M43.3 31H23l8.1-8.2c.4-.4.4-1 0-1.4s-1-.4-1.4 0L20 31.3c-.4.4-.4 1 0 1.4l9.7 9.9c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-8-8.2h20.2c.6 0 1-.4 1-1s-.4-1-1-1" />
    <path d="M32 3C16 3 3 16 3 32s13 29 29 29 29-13 29-29S48 3 32 3m0 56C17.1 59 5 46.9 5 32S17.1 5 32 5s27 12.1 27 27-12.1 27-27 27" />
  </svg>
);
export default IconArrowLeftCircleCopy;
