import * as React from "react";
const IconSpinnerSolidCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.6 23c-.2-.5-.7-.8-1.3-.7-.5.2-.8.7-.7 1.3.9 2.7 1.3 5.5 1.3 8.4 0 4.3-.9 8.4-2.8 12.2C52.5 54 42.8 60 32 60 16.6 60 4 47.4 4 32S16.6 4 32 4c4.3 0 8.4.9 12.2 2.8.5.2 1.1 0 1.3-.5s0-1.1-.5-1.3c-4-2-8.4-3-13-3C15.5 2 2 15.5 2 32s13.5 30 30 30c11.6 0 21.9-6.5 27-16.9 2-4.1 3-8.5 3-13.1 0-3.1-.5-6.1-1.4-9" />
  </svg>
);
export default IconSpinnerSolidCopy;
