import * as React from "react";
const IconMetaverse5 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 65 65"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M63.1 32c-.5-.7-11.2-17-30.2-17S3.2 31.3 2.7 32c-.2.3-.2.8 0 1.1.5.6 11.2 16.9 30.2 16.9s29.7-16.2 30.2-16.9c.2-.4.2-.8 0-1.1M32.9 48c-16 0-26.1-12.7-28.1-15.5C6.8 29.7 16.9 17 32.9 17S59 29.7 61 32.5C59 35.3 48.9 48 32.9 48" />
    <path d="M33.7 20.5c-.4-.4-1.1-.4-1.5 0l-8.5 10c-.2.2-.3.6-.2.9 0 .1.1.2.1.3l8.5 12.6c.2.3.5.4.8.4s.6-.2.8-.4l8.5-12.6c.1-.1.1-.2.1-.3.1-.3 0-.6-.2-.9zM32.9 42 27 33.4l5.5 2.2c.2.1.5.1.7 0l5.5-2.2zm0-8.5L26 30.8l6.9-8.1 6.9 8.1z" />
  </svg>
);
export default IconMetaverse5;
