import * as React from "react";
const IconMg = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 85.333 512 341.333"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M512 426.666V85.329H0v341.337z" />
    <path  d="M512 85.331H0v170.666h512z" />
    <path  d="M0 85.334h181.793v341.337H0z" />
  </svg>
);
export default IconMg;
