import * as React from "react";
const IconCarAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.8 20.3c-.3-.5-.9-.6-1.4-.3l-4.3 3-.8-9.2c-.4-4.8-4.8-8.6-10-8.6H19.5c-5.2 0-9.7 3.8-10.1 8.6l-.9 9.6-5-3.3c-.5-.3-1.1-.2-1.4.3s-.2 1.1.3 1.4l4.5 3c-1.6.8-2.7 2.5-2.7 4.4v25.6c0 2.2 1.8 4 4 4h3.3c2.2 0 4-1.8 4-4V49h33.7v5.8c0 2.2 1.8 4 4 4h3.3c2.2 0 4-1.8 4-4V29.2c0-2.1-1.3-3.9-3.1-4.6l4.2-2.8c.4-.4.5-1 .2-1.5M11.4 14c.3-3.8 3.9-6.7 8.1-6.7h25.8c4.2 0 7.8 3 8.1 6.7l.9 10.2H10.5zm2.1 40.8c0 1.1-.9 2-2 2H8.3c-1.1 0-2-.9-2-2V49h7.3v5.8zm28.8-7.9h-4.6v-4.1c0-.6-.4-1-1-1s-1 .4-1 1v4.1h-6.8v-4.1c0-.6-.4-1-1-1s-1 .4-1 1v4.1h-4.6v-7.8c0-1.6 1.3-2.9 2.9-2.9h14.2c1.6 0 2.9 1.3 2.9 2.9zm16.2 7.9c0 1.1-.9 2-2 2h-3.3c-1.1 0-2-.9-2-2V49h7.3zm0-25.6V47H44.3v-7.8c0-2.7-2.2-4.9-4.9-4.9H25.3c-2.7 0-4.9 2.2-4.9 4.9V47H6.3V29.2c0-1.7 1.3-3 3-3h46.1c1.7 0 3.1 1.3 3.1 3" />
    <path d="M13.5 37.3h-1.3c-.6 0-1 .4-1 1s.4 1 1 1h1.3c.6 0 1-.4 1-1s-.4-1-1-1M52.3 37.3H51c-.6 0-1 .4-1 1s.4 1 1 1h1.3c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconCarAlt2;
