import * as React from "react";
const IconTankTop = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.1 36.5 47.2 17.2V3c0-.6-.4-1-1-1s-1 .4-1 1v14L32 35.4 18.8 17V3c0-.6-.4-1-1-1s-1 .4-1 1v14.2L2.9 36.5c-.1.2-.2.4-.2.6v20c.1 2.7 2.3 4.9 5 4.9h48.7c2.7 0 4.9-2.2 4.9-4.9v-20c-.1-.2-.1-.4-.2-.6M17.9 19.2l13.3 18.5c.4.5 1.2.5 1.6 0l13.3-18.5 13.1 18.3v12.1H4.8V37.4zM56.3 60H7.7c-1.6 0-2.9-1.3-2.9-2.9v-5.5h54.5v5.5c-.1 1.6-1.4 2.9-3 2.9" />
  </svg>
);
export default IconTankTop;
