import * as React from "react";
const IconSpeechBubble26 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56 19.6h-4.8V7c0-2.8-2.2-5-5-5H8C5.2 2 3 4.2 3 7v20.6c0 2.8 2.2 5 5 5h4.8v12.6c0 2.8 2.2 5 5 5h6.6V61q0 .6.6.9c.1.1.3.1.4.1.2 0 .5-.1.7-.2l13.5-11.5H56c2.8 0 5-2.2 5-5V24.6c0-2.7-2.2-5-5-5m-48 11c-1.7 0-3-1.3-3-3V7c0-1.7 1.3-3 3-3h38.2c1.7 0 3 1.3 3 3v13.4c0 .1-.1.2-.1.3v9.9h-4.6c-.6 0-1 .4-1 1v7.2l-9.3-8c-.2-.2-.4-.2-.7-.2H8m51 14.6c0 1.7-1.3 3-3 3H39.2c-.2 0-.5.1-.7.2L26.4 58.8v-9.6c0-.6-.4-1-1-1h-7.6c-1.7 0-3-1.3-3-3V32.6h18.4l10.7 9.1c.2.2.4.2.7.2.1 0 .3 0 .4-.1q.6-.3.6-.9v-8.4h4.6c.6 0 1-.4 1-1v-9.9H56c1.7 0 3 1.3 3 3z" />
    <path d="M15.4 17.7c0-1.1-.9-2-2-2h-.1c-1.1 0-2 .9-2 2s.9 2 2 2h.1c1.1 0 2-.9 2-2M26.6 19.7h.1c1.1 0 2-.9 2-2s-.9-2-2-2h-.1c-1.1 0-2 .9-2 2s.9 2 2 2M39.9 19.7h.1c1.1 0 2-.9 2-2s-.9-2-2-2h-.1c-1.1 0-2 .9-2 2s.9 2 2 2" />
  </svg>
);
export default IconSpeechBubble26;
