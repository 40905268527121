import * as React from "react";
const IconChestOfDrawers5 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 4.4H7c-2.8 0-5 2.2-5 5v40.2c0 2.8 2.2 5 5 5h.7v4.1c0 .6.4 1 1 1s1-.4 1-1v-4.1h43.1v4.1c0 .6.4 1 1 1s1-.4 1-1v-4.1H57c2.8 0 5-2.2 5-5V9.4c0-2.8-2.2-5-5-5m-34.2 2h15.1v45.9c0 .1 0 .2.1.4H22.7c0-.1.1-.2.1-.4zm17.1 15.5H60v14.2H39.9zM60 9.4V20H39.9V6.4H57c1.7 0 3 1.3 3 3M4 49.6V9.4c0-1.7 1.3-3 3-3h13.8v45.9c0 .1 0 .2.1.4H7c-1.7-.1-3-1.5-3-3.1m53 3H39.9c0-.1.1-.2.1-.4V38.1h20v11.4c0 1.7-1.3 3.1-3 3.1" />
    <path d="M30.7 30c.6 0 1-.4 1-1v-2.1c0-.6-.4-1-1-1s-1 .4-1 1V29c0 .6.5 1 1 1M12.6 25.9c-.6 0-1 .4-1 1V29c0 .6.4 1 1 1s1-.4 1-1v-2.1c0-.5-.5-1-1-1" />
  </svg>
);
export default IconChestOfDrawers5;
