import React, { useEffect, useState } from 'react';
import { BringzzBoxSlider, BringzzButton, BringzzOrder, BringzzText } from "@bringzz/components";
import { useNavigation } from 'context/NavigationContext';
import {
    BringzzPageContainer,
    BringzzIcon,
    BringzzOrderCard,
    BringzzAccordion
} from "@bringzz/components";
import useRequest from 'hooks/useRequest';

const OrdersPage = () => {
    const { backPress } = useNavigation();

    const { navigate } = useNavigation();
    const { sendRequest, loading } = useRequest();
    const [orders, setOrders] = useState([])

    const fetchOrders = async () => {
        await sendRequest({
            method: 'GET',
            url: `/orders`,
        }).then((response) => {
            if (response.status === 'success') {
                setOrders(response.data)
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    useEffect(() => {
        fetchOrders()
    }, [])

    return (
        <BringzzPageContainer
            className="bg-white pb-44"
            header={{
                left: (
                    <BringzzIcon
                        size='22'
                        icon='IconChevronLeft'
                        folder='LineIcons'
                        className='cursor-pointer'
                        onClick={backPress}
                    />
                ),
                title: "My Orders"
            }}
            footer={
                <div className='h-20 p-5 flex justify-center bg-white'>
                    <BringzzButton
                        size='lg'
                        className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
                        onClick={() => navigate("/settings/pharmacy/orders/place")}
                    >
                        Scan now
                    </BringzzButton>
                </div>
            }
            authenticated={false}
            footerHover={true}
        >
            <div>
                <div className='px-6 divide-y '>
                    {[{
                        status: "accepted",
                        label: "Accepted"
                    },
                    {
                        status: "inProgress",
                        label: "In Progress"
                    },
                    {
                        status: "ready",
                        label: "Ready"
                    }
                    ].map((statusObj, indx) => {
                        return orders[statusObj.status] && <div className='' key={indx}>
                            <BringzzAccordion
                                separated={false}
                                title={statusObj.label}
                                defaultValue={true}
                            >
                                <BringzzBoxSlider>
                                    {orders[statusObj.status].map((order, index) => {
                                        return <BringzzOrder
                                            key={index}
                                            mode={statusObj.status === 'accepted' ? 1 : 2}
                                            orderDetails={{
                                                status: statusObj.status,
                                                avatar: "",
                                                date: new Date(order.orderdate),
                                                name: order.customerName
                                            }}
                                        >
                                        </BringzzOrder>
                                    })}
                                </BringzzBoxSlider>
                            </BringzzAccordion>
                        </div>
                    }
                    )}
                </div>
                {orders.completed &&
                    <div className='bg-sand px-6 pb-8'>
                        <div className='flex justify-between items-center'>
                            <BringzzText tag='h3' className={"my-4"}>Recently Completed </BringzzText>
                            <BringzzText tag='h5' className={"text-magic-lilac cursor-pointer"} onClick={() => navigate("/pharmacy/orders/completed")}>Show All</BringzzText>
                        </div>
                        <div className='space-y-4'>
                            {orders.completed.map(((order, index) => {
                                return <div key={index}>
                                    <BringzzOrderCard
                                        orderDetails={{
                                            name: order.customerName,
                                            date: new Date(order.orderdate),
                                            status: 'Completed',
                                            price: order.price,
                                            itemsCount: order.products.length
                                        }}
                                        onClick={() => navigate('/pharmacy/orders/view')}
                                    ></BringzzOrderCard>
                                </div>
                            }))}
                        </div>
                    </div>
                }
            </div>
        </BringzzPageContainer >
    );
};

export default OrdersPage;
