import * as React from "react";
const IconCenterAlign2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M3 33h57.8c.6 0 1-.4 1-1s-.4-1-1-1H3c-.6 0-1 .4-1 1s.4 1 1 1M31.9 22.2c.6 0 1-.4 1-1v-9.7c0-.6-.4-1-1-1s-1 .4-1 1v9.7c0 .5.5 1 1 1M31.9 41.8c-.6 0-1 .4-1 1v9.7c0 .6.4 1 1 1s1-.4 1-1v-9.7c0-.5-.4-1-1-1M50.7 4.1h5.8c1.8 0 3.3 1.5 3.3 3.3v5.8c0 .6.4 1 1 1s1-.4 1-1V7.4c0-2.9-2.4-5.3-5.3-5.3h-5.8c-.6 0-1 .4-1 1s.4 1 1 1M3 14.2c.6 0 1-.4 1-1V7.4c0-1.8 1.5-3.3 3.3-3.3h5.8c.6 0 1-.4 1-1s-.4-1-1-1H7.3C4.4 2.1 2 4.5 2 7.4v5.8c0 .6.4 1 1 1M13.1 59.9H7.3c-1.8 0-3.3-1.5-3.3-3.3v-5.8c0-.6-.4-1-1-1s-1 .4-1 1v5.8c0 2.9 2.4 5.3 5.3 5.3h5.8c.6 0 1-.4 1-1s-.4-1-1-1M60.8 49.8c-.6 0-1 .4-1 1v5.8c0 1.8-1.5 3.3-3.3 3.3h-5.8c-.6 0-1 .4-1 1s.4 1 1 1h5.8c2.9 0 5.3-2.4 5.3-5.3v-5.8c0-.6-.4-1-1-1M22 59.9h-1.4c-.6 0-1 .4-1 1s.4 1 1 1H22c.6 0 1-.4 1-1s-.5-1-1-1M43.7 59.9h-1.4c-.6 0-1 .4-1 1s.4 1 1 1h1.4c.6 0 1-.4 1-1s-.5-1-1-1M20.5 4.1H22c.6 0 1-.4 1-1s-.4-1-1-1h-1.4c-.6 0-1 .4-1 1s.4 1 .9 1M42.2 4.1h1.4c.6 0 1-.4 1-1s-.4-1-1-1h-1.4c-.6 0-1 .4-1 1s.5 1 1 1M61 19.4c-.6 0-1 .4-1 1v1.4c0 .6.4 1 1 1s1-.4 1-1v-1.4c0-.5-.4-1-1-1M61 41.1c-.6 0-1 .4-1 1v1.4c0 .6.4 1 1 1s1-.4 1-1v-1.4c0-.5-.4-1-1-1M3.2 19.4c-.6 0-1 .4-1 1v1.4c0 .6.4 1 1 1s1-.4 1-1v-1.4c0-.5-.5-1-1-1M2.2 42.1v1.4c0 .6.4 1 1 1s1-.4 1-1v-1.4c0-.6-.4-1-1-1s-1 .5-1 1" />
  </svg>
);
export default IconCenterAlign2;
