import * as React from "react";
const IconSquarePollVertical = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56 3H8C5.2 3 3 5.2 3 8v48c0 2.8 2.2 5 5 5h48c2.8 0 5-2.2 5-5V8c0-2.8-2.2-5-5-5m3 53c0 1.7-1.3 3-3 3H8c-1.7 0-3-1.3-3-3V8c0-1.7 1.3-3 3-3h48c1.7 0 3 1.3 3 3z" />
    <path d="M16.4 34.1c-.6 0-1 .4-1 1v15.3c0 .6.4 1 1 1s1-.4 1-1V35.1c0-.5-.5-1-1-1M32 18.5c-.6 0-1 .4-1 1v31c0 .6.4 1 1 1s1-.4 1-1v-31c0-.6-.4-1-1-1M47.6 27c-.6 0-1 .4-1 1v22.5c0 .6.4 1 1 1s1-.4 1-1V28c0-.6-.4-1-1-1" />
  </svg>
);
export default IconSquarePollVertical;
