import * as React from "react";
const IconSpellcheckCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M24 29.2c-.3-.7-.8-.9-1.4-.9h-6.2c-.7 0-1.3.3-1.4.9L3.1 56.3c-.5 1.1.3 2.2 1.4 2.2h5.1c.7 0 1.2-.5 1.4-.9l1.7-4.1h12.8l1.6 4.1c.1.6.8.9 1.4.9h5.4c1.4-.1 2.2-1.2 1.7-2.2zm-8.9 16.7L19.3 35l4.2 10.9zM61.7 4.8c-.4-.4-1-.4-1.4 0L37.7 27.1l-6.3-6.2c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l6.5 6.4c.3.3.8.5 1.2.5s.9-.2 1.2-.5L61.7 6.2c.4-.4.4-1 0-1.4" />
  </svg>
);
export default IconSpellcheckCopy;
