import * as React from "react";
const IconKeyAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M33 33.1V3c0-.6-.4-1-1-1s-1 .4-1 1v5.8h-8.5c-.6 0-1 .4-1 1s.4 1 1 1H31v22.3c-7.5.5-13.5 6.8-13.5 14.4C17.5 55.5 24 62 32 62s14.5-6.5 14.5-14.5c0-7.6-6-13.9-13.5-14.4M32 60c-6.9 0-12.5-5.6-12.5-12.5S25.1 35.1 32 35.1s12.5 5.6 12.5 12.5S38.9 60 32 60" />
  </svg>
);
export default IconKeyAlt2;
