import * as React from "react";
const IconTicketCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.4 24.8c.9 0 1.6-.8 1.6-1.7v-9c0-.9-.7-1.6-1.6-1.6H3.6c-.9 0-1.6.7-1.6 1.6v9.2c0 1 .7 1.7 1.5 1.7 3.6.3 6.4 3.4 6.4 7.1 0 3.8-2.7 6.8-6.3 7-.9 0-1.6.7-1.6 1.6v9c0 .9.7 1.6 1.6 1.6h56.7c.9 0 1.7-.7 1.7-1.7v-9c0-.9-.7-1.7-1.5-1.7-3.6-.3-6.4-3.4-6.4-7.1 0-3.6 2.8-6.7 6.3-7M52.1 32c0 4.6 3.4 8.5 7.9 9.1v8.4H30.1c.1-.2.2-.4.2-.6v-6.3c0-.6-.4-1-1-1s-1 .4-1 1v6.3c0 .2.1.5.2.6H4v-8.3c4.5-.5 7.9-4.3 7.9-9 0-4.6-3.4-8.5-7.9-9.1v-8.5h24.6c-.1.2-.2.4-.2.6v6.3c0 .6.4 1 1 1s1-.4 1-1v-6.3c0-.2-.1-.5-.2-.6H60v8.3c-4.5.5-7.9 4.4-7.9 9.1" />
    <path d="M29.4 28.4c-.6 0-1 .4-1 1v5.3c0 .6.4 1 1 1s1-.4 1-1v-5.3c0-.6-.5-1-1-1" />
  </svg>
);
export default IconTicketCopy;
