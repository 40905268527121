import * as React from "react";
const IconMessageLove = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.9 9.9H7.1C4.3 9.9 2 12.2 2 15v31.4c0 2.8 2.3 5.1 5.1 5.1h27.6c.6 0 1-.4 1-1s-.4-1-1-1H7.1c-1.7 0-3.1-1.4-3.1-3.1V15c0-.4.1-.8.2-1.1l25 15.2c.9.5 1.8.8 2.8.8s1.9-.3 2.8-.8l25-15.2c.1.3.2.7.2 1.1v14.4c0 .6.4 1 1 1s1-.4 1-1V15c0-2.8-2.3-5.1-5.1-5.1M33.8 27.4c-1.1.7-2.4.7-3.5 0l-24.7-15c.5-.3 1-.4 1.6-.4H57c.6 0 1.1.2 1.6.4z" />
    <path d="M54.4 35.7c-1.4 0-2.7.5-3.8 1.4-1.1-.9-2.4-1.4-3.8-1.4-1.6 0-3.1.6-4.2 1.8-2.3 2.4-2.3 6.3 0 8.7l7.3 7.6c.2.2.4.3.7.3.2 0 .5-.1.7-.3l7.3-7.6c1.1-1.2 1.7-2.7 1.7-4.3s-.6-3.2-1.7-4.3c-1.1-1.3-2.6-1.9-4.2-1.9m2.8 9.1-6.6 6.8-6.6-6.8c-1.6-1.6-1.6-4.3 0-5.9.7-.8 1.7-1.2 2.8-1.2 1 0 2 .4 2.8 1.2l.3.3c.2.2.4.3.7.3s.5-.1.7-.3l.3-.3c1.5-1.5 4.1-1.5 5.5 0 .8.8 1.2 1.8 1.2 2.9s-.4 2.2-1.1 3" />
  </svg>
);
export default IconMessageLove;
