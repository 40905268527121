import * as React from "react";
const IconMalariaTesting = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M23 6a.5.5 0 0 1 .5.5v2.728c.28.264.523.745.698 1.366L25 9.793V8.5a.5.5 0 0 1 .146-.354l1.5-1.5a.5.5 0 1 1 .707.708L26 8.707V10a.5.5 0 0 1-.146.354l-1.425 1.424.03.281 4.803.798c.157.714-.093 2.143-2.346 2.143-1.303 0-2.04-.67-2.431-1.427q-.018.319-.053.62l2.355 1.647a.5.5 0 0 1 .177.596l-.317.792 1.045.435a.5.5 0 1 1-.384.924l-1.5-.625a.5.5 0 0 1-.272-.648l.353-.882-1.655-1.158a5 5 0 0 1-.212.653l.425.85A.5.5 0 0 1 24.5 17v.992l.434.76a.5.5 0 0 1-.868.496l-.5-.875a.5.5 0 0 1-.066-.248v-1.007l-.123-.245A.66.66 0 0 1 23 17a.64.64 0 0 1-.324-.094l-.176.44v.779a.5.5 0 0 1-.066.248l-.5.875a.5.5 0 0 1-.868-.496l.434-.76v-.742q0-.097.036-.186l.448-1.12a5 5 0 0 1-.218-.67l-1.655 1.158.353.882a.5.5 0 0 1-.272.648l-1.5.625a.5.5 0 1 1-.384-.924l1.045-.435-.317-.792a.5.5 0 0 1 .177-.596l2.355-1.648c-.04-.334-.062-.688-.067-1.054-.318.916-1.068 1.862-2.617 1.862-2.253 0-2.503-1.429-2.346-2.143l5.007-.831.026-.248-1.425-1.424A.5.5 0 0 1 20 10V8.707l-1.354-1.353a.5.5 0 0 1 .707-.708l1.5 1.5A.5.5 0 0 1 21 8.5v1.293l.802.801c.175-.621.418-1.102.698-1.366V6.5A.5.5 0 0 1 23 6m-4.116 8c-.75 0-1.069-.189-1.204-.319l2.679-.445-.05.08c-.25.367-.652.684-1.425.684m8.032 0c.75 0 1.069-.189 1.204-.319l-2.679-.445.05.08c.25.367.652.684 1.425.684M23.5 13c0 1.016-.156 1.892-.376 2.477a3 3 0 0 1-.124.287 3 3 0 0 1-.124-.287c-.22-.585-.376-1.46-.376-2.477s.156-1.892.376-2.477q.064-.17.124-.287.06.117.124.287c.22.585.376 1.46.376 2.477"
      clipRule="evenodd"
    />
    <path
      
      d="M38 25a2 2 0 1 1 0 4 2 2 0 0 1 0-4M31 27a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0"
    />
    <path
      
      fillRule="evenodd"
      d="M27 24a3 3 0 1 1 0 6H11a3 3 0 1 1 0-6zm-14 2h-2a1 1 0 1 0 0 2h2zm12 0h2a1 1 0 1 1 0 2h-2zm-10 0h3v2h-3zm8 0h-3v2h3z"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M4 32a2 2 0 0 0 2 2h36a2 2 0 0 0 2-2V22a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2zm2-10v10h36V22z"
      clipRule="evenodd"
    />
    <path
      
      d="M7.35 14.105c.238-.105.495-.131.743-.079s.485.185.677.39a1 1 0 0 0 1.46-1.368 3.3 3.3 0 0 0-1.722-.978 3.2 3.2 0 0 0-1.966.206 3.4 3.4 0 0 0-1.495 1.304A3.6 3.6 0 0 0 4.5 15.5c0 .678.188 1.346.547 1.92s.877 1.031 1.495 1.304a3.2 3.2 0 0 0 1.966.206 3.3 3.3 0 0 0 1.722-.978 1 1 0 1 0-1.46-1.368c-.192.205-.43.337-.677.39a1.2 1.2 0 0 1-.742-.079 1.4 1.4 0 0 1-.609-.537A1.6 1.6 0 0 1 6.5 15.5c0-.31.087-.61.242-.858.156-.248.37-.432.609-.537"
    />
    <path
      
      fillRule="evenodd"
      d="M12.5 36a1 1 0 0 1 1-1H16a2.5 2.5 0 0 1 0 5h-1.5v1a1 1 0 1 1-2 0zm2 2H16a.5.5 0 0 0 0-1h-1.5zM33.5 12a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1H36a2.5 2.5 0 0 0 0-5zm2.5 3h-1.5v-1H36a.5.5 0 0 1 0 1"
      clipRule="evenodd"
    />
    <path
      
      d="M22 42a1 1 0 0 0 .936-.649l1.5-4a1 1 0 1 0-1.872-.702L22 38.152l-.564-1.503a1 1 0 0 0-1.872.702l1.5 4A1 1 0 0 0 22 42M41.5 18v-2a1 1 0 0 0 0-2 1 1 0 0 0 0-2c-.726 0-1.276.325-1.611.79A2.1 2.1 0 0 0 39.5 14a1 1 0 1 0 0 2v2a1 1 0 1 0 2 0"
    />
  </svg>
);
export default IconMalariaTesting;
