import * as React from "react";
const IconShiftLeftCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M7.1 3c-.6 0-1 .4-1 1v56c0 .6.4 1 1 1s1-.4 1-1V4c0-.6-.5-1-1-1M56.9 33.5H24l9-9.1c.4-.4.4-1 0-1.4s-1-.4-1.4 0L20.9 33.7c-.4.4-.4 1 0 1.4l10.6 10.8c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-8.9-9h32.9c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconShiftLeftCopy;
