import * as React from "react";
const IconDemolition = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <circle cx={17.6} cy={50.2} r={2} />
    <circle cx={27.1} cy={50.2} r={2} />
    <circle cx={36.6} cy={50.2} r={2} />
    <path d="M55.7 27.6c-.4 0-.9 0-1.3.1l-5.1-8.5c1.7-1.5 2.9-3.7 2.9-6.1 0-4.4-3.6-8.1-8.1-8.1S36 8.6 36 13.1c0 1 .2 2 .6 2.9l-10.4 6.9c-.1-.4-.5-.7-.9-.7h-5.9c-2.5 0-4.6 2.1-4.6 4.6v14.5c0 .1 0 .2.1.3H9.4C5.3 41.6 2 44.9 2 49v2.6C2 55.7 5.3 59 9.4 59h38.2c4.1 0 7.4-3.3 7.4-7.4V49c0-4.1-3.3-7.4-7.4-7.4h-16l-1.8-6.3c.1 0 .2-.1.3-.2L45.3 21c.8-.1 1.6-.4 2.3-.7l4.9 8.2c-1.8 1.1-3.1 3.1-3.1 5.4 0 3.5 2.8 6.3 6.3 6.3s6.3-2.8 6.3-6.3-2.8-6.3-6.3-6.3M44.2 7c3.3 0 6.1 2.7 6.1 6.1 0 3.3-2.7 6.1-6.1 6.1s-6.1-2.7-6.1-6.1S40.8 7 44.2 7M53 49v2.6c0 3-2.4 5.4-5.4 5.4H9.4c-3 0-5.4-2.4-5.4-5.4V49c0-3 2.4-5.4 5.4-5.4h38.2c3 0 5.4 2.4 5.4 5.4m-36.3-7.4c0-.1.1-.2.1-.3V26.8c0-1.4 1.2-2.6 2.6-2.6h5.1l.1.4 4.8 17zm12.5-8.4-2.4-8.4 10.8-7.1c1.2 1.6 2.9 2.7 4.8 3.2zm26.5 4.9c-2.4 0-4.3-1.9-4.3-4.3s1.9-4.3 4.3-4.3 4.3 1.9 4.3 4.3-1.9 4.3-4.3 4.3" />
  </svg>
);
export default IconDemolition;
