import * as React from "react";
const IconFlood = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 65 65"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M16.3 35.7c.6 0 1-.4 1-1v-15L32.5 7.5l15.2 12.1v15c0 .6.4 1 1 1s1-.4 1-1V19.2c0-.3-.1-.6-.4-.8L33.1 5.5c-.4-.3-.9-.3-1.2 0L15.7 18.4c-.2.2-.4.5-.4.8v15.5c0 .5.4 1 1 1" />
    <path d="M36.7 35.7c.6 0 1-.4 1-1v-11c0-.6-.4-1-1-1h-8.4c-.6 0-1 .4-1 1v11c0 .6.4 1 1 1s1-.4 1-1v-10h6.4v10c0 .5.5 1 1 1M4.5 47.4l5.6-3.1c2.1-1.2 4.7-1.2 6.8 0l1.7.9c2.7 1.5 6 1.5 8.8 0l1.7-.9c2.1-1.2 4.7-1.2 6.8 0l1.7.9c2.7 1.5 6 1.5 8.8 0l1.7-.9c2.1-1.2 4.7-1.2 6.8 0l5.6 3.1c.2.1.3.1.5.1.4 0 .7-.2.9-.5.3-.5.1-1.1-.4-1.4l-5.6-3.1c-2.7-1.5-6-1.5-8.8 0l-1.7.9c-2.1 1.2-4.7 1.2-6.8 0l-1.7-.9c-2.7-1.5-6-1.5-8.8 0l-1.7.9c-2.1 1.2-4.7 1.2-6.8 0l-1.7-.9c-2.7-1.5-6-1.5-8.8 0l-5.6 3.1c-.5.3-.6.9-.4 1.4.3.5.9.6 1.4.4M61.5 57.9l-5.6-3.1c-2.7-1.5-6-1.5-8.8 0l-1.7.9c-2.1 1.2-4.7 1.2-6.8 0l-1.7-.9c-2.7-1.5-6-1.5-8.8 0l-1.7.9c-2.1 1.2-4.7 1.2-6.8 0l-1.7-.9c-2.7-1.5-6-1.5-8.8 0l-5.6 3.1c-.5.3-.7.9-.4 1.4s.9.7 1.4.4l5.6-3.1c2.1-1.2 4.7-1.2 6.8 0l1.7.9c2.7 1.5 6 1.5 8.8 0l1.7-.9c2.1-1.2 4.7-1.2 6.8 0l1.7.9c2.7 1.5 6 1.5 8.8 0l1.7-.9c2.1-1.2 4.7-1.2 6.8 0l5.6 3.1c.2.1.3.1.5.1.4 0 .7-.2.9-.5.2-.5.1-1.1-.4-1.4" />
  </svg>
);
export default IconFlood;
