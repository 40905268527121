import * as React from "react";
const IconMoveTask = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60 31H32.6c-.6 0-1 .4-1 1s.4 1 1 1H60c.6 0 1-.4 1-1s-.4-1-1-1M60 47.7H32.8c-.6 0-1 .4-1 1s.4 1 1 1H60c.6 0 1-.4 1-1s-.4-1-1-1M32.8 16.3H60c.6 0 1-.4 1-1s-.4-1-1-1H32.8c-.6 0-1 .4-1 1s.4 1 1 1M18.4 30.7 6.7 22.2c-.7-.5-1.6-.6-2.4-.2-.8.5-1.3 1.3-1.3 2.1V41c0 .9.5 1.7 1.3 2.1.3.2.7.3 1.1.3.5 0 1-.1 1.4-.4l11.7-8.4c.6-.4 1-1.1 1-1.9-.1-.9-.5-1.6-1.1-2m-1.2 2.1L5.5 41.3c-.1.1-.3.1-.4 0 0-.1-.1-.1-.1-.3V24.1c0-.2.1-.3.2-.3h.2c.1 0 .1 0 .2.1l11.7 8.4c.1.1.1.2.1.3 0 0 0 .1-.2.2" />
  </svg>
);
export default IconMoveTask;
