import * as React from "react";
const IconEthernet = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60 27.9h-7.2v-6.5c0-.6-.4-1-1-1h-7.6V13c0-.6-.4-1-1-1H20.8c-.6 0-1 .4-1 1v7.4h-7.6c-.6 0-1 .4-1 1v6.5H4c-.6 0-1 .4-1 1V47c0 2.8 2.2 5 5 5h48c2.8 0 5-2.2 5-5V28.9c0-.6-.4-1-1-1M59 47c0 1.7-1.3 3-3 3h-8.6V38.2c0-.6-.4-1-1-1s-1 .4-1 1V50H33V38.2c0-.6-.4-1-1-1s-1 .4-1 1V50H18.6V38.2c0-.6-.4-1-1-1s-1 .4-1 1V50H8c-1.7 0-3-1.3-3-3V29.9h7.2c.6 0 1-.4 1-1v-6.5h7.6c.6 0 1-.4 1-1V14h20.4v7.4c0 .6.4 1 1 1h7.6v6.5c0 .6.4 1 1 1H59z" />
  </svg>
);
export default IconEthernet;
