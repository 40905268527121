import * as React from "react";
const IconCashApp = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 65 64"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M55.518 1.1c-3.5-1.1-6.7-1.1-13.1-1.1h-19.4c-6.4 0-9.6 0-13.1 1.1a13.6 13.6 0 0 0-8.1 8.1c-1.1 3.46-1.1 6.68-1.1 13.1v19.39c0 6.41 0 9.61 1.1 13.11a13.6 13.6 0 0 0 8.1 8.1c3.46 1.1 6.68 1.1 13.1 1.1h19.4c6.4 0 9.6 0 13.1-1.12a13.6 13.6 0 0 0 8.1-8.1c1.1-3.46 1.1-6.68 1.1-13.1V22.31c0-6.41 0-9.61-1.1-13.11a13.6 13.6 0 0 0-8.1-8.1m-10.53 22.7c-.47.5-1.3.5-1.8 0l.02.02c-2.11-1.9-5.34-3.29-8.7-3.29-2.64 0-5.27.96-5.27 3.38 0 2.303 2.411 3.186 5.353 4.263l.727.267c5.7 1.97 10.4 4.39 10.4 10.13 0 6.23-4.72 10.5-12.469 11.02l-.7 3.39a1.32 1.32 0 0 1-1.3 1.06L26.4 54c-.83-.01-1.44-.78-1.27-1.61l.74-3.57c-3.032-.83-5.692-2.33-7.791-4.4a1.36 1.36 0 0 1 0-1.92l2.7-2.7a1.303 1.303 0 0 1 1.84 0c2.6 2.61 5.96 3.68 9.16 3.68 3.52 0 5.9-1.46 5.9-3.95 0-2.188-1.954-2.92-5.66-4.312q-.591-.221-1.24-.468c-4.77-1.71-9.28-4.2-9.28-10 0-6.7 5.46-9.97 11.9-10.3l.7-3.38a1.32 1.32 0 0 1 1.28-1.06h4.84c.83 0 1.45.78 1.28 1.61l-.79 3.8a19.7 19.7 0 0 1 6.72 3.84c.56.54.59 1.44.06 1.94z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconCashApp;
