import * as React from "react";
const IconSignSale = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M15.2 21c.6 0 1-.4 1-1s-.4-1-1-1H9.3c-.6 0-1 .4-1 1v6.6c0 .6.4 1 1 1h4.9v3.9H9.3c-.6 0-1 .4-1 1s.4 1 1 1h5.9c.6 0 1-.4 1-1v-5.9c0-.6-.4-1-1-1h-4.9V21z" />
    <path d="M57.3 13.1H33V3.6c0-.6-.4-1-1-1s-1 .4-1 1v9.5H6.7c-2.6 0-4.7 2.1-4.7 4.7v16.9c0 2.6 2.1 4.7 4.7 4.7H31v21c0 .6.4 1 1 1s1-.4 1-1v-21h24.3c2.6 0 4.7-2.1 4.7-4.7V17.9c0-2.6-2.1-4.8-4.7-4.8M60 34.7c0 1.5-1.2 2.7-2.7 2.7H6.7c-1.5 0-2.7-1.2-2.7-2.7V17.9c0-1.5 1.2-2.7 2.7-2.7h50.6c1.5 0 2.7 1.2 2.7 2.7z" />
    <path d="M42.5 32.1h-5.7v-12c0-.6-.4-1-1-1s-1 .4-1 1v13c0 .6.4 1 1 1h6.7c.6 0 1-.4 1-1s-.4-1-1-1M53.1 21.1c.6 0 1-.4 1-1s-.4-1-1-1h-5c-.6 0-1 .4-1 1v12.6c0 .6.4 1 1 1h5c.6 0 1-.4 1-1s-.4-1-1-1h-4v-4.3h1.5c.6 0 1-.4 1-1s-.4-1-1-1h-1.5v-4.3zM29.1 19.1h-6.7c-.6 0-1 .4-1 1v13c0 .6.4 1 1 1s1-.4 1-1V27h4.7v6.1c0 .6.4 1 1 1s1-.4 1-1V20.2c0-.6-.4-1.1-1-1.1m-5.7 2h4.7V25h-4.7z" />
  </svg>
);
export default IconSignSale;
