import React, { useState } from 'react';
import { BringzzButton, BringzzCheckbox, BringzzText } from "@bringzz/components";
import { useNavigation } from 'context/NavigationContext';
import {
    BringzzPageContainer,
    BringzzIcon,
    BringzzRadioList,
    BringzzCountryDropdown,
    BringzzInput
} from "@bringzz/components";
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const ConsumerLocationPage = () => {
    const { backPress } = useNavigation();

    const { navigate } = useNavigation();

    const [selected, setSelected] = useState(null)

    return (
        <BringzzPageContainer
            className="bg-white"
            header={{
                left: (
                    <BringzzIcon
                        size='22'
                        icon='IconClose'
                        folder='LineIcons'
                        className='cursor-pointer'
                        onClick={backPress}
                    />
                ),
                title: "Location"
            }}
            footer={
                <div className='pb-8 px-6'>
                    <BringzzButton
                        size='lg'
                        className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
                        onClick={() => {
                            // navigate('/register/address');
                        }}
                        disabled={true}
                    >
                        Save
                    </BringzzButton>
                </div>
            }
            authenticated={false}
        >
            <div className='relative flex justify-center bg-magic-lilac/20'>
                <div className='relative bg-white w-full bg-cover bg-center flex flex-col'>
                    {/* Content */}
                    <div className='flex-grow flex flex-col py-8 px-4'>
                        <div className='z-10 flex flex-col '>
                            <div className='pl-2 flex flex-col space-y-6 md:max-w-lg lg:max-w-xl xl:max-w-2xl'>
                                <BringzzText tag='h1' className='pl-2 text-midnight-blue font-bold'>
                                    Add Your Address
                                </BringzzText>
                            </div>
                            <div className='mt-6'>
                                <div className='space-y-6 mt-4'>
                                    <div className='flex items-end w-full space-x-2'>
                                        <div className="flex-grow">
                                            <BringzzInput
                                                label="Street name and house number"
                                                placeholder="Street name and house number"
                                                icon={{
                                                    right: (
                                                        <BringzzIcon
                                                            icon='IconMagnifier'
                                                            folder='LineIcons'
                                                            size='16'
                                                            className='text-midnight-blue'
                                                        />
                                                    )
                                                }}
                                                className="w-full"
                                            />
                                        </div>
                                        <BringzzInput className='w-8' placeholder='#' />
                                    </div>

                                    <div className='flex gap-2'>
                                        <BringzzInput label="Entrance" placeholder="A" />
                                        <BringzzInput label="Floor" placeholder="4" />
                                        <BringzzInput label="Door" placeholder="10" />
                                    </div>
                                    <div className='flex gap-2'>
                                        <BringzzCountryDropdown
                                            mode="flag"
                                            label="Country"
                                            onCountryChange={() => console.log("changed")}
                                            className="flex-1"
                                        />
                                        <BringzzInput label="Postal code" placeholder="1010" className="flex-1" />
                                        <BringzzInput label="City" placeholder="Wien" className="flex-1" />
                                    </div>
                                    <div className='space-y-2'>
                                        <BringzzText tag="h4">Address labels (opt)</BringzzText>
                                        <div className='flex space-x-4'>
                                            <div className="text-center space-y-1">
                                                <BringzzIcon
                                                    size='22'
                                                    icon='IconHome'
                                                    folder='LineIcons'
                                                    className={
                                                        classNames(
                                                            "cursor-pointer flex items-center justify-center h-10 w-10 rounded-full",
                                                            selected == "home" ? "bg-magic-lilac" : "bg-gray-100"
                                                        )
                                                    }
                                                    onClick={() => { setSelected("home") }}
                                                />
                                                <BringzzText className="font-normal text-xs">Home</BringzzText>
                                            </div>
                                            <div className="text-center space-y-1">
                                                <BringzzIcon
                                                    size='22'
                                                    icon='IconBriefcase'
                                                    folder='LineIcons'
                                                    className={
                                                        classNames(
                                                            "cursor-pointer flex items-center justify-center h-10 w-10 rounded-full",
                                                            selected == "work" ? "bg-magic-lilac" : "bg-gray-100"
                                                        )
                                                    }
                                                    onClick={() => { setSelected("work") }}
                                                />
                                                <BringzzText className="font-normal text-xs">Work</BringzzText>
                                            </div>
                                            <div className="text-center space-y-1">
                                                <BringzzIcon
                                                    size='22'
                                                    icon='IconHeart'
                                                    folder='LineIcons'
                                                    className={
                                                        classNames(
                                                            "cursor-pointer flex items-center justify-center h-10 w-10 rounded-full",
                                                            selected == "partner" ? "bg-magic-lilac" : "bg-gray-100"
                                                        )
                                                    }
                                                    onClick={() => { setSelected("partner") }}
                                                />
                                                <BringzzText className="font-normal text-xs">Parents</BringzzText>
                                            </div>
                                            <div className="text-center space-y-1">
                                                <BringzzIcon
                                                    size='22'
                                                    icon='IconMapMarker'
                                                    folder='LineIcons'
                                                    className={
                                                        classNames(
                                                            "cursor-pointer flex items-center justify-center h-10 w-10 rounded-full",
                                                            selected == "other" ? "bg-magic-lilac" : "bg-gray-100"
                                                        )
                                                    }
                                                    onClick={() => { setSelected("other") }}
                                                />
                                                <BringzzText className="font-normal text-xs">Other</BringzzText>
                                            </div>
                                            <div className="text-center space-y-1">
                                                <BringzzIcon
                                                    size='22'
                                                    icon='IconPlus'
                                                    folder='LineIcons'
                                                    className={
                                                        classNames(
                                                            "cursor-pointer flex items-center justify-center h-10 w-10 rounded-full",
                                                            selected == "add" ? "bg-magic-lilac" : "bg-gray-100"
                                                        )
                                                    }
                                                    onClick={() => { setSelected("add") }}
                                                />
                                                <BringzzText className="font-normal text-xs">Add</BringzzText>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <BringzzCheckbox label='Use the address as billing address'></BringzzCheckbox>
                                    </div>
                                    <div>
                                        <BringzzInput label='Note' type='textarea' placeholder='e.g. please call in case you cannot reach me at the door' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </BringzzPageContainer >
    );
};

export default ConsumerLocationPage;
