import * as React from "react";
const IconBed4 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.2 51.4 49.3 23V7.2C49.3 4.3 47 2 44.1 2H19.9c-2.8 0-5.2 2.3-5.2 5.2v15.9L7.8 51.4v5.5c0 2.8 2.3 5.2 5.2 5.2h38.1c2.8 0 5.2-2.3 5.2-5.2v-5.2c-.1-.1-.1-.2-.1-.3M16.5 24.2h5.4v1.6c0 2.2 1.8 4 4 4h12.3c2.2 0 4-1.8 4-4v-1.6h5.4L54 50.7H10zm7.4 1.6v-5.3c0-1.1.9-2 2-2h12.3c1.1 0 2 .9 2 2v5.3c0 1.1-.9 2-2 2H25.9c-1.1 0-2-.9-2-2M16.7 7.2c0-1.7 1.4-3.2 3.2-3.2h24.3c1.7 0 3.2 1.4 3.2 3.2v15h-5.2v-1.7c0-2.2-1.8-4-4-4H25.9c-2.2 0-4 1.8-4 4v1.7h-5.2zM51.1 60H12.9c-1.7 0-3.2-1.4-3.2-3.2v-4.2h44.4v4.2c.1 1.8-1.3 3.2-3 3.2" />
  </svg>
);
export default IconBed4;
