import * as React from "react";
const IconCameraAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.7 17.8h-8.6l-2.2-4.4c-1.1-2.1-3.2-3.5-5.6-3.5H24.7c-2.4 0-4.5 1.3-5.6 3.5l-2.2 4.4H8.3c-3.5 0-6.3 2.8-6.3 6.3v23.7c0 3.5 2.8 6.3 6.3 6.3h47.5c3.5 0 6.3-2.8 6.3-6.3V24.1c-.1-3.5-2.9-6.3-6.4-6.3m4.3 30c0 2.4-1.9 4.3-4.3 4.3H8.3c-2.4 0-4.3-1.9-4.3-4.3V24.1c0-2.4 1.9-4.3 4.3-4.3h9.2q.6 0 .9-.6l2.5-5c.7-1.5 2.2-2.4 3.8-2.4h14.6c1.6 0 3.1.9 3.8 2.4l2.5 5c.2.3.5.6.9.6h9.2c2.4 0 4.3 1.9 4.3 4.3z" />
    <path d="M32 25.7c-4.9 0-8.9 4-8.9 8.9s4 8.9 8.9 8.9 8.9-4 8.9-8.9-4-8.9-8.9-8.9m0 15.8c-3.8 0-6.9-3.1-6.9-6.9s3.1-6.9 6.9-6.9 6.9 3.1 6.9 6.9-3.1 6.9-6.9 6.9M16.2 25.7h-5.3c-.6 0-1 .4-1 1s.4 1 1 1h5.3c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconCameraAlt1;
