import * as React from "react";
const IconRefrigerator = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M46 2H18c-2.2 0-4 1.8-4 4v52c0 2.2 1.8 4 4 4h28c2.2 0 4-1.8 4-4V6c0-2.2-1.8-4-4-4M18 4h28c1.1 0 2 .9 2 2v18.9H16V6c0-1.1.9-2 2-2m28 56H18c-1.1 0-2-.9-2-2V26.9h32V58c0 1.1-.9 2-2 2" />
    <path d="M40.2 19.9c.6 0 1-.4 1-1v-4c0-.6-.4-1-1-1s-1 .4-1 1v4c0 .5.4 1 1 1M40.2 32.7c-.6 0-1 .4-1 1v4c0 .6.4 1 1 1s1-.4 1-1v-4c0-.6-.5-1-1-1" />
  </svg>
);
export default IconRefrigerator;
