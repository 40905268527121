import * as React from "react";
const IconEnvelopeAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 12.7H7c-2.2 0-4 1.8-4 4v30.7c0 2.2 1.8 4 4 4h50c2.2 0 4-1.8 4-4V16.7c0-2.2-1.8-4-4-4m-50 2h50.1L33.6 29.3c-1 .6-2.2.6-3.1 0L6.6 14.7zm22.4 16.4c.8.5 1.7.7 2.6.7s1.8-.2 2.6-.7l1.3-.8 20 19.1H7.7l20.4-19.1zM5 16.7c0-.2 0-.3.1-.5l21.3 13L5.5 48.6c-.3-.3-.5-.8-.5-1.3zm54 30.6c0 .6-.3 1.2-.7 1.5L37.6 29.2 58.8 16c.1.2.1.5.1.7v30.6z" />
  </svg>
);
export default IconEnvelopeAlt;
