import * as React from "react";
const IconEt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22.5 15"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 0h22.5v5H0z" />
    <path  d="M0 10h22.5v5H0z" />
    <path  d="M0 5h22.5v5H0z" />
    <circle cx={11.3} cy={7.5} r={5.2}  />
    <g  >
      <path
        strokeWidth={0.5}
        d="m11.3 8.8-2.1 1.5.8-2.4-2-1.5h2.5l.8-2.3.8 2.3h2.6l-2.1 1.5.8 2.4zm-1-2.4h1.9M9.9 7.8l1.3 1m.8-2.6.7 1.8M10 7.9l.6-1.8M11 9l1.6-1.1"
      />
      <path
        strokeWidth={0.25}
        d="m8.7 3.9 1.1 1.6m4-1.7-1 1.6m-1.5 4.7V12M8.9 8.3 7.1 9m8.4 0-1.8-.7"
      />
    </g>
  </svg>
);
export default IconEt;
