import * as React from "react";
const IconJail = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.9 2H12.1c-2.2 0-4 1.8-4 4v52c0 2.2 1.8 4 4 4h39.8c2.2 0 4-1.8 4-4V6c0-2.2-1.8-4-4-4m-28 20.2c1.1 0 2 .9 2 2v15.6c0 1.1-.9 2-2 2H10.1V22.2zm1.6 21.2c1.4-.6 2.3-2 2.3-3.6V24.2c0-1.6-.9-3-2.3-3.6V4h12.8v56H25.6V43.6c0-.1 0-.1-.1-.2M10.1 6c0-1.1.9-2 2-2h11.5v16.2H10.1zm0 52V43.8h13.5V60H12.1c-1.1 0-2-.9-2-2m43.8 0c0 1.1-.9 2-2 2H40.4V4h11.5c1.1 0 2 .9 2 2z" />
    <path d="M18 34.2c.6 0 1-.4 1-1v-2.9c0-.6-.4-1-1-1s-1 .4-1 1v2.9c0 .6.4 1 1 1" />
  </svg>
);
export default IconJail;
