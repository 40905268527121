import * as React from "react";
const IconHurricane = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 65 65"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32.3 59.4H21.8c-.6 0-1 .4-1 1s.4 1 1 1h10.5c.6 0 1-.4 1-1s-.5-1-1-1M35.3 28.4c0-.6-.4-1-1-1h-25c-.6 0-1 .4-1 1s.4 1 1 1h25c.6 0 1-.4 1-1M9.3 5.4h46c.6 0 1-.4 1-1s-.4-1-1-1h-46c-.6 0-1 .4-1 1s.4 1 1 1M38.7 36.4c0-.6-.4-1-1-1H16.4c-.6 0-1 .4-1 1s.4 1 1 1h21.3c.5 0 1-.4 1-1M47.7 19.4H13.1c-.6 0-1 .4-1 1s.4 1 1 1h34.6c.6 0 1-.4 1-1s-.4-1-1-1M55.3 11.4H17.4c-.6 0-1 .4-1 1s.4 1 1 1h37.9c.6 0 1-.4 1-1s-.5-1-1-1M40.1 51.4H24.4c-.6 0-1 .4-1 1s.4 1 1 1h15.7c.6 0 1-.4 1-1s-.4-1-1-1M43.5 43.4H21.8c-.6 0-1 .4-1 1s.4 1 1 1h21.7c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconHurricane;
