import * as React from "react";
const IconRightAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2.8C16 2.8 3 15.9 3 32s13 29.2 29 29.2S61 48.1 61 32 48 2.8 32 2.8m0 56.4c-2.8 0-5.6-.4-8.1-1.3.3-.2.5-.5.5-.9V36.2c0-1.8 1.4-3.2 3.2-3.2h12.9l-6.1 6c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3l7.8-7.8c.2-.2.3-.4.3-.7s-.1-.5-.3-.7l-7.8-7.8c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l6.1 6H27.6c-2.9 0-5.2 2.3-5.2 5.2v20.9c0 .1 0 .2.1.3C12.3 53.5 5 43.6 5 32 5 17 17.1 4.8 32 4.8S59 17 59 32 46.9 59.2 32 59.2" />
  </svg>
);
export default IconRightAlt;
