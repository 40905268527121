import * as React from "react";
const IconIs = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 513 342"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 0h513v342H0z" />
    <path
      
      d="M513 210.6H202.2v130.7h-79.8V210.6H0v-79.8h122.4V0h79.8v130.8H513v18.5l-1 42.7z"
    />
    <path
      
      d="M513 149.3V192H183.7v149.3H141V192H0v-42.7h141V0h42.7v149.3z"
    />
  </svg>
);
export default IconIs;
