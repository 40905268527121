import * as React from "react";
const IconHexagons = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m52.8 7.6-8.5-4.9c-1.5-.9-3.5-.9-5 0l-8.5 4.9c-1.6.9-2.5 2.6-2.5 4.4v9.8c0 1.8 1 3.5 2.5 4.4l8.5 4.9c.8.4 1.6.7 2.5.7s1.7-.2 2.5-.7l8.5-4.9c1.6-.9 2.5-2.6 2.5-4.4V12c0-1.9-1-3.5-2.5-4.4m.5 14.1c0 1.1-.6 2.1-1.5 2.6l-8.5 4.9c-.9.5-2.1.5-3 0l-8.5-4.9c-.9-.5-1.5-1.5-1.5-2.6v-9.8c0-1.1.6-2.1 1.5-2.6l8.5-4.9c.5-.3 1-.4 1.5-.4s1 .1 1.5.4l8.5 4.9c.9.5 1.5 1.5 1.5 2.6zM33.3 37.9 24.8 33c-1.5-.9-3.5-.9-5 0l-8.5 4.9c-1.6.9-2.5 2.6-2.5 4.4v9.8c0 1.8 1 3.5 2.5 4.4l8.5 4.9c.8.4 1.6.7 2.5.7s1.7-.2 2.5-.7l8.5-4.9c1.6-.9 2.5-2.6 2.5-4.4v-9.8c0-1.8-.9-3.5-2.5-4.4m.5 14.2c0 1.1-.6 2.1-1.5 2.6l-8.5 4.9c-.9.5-2.1.5-3 0l-8.5-4.9c-.9-.5-1.5-1.5-1.5-2.6v-9.8c0-1.1.6-2.1 1.5-2.6l8.5-4.9c.5-.3 1-.4 1.5-.4s1 .1 1.5.4l8.5 4.9c.9.5 1.5 1.5 1.5 2.6z" />
  </svg>
);
export default IconHexagons;
