import * as React from "react";
const IconWallet = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.8 21.3h-2.2v-7.4c0-1.9-.9-3.8-2.5-4.9s-3.6-1.4-5.4-.8l-37.6 13H7c-3.3 0-6 2.7-6 6v22.3c0 3.3 2.7 6 6 6h48.8c3.3 0 6-2.7 6-6V27.3c0-3.3-2.7-6-6-6M47 12c.6-.2 1.3-.1 1.8.3s.8 1 .8 1.6v7.4H20.3zm8.8 39.5H7c-1.1 0-2-.9-2-2V27.3c0-1.1.9-2 2-2h48.8c1.1 0 2 .9 2 2v3.9H46.4c-4.1 0-7.4 3.3-7.4 7.4s3.3 7.4 7.4 7.4h11.4v3.6c0 1-.9 1.9-2 1.9m2-16.3V42H46.4c-1.9 0-3.4-1.5-3.4-3.4s1.5-3.4 3.4-3.4z" />
  </svg>
);
export default IconWallet;
