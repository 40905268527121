import React, { useEffect, useState } from 'react';
import {
    BringzzPageContainer,
    BringzzIcon,
    BringzzText,
    BringzzInput,
    BringzzButton, BringzzBottomDrawer,
    BringzzRadioList,
    BringzzRadio,
    BringzzImage
} from '@bringzz/components';
import { useNavigation } from 'context/NavigationContext';
import { useLocation } from 'react-router-dom';
import { useAuth } from 'context/AuthContext';
import {
    useDebounce
} from "@bringzz/hooks";
import classNames from 'classnames';
import usePageState from 'hooks/usePageState';
import { compressImageToBase64, isValidURL } from 'utils';
import useRequest from 'hooks/useRequest';

let state = {
    checked: <div></div>,
    unChecked: <div></div>
};

const AddExternalLinkPage = () => {
    const { backPress } = useNavigation();
    const [addPhotoDrawer, setAddPhotoDrawer] = useState(false);
    const { navigate } = useNavigation();
    const [ogImage, setOgImage] = useState(null);
    const [externalLink, setExternalLink] = useState('')
    const [validExternalLink, setValidExternalLink] = useState(false)
    const [fetchingImage, setFetchingImage] = useState(false)
    const [headline, setHeadline] = useState(null)
    const [description, setDescription] = useState(null)
    const [isConvertingInProgress, setIsConvertingInProgress] = useState(false)
    const { sendRequest, loading } = useRequest();
    const { user } = useAuth();

    const debouncedInputValue = useDebounce(externalLink, 500);

    const populateForm = (formData) => {
        setValidExternalLink(true)
        setHeadline(formData.headline || '')
        setOgImage(formData.ogImage || '')
        setDescription(formData.description || '')
        setExternalLink(formData.externalLink || '')
    }

    const formData = usePageState();

    useEffect(() => {
        if (formData) {
            populateForm(formData)
        }
    }, [formData]);

    const fetchOpenGraphImage = async (url) => {
        try {
            setFetchingImage(true);
            const response = await fetch(url);
            const html = await response.text();

            // Improved regex to handle various cases, including single quotes and optional attributes.
            const ogImageMatch = html.match(/<meta[^>]*property=["']?og:image["']?[^>]*content=["']([^"']+)["'][^>]*>/i) ||
                html.match(/<meta[^>]*content=["']([^"']+)["'][^>]*property=["']?og:image["']?[^>]*>/i);

            let imageUrl = null;

            if (ogImageMatch && ogImageMatch[1]) {
                imageUrl = ogImageMatch[1];
            } else {
                // Attempt to use a fallback by parsing the entire document for images
                const fallbackImageMatch = html.match(/<img[^>]*src=["']([^"']+)["'][^>]*>/i);
                if (fallbackImageMatch && fallbackImageMatch[1]) {
                    imageUrl = fallbackImageMatch[1];
                }
            }

            if (imageUrl) {
                // Verify if the image URL is valid and can be loaded
                const validImage = await validateImage(imageUrl);
                if (validImage) {
                    setOgImage(imageUrl);
                }
            }
            console.log("Fetch finished");
            setFetchingImage(false);
        } catch (error) {
            console.error("Error fetching Open Graph data:", error);
            setOgImage(null);
            setFetchingImage(false);
        }
    };

    const validateImage = (url) => {
        return new Promise((resolve) => {
            const img = new Image();
            img.src = url;
            img.onload = () => resolve(true);  // Image successfully loaded
            img.onerror = () => resolve(false);  // Error loading image
        });
    };

    const handlePickerClick = async choice => {
        try {
            let base64Image = '';

            if (choice === 'camera') {
                // Handling camera input
                const stream = await navigator.mediaDevices.getUserMedia({
                    video: true
                });
                const video = document.createElement('video');
                video.srcObject = stream;
                video.play();

                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');
                video.onloadedmetadata = () => {
                    canvas.width = video.videoWidth;
                    canvas.height = video.videoHeight;
                    context.drawImage(video, 0, 0, canvas.width, canvas.height);
                    base64Image = canvas.toDataURL('image/jpeg');
                    // Stop the video stream
                    stream.getTracks().forEach(track => track.stop());
                    setOgImage(base64Image);
                    console.log(base64Image);
                };
            } else if (choice === 'photos') {
                // Handling gallery input
                const input = document.createElement('input');
                input.type = 'file';
                input.accept = 'image/*';
                input.onchange = async event => {
                    const file = event.target.files[0];
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        base64Image = reader.result;
                        setOgImage(base64Image);
                        console.log(base64Image);
                    };
                    reader.readAsDataURL(file);
                };
                input.click();
            }
        } catch (error) {
            console.error('Error handling picker choice:', error);
        }
    };

    const handleRemovePhoto = () => {
        setOgImage(null); // Only reset the photo state
    };

    useEffect(() => {
        if (debouncedInputValue.trim() !== '') {
            const fetchData = async () => {
                await fetchOpenGraphImage(debouncedInputValue)
            };
            fetchData();
        }
    }, [debouncedInputValue]);

    const handleLinkChange = async (e, valid, value) => {
        setValidExternalLink(valid)
        if (valid)
            setExternalLink(value)
    };

    const onSubmit = async () => {
        if (isValidURL(ogImage)) {
            setIsConvertingInProgress(true)
            await compressImageToBase64(ogImage)
                .then(base64Image => {
                    navigate(`/posts/preview/external-link`, {
                        state: {
                            ogImage: base64Image,
                            headline,
                            description,
                            externalLink
                        }
                    })
                })
                .catch(error => {
                    console.error(error);
                    setIsConvertingInProgress(false)
                });
        } else {
            navigate(`/posts/preview/external-link`, {
                state: {
                    ogImage,
                    headline,
                    description,
                    externalLink
                }
            })
        }
    }

    const save = async (base64Image) => {
        const data = {
            "type": 2,
            "headline": headline,
            "images": [
                {
                    "content": base64Image ? base64Image : ogImage
                }
            ],
            "description": description,
            "release": "",
            "link": externalLink,
            "published": false,
            "userId": user.id
        }

        await sendRequest({
            method: 'POST',
            url: `/module/post/createPost`,
            data
        }).then((response) => {
            if (response.status === 'success')
                navigate(`/posts/saved/external-link`)
        }).catch((error) => {
            console.log(error);
        })
    }

    const onSave = async () => {
        if (isValidURL(ogImage)) {
            setIsConvertingInProgress(true)
            await compressImageToBase64(ogImage)
                .then(async base64Image => {
                    await save(base64Image)
                })
                .catch(error => {
                    console.error(error);
                    setIsConvertingInProgress(false)
                });
        } else {
            await save()
        }
    }

    return (
        (
            <BringzzPageContainer
                header={{
                    left: (
                        <BringzzIcon
                            size="22"
                            icon="IconChevronLeft"
                            folder="LineIcons"
                            className="cursor-pointer"
                            onClick={backPress}
                        />
                    ),
                    title: <BringzzText tag="h2">External Link</BringzzText>
                }}
                footer={
                    <div className="pb-28 px-6 flex flex-row space-x-4">
                        <BringzzButton
                            onClick={onSave}
                            disabled={!ogImage || !validExternalLink || !headline || !description || !externalLink || loading}
                            size="md"
                            className="border w-full border-black justify-center"
                        >
                            Save
                        </BringzzButton>
                        <BringzzButton
                            onClick={onSubmit}
                            size="md"
                            disabled={!ogImage || !validExternalLink || !headline || !description || !externalLink}
                            className="bg-magic-lilac w-full flex justify-center text-midnight-blue"
                        >
                            Share
                        </BringzzButton>
                    </div>
                }
            >
                <div className="p-4">
                    <div className="space-y-4">
                        <BringzzInput
                            label="External Link"
                            tooltip="something"
                            placeholder='http://www.yourlink.com'
                            pattern='^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:[0-9]{1,5})?(\/[^\s]*)?$'
                            onChange={handleLinkChange}
                            defaultValue={externalLink}
                            icon={{
                                right: <div style={{ animation: 'spin 4s linear infinite' }} className='text-black'>
                                    {fetchingImage && <svg width="22" height="22" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 2V6M12 18V22M6 12H2M22 12H18M19.0784 19.0784L16.25 16.25M19.0784 4.99994L16.25 7.82837M4.92157 19.0784L7.75 16.25M4.92157 4.99994L7.75 7.82837" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>}
                                </div>
                            }}
                        />

                        {validExternalLink && <div
                            className={
                                classNames(
                                    `w-full my-4 rounded-md flex flex-col items-center justify-center bg-white transition-all duration-300 space-y-4`,)
                            }
                        >
                            {
                                ogImage && <div className="relative w-full h-full border rounded-md">
                                    <img src={ogImage} alt={"Couldn't load image"} className="h-48 w-full object-cover rounded-md" />
                                    <BringzzIcon
                                        size="24"
                                        icon="IconTrashCan"
                                        folder="LineIcons"
                                        className="absolute text-white top-2 right-2 cursor-pointer"
                                        onClick={handleRemovePhoto}
                                    />
                                </div>
                            }
                            <div
                                className="flex cursor-pointer border rounded-md items-center justify-between w-full p-3"
                                onClick={() => setAddPhotoDrawer(true)}
                            >
                                <div className='flex items-center space-x-2'>
                                    <BringzzIcon
                                        size="24"
                                        icon="IconImage"
                                        folder="LineIcons"
                                        className="cursor-pointer text-gray-500"
                                    />
                                    <BringzzText tag="h4" className="text-gray-500">Custom Image</BringzzText>
                                </div>
                            </div>

                        </div>}
                        <BringzzInput
                            label="Headline (max. XXX char)"
                            tooltip="something"
                            placeholder='Your posting headline'
                            defaultValue={headline}
                            required={true}
                            onChange={(e, valid, value) => {
                                if (valid)
                                    setHeadline(value)
                            }}
                        />
                        <BringzzInput
                            label="Description (max. XXX char)"
                            type="textarea"
                            placeholder="Your posting description"
                            tooltip="something"
                            defaultValue={description}
                            defaultRows={5}
                            required={true}
                            onChange={(e, valid, value) => {
                                if (valid)
                                    setDescription(value)
                            }}
                        />
                    </div>
                </div>
                <BringzzBottomDrawer
                    isOpen={addPhotoDrawer}
                    title={'Upload product photo'}
                    close={() => setAddPhotoDrawer(false)}
                >
                    <div className="px-4 py-6">
                        <BringzzRadioList
                            state={state}
                            className="rounded-xl w-full text-center"
                            onChange={choice => {
                                handlePickerClick(choice);
                                setAddPhotoDrawer(false);
                            }}
                        >
                            <BringzzRadio
                                value="camera"
                                className="p-2 py-4 hover:bg-slate- border-1 border-t border-gray-400 flex"
                            >
                                <div>Camera</div>
                            </BringzzRadio>
                            <BringzzRadio
                                value="photos"
                                className="p-2 py-4 hover:bg-slate- border-1 border-t border-gray-400"
                            >
                                <div>Photos</div>
                            </BringzzRadio>
                        </BringzzRadioList>
                    </div>
                </BringzzBottomDrawer>
            </BringzzPageContainer>
        )
    );
};

export default AddExternalLinkPage;
