import * as React from "react";
const IconHome6 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.2 27 32.7 2.3c-.4-.4-1-.4-1.4 0L4.8 27c-.2.2-.4.5-.3.9 0 .3.2.6.5.8 4.2 2.2 6.7 6.3 6.7 11V58c0 2.2 1.8 4 4 4h7.4c2.2 0 4-1.8 4-4v-5.8c0-1.1.9-2 2-2h5.4c1.1 0 2 .9 2 2V58c0 2.2 1.8 4 4 4h7.6c2.2 0 4-1.8 4-4V39.6c0-4.6 2.6-8.8 6.8-11 .3-.1.5-.4.5-.8.1-.3 0-.6-.2-.8m-9.1 12.6V58c0 1.1-.9 2-2 2h-7.6c-1.1 0-2-.9-2-2v-5.8c0-2.2-1.8-4-4-4h-5.4c-2.2 0-4 1.8-4 4V58c0 1.1-.9 2-2 2h-7.4c-1.1 0-2-.9-2-2V39.6c0-2.8-.8-5.4-2.1-7.6h40.8c-1.5 2.2-2.3 4.9-2.3 7.6m3.8-9.6H10.1c-.8-.9-1.8-1.8-2.9-2.5L32 4.4l24.8 23.1c-1.1.7-2 1.5-2.9 2.5" />
  </svg>
);
export default IconHome6;
