import * as React from "react";
const IconCameraAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.7 17.9v-3.4c0-1.1-.9-2-2-2h-8.5c-1.1 0-2 .9-2 2v3.3h-5.9v-1.6c0-3.5-2.8-6.3-6.3-6.3H21.5c-3.5 0-6.3 2.8-6.3 6.3v1.6H8.3c-3.5 0-6.3 2.8-6.3 6.3v23.7c0 3.5 2.8 6.3 6.3 6.3h47.5c3.5 0 6.3-2.8 6.3-6.3V24.1c-.1-3.1-2.4-5.7-5.4-6.2m-10.5-3.4h8.5v3.3h-8.5zM60 47.8c0 2.4-1.9 4.3-4.3 4.3H8.3c-2.4 0-4.3-1.9-4.3-4.3V24.1c0-2.4 1.9-4.3 4.3-4.3h7.9c.6 0 1-.4 1-1v-2.6c0-2.4 1.9-4.3 4.3-4.3H32c2.4 0 4.3 1.9 4.3 4.3v2.6c0 .6.4 1 1 1h18.4c2.4 0 4.3 1.9 4.3 4.3z" />
    <path d="M28 23.1c-5.6 0-10.2 4.6-10.2 10.2S22.4 43.5 28 43.5 38.3 39 38.3 33.3 33.7 23.1 28 23.1m0 18.4c-4.5 0-8.2-3.7-8.2-8.2s3.7-8.2 8.2-8.2 8.2 3.7 8.2 8.2c.1 4.6-3.6 8.2-8.2 8.2" />
  </svg>
);
export default IconCameraAlt;
