import * as React from "react";
const IconCreditCardsAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.2 26.2 48.7 6q-.9-1.95-3-2.7c-1.3-.5-2.8-.4-4 .2L5 20.5c-2.6 1.2-3.8 4.4-2.6 7L11 45.7v10.1c0 2.9 2.4 5.3 5.3 5.3h40.5c2.9 0 5.3-2.4 5.3-5.3V31.2c-.1-2.3-1.7-4.4-3.9-5m-2.3-.2H17.6L49 11.3zM13 36.2h47v6.1H13zm47-5v3H13v-3c0-1.8 1.5-3.3 3.3-3.3h40.5c.2 0 .4 0 .6.1 1.4.3 2.6 1.7 2.6 3.2M5.9 22.4 42.6 5.2c.8-.4 1.7-.4 2.5-.1s1.5.9 1.8 1.7l1.2 2.7L5.5 29.4l-1.2-2.7c-.8-1.6 0-3.6 1.6-4.3m.5 8.8 5.2-2.4c-.4.7-.7 1.6-.7 2.5V41zm50.3 27.9H16.2c-1.8 0-3.3-1.5-3.3-3.3V44.3h47v11.5c.1 1.8-1.4 3.3-3.2 3.3" />
  </svg>
);
export default IconCreditCardsAlt;
