import * as React from "react";
const IconMeasuringTape = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M37.3 19.1c-4.9 0-8.9 4-8.9 8.9s4 8.9 8.9 8.9 8.9-4 8.9-8.9-4-8.9-8.9-8.9m0 15.9c-3.8 0-6.9-3.1-6.9-6.9s3.1-6.9 6.9-6.9 6.9 3.1 6.9 6.9-3.1 6.9-6.9 6.9" />
    <path d="M38.6 3.3c-13.3 0-23.4 9.6-23.4 22.2v4.1H9.3c-1.1 0-2 .9-2 2v8.5c0 1.1.9 2 2 2h5.9V48H5.6c-2 0-3.6 1.6-3.6 3.6v7.9c0 .6.4 1 1 1s1-.4 1-1v-7.9c0-.9.7-1.6 1.6-1.6h34.1c12.7.1 22.3-9.9 22.3-23.3C62 13.8 51.5 3.3 38.6 3.3M9.3 40.2v-8.5h5.9v8.5zm30.5 7.9H17.2V25.6c0-11.5 9.2-20.2 21.4-20.2C50.4 5.3 60 14.9 60 26.7c0 12.2-8.7 21.4-20.2 21.4" />
  </svg>
);
export default IconMeasuringTape;
