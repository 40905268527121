import * as React from "react";
const IconSnowfall = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.2 35.9c-.2-.5-.8-.7-1.3-.5l-9 4.5-15.3-8.6L48.9 23l9.1 4.4c.1.1.3.1.4.1q.6 0 .9-.6c.2-.5 0-1.1-.5-1.3L51 21.8l6.5-3.8c.5-.3.6-.9.4-1.4-.3-.5-.9-.6-1.4-.4L50 20.1l.6-8.5c0-.6-.4-1-.9-1.1-.5 0-1 .4-1.1.9l-.7 9.8-14.2 8.2V12.9L42 7.3c.5-.3.6-.9.3-1.4s-.9-.6-1.4-.3l-7.2 4.9V3c0-.6-.4-1-1-1s-1 .4-1 1v7.5l-7-4.8c-.5-.3-1.1-.2-1.4.3s-.2 1.1.3 1.4l8.2 5.6v16.7l-15.7-8.9-.8-9.8c0-.6-.5-1-1.1-.9-.6 0-1 .5-.9 1.1l.7 8.5L7.5 16c-.5-.3-1.1-.1-1.4.4s-.1 1.1.4 1.4l6.5 3.7-7.8 3.9c-.5.2-.7.8-.5 1.3q.3.6.9.6c.1 0 .3 0 .4-.1l9-4.5 15.6 8.8L14.1 41l-9-4.4c-.5-.2-1.1 0-1.3.5s0 1.1.5 1.3l7.8 3.8-5.7 3.3c-.5.3-.6.9-.4 1.4.2.3.5.5.9.5.2 0 .3 0 .5-.1l5.8-3.3-.6 8.5c0 .6.4 1 .9 1.1h.1c.5 0 1-.4 1-.9l.7-9.9L31.7 33v18.9l-8.3 5.6c-.5.3-.6.9-.3 1.4.2.3.5.4.8.4.2 0 .4-.1.6-.2l7.2-4.9V61c0 .6.4 1 1 1s1-.4 1-1v-6.7l7.1 4.8c.2.1.4.2.6.2.3 0 .6-.2.8-.4.3-.5.2-1.1-.3-1.4l-8.2-5.6V33.1l15.2 8.6.8 9.9c0 .5.5.9 1 .9h.1c.6 0 1-.5.9-1.1l-.7-8.5 5.8 3.3c.2.1.3.1.5.1.3 0 .7-.2.9-.5.3-.5.1-1.1-.4-1.4L52 41.1l7.8-3.9c.5-.2.7-.8.4-1.3" />
  </svg>
);
export default IconSnowfall;
