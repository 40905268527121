import React, { useState } from 'react';
import { BringzzButton, BringzzText } from "@bringzz/components";
import { useNavigation } from 'context/NavigationContext';
import {
	BringzzPageContainer,
	BringzzIcon,
	BringzzRadioList,
	BringzzDate,
	BringzzInput
} from "@bringzz/components";
import { useLocation } from 'react-router-dom';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const PharmacyCardPage = () => {
	const { backPress } = useNavigation();

	const { navigate } = useNavigation();

	let icon = (
		<BringzzIcon
			icon='IconMountain'
			library='Custom'
			className='flex items-center justify-center h-10 w-10 bg-gray-100 rounded-full'
			size='28'
		/>
	);

	let state = {
		checked: (
			<BringzzIcon
				icon='IconCheck'
				library='Custom'
				className='h-6 w-6 p-1 bg-green-500 flex justify-center items-center text-light rounded-full'
				size='14'
			/>
		),
		unChecked: (
			<div className='h-6 w-6 rounded-full border border-neutral-400'></div>
		)
	};

	return (
		<BringzzPageContainer
			header={{
				left: (
					<BringzzIcon
						size='22'
						icon='IconChevronLeft'
						folder='LineIcons'
						className='cursor-pointer'
						onClick={backPress}
					/>
				)
			}}
			footer={
				<div className='px-4 mb-4'>
					<BringzzButton
						size='lg'
						className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
						onClick={() => {
							navigate('/register/pharmacy/inventory-permission/');
						}}
					>
						Save
					</BringzzButton>
				</div>
			}
			authenticated={false}
		>
			<div className='relative flex justify-center bg-magic-lilac/20'>
				<div className='relative bg-white w-full bg-cover bg-center flex flex-col'>
					{/* Content */}
					<div className='flex-grow flex flex-col py-8 px-4'>
						<div className='z-10 flex flex-col '>
							<div className='flex flex-col text-center space-y-6 mx-auto md:max-w-lg lg:max-w-xl xl:max-w-2xl'>
								<BringzzText tag='h1' className='text-midnight-blue'>
									Add Credit Card
								</BringzzText>
							</div>
							<div className='mt-6'>
								<BringzzInput
									label='Credit Card'
									placeholder=''
									icon={{
										left: (
											<BringzzIcon
												icon='IconCreditCard'
												folder='LineIcons'
												className='h-6 w-6'
												size='24'
											/>
										),
										right: (
											<BringzzIcon
												icon='IconCameraAlt1'
												folder='LineIcons'
												className='h-6 w-6'
												size='24'
											/>
										)
									}}
								/>
								<div className='flex gap-2'>
									<BringzzInput
										label='Exp. Date'
										placeholder='MM/YY'
										right={
											<BringzzIcon
												icon='IconQuestionCircle'
												folder='LineIcons'
												className='h-6 w-6'
												size='24'
											/>
										}
									/>
									<BringzzInput
										label='CVV'
										placeholder='123'
										right={
											<BringzzIcon
												icon='IconQuestionCircle'
												folder='LineIcons'
												className='h-6 w-6'
												size='24'
											/>
										}
									/>
								</div>
								<BringzzInput
									label='Card name/label (optional)'
									placeholder='e.g. family credit card'
									right={
										<BringzzIcon
											icon='IconQuestionCircle'
											folder='LineIcons'
											className='h-6 w-6'
											size='24'
										/>
									}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</BringzzPageContainer>
	);
};

export default PharmacyCardPage;
