import * as React from "react";
const IconVuejs = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      
      d="M48.082 6.514h-2.667l-7.048.002-6.332 10.55-6.356-10.55-7.056-.003H6.057v.001H2.219l29.858 50.973L61.781 6.515zm-16.015 40.4L11.525 11.846h7.53l13.028 22.419 12.925-22.42H52.5z"
    />
  </svg>
);
export default IconVuejs;
