import * as React from "react";
const IconCutlery = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.9 11.7c-4.4-4.4-14.5-1.1-20.3 4.6-1.3 1.3-2 3.1-1.9 5s.9 3.7 2.2 5l2.1 2.1-9.1 9.1-5.8-5.8.1-.1C29 30 30 27.7 30 25.3c0-2.3-.9-4.5-2.5-6.1L16.8 8.5c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4L26 20.5c1.3 1.2 1.9 2.9 1.9 4.7 0 1.9-.8 3.7-2.2 5.1l-.1.1-15.3-15.3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l15.4 15.4-.1.1c-2.8 2.8-7.2 2.9-9.9.2L3.7 21.6c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l10.6 10.6c1.7 1.7 3.9 2.5 6.2 2.5 2.4 0 4.7-.9 6.6-2.7l.1-.1 5.8 5.8-15 15c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3l15-15 15 15c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-15-15 9.1-9.1 1.7 1.7c1.5 1.5 3.4 2.2 5.3 2.2 1.8 0 3.5-.7 4.8-2 4-4 6.7-9.8 6.7-14.5q0-3.6-2.1-5.7m-6 18.8c-1.9 1.9-5.2 1.8-7.3-.3L41.3 25c-1-1-1.6-2.3-1.6-3.7-.1-1.4.4-2.6 1.4-3.6 5-5 13.9-8.1 17.4-4.6 1 1 1.5 2.4 1.5 4.3 0 4.1-2.5 9.5-6.1 13.1" />
  </svg>
);
export default IconCutlery;
