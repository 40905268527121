import * as React from "react";
const IconGraphDecrease = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 58.7h-1.9V30.6c0-1.7-1.3-3-3-3h-5.7c-1.7 0-3 1.3-3 3v28.1H38V36.6c0-1.7-1.3-3-3-3h-5.7c-1.7 0-3 1.3-3 3v22.1h-9.4v-15c0-1.7-1.3-3-3-3h-6c-1.7 0-3 1.3-3 3v15H3c-.6 0-1 .4-1 1s.4 1 1 1h58c.6 0 1-.4 1-1s-.4-1-1-1m-54.1 0v-15c0-.6.4-1 1-1h5.7c.6 0 1 .4 1 1v15zm21.2 0V36.6c0-.6.4-1 1-1h5.7c.6 0 1 .4 1 1v22.1zm21.2 0V30.6c0-.6.4-1 1-1H56c.6 0 1 .4 1 1v28.1zM9.1 18.8l7 4.4c.2.1.4.2.5.2.3 0 .7-.2.8-.5.3-.5.2-1.1-.3-1.4l-5-3.1h.1l43.9-13c.5-.2.8-.7.7-1.2-.2-.5-.7-.8-1.2-.7l-43.7 13 3-4.8c.3-.5.2-1.1-.3-1.4s-1.1-.2-1.4.3l-4.4 7c-.3.3-.2.9.3 1.2" />
  </svg>
);
export default IconGraphDecrease;
