import * as React from "react";
const IconCoffeeCupAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M49.6 48.2c5 0 9.1-4.1 9.1-9.1S54.6 30 49.6 30H9.2c-.6 0-1 .4-1 1v5c0 .1 0 .2.1.3L17.1 60H6.2c-.6 0-1 .4-1 1s.4 1 1 1h46.4c.6 0 1-.4 1-1s-.4-1-1-1h-11L46 48.2zm7.2-9.2c0 3.9-3.2 7.1-7.1 7.1h-2.8l3.6-9.7c0-.1.1-.2.1-.3V32c3.4.4 6.2 3.4 6.2 7M19.2 60l-9-24.2v-3.9h38.3v4l-9 24.1zM22.6 22.9c.2.2.5.4.8.4.2 0 .5-.1.6-.2.4-.4.5-1 .1-1.4-.2-.3-2.3-2.9-.2-4.9 5-5 2.4-11.5.3-14.2-.3-.4-1-.5-1.4-.2s-.5 1-.2 1.4c1.7 2.2 3.9 7.6-.1 11.6-2.7 2.5-1.2 5.9.1 7.5M35.7 22.9c.2.2.5.4.8.4.2 0 .5-.1.6-.2.4-.4.5-1 .1-1.4-.2-.4-2.2-3-.2-5.1 5-5 2.4-11.5.3-14.2-.3-.4-1-.5-1.4-.2s-.5 1-.2 1.4c1.7 2.2 3.9 7.6-.1 11.6-2.7 2.7-1.2 6.1.1 7.7" />
  </svg>
);
export default IconCoffeeCupAlt;
