import * as React from "react";
const IconTriangle3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.4 54.5 36.7 4.9C35.8 3.1 34 2 32 2s-3.8 1.1-4.7 2.9L2.6 54.5c-.8 1.6-.7 3.5.2 5.1 1 1.6 2.6 2.5 4.5 2.5h49.5c1.8 0 3.5-.9 4.5-2.5.9-1.6 1-3.5.1-5.1m-1.9 4c-.6 1-1.6 1.5-2.8 1.5H7.2c-1.1 0-2.2-.6-2.8-1.5-.6-1-.6-2.1-.1-3.1L29.1 5.8C29.7 4.7 30.7 4 32 4s2.3.7 2.9 1.8l24.8 49.6c.5 1 .4 2.1-.2 3.1" />
    <path d="M38.4 33.7c-.4-.4-1-.4-1.4 0l-5 5-5-5c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l5 5-5 5c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l5-5 5 5c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-5-5 5-5c.4-.3.4-1 0-1.4" />
  </svg>
);
export default IconTriangle3;
