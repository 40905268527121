import * as React from "react";
const IconPlaneAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M42.5 62h-.2L32 59.4 21.7 62c-.3.1-.6 0-.9-.2-.2-.2-.4-.5-.4-.8v-5.3c0-.3.1-.5.3-.7l5-5v-8.8l-19.8 5c-.3.1-.6 0-.9-.2-.2-.2-.4-.5-.4-.8V37c0-.3.1-.6.4-.8l20.7-15.4V8.2C25.7 4.8 28.5 2 32 2s6.3 2.8 6.3 6.2v12.6L59 36.2c.3.2.4.5.4.8v8.2c0 .3-.1.6-.4.8-.2.2-.6.3-.9.2l-19.8-5V50l5 5c.2.2.3.4.3.7V61c0 .3-.1.6-.4.8-.2.1-.4.2-.7.2M32 57.4h.2l9.3 2.3v-3.6l-5-5c-.2-.2-.3-.4-.3-.7V39.9c0-.3.1-.6.4-.8s.6-.3.9-.2l19.8 5v-6.4L36.7 22.1c-.3-.2-.4-.5-.4-.8V8.2C36.3 5.9 34.4 4 32 4s-4.3 1.9-4.3 4.2v13.1c0 .3-.1.6-.4.8L6.7 37.5v6.4l19.8-5c.3-.1.6 0 .9.2.2.2.4.5.4.8v10.5c0 .3-.1.5-.3.7l-5 5v3.6l9.3-2.3z" />
  </svg>
);
export default IconPlaneAlt;
