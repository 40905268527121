import * as React from "react";
const IconCornerDoubleRightUp = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M41.5 17.1c-.4-.4-1-.4-1.4 0L26.9 30.3c-.4.4-.4 1 0 1.4s1 .4 1.4 0l11.5-11.5v34c0 2.6-2.1 4.7-4.7 4.7H10c-.6 0-1 .4-1 1s.4 1 1 1h25.1c3.7 0 6.7-3 6.7-6.7v-34l11.5 11.5c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4z" />
    <path d="M28.3 17.9 40.8 5.4l12.5 12.5c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4L41.5 3.3c-.4-.4-1-.4-1.4 0L26.9 16.5c-.4.4-.4 1 0 1.4s1 .4 1.4 0" />
  </svg>
);
export default IconCornerDoubleRightUp;
