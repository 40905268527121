import * as React from "react";
const IconStatsUpCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60 59H5V4c0-.6-.4-1-1-1s-1 .4-1 1v56c0 .6.4 1 1 1h56c.6 0 1-.4 1-1s-.4-1-1-1" />
    <path d="M60 23.4H47.3c-.6 0-1 .4-1 1s.4 1 1 1h10.1l-8.1 7.1H35.7c-.3 0-.6.1-.8.4l-7.5 9.8H16.3c-.6 0-1 .4-1 1s.4 1 1 1h11.6c.3 0 .6-.1.8-.4l7.5-9.8h13.5c.2 0 .5-.1.7-.2l8.7-7.6v10.5c0 .6.4 1 1 1s1-.4 1-1V24.4c-.1-.6-.5-1-1.1-1" />
  </svg>
);
export default IconStatsUpCopy;
