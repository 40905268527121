import * as React from "react";
const IconTextFormatRemoveCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M43.3 55H5.5c-.6 0-1 .4-1 1s.4 1 1 1h37.8c.6 0 1-.4 1-1s-.4-1-1-1M58.4 41.3l3.3-3.3c.4-.4.4-1 0-1.4s-1-.4-1.4 0L57 39.9l-3.3-3.3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l3.3 3.3-3.3 3.3c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l3.3-3.3 3.3 3.3c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4zM30.4 9.3c-.3-.9-1-1.3-2-1.3h-8c-.8 0-1.7.4-2 1.3L3.2 45.5c-.6 1.4.3 2.9 1.9 2.9h5.3c.9 0 1.9-.7 2.1-1.4l2.5-6.2h18.6l2.2 6.2c.2.9 1.2 1.4 2.1 1.4h5.6c1.7-.2 2.8-1.8 2.1-3zm-11.5 24L24.3 19l5.4 14.2H18.9z" />
  </svg>
);
export default IconTextFormatRemoveCopy;
