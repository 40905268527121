import * as React from "react";
const IconBaguette = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 20.3C15.2 20.3 2 27 2 35.4c0 .8.1 1.5.4 2.4.5 1.6 1.8 2.8 3.4 3.3 5 1.6 15.1 2.6 26.2 2.6s21.2-1 26.2-2.6c1.6-.5 2.9-1.7 3.4-3.3v-.1c.2-.8.3-1.6.3-2.3C62 27 48.8 20.3 32 20.3m27.8 16.8c-.3 1-1.1 1.8-2.1 2.1-4.9 1.5-14.7 2.5-25.7 2.5s-20.8-.9-25.6-2.5c-1-.3-1.8-1.1-2.1-2q-.3-.9-.3-1.8c0-4 4.1-7.6 10.3-10.1.7 4.4 3.7 7.7 7.3 7.7.6 0 1-.4 1-1s-.4-1-1-1c-2.6 0-4.9-2.7-5.3-6.3 3.4-1.1 7.4-1.9 11.6-2.2.4 5.1 3.5 10.5 7.4 10.5.6 0 1-.4 1-1s-.4-1-1-1c-2.4 0-5-4.3-5.4-8.6h2.2c3.3 0 6.5.3 9.5.8v.2c0 5.3 3.3 9.7 7.4 9.7.6 0 1-.4 1-1s-.4-1-1-1c-2.9 0-5.3-3.3-5.4-7.5 9.5 2 16.4 6.6 16.4 11.8 0 .6-.1 1.1-.2 1.7" />
  </svg>
);
export default IconBaguette;
