import * as React from "react";
const IconShark = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M11.2 62c-.2 0-.4 0-.6-.1-1-.2-1.9-1-2.1-2l-.2-.8c-1-3.5-.6-7.3 1.1-10.5l-1.2-1.2C4.3 43.6 2 38.2 2 32.8 2 27.2 4.1 22 8.1 18.1c3.9-4 9.1-6.1 14.7-6.1h3.6V4.8c0-1.4 1-2.8 2.9-2.8 6 0 11.2 4.2 12.5 10h17.4c1.6 0 2.8 1.3 2.8 2.8 0 11-9 20-20 20h-4.2c-2 1.9-5.3 3.3-9.6 4.3-3.6.8-6.6 1.1-6.8 1.1-1.1.1-2.1-.5-2.7-1.4s-.5-2.1.1-3l.7-1h-5.3c0 .9.3 2.2.9 3.5.2.5 0 1.1-.5 1.3s-1.1 0-1.3-.5c-.9-2-1.3-4.3-.9-5.6.1-.4.5-.7 1-.7h8.1c.4 0 .7.2.9.5s.1.7-.1 1L20.5 37c-.3.4-.1.8 0 .9s.3.4.8.4c.1 0 3.1-.2 6.5-1 4.2-1 7.2-2.4 9-4.1.2-.2.4-.3.7-.3H42c9.9 0 18-8.1 18-18 0-.4-.4-.8-.8-.8H40.9c-.5 0-.9-.4-1-.8C39.1 7.9 34.5 4 29.3 4c-.7 0-.9.5-.9.8V13c0 .6-.4 1-1 1h-4.6c-5 0-9.7 2-13.3 5.5S4 27.8 4 32.8c0 4.9 2.1 9.8 5.6 13.3l1.7 1.7c.3.3.4.8.1 1.2-1.8 2.9-2.2 6.4-1.3 9.6l.2.8c.1.4.5.5.6.6.1 0 .5.1.8-.3l6.9-8.6c.1-.2.3-.3.5-.3 4.9-1.6 9.3-2.5 10.3-2.7.3-.2.3-.5.3-.7s0-.5-.4-.7c-.5-.2-.7-.8-.4-1.3.2-.5.8-.7 1.3-.4 1 .5 1.6 1.4 1.6 2.5s-.6 2-1.6 2.5H30s-4.8.8-9.9 2.5l-6.7 8.4c-.6.7-1.4 1.1-2.2 1.1" />
  </svg>
);
export default IconShark;
