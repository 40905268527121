import * as React from "react";
const IconIl = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 513 342"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 0h513v342H0z" />
    <path
      
      d="M340.6 122.4h-56.1l-28-48.6-28 48.6h-56.1l28 48.6-28 48.6h56.1l28 48.6 28-48.6h56.1l-28-48.6zM293.2 171 276 204.2h-38.9L219.8 171l17.2-33.2h38.9zm-36.7-71.8 11.9 23.3h-23.9zm-58.3 38.6h23.9l-10.8 21zm0 66.4 13-22.1 11.9 22.1zm58.3 37.5-11.9-22.1h23.9zm59.4-37.5h-25l11.9-22.1zm-26.1-66.4h26.1l-13 22.1zM0 21.3h512V64H0zm0 256h512V320H0z"
    />
  </svg>
);
export default IconIl;
