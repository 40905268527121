import * as React from "react";
const IconSydneyOperaHouse = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 44.5h-3.3c1-2.3 2.2-4.5 3.5-6.4.2-.3.2-.8 0-1.1s-.6-.5-1-.5c-2.2.3-4.4.9-6.5 1.6-.2.1-.4.2-.7.2-1.5-15.7-13.7-30-27.8-32-.4-.1-.8.1-1 .5-.2.3-.2.8 0 1.1.7 1.1 5.5 8.5 7.2 19.1-5.4-4.7-13.7-8.8-20-9.7-.4-.1-.8.1-1 .5-.2.3-.2.8 0 1.1 3.4 5 5.7 11.2 6.9 18.1-4-2.3-10.1-5.1-14.2-5.7-.4-.1-.8.1-1 .5-.2.3-.2.8 0 1.1 2.3 3.4 4.1 7.4 5.4 11.9H4.4c-.6 0-1 .4-1 1V57c0 .6.4 1 1 1H61c.6 0 1-.4 1-1V45.5c0-.6-.4-1-1-1M54.4 40c1.3-.5 2.6-.9 3.9-1.1-1 1.7-1.9 3.6-2.8 5.6h-9.2c1.5-1.2 3.8-2.6 6.2-3.7h.1c.6-.3 1.2-.6 1.8-.8m-3.3-.8c-3.8 1.8-7 4.4-8 5.3h-9.8c0-.3.1-.6.1-.9l.1-.7c2.3-13.5-1.8-26.4-6.4-34.2 12.5 3 22.9 16.1 24 30.5M31.8 30c.1 0 .1.1 0 0 .4 3.9.4 8.1-.3 12.6l-.1.7c0 .4-.1.8-.1 1.1H20.2c-.1-2-.3-3.9-.5-5.8v-.2c-.9-7-3.1-13.5-6.2-18.9 6.2 1.7 14 6 18.3 10.5M5.1 33.6c4.1 1.2 9.5 3.8 12.6 5.8.2 1.7.3 3.4.4 5.1H9.7c-1.2-4-2.7-7.7-4.6-10.9M60 55.7H5.4v-9.2H60z" />
  </svg>
);
export default IconSydneyOperaHouse;
