import * as React from "react";
const IconHospitalBedAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 19.8H19.4V13c0-.6-.4-1-1-1H4.5c-.6 0-1 .4-1 1v6.8H3c-.6 0-1 .4-1 1s.4 1 1 1h8.4l18.5 9.7-17.2 9c-.5-.2-1.1-.3-1.7-.3-3.2 0-5.8 2.6-5.8 5.9S7.8 52 11 52c2.6 0 4.7-1.7 5.5-4h31.1c.8 2.3 3 4 5.5 4 3.2 0 5.8-2.6 5.8-5.9s-2.6-5.9-5.8-5.9c-.6 0-1.2.1-1.7.3l-17.2-9 18.5-9.7H61c.6 0 1-.4 1-1s-.4-1-1-1M5.5 14.1h11.9v5.8H5.5zm5.4 35.8c-2.1 0-3.8-1.7-3.8-3.9 0-2.1 1.7-3.9 3.8-3.9s3.8 1.7 3.8 3.9c.1 2.2-1.7 3.9-3.8 3.9m46-3.8c0 2.1-1.7 3.9-3.8 3.9s-3.8-1.7-3.8-3.9c0-2.1 1.7-3.9 3.8-3.9s3.8 1.7 3.8 3.9m-7.7-4.5c-1.2 1-2 2.6-2 4.3H16.8c0-1.7-.8-3.2-2-4.3l17.2-9zM32 30.3l-16.3-8.5h32.6z" />
  </svg>
);
export default IconHospitalBedAlt1;
