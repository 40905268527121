import * as React from "react";
const IconHourglassCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m41.4 32 6.9-18c1-2.7.7-5.6-1-7.9-1.7-2.5-4.7-4-7.8-4h-15c-3.2 0-6.1 1.5-7.8 4-1.6 2.4-2 5.3-1 7.9l6.9 18-6.9 18c-1 2.7-.7 5.6 1 7.9 1.7 2.5 4.6 4 7.8 4h15.1c3.2 0 6.1-1.5 7.8-4 1.6-2.4 2-5.3 1-7.9zm4.3 24.8c-1.4 2-3.7 3.2-6.2 3.2h-15c-2.5 0-4.8-1.2-6.2-3.2-1.3-1.8-1.5-4-.7-6.1l6.9-18q.3-.75 0-1.5l-6.9-18c-.8-2-.5-4.3.7-6.1C19.7 5.2 22 4 24.5 4h15.1c2.5 0 4.8 1.2 6.2 3.2 1.3 1.8 1.5 4 .7 6.1l-6.9 18q-.3.75 0 1.5l6.9 18c.7 2 .5 4.2-.8 6" />
  </svg>
);
export default IconHourglassCopy;
