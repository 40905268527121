import * as React from "react";
const IconElegantTable4 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 16.5H3c-.6 0-1 .4-1 1s.4 1 1 1h5v28c0 .6.4 1 1 1s1-.4 1-1V19.1l7.8 4.7v17.4c0 .6.4 1 1 1s1-.4 1-1V24.9l13.8 8.3c.8.5 1.8.7 2.7.7 1.2 0 2.4-.4 3.4-1.2l4.7-3.9v12.3c0 .6.4 1 1 1s1-.4 1-1V27.2l7.8-6.5v25.8c0 .6.4 1 1 1s1-.4 1-1V19l.5-.5H61c.6 0 1-.4 1-1s-.4-1-1-1M38.3 31.2c-1.1.9-2.6 1-3.8.3l-21.6-13h40.5z" />
  </svg>
);
export default IconElegantTable4;
