import * as React from "react";
const IconAlignRight2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60 13.1c-.6 0-1 .4-1 1v35.8c0 .6.4 1 1 1s1-.4 1-1V14.1c0-.5-.4-1-1-1M43.1 13.8c-3 0-5.3 2.4-5.3 5.4v25.6c0 3 2.4 5.3 5.3 5.3H45c3 0 5.4-2.4 5.4-5.3V19.2c0-3-2.4-5.4-5.4-5.4zm5.3 5.4v25.6c0 1.8-1.5 3.3-3.4 3.3h-1.9c-1.8 0-3.3-1.5-3.3-3.3V19.2c0-1.8 1.5-3.4 3.3-3.4H45c1.9 0 3.4 1.5 3.4 3.4" />
    <path d="M59.1 60H8.3C6.5 60 5 58.5 5 56.7V7.4C5 5.5 6.5 4 8.3 4H59c.6 0 1-.4 1-1s-.4-1-1-1H8.3C5.4 2 3 4.4 3 7.4v49.3c0 3 2.4 5.3 5.4 5.3h50.7c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconAlignRight2;
