import * as React from "react";
const IconBrain = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62 30.2c-.1-3.4-1.9-6.6-4.6-8.4.3-3-.6-5.9-2.5-8.2-1.7-2.1-4.2-3.3-6.8-3.5-1.4-3.4-4.3-5.7-7.7-6.2-3.1-.4-6.2.7-8.4 3.1-2.2-2.4-5.3-3.6-8.5-3.1-3.5.5-6.4 2.9-7.7 6.2-2.6.2-5 1.4-6.8 3.5-1.9 2.3-2.8 5.2-2.5 8.2C3.7 23.7 2 27 2 30.6c0 4.3 2.4 8 6 9.7-.4 2.6.2 5.3 1.6 7.6 1.4 2.1 3.3 3.6 5.7 4.2.9 4 3.7 6.9 7.5 7.8 3.4.8 6.8-.3 9.2-2.9 1.9 2.1 4.4 3.2 7 3.2.7 0 1.4-.1 2.2-.2 3.8-.9 6.7-3.8 7.5-7.8 2.3-.6 4.3-2.1 5.7-4.2 1.4-2.2 2-4.9 1.6-7.6 3.7-1.8 6.2-5.8 6-10.2M23.3 58c-3.2-.7-5.6-3.4-6.1-6.8-.1-.4-.4-.7-.8-.8-2-.4-3.8-1.7-5-3.5-1.3-2-1.7-4.5-1.2-6.9.1-.5-.2-1-.7-1.2C6.2 37.6 4 34.4 4 30.6c0-3.1 1.6-6 4.2-7.5.4-.2.6-.6.5-1-.4-2.6.3-5.2 1.9-7.2 1.5-1.8 3.6-2.8 5.8-2.8h.1c.4 0 .8-.3.9-.7 1-3 3.4-5.1 6.4-5.6 2.8-.3 5.4.9 7.2 3.2v46c-1.9 2.5-4.8 3.6-7.7 3m31.2-19.3c-.5.2-.8.7-.7 1.2.6 2.4.1 4.9-1.2 6.9-1.2 1.9-3 3.1-5 3.5-.4.1-.7.4-.8.8-.6 3.5-2.9 6.1-6.1 6.8-3 .7-5.8-.4-7.7-2.9V9c1.8-2.3 4.4-3.5 7.2-3.1 3 .4 5.3 2.5 6.4 5.6.1.4.6.7 1.1.7 2.2 0 4.3 1 5.8 2.8 1.7 2 2.4 4.6 1.9 7.2-.1.4.1.8.5 1 2.4 1.4 4 4.1 4.2 7.1.1 3.8-2.2 7.3-5.6 8.4" />
    <path d="M24.5 28.8c-.4.1-2.8 1-4.5 2.7-1-.4-1.8-1.1-2.1-1.9-.8-1.8.2-4.1.2-4.1.2-.5 0-1.1-.5-1.3s-1.1 0-1.3.5c-.1.1-1.4 3.1-.3 5.7q.75 1.8 2.7 2.7c-.2.4-.4.8-.4 1.3-.3 1.8.6 3.5 2.6 5.2.2.2.4.2.6.2.3 0 .6-.1.8-.4.4-.4.3-1.1-.1-1.4-1.4-1.2-2.1-2.3-1.9-3.3.4-2 3.7-3.6 4.9-4 .5-.2.8-.8.6-1.3s-.8-.7-1.3-.6M48.3 24.2c-.5.2-.7.8-.5 1.3 0 0 1 2.3.2 4.1-.4.8-1.1 1.5-2.1 1.9-1.7-1.6-4.1-2.5-4.5-2.7-.5-.2-1.1.1-1.3.6s.1 1.1.6 1.3c1.2.4 4.6 2 4.9 4 .2 1-.5 2.1-1.9 3.3-.4.4-.5 1-.1 1.4.2.2.5.4.8.4.2 0 .5-.1.6-.2 2-1.7 2.9-3.5 2.6-5.2-.1-.4-.2-.9-.4-1.3 1.3-.6 2.2-1.6 2.7-2.7 1.2-2.7-.2-5.6-.3-5.7-.2-.5-.8-.7-1.3-.5" />
  </svg>
);
export default IconBrain;
