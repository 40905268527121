import * as React from "react";
const IconAddCart1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 4.2h-8c-.5 0-.9.3-1 .8l-3.3 13.6H37.8c-.6 0-1 .4-1 1s.4 1 1 1h10.4L43.5 40H8.8L4.3 20.6H13c.6 0 1-.4 1-1s-.4-1-1-1H3c-.3 0-.6.1-.8.4-.2.2-.2.5-.2.8l5 21.4c.1.5.5.8 1 .8h36.3c.5 0 .9-.3 1-.8l8.5-35.1H61c.6 0 1-.4 1-1s-.4-.9-1-.9M38.6 49.3h-25c-3 0-5.4 2.4-5.4 5.4s2.4 5.4 5.4 5.4 5.4-2.4 5.4-5.4c0-1.3-.5-2.4-1.2-3.4h16.7c-.8.9-1.2 2.1-1.2 3.4 0 3 2.4 5.4 5.4 5.4s5.4-2.4 5.4-5.4-2.6-5.4-5.5-5.4M17 54.6c0 1.9-1.5 3.4-3.4 3.4s-3.4-1.5-3.4-3.4 1.5-3.4 3.4-3.4c1.9.1 3.4 1.6 3.4 3.4M38.6 58c-1.9 0-3.4-1.5-3.4-3.4s1.5-3.4 3.4-3.4 3.4 1.5 3.4 3.4-1.6 3.4-3.4 3.4" />
    <path d="M25.4 22.7c.2.2.5.3.7.3s.5-.1.7-.3l5-5c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-3.3 3.3V5c0-.6-.4-1-1-1s-1 .4-1 1v14.5l-3.3-3.3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4z" />
  </svg>
);
export default IconAddCart1;
