import * as React from "react";
const IconBrowserTimer = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 24.7c-7.2 0-13 5.8-13 13s5.8 13 13 13 13-5.8 13-13c0-7.1-5.8-13-13-13m0 24c-6.1 0-11-4.9-11-11s4.9-11 11-11 11 4.9 11 11-4.9 11-11 11" />
    <path d="M32.4 38.5v-3.8c0-.6-.4-1-1-1s-1 .4-1 1v4.2c0 .3.1.5.3.7l2.5 2.5c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4z" />
    <path d="M58 4H6C3.8 4 2 5.8 2 8v48c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V8c0-2.2-1.8-4-4-4M6 6h52c1.1 0 2 .9 2 2v8.9H4V8c0-1.1.9-2 2-2m52 52H6c-1.1 0-2-.9-2-2V18.9h56V56c0 1.1-.9 2-2 2" />
  </svg>
);
export default IconBrowserTimer;
