import * as React from "react";
const IconHorse = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.3 20-3.4-6.9c-.1-.1-.1-.2-.1-.4V8.9c0-.6-.4-1-1-1h-3.2c-2.6 0-5 .7-6.9 1.9-2.2 1.4-3.9 3.7-4.8 6.5l-.6 1.9c-.2.7-.5 1.3-.9 1.9-1.3 2-3.3 3.3-5.7 3.5-7.8.8-15.6-1.8-15.7-1.9q-.9-.15-1.5-.3c-1.9-.1-3.7.5-5.2 1.7-3-.8-5.4-.7-7.1.6-2.4 1.8-2.7 4.9-2.7 6.1v8.8c0 .6.4 1 1 1h5l-4.2 6.1c-.1.2-.2.4-.2.6v8.8c0 .6.4 1 1 1h7.1c.3 0 .6-.1.8-.3s.3-.5.2-.8c-.2-1.2-.8-2.4-1.6-3.2-.2-.2-.3-.5-.1-.8l1.9-3.7c.1-.2.2-.3.4-.4l2.5-1.2c2-1 3.5-2.7 4.2-4.7 2.8.8 8.5 2.2 13.7 2.2q1.8 0 3.6-.3v12.4c0 .6.4 1 1 1h8.1c.3 0 .6-.1.8-.3s.3-.5.2-.8c-.2-1.2-.7-2.3-1.6-3.2-.2-.2-.3-.5-.3-.7l1.9-9.4c0-.2.2-.4.4-.5 3.1-1.6 4.9-4.9 4.5-8.3l-1.1-9 2.5-2.3c.1.1.2.1.3.2l1 1.4c.8 1.1 2.3 1.6 3.7 1.2 1.5-.5 2.3-1.7 2.3-3.3 0-.7-.1-1-.2-1.3M4.5 29.8c0-.7.1-3.3 1.8-4.5 1-.8 2.5-.9 4.3-.6-1 1.4-1.5 3-1.5 4.7L9 37.7c-.1 0-.2-.1-.3-.1H4.5zm54-7.4c-.5.1-1.2 0-1.5-.5l-1-1.4c-.5-.7-1.3-1.1-2.2-1.1-.3 0-.5.1-.7.3l-3.2 3c-.2.2-.3.5-.3.8l1.1 9.5c.3 2.6-1.1 5.1-3.4 6.3-.8.4-1.3 1.1-1.5 1.9L44 50.7c-.2.9.1 1.8.8 2.5q.45.45.6.9h-5.7V41.2c0-.1-.1-.1-.1-.2s-.1-.1-.1-.1c0-.1-.1-.1-.1-.1s-.1-.1-.2-.1-.1 0-.2-.1h-.5c-6.2 1.5-15.7-1.1-18.4-1.9h-.4c-.1 0-.1 0-.2.1 0 0-.1 0-.1.1-.1 0-.1.1-.2.1l-.1.1c0 .1-.1.1-.1.2l-.1.1c-.4 1.9-1.6 3.5-3.4 4.4L12.9 45c-.5.3-1 .7-1.2 1.2L9.8 50c-.5 1.1-.3 2.4.5 3.2.3.3.5.6.7 1H6.2v-7.5l4.6-6.8c.1-.2.2-.4.2-.5l.2-9.8c0-1.7.8-3.4 2.1-4.5 1.2-1.1 2.7-1.6 4.2-1.5.3 0 .7.1 1.1.2.3.1 8.2 2.7 16.5 2 3-.3 5.6-1.9 7.2-4.4.5-.7.9-1.6 1.1-2.4l.6-1.9c.7-2.3 2.1-4.2 3.9-5.4 1.6-1 3.6-1.6 5.8-1.6h2.2V13q0 .6.3 1.2l3.4 6.9v.1c-.1.5-.4 1-1.1 1.2" />
  </svg>
);
export default IconHorse;
