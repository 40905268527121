import * as React from "react";
const IconAngular = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M26.8 33.7h10.6L32 21zM32 1 3.3 11.3l4.5 38.2L32 63l24.4-13.5 4.4-38.2zm18 47.2h-6.8l-3.5-9H24.4l-3.7 9H14L32 7.9z" />
  </svg>
);
export default IconAngular;
