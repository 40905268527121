import * as React from "react";
const IconHangerAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M45.4 24.6c-.6 0-1 .4-1 1v4c0 1.8-1.5 3.4-3.3 3.4h-7.6V19.6h3.2c3 0 5.3-2.4 5.3-5.4v-4c0-.6-.4-1-1-1s-1 .4-1 1v4c0 1.8-1.5 3.4-3.3 3.4h-3.2V3c0-.6-.4-1-1-1s-1 .4-1 1v23.3h-2.7c-1.8 0-3.3-1.5-3.3-3.4v-4c0-.6-.4-1-1-1s-1 .4-1 1v4c0 3 2.4 5.4 5.3 5.4h2.7v13.3H23c-1.8 0-3.4-1.5-3.4-3.4v-4c0-.6-.4-1-1-1s-1 .4-1 1v4c0 3 2.4 5.4 5.4 5.4h8.5V60h-6.7c-.6 0-1 .4-1 1s.4 1 1 1h15.5c.6 0 1-.4 1-1s-.4-1-1-1h-6.7V34.9h7.6c3 0 5.3-2.4 5.3-5.4v-4c-.1-.5-.5-.9-1.1-.9" />
  </svg>
);
export default IconHangerAlt;
