import * as React from "react";
const IconMobileAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M43.4 2H20.6c-4 0-7.3 3.3-7.3 7.3v45.5c0 4 3.3 7.3 7.3 7.3h22.7c4 0 7.3-3.3 7.3-7.3V9.3c0-4-3.2-7.3-7.2-7.3M20.6 4h22.7c2.9 0 5.3 2.4 5.3 5.3v41.1H15.3V9.3c.1-2.9 2.4-5.3 5.3-5.3m22.8 56H20.6c-2.9 0-5.3-2.4-5.3-5.3v-2.4h33.3v2.5c0 2.8-2.3 5.2-5.2 5.2" />
  </svg>
);
export default IconMobileAlt1;
