import * as React from "react";
const IconDrupal = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M39.4 12.8c-.7-.2-1.4-.7-1.9-1.4-.3-.4-.7-1-1.2-1.7L32 3.4c-.8-1.3-2.4-1.9-4-1.7-1.6.3-2.8 1.5-3.1 3l-1.4 6.8c-.3 1.1-1.1 1.9-2.1 2.4-9.2 4-15.1 13-15.1 23 0 13.9 11.5 25.3 25.7 25.3s25.7-11.3 25.7-25.3c0-10.8-7.5-20.8-18.3-24.1m-7.4 45c-11.7 0-21.2-9.3-21.2-20.8 0-8.2 4.9-15.6 12.4-18.9 2.4-1 4.1-3 4.7-5.4l1.2-5.6 3.5 5.1c.5.7.9 1.4 1.3 1.8 1 1.4 2.6 2.6 4.2 3 8.9 2.8 15.1 11 15.1 20 0 11.4-9.5 20.8-21.2 20.8" />
    <path d="M48 32c-.4-1.7-1.4-3.2-2.9-4-1.1-.7-2.4-1-3.8-.9-1 .1-1.9.3-2.8.6-.9.4-1.7.9-2.3 1.3l-.5.3c-.1 0-.1.1-.2.1s-.1-.1-.2-.1l-.2-.3c-.5-.3-1-.8-1.8-1.1-3.1-1.5-6.5-1.9-9.4-1.1-2.1.6-3.9 2-5 3.9-.9 1.8-1.2 4-.7 6.1.6 2.3 2 4.2 3.9 5.4 1.2.7 2.6 1.1 4 1.1.7 0 1.4-.1 2.3-.3 2.9-.8 5.1-2.5 7-4.3l.6.9c1.8 2.3 5.3 3.8 8.4 2.2 1.4-.7 3.3-2.3 3.7-6 .2-1.6.2-2.7-.1-3.8m-14 1.9-1.1 1c-1.7 1.6-3.5 3.1-5.6 3.7-.5.1-.9.2-1.2.2-.6 0-1.2-.2-1.7-.5-.9-.5-1.5-1.5-1.8-2.6-.2-1-.1-2.1.3-2.8.5-.9 1.4-1.5 2.3-1.8.6-.2 1.3-.3 1.9-.3 1.4 0 2.9.4 4.2 1.1l.1.1c.3.1.6.4 1 .6l.3.2c.2.2.4.3.7.5s.5.3.8.5c-.1 0-.1 0-.2.1m9.7 1.2c-.1.9-.4 2.1-1.3 2.6s-2.2-.2-2.8-1c-.2-.2-.3-.5-.5-.8l-.3-.5c-.5-.8-.9-1.4-1.4-1.8.3-.2.6-.3.9-.5l.4-.3c.5-.4 1.1-.7 1.6-.9.4-.2.9-.3 1.3-.3.5 0 .9.1 1.1.2l.1.1c.4.2.7.7.8 1.3v.1c.2.4.2 1 .1 1.8M36.5 50.5l-1.2.6c-.9.3-1.8.4-2.7.4-.9-.1-1.8-.3-2.8-.7-1.1-.5-2.5 0-3 1.2-.5 1.1 0 2.5 1.2 3 1.4.6 2.8 1 4.2 1.1h.8c1.2 0 2.5-.2 3.7-.6.7-.3 1.4-.6 2.1-1 1.1-.6 1.4-2 .8-3.1-.7-1.2-2-1.6-3.1-.9M37.6 43.1c-2 0-3.9.9-5.2 2.3-.9.9-.8 2.3.1 3.2.4.4 1 .6 1.5.6.6 0 1.2-.2 1.6-.7.5-.5 1.3-.9 2-.9.5 0 .9.1 1.1.3.9.9 2.3.9 3.2 0s.9-2.3 0-3.2c-1-1-2.5-1.5-4.3-1.6" />
  </svg>
);
export default IconDrupal;
