import * as React from "react";
const IconEgg = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 62c-6.6 0-12.3-2.1-16.4-6-2.2-2.1-3.9-4.7-5-7.8-1.2-3.2-1.8-6.8-1.8-10.7 0-7.9 2.4-16.6 6.4-23.4 2.1-3.6 4.5-6.4 7.2-8.5C28.5.9 35.5.9 41.6 5.6c2.7 2.1 5.1 4.9 7.2 8.5 4 6.8 6.4 15.5 6.4 23.4 0 4-.6 7.6-1.8 10.7-1.1 3.1-2.8 5.7-5 7.8-4.1 3.9-9.8 6-16.4 6m0-58c-2.9 0-5.7 1.1-8.4 3.1-2.5 1.9-4.7 4.6-6.7 7.9-3.8 6.5-6.1 14.9-6.1 22.4 0 3.7.6 7.1 1.7 10 1 2.8 2.6 5.1 4.6 7.1 3.7 3.6 8.9 5.5 15 5.5s11.3-1.9 15-5.5c2-1.9 3.5-4.3 4.6-7.1 1.1-2.9 1.7-6.3 1.7-10 0-7.5-2.3-15.9-6.1-22.4-2-3.4-4.2-6-6.7-7.9C37.7 5.1 34.9 4 32 4" />
  </svg>
);
export default IconEgg;
