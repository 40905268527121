import * as React from "react";
const IconHandbag3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.7 29.2c-1.1-1.3-2.7-2.1-4.4-2.1H40C39.7 14.5 37 2 32 2s-7.7 12.5-8 25.1H13.8c-1.7 0-3.3.8-4.4 2.1s-1.5 3.1-1.2 4.7l4.5 23.4c.5 2.7 2.9 4.7 5.6 4.7h27.5c2.7 0 5.1-2 5.6-4.7l4.5-23.4c.3-1.7-.1-3.4-1.2-4.7M32 4c2.4 0 5.7 8.8 6 23.1H26C26.3 12.8 29.6 4 32 4m21.9 29.5L49.4 57c-.3 1.8-1.9 3-3.7 3H18.3c-1.8 0-3.3-1.3-3.7-3l-4.5-23.4c-.2-1.1.1-2.2.8-3.1s1.8-1.4 2.9-1.4h36.4c1.1 0 2.2.5 2.9 1.4.7.8 1 1.9.8 3" />
  </svg>
);
export default IconHandbag3;
