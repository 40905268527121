import * as React from "react";
const IconQuoteAlt5 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.9 26.9h-1.6c-1.1 0-2.1-.6-2.7-1.5s-.6-2.1-.1-3.1l2.3-4.6c.8-1.6.7-3.5-.2-5s-2.6-2.4-4.4-2.4H47c-2 0-3.7 1.1-4.6 2.8l-6.7 13.4c-.4.7-.5 1.5-.5 2.3v19.7c0 2.8 2.3 5.1 5.1 5.1h16.6c2.8 0 5.1-2.3 5.1-5.1V32c0-2.8-2.3-5.1-5.1-5.1M60 48.6c0 1.7-1.4 3.1-3.1 3.1H40.3c-1.7 0-3.1-1.4-3.1-3.1V28.8c0-.5.1-1 .3-1.4L44.2 14c.5-1.1 1.6-1.7 2.8-1.7h3.2c1.1 0 2.1.6 2.7 1.5s.6 2.1.1 3.1l-2.3 4.6c-.8 1.6-.7 3.5.2 5s2.6 2.4 4.4 2.4h1.6c1.7 0 3.1 1.4 3.1 3.1zM23.7 26.9h-1.6c-1.1 0-2.1-.6-2.7-1.5s-.6-2.1-.1-3.1l2.3-4.6c.8-1.6.7-3.5-.2-5s-2.6-2.4-4.4-2.4h-3.2c-2 0-3.7 1.1-4.6 2.8L2.5 26.5c-.3.7-.5 1.5-.5 2.3v19.7c0 2.8 2.3 5.1 5.1 5.1h16.6c2.8 0 5.1-2.3 5.1-5.1V32c.1-2.8-2.3-5.1-5.1-5.1m3.2 21.7c0 1.7-1.4 3.1-3.1 3.1H7.1c-1.7 0-3.1-1.4-3.1-3.1V28.8c0-.5.1-1 .3-1.4L11 14c.5-1.1 1.6-1.7 2.8-1.7H17c1.1 0 2.1.6 2.7 1.5s.6 2.1.1 3.1l-2.3 4.6c-.8 1.6-.7 3.5.2 5s2.6 2.4 4.4 2.4h1.6c1.7 0 3.1 1.4 3.1 3.1v16.6z" />
  </svg>
);
export default IconQuoteAlt5;
