import * as React from "react";
const IconProjector = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56 18.6h-6.7c-2.5-1.9-5.6-3.1-9-3.1s-6.5 1.2-9 3.1H8c-2.8 0-5 2.2-5 5v13.2c0 2.8 2.2 5 5 5h1v5.7c0 .6.4 1 1 1s1-.4 1-1v-5.7h20.3c2.5 1.9 5.6 3.1 9 3.1s6.5-1.2 9-3.1H53v5.7c0 .6.4 1 1 1s1-.4 1-1v-5.7h1c2.8 0 5-2.2 5-5V23.6c0-2.8-2.2-5-5-5M5 36.8V23.6c0-1.7 1.3-3 3-3h21.2c-2.2 2.6-3.6 5.9-3.6 9.6s1.4 7 3.6 9.6H8c-1.7 0-3-1.4-3-3m22.6-6.6c0-7 5.7-12.7 12.7-12.7S53 23.2 53 30.2s-5.7 12.7-12.7 12.7-12.7-5.7-12.7-12.7M59 36.8c0 1.7-1.3 3-3 3h-4.6c2.2-2.6 3.6-5.9 3.6-9.6s-1.4-7-3.6-9.6H56c1.7 0 3 1.3 3 3z" />
    <path d="M17.2 25.7h-5.1c-.6 0-1 .4-1 1s.4 1 1 1h5.1c.6 0 1-.4 1-1s-.5-1-1-1M17.2 32.7h-5.1c-.6 0-1 .4-1 1s.4 1 1 1h5.1c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconProjector;
