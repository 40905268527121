import * as React from "react";
const IconSpeechBubble45 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.8 17.7C48.2 9.4 40.6 3.8 31.9 3.8S15.5 9.5 13 17.8c-6.2.8-11 6.3-11 12.6 0 7.1 5.8 12.8 12.8 12.8h4.8c-1.5 2.9-4.7 7.5-11.2 12.1-1 .7-1.4 2-1 3.1s1.4 1.8 2.5 1.8h.3c8.3-.8 15.4-4.2 20.5-10 2.4-2.7 3.8-5.3 4.6-6.9h13.8c7.1 0 12.8-5.8 12.8-12.8.1-6.6-4.8-12-11.1-12.8m-1.6 23.6H34.7c-.4 0-.8.2-.9.6-.6 1.4-2 4.2-4.5 7-4.8 5.4-11.4 8.7-19.2 9.4-.5 0-.7-.3-.7-.5 0-.1-.2-.5.2-.8 8-5.7 11.3-11.4 12.6-14.3.1-.3.1-.7-.1-.9-.2-.3-.5-.5-.8-.5h-6.4C8.9 41.3 4 36.4 4 30.4c0-5.6 4.3-10.3 9.9-10.8.4 0 .8-.3.9-.7 2.1-7.7 9.1-13.1 17.1-13.1S47 11.2 49 18.9c.1.4.5.7.9.7C55.6 20 60 24.7 60 30.4c0 6-4.9 10.9-10.8 10.9" />
    <path d="M21.3 26.4H21c-1.3 0-2.4 1.1-2.4 2.4s1.1 2.4 2.4 2.4h.3c1.3 0 2.4-1.1 2.4-2.4s-1.1-2.4-2.4-2.4M31.7 26.4h-.3c-1.3 0-2.4 1.1-2.4 2.4s1.1 2.4 2.4 2.4h.3c1.3 0 2.4-1.1 2.4-2.4s-1.1-2.4-2.4-2.4M42 26.4h-.3c-1.3 0-2.4 1.1-2.4 2.4s1.1 2.4 2.4 2.4h.3c1.3 0 2.4-1.1 2.4-2.4s-1.1-2.4-2.4-2.4" />
  </svg>
);
export default IconSpeechBubble45;
