import * as React from "react";
const IconShipStar = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M42.8 57c-.3-.2-.5-.4-.8-.6l4.5-16.1c.7-2.5-.6-5.2-3-6.2l-3.3-1.4v-4.9c0-.6-.4-1-1-1s-1 .4-1 1v4l-9.7-4.1c-1.3-.5-2.7-.5-4 0L14.8 32v-7.4c0-1.8 1.4-3.2 3.2-3.2h13.6c.6 0 1-.4 1-1s-.4-1-1-1H19.7v-6c0-1.8 1.4-3.2 3.2-3.2h8c.6 0 1-.4 1-1s-.4-1-1-1h-3.4V3c0-.6-.4-1-1-1s-1 .4-1 1v5.3h-2.6c-2.9 0-5.2 2.3-5.2 5.2v6c-2.8.1-5 2.4-5 5.2v8.2l-3.2 1.3c-2.4 1-3.7 3.7-3 6.2L11 56.5c-.3.2-.5.3-.8.6l-3.7 3.2c-.4.4-.5 1-.1 1.4.2.2.5.3.8.3.2 0 .5-.1.7-.2l3.7-3.2c1.2-1 3-1 4.2 0l1 .8c1.9 1.7 4.8 1.7 6.8 0l1-.8c1.2-1 3-1 4.2 0l1 .8c1.9 1.7 4.8 1.7 6.8 0l1-.8c1.2-1 3-1 4.2 0l3.7 3.2c.4.4 1 .3 1.4-.1s.3-1-.1-1.4zM36 57l-1 .8c-1.2 1-3 1-4.2 0l-1-.8c-1.9-1.7-4.8-1.7-6.8 0l-1 .8c-1.2 1-3 1-4.2 0L17 57c-1.1-1-2.6-1.4-4.1-1.2l-4.5-16c-.4-1.5.4-3.2 1.9-3.8l3.8-1.6h.1l11.1-4.6c.8-.3 1.7-.3 2.4 0l11.1 4.6h.1l3.8 1.6c1.5.6 2.3 2.2 1.8 3.8l-4.5 16c-1.4-.1-2.8.2-4 1.2M57.8 9.9q-.15-.45-.6-.6l-4.4-1.5L49.9 4c-.4-.5-1.2-.5-1.6 0l-2.8 3.7-4.4 1.5q-.45.15-.6.6c-.15.45 0 .6.1.9l2.7 3.8v4.7c0 .3.2.6.4.8s.6.2.9.1l4.4-1.4 4.4 1.4h.3c.2 0 .4-.1.6-.2.3-.2.4-.5.4-.8v-4.7l2.7-3.8c.4-.1.4-.4.4-.7m-4.7 3.8c-.1.2-.2.4-.2.6v3.6l-3.5-1.1h-.6l-3.5 1.1v-3.6c0-.2-.1-.4-.2-.6L43 10.8l3.4-1.2c.2-.1.4-.2.5-.4l2.2-2.9 2.2 2.9c.1.2.3.3.5.4l3.4 1.2z" />
  </svg>
);
export default IconShipStar;
