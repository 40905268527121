import React, { useState, useEffect } from 'react';
import { useNavigation } from '../../../context/NavigationContext';
import {
	BringzzPageContainer,
	BringzzIcon,
	BringzzText,
} from '@bringzz/components';
import useUser from '../../../hooks/useUser';

const PharmacyInfo = () => {
	const { backPress, navigate } = useNavigation();
	const { getUser } = useUser();
	const user = getUser();

	const pharmacistFields = [
		{
			name: 'Profile',
			path: 'profile',
            icon: 'IconHospitalAlt'
		},
		{ name: 'Address', path: 'address', icon: 'IconHospitalLocation' },
		{
			name: 'Opening hours',
			path: 'opening',
            icon: 'IconClockAlt1'
		},
		{ name: 'Night duties', path: 'duties',icon: 'IconClockAlt1' },
	];

	const [fields, setFields] = useState([]);

	useEffect(() => {
		if (user) {
            setFields(pharmacistFields);
		}
	}, [user]);

	return (
		<BringzzPageContainer
			header={{
				left: (
					<BringzzIcon
						size='22'
						icon='IconChevronLeft'
						folder='LineIcons'
						className='cursor-pointer'
						onClick={backPress}
					/>
				),
				title: <BringzzText tag='h2'>Pharmacy Info</BringzzText>
			}}
		>
			<div className='p-4'>
				{fields.map((field, index) => (
					<div
						key={index}
						className='flex items-center justify-between cursor-pointer  py-4'
						onClick={() => field.path && navigate(`/settings/pharmacy/info/${field.path}`)}
					>
						<div className='flex flex-row items-center '>
                            <div className='items-center' >
                            <BringzzIcon size='22' icon={field.icon} folder={'LineIcons'} />

                                </div>
                                <div className='items-center'>
                                <BringzzText className={"px-4"} tag='h3'>{field.name}</BringzzText>

                                </div>		
						</div>
						<BringzzIcon size='22' icon={'IconArrowRight'} folder={'LineIcons'} />
					</div>
				))}
			</div>
		</BringzzPageContainer>
	);
};

export default PharmacyInfo;
