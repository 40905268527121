import * as React from "react";
const IconMasked = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      d="M18 30h12v2H18zM30 34H18v2h12zM21.302 22.442c.13-.358.091-.795-.016-1.193a4.2 4.2 0 0 0-.61-1.28c-.581-.829-1.544-1.59-2.845-1.646-1.347-.056-2.353.799-2.973 1.706a5.6 5.6 0 0 0-.695 1.416c-.143.446-.219.902-.169 1.267a.5.5 0 0 0 .766.352c.4-.256.819-.607 1.207-.931.176-.148.347-.29.505-.415.562-.444 1-.697 1.363-.715.344-.017.742.18 1.244.556.18.134.353.276.534.424l.195.159c.244.197.504.399.766.557a.5.5 0 0 0 .728-.257M26.613 22.442c-.13-.358-.09-.795.017-1.193.112-.416.319-.863.61-1.28.58-.829 1.544-1.59 2.845-1.646 1.346-.056 2.353.799 2.973 1.706.314.46.548.958.695 1.416.142.446.218.902.168 1.267a.5.5 0 0 1-.765.352c-.4-.256-.82-.607-1.207-.931-.177-.148-.347-.29-.505-.415-.563-.444-1-.697-1.363-.715-.344-.017-.743.18-1.244.556-.18.134-.354.276-.534.424l-.196.159c-.244.197-.503.399-.765.557a.5.5 0 0 1-.729-.257"
    />
    <path
      
      fillRule="evenodd"
      d="M41.853 26.315a17.95 17.95 0 0 1-5.336 10.62L36.5 37q-.28.243-.564.473A17.93 17.93 0 0 1 24 42a17.92 17.92 0 0 1-10.875-3.655q-.57-.4-1.125-.845l-.039-.118a17.97 17.97 0 0 1-5.792-10.904c-.902-.656-1.677-1.582-1.834-2.864-.194-1.59.612-3.345 2.434-5.296a1 1 0 0 1 .203-.168C9.4 11.08 16.107 6 24 6c7.984 0 14.754 5.198 17.11 12.395 1.771 1.92 2.554 3.65 2.362 5.219-.144 1.179-.812 2.057-1.62 2.701M14.308 36.732c6.38 4.468 14.025 4.323 20.332-.783l.081-.073c.41-1.665.6-2.88.567-4.037-.03-1.042-.242-2.131-.755-3.531-4.117-.863-7.348-1.296-10.54-1.308-3.17-.011-6.378.393-10.443 1.279-.449 1.487-.637 2.644-.633 3.752.005 1.217.242 2.494.777 4.21q.3.252.614.491m-3.316-3.412c-.18-1.622-.036-3.135.451-4.948a19 19 0 0 1-.913-.166c-.625-.13-1.37-.318-2.12-.588a15.9 15.9 0 0 0 2.582 5.702m24.524-6.848c-8.94-1.919-14.151-2.01-23.116.013l-.27-.034c-.296-.04-.713-.104-1.193-.204-.911-.189-1.989-.492-2.887-.956A16 16 0 0 1 8 24c0-8.837 7.164-16 16-16s16 7.163 16 16q0 .597-.043 1.183l-.012.007c-.933.52-2.1.855-3.075 1.057a17 17 0 0 1-1.353.225m1.138 1.852c.547 1.693.716 3.113.605 4.632a15.9 15.9 0 0 0 2.347-5.416c-.821.312-1.647.524-2.33.666q-.333.069-.622.118"
      clipRule="evenodd"
    />
  </svg>
);
export default IconMasked;
