import * as React from "react";
const IconChurch = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m57 28.5-10.7-5.1c-1.1-.5-1.8-1.6-1.8-2.8v-9.7l2.3 1.4c.5.3 1.1.1 1.4-.4s.1-1.1-.4-1.4L34.5 2.7c-1.6-1-3.6-.9-5.2 0l-13.2 8c-.5.3-.6.9-.3 1.4.2.3.5.5.9.5.2 0 .4 0 .5-.1l2.3-1.4v9.7c0 1.2-.7 2.3-1.8 2.8L7 28.6c-.5.2-.7.8-.5 1.3q.3.6.9.6c.1 0 .3 0 .4-.1l3.6-1.7v28.2c0 2.8 2.3 5.1 5.1 5.1h30.6c2.8 0 5.1-2.3 5.1-5.1V28.5l3.7 1.8c.1.1.3.1.4.1q.6 0 .9-.6c.5-.4.3-1-.2-1.3M27.4 60V49.3c0-2.5 2-4.5 4.5-4.5s4.5 2 4.5 4.5V60zm23-32.4v29.3c0 1.7-1.4 3.1-3.1 3.1h-8.8V49.3c0-3.6-2.9-6.5-6.5-6.5s-6.5 2.9-6.5 6.5V60h-8.8c-1.7 0-3.1-1.4-3.1-3.1V27.7l5.1-2.4c1.8-.8 2.9-2.7 2.9-4.6V9.8l8.9-5.4c1-.6 2.2-.6 3.2 0l9 5.3v10.8c0 1.9 1.1 3.8 2.9 4.6z" />
    <path d="M36.6 27.7h-3.7V24c0-.6-.4-1-1-1s-1 .4-1 1v3.7h-3.7c-.6 0-1 .4-1 1s.4 1 1 1h3.7v3.7c0 .6.4 1 1 1s1-.4 1-1v-3.7h3.7c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconChurch;
