import * as React from "react";
const IconMonkey = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 65 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.9 61H8.5c-.6 0-1-.4-1-1s.4-1 1-1h10.4c2.1 0 3.7-1.7 3.7-3.7V43.5c-1 .9-2.3 1.4-3.7 1.4H8.5c-.6 0-1-.4-1-1s.4-1 1-1h10.4c2.1 0 3.7-1.7 3.7-3.7v-6.1c-7.5-.4-13.4-6.6-13.4-14.2v-3.7H5.6c-.6 0-1-.4-1-1s.4-1 1-1h3.6v-.6c0-5.2 4.3-9.5 9.5-9.5h9.4c5.2 0 9.5 4.3 9.5 9.5v.6H41c.6 0 1 .4 1 1s-.4 1-1 1h-3.3v3.7c0 1.8-.3 3.6-1 5.2 7 .6 12.1 6.5 12.1 14.2V59h2.1c4.1 0 7.5-3.4 7.5-7.5V30.9c0-4.9-4-8.9-8.9-8.9-.6 0-1-.4-1-1s.4-1 1-1c6 0 10.9 4.9 10.9 10.9v20.6c0 5.2-4.3 9.5-9.5 9.5m-27.6-2h23.5V38.2c0-7-4.6-12.1-11-12.2-2.3 3.9-6.3 6.6-11 7v22.2c-.1 1.5-.6 2.8-1.5 3.8M18.8 5c-4.1 0-7.5 3.4-7.5 7.5v6.4c0 6.7 5.5 12.2 12.2 12.2s12.2-5.5 12.2-12.2v-6.4c0-4.1-3.4-7.5-7.5-7.5z" />
  </svg>
);
export default IconMonkey;
