import * as React from "react";
const IconMobileApplication = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M43.4 2H20.6c-4 0-7.3 3.3-7.3 7.3v45.5c0 4 3.3 7.3 7.3 7.3h22.7c4 0 7.3-3.3 7.3-7.3V9.3c0-4-3.2-7.3-7.2-7.3M20.6 4h22.7c2.9 0 5.3 2.4 5.3 5.3v41.1H15.3V9.3c.1-2.9 2.4-5.3 5.3-5.3m22.8 56H20.6c-2.9 0-5.3-2.4-5.3-5.3v-2.4h33.3v2.5c0 2.8-2.3 5.2-5.2 5.2" />
    <path d="M22.2 26.1H32c.6 0 1-.4 1-1v-9.7c0-.6-.4-1-1-1h-9.7c-.6 0-1 .4-1 1v9.7c-.1.5.4 1 .9 1m1-9.8H31V24h-7.7v-7.7zM22.2 44.1H32c.6 0 1-.4 1-1v-9.7c0-.6-.4-1-1-1h-9.7c-.6 0-1 .4-1 1v9.7c-.1.6.4 1 .9 1m1-9.7H31v7.7h-7.7v-7.7zM41 26.1h1.9c.6 0 1-.4 1-1s-.4-1-1-1H42v-7.7h.9c.6 0 1-.4 1-1s-.4-1-1-1H41c-.6 0-1 .4-1 1v9.7c0 .5.5 1 1 1M41 44.1h1.9c.6 0 1-.4 1-1s-.4-1-1-1H42v-7.7h.9c.6 0 1-.4 1-1s-.4-1-1-1H41c-.6 0-1 .4-1 1v9.7c0 .6.5 1 1 1" />
  </svg>
);
export default IconMobileApplication;
