import * as React from "react";
const IconCenterAlign4 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M48.3 38.9H33v-9.1h8.8c3.2 0 5.7-2.6 5.7-5.7V22c0-3.2-2.6-5.7-5.7-5.7H33V3c0-.6-.4-1-1-1s-1 .4-1 1v13.2h-8.8c-3.2 0-5.7 2.6-5.7 5.7V24c0 3.2 2.6 5.7 5.7 5.7H31v9.1H15.7c-3.2 0-5.7 2.6-5.7 5.7v2.1c0 3.2 2.6 5.7 5.7 5.7H31V61c0 .6.4 1 1 1s1-.4 1-1v-8.5h15.3c3.2 0 5.7-2.6 5.7-5.7v-2.1c.1-3.2-2.5-5.8-5.7-5.8M18.5 24.1V22c0-2.1 1.7-3.7 3.7-3.7h19.5c2.1 0 3.7 1.7 3.7 3.7v2.1c0 2.1-1.7 3.7-3.7 3.7H22.2c-2 0-3.7-1.6-3.7-3.7m33.6 22.7c0 2.1-1.7 3.7-3.7 3.7H15.7c-2.1 0-3.7-1.7-3.7-3.7v-2.1c0-2.1 1.7-3.7 3.7-3.7h32.7c2.1 0 3.7 1.7 3.7 3.7z" />
  </svg>
);
export default IconCenterAlign4;
