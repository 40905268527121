import * as React from "react";
const IconMn = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 513 342"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 0h513v342H0z" />
    <path  d="M342 0h171v342H342zM0 0h171v342H0z" />
    <g >
      <path d="M108.3 166.3h14.8v74.2h-14.8zm-59.4 0h14.8v74.2H48.9z" />
      <circle cx={86} cy={203.4} r={14.8} />
      <path d="M71.2 225.7h29.7v14.8H71.2zm0-59.4h29.7v14.8H71.2z" />
      <circle cx={86} cy={144} r={14.8} />
      <path d="M76.3 123.9h19.5L86 108.3z" />
    </g>
  </svg>
);
export default IconMn;
