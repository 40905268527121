import { useState, useCallback } from 'react';

const useUser = () => {
    const [user, setUserState] = useState(() => {
        try {
            const savedUser = localStorage.getItem('user');
            return savedUser ? JSON.parse(savedUser) : null;
        } catch (error) {
            return null;
        }
    });

    const setUser = useCallback((userData) => {
        try {
            const userDataString = JSON.stringify(userData);
            localStorage.setItem('user', userDataString);
            setUserState(userData);
        } catch (error) {
            console.error('Error setting user data to localStorage:', error);
        }
    }, []);

    const getUser = useCallback(() => {
        return user;
    }, [user]);

    return { getUser, setUser, user };
};

export default useUser;