import * as React from "react";
const IconSweating = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      d="M24 40c4.147 0 7.925-1.577 10.767-4.165.642.38 1.365.63 2.136.715A17.95 17.95 0 0 1 24 42a17.9 17.9 0 0 1-9.795-2.896 5.7 5.7 0 0 0 1.194-1.61A15.93 15.93 0 0 0 24 40M42 24q-.001 1.321-.185 2.593a18.6 18.6 0 0 0-1.817-2.828C39.873 15.037 32.758 8 24 8 15.163 8 8 15.163 8 24c0 1.141.12 2.254.347 3.328a21 21 0 0 0-1.51 2.114A18 18 0 0 1 6 24c0-9.941 8.059-18 18-18s18 8.059 18 18"
    />
    <path
      
      d="M14.807 22.233c-.683-.438-1.183-1.145-1.064-1.883a.5.5 0 0 1 .668-.389c1.818.675 3.846.256 5.642-1.448a.5.5 0 0 1 .818.203c.231.689.024 1.618-.48 2.382a3.8 3.8 0 0 1-2.654 1.665c-1.198.177-2.216-.073-2.93-.53M32.754 22.233c.683-.438 1.184-1.145 1.065-1.883a.5.5 0 0 0-.668-.389c-1.818.675-3.846.256-5.642-1.448a.5.5 0 0 0-.818.203c-.232.689-.024 1.618.48 2.382a3.8 3.8 0 0 0 2.653 1.665c1.199.177 2.216-.073 2.93-.53M37.5 34c1.933 0 3.5-1.465 3.5-3.273C41 27.864 37.5 25 37.5 25S34 27.864 34 30.727C34 32.535 35.567 34 37.5 34M14 34.727C14 36.535 12.433 38 10.5 38S7 36.535 7 34.727C7 31.864 10.5 29 10.5 29s3.5 2.864 3.5 5.727"
    />
    <path
      
      fillRule="evenodd"
      d="M31.407 33C30.03 29.438 27.23 27 24 27s-6.03 2.438-7.407 6c-.387 1.003.42 2 1.495 2h11.824c1.075 0 1.882-.997 1.495-2M24 29c1.96 0 3.992 1.386 5.232 4H18.768c1.24-2.614 3.272-4 5.232-4"
      clipRule="evenodd"
    />
  </svg>
);
export default IconSweating;
