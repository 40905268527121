import * as React from "react";
const IconIdeaPresentation = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M26.5 37.6c.6.4.9 1.1.9 1.8v1.8c0 2.3 1.8 4.1 4.1 4.1h1c2.3 0 4.1-1.8 4.1-4.1v-1.9c0-.4.1-.7.3-1s.4-.6.7-.7c3-1.9 4.8-5.1 4.9-8.6.1-2.8-1-5.4-2.9-7.4s-4.5-3.2-7.3-3.3-5.5 1-7.6 3-3.1 4.6-3.1 7.5c0 3.6 1.8 6.9 4.9 8.8m8.1 3.6c0 1.2-1 2.1-2.1 2.1h-1c-1.2 0-2.1-1-2.1-2.1v-2.3h5.3v2.3zm-8.5-18.5c1.6-1.5 3.7-2.4 5.9-2.4h.2c2.2.1 4.3 1 5.9 2.7s2.4 3.8 2.3 6c-.1 2.8-1.6 5.4-4 6.9-.4.3-.8.6-1.1 1h-6.8c-.3-.4-.6-.7-1.1-1-2.5-1.6-4-4.2-4-7.2.2-2.3 1.1-4.4 2.7-6" />
    <path d="M57.2 2H6.8c-2.2 0-4 1.8-4 4v2.4c0 2.2 1.8 4 4 4h.5v34.3c0 4.4 3.6 8 8 8H31V61c0 .6.4 1 1 1s1-.4 1-1v-6.4h15.7c4.4 0 8-3.6 8-8V12.4h.5c2.2 0 4-1.8 4-4V6c0-2.2-1.8-4-4-4m-2.5 44.7c0 3.3-2.7 6-6 6H15.3c-3.3 0-6-2.7-6-6V12.4h45.4zm4.5-38.3c0 1.1-.9 2-2 2H6.8c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2h50.3c1.1 0 2 .9 2 2v2.4z" />
  </svg>
);
export default IconIdeaPresentation;
