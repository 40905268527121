import * as React from "react";
const IconWoman = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M24 11a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5m0 2a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9M20.96 17c-1.37 0-2.298.665-2.907 1.531-.564.801-.897 1.829-1.168 2.717-.2.66-.237.798-.331 1.159l-.003.011c-.131.503-.365 1.396-1.195 4.328-.134.477.132 1.048.735 1.213.62.171 1.195-.202 1.338-.705.91-3.22 1.106-3.984 1.204-4.366v-.003c.078-.305.106-.41.314-1.094.28-.92.54-1.657.897-2.164l.002-.004.009-.011 1.688.873-3.96 14.315c1.18.314 3.055.7 6.418.7 3.258 0 5.3-.363 6.427-.665l-3.97-14.35 1.687-.876.012.018c.357.507.617 1.244.897 2.165.208.684.236.788.314 1.093v.003c.098.382.294 1.147 1.204 4.366.143.503.717.876 1.338.705.603-.165.87-.736.735-1.213a212 212 0 0 1-1.18-4.273l-.018-.066c-.094-.361-.13-.5-.331-1.16-.27-.887-.604-1.915-1.168-2.716-.61-.866-1.537-1.531-2.906-1.531H20.96m12.338 4.894c.127.486.357 1.368 1.183 4.285a3 3 0 0 1-2.084 3.715 3 3 0 0 1-1.327.056l1.213 4.385c.271.98-.214 2.114-1.27 2.407-1.292.36-3.525.758-7.012.758-3.536 0-5.571-.41-6.905-.766-1.06-.283-1.646-1.428-1.352-2.49l1.188-4.294c-.432.08-.885.065-1.328-.056a3 3 0 0 1-2.084-3.715c.826-2.917 1.056-3.798 1.183-4.285v-.003c.104-.396.147-.557.358-1.25.263-.863.675-2.193 1.449-3.294.91-1.295 2.377-2.347 4.45-2.347h6.081999999999999c2.072 0 3.538 1.052 4.45 2.347.774 1.1 1.185 2.43 1.448 3.294.201.66.25.837.343 1.195zM19 39.014v3.374a1.5 1.5 0 0 0 2.947.394l.921-3.375A39 39 0 0 1 19 39.014m7.13.386.923 3.382A1.5 1.5 0 0 0 30 42.388V39a41 41 0 0 1-3.87.4"
      clipRule="evenodd"
    />
  </svg>
);
export default IconWoman;
