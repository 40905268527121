import * as React from "react";
const IconBridgeAlt4 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 14H3c-.6 0-1 .4-1 1s.4 1 1 1h3.7v29c0 2.8 2.2 5 5.1 5 2.3 0 4.3-1.6 4.9-3.9 1.8-7.1 8.1-12 15.4-12s13.6 4.9 15.4 12c.6 2.3 2.6 3.9 4.9 3.9h.1c2.8 0 5-2.2 5-5V16H61c.6 0 1-.4 1-1s-.4-1-1-1m-28 9.8V16h10.1v7.8zm-12.1 0V16H31v7.8zm-2-7.8v7.8H8.7V16zm36.4 29c0 1.7-1.3 3-3 3h-.1c-1.4 0-2.6-1-2.9-2.4-2-7.9-9.1-13.5-17.3-13.5s-15.3 5.6-17.3 13.5c-.3 1.4-1.6 2.4-3 2.4-1.7 0-3-1.3-3-3V25.8h46.6zm0-21.2H45.1V16h10.1v7.8z" />
  </svg>
);
export default IconBridgeAlt4;
