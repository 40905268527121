import * as React from "react";
const IconStackoverflow = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.2 57.5H11.5V40.9H5.8V63h49.8V40.9h-5.4zM41.732.98l-4.417 3.277 16.444 22.166 4.417-3.277zM31.56 9.876l-3.517 4.23 21.221 17.647 3.517-4.23z" />
    <path d="M44.6 46.4H17v5.5h27.6zM48.4 33.1 23.5 21.3l-2.4 5.1 25 11.7zM45.7 39.6l-27-5.8-1.3 5.5L44.6 45z" />
  </svg>
);
export default IconStackoverflow;
