import * as React from "react";
const IconBabyMale0609m = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M16 15a5 5 0 1 1-1.903-3.925L17.172 8H15a1 1 0 1 1 0-2h4.5a1 1 0 0 1 1 1v5a1 1 0 1 1-2 0V9.5l-3.108 3.108c.388.71.608 1.526.608 2.392m-2 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0M30 17a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11m0-2a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M24 28.94q0 .184.033.393C22.179 30.923 21 33.253 21 35.857c0 .957.19 2.389.737 3.62C22.278 40.697 23.319 42 25.11 42c1.745 0 3.222-1.373 3.222-3.143 0-.753-.302-1.404-.507-1.844l-.03-.064c-.236-.51-.352-.798-.352-1.092 0-.289.166-.771.527-1.324.21-.32.448-.608.674-.835.468.181.929.302 1.355.302.427 0 .887-.12 1.355-.302.226.227.465.514.674.835.36.553.527 1.035.527 1.324 0 .286-.117.58-.354 1.1l-.031.067c-.203.442-.504 1.096-.504 1.833 0 1.77 1.477 3.143 3.222 3.143 1.786 0 2.817-1.389 3.35-2.59.549-1.239.761-2.666.761-3.553 0-2.604-1.18-4.933-3.033-6.524q.033-.208.033-.393v-5.139q.594-.117 1.206-.255a59 59 0 0 0 2.59-.653 3 3 0 0 0-1.591-5.786c-3.364.925-5.819 1.317-8.192 1.307-2.384-.01-4.851-.426-8.254-1.316a3 3 0 1 0-1.518 5.804c1.323.346 2.565.64 3.76.873zm15.028-7.91.237-.066a1 1 0 0 0-.53-1.928c-3.465.952-6.111 1.39-8.731 1.378-2.623-.01-5.274-.472-8.75-1.381a1 1 0 0 0-.507 1.935c1.597.418 3.05.75 4.425.99a1 1 0 0 1 .828.985V26h8v-3.03a1 1 0 0 1 .829-.985 47 47 0 0 0 4.199-.956M24.885 31.272C23.711 32.478 23 34.091 23 35.857c0 .758.16 1.897.564 2.808.411.925.926 1.335 1.547 1.335.71 0 1.222-.546 1.222-1.143 0-.274-.111-.548-.351-1.067l-.022-.047c-.209-.45-.516-1.11-.516-1.886 0-.863.41-1.741.852-2.417q.211-.321.455-.623a5.05 5.05 0 0 1-1.866-1.544m8.364 1.544q.245.302.455.623c.441.676.852 1.554.852 2.417 0 .765-.306 1.431-.514 1.884l-.022.047c-.242.53-.353.808-.353 1.07 0 .596.512 1.142 1.222 1.142.559 0 1.084-.414 1.52-1.4.42-.947.591-2.091.591-2.743 0-1.766-.711-3.38-1.885-4.584a5.05 5.05 0 0 1-1.866 1.544M34 28.94c0 .09-.067.475-.354.938-.27.437-.706.89-1.373 1.188-.229.102-.452.213-.676.324l-.109.054c-.245.121-.463.227-.678.318-.466.197-.714.238-.81.238s-.344-.041-.81-.238a13 13 0 0 1-.68-.318l-.127-.063a18 18 0 0 0-.656-.315 3.06 3.06 0 0 1-1.373-1.187c-.287-.464-.354-.849-.354-.939V28h7.982z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconBabyMale0609m;
