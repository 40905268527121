import * as React from "react";
const IconVrAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.6 16.9H9.4c-3.6 0-6.4 2.9-6.4 6.4v17.4c0 3.6 2.9 6.4 6.4 6.4H23c.4 0 .7-.1 1.1-.2l5.7-2.6c.7-.3 1.4-.5 2.2-.5s1.5.2 2.2.5l5.7 2.6c.3.2.7.2 1.1.2h13.6c3.6 0 6.4-2.9 6.4-6.4V23.3c0-3.6-2.9-6.4-6.4-6.4M59 40.7c0 2.5-2 4.4-4.4 4.4H40.9c-.1 0-.2 0-.2-.1L35 42.5c-.9-.4-2-.7-3-.7s-2.1.2-3 .7l-5.7 2.6c-.1 0-.2.1-.2.1H9.4c-2.5 0-4.4-2-4.4-4.4V23.3c0-2.5 2-4.4 4.4-4.4h45.1c2.5 0 4.4 2 4.4 4.4v17.4z" />
    <path d="M24.3 31H14c-.6 0-1 .4-1 1s.4 1 1 1h10.3c.6 0 1-.4 1-1s-.5-1-1-1M50 31H39.7c-.6 0-1 .4-1 1s.4 1 1 1H50c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconVrAlt;
