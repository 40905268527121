import * as React from "react";
const IconBurgerCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.4 23.6C55.9 14.5 48.2 7.3 39 7.3H25c-4.7 0-9.1 1.8-12.3 5.1-3 3-4.8 7-5.1 11.3C4.3 24.9 2 28 2 31.6v.8c0 3.6 2.3 6.7 5.6 7.9.5 9.1 8.1 16.4 17.4 16.4h14c4.7 0 9.1-1.8 12.3-5.1 3.1-3 4.8-7 5.1-11.3 3.3-1.2 5.6-4.3 5.6-7.9v-.7c0-3.7-2.3-6.8-5.6-8.1M14 13.8c3-2.9 6.8-4.5 11-4.5h14c8 0 14.6 6.1 15.4 13.9-.3 0-.6-.1-1-.1H10.8c-.4 0-.8 0-1.2.1.4-3.6 1.9-6.9 4.4-9.4m36 36.4c-2.9 2.9-6.8 4.5-10.9 4.5H25c-8 0-14.6-6.1-15.4-13.9.4.1.8.1 1.2.1h42.4c.4 0 .8 0 1.2-.1-.3 3.6-1.9 6.9-4.4 9.4m10-17.8c0 3.6-3 6.5-6.8 6.5H10.8C7 38.9 4 36 4 32.4v-.8c0-3.6 3-6.5 6.8-6.5h42.6c3.6 0 6.6 3 6.6 6.6z" />
  </svg>
);
export default IconBurgerCopy;
