import * as React from "react";
const IconCodeMerge = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.2 24.2c-4.2 0-7.7 3.4-7.7 7.7s3.4 7.7 7.7 7.7 7.7-3.4 7.7-7.7-3.5-7.7-7.7-7.7m0 13.4c-3.1 0-5.7-2.5-5.7-5.7 0-3.1 2.5-5.7 5.7-5.7s5.7 2.5 5.7 5.7c0 3.1-2.6 5.7-5.7 5.7M15.2 24.8l-.4-.8v-5.7c3.8-.5 6.7-3.7 6.7-7.6C21.5 6.4 18 3 13.8 3s-7.7 3.4-7.7 7.7c0 3.9 2.9 7.1 6.7 7.6v27.5c-3.8.5-6.7 3.7-6.7 7.6 0 4.2 3.4 7.7 7.7 7.7s7.7-3.4 7.7-7.7c0-3.9-2.9-7.1-6.7-7.6V27.9c2.4 3.1 6.2 5 10.3 5h17.3v-2H25.1c-4.2 0-8-2.3-9.9-6.1M8.1 10.7c0-3.1 2.5-5.7 5.7-5.7 3.1 0 5.7 2.5 5.7 5.7 0 3.1-2.5 5.7-5.7 5.7-3.1-.1-5.7-2.6-5.7-5.7m11.4 42.6c0 3.1-2.5 5.7-5.7 5.7-3.1 0-5.7-2.5-5.7-5.7s2.5-5.7 5.7-5.7c3.1.1 5.7 2.6 5.7 5.7" />
  </svg>
);
export default IconCodeMerge;
