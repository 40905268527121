import * as React from "react";
const IconHeader1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.2 2.1H8.8C5.1 2.1 2 5.1 2 8.9v46.4c0 3.7 3.1 6.8 6.8 6.8h46.4c3.7 0 6.8-3.1 6.8-6.8V8.9c0-3.8-3.1-6.8-6.8-6.8m-46.4 2h46.4c2.6 0 4.8 2.2 4.8 4.8v12H4v-12c0-2.7 2.2-4.8 4.8-4.8M4 55.3V22.8h15.4V60H8.8c-2.6.1-4.8-2.1-4.8-4.7m51.2 4.8H21.4V22.8H60v32.4c0 2.7-2.2 4.9-4.8 4.9" />
  </svg>
);
export default IconHeader1;
