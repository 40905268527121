import * as React from "react";
const IconLadder = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.9 57.5 50.7 3.4C50.6 2.6 50 2 49.2 2h-7.8c-2 0-3.6 1.4-3.9 3.3L37 9H25.1l.4-2.5c.2-1.1-.2-2.3-.9-3.2s-1.8-1.4-3-1.4h-4.7c-2 0-3.6 1.4-3.9 3.3L5.1 57.5c-.2 1.1.2 2.3.9 3.1.8.9 1.9 1.4 3 1.4h4.5c1.9 0 3.6-1.4 3.9-3.3l.7-4.4h12.1l-.5 3.2c-.2 1.1.2 2.3.9 3.1s1.8 1.4 3 1.4h4.5c2 0 3.6-1.4 3.9-3.3l2.3-15 2.3 15c.3 1.9 1.9 3.3 3.9 3.3H55c1.1 0 2.2-.5 3-1.4.7-.8 1-2 .9-3.1M36.7 11l-1.3 8.3H23.5l1.3-8.3zm-4.6 30.3h-12l1.4-9h12zm1.7-10.9h-12l1.4-9h11.9zm-18.3 28c-.1.9-.9 1.6-1.9 1.6H9c-.6 0-1.1-.2-1.5-.7-.4-.4-.5-1-.4-1.5L15 5.6c.1-.9.9-1.6 1.9-1.6h4.7c.6 0 1.1.2 1.5.7.4.4.5 1 .4 1.5zm2.9-6.1 1.4-9h12l-1.4 9zM40 58.4c-.2.9-1 1.6-1.9 1.6h-4.5c-.6 0-1.1-.2-1.5-.7s-.5-1-.4-1.5l7.8-52.2c.1-.9.9-1.6 1.9-1.6h4.7c.6 0 1.1.2 1.5.7.4.4.5 1 .4 1.5zm16.4.9c-.3.5-.9.7-1.4.7h-4.5c-1 0-1.8-.7-1.9-1.6l-3.3-21.2 4.3-27.9 7.3 48.6c.1.4-.1 1-.5 1.4" />
  </svg>
);
export default IconLadder;
