import * as React from "react";
const IconDoorHanger = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 35.9c-.9-.5-1.8-.7-2.8-.7-1.5 0-2.9.5-4 1.6-1.2 1.1-1.8 2.5-1.9 4.2 0 1.7.6 3.4 1.8 4.6l5.2 5.2c.4.4 1 .7 1.6.7s1.2-.2 1.6-.7l5.4-5.4c1.1-1.1 1.7-2.6 1.7-4.2s-.6-3.1-1.7-4.2c-1.8-1.9-4.6-2.3-6.9-1.1m5.6 8.1-5.4 5.4c-.1.1-.3.1-.4 0l-5.2-5.2c-.8-.8-1.3-2-1.3-3.2 0-1.1.5-2.1 1.2-2.7.7-.7 1.7-1 2.6-1 .8 0 1.6.2 2.2.7.3.2.8.2 1.1 0 1.6-1.1 3.7-.9 5 .5.8.8 1.2 1.7 1.2 2.8.2.9-.2 1.9-1 2.7" />
    <path d="M32 2c-8.9 0-16.1 7.2-16.1 16.1 0 2.4 1.9 4.3 4.3 4.3H23c2.4 0 4.3-1.9 4.3-4.3v-.3c0-2.6 2.1-4.6 4.6-4.6s4.6 2.1 4.6 4.6v4.1c0 1.3-.9 2.4-2.1 2.8L19.5 29c-2.1.6-3.5 2.5-3.5 4.7v23.5c0 2.7 2.2 4.9 4.9 4.9h22.4c2.7 0 4.9-2.2 4.9-4.9V18.1C48.1 9.2 40.9 2 32 2m14.1 55.1c0 1.6-1.3 2.9-2.9 2.9H20.8c-1.6 0-2.9-1.3-2.9-2.9V33.6c0-1.3.9-2.4 2.1-2.8l15.1-4.3c2.1-.6 3.5-2.5 3.5-4.7v-4.1c0-3.7-3-6.6-6.6-6.6-3.7 0-6.6 3-6.6 6.6v.3c0 1.3-1 2.3-2.3 2.3h-2.8c-1.3 0-2.3-1-2.3-2.3-.1-7.7 6.2-14 14-14s14.1 6.3 14.1 14.1z" />
  </svg>
);
export default IconDoorHanger;
