import React from 'react';
import { useNavigation } from '../../context/NavigationContext';
import { BringzzPageContainer, BringzzIcon, BringzzText, BringzzList } from "@bringzz/components"

const TermsPage = () => {
    const { backPress } = useNavigation();

    return (
        <BringzzPageContainer
            header={{
                left: <BringzzIcon
                    size="22"
                    icon="IconChevronLeft"
                    folder="LineIcons"
                    className="cursor-pointer"
                    onClick={backPress}
                />,
                title: <BringzzText tag='h2'>Terms & Conditions</BringzzText>
            }}>

            <div className='p-4 space-y-2'>
                <BringzzText tag='h2'>Section Title</BringzzText>
                <BringzzText tag='h4' className='font-normal'>Elementum libero faucibus aliquet sed mauris. Felis mauris nec posuere mauris etiam. Praesent eget nec elementum eleifend tempus duis egestas. Et tellus blandit pharetra id. Elementum elit enim eget enim viverra eros diam neque. Dolor urna facilisis tristique donec. Facilisis justo tincidunt sagittis pharetra sed suspendisse. Imperdiet est etiam id neque ultricies.Elementum libero faucibus aliquet sed mauris. Felis mauris nec posuere mauris etiam. </BringzzText>
                {/* TODO: BringzzTextList? */}
                <ul className='pl-4' style={{ listStyleType: 'circle' }}>
                    <li><BringzzText tag='h4' className='font-normal'>Felis mauris nec posuere mauris etiam.</BringzzText></li>
                    <li><BringzzText tag='h4' className='font-normal'>Felis mauris nec posuere mauris etiam.</BringzzText></li>
                    <li><BringzzText tag='h4' className='font-normal'>Felis mauris nec posuere mauris etiam.</BringzzText></li>
                    <li><BringzzText tag='h4' className='font-normal'>Felis mauris nec posuere mauris etiam.</BringzzText></li>
                </ul>
            </div>
        </BringzzPageContainer>
    )
}

export default TermsPage;