import * as React from "react";
const IconAchievement = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M47.9 44.8c4.3 0 7.9-3.5 7.9-7.9 0-4.3-3.5-7.9-7.9-7.9S40 32.6 40 36.9c0 4.4 3.5 7.9 7.9 7.9m0-13.8c3.2 0 5.9 2.6 5.9 5.9s-2.6 5.9-5.9 5.9-5.9-2.6-5.9-5.9 2.6-5.9 5.9-5.9M61.7 55.9c-3.5-3.6-8.5-5.6-13.7-5.6s-10.2 2.1-13.7 5.6c-.4.4-.4 1 0 1.4s1 .4 1.4 0c3.1-3.2 7.6-5 12.3-5s9.1 1.8 12.3 5c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4M26 23.3c-.7-1-.7-2.3 0-3.2l3.1-4.4c.5-.7.5-1.6.2-2.4s-1.2-1.2-2-1.2H4v-5c0-.6-.4-1-1-1s-1 .4-1 1v49.8c0 .6.4 1 1 1s1-.4 1-1V31.3h23.2c.9 0 1.6-.5 2-1.2.4-.8.3-1.7-.2-2.4zm1.4 5.8c0 .1-.1.1-.2.1H4V14.1h23.2c.1 0 .2.1.2.1 0 .1.1.2 0 .3l-3.1 4.4c-1.2 1.7-1.2 3.9 0 5.5l3.1 4.4c.1.2 0 .3 0 .3" />
  </svg>
);
export default IconAchievement;
