import * as React from "react";
const IconHandshakeCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 36.4h-2.3c-1.4 0-2.5-1.3-2.5-3V18.2c0-1.7 1.1-3.1 2.5-3.1H61c.6 0 1-.4 1-1s-.4-1-1-1h-2.3c-1.8 0-3.4 1.2-4.1 2.9H40.5l-2.2-1.9c-3.3-2.9-7.8-4-11.8-3-5.3 1.3-8.8 5.6-10.1 7.4-.1.3-.4.5-.7.5h-3v-.8c0-2.8-2-5-4.5-5H3c-.6 0-1 .4-1 1s.4 1 1 1h5.2c1.4 0 2.5 1.3 2.5 3v15.1c0 1.7-1.1 3.1-2.5 3.1H3c-.6 0-1 .4-1 1s.4 1 1 1h5.2c1.8 0 3.4-1.2 4.1-2.9h1.3c1.2 0 2.5.4 3.4 1.2-.3.4-.4.9-.1 1.3l2.3 3.1c.1.2.3.3.5.4l3.5.9 1.6 3.3c.1.3.4.5.6.5l3.6 1 2.3 3.6c.1.2.3.4.6.4l5.6 1.6h.3c.3 0 .6-.2.8-.4L49.1 37c.4-.6 1-.9 1.7-.9H55c.8 1.4 2.2 2.3 3.7 2.3H61c.6 0 1-.4 1-1s-.4-1-1-1m-42.7-1.3c-1.3-1.1-3-1.7-4.7-1.7h-.9V21h3c1 0 1.9-.5 2.4-1.3 1.1-1.6 4.3-5.5 8.9-6.6 3.4-.9 7.1.1 10 2.6l2.7 2.3v.1h.1l1.3 1.1c.8.7 1.2 1.5 1.3 2.3 0 .6-.2 1.1-.6 1.6l-1.7 1.8c-1.3 1.3-3.3 1.5-4.8.4l-.4-.3c-2.2-1.6-5.1-1.4-7.1.5l-9.4 9.8zm32.4-1c-1.3 0-2.6.7-3.3 1.8l-10 14.9-4.5-1.3-2.3-3.6c-.1-.2-.3-.4-.6-.4l-3.5-1-1.6-3.3c-.1-.3-.4-.5-.6-.5l-3.6-1-1.7-2.2 10.2-10.6c1.2-1.2 3.1-1.3 4.5-.3l.4.3c2.3 1.7 5.5 1.4 7.4-.7l1.7-1.8c.8-.8 1.2-1.9 1.1-3.1s-.6-2.3-1.6-3.3h11.4v15.3c0 .2 0 .5.1.7h-3.5z" />
  </svg>
);
export default IconHandshakeCopy;
