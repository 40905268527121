import * as React from "react";
const IconQuora = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.1 62.3c-4 0-7.2-1.4-9.7-4.2-3 1-6.2 1.6-9.5 1.6C16 59.7 3 46.7 3 30.7c.1-16 13-28.9 29-28.9s29 13 29 28.9c0 8.6-2.6 15.1-8.2 20.1 1 .6 2.3 1.3 3.4 1.7.8.3 1.4 1.2 1.4 2.1v5.1c0 1.2-.9 2.2-2.1 2.2-.6.1-3.5.4-4.4.4m-9-9.2c.7 0 1.4.3 1.8.9 1.3 1.8 3.2 3.7 7.2 3.7.3 0 1 0 1.9-.1V56c-1.8-.8-4.3-2.3-5.4-4-.2-.3-.3-.6-.4-.9-.2-1.3.7-2.1 1.9-3.2 5-4.3 7.3-9.6 7.3-17.3 0-13.5-11-24.4-24.5-24.4-13.4.1-24.3 11-24.3 24.5S18.5 55.2 32 55.2q4.95 0 9.3-1.8c.3-.2.6-.3.8-.3" />
    <path d="M32.3 52.1c-7.5 0-13.6-6.1-13.6-13.6V22.8c0-7.5 6.1-13.6 13.6-13.6 3.6 0 7.1 1.5 9.6 4s3.9 5.9 3.9 9.6c-.1 17.9-.3 18.7-.4 19-.2.8-.7 1.4-1.4 1.6-.7.3-1.5.1-2.2-.3-.9-.7-4.1-1.6-7.3-2.3v1.6c1.7.9 4.6 2.5 6 3.7.5.5.8 1.1.7 1.8 0 .7-.4 1.3-.9 1.7-2.3 1.6-5.2 2.5-8 2.5m0-38.4c-5 0-9.1 4.1-9.1 9.1v15.7c0 5 4.1 9.1 9.1 9.1.8 0 1.5-.1 2.2-.3-1.1-.6-2.3-1.3-3.2-1.7-.8-.4-1.3-1.2-1.3-2V38c0-.7.3-1.3.8-1.7s1.2-.6 1.8-.5c1.5.3 5.3 1 8.4 2 .1-3.9.1-10.5.2-15.1 0-2.4-.9-4.7-2.7-6.4-1.5-1.7-3.8-2.6-6.2-2.6" />
  </svg>
);
export default IconQuora;
