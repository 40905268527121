// SearchOverviewPage.js
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigation } from 'context/NavigationContext';
import { BringzzPageContainer, BringzzInput, BringzzIcon, BringzzText, BringzzSelect, BringzzDropdown, BringzzButton, Button } from "@bringzz/components";
import HeaderDefault from './HeaderDefault';
import Categories from './Categories';
import Groups from './Groups';
import ScanCTA from './ScanCTA';
import GeneralOptions from './GeneralOptions';
import SearchFooter from './SearchFooter';
import { categories, alphabets } from './constants';
import classNames from 'classnames';
import HeaderCategory from './HeaderCategory';
import HeaderInput from './HeaderInput';
import useRequest from 'hooks/useRequest'; // Import useRequest hook
import { useDebounce } from '@bringzz/hooks';
import { useBasket } from 'context/BasketContext';

const INITIAL_HEADER_HEIGHT = 214;
const SHRINK_SPEED = 1;
const PRODUCTS_PER_PAGE = 10;

const sortOptions = [
    { value: 'kurztext', label: 'Name (A-Z)' },
    { value: 'kurztext_desc', label: 'Name (Z-A)' },
    { value: 'avp', label: 'Price (Low to High)' },
    { value: 'avp_desc', label: 'Price (High to Low)' },
    { value: 'pzn', label: 'PZN' },
];

const SearchOverviewPage = () => {
    const { navigate } = useNavigation();
    const [searchQuery, setSearchQuery] = useState("");
    const [headerHeight, setHeaderHeight] = useState(INITIAL_HEADER_HEIGHT);
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const [MIN_HEADER_HEIGHT, SET_MIN_HEADER_HEIGHT] = useState(48)
    const [isHeaderOpen, setIsHeaderOpen] = useState(true);
    const [searchData, setSearchData] = useState(null)
    const [alphabetFilter, setAlphanetFilter] = useState('All')
    const { sendRequest, data, error, loading } = useRequest(); // Use the useRequest hook
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const observer = useRef();
    const [sortBy, setSortBy] = useState('kurztext'); // Add this line
    const debouncedSearchTerm = useDebounce(searchQuery, 300); // 300ms delay
    const { addToBasket } = useBasket(); // Add this line

    const lastProductElementRef = useCallback(node => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setPage(prevPage => prevPage + 1);
            }
        });
        if (node) observer.current.observe(node);
    }, [loading, hasMore]);

    const handleScroll = () => {
        const scrollTop = window.scrollY;
        const scrollDifference = scrollTop - lastScrollTop;

        if (scrollDifference > 0) {
            const newHeight = Math.max(MIN_HEADER_HEIGHT, headerHeight - scrollDifference * SHRINK_SPEED);
            setHeaderHeight(newHeight);
        } else if (scrollTop < 170 && scrollDifference < 0) {
            const newHeight = Math.min(INITIAL_HEADER_HEIGHT, headerHeight - scrollDifference * SHRINK_SPEED);
            setHeaderHeight(newHeight);
        }

        setLastScrollTop(scrollTop);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [lastScrollTop, headerHeight]);

    useEffect(() => {
        if (searchQuery && searchData?.method === "input") {
            searchProducts(searchQuery, page);
        }
    }, [page]);

    const toggleHeaderHeight = () => {
        setIsHeaderOpen(prevState => !prevState);
        setHeaderHeight(prevHeight =>
            prevHeight === INITIAL_HEADER_HEIGHT ? MIN_HEADER_HEIGHT : INITIAL_HEADER_HEIGHT
        );
    };

    const searchProducts = async (query, currentPage) => {
        try {
            const response = await sendRequest({
                method: 'POST',
                url: '/module/product/searchProducts',
                data: {
                    query: query,
                    page: currentPage,
                    limit: PRODUCTS_PER_PAGE,
                    sortBy: sortBy
                }
            });

            if (response.status === 'success') {
                setSearchData(prevData => ({
                    method: "input",
                    value: query,
                    results: currentPage === 1 ? response.data : [...(prevData?.results || []), ...response.data]
                }));
                setHasMore(response.data.length === PRODUCTS_PER_PAGE);
            } else {
                console.error('Search failed:', response.message);
                if (currentPage === 1) setSearchData(null);
            }
        } catch (error) {
            console.error('Error searching products:', error);
            if (currentPage === 1) setSearchData(null);
        }
    };

    const handleSearch = () => {
        if (searchQuery.length !== 0) {
            setPage(1);
            searchProducts(searchQuery, 1);
        } else {
            setSearchData(null);
        }
    };

    const handleSortChange = (newSortBy) => {
        setSortBy(newSortBy);
        setPage(1);
        searchProducts(searchQuery, 1);
    };

    const formatPrice = (price) => {
        return price ? `€${parseFloat(price).toFixed(2)}` : 'N/A';
    };

    useEffect(() => {
        if (debouncedSearchTerm) {
            handleSearch(debouncedSearchTerm);
        }
    }, [debouncedSearchTerm]);

    useEffect(() => {
        if (debouncedSearchTerm !== '') {
            // Perform search here
            handleProductNameSearch(debouncedSearchTerm);
        }
    }, [debouncedSearchTerm]);

    const handleProductNameSearch = (searchTerm) => {
        // Implement your search logic here
        console.log('Searching for:', searchTerm);
        // Update your search results state or fetch data based on the search term
    };

    console.log(searchData);
    console.log(MIN_HEADER_HEIGHT);

    return (
        <BringzzPageContainer
            className="bg-sand pb-40"
            authenticated={false}
            footerHover={true}
            footer={
                <div className='p-4'>
                    <BringzzInput
                        className='shadow-xl border-slate-400 bg-gray-100'
                        placeholder='Search for Productnames'
                        onChange={(e, newIsValid, newValue) => setSearchQuery(newValue)}
                        icon={{
                            left: <BringzzIcon
                                size="20"
                                icon="IconSearchAlt"
                                folder="LineIcons"
                                className="cursor-pointer"
                                onClick={handleSearch}
                            />,
                        }}
                        onEnterPress={handleSearch}
                    />
                </div>
            }
        >
            <div className='relative'>
                {!searchData && <HeaderDefault headerHeight={headerHeight} />}
                {searchData && searchData.method == "category" && <HeaderCategory SET_MIN_HEADER_HEIGHT={SET_MIN_HEADER_HEIGHT} searchData={searchData} setSearchData={setSearchData} headerHeight={headerHeight} />}
                {searchData && searchData.method == "input" && <HeaderInput SET_MIN_HEADER_HEIGHT={SET_MIN_HEADER_HEIGHT} searchData={searchData} setSearchData={setSearchData} headerHeight={headerHeight} />}
                <div style={{ paddingTop: searchData && searchData.method == "input" ? `60px` : `${INITIAL_HEADER_HEIGHT}px` }}>
                    {!searchData && <div>
                        <Categories setSearchData={setSearchData} callBack={() => {
                            SET_MIN_HEADER_HEIGHT(100)
                            setHeaderHeight(190)
                        }} />
                        <Groups />
                        <ScanCTA />
                        <GeneralOptions />
                    </div>}
                    {searchData && <div className='rounded-xl bg-white mx-2 py-2'>
                        {searchData.method == "category" && <div>
                            <div className='flex items-center px-4 space-x-2 overflow-x-auto '>
                                <BringzzText tag='h3' className='whitespace-nowrap mr-1'>A-Z</BringzzText>
                                {alphabets.map((char, index) => {
                                    return <div key={index} className={classNames('bg-sand p-2 rounded-full cursor-pointer',
                                        char == alphabetFilter && "!bg-magic-lilac"
                                    )}
                                        onClick={() => setAlphanetFilter(char)}
                                    >
                                        <BringzzText tag='h5'>{char}</BringzzText>
                                    </div>
                                })}
                            </div>
                            {categories.filter((category) => category.shortName == searchData.value)[0].items
                                .filter((item) => {
                                    if (alphabetFilter == 'All') {
                                        return true
                                    } else if (alphabetFilter == '#') {
                                        return !/^[A-Za-z]/.test(item.label);
                                    } else {
                                        return item.label.startsWith(alphabetFilter)
                                    }
                                })
                                .map((item, index) => {
                                    return <div key={index} className={classNames(
                                        'h-12 cursor-pointer flex justify-between px-6 items-center',
                                    )}>
                                        <div className='flex space-x-1 items-center'>
                                            <BringzzText tag='h4'>{item.label.split("*")[0]}</BringzzText>
                                            <div>&bull;</div>
                                            <BringzzText tag='h4'>{item.label.split("*")[1]}</BringzzText>
                                        </div>
                                        <BringzzIcon
                                            size="20"
                                            icon="IconChevronRight"
                                            folder="LineIcons"
                                            className="cursor-pointer"
                                        />
                                    </div>
                                })}
                        </div>}
                        {searchData.method == "input" && (
                            <div>
                                <div className="p-4 border-b">
                                    <BringzzDropdown
                                        options={sortOptions}
                                        value={sortBy}
                                        onChange={handleSortChange}
                                        placeholder="Sort by"
                                    />
                                </div>
                                {searchData.results && searchData.results.length > 0 ? (
                                    searchData.results.map((product, index) => (
                                        <div
                                            key={index}
                                            className="p-4 border-b"
                                            ref={index === searchData.results.length - 1 ? lastProductElementRef : null}
                                        >
                                            <BringzzText tag='h4' className="font-bold">{product.name}</BringzzText>
                                            <BringzzText tag='p' className="text-sm">PZN: {product.pzn}</BringzzText>
                                            <BringzzText tag='p' className="text-sm">Package: {product.packaging} - {product.packageQuantity}</BringzzText>
                                            <div className="mt-2 flex justify-between items-center">
                                                <BringzzText tag='p' className="text-lg font-semibold">{formatPrice(product.price)}</BringzzText>
                                                <BringzzButton
                                                    className="bg-magic-lilac justify-center"
                                                    onClick={() => addToBasket(product)}
                                                    disabled={!product.price}
                                                >
                                                    Add to Basket
                                                </BringzzButton>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className="p-4">No products found</div>
                                )}
                                {loading && <div className="p-4">Loading more products...</div>}
                                {error && <div className="p-4 text-red-500">Error: {error.message}</div>}
                            </div>
                        )}
                    </div>}
                    <SearchFooter />
                </div>
            </div>
        </BringzzPageContainer>
    );
};

export default SearchOverviewPage;
