import * as React from "react";
const IconTrophy4 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.4 60h-9.1v-4.2c0-1.9-1.3-3.4-3-3.9l4.4-48.1c0-.5-.3-1-.8-1.1-19-3.6-27.6 6-27.9 6.4-.2.2-.3.5-.2.8l5.9 42c-1.7.5-3 2-3 3.8V60h-9.1c-.6 0-1 .4-1 1s.4 1 1 1h42.8c.6 0 1-.4 1-1s-.4-1-1-1M40.1 42.9 22.9 39l-1.6-11.3 21.2-10.4zm-.2 2-.6 6.9H24.7l-1.5-10.7zm3.7-40.3-1 10.3L21 25.6l-2.2-15.4c1.6-1.6 9.5-8.2 24.8-5.6M21.7 60v-4.2c0-1.1.9-2 2-2h16.6c1.1 0 2 .9 2 2V60z" />
  </svg>
);
export default IconTrophy4;
