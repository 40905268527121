import * as React from "react";
const IconBitcoinLaptop = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 46.4h-4.2V19.1c0-3.7-3-6.6-6.6-6.6h-6.9c-.9-3.2-3.9-5.6-7.5-5.6h-2V5.8c0-.6-.4-1-1-1s-1 .4-1 1v1.1h-2.4V5.8c0-.6-.4-1-1-1s-1 .4-1 1v1.1h-5.9c-.6 0-1 .4-1 1s.4 1 1 1h1.1v3.6h-8.8c-3.7 0-6.6 3-6.6 6.6v27.2H3c-.6 0-1 .4-1 1v4.4c0 4.1 3.3 7.4 7.4 7.4h45.2c4.1 0 7.4-3.3 7.4-7.4v-4.4c0-.5-.4-.9-1-.9M41.5 14.1v.4c-.1 2.7-2.4 4.8-5.2 4.8H24.6V8.9h11.2c3 0 5.6 2.3 5.7 5.2m-5.2 7.2c2.8 0 5.1 2.1 5.2 4.7v.4c-.1 2.9-2.7 5.3-5.7 5.3H24.6V21.3zM9.2 19.1c0-2.5 2.1-4.6 4.6-4.6h8.8v17.2h-1.1c-.6 0-1 .4-1 1s.4 1 1 1h5.9V35c0 .6.4 1 1 1s1-.4 1-1v-1.2h2.4V35c0 .6.4 1 1 1s1-.4 1-1v-1.2h1.9c4.1 0 7.6-3.2 7.7-7.2V26c-.1-2.4-1.5-4.5-3.5-5.7 2.1-1.2 3.5-3.3 3.5-5.8v-.1h6.7c2.5 0 4.6 2.1 4.6 4.6v27.2H9.2zM60 51.8c0 3-2.4 5.4-5.4 5.4H9.4c-3 0-5.4-2.4-5.4-5.4v-3.4h56z" />
  </svg>
);
export default IconBitcoinLaptop;
