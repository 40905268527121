import * as React from "react";
const IconTractor1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54 25.5v-8.9c0-2.7-2.2-4.8-4.9-4.8h-7.5c-2.1 0-3.9 1.3-4.6 3.3l-3 8.6c-.4 1-1.2 1.7-2.3 1.9l-13.8 1.9v-5.2c0-2-1.6-3.6-3.6-3.6h-2.5c-.6 0-1 .4-1 1s.4 1 1 1h2.5c.9 0 1.6.7 1.6 1.6v5.5l-3 .4c-2.4.3-4.2 2.4-4.2 4.8v4.3C5.4 38 3 41 3 44.6c0 4.3 3.5 7.7 7.7 7.7 4.3 0 7.7-3.5 7.7-7.7v-.3h15.1c2.4 4.7 7.3 8 12.9 8 8 0 14.5-6.5 14.5-14.4.1-5.3-2.7-9.9-6.9-12.4m-18-1.3 3-8.6c.4-1.1 1.5-1.9 2.7-1.9h7.5c1.6 0 2.9 1.3 2.9 2.8v8c-1.7-.7-3.5-1.1-5.4-1.1-2.5 0-4.9.6-6.9 1.8h-4.1c0-.3.1-.6.3-1M10.7 50.3c-3.2 0-5.7-2.6-5.7-5.7 0-3.2 2.6-5.7 5.7-5.7s5.7 2.6 5.7 5.7c.1 3.1-2.5 5.7-5.7 5.7m22-8H18.1c-1-3.2-3.9-5.5-7.4-5.5h-.1v-4.1c0-1.4 1.1-2.6 2.5-2.8L32 27.4c.4 0 .7-.1 1-.3h3.7c-2.9 2.6-4.7 6.5-4.7 10.7 0 1.6.3 3.1.7 4.5m13.8 8c-6.9 0-12.5-5.6-12.5-12.4s5.6-12.4 12.5-12.4C53.4 25.4 59 31 59 37.9s-5.6 12.4-12.5 12.4" />
    <path d="M46.5 31.6c-3.5 0-6.3 2.8-6.3 6.3s2.8 6.3 6.3 6.3 6.3-2.8 6.3-6.3-2.8-6.3-6.3-6.3m0 10.5c-2.4 0-4.3-1.9-4.3-4.3s1.9-4.3 4.3-4.3 4.3 1.9 4.3 4.3-1.9 4.3-4.3 4.3" />
  </svg>
);
export default IconTractor1;
