import * as React from "react";
const IconChefHat = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M47.3 12.7C45.1 6.1 39 1.8 32 1.8S18.9 6.2 16.7 12.7C8.9 14.4 3.4 21.3 3.4 29.2v.1c0 6.1 4.6 11.2 10.5 12.2 0 3.4.1 10.2.2 14.5 0 3.4 2.8 6.2 6.2 6.2h23.3c3.4 0 6.2-2.7 6.2-6.1l.3-14.6c5.9-1 10.4-6.2 10.4-12.4.1-7.8-5.4-14.7-13.2-16.4m-3.6 45.1H20.4c-.9 0-1.7-.8-1.7-1.7 0-1.2 0-2.7-.1-4.1h26.9l-.1 4c0 1-.8 1.8-1.7 1.8m4.8-20.6c-1.5.1-2.7 1.3-2.7 2.8l-.2 7.4H18.5c0-3.2-.1-6.2-.1-7.4 0-1.5-1.2-2.7-2.7-2.8-4.3-.2-7.8-3.7-7.8-7.9v-.1c0-5.9 4.2-11.1 10.1-12.2 1.3-.3 2.4-1.2 2.8-2.5 1.5-4.9 6-8.2 11.2-8.2s9.7 3.3 11.2 8.2c.4 1.3 1.5 2.3 2.8 2.5 5.8 1.1 10.1 6.3 10.1 12.2 0 4.3-3.3 7.8-7.6 8" />
  </svg>
);
export default IconChefHat;
