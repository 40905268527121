import * as React from "react";
const IconSignal = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 22.4c-3.2 0-5.8 2.6-5.8 5.8 0 2.4 1.5 4.5 3.6 5.4v19.6c0 1.2 1 2.3 2.3 2.3 1.2 0 2.3-1 2.3-2.3V33.5c2.1-.9 3.6-2.9 3.6-5.4-.2-3.1-2.8-5.7-6-5.7m0 4.5c.7 0 1.3.6 1.3 1.3s-.6 1.3-1.3 1.3-1.3-.6-1.3-1.3.6-1.3 1.3-1.3M22.1 27.5c0-1.9.8-3.7 2.1-5 .9-.9.9-2.3 0-3.2s-2.3-.9-3.2 0c-2.2 2.2-3.4 5.1-3.4 8.2s1.2 6.1 3.4 8.3c.4.4 1 .7 1.6.7s1.1-.2 1.6-.7c.9-.9.9-2.3 0-3.2-1.3-1.4-2.1-3.2-2.1-5.1M42.9 19.3c-.9-.9-2.3-.9-3.2 0s-.9 2.3 0 3.2c1.4 1.3 2.1 3.1 2.1 5s-.7 3.7-2.1 5.1c-.9.9-.9 2.3 0 3.2.4.4 1 .7 1.6.7s1.2-.2 1.6-.7c2.2-2.2 3.4-5.2 3.4-8.3s-1.2-6-3.4-8.2" />
    <path d="M13.7 27.5c0-3.8 1.5-7.3 4.2-10 .9-.9.9-2.3 0-3.2s-2.3-.9-3.2 0c-3.6 3.5-5.5 8.2-5.5 13.2s2 9.7 5.5 13.2c.4.4 1 .7 1.6.7s1.2-.2 1.6-.7c.9-.9.9-2.3 0-3.2-2.7-2.7-4.2-6.2-4.2-10M49.3 14.3c-.9-.9-2.3-.9-3.2 0s-.9 2.3 0 3.2c2.7 2.7 4.2 6.2 4.2 10s-1.5 7.3-4.2 10c-.9.9-.9 2.3 0 3.2.4.4 1 .7 1.6.7s1.1-.2 1.6-.7c3.6-3.5 5.5-8.2 5.5-13.2s-2-9.7-5.5-13.2" />
    <path d="M5.3 27.5c0-5.7 2.2-11 6.3-15 .9-.9.9-2.3 0-3.2s-2.3-.9-3.2 0C3.5 14.2.8 20.6.8 27.5s2.7 13.3 7.6 18.2c.4.4 1 .7 1.6.7s1.2-.2 1.6-.7c.9-.9.9-2.3 0-3.2-4.1-4-6.3-9.3-6.3-15M55.6 9.3c-.9-.9-2.3-.9-3.2 0s-.9 2.3 0 3.2c4.1 4 6.3 9.3 6.3 15s-2.2 11-6.3 15c-.9.9-.9 2.3 0 3.2.4.4 1 .7 1.6.7s1.1-.2 1.6-.7c4.9-4.9 7.6-11.3 7.6-18.2.1-6.9-2.7-13.3-7.6-18.2" />
  </svg>
);
export default IconSignal;
