import * as React from "react";
const IconCommentsAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.9 34.2-2.2-5.8c1.8-2.9 2.5-6.3 2.1-9.8-.8-7.4-6.7-13.4-14.2-14.1-4.8-.5-9.5 1.1-13 4.5-3.4 3.4-5.1 8.1-4.6 13 0 .2 0 .3.1.5-.5 0-1-.1-1.5-.1H18.3C9.3 22.4 2 29.5 2 38.2 2 47 7.7 53.1 21.8 59.5c.2.1.5.2.8.2.4 0 .7-.1 1-.3.5-.3.9-.9.9-1.6V54h4.1c9 0 16.3-7.1 16.3-15.8 0-.7-.1-1.3-.1-1.9 3.4.2 6.7-.5 9.5-2.3l5.1 2.6c.3.1.5.2.8.2.4 0 .9-.2 1.2-.5.6-.6.7-1.4.5-2.1M28.5 52h-4.2c-1 0-1.9.8-1.9 1.9v3.7C9.2 51.5 4 46.1 4 38.2c0-7.6 6.4-13.8 14.3-13.8h10.2c7.9 0 14.3 6.2 14.3 13.8.1 7.6-6.4 13.8-14.3 13.8m26.2-20.1c-.3-.2-.7-.1-1 .1-2.7 1.8-5.9 2.6-9.1 2.3h-.2c-1.5-5.7-6.2-10.1-12.2-11.4-.1-.3-.1-.7-.1-1-.4-4.2 1-8.4 4-11.3 3-3 7.1-4.4 11.4-4 6.5.7 11.7 5.9 12.4 12.4.3 3.2-.4 6.3-2.1 9-.2.3-.2.6-.1.9l2.2 5.8z" />
  </svg>
);
export default IconCommentsAlt1;
