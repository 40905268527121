import * as React from "react";
const IconHospitalBuilding = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.9 37H35.2V6c0-2.2-1.8-4-4-4h-23c-2.2 0-4 1.8-4 4v52c0 2.2 1.8 4 4 4h46.7c2.7 0 5-2.2 5-5V42c-.1-2.7-2.3-5-5-5m-29 23H13.4v-6c0-3.4 2.8-6.3 6.3-6.3S26 50.5 26 54v6zm7.3 0h-5.3v-6c0-4.5-3.7-8.3-8.3-8.3s-8.3 3.7-8.3 8.3v6H8.2c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2h23c1.1 0 2 .9 2 2zm24.6-3c0 1.6-1.3 3-3 3H35.2V39h19.7c1.6 0 3 1.3 3 3v15z" />
    <path d="M16 22c.6 0 1-.4 1-1v-3.7h5.3V21c0 .6.4 1 1 1s1-.4 1-1v-9.4c0-.6-.4-1-1-1s-1 .4-1 1v3.7H17v-3.7c0-.6-.4-1-1-1s-1 .4-1 1V21c0 .6.5 1 1 1M24.5 29.8h-9.7c-.6 0-1 .4-1 1s.4 1 1 1h9.7c.6 0 1-.4 1-1s-.5-1-1-1M24.5 37h-9.7c-.6 0-1 .4-1 1s.4 1 1 1h9.7c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconHospitalBuilding;
