import * as React from "react";
const IconChartDeathRateStable = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M15 16.5a2.5 2.5 0 0 1 4.94-.549 2.61 2.61 0 1 1-.026 1.202A2.501 2.501 0 0 1 15 16.5m2.5-.5a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1m4.357.608a.608.608 0 1 1 1.216-.001.608.608 0 0 1-1.216 0"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M14.462 21.835c-.462 0-1.847-.453-1.847-2.487 0-.805-.252-1.186-.558-1.646C11.59 17 11 16.112 11 13.243c0-9.657 18-9.657 18 0 0 2.87-.59 3.757-1.057 4.46-.306.46-.558.84-.558 1.645 0 2.035-1.808 2.487-1.847 2.487s-.615 1.356-.615 2.26C24.923 25 22.308 25 20 25s-4.923 0-4.923-.904c0-.905-.154-2.261-.616-2.261m.225-2.004.007.015c1.08.106 1.63.93 1.802 1.236.225.4.339.828.403 1.128.048.228.085.466.112.703.788.084 1.843.087 2.989.087 1.184 0 2.27-.003 3.066-.096a9.756 9.756 0 0 1 .516-1.62 5 5 0 0 1 .13-.285 2.102 2.102 0 0 1 .611-.754c.074-.056.333-.252.733-.351l.006-.003a.7.7 0 0 0 .16-.095.4.4 0 0 0 .103-.12c.018-.035.06-.128.06-.328 0-1.484.657-2.43.968-2.877q.078-.112.12-.181c.174-.292.527-.931.527-3.047 0-1.702-.756-2.933-1.976-3.806C23.744 8.522 21.926 8 20 8s-3.745.522-5.024 1.437C13.756 10.31 13 11.542 13 13.243c0 2.116.353 2.755.526 3.047q.043.07.121.18c.31.448.968 1.394.968 2.878 0 .28.047.423.07.477z"
      clipRule="evenodd"
    />
    <path
      
      d="M7 42a1 1 0 0 1-1-1V6h2v25.995c2.31-.024 4.575-.39 6.627-1.067l3.672-1.207a14.9 14.9 0 0 1 4.62-.721c1.596 0 3.177.245 4.622.721l3.672 1.211c2.122.7 4.474 1.068 6.867 1.068h1.287l-3.074-3.075a1 1 0 0 1 1.414-1.414l4.78 4.782a1 1 0 0 1 0 1.414l-4.78 4.782a1 1 0 0 1-1.414-1.414L39.366 34H38.08c-2.592 0-5.157-.398-7.493-1.168l-3.672-1.211A12.9 12.9 0 0 0 22.92 31c-1.396 0-2.765.215-3.995.62l-3.672 1.208c-2.264.746-4.743 1.143-7.253 1.167V40h34v2z"
    />
  </svg>
);
export default IconChartDeathRateStable;
