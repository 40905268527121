import * as React from "react";
const IconSpeechBubble24 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.4 12.8c-2.8-3.2-6.8-5-11-5H22.8c-.4 0-.8.3-.9.7s0 .9.3 1.1l11.4 8.5c-1.3 4.4-.5 9.1 2.2 12.8 2.8 3.7 7.1 5.9 11.7 5.9h.7c6.8-.3 12.7-5.7 13.7-12.4.5-4.2-.7-8.4-3.5-11.6m1.5 11.4C59.1 30 54 34.6 48.1 34.9c-4.3.2-8.2-1.6-10.7-5.1-2.5-3.4-3.1-7.7-1.7-11.7.1-.4 0-.9-.3-1.1l-9.5-7.2h21.7c3.6 0 7.1 1.6 9.5 4.3 2.2 2.8 3.3 6.4 2.8 10.1M30.5 45.9c1.6-5.1.2-10.6-3.7-14.5-4.1-4.1-10-5.3-15.8-3.2-3.2 1.2-5.9 3.6-7.3 6.7-2.3 4.9-2.1 10.3.6 14.6 2.7 4.2 7.3 6.8 12.3 6.8h24.7c.4 0 .8-.3.9-.7s0-.9-.3-1.1zm-14 8.3c-4.3 0-8.3-2.2-10.6-5.9S3.4 40 5.4 35.6C6.6 33 8.9 31 11.6 30c1.6-.6 3.3-.9 4.9-.9 3.3 0 6.4 1.2 8.8 3.6 3.5 3.5 4.6 8.5 3 13.1-.1.4 0 .9.3 1.1l9.5 7.2H16.5z" />
  </svg>
);
export default IconSpeechBubble24;
