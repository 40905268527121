import * as React from "react";
const IconHatMedical = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.6 15.6c-23.6-8.6-45-2.9-53.2 0-2.5.9-3.9 3.5-3.3 6.2l7.1 27.1c.4 1.4 1.3 2.6 2.5 3.3 1.3.7 2.7.8 4.1.3 12.9-4.5 25.9-1.8 32.6.3.5.2 1 .2 1.5.2.9 0 1.7-.2 2.5-.7 1.2-.7 2.1-1.8 2.5-3.2l6.9-27.3c.7-2.6-.7-5.3-3.2-6.2m1.3 5.6L53 48.6c-.2.8-.8 1.6-1.5 2-.8.4-1.6.5-2.5.3-4-1.4-10.5-2.9-18-2.9-5 0-10.3.7-15.7 2.6-.9.3-1.8.2-2.5-.2-.8-.4-1.3-1.1-1.5-2L4.1 21.2c-.4-1.6.4-3.2 2-3.8 7.9-2.8 28.8-8.4 51.8 0 1.6.7 2.4 2.3 2 3.8" />
    <path d="M40 30.2h-7v-7c0-.6-.4-1-1-1s-1 .4-1 1v7h-7c-.6 0-1 .4-1 1s.4 1 1 1h7v7c0 .6.4 1 1 1s1-.4 1-1v-7h7c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconHatMedical;
