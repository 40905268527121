import * as React from "react";
const IconCalendarCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M17.8 37.8h-3.3c-.6 0-1 .4-1 1V42c0 .6.4 1 1 1h3.3c.6 0 1-.4 1-1v-3.2c0-.6-.4-1-1-1M28.4 37.8h-3.3c-.6 0-1 .4-1 1V42c0 .6.4 1 1 1h3.3c.6 0 1-.4 1-1v-3.2c0-.6-.5-1-1-1M38.9 37.8h-3.3c-.6 0-1 .4-1 1V42c0 .6.4 1 1 1h3.3c.6 0 1-.4 1-1v-3.2c0-.6-.4-1-1-1M49.5 37.8h-3.3c-.6 0-1 .4-1 1V42c0 .6.4 1 1 1h3.3c.6 0 1-.4 1-1v-3.2c0-.6-.5-1-1-1M17.8 48.2h-3.3c-.6 0-1 .4-1 1v3.2c0 .6.4 1 1 1h3.3c.6 0 1-.4 1-1v-3.2c0-.6-.4-1-1-1M28.4 48.2h-3.3c-.6 0-1 .4-1 1v3.2c0 .6.4 1 1 1h3.3c.6 0 1-.4 1-1v-3.2c0-.6-.5-1-1-1M38.9 48.2h-3.3c-.6 0-1 .4-1 1v3.2c0 .6.4 1 1 1h3.3c.6 0 1-.4 1-1v-3.2c0-.6-.4-1-1-1M49.5 48.2h-3.3c-.6 0-1 .4-1 1v3.2c0 .6.4 1 1 1h3.3c.6 0 1-.4 1-1v-3.2c0-.6-.5-1-1-1" />
    <path d="M57 16.1H33v-4.2c2.3-.5 4.1-2.5 4.1-4.9 0-2.7-2.3-5-5.1-5s-5.1 2.2-5.1 5c0 2.4 1.7 4.4 4.1 4.9v4.2H7c-2.8 0-5 2.2-5 5V57c0 2.8 2.2 5 5 5h50c2.8 0 5-2.2 5-5V21.1c0-2.8-2.2-5-5-5M28.9 7c0-1.6 1.4-3 3.1-3s3.1 1.3 3.1 3-1.4 3-3.1 3-3.1-1.4-3.1-3M7 18.1h50c1.7 0 3 1.3 3 3V29H4v-7.9c0-1.7 1.3-3 3-3M57 60H7c-1.7 0-3-1.3-3-3V31h56v26c0 1.7-1.3 3-3 3" />
  </svg>
);
export default IconCalendarCopy;
