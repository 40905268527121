import * as React from "react";
const IconTreeAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m51.4 39.6-9.7-17.1v-.2c0-.1.1-.1.2-.1h1.8c.8 0 1.6-.5 2-1.2s.3-1.6-.2-2.3L33.7 2.9q-.6-.9-1.8-.9c-1.2 0-1.4.3-1.8.9L18.5 18.7c-.5.7-.6 1.6-.2 2.3s1.1 1.2 2 1.2h2.9c.1 0 .1 0 .2.1v.2l-10.6 17c-.4.7-.4 1.5-.1 2.2.4.7 1.1 1.1 1.9 1.1H31V61c0 .6.4 1 1 1s1-.4 1-1V42.9h16.5c.8 0 1.5-.4 1.9-1.1s.4-1.5 0-2.2m-1.8 1.2c0 .1-.1.1-.2.1H14.5c-.1 0-.1 0-.2-.1v-.2l10.6-17c.4-.7.4-1.5.1-2.2-.4-.7-1.1-1.1-1.9-1.1h-2.9c-.1 0-.1 0-.2-.1V20L31.8 4.1c0-.1.1-.1.1-.1s.1 0 .2.1l11.7 15.8c0 .1.1.1 0 .2 0 .1-.1.1-.2.1h-1.8c-.8 0-1.5.4-1.9 1.1s-.4 1.5 0 2.2l9.7 17.1c.1 0 .1.1 0 .2" />
  </svg>
);
export default IconTreeAlt;
