import * as React from "react";
const IconLinkCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m58.9 43.6-8.4-8.3c-4-4-10.5-4.2-14.7-.4l-6.7-6.7c1.7-1.9 2.7-4.4 2.7-7.1 0-2.9-1.1-5.6-3.1-7.6l-8.4-8.3C16.2 1 9.4 1 5.2 5.2c-2 2-3.2 4.7-3.2 7.6s1.1 5.6 3.2 7.6l8.3 8.3c2.1 2.1 4.8 3.1 7.6 3.1 2.3 0 4.7-.8 6.6-2.3l6.8 6.8c-1.5 1.9-2.3 4.2-2.3 6.6 0 2.9 1.1 5.6 3.2 7.6l8.3 8.3c2.1 2.1 4.8 3.1 7.6 3.1s5.5-1 7.6-3.1c2-2 3.1-4.7 3.2-7.5-.1-2.9-1.2-5.6-3.2-7.7m-44-16.3L6.6 19C4.9 17.3 4 15.1 4 12.8s.9-4.5 2.6-6.2S10.5 4 12.8 4s4.5.9 6.2 2.6l8.3 8.3c1.6 1.7 2.5 3.9 2.5 6.2 0 2.1-.8 4.1-2.1 5.7L24 23.1c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l3.6 3.6c-3.4 2.6-8.3 2.3-11.3-.8m42.5 30.1c-3.4 3.4-9 3.4-12.4 0l-8.3-8.3c-1.7-1.7-2.6-3.9-2.6-6.2 0-1.9.6-3.6 1.7-5.1l3.6 3.5c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-3.6-3.6c1.6-1.4 3.7-2.1 5.7-2.1 2.2 0 4.5.9 6.2 2.6l8.3 8.3a9.1 9.1 0 0 1 2.5 6.3c.1 2.2-.8 4.4-2.5 6" />
  </svg>
);
export default IconLinkCopy;
