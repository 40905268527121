import * as React from "react";
const IconTrophy1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m39.4 24.6-4.5-.7-2-4.1c-.2-.3-.5-.6-.9-.6q-.6 0-.9.6l-2 4.1-4.5.7c-.4.1-.7.3-.8.7s0 .8.3 1l3.3 3.2-.8 4.5c-.1.4.1.8.4 1 .2.1.4.2.6.2s.3 0 .5-.1l4-2.1 4 2.1c.3.2.7.1 1.1-.1.3-.2.5-.6.4-1l-.8-4.5 3.3-3.2c.3-.3.4-.7.3-1-.3-.4-.6-.7-1-.7m-4.5 3.8c-.2.2-.3.6-.3.9l.5 3-2.7-1.4c-.1-.1-.3-.1-.5-.1s-.3 0-.5.1l-2.7 1.4.5-3c.1-.3-.1-.7-.3-.9l-2.2-2.1 3-.4c.3 0 .6-.3.8-.5l1.3-2.7 1.3 2.7c.1.3.4.5.8.5l3 .4z" />
    <path d="M58.4 12.5h-9.6V8c0-2.2-1.8-4-4-4H19.2c-2.2 0-4 1.8-4 4v4.5H7c-2.7 0-5 2.2-5 5v.9C2 24 6.6 28.6 12.2 28.6h3v3.2c0 7.1 5.8 12.9 12.9 12.9h3.3c-.2.3-.4.6-.4.9v11.9c0 .2.1.3.1.5h-6.3c-.6 0-1 .4-1 1s.4 1 1 1h14.4c.6 0 1-.4 1-1s-.4-1-1-1h-6.3c.1-.1.1-.3.1-.5V45.6c0-.4-.2-.6-.5-.8h3.3c7.1 0 12.9-5.8 12.9-12.9v-3.2h3C57.4 28.6 62 24 62 18.4v-2.3c0-2-1.6-3.6-3.6-3.6M17.2 8c0-1.1.9-2 2-2h25.5c1.1 0 2 .9 2 2v4.5H17.2zm-5 18.6c-4.5 0-8.2-3.7-8.2-8.2v-.9c0-1.6 1.3-3 3-3h8.3v12.1zm23.6 16.2h-7.6c-6 0-10.9-4.9-10.9-10.9V14.5h29.5v17.4c0 6-4.9 10.9-11 10.9M60 18.4c0 4.5-3.7 8.2-8.2 8.2h-3V14.5h9.6c.9 0 1.6.7 1.6 1.6z" />
  </svg>
);
export default IconTrophy1;
