import * as React from "react";
const IconLinkAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M9.5 26.5h12.3c.6 0 1-.4 1-1s-.4-1-1-1H9.5C5.4 24.5 2 27.8 2 32s3.4 7.5 7.5 7.5h12.3c.6 0 1-.4 1-1s-.4-1-1-1H9.5c-3 0-5.5-2.4-5.5-5.5s2.5-5.5 5.5-5.5M36.5 31h-9c-.6 0-1 .4-1 1s.4 1 1 1h9c.6 0 1-.4 1-1s-.4-1-1-1M54.5 24.5H42.2c-.6 0-1 .4-1 1s.4 1 1 1h12.3c3.1 0 5.5 2.5 5.5 5.5s-2.5 5.5-5.5 5.5H42.2c-.6 0-1 .4-1 1s.4 1 1 1h12.3c4.2 0 7.5-3.4 7.5-7.5 0-4.2-3.4-7.5-7.5-7.5" />
  </svg>
);
export default IconLinkAlt;
