import React, { Fragment, useState, useEffect } from 'react';
import {
	generateCSSString,
	getResponsiveStyles,
	getMarginStyles,
	getPaddingStyles
} from '../../helpers/style';
import { userComponentAccess } from '../../helpers/user';

import Footer from './components/Footer';
import AccordionList from './components/AccordionList';
import Carousel from './components/Carousel';
import Container from './components/Container';
import Html from './components/Html';
import Text from './components/Text';
import MenuLinks from './components/Menu/components/MenuLinks';

import { BringzzPageContainer } from '@bringzz/components';
import { BringzzAccordion } from '@bringzz/components';
import { BringzzButton } from '@bringzz/components';
import { BringzzIcon } from '@bringzz/components';
import { BringzzImage } from '@bringzz/components';
import { BringzzImageSlider } from '@bringzz/components';
import { BringzzText } from '@bringzz/components';
import BringzzLogo from "../../../../assets/BringzzLogo.png";

const getCssValueContainer = (component) => {
	switch (component.component) {
		case `cmsComponentContainer`:
			return `${generateCSSString(component, [...getResponsiveStyles('container', 'Width')])} flex flex-column`;
		default:
			return 'w-full';
	}
}

export default function Component({
	component,
	wrapper = 'div',
	screens = { sm: 576, md: 768, lg: 992, xl: 1200 },
	...props
}) {
	const tabletSize = screens.lg;
	const [menuOpen, setMenuOpen] = useState(false);
	const [device, setDevice] = useState(window.innerWidth > tabletSize ? 'desktop' : 'mobile');

	const Wrapper = wrapper;
	const cssValueContainer = getCssValueContainer(component);
	const children = component.children?.map((child, key) => (
		<Component key={key} component={child} wrapper={Wrapper} {...props} />
	));

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth <= tabletSize) return setDevice('mobile');
			return setDevice('desktop');
		};
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const getComponent = () => {
		switch (component.component) {
			case 'cmsPageFooter':
				return <Footer component={component} children={children} {...props} />;
			case 'cmsComponentAccordionList':
				return (
					<AccordionList
						className={generateCSSString(component, [...getPaddingStyles('accList'), 'accList_gap'])}
						component={component}
						children={children}
						{...props}
					/>
				);
			case 'cmsComponentCarousel':
				return (
					<Carousel
						className={`${generateCSSString(component, [...getPaddingStyles('carousel')])}`}
						component={component}
						children={children}
						{...props}
					/>
				);
			case 'cmsComponentContainer':
				return (
					<Container
						component={component}
						classNameWrapper={generateCSSString(component, [
							...getMarginStyles('container'),
							'container_borderWidth',
							'container_borderRadius'
						])}
						classNameContainer={generateCSSString(component, [
							...getPaddingStyles('container'),
							...getResponsiveStyles('container', 'FlexDirection'),
							...getResponsiveStyles('container', 'JustifyContent'),
							...getResponsiveStyles('container', 'AlignItems')
						])}
						children={children}
						{...props}
					/>
				);
			case 'cmsComponentHtml':
				return <Html component={component} {...props} />;
			case 'cmsComponentText':
				return <Text component={component} {...props} />;

			//Bringzz
			case 'cmsBringzzLink':
				if (component.link_linkType !== 'button')
					return <Component component={{ ...component, component: 'cmsBringzzText' }} />
				return <Component component={{ ...component, component: 'cmsBringzzButton' }} />
			case 'cmsBringzzPageContainer':
				return (
					<BringzzPageContainer
						className={'md:max-w-full'}
						header={
							!props.hideHeader
								? {
									left: <a href='/'><img className="h-5" src={BringzzLogo} alt="BringzzLogo" /></a>,
									title: device !== 'mobile' &&
										<MenuLinks
											component={component.children[0]}
											children={component.children[0]?.children[0]?.children?.map(child => <Component key={child.uuid} component={child} />)}
										/>,
									right: device !== 'mobile' ?
										<MenuLinks
											component={component.children[0]}
											children={component.children[0]?.children[1]?.children?.map(child => <Component key={child.uuid} component={child} />)}
										/>
										: !menuOpen ?
											<BringzzIcon
												size="24"
												icon="IconMenu"
												folder="LineIcons"
												className="cursor-pointer"
												onClick={() => setMenuOpen(!menuOpen)}
											/>
											:
											<BringzzIcon
												size="18"
												icon="IconClose"
												folder="LineIcons"
												className="cursor-pointer"
												onClick={() => setMenuOpen(!menuOpen)}
											/>
								}
								: null
						}
						children={
							device === 'mobile' && menuOpen ?
								<MenuLinks
									mobile={true}
									component={component.children[0]}
									children={component.children[0]?.children[0]?.children?.concat(component.children[0]?.children[1]?.children ?? []).map((child) => <Component key={child.uuid} component={child} wrapper={Fragment} />)}
								/>
								:
								component.children.slice(1, component.children.length - 1)?.map((child) => {
									return <Component key={child.uuid} component={child} wrapper={Wrapper} {...props} />
								})
						}
						footer={
							<Component
								component={component.children[component.children.length - 1]}
								wrapper={Wrapper}
								{...props}
							/>
						}
					/>
				);
			case 'cmsBringzzImage':
				var cssValue = `block ${generateCSSString(component, [...getResponsiveStyles('image', 'Width'), 'image_borderRadius', 'image_objectFit', 'image_objectPosition'])}`;
				return (
					component.imageLink_ ?
						<div className={`relative w-full flex ${generateCSSString(component, ['image_alignment', ...getPaddingStyles('image')])}`}>
							<a href={component.imageLink_} target={props.backend ? '_blank' : component.image_target} rel="noreferrer" className={cssValue}>
								<BringzzImage
									src={process.env.REACT_APP_BACKEND_URL + component.image_}
									alt={component.imageAlt_} />
							</a>
						</div>
						:
						<div className={`relative w-full flex ${generateCSSString(component, ['image_alignment', ...getPaddingStyles('image')])}`}>
							<BringzzImage
								className={cssValue}
								src={process.env.REACT_APP_BACKEND_URL + component.image_}
								alt={component.imageAlt_} />
						</div>
				);
			case 'cmsBringzzImageSlider':
				return (
					<BringzzImageSlider
						mode='custom'
						autoSlide={component.slider_autoSlide == 1}
						sections={children}
					/>
				);
			case 'cmsBringzzAccordionList':
				return (
					<AccordionList
						className={generateCSSString(component, [
							...getPaddingStyles('accList'),
							'accList_gap'
						])}
						component={component}
						children={children}
					/>
				);
			case 'cmsBringzzAccordion':
				return (
					<BringzzAccordion
						className={generateCSSString(component, [
							...getPaddingStyles('accordion')
						])}
						defaultValue={
							component.accordion_accordionInitialDisplay == 0
								? component.initialDefault == 1
									? false
									: true
								: component.accordion_accordionInitialDisplay == 1
									? false
									: true
						}
						title={
							<BringzzText
								className={generateCSSString(component, [
									'titleCollapsed_colorClass',
									'title_textAlignment',
									'title_fontWeight'
								])}
								tag={component.title_textTag}
							>
								<span
									dangerouslySetInnerHTML={{ __html: component.titleText_ }}
								/>
							</BringzzText>
						}
						subtitle={
							<BringzzText
								className={generateCSSString(component, [
									'subtitleCollapsed_colorClass',
									'subtitle_textAlignment',
									'subtitle_fontWeight'
								])}
								tag={component.subtitle_textTag}
							>
								<span
									dangerouslySetInnerHTML={{ __html: component.subtitleText_ }}
								/>
							</BringzzText>
						}
						titleIcon={
							component.icon_ == 1 ? (
								<BringzzIcon
									folder={component.icon_iconFolder}
									icon={component.icon_iconComponent}
									size={component.icon_iconSize}
								/>
							) : null
						}
						separated={component.accordionSeparated_}
						children={children}
					/>
				);
			case 'cmsBringzzButton':
				return (
					<div
						className={`w-full flex ${generateCSSString(component, ['btn_alignment'])}`}
					>
						<a rel='noopener noreferrer'
							className={`${generateCSSString(component, ['btn_btnWidth'])}`}
							target={props.backend ? '_blank' : component.btn_target}
							href={component.btnLink_}
						>
							<BringzzButton
								className={
									'w-auto justify-center border ' +
									generateCSSString(component, [
										'btnText_colorClass',
										'btnHoverText_colorClass',
										'btnBackground_colorClass',
										'btnHoverBackground_colorClass',
										'btnBorder_colorClass',
										'btnHoverBorder_colorClass',
										...getMarginStyles('btn')
									])
								}
								children={
									<span
										dangerouslySetInnerHTML={{ __html: component.btnText_ }}
									/>
								}
								icon={
									component.icon_ == 1
										? {
											variant: component.icon_iconComponent,
											folder: component.icon_iconFolder,
											size: component.icon_iconSize
										}
										: null
								}
								size={component.btn_btnSize}
							/>
						</a>
					</div>
				);
			case 'cmsBringzzIcon':
				return (
					<BringzzIcon
						className={
							'flex ' +
							generateCSSString(component, [
								'icon_alignment',
								...getPaddingStyles('icon')
							])
						}
						folder={component.icon_iconFolder}
						icon={component.icon_iconComponent}
						size={component.icon_iconSize}
					/>
				);
			case 'cmsBringzzText':
				return (
					<BringzzText
						className={generateCSSString(component, [
							'text_colorClass',
							'text_textAlignment',
							'text_fontWeight',
							...getPaddingStyles('text')
						])}
						tag={component.text_textTag}
						children={
							<span dangerouslySetInnerHTML={{ __html: component.textText_ }} />
						}
					/>
				);

			default:
				return <div>{`${component.component} Missing`}</div>;
		}
	};
	return (
		<Fragment>
			{
				(component.component !== 'cmsPageFooter' || !props.hideFooter) &&
				(component.hidden_ != 1 &&
					(component.roleId_ === undefined ||
						userComponentAccess(component.roleId_) //return: true later on would be handle in the BE
					)) && (
					<Wrapper
						id={component.anchor_ ?? 'CMS-' + component.uuid}
						className={`cms ${cssValueContainer}`}
					>
						{getComponent()}
					</Wrapper>
				)}
		</Fragment>
	);
}