import * as React from "react";
const IconLaptopThumbsUp = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.3 51.1H49v-8.6c0-.6-.4-1-1-1s-1 .4-1 1v8.6H9.3V30.3c0-1.7 1.4-3 3-3H25c.6 0 1-.4 1-1s-.4-1-1-1H12.3c-2.8 0-5 2.3-5 5v20.8H3c-.6 0-1 .4-1 1s.4 1 1 1h50.3c.6 0 1-.4 1-1s-.5-1-1-1M61 20.7c-.6 0-1 .4-1 1V32c0 .6.4 1 1 1s1-.4 1-1V21.7c0-.5-.4-1-1-1M54.2 18.9c-.2-.5-1-1.4-5.4-5.7-.5-.4-.8-.8-1-1-.4-.4-1-1-1.8-1.2-1.3-.3-2.7.5-3.1 1.8-.2.7 0 1.5.1 2l.7 3.2h-5c-.7 0-1.5 0-2.2.4-.8.4-1.5 1.2-1.7 2.2-.1.2-.1.4-.1.5-.1.6-.1 1.2 0 1.9.1.4.3 1.6 3.4 9 .3.7.7 1.6 1.7 2.2.7.4 1.4.4 2 .4h8.9c.8 0 2.2 0 3.1-1 .9-.9.9-2.3.9-3.1v-9.8c-.2-.6-.3-1.2-.5-1.8m-2.1 13.3c-.3.3-1 .4-1.7.4h-8.8c-.4 0-.8 0-1-.1-.3-.2-.5-.7-.8-1.2-2.8-6.4-3.2-8.1-3.3-8.5-.1-.5-.1-.9 0-1.3 0-.1 0-.2.1-.3.1-.4.4-.8.7-.9.2-.3.7-.3 1.1-.3h6.4c.3 0 .6-.1.8-.4.2-.2.3-.5.2-.8l-1-4.5c-.1-.4-.2-.8-.1-1 .1-.3.4-.5.7-.4s.6.4.9.7c.2.2.6.6 1.1 1 1.4 1.3 4.5 4.4 5 5 .1.3.1.6.1 1v9.8c0 .8-.1 1.5-.4 1.8" />
  </svg>
);
export default IconLaptopThumbsUp;
