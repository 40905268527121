import * as React from "react";
const IconVirusAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M20.4 5.9a1 1 0 1 0 0 2H23v2.536a13.54 13.54 0 0 0-7.884 3.266l-1.852-1.852 1.543-1.543a1 1 0 0 0-1.414-1.414l-4.5 4.5a1 1 0 1 0 1.414 1.414l1.543-1.543 1.852 1.852A13.54 13.54 0 0 0 10.436 23H7.9v-2.6a1 1 0 1 0-2 0v7.2a1 1 0 1 0 2 0V25h2.536a13.54 13.54 0 0 0 3.266 7.884l-1.852 1.852-1.543-1.543a1 1 0 0 0-1.414 1.414l4.5 4.5a1 1 0 0 0 1.414-1.414l-1.543-1.543 1.852-1.852A13.54 13.54 0 0 0 23 37.564V40.1h-2.6a1 1 0 1 0 0 2h7.2a1 1 0 1 0 0-2H25v-2.536a13.54 13.54 0 0 0 7.884-3.266l1.852 1.852-1.543 1.543a1 1 0 0 0 1.414 1.414l4.5-4.5a1 1 0 0 0-1.414-1.414l-1.543 1.543-1.852-1.852A13.54 13.54 0 0 0 37.564 25H40.1v2.6a1 1 0 1 0 2 0v-7.2a1 1 0 1 0-2 0V23h-2.536a13.54 13.54 0 0 0-3.266-7.884l1.852-1.852 1.543 1.543a1 1 0 0 0 1.414-1.414l-4.5-4.5a1 1 0 1 0-1.414 1.414l1.543 1.543-1.852 1.852A13.54 13.54 0 0 0 25 10.436V7.9h2.6a1 1 0 1 0 0-2zm3.6 6.5c6.406 0 11.6 5.193 11.6 11.6 0 6.406-5.194 11.6-11.6 11.6-6.407 0-11.6-5.194-11.6-11.6 0-6.407 5.193-11.6 11.6-11.6m6.4 11.15a.35.35 0 1 1 .7 0 .35.35 0 0 1-.7 0m.35-2.35a2.35 2.35 0 1 0 0 4.7 2.35 2.35 0 0 0 0-4.7m-6.3 9.2a.35.35 0 1 0 0 .7.35.35 0 0 0 0-.7m-2.35.35a2.35 2.35 0 1 1 4.7 0 2.35 2.35 0 0 1-4.7 0M18.7 20.4a1.7 1.7 0 1 1 3.4 0 1.7 1.7 0 0 1-3.4 0m1.7-3.7a3.7 3.7 0 1 0 0 7.4 3.7 3.7 0 0 0 0-7.4"
      clipRule="evenodd"
    />
  </svg>
);
export default IconVirusAlt;
