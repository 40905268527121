import * as React from "react";
const IconSketch = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M3.3 20.3 1 23.4h11.3l.8-16.3zM49.5 5.6 33.4 4l17 18.5zM14 27.2l-1.4-2.7H1.1l28.2 32.8zm.2-3.8h35.6L39.6 12.3 32 4zm37.2 1.1L34.7 57.4l28.2-32.8H51.4zM52 8.6 50.9 7l.1 2.2.7 14.2H63zm-37.9 3.1-.6 10.7L30.6 4 14.5 5.6zm36.1 12.8H13.8L19.2 35 32 60z" />
  </svg>
);
export default IconSketch;
