import * as React from "react";
const IconPaintBucketAltCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.8 21.8C51.8 10.9 42.9 2 32 2s-19.8 8.9-19.8 19.8v2.7l1.6 32.6c.1 2.7 2.4 4.9 5.1 4.9H45c2.7 0 5-2.1 5.1-4.9l1.6-32.6v-2.7zm-37.6 3.7H20v4.9c0 2.1 1.7 3.8 3.8 3.8H26c.5 0 .9.4.9.9v4.8c0 2.9 2.3 5.2 5.2 5.2 2.6 0 4.7-2 4.9-4.6h3.3c2.1 0 3.8-1.7 3.8-3.8V25.5h5.7l-1.2 25.3h-33zM36 33.6c-.6 0-1 .4-1 1V40c0 1.6-1.3 3-3 3-1.8 0-3.2-1.4-3.2-3.2V35c0-1.6-1.3-2.9-2.9-2.9h-2.2c-1 0-1.8-.8-1.8-1.8v-4.9H42v11.2c0 1-.8 1.8-1.8 1.8H37v-3.8c0-.6-.5-1-1-1M32 4c9.8 0 17.8 8 17.8 17.8v1.6H14.2v-1.6C14.2 12 22.2 4 32 4m13.1 56H18.9c-1.7 0-3.1-1.3-3.1-3l-.2-4.2h32.8l-.2 4.2c-.1 1.7-1.5 3-3.1 3" />
  </svg>
);
export default IconPaintBucketAltCopy;
