import * as React from "react";
const IconCorners = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M47.5 34.3 22.4 23.5c-.4-.2-.8-.1-1.1.2s-.4.7-.2 1.1l10.8 25.1q.3.6.9.6c.4 0 .8-.3.9-.7l3.4-10.2 10.2-3.5c.4-.1.7-.5.7-.9s-.1-.7-.5-.9m-11.4 3.6q-.45.15-.6.6l-2.7 8.2L24 26.3l20.4 8.8z" />
    <path d="M60.5 2H12.1c-5.3 0-9.6 4.3-9.6 9.6V61c0 .6.4 1 1 1s1-.4 1-1V11.6C4.5 7.4 7.9 4 12.1 4h48.4c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconCorners;
