import * as React from "react";
const IconRotation = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M47.6 26.2V11.3c0-1.6-.9-3-2.3-3.6L33.7 2.3c-1.1-.5-2.3-.5-3.4 0L18.7 7.7c-1.4.7-2.3 2.1-2.3 3.6v14.9c0 1.4.8 2.8 2 3.5L30 36.4c.6.4 1.3.5 2 .5s1.4-.2 2-.5l11.6-6.7c1.3-.7 2-2 2-3.5M31.2 4.1c.2-.1.5-.2.8-.2s.6.1.8.2l11.5 5.3L32 15.1 19.7 9.4zM19.4 28c-.6-.4-1-1-1-1.7V11L31 16.8v17.8zm25.2 0L33 34.7V16.8L45.6 11v15.200000000000001c0 .7-.3 1.4-1 1.8M26.9 57.1c.5 0 1-.4 1-1s-.4-1-1-1c-12.2-.6-19.7-3.8-19.7-6.6 0-1.8 3.9-4.5 12.5-5.8.5-.1.9-.6.8-1.1s-.6-.9-1.1-.8c-8.9 1.4-14.2 4.3-14.2 7.8 0 5.1 11.2 8 21.7 8.5M46.4 41c-.5-.1-1.1.3-1.2.8s.3 1.1.8 1.2c7.5 1.4 10.8 3.8 10.8 5.5 0 2.3-5.7 5.3-16.3 6.3-.5.1-1 .5-.9 1.1 0 .5.5.9 1 .9h.1c8.4-.8 18.1-3.5 18.1-8.3 0-3.2-4.5-5.9-12.4-7.5" />
    <path d="M26.4 50.3c-.4-.3-1-.3-1.4.1s-.3 1 .1 1.4l4.5 3.9-4.6 4.7c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l5.4-5.4c.2-.2.3-.5.3-.7s-.1-.5-.3-.7z" />
  </svg>
);
export default IconRotation;
