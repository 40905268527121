import * as React from "react";
const IconKeyAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.2 7.8C48.5.1 36 .1 28.4 7.8c-5.1 5.1-7 12.5-5 19.4L2.8 47.8c-.5.5-.8 1.2-.8 1.9v9.6C2 60.8 3.2 62 4.7 62h9.6c.7 0 1.4-.3 1.9-.8l2.4-2.4c.6-.6.9-1.4.8-2.2l-.2-1.6 2.6-.2c1.3-.1 2.3-1.1 2.4-2.4l.2-2.6 1.6.2c.8.1 1.5-.1 2.1-.7.6-.5.9-1.2.9-2v-2.2h2.1c.7 0 1.4-.3 1.9-.8l3.9-3.9c6.9 2 14.2.2 19.3-4.8 7.7-7.7 7.7-20.2 0-27.8m-1.4 26.4c-4.6 4.6-11.5 6.2-17.8 4.2-.4-.1-.7 0-1 .2l-4.4 4.3c-.1.1-.3.2-.5.2H28c-.6 0-1 .4-1 1v3.2c0 .2-.1.4-.2.5-.2.1-.3.2-.5.2l-2.6-.3c-.3 0-.5 0-.7.2s-.3.4-.4.7l-.3 3.6c0 .3-.3.6-.6.6l-3.7.3c-.3 0-.5.2-.7.4s-.2.5-.2.7l.3 2.6c0 .2 0 .4-.2.6l-2.4 2.4c-.1.1-.3.2-.5.2H4.7c-.4 0-.7-.3-.7-.7v-9.6c0-.2.1-.4.2-.5l21-21.1c.3-.3.4-.7.2-1-2.1-6.4-.4-13.2 4.3-18 3.4-3.4 8-5.2 12.5-5.2s9.1 1.7 12.5 5.2c7 7 7 18.2.1 25.1" />
    <path d="M41.5 13.8c-2.4 2.4-2.4 6.2 0 8.6 1.2 1.2 2.8 1.8 4.3 1.8s3.1-.6 4.3-1.8c2.4-2.4 2.4-6.2 0-8.6-2.3-2.3-6.2-2.3-8.6 0m7.2 7.2c-1.6 1.6-4.2 1.6-5.8 0s-1.6-4.2 0-5.8c.8-.8 1.8-1.2 2.9-1.2 1 0 2.1.4 2.9 1.2 1.6 1.7 1.6 4.2 0 5.8" />
  </svg>
);
export default IconKeyAlt;
