import * as React from "react";
const IconTrolleyCart = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M25.9 60.8c-4.4 0-8.5-2.7-10.1-7.1-1.9-5.3.5-11.1 5.5-13.5L11.1 12.8c-.3-.9-1.2-1.6-2.2-1.8l-6.1-.9C2.3 10 1.9 9.5 2 9c.1-.6.6-1 1.1-.9l6.1.9c1.7.3 3.1 1.4 3.7 3L23 39.5c2.2-.6 4.5-.5 6.6.3l.7-.3-10.2-27.7c-.9-2.4.4-5.2 2.8-6.1l5.8-2.1c1.2-.4 2.5-.4 3.6.1s2 1.5 2.5 2.7l3.1 8.3 4.8-1.8c1.2-.4 2.5-.4 3.6.1s2 1.5 2.5 2.7l5.6 15.1.7-.3c2.4-.9 5.2.4 6.1 2.8l.7 1.9c.4 1.2.4 2.5-.1 3.6s-1.5 2-2.7 2.5l-.7.3 1.6 4.2c.2.5-.1 1.1-.6 1.3s-1.1-.1-1.3-.6l-1.6-4.2-19.7 7.3c.1 1.7-.3 3.4-1 5-1.2 2.6-3.4 4.6-6.1 5.6-1.3.3-2.6.6-3.8.6m0-19.6c-1 0-2.1.2-3 .5-4.5 1.7-6.9 6.7-5.2 11.3 1.7 4.5 6.7 6.9 11.3 5.2 2.2-.8 4-2.4 4.9-4.6 1-2.1 1.1-4.5.3-6.7s-2.4-4-4.6-4.9c-1.2-.6-2.5-.8-3.7-.8m10.1 5c.2.4.3.8.4 1.3l21.8-8.1c.7-.3 1.2-.8 1.5-1.4.3-.7.3-1.4.1-2.1l-.7-1.9c-.5-1.4-2.1-2.1-3.5-1.6L32 41.1c1.8 1.3 3.2 3 4 5.1m-10.3-25 6.5 17.7 20.1-7.4-5.6-15.1c-.3-.7-.8-1.2-1.4-1.5-.7-.3-1.4-.3-2.1-.1l-7.5 2.8 1.8 4.8c.2.5-.1 1.1-.6 1.3s-1.1-.1-1.3-.6l-1.8-4.8zm-.1-14.4-2 .7C22.2 8 21.5 9.6 22 11l3.1 8.3 9.2-3.4 1.8-.6L32.9 7c-.5-1.4-2.1-2.1-3.5-1.6l-1.9.7 1.6 4.3c.2.5-.1 1.1-.6 1.3s-1.1-.1-1.3-.6zm.3 47.1c-1.6 0-3.1-1-3.7-2.6-.7-2 .3-4.3 2.3-5s4.3.3 5 2.3-.3 4.3-2.3 5c-.4.2-.9.3-1.3.3m-.7-5.7c-1 .4-1.5 1.5-1.1 2.4.4 1 1.5 1.5 2.4 1.1 1-.4 1.5-1.5 1.1-2.4-.3-1-1.4-1.5-2.4-1.1" />
  </svg>
);
export default IconTrolleyCart;
