import * as React from "react";
const IconMountain = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 16 17"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M13.839 14.075a1 1 0 0 0 .79-1.612L11.09 7.88a1 1 0 0 0-1.583 0L7.41 10.592a1 1 0 0 1-1.568.02l-.675-.832a1 1 0 0 0-1.553 0L1.45 12.444a1 1 0 0 0 .776 1.63h11.612"
      clipRule="evenodd"
    />
    <circle cx={5.047} cy={4.234} r={1.641}  />
  </svg>
);
export default IconMountain;
