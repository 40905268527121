import * as React from "react";
const IconLungsAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path
      d="M6.6 58.7z"
      style={{
        fill: "none",
        stroke: "#000",
        strokeMiterlimit: 10,
      }}
    />
    <path d="M48.9 18.1c-1.8-.8-3.9-.7-5.6.4s-2.7 2.9-2.7 5v10.1L33 29.2V5.3c0-.6-.4-1-1-1s-1 .4-1 1v23.9l-7.6 4.4V23.5c0-2-1-3.9-2.7-5s-3.8-1.2-5.6-.4C7.1 21.8 2 29.9 2 38.7v9.9c0 1.9.9 3.6 2.4 4.7 1 .8 2.2 1.1 3.5 1.1.6 0 1.1-.1 1.7-.3l6.9-2.1c4.1-1.3 6.9-5 6.9-9.3v-6.9l8.6-5 8.6 5v6.9c0 4.3 2.8 8.1 6.9 9.4l6.9 2.1c.6.2 1.1.3 1.7.3 1.2 0 2.4-.4 3.5-1.1 1.5-1.1 2.4-2.8 2.4-4.7v-9.9c0-8.9-5.1-17-13.1-20.7M21.4 42.7c0 3.4-2.2 6.4-5.5 7.4L9 52.3c-1.2.3-2.4.1-3.4-.6S4 49.8 4 48.6v-9.9c0-8 4.7-15.4 11.9-18.7.5-.2 1.1-.4 1.6-.4q1.05 0 2.1.6c1.1.7 1.8 1.9 1.8 3.3v11.2l-3.3 1.9c-.5.3-.6.9-.4 1.4.2.3.5.5.9.5.2 0 .3 0 .5-.1l2.3-1.3zM60 48.6c0 1.2-.6 2.4-1.6 3.1s-2.2.9-3.4.6l-6.9-2.1c-3.3-1-5.5-4-5.5-7.4V37l2.3 1.3c.5.3 1.1.1 1.4-.4s.1-1.1-.4-1.4l-3.3-1.9V23.5c0-1.3.7-2.6 1.8-3.3q.9-.6 2.1-.6c.5 0 1.1.1 1.6.4C55.3 23.3 60 30.7 60 38.7z" />
  </svg>
);
export default IconLungsAlt;
