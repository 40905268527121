import * as React from "react";
const IconShipUpArrow = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M37.5 57.2c-.2-.2-.5-.4-.7-.5L41 41.4c.7-2.4-.6-5-2.9-5.9L35 34.2v-4.6c0-.6-.4-1-1-1s-1 .4-1 1v3.8l-9.2-3.8a5 5 0 0 0-3.8 0l-9.2 3.8v-7c0-1.6 1.3-3 3-3h12.9c.6 0 1-.4 1-1s-.4-1-1-1H15.6v-5.6c0-1.6 1.3-3 3-3h7.6c.6 0 1-.4 1-1s-.4-1-1-1H23V5.9c0-.6-.4-1-1-1s-1 .4-1 1v4.9h-2.4c-2.7 0-5 2.2-5 5v5.6c-2.6.1-4.7 2.3-4.7 4.9v7.8l-3.1 1.3c-2.3 1-3.6 3.5-2.9 5.9l4.3 15.3c-.2.2-.4.4-.7.6l-3.5 3c-.4.4-.5 1-.1 1.4s1 .5 1.4.1l3.5-3c1.1-1 2.8-1 3.9 0l.9.8c1.9 1.6 4.6 1.6 6.5 0l.9-.8c1.1-1 2.8-1 3.9 0l.9.8c1.9 1.6 4.6 1.6 6.5 0l.9-.8c1.1-1 2.8-1 3.9 0l3.5 3c.2.2.4.2.7.2s.6-.1.8-.3c.4-.4.3-1-.1-1.4zm-6.5 0-.9.8c-1.1 1-2.8 1-3.9 0l-.9-.8c-1.9-1.6-4.6-1.6-6.5 0l-1 .8c-1.1 1-2.8 1-3.9 0l-.9-.8c-1.1-.9-2.5-1.3-3.9-1.1L4.9 40.9c-.4-1.4.3-3 1.7-3.5l3.6-1.5h.1l10.6-4.4c.7-.3 1.6-.3 2.3 0l10.5 4.4h.1l3.6 1.5c1.4.6 2.1 2.1 1.7 3.5l-4.3 15.2c-1.3-.2-2.7.2-3.8 1.1M47.3 2c-7.7 0-14.1 6.3-14.1 14.1 0 7.7 6.3 14.1 14.1 14.1s14.1-6.3 14.1-14.1C61.3 8.3 55 2 47.3 2m0 26.1c-6.6 0-12.1-5.4-12.1-12.1S40.6 4 47.3 4c6.6 0 12.1 5.4 12.1 12.1s-5.5 12-12.1 12" />
    <path d="M48 11.9c-.4-.4-1-.4-1.4 0l-4.7 4.7c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l4-4 3.9 3.9c.4.4 1 .4 1.4 0s.4-1 0-1.4z" />
  </svg>
);
export default IconShipUpArrow;
