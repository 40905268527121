import * as React from "react";
const IconSwapVertical = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M15.2 59c-.3 0-.5-.1-.7-.3L3.3 47.4c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l9.5 9.5V21.4c0-.6.4-1 1-1s1 .4 1 1v34.2l9.9-9.9c.4-.4 1-.4 1.4 0s.4 1 0 1.4L16 58.7c-.2.2-.5.3-.8.3M48.8 43.6c-.6 0-1-.4-1-1V8.5l-9.9 9.9c-.4.4-1 .4-1.4 0s-.4-1 0-1.4L48 5.3c.4-.4 1-.4 1.4 0l11.2 11.2c.4.4.4 1 0 1.4s-1 .4-1.4 0l-9.5-9.5v34.2c.1.6-.4 1-.9 1" />
  </svg>
);
export default IconSwapVertical;
