import * as React from "react";
const IconCactus3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.9 51.9c-.2-.5-.7-.8-1.3-.6-1.2.4-3.1.7-6 .6v-8.4h5.8c.6 0 1-.4 1-1V35c0-.6-.4-1-1-1s-1 .4-1 1v6.4h-4.8v-14c0-2.7-2.2-4.9-4.9-4.9s-4.9 2.2-4.9 4.9v10.4h-4.7v-10c0-.6-.4-1-1-1s-1 .4-1 1v11c0 .6.4 1 1 1h5.7v10.9c-4-.7-8.8-1.8-14.7-3.5-1.5-.4-2.9-.7-4.4-1V35.8h6.4c.6 0 1-.4 1-1v-10c0-.6-.4-1-1-1s-1 .4-1 1v9h-5.4V16.4c0-3.5-2.8-6.3-6.3-6.3s-6.3 2.8-6.3 6.3v12.5h-7V15.1c0-.6-.4-1-1-1s-1 .4-1 1v14.8c0 .6.4 1 1 1h8v14.6c-5.9.4-10.1 1.6-10.4 1.7-.5.2-.8.7-.7 1.2s.7.8 1.2.7c.1 0 12.8-3.7 26.3 0 11.5 3.2 20.2 4.7 26.1 4.7 2.3 0 4.2-.2 5.6-.7.6-.1.9-.7.7-1.2m-46.7-6.5v-29c0-2.4 1.9-4.3 4.3-4.3s4.3 1.9 4.3 4.3v29.5c-3.1-.4-6-.5-8.6-.5m31.7-18c0-1.6 1.3-2.9 2.9-2.9s2.9 1.3 2.9 2.9v24.3c-1.7-.1-3.6-.4-5.8-.7z" />
  </svg>
);
export default IconCactus3;
