import * as React from "react";
const IconToast = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m24.5 17.2-7.1-1.9c-2.6-.7-5.3.8-6 3.4L6.2 38.2C5 44 8.5 47.1 11.3 48.3l-2.1 7.6-6-1.6c-.5-.2-1 .1-1.2.7-.1.5.2 1.1.7 1.2l7 1.8 7.2 1.9h.3c.4 0 .8-.3 1-.7.1-.5-.2-1.1-.7-1.2l-6.2-1.7 2.1-7.6c.5.1.9.1 1.4.1 6.4 0 8.1-6.2 8.1-6.3L28.1 23c.5-2.5-1-5.1-3.6-5.8m1.5 5.4-5.2 19.5c-.1.2-1.6 5.9-8 4.6-.6-.2-5.8-1.9-4.6-8l5.2-19.5c.2-.7.7-1.4 1.3-1.7.4-.3.9-.4 1.4-.4.2 0 .5 0 .7.1l7.1 1.9c1.6.4 2.5 2 2.1 3.5M62 55c-.1-.5-.7-.9-1.2-.7l-6 1.6-2.1-7.6c2.8-1.2 6.3-4.3 5.1-10.1l-5.2-19.5c-.3-1.3-1.1-2.3-2.3-3s-2.4-.8-3.7-.5l-7.1 1.9c-1.3.3-2.3 1.1-3 2.3-.7 1.1-.8 2.4-.5 3.7l5.2 19.4c0 .1 1.7 6.3 8.1 6.3.4 0 .9 0 1.4-.1l2.1 7.6-6.2 1.7c-.5.1-.8.7-.7 1.2.1.4.5.7 1 .7h.3l7.2-1.9 6.9-1.9c.5-.1.8-.6.7-1.1M43.2 42.1 38 22.6c-.2-.7-.1-1.5.3-2.2s1-1.1 1.7-1.3l7.1-1.9c.7-.2 1.5-.1 2.2.3s1.1 1 1.3 1.7l5.2 19.4c1.2 6.2-4 7.9-4.6 8.1-6.4 1.2-7.9-4.4-8-4.6M25.4 13c.2.2.5.4.8.4.2 0 .5-.1.6-.2.4-.4.5-1 .1-1.4l-3.6-4.3c-.4-.4-1-.5-1.4-.1s-.5 1-.1 1.4zM32.1 4c-.6 0-1 .4-1 1v5.6c0 .6.4 1 1 1s1-.4 1-1V5c0-.6-.4-1-1-1M38.1 13.3c.3 0 .6-.1.8-.4l3.6-4.3c.4-.4.3-1.1-.1-1.4s-1.1-.2-1.4.2l-3.6 4.3c-.4.4-.3 1.1.1 1.4.2.2.4.2.6.2" />
  </svg>
);
export default IconToast;
