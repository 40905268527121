import * as React from "react";
const IconRoadAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M28.8 22.8c4.4 0 7.9-3.5 7.9-7.9V3c0-.6-.4-1-1-1s-1 .4-1 1v11.9c0 3.3-2.6 5.9-5.9 5.9h-5.3c-9.7 0-17.6 7.9-17.6 17.6V61c0 .6.4 1 1 1s1-.4 1-1V38.3c0-8.6 7-15.6 15.6-15.6h5.3zM57.1 2c-.6 0-1 .4-1 1v22.2c0 8.6-7 15.6-15.6 15.6h-5.3c-4.4 0-7.9 3.5-7.9 7.9V61c0 .6.4 1 1 1s1-.4 1-1V48.7c0-3.3 2.6-5.9 5.9-5.9h5.3c9.7 0 17.6-7.9 17.6-17.6V3c0-.6-.4-1-1-1M46.8 6.5c.6 0 1-.4 1-1V3c0-.6-.4-1-1-1s-1 .4-1 1v2.5c0 .6.4 1 1 1" />
    <path d="M44.8 24.8c.1.1.3.1.4.1q.6 0 .9-.6c.7-1.5 1.2-3.1 1.4-4.7.1-.5-.3-1.1-.9-1.1-.5-.1-1.1.3-1.1.9-.2 1.4-.6 2.8-1.3 4.1-.1.5.1 1.1.6 1.3M38.9 31.5c.2 0 .3 0 .5-.1q2.25-1.2 3.9-3c.4-.4.4-1 0-1.4s-1-.4-1.4 0c-1 1-2.2 1.9-3.4 2.6-.5.3-.7.9-.5 1.4.2.3.6.5.9.5M46.8 15.8c.6 0 1-.4 1-1v-4.7c0-.6-.4-1-1-1s-1 .4-1 1v4.7c0 .6.4 1 1 1M25 32.8c-1.5.7-2.8 1.7-4 2.9-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3 1-1 2.2-1.9 3.5-2.5.5-.2.7-.8.5-1.3-.3-.5-.9-.7-1.4-.5M29.8 31.4c-.5.1-.9.6-.8 1.1s.5.9 1 .9h.1c.6-.1 1.3-.1 2-.1.9 0 1.8-.1 2.7-.2.5-.1.9-.6.8-1.2-.1-.5-.6-.9-1.2-.8-1.5.2-3.1 0-4.6.3M17.2 48.2c-.6 0-1 .4-1 1v4.7c0 .6.4 1 1 1s1-.4 1-1v-4.7c0-.6-.4-1-1-1M19.4 39.2c-.5-.3-1.1-.1-1.3.4-.8 1.5-1.3 3.1-1.6 4.7-.1.5.3 1.1.8 1.2h.2c.5 0 .9-.3 1-.8.2-1.4.7-2.8 1.4-4.1.2-.5 0-1.1-.5-1.4M17.2 57.5c-.6 0-1 .4-1 1V61c0 .6.4 1 1 1s1-.4 1-1v-2.5c0-.6-.4-1-1-1" />
  </svg>
);
export default IconRoadAlt;
