import * as React from "react";
const IconToilet = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M24.1 29.1H3c-.3 0-.6.2-.8.4-.2.3-.2.6-.1.9l10.6 26.2q.3.6.9.6c.6 0 .8-.2.9-.6l10.6-26.2c.1-.3.1-.7-.1-.9s-.5-.4-.9-.4M13.6 53.7 4.5 31.1h18.2zM61.9 56.6 51.4 30.4q-.3-.6-.9-.6c-.6 0-.8.2-.9.6L38.9 56.6c-.1.3-.1.7.1.9.2.3.5.4.8.4H61c.3 0 .6-.2.8-.4s.3-.6.1-.9M41.3 56l9.1-22.6L59.5 56zM13.6 22c4.4 0 8-3.6 8-8s-3.6-8-8-8-8 3.6-8 8 3.6 8 8 8m0-14c3.3 0 6 2.7 6 6s-2.7 6-6 6-6-2.7-6-6 2.7-6 6-6M50.4 23.4c4.4 0 8-3.6 8-8s-3.6-8-8-8-8 3.6-8 8 3.6 8 8 8m0-14c3.3 0 6 2.7 6 6s-2.7 6-6 6-6-2.7-6-6 2.7-6 6-6" />
  </svg>
);
export default IconToilet;
