import React from "react";
import BringzzIcon from "../BringzzIcon";
import BringzzText from "../BringzzText";
import PropTypes from "prop-types";
import classnames from "classnames";

// TODO: Update greetingComponent once provided in Design
const BringzzTopBar = ({ className, sections }) => {
  const LocationComponent = ({ location }) => {
    return (
      <>
        <div className="flex items-center">
          <BringzzIcon icon="IconGeoLocation" folder="HealthIcons" size="22" />
          <BringzzText className="mr-1 font-normal">{location}</BringzzText>
          <BringzzIcon icon="IconChevronDown" folder="LineIcons" size="16" />
        </div>
      </>
    );
  };

  const NotificationComponent = ({ unread }) => {
    return (
      <>
        <div className="relative cursor-pointer">
          <BringzzIcon icon="IconBell" folder="CustomIcons" size="24" />
          {unread && (
            <div
              className="absolute top-0 right-0 text-xs text-magic-lilac"
              style={{ transform: "translate(-25%, -25%)" }}
            >
              &#9679;
            </div>
          )}
        </div>
      </>
    );
  };

  const renderSection = (section) => {
    const sectionComponent = (() => {
      switch (section.name) {
        case "logo":
          return <img className="h-5" src={section.value} alt="BringzzLogo" />;
        case "title":
          return (
            <BringzzText className="text-xs font-gt-maru uppercase">
              {section.value}
            </BringzzText>
          );
        case "location":
          return <LocationComponent location={section.value} />;
        case "notification":
          return <NotificationComponent unread={section.value} />;
        case "greeting":
          return (
            <div className="font-bold">{`Hi, ${section.value.charAt(0).toUpperCase() + section.value.slice(1).toLowerCase()}`}</div>
          );
        default:
          return <></>;
      }
    })();

    return (
      <div
        key={section.name}
        className={`flex justify-${section.alignment}`}
        onClick={section.onClick}
      >
        {sectionComponent}
      </div>
    );
  };

  return (
    <div
      className={classnames(className, "flex justify-center items-center h-11")}
    >
      <div className="flex justify-between items-center w-full px-4">
        {sections.map(renderSection)}
      </div>
    </div>
  );
};

BringzzTopBar.propTypes = {
  className: PropTypes.string,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOf([
        "logo",
        "title",
        "location",
        "notification",
        "greeting",
      ]),
      value: PropTypes.any.isRequired,
      alignment: PropTypes.string.isRequired,
      onClick: PropTypes.func,
    })
  ).isRequired,
};

export default BringzzTopBar;
