import * as React from "react";
const IconElegantTable2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M16.6 33.9H4V14.3c0-.6-.4-1-1-1s-1 .4-1 1v35.5c0 .6.4 1 1 1s1-.4 1-1v-6.4h15.7v6.4c0 .6.4 1 1 1s1-.4 1-1V39c0-2.8-2.3-5.1-5.1-5.1M4 41.3v-5.4h12.6c1.7 0 3 1.4 3 3v2.4zM61 13.3c-.6 0-1 .4-1 1V34H47.4c-2.8 0-5 2.3-5 5v10.8c0 .6.4 1 1 1s1-.4 1-1v-6.4H60v6.4c0 .6.4 1 1 1s1-.4 1-1V14.3c0-.6-.4-1-1-1m-16.7 28V39c0-1.7 1.4-3 3-3H60v5.4H44.3zM46.3 25.2c.6 0 1-.4 1-1s-.4-1-1-1H17.7c-.6 0-1 .4-1 1s.4 1 1 1H31v24.6c0 .6.4 1 1 1s1-.4 1-1V25.2z" />
  </svg>
);
export default IconElegantTable2;
