import * as React from "react";
const IconCloudComputing1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.8 44.5c-2.9 0-5.4 2.1-5.9 4.9h-4.1V36.8c.2.1.4.1.7.1 6.2-1.7 10.6-7.4 10.6-13.9 0-3.6-1.4-7.1-3.8-9.8-2.2-2.4-5.2-4-8.4-4.4-1.7-2.9-4.3-5-7.3-6-1.3-.5-2.8-.8-4.4-.8-7.6 0-13.7 6.1-13.8 13.7-5.7.3-10.3 5-10.3 10.8 0 5.9 5.1 10.8 11.2 10.8H20v12.1h-4c-.5-2.8-2.9-4.9-5.9-4.9-3.3 0-5.9 2.7-5.9 5.9s2.7 5.9 5.9 5.9c2.9 0 5.4-2.1 5.9-4.9h5.1c.6 0 1-.4 1-1V30.7c0-.6-.4-1-1-1s-1 .4-1 1v4.6h-1.7c-5 0-9.2-4.1-9.2-8.8 0-5 3.9-8.8 8.8-8.8h.4c.6 0 1-.4 1-1v-.9C19.4 9.3 24.7 4 31.2 4c1.4 0 2.7.2 3.8.6 2.7.9 5 2.9 6.5 5.6.2.3.4.5.8.5 2.9.3 5.7 1.7 7.7 3.9 2.1 2.3 3.3 5.3 3.3 8.4 0 5.6-3.7 10.5-9.1 11.9-.2 0-.2.1-.3.1v-4.3c0-.6-.4-1-1-1s-1 .4-1 1v19.7c0 .6.4 1 1 1H48c.5 2.8 2.9 4.9 5.9 4.9 3.3 0 5.9-2.7 5.9-5.9s-2.7-5.9-6-5.9m-43.6 9.9c-2.2 0-3.9-1.8-3.9-3.9 0-2.2 1.8-3.9 3.9-3.9s3.9 1.8 3.9 3.9-1.7 3.9-3.9 3.9m43.6 0c-2.2 0-3.9-1.8-3.9-3.9 0-2.2 1.8-3.9 3.9-3.9 2.2 0 3.9 1.8 3.9 3.9.1 2.1-1.7 3.9-3.9 3.9" />
    <path d="M33 50.2V27.9c0-.6-.4-1-1-1s-1 .4-1 1v22.3c-2.8.5-4.9 2.9-4.9 5.9 0 3.3 2.7 5.9 5.9 5.9s5.9-2.7 5.9-5.9c0-3-2.1-5.4-4.9-5.9M32 60c-2.2 0-3.9-1.8-3.9-3.9 0-2.2 1.8-3.9 3.9-3.9 2.2 0 3.9 1.8 3.9 3.9S34.2 60 32 60" />
  </svg>
);
export default IconCloudComputing1;
