import * as React from "react";
const IconHomeCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.3 19.3 33.9 2.6c-1.2-.7-2.7-.7-3.9 0L3.7 19.2c-1.1.7-1.7 1.9-1.7 3.1v34.9C2 59.9 4.1 62 6.7 62h13.6c2.6 0 4.8-2.1 4.8-4.8V46.3c0-1.5 1.2-2.7 2.7-2.7h8.3c1.5 0 2.8 1.2 2.8 2.8v10.9c0 2.6 2.1 4.7 4.7 4.7h13.6c2.6 0 4.8-2.1 4.8-4.8V22.4c0-1.3-.6-2.4-1.7-3.1M60 57.2c0 1.5-1.2 2.8-2.8 2.8H43.7c-1.5 0-2.7-1.2-2.7-2.7v-11c0-2.6-2.1-4.8-4.8-4.8h-8.3c-2.6 0-4.7 2.1-4.7 4.7v10.9c0 1.5-1.2 2.8-2.8 2.8H6.7C5.2 60 4 58.8 4 57.3v-35c0-.6.3-1.1.8-1.4L31.1 4.3c.5-.3 1.2-.3 1.7 0L59.2 21c.5.3.8.8.8 1.4z" />
  </svg>
);
export default IconHomeCopy;
