import * as React from "react";
const IconBaloonCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M41.4 2c-4.8 0-9.1 3.2-11.3 8.3-1.9-1.2-4.1-2-6.5-2-7.6 0-13.7 7.4-13.7 16.6 0 8.9 5.8 18.8 13.5 18.9-.5 1.3-1.1 3.4-1.4 4.4-.6 2.1 0 4.2.5 6.2.6 2.3 1.2 4.3 0 6.1-.3.5-.2 1.1.3 1.4.2.1.4.2.5.2.3 0 .6-.2.8-.5 1.7-2.6.9-5.3.3-7.7-.5-1.8-1-3.5-.5-5.1.3-1 1.3-4 1.7-5.1 4.5-1 8.2-5.3 10.1-10.5 1 .7 2 1.3 3.1 1.6.3.1.6.1.9.2-.1.4-.3.9-.6 1.6-.5 1.4-1.1 3-1.3 3.8-.6 2.1 0 4.2.5 6.2.6 2.3 1.2 4.3 0 6.1-.3.5-.2 1.1.3 1.4.2.1.4.2.5.2.3 0 .6-.2.8-.5 1.7-2.6.9-5.3.3-7.7-.5-1.8-1-3.5-.5-5.1.2-.7.8-2.3 1.3-3.6.3-.7.5-1.3.6-1.7.1-.1.1-.3 0-.4q1.05 0 2.1-.3C49.4 33.3 54 25.5 54 17.6 54.1 8.9 48.4 2 41.4 2M23.6 41.8c-6.6 0-11.7-9.1-11.7-17 0-8 5.3-14.6 11.7-14.6s11.7 6.5 11.7 14.6c0 7.9-5.1 17-11.7 17m19.6-9c-1.2.4-2.5.4-3.7 0-1-.3-2.1-.9-3-1.8.6-2 .9-4.1.9-6.2 0-5.5-2.2-10.3-5.6-13.3C33.5 6.9 37.2 4 41.4 4c5.9 0 10.7 6 10.7 13.3 0 6.9-4.1 14-8.9 15.5" />
  </svg>
);
export default IconBaloonCopy;
