import * as React from "react";
const IconAngleDoubleUpCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55 50.8c-.3 0-.5-.1-.7-.3L32 28.6 9.7 50.5c-.4.4-1 .4-1.4 0s-.4-1 0-1.4l23-22.6c.4-.4 1-.4 1.4 0l23 22.6c.4.4.4 1 0 1.4-.2.2-.4.3-.7.3" />
    <path d="M55 37.8c-.3 0-.5-.1-.7-.3L32 15.6 9.7 37.5c-.4.4-1 .4-1.4 0s-.4-1 0-1.4l23-22.6c.4-.4 1-.4 1.4 0l23 22.6c.4.4.4 1 0 1.4-.2.2-.4.3-.7.3" />
  </svg>
);
export default IconAngleDoubleUpCopy;
