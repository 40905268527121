import * as React from "react";
const IconSsd = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M12 4a3 3 0 0 0-3 3v6.628h46V7a3 3 0 0 0-3-3zm43 11.628H9V57a3 3 0 0 0 3 3h3.775l1.11-8.634.002-.018a6 6 0 0 1 5.937-5.13h18.352a6 6 0 0 1 5.937 5.13v.007L48.319 60H52a3 3 0 0 0 3-3zM46.298 60l-1.164-8.362v-.003a4 4 0 0 0-3.958-3.416h-1.009V60zm-8.13 0V48.219H33V60zM31 60V48.219h-5.167V60zm-7.167 0V48.219h-1.01a4 4 0 0 0-3.956 3.411L17.791 60zM7 7a5 5 0 0 1 5-5h40a5 5 0 0 1 5 5v50a5 5 0 0 1-5 5H12a5 5 0 0 1-5-5zm10.669 20.51a3.49 3.49 0 0 1 3.49-3.49h3.32a1 1 0 1 1 0 2h-3.32a1.49 1.49 0 1 0 0 2.98h.913a3.407 3.407 0 0 1 0 6.813h-3.403a1 1 0 1 1 0-2h3.403a1.407 1.407 0 0 0 0-2.814h-.913a3.49 3.49 0 0 1-3.49-3.49m9.81 0a3.49 3.49 0 0 1 3.49-3.49h3.32a1 1 0 1 1 0 2h-3.32a1.49 1.49 0 1 0 0 2.98h.912a3.407 3.407 0 0 1 0 6.813h-3.403a1 1 0 1 1 0-2h3.403a1.407 1.407 0 0 0 0-2.814h-.913a3.49 3.49 0 0 1-3.49-3.49m9.81-2.49a1 1 0 0 1 1-1h4.042a4 4 0 0 1 4 4v3.96a4 4 0 0 1-4 4h-4.043a1 1 0 0 1-1-1zm2 1v7.96h3.042a2 2 0 0 0 2-2v-3.96a2 2 0 0 0-2-2z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconSsd;
