import * as React from "react";
const IconCreditCardsCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.8 18.4v-6.6c0-1.4-.6-2.8-1.7-3.6-1.1-.9-2.5-1.2-4-.9L7.9 18H7c-2.8 0-5 2.2-5 5v28.9c0 2.8 2.2 5 5 5h50c2.8 0 5-2.2 5-5V23c0-2.1-1.3-3.9-3.2-4.6m-5.2-9.3c.8-.2 1.6 0 2.3.5s1 1.3 1 2.1V18H16.5zM7 20h50c1.7 0 3 1.3 3 3v5.5H4V23c0-1.6 1.3-3 3-3m53 10.6v7.3H4v-7.3zm-3 24.3H7c-1.7 0-3-1.3-3-3v-12h56V52c0 1.6-1.3 2.9-3 2.9" />
  </svg>
);
export default IconCreditCardsCopy;
