import * as React from "react";
const IconEmptyFileCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M47.5 2h-31c-3.6 0-6.6 3-6.6 6.6v31.6c0 1.1.5 2.3 1.3 3l18.7 17.6c.8.8 1.8 1.2 2.9 1.2h14.6c3.6 0 6.6-3 6.6-6.6V8.6C54.1 5 51.1 2 47.5 2M13.3 42.5h15.4c1.3 0 2.3 1 2.3 2.2v14.5zm38.8 12.9c0 2.5-2.1 4.6-4.6 4.6H33V44.6c0-2.3-1.9-4.2-4.3-4.2H11.9V8.6C11.9 6.1 14 4 16.5 4h31c2.5 0 4.6 2.1 4.6 4.6z" />
  </svg>
);
export default IconEmptyFileCopy;
