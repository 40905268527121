import * as React from "react";
const IconIdeaShare = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.7 47.5c-1.1-3.5-4.5-5.4-7.8-4.5-4.1 1.2-9.9 2.9-13.4 3.9 1.1-1.8 1.1-3.2.7-4.2-.7-1.8-2.8-2.6-4.5-2.6h-9.6c-1.1 0-2.1-.5-3.2-1.4-.9-.8-2-1.2-3.1-1.2h-7.7C7.3 37.6 5 40 5 42.9v12.3c0 3 2.3 5.5 5.2 5.5h9.3q1.8 0 3.3-1.2l.6.3c2.6 1.4 5.5 2.2 8.4 2.2 1.8 0 3.5-.3 5.2-.8l17.4-5.3c4.4-1.7 5.1-5.4 4.3-8.4M10.2 58.7c-1.8 0-3.2-1.6-3.2-3.5V42.9c0-1.9 1.4-3.4 3.1-3.4h7.7c.7 0 1.3.2 1.8.7.4.4.8.7 1.3 1v17.2c-.4.2-.9.3-1.3.3zM53.8 54l-17.3 5.3c-1.4.5-3 .7-4.6.7-2.6 0-5.2-.7-7.5-1.9l-1.3-.7-.3-.1V42c.4.1.9.2 1.3.2h9.6c1 0 2.3.5 2.6 1.3.4 1-.4 2.7-2.3 4.5l-.6.5.4.7c.4.8.4.8 9.4-1.9l8.2-2.4c2.2-.6 4.5.7 5.3 3.1.4 1.4.9 4.6-2.9 6M37.3 26c.8.5 1.3 1.5 1.3 2.5v2.3c0 2.7 2.2 5 5 5h1.2c2.7 0 5-2.2 5-5v-2.4c0-1 .5-1.9 1.3-2.5 3.7-2.3 6-6.3 6.1-10.7.1-3.4-1.2-6.7-3.6-9.3-2.4-2.5-5.6-4-9-4.1-3.5-.1-6.9 1.2-9.4 3.7s-3.9 5.8-3.9 9.3c-.1 4.7 2.2 8.8 6 11.2m10.5 4.8c0 1.6-1.3 3-3 3h-1.2c-1.6 0-3-1.3-3-3v-2.3c0-.3 0-.6-.1-.9h7.3c0 .3-.1.5-.1.8v2.4zM36.5 7.1c2.1-2 4.8-3.1 7.7-3.1h.3c2.9.1 5.6 1.3 7.6 3.5 2.1 2.2 3.1 4.9 3.1 7.8-.1 3.7-2 7.1-5.2 9-.5.3-1 .8-1.4 1.3h-8.9c-.4-.5-.8-1-1.4-1.3-3.2-2-5.2-5.5-5.2-9.3.1-3 1.3-5.8 3.4-7.9" />
  </svg>
);
export default IconIdeaShare;
