import * as React from "react";
const IconInvention = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M43.6 21.9c-3.4-3.2-7.8-4.8-12.6-4.5-8.7.5-15.6 7.7-15.7 16.3-.1 5.5 2.5 10.5 6.8 13.6v7.2c0 3.7 3 6.6 6.7 6.6H35c3.7 0 6.7-3 6.7-6.6v-7.1c4.4-3.1 7-8.1 7-13.5.1-4.5-1.8-8.8-5.1-12m-8.5 34.8h-6.2c-1.2 0-2.2-1-2.2-2.1v-6.1h10.7v6.1c-.1 1.1-1.1 2.1-2.3 2.1m3.8-12.8h-4.7v-2.7c0-1.2-1-2.3-2.3-2.3-1.2 0-2.3 1-2.3 2.3v2.7H25c-3.4-2.3-5.4-6-5.3-10.2.1-6.3 5.1-11.5 11.5-11.9h.7c3.3 0 6.2 1.1 8.5 3.3 2.4 2.3 3.8 5.5 3.8 8.8.1 4.1-1.9 7.8-5.3 10M32 13.1c1.2 0 2.3-1 2.3-2.3V5.1c0-1.2-1-2.3-2.3-2.3-1.2 0-2.3 1-2.3 2.3v5.8c.1 1.2 1.1 2.2 2.3 2.2M58 16.4c.9-.9.9-2.3 0-3.2s-2.3-.9-3.2 0l-4 3.9c-.9.9-.9 2.3 0 3.2.4.4 1 .7 1.6.7s1.1-.2 1.6-.6zM9.2 13.2c-.9-.9-2.3-.9-3.2 0s-.9 2.3 0 3.2l3.9 3.9c.4.4 1 .7 1.6.7s1.2-.2 1.6-.7c.9-.9.9-2.3 0-3.2zM8.8 35.9H3c-1.2 0-2.3 1-2.3 2.3s1 2.3 2.3 2.3h5.8c1.2 0 2.3-1 2.3-2.3s-1.1-2.3-2.3-2.3M61 35.9h-5.8c-1.2 0-2.3 1-2.3 2.3s1 2.3 2.3 2.3H61c1.2 0 2.3-1 2.3-2.3s-1.1-2.3-2.3-2.3" />
  </svg>
);
export default IconInvention;
