import * as React from "react";
const IconServiceCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.6 41.3h-2.7C55.8 28 45.6 17.6 33 17v-5.1h2.5c.6 0 1-.4 1-1s-.4-1-1-1h-6.9c-.6 0-1 .4-1 1s.4 1 1 1H31V17c-12.6.6-22.8 11-23.9 24.3H4.4c-1.3 0-2.4 1.1-2.4 2.4v5.2c0 2.9 2.3 5.2 5.2 5.2h49.6c2.9 0 5.2-2.3 5.2-5.2v-5.2c0-1.3-1.1-2.4-2.4-2.4M32 19c11.9 0 21.8 9.7 22.9 22.3H9.1C10.2 28.7 20.1 19 32 19m28 29.9c0 1.8-1.4 3.2-3.2 3.2H7.2c-1.8 0-3.2-1.4-3.2-3.2v-5.2c0-.2.2-.4.4-.4h55.2c.2 0 .4.2.4.4z" />
  </svg>
);
export default IconServiceCopy;
