import * as React from "react";
const IconNa = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 85.333 512 341.333"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 85.337h512v341.326H0z" />
    <path  d="M0 426.663V85.337h512" />
    <path  d="M512 152.222V85.337H411.67L0 359.778v66.885h100.33z" />
    <path
      
      d="M512 85.337v40.125L60.193 426.663H0v-40.125L451.807 85.337z"
    />
    <path
      
      d="m187.737 189.212-22.741 10.696 12.11 22.024-24.693-4.724-3.129 24.945-17.199-18.347-17.2 18.347-3.129-24.945-24.693 4.723 12.109-22.023-22.739-10.696 22.74-10.697-12.11-22.022 24.693 4.722 3.13-24.944 17.199 18.347 17.2-18.347 3.128 24.944 24.693-4.722-12.108 22.024z"
    />
  </svg>
);
export default IconNa;
