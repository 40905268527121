import * as React from "react";
const IconYacht2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.5 46.9c-.2-.3-.5-.4-.8-.4H35.1v-8.1h13.4c.4 0 .7-.2.9-.5s.2-.7 0-1L35.1 10.4V3c0-.5-.3-.9-.8-1-.4-.1-.9.1-1.1.5L17.7 31.2c-.8 1.5-.8 3.3.1 4.8s2.4 2.4 4.2 2.4h11.1v8.1H7.3c-.3 0-.6.2-.8.4-.2.3-.2.6-.1.9l4.2 11.1c.7 1.9 2.5 3.1 4.5 3.1H49c2 0 3.8-1.3 4.5-3.1l4.2-11.1c.1-.3 0-.6-.2-.9M46.8 36.4H35.1V14.6zM19.5 35c-.5-.9-.5-1.9-.1-2.8L33.1 7v29.4H22c-1.1 0-2-.5-2.5-1.4m32.1 23.2c-.4 1.1-1.5 1.8-2.7 1.8H15.1c-1.2 0-2.2-.7-2.7-1.8l-3.7-9.7h46.6z" />
  </svg>
);
export default IconYacht2;
