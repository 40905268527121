import * as React from "react";
const IconDeliveryTruck1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.1 19.8H41.3v-.9c0-3-2.4-5.4-5.4-5.4H7.4c-3 0-5.4 2.4-5.4 5.4V41c0 3 2.4 5.4 5.4 5.4h7.8c.7 2.4 2.9 4.1 5.5 4.1s4.8-1.7 5.5-4.1h18.9c.7 2.4 2.9 4.1 5.5 4.1s4.8-1.7 5.5-4.1H61c.6 0 1-.4 1-1V28.7c0-4.9-4-8.9-8.9-8.9M4 41V18.9c0-1.9 1.5-3.4 3.4-3.4H36c1.9 0 3.4 1.5 3.4 3.4v25.5h-13c-.2-3-2.7-5.4-5.7-5.4s-5.5 2.4-5.7 5.4H7.4C5.5 44.4 4 42.9 4 41m16.7 7.5c-2.1 0-3.7-1.7-3.7-3.7s1.7-3.7 3.7-3.7 3.7 1.7 3.7 3.7-1.7 3.7-3.7 3.7m30 0c-2.1 0-3.7-1.7-3.7-3.7s1.7-3.7 3.7-3.7 3.7 1.7 3.7 3.7-1.7 3.7-3.7 3.7m9.3-4.1h-3.6c-.2-3-2.7-5.4-5.7-5.4s-5.5 2.4-5.7 5.4h-3.6V21.8h11.8c3.8 0 6.9 3.1 6.9 6.9v15.7z" />
    <path d="M11.2 23.1h9.5c.6 0 1-.4 1-1s-.4-1-1-1h-9.5c-.6 0-1 .4-1 1s.4 1 1 1M17.8 30.4c.6 0 1-.4 1-1s-.4-1-1-1h-6.6c-.6 0-1 .4-1 1s.4 1 1 1z" />
  </svg>
);
export default IconDeliveryTruck1;
