import * as React from "react";
const IconCorn = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 65 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M41.4 61c-5.7 0-10.9-2.2-14.8-6.2L11.7 39.6c-.3-.3-.4-.7-.2-1 .1-.4.4-.6.8-.7 1.3-.2 2.6-.4 4-.5l-8.6-8.8C2 23 2.2 13.5 8 7.6c2.9-3 6.8-4.6 10.8-4.6 3.8 0 7.3 1.5 10 4.2l16 16.4c.1-1 .1-1.9.3-2.9 0-.4.3-.7.7-.8s.8 0 1 .2l9.7 9.8c3 3.1 4.8 7.3 5 11.8.2 4.1-1.1 8.3-3.4 11.8l3 3.1c.4.4.4 1 0 1.4s-1 .4-1.4 0l-2.9-3c-4.1 3.8-9.4 6-14.8 6.1-.3-.1-.4-.1-.6-.1M28 53.4c3.6 3.7 8.5 5.7 13.8 5.6 4.9-.1 9.7-2 13.5-5.5-.1-.1-.2-.2-.3-.4-5.3-5.2-11.9-9.2-19-11.5-6.8-2.2-14.2-2.9-21.5-2zm28.4-1.6.2.2c2-3 3-6.7 2.9-10.2-.2-4-1.7-7.7-4.4-10.4L46.9 23c-.4 5.2.2 10.4 1.8 15.3 1.6 5 4.2 9.6 7.7 13.5M20.6 37.3c5.4 0 10.8.8 15.9 2.5 4.8 1.6 9.4 3.9 13.6 6.8-1.4-2.4-2.5-5-3.4-7.7-1.3-4-2-8.3-2-12.6L27.4 8.6C25.1 6.3 22.1 5 18.8 5 15.3 5 12 6.4 9.4 9c-5 5.2-5.2 13.4-.4 18.3l9.8 10zm2.9-6.1c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l2.8-2.9c.4-.4 1-.4 1.4 0s.4 1 0 1.4l-2.8 2.9c-.2.2-.5.3-.7.3m-10-7.2c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l2.8-2.9c.4-.4 1-.4 1.4 0s.4 1 0 1.4l-2.8 2.9c-.2.2-.4.3-.7.3m11.2-2.8c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l2.8-2.9c.4-.4 1-.4 1.4 0s.4 1 0 1.4l-2.8 2.9c-.2.2-.5.3-.7.3" />
  </svg>
);
export default IconCorn;
