import * as React from "react";
const IconAd = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 513 342"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 0h513v342H0z" />
    <path  d="M0 0h171v342H0z" />
    <path  d="M342 0h171v342H342zM198.9 113.6H256v64.8h-57.1z" />
    <path
      
      strokeWidth={7}
      d="M267.6 113.6v64.7m16.6-64.7v64.7m16.6-64.7v64.7"
    />
    <path
      
      strokeWidth={5}
      d="M247.4 178.4v64.7m-13.3-64.7v64.7m-13.3-64.6v53.9m-13.7-53.9v47.1"
    />
    <path  d="m199.9 146 27.6-32.4 28.5 32.3-28.1 32.4z" />
    <path
      
      d="M182.2 95.9v92.2c0 34.3 27.3 54.2 48.6 64.5-.8 1.4 25.1 8.3 25.1 8.3s25.9-6.9 25.1-8.3c21.4-10.3 48.6-30.1 48.6-64.5V95.9zm129.9 92.2c0 16.9-10 29.4-32.8 43.9-8.6 5.5-17.5 9-23.3 11-5.8-2-14.7-5.5-23.3-11-22.8-14.5-32.8-28-32.8-43.9v-74.5h112.2z"
    />
    <path
      
      d="M264.4 188.9h29.5v8.6h-29.5zm0 17.6h29.5v8.6h-29.5z"
    />
  </svg>
);
export default IconAd;
