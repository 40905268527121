import * as React from "react";
const IconYoungPeople = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M29.5 12a4 4 0 1 1 8 0 4 4 0 0 1-8 0m4-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4M10 17a1 1 0 0 0-.924 1.384A5 5 0 0 0 6 23v2a5 5 0 0 0 3 4.584V36a3 3 0 0 0 5.5 1.66A3 3 0 0 0 20 36v-6.416A5 5 0 0 0 23 25v-2c0-2.08-1.27-3.863-3.076-4.616A1 1 0 0 0 19 17zm1 11.874v-1.96a3.5 3.5 0 0 0 1.236-.467c.645-.405 1.048-.995 1.297-1.659.243-.644.352-1.387.407-2.164.056-.783.06-1.672.06-2.624h1c0 .952.004 1.84.06 2.624.055.777.164 1.52.406 2.164.25.664.653 1.254 1.298 1.66.37.232.785.382 1.236.466V36a1 1 0 0 1-1.997.072L15.498 29a1 1 0 0 0-1.995 0l-.505 7.072A1 1 0 0 1 11 36zM8 25c0 .888.386 1.687 1 2.236V22h2v2.843q.098-.042.17-.088c.193-.121.357-.314.492-.67.141-.378.233-.896.283-1.603.05-.7.055-1.52.055-2.482h-1a3 3 0 0 0-3 3zm13 0c0 .888-.386 1.687-1 2.236V22h-2v2.843a1 1 0 0 1-.17-.088c-.193-.121-.357-.314-.492-.67-.141-.378-.233-.896-.283-1.603-.05-.7-.055-1.52-.055-2.482h1a3 3 0 0 1 3 3zM28.076 18.384A5 5 0 0 0 25 23v2c0 1.772.922 3.328 2.31 4.215l-1.224 1.815c-.833 1.234-.073 2.934 1.437 3.088 1.597.162 4.096.382 5.977.382 1.88 0 4.38-.22 5.977-.382 1.51-.154 2.27-1.854 1.437-3.088l-1.224-1.815A5 5 0 0 0 42 25v-2c0-2.08-1.27-3.863-3.076-4.616A1 1 0 0 0 38 17h-9a1 1 0 0 0-.924 1.384M27 23a3 3 0 0 1 3-3h1c0 .962-.005 1.781-.055 2.482-.05.707-.142 1.225-.283 1.602-.135.357-.299.55-.491.67q-.073.047-.171.089V23h-2v4.236c-.614-.55-1-1.348-1-2.236zm3.365 5.265L30 28.149v-1.235a3.5 3.5 0 0 0 1.236-.467c.645-.405 1.048-.995 1.297-1.659.243-.644.352-1.387.407-2.164.056-.783.06-1.672.06-2.624h1c0 .952.004 1.84.06 2.624.055.777.164 1.52.407 2.164.25.664.652 1.254 1.297 1.66.37.232.785.382 1.236.466v1.235l-.365.116 2.61 3.866c-1.595.162-3.986.369-5.745.369s-4.15-.207-5.744-.369zM39 27.236c.614-.55 1-1.348 1-2.236v-2a3 3 0 0 0-3-3h-1c0 .962.005 1.781.055 2.482.05.707.142 1.225.283 1.602.135.357.299.55.491.67q.073.047.171.089V23h2z"
      clipRule="evenodd"
    />
    <path
      
      d="M31.77 36.144a37 37 0 0 1-3.27-.327V37a1.5 1.5 0 0 0 2.952.375zM35.228 36.137l.32 1.238A1.5 1.5 0 0 0 38.5 37v-1.197a39 39 0 0 1-3.272.334"
    />
    <path
      
      fillRule="evenodd"
      d="M14.5 8a4 4 0 1 0 0 8 4 4 0 0 0 0-8m-2 4a2 2 0 1 1 4 0 2 2 0 0 1-4 0"
      clipRule="evenodd"
    />
  </svg>
);
export default IconYoungPeople;
