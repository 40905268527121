import * as React from "react";
const IconTimerDesktop = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M33 28.4c7.3 0 13.2-5.9 13.2-13.2S40.2 2 33 2 19.8 7.9 19.8 15.2 25.7 28.4 33 28.4M33 4c6.2 0 11.2 5 11.2 11.2s-5 11.2-11.2 11.2-11.2-5-11.2-11.2S26.8 4 33 4" />
    <path d="M34.1 19.7c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4L33.3 16v-3.9c0-.6-.4-1-1-1s-1 .4-1 1v4.3c0 .3.1.5.3.7z" />
    <path d="M55.7 10.8h-2.4c-.6 0-1 .4-1 1s.4 1 1 1h2.4c1.8 0 3.3 1.5 3.3 3.3v30.4c0 1.8-1.5 3.3-3.3 3.3H8.3c-1.8 0-3.3-1.5-3.3-3.3V16.1c0-1.8 1.5-3.3 3.3-3.3h3.9c.6 0 1-.4 1-1s-.4-1-1-1H8.3c-2.9 0-5.3 2.4-5.3 5.3v30.4c0 2.9 2.4 5.3 5.3 5.3h18c0 .7-.5 5.5-3.6 8.4-.3.3-.4.7-.2 1.1s.5.7.9.7h17.3c.4 0 .8-.3.9-.6.2-.4.1-.8-.2-1.1-3.1-2.9-3.6-7.7-3.6-8.4h18c2.9 0 5.3-2.4 5.3-5.3V16.1c-.1-2.9-2.5-5.3-5.4-5.3M38.4 60H25.6c2.5-3.6 2.8-7.9 2.8-8.2h7.3c0 .3.2 4.6 2.7 8.2" />
  </svg>
);
export default IconTimerDesktop;
