import * as React from "react";
const IconChevronLeft = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M43.3 57.3c-.6 0-1.2-.2-1.6-.7l-22.6-23c-.9-.9-.9-2.3 0-3.2l22.6-23c.9-.9 2.3-.9 3.2 0s.9 2.3 0 3.2L23.9 32 45 53.4c.9.9.9 2.3 0 3.2-.6.4-1.1.7-1.7.7" />
  </svg>
);
export default IconChevronLeft;
