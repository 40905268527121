import * as React from "react";
const IconChange = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 31H32.3V7.8c0-3.2-2.6-5.8-5.8-5.8H7.8C4.6 2 2 4.6 2 7.8V37c0 3.2 2.6 5.8 5.8 5.8h12.4V57c0 2.8 2.2 5 5 5H57c2.8 0 5-2.2 5-5V36c0-2.8-2.2-5-5-5m3 5v10.5L53 41c-.3-.3-.8-.3-1.1-.1l-9.9 6-9.2-6c-.3-.2-.7-.2-1 0l-9.4 5.5V36c0-1.7 1.3-3 3-3H57c1.7 0 3 1.3 3 3M7.8 40.8C5.7 40.8 4 39.1 4 37V7.8C4 5.7 5.7 4 7.8 4h18.7c2.1 0 3.8 1.7 3.8 3.8V31h-5.1c-2.8 0-5 2.2-5 5v4.8zM57 60H25.3c-1.7 0-3-1.3-3-3v-8.3l9.9-5.8 9.2 6c.3.2.7.2 1.1 0l9.8-6 7.6 5.9h.1v8c0 1.9-1.3 3.2-3 3.2M39.8 7.3c8.6 0 15.7 7 15.7 15.7v.1l-3.8-3.5c-.4-.4-1-.4-1.4.1-.4.4-.4 1 .1 1.4l5.1 4.7c.2.2.4.3.7.3s.5-.1.7-.3l5-4.6c.4-.4.4-1 .1-1.4-.4-.4-1-.4-1.4-.1l-2.9 2.7c-.3-9.4-8.1-17-17.6-17-.6 0-1 .4-1 1s.1.9.7.9" />
  </svg>
);
export default IconChange;
