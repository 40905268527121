import * as React from "react";
const IconHotMeal = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      d="m24 10.5-.895-.447v.003l-.003.004-.006.013-.021.043-.07.15a10.96 10.96 0 0 0-.736 2.287c-.264 1.322-.351 3.279 1.024 4.654.625.625.712 1.668.476 2.847a9 9 0 0 1-.65 1.971l-.012.025-.002.003.895.447c.894.447.895.446.895.446v-.002l.003-.004.006-.013.021-.043.07-.15a10.96 10.96 0 0 0 .736-2.287c.264-1.322.351-3.279-1.024-4.654-.625-.625-.712-1.668-.476-2.847a9 9 0 0 1 .65-1.971l.012-.025.002-.003z"
    />
    <path
      
      fillRule="evenodd"
      d="M4 29a1 1 0 0 1 1-1h38a1 1 0 1 1 0 2h-3a8 8 0 0 1-8 8H16a8 8 0 0 1-8-8H5a1 1 0 0 1-1-1m6 1h28a6 6 0 0 1-6 6H16a6 6 0 0 1-6-6"
      clipRule="evenodd"
    />
    <path
      
      d="m15.17 12.441.83.56c.829.559.83.558.83.558l-.013.02-.054.085a6 6 0 0 0-.192.344 5.8 5.8 0 0 0-.45 1.128c-.24.892-.154 1.596.474 2.06 1.622 1.202 1.536 2.999 1.214 4.19a7.8 7.8 0 0 1-.605 1.528 8 8 0 0 1-.338.588l-.02.032-.005.007-.008.012-.002.004-.001.001s-.001.002-.83-.558-.83-.559-.83-.559l.013-.019.054-.086c.047-.079.115-.196.192-.344.155-.298.333-.698.45-1.128.24-.892.154-1.595-.474-2.06-1.622-1.202-1.536-2.998-1.214-4.19.165-.612.409-1.15.605-1.528a8 8 0 0 1 .363-.626l.007-.012.003-.005zM33 13c-.829-.56-.83-.559-.83-.559v.002l-.004.005-.008.012a7 7 0 0 0-.362.626c-.196.378-.44.916-.605 1.528-.322 1.191-.408 2.988 1.214 4.19.628.465.714 1.168.473 2.06-.116.43-.294.83-.45 1.128a6 6 0 0 1-.245.43l-.012.02L33 23c.829.56.83.558.83.558v-.001l.004-.004.008-.012.015-.025.01-.014.08-.13c.067-.11.158-.267.257-.458.196-.378.44-.916.605-1.528.322-1.191.408-2.988-1.214-4.19-.628-.465-.714-1.168-.473-2.06.116-.43.294-.83.45-1.128a6 6 0 0 1 .245-.43l.012-.019z"
    />
  </svg>
);
export default IconHotMeal;
