import * as React from "react";
const IconCoins = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M30.6 33.4h-4.8c-1.8 0-3.3-1.5-3.3-3.3s1.5-3.3 3.3-3.3h8.5c.6 0 1-.4 1-1s-.4-1-1-1h-3v-2.6c0-.6-.4-1-1-1s-1 .4-1 1v2.6h-3.6c-2.9 0-5.3 2.4-5.3 5.3s2.4 5.3 5.3 5.3h4.8c1.8 0 3.3 1.5 3.3 3.3S32.3 42 30.5 42h-9c-.6 0-1 .4-1 1s.4 1 1 1h7.8v2.9c0 .6.4 1 1 1s1-.4 1-1v-3c2.6-.4 4.5-2.6 4.5-5.2.2-2.9-2.2-5.3-5.2-5.3" />
    <path d="M35.8 4.5c-6.9 0-13.5 2.6-18.4 7.4-.2.2-.3.5-.3.8C8.7 16.9 3 25.2 3 34.8c0 13.6 11.5 24.7 25.7 24.7 6.1 0 11.6-2 16-5.4.2.2.5.4.8.4.1 0 .3 0 .4-.1C55.7 50.4 62 41 62 30.5c0-14.4-11.8-26-26.2-26M5 34.8c0-12.5 10.6-22.7 23.7-22.7s23.7 10.2 23.7 22.7-10.6 22.7-23.7 22.7S5 47.3 5 34.8m43 16.3c4-4.3 6.4-10 6.4-16.3 0-13.6-11.5-24.7-25.7-24.7-2.5 0-5 .4-7.3 1 4.1-3 9.1-4.7 14.4-4.7 13.3.1 24.2 10.8 24.2 24 0 8.6-4.6 16.4-12 20.7" />
  </svg>
);
export default IconCoins;
