import * as React from "react";
const IconCool5 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
    <path d="M49.5 25.9v-7.8c0-.9-.6-1.5-1.3-1.5H15.8c-.7 0-1.3.7-1.3 1.5v7.8c0 1.7 1.2 3.1 2.6 3.1h9.5c1.3 0 2.8-1.5 2.8-3.3v-4.2h5v4.2c0 1.8 1.1 3.3 2.4 3.3h10c1.5 0 2.7-1.4 2.7-3.1M34.2 40.8H22.9c-3.2 0-5.8 2.6-5.8 5.8 0 .6.4 1 1 1s1-.4 1-1c0-2.1 1.7-3.8 3.8-3.8h11.3c.6 0 1-.4 1-1s-.4-1-1-1M45.2 32.2c-.8.8-4.5 5.1-4.5 7.7 0 2.9 2.4 5.3 5.3 5.3s5.3-2.4 5.3-5.3c0-2.6-3.8-6.9-4.5-7.7-.5-.4-1.3-.4-1.6 0m.7 11c-1.8 0-3.3-1.5-3.3-3.3 0-1.2 1.7-3.7 3.3-5.5 1.5 1.8 3.3 4.3 3.3 5.5 0 1.8-1.5 3.3-3.3 3.3" />
  </svg>
);
export default IconCool5;
