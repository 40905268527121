import * as React from "react";
const IconKiss = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M40.1 42.5c0-2-1.9-3.6-4.2-3.6-.6 0-1 .4-1 1s.4 1 1 1c1.2 0 2.2.7 2.2 1.6s-1 1.6-2.2 1.6c-.6 0-1 .4-1 1s.4 1 1 1c1.2 0 2.2.7 2.2 1.6s-1 1.6-2.2 1.6c-.6 0-1 .4-1 1s.4 1 1 1c2.3 0 4.2-1.6 4.2-3.6 0-1-.5-2-1.3-2.6.7-.6 1.3-1.6 1.3-2.6" />
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
    <path d="M21.4 19.3c-1.9 0-3.5 1.6-3.5 3.5s1.6 3.5 3.5 3.5 3.5-1.6 3.5-3.5-1.5-3.5-3.5-3.5M42.6 19.3c-1.9 0-3.5 1.6-3.5 3.5s1.6 3.5 3.5 3.5 3.5-1.6 3.5-3.5-1.6-3.5-3.5-3.5" />
  </svg>
);
export default IconKiss;
