import * as React from "react";
const IconPassportAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M47.9 2H14.1c-1.7 0-3 1.3-3 3v54c0 1.7 1.3 3 3 3h33.7c2.8 0 5-2.2 5-5V7c.1-2.8-2.2-5-4.9-5m3 55c0 1.7-1.3 3-3 3H14.1c-.6 0-1-.4-1-1V5c0-.6.4-1 1-1h33.7c1.7 0 3 1.3 3 3v50z" />
    <path d="M39.9 39.2H24.1c-.6 0-1 .4-1 1s.4 1 1 1h15.8c.6 0 1-.4 1-1s-.4-1-1-1M37.3 47.8H26.7c-.6 0-1 .4-1 1s.4 1 1 1h10.5c.6 0 1-.4 1-1s-.4-1-.9-1M25.4 20c.1 0 .2 0 .3-.1l5.3-1.8V25l-3.9 2c-.5.2-.7.8-.4 1.3q.3.6.9.6c.1 0 .3 0 .4-.1l4-2 4 2c.1.1.3.1.4.1q.6 0 .9-.6c.2-.5 0-1.1-.4-1.3L33 25v-6.8l5.3 1.8c.1 0 .2.1.3.1.4 0 .8-.3.9-.7.2-.5-.1-1.1-.6-1.3l-5.9-2v-3.7c0-.6-.4-1-1-1s-1 .4-1 1v3.7l-5.9 2c-.5.2-.8.7-.6 1.3.1.4.5.6.9.6" />
  </svg>
);
export default IconPassportAlt;
