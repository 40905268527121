import * as React from "react";
const IconCoinsAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M49.4 20.8c7.3 0 12.6-2.9 12.6-7s-5.3-7-12.6-7-12.6 2.9-12.6 7 5.3 7 12.6 7m0-12c6 0 10.6 2.2 10.6 5s-4.7 5-10.6 5-10.6-2.2-10.6-5 4.6-5 10.6-5M61.1 23.2c-.5-.2-1.1.1-1.3.6-.4 1.2-1.7 2.2-3.7 3-1.9.7-4.3 1.2-6.8 1.2-5 0-9.3-1.6-10.4-3.9-.2-.5-.8-.7-1.3-.5s-.7.8-.5 1.3c1.4 3.1 6.2 5 12.2 5 2.8 0 5.4-.5 7.6-1.3 2.5-1 4.2-2.4 4.8-4.2.2-.5 0-1.1-.6-1.2M61.1 32.3c-.5-.2-1.1.1-1.3.6-.4 1.2-1.7 2.2-3.7 3-1.9.7-4.3 1.2-6.8 1.2-5 0-9.3-1.6-10.4-3.9-.2-.5-.8-.7-1.3-.5s-.7.8-.5 1.3c1.4 3.1 6.2 5 12.2 5 2.7 0 5.4-.5 7.6-1.3 2.5-1 4.2-2.4 4.8-4.2.2-.5 0-1-.6-1.2M61.1 41.4c-.5-.2-1.1.1-1.3.6-.4 1.2-1.7 2.2-3.7 3-1.9.7-4.3 1.2-6.8 1.2-5 0-9.3-1.6-10.4-3.9-.2-.5-.8-.7-1.3-.5s-.7.8-.5 1.3c1.4 3.1 6.2 5 12.2 5 2.8 0 5.4-.5 7.6-1.3 2.5-1 4.2-2.4 4.8-4.2.2-.4 0-1-.6-1.2M61.1 50.5c-.5-.2-1.1.1-1.3.6-.4 1.2-1.7 2.2-3.7 3-1.9.7-4.3 1.2-6.8 1.2-5 0-9.3-1.6-10.4-3.9-.2-.5-.8-.7-1.3-.5s-.7.8-.5 1.3c1.4 3.1 6.2 5 12.2 5 2.8 0 5.4-.5 7.6-1.3 2.5-1 4.2-2.4 4.8-4.2.2-.4 0-1-.6-1.2M14.6 25C7.3 25 2 27.9 2 32s5.3 7 12.6 7 12.6-2.9 12.6-7-5.3-7-12.6-7m0 12C8.7 37 4 34.8 4 32s4.7-5 10.6-5c6 0 10.6 2.2 10.6 5 .1 2.8-4.6 5-10.6 5M26.4 41.4c-.5-.2-1.1.1-1.3.6-.4 1.2-1.7 2.2-3.7 3-1.9.7-4.3 1.2-6.8 1.2-5 0-9.3-1.6-10.4-3.9-.2-.5-.8-.7-1.3-.5s-.7.8-.5 1.3c1.4 3.1 6.2 5 12.2 5 2.8 0 5.4-.5 7.6-1.3 2.5-1 4.2-2.4 4.8-4.2.2-.4-.1-1-.6-1.2M26.4 50.5c-.5-.2-1.1.1-1.3.6-.4 1.2-1.7 2.2-3.7 3-1.9.7-4.3 1.2-6.8 1.2-5 0-9.3-1.6-10.4-3.9-.2-.5-.8-.7-1.3-.5s-.7.8-.5 1.3c1.4 3.1 6.2 5 12.2 5 2.8 0 5.4-.5 7.6-1.3 2.5-1 4.2-2.4 4.8-4.2.2-.4-.1-1-.6-1.2" />
  </svg>
);
export default IconCoinsAlt;
