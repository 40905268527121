import * as React from "react";
const IconDisabled = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M20.2 40c-3.3 0-5.9 2.7-5.9 5.9 0 3.3 2.7 5.9 5.9 5.9s5.9-2.7 5.9-5.9-2.7-5.9-5.9-5.9m0 9.8c-2.2 0-3.9-1.8-3.9-3.9S18 42 20.2 42s3.9 1.8 3.9 3.9-1.8 3.9-3.9 3.9" />
    <path d="M56.9 50.8c-.9 0-1.7.3-2.5.7l-3.6-4.2c.8-1 1.2-2.3 1.2-3.6V24c0-4.1-3.3-7.4-7.4-7.4H20.8V7.9c0-2.7-2.2-4.8-4.8-4.8H3c-.6 0-1 .4-1 1s.4 1 1 1h12.9c1.6 0 2.8 1.3 2.8 2.8v23.6c-7.3.7-13.1 6.9-13.1 14.4 0 8 6.5 14.5 14.5 14.5 6.7 0 12.3-4.6 14-10.7H46c1.3 0 2.5-.4 3.5-1.1l3.5 4.1c-.7.9-1.1 1.9-1.1 3.1 0 2.8 2.3 5.1 5.1 5.1s5.1-2.3 5.1-5.1-2.4-5-5.2-5M44.7 18.6c3 0 5.4 2.4 5.4 5.4v1.3H20.8v-6.6h23.9zM20.2 58.4c-6.9 0-12.5-5.6-12.5-12.5s5.6-12.5 12.5-12.5S32.6 39 32.6 45.9s-5.5 12.5-12.4 12.5m14.3-10.7c.1-.6.1-1.2.1-1.8 0-7.8-6.2-14.1-13.9-14.4v-4.2H50v16.3c0 2.3-1.8 4.1-4.1 4.1zm22.4 11.2c-1.7 0-3.1-1.4-3.1-3.1 0-.9.4-1.8 1.1-2.3.5-.5 1.2-.7 2-.7 1.7 0 3.1 1.4 3.1 3.1s-1.4 3-3.1 3" />
  </svg>
);
export default IconDisabled;
