import * as React from "react";
const IconCarWedding = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M14.8 39.8h6.6c.6 0 1-.4 1-1s-.4-1-1-1h-6.6c-.6 0-1 .4-1 1s.5 1 1 1M42.2 39.8h6.6c.6 0 1-.4 1-1s-.4-1-1-1h-6.6c-.6 0-1 .4-1 1s.4 1 1 1" />
    <path d="M61 27.1h-3.9l-5.2-13.2c-.5-1.4-1.9-2.4-3.4-2.4h-8.8c.2-.6.4-1.3.4-2 0-1.5-.6-2.9-1.6-3.9-1.7-1.7-4.4-2.1-6.5-1-.8-.4-1.7-.6-2.6-.6-1.4 0-2.7.5-3.7 1.4-1.1 1-1.7 2.4-1.7 3.9 0 .8.1 1.5.4 2.2h-9.2c-1.5 0-2.8.9-3.4 2.3L6.5 27.1H3c-.6 0-1 .4-1 1s.4 1 1 1h2.7l-.5 1.2c0 .1-.1.2-.1.3v14.2c0 2.2 1.8 4 4 4h.6v5.9c0 2.9 2.4 5.2 5.2 5.2h1.9c2.9 0 5.2-2.4 5.2-5.2v-5.9h18.8v5.9c0 2.9 2.4 5.2 5.2 5.2h1.9c2.9 0 5.2-2.4 5.2-5.2v-5.9h1.2c2.2 0 4-1.8 4-4V30.7c0-.2-.1-.4-.2-.6l-.4-1H61c.6 0 1-.4 1-1s-.4-1-1-1M27 6.9q1.05-.9 2.4-.9c.7 0 1.4.2 2 .6.3.2.8.2 1.1 0 1.4-1 3.3-.8 4.5.4.7.7 1 1.6 1 2.5s-.4 1.8-1 2.5l-4.9 5h-.3L27 12.2c-.7-.7-1.2-1.8-1.1-2.8 0-1 .4-1.9 1.1-2.5m-13.4 7.7c.3-.6.9-1.1 1.5-1.1H25c.2 0 .3 0 .4-.1l.2.2 4.8 4.8c.4.4 1 .6 1.5.6s1.1-.2 1.5-.6l4.9-4.9h10.1c.7 0 1.3.4 1.5 1l6 15.1H7.6zm6.6 40.2c0 1.8-1.5 3.2-3.2 3.2h-2c-1.8 0-3.2-1.5-3.2-3.2v-5.9h8.4zm31.1 0c0 1.8-1.5 3.2-3.2 3.2h-1.9c-1.8 0-3.2-1.5-3.2-3.2v-5.9h8.4v5.9zm5.2-9.9c0 1.1-.9 2-2 2H9.1c-1.1 0-2-.9-2-2V31.7h49.4z" />
  </svg>
);
export default IconCarWedding;
