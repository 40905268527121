import * as React from "react";
const IconCocktailAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M48.7 19.2c.6-.7.7-1.7.3-2.6s-1.2-1.4-2.2-1.4H26.1L16.5 2.4c-.3-.4-1-.5-1.4-.2s-.5 1-.2 1.4l8.7 11.6h-6.3c-.9 0-1.8.5-2.2 1.4s-.3 1.8.3 2.6L31 37.7V60h-6.4c-.5 0-1 .4-1 1s.4 1 1 1h14.7c.6 0 1-.4 1-1s-.4-1-1-1H33V37.7zM17 17.4c0-.1.1-.2.4-.2h7.9l3.6 4.7c.2.3.5.4.8.4.2 0 .4-.1.6-.2.4-.3.5-1 .2-1.4l-2.6-3.5h19.2c.2 0 .3.1.4.2 0 .1.1.3-.1.4L32.1 35.7 17 17.8c-.1-.1-.1-.3 0-.4" />
  </svg>
);
export default IconCocktailAlt;
