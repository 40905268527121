import * as React from "react";
const IconArrowRightCircleCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M34.3 21.4c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4L41 31H20.7c-.6 0-1 .4-1 1s.4 1 1 1H41l-8.1 8.2c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3l9.8-9.9c.4-.4.4-1 0-1.4z" />
    <path d="M32 3C16 3 3 16 3 32s13 29 29 29 29-13 29-29S48 3 32 3m0 56C17.1 59 5 46.9 5 32S17.1 5 32 5s27 12.1 27 27-12.1 27-27 27" />
  </svg>
);
export default IconArrowRightCircleCopy;
