import * as React from "react";
const IconMobileAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M43.4 2H20.6c-4 0-7.3 3.3-7.3 7.3v45.5c0 4 3.3 7.3 7.3 7.3h22.7c4 0 7.3-3.3 7.3-7.3V9.3c0-4-3.2-7.3-7.2-7.3M26.2 4h12.2v3.3c0 1.2-1 2.1-2.1 2.1h-8c-1.2 0-2.1-1-2.1-2.1zm22.4 50.7c0 2.9-2.4 5.3-5.3 5.3H20.6c-2.9 0-5.3-2.4-5.3-5.3V9.3c0-2.9 2.4-5.3 5.3-5.3h3.6v3.3c0 2.3 1.9 4.1 4.1 4.1h8c2.3 0 4.1-1.9 4.1-4.1V4h2.9c2.9 0 5.3 2.4 5.3 5.3z" />
    <path d="M34.4 51.9h-4.8c-.6 0-1 .4-1 1s.4 1 1 1h4.8c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconMobileAlt;
