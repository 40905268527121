import * as React from "react";
const IconChefHatCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M47.2 13.2c-.2 0-.3-.2-.4-.4C44.7 6.3 38.8 2 32 2S19.3 6.3 17.2 12.8c-.1.2-.2.3-.4.4-7.6 1.5-13.1 8.2-13.1 15.9v.1c0 6 4.8 11 10.9 11.5 0 2.9.2 11.2.2 16.2 0 2.8 2.3 5.1 5.1 5.1H44c2.8 0 5.1-2.3 5.1-5l.4-16.2c6-.5 10.7-5.5 10.7-11.6.1-7.8-5.4-14.5-13-16M44.1 60H19.9c-1.7 0-3.1-1.4-3.1-3.1 0-1.6-.1-3.6-.1-5.6h30.6l-.1 5.7c0 1.6-1.4 3-3.1 3m5-21.2c-.9 0-1.5.7-1.5 1.6l-.2 8.9H16.7c-.1-3.9-.1-7.5-.1-8.9 0-.8-.7-1.5-1.5-1.6-5.2-.3-9.3-4.5-9.3-9.5v-.1c0-6.8 4.8-12.7 11.5-14 .9-.2 1.7-.8 1.9-1.7C20.9 7.8 26.1 4 32 4s11.1 3.8 12.9 9.4c.3.9 1 1.5 1.9 1.7 6.7 1.3 11.5 7.2 11.5 14 0 5.1-4 9.4-9.2 9.7" />
  </svg>
);
export default IconChefHatCopy;
