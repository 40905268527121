import * as React from "react";
const IconLightsMedical = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.1 31.7H7.9c-3.2 0-5.9 2.6-5.9 5.9v4.8c0 .6.4 1 1 1h14.7v16.1h-7.5c-.6 0-1 .4-1 1s.4 1 1 1h43.3c.6 0 1-.4 1-1s-.4-1-1-1H46V43.4h15c.6 0 1-.4 1-1v-4.8c0-3.2-2.6-5.9-5.9-5.9M44.3 59.5H19.7V43.4h24.6zM60 41.4H4v-3.8c0-2.1 1.7-3.9 3.9-3.9h48.3c2.1 0 3.9 1.7 3.9 3.9v3.8zM45.4 10.9l.1-.1c1.4-1.4 3.8-1.4 5.2 0L53 13c.7.7 1.1 1.6 1.1 2.6s-.4 1.9-1.1 2.6l-.1.1c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l.1-.1c1.1-1.1 1.7-2.5 1.7-4s-.6-2.9-1.7-4l-.3-.3 7.1-7.1c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-7.1 7.1-.5-.5c-2.2-2.2-5.8-2.2-8 0l-.2.1c-.4.4-.4 1 0 1.4s1 .4 1.4 0M9.9 11.3l-.3.3c-2.2 2.2-2.2 5.8 0 8l.1.1c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-.1-.1c-1.4-1.4-1.4-3.8 0-5.2l2.2-2.2c1.4-1.4 3.8-1.4 5.2 0l.1.1c.4.4 1 .4 1.4 0s.4-1 0-1.4l-.1-.1c-2.2-2.2-5.8-2.2-8 0l-.5.5-7.1-7.1c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4z" />
  </svg>
);
export default IconLightsMedical;
