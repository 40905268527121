import * as React from "react";
const IconHand = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path
      d="M57.3 45.8V15.2c0-3.2-2.8-5.7-6.2-5.7-.6 0-1.2.1-1.7.2V8.5c0-3.2-2.8-5.7-6.2-5.7-1.2 0-2.4.3-3.3.9-1.1-1.2-2.8-1.9-4.6-1.9-2.6 0-4.9 1.5-5.8 3.6-.7-.3-1.4-.4-2.2-.4-3.4 0-6.2 2.6-6.2 5.7v21.6l-1.3-1.6c-2.3-3-6.5-4-9.8-2.2-1.6.9-2.8 2.4-3.2 4.2s0 3.7 1.2 5.3l9 11.9c.5.6.9 1.3 1.4 1.9 1.4 2 2.9 4.2 5 5.9 4 3.3 9.4 4.5 14.5 4.5 2.5 0 4.8-.3 7-.7 12.5-2.7 12.5-12.1 12.4-15.7M43.9 57.1c-5.2 1.1-13 1.1-17.6-2.8-1.6-1.4-2.9-3.2-4.2-5.1l-1.5-2.1-9-11.9c-.4-.5-.5-1.1-.4-1.6q.15-.75.9-1.2c1.3-.7 3.2-.2 4.1 1l.1.1 5.4 6.4c.6.7 1.6 1 2.5.7s1.5-1.2 1.5-2.1V10.7c0-.6.8-1.2 1.7-1.2s1.7.6 1.7 1.2v17.6c0 1.2 1 2.3 2.3 2.3s2.2-1 2.2-2.3V7.4c0-.7.8-1.2 1.7-1.2s1.7.6 1.7 1.3v21.6c0 1.2 1 2.3 2.3 2.3s2.3-1 2.3-2.3V8.5c0-.6.8-1.2 1.7-1.2s1.7.6 1.7 1.2v22.6c0 1.2 1 2.3 2.3 2.3s2.3-1 2.3-2.3v-16c.1-.6.8-1.1 1.7-1.1s1.7.6 1.7 1.2v30.7c-.1 4.5-.8 9.4-9.1 11.2"
      className="prefix__st0"
    />
  </svg>
);
export default IconHand;
