import * as React from "react";
const IconIdeaLabel = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32.3 17c-3.1-.1-6.1 1.1-8.3 3.3s-3.5 5.1-3.5 8.3c0 4 2 7.7 5.4 9.8.7.4 1.1 1.2 1.1 2.1v2c0 2.5 2 4.5 4.5 4.5h1.1c2.5 0 4.5-2 4.5-4.5v-2.1q0-.6.3-1.2c.2-.3.5-.6.8-.8 3.3-2.1 5.3-5.6 5.4-9.5.1-3-1.1-6-3.2-8.2-2.2-2.3-5.1-3.6-8.1-3.7M35 42.5c0 1.4-1.1 2.5-2.5 2.5h-1.1c-1.4 0-2.5-1.1-2.5-2.5v-2c0-.2 0-.5-.1-.7h6.1v2.7zm2-5.8c-.5.3-.9.7-1.2 1.1h-7.7c-.3-.5-.7-.8-1.2-1.1-2.8-1.8-4.5-4.8-4.5-8.1 0-2.6 1-5 2.9-6.8C27.1 20 29.5 19 32 19h.2c2.5.1 4.9 1.1 6.6 3 1.8 1.9 2.7 4.3 2.7 6.8-.1 3.2-1.7 6.2-4.5 7.9" />
    <path d="m60.4 27.6-2-2.3c-.8-.9-1.2-2.2-1.1-3.5l.3-3c.3-3.3-1.9-6.3-5.1-7.1l-3-.7c-1.2-.3-2.3-1.1-3-2.1L45 6.3c-1.7-2.9-5.3-4-8.3-2.7l-2.8 1.2c-1.2.5-2.5.5-3.7 0l-2.8-1.2c-3-1.3-6.6-.2-8.3 2.7L17.5 9c-.6 1.1-1.7 1.9-3 2.1l-3 .7c-3.2.7-5.4 3.8-5.1 7.1l.3 3c.1 1.3-.3 2.5-1.1 3.5l-2 2.3a6.56 6.56 0 0 0 0 8.7l2 2.3c.8.9 1.2 2.2 1.1 3.5l-.3 3c-.3 3.3 1.9 6.3 5.1 7.1l3 .7c1.2.3 2.3 1.1 3 2.1l1.5 2.6c1.7 2.9 5.3 4 8.3 2.7l2.8-1.2c1.2-.5 2.5-.5 3.7 0l2.8 1.2c.8.4 1.7.5 2.6.5 2.3 0 4.5-1.2 5.7-3.2l1.5-2.6c.6-1.1 1.7-1.9 3-2.1l3-.7c3.2-.7 5.4-3.8 5.1-7.1l-.3-3c-.1-1.3.3-2.5 1.1-3.5l2-2.3c2.2-2.5 2.2-6.3.1-8.8M58.9 35l-2 2.3c-1.2 1.4-1.8 3.2-1.6 5l.3 3c.2 2.3-1.3 4.4-3.6 4.9l-3 .7c-1.8.4-3.3 1.5-4.2 3.1l-1.5 2.6c-1.2 2-3.7 2.8-5.8 1.9l-2.8-1.2c-.8-.4-1.7-.5-2.6-.5s-1.8.2-2.6.5l-2.8 1.2c-2.1.9-4.6.1-5.8-1.9L19.3 54c-.9-1.6-2.5-2.7-4.2-3.1l-3-.7c-2.3-.5-3.8-2.6-3.6-4.9l.3-3c.2-1.8-.4-3.6-1.6-5l-2-2.3c-1.5-1.7-1.5-4.4 0-6.1l2-2.3c1.2-1.4 1.8-3.2 1.6-5l-.3-3c-.2-2.3 1.3-4.4 3.6-4.9l3-.7c1.8-.4 3.3-1.5 4.2-3.1l1.5-2.6c1.2-2 3.7-2.8 5.8-1.9l2.8 1.2c1.7.7 3.6.7 5.2 0l2.8-1.2c2.1-.9 4.6-.1 5.8 1.9l1.5 2.6c.9 1.6 2.5 2.7 4.2 3.1l3 .7c2.3.5 3.8 2.6 3.6 4.9l-.3 3c-.2 1.8.4 3.6 1.6 5l2 2.3c1.6 1.8 1.6 4.4.1 6.1" />
  </svg>
);
export default IconIdeaLabel;
