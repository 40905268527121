import * as React from "react";
const IconBuildingAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 43.8h-8.8V6c0-2.2-1.8-4-4-4H18.8c-2.2 0-4 1.8-4 4v37.8H6c-2.2 0-4 1.8-4 4V58c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V47.8c0-2.2-1.8-4-4-4M4 58V47.8c0-1.1.9-2 2-2h8.8V60H6c-1.1 0-2-.9-2-2m12.8-13.2V6c0-1.1.9-2 2-2h26.4c1.1 0 2 .9 2 2v54H41v-3.9c0-2.2-1.8-4-4-4H27c-2.2 0-4 1.8-4 4V60h-6.2zM25 60v-3.9c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2V60zm35-2c0 1.1-.9 2-2 2h-8.8V45.8H58c1.1 0 2 .9 2 2z" />
    <path d="M27.8 23H24c-.6 0-1 .4-1 1s.4 1 1 1h3.8c.6 0 1-.4 1-1s-.5-1-1-1M27.8 30.3H24c-.6 0-1 .4-1 1s.4 1 1 1h3.8c.6 0 1-.4 1-1s-.5-1-1-1M40 23h-3.8c-.6 0-1 .4-1 1s.4 1 1 1H40c.6 0 1-.4 1-1s-.4-1-1-1M40 30.3h-3.8c-.6 0-1 .4-1 1s.4 1 1 1H40c.6 0 1-.4 1-1s-.4-1-1-1M23.7 15h16.5c.6 0 1-.4 1-1s-.4-1-1-1H23.7c-.6 0-1 .4-1 1s.5 1 1 1" />
  </svg>
);
export default IconBuildingAlt1;
