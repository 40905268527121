import * as React from "react";
const IconSaveCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m58.7 11.8-10-9.2-.1-.1c-.5-.3-1-.5-1.6-.5H11.2C7.6 2 4.6 4.9 4.6 8.6v46.8c0 3.6 2.9 6.6 6.6 6.6h41.6c3.6 0 6.6-2.9 6.6-6.6V13.5c0-.6-.3-1.2-.7-1.7M41.6 4v8.2q0 .3-.3.3H17.5q-.3 0-.3-.3V4zM19.8 60V38.6q0-.3.3-.3h23.8q.3 0 .3.3V60zm37.6-4.6c0 2.5-2.1 4.6-4.6 4.6h-6.6V38.6c0-1.3-1-2.3-2.3-2.3H20.1c-1.3 0-2.3 1-2.3 2.3V60h-6.6c-2.5 0-4.6-2.1-4.6-4.6V8.6C6.6 6.1 8.7 4 11.2 4h4v8.2c0 1.3 1 2.3 2.3 2.3h23.8c1.3 0 2.3-1 2.3-2.3V4H47c.1 0 .2 0 .3.1l9.9 9.1c.1.1.1.1.1.3v41.9z" />
    <path d="M37.3 44.2H26.7c-.6 0-1 .4-1 1s.4 1 1 1h10.6c.6 0 1-.4 1-1s-.5-1-1-1M37.3 52.1H26.7c-.6 0-1 .4-1 1s.4 1 1 1h10.6c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconSaveCopy;
