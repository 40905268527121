import * as React from "react";
const IconSearchAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.4 55.7 44.9 42.3c6.8-9.3 6.1-22.6-2.4-31-4.5-4.5-10.5-7-16.9-7s-12.4 2.5-16.9 7c-9.3 9.3-9.3 24.5 0 33.8 4.5 4.5 10.5 7 16.9 7 6.1 0 11.8-2.3 16.3-6.4l16.7 13.5c.4.3.9.5 1.4.5.7 0 1.3-.3 1.7-.8.8-1 .7-2.4-.3-3.2m-35.8-8.1c-5.2 0-10-2-13.7-5.7-7.6-7.6-7.6-19.9 0-27.4 3.7-3.7 8.5-5.7 13.7-5.7s10 2 13.7 5.7c7.6 7.6 7.6 19.9 0 27.4-3.6 3.7-8.5 5.7-13.7 5.7" />
  </svg>
);
export default IconSearchAlt;
