import * as React from "react";
const IconAlarmAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m57.2 51.7-2.1-2.2c-3-3.3-4.9-7.5-5.2-11.8V27.4c0-4.7-1.8-9.1-5.1-12.4-3.2-3.2-7.4-5-11.8-5.2V4c0-.6-.4-1-1-1s-1 .4-1 1v5.8c-4.4.2-8.6 2-11.7 5.1-3.3 3.3-5.2 7.7-5.2 12.4v10.3c-.4 4.4-2.2 8.6-5.2 11.8l-2.1 2.2c-.3.3-.3.7-.2 1.1s.5.6.9.6h16.2c.2 4.2 3.6 7.6 7.9 7.6s7.8-3.4 7.9-7.6h16.8c.4 0 .8-.2.9-.6s.2-.7 0-1M31.7 59c-3.2 0-5.7-2.5-5.9-5.6h11.8c-.1 3.1-2.7 5.6-5.9 5.6M9.8 51.4l.5-.5c3.3-3.6 5.4-8.2 5.8-13V27.4c0-4.2 1.6-8.1 4.6-11 2.9-2.9 6.8-4.6 11-4.6h.6c4.2 0 8.1 1.6 11 4.6 2.9 2.9 4.6 6.8 4.6 11v10.4c.4 4.8 2.4 9.4 5.8 13l.5.5H9.8z" />
  </svg>
);
export default IconAlarmAlt;
