import * as React from "react";
const IconBriefcase4 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62 28.8V17.9c0-2.2-1.8-4-4-4H43.9V6.7c0-2.2-1.8-4-4-4H24.1c-2.2 0-4 1.8-4 4v7.1H6c-2.2 0-4 1.8-4 4v39.4c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4zM22.1 6.7c0-1.1.9-2 2-2H40c1.1 0 2 .9 2 2v7.1H22.1zM6 15.9h52c1.1 0 2 .9 2 2v10.2l-10.5 4.3c-1.9.8-4 .8-6 0l-7.8-3.2c-2.4-1-5.1-1-7.5 0l-7.8 3.2c-1.9.8-4.1.8-6 0L4 28.1V17.9c0-1.1.9-2 2-2m52 43.4H6c-1.1 0-2-.9-2-2v-27l9.8 4c2.4 1 5.1 1 7.5 0l7.8-3.2c1.9-.8 4-.8 6 0l7.8 3.2c1.2.5 2.5.7 3.7.7 1.3 0 2.5-.2 3.7-.7l9.8-4v27c-.1 1.1-1 2-2.1 2" />
  </svg>
);
export default IconBriefcase4;
