import * as React from "react";
const IconTaxes = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M15.3 34.8h12.1c.6 0 1-.4 1-1s-.4-1-1-1H15.3c-.6 0-1 .4-1 1s.5 1 1 1M15.3 44.1h14.5c.6 0 1-.4 1-1s-.4-1-1-1H15.3c-.6 0-1 .4-1 1s.5 1 1 1" />
    <path d="M34.5 58H10.6c-1.2 0-2.2-1-2.2-2.2V6.2C8.5 5 9.4 4 10.6 4h16.2v14.8c0 2.2 1.9 4.1 4.2 4.1h16.2v11.9c0 .6.4 1 1 1s1-.4 1-1V23.1c0-1.1-.5-2.2-1.3-3l-18.1-17C29.1 2.4 28.1 2 27 2H10.6C8.3 2 6.5 3.9 6.5 6.2v49.7c0 2.3 1.9 4.2 4.2 4.2h23.8c.6 0 1-.4 1-1s-.5-1.1-1-1.1m11.3-37.1H31c-1.2 0-2.2-.9-2.2-2.1V4.9z" />
    <path d="M46.3 42.9c0-1.9-1.6-3.5-3.5-3.5s-3.5 1.6-3.5 3.5 1.6 3.5 3.5 3.5 3.5-1.6 3.5-3.5m-5 0c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5-.7 1.5-1.5 1.5-1.5-.7-1.5-1.5M53.2 54.1c-1.9 0-3.5 1.6-3.5 3.5s1.6 3.5 3.5 3.5 3.5-1.6 3.5-3.5-1.6-3.5-3.5-3.5m0 5c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5-.7 1.5-1.5 1.5M55.8 42.1c-.4-.4-1-.4-1.4 0L40.6 55.8c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l13.7-13.7c.5-.4.5-1 .1-1.4" />
  </svg>
);
export default IconTaxes;
