import * as React from "react";
const IconBriefcaseBitcoin = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M41.3 32.9v-.4c-.1-3.3-2.9-5.9-6.3-5.9v-1.7c0-.6-.4-1-1-1s-1 .4-1 1v1.7h-4.2v-1.7c0-.6-.4-1-1-1s-1 .4-1 1v1.7h-3.2c-.6 0-1 .4-1 1s.4 1 1 1h.7v17.8h-.7c-.6 0-1 .4-1 1s.4 1 1 1h3.2v1.8c0 .6.4 1 1 1s1-.4 1-1v-1.8H33v1.8c0 .6.4 1 1 1s1-.4 1-1v-1.8c3.4 0 6.2-2.7 6.3-5.9V42c-.1-1.9-1.1-3.5-2.6-4.5 1.6-1.1 2.6-2.7 2.6-4.6m-5.9 5.5c2.1 0 3.8 1.6 3.9 3.6v.3c-.1 2.2-2 4-4.3 4h-8.6v-7.9zm0-2h-9.1v-7.9H35c2.3 0 4.3 1.8 4.3 4v.3c0 2-1.8 3.6-3.9 3.6" />
    <path d="M58 16.8H41.1v-5.5c0-3-2.4-5.4-5.4-5.4h-7.3c-3 0-5.4 2.4-5.4 5.4v5.5H6c-2.2 0-4 1.8-4 4v33.3c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V20.8c0-2.2-1.8-4-4-4m-33.1-5.5c0-1.9 1.5-3.4 3.4-3.4h7.3c1.9 0 3.4 1.5 3.4 3.4v5.5H24.9zM60 54.1c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V20.8c0-1.1.9-2 2-2h52c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconBriefcaseBitcoin;
