import * as React from "react";
const IconTruckFeedback = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M21.9 31.4c2.8 0 4.6-1.5 4.9-4.1l1.3-8.2c.2-1.5-.3-3.1-1.5-4.1-.8-.7-1.8-1-2.8-1h-3.7v-2.7c0-1.9-.6-3.3-1.8-4.1-.7-.5-1.6-.8-2.7-.8-.8 0-1.9.2-2.1.2-.7.2-1.1.8-1.1 1.5v4.2c0 1.6-1.3 2.6-2.3 3.1-.1-.4-.5-.6-.9-.6H3c-.6 0-1 .4-1 1v14.9c0 .6.4 1 1 1h6.3c.4 0 .8-.2.9-.6q.75.3 1.5.3zm-7.4-19.1V8.5c.3-.1.8-.1 1.2-.1.6 0 1.1.1 1.5.4.6.5.9 1.3.9 2.5V15c0 .6.4 1 1 1h4.7q.75 0 1.5.6c.6.5.9 1.4.8 2.2L24.8 27c-.2 1.9-1.7 2.4-2.9 2.4H11.7c-.5 0-1-.2-1.4-.5V17.6c1.8-.7 4.2-2.4 4.2-5.3M8.3 29.7H4V16.8h4.3z" />
    <path d="m61.8 38.6-6.2-9c-.2-.3-.5-.4-.8-.4H44.4v-2.7c0-2.6-2.1-4.7-4.7-4.7h-7c-.6 0-1 .4-1 1s.4 1 1 1h7c1.5 0 2.7 1.2 2.7 2.7v25.9h-9.8c-.3-2.4-2.2-4.2-4.7-4.2s-4.4 1.9-4.7 4.2h-6.7c-1.5 0-2.7-1.2-2.7-2.7v-12c0-.6-.4-1-1-1s-1 .4-1 1v12c0 2.6 2.1 4.7 4.7 4.7h6.9c.6 1.9 2.4 3.2 4.5 3.2s3.8-1.4 4.5-3.2h14.7c.6 1.9 2.4 3.2 4.5 3.2s3.8-1.4 4.5-3.2H61c.6 0 1-.4 1-1V39.2c0-.2-.1-.4-.2-.6m-7.6-7.4 5.3 7.6H44.4v-7.6zM27.9 55.6c-1.5 0-2.7-1.2-2.7-2.7s1.2-2.7 2.7-2.7 2.7 1.2 2.7 2.7c.1 1.5-1.2 2.7-2.7 2.7m23.7 0c-1.5 0-2.7-1.2-2.7-2.7s1.2-2.7 2.7-2.7 2.7 1.2 2.7 2.7-1.2 2.7-2.7 2.7m4.7-3.3c-.3-2.3-2.3-4.2-4.7-4.2s-4.4 1.9-4.7 4.2h-2.5V40.8H60v11.5z" />
  </svg>
);
export default IconTruckFeedback;
