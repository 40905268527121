import * as React from "react";
const IconComb2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.8 60.3 34 36.5c1.5-2.1 2-5.5 1.3-9.3v-.1h5.1c.6 0 1-.4 1-1s-.4-1-1-1h-5.6c-.4-1.5-1-3.1-1.8-4.5l6-2.5c.5-.2.7-.8.5-1.3s-.8-.7-1.3-.5L32 18.8c-.8-1.2-1.6-2.3-2.6-3.4l5-5c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-5 5c-1.1-1-2.3-1.9-3.5-2.7l2.6-6.2c.2-.5 0-1.1-.5-1.3s-1.1 0-1.3.5l-2.5 6c-1.5-.8-2.9-1.3-4.4-1.8V3c0-.6-.4-1-1-1s-1 .4-1 1v5.1c-4.3-.8-8.1-.1-10.2 2-.2.2-.3.5-.3.7 0 .3.1.5.3.7l25.7 25.7 24.5 24.5c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4M27.3 16.1c3.1 3.1 5.3 7.2 6 11.4.6 3.1.2 5.8-.8 7.5L8.4 10.9c3.7-2.2 12.5-1.2 18.9 5.2" />
  </svg>
);
export default IconComb2;
