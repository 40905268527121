import * as React from "react";
const IconMedal4 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M33 37.5v-9.7l10.6-7.7c1.4-1 2-2.8 1.5-4.5L42.6 8l-1.1-3.3C41 3.1 39.5 2 37.7 2H26.3c-1.7 0-3.3 1.1-3.8 2.8L19 15.7c-.5 1.7 0 3.5 1.5 4.5L31 27.9v9.7c-6.3.5-11.3 5.8-11.3 12.2C19.7 56.5 25.2 62 32 62s12.3-5.5 12.3-12.3c0-6.4-5-11.7-11.3-12.2m-1-11.4-5.3-3.8 16.5-5c-.1.5-.3.9-.8 1.2zM24.4 5.4c.3-.8 1-1.4 1.9-1.4h11.5c.9 0 1.6.6 1.9 1.4l.7 2.2-18.3 4.8zm-3.6 10.9.5-1.6L41 9.5l1.9 5.8-18.2 5.5-3.1-2.3c-.8-.5-1-1.4-.8-2.2M32 60c-5.7 0-10.3-4.6-10.3-10.3S26.3 39.5 32 39.5s10.3 4.6 10.3 10.3S37.7 60 32 60" />
    <path d="M32.7 44.4c-.4-.4-1-.4-1.4 0l-4.1 4.1c-.4.4-.4 1 0 1.4l4.1 4.1c.2.2.5.3.7.3s.5-.1.7-.3l4.1-4.1c.4-.4.4-1 0-1.4zm-.7 7.4-2.6-2.6 2.6-2.6 2.6 2.6z" />
  </svg>
);
export default IconMedal4;
