import * as React from "react";
const IconB = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M13 12a3 3 0 0 1 3-3h10a9 9 0 0 1 6.708 15A9 9 0 0 1 26 39H16a3 3 0 0 1-3-3zm3-1a1 1 0 0 0-1 1v24a1 1 0 0 0 1 1h10a7 7 0 0 0 4.63-12.25 1 1 0 0 1 0-1.5A7 7 0 0 0 26 11zm1 3a1 1 0 0 1 1-1h8a5 5 0 0 1 0 10h-8a1 1 0 0 1-1-1zm2 1v6h7a3 3 0 1 0 0-6zm-2 11a1 1 0 0 1 1-1h8a5 5 0 0 1 0 10h-8a1 1 0 0 1-1-1zm2 1v6h7a3 3 0 1 0 0-6z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconB;
