import * as React from "react";
const IconBitcoinUp = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 3c-11.3 0-20.4 9.1-20.4 20.4 0 6.2 2.8 11.9 7.6 15.8.2.1.4.2.6.2.3 0 .6-.1.8-.4.3-.4.3-1.1-.2-1.4-4.4-3.5-6.8-8.7-6.8-14.3C13.6 13.2 21.8 5 32 5s18.4 8.2 18.4 18.4c0 .6.4 1 1 1s1-.4 1-1C52.4 12.1 43.3 3 32 3M48.9 40.6h-9.7c-.6 0-1 .4-1 1s.4 1 1 1h7.3l-12.3 8.7c-.4.3-.8.4-1.3.4H21.1c-1.2 0-2.4.5-3.2 1.3l-6.1 6.3c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3l6.1-6.3c.4-.5 1-.7 1.7-.7h11.8c.9 0 1.7-.3 2.4-.8l12.6-8.8v7.2c0 .6.4 1 1 1s1-.4 1-1v-9.7c.1-.5-.3-1-.9-1" />
    <path d="M26.9 31.3H26c-.6 0-1 .4-1 1s.4 1 1 1h2.8v1.5c0 .6.4 1 1 1s1-.4 1-1v-1.5h3.5v1.5c0 .6.4 1 1 1s1-.4 1-1v-1.6c2.8-.2 5-2.4 5.1-5.1v-.4c-.1-1.5-.9-2.9-2.1-3.7 1.2-.9 2.1-2.3 2.1-3.8v-.4c-.1-2.7-2.4-4.9-5.1-5.1v-1.5c0-.6-.4-1-1-1s-1 .4-1 1v1.5h-3.5v-1.5c0-.6-.4-1-1-1s-1 .4-1 1v1.5H26c-.6 0-1 .4-1 1s.4 1 1 1h.9v14.6m9.4-6.3c1.7 0 3.1 1.3 3.1 2.8v.3c-.1 1.7-1.6 3.2-3.5 3.2h-7.1V25zm3.2-5.1v.2c0 1.6-1.5 2.9-3.1 2.9H29v-6.3h7c1.9 0 3.4 1.5 3.5 3.2" />
  </svg>
);
export default IconBitcoinUp;
