import * as React from "react";
const IconMushroomAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 62q-6.45 0-9.3-3.6c-3.3-4.3-1.7-10.8 0-17.1.6-2.5 1.3-5.1 1.5-7.2-2.4 1.3-4.6 2.4-7 2.8-3.2.5-5.8-.6-7.9-3.3-3-4-2.9-11.1.4-17.6 2-3.9 4.8-7.3 8.3-9.7C22.1 3.4 26.8 2 32 2s9.9 1.4 13.9 4.3c3.5 2.4 6.3 5.8 8.3 9.7 3.3 6.6 3.4 13.6.4 17.6-1.7 2.3-3.9 3.4-6.5 3.4-.5 0-.9 0-1.4-.1-2.4-.4-4.6-1.6-7-2.8.2 2.1.9 4.7 1.5 7.2 1.6 6.3 3.3 12.8 0 17.1-1.8 2.4-4.9 3.6-9.2 3.6m-6.7-30.6c.2 0 .3 0 .5.1.3.2.5.5.5.8.1 2.6-.8 6-1.7 9.4-1.5 5.8-3.1 11.8-.3 15.4 1.5 1.9 4 2.8 7.7 2.8s6.2-.9 7.7-2.8c2.7-3.5 1.2-9.5-.3-15.4-.9-3.4-1.8-6.9-1.7-9.4 0-.3.2-.6.5-.8s.7-.2 1 0c.5.2.9.5 1.4.7l.1.1c2.3 1.2 4.4 2.3 6.4 2.6 2.4.4 4.4-.4 6-2.5 2.6-3.4 2.3-9.6-.6-15.5-1.8-3.6-4.5-6.7-7.7-9C41.1 5.3 36.8 4 32 4s-9.1 1.3-12.8 3.9c-3.2 2.2-5.8 5.3-7.6 9-3 5.9-3.2 12.1-.6 15.5 1.6 2.1 3.5 2.9 6 2.5 2.1-.3 4.2-1.4 6.4-2.6.5-.3 1-.5 1.5-.8.1 0 .3-.1.4-.1" />
  </svg>
);
export default IconMushroomAlt2;
