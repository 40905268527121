import * as React from "react";
const IconCastleAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.5 2c-.6 0-1 .4-1 1v8.1h-17V3c0-.6-.4-1-1-1s-1 .4-1 1v8.1h-17V3c0-.6-.4-1-1-1s-1 .4-1 1v8.1h-17V3c0-.6-.4-1-1-1s-1 .4-1 1v54c0 2.8 2.3 5.1 5.1 5.1h16c.6 0 1-.4 1-1V45.4c0-2.1.9-4.2 2.4-5.7 1.4-1.4 3.3-2.2 5.2-2.1 1.9 0 3.7.8 5.1 2.2s2.2 3.3 2.2 5.3v16c0 .6.4 1 1 1h16c2.8 0 5.1-2.3 5.1-5.1V3c-.1-.6-.5-1-1.1-1m-1 20.8h-55v-9.7h55.1v9.7zm-3 37.2h-15V45c0-2.5-1-4.9-2.8-6.7-1.7-1.7-4-2.7-6.5-2.8-2.5 0-4.8.9-6.7 2.7-1.9 1.9-3 4.5-3 7.1V60h-15c-1.7 0-3.1-1.4-3.1-3.1V24.8h55.1V57c0 1.6-1.3 3-3 3" />
  </svg>
);
export default IconCastleAlt2;
