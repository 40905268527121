import * as React from "react";
const IconHighlightAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M20 48.8c2 0 3.9-.8 5.4-2.2l32.7-32.7c.8-.8 1.2-1.7 1.2-2.8s-.5-2.1-1.2-2.8L53 3.2c-1.5-1.5-4.1-1.5-5.6 0L14.6 35.9c-1.4 1.4-2.2 3.3-2.2 5.4 0 2 .8 3.9 2.2 5.4s3.3 2.1 5.4 2.1M48.8 4.6c.3-.4.8-.6 1.4-.6s1 .2 1.4.6l5.1 5.1c.4.4.6.9.6 1.4s-.2 1-.6 1.4L30.9 38.2 23 30.3zM16 37.3l5.6-5.6 7.9 7.9-5.6 5.6c-1.1 1.1-2.5 1.6-3.9 1.6-1.5 0-2.9-.6-3.9-1.6-1.1-1.1-1.6-2.5-1.6-3.9s.5-2.9 1.5-4M59.5 60h-55c-.6 0-1 .4-1 1s.4 1 1 1h55c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconHighlightAlt1;
