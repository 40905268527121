import * as React from "react";
const IconPavedRoad = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M42 7A35 35 0 0 0 7 42a1 1 0 1 1-2 0A37 37 0 0 1 42 5a1 1 0 1 1 0 2"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M38.376 15.244q-1.118.15-2.213.394l-.432-1.953a29 29 0 0 1 2.377-.423zm-6.502 1.726a27 27 0 0 0-4.01 2.025l-1.046-1.704a29 29 0 0 1 4.306-2.175zm-7.627 4.687a27 27 0 0 0-3.127 3.224l-1.547-1.268a29 29 0 0 1 3.359-3.463zM18.57 28.58a27 27 0 0 0-1.9 4.07l-1.876-.693a29 29 0 0 1 2.04-4.372zm-3.1 8.398a27 27 0 0 0-.326 2.224l-1.989-.208q.126-1.205.35-2.388zM34.729 24.446A19 19 0 0 1 42 23a1 1 0 1 1 0 2 17 17 0 0 0-17 17 1 1 0 1 1-2 0 19 19 0 0 1 11.729-17.554"
      clipRule="evenodd"
    />
  </svg>
);
export default IconPavedRoad;
