import * as React from "react";
const IconConnectdevelop = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.8 30.5-5.6-9.9c.1-.1.1-.4.1-.8 0-.8-.8-1.7-1.7-1.7l-6.4-11c.1-.1.1-.4.1-.6 0-1-.8-1.7-1.7-1.7-.6 0-1 .3-1.4.7h-12c-.2-.3-.6-.5-1.1-.5-.6 0-1 .3-1.3.7h-12c-.4-.5-.9-.7-1.4-.7-1 0-1.7.8-1.7 1.7 0 .1.1.4.1.6l-6.4 11c-.6.3-1.1.8-1.1 1.6v.1L2.4 30.4c-.8.1-1.4.8-1.4 1.7 0 .8.6 1.6 1.4 1.7l6.2 10.7c-.1.1-.1.3-.1.6 0 .8.6 1.6 1.4 1.7L15.7 57c-.1.1-.1.4-.1.6 0 1 .8 1.7 1.7 1.7.6 0 1-.3 1.3-.6h12.1c.4.3.8.6 1.4.6.4 0 1-.3 1.3-.6h12.1c.3.3.8.6 1.3.6 1 0 1.7-.8 1.7-1.7 0-.1 0-.3-.1-.4L54.2 47c.8-.1 1.4-.8 1.4-1.7 0-.1 0-.4-.1-.6L61.7 34c.8-.1 1.4-.8 1.4-1.7s-.5-1.6-1.3-1.8m-45 23.7-4.9-8.6h4.9zm0-9.4h-4.9c0-.1-.1-.1-.1-.4l5.1-5.4v5.8zm0-7.1L11 43.8c-.1-.1-.3-.3-.4-.3l-6.2-11c.1-.1.1-.3.1-.4v-.4l5.9-10.2c.3 0 .6-.1.8-.3l5.6 5.9zm0-11.7-5.1-5.4c.1-.3.3-.6.3-.8v-.1l5-1.7zm0-9-4.9 1.7 4.9-8.6zm37 4.3.1.1-4 18.9-7.2-7.6zM32.5 43.5l1.3 1.4h-2.7zm0-1.4L23 32.4l9-9.5 9.5 9.9zm.5.7 8.9-9.3 7.6 8-.5 3.3H35zM45.9 8.2c.1.2.1.2.4.2l6.5 11.4v.1c0 .3.1.7.3.8l-11 11.4-9.5-9.9zm-1-.3L32.1 21.6l-6.5-6.8L44.5 8h.4zm-14 0c.3.3.7.4 1.1.4s.8-.1 1.1-.4h8.5L24.9 14l-5.8-6.1zm-13.1.7.1-.1c.1 0 .1-.1.3-.1l5.8 6.1-6.2 2.3zm0 9.1 6.9-2.5 6.8 7.2-9.2 9.5-4.6-4.9c.1 0 .1-9.3.1-9.3m0 10.4 4.1 4.2-4.1 4.4zm0 9.9 4.8-5.1 9.5 9.7-2 2H17.8zm.7 18.4c-.1-.1-.4-.3-.7-.4l-.1-.1V45.6h11.4zm14.7 0c-.3-.3-.7-.4-1.1-.4s-.8.1-1.1.4H20l10.4-10.7h4.4l10.4 10.7zm13.7-1.3-.4.8c-.1 0-.3.1-.4.1L35.9 45.6h13zm1.4-2.6 1.4-6.9h2.5zm3.9-7.7h-2.4l.4-2.4 2 2c.2.1 0 .2 0 .4m7.5-12.2-6.4 10.9c-.1.1-.3.1-.4.1l-2.4-2.5 4-18.5 5.1 8.9c0 .1-.1.3-.1.4.1.5.2.6.2.7" />
  </svg>
);
export default IconConnectdevelop;
