import React from "react";
import { generateCSSString, getPaddingStyles } from "../../../../helpers/style";

export default function Text({
    component
}) {

    const cssValue = [...getPaddingStyles('text')]
    return (
        <div 
            id={`Text-${component.uuid}`}
            className={`w-full ${generateCSSString(component, cssValue)} break-words`} 
            dangerouslySetInnerHTML={{__html: component.textContent_}}>
        </div>

    )
}