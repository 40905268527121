import * as React from "react";
const IconFlowerAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62.5 32c0-4.6-3.7-8.3-8.3-8.3H52l1.6-1.6c1.6-1.6 2.4-3.6 2.4-5.9s-.9-4.3-2.4-5.9C52 8.9 49.9 8 47.7 8s-4.3.9-5.9 2.4L40.3 12V9.8c0-4.6-3.7-8.3-8.3-8.3s-8.3 3.7-8.3 8.3V12l-1.6-1.6C20.6 8.9 18.5 8 16.3 8s-4.3.9-5.9 2.4C8.9 12 8 14.1 8 16.3s.9 4.3 2.4 5.9l1.6 1.6H9.8c-4.6 0-8.3 3.7-8.3 8.3s3.7 8.3 8.3 8.3H12L10.4 42C8.9 43.4 8 45.5 8 47.7s.9 4.3 2.4 5.9C12 55.2 14 56 16.3 56s4.3-.9 5.9-2.4l1.6-1.6v2.2c0 4.6 3.7 8.3 8.3 8.3s8.3-3.7 8.3-8.3V52l1.6 1.6c1.6 1.6 3.6 2.4 5.9 2.4s4.3-.9 5.9-2.4c1.6-1.6 2.4-3.6 2.4-5.9s-.9-4.3-2.4-5.9L52 40.3h2.2c4.6 0 8.3-3.7 8.3-8.3m-13.6 8 3.3 3.3c1.2 1.2 1.8 2.8 1.8 4.4s-.7 3.3-1.8 4.4C51 53.3 49.4 54 47.7 54s-3.3-.7-4.4-1.8L40 48.9c-.3-.3-.7-.4-1.1-.2q-.6.3-.6.9v4.6c0 3.5-2.8 6.3-6.3 6.3s-6.3-2.8-6.3-6.3v-4.6c0-.4-.2-.8-.6-.9-.1-.1-.3-.1-.4-.1-.3 0-.5.1-.7.3l-3.3 3.3C19.5 53.3 18 54 16.3 54s-3.3-.7-4.4-1.8C10.7 51 10 49.4 10 47.7s.7-3.3 1.8-4.4l3.3-3.3c.3-.3.4-.7.2-1.1s-.5-.6-.9-.6H9.8c-3.5 0-6.3-2.8-6.3-6.3s2.8-6.3 6.3-6.3h4.6c.4 0 .8-.2.9-.6.2-.4.1-.8-.2-1.1l-3.3-3.3C10.7 19.5 10 18 10 16.3s.7-3.3 1.8-4.4c1.2-1.2 2.8-1.8 4.4-1.8s3.3.7 4.4 1.8l3.3 3.3c.3.3.7.4 1.1.2q.6-.3.6-.9V9.8c0-3.5 2.8-6.3 6.3-6.3s6.3 2.8 6.3 6.3v4.6c0 .4.2.8.6.9.4.2.8.1 1.1-.2l3.3-3.3c1.2-1.2 2.8-1.8 4.4-1.8s3.3.7 4.4 1.8c1.2 1.2 1.8 2.8 1.8 4.4 0 1.7-.7 3.3-1.8 4.4L48.9 24c-.3.3-.4.7-.2 1.1q.3.6.9.6h4.6c3.5 0 6.3 2.8 6.3 6.3s-2.8 6.3-6.3 6.3h-4.6c-.4 0-.8.2-.9.6-.2.4-.1.8.2 1.1" />
    <path d="M32 21.8c-5.6 0-10.2 4.6-10.2 10.2S26.4 42.2 32 42.2 42.2 37.6 42.2 32 37.6 21.8 32 21.8m0 18.4c-4.5 0-8.2-3.7-8.2-8.2s3.7-8.2 8.2-8.2 8.2 3.7 8.2 8.2-3.7 8.2-8.2 8.2" />
  </svg>
);
export default IconFlowerAlt1;
