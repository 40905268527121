import * as React from "react";
const IconDumbbellCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 31h-3.4v-5.4c0-1.1-.9-2-2-2h-7.8c-1.1 0-2 .9-2 2V31H18.3v-5.4c0-1.1-.9-2-2-2H8.5c-1.1 0-2 .9-2 2V31H3c-.6 0-1 .4-1 1s.4 1 1 1h3.5v5.4c0 1.1.9 2 2 2h7.8c1.1 0 2-.9 2-2V33h27.4v5.4c0 1.1.9 2 2 2h7.8c1.1 0 2-.9 2-2V33H61c.6 0 1-.4 1-1s-.4-1-1-1M8.5 38.4V25.6h7.8v12.7H8.5zm47.1 0h-7.8V25.6h7.8z" />
  </svg>
);
export default IconDumbbellCopy;
