import * as React from "react";
const IconBed3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 28.9h-2v-6c0-3.9-3.1-7-7-7H31.6c-3.9 0-7 3.1-7 7v6h-6.3c1.5-1.2 2.5-3 2.5-5.1 0-3.5-2.9-6.4-6.4-6.4S8 20.3 8 23.8c0 2.1 1 3.9 2.5 5.1H4V9.4c0-.6-.4-1-1-1s-1 .4-1 1v45.1c0 .6.4 1 1 1s1-.4 1-1v-8.4h56v8.4c0 .6.4 1 1 1s1-.4 1-1V29.9c0-.6-.4-1-1-1m-46.6-9.5c2.4 0 4.4 2 4.4 4.4s-2 4.4-4.4 4.4-4.4-2-4.4-4.4 2-4.4 4.4-4.4m12.2 3.4c0-2.8 2.2-5 5-5H52c2.8 0 5 2.2 5 5v6H26.6zM4 44.2V30.9h56v13.3z" />
  </svg>
);
export default IconBed3;
