import * as React from "react";
const IconHospitalCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.2 20.8 34.9 3.1a4.43 4.43 0 0 0-5.9 0L8.8 20.8c-1 .8-1.5 2.1-1.5 3.4v33.3c0 2.5 2 4.5 4.5 4.5h8.9c2.5 0 4.5-2 4.5-4.5V46c0-1.4 1.1-2.5 2.5-2.5h8.9c1.4 0 2.5 1.1 2.5 2.5v11.5c0 2.5 2 4.5 4.5 4.5h8.9c2.5 0 4.5-2 4.5-4.5V24.2c-.3-1.3-.8-2.5-1.8-3.4m-.5 36.7c0 1.4-1.1 2.5-2.5 2.5h-8.9c-1.4 0-2.5-1.1-2.5-2.5V46c0-2.5-2-4.5-4.5-4.5h-8.9c-2.5 0-4.5 2-4.5 4.5v11.5c0 1.4-1.1 2.5-2.5 2.5h-8.9C10.1 60 9 58.9 9 57.5V24.2c0-.7.3-1.4.8-1.9L30.4 4.6c.9-.8 2.3-.8 3.2 0l20.3 17.7c.5.5.8 1.1.8 1.9z" />
    <path d="M37.3 20.4c-.6 0-1 .4-1 1v4.3h-8.6v-4.3c0-.6-.4-1-1-1s-1 .4-1 1V32c0 .6.4 1 1 1s1-.4 1-1v-4.3h8.6V32c0 .6.4 1 1 1s1-.4 1-1V21.4c0-.5-.5-1-1-1" />
  </svg>
);
export default IconHospitalCopy;
