import * as React from "react";
const IconAddCart = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 4.1h-8c-.5 0-.9.3-1 .8l-3.3 13.6h-9.8v-.9C38.9 10.1 32.8 4 25.3 4s-13.6 6.1-13.6 13.6v.9H3c-.3 0-.6.1-.8.4s-.2.6-.2.9l5 21.4c.1.5.5.8 1 .8h36.3c.5 0 .9-.3 1-.8l8.5-35.1H61c.6 0 1-.4 1-1s-.4-1-1-1m-35.6 2c6.4 0 11.6 5.2 11.6 11.6s-5.2 11.6-11.6 11.6-11.6-5.2-11.6-11.6S19 6.1 25.4 6.1M43.5 40H8.8L4.3 20.5h7.8c1.3 6.1 6.8 10.7 13.3 10.7s12-4.6 13.3-10.7h9.6zM38.6 49.2h-25c-3 0-5.4 2.4-5.4 5.4s2.4 5.4 5.4 5.4 5.4-2.4 5.4-5.4c0-1.3-.5-2.4-1.2-3.4h16.7c-.8.9-1.2 2.1-1.2 3.4 0 3 2.4 5.4 5.4 5.4s5.4-2.4 5.4-5.4-2.6-5.4-5.5-5.4M17 54.5c0 1.9-1.5 3.4-3.4 3.4s-3.4-1.5-3.4-3.4 1.5-3.4 3.4-3.4c1.9.1 3.4 1.6 3.4 3.4m21.6 3.4c-1.9 0-3.4-1.5-3.4-3.4s1.5-3.4 3.4-3.4 3.4 1.5 3.4 3.4c-.1 1.9-1.6 3.4-3.4 3.4" />
    <path d="M21.2 18.7h3.2v3.2c0 .6.4 1 1 1s1-.4 1-1v-3.2h3.2c.6 0 1-.4 1-1s-.4-1-1-1h-3.2v-3.2c0-.6-.4-1-1-1s-1 .4-1 1v3.2h-3.2c-.6 0-1 .4-1 1s.4 1 1 1" />
  </svg>
);
export default IconAddCart;
