import * as React from "react";
const IconCrownAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.1 19c-.7-.6-1.8-.8-2.7-.3l-9.1 4.4c-.8.4-1.7.2-2.3-.5L33.8 7.4c-.9-1.1-2.8-1.1-3.7 0L16.9 22.6c-.6.7-1.5.9-2.3.5l-9.1-4.4c-.9-.4-1.9-.3-2.7.3-.7.6-1 1.6-.7 2.6l6.4 20.8c.9 2.9 3.5 4.8 6.5 4.8h34.1c3 0 5.6-1.9 6.5-4.8L62 21.6c.2-.9-.1-1.9-.9-2.6M60 21l-6.4 20.8c-.6 2-2.4 3.4-4.6 3.4H14.9c-2.1 0-3.9-1.4-4.6-3.4L4 21c-.1-.2.1-.4.1-.5.1 0 .2-.1.3-.1h.2l9.1 4.4c1.6.8 3.5.4 4.7-1L31.7 8.7c.1-.1.3-.2.3-.2s.2 0 .3.2l13.3 15.2c1.2 1.3 3.1 1.7 4.7 1l9.1-4.4c.2-.1.4 0 .5.1 0 0 .2.2.1.4M53.3 55.4H10.7c-.6 0-1 .4-1 1s.4 1 1 1h42.6c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconCrownAlt1;
