import * as React from "react";
const IconFarenheit = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 4.4H37.5c-3 0-5.5 2.5-5.5 5.6v17.2h-6.6c-.6 0-1 .4-1 1s.4 1 1 1H32v29.4c0 .6.4 1 1 1s1-.4 1-1V29.2h17.2c.6 0 1-.4 1-1s-.4-1-1-1H34V10c0-2 1.6-3.6 3.5-3.6H61c.6 0 1-.4 1-1s-.4-1-1-1M8.9 4.7c-3.8 0-6.9 3-6.9 6.8v.2c0 3.8 3.1 6.9 6.9 6.9s6.9-3.1 6.9-6.9v-.2c0-3.8-3.1-6.8-6.9-6.8m4.9 7c0 2.7-2.2 4.9-4.9 4.9S4 14.4 4 11.7v-.2c0-2.7 2.2-4.9 4.9-4.9s4.9 2.2 4.9 4.9z" />
  </svg>
);
export default IconFarenheit;
