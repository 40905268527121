import * as React from "react";
const IconAwardRibbon = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M23.999 6c-7.18 0-13 5.82-13 13 0 5.404 3.298 10.039 7.991 12H19v.004A13 13 0 0 0 24 32 13 13 0 0 0 29 31.003V31h.008c4.693-1.961 7.991-6.596 7.991-12 0-7.18-5.82-13-13-13M31 32.27C35.757 29.753 39 24.755 39 19c0-8.284-6.716-15-15-15s-15 6.716-15 15c0 5.756 3.243 10.756 8.001 13.27V43a1 1 0 0 0 1.555.832L24 40.202l5.445 3.63A1 1 0 0 0 31 43zm-2 .876A15 15 0 0 1 24 34c-1.753 0-3.435-.3-4.999-.853v7.985l4.445-2.964a1 1 0 0 1 1.11 0L29 41.132zM24 12a7 7 0 1 0 0 14 7 7 0 0 0 0-14m-9 7a9 9 0 1 1 18 0 9 9 0 0 1-18 0"
      clipRule="evenodd"
    />
  </svg>
);
export default IconAwardRibbon;
