import * as React from "react";
const IconCastleAlt6 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 25.7h-9.2V5.5c0-.6-.4-1-1-1s-1 .4-1 1v10.1h-8.5V5.5c0-.6-.4-1-1-1s-1 .4-1 1v10.1h-8.5V5.5c0-.6-.4-1-1-1s-1 .4-1 1v10.1h-8.5V5.5c0-.6-.4-1-1-1s-1 .4-1 1v20.1H6c-2.2 0-4 1.8-4 4v25.8c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V29.7c0-2.2-1.8-4-4-4M15.2 57.5H6c-1.1 0-2-.9-2-2V29.7c0-1.1.9-2 2-2h9.2zm12.5 0v-5.1c0-2.4 1.9-4.3 4.3-4.3s4.3 1.9 4.3 4.3v5.1zm19.1 0h-8.5v-5.1c0-3.5-2.8-6.3-6.3-6.3s-6.3 2.8-6.3 6.3v5.1h-8.5V17.7h29.6v39.8m13.2-2c0 1.1-.9 2-2 2h-9.2V27.7H58c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconCastleAlt6;
