import * as React from "react";
const IconTv = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 6.6H6c-2.2 0-4 1.8-4 4v30.3c0 2.2 1.8 4 4 4h25v10.6h-5.5c-.6 0-1 .4-1 1s.4 1 1 1h12.2c.6 0 1-.4 1-1s-.4-1-1-1H33V44.9h25c2.2 0 4-1.8 4-4V10.6c0-2.2-1.8-4-4-4m2 34.3c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V10.6c0-1.1.9-2 2-2h52c1.1 0 2 .9 2 2z" />
    <path d="M27.4 20.9h-9.1c-.6 0-1 .4-1 1s.4 1 1 1h3.5V31c0 .6.4 1 1 1s1-.4 1-1v-8.1h3.5c.6 0 1-.4 1-1s-.3-1-.9-1M45.3 21c-.5-.2-1.1 0-1.3.4l-3.6 7.2-3.4-7.1c-.2-.5-.8-.7-1.3-.5s-.7.8-.5 1.3l4.2 9.1c.2.3.5.6.9.6q.6 0 .9-.6l4.6-9.1c.2-.4 0-1-.5-1.3" />
  </svg>
);
export default IconTv;
