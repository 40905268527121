import React, { useState } from 'react';
import { BringzzButton, BringzzText } from "@bringzz/components";
import { useNavigation } from 'context/NavigationContext';
import {
    BringzzPageContainer,
    BringzzIcon,
    BringzzRadioList,
    BringzzCountryDropdown,
    BringzzInput
} from "@bringzz/components";
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const ShopperAddressPage = () => {
    const { backPress } = useNavigation();

    const { navigate } = useNavigation();

    const [selected, setSelected] = useState(null)

    return (
        <BringzzPageContainer
            className="bg-white"
            header={{
                left: (
                    <BringzzIcon
                        size='22'
                        icon='IconChevronLeft'
                        folder='LineIcons'
                        className='cursor-pointer'
                        onClick={backPress}
                    />
                )
            }}
            footer={
                <div className='px-4 mb-4'>
                    <BringzzButton
                        size='lg'
                        className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
                        onClick={() => {
                            navigate('/home');
                        }}
                    >
                        Save
                    </BringzzButton>
                </div>
            }
            authenticated={false}
        >
            <div className='relative flex justify-center bg-magic-lilac/20'>
                <div className='relative bg-white w-full bg-cover bg-center flex flex-col'>
                    {/* Content */}
                    <div className='flex-grow flex flex-col py-8 px-4'>
                        <div className='z-10 flex flex-col '>
                            <div className='flex flex-col text-center space-y-6 mx-auto md:max-w-lg lg:max-w-xl xl:max-w-2xl'>
                                <BringzzText tag='h1' className='text-midnight-blue'>
                                    Your Address
                                </BringzzText>
                                <BringzzText
                                    tag='h4'
                                    className='text-midnight-blue/25 font-normal'
                                >
                                    We’ll be using this for your bills and and preferred delivery address. You can change this later.
                                </BringzzText>
                            </div>
                            <div className='mt-6'>
                                <div className='space-y-4 mt-4'>
                                    <BringzzInput label="Street name and house number" placeholder="Street name and house number" tooltip="Sonething" />
                                    <div className='flex gap-2'>
                                        <BringzzInput label="Entrance (opt.)" placeholder="Entrance" tooltip="Sonething" />
                                        <BringzzInput label="Floor (opt.)" placeholder="Floor" tooltip="Sonething" />
                                        <BringzzInput label="Door (opt.)" placeholder="Door" tooltip="Sonething" />
                                    </div>
                                    <div className='flex gap-2'>
                                        <BringzzInput label="Postal code" placeholder="" tooltip="Sonething" />
                                        <BringzzInput label="Stadt" placeholder="" tooltip="Sonething" />
                                    </div>
                                    <BringzzCountryDropdown
                                        mode="flag-name"
                                        label="Country"
                                        onCountryChange={() => console.log("changed")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </BringzzPageContainer >
    );
};

export default ShopperAddressPage;
