import * as React from "react";
const IconDatabase = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      d="M30 36.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0M33.5 37a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3"
    />
    <path
      
      fillRule="evenodd"
      d="M24 6c7.72 0 14.08 1.75 14.908 4H39v28c0 2.21-6.716 4-15 4S9 40.21 9 38V10h.092C9.92 7.75 16.279 6 24 6m0 27c-5.557 0-10.408-.907-13-2.253v6.946q.08.066.245.172c.517.326 1.398.693 2.664 1.031C16.412 39.563 19.98 40 24 40s7.588-.437 10.091-1.104c1.266-.338 2.147-.705 2.664-1.03q.164-.107.245-.173v-6.946C34.408 32.093 29.557 33 24 33m0-9c5.557 0 10.408-.907 13-2.253V28.5h-.177a3 3 0 0 1-.159.12c-.51.362-1.379.77-2.632 1.146C31.55 30.511 28.004 31 24 31s-7.55-.49-10.032-1.234c-1.253-.376-2.122-.784-2.632-1.146a3 3 0 0 1-.16-.12H11v-6.753C13.592 23.093 18.443 24 24 24m0-9c5.557 0 10.408-.906 13-2.254V19.5h-.177a3 3 0 0 1-.159.12c-.51.362-1.379.77-2.632 1.146C31.55 21.511 28.004 22 24 22s-7.55-.49-10.032-1.234c-1.253-.376-2.122-.784-2.632-1.146a3 3 0 0 1-.16-.12H11v-6.754C13.592 14.095 18.443 15 24 15m12.75-4.557.073.057a3 3 0 0 1-.159.12c-.51.362-1.379.77-2.632 1.146C31.55 12.511 28.004 13 24 13s-7.55-.49-10.032-1.234c-1.253-.376-2.122-.784-2.632-1.146a3 3 0 0 1-.159-.12 3 3 0 0 1 .159-.12 4 4 0 0 1 .348-.222q.223-.128.498-.258.55-.262 1.327-.522.451-.15.974-.291c.766-.207 1.62-.391 2.543-.546C19.06 8.201 21.438 8 24 8c3.727 0 7.065.424 9.517 1.087q.267.072.515.147c.98.294 1.743.613 2.284.924a5 5 0 0 1 .348.222zm.36 27.144q.002 0-.016.021zm-26.22 0q.001 0 .016.021-.018-.021-.017-.021"
      clipRule="evenodd"
    />
  </svg>
);
export default IconDatabase;
