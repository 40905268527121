// Groups.js
import React from 'react';
import { BringzzText, BringzzIcon, BringzzAccordion } from "@bringzz/components";
import { groupsBy } from './constants';
import classNames from 'classnames';

const Groups = () => {
    return (
        <div className='mt-2 mx-2 space-y-2'>
            {groupsBy.map((group, index) => (
                <BringzzAccordion key={index} className='px-6 bg-white rounded-xl' title={
                    <BringzzText tag='h4'>{group.label}</BringzzText>
                }
                    icon={
                        {
                            expanded: <BringzzIcon icon="IconMinu" folder="LineIcons" size="16" />,
                            closed: <BringzzIcon icon="IconPlus" folder="LineIcons" size="16" />
                        }
                    }
                >
                    {group.items.map((category, index) => (
                        <div key={index} className={classNames(
                            'flex items-center h-12 justify-between cursor-pointer',
                            index !== group.items.length - 1 && 'border-b'
                        )}
                        >
                            <div className='flex space-x-3 items-center'>
                                {category.icon}
                                <BringzzText tag='h3' className='font-normal'>{category.shortName}</BringzzText>
                            </div>
                            <BringzzIcon size="16" icon="IconChevronRight" folder="LineIcons" />
                        </div>
                    ))}
                </BringzzAccordion>
                // <div key={index} className='flex justify-between items-center bg-white rounded-xl py-2 px-4'>
                //     <BringzzText tag='h4'>{group.label}</BringzzText>
                //     <BringzzIcon size="16" icon="IconPlus" folder="LineIcons" />
                // </div>
            ))}
        </div>
    );
};

export default Groups;