import * as React from "react";
const IconShieldAlt2Check = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.3 5.7c-.3-.2-.7-.2-1 0-2.6 1.7-5.7 2.6-8.9 2.6-4.9 0-9.5-2.2-12.7-5.9-.4-.5-1.2-.5-1.5 0-3.1 3.8-7.8 5.9-12.7 5.9-3.2 0-6.2-.9-8.9-2.6-.3-.2-.7-.2-1 0-.2.2-.4.5-.4.9V33c0 14.3 19.4 26.6 23.3 28.9.1.1.3.1.5.1s.4 0 .5-.1c3.9-2.3 23.3-14.6 23.3-28.9V6.6c0-.4-.2-.7-.5-.9M53.8 33c0 12.7-17.2 24-21.8 26.8C27.3 57 10.2 45.7 10.2 33V8.3c2.6 1.3 5.5 2 8.4 2 5.1 0 10-2.1 13.4-5.8 3.5 3.7 8.3 5.8 13.4 5.8 2.9 0 5.8-.7 8.4-2z" />
    <path d="M40.8 24.2 27.9 34.7l-4.6-6.3c-.3-.4-.9-.5-1.4-.2-.4.3-.5.9-.2 1.4l5.2 7.2c.2.2.4.4.7.4h.1c.2 0 .5-.1.6-.2L42 25.9c.4-.3.5-1 .1-1.4-.2-.5-.9-.6-1.3-.3" />
  </svg>
);
export default IconShieldAlt2Check;
