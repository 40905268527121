import * as React from "react";
const IconBracketsAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 4H6C3.8 4 2 5.8 2 8v48c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V8c0-2.2-1.8-4-4-4m2 52c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V8c0-1.1.9-2 2-2h52c1.1 0 2 .9 2 2z" />
    <path d="M20.6 41.4h-1.5v-6.5c0-.2-.1-.5-.3-.7l-2.3-2.6 2.3-2.6c.2-.2.3-.4.3-.7v-5.9h1.5c.6 0 1-.4 1-1s-.4-1-1-1h-2.5c-.6 0-1 .4-1 1V28l-2.6 3c-.3.4-.3 1 0 1.3l2.7 3v7.2c0 .6.4 1 1 1h2.5c.6 0 1-.4 1-1s-.5-1.1-1.1-1.1M46.9 28v-6.5c0-.6-.4-1-1-1h-2.5c-.6 0-1 .4-1 1s.4 1 1 1h1.5v5.9c0 .2.1.5.3.7l2.3 2.6-2.3 2.6c-.2.2-.3.4-.3.7v6.5h-1.5c-.6 0-1 .4-1 1s.4 1 1 1h2.5c.6 0 1-.4 1-1v-7.2l2.7-3c.3-.4.3-1 0-1.3z" />
  </svg>
);
export default IconBracketsAlt2;
