import * as React from "react";
const IconDumbell = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 20.6h-3.8v-1.4c0-2.2-1.8-4-4-4h-8.9c-.6 0-1 .4-1 1V31H23.7V16.1c0-.6-.4-1-1-1h-8.9c-2.2 0-4 1.8-4 4v1.4H6c-2.2 0-4 1.8-4 4v14.9c0 2.2 1.8 4 4 4h3.8v1.4c0 2.2 1.8 4 4 4h8.9c.6 0 1-.4 1-1V33h16.7v14.9c0 .6.4 1 1 1h8.9c2.2 0 4-1.8 4-4v-1.4H58c2.2 0 4-1.8 4-4V24.6c0-2.3-1.8-4-4-4M6 41.4c-1.1 0-2-.9-2-2V24.6c0-1.1.9-2 2-2h3.8v18.9H6zm15.7 5.5h-7.9c-1.1 0-2-.9-2-2V19.2c0-1.1.9-2 2-2h7.9zm30.5-2c0 1.1-.9 2-2 2h-7.9V17.1h7.9c1.1 0 2 .9 2 2v25.8m7.8-5.5c0 1.1-.9 2-2 2h-3.8V22.6H58c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconDumbell;
