import * as React from "react";
const IconDirectionLtrCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M52.2 3H21.6c-6.1 0-10.9 4.8-10.9 10.8s4.9 10.9 10.9 10.9H24v15.1c0 .6.4 1 1 1s1-.4 1-1V5h10v34.8c0 .6.4 1 1 1s1-.4 1-1V5h14.2c.6 0 1-.4 1-1s-.4-1-1-1M21.7 22.7c-4.9 0-8.9-4-8.9-8.9S16.7 5 21.7 5h2.4v17.6c-.5.1-1.3.1-2.4.1M45.8 45.8c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l4.9 5H13.7c-.6 0-1 .4-1 1s.4 1 1 1h35.6l-5 5.1c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3l6.6-6.7c.4-.4.4-1 0-1.4z" />
  </svg>
);
export default IconDirectionLtrCopy;
