import * as React from "react";
const IconRocketFavourite = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.3 40.6c-1.7 0-3.3.6-4.5 1.7-1.2-1.1-2.8-1.7-4.5-1.7-3.7 0-6.7 3-6.7 6.7 0 6.1 9.1 13 10.2 13.8.3.2.7.4 1.1.4s.8-.1 1.1-.4c1-.8 10.2-7.7 10.2-13.8-.2-3.6-3.2-6.7-6.9-6.7m-4.5 18.9c-1.1-.9-9.2-7.2-9.2-12.1 0-2.6 2.1-4.7 4.7-4.7 1.5 0 2.8.7 3.7 1.8.4.5 1.2.5 1.6 0 .9-1.2 2.3-1.8 3.7-1.8 2.6 0 4.7 2.1 4.7 4.7 0 4.8-8.1 11.2-9.2 12.1M56.3 3.5c0-.5-.4-.9-.9-.9-6.3-.4-14.1.7-24.9 11.5l-9.7 9.7H9.6c-.3 0-.5.1-.7.3l-6.6 6.6c-.2.1-.3.4-.3.7s.1.5.3.7l7.6 7.6c-.9.3-1.8.8-2.5 1.6-2.6 2.6-4 12.1-4.1 13.1 0 .3.1.6.3.8s.4.3.7.3h.1c1.1-.1 10.6-1.5 13.2-4.1.7-.7 1.2-1.6 1.6-2.5l7.6 7.6c.2.2.5.3.7.3.3 0 .5-.1.7-.3l1.3-1.3c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-.6.6-23-23 5.6-5.6h11.1c.3 0 .5-.1.7-.3l10-10c9.7-9.7 16.6-11.2 22.5-11 .2 5.9-1.2 12.7-11 22.5l-10 10c-.2.2-.3.4-.3.7v1.1c0 .6.4 1 1 1s1-.4 1-1v-.7l9.7-9.7C55.6 17.5 56.7 9.7 56.3 3.5M16.1 50c-1.4 1.4-6.8 2.7-10.7 3.4.6-3.8 1.9-9.3 3.4-10.7.7-.7 1.7-1.2 2.7-1.4l6 6c-.2 1-.6 1.9-1.4 2.7" />
  </svg>
);
export default IconRocketFavourite;
