import * as React from "react";
const IconSpeechBubble27 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.9 2.8H7.1C4.3 2.8 2 5.1 2 7.9v33.5c0 2.8 2.3 5.1 5.1 5.1h5c-1 3.6-2.9 10.3-4.7 13.1-.2.4-.2.9.1 1.2.2.2.5.4.8.4.1 0 .3 0 .4-.1 5-2.2 14.5-9.6 18-14.5h30.2c2.8 0 5.1-2.3 5.1-5.1V7.9c0-2.8-2.3-5.1-5.1-5.1M60 41.5c0 1.7-1.4 3.1-3.1 3.1H26.2c-.3 0-.7.2-.8.5-2.5 3.8-9.6 9.7-14.7 12.8 1.4-3.4 2.7-8 3.8-12 .1-.3 0-.6-.2-.9-.2-.2-.5-.4-.8-.4H7.1c-1.7 0-3.1-1.4-3.1-3.1V7.9c0-1.7 1.4-3.1 3.1-3.1h49.7c1.7 0 3.1 1.4 3.1 3.1v33.6z" />
    <path d="M16.3 21.8h-.1c-1.1 0-1.9.9-1.9 2s.9 2 2 2 2-.9 2-2-.9-2-2-2M31.4 21.8h-.1c-1.1 0-1.9.9-1.9 2s.9 2 2 2 2-.9 2-2-.9-2-2-2M46.6 21.8h-.1c-1.1 0-1.9.9-1.9 2s.9 2 2 2 2-.9 2-2-.9-2-2-2" />
  </svg>
);
export default IconSpeechBubble27;
