import * as React from "react";
const IconRemoveFileCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M38.8 23.1H25.2c-.6 0-1 .4-1 1s.4 1 1 1h13.6c.6 0 1-.4 1-1s-.4-1-1-1" />
    <path d="M49.8 2H14.2c-2.4 0-4.3 1.9-4.3 4.3v33.9c0 1.1.5 2.3 1.3 3l18.7 17.6c.8.8 1.8 1.2 2.9 1.2h17c2.4 0 4.3-1.9 4.3-4.3V6.3c0-2.4-1.9-4.3-4.3-4.3M13.3 42.5h15.4c1.3 0 2.3 1 2.3 2.2v14.5zm38.8 15.2c0 1.3-1 2.3-2.3 2.3H33V44.6c0-2.3-1.9-4.2-4.3-4.2H11.9V6.3c0-1.3 1-2.3 2.3-2.3h35.6c1.3 0 2.3 1 2.3 2.3z" />
  </svg>
);
export default IconRemoveFileCopy;
