import * as React from "react";
const IconPaymentTerminal = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 15.9H45.8v-3.1c0-.6-.4-1-1-1h-8.4V6.9c0-2.2-1.8-4-4-4H6c-2.2 0-4 1.8-4 4v50.2c0 2.2 1.8 4 4 4h26.3c2.2 0 4-1.8 4-4v-4.9h8.4c.6 0 1-.4 1-1v-3.1H58c2.2 0 4-1.8 4-4V19.9c0-2.2-1.8-4-4-4M34.3 57.1c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V6.9c0-1.1.9-2 2-2h26.3c1.1 0 2 .9 2 2v50.2m9.5-6.9h-7.4V13.8h7.4v36.4M60 44.1c0 1.1-.9 2-2 2H45.8V17.9H58c1.1 0 2 .9 2 2z" />
    <path d="M54 23.7c-.6 0-1 .4-1 1v5.6c0 .6.4 1 1 1s1-.4 1-1v-5.6c0-.5-.5-1-1-1M10.9 21h16c.6 0 1-.4 1-1v-7.9c0-.6-.4-1-1-1h-16c-.6 0-1 .4-1 1V20c0 .6.4 1 1 1m1-7.9h14V19h-14zM12.6 29.5h-1.5c-.6 0-1 .4-1 1s.4 1 1 1h1.5c.6 0 1-.4 1-1s-.5-1-1-1M27.1 29.5h-1.5c-.6 0-1 .4-1 1s.4 1 1 1h1.5c.6 0 1-.4 1-1s-.5-1-1-1M12.6 38.3h-1.5c-.6 0-1 .4-1 1s.4 1 1 1h1.5c.6 0 1-.4 1-1s-.5-1-1-1M27.1 38.3h-1.5c-.6 0-1 .4-1 1s.4 1 1 1h1.5c.6 0 1-.4 1-1s-.5-1-1-1M23.2 49.6h-8.1c-.6 0-1 .4-1 1s.4 1 1 1h8.1c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconPaymentTerminal;
