import * as React from "react";
const IconBurgerAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M3 23.6h58c.6 0 1-.4 1-1C62 11.3 52.7 2 41.4 2H22.6C17.1 2 11.9 4.1 8 8s-6 9.1-6 14.6c0 .6.4 1 1 1M9.5 9.5C13 5.9 17.7 4 22.6 4h18.7c9.9 0 18.1 7.8 18.6 17.6H4c.3-4.6 2.2-8.9 5.5-12.1M61 40.4H3c-.6 0-1 .4-1 1C2 52.7 11.3 62 22.6 62h18.7c5.5 0 10.7-2.1 14.6-6s6-9.1 6-14.6c.1-.6-.3-1-.9-1m-6.5 14.1C51 58.1 46.3 60 41.4 60H22.6C12.7 60 4.5 52.2 4 42.4h56c-.3 4.6-2.2 8.9-5.5 12.1M61 31H3c-.6 0-1 .4-1 1s.4 1 1 1h58c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconBurgerAlt1;
