import * as React from "react";
const IconIdeaHead = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62 30.2c-.1-3.5-1.9-6.7-4.6-8.5.3-3-.6-5.9-2.5-8.2-1.8-2.1-4.2-3.3-6.8-3.5-1.4-3.4-4.3-5.8-7.8-6.3-2.8-.4-5.4.4-7.5 2.2v-.4c0-1.2-1-2.2-2.2-2.2-5.8 0-11.3 2.3-15.4 6.4s-6.4 9.6-6.4 15.4c0 1.3-.3 2.5-1 3.5l-5.4 8.6c-.2.5-.4 1.1-.4 1.7 0 1.9 1.5 3.3 3.3 3.3h3.6v5.9c0 3.1 2.5 5.6 5.6 5.6.6 0 1.2-.1 1.6-.3l4.3-1.3v6.3c0 1.2 1 2.2 2.2 2.2h8.1c1.2 0 2.2-1 2.2-2.2V58c1.7 1.5 3.9 2.3 6.1 2.3.7 0 1.4-.1 2.2-.3 3.8-.9 6.7-3.9 7.6-7.9 2.3-.6 4.3-2.1 5.7-4.3s2-5 1.6-7.6c3.6-1.5 6.1-5.6 5.9-10M30.8 58.5c0 .1-.1.2-.2.2h-8.1c-.1 0-.2-.1-.2-.2v-7.7c0-.3-.1-.6-.4-.8-.2-.1-.4-.2-.6-.2H21l-5.6 1.7c-.3.1-.6.1-1 .1-2 0-3.6-1.6-3.6-3.6v-6.9c0-.6-.4-1-1-1H5.3c-.7 0-1.3-.6-1.3-1.3 0-.2.1-.5.2-.7l5.4-8.5c.9-1.4 1.3-2.9 1.3-4.6 0-5.3 2.1-10.2 5.8-14 3.7-3.7 8.7-5.8 14-5.8.1 0 .2.1.2.2v53.1zm23.6-19.7c-.5.2-.8.7-.7 1.2.6 2.4.1 4.9-1.2 6.9-1.2 1.9-3 3.1-5.1 3.5-.4.1-.7.4-.8.8-.6 3.5-2.9 6.1-6.2 6.9-3 .7-5.9-.4-7.8-3V8.8c1.8-2.3 4.4-3.5 7.2-3.1 3 .4 5.4 2.5 6.4 5.6.1.4.6.7 1.1.7 2.3 0 4.3 1 5.9 2.8 1.7 2 2.4 4.6 1.9 7.3-.1.4.1.8.5 1 2.5 1.4 4.1 4.2 4.2 7.2.4 3.8-1.9 7.3-5.4 8.5" />
  </svg>
);
export default IconIdeaHead;
