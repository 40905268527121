import * as React from "react";
const IconImm = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M24 42c9.941 0 18-8.059 18-18S33.941 6 24 6 6 14.059 6 24s8.059 18 18 18m0 2c11.046 0 20-8.954 20-20S35.046 4 24 4 4 12.954 4 24s8.954 20 20 20"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M24 31a7 7 0 1 0 0-14 7 7 0 0 0 0 14m0 2a9 9 0 1 0 0-18 9 9 0 0 0 0 18"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M20.576 28.424a3.5 3.5 0 0 1-1.29-1.538l4.6-4.6c.63.271 1.164.723 1.538 1.29zm5.235-4.063a3.5 3.5 0 0 1-4.45 4.45zm-2.842-2.33L19.03 25.97a3.5 3.5 0 0 1 3.938-3.938"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M20.433 28.325a3.5 3.5 0 0 1-1.148-1.439l4.601-4.6q.084.036.166.076a3.5 3.5 0 0 1 1.372 1.214l-4.848 4.848a4 4 0 0 1-.143-.1m5.378-3.964a3.5 3.5 0 0 1 .188 1.226 3.5 3.5 0 0 1-4.638 3.224zm-4.397-2.19A3.51 3.51 0 0 0 19 25.5a4 4 0 0 0 .031.469l3.938-3.938a3.5 3.5 0 0 0-1.555.14M17.81 15.639a3.5 3.5 0 0 0-4.45-4.45zm-6.525-2.525a3.5 3.5 0 0 1 1.29-1.538l4.85 4.848a3.5 3.5 0 0 1-1.539 1.29zm3.684 4.855q-.23.03-.469.031a3.5 3.5 0 0 1-3.469-3.969zM33.576 34.424a3.5 3.5 0 0 1-1.29-1.538l4.6-4.6c.63.271 1.164.723 1.538 1.29zm5.234-4.063a3.5 3.5 0 0 1-4.45 4.45zm-2.841-2.33L32.03 31.97a3.5 3.5 0 0 1 3.938-3.938"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M33.433 34.325a3.5 3.5 0 0 1-1.148-1.439l4.601-4.6a3.5 3.5 0 0 1 1.538 1.29l-4.848 4.848a4 4 0 0 1-.143-.1m5.377-3.964q.031.09.058.185A3.505 3.505 0 0 1 35.5 35a3.5 3.5 0 0 1-1.139-.19zm-4.396-2.19a3.5 3.5 0 0 0-2.383 3.797l3.938-3.937a3.55 3.55 0 0 0-1.555.14M31 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 2a3 3 0 1 0 0-6 3 3 0 0 0 0 6M13 33a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 2a3 3 0 1 0 0-6 3 3 0 0 0 0 6"
      clipRule="evenodd"
    />
  </svg>
);
export default IconImm;
