import * as React from "react";
const IconServer19 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.5 42.5c-4.3 0-7.8 3.5-7.8 7.8s3.5 7.8 7.8 7.8 7.8-3.5 7.8-7.8-3.5-7.8-7.8-7.8m0 13.5c-3.2 0-5.8-2.6-5.8-5.8s2.6-5.8 5.8-5.8 5.8 2.6 5.8 5.8c0 3.3-2.6 5.8-5.8 5.8" />
    <path d="m53.7 51.3-1.6-.6v-2.2c0-.6-.4-1-1-1s-1 .4-1 1v2.9c0 .4.3.8.6.9l2.2.8c.1 0 .2.1.4.1.4 0 .8-.2.9-.6.2-.5 0-1.1-.5-1.3M62 23.2V10c0-2.8-2.2-5-5-5H7c-2.8 0-5 2.2-5 5v13.2c0 1.6.8 3.1 2 4-1.2.9-2 2.4-2 4v13.2c0 2.8 2.2 5 5 5h31.3c.6 0 1-.4 1-1s-.4-1-1-1H7c-1.7 0-3-1.3-3-3V31.2c0-1.7 1.3-3 3-3h50c1.7 0 3 1.3 3 3v9.6c0 .6.4 1 1 1s1-.4 1-1v-9.6c0-1.6-.8-3.1-2-4 1.2-.9 2-2.4 2-4m-2 0c0 1.7-1.3 3-3 3H7c-1.7 0-3-1.3-3-3V10c0-1.7 1.3-3 3-3h50c1.7 0 3 1.3 3 3z" />
    <path d="M15.6 15.4c-.6 0-1 .4-1 1v.2c0 .6.4 1 1 1s1-.4 1-1v-.2c0-.6-.5-1-1-1M50.8 15.5H44c-.6 0-1 .4-1 1s.4 1 1 1h6.7c.6 0 1-.4 1-1s-.4-1-.9-1M14.6 37.5v.2c0 .6.4 1 1 1s1-.4 1-1v-.2c0-.6-.4-1-1-1s-1 .5-1 1M50.8 38.5c.6 0 1-.4 1-1s-.4-1-1-1H44c-.6 0-1 .4-1 1s.4 1 1 1z" />
  </svg>
);
export default IconServer19;
