import * as React from "react";
const IconHouseHeart = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.7 28c-.6 0-1 .4-1 1v27.3c0 1.8-1.4 3.2-3.2 3.2H12.6c-1.8 0-3.2-1.4-3.2-3.2V29c0-.6-.4-1-1-1s-1 .4-1 1v27.3c0 2.9 2.3 5.2 5.2 5.2h38.9c2.9 0 5.2-2.3 5.2-5.2V29c0-.5-.5-1-1-1M61.5 20.5 34.8 3.3c-1.7-1.1-3.9-1.1-5.7 0L2.5 20.5c-.5.3-.6.9-.3 1.4s.9.6 1.4.3L30.3 4.9c1.1-.7 2.4-.7 3.5 0l26.7 17.2c.2.1.4.2.5.2.3 0 .7-.2.8-.5.3-.4.2-1-.3-1.3" />
    <path d="m22.6 40.1 8.7 8.8c.2.2.4.3.7.3s.5-.1.7-.3l8.7-8.8.6-.6c1.4-1.4 2.1-3.2 2.1-5.1s-.7-3.7-2.1-5.1c-1.3-1.4-3.1-2.1-5-2.1s-3.6.7-5 2c-1.3-1.3-3.1-2-5-2s-3.7.8-5 2.1c-2.8 2.8-2.8 7.3 0 10.1zm.8-9.4q1.5-1.5 3.6-1.5c1.4 0 2.7.5 3.6 1.5l.6.6c.2.2.4.3.7.3s.5-.1.7-.3l.6-.6c1-1 2.3-1.5 3.6-1.5q2.1 0 3.6 1.5c1 1 1.5 2.3 1.5 3.7s-.5 2.7-1.5 3.7L32 46.7l-8-8-.6-.6c-2-2.1-2-5.3 0-7.4" />
  </svg>
);
export default IconHouseHeart;
