import * as React from "react";
const IconNoodles = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.5 31.8c-.6-.7-1.6-1.1-2.5-1.1H30.2c0-.1.1-.2.1-.3v-12l27.9-2.2c.6 0 1-.5.9-1.1 0-.6-.5-1-1.1-.9l-27.8 2.2V9.2l28-5.2c.5-.1.9-.6.8-1.2 0-.5-.5-.9-1-.8L30.3 7.1v-.5c0-.6-.4-1-1-1s-1 .4-1 1v1l-9.8 1.8V6.6c0-.6-.4-1-1-1s-1 .4-1 1v3.2l-9.6 1.8c-.5.1-.9.6-.8 1.2.1.5.5.8 1 .8h.2l9.2-1.7v5.7l-9.5.6c-.6 0-1 .5-.9 1.1 0 .5.5.9 1 .9h.1l9.3-.7v10.9c0 .1 0 .2.1.3H6c-1 0-1.9.4-2.5 1.1s-1 1.6-.9 2.6c.9 9.2 6.2 17.4 14.2 22v.9c0 2.5 2.1 4.6 4.6 4.6h21.2c2.5 0 4.6-2.1 4.6-4.6v-.9c2.2-1.2 4.2-2.8 6-4.6 4.7-4.7 7.6-10.9 8.2-17.5.1-.8-.2-1.8-.9-2.5m-42-20.4 9.8-1.8v7l-9.8.8zm0 19v-11l9.8-.8v11.8c0 .1 0 .2.1.3h-9.9zm33.3 20.1q-2.7 2.7-6 4.5c-.3.2-.5.5-.5.9v1.5c0 1.4-1.2 2.6-2.6 2.6H21.4c-1.4 0-2.6-1.2-2.6-2.6v-1.5c0-.4-.2-.7-.5-.9-5.1-2.8-9-7.1-11.4-12.2h50.2c-1.3 2.8-3.1 5.4-5.3 7.7m6.1-9.7H6.1c-.8-2.1-1.3-4.3-1.5-6.5 0-.4.1-.8.4-1 .3-.3.7-.5 1.1-.5h52c.4 0 .8.2 1.1.5s.4.7.4 1c-.4 2.2-.9 4.4-1.7 6.5" />
  </svg>
);
export default IconNoodles;
