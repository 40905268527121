import * as React from "react";
const IconMerge = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.7 31.3 49.5 19.9c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l9.5 9.7H34.5L24 12.9c-.2-.3-.5-.5-.9-.5H4c-.6 0-1 .4-1 1s.4 1 1 1h18.6L32.8 32 22.6 49.6H4c-.6 0-1 .4-1 1s.4 1 1 1h19.2c.4 0 .7-.2.9-.5L34.5 33h23.1L48 42.7c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3l11.2-11.4c.5-.4.5-1 .1-1.4" />
  </svg>
);
export default IconMerge;
