import * as React from "react";
const IconBasketball = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.6 14.5c-3.8-5.3-9.3-9.3-15.5-11.3Q34.95 1.25 28.2 2c-5.2.7-9.9 2.5-13.9 5.4Q8 11.9 4.7 18.8c-1.9 4.1-3 8.4-3 12.5-.1 6.6 1.8 13 5.6 18.3 3.9 5.4 9.2 9.3 15.5 11.3 3 .9 6 1.4 9.1 1.4 1.2 0 2.5-.1 3.7-.2 5.2-.7 9.9-2.5 13.9-5.4q6.3-4.5 9.6-11.4c1.9-4.1 3-8.4 3-12.5.3-6.6-1.7-12.9-5.5-18.3m1.1 18.2c0 1.3-.2 2.6-.4 3.9-5.5.6-10.7 2.4-15.4 5.4l-6.7-9.4 19-13.6c2.4 4.2 3.6 8.8 3.5 13.7m-6.1-17.3L32.6 29 26 19.7c4.3-3.5 7.8-8 10.2-13 1.2.2 2.4.5 3.6.8 4.6 1.4 8.7 4.2 11.8 7.9m-22.8-9c.8-.1 1.7-.1 2.5-.1-2 3.7-4.7 7-8 9.7l-4.4-6.2c3-1.8 6.3-2.9 9.9-3.4m-13.5 6 4.4 6.2C16.1 20.8 12 22.3 7.9 23c.3-.8.6-1.6 1-2.3 1.5-3.2 3.7-6 6.4-8.3m-9 18.9c0-1.2.1-2.4.4-3.6 5.6-.6 10.9-2.5 15.7-5.5l6.6 9.3-19 13.6C7.4 41 6.2 36.2 6.3 31.3m6.2 17.5 19-13.6 6.7 9.4c-4.3 3.5-7.8 7.9-10.2 12.9-1.3-.2-2.6-.5-3.8-.9-4.6-1.5-8.6-4.1-11.7-7.8m22.7 8.8c-.7.1-1.5.1-2.2.2 2-3.6 4.7-6.8 7.9-9.5l4.2 6c-3 1.6-6.3 2.8-9.9 3.3m13.5-6-4.2-5.9C48 43.5 51.9 42 56 41.3c-.3.7-.5 1.3-.8 2-1.6 3.2-3.8 6-6.5 8.3" />
  </svg>
);
export default IconBasketball;
