import * as React from "react";
const IconMist = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M19 32.7h20c7 0 12.8-5.6 12.8-12.5 0-3.1-1.2-6.2-3.4-8.5-1.9-2-4.6-3.4-7.4-3.8-1.5-2.5-3.8-4.3-6.4-5.2-1.2-.4-2.5-.7-4-.7C24 2 18.5 7.3 18.4 13.8c-5 .3-9 4.4-9 9.4 0 5.2 4.3 9.5 9.6 9.5m0-17h.4c.6 0 1-.4 1-1V14c0-5.5 4.6-10 10.2-10q1.8 0 3.3.6c2.4.8 4.4 2.5 5.6 4.7.2.3.4.5.8.5 2.5.3 5 1.5 6.6 3.3 1.8 2 2.9 4.5 2.9 7.1C49.8 26 45 30.7 39 30.7H19c-4.3 0-7.6-3.3-7.6-7.5s3.4-7.5 7.6-7.5M8.3 48.1l5.5-4c1.2-.9 2.9-.9 4.1 0l2.8 2.1c1.9 1.4 4.5 1.4 6.5 0l2.8-2.1c1.2-.9 2.9-.9 4.1 0l2.8 2.1c1.9 1.4 4.5 1.4 6.5 0l2.8-2.1c1.2-.9 2.9-.9 4.1 0l5.5 4c.2.1.4.2.6.2.3 0 .6-.1.8-.4.3-.4.2-1.1-.2-1.4l-5.5-4c-1.9-1.4-4.5-1.4-6.5 0l-2.8 2.1c-1.2.9-2.9.9-4.1 0l-2.8-2.1c-1.9-1.4-4.5-1.4-6.5 0L26 44.6c-1.2.9-2.9.9-4.1 0L19 42.5c-1.9-1.4-4.5-1.4-6.5 0l-5.5 4c-.4.3-.5 1-.2 1.4.4.5 1.1.6 1.5.2M51.4 56.2c-1.9-1.4-4.5-1.4-6.5 0l-2.8 2.1c-1.2.9-2.9.9-4.1 0l-2.8-2.1c-1.9-1.4-4.5-1.4-6.5 0l-2.8 2.1c-1.2.9-2.9.9-4.1 0L19 56.2c-1.9-1.4-4.5-1.4-6.5 0l-5.5 4c-.4.3-.5 1-.2 1.4s1 .5 1.4.2l5.5-4c1.2-.9 2.9-.9 4.1 0l2.8 2.1c1.9 1.4 4.5 1.4 6.5 0l2.8-2.1c1.2-.9 2.9-.9 4.1 0l2.8 2.1c1.9 1.4 4.5 1.4 6.5 0l2.8-2.1c1.2-.9 2.9-.9 4.1 0l5.5 4c.2.1.4.2.6.2.3 0 .6-.1.8-.4.3-.4.2-1.1-.2-1.4z" />
  </svg>
);
export default IconMist;
