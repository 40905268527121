import * as React from "react";
const IconHandbag4 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M40 27.2C39.7 14.5 37 2 32 2s-7.7 12.5-8 25.2c-6 .7-10.7 5.8-10.7 12v17.1c0 3.2 2.6 5.7 5.7 5.7h25.8c3.2 0 5.7-2.6 5.7-5.7V39.2c.1-6.2-4.5-11.3-10.5-12M32 4c2.4 0 5.7 8.8 6 23.1H26C26.3 12.8 29.6 4 32 4m-6.6 25.1h13.2c5.5 0 10.1 4.5 10.1 10.1v.8c-3.4 1.1-9.8 1.9-16.6 1.9-6.9 0-13.3-.7-16.6-1.9v-.8c-.1-5.6 4.4-10.1 9.9-10.1M44.9 60H19.1c-2.1 0-3.7-1.7-3.7-3.7V42c3.7 1.1 9.9 1.8 16.6 1.8 6.8 0 12.9-.7 16.6-1.8v14.2c0 2.1-1.6 3.8-3.7 3.8" />
  </svg>
);
export default IconHandbag4;
