import * as React from "react";
const IconIceCreamAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2c-6.6 0-12 5.4-12 12v29.2c0 1.7 1.3 3 3 3h8V61c0 .6.4 1 1 1s1-.4 1-1V46.2h8c1.7 0 3-1.3 3-3V14c0-6.6-5.4-12-12-12m10 41.2c0 .6-.4 1-1 1H23c-.6 0-1-.4-1-1V14c0-5.5 4.5-10 10-10s10 4.5 10 10z" />
  </svg>
);
export default IconIceCreamAlt;
