import * as React from "react";
const IconApartmentsAlt7 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 50.8h-4.1V22.7c0-1.4-.6-2.7-1.7-3.6l-8.3-6.8c-1.7-1.4-4.2-1.4-5.9 0l-9 7.3-9-7.3c-1.7-1.4-4.2-1.4-5.9 0l-8.3 6.8a4.6 4.6 0 0 0-1.7 3.6v28.1H3c-.6 0-1 .4-1 1s.4 1 1 1h58c.6 0 1-.4 1-1s-.4-1-1-1m-43.7 0v-6.1c0-1.5 1.2-2.8 2.8-2.8s2.8 1.2 2.8 2.8v6.1zm7.5 0v-6.1c0-2.6-2.1-4.8-4.8-4.8-2.6 0-4.8 2.1-4.8 4.8v6.1H9.1V22.7c0-.8.4-1.6 1-2.1l8.3-6.8c1-.8 2.4-.8 3.3 0l9.3 7.6v29.3h-6.2zm16.4 0v-6.1c0-1.5 1.2-2.8 2.8-2.8s2.8 1.2 2.8 2.8v6.1zm7.6 0v-6.1c0-2.6-2.1-4.8-4.8-4.8s-4.8 2.1-4.8 4.8v6.1H33V21.4l9.3-7.6c1-.8 2.4-.8 3.3 0l8.3 6.8c.6.5 1 1.3 1 2.1v28.1z" />
  </svg>
);
export default IconApartmentsAlt7;
