import * as React from "react";
const IconGorilla = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M27.5 62H22c-.6 0-1-.4-1-1v-2c0-1.5-2.1-3.5-4-4.7-1.1-.7-1.8-1.9-1.8-3.2v-4c0-.3.1-.5.3-.7 1.7-1.8 3-3.9 4-6.2.2-.5.8-.7 1.3-.5s.8.8.5 1.3c-1 2.4-2.4 4.6-4.1 6.5v3.6c0 .6.3 1.2.8 1.5 2.3 1.4 5 3.9 5 6.4v1h6.4q.6-.15.6-.6c.1-.2.1-.5-.2-.8l-3.9-3.9c-.2-.2-.3-.5-.3-.8l.7-10.9c0-.3.2-.6.4-.7.2-.2.5-.2.8-.2 3.8 1 7.9.8 11.6-.4.3-.1.6-.1.8.1.2.1.4.4.5.7.5 2.2.9 3.8 1.5 5.8v4.5c0 .3-.2.6-.4.8l-.9.6c-1.4 1-1.6 2.5-1.3 3.6s1.3 2.3 3 2.3h3c1.5 0 2.8-1.1 3.1-2.5l1.9-9.1c.9-4.4 1.3-6.1 1.3-6.5v-.1c-.6-4.9-.7-9.6-.3-14.8 0-.4.4-.8.8-.9l4.1-.9c1.6-.4 2.9-1.5 3.4-3.1s0-3.3-1.1-4.5l-2-2c-.2-.2-.3-.4-.3-.7v-3.8c0-.2-.1-.4-.2-.6l-4.4-4.4C50.3 4.8 48.5 4 46.5 4s-3.8.8-5.2 2.2l-4.4 4.4c-4.7 4.7-13.2 7.9-21 7.9C9.4 18.5 4 23.8 4 30.4v4.4c0 2.7 0 2.8.1 3v.2c1.7 6.9 2.6 14 2.6 21.2 0 .4.4.8.8.8h7c.6 0 1 .4 1 1s-.4 1-1 1h-7c-1.6 0-2.8-1.3-2.8-2.8 0-7-.9-14-2.6-20.7v-.2c-.1-.5-.1-.5-.1-3.5v-4.4c0-7.7 6.2-13.9 13.9-13.9 7.3 0 15.2-3 19.5-7.3l4.4-4.4C41.6 3 44 2 46.5 2s4.9 1 6.6 2.7l4.4 4.4c.5.5.8 1.2.8 2v3.4l1.7 1.7c1.7 1.7 2.3 4.2 1.6 6.5s-2.5 4-4.9 4.5l-3.2.8c-.4 4.7-.3 9.1.3 13.6.1.4.1.4-1.4 7.2l-1.9 9.1c-.5 2.4-2.6 4.1-5.1 4.1h-3c-2.3 0-4.3-1.4-4.9-3.7-.7-2.2.1-4.5 2.1-5.8l.4-.3v-3.5c-.5-1.6-.9-3.1-1.3-4.8-3.4 1-7.1 1.1-10.5.4l-.6 9.2 3.6 3.6c.8.8 1.1 1.9.8 2.9s-1.1 1.7-2.2 1.9c-.2.1-.2.1-2.3.1m24.4-20" />
  </svg>
);
export default IconGorilla;
