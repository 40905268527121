import * as React from "react";
const IconChildCare = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M17.658 29.008c-.304.018-.474.062-.538.09-.634.29-2.715 1.045-4.12 1.548v7.1c.72-.033 1.53-.061 1.985-.043 2.022.081 3.549.552 4.976 1.005l.126.04c1.378.438 2.664.847 4.355.975.519.04.972.085 1.396.128a30 30 0 0 0 1.515.129c.86.044 1.52-.003 2.054-.199.66-.242 3.04-1.355 5.43-2.568a84 84 0 0 0 3.272-1.739 24 24 0 0 0 1.165-.696c.327-.213.485-.34.528-.384.178-.183.305-.688.072-1.143-.162-.317-.614-.781-1.884-.571-1.47.243-3.218.96-4.935 1.664-.833.342-1.662.681-2.395.926-.704.235-1.46.434-2.108.395-.94-.057-2.596-.305-3.97-.529a156 156 0 0 1-2.362-.404l-.153-.027-.053-.01.179-.984-.18.984.192-1.984 5.36.065q.007 0 .022-.002c.036-.003.108-.012.212-.039.204-.053.54-.177.956-.475.144-.103.32-.282.479-.514.13-.19.227-.381.284-.543-.799-.023-1.583-.157-2.168-.285a15 15 0 0 1-1.022-.262l-.046-.014-6.566-1.473h-.002a9 9 0 0 0-.314-.05c-.212-.031-.503-.068-.818-.093a7 7 0 0 0-.925-.018m2.257-.82.183-.982.018.003 6.688 1.5.041.014.012.004.046.014.188.054c.165.046.403.107.686.169.574.126 1.298.242 1.974.242.624 0 1.269.233 1.614.865.296.542.227 1.138.116 1.55-.138.514-.424 1.054-.787 1.513.49-.185 1.026-.404 1.602-.64l.123-.05c1.623-.667 3.547-1.457 5.244-1.737 1.94-.321 3.351.383 3.991 1.632.57 1.112.438 2.571-.42 3.45-.215.221-.543.453-.872.666a26 26 0 0 1-1.266.76 86 86 0 0 1-3.354 1.782c-2.371 1.203-4.857 2.373-5.647 2.662-.916.335-1.9.367-2.845.318a38 38 0 0 1-1.708-.145c-.43-.043-.858-.085-1.25-.115-1.928-.146-3.408-.617-4.77-1.05l-.166-.053c-1.407-.447-2.716-.843-4.451-.913-.59-.023-2.057.053-2.847.099L11 39.862V29.235l.665-.236c1.355-.482 3.982-1.427 4.625-1.72.402-.183.867-.245 1.252-.268a9 9 0 0 1 1.195.021 14 14 0 0 1 1.327.168l.024.004.006.001h.004z"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M6 29a2 2 0 0 1 2-2h3a2 2 0 0 1 2 2v10.996a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2zm2 0v10.996h3V29.001zM19.299 19.079l.02-.02a.643.643 0 0 0-.913-.908l-1.96 1.973a2 2 0 0 1-1.42.59h-2.585a.643.643 0 0 0 0 1.286h2.76a3 3 0 0 0 2.157-.915zm-4.272-.366H12.44a2.643 2.643 0 0 0 0 5.287h2.76a5 5 0 0 0 3.595-1.524l1.94-2.007a2.643 2.643 0 1 0-3.748-3.728zM32.74 12.998a2.5 2.5 0 1 0 0-4.998 2.5 2.5 0 0 0 0 4.998m4.5-2.499a4.5 4.5 0 1 1-8.998 0 4.5 4.5 0 0 1 8.998 0M29.718 23.294l.073.072a2.3 2.3 0 0 0 1.63.622l.039.003q.018.004.039.004l3.137-.004a2.667 2.667 0 0 0-.005-5.334h-1.956c-.2 0-1.65-1.527-2.73-2.697a4.07 4.07 0 0 0-2.985-1.316h-4.856l.468 9.35h3.083a4.7 4.7 0 0 0 1.816-.371c.574-.245 1.454-.979 1.526-1.051.068-.068.654.641.716.716zm-3.032-1.51.047-.025q.053-.03.136-.082c.112-.07.237-.158.361-.25a9 9 0 0 0 .404-.319c.68-.637 1.485-.563 1.8-.496.328.07.561.206.646.257a2.6 2.6 0 0 1 .403.304c.148.133.29.287.385.392.106.117.205.232.275.315l.04.048a.3.3 0 0 0 .068.04m-4.565-.185c-.327.139-.678.21-1.033.212h-1.179l-.267-5.351h2.753c.564 0 1.115.24 1.515.673.545.59 1.196 1.284 1.744 1.837.27.273.538.535.766.739.11.097.245.214.388.317.069.05.181.127.322.201l.006.003c.082.044.46.243.974.243h1.956a.667.667 0 0 1 .002 1.334l-3.04.003a2 2 0 0 0-.172-.006h-.052a.3.3 0 0 1-.118-.02"
      clipRule="evenodd"
    />
  </svg>
);
export default IconChildCare;
