import * as React from "react";
const IconSchoolBenchAltCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 17.4c-.6 0-1 .4-1 1V28H42.5c-2 0-3.6 1.6-3.6 3.6v5.3c0 1.7 1.1 3 2.6 3.5v9.7c0 .6.4 1 1 1s1-.4 1-1v-9.5H60v9.5c0 .6.4 1 1 1s1-.4 1-1V18.4c0-.5-.4-1-1-1M40.9 36.9v-5.3c0-.9.7-1.6 1.6-1.6H60v8.5H42.5c-.9 0-1.6-.7-1.6-1.6M30.4 19.6 6.3 13c-1-.3-2-.1-2.8.6-.9.7-1.4 1.8-1.4 3v5.1c0 1.7 1.1 3.1 2.6 3.5l2.7.7v24.2c0 .6.4 1 1 1s1-.4 1-1V26.5L25.7 31v19.1c0 .6.4 1 1 1s1-.4 1-1V31.5l1 .3c.3.1.6.1.9.1.7 0 1.4-.2 2-.7.9-.7 1.4-1.8 1.4-3v-5.1c0-1.6-1.1-3.1-2.6-3.5m.6 8.7c0 .5-.2 1.1-.6 1.4-.2.1-.6.4-1.1.2L5.1 23.3c-.7-.2-1.1-.9-1.1-1.6v-5.1c0-.5.2-1.1.6-1.4.2-.2.6-.4 1.1-.2l24.2 6.6c.6.2 1.1.8 1.1 1.6z" />
  </svg>
);
export default IconSchoolBenchAltCopy;
