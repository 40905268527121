import * as React from "react";
const IconAf = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 513 342"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 0h513v342H0z" />
    <path  d="M331 0h182v342H331z" />
    <path d="M0 0h181.8v342H0z" />
    <path
      
      d="M256 126.7c-19.4 0-35.2 15.8-35.2 35.2v52.8h70.4v-52.8c0-19.5-15.8-35.2-35.2-35.2"
    />
    <path
      
      d="M256 84.3c-47.7 0-86.4 38.7-86.4 86.4S208.3 257 256 257s86.4-38.7 86.4-86.4-38.7-86.3-86.4-86.3m0 157.7c-39.4 0-71.4-32-71.4-71.4s32-71.4 71.4-71.4 71.4 32 71.4 71.4c0 39.5-32 71.4-71.4 71.4"
    />
  </svg>
);
export default IconAf;
