import * as React from "react";
const IconMedalFirstAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m56.8 54.9-7.9-19.6c2.6-3.5 4.1-7.7 4.1-12.4C52.9 11.4 43.5 2 32 2s-20.9 9.4-20.9 20.9c0 4.6 1.5 8.9 4.1 12.4l-8 19.6c-.1.3-.1.7.1 1s.5.4.9.4l8.1-.6 5.4 6c.2.2.5.3.7.3h.2c.3-.1.6-.3.8-.6l7.1-17.6c.5 0 1 .1 1.5.1s1 0 1.5-.1l7.1 17.6c.1.3.4.6.8.6h.2c.3 0 .6-.1.7-.3l5.4-6 8.1.6c.3 0 .7-.1.9-.4.1-.4.2-.7.1-1m-34.7 4.2L17.5 54c-.2-.2-.5-.4-.8-.3l-7 .5L16.6 37c3.1 3.3 7.2 5.7 11.8 6.5zm-9-36.2C13.1 12.5 21.6 4 32 4s18.9 8.5 18.9 18.9S42.4 41.8 32 41.8s-18.9-8.5-18.9-18.9m34.2 30.7c-.3 0-.6.1-.8.3l-4.6 5.2-6.3-15.6c4.6-.8 8.8-3.1 11.8-6.5l6.9 17.1z" />
    <path d="M32.4 16.2c-.3-.2-.8-.1-1.1.1l-5.7 4.6c-.4.3-.5 1-.1 1.4.3.4 1 .5 1.4.1l4-3.3V33c0 .6.4 1 1 1s1-.4 1-1V17.1c.1-.4-.1-.7-.5-.9" />
  </svg>
);
export default IconMedalFirstAlt;
