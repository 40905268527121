import * as React from "react";
const IconLockAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M43 21.5c0-.1.1-.2.1-.4V13c0-3-1.1-5.7-3.2-7.8C37.8 3.2 35 2 32.1 2c-3.4 0-6.6 1.6-8.7 4.3-.3.4-.3 1.1.2 1.4.4.3 1 .2 1.3-.2C26.7 5.3 29.3 4 32.1 4c2.4 0 4.7.9 6.4 2.7 1.7 1.7 2.6 4 2.6 6.4v7.4c-2.8-1.3-5.8-2-9.1-2-12 0-21.7 9.8-21.7 21.8S20 62 32 62s21.7-9.8 21.7-21.8c0-7.9-4.3-14.9-10.7-18.7M32 60c-10.9 0-19.7-8.9-19.7-19.8S21.1 20.4 32 20.4s19.7 8.9 19.7 19.8S42.9 60 32 60" />
    <path d="M32 41.2c-.6 0-1 .4-1 1v8.7c0 .6.4 1 1 1s1-.4 1-1v-8.7c0-.5-.4-1-1-1" />
  </svg>
);
export default IconLockAlt1;
