import React, { useState } from 'react';
import { useNavigation } from 'context/NavigationContext';
import {
	BringzzPageContainer,
	BringzzCountryDropdown,
	BringzzButton,
	BringzzIcon,
	BringzzText,
	BringzzInput
} from '@bringzz/components';
import useUser from 'hooks/useUser';
import useRequest from 'hooks/useRequest';

const EditPharmacyAddress = () => {
	const { backPress, navigate } = useNavigation();
	const { sendRequest, loading } = useRequest();
	const { getUser } = useUser();
	const user = getUser();
	const [pharmacyAddressStreetName, setPharmacyAddressStreetName] =
		useState('');
	const [pharmacyAddressHouseNumber, setPharmacyAddressHouseNumber] =
		useState('');
	const [pharmacyAddressEntrance, setPharmacyAddressEntrance] = useState('');
	const [pharmacyAddressFloor, setPharmacyAddressFloor] = useState('');
	const [pharmacyAddressDoor, setPharmacyAddressDoor] = useState('');
	const [pharmacyAddressPostalCode, setPharmacyAddressPostalCode] =
		useState('');
	const [pharmacyAddressCity, setPharmacyAddressCity] = useState('');
	const [pharmacyAddressCountry, setPharmacyAddressCountry] = useState('');

	return (
		<BringzzPageContainer
			header={{
				left: (
					<BringzzIcon
						size='22'
						icon='IconChevronLeft'
						folder='LineIcons'
						className='cursor-pointer'
						onClick={backPress}
					/>
				),
				title: <BringzzText tag='h2'>Edit address</BringzzText>
			}}
			footer={
				<div className='pb-28 px-6'>
					<BringzzButton
						size='lg'
						className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
						onClick={async () => {
							await sendRequest({
								method: 'PATCH',
								url: `/pharmacy/info/address`,
								data: {
									id: user.pharmacy.address.id,
									street: pharmacyAddressStreetName,
									housenumber: pharmacyAddressHouseNumber,
									entrance: pharmacyAddressEntrance,
									floor: pharmacyAddressFloor,
									door: pharmacyAddressDoor,
									postalcode: pharmacyAddressPostalCode,
									city: pharmacyAddressCity,
									country: pharmacyAddressCountry
								}
							})
								.then(response => {
									if (response.status === 'success')
										navigate(`/settings/pharmacy/info`, false);
								})
								.catch(error => {
									console.log(error);
								});
						}}
						disabled={
							loading ||
							(!pharmacyAddressStreetName &&
								!pharmacyAddressHouseNumber &&
								!pharmacyAddressPostalCode &&
								!pharmacyAddressCity &&
								!pharmacyAddressCountry &&
								!pharmacyAddressEntrance &&
								!pharmacyAddressFloor &&
								!pharmacyAddressDoor)
						}
					>
						Save
					</BringzzButton>
				</div>
			}
		>
			<div className='p-6 space-y-4'>
				<div className='flex flex-1 gap-3'>
					<BringzzInput
						onChange={(e, isValid, newValue) => {
							setPharmacyAddressStreetName(newValue);
						}}
						defaultValue={user.pharmacy.address.street}
						id='streename'
						label='Street Name'
						containerClassname='w-full'
					/>
					<BringzzInput
						onChange={(e, isValid, newValue) => {
							setPharmacyAddressHouseNumber(newValue);
						}}
						defaultValue={user.pharmacy.address.housenumber}
						id='housenumber'
						label='House Number'
						containerClassname='w-full'
					/>
				</div>
				<div className='flex flex-1 gap-3'>
					<BringzzInput
						onChange={(e, isValid, newValue) => {
							setPharmacyAddressEntrance(newValue);
						}}
						defaultValue={user.pharmacy.address.entrance}
						id='entrance'
						label='Entrance (opt.)'
						containerClassname='w-full'
						placeholder='Entrance'
					/>
					<BringzzInput
						onChange={(e, isValid, newValue) => {
							setPharmacyAddressFloor(newValue);
						}}
						defaultValue={user.pharmacy.address.floor}
						id='floor'
						label='Floopr (opt.)'
						type='number'
						containerClassname='w-full'
						placeholder='Floor'
					/>
					<BringzzInput
						onChange={(e, isValid, newValue) => {
							setPharmacyAddressDoor(newValue);
						}}
						defaultValue={user.pharmacy.address.door}
						id='door'
						label='Door (opt.)'
						type='number'
						containerClassname='w-full'
						placeholder='Door'
					/>
				</div>
				<div className='flex flex-1 gap-3'>
					<BringzzInput
						onChange={(e, isValid, newValue) => {
							setPharmacyAddressPostalCode(newValue);
						}}
						defaultValue={user.pharmacy.address.postalcode}
						id='postalcode'
						label='Postal Code'
						containerClassname='w-full'
					/>
					<BringzzInput
						onChange={(e, isValid, newValue) => {
							setPharmacyAddressCity(newValue);
						}}
						defaultValue={user.pharmacy.address.city}
						id='city'
						label='City'
						containerClassname='w-full'
					/>
				</div>

				<div className=''>
					<BringzzText tag='h4' className='font-bold'>
						Country
					</BringzzText>
					<BringzzCountryDropdown
						className='!rounded-r-none p-2 bg-[#F4F4F4]'
						mode={'flag-name'}
						onCountryChange={result => {
							setPharmacyAddressCountry(result);
						}}
					/>
				</div>
			</div>
		</BringzzPageContainer>
	);
};

export default EditPharmacyAddress;
