import * as React from "react";
const IconPoundCoin = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M41.7 43.1H28.5c1.3-1.3 2.1-3.2 2.1-5.3V33h8.7c.6 0 1-.4 1-1s-.4-1-1-1h-8.7v-3.8c0-3.5 2.5-6.3 5.5-6.3 1.4 0 2.7.6 3.8 1.7.4.4 1 .4 1.4 0s.4-1 0-1.4c-1.4-1.5-3.3-2.3-5.2-2.3-4.1 0-7.5 3.7-7.5 8.3V31h-6.3c-.6 0-1 .4-1 1s.4 1 1 1h6.3v4.8c0 2.9-2.1 5.3-4.7 5.3h-1.6c-.6 0-1 .4-1 1s.4 1 1 1h19.3c.6 0 1-.4 1-1s-.4-1-.9-1" />
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
  </svg>
);
export default IconPoundCoin;
