import * as React from "react";
const IconCornerRightDown = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.7 41.3c-.4-.4-1-.4-1.4 0L44.2 56.4V12.5c0-4.6-3.7-8.3-8.3-8.3H4c-.6 0-1 .4-1 1s.4 1 1 1h31.9c3.5 0 6.3 2.8 6.3 6.3v43.9L27.1 41.3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l16.8 16.8c.2.2.5.3.7.3s.5-.1.7-.3l16.8-16.8c.4-.4.4-1 0-1.4" />
  </svg>
);
export default IconCornerRightDown;
