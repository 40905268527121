import * as React from "react";
const IconGrammer = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M19.3 21.8V11.7c0-2.4-1.9-4.3-4.3-4.3H4.6c-1.2 0-2.1.9-2.1 2.1v2c0 .6.4 1 1 1s1-.4 1-1v-2c0-.1 0-.1.1-.1H15c1.3 0 2.3 1 2.3 2.3v6.7H6c-2.2 0-4 1.8-4 4v7.3c0 2.2 1.8 4 4 4h9.3c2.2 0 4-1.8 4-4zm-2 8c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2v-7.3c0-1.1.9-2 2-2h11.3v9.3M29 33.8h9.3c2.2 0 4-1.8 4-4v-7.3c0-2.2-1.8-4-4-4H27v-14c0-.6-.4-1-1-1s-1 .4-1 1v25.299999999999997c0 2.2 1.8 4 4 4m9.3-13.4c1.1 0 2 .9 2 2v7.3c0 1.1-.9 2-2 2H29c-1.1 0-2-.9-2-2v-9.3zM61 31.8h-8.8c-1.3 0-2.3-1-2.3-2.3v-6.7c0-1.3 1-2.3 2.3-2.3H61c.6 0 1-.4 1-1s-.4-1-1-1h-8.8c-2.4 0-4.3 1.9-4.3 4.3v6.7c0 2.4 1.9 4.3 4.3 4.3H61c.6 0 1-.4 1-1s-.4-1-1-1M43.6 43.1l-15 15-8.2-8.1c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l8.9 8.9c.2.2.5.3.7.3s.5-.1.7-.3L45 44.6c.4-.4.4-1 0-1.4s-1-.4-1.4-.1" />
  </svg>
);
export default IconGrammer;
