import * as React from "react";
const IconShipWarning = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M35.9 57.3c-.2-.2-.4-.4-.7-.5l4.1-14.6c.7-2.3-.5-4.8-2.8-5.7l-2.9-1.2v-4.4c0-.6-.4-1-1-1s-1 .4-1 1v3.5L23 30.8c-1.2-.5-2.5-.5-3.7 0l-8.8 3.7v-6.6c0-1.5 1.3-2.8 2.8-2.8h12.4c.6 0 1-.4 1-1s-.4-1-1-1H15v-5.3c0-1.5 1.3-2.8 2.8-2.8h7.3c.6 0 1-.4 1-1s-.4-1-1-1h-3V8.3c0-.6-.4-1-1-1s-1 .4-1 1V13h-2.3c-2.6 0-4.8 2.2-4.8 4.8v5.4c-2.5.1-4.5 2.2-4.5 4.8v7.4l-2.9 1.2c-2.2.9-3.4 3.4-2.8 5.7l4.1 14.6c-.2.1-.5.3-.7.5l-3.4 2.9c-.4.4-.5 1-.1 1.4s1 .5 1.4.1l3.4-2.9c1-.9 2.6-.9 3.7 0l.9.8c1.8 1.5 4.5 1.5 6.3 0l.9-.8c1-.9 2.6-.9 3.7 0l.9.8c1.8 1.6 4.5 1.6 6.3 0l.9-.8c1-.9 2.6-.9 3.7 0l3.4 2.9c.2.2.4.2.7.2s.6-.1.8-.3c.4-.4.3-1-.1-1.4zm-6.2 0-.9.8c-1 .9-2.6.9-3.7 0l-.9-.8c-1.8-1.5-4.5-1.5-6.3 0l-.9.8c-1 .9-2.6.9-3.7 0l-.9-.8c-1-.9-2.4-1.3-3.7-1.1l-4-14.5c-.4-1.4.3-2.8 1.6-3.3L9.8 37h.1L20 32.7c.7-.3 1.5-.3 2.2 0l10.1 4.2h.1l3.5 1.4c1.3.5 2 2 1.6 3.3l-4.1 14.5c-1.4 0-2.7.3-3.7 1.2M60.9 18.8 53.1 4.5C52.2 2.9 50.6 2 48.9 2c-1.8 0-3.4.9-4.2 2.5l-7.8 14.3c-.8 1.5-.8 3.3.1 4.8s2.4 2.4 4.1 2.4h15.6c1.7 0 3.3-.9 4.1-2.4.9-1.5.9-3.3.1-4.8m-1.8 3.7c-.5.9-1.4 1.4-2.4 1.4H41c-1 0-1.9-.5-2.4-1.4s-.5-1.9 0-2.8l7.8-14.3c.5-.9 1.4-1.4 2.5-1.4 1 0 2 .5 2.5 1.5l7.8 14.3c.4.8.4 1.9-.1 2.7" />
    <path d="M48.9 10.8c-.6 0-1 .4-1 1v1.8c0 .6.4 1 1 1s1-.4 1-1v-1.8c0-.6-.5-1-1-1M48.9 17.9c-.6 0-1 .5-1 1s.4 1 1 1 1-.4 1-1v-.1c0-.5-.5-.9-1-.9" />
  </svg>
);
export default IconShipWarning;
