import * as React from "react";
const IconEmpireStateBuilding = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.4 60h-9.5V39.6c0-2.2-1.8-4-4-4h-4V25.1c0-2.7-2.2-5-5-5h-.3v-2.3c0-2.3-1.6-4.2-3.7-4.8V3c0-.6-.4-1-1-1s-1 .4-1 1v9.9c-2.5.3-4.4 2.4-4.4 4.9v2.3h-.3c-2.7 0-5 2.2-5 5v10.4h-4c-2.2 0-4 1.8-4 4V60H4.6c-.6 0-1 .4-1 1s.4 1 1 1h54.9c.6 0 1-.4 1-1s-.5-1-1.1-1M28.6 17.8c0-1.6 1.3-3 3-3h.2c1.6 0 3 1.3 3 3v2.3h-6.1v-2.3zm-2.3 4.4H37c1.6 0 3 1.3 3 3v10.5c-2 .2-3.6 1.9-3.6 4V60H27V39.6c0-2.1-1.6-3.7-3.6-4V25.1c0-1.6 1.3-2.9 2.9-2.9M15.4 39.6c0-1.1.9-2 2-2H23c1.1 0 2 .9 2 2V60h-9.5V39.6zM41 60h-2.6V39.6c0-1.1.9-2 2-2H46c1.1 0 2 .9 2 2V60z" />
  </svg>
);
export default IconEmpireStateBuilding;
