import * as React from "react";
const IconWaterDrops = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M18.3 5.8c-.7 0-1.3.3-1.8.8-4.2 4.8-14 16.9-14 23.9 0 8.7 7.1 15.8 15.8 15.8s15.8-7.1 15.8-15.8c0-7-9.8-19.1-14-23.9-.5-.5-1.1-.8-1.8-.8m0 38.6c-7.6 0-13.8-6.2-13.8-13.8 0-5.2 7-15.1 13.5-22.6.1-.1.2-.1.3-.1s.2 0 .3.1c6.5 7.5 13.5 17.4 13.5 22.6 0 7.6-6.2 13.8-13.8 13.8M53.1 23.7c4.7 0 8.5-3.8 8.5-8.5C61.6 12 58 6.7 55 2.9c-.5-.6-1.2-.9-1.9-.9s-1.4.3-1.9.9c-3 3.8-6.6 9.1-6.6 12.3 0 4.7 3.8 8.5 8.5 8.5m-.3-19.6c.1-.1.2-.1.3-.1s.2 0 .3.1c3.9 4.9 6.2 9.1 6.2 11.1 0 3.6-2.9 6.5-6.5 6.5s-6.5-2.9-6.5-6.5c0-2 2.3-6.1 6.2-11.1M48.9 33.2c-.7 0-1.4.3-1.8.9-3.5 4.2-9.4 12-9.4 16.7 0 6.2 5 11.3 11.2 11.3s11.2-5 11.2-11.3c0-4.6-5.9-12.4-9.4-16.7q-.6-.9-1.8-.9m0 26.8c-5.1 0-9.2-4.2-9.2-9.3 0-2.9 3.3-8.6 8.9-15.4.1-.1.2-.1.3-.1s.2 0 .3.1c5.6 6.7 8.9 12.5 8.9 15.4.1 5.1-4.1 9.3-9.2 9.3" />
  </svg>
);
export default IconWaterDrops;
