import * as React from "react";
const IconArrowRightCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.7 31.3 36 10.3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4L53.5 31H8c-.6 0-1 .4-1 1s.4 1 1 1h45.7l-19 19.4c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3l20.7-21c.3-.5.3-1.1-.1-1.5" />
  </svg>
);
export default IconArrowRightCopy;
