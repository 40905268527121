import * as React from "react";
const IconWalletAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.1 19.9h-5.4c.9-1.1 1.5-2.4 1.5-4V12c0-3.3-2.7-6-6-6H8c-3.3 0-6 2.7-6 6v39.9c0 3.3 2.7 5.9 5.9 5.9h48.2c3.3 0 5.9-2.7 5.9-5.9V25.8c0-3.3-2.7-5.9-5.9-5.9M60 45.8H49.2c-1.2 0-2.1-1-2.1-2.1v-9.6c0-1.2 1-2.1 2.1-2.1H60zM4 12.1c0-2.2 1.8-4 4-4h38.3c2.2 0 4 1.8 4 4V16c0 2.2-1.8 4-4 4H8c-2.2 0-4-1.8-4-4v-3.9m52.1 43.8H7.9C5.8 55.9 4 54.1 4 52V20.4c1.1.9 2.4 1.5 4 1.5h48.2c2.2 0 3.9 1.8 3.9 3.9v4.1H49.2c-2.3 0-4.1 1.9-4.1 4.1v9.6c0 2.3 1.9 4.1 4.1 4.1H60V52c0 2.1-1.8 3.9-3.9 3.9" />
    <path d="M53.8 40.8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2c0 1.2.9 2 2 2" />
  </svg>
);
export default IconWalletAlt1;
