import * as React from "react";
const IconTether = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M16.73 6a2 2 0 0 0-1.859 1.26L5.672 30.359a2 2 0 0 0 .466 2.176l24.47 23.723a2 2 0 0 0 2.784 0l24.47-23.723a2 2 0 0 0 .466-2.176l-9.2-23.099A2 2 0 0 0 47.27 6zm-3.717.52A4 4 0 0 1 16.73 4h30.542a4 4 0 0 1 3.716 2.52l9.199 23.099a4 4 0 0 1-.932 4.352l-24.47 23.723a4 4 0 0 1-5.568 0L4.746 33.97a4 4 0 0 1-.932-4.352zm10.511 9.742a1 1 0 0 1 1-1h15.622a1 1 0 0 1 0 2h-6.81v6.853c3.278.082 6.26.564 8.515 1.324 1.236.416 2.308.934 3.091 1.559.774.616 1.4 1.45 1.4 2.488s-.626 1.872-1.4 2.488c-.783.624-1.855 1.143-3.09 1.559-2.255.76-5.238 1.242-8.517 1.324v8.073a1 1 0 0 1-2 0v-8.073c-3.279-.082-6.261-.564-8.516-1.324-1.235-.416-2.307-.935-3.09-1.559-.774-.616-1.4-1.45-1.4-2.488s.626-1.872 1.4-2.488c.783-.625 1.855-1.143 3.09-1.56 2.255-.759 5.238-1.241 8.516-1.323v-6.853h-6.81a1 1 0 0 1-1-1m7.811 9.854c-3.106.081-5.865.54-7.877 1.218-1.118.377-1.95.803-2.483 1.228-.543.432-.646.752-.646.924s.103.491.645.924c.533.425 1.366.851 2.484 1.228 2.012.678 4.77 1.136 7.877 1.218zm2 6.74c3.107-.082 5.865-.54 7.877-1.218 1.118-.377 1.951-.803 2.484-1.228.543-.433.645-.752.645-.924s-.102-.492-.645-.924c-.533-.425-1.366-.851-2.483-1.228-2.013-.678-4.771-1.137-7.878-1.218z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconTether;
