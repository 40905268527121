import * as React from "react";
const IconAutomation = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.1 39.5-6.2-3.4c-.3-.2-.4-.5-.4-.8q.3-1.8.3-3.6 0-1.95-.3-3.6c-.1-.4.1-.7.3-.8l6.3-3.5c.7-.4 1-1.3.7-2.2 0 0 0-.1-.1-.1L56 11.6c-.4-.7-1.3-1-2.2-.7 0 0-.1 0-.1.1l-6.4 3.6c-.2.1-.5.1-.7 0-.5-.4-.9-.7-1.3-1.1-1.1-1-2.1-1.8-3.5-2.5q-.9-.45-.9-1.5V5.6c0-2-1.6-3.6-3.6-3.6H26.8c-2 0-3.6 1.6-3.6 3.6v4c0 .6-.4 1.2-.9 1.5-1.4.7-2.4 1.6-3.6 2.6-.4.3-.7.6-1.2 1-.2.1-.4.2-.7.1l-5.9-3.3c-1.8-1-2.6.1-2.7.4l-5.7 9.8c-.2.4-.3.9-.2 1.3s.4.8.8 1l6.2 3.4c.3.2.4.5.4.8q-.3 1.65-.3 3.6c0 1.2.1 2.4.2 3.6 0 .3-.1.6-.4.8l-6.3 3.5c-.7.4-1 1.3-.7 2.2 0 0 0 .1.1.1L8 51.8c.4.7 1.3 1 2.1.7 0 0 .1 0 .1-.1l6.4-3.6c.2-.1.5-.1.7 0 .4.4.8.7 1.2 1 1.2 1 2.2 1.8 3.6 2.5q.9.45.9 1.5v4.4c0 2 1.6 3.6 3.6 3.6h10.5c2 0 3.6-1.6 3.6-3.6v-4.6c0-.6.4-1.2.9-1.5 1.4-.7 2.4-1.5 3.5-2.5.4-.3.8-.7 1.2-1 .2-.1.4-.2.7-.1l5.9 3.3c1.8 1 2.6-.1 2.7-.4l5.8-9.8c.2-.4.3-.9.2-1.3.2-.2-.1-.6-.5-.8m-6.8 10.9c-.1 0-.1-.1-.2-.1L48.2 47c-.9-.5-2.1-.4-2.9.2-.5.4-.9.7-1.3 1.1-1.1.9-1.9 1.6-3.1 2.2s-2.1 1.9-2.1 3.3v4.6c0 .9-.7 1.6-1.6 1.6H26.8c-.9 0-1.6-.7-1.6-1.6V54c0-1.4-.8-2.7-2.1-3.3-1.2-.6-2.1-1.3-3.2-2.2-.4-.3-.8-.7-1.3-1.1s-1-.6-1.6-.6c-.4 0-.9.1-1.3.3l-6.1 3.4-5.5-9.3 6-3.4c1-.5 1.5-1.6 1.4-2.8-.1-1.1-.2-2.2-.2-3.3 0-1.2.1-2.3.2-3.3.2-1.2-.3-2.4-1.4-2.9l-5.9-3.3 5.4-9.3c.1 0 .1.1.2.1l5.9 3.3c.9.5 2.1.4 2.9-.3.4-.4.8-.7 1.2-1 1.1-.9 2-1.7 3.2-2.3 1.3-.6 2.1-1.9 2.1-3.3v-4c0-.9.7-1.6 1.6-1.6h10.5c.9 0 1.6.7 1.6 1.6v3.9c0 1.4.8 2.7 2.1 3.3 1.2.6 2 1.3 3.1 2.2.4.4.9.7 1.3 1.1.8.7 2 .7 2.9.2l6.1-3.4 5.5 9.3-6 3.3c-1 .6-1.6 1.7-1.3 2.9.2 1 .2 2.1.2 3.3q0 1.65-.3 3.3c-.2 1.2.4 2.3 1.4 2.9l5.9 3.2z" />
    <path d="m41.8 31.2-12.9-8.8c-.3-.2-.7-.2-1-.1-.3.2-.5.5-.5.9v17.6c0 .4.2.7.5.9.1.1.3.1.5.1s.4-.1.6-.2l12.9-8.8c.3-.2.4-.5.4-.8s-.2-.6-.5-.8m-12.5 7.7V25.1L39.5 32z" />
  </svg>
);
export default IconAutomation;
