import * as React from "react";
const IconTeabagCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M14.9 30.4h5.3c.6 0 1-.4 1-1s-.4-1-1-1h-5.3c-.6 0-1 .4-1 1s.4 1 1 1" />
    <path d="m60.1 44.3-4.7-2.4v-20C55.4 10.9 46.7 2 36 2 24.9 2 17.3 8.3 16.6 17.8H7.4c-1.1 0-2.1.8-2.3 1.9L3.4 27c-.1.3-.1.5-.1.7v31.9c0 1.3 1.1 2.4 2.4 2.4h23.6c1.3 0 2.3-1 2.4-2.5V27.7c0-.2 0-.3-.1-.3l-1.8-7.6c-.2-1.1-1.2-2-2.3-2h-8.9C19.3 8.3 27.7 4 36 4c9.6 0 17.5 8 17.5 17.9V42l-4.7 2.4c-.3.2-.6.5-.6.9V61c0 .6.4 1 1 1h10.5c.6 0 1-.4 1-1V45.2q0-.6-.6-.9M27.8 20.2l1.8 7.3c0 .1 0 .2.1.3v31.6c0 .3-.2.6-.4.6H5.7c-.2 0-.4-.2-.4-.4V27.8l1.8-7.7c0-.2.2-.3.3-.3h20.1c.1 0 .3.1.3.4M58.7 60h-8.5V45.8l4.3-2.1 4.3 2.1V60z" />
  </svg>
);
export default IconTeabagCopy;
