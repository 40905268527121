import * as React from "react";
const IconTruckUnknown = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.8 38.8 54.3 28c-.2-.3-.5-.4-.8-.4H40.9v-3.5c0-3-2.5-5.5-5.5-5.5H27c-.6 0-1 .4-1 1s.4 1 1 1h8.4c1.9 0 3.5 1.6 3.5 3.5v31.4H26.7c-.2-2.8-2.6-5.1-5.5-5.1s-5.3 2.2-5.5 5.1H7.5C5.6 55.5 4 53.9 4 52V37.6c0-.6-.4-1-1-1s-1 .4-1 1V52c0 3 2.5 5.5 5.5 5.5h8.4c.7 2.3 2.8 3.9 5.3 3.9s4.6-1.6 5.3-3.9h18c.7 2.3 2.8 3.9 5.3 3.9s4.6-1.6 5.3-3.9H61c.6 0 1-.4 1-1V39.4c0-.2-.1-.4-.2-.6M53 29.6l6.6 9.6H40.9v-9.6zM21.2 59.4c-1.9 0-3.5-1.6-3.5-3.5s1.6-3.5 3.5-3.5 3.5 1.6 3.5 3.5-1.6 3.5-3.5 3.5m28.5 0c-1.9 0-3.5-1.6-3.5-3.5s1.6-3.5 3.5-3.5 3.5 1.6 3.5 3.5-1.6 3.5-3.5 3.5m5.5-3.9c-.2-2.8-2.6-5.1-5.5-5.1s-5.3 2.2-5.5 5.1h-3.3V41.2H60v14.3zM5.2 6.2c1.2-1 2.7-1.6 4.2-1.6 3.5 0 6.3 2.8 6.3 6.3s-2.8 6.3-6.3 6.3c-.6 0-1 .4-1 1v6.2c0 .6.4 1 1 1s1-.4 1-1v-5.3c4.1-.5 7.3-4 7.3-8.3 0-4.6-3.7-8.3-8.3-8.3-2 0-4 .7-5.5 2.1-.4.4-.4 1-.1 1.4.3.6 1 .6 1.4.2" />
    <path d="M9.3 29.6c-.6 0-1 .4-1 1v.4c0 .6.4 1 1 1s1-.4 1-1v-.4c0-.6-.5-1-1-1" />
  </svg>
);
export default IconTruckUnknown;
