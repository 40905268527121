import * as React from "react";
const IconShoppingBag15 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.1 53.4 56 8.8C55.5 4.9 52.3 2 48.3 2H15.7c-4 0-7.2 2.9-7.7 6.8L2.9 53.4c-.3 2.2.4 4.4 1.9 6S8.4 62 10.5 62h43c2.2 0 4.3-.9 5.7-2.6s2.2-3.8 1.9-6m-3.4 4.7c-1.1 1.2-2.6 1.9-4.2 1.9h-43c-1.6 0-3.2-.7-4.2-1.9a5.48 5.48 0 0 1-1.4-4.4L10 9c.3-2.9 2.8-5 5.7-5h32.7c2.9 0 5.3 2.2 5.7 5l5.1 44.6c.1 1.7-.4 3.3-1.5 4.5" />
    <path d="M42.3 16.1c-.5.1-.9.6-.8 1.2.1.7.2 1.4.2 2.1 0 5.4-4.4 9.8-9.8 9.8s-9.8-4.4-9.8-9.8c0-.7.1-1.4.2-2.1.1-.5-.2-1.1-.8-1.2-.5-.1-1.1.2-1.2.8-.2.8-.3 1.7-.3 2.5 0 6.5 5.3 11.8 11.8 11.8s11.8-5.3 11.8-11.8c0-.8-.1-1.6-.3-2.5.1-.5-.4-.9-1-.8" />
  </svg>
);
export default IconShoppingBag15;
