import * as React from "react";
const IconApartmentAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 26.3H31.8V14c0-2.2-1.8-4-4-4H6c-2.2 0-4 1.8-4 4v36c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V30.3c0-2.2-1.8-4-4-4M29.8 52H6c-1.1 0-2-.9-2-2V14c0-1.1.9-2 2-2h21.8c1.1 0 2 .9 2 2v38M60 50c0 1.1-.9 2-2 2H31.8V28.3H58c1.1 0 2 .9 2 2z" />
    <path d="M12.3 20.1H9.7c-.6 0-1 .4-1 1s.4 1 1 1h2.6c.6 0 1-.4 1-1s-.4-1-1-1M23.2 20.1h-2.6c-.6 0-1 .4-1 1s.4 1 1 1h2.6c.6 0 1-.4 1-1s-.5-1-1-1M12.3 30.8H9.7c-.6 0-1 .4-1 1s.4 1 1 1h2.6c.6 0 1-.4 1-1s-.4-1-1-1M23.2 30.8h-2.6c-.6 0-1 .4-1 1s.4 1 1 1h2.6c.6 0 1-.4 1-1s-.5-1-1-1M12.3 41.5H9.7c-.6 0-1 .4-1 1s.4 1 1 1h2.6c.6 0 1-.4 1-1s-.4-1-1-1M23.2 41.5h-2.6c-.6 0-1 .4-1 1s.4 1 1 1h2.6c.6 0 1-.4 1-1s-.5-1-1-1M41.4 34.4h-2.6c-.6 0-1 .4-1 1s.4 1 1 1h2.6c.6 0 1-.4 1-1s-.5-1-1-1M52.2 34.4h-2.6c-.6 0-1 .4-1 1s.4 1 1 1h2.6c.6 0 1-.4 1-1s-.4-1-1-1M41.4 43.2h-2.6c-.6 0-1 .4-1 1s.4 1 1 1h2.6c.6 0 1-.4 1-1s-.5-1-1-1M52.2 43.2h-2.6c-.6 0-1 .4-1 1s.4 1 1 1h2.6c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconApartmentAlt;
