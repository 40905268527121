import * as React from "react";
const IconBalance1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M13.3 19.1c3.4 0 6.2-2.7 6.2-6s-2.8-6-6.2-6-6.2 2.7-6.2 6 2.8 6 6.2 6m0-10.1c2.3 0 4.2 1.8 4.2 4s-1.9 4-4.2 4-4.2-1.8-4.2-4 1.9-4 4.2-4M4.2 37.1h18.2c.9 0 1.6-.7 1.6-1.6v-4.4c0-4.4-3.6-7.9-7.9-7.9h-5.6c-4.4 0-7.9 3.6-7.9 7.9v4.4c0 .9.7 1.6 1.6 1.6m.4-6c0-3.3 2.7-5.9 5.9-5.9h5.6c3.3 0 5.9 2.7 5.9 5.9v4H4.6zM56 21.1h-4.2c-2.2 0-4-1.8-4-3.9s1.8-3.9 4-3.9H61c.6 0 1-.4 1-1s-.4-1-1-1h-4.7V8c0-.6-.4-1-1-1s-1 .4-1 1v3.2h-2.5c-3.3 0-6 2.7-6 5.9 0 3.3 2.7 5.9 6 5.9H56c2.2 0 4 1.8 4 3.9s-1.8 3.9-4 3.9h-9.2c-.6 0-1 .4-1 1s.4 1 1 1h7.5V36c0 .6.4 1 1 1s1-.4 1-1v-3.2c3.1-.2 5.7-2.7 5.7-5.9s-2.7-5.8-6-5.8M60.1 44.2H3c-.6 0-1 .4-1 1s.4 1 1 1h27l-7.8 9.1c-.3.3-.3.7-.2 1.1q.3.6.9.6h17.3q.6 0 .9-.6c.3-.6.1-.8-.2-1.1l-7.8-9.1h27c.6 0 1-.4 1-1s-.5-1-1-1M25.1 55l6.5-7.5L38 55z" />
  </svg>
);
export default IconBalance1;
