import * as React from "react";
const IconProfit = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M13 41h-2.5c-3.3 0-6 2.7-6 6v9c0 3.3 2.7 6 6 6H13c3.3 0 6-2.7 6-6v-9c0-3.3-2.7-6-6-6m-2.5 2H13c2.2 0 4 1.8 4 4v3.5H6.6V47c0-2.2 1.7-4 3.9-4M13 60h-2.5c-2.2 0-4-1.8-4-4v-3.5H17V56c0 2.2-1.8 4-4 4M33.2 34.5h-2.5c-3.3 0-6 2.7-6 6V56c0 3.3 2.7 6 6 6h2.5c3.3 0 6-2.7 6-6V40.4c0-3.3-2.7-5.9-6-5.9M26.8 45h10.4v5.5H26.8zm4-8.5h2.5c2.2 0 4 1.8 4 4V43H26.8v-2.6c0-2.2 1.8-3.9 4-3.9M33.2 60h-2.5c-2.2 0-4-1.8-4-4v-3.5h10.4V56c.1 2.2-1.7 4-3.9 4M53.5 24H51c-3.3 0-6 2.7-6 6v26c0 3.3 2.7 6 6 6h2.5c3.3 0 6-2.7 6-6V29.9c-.1-3.3-2.7-5.9-6-5.9M47 35.1h10.4v6.1H47zm0 8.1h10.4v6.1H47zM51 26h2.5c2.2 0 4 1.8 4 4v3.2H47V30c0-2.2 1.8-4 4-4m2.5 34H51c-2.2 0-4-1.8-4-4v-4.8h10.4V56c0 2.2-1.7 4-3.9 4M53.5 14.7h-5c-.6 0-1 .4-1 1s.4 1 1 1H52V18c0 .6.4 1 1 1s1-.4 1-1v-1.3c1.7-.2 3-1.7 3-3.5V13c0-1.9-1.6-3.5-3.5-3.5H51c-.8 0-1.5-.7-1.5-1.5v-.3c0-.8.7-1.5 1.5-1.5h5c.6 0 1-.4 1-1s-.4-1-1-1h-2V3c0-.6-.4-1-1-1s-1 .4-1 1v1.2h-1c-1.9 0-3.5 1.6-3.5 3.5V8c0 1.9 1.6 3.5 3.5 3.5h2.5c.8 0 1.5.7 1.5 1.5v.2c0 .9-.7 1.5-1.5 1.5" />
  </svg>
);
export default IconProfit;
