import * as React from "react";
const IconBarChart1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 57.4H6.6c-1.4 0-2.6-1.2-2.6-2.6V5.7c0-.6-.4-1-1-1s-1 .4-1 1v49.1c0 2.5 2.1 4.6 4.6 4.6H61c.6 0 1-.4 1-1s-.4-1-1-1" />
    <path d="M18.4 40c3.7 0 6.7-3 6.7-6.7 0-1.1-.3-2.1-.7-3l6.1-3.8c1.2 1.5 3.1 2.4 5.1 2.4 3.7 0 6.7-3 6.7-6.7 0-1-.2-2-.6-2.8l7.4-4.1c1.2 1.6 3.1 2.6 5.3 2.6a6.7 6.7 0 1 0-6.7-6.7c0 .8.2 1.6.4 2.3l-7.6 4.2c-1.2-1.3-3-2.2-4.9-2.2-3.7 0-6.7 3-6.7 6.7 0 .9.2 1.8.5 2.6l-6.2 3.9c-1.2-1.2-2.9-2-4.7-2-3.7 0-6.7 3-6.7 6.7 0 3.6 2.9 6.6 6.6 6.6M54.2 6.6c2.6 0 4.7 2.1 4.7 4.7S56.8 16 54.2 16s-4.7-2.1-4.7-4.7 2.2-4.7 4.7-4.7m-18.7 11c2.6 0 4.7 2.1 4.7 4.7S38.1 27 35.5 27s-4.7-2.1-4.7-4.7c.1-2.6 2.2-4.7 4.7-4.7M18.4 28.7c2.6 0 4.7 2.1 4.7 4.7S21 38 18.4 38s-4.7-2.1-4.7-4.7 2.1-4.6 4.7-4.6" />
  </svg>
);
export default IconBarChart1;
