import * as React from "react";
const IconSwap2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 65 65"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M40.5 11.5c-2.8 0-5.5.6-8 1.6-2.5-1-5.2-1.6-8-1.6-11.6 0-21 9.4-21 21s9.4 21 21 21c2.8 0 5.4-.5 8-1.6 2.5 1 5.2 1.6 8 1.6 11.6 0 21-9.4 21-21s-9.4-21-21-21m-16 40c-10.5 0-19-8.5-19-19s8.5-19 19-19c1.9 0 3.7.3 5.5.8-6 3.5-10.2 9.8-10.5 17.2H16c-.6 0-1 .4-1 1s.4 1 1 1h3.5c.4 7.4 4.5 13.7 10.5 17.2-1.8.5-3.6.8-5.5.8m16 0c-10.2 0-18.4-8-19-18h19.2l-5.6 5.7c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3l7.2-7.4c.4-.4.4-1 0-1.4l-7.2-7.4c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l5.6 5.7H21.5c.5-10 8.8-18 19-18 10.5 0 19 8.5 19 19s-8.5 19-19 19" />
  </svg>
);
export default IconSwap2;
