import * as React from "react";
const IconTime = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 22c-7.7 0-13.9 6.2-13.9 13.9S24.3 49.8 32 49.8s13.9-6.2 13.9-13.9S39.7 22 32 22m0 25.8c-6.6 0-11.9-5.3-11.9-11.9S25.4 24 32 24s11.9 5.3 11.9 11.9c0 6.5-5.3 11.9-11.9 11.9" />
    <path d="M32.3 36.8v-4.1c0-.6-.4-1-1-1s-1 .4-1 1v4.5c0 .3.1.5.3.7l2.7 2.7c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4z" />
    <path d="M42.5 2h-21c-7.2 0-13 5.8-13 13v34c0 7.2 5.8 13 13 13h21c7.2 0 13-5.8 13-13V15c0-7.2-5.8-13-13-13m11 47c0 6.1-4.9 11-11 11h-21c-6.1 0-11-4.9-11-11V15c0-6.1 4.9-11 11-11h21c6.1 0 11 4.9 11 11z" />
  </svg>
);
export default IconTime;
