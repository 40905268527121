import * as React from "react";
const IconMediaServer = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62 42.1v-8.9c0-2.2-1.8-4-4-4h-3.2v-21c0-2.9-2.3-5.2-5.2-5.2H7.2C4.3 3 2 5.3 2 8.2v29.9c0 2.9 2.3 5.2 5.2 5.2h12.1q.3 1.05 1.2 1.8c-.8.7-1.4 1.8-1.4 3V57c0 2.2 1.8 4 4 4H58c2.2 0 4-1.8 4-4v-8.9c0-1.2-.5-2.3-1.4-3 .9-.7 1.4-1.8 1.4-3M52.8 8.2V17H40.1l7.4-12h2.1c1.8 0 3.2 1.4 3.2 3.2M31.1 5l-7.4 12H12.1l7.4-12zm2.4 0h11.6l-7.4 12H26.1zM7.2 5h9.9L9.8 17H4V8.2C4 6.4 5.4 5 7.2 5M4 38.1V19h48.8v10.1H23.1c-2.2 0-4 1.8-4 4v8.1H7.2c-1.8.1-3.2-1.3-3.2-3.1m56 10V57c0 1.1-.9 2-2 2H23.1c-1.1 0-2-.9-2-2v-8.9c0-1.1.9-2 2-2H58c1.1 0 2 .9 2 2m-36.9-4c-1.1 0-2-.9-2-2v-8.9c0-1.1.9-2 2-2H58c1.1 0 2 .9 2 2v8.9c0 1.1-.9 2-2 2z" />
    <path d="M28.9 35.5c-1.1 0-2 .9-2 2v.1c0 1.1.9 2 2 2s2-.9 2-2v-.1c0-1.2-.9-2-2-2M53.8 36.5H49c-.6 0-1 .4-1 1s.4 1 1 1h4.8c.6 0 1-.4 1-1s-.5-1-1-1M28.9 54.5c1.1 0 2-.9 2-2v-.1c0-1.1-.9-2-2-2s-2 .9-2 2v.1c0 1.1.9 2 2 2M49 53.5h4.8c.6 0 1-.4 1-1s-.4-1-1-1H49c-.6 0-1 .4-1 1s.5 1 1 1" />
  </svg>
);
export default IconMediaServer;
