import * as React from "react";
const IconPaidSign = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.3 13.9H6.7C4.1 13.9 2 16 2 18.6v26.7C2 47.9 4.1 50 6.7 50h50.6c2.6 0 4.7-2.1 4.7-4.7V18.6c0-2.6-2.1-4.7-4.7-4.7M60 45.4c0 1.5-1.2 2.7-2.7 2.7H6.7c-1.5 0-2.7-1.2-2.7-2.7V18.6c0-1.5 1.2-2.7 2.7-2.7h50.6c1.5 0 2.7 1.2 2.7 2.7z" />
    <path d="M17.9 24.8h-6.7c-.6 0-1 .4-1 1v13c0 .6.4 1 1 1s1-.4 1-1v-6.1h5.7c.6 0 1-.4 1-1v-5.8c0-.6-.4-1.1-1-1.1m-1 5.9h-4.7v-3.8h4.7zM31.2 24.8h-6.7c-.6 0-1 .4-1 1v13c0 .6.4 1 1 1s1-.4 1-1v-6.1h4.7v6.1c0 .6.4 1 1 1s1-.4 1-1V25.9c0-.6-.4-1.1-1-1.1m-5.7 2h4.7v3.8h-4.7zM38.2 24.2c-.6 0-1 .4-1 1v13.6c0 .6.4 1 1 1s1-.4 1-1V25.2c0-.6-.5-1-1-1M51.4 25.2c-.2-.2-.5-.3-.7-.3h-5c-.6 0-1 .4-1 1v12.6c0 .6.4 1 1 1h5c.3 0 .6-.1.8-.4l3.2-4c.1-.2.2-.4.2-.6v-5.1c0-.2-.1-.5-.3-.7zm1.4 8.9-2.6 3.4h-3.5V26.8h3.6l2.6 2.9v4.4z" />
  </svg>
);
export default IconPaidSign;
