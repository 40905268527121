import * as React from "react";
const IconCogsCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M43 35.4c-4.2 0-7.5 3.4-7.5 7.6s3.4 7.6 7.5 7.6 7.5-3.4 7.5-7.6-3.3-7.6-7.5-7.6m0 13.1c-3.1 0-5.5-2.5-5.5-5.6s2.5-5.6 5.5-5.6 5.5 2.5 5.5 5.6-2.4 5.6-5.5 5.6" />
    <path d="m61.2 47.3-3.9-2.1v-.1c.1-.8.2-1.6.2-2.3 0-.8-.1-1.6-.2-2.3v-.1l3.9-2.2c.6-.4.9-1.1.6-1.8L58.2 30c-.4-.6-1.1-.8-1.8-.6l-4.1 2.3c-.3-.2-.5-.5-.8-.7-.7-.6-1.3-1.1-2.3-1.6-.2-.1-.4-.3-.4-.6v-2.4c0-1.5-1.2-2.6-2.6-2.6h-6.5c-1.5 0-2.6 1.2-2.6 2.6V29c0 .2-.1.5-.4.6-.9.5-1.6 1-2.3 1.6-.2.2-.5.4-.7.6l-3.7-2c-1.1-.6-1.9-.2-2.2.4l-3.6 6.1c-.2.3-.3.7-.2 1.1s.3.7.7.9l3.9 2.1v.1c-.1.7-.2 1.4-.2 2.3 0 .8 0 1.5.1 2.3v.1l-3.9 2.2c-.6.4-.9 1.1-.6 1.8l3.7 6.3c.4.6 1.1.8 1.8.6l4.1-2.3c.3.2.5.4.8.6.7.6 1.4 1.2 2.3 1.6.2.1.4.3.4.6v2.8c0 1.5 1.2 2.6 2.6 2.6h6.5c1.5 0 2.6-1.2 2.6-2.6v-2.9c0-.2.1-.5.4-.6.9-.4 1.5-1 2.3-1.6.2-.2.5-.4.7-.6l3.7 2.1c1.1.6 1.9.2 2.2-.4l3.6-6.1c.2-.3.2-.7.1-1.1q0-.6-.6-.9m-4.5 6.6L53.2 52c-.7-.4-1.6-.3-2.2.2-.3.2-.6.5-.8.7-.6.6-1.1 1-1.8 1.3-.9.4-1.5 1.4-1.5 2.4v2.9c0 .4-.3.6-.6.6h-6.5c-.4 0-.6-.3-.6-.6v-2.8c0-1-.6-1.9-1.5-2.4-.7-.3-1.2-.8-1.9-1.3-.2-.2-.5-.4-.8-.7-.4-.3-.8-.4-1.2-.4-.3 0-.7.1-1 .3L29.2 54l-3-5.1 3.4-1.9c.7-.4 1.2-1.2 1-2.1-.1-.7-.1-1.3-.1-2s0-1.4.1-2c.2-.9-.3-1.8-1-2.2l-3.4-1.8 3-5.1 3.5 1.9c.7.4 1.6.3 2.2-.2.3-.2.5-.4.8-.6.7-.6 1.2-1 1.9-1.4.9-.4 1.5-1.4 1.5-2.4v-2.5c0-.4.3-.6.6-.6h6.5c.4 0 .6.3.6.6V29c0 1 .6 1.9 1.5 2.4.7.3 1.2.8 1.8 1.3.3.2.5.5.8.7.6.5 1.5.6 2.2.2l3.5-1.9 3 5.1-3.4 1.9c-.8.4-1.2 1.3-1 2.2.1.6.1 1.2.1 2 0 .6-.1 1.3-.2 2-.1.9.3 1.8 1 2.2l3.3 1.8zM15.3 10.6c-2.6 0-4.7 2.1-4.7 4.7s2.1 4.7 4.7 4.7 4.7-2.1 4.7-4.7-2.1-4.7-4.7-4.7m0 7.5c-1.5 0-2.7-1.2-2.7-2.7s1.2-2.7 2.7-2.7 2.7 1.2 2.7 2.7c0 1.4-1.2 2.7-2.7 2.7" />
    <path d="m25.9 24 2.5-4.2c.2-.3.2-.7.1-1s-.3-.6-.6-.8l-2.5-1.3c.1-.5.1-1 .1-1.5s0-1-.1-1.5l2.5-1.4c.6-.3.8-1 .5-1.6L26 6.4c-.3-.5-1-.8-1.6-.5l-2.6 1.5c-.1-.1-.2-.2-.4-.3-.5-.4-.9-.8-1.6-1.1l-.1-.1V4.2c0-1.2-1-2.2-2.2-2.2H13c-1.1 0-2 1-2 2.1v1.7s0 .1-.1.1c-.7.3-1.2.7-1.7 1.2l-.3.3-2.3-1.3c-.9-.5-1.6-.2-1.9.4l-2.4 4.2c-.2.3-.2.7-.1 1s.3.6.6.8l2.5 1.4c-.1.4-.1.9-.1 1.5 0 .5 0 1 .1 1.4l-2.5 1.4c-.6.3-.8 1-.5 1.6L4.6 24c.3.5 1 .8 1.6.5L8.8 23l.3.3c.5.4 1 .8 1.7 1.2l.1.1v1.9c0 1.2.9 2.1 2.1 2.1h4.5c1.2 0 2.1-1 2.1-2.1v-1.9s0-.1.1-.1c.7-.3 1.1-.7 1.6-1.1l.3-.3 2.3 1.3c1 .4 1.7.1 2-.4m-5.3-2.7c-.2.2-.4.3-.6.5-.4.4-.8.6-1.2.9-.7.4-1.2 1.1-1.2 1.9v1.9c0 .1-.1.1-.1.1H13c-.1 0-.1-.1-.1-.1v-1.9c0-.8-.5-1.6-1.2-1.9q-.6-.3-1.2-.9c-.2-.1-.4-.3-.6-.5-.3-.2-.7-.4-1-.4s-.6.1-.8.2L6 22.4l-1.7-3 2-1.1c.6-.3 1-1 .9-1.8-.1-.4-.1-.9-.1-1.3 0-.5 0-.9.1-1.3.1-.7-.2-1.5-.9-1.8l-2-1.1L6 8.1l2.1 1.2c.6.3 1.4.2 1.9-.2.2-.2.4-.3.5-.4.5-.5.8-.7 1.3-1 .7-.3 1.2-1.1 1.2-1.9V4.1s0-.1.1-.1h4.5c.1 0 .1.1.1.1v1.7c0 .8.5 1.6 1.2 1.9.4.2.7.5 1.2.8.2.2.4.3.6.5.5.4 1.3.5 1.9.2l2-1.2 1.7 3-2 1.1c-.6.4-1 1.1-.9 1.8.1.4.1.8.1 1.3 0 .4 0 .9-.1 1.3-.1.7.2 1.5.9 1.8l2 1.1-1.7 2.9-2.1-1.2c-.6-.3-1.4-.2-1.9.2" />
  </svg>
);
export default IconCogsCopy;
