import * as React from "react";
const IconMicrophoneAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 34.2c6.3 0 11.4-5.1 11.4-11.4v-9.4C43.4 7.1 38.3 2 32 2S20.6 7.1 20.6 13.4v9.4c0 6.3 5.1 11.4 11.4 11.4m-9.4-20.8C22.6 8.2 26.8 4 32 4s9.4 4.2 9.4 9.4v9.4c0 5.2-4.2 9.4-9.4 9.4s-9.4-4.2-9.4-9.4z" />
    <path d="M52.1 23.4c0-.6-.4-1-1-1s-1 .4-1 1c0 9.9-8.1 18-18.1 18s-18.1-8.1-18.1-18c0-.6-.4-1-1-1s-1 .4-1 1c0 10.7 8.5 19.4 19.1 19.9V61c0 .6.4 1 1 1s1-.4 1-1V43.3c10.6-.5 19.1-9.2 19.1-19.9" />
  </svg>
);
export default IconMicrophoneAlt;
