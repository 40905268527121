import * as React from "react";
const IconPatreon = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M63 24.7C63 37 53 47 40.7 47S18.4 37 18.4 24.7s10-22.3 22.3-22.3C53 2.3 63 12.3 63 24.7m-62 37h11V2.3H1z" />
  </svg>
);
export default IconPatreon;
