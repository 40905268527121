import * as React from "react";
const IconLightBulb = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M49.8 15.3v-.1c-.5-1.4-1.6-4.3-4.2-7.1q-4.2-4.5-9.9-5.7c-2.4-.5-4.7-.5-7.1 0-3.9.7-7.3 2.6-10.1 5.6-2.6 2.8-3.7 5.7-4.3 7.2-.6 1.7-1.7 4.7-1.4 8.5.2 2.9 1.2 5.8 2.8 8.6.7 1.1 1.6 2.6 2.9 4.3.4.6.7 1 1 1.4l.1.1c.8 1.1 1.3 1.8 1.8 2.9s.9 2.3 1.2 3.4c.1.4.5.7 1 .7h16.6c.5 0 .9-.3 1-.7.3-1.2.7-2.4 1.3-3.4.5-1.1 1-1.8 1.8-2.8l1.6-2.2c1-1.4 1.8-2.6 2.4-3.7.7-1.2 2.5-4.3 2.8-8.6.3-3.8-.7-6.7-1.3-8.4m-.7 8.3c-.3 3.8-2 6.6-2.6 7.7s-1.4 2.2-2.3 3.6l-1.6 2.2c-.8 1.1-1.4 1.9-2 3.1-.5.9-.9 2-1.2 3H24.5c-.3-1-.7-2-1.1-3-.6-1.3-1.2-2.1-2-3.2l-.1-.1c-.3-.4-.6-.8-1-1.4-1.2-1.7-2.2-3.1-2.8-4.2-1.5-2.5-2.4-5.1-2.6-7.7-.3-3.4.7-6.1 1.3-7.7.5-1.4 1.5-3.9 3.8-6.4 2.5-2.7 5.5-4.4 8.8-5.1q3.15-.6 6.3 0c3.4.6 6.4 2.3 8.9 5 2.4 2.5 3.3 5.1 3.8 6.4v.1c.7 1.6 1.6 4.3 1.3 7.7M38.4 51.3H25.6c-.6 0-1 .4-1 1s.4 1 1 1h12.7c.6 0 1-.4 1-1s-.4-1-.9-1M36.2 60h-8.4c-.6 0-1 .4-1 1s.4 1 1 1h8.4c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconLightBulb;
