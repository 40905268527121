import React, { useState, useRef } from 'react';
import { useNavigation } from '../../context/NavigationContext';
import { BringzzPageContainer, BringzzIcon, BringzzText, BringzzProgressBar, BringzzButton } from "@bringzz/components"
import BikeImage from 'assets/images/Bike.png'
import RocketImage from 'assets/images/Rocket.png'
import { BringzzOrderFlow } from "@bringzz/components"

const MatchingProcessShopper3 = () => {
    const { backPress } = useNavigation();
    const orderFlowRef = useRef();
    const [currentScreen, setCurrentScreen] = useState('accepted');

    return (
        <>
            {/* <BringzzOrderFlow
                ref={orderFlowRef}
                flowType={FLOW_TYPES.SHOPPER}
                perspective={PERSPECTIVES.SHOPPER}
                currentScreen={currentScreen}
                onScreenChange={setCurrentScreen}
            /> */}
            <BringzzPageContainer
                header={{
                    right: <BringzzIcon
                        size="18"
                        icon="IconClose"
                        folder="LineIcons"
                        className="cursor-pointer"
                        onClick={backPress}
                    />,
                    title: <BringzzText tag='h2'>Order #1234</BringzzText>,
                }}>

                <div className='text-center p-4'>
                    <BringzzText tag="h1">Matching pharmacy...</BringzzText>
                    <BringzzText tag="h4" className="font-normal">Delivery arriving at XX:XX</BringzzText>
                    <div className='mt-6'>
                        <BringzzProgressBar state={1} segments={
                            [
                                { img: RocketImage, text: "Waiting for pharmacy to accept request...." },
                                { img: BikeImage, text: "Started delivery...." },
                                { img: RocketImage, text: "Waiting for pharmacy to accept request...." },
                                { img: BikeImage, text: "Waiting for pharmacy to accept request...." },
                            ]
                        } />
                    </div>
                    <div className='flex justify-center mt-6'>
                        <BringzzButton className="border border-black">Cancel order</BringzzButton>
                    </div>
                </div>

            </BringzzPageContainer>
        </>
    )
}

export default MatchingProcessShopper3;