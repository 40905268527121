import * as React from "react";
const IconCoinbase1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 65 65"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32.5 18.4c3.5 0 6.9 1.2 9.5 3.5.4.4 1.1.3 1.4-.1.4-.4.3-1.1-.1-1.4-3-2.6-6.8-4-10.8-4-9.2 0-16.6 7.4-16.6 16.6s7.4 16.6 16.6 16.6c4 0 7.8-1.4 10.8-4 .4-.4.5-1 .1-1.4s-1-.5-1.4-.1c-2.6 2.3-6 3.5-9.5 3.5-8 0-14.6-6.5-14.6-14.6s6.6-14.6 14.6-14.6" />
    <path d="M32.5 4c-16 0-29 13-29 29s13 29 29 29 29-13 29-29-13-29-29-29m0 56c-14.9 0-27-12.1-27-27s12.1-27 27-27 27 12.1 27 27-12.1 27-27 27" />
  </svg>
);
export default IconCoinbase1;
