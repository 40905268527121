import * as React from "react";
const IconFirebase = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 65 64"
    {...props}
  >
    <path
      
      d="M12.638 40.866 18.37 4.124c.198-1.252 1.879-1.548 2.472-.428l6.162 11.533zm40.762 8.93-5.47-33.842c-.165-1.054-1.483-1.482-2.241-.725L11.22 49.796 30.3 60.506c1.186.659 2.67.659 3.855 0zM37.912 20.271l-4.416-8.403a1.313 1.313 0 0 0-2.34 0l-19.408 34.6z"
    />
  </svg>
);
export default IconFirebase;
