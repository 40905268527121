import React, { useEffect, useRef, useState } from "react";
import BringzzImage from "../BringzzImage";
import BringzzText from "../BringzzText";
import BringzzButton from "../BringzzButton";

const BringzzOrderCard = ({ orderDetails, className, onClick, mode = "default", ...props }) => {
  function formatDateTimeDDMMYYHHMM(date) {
    let day = date.getDate().toString().padStart(2, "0");
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let year = date.getFullYear().toString().substr(-2);
    let hours = date.getHours().toString().padStart(2, "0");
    let minutes = date.getMinutes().toString().padStart(2, "0");
    return `${day}/${month}/${year} | ${hours}:${minutes}`;
  }
  return (
    <>
      <div className="p-6 rounded-md border border-midnight-blue">
        <div className="flex flex-row gap-x-4">
          <div>
            {orderDetails?.avatar ? (
              <BringzzImage
                width={"58px"}
                className="rounded-full"
                src={orderDetails.avatar}
              />
            ) : (
              <div className="w-[58px] h-[58px] bg-midnight-blue flex items-center justify-center rounded-full">
                <BringzzText className="text-white text-xl">
                  {orderDetails.name[0].toUpperCase()}
                </BringzzText>
              </div>
            )}
          </div>
          <div className="grow">
            <div className="flex justify-between">
              <div>
                <div>
                  <BringzzText tag="h4" className="font-bold">
                    {orderDetails.name}
                  </BringzzText>
                </div>
                <div>
                  <BringzzText tag="h4" className="font-light">
                    {formatDateTimeDDMMYYHHMM(orderDetails.date)}
                  </BringzzText>
                </div>
                <div>
                  <BringzzText
                    tag="h4"
                    className="font-light text-approval-green"
                  >
                    {orderDetails.status}
                  </BringzzText>
                </div>
              </div>
              <div className="">
                <div>
                  <BringzzText tag="h4" className="font-bold">
                    € {orderDetails.price}
                  </BringzzText>
                </div>
              </div>
            </div>
            <div className="flex flex-col mt-2">
              <BringzzText tag="h5" className={"font-normal"}>
                {orderDetails.shopperName}
              </BringzzText>
              <BringzzText tag="h5" className={"font-normal"}>
                {orderDetails.itemsCount} items
              </BringzzText>
            </div>
          </div>
        </div>
        {mode == "default" &&
          <div className="mt-4">
            <BringzzButton className="bg-magic-lilac w-full text-center flex justify-center" onClick={onClick && onClick}>
              <span>View</span>
            </BringzzButton>
          </div>}
        {mode == "reorder" &&
          <div className="mt-4">
            <BringzzButton className="bg-magic-lilac w-full text-center flex justify-center" onClick={onClick && onClick}>
              <span>Reorder</span>
            </BringzzButton>
          </div>}
      </div>
    </>
  );
};

export default BringzzOrderCard;
