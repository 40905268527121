import * as React from "react";
const IconLadiesTShirt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M47.2 45.9c-.6-2.4-1.2-4.6-1.3-6.5-.5-5-.1-9.9 1.1-16.4.1-.4-.1-.7-.4-1-1.9-1.5-3.1-3.6-3.9-5.3l1.8-13.6c.1-.5-.3-1.1-.9-1.1-.6-.1-1.1.3-1.1.9L40.8 16c-1.7 1.7-4.5 3.6-8.8 3.6s-7.1-2-8.8-3.7l-1.7-13c-.1-.5-.6-.9-1.1-.9-.5.1-.9.6-.9 1.1l1.8 13.7c-.7 1.7-2 3.8-3.9 5.3-.3.2-.4.6-.4 1 1.2 6.5 1.5 11.4 1.1 16.4-.2 1.9-.7 4-1.3 6.5-1 3.9-2.1 8.3-2.2 13.6 0 .5.3.9.8 1C17.8 61 24.2 62 32 62s14.2-1 16.6-1.4c.5-.1.8-.5.8-1-.1-5.3-1.2-9.8-2.2-13.7M32 60c-6.8 0-12.6-.8-15.4-1.2.2-4.7 1.2-8.7 2.2-12.3.6-2.5 1.2-4.7 1.4-6.8.5-5.1.2-10-1-16.4 1.7-1.5 2.9-3.3 3.6-4.9 2 1.7 5 3.3 9.2 3.3s7.2-1.6 9.2-3.3h.1c.8 1.6 2 3.4 3.6 4.9-1.2 6.4-1.5 11.3-1 16.4.2 2.1.7 4.3 1.4 6.8.9 3.6 1.9 7.6 2.2 12.3-2.9.4-8.7 1.2-15.5 1.2" />
  </svg>
);
export default IconLadiesTShirt;
