import * as React from "react";
const IconMedicine = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M23.4 38.9H7.2c-2.9 0-5.2 2.3-5.2 5.2v2.5c0 2.9 2.3 5.2 5.2 5.2h16.2c2.9 0 5.2-2.3 5.2-5.2v-2.5c0-2.9-2.3-5.2-5.2-5.2m-9.1 10.8H7.2c-1.8 0-3.2-1.4-3.2-3.2V44c0-1.8 1.4-3.2 3.2-3.2h7.1zm12.3-3.2c0 1.8-1.4 3.2-3.2 3.2h-7.1v-8.8h7.1c1.8 0 3.2 1.4 3.2 3.2zM61.4 28.1l-3.2-6.6c-.1-.2-.2-.3-.4-.4v-4.5c0-2.2-1.8-4-4-4H43.7c-2.2 0-4 1.8-4 4v4.6c-.1.1-.2.2-.2.3l-3.2 6.6c-.4.8-.6 1.8-.6 2.7v16.9c0 2.7 2.2 4.9 4.9 4.9h16.6c2.7 0 4.9-2.2 4.9-4.9V30.8c-.1-.9-.3-1.9-.7-2.7m-23.8 4.6H60v8.9H37.6zm4.1-16.1c0-1.1.9-2 2-2h10.1c1.1 0 2 .9 2 2v4.3H41.7zM41 23h15.7l2.9 6c.3.5.4 1.2.4 1.8H37.6c0-.6.2-1.2.4-1.8zm16.1 27.7H40.5c-1.6 0-2.9-1.3-2.9-2.9v-4.1H60v4.1c0 1.6-1.3 2.9-2.9 2.9M15.5 27c2 0 4-.8 5.5-2.3 3-3 3-8 0-11.1s-8-3-11.1 0-3 8 0 11.1c1.6 1.5 3.6 2.3 5.6 2.3m4.1-3.7c-2 2-5.2 2.2-7.5.6l8.1-8.1c1.6 2.3 1.4 5.5-.6 7.5M11.4 15c1.1-1.1 2.6-1.7 4.1-1.7 1.2 0 2.3.4 3.3 1.1l-8.1 8.1c-1.6-2.3-1.4-5.4.7-7.5" />
  </svg>
);
export default IconMedicine;
