import * as React from "react";
const IconWheelbarrowCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.8 44.5c-1.2 0-2.3.3-3.2.9L49 41.2c4.2-2 7.6-5.7 9.2-10.4l1.9-5.7c.4-1.2.7-2.6-.1-3.7-.6-.9-1.8-1.3-3.5-1.3H16.7L13.4 9.6c-.5-1.5-1.9-2.5-3.4-2.5H3c-.6 0-1 .4-1 1s.4 1 1 1h7c.7 0 1.3.4 1.5 1.1l3.5 11V54c0 .7.4 1.3 1.1 1.6.2.1.4.1.6.1.4 0 .8-.2 1.2-.5l16.6-12.4h7.4c1.8 0 3.6-.3 5.3-.9l4 4.7c-.9 1.1-1.5 2.5-1.5 4 0 3.4 2.8 6.2 6.2 6.2s6.2-2.8 6.2-6.2-2.9-6.1-6.3-6.1M17 53.4V42.8h14.1zm0-12.6V22.1h39.6c1.4 0 1.7.3 1.8.5.3.4-.1 1.6-.2 1.9l-1.9 5.7c-2.1 6.4-8 10.6-14.5 10.6zm38.8 14.1c-2.3 0-4.2-1.9-4.2-4.2s1.9-4.2 4.2-4.2 4.2 1.9 4.2 4.2-1.9 4.2-4.2 4.2" />
  </svg>
);
export default IconWheelbarrowCopy;
