import * as React from "react";
const IconScrollDownCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <circle cx={32} cy={13.5} r={2.6} />
    <circle cx={32} cy={24.1} r={2.6} />
    <circle cx={32} cy={34.6} r={2.6} />
    <path d="M39.2 41.9 32 49l-7.2-7.1c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l7.9 7.8c.2.2.5.3.7.3s.5-.1.7-.3l7.9-7.8c.4-.4.4-1 0-1.4s-1-.4-1.4 0" />
    <path d="M42.5 2h-21c-5 0-9 4-9 9v42c0 5 4 9 9 9h20.9c5 0 9-4 9-9V11c.1-5-4-9-8.9-9m7 51c0 3.9-3.1 7-7 7h-21c-3.9 0-7-3.1-7-7V11c0-3.9 3.1-7 7-7h20.9c3.9 0 7 3.1 7 7v42z" />
  </svg>
);
export default IconScrollDownCopy;
