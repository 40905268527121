import * as React from "react";
const IconTreesAlt7 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.5 32.8 50.4 7.7c-.9-2.1-2.9-3.4-5.2-3.4S40.9 5.6 40 7.7l-9.7 22-7.2-16.2c-.9-2-2.8-3.3-5-3.3s-4.1 1.3-5 3.3L2.5 37.4c-.8 1.7-.6 3.7.4 5.2 1 1.6 2.7 2.5 4.6 2.5h9.6v12.6H6.7c-.6 0-1 .4-1 1s.4 1 1 1h50.7c.6 0 1-.4 1-1s-.4-1-1-1H46.2V40.9h10.1c1.9 0 3.7-1 4.8-2.6s1.2-3.7.4-5.5m-54 10.3c-1.2 0-2.3-.6-2.9-1.6-.7-1-.8-2.2-.3-3.3l10.6-23.9c.7-1.5 2.1-2.1 3.2-2.1s2.5.5 3.2 2.1l7.9 17.8-.3.7c-.8 1.8-.6 3.8.4 5.4.7 1 1.6 1.8 2.7 2.2-.4 1.5-1.8 2.6-3.4 2.6H19v-6.4c0-.6-.4-1-1-1s-1 .4-1 1V43H7.5zm36.7 14.6H19.1V45.1h9.6c2.6 0 4.8-1.8 5.3-4.2h10.2zm15.2-20.5c-.7 1.1-1.8 1.7-3.1 1.7H46.2V31c0-.6-.4-1-1-1s-1 .4-1 1v7.9H34.1c-1.3 0-2.4-.6-3.1-1.7s-.8-2.4-.3-3.5l.5-1.1v-.1l10.6-24c.7-1.6 2.2-2.2 3.4-2.2s2.7.6 3.4 2.2l11.1 25.1c.5 1.2.4 2.5-.3 3.6" />
  </svg>
);
export default IconTreesAlt7;
