import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/**
 * Overlay component for displaying modal-like content.
 * @param {object} props - The component props.
 * @param {boolean} props.isOpen - Whether the overlay is open.
 * @param {function} props.onClose - Function to call when the overlay is closed.
 * @param {React.ReactNode} props.children - The content to display inside the overlay.
 * @param {React.ReactNode} props.closeButton - Custom close button to be displayed.
 * @param {string} [props.className] - Additional CSS classes for the overlay.
 * @returns {JSX.Element|null} - JSX for the overlay component or null if not open.
 */
const BringzzOverlay = ({ isOpen, onClose, children, closeButton, className }) => {
    if (!isOpen) return null;

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <div
            className={classnames(
                'fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center',
                className
            )}
            onClick={handleOverlayClick}
        >
            <div className="bg-white rounded-lg mx-5 sm:mx-20 md:w-1/3 lg:w-1/3">
                {children}
                {closeButton}
            </div>
        </div>
    );
};

BringzzOverlay.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node,
    closeButton: PropTypes.node,
    className: PropTypes.string,
};

export default BringzzOverlay;
