import * as React from "react";
const IconPiggyBank1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.3 25.3c-.5-.1-1.1.2-1.2.7-1 3.4-2 3.9-2.1 3.9-.2.1-.4-.1-.5-.1-.4-.4-1-.4-1.4 0v.1c-.9-3.8-3.2-7.3-6.6-10.2.9-1.4 1.5-3 1.5-4.8 0-4.7-3.8-8.6-8.6-8.6-4.2 0-7.8 3.1-8.4 7.1-.9-.1-1.8-.1-2.8-.1-2.4 0-4.8.3-7.1.8l-.9.2-.5-.7c-1.2-1.6-3-2.8-5.3-3.6-1.8-.6-6.5-.9-6.9-.8-.3 0-.6.2-.8.4-.2.3-.2.6-.1.9l3 9.9-4.2 6L2.7 28c-.4.2-.7.6-.7 1v10c0 .4.3.8.7 1l5.6 1.6.3.5c1.5 3.1 3.9 5.8 6.9 7.9l.7.5-.8 6.1c0 .3 0 .6.2.8s.5.3.8.3h8.8c.3 0 .6-.2.8-.4l2-2.8 11.3-.9 2.4 3.7c.2.3.5.5.8.5h8.7c.3 0 .6-.1.8-.3s.3-.5.2-.8l-1.3-9.6.4-.4c3.4-3.6 5.2-7.9 5.2-12.5 0-.9-.1-1.7-.2-2.6.3.2.8.5 1.5.5.2 0 .4 0 .6-.1 1.5-.4 2.7-2.1 3.6-5.3.1-.7-.2-1.2-.7-1.4M42.4 8.4C46 8.4 49 11.3 49 15s-2.9 6.6-6.6 6.6c-3.6 0-6.6-2.9-6.6-6.6s2.9-6.6 6.6-6.6m7.5 36.7-.7.8c-.2.2-.3.5-.3.8l1.2 8.9h-7l-2.4-3.7c-.2-.3-.5-.5-.9-.4l-12.3 1c-.3 0-.6.2-.7.4l-1.9 2.8h-7.1l.7-5.5c0-.4-.1-.7-.4-.9l-1.2-.8c-2.7-1.9-4.9-4.4-6.2-7.1l-.7-1.1c-.1-.3-.4-.4-.6-.5L4 38.2v-8.4l5.3-1.5c.2-.1.4-.2.5-.4l4.7-6.7c.2-.3.2-.6.1-.9l-2.8-9c1.5.1 3.8.4 4.8.7 1.9.6 3.4 1.6 4.3 2.9l.9 1.3c.2.3.7.5 1.1.4l1.5-.4c2.1-.5 4.4-.8 6.6-.8.9 0 1.8.1 2.7.1.3 4.4 4 8 8.5 8 2.2 0 4.2-.9 5.8-2.2 4.1 3.5 6.4 8 6.4 12.8.1 4-1.5 7.8-4.5 11" />
  </svg>
);
export default IconPiggyBank1;
