import * as React from "react";
const IconTreeAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.3 21.3C51.3 10.6 42.6 2 32 2s-19.3 8.6-19.3 19.3c0 10.3 8.1 18.7 18.3 19.2V61c0 .6.4 1 1 1s1-.4 1-1V40.5c10.2-.6 18.3-9 18.3-19.2M33 38.5v-4.9l6.2-6.2c.4-.4.4-1 0-1.4s-1-.4-1.4 0L33 30.7v-7c0-.6-.4-1-1-1s-1 .4-1 1v7L26.2 26c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l6.2 6.2v4.9c-9.1-.5-16.3-8-16.3-17.2C14.7 11.7 22.5 4 32 4s17.3 7.7 17.3 17.3c0 9.1-7.2 16.6-16.3 17.2" />
  </svg>
);
export default IconTreeAlt1;
