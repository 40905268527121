import React, { useState } from 'react';
import { BringzzButton, BringzzText } from "@bringzz/components";
import { useNavigation } from 'context/NavigationContext';
import {
    BringzzPageContainer,
    BringzzIcon,
    BringzzRadioList,
    BringzzRadio
} from "@bringzz/components";
import SOFORT_IMAGE from "assets/methods/sofort.png";
import { requestLocationPermission } from 'location';

const ConsumerAddressPage = () => {
    const { backPress } = useNavigation();
    const { navigate } = useNavigation();

    let state = {
        checked: (
            <BringzzIcon
                icon="IconCheck"
                library="Custom"
                className="h-5 w-5 p-1 bg-magic-lilac text-light rounded-full"
                size="12"
            />
        ),
        unChecked: (
            <div className="h-5 w-5 rounded-full border border-neutral-400"></div>
        ),
    };

    const [selectedMethod, setSelectedMethod] = useState(null);
    const [locationConfirmed, setLocationConfirmed] = useState(false);
    const [userAddress, setUserAddress] = useState("");

    // Function to fetch the address from the coordinates
    const fetchAddressFromCoordinates = async (latitude, longitude) => {
        try {
            const response = await fetch(
                `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
            );
            const data = await response.json();
            if (data && data.display_name) {
                setUserAddress(data.display_name);
            }
        } catch (error) {
            console.error("Failed to fetch address", error);
        }
    };

    // Function to get the user's current location
    const getUserLocation = async () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    fetchAddressFromCoordinates(latitude, longitude);
                    setLocationConfirmed(true);
                },
                (error) => {
                    console.error("Error getting location", error);
                }
            );
        }
    };

    return (
        <BringzzPageContainer
            className="bg-white"
            steps={true}
            activeStep={5}
            header={{
                left: (
                    <BringzzIcon
                        size='22'
                        icon='IconChevronLeft'
                        folder='LineIcons'
                        className='cursor-pointer'
                        onClick={backPress}
                    />
                ),
                title: "Join Us",
                right: <BringzzButton size='sm' className="border border-black" onClick={() => {
                    navigate('/register/notification');
                }}>Skip</BringzzButton>
            }}
            footer={
                <div className='pb-8 px-6'>
                    <BringzzButton
                        size='lg'
                        className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
                        onClick={async () => {
                            if (selectedMethod === "add") {
                                navigate("/register/consumer/location");
                            } else {
                                if (!locationConfirmed) {
                                    const status = await requestLocationPermission();
                                    if (status) {
                                        getUserLocation();
                                    }
                                } else {
                                    navigate("/register/notification");
                                }
                            }
                        }}
                        disabled={!selectedMethod}
                    >
                        Continue
                    </BringzzButton>
                </div>
            }
            authenticated={false}
        >
            <div className='relative flex justify-center bg-magic-lilac/20'>
                <div className='relative bg-white w-full bg-cover bg-center flex flex-col'>
                    {/* Content */}
                    <div className='flex-grow flex flex-col py-8 px-4'>
                        <div className='z-10 flex flex-col '>
                            <div className='pl-2 flex flex-col space-y-6 md:max-w-lg lg:max-w-xl xl:max-w-2xl'>
                                <BringzzText tag='h1' className='pl-2 text-midnight-blue font-bold'>
                                    Your Address
                                </BringzzText>
                                <BringzzText tag="h3" className="font-normal">We’ll be using your address for your bills and preferred delivery address. You can change this later, if you need.</BringzzText>
                            </div>
                            <div className='mt-8'>
                                <BringzzRadioList
                                    onChange={(newValue) => setSelectedMethod(newValue)}
                                    state={state}
                                    className="divide-y border-t"
                                >
                                    <BringzzRadio left={<BringzzIcon
                                        icon="IconStar"
                                        folder="LineIcons"
                                        className="flex items-center w-10 justify-center rounded-full"
                                        src={SOFORT_IMAGE}
                                        size='24'
                                    />} leftClassname='w-12' className="p-2" value="current">
                                        <div>
                                            <BringzzText tag="h3">Use current location</BringzzText>
                                            {locationConfirmed && <BringzzText tag='h5' className='font-normal'>{userAddress || "Fetching address..."}</BringzzText>}
                                        </div>
                                    </BringzzRadio>
                                    <BringzzRadio left={<BringzzIcon
                                        icon="IconClose"
                                        folder="LineIcons"
                                        className="flex items-center w-10 justify-center rounded-full"
                                        src={SOFORT_IMAGE}
                                        size='24'
                                    />} leftClassname='w-12' className="p-2" value="add">
                                        <div>
                                            <BringzzText tag="h3">Add new address</BringzzText>
                                        </div>
                                    </BringzzRadio>
                                </BringzzRadioList>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BringzzPageContainer>
    );
};

export default ConsumerAddressPage;
