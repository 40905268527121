import * as React from "react";
const IconCutleryAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55 2c-.6 0-1 .4-1 1v16.5c0 3.9-3.3 7.1-7.4 7.1h-.2V3c0-.6-.4-1-1-1s-1 .4-1 1v23.6h-.2c-4.1 0-7.4-3.2-7.4-7.1V3c0-.6-.4-1-1-1s-1 .4-1 1v16.5c0 5 4.2 9.1 9.4 9.1h.2V61c0 .6.4 1 1 1s1-.4 1-1V28.6h.2c5.2 0 9.4-4.1 9.4-9.1V3c0-.6-.4-1-1-1M20.1 2.3h-4.2c-3.2 0-5.9 2.6-5.9 5.8L8 38.4c0 .3.1.5.3.7s.5.3.7.3h10.1V61c0 .6.4 1 1 1s1-.4 1-1V3.3c0-.5-.5-1-1-1m-10 35.1L12 8.1c0-2.1 1.7-3.8 3.9-3.8h3.2v33.1z" />
  </svg>
);
export default IconCutleryAlt;
