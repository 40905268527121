import * as React from "react";
const IconInnerAlign = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.8 4h5.8c1.8 0 3.3 1.5 3.3 3.4v5.8c0 .6.4 1 1 1s1-.4 1-1V7.4c0-3-2.4-5.4-5.3-5.4h-5.8c-.6 0-1 .4-1 1s.4 1 1 1M2.9 14.1c.6 0 1-.4 1-1V7.4C3.9 5.5 5.4 4 7.3 4h5.8c.6 0 1-.4 1-1s-.4-1-1-1H7.3c-3 0-5.4 2.4-5.4 5.4v5.8c0 .5.5.9 1 .9M13.1 60H7.3c-1.8 0-3.4-1.5-3.4-3.3v-5.8c0-.6-.4-1-1-1s-1 .4-1 1v5.8c0 3 2.4 5.3 5.4 5.3h5.8c.6 0 1-.4 1-1s-.5-1-1-1M60.9 49.8c-.6 0-1 .4-1 1v5.8c0 1.8-1.5 3.3-3.3 3.3h-5.8c-.6 0-1 .4-1 1s.4 1 1 1h5.8c3 0 5.3-2.4 5.3-5.3v-5.8c0-.5-.4-1-1-1M21.9 60h-1.4c-.6 0-1 .4-1 1s.4 1 1 1h1.4c.6 0 1-.4 1-1s-.4-1-1-1M43.7 60h-1.5c-.6 0-1 .4-1 1s.4 1 1 1h1.5c.6 0 1-.4 1-1s-.5-1-1-1M20.5 4h1.4c.6 0 1-.4 1-1s-.4-1-1-1h-1.4c-.6 0-1 .4-1 1s.4 1 1 1M42.2 4h1.5c.6 0 1-.4 1-1s-.4-1-1-1h-1.5c-.6 0-1 .4-1 1s.5 1 1 1M61.1 19.4c-.6 0-1 .4-1 1v1.5c0 .6.4 1 1 1s1-.4 1-1v-1.5c0-.6-.5-1-1-1M61.1 41.2c-.6 0-1 .4-1 1v1.4c0 .6.4 1 1 1s1-.4 1-1v-1.4c0-.6-.5-1-1-1M3.1 19.4c-.6 0-1 .4-1 1v1.5c0 .6.4 1 1 1s1-.4 1-1v-1.5c0-.6-.5-1-1-1M3.1 44.6c.6 0 1-.4 1-1v-1.4c0-.6-.4-1-1-1s-1 .4-1 1v1.4c0 .6.4 1 1 1M60.9 33c.6 0 1-.4 1-1s-.4-1-1-1h-28V3c0-.6-.4-1-1-1s-1 .4-1 1v28h-28c-.6 0-1 .4-1 1s.4 1 1 1h28v28c0 .6.4 1 1 1s1-.4 1-1V33z" />
  </svg>
);
export default IconInnerAlign;
