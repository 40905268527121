import * as React from "react";
const IconVespaMotorcycle = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M30.903 17.407a4 4 0 0 1-1.89-3.732L25 12.97l.346-1.97 4.338.762A4 4 0 0 1 33 10h3.385c.34 0 .615.275.615.615v6.77c0 .34-.276.615-.615.615H36v8.25q.325-.093.661-.153a6.5 6.5 0 0 1 7.22 4.134c.313.84-.02 1.607-.594 2.064a5.501 5.501 0 0 1-10.733 1.892c-.541-.182-1.011-.59-1.189-1.187H19.979a5.5 5.5 0 0 1-10.956 0H4.47A.457.457 0 0 1 4 32.556c0-3.536 2.027-6.623 5.04-8.276A1 1 0 0 1 9 24v-3a3 3 0 0 1 3-3h9a3 3 0 0 1 3 3v3a1 1 0 0 1-1 1v1h6.24zM31 14a2 2 0 0 1 2-2h2v4h-2a2 2 0 0 1-2-2m-2.148 14H23v3h5.272zm1.457 3h1.156A6.5 6.5 0 0 1 34 27.218V18h-1.175zm4.35 3.066a3.501 3.501 0 0 0 6.609-1.165zM11 21a1 1 0 0 1 1-1h9a1 1 0 0 1 1 1v2H11zM6.174 31c.76-3.365 3.939-6 7.912-6H21v5.968a.18.18 0 0 1-.111.032zm11.445 2.023q.189.006.34.013a3.5 3.5 0 0 1-6.918 0q.151-.008.34-.013C12.117 33 13.11 33 14.5 33s2.383 0 3.12.023m22.752-4.21a4.5 4.5 0 0 0-7.076 3.463l8.642-1.524a4.5 4.5 0 0 0-1.566-1.94"
      clipRule="evenodd"
    />
  </svg>
);
export default IconVespaMotorcycle;
