import * as React from "react";
const IconBox6 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.5 48.8h-3.4q.3-.3.3-.6v-3.9c0-3.6-2.9-6.4-6.4-6.4-1.5 0-2.9.5-4.1 1.5-.4.4-.5 1-.1 1.4s1 .5 1.4.1c.8-.7 1.8-1 2.8-1 2.4 0 4.4 2 4.4 4.4v3.9c0 .2.1.5.3.6H40.3c-.6 0-1 .4-1 1V61c0 .6.4 1 1 1h19.1c.6 0 1-.4 1-1V49.8c.1-.5-.4-1-.9-1m-1 11.2H41.3v-9.2h17.1V60z" />
    <path d="M29.8 56.5H9.6c-2.3 0-4.1-1.8-4.1-4.1V20.2c0-.3.1-.6.1-.9h49.9c.1.3.1.6.1.9v9.9c0 .6.4 1 1 1s1-.4 1-1v-9.9c0-1.5-.6-3-1.6-4.1L45.3 4c-1.2-1.3-2.8-2-4.6-2H20.5c-1.7 0-3.4.7-4.6 2L5.1 16.2c-1 1.1-1.6 2.6-1.6 4.1v32.1c0 3.4 2.7 6.1 6.1 6.1h20.2c.6 0 1-.4 1-1s-.4-1-1-1m14-51.1 10.7 11.9H31.6V4h9.1c1.2 0 2.3.5 3.1 1.4m-26.4 0c.8-.9 1.9-1.4 3.1-1.4h9.1v13.3H6.8z" />
  </svg>
);
export default IconBox6;
