import * as React from "react";
const IconSwitch = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.7 49.1 51.1 39c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l8 8.4H4c-.6 0-1 .4-1 1s.4 1 1 1h53.7l-8 8.4c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3l9.6-10.1c.4-.4.4-1 0-1.4M12.9 25c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-8-8.4H60c.6 0 1-.4 1-1s-.4-1-1-1H6.3l8-8.4c.4-.4.4-1 0-1.4s-1-.4-1.4 0L3.3 13.5c-.4.4-.4 1 0 1.4z" />
  </svg>
);
export default IconSwitch;
