import * as React from "react";
const IconCenterAlign6 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 3.9c-.6 0-1 .4-1 1v50.8c0 1.8-1.5 3.3-3.3 3.3H33V48.7h11.8c3 0 5.3-2.4 5.3-5.3v-1.9c0-3-2.4-5.3-5.3-5.3H33V28h7.9c3 0 5.4-2.4 5.4-5.3v-1.9c0-3-2.4-5.4-5.4-5.4H33V5h16.9c.6 0 1-.4 1-1s-.4-1-1-1H14.1c-.6 0-1 .4-1 1s.4 1 1 1H31v10.3h-7.9c-2.9 0-5.3 2.4-5.3 5.4v1.9c0 2.9 2.4 5.3 5.3 5.3H31v8.2H19.2c-3 0-5.4 2.4-5.4 5.3v1.9c0 3 2.4 5.3 5.4 5.3H31V59H7.4C5.5 59 4 57.5 4 55.7V4.9c0-.6-.4-1-1-1s-1 .4-1 1v50.8c0 3 2.4 5.3 5.4 5.3h49.3c3 0 5.3-2.4 5.3-5.3V4.9c0-.5-.4-1-1-1M19.7 22.6v-1.9c0-1.8 1.5-3.4 3.3-3.4h17.9c1.8 0 3.4 1.5 3.4 3.4v1.9c0 1.8-1.5 3.3-3.4 3.3H23.1c-1.9 0-3.4-1.5-3.4-3.3m-3.9 20.8v-1.9c0-1.8 1.5-3.3 3.4-3.3h25.6c1.8 0 3.3 1.5 3.3 3.3v1.9c0 1.8-1.5 3.3-3.3 3.3H19.2c-1.9 0-3.4-1.5-3.4-3.3" />
  </svg>
);
export default IconCenterAlign6;
