import * as React from "react";
const IconApartments = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.6 2H29.4c-2.9 0-5.2 2.3-5.2 5.2v9.6l-1.8-1.5c-1.9-1.6-4.7-1.6-6.6 0l-9.7 7.9c-1.2 1-1.9 2.5-1.9 4v29.5c0 2.9 2.3 5.2 5.2 5.2h45.1c2.9 0 5.2-2.3 5.2-5.2V7.2c.1-2.9-2.2-5.2-5.1-5.2M23.2 60h-8v-9.6c0-2.2 1.8-4 4-4 1.1 0 2.1.4 2.8 1.2.8.8 1.2 1.8 1.2 2.8zm2 0v-9.6c0-1.6-.6-3.1-1.8-4.2-1.1-1.1-2.6-1.8-4.2-1.8-3.3 0-6 2.7-6 6V60H9.4c-1.8 0-3.2-1.5-3.2-3.2V27.2c0-1 .4-1.9 1.2-2.5l9.7-7.9c1.2-1 2.9-1 4.1 0l9.6 7.9c.8.6 1.2 1.5 1.2 2.5V60zm32.6-3.2c0 1.8-1.5 3.2-3.2 3.2H34V27.2c0-1.6-.7-3.1-1.9-4.1l-5.8-4.7V7.2c0-1.8 1.5-3.2 3.2-3.2h25.2c1.8 0 3.2 1.5 3.2 3.2v49.6z" />
    <path d="M48.2 14.1h-8.7c-.6 0-1 .4-1 1s.4 1 1 1h8.7c.6 0 1-.4 1-1s-.5-1-1-1M48.2 24.1h-8.7c-.6 0-1 .4-1 1s.4 1 1 1h8.7c.6 0 1-.4 1-1s-.5-1-1-1M48.2 34.1h-8.7c-.6 0-1 .4-1 1s.4 1 1 1h8.7c.6 0 1-.4 1-1s-.5-1-1-1M48.2 44.1h-8.7c-.6 0-1 .4-1 1s.4 1 1 1h8.7c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconApartments;
