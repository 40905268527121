import * as React from "react";
const IconFingerprint1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 65 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M26.9 38.9c-.6 0-1 .4-1 1V46c0 .6.4 1 1 1s1-.4 1-1v-6.1c0-.5-.4-1-1-1M32.5 25.4c-3.6 0-6.6 2.8-6.6 6.2v2.2c0 .6.4 1 1 1s1-.4 1-1v-2.2c0-2.3 2.1-4.2 4.6-4.2s4.6 1.9 4.6 4.2c0 .6.4 1 1 1s1-.4 1-1c0-3.4-3-6.2-6.6-6.2M19.7 25.2c-.5-.2-1.1 0-1.3.5-.6 1.4-.9 2.9-.9 4.4v13c0 .6.4 1 1 1s1-.4 1-1v-13c0-1.3.2-2.5.7-3.7.2-.4 0-1-.5-1.2M38.1 35.7c-.6 0-1 .4-1 1V46c0 .6.4 1 1 1s1-.4 1-1v-9.3c0-.6-.4-1-1-1" />
    <path d="M32.5 3c-16 0-29 13-29 29s13 29 29 29 29-13 29-29-13-29-29-29m0 56c-14.9 0-27-12.1-27-27s12.1-27 27-27 27 12.1 27 27-12.1 27-27 27" />
    <path d="M46.5 33.3c-.6 0-1 .4-1 1v8.9c0 .6.4 1 1 1s1-.4 1-1v-8.9c0-.6-.4-1-1-1M46.3 27.8c.5-.2.8-.7.6-1.3C45 20.9 39.1 17 32.5 17c-2.9 0-5.8.7-8.3 2.2-.5.3-.6.9-.4 1.4s.9.6 1.4.4c2.2-1.3 4.7-1.9 7.3-1.9 5.8 0 10.9 3.3 12.5 8.1.1.4.5.7.9.7.2-.1.3-.1.4-.1" />
  </svg>
);
export default IconFingerprint1;
