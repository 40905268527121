import * as React from "react";
const IconDesert = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62 51.5c-.1-.5-.7-.9-1.2-.7-2.7.6-7 .7-11.8.3v-11h3.2c2.8 0 5-2.2 5-5v-4.9c0-.6-.4-1-1-1s-1 .4-1 1v4.9c0 1.7-1.3 3-3 3H49V17.8c0-.6-.4-1-1-1s-1 .4-1 1v15h-6.1c-1.7 0-3-1.3-3-3v-4.9c0-.6-.4-1-1-1s-1 .4-1 1v4.9c0 2.8 2.2 5 5 5H47V51c-6.6-.7-13.7-2.1-18.8-4.1-9.8-3.7-24.8-.2-25.4 0-.6.1-.9.6-.8 1.2.1.5.7.9 1.2.7.1 0 15-3.5 24.2 0 7.3 2.8 18.8 4.6 27 4.6 2.7 0 5-.2 6.7-.6.7-.2 1-.7.9-1.3M11.5 24.3c3.8 0 6.8-3.1 6.8-6.8 0-3.8-3.1-6.8-6.8-6.8s-6.8 3.1-6.8 6.8c0 3.8 3 6.8 6.8 6.8m0-11.6c2.7 0 4.8 2.2 4.8 4.8s-2.2 4.8-4.8 4.8-4.8-2.2-4.8-4.8 2.1-4.8 4.8-4.8" />
  </svg>
);
export default IconDesert;
