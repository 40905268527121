import * as React from "react";
const IconDribbbleCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 1C14.9 1 1 14.9 1 32s13.9 31 31 31 31-13.9 31-31S49.1 1 32 1m28.9 33.5c-7-.6-14.5.1-21.7 1.9-1.2-3.4-2.6-6.8-4.1-10C42.8 24 50 20.3 55.9 15.6 59.1 20.3 61 25.9 61 32c0 .8 0 1.6-.1 2.5M54.7 14c-5.9 4.6-12.9 8.2-20.5 10.5-3.9-8-8.4-14.7-13.1-19.4C24.4 3.8 28.1 3 32 3c9.2 0 17.4 4.3 22.7 11M19.1 6c4.7 4.5 9.2 11.1 13.2 19.1-9.8 2.6-19.9 3-28.7 1.1C5.4 17.4 11.2 10 19.1 6M3.3 28.2c3.8.8 7.8 1.3 12 1.3 5.9 0 12-.8 17.9-2.5 1.5 3.2 2.8 6.5 4.1 9.9h-.2c-10.2 3-19.2 8.1-26.2 14.9C6 46.6 3 39.7 3 32c0-1.3.1-2.6.3-3.8m9 25c6.7-6.5 15.4-11.5 25.3-14.4l.3-.1c.5 1.5 1 3 1.4 4.5 1.6 5.3 2.7 10.6 3.4 15.6C39.4 60.3 35.8 61 32 61c-7.6 0-14.5-3-19.7-7.8m32.3 4.9c-.8-5-1.9-10.1-3.4-15.3-.5-1.5-.9-3-1.4-4.5 7-1.8 14.2-2.4 20.9-1.9C59.2 46 53 54 44.6 58.1" />
  </svg>
);
export default IconDribbbleCopy;
