import * as React from "react";
const IconPhoneCallDollar = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.3 49.8c-5.4-.5-10.7-1.9-15.7-4.1-.5-.2-1 0-1.3.4l-4.7 8.8C22.5 50.2 15 42.7 10.3 33.6l8.8-4.7c.5-.2.7-.8.4-1.3-2.2-5-3.6-10.2-4.1-15.7 0-.5-.5-.9-1-.9H4.2c-.3 0-.5.1-.7.3s-.3.4-.3.7c0 7.5 1.6 14.8 4.9 21.6C13 43.8 21.4 52.2 31.6 57.1c6.8 3.2 14.1 4.9 21.6 4.9.3 0 .5-.1.7-.3s.3-.4.3-.7V50.8c0-.5-.3-1-.9-1M5.2 13h8.3c.5 5 1.8 9.9 3.8 14.6l-7.9 4.2c-2.6-6-4-12.3-4.2-18.8m28.3 42.7 4.2-7.9c4.6 2 9.5 3.3 14.6 3.8v8.3c-6.6 0-12.9-1.5-18.8-4.2M41.8 14.6h5.6c.6 0 1-.4 1-1s-.4-1-1-1H45V11c0-.6-.4-1-1-1s-1 .4-1 1v1.6h-1.2c-2.2 0-4 1.8-4 4s1.8 4 4 4h2.6c1.1 0 2 .9 2 2s-.9 2-2 2h-5.6c-.6 0-1 .4-1 1s.4 1 1 1H43v1.6c0 .6.4 1 1 1s1-.4 1-1v-1.6c2-.3 3.5-1.9 3.5-4 0-2.2-1.8-4-4-4h-2.6c-1.1 0-2-.9-2-2s.8-2 1.9-2" />
    <path d="M43.1 2c-9.7 0-17.7 7.9-17.7 17.7s7.9 17.7 17.7 17.7 17.7-7.9 17.7-17.7S52.8 2 43.1 2m0 33.3c-8.6 0-15.7-7-15.7-15.7S34.5 4 43.1 4s15.7 7 15.7 15.7-7.1 15.6-15.7 15.6" />
  </svg>
);
export default IconPhoneCallDollar;
