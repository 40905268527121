import * as React from "react";
const IconHandCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path
      d="M51.8 45.6V15.5c0-2.4-2.2-4.4-4.9-4.4-1.1 0-2.1.3-2.9.9V8.8c0-2.4-2.2-4.4-4.9-4.4-1.4 0-2.6.5-3.5 1.3-.8-1.4-2.4-2.3-4.3-2.3-2.6 0-4.7 1.8-4.9 4.1-.8-.6-1.8-.9-2.9-.9-2.7 0-4.9 2-4.9 4.4v24.6l-3.5-4.2C13.2 29 9.7 28.2 7 29.6c-1.3.7-2.2 1.9-2.5 3.3s0 2.9 1 4.2c1.8 2.4 3.7 4.9 5.5 7.3l3.3 4.4c.5.6.9 1.3 1.4 1.9 1.4 1.9 2.8 4 4.7 5.6 3.6 3.1 8.7 4.2 13.4 4.2 2.3 0 4.6-.3 6.6-.7 11.5-2.4 11.4-11 11.4-14.2M40 57.9c-5.7 1.2-13.5 1.1-18.3-3.1-1.7-1.4-3-3.3-4.4-5.2-.5-.7-.9-1.3-1.4-2l-3.3-4.4C10.7 40.8 8.9 38.4 7 36c-.6-.8-.8-1.7-.6-2.6.2-.8.7-1.5 1.5-2 1.8-1 4.3-.4 5.6 1.4l5.3 6.3c.3.3.7.4 1.1.3s.7-.5.7-.9V11.1c0-1.3 1.3-2.4 2.9-2.4s2.8 1 2.9 2.3v17.5c0 .6.4 1 1 1s1-.4 1-1V7.8c0-1.3 1.3-2.4 2.9-2.4s2.9 1.1 2.9 2.4v21.3c0 .6.4 1 1 1s1-.4 1-1V8.8c0-1.3 1.3-2.4 2.9-2.4S42 7.5 42 8.8v22.3c0 .6.4 1 1 1s1-.4 1-1V15.4c.1-1.3 1.3-2.3 2.9-2.3s2.9 1.1 2.9 2.4v30.2c0 3.2.1 10.1-9.8 12.2"
      style={{
        fill: "#030104",
      }}
    />
  </svg>
);
export default IconHandCopy;
