import * as React from "react";
const IconAlignRight = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.4 2c-.6 0-1 .4-1 1v58c0 .6.4 1 1 1s1-.4 1-1V3c0-.6-.5-1-1-1M43.1 11.5H10.4c-3.2 0-5.7 2.6-5.7 5.7v2.1c0 3.2 2.6 5.7 5.7 5.7h32.7c3.2 0 5.7-2.6 5.7-5.7v-2.1c0-3.1-2.6-5.7-5.7-5.7m3.7 7.8c0 2.1-1.7 3.7-3.7 3.7H10.4c-2.1 0-3.7-1.7-3.7-3.7v-2.1c0-2.1 1.7-3.7 3.7-3.7h32.7c2.1 0 3.7 1.7 3.7 3.7zM43.1 38.9H23.6c-3.2 0-5.7 2.6-5.7 5.7v2.1c0 3.2 2.6 5.7 5.7 5.7h19.5c3.2 0 5.7-2.6 5.7-5.7v-2.1c0-3.1-2.6-5.7-5.7-5.7m3.7 7.9c0 2.1-1.7 3.7-3.7 3.7H23.6c-2.1 0-3.7-1.7-3.7-3.7v-2.1c0-2.1 1.7-3.7 3.7-3.7h19.5c2.1 0 3.7 1.7 3.7 3.7z" />
  </svg>
);
export default IconAlignRight;
