import * as React from "react";
const IconPig = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.3 24c-.5-.2-1.1.2-1.2.7-1.1 3.8-2.7 4.8-3.6 5-1.7-9.7-11.9-17.2-24.3-17.2-2.4 0-4.7.3-6.9.8l-.8.2-.5-.7c-1.2-1.6-2.9-2.8-5.1-3.5q-1.05-.3-3.6-.6c-1-.1-1.9.3-2.6 1.1-.6.8-.8 1.8-.5 2.7l2.1 6.9-4 5.7-4.1 1.2c-1.3.3-2.2 1.5-2.2 2.8v6.7c0 1.3.9 2.5 2.1 2.8l4 1.1.2.5c1.4 3 3.8 5.6 6.7 7.6l.6.4-.4 3.8c-.1.8.1 1.7.7 2.3s1.4 1 2.2 1h5.3c1 0 1.9-.5 2.4-1.3l1.3-1.9 10.8-.9 1.8 2.7c.5.8 1.5 1.3 2.5 1.3h5.2c.8 0 1.6-.4 2.2-1s.8-1.5.7-2.3l-.9-7 .4-.4c3.3-3.5 5-7.7 5-12.1v-.8c1.4-.3 3.9-1.5 5.3-6.4 0-.5-.3-1.1-.8-1.2M49.2 43.1l-.7.8c-.2.2-.3.5-.3.8l1 7.5c0 .3 0 .5-.2.7s-.4.3-.7.3h-5.2c-.3 0-.6-.2-.8-.4l-2.1-3.2c-.2-.3-.5-.5-.8-.5h-.1l-11.9.9c-.3 0-.6.2-.7.4l-1.6 2.3c-.2.2-.5.4-.8.4H19c-.3 0-.5-.1-.7-.3s-.3-.5-.2-.7l.6-4.3c0-.4-.1-.7-.4-1l-1.1-.8c-2.6-1.8-4.7-4.2-6-6.9l-.4-.9c-.1-.3-.4-.4-.6-.5l-4.5-1.3c-.4.2-.7-.2-.7-.6v-6.7c0-.4.3-.8.7-.9l4.5-1.3c.2-.1.4-.2.5-.4l4.5-6.4c.2-.3.2-.6.1-.9l-2.2-7.3c-.1-.3 0-.6.2-.9.2-.2.5-.4.8-.3 1.5.2 2.6.3 3.2.5 1.8.6 3.2 1.5 4.1 2.8l.9 1.2c.2.3.7.5 1.1.4l1.5-.4c2-.5 4.2-.8 6.4-.8 12.4 0 22.5 8.1 22.5 17.9-.1 4-1.7 7.7-4.6 10.8" />
  </svg>
);
export default IconPig;
