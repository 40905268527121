import * as React from "react";
const IconWhale = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.3 29.2c-.5-.6-1.3-1-2.1-1H37.7c-.6 0-1 .4-1 1s.4 1 1 1h21.5c.3 0 .5.2.6.3s.2.3.2.6C58.4 43 48.2 51.8 36.3 51.8l-4.7-.1c-.3 0-.6.1-.8.4-4.2 5-10.4 7.9-17 7.9-.5 0-.7-.4-.8-.6s-.2-.6.3-.9l.3-.3c1.7-1.3 3.6-2.7 5.6-5.6.2-.3.2-.6.1-.9s-.4-.5-.7-.6C11.7 49.3 6.4 44 4.6 37c-1.8-6.8.1-14 5.2-19 3.5-3.5 7.8-5.4 12.8-5.8.4 0 1.4-.1 1.4-1C24 7.3 27.2 4 31.2 4c.4 0 .8.4.8.8v5.8c0 1.6-.6 3.2-1.6 4.4-.3.4-.3.9 0 1.3 1 1.2 1.6 2.8 1.6 4.4v4c0 .4-.4.8-.8.8-3.6 0-6.6-2.6-7.1-6.2 0-.3-.2-.5-.4-.7s-.5-.2-.8-.1c-1.9.4-3.5 1.5-5.2 3.3-1.2 1.6-1.5 3.7-.6 5.5s2.7 2.9 4.7 2.9c.6 0 1-.4 1-1s-.4-1-1-1c-1.3 0-2.3-.7-2.9-1.8s-.4-2.4.3-3.3c1-1.1 2-1.9 3.1-2.3 1.1 3.9 4.7 6.7 8.8 6.7 1.6 0 2.8-1.3 2.8-2.8v-4c0-1.8-.6-3.6-1.6-5.1 1-1.5 1.6-3.3 1.6-5.1V4.8c0-1.6-1.3-2.8-2.8-2.8-4.7 0-8.7 3.6-9.1 8.2-5.3.5-9.9 2.6-13.6 6.3C2.8 22.1.7 30 2.8 37.6c1.9 7.2 7.1 12.7 14.1 15.1-1.5 1.9-2.9 2.9-4.3 4l-.4.3c-1 .7-1.4 2-1 3.1.4 1.2 1.4 1.9 2.7 1.9 7 0 13.6-3 18.2-8.2h4.3c12.9 0 23.9-9.6 25.6-22.4.1-.7-.2-1.5-.7-2.2" />
    <path d="M53 36.5c0-1.4-1.2-2.6-2.6-2.6s-2.6 1.2-2.6 2.6 1.2 2.6 2.6 2.6c1.4-.1 2.6-1.2 2.6-2.6" />
  </svg>
);
export default IconWhale;
