import * as React from "react";
const IconHome1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.1 33.7H38v-8.4h8.7c2.2 0 4-1.8 4-4V6c0-2.2-1.8-4-4-4H17.4c-2.2 0-4 1.8-4 4v15.3c0 2.2 1.8 4 4 4H26v8.4H12.9c-2.2 0-4 1.8-4 4V53c0 2.1 1.6 3.7 3.6 4v4c0 .6.4 1 1 1s1-.4 1-1v-4h35v4c0 .6.4 1 1 1s1-.4 1-1v-4c2-.2 3.6-1.9 3.6-4V37.7c0-2.2-1.8-4-4-4M15.4 21.3V6c0-1.1.9-2 2-2h29.3c1.1 0 2 .9 2 2v15.3c0 1.1-.9 2-2 2H17.4c-1.1 0-2-.9-2-2m12.6 4h8v8.4h-8zM10.9 53V37.7c0-1.1.9-2 2-2H31V55H12.9c-1.1 0-2-.9-2-2m42.2 0c0 1.1-.9 2-2 2H33V35.7h18.1c1.1 0 2 .9 2 2z" />
    <path d="M24.2 48.1c.6 0 1-.4 1-1v-5c0-.6-.4-1-1-1s-1 .4-1 1v5c0 .5.4 1 1 1M39.8 41.1c-.6 0-1 .4-1 1v5c0 .6.4 1 1 1s1-.4 1-1v-5c0-.5-.4-1-1-1" />
  </svg>
);
export default IconHome1;
