import * as React from "react";
const IconSquares3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.1 4.5H33.6c-2.7 0-4.9 2.2-4.9 4.9v16.9H6c-2.2 0-4 1.8-4 4v25.3c0 2.2 1.8 4 4 4h25.3c2.2 0 4-1.8 4-4V37.7h21.8c2.7 0 4.9-2.2 4.9-4.9V9.4c0-2.7-2.2-4.9-4.9-4.9m-23.8 51c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V30.2c0-1.1.9-2 2-2h25.3c1.1 0 2 .9 2 2zM60 32.9c0 1.6-1.3 2.9-2.9 2.9H35.3v-5.5c0-2.2-1.8-4-4-4h-.6V9.4c0-1.6 1.3-2.9 2.9-2.9h23.5c1.6 0 2.9 1.3 2.9 2.9z" />
    <path d="M25.2 38.5 19.1 35c-.3-.2-.7-.2-1 0L12 38.5c-.3.2-.5.5-.5.9v7c0 .4.2.7.5.9l6.1 3.5c.2.1.3.1.5.1s.3 0 .5-.1l6.1-3.5c.3-.2.5-.5.5-.9v-7c0-.4-.2-.7-.5-.9m-1.5 7.3-5.1 2.9-5.1-2.9V40l5.1-2.9 5.1 2.9z" />
  </svg>
);
export default IconSquares3;
