import * as React from "react";
const Icon5g = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M26.4 39.4c.6 0 1-.4 1-1s-.4-1-1-1h-12c-.6 0-1 .4-1 1v11.3c0 .6.4 1 1 1H25c.3 0 .5.2.5.5v8.4c0 .3-.2.5-.5.5H14.3c-.6 0-1 .4-1 1s.4 1 1 1h10.6c1.4 0 2.5-1.1 2.5-2.5v-8.4c0-1.4-1.1-2.5-2.5-2.5h-9.6v-9.3zM39.1 39.4h10.6c.6 0 1-.4 1-1s-.4-1-1-1H39.1c-1.4 0-2.5 1.1-2.5 2.5v19.7c0 1.4 1.1 2.5 2.5 2.5h9.1c1.4 0 2.5-1.1 2.5-2.5v-6.9c0-1.4-1.1-2.5-2.5-2.5h-3.1c-.6 0-1 .4-1 1s.4 1 1 1h3.1c.3 0 .5.2.5.5v6.9c0 .3-.2.5-.5.5h-9.1c-.3 0-.5-.2-.5-.5V39.9c0-.3.2-.5.5-.5M15.4 21.3c9.2-9.2 24.1-9.2 33.3 0 .4.4 1 .4 1.4 0s.4-1 0-1.4c-10-10-26.1-10-36.1 0-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3" />
    <path d="M57.4 12.5C50.6 5.7 41.6 2 32 2S13.4 5.7 6.6 12.5c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3C14.4 7.5 22.9 4 32 4s17.6 3.5 24 9.9c.4.4 1 .4 1.4 0s.4-1 0-1.4M42 28.9c.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4-5.9-5.9-15.6-5.9-21.5 0-.4.4-.4 1 0 1.4s1 .4 1.4 0c2.5-2.5 5.8-3.9 9.3-3.9s6.8 1.4 9.3 3.9c.3.2.6.3.8.3" />
  </svg>
);
export default Icon5g;
