import * as React from "react";
const IconIdeaEnvelope = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.9 29.8q0-.15 0 0 0-.15 0 0 0-.15 0 0c-.3-.5-.7-.8-1.1-1.2L45.6 17.7c.1-.5.1-1.1.1-1.6.1-3.6-1.3-7.1-3.8-9.8S36 2.1 32.3 2 25 3.3 22.4 5.9c-2.7 2.6-4.1 6.1-4.1 9.8 0 .5 0 1.1.1 1.6L4.2 28.1c-.6.4-1 1-1.4 1.6v.1c-.4.7-.6 1.6-.6 2.4v24.5c0 2.9 2.3 5.2 5.2 5.2h49.4c2.9 0 5.2-2.3 5.2-5.2v-24c-.1-1.1-.5-2.1-1.1-2.9M23.8 7.3C26 5.2 28.9 4 32 4h.3c3.1.1 6 1.4 8.2 3.7s3.3 5.3 3.3 8.4c-.1 3.9-2.2 7.5-5.5 9.6-.6.4-1.1.9-1.5 1.4h-9.5c-.4-.6-.9-1.1-1.4-1.4-3.5-2.2-5.5-5.9-5.5-9.9-.1-3.3 1.1-6.3 3.4-8.5M28 29.1h7.9c-.1.3-.1.6-.1.9v2.5c0 1.8-1.4 3.2-3.2 3.2h-1.3c-1.8 0-3.2-1.4-3.2-3.2v-2.4c.1-.4 0-.7-.1-1m-9.2-9.6c.9 3.2 3 6 5.9 7.9.9.5 1.4 1.6 1.4 2.7v2.4c0 2.9 2.3 5.2 5.2 5.2h1.3c2.9 0 5.2-2.3 5.2-5.2V30c0-1.1.5-2.1 1.4-2.7 2.8-1.8 4.8-4.4 5.8-7.5L58.4 30 32 44.8 5.2 29.9l.2-.2zm41.1 37.3c0 1.8-1.4 3.2-3.2 3.2H7.3c-1.8 0-3.2-1.4-3.2-3.2V32.3c0-.2 0-.4.1-.6l27.3 15.1c.2.1.3.1.5.1s.3 0 .5-.1l27.2-15.2c.1.4.2.7.2 1.1z" />
  </svg>
);
export default IconIdeaEnvelope;
