import * as React from "react";
const IconSymbol = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M44.6 62H19.4c-2.2 0-4.2-1.2-5.2-3.1s-.9-4.3.4-6l13.1-18.6c.9-1.3.9-3.1 0-4.4L14.6 11.2c-1.3-1.8-1.4-4.1-.4-6s3-3.1 5.2-3.1h25.3c3.2 0 5.8 2.6 5.8 5.8v3.5c0 .6-.4 1-1 1s-1-.4-1-1V7.8c0-2.1-1.7-3.8-3.8-3.8H19.4c-1.5 0-2.7.8-3.4 2.1s-.6 2.8.3 4l13.1 18.6c1.4 2 1.4 4.7 0 6.7L16.2 54c-.8 1.2-.9 2.7-.3 4 .7 1.3 1.9 2.1 3.4 2.1h25.3c2.1 0 3.8-1.7 3.8-3.8v-3.5c0-.6.4-1 1-1s1 .4 1 1v3.5c.1 3.1-2.5 5.7-5.8 5.7" />
  </svg>
);
export default IconSymbol;
