import * as React from "react";
const IconUserQuestion = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M46.4 32.9c-.2-.3-.4-.6-.7-.8-3.8-4.5-8.8-7-14.2-7.4 5.7-.6 10.2-5.5 10.2-11.3C41.7 7.1 36.6 2 30.3 2S18.9 7.1 18.9 13.4c0 5.9 4.5 10.7 10.2 11.3-5.4.3-10.4 2.9-14.2 7.4-4 4.7-6.2 11.3-6.4 18.5q0 .6.6.9c2.5 1.3 11.6 5.4 21.2 5.4 3.1 0 6.3-.4 9.5-1.1.5-.1.9-.7.8-1.2s-.7-.9-1.2-.8c-3.1.7-6.2 1-9.1 1-8.6 0-16.7-3.5-19.8-4.9.2-6.5 2.3-12.4 5.9-16.6C20 29 25 26.6 30.3 26.6s10.2 2.4 13.9 6.7c.2.2.4.4.5.7.3.5.9.6 1.4.3s.6-.9.3-1.4M20.9 13.4c0-5.2 4.2-9.4 9.4-9.4s9.4 4.2 9.4 9.4-4.2 9.4-9.4 9.4-9.4-4.2-9.4-9.4M55.5 42.6c-.3-2.3-2.1-4.1-4.4-4.3-2.1-.3-4.1.8-5.1 2.6-.3.5-.1 1.1.4 1.4s1.1.1 1.4-.4c.6-1.1 1.8-1.7 3.1-1.6 1.4.1 2.5 1.2 2.7 2.6.1 1.2-.5 2.4-1.6 2.9-1.4.7-2.4 2.3-2.4 3.8V51c0 .6.4 1 1 1s1-.4 1-1v-1.4c0-.7.6-1.7 1.3-2 1.7-1 2.8-3 2.6-5M50.5 56.6c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5 1.5-.7 1.5-1.5-.7-1.5-1.5-1.5" />
  </svg>
);
export default IconUserQuestion;
