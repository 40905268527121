import * as React from "react";
const IconChestAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M26.1 6.5c-.1-.5-.6-.9-1.1-.8s-.9.6-.8 1.1c0 .1.9 7.8-15.1 12.8-1.1.2-7.4 2.8-7.4 29.1 0 .6.4 1 1 1s1-.4 1-1c0-25.4 5.8-27.1 5.8-27.1h.2C27.2 16 26.1 6.9 26.1 6.5M55 19.5C39.2 14.6 39.9 6.9 39.9 6.8c.1-.5-.3-1-.8-1.1-.6-.1-1.1.3-1.1.8-.1.4-1.2 9.5 16.6 15h.2c.1 0 5.8 1.9 5.8 27.1 0 .6.4 1 1 1s1-.4 1-1c-.2-26.3-6.4-28.8-7.6-29.1" />
    <path d="M11.7 29.1c-.5.3-.6.9-.4 1.4 0 .1 1.9 3.2 3.6 8.2.1.6.5 4.2-3 8.5-.4.4-.3 1.1.1 1.4.2.2.4.2.6.2.3 0 .6-.1.8-.4 1.5-1.8 2.4-3.5 2.9-5 1 4.1 1.7 8.9 1.4 13.9 0 .6.4 1 .9 1.1h.1c.5 0 1-.4 1-.9.9-14.9-6.3-27.4-6.6-27.9-.3-.6-.9-.7-1.4-.5M53.2 29.1c-.5-.3-1.1-.1-1.4.4s-7.5 13.1-6.6 27.9c0 .5.5.9 1 .9h.1c.6 0 1-.5.9-1.1-.3-5.5.5-10.7 1.7-15 .5 1.5 1.4 3.4 2.7 5.6.2.3.5.5.9.5.2 0 .4 0 .5-.2.5-.3.6-.9.3-1.4-2.7-4.4-3.2-7.4-3.3-8.2 1.7-4.9 3.5-8.1 3.5-8.1.3-.4.2-1-.3-1.3M28.1 39c-.3-.4-1-.5-1.4-.2-2.1 1.5-3.9.7-4 .6-.5-.2-1.1 0-1.3.4-.2.5 0 1.1.4 1.3.1 0 1 .5 2.3.5 1.1 0 2.4-.3 3.8-1.3.5-.2.6-.8.2-1.3M29.9 46.3c-.5-.1-1.1.2-1.2.7-.6 2.1-2.2 2.5-2.3 2.6-.5.1-.9.6-.8 1.2.1.5.5.8 1 .8h.2c.1 0 2.9-.7 3.8-4 .2-.6-.2-1.1-.7-1.3M38.3 49.6s-1.7-.4-2.3-2.6c-.1-.5-.7-.8-1.2-.7s-.8.7-.7 1.2c.9 3.3 3.7 4 3.8 4h.2c.5 0 .9-.3 1-.8 0-.5-.3-1-.8-1.1M41.5 39.5s-1.9.9-4-.7c-.4-.3-1.1-.2-1.4.2s-.2 1.1.2 1.4c1.4 1 2.7 1.3 3.8 1.3 1.3 0 2.2-.4 2.3-.5.5-.2.7-.8.4-1.3-.2-.5-.8-.7-1.3-.4M35.5 25.7c4.6-.2 6.3-3.5 6.3-3.7.2-.5 0-1.1-.5-1.3s-1.1 0-1.3.5c0 0-1.2 2.4-4.6 2.5-.6 0-1 .5-1 1 .1.5.5 1 1.1 1M24.8 21.1c-.2-.5-.8-.7-1.3-.5s-.7.8-.5 1.3c.1.1 1.7 3.5 6.3 3.7.5 0 1-.4 1-1s-.4-1-1-1c-3.3 0-4.5-2.4-4.5-2.5" />
  </svg>
);
export default IconChestAlt;
