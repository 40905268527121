import * as React from "react";
const IconBooks = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.2 54.5 49.3 8.3c-.3-1.3-1-2.4-2.1-3.2-1.1-.7-2.4-1-3.7-.8l-8.4 1.6C34.6 3.7 32.6 2 30.2 2H10.6c-2.8 0-5 2.2-5 5v50c0 2.8 2.2 5 5 5h19.6c2.8 0 5-2.2 5-5V13.1l8.5 44.1c.3 1.3 1 2.4 2.1 3.2.8.6 1.8.9 2.8.9.3 0 .6 0 1-.1l4.7-.9c2.6-.5 4.4-3.1 3.9-5.8M33.2 7v13.7H21.5V4h8.8c1.6 0 2.9 1.3 2.9 3M19.5 47.2H7.7V19.7h11.8zm2-24.5h11.8v17.7H21.5zM10.7 4h8.8v13.7H7.7V7c0-1.7 1.3-3 3-3m-3 53v-7.8h11.8V60h-8.8c-1.7 0-3-1.3-3-3m22.5 3h-8.8V42.3h11.8V57c0 1.7-1.3 3-3 3m23.7-1.6-4.7.9c-.8.2-1.6 0-2.2-.5-.7-.4-1.1-1.1-1.3-1.9L36.2 7.7l7.6-1.5c.8-.1 1.6 0 2.2.5.7.5 1.1 1.1 1.3 1.9l8.9 46.2c.4 1.7-.7 3.3-2.3 3.6" />
  </svg>
);
export default IconBooks;
