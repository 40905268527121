import * as React from "react";
const IconChefHatAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.6 15.7c-2 0-4 .5-5.7 1.6v-.2c0-7.4-5.8-13.4-13-13.4s-13 6-13 13.4v.1c-1.7-.9-3.5-1.4-5.4-1.4C7.1 15.7 2 21 2 27.6c0 6.2 4.6 11.2 10.4 11.8v15.8c0 1.5.7 3 1.8 4 1 .8 2.1 1.2 3.4 1.2.3 0 .6 0 .8-.1l3.6-.6c6.5-1.1 13.2-1.1 19.7 0l3.6.6c1.5.2 3-.2 4.2-1.2s1.8-2.4 1.8-4V39.3c6.1-.5 10.7-5.6 10.7-11.7 0-6.6-5.1-11.9-11.4-11.9m-2.2 41.9c-.7.6-1.7.9-2.6.7l-3.6-.6c-3.4-.6-6.8-.8-10.2-.8s-6.8.3-10.2.8l-3.6.6c-.9.2-1.9-.1-2.6-.7s-1.1-1.5-1.1-2.4v-6l7.7-1.3c6.5-1.1 13.2-1.1 19.7 0l7.7 1.3v6c0 .9-.5 1.8-1.2 2.4m2.2-20.2c-.6 0-1 .4-1 1v8.8L42.2 46c-.4-.1-.7-.1-1.1-.2V37c0-.6-.4-1-1-1s-1 .4-1 1v8.6c-4.7-.5-9.5-.5-14.2 0V37c0-.6-.4-1-1-1s-1 .4-1 1v8.8c-.4.1-.7.1-1.1.2l-7.4 1.2v-8.8c0-.6-.4-1-1-1C8.2 37.4 4 33 4 27.6s4.2-9.8 9.4-9.8c2.2 0 4.2.8 5.9 2.2.3.3.8.3 1.1.1.4-.2.6-.6.5-1-.1-.6-.2-1.2-.2-1.9 0-6.3 4.9-11.4 11-11.4s11 5.1 11 11.4c0 .8-.1 1.5-.2 2.2-.1.4.1.9.5 1.1s.8.2 1.2-.1c1.7-1.6 3.9-2.5 6.2-2.5 5.2 0 9.4 4.4 9.4 9.8.2 5.3-4 9.7-9.2 9.7" />
  </svg>
);
export default IconChefHatAlt2;
