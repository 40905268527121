import * as React from "react";
const IconUndo = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M45.6 20.8H20V11c0-.9-.5-1.7-1.3-2.1s-1.8-.3-2.5.3L2.9 20q-.9.75-.9 1.8c0 .7.3 1.4.9 1.8l13.4 10.8c.4.3.9.5 1.4.5.3 0 .7-.1 1-.2.8-.4 1.3-1.2 1.3-2.1v-9.8h25.6C53.5 22.8 60 29.6 60 38s-6.5 15.2-14.4 15.2h-14c-.6 0-1 .4-1 1s.4 1 1 1h14c9 0 16.4-7.7 16.4-17.2s-7.4-17.2-16.4-17.2M18 32.6c0 .2-.1.3-.2.3s-.2.1-.3 0L4.1 22.1c-.1-.1-.1-.2-.1-.3s0-.2.1-.2l13.4-10.8c.1-.1.1-.1.2-.1h.1c.1 0 .2.1.2.3z" />
  </svg>
);
export default IconUndo;
