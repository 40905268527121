import * as React from "react";
const IconBowtieAlt3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62 44.9c-.2-1.7-1.2-3.1-2.6-4L45 32.4l2-2c.8-.8 1.2-1.8 1.2-2.8s-.4-2.1-1.2-2.8L39.2 17c-1.5-1.5-4.1-1.5-5.7 0l-2 2-8.4-14.3c-.9-1.5-2.3-2.4-4-2.6s-3.3.4-4.5 1.5l-11 11c-1.2 1.2-1.8 2.8-1.5 4.5s1.2 3.1 2.6 4L19 31.6l-2 2c-.8.8-1.2 1.8-1.2 2.8 0 1.1.4 2.1 1.2 2.8l7.8 7.8c.8.8 1.8 1.2 2.8 1.2s2.1-.4 2.8-1.2l2-2 8.4 14.3c.9 1.5 2.3 2.4 4 2.6h.7c1.4 0 2.8-.6 3.8-1.6l11-11c1.3-1.1 1.9-2.8 1.7-4.4M5.7 21.4c-.9-.5-1.5-1.5-1.7-2.5s.2-2.1 1-2.9L16 5c.7-.7 1.5-1 2.4-1h.4c1.1.1 2 .7 2.5 1.7L30 20.5l-9.7 9.7zM29 45.6c-.8.8-2.1.8-2.8 0l-7.8-7.8c-.4-.4-.6-.9-.6-1.4s.2-1 .6-1.4l2.9-2.9L32 21.4l2.9-2.9c.4-.4.9-.6 1.4-.6s1 .2 1.4.6l7.8 7.8c.4.4.6.9.6 1.4s-.2 1-.6 1.4L42.6 32 31.9 42.7zM59 48 48 59c-.8.8-1.8 1.1-2.9 1s-2-.7-2.5-1.7l-8.7-14.8 9.7-9.7 14.8 8.7c.9.5 1.5 1.5 1.7 2.5 0 1.2-.3 2.2-1.1 3" />
  </svg>
);
export default IconBowtieAlt3;
