import * as React from "react";
const IconBacteria = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M16 8.022a7.4 7.4 0 0 1 4.953 2.44l2.168-2.169 1.414 1.414-2.44 2.44c.488.99.762 2.104.762 3.282 0 2.322.815 4.454 2.175 6.125l2.26-2.261 1.415 1.414-2.261 2.261a9.67 9.67 0 0 0 6.125 2.175c.656 0 1.291.085 1.896.244l1.667-2.887 1.732 1-1.537 2.662A7.43 7.43 0 0 1 40 32.572c0 1.087-.234 2.12-.654 3.05l2.618 1.512-1 1.732-2.679-1.547A7.41 7.41 0 0 1 32.571 40q-.285 0-.571-.007V43h-2v-3.133a24.4 24.4 0 0 1-8.606-2.55l-1.528 2.647-1.732-1 1.518-2.63a24.7 24.7 0 0 1-5.312-4.432l-2.633 2.634-1.414-1.415 2.762-2.762a24.5 24.5 0 0 1-3.801-7.16L6.259 24l-.518-1.932 2.96-.793A24.6 24.6 0 0 1 8 15.43c0-.681.092-1.34.263-1.967L5.5 11.866l1-1.732 2.556 1.476A7.44 7.44 0 0 1 14 8.137V5h2zM15.429 10A5.43 5.43 0 0 0 10 15.429C10 27.894 20.106 38 32.571 38a5.429 5.429 0 0 0 0-10.857c-6.47 0-11.714-5.245-11.714-11.714A5.43 5.43 0 0 0 15.43 10M15 15a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-3 1a3 3 0 1 1 6 0 3 3 0 0 1-6 0m11 14a1 1 0 1 1 2 0 1 1 0 0 1-2 0m1-3a3 3 0 1 0 0 6 3 3 0 0 0 0-6m-5-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m13 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4"
      clipRule="evenodd"
    />
  </svg>
);
export default IconBacteria;
