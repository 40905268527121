import * as React from "react";
const IconAlarmTimer = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M45.4 2c-6.4 0-12 3.6-14.9 9-2.2-1.1-4.7-1.8-7.1-1.9V3c0-.6-.4-1-1-1s-1 .4-1 1v6.1c-4.5.2-8.7 2.1-11.8 5.3-3.4 3.4-5.3 8-5.3 12.8v10.4s-.3 4.8-1.9 8.9c-.7 1.7-.5 3.7.5 5.2s2.8 2.5 4.6 2.5h6.8c.2 4.4 3.8 7.9 8.2 7.9s8-3.5 8.2-7.9h6.8c1.9 0 3.6-.9 4.6-2.5 1-1.5 1.2-3.5.5-5.2-1.7-4.1-1.9-8.8-1.9-8.8V35c1.5.5 3.1.7 4.8.7 9.3 0 16.8-7.5 16.8-16.8S54.6 2 45.4 2m-23 58c-3.3 0-6-2.6-6.2-5.9h12.3c-.1 3.3-2.8 5.9-6.1 5.9m16.1-22.3c0 .2.3 5.1 2.1 9.5.5 1.1.3 2.3-.3 3.3-.7 1-1.8 1.6-3 1.6H7.4c-1.2 0-2.3-.6-3-1.6s-.8-2.2-.3-3.3c1.8-4.4 2.1-9.3 2.1-9.5V27.2c0-4.3 1.7-8.4 4.7-11.4C14 12.7 18 11 22.3 11c2.6 0 5.1.6 7.3 1.8-.7 1.9-1.1 3.9-1.1 6 0 6.8 4.1 12.7 10 15.4zm6.9-4.1c-8.2 0-14.8-6.6-14.8-14.8S37.2 4 45.4 4s14.8 6.6 14.8 14.8-6.7 14.8-14.8 14.8" />
    <path d="M45.5 20v-5.2c0-.6-.4-1-1-1s-1 .4-1 1v5.6c0 .3.1.5.3.7l3.3 3.3c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4z" />
  </svg>
);
export default IconAlarmTimer;
