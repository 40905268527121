import * as React from "react";
const IconVirusResearch = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      d="M21 6a1 1 0 1 0 0 2h1v3.041a11.95 11.95 0 0 0-6.75 2.797l-.028-.03-2.121-2.122.707-.707a1 1 0 0 0-1.415-1.414l-2.828 2.828a1 1 0 1 0 1.414 1.415l.707-.707 2.122 2.12.03.03A11.95 11.95 0 0 0 11.04 22L11 22H8v-1a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0v-1h3.041a11.95 11.95 0 0 0 2.797 6.75l-.03.028-2.122 2.122-.707-.707a1 1 0 0 0-1.414 1.414l2.828 2.828a1 1 0 1 0 1.415-1.414l-.707-.707 2.12-2.121.03-.03a11.96 11.96 0 0 0 5.568 2.64 1 1 0 0 0 .362-1.968C16.526 31.98 13 27.901 13 23c0-5.523 4.477-10 10-10 4.9 0 8.98 3.526 9.835 8.18a1 1 0 1 0 1.967-.36 11.96 11.96 0 0 0-2.64-5.57l.006-.005.024-.023 2.122-2.121.707.707a1 1 0 0 0 1.414-1.415l-2.828-2.828a1 1 0 1 0-1.415 1.414l.708.707-2.122 2.122-.028.03A11.95 11.95 0 0 0 24 11.04V8h1a1 1 0 1 0 0-2z"
    />
    <path
      
      fillRule="evenodd"
      d="M16 20.5a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0m3.5-1.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3M31.5 23a8.5 8.5 0 1 0 5.262 15.176l4.53 4.531a1 1 0 0 0 1.415-1.414l-4.531-4.531A8.5 8.5 0 0 0 31.5 23M25 31.5a6.5 6.5 0 1 1 13 0 6.5 6.5 0 0 1-13 0"
      clipRule="evenodd"
    />
  </svg>
);
export default IconVirusResearch;
