import * as React from "react";
const IconMoneyIdea = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M48.5 9.2c-4.3-4.5-9.9-7-15.9-7.2-6.2-.2-12.1 2.2-16.6 6.5-4.4 4.3-6.9 10.2-6.9 16.4 0 7.9 4 15.2 10.8 19.4 1.7 1.1 2.8 3.1 2.8 5.3v4.2c0 4.5 3.7 8.2 8.2 8.2h2.2c4.5 0 8.2-3.7 8.2-8.2v-4.3c0-2.1 1.1-4.1 2.8-5.2 6.5-4.1 10.5-11.1 10.7-18.8.2-6-2-11.8-6.3-16.3m-9.2 44.6c0 3.4-2.8 6.2-6.2 6.2h-2.2c-3.4 0-6.2-2.8-6.2-6.2v-4.2c0-.9-.1-1.7-.4-2.5h15.4c-.2.7-.3 1.5-.3 2.3v4.4zm3.8-11.2c-1 .6-1.9 1.5-2.5 2.5H23.4c-.6-1-1.5-1.9-2.5-2.5-6.1-3.8-9.8-10.5-9.8-17.7 0-5.7 2.2-11 6.3-14.9 4-3.9 9.1-6 14.6-6h.5c5.5.1 10.6 2.5 14.5 6.6s6 9.4 5.8 14.9c-.1 7-3.8 13.4-9.7 17.1" />
    <path d="M30.3 17.2h7.2c.6 0 1-.4 1-1s-.4-1-1-1h-3.4v-2.3c0-.6-.4-1-1-1s-1 .4-1 1v2.3h-1.8c-2.7 0-4.9 2.2-4.9 4.8 0 2.7 2.2 4.8 4.9 4.8h3.3c1.6 0 2.9 1.3 2.9 2.8 0 1.6-1.3 2.8-2.9 2.8h-7.2c-.6 0-1 .4-1 1s.4 1 1 1H32v2.3c0 .6.4 1 1 1s1-.4 1-1v-2.3c2.5-.2 4.4-2.3 4.4-4.8 0-2.7-2.2-4.8-4.9-4.8h-3.3c-1.6 0-2.9-1.3-2.9-2.8s1.5-2.8 3-2.8" />
  </svg>
);
export default IconMoneyIdea;
