import * as React from "react";
const IconIslam = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M45.9 53.8c-2 .5-4.1.8-6.1.8-12.5 0-22.6-10.2-22.6-22.6s10-22.6 22.5-22.6c2 0 4.1.3 6.1.8.5.1 1-.1 1.2-.5s.1-1-.3-1.2c-4.7-3.3-10.2-5-16.1-5C14.8 3.4 2 16.2 2 32s12.8 28.6 28.6 28.6c5.9 0 11.5-1.7 16.1-5 .4-.3.5-.8.3-1.2-.2-.5-.7-.7-1.1-.6m-15.3 4.8C15.9 58.6 4 46.7 4 32S15.9 5.4 30.6 5.4c3.5 0 6.9.7 10.1 2-14-.6-25.6 10.7-25.6 24.6 0 13.6 11.1 24.6 24.6 24.6h.9c-3.1 1.3-6.5 2-10 2" />
    <path d="M62 27.1c-.1-.4-.4-.6-.8-.7l-7.4-1.1-3.3-6.7c-.2-.3-.5-.6-.9-.6q-.6 0-.9.6l-3.3 6.7-7.4 1.1c-.4.1-.7.3-.8.7s0 .8.3 1l5.4 5.2-1.3 7.4c-.1.4.1.8.4 1 .2.1.4.2.6.2s.3 0 .5-.1l6.6-3.5 6.6 3.5c.3.2.7.1 1.1-.1.3-.2.5-.6.4-1l-1.3-7.4 5.3-5.2c.2-.3.3-.7.2-1m-7.4 5.2c-.2.2-.3.6-.3.9l1 5.9-5.3-2.8q-.45-.3-.9 0l-5.3 2.8 1-5.9c.1-.3-.1-.7-.3-.9l-4.3-4.2 5.9-.9c.3 0 .6-.3.8-.5l2.6-5.4 2.7 5.4c.1.3.4.5.8.5l5.9.9z" />
  </svg>
);
export default IconIslam;
