import * as React from "react";
const IconQuoteAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M45.8 20.4c.6 0 1-.4 1-1s-.4-1-1-1c-5.9 0-10.8 4.8-10.8 10.8v14.4c0 1.1.9 2 2 2h7.8c1.1 0 2-.9 2-2v-7.8c0-1.1-.9-2-2-2H37v-4.6c0-4.8 4-8.8 8.8-8.8m-1 23.2H37v-7.8h7.8zM28 20.4c.6 0 1-.4 1-1s-.4-1-1-1c-5.9 0-10.8 4.8-10.8 10.8v14.4c0 1.1.9 2 2 2H27c1.1 0 2-.9 2-2v-7.8c0-1.1-.9-2-2-2h-7.8v-4.6c0-4.8 3.9-8.8 8.8-8.8m-1 23.2h-7.8v-7.8H27z" />
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
  </svg>
);
export default IconQuoteAlt2;
