import * as React from "react";
const IconLabelOff = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.8 36.2-1.1-2.6q-.6-1.65 0-3.3l1-2.5c.5-1.1.2-2.4-.6-3.3l-1.9-1.9c-.8-.8-1.3-1.9-1.3-3.1v-2.8c0-1.2-.7-2.3-1.9-2.8l-2.5-1c-1.1-.4-1.9-1.3-2.3-2.3l-1-2.5c-.5-1.1-1.6-1.9-2.8-1.9h-2.7c-1.2 0-2.2-.4-3.1-1.3L39.7 3c-.7-.7-1.5-1-2.3-1-.4 0-.8.1-1.2.2l-2.6 1.1c-1 .4-2.2.4-3.3 0l-2.6-1.1c-1.2-.5-2.4-.2-3.3.7l-1.9 1.9c-.8.8-1.9 1.3-3.1 1.3h-2.8c-1.2 0-2.3.8-2.8 1.9l-1 2.5c-.4 1.1-1.3 1.9-2.3 2.3l-2.5 1c-1.2.5-1.9 1.6-1.9 2.8v2.8c0 1.2-.4 2.2-1.3 3.1l-1.9 1.9c-.9.9-1.1 2.2-.7 3.3l1.1 2.6q.6 1.65 0 3.3l-1 2.5c-.5 1.1-.2 2.4.6 3.3l1.9 1.9c.8.8 1.3 1.9 1.3 3.1v2.8c0 1.2.8 2.3 1.9 2.8l2.5 1c1.1.4 1.9 1.3 2.3 2.3l1 2.5c.5 1.2 1.6 1.9 2.8 1.9h2.8c1.2 0 2.2.4 3.1 1.3l1.9 2c.6.6 1.3.9 2.2.9.4 0 .8-.1 1.2-.2l2.6-1.1c1-.4 2.2-.4 3.3 0l2.6 1.1c.4.1.8.2 1.1.2.8 0 1.6-.3 2.2-.9l1.9-1.9c.8-.8 1.9-1.3 3.1-1.3h2.8c1.2 0 2.3-.7 2.8-1.9l1-2.5c.4-1.1 1.3-1.9 2.3-2.3l2.5-1c1.2-.5 1.9-1.6 1.9-2.8v-2.8c0-1.2.4-2.2 1.3-3.1l2-2c.8-.8 1-2.1.6-3.2m-2.1 1.9-2 1.9c-1.2 1.2-1.8 2.8-1.8 4.5v2.8c0 .4-.2.8-.6 1l-2.6 1.1c-1.6.6-2.8 1.9-3.4 3.4l-1 2.5c-.2.4-.5.6-1 .6h-2.8c-1.7 0-3.3.7-4.5 1.8l-1.9 2c-.3.3-.8.4-1.1.2l-2.5-1c-.8-.3-1.6-.5-2.4-.5s-1.7.2-2.4.5l-2.7 1c-.3.1-.8.1-1.1-.2l-1.9-2c-1.2-1.2-2.8-1.8-4.5-1.8h-2.8c-.4 0-.8-.2-1-.6l-1.1-2.6c-.6-1.6-1.9-2.8-3.4-3.4l-2.6-1.1c-.4-.2-.6-.5-.6-1v-2.8c0-1.7-.7-3.3-1.8-4.5L4.3 38c-.3-.2-.4-.6-.2-1l1-2.5c.6-1.6.6-3.3 0-4.8l-1-2.5c-.2-.4-.1-.8.2-1.1l2-2.1c1.2-1.2 1.8-2.8 1.8-4.5v-2.8c0-.4.2-.8.6-1l2.6-1.1c1.6-.6 2.8-1.9 3.4-3.4l1.1-2.6c.2-.4.5-.6 1-.6h2.8c1.7 0 3.3-.7 4.5-1.8L26 4.3c.3-.3.8-.4 1.1-.2l2.5 1c1.6.6 3.3.6 4.8 0l2.6-1c.3-.1.8-.1 1.1.2L40 6.2C41.2 7.4 42.8 8 44.5 8h2.7c.4 0 .8.2 1 .6l1.1 2.6c.6 1.6 1.9 2.8 3.4 3.4l2.6 1.1c.4.2.6.5.6 1v2.8c0 1.7.7 3.3 1.8 4.5l2 1.9c.3.3.4.7.2 1.1l-1 2.5c-.7 1.6-.7 3.3 0 4.8l1.1 2.6q.15.75-.3 1.2" />
    <path d="M36.6 25.3 25.2 36.6c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3L38 26.7c.4-.4.4-1 0-1.4s-1-.4-1.4 0M24.9 23.1l-.3-.5c-.3-.5-.9-.6-1.4-.3s-.6.9-.3 1.4l.3.5c.2.3.5.4.8.4.2 0 .4-.1.6-.2.5-.3.6-.9.3-1.3M40.6 39.4c-.4-.3-1.1-.2-1.4.2s-.2 1.1.3 1.4l.5.3c.2.1.4.2.6.2.3 0 .6-.1.8-.4.3-.4.2-1.1-.2-1.4z" />
  </svg>
);
export default IconLabelOff;
