import * as React from "react";
const IconArrowsExpandDownRight = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M19.2 23.4H7c-2.2 0-4-1.8-4-4V7.2c0-2.2 1.8-4 4-4h12.2c2.2 0 4 1.8 4 4v12.2c0 2.2-1.8 4-4 4M7 5.2c-1.1 0-2 .9-2 2v12.2c0 1.1.9 2 2 2h12.2c1.1 0 2-.9 2-2V7.2c0-1.1-.9-2-2-2zM60 60.8H43.8c-.6 0-1-.4-1-1s.4-1 1-1h13.7L32.8 34.3c-.4-.4-.4-1 0-1.4s1-.4 1.4 0L59 57.4V43.6c0-.6.4-1 1-1s1 .4 1 1v16.2c0 .1 0 .2-.1.3 0 .1-.1.2-.2.3s-.2.2-.3.2c-.1.1-.3.2-.4.2" />
  </svg>
);
export default IconArrowsExpandDownRight;
