import * as React from "react";
const IconSignaling = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 3C16 3 3 16 3 32s13 29 29 29 29-13 29-29S48 3 32 3m0 56c-2.5 0-4.9-.3-7.2-1 0-.1.1-.2.1-.3v-9.6l7.7-5.5c.3-.2.4-.5.4-.8V17.6l9.3 9.7c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-11-11.4c-.4-.4-1.1-.4-1.4 0L20.2 25.9c-.4.4-.4 1 0 1.4s1 .4 1.4 0l9.3-9.7v23.6l-6.1 4.4v-7.2c0-.6-.4-1-1-1s-1 .4-1 1v18.9C12.5 53.7 5 43.7 5 32 5 17.1 17.1 5 32 5s27 12.1 27 27-12.1 27-27 27" />
  </svg>
);
export default IconSignaling;
