import * as React from "react";
const IconBookFont = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.3 44.5V6c0-1.7-1.3-3-3-3H15.6c-2.7 0-4.9 2.2-4.9 4.9v46.2c.4 3.9 3.7 6.9 7.7 6.9H52c.6 0 1-.4 1-1s-.4-1-1-1h-.6V47.3c1.1-.5 1.9-1.5 1.9-2.8M12.7 7.9C12.7 6.3 14 5 15.6 5h34.7c.6 0 1 .4 1 1v38.5c0 .6-.4 1-1 1H18.5c-2.1 0-4 .8-5.5 2.3l-.3.3zM18.5 59c-3.2 0-5.8-2.6-5.8-5.7 0-1.5.6-3 1.7-4.1s2.5-1.7 4.1-1.7h30.9V59z" />
    <path d="M24.6 33.4c.5.3 1.1.1 1.4-.4l2.6-4.9h9.6l2.6 4.9c.2.3.5.5.9.5.2 0 .3 0 .5-.1.5-.3.7-.9.4-1.4l-8.3-15.5c-.3-.7-1.4-.7-1.8 0L24.2 32c-.3.5-.1 1.1.4 1.4m8.8-14.3 3.8 7h-7.5z" />
  </svg>
);
export default IconBookFont;
