import * as React from "react";
const IconTrainers = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m51.9 28.8-13.6-1.4-15.8-6.2-4.3-3.9c-.4-.4-1-.3-1.4.1s-.3 1 .1 1.4l3.5 3.2c-2.4 1.8-9.5 6.3-17.2 4.2-.3-.1-.6 0-.9.2-.2.3-.3.5-.3.9v14.2c0 3 2.4 5.4 5.4 5.4H61c.6 0 1-.4 1-1V40c0-5.8-4.3-10.6-10.1-11.2M60 44.9H7.4c-1.9 0-3.4-1.5-3.4-3.4v-13c8.7 1.7 16.2-3.7 18.2-5.2l7.4 2.9-3.6 4.3c-.4.4-.3 1.1.1 1.4.2.2.4.2.6.2.3 0 .6-.1.8-.4l4-4.7v-.1l5.4 2.1-3.7 4.3c-.4.4-.3 1.1.1 1.4.2.2.4.2.6.2.3 0 .6-.1.8-.4l4-4.7c.1-.1.2-.3.2-.4l12.7 1.3c4.7.5 8.3 4.4 8.3 9.2v5z" />
  </svg>
);
export default IconTrainers;
