import * as React from "react";
const IconParenthesesAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M19 32c0-6.4 2-12.1 4.9-14.4.4-.3.5-1 .2-1.4s-1-.5-1.4-.2c-3.5 2.6-5.7 8.9-5.7 16 0 7 2.2 13.3 5.7 16 .2.1.4.2.6.2.3 0 .6-.1.8-.4.3-.4.3-1.1-.2-1.4C21 44.1 19 38.4 19 32M41.3 16c-.4-.3-1.1-.3-1.4.2-.3.4-.3 1.1.2 1.4C43 19.8 45 25.6 45 32s-2 12.1-4.9 14.4c-.4.3-.5 1-.2 1.4.2.3.5.4.8.4.2 0 .4-.1.6-.2 3.5-2.7 5.7-8.9 5.7-16s-2.2-13.3-5.7-16" />
    <path d="M58 2H6C3.8 2 2 3.8 2 6v52c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V6c0-2.2-1.8-4-4-4m2 56c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2h52c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconParenthesesAlt2;
