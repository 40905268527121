import * as React from "react";
const IconDrill = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.2 10.9H26.9c-.1 0-.3 0-.4.1l-9 2.2c-.8.2-1.4.9-1.4 1.8v5.5H3c-.6 0-1 .4-1 1s.4 1 1 1h13.2V28c0 .8.6 1.6 1.4 1.8l9 2.2c.1 0 .3.1.4.1h9.3v.3l2.6 7.9c.1.4.5.7.9.7h4.3v9.6c0 .5.3.9.8 1l10.5 2.6h.2c.2 0 .4-.1.6-.2.2-.2.4-.5.4-.8V32h2.5c1 0 1.8-.8 1.8-1.8V12.7c.1-1-.7-1.8-1.7-1.8m-41 4.2 7.5-1.9v16.5l-7.5-1.9zm36.5 36.7-8.5-2.1v-9.8c0-.6-.4-1-1-1h-4.6L38.3 32h16.4zM59 30H27.7V12.9H59z" />
  </svg>
);
export default IconDrill;
