import * as React from "react";
const IconPushDown = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M45.1 59H18.9c-.6 0-1 .4-1 1s.4 1 1 1h26.2c.6 0 1-.4 1-1s-.5-1-1-1M31.3 50.1c.2.2.4.3.7.3s.5-.1.7-.3l10.7-10.5c.4-.4.4-1 0-1.4s-1-.4-1.4 0L33 47V4c0-.6-.4-1-1-1s-1 .4-1 1v43l-9-8.8c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4z" />
  </svg>
);
export default IconPushDown;
