import * as React from "react";
const IconCubes = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 2H7C4.2 2 2 4.2 2 7v50c0 2.8 2.2 5 5 5h50c2.8 0 5-2.2 5-5V7c0-2.8-2.2-5-5-5m3 5v24H33.5V4H57c1.7 0 3 1.3 3 3M7 4h24.5v27H4V7c0-1.7 1.3-3 3-3M4 57V33h27.5v27H7c-1.7 0-3-1.3-3-3m53 3H33.5V33H60v24c0 1.7-1.3 3-3 3" />
    <path d="M42.3 24.4h9.8c1.1 0 2-.9 2-2v-9.8c0-1.1-.9-2-2-2h-9.8c-1.1 0-2 .9-2 2v9.8c0 1.1.9 2 2 2m0-11.8h9.8v9.8h-9.8zM13.4 21.3c.2.2.5.3.7.3s.5-.1.7-.3l2.4-2.4 2.4 2.4c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-2.4-2.4 2.4-2.4c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-2.4 2.4-2.4-2.4c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l2.4 2.4-2.4 2.4c-.4.4-.4 1 0 1.4M17.3 39.4c-3.8 0-6.9 3.1-6.9 6.9s3.1 6.9 6.9 6.9 6.9-3.1 6.9-6.9-3.1-6.9-6.9-6.9m0 11.8c-2.7 0-4.9-2.2-4.9-4.9s2.2-4.9 4.9-4.9 4.9 2.2 4.9 4.9-2.2 4.9-4.9 4.9M50.1 40.3c-.6-.7-1.6-1.2-2.6-1.2s-1.9.4-2.6 1.2l-6.3 7.4c-.9 1-1.1 2.4-.5 3.6s1.7 2 3 2h12.7c1.3 0 2.5-.7 3-2 .6-1.2.4-2.6-.5-3.6zm5 10.2c-.2.5-.7.8-1.2.8H41.2c-.5 0-1-.3-1.2-.8s-.2-1 .2-1.5l6.3-7.4c.3-.3.6-.5 1-.5s.8.2 1 .5l6.3 7.4q.6.75.3 1.5" />
  </svg>
);
export default IconCubes;
