import * as React from "react";
const IconGoogleCloud = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      
      d="M61.997 33.585a17.46 17.46 0 0 0-6.712-8.717 24.24 24.24 0 0 0-7.318-11.799l-.113.113v-.132a24.1 24.1 0 0 0-10.815-5.502 24.5 24.5 0 0 0-12.158.454A24.36 24.36 0 0 0 14.5 14.317a24.2 24.2 0 0 0-6.032 10.55c.133-.056.284-.094.416-.132-.227.133-.435.284-.643.435a17.6 17.6 0 0 0-6.334 8.604 17.55 17.55 0 0 0-.151 10.664 17.5 17.5 0 0 0 6.07 8.792h.018l-.019.057a17.33 17.33 0 0 0 10.57 3.517h13.406l.076.075h13.462a17.53 17.53 0 0 0 10.475-3.365 17.54 17.54 0 0 0 6.391-8.944 17.46 17.46 0 0 0-.208-10.986M51.56 41.942a6.8 6.8 0 0 1-1.456 2.193 6.6 6.6 0 0 1-2.193 1.456c-.813.34-1.683.51-2.572.51H31.858v-.075H18.395a6.6 6.6 0 0 1-2.76-.605 6.95 6.95 0 0 1-2.515-1.985 6.74 6.74 0 0 1-1.305-2.931 6.6 6.6 0 0 1 .189-3.215 6.69 6.69 0 0 1 4.425-4.424 6.85 6.85 0 0 1 3.214-.208 7 7 0 0 1 2.93 1.323c.852.681 1.532 1.551 1.986 2.534l7.81-7.809a17.6 17.6 0 0 0-6.165-5.067 17 17 0 0 0-4.368-1.456c.057-.057.095-.076.152-.057a13.47 13.47 0 0 1 8.924-4.349 13.42 13.42 0 0 1 9.473 2.95h.151a13.4 13.4 0 0 1 3.593 4.67 13.5 13.5 0 0 1 1.23 5.9v1.342c.888 0 1.758.17 2.57.51a6.6 6.6 0 0 1 2.194 1.456 6.8 6.8 0 0 1 1.456 2.194c.34.813.51 1.683.51 2.571 0 .89-.17 1.759-.51 2.572z"
    />
  </svg>
);
export default IconGoogleCloud;
