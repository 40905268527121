import * as React from "react";
const IconDownAlign = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 22.4c.6 0 1-.4 1-1V3.1c0-.6-.4-1-1-1s-1 .4-1 1v18.2c0 .6.4 1.1 1 1.1M50.7 4.4h5.7c1.8 0 3.3 1.5 3.3 3.3v5.7c0 .6.4 1 1 1s1-.4 1-1V7.7c0-2.9-2.4-5.3-5.3-5.3h-5.7c-.6 0-1 .4-1 1s.4 1 1 1M3.3 14.5c.6 0 1-.4 1-1V7.7c0-1.8 1.5-3.3 3.3-3.3h5.7c.6 0 1-.4 1-1s-.4-1-1-1H7.6c-2.9 0-5.3 2.4-5.3 5.3v5.7c0 .6.4 1.1 1 1.1M60.7 49.8c-.6 0-1 .4-1 1v5.7c0 1.8-1.5 3.3-3.3 3.3H33V42.9c0-.6-.4-1-1-1s-1 .4-1 1v16.900000000000002H7.6c-1.8 0-3.3-1.5-3.3-3.3v-5.7c0-.6-.4-1-1-1s-1 .4-1 1v5.7c0 2.9 2.4 5.3 5.3 5.3h48.8c2.9 0 5.3-2.4 5.3-5.3v-5.7c0-.5-.4-1-1-1M20.7 4.4h1.4c.6 0 1-.4 1-1s-.4-1-1-1h-1.4c-.6 0-1 .4-1 1s.4 1 1 1M42.2 4.4h1.4c.6 0 1-.4 1-1s-.4-1-1-1h-1.4c-.6 0-1 .4-1 1s.5 1 1 1M60.9 19.7c-.6 0-1 .4-1 1v1.4c0 .6.4 1 1 1s1-.4 1-1v-1.4c0-.6-.5-1-1-1M60.9 41.2c-.6 0-1 .4-1 1v1.4c0 .6.4 1 1 1s1-.4 1-1v-1.4c0-.6-.5-1-1-1M3.5 19.7c-.6 0-1 .4-1 1v1.4c0 .6.4 1 1 1s1-.4 1-1v-1.4c0-.6-.5-1-1-1M2.5 42.2v1.4c0 .6.4 1 1 1s1-.4 1-1v-1.4c0-.6-.4-1-1-1s-1 .4-1 1M61 31.1H42.8c-.6 0-1 .4-1 1s.4 1 1 1H61c.6 0 1-.4 1-1 0-.5-.4-1-1-1M3 33.1h18.2c.6 0 1-.4 1-1s-.4-1-1-1H3c-.6 0-1 .4-1 1s.4 1 1 1" />
  </svg>
);
export default IconDownAlign;
