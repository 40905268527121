import * as React from "react";
const IconBandageAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 2H36c-2.8 0-5 2.3-5 5v27.1H15.9C8.2 34.2 2 40.4 2 48.1S8.2 62 15.9 62H57c2.8 0 5-2.3 5-5V7c0-2.7-2.3-5-5-5M33 22.8l27-10.5v23.4l-27-3.6zM36 4h21c1.7 0 3 1.4 3 3v3.1L33 20.7V7c0-1.6 1.4-3 3-3m-7.7 32.2L24.6 60h-.1L13 36.6c.9-.2 1.9-.4 2.9-.4zM4 48.1c0-4.9 2.9-9.1 7.1-10.9L22.3 60h-6.4C9.3 60 4 54.7 4 48.1M26.6 60l3.7-23.8h1.1L44.7 60H26.6M57 60H47L33 34.9v-.8l27 3.6V57c0 1.6-1.4 3-3 3" />
  </svg>
);
export default IconBandageAlt;
