import * as React from "react";
const IconLnioLniCloudy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.6 32.7h-9.1c2.5-1.8 4.1-4.8 4.1-8.1 0-5.3-4.3-9.7-9.6-10C44.9 7.6 39.1 2 32 2c-1.5 0-3 .2-4.2.7-2.9 1-5.3 2.9-6.9 5.6-3 .4-5.9 1.9-7.9 4.1-2.3 2.5-3.6 5.7-3.6 9 0 4.8 2.6 8.9 6.4 11.3H4.4c-.6 0-1 .4-1 1s.4 1 1 1h55.2c.6 0 1-.4 1-1s-.5-1-1-1m-36.6 0c-6.4 0-11.6-5.1-11.6-11.3 0-2.8 1.1-5.6 3.1-7.7 1.8-1.9 4.4-3.2 7.1-3.5.3 0 .6-.2.8-.5 1.3-2.4 3.5-4.2 6.1-5.1 1-.4 2.2-.6 3.5-.6 6 0 11 4.8 11 10.8v.8c0 .6.4 1 1 1h.4c4.6 0 8.2 3.5 8.2 8.1s-3.6 8.1-8.2 8.1H23zM52.4 45.6H11.6c-.6 0-1 .4-1 1s.4 1 1 1h40.8c.6 0 1-.4 1-1s-.5-1-1-1M44.2 60H19.8c-.6 0-1 .4-1 1s.4 1 1 1h24.5c.6 0 1-.4 1-1s-.5-1-1.1-1" />
  </svg>
);
export default IconLnioLniCloudy;
