import * as React from "react";
const IconLiver = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M10.3 54.8C2.9 54.8 2 45.2 2 38 2 23.9 9.6 9.3 22.3 9.3c9.7 0 16.1 2 18.2 2.8 1.4-.3 3.2-.4 5.5-.3h.5c7.1 0 15.5 1 15.5 5.8 0 4.2-9.7 20.3-22.7 20.3-8.6 13.7-24.2 16.9-29 16.9m12-43.5C10.9 11.3 4 24.9 4 38c0 13.2 3.6 14.7 6.3 14.7 4.6 0 19.6-3 27.6-16.4.2-.3.5-.5.9-.5h.5C50.7 35.8 60 21.1 60 17.5c0-2.4-5.2-3.8-13.5-3.8H46c-2.3 0-4 .1-5.3.4h-.6c-1.7-.8-8-2.8-17.8-2.8" />
  </svg>
);
export default IconLiver;
