import React, { useState } from 'react';
import { useNavigation } from 'context/NavigationContext';
import {
	BringzzPageContainer,
	BringzzButton,
	BringzzIcon,
	BringzzText,
	BringzzInput,
	BringzzOrderCard
} from '@bringzz/components';
import useUser from 'hooks/useUser';
import useRequest from 'hooks/useRequest';

const ShopperDeliveries = () => {
	const { backPress, navigate } = useNavigation();
	const { sendRequest, loading } = useRequest();
	const { getUser } = useUser();
	const [stateInsuranceId, setStateInsuranceId] = useState('');

	const user = getUser();

	return (
		<BringzzPageContainer
			header={{
				left: (
					<BringzzIcon
						size='22'
						icon='IconChevronLeft'
						folder='LineIcons'
						className='cursor-pointer'
						onClick={backPress}
					/>
				),
				title: <BringzzText tag='h2'>Deliveries</BringzzText>
			}}
		>
			<div className='p-6 space-y-4'>
				{user.deliveries.map(delivery => (
					<>
						<BringzzOrderCard
							onClick={() =>
								navigate(
									`/settings/account/shopper/deliveries/detail?id=${delivery.uuid}`
								)
							}
							orderDetails={{
								name: delivery.customerFirstName,
								date: new Date(),
								status: 'completed',
								price: delivery.price
							}}
						></BringzzOrderCard>
					</>
				))}
			</div>
		</BringzzPageContainer>
	);
};

export default ShopperDeliveries;
