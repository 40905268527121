import * as React from "react";
const IconBed6 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 28.9H20.4c.5-.7.8-1.6.8-2.6v-2.5c0-2.5-2.1-4.6-4.6-4.6h-9c-1.5 0-2.8.7-3.6 1.8V9.4c0-.6-.4-1-1-1s-1 .4-1 1v45.1c0 .6.4 1 1 1s1-.4 1-1v-8.4h56v8.4c0 .6.4 1 1 1s1-.4 1-1V29.9c0-.6-.4-1-1-1M5 23.7c0-1.4 1.2-2.6 2.6-2.6h8.9c1.4 0 2.6 1.2 2.6 2.6v2.5c0 1.4-1.2 2.6-2.6 2.6H7.6c-1.4 0-2.6-1.2-2.6-2.6zM4 44.2V30.9h56v13.3z" />
  </svg>
);
export default IconBed6;
