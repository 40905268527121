import * as React from "react";
const IconCopyAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.7 14.1 45.2 3.7c-.5-.4-1.1-.7-1.7-.7H25.2c-2.2 0-4 1.8-4 4v36.5c0 2.2 1.8 4 4 4h27.2c2.2 0 4-1.8 4-4V15.9c0-.7-.2-1.4-.7-1.8m-2.5.3h-8.1v-8zm-.8 31.2H25.2c-1.1 0-2-.9-2-2V7c0-1.1.9-2 2-2h17.9v10.4c0 .6.4 1 1 1h10.4v27.1c-.1 1.2-1 2.1-2.1 2.1" />
    <path d="M44.1 23.8H30.9c-.6 0-1 .4-1 1s.4 1 1 1h13.2c.6 0 1-.4 1-1s-.5-1-1-1M44.1 32.2H30.9c-.6 0-1 .4-1 1s.4 1 1 1h13.2c.6 0 1-.4 1-1s-.5-1-1-1M41.8 53.3c-.6 0-1 .4-1 1V57c0 1.1-.9 2-2 2H11.6c-1.1 0-2-.9-2-2V20.4c0-1.1.9-2 2-2H15c.6 0 1-.4 1-1s-.4-1-1-1h-3.4c-2.2 0-4 1.8-4 4V57c0 2.2 1.8 4 4 4h27.2c2.2 0 4-1.8 4-4v-2.7c0-.6-.4-1-1-1" />
  </svg>
);
export default IconCopyAlt;
