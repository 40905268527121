import * as React from "react";
const IconTemparatureMeasuring = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m48.4 14.1-7 7c-1.2-.9-2.5-1.3-4-1.3a6.7 6.7 0 1 0 6.7 6.7c0-1.5-.5-2.8-1.3-4l7-7c.4-.4.4-1 0-1.4s-1.1-.4-1.4 0m-11 17.1c-2.6 0-4.7-2.1-4.7-4.7s2.1-4.7 4.7-4.7c1.3 0 2.5.5 3.3 1.4.9.9 1.4 2.1 1.4 3.3 0 2.6-2.1 4.7-4.7 4.7" />
    <path d="M57.6 6.3C51.9.6 42.7.6 37 6.3l-13 13c-2.7 2.7-4.2 6.2-4.4 10-.1 2.6-1 5.1-2.6 7.2L2.9 55.4c-1.2 1.6-1.1 4 .4 5.4.8.8 1.9 1.2 3 1.2.9 0 1.7-.3 2.5-.8l19-14.2c2-1.5 4.4-2.4 6.9-2.5h.2c3.6-.2 7-1.8 9.6-4.3L57.7 27c2.8-2.8 4.3-6.4 4.3-10.3S60.4 9 57.6 6.3m-1.4 19.1L43 38.7c-2.2 2.2-5.2 3.6-8.3 3.8h-.2c-2.9.2-5.6 1.2-8 2.9l-19 14.2c-.8.6-2 .5-2.8-.2-.7-.7-.8-1.9-.2-2.8l14.1-18.8c1.8-2.4 2.8-5.3 3-8.4.1-3.3 1.5-6.3 3.8-8.6l13-13.1C40.9 5.2 44.1 4 47.3 4s6.4 1.2 8.9 3.7c2.4 2.4 3.7 5.5 3.7 8.9s-1.3 6.5-3.7 8.8" />
  </svg>
);
export default IconTemparatureMeasuring;
