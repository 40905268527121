import * as React from "react";
const IconBedPatient = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.6 24.1H8.4C4.9 24.1 2 27 2 30.5V34c0 .6.4 1 1 1h16.4l10.8 6.8-9.2 5.8c-.9-.9-2.2-1.5-3.7-1.5-2.9 0-5.2 2.3-5.2 5.2s2.3 5.2 5.2 5.2 5.2-2.3 5.2-5.2c0-.7-.2-1.4-.4-2l10-6.3 10 6.3c-.3.6-.4 1.3-.4 2 0 2.9 2.3 5.2 5.2 5.2s5.2-2.3 5.2-5.2-2.3-5.2-5.2-5.2c-1.4 0-2.7.6-3.7 1.5l-9.3-5.8L44.7 35H61c.6 0 1-.4 1-1v-3.5c0-3.5-2.9-6.4-6.4-6.4M17.3 54.5c-1.8 0-3.2-1.4-3.2-3.2s1.4-3.2 3.2-3.2 3.2 1.4 3.2 3.2-1.5 3.2-3.2 3.2m29.6-6.4c1.8 0 3.2 1.4 3.2 3.2s-1.4 3.2-3.2 3.2-3.2-1.4-3.2-3.2c-.1-1.8 1.4-3.2 3.2-3.2m-14.8-7.5L23.2 35h17.7zM60 33H4v-2.5c0-2.4 2-4.4 4.4-4.4h47.1c2.4 0 4.4 2 4.4 4.4V33zM23.5 18.2h32c1.2 0 2.2-1 2.2-2.2v-2.4c0-3.4-2.8-6.2-6.2-6.2H23.6c-1.1 0-2 .9-2 2v6.8c0 1.2.9 2 1.9 2m28-8.7c2.3 0 4.2 1.9 4.2 4.2v2.4c0 .1-.1.2-.2.2H23.6V9.6h27.9zM11.9 18.1c2.8 0 5.1-2.3 5.1-5.1s-2.3-5.1-5.1-5.1-5.1 2.3-5.1 5.1 2.3 5.1 5.1 5.1m0-8.2c1.7 0 3.1 1.4 3.1 3.1s-1.4 3.1-3.1 3.1-3.1-1.4-3.1-3.1 1.4-3.1 3.1-3.1" />
  </svg>
);
export default IconBedPatient;
