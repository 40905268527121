import React from 'react';
import { useNavigation } from '../../context/NavigationContext';
import { BringzzPageContainer, BringzzIcon, BringzzText, BringzzConversation } from "@bringzz/components"

const AccountConsumerChat9 = () => {
    const { backPress } = useNavigation();

    const conversation = {
        name: 'Apotheke zum Goldenen',
        receivedImage: 'path/to/received-image.jpg',
        sentImage: 'path/to/sent-image.jpg',
    };

    const messages = [
        { datetime: '28.02, 14:45', sender: 'received', text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea' },
        { datetime: '28.02, 14:45', sender: 'sent', text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore' },
        { datetime: '28.02, 14:45', sender: 'received', text: 'Lorem ipsum dolor sit amet, consetetur sadipscing t accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor' },
        { datetime: '28.02, 14:45', sender: 'sent', text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut' },
    ];

    return (
        <BringzzPageContainer
            header={{
                left: <BringzzIcon
                    size="22"
                    icon="IconChevronLeft"
                    folder="LineIcons"
                    className="cursor-pointer"
                    onClick={backPress}
                />,
                title: <BringzzText tag='h2'>{conversation.name}</BringzzText>,
            }}>

            <BringzzConversation conversation={conversation} messages={messages} />

        </BringzzPageContainer>

    )
}

export default AccountConsumerChat9;