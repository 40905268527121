import * as React from "react";
const IconFlagsAlt1Copy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62 24c-.1-.5-.7-.8-1.2-.7-6.2 1.7-16.8 2.8-28.4 2.8-12 0-23.2-1.1-29.1-3-.5-.2-1.1.1-1.3.7-.2.5.1 1 .6 1.2l4.8 13.2c.2.6.7 1 1.4 1.1H9c.5 0 1-.3 1.4-.7l8-11c1.8.1 3.8.3 5.7.4l6.4 12c.3.6.9.9 1.5.9s1.2-.3 1.5-.9L40 27.9c2-.1 3.9-.2 5.8-.3l7.9 10.9c.3.4.8.7 1.4.7h.2c.6-.1 1.1-.5 1.3-1.1l4.7-12.9c.5-.1.8-.7.7-1.2M9.1 36.9 5 25.6c3 .7 6.8 1.3 11 1.7zm22.8 1.7L26.4 28c2 .1 3.9.1 6 .1 1.8 0 3.5 0 5.2-.1zm23-1.7L48 27.4c4.2-.4 7.9-.9 10.9-1.6z" />
  </svg>
);
export default IconFlagsAlt1Copy;
