import * as React from "react";
const IconSad5 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
    <path d="M33 40.3H22.7c-2.8 0-5.1 2.3-5.1 5.1v.8c0 .6.4 1 1 1s1-.4 1-1v-.8c0-1.7 1.4-3.1 3.1-3.1H33c.6 0 1-.4 1-1s-.4-1-1-1M28.9 21c0-.6-.4-1-1-1s-1 .4-1 1c0 2.9-2.3 5.2-5.2 5.2s-5.2-2.3-5.2-5.2c0-.6-.4-1-1-1s-1 .4-1 1c0 4 3.2 7.2 7.2 7.2s7.2-3.2 7.2-7.2M48.5 20c-.6 0-1 .4-1 1 0 2.9-2.3 5.2-5.2 5.2s-5.2-2.3-5.2-5.2c0-.6-.4-1-1-1s-1 .4-1 1c0 4 3.2 7.2 7.2 7.2s7.2-3.2 7.2-7.2c0-.5-.5-1-1-1M44.6 34.2c-.3 0-.5.1-.7.3-.7.7-4.2 4.5-4.2 7 0 2.7 2.2 4.9 4.9 4.9s4.9-2.2 4.9-4.9c0-2.4-3.5-6.2-4.2-7-.2-.2-.4-.3-.7-.3m0 10.1c-1.6 0-2.9-1.3-2.9-2.9 0-1 1.5-3.2 2.9-4.8 1.4 1.6 2.9 3.8 2.9 4.8 0 1.6-1.3 2.9-2.9 2.9" />
  </svg>
);
export default IconSad5;
