import * as React from "react";
const IconMetaverse1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M17.7 24.2c-.4-.1-.8 0-1.1.3l-5.9 7-5.9-7c-.3-.3-.8-.5-1.1-.3-.4.1-.7.5-.7.9v13.7c0 .6.4 1 1 1s1-.4 1-1v-11l4.9 5.8c.4.4 1.1.4 1.5 0l4.9-5.8v11c0 .6.4 1 1 1s1-.4 1-1V25.1c0-.4-.2-.8-.6-.9M30.6 26.1c.6 0 1-.4 1-1s-.4-1-1-1h-6.9c-.6 0-1 .4-1 1v13.7c0 .6.4 1 1 1h6.9c.6 0 1-.4 1-1s-.4-1-1-1h-5.9v-5.1h2.7c.6 0 1-.4 1-1s-.4-1-1-1h-2.7v-4.6zM45.9 24.1H36c-.6 0-1 .4-1 1s.4 1 1 1h3.9v12.7c0 .6.4 1 1 1s1-.4 1-1V26.1h3.9c.6 0 1-.4 1-1s-.4-1-.9-1M60.9 38.5l-6-13.7q-.3-.6-.9-.6c-.6 0-.8.2-.9.6l-6 13.7c-.2.5 0 1.1.5 1.3s1.1 0 1.3-.5L54 27.6l5.1 11.6q.3.6.9.6c.1 0 .3 0 .4-.1.5-.1.7-.7.5-1.2" />
  </svg>
);
export default IconMetaverse1;
