import * as React from "react";
const IconLayersCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m53.7 40.2-2.8-1.5 3-1.8c1.7-1 2.6-2.8 2.6-4.7s-1.1-3.7-2.8-4.6l-4.1-2.3 4.3-2.7c1.7-1 2.6-2.8 2.6-4.7s-1.1-3.7-2.8-4.6L34.8 2.7c-1.7-.9-3.7-.9-5.4 0L10.3 13.8c-1.7 1-2.7 2.7-2.7 4.7s1 3.7 2.7 4.7l4.3 2.5-4.3 2.5c-1.7 1-2.7 2.7-2.7 4.7s1 3.7 2.7 4.7l2.9 1.7-2.9 1.7c-1.7 1-2.7 2.7-2.7 4.7s1 3.7 2.7 4.7l19.1 11c.8.5 1.8.7 2.7.7 1 0 2-.3 2.8-.8l18.9-11.6c1.7-1 2.6-2.8 2.6-4.7 0-2.1-1.1-3.8-2.7-4.8M9.6 18.5c0-1.2.6-2.3 1.7-3l19.2-11c.5-.3 1.1-.5 1.7-.5s1.1.1 1.7.4l18.8 10.5c1.1.6 1.7 1.7 1.7 2.9s-.6 2.3-1.6 3L33.9 32.5c-1.1.7-2.4.7-3.5 0l-19.1-11c-1.1-.6-1.7-1.7-1.7-3m0 14.3c0-1.2.6-2.3 1.7-3l5.3-3 12.8 7.4c.8.5 1.8.7 2.7.7 1 0 2-.3 2.8-.8l12.7-7.8 5.1 2.9c1.1.6 1.7 1.7 1.7 2.9s-.6 2.3-1.6 3L33.9 46.8c-1.1.7-2.4.7-3.5 0l-19.1-11c-1.1-.6-1.7-1.7-1.7-3m43.2 15L33.9 59.5c-1.1.7-2.4.7-3.5 0l-19.1-11c-1.1-.6-1.7-1.7-1.7-3 0-1.2.6-2.3 1.7-3l3.9-2.2 14.2 8.2c.8.5 1.8.7 2.7.7 1 0 2-.3 2.8-.8l14-8.6 3.7 2.1c1.1.6 1.7 1.7 1.7 2.9s-.5 2.4-1.5 3" />
  </svg>
);
export default IconLayersCopy;
