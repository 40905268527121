import * as React from "react";
const IconArrowsExchangeAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M44.3 21.9H4q-.6 0-.9-.6c-.2-.4-.1-.8.1-1.1l7.7-9.1c.4-.4 1-.5 1.4-.1s.5 1 .1 1.4l-6.3 7.5h38.1c.6 0 1 .4 1 1s-.4 1-.9 1M52.3 53.3c-.2 0-.5-.1-.6-.2-.4-.4-.5-1-.1-1.4l6.3-7.5H19.7c-.6 0-1-.4-1-1s.4-1 1-1H60q.6 0 .9.6c.2.4.1.8-.1 1.1L53.1 53c-.2.2-.5.3-.8.3" />
  </svg>
);
export default IconArrowsExchangeAlt;
