import * as React from "react";
const IconRecycleAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.6 9.3H40.2V5.8c0-2.1-1.7-3.8-3.8-3.8h-8.8c-2.1 0-3.8 1.7-3.8 3.8v3.5H13.4c-1.7 0-3 1.3-3 3v5.3c0 1.7 1.3 3 3 3h.2v34.8c0 3.7 3 6.6 6.6 6.6h23.6c3.7 0 6.6-3 6.6-6.6V20.6h.2c1.7 0 3-1.3 3-3v-5.3c0-1.6-1.3-3-3-3M25.8 5.8c0-1 .8-1.8 1.8-1.8h8.8c1 0 1.8.8 1.8 1.8v3.5H25.8zm22.6 49.6c0 2.6-2.1 4.6-4.6 4.6H20.2c-2.6 0-4.6-2.1-4.6-4.6V20.6h32.9v34.8zm3.2-37.8c0 .6-.4 1-1 1H13.4c-.6 0-1-.4-1-1v-5.3c0-.6.4-1 1-1h37.2c.6 0 1 .4 1 1z" />
    <path d="M25.4 45.8c.2 0 .4-.1.6-.2.4-.3.5-1 .2-1.4-1.1-1.5-1.7-3.4-1.7-5.3 0-.9.1-1.8.4-2.6.3-.9.6-1.7 1.1-2.4l.9-1.2c.4-.4.4-1 0-1.4s-1-.4-1.4 0-.8.9-1.2 1.4c-.6.9-1.1 1.9-1.4 3-.3 1-.5 2.1-.5 3.2 0 2.4.7 4.6 2.1 6.5.3.2.6.4.9.4M37.8 33.9c.5.8.9 1.6 1.1 2.4.3.8.4 1.7.4 2.6 0 1.9-.6 3.8-1.7 5.3-.3.4-.2 1.1.2 1.4.2.1.4.2.6.2.3 0 .6-.1.8-.4 1.4-1.9 2.1-4.1 2.1-6.5 0-1.1-.2-2.1-.5-3.2s-.8-2.1-1.4-3c-.4-.5-.7-1-1.2-1.4-.4-.4-1-.4-1.4 0s-.4 1 0 1.4c.4.3.7.7 1 1.2" />
  </svg>
);
export default IconRecycleAlt;
