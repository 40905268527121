import * as React from "react";
const IconCalenderAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56 11.8h-6.4V8.4c0-.6-.4-1-1-1s-1 .4-1 1v3.5H16.4V8.4c0-.6-.4-1-1-1s-1 .4-1 1v3.5H8c-2.8 0-5 2.2-5 5v34.8c0 2.8 2.2 5 5 5h48c2.8 0 5-2.2 5-5V16.8c0-2.7-2.2-5-5-5M5 27.9h11v12.4H5zm13 0h13v12.4H18zm13 14.4v12.3H18V42.3zm2 0h13v12.3H33zm0-2V27.9h13v12.4zm15-12.4h11v12.4H48zM8 13.8h6.4v3.4c0 .6.4 1 1 1s1-.4 1-1v-3.4h31.1v3.4c0 .6.4 1 1 1s1-.4 1-1v-3.4H56c1.7 0 3 1.3 3 3v9.1H5v-9.1c0-1.6 1.3-3 3-3M5 51.6v-9.3h11v12.3H8c-1.7 0-3-1.3-3-3m51 3h-8V42.3h11v9.3c0 1.7-1.3 3-3 3" />
  </svg>
);
export default IconCalenderAlt2;
