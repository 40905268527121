import * as React from "react";
const IconHousePlanAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.9 2.1H7.1C4.3 2.1 2 4.4 2 7.3v30.2c0 2.8 2.3 5.1 5.1 5.1H11c.8 0 1.6.3 2.1.8l4.1 3.8c.4.4 1 .4 1.4 0s.4-1 0-1.4L14.5 42c-1-.9-2.2-1.4-3.5-1.4H7.1c-1.7 0-3.1-1.4-3.1-3.1V25.7l2.8 2.6c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.3-1-.1-1.4L4 23V7.3c0-1.7 1.4-3.1 3.1-3.1h17v18.3h-6.5c-.6 0-1 .4-1 1s.4 1 1 1h12c.6 0 1-.4 1-1s-.4-1-1-1h-3.4V4.1H57c1.7 0 3.1 1.4 3.1 3.1v15.2H43.2q-2.1 0-3.6 1.5l-4 3.9c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3l4-3.9c.6-.6 1.4-.9 2.2-.9H60v15.9H43.6c-.6 0-1 .4-1 1s.4 1 1 1H60v14.3c0 1.7-1.4 3.1-3.1 3.1H45.7c-1.7 0-3.1-1.4-3.1-3.1v-1.1c0-2.8-2.3-5.1-5.1-5.1h-1.2c-1.7 0-3.1-1.4-3.1-3.1v-5.5l3.5-3.5c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-3.5 3.5h-7c-.6 0-1 .4-1 1s.4 1 1 1H31v5c0 2.8 2.3 5.1 5.1 5.1h1.2c1.7 0 3.1 1.4 3.1 3.1v1.1c0 2.8 2.3 5.1 5.1 5.1h11.2c2.8 0 5.1-2.3 5.1-5.1V7.3c.2-2.9-2.1-5.2-4.9-5.2" />
  </svg>
);
export default IconHousePlanAlt2;
