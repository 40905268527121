import * as React from "react";
const IconBell = (props) => (
  <svg
    height={props.size || "48"}
    width={props.size || "48"}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.6679 17.765H8.33461M15.0013 6.93164C15.0013 5.60556 14.4745 4.33379 13.5368 3.39611C12.5991 2.45842 11.3274 1.93164 10.0013 1.93164C8.6752 1.93164 7.40343 2.45842 6.46574 3.39611C5.52806 4.33379 5.00128 5.60556 5.00128 6.93164C5.00128 9.50679 4.35167 11.2699 3.626 12.4362C3.01388 13.4199 2.70783 13.9117 2.71905 14.0489C2.73147 14.2009 2.76366 14.2588 2.88609 14.3496C2.99666 14.4316 3.4951 14.4316 4.49199 14.4316H15.5106C16.5075 14.4316 17.0059 14.4316 17.1165 14.3496C17.2389 14.2588 17.2711 14.2009 17.2835 14.0489C17.2947 13.9117 16.9887 13.4199 16.3766 12.4362C15.6509 11.2699 15.0013 9.50679 15.0013 6.93164Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default IconBell;
