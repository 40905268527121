import * as React from "react";
const IconTable = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 2H7C4.2 2 2 4.2 2 7v50c0 2.8 2.2 5 5 5h50c2.8 0 5-2.2 5-5V7c0-2.8-2.2-5-5-5M18.4 49.1H31V60H18.3c.1-.1.1-.3.1-.4zM60 21.2v12.3H47.6V21.2zM45.6 33.5H33V21.2h12.6zM31 21.4v12.2H18.4V21.2H31zM16.4 33.5H4V21.2h12.4zM4 35.5h12.4v11.6H4zm14.4 0H31v11.6H18.4zM33 49.1h12.6v10.5c0 .1 0 .3.1.4H33zm0-2V35.5h12.6v11.6zm14.6-11.6H60v11.6H47.6zM7 4h50c1.7 0 3 1.3 3 3v12.2H4V7c0-1.7 1.3-3 3-3M4 57v-7.9h12.4v10.5c0 .1 0 .3.1.4H7c-1.7 0-3-1.3-3-3m53 3h-9.5c.1-.1.1-.3.1-.4V49.1H60V57c0 1.7-1.3 3-3 3" />
  </svg>
);
export default IconTable;
