import * as React from "react";
const IconOculus = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 65 64"
    {...props}
  >
    <path
      
      d="M11.13 34.187a1.5 1.5 0 0 1-.601.218c-.22.032-.43.025-.65.025H5.425c-.219 0-.43.007-.649-.025a1.5 1.5 0 0 1-.602-.218 1.24 1.24 0 0 1-.58-1.032c0-.416.218-.8.58-1.031.185-.116.382-.186.602-.218s.43-.026.65-.026H9.88c.218 0 .43-.006.649.026.218.032.416.102.601.218.363.236.581.621.581 1.031 0 .417-.22.801-.582 1.032m1.79-4.006a4.25 4.25 0 0 0-1.618-.743 5.6 5.6 0 0 0-1.032-.122q-.39-.008-.78-.006H5.815q-.39-.002-.779.006a5.6 5.6 0 0 0-1.032.122 4.25 4.25 0 0 0-1.62.743C1.42 30.906.859 32 .859 33.155c0 1.16.56 2.25 1.524 2.974.479.358 1.025.61 1.619.743a5.6 5.6 0 0 0 1.032.122q.39.008.78.006H9.49q.39.002.779-.006a5.6 5.6 0 0 0 1.032-.122 4.2 4.2 0 0 0 1.619-.743c.964-.725 1.523-1.82 1.524-2.975.001-1.16-.56-2.248-1.523-2.973M59.278 34.33l-1.173 1.125c.848.9 2.092 1.352 3.49 1.352 1.852 0 3.264-.862 3.264-2.267 0-1.312-.988-2.027-2.557-2.173l-.877-.08c-.72-.066-1.186-.29-1.186-.688 0-.424.48-.663 1.3-.663.89 0 1.554.345 1.992.848l1.159-1.112c-.72-.81-1.823-1.194-3.067-1.194-1.936 0-3.165.901-3.165 2.227 0 1.206.933 2 2.6 2.147l.89.08c.721.066 1.102.305 1.102.702 0 .49-.594.716-1.356.716-.975-.001-1.822-.372-2.416-1.02m-4.409 2.358h1.823v-7.104H54.8v4.082c0 .928-.706 1.537-1.625 1.537-.904 0-1.511-.636-1.511-1.537v-4.082h-1.894v4.36c0 1.737 1.384 2.85 2.967 2.85.89 0 1.654-.385 2.134-.875zM47.663 27h-1.894v9.688h1.894zm-5.892 9.688h1.823v-7.104H41.7v4.082c0 .928-.706 1.537-1.625 1.537-.904 0-1.512-.636-1.512-1.537v-4.082H36.67v4.36c0 1.737 1.385 2.85 2.968 2.85.89 0 1.653-.385 2.134-.875zm-7.687-2.2c-.367.397-.904.675-1.611.675-1.258 0-2.063-.86-2.063-2.014 0-1.18.805-2.041 2.063-2.041.706 0 1.244.278 1.611.702l1.3-1.219c-.678-.689-1.696-1.126-2.896-1.126-2.29 0-3.971 1.576-3.971 3.67s1.68 3.67 3.97 3.67c1.187 0 2.22-.437 2.897-1.112zm-10.74.675c-1.257 0-2.077-.86-2.077-2.014 0-1.18.82-2.041 2.077-2.041s2.077.86 2.077 2.04c.001 1.154-.819 2.015-2.077 2.015m0 1.644c2.29 0 3.971-1.577 3.971-3.671s-1.68-3.67-3.97-3.67-3.97 1.576-3.97 3.67 1.68 3.67 3.97 3.67"
    />
  </svg>
);
export default IconOculus;
