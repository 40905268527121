import * as React from "react";
const IconMushroomsAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M37.4 20.1c0-3.6-1.9-7-5.5-9.5C28.6 8.3 24.2 7 19.7 7s-8.9 1.3-12.2 3.6C3.9 13.1 2 16.4 2 20.1c0 3.8 1.6 6.8 4.4 8.2 1.6.8 3.4.9 4.9.2.6-.2 1.1-.6 1.5-1 .6 2.7.3 6-.2 8.9-.1.7.1 1.4.5 1.9s1.1.8 1.8.8h9.3c.7 0 1.4-.3 1.8-.8s.6-1.2.5-1.9c-.6-3.2-1.1-6.3-.3-9 .5.5 1 .9 1.7 1.2 1.5.7 3.4.6 5-.2 1.5-.8 4.5-2.9 4.5-8.3m-5.2 6.4c-1.1.5-2.3.6-3.4.2-.6-.2-1.3-.8-1.6-1.8-.1-.4-.5-.7-.8-.7-.4 0-.8.2-1 .5-2.1 3.8-1.4 7.8-.7 12.1 0 .1 0 .2-.1.3 0 0-.1.1-.3.1H15c-.1 0-.2-.1-.3-.1 0 0-.1-.1-.1-.3.6-3.9 1-8.4-.6-11.9q-.3-.6-.9-.6H13c-.4 0-.8.3-.9.7-.3 1-1.1 1.5-1.6 1.7-1 .4-2.2.3-3.3-.2-2.1-1-3.3-3.3-3.3-6.4 0-3 1.6-5.7 4.6-7.8S15.4 9 19.6 9s8.1 1.2 11.1 3.3 4.6 4.9 4.6 7.8c.1 4.3-2.2 5.9-3.1 6.4M57.7 34.6c-2.5-1.8-5.8-2.7-9.2-2.7s-6.7 1-9.2 2.7C36.5 36.5 35 39.2 35 42c0 3 1.3 5.4 3.6 6.5 1.5.7 3.1.7 4.4.1.2 1.7-.1 3.8-.4 5.7-.1.7.1 1.4.5 1.9s1.1.8 1.8.8h7.4c.7 0 1.3-.3 1.8-.8.4-.5.6-1.2.5-1.9-.3-2-.6-4-.4-5.7 1.3.6 2.9.6 4.4-.1.9-.5 3.4-2.2 3.4-6.5 0-2.9-1.5-5.5-4.3-7.4m-.1 12.1c-1.3.6-2.7.4-3.4-.5-.2-.3-.6-.4-1-.3s-.7.3-.8.7c-.8 2.4-.4 5.4.1 8 0 .1 0 .2-.1.3s-.1.1-.3.1h-7.4c-.1 0-.2-.1-.3-.1 0 0-.1-.1-.1-.3.5-3.2.8-5.8.1-8.1-.1-.4-.4-.6-.8-.7h-.2c-.3 0-.6.1-.8.4-.8.9-2.2 1.1-3.4.5-1.5-.8-2.5-2.5-2.5-4.7s1.2-4.2 3.5-5.8c2.2-1.5 5.1-2.4 8.1-2.4s5.9.8 8.1 2.4 3.5 3.6 3.5 5.8c.1 3.2-1.6 4.4-2.3 4.7" />
  </svg>
);
export default IconMushroomsAlt;
