import * as React from "react";
const IconMolecule = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.2 22.9c-2.7 0-4.9 1.8-5.7 4.3l-13.2-1.7c-.5-2.9-3-5-5.9-5q-2.25 0-3.9 1.5L13.8 11.7c.7-1 1.1-2.2 1.1-3.5 0-3.4-2.7-6.2-6-6.2s-6 2.8-6 6.2 2.7 6.2 6 6.2c1.3 0 2.6-.5 3.6-1.2l12.8 10.3c-.5.9-.9 2-.9 3.2 0 2.3 1.2 4.3 3 5.3L24 40.8l1.9.7 3.4-8.8c.4.1.7.1 1.1.1 3 0 5.5-2.3 6-5.3l12.9 1.7c0 3.4 2.7 6.1 6 6.1s6-2.8 6-6.2-2.8-6.2-6.1-6.2M8.8 12.3c-2.2 0-4-1.9-4-4.2S6.6 4 8.8 4s4 1.9 4 4.2-1.8 4.1-4 4.1m21.5 18.5c-2.2 0-4-1.9-4-4.2s1.8-4.2 4-4.2 4 1.9 4 4.2-1.8 4.2-4 4.2m24.9 2.4c-2.2 0-4-1.9-4-4.2s1.8-4.2 4-4.2 4 1.9 4 4.2-1.8 4.2-4 4.2" />
    <path d="M21.7 40.8c-5.7 0-10.3 4.7-10.3 10.6S16 62 21.7 62 32 57.3 32 51.4s-4.6-10.6-10.3-10.6m0 19.2c-4.6 0-8.3-3.9-8.3-8.6s3.7-8.6 8.3-8.6 8.3 3.9 8.3 8.6-3.7 8.6-8.3 8.6" />
  </svg>
);
export default IconMolecule;
