import * as React from "react";
const IconSchoolBenchAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60 16.7c-1.2 0-2.3 1-2.3 2.3v7.9H42.2c-2.6 0-4.8 2.2-4.8 4.8v5.1c0 1.8 1 3.4 2.5 4.2v8.5c0 1.2 1 2.3 2.3 2.3s2.3-1 2.3-2.3v-7.9h13.3v7.9c0 1.2 1 2.3 2.3 2.3s2.3-1 2.3-2.3V18.9c-.1-1.2-1.2-2.2-2.4-2.2M41.9 31.6q0-.3.3-.3h15.6V37H42.2q-.3 0-.3-.3zM30.8 18.8 7.5 12.4c-1.3-.4-2.7-.1-3.9.8s-1.9 2.3-1.9 3.9V22c0 2.2 1.4 4.1 3.4 4.6l1.7.5v22.4c0 1.2 1 2.3 2.3 2.3s2.3-1 2.3-2.3V28.3L24.7 32v17.5c0 1.2 1 2.3 2.3 2.3s2.3-1 2.3-2.3V33.1h.5q1.5 0 2.7-.9c1.2-.9 1.9-2.3 1.9-3.9v-4.9c-.1-2.1-1.6-4-3.6-4.6m-1 9.6c0 .2-.1.3-.1.3L6.4 22.3s-.1-.1-.1-.3v-4.9c0-.2.1-.3.1-.3l23.3 6.4s.1.1.1.3z" />
  </svg>
);
export default IconSchoolBenchAlt;
