import * as React from "react";
const IconCharging = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 25.3h-4.3v-3.7c0-2.8-2.2-5-5-5H7c-2.8 0-5 2.2-5 5v20.7c0 2.8 2.2 5 5 5h41.7c2.8 0 5-2.2 5-5v-3.7H58c2.2 0 4-1.8 4-4v-5.3c0-2.2-1.8-4-4-4m-6.3 17.1c0 1.7-1.3 3-3 3H7c-1.7 0-3-1.3-3-3V21.6c0-1.7 1.3-3 3-3h41.7c1.7 0 3 1.3 3 3v20.8m8.3-7.7c0 1.1-.9 2-2 2h-4.3v-9.3H58c1.1 0 2 .9 2 2z" />
    <path d="M31.7 31.2h-5.4l3-5.1c.3-.5.1-1.1-.4-1.4s-1.1-.1-1.4.4l-3.9 6.6c-.2.3-.2.7 0 1s.5.5.9.5H30L27.3 38c-.3.5-.1 1.1.4 1.4.2.1.3.1.5.1.4 0 .7-.2.9-.5l3.5-6.3c.2-.3.2-.7 0-1s-.5-.5-.9-.5" />
  </svg>
);
export default IconCharging;
