import * as React from "react";
const IconMotion = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m24.7 13.7-9.4-9.4c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l7.7 7.7H5.9c-1.6 0-2.9 1.3-2.9 2.9v22.099999999999998c0 .6.4 1 1 1s1-.4 1-1V16.3c0-.5.4-.9.9-.9h15.7l-7.7 7.7c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l9.4-9.4c.4-.4.4-1 0-1.4M27.1 58h-8c-.6 0-1 .4-1 1s.4 1 1 1h8c.6 0 1-.4 1-1s-.4-1-1-1M11.1 58H5.9c-.5 0-.9-.4-.9-.8-.1-.5-.6-1-1.1-.9-.5 0-.9.5-.9 1.1.2 1.5 1.4 2.6 2.9 2.6h5.2c.6 0 1-.4 1-1s-.4-1-1-1M43.1 58h-8c-.6 0-1 .4-1 1s.4 1 1 1h8c.6 0 1-.4 1-1s-.4-1-1-1M4 50.3c.6 0 1-.4 1-1v-8c0-.6-.4-1-1-1s-1 .4-1 1v8c0 .5.4 1 1 1M60 26c-.6 0-1 .4-1 1v8c0 .6.4 1 1 1s1-.4 1-1v-8c0-.5-.4-1-1-1M60 42c-.6 0-1 .4-1 1v8c0 .6.4 1 1 1s1-.4 1-1v-8c0-.5-.4-1-1-1M47.6 13.6h-8c-.6 0-1 .4-1 1s.4 1 1 1h8c.6 0 1-.4 1-1s-.4-1-1-1M58.1 13.6h-2.5c-.6 0-1 .4-1 1s.4 1 1 1h2.5c.5 0 .9.4.9.9V19c0 .6.4 1 1 1s1-.4 1-1v-2.5c0-1.6-1.3-2.9-2.9-2.9M58.5 57.8c-.1.1-.3.1-.5.1h-7c-.6 0-1 .4-1 1s.4 1 1 1h7c.5 0 1-.1 1.5-.4s.6-.9.4-1.4c-.3-.4-.9-.5-1.4-.3" />
  </svg>
);
export default IconMotion;
