import * as React from "react";
const IconHelicopterCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 23.1c-.6 0-1 .4-1 1v1.6H35.6v-3.4c0-1-.8-1.8-1.8-1.8H22.4V12h14.7c.6 0 1-.4 1-1s-.4-1-1-1H5.6c-.6 0-1 .4-1 1s.4 1 1 1h14.7v8.5H9c-3.8 0-7 3.1-7 7v14.3c0 1 .8 1.8 1.8 1.8h6.1v8.5H6c-.7 0-1.3-.5-1.3-1.1s-.4-1-1-1-1 .4-1 1c0 1.7 1.5 3.1 3.3 3.1h26c.6 0 1-.4 1-1s-.4-1-1-1h-6.9v-8.5h8.7c1 0 1.8-.8 1.8-1.8v-3.3L60 35.8v1.5c0 .6.4 1 1 1s1-.4 1-1V24.1c0-.6-.4-1-1-1m-37.9 29H11.9v-8.5h11.2zm10.5-10.6H4V27.4c0-2.7 2.2-5 5-5h24.7v19.1zm2-5.1v-8.7H60v6z" />
  </svg>
);
export default IconHelicopterCopy;
