import * as React from "react";
const IconShipThumbsUp = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M36.5 57.5c-.2-.2-.4-.3-.6-.5l3.9-13.9c.6-2.3-.5-4.6-2.7-5.5l-2.8-1.1v-4.2c0-.6-.4-1-1-1s-1 .4-1 1v3.3L24 32.1c-1.1-.5-2.4-.5-3.6 0L12 35.6v-6.3c0-1.5 1.2-2.6 2.6-2.6h11.8c.6 0 1-.4 1-1s-.4-1-1-1h-9.9v-5.1c0-1.5 1.2-2.6 2.6-2.6H26c.6 0 1-.4 1-1s-.4-1-1-1h-2.8v-4.4c0-.6-.4-1-1-1s-1 .4-1 1V15H19c-2.6 0-4.6 2.1-4.6 4.6v5.1c-2.4.2-4.3 2.2-4.3 4.6v7.1l-2.7 1.1c-2.2.9-3.3 3.3-2.7 5.5l4 14c-.2.1-.4.3-.6.4l-3.2 2.8c-.4.4-.5 1-.1 1.4.1.3.4.4.7.4.2 0 .5-.1.7-.2L9.4 59c1-.8 2.5-.8 3.4 0l.9.7c1.7 1.5 4.3 1.5 6.1 0l.8-.7c1-.8 2.5-.8 3.4 0l.8.7c1.7 1.5 4.3 1.5 6.1 0l.8-.7c1-.9 2.5-.8 3.4 0l3.2 2.8c.4.4 1 .3 1.4-.1s.3-1-.1-1.4zm-6 0-.8.7c-1 .8-2.5.8-3.4 0l-.8-.7c-1.7-1.5-4.3-1.5-6.1 0l-.8.7c-1 .8-2.5.8-3.4 0l-.9-.7c-1-.9-2.3-1.2-3.5-1.1L6.7 42.5c-.4-1.3.3-2.6 1.5-3.1l3.3-1.4h.1l9.7-4c.6-.3 1.4-.3 2 0l9.6 4h.1l3.3 1.4c1.2.5 1.9 1.9 1.5 3.1L34 56.4c-1.3-.2-2.5.2-3.5 1.1M58 10.5c-.8-.6-1.7-1-2.7-1h-3.6V6.9c0-1.9-.6-3.2-1.7-4.1-.7-.5-1.5-.8-2.6-.8-.8 0-1.9.2-2.1.2-.7.2-1.1.8-1.1 1.5v4c0 1.6-1.3 2.5-2.2 3q-.3-.6-.9-.6H35c-.6 0-1 .4-1 1v14.5c0 .6.4 1 1 1h6.1q.6 0 .9-.6.75.3 1.5.3h9.9c2.7 0 4.5-1.5 4.8-4l1.3-7.9c.2-1.5-.3-3-1.5-3.9M40.2 24.7H36V12.1h4.1v12.6zm17.3-10.6-1.3 8c-.2 1.9-1.7 2.3-2.8 2.3h-9.9c-.5 0-1-.2-1.4-.5v-11c1.7-.6 4.1-2.3 4.1-5.1V4.1c.3 0 .8-.1 1.2-.1.6 0 1.1.1 1.4.4.6.4.9 1.3.9 2.4v3.6c0 .6.4 1 1 1h4.6c.5 0 1 .2 1.4.5.6.6.9 1.4.8 2.2" />
  </svg>
);
export default IconShipThumbsUp;
