import * as React from "react";
const IconHighHeel = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.4 45.7c-.1-.1-10.9-10.9-12.7-13.5-2.1-3.1-3.8-6.9-5-11.2-.8-3.7-2.9-6-5.8-6.7-1.9-.2-3.6.3-3.9.4-2.8.6-6.1 0-9.4-1.5-2.7-1.2-5.1-2.9-7.6-4.7l-.5-.3c-.4-.4-1.7-1.6-3.4-1.6-1.1 0-2 .4-2.9 1.2-.4.3-7.2 7.9-7.2 12.8 0 .3.1.5.3.7l1.8 1.9 1.7 30.5c0 .4.3.8.8.9h.2c.4 0 .7-.2.9-.5l10-17.3c2 2.1 3.7 4.7 5.6 7.4l.3.4c1.6 2.4 3.4 4.9 5.5 7.6 3.6 4.6 7.3 5.3 9.9 5.3.7 0 1.3-.1 1.8-.1.3 0 .6-.1.9 0 5.6-.3 11.5-.7 16.9-2.6 1.7-.6 3-2 3.5-3.9.2-2-.4-3.8-1.7-5.2M16 35.8 7.6 50.2 6.3 25.3l10.1 10.1c-.2.1-.3.2-.4.4m43.9 14.5c-.3 1.2-1.1 2.1-2.2 2.5-5.1 1.9-10.6 2.2-16.2 2.5-.3 0-.6 0-1 .1-2.1.2-6 .5-9.9-4.5-2-2.6-3.8-5-5.4-7.4l-.3-.4c-1.9-2.8-3.7-5.5-5.8-7.7L4 20.2c.3-3.5 4.8-9.1 6.6-11.1.4-.4.9-.6 1.4-.6.9 0 1.8.8 2.1 1.1.1.1.1.1.2.1l.6.4c2.6 1.8 5.1 3.6 7.9 4.8 3.7 1.8 7.5 2.4 10.7 1.7h.1s1.5-.5 3-.3c2.6.6 3.7 3.1 4.2 5.2 1.3 4.6 3.1 8.6 5.3 11.8C48 36 58.5 46.6 59 47.1c.8.9 1.2 2.1.9 3.2" />
  </svg>
);
export default IconHighHeel;
