import React, { useState } from 'react';
import BringzzText from "../BringzzText";

const BringzzRangePicker = ({ min, max, step, onChange }) => {
    const [minValue, setMinValue] = useState(min);
    const [maxValue, setMaxValue] = useState(max);

    const handleMinChange = (event) => {
        const value = Math.min(Number(event.target.value), maxValue - step);
        setMinValue(value);
        onChange([value, maxValue]);
    };

    const handleMaxChange = (event) => {
        const value = Math.max(Number(event.target.value), minValue + step);
        setMaxValue(value);
        onChange([minValue, value]);
    };

    return (
        <div className="relative w-full h-8 mt-4">
            <div className="absolute w-full h-1 bg-gray-300 rounded" />
            <div
                className="absolute h-1 bg-purple-600 rounded"
                style={{
                    left: `${((minValue - min) / (max - min)) * 100}%`,
                    right: `${100 - ((maxValue - min) / (max - min)) * 100}%`
                }}
            />

            <input
                type="range"
                min={min}
                max={max}
                step={step}
                value={minValue}
                onChange={handleMinChange}
                className="absolute w-full h-1 bg-transparent appearance-none pointer-events-auto z-20"
                style={{ zIndex: minValue > max - 100 ? 5 : 4 }}
            />
            <input
                type="range"
                min={min}
                max={max}
                step={step}
                value={maxValue}
                onChange={handleMaxChange}
                className="absolute w-full h-1 bg-transparent appearance-none pointer-events-auto z-20"
            />

            <div className="absolute top-0 left-0 w-full flex justify-between items-center mt-5 text-sm pointer-events-none">
                <span
                    className="absolute"
                    style={{
                        left: `calc(${((minValue - min) / (max - min)) * 100}%)`,
                        transform: 'translateX(-50%)',
                    }}
                >
                    <BringzzText tag='h4'>€{minValue}</BringzzText>
                </span>
                <span
                    className="absolute"
                    style={{
                        left: `calc(${((maxValue - min) / (max - min)) * 100}% - 10px)`,
                        transform: 'translateX(-50%)',
                    }}
                >
                    <BringzzText tag='h4'>€{maxValue}</BringzzText>
                </span>
            </div>
        </div>
    );
};

export default BringzzRangePicker;
