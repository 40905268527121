import React, { useState } from 'react';
import { BringzzButton, BringzzText } from "@bringzz/components";
import { useNavigation } from 'context/NavigationContext';
import {
    BringzzPageContainer,
    BringzzIcon,
    BringzzRadioList,
    BringzzRadio,
    BringzzMultiSelect,
    BringzzCheck
} from "@bringzz/components";
import { useLocation } from 'react-router-dom';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

// TODO: BringzzRadioList should accept multiple selections?
const ConsumerInterestPage = () => {
    const { backPress } = useNavigation();

    const { navigate } = useNavigation();

    const [selected, setSelected] = useState(null)

    let icon = (
        <BringzzIcon
            icon="IconMountain"
            library="Custom"
            className="flex items-center justify-center h-10 w-10 bg-gray-100 rounded-full"
            size="28"
        />
    );

    let state = {
        checked: (
            <BringzzIcon
                icon="IconCheck"
                library="Custom"
                className="h-5 w-5 p-1 bg-magic-lilac text-light rounded-full"
                size="12"
            />
        ),
        unChecked: (
            <div className="h-5 w-5 rounded-full border border-neutral-400"></div>
        ),
    };

    const [selectedInterests, setSelectedInterests] = useState(null)

    const handleMultiSelectChange = (selectedValues) =>
        setSelectedInterests(selectedValues)

    const defaultIntrests = [
        { label: "Recommendations by our pharmacists", icon: <BringzzIcon icon='IconLike3' folder='LineIcons' size='28' /> },
        { label: "Special offers", icon: <BringzzIcon icon='IconDiscountLabel' folder='LineIcons' size='28' /> },
        { label: "Traditional products from local pharmacies", icon: <BringzzIcon icon='IconBowlAlt' folder='LineIcons' size='28' /> },
        { label: "Allergy products during high seasons", icon: <BringzzIcon icon='IconAllergies' folder='HealthIcons' size='28' /> },
        { label: "Diabetes Products", icon: <BringzzIcon icon='IconDiabetes' folder='HealthIcons' size='28' /> },
        { label: "Protective products against viruses & bacteria", icon: <BringzzIcon icon='IconVirus' folder='HealthIcons' size='28' /> },
        { label: "Updates & new cosmetics", icon: <BringzzIcon icon='IconDairy' folder='HealthIcons' size='28' /> },
        { label: "Homeopathics", icon: <BringzzIcon icon='IconElectricity' folder='LineIcons' size='28' /> },
        { label: "Performance enhancing products", icon: <BringzzIcon icon='IconElectricity' folder='LineIcons' size='28' /> },
    ]

    return (
        <BringzzPageContainer
            className="bg-white"
            steps={true}
            activeStep={10}
            header={{
                left: (
                    <BringzzIcon
                        size='22'
                        icon='IconChevronLeft'
                        folder='LineIcons'
                        className='cursor-pointer'
                        onClick={backPress}
                    />
                ),
                title: "Choose your interests",
                right: <BringzzButton size='sm' className="border border-black" onClick={() => {
                    navigate('/register/consumer/brands');
                }}>Skip</BringzzButton>
            }}
            footer={
                <div className='pb-8 px-6'>
                    <BringzzButton
                        size='lg'
                        className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
                        onClick={async () => {
                            navigate('/register/consumer/brands')
                        }}
                        disabled={!selectedInterests}
                    >
                        Continue
                    </BringzzButton>
                </div>
            }
            authenticated={false}
        >
            <div className='relative flex justify-center bg-magic-lilac/20'>
                <div className='relative bg-white w-full bg-cover bg-center flex flex-col'>
                    {/* Content */}
                    <div className='flex-grow flex flex-col py-8 px-4'>
                        <div className='z-10 flex flex-col '>
                            <div className='pl-2 flex flex-col space-y-6 md:max-w-lg lg:max-w-xl xl:max-w-2xl'>
                                <BringzzText tag='h1' className='pl-2 text-midnight-blue font-bold'>
                                    Let us know what you are interested in
                                </BringzzText>
                                <BringzzText tag="h5" className="font-normal">Based on your selections, we will be able to personalise your content feed, newsletter and notifications. </BringzzText>
                            </div>
                            <div className='mt-6'>
                                <BringzzMultiSelect
                                    onChange={handleMultiSelectChange}
                                    state={{ checked: state.checked, unChecked: state.unChecked }}
                                    className="divide-y"
                                >
                                    {defaultIntrests.map((interest, index) => {
                                        return <BringzzCheck labelClassname='text-left pl-4' left={interest.icon} key={index} value={interest.label}>{interest.label}</BringzzCheck>
                                    })}

                                </BringzzMultiSelect>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BringzzPageContainer >
    );
};

export default ConsumerInterestPage;
