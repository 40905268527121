import * as React from "react";
const IconBarCodeSearch = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M6.6 2c-.6 0-1 .4-1 1v58c0 .6.4 1 1 1s1-.4 1-1V3c0-.6-.5-1-1-1M17.8 2c-.6 0-1 .4-1 1v41.1c0 .6.4 1 1 1s1-.4 1-1V3c0-.6-.5-1-1-1M58.1 49.7l-8.6-8.6c2.7-3.2 3.7-7.4 2.9-11.3V3c0-.6-.4-1-1-1s-1 .4-1 1v22.2c-.4-.7-1-1.3-1.5-1.9-2.1-2.1-4.9-3.4-7.7-3.7V3c0-.6-.4-1-1-1s-1 .4-1 1v16.5c-3.2.1-6.4 1.4-8.9 3.8l-.3.3V3c0-.6-.4-1-1-1s-1 .4-1 1v23.5c-2.6 5-1.9 11.3 2.3 15.4 2.6 2.6 5.9 3.8 9.3 3.8 3 0 6.1-1.1 8.5-3.2l8.6 8.6c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4m-26.4-9.2c-4.3-4.3-4.3-11.4 0-15.7 2.2-2.2 5-3.3 7.9-3.3 2.8 0 5.7 1.1 7.9 3.3 4.3 4.3 4.3 11.4 0 15.7-4.4 4.3-11.4 4.3-15.8 0" />
  </svg>
);
export default IconBarCodeSearch;
