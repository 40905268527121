import * as React from "react";
const IconPhoneCut = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m60.5 45.6-9.1-6c-1.6-1-3.6-.6-4.7.8L44.1 44c-.4.6-1.3.7-1.9.3-1.9-1.2-3.8-2.5-5.6-3.8-.4-.3-1.1-.3-1.4.2-.3.4-.3 1.1.2 1.4 1.9 1.4 3.8 2.8 5.8 4 1.6 1 3.6.6 4.6-.9l2.6-3.6c.4-.6 1.3-.7 1.9-.3l9.1 6c.6.4.8 1.2.5 1.8l-5 8.2-.3.3c-4.6 4-15.6.8-26.9-7.8-.4-.3-1.1-.2-1.4.2s-.2 1.1.2 1.4c8.5 6.4 16.7 9.9 22.8 9.9 2.7 0 5-.7 6.7-2.2.2-.2.5-.5.7-.8l5-8.2c.8-1.5.3-3.5-1.2-4.5M56.3 8.2c-.4-.4-1-.4-1.4 0L28.8 33.8c-4.8-4.9-8-9.4-9.5-11.7-.4-.6-.2-1.4.4-1.8l3.7-2.5c.7-.5 1.2-1.3 1.4-2.1.2-.9 0-1.8-.5-2.5l-6.1-8.9c-1-1.5-3-1.9-4.6-1L5.1 8c-.4.2-.6.4-.9.6-5.8 6.4.3 21.9 13.9 35.6L7.7 54.4c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3L56.3 9.6c.4-.3.4-1 0-1.4M5.7 10l.3-.3 8.4-4.9c.6-.4 1.5-.2 1.9.4l6.1 8.9c.2.3.3.7.2 1s-.3.6-.6.8l-3.7 2.5c-1.5 1-1.9 3-.9 4.6 1.5 2.3 4.9 7 9.8 12l-7.8 7.6C6.9 30.1.7 15.5 5.7 10" />
  </svg>
);
export default IconPhoneCut;
