import * as React from "react";
const IconWifiAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.2 13.4C44.8-2 19.5-1.8 3.8 13.9c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3C20.1.4 44.1.2 58.8 14.8c.4.4 1 .4 1.4 0s.4-1 0-1.4M15.5 35.5c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3c8.2-8.2 21.6-8.3 29.7-.1.4.4 1 .4 1.4 0s.4-1 0-1.4c-8.9-8.9-23.4-8.9-32.5.1M32.3 48.2c-1.9 0-3.6.7-4.9 2s-2 3-2 4.9.7 3.6 2 4.9 3 2 4.9 2c1.8 0 3.6-.7 4.9-2 2.7-2.7 2.7-7.1 0-9.8-1.3-1.3-3-2-4.9-2m3.5 10.4c-.9.9-2.1 1.4-3.5 1.4-1.3 0-2.5-.5-3.5-1.4s-1.4-2.2-1.4-3.5.5-2.5 1.4-3.5c.9-.9 2.1-1.4 3.5-1.4 1.3 0 2.5.5 3.5 1.4 1.9 1.9 1.9 5.1 0 7" />
  </svg>
);
export default IconWifiAlt;
