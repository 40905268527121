import * as React from "react";
const IconHome8x = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.9 25.7H31.3V8.5c0-2-1.1-3.8-2.9-4.6-1.8-.9-3.9-.6-5.4.7L3.9 20.4c-1.2 1-1.9 2.4-1.9 3.9v31.2c0 2.8 2.3 5.1 5.1 5.1H57c2.8 0 5.1-2.3 5.1-5.1V30.8c-.1-2.8-2.4-5.1-5.2-5.1M4 55.5V24.3c0-.9.4-1.8 1.1-2.4L24.2 6.1c.6-.5 1.3-.7 2-.7.4 0 .9.1 1.3.3 1.1.5 1.8 1.6 1.8 2.8v50.1h-6.4V46.7c0-2.2-1.8-4-4-4h-4.3c-2.2 0-4 1.8-4 4v11.9H7.1c-1.7 0-3.1-1.4-3.1-3.1m8.6 3.1V46.7c0-1.1.9-2 2-2h4.3c1.1 0 2 .9 2 2v11.9zM60 55.5c0 1.7-1.4 3.1-3.1 3.1H31.3V27.7h25.6c1.7 0 3.1 1.4 3.1 3.1z" />
  </svg>
);
export default IconHome8x;
