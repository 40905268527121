import * as React from "react";
const IconAngry = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M18.8 25.1h9.4c.4 0 .8-.3.9-.7s0-.9-.3-1.1L20 16.7c-.4-.3-1.1-.2-1.4.2s-.2 1.1.2 1.4l6.4 4.8h-6.4c-.6 0-1 .4-1 1s.5 1 1 1M35.8 25.1h9.4c.6 0 1-.4 1-1s-.4-1-1-1h-6.4l6.4-4.8c.4-.3.5-1 .2-1.4s-1-.5-1.4-.2l-8.8 6.6c-.3.3-.5.7-.3 1.1.1.4.4.7.9.7" />
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
    <path d="M32 33.3c-7.7 0-14.3 5.5-15.8 13-.1.6 0 1.2.4 1.7s1 .8 1.7.8h27.3c.7 0 1.3-.3 1.7-.8s.6-1.1.5-1.7c-1.5-7.6-8.1-13-15.8-13m13.6 13.5H18.4c-.1 0-.1-.1-.2-.2C19.5 40 25.3 35.2 32 35.2S44.5 40 45.8 46.7c0 .1-.1.1-.2.1" />
  </svg>
);
export default IconAngry;
