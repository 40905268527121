import * as React from "react";
const IconDownServer = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62 25.6V12.4c0-2.8-2.2-5-5-5H7c-2.8 0-5 2.2-5 5v13.2c0 1.6.8 3.1 2 4-1.2.9-2 2.4-2 4v13.2c0 2.8 2.2 5 5 5h35.3c.6 0 1-.4 1-1s-.4-1-1-1H7c-1.7 0-3-1.3-3-3V33.6c0-1.7 1.3-3 3-3h50c1.7 0 3 1.3 3 3v9.6c0 .6.4 1 1 1s1-.4 1-1v-9.6c0-1.6-.8-3.1-2-4 1.2-.9 2-2.4 2-4m-2 0c0 1.7-1.3 3-3 3H7c-1.7 0-3-1.3-3-3V12.4c0-1.7 1.3-3 3-3h50c1.7 0 3 1.3 3 3z" />
    <path d="M15.6 17.8c-.6 0-1 .4-1 1v.2c0 .6.4 1 1 1s1-.4 1-1v-.2c0-.6-.5-1-1-1M50.8 17.9H44c-.6 0-1 .4-1 1s.4 1 1 1h6.7c.6 0 1-.4 1-1s-.4-1-.9-1M14.6 39.9v.2c0 .6.4 1 1 1s1-.4 1-1v-.2c0-.6-.4-1-1-1s-1 .5-1 1M51.8 39.9c0-.6-.4-1-1-1H44c-.6 0-1 .4-1 1s.4 1 1 1h6.7c.6 0 1.1-.4 1.1-1M57.3 51.6l-2.6 2.6v-6.9c0-.6-.4-1-1-1s-1 .4-1 1v6.9l-2.6-2.6c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l3.6 3.6c.4.4.9.6 1.4.6s1-.2 1.4-.6l3.6-3.6c.4-.4.4-1 0-1.4s-1-.4-1.4 0" />
  </svg>
);
export default IconDownServer;
