import * as React from "react";
const IconThumbnail = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M13.8 2H6C3.8 2 2 3.8 2 6v7.8c0 2.2 1.8 4 4 4h7.8c2.2 0 4-1.8 4-4V6c0-2.2-1.8-4-4-4m2 11.8c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2h7.8c1.1 0 2 .9 2 2zM35.9 2h-7.8c-2.2 0-4 1.8-4 4v7.8c0 2.2 1.8 4 4 4h7.8c2.2 0 4-1.8 4-4V6c0-2.2-1.8-4-4-4m2 11.8c0 1.1-.9 2-2 2h-7.8c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2h7.8c1.1 0 2 .9 2 2zM58 2h-7.8c-2.2 0-4 1.8-4 4v7.8c0 2.2 1.8 4 4 4H58c2.2 0 4-1.8 4-4V6c0-2.2-1.8-4-4-4m2 11.8c0 1.1-.9 2-2 2h-7.8c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2H58c1.1 0 2 .9 2 2zM13.8 24.1H6c-2.2 0-4 1.8-4 4v7.8c0 2.2 1.8 4 4 4h7.8c2.2 0 4-1.8 4-4v-7.8c0-2.2-1.8-4-4-4m2 11.8c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2v-7.8c0-1.1.9-2 2-2h7.8c1.1 0 2 .9 2 2zM35.9 24.1h-7.8c-2.2 0-4 1.8-4 4v7.8c0 2.2 1.8 4 4 4h7.8c2.2 0 4-1.8 4-4v-7.8c0-2.2-1.8-4-4-4m2 11.8c0 1.1-.9 2-2 2h-7.8c-1.1 0-2-.9-2-2v-7.8c0-1.1.9-2 2-2h7.8c1.1 0 2 .9 2 2zM58 24.1h-7.8c-2.2 0-4 1.8-4 4v7.8c0 2.2 1.8 4 4 4H58c2.2 0 4-1.8 4-4v-7.8c0-2.2-1.8-4-4-4m2 11.8c0 1.1-.9 2-2 2h-7.8c-1.1 0-2-.9-2-2v-7.8c0-1.1.9-2 2-2H58c1.1 0 2 .9 2 2zM13.8 46.2H6c-2.2 0-4 1.8-4 4V58c0 2.2 1.8 4 4 4h7.8c2.2 0 4-1.8 4-4v-7.8c0-2.2-1.8-4-4-4m2 11.8c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2v-7.8c0-1.1.9-2 2-2h7.8c1.1 0 2 .9 2 2zM35.9 46.2h-7.8c-2.2 0-4 1.8-4 4V58c0 2.2 1.8 4 4 4h7.8c2.2 0 4-1.8 4-4v-7.8c0-2.2-1.8-4-4-4m2 11.8c0 1.1-.9 2-2 2h-7.8c-1.1 0-2-.9-2-2v-7.8c0-1.1.9-2 2-2h7.8c1.1 0 2 .9 2 2zM58 46.2h-7.8c-2.2 0-4 1.8-4 4V58c0 2.2 1.8 4 4 4H58c2.2 0 4-1.8 4-4v-7.8c0-2.2-1.8-4-4-4M60 58c0 1.1-.9 2-2 2h-7.8c-1.1 0-2-.9-2-2v-7.8c0-1.1.9-2 2-2H58c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconThumbnail;
