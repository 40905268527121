import * as React from "react";
const IconWeddingShoe = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.9 34.4c-.3-4.4-3.7-5.5-5.5-5.5-2.2.1-4.5-.7-6.5-2.2-.1-.1-.4-.3-.9-.7-2.6-2-10.4-8-14.1-10.4-4.7-3-9.6-1.7-9.7-1.7-9.6 2.4-16.2.3-16.2.2h-.2c-2.1-.3-3.7 0-5 1.1C2 16.8 2 19.5 2 19.6v25.9c0 2.7 2.2 4.9 4.9 4.9h14c2.7 0 4.9-2.2 4.9-4.9v-3.1c.4.2.9.3 1.4.5 4.7 1.9 14.6 5.9 28.3 4.7h.1c3.2-.8 5.4-2.8 6.1-5.6.4-2.4.3-5.5.2-7.6M20.8 48.3h-14c-1.6 0-2.9-1.3-2.9-2.9v-3.5h19.7v3.5c.1 1.7-1.2 2.9-2.8 2.9m38.8-6.7c-.5 2-2 3.5-4.5 4.1-13.2 1.1-22.7-2.7-27.3-4.6-1.3-.5-2.2-.9-2.9-1H4V19.7s0-1.8 1.2-2.9c.5-.6 1.3-.8 2.2-.8.3 0 .7 0 1 .1.9.3 7.6 2.2 17.3-.1 0 0 4.2-1.1 8.2 1.4.9.6 2.2 1.5 3.5 2.4l-6.5 5.1c-.4.3-.5 1-.2 1.4.2.3.5.4.8.4.2 0 .4-.1.6-.2l6.9-5.4c1.1.8 2.3 1.7 3.4 2.5l-6 4.6c-.4.3-.5 1-.2 1.4.2.3.5.4.8.4.2 0 .4-.1.6-.2l6.4-5c1.6 1.2 2.9 2.2 3.7 2.8.5.4.9.7 1 .8 2.3 1.7 5 2.6 7.6 2.5.1 0 3.2 0 3.5 3.6.2 2 .3 4.9-.2 7.1" />
  </svg>
);
export default IconWeddingShoe;
