import * as React from "react";
const IconCandyCaneCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.3 15.3c-7.3-7.1-18.9-7.4-25.9-.6l-26.6 26C2.6 41.8 2 43.3 2 44.9c0 1.7.7 3.3 2 4.6 2.5 2.5 6.5 2.5 8.9.2l26.2-25.6c2.1-2.1 5.6-2.1 7.9-.2 1.3 1.1 2 2.6 2.1 4.2 0 1.5-.5 2.8-1.5 3.8l-12 11.6c-1.1 1.1-1.8 2.6-1.8 4.2 0 1.7.7 3.3 2 4.6s3 1.9 4.6 1.9 3.1-.6 4.3-1.7l12.2-11.9c3.4-3.3 5.2-7.8 5.1-12.6-.1-4.7-2.1-9.2-5.7-12.7M11.5 48.2c-1.6 1.6-4.4 1.5-6.1-.2-.9-.9-1.4-2-1.4-3.1s.4-2.1 1.2-2.8l7.2-7L21 39zm11-10.7-8.6-3.9 8.3-8.1 8.6 3.9zm20.8 13.6c-1.6 1.6-4.4 1.5-6.1-.2-.9-.9-1.4-2-1.4-3.1s.4-2.1 1.2-2.8l3.5-3.4 8.6 3.9zm12.2-11.9L50.6 44 42 40.1l6.9-6.7c1.4-1.4 2.2-3.3 2.1-5.3 0-2.2-1.1-4.2-2.8-5.7-3.1-2.6-7.7-2.5-10.6.3L32.1 28l-8.4-4 8.1-7.9c6.2-6.1 16.6-5.8 23.1.6 3.2 3.1 5 7.1 5.1 11.4s-1.5 8.2-4.5 11.1" />
  </svg>
);
export default IconCandyCaneCopy;
