import * as React from "react";
const IconRocketLaunch5 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M20.9 43.4h6.5c-.2.5-.2 1-.2 1.5 0 2.5 3.5 7.3 4.3 8.2.2.3.5.4.8.4s.6-.1.8-.4c.7-.9 4.2-5.8 4.2-8.2 0-.5-.1-1-.2-1.5h6.5c.6 0 1-.4 1-1v-6.1c0-.3-.1-.5-.3-.7l-5-5v-8.9c0-10.2-3.2-14.4-6.3-17.2-.4-.3-.9-.3-1.3 0-3.1 2.7-6.3 6.9-6.3 17.2v8.9l-5 5c-.2.2-.3.4-.3.7v6.1c-.2.6.2 1 .8 1M35.2 45c0 1.1-1.6 3.8-3 5.9-1.5-2.1-3-4.8-3-5.9 0-.5.1-1.1.4-1.5h5.2c.3.4.4.9.4 1.5m-13.3-8.3 5-5c.2-.2.3-.4.3-.7v-9.3c0-8.7 2.3-12.5 5-15 2.7 2.6 5 6.4 5 15V31c0 .3.1.5.3.7l5 5v4.7H21.9z" />
    <path d="M59.5 57.7h-4.8c.2-.5.4-1 .4-1.6v-22c0-2.7-2.2-4.9-4.9-4.9h-1.5c-.6 0-1 .4-1 1s.4 1 1 1h1.5c1.6 0 2.9 1.3 2.9 2.9v22c0 .9-.7 1.6-1.6 1.6H12.9c-.9 0-1.6-.7-1.6-1.6v-22c0-1.6 1.3-2.9 2.9-2.9h.8c.6 0 1-.4 1-1s-.4-1-1-1h-.8c-2.7 0-4.9 2.2-4.9 4.9v22c0 .6.2 1.1.4 1.6H4.5c-.6 0-1 .4-1 1s.4 1 1 1h54.9c.6 0 1-.4 1-1s-.3-1-.9-1" />
  </svg>
);
export default IconRocketLaunch5;
