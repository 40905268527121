import * as React from "react";
const IconSproutAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.5 59.6 38.3 45c-1.4-.9-3-1.4-4.5-1.7V27.8c1.1.3 2.3.4 3.5.4 4.7 0 9.6-2.1 13.5-6C55.9 17 58 9.8 55.9 4l-.2-.4-.4-.2C49.4 1.3 42.2 3.3 37 8.5s-7.2 12.3-5.2 18.1v16.6c-2.1 0-4.2.6-6 1.8L2.5 59.6c-.4.2-.6.7-.4 1.1.1.4.5.7 1 .7h58c.4 0 .8-.3 1-.7 0-.4-.2-.9-.6-1.1M38.3 9.9c4.5-4.5 10.8-6.4 15.8-4.8 1.6 5-.3 11.3-4.8 15.8s-10.8 6.4-15.8 4.8c-1.5-5 .4-11.3 4.8-15.8M6.5 59.5l20.3-12.8c3.2-2 7.3-2 10.4 0l20.3 12.8z" />
  </svg>
);
export default IconSproutAlt;
