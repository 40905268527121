import * as React from "react";
const IconCream = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M44.9 3.7c-1-1.1-2.4-1.7-3.8-1.7H22.9c-1.5 0-2.9.6-3.8 1.7-1 1.1-1.4 2.5-1.3 4l3.3 31.7c.3 2.6 2.5 4.6 5.1 4.6h11.6c2.6 0 4.9-2 5.1-4.6l3.3-31.7c.1-1.5-.3-2.9-1.3-4m-.7 3.8-3.3 31.7c-.2 1.6-1.5 2.8-3.1 2.8H26.2c-1.6 0-3-1.2-3.1-2.8L19.8 7.5c-.1-.9.2-1.8.8-2.5s1.4-1 2.3-1H41c.9 0 1.7.4 2.3 1 .7.7 1 1.6.9 2.5M40 50.8H23.7c-1.1 0-2 .9-2 2V60c0 1.1.9 2 2 2H40c1.1 0 2-.9 2-2v-7.2c0-1.1-.9-2-2-2M23.7 60v-7.2H40V60z" />
  </svg>
);
export default IconCream;
