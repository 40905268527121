import * as React from "react";
const IconShoppingMobile = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M40.9 57.6c-1.5 1.6-3.5 2.4-5.6 2.4H13.1c-4.3 0-7.7-3.5-7.7-7.7V11.7C5.4 7.5 8.8 4 13.1 4h22.2c2.6 0 5 1.3 6.4 3.4.3.4.9.6 1.4.2s.6-.9.3-1.4c-1.8-2.7-4.8-4.3-8-4.3H13.1c-5.4 0-9.7 4.4-9.7 9.7v40.5c0 5.4 4.4 9.7 9.7 9.7h22.2c2.7 0 5.2-1.1 7.1-3.1.4-.4.4-1 0-1.4-.5-.2-1.1-.1-1.5.3" />
    <path d="M60.4 29.4c-.2-.2-.5-.4-.8-.4h-4.5c0-.1-.1-.2-.2-.3l-6.4-6.4c.5-.7.7-1.6.7-2.5 0-2.6-2.1-4.6-4.6-4.6S40 17.3 40 19.8c0 .9.3 1.8.7 2.5l-6.4 6.4c-.1.1-.2.2-.2.3h-4.5c-.3 0-.6.1-.8.4-.2.2-.3.6-.2.9l4.6 17.4c.1.4.5.7 1 .7H55c.5 0 .9-.3 1-.7l4.6-17.4c.1-.3 0-.6-.2-.9M44.6 17.2c1.5 0 2.6 1.2 2.6 2.6s-1.2 2.6-2.6 2.6-2.6-1.2-2.6-2.6 1.1-2.6 2.6-2.6m-2.5 6.5c.7.5 1.6.7 2.5.7s1.8-.3 2.5-.7l5.3 5.3H36.7zm12.2 22.7H34.9L30.8 31h27.5zM24.9 11.2c.6 0 1-.4 1-1s-.4-1-1-1h-1.5c-.6 0-1 .4-1 1s.4 1 1 1zM21 53.8c0 .6.4 1 1 1h4.4c.6 0 1-.4 1-1s-.4-1-1-1H22c-.5 0-1 .5-1 1" />
  </svg>
);
export default IconShoppingMobile;
