import * as React from "react";
const IconDesk = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 2H7C4.2 2 2 4.2 2 7v54c0 .6.4 1 1 1s1-.4 1-1V20.7h26.5V61c0 .6.4 1 1 1s1-.4 1-1v-8.8H60V61c0 .6.4 1 1 1s1-.4 1-1V7c0-2.8-2.2-5-5-5M4 7c0-1.7 1.3-3 3-3h50c1.7 0 3 1.3 3 3v11.7H4zm56 13.7v13.7H32.5V20.7zM32.5 50.2V36.4H60v13.8z" />
    <path d="M34.1 11.3c0-.6-.4-1-1-1h-2.3c-.6 0-1 .4-1 1s.4 1 1 1h2.3c.6 0 1-.5 1-1M44.6 29h2.3c.6 0 1-.4 1-1s-.4-1-1-1h-2.3c-.6 0-1 .4-1 1s.5 1 1 1M46.9 42.7h-2.3c-.6 0-1 .4-1 1s.4 1 1 1h2.3c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconDesk;
