import * as React from "react";
const IconNewspaper = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 7.7c-.6 0-1 .4-1 1v42.1c0 4.7-3.8 8.4-8.4 8.4H11.3C7.3 59.3 4 56 4 52V8.4c0-2.1 1.7-3.7 3.7-3.7H46c2.1 0 3.7 1.7 3.7 3.7v40.4c0 .6.4 1 1 1s1-.4 1-1V8.4c0-3.2-2.6-5.7-5.7-5.7H7.7C4.6 2.7 2 5.3 2 8.4V52c0 5.1 4.2 9.3 9.3 9.3h40.3c5.8 0 10.4-4.7 10.4-10.4V8.7c0-.5-.4-1-1-1" />
    <path d="M42.9 12.1H12.1c-.6 0-1 .4-1 1s.4 1 1 1h30.8c.6 0 1-.4 1-1s-.4-1-1-1M12.1 48.9h30.8c.6 0 1-.4 1-1s-.4-1-1-1H12.1c-.6 0-1 .4-1 1s.4 1 1 1M26 37.4c.6 0 1-.4 1-1V22c0-.6-.4-1-1-1H11.6c-.6 0-1 .4-1 1v14.4c0 .6.4 1 1 1zM12.6 23H25v12.4H12.6zM35.2 24.7h7c.6 0 1-.4 1-1s-.4-1-1-1h-7c-.6 0-1 .4-1 1s.5 1 1 1M35.2 35.2h7c.6 0 1-.4 1-1s-.4-1-1-1h-7c-.6 0-1 .4-1 1s.5 1 1 1" />
  </svg>
);
export default IconNewspaper;
