import * as React from "react";
const IconMountains4 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m58.4 53.4-7.3-15.9c2.5-3.8 3.8-8.1 3.8-12.6C54.9 12.3 44.6 2 32 2S9.1 12.3 9.1 24.9c0 4.5 1.3 8.9 3.8 12.6L5.6 53.4c-.9 1.9-.7 4 .4 5.8S9 62 11.1 62h41.8c2.1 0 4-1 5.1-2.8 1.1-1.7 1.3-3.9.4-5.8M11.1 24.9C11.1 13.4 20.5 4 32 4s20.9 9.4 20.9 20.9c0 3.7-1 7.3-2.8 10.5l-5.8-12.6c-1-2.2-3.1-3.5-5.5-3.5s-4.5 1.3-5.5 3.5l-5.8 12.5-1.3-2.9c-1-2.2-3.1-3.5-5.5-3.5s-4.5 1.3-5.5 3.5l-1.4 3c-1.7-3.2-2.7-6.8-2.7-10.5M7.7 58.1c-.7-1.2-.8-2.6-.3-3.9l9.7-21c.7-1.5 2.1-2.4 3.7-2.4s3 .9 3.7 2.4l2.2 4.8 7.5 16.2c.6 1.3.5 2.7-.3 3.9-.7 1.1-2 1.8-3.3 1.9H11.1c-1.4 0-2.7-.7-3.4-1.9m48.6 0c-.7 1.2-2 1.9-3.4 1.9h-18c.2-.2.4-.5.6-.8 1.1-1.7 1.3-3.9.4-5.8l-7.2-15.7 6.5-14.1c.7-1.5 2.1-2.4 3.7-2.4s3 .9 3.7 2.4l14.1 30.6c.5 1.3.4 2.8-.4 3.9" />
  </svg>
);
export default IconMountains4;
