import * as React from "react";
const IconGrape = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.6 24.1c0-4.5-3.7-8.2-8.2-8.2-3.1 0-5.8 1.7-7.2 4.3-1.2-2.3-3.5-3.9-6.2-4.2.3-6.1 3.4-11 7.2-11 .6 0 1-.4 1-1s-.4-1-1-1c-5 0-8.9 5.6-9.2 13-2.7.3-5 1.9-6.2 4.2-1.4-2.5-4.1-4.3-7.2-4.3-4.5 0-8.2 3.7-8.2 8.2s3.7 8.2 8.2 8.2c.8 0 1.5-.1 2.2-.3-2 1.5-3.2 3.9-3.2 6.5 0 4.5 3.7 8.2 8.2 8.2.8 0 1.5-.1 2.2-.3-2 1.5-3.2 3.9-3.2 6.5 0 4.5 3.7 8.2 8.2 8.2s8.2-3.7 8.2-8.2c0-2.7-1.3-5-3.2-6.5.7.2 1.5.3 2.2.3 4.5 0 8.2-3.7 8.2-8.2 0-2.7-1.3-5-3.2-6.5.7.2 1.5.3 2.2.3 4.5 0 8.2-3.7 8.2-8.2m-8.2-6.2c3.4 0 6.2 2.8 6.2 6.2s-2.8 6.2-6.2 6.2-6.2-2.8-6.2-6.2 2.8-6.2 6.2-6.2m-14.4 0c3.4 0 6.2 2.8 6.2 6.2s-2.8 6.2-6.2 6.2-6.2-2.8-6.2-6.2 2.8-6.2 6.2-6.2m2.2 14c-.9.7-1.7 1.6-2.2 2.6-.6-1-1.3-1.9-2.2-2.6.7.2 1.5.3 2.2.3s1.5-.1 2.2-.3m-22.8-7.8c0-3.4 2.8-6.2 6.2-6.2s6.2 2.8 6.2 6.2-2.8 6.2-6.2 6.2-6.2-2.8-6.2-6.2M24.8 28c.6 1 1.3 1.9 2.2 2.6-.7-.2-1.5-.3-2.2-.3-.8 0-1.5.1-2.2.3.9-.7 1.7-1.6 2.2-2.6m-6.2 10.4c0-3.4 2.8-6.2 6.2-6.2S31 35 31 38.4s-2.8 6.2-6.2 6.2-6.2-2.7-6.2-6.2m19.6 14.4c0 3.4-2.8 6.2-6.2 6.2s-6.2-2.8-6.2-6.2 2.8-6.2 6.2-6.2 6.2 2.8 6.2 6.2M32 44.6c-.8 0-1.5.1-2.2.3.9-.7 1.7-1.6 2.2-2.6.6 1 1.3 1.9 2.2 2.6-.7-.2-1.4-.3-2.2-.3m13.4-6.2c0 3.4-2.8 6.2-6.2 6.2S33 41.8 33 38.4s2.8-6.2 6.2-6.2c3.4.1 6.2 2.8 6.2 6.2m-6.2-8.1c-.8 0-1.5.1-2.2.3.9-.7 1.7-1.6 2.2-2.6.6 1 1.3 1.9 2.2 2.6-.7-.2-1.4-.3-2.2-.3" />
  </svg>
);
export default IconGrape;
