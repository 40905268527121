import * as React from "react";
const IconPencilAltCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.9 11.2c0-.5-.2-.9-.5-1.3l-3.7-3.7c-1.1-1.1-2.2-2.3-3.4-3.4-.3-.4-.7-.7-1.2-.7-.5-.1-1 .1-1.5.4l-7.4 7.4H8.7c-3.7 0-6.6 3-6.6 6.6v38.9C2.1 59 5 62 8.7 62h38.9c3.7 0 6.6-3 6.6-6.6V19.6l7.2-7.1c.4-.4.5-.9.5-1.3m-9.6 7.5L33.8 37.1c-.2.2-.4.3-.7.4l-10 3.3 3.3-10c.1-.3.2-.5.4-.7l18.4-18.4h.1v-.1l1-1 7 7zm0 36.7c0 2.6-2.1 4.6-4.6 4.6h-39c-2.6 0-4.6-2.1-4.6-4.6v-39c0-2.6 2.1-4.6 4.6-4.6h33.7l-17 16.9q-.6.6-.9 1.5l-3.8 11.6c-.2.5 0 .9.1 1 .2.3.6.5 1 .5h.2l11.8-3.9q.9-.3 1.5-.9l17.1-17v33.9zm2.5-39.2-7-7 5.1-5.1 3.4 3.4c1.2 1.2 2.3 2.4 3.6 3.6z" />
  </svg>
);
export default IconPencilAltCopy;
