import * as React from "react";
const IconWhiteboardAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 6.9H33V4c0-.6-.4-1-1-1s-1 .4-1 1v2.9H7c-2.8 0-5 2.2-5 5v20.5c0 2.8 2.2 5 5 5h24.5v9.9c-.1 0-.1 0-.2.1L13.7 59.2c-.5.3-.6.9-.3 1.4.2.3.5.4.8.4.2 0 .4-.1.6-.2l16.6-11.2V58c0 .6.4 1 1 1s1-.4 1-1v-8.3L49 60.8c.2.1.4.2.6.2.3 0 .6-.1.8-.4.3-.5.2-1.1-.2-1.4L33.7 47.4c-.1 0-.1-.1-.2-.1v-9.9H57c2.8 0 5-2.2 5-5V11.9c0-2.7-2.2-5-5-5m3 25.5c0 1.7-1.3 3-3 3H7c-1.7 0-3-1.3-3-3V11.9c0-1.7 1.3-3 3-3h50c1.7 0 3 1.3 3 3z" />
  </svg>
);
export default IconWhiteboardAlt1;
