import * as React from "react";
const IconDesktopComputerAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 10.3h-7.4c-.6 0-1 .4-1 1s.4 1 1 1H57c1.7 0 3 1.4 3 3v27.1H4V15.3c0-1.7 1.4-3 3-3h7.3c.6 0 1-.4 1-1s-.4-1-1-1H7c-2.8 0-5 2.3-5 5v33c0 2.8 2.3 5 5 5h17.6c-.6 1.5-1.8 3.8-3.4 5.1-.4.4-.6 1-.4 1.5s.7.9 1.3.9h19.4c.6 0 1.1-.3 1.3-.9.2-.5 0-1.1-.4-1.5-1.6-1.3-2.8-3.6-3.4-5.1h18c2.8 0 5-2.3 5-5v-33c0-2.8-2.3-5-5-5M39.9 58.8H23.8c1.6-1.9 2.6-4.3 3.1-5.5h10c.3 1.2 1.4 3.6 3 5.5M57 51.3H7c-1.7 0-3-1.4-3-3v-3.8h56v3.8c0 1.7-1.4 3-3 3" />
    <path d="M21.5 34.5c.2 0 .3 0 .5-.1.4-.2 5.5-3.2 10.3-7.5 2.5 2.3 5.7 4.7 9.6 7.5.5.3 1.1.2 1.4-.2.3-.5.2-1.1-.2-1.4q-5.7-4.05-9.3-7.2c3.7-3.7 6.8-8.3 6.8-13.1v-.2c0-1.1.1-4.4-2.2-6.7C36.9 4 34.7 3.2 32 3.2c-.4 0-3.5.1-5.9 2.4-1.6 1.6-2.4 3.9-2.3 6.8.1 4.4 2.1 8.4 7 13.1-4.6 4.2-9.7 7.1-9.8 7.2-.5.3-.6.9-.4 1.4.3.3.6.4.9.4m4.4-22.1c-.1-2.3.5-4.1 1.7-5.3 1.8-1.8 4.3-1.8 4.4-1.8 2.2 0 3.9.6 4.9 1.7 1.7 1.7 1.6 4.3 1.6 5.3v.2c0 4.2-2.9 8.3-6.3 11.7-4.4-4.4-6.2-7.9-6.3-11.8" />
  </svg>
);
export default IconDesktopComputerAlt;
