import * as React from "react";
const IconVectorNode = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.4 2h-7c-2.2 0-4 1.8-4 4v7c0 .7.2 1.4.6 2L16.6 47.6c-.6-.4-1.3-.6-2-.6h-7c-2.2 0-4 1.8-4 4v7c0 2.2 1.8 4 4 4h7c2.2 0 4-1.8 4-4v-7c0-.7-.2-1.4-.6-2l29.4-32.7c.6.4 1.3.6 2 .6h7c2.2 0 4-1.8 4-4V6c0-2.2-1.8-4-4-4M16.6 58c0 1.1-.9 2-2 2h-7c-1.1 0-2-.9-2-2v-7c0-1.1.9-2 2-2h7c1.1 0 2 .9 2 2zm41.8-45c0 1.1-.9 2-2 2h-7c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2h7c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconVectorNode;
