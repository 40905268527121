import * as React from "react";
const IconChestOfDrawers7 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.6 2H13.4c-2.8 0-5 2.2-5 5v50c0 2.8 2.2 5 5 5h37.2c2.8 0 5-2.2 5-5V7c0-2.8-2.2-5-5-5m3 5v13H33V4h17.6c1.7 0 3 1.3 3 3M13.4 4H31v16H10.4V7c0-1.7 1.3-3 3-3m-3 53V22H31v11.2h-1.9c-.6 0-1 .4-1 1s.4 1 1 1H31V60H13.4c-1.7 0-3-1.3-3-3m40.2 3H33V35.2h1.9c.6 0 1-.4 1-1s-.4-1-1-1H33V22h20.6v35c0 1.7-1.3 3-3 3" />
    <path d="M42.2 13.3h2.6c.6 0 1-.4 1-1s-.4-1-1-1h-2.6c-.6 0-1 .4-1 1s.5 1 1 1M20 13.3h2.6c.6 0 1-.4 1-1s-.4-1-1-1H20c-.6 0-1 .4-1 1s.4 1 1 1" />
  </svg>
);
export default IconChestOfDrawers7;
