import * as React from "react";
const IconBricksAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59 36.3H46.2v-7.6c0-1.7-1.3-3-3-3H25.1v-7.6c0-1.7-1.3-3-3-3H5c-1.7 0-3 1.3-3 3v7.6c0 .4.1.7.3 1-.2.3-.3.7-.3 1v8.6c0 .4.1.7.3 1-.2.3-.3.6-.3 1v7.5c0 1.7 1.3 3 3 3h18.1c.4 0 .7-.1 1-.3.3.2.6.3 1 .3h19.1c.4 0 .7-.1 1-.3.3.2.6.3 1 .3H59c1.7 0 3-1.3 3-3v-6.5c0-1.7-1.3-3-3-3m-15.8-8.6c.6 0 1 .4 1 1v7.6H25.1v-8.6zM4 27.7h19.1v8.6H4zm0-9.5c0-.6.4-1 1-1h17.1c.6 0 1 .4 1 1v7.6H4zm1 28.6c-.6 0-1-.4-1-1v-7.5h19.1v8.5zm20.1 0v-8.5h19.1v8.5zm34.9-1c0 .6-.4 1-1 1H46.2v-8.5H59c.6 0 1 .4 1 1z" />
  </svg>
);
export default IconBricksAlt;
