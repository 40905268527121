import * as React from "react";
const IconVrChat = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.8 33H43.4c-2.9 0-5.2 2.3-5.2 5.2v4.9c0 2.9 2.3 5.2 5.2 5.2h.8v4.8c0 .4.2.8.6.9.1.1.3.1.4.1.2 0 .5-.1.7-.3l6-5.6h4.9c2.9 0 5.2-2.3 5.2-5.2v-4.9c0-2.7-2.3-5.1-5.2-5.1M60 43.2c0 1.8-1.5 3.2-3.2 3.2h-5.3c-.3 0-.5.1-.7.3L46.1 51v-3.5c0-.6-.4-1-1-1h-1.8c-1.8 0-3.2-1.5-3.2-3.2v-4.9c0-1.8 1.5-3.2 3.2-3.2h13.4c1.8 0 3.2 1.5 3.2 3.2v4.8z" />
    <path d="M8.9 21.4h31.6c2.7 0 4.9 2.2 4.9 4.9 0 .6.4 1 1 1s1-.4 1-1c0-3.8-3.1-6.9-6.9-6.9h-3.2v-2.8c0-3.8-3.1-6.9-6.9-6.9H19c-3.8 0-6.9 3.1-6.9 6.9v2.8H8.9c-3.8 0-6.9 3.1-6.9 6.9v12.5c0 3.8 3.1 6.9 6.9 6.9h9.6c.3 0 .5-.1.7-.3l5.4-5.4 5.4 5.4c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-6-6c-.4-.4-1-.4-1.4 0l-5.9 5.8H8.9c-2.7 0-4.9-2.2-4.9-4.9v-6.8h6.4c.6 0 1-.4 1-1s-.4-1-1-1H4v-3.8c0-2.7 2.2-4.9 4.9-4.9M19 11.7h11.4c2.7 0 4.9 2.2 4.9 4.9v2.8H14.1v-2.8c0-2.7 2.2-4.9 4.9-4.9" />
  </svg>
);
export default IconVrChat;
