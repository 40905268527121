import * as React from "react";
const IconEuroCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M49.8 60c-12.1 0-22.7-8.1-26.5-19.1h17.3c.6 0 1-.4 1-1s-.4-1-1-1H22.7q-.9-3.3-.9-6.9c0-1.4.1-2.9.3-4.3h18.4c.6 0 1-.4 1-1s-.4-1-1-1h-18C25.5 13.5 36.8 4 49.8 4c.6 0 1-.4 1-1s-.4-1-1-1C35.7 2 23.5 12.4 20.5 25.7h-6.3c-.6 0-1 .4-1 1s.4 1 1 1h5.9c-.2 1.4-.3 2.8-.3 4.3 0 2.4.3 4.7.8 6.9h-6.4c-.6 0-1 .4-1 1s.4 1 1 1h7C25.1 53 36.6 62 49.8 62c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconEuroCopy;
