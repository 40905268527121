import * as React from "react";
const IconTriangle5 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M39.8 17.5H24.2c-1.5 0-2.8.7-3.6 1.9-.9 1.2-1.1 2.7-.6 4.1l7.8 22.4c.6 1.8 2.3 3 4.2 3s3.6-1.2 4.2-3L44 23.4c.5-1.4.3-2.9-.6-4.1s-2.2-1.8-3.6-1.8m2.3 5.3-7.8 22.4c-.4 1.2-1.5 1.7-2.3 1.7s-1.9-.4-2.3-1.7l-7.8-22.4c-.3-.8-.1-1.6.3-2.3.5-.7 1.2-1 2-1h15.5c.8 0 1.5.4 2 1 .5.7.7 1.5.4 2.3" />
    <path d="M32 3C16 3 3 16 3 32s13 29 29 29 29-13 29-29S48 3 32 3m0 56C17.1 59 5 46.9 5 32S17.1 5 32 5s27 12.1 27 27-12.1 27-27 27" />
  </svg>
);
export default IconTriangle5;
