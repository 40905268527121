import * as React from "react";
const IconNotepadCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.3 4c-1.3-1.3-2.9-2-4.7-2H20.3C17.9 2 16 3.9 16 6.3v6.3h-4.3c-.6 0-1 .4-1 1s.4 1 1 1H16V31h-4.3c-.6 0-1 .4-1 1s.4 1 1 1H16v16.5h-4.3c-.6 0-1 .4-1 1s.4 1 1 1H16v6.3c0 2.4 1.9 4.3 4.3 4.3h26.3c3.7 0 6.7-3 6.7-6.7V8.8c0-1.9-.7-3.6-2-4.8m-.1 51.3c0 2.6-2.1 4.7-4.7 4.7H20.3C19 60 18 59 18 57.7v-6.3h6.9c.6 0 1-.4 1-1s-.4-1-1-1H18V33h6.9c.6 0 1-.4 1-1s-.4-1-1-1H18V14.5h6.9c.6 0 1-.4 1-1s-.4-1-1-1H18V6.3C18 5 19 4 20.3 4h26.3c1.3 0 2.4.5 3.3 1.4s1.4 2.1 1.4 3.3z" />
  </svg>
);
export default IconNotepadCopy;
