import * as React from "react";
const IconSofa1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.7 27.2c-1 0-1.9.1-2.8.4l1.2-8.2c.7-4.4-.5-8.7-3.3-12C45.8 4 41.3 2 36.5 2h-8.9c-4.8 0-9.2 2-12.2 5.4-2.8 3.3-4 7.6-3.3 12l1.3 8.4c-1.1-.4-2.2-.7-3.4-.7-4.2 0-6.5 4.2-6.5 7 0 2.9 1.8 5.7 4.4 6.8.1.1.3.1.4.2l.1.1c.9.4 1.1.5 1.2.7v4.8c0 5.6 2.6 9.3 6.9 9.9h.9c0 .1-.1.2-.1.4v4c0 .6.4 1 1 1s1-.4 1-1v-3.9c0-.1 0-.3-.1-.4h25.7c0 .1-.1.2-.1.3v4c0 .6.4 1 1 1s1-.4 1-1v-3.9c0-.1 0-.2-.1-.3h1.1c4.3-.5 6.9-4.3 6.9-10v-4.9c.1-.1.3-.3 1.2-.6h.1c.2-.1.3-.1.4-.2 2.4-1.1 4.2-3.9 4.2-6.8 0-3-2.7-7.1-6.9-7.1M16.9 8.7c2.6-3 6.5-4.7 10.7-4.7h8.9c4.2 0 8.1 1.7 10.7 4.7 2.5 2.9 3.5 6.5 2.9 10.4l-1.5 10c-.8 1.6-1.2 4.7-1.5 6.5-3-.9-8.1-2-14.8-2-5.3 0-10.3.7-14.7 2-.4-1.6-1.2-4.6-2-6.1v-.1L14 19c-.5-3.7.5-7.4 2.9-10.3m38.6 30.5c-.1 0-.2.1-.3.1l-.1.1c-1.1.4-2.4 1-2.4 2.5v4.8c0 4.6-1.8 7.5-5.1 8h-31c-3.2-.5-5.1-3.5-5.1-7.9V42c0-1.5-1.4-2.1-2.5-2.5l-.2-.1c-.1 0-.2-.1-.3-.1-1.8-.8-3.2-3-3.2-5 0-1.8 1.5-5 4.5-5 1.4 0 2.7.4 3.8 1.1.7 1.3 1.8 5.4 2.1 6.9.1.3.2.5.5.6.2.1.5.2.8.1 4.4-1.5 9.7-2.3 15.2-2.3 7.4 0 12.8 1.4 15.4 2.3h.3c.2 0 .4-.1.5-.2.3-.2.4-.4.5-.7.2-2.3.8-5.8 1.4-7 1-.5 2.1-.8 3.3-.8 3.1 0 4.9 3.3 4.9 5 .1 2-1.2 4.2-3 4.9" />
  </svg>
);
export default IconSofa1;
