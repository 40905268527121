import * as React from "react";
const IconLocationWedding = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M31.9 21.3c-1.2-.7-2.6-1.1-4-1-2 0-3.9.7-5.4 2.1-1.6 1.4-2.4 3.4-2.5 5.6-.1 2.3.9 4.6 2.5 6.2l7.4 7.4c.5.5 1.3.8 2 .8s1.4-.3 2-.8l7.6-7.6c1.5-1.5 2.3-3.5 2.3-5.6s-.8-4.1-2.3-5.6C39 20 35 19.5 31.9 21.3m8.2 11.1L32.5 40c-.3.3-.8.3-1.1 0L24 32.7c-1.2-1.2-1.9-3-1.9-4.7 0-1.6.7-3.1 1.8-4.1s2.5-1.6 4-1.6c1.2 0 2.4.4 3.4 1.1.3.2.8.2 1.1 0 2.4-1.6 5.6-1.4 7.6.7 1.1 1.1 1.8 2.6 1.8 4.2.1 1.5-.5 3-1.7 4.1" />
    <path d="M32 2C17.6 2 5.9 13.7 5.9 28.1c0 17.8 24.5 33.1 25.6 33.7.1.2.3.2.5.2s.4-.1.5-.2c1-.7 25.6-16.2 25.6-33.7C58.1 13.7 46.4 2 32 2m0 57.8C28.4 57.5 7.9 43.4 7.9 28.1 7.9 14.8 18.7 4 32 4s24.1 10.8 24.1 24.1c0 15.1-20.5 29.3-24.1 31.7" />
  </svg>
);
export default IconLocationWedding;
