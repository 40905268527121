import * as React from "react";
const IconUserStar = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M33.2 56.2c-3.1.7-6.2 1.1-9.2 1.1-8.7 0-16.9-3.5-19.9-5 .2-6.6 2.3-12.5 5.9-16.8 3.7-4.4 8.7-6.8 14-6.8 5.4 0 10.3 2.4 14 6.8.2.2.4.5.5.7.3.5.9.6 1.4.3s.6-.9.3-1.4c-.2-.3-.4-.6-.7-.9-3.8-4.5-8.9-7.1-14.4-7.4 5.8-.6 10.4-5.5 10.4-11.4 0-6.3-5.2-11.5-11.5-11.5S12.5 9.1 12.5 15.4c0 6 4.6 10.9 10.4 11.4-5.5.3-10.6 2.9-14.4 7.4C4.4 39.1 2.1 45.7 2 53q0 .6.6.9c2.6 1.3 11.7 5.4 21.4 5.4 3.1 0 6.3-.4 9.6-1.1.5-.1.9-.7.8-1.2-.2-.5-.7-.9-1.2-.8M14.5 15.4c0-5.2 4.3-9.5 9.5-9.5s9.5 4.3 9.5 9.5-4.3 9.5-9.5 9.5-9.5-4.2-9.5-9.5" />
    <path d="M61.9 43.6c-.4-1.2-1.5-2-2.8-2h-5.5c-.4 0-.7-.2-.8-.6l-1.7-5.2c-.4-1.2-1.5-2-2.8-2s-2.4.8-2.8 2L43.9 41c-.1.4-.5.6-.8.6h-5.5c-1.3 0-2.4.8-2.8 2s0 2.5 1.1 3.2l4.4 3.2c.3.2.4.6.3 1L39 56.3c-.4 1.2 0 2.5 1.1 3.2.5.4 1.1.6 1.7.6s1.2-.2 1.7-.6l4.4-3.2c.3-.2.7-.2 1.1 0l4.4 3.2c1 .8 2.4.8 3.4 0s1.4-2 1.1-3.2l-1.7-5.2c-.1-.4 0-.8.3-1l4.4-3.2c.9-.8 1.4-2.1 1-3.3m-2.3 1.7-4.4 3.2c-1 .7-1.4 2-1.1 3.2l1.7 5.2c.2.6-.2.9-.3 1s-.6.4-1 0l-4.4-3.2c-1-.7-2.4-.7-3.4 0l-4.4 3.2c-.5.4-.9.1-1 0s-.5-.4-.3-1l1.7-5.2c.4-1.2 0-2.5-1.1-3.2l-4.4-3.2c-.5-.4-.4-.9-.3-1 0-.1.2-.6.9-.6h5.5c1.3 0 2.4-.8 2.8-2l1.7-5.2c.2-.6.7-.6.8-.6s.7 0 .8.6l1.7 5.2c.4 1.2 1.5 2 2.8 2h5.5c.6 0 .8.5.9.6-.3.1-.2.6-.7 1" />
  </svg>
);
export default IconUserStar;
