import * as React from "react";
const IconArrowsBreakV = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m21.5 14.1 9.5-8v19h-9.8c-.6 0-1 .4-1 1s.4 1 1 1h21.6c.6 0 1-.4 1-1s-.4-1-1-1H33v-19l9.5 8c.2.2.4.2.6.2.3 0 .6-.1.8-.4.4-.4.3-1.1-.1-1.4L32.6 3.2c-.4-.3-.9-.3-1.3 0l-11.2 9.4c-.4.4-.5 1-.1 1.4s1.1.5 1.5.1M42.5 49.9l-9.5 8v-19h9.8c.6 0 1-.4 1-1s-.4-1-1-1H21.2c-.6 0-1 .4-1 1s.4 1 1 1H31v19l-9.5-8c-.4-.4-1.1-.3-1.4.1s-.3 1.1.1 1.4l11.2 9.4c.2.2.4.2.6.2s.5-.1.6-.2l11.2-9.4c.4-.4.5-1 .1-1.4-.3-.4-1-.5-1.4-.1" />
  </svg>
);
export default IconArrowsBreakV;
