import * as React from "react";
const IconLovePotion = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M38.8 17.9V4h1.8c.6 0 1-.4 1-1s-.4-1-1-1h-2.8c-.6 0-1 .4-1 1v15.9c0 .1 0 .1.1.2 0 .1.1.1.1.2s.1.1.1.1.1.1.2.1.1.1.2.1h.1c8.9 2.5 15.1 10.6 15.1 19.8C52.6 50.8 43.4 60 32 60s-20.6-9.2-20.6-20.6c0-9.2 6.2-17.4 15.1-19.8h.1c.1 0 .1-.1.2-.1s.1-.1.2-.1l.1-.1c0-.1.1-.1.1-.2s0-.1.1-.2V3c0-.6-.4-1-1-1h-2.7c-.6 0-1 .4-1 1s.4 1 1 1h1.7v13.9C15.9 20.8 9.4 29.5 9.4 39.4 9.4 51.9 19.5 62 32 62s22.6-10.1 22.6-22.6c0-9.9-6.5-18.6-15.8-21.5" />
    <path d="M23.8 36.4c0 1.6.6 3.2 1.7 4.4l4.9 4.9c.4.4 1 .6 1.6.6s1.1-.2 1.6-.6l5-5c1.1-1.1 1.6-2.5 1.6-4s-.6-2.9-1.6-4c-1.8-1.8-4.4-2.1-6.6-1-.8-.4-1.7-.6-2.6-.6-1.4 0-2.7.5-3.8 1.5-1.1.8-1.8 2.2-1.8 3.8m3.1-2.5q1.05-.9 2.4-.9c.7 0 1.4.2 2 .6.3.2.8.2 1.1 0 1.4-1 3.4-.8 4.6.4.7.7 1.1 1.6 1.1 2.6s-.4 1.9-1.1 2.6l-5 5q-.15.15-.3 0L27 39.3c-.8-.8-1.2-1.8-1.2-2.9 0-1 .4-1.9 1.1-2.5" />
  </svg>
);
export default IconLovePotion;
