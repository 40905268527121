import * as React from "react";
const IconPsd = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.7 12.2 39.3 3.7c-.7-.4-1.5-.7-2.3-.7H15.7c-2.9 0-5.2 2.3-5.2 5.2v47.5c0 2.9 2.3 5.2 5.2 5.2h32.7c2.9 0 5.2-2.3 5.2-5.2V15.6c0-1.3-.7-2.6-1.9-3.4m-.1 43.6c0 1.8-1.4 3.2-3.2 3.2H15.7c-1.8 0-3.2-1.4-3.2-3.2V8.2c0-1.8 1.4-3.2 3.2-3.2H37c.4 0 .9.1 1.2.4l12.4 8.5c.6.4.9 1.1.9 1.8v40.1z" />
    <path d="M29.1 23.9h-6c-.6 0-1 .4-1 1v22c0 .6.4 1 1 1s1-.4 1-1v-12h5c2.3 0 4.1-1.8 4.1-4.1V28c0-2.3-1.9-4.1-4.1-4.1m2.1 6.9c0 1.2-.9 2.1-2.1 2.1h-5v-7h5c1.2 0 2.1.9 2.1 2.1zM40 35.4h2.7c.6 0 1-.4 1-1s-.4-1-1-1H40c-2.1 0-3.7 1.7-3.7 3.7v.9c0 2.1 1.7 3.7 3.7 3.7 1 0 1.7.8 1.7 1.7v.9c0 1-.8 1.7-1.7 1.7h-2.7c-.6 0-1 .4-1 1s.4 1 1 1H40c2.1 0 3.7-1.7 3.7-3.7v-.9c0-2.1-1.7-3.7-3.7-3.7-1 0-1.7-.8-1.7-1.7v-.9c0-1 .8-1.7 1.7-1.7" />
  </svg>
);
export default IconPsd;
