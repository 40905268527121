import * as React from "react";
const IconTreadmill = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m50.2 37.7 8.6-20.5c.1-.1.1-.3.1-.4v-9c0-1.4-.6-2.8-1.6-3.8S55 2.4 53.5 2.4h-3.4c-.6 0-1 .4-1 1s.4 1 1 1h3.4c.9 0 1.7.3 2.3 1 .6.6 1 1.5 1 2.3v8.8l-8.9 21h-34C7.3 37.7 2 43 2 49.6c0 6.5 5.3 11.9 11.9 11.9h36.3c6.5 0 11.9-5.3 11.9-11.9-.1-6.6-5.4-11.9-11.9-11.9M4 49.6c0-5.4 4.4-9.9 9.9-9.9 5.4 0 9.9 4.4 9.9 9.9s-4.4 9.9-9.9 9.9C8.4 59.4 4 55 4 49.6m16.4 9.8c3.2-2.1 5.3-5.8 5.3-9.9s-2.1-7.7-5.3-9.9h23.1c-3.2 2.1-5.3 5.8-5.3 9.9s2.1 7.7 5.3 9.9zm29.7 0c-5.4 0-9.9-4.4-9.9-9.9s4.4-9.9 9.9-9.9S60 44 60 49.5s-4.4 9.9-9.9 9.9" />
  </svg>
);
export default IconTreadmill;
