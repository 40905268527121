import * as React from "react";
const IconLoveAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.9 46.4c-1.2-3.7-4.8-5.8-8.3-4.8-4.6 1.4-11 3.3-14.7 4.3 1.4-2.1 1.3-3.6.9-4.6-.8-1.9-3-2.8-4.8-2.8H23.5c-1.2 0-2.4-.5-3.5-1.6-.9-.8-2.1-1.3-3.3-1.3H8.3c-3 0-5.5 2.6-5.5 5.7v13.3c0 3.2 2.5 5.9 5.6 5.9h10.1c1.3 0 2.5-.5 3.5-1.3l.7.4c2.8 1.5 5.9 2.3 9.1 2.3 1.9 0 3.8-.3 5.6-.8l18.8-5.7c4.8-1.8 5.6-5.8 4.7-9M8.4 58.6c-2 0-3.6-1.7-3.6-3.9V41.4c0-2.1 1.6-3.7 3.5-3.7h8.4c.7 0 1.4.3 2 .8.5.4 1 .8 1.5 1.1H20v18.7c-.5.2-1 .4-1.5.4H8.4zm47.2-5.1-18.8 5.7c-1.6.5-3.3.8-5 .8-2.8 0-5.6-.7-8.1-2.1l-1.4-.7-.3-.2V40.3c.5.1 1 .2 1.5.2h10.4c1.2 0 2.6.5 3 1.5.5 1.2-.5 3-2.6 4.9l-.6.5.4.7c.4.8.4.8 10.1-2.1 4.5-1.3 8.9-2.6 8.9-2.6 2.4-.7 5 .8 5.9 3.4.4 1.6.8 5.1-3.4 6.7M43 27.7c.6.6 1.5.9 2.3.9s1.7-.3 2.3-.9l9.3-9.3c1.8-1.8 2.8-4.2 2.8-6.8 0-2.5-1-5-2.8-6.8-3.1-3.1-7.9-3.7-11.6-1.5-1.5-.9-3.2-1.3-4.9-1.3-2.4 0-4.6.9-6.4 2.5-1.9 1.7-2.9 4.1-3 6.7-.1 2.8 1 5.5 3 7.5zM35.3 6c1.4-1.3 3.2-2 5-2h.1c1.5 0 3 .5 4.2 1.3.3.2.8.2 1.1 0 3-2.1 7-1.7 9.6.9 1.4 1.4 2.2 3.3 2.2 5.4 0 2-.8 3.9-2.2 5.3L46 26.2c-.5.5-1.3.5-1.7 0l-9-9c-1.6-1.6-2.4-3.8-2.4-6 .1-2 1-3.9 2.4-5.2" />
  </svg>
);
export default IconLoveAlt;
