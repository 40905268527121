import React, { useEffect, useState } from 'react';
import {
	BringzzPageContainer,
	BringzzIcon,
	BringzzText,
	BringzzInput,
	BringzzButton,
	BringzzCheckbox,
	BringzzBottomDrawer,
	BringzzRadioList,
	BringzzRadio,
	BringzzImage,
	BringzzOrderCard
} from '@bringzz/components';
import { useNavigation } from 'context/NavigationContext';
import { useLocation } from 'react-router-dom';
import useUser from 'hooks/useUser';
import PostExample1 from '../../../../assets/images/PostExample1.png';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

let state = {
	checked: <div></div>,
	unChecked: <div></div>
};

const ShopperDeliveryInvoice = () => {
	let query = useQuery();
	const { backPress } = useNavigation();
	const { getUser } = useUser();
	let id = query.get('id');
	const [delivery, setDelivery] = useState(null); // State to manage the photo
	const { navigate } = useNavigation();

	useEffect(() => {
		if (id) {
			getUser().deliveries.forEach(deliverie => {
				if (deliverie.uuid == id) {
					setDelivery(deliverie);
				}
			});
		}
	}, [id]);

	return (
		delivery && (
			<BringzzPageContainer
				header={{
					left: (
						<BringzzIcon
							size='22'
							icon='IconChevronLeft'
							folder='LineIcons'
							className='cursor-pointer'
							onClick={backPress}
						/>
					),
					right: (
						<BringzzIcon
							icon='IconUpload'
							folder='LineIcons'
							size='20'
							className='p-2'
						/>
					),

					title: <BringzzText tag='h2'>Invoice #{delivery.uuid}</BringzzText>
				}}
				footer={
					<div className='pb-28 px-6'>
						<BringzzButton
							icon={{ variant: 'IconUpload', folder: 'LineIcons', size: '20' }}
							className='bg-magic-lilac  w-full justify-center'
						>
							Share
						</BringzzButton>
					</div>
				}
			>
				<div className='p-6 space-y-4'>
					<BringzzImage
						src='/images/default-image.png'
						alt='Image 1'
						width={'100%'}
						height={'100%'}
					/>
				</div>
			</BringzzPageContainer>
		)
	);
};

export default ShopperDeliveryInvoice;
