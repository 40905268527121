import { useNavigation } from 'context/NavigationContext';
import { useImperativeHandle, forwardRef } from 'react';

const FLOW_TYPES = {
  SHOPPER_PERSCRIPTION: 'SHOPPER_PERSCRIPTION',
  SHOPPER: 'SHOPPER',
  SHOPPER_PAPER_RX: 'SHOPPER_PAPER_RX',
  SELF_COLLECTION: 'SELF_COLLECTION',
  SHIPPING_NON_RX: 'SHIPPING_NON_RX'
};

const PERSPECTIVES = {
  PHARMACY: 'PHARMACY',
  CUSTOMER: 'CUSTOMER',
  SHOPPER: 'SHOPPER'
};

const flowConfigs = {
  [FLOW_TYPES.SHOPPER]: {
    name: 'Order with Shopper',
    [PERSPECTIVES.CUSTOMER]: {
      screens: [
        { id: 'basket', path: '/basket' },
        { id: 'matching_pharmacy', path: '/matchmaking/pickup/1' },
        { id: 'pharmacy_preparing', path: '/matchmaking/pickup/2' },
        { id: 'shopper_matching', path: '/matchmaking/pickup/3' },
        { id: 'shopper_pickup', path: '/matchmaking/pickup/4' },
        { id: 'scan_qr', path: '/customer/orders/qr' },
        { id: 'delivery', path: '/orders/view' },
        { id: 'completed', path: '/orders/completed' }
      ]
    },
    [PERSPECTIVES.PHARMACY]: {
      screens: [
        { id: 'order_details', path: '/pharmacy/orders/details' },
        { id: 'preparing', path: '/pharmacy/orders/preparing' },
        { id: 'qr', path: '/pharmacy/orders/qr' },
        { id: 'verify', path: '/pharmacy/orders/verify' },
        { id: 'handover', path: '/pharmacy/orders/handover' },
        { id: 'completed', path: '/pharmacy/orders/completed' }
      ]
    },
    [PERSPECTIVES.SHOPPER]: {
      screens: [
        { id: 'order_details', path: '/shopper/orders/details' },
        { id: 'accepted', path: '/shopper/orders/accepted' },
        { id: 'scan_qr', path: '/shopper/orders/scan' },
        { id: 'pickup', path: '/shopper/orders/pickup' },
        { id: 'delivery', path: '/shopper/orders/delivery' },
        { id: 'completed', path: '/shopper/orders/completed' }
      ]
    }
  },
  [FLOW_TYPES.SHOPPER_PERSCRIPTION]: {
    name: 'Order with Shopper perscription',
    [PERSPECTIVES.CUSTOMER]: {
      screens: [
        { id: 'basket', path: '/basket' },
        { id: 'matching_pharmacy', path: '/matchmaking/pickup/1' },
        { id: 'pharmacy_preparing', path: '/matchmaking/pickup/2' },
        { id: 'shopper_matching', path: '/matchmaking/pickup/3' },
        { id: 'shopper_pickup', path: '/matchmaking/pickup/4' },
        { id: 'scan_qr', path: '/customer/orders/qr' },
        { id: 'delivery', path: '/orders/view' },
        { id: 'completed', path: '/orders/completed' }
      ]
    },
    [PERSPECTIVES.PHARMACY]: {
      screens: [
        { id: 'order_details', path: '/pharmacy/orders/details' },
        { id: 'preparing', path: '/pharmacy/orders/preparing' },
        { id: 'qr', path: '/pharmacy/orders/qr' },
        { id: 'verify', path: '/pharmacy/orders/verify' },
        { id: 'handover', path: '/pharmacy/orders/handover' },
        { id: 'completed', path: '/pharmacy/orders/completed' }
      ]
    },
    [PERSPECTIVES.SHOPPER]: {
      screens: [
        { id: 'order_details', path: '/shopper/orders/details' },
        { id: 'accepted', path: '/shopper/orders/accepted' },
        { id: 'scan_qr', path: '/shopper/orders/scan' },
        { id: 'pickup', path: '/shopper/orders/pickup' },
        { id: 'delivery', path: '/shopper/orders/delivery' },
        { id: 'completed', path: '/shopper/orders/completed' }
      ]
    }
  },
  [FLOW_TYPES.SELF_COLLECTION]: {
    name: 'Order with Shopper (Paper Prescription)',
    [PERSPECTIVES.CUSTOMER]: {
      screens: [
        { id: 'upload', path: '/upload' },
        { id: 'matching', path: '/matchmaking' },
        { id: 'processing', path: '/orders/place' },
        { id: 'delivery', path: '/orders/view' }
      ]
    },
    [PERSPECTIVES.PHARMACY]: {
      screens: [
        { id: 'orders', path: '/pharmacy/orders' },
        { id: 'processing', path: '/pharmacy/orders/place' },
        { id: 'view', path: '/pharmacy/orders/view' }
      ]
    },
  },
  [FLOW_TYPES.SHOPPER_PAPER_RX]: {
    name: 'Order with Shopper (NON RX)',
    [PERSPECTIVES.CUSTOMER]: {
      screens: [
        { id: 'upload', path: '/upload' },
        { id: 'matching', path: '/matchmaking' },
        { id: 'processing', path: '/orders/place' },
        { id: 'delivery', path: '/orders/view' }
      ]
    },
    [PERSPECTIVES.PHARMACY]: {
      screens: [
        { id: 'orders', path: '/pharmacy/orders' },
        { id: 'processing', path: '/pharmacy/orders/place' },
        { id: 'view', path: '/pharmacy/orders/view' }
      ]
    },
  }
};

const BringzzOrderFlow = forwardRef(({ flowType, perspective, currentScreen, onScreenChange }, ref) => {
  const { navigate } = useNavigation();
  const flow = flowConfigs[flowType][perspective];
  
  const goToNextScreen = () => {
    const currentIndex = flow.screens.findIndex(screen => screen.id === currentScreen);
    if (currentIndex < flow.screens.length - 1) {
      const nextScreen = flow.screens[currentIndex + 1];
      onScreenChange(nextScreen.id);
      navigate(nextScreen.path);
    }
  };

  const goToSpecificScreen = (screenId) => {
    const screen = flow.screens.find(screen => screen.id === screenId);
    if (screen) {
      onScreenChange(screenId);
      navigate(screen.path);
    }
  };

  const goToPreviousScreen = () => {
    const currentIndex = flow.screens.findIndex(screen => screen.id === currentScreen);
    if (currentIndex > 0) {
      const previousScreen = flow.screens[currentIndex - 1];
      onScreenChange(previousScreen.id);
      navigate(previousScreen.path);
    }
  };

  useImperativeHandle(ref, () => ({
    goToNextScreen,
    goToPreviousScreen,
    goToSpecificScreen
  }));

  return null;
});

export default BringzzOrderFlow; 