import * as React from "react";
const IconHouseAuction1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.6 12.4h-8.5c-.2 0-.3 0-.4.1l-4.5 2.2c-.3.2-.6.5-.6.9v1.3H29.5V6.5c0-2.2-1.8-4-4-4h-8.9c-2.2 0-4 1.8-4 4v22.7c0 2.2 1.8 4 4 4h8.9c2.2 0 4-1.8 4-4V18.8h13.2v1.3q0 .6.6.9l4.5 2.2c.1.1.3.1.4.1h8.5c3 0 5.4-2.4 5.4-5.4v-.1c-.1-3-2.5-5.4-5.5-5.4M27.5 29.2c0 1.1-.9 2-2 2h-8.9c-1.1 0-2-.9-2-2V6.5c0-1.1.9-2 2-2h8.9c1.1 0 2 .9 2 2zM60 17.9c0 1.9-1.5 3.4-3.4 3.4h-8.2l-3.7-1.8v-3.3l3.7-1.8h8.2c1.9 0 3.4 1.5 3.4 3.4zM36.8 50.4h-.5V46c0-2.8-2.2-5-5-5H12.5c-2.8 0-5 2.2-5 5v4.4H7c-2.8 0-5 2.2-5 5v5.1c0 .6.4 1 1 1h37.8c.6 0 1-.4 1-1v-5.1c0-2.7-2.2-5-5-5M9.5 46c0-1.7 1.3-3 3-3h18.8c1.7 0 3 1.3 3 3v4.4H9.5zm30.3 13.5H4v-4.1c0-1.7 1.3-3 3-3h29.8c1.7 0 3 1.3 3 3z" />
  </svg>
);
export default IconHouseAuction1;
