import * as React from "react";
const IconDrumstick = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.2 7.6C51.7 4 47 2 42.1 2c-5.3 0-10.4 2.3-14.5 6.4-7.2 7.4-7 29.6-6.9 33.1l-10.6 11q-.3 0-.6.3l-6.4.1c-.6 0-1 .5-1 1s.5 1 1 1l6.1-.1V61c0 .6.4 1 1 1s1-.4 1-1v-6.5q.3-.3.3-.6l10.6-11h1.8c6.6 0 25.4-.6 31.9-7 9.6-9.4 6.6-20.9-.6-28.3m-.6 26.8c-6.4 6.3-27.1 6.5-31.8 6.4-.1-4.6.1-24.7 6.3-31.1 3.6-3.7 8.3-5.8 13-5.8 4.4 0 8.4 1.7 11.7 5 6.4 6.8 9.2 17.2.8 25.5" />
  </svg>
);
export default IconDrumstick;
