import * as React from "react";
const IconTruckWarning = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.8 37.7-6.4-9.3c-.2-.3-.5-.4-.8-.4H43.9v-2.8c0-2.7-2.2-4.8-4.8-4.8h-7.2c-.6 0-1 .4-1 1s.4 1 1 1h7.2c1.6 0 2.8 1.3 2.8 2.8v26.7H31.7c-.3-2.4-2.3-4.4-4.8-4.4s-4.5 1.9-4.8 4.4h-6.9c-1.6 0-2.8-1.3-2.8-2.8V36.7c0-.6-.4-1-1-1s-1 .4-1 1V49c0 2.7 2.2 4.8 4.8 4.8h7.1c.6 1.9 2.4 3.3 4.6 3.3s3.9-1.4 4.6-3.3h15.2c.6 1.9 2.4 3.3 4.6 3.3s3.9-1.4 4.6-3.3H61c.6 0 1-.4 1-1V38.2c0-.2-.1-.4-.2-.5M54.1 30l5.5 7.9H43.9V30zM27 55.2c-1.6 0-2.8-1.3-2.8-2.8s1.3-2.8 2.8-2.8c1.6 0 2.8 1.3 2.8 2.8s-1.3 2.8-2.8 2.8m24.3 0c-1.6 0-2.8-1.3-2.8-2.8s1.3-2.8 2.8-2.8 2.8 1.3 2.8 2.8-1.2 2.8-2.8 2.8m4.8-3.4c-.3-2.4-2.3-4.4-4.8-4.4s-4.5 1.9-4.8 4.4h-2.6V39.9H60v11.9z" />
    <path d="M26.9 28.6c.9-1.5.9-3.3.1-4.8L19 9.3c-.9-1.6-2.5-2.5-4.3-2.5s-3.4.9-4.3 2.5L2.6 23.8c-.8 1.5-.8 3.3.1 4.8S5.1 31 6.9 31h15.8c1.7 0 3.3-.9 4.2-2.4m-22.5-1c-.5-.9-.5-1.9 0-2.8l7.9-14.5c.5-.9 1.4-1.5 2.5-1.5s2 .6 2.5 1.5l7.9 14.5c.5.9.5 1.9 0 2.8S23.8 29 22.7 29H6.9c-1.1 0-2-.5-2.5-1.4" />
    <path d="M14.8 15.7c-.6 0-1 .4-1 1v1.8c0 .6.4 1 1 1s1-.4 1-1v-1.8c0-.6-.5-1-1-1M14.8 22.9c-.6 0-1 .5-1 1s.4 1 1 1 1-.4 1-1v-.1c0-.5-.5-.9-1-.9" />
  </svg>
);
export default IconTruckWarning;
