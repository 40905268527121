import * as React from "react";
const IconBox7 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M29.8 57H9.4c-2.3 0-4.2-1.9-4.2-4.2V20.4c0-.3.1-.7.1-1h50.4c.1.3.1.6.1 1v10c0 .6.4 1 1 1s1-.4 1-1v-10c0-1.5-.6-3-1.6-4.1L45.2 4c-1-1.3-2.7-2-4.4-2H20.3c-1.8 0-3.4.7-4.6 2.1l-11 12.3c-1 1.1-1.6 2.6-1.6 4.1V53c.1 3.3 2.9 6 6.3 6h20.4c.6 0 1-.4 1-1s-.5-1-1-1M43.9 5.4l10.8 12.1H31.6V4h9.2c1.2 0 2.3.5 3.1 1.4m-26.7 0c.8-.9 2-1.4 3.1-1.4h9.2v13.4h-23z" />
    <path d="M59.8 48.7h-3.2v-4.6c0-3.6-2.9-6.5-6.5-6.5s-6.5 2.9-6.5 6.5v4.6h-3.2c-.6 0-1 .4-1 1V61c0 .6.4 1 1 1h19.3c.6 0 1-.4 1-1V49.7c.1-.5-.4-1-.9-1m-14.2-4.6c0-2.5 2-4.5 4.5-4.5s4.5 2 4.5 4.5v4.6h-9zM58.8 60H41.5v-9.3h17.3z" />
  </svg>
);
export default IconBox7;
