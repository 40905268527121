import * as React from "react";
const IconForrestAlt3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m59.2 53.1-5.4.4v-8.6c4.7-.7 8.2-6.5 8.2-13.8C62 24 58.2 16 52.8 16c-4.3 0-7.5 4.9-8.7 10.5-1.5-.8-3.2-1.3-4.9-1.3-5.5 0-9.9 4.4-9.9 9.9 0 5.1 3.9 9.3 8.9 9.8v9.6q-3.9-.3-7.5-1.8l-2.9-1.3c-2.5-1.1-4.7-2.6-6.6-4.6-3.2-3.3-7.2-5.5-11.7-6.5L3 38.9c-.5-.1-1.1.2-1.2.8-.1.5.2 1.1.8 1.2l6.5 1.4c4.1.9 7.8 3 10.7 5.9 2.1 2.1 4.5 3.8 7.2 5l2.9 1.3c3 1.3 6.3 2 9.6 2 .6 0 1.2 0 1.8-.1l18-1.4c.6 0 1-.5.9-1.1s-.5-.8-1-.8M52.8 18c3.7 0 7.2 6.4 7.2 13.1 0 5.9-2.8 10.9-6.2 11.7v-4.4c0-.6-.4-1-1-1s-1 .4-1 1v4.4c-3.5-.8-6.2-5.8-6.2-11.7 0-6.7 3.5-13.1 7.2-13.1M31.3 35.1c0-4.3 3.5-7.9 7.9-7.9 1.6 0 3.2.5 4.5 1.4-.1.8-.2 1.7-.2 2.5 0 3.4.8 6.4 2 8.8-.1.1-.2.1-.3.2-1.3 1.6-3.1 2.6-5.1 2.8v-4.1c0-.6-.4-1-1-1s-1 .4-1 1V43c-3.7-.5-6.8-3.8-6.8-7.9m9.9 19.4h-.9V45c2.5-.3 4.8-1.5 6.5-3.4 1.4 1.8 3.1 3 5.1 3.3v8.8zM11.3 23.5c4.4 0 8-3.6 8-8s-3.6-8-8-8-8 3.6-8 8c-.1 4.3 3.5 8 8 8m0-14.1c3.3 0 6 2.7 6 6s-2.7 6-6 6-6-2.7-6-6 2.6-6 6-6" />
  </svg>
);
export default IconForrestAlt3;
