import * as React from "react";
const IconPoundCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.7 60H18.1c5-2.8 8.3-8.1 8.3-14.2V35.4h28c.6 0 1-.4 1-1s-.4-1-1-1h-28V20.5C26.4 11.4 33.7 4 42.8 4 47 4 51 5.6 54 8.6c.4.4 1 .4 1.4 0s.4-1 0-1.4C52 3.8 47.5 2 42.8 2c-10.1 0-18.3 8.3-18.3 18.5v12.9H6.9c-.6 0-1 .4-1 1s.4 1 1 1h17.5v10.4c0 7.8-6.3 14.2-14.1 14.2h-6c-.6 0-1 .4-1 1s.4 1 1 1h55.5c.6 0 1-.4 1-1s-.5-1-1.1-1" />
  </svg>
);
export default IconPoundCopy;
