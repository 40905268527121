import * as React from "react";
const IconArrowLongDownC = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M43.8 48.2c-.4-.4-1-.4-1.4 0L33 57.9v-43c2.9-.5 5.2-3 5.2-6.1 0-3.4-2.8-6.2-6.2-6.2s-6.2 2.8-6.2 6.2c0 3.1 2.2 5.6 5.2 6.1v43l-9.4-9.7c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4L31.3 61c.2.2.4.3.7.3s.5-.1.7-.3l11.1-11.4c.4-.4.4-1 0-1.4m-16-39.4c0-2.3 1.9-4.2 4.2-4.2s4.2 1.9 4.2 4.2S34.3 13 32 13s-4.2-1.9-4.2-4.2" />
  </svg>
);
export default IconArrowLongDownC;
