import * as React from "react";
const IconSearchAltCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.6 57.6 43.9 42.4c7.2-9.2 6.5-22.5-1.9-30.9-4.4-4.4-10.3-6.9-16.6-6.9S13.3 7 8.8 11.5c-9.1 9.1-9.1 24 0 33.1 4.4 4.4 10.3 6.9 16.6 6.9s12.1-2.4 16.6-6.9c.2-.2.4-.4.6-.7l17.8 15.2c.2.2.4.2.6.2.3 0 .6-.1.8-.4.3-.3.3-.9-.2-1.3M40.5 43.2c-4 4-9.4 6.3-15.1 6.3s-11.1-2.2-15.1-6.3C2 34.8 2 21.3 10.3 12.9c4-4 9.4-6.3 15.1-6.3s11.1 2.2 15.1 6.3c8.4 8.3 8.4 21.9 0 30.3" />
  </svg>
);
export default IconSearchAltCopy;
