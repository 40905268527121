import * as React from "react";
const IconSpeechBubble6 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.3 25.4 52 18V7.1C52 4.3 49.8 2 46.9 2H10.5C7.7 2 5.4 4.3 5.4 7.1v17c0 2.8 2.3 5.1 5.1 5.1h45.9c.9 0 1.8-.5 2.1-1.4.5-.7.4-1.7-.2-2.4M56.8 27c0 .1-.1.2-.3.2h-46c-1.7 0-3.1-1.4-3.1-3.1v-17C7.4 5.4 8.8 4 10.5 4h36.4C48.7 4 50 5.4 50 7.1v11.2c0 .2.1.5.2.6l6.4 7.7c.3.2.2.4.2.4M53.5 34.7H17c-2.8 0-5.1 2.3-5.1 5.1v10.8L5.7 58c-.6.7-.7 1.7-.3 2.5.4 1 1.2 1.5 2.2 1.5h45.9c2.8 0 5.1-2.3 5.1-5.1v-17c0-2.9-2.3-5.2-5.1-5.2m3.1 22.2c0 1.7-1.4 3.1-3.1 3.1H7.6c-.2 0-.3-.1-.3-.2s-.1-.2.1-.4l6.4-7.7c.2-.2.2-.4.2-.6V39.8c0-1.7 1.4-3.1 3.1-3.1h36.4c1.7 0 3.1 1.4 3.1 3.1z" />
    <path d="M38 10.8H19.2c-.6 0-1 .4-1 1s.4 1 1 1H38c.6 0 1-.4 1-1s-.5-1-1-1M38 19.5H19.2c-.6 0-1 .4-1 1s.4 1 1 1H38c.6 0 1-.4 1-1s-.5-1-1-1M43.4 43.7H24.7c-.6 0-1 .4-1 1s.4 1 1 1h18.7c.6 0 1-.4 1-1s-.4-1-1-1M43.4 52.4H24.7c-.6 0-1 .4-1 1s.4 1 1 1h18.7c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconSpeechBubble6;
