import * as React from "react";
const IconAddFile = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.2 52.4h-5.6v-5.6c0-.6-.4-1-1-1s-1 .4-1 1v5.6H44c-.6 0-1 .4-1 1s.4 1 1 1h5.6V60c0 .6.4 1 1 1s1-.4 1-1v-5.6h5.6c.6 0 1-.4 1-1s-.5-1-1-1" />
    <path d="M38.7 59H10.8c-1.7 0-3-1.3-3-3V8c0-1.7 1.3-3 3-3h23.8v14c0 .6.4 1 1 1h13.9v20c0 .6.4 1 1 1s1-.4 1-1V19.6c0-.8-.3-1.6-.9-2.1L36.9 3.9c-.5-.6-1.3-.9-2.1-.9h-24c-2.8 0-5 2.2-5 5v48c0 2.8 2.2 5 5 5h27.9c.6 0 1-.4 1-1s-.4-1-1-1m9.7-41H36.6V6.4z" />
  </svg>
);
export default IconAddFile;
