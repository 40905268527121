import * as React from "react";
const IconCactusAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M31.1 14c-.6 0-1 .4-1 1v12.8c0 .6.4 1 1 1s1-.4 1-1V15c0-.5-.5-1-1-1" />
    <path d="M42.4 49.2c9.2 0 16.7-7.5 16.7-16.7 0-3.2-2.6-5.8-5.8-5.8s-5.8 2.6-5.8 5.8v.5c0 1.6-1.3 2.9-2.9 2.9H42V11.1c0-5-4-9.1-9.1-9.1h-3.7c-5 0-9.1 4.1-9.1 9.1v11.6h-4.7c-.4 0-.7-.3-.7-.7 0-2.7-2.2-4.9-4.9-4.9S4.9 19.3 4.9 22c0 6.9 5.6 12.5 12.5 12.5h2.7V60h-6.3c-.6 0-1 .4-1 1s.4 1 1 1h34.5c.6 0 1-.4 1-1s-.4-1-1-1H42V49.2zM44.5 38c2.7 0 4.9-2.2 4.9-4.9v-.5c0-2.1 1.7-3.8 3.8-3.8s3.8 1.7 3.8 3.8c0 8.1-6.6 14.7-14.7 14.7H42V38zm-27.1-5.4c-5.8 0-10.5-4.7-10.5-10.5 0-1.6 1.3-2.9 2.9-2.9s2.9 1.3 2.9 2.9c0 1.5 1.2 2.7 2.7 2.7h4.7v7.8zM22.1 60V11.1c0-3.9 3.2-7.1 7.1-7.1h3.7c3.9 0 7.1 3.2 7.1 7.1V60z" />
    <path d="M31.1 42.3c-.6 0-1 .4-1 1v7.9c0 .6.4 1 1 1s1-.4 1-1v-7.9c0-.5-.5-1-1-1" />
  </svg>
);
export default IconCactusAlt;
