import * as React from "react";
const IconCondominium = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 18.9h-6.6v-8.6c0-2.6-2.1-4.8-4.8-4.8H17.4c-2.6 0-4.8 2.1-4.8 4.8v8.6H6c-2.2 0-4 1.8-4 4v31.6c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V22.9c0-2.3-1.8-4-4-4M4 54.5V22.9c0-1.1.9-2 2-2h6.6v35.6H6c-1.1 0-2-.9-2-2m10.6-34.6v-9.6c0-1.5 1.2-2.8 2.8-2.8h29.2c1.5 0 2.8 1.2 2.8 2.8v46.2h-9.7V44c0-.6-.4-1-1-1H25.3c-.6 0-1 .4-1 1v12.5h-9.7zm11.7 36.6V45h11.5v11.5zm33.7-2c0 1.1-.9 2-2 2h-6.6V20.9H58c1.1 0 2 .9 2 2z" />
    <path d="M20.7 22.5h7.5c.6 0 1-.4 1-1V14c0-.6-.4-1-1-1h-7.5c-.6 0-1 .4-1 1v7.5c0 .5.4 1 1 1m1-7.5h5.5v5.5h-5.5zM35.8 22.5h7.5c.6 0 1-.4 1-1V14c0-.6-.4-1-1-1h-7.5c-.6 0-1 .4-1 1v7.5c0 .5.5 1 1 1m1-7.5h5.5v5.5h-5.5zM28.2 36.4c.6 0 1-.4 1-1v-7.5c0-.6-.4-1-1-1h-7.5c-.6 0-1 .4-1 1v7.5c0 .6.4 1 1 1zm-6.5-7.5h5.5v5.5h-5.5zM35.8 36.4h7.5c.6 0 1-.4 1-1v-7.5c0-.6-.4-1-1-1h-7.5c-.6 0-1 .4-1 1v7.5c0 .5.5 1 1 1m1-7.5h5.5v5.5h-5.5z" />
  </svg>
);
export default IconCondominium;
