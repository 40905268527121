import * as React from "react";
const IconTriangle6 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M35.1 18c-.6-1.8-2.3-3-4.2-3s-3.6 1.2-4.2 3l-7.8 22.4c-.5 1.4-.3 2.9.6 4.1s2.2 1.9 3.6 1.9h15.5c1.5 0 2.8-.7 3.6-1.9s1.1-2.7.6-4.1zm5.5 25.3c-.5.7-1.2 1-2 1H23.1c-.8 0-1.5-.4-2-1-.5-.7-.6-1.5-.3-2.3l7.8-22.4c.4-1.2 1.5-1.7 2.3-1.7s1.9.4 2.3 1.7L40.9 41c.3.8.2 1.6-.3 2.3" />
    <path d="M32 3C16 3 3 16 3 32s13 29 29 29 29-13 29-29S48 3 32 3m0 56C17.1 59 5 46.9 5 32S17.1 5 32 5s27 12.1 27 27-12.1 27-27 27" />
  </svg>
);
export default IconTriangle6;
