import * as React from "react";
const IconUserIdentification = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M47.8 29.2c-3.7 0-7.1 1.5-9.7 3.8-3.7-3.6-8.3-5.6-13.3-5.8 6-.4 10.7-5.4 10.7-11.5 0-6.3-5.2-11.5-11.5-11.5S12.5 9.4 12.5 15.8c0 6 4.6 10.9 10.5 11.5-5.6.3-10.7 2.9-14.5 7.5-4 4.8-6.3 11.4-6.4 18.7q0 .6.6.9c2.6 1.3 11.7 5.5 21.5 5.5 3.1 0 6.3-.4 9.5-1.1.1.5.5.9 1 .9.6 0 1-.4 1-1V43.5c0-6.7 5.5-12.2 12.2-12.2S60 36.7 60 43.5v15.1c0 .6.4 1 1 1s1-.4 1-1V43.5c0-7.9-6.4-14.3-14.2-14.3M14.5 15.8c0-5.2 4.3-9.5 9.5-9.5s9.5 4.3 9.5 9.5-4.3 9.5-9.5 9.5-9.5-4.3-9.5-9.5m19 27.7v13.2h-.3c-3.1.7-6.2 1.1-9.2 1.1-8.7 0-16.9-3.5-20-5 .2-6.6 2.3-12.5 6-16.8 3.7-4.4 8.7-6.8 14.1-6.8 4.7 0 9.2 1.9 12.7 5.3-2.1 2.5-3.3 5.6-3.3 9" />
    <path d="M47.8 38c-3.3 0-6 2.7-6 6v14.5c0 .6.4 1 1 1s1-.4 1-1V44c0-2.2 1.8-4 4-4s4 1.8 4 4v1.5c0 .6.4 1 1 1s1-.4 1-1V44c0-3.3-2.7-6-6-6M52.8 52.7c-.6 0-1 .4-1 1v4.9c0 .6.4 1 1 1s1-.4 1-1v-4.9c0-.6-.4-1-1-1" />
  </svg>
);
export default IconUserIdentification;
