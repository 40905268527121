import * as React from "react";
const IconLayersAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 35.9H45V24.2c0-2.9-2.3-5.2-5.2-5.2H28.1V7.2c0-2.9-2.3-5.2-5.2-5.2H7.2C4.3 2 2 4.3 2 7.2v15.6C2 25.7 4.3 28 7.2 28H19v11.7c0 2.9 2.3 5.2 5.2 5.2h11.7v13c0 2.2 1.8 4 4 4H58c2.2 0 4-1.8 4-4v-18c0-2.2-1.8-4-4-4M7.2 26.1c-1.8 0-3.2-1.4-3.2-3.2V7.2C4 5.4 5.4 4 7.2 4h15.6C24.6 4 26 5.4 26 7.2V19h-1.9c-2.9 0-5.2 2.3-5.2 5.2v1.9zm17 16.9c-1.8 0-3.2-1.4-3.2-3.2V24.2c0-1.8 1.4-3.2 3.2-3.2h15.6c1.8 0 3.2 1.4 3.2 3.2v11.7h-3.1c-2.2 0-4 1.8-4 4V43zM60 58c0 1.1-.9 2-2 2H39.9c-1.1 0-2-.9-2-2V39.9c0-1.1.9-2 2-2H58c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconLayersAlt;
