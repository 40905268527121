import * as React from "react";
const IconRulerAltCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.3 17.1 46.9 2.7C45 .8 41.8.8 39.8 2.7L2.7 39.9C.8 41.8.8 45 2.7 47l14.4 14.4c1 1 2.3 1.5 3.5 1.5s2.6-.5 3.5-1.5l37.1-37.1c2-2.1 2-5.3.1-7.2m-1.4 5.6L22.7 59.9c-1.2 1.2-3.1 1.2-4.2 0L4.1 45.5c-1.2-1.2-1.2-3.1 0-4.2L8 37.4 18.6 48c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4L9.5 35.9l5.8-5.8 6.7 6.7c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-6.7-6.7 5.8-5.8 10.6 10.6c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4L23.9 21.5l5.8-5.8 6.7 6.7c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-6.7-6.7 5.8-5.8L47.5 19c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4L38.3 7l2.9-2.9c1.2-1.2 3.1-1.2 4.2 0l14.4 14.4c1.3 1.2 1.3 3.1.1 4.2" />
  </svg>
);
export default IconRulerAltCopy;
