import * as React from "react";
const IconCloudServer1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M19.8 10.7c-.6 0-1 .4-1 1v.1c0 .6.4 1 1 1s1-.4 1-1v-.1c0-.6-.4-1-1-1M40.9 12.7h5c.6 0 1-.4 1-1s-.4-1-1-1h-5c-.6 0-1 .4-1 1s.5 1 1 1" />
    <path d="M46 44.2c5 0 9-4 9-8.8 0-4.9-4-8.8-9-8.8-1.1 0-2.1.2-3.1.5.1-.5.1-1 .1-1.5 0-1.8-.5-3.5-1.3-5h7.7c2.8 0 5-2.2 5-5V8c0-2.8-2.2-5-5-5H14.5c-2.8 0-5 2.2-5 5v7.7c0 2.8 2.2 5 5 5h7.7c-.8 1.5-1.3 3.2-1.3 5 0 .5 0 1 .1 1.5-1-.4-2-.5-3.1-.5-5 0-9 4-9 8.8 0 4.9 4 8.8 9 8.8h13V50c-2.3.4-4.1 2.2-4.5 4.5H14.1c-.6 0-1 .4-1 1s.4 1 1 1h12.4C27 59 29.3 61 32 61s5-2 5.5-4.6h12.4c.6 0 1-.4 1-1s-.4-1-1-1H37.5c-.4-2.3-2.2-4.1-4.5-4.5v-5.7zM35.6 55.4c0 2-1.6 3.6-3.6 3.6s-3.6-1.6-3.6-3.6 1.6-3.6 3.6-3.6 3.6 1.6 3.6 3.6M14.5 18.7c-1.7 0-3-1.3-3-3V8c0-1.7 1.3-3 3-3h34.9c1.7 0 3 1.3 3 3v7.7c0 1.7-1.3 3-3 3h-9.1c-2-2.3-5-3.8-8.4-3.8-3.3 0-6.3 1.5-8.4 3.8zM11 35.4c0-3.8 3.1-6.8 7-6.8 1.5 0 2.9.4 4.1 1.3.4.3.8.2 1.2 0 .3-.3.5-.7.3-1.1q-.6-1.5-.6-3c0-4.8 4-8.8 9-8.8s9 3.9 9 8.8c0 1-.2 2.1-.6 3-.2.4 0 .9.3 1.1.3.3.8.3 1.2 0 1.2-.8 2.6-1.3 4.1-1.3 3.9 0 7 3.1 7 6.8s-3.1 6.8-7 6.8H18c-3.9 0-7-3-7-6.8" />
  </svg>
);
export default IconCloudServer1;
