import * as React from "react";
const IconNecklaceAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62 7.5c0-.6-.4-1-1-1s-1 .4-1 1c0 15.4-12.6 28-28 28S4 22.9 4 7.5c0-.6-.4-1-1-1s-1 .4-1 1c0 15.8 12.3 28.7 27.7 29.9-2.5 2.4-7.1 7.1-7.1 10.8 0 5.1 4.2 9.3 9.3 9.3s9.3-4.2 9.3-9.3c0-3.8-4.5-8.4-7.1-10.8C49.7 36.2 62 23.3 62 7.5M39.3 48.2c0 4-3.3 7.3-7.3 7.3s-7.3-3.3-7.3-7.3c0-3.1 4.8-7.9 7.3-10.1 2.5 2.2 7.3 7 7.3 10.1" />
  </svg>
);
export default IconNecklaceAlt;
