import * as React from "react";
const IconMx = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 513 342"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M342 0H0v341.3h513V0z" />
    <path  d="M0 0h171v342H0z" />
    <path  d="M171 0h171v342H171z" />
    <path
      
      d="M195.8 171.2c0 21.6 11.5 41.7 30.3 52.5 5.8 3.4 13.2 1.4 16.6-4.4s1.4-13.2-4.4-16.6c-11.3-6.5-18.2-18.5-18.2-31.5 0-6.7-5.4-12.1-12.1-12.1s-12.2 5.4-12.2 12.1m93.4 51.1c17.5-11.1 28-30.4 28-51.1 0-6.7-5.4-12.1-12.1-12.1s-12.1 5.4-12.1 12.1c0 12.4-6.3 24-16.8 30.7-5.7 3.5-7.5 10.9-4.1 16.7s10.9 7.5 16.7 4.1c0-.2.2-.3.4-.4"
    />
    <ellipse cx={256.5} cy={159.1}  rx={24.3} ry={36.4} />
  </svg>
);
export default IconMx;
