import * as React from "react";
const IconSupplement = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 3.9H3c-.6 0-1 .4-1 1v14.7c0 .6.4 1 1 1h2.7V53c0 3.9 3.1 7 7 7h38.6c3.9 0 7-3.1 7-7V20.6H61c.6 0 1-.4 1-1V4.9c0-.5-.4-1-1-1m-4.7 49.2c0 2.8-2.2 5-5 5H12.7c-2.8 0-5-2.2-5-5V20.6h48.6zM60 18.6H4V5.9h56z" />
    <path d="M22.1 50.7h19.7c2.2 0 4-1.8 4-4V38c0-2.2-1.8-4-4-4H22.1c-2.2 0-4 1.8-4 4v8.7c0 2.2 1.8 4 4 4m-2-12.7c0-1.1.9-2 2-2h19.7c1.1 0 2 .9 2 2v8.7c0 1.1-.9 2-2 2H22.1c-1.1 0-2-.9-2-2z" />
  </svg>
);
export default IconSupplement;
