import React from 'react';
import { useNavigation } from '../../context/NavigationContext';
import {
	BringzzPageContainer,
	BringzzIcon,
	BringzzText,
	BringzzAccordion,
	BringzzOrder,
	BringzzOrderCard,
	BringzzBoxSlider
} from "@bringzz/components";

const AccountPharmacyOrder1 = () => {
	const { backPress } = useNavigation();
	const now = new Date();
	return (
		<BringzzPageContainer
			header={{
				left: (
					<BringzzIcon
						size='22'
						icon='IconChevronLeft'
						folder='LineIcons'
						className='cursor-pointer'
						onClick={backPress}
					/>
				),
				title: <BringzzText tag='h2'>My Orders</BringzzText>
			}}
		>
			<div className='p-4'>
				<BringzzAccordion
					separated={false}
					title='Accepted'
					defaultValue={true}
				>
					<BringzzBoxSlider>
						<BringzzOrder
							mode={1}
							orderDetails={{
								name: 'Nedhir B.',
								date: now,
								status: 'ready'
							}}
						></BringzzOrder>
						<BringzzOrder
							mode={1}
							orderDetails={{
								name: 'Nedhir B.',
								date: now,
								status: 'ready'
							}}
						></BringzzOrder>
						<BringzzOrder
							mode={1}
							orderDetails={{
								name: 'Nedhir B.',
								date: now,
								status: 'ready'
							}}
						></BringzzOrder>
						<BringzzOrder
							mode={1}
							orderDetails={{
								name: 'Nedhir B.',
								date: now,
								status: 'ready'
							}}
						></BringzzOrder>
						<BringzzOrder
							mode={1}
							orderDetails={{
								name: 'Nedhir B.',
								date: now,
								status: 'ready'
							}}
						></BringzzOrder>
						<BringzzOrder
							mode={1}
							orderDetails={{
								name: 'Nedhir B.',
								date: now,
								status: 'ready'
							}}
						></BringzzOrder>
						<BringzzOrder
							mode={1}
							orderDetails={{
								name: 'Nedhir B.',
								date: now,
								status: 'ready'
							}}
						></BringzzOrder>
						<BringzzOrder
							mode={1}
							orderDetails={{
								name: 'Nedhir B.',
								date: now,
								status: 'ready'
							}}
						></BringzzOrder>
						<BringzzOrder
							mode={1}
							orderDetails={{
								name: 'Nedhir B.',
								date: now,
								status: 'ready'
							}}
						></BringzzOrder>
					</BringzzBoxSlider>
				</BringzzAccordion>
				<BringzzAccordion
					title='In progress'
					defaultValue={true}
					className='mt-4'
					separated={false}
				>
					<BringzzBoxSlider>
						<BringzzOrder
							mode={2}
							orderDetails={{
								name: 'Nedhir B.',
								date: now,
								status: 'inprogress'
							}}
						></BringzzOrder>
						<BringzzOrder
							mode={2}
							orderDetails={{
								name: 'Nedhir B.',
								date: now,
								status: 'inprogress'
							}}
						></BringzzOrder>
						<BringzzOrder
							mode={2}
							orderDetails={{
								name: 'Nedhir B.',
								date: now,
								status: 'inprogress'
							}}
						></BringzzOrder>
					</BringzzBoxSlider>
				</BringzzAccordion>
				<BringzzAccordion
					separated={false}
					title='Ready'
					defaultValue={true}
					className='mt-4'
				>
					<BringzzBoxSlider>
						<BringzzOrder
							mode={2}
							orderDetails={{
								name: 'Nedhir B.',
								date: now,
								status: 'ready'
							}}
						></BringzzOrder>
						<BringzzOrder
							mode={2}
							orderDetails={{
								name: 'Nedhir B.',
								date: now,
								status: 'ready'
							}}
						></BringzzOrder>
					</BringzzBoxSlider>
				</BringzzAccordion>
			</div>
			<div className='bg-sand'>
				<div className='p-4'>
					<BringzzAccordion
						separated={false}
						title='Recently Completed'
						defaultValue={true}
					>
						<div className='p-1 flex flex-col gap-y-4'>
							<BringzzOrderCard
								orderDetails={{
									name: 'Nedhir B.',
									date: now,
									status: 'completed',
									price: 12.22
								}}
							></BringzzOrderCard>
							<BringzzOrderCard
								orderDetails={{
									name: 'Nedhir B.',
									date: now,
									status: 'completed',
									price: 12.22
								}}
							></BringzzOrderCard>
						</div>
					</BringzzAccordion>
				</div>
			</div>
		</BringzzPageContainer>
	);
};

export default AccountPharmacyOrder1;
