import * as React from "react";
const IconWateringCan = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61 27.1-2.2-2.2c2-2.3 3-5.3 3-8.3 0-3.5-1.4-6.7-3.8-9.2-2.5-2.5-5.7-3.8-9.2-3.8-3.1 0-6 1.1-8.3 3l-2.7-2.7c-.9-.9-2.2-1.5-3.5-1.5s-2.6.5-3.5 1.5L7.5 27.1C6.6 28 6 29.3 6 30.6s.5 2.6 1.5 3.5l21.1 21.1-24.4-1.6.5-5.1c.1-.5-.3-1-.9-1.1-.5 0-1 .3-1.1.9L1.5 60.5c-.1.5.3 1 .9 1.1h.1c.5 0 .9-.4 1-.9l.5-5.1 26.8 1.8c1 1 2.2 1.4 3.5 1.4s2.6-.5 3.5-1.5L61 34.1c2-1.9 2-5.1 0-7M48.8 5.5c2.9 0 5.7 1.1 7.8 3.2s3.2 4.8 3.2 7.8c0 2.6-.9 5-2.5 6.9L41.9 7.9c2-1.6 4.4-2.4 6.9-2.4m10.8 27.2L36.4 55.9c-1.1 1.1-3.1 1.1-4.2 0L9 32.7q-.9-.9-.9-2.1c0-1.2.3-1.6.9-2.1L32.2 5.3q.9-.9 2.1-.9c1.2 0 1.6.3 2.1.9l23.2 23.2c1.2 1.1 1.2 3 0 4.2" />
  </svg>
);
export default IconWateringCan;
