import * as React from "react";
const IconTrailer = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.8 31-6-8.6c-.2-.3-.5-.4-.8-.4h-9.8v-2.6c0-2.5-2.1-4.6-4.6-4.6h-34c-2.5 0-4.6 2.1-4.6 4.6v22.2c0 2.5 2.1 4.6 4.6 4.6h4.2c.6 1.8 2.3 3.1 4.3 3.1s3.7-1.3 4.3-3.1h5.7c.6 1.8 2.3 3.1 4.3 3.1s3.7-1.3 4.3-3.1h14c.6 1.8 2.3 3.1 4.3 3.1s3.7-1.3 4.3-3.1H61c.6 0 1-.4 1-1V31.6c0-.3-.1-.4-.2-.6m-7.3-7.1 5 7.2H45.2v-7.2zM15.1 47.2c-1.4 0-2.6-1.2-2.6-2.6s1.2-2.6 2.6-2.6 2.6 1.2 2.6 2.6-1.2 2.6-2.6 2.6m14.3 0c-1.4 0-2.6-1.2-2.6-2.6S28 42 29.4 42s2.6 1.2 2.6 2.6-1.2 2.6-2.6 2.6m13.8-3.1h-9.3c-.3-2.3-2.2-4-4.5-4s-4.3 1.8-4.5 4h-5.3c-.3-2.3-2.2-4-4.5-4s-4.3 1.8-4.5 4h-4C5.2 44.1 4 43 4 41.5V19.4c0-1.4 1.2-2.6 2.6-2.6h34.1c1.4 0 2.6 1.2 2.6 2.6v24.7zm8.8 3.1c-1.4 0-2.6-1.2-2.6-2.6S50.6 42 52 42s2.6 1.2 2.6 2.6-1.2 2.6-2.6 2.6m4.5-3.1c-.3-2.3-2.2-4-4.5-4s-4.3 1.8-4.5 4h-2.3v-11H60v11z" />
    <path d="M15.1 24.2c-.6 0-1 .4-1 1v7.3c0 .6.4 1 1 1s1-.4 1-1v-7.3c0-.5-.5-1-1-1M23.5 24.2c-.6 0-1 .4-1 1v7.3c0 .6.4 1 1 1s1-.4 1-1v-7.3c0-.5-.4-1-1-1M31.9 24.2c-.6 0-1 .4-1 1v7.3c0 .6.4 1 1 1s1-.4 1-1v-7.3c0-.5-.4-1-1-1" />
  </svg>
);
export default IconTrailer;
