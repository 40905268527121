import * as React from "react";
const IconVr = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.6 18.9c2.5 0 4.4 2 4.4 4.4v17.4c0 2.5-2 4.4-4.4 4.4H42.8q-1.5 0-2.4-1.2l-5.8-7.2c-.7-.7-1.6-1.1-2.6-1.1s-1.9.4-2.6 1.2L23.6 44c-.6.7-1.5 1.2-2.4 1.2H9.4c-2.5 0-4.4-2-4.4-4.4V23.3c0-2.5 2-4.4 4.4-4.4zm0-2H9.4c-3.6 0-6.4 2.9-6.4 6.4v17.4c0 3.6 2.9 6.4 6.4 6.4h11.7c1.6 0 3-.7 4-1.9l5.8-7.2c.3-.3.6-.5 1-.5s.7.2 1 .5l5.8 7.2c1 1.2 2.4 1.9 4 1.9h11.7c3.6 0 6.4-2.9 6.4-6.4V23.3c.2-3.6-2.7-6.4-6.2-6.4" />
  </svg>
);
export default IconVr;
