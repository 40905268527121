import * as React from "react";
const IconNotificationAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.3 5.4c-4.8 0-9 3.3-10.3 7.8H6.8C4.2 13.2 2 15.3 2 18v35.8c0 2.6 2.2 4.8 4.8 4.8h37.5c2.6 0 4.8-2.2 4.8-4.8V27c.7.1 1.4.2 2.1.2 5.9 0 10.7-4.9 10.7-10.9.1-6-4.7-10.9-10.6-10.9m-4.2 48.4c0 1.5-1.3 2.8-2.8 2.8H6.8c-1.5 0-2.8-1.3-2.8-2.8V18c0-1.5 1.3-2.8 2.8-2.8h33.8c0 .4-.1.8-.1 1.1 0 4.5 2.7 8.4 6.6 10.1zm4.2-28.6c-4.8 0-8.7-4-8.7-8.9s3.9-8.9 8.7-8.9 8.7 4 8.7 8.9-3.9 8.9-8.7 8.9" />
    <path d="M11.9 30.7H32c.6 0 1-.4 1-1s-.4-1-1-1H11.9c-.6 0-1 .4-1 1s.4 1 1 1M36.9 40.7h-25c-.6 0-1 .4-1 1s.4 1 1 1h25c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconNotificationAlt;
