import * as React from "react";
const IconStationaryBike = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M31 33.5c-.3-.5-.9-.6-1.4-.2-.5.3-.6.9-.2 1.4L37 45.5c.2.3.5.4.8.4.2 0 .4-.1.6-.2.5-.3.6-.9.2-1.4l-2.9-4.1 11.5-3.5c.5-.2.8-.7.7-1.2-.2-.5-.7-.8-1.2-.7l-12.2 3.7z" />
    <path d="m61 5.4-12-.1c-2.1 0-3.8 1.5-4.1 3.5l-2.3 14.7c-1.9 0-3.1.5-3.2.5s-13.5 3.7-20.2 5.4c-2.4.6-4.1 1-5.3 1.4L9.5 14.4h15.4c.6 0 1-.4 1-1s-.4-1-1-1H9L7.6 6.9c-.2-.6-.7-.9-1.2-.7-.6.1-.9.6-.8 1.2l6.2 24c-.8.3-1.1.4-1.2.5-7 4.2-5.1 11.1-5.1 11.2 1.8 6.1 6.3 7.4 8.8 7.7v5.9H3c-.6 0-1 .4-1 1s.4 1 1 1h50.8c.6 0 1-.4 1-1s-.4-1-1-1H43.4v-5.9c1 0 2-.2 2.9-.5 5.3-1.6 8.7-5.2 9.8-10 1-4.7-.5-9.9-3.9-12.9-2.7-2.4-5.5-3.4-7.7-3.7l2.3-14.5c.2-1.1 1.1-1.8 2.1-1.8l12 .1c.5 0 1-.4 1-1 .1-.6-.3-1.1-.9-1.1M16.3 56.7v-5.9h25.1v5.9zm37.9-16.8c-.9 4.1-3.9 7.1-8.4 8.5-.9.3-1.9.4-2.9.4h-27c-.3 0-6.4.7-8.5-6.3 0-.3-1.4-5.5 4.3-8.9.1 0 1.4-.7 7.9-2.3 6.8-1.7 20.2-5.3 20.4-5.4.1 0 5.6-1.9 11 2.9 2.7 2.5 4.1 7.1 3.2 11.1" />
  </svg>
);
export default IconStationaryBike;
