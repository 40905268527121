import * as React from "react";
const IconRupeeCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.1 17.8H38.2c-.2-5.5-2.6-11-7.1-13.8H53c.6 0 1-.4 1-1s-.4-1-1-1H10.9c-.6 0-1 .4-1 1s.4 1 1 1h13.2c7.6 0 11.8 6.8 12.1 13.8H10.9c-.6 0-1 .4-1 1s.4 1 1 1h25.3c-.9 7.3-12 12.7-19.3 12.7q-.6 0-.9.6c-.3.6-.1.8.1 1.1l23 27.5c.2.2.5.4.8.4.2 0 .5-.1.6-.2.4-.4.5-1 .1-1.4L19 34.4c8.1-.9 18.4-6.7 19.2-14.6h14.9c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconRupeeCopy;
