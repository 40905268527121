import * as React from "react";
const IconUserLock = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.9 40.8v-4c0-1.6-.6-3.2-1.8-4.3-1.1-1.2-2.7-1.8-4.3-1.8-3.4 0-6.1 2.7-6.1 6.1v4c-3.3 2-5.5 5.6-5.5 9.8 0 6.3 5.2 11.5 11.5 11.5 6.4 0 11.5-5.2 11.5-11.5.1-4.2-2.1-7.8-5.3-9.8m-10.2-4.1c0-2.3 1.8-4.1 4.1-4.1 1.1 0 2.1.4 2.9 1.2s1.2 1.8 1.2 2.9v3c-1.3-.5-2.7-.8-4.1-.8s-2.8.3-4.1.8zM49.8 60c-5.3 0-9.5-4.3-9.5-9.5s4.3-9.5 9.5-9.5 9.5 4.3 9.5 9.5S55 60 49.8 60" />
    <path d="M49.8 50.5c-.6 0-1 .4-1 1v1.4c0 .6.4 1 1 1s1-.4 1-1v-1.4c0-.6-.5-1-1-1M38.4 30.5c-3.6-2.6-7.9-4-12.3-4-6.4 0-12.3 2.8-16.6 8-4.3 5.1-6.7 12.2-6.9 20q0 .6.6.9c2.8 1.4 12.5 5.8 22.9 5.8.9 0 1.8 0 2.8-.1.6 0 1-.5.9-1.1 0-.6-.5-1-1.1-.9-.9.1-1.8.1-2.6.1-9.3 0-18.2-3.8-21.5-5.3.2-7.1 2.5-13.5 6.4-18.1 4-4.7 9.3-7.3 15.1-7.3 4 0 7.8 1.2 11.1 3.6.4.3 1.1.2 1.4-.2.4-.5.3-1.1-.2-1.4M26.2 26.5c6.8 0 12.2-5.5 12.2-12.2S32.9 2 26.2 2 13.9 7.5 13.9 14.2s5.5 12.3 12.3 12.3m0-22.5c5.6 0 10.2 4.6 10.2 10.2s-4.6 10.2-10.2 10.2S16 19.8 16 14.2C15.9 8.6 20.5 4 26.2 4" />
  </svg>
);
export default IconUserLock;
