import * as React from "react";
const IconYenCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M48.7 3.5c.3-.5.1-1.1-.4-1.4s-1.1-.1-1.3.4L32 27.4 17 2.5c-.3-.5-.9-.6-1.4-.3-.4.2-.6.8-.3 1.3L31 29.6v4H18.8c-.6 0-1 .4-1 1s.4 1 1 1H31v8.5H18.8c-.6 0-1 .4-1 1s.4 1 1 1H31V61c0 .6.4 1 1 1s1-.4 1-1V46.2h12.2c.6 0 1-.4 1-1s-.4-1-1-1H33v-8.5h12.2c.6 0 1-.4 1-1s-.4-1-1-1H33v-4z" />
  </svg>
);
export default IconYenCopy;
