import * as React from "react";
const IconFunnelCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.8 3.2c-.3-.7-1-1.2-1.8-1.2H7c-.8 0-1.5.4-1.9 1.1-.3.6-.2 1.4.3 2.1l17.7 20.4.1.1-.1-.1V60c0 .7.3 1.3.9 1.6.3.2.7.3 1.1.3q.6 0 1.2-.3L40.1 53l.2-.1c.5-.5.7-1 .7-1.5V25.6L58.5 5.2c.5-.5.6-1.3.3-2m-19.3 21c-.5.5-.5 1-.5 1.4v25.7l-.1.1L25.3 60c-.1 0-.2 0-.2.1V25.7c0-.4-.2-1-.4-1.2L7 4h49.9z" />
  </svg>
);
export default IconFunnelCopy;
