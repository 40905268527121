import * as React from "react";
const IconLovePair = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.1 30.9c-1.8-1.8-4.4-2.3-6.6-1.6C51.5 27.4 45.6 17 32 17c-13.7 0-19.5 10.5-20.4 12.3-.6-.2-1.3-.3-2-.3h-.1c-1.6 0-3.2.6-4.4 1.7-1.3 1.2-2 2.8-2.1 4.6 0 1.9.7 3.8 2 5.1l5.9 5.9c.5.5 1.1.7 1.7.7s1.3-.2 1.7-.7l6.1-6.1c1.2-1.2 1.9-2.9 1.9-4.7s-.7-3.4-1.9-4.7c-1.8-1.8-4.4-2.4-6.7-1.6C15.5 26.4 20.9 19 32 19c11.3 0 16.6 7.6 18.2 10.4-.7-.3-1.4-.4-2.2-.4-1.7 0-3.2.6-4.5 1.7-1.3 1.2-2 2.8-2.1 4.6 0 1.9.7 3.8 2 5.1l5.9 5.9c.5.5 1.1.7 1.7.7s1.3-.2 1.7-.7l6.1-6.1c1.3-1.2 1.9-2.9 1.9-4.7.3-1.7-.4-3.4-1.6-4.6m-40 1.4c.9.9 1.4 2 1.3 3.3 0 1.2-.5 2.4-1.3 3.2L13 44.9c-.2.2-.5.2-.6 0L6.5 39c-1-1-1.5-2.3-1.5-3.7 0-1.3.5-2.4 1.4-3.2.9-.7 1.9-1.1 3.1-1.1.9 0 1.8.3 2.6.8.3.2.8.2 1.1 0 1.9-1.3 4.3-1.1 5.9.5m38.6 6.5-6.1 6.1c-.2.2-.5.2-.6 0L45 39c-1-1-1.5-2.3-1.5-3.7 0-1.3.5-2.4 1.4-3.2.8-.8 1.9-1.2 3.1-1.2h.1c.9 0 1.8.3 2.6.8.3.2.8.2 1.1 0 1.8-1.3 4.3-1 5.9.5.9.9 1.4 2 1.3 3.3 0 1.3-.5 2.4-1.3 3.3" />
  </svg>
);
export default IconLovePair;
