import * as React from "react";
const IconEyeTest = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.7 60.3 42.8 41.4c8.5-9.2 8.3-23.6-.7-32.5C37.7 4.4 31.8 2 25.5 2S13.3 4.4 8.9 8.9C-.3 18-.3 33 8.9 42.1c4.4 4.4 10.3 6.9 16.6 6.9 5.9 0 11.5-2.2 15.9-6.2l18.9 18.9c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4M10.3 40.7c-8.4-8.4-8.4-22 0-30.4C14.4 6.2 19.8 4 25.5 4s11.1 2.2 15.2 6.3c8.4 8.4 8.4 22 0 30.4-4.1 4-9.4 6.3-15.2 6.3-5.7 0-11.1-2.2-15.2-6.3" />
    <path d="M38 24c-.2-.3-5.6-6.3-12.5-6.3S13.2 23.8 13 24c-.3.4-.3.9 0 1.3.2.3 5.6 6.3 12.5 6.3s12.3-6.1 12.5-6.3c.3-.3.3-.9 0-1.3m-12.5 5.7c-4.7 0-8.8-3.5-10.3-5 1.5-1.5 5.6-5 10.3-5s8.8 3.5 10.3 5c-1.5 1.5-5.6 5-10.3 5" />
    <path d="M23.3 24.7c0 1.2 1 2.2 2.2 2.2s2.2-1 2.2-2.2-1-2.2-2.2-2.2-2.2 1-2.2 2.2" />
  </svg>
);
export default IconEyeTest;
