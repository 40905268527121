import * as React from "react";
const IconPadlock = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M25 31.4V41c0 .6.4 1 1 1h16.5c.6 0 1-.4 1-1v-9.7c0-.6-.4-1-1-1h-2.7c.2 0 .2-.2.2-.3v-3.4c0-3.1-2.6-5.7-5.7-5.7s-5.7 2.6-5.7 5.7V30c0 .2 0 .3.1.4H26c-.5 0-1 .4-1 1m5.6-1.4v-3.4c0-2 1.7-3.7 3.7-3.7s3.7 1.7 3.7 3.7V30c0 .2 0 .3.1.4h-7.6c.1-.1.1-.3.1-.4M27 32.4h14.5V40H27z" />
    <path d="M34.3 4.7c-7.6 0-14.9 3.2-20.1 8.8-.4.4-.3 1 .1 1.4s1 .3 1.4-.1c4.8-5.2 11.6-8.2 18.6-8.2 14 0 25.3 11.4 25.3 25.3 0 14-11.4 25.3-25.3 25.3-11 0-20.6-7-24-17.3.1 0 .3.1.4.1l7.1 2.9c.5.2 1.1 0 1.3-.5s0-1.1-.5-1.3l-7.1-2.9q-1.5-.6-3 0c-1 .4-1.7 1.2-2.1 2.1l-2.9 7.1c-.2.5 0 1.1.5 1.3.1.1.3.1.4.1.4 0 .8-.2.9-.6l2.9-7.1c.1-.2.2-.3.3-.4 3.7 11.1 14.1 18.6 25.9 18.6C49.5 59.3 61.7 47 61.7 32S49.4 4.7 34.3 4.7" />
  </svg>
);
export default IconPadlock;
