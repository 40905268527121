import * as React from "react";
const IconBoardingPass = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 11.2H5c-1.7 0-3 1.3-3 3v35.5c0 1.7 1.3 3 3 3h52c2.8 0 5-2.2 5-5V16.2c0-2.7-2.2-5-5-5m3 36.6c0 1.7-1.3 3-3 3H5c-.6 0-1-.4-1-1V14.2c0-.6.4-1 1-1h52c1.7 0 3 1.3 3 3z" />
    <path d="M50.5 24.4H39.9c-.6 0-1 .4-1 1s.4 1 1 1h10.5c.6 0 1-.4 1-1s-.4-1-.9-1M50.5 37.6H39.9c-.6 0-1 .4-1 1s.4 1 1 1h10.5c.6 0 1-.4 1-1s-.4-1-.9-1M29.7 28.4 22.5 26v-5.9c0-.6-.4-1-1-1s-1 .4-1 1V26l-7.2 2.4c-.5.2-.8.7-.6 1.3.1.4.5.7.9.7.1 0 .2 0 .3-.1l6.6-2.2V38l-4.7 2.3c-.5.2-.7.8-.5 1.3q.3.6.9.6c.1 0 .3 0 .4-.1l4.8-2.4 4.8 2.4c.1.1.3.1.4.1q.6 0 .9-.6c.2-.5 0-1.1-.5-1.3L22.5 38v-9.9l6.6 2.2c.1 0 .2.1.3.1.4 0 .8-.3.9-.7.2-.5-.1-1.1-.6-1.3" />
  </svg>
);
export default IconBoardingPass;
