import * as React from "react";
const IconQuoteAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M29 20.4c0-1.1-.9-2-2-2h-7.8c-1.1 0-2 .9-2 2v7.8c0 1.1.9 2 2 2H27v4.6c0 4.8-3.9 8.8-8.8 8.8-.6 0-1 .4-1 1s.4 1 1 1c5.9 0 10.8-4.8 10.8-10.8V20.4m-2 7.8h-7.8v-7.8H27zM44.8 18.4H37c-1.1 0-2 .9-2 2v7.8c0 1.1.9 2 2 2h7.8v4.6c0 4.8-3.9 8.8-8.8 8.8-.6 0-1 .4-1 1s.4 1 1 1c5.9 0 10.8-4.8 10.8-10.8V20.4c0-1.1-.9-2-2-2m-7.8 2h7.8v7.8H37z" />
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
  </svg>
);
export default IconQuoteAlt1;
