import * as React from "react";
const IconPerfume2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M52.1 30.7h-8.5v-8.6c0-.6-.4-1-1-1H38v-7.4h5.2c.6 0 1-.4 1-1s-.4-1-1-1H38v-.4c0-.6-.4-1-1-1s-1 .4-1 1v.4h-5.2c-.6 0-1 .4-1 1s.4 1 1 1H36v7.4h-4.7c-.6 0-1 .4-1 1v8.6h-8.5c-.6 0-1 .4-1 1V58c0 2.2 1.8 4 4 4h24.3c2.2 0 4-1.8 4-4V31.7c0-.5-.4-1-1-1m-19.8-7.5h9.3v7.6h-9.3zM51.1 58c0 1.1-.9 2-2 2H24.8c-1.1 0-2-.9-2-2V32.7h28.3zM23.8 12.7c0-.6-.4-1-1-1h-11c-.6 0-1 .4-1 1s.4 1 1 1h11c.6 0 1-.4 1-1M12.9 3.9l9.6 4.2c.1.1.3.1.4.1.4 0 .8-.2.9-.6.2-.5 0-1.1-.5-1.3l-9.6-4.2c-.5-.2-1.1 0-1.3.5-.3.5 0 1.1.5 1.3M23.8 17.9c-.2-.5-.8-.7-1.3-.5l-9.6 4.2c-.5.2-.7.8-.5 1.3q.3.6.9.6c.1 0 .3 0 .4-.1l9.6-4.2c.4-.2.7-.8.5-1.3" />
  </svg>
);
export default IconPerfume2;
