import * as React from "react";
const IconRocketAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m50.6 39.4-5.1-2.7v-7.9c0-15.2-6.6-21.5-13-25.6-.3-.2-.7-.2-1.1 0-6.4 4.1-13 10.3-13 25.6v7.9l-5.1 2.7c-2.4 1.3-3.9 3.8-3.9 6.5v7.6c0 .4.2.7.5.9.2.1.3.1.5.1s.3 0 .5-.1l7.5-4v2.3c0 .6.4 1 1 1h2.8V60c0 .6.4 1 1 1h17.5c.6 0 1-.4 1-1v-6.4h2.9c.6 0 1-.4 1-1v-2.3l7.5 4c.1.1.3.1.5.1s.4 0 .5-.1c.3-.2.5-.5.5-.9v-7.6c-.1-2.6-1.6-5.1-4-6.4M11.5 51.8v-5.9c0-2 1.1-3.8 2.8-4.8l4.1-2.2V48zM39.7 59H24.2v-5.4h15.5zm3.9-7.4H33V38.4c0-.6-.4-1-1-1s-1 .4-1 1v13.2H20.4V28.8c0-13.9 5.5-19.6 11.6-23.6 6.1 4 11.6 9.8 11.6 23.6zm8.9.2L45.6 48v-9.1l4.1 2.2c1.7.9 2.8 2.8 2.8 4.8z" />
    <path d="M32 19.1c-2.7 0-4.9 2.2-4.9 4.9s2.2 4.9 4.9 4.9 4.9-2.2 4.9-4.9-2.2-4.9-4.9-4.9m0 7.8c-1.6 0-2.9-1.3-2.9-2.9s1.3-2.9 2.9-2.9 2.9 1.3 2.9 2.9-1.3 2.9-2.9 2.9" />
  </svg>
);
export default IconRocketAlt;
