import * as React from "react";
const IconCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 24H34.2V8.6C34.2 5 31.3 2 27.6 2h-19C4.9 2 2 4.9 2 8.6v22.6c0 3.6 2.9 6.6 6.6 6.6h19.2V57c0 2.8 2.2 5 5 5H57c2.8 0 5-2.2 5-5V29c0-2.8-2.2-5-5-5M8.6 35.8c-2.5 0-4.6-2-4.6-4.6V8.6C4 6 6 4 8.6 4h19.1c2.5 0 4.6 2 4.6 4.6v15.5c-2.5.3-4.4 2.4-4.4 4.9v6.8zM60 57c0 1.7-1.3 3-3 3H32.8c-1.7 0-3-1.3-3-3V29c0-1.7 1.3-3 3-3H57c1.7 0 3 1.3 3 3z" />
    <path d="M51.6 32.7H38.2c-.6 0-1 .4-1 1s.4 1 1 1h13.4c.6 0 1-.4 1-1s-.4-1-1-1M51.6 42.1H38.2c-.6 0-1 .4-1 1s.4 1 1 1h13.4c.6 0 1-.4 1-1s-.4-1-1-1M51.6 51.5H38.2c-.6 0-1 .4-1 1s.4 1 1 1h13.4c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconCopy;
