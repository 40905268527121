import * as React from "react";
const IconWrench = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.8 62c-1.9 0-3.7-.8-5.1-2.1l-30-30.2c-4.9 1.4-10.1 0-13.7-3.6-3.5-3.6-4.9-8.8-3.6-13.7.1-.3.4-.6.7-.7s.7 0 1 .3l7 7 6.1-1.6 1.6-6.2-7-7c-.3-.3-.3-.6-.3-1 .1-.3.4-.6.7-.7 4.8-1.3 10 .1 13.6 3.6 3.4 3.5 4.8 8.5 3.7 13.2l30.2 30.4c2.8 2.8 2.8 7.4 0 10.2-1.2 1.4-3 2.1-4.9 2.1M20.1 27.6c.3 0 .5.1.7.3l30.4 30.6c1 1 2.3 1.5 3.6 1.5 1.4 0 2.7-.5 3.6-1.5 2-2 2-5.3 0-7.3L27.9 20.4c-.3-.3-.4-.6-.3-1 1.2-4.2 0-8.8-3.1-11.8-2.6-2.6-6.3-3.9-9.9-3.5l6.1 6.1c.3.3.3.6.3 1l-2 7.2c-.1.3-.4.6-.7.7L11.1 21c-.3.1-.7 0-1-.3l-6-6.1c-.4 3.6.8 7.3 3.4 10 3.2 3.2 7.9 4.4 12.3 2.9.1.1.2.1.3.1" />
  </svg>
);
export default IconWrench;
