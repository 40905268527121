import * as React from "react";
const IconHandbag5 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M48.4 29.1c-1.1-1.3-2.7-2-4.4-2h-4C39.7 14.5 37 2 32 2s-7.7 12.5-8 25.1h-4.1c-1.7 0-3.3.7-4.4 2s-1.6 3-1.3 4.7l3.9 23.4C18.6 60 21 62 23.8 62h16.3c2.8 0 5.2-2 5.7-4.8l3.9-23.4c.3-1.7-.2-3.4-1.3-4.7M32 4c2.4 0 5.7 8.8 6 23.1H26C26.3 12.8 29.6 4 32 4m15.7 29.4-3.9 23.4c-.3 1.9-1.8 3.2-3.6 3.2H23.8c-1.8 0-3.4-1.3-3.7-3.1l-3.9-23.4c-.2-1.1.1-2.2.8-3s1.8-1.3 2.9-1.3H44c1.1 0 2.1.5 2.9 1.3.7.8 1 1.9.8 2.9" />
  </svg>
);
export default IconHandbag5;
