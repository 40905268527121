import * as React from "react";
const IconHighLevel = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      d="M6.109 24a17.93 17.93 0 0 1 4.956-10.52l3.056 3.055 1.415-1.414-3.001-3A17.93 17.93 0 0 1 23 8.027V13h2V8.027a17.92 17.92 0 0 1 10.465 4.094L32.585 15 34 16.414l2.935-2.935A17.93 17.93 0 0 1 41.891 24h-4.207v2H42a17.9 17.9 0 0 1-4.106 11.427l-3.42-3.42-1.415 1.414 3.478 3.477-.013.012 1.391 1.437A19.93 19.93 0 0 0 44 25.99C44 14.95 35.045 6 24 6S4 14.949 4 25.99a19.92 19.92 0 0 0 5.79 14.065l1.42-1.407-.019-.02 3.006-3.005-1.414-1.414-2.919 2.918A17.9 17.9 0 0 1 6 26h4.053v-2z"
    />
    <path
      
      fillRule="evenodd"
      d="M35.482 28.621 21.518 29.91a1.6 1.6 0 0 0-.973.598l-1.638 2.43c-.183 1.213-.053 1.696.665 2.48l2.633 1.284c.364.15.77.16 1.141.032l12.716-5.872c1.891-.657 1.385-2.617-.582-2.24m-12.664 6.154 8.054-3.72-8.832.815-1.202 1.782c-.006.081-.005.13-.003.155zm-1.998-.994.004.008zm14.684-4.852.003-.002z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconHighLevel;
