import * as React from "react";
const IconMessageDesktop = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.2 14.7c-.6 0-1 .4-1 1s.4 1 1 1c1 0 1.8.8 1.8 1.7v27.2c0 1-.8 1.8-1.8 1.8H5.8c-1 0-1.8-.8-1.8-1.8V18.5c0-1 .8-1.7 1.8-1.7.6 0 1-.4 1-1s-.4-1-1-1c-2.1 0-3.8 1.7-3.8 3.7v27.2c0 2.1 1.7 3.8 3.8 3.8H26l-5.9 9.9h-6.6c-.6 0-1 .4-1 1s.4 1 1 1h36.9c.6 0 1-.4 1-1s-.4-1-1-1h-6.6l-5.9-9.9h20.3c2.1 0 3.8-1.7 3.8-3.8V18.5c0-2.1-1.7-3.8-3.8-3.8M41.5 59.4h-19l5.8-9.8v-.1h7.3z" />
    <path d="M19 24h26c1.5 0 2.7-1.2 2.7-2.7v-16c0-1.5-1.2-2.7-2.7-2.7H19c-1.5 0-2.7 1.2-2.7 2.7v16c.1 1.5 1.3 2.7 2.7 2.7m26-2H19c-.4 0-.7-.3-.7-.7V5.9l12.1 7.4c.5.3 1 .4 1.6.4.5 0 1.1-.1 1.6-.4L45.7 6v15.4c-.1.3-.4.6-.7.6M20.2 4.6h23.7l-11.4 6.9c-.3.2-.7.2-1.1 0z" />
  </svg>
);
export default IconMessageDesktop;
