import * as React from "react";
const IconWarehouse = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62 27.3v-4.8c0-1.9-1-3.6-2.6-4.6L34.8 3.1c-1.7-1-3.8-1-5.5 0L4.6 17.9C3 18.9 2 20.6 2 22.5v33.8c0 3 2.4 5.4 5.3 5.4h49.4c3 0 5.3-2.4 5.3-5.3zM5.6 19.6 30.3 4.8c.5-.3 1.1-.5 1.7-.5s1.2.2 1.7.5l24.6 14.8c1 .6 1.6 1.7 1.6 2.9v3.8H4v-3.8c0-1.2.6-2.3 1.6-2.9m17.5 24.7h17.8v8.1H23.1zm17.8-2H23.1v-3.8c0-1.1.9-2 2-2h13.8c1.1 0 2 .9 2 2zM23.1 59.6v-5.1h17.8v5.1zm33.5 0H42.9V38.5c0-2.2-1.8-4-4-4H25.1c-2.2 0-4 1.8-4 4v21.1H7.3c-1.8 0-3.3-1.5-3.3-3.4v-28h56v28c0 1.9-1.5 3.4-3.4 3.4" />
    <path d="M22.1 19.7h19.8c.6 0 1-.4 1-1s-.4-1-1-1H22.1c-.6 0-1 .4-1 1s.5 1 1 1" />
  </svg>
);
export default IconWarehouse;
