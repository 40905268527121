import * as React from "react";
const IconPointerRightCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.7 25c-.6-2.1-2-3.7-3.9-4.6-1.7-.8-3.6-.9-5.5-.9-4.8-.1-9.6-.1-14.3 0 1-1.1 1.9-2.3 2.6-3.6 1.2-2.4 1.6-4.8.9-6.9-.8-2.6-3.3-4.4-5.8-4.3-2.4 0-4.2 1.5-5.9 2.8-3.4 2.6-7.3 5.3-12.3 8.4-.2.1-.7.3-1.2.6-2.9 1.4-9 4.4-11.4 8.8C2.2 30 1.6 37.2 2.2 42.1c.6 5.6 2.7 10.1 6 12.8 4.2 3.5 10.1 4.1 14.8 4.3 1.8.1 3.7.1 5.5.1h4.9c1.5 0 3.2 0 4.8-.6 1.8-.7 3.3-2.2 4-4.1.5-1.5.5-3.1 0-4.5 2.5-1.9 3.5-5.3 2.5-8.2 2.1-1.9 2.9-4.9 2.1-7.5.4 0 .9 0 1.3.1 1.7.1 3.5.2 5.3 0 2.6-.2 4.9-1.2 6.5-2.6.4-.4.8-.9 1.1-1.3 1-1.6 1.3-3.6.7-5.6m-2.4 4.4c-.2.3-.5.7-.8 1-1.2 1.1-3.1 1.8-5.3 2.1-1.6.2-3.2.1-5-.1-1-.1-2-.1-3-.1-.4 0-.7.2-.9.5s-.2.7 0 1c1.4 2.2.7 5.4-1.4 6.9-.4.3-.5.8-.3 1.3 1.2 2.3.2 5.5-2.1 6.8-.2.1-.4.3-.5.6s0 .5.1.8c.6 1.1.7 2.4.2 3.7-.5 1.4-1.5 2.5-2.8 3-1.2.5-2.7.5-4.1.5-3.4 0-6.9.1-10.4-.1-4.4-.2-9.9-.8-13.7-3.9-3.7-3.1-4.9-7.9-5.3-11.5-.5-4.7 0-11.3 2.3-15.7 2-3.8 7.7-6.6 10.5-7.9.6-.3 1.1-.5 1.4-.7 5.1-3.1 9.1-5.8 12.6-8.5 1.5-1.2 3-2.3 4.7-2.4h.1c1.6 0 3.3 1.3 3.9 3 .7 2.1-.2 4.3-.7 5.3-.9 1.8-2.4 3.4-3.8 4.9-.3.3-.3.7-.2 1.1q.3.6.9.6c5.4-.1 11-.1 16.5 0 1.8.1 3.4.1 4.7.8 1.3.6 2.4 1.8 2.8 3.3.5 1.3.3 2.7-.4 3.7" />
  </svg>
);
export default IconPointerRightCopy;
