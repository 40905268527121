import * as React from "react";
const IconBored = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M20.2 27.7c4.2 0 7.7-3.9 7.7-8.7 0-.6-.4-1-1-1s-1 .4-1 1c0 3.7-2.5 6.7-5.7 6.7s-5.7-3-5.7-6.7c0-.6-.4-1-1-1s-1 .4-1 1c0 4.7 3.5 8.7 7.7 8.7M50.5 17.9c-.6 0-1 .4-1 1 0 3.7-2.5 6.7-5.7 6.7-3.1 0-5.7-3-5.7-6.7 0-.6-.4-1-1-1s-1 .4-1 1c0 4.8 3.4 8.7 7.7 8.7 4.2 0 7.7-3.9 7.7-8.7 0-.5-.4-1-1-1" />
    <path d="M62 30.5C61.2 14.6 48 2 32 2S2.8 14.6 2 30.5V32c0 1.6.1 3.1.3 4.6C4.6 50.9 17 62 32 62s27.4-11.1 29.7-25.4c.2-1.5.3-3 .3-4.6zM32 60C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
    <path d="M43.5 36.3h-23c-2.9 0-5.3 2.4-5.3 5.3v2c0 2.9 2.4 5.3 5.3 5.3h23.1c2.9 0 5.3-2.4 5.3-5.3v-2c-.1-3-2.4-5.3-5.4-5.3m3.3 7.2c0 1.8-1.5 3.3-3.3 3.3h-23c-1.8 0-3.3-1.5-3.3-3.3v-2c0-1.8 1.5-3.3 3.3-3.3h23.1c1.8 0 3.3 1.5 3.3 3.3v2z" />
  </svg>
);
export default IconBored;
