import * as React from "react";
const IconSuperCool = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M49.1 16.2H14.9c-.8 0-1.4.7-1.4 1.6v8.3c0 1.8 1.2 3.3 2.8 3.3h10.1c1.4 0 3-1.5 3-3.4v-4.5h5.3V26c0 1.9 1.1 3.4 2.5 3.4h10.6c1.5 0 2.8-1.5 2.8-3.3v-8.3c-.1-.9-.8-1.6-1.5-1.6" />
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
    <path d="M45.6 36.3H18.4c-.7 0-1.3.3-1.7.8s-.6 1.1-.5 1.7c1.5 7.5 8.1 13 15.8 13s14.3-5.5 15.8-13c.1-.6 0-1.2-.5-1.7-.4-.5-1-.8-1.7-.8M32 49.8c-6.7 0-12.5-4.8-13.8-11.5 0 0 .1-.1.2-.1h27.3c.1 0 .1.1.2.2C44.5 45 38.7 49.8 32 49.8" />
  </svg>
);
export default IconSuperCool;
