import * as React from "react";
const IconYogaMatAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.8 8.9c-1.4-.9-3.1-1.1-4.7-.5l-6.6 2.7H22.1C20.5 7.6 17 5.2 13 5.2c-5.5 0-10 4.5-10 10v33.5c0 5.5 4.5 10 10 10h34.9l9.9-4.1c1.9-.8 3.1-2.6 3.1-4.6V13c.1-1.7-.7-3.2-2.1-4.1M5 15.2c0-4.4 3.6-8 8-8s8 3.6 8 8v27.5c-1.8-2.5-4.7-4-8-4s-6.2 1.6-8 4zm0 33.6c0-4.4 3.6-8 8-8s8 3.6 8 8-3.6 8-8 8-8-3.6-8-8m54 1.3c0 1.2-.7 2.3-1.9 2.8l-9.6 3.9H19c2.5-1.8 4-4.7 4-8V15.2c0-.7-.1-1.5-.2-2.2h25.1l6.9-2.8c.9-.4 2-.3 2.8.3.9.6 1.4 1.5 1.4 2.5z" />
  </svg>
);
export default IconYogaMatAlt;
