import * as React from "react";
const IconTiktokAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      
      d="M33.413.053C36.907 0 40.373.027 43.84 0c.213 4.08 1.68 8.24 4.667 11.12 2.986 2.96 7.2 4.32 11.306 4.773V26.64c-3.84-.133-7.706-.933-11.2-2.587-1.52-.693-2.933-1.573-4.32-2.48-.026 7.787.027 15.574-.053 23.334-.213 3.733-1.44 7.44-3.6 10.506-3.493 5.12-9.547 8.454-15.76 8.56-3.813.214-7.627-.826-10.88-2.746C8.613 58.053 4.827 52.24 4.267 46c-.054-1.333-.08-2.667-.027-3.973.48-5.067 2.987-9.92 6.88-13.227 4.427-3.84 10.613-5.68 16.4-4.587.053 3.947-.107 7.894-.107 11.84-2.64-.853-5.733-.613-8.053.987-1.68 1.093-2.96 2.773-3.627 4.667-.56 1.36-.4 2.853-.373 4.293.64 4.373 4.853 8.053 9.333 7.653 2.987-.026 5.84-1.76 7.387-4.293.507-.88 1.067-1.787 1.093-2.827.267-4.773.16-9.52.187-14.293.027-10.747-.027-21.467.053-32.187"
    />
  </svg>
);
export default IconTiktokAlt;
