import * as React from "react";
const IconPlaceholderRuble = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2C18.7 2 7.9 12.8 7.9 26.1c0 18.8 22.5 35.1 23.5 35.7.2.1.4.2.6.2s.4-.1.6-.2c1-.7 23.5-16.9 23.5-35.7C56.1 12.8 45.3 2 32 2m0 57.8C28.5 57.1 9.9 42.3 9.9 26.1 9.9 13.9 19.8 4 32 4s22.1 9.9 22.1 22.1c0 16.2-18.6 31-22.1 33.7" />
    <path d="M37.2 15.4h-7.3c-.6 0-1 .4-1 1v16.3h-6.4c-.6 0-1 .4-1 1s.4 1 1 1h6.4V41c0 .6.4 1 1 1s1-.4 1-1v-6.3h6.4c.6 0 1-.4 1-1s-.4-1-1-1h-6.4V26h6.3c2.9 0 5.3-2.4 5.3-5.3s-2.4-5.3-5.3-5.3m0 8.5h-6.3v-6.5h6.3c1.8 0 3.3 1.5 3.3 3.3s-1.5 3.2-3.3 3.2" />
  </svg>
);
export default IconPlaceholderRuble;
