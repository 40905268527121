import * as React from "react";
const IconUmbrella = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M33 6.2V3c0-.6-.4-1-1-1s-1 .4-1 1v3.2C16.4 6.7 4.5 18 2.9 33c-.1 1 .2 2.1 1 2.9.7.8 1.8 1.3 2.8 1.3H31V61c0 .6.4 1 1 1s1-.4 1-1V37.2h24.3c1.1 0 2.1-.5 2.8-1.3s1.1-1.8 1-2.9C59.5 18 47.6 6.7 33 6.2m25.6 28.3c-.3.4-.8.6-1.4.6H6.7c-.5 0-1-.2-1.4-.6-.3-.4-.5-.8-.4-1.3C6.4 18.9 18 8.1 32 8.1s25.6 10.8 27.1 25.1c0 .5-.1 1-.5 1.3" />
  </svg>
);
export default IconUmbrella;
