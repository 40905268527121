import * as React from "react";
const IconMicCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 43c3 0 5.8-1.2 7.9-3.3 2-2.1 3.2-4.8 3.1-7.7V13c0-6.1-4.9-11-11-11S21 6.9 21 13v19c0 6.1 4.9 11 11 11m-9-30c0-4.9 4-9 9-9s9 4 9 9v19c0 2.4-.9 4.6-2.5 6.3-1.7 1.7-4 2.7-6.4 2.7-5 0-9-4-9-9V13z" />
    <path d="M52 31.5c0-.6-.4-1-1-1s-1 .4-1 1c0 9.9-8.1 18-18 18s-17.9-8-17.9-17.9c0-.6-.4-1-1-1s-1 .4-1 1c0 10.7 8.4 19.4 18.9 19.9V60h-7.7c-.6 0-1 .4-1 1s.4 1 1 1h17.5c.6 0 1-.4 1-1s-.4-1-1-1H33v-8.5c10.5-.6 19-9.3 19-20" />
  </svg>
);
export default IconMicCopy;
