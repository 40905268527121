import * as React from "react";
const IconXbox = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m22.3 19.4 1.3-1.5-2.3-2c-3.8-3.4-6.3-5.1-8.6-5.9-1.8-.7-2-.6-3.5 1-3.5 3.8-6.6 10-7.5 15.3-.7 4.4-.8 5.5-.6 7.6.8 6.3 2.2 10.7 5.2 15.1 1.3 1.8 1.5 2.3 1.1 1.3-.6-1.4-.1-4.8 1.1-8 2.3-6.1 8.2-16 13.8-22.9M17.7 5.3c.8 0 2.1 0 3 .1 2.3.4 6.3 1.8 8.9 3.1 1.2.8 2.4 1.3 2.4 1.2 0 0 1.3-.6 2.7-1.3 4.6-2.3 9-3.4 12-3.2l1.5.1-1-.3c-2.1-1.4-6.1-2.8-9.3-3.5-3.1-.7-8.9-.7-12 0-2.7.6-4.8 1.3-7.2 2.4-2.7 1.4-2.9 1.6-1 1.4M32.3 26.2c-.6-.1-6.2 4.8-10.1 9-7 7.5-11.1 13.9-11.1 17.7 0 1.8.7 2.8 4.2 5.1 4.2 2.8 9 4.5 13.8 4.9 3.9.3 8.7-.1 12.5-1.3 4.5-1.4 10.6-5.2 11.5-7 1.3-2.4-1.3-8-6.8-14.8-3.4-4.2-12.9-13.4-14-13.6M62.4 26c-1-4.4-2.8-8.4-5.2-12-1.5-2.1-3.5-4.2-4.1-4.2-1 0-3.1.7-4.5 1.7-1.8 1.1-3.5 2.5-6.1 4.8l-2 1.7 1.8 2.3c5.6 7 11.7 17.5 13.5 23.1.8 2.5 1.3 5.1 1 6.5-.1 1-.1 1 .1.7.8-1 2.5-3.9 3.2-5.5.8-2 1.8-5.1 2.3-7.3.7-2.9.6-9 0-11.8" />
  </svg>
);
export default IconXbox;
