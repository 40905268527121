import * as React from "react";
const IconOfferCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60 27.9 57.5 26c-1-.8-1.5-2.1-1.2-3.3l.8-3c.4-1.5.1-3.1-.8-4.3-1-1.3-2.5-2.2-4.2-2.3l-3.2-.2c-1.4-.1-2.6-.9-3.1-2.1L44.6 8c-.6-1.4-1.8-2.5-3.3-3.1-1.6-.5-3.3-.3-4.7.5L33.9 7c-1.2.7-2.7.7-3.8 0l-2.7-1.6c-1.4-.9-3.1-1.1-4.7-.5-1.5.5-2.7 1.6-3.3 3.1l-1.2 2.8c-.5 1.2-1.7 2-3.1 2.1l-3.2.2c-1.7.1-3.2.9-4.2 2.3-.9 1.3-1.2 2.8-.8 4.3l.8 3c.3 1.1-.2 2.5-1.2 3.3L4 27.9c-1.3 1-2 2.5-2 4.1s.7 3.1 2 4.1L6.5 38c1 .8 1.5 2.1 1.2 3.3l-.8 3c-.4 1.5-.1 3.1.8 4.3 1 1.3 2.5 2.2 4.2 2.3l3.2.2c1.4.1 2.6.9 3.1 2.1l1.2 2.8c.6 1.4 1.8 2.5 3.3 3.1q.9.3 1.8.3c1 0 2-.3 2.9-.8l2.7-1.6c1.2-.7 2.7-.7 3.8 0l2.7 1.6c1.4.9 3.1 1.1 4.7.5 1.5-.5 2.7-1.6 3.3-3.1l1.2-2.8c.5-1.2 1.7-2 3.1-2.1l3.2-.2c1.7-.1 3.2-.9 4.2-2.3.9-1.3 1.2-2.8.8-4.3l-.8-3c-.3-1.2.1-2.5 1.2-3.3l2.5-1.9c1.3-1 2-2.5 2-4.1s-.7-3.1-2-4.1m-1.3 6.6-2.5 1.9c-1.7 1.3-2.4 3.4-1.8 5.4l.8 3c.2.9.1 1.9-.5 2.6-.6.9-1.6 1.4-2.7 1.5l-3.2.2c-2.1.1-4 1.4-4.8 3.3l-1.2 2.8c-.4.9-1.1 1.6-2.1 1.9s-2.2.2-3.1-.3l-2.7-1.6c-1.8-1.1-4.1-1.1-5.9 0l-2.7 1.6c-.9.6-2 .7-3.1.3-1-.3-1.7-1-2.1-1.9L20 52.5c-.8-1.9-2.7-3.2-4.8-3.3l-3.2-.3c-1.1-.1-2.1-.6-2.7-1.5-.6-.8-.7-1.7-.5-2.6l.8-3c.5-2-.2-4.1-1.8-5.4l-2.5-1.9C4.5 33.9 4 33 4 32s.5-1.9 1.3-2.5l2.5-1.9c1.7-1.3 2.4-3.4 1.8-5.4l-.8-3c-.2-.9-.1-1.9.5-2.6.6-.9 1.6-1.4 2.7-1.4l3.2-.2c2.1-.1 4-1.4 4.8-3.3l1.2-2.8c.4-.9 1.1-1.6 2.1-1.9.4-.1.8-.2 1.2-.2.7 0 1.3.2 1.9.5l2.7 1.6c1.8 1.1 4.1 1.1 5.9 0l2.7-1.6c.9-.6 2-.7 3.1-.3 1 .3 1.7 1 2.1 1.9l1.2 2.8c.8 1.9 2.7 3.2 4.8 3.3l3.2.2c1.1.1 2.1.6 2.7 1.4s.7 1.7.5 2.6l-.8 3c-.5 2 .2 4.1 1.8 5.4l2.5 1.9c.7.6 1.2 1.5 1.2 2.5s-.5 1.9-1.3 2.5" />
    <path d="M40.6 23.4c-.4-.4-1-.4-1.4 0L23.4 39.1c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l15.8-15.8c.4-.3.4-.9 0-1.3M28.9 28.9c.9-.9 1.4-2.2 1.4-3.5s-.5-2.6-1.4-3.5c-1.9-1.9-5.1-1.9-7 0-.9.9-1.4 2.2-1.4 3.5s.5 2.6 1.4 3.5 2.2 1.4 3.5 1.4 2.6-.5 3.5-1.4m-5.6-1.4q-.9-.9-.9-2.1t.9-2.1 2.1-.9c1.2 0 1.5.3 2.1.9q.9.9.9 2.1t-.9 2.1c-1.1 1.1-3 1.1-4.2 0M35.1 35c-1.9 1.9-1.9 5.1 0 7 1 1 2.2 1.4 3.5 1.4s2.5-.5 3.5-1.4c1.9-1.9 1.9-5.1 0-7-2-1.9-5.1-1.9-7 0m5.6 5.6c-1.1 1.1-3 1.1-4.2 0-1.1-1.1-1.1-3 0-4.2q.9-.9 2.1-.9c1.2 0 1.5.3 2.1.9 1.1 1.2 1.1 3.1 0 4.2" />
  </svg>
);
export default IconOfferCopy;
