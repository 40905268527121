import * as React from "react";
const IconPregnant3638w = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M40.314 31.607a1 1 0 0 1-1.813-.845 16 16 0 0 0-22.824-20.427l.322.414v.001l.046.058q.161.206.325.42c.423.55.856 1.14 1.169 1.735.311.592.56 1.291.49 2.041-.076.792-.488 1.474-1.171 2.036l-.748.615c-.262.215-.337.407-.355.53a.57.57 0 0 0 .106.41c.19.28.684.576 1.44.375.5-.134 1.163-.281 1.765.045.298.162.493.391.627.58.118.167.23.366.328.54l.042.074.122.272a3 3 0 0 1 5.83-.224l.406 1.44.648-.07a3 3 0 0 1 3.123 1.918c.492 1.296.32 2.62-.033 3.64-.37 1.066-1.024 2.078-1.89 2.93-1.78 1.75-4.494 2.875-7.845 2.57a3 3 0 0 1-2.525-1.9l-1.032-2.654-.878.249a3 3 0 0 1-1.633-5.774l3.502-.99a3 3 0 0 1 .598-.106l-.18-.396a9 9 0 0 0-.162-.28c-.069.012-.165.033-.298.068-1.494.398-2.893-.132-3.607-1.18a2.57 2.57 0 0 1-.432-1.837c.102-.675.473-1.29 1.063-1.775l.747-.614c.362-.299.435-.523.45-.68.019-.197-.04-.487-.268-.922-.227-.43-.566-.903-.985-1.448l-.335-.432q-.203-.259-.413-.533a16 16 0 0 0-4.608 19.126 1 1 0 1 1-1.822.826 18 18 0 1 1 32.707.174M22.856 20.11a1 1 0 0 1 1.234.691l.635 2.252a1 1 0 0 0 1.069.724l1.487-.16a1 1 0 0 1 1.042.64c.273.72.201 1.536-.054 2.274-.26.751-.737 1.505-1.403 2.16-1.346 1.323-3.476 2.257-6.261 2.004a1 1 0 0 1-.842-.633l-1.361-3.502a1 1 0 0 0-1.204-.6l-1.753.496a1 1 0 0 1-.545-1.924l3.503-.991a1 1 0 0 1 1.204.6l1.53 3.933a1 1 0 0 0 1.023.633c1.118-.103 2.012-.466 2.689-.935a1 1 0 0 0-.6-1.822 1 1 0 0 1-.991-.728l-1.093-3.878a1 1 0 0 1 .691-1.234"
      clipRule="evenodd"
    />
    <path
      
      d="M10.159 36.8c.353 0 .541.254.541.45s-.188.45-.541.45h-.894a.8.8 0 0 0 0 1.6h.894c.353 0 .541.254.541.45s-.188.45-.541.45h-.894c-.27 0-.457-.159-.517-.317a.8.8 0 0 0-1.496.567c.308.813 1.114 1.35 2.013 1.35h.894c1.128 0 2.141-.866 2.141-2.05 0-.482-.168-.91-.442-1.25a1.97 1.97 0 0 0 .442-1.25c0-1.184-1.013-2.05-2.141-2.05h-.894c-.899 0-1.705.537-2.013 1.35a.8.8 0 0 0 1.496.567c.06-.158.248-.317.517-.317zM26.2 37.25c0-.196-.188-.45-.541-.45h-.894c-.27 0-.457.159-.517.317a.8.8 0 0 1-1.496-.567c.308-.813 1.114-1.35 2.013-1.35h.894c1.128 0 2.141.866 2.141 2.05 0 .482-.168.91-.442 1.25.274.34.442.768.442 1.25 0 1.184-1.013 2.05-2.141 2.05h-.894c-.899 0-1.705-.537-2.013-1.35a.8.8 0 1 1 1.496-.567c.06.158.248.317.517.317h.894c.353 0 .541-.254.541-.45s-.188-.45-.541-.45h-.894a.8.8 0 0 1 0-1.6h.894c.353 0 .541-.254.541-.45"
    />
    <path
      
      fillRule="evenodd"
      d="M16.68 36.8c.252 0 .435.15.495.313a.8.8 0 0 0 1.5-.56c-.302-.808-1.097-1.353-1.995-1.353h-.86c-1.13 0-2.12.878-2.12 2.05v2.5c0 1.172.99 2.05 2.12 2.05h.86c1.13 0 2.12-.878 2.12-2.05s-.99-2.05-2.12-2.05h-.86q-.268 0-.52.063v-.513c0-.208.191-.45.52-.45zm-1.38 2.95c0-.208.191-.45.52-.45h.86c.329 0 .52.242.52.45s-.191.45-.52.45h-.86c-.329 0-.52-.242-.52-.45"
      clipRule="evenodd"
    />
    <path
      
      d="M35.21 36.132a.8.8 0 0 1 1.58-.264l.463 2.785.552-.967a.8.8 0 0 1 1.39 0l.552.967.464-2.785a.8.8 0 0 1 1.578.264l-.833 5a.8.8 0 0 1-1.484.265l-.972-1.701-.972 1.7a.8.8 0 0 1-1.484-.264z"
    />
    <path
      
      fillRule="evenodd"
      d="M33.862 38.5c.273-.34.438-.77.438-1.25 0-1.172-.99-2.05-2.12-2.05h-.86c-1.13 0-2.12.878-2.12 2.05 0 .48.165.91.438 1.25-.273.34-.438.77-.438 1.25 0 1.172.99 2.05 2.12 2.05h.86c1.13 0 2.12-.878 2.12-2.05 0-.48-.165-.91-.438-1.25m-2.543-1.7c-.328 0-.519.242-.519.45s.191.45.52.45h.86c.329 0 .52-.242.52-.45s-.191-.45-.52-.45zm1.381 2.95c0-.208-.191-.45-.52-.45h-.86c-.329 0-.52.242-.52.45s.191.45.52.45h.86c.329 0 .52-.242.52-.45"
      clipRule="evenodd"
    />
    <path  d="M20.5 38.2a.8.8 0 0 0 0 1.6h1a.8.8 0 0 0 0-1.6z" />
    <path
      
      fillRule="evenodd"
      d="M29.787 21.418a3.819 3.819 0 1 0 3.547-6.764 3.819 3.819 0 0 0-3.547 6.764m.929-1.772a1.819 1.819 0 1 0 1.689-3.221 1.819 1.819 0 0 0-1.69 3.221"
      clipRule="evenodd"
    />
  </svg>
);
export default IconPregnant3638w;
