import * as React from "react";
const IconSafeBox = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 9.9H6c-2.2 0-4 1.8-4 4v27c0 2.2 1.8 4 4 4h6.2v8.2c0 .6.4 1 1 1s1-.4 1-1v-8.2h37.5v8.2c0 .6.4 1 1 1s1-.4 1-1v-8.2H58c2.2 0 4-1.8 4-4v-27c0-2.2-1.8-4-4-4m2 31c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2v-27c0-1.1.9-2 2-2h52c1.1 0 2 .9 2 2z" />
    <path d="M23.8 17.8H10.7c-.6 0-1 .4-1 1v7.4c0 .6.4 1 1 1h13.1c.6 0 1-.4 1-1v-7.4c0-.6-.5-1-1-1m-1 7.4H11.7v-5.4h11.1zM52.7 16.5c-.6 0-1 .4-1 1v17c0 .6.4 1 1 1s1-.4 1-1v-17c0-.5-.5-1-1-1M33.2 17.8h-.7c-.6 0-1 .4-1 1s.4 1 1 1h.7c.6 0 1-.4 1-1s-.5-1-1-1M42.4 17.8h-.7c-.6 0-1 .4-1 1s.4 1 1 1h.7c.6 0 1-.4 1-1s-.5-1-1-1M33.2 26.3h-.7c-.6 0-1 .4-1 1s.4 1 1 1h.7c.6 0 1-.4 1-1s-.5-1-1-1M42.4 26.3h-.7c-.6 0-1 .4-1 1s.4 1 1 1h.7c.6 0 1-.4 1-1s-.5-1-1-1M33.2 34.9h-.7c-.6 0-1 .4-1 1s.4 1 1 1h.7c.6 0 1-.4 1-1s-.5-1-1-1M42.4 34.9h-.7c-.6 0-1 .4-1 1s.4 1 1 1h.7c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconSafeBox;
