import * as React from "react";
const IconMustache = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.5 4.8c-.2-1.1-1.1-1.9-2.2-2.1s-2.2.3-2.8 1.2c-3.8 6-10.8 4.8-14.7 3.5-3.5-1.1-7.5-.3-10.1 2.2-2.6-2.5-6.6-3.4-10.1-2.2C17.8 8.6 10.8 9.8 7 3.9c-.5-.8-1.4-1.3-2.3-1.3C3.2 2.6 2 3.8 2 5.3v55c0 .6.4 1 1 1s1-.4 1-1V19.7c2.6 3.3 8.4 9.1 17.8 10.2 4.1.3 7.6-.7 10-2.8 3.9 3.5 10.1 3.3 14.8 1.9 9.1-2.7 17.6-11.5 14.9-24.2M46 27.1c-4.2 1.3-10.2 1.5-13.5-2.1-.2-.2-.5-.3-.7-.3-.3 0-.5.1-.7.3-2.6 2.9-6.8 3-9 2.9C10 26.7 4.2 16.6 4.1 16.5c0-.1-.1-.1-.1-.2v-11c0-.4.3-.7.7-.7.3 0 .5.1.6.4 4.5 7.1 12.6 5.8 17 4.3 3.1-1 6.6 0 8.7 2.4.4.4 1.1.4 1.5 0 2.1-2.5 5.6-3.4 8.7-2.4 4.4 1.4 12.4 2.8 17-4.3.2-.2.4-.4.7-.3q.45.15.6.6c2.5 11.5-5 19.3-13.5 21.8" />
  </svg>
);
export default IconMustache;
