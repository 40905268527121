import * as React from "react";
const IconAllergies = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M18.983 26.983a1 1 0 1 1-2 .001 1 1 0 0 1 2-.001m5 .996a1 1 0 1 1-2 .001 1 1 0 0 1 2-.001m-3.995 5.003a1 1 0 1 0-.002-2 1 1 0 0 0 .002 2m5.998-2.005a1 1 0 1 1-2 .002 1 1 0 0 1 2-.002m1.004 5a1 1 0 1 0-.002-2 1 1 0 0 0 .002 2m2.996-5.003a1 1 0 1 1-2 .001 1 1 0 0 1 2-.001m-7.995 6.006a1 1 0 1 0-.002-2 1 1 0 0 0 .002 2m-4.002-1.996a1 1 0 1 1-2 .001 1 1 0 0 1 2-.002m-3.002-2.998a1 1 0 1 0-.002-2 1 1 0 0 0 .002 2"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M22.5 6A1.5 1.5 0 0 1 24 7.5V23h2V10.5a1.5 1.5 0 0 1 3 0v15.586c0 1.904 2.41 2.73 3.579 1.227l3.186-4.097a1.518 1.518 0 0 1 2.496 1.72L31.057 36.8A10.81 10.81 0 0 1 21.814 42C15.842 42 11 37.158 11 31.186V16.5a1.5 1.5 0 0 1 3 0v8.618h2V10.5a1.5 1.5 0 0 1 3 0V23h2V7.5A1.5 1.5 0 0 1 22.5 6m-3.496 1.339a3.5 3.5 0 0 1 6.992 0A3.5 3.5 0 0 1 31 10.5v15.586l3.187-4.097a3.518 3.518 0 0 1 5.784 3.986l-7.204 11.862A12.81 12.81 0 0 1 21.814 44C14.737 44 9 38.263 9 31.186V16.5a3.5 3.5 0 0 1 5-3.163V10.5a3.5 3.5 0 0 1 5.004-3.161"
      clipRule="evenodd"
    />
  </svg>
);
export default IconAllergies;
