import * as React from "react";
const IconMedalFirst = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32.4 39.2c-.4-.2-.8-.1-1.1.2l-3.9 3.9c-.4.4-.4 1 0 1.4s1 .4 1.4 0l2.2-2.2v9.3c0 .6.4 1 1 1s1-.4 1-1V40.1c0-.4-.2-.8-.6-.9" />
    <path d="M33 30 45.2 3.4c.2-.5 0-1.1-.5-1.3s-1.1 0-1.3.5L32 27.3 20.7 2.6c-.2-.5-.8-.7-1.3-.5s-.7.8-.5 1.3L31 30c-8.4.5-15 7.4-15 16 0 8.8 7.2 16 16 16s16-7.2 16-16c0-8.6-6.6-15.5-15-16m-1 30c-7.7 0-14-6.3-14-14s6.3-14 14-14 14 6.3 14 14-6.3 14-14 14" />
  </svg>
);
export default IconMedalFirst;
