import * as React from "react";
const IconFileMedical1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.8 2H13.3c-2.2 0-4 1.8-4 4v52c0 2.2 1.8 4 4 4h37.5c2.2 0 4-1.8 4-4V6c0-2.2-1.8-4-4-4m2 56c0 1.1-.9 2-2 2H13.3c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2h37.5c1.1 0 2 .9 2 2z" />
    <path d="M39.6 30.5H33V24c0-.6-.4-1-1-1s-1 .4-1 1v6.6h-6.6c-.6 0-1 .4-1 1s.4 1 1 1H31v6.6c0 .6.4 1 1 1s1-.4 1-1v-6.6h6.6c.6 0 1-.4 1-1s-.5-1.1-1-1.1" />
  </svg>
);
export default IconFileMedical1;
