import * as React from "react";
const IconSproutCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.7 12.7c0-.3-.1-.6-.3-.8s-.5-.3-.8-.3c-.6.1-13.9 1.6-20.3 8-3.3 3.3-5.4 8.5-6.5 12.9v-1.2c-.1-.6-1.6-13.9-8-20.3S4.9 3.1 4.4 3c-.3 0-.6.1-.8.3s-.4.5-.3.8c.1.6 1.6 13.9 8 20.3 5.5 5.5 16.3 7.5 19.4 7.9V60c0 .6.4 1 1 1s1-.4 1-1V41c2.3-.3 14.1-2.1 19.9-8 6.5-6.4 8.1-19.7 8.1-20.3M12.7 23C7.9 18.2 6 8.6 5.4 5.2c3.5.6 13 2.5 17.9 7.3 4.8 4.8 6.7 14.4 7.3 17.9-3.5-.6-13.1-2.6-17.9-7.4m20.7 15.9c.6-3.5 2.5-13.1 7.3-17.9s14.4-6.7 17.8-7.3c-.6 3.5-2.5 13-7.3 17.8-4.7 4.9-14.3 6.8-17.8 7.4" />
  </svg>
);
export default IconSproutCopy;
