import * as React from "react";
const IconWeight = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M35.851 16.25c.742.819.515 2.076-.39 2.71l-4.872 3.418c-.904.634-2.14.378-3.033-.27a6.04 6.04 0 0 0-3.455-1.156 6.05 6.05 0 0 0-3.492 1.04c-.915.619-2.158.834-3.04.17l-4.757-3.578c-.882-.664-1.068-1.929-.299-2.722a16 16 0 0 1 23.338.389m-1.655 1.156a14 14 0 0 0-20.067-.334l4.638 3.488a.4.4 0 0 0 .13.006c.157-.014.377-.086.59-.23a8.05 8.05 0 0 1 4.8-1.38q.057-.12.149-.226l2.746-3.158a1 1 0 0 1 1.51 1.312l-2.155 2.478a8 8 0 0 1 2.195 1.127c.208.151.425.23.582.25.076.01.116.003.13-.001zm-4.743 3.329-.008.003q.006-.005.008-.003m-10.694-.178q0 0 .008.003z"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M36.216 42a4 4 0 0 0 3.994-3.778l1.556-28A4 4 0 0 0 37.772 6H10.228a4 4 0 0 0-3.993 4.222l1.555 28A4 4 0 0 0 11.784 42zm-24.432-2h24.432a2 2 0 0 0 1.997-1.89l1.556-28A2 2 0 0 0 37.772 8H10.228a2 2 0 0 0-1.997 2.11l1.556 28A2 2 0 0 0 11.784 40"
      clipRule="evenodd"
    />
  </svg>
);
export default IconWeight;
