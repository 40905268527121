import * as React from "react";
const IconHammerAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M49.7 9.8H38.1v-5c0-1.6-1.3-2.9-2.9-2.9h-6.6c-1.6 0-2.9 1.3-2.9 2.9v5H14.3c-3.6 0-6.5 2.9-6.5 6.5v7.3c0 3.6 2.9 6.5 6.5 6.5h11.5v29.1c0 1.6 1.3 2.9 2.9 2.9h6.6c1.6 0 2.9-1.3 2.9-2.9V30.1h11.5c3.6 0 6.5-2.9 6.5-6.5v-7.3c0-3.6-2.9-6.5-6.5-6.5M27.9 4.9c0-.5.4-.9.9-.9h6.6c.5 0 .9.4.9.9v5H28v-5zm8.2 54.2c0 .5-.4.9-.9.9h-6.6c-.5 0-.9-.4-.9-.9v-29H36v29zm18.1-35.5c0 2.5-2 4.5-4.5 4.5H14.3c-2.5 0-4.5-2-4.5-4.5v-7.3c0-2.5 2-4.5 4.5-4.5h35.3c2.5 0 4.5 2 4.5 4.5v7.3z" />
  </svg>
);
export default IconHammerAlt;
