import * as React from "react";
const IconTableLamp = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59 60H46.9V7.6c0-3.1-2.5-5.6-5.6-5.6H21.2c-3.1 0-5.6 2.5-5.6 5.6v2.3C9.1 10.4 4 15.9 4 22.5v6c0 .6.4 1 1 1h23.4c.6 0 1-.4 1-1v-6c0-6.7-5.2-12.1-11.7-12.6V7.6c0-2 1.6-3.6 3.6-3.6h20.1c2 0 3.6 1.6 3.6 3.6V60H32.5c-.6 0-1 .4-1 1s.4 1 1 1H59c.6 0 1-.4 1-1s-.4-1-1-1M27.4 22.5v5H6v-5c0-5.9 4.8-10.7 10.7-10.7s10.7 4.8 10.7 10.7" />
  </svg>
);
export default IconTableLamp;
