import * as React from "react";
const IconBz = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 513 342"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 0h513v342H0z" />
    <circle cx={260.9} cy={170.9} r={118.9}  />
    <circle
      cx={261.9}
      cy={173.1}
      r={94.5}
      
      
      strokeMiterlimit={10}
      strokeWidth={18}
    />
    <g >
      <path
        
        d="m261.9 151.5-50.6 23.4v20c0 11.8 6.1 22.8 16.2 28.9L262 239l34.5-15.2c10-6.2 16.2-17.1 16.2-28.9v-20z"
      />
      <path  d="M211.3 128.1h101.3v46.7H211.3z" />
    </g>
    <path  d="M0 0h513v35H0zm0 306h513v35H0z" />
  </svg>
);
export default IconBz;
