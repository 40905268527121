import * as React from "react";
const IconDress = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.5 50.5 47.2 32.3V19.4c0-.1-.1-.1-.1-.2 0 0 0-.1-.1-.1l-6.8-8V3c0-.6-.4-1-1-1s-1 .4-1 1v8.1l-6 7-6-7V3c0-.6-.4-1-1-1s-1 .4-1 1v8.1l-6.9 8s0 .1-.1.1c0 .1-.1.1-.1.2V32L3.5 50.4c-.3.4-.2 1 .2 1.4 8 6.6 18 10.3 28.4 10.3 10.3 0 20.4-3.6 28.3-10.2.3-.4.4-1 .1-1.4M32.8 20.4l6.3-7.3 6.1 7.1v10.5c-3.5 3.5-8.1 5.5-13.1 5.5s-9.6-2-13.1-5.5V20.1l6-7.1 6.3 7.3c.4.5 1.1.5 1.5.1M32 60c-9.6 0-18.9-3.3-26.4-9.2l13-17.7c3.7 3.2 8.5 5 13.4 5s9.6-1.7 13.3-4.9l13 17.7C50.9 56.8 41.6 60 32 60" />
  </svg>
);
export default IconDress;
