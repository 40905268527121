import * as React from "react";
const IconSkirt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.9 48.8 51.2 21.3V10.1c0-2.2-1.8-4-4-4H16.8c-2.2 0-4 1.8-4 4v11.2L2.1 48.8c-.2.5 0 1 .4 1.2 8.9 5.2 19.1 7.9 29.5 7.9s20.6-2.7 29.5-7.9c.4-.2.6-.7.4-1.2m-8-14.9c-3.2-.2-6.5-.4-9.7-.3-3 0-6.6.1-9.7 1.5-1.3.6-2.5 1.4-3.7 2.2-.9.6-1.7 1.1-2.6 1.6-2.3 1.2-4.8 1.8-7.3 1.8h-1.1c-1.2-.1-2.4-.1-3.6.2-1.4.3-2.7 1.1-3.8 1.8l-7.7 4.9 9.8-25h35zM16.8 8.1h30.3c1.1 0 2 .9 2 2v10.4H14.8V10.1c0-1.1.9-2 2-2M32 55.9c-9.3 0-18.4-2.3-26.5-6.6 0 0 .1 0 .1-.1l7.9-5c1-.6 2-1.3 3.1-1.6.9-.2 1.9-.2 3-.1h1.2c2.9 0 5.7-.6 8.3-2 1-.5 1.9-1.1 2.8-1.7 1.1-.8 2.2-1.5 3.4-2 2.8-1.2 5.9-1.4 8.9-1.4 3.5 0 7 .1 10.5.4l5 12.8c-8.4 4.8-18 7.3-27.7 7.3" />
  </svg>
);
export default IconSkirt1;
