import React, { useState } from 'react';
import { useNavigation } from 'context/NavigationContext';
import {
	BringzzPageContainer,
	BringzzButton,
	BringzzIcon,
	BringzzText,
	BringzzBottomDrawer,
	BringzzCountryDropdown,
	BringzzDropdown,
	BringzzImage
} from '@bringzz/components';
import useUser from 'hooks/useUser';
import useRequest from 'hooks/useRequest';

const options = [
	{ value: 'Passport', label: 'Passport' },
	{ value: 'Driver license', label: 'Driver license' },
	{ value: 'National id', label: 'National id' }
];

const EditIDVerificationView = () => {
	const { backPress, navigate } = useNavigation();
	const { sendRequest, loading } = useRequest();
	const [idImage, setIdImage] = useState(null);
	const [idEditDrawerOpened, setIdEditDrawerOpened] = useState(false);
	const { getUser } = useUser();
	const [selectedCountry, setSelectedCountry] = useState(null);
	const [idVerificationType, setIdVerificationType] = useState(null);

	const user = getUser();

	const takePhotoClick = async () => {
		try {
			let base64Image = '';
			// Handling camera input
			const stream = await navigator.mediaDevices.getUserMedia({
				video: true
			});
			const video = document.createElement('video');
			video.srcObject = stream;
			video.play();

			const canvas = document.createElement('canvas');
			const context = canvas.getContext('2d');
			video.onloadedmetadata = () => {
				canvas.width = video.videoWidth;
				canvas.height = video.videoHeight;
				context.drawImage(video, 0, 0, canvas.width, canvas.height);
				base64Image = canvas.toDataURL('image/jpeg');
				// Stop the video stream
				stream.getTracks().forEach(track => track.stop());
				setIdImage(base64Image);
				setIdEditDrawerOpened(false);
				console.log(base64Image);
			};
		} catch (error) {
			console.error('Error handling picker choice:', error);
		}
	};

	return (
		<BringzzPageContainer
			header={{
				left: (
					<BringzzIcon
						size='22'
						icon='IconChevronLeft'
						folder='LineIcons'
						className='cursor-pointer'
						onClick={backPress}
					/>
				),
				title: <BringzzText tag='h2'>Update ID-Verification</BringzzText>
			}}
			footer={
				<div className='pb-28 px-6'>
					{idImage ? (
						// add two bringz button in a row one for upload and one for update
						<div className='flex justify-center space-x-6 px-4'>
							<BringzzButton
								size='lg'
								className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
								onClick={async () => {
									await sendRequest({
										method: 'PATCH',
										url: `/account/id`,
										data: {
											idVerificationType,
											idVerificationCountry: selectedCountry,
											idVerificationValue: idImage
										}
									})
										.then(response => {
											if (response.status === 'success')
												navigate(`/account`, false);
										})
										.catch(error => {
											console.log(error);
										});
								}}
								disabled={loading}
							>
								Submit
							</BringzzButton>
							<BringzzButton
								size='lg'
								className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
								onClick={() => setIdEditDrawerOpened(true)}
							>
								Retake
							</BringzzButton>
						</div>
					) : (
						<BringzzButton
							size='lg'
							className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
							onClick={() => setIdEditDrawerOpened(true)}
						>
							Update verification
						</BringzzButton>
					)}
				</div>
			}
		>
			<div className='p-6 space-y-4'>
				<div className='p-4 bg-gray-100 rounded-lg'>
					<BringzzText tag='h4' className='font-bold'>
						Current ID-Verification
					</BringzzText>
					<BringzzText tag='p' className='mt-2 font-medium'>
						{user.id.type} | No. {user.id.value}
					</BringzzText>
					<BringzzText tag='p' className='mt-1'>
						Place of issue: {user.id.country}
					</BringzzText>
					<BringzzText tag='p' className='mt-1'>
						Lorem ipdsum ID-Data
					</BringzzText>
				</div>
			</div>
			{idImage && (
				<div className='h-full p-6 space-y-4 w-full flex justify-center items-center'>
					<BringzzImage src={idImage} className='h-full w-full aspect-auto' />
				</div>
			)}
			<BringzzBottomDrawer
				isOpen={idEditDrawerOpened}
				close={() => setIdEditDrawerOpened(false)}
				title='Update ID'
			>
				<div className='px-4 py-6 min-h-100'>
					<div className='py-4'>
						<BringzzCountryDropdown
							label='Select Country'
							mode='flag-name'
							onCountryChange={country => {
								setSelectedCountry(country);
								console.log('changed');
							}}
						/>
					</div>
					<div className='py-4'>
						<BringzzDropdown
							label={'Choose id type'}
							options={options}
							defaultValue={options[0]}
							onChange={value => {
								setIdVerificationType(value);
							}}
						/>
					</div>
				</div>

				<div className='text-center mb-12 px-4'>
					<BringzzButton
						onClick={takePhotoClick}
						className='bg-magic-lilac w-full flex justify-center'
					>
						Take Photo
					</BringzzButton>
				</div>
			</BringzzBottomDrawer>
		</BringzzPageContainer>
	);
};

export default EditIDVerificationView;
