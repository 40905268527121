import * as React from "react";
const IconCocktail = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.5 4.6c-4.9 0-8.9 4-8.9 8.9 0 .6.1 1.1.2 1.6H22.3l-1.8-9C20 3.8 17.8 2 15.3 2H5.6c-.6 0-1 .4-1 1s.4 1 1 1h9.7c1.5 0 2.9 1.1 3.2 2.6l1.7 8.6H14c-1 0-1.8.5-2.2 1.4s-.3 1.9.4 2.6l16.2 18.5V60h-6.7c-.6 0-1 .4-1 1s.4 1 1 1h15.4c.6 0 1-.4 1-1s-.4-1-1-1h-6.7V37.6l14.8-16.9c1.5 1.1 3.4 1.7 5.3 1.7 4.9 0 8.9-4 8.9-8.9s-4-8.9-8.9-8.9M13.6 17.4c0-.1.1-.2.4-.2h30.7c.2 0 .3.2.4.2s.1.3-.1.4l-15.6 18-15.7-17.9c-.1-.2-.1-.4-.1-.5m36.9 3.1c-1.4 0-2.8-.4-3.9-1.3.6-.7.8-1.7.4-2.6s-1.2-1.4-2.2-1.4h-.9c-.1-.5-.2-1.1-.2-1.6 0-3.8 3.1-6.9 6.9-6.9s6.9 3.1 6.9 6.9-3.2 6.9-7 6.9" />
  </svg>
);
export default IconCocktail;
