import * as React from "react";
const IconDerrickAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m54.4 42.9-18.9-9.8.1-.1c.3-.4.3-1.1-.1-1.4-.4-.4-1.1-.3-1.4.1-.5.6-1.7.8-2.7.5-.6-.2-1.7-.8-1.9-2.4-.2-2 1.2-3.1 2.4-3.6.7-.3 1.2-1 1.2-1.8l-.1-7.1c3.8-.5 6.7-3.8 6.7-7.7C39.7 5.5 36.3 2 32 2s-7.7 3.5-7.7 7.7c0 4 3 7.2 6.8 7.7l.1 7c-1.3.5-4 2.1-3.6 5.6.1 1.1.6 2.1 1.3 2.9l-19.3 10C8 43.8 7 45.4 7 47.2v9.9c0 2.7 2.2 4.9 4.9 4.9h40.3c2.7 0 4.9-2.2 4.9-4.9v-9.9c-.1-1.8-1.1-3.4-2.7-4.3M26.3 9.7C26.3 6.6 28.8 4 32 4s5.7 2.6 5.7 5.7c0 3.2-2.6 5.7-5.7 5.7-3.2.1-5.7-2.5-5.7-5.7m-15.8 35 20-10.4c.1-.1.2-.1.3-.2h.1c.5.1 1 .2 1.5.2.4 0 .7 0 1-.1l20.1 10.5c.3.2.6.4.8.6H9.7c.3-.2.5-.4.8-.6M52.2 60H11.8C10.3 60 9 58.7 9 57.1v-9.8h46v9.8c0 1.6-1.3 2.9-2.8 2.9" />
  </svg>
);
export default IconDerrickAlt1;
