import * as React from "react";
const IconAirFlow = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.5 53.1 56 49.7c-2.8-1.7-6.4-1.7-9.2 0l-1.5.9c-2.2 1.3-4.9 1.3-7.2 0l-1.5-.9c-2.8-1.7-6.4-1.7-9.2 0l-1.5.9c-2.2 1.3-4.9 1.3-7.2 0l-1.5-.9c-2.8-1.7-6.4-1.7-9.2 0l-5.6 3.4c-.4.3-.5.9-.3 1.4s.9.6 1.4.3l5.6-3.4c2.2-1.3 4.9-1.3 7.1 0l1.5.9c2.8 1.7 6.4 1.7 9.2 0l1.5-.9c2.2-1.3 4.9-1.3 7.2 0l1.5.9c2.8 1.7 6.4 1.7 9.2 0l1.5-.9c2.2-1.3 4.9-1.3 7.2 0l5.6 3.4c.2.1.3.1.5.1.3 0 .7-.2.9-.5.1-.4 0-1-.5-1.3M61.5 33.4 56 30c-2.8-1.7-6.4-1.7-9.2 0l-1.5.9c-2.2 1.3-4.9 1.3-7.2 0l-1.5-.9c-2.8-1.7-6.4-1.7-9.2 0l-1.5.9a6.95 6.95 0 0 1-7.1 0l-1.5-.9c-2.8-1.7-6.4-1.7-9.2 0l-5.6 3.4c-.5.3-.6.9-.3 1.4s.9.6 1.4.3l5.6-3.4c2.2-1.3 4.9-1.3 7.1 0l1.5.9c2.8 1.7 6.4 1.7 9.2 0l1.5-.9c2.2-1.3 4.9-1.3 7.2 0l1.5.9c2.8 1.7 6.4 1.7 9.2 0l1.5-.9c2.2-1.3 4.9-1.3 7.2 0l5.6 3.4c.2.1.3.1.5.1.3 0 .7-.2.9-.5 0-.4-.1-1-.6-1.3M3.5 15.5l5.6-3.4c2.2-1.3 4.9-1.3 7.1 0l1.5.9c2.8 1.7 6.4 1.7 9.2 0l1.5-.9c2.2-1.3 4.9-1.3 7.2 0l1.5.9c2.8 1.7 6.4 1.7 9.2 0l1.5-.9c2.2-1.3 4.9-1.3 7.2 0l5.6 3.4c.2.1.3.1.5.1.3 0 .7-.2.9-.5.3-.5.1-1.1-.3-1.4L56 10.4c-2.8-1.7-6.4-1.7-9.2 0l-1.5.9c-2.2 1.3-4.9 1.3-7.2 0l-1.5-.9c-2.8-1.7-6.4-1.7-9.2 0l-1.5.9c-2.2 1.3-4.9 1.3-7.2 0l-1.5-.9c-2.8-1.7-6.4-1.7-9.2 0l-5.6 3.4c-.4.2-.5.9-.3 1.3.3.5.9.7 1.4.4" />
  </svg>
);
export default IconAirFlow;
