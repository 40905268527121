import * as React from "react";
const IconBike = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M35.745 12.17 30.82 13.65l3.28 8.578a8 8 0 1 1-1.868.715l-1.648-4.31-5.682 11.802A1 1 0 0 1 24 31h-4.062A8.001 8.001 0 0 1 4 30a8 8 0 0 1 15.938-1h2.5l-4.88-13.664A1 1 0 0 1 17.5 15H16a1 1 0 1 1 0-2h4.5a1 1 0 1 1 0 2h-.938l1.842 5.157 8.127-4.277-.965-2.523a1 1 0 0 1 .647-1.315l5.957-1.787zm-13.662 9.89 1.972 5.52 4.23-8.784zm12.983 8.297-2.113-5.527a6 6 0 1 0 1.868-.715l2.113 5.528a1 1 0 0 1-1.868.714M17.917 29H12a1 1 0 1 0 0 2h5.917A6.002 6.002 0 0 1 6 30a6 6 0 0 1 11.917-1"
      clipRule="evenodd"
    />
  </svg>
);
export default IconBike;
