import * as React from "react";
const IconCheckAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.8 23.1H33.5v-2.5c0-5.2-4.2-9.4-9.4-9.4H7.2c-2.9 0-5.2 2.3-5.2 5.2v19.2c0 2.9 2.3 5.2 5.2 5.2h4.4v8.1c0 2.2 1.8 4 4 4h41.1c2.9 0 5.2-2.3 5.2-5.2V28.3c.1-2.9-2.2-5.2-5.1-5.2M7.2 38.7c-1.8 0-3.2-1.4-3.2-3.2V16.4c0-1.8 1.4-3.2 3.2-3.2h16.9c4.1 0 7.4 3.3 7.4 7.4V40c-.9-.7-2.1-1.2-3.3-1.2h-21zm6.5 10.2v-8.1h14.5c1.8 0 3.3 1.5 3.3 3.3v3.5c0 1.8-1.5 3.3-3.3 3.3H15.7c-1.1 0-2-.9-2-2M60 47.6c0 1.8-1.4 3.2-3.2 3.2H32.3c.7-.9 1.2-2.1 1.2-3.3V25h23.3c1.8 0 3.2 1.4 3.2 3.2z" />
    <path d="M40 33.9h6.7c.6 0 1-.4 1-1s-.4-1-1-1H40c-.6 0-1 .4-1 1s.4 1 1 1M49.6 40.4h-9.7c-.6 0-1 .4-1 1s.4 1 1 1h9.7c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconCheckAlt1;
