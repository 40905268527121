import * as React from "react";
const IconVault = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 2H6C3.8 2 2 3.8 2 6v52c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V6c0-2.2-1.8-4-4-4m0 58H6c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2h52c1.1 0 2 .9 2 2v10.9h-6.4c-.6 0-1 .4-1 1s.4 1 1 1H60v27.6h-6.4c-.6 0-1 .4-1 1s.4 1 1 1H60V58c0 1.1-.9 2-2 2" />
    <path d="M44.4 31.6h-5.7c-.4-5.3-4.7-9.6-10-10.1v-5.7c0-.6-.4-1-1-1s-1 .4-1 1v5.7c-5.3.5-9.6 4.7-10.1 10.1H11c-.6 0-1 .4-1 1s.4 1 1 1h5.7c.5 5.3 4.7 9.6 10.1 10.1v5.7c0 .6.4 1 1 1s1-.4 1-1v-5.7c5.3-.5 9.6-4.7 10.1-10.1h5.7c.6 0 1-.4 1-1s-.6-1-1.2-1M27.7 41.7c-5 0-9.1-4.1-9.1-9.1s4.1-9.1 9.1-9.1 9.1 4.1 9.1 9.1-4.1 9.1-9.1 9.1" />
    <path d="M27.7 28.7c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4m0 5.9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2" />
  </svg>
);
export default IconVault;
