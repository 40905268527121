import * as React from "react";
const IconSmartphoneHeartBeat = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.2 34.8h-5.7l-5.9-13.6c-.2-.5-.7-.9-1.3-.9s-1.1.4-1.3.9l-5.6 14.4-3.1-7.2q-.3-.6-.9-.6H28c-.6 0-1 .4-1 1s.4 1 1 1h5.8l3.4 8.1c.2.5.7.8 1.2.8s1-.4 1.2-.9l5.6-14.5 5.5 12.8q.3.6.9.6H58c.6 0 1-.4 1-1s-.2-.9-.8-.9M20.8 10.9c-.6 0-1 .4-1 1s.4 1 1 1h8.4c.6 0 1-.4 1-1s-.4-1-1-1z" />
    <path d="M44.1 44.2c-.6 0-1 .4-1 1v12.6c0 1.2-1 2.2-2.2 2.2H9c-1.2 0-2.2-1-2.2-2.2V6.2C6.8 5 7.8 4 9 4h31.9c1.2 0 2.2 1 2.2 2.2v8.3c0 .6.4 1 1 1s1-.4 1-1V6.2c0-2.3-1.9-4.2-4.2-4.2H9C6.7 2 4.8 3.9 4.8 6.2v51.6C4.8 60.1 6.7 62 9 62h31.9c2.3 0 4.2-1.9 4.2-4.2V45.2c0-.5-.5-1-1-1" />
  </svg>
);
export default IconSmartphoneHeartBeat;
