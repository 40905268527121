import * as React from "react";
const IconCarAltCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M14.6 37.3h6.6c.6 0 1-.4 1-1s-.4-1-1-1h-6.6c-.6 0-1 .4-1 1s.4 1 1 1M41.9 37.3h6.6c.6 0 1-.4 1-1s-.4-1-1-1h-6.6c-.6 0-1 .4-1 1s.5 1 1 1" />
    <path d="M61 22.3h-3.9L51.9 9.1c-.5-1.4-1.9-2.4-3.4-2.4H15.1c-1.5 0-2.8.9-3.4 2.3L6.5 22.3H3c-.6 0-1 .4-1 1s.4 1 1 1h2.7l-.5 1.2c0 .1-.1.2-.1.3v17c0 2.2 1.8 4 4 4h.6v6.4c0 2.2 1.8 4 4 4h4.4c2.2 0 4-1.8 4-4v-6.4h18.8v6.4c0 2.2 1.8 4 4 4h4.4c2.2 0 4-1.8 4-4v-6.4h1.2c2.2 0 4-1.8 4-4v-17c0-.2-.1-.4-.2-.6l-.4-1H61c.6 0 1-.4 1-1s-.4-.9-1-.9M13.6 9.8c.3-.6.9-1.1 1.5-1.1h33.4c.7 0 1.3.4 1.5 1l6 15.1H7.6zm6.6 43.5c0 1.1-.9 2-2 2h-4.4c-1.1 0-2-.9-2-2v-6.4h8.4zm31.1 0c0 1.1-.9 2-2 2h-4.4c-1.1 0-2-.9-2-2v-6.4h8.4zm5.2-10.4c0 1.1-.9 2-2 2H9.1c-1.1 0-2-.9-2-2v-16h49.4z" />
  </svg>
);
export default IconCarAltCopy;
