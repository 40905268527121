import * as React from "react";
const IconBellAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M48.9 37.2v-9.7c0-9-7.1-16.3-15.9-16.9V5.1c0-.6-.4-1-1-1s-1 .4-1 1v5.6c-8.9.5-15.9 7.9-15.9 16.9v9.6s-.3 4.4-1.8 8.2c-.7 1.6-.5 3.5.5 4.9 1 1.5 2.6 2.3 4.3 2.3h6.2c.2 4.1 3.5 7.3 7.6 7.3s7.5-3.2 7.6-7.3h6.2c1.7 0 3.4-.9 4.3-2.3 1-1.5 1.2-3.3.5-4.9-1.3-3.8-1.6-8.2-1.6-8.2M32 57.9c-3 0-5.4-2.4-5.6-5.3h11.3c-.2 2.9-2.7 5.3-5.7 5.3m16.6-8.8c-.6.9-1.6 1.5-2.7 1.5H18.2c-1.1 0-2.1-.5-2.7-1.4s-.7-2-.3-3c1.7-4.1 1.9-8.6 1.9-8.9v-9.7c0-8.2 6.7-14.9 14.9-14.9s14.9 6.7 14.9 14.9v9.7c0 .2.3 4.7 1.9 8.8.5 1 .4 2.1-.2 3M10 7.7c-5.2 5.6-8 12.8-8 20.4 0 .6.4 1 1 1s1-.4 1-1c0-7.1 2.7-13.8 7.5-19 .4-.4.4-1-.1-1.4-.4-.4-1-.4-1.4 0M54 7.7c-.4-.4-1-.4-1.4-.1-.4.4-.4 1-.1 1.4 4.8 5.2 7.5 12 7.5 19.1 0 .6.4 1 1 1s1-.4 1-1c0-7.6-2.8-14.8-8-20.4" />
  </svg>
);
export default IconBellAlt;
