import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import BringzzText from '../BringzzText';

/**
 * @typedef {object} BringzzCheckboxProps
 * @property {string} [id] - The ID for the checkbox element.
 * @property {string} [label] - Label text for the checkbox.
 * @property {boolean} [checked] - Whether the checkbox is checked.
 * @property {function} [onChange] - Function to call when the checkbox state changes.
 * @property {string} [className] - Additional CSS classes for the checkbox.
 */

/**
 * Custom checkbox component with optional label.
 * @param {BringzzCheckboxProps} props - The component props.
 * @returns {JSX.Element} - JSX for the custom checkbox component.
 */
const BringzzCheckbox = ({ id, label, checked, onChange, className }) => {
    const handleChange = (e) => {
        if (onChange) {
            onChange(e.target.checked); // Pass the checked state to the onChange function
        }
    };

    return (
        <div className={classnames('flex items-center', className)}>
            <input
                type="checkbox"
                id={id}
                checked={checked}
                onChange={handleChange}
                className="accent-magic-lilac"
            />
            {label && (
                <label htmlFor={id} className="ml-2 text-sm">
                    <BringzzText tag='h4' className={'font-normal'}>{label}</BringzzText>
                </label>
            )}
        </div>
    );
};

BringzzCheckbox.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
};

export default BringzzCheckbox;
