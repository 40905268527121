import * as React from "react";
const IconTruckPrivate = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.8 36-7.3-10.6c-.2-.3-.5-.4-.8-.4H41.3v-3.4c0-3-2.4-5.4-5.4-5.4h-8.2c-.6 0-1 .4-1 1s.4 1 1 1h8.2c1.9 0 3.4 1.5 3.4 3.4v30.7H27.4c-.2-2.8-2.5-5-5.4-5-2.8 0-5.2 2.2-5.4 5h-8c-1.9 0-3.4-1.5-3.4-3.4V34.7c0-.6-.4-1-1-1s-1 .4-1 1v14.1c0 3 2.4 5.4 5.4 5.4h8.2c.7 2.2 2.7 3.8 5.2 3.8 2.4 0 4.5-1.6 5.2-3.8h17.6c.7 2.2 2.7 3.8 5.2 3.8s4.5-1.6 5.2-3.8H61c.6 0 1-.4 1-1V36.5c0-.2-.1-.4-.2-.5m-8.7-9.1 6.5 9.4H41.3v-9.4zM22 56.1c-1.9 0-3.4-1.5-3.4-3.4s1.5-3.4 3.4-3.4 3.4 1.5 3.4 3.4-1.5 3.4-3.4 3.4m27.9 0c-1.9 0-3.4-1.5-3.4-3.4s1.5-3.4 3.4-3.4 3.4 1.5 3.4 3.4-1.5 3.4-3.4 3.4m5.4-3.9c-.2-2.8-2.5-5-5.4-5s-5.2 2.2-5.4 5h-3.2v-14H60v14z" />
    <path d="M20.5 26v-9.6c0-.6-.4-1-1-1H17v-3.8c0-3.1-2.6-5.7-5.7-5.7s-5.7 2.6-5.7 5.7v3.8H3c-.6 0-1 .4-1 1V26c0 .6.4 1 1 1h16.5c.5 0 1-.4 1-1M7.6 11.6c0-2 1.7-3.7 3.7-3.7S15 9.6 15 11.6v3.8H7.6zM18.5 25H4v-7.6h14.5z" />
  </svg>
);
export default IconTruckPrivate;
