import React from "react";
import classnames from "classnames";
import BringzzText from "../BringzzText";
import PropTypes from "prop-types";

/**
 * `BringzzPromoBanner` displays a promotional banner with a header and a subheader.
 * It allows for customization of its background color through `className` and has default styling.
 *
 * @component
 * @example
 * return (
 *   <BringzzPromoBanner
 *     className="bg-electric-blue" // Optional custom styling
 *     header="Summer Sale!"
 *     subHeader="Up to 50% off on selected items."
 *   />
 * );
 */
const BringzzPromoBanner = ({
  className = "bg-electric-blue",
  type,
  header,
  subHeader,
  ...props
}) => {
  return (
    <div
      className={classnames(
        className,
        "h-16 flex flex-col items-center justify-center text-racing-green"
      )}
    >
      <BringzzText
        tag="h5"
        className={"font-bold"}
      >
        {header}
      </BringzzText>
      <BringzzText
        tag="h5"
        className={"font-normal"}>
        {subHeader}
      </BringzzText>
    </div>
  );
};

BringzzPromoBanner.propTypes = {
  className: PropTypes.string,
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.node.isRequired,
};
export default BringzzPromoBanner;
