import * as React from "react";
const IconNetwork = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M31.9 18.4c4.2 0 7.5-3.4 7.5-7.5S36 3.4 31.9 3.4s-7.5 3.4-7.5 7.5 3.3 7.5 7.5 7.5m0-10.6c1.7 0 3 1.4 3 3s-1.4 3-3 3-3-1.4-3-3 1.3-3 3-3M23.6 28.5c2.1-2.3 5.2-3.7 8.4-3.7s6.3 1.3 8.4 3.7c.4.5 1 .7 1.7.7.5 0 1.1-.2 1.5-.6.9-.8 1-2.3.1-3.2-3-3.2-7.3-5.1-11.7-5.1s-8.7 1.9-11.7 5.1c-.8.9-.8 2.3.1 3.2s2.3.8 3.2-.1M13.4 50.1c4.2 0 7.5-3.4 7.5-7.5 0-4.2-3.4-7.5-7.5-7.5s-7.5 3.4-7.5 7.5c-.1 4.2 3.3 7.5 7.5 7.5m0-10.6c1.7 0 3 1.4 3 3 0 1.7-1.4 3-3 3s-3-1.4-3-3c-.1-1.6 1.3-3 3-3M13.1 51.8c-4.4 0-8.7 1.9-11.7 5.1-.8.9-.8 2.3.1 3.2s2.3.8 3.2-.1c2.1-2.3 5.2-3.7 8.4-3.7s6.3 1.3 8.4 3.7c.4.5 1 .7 1.7.7.5 0 1.1-.2 1.5-.6.9-.8 1-2.3.1-3.2-3-3.2-7.3-5.1-11.7-5.1M50.4 50.1c4.2 0 7.5-3.4 7.5-7.5 0-4.2-3.4-7.5-7.5-7.5-4.2 0-7.5 3.4-7.5 7.5 0 4.2 3.4 7.5 7.5 7.5m0-10.6c1.7 0 3 1.4 3 3 0 1.7-1.4 3-3 3-1.7 0-3-1.4-3-3s1.4-3 3-3M62.7 57c-3-3.2-7.3-5.1-11.7-5.1s-8.7 1.9-11.7 5.1c-.8.9-.8 2.3.1 3.2.9.8 2.3.8 3.2-.1 2.1-2.3 5.2-3.7 8.4-3.7s6.3 1.3 8.4 3.7c.4.5 1 .7 1.7.7.5 0 1.1-.2 1.5-.6.8-.9.9-2.3.1-3.2M39.2 40.9c.5-1.1 0-2.5-1.1-3l-3.9-1.8V32c0-1.2-1-2.3-2.2-2.3s-2.3 1-2.3 2.3v4l-4 1.9c-1.1.5-1.6 1.9-1.1 3s1.9 1.6 3 1.1l4.2-2 4.5 2c.3.1.6.2.9.2.8 0 1.6-.5 2-1.3" />
  </svg>
);
export default IconNetwork;
