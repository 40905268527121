import * as React from "react";
const IconLv = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 513 342"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 0h513v342H0z" />
    <path
      
      d="M0 0h513v127.6H0zm0 214.4h513V342H0z"
      className="prefix__st1"
    />
  </svg>
);
export default IconLv;
