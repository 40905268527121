import * as React from "react";
const IconTimeSmartphone = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M25.1 8.8h-3.8c-.6 0-1 .4-1 1s.4 1 1 1h3.8c.6 0 1-.4 1-1s-.4-1-1-1" />
    <path d="M43.7 17c-.9 0-1.7.1-2.5.2V8.1c0-3.6-2.8-6.6-6.3-6.6H11.5c-3.5 0-6.3 3-6.3 6.6v46.8c0 3.6 2.8 6.6 6.3 6.6h23.4c3.5 0 6.3-3 6.3-6.6v-7.8c.8.1 1.7.2 2.6.2 8.4 0 15.2-6.8 15.2-15.2S52.1 17 43.7 17m-8.9 42.5H11.5c-2.4 0-4.3-2.1-4.3-4.6v-3.7h32v3.7c-.1 2.5-2 4.6-4.4 4.6m4.3-12.2v2h-32V8.1c0-2.5 1.9-4.6 4.3-4.6h23.4c2.4 0 4.3 2.1 4.3 4.6v9.6c-6.2 1.9-10.6 7.7-10.6 14.5s4.6 12.6 10.8 14.5c-.1.2-.2.4-.2.6m4.6-1.9c-7.3 0-13.2-5.9-13.2-13.2S36.4 19 43.7 19s13.2 5.9 13.2 13.2-6 13.2-13.2 13.2" />
    <path d="M43.9 33.2v-4.6c0-.6-.4-1-1-1s-1 .4-1 1v5c0 .3.1.5.3.7l3 3c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4z" />
  </svg>
);
export default IconTimeSmartphone;
