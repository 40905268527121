import * as React from "react";
const IconTargetAudienceAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 33c3.2 0 5.8-2.6 5.8-5.8s-2.6-5.8-5.8-5.8-5.8 2.6-5.8 5.8S28.8 33 32 33m0-9.6c2.1 0 3.8 1.7 3.8 3.8S34.1 31 32 31s-3.8-1.7-3.8-3.8 1.7-3.8 3.8-3.8M32 36.7c-3.8 0-7.3 1.8-9.4 4.9-.3.5-.2 1.1.2 1.4.5.3 1.1.2 1.4-.2 1.8-2.6 4.7-4.1 7.8-4.1s6 1.5 7.8 4.1c.2.3.5.4.8.4.2 0 .4-.1.6-.2.5-.3.6-.9.2-1.4-2.1-3.1-5.6-4.9-9.4-4.9M11.1 26.8c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l2.8 2.8H3c-.6 0-1 .4-1 1s.4 1 1 1h9.4l-2.8 2.8c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l4.5-4.5c.4-.4.4-1 0-1.4zM61 31h-9.4l2.8-2.8c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-4.5 4.5c-.4.4-.4 1 0 1.4l4.5 4.5c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4L51.6 33H61c.6 0 1-.4 1-1s-.4-1-1-1M31.3 15.5c.2.2.5.3.7.3s.5-.1.7-.3l4.5-4.5c.4-.4.4-1 0-1.4s-1-.4-1.4 0L33 12.4V3c0-.6-.4-1-1-1s-1 .4-1 1v9.4l-2.8-2.8c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4zM32.7 48.5c-.4-.4-1-.4-1.4 0L26.8 53c-.4.4-.4 1 0 1.4s1 .4 1.4 0l2.8-2.8V61c0 .6.4 1 1 1s1-.4 1-1v-9.4l2.8 2.8c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4z" />
  </svg>
);
export default IconTargetAudienceAlt;
