import * as React from "react";
const IconWheelchairAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path  d="M17 32a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
    <path
      
      fillRule="evenodd"
      d="M10 32a6 6 0 1 1 12 0 6 6 0 0 1-12 0m6-4a4 4 0 1 0 0 8 4 4 0 0 0 0-8"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M8 6v2h3.22l3.519 14.079c-4.928.62-8.74 4.825-8.74 9.921 0 5.523 4.478 10 10 10 5.524 0 10-4.477 10-10 0-1.045-.16-2.053-.457-3H31v7.17A3.001 3.001 0 0 0 32 42a3 3 0 0 0 1-5.83v-5.928l6.071 6.072 3.536-3.536-1.414-1.414-2.122 2.121L33 27.414V26a3 3 0 0 0-3-3h-1v-3a3 3 0 0 0-3-3H15.53L12.78 6zm8.03 13 .758 3.03a9.9 9.9 0 0 1 3.576.97H27v-3a1 1 0 0 0-1-1zM31 27h-6.338a10 10 0 0 0-1.52-2H30a1 1 0 0 1 1 1zm-15-3a8 8 0 1 0 0 16 8 8 0 0 0 0-16m15 15a1 1 0 1 1 2 0 1 1 0 0 1-2 0"
      clipRule="evenodd"
    />
  </svg>
);
export default IconWheelchairAlt;
