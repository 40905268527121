import * as React from "react";
const IconFirecrackersAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M40.8 15.3h-7.4l2.2-2.7c.3-.4.3-.9 0-1.3l-2.9-3.5 3.5-4.2c.4-.4.3-1.1-.1-1.4-.4-.4-1.1-.3-1.4.1l-4 4.9c-.3.4-.3.9 0 1.3l2.9 3.5-2.8 3.3h-7.7c-2.2 0-4 1.8-4 4V58c0 2.2 1.8 4 4 4h17.6c2.2 0 4-1.8 4-4V19.3c.1-2.2-1.7-4-3.9-4M21.2 27.2h21.6v22.9H21.2zm2-9.9h17.6c1.1 0 2 .9 2 2v5.9H21.2v-5.9c0-1.1.9-2 2-2M40.8 60H23.2c-1.1 0-2-.9-2-2v-5.9h21.6V58c0 1.1-.9 2-2 2" />
  </svg>
);
export default IconFirecrackersAlt;
