import * as React from "react";
const IconAlarmCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m57.4 54.6-2.1-3.2c-.5-.9-.8-1.7-.8-2.6V27.2c0-5.7-2.4-11-6.9-15-4-3.6-9.2-5.6-14.7-5.9V3c0-.6-.4-1-1-1s-1 .4-1 1v3.3c-.6 0-1.2.1-1.9.1C17.8 7.6 9.3 16.7 9.3 27.5v21.2c-.1 1.1-.3 1.8-.7 2.5l-2 3.4c-.4.6-.4 1.4 0 1.9.4.6 1 .9 1.7.9H31V61c0 .6.4 1 1 1s1-.4 1-1v-3.6h22.8c.7 0 1.3-.3 1.7-.9.3-.6.3-1.3-.1-1.9m-25.3.8q-.15 0 0 0H8.4l1.9-3.3c.5-.9.8-1.9 1-3.3V27.4c0-9.8 7.8-18 18.1-19.1.9-.1 1.7-.1 2.5-.1 5.4 0 10.5 1.9 14.4 5.4 4 3.6 6.2 8.4 6.2 13.5v21.6c0 1.3.4 2.5 1.1 3.7l1.9 3z" />
  </svg>
);
export default IconAlarmCopy;
