import * as React from "react";
const IconNailPolish = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M41.8 36.8 40.2 8.1C40 4.7 37.2 2 33.7 2h-3.5c-3.4 0-6.2 2.7-6.4 6.1l-1.6 28.8c-3.7 3.5-5.8 8.9-5.8 14.6 0 2.9 1.1 6.3 3.4 10.1.2.3.5.5.9.5h22.7c.4 0 .7-.2.9-.5 2.3-3.8 3.4-7.2 3.4-10.1-.1-5.8-2.2-11.1-5.9-14.7M30.2 4h3.5c2.4 0 4.3 1.8 4.4 4.2l1.5 26.9c-2.3-1.6-4.9-2.4-7.7-2.4-2.7 0-5.4.8-7.7 2.4l1.5-26.9c.2-2.4 2.2-4.2 4.5-4.2m12.6 56H21.2c-1.9-3.3-2.9-6.2-2.9-8.6 0-5.4 2.1-10.5 5.7-13.6 2.3-2.1 5.1-3.1 7.9-3.1s5.6 1.1 7.9 3.1c3.6 3.2 5.7 8.2 5.7 13.6.1 2.4-.8 5.3-2.7 8.6" />
  </svg>
);
export default IconNailPolish;
