import * as React from "react";
const IconGirl1015y = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M24 6a5 5 0 1 0 0 10 5 5 0 0 0 0-10m-3 5a3 3 0 1 1 6 0 3 3 0 0 1-6 0M17 19a1 1 0 0 1 1-1h12a1 1 0 0 1 .977 1.216C33.35 19.932 35 22.331 35 25.023c0 2.53-1.45 4.792-3.594 5.653.3.576.6 1.109.859 1.553.688 1.18.042 2.78-1.387 3.023-5.026.857-8.576.834-13.99-.007-1.48-.23-2.135-1.902-1.404-3.097.275-.447.587-.983.894-1.565C14.353 29.659 13 27.47 13 25.023c0-2.692 1.65-5.091 4.023-5.807A1 1 0 0 1 17 19m-2 6.023C15 22.695 16.668 21 18.5 21h2.498q-.003.237-.01.463c-.022.713-.085 1.232-.208 1.613-.115.354-.265.539-.457.66-.216.136-.604.264-1.323.264v.007a41 41 0 0 0-.92-.007H18v2c1.158 0 2.235.01 3.027.265.375.12.603.272.738.433.122.145.235.375.235.802 0 .526-.09.795-.173.936-.069.116-.178.221-.428.315-.277.104-.68.175-1.274.212C19.538 29 18.843 29 18 29v.005l-.034-.006C16.363 28.716 15 27.115 15 25.023m8.297.389a3.2 3.2 0 0 0-1.053-.809c.192-.28.334-.588.438-.91.213-.658.282-1.408.306-2.166q.007-.255.01-.527h2.004q.003.272.01.527c.024.758.093 1.508.306 2.166.222.685.614 1.307 1.292 1.735.654.412 1.463.572 2.39.572v3.122l-.538.061.595 1.263a37 37 0 0 0 1.48 2.79l.003.005v.002l.002.004v.003l.001.005a.1.1 0 0 1-.004.022l-.002.004c-4.787.816-8.118.8-13.34-.012l-.008-.014a.1.1 0 0 1-.007-.036q0-.011.008-.026c.368-.6.807-1.36 1.223-2.193.666-.001 1.286-.007 1.837-.041.657-.041 1.295-.127 1.851-.335.583-.219 1.099-.582 1.447-1.169.333-.563.452-1.231.452-1.955 0-.823-.234-1.53-.703-2.088M31 28.654c1.15-.631 2-1.975 2-3.631C33 22.695 31.332 21 29.5 21h-2.498q.003.237.01.463c.022.713.085 1.232.208 1.613.115.354.265.539.457.66.216.136.604.264 1.323.264h2z"
      clipRule="evenodd"
    />
    <path
      
      d="M20.3 41.874a1.5 1.5 0 0 1-1.3-1.486v-3.374c1.365.203 2.632.333 3.868.393l-.92 3.375a1.5 1.5 0 0 1-1.647 1.092M27.053 40.782 26.13 37.4A41 41 0 0 0 30 37v3.388a1.5 1.5 0 0 1-2.947.394"
    />
  </svg>
);
export default IconGirl1015y;
