import * as React from "react";
const IconBulbIdea = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M21.7 43.6c3.6 0 6.5-2.9 6.5-6.5s-2.9-6.5-6.5-6.5-6.5 2.9-6.5 6.5 2.9 6.5 6.5 6.5m0-11c2.5 0 4.5 2 4.5 4.5s-2 4.5-4.5 4.5-4.5-2-4.5-4.5 2-4.5 4.5-4.5M28.9 50.1H15.3c-2.8 0-5.1 2.3-5.1 5.1V61c0 .6.4 1 1 1s1-.4 1-1v-5.9c0-1.7 1.4-3.1 3.1-3.1h13.6c1.7 0 3.1 1.4 3.1 3.1V61c0 .6.4 1 1 1s1-.4 1-1v-5.9c0-2.8-2.3-5-5.1-5M50 6.2c-2.5-2.6-5.8-4.1-9.3-4.2-3.6-.1-7 1.2-9.6 3.8s-4 5.9-4 9.5c0 4.6 2.3 8.8 6.3 11.3.8.5 1.4 1.5 1.4 2.6v2.3c0 2.8 2.3 5.1 5.1 5.1h1.2c2.8 0 5.1-2.3 5.1-5.1v-2.4c0-1 .5-2 1.4-2.5 3.8-2.4 6.1-6.4 6.2-10.9 0-3.5-1.3-6.9-3.8-9.5m-5.9 25.3c0 1.7-1.4 3.1-3.1 3.1h-1.2c-1.7 0-3.1-1.4-3.1-3.1v-2.3c0-.3 0-.6-.1-1h7.6c-.1.3-.1.6-.1.9zm2.3-6.6c-.6.4-1 .8-1.4 1.4h-9.2c-.4-.5-.8-1-1.4-1.4-3.3-2.1-5.3-5.7-5.3-9.6 0-3.1 1.2-5.9 3.4-8.1 2.2-2.1 5-3.2 7.9-3.2h.3c3 .1 5.8 1.3 7.9 3.6 2.1 2.2 3.2 5.1 3.1 8.1-.1 3.7-2.1 7.2-5.3 9.2" />
  </svg>
);
export default IconBulbIdea;
