import * as React from "react";
const IconAlert = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      strokeWidth={2}
      d="M21 8a3 3 0 1 1 6 0v22a3 3 0 1 1-6 0zm0 32a3 3 0 1 1 6 0 3 3 0 0 1-6 0Z"
    />
  </svg>
);
export default IconAlert;
