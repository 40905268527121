import * as React from "react";
const IconDoctorFemale = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M32.263 8.356q.045.055.083.114l.001.002.003.005.009.015.032.053q.044.07.119.198a32.282 32.282 0 0 1 1.656 3.267C35.058 14.06 36 16.827 36 19.463c0 2.983-2.015 4.637-3.787 5.491a11.7 11.7 0 0 1-2.776.915A7.97 7.97 0 0 1 24 27.999a7.99 7.99 0 0 1-6.257-3.013l-.046-.004a8 8 0 0 1-.649-.086 8 8 0 0 1-1.97-.606c-.73-.34-1.506-.862-2.1-1.663-.603-.813-.978-1.859-.978-3.164 0-2.467 1.202-5.27 2.306-7.352a36 36 0 0 1 2.206-3.619l.032-.045.012-.019.088-.13c.075-.107.182-.25.326-.418a7.4 7.4 0 0 1 1.32-1.195C19.502 5.821 21.372 5 24.054 5c2.675 0 4.712.817 6.084 1.643.685.413 1.204.827 1.557 1.144a8 8 0 0 1 .55.55l.01.012.005.005zM18.237 9.512l-.048.074-.002.002-.008.01-.033.048q-.046.063-.132.19a34.39 34.39 0 0 0-1.942 3.212C14.988 15.094 14 17.522 14 19.462c0 .899.25 1.522.584 1.973.344.462.819.8 1.339 1.042.247.115.497.206.735.277a24 24 0 0 1-.429-2.6c-.113-1.074-.146-2.265.056-3.318.199-1.032.668-2.141 1.742-2.718 1.429-.767 3.847-1.793 5.979-2.5 1.063-.353 2.1-.642 2.929-.774.407-.064.825-.1 1.193-.063.288.03.885.139 1.215.68 2.68 3.869 2.72 7.414 2.041 10.003-.196.749-.45 1.412-.712 1.976q.333-.126.672-.288c1.45-.7 2.656-1.814 2.656-3.69 0-2.222-.81-4.686-1.668-6.654a30 30 0 0 0-1.546-3.051l-.094-.159-.024-.025-.011-.012a6 6 0 0 0-.298-.287 8.3 8.3 0 0 0-1.252-.918C27.99 7.683 26.305 7 24.055 7c-2.244 0-3.712.679-4.606 1.315a5.4 5.4 0 0 0-.961.867 4 4 0 0 0-.246.322zM32.346 8.47l-.848.53zm-.848.53.765-.644zM18.743 22.894l-.075-.317a23 23 0 0 1-.45-2.63c-.104-.993-.117-1.96.032-2.733.152-.792.43-1.175.723-1.333 1.307-.702 3.616-1.685 5.663-2.364 1.026-.34 1.942-.59 2.612-.696.253-.04.439-.055.565-.054 2.198 3.268 2.175 6.137 1.636 8.191a9.9 9.9 0 0 1-1.053 2.517 8 8 0 0 1-.565.834l-.007.009-.021.026-.004.004-.001.001a1 1 0 0 0-.224.471 5.998 5.998 0 0 1-8.83-1.926M27.98 12.78q-.002 0-.026-.006a.1.1 0 0 1 .026.006"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M17.914 28.855c-.212-.422-.473-.943-.914-.842-5.404 1.23-11 4.782-11 8.557V42h36v-5.43c0-2.974-3.472-5.808-7.587-7.48l-.014-.027-.005-.01-.033.016c-1.093-.44-2.231-.8-3.361-1.056-.503-.115-1.023.577-1.25 1.01H18zm13.489 1.321q.656.181 1.301.407c.012.342-.014.745-.07 1.157a8 8 0 0 1-.272 1.26H31a1 1 0 0 0-.894.553l-1 2A1 1 0 0 0 29 36v2a1 1 0 0 0 1 1h2v-2h-1v-.764L31.618 35h2.764L35 36.236V37h-1v2h2a1 1 0 0 0 1-1v-2a1 1 0 0 0-.106-.447l-1-2A1 1 0 0 0 35 33h-.566a11 11 0 0 0 .248-1.608c.975.46 1.881.988 2.666 1.56C39.27 34.356 40 35.668 40 36.57V40H8v-3.43c0-.903.73-2.215 2.652-3.617.966-.705 2.119-1.343 3.355-1.871.013.31.043.624.084.928.069.5.172.998.297 1.426l.008.028a3 3 0 1 0 1.956-.444l-.044-.144a8 8 0 0 1-.235-1.136 7 7 0 0 1-.07-1.171q.005-.126.015-.224.18-.056.36-.107l.415.786h14.164zM16 37.015c.538 0 1-.44 1-1.015 0-.574-.462-1.015-1-1.015s-1 .44-1 1.015c0 .574.462 1.015 1 1.015"
      clipRule="evenodd"
    />
  </svg>
);
export default IconDoctorFemale;
