import * as React from "react";
const IconBabyMale0306m = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M17 6a1 1 0 1 0 0 2h1.586l-3.11 3.11a6 6 0 1 0 1.414 1.414L20 9.414V11a1 1 0 1 0 2 0V7a1 1 0 0 0-1-1zm-5 14a4 4 0 1 0 0-8 4 4 0 0 0 0 8M42 22.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0m-2 0a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0M30.66 30.03a5.9 5.9 0 0 0-2.942-.767h-5.271l.054 1.052c.262 5.029.393 7.71.459 9.135a160 160 0 0 1 .063 1.528l.001.028V42h4.392c1.697-.003 3.03-.719 4.126-1.666.097.141.201.29.306.43.337.446.994 1.236 2.011 1.236h5C40.73 42 42 40.277 42 38.685c0-1.754-1.418-3.315-3.26-3.315h-2.648c-.814-.409-1.483-1.196-2.299-2.238l-.154-.197c-.776-.994-1.706-2.187-2.978-2.905m-.982 1.742a3.9 3.9 0 0 0-1.951-.509h-3.174A1479 1479 0 0 1 24.986 40h2.427c1.358-.002 2.437-.703 3.526-1.859l.715-.76.729.748c.227.233.427.516.588.75l.134.197c.119.175.224.33.339.482.267.354.4.426.426.44l.005.002h4.985c.543 0 1.14-.592 1.14-1.315 0-.722-.594-1.315-1.26-1.315h-3.07l-.188-.08c-1.443-.613-2.442-1.877-3.264-2.925l-.068-.087c-.856-1.094-1.559-1.991-2.472-2.506M14.2 32.4 12.25 35H9.5a3.5 3.5 0 1 0 0 7H14a3.5 3.5 0 0 0 2.8-1.4l3-4a3.5 3.5 0 1 0-5.6-4.2m1.6 1.2L13.25 37H9.5a1.5 1.5 0 0 0 0 3H14a1.5 1.5 0 0 0 1.2-.6l3-4a1.5 1.5 0 0 0-2.4-1.8"
      clipRule="evenodd"
    />
  </svg>
);
export default IconBabyMale0306m;
