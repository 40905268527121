import * as React from "react";
const IconDomain = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M7.7 36.8c-.9 0-1.7-.4-2.2-1.1l-3.4-4.9c-.7-1-.4-2.4.6-3.1s2.4-.4 3.1.6L7.6 31l1.5-2.2c.5-.7 1.3-1.1 2.2-1.1s1.7.4 2.2 1.1L15 31l1.8-2.7c.7-1 2.1-1.3 3.1-.6s1.3 2.1.6 3.1l-3.4 4.9c-.5.7-1.3 1.1-2.2 1.1s-1.7-.4-2.2-1.1l-1.5-2.2-1.5 2.2c-.3.7-1.1 1.1-2 1.1M28.3 36.8c-.9 0-1.7-.4-2.2-1.1l-3.4-4.9c-.7-1-.4-2.4.6-3.1s2.4-.4 3.1.6l1.8 2.7 1.5-2.2c.5-.7 1.3-1.1 2.2-1.1s1.7.4 2.2 1.1l1.5 2.2 1.8-2.7c.7-1 2.1-1.3 3.1-.6s1.3 2.1.6 3.1l-3.4 4.9c-.5.7-1.3 1.1-2.2 1.1s-1.7-.4-2.2-1.1L32 33.4l-1.5 2.2c-.5.8-1.3 1.2-2.2 1.2M56.3 36.8c-.9 0-1.7-.4-2.2-1.1l-1.5-2.2-1.5 2.2c-.5.7-1.3 1.1-2.2 1.1s-1.7-.4-2.2-1.1l-3.4-4.9c-.7-1-.4-2.4.6-3.1s2.4-.4 3.1.6l1.8 2.7 1.5-2.2c.5-.7 1.3-1.1 2.2-1.1s1.7.4 2.2 1.1l1.5 2.2 1.8-2.7c.7-1 2.1-1.3 3.1-.6s1.3 2.1.6 3.1l-3.4 4.9c-.3.7-1.1 1.1-2 1.1m-5.2-5.6" />
  </svg>
);
export default IconDomain;
