import * as React from "react";
const IconEnvelopeOpenText = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m60.5 23.5-6.7-4.2v-.7c0-2.8-2.2-5-5-5h-4.3l-.3-.3L32.5 6c-.3-.2-.7-.2-1.1 0l-12.2 7.6h-4.1c-2.8 0-5 2.2-5 5v.7l-6.7 4.2c-.2.2-.4.5-.4.9v28.4c0 3 2.4 5.4 5.4 5.4h47.2c3 0 5.4-2.4 5.4-5.4V24.4c0-.4-.2-.7-.5-.9M59 24.9v4.2L53.8 31v-9.3zM32 8l8.9 5.6H23.1zm-16.8 7.6h33.6c1.7 0 3 1.3 3 3v13.1l-20.2 7.5-19.4-7.1V18.6c0-1.6 1.3-3 3-3m-5 6.1v9.7L5 29.5v-4.6zm45.4 34.4H8.4c-1.9 0-3.4-1.5-3.4-3.4v-21l26.2 9.6c.1 0 .2.1.3.1s.2 0 .3-.1l27.1-10v21.5c.1 1.8-1.4 3.3-3.3 3.3" />
    <path d="M23.3 24.2h17.4c.6 0 1-.4 1-1s-.4-1-1-1H23.3c-.6 0-1 .4-1 1s.5 1 1 1M23.3 31.4h17.4c.6 0 1-.4 1-1s-.4-1-1-1H23.3c-.6 0-1 .4-1 1s.5 1 1 1" />
  </svg>
);
export default IconEnvelopeOpenText;
