import * as React from "react";
const IconCupcakeAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m51.8 18-.2-.2c-1.5-1.4-4.2-3.4-9.9-5.5-1-.4-2.2-.7-3.5-.9l.1-.1c1-1 1.6-2.4 1.6-3.9s-.6-2.8-1.6-3.9c-1.7-1.7-4.3-2.1-6.4-1-.7-.3-1.6-.5-2.4-.5h-.1c-1.4 0-2.6.5-3.7 1.4-1.1 1-1.7 2.3-1.7 3.8 0 1.6.6 3.1 1.7 4.2l.2.2c-1.3.2-2.5.5-3.6.8-5.3 1.5-8.9 4.4-9 4.5C8 21.1 5 26.1 4.5 31.7v.2c-.2 1.2-.1 2.5 0 2.6 0 .2 2.8 16.8 4.1 23.7.4 2.1 2.2 3.7 4.4 3.7h38.2c2.2 0 4-1.6 4.4-3.7l4.1-23.6v-.1c.3-9.2-5.9-14.7-7.9-16.5M27.1 4.9c.6-.6 1.4-.9 2.3-.9h.1c.7 0 1.4.2 1.9.6.3.2.8.2 1.1 0 1.4-1 3.2-.8 4.4.4.7.7 1 1.5 1 2.5 0 .9-.4 1.8-1 2.4L32 14.8c-.1.1-.2.1-.2 0l-4.7-4.7c-.7-.7-1.1-1.7-1.1-2.8 0-.9.4-1.8 1.1-2.4M6.4 32.2v-.3c.4-5.1 3.2-9.6 8.1-13.3 0 0 3.4-2.7 8.3-4.1 1.2-.3 2.5-.6 4-.9.2 0 .4-.2.6-.3l2.9 2.9c.4.4 1 .6 1.5.6s1.1-.2 1.5-.6l3.2-3.2c.2.1.3.3.6.3q2.25.3 3.9.9c5.4 1.9 7.8 3.8 9.2 5.1l.2.2c1.8 1.6 7.4 6.6 7.1 14.9l-.1.8c-.6 1.8-3.7 5.7-9 5.7-4.9 0-7.1-3.3-7.2-3.5-.2-.3-.5-.5-.8-.5s-.7.2-.8.5c0 0-2.2 3.4-7.5 3.5-5.2.1-7.4-3.3-7.5-3.5-.2-.3-.5-.5-.8-.5s-.7.1-.9.4c-2.6 3.6-6.9 3.5-7 3.5-9.2-1-9.4-6.6-9.4-6.9-.1-.4-.1-1.1-.1-1.7m47.1 25.7c-.2 1.2-1.2 2.1-2.5 2.1H12.9c-1.2 0-2.2-.9-2.4-2-.8-4.4-2.2-12.9-3.2-18.4 1.7 1.5 4.3 2.8 8.4 3.3.2 0 4.7.1 8-3.4 1.2 1.3 3.8 3.4 8.1 3.4h.3c4.4-.1 7.1-2.1 8.3-3.4 1.2 1.3 3.8 3.4 8.1 3.4 3.5 0 6.3-1.4 8.3-3.2z" />
    <path d="M17.8 47.7c-.2-.5-.8-.7-1.3-.5s-.7.8-.4 1.3l2.4 4.8q.3.6.9.6c.1 0 .3 0 .4-.1.5-.2.7-.8.4-1.3zM32 47c-.6 0-1 .4-1 1v5c0 .6.4 1 1 1s1-.4 1-1v-5c0-.6-.4-1-1-1M47.5 47.2c-.5-.2-1.1 0-1.3.5l-2.4 4.8c-.2.5 0 1.1.5 1.3.1.1.3.1.4.1q.6 0 .9-.6l2.4-4.8c.2-.4 0-1-.5-1.3" />
  </svg>
);
export default IconCupcakeAlt2;
