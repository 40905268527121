import * as React from "react";
const IconApartmentsAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 13.7H15.8c-.6 0-1 .4-1 1s.4 1 1 1H32c.6 0 1-.4 1-1s-.4-1-1-1M32 27.5H15.8c-.6 0-1 .4-1 1s.4 1 1 1H32c.6 0 1-.4 1-1s-.4-1-1-1" />
    <path d="M55.9 8.3H44.8V6.5c0-2.8-2.3-5.1-5.1-5.1H8.1C5.3 1.4 3 3.7 3 6.5v50.9c0 2.8 2.3 5.1 5.1 5.1h31.5c2.8 0 5.1-2.3 5.1-5.1v-2.1h11.1c2.8 0 5.1-2.3 5.1-5.1V13.4c.1-2.8-2.2-5.1-5-5.1M31 60.6H16.8V46.5c0-1.7 1.4-3.1 3.1-3.1h8c1.7 0 3.1 1.4 3.1 3.1zm11.8-3.1c0 1.7-1.4 3.1-3.1 3.1H33V46.5c0-2.8-2.3-5.1-5.1-5.1h-8c-2.8 0-5.1 2.3-5.1 5.1v14.1H8.1c-1.7 0-3.1-1.4-3.1-3.1v-51c0-1.7 1.4-3.1 3.1-3.1h31.5c1.7 0 3.1 1.4 3.1 3.1v51zM59 50.2c0 1.7-1.4 3.1-3.1 3.1H44.8V39.7H51c.6 0 1-.4 1-1s-.4-1-1-1h-6.3V25.9H51c.6 0 1-.4 1-1s-.4-1-1-1h-6.3V10.3h11.1c1.7 0 3.1 1.4 3.1 3.1v36.8z" />
  </svg>
);
export default IconApartmentsAlt2;
