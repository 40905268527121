import * as React from "react";
const IconZoomInAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60 58.6 41.4 40c7.7-8.5 7.5-21.7-.7-29.9-4.1-4.1-9.5-6.4-15.3-6.4S14.2 6 10.1 10.1c-8.4 8.5-8.4 22.2 0 30.7 4.1 4.1 9.5 6.4 15.3 6.4 5.4 0 10.6-2 14.6-5.7L58.6 60c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4M11.5 39.3c-7.7-7.7-7.7-20.2 0-27.8 3.7-3.7 8.7-5.8 13.9-5.8 5.3 0 10.2 2 13.9 5.8 7.7 7.7 7.7 20.2 0 27.8-3.7 3.7-8.6 5.8-13.9 5.8S15.2 43 11.5 39.3" />
    <path d="M31.3 24.4h-4.9v-4.9c0-.6-.4-1-1-1s-1 .4-1 1v4.9h-4.9c-.6 0-1 .4-1 1s.4 1 1 1h4.9v4.9c0 .6.4 1 1 1s1-.4 1-1v-4.9h4.9c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconZoomInAlt;
