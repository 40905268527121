import * as React from "react";
const IconBeach = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.5 60.1c-15.1-8-27.2-10-36.5-9.3.1-.2.1-.3.1-.5l-2.9-21.1c0-.2-.2-.4-.3-.6.6.1 1.2.3 1.7.7 2.4 1.9 5.6 1.5 7.5-.8 1.2-1.5 3.2-1.7 4.7-.5l3.4 2.7c.3.3.8.3 1.1.1.4-.2.6-.6.5-1-.6-5.2-3.1-9.8-7.2-13.1-3.8-3.1-8.6-4.5-13.4-4.2v-.2l-.3-3.1c-.1-.5-.6-.9-1.1-.9-.5.1-.9.6-.9 1.1l.3 3.1c0 .1 0 .1.1.2-10.1 1.8-17.2 11-16.2 21.2 0 .3.1.7.4.9.1.1.2.1.3.2h.4c.3 0 .6-.3.8-.5l2.6-3.2c1.2-1.5 3.2-1.7 4.7-.5 2.4 1.9 5.6 1.5 7.5-.9.4-.5.9-.9 1.5-1.1-.1.2-.2.4-.2.7L23 50.6c0 .2.1.3.2.4-14 1.6-20.9 9.2-21 9.3-.4.4-.3 1 .1 1.4s1 .3 1.4-.1c.2-.2 18.5-20.2 56.8.2.2.2.3.2.5.2.4 0 .7-.2.9-.5.2-.5.1-1.1-.4-1.4M17.3 28.7c-1.2 1.5-3.2 1.7-4.7.5-2.4-1.9-5.6-1.6-7.5.8l-1 1.3c.3-8.5 6.8-15.7 15.5-16.7.7-.1 1.3-.1 2-.1 3.9 0 7.7 1.3 10.8 3.8 3 2.4 5 5.6 6 9.2l-1.3-1c-2.4-1.9-5.6-1.5-7.5.8-1.2 1.5-3.2 1.7-4.7.5-2.5-1.9-5.7-1.5-7.6.9M53.1 19.8c4.9 0 8.9-4 8.9-8.9S58 2 53.1 2s-8.9 4-8.9 8.9 4 8.9 8.9 8.9m0-15.8c3.8 0 6.9 3.1 6.9 6.9s-3.1 6.9-6.9 6.9-6.9-3.1-6.9-6.9S49.3 4 53.1 4" />
  </svg>
);
export default IconBeach;
