import * as React from "react";
const IconCapitalLetter = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m8.4 25.8 1.5-3.6h10.8l1.3 3.6c.2.6.7.7 1.2.7h3.3c1.1-.1 1.5-.9 1.2-1.7L18.9 4.2c-.2-.6-.4-.7-1-.7h-4.7c-.4 0-1 .2-1.2.7L3.1 25c-.4.7.2 1.6 1 1.6h3.1c.7-.1 1.1-.5 1.2-.8m6.9-16 3.1 8.1h-6.3zM36.1 6.8H61c.6 0 1-.4 1-1s-.4-1-1-1H36.1c-.6 0-1 .4-1 1s.4 1 1 1M61 23H36.1c-.6 0-1 .4-1 1s.4 1 1 1H61c.6 0 1-.4 1-1s-.4-1-1-1M61 41.3H5.4c-.6 0-1 .4-1 1s.4 1 1 1H61c.6 0 1-.4 1-1s-.4-1-1-1M61 59.5H5.4c-.6 0-1 .4-1 1s.4 1 1 1H61c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconCapitalLetter;
