import * as React from "react";
const IconGrowCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.1 46.2c-1.1-3.6-4.7-5.6-8.1-4.6-4.3 1.3-10.5 3.1-14.1 4.1 1.3-2 1.2-3.4.8-4.4-.7-1.9-2.9-2.7-4.7-2.7H22c-1.1 0-2.3-.5-3.4-1.5-.9-.8-2-1.3-3.2-1.3H7.3c-2.9 0-5.3 2.5-5.3 5.6v12.9C2 57.4 4.4 60 7.4 60h9.7c1.2 0 2.4-.4 3.4-1.3l.7.4c2.7 1.5 5.7 2.3 8.8 2.3 1.9 0 3.7-.3 5.4-.8L53.6 55c4.6-1.8 5.3-5.7 4.5-8.8M7.4 58C5.5 58 4 56.3 4 54.3V41.4c0-2 1.5-3.6 3.3-3.6h8.1c.7 0 1.4.3 1.9.7.4.4.9.7 1.4 1h-.1v18c-.5.2-.9.5-1.4.5zM53 53.1l-18.1 5.5c-1.5.5-3.1.7-4.8.7-2.7 0-5.4-.7-7.8-2l-1.3-.7-.3-.1v-16c.5.1.9.2 1.4.2h10c1.1 0 2.4.5 2.8 1.4.4 1.1-.5 2.8-2.5 4.7l-.6.5.4.7c.4.8.4.8 9.8-2 4.3-1.3 8.6-2.5 8.6-2.5 2.3-.7 4.8.8 5.6 3.3.4 1.4.8 4.8-3.2 6.3M61.9 10.3c-.1-1.1-1-1.9-2-2-2.3-.2-6.7 0-9.6 2.9-1.4 1.4-2.4 3.4-3 5.3.2-2.1.2-6.9-2.7-10.2-2.8-3.1-7.4-3.6-9.9-3.7-1.1 0-2 .9-2.1 2.1-.2 2.7-.1 7.7 2.6 10.8 2.2 2.5 5.7 3 8.2 3 1.6 0 2.7-.2 3-.2v15.8c0 .6.4 1 1 1s1-.4 1-1v-10h.6c2.6 0 7.4-.4 10.3-3.3 2.9-3 2.8-8.3 2.6-10.5m-16.7 6.1c-1.7.2-6.3.4-8.6-2.2s-2.3-7.4-2.1-9.3c0-.2.1-.2.1-.2 1.8 0 6.1.4 8.5 3s2.2 7 2.1 8.7m12.5 3.1c-2.6 2.6-7.6 2.7-9.4 2.7.1-1.8.8-6.9 3.4-9.5 2.5-2.5 6.5-2.5 8.1-2.3.1 0 .2.1.2.2.1 1.7.2 6.3-2.3 8.9" />
  </svg>
);
export default IconGrowCopy;
