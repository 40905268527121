import * as React from "react";
const IconBaloonAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.7 20.7C50.7 10.4 42.3 2 32 2s-18.7 8.4-18.7 18.7c0 21 14.2 26.4 17.7 27.4V61c0 .6.4 1 1 1s1-.4 1-1V48.1c3.6-1 17.7-6.3 17.7-27.4M32 46.3c-1.9-.4-16.7-4.3-16.7-25.6C15.3 11.5 22.8 4 32 4s16.7 7.5 16.7 16.7C48.7 42 33.9 45.9 32 46.3" />
    <path d="M36.2 11.9c-.4.4-.4 1-.1 1.4 1 1.1 3.4 4.5 2.9 7.2-.2 1.4-1.1 2.4-2.7 3.3-.5.3-.7.9-.4 1.4.2.3.5.5.9.5.2 0 .3 0 .5-.1 2.1-1.2 3.3-2.8 3.7-4.7.7-4.3-3.2-8.7-3.4-8.9-.4-.4-1-.4-1.4-.1" />
  </svg>
);
export default IconBaloonAlt2;
