import * as React from "react";
const IconWeightMachine = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.3 2H10.7C5.9 2 2 5.9 2 10.7v42.5c0 4.8 3.9 8.7 8.7 8.7h42.5c4.8 0 8.7-3.9 8.7-8.7V10.7C62 5.9 58.1 2 53.3 2m-9.5 2v7.4c0 3-2.4 5.4-5.4 5.4h-6.2l3.1-5.4c.3-.5.1-1.1-.4-1.4s-1.1-.1-1.4.4l-3.6 6.4h-4.4c-3 0-5.4-2.4-5.4-5.4V4zM60 53.3c0 3.7-3 6.7-6.7 6.7H10.7C7 60 4 57 4 53.3V10.7C4 7 7 4 10.7 4h7.5v7.4c0 4.1 3.3 7.4 7.4 7.4h12.8c4.1 0 7.4-3.3 7.4-7.4V4h7.5C57 4 60 7 60 10.7z" />
  </svg>
);
export default IconWeightMachine;
