import React, { useState, useEffect } from 'react';
import { useNavigation } from '../../context/NavigationContext';
import {
	BringzzPageContainer,
	BringzzIcon,
	BringzzText,
	BringzzBottomDrawer,
	BringzzRadioList,
	BringzzRadio,
	BringzzCheckbox
} from '@bringzz/components';
import useRequest from 'hooks/useRequest';
import { useAuth } from 'context/AuthContext';

function formatDate(dateString) {
	const date = new Date(dateString);
	const day = String(date.getDate()).padStart(2, '0');
	const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
	const year = date.getFullYear();

	return `${day}.${month}.${year}`;
}

const AccountNamePage = () => {
	const { backPress, navigate } = useNavigation();
	const { user, refreshUserData } = useAuth()

	const { sendRequest, data, error, loading } = useRequest();

	const consumerFields = [
		{ name: 'Name', value: `${user.firstName} ${user.lastName}`, path: 'account/name' },
		{ name: 'Email', value: user.emailAddress, path: 'account/email' },
		{
			name: 'Mobile Number',
			value: `${user.phoneNumber}`,
			path: 'account/mobile'
		},
		{ name: 'SV-Nr', value: user.stateInsuranceId, path: 'account/svnr' },
		{
			name: 'Private insurance',
			value: `${user.privateInsuranceCompanyname} | ${user.privateInsuranceId}`,
			path: 'account/insurance'
		},
		{
			name: 'Doctor verification',
			value: `${user.doctorType} | ID: ${user.doctorId}\nUID: ${user.doctorUid}`,
			path: 'account/doctor'
		}
	];

	const pharmacistFields = [
		{
			name: 'Name',
			value: `${user.firstName} ${user.lastName} ${user.birthday}`,
			path: 'account/name'
		},
		{ name: 'Email', value: user.emailAddress, path: 'account/email' },
		{
			name: 'Mobile Number',
			value: `${user.phoneNumber}`,
			path: 'account/mobile'
		},
		{ name: 'Bio', value: user.description, path: 'account/bio' }
	];

	const shopperFields = [
		{
			name: 'Name',
			value: `${user.firstName} ${user.lastName}\n${user.birthday}`,
			path: 'account/name'
		},
		{ name: 'Email', value: user.emailAddress, path: 'account/email' },
		{
			name: 'Mobile Number',
			value: `${user.phoneNumber}`,
			path: 'account/mobile'
		},
		{
			name: 'Address',
			value: 'Musterstastrsese 10, 1040 Wien',
			path: 'address'
		},
		{ name: 'ID-Verification', value: 'Passport | P123456789', path: 'id' }
	];

	let state = {
		checked: <div></div>,
		unChecked: <div></div>
	};

	const [fields, setFields] = useState([]);
	const [pickerDrawerOpened, setPickerDrawerOpened] = useState(null);
	const [profileImage, setProfileImage] = useState(user.userPic || null);
	const [hide, setHide] = useState(user.hideProfile || false);

	useEffect(() => {
		if (user) {
			switch (user.userRole) {
				case 1:
					setFields(pharmacistFields);
					break;
				case 4:
					setFields(shopperFields);
					break;
				case 5:
					setFields(consumerFields);
					break;
				default:
					setFields([]);
					break;
			}
		}
	}, [user]);

	const postImage = async (base64Image) => {
		// Post base64Image to the server (this is a placeholder function)
		const result = await sendRequest({
			method: 'POST',
			url: `/module/profile/changeProfilePicture`,
			data: {
				base64Image
			}
		})
			.then(response => {
				if (response.status === 'success') {
					return response
				};
			})
			.catch(error => {
				console.error(error);
				return false;
			});

		if (result) refreshUserData()
	};

	const handlePickerClick = async choice => {
		try {
			let base64Image = '';

			if (choice === 'camera') {
				// Handling camera input
				const stream = await navigator.mediaDevices.getUserMedia({
					video: true
				});
				const video = document.createElement('video');
				video.srcObject = stream;
				video.play();

				const canvas = document.createElement('canvas');
				const context = canvas.getContext('2d');
				video.onloadedmetadata = () => {
					canvas.width = video.videoWidth;
					canvas.height = video.videoHeight;
					context.drawImage(video, 0, 0, canvas.width, canvas.height);
					base64Image = canvas.toDataURL('image/jpeg');
					// Stop the video stream
					stream.getTracks().forEach(track => track.stop());
					setProfileImage(base64Image);
					postImage(base64Image)
				};
			} else if (choice === 'photos') {
				// Handling gallery input
				const input = document.createElement('input');
				input.type = 'file';
				input.accept = 'image/*';
				input.onchange = async event => {
					const file = event.target.files[0];
					const reader = new FileReader();
					reader.onloadend = () => {
						base64Image = reader.result;
						setProfileImage(base64Image);
						postImage(base64Image)
					};
					reader.readAsDataURL(file);
				};
				input.click();
			} else if (choice === 'file') {
				// Handling file input
				const input = document.createElement('input');
				input.type = 'file';
				input.accept = 'image/jpeg,image/png';
				input.onchange = async event => {
					const file = event.target.files[0];
					const reader = new FileReader();
					reader.onloadend = () => {
						base64Image = reader.result;
						setProfileImage(base64Image);
						postImage(base64Image)
					};
					reader.readAsDataURL(file);
				};
				input.click();
			}
		} catch (error) {
			console.error('Error handling picker choice:', error);
		}
	};

	return (
		<BringzzPageContainer
			header={{
				left: (
					<BringzzIcon
						size='22'
						icon='IconChevronLeft'
						folder='LineIcons'
						className='cursor-pointer'
						onClick={backPress}
					/>
				),
				title: <BringzzText tag='h2'>My Account</BringzzText>
			}}
		>
			<div className='p-4 divide-y divide-solid'>
				{user && user.userRole !== 4 && (
					<div className='mt-4 mb-8 flex justify-center'>
						<div className='space-y-4 relative'>
							<div
								className='flex justify-center text-white font-bold text-4xl items-center rounded-full bg-midnight-blue h-44 w-44'
								style={{
									backgroundImage: profileImage ? `url(${profileImage})` : '',
									backgroundSize: 'cover',
									backgroundPosition: 'center'
								}}
							>
								{!profileImage && user && user.firstName.charAt(0)}
							</div>
							<div
								className='bg-magic-lilac h-12 w-12 absolute bottom-0 rounded-full right-2'
								onClick={() => setPickerDrawerOpened(true)}
							>
								<BringzzIcon
									size='24'
									icon='IconPlus'
									folder='LineIcons'
									className='cursor-pointer flex h-full flex-col items-center justify-center'
								/>
							</div>
						</div>
					</div>
				)}
				{fields.map((field, index) => (
					<div
						key={index}
						className='flex items-center justify-between py-4 px-2 cursor-pointer'
						onClick={() => field.path && navigate(`settings/${field.path}`)}
					>
						<div className='flex flex-col'>
							<BringzzText tag='h3'>{field.name}</BringzzText>
							<div className='w-64'>
								<BringzzText
									tag='h3'
									className='text-normal text-black/50 whitespace-pre-line truncate'
								>
									{field.value}
								</BringzzText>
							</div>
						</div>
						<BringzzIcon size='18' icon={'IconPencil'} folder={'LineIcons'} />
					</div>
				))}
				{user && user.userRole === 1 && (
					<div className='px-2 py-4'>
						<BringzzCheckbox
							id='example-checkbox2'
							label='Hide my profile (name + photo) on team page'
							checked={hide}
							onChange={hideChecked =>
								setHide(() => {
									sendRequest({
										method: 'POST',
										url: `/module/profile/changeHideProfile`,
										data: { hideProfile: hideChecked }
									})
										.then(response => {
											if (response.status === 'success') {
												refreshUserData()
												return hideChecked
											};
										})
										.catch(error => {
											console.log(error);
											return hideChecked;
										});
								})
							}
						/>
					</div>
				)}
			</div>

			<BringzzBottomDrawer
				isOpen={pickerDrawerOpened}
				title={'Update your Profile Picture'}
				close={() => setPickerDrawerOpened(false)}
			>
				<div className='px-4 py-6'>
					<BringzzRadioList
						state={state}
						className='rounded-xl w-full text-center'
						onChange={choice => {
							handlePickerClick(choice);
							setPickerDrawerOpened(false);
						}}
					>
						<BringzzRadio
							value='camera'
							className='p-2 py-4 hover:bg-slate- border-1 border-t border-gray-400 flex'
						>
							<div>Camera</div>
						</BringzzRadio>
						<BringzzRadio
							value='photos'
							className='p-2 py-4 hover:bg-slate- border-1 border-t border-gray-400'
						>
							<div>Photos</div>
						</BringzzRadio>
						<BringzzRadio
							className='p-2 py-4 hover:bg-slate- border-1 border-t border-gray-400'
							value='file'
						>
							<div>File</div>
						</BringzzRadio>
					</BringzzRadioList>
				</div>
			</BringzzBottomDrawer>
		</BringzzPageContainer>
	);
};

export default AccountNamePage;
