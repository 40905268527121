import * as React from "react";
const IconChefHatAlt3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62 26.2c0-6.5-5.1-11.8-11.4-11.8-2 0-4 .5-5.7 1.6v-.2c0-7.4-5.8-13.5-13-13.5s-13 6-13 13.5v.1c-1.7-.9-3.5-1.4-5.4-1.4C7.1 14.3 2 19.6 2 26.2 2 32.3 6.6 37.4 12.4 38v12.3h-.6c-2.4 0-4.3 1.9-4.3 4.3v3c0 2.4 1.9 4.3 4.3 4.3h40.4c2.4 0 4.3-1.9 4.3-4.3v-3c0-2.4-1.9-4.3-4.3-4.3h-.6V38c5.8-.6 10.4-5.7 10.4-11.8m-7.5 28.3v3c0 1.3-1 2.3-2.3 2.3H11.8c-1.3 0-2.3-1-2.3-2.3v-3c0-1.3 1-2.3 2.3-2.3h40.4c1.2 0 2.3 1 2.3 2.3m-13.2-4.3 1.7-5.6c.2-.5-.1-1.1-.7-1.2-.5-.2-1.1.1-1.2.7l-1.9 6.1H33v-6.9c0-.6-.4-1-1-1s-1 .4-1 1v6.9h-6.3l-1.9-6.1c-.2-.5-.7-.8-1.2-.7-.5.2-.8.7-.7 1.2l1.7 5.6h-8.2V37c0-.6-.4-1-1-1C8.2 36 4 31.6 4 26.2s4.2-9.8 9.4-9.8c2.2 0 4.2.8 5.9 2.2.3.3.8.3 1.1.1s.6-.6.5-1c-.1-.6-.2-1.2-.2-1.9 0-6.3 4.9-11.5 11-11.5s11 5.1 11 11.5c0 .8-.1 1.5-.2 2.2-.1.4.1.9.5 1.1s.8.2 1.2-.1c1.7-1.6 3.9-2.5 6.2-2.5 5.2 0 9.4 4.4 9.4 9.8s-4.2 9.8-9.4 9.8c-.6 0-1 .4-1 1v13.2h-8.1z" />
  </svg>
);
export default IconChefHatAlt3;
