import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import BringzzText from '../BringzzText';

const BringzzProgressBarCard = ({ img, text }) => {
    return (
        <div className='flex flex-col text-center h-36 border border-black rounded-lg mt-6'>
            <div className='flex justify-center'>
                <img className='h-24 w-24' src={img} alt='progress img'></img>
            </div>
            <BringzzText tag='h4' className={"font-normal"}>{text}</BringzzText>
        </div>
    );
};

const BringzzProgressBar = ({ state, segments, mode = "default" }) => {
    const visibleSegments = segments.filter(segment => !segment.hidden);
    let adjustedStateIndex = 0;
    for (let i = 0; i < state; i++) {
        if (!segments[i].hidden) {
            adjustedStateIndex++;
        }
    }

    if (!visibleSegments[adjustedStateIndex - 1]) {
        console.error('Invalid state provided');
        return false;
    }

    const activeSegment = segments[state - 1];

    return (
        <div>
            <div className="flex w-full bg-gray-200 rounded-full h-8 overflow-hidden bg-gradient-to-r from-[#CBA9FE] to-magic-lilac">
                {visibleSegments.map((segment, index) => {
                    console.log(`${index} => ${state}`);
                    return (
                        <div
                            key={index}
                            className={classnames(
                                'flex h-full relative items-center justify-center',
                                {
                                    'bg-midnight-blue text-white': index < adjustedStateIndex,
                                    'rounded-r-full': state - 1 < index,
                                    // '!rounded-none': index !== state - 1,
                                }
                            )}
                            style={{ width: `${100 / visibleSegments.length}%` }}
                        >
                            <BringzzText tag="h4" className="text-white font-bold">{index + 1}</BringzzText>
                            {index < visibleSegments.length - 1 && (
                                <div
                                    className="absolute right-0 top-1/2 transform -translate-y-1/2 -translate-x-1/2 w-1 h-1 bg-white rounded-full"
                                />
                            )}
                        </div>
                    )
                })}
            </div>
            {mode === "default" && <BringzzProgressBarCard img={activeSegment.img} text={activeSegment.text} />}
            {mode === "custom" && activeSegment.content}
        </div>
    );
};

BringzzProgressBar.propTypes = {
    state: PropTypes.number.isRequired,
    segments: PropTypes.arrayOf(PropTypes.shape({
        img: PropTypes.any.isRequired,
        text: PropTypes.string.isRequired,
        hidden: PropTypes.bool
    })).isRequired
};

export default BringzzProgressBar;
