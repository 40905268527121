import * as React from "react";
const IconUserMedical = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M27.6 2C21 2 15.5 7.4 15.5 14.1c0 6.6 5.4 12.1 12.1 12.1s12.1-5.4 12.1-12.1C39.6 7.4 34.2 2 27.6 2m0 22.1c-5.5 0-10.1-4.5-10.1-10.1 0-5.5 4.6-10 10.1-10s10.1 4.5 10.1 10.1c-.1 5.5-4.6 10-10.1 10M36.6 33.6H15.5c-4.9 0-8.9 4-8.9 8.9s4 8.9 8.9 8.9h14.1c.6 0 1-.4 1-1s-.4-1-1-1H15.5c-3.8 0-6.9-3.1-6.9-6.9s3.1-6.9 6.9-6.9h21.1c.6 0 1-.4 1-1s-.4-1-1-1M53.3 56.7 52 55.5c-.4-.4-.8-.7-1.1-1.1 1.4-1.4 2.8-2.9 4.1-4.6 1.9-2.6 2.7-5.3 2.2-7.8-.7-3.4-4-6.1-7.7-6.2-3.6.1-6.9 2.8-7.7 6.2-.5 2.4.3 5.1 2.2 7.7 1.3 1.7 2.7 3.2 4.1 4.6-.4.4-.8.7-1.1 1.1l-1.3 1.2-3.6 3.6c-.4.4-.4 1 0 1.4s1 .4 1.4 0l3.6-3.6 1.2-1.2 1.1-1.1 1.1 1.1 1.2 1.2 3.6 3.6c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4-1-1.1-2.2-2.3-3.4-3.5m-3.8-3.8c-1.3-1.4-2.7-2.8-3.9-4.4-.9-1.2-2.4-3.7-1.9-6.1.6-2.5 3-4.5 5.7-4.6 2.7.1 5.2 2 5.8 4.6.5 2.5-.9 4.9-1.9 6.1-1.1 1.6-2.5 3-3.8 4.4" />
  </svg>
);
export default IconUserMedical;
