import * as React from "react";
const IconArch = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 15.3h-3.6V11c0-2.4-2-4.4-4.4-4.4H11c-2.4 0-4.4 2-4.4 4.4v4.3H3c-.6 0-1 .4-1 1s.4 1 1 1h3.6v38.1H4.2c-.6 0-1 .4-1 1s.4 1 1 1h55.6c.6 0 1-.4 1-1s-.4-1-1-1h-2.4V17.3H61c.6 0 1-.4 1-1s-.4-1-1-1M8.6 11c0-1.3 1.1-2.4 2.4-2.4h42c1.3 0 2.4 1.1 2.4 2.4v4.3H8.6zm27.3 44.4H25.7V38.2c0-3.5 2.8-6.3 6.3-6.3s6.3 2.8 6.3 6.3v17.2h-2.4m19.5 0h-15V38.2c0-4.6-3.7-8.3-8.3-8.3s-8.3 3.7-8.3 8.3v17.2h-15V17.3h46.7v38.1z" />
  </svg>
);
export default IconArch;
