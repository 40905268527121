import * as React from "react";
const IconElectricity = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.7 43.5 36.8 13c-1.2-1.5-2.9-2.3-4.8-2.3s-3.6.8-4.8 2.3L3.3 43.5c-1.5 1.8-1.7 4.3-.7 6.4s3.1 3.4 5.5 3.4h47.8c2.3 0 4.4-1.3 5.5-3.4 1-2.1.7-4.6-.7-6.4M59.6 49c-.7 1.4-2.1 2.3-3.7 2.3H8.1c-1.6 0-3-.9-3.7-2.3s-.5-3.1.5-4.3l23.9-30.4c.8-1 2-1.6 3.2-1.6 1.3 0 2.4.6 3.2 1.6l23.9 30.4c1 1.2 1.2 2.9.5 4.3" />
    <path d="m30.9 34.2 4.4-4.2c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-5.2 4.9c-.2.2-.3.5-.3.7s.1.5.3.7l4.4 4.2-4.4 4.2c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l5.2-4.9c.2-.2.3-.5.3-.7s-.1-.5-.3-.7z" />
  </svg>
);
export default IconElectricity;
