import * as React from "react";
const IconUserAlt5 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M48.8 35c-4.4-5.2-10.4-8.1-16.8-8.1S19.6 29.7 15.2 35c-4.4 5.2-6.8 12.4-7 20.2q0 .6.6.9C11.5 57.5 21.4 62 32 62c11.5 0 20.7-4.5 23.3-5.9.3-.2.5-.5.5-.9-.1-7.9-2.6-15-7-20.2M32 60c-9.5 0-18.5-3.8-21.8-5.4.2-7.1 2.5-13.6 6.5-18.3 4-4.8 9.4-7.4 15.3-7.4 5.8 0 11.3 2.6 15.3 7.4 4 4.7 6.3 11.2 6.5 18.4C50.7 56.2 42.3 60 32 60M32 26.8c6.8 0 12.4-5.6 12.4-12.4S38.8 2 32 2 19.6 7.6 19.6 14.4 25.2 26.8 32 26.8M32 4c5.7 0 10.4 4.7 10.4 10.4S37.7 24.8 32 24.8s-10.4-4.7-10.4-10.4S26.3 4 32 4" />
  </svg>
);
export default IconUserAlt5;
