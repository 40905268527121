import * as React from "react";
const IconWalking = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M30.4 15.2c3.7 0 6.6-3 6.6-6.6S34.1 2 30.4 2s-6.6 3-6.6 6.6 3 6.6 6.6 6.6m0-11.2C32.9 4 35 6.1 35 8.6s-2.1 4.6-4.6 4.6-4.6-2.1-4.6-4.6S27.9 4 30.4 4M49.6 60H39.3v-8.3c0-.2-.1-.4-.2-.6l-6.8-10.3V33l8.1 3c.1 0 .2.1.3.1.4 0 .8-.3.9-.7.2-.5-.1-1.1-.6-1.3l-8.8-3.2V24c0-.4-.2-.8-.6-.9L27 21c-2-.9-4.3-.4-5.7 1.2l-1.9 2.1c-.2.2-.3.4-.3.7v10.6c0 .6.4 1 1 1s1-.4 1-1V25.4l1.6-1.8c.9-1 2.2-1.3 3.4-.7l4.2 1.9v17l-8.5 17.6c-.1.2-.1.5 0 .8h-7.4c-.6 0-1 .4-1 1s.4 1 1 1h35.2c.6 0 1-.4 1-1s-.4-1.2-1-1.2M31.7 43.5l5.7 8.6v8H23.7z" />
  </svg>
);
export default IconWalking;
