import * as React from "react";
const IconDead2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
    <path d="M24.1 27.9c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-3.1-3.1 3.1-3.1c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-3 3.1-3.1-3.1c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l3.1 3.1-3.1 3.1c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l3.1-3.1zM47.6 18.9c-.4-.4-1-.4-1.4 0L43.1 22 40 18.9c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l3.1 3.1-3.1 3.1c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l3.1-3.1 3.1 3.1c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-3.1-3.1 3.1-3.1c.3-.4.3-1 0-1.4M32 34c-6.6 0-12.2 4.7-13.5 11.1-.1.6 0 1.1.4 1.6s.9.7 1.5.7h23c.6 0 1.2-.3 1.5-.7.4-.4.5-1 .4-1.6C44.2 38.7 38.6 34 32 34M20.5 45.4C21.6 40 26.4 36 32 36s10.4 4 11.5 9.4z" />
  </svg>
);
export default IconDead2;
