import * as React from "react";
const IconBoxingGlove = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.2 2h-38c-2.8 0-5 2.2-5 5v8.8c0 1.1.4 2.2 1 3H9.8c-2.8 0-5 2.2-5 5v8.8c0 2.8 2.2 5 5 5h16.9c2.8 0 5-2.2 5-5v-8.8c0-1.1-.4-2.2-1-3h23.4c1.1 0 2.2-.4 3-1v21.8c0 4.9-4 8.9-8.9 8.9H22.1c-4.9 0-8.9-4-8.9-8.9v-3.9h-2v3.9c0 6 4.9 10.9 10.9 10.9H24V58c0 2.2 1.8 4 4 4h14.8c2.2 0 4-1.8 4-4v-5.5h1.5c6 0 10.9-4.9 10.9-10.9V7c0-2.8-2.3-5-5-5m-9.4 56c0 1.1-.9 2-2 2H28c-1.1 0-2-.9-2-2v-5.5h18.8zm-15-34.2v8.8c0 1.7-1.3 3-3 3h-17c-1.7 0-3-1.3-3-3v-8.8c0-1.7 1.3-3 3-3h16.9c1.7 0 3.1 1.4 3.1 3m27.4-8c0 1.7-1.3 3-3 3h-38c-1.7 0-3-1.3-3-3V7c0-1.7 1.3-3 3-3h38c1.7 0 3 1.3 3 3v8.8" />
  </svg>
);
export default IconBoxingGlove;
