import * as React from "react";
const IconIslandCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.1 21.1c4.9 0 8.9-4 8.9-8.9s-4-8.9-8.9-8.9-8.9 4-8.9 8.9c0 5 4 8.9 8.9 8.9m0-15.8c3.8 0 6.9 3.1 6.9 6.9s-3.1 6.9-6.9 6.9-6.9-3.1-6.9-6.9 3.1-6.9 6.9-6.9M61 58.7c-1.4 0-2-.7-2.9-1.7s-2.1-2.3-4.4-2.3-3.4 1.3-4.3 2.3c-.8.9-1.5 1.7-2.9 1.7s-2-.7-2.9-1.7-2.1-2.3-4.4-2.3-3.4 1.3-4.4 2.3c-.8.9-1.5 1.7-2.9 1.7s-2-.7-2.9-1.7-2.1-2.3-4.4-2.3-3.4 1.3-4.4 2.3c-.8.9-1.5 1.6-2.8 1.7l-.2-.4c0-.1-4.3-9.5-3-16.8.3-1.5.9-3.1 1.5-4.8s1.3-3.5 1.7-5.3c.1-.1.3-.3.3-.5.1-.5.3-1 .4-1.4 2.4.8 6.2 4.1 7.1 7.6.1.4.5.7 1 .7h.3c.5-.1.8-.7.7-1.2-1-3.8-4.9-7.7-8.2-8.9 2.7-4.2 8.7-6.5 15-5.3.5.1 1.1-.3 1.2-.8s-.3-1.1-.8-1.2c-7-1.3-13.6 1.3-16.9 5.9-.4-3.3-1.7-6.5-3.2-8.7s-3.2-3.5-4.8-3.8c-.5-.1-1.1.3-1.2.8s.3 1.1.8 1.2c1.1.2 2.3 1.3 3.5 3 1.3 2 2.6 4.9 2.9 7.9-3.7-2.9-9.1-3.3-13.3-.6-.5.3-.6.9-.3 1.4s.9.6 1.4.3c4-2.5 9.4-1.7 12.3 1.8 0 .4-.1.8-.2 1.2v.2c-.4 1.7-1 3.4-1.6 5.1-.7 1.8-1.3 3.5-1.6 5.1-1.1 5.9 1.1 12.8 2.4 16-.1-.1-.1-.2-.2-.2-.9-1-2.1-2.3-4.4-2.3S6.8 56 5.9 57c-.9.9-1.5 1.7-2.9 1.7-.6 0-1 .4-1 1s.4 1 1 1c2.3 0 3.4-1.3 4.4-2.3.8-.9 1.5-1.7 2.9-1.7s2 .7 2.9 1.7c.8.9 1.8 2 3.5 2.2.1 0 .2.1.3.1h.5c2.3 0 3.4-1.3 4.4-2.3.8-.9 1.5-1.7 2.9-1.7s2 .7 2.9 1.7 2.1 2.3 4.4 2.3 3.4-1.3 4.4-2.3c.8-.9 1.5-1.7 2.9-1.7s2 .7 2.9 1.7 2.1 2.3 4.4 2.3 3.4-1.3 4.4-2.3c.8-.9 1.5-1.7 2.9-1.7s2 .7 2.9 1.7 2.1 2.3 4.4 2.3c.6 0 1-.4 1-1s-.7-1-1.3-1" />
  </svg>
);
export default IconIslandCopy;
