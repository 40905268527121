import * as React from "react";
const IconHeartMonitor = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M42.3 17 39.8 5.6c-.5-2.3-2.5-3.8-4.8-3.8h-6c-2.3 0-4.3 1.6-4.8 3.8L21.6 17c-3.3.2-5.9 2.9-5.9 6.2v17.5c0 3.3 2.6 6 5.9 6.2l2.6 11.5c.5 2.3 2.5 3.8 4.8 3.8h6c2.3 0 4.3-1.6 4.8-3.8L42.3 47c3.3-.2 5.9-2.9 5.9-6.2V23.3c0-3.4-2.6-6.1-5.9-6.3M28.6 6.6c0-.2.2-.3.4-.3h6c.2 0 .4.1.4.3L37.7 17H26.2zM22 21.5h20c1 0 1.8.8 1.8 1.8v8.3l-7-4c-.7-.4-1.5-.4-2.2 0L27 31.9l-6.7-3.8v-4.8c-.1-1 .7-1.8 1.7-1.8m13.4 35.9c0 .2-.2.3-.4.3h-6c-.2 0-.4-.1-.4-.3L26.2 47h11.5zM42 42.5H22c-1 0-1.8-.8-1.8-1.8v-7.4l5.5 3.2c.7.4 1.5.4 2.2 0l7.6-4.3 8.2 4.7v3.9c.1.9-.7 1.7-1.7 1.7" />
  </svg>
);
export default IconHeartMonitor;
