import * as React from "react";
const IconFlower = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M49.6 20.8c-1.9 0-4.6.7-8.5 2.1 1.4-3.8 2.1-6.6 2.1-8.5 0-7-5-12.7-11.2-12.7S20.8 7.4 20.8 14.4c0 1.9.7 4.6 2.1 8.5-3.8-1.4-6.6-2.1-8.5-2.1-7 0-12.7 5-12.7 11.2s5.7 11.2 12.7 11.2c1.9 0 4.6-.7 8.5-2.1-1.4 3.8-2.1 6.6-2.1 8.5 0 7 5 12.7 11.2 12.7s11.2-5.7 11.2-12.7c0-1.9-.7-4.6-2.1-8.5 3.8 1.4 6.6 2.1 8.5 2.1 7 0 12.7-5 12.7-11.2s-5.7-11.2-12.7-11.2M26.7 32c0-2.9 2.4-5.3 5.3-5.3s5.3 2.4 5.3 5.3-2.4 5.3-5.3 5.3-5.3-2.4-5.3-5.3M32 6.3c3.7 0 6.7 3.7 6.7 8.2 0 1.1-.5 3.5-2.5 8.8q-1.95-.9-4.2-.9c-1.5 0-2.9.3-4.2 1-2.1-5.2-2.5-7.6-2.5-8.8 0-4.7 3-8.3 6.7-8.3M6.3 32c0-3.7 3.7-6.7 8.2-6.7 1.1 0 3.5.5 8.8 2.5-.6 1.3-1 2.7-1 4.2s.3 2.9 1 4.2c-5.2 2.1-7.6 2.5-8.8 2.5-4.6 0-8.2-3-8.2-6.7M32 57.8c-3.7 0-6.7-3.7-6.7-8.2 0-1.1.5-3.5 2.5-8.8 1.3.6 2.7 1 4.2 1s2.9-.3 4.2-1c2.1 5.2 2.5 7.6 2.5 8.8 0 4.5-3 8.2-6.7 8.2m17.6-19.1c-1.1 0-3.5-.5-8.8-2.5.6-1.3 1-2.7 1-4.2s-.3-2.9-1-4.2c5.2-2.1 7.6-2.5 8.8-2.5 4.5 0 8.2 3 8.2 6.7s-3.7 6.7-8.2 6.7" />
  </svg>
);
export default IconFlower;
