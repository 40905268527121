import * as React from "react";
const IconReloadBrowser = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 4H6C3.8 4 2 5.8 2 8v48c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V8c0-2.2-1.8-4-4-4M6 6h52c1.1 0 2 .9 2 2v8.9H4V8c0-1.1.9-2 2-2m52 52H6c-1.1 0-2-.9-2-2V18.9h56V56c0 1.1-.9 2-2 2" />
    <path d="M30.4 35c0-.6-.4-1-1-1h-6.8c1.5-3.6 5.2-6 9.3-6 3.4 0 6.6 1.6 8.4 4.3.3.5.9.6 1.4.3s.6-.9.3-1.4C39.8 28 36 26 31.9 26c-5.5 0-10.3 3.6-11.7 8.7-.1.3 0 .6.2.9.2.2.5.4.8.4h8.2c.5 0 1-.5 1-1M42.8 40h-7.3c-.6 0-1 .4-1 1s.4 1 1 1h5.9c-1.6 3.9-5.3 6.5-9.5 6.5-2.8 0-5.5-1.2-7.4-3.3-.4-.4-1-.4-1.4-.1s-.4 1-.1 1.4c2.3 2.5 5.6 4 8.9 4 5.6 0 10.5-3.8 11.9-9.3.1-.3 0-.6-.2-.9-.2-.2-.5-.3-.8-.3" />
  </svg>
);
export default IconReloadBrowser;
