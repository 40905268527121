import * as React from "react";
const IconHouseAuction = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M30.1 21.7c.2.2.5.3.7.3s.5-.1.7-.3l4.9-4.4c.4-.4.4-1 .1-1.4-.4-.4-1-.4-1.4-.1l-4.2 3.8-1.5-1.5c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4z" />
    <path d="M31.6 34.1c.1.1.3.1.4.1s.3 0 .4-.1C44 28.7 45 23 44.4 10.4c0-.5-.4-.9-.8-.9-2.4-.4-5.5-1.6-7.4-3.9-.2-.2-.5-.4-.8-.4h-6.9c-.3 0-.6.1-.8.4-1.8 2.3-5 3.6-7.4 3.9-.5.1-.8.5-.8.9C19 23 20 28.7 31.6 34.1M21.5 11.3c3-.7 5.7-2.2 7.5-4.1h6c1.7 2 4.5 3.5 7.5 4.1.4 12.1-.9 16.3-10.5 20.9-9.5-4.6-10.9-8.8-10.5-20.9M61 56.9h-3.9v-8l1.2 1c.2.2.4.2.6.2.3 0 .6-.1.8-.4.4-.4.3-1.1-.1-1.4l-9.8-8.2c-.4-.3-.9-.3-1.3 0l-7 5.9-2.8 2.3c-.4.4-.5 1-.1 1.4s1 .5 1.4.1l1.2-1v8H22.9v-8l1.2 1c.2.2.4.2.6.2.3 0 .6-.1.8-.4.4-.4.3-1.1-.1-1.4L15.6 40c-.4-.3-.9-.3-1.3 0l-7 5.9-2.8 2.3c-.4.4-.5 1-.1 1.4s1 .5 1.4.1l1.2-1v8H3c-.6 0-1 .4-1 1s.4 1 1 1h57.99999999999999c.6 0 1-.4 1-1s-.4-.8-1-.8m-52.1 0v-9.7l6-5 6 5v9.7zm34.2 0v-9.7l6-5 6 5v9.7z" />
  </svg>
);
export default IconHouseAuction;
