import * as React from "react";
const IconMailbox = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M48.3 4h8.2c.6 0 1-.4 1-1s-.4-1-1-1h-8.2c-2.2 0-4 1.8-4 4v6.9h-24v.1c-.4 0-.8-.1-1.3-.1-6.9 0-12.4 5.6-12.4 12.4V42c0 2.8 2.2 5 5 5h22.5c0 .1-.1.2-.1.4V61c0 .6.4 1 1 1s1-.4 1-1V47.4c0-.1 0-.3-.1-.4h13.8c3.9 0 7.1-3.2 7.1-7.1V25c0-6.2-4.6-11.2-10.6-12V6c.1-1.1 1-2 2.1-2m-27 41h-9.7c-1.7 0-3-1.3-3-3V25.4C8.6 19.6 13.3 15 19 15c6 0 10.8 4.8 10.8 10.8V45zm33.5-20v14.9c0 2.8-2.3 5.1-5.1 5.1h-18V25.7c0-4.5-2.4-8.5-6-10.8h18.4v11c0 .6.4 1 1 1s1-.4 1-1V15c5 .8 8.7 4.9 8.7 10" />
    <path d="M21.3 35.5h-5.7c-.6 0-1 .4-1 1s.4 1 1 1h5.7c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconMailbox;
