import * as React from "react";
const IconCrescent = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.9 30.3c-.2-4.3-1.4-8.4-3.4-12.3-2-3.8-4.9-7.2-8.3-9.8-3.9-3-8.6-5-13.5-5.8-1.5-.3-3.1-.4-4.7-.4C19.4 2 8.1 9.9 3.8 21.8 2.6 25 2 28.5 2 32c0 1 0 1.8.1 2.5.7 8.7 5.2 16.7 12.4 21.8C19.6 60 25.7 62 32 62c1.6 0 3.2-.1 4.8-.4C47.2 60 56 52.8 59.9 43c1.4-3.5 2.1-7.2 2.1-11 0-.7 0-1.2-.1-1.7M24.3 40c-.6-2.6-.9-5.3-.9-8 0-.8 0-1.6.1-2.5l33.7-9.9c1.5 3.1 2.5 6.5 2.8 9.9zm31.9-22.2-32.5 9.5c.5-3.7 1.6-7.3 3.3-10.7L49.5 10c2.6 2.2 4.9 4.9 6.7 7.8M29 12.9c.1-.2.3-.4.4-.6.2-.3.4-.7.7-1 .2-.2.3-.4.5-.7s.5-.6.7-.9c.2-.2.4-.4.6-.7s.5-.6.7-.8l.6-.6.8-.8c.2-.2.4-.4.7-.6s.5-.4.8-.7c.2-.2.5-.4.7-.5.2-.2.5-.4.7-.5 3.7.7 7.3 2.1 10.5 4.2l-19.1 5.6c0-.1.1-.1.1-.2q.3-.75.6-1.2M32 60c-5.9 0-11.6-1.8-16.3-5.3C9 49.9 4.8 42.5 4.1 34.3 4 33.7 4 32.9 4 32c0-3.3.6-6.5 1.7-9.5C9.7 11.4 20.3 4 32 4c.7 0 1.4 0 2.1.1-.4.4-.9.8-1.3 1.2l-1.4 1.4-.4.4c-.3.3-.6.7-.9 1-.2.2-.3.4-.5.6-.3.3-.5.6-.8 1-.2.2-.3.4-.5.6-.2.3-.5.7-.7 1-.1.2-.3.4-.4.6-.2.4-.5.8-.7 1.1-.1.2-.2.4-.3.5-.3.6-.6 1.1-.9 1.7-2.1 4.1-3.4 8.6-3.8 13.3-.1 1.1-.2 2.3-.2 3.3 0 3.3.4 6.5 1.2 9.5 1 3.8 2.6 7.4 4.8 10.7.5.8 1.1 1.6 1.7 2.3.2.2.4.5.6.7.4.5.9 1 1.3 1.5.2.3.5.5.7.7l1.4 1.4c.2.2.5.4.7.6l.3.3c-.6.5-1.3.5-2 .5m4.9-.4c-.4-.3-.7-.6-1.1-.9-.3-.3-.7-.5-1-.8l-1.2-1.2-.8-.8c-.4-.5-.9-1-1.3-1.5-.2-.3-.4-.5-.6-.7-.3-.4-.6-.9-.9-1.3l27.3-8c-4 7.9-11.6 13.6-20.4 15.2m21.3-17.7-29.5 8.7c-1.7-2.7-3-5.5-3.9-8.6L60 31.6v.4q0 5.1-1.8 9.9" />
  </svg>
);
export default IconCrescent;
