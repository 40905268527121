import * as React from "react";
const IconCoral = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 65 64"
    {...props}
  >
    <path
      
      d="M56.723 35.014a3.051 3.051 0 1 1-.049-6.102 3.051 3.051 0 0 1 .05 6.102m-29.52-14.09a3.052 3.052 0 1 1 .05 6.103 3.052 3.052 0 0 1-.05-6.103M8.7 12.912a3.027 3.027 0 1 1-.05 6.053 3.027 3.027 0 0 1 .05-6.053m34.53-7.988a3.05 3.05 0 1 1 .05 6.102 3.05 3.05 0 0 1-.05-6.102m21.457 27.064a7.963 7.963 0 1 0-10.369 7.566v4.068a5.557 5.557 0 0 1-5.556 5.557h-2.952a13.05 13.05 0 0 0-8.608 3.224 16.9 16.9 0 0 0-4.019-7.441 12.95 12.95 0 0 0 12.577-12.95V15.567a7.963 7.963 0 1 0-4.961 0v16.372a8.037 8.037 0 0 1-8.037 8.037h-4.689a10.2 10.2 0 0 0 1.588-5.531V31.54a7.963 7.963 0 1 0-4.961 0v2.903a5.53 5.53 0 0 1-5.334 5.531 8.11 8.11 0 0 1-8.062-8.111v-8.36a7.963 7.963 0 1 0-4.961 0v8.335a13.073 13.073 0 0 0 12.949 13.048h2.952a10.07 10.07 0 0 1 7.442 3.423 11.7 11.7 0 0 1 3.2 7.889v7.764h4.96v-1.761a8.136 8.136 0 0 1 8.137-8.137h2.952a10.47 10.47 0 0 0 10.469-10.468V39.53a7.96 7.96 0 0 0 5.457-7.442"
    />
  </svg>
);
export default IconCoral;
