import * as React from "react";
const IconCertificateCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 36.3h-5.5V8.8c0-3.6-2.8-6.6-6.3-6.8H12.5C6.7 2 2 6.9 2 12.9v4.4c0 1.4 1.1 2.5 2.5 2.5h8v29c0 2.9 2.4 5.3 5.4 5.3H23v6.6c0 .5.3 1 .7 1.2.3.1.5.1.8.1s.5-.1.7-.2l5.5-3.5 5.4 3.5.1.1c.5.2 1 .2 1.4 0s.6-.7.6-1.2v-6.5H57c2.8 0 5-2.2 5-5v-7.8c0-2.9-2.2-5.1-5-5.1m-53-19v-4.4C4 8 7.8 4 12.5 4h26.2c-1.5 1.4-2.4 3.4-2.4 5.6v7.7c0 .3-.2.5-.5.5H4.5c-.3 0-.5-.2-.5-.5m26.7 29c-4.5 0-8.2-3.6-8.2-8.1s3.7-8.1 8.2-8.1 8.2 3.6 8.2 8.1-3.7 8.1-8.2 8.1m1 10.2c-.6-.4-1.4-.4-2 0l-4.6 3V46.6c1.6 1 3.5 1.7 5.6 1.7s4-.6 5.6-1.7v12.9zm6.6-4.4v-7.2c1.6-1.8 2.6-4.1 2.6-6.7 0-5.6-4.6-10.1-10.2-10.1s-10.2 4.5-10.2 10.1c0 2.6 1 4.9 2.6 6.7v7.2H18c-1.9 0-3.4-1.5-3.4-3.3v-29h21.2c1.4 0 2.5-1.1 2.5-2.5V9.6c0-3 2.2-5.4 4.9-5.6h1.7c2.6 0 4.6 2.1 4.6 4.8v43.3zm21.7-3c0 1.7-1.3 3-3 3h-5.6V38.3H57c1.7 0 3 1.3 3 3z" />
  </svg>
);
export default IconCertificateCopy;
