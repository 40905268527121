import * as React from "react";
const IconHistory = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M33 24c-6.5 0-11.7 5.2-11.7 11.7S26.6 47.4 33 47.4s11.7-5.2 11.7-11.7S39.5 24 33 24m0 21.4c-5.3 0-9.7-4.4-9.7-9.7S27.7 26 33 26s9.7 4.4 9.7 9.7-4.3 9.7-9.7 9.7" />
    <path d="M33.5 36.3V33c0-.6-.4-1-1-1s-1 .4-1 1v3.8c0 .3.1.5.3.7l2.2 2.2c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4z" />
    <path d="M51.4 12.5H13.7c-1.3 0-2.5-.5-3.3-1.4-.8-.8-1.2-1.9-1.2-3 .1-1.1.5-2.1 1.3-2.9q1.2-1.2 3-1.2h36.8c.6 0 1-.4 1-1s-.4-1-1-1H13.5c-1.7 0-3.2.7-4.4 1.8C8 4.9 7.3 6.4 7.2 7.9v48.7c0 3 2.4 5.4 5.4 5.4h38.8c3 0 5.4-2.4 5.4-5.4V17.8c0-2.9-2.4-5.3-5.4-5.3m3.4 44.1c0 1.9-1.5 3.4-3.4 3.4H12.6c-1.9 0-3.4-1.5-3.4-3.4v-44c1.2 1.1 2.8 1.8 4.5 1.8h37.7c1.9 0 3.4 1.5 3.4 3.4z" />
  </svg>
);
export default IconHistory;
