import React, { useState } from 'react';
import { BringzzButton, BringzzText } from "@bringzz/components";
import { useNavigation } from 'context/NavigationContext';
import { BringzzPageContainer, BringzzPinCode, BringzzIcon } from "@bringzz/components";
import { useLocation } from 'react-router-dom';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}
const methodTitle = {
	phone: 'Phone number',
	email: 'Email address'
}

const OTPpage = () => {
	const { backPress } = useNavigation();

	const { navigate } = useNavigation();

	const [loading, setLoading] = useState(false);
	const [isValid, setIsValid] = useState(false)

	let query = useQuery();
	let redirect = query.get('redirect');
	let method = query.get('method');
	let methodValue = query.get('value');

	// TODO: Update this with mock api
	const fetchPin = async pin => {
		setLoading(true);
		const response = await fetch(
			'https://jsonplaceholder.typicode.com/todos/1'
		);
		const result = await response.json();

		setLoading(false);
		// Return the security pin
		return '1234';
	};

	const title = <div className='flex flex-col'><div>Confirm</div><div>your {methodTitle[method]}</div></div>

	return (
		<BringzzPageContainer
			className='bg-white'
			header={{
				left: (
					<BringzzIcon
						size='22'
						icon='IconClose'
						folder='LineIcons'
						className='cursor-pointer'
						onClick={backPress}
					/>
				),
				title: redirect == '/reset-password' ? <BringzzText tag='h3'>{"Reset Password"}</BringzzText> : "",

			}}
			authenticated={false}
			footer={
				<div className='pb-8 px-6'>
					<BringzzButton
						size='lg'
						className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
						onClick={() => {
							console.log(redirect);
							navigate(`${redirect}?method=${method}&value=${methodValue}`, false);
						}}
						disabled={!isValid}
					>
						Confirm
					</BringzzButton>
				</div>
			}
		>
			<div className='relative flex justify-center bg-magic-lilac/20'>
				<div className='relative bg-white w-full bg-cover bg-center flex flex-col md:max-w-2xl '>
					{/* Content */}
					<div className='flex-grow flex flex-col justify-between py-12 px-4'>
						{/* Bottom part */}
						<div className='z-10 flex flex-col '>
							<div className='flex space-y-6 mx-2 md:max-w-lg lg:max-w-xl xl:max-w-2xl'>
								<BringzzText tag='h1' className='text-midnight-blue font-bold'>
									{redirect === '/reset-password'
										? title
										: (method == "phone"
											? 'We just sent you an SMS'
											: 'We just sent you an Email')}
								</BringzzText>
							</div>
							<div className='flex justify-center items-center mt-6'>
								<BringzzPinCode
									length={4}
									onChange={pin => console.log('pin change: ', pin)}
									onValidate={async pin => {
										return pin == (await fetchPin(pin));
									}}
									onSuccess={() => {
										setIsValid(true)
									}}
									onError={() => {
										console.log('Error');
									}}
									upperChildContent={
										<div className='text-center py-4'>
											<BringzzText tag="h3" className="font-normal" >
												Enter the one-time code we sent to <br />
											</BringzzText>
											<BringzzText ag="h3" className="font-bold">
												{
													method == "email" ? (
														methodValue
													) : (
														<>
															+***-***-
															{methodValue.substring(
																methodValue.length - 3,
																methodValue.length
															)}
														</>
													)
												}
											</BringzzText>
										</div>
									}
									lowerChildContent={
										<div className='flex justify-center align-center mt-6 space-x-2'>
											<BringzzText tag="h3" className="font-normal">{"Didn't receive code? "}</BringzzText>
											<div>&bull;</div>
											<a href='#'>
												<BringzzText tag="h3" className="underline font-bold text-midnight-blue">{"Resend Code"}</BringzzText>
											</a>
										</div>
									}
									spacing='space-x-2'
									disabled={loading}
									className={loading ? 'opacity-25' : ''}
								></BringzzPinCode>
							</div>
						</div>
					</div>
				</div>
			</div>
		</BringzzPageContainer>
	);
};

export default OTPpage;
