import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

/**
 * A text component for displaying styled text with default and customizable styles using Tailwind CSS.
 * Ensures default text color is "text-midnight-blue" which can be overridden by specifying a text color in className.
 *
 * @component
 * @example
 * return (
 *   <BringzzText tag="h1" className="text-red-500">
 *     Customized Hello World
 *   </BringzzText>
 * );
 */
const BringzzText = ({ className, tag = 'p', children, ...props }) => {
  const DEFAULT_CLASSES = "font-circular-std";
  const FONT_MEDIUM_CLASS = "font-medium";
  const DEFAULT_TEXT_COLOR = "text-midnight-blue";

  const TAG_STYLES = {
    h1: "text-2xl font-gt-maru",
    h2: "text-[17px] font-gt-maru",
    h3: "text-[15px] font-circular-std",
    h4: "text-sm font-circular-std",
    h5: "text-xs font-circular-std",
  };

  const containsClass = (pattern) => new RegExp(pattern).test(className);

  const fontClass = containsClass('font-[^\s]+') ? '' : FONT_MEDIUM_CLASS;
  const textColorClass = containsClass('text-[^\s]+') ? '' : DEFAULT_TEXT_COLOR;

  const combinedClasses = classnames(DEFAULT_CLASSES, fontClass, textColorClass, TAG_STYLES[tag], className);

  return React.createElement(tag, {
    ...props,
    className: combinedClasses,
  }, children);
};

BringzzText.propTypes = {
  className: PropTypes.string,
  tag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'p']),
  children: PropTypes.node,
};

export default BringzzText;