import * as React from "react";
const IconPlaceholderMap = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m59.6 55.5-3.5-26.1c-.4-2.8-2.8-5-5.7-5h-7.7c2-2.8 3.4-5.7 3.4-8.3C46.2 8.4 39.8 2 32 2S17.8 8.4 17.8 16.2c0 2.6 1.5 5.5 3.4 8.3h-7.7c-2.9 0-5.3 2.1-5.7 5l-3.4 26c-.2 1.6.3 3.3 1.4 4.5s2.7 2 4.3 2H54c1.6 0 3.2-.7 4.3-2s1.5-2.8 1.3-4.5M32 4c6.7 0 12.2 5.5 12.2 12.2 0 2.6-1.8 5.7-4.1 8.5-2.9 3.7-6.6 7-8 8.3-3.9-3.4-12.2-11.6-12.2-16.9C19.8 9.5 25.3 4 32 4M9.8 29.7c.2-1.8 1.8-3.2 3.7-3.2h9.2c3.7 4.6 8.3 8.5 8.6 8.7.2.2.4.2.6.2s.5-.1.6-.2c.3-.3 4.9-4.1 8.6-8.7h9.2c1.9 0 3.4 1.4 3.7 3.2l.9 7.1H44.2c-.3 0-.6.1-.8.3l-7 8.1H8.1c-.1 0-.2 0-.3.1zm-2.5 29c-.7-.8-1-1.9-.9-2.9l1.2-8.7c.2.1.3.2.5.2h28.8c.3 0 .6-.1.8-.3l7-8.1h10.7l1.1 8.2L39 52.4c-.3.1-.6.4-.7.7s0 .7.2.9l5.4 6H10.1C9 60 8 59.5 7.3 58.7m49.4 0c-.7.8-1.7 1.3-2.8 1.3h-7.3l-5.5-6.2L56.7 49l.9 6.7c.2 1.2-.2 2.2-.9 3" />
    <path d="M37.8 15.7c0-3.2-2.6-5.8-5.8-5.8s-5.8 2.6-5.8 5.8 2.6 5.8 5.8 5.8c3.2.1 5.8-2.6 5.8-5.8m-9.6 0c0-2.1 1.7-3.8 3.8-3.8s3.8 1.7 3.8 3.8-1.7 3.8-3.8 3.8c-2.1.1-3.8-1.7-3.8-3.8" />
  </svg>
);
export default IconPlaceholderMap;
