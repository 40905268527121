import * as React from "react";
const IconSupportCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M30.6 20.1H14.4c-.6 0-1 .4-1 1s.4 1 1 1h16.2c.6 0 1-.4 1-1s-.4-1-1-1M14.4 29.7h7.4c.6 0 1-.4 1-1s-.4-1-1-1h-7.4c-.6 0-1 .4-1 1s.4 1 1 1M26.9 35.3H14.4c-.6 0-1 .4-1 1s.4 1 1 1h12.5c.6 0 1-.4 1-1s-.4-1-1-1M47.8 18.8c-2.2-.2-4.2.8-5.2 2.7-.3.5-.1 1.1.4 1.4s1.1.1 1.4-.4c.6-1.1 1.9-1.8 3.2-1.6 1.4.2 2.6 1.3 2.8 2.6s-.5 2.5-1.7 3c-1.4.7-2.4 2.3-2.4 3.8v1.4c0 .6.4 1 1 1s1-.4 1-1v-1.4c0-.7.6-1.7 1.3-2 1.9-.9 3-3 2.8-5.1-.4-2.3-2.3-4.1-4.6-4.4" />
    <path d="M54.5 11.1h-45C5.9 11.1 3 14 3 17.5V50c0 1.2.6 2.2 1.7 2.7q.6.3 1.2.3c.7 0 1.3-.2 1.9-.7l7.3-6.1h39.4c3.6 0 6.5-2.9 6.5-6.5V17.5c0-3.5-2.9-6.4-6.5-6.4M59 39.7c0 2.5-2 4.5-4.5 4.5H14.8c-.2 0-.5.1-.6.2l-7.6 6.3c-.4.4-.9.2-1 .1-.2 0-.6-.2-.6-.8V17.5C5 15 7 13 9.5 13h45c2.5 0 4.5 2 4.5 4.5z" />
    <path d="M47.4 34.4c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.2-2.5-2.5-2.5m0 3c-.3 0-.5-.2-.5-.5s.2-.5.5-.5.5.2.5.5-.3.5-.5.5" />
  </svg>
);
export default IconSupportCopy;
