import * as React from "react";
const IconSmartphoneAltMedical = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m51.1 36.5-.5-.5-.9-.9c1.1-1.2 2.3-2.4 3.3-3.8 1.7-2.2 2.3-4.6 1.9-6.6-.6-2.9-3.5-5.2-6.6-5.3-3.1.1-5.9 2.4-6.6 5.3-.4 2.1.2 4.4 1.9 6.6 1 1.4 2.2 2.6 3.3 3.8l-.8.8-.6.6L42 40c-.4.4-.4 1 0 1.4s1 .4 1.4 0l3.5-3.5.6-.6c.3-.3.6-.6.9-.8l.9.9.5.5 3.5 3.5c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4-1.3-1.2-2.4-2.3-3.6-3.5m-2.8-2.8c-1.1-1.1-2.2-2.3-3.1-3.5-.8-1-2-3-1.5-5 .4-2 2.5-3.7 4.6-3.7 2.2.1 4.2 1.7 4.7 3.7.4 2-.8 4-1.5 5-1.1 1.2-2.2 2.4-3.2 3.5M25 10.9c-.6 0-1 .4-1 1s.4 1 1 1h8.4c.6 0 1-.4 1-1s-.4-1-1-1z" />
    <path d="M48.3 44.2c-.6 0-1 .4-1 1v12.6c0 1.2-1 2.2-2.2 2.2H13.2C12 60 11 59 11 57.8V6.2C11 5 12 4 13.2 4h31.9c1.2 0 2.2 1 2.2 2.2v8.3c0 .6.4 1 1 1s1-.4 1-1V6.2c0-2.3-1.9-4.2-4.2-4.2H13.2C10.9 2 9 3.9 9 6.2v51.6c0 2.3 1.9 4.2 4.2 4.2h31.9c2.3 0 4.2-1.9 4.2-4.2V45.2c0-.5-.5-1-1-1" />
  </svg>
);
export default IconSmartphoneAltMedical;
