import * as React from "react";
const IconAlarmCheck = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M45.3 2.2c-6.4 0-12 3.6-14.8 8.9-2.2-1.1-4.6-1.8-7.1-1.9v-6c0-.6-.4-1-1-1s-1 .4-1 1v6c-4.5.2-8.6 2.1-11.8 5.2-3.4 3.4-5.3 7.9-5.3 12.8v10.3s-.3 4.7-1.9 8.8c-.7 1.7-.5 3.7.5 5.2C4 53.1 5.7 54 7.5 54h6.8c.2 4.4 3.7 7.8 8.1 7.8s8-3.5 8.1-7.8h6.8c1.9 0 3.6-.9 4.6-2.5 1-1.5 1.2-3.5.5-5.2-1.7-4-1.9-8.7-1.9-8.7v-2.7c1.5.5 3.1.7 4.8.7 9.2 0 16.7-7.5 16.7-16.7S54.5 2.2 45.3 2.2M22.4 59.8c-3.3 0-6-2.6-6.1-5.8h12.2c-.1 3.2-2.8 5.8-6.1 5.8m16.1-22.2c0 .2.3 5.1 2.1 9.4.5 1.1.3 2.3-.3 3.3-.7 1-1.7 1.6-2.9 1.6H7.5c-1.2 0-2.3-.6-2.9-1.6-.7-1-.8-2.2-.3-3.3 1.8-4.4 2.1-9.2 2.1-9.5V27.2c0-4.3 1.7-8.3 4.7-11.4 3-3 7-4.7 11.3-4.7 2.5 0 5 .6 7.3 1.8-.7 1.9-1.1 3.9-1.1 6 0 6.8 4.1 12.7 9.9 15.3zm6.8-4c-8.1 0-14.7-6.6-14.7-14.7S37.2 4.2 45.3 4.2 60 10.8 60 18.9s-6.6 14.7-14.7 14.7" />
    <path d="m51.3 16-9.1 5.9-1.8-3.4c-.3-.5-.9-.7-1.3-.4-.5.3-.7.9-.4 1.3l2.3 4.4c.1.3.4.4.6.5h.3c.2 0 .4-.1.5-.2l10-6.4c.5-.3.6-.9.3-1.4s-.9-.6-1.4-.3" />
  </svg>
);
export default IconAlarmCheck;
