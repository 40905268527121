import * as React from "react";
const IconSupport = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M30.9 20.3H14c-1.2 0-2.2 1-2.2 2.2s1 2.2 2.2 2.2h16.8c1.2 0 2.2-1 2.2-2.2s-.9-2.2-2.1-2.2M21.8 32.7H14c-1.2 0-2.2 1-2.2 2.2s1 2.2 2.2 2.2h7.7c1.2 0 2.2-1 2.2-2.2s-.9-2.2-2.1-2.2M48.6 34.5c-.1-.1-.2-.2-.4-.2-.1-.1-.3-.1-.4-.1-.7-.1-1.5.1-2 .6l-.3.3c-.1.1-.2.3-.2.4-.1.1-.1.3-.1.4 0 .2-.1.3-.1.4 0 .2 0 .3.1.4 0 .2.1.3.1.4.1.1.1.3.2.4l.3.3c.4.4 1 .7 1.6.7s1.2-.2 1.6-.7l.3-.3c.1-.1.1-.2.2-.4.1-.1.1-.3.1-.4v-.4c0-.6-.2-1.2-.7-1.6-.1 0-.2-.1-.3-.2M47.9 15.9c-2.5-.3-4.8.9-6 3.1-.6 1.1-.2 2.5.9 3 1.1.6 2.5.2 3-.9.3-.5.9-.8 1.5-.7s1.2.6 1.2 1.1c.1.7-.3 1.1-.7 1.3-1.7.8-2.9 2.8-2.9 4.6v1.3c0 1.2 1 2.2 2.2 2.2s2.2-1 2.2-2.2v-1.3c0-.2.2-.5.4-.6 2.2-1.1 3.5-3.4 3.2-5.9-.2-2.6-2.4-4.7-5-5" />
    <path d="M56 7.9H8c-3.4 0-6.2 2.8-6.2 6.2v37.7c0 1.6.9 3.1 2.4 3.8.6.3 1.2.4 1.8.4 1 0 1.9-.3 2.7-1l8.5-7H56c3.4 0 6.2-2.8 6.2-6.2V14.2c0-3.5-2.8-6.3-6.2-6.3m1.8 33.9c0 1-.8 1.8-1.8 1.8H16.3c-.5 0-1 .2-1.4.5l-8.6 7.1v-37c0-1 .8-1.8 1.8-1.8h48c1 0 1.8.8 1.8 1.8v27.6z" />
  </svg>
);
export default IconSupport;
