import * as React from "react";
const IconIslamicBookAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M44.6 22.5v-2.6c0-.6-.4-1-1-1H41l-2.5-2.5c-.4-.4-1-.4-1.4 0l-2.5 2.5H32c-.6 0-1 .4-1 1v2.6L28.5 25c-.4.4-.4 1 0 1.4l2.5 2.5v2.6c0 .6.4 1 1 1h2.6l2.5 2.5c.2.2.5.3.7.3s.5-.1.7-.3l2.5-2.5h2.6c.6 0 1-.4 1-1v-2.6l2.5-2.5c.4-.4.4-1 0-1.4zm-1.7 5.2c-.2.2-.3.4-.3.7v2h-2c-.3 0-.5.1-.7.3l-2.1 2.1-2.1-2.1c-.2-.2-.4-.3-.7-.3h-2v-2c0-.3-.1-.5-.3-.7l-2.1-2.1 2.1-2.1c.2-.2.3-.4.3-.7v-2h2c.3 0 .5-.1.7-.3l2.1-2.1 2.1 2.1c.2.2.4.3.7.3h2v2c0 .3.1.5.3.7l2.1 2.1z" />
    <path d="M50.2 2H13.8c-2.2 0-4 1.8-4 4v52c0 2.2 1.8 4 4 4h36.3c2.2 0 4-1.8 4-4V6c.1-2.2-1.7-4-3.9-4M11.8 58V6c0-1.1.9-2 2-2h7.7v56h-7.7c-1.1 0-2-.9-2-2m40.4 0c0 1.1-.9 2-2 2H23.6V4h26.6c1.1 0 2 .9 2 2z" />
    <path d="M43.5 42.5H32.2c-.6 0-1 .4-1 1s.4 1 1 1h11.3c.6 0 1-.4 1-1s-.5-1-1-1M40.7 50.7h-8.6c-.6 0-1 .4-1 1s.4 1 1 1h8.6c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconIslamicBookAlt;
