import * as React from "react";
const IconStarSmall = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="m24 17.222-1.577 3.185-1.792-.888 2.452-4.95a1.025 1.025 0 0 1 1.834 0l2.452 4.95-1.792.888zm6.222 9.24a1.02 1.02 0 0 0-.294.903l.937 5.441c.143.833-.734 1.468-1.484 1.075l-4.905-2.57a1.03 1.03 0 0 0-.952 0l-4.905 2.57c-.75.393-1.627-.242-1.484-1.075l.937-5.441a1.02 1.02 0 0 0-.294-.902l-3.968-3.854a1.018 1.018 0 0 1 .567-1.739l5.483-.794c.334-.048.622-.256.77-.557l1.793.888a3.02 3.02 0 0 1-2.276 1.649l-3.512.508 2.536 2.464-1.31 1.349 1.31-1.35a3.02 3.02 0 0 1 .872 2.677l-.6 3.488 3.153-1.652c.88-.46 1.929-.46 2.808 0l3.153 1.652-.6-3.488a3.02 3.02 0 0 1 .872-2.676zm1.143-3.898-3.512-.508a3.02 3.02 0 0 1-2.276-1.65l1.792-.887c.15.3.438.51.77.558l5.484.793a1.018 1.018 0 0 1 .567 1.739l-3.968 3.854-1.393-1.435z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconStarSmall;
