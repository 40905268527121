import * as React from "react";
const IconTrainAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.8 60.4 43.5 46.7l3-6.7h2.2c.6 0 1-.4 1-1V8c0-3.3-2.7-6-6-6H20.4c-3.3 0-6 2.7-6 6v31c0 .6.4 1 1 1h2.2l3 6.7L9.2 60.4c-.4.4-.3 1.1.1 1.4.2.1.4.2.6.2.3 0 .6-.1.8-.4L22 48.1h20l11.3 13.5c.2.2.5.4.8.4.2 0 .5-.1.6-.2.4-.4.5-1 .1-1.4M16.4 13.1h31.3v11.7H16.4zm0-5.1c0-2.2 1.8-4 4-4h23.3c2.2 0 4 1.8 4 4v3.1H16.4zm0 18.8h31.3V38H16.4zm6.1 19.3L19.8 40h24.5l-2.7 6.1z" />
    <path d="M23.4 33.4H27c.6 0 1-.4 1-1s-.4-1-1-1h-3.6c-.6 0-1 .4-1 1 0 .5.4 1 1 1M37 33.4h3.6c.6 0 1-.4 1-1s-.4-1-1-1H37c-.6 0-1 .4-1 1 0 .5.4 1 1 1" />
  </svg>
);
export default IconTrainAlt1;
