import * as React from "react";
const IconScrewdriver = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 31H35.6v-9.5c0-.6-.4-1-1-1h-5.3c-.6 0-1 .4-1 1 0 2.4-1.9 4.3-4.3 4.3s-4.3-1.9-4.3-4.3c0-.6-.4-1-1-1H8.3c-3.5 0-6.3 2.8-6.3 6.3v10.5c0 3.5 2.8 6.3 6.3 6.3h10.5c.6 0 1-.4 1-1 0-2.4 1.9-4.3 4.3-4.3s4.3 1.9 4.3 4.3c0 .6.4 1 1 1h5.3c.6 0 1-.4 1-1V33H61c.6 0 1-.4 1-1s-.4-1-1-1M33.6 41.5h-3.4c-.5-3-3.1-5.3-6.2-5.3s-5.7 2.3-6.2 5.3H8.3c-2.4 0-4.3-1.9-4.3-4.3V26.7c0-2.4 1.9-4.3 4.3-4.3h9.6c.5 3 3.1 5.3 6.2 5.3s5.7-2.3 6.2-5.3h3.4v19.1z" />
  </svg>
);
export default IconScrewdriver;
