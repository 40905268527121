import * as React from "react";
const IconBurjAlArab = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.7 57.7h-7.2v-8.6c0-21.4-17.3-38.8-38.6-39.1V3c0-.6-.4-1-1-1s-1 .4-1 1v54.7H6.3c-.6 0-1 .4-1 1s.4 1 1 1h3.6V61c0 .6.4 1 1 1s1-.4 1-1v-1.3h45.8c.6 0 1-.4 1-1s-.4-1-1-1M39.5 25c2.4 2.8 4.4 6 5.9 9.4H11.9V25h27.6M11.9 36.4h34.3c1.1 3 1.8 6.1 2.1 9.4H11.9zM37.7 23H11.9V12c10.1.2 19.2 4.3 25.8 11M11.9 47.8h36.6v10H11.9z" />
  </svg>
);
export default IconBurjAlArab;
