import * as React from "react";
const IconReportLaptop = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59 40.8h-.4V14.2c0-1.7-1.3-3-3-3H8.4c-1.7 0-3 1.3-3 3v26.6H5c-1.7 0-3 1.3-3 3v5.9c0 1.7 1.3 3 3 3h54c1.7 0 3-1.3 3-3v-5.9c0-1.6-1.3-3-3-3M7.4 14.2c0-.6.4-1 1-1h47.1c.6 0 1 .4 1 1v26.6H7.4zM60 49.8c0 .6-.4 1-1 1H5c-.6 0-1-.4-1-1v-5.9c0-.6.4-1 1-1h54c.6 0 1 .4 1 1z" />
    <path d="M25.1 25.9H22v-3.1c0-.6-.4-1-1-1s-1 .4-1 1v3.1h-3.1c-.6 0-1 .4-1 1s.4 1 1 1H20V31c0 .6.4 1 1 1s1-.4 1-1v-3.1h3.1c.6 0 1-.4 1-1s-.5-1-1-1M47.4 19H38c-.6 0-1 .4-1 1s.4 1 1 1h9.4c.6 0 1-.4 1-1s-.4-1-1-1M47.4 25.9H38c-.6 0-1 .4-1 1s.4 1 1 1h9.4c.6 0 1-.4 1-1s-.4-1-1-1M47.4 32.8H38c-.6 0-1 .4-1 1s.4 1 1 1h9.4c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconReportLaptop;
