import * as React from "react";
const IconPendrive = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.5 35.9 29.1 7.5c-1-1-2.8-1-3.9 0l-1.1 1.1-4.7-4.7c-2.4-2.4-6.3-2.4-8.7 0l-7 7c-2.4 2.4-2.4 6.3 0 8.7l4.7 4.7-1.1 1.1c-1.1 1.1-1.1 2.8 0 3.9l28 28c3.1 3.1 7.3 4.7 11.4 4.7 3.6 0 7.1-1.2 9.9-3.7 3.2-2.8 5.1-6.8 5.2-11 .3-4.3-1.3-8.4-4.3-11.4M5.2 18.2c-1.6-1.6-1.6-4.3 0-5.9l7-7c1.6-1.6 4.3-1.6 5.9 0l4.7 4.7L9.9 22.9zm50.2 38.6c-5.3 4.6-13.4 4.2-18.5-1l-28-28c-.3-.3-.3-.7 0-1L26.7 8.9c.1-.1.3-.2.5-.2s.4.1.5.2l28.4 28.4c2.6 2.6 4 6.1 3.9 9.9-.1 3.7-1.7 7.1-4.6 9.6" />
    <path d="M45.2 38.9c-1.7 0-3.3.7-4.5 1.9s-1.9 2.8-1.9 4.5.7 3.3 1.9 4.5 2.8 1.9 4.5 1.9 3.3-.7 4.5-1.9c2.5-2.5 2.5-6.5 0-9-1.2-1.3-2.8-1.9-4.5-1.9m3.1 9.5c-.8.8-1.9 1.3-3.1 1.3s-2.3-.5-3.1-1.3-1.3-1.9-1.3-3.1.5-2.3 1.3-3.1 1.9-1.3 3.1-1.3 2.3.5 3.1 1.3c1.7 1.7 1.7 4.5 0 6.2" />
  </svg>
);
export default IconPendrive;
