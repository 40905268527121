import * as React from "react";
const IconDesktopComputer = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 8.1h-7.4c-.6 0-1 .4-1 1s.4 1 1 1H57c1.7 0 3 1.4 3 3v27.1H4V13.1c0-1.7 1.4-3 3-3h7.3c.6 0 1-.4 1-1s-.4-1-1-1H7c-2.8 0-5 2.3-5 5v33c0 2.8 2.3 5 5 5h17.6c-.6 1.5-1.8 3.8-3.4 5.1-.4.4-.6 1-.4 1.5s.7.9 1.3.9h19.4c.6 0 1.1-.3 1.3-.9.2-.5 0-1.1-.4-1.5-1.6-1.3-2.8-3.6-3.4-5.1h18c2.8 0 5-2.3 5-5v-33c0-2.8-2.3-5-5-5M39.9 56.6H23.8c1.6-1.9 2.6-4.3 3.1-5.5h10c.3 1.2 1.4 3.6 3 5.5M57 49.1H7c-1.7 0-3-1.4-3-3v-3.8h56v3.8c0 1.7-1.4 3-3 3" />
    <path d="M23.8 15.6H31v7.2c0 .6.4 1 1 1s1-.4 1-1v-7.2h7.2c.6 0 1-.4 1-1s-.4-1-1-1H33V6.4c0-.6-.4-1-1-1s-1 .4-1 1v7.2h-7.2c-.6 0-1 .4-1 1s.5 1 1 1" />
  </svg>
);
export default IconDesktopComputer;
