import * as React from "react";
const IconIotMobile = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M18.995 4A3 3 0 0 0 16 7v50c0 1.658 1.342 3 2.995 3h26.01A3 3 0 0 0 48 57V7c0-1.658-1.342-3-2.995-3zM14 7c0-2.76 2.236-5 4.995-5h26.01C47.765 2 50 4.24 50 7v50c0 2.76-2.236 5-4.995 5h-26.01A5 5 0 0 1 14 57zm14.01 4.008a1 1 0 0 1 1-1h5.979a1 1 0 0 1 0 2H29.01a1 1 0 0 1-1-1M32 32c-3.434 0-6.75 1.254-9.327 3.528a1 1 0 0 1-1.323-1.5 16.096 16.096 0 0 1 21.3 0 1 1 0 0 1-1.323 1.5A14.1 14.1 0 0 0 32 32m.038 5.725c-2.195 0-4.32.77-6.006 2.177a1 1 0 0 1-1.281-1.536 11.38 11.38 0 0 1 14.574 0 1 1 0 1 1-1.281 1.536 9.38 9.38 0 0 0-6.006-2.177m-.033 5.725a4.66 4.66 0 0 0-2.701.863 1 1 0 1 1-1.16-1.63 6.66 6.66 0 0 1 7.721 0 1 1 0 1 1-1.16 1.63 4.66 4.66 0 0 0-2.7-.863M31 48.176a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2H32a1 1 0 0 1-1-1"
      clipRule="evenodd"
    />
  </svg>
);
export default IconIotMobile;
