import * as React from "react";
const IconSofa8 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62 41.2V27.7c0-2.7-2-4.8-4.5-5.2 0-.1.1-.2.1-.3v-3.6c0-5.3-4.3-9.6-9.6-9.6H16c-5.3 0-9.6 4.3-9.6 9.6v3.6c0 .1 0 .2.1.3C4 22.9 2 25.1 2 27.7v13.5c0 3.7 3 6.7 6.6 6.7v6c0 .6.4 1 1 1s1-.4 1-1v-6h42.7v6c0 .6.4 1 1 1s1-.4 1-1v-6c3.7 0 6.7-3 6.7-6.7m-6.5-22.6v3.6c0 .1 0 .2.1.3h-.5c-2.9 0-5.3 2.4-5.3 5.3V33H33V11.1h15c4.2 0 7.5 3.3 7.5 7.5m-47 0c0-4.2 3.4-7.6 7.6-7.6h15v21.9H14.2v-5.2c0-2.9-2.4-5.3-5.3-5.3h-.5c0-.1.1-.2.1-.3zm46.8 27.3H8.7c-2.6 0-4.7-2.1-4.7-4.7V27.7c0-1.8 1.5-3.3 3.3-3.3h1.6c1.8 0 3.3 1.5 3.3 3.3v6.2c0 .6.4 1 1 1h37.7c.6 0 1-.4 1-1v-6.2c0-1.8 1.5-3.3 3.3-3.3h1.6c1.8 0 3.3 1.5 3.3 3.3v13.5c-.1 2.6-2.2 4.7-4.8 4.7" />
  </svg>
);
export default IconSofa8;
