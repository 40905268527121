import * as React from "react";
const IconMessageInfo = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.8 42.7c-.6 0-1 .4-1 1v8.8c0 .6.4 1 1 1s1-.4 1-1v-8.8c0-.5-.4-1-1-1M56.9 11.6H7.1c-2.8 0-5.1 2.3-5.1 5.1V48c0 2.8 2.3 5.1 5.1 5.1h31.8c.6 0 1-.4 1-1s-.4-1-1-1H7.1C5.4 51.1 4 49.7 4 48V16.6c0-.4.1-.8.2-1.1l25 15.2c.9.5 1.8.8 2.8.8s1.9-.3 2.8-.8l25-15.2c.1.3.2.7.2 1.1V31c0 .6.4 1 1 1s1-.4 1-1V16.6c0-2.8-2.3-5-5.1-5M33.8 29c-1.1.7-2.4.7-3.5 0L5.5 14c.5-.3 1-.4 1.6-.4h49.8c.6 0 1.1.2 1.6.4z" />
    <circle cx={51.8} cy={37.9} r={2.1} />
  </svg>
);
export default IconMessageInfo;
