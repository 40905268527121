import * as React from "react";
const IconBackpack = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.1 33h-5.8V17.2c0-4.1-3.3-7.5-7.4-7.5h-2.5V6.9c0-2.7-2.1-4.9-4.7-4.9H25.3c-2.7 0-4.8 2.2-4.9 4.9v2.8H18c-4.1 0-7.4 3.4-7.4 7.5V33H4.9c-1.2 0-2.3 1.1-2.3 2.3v12.9c0 1.3.5 2.5 1.4 3.4S6.1 53 7.4 53h3.3v4.1c0 2.7 2.2 4.9 4.8 4.9h33c2.7 0 4.8-2.2 4.8-4.9V53h3.3c1.3 0 2.5-.5 3.4-1.4s1.4-2.1 1.4-3.4V35.4c0-1.3-1-2.4-2.3-2.4M25.3 4h13.3c1.5 0 2.7 1.3 2.7 2.9v2.8H22.5V6.9c0-1.6 1.3-2.9 2.8-2.9m-7.2 7.8h27.8c3 0 5.4 2.5 5.4 5.5V23l-18.6 6c-.5.2-1.1.2-1.6 0l-18.4-6.1v-5.7c0-3 2.4-5.4 5.4-5.4M7.4 51c-.7 0-1.4-.3-2-.8-.5-.5-.8-1.2-.8-2V35.4q0-.3.3-.3h5.8v16H7.4zm17.4 9v-9.2c0-1.1.9-2 2-2h10.4c1.1 0 2 .9 2 2V60zm26.5-2.9c0 1.6-1.3 2.9-2.8 2.9h-7.3v-9.2c0-2.2-1.8-4-4-4H26.8c-2.2 0-4 1.8-4 4V60h-7.3c-1.5 0-2.8-1.3-2.8-2.9V25l17.7 5.9c.5.2 1 .2 1.5.2s1-.1 1.4-.2l18-5.9v32.1m8.1-8.9c0 .7-.3 1.5-.8 2s-1.2.8-2 .8h-3.3V35h5.8c.1 0 .3.2.3.3z" />
  </svg>
);
export default IconBackpack;
