import * as React from "react";
const IconMz = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 85.333 513 342"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 85.333h513v342H0z" />
    <path  d="M0 323.333h513v104H0z" />
    <path  d="M0 85.333h513v104H0z" />
    <path d="M0 204.333h513v104H0z" />
    <path  d="M256 256.006 0 426.668V85.331z" />
    <path
      
      d="m83.477 195.132 15.107 46.498h48.894l-39.554 28.739 15.107 46.499-39.554-28.738-39.555 28.738 15.11-46.499-39.554-28.739H68.37z"
    />
  </svg>
);
export default IconMz;
