import * as React from "react";
const IconCalenderAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56 11.8h-6.6V8.4c0-.6-.4-1-1-1s-1 .4-1 1v3.4H16.6V8.4c0-.6-.4-1-1-1s-1 .4-1 1v3.4H8c-2.8 0-5 2.2-5 5v34.8c0 2.8 2.2 5 5 5h48c2.8 0 5-2.2 5-5V16.8c0-2.8-2.2-5-5-5m-48 2h6.6v3.3c0 .6.4 1 1 1s1-.4 1-1v-3.3h30.8v3.3c0 .6.4 1 1 1s1-.4 1-1v-3.3H56c1.7 0 3 1.3 3 3v7.9H5v-7.9c0-1.7 1.3-3 3-3m48 40.8H8c-1.7 0-3-1.3-3-3V26.7h54v24.9c0 1.6-1.3 3-3 3" />
    <path d="M28.8 30.7h-2.2c-.6 0-1 .4-1 1v2.2c0 .6.4 1 1 1h2.2c.6 0 1-.4 1-1v-2.2c0-.6-.5-1-1-1M37.9 30.7h-2.2c-.6 0-1 .4-1 1v2.2c0 .6.4 1 1 1h2.2c.6 0 1-.4 1-1v-2.2c0-.6-.4-1-1-1M47 30.7h-2.2c-.6 0-1 .4-1 1v2.2c0 .6.4 1 1 1H47c.6 0 1-.4 1-1v-2.2c0-.6-.4-1-1-1M19.2 38.4H17c-.6 0-1 .4-1 1v2.2c0 .6.4 1 1 1h2.2c.6 0 1-.4 1-1v-2.2c0-.6-.5-1-1-1M28.8 38.4h-2.2c-.6 0-1 .4-1 1v2.2c0 .6.4 1 1 1h2.2c.6 0 1-.4 1-1v-2.2c0-.6-.5-1-1-1M37.9 38.4h-2.2c-.6 0-1 .4-1 1v2.2c0 .6.4 1 1 1h2.2c.6 0 1-.4 1-1v-2.2c0-.6-.4-1-1-1M47 38.4h-2.2c-.6 0-1 .4-1 1v2.2c0 .6.4 1 1 1H47c.6 0 1-.4 1-1v-2.2c0-.6-.4-1-1-1M19.2 46.1H17c-.6 0-1 .4-1 1v2.2c0 .6.4 1 1 1h2.2c.6 0 1-.4 1-1v-2.2c0-.6-.5-1-1-1M28.8 46.1h-2.2c-.6 0-1 .4-1 1v2.2c0 .6.4 1 1 1h2.2c.6 0 1-.4 1-1v-2.2c0-.6-.5-1-1-1M37.9 46.1h-2.2c-.6 0-1 .4-1 1v2.2c0 .6.4 1 1 1h2.2c.6 0 1-.4 1-1v-2.2c0-.6-.4-1-1-1" />
  </svg>
);
export default IconCalenderAlt1;
