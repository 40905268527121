import * as React from "react";
const IconPaintRollerCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.7 9.9h-1.6v-5c0-1.6-1.2-2.9-2.6-2.9H17.8c-1.4 0-2.6 1.3-2.6 2.9v5H9.9c-1.4 0-2.6 1.2-2.6 2.6v15.2c0 1.4 1.2 2.6 2.6 2.6h28.4c.3 0 .6.3.6.6v5.3h-3c-1.3 0-2.3 1.1-2.3 2.5v20.8c0 1.4 1 2.5 2.3 2.5h8c1.3 0 2.3-1.1 2.3-2.5V38.7c0-1.4-1-2.5-2.3-2.5h-3V31c0-1.4-1.2-2.6-2.6-2.6H9.9c-.3 0-.6-.3-.6-.6V12.5c0-.3.3-.6.6-.6h5.3v5c0 1.6 1.2 2.9 2.6 2.9h33.7c1.4 0 2.6-1.3 2.6-2.9v-5h1.6c.6 0 1-.4 1-1s-.4-1-1-1M44.2 38.7v20.8c0 .3-.2.5-.3.5h-8c-.1 0-.3-.2-.3-.5V38.7c0-.3.2-.5.3-.5h8c.1.1.3.3.3.5m7.9-21.8c0 .6-.3.9-.6.9H17.8c-.3 0-.6-.4-.6-.9v-12c0-.5.3-.9.6-.9h33.7c.3 0 .6.4.6.9z" />
  </svg>
);
export default IconPaintRollerCopy;
