import React, { useState } from 'react';
import { useNavigation } from 'context/NavigationContext';
import {
	BringzzButton,
	BringzzText,
	BringzzInput,
	BringzzDate,
	BringzzPageContainer,
	BringzzIcon,
	BringzzImageUpload,
	BringzzBottomDrawer,
	BringzzCheckbox,
	BringzzRotatingSelect
} from "@bringzz/components";
import { useLocation } from 'react-router-dom';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const OpeningHoursPage = () => {
	const { backPress } = useNavigation();
	const [currentAMPM, setCurrentAMPM] = useState(null);
	const [currentHours, setCurrentHours] = useState(null);
	const [currentMinutes, setCurrentMinutes] = useState(null);
	const [selectingTime, setSelectingTime] = useState(false);
	const [selectingOpeningHours, setSelectingOpeningHours] = useState(false);
	const [selectingClosingHours, setSelectingClosingHours] = useState(false);
	const [selectingDayIndex, setSelectingDayIndex] = useState(null);
	const [selectingHoursIntervalIndex, setSelectingHoursIntervalIndex] =
		useState(null);

	const [openingHours, setOpeningHours] = useState([
		{
			closed: false,
			hours: [{ opens: '', closes: '' }],
			dayName: 'Monday'
		},
		{
			closed: false,
			hours: [{ opens: '', closes: '' }],
			dayName: 'Tuesday'
		},
		{
			closed: false,
			hours: [{ opens: '', closes: '' }],
			dayName: 'Wednesday'
		},
		{
			closed: false,
			hours: [{ opens: '', closes: '' }],
			dayName: 'Thursday'
		},
		{
			closed: false,
			hours: [{ opens: '', closes: '' }],
			dayName: 'Friday'
		},
		{
			closed: true,
			hours: [{ opens: '', closes: '' }],
			dayName: 'Saturday'
		},
		{
			closed: true,
			hours: [{ opens: '', closes: '' }],
			dayName: 'Sunday'
		}
	]);

	const hoursOptions = [
		{ label: '1', value: 1 },
		{ label: '2', value: 2 },
		{ label: '3', value: 3 },
		{ label: '4', value: 4 },
		{ label: '5', value: 5 },
		{ label: '6', value: 6 },
		{ label: '7', value: 7 },
		{ label: '8', value: 8 },
		{ label: '9', value: 9 },
		{ label: '10', value: 10 },
		{ label: '11', value: 11 },
		{ label: '12', value: 12 }
	];

	const generateMinutesOptions = () => {
		let array = [];
		for (let index = 0; index < 60; index++) {
			array.push({ value: index, label: index.toString().padStart(2, '0') });
		}
		return array;
	};
	const minutesOptions = generateMinutesOptions();

	const AMPMOptions = [
		{ value: 'AM', label: 'AM' },
		{ value: 'PM', label: 'PM' }
	];

	const changeHours = result => {
		setCurrentHours(result.value);
	};
	const changeMinutes = result => {
		setCurrentMinutes(result.value);
	};
	const changeAMPM = result => {
		setCurrentAMPM(result.value);
	};

	const closeTimeSelection = () => {
		const time = `${currentHours}:${currentMinutes.toString().padStart(2, '0')} ${currentAMPM}`;
		const openingHoursCopy = [...openingHours];
		if (selectingClosingHours) {
			openingHoursCopy[selectingDayIndex].hours[
				selectingHoursIntervalIndex
			].closes = time;
		} else {
			openingHoursCopy[selectingDayIndex].hours[
				selectingHoursIntervalIndex
			].opens = time;
		}
		console.log(openingHoursCopy);
		setOpeningHours(openingHoursCopy);
		setSelectingTime(false);
	};

	const addInterval = dayIndex => {
		const openingHoursCopy = [...openingHours];
		openingHoursCopy[dayIndex].hours.push({ opens: '', closes: '' });
		setOpeningHours(openingHoursCopy);
	};
	const removeInterval = (dayIndex, intervalIndex) => {
		const openingHoursCopy = [...openingHours];
		openingHoursCopy[dayIndex].hours.splice(intervalIndex, 1);
		setOpeningHours(openingHoursCopy);
	};

	const changeClosedState = (index, value) => {
		const openingHoursCopy = [...openingHours];
		openingHoursCopy[index].closed = value;
		setOpeningHours(openingHoursCopy);
	};
	const { navigate } = useNavigation();

	return (
		<BringzzPageContainer
			header={{
				left: (
					<BringzzIcon
						size='22'
						icon='IconChevronLeft'
						folder='LineIcons'
						className='cursor-pointer'
						onClick={backPress}
					/>
				)
			}}
			footer={
				<div className='px-4 mb-4'>
					<BringzzButton
						size='lg'
						className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
						onClick={() => {
							navigate('/register/pharmacy/night-duty/');
						}}
					>
						Continue
					</BringzzButton>
				</div>
			}
			authenticated={false}
		>
			{selectingTime && (
				<BringzzBottomDrawer
					isOpen={selectingTime}
					close={closeTimeSelection}
					title='Select...'
				>
					<div className='flex items-center relative gap-x-4 justify-center'>
						<div className='h-12 absolute top-1/2 right-0 w-full border-y -translate-y-1/2'></div>
						<div>
							<BringzzRotatingSelect
								options={hoursOptions}
								onSelect={changeHours}
							/>
						</div>
						<div>
							<BringzzRotatingSelect
								options={minutesOptions}
								onSelect={changeMinutes}
							/>
						</div>
						<div>
							<BringzzRotatingSelect
								options={AMPMOptions}
								onSelect={changeAMPM}
							/>
						</div>
					</div>

					<div className='flex justify-center items-center mt-4'>
						<BringzzButton
							className='bg-magic-lilac'
							onClick={closeTimeSelection}
						>
							Apply
						</BringzzButton>
					</div>
				</BringzzBottomDrawer>
			)}
			<div className='relative flex justify-center bg-magic-lilac/20'>
				<div className='relative bg-white w-full bg-cover bg-center flex flex-col'>
					{/* Content */}
					<div className='flex-grow flex flex-col py-8 px-4'>
						<div className='z-10 flex flex-col '>
							<div className='flex flex-col text-center space-y-6 mx-auto md:max-w-lg lg:max-w-xl xl:max-w-2xl'>
								<BringzzText tag='h1' className='text-midnight-blue'>
									Opening hours
								</BringzzText>
							</div>
							<div className='mt-12'>
								{openingHours &&
									openingHours.map((day, dayIndex) => {
										return (
											<div
												className='flex gap-x-6 mt-4 pb-4 border-b'
												key={dayIndex}
											>
												<div className='grow'>
													<div>
														<BringzzText>{day.dayName}</BringzzText>
													</div>
													<div className='mt-3'>
														<BringzzCheckbox
															label='closed'
															onChange={value => {
																changeClosedState(dayIndex, value);
															}}
															checked={day.closed}
														></BringzzCheckbox>
													</div>
												</div>
												<div className='space-y-4'>
													{!day.closed &&
														day.hours.map((interval, intervalIndex) => {
															return (
																<div
																	className='flex items-center gap-x-4 '
																	key={intervalIndex}
																>
																	<div className=''>
																		<div>
																			<BringzzText>Opens</BringzzText>
																		</div>
																		<div className='w-[80px]'>
																			<BringzzInput
																				type='text'
																				placeholder='HH:MM'
																				onClick={() => {
																					setSelectingOpeningHours(true);
																					setSelectingDayIndex(dayIndex);
																					setSelectingHoursIntervalIndex(
																						intervalIndex
																					);
																					setSelectingTime(true);
																				}}
																				value={interval.opens}
																			/>{' '}
																		</div>
																	</div>
																	<div className=''>
																		<div>
																			<BringzzText>Closes</BringzzText>
																		</div>
																		<div className=' flex items-center gap-x-4'>
																			<div className='w-[80px]'>
																				<BringzzInput
																					type='text'
																					placeholder='HH:MM'
																					onClick={() => {
																						setSelectingClosingHours(true);
																						setSelectingDayIndex(dayIndex);
																						setSelectingHoursIntervalIndex(
																							intervalIndex
																						);
																						setSelectingTime(true);
																					}}
																					value={interval.closes || ''}
																				/>
																			</div>

																			<div>
																				{intervalIndex === 0 ? (
																					<BringzzIcon
																						size='28'
																						icon='IconCirclePlus'
																						folder='LineIcons'
																						className='cursor-pointer'
																						onClick={() => {
																							addInterval(dayIndex);
																						}}
																					/>
																				) : (
																					<BringzzIcon
																						size='28'
																						icon='IconTrashCan'
																						folder='LineIcons'
																						className='cursor-pointer'
																						onClick={() => {
																							removeInterval(dayIndex);
																						}}
																					/>
																				)}
																			</div>
																		</div>
																	</div>
																</div>
															);
														})}
												</div>
											</div>
										);
									})}
							</div>
						</div>
					</div>
				</div>
			</div>
		</BringzzPageContainer>
	);
};

export default OpeningHoursPage;
