import * as React from "react";
const IconBarcode = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M18 9H7c-.6 0-1 .4-1 1v11c0 .6.4 1 1 1s1-.4 1-1V11h10c.6 0 1-.4 1-1s-.4-1-1-1M57 42c-.6 0-1 .4-1 1v10H46c-.6 0-1 .4-1 1s.4 1 1 1h11c.6 0 1-.4 1-1V43c0-.6-.4-1-1-1M57 9H46c-.6 0-1 .4-1 1s.4 1 1 1h10v10c0 .6.4 1 1 1s1-.4 1-1V10c0-.5-.4-1-1-1M18 53H8V43c0-.6-.4-1-1-1s-1 .4-1 1v11c0 .6.4 1 1 1h11c.6 0 1-.4 1-1s-.4-1-1-1M24.1 44.5c-.6 0-1 .4-1 1v1.8c0 .6.4 1 1 1s1-.4 1-1v-1.8c0-.6-.5-1-1-1M32.9 15.7c-.6 0-1 .4-1 1v30.7c0 .6.4 1 1 1s1-.4 1-1V16.7c0-.6-.4-1-1-1" />
    <path d="M51.6 47.3V16.7c0-.6-.4-1-1-1s-1 .4-1 1v30.7c0 .6.4 1 1 1s1-.5 1-1.1M16.3 47.3V16.7c0-.6-.4-1-1-1s-1 .4-1 1v30.7c0 .6.4 1 1 1s1-.5 1-1.1M24.1 15.7c-.6 0-1 .4-1 1v22.8c0 .6.4 1 1 1s1-.4 1-1V16.7c0-.6-.5-1-1-1M41.8 44.5c-.6 0-1 .4-1 1v1.8c0 .6.4 1 1 1s1-.4 1-1v-1.8c0-.6-.5-1-1-1M41.8 15.7c-.6 0-1 .4-1 1v22.8c0 .6.4 1 1 1s1-.4 1-1V16.7c0-.6-.5-1-1-1" />
  </svg>
);
export default IconBarcode;
