import * as React from "react";
const IconSpeechBubble29 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.8 11.5c-2.2-2-5-3.1-8-3-2.1-4-6.5-6.5-11.4-6.5S27.1 4.5 25 8.4c-2.9-.1-5.8 1-8 3-2.3 2.1-3.6 5.1-3.6 8.3 0 4.3 2.6 8.3 6.5 10.2 0 3.8 2 7.2 5.4 9 1.5.8 3.1 1.2 4.7 1.2 2 0 4-.6 5.7-1.9 3 2.1 6.8 2.5 10.2.8 3.4-1.6 5.5-4.9 5.6-8.5 4.6-1.5 7.8-5.8 7.8-10.7.1-3.2-1.2-6.2-3.5-8.3m-5.5 17.2c-.5.1-.8.5-.8 1 .1 3.2-1.6 6.1-4.5 7.5s-6.2 1-8.7-1c-.4-.3-.9-.3-1.3 0-2.5 2.1-5.9 2.5-8.8 1s-4.6-4.5-4.3-7.7c0-.4-.2-.8-.6-1-3.5-1.4-5.9-4.9-5.9-8.6 0-2.6 1.1-5 3-6.8 1.7-1.6 4-2.5 6.4-2.5h.7c.4 0 .8-.2 1-.6 1.7-3.7 5.6-6 9.9-6s8.2 2.3 9.8 5.9c.2.4.6.6 1 .6 2.6-.2 5.2.7 7.1 2.5s3 4.2 3 6.8c.1 4.2-2.8 7.9-7 8.9M19 43.9c-2.4 0-4.4 2-4.4 4.4s2 4.4 4.4 4.4 4.4-2 4.4-4.4-2-4.4-4.4-4.4m0 6.7c-1.3 0-2.4-1.1-2.4-2.4s1.1-2.4 2.4-2.4 2.4 1.1 2.4 2.4-1.1 2.4-2.4 2.4" />
    <path d="M27.7 19.4h-.3c-1.1 0-2 .9-2 2s.9 2 2 2h.3c1.1 0 2-.9 2-2s-.9-2-2-2M37.2 19.4h-.3c-1.1 0-2 .9-2 2s.9 2 2 2h.3c1.1 0 2-.9 2-2s-.9-2-2-2M9 53.3c-2.4 0-4.4 2-4.4 4.4S6.6 62 9 62s4.4-2 4.4-4.4-2-4.3-4.4-4.3M9 60c-1.3 0-2.4-1.1-2.4-2.4s1.1-2.4 2.4-2.4 2.4 1.1 2.4 2.4S10.3 60 9 60M46.7 19.4h-.3c-1.1 0-2 .9-2 2s.9 2 2 2h.3c1.1 0 2-.9 2-2s-.9-2-2-2" />
  </svg>
);
export default IconSpeechBubble29;
