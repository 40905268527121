import { BringzzText, BringzzQrCode } from '@bringzz/components';

// ModeOverlay Component (handles the different modes: handover, confirm, collect)
const CameraModeOverlay = ({ mode, selectedOption, text = "" }) => {
    return (
        <>
            {selectedOption === 'QR-Code' &&
                <div className="absolute top-1/2 left-1/2 w-64 h-64 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none grid grid-cols-3 grid-rows-3">
                    <div className="border-t-2 border-l-2 border-white bg-opacity-50 col-start-1 row-start-1" />
                    <div className="border-t-2 border-r-2 border-white bg-opacity-50 col-start-3 row-start-1" />
                    <div className="border-b-2 border-l-2 border-white bg-opacity-50 col-start-1 row-start-3" />
                    <div className="border-b-2 border-r-2 border-white bg-opacity-50 col-start-3 row-start-3" />
                </div>
            }
            {selectedOption === 'Barcode' && (
                <div className="absolute inset-0">
                    <div className="absolute top-1/2 left-1/2 w-64 h-24 transform -translate-x-1/2 -translate-y-1/2 border-2 border-white" />
                </div>
            )}
            <div className="absolute top-32 text-center w-full px-8">
                {mode === 'handover' && (
                    <BringzzText tag="h4" className="bg-black/60 p-2 text-white font-normal">
                        {`Instructions (Temporary: ${text})`}
                    </BringzzText>
                )}
                {mode === 'confirm' && (
                    <div>
                        <BringzzText tag="h4" className="bg-black/60 p-2 text-white font-normal">
                            Scan the QR-Code on the shopper’s bag to confirm you have received your order
                        </BringzzText>
                        <BringzzText tag="h4" className="bg-black/50 p-2 text-white font-normal">
                            No. of bags left to scan: X
                        </BringzzText>
                    </div>
                )}
                {mode === 'collect' && (
                    <div>
                        <BringzzText tag="h4" className="bg-black/60 p-2 text-white font-normal">
                            Show this code to the pharmacist to verify yourself and receive your order. Then click continue.
                        </BringzzText>
                        <BringzzQrCode value="123456798" />
                    </div>
                )}
            </div>
        </>
    );
};

export default CameraModeOverlay