import * as React from "react";
const IconFind = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M25.9 13.7c-3 0-5.9 1.2-8 3.3s-3.3 5-3.3 8 1.2 5.9 3.3 8c2.2 2.2 5.1 3.3 8 3.3s5.8-1.1 8-3.3c2.1-2.1 3.3-5 3.3-8s-1.2-5.9-3.3-8-4.9-3.3-8-3.3m6.6 17.9c-3.6 3.6-9.6 3.6-13.2 0s-3.6-9.6 0-13.2c1.8-1.8 4.1-2.7 6.6-2.7s4.8 1 6.6 2.7c1.8 1.8 2.7 4.1 2.7 6.6.1 2.5-.9 4.9-2.7 6.6" />
    <path d="m60.8 60.3-18.5-19c8.9-9 8.9-23.6-.1-32.5C37.9 4.4 32.1 2 25.9 2S14 4.4 9.6 8.7c-9 9-9 23.6 0 32.6C14.1 45.8 20 48 25.9 48c5.3 0 10.6-1.8 14.9-5.4l18.6 19.1c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4M11.1 39.9c-8.2-8.2-8.2-21.6 0-29.8 4-4 9.3-6.2 14.9-6.2s10.9 2.2 14.9 6.2c8.2 8.2 8.2 21.5 0 29.8-8.3 8.2-21.6 8.2-29.8 0" />
  </svg>
);
export default IconFind;
