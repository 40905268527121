import * as React from "react";
const IconSpiralTool = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.6 16.6c-5.1-5.1-12-8-19.2-8-7.3 0-14.1 2.8-19.2 8-7 7-10.5 16.5-9.8 26.3H4c-1.1 0-2 .9-2 2v8.5c0 1.1.9 2 2 2h8.5c1.1 0 2-.9 2-2v-8.5c0-1.1-.9-2-2-2H9.3c-.7-9.3 2.6-18.3 9.2-24.9 4.8-4.8 11.1-7.4 17.8-7.4s13.1 2.6 17.8 7.4c3.8 3.8 5.8 8.8 5.8 14.1s-2.1 10.3-5.8 14.1c-3 3-6.9 4.6-11.1 4.6s-8.2-1.6-11.1-4.6c-4.8-4.8-4.8-12.7 0-17.6 1.8-1.8 4.3-2.9 6.9-2.9s5 1 6.9 2.9c1.4 1.4 2.2 3.3 2.2 5.4s-.8 3.9-2.2 5.4c-2.3 2.3-6 2.3-8.3 0-.8-.8-1.3-2-1.3-3.2s.5-2.3 1.3-3.2c.4-.4.4-1 0-1.4s-1-.4-1.4 0c-1.2 1.2-1.9 2.9-1.9 4.6s.7 3.4 1.9 4.6c3.1 3.1 8.1 3.1 11.1 0 1.8-1.8 2.8-4.2 2.8-6.8s-1-5-2.8-6.8c-2.2-2.2-5.2-3.4-8.3-3.4s-6.1 1.2-8.3 3.4c-2.7 2.7-4.2 6.3-4.2 10.2 0 3.8 1.5 7.5 4.2 10.2 3.4 3.4 7.8 5.2 12.6 5.2 4.7 0 9.2-1.8 12.6-5.2 4.1-4.1 6.4-9.7 6.4-15.5-.1-5.9-2.4-11.4-6.5-15.5M12.5 53.4H4v-8.5h8.5z" />
  </svg>
);
export default IconSpiralTool;
