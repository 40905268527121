import * as React from "react";
const IconIslandAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62.1 60.4c-.1-.5-.7-.9-1.2-.8-5.1 1.1-8.1-.3-12.6-2.4-4.3-2-9.6-4.5-18.7-6-2.8-.5-5.3-.7-7.6-.7-1.3-4.2-2.5-9.9-1.6-14.9.3-1.8 1.1-3.8 1.9-5.9.9-2.3 1.8-4.7 2.2-7.1v-.1c.1-.7.4-1.3.6-2 3.1.7 8 5 9.3 9.6.1.4.5.7 1 .7h.3c.5-.1.9-.7.7-1.2C35 25 30 20.1 26 18.9c3.3-5.3 10.9-8.2 19-6.8.5.1 1.1-.3 1.2-.8s-.3-1.1-.8-1.2c-8.6-1.6-16.9 1.6-20.7 7.3-.5-4-2.1-8-4-10.7s-4-4.3-5.9-4.7c-.5-.1-1.1.3-1.2.8s.3 1.1.8 1.2c1.4.2 3 1.6 4.5 3.8 1.7 2.6 3.3 6.5 3.7 10.4-4.4-3.8-11.3-4.5-16.5-1.2-.5.3-.6.9-.3 1.4s.9.6 1.4.3c5.1-3.2 11.9-2.1 15.5 2.4 0 .5-.1 1-.2 1.5-.4 2.2-1.3 4.4-2.1 6.6s-1.6 4.2-2 6.2c-.9 5.1.2 10.9 1.5 15.3-12.4.4-17.6 6.3-17.8 6.6-.4.4-.3 1 .1 1.4s1.1.3 1.4-.1c.1-.1 7.3-8.4 25.6-5.4 8.9 1.5 14.1 3.9 18.2 5.8 3.6 1.7 6.4 3 10 3 1.2 0 2.5-.1 3.9-.5.6-.1.9-.6.8-1.1" />
  </svg>
);
export default IconIslandAlt1;
