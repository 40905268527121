import * as React from "react";
const IconWebsiteHouse = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 7H6c-2.2 0-4 1.8-4 4v30.7c0 2.2 1.8 4 4 4h25V55h-5.7c-.6 0-1 .4-1 1s.4 1 1 1h13.4c.6 0 1-.4 1-1s-.4-1-1-1H33v-9.3h25c2.2 0 4-1.8 4-4V11c0-2.2-1.8-4-4-4m2 34.7c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V11c0-1.1.9-2 2-2h52c1.1 0 2 .9 2 2z" />
    <path d="M20.8 19.5c-.4-.3-.9-.3-1.2 0l-8.7 6.6c-.4.3-.5 1-.2 1.4s1 .5 1.4.2l2.3-1.7v7c0 .6.4 1 1 1H25c.6 0 1-.4 1-1v-7l2.3 1.7c.2.1.4.2.6.2.3 0 .6-.1.8-.4.3-.4.3-1.1-.2-1.4zm3.2 5.3v7.1h-7.6v-7.1c0-.1 0-.2-.1-.3l3.9-2.9 3.9 2.9c-.1.1-.1.2-.1.3M37.6 19h8.6c.6 0 1-.4 1-1s-.4-1-1-1h-8.6c-.6 0-1 .4-1 1s.4 1 1 1M50.5 25.7H37.6c-.6 0-1 .4-1 1s.4 1 1 1h12.9c.6 0 1-.4 1-1s-.5-1-1-1M46.1 34.4h-8.6c-.6 0-1 .4-1 1s.4 1 1 1h8.6c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconWebsiteHouse;
