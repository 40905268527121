import * as React from "react";
const IconEcoCare = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M32 13.844v13.354c0 1.451-.548 2.6-1.449 3.363-.879.743-2.013 1.05-3.098 1.004-1.254-.052-2.564-.597-3.444-1.613-.227 1.83.427 3.047.724 3.368l.68.733-1.466 1.36-.68-.733c-.814-.878-1.688-2.947-1.129-5.65a6.4 6.4 0 0 1-2.595.006c-.897-.19-1.779-.599-2.447-1.286C16.412 27.047 16 26.1 16 24.96c0-1.644.587-2.832 1.636-3.7.97-.803 2.287-1.285 3.619-1.716q.468-.15.956-.303c2.539-.797 5.424-1.703 8.143-4.003zm-7.039 13.292c.421-.772 1-1.598 1.782-2.467l.67-.743-1.487-1.338-.67.743c-.99 1.1-1.712 2.17-2.224 3.185-.752.552-1.962.799-3.075.563-.603-.128-1.096-.383-1.428-.724-.316-.324-.529-.768-.529-1.396 0-1.086.35-1.695.911-2.16.639-.528 1.604-.914 2.96-1.353q.443-.142.926-.293c2.079-.649 4.66-1.455 7.203-3.151v9.196c0 .915-.327 1.486-.74 1.835-.435.368-1.053.562-1.723.534-1.328-.055-2.51-.924-2.576-2.431"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="m24 41-.436.9-.004-.002-.012-.006-.043-.021-.16-.08c-.138-.07-.338-.174-.591-.31a48.445 48.445 0 0 1-8.406-5.768C9.784 31.786 5 25.963 5 18.724c0-3.253 1.077-6.404 2.921-8.76C9.771 7.603 12.432 6 15.563 6 19.279 6 22.1 8.107 24 11.078 25.899 8.108 28.72 6 32.438 6c3.13 0 5.791 1.603 7.64 3.965C41.924 12.32 43 15.47 43 18.725c0 6.963-4.792 12.787-9.341 16.772a52 52 0 0 1-8.983 6.276l-.159.085-.043.023-.011.006-.005.002zM9.496 11.197C7.941 13.183 7 15.894 7 18.724c0 6.342 4.216 11.657 8.652 15.473a46.5 46.5 0 0 0 8.335 5.674l.294-.164a50.017 50.017 0 0 0 8.06-5.714C36.791 30.093 41 24.78 41 18.723c0-2.83-.941-5.54-2.496-7.526C36.954 9.218 34.834 8 32.438 8c-3.314 0-5.88 2.167-7.54 5.56L24 15.397l-.898-1.835C21.442 10.167 18.876 8 15.562 8c-2.396 0-4.516 1.218-6.066 3.197"
      clipRule="evenodd"
    />
    <path  d="m24 41 .458.889-.444.228-.45-.217z" />
  </svg>
);
export default IconEcoCare;
