import * as React from "react";
const IconCool11 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 34c-6.5 0-12.2 4.7-13.4 11.1-.1.6 0 1.1.4 1.6s.9.7 1.5.7h23c.6 0 1.2-.3 1.5-.7.4-.4.5-1 .4-1.6C44.2 38.7 38.5 34 32 34M20.5 45.4C21.6 40 26.4 36 32 36s10.4 4 11.5 9.4z" />
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
    <path d="M48.2 16.5H15.8c-.7 0-1.3.7-1.3 1.5v7.8c0 1.7 1.2 3.1 2.6 3.1h9.5c1.3 0 2.8-1.5 2.8-3.3v-4.2h5v4.2c0 1.8 1.1 3.3 2.4 3.3h10c1.4 0 2.6-1.4 2.6-3.1V18c.1-.8-.5-1.5-1.2-1.5" />
  </svg>
);
export default IconCool11;
