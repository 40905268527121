import * as React from "react";
const IconSpeechBubble40 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32.1 3.8C14.9 3.8 2 15.2 2 30.3 2 46 15.5 60 31 60.2h5.2c.5 0 .9-.3 1-.8.1-.4-.1-.9-.5-1.1-4.9-2.7-6.5-4.6-7.5-6.2 9.5.8 18.8-2 25.2-7.6 5-4.4 7.6-9.9 7.6-15.9C62 14.9 48.6 3.8 32.1 3.8m21 39.2c-6.4 5.6-16.2 8.2-25.7 6.9-.4-.1-.8.1-1 .5-.2.3-.2.8 0 1.1s.4.6.5.9c.9 1.5 2 3.4 5.6 5.8H31C16.6 58 4 45 4 30.3c0-14 12.1-24.5 28.1-24.5C47.5 5.8 60 16 60 28.6c0 5.5-2.4 10.5-6.9 14.4" />
    <path d="M19.9 20.7h17.3c.6 0 1-.4 1-1s-.4-1-1-1H19.9c-.6 0-1 .4-1 1s.5 1 1 1M44.1 26.9H19.9c-.6 0-1 .4-1 1s.4 1 1 1h24.2c.6 0 1-.4 1-1s-.5-1-1-1M41.3 36.6H19.9c-.6 0-1 .4-1 1s.4 1 1 1h21.4c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconSpeechBubble40;
