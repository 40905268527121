import * as React from "react";
const IconWifi = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.6 13.1C44.6-2 19.8-1.7 4.4 13.7c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3C20.4.5 43.9.2 58.2 14.5c.4.4 1 .4 1.4 0s.4-1 0-1.4M12.2 28.3c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3c10.1-10.1 26.5-10.3 36.4-.3.4.4 1 .4 1.4 0s.4-1 0-1.4c-10.6-10.7-28.3-10.6-39.2.3M20.3 43.2c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3c5.5-5.5 14.5-5.6 20-.1.4.4 1 .4 1.4 0s.4-1 0-1.4c-6.2-6.2-16.5-6.2-22.8.1M32.1 51.9q-2.1 0-3.6 1.5c-2 2-2 5.2 0 7.2q1.5 1.5 3.6 1.5t3.6-1.5c2-2 2-5.2 0-7.2-1-1-2.3-1.5-3.6-1.5m2.2 7.2c-.6.6-1.3.9-2.2.9-.8 0-1.6-.3-2.2-.9-1.2-1.2-1.2-3.1 0-4.3.6-.6 1.3-.9 2.2-.9.8 0 1.6.3 2.2.9 1.2 1.2 1.2 3.1 0 4.3" />
  </svg>
);
export default IconWifi;
