import * as React from "react";
const IconFunnel = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M26.2 62.2c-.6 0-1.2-.2-1.8-.5-1-.6-1.5-1.6-1.5-2.7V27.2L5.4 6.9c-.7-1-.9-2.3-.4-3.4s1.7-1.8 3-1.8h48c1.3 0 2.5.8 3 2s.3 2.4-.6 3.4l-17.2 20v23.3c0 .6-.2 1.5-1.1 2.4l-.2.2-12 8.9c-.5.2-1.1.3-1.7.3m.5-37.6c.5.6.8 1.5.8 2.1v29.9l9.3-6.9v-23c0-.5 0-1.4.8-2.2L53.2 6.3H10.8zm-3.5 2.9.1.1zM55.1 4.1" />
  </svg>
);
export default IconFunnel;
