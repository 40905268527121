import * as React from "react";
const IconFlutter = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 65 64"
    {...props}
  >
    <path
      
      d="M55.733 29.772 40.121 45.384 55.733 61H37.887l-6.691-6.69-8.925-8.926 15.615-15.612zM37.887 3l-29 29 8.925 8.925L55.733 3z"
    />
  </svg>
);
export default IconFlutter;
