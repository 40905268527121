import * as React from "react";
const IconMapCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <circle cx={33.7} cy={12.9} r={2} />
    <path d="m58 18.6-16.6-3.8q.3-1.05.3-1.8c0-4.5-3.5-8-7.9-8s-8 3.6-8 8c0 1.3.6 3 1.5 4.9l-4.8 1-14-4c-1.5-.4-3.2-.1-4.5.9s-2 2.5-2 4.1v29.6c0 2.3 1.5 4.3 3.7 5L22.1 59l.2.1 19.4-4 14 3.2c.4.1.8.1 1.2.1 1.1 0 2.3-.4 3.2-1.1 1.2-1 1.9-2.4 1.9-4V23.6c0-2.4-1.6-4.5-4-5M33.8 7c3.3 0 5.9 2.6 5.9 6 0 2.3-3.1 7.6-6 11.9-2.9-4.3-5.9-9.6-5.9-11.9-.1-3.4 2.6-6 6-6m-5.7 12.5c1.3 2.5 3 5.1 4.2 6.9.3.5.8.8 1.4.8s1.1-.3 1.4-.7c1.7-2.5 4.2-6.4 5.6-9.7v36.6L23.4 57V20.7l4.7-1zM4 49.4V19.9c0-1 .5-1.9 1.2-2.5.8-.6 1.8-.8 2.8-.6l13.4 3.8v36.1L6.3 52.5C4.9 52.1 4 50.8 4 49.4m56 3.9c0 1-.4 1.9-1.2 2.5q-1.2.9-2.7.6l-13.4-3.1V17.1l14.9 3.4c1.4.3 2.4 1.6 2.4 3.1z" />
  </svg>
);
export default IconMapCopy;
