import * as React from "react";
const IconIdCardAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.1 15H33V7.5c0-.6-.4-1-1-1s-1 .4-1 1V15H7.9C5.2 15 3 17.2 3 19.9v32.8c0 2.7 2.2 4.9 4.9 4.9h48.3c2.7 0 4.9-2.2 4.9-4.9V19.9c-.1-2.7-2.3-4.9-5-4.9M7.9 17h48.3c1.6 0 2.9 1.3 2.9 2.9v3.7H5v-3.7C5 18.3 6.3 17 7.9 17m48.2 38.5H7.9c-1.6 0-2.9-1.3-2.9-2.9v-27h54v27.1c0 1.5-1.3 2.8-2.9 2.8" />
    <path d="M23.8 41.7c1.5-1.1 2.4-2.8 2.4-4.8 0-3.3-2.7-6-6-6s-6 2.7-6 6c0 2 1 3.7 2.4 4.8-2.7 1.3-4.7 4.1-4.7 7.4 0 .6.4 1 1 1s1-.4 1-1c0-3.4 2.8-6.2 6.2-6.2s6.2 2.8 6.2 6.2c0 .6.4 1 1 1s1-.4 1-1c.2-3.2-1.7-6-4.5-7.4M16.3 37c0-2.2 1.8-4 4-4s4 1.8 4 4-1.8 4-4 4-4-1.9-4-4M50.9 36H38.6c-.6 0-1 .4-1 1s.4 1 1 1h12.3c.6 0 1-.4 1-1s-.4-1-1-1M46.6 43.6h-7.9c-.6 0-1 .4-1 1s.4 1 1 1h7.9c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconIdCardAlt;
