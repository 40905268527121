import * as React from "react";
const IconSquares = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 3C16 3 3 16 3 32s13 29 29 29 29-13 29-29S48 3 32 3m0 56C17.1 59 5 46.9 5 32S17.1 5 32 5s27 12.1 27 27-12.1 27-27 27" />
    <path d="M42.3 17.9h-9.9c-2.6 0-4.6 2.1-4.6 4.6V27h-6.1c-2.6 0-4.6 2.1-4.6 4.6v9.9c0 2.6 2.1 4.6 4.6 4.6h9.9c2.6 0 4.6-2.1 4.6-4.6V37h6.1c2.6 0 4.6-2.1 4.6-4.6v-9.9c0-2.6-2.1-4.6-4.6-4.6m-8.1 23.6c0 1.5-1.2 2.6-2.6 2.6h-9.9c-1.5 0-2.6-1.2-2.6-2.6v-9.9c0-1.5 1.2-2.6 2.6-2.6h6.1v3.4c0 2.6 2.1 4.6 4.6 4.6h1.8zm0-6.5h-1.8c-1.5 0-2.6-1.2-2.6-2.6V29h1.8c1.5 0 2.6 1.2 2.6 2.6zm10.7-2.6c0 1.5-1.2 2.6-2.6 2.6h-6.1v-3.4c0-2.6-2.1-4.6-4.6-4.6h-1.8v-4.5c0-1.5 1.2-2.6 2.6-2.6h9.9c1.5 0 2.6 1.2 2.6 2.6z" />
  </svg>
);
export default IconSquares;
