import * as React from "react";
const IconPaperclipCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M47.8 20.4c-.6 0-1 .4-1 1v25c.1 3.7-1.4 7-4.1 9.7-2.8 2.6-6.5 4.1-10.3 4-4.1 0-8.4-1.6-11-4.1-2.7-2.6-4.3-6.1-4.3-9.6V14.2c0-3.4 1.7-6.4 4.6-8.3 2.9-2 6.4-2.5 9.8-1.3l.3.1c.4.1.8.2 1.2.5 3.7 1.9 5.9 5.3 5.9 9.1v27.4c0 2-.7 3.9-1.9 5.3-.2.3-.4.4-.5.5q-1.95 1.8-4.5 1.8c-1.9 0-3.8-.9-5-2.3-1.3-1.5-2-3.4-2-5.4v-23c0-.6-.4-1-1-1s-1 .4-1 1v23c0 2.5.9 4.8 2.5 6.7s4 3 6.5 3c2.2 0 4.2-.8 5.8-2.4.1-.1.4-.3.6-.7 1.6-1.8 2.4-4.1 2.4-6.6V14.3c0-4.6-2.6-8.6-6.9-10.8q-.75-.45-1.5-.6l-.3-.1c-4-1.3-8.2-.8-11.6 1.5-3.5 2.3-5.4 5.9-5.4 9.9v32.1c0 4.1 1.8 8.1 4.9 11.1 3 2.8 7.8 4.6 12.3 4.6h.5c4.1 0 8.2-1.6 11.2-4.5 3.2-3.1 4.8-7 4.8-11.2V21.4c0-.6-.4-1-1-1" />
  </svg>
);
export default IconPaperclipCopy;
