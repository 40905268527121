import * as React from "react";
const IconCodeCommit = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60 31H43.5C43 25.1 38.1 20.5 32 20.5S21.1 25.1 20.6 31H4c-.6 0-1 .4-1 1s.4 1 1 1h16.6c.5 5.9 5.4 10.5 11.5 10.5S43 38.9 43.5 33H60c.6 0 1-.4 1-1s-.4-1-1-1M32.1 41.5c-5.2 0-9.5-4.3-9.5-9.5s4.3-9.5 9.5-9.5 9.5 4.3 9.5 9.5-4.3 9.5-9.5 9.5" />
  </svg>
);
export default IconCodeCommit;
