import * as React from "react";
const IconNavigation = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.7 33.5 7 3.4c-1.1-.6-2.4-.5-3.2.4-.9.8-1 2.1-.4 3.2l30.2 52.7c.5.8 1.4 1.3 2.3 1.3h.1c1 0 1.9-.7 2.3-1.6l6.6-14.6 14.6-6.6c.9-.4 1.5-1.3 1.6-2.3-.1-.9-.6-1.9-1.4-2.4m-1.1 2.9-14.3 6.4-8.2-8.2c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l8.2 8.2-6.4 14.3c-.2.4-.5.5-.6.5s-.4 0-.6-.3L5.1 6c-.2-.4 0-.7.1-.8s.2-.2.5-.2c.1 0 .2 0 .3.1l52.7 30.2c.3.2.3.5.3.6s-.1.4-.4.5" />
  </svg>
);
export default IconNavigation;
