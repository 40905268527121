import * as React from "react";
const IconTriangle9 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m54.9 51-15-43.4C38.7 4.2 35.6 2 32 2s-6.7 2.2-7.8 5.6L9.1 51c-.9 2.6-.5 5.3 1.1 7.5s4 3.5 6.8 3.5h30c2.7 0 5.2-1.3 6.8-3.5s2-4.9 1.1-7.5m-2.7 6.4C51 59 49.1 60 47 60H17c-2.1 0-3.9-1-5.1-2.6-1.2-1.7-1.5-3.8-.8-5.7L26 8.2C27.1 5.1 29.8 4 32 4s4.9 1.1 6 4.2l15 43.4c.7 2 .4 4.1-.8 5.8" />
  </svg>
);
export default IconTriangle9;
