import * as React from "react";
const IconHandWipePaper = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M8 6v7.857c0 .549.23 1.088.662 1.495a2.44 2.44 0 0 0 1.671.648H12a1 1 0 1 1 0 2h-1.667a4.44 4.44 0 0 1-3.043-1.193A4.06 4.06 0 0 1 6 13.857V6zM42 6v7.857a4.05 4.05 0 0 1-1.29 2.95A4.44 4.44 0 0 1 37.667 18H36a1 1 0 1 1 0-2h1.667c.637 0 1.237-.24 1.67-.648.432-.407.663-.946.663-1.495V6z"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M11 12a1 1 0 0 1 1-1h24a1 1 0 0 1 1 1v24a1 1 0 0 1-1.447.894L33 35.618l-2.553 1.276a1 1 0 0 1-.894 0L27 35.618l-2.553 1.276a1 1 0 0 1-.84.025l-2.93-1.255a1 1 0 1 1 .788-1.838l2.503 1.072 2.585-1.292a1 1 0 0 1 .894 0L30 34.882l2.553-1.276a1 1 0 0 1 .894 0l1.553.776V13H13v16a1 1 0 1 1-2 0z"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M12.848 23.47a1 1 0 0 1-.317 1.378l-1.29.807a7 7 0 0 0-2.386 2.467A6.45 6.45 0 0 0 8 31.319V41a1 1 0 1 1-2 0v-9.681a8.45 8.45 0 0 1 1.117-4.188 9 9 0 0 1 3.062-3.17l1.29-.809a1 1 0 0 1 1.379.317M21.235 31.082a1.815 1.815 0 0 0-3.178-1.755l-3.676 6.535-1.743-.98 3.676-6.535a3.815 3.815 0 0 1 6.68 3.687l-5.115 9.442a1 1 0 0 1-1.758-.952z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconHandWipePaper;
