import * as React from "react";
const IconIdentity = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M45.7 2H18.3c-2.8 0-5 2.2-5 5v50c0 2.8 2.2 5 5 5h27.4c2.8 0 5-2.2 5-5V7c0-2.8-2.2-5-5-5m3 55c0 1.7-1.3 3-3 3H18.3c-1.7 0-3-1.3-3-3V7c0-1.7 1.3-3 3-3h27.4c1.7 0 3 1.3 3 3z" />
    <path d="M32 42.3c3.3 0 5.9-2.7 5.9-5.9s-2.7-5.9-5.9-5.9-5.9 2.7-5.9 5.9 2.6 5.9 5.9 5.9m0-9.9c2.2 0 3.9 1.8 3.9 3.9 0 2.2-1.8 3.9-3.9 3.9-2.2 0-3.9-1.8-3.9-3.9s1.7-3.9 3.9-3.9M32.1 46.2c-4 0-7.6 2-9.8 5.4-.3.4-.1 1.1.3 1.4.2.1.4.2.5.2.3 0 .7-.2.8-.5 1.8-2.8 4.8-4.4 8.1-4.4 3 0 6 1.7 7.9 4.5.3.5.9.6 1.4.3s.6-.9.3-1.4c-2.2-3.5-5.8-5.5-9.5-5.5M27.4 16.8H31v3.6c0 .6.4 1 1 1s1-.4 1-1v-3.6h3.6c.6 0 1-.4 1-1s-.4-1-1-1H33v-3.6c0-.6-.4-1-1-1s-1 .4-1 1v3.6h-3.6c-.6 0-1 .4-1 1s.5 1 1 1" />
  </svg>
);
export default IconIdentity;
