import * as React from "react";
const IconBarCheck = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 60h-9.2v-6.6c0-.6-.4-1-1-1s-1 .4-1 1V60H33.7V42.1c0-.2-.1-.3-.1-.5 1.1.3 2.3.4 3.5.4 3.5 0 7.1-1.3 9.9-3.8 0 .2.1.3.2.4l8.4 8.4c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-8.4-8.4c-.1-.1-.3-.2-.4-.2 5.2-5.8 5-14.8-.6-20.4-5.8-5.8-15.2-5.8-21 0s-5.8 15.2 0 21c1.7 1.7 3.6 2.8 5.7 3.5-.3.2-.5.5-.5.9v18H16.2V44.5c0-.6-.4-1-1-1s-1 .4-1 1V60H6.7C5.2 60 4 58.8 4 57.3V3c0-.6-.4-1-1-1s-1 .4-1 1v54.3C2 59.9 4.1 62 6.7 62H61c.6 0 1-.4 1-1s-.4-1-1-1M27.9 18c2.5-2.5 5.8-3.8 9.1-3.8s6.6 1.3 9.1 3.8c5 5 5 13.2 0 18.2s-13.2 5-18.2 0c-5.1-5-5.1-13.1 0-18.2" />
  </svg>
);
export default IconBarCheck;
