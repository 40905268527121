import * as React from "react";
const IconIceCreamAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.7 33.8h-3.9c.6-.8 1-1.9 1-3v-2.5c0-2.6-2-4.7-4.6-5 .7-.8 1.1-1.9 1.1-3v-2.5c0-2.8-2.2-5-5-5h-.1c.6-4.7-2.4-10.1-2.5-10.3-.2-.4-.6-.5-1-.5-.4.1-.7.3-.8.7-1.2 4.4-7.1 9.1-8.4 10.1h-3.8c-2.8 0-5 2.2-5 5v2.5c0 1.2.4 2.2 1.1 3-2.5.2-4.6 2.3-4.6 5v2.5c0 1.1.4 2.2 1 3h-3.9c-.6 0-1 .4-1 1s.4 1 1 1h5l2.1 20.6c.3 3.2 3 5.6 6.2 5.6h12.9c3.2 0 5.9-2.4 6.2-5.6l2.1-20.6h5c.6 0 1-.4 1-1s-.5-1-1.1-1M36.9 5.5c.8 1.9 1.7 4.8 1.3 7.3h-7.5c1.9-1.7 4.7-4.4 6.2-7.3M20.7 17.8c0-1.7 1.3-3 3-3h16.6c1.7 0 3 1.3 3 3v2.5c0 1.7-1.3 3-3 3H23.7c-1.7 0-3-1.3-3-3zm-3.5 10.5c0-1.7 1.3-3 3-3h23.7c1.7 0 3 1.3 3 3v2.5c0 1.7-1.3 3-3 3H20.2c-1.7 0-3-1.3-3-3zM22 58c-.3-.5-.6-1.2-.7-1.9l-.7-7.3 11.1-13.1h8.9zm-1.8-22.2h8.9l-8.7 10.3-1-10.3zM38.4 60h-3.5l8.3-9.8-.6 6c-.2 2.2-2 3.8-4.2 3.8m5.2-13.3L32.3 60h-6.7q-1.2 0-2.1-.6l19.7-23.6h1.5z" />
  </svg>
);
export default IconIceCreamAlt1;
