import React, { useState, useEffect, useRef } from 'react';
import { useNavigation } from 'context/NavigationContext';
import { BringzzPageContainer, BringzzIcon, BringzzText, BringzzInput } from "@bringzz/components";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import classNames from 'classnames';
import LocationCard from './LocationCard';

const Modes = Object.freeze({
    MAP: 'MAP',
    LIST: 'LIST',
});

const pharmacies = [
    { lat: -34.397, lng: 150.644, text: "Pharmacy 1" },
    { lat: -34.307, lng: 150.654, text: "Pharmacy 2" },
    { lat: -34.517, lng: 150.664, text: "Pharmacy 3" },
    { lat: -34.327, lng: 150.674, text: "Pharmacy 4" },
    { lat: -34.437, lng: 150.684, text: "Pharmacy 5" }
]

const defaultCenter = { lat: -34.397, lng: 150.644 };
const defaultZoom = 12;
const SearchPharmacyPage = () => {
    const { backPress } = useNavigation();
    const [mode, setMode] = useState(Modes.LIST);
    const [map, setMap] = useState(null);
    const [userLocation, setUserLocation] = useState(defaultCenter);
    const [currentIndex, setCurrentIndex] = useState(0);

    const ref = useRef();
    const scrollRef = useRef();

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const { latitude, longitude } = position.coords;
                setUserLocation({ lat: latitude, lng: longitude });
            });
        }
    }, []);

    useEffect(() => {
        if (ref.current && !map && userLocation) {
            const mapInstance = new window.google.maps.Map(ref.current, {
                center: userLocation,
                zoom: defaultZoom,
                disableDoubleClickZoom: true,
                fullscreenControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                zoomControl: false,
                styles: [
                    {
                        featureType: "poi",
                        stylers: [{ visibility: "off" }],
                    },
                ],
            });

            setMap(mapInstance);

            // Add user's location marker
            new window.google.maps.Marker({
                position: userLocation,
                map: mapInstance,
                title: "You are here",
                icon: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png' // Use the blue default pin
            });

            // Add markers for pharmacies
            pharmacies.forEach(coord => {
                new window.google.maps.Marker({
                    position: { lat: coord.lat, lng: coord.lng },
                    map: mapInstance,
                    title: coord.text
                });
            });
        }
    }, [userLocation, map]);

    const toggleMode = () => {
        setMode((prevMode) => (prevMode === Modes.MAP ? Modes.LIST : Modes.MAP));
    };
    const render = (status) => {
        switch (status) {
            case Status.LOADING:
                return <span>loading</span>;
            case Status.FAILURE:
                return <span>Error</span>;
            case Status.SUCCESS:
                return (
                    <div ref={ref} style={{ height: '100%' }} />
                );
        }
    };

    const getCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const { latitude, longitude } = position.coords;
                setUserLocation({ lat: latitude, lng: longitude });
                map && map.setCenter({ lat: latitude, lng: longitude });
                new window.google.maps.Marker({
                    position: { lat: latitude, lng: longitude },
                    map,
                    title: "You are here"
                });
            });
        }
    };
    const handleScroll = () => {
        const scrollLeft = scrollRef.current.scrollLeft;
        const cardWidth = scrollRef.current.firstChild.offsetWidth;
        const index = Math.round(scrollLeft / cardWidth);

        scrollRef.current.scrollTo({
            left: index * cardWidth,
            behavior: 'smooth'
        });
        if (index !== currentIndex) {
            setCurrentIndex(index);
            const selectedPharmacy = pharmacies[index];
            map && map.setCenter({ lat: selectedPharmacy.lat, lng: selectedPharmacy.lng });
        }
    }
    const handleCardClick = (index) => {
        setCurrentIndex(index);
        const selectedPharmacy = pharmacies[index];
        map && map.setCenter({ lat: selectedPharmacy.lat, lng: selectedPharmacy.lng });
        scrollRef.current.scrollTo({
            left: index * scrollRef.current.firstChild.offsetWidth,
            behavior: 'smooth'
        });
    };

    return (
        <BringzzPageContainer
            className="bg-white pb-24"
            authenticated={false}
            footerHover={true}
            header={{
                title: "Find pharmacy nearby",
                left: (
                    <BringzzIcon
                        size='22'
                        icon='IconChevronLeft'
                        folder='LineIcons'
                        className='cursor-pointer'
                        onClick={backPress}
                    />
                ),
                right: (
                    <div className='flex items-center space-x-2'>
                        <BringzzIcon
                            size='22'
                            icon='IconListAlt1'
                            folder='LineIcons'
                            className={classNames('cursor-pointer', mode == Modes.LIST && "text-magic-lilac")}
                            onClick={toggleMode}
                        />
                        <BringzzIcon
                            size='22'
                            icon='IconMapMarker'
                            folder='LineIcons'
                            className={classNames('cursor-pointer', mode == Modes.MAP && "text-magic-lilac")}
                            onClick={toggleMode}
                        />
                    </div>
                )
            }}
        >
            {mode === Modes.MAP && (
                <div className='h-full relative'>
                    <Wrapper
                        apiKey={"AIzaSyBpJtJ2NNTVui0Kd7HSYlOzCctbIPfziBA"}
                        render={render}
                    />
                    <BringzzIcon
                        size='22'
                        icon='IconSend'
                        folder='LineIcons'
                        className="absolute cursor-pointer top-4 right-4 bg-white text-slate-600 p-3 -rotate-45 rounded-full shadow-lg"
                        onClick={getCurrentLocation}
                    />
                    <div
                        ref={scrollRef}
                        className="absolute bottom-14 left-0 right-0 p-6 flex overflow-x-auto space-x-4"
                        onScroll={handleScroll}
                        style={{ scrollSnapType: 'x mandatory', scrollBehavior: 'smooth' }}
                    >
                        {pharmacies.map((pharmacy, index) => {
                            return <LocationCard key={index} className="w-9/10 scroll-snap-align-start flex-shrink-0 cursor-pointer" onClick={() => handleCardClick(index)} />
                        })}
                    </div>
                    <div
                        className="absolute bg-white bottom-0 left-0 right-0 px-6 py-3 shadow-lg"
                    >

                        <BringzzInput
                            placeholder='Search'
                            icon={{
                                left: <BringzzIcon
                                    size="20"
                                    icon="IconSearchAlt"
                                    folder="LineIcons"
                                    className="cursor-pointer"
                                />,
                            }}
                        />
                    </div>
                </div>
            )}
            {mode === Modes.LIST && (
                <div className='p-6 space-y-4'>
                    {pharmacies.map((pharmacy, index) => {
                        return <LocationCard key={index} />
                    })}
                </div>
            )}
        </BringzzPageContainer>
    );
}

export default SearchPharmacyPage;
