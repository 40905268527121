import * as React from "react";
const IconCandy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.8 7 57 4.1c-1.7-1.7-4.1-2.6-6.6-2.3-2.4.3-4.6 1.6-6 3.7l-3.3 5.1c-6.3-1.9-13.4-.3-18.4 4.6L15 22.8c-3.5 3.5-5.4 8.1-5.4 13 0 1.9.3 3.7.8 5.4l-5 3.2c-2.1 1.3-3.5 3.5-3.7 6-.3 2.5.6 4.9 2.3 6.6l2.9 2.9c1.5 1.5 3.6 2.4 5.7 2.4h.8c2.4-.3 4.6-1.6 5.9-3.6l3.4-5.1c1.7.5 3.5.8 5.2.8 4.7 0 9.5-1.8 13.1-5.4l2.8-2.8 4.8-4.7c3.5-3.5 5.4-8.1 5.4-13 0-1.9-.3-3.7-.8-5.5l4.9-3.1c2.1-1.3 3.5-3.5 3.8-6s-.3-5.2-2.1-6.9m-44 49.1c-.6.9-1.6 1.5-2.7 1.6s-2.2-.3-2.9-1l-2.9-2.9c-.8-.8-1.2-1.9-1-3 .1-1.1.7-2 1.7-2.6l4.4-2.8c.8 1.2 1.7 2.4 2.7 3.5 1.1 1.1 2.4 2 3.7 2.8zm2.5-10.5c-2.6-2.6-4.1-6.1-4.1-9.8s1.5-7.2 4.1-9.8l1.5-1.5c1.2.7 2.3 1 3.2 1.1 1.1.2 1.7.3 2.7 1.3.9.9 1.1 1.6 1.3 2.7.3 1.3.6 3.1 2.5 5s3.7 2.3 5 2.5c1.1.2 1.7.3 2.7 1.3.9.9 1.1 1.6 1.3 2.7.2.9.4 2 1.1 3.2l-1.5 1.4c-5.5 5.4-14.3 5.4-19.8-.1M45.7 38 43 40.8c0-.2-.1-.4-.1-.6-.3-1.3-.6-3.1-2.5-5s-3.7-2.3-5-2.5c-1.1-.2-1.7-.3-2.7-1.3-.9-.9-1.1-1.6-1.3-2.7-.3-1.3-.6-3.1-2.5-5s-3.7-2.3-5-2.5c-.2 0-.4-.1-.6-.1l2.8-2.7c2.7-2.7 6.3-4.1 9.9-4.1s7.2 1.4 9.9 4.1c2.6 2.6 4.1 6.1 4.1 9.8s-1.6 7.2-4.3 9.8m12-24.9c-.1 1.1-.7 2.1-1.7 2.7l-4.4 2.8c-.8-1.2-1.6-2.4-2.7-3.4-1.1-1.1-2.3-2-3.6-2.8l2.9-4.5c.6-.9 1.6-1.5 2.7-1.6s2.2.3 2.9 1l2.9 2.9c.7.7 1.1 1.8 1 2.9" />
  </svg>
);
export default IconCandy;
