import * as React from "react";
const IconVrMotion = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M38.9 25.5c1 0 1.7-.8 1.7-1.7 0-1-.8-1.7-1.7-1.7-1 0-1.7.8-1.7 1.7s.8 1.7 1.7 1.7" />
    <path d="M18.4 38.6c3.4 0 6.1-2.5 6.4-5.7h14.5c.3 3.2 3 5.8 6.4 5.8 3.5 0 6.4-2.8 6.4-6.3v-12c0-1.6-.6-3.2-1.8-4.4s-2.9-1.9-4.6-1.9H33V3c0-.6-.4-1-1-1s-1 .4-1 1v11.1H18.4c-3.5 0-6.4 2.8-6.4 6.3v11.8c-.1 3.6 2.8 6.4 6.4 6.4m-4.5-18.2c0-2.4 2-4.3 4.4-4.3h27.3c1.2 0 2.3.5 3.2 1.3.8.8 1.2 1.9 1.2 3v11.9c0 2.4-2 4.3-4.4 4.3s-4.4-1.9-4.4-4.3c0-.8-.6-1.5-1.5-1.5H24.2c-.8 0-1.5.6-1.5 1.5 0 2.4-2 4.3-4.4 4.3s-4.4-1.9-4.4-4.3z" />
    <path d="M18.6 24.8h2v2c0 .6.4 1 1 1s1-.4 1-1v-2h2c.6 0 1-.4 1-1s-.4-1-1-1h-2v-2c0-.6-.4-1-1-1s-1 .4-1 1v2h-2c-.6 0-1 .4-1 1s.4 1 1 1M45.9 25.5c1 0 1.7-.8 1.7-1.7 0-1-.8-1.7-1.7-1.7s-1.7.8-1.7 1.7c-.1.9.7 1.7 1.7 1.7" />
    <circle cx={42.4} cy={20.3} r={1.7} />
    <circle cx={42.4} cy={27.2} r={1.7} />
    <path d="M27.7 57.7c.5 0 1-.4 1-1s-.4-1-1-1c-10-.5-16.7-3.2-16.7-5.4 0-1.6 3.6-3.7 10.5-4.8.5-.1.9-.6.8-1.1s-.6-.9-1.1-.8C17.6 44.1 9 45.9 9 50.3c0 4.5 9.4 7 18.7 7.4q-.15 0 0 0M44.3 43.8c-.5-.1-1.1.3-1.2.8s.3 1.1.8 1.2C50.1 47 53 49 53 50.4c0 1.9-5.2 4.4-13.8 5.2-.5.1-1 .5-.9 1.1 0 .5.5.9 1 .9h.1c7.5-.7 15.6-3 15.6-7.2 0-2.9-3.9-5.3-10.7-6.6" />
    <path d="M27.3 51.7c-.4-.4-1-.3-1.4.1s-.3 1 .1 1.4l3.7 3.2-3.7 3.9c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l4.6-4.6c.2-.2.3-.5.3-.7s-.1-.5-.3-.7z" />
  </svg>
);
export default IconVrMotion;
