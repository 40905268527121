import * as React from "react";
const IconAncv = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M19.5 11a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5m0 2a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9M32 16a4 4 0 1 0 0-8 4 4 0 0 0 0 8m0 2a6 6 0 1 0 0-12 6 6 0 0 0 0 12"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M32 9a1 1 0 0 1 1 1v1.697l.832 1.248a1 1 0 0 1-1.664 1.11L31 12.303V10a1 1 0 0 1 1-1M15.08 15.307c.273-.12.779-.307 1.42-.307h2c.571 0 1.027.164 1.15.209l.009.002c.2.072.385.156.538.23.313.153.654.347 1.004.568a20 20 0 0 1 2.468 1.864c1.778 1.564 3.884 3.932 5.116 7.013.784 1.958.728 3.847 0 5.492-.682 1.538-1.836 2.603-2.818 3.297-3.05 2.154-3.193 3.554-3.969 7.434A3 3 0 0 1 16 41c0-5.133-.002-8.187-.234-11.075a3 3 0 0 1-4.136-.586 46 46 0 0 1-1.516-2.17 27 27 0 0 1-.88-1.412 9 9 0 0 1-.377-.729c-.057-.127-.13-.3-.192-.499A3.4 3.4 0 0 1 8.5 23.5c0-.716.26-1.272.318-1.393v-.003l.003-.004c.094-.206.2-.39.284-.53.173-.286.387-.599.606-.9a34 34 0 0 1 1.636-2.06c.6-.7 1.279-1.437 1.914-2.024.311-.288.678-.6 1.065-.86.194-.131.45-.287.753-.42M16.5 17c-.735 0-1.375.586-1.882 1.055-.555.513-1.177 1.186-1.752 1.857a32 32 0 0 0-1.535 1.93c-.252.348-.514.704-.694 1.095a1.35 1.35 0 0 0-.137.563c0 .457.254.883.472 1.267.223.391.516.854.811 1.3a45 45 0 0 0 1.427 2.046 1 1 0 0 0 1.58-1.226 44 44 0 0 1-1.88-2.77l-.345-.569.39-.539a30 30 0 0 1 1.797-2.217l1.338-1.504.39 1.974C17.992 28.909 18 31.423 18 41a1 1 0 0 0 1 1c.621 0 1-.521 1-1.099.816-4.08 1.295-6.375 4.813-8.86.816-.575 1.66-1.385 2.143-2.474.498-1.124.567-2.452-.027-3.938-1.078-2.694-2.947-4.817-4.58-6.255a18 18 0 0 0-2.214-1.673c-.365-.23-.745-.46-1.152-.607A1.4 1.4 0 0 0 18.5 17z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconAncv;
