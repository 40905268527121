import * as React from "react";
const IconSunsetAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 16.4c-7.3 0-13.3 6-13.3 13.3S24.7 43 32 43s13.3-6 13.3-13.3-6-13.3-13.3-13.3M32 41c-6.2 0-11.3-5.1-11.3-11.3S25.8 18.4 32 18.4s11.3 5.1 11.3 11.3C43.3 36 38.2 41 32 41M32 9.3c.6 0 1-.4 1-1V4c0-.6-.4-1-1-1s-1 .4-1 1v4.3c0 .6.4 1 1 1M49.5 10.8l-3.1 3.1c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l3.1-3.1c.4-.4.4-1 0-1.4s-1-.4-1.4 0M16.2 44.1l-3.1 3.1c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l3.1-3.1c.4-.4.4-1 0-1.4s-1-.4-1.4 0M57.7 28.7h-4.3c-.6 0-1 .4-1 1s.4 1 1 1h4.3c.6 0 1-.4 1-1s-.4-1-1-1M10.6 28.7H6.3c-.6 0-1 .4-1 1s.4 1 1 1h4.3c.6 0 1-.4 1-1s-.4-1-1-1M47.8 44.1c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l3.1 3.1c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4zM14.5 10.8c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l3.1 3.1c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4zM38 53.5l-5 4.3v-9.7c0-.6-.4-1-1-1s-1 .4-1 1v9.7l-5-4.3c-.4-.4-1-.3-1.4.1s-.3 1 .1 1.4l6.6 5.7c.2.2.4.2.7.2.2 0 .5-.1.7-.2l6.6-5.7c.4-.4.5-1 .1-1.4s-1-.5-1.4-.1" />
  </svg>
);
export default IconSunsetAlt;
