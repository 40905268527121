import * as React from "react";
const IconCrow = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M52.4 8.9H51c-1.9 0-3.5 1.1-4.3 2.7-4.5.1-7.4 2.1-7.5 2.2-.3.2-.5.6-.4 1s.3.7.7.8l6.5 1.9c.3.1.5.3.5.6V20L10.9 45l-7.5 5.3c-.5.3-.6.9-.2 1.4.2.2.5.3.8.3.2 0 .4-.1.6-.2l7.2-5.1 15.6-1 4 .1 3.1 8.6c.1.4.5.7.9.7.1 0 .2 0 .3-.1.5-.2.8-.8.6-1.3l-2.8-7.9 6.7.1 3 8.5c.1.4.5.7.9.7.1 0 .2 0 .3-.1.5-.2.8-.8.6-1.3L42.3 46h1.2C53.2 46 61 38.2 61 28.5v-11c0-4.7-3.9-8.6-8.6-8.6m-7.5 21.7c-3.1 4.4-10 6.8-20.2 7.1l21.8-15.3c.3 1.8.5 5.2-1.6 8.2M59 28.5C59 37.1 52.1 44 43.5 44l-16.2-.2h-.1l-12.2.8 6.8-4.8h.7c12.3 0 20.4-2.7 24.1-8 3-4.3 2.1-8.9 1.6-10.7.1-.2.2-.3.2-.5v-2.4c0-1.2-.8-2.2-1.9-2.5l-4-1.2c1.2-.4 2.9-.8 4.8-.8.4 0 .9-.3 1-.7.3-1.2 1.4-2 2.6-2h1.4c3.6 0 6.6 3 6.6 6.6v10.9z" />
  </svg>
);
export default IconCrow;
