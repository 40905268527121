import * as React from "react";
const IconSearchStore = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.9 14.7 49.8 4.3C49 2.9 47.4 2 45.8 2H18.2c-1.6 0-3.1.9-4 2.3L8.1 14.7c-.1.2-.2.3-.2.5v7c0 2.3 1.4 4.2 3.3 5.1v19.3c0 2.5 2.1 4.6 4.6 4.6h8.1l1.2 1.5c2.3 2.3 5.3 3.5 8.5 3.5 2.9 0 5.6-1 7.8-2.9l8.4 8.4c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-8.4-8.4c.2-.2.4-.5.6-.7h4.7c2.5 0 4.6-2.1 4.6-4.6V27.3c1.9-.9 3.3-2.8 3.3-5.1v-7c.1-.2 0-.3-.1-.5m-13.4 7.4v.1c0 1.9-1.6 3.5-3.5 3.5h-2.5c-1.9 0-3.5-1.6-3.5-3.5V4h5.5l4.1 12.7v5.4zM31 4v18.2c0 1.9-1.6 3.5-3.5 3.5H25c-1.9 0-3.5-1.6-3.5-3.5v-5.5l4-12.7zM9.9 22.2v-6.7l6-10.2c.5-.8 1.4-1.3 2.3-1.3h5.3l-3.9 12.2v6c0 1.9-1.6 3.5-3.5 3.5h-2.5c-2.1 0-3.7-1.5-3.7-3.5m16.7 29c-3.9-3.9-3.9-10.3 0-14.2 2-2 4.5-2.9 7.1-2.9s5.2 1 7.1 2.9 2.9 4.4 2.9 7.1-1 5.2-2.9 7.1-4.4 2.9-7.1 2.9c-2.7.1-5.2-1-7.1-2.9m24.2-4.6c0 1.4-1.2 2.6-2.6 2.6h-3.5c.7-1.6 1.1-3.3 1.1-5.1 0-3.2-1.3-6.3-3.5-8.5-4.7-4.7-12.4-4.7-17.1 0-3.7 3.7-4.5 9.1-2.4 13.6h-6.9c-1.4 0-2.6-1.2-2.6-2.6V27.7H16c1.9 0 3.5-.9 4.5-2.4 1 1.4 2.7 2.4 4.5 2.4h2.5c1.9 0 3.5-.9 4.5-2.4 1 1.4 2.7 2.4 4.5 2.4H39c1.9 0 3.5-.9 4.5-2.4 1 1.4 2.7 2.4 4.5 2.4h2.7v18.9zm3.3-24.4c0 1.9-1.6 3.5-3.5 3.5h-2.5c-1.9 0-3.5-1.6-3.5-3.5v-6L40.6 4h5.3c.9 0 1.8.5 2.2 1.3l6 10.2z" />
  </svg>
);
export default IconSearchStore;
