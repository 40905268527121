import * as React from "react";
const IconAlarmClockAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M31.3 10.8c-12.5 0-22.6 10.1-22.6 22.6 0 6.2 2.5 11.7 6.5 15.8l-5.4 8.1c-.3.5-.2 1.1.3 1.4.2.1.4.2.6.2.3 0 .6-.2.8-.4l5.2-7.8c3.9 3.4 9 5.4 14.6 5.4s10.7-2 14.6-5.4l5.2 7.8c.2.3.5.4.8.4.2 0 .4-.1.6-.2.5-.3.6-.9.3-1.4l-5.4-8.1c4-4.1 6.5-9.7 6.5-15.8 0-12.5-10.1-22.6-22.6-22.6M10.7 33.4c0-11.4 9.2-20.6 20.6-20.6S51.9 22 51.9 33.4 42.7 54 31.3 54c-11.3-.1-20.6-9.3-20.6-20.6M61.8 17.9 51 5.5c-.4-.4-1-.5-1.4-.1s-.5 1-.1 1.4l10.8 12.3c.2.2.5.3.8.3.2 0 .5-.1.7-.2.3-.3.3-.9 0-1.3M14.4 5.4c-.4-.4-1-.3-1.4.1L2.2 17.9c-.4.4-.3 1 .1 1.4.2.2.4.2.7.2s.6-.1.8-.3L14.5 6.9c.4-.5.4-1.1-.1-1.5" />
    <path d="M40.4 35.6h-8.1v-9.5c0-.6-.4-1-1-1s-1 .4-1 1v10.5c0 .6.4 1 1 1h9.1c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconAlarmClockAlt;
