import * as React from "react";
const IconSpeechBubble15 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.7 8c-1-1.2-2.4-1.9-3.9-2.1l-35-3.2c-1.6-.1-3.2.4-4.3 1.5-1.2 1.1-1.9 2.6-1.9 4.2v26.5L2.5 42.1c-.3.2-.5.6-.5.9 0 .4.3.7.6.9l13 5v6.7c0 1.6.7 3.2 2 4.3 1 .9 2.4 1.4 3.7 1.4.3 0 .5 0 .8-.1L52.6 57c2.6-.4 4.6-2.4 4.9-5L62 12.3c.1-1.6-.3-3.1-1.3-4.3m-.7 4-4.5 39.8c-.2 1.7-1.5 3-3.1 3.2l-30.5 4.2c-1.1.1-2.1-.2-2.9-.9s-1.3-1.7-1.3-2.8v-7.3c0-.4-.3-.8-.6-.9L5.4 42.8l11.7-6.5c.3-.2.5-.5.5-.9v-27c0-1 .4-2 1.2-2.7s1.8-1 2.8-.9l35 3.2c1 .1 1.9.6 2.5 1.3s1 1.7.9 2.7" />
    <path d="M45.9 20.6H30.6c-.6 0-1 .4-1 1s.4 1 1 1h15.3c.6 0 1-.4 1-1s-.5-1-1-1M45.9 30.5H30.6c-.6 0-1 .4-1 1s.4 1 1 1h15.3c.6 0 1-.4 1-1 0-.5-.5-1-1-1M45.9 40.5H30.6c-.6 0-1 .4-1 1s.4 1 1 1h15.3c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconSpeechBubble15;
