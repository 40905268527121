import * as React from "react";
const IconScaling = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 31H44.3V7c0-2.8-2.2-5-5-5H7C4.2 2 2 4.2 2 7v32.3c0 2.8 2.2 5 5 5h24V57c0 2.8 2.2 5 5 5h21c2.8 0 5-2.2 5-5V36c0-2.8-2.2-5-5-5M7 42.3c-1.7 0-3-1.3-3-3V7c0-1.7 1.3-3 3-3h32.3c1.7 0 3 1.3 3 3v24H36c-2.8 0-5 2.2-5 5v6.3zM42.3 33v6.3c0 1.7-1.3 3-3 3H33V36c0-1.7 1.3-3 3-3zM60 57c0 1.7-1.3 3-3 3H36c-1.7 0-3-1.3-3-3V44.3h6.3c2.8 0 5-2.2 5-5V33H57c1.7 0 3 1.3 3 3z" />
  </svg>
);
export default IconScaling;
