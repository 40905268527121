import * as React from "react";
const IconLineDashedCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 31H50.5c-.6 0-1 .4-1 1s.4 1 1 1H61c.6 0 1-.4 1-1s-.4-1-1-1M37.3 31H26.7c-.6 0-1 .4-1 1s.4 1 1 1h10.5c.6 0 1-.4 1-1 .1-.6-.4-1-.9-1M13.5 31H3c-.6 0-1 .4-1 1s.4 1 1 1h10.5c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconLineDashedCopy;
