import * as React from "react";
const IconRocketGrowth2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.7 33.2-7.3-7.3c-.2-.2-.4-.3-.7-.3H41.4L30.6 14.8C18.6 2.8 9.9 1.7 3 2.1c-.5 0-.9.4-.9.9-.5 6.9.7 15.5 12.7 27.6l10.8 10.8v12.3c0 .3.1.5.3.7l7.3 7.3c.2.2.5.3.7.3s.5-.1.7-.3l8.5-8.5c.4 1.1 1 2 1.8 2.9 2.8 2.8 13.3 4.4 14.5 4.5h.1c.3 0 .5-.1.7-.3s.3-.5.3-.8c-.2-1.2-1.7-11.7-4.5-14.5-.8-.8-1.8-1.4-2.9-1.8l8.5-8.5c.5-.5.5-1.1.1-1.5m-7.1 13.2c1.8 1.8 3.2 8.4 3.8 12-3.7-.6-10.3-2-12-3.8-.8-.8-1.4-1.9-1.6-3.1l6.8-6.8c1.1.3 2.2.8 3 1.7M33.9 59.6l-6.3-6.3V41c0-.3-.1-.5-.3-.7L16.2 29.1C5.3 18.2 3.7 10.6 4 4c6.6-.2 14.3 1.3 25.1 12.2l11.1 11.1c.2.2.4.3.7.3h12.3l6.3 6.3z" />
  </svg>
);
export default IconRocketGrowth2;
