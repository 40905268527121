import * as React from "react";
const IconDesktopMonitorHeart = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M15.2 24.8h6.6l3.8 7.7c.2.5.7.8 1.3.8.5 0 1-.3 1.3-.8l6.3-14.1L40.8 31c.2.3.5.6.9.6h7.1c.6 0 1-.4 1-1s-.4-1-1-1h-6.5l-6.5-13c-.2-.5-.7-.8-1.3-.8s-1.1.3-1.3.8l-6.3 14-3.5-7.1c-.2-.3-.5-.6-.9-.6h-7.2c-.6 0-1 .4-1 1s.4.9.9.9" />
    <path d="M57 6.7H7c-2.8 0-5 2.3-5 5v33c0 2.8 2.3 5 5 5h17.6c-.6 1.5-1.8 3.8-3.4 5.1-.4.4-.6 1-.4 1.5s.7.9 1.3.9h19.4c.6 0 1.1-.3 1.3-.9.2-.5 0-1.1-.4-1.5-1.6-1.3-2.8-3.6-3.4-5.1h18c2.8 0 5-2.3 5-5v-33c0-2.7-2.3-5-5-5m-50 2h50c1.7 0 3 1.4 3 3v27.1H4v-27c0-1.7 1.4-3.1 3-3.1m32.9 46.6H23.8c1.6-1.9 2.6-4.3 3.1-5.5h10c.3 1.2 1.4 3.6 3 5.5M57 47.8H7c-1.7 0-3-1.4-3-3V41h56v3.8c0 1.6-1.4 3-3 3" />
  </svg>
);
export default IconDesktopMonitorHeart;
