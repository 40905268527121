import * as React from "react";
const IconBellMute = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M46.6 52.1H16.7c-1.2 0-2.3-.6-3-1.6s-.8-2.2-.3-3.4c1.8-4.4 2.1-9.3 2.1-9.6v-7.4c0-.6-.4-1-1-1s-1 .4-1 1v7.3s-.3 4.8-2 8.9c-.7 1.7-.5 3.7.5 5.2s2.8 2.5 4.6 2.5h6.8c.2 4.4 3.8 7.9 8.2 7.9s8-3.5 8.2-7.9h6.8c.6 0 1-.4 1-1s-.4-.9-1-.9M31.7 60c-3.3 0-6-2.6-6.2-5.9h12.3c-.1 3.3-2.8 5.9-6.1 5.9M58.5 47.4l-8.9-6.1q.3-.3.3-.6V27.2c0-9.7-7.6-17.6-17.2-18.1V3c0-.6-.4-1-1-1s-1 .4-1 1v6.1c-6 .3-11.4 3.5-14.5 8.7-.1.1-.2.2-.2.4l-9.3-6.4c-.5-.3-1.1-.2-1.4.3-.3.3-.2.9.2 1.3l51.8 35.7c.2.1.4.2.6.2.3 0 .6-.2.8-.4.3-.5.2-1.1-.2-1.5M17.8 18.8c3-4.9 8.1-7.8 13.8-7.8 8.9 0 16.2 7.3 16.2 16.2v12.9l-30.4-21c.2 0 .4-.1.4-.3" />
  </svg>
);
export default IconBellMute;
