import * as React from "react";
const IconCloudUploadAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M49.8 28.5c-1.5 0-2.9.3-4.3.8.1-.8.2-1.5.2-2.3 0-7.4-6.2-13.4-13.7-13.4s-13.7 6-13.7 13.4c0 .8.1 1.6.2 2.3-1.4-.6-2.8-.8-4.3-.8C8 28.5 3 33.4 3 39.5s5 10.9 11.2 10.9h35.6C56 50.4 61 45.5 61 39.5s-5-11-11.2-11m0 19.9H14.2c-5.1 0-9.2-4-9.2-8.9s4.1-8.9 9.2-8.9c1.9 0 3.7.6 5.3 1.7.4.3.8.2 1.2 0 .3-.3.5-.7.3-1.1-.5-1.3-.7-2.6-.7-4 0-6.3 5.3-11.4 11.7-11.4 6.5 0 11.7 5.1 11.7 11.4 0 1.4-.2 2.7-.7 4-.2.4 0 .9.3 1.1.3.3.8.3 1.2 0 1.6-1.1 3.4-1.7 5.3-1.7 5.1 0 9.2 4 9.2 8.9s-4.1 8.9-9.2 8.9" />
    <path d="M32.7 28.8c-.4-.4-1-.4-1.4 0l-5.1 4.9c-.4.4-.4 1 0 1.4s1 .4 1.4 0l3.5-3.3v10.1c0 .6.4 1 1 1s1-.4 1-1v-10l3.4 3.3c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4z" />
  </svg>
);
export default IconCloudUploadAlt;
