import * as React from "react";
const IconBriefcaseAltCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 20.9H42.8v-3.5c0-5-4.1-9.1-9.1-9.1h-3.6c-5 0-9.1 4.1-9.1 9.1v3.5H7c-2.8 0-5 2.2-5 5v24.7c0 2.8 2.2 5 5 5h50c2.8 0 5-2.2 5-5V25.9c0-2.8-2.2-5-5-5m-33.8-3.5c0-3.9 3.2-7.1 7.1-7.1h3.6c3.9 0 7.1 3.2 7.1 7.1v3.5H23.2zM7 22.9h50c1.7 0 3 1.3 3 3v7.3h-8.8v-3.3c0-.6-.4-1-1-1s-1 .4-1 1v3.3H14.8v-3.3c0-.6-.4-1-1-1s-1 .4-1 1v3.3H4v-7.3c0-1.7 1.3-3 3-3m50 30.7H7c-1.7 0-3-1.3-3-3V35.2h8.8v3.3c0 .6.4 1 1 1s1-.4 1-1v-3.3h34.4v3.3c0 .6.4 1 1 1s1-.4 1-1v-3.3H60v15.5c0 1.6-1.3 2.9-3 2.9" />
  </svg>
);
export default IconBriefcaseAltCopy;
