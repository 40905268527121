import * as React from "react";
const IconTrain = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54 17.6c-.8-6.8-6.6-11.9-13.5-11.9h-.7V5c0-1.8-1.5-3.3-3.3-3.3h-9.2C25.5 1.7 24 3.2 24 5v.7h-.8c-6.9 0-12.6 5.1-13.4 11.9-.1.5-.1 1.1-.1 1.6v27.1c0 3.4 2.7 6.1 6.1 6.1h.9l-4.2 6.4c-.7 1-.4 2.4.6 3.1.4.3.8.4 1.2.4.7 0 1.4-.4 1.9-1l5.8-8.9h19.7l5.6 8.8c.4.7 1.2 1 1.9 1q.6 0 1.2-.3c1.1-.7 1.4-2.1.7-3.1l-4-6.4h.9c3.4 0 6.1-2.7 6.1-6.1V19.1c0-.5 0-1-.1-1.5m-24.2 2.5V28H14.4v-7.9zm4.5 0h15.4V28H34.3zM28.7 6.3h6.7v3.3h-6.7zm-5.3 3.9h.8v.7c0 1.8 1.5 3.3 3.3 3.3h9.2c1.8 0 3.3-1.5 3.3-3.3v-.7h.7c3.7 0 7 2.2 8.3 5.4H15.1c1.4-3.2 4.6-5.4 8.3-5.4M48 47.9H16c-.9 0-1.6-.7-1.6-1.6V32.5h35.2v13.8c0 .9-.7 1.6-1.6 1.6" />
    <path d="M23.4 34.4c-3.2 0-5.8 2.6-5.8 5.8s2.6 5.8 5.8 5.8 5.8-2.6 5.8-5.8-2.6-5.8-5.8-5.8m0 7.1c-.7 0-1.3-.6-1.3-1.3s.6-1.3 1.3-1.3 1.3.6 1.3 1.3-.6 1.3-1.3 1.3M40.6 34.4c-3.2 0-5.8 2.6-5.8 5.8s2.6 5.8 5.8 5.8 5.8-2.6 5.8-5.8-2.6-5.8-5.8-5.8m0 7.1c-.7 0-1.3-.6-1.3-1.3s.6-1.3 1.3-1.3 1.3.6 1.3 1.3-.5 1.3-1.3 1.3" />
  </svg>
);
export default IconTrain;
