import React from 'react';
import classNames from 'classnames';
import { BringzzText } from "@bringzz/components";
import { calculateFontSize } from './utils';

const INITIAL_HEADER_HEIGHT = 214;
const MIN_HEADER_HEIGHT = 48;
const TITLE_FONT_MAX = 24;
const TITLE_FONT_MIN = 16;
const PARAGRAPH_FONT_MAX = 13;
const PARAGRAPH_FONT_MIN = 8;

const getClassNames = (headerHeight) => {
    const baseClasses = 'fixed px-4 py-6 top-0 z-50 bg-white transition-shadow duration-300 max-w-xl';
    const spaceBetweenY = headerHeight <= 120 ? 0 : headerHeight <= 140 ? 0 : headerHeight <= 160 ? 1 : headerHeight <= 180 ? 2 : 6;
    const additionalClasses = headerHeight <= 120
        ? 'w-full flex items-center justify-center border-b shadow-md rounded-none'
        : 'rounded-b-xl mx-2 flex flex-col justify-end';

    return `${baseClasses} space-y-${spaceBetweenY} ${additionalClasses}`;
};

const HeaderDefault = ({ headerHeight, title }) => {
    const titleFontSize = calculateFontSize(headerHeight, TITLE_FONT_MAX, TITLE_FONT_MIN, INITIAL_HEADER_HEIGHT, MIN_HEADER_HEIGHT);
    const paragraphFontSize = calculateFontSize(headerHeight, PARAGRAPH_FONT_MAX, PARAGRAPH_FONT_MIN, INITIAL_HEADER_HEIGHT, 120);

    const defaultTitle = (
        <div className={classNames(
            'flex space-x-1 transition-all duration-300 transform',
            headerHeight <= 120 ? 'justify-center items-center' : 'pl-4 pt-4'
        )}>
            <BringzzText tag='h2' style={{ fontSize: `${titleFontSize}px` }}>Browse</BringzzText>
            <BringzzText tag='h2' className='text-magic-lilac' style={{ fontSize: `${titleFontSize}px` }}>&</BringzzText>
            <BringzzText tag='h2' style={{ fontSize: `${titleFontSize}px` }}>Search</BringzzText>
        </div>
    );

    return (
        <div className={getClassNames(headerHeight)} style={{ height: `${headerHeight}px` }}>
            {!title && defaultTitle}
            {title && (
                <div className={classNames(
                    'flex space-x-1 transition-all duration-300 transform',
                    headerHeight <= 120 ? 'justify-center items-center' : 'pl-4 pt-4'
                )}>
                    <BringzzText tag='h2' style={{ fontSize: `${titleFontSize}px` }}>{title.text}</BringzzText>
                </div>
            )}
            {headerHeight > 120 && (
                <BringzzText tag='h4' className='font-normal transition-all duration-300' style={{ fontSize: `${paragraphFontSize}px` }}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum consequat nisl eget ultricies mollis. Praesent eu sodales libero, sit amet commodo ante. Fusce ut mauris urna.
                </BringzzText>
            )}
        </div>
    );
};

export default HeaderDefault;
