import * as React from "react";
const IconPng = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M14.904 5c-1.198 0-2.23 1.033-2.23 2.23v49.54c0 1.197 1.032 2.23 2.23 2.23h34.423a2 2 0 0 0 2-2V24.058q0-.11-.006-.212H35.096c-2.302 0-4.096-1.794-4.096-4.096V5zM33 5.855V19.75c0 1.198.898 2.096 2.096 2.096h14.91zM10.673 7.23c0-2.303 1.929-4.231 4.23-4.231h16.29c1 0 2.08.329 2.848 1.089l18.002 16.927c.964.79 1.284 1.928 1.284 3.042V57a4 4 0 0 1-4 4H14.904c-2.302 0-4.23-1.928-4.23-4.23zM28.22 33.304a1 1 0 0 1 1.123.418l5.158 8.026v-7.485a1 1 0 1 1 2 0v10.89a1 1 0 0 1-1.842.54L29.5 37.669v7.485a1 1 0 0 1-2 0v-10.89a1 1 0 0 1 .719-.96m-8.719 1.96a1 1 0 0 0-1 1v1.89h4a1 1 0 0 0 1-1v-.89a1 1 0 0 0-1-1zm-1 4.89h4a3 3 0 0 0 3-3v-.89a3 3 0 0 0-3-3h-3a3 3 0 0 0-3 3v8.889a1 1 0 1 0 2 0zm20-3.89a3 3 0 0 1 3-3h4.976a1 1 0 1 1 0 2H41.5a1 1 0 0 0-1 1v6.889a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-.89a1 1 0 0 0-1-1h-.986a1 1 0 1 1 0-2h.986a3 3 0 0 1 3 3v.89a3 3 0 0 1-3 3h-3a3 3 0 0 1-3-3z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconPng;
