import * as React from "react";
const IconPhoneCallingAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.1 45.9 51 39.8c-1.6-1.1-3.7-.6-5 1.3l-2.3 3.3c-.4.6-1.2.8-1.8.4-12.4-7.8-20.2-18.7-23-23-.4-.6-.2-1.5.4-1.9l3.7-2.6c1.5-1.1 1.9-3.2.9-4.7l-6.1-9.1c-1-1.5-3-2-4.6-1.1L4.4 7.6c-.1 0-.1.1-.2.2-6.5 6.4-.2 22.9 14.3 37.4 6.8 6.8 14.4 12 21.3 14.8 3.4 1.3 6.4 2 9.1 2 2.9 0 5.3-.8 7-2.5l.2-.2 5.1-8.7c.9-1.6.4-3.7-1.1-4.7m-.7 3.6-5.1 8.6c-2.6 2.5-7.6 2.5-13.9 0-6.7-2.7-14-7.8-20.6-14.4C6.7 30.6.2 14.8 5.5 9.3l8.6-5.1c.7-.4 1.5-.2 1.9.4l6.1 9.1c.4.6.3 1.5-.4 1.9L18 18.2c-1.5 1-1.9 3.1-.9 4.6 2.8 4.4 10.8 15.6 23.6 23.6 1.5 1 3.5.5 4.5-1l2.3-3.3c.7-.9 1.5-1.3 2.2-.8l9.1 6.1c.8.6 1 1.5.6 2.1" />
    <path d="M34.2 18.6c2.5 0 4.5-2 4.5-4.5s-2-4.5-4.5-4.5-4.5 2-4.5 4.5 2 4.5 4.5 4.5m0-7c1.4 0 2.5 1.1 2.5 2.5s-1.1 2.5-2.5 2.5-2.5-1.1-2.5-2.5 1.2-2.5 2.5-2.5M46 18.6c2.5 0 4.5-2 4.5-4.5s-2-4.5-4.5-4.5-4.5 2-4.5 4.5 2.1 4.5 4.5 4.5m0-7c1.4 0 2.5 1.1 2.5 2.5s-1.1 2.5-2.5 2.5-2.5-1.1-2.5-2.5 1.2-2.5 2.5-2.5M57.9 18.6c2.5 0 4.5-2 4.5-4.5s-2-4.5-4.5-4.5-4.5 2-4.5 4.5 2 4.5 4.5 4.5m0-7c1.4 0 2.5 1.1 2.5 2.5s-1.1 2.5-2.5 2.5-2.5-1.1-2.5-2.5 1.1-2.5 2.5-2.5" />
  </svg>
);
export default IconPhoneCallingAlt;
