import * as React from "react";
const IconSpeechBubble2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32.4 2.1c-7.9 0-15.3 3.1-20.9 8.7C2.2 20 .2 34.5 6.6 45.9L2 60.7c-.1.4 0 .7.3 1 .2.2.4.3.7.3.1 0 .2 0 .3-.1l14.3-4.7c9.2 5.3 20.1 5.4 29.3.1 9.4-5.3 15-14.9 15-25.7C62 15.3 48.7 2.1 32.4 2.1M46 55.5c-8.7 5-19.1 4.8-27.7-.3-.3-.2-.6-.2-.8-.1l-13 4.3 4.1-13.3c.1-.3 0-.6-.1-.8C2.3 34.6 4.2 20.9 13 12.1 18.2 6.9 25.1 4 32.5 4 47.6 4.1 60 16.4 60 31.6c0 10-5.2 19-14 23.9" />
    <path d="M41 21.6H23.9c-.6 0-1 .4-1 1s.4 1 1 1H41c.6 0 1-.4 1-1s-.4-1-1-1M41 30.9H23.9c-.6 0-1 .4-1 1s.4 1 1 1H41c.6 0 1-.4 1-1s-.4-1-1-1M41 39.5H23.9c-.6 0-1 .4-1 1s.4 1 1 1H41c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconSpeechBubble2;
