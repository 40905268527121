import * as React from "react";
const IconDinnerCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M46.7 2h-3.3C42 2 41 3.1 41 4.4v53.7c0 2.1 1.7 3.8 3.8 3.8h2.9c2.1 0 3.8-1.7 3.8-3.8v-22h2.9c1.3 0 2.4-1.1 2.4-2.4V12.2C56.8 6.6 52.2 2 46.7 2m1 58h-2.9c-1 0-1.8-.8-1.8-1.8v-22h6.6v22c0 1-.9 1.8-1.9 1.8m7.2-26.3c0 .2-.2.4-.4.4H43V4.4c0-.2.1-.4.4-.4h3.3c4.5 0 8.1 3.7 8.2 8.2zM26.4 2.2c-.6 0-1 .4-1 1v15.9h-7.1V3.2c0-.6-.4-1-1-1s-1 .4-1 1v15.9H9.1V3.2c0-.6-.4-1-1-1s-1 .4-1 1v25.5c0 1.1.8 1.8 1.8 1.8H12V58c0 2.1 1.7 3.8 3.8 3.8h2.8c2.1 0 3.8-1.7 3.8-3.8V30.6h3c1.1 0 1.8-.8 1.8-1.8V3.2c.2-.5-.3-1-.8-1m-5.9 56c0 1-.8 1.8-1.8 1.8h-2.8c-1 0-1.8-.8-1.8-1.8V30.6h6.4zM9.1 28.6v-7.5h16.3v7.5z" />
  </svg>
);
export default IconDinnerCopy;
