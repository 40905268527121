import * as React from "react";
const IconTelescope = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m60 14.6-1.2-8.2c-.2-1.4-1-2.7-2.2-3.5s-2.6-1.1-4.1-.8l-44.4 9c-2.7.5-4.5 3.1-4.1 5.9l.5 3.5c.4 2.6 2.6 4.5 5.2 4.5h.5l20.5-2v11.5h-7.8c-.6 0-1 .4-1 1s.4 1 1 1h6.9L17.2 60.3c-.3.5-.1 1.1.4 1.3.1.1.3.1.5.1.4 0 .7-.2.9-.5l11.7-22.3V61c0 .6.4 1 1 1s1-.4 1-1V38.9l11.7 22.3c.2.3.5.5.9.5.2 0 .3 0 .5-.1.5-.3.7-.9.4-1.3L33.6 36.4h6.7c.6 0 1-.4 1-1s-.4-1-1-1h-7.6V22.8l22.6-2.1c1.4-.1 2.7-.8 3.6-2s1.3-2.6 1.1-4.1m-36.6 7v-.3l-1.6-10.9 16.1-3.3v.1l1.9 12.9zM10 22.9c-1.7.2-3.3-1-3.5-2.8L6 16.7c-.3-1.7.8-3.3 2.5-3.7l11.3-2.3 1.6 10.8c0 .1 0 .1.1.2zm47.4-5.5c-.6.7-1.4 1.1-2.2 1.2l-13.3 1.3-1.9-13c0-.1 0-.1-.1-.2L53 4.1c.2-.1.4-.1.6-.1.7 0 1.3.2 1.9.6.7.5 1.2 1.3 1.3 2.2L58 15c.2.8-.1 1.7-.6 2.4" />
  </svg>
);
export default IconTelescope;
