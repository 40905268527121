import * as React from "react";
const IconLaptopAltSwitch = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 42.5h-4.2V15.2c0-3.6-2.9-6.5-6.5-6.5H13.7c-3.6 0-6.5 2.9-6.5 6.5v27.4H3c-.6 0-1 .4-1 1v4.2c0 4.2 3.4 7.6 7.6 7.6h44.7c4.2 0 7.6-3.4 7.6-7.6v-4.2c.1-.6-.3-1.1-.9-1.1M9.2 15.2c0-2.5 2-4.5 4.5-4.5h36.6c2.5 0 4.5 2 4.5 4.5v27.4h-16c-.3 0-.6.1-.8.4l-1.5 1.8h-9.4L25.4 43c-.2-.2-.5-.3-.7-.3H9.2zM60 47.7c0 3.1-2.5 5.6-5.6 5.6H9.6c-3.1 0-5.6-2.5-5.6-5.6v-3.2h20.2l1.7 1.8c.2.2.5.3.7.3h10.3c.3 0 .6-.1.8-.4l1.5-1.8H60z" />
    <path d="M30 18.7h4c.6 0 1-.4 1-1s-.4-1-1-1h-4c-.6 0-1 .4-1 1s.5 1 1 1" />
  </svg>
);
export default IconLaptopAltSwitch;
