import * as React from "react";
const IconMedal5 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M42.6 36.7 56.4 9.6c.8-1.6.8-3.5-.2-5.1S53.6 2 51.7 2h-7.1c-2 0-3.8 1.1-4.7 2.9l-8 15.6L24 4.9C23.1 3.1 21.4 2 19.4 2h-7.1c-1.8 0-3.5.9-4.5 2.5s-1 3.5-.2 5.1l13.8 27.1c.1.2.3.3.4.4-2.7 2.6-4.3 6.3-4.3 10.3C17.5 55.5 24 62 32 62s14.5-6.5 14.5-14.5c0-4-1.7-7.7-4.3-10.3.2-.2.3-.3.4-.5m-19.4-.9L9.4 8.7c-.5-1-.5-2.2.1-3.2S11.1 4 12.3 4h7.1c1.2 0 2.3.7 2.9 1.8l14.2 27.9c-1.4-.5-2.9-.7-4.5-.7-3.3 0-6.4 1.1-8.8 3zm21.3 11.7C44.5 54.4 38.9 60 32 60s-12.5-5.6-12.5-12.5S25.1 35 32 35s12.5 5.6 12.5 12.5M39.4 35l-6.3-12.3 8.6-16.9c.6-1.1 1.7-1.8 2.9-1.8h7.1c1.1 0 2.2.6 2.8 1.5.6 1 .6 2.1.1 3.2L40.8 35.8v.1c-.5-.3-.9-.6-1.4-.9" />
    <path d="M32 42.6c-2.7 0-4.9 2.2-4.9 4.9s2.2 4.9 4.9 4.9 4.9-2.2 4.9-4.9-2.2-4.9-4.9-4.9m0 7.8c-1.6 0-2.9-1.3-2.9-2.9s1.3-2.9 2.9-2.9 2.9 1.3 2.9 2.9-1.3 2.9-2.9 2.9" />
  </svg>
);
export default IconMedal5;
