import * as React from "react";
const IconShuffleCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M52.5 37.8c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l6.9 7.1H44.1L32.7 32l11.4-14.3H58l-6.9 7.1c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3l8.6-8.8c.4-.4.4-1 0-1.4l-8.6-8.8c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l6.9 7.1H44.1c-.6 0-1.2.3-1.5.7l-11.2 14-11.2-14c-.4-.5-.9-.7-1.5-.7H4c-.6 0-1 .4-1 1s.4 1 1 1h14.7L30.2 32 18.7 46.3H4c-.6 0-1 .4-1 1s.4 1 1 1h14.7c.6 0 1.2-.3 1.5-.7l11.2-14 11.2 14c.4.5.9.7 1.5.7H58l-6.9 7.1c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3l8.6-8.8c.4-.4.4-1 0-1.4z" />
  </svg>
);
export default IconShuffleCopy;
