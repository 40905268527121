import * as React from "react";
const IconHappy1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M24 27.4c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-3.5-3.2 3.5-3.2c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-3.6 3.3-3.5-3.5c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l3.5 3.2-3.5 3.4c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l3.6-3.3zM48.5 18.1c-.4-.4-1-.4-1.4 0l-3.6 3.3L40 18c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l3.5 3.2-3.5 3.4c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l3.6-3.3 3.6 3.3c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4L45 22.7l3.5-3.2c.3-.4.4-1 0-1.4" />
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
    <path d="M44.4 44.5c-3.4 3.1-7.8 4.8-12.4 4.8s-9-1.7-12.4-4.8c-.4-.4-1-.4-1.4.1-.4.4-.4 1 .1 1.4 3.7 3.5 8.6 5.4 13.7 5.4s10-1.9 13.7-5.4c.4-.4.4-1 .1-1.4-.3-.5-1-.5-1.4-.1" />
  </svg>
);
export default IconHappy1;
