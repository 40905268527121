import * as React from "react";
const IconAmbulanceAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.6 13.3H20.2V9.8c0-.6-.4-1-1-1s-1 .4-1 1v3.5h-2c-1.2 0-2.3.5-3.2 1.3l-9.8 10c-.8.9-1.2 2-1.2 3.1v12.8C2 43 4 45 6.4 45h4.1c-.6 1-.9 2.2-.9 3.4 0 3.8 3.1 6.8 6.8 6.8 3.8 0 6.8-3.1 6.8-6.8 0-1.2-.3-2.4-.9-3.4H42c-.6 1-.9 2.2-.9 3.4 0 3.8 3.1 6.8 6.8 6.8s6.8-3.1 6.8-6.8c0-1.2-.3-2.4-.9-3.4h3.7c2.4 0 4.4-2 4.4-4.4V17.8c.1-2.5-1.9-4.5-4.3-4.5M16.4 53.2c-2.7 0-4.8-2.2-4.8-4.8s2.2-4.8 4.8-4.8 4.8 2.2 4.8 4.8-2.1 4.8-4.8 4.8m31.5 0c-2.7 0-4.8-2.2-4.8-4.8s2.2-4.8 4.8-4.8 4.8 2.2 4.8 4.8-2.1 4.8-4.8 4.8M60 40.6c0 1.3-1.1 2.4-2.4 2.4h-5.5c-1.2-.9-2.6-1.4-4.2-1.4s-3 .5-4.2 1.4H20.6c-1.2-.9-2.6-1.4-4.2-1.4s-3 .5-4.2 1.4H6.4C5.1 43 4 41.9 4 40.6V27.7c0-.6.2-1.2.7-1.7l9.8-10c.5-.5 1.1-.7 1.7-.7h41.4c1.3 0 2.4 1.1 2.4 2.4z" />
    <path d="M37.7 28.2H33v-4.7c0-.6-.4-1-1-1s-1 .4-1 1v4.7h-4.7c-.6 0-1 .4-1 1s.4 1 1 1H31v4.7c0 .6.4 1 1 1s1-.4 1-1v-4.7h4.7c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconAmbulanceAlt;
