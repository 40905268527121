import * as React from "react";
const IconSofa2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.7 26.6c-.6-2-2.4-3.3-4.7-3.5-1.2-.1-2.6 0-4 .3v-.1c-3.3-7.2-11.6-11.9-21.3-11.9-9.1 0-17.2 4.7-20.6 12-1.4-.3-2.9-.4-4.1-.3-2.3.2-4.1 1.5-4.7 3.5-.7 2.2-.1 4.6 1.5 6.2 1.7 1.6 3.9 2.2 6.1 1.6.9 4.7.2 11.1-.1 12.6-.1.3 0 .6.2.8s.5.4.8.4h5.8v3.3c0 .6.4 1 1 1s1-.4 1-1v-3.3h27v3.3c0 .6.4 1 1 1s1-.4 1-1v-3.3h5.8c.3 0 .6-.1.8-.4.2-.2.3-.5.2-.8-.3-1.5-1-7.9-.1-12.6 3.1 1 4.4 0 6.1-1.6 1.4-1.5 2-4 1.3-6.2m-30-13.1c8.8 0 16.4 4.2 19.4 10.6-1.7.7-3.2 1.8-4.2 3.3-1.9 2.9-2.1 6.3-2.1 9.7 0 .1 0 .1-.1.1H19.3c-.1 0-.1 0-.1-.1 0-3.3-.3-6.8-2.1-9.7-1-1.5-2.4-2.6-4-3.3 3.1-6.4 10.5-10.6 18.6-10.6m27.1 17.9c-1.5 1.4-2 1.9-5 .7-.3-.1-.6-.1-.8 0-.3.1-.4.4-.5.6-1.2 4.3-.8 10.5-.4 13.4H11.9c.4-2.7.9-8.9-.3-13.4-.1-.3-.3-.5-.5-.7-.3-.1-.6-.1-.8 0-1.7.8-3.6.5-5-.8-1-1-1.4-2.7-1-4.1.6-1.7 2.3-2.1 3-2.1 2.7-.3 6.5.7 8.2 3.4 1.6 2.4 1.8 5.5 1.8 8.6 0 1.2.9 2.1 2.1 2.1h25.4c1.2 0 2.1-.9 2.1-2.1 0-3 .2-6.1 1.8-8.6 1.8-2.7 5.5-3.6 8.2-3.4.7.1 2.4.4 3 2.1.4 1.6 0 3.2-1.1 4.3" />
  </svg>
);
export default IconSofa2;
