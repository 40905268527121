import * as React from "react";
const IconPoundSterling2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M23.8 41.5h16.3c.6 0 1-.4 1-1s-.4-1-1-1H29.8c.8-.9 1.2-2.2 1.2-3.5v-2.4h7.5c.6 0 1-.4 1-1s-.4-1-1-1H31v-3.1c0-2.3 1.8-4.1 4.1-4.1 1.1 0 2.1.4 2.8 1.1.4.4 1 .4 1.4 0s.4-1 0-1.4c-1.1-1.1-2.6-1.7-4.2-1.7-3.4 0-6.1 2.8-6.1 6.1v3.1h-4.4c-.6 0-1 .4-1 1s.4 1 1 1H29V36c0 1.9-1.5 3.5-3.4 3.5h-1.8c-.6 0-1 .4-1 1s.5 1 1 1" />
    <path d="M58 13.8H6c-2.2 0-4 1.8-4 4v28.4c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V17.8c0-2.2-1.8-4-4-4M4 25.4c.7.2 1.5.4 2.2.4 4 0 7.3-3.3 7.3-7.3 0-.9-.2-1.8-.5-2.7h38c-.3.9-.5 1.8-.5 2.7 0 4 3.3 7.3 7.3 7.3.8 0 1.5-.1 2.2-.4v13.2c-.7-.2-1.5-.4-2.2-.4-4 0-7.3 3.3-7.3 7.3 0 .9.2 1.8.5 2.7H13c.3-.9.5-1.8.5-2.7 0-4-3.3-7.3-7.3-7.3-.8 0-1.5.1-2.2.4zm56-7.6v5.5c-.7.3-1.4.5-2.2.5-2.9 0-5.3-2.4-5.3-5.3 0-1 .3-1.9.8-2.7H58c1.1 0 2 .9 2 2m-54-2h4.7c.5.8.8 1.7.8 2.7 0 2.9-2.4 5.3-5.3 5.3-.8 0-1.5-.2-2.2-.5v-5.5c0-1.1.9-2 2-2M4 46.2v-5.5c.7-.3 1.4-.5 2.2-.5 2.9 0 5.3 2.4 5.3 5.3 0 1-.3 1.9-.8 2.7H6c-1.1 0-2-.9-2-2m54 2h-4.7c-.5-.8-.8-1.7-.8-2.7 0-2.9 2.4-5.3 5.3-5.3.8 0 1.5.2 2.2.5v5.5c0 1.1-.9 2-2 2" />
  </svg>
);
export default IconPoundSterling2;
