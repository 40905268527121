import * as React from "react";
const IconSleeveless = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.5 62h-39c-3 0-5.4-2.4-5.4-5.3V33.5c0-.2.1-.5.2-.6 3.4-4.1 5.2-9.1 5.2-14.5 0-4.2-1.2-8.4-3.4-12-.1-.3-.1-.7 0-.9.1-.3.4-.5.7-.6L20.3 2c.3-.1.7 0 1 .3l10.6 10.8L42.6 2.3c.3-.3.6-.4 1-.3l10.6 2.8c.3.1.5.3.7.6.1.3.1.6-.1.9-2.2 3.7-3.3 7.9-3.3 12 0 5.2 1.8 10.3 5.2 14.5.1.2.2.4.2.6v23.2c-.1 3-2.5 5.4-5.4 5.4M9.2 33.8v22.8c0 1.8 1.5 3.3 3.4 3.3h39c1.8 0 3.3-1.5 3.3-3.3V33.8c-3.5-4.5-5.4-10-5.4-15.5 0-4.1 1-8.2 3-11.9l-8.8-2.3-11 11.1c-.2.2-.4.3-.7.3s-.5-.1-.7-.3l-11-11.1-8.7 2.3c2 3.7 3 7.8 3 11.9 0 5.8-1.9 11.1-5.4 15.5" />
  </svg>
);
export default IconSleeveless;
