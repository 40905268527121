import * as React from "react";
const IconTopLeftArrowBox = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56 61H8c-2.8 0-5-2.2-5-5V8c0-2.8 2.2-5 5-5h48c2.8 0 5 2.2 5 5v48c0 2.8-2.2 5-5 5M8 5C6.3 5 5 6.3 5 8v48c0 1.7 1.3 3 3 3h48c1.7 0 3-1.3 3-3V8c0-1.7-1.3-3-3-3z" />
    <path d="M40.2 41.8c-.3 0-.5-.1-.7-.3L23.6 25.6v12.8c0 .6-.4 1-1 1s-1-.4-1-1V23.2c0-.1 0-.3.1-.4 0-.1.1-.2.2-.3s.2-.2.3-.2.2-.1.4-.1h15.7c.6 0 1 .4 1 1s-.4 1-1 1H25l15.9 15.9c.4.4.4 1 0 1.4-.2.2-.4.3-.7.3" />
  </svg>
);
export default IconTopLeftArrowBox;
