import * as React from "react";
const IconRdtResultInvalid = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      d="M20 38a2 2 0 1 1-4 0 2 2 0 0 1 4 0M18 31a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3"
    />
    <path
      
      fillRule="evenodd"
      d="M18 8a4 4 0 0 0-4 4v13a4 4 0 0 0 8 0V12a4 4 0 0 0-4-4m2 17V12a2 2 0 1 0-4 0v13a2 2 0 1 0 4 0"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M12 4a2 2 0 0 0-2 2v36a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2zm12 2H12v36h12z"
      clipRule="evenodd"
    />
    <path
      
      d="M30.85 8.105c.238-.105.495-.131.743-.079s.485.185.677.39a1 1 0 1 0 1.46-1.368 3.3 3.3 0 0 0-1.722-.978 3.2 3.2 0 0 0-1.966.206 3.4 3.4 0 0 0-1.495 1.304A3.6 3.6 0 0 0 28 9.5c0 .678.188 1.346.547 1.92s.877 1.031 1.495 1.304a3.2 3.2 0 0 0 1.966.206 3.3 3.3 0 0 0 1.722-.978 1 1 0 1 0-1.46-1.368c-.192.205-.43.337-.677.39a1.2 1.2 0 0 1-.742-.079 1.4 1.4 0 0 1-.609-.537A1.6 1.6 0 0 1 30 9.5c0-.31.087-.61.242-.858.156-.248.37-.432.609-.537M28.5 15a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-1v4a1 1 0 1 1-2 0v-4h-1a1 1 0 0 1-1-1M30 27.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .472.333 1 1 0 1 0 1.886-.666A2.5 2.5 0 0 0 31.5 25h-1a2.5 2.5 0 0 0 0 5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.472-.333 1 1 0 1 0-1.886.666A2.5 2.5 0 0 0 30.5 33h1a2.5 2.5 0 0 0 0-5h-1a.5.5 0 0 1-.5-.5M30.5 36a.5.5 0 0 0 0 1h1a2.5 2.5 0 0 1 0 5h-1a2.5 2.5 0 0 1-2.358-1.667 1 1 0 1 1 1.886-.666.5.5 0 0 0 .472.333h1a.5.5 0 0 0 0-1h-1a2.5 2.5 0 0 1 0-5h1a2.5 2.5 0 0 1 2.358 1.667 1 1 0 1 1-1.886.666A.5.5 0 0 0 31.5 36zM38 26.5a1 1 0 0 0-1.721-.692l-1 1.041A1 1 0 0 0 36 28.542V31.5a1 1 0 1 0 2 0z"
    />
  </svg>
);
export default IconRdtResultInvalid;
