import * as React from "react";
const IconFamily = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M14.6 25.9c3.9 0 7.1-3.2 7.1-7.1s-3.2-7.1-7.1-7.1-7.1 3.2-7.1 7.1 3.2 7.1 7.1 7.1m0-12.2c2.8 0 5.1 2.3 5.1 5.1s-2.3 5.1-5.1 5.1-5.1-2.3-5.1-5.1 2.3-5.1 5.1-5.1M58.3 33.7c-2.4-2.4-5.5-3.7-8.9-3.7-5.2 0-9.8 3.2-11.7 7.9-1.6-1.4-3.7-2.2-6.1-2.2-2.1 0-4 .7-5.5 1.8-.6-1.4-1.5-2.7-2.6-3.8-2.3-2.3-5.5-3.7-8.8-3.7-3.3-.1-6.4 1.3-8.8 3.6C3.4 36.1 2 39.5 2 43v4.2c0 2.8 2.3 5.1 5.1 5.1h49.8c2.8 0 5.1-2.3 5.1-5.1v-4.6c0-3.4-1.3-6.5-3.7-8.9M39 45v5.3H24.2V45c0-4.1 3.3-7.4 7.4-7.4S39 41 39 45M7.1 50.3c-1.7 0-3.1-1.4-3.1-3.1V43c0-2.9 1.2-5.8 3.4-8 2-2 4.6-3.1 7.3-3.1h.1c2.8 0 5.4 1.1 7.4 3.1 1.1 1.1 1.9 2.4 2.4 3.8-1.4 1.6-2.3 3.8-2.3 6.1v5.3H7.1zM60 47.2c0 1.7-1.4 3.1-3.1 3.1H41V45c0-2.1-.7-4-1.8-5.5C40.5 35 44.6 32 49.3 32c2.8 0 5.5 1.1 7.5 3.1s3.1 4.7 3.1 7.5v4.6zM49.4 25.9c3.9 0 7.1-3.2 7.1-7.1s-3.2-7.1-7.1-7.1-7.1 3.2-7.1 7.1 3.2 7.1 7.1 7.1m0-12.2c2.8 0 5.1 2.3 5.1 5.1s-2.3 5.1-5.1 5.1-5.1-2.3-5.1-5.1 2.3-5.1 5.1-5.1" />
    <path d="M31.7 32.2c2.9 0 5.2-2.3 5.2-5.2s-2.3-5.2-5.2-5.2-5.2 2.3-5.2 5.2 2.3 5.2 5.2 5.2m0-8.3c1.8 0 3.2 1.4 3.2 3.2s-1.4 3.2-3.2 3.2-3.2-1.4-3.2-3.2 1.4-3.2 3.2-3.2" />
  </svg>
);
export default IconFamily;
