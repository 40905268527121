import * as React from "react";
const IconOldMan = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M43.3 23.2c-1.3-1.2-2.9-1.9-4.7-1.9h-2.7c-3.9 0-7.5 1.9-9.6 5.1L25 28.3c-.2.4-.6.7-1 .9l-6.8 3.4c-1.6.8-2.7 2.5-2.7 4.3 0 1.4.6 2.7 1.7 3.6.8.7 1.9 1.1 2.9 1.2V61c0 .6.4 1 1 1s1-.4 1-1V41.5l2.7-.4c1.3-.2 2.6-.8 3.6-1.7l3.6-3.2V61c0 .6.4 1 1 1s1-.4 1-1V34c0-.4-.2-.8-.6-.9-.4-.2-.8-.1-1.1.2l-5.2 4.6c-.7.6-1.6 1-2.5 1.2l-2.4.3c0-.6-.4-1-1-1s-1 .4-1 1v.3c-.6 0-1.2-.3-1.6-.7-.6-.5-1-1.3-1-2.1 0-1.1.6-2 1.6-2.5L25 31c.7-.4 1.3-.9 1.7-1.5l1.2-1.8c1.8-2.7 4.7-4.3 7.9-4.3h2.7c1.3 0 2.5.5 3.3 1.3 3.6 3.4 5.3 9.3 5.5 18.6 0 2.4-.6 4.7-2 6.8l-.8 1.1c-1.3 1.9-1.9 4.1-1.9 6.4V61c0 .6.4 1 1 1s1-.4 1-1v-3.5c0-1.9.6-3.7 1.6-5.3l.8-1.1c1.6-2.4 2.4-5.1 2.3-7.9-.1-9.9-2-16.3-6-20M34.3 16.5c4 0 7.3-3.3 7.3-7.3s-3.3-7.3-7.3-7.3S27 5.2 27 9.2c0 4.1 3.3 7.3 7.3 7.3m0-12.5c2.9 0 5.3 2.4 5.3 5.3s-2.4 5.3-5.3 5.3S29 12.2 29 9.3 31.4 4 34.3 4" />
  </svg>
);
export default IconOldMan;
