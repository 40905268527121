import * as React from "react";
const IconCloudNetwork2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.6 43.3c-3.2 0-5.8 2.4-6.3 5.6h-3.5V36.4c.2.1.4.1.7.1 6.2-1.7 10.4-7.3 10.4-13.7 0-3.6-1.3-7-3.8-9.7-2.1-2.3-5.1-3.9-8.3-4.4-1.7-2.8-4.2-4.9-7.2-6-1.3-.4-2.8-.7-4.4-.7-7.5 0-13.6 6.1-13.7 13.5-5.6.3-10.1 5-10.1 10.7 0 5.8 5.1 10.7 11.1 10.7h1.2c.2 0 .3-.1.5-.1v12h-3.5c-.5-3.1-3.1-5.6-6.3-5.6-3.5 0-6.4 2.9-6.4 6.6s2.9 6.6 6.4 6.6c3.2 0 5.8-2.4 6.3-5.6h4.5c.6 0 1-.4 1-1V30.4c0-.6-.4-1-1-1s-1 .4-1 1v4.7c-.1-.1-.3-.1-.5-.1h-1.2c-4.9 0-9.1-4-9.1-8.7 0-4.9 3.8-8.7 8.7-8.7h.4c.6 0 1-.4 1-1v-.8C19.5 9.2 24.7 4 31.2 4c1.4 0 2.6.2 3.7.6 2.7.9 5 2.9 6.4 5.5.2.3.4.5.8.5 2.9.3 5.6 1.7 7.6 3.8 2.1 2.3 3.3 5.2 3.3 8.3 0 5.5-3.7 10.3-9 11.8-.1 0-.1 0-.1.1v-4.2c0-.6-.4-1-1-1s-1 .4-1 1v19.5c0 .6.4 1 1 1h4.5c.5 3.1 3.1 5.6 6.3 5.6 3.5 0 6.4-2.9 6.4-6.6s-3-6.6-6.5-6.6M10.4 54.4c-2.4 0-4.4-2-4.4-4.6s2-4.6 4.4-4.6 4.4 2 4.4 4.6-2 4.6-4.4 4.6m43.2 0c-2.4 0-4.4-2-4.4-4.6s2-4.6 4.4-4.6 4.4 2 4.4 4.6-2 4.6-4.4 4.6" />
    <path d="M33 49V27.6c0-.6-.4-1-1-1s-1 .4-1 1V49c-3.1.5-5.4 3.2-5.4 6.5 0 3.6 2.9 6.6 6.4 6.6s6.4-2.9 6.4-6.6c0-3.3-2.3-6-5.4-6.5m-1 11c-2.4 0-4.4-2-4.4-4.6s2-4.6 4.4-4.6 4.4 2 4.4 4.6-2 4.6-4.4 4.6" />
  </svg>
);
export default IconCloudNetwork2;
