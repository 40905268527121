import * as React from "react";
const IconFileExclamation = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54 17.5 40.2 3.9c-.5-.6-1.3-.9-2.1-.9h-24c-2.8 0-5 2.2-5 5v48c0 2.8 2.2 5 5 5h35.7c2.8 0 5-2.2 5-5V19.6c.1-.8-.3-1.6-.8-2.1m-2.3.5H39.9V6.4zm1.2 38c0 1.7-1.3 3-3 3H14.1c-1.7 0-3-1.3-3-3V8c0-1.7 1.3-3 3-3h23.8v14c0 .6.4 1 1 1h13.9v36z" />
    <path d="M31 25.6c-.6 0-1 .4-1 1v13.5c0 .6.4 1 1 1s1-.4 1-1V26.6c0-.5-.5-1-1-1M31 46.6c-.6 0-1 .4-1 1v.5c0 .6.4 1 1 1s1-.4 1-1v-.5c0-.5-.5-1-1-1" />
  </svg>
);
export default IconFileExclamation;
