import * as React from "react";
const IconRocketLaunch2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51 21.5h-1.6c-.6 0-1 .4-1 1s.4 1 1 1H51c1.7 0 3.2 1.4 3.2 3.2V50c0 1-.8 1.8-1.8 1.8H11.6c-1 0-1.8-.8-1.8-1.8V26.7c0-1.7 1.4-3.2 3.2-3.2h.9c.6 0 1-.4 1-1s-.4-1-1-1H13c-2.8 0-5.2 2.3-5.2 5.2V50c0 2.1 1.7 3.8 3.8 3.8h15.6l-1.2 7c0 .3 0 .6.2.8s.5.4.8.4h10c.3 0 .6-.1.8-.4.2-.2.3-.5.2-.8l-1.2-7.1h15.6c2.1 0 3.8-1.7 3.8-3.8V26.7c0-2.9-2.3-5.2-5.2-5.2M35.8 60h-7.7l1-6.3h5.6z" />
    <path d="M18.2 38.5c.2.1.3.1.5.1s.3 0 .5-.1l5.5-3v1.7c0 .6.4 1 1 1h12.7c.6 0 1-.4 1-1v-1.7l5.5 3c.1.1.3.1.5.1s.4 0 .5-.1c.3-.2.5-.5.5-.9v-5.9c0-2.2-1.2-4.2-3.1-5.3l-3.8-2.1v-4c0-10.8-3.4-15.2-6.7-18.1-.4-.3-.9-.3-1.3 0-3.3 2.9-6.7 7.3-6.7 18.2v4L21 26.5c-1.9 1-3.1 3.1-3.1 5.3v5.9c-.2.3 0 .6.3.8m24-10.3c1.3.7 2.1 2 2.1 3.5v4.2l-4.9-2.7v-6.6zm-15.6-7.8c0-9.3 2.5-13.3 5.4-16 2.9 2.8 5.4 6.8 5.4 16v15.8H26.6V20.4m-6.9 11.3c0-1.5.8-2.8 2.1-3.5l2.9-1.6v6.6l-4.9 2.7v-4.2z" />
  </svg>
);
export default IconRocketLaunch2;
