import * as React from "react";
const IconChestOfDrawers = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 3H7C4.2 3 2 5.3 2 8v42.9c0 2.8 2.2 5 5 5h.7V60c0 .6.4 1 1 1s1-.4 1-1v-4.1h43.1V60c0 .6.4 1 1 1s1-.4 1-1v-4.1H57c2.8 0 5-2.2 5-5V8c0-2.7-2.2-5-5-5m3 5v10.6H33.5V5H57c1.7 0 3 1.4 3 3M7 5h24.5v31.1H4V8c0-1.6 1.3-3 3-3M4 50.9V38.1h27.5v15.4c0 .1 0 .2.1.4H7c-1.7 0-3-1.3-3-3m53 3H33.4c0-.1.1-.2.1-.4v-33H60v30.3c0 1.8-1.3 3.1-3 3.1" />
    <path d="M40.4 34.8c-.6 0-1 .4-1 1v2.1c0 .6.4 1 1 1s1-.4 1-1v-2.1c0-.6-.4-1-1-1M18.7 44h-2.1c-.6 0-1 .4-1 1s.4 1 1 1h2.1c.6 0 1-.4 1-1s-.4-1-1-1M24.1 21.9c.6 0 1-.4 1-1v-2.1c0-.6-.4-1-1-1s-1 .4-1 1v2.1c0 .6.5 1 1 1M45 13.5h2.1c.6 0 1-.4 1-1s-.4-1-1-1H45c-.6 0-1 .4-1 1s.4 1 1 1" />
  </svg>
);
export default IconChestOfDrawers;
