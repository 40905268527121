import * as React from "react";
const IconHandsHolding = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M45.6 51.5h-6.7c-2.8 0-5.1-2.3-5.1-5.1v-6.8c0-2.8 1.1-5.5 3.1-7.5l3.5-3.5c.9-.9 2.1-1.4 3.4-1.4s2.5.5 3.5 1.4l1.4 1.4c.7.8 1.2 1.7 1.3 2.7l.3-.3c.9-.9 1.3-2 1.3-3.2V17.4c0-2.7 2.2-4.9 4.9-4.9s4.9 2.2 4.9 4.9v17.1c0 2.5-1 4.8-2.7 6.5l-8.6 8.6c-1.1 1.2-2.8 1.9-4.5 1.9m-1.8-22.3c-.8 0-1.5.3-2 .8l-3.5 3.5c-1.6 1.6-2.5 3.8-2.5 6.1v6.8c0 1.7 1.4 3.1 3.1 3.1h6.7c1.2 0 2.3-.5 3.2-1.3l8.6-8.6c1.4-1.4 2.1-3.2 2.1-5.1V17.4c0-1.6-1.3-2.9-2.9-2.9s-2.9 1.3-2.9 2.9v11.8c0 1.7-.7 3.4-1.9 4.6l-4.4 4.4-2 2c-.4.4-1 .4-1.4 0s-.4-1 0-1.4l3.3-3.3c1.1-1.1 1.1-2.9 0-4.1L45.8 30c-.5-.5-1.2-.8-2-.8M25.1 51.5h-6.7c-1.7 0-3.4-.7-4.6-1.9L5.2 41c-1.7-1.7-2.7-4.1-2.7-6.5V17.4c0-2.7 2.2-4.9 4.9-4.9s4.9 2.2 4.9 4.9v11.8c0 1.2.5 2.3 1.3 3.2l.3.3c.2-1 .6-1.9 1.3-2.7l1.4-1.4c.9-.9 2.1-1.4 3.4-1.4s2.5.5 3.4 1.4l3.5 3.5c2 2 3.1 4.7 3.1 7.5v6.8c.2 2.8-2.1 5.1-4.9 5.1m-17.7-37c-1.6 0-2.9 1.3-2.9 2.9v17.1c0 1.9.8 3.8 2.1 5.1l8.6 8.6c.9.9 2 1.3 3.2 1.3h6.7c1.7 0 3.1-1.4 3.1-3.1v-6.8c0-2.3-.9-4.5-2.5-6.1L22.2 30c-.5-.5-1.3-.8-2-.8-.8 0-1.5.3-2 .9l-1.4 1.4c-1.1 1.1-1.1 2.9 0 4.1l3.3 3.3c.4.4.4 1 0 1.4s-1 .4-1.4 0l-2-2-4.4-4.4c-1.2-1.2-1.9-2.9-1.9-4.6V17.4c0-1.6-1.3-2.9-3-2.9" />
  </svg>
);
export default IconHandsHolding;
