import * as React from "react";
const IconPassport4 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M37 50.1H27c-.6 0-1 .4-1 1s.4 1 1 1h10c.6 0 1-.4 1-1s-.4-1-1-1" />
    <path d="M46.9 2H15.1c-1.7 0-3 1.3-3 3v54c0 1.7 1.3 3 3 3H47c2.8 0 5-2.2 5-5V7c-.1-2.8-2.3-5-5.1-5m3 55c0 1.7-1.3 3-3 3H15.1c-.6 0-1-.4-1-1V5c0-.6.4-1 1-1H47c1.7 0 3 1.3 3 3v50z" />
    <path d="M39.5 42.9h-15c-.6 0-1 .4-1 1s.4 1 1 1h15.1c.6 0 1-.4 1-1s-.5-1-1.1-1M32 10.1c-7.3 0-13.2 5.9-13.2 13.2S24.7 36.5 32 36.5s13.2-5.9 13.2-13.2S39.3 10.1 32 10.1m11.2 12.2h-6.1c-.1-3.6-.9-7-2.3-9.9 4.5 1.2 8 5.2 8.4 9.9M32.4 12.1C34 15 35 18.5 35.1 22.3h-6.2c.1-3.8 1.1-7.4 2.7-10.2h.8m-3.2.4c-1.4 2.9-2.1 6.3-2.3 9.9h-6.1c.4-4.8 3.9-8.8 8.4-9.9m-8.4 11.8h6.1c.1 3.6.9 7 2.3 9.9-4.5-1.2-8-5.1-8.4-9.9m10.8 10.2c-1.6-2.8-2.6-6.4-2.7-10.2h6.2c-.1 3.8-1.1 7.4-2.7 10.2h-.8m3.2-.3c1.4-2.9 2.1-6.3 2.3-9.9h6.1c-.4 4.8-3.9 8.7-8.4 9.9" />
  </svg>
);
export default IconPassport4;
