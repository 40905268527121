import * as React from "react";
const IconDollarBubble = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M30.2 24.1h7.7c.6 0 1-.4 1-1s-.4-1-1-1h-3.7v-2.5c0-.6-.4-1-1-1s-1 .4-1 1v2.5h-1.9c-2.8 0-5.1 2.3-5.1 5.1s2.3 5.1 5.1 5.1h3.5c1.7 0 3.1 1.4 3.1 3.1s-1.4 3.1-3.1 3.1h-7.7c-.6 0-1 .4-1 1s.4 1 1 1h6.1V43c0 .6.4 1 1 1s1-.4 1-1v-2.6c2.6-.2 4.7-2.4 4.7-5.1 0-2.8-2.3-5.1-5.1-5.1h-3.5c-1.7 0-3.1-1.4-3.1-3.1s1.3-3 3-3" />
    <path d="M32 2C15.9 2 2.7 15.1 2.7 31.3 2.7 38 5 44.5 9.2 49.6c-1.1 1.8-4.1 7.1-4.8 9.9-.3 1.1.2 1.7.5 2 .4.3 1 .5 1.7.5 3.1 0 8.9-2.5 12.7-4.3 4 1.9 8.2 2.9 12.7 2.9 16.1 0 29.3-13.1 29.3-29.3S48.1 2 32 2m0 56.5c-4.3 0-8.4-1-12.2-2.9q-.45-.15-.9 0C13.3 58.4 7.7 60.3 6.3 60c.8-3.1 4.9-9.9 4.9-10 .2-.4.2-.8-.1-1.2C7 43.9 4.7 37.7 4.7 31.3 4.7 16.2 17 4 32 4s27.3 12.2 27.3 27.3S47 58.5 32 58.5" />
  </svg>
);
export default IconDollarBubble;
