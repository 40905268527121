import * as React from "react";
const IconTargetCustomerCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 31h-7.3c-.6-11.2-9.6-20.1-20.7-20.6V3c0-.6-.4-1-1-1s-1 .4-1 1v7.4c-11.1.5-20.1 9.4-20.7 20.6H3c-.6 0-1 .4-1 1s.4 1 1 1h7.3c.4 11.3 9.5 20.4 20.7 20.9V61c0 .6.4 1 1 1s1-.4 1-1v-7.1c11.2-.5 20.2-9.6 20.7-20.9H61c.6 0 1-.4 1-1s-.4-1-1-1M33 51.9v-1.4c0-.6-.4-1-1-1s-1 .4-1 1v1.4c-10.1-.5-18.2-8.7-18.7-18.9h3.8c.6 0 1-.4 1-1s-.4-1-1-1h-3.8C12.9 21 21 12.9 31 12.4v1.1c0 .6.4 1 1 1s1-.4 1-1v-1.1C43 12.9 51.1 21 51.6 31h-1.2c-.6 0-1 .4-1 1s.4 1 1 1h1.2c-.4 10.2-8.5 18.4-18.6 18.9" />
    <path d="M35.7 36.8c2.1-1.2 3.5-3.5 3.5-6.1 0-3.9-3.2-7.1-7.1-7.1S25 26.8 25 30.7c0 2.6 1.4 4.9 3.5 6.1q-3.45.9-6.3 3.3c-.4.4-.4 1-.1 1.4.4.4 1 .5 1.4.1 2.4-2.1 5.4-3.3 8.6-3.3s6.2 1.2 8.6 3.3c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.3-1-.1-1.4-1.9-1.7-4-2.8-6.3-3.4m-8.8-6.1c0-2.8 2.3-5.1 5.1-5.1s5.1 2.3 5.1 5.1-2.3 5.1-5.1 5.1-5.1-2.3-5.1-5.1" />
  </svg>
);
export default IconTargetCustomerCopy;
