import * as React from "react";
const IconBookAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M15.3 49.8c1.1-1.1 2.7-1.8 4.3-1.8h28.1c2.6 0 4.8-2.2 4.8-4.8V6.8c0-2.6-2.2-4.8-4.8-4.8H16.4c-2.6 0-4.8 2.2-4.8 4.8v48c.4 4 3.8 7.2 8 7.2h31.6c.6 0 1-.4 1-1s-.4-1-1-1H19.6c-3.3 0-6-2.7-6-5.9V54c0-1.6.6-3.1 1.7-4.2M16.4 4h31.3c1.5 0 2.8 1.3 2.8 2.8v36.4c0 1.5-1.3 2.8-2.8 2.8H19.6c-2.1 0-4.2.8-5.7 2.3-.1.1-.2.2-.3.4V6.8c0-1.5 1.2-2.8 2.8-2.8" />
    <path d="M24.8 15.8h14.7c.6 0 1-.4 1-1s-.4-1-1-1H24.8c-.6 0-1 .4-1 1s.4 1 1 1" />
  </svg>
);
export default IconBookAlt;
