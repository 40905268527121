import * as React from "react";
const IconDeliveryTruck = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.2 36h-5.3V18.9c0-1.9-1.1-3.6-2.8-4.5L28.2 5c-1.4-.7-3.1-.7-4.5 0L4.8 14.4C3.1 15.3 2 17 2 18.9v35.7c0 2.7 2.2 5 5 5h9.4c.6 0 1-.4 1-1v-2.4H27c.6 1.8 2.3 3 4.2 3 2 0 3.6-1.3 4.2-3h13.7c.6 1.8 2.3 3 4.2 3s3.6-1.3 4.2-3H61c.6 0 1-.4 1-1V42.8c0-3.7-3.1-6.8-6.8-6.8M15.4 57.6H7c-1.6 0-3-1.3-3-3V18.9c0-1.1.6-2.2 1.6-2.7l18.9-9.5c.8-.4 1.8-.4 2.7 0l18.9 9.5c1 .5 1.6 1.5 1.6 2.7V36h-1.1v-3.7c0-.6-.4-1-1-1H35.2v-2.8c0-2.7-2.2-5-5-5h-9.8c-2.7 0-5 2.2-5 5zm17.8-26.2H17.4v-2.8c0-1.6 1.3-3 3-3h9.8c1.6 0 3 1.3 3 3zm-15.8 2h27.4v20.8h-9c-.3-2.2-2.1-3.9-4.4-3.9S27.2 52 27 54.2h-9.4V33.4zm13.9 23.8c-1.4 0-2.5-1.1-2.5-2.5s1.1-2.5 2.5-2.5 2.5 1.1 2.5 2.5-1.1 2.5-2.5 2.5m22.1 0c-1.4 0-2.5-1.1-2.5-2.5s1.1-2.5 2.5-2.5 2.5 1.1 2.5 2.5-1.1 2.5-2.5 2.5m6.6-3h-2.2c-.3-2.2-2.1-3.9-4.4-3.9S49.2 52 49 54.2h-2.2V38h8.4c2.7 0 4.8 2.2 4.8 4.8z" />
  </svg>
);
export default IconDeliveryTruck;
