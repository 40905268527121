import * as React from "react";
const IconSimpleLogo = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M25.917 5.456C29.525 9.407 39 20.623 39 29.455 39 37.488 32.528 44 24.544 44S10.09 37.488 10.09 29.455c0-8.832 9.475-20.048 13.083-23.999C24.02 4.527 24.544 4 24.544 4s.524.527 1.373 1.456M24.544 6.92q.349.383.76.848a81 81 0 0 1 4.847 6.041c1.763 2.439 3.494 5.168 4.779 7.914C36.224 24.49 37 27.14 37 29.454 37 36.395 31.412 42 24.544 42S12.09 36.395 12.09 29.455c0-2.316.776-4.966 2.07-7.733 1.284-2.746 3.016-5.475 4.778-7.914a81 81 0 0 1 5.607-6.889"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M31.426 32.301a1.48 1.48 0 0 0-2.061.266l-.203.26c-2.354 3.025-6.97 3.025-9.324 0l-.202-.26a1.48 1.48 0 0 0-2.062-.266 1.44 1.44 0 0 0-.27 2.036l.203.26c3.531 4.537 10.455 4.537 13.986 0l.202-.26a1.44 1.44 0 0 0-.269-2.036"
      clipRule="evenodd"
    />
  </svg>
);
export default IconSimpleLogo;
