import * as React from "react";
const IconShareMedical = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 21.6h-5.4L50 8.7c-.2-.5-.7-.9-1.3-.8-.6 0-1 .4-1.3.9l-5.3 13.6-2.9-6.8q-.3-.6-.9-.6h-6.2c-.6 0-1 .4-1 1s.4 1 1 1h5.5l3.3 7.6c.2.5.7.8 1.2.8s1-.4 1.2-.9l5.3-13.7L54 23q.3.6.9.6H61c.6 0 1-.4 1-1s-.4-1-1-1M55.7 41.6c-1.1-3.4-4.5-5.4-7.8-4.5C43.8 38.3 38 40 34.5 41c1.1-1.8 1.1-3.2.7-4.2-.7-1.8-2.8-2.6-4.5-2.6h-9.6c-1.1 0-2.1-.5-3.2-1.4-.9-.8-2-1.2-3.1-1.2H7.1C4.3 31.6 2 34 2 37v12.3c0 3 2.3 5.5 5.2 5.5h9.3q1.8 0 3.3-1.2l.6.3c2.6 1.4 5.5 2.2 8.4 2.2 1.8 0 3.5-.3 5.2-.8L51.3 50c4.5-1.6 5.2-5.4 4.4-8.4M7.2 52.8c-1.8 0-3.2-1.6-3.2-3.5V37.1c0-1.9 1.4-3.4 3.1-3.4h7.7c.7 0 1.3.2 1.8.7q.6.6 1.2.9v17.2c-.4.2-.9.3-1.3.3zm43.5-4.6-17.3 5.3c-1.4.5-3 .7-4.6.7-2.6 0-5.2-.7-7.5-1.9l-1.3-.7-.3-.1V36.2c.4.1.9.2 1.3.2h9.6c1 0 2.3.5 2.6 1.3.4 1-.4 2.7-2.3 4.5l-.6.5.4.7c.4.8.4.8 9.4-1.9l8.2-2.4c2.2-.6 4.5.7 5.3 3.1.5 1.3.9 4.5-2.9 6" />
  </svg>
);
export default IconShareMedical;
