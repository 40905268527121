import * as React from "react";
const IconSilverware = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M15.9 2C9.5 2 4.5 11.8 4.5 20.2c0 3.9 3.4 7.1 7.6 7.1h3.1V61c0 .6.4 1 1 1s1-.4 1-1V27.4h2.5c4.2 0 7.6-3.2 7.6-7.2 0-8.4-5-18.2-11.4-18.2m3.8 23.4H12c-3.1 0-5.6-2.3-5.6-5.2C6.5 12.8 10.7 4 15.9 4s9.4 8.8 9.4 16.2c0 2.9-2.5 5.2-5.6 5.2M58.5 2.7c-.6 0-1 .4-1 1v15.5c0 3.9-3.3 7.1-7.4 7.1h-.2V3.7c0-.6-.4-1-1-1s-1 .4-1 1v22.7h-.2c-4.1 0-7.4-3.2-7.4-7.1V3.7c0-.6-.4-1-1-1s-1 .4-1 1v15.5c0 5 4.2 9.1 9.4 9.1h.2V61c0 .6.4 1 1 1s1-.4 1-1V28.4h.2c5.2 0 9.4-4.1 9.4-9.1V3.7c0-.5-.4-1-1-1" />
  </svg>
);
export default IconSilverware;
