import * as React from "react";
const IconTakaCard = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.2 7.8H8.8C5.1 7.8 2 10.6 2 14v29c0 3.5 3.1 6.3 6.8 6.3h23.1c.6 0 1-.4 1-1s-.4-1-1-1H8.8C6.2 47.3 4 45.4 4 43V28.9h56v8.7c0 .6.4 1 1 1s1-.4 1-1V14c0-3.4-3.1-6.2-6.8-6.2m-46.4 2h46.4c2.6 0 4.8 1.9 4.8 4.3v4.3H4V14c0-2.3 2.2-4.2 4.8-4.2M4 26.9v-6.6h56v6.6z" />
    <path d="M51.9 46.4c-.5-.2-1.1 0-1.3.5s0 1.1.5 1.3c1.2.5 1.9 1.6 1.9 2.9 0 1.7-1.4 3.1-3.2 3.1-1.7 0-3.1-1.4-3.1-3.1V42h6.9c.6 0 1-.4 1-1s-.4-1-1-1h-6.9v-3.7c0-1.8-1.4-3.2-3.2-3.2H43c-1.8 0-3.2 1.4-3.2 3.2 0 .6.4 1 1 1s1-.4 1-1c0-.7.5-1.2 1.2-1.2h.5c.7 0 1.2.5 1.2 1.2V40h-2.9c-.6 0-1 .4-1 1s.4 1 1 1h2.9v9.1c0 2.8 2.3 5.1 5.1 5.1s5.2-2.3 5.2-5.1c.1-2-1.2-3.9-3.1-4.7" />
  </svg>
);
export default IconTakaCard;
