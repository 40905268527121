import * as React from "react";
const IconIslandAlt4 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 2c15.4 0 28 12.6 28 28 0 3.9-.8 7.6-2.2 10.9H43v-.2c0-3.7-4.3-6.7-10-7 0-.1 0-.3-.1-.4l-.2-.5s-2.1-4.7-1.5-8.3c.1-.7.4-1.5.7-2.4.3-.8.6-1.7.8-2.6.1-.1.1-.2.2-.4v-.2c1 .5 2.5 1.9 2.9 3.4.1.4.5.7 1 .7h.3c.5-.1.9-.7.7-1.2-.5-1.9-2.2-3.8-3.9-4.6 1.4-1.7 4.1-2.5 6.8-2 .5.1 1.1-.3 1.2-.8s-.3-1.1-.8-1.2c-3.3-.6-6.5.4-8.4 2.4-.3-1.4-.9-2.7-1.5-3.6s-1.5-1.9-2.8-2.2c-.5-.1-1.1.3-1.2.8s.3 1.1.8 1.2c.3.1.9.4 1.5 1.3.5.7.9 1.7 1.2 2.8-1.9-1-4.4-1-6.4.2-.5.3-.6.9-.3 1.4s.9.6 1.4.3c1.8-1.1 4.1-.8 5.5.7v.4c-.2.8-.5 1.7-.8 2.5-.3.9-.7 1.8-.8 2.7-.8 4.2 1.5 9.3 1.7 9.5-5.5.4-9.7 3.3-9.9 7.1H6.2C4.8 39.6 4 35.9 4 32 4 16.6 16.6 4 32 4m0 33.7c4.9 0 9 2.3 9 5v.2H23c.3-2.8 4.3-5.2 9-5.2M32 60c-10.8 0-20.2-6.1-24.8-15.1h49.7C52.2 53.9 42.8 60 32 60" />
  </svg>
);
export default IconIslandAlt4;
