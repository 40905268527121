import * as React from "react";
const IconMobileAltRounded = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M43.7 2H20.3C16.8 2 14 5 14 8.6v46.8c0 3.6 2.8 6.6 6.3 6.6h23.4c3.5 0 6.3-3 6.3-6.6V8.6C50 5 47.2 2 43.7 2M16 18.2h32v31.5H16zM20.3 4h23.4C46.1 4 48 6.1 48 8.6v7.6H16V8.6C16 6.1 17.9 4 20.3 4m23.4 56H20.3c-2.4 0-4.3-2.1-4.3-4.6v-3.7h32v3.7c0 2.5-1.9 4.6-4.3 4.6" />
    <path d="M30.2 11.3H34c.6 0 1-.4 1-1s-.4-1-1-1h-3.8c-.6 0-1 .4-1 1s.5 1 1 1" />
  </svg>
);
export default IconMobileAltRounded;
