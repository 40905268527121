import * as React from "react";
const IconShapeBuilderTool = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M39 47.9h-.8c-5.2-.2-10.2-2.4-13.9-6 5.7-2.5 9.8-8.2 9.8-14.8 0-6.7-4.2-12.5-10-14.9C28 8.3 33.4 6 39 6c11.6 0 21 9.4 21 21 0 5.5-2.1 10.7-5.9 14.6-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3c4.2-4.3 6.5-10 6.5-16C62 14.3 51.7 4 39 4c-6.5 0-12.7 2.8-17 7.6-1.3-.3-2.6-.5-3.9-.5-8.9 0-16.1 7.2-16.1 16s7.2 16.1 16.1 16.1q2.25 0 4.2-.6c4.1 4.4 9.9 7 15.9 7.3h.8c.6 0 1-.4 1-1s-.4-1-1-1M4 27.1C4 19.3 10.3 13 18.1 13s14.1 6.3 14.1 14.1-6.3 14.1-14.1 14.1S4 34.9 4 27.1" />
    <path d="M20.5 26.1h-7c-.6 0-1 .4-1 1s.4 1 1 1h7c.6 0 1-.4 1-1s-.4-1-1-1M51.6 27.1c0-.6-.4-1-1-1h-7c-.6 0-1 .4-1 1s.4 1 1 1h7c.5 0 1-.4 1-1M45.9 41.5c-.3-.3-.7-.4-1.1-.2s-.6.6-.6 1l1.1 16.8c0 .4.3.7.7.9.1 0 .2.1.3.1.3 0 .5-.1.7-.3l4.2-4.5 6.2-.3c.4 0 .7-.3.9-.7.1-.4 0-.8-.2-1.1zm4.8 11.7c-.3 0-.5.1-.7.3l-2.9 3.1-.8-11.9L55 53z" />
  </svg>
);
export default IconShapeBuilderTool;
