import * as React from "react";
const IconPointerLeftCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.3 25.3c-2.3-4.4-8.4-7.4-11.4-8.8-.5-.3-1-.5-1.2-.6-5-3.1-8.9-5.7-12.4-8.4-1.7-1.3-3.5-2.8-5.9-2.8-2.5 0-5 1.8-5.8 4.3-.7 2.1-.4 4.5.9 6.9.7 1.3 1.6 2.5 2.6 3.6-4.7-.1-9.5-.1-14.3 0-1.9.1-3.8.2-5.5.9-2 .9-3.4 2.6-4 4.6S2 29 3 30.5c.3.5.7.9 1.1 1.3 1.5 1.4 3.8 2.3 6.5 2.6 1.8.2 3.6.1 5.3 0 .4 0 .9-.1 1.3-.1-.8 2.6 0 5.6 2 7.5-1.1 2.9 0 6.3 2.5 8.2-.5 1.4-.5 3 0 4.5.7 1.9 2.1 3.4 4 4.1 1.6.6 3.3.6 4.8.6h4.9c1.8 0 3.7 0 5.5-.1 4.7-.2 10.7-.9 14.8-4.3 3.3-2.8 5.4-7.2 6-12.8.7-4.8.1-12-2.4-16.7m.5 16.6c-.4 3.6-1.6 8.4-5.3 11.5s-9.3 3.7-13.7 3.9c-3.4.1-7 .1-10.4.1-1.4 0-2.8 0-4.1-.5s-2.3-1.6-2.8-3c-.5-1.3-.4-2.7.2-3.7.1-.2.2-.5.1-.8s-.2-.5-.5-.6C21 47.5 20 44.3 21.2 42c.2-.4.1-1-.3-1.3-2.2-1.5-2.8-4.7-1.4-6.9.2-.3.2-.7 0-1s-.5-.5-.9-.5c-1 0-2 .1-3 .1-1.7.1-3.3.2-5 .1-2.2-.2-4.1-.9-5.3-2.1-.3-.3-.6-.6-.8-1-.5-1-.7-2.4-.3-3.8.4-1.5 1.5-2.7 2.8-3.3 1.4-.6 3-.7 4.7-.8 5.5-.2 11.1-.1 16.5 0 .4 0 .8-.2.9-.6.2-.4.1-.8-.2-1.1-1.4-1.5-2.9-3.1-3.8-4.9-.6-1.1-1.4-3.2-.7-5.3.6-1.7 2.3-3 3.9-3C30 6.6 31.5 7.8 33 9c3.5 2.7 7.4 5.4 12.5 8.5.3.2.7.4 1.4.7 2.7 1.3 8.5 4.1 10.5 7.9 2.4 4.5 2.9 11.2 2.4 15.8" />
  </svg>
);
export default IconPointerLeftCopy;
