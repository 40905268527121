import * as React from "react";
const IconTreesAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M18.8 3.6C9.5 3.6 2 11.1 2 20.4c0 8.9 7 16.2 15.8 16.7v22.3c0 .6.4 1 1 1s1-.4 1-1V37.1c8.8-.5 15.8-7.8 15.8-16.7 0-9.3-7.6-16.8-16.8-16.8m1 31.5V31l5.3-5.3c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-3.9 3.9v-5.7c0-.6-.4-1-1-1s-1 .4-1 1v5.7l-3.9-3.9c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l5.3 5.3v4.1C10.1 34.6 4 28.2 4 20.4c0-8.2 6.6-14.8 14.8-14.8s14.8 6.6 14.8 14.8c0 7.8-6.1 14.2-13.8 14.7M62 33.5c0-6.7-5.4-12.1-12.1-12.1s-12.1 5.4-12.1 12.1c0 6.4 4.9 11.6 11.1 12.1v13.7c0 .6.4 1 1 1s1-.4 1-1V45.6C57.1 45 62 39.8 62 33.5M50.9 43.6v-2.1l3.3-4.2c.3-.4.3-1.1-.2-1.4-.4-.3-1.1-.3-1.4.2l-1.7 2.2V34c0-.6-.4-1-1-1s-1 .4-1 1v4.2L47.1 36c-.3-.4-1-.5-1.4-.2s-.5 1-.2 1.4l3.3 4.2v2.1c-5.1-.5-9.1-4.8-9.1-10.1 0-5.6 4.5-10.1 10.1-10.1S60 27.9 60 33.5c0 5.2-4 9.6-9.1 10.1" />
  </svg>
);
export default IconTreesAlt1;
