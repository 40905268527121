import * as React from "react";
const IconBoundary = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m59.8 11.3-7.9-3.6c-1.1-.5-2.4-.5-3.5.2l-6 3.5c-.5.3-1.2.3-1.7.1L33 7.8c-1.1-.5-2.4-.5-3.5.1L23 11.4c-.5.3-1.1.3-1.7 0l-7.2-3.6c-1.1-.6-2.5-.5-3.6.1l-6.8 3.8c-1 .6-1.7 1.8-1.7 3.1v35.5c0 3.5 2.9 6.3 6.5 6.3h47c3.6 0 6.5-2.8 6.5-6.3V14.6c0-1.4-.9-2.7-2.2-3.3M24 13.1l6.5-3.5c.5-.3 1.1-.3 1.6 0l7.7 3.7c.5.2 1.1.4 1.6.4v41.2H22.5V13.5c.5 0 1-.2 1.5-.4M4 50.3V14.8c0-.6.3-1.2.9-1.5l6.8-3.8c.3-.2.6-.2.9-.2s.6.1.8.2l7.1 3.6v41.6h-12c-2.5 0-4.5-2-4.5-4.4m56 0c0 2.4-2 4.3-4.5 4.3h-12V13l5.9-3.4c.5-.3 1.1-.3 1.7-.1l7.9 3.6c.6.3 1 .9 1 1.5z" />
  </svg>
);
export default IconBoundary;
