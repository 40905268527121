import * as React from "react";
const IconTelephone2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30c4.9 0 9.7-1.2 14.5-3.7 3.8-2 5.8-4.4 6-7.1.3-3.4-2.2-6.3-3.6-7.6l.2-.2c1.9-1.9 1.9-4.6 0-6.5l-4-4c-1.9-1.9-4.5-1.8-6.4 0L37 34.5c-1-.6-2.9-1.9-4.3-3.2-1.4-1.4-2.8-3.2-3.4-4.3l1.6-1.6.1-.1c2-2 2-4.6 0-6.5l-4-4c-1.9-1.8-4.5-1.9-6.4 0l-2.5 2.5c-1.1 1.1-1.8 2.5-1.9 4-.2 2.3.5 4.4 1 5.8 1.2 3.3 3 6.3 5.7 9.6.8.9 1.5 1.7 2.2 2.4 2.8 2.8 6 5 9.5 6.8 1.7.8 4.1 1.8 6.8 2h.5c2 0 3.7-.7 5-2.1l.7-.7c1.1 1.1 3.2 3.4 3 6-.2 2.1-1.9 3.9-5 5.5-4.4 2.3-9 3.5-13.5 3.5C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28q0 4.2-1.2 8.1c-.2.5.1 1.1.7 1.2.5.2 1.1-.1 1.2-.7.9-2.7 1.3-5.6 1.3-8.6C62 15.5 48.5 2 32 2m9.5 43.8c-2.3-.2-4.5-1-6-1.8-3.3-1.6-6.3-3.8-9-6.4-.7-.7-1.4-1.4-2.1-2.3-2.5-3.1-4.3-5.9-5.4-9-.5-1.3-1-3.1-.9-5 .1-1.1.5-2 1.3-2.8l2.5-2.5c1.1-1.1 2.5-1.1 3.6 0l4 4c1.2 1.2 1.2 2.6 0 3.7v.3l-2.1 2.1c-.3.3-.4.7-.2 1.1.5 1.1 2.3 3.7 4.1 5.6 2.2 2.1 5.3 3.9 5.3 4 .4.2.9.1 1.2-.2l2.2-2.2c.5-.5 1.2-.8 1.8-.8s1.2.3 1.8.8l4 4c1.1 1.1 1.1 2.5 0 3.7-.3.3-2.2 2.2-2.2 2.3-1 1-2.3 1.4-3.9 1.4" />
  </svg>
);
export default IconTelephone2;
