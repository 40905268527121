import * as React from "react";
const IconShirtFull = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.7 12.4-9.9-9.9c-.2-.2-.4-.3-.7-.3H40.4c-.6 0-1 .4-1 1 0 3.9-3.2 7.1-7.1 7.1s-7.1-3.2-7.1-7.1c0-.6-.4-1-1-1H12.9c-.3 0-.5.1-.7.3l-9.9 9.9c-.2.2-.3.4-.3.7v37.4c0 .6.4 1 1 1h12V56c0 3.2 2.6 5.8 5.8 5.8h22.9c3.2 0 5.8-2.6 5.8-5.8v-4.6H61c.6 0 1-.4 1-1V13.1c0-.3-.1-.5-.3-.7M60 49.5H49.5V20.7c0-.6-.4-1-1-1s-1 .4-1 1V56c0 2.1-1.7 3.8-3.8 3.8H20.8c-2.1 0-3.8-1.7-3.8-3.8V20.7c0-.6-.4-1-1-1s-1 .4-1 1v28.7H4v-36l9.3-9.3h9.8c.5 4.6 4.4 8.1 9.1 8.1s8.6-3.6 9.1-8.1h9.3l9.3 9.3v36.1z" />
  </svg>
);
export default IconShirtFull;
