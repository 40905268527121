import * as React from "react";
const IconNetworkCloud = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.5 21.9q-2.4 0-4.5.9c.1-.8.2-1.6.2-2.5 0-7.7-6.4-13.9-14.2-13.9s-14.2 6.2-14.2 13.9c0 .8.1 1.7.2 2.5q-2.1-.9-4.5-.9C7.2 21.9 2 27 2 33.2s5.2 11.3 11.5 11.3H31v3.6c-1.8.4-3.3 1.8-3.7 3.7h-10c-.6 0-1 .4-1 1s.4 1 1 1h10.1c.5 2.2 2.4 3.8 4.7 3.8s4.2-1.6 4.7-3.8h10.1c.6 0 1-.4 1-1s-.4-1-1-1H36.7c-.4-1.8-1.8-3.3-3.7-3.7v-3.6h17.5c6.4 0 11.5-5.1 11.5-11.3s-5.2-11.3-11.5-11.3M34.8 52.8c0 1.6-1.3 2.8-2.8 2.8s-2.8-1.2-2.8-2.8c0-1.5 1.2-2.8 2.8-2.8 1.5 0 2.8 1.3 2.8 2.8m15.7-10.3h-37c-5.3 0-9.5-4.2-9.5-9.3s4.3-9.3 9.5-9.3c2 0 3.9.6 5.5 1.7.4.3.8.2 1.2 0 .3-.3.5-.7.3-1.1-.5-1.3-.8-2.7-.8-4.1 0-6.5 5.5-11.9 12.2-11.9s12.2 5.3 12.2 11.9c0 1.4-.3 2.8-.8 4.1-.2.4 0 .9.3 1.1.3.3.8.3 1.2 0 1.6-1.1 3.5-1.7 5.5-1.7 5.3 0 9.5 4.2 9.5 9.3.2 5.1-4.1 9.3-9.3 9.3" />
  </svg>
);
export default IconNetworkCloud;
