import * as React from "react";
const IconGraphAlt3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 58.6h-1.9V34.5c0-1.7-1.3-3-3-3h-5.7c-1.7 0-3 1.3-3 3v24.1H38V44.4c0-1.7-1.3-3-3-3h-5.7c-1.7 0-3 1.3-3 3v14.2h-9.4V30.8c0-1.7-1.3-3-3-3h-6c-1.7 0-3 1.3-3 3v27.8H3c-.6 0-1 .4-1 1s.4 1 1 1h58c.6 0 1-.4 1-1s-.4-1-1-1m-54.1 0V30.8c0-.6.4-1 1-1h5.7c.6 0 1 .4 1 1v27.8zm21.2 0V44.4c0-.6.4-1 1-1h5.7c.6 0 1 .4 1 1v14.2zm21.2 0V34.5c0-.6.4-1 1-1H56c.6 0 1 .4 1 1v24.1z" />
    <path d="M10.1 15.2c1.7 0 3.2-.7 4.3-1.9l12.2 7.9c-.4.8-.6 1.6-.6 2.5 0 3.3 2.6 5.9 5.9 5.9s5.9-2.6 5.9-5.9V23l10.6-4.4c1.1 1.4 2.8 2.4 4.7 2.4 3.3 0 5.9-2.6 5.9-5.9s-2.6-5.9-5.9-5.9-5.9 2.6-5.9 5.9c0 .6.1 1.2.3 1.7l-10.3 4.3c-1-2-3-3.3-5.3-3.3-1.6 0-3.1.7-4.2 1.7l-12.3-8c.3-.7.5-1.5.5-2.3 0-3.3-2.6-5.9-5.9-5.9S4.2 6 4.2 9.3s2.7 5.9 5.9 5.9m43.1-3.9c2.1 0 3.9 1.8 3.9 3.9s-1.7 3.9-3.9 3.9-3.9-1.8-3.9-3.9 1.7-3.9 3.9-3.9M32 19.9c2.1 0 3.9 1.8 3.9 3.9s-1.7 3.9-3.9 3.9c-2.1 0-3.9-1.8-3.9-3.9s1.8-3.9 3.9-3.9M10.1 5.4c2.1 0 3.9 1.8 3.9 3.9s-1.7 3.9-3.9 3.9-3.9-1.8-3.9-3.9S8 5.4 10.1 5.4" />
  </svg>
);
export default IconGraphAlt3;
