import * as React from "react";
const IconMirror1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M49.8 60h-5.4V47.3c0-.2-.1-.4-.2-.6 4.6-4.6 7.6-11.7 7.6-19.7 0-13.8-8.9-25-19.8-25S12.2 13.2 12.2 27.1c0 8 3 15.1 7.6 19.7-.1.2-.2.3-.2.6V60h-5.4c-.6 0-1 .4-1 1s.4 1 1 1h35.6c.6 0 1-.4 1-1s-.5-1-1-1M14.2 27.1C14.2 14.3 22.2 4 32 4s17.8 10.3 17.8 23.1-8 23.1-17.8 23.1c-9.8-.1-17.8-10.4-17.8-23.1m7.4 21.3c3 2.4 6.6 3.7 10.4 3.7s7.4-1.4 10.4-3.7V60H21.6z" />
  </svg>
);
export default IconMirror1;
