import * as React from "react";
const IconCircle = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 62C15.5 62 2 48.5 2 32S15.5 2 32 2s30 13.5 30 30-13.5 30-30 30m0-58C16.6 4 4 16.6 4 32s12.6 28 28 28 28-12.6 28-28S47.4 4 32 4" />
  </svg>
);
export default IconCircle;
