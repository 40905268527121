import * as React from "react";
const IconTrophy2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.1 60h-8v-3.3c0-1.8-1.2-3.2-2.7-3.8v-3.5c0-2.2-1.8-4-4-4H35l5.7-9.5c.1-.2.1-.3.1-.5V22.9c2.2-2.2 3.6-5.3 3.6-8.7C44.3 7.5 38.8 2 32 2S19.7 7.5 19.7 14.3c0 3.4 1.4 6.5 3.6 8.7v12.5c0 .2 0 .4.1.5l5.7 9.5h-1.4c-2.2 0-4 1.8-4 4V53c-1.6.5-2.7 2-2.7 3.8V60h-8c-.6 0-1 .4-1 1s.4 1 1 1h38c.6 0 1-.4 1-1s-.4-1-.9-1M21.7 14.3C21.7 8.6 26.3 4 32 4s10.3 4.6 10.3 10.3S37.7 24.5 32 24.5s-10.3-4.6-10.3-10.2m3.6 20.9V24.5c1.9 1.2 4.2 2 6.7 2s4.7-.7 6.7-2v10.6l-6.1 10.3h-1zm.4 14.2c0-1.1.9-2 2-2h8.6c1.1 0 2 .9 2 2v3.3H25.7zM23 60v-3.3c0-1.1.9-2 2-2h14c1.1 0 2 .9 2 2V60z" />
  </svg>
);
export default IconTrophy2;
