import * as React from "react";
const IconAlignLeft1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M5 49.9V14.1c0-.6-.4-1-1-1s-1 .4-1 1v35.8c0 .6.4 1 1 1s1-.5 1-1M20.9 50.2c3 0 5.4-2.4 5.4-5.3V19.2c0-3-2.4-5.4-5.4-5.4H19c-3 0-5.4 2.4-5.4 5.4v25.6c0 3 2.4 5.3 5.4 5.3h1.9zm-5.3-5.4V19.2c0-1.8 1.5-3.4 3.4-3.4h1.9c1.8 0 3.4 1.5 3.4 3.4v25.6c0 1.8-1.5 3.3-3.4 3.3H19c-1.9.1-3.4-1.4-3.4-3.3" />
    <path d="M55.7 2H4.9c-.6 0-1 .4-1 1s.4 1 1 1h50.8C57.5 4 59 5.5 59 7.4v49.3c0 1.8-1.5 3.3-3.3 3.3H4.9c-.6 0-1 .4-1 1s.4 1 1 1h50.8c3 0 5.3-2.4 5.3-5.3V7.4c0-3-2.4-5.4-5.3-5.4" />
  </svg>
);
export default IconAlignLeft1;
