import * as React from "react";
const IconCat = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51 49.7c-.5-.3-1-.5-1.5-.8-.9-.4-1.7-.7-2-1.4-.1-.3-.1-.6-.1-.9-.1-1.9 0-3.9.3-5.8.2-1 .3-1.9.7-2.7.2-.4.5-.8.7-1.3l.2-.3c1.5-2.5 2.3-4.8 2.4-7 .1-3.3-1.5-6.5-2.8-9l-.1-.1c3.1-2.1 4.4-5.9 4.8-8.2 0-.2 0-.5-.2-.7L52.3 10 51 2.8c-.1-.3-.3-.6-.6-.7s-.7-.1-.9.1l-6.2 4.1h-.9l-6.2-4.1c-.3-.2-.6-.2-.9-.1s-.5.4-.6.7L33.3 10l-1.2 1.7c-.1.2-.2.5-.2.7.3 2.2 1.5 5.6 4.2 7.8-.9 2.5-3.1 4.5-5.6 6.5l-3.3 2.7c-2.1 1.7-4.3 3.5-6 5.7-1.4 1.8-3 4.6-3 7.9-4.5.5-8 4.3-8 8.9 0 5.6 4.6 10.1 10.1 10.1h15c.6 0 1-.4 1-1s-.4-1-1-1h-15c-4.5 0-8.1-3.7-8.1-8.1 0-3.6 2.7-6.5 6.2-6.9.5 2.4 1.7 4.8 3.8 7.1l.3.4c1 1.2 2.2 2.6 3.9 2.8h24c.9 0 1.8-.5 2.2-1.3s.4-1.8-.1-2.6c-.2-.7-.8-1.3-1.5-1.7M34 12.5l1.1-1.6c.1-.1.1-.3.2-.4l1-5.9L41.5 8c.2.1.4.2.5.2h1.5c.2 0 .4-.1.5-.2l5.2-3.4 1 5.9c0 .1.1.3.2.4l1.1 1.6c-.3 1.6-1.9 7.7-8.2 7.7h-1.1c-6.2 0-7.9-6.1-8.2-7.7m17 40.4c0 .1-.2.3-.5.3h-9.7c-.4-2.7-2.6-4.8-5.5-5.1 1.8-1.1 2.8-3 2.8-5.2 0-4-3.2-7.2-7.2-7.2-.6 0-1 .4-1 1s.4 1 1 1c2.9 0 5.2 2.3 5.2 5.2 0 2.1-1.2 3.6-3.3 4.2-.6.2-1 .7-1 1.4 0 .6.5 1.2 1.1 1.3l1.2.2c.3.1.6.1.9.1h.1c1.8.2 3.2 1.4 3.6 3.1h-12c-.9-.1-1.8-1.2-2.6-2.1l-.3-.4c-1.6-1.8-3.5-4.5-3.5-7.6-.1-2.8 1.4-5.3 2.6-6.8 1.6-2.1 3.7-3.8 5.7-5.4l3.3-2.7c2.1-1.7 4.8-3.9 6.1-7 1.2.6 2.7 1 4.3 1h1.1c1.4 0 2.7-.3 3.8-.7 1.3 2.4 2.7 5.2 2.6 8q0 2.7-2.1 6l-.2.3-.9 1.5c-.5 1-.6 2.1-.8 3.2-.3 2-.4 4.1-.4 6.2 0 .5.1 1 .3 1.6.6 1.4 1.9 1.9 3 2.4.5.2.9.4 1.3.6.4.3.8.6 1.1 1.1.1.2 0 .4-.1.5" />
  </svg>
);
export default IconCat;
