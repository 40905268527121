import * as React from "react";
const IconBillboardAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.1 11.7h-6.7V4h2.2c.6 0 1-.4 1-1s-.4-1-1-1h-6.5c-.6 0-1 .4-1 1s.4 1 1 1h2.2v7.7H33V4h2.2c.6 0 1-.4 1-1s-.4-1-1-1h-6.5c-.6 0-1 .4-1 1s.4 1 1 1H31v7.7H16.6V4h2.2c.6 0 1-.4 1-1s-.4-1-1-1h-6.5c-.6 0-1 .4-1 1s.4 1 1 1h2.2v7.7H7.9c-2.2 0-4 1.8-4 4v29.8c0 2.2 1.8 4 4 4h3.5V61c0 .6.4 1 1 1s1-.4 1-1V49.5h37.2V61c0 .6.4 1 1 1s1-.4 1-1V49.5h3.5c2.2 0 4-1.8 4-4V15.7c0-2.2-1.8-4-4-4m2 33.8c0 1.1-.9 2-2 2H7.9c-1.1 0-2-.9-2-2V15.7c0-1.1.9-2 2-2H56c1.1 0 2 .9 2 2v29.8z" />
  </svg>
);
export default IconBillboardAlt1;
