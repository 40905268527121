import * as React from "react";
const IconCupidAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.4 11.6-4.1-4L53 3.5c-.4-.4-.9-.6-1.4-.6s-1 .2-1.4.6l-4 4.3c-.5.6-.7 1.4-.4 2.2l2.4 5.3-4 4c-2.2-1.2-4.5-1.6-6.9-1-4.4 1.1-7.7 5.2-9.2 7.4-1.5-2.2-4.7-6.4-9.2-7.4q-4.05-1.05-8.1 1.8C.9 27 7.9 41.4 8 41.5c.1.3 1.9 3.8 5.1 7.7L4 57.5v-5.9c0-.6-.4-1-1-1s-1 .4-1 1v8.5c0 .6.4 1 1 1h8.5c.6 0 1-.4 1-1s-.4-1-1-1H5.2l9.2-8.3c3.3 3.7 7.9 7.4 13.7 9.3h.6c13.1-4.2 19.5-18 19.8-18.5.1-.1 6.8-14.1-2.5-21.2l3.7-3.7 5.1 2.4c.3.1.6.2.8.2.5 0 1-.2 1.3-.5l4.4-4.1c.4-.4.6-.9.6-1.4.1-.8-.1-1.3-.5-1.7M47.7 9.2l3.9-4.3 3.5 3.4-5.4 5.4zm-1 31.5c-.1.1-6.2 13.3-18.3 17.4-5.2-1.8-9.4-5.3-12.5-8.7l3.4-3c.4-.4.4-1 .1-1.4-.4-.4-1-.4-1.4-.1l-3.3 3c-3.2-3.8-4.8-7.2-4.9-7.3s-6.3-13.1 2.4-19.1c1.6-1.1 3.2-1.7 4.8-1.7.6 0 1.1.1 1.7.2 5.2 1.2 8.7 7.8 8.8 7.9.2.3.5.5.9.5s.7-.2.9-.5c0-.1 3.6-6.6 8.8-7.9 2.1-.5 4.3 0 6.4 1.5 8.5 6.1 2.2 19 2.2 19.2m8.9-23.5-4.4-2.1 5.4-5.4L60 13z" />
  </svg>
);
export default IconCupidAlt;
