import * as React from "react";
const IconShipUnknown = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M42.2 57.2c-.2-.2-.5-.4-.8-.5l4.4-15.5c.7-2.4-.6-5-2.9-6l-3.1-1.3v-4.7c0-.6-.4-1-1-1s-1 .4-1 1V33l-9.3-3.9c-1.2-.5-2.6-.5-3.9 0L15.2 33v-7.1c0-1.7 1.4-3 3-3h13.1c.6 0 1-.4 1-1s-.4-1-1-1H20v-5.7c0-1.7 1.4-3 3-3h7.7c.6 0 1-.4 1-1s-.4-1-1-1h-3.2v-5c0-.6-.4-1-1-1s-1 .4-1 1v5H23c-2.8 0-5 2.3-5 5v5.7c-2.7.1-4.8 2.3-4.8 5v7.9l-3.1 1.3c-2.3 1-3.6 3.6-2.9 6l4.4 15.5c-.3.2-.5.3-.7.5l-3.6 3.1c-.4.4-.5 1-.1 1.4s1 .5 1.4.1l3.6-3.1c1.1-1 2.8-1 3.9 0l.9.8c1.9 1.6 4.7 1.6 6.6 0l.9-.8c1.1-1 2.8-1 4 0l.9.8c1.9 1.6 4.7 1.6 6.6 0l.9-.8c1.1-1 2.8-1 4 0l3.6 3.1c.2.2.4.2.7.2s.6-.1.8-.3c.4-.4.3-1-.1-1.4zm-6.6 0-1 .8c-1.1 1-2.8 1-4 0l-.9-.8c-1.9-1.6-4.7-1.6-6.6 0l-.8.8c-1.1 1-2.8 1-4 0l-.9-.8c-1.1-1-2.5-1.3-3.9-1.2L9.1 40.6c-.4-1.5.3-3 1.7-3.6l3.7-1.5h.1L25.3 31c.7-.3 1.6-.3 2.3 0l10.7 4.4h.1l3.7 1.5c1.4.6 2.2 2.1 1.7 3.6L39.5 56c-1.4-.2-2.8.2-3.9 1.2M49.6 2c-1.9 0-3.6.7-5 1.9-.4.4-.4 1-.1 1.4.4.4 1 .4 1.4.1 1-.9 2.3-1.4 3.7-1.4 3.1 0 5.5 2.5 5.5 5.5S52.6 15 49.6 15c-.6 0-1 .4-1 1v5.6c0 .6.4 1 1 1s1-.4 1-1V17c3.7-.5 6.5-3.7 6.5-7.5 0-4.1-3.4-7.5-7.5-7.5M49.5 26.1c-.6 0-1 .4-1 1v.3c0 .6.4 1 1 1s1-.4 1-1v-.3c0-.6-.4-1-1-1" />
  </svg>
);
export default IconShipUnknown;
