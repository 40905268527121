import * as React from "react";
const IconArchiveCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.6 4.6H6.4C4 4.6 2 6.6 2 9.1v6.3c0 2.4 2 4.4 4.4 4.4h.8v35.1c0 2.4 2 4.4 4.4 4.4h40.6c2.4 0 4.4-2 4.4-4.4V19.8h.8c2.4 0 4.4-2 4.4-4.4V9.1c.2-2.5-1.8-4.5-4.2-4.5m-2.9 50.3c0 1.3-1.1 2.4-2.4 2.4H11.7c-1.3 0-2.4-1.1-2.4-2.4V19.8h45.5v35.1zM60 15.4c0 1.3-1.1 2.4-2.4 2.4H6.4c-1.3 0-2.4-1.1-2.4-2.4V9.1c0-1.3 1.1-2.4 2.4-2.4h51.1c1.3 0 2.4 1.1 2.4 2.4v6.3z" />
    <path d="M23.5 43.5h17.1c1.7 0 3-1.3 3-3V34c0-1.7-1.3-3-3-3H23.5c-1.7 0-3 1.3-3 3v6.5c0 1.7 1.3 3 3 3m-1-9.5c0-.6.4-1 1-1h17.1c.6 0 1 .4 1 1v6.5c0 .6-.4 1-1 1H23.5c-.6 0-1-.4-1-1z" />
  </svg>
);
export default IconArchiveCopy;
