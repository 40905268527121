import * as React from "react";
const IconConstructionHammer = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.9 1.8H16.6c-2.9 0-5.5 1.6-6.8 4.2L3.1 19.5c-.8 1.5-.7 3.3.2 4.8s2.5 2.3 4.2 2.3h15.6V56c0 3.4 2.8 6.2 6.2 6.2h5.3c3.4 0 6.2-2.8 6.2-6.2V26.6h13c4.2 0 7.6-3.4 7.6-7.6V9.3c0-4.2-3.4-7.5-7.5-7.5M36.4 56c0 1-.8 1.8-1.8 1.8h-5.3c-1 0-1.8-.8-1.8-1.8V26.6h8.8V56zm20.5-36.9c0 1.7-1.4 3.1-3.1 3.1H7.5c-.1 0-.3-.1-.3-.2-.1-.1-.1-.3 0-.4L14 8c.5-1 1.6-1.7 2.7-1.7h37.2c1.7 0 3.1 1.4 3.1 3.1v9.7z" />
  </svg>
);
export default IconConstructionHammer;
