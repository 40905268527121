import * as React from "react";
const IconFunnelAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M52.4 2H11.6C8.4 2 5.8 4.6 5.8 7.8V11c0 1.4.5 2.8 1.4 3.9l17.3 21.4v18.5c0 2.4 1.5 4.6 3.8 5.5l3.4 1.2c.7.2 1.3.4 2 .4 1.2 0 2.3-.4 3.3-1 1.6-1.1 2.5-2.9 2.5-4.8V36.3L56.8 15c.9-1.1 1.4-2.5 1.4-3.9V7.8c0-3.2-2.6-5.8-5.8-5.8M7.8 7.8C7.8 5.7 9.5 4 11.6 4h40.7c2.1 0 3.8 1.7 3.8 3.8V11c0 .5-.1 1-.3 1.4H8c-.2-.4-.2-.9-.2-1.4zm30 27.6c-.1.2-.2.4-.2.6v20.1c0 1.3-.6 2.4-1.6 3.1s-2.3.9-3.5.5L29 58.5c-1.5-.6-2.5-2-2.5-3.6V36c0-.2-.1-.5-.2-.6l-17-20.9h45.3z" />
  </svg>
);
export default IconFunnelAlt2;
