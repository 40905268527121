import * as React from "react";
const IconGeometry = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.1 42 34.2 2.9c-.4-.6-1.1-.9-1.8-.9s-1.4.4-1.7.9L6.3 40v.1c-1.8 2-2.8 4.2-2.8 6.6C3.5 55.3 16.4 62 33 62c10.5 0 20.3-2.9 25.5-7.5.4-.4.5-1 .1-1.4s-1-.5-1.4-.1C52.3 57.3 43 60 33 60 18.1 60 5.5 53.9 5.5 46.7c0-6.9 11.8-12.8 26.1-13.2v10.1c0 1.5 1.2 2.8 2.8 2.8h23.4c1 0 2-.6 2.4-1.5s.4-2-.1-2.9M31.4 5.5l.1 26c-8.2.2-15.4 2-20.5 4.9zm27 38.4c-.1.1-.3.4-.7.4H34.3c-.4 0-.8-.3-.8-.8V33.4c2.6 0 5.1.2 7.4.6.5.1 1.1-.3 1.1-.8.1-.5-.3-1.1-.8-1.1-2.5-.4-5.1-.6-7.7-.6l-.1-26.1 25 37.7c.2.4.1.7 0 .8" />
  </svg>
);
export default IconGeometry;
