import * as React from "react";
const IconBmw = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 65 64"
    {...props}
  >
    <path
      
      d="M32.985 0c-17.635 0-32 14.342-32 32s14.342 32 32 32c17.657 0 32-14.343 32-32 0-17.658-14.343-32-32-32m0 63.102C15.834 63.102 1.883 49.152 1.883 32 1.883 14.849 15.833.898 32.985.898c17.15 0 31.102 13.95 31.102 31.102 0 17.151-13.951 31.102-31.102 31.102"
    />
    <path
      
      d="M14.752 19.43s.069.046.092.07c0 0 .023 0 .023-.024l1.865-2.348c.23-.276.46-.576.69-.852a.97.97 0 0 0 .138-.99c-.184-.345-.506-.575-.92-.575-.323 0-.576.184-.783.437l-.829 1.036a135 135 0 0 0-1.68 2.118c.299.23.575.46.874.714.184.138.346.276.507.414"
    />
    <path
      
      d="M32.985 1.427c-16.875 0-30.55 13.675-30.55 30.55s13.675 30.55 30.55 30.55 30.55-13.675 30.55-30.55-13.675-30.55-30.55-30.55m-4.927 1.911 2.855-.322 2.072 6.055 2.072-6.055 2.855.322.322 8.587L36 11.603l-.207-5.732-2.003 5.732h-1.589L30.176 5.87l-.207 5.732-2.233.322zM8.766 17.496l-.69-.552-.07-.07c.047-.045.07-.091.116-.137.598-.737 1.174-1.474 1.772-2.233.69-.852 1.382-1.727 2.072-2.602.138-.161.254-.322.392-.506.23-.3.552-.507.897-.645a2.09 2.09 0 0 1 2.164.46c.277.277.484.622.576.99.092.392.07.76-.046 1.151.046-.046.092-.091.161-.138.668-.46 1.543-.598 2.302-.253s1.243 1.036 1.358 1.842c.024.3 0 .621-.069.92-.184.576-.575 1.014-.943 1.451-.714.898-1.428 1.773-2.141 2.67-.599.737-1.198 1.474-1.773 2.21-.046.07-.092.116-.138.162 0 0-.046-.046-.07-.046-.206-.184-.437-.346-.667-.53-.875-.69-1.726-1.381-2.601-2.072-.875-.69-1.75-1.404-2.625-2.095m24.219 33.589c-10.544 0-19.085-8.541-19.085-19.108s8.541-19.085 19.085-19.085 19.085 8.541 19.085 19.085-8.541 19.108-19.085 19.108M51.149 22.1l-1.105-1.888 3.776-4.95-5.664 2.602-1.588-1.474L51.725 9.6l1.588 1.473-2.993 3.914 4.766-2.187 1.404 1.75-3.177 4.167 4.466-2.05 1.105 1.888-7.758 3.569z"
    />
    <path
      
      d="M14.66 13.974c.069-.322 0-.645-.23-.875a.95.95 0 0 0-1.083-.23c-.276.115-.437.345-.598.576-.69.828-1.335 1.68-2.026 2.509 0 0-.161.184-.138.184.138.115.276.207.414.322.368.3.714.576 1.06.875.368-.437.713-.875 1.058-1.335.368-.437.714-.875 1.06-1.312.16-.23.367-.415.46-.69M14.844 31.977h18.14V13.859c-9.899.253-17.864 8.242-18.14 18.118M32.985 50.118c9.9-.253 17.865-8.242 18.14-18.141h-18.14z"
    />
  </svg>
);
export default IconBmw;
