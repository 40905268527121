import * as React from "react";
const IconQuestionCircleCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
    <path d="M33.6 12.7c-2.6-.5-5.3.2-7.3 1.9s-3.2 4.2-3.2 6.8c0 1 .2 2 .5 3 .2.5.8.8 1.3.6s.8-.8.6-1.3c-.3-.7-.4-1.5-.4-2.3 0-2 .9-4 2.5-5.3s3.6-1.9 5.7-1.5c2.8.5 5.1 2.8 5.5 5.5.6 3.3-1.1 6.4-4.2 7.6-2.2 1.1-3.6 3.3-3.6 5.8v6.4c0 .6.4 1 1 1s1-.4 1-1v-6.4c0-1.7 1-3.2 2.4-3.8 3.9-1.6 6.1-5.7 5.4-9.8-.7-3.6-3.6-6.6-7.2-7.2M32.1 47.6h-.3c-.6 0-1 .4-1 1s.4 1 1 1h.3c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconQuestionCircleCopy;
