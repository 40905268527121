import * as React from "react";
const IconAlignLeft3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M41.7 32c0 .6.4 1 1 1H61c.6 0 1-.4 1-1s-.4-1-1-1H42.7c-.6 0-1 .4-1 1M60.7 49.8c-.6 0-1 .4-1 1v5.8c0 1.8-1.5 3.3-3.3 3.3h-5.8c-.6 0-1 .4-1 1s.4 1 1 1h5.8c2.9 0 5.3-2.4 5.3-5.3v-5.8c0-.6-.4-1-1-1M50.6 4.1h5.8c1.8 0 3.3 1.5 3.3 3.3v5.8c0 .6.4 1 1 1s1-.4 1-1V7.5c0-2.9-2.4-5.3-5.3-5.3h-5.8c-.6 0-1 .4-1 1s.5.9 1 .9M13.1 59.9H7.3c-1.8 0-3.3-1.5-3.3-3.3V33h17c.6 0 1-.4 1-1s-.4-1-1-1H4V7.5c0-1.8 1.5-3.3 3.3-3.3h5.8c.6 0 1-.4 1-1s-.4-1-1-1H7.3C4.4 2.1 2 4.5 2 7.5v49.1c0 2.9 2.4 5.3 5.3 5.3h5.8c.6 0 1-.4 1-1s-.4-1-1-1M61.7 22.1v-1.4c0-.6-.4-1-1-1s-1 .4-1 1v1.4c0 .6.4 1 1 1s1-.5 1-1M60.7 41.3c-.6 0-1 .4-1 1v1.4c0 .6.4 1 1 1s1-.4 1-1v-1.4c0-.6-.4-1-1-1M43.4 60H42c-.6 0-1 .4-1 1s.4 1 1 1h1.4c.6 0 1-.4 1-1s-.4-1-1-1M21.8 60h-1.4c-.6 0-1 .4-1 1s.4 1 1 1h1.4c.6 0 1-.4 1-1s-.5-1-1-1M42 4.3h1.4c.6 0 1-.4 1-1s-.4-1-1-1H42c-.6 0-1 .4-1 1s.4 1 1 1M21.8 2.3h-1.4c-.6 0-1 .4-1 1s.4 1 1 1h1.4c.6 0 1-.4 1-1s-.5-1-1-1M31.9 41.8c-.6 0-1 .4-1 1v18.3c0 .6.4 1 1 1s1-.4 1-1V42.8c0-.5-.5-1-1-1M31.9 1.9c-.6 0-1 .4-1 1v18.3c0 .6.4 1 1 1s1-.4 1-1V2.9c0-.6-.5-1-1-1" />
  </svg>
);
export default IconAlignLeft3;
