import * as React from "react";
const IconCandyAlt3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m60.3 7.7-4-4A5.8 5.8 0 0 0 51.8 2c-1.6.1-3.2.9-4.2 2.1l-6.1 7.5C33.2 7.8 23 9.3 16.2 16.1s-8.3 17-4.5 25.3l-7.6 6.2c-1.3 1-2.1 2.6-2.1 4.2-.1 1.7.5 3.3 1.7 4.5l4 4c1.1 1.1 2.6 1.7 4.2 1.7h.3c1.7-.1 3.2-.9 4.3-2.2l6.2-7.6c3 1.4 6.2 2.1 9.4 2.1 5.7 0 11.5-2.2 15.9-6.6 6.8-6.8 8.3-17 4.5-25.3l7.5-6.1c1.3-1.1 2.1-2.6 2.1-4.3 0-1.5-.7-3.1-1.8-4.3M14.9 58.6c-.7.9-1.7 1.4-2.8 1.4-1.1.1-2.1-.4-2.9-1.1l-4-4c-.8-.8-1.2-1.9-1.1-3s.6-2.1 1.4-2.8l7.2-5.9c1 1.6 2.1 3.2 3.5 4.6s3 2.6 4.6 3.5zm2.7-12.2C11.8 40.5 10.2 32 13 24.7L39.3 51c-7.3 2.8-15.8 1.2-21.7-4.6m28.9 0c-1.6 1.6-3.4 2.9-5.3 3.8l-.1-.1-27.3-27.3c1-1.9 2.2-3.7 3.8-5.3 1.5-1.5 3.3-2.8 5.1-3.7l27.5 27.5c-.9 1.8-2.2 3.6-3.7 5.1m4.6-7.1L24.7 12.9c2.4-.9 4.9-1.4 7.4-1.4 5.2 0 10.5 2 14.5 6 5.8 5.9 7.3 14.5 4.5 21.8m7.5-24.4-7.1 5.8c-1-1.7-2.1-3.2-3.5-4.6s-3-2.6-4.6-3.5l5.8-7.1c.7-.9 1.7-1.4 2.8-1.4 1.1-.1 2.2.4 3 1.1l4 4c.6.7 1 1.7 1 2.8-.1 1.1-.6 2.2-1.4 2.9" />
  </svg>
);
export default IconCandyAlt3;
