import * as React from "react";
const IconAudi = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 65 64"
    {...props}
  >
    <path
      
      d="m42.182 39.684 2.648 2.945h-.674c-3.685 0-6.68 1.398-6.68 4.426 0 2.434 2.995 4.425 6.68 4.425s6.68-1.99 6.68-4.425-3.044-5.446-4.59-7.355h-4.064zm5.165 7.272c0 1.267-1.43 2.287-3.175 2.287s-3.191-1.02-3.191-2.287 1.43-2.287 3.191-2.287 3.175 1.02 3.175 2.287M33.28 47.614c0 2.419-3.964 2.419-3.964 0v-4.755h-3.422v5.068c0 4.952 10.727 5.001 10.727 0v-5.068h-3.34z"
    />
    <path
      
      fillRule="evenodd"
      d="M54.968 42.86v8.57h-3v-8.57z"
      clipRule="evenodd"
    />
    <path
      
      d="m14.197 39.98 1.184 1.448-6.597 10.02h3.965l1.07-1.778h7.534l1.12 1.777h4.129l-7.914-11.45h-4.491zm1.036 7.354 2.27-3.75 2.37 3.75zM53.797 12.358c-2.65-.033-5.1.855-7.058 2.368a11.3 11.3 0 0 0-6.73-2.336 11.32 11.32 0 0 0-7.057 2.353 11.3 11.3 0 0 0-6.745-2.336c-2.633-.05-5.067.839-7.025 2.32-1.86-1.432-4.18-2.27-6.696-2.32-6.252-.082-11.401 4.952-11.5 11.187-.082 6.252 4.935 11.401 11.187 11.484a11.25 11.25 0 0 0 7.025-2.337 11.23 11.23 0 0 0 6.712 2.32c2.65.033 5.1-.839 7.058-2.352 1.876 1.43 4.196 2.32 6.73 2.336 2.648.049 5.1-.856 7.057-2.353a11.25 11.25 0 0 0 6.729 2.336c6.252.083 11.401-4.935 11.5-11.187.082-6.252-4.952-11.401-11.187-11.5m-.28 21.387a10.06 10.06 0 0 1-6.762-2.73c-1.941 1.76-4.31 2.796-7.025 2.747a9.98 9.98 0 0 1-6.745-2.731c-1.892 1.76-4.459 2.78-7.058 2.747-2.5-.033-4.92-1.02-6.729-2.698a9 9 0 0 1-1.003.806 9.9 9.9 0 0 1-6.022 1.909c-4.096-.05-7.617-2.583-9.114-6.153a4.5 4.5 0 0 1-1.037-.198 11.1 11.1 0 0 1-.641-3.85C1.463 17.738 6.15 13 11.926 12.803c.149.23.33.526.477.888h.05c2.583.05 5.05 1.07 6.893 2.88l-.082-1.416a10.85 10.85 0 0 1 6.416-2.352c.082.131.313.444.51.888 2.534.033 5.117 1.152 6.894 2.863L33 15.203a10.64 10.64 0 0 1 6.45-2.401c.164.23.328.526.477.872h.049c2.55.033 5.084 1.2 6.91 2.928l-.099-1.431a11.05 11.05 0 0 1 6.45-2.419c.081.132.46.889.526.889 5.396.246 9.69 3.487 9.92 10.184.165 5.527-4.656 9.97-10.184 9.903"
    />
    <path
      
      d="M15.776 27.444a10.4 10.4 0 0 1-.641-3.85c.033-2.5.905-4.787 2.352-6.614.56 0 1.12-.032 1.448-.032a9.46 9.46 0 0 0-6.465-2.682c-5.232-.066-9.543 4.13-9.608 9.345-.05 4.557 3.126 8.406 7.403 9.377.148-.18.313-.378.477-.592.477.082.987.132 1.497.148 1.99.033 3.817-.609 5.397-1.777l1.316.033a10.2 10.2 0 0 1-2.123-3.175 5.4 5.4 0 0 1-1.02-.181M12.24 32.1c-4.606-.066-8.292-3.85-8.242-8.457.066-4.59 3.866-8.291 8.473-8.242 1.71.017 3.29.56 4.606 1.464-1.431 1.876-2.303 4.196-2.32 6.73a11.34 11.34 0 0 0 2.32 7.024c-1.02.675-2.747 1.514-4.837 1.48"
    />
    <path
      
      d="M20.695 21.044c.181-.46.51-.724.51-.724-.312-1.102-1.2-2.254-2.007-3.093a9.63 9.63 0 0 0-2.632 6.383c-.05 1.679.592 4.327 1.875 5.808.247.28.428.592.774.889l.032-1.201c1.218-1.448 1.843-3.324 1.876-5.232 0-.987-.132-1.942-.428-2.83m-1.497 7.453a8.4 8.4 0 0 1-1.497-4.854 8.14 8.14 0 0 1 1.48-4.623 8.28 8.28 0 0 1 1.481 4.87 8.2 8.2 0 0 1-1.48 4.623M34.433 21.028c.18-.477.51-.724.51-.724-.264-.938-1.218-2.304-1.974-3.06a9.4 9.4 0 0 0-2.583 6.366 9.47 9.47 0 0 0 2.632 6.68l.033-1.119c1.02-1.645 1.793-3.224 1.81-5.297 0-.987-.132-1.941-.428-2.83m-1.465 7.403a8.25 8.25 0 0 1-1.447-4.82 8.4 8.4 0 0 1 1.447-4.574 8.5 8.5 0 0 1 1.448 4.804 8.5 8.5 0 0 1-1.447 4.59"
    />
    <path
      
      d="M43.366 27.411a10.7 10.7 0 0 1-.642-3.85c.033-2.5.922-4.787 2.353-6.613h1.415c-1.76-1.662-4.014-2.666-6.498-2.699a9.44 9.44 0 0 0-6.647 2.6 9.95 9.95 0 0 1 2.747 7.025c-.032 2.484-1.02 4.968-2.714 6.762a9.4 9.4 0 0 0 4.392 2.336c.149-.198.313-.395.478-.593.477.083.987.132 1.497.149 2.155.032 3.965-.757 5.495-1.794l1.2-.033a10.2 10.2 0 0 1-2.056-3.109 5.4 5.4 0 0 1-1.02-.181m-3.603 4.656a8.4 8.4 0 0 1-4.656-1.48 11.27 11.27 0 0 0 2.304-6.697c.049-2.632-.84-5.05-2.32-7.008a8.4 8.4 0 0 1 4.902-1.514 8.37 8.37 0 0 1 4.64 1.497 11.27 11.27 0 0 0-2.303 6.696 11.32 11.32 0 0 0 2.32 7.009c-1.02.674-2.764 1.53-4.887 1.497M62.549 20.27c-.757-2.68-4.557-6.053-8.77-6.053-2.566-.033-4.902.97-6.646 2.599 1.794 1.876 2.797 4.442 2.78 7.041-.032 2.419-1.02 5.018-2.747 6.762a9.6 9.6 0 0 0 4.393 2.32c.148-.181.312-.379.477-.592.477.098.987.148 1.497.148 4.853.065 8.851-3.834 8.934-8.67a8.9 8.9 0 0 0-.445-2.83c.181-.46.527-.724.527-.724m-9 11.78a8.2 8.2 0 0 1-4.639-1.481c1.431-1.86 2.27-4.179 2.303-6.696a11.3 11.3 0 0 0-2.32-7.009 8.3 8.3 0 0 1 4.887-1.513c4.59.066 8.292 3.866 8.242 8.473-.066 4.59-3.866 8.291-8.472 8.225"
    />
    <path
      
      d="M48.236 21.011c.197-.477.51-.724.51-.724-.296-1.086-1.185-2.254-2.007-3.076a9.54 9.54 0 0 0-2.583 6.367 9.43 9.43 0 0 0 2.682 6.728v-1.151c1.233-1.662 1.776-3.126 1.826-5.314 0-.987-.132-1.942-.445-2.83m-1.464 7.42c-.954-1.366-1.48-3.044-1.464-4.837a8.25 8.25 0 0 1 1.448-4.574 8.28 8.28 0 0 1 1.48 4.82c-.032 1.712-.559 3.291-1.464 4.59M29.58 27.444a11.1 11.1 0 0 1-.642-3.85 10.96 10.96 0 0 1 2.352-6.614l1.366-.032c-1.793-1.662-3.932-2.633-6.45-2.666a9.4 9.4 0 0 0-6.597 2.583c1.794 1.909 2.814 4.31 2.78 7.042-.032 2.533-1.052 4.968-2.78 6.794 1.201 1.136 2.764 1.942 4.393 2.304.148-.198.296-.395.477-.609.477.099.987.148 1.497.148 1.777.033 4.097-.576 5.446-1.81h1.267a9.7 9.7 0 0 1-2.057-3.126 5.6 5.6 0 0 1-1.036-.18m-3.62 4.655a8.1 8.1 0 0 1-4.606-1.464 11.28 11.28 0 0 0 2.336-6.712c.033-2.649-.856-5.084-2.336-7.042a8.37 8.37 0 0 1 4.837-1.48 8.43 8.43 0 0 1 4.656 1.497 11.27 11.27 0 0 0-2.304 6.696 11.26 11.26 0 0 0 2.303 6.992c-1.02.674-2.763 1.546-4.886 1.513"
    />
  </svg>
);
export default IconAudi;
