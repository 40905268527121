import * as React from "react";
const IconGt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 513 342"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 0h513v342H0z" />
    <path  d="M0 0h171v342H0zm342 0h171v342H342z" />
    <circle cx={256} cy={155.8} r={25.5}  />
    <path
      
      d="M194.2 155.9c0 22.1 11.8 42.5 30.8 53.5 5.9 3.4 13.5 1.4 16.9-4.5s1.4-13.5-4.5-16.9c-11.5-6.6-18.5-18.9-18.5-32.1 0-6.8-5.5-12.4-12.4-12.4s-12.3 5.5-12.3 12.4m95.1 52.1c17.8-11.4 28.6-31 28.5-52.1 0-6.8-5.5-12.4-12.4-12.4-6.8 0-12.4 5.5-12.4 12.4 0 12.7-6.5 24.5-17.1 31.3-5.8 3.6-7.7 11.2-4.1 17s11.2 7.7 17 4.1c.2-.1.3-.2.5-.3"
    />
  </svg>
);
export default IconGt;
