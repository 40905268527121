import * as React from "react";
const IconBug = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.7 38.5h-9c-.1-2.4-.5-4.7-1.1-6.9l7.2-6.3c.9-.8 1-2.2.2-3.2-.8-.9-2.2-1-3.2-.2l-6 5.3c-1.4-2.7-3.2-4.9-5.3-6.6.9-.4 1.7-.9 2.4-1.7 1.3-1.4 1.9-3.3 1.7-5.2-.6-6.8-6-11.9-12.7-11.9-6.6 0-12.2 5.1-12.9 11.9-.2 1.9.4 3.8 1.7 5.2.7.8 1.5 1.3 2.4 1.7-2.1 1.6-3.8 3.8-5.2 6.4l-5.8-5.1c-.9-.8-2.4-.7-3.2.2s-.7 2.4.2 3.2l7 6.1c-.7 2.2-1.1 4.6-1.2 7.1H6.3c-1.2 0-2.3 1-2.3 2.3S5 43 6.3 43h8.9c.2 2.1.7 4 1.3 5.9l-7.2 6.4c-.9.8-1 2.2-.2 3.2.4.5 1.1.8 1.7.8.5 0 1.1-.2 1.5-.6l6.1-5.4c3.1 5.5 8 9 13.6 9s10.6-3.6 13.6-9.2l6.3 5.6c.4.4 1 .6 1.5.6.6 0 1.2-.3 1.7-.8.8-.9.7-2.4-.2-3.2l-7.4-6.5q.9-2.7 1.2-5.7h9.1c1.2 0 2.3-1 2.3-2.3s-1.1-2.3-2.4-2.3M23.5 14.1c.5-4.5 4.1-7.9 8.4-7.9s7.8 3.4 8.3 7.9c.1.7-.1 1.3-.5 1.7-.5.5-1.1.8-1.6.8H25.7c-.6 0-1.2-.3-1.6-.7q-.6-.75-.6-1.8m8.4 43.7c-6.8 0-12.4-8-12.4-17.9S25.1 22 31.9 22s12.4 8 12.4 17.9c0 9.8-5.6 17.9-12.4 17.9" />
  </svg>
);
export default IconBug;
