import * as React from "react";
const IconBankAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.5 17.4-27-14.8c-1.6-.9-3.4-.9-5 0l-27 14.8c-.4.2-.6.7-.5 1.1s.5.8 1 .8h4v30.4c-1.6.6-2.7 2-2.7 3.8v4.7c0 2.2 1.8 4 4 4h47.5c2.2 0 4-1.8 4-4v-4.7c0-1.7-1.1-3.2-2.7-3.8V19.2H61c.5 0 .9-.3 1-.8.1-.3-.1-.8-.5-1m-44.8 1.8v30.2H9V19.2zm2 30.2V19.2h25.9v30.2zm39.1 4v4.7c0 1.1-.9 2-2 2H8.3c-1.1 0-2-.9-2-2v-4.7c0-1.1.9-2 2-2h47.5c1.1 0 2 .9 2 2m-2.7-4h-8.4V19.2h8.4zM6.9 17.2l23.6-13c1-.5 2.1-.5 3.1 0l23.6 13z" />
    <path d="M37.9 42.7c-2.7 0-5.2-1.4-6.8-3.4h3.8c.6 0 1-.4 1-1s-.4-1-1-1H30c-.4-1-.6-2-.6-3.1 0-.8.1-1.5.3-2.2h5.2c.6 0 1-.4 1-1s-.4-1-1-1h-4.3c1.5-2.5 4.3-4.3 7.4-4.3.6 0 1-.4 1-1s-.4-1-1-1c-4.2 0-8 2.6-9.6 6.3h-2.1c-.6 0-1 .4-1 1s.4 1 1 1h1.4c-.2.7-.3 1.5-.3 2.2 0 1.1.2 2.1.5 3.1h-1.7c-.6 0-1 .4-1 1s.4 1 1 1h2.6c1.8 3.2 5.3 5.4 9.2 5.4.6 0 1-.4 1-1s-.6-1-1.1-1" />
  </svg>
);
export default IconBankAlt1;
