// Categories.js
import React from 'react';
import { BringzzText, BringzzIcon } from "@bringzz/components";
import { categories } from './constants';
import { useNavigation } from 'context/NavigationContext';
import classNames from 'classnames';

const Categories = ({ setSearchData, callBack }) => {
    const { navigate } = useNavigation();

    return (
        <div className='py-2 px-6 mx-2 bg-white rounded-xl'>
            {categories.map((category, index) => (
                <div key={index} className={classNames(
                    'flex items-center h-12 justify-between cursor-pointer',
                    index !== categories.length - 1 && 'border-b'
                )}
                    onClick={() => {
                        setSearchData({
                            method: "category",
                            value: category.shortName
                        })
                        callBack && callBack()
                    }}
                >
                    <div className='flex space-x-3 items-center'>
                        {category.icon}
                        <BringzzText tag='h3' className='font-normal'>{category.shortName}</BringzzText>
                    </div>
                    <BringzzIcon size="16" icon="IconChevronRight" folder="LineIcons" />
                </div>
            ))}
        </div>
    );
};

export default Categories;