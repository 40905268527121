import * as React from "react";
const IconUnPavedRoad = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      d="M40.5 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3M21.113 14.172a33.92 33.92 0 0 0-10.542 13.85A34 34 0 0 0 8 41a1 1 0 0 1-2 0c0-4.715.925-9.384 2.721-13.74a35.9 35.9 0 0 1 11.68-15.06q.106-.08.222-.127a1.502 1.502 0 0 1 2.402-.162 1.5 1.5 0 0 1-1.912 2.26M24.373 23.43l.723-.69-1.381-1.446-.724.69a29 29 0 0 0-1.676 1.741l-.663.75 1.497 1.325.663-.748a27 27 0 0 1 1.56-1.621M20.108 28.654l.535-.845-1.69-1.07-.534.845a31.6 31.6 0 0 0-2.258 4.255l-.4.916 1.832.801.4-.916a30 30 0 0 1 2.115-3.986M16.495 36.902l.262-.965-1.93-.524-.262.965q-.315 1.157-.546 2.343l-.192.982 1.963.383.192-.981q.217-1.117.513-2.203M31.529 27.849q.088-.056.162-.125a1.5 1.5 0 1 0-1.178-1.597l-.042.024a18.7 18.7 0 0 0-2.916 2.24 17.8 17.8 0 0 0-4.108 5.776A16.9 16.9 0 0 0 22 41a1 1 0 0 0 2 0c0-2.066.433-4.113 1.277-6.026a15.8 15.8 0 0 1 3.647-5.124 16.7 16.7 0 0 1 2.605-2.001M42 12.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0M40.5 19a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3M41.5 21.35a1 1 0 1 1-2 0 1 1 0 0 1 2 0M35.412 9.618a1.5 1.5 0 1 0-.42-2.97 1.5 1.5 0 0 0 .42 2.97M37.388 12.873a1.5 1.5 0 1 1-2.97.42 1.5 1.5 0 0 1 2.97-.42M36.814 19.519a1.5 1.5 0 1 0-.42-2.97 1.5 1.5 0 0 0 .42 2.97M38.79 22.774a1.5 1.5 0 1 1-2.97.42 1.5 1.5 0 0 1 2.97-.42M29.292 11.614a1.5 1.5 0 1 0-1.23-2.737 1.5 1.5 0 0 0 1.23 2.737M32.095 14.191a1.5 1.5 0 1 1-2.737 1.23 1.5 1.5 0 0 1 2.737-1.23M33.39 20.735a1.5 1.5 0 1 0-1.229-2.737 1.5 1.5 0 0 0 1.23 2.737M35.077 22.266a1 1 0 1 1-1.825.82 1 1 0 0 1 1.825-.82M26.649 16.682a1.5 1.5 0 1 1-2.28 1.95 1.5 1.5 0 0 1 2.28-1.95M29.733 22.597a1.5 1.5 0 1 0-1.95-2.28 1.5 1.5 0 0 0 1.95 2.28M26.694 14.56a.868.868 0 1 0 0-1.737.868.868 0 0 0 0 1.736M30.155 18.034a.868.868 0 1 1-1.736 0 .868.868 0 0 1 1.736 0M33.71 16.79a.868.868 0 1 0 0-1.737.868.868 0 0 0 0 1.736M39.334 15.132a.868.868 0 1 1-1.736 0 .868.868 0 0 1 1.736 0M32.71 12.229a.868.868 0 1 0 0-1.737.868.868 0 0 0 0 1.737M39 10.132a.868.868 0 1 1-1.736 0 .868.868 0 0 1 1.736 0"
    />
  </svg>
);
export default IconUnPavedRoad;
