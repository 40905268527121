import * as React from "react";
const IconSpreadsheets = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M14 19a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H15a1 1 0 0 1-1-1zm2 6v3h4v-3zm0 8v-3h4v3zm6 0v-3h4v3zm6 0v-3h4v3zm0-8v3h4v-3zm-6 3h4v-3h-4zm-6-5h16v-3H16z"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M10 5a1 1 0 0 1 1-1h20a1 1 0 0 1 .707.293l6 6A1 1 0 0 1 38 11v32a1 1 0 0 1-1 1H11a1 1 0 0 1-1-1zm2 1v36h24V12h-5a1 1 0 0 1-1-1V6zm20 1.414V10h2.586z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconSpreadsheets;
