import * as React from "react";
const IconHat4 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M12.7 62c-3.6 0-6.2-.8-7-2.8-2-4.9 8.4-11.4 12.7-13.8-1.5-6.8-3.2-19.4 2.8-28C24.9 12 31.1 9 39.5 8.6c-.1-2.6 1.4-5.1 3.9-6.1 3.2-1.3 7 .2 8.3 3.5.6 1.6.6 3.3 0 4.9-.7 1.6-1.9 2.8-3.5 3.4-3.2 1.3-7-.2-8.3-3.5 0 0 0-.1-.1-.1q-5.55 1.5-7.2 5.7c-2.4 6.2 1.8 15.6 4.7 21 9-2.7 19-3.9 20.8.4 2.1 5.2-10.2 13.5-23 18.8-7.7 3.1-16.4 5.4-22.4 5.4m6.2-14.6c-8.4 4.8-12.1 9.2-11.3 11 1.1 2.6 11.8 2.4 26.9-3.8S57.4 41 56.4 38.5c-.8-2-7.3-2.4-17.9.7.9 1.5 1.5 2.5 1.6 2.5.3.5.2 1.1-.3 1.4s-1.1.2-1.4-.3c-.5-.7-11.4-16.9-7.5-27.1.7-1.7 1.7-3.1 3.1-4.3q-7.35 1.8-11.1 7.2c-7.8 11.2-1.3 30.7-1.2 30.9.2.5-.1 1.1-.6 1.3s-1.1-.1-1.3-.6c-.2-.3-.5-1.2-.9-2.8M44.2 4.3c-2.2.9-3.3 3.5-2.4 5.7s3.5 3.3 5.7 2.4c1.1-.4 1.9-1.3 2.4-2.3.5-1.1.5-2.3 0-3.3-.9-2.3-3.4-3.4-5.7-2.5" />
  </svg>
);
export default IconHat4;
