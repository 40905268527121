import * as React from "react";
const IconSkirt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62 50.1 52.1 18V6c0-2.2-1.8-4-4-4H15.9c-2.2 0-4 1.8-4 4v12L2 50c-.1.4 0 .8.3 1.1l5.3 4.6c2 1.7 4.5 2.7 7.2 2.7h5c1.3 0 2.6.3 3.7.8l3.8 1.7c1.5.7 3 1 4.6 1s3.1-.3 4.6-1l3.8-1.7c1.2-.5 2.5-.8 3.7-.8h5.1c2.6 0 5.1-.9 7.1-2.6l5.3-4.5c.5-.4.6-.8.5-1.2m-46.1-46H48c1.1 0 2 .9 2 2v11.1H13.9V6.1c0-1.1.9-2 2-2M55 54.2c-1.6 1.4-3.7 2.2-5.8 2.2h-5.1c-1.6 0-3.1.4-4.6 1l-3.8 1.7c-2.4 1.1-5.1 1.1-7.5 0l-3.8-1.7c-1.4-.7-3-1-4.6-1h-5c-2.2 0-4.2-.8-5.9-2.2L4.1 50l9.5-30.8h36.6l9.5 30.9z" />
  </svg>
);
export default IconSkirt;
