import * as React from "react";
const IconClipboardMedical = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M48.5 11.9h-3.9v-.1c0-2-1.6-3.6-3.6-3.6h-2.3C38.4 4.7 35.5 2 32 2s-6.4 2.7-6.7 6.1H23c-2 0-3.6 1.6-3.6 3.6v.1h-3.9c-2.7 0-4.9 2.2-4.9 4.9V57c0 2.7 2.2 4.9 4.9 4.9h33.1c2.7 0 4.9-2.2 4.9-4.9V16.8c0-2.7-2.2-4.9-5-4.9m-27.1-.1c0-.9.7-1.6 1.6-1.6h3.3c.6 0 1-.4 1-1v-.5C27.3 6.1 29.4 4 32 4s4.7 2.1 4.7 4.7v.5c0 .6.4 1 1 1H41c.9 0 1.6.7 1.6 1.6v2.4c0 .9-.7 1.6-1.6 1.6H23c-.9 0-1.6-.7-1.6-1.6zm30.1 45.3c0 1.6-1.3 2.9-2.9 2.9H15.5c-1.6 0-2.9-1.3-2.9-2.9V16.8c0-1.6 1.3-2.9 2.9-2.9h3.9v.4c0 2 1.6 3.6 3.6 3.6h18c2 0 3.6-1.6 3.6-3.6v-.4h3.9c1.6 0 2.9 1.3 2.9 2.9v40.3z" />
    <path d="M36.3 37.6H33v-3.3c0-.6-.4-1-1-1s-1 .4-1 1v3.3h-3.2c-.6 0-1 .4-1 1s.4 1 1 1H31v3.2c0 .6.4 1 1 1s1-.4 1-1v-3.2h3.3c.6 0 1-.4 1-1s-.5-1-1-1" />
    <path d="M32 26c-6.9 0-12.6 5.6-12.6 12.6 0 6.9 5.6 12.6 12.6 12.6s12.6-5.6 12.6-12.6S38.9 26 32 26m0 23.1c-5.8 0-10.6-4.7-10.6-10.6S26.2 28 32 28s10.6 4.7 10.6 10.6S37.8 49.1 32 49.1" />
  </svg>
);
export default IconClipboardMedical;
