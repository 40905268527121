import * as React from "react";
const IconMagnetCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.4 9.6C49.5 4.7 43.2 2 36.4 2h-.1C29.9 2 24 4.4 19.6 8.8l-16 16.1c-2.1 2.1-2.1 5.4 0 7.5l6.1 6.1c2.1 2.1 5.4 2.1 7.5 0l15-14.9c2.4-2.4 6-2.7 8.3-.8 1.2 1 2 2.5 2.1 4.1.1 1.8-.6 3.6-1.9 4.9L25.5 46.9c-2.1 2.1-2.1 5.4 0 7.5l6.1 6.1c1 1 2.4 1.5 3.8 1.5s2.7-.5 3.8-1.5l16.1-16.1c9.2-9.3 8.9-24.9-.9-34.8M37.8 59.1a3.32 3.32 0 0 1-4.7 0L27 53a3.32 3.32 0 0 1 0-4.7l6.2-6.2L44 52.8zm16-16.1-8.4 8.4-10.8-10.8 7.4-7.5c1.7-1.7 2.6-4 2.5-6.4-.1-2.2-1.1-4.2-2.8-5.6-1.4-1.1-3.1-1.7-4.8-1.7-2.2 0-4.5.9-6.2 2.6l-7.3 7.3-10.8-10.6-1.4 1.4L22 30.9l-6.2 6.2a3.32 3.32 0 0 1-4.7 0L5 31a3.32 3.32 0 0 1 0-4.7l16-16.1c4.1-4.1 9.6-6.3 15.4-6.2 6.2 0 12.1 2.5 16.6 7.1 9 9.1 9.4 23.4.8 31.9" />
  </svg>
);
export default IconMagnetCopy;
