import * as React from "react";
const IconSunriseAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 11.3c.6 0 1-.4 1-1V3c0-.6-.4-1-1-1s-1 .4-1 1v7.3c0 .6.4 1 1 1M48.9 18.3c.3 0 .5-.1.7-.3l3.4-3.4c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-3.4 3.4c-.4.4-.4 1 0 1.4.2.2.4.3.7.3M59.1 33.2H46.7c-.5-7.7-6.9-13.8-14.7-13.8s-14.2 6.1-14.7 13.8H4.9c-.6 0-1 .4-1 1s.4 1 1 1H59c.6 0 1-.4 1-1s-.4-1-.9-1M32 21.4c6.7 0 12.2 5.2 12.7 11.8H19.3c.5-6.6 6-11.8 12.7-11.8M14.4 18c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-3.4-3.4c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4zM52 45.9H12c-.6 0-1 .4-1 1s.4 1 1 1h40c.6 0 1-.4 1-1s-.4-1-1-1M44 60H20c-.6 0-1 .4-1 1s.4 1 1 1h24c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconSunriseAlt2;
