import * as React from "react";
const IconPeace = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M24 4C12.954 4 4 12.954 4 24s8.954 20 20 20 20-8.954 20-20S35.046 4 24 4m-1 2.027C13.524 6.547 6 14.394 6 24c0 4.61 1.734 8.817 4.585 12.001L23 23.586zm0 35.946a17.93 17.93 0 0 1-11.001-4.557L23 26.414zm2-35.946v17.559L37.416 36A17.93 17.93 0 0 0 42 24c0-9.606-7.524-17.454-17-17.973m11.001 31.389L25 26.414v15.559a17.93 17.93 0 0 0 11.001-4.557"
      clipRule="evenodd"
    />
  </svg>
);
export default IconPeace;
