import * as React from "react";
const IconWebsiteRank = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M37.9 19.2c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2M45.8 19.2c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2M53.6 19.2c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2" />
    <path d="M57 9.8H7c-2.8 0-5 2.2-5 5v34.4c0 2.8 2.2 5 5 5h50c2.8 0 5-2.2 5-5V14.8c0-2.7-2.2-5-5-5m-50 2h50c1.7 0 3 1.3 3 3V23H4v-8.2c0-1.6 1.3-3 3-3m50 40.4H7c-1.7 0-3-1.3-3-3V25h56v24.2c0 1.6-1.3 3-3 3" />
    <path d="m38.3 36-3.6-.5c-.2 0-.4-.2-.5-.4l-1.6-3.3c-.3-.5-1-.5-1.2 0l-1.6 3.3c-.1.2-.3.3-.5.4l-3.6.5c-.6.1-.8.8-.4 1.2l2.6 2.5c.2.2.2.4.2.6l-.6 3.6c-.1.6.5 1 1 .7l3.2-1.7q.3-.15.6 0l3.2 1.7c.5.3 1.1-.2 1-.7l-.6-3.6c0-.2 0-.4.2-.6l2.6-2.5c.4-.5.2-1.1-.4-1.2" />
  </svg>
);
export default IconWebsiteRank;
