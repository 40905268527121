import * as React from "react";
const IconMoveDown = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M45.4 47.5 33 57.9V4c0-.6-.4-1-1-1s-1 .4-1 1v53.9L18.6 47.5c-.4-.4-1.1-.3-1.4.1s-.3 1.1.1 1.4l14 11.8c.2.2.4.2.6.2s.5-.1.6-.2l14-11.8c.4-.4.5-1 .1-1.4s-.8-.5-1.2-.1M8.7 3.3c-.6 0-1 .4-1 1v30.9c0 .6.4 1 1 1s1-.4 1-1V4.3c0-.6-.4-1-1-1M55.3 3.3c-.6 0-1 .4-1 1v30.9c0 .6.4 1 1 1s1-.4 1-1V4.3c0-.6-.5-1-1-1" />
  </svg>
);
export default IconMoveDown;
