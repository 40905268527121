import * as React from "react";
const IconSpeechBubble9 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.6 25.8c-1.9-3.7-5.7-6.3-9.8-6.8-2.6-8.2-10.2-13.9-18.9-13.9s-16.4 5.7-18.9 14c-4.1.6-7.7 3.2-9.6 6.9-2.1 4.1-1.8 9 .8 13.3 2 3.3 5.7 5.3 9.7 5.3h12v12c0 1.1.6 1.9 1.6 2.3.3.1.5.1.8.1.7 0 1.4-.4 1.9-1l9.7-13.4H50c4 0 7.8-2 9.7-5.3 2.7-4.4 3-9.3.9-13.5m-2.5 12.4c-1.6 2.7-4.7 4.3-8 4.3H39.4c-.3 0-.6.2-.8.4l-10 13.8c-.1.2-.3.2-.4.1-.1 0-.3-.1-.3-.4v-13c0-.6-.4-1-1-1h-13c-3.3 0-6.4-1.7-8-4.3-2.3-3.7-2.5-7.9-.8-11.4 1.7-3.3 5-5.6 8.7-5.9.4 0 .8-.3.9-.7C16.8 12.4 23.8 7 31.8 7s15.1 5.4 17.1 13.1c.1.4.5.7.9.7 3.7.3 7.1 2.5 8.9 5.8 1.9 3.7 1.7 7.8-.6 11.6" />
    <path d="M40.1 22.1H23.9c-.6 0-1 .4-1 1s.4 1 1 1h16.2c.6 0 1-.4 1-1s-.4-1-1-1M40.1 30.9H23.9c-.6 0-1 .4-1 1s.4 1 1 1h16.2c.6 0 1-.4 1-1 0-.5-.4-1-1-1" />
  </svg>
);
export default IconSpeechBubble9;
