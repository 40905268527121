import * as React from "react";
const IconServer14 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62 23.9V10.8c0-2.8-2.2-5-5-5H7c-2.8 0-5 2.2-5 5V24c0 1.6.8 3.1 2 4-1.2.9-2 2.4-2 4v13.2c0 2.8 2.2 5 5 5h35.3c.6 0 1-.4 1-1s-.4-1-1-1H7c-1.7 0-3-1.3-3-3V31.9c0-1.7 1.3-3 3-3h50c1.7 0 3 1.3 3 3v9.6c0 .6.4 1 1 1s1-.4 1-1v-9.6c0-1.6-.8-3.1-2-4 1.2-.9 2-2.3 2-4m-2 0c0 1.7-1.3 3-3 3H7c-1.7 0-3-1.3-3-3V10.8c0-1.7 1.3-3 3-3h50c1.7 0 3 1.3 3 3z" />
    <path d="M15.6 16.1c-.6 0-1 .4-1 1v.2c0 .6.4 1 1 1s1-.4 1-1v-.2c0-.5-.5-1-1-1M50.8 16.2H44c-.6 0-1 .4-1 1s.4 1 1 1h6.7c.6 0 1-.4 1-1s-.4-1-.9-1M14.6 38.3v.2c0 .6.4 1 1 1s1-.4 1-1v-.2c0-.6-.4-1-1-1s-1 .4-1 1M51.8 38.3c0-.6-.4-1-1-1H44c-.6 0-1 .4-1 1s.4 1 1 1h6.7c.6 0 1.1-.5 1.1-1M59.5 47.6h-4c-.1 0-.2 0-.2-.1l-.5-1.2c-.3-.8-1.1-1.4-2-1.4h-4c-1.2 0-2.2 1-2.2 2.2v8.6c0 1.2 1 2.2 2.2 2.2h10.8c1.2 0 2.2-1 2.2-2.2v-5.9c-.1-1.2-1.1-2.2-2.3-2.2m.2 8.1c0 .1-.1.2-.2.2H48.6c-.1 0-.2-.1-.2-.2v-8.6c0-.1.1-.2.2-.2h4c.1 0 .2.1.2.1l.5 1.2c.3.8 1.1 1.4 2 1.4h4c.1 0 .2.1.2.2v5.9z" />
  </svg>
);
export default IconServer14;
