/* eslint-disable no-undef */
const DefaultMenuList = [
	{
		label: 'Home',
		icon: 'IconBringzz',
		folder: 'CustomIcons',
		url: '/home'
	},
	{
		label: 'Search',
		icon: 'IconSearchAlt',
		folder: 'LineIcons',
		url: '/search'
	},
	{
		label: 'Scan',
		icon: 'IconBarcode',
		folder: 'LineIcons',
		url: '/scan'
	},
	{
		label: 'Basket',
		icon: 'IconShoppingBag14',
		folder: 'LineIcons',
		url: '/basket'
	},
	{
		label: 'Profile',
		icon: 'IconUserAlt1',
		folder: 'LineIcons',
		url: '/settings'
	}
];

const PharmacistMenuList = [
	{
		label: 'Home',
		icon: 'IconBringzz',
		folder: 'CustomIcons',
		url: '/home'
	},
	{
		label: 'Search',
		icon: 'IconSearchAlt',
		folder: 'LineIcons',
		url: '/search'
	},
	{
		label: 'Post',
		icon: 'IconCirclePlus',
		folder: 'LineIcons',
		url: '/posts'
	},
	{
		label: 'Basket',
		icon: 'IconShoppingBag14',
		folder: 'LineIcons',
		url: '/basket'
	},
	{
		label: 'Profile',
		icon: 'IconUserAlt1',
		folder: 'LineIcons',
		url: '/settings'
	}
];

const hoursOptions = [
	{ label: '00', value: 0 },
	{ label: '1', value: 1 },
	{ label: '2', value: 2 },
	{ label: '3', value: 3 },
	{ label: '4', value: 4 },
	{ label: '5', value: 5 },
	{ label: '6', value: 6 },
	{ label: '7', value: 7 },
	{ label: '8', value: 8 },
	{ label: '9', value: 9 },
	{ label: '10', value: 10 },
	{ label: '11', value: 11 },
	{ label: '12', value: 12 },
	{ label: '13', value: 13 },
	{ label: '14', value: 14 },
	{ label: '15', value: 15 },
	{ label: '16', value: 16 },
	{ label: '17', value: 17 },
	{ label: '18', value: 18 },
	{ label: '19', value: 19 },
	{ label: '20', value: 20 },
	{ label: '21', value: 21 },
	{ label: '22', value: 22 },
	{ label: '23', value: 23 },
	{ label: '24', value: 24 }
];

const generateMinutesOptions = () => {
	let array = [];
	for (let index = 0; index < 60; index++) {
		array.push({ value: index, label: index.toString().padStart(2, '0') });
	}
	return array;
};
const minutesOptions = generateMinutesOptions();

module.exports = {
	DefaultMenuList, PharmacistMenuList, hoursOptions, minutesOptions
};
