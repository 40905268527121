import * as React from "react";
const IconKeywordResearchCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.1 52.5 47.2 38.7c-1.5-1.5-4.1-1.5-5.7 0l-.8.8-7.2-7.2c6.6-7.2 6.4-18.3-.5-25.2C25.9 0 14.2 0 7.1 7.1S0 25.9 7.1 33c3.6 3.6 8.3 5.4 13 5.4 4.4 0 8.7-1.6 12.2-4.7l7.2 7.2-.8.8c-.8.8-1.2 1.8-1.2 2.8 0 1.1.4 2.1 1.2 2.8l13.9 13.9c.8.8 1.8 1.2 2.8 1.2 1.1 0 2.1-.4 2.8-1.2l2.9-2.9c.8-.8 1.2-1.8 1.2-2.8s-.4-2.2-1.2-3M8.5 31.6c-6.4-6.4-6.4-16.7 0-23.1 3.2-3.2 7.4-4.8 11.5-4.8s8.4 1.6 11.5 4.8c6.4 6.4 6.4 16.7 0 23.1-6.3 6.3-16.6 6.3-23 0m51.2 25.2-2.9 2.9c-.8.8-2.1.8-2.8 0L40.1 45.8c-.4-.4-.6-.9-.6-1.4s.2-1 .6-1.4l2.9-2.9c.4-.4.9-.6 1.4-.6s1 .2 1.4.6L59.7 54c.4.4.6.9.6 1.4s-.2 1-.6 1.4" />
    <path d="M14.8 17.3h5.1c.6 0 1-.4 1-1s-.4-1-1-1h-5.1c-.6 0-1 .4-1 1s.5 1 1 1M25.5 23H14.8c-.6 0-1 .4-1 1s.4 1 1 1h10.7c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconKeywordResearchCopy;
