import * as React from "react";
const IconBracelet = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62 39.3c0-7-11.2-12.6-26.1-13.4 1.6-1.2 2.6-3.1 2.6-5.2 0-3.3-5.2-10.1-5.7-10.9-.4-.5-1.2-.5-1.6 0-.6.8-5.7 7.6-5.7 10.9 0 2.1 1 4 2.6 5.2C13.2 26.7 2 32.3 2 39.3c0 6.4 9.6 11.9 23.5 13.2v.8c0 .6.4 1 1 1s1-.4 1-1v-4.1c0-.6-.4-1-1-1s-1 .4-1 1v1.3C13 49.3 4 44.6 4 39.3 4 33 16.8 27.8 32 27.8s28 5.3 28 11.5c0 5.3-9 10-21.5 11.2v-1c0-.6-.4-1-1-1s-1 .4-1 1v4.1c0 .6.4 1 1 1s1-.4 1-1v-1.1C52.4 51.2 62 45.8 62 39.3M32 12.1c2 2.8 4.5 6.9 4.5 8.6 0 2.5-2 4.5-4.5 4.5s-4.5-2-4.5-4.5c0-1.8 2.5-5.9 4.5-8.6" />
  </svg>
);
export default IconBracelet;
