import * as React from "react";
const IconCactusAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M24.9 29c-.6 0-1 .4-1 1v5.5c0 1.9-1.6 3.5-3.5 3.5h-3.8V15.9c0-.6-.4-1-1-1s-1 .4-1 1V33H7.5C5.6 33 4 31.4 4 29.5V24c0-.6-.4-1-1-1s-1 .4-1 1v5.5c0 3 2.5 5.5 5.5 5.5h7.1v17.5c0 .6.4 1 1 1s1-.4 1-1V41.1h3.8c3.1 0 5.5-2.5 5.5-5.5V30c0-.6-.4-1-1-1M52.7 22.5h3.8c3.1 0 5.5-2.5 5.5-5.5v-5.5c0-.6-.4-1-1-1s-1 .4-1 1V17c0 1.9-1.6 3.5-3.5 3.5h-3.8v-8.9c0-.6-.4-1-1-1s-1 .4-1 1V33h-7.1c-2 0-3.5-1.6-3.5-3.5V24c0-.6-.4-1-1-1s-1 .4-1 1v5.5c0 3.1 2.5 5.5 5.5 5.5h7.1v17.5c0 .6.4 1 1 1s1-.4 1-1V41.1h3.8c3.1 0 5.5-2.5 5.5-5.5V30c0-.6-.4-1-1-1s-1 .4-1 1v5.5c0 1.9-1.6 3.5-3.5 3.5h-3.8z" />
  </svg>
);
export default IconCactusAlt2;
