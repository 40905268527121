import * as React from "react";
const IconRuralPost = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path  d="M39 12h4v2h-4z" />
    <path
      
      fillRule="evenodd"
      d="M6 16h17v2H8v19h21V25h8v12h3V18h-1v-2h3v21h1a1 1 0 1 1 0 2H5a1 1 0 1 1 0-2h1zm25 21h4V27h-4z"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M27 32h-1v-7H11v7h-1v2h17zm-14-5v5h11v-5z"
      clipRule="evenodd"
    />
    <path  d="M5 12v2h18v-2zM34 15h-2v-2h-2v2h-2v2h2v2h2v-2h2z" />
    <path
      
      fillRule="evenodd"
      d="M37 12a2 2 0 0 0-2-2h-8a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zm-10 0h8v8h-8z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconRuralPost;
