import * as React from "react";
const IconShoppingWedding = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M40 24.4c-1.3-.9-2.6-1.2-4-.9-1.8.4-3.1 1.8-3.9 2.9-.8-1.1-2.2-2.5-3.9-2.9-1.4-.3-2.7 0-4 .9-3.9 2.7-2.7 7.9-1.5 10.3.1.3 3.2 6.6 9.3 8.5h.6c6-1.9 9-8.3 9.1-8.5.9-2.4 2.1-7.6-1.7-10.3m-.3 9.4c0 .1-2.6 5.6-7.6 7.3-5-1.8-7.7-7.3-7.8-7.4 0-.1-2.6-5.3.9-7.7q.9-.6 1.8-.6c.2 0 .4 0 .6.1 2 .5 3.5 3.2 3.5 3.2.2.3.5.5.9.5s.7-.2.9-.5c.4-.8 1.8-2.8 3.5-3.2q1.2-.3 2.4.6c3.5 2.3 1 7.4.9 7.7" />
    <path d="M50 13.9c-.2-2.4-2.3-4.3-4.7-4.3h-5v-.7c0-3.8-3.1-6.9-6.9-6.9h-2.9c-3.8 0-6.9 3.1-6.9 6.9v.7h-5c-2.4 0-4.5 1.8-4.7 4.3L9.7 56.8c-.1 1.3.3 2.6 1.2 3.6s2.2 1.6 3.5 1.6h35.1c1.3 0 2.6-.6 3.5-1.6s1.3-2.3 1.2-3.6zm-24.3-5c0-2.7 2.2-4.9 4.9-4.9h2.9c2.7 0 4.9 2.2 4.9 4.9v.7H25.7zm25.9 50.2c-.5.6-1.2.9-2 .9H14.4c-.8 0-1.5-.3-2-.9s-.8-1.3-.7-2.1L16 14.1c.1-1.4 1.3-2.5 2.7-2.5h26.7c1.4 0 2.6 1.1 2.7 2.5L52.3 57c0 .8-.2 1.5-.7 2.1" />
  </svg>
);
export default IconShoppingWedding;
