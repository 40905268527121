import * as React from "react";
const IconHumanResoruces = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M39 8H9a1 1 0 0 0-1 1v30a1 1 0 0 0 1 1h30a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1M9 6a3 3 0 0 0-3 3v30a3 3 0 0 0 3 3h30a3 3 0 0 0 3-3V9a3 3 0 0 0-3-3z"
      clipRule="evenodd"
    />
    <path  d="M19 20a3 3 0 1 1-6 0 3 3 0 1 1 6 0" />
    <path
      
      fillRule="evenodd"
      d="M12.256 27.24c-.21.211-.256.369-.256.487V31h-2v-3.273c0-.788.37-1.425.837-1.896.459-.461 1.05-.807 1.637-1.064C13.642 24.257 15.028 24 16 24c.971 0 2.358.257 3.526.767.587.257 1.178.603 1.637 1.064.468.471.837 1.108.837 1.896V31h-2v-3.273c0-.118-.047-.276-.256-.486-.218-.22-.564-.442-1.019-.641-.914-.4-2.028-.6-2.725-.6s-1.81.2-2.725.6c-.455.198-.8.42-1.019.64M28.256 27.24c-.21.211-.256.369-.256.487V31h-2v-3.273c0-.788.37-1.425.837-1.896.459-.461 1.05-.807 1.637-1.064C29.642 24.257 31.029 24 32 24s2.358.257 3.526.767c.587.257 1.178.603 1.637 1.064.468.471.837 1.108.837 1.896V31h-2v-3.273c0-.118-.047-.276-.256-.486-.218-.22-.564-.442-1.019-.641-.914-.4-2.028-.6-2.725-.6s-1.81.2-2.725.6c-.455.198-.8.42-1.019.64"
      clipRule="evenodd"
    />
    <path
      
      d="M35 20a3 3 0 1 1-6 0 3 3 0 1 1 6 0M27 18a3 3 0 1 1-6 0 3 3 0 1 1 6 0"
    />
    <path
      
      fillRule="evenodd"
      d="M22.697 24.995c-.283.028-.38.092-.451.171l-1.492-1.332c.525-.588 1.18-.774 1.749-.83.343-.033.769-.021 1.118-.012.143.004.273.008.379.008s.236-.004.379-.008c.349-.01.775-.02 1.118.013.57.055 1.224.241 1.749.829l-1.492 1.332c-.07-.079-.168-.143-.451-.17a5 5 0 0 0-.554-.012l-.202.005c-.17.005-.363.011-.547.011s-.377-.006-.547-.01l-.202-.006a5 5 0 0 0-.554.011"
      clipRule="evenodd"
    />
  </svg>
);
export default IconHumanResoruces;
