import * as React from "react";
const IconPriceTag = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M30.9 39.5h5.3c.6 0 1-.4 1-1s-.4-1-1-1H34v-1.4c0-.6-.4-1-1-1s-1 .4-1 1v1.4h-1c-2.1 0-3.8 1.7-3.8 3.8s1.7 3.8 3.8 3.8h2.4c1 0 1.8.8 1.8 1.8s-.8 1.8-1.8 1.8h-5.3c-.6 0-1 .4-1 1s.4 1 1 1H32v1.4c0 .6.4 1 1 1s1-.4 1-1v-1.5c1.8-.3 3.2-1.9 3.2-3.8 0-2.1-1.7-3.8-3.8-3.8H31c-1 0-1.8-.8-1.8-1.8s.7-1.7 1.7-1.7" />
    <path d="M41.1 16.2H27.4c-.2-.9-.3-2-.3-3 0-5 2.3-9.2 5.1-9.2 1.9 0 3.8 2.2 4.6 5.5.1.5.7.9 1.2.7.5-.1.9-.7.7-1.2-1.1-4.3-3.7-7-6.6-7-4 0-7.1 4.9-7.1 11.2q0 1.5.3 3h-2.5c-3.3 0-6 2.7-6 6V56c0 3.3 2.7 6 6 6h18.3c3.3 0 6-2.7 6-6V22.2c0-3.3-2.7-6-6-6m4 39.8c0 2.2-1.8 4-4 4H22.9c-2.2 0-4-1.8-4-4V22.2c0-2.2 1.8-4 4-4h2.9c1.1 3.7 3.5 6.2 6.4 6.2.6 0 1-.4 1-1s-.4-1-1-1c-1.8 0-3.3-1.7-4.2-4.2h13.2c2.2 0 4 1.8 4 4V56z" />
  </svg>
);
export default IconPriceTag;
