import * as React from "react";
const IconBubbleAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.2 55.2q-1.2 0-2.4-.6l-11.9-6.8c-.4-.2-.9-.4-1.4-.4H6.8c-2.6 0-4.8-2.1-4.8-4.8v-29C2 11 4.2 8.8 6.8 8.8h50.4c2.6 0 4.8 2.1 4.8 4.8v36.9c0 1.7-.9 3.3-2.4 4.1-.7.4-1.6.6-2.4.6M6.8 10.8C5.3 10.8 4 12 4 13.6v29.1c0 1.5 1.3 2.8 2.8 2.8h34.7c.8 0 1.7.2 2.4.6l11.9 6.8c.9.5 1.9.5 2.8 0s1.4-1.4 1.4-2.4V13.6c0-1.5-1.3-2.8-2.8-2.8z" />
  </svg>
);
export default IconBubbleAlt;
