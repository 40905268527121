import * as React from "react";
const IconBridgeAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 39.6h-7.3V10.9c0-.6-.4-1-1-1s-1 .4-1 1v4.9C45.9 16.5 39.1 17 32 17s-13.9-.4-19.7-1.2v-4.9c0-.6-.4-1-1-1s-1 .4-1 1v28.8H3c-.6 0-1 .4-1 1s.4 1 1 1h7.3v10.5H8.8c-.6 0-1 .4-1 1s.4 1 1 1h4.9c.6 0 1-.4 1-1s-.4-1-1-1h-1.5V41.6h39.4v10.5h-1.5c-.6 0-1 .4-1 1s.4 1 1 1H55c.6 0 1-.4 1-1s-.4-1-1-1h-1.5V41.6H61c.6 0 1-.4 1-1s-.4-1-1-1m-30 0h-7.7V18.8c2.5.1 5.1.2 7.7.2zM33 19c2.6 0 5.2-.1 7.7-.2v20.9H33zm-20.7-1.2c2.8.4 5.9.7 9 .9v21h-9zm30.4 21.8v-21q4.8-.3 9-.9v21.9z" />
  </svg>
);
export default IconBridgeAlt2;
