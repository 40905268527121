import * as React from "react";
const IconSignature = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 19.9c-2-2-4-4.2-6-6.1-.3-.4-.8-.6-1.3-.6s-.9.2-1.2.5L29.7 36.4c-.3.3-.5.7-.7 1.1l-3.4 10.1v.1c-1.3 1-2.5 1.3-3.6.8-.4-.2-.8-.4-1.2-.7s-.8-.6-1.2-.8c-1.1-.6-2.6-.8-3.8 0-.2.1-.3.3-.5.4l-.2.2c-.3.2-.6.3-1 .3s-.8-.2-1-.5c-.3-.4-.5-1-.6-1.6-.1-.3-.2-.7-.3-1-.6-1.9-2-3.4-3.9-4.2-1.1-.5-2.2-.5-3-.1s-1.3 1.2-1.7 2c-.4.7-.7 1.4-1 2.2-.2.5 0 1.1.5 1.3s1.1 0 1.3-.5c.3-.7.6-1.4.9-2 .2-.4.5-.9.9-1.1.3-.2.8-.1 1.4.1 1.3.5 2.3 1.6 2.7 2.9.1.3.2.5.2.8.2.8.4 1.7 1 2.4q.9 1.05 2.4 1.2c.9.1 1.9-.2 2.5-.8l.3-.3c.1-.1.2-.2.3-.2.4-.3 1.1-.2 1.7.1.3.2.6.4 1 .6.5.3 1 .7 1.6 1 .5.2 1.2.4 1.9.4 1.2 0 2.6-.4 4.1-1.8l9.9-3.3c.4-.1.8-.4 1.1-.7L61 22.3c.3-.3.5-.7.5-1.2 0-.4-.2-.8-.5-1.2M36.9 43.5c-.1.1-.2.2-.3.2l-8.5 2.8 2.8-8.5c0-.1.1-.2.2-.3L48 21l5.7 5.7zm18.3-18.2-5.7-5.7 4.2-4.2c1.9 1.9 3.8 3.8 5.7 5.8z" />
  </svg>
);
export default IconSignature;
