import * as React from "react";
const IconBallonAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M52.3 22.3c0-.4 0-.8-.1-1.2v-.3C51.5 10.3 42.7 2 32 2c-11.2 0-20.3 9.1-20.3 20.3C11.7 44.4 26 50.6 30.5 52l-8.3 8.3c-.3.3-.4.7-.2 1.1s.5.6.9.6h18.2c.4 0 .8-.2.9-.6.2-.4.1-.8-.2-1.1L33.5 52c4.5-1.4 18.8-7.6 18.8-29.7M15.1 33.4l35.2-11c0 4.2-.5 7.8-1.4 10.9l-29.3 9c-1.8-2.4-3.4-5.3-4.5-8.9m-1.4-11h.2l32.9-11c1.9 2.5 3.1 5.6 3.5 8.8L14.6 31.4q-.9-3.9-.9-9M32 4c5.3 0 10 2.2 13.3 5.8L13.8 20.3C14.8 11.2 22.6 4 32 4m-6.7 56 6.7-6.7 6.7 6.7zm6.7-9.7c-1.1-.2-6.3-1.6-10.9-6.4l27-8.3C43.6 47.4 33.6 50 32 50.3" />
  </svg>
);
export default IconBallonAlt1;
