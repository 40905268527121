import * as React from "react";
const IconRocketIdea = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M48.8 3c0-.5-.4-.9-.9-.9-5.1-.3-11.6.6-20.4 9.4l-7.9 7.9h-8.9c-.3 0-.5.1-.7.3L4.5 25c-.2.2-.3.4-.3.7s.1.5.3.7l6 6q-1.05.45-1.8 1.2c-2.1 2.1-3.3 9.9-3.4 10.8 0 .3.1.6.3.8s.4.3.7.3h.1c.9-.1 8.7-1.3 10.8-3.4q.75-.75 1.2-1.8l6 6c.2.2.5.3.7.3.3 0 .5-.1.7-.3l1-1c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-.3.3L6.6 25.7l4.4-4.4h8.9c.3 0 .5-.1.7-.3l8.2-8.2c7.8-7.8 13.3-9 18-8.9.1 4.7-1.1 10.2-8.9 18l-8.2 8.2c-.2.2-.3.4-.3.7v.9c0 .6.4 1 1 1s1-.4 1-1v-.5l7.9-7.9c8.9-8.8 9.8-15.2 9.5-20.3m-33 37.7c-1.1 1.1-5.2 2.1-8.3 2.6.5-3 1.5-7.2 2.6-8.3.5-.5 1.2-.9 2-1.1l4.8 4.8c-.2.8-.6 1.5-1.1 2M56.3 33.1c-2.3-2.5-5.5-3.9-8.8-4-3.4-.1-6.7 1.2-9.1 3.6-2.5 2.4-3.8 5.6-3.8 9 0 4.4 2.2 8.4 5.9 10.7.8.5 1.3 1.4 1.3 2.4V57c0 2.7 2.2 4.8 4.8 4.8h1.2c2.7 0 4.8-2.2 4.8-4.8v-2.3c0-1 .5-1.9 1.3-2.4 3.6-2.3 5.8-6.1 5.9-10.4.1-3.1-1.2-6.3-3.5-8.8m-5.7 24.1c0 1.6-1.3 2.8-2.8 2.8h-1.2c-1.6 0-2.8-1.3-2.8-2.8V55c0-.3 0-.6-.1-.8h7c0 .3-.1.5-.1.8zm2.2-6.4c-.5.3-1 .8-1.3 1.3h-8.6c-.4-.5-.8-1-1.3-1.3-3.1-2-5-5.3-5-9 0-2.9 1.1-5.6 3.2-7.6 2-2 4.6-3 7.4-3h.3c2.8.1 5.4 1.3 7.4 3.3 2 2.1 3 4.8 3 7.6-.2 3.6-2.1 6.8-5.1 8.7" />
  </svg>
);
export default IconRocketIdea;
