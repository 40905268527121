import * as React from "react";
const IconHeadIdea = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M34.5 2c-12 0-21.8 9.8-21.8 21.8V28c0 .2 0 .3-.1.5L7.8 38c-.2.3-.1.7 0 1s.5.5.9.5h2.9c.6 0 1.1.5 1.1 1.1v12.1c0 .3.1.6.4.8.2.2.5.3.8.2l10-1.7c.3-.1.6 0 .9.2.2.2.4.5.4.8v8c0 .3.1.6.4.8.2.2.4.2.6.2h.2l20.8-3.5c.5-.1.8-.5.8-1V46.4c0-3 1.2-6 3.4-8.7 3.2-3.9 5-8.8 5-13.9C56.3 11.8 46.5 2 34.5 2m15.3 34.5c-2.5 3-3.8 6.5-3.8 10v10.2l-18.8 3.1V53c0-.9-.4-1.8-1.1-2.3-.6-.5-1.3-.7-2-.7h-.5l-8.9 1.5v-11c0-1.7-1.4-3.1-3.1-3.1h-1.3l4-8c.2-.4.3-.9.3-1.4v-4.2C14.6 12.9 23.5 4 34.5 4s19.8 8.9 19.8 19.8c0 4.7-1.6 9.1-4.5 12.7" />
    <path d="M30.3 25.1h7.4c.6 0 1-.4 1-1s-.4-1-1-1h-3.6v-2.4c0-.6-.4-1-1-1s-1 .4-1 1v2.4h-1.8c-2.7 0-5 2.2-5 5s2.2 5 5 5h3.4c1.6 0 3 1.3 3 3s-1.3 3-3 3h-7.4c-.6 0-1 .4-1 1s.4 1 1 1h5.8v2.4c0 .6.4 1 1 1s1-.4 1-1v-2.4c2.5-.2 4.6-2.3 4.6-4.9 0-2.7-2.2-5-5-5h-3.4c-1.6 0-3-1.3-3-3 0-1.8 1.3-3.1 3-3.1" />
  </svg>
);
export default IconHeadIdea;
