import * as React from "react";
const IconLayersAlt3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 65 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.5 3h-36c-1.7 0-3 1.3-3 3v13h-3c-1.7 0-3 1.3-3 3v13h-3c-1.7 0-3 1.3-3 3v20c0 1.7 1.3 3 3 3h36c1.7 0 3-1.3 3-3V45h3c1.7 0 3-1.3 3-3V29h3c1.7 0 3-1.3 3-3V6c0-1.7-1.3-3-3-3m-11 55c0 .6-.4 1-1 1h-36c-.6 0-1-.4-1-1V38c0-.6.4-1 1-1h36c.6 0 1 .4 1 1zm6-16c0 .6-.4 1-1 1h-3v-5c0-1.7-1.3-3-3-3h-31V22c0-.6.4-1 1-1h36c.6 0 1 .4 1 1zm6-16c0 .6-.4 1-1 1h-3v-5c0-1.7-1.3-3-3-3h-31V6c0-.6.4-1 1-1h36c.6 0 1 .4 1 1z" />
  </svg>
);
export default IconLayersAlt3;
