import * as React from "react";
const IconBinocular = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.7 34.8c-.1-1.6-1.4-2.8-3-2.8V15.4c0-.8-.3-2.5-2.6-2.9V7.9c0-2.4-3.4-3.3-6.4-3.3s-6.4.9-6.4 3.3v4.6c-2.4.4-2.6 2.1-2.6 2.9v2.2H26.4v-2.2c0-.8-.3-2.5-2.6-2.9V7.9c0-2.4-3.4-3.3-6.4-3.3S11 5.4 11 7.9v4.6c-2.3.3-2.7 1.9-2.7 2.9v16.5c-1.5 0-2.8 1.3-2.9 2.8L4 56.2c-.1.9.2 1.7.8 2.3s1.3.9 2.2.9h20.7c.8 0 1.6-.3 2.2-.9s.9-1.4.8-2.3l-1.3-21.5c-.1-1.5-1.4-2.8-2.9-2.8v-5.3h11.3v5.3c-1.5 0-2.8 1.3-2.9 2.8l-1.4 21.5c-.1.8.2 1.6.8 2.2s1.4 1 2.2 1H57c.8 0 1.6-.3 2.2-.9s.9-1.4.8-2.2zm-12-28.2c2.7 0 4.4.8 4.4 1.3v4.6h-8.8V7.9c0-.6 1.7-1.3 4.4-1.3m-7.1 8.8c0-.4 0-1 1.6-1H52c1.6 0 1.6.6 1.6 1v16.5h-14zM17.3 6.6c2.7 0 4.4.8 4.4 1.3v4.6H13V7.9c0-.6 1.7-1.3 4.3-1.3M12 14.4h10.8c1.6 0 1.6.6 1.6 1v16.5H10.3V15.4c0-.4 0-1 1.7-1m15.3 20.5 1.3 21.5c0 .3-.1.6-.3.8s-.4.3-.7.3H7c-.3 0-.5-.1-.7-.3-.2-.3-.3-.5-.3-.8l1.3-21.5c0-.5.5-1 1-1h18c.5 0 1 .5 1 1m-.9-10.3v-5h11.3v5zm31.3 32.5c-.2.2-.4.3-.7.3H36.4c-.3 0-.6-.1-.8-.3s-.3-.5-.3-.7l1.4-21.5c0-.5.5-1 1-1h17.9c.5 0 1 .4 1.1 1L58 56.4c0 .2-.1.5-.3.7" />
  </svg>
);
export default IconBinocular;
