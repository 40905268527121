import React, { useState } from 'react';
import BringzzTab from './BringzzTab'
import BringzzTabList from './BringzzTabList'
import BringzzTabPanel from './BringzzTabPanel'
import PropTypes from 'prop-types';

const BringzzTabs = ({ defaultActiveTab, children }) => {
    const [activeTab, setActiveTab] = useState(defaultActiveTab);

    const activeBringzzTabPanel = React.Children.toArray(children).find((child) =>
        child.type === BringzzTabPanel && child.props.id === activeTab
    );

    return (
        <div className="w-full">
            <BringzzTabList activeTab={activeTab} onTabChange={setActiveTab}>
                {children.map((child) => {
                    return child.type === BringzzTab ? child : null
                })}
            </BringzzTabList>
            {activeBringzzTabPanel}
        </div>
    );
};

BringzzTabs.propTypes = {
    defaultActiveTab: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
};

export default BringzzTabs;
