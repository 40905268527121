import * as React from "react";
const IconTt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 85.333 512 341.333"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 85.337h512v341.326H0z" />
    <path
      
      d="m6.066 85.337 207.961 212.636 131.584 128.69h160.323L297.973 214.027 166.389 85.337z"
    />
    <path d="M43.364 85.337 384.69 426.663h83.946L127.31 85.337z" />
  </svg>
);
export default IconTt;
