import * as React from "react";
const IconPhoneCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m60.4 45.2-9.1-5.8c-1.5-1-3.6-.6-4.6.8l-2.6 3.5c-.4.6-1.3.7-2 .3C29.7 36.7 22 26.4 19.2 22.3c-.2-.3-.2-.6-.2-.9.1-.3.3-.6.5-.8l3.7-2.5c.7-.5 1.2-1.2 1.4-2.1s0-1.7-.5-2.5L18 4.8c-1-1.4-3-1.9-4.6-1L5.1 8.6c-.4.2-.6.4-.8.6-6 6.3.4 21.9 14.6 35.3 10.6 10 22.3 16 30.4 16 2.7 0 5-.7 6.7-2.1.2-.2.5-.5.7-.8l5-8c.8-1.5.3-3.4-1.3-4.4m-.6 3.4-5 8-.3.3c-5.8 4.9-21.2-1.3-34.4-13.8C7.1 30.6.6 16 5.7 10.6c.1-.1.2-.2.3-.2l8.4-4.7c.2-.1.5-.2.7-.2q.75 0 1.2.6l6.1 8.7c.2.3.3.6.2.9s-.3.6-.5.8L18.4 19c-.7.5-1.2 1.2-1.4 2.1-.2.8 0 1.7.5 2.4 2.8 4.2 10.8 14.8 23.5 22.4 1.5.9 3.6.5 4.6-.9l2.6-3.5c.4-.6 1.3-.7 2-.3l9.1 5.8c.7.3.9 1 .5 1.6" />
  </svg>
);
export default IconPhoneCopy;
