import * as React from "react";
const IconChatRemove = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53 10.6c-6.3-6.3-15.1-9.4-24-8.4C15.1 3.6 4.1 14.7 2.7 28.5c-.7 6.6.8 13 4.2 18.6L2.4 58.5c-.4 1-.1 2.1.7 2.8.5.5 1.1.7 1.7.7.4 0 .8-.1 1.1-.3l10.2-5.1c5.6 3.6 12.1 5.1 18.8 4.5 14-1.3 25.2-12.4 26.6-26.4 1-9-2.1-17.7-8.5-24.1m6.6 23.9c-1.3 13.1-11.7 23.4-24.8 24.7-6.4.6-12.7-1-18-4.5-.3-.2-.7-.2-1-.1L5 59.9c-.3.2-.5 0-.6-.1s-.3-.3-.1-.6l4.6-11.9c.1-.3.1-.6-.1-.9C5.4 41.1 4 35 4.6 28.7 6 15.8 16.3 5.5 29.2 4.2c1-.1 2-.2 2.9-.2 7.3 0 14.3 2.9 19.5 8.1 5.9 5.8 8.8 14 8 22.4" />
    <path d="M39.7 31H24.3c-.6 0-1 .4-1 1s.4 1 1 1h15.4c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconChatRemove;
