import * as React from "react";
const IconMoshjidLocation = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 32.2c.3 0 .5-.1.7-.3l2.8-2.8h2.3c.6 0 1-.4 1-1v-2.3l2.8-2.8c.2-.2.3-.4.3-.7s-.1-.5-.3-.7l-2.8-2.8v-2.3c0-.6-.4-1-1-1h-2.3l-2.8-2.8c-.4-.4-1-.4-1.4 0l-2.8 2.8h-2.3c-.6 0-1 .4-1 1v2.3l-2.8 2.8c-.4.4-.4 1 0 1.4l2.8 2.8v2.3c0 .6.4 1 1 1h2.3l2.8 2.8c.2.2.4.3.7.3m-3.1-5.1h-1.7v-1.7c0-.3-.1-.5-.3-.7l-2.4-2.4 2.4-2.4c.2-.2.3-.4.3-.7v-1.7h1.7c.3 0 .5-.1.7-.3l2.4-2.4 2.4 2.4c.2.2.4.3.7.3h1.7v1.7c0 .3.1.5.3.7l2.4 2.4-2.4 2.4c-.2.2-.3.4-.3.7v1.7h-1.7c-.3 0-.5.1-.7.3L32 29.8l-2.4-2.4c-.1-.2-.4-.3-.7-.3" />
    <path d="m56.9 58.1-5.3-8.5c-.5-.8-1.3-1.2-2.2-1.2H36c6.2-5.9 16.3-17.1 16.3-26.1C52.3 11.1 43.2 2 32 2s-20.3 9.1-20.3 20.3c0 8.9 10.2 20.1 16.4 26.1H14.6c-.9 0-1.7.5-2.2 1.2l-5.3 8.5c-.5.8-.5 1.8-.1 2.6.5.8 1.4 1.3 2.3 1.3h45.4c.9 0 1.8-.5 2.2-1.3.5-.9.5-1.8 0-2.6M13.7 22.3C13.7 12.2 21.9 4 32 4s18.3 8.2 18.3 18.3c0 7.8-9.3 19-18.3 27-9-8-18.3-19.2-18.3-27m41.5 37.4c0 .1-.2.3-.5.3H9.3c-.3 0-.4-.2-.5-.3 0-.1-.1-.3 0-.6l5.3-8.5c.1-.2.3-.3.5-.3h15c.1 0 .3 0 .4-.1.5.4.9.8 1.3 1.2.2.2.4.3.7.3.2 0 .5-.1.7-.3.4-.3.8-.7 1.3-1.2.1.1.3.1.4.1h15c.2 0 .4.1.5.3l5.3 8.5c.1.3 0 .5 0 .6" />
  </svg>
);
export default IconMoshjidLocation;
