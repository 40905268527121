import * as React from "react";
const IconBallonAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M52.3 22.3C52.3 11.1 43.2 2 32 2s-20.3 9.1-20.3 20.3C11.7 44.4 26 50.6 30.5 52l-8.3 8.3c-.3.3-.4.7-.2 1.1s.5.6.9.6h18.2c.4 0 .8-.2.9-.6.2-.4.1-.8-.2-1.1L33.5 52c4.5-1.4 18.8-7.6 18.8-29.7M25.3 60l6.7-6.7 6.7 6.7zm6.7-9.7c-2-.4-18.3-4.7-18.3-28C13.7 12.2 21.9 4 32 4s18.3 8.2 18.3 18.3c0 23.3-16.3 27.6-18.3 28" />
    <path d="M36.6 12.8c-.4.4-.4 1-.1 1.4 0 0 3.8 4.3 3.2 8-.3 1.5-1.2 2.7-2.9 3.7-.5.3-.7.9-.4 1.4.2.3.5.5.9.5.2 0 .3 0 .5-.1q3.45-1.95 3.9-5.1c.8-4.6-3.5-9.4-3.7-9.6-.4-.5-1-.6-1.4-.2" />
  </svg>
);
export default IconBallonAlt;
