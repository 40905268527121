import * as React from "react";
const IconCourtAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.1 19.2 34.8 2.9c-1.7-1.2-3.9-1.2-5.6 0L5.9 19.2c-.4.3-.5.7-.4 1.1s.5.7 1 .7h6.2v39H10c-.6 0-1 .4-1 1s.4 1 1 1h44c.6 0 1-.4 1-1s-.4-1-1-1h-2.5V21.1h6c.4 0 .8-.3 1-.7.1-.5 0-.9-.4-1.2m-43.4 1.9h10.1V60H14.7zM34.6 60h-7.9V21.1h10.8V60zm15 0H39.5V21.1h10.1zm-40-40.9L30.4 4.5c1-.7 2.3-.7 3.3 0L54.5 19H9.6z" />
  </svg>
);
export default IconCourtAlt;
