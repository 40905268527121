import * as React from "react";
const IconDiamond = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.4 16.3 55.8 5c-.9-1.8-2.7-2.8-4.6-2.8H12.9c-2 0-3.7 1.1-4.6 2.8L2.6 16.3c-.8 1.6-.7 3.6.3 5.1l24.4 38c.9 1.5 2.6 2.4 4.3 2.4s3.3-.9 4.3-2.3l25.3-38c1-1.6 1.1-3.6.2-5.2M54 5.9l5.7 11.3c.1.2.1.3.2.5h-17l-4-13.5h12.2c1.2 0 2.3.6 2.9 1.7m-22.5 49-9.3-35.2h19.1zm5.3-50.7 4 13.5H22.3l4-13.5zM10.1 5.9c.5-1.1 1.6-1.7 2.8-1.7h11.3l-4 13.5h-16c.1-.2.1-.3.2-.5zm24.1 52.5c-.6.9-1.6 1.4-2.6 1.4-1.1 0-2.1-.5-2.6-1.5l-24.4-38c-.1-.2-.2-.4-.3-.7h15.9L30.6 59c0 .1.1.1.1.2v.1c.1.2.3.4.6.4h.4c.2-.1.4-.2.6-.4v-.1c0-.1.1-.1.1-.2l10.9-39.3h16.4c-.1.2-.2.5-.3.7z" />
  </svg>
);
export default IconDiamond;
