import * as React from "react";

const IconSignal = (props) => (
    <svg
        className={"fill-current"}
        xmlns="http://www.w3.org/2000/svg"
        width={props.size || "48"}
        height={props.size || "48"}
        viewBox="0 0 20 25"
        {...props}
    >
        <path
            d="M15.1431 1.78613C17.0239 5.04369 18.014 8.73892 18.014 12.5004C18.014 16.2619 17.0239 19.9572 15.1431 23.2147M10.4288 4.14328C11.8958 6.68417 12.6681 9.56645 12.6681 12.5004C12.6681 15.4344 11.8958 18.3167 10.4288 20.8576M5.92883 6.30756C6.98958 8.16437 7.54801 10.2706 7.54801 12.4147C7.54801 14.5588 6.98958 16.665 5.92883 18.5218M1.42883 8.64328C2.1933 9.80025 2.59859 11.1366 2.59859 12.5004C2.59859 13.8642 2.1933 15.2006 1.42883 16.3576"
            stroke="white"
            strokeWidth="2.57143"
            strokeLinecap="round"
        />
    </svg>
);

export default IconSignal;
