import * as React from "react";
const IconCloudSun = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.7 34.4c-.3 0-.6-.2-.7-.5-1.9-4.1-6-6.7-10.6-6.7-4.7 0-8.9 2.8-10.7 7-.1.3-.4.4-.7.4-2.4-.5-4.9-.2-7.2.8-3.5 1.6-6 4.8-6.6 8.5-.5 3.3.4 6.6 2.6 9.1 2.2 2.6 5.5 4.1 8.9 4.1h23.4c6.2 0 11.4-4.5 12-10.3.5-6.2-4.1-11.7-10.4-12.4M60 46.7c-.5 4.7-4.9 8.4-10 8.4H26.5c-2.9 0-5.6-1.2-7.4-3.4-1.8-2.1-2.5-4.8-2.1-7.5.5-3 2.6-5.7 5.5-7 1.3-.6 2.7-.9 4.1-.9.6 0 1.3.1 1.9.2 1.2.3 2.5-.4 3-1.5 1.5-3.5 5-5.8 8.9-5.8 3.8 0 7.3 2.2 8.8 5.6.4.9 1.3 1.5 2.3 1.6 5.2.6 9 5.1 8.5 10.3M15.7 30c.5-.2.8-.7.6-1.3-.2-.7-.3-1.4-.3-2.2 0-3.9 3.3-7.1 7.3-7.1 2.4 0 4.7 1.2 6 3.1.3.5.9.6 1.4.2.5-.3.6-.9.2-1.4-1.7-2.5-4.6-3.9-7.7-3.9-5.1 0-9.3 4.1-9.3 9.1 0 1 .2 1.9.4 2.8.1.4.5.7 1 .7zM24.3 10.6c.6 0 1-.4 1-1V7.9c0-.6-.4-1-1-1s-1 .4-1 1v1.7c0 .5.5 1 1 1M38 16.4c.3 0 .5-.1.7-.3l1.7-1.7c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-1.7 1.7c-.4.4-.4 1 0 1.4.1.2.4.3.7.3M4.7 27.7H3c-.6 0-1 .4-1 1s.4 1 1 1h1.7c.6 0 1-.4 1-1s-.4-1-1-1M9.7 13c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l1.7 1.7c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4z" />
  </svg>
);
export default IconCloudSun;
