import * as React from "react";
const IconDo = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 513 342"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 0h513v342H0z" />
    <path  d="M0 215.2h211.5v126.1H0z" />
    <path  d="M0 0h211.5v126.2H0z" />
    <path  d="M300.5 0H512v126.2H300.5z" />
    <path  d="M300.5 215.2H512v126.1H300.5z" />
    <g  strokeMiterlimit={10} strokeWidth={5}>
      <path  d="M256 130h-49.9v49.4s19.5 6 49.9 6z" />
      <path
        
        d="M206.1 179.4v6c0 27.5 22.3 49.9 49.9 49.9v-49.9c-30.4 0-49.9-6-49.9-6z"
      />
      <path
        
        d="M256 235.3c27.5 0 49.9-22.3 49.9-49.9v-6s-19.5 6-49.9 6z"
      />
      <path  d="M256 130v55.4c30.4 0 49.9-6 49.9-6V130z" />
    </g>
  </svg>
);
export default IconDo;
