import * as React from "react";
const IconPushRight = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60 17.9c-.6 0-1 .4-1 1v26.2c0 .6.4 1 1 1s1-.4 1-1V18.9c0-.5-.4-1-1-1M39.6 20.6c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l8.8 9H4c-.6 0-1 .4-1 1s.4 1 1 1h43l-8.8 9c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3l10.5-10.7c.4-.4.4-1 0-1.4z" />
  </svg>
);
export default IconPushRight;
