import * as React from "react";
const IconGf = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 90 60"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="m0 0 90 60H0z" />
    <path  d="M90 0v60L0 0z" />
    <path
      
      d="m50.7 33.3 9.3-6.7H48.5L45 15.7l-3.5 10.9H30l9.3 6.7-3.6 11 9.3-6.7 9.3 6.7z"
    />
  </svg>
);
export default IconGf;
