import * as React from "react";
const IconCandyCorn = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59 5.1c-2.6-2.6-6.2-3.6-9.8-2.8-1.8.4-3.6.9-5.4 1.3-5.3 1.4-10.5 3-15.4 4.8l-.8.3-3.9 1.5c-2.5 1-5 2-7.4 3.1-.6.3-1.2.6-1.8.8-.7.3-1.4.6-2 .9l-.5.4c-.9.4-1.7.9-2.6 1.3-5.5 2.9-8.4 9.1-7 15.2C4 39.1 7.7 45.8 13 51c5.3 5.3 12 9 19.3 10.6 1 .2 2.1.3 3.1.3 5.2 0 9.9-2.9 12.2-7.5.3-.6.6-1.2.9-1.7l.3-.7c1.7-3.6 3.4-7.5 5-11.5l.4-1c2.8-7.3 5.3-15.4 7.6-24.7.8-3.5-.2-7.1-2.8-9.7m-8.3-1h1.4c.2 0 .4 0 .7.1h.3c.2 0 .5.1.7.2.1 0 .2 0 .2.1l.9.3c.1 0 .1.1.2.1.2.1.4.2.7.3.1 0 .2 0 .2.1.2.1.4.3.6.4.1.1.2.1.2.2.3.2.5.4.7.7 2.1 2.1 2.9 5 2.1 7.9-.3 1.1-.6 2.3-.9 3.4-.1.2-.1.5-.2.7-.2.9-.5 1.7-.7 2.5-.1.4-.2.7-.3 1.1l-.6 2.1c-.1.4-.2.8-.4 1.2-.2.6-.4 1.2-.6 1.9-.1.4-.3.8-.4 1.2-.2.6-.4 1.2-.6 1.7-.1.4-.3.8-.4 1.2-.2.6-.4 1.1-.6 1.7-.1.4-.3.8-.4 1.1-.2.6-.4 1.1-.6 1.7-.1.4-.3.7-.4 1.1s-.3.7-.4 1c-3.8-1.9-7.4-4.4-10.7-7.5-6.2-5.9-10.4-13.1-12-20.4.8-.3 1.6-.5 2.4-.8 1.2-.4 1.9-.7 2.6-.9l3-.9c.7-.2 1.3-.4 2-.6 1.1-.3 2.3-.6 3.4-1 .6-.2 1.1-.3 1.7-.5 1.7-.5 3.5-.9 5.2-1.3.3-.1.7-.1 1-.1m-.2 39c-.3.7-.6 1.4-.8 2-.2.4-.3.8-.5 1.2-.3.6-.5 1.2-.8 1.8l-.6 1.2c-.2.4-.4.9-.6 1.3-7.3-1.2-14.6-5-20.7-10.7-7-6.6-11.5-15.1-12.4-23.3.6-.3 1.2-.5 1.7-.8s1.1-.5 1.6-.7c.7-.3 1.5-.7 2.2-1 .5-.2 1-.4 1.5-.7.9-.4 1.7-.7 2.6-1.1.4-.2.9-.4 1.3-.5 1-.4 1.9-.7 2.9-1.1 1.7 7.6 6.1 15.1 12.5 21.2 3.5 3.3 7.3 5.9 11.3 7.9-.3.8-.6 1.5-.9 2.3 0 .3-.1.7-.3 1M35.3 60c-.8 0-1.7-.1-2.6-.3-6.9-1.6-13.2-5.1-18.3-10.1-5-5-8.5-11.3-10.1-18.2-1.2-5.2 1.3-10.5 6-13 .6-.3 1.3-.6 1.9-.9 1.1 8.4 5.8 17 12.9 23.8 6.2 5.9 13.7 9.8 21.2 11.2l-.6 1.2c-2 3.9-6 6.3-10.4 6.3" />
  </svg>
);
export default IconCandyCorn;
