import * as React from "react";
const IconScooterCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.5 7.3H38.2C37.7 4.3 35.1 2 32 2s-5.7 2.3-6.2 5.3H13.5c-.6 0-1 .4-1 1s.4 1 1 1h12.3c0 .2.1.5.1.7-4.7 1.1-8.1 5.3-8.1 10.2V42c0 4.9 3.3 9 7.9 10.1v6c0 2.2 1.8 4 4 4h4.5c2.2 0 4-1.8 4-4v-6c4.6-1.1 7.9-5.3 7.9-10.1V20.1c0-4.9-3.4-9.1-8.1-10.2.1-.2.1-.4.1-.7h12.3c.6 0 1-.4 1-1s-.4-.9-.9-.9M32 4c2.4 0 4.3 1.9 4.3 4.3s-1.9 4.3-4.3 4.3-4.3-1.9-4.3-4.3S29.6 4 32 4m4.3 54c0 1.1-.9 2-2 2h-4.5c-1.1 0-2-.9-2-2v-9.8c0-1.1.9-2 2-2h4.5c1.1 0 2 .9 2 2zm1.2-46.1c3.9.8 6.7 4.3 6.7 8.2v21.8c0 3.7-2.4 7-5.9 8v-1.8c0-2.2-1.8-4-4-4h-4.5c-2.2 0-4 1.8-4 4V50c-3.5-1.1-5.9-4.3-5.9-8V20.1c0-4 2.8-7.4 6.7-8.2.1 0 .2-.1.3-.1 1.1 1.7 3 2.8 5.2 2.8s4.1-1.1 5.2-2.8c0 0 .1.1.2.1" />
  </svg>
);
export default IconScooterCopy;
