import * as React from "react";
const IconPlane = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M37 62.3c-.9 0-1.8-.4-2.4-1L29.4 56c-1.1-1.1-1.3-2.9-.5-4.2l4-6.4-6.8-6.7L14.4 53c-.6.8-1.5 1.2-2.5 1.3s-1.9-.3-2.6-1l-6.5-6.5c-1.2-1.2-1.3-3-.4-4.3L13.3 26 9 21.8c-3.5-3.5-3.4-9.2.1-12.7 1.7-1.7 3.9-2.6 6.3-2.7 2.5 0 4.7.9 6.4 2.6l4.2 4.3 16.3-11c1.4-.9 3.2-.7 4.3.4l6.5 6.5c.7.7 1 1.6 1 2.6s-.5 1.9-1.3 2.5L38.6 26.1l6.7 6.8 6.5-4.1c1.4-.9 3.1-.7 4.3.5l5.2 5.2c.8.8 1.1 1.8 1 2.9-.2 1.1-.8 2-1.8 2.6l-13.1 7.3-7.3 13.1c-.5 1-1.5 1.6-2.6 1.8-.1 0-.3.1-.5.1m-3.8-8.9 3.6 3.7 7.2-13 13-7.2-3.6-3.7-6.5 4.1c-1.4.9-3.2.7-4.3-.5l-8.1-8.3c-.7-.7-1-1.6-1-2.6.1-1 .5-1.9 1.3-2.5l14.3-11.7-5-5.1-16.3 10.9c-1.4.9-3.2.7-4.4-.4l-4.8-4.9c-.8-.8-1.9-1.2-3.1-1.2s-2.4.5-3.2 1.4c-1.8 1.8-1.8 4.6-.1 6.3l4.9 4.8c1.2 1.2 1.3 3 .4 4.4L6.6 44.2l5 5.1L23.3 35c.6-.8 1.5-1.2 2.5-1.3 1 0 1.9.3 2.6 1l8.2 8.1c1.1 1.1 1.3 2.9.5 4.3zM13.7 25.3" />
  </svg>
);
export default IconPlane;
