import React, { Children, useState } from "react";
import BringzzIcon from "../../BringzzIcon";
import BringzzText from "../../BringzzText";
import PropTypes from 'prop-types';

const BringzzPostingCardAction = ({ action }) => {
    return <div className="flex justify-between bg-magic-lilac h-9 px-3 py-2 rounded items-center cursor-pointer"
        onClick={action.onClick}
    >
        <div className="flex items-center space-x-1">
            <BringzzIcon
                icon={action.icon}
                folder={action.iconFolder}
                size="16"
            ></BringzzIcon>
            <BringzzText tag="h5" className={"font-semibold"}>
                {action.label}
            </BringzzText>
        </div>
        <BringzzIcon
            icon="IconChevronRight"
            folder="LineIcons"
            size="20"
        ></BringzzIcon>
    </div>
}

BringzzPostingCardAction.propTypes = {
    action: PropTypes.shape({
        icon: PropTypes.string.isRequired,
        iconFolder: PropTypes.string,
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
    }).isRequired,
};

export default BringzzPostingCardAction;
