import * as React from "react";
const IconCornerDoubleUpRight = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M46.9 22.5 33.7 9.3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l11.5 11.5h-34c-3.7 0-6.7 3-6.7 6.7V54c0 .6.4 1 1 1s1-.4 1-1V28.9c0-2.6 2.1-4.7 4.7-4.7h34L32.2 35.7c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l13.2-13.2c.5-.4.5-1 .1-1.4" />
    <path d="M60.7 22.5 47.5 9.3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l12.5 12.5-12.5 12.5c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l13.2-13.2c.4-.4.4-1 0-1.4" />
  </svg>
);
export default IconCornerDoubleUpRight;
