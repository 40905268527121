import * as React from "react";
const IconTableLamp2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.3 51.3H46.9L59 26.4c.7-1.5.7-3.2.1-4.7-.7-1.5-2-2.6-3.5-3.1l-21.2-6.1-9.9-9.9c-.4-.4-1-.4-1.4 0L16.7 9 2.6 14.6c-.3.2-.5.4-.6.8s0 .7.3.9l4.6 4.6a6.64 6.64 0 0 0 0 9.4c1.3 1.3 3 1.9 4.7 1.9s3.4-.6 4.7-1.9l4.6 4.6c.2.2.4.3.7.3h.2c.3-.1.6-.3.7-.6l5.6-14.1 5.9-5.9 20.9 6c1 .3 1.8 1 2.3 2s.4 2 0 3L44.9 51c-.1.1-.1.2-.1.4H33.4c-2.1 0-3.7 1.7-3.7 3.7v2.8c0 2.1 1.7 3.7 3.7 3.7h24.9c2.1 0 3.7-1.7 3.7-3.7v-2.8c0-2.1-1.7-3.8-3.7-3.8m-50-22.4c-1.8-1.8-1.8-4.8 0-6.6l6.6 6.6c-1.8 1.8-4.7 1.8-6.6 0m18.3-9.7c-.1.1-.2.2-.2.3l-5.2 12.9L4.8 15.9l12.9-5.2c.1-.1.2-.1.3-.2l5.7-5.7 8.6 8.6zM60 57.9c0 1-.8 1.7-1.7 1.7H33.4c-1 0-1.7-.8-1.7-1.7v-2.8c0-1 .8-1.7 1.7-1.7h24.9c1 0 1.7.8 1.7 1.7z" />
  </svg>
);
export default IconTableLamp2;
