export function requestNotificationPermission() {
    if ('Notification' in window) {
        return Notification.requestPermission().then(permission => {
            if (permission === 'granted') {
                console.log('Notification permission granted.');
                return true;
            } else {
                console.log('Notification permission denied.');
                return false;
            }
        });
    } else {
        console.error('Notifications are not supported in this browser.');
        return Promise.resolve(false);
    }
}

export function checkNotificationPermission() {
    if ('Notification' in window) {
        return Notification.permission === 'granted';
    }
    return false;
}

export function showLocalNotification(title, body) {
    if (navigator.serviceWorker) {
        navigator.serviceWorker.ready.then(registration => {
            registration.showNotification(title, {
                body: body,
                icon: '/icon.png', // Add your icon path
                vibrate: [200, 100, 200],
                tag: 'pwa-notification',
                actions: [
                    { action: 'explore', title: 'Explore', icon: '/check.png' },
                    { action: 'close', title: 'Close', icon: '/close.png' }
                ]
            });
        });
    }
}
