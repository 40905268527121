import * as React from "react";
const IconOralContraceptionPillsx28 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="m8.444 21.172 18.384 18.384a2 2 0 0 0 2.829 0l9.9-9.9a2 2 0 0 0 0-2.828L21.171 8.444a2 2 0 0 0-2.829 0l-9.9 9.9a2 2 0 0 0 0 2.828m16.97 19.799a4 4 0 0 0 5.657 0l9.9-9.9a4 4 0 0 0 0-5.657L22.586 7.03a4 4 0 0 0-5.657 0l-9.9 9.899a4 4 0 0 0 0 5.657z"
      clipRule="evenodd"
    />
    <circle
      cx={28.243}
      cy={36.728}
      r={0.5}
      
      transform="rotate(-135 28.243 36.728)"
    />
    <circle
      cx={25.414}
      cy={33.9}
      r={0.5}
      
      transform="rotate(-135 25.414 33.9)"
    />
    <circle
      cx={22.586}
      cy={31.071}
      r={0.5}
      
      transform="rotate(-135 22.586 31.07)"
    />
    <circle
      cx={19.758}
      cy={28.242}
      r={0.5}
      
      transform="rotate(-135 19.758 28.242)"
    />
    <circle
      cx={16.929}
      cy={25.414}
      r={0.5}
      
      transform="rotate(-135 16.929 25.414)"
    />
    <circle
      cx={14.101}
      cy={22.586}
      r={0.5}
      
      transform="rotate(-135 14.1 22.586)"
    />
    <circle
      cx={11.272}
      cy={19.757}
      r={0.5}
      
      transform="rotate(-135 11.272 19.757)"
    />
    <circle
      cx={31.071}
      cy={33.899}
      r={0.5}
      
      transform="rotate(-135 31.071 33.899)"
    />
    <circle
      cx={28.243}
      cy={31.071}
      r={0.5}
      
      transform="rotate(-135 28.243 31.07)"
    />
    <circle
      cx={25.414}
      cy={28.242}
      r={0.5}
      
      transform="rotate(-135 25.414 28.242)"
    />
    <circle
      cx={22.586}
      cy={25.414}
      r={0.5}
      
      transform="rotate(-135 22.586 25.414)"
    />
    <circle
      cx={19.758}
      cy={22.585}
      r={0.5}
      
      transform="rotate(-135 19.758 22.585)"
    />
    <circle
      cx={16.929}
      cy={19.757}
      r={0.5}
      
      transform="rotate(-135 16.93 19.757)"
    />
    <circle
      cx={14.101}
      cy={16.929}
      r={0.5}
      
      transform="rotate(-135 14.1 16.929)"
    />
    <circle
      cx={33.9}
      cy={31.07}
      r={0.5}
      
      transform="rotate(-135 33.9 31.07)"
    />
    <circle
      cx={31.072}
      cy={28.242}
      r={0.5}
      
      transform="rotate(-135 31.072 28.242)"
    />
    <circle
      cx={28.243}
      cy={25.414}
      r={0.5}
      
      transform="rotate(-135 28.243 25.414)"
    />
    <circle
      cx={25.415}
      cy={22.585}
      r={0.5}
      
      transform="rotate(-135 25.415 22.585)"
    />
    <circle
      cx={22.586}
      cy={19.757}
      r={0.5}
      
      transform="rotate(-135 22.586 19.757)"
    />
    <circle
      cx={19.758}
      cy={16.928}
      r={0.5}
      
      transform="rotate(-135 19.758 16.928)"
    />
    <circle
      cx={16.929}
      cy={14.1}
      r={0.5}
      
      transform="rotate(-135 16.93 14.1)"
    />
    <circle
      cx={36.728}
      cy={28.242}
      r={0.5}
      
      
      transform="rotate(-135 36.728 28.242)"
    />
    <circle
      cx={33.9}
      cy={25.413}
      r={0.5}
      
      
      transform="rotate(-135 33.9 25.413)"
    />
    <circle
      cx={31.072}
      cy={22.585}
      r={0.5}
      
      
      transform="rotate(-135 31.072 22.585)"
    />
    <circle
      cx={28.243}
      cy={19.757}
      r={0.5}
      
      
      transform="rotate(-135 28.243 19.757)"
    />
    <circle
      cx={25.415}
      cy={16.928}
      r={0.5}
      
      
      transform="rotate(-135 25.415 16.928)"
    />
    <circle
      cx={22.587}
      cy={14.1}
      r={0.5}
      
      
      transform="rotate(-135 22.587 14.1)"
    />
    <circle
      cx={19.758}
      cy={11.271}
      r={0.5}
      
      
      transform="rotate(-135 19.758 11.271)"
    />
  </svg>
);
export default IconOralContraceptionPillsx28;
