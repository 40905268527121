import * as React from "react";
const IconSmokingCessation = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      d="M10 8.586 39.414 38 38 39.414 8.586 10zM7 32a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h24a1 1 0 1 1 0 2H7a3 3 0 0 1-3-3v-2a3 3 0 0 1 3-3h18.5a1 1 0 1 1 0 2zM40 32a1 1 0 1 0-2 0v2a1 1 0 1 0 2 0zM34 17a3 3 0 0 0-3 3v1.818a3 3 0 0 0 3 3h3a3 3 0 0 1 3 3V28a1 1 0 1 1-2 0v-.182a1 1 0 0 0-1-1h-3a5 5 0 0 1-5-5V20a5 5 0 0 1 5-5 1 1 0 1 1 0 2"
    />
    <path
      
      d="M38 19a1 1 0 1 0 0 2 4 4 0 0 1 4 4v3a1 1 0 1 0 2 0v-3a6 6 0 0 0-2.644-4.974A4.4 4.4 0 0 0 43 16.59V15a5 5 0 0 0-5-5 1 1 0 1 0 0 2 3 3 0 0 1 3 3v1.59A2.41 2.41 0 0 1 38.59 19zM44 32a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0z"
    />
  </svg>
);
export default IconSmokingCessation;
