import * as React from "react";
const IconLetterIdea = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M29.6 12.5c-2.7-.1-5.2.9-7.1 2.8-1.9 1.8-2.9 4.3-2.9 7 0 3.4 1.7 6.5 4.6 8.3.5.3.8.9.8 1.6v1.7c0 2.1 1.7 3.9 3.9 3.9h.9c2.1 0 3.9-1.7 3.9-3.9V32c0-.6.3-1.2.8-1.6 2.8-1.7 4.5-4.7 4.6-8 .1-2.6-.9-5-2.7-7-1.8-1.8-4.2-2.8-6.8-2.9m2.1 21.3c0 1-.8 1.9-1.9 1.9h-.9c-1 0-1.9-.8-1.9-1.9v-2.1h4.7v2.1m1.8-5c-.4.2-.7.6-1 .9h-6.2c-.3-.4-.6-.7-1-.9-2.3-1.4-3.6-3.9-3.6-6.6 0-2.1.8-4.1 2.3-5.5s3.4-2.2 5.4-2.2h.2c2 .1 3.9.9 5.4 2.4 1.4 1.5 2.2 3.5 2.2 5.5-.1 2.6-1.5 5-3.7 6.4" />
    <path d="M54.6 4.4q-.15 0 0 0h-40c-3.4 0-6.2 2.8-6.2 6.2v32.3H6.9C4.2 42.8 2 45 2 47.7v4.5c0 4.1 3.3 7.4 7.4 7.4h32.3c4.1 0 7.4-3.3 7.4-7.4v-31h8c2.7 0 4.9-2.2 4.9-4.9v-4.5c0-4.1-3.3-7.4-7.4-7.4M9.4 57.6c-3 0-5.4-2.4-5.4-5.4v-4.5c0-1.6 1.3-2.9 2.9-2.9h24.5c1.6 0 2.9 1.3 2.9 2.9v4.5c0 2.1.9 4.1 2.4 5.4zm37.7-13.8v8.4c0 3-2.4 5.4-5.4 5.4s-5.4-2.4-5.4-5.4v-4.5c0-2.7-2.2-4.9-4.9-4.9h-21V10.5c0-2.3 1.9-4.2 4.2-4.2h35c-1.5 1.4-2.4 3.3-2.4 5.4v32.1zM60 16.3c0 1.6-1.3 2.9-2.9 2.9h-8v-7.4c0-3 2.4-5.4 5.4-5.4S60 8.8 60 11.8z" />
  </svg>
);
export default IconLetterIdea;
