import * as React from "react";
const IconDk = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 513 342"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 0h513v342H0z" />
    <path  d="M190 0h-60v140H0v60h130v142h60V200h323v-60H190z" />
  </svg>
);
export default IconDk;
