import * as React from "react";
const IconAnalytics = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.7 60.3 44.6 43.2c-.1-.1-.3-.2-.4-.2 4.2-4.5 6.5-10.4 6.5-16.6 0-6.5-2.5-12.6-7.1-17.2C38.9 4.5 32.8 2 26.3 2S13.7 4.5 9.1 9.1 2 19.8 2 26.3s2.5 12.6 7.1 17.2 10.7 7.1 17.2 7.1c6.2 0 12.1-2.3 16.6-6.5 0 .2.1.3.2.4l17.1 17.1c.2.2.5.3.7.3s.5-.1.7-.3c.5-.3.5-.9.1-1.3M26.3 48.7c-6 0-11.6-2.3-15.8-6.5S4 32.3 4 26.3s2.3-11.6 6.5-15.8S20.4 4 26.3 4c6 0 11.6 2.3 15.8 6.5s6.5 9.8 6.5 15.8-2.3 11.6-6.5 15.8-9.8 6.6-15.8 6.6" />
    <path d="M23.9 14c-4.2-.1-6.4 3.6-6.9 5.6-.1.5.2 1.1.7 1.2h.3c.4 0 .8-.3 1-.7 0 0 1.2-4.3 4.9-4.2.6 0 1-.4 1-1 0-.4-.5-.8-1-.9M37.7 20c-.2 0-4.3-1.2-4.2-4.9 0-.6-.4-1-1-1-.5 0-1 .4-1 1-.1 4.2 3.6 6.4 5.6 6.9h.3c.4 0 .8-.3 1-.7.2-.6-.1-1.2-.7-1.3M12.7 30.4c-.5.3-.6.9-.4 1.4.3.5.9.6 1.4.4 0 0 3.9-2.2 6.4.5.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4-2.8-3-7-1.9-8.8-.9M36.2 34.3c-.5-.1-1.1.2-1.2.7 0 0-1.2 4.2-4.9 4.2-.6 0-1 .4-1 1s.4 1 1 1h.2c4.1 0 6.2-3.7 6.7-5.6.1-.6-.2-1.1-.8-1.3" />
  </svg>
);
export default IconAnalytics;
