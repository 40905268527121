import * as React from "react";
const IconShoppingBag14 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50 14h-7.1v-1.1C42.9 6.9 38 2 32 2S21.1 6.9 21.1 12.9V14H14c-3.3 0-6 2.7-6 6v29.4c0 7 5.7 12.6 12.6 12.6h22.8C50.3 62 56 56.4 56 49.4V20c0-3.3-2.7-6-6-6m-26.9-1.1C23.1 8 27.1 4 32 4s8.9 4 8.9 8.9V14H23.1zM14 16h7.1v5.2c0 .6.4 1 1 1s1-.4 1-1V16H41v5.2c0 .6.4 1 1 1s1-.4 1-1V16h7c2.2 0 4 1.8 4 4v29.2H10V20c0-2.2 1.8-4 4-4m29.4 44H20.6c-5.2 0-9.6-3.8-10.4-8.8h43.6c-.8 5-5.2 8.8-10.4 8.8" />
  </svg>
);
export default IconShoppingBag14;
