import * as React from "react";
const IconBn = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 513 342"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 0h513v342H0z" />
    <path d="m0 193.74 513 127.19V214.26L0 97.08z" />
    <path  d="M513 234.26v-86.67L0 20.41v86.67" />
    <g   strokeMiterlimit={10} strokeWidth={3}>
      <path d="M306.4 134.01a50.3 50.3 0 0 1 6.07 24.02c0 21.55-13.51 39.94-32.52 47.19v-59.51c5.7-1.19 10-6.37 10-12.59 6.9 0 12.5-5.75 12.5-12.85h-.42c6.9 0 12.91-5.75 12.91-12.85h-34.99V76.58l-14.99-27.71-15 27.71v30.84h-34.99c0 7.1 6.01 12.85 12.91 12.85h-.42c0 7.1 5.6 12.85 12.5 12.85 0 6.22 4.29 11.4 9.99 12.59v61.37c-22.09-5.39-38.48-25.3-38.48-49.05 0-8.7 2.2-16.88 6.07-24.02-10.96 11.21-17.72 26.53-17.72 43.44 0 34.32 27.82 62.15 62.15 62.15s62.15-27.83 62.15-62.15c0-16.91-6.77-32.24-17.72-43.44z" />
      <path d="M198.46 226.81s20.69 27.71 66.5 27.71 66.5-27.71 66.5-27.71l13.47 28.37s-21.03 27.71-79.97 27.71-79.97-27.71-79.97-27.71zm-69.22-93.63h20.88l24.93 27.26v73.45h-26.28v-68.37zm270.76 0h-20.88l-24.93 27.26v73.45h26.27v-68.37z" />
    </g>
  </svg>
);
export default IconBn;
