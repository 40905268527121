import * as React from "react";
const IconPiss = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M18.8 25.1h9.4c.4 0 .8-.3.9-.7s0-.9-.3-1.1L20 16.7c-.4-.3-1.1-.2-1.4.2s-.2 1.1.2 1.4l6.4 4.8h-6.4c-.6 0-1 .4-1 1s.5 1 1 1M45.2 23.1h-6.4l6.4-4.8c.4-.3.5-1 .2-1.4s-1-.5-1.4-.2l-8.8 6.6c-.3.3-.5.7-.3 1.1.1.4.5.7.9.7h9.4c.6 0 1-.4 1-1s-.5-1-1-1" />
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
    <path d="M32 39c-5.2 0-10.1 1.9-13.8 5.4-.4.4-.4 1 0 1.4s1 .4 1.4 0C22.9 42.7 27.4 41 32 41s9.1 1.7 12.4 4.9c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4C42.1 40.9 37.2 39 32 39" />
  </svg>
);
export default IconPiss;
