import * as React from "react";
const IconThreeQuertersAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M37.6 2.1c-1.7-.3-3.4.1-4.7 1.2S30.8 6 30.8 7.7v23.1H7.7c-1.7 0-3.3.8-4.4 2.1S1.8 36 2.1 37.6C4.9 52 17.3 62 31.8 62c1.1 0 2.3-.1 3.4-.2 13.8-1.5 25-12.6 26.6-26.5 1.8-15.9-8.6-30.2-24.2-33.2m22.2 33C58.4 48 47.9 58.4 34.9 59.8c-14.7 1.6-28-8.1-30.8-22.6-.2-1.1.1-2.2.8-3.1.7-.8 1.7-1.3 2.8-1.3h24.1c.6 0 1-.4 1-1V7.7c0-1.1.5-2.1 1.3-2.8.9-.7 2-1 3.1-.8 14.6 2.8 24.3 16.1 22.6 31" />
    <path d="M31.8 45.2c-.6 0-1 .4-1 1v5c0 .6.4 1 1 1s1-.4 1-1v-5c0-.5-.5-1-1-1M20.7 41.3l-3.6 3.6c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l3.6-3.6c.4-.4.4-1 0-1.4s-1-.4-1.4 0M42.1 22.3c.3 0 .5-.1.7-.3l3.6-3.6c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-3.6 3.6c-.4.4-.4 1 0 1.4.2.2.5.3.7.3M46.4 30.6c-.6 0-1 .4-1 1s.4 1 1 1h5c.6 0 1-.4 1-1s-.4-1-1-1zM20.7 22c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4L18.6 17c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4zM42.8 41.3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l3.6 3.6c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4z" />
  </svg>
);
export default IconThreeQuertersAlt;
