import * as React from "react";
const IconImplant = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M20 10a4 4 0 0 1 8 0v28a4 4 0 0 1-8 0zm4-2a2 2 0 0 0-2 2v28a2 2 0 1 0 4 0V10a2 2 0 0 0-2-2"
      clipRule="evenodd"
    />
  </svg>
);
export default IconImplant;
