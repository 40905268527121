import * as React from "react";
const IconRevenueCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.7 45.7c-.9-2.8-3.9-4.5-6.8-3.7l-14.1 3.4v-1.1c0-2.6-2-4.8-4.9-5.3H22c-.6-1.5-2.2-2.6-3.9-2.6h-12c-2.3 0-4.2 1.9-4.2 4.2v14.6c0 2.4 1.9 4.3 4.3 4.3H18c1.2 0 2.4-.5 3.2-1.4.4-.4.7-.9.9-1.4l1.1.5c2.8 1.4 5.8 2.1 8.9 2.1 1.8 0 3.7-.3 5.5-.8l15.7-4.4 4.5-1.3c1.5-.4 2.7-1.3 3.4-2.6.8-1.5 1-3 .5-4.5M19.8 56.6c-.5.5-1.1.7-1.8.7H6.3c-1.2 0-2.3-1-2.3-2.3V40.5c0-1.2 1-2.2 2.2-2.2h12c1.2 0 2.3 1 2.3 2.3v14.5c0 .5-.2 1.1-.7 1.5m39.7-7.5c-.5.8-1.2 1.4-2.2 1.7l-4.5 1.3-15.7 4.4c-4.4 1.2-9 .8-13-1.2l-1.7-.9V40.9h13.3c1.8.3 3.1 1.7 3.1 3.3v1.6l-4.5 1.1c-.5.1-.9.7-.7 1.2.1.5.7.9 1.2.7l20.6-4.9c1.9-.5 3.8.6 4.4 2.4.3.9.2 1.9-.3 2.8M47.8 19.8h5.3c2.3 0 4.2 1.9 4.2 4.3s-1.9 4.3-4.2 4.3h-7.9c-.6 0-1 .4-1 1s.4 1 1 1h4.3V32c0 .6.4 1 1 1s1-.4 1-1v-1.6h1.7c3.4 0 6.2-2.8 6.2-6.3s-2.8-6.3-6.2-6.3h-5.3c-2.3 0-4.2-1.9-4.2-4.3s1.9-4.3 4.2-4.3h7.9c.6 0 1-.4 1-1s-.4-1-1-1h-4.3V5.6c0-.6-.4-1-1-1s-1 .4-1 1v1.6h-1.6c-3.4 0-6.2 2.8-6.2 6.3s2.7 6.3 6.1 6.3" />
  </svg>
);
export default IconRevenueCopy;
