import * as React from "react";
const IconApartmentsAlt4 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.4 11 46.8 4.4c-1.5-.8-3.3-.8-4.8.1S39.6 7 39.6 8.7v13.8H24.3V8.7c0-1.7-.9-3.3-2.4-4.2s-3.3-.9-4.8-.1L4.6 11C3 11.8 2 13.5 2 15.3v40c0 2.7 2.2 4.9 4.9 4.9h50.2c2.7 0 4.9-2.2 4.9-4.9v-40c0-1.8-1-3.5-2.6-4.3M6.9 58.2c-1.6 0-2.9-1.3-2.9-2.9v-40c0-1.1.6-2.1 1.5-2.6l12.6-6.6c.9-.5 2-.4 2.8.1.9.5 1.4 1.5 1.4 2.5v13.8H19c-2.2 0-4 1.8-4 4v31.7zm21.2 0v-9.4c0-1.1.9-2 2-2h3.8c1.1 0 2 .9 2 2v9.4zm12.6 0H38v-9.4c0-2.2-1.8-4-4-4h-3.8c-2.2 0-4 1.8-4 4v9.4H17V26.5c0-1.1.9-2 2-2h26c1.1 0 2 .9 2 2v31.7zM60 55.3c0 1.6-1.3 2.9-2.9 2.9H49V26.5c0-2.2-1.8-4-4-4h-3.4V8.7c0-1 .5-1.9 1.4-2.5.9-.5 1.9-.6 2.8-.1l12.6 6.6c1 .5 1.5 1.5 1.5 2.6v40z" />
  </svg>
);
export default IconApartmentsAlt4;
