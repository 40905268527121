import * as React from "react";
const IconView = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M28.4 20.8c-9 0-15.1 6.6-15.3 6.9-.3.3-.3.8-.1 1.1s3.8 7.1 15.5 7.1c11 0 15.3-6.8 15.4-7.1.2-.4.2-.8-.1-1.1-.2-.2-5.5-6.9-15.4-6.9m0 13.2c-8.5 0-12.2-3.9-13.3-5.5 1.6-1.5 6.6-5.7 13.3-5.7 7.3 0 11.9 4.1 13.3 5.7C40.5 30 36.5 34 28.4 34" />
    <circle cx={28.4} cy={28.4} r={2.2} />
    <path d="M61.7 60.3 47.8 46.4c9.6-10.3 9.4-26.6-.7-36.6C36.8-.6 20-.6 9.7 9.7s-10.3 27.1 0 37.4c5.1 5.2 11.9 7.7 18.7 7.7 6.5 0 12.9-2.3 18-7l13.9 13.9c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4M11.2 45.7C6.5 41.1 4 34.9 4 28.4s2.5-12.7 7.2-17.3S21.9 4 28.4 4s12.7 2.5 17.3 7.2 7.2 10.7 7.2 17.3-2.5 12.7-7.2 17.3S35 53 28.4 53s-12.6-2.7-17.2-7.3" />
  </svg>
);
export default IconView;
