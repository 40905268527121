import * as React from "react";
const IconShiftRightCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.9 3c-.6 0-1 .4-1 1v56c0 .6.4 1 1 1s1-.4 1-1V4c0-.6-.4-1-1-1M32.5 22.9c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l9 9.1h-33c-.6 0-1 .4-1 1s.4 1 1 1H40l-9 9.1c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3L43 35.1c.4-.4.4-1 0-1.4z" />
  </svg>
);
export default IconShiftRightCopy;
