import * as React from "react";
const IconWineGlass1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M45.2 41.8c3.4-3.7 5.1-8.5 4.8-13.6l-.9-12.1-.7-9.3C48.2 4.1 45.9 2 43.3 2H20.7c-2.7 0-4.9 2.1-5.1 4.8L14 28.3c-.4 5 1.3 9.9 4.8 13.6 3.2 3.5 7.5 5.4 12.2 5.7V60h-8.6c-.6 0-1 .4-1 1s.4 1 1 1h19.1c.6 0 1-.4 1-1s-.4-1-1-1H33V47.5c4.7-.3 9-2.3 12.2-5.7M17.6 6.9c.1-1.6 1.5-2.9 3.1-2.9h22.5c1.6 0 3 1.3 3.1 2.9l.6 8.6L42 18c-3 1.5-6.7 1.2-9.5-.6-3-2-6.7-2.4-10.1-1.3L16.8 18zM16 28.4l.6-8.2 6.3-2.1c2.8-1 5.9-.6 8.4 1 1.9 1.2 4.1 1.9 6.4 1.9 1.7 0 3.5-.4 5.1-1.2l4.4-2.1.8 10.7c.3 4.5-1.2 8.8-4.2 12s-7.2 5.1-11.7 5.1-8.7-1.8-11.7-5.1-4.7-7.5-4.4-12" />
  </svg>
);
export default IconWineGlass1;
