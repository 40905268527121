import * as React from "react";
const IconVrBrowser = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.9 4.4H7.1C4.3 4.4 2 6.7 2 9.6v44.9c0 2.8 2.3 5.1 5.1 5.1h49.7c2.8 0 5.1-2.3 5.1-5.1V9.6a5 5 0 0 0-5-5.2m-49.8 2h49.7c1.7 0 3.1 1.4 3.1 3.1v6.6H4V9.6c0-1.8 1.4-3.2 3.1-3.2m49.8 51.2H7.1c-1.7 0-3.1-1.4-3.1-3.1V18.2h56v36.3c0 1.7-1.4 3.1-3.1 3.1" />
    <path d="M43 31.4h-1.9v-1.6c0-2.8-2.3-5.1-5.1-5.1h-8c-2.8 0-5.1 2.3-5.1 5.1v1.6H21c-2.8 0-5.1 2.3-5.1 5.1v8.8c0 2.8 2.3 5.1 5.1 5.1h6.7c.3 0 .5-.1.7-.3l3.6-3.6 3.6 3.6c.2.2.4.3.7.3H43c2.8 0 5.1-2.3 5.1-5.1v-8.8c.1-2.8-2.2-5.1-5.1-5.1m-15-4.8h8c1.7 0 3.1 1.4 3.1 3.1v1.6H24.9v-1.6c0-1.7 1.4-3.1 3.1-3.1m15 21.9h-6.3l-4-4c-.4-.4-1-.4-1.4 0l-4 4H21c-1.7 0-3.1-1.4-3.1-3.1V41H22c.6 0 1-.4 1-1s-.4-1-1-1h-4.2v-2.4c0-1.7 1.4-3.1 3.1-3.1H43c1.7 0 3.1 1.4 3.1 3.1V39H42c-.6 0-1 .4-1 1s.4 1 1 1h4.2v4.4c0 1.6-1.4 3.1-3.2 3.1" />
  </svg>
);
export default IconVrBrowser;
