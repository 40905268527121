import * as React from "react";
const IconSlim = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M9.9 26.6c-.9-.9-2.3-.9-3.2 0s-.9 2.3 0 3.2l1.4 1.4H3c-1.2 0-2.2 1-2.2 2.2s1 2.2 2.2 2.2h5.1L6.7 37c-.9.9-.9 2.3 0 3.2.4.4 1 .7 1.6.7s1.2-.2 1.6-.7l5.2-5.2c.9-.9.9-2.3 0-3.2zM61 31.2h-5.1l1.4-1.4c.9-.9.9-2.3 0-3.2s-2.3-.9-3.2 0l-5.2 5.2c-.9.9-.9 2.3 0 3.2l5.2 5.2c.4.4 1 .7 1.6.7s1.2-.2 1.6-.7c.9-.9.9-2.3 0-3.2l-1.4-1.4H61c1.2 0 2.2-1 2.2-2.2s-1-2.2-2.2-2.2M44.7 26.2c-1.2-5.4 1.8-14.2 1.8-14.3.4-1.2-.2-2.5-1.4-2.9s-2.5.2-2.9 1.4c-.1.4-3.4 10.1-2 16.7.2.8.4 1.6.6 2.3-.8.1-1.6.2-2.5.3-2.1.3-4.2.6-6.3.6-2.2 0-4.4-.3-6.5-.6-.9-.1-1.8-.3-2.7-.4.2-.7.4-1.5.5-2.3 1.5-6.6-1.8-16.3-2-16.7-.3-1.1-1.5-1.7-2.7-1.3s-1.8 1.7-1.4 2.9c0 .1 3 8.9 1.8 14.3-.6 2.6-1.2 4.7-1.8 6.6-.8 2.5-1.4 4.6-1.4 6.5 0 3 2.7 12.4 3.3 14.3.3 1 1.2 1.6 2.2 1.6.2 0 .4 0 .6-.1 1.2-.4 1.9-1.6 1.5-2.8-.7-2.5-1.8-6.2-2.4-9.1.6-.2 1.9-.5 4.2-.5 2.1 0 4.1 1.1 4.6 1.7v7c0 1.2 1 2.2 2.2 2.2s2.2-1 2.2-2.2v-7.1c.1-.4 1.4-1.5 3.4-1.5 2.5 0 4.2.3 5 .5-.7 2.9-1.7 6.6-2.4 9.1-.3 1.2.3 2.4 1.5 2.8.2.1.4.1.6.1 1 0 1.9-.6 2.2-1.6.5-1.9 3.3-11.3 3.3-14.3 0-1.9-.6-4-1.4-6.5-.5-2-1.2-4.2-1.7-6.7m-12.9 14c-1.8-1.2-4.3-2-6.6-2-1.9 0-3.5.2-4.8.5.1-1.2.6-2.7 1.2-4.6 0-.1.1-.2.1-.3 1 .1 2.1.3 3.3.4 2.2.3 4.6.7 7.1.7s4.9-.4 7-.7c1.1-.2 2.1-.3 3-.4 0 .1.1.2.1.2.6 1.9 1 3.4 1.2 4.6-2.1-.5-4.5-.5-5.7-.5-2.3.1-4.5.9-5.9 2.1" />
    <path d="M32 27.4c2.6.1 3-4 .4-4.5-3.1-.6-3.6 4.5-.4 4.5" />
  </svg>
);
export default IconSlim;
