import * as React from "react";
const IconFan = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 65 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.3 25.8C55.3 13.2 45.1 3 32.5 3S9.7 13.2 9.7 25.8c0 4.1 1.1 8 3.1 11.4 3.8 6.5 10.8 11 18.8 11.4V59h-7.2c-.6 0-1 .4-1 1s.4 1 1 1h16.4c.6 0 1-.4 1-1s-.4-1-1-1h-7.2V48.7c12-.6 21.7-10.6 21.7-22.9m-2 0q0 2.4-.6 4.8c-.2-.1-.5-.1-.7 0-3.4 1.3-8.2 1-12.8-.8.7-1.2 1.1-2.5 1.1-3.9 0-3.4-2.2-6.3-5.2-7.4v-1c0-4.8-1.3-9.3-3.5-12.4h.8C44 5 53.3 14.4 53.3 25.8m-15 0c0 3.2-2.6 5.8-5.8 5.8s-5.8-2.6-5.8-5.8 2.6-5.8 5.8-5.8 5.8 2.6 5.8 5.8m-9-20.5c2.4 2.8 3.9 7.3 3.9 12.2v.6h-.7c-4.3 0-7.8 3.5-7.8 7.8v.6c-.3.1-.7.2-1 .4-4.3 1.8-7.9 4.7-10 7.9-1.3-2.7-2-5.7-2-8.9 0-10.5 7.6-19.1 17.6-20.6M14.8 36.8c1.7-3.3 5.2-6.4 9.6-8.2.2-.1.5-.2.7-.3 1.1 3.1 4 5.3 7.4 5.3 2.1 0 4.1-.9 5.5-2.3h.1c3.1 1.3 6.3 2 9.2 2 1.7 0 3.4-.3 4.9-.7-2.8 8.1-10.6 14-19.7 14-7.5.1-14-3.9-17.7-9.8" />
  </svg>
);
export default IconFan;
