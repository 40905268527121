import * as React from "react";
const IconSavings = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M49 24.3h-2.1v-5.8c0-2.2-1.8-4-4-4h-3c.7-1.3 1.1-2.7 1.1-4.2C41 5.7 37.3 2 32.7 2s-8.4 3.7-8.4 8.4c0 1.5.4 2.9 1.1 4.2h-4.3c-2.2 0-4 1.8-4 4v5.8H15c-2.2 0-4 1.8-4 4V58c0 2.2 1.8 4 4 4h34c2.2 0 4-1.8 4-4V28.3c0-2.2-1.8-4-4-4M26.3 10.4c0-3.5 2.8-6.4 6.4-6.4S39 6.8 39 10.4c0 1.5-.6 3-1.5 4.2h-9.6c-1-1.3-1.6-2.7-1.6-4.2m-7.2 8.1c0-1.1.9-2 2-2H43c1.1 0 2 .9 2 2v5.8H19.1zM51 58c0 1.1-.9 2-2 2H15c-1.1 0-2-.9-2-2V28.3c0-1.1.9-2 2-2h34c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconSavings;
