import * as React from "react";
const IconBedDentist = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.6 42.3c-.9-.6-1.9-.9-2.9-.9H18.5c-.8 0-1.5-.3-2.1-.8L3.7 28.7c-.4-.4-1-.4-1.4.1-.4.4-.4 1 .1 1.4L15 42c.9.9 2.1 1.3 3.4 1.3h7.5v7.9h-3c-2.8 0-5 2.3-5 5v3.1c0 .6.4 1 1 1s1-.4 1-1v-3.1c0-1.7 1.4-3 3-3h18.8c1.7 0 3 1.4 3 3v3.1c0 .6.4 1 1 1s1-.4 1-1v-3.1c0-2.8-2.3-5-5-5H38v-7.9h10.7q.9 0 1.8.6l8.2 5.8c.2.1.4.2.6.2.3 0 .6-.1.8-.4.3-.5.2-1.1-.2-1.4zm-15.6 9h-8.1v-7.9H36zM61.7 3.9c-.4-.4-1-.4-1.4 0l-6.9 6.9-.5-.5c-1.1-1.1-2.5-1.6-3.9-1.6s-2.9.6-3.9 1.6l-.1.1c-.4.4-.4 1 0 1.4s1 .4 1.4 0l.1-.1c1.4-1.4 3.7-1.4 5.1 0l2.1 2.1c1.4 1.4 1.4 3.7 0 5.1l-.1.1c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3l.1-.1c2.2-2.2 2.2-5.7 0-7.9l-.3-.3 6.9-6.9c.4-.2.4-.9 0-1.3M11.5 12.3l-.3.3c-2.2 2.2-2.2 5.7 0 7.9l.1.1c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-.1-.2c-1.4-1.4-1.4-3.7 0-5.1l2.1-2.1c1.4-1.4 3.7-1.4 5.1 0l.1.1c.4.4 1 .4 1.4 0s.4-1 0-1.4l-.1-.1c-2.2-2.2-5.7-2.2-7.9 0l-.5.5-6.8-7c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4z" />
  </svg>
);
export default IconBedDentist;
