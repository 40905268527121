import * as React from "react";
const IconNpm = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 33.8h-3.5v-6.9H32zM63 20v20.6H32V44H18.2v-3.5H1V20zm-44.8 3.3H4.4v13.8h6.9V26.9h3.5v10.4h3.5zm17.3 0H21.7v17.2h6.9V37h6.9zm24 0H38.9v13.8h6.9V26.9h3.5v10.4h3.5V26.9h3.5v10.4h3.5v-14z" />
  </svg>
);
export default IconNpm;
