import * as React from "react";
const IconWand = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.7 23.5-7.6-7.6L59 6.3c.2-.4.1-.9-.2-1.2s-.8-.3-1.2-.1l-9.5 4.9-7.6-7.6c-.3-.3-.8-.4-1.2-.2s-.6.6-.5 1l1.7 10.6-9.5 4.9c-.4.2-.6.6-.5 1s.4.8.8.8l9.3 1.5L2.3 60.3c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3L42 23.4l1.5 9.4c.1.4.4.8.8.8h.2c.4 0 .7-.2.9-.5l4.9-9.5 10.6 1.7c.4.1.9-.1 1-.5.2-.6.1-1-.2-1.3m-11.9-2.1c-.4-.1-.9.1-1 .5L45 29.4l-1.3-8.3c0-.1 0-.1-.1-.2-.1-.3-.4-.6-.8-.6L34.6 19l7.4-3.8c.4-.2.6-.6.5-1l-1.3-8.3 5.9 5.9c.3.3.8.4 1.2.2l7.4-3.8-3.7 7.4c-.2.4-.1.9.2 1.2l5.9 5.9z" />
  </svg>
);
export default IconWand;
