import React, { useState } from 'react';
import { BringzzRotatingSelect } from "@bringzz/components";

const longMonths = [
    { label: 'January', value: 0 },
    { label: 'February', value: 1 },
    { label: 'March', value: 2 },
    { label: 'April', value: 3 },
    { label: 'May', value: 4 },
    { label: 'June', value: 5 },
    { label: 'July', value: 6 },
    { label: 'August', value: 7 },
    { label: 'September', value: 8 },
    { label: 'October', value: 9 },
    { label: 'November', value: 10 },
    { label: 'December', value: 11 }
];

const TestingPage = () => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [month, setMonth] = useState(selectedDate.getMonth());

    return (
        <div>
            <BringzzRotatingSelect
                options={longMonths}
                onSelect={(monthObject) => setMonth(monthObject.value)}
                defaultOption={3} // May
            />
        </div>
    );
};

export default TestingPage;
