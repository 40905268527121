import * as React from "react";
const IconCabinet1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.7 2H9.3c-.6 0-1 .4-1 1s.4 1 1 1H13v57c0 .6.4 1 1 1s1-.4 1-1v-5.3h34V61c0 .6.4 1 1 1s1-.4 1-1V4h3.7c.6 0 1-.4 1-1s-.4-1-1-1M49 53.7H15V4h34z" />
    <path d="M26.3 25.8h11.4c2.2 0 4-1.8 4-4v-4.5c0-2.2-1.8-4-4-4H26.3c-2.2 0-4 1.8-4 4v4.5c0 2.2 1.8 4 4 4m-2-8.6c0-1.1.9-2 2-2h11.4c1.1 0 2 .9 2 2v4.5c0 1.1-.9 2-2 2H26.3c-1.1 0-2-.9-2-2zM26.3 44.6h11.4c2.2 0 4-1.8 4-4v-4.5c0-2.2-1.8-4-4-4H26.3c-2.2 0-4 1.8-4 4v4.5c0 2.2 1.8 4 4 4m-2-8.5c0-1.1.9-2 2-2h11.4c1.1 0 2 .9 2 2v4.5c0 1.1-.9 2-2 2H26.3c-1.1 0-2-.9-2-2z" />
  </svg>
);
export default IconCabinet1;
