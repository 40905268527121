import * as React from "react";
const IconCornerDownLeft = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.8 3c-.6 0-1 .4-1 1v31.9c0 3.5-2.8 6.3-6.3 6.3H7.6l15.1-15.1c.4-.4.4-1 0-1.4s-1-.4-1.4 0L4.5 42.5c-.2.2-.3.4-.3.7s.1.5.3.7l16.8 16.8c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4L7.6 44.2h43.9c4.6 0 8.3-3.7 8.3-8.3V4c0-.6-.4-1-1-1" />
  </svg>
);
export default IconCornerDownLeft;
