import * as React from "react";
const IconPl = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 85.333 512 341.333"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <g >
      <path d="M0 85.337h512v341.326H0z" />
      <path d="M0 85.337h512V256H0z" />
    </g>
    <path  d="M0 256h512v170.663H0z" />
  </svg>
);
export default IconPl;
