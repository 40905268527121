import React, { useState, cloneElement } from "react";
import PropTypes from "prop-types";

/**
 * @typedef {object} BringzzRadioListProps
 * @property {React.ReactNode} children - The child BringzzRadio components.
 * @property {function} onChange - Function to call when the selected radio changes.
 * @property {string} defaultValue - The default value of the selected radio.
 * @property {{ checked: React.ReactNode, unChecked: React.ReactNode }} state - State icons for checked/unchecked states.
 * @property {React.ReactNode} [left] - Optional left element, usually an icon, to be used as a default for all radio items.
 */

/**
 * A list component for managing a group of BringzzRadio components.
 * @param {BringzzRadioListProps} props - The component props.
 * @returns {JSX.Element} - JSX for the radio list component.
 */
export default function BringzzRadioList({
  children,
  onChange,
  defaultValue,
  state,
  left,
  className,
  ...parentProps
}) {
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  const handleOnChange = (newValue) => {
    setSelectedValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  const clonedChildren = React.Children.map(children, (child) => {
    if (React.isValidElement(child) && child.type.displayName === "BringzzRadio") {
      // Build props for the child
      const childProps = {
        ...parentProps,
        state: child.props.state || state,
        onChange: handleOnChange,
        checked: child.props.value === selectedValue,
        controlled: true,
        value: child.props.value,
      };

      // If the child does not have its own icon, use the default icon
      if (!child.props.left) {
        childProps.left = left;
      }

      return cloneElement(child, childProps);
    }
    return child;
  });

  return <div className={className}>{clonedChildren}</div>;
}

BringzzRadioList.propTypes = {
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  state: PropTypes.shape({
    checked: PropTypes.node.isRequired,
    unChecked: PropTypes.node.isRequired,
  }).isRequired,
  left: PropTypes.node,
};
