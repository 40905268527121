import * as React from "react";
const IconKn = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 513 342"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 0h513v342H0z" />
    <path  d="M512 0H0v342" />
    <path  d="M307.7 0 0 194.5V342h206.9L513 148.5V0z" />
    <path d="M513 0H385.2L0 249.4V341l126.2 1L513 91.6z" />
    <path
      
      d="m141.1 187 31.2 24 32.3-22.2-13.1 37 31.1 23.9-39.2-1.1-13.2 37-11.1-37.6-39.2-1.1 32.3-22.2zM310.6 70.8l31.2 23.9 32.3-22.2-13.1 37 31.1 23.9-39.2-1.1-13.2 37-11.1-37.6-39.2-1.1 32.4-22.2z"
    />
  </svg>
);
export default IconKn;
