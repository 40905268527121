import * as React from "react";
const IconPetard = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.8 36.2 52 28.3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l7.9 7.9c2.1 2.1 2.1 5.6 0 7.7s-5.6 2.1-7.7 0l-4-4c.3-.7.5-1.4.5-2.1 0-1.4-.5-2.7-1.5-3.6L20.9 10.8l2-2c.7-.7.9-1.7.5-2.6s-1.2-1.5-2.2-1.5H4.4C3.1 4.8 2 5.8 2 7.1v16.8c0 1 .6 1.8 1.5 2.2.3.1.6.2.9.2.6 0 1.2-.2 1.7-.7l2-2 24.8 24.8c1 1 2.3 1.5 3.6 1.5.2 0 .4 0 .6-.1 0 0 0 .1.1.1l9 9c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-8.4-8.4c.3-.2.7-.4 1-.7l5.4-5.4 3.7 3.7c1.5 1.5 3.4 2.2 5.3 2.2s3.8-.7 5.3-2.2c2.8-2.9 2.8-7.6-.1-10.5m-24.9-8.5-6 14-8.3-8.3 5.9-14.1zM4.6 24.2c-.2.2-.3.1-.4.1S4 24.2 4 24V7.1c0-.2.2-.4.4-.4h16.8c.2 0 .3.1.3.2s.1.3-.1.4zm14.8-12 5.5 5.5L19 31.8l-9.6-9.6zM38.7 47c-1.2 1.2-3.2 1.2-4.4 0l-3.8-3.8 6-14 7.8 7.8c.6.6.9 1.4.9 2.2s-.3 1.6-.9 2.2z" />
  </svg>
);
export default IconPetard;
