import * as React from "react";
const IconFireworksCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M47 36.4c0-1.4-.5-2.7-1.5-3.6L20.8 8l2-2c.7-.7.9-1.7.5-2.6C22.9 2.6 22 2 21.1 2H4.4C3.1 2 2 3.1 2 4.4v16.7c0 1 .6 1.8 1.5 2.2.3.1.6.2.9.2.6 0 1.2-.2 1.7-.7l2-2 24.8 24.8c1 1 2.3 1.5 3.5 1.5 1.3 0 2.6-.5 3.5-1.5l5.6-5.6c1-1 1.5-2.3 1.5-3.6M34.8 24.8l-5.9 13.9-8.3-8.3 5.8-14zM4.6 21.3c-.2.2-.3.1-.4.1s-.2-.1-.2-.3V4.4c0-.2.2-.4.4-.4h16.7c.2 0 .3.1.3.2s.1.3-.1.4zM19.4 9.4l5.5 5.5-5.8 14-9.6-9.6zm24.7 29.2-5.6 5.6c-1.2 1.2-3.1 1.2-4.2 0l-3.9-3.9 6-13.9 7.8 7.8c.6.6.9 1.4.9 2.2-.1.8-.4 1.6-1 2.2M44.7 54.6c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l5.7 5.7c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4zM50.4 48.9c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l9.5 9.5c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4zM61.7 48.9 56 43.3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l5.7 5.7c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1.1 0-1.5" />
  </svg>
);
export default IconFireworksCopy;
