import * as React from "react";
const IconWarningCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.5 42.5 38.3 12.3c-1.5-2-3.8-3.2-6.3-3.2s-4.8 1.2-6.3 3.2L3.5 42.5c-1.8 2.4-2 5.5-.7 8.2s4 4.3 7 4.3h44.3c3 0 5.7-1.6 7-4.3 1.4-2.7 1.1-5.8-.6-8.2m-1.1 7.2c-1 2-3 3.2-5.2 3.2H9.9c-2.2 0-4.2-1.2-5.2-3.2s-.8-4.3.5-6.1l22.1-30.2c1.1-1.5 2.8-2.4 4.7-2.4s3.6.9 4.7 2.4l22.1 30.2c1.4 1.9 1.6 4.1.6 6.1" />
    <path d="M32 37.2c.6 0 1-.4 1-1V25c0-.6-.4-1-1-1s-1 .4-1 1v11.3c0 .5.4.9 1 .9M32 41.3c-1.6 0-2.9 1.3-2.9 2.9s1.3 2.9 2.9 2.9 2.9-1.3 2.9-2.9-1.3-2.9-2.9-2.9" />
  </svg>
);
export default IconWarningCopy;
