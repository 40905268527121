import * as React from "react";
const IconTreesAlt4 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.5 35.6c0-3.7-1.4-7.2-4-9.8 2.5-2.6 4-6.1 4-9.7 0-7.8-6.3-14.1-14-14.1-6 0-11.2 3.8-13.2 9.3C27 9 23.8 7.6 20.2 7.6c-7.1 0-12.8 5.7-12.8 12.8 0 3.3 1.3 6.5 3.5 8.8-2.3 2.4-3.5 5.5-3.5 8.8 0 6.7 5.2 12.2 11.8 12.7V60H9.9c-.6 0-1 .4-1 1s.4 1 1 1h42.6c.6 0 1-.4 1-1s-.4-1-1-1h-9V49.6c7.3-.6 13-6.6 13-14M42.5 4c6.6 0 12 5.4 12 12.1 0 3.5-1.5 6.7-4.1 9-.2.2-.3.5-.3.8s.1.6.3.8c2.6 2.3 4.1 5.6 4.1 9 0 6.3-4.9 11.5-11 12V33.5c0-.6-.4-1-1-1s-1 .4-1 1v14.1c-3.7-.3-7.1-2.3-9.1-5.5.4-1.3.7-2.6.7-4 0-3.3-1.3-6.5-3.5-8.8 2.3-2.4 3.5-5.5 3.5-8.8 0-2.7-.8-5.1-2.2-7.2C32.1 7.8 37 4 42.5 4M9.4 38c0-3.1 1.3-6.1 3.6-8.1.2-.2.3-.5.3-.8s-.1-.6-.3-.8c-2.3-2-3.6-5-3.6-8.1 0-6 4.8-10.8 10.8-10.8s10.8 5 10.8 11c0 3.1-1.3 6-3.6 8.1-.2.2-.3.5-.3.8s.1.6.3.8c2.3 2 3.6 5 3.6 8.1 0 5.6-4.3 10.2-9.8 10.7V36.1c0-.6-.4-1-1-1s-1 .4-1 1v12.6c-5.4-.5-9.8-5.1-9.8-10.7m11.8 22v-9.2c4.4-.3 8.2-2.9 10.2-6.6 2.4 3.1 6.1 5.1 10.1 5.4V60z" />
  </svg>
);
export default IconTreesAlt4;
