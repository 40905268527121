import * as React from "react";
const IconHat3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M43.8 33.9v-9.5c0-6.5-5.3-11.8-11.8-11.8s-11.8 5.3-11.8 11.8v9.5C15.3 34.5 2 36.7 2 42.3c0 5.9 15.5 9.1 30 9.1s30-3.2 30-9.1c0-5.5-12.9-7.7-18.2-8.4M32 49.4c-17.3 0-28-4.1-28-7.1 0-2.2 5.6-5.1 16.2-6.4v2.8c0 .6.4 1 1 1s1-.4 1-1V24.4c0-5.4 4.4-9.8 9.8-9.8s9.8 4.4 9.8 9.8v14.3c0 .6.4 1 1 1s1-.4 1-1V36C54.4 37.3 60 40.1 60 42.3c0 3-10.7 7.1-28 7.1" />
  </svg>
);
export default IconHat3;
