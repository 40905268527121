import * as React from "react";
const IconGlutenFree = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M40.7 14.9c-.6 0-1 .4-1 1v2L33 20.7v-7.5c0-.6-.4-1-1-1s-1 .4-1 1v7.4l-6.7-2.8v-1.9c0-.6-.4-1-1-1s-1 .4-1 1v20.2c0 .4.2.8.6.9l8.1 3.3v9.1c0 .6.4 1 1 1s1-.4 1-1v-9.1l8.1-3.3q.6-.3.6-.9V15.9c0-.5-.4-1-1-1m-1 5.2v6.4L33 29.3v-6.4zM31 22.8v7.1l-6.7-2.8V20zm-6.7 12.6v-6.1l6.7 2.8v6.1zm8.7 2.8v-6.7l6.7-2.8v6.8z" />
    <path d="M56.6 14.8C51.1 7.1 42.1 2 32 2 15.5 2 2 15.5 2 32c0 3.8.7 7.5 2 10.8v.1C8.4 54.1 19.3 62 32 62c16.5 0 30-13.5 30-30 0-6.4-2-12.3-5.4-17.2M32 60c-11.6 0-21.5-7-25.8-17.1l9.3-5c.5-.3.7-.9.4-1.4s-.9-.7-1.4-.4l-9 4.8C4.5 38.2 4 35.2 4 32 4 16.6 16.6 4 32 4c9 0 17.1 4.3 22.2 11L48 18.3c-.5.3-.7.9-.4 1.4.2.3.5.5.9.5.2 0 .3 0 .5-.1l6.4-3.4C58.3 21 60 26.3 60 32c0 15.4-12.6 28-28 28" />
  </svg>
);
export default IconGlutenFree;
