import * as React from "react";
const IconGoFrame1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m56 35.9-2.7-10.2L56 16c.4-1.6.1-3.2-.9-4.5s-2.5-2-4.1-2H9.8V3c0-.6-.4-1-1-1s-1 .4-1 1v58c0 .6.4 1 1 1s1-.4 1-1V42.4H51c1.6 0 3.1-.7 4.1-2s1.3-3 .9-4.5m-2.5 3.2c-.6.8-1.5 1.2-2.5 1.2H9.8v-29H51c1 0 1.9.5 2.5 1.2s.8 1.8.5 2.7l-2.7 10v.5l2.8 10.5c.2 1.1 0 2.1-.6 2.9" />
    <path d="M22.2 18.5h6c.6 0 1-.4 1-1s-.4-1-1-1h-6c-2.8 0-5.2 2.3-5.2 5.2v8c0 2.8 2.3 5.2 5.2 5.2H24c2.8 0 5.2-2.3 5.2-5.2v-4.1c0-.6-.4-1-1-1h-3c-.6 0-1 .4-1 1s.4 1 1 1h2v3.1c0 1.7-1.4 3.2-3.2 3.2h-1.8c-1.7 0-3.2-1.4-3.2-3.2v-8c0-1.8 1.5-3.2 3.2-3.2M40.3 16.5h-.4c-2.8 0-5.2 2.3-5.2 5.2v8c0 2.8 2.3 5.2 5.2 5.2h.4c2.8 0 5.2-2.3 5.2-5.2v-8c-.1-2.9-2.4-5.2-5.2-5.2m3.1 13.1c0 1.7-1.4 3.2-3.2 3.2h-.4c-1.7 0-3.2-1.4-3.2-3.2v-8c0-1.7 1.4-3.2 3.2-3.2h.4c1.7 0 3.2 1.4 3.2 3.2z" />
  </svg>
);
export default IconGoFrame1;
