import * as React from "react";
const IconBusinessPartnership = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.7 45.7c-1.2-3.8-4.9-6-8.5-4.9-4.8 1.4-11.4 3.4-15.2 4.5 1.4-2.1 1.4-3.7.9-4.8-.8-1.9-3-2.8-4.9-2.8H23.2c-1.2 0-2.4-.5-3.6-1.6-.9-.9-2.1-1.3-3.4-1.3H7.6c-3.1 0-5.6 2.6-5.6 5.9v13.7c0 3.3 2.6 6 5.7 6h10.4c1.3 0 2.6-.5 3.6-1.4l.8.4c2.8 1.6 6.1 2.4 9.3 2.4q3 0 5.7-.9L56.9 55c4.9-1.9 5.7-6 4.8-9.3m-54 12.6c-2.1 0-3.7-1.8-3.7-4V40.6c0-2.1 1.6-3.9 3.6-3.9h8.6c.8 0 1.5.3 2 .8.5.4.9.8 1.4 1.1v19.3c-.5.3-1 .4-1.6.4zm48.6-5.2L37 59c-1.6.5-3.3.8-5.1.8-2.9 0-5.8-.7-8.4-2.2l-1.4-.8-.4-.1V39.4c.5.1 1 .2 1.6.2H34c1.2 0 2.6.5 3.1 1.6.5 1.2-.5 3-2.6 5.1l-.6.5.4.7c.4.8.5.8 10.4-2.1 4.6-1.3 9.1-2.7 9.1-2.7 2.5-.7 5.2.8 6.1 3.6.2 1.2.9 5.1-3.6 6.8M2.3 18.3c1.2 3.8 4.9 5.9 8.5 4.9 4.8-1.4 11.4-3.4 15.2-4.5-1.4 2.1-1.4 3.7-.9 4.8.8 1.9 3 2.8 4.9 2.8h10.7c1.2 0 2.4.5 3.6 1.6.9.9 2.1 1.3 3.4 1.3h8.6c3.1 0 5.6-2.6 5.6-5.9V9.7c0-3.3-2.6-6-5.7-6H45.9c-1.3 0-2.6.5-3.6 1.4l-.8-.4c-2.8-1.6-6.1-2.4-9.3-2.4q-3 0-5.7.9L7.1 9c-4.9 1.9-5.7 6-4.8 9.3m54-12.6c2.1 0 3.7 1.8 3.7 4v13.7c0 2.1-1.6 3.9-3.6 3.9h-8.6c-.8 0-1.5-.3-2-.8-.5-.4-.9-.8-1.4-1.1V6.1c.5-.3 1-.4 1.6-.4zM7.7 10.9 27 5c1.6-.5 3.3-.8 5.1-.8 2.9 0 5.8.7 8.4 2.2l1.4.8.4.1v17.2c-.5-.1-1-.2-1.6-.2H30.1c-1.2 0-2.6-.5-3.1-1.6-.5-1.2.5-3 2.6-5.1l.6-.5-.4-.7c-.2-.3-.3-.4-.7-.4-.9 0-3.1.7-9.7 2.6-4.6 1.3-9.1 2.7-9.1 2.7-2.5.7-5.2-.8-6.1-3.6-.3-1.2-1-5.1 3.5-6.8" />
  </svg>
);
export default IconBusinessPartnership;
