import * as React from "react";
const IconRhombus = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M35.9 16.4c-2.2-2.2-5.7-2.2-7.8 0L16.4 28.1c-2.2 2.2-2.2 5.7 0 7.8l11.7 11.7c1 1 2.4 1.6 3.9 1.6s2.9-.6 3.9-1.6l11.7-11.7c1-1 1.6-2.4 1.6-3.9s-.6-2.9-1.6-3.9zm10.3 18.1L34.5 46.2c-1.3 1.3-3.7 1.3-5 0L17.8 34.5c-1.4-1.4-1.4-3.6 0-5l11.7-11.7c.7-.7 1.6-1 2.5-1s1.8.3 2.5 1l11.7 11.7c.7.7 1 1.6 1 2.5s-.3 1.8-1 2.5" />
    <path d="M32 3C16 3 3 16 3 32s13 29 29 29 29-13 29-29S48 3 32 3m0 56C17.1 59 5 46.9 5 32S17.1 5 32 5s27 12.1 27 27-12.1 27-27 27" />
  </svg>
);
export default IconRhombus;
