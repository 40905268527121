import * as React from "react";
const IconMessageBlock = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M44.3 37.2c-1.9 1.9-2.9 4.4-2.9 7s1 5.1 2.9 7 4.4 2.9 7 2.9 5.1-1 7-2.9c3.9-3.9 3.9-10.2 0-14-3.9-3.9-10.1-3.9-14 0m-.9 7c0-2.1.8-4.1 2.3-5.6s3.6-2.3 5.6-2.3c1.7 0 3.4.6 4.8 1.7l-11 11s-.1.1-.1.2c-1-1.5-1.6-3.2-1.6-5m13.5 5.6c-1.5 1.5-3.5 2.3-5.6 2.3-1.8 0-3.5-.6-4.9-1.7.1 0 .1-.1.2-.1l11-11c2.4 3.1 2.1 7.6-.7 10.5" />
    <path d="M56.9 9.9H7.1C4.3 9.9 2 12.2 2 15v31.4c0 2.8 2.3 5.1 5.1 5.1h27.6c.6 0 1-.4 1-1s-.4-1-1-1H7.1c-1.7 0-3.1-1.4-3.1-3.1V15c0-.4.1-.8.2-1.1l25 15.2c.9.5 1.8.8 2.8.8s1.9-.3 2.8-.8l25-15.2c.1.3.2.7.2 1.1v14.4c0 .6.4 1 1 1s1-.4 1-1V15c0-2.8-2.3-5.1-5.1-5.1M33.8 27.3c-1.1.7-2.4.7-3.5 0l-24.7-15c.5-.3 1-.4 1.6-.4H57c.6 0 1.1.2 1.6.4z" />
  </svg>
);
export default IconMessageBlock;
