import * as React from "react";
const IconBookshelf1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.5 2h-39c-2.2 0-4 1.8-4 4v55c0 .6.4 1 1 1s1-.4 1-1v-7.7h43V61c0 .6.4 1 1 1s1-.4 1-1V6c0-2.2-1.8-4-4-4m2 4v25.9h-5.3l3.3-8.9c.2-.5-.1-1.1-.6-1.3s-1.1.1-1.3.6L46 31.8v.1h-5.2l4.3-10.8c.2-.5 0-1.1-.6-1.3-.5-.2-1.1 0-1.3.6l-4.6 11.5H33V4h18.5c1.1 0 2 .9 2 2m-41-2H31v27.9h-6.5v-9.2c0-.6-.4-1-1-1s-1 .4-1 1v9.2h-4.7v-9.2c0-.6-.4-1-1-1s-1 .4-1 1v9.2h-5.3V6c0-1.1.9-2 2-2m-2 47.3V33.9h43.4v17.3H10.5z" />
    <path d="M16.8 39.4c-.6 0-1 .4-1 1v4.9c0 .6.4 1 1 1s1-.4 1-1v-4.9c0-.6-.4-1-1-1" />
  </svg>
);
export default IconBookshelf1;
