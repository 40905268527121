import * as React from "react";
const IconSaline = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M42.7 2H21.3c-3.7 0-6.6 3-6.6 6.6v24.2c0 2.4 1.3 4.6 3.4 5.8l12.8 7V61c0 .6.4 1 1 1s1-.4 1-1V45.7l12.8-7c2.1-1.2 3.4-3.4 3.4-5.8V8.6C49.3 5 46.3 2 42.7 2M21.3 4h21.3c2.6 0 4.6 2.1 4.6 4.6v5.6H16.7V8.6c0-2.5 2.1-4.6 4.6-4.6m23.6 32.9L32 44l-12.9-7.1c-1.5-.8-2.4-2.4-2.4-4.1V16.3h30.6v16.6c0 1.7-.9 3.2-2.4 4" />
  </svg>
);
export default IconSaline;
