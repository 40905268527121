import * as React from "react";
const IconBloodPressureMonitor = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path  d="m23.5 26.5 1.5-5 1.5 5-1.5 2z" />
    <path
      
      fillRule="evenodd"
      d="M39.508 18.853A3.5 3.5 0 0 0 42 15.5v-6a3.5 3.5 0 1 0-7 0v6a3.5 3.5 0 0 0 2.507 3.357q.015 1.687.037 3.226v.001c.049 3.651.09 6.694-.177 9.224-.377 3.58-1.34 5.766-3.483 7.028-2.88 1.697-7.237 2.285-10.905 1.823-1.827-.23-3.389-.71-4.462-1.366-.434-.265-.765-.542-1.006-.828 1.824-.103 3.605-.433 5.054-1.022 1.777-.723 3.387-1.999 3.434-4.014A7.002 7.002 0 0 0 26 19.071v-5.949h-.003Q26 13.062 26 13c0-2.761-4.477-5-10-5S6 10.239 6 13q0 .061.003.122H6V32.84c0 1.985 1.665 3.253 3.43 3.985 1.64.68 3.715 1.06 5.8 1.152.388 1.064 1.23 1.903 2.244 2.523 1.399.855 3.264 1.393 5.255 1.644 3.966.499 8.804-.1 12.17-2.085 2.966-1.747 4.057-4.738 4.457-8.542.28-2.654.236-5.882.187-9.603q-.022-1.48-.035-3.061M38.5 8A1.5 1.5 0 0 1 40 9.5v6a1.5 1.5 0 0 1-3 0v-6A1.5 1.5 0 0 1 38.5 8M24 13c0 .215-.226.948-1.823 1.747C20.699 15.485 18.518 16 16 16s-4.7-.515-6.177-1.253C8.226 13.948 8 13.215 8 13s.226-.948 1.823-1.747C11.301 10.515 13.482 10 16 10s4.7.515 6.177 1.253C23.774 12.052 24 12.785 24 13m0 3c-1.825 1.215-4.729 2-8 2q-.46 0-.91-.02v17.988q.459.026.921.03c2.168.026 4.275-.287 5.8-.907 1.513-.617 2.142-1.384 2.186-2.162A7.001 7.001 0 0 1 24 19.07zm6 10a5 5 0 1 1-10 0 5 5 0 0 1 10 0"
      clipRule="evenodd"
    />
  </svg>
);
export default IconBloodPressureMonitor;
