import * as React from "react";
const IconMoneyProtection = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.6 7.5 33.3 1.9c-.8-.2-1.7-.2-2.5 0L10.4 7.5c-2.1.6-3.6 2.5-3.6 4.7V27c0 15.5 9.2 29.2 23.4 34.9.6.2 1.2.4 1.8.4s1.2-.1 1.8-.4c14.2-5.7 23.4-19.5 23.4-35V12.2c0-2.2-1.5-4.1-3.6-4.7m-.9 19.4c0 13.4-8.3 25.8-20.5 30.8h-.3c-12.5-5-20.6-17.1-20.6-30.7V12.2c0-.1.1-.3.2-.3l20.4-5.6h.2l20.4 5.6c.1 0 .2.2.2.3z" />
    <path d="M35.5 27.2h-7c-1.6 0-2.8-1.3-2.8-2.9s1.3-2.9 2.8-2.9h11.2c1.2 0 2.2-1 2.2-2.2s-1-2.2-2.2-2.2h-5.4v-1.5c0-1.2-1-2.2-2.3-2.2s-2.2 1-2.2 2.2V17h-1.3c-4 0-7.3 3.3-7.3 7.4s3.3 7.4 7.3 7.4h7c1.6 0 2.8 1.3 2.8 2.9s-1.3 2.9-2.8 2.9H24.4c-1.2 0-2.2 1-2.2 2.2s1 2.2 2.2 2.2h5.4v1.5c0 1.2 1 2.2 2.2 2.2s2.3-1 2.3-2.2V42h1.3c4 0 7.3-3.3 7.3-7.4s-3.3-7.4-7.4-7.4" />
  </svg>
);
export default IconMoneyProtection;
