import * as React from "react";
const IconLighthouse = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M46 17.3c.6 0 1-.4 1-1s-.4-1-1-1h-2.6c-.5-5.6-4.9-10-10.4-10.5V3c0-.6-.4-1-1-1s-1 .4-1 1v1.8c-5.5.5-10 4.9-10.4 10.5H18c-.6 0-1 .4-1 1s.4 1 1 1h2.4v23.1l-2.7 20.4c0 .3 0 .6.2.8s.5.3.8.3h8.9c.1 0 .2.1.3.1H36c.1 0 .2 0 .3-.1h8.9c.3 0 .6-.1.8-.3s.3-.5.2-.8l-2.7-20.4V17.3zm-23.6 0h19v22.2h-19zm9.5-10.5c4.9 0 9 3.7 9.4 8.5H22.5c.5-4.8 4.5-8.5 9.4-8.5M35 59.9h-6.2v-5.3c0-1.1.9-2 2-2H33c1.1 0 2 .9 2 2zm2 0v-5.3c0-2.2-1.8-4-4-4h-2.1c-2.2 0-4 1.8-4 4v5.3h-7l2.5-18.5h19.2L44 59.9z" />
    <path d="M31.9 23.6c-2.3 0-4.2 1.9-4.2 4.2s1.9 4.2 4.2 4.2 4.2-1.9 4.2-4.2-1.8-4.2-4.2-4.2m0 6.5c-1.2 0-2.2-1-2.2-2.2s1-2.2 2.2-2.2 2.2 1 2.2 2.2c.1 1.2-.9 2.2-2.2 2.2" />
  </svg>
);
export default IconLighthouse;
