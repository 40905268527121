import * as React from "react";
const IconBible = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M48.9 2H15.1c-3.2 0-5.8 2.6-5.8 5.8v48.3c0 3.2 2.6 5.8 5.8 5.8h33.8c3.2 0 5.8-2.6 5.8-5.8V7.8c0-3.2-2.6-5.8-5.8-5.8M11.3 56.2V7.8C11.3 5.7 13 4 15.1 4h5.3v56h-5.3c-2.1 0-3.8-1.7-3.8-3.8m41.4 0c0 2.1-1.7 3.8-3.8 3.8H22.4V4h26.5c2.1 0 3.8 1.7 3.8 3.8z" />
    <path d="M42.9 24.3h-5.1v-4.7c0-.6-.4-1-1-1s-1 .4-1 1v4.7h-5.1c-.6 0-1 .4-1 1s.4 1 1 1h5.1v13c0 .6.4 1 1 1s1-.4 1-1v-13h5.1c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconBible;
