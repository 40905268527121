import * as React from "react";
const IconDatabase4 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.7 50.5 55.4 45c-.1-.1-.2-.1-.3-.2h-.4c-.1 0-.3 0-.4.1-.1 0-.2.1-.3.1l-5.4 5.5c-.4.4-.4 1 0 1.4s1 .4 1.4 0l3.7-3.7v9.7c0 .6.4 1 1 1s1-.4 1-1v-9.7l3.6 3.7c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.3.4-1 0-1.4M39 44.1c-3.1.5-6.3.7-9.6.7C15 44.7 5 40.5 5 36.7v-6.2c3.9 3.6 13.1 6.1 24.3 6.1 11.3 0 20.4-2.4 24.3-6.1v5.1c0 .6.4 1 1 1s1-.4 1-1V15.2c0-5.7-11.3-10-26.3-10S3 9.5 3 15.2v31.900000000000002C3 52.6 14.6 57 29.3 57c2.3 0 4.6-.1 6.8-.3.5-.1.9-.5.9-1.1-.1-.5-.5-.9-1.1-.9-2.1.2-4.4.3-6.6.3C15.4 55 5 50.7 5 46.9v-6.2c3.9 3.6 13.1 6.1 24.3 6.1 3.4 0 6.7-.2 9.9-.7.5-.1.9-.6.8-1.1 0-.6-.5-1-1-.9m-9.7-9.5C15 34.6 5 30.3 5 26.5v-7.4c3.9 3.6 13.1 6.1 24.3 6.1 11.3 0 20.4-2.4 24.3-6.1v7.5c-.1 3.8-10 8-24.3 8m0-27.5c14.3 0 24.3 4.2 24.3 8v.1c-.2 3.8-10.1 8-24.3 8C15 23.2 5 18.9 5 15.1s10-8 24.3-8" />
  </svg>
);
export default IconDatabase4;
