import * as React from "react";
const IconCd = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 513 342"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 0h513v342H0z" />
    <path  d="M513 66.9V0H411.7L0 274.4V342h100.3z" />
    <path  d="M513 0v40.1L60.2 342H0v-40.8L451.8 0z" />
    <path
      
      d="m93.6 31.2 16.3 50.4H163l-42.9 31.2 16.4 50.5-42.9-31.2-43 31.2L67 112.8 24.1 81.6h53.1z"
    />
  </svg>
);
export default IconCd;
