import * as React from "react";
const IconXray = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M29 9a5 5 0 1 1-10 0 5 5 0 0 1 10 0m-2 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0"
      clipRule="evenodd"
    />
    <path
      
      d="M17 41v-7h2v7a1 1 0 0 0 1 1h.087a1 1 0 0 0 .995-.91l.645-7.09h2.008l-.66 7.272a3 3 0 0 1-.097.53A3 3 0 0 1 20.086 44H20a3 3 0 0 1-3-3M26.917 41.09 26.272 34h-2.008l.661 7.272a3 3 0 0 0 .097.53A3 3 0 0 0 27.913 44H28a3 3 0 0 0 3-3v-7h-2v7a1 1 0 0 1-1 1h-.087a1 1 0 0 1-.996-.91"
    />
    <path
      
      fillRule="evenodd"
      d="M11 15a1 1 0 0 0-1 1v15a1 1 0 0 0 1 1h26a1 1 0 0 0 1-1V16a1 1 0 0 0-1-1zm1 2v13h7.5c0-.558.26-1.043.528-1.4a4.9 4.9 0 0 1 1.051-.998A6.3 6.3 0 0 1 23 26.668V24.5h-.8c-.603 0-1.447.364-2.257.855a12.5 12.5 0 0 0-1.285.898l-.015.013-.003.002L18 25.5l-.64-.768.001-.002.003-.002.007-.006.026-.02q.03-.027.09-.072a14.512 14.512 0 0 1 1.42-.985c.84-.51 2.096-1.145 3.293-1.145h.8v-2h-.5c-.57 0-1.31.2-1.97.44a12 12 0 0 0-1.069.447l-.012.007h-.002L19 20.5l-.447-.895.003-.001.007-.003.021-.011a9 9 0 0 1 .346-.16c.227-.1.546-.235.916-.37.713-.26 1.723-.56 2.654-.56h.5V17zm13 7.5h1.1c.749 0 1.519.366 2.163.819a7 7 0 0 1 .986.841l.008.01.743-.67.743-.67-.002-.001-.002-.003-.007-.008-.022-.023-.075-.079a9.053 9.053 0 0 0-1.223-1.035c-.78-.547-1.96-1.181-3.312-1.181H25v-2h.75c.694 0 1.37.201 1.893.422a6 6 0 0 1 .8.408l.003.002L29 20.5l.554-.832-.001-.001-.003-.002-.007-.005-.02-.013-.067-.042a8.163 8.163 0 0 0-1.037-.527c-.663-.28-1.613-.578-2.669-.578H25V17h11v13h-7.5c0-.562-.268-1.055-.536-1.413a5 5 0 0 0-1.063-1.02A5.9 5.9 0 0 0 25 26.638zm1.493 5.5a1 1 0 0 0-.129-.212 3 3 0 0 0-.64-.604c-.576-.42-1.23-.672-1.692-.684-.544.046-1.227.325-1.787.727-.28.2-.49.404-.62.577a1 1 0 0 0-.119.196z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconXray;
