import * as React from "react";
const IconFilterslider = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 65 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.5 48.6h-7.1c-.5-5.2-4.9-9.3-10.3-9.3s-9.8 4.1-10.3 9.3H4.5c-.6 0-1 .4-1 1s.4 1 1 1h28.3c.5 5.2 4.9 9.3 10.3 9.3s9.8-4.1 10.3-9.3h7.1c.6 0 1-.4 1-1s-.4-1-1-1m-17.4 9.2c-4.6 0-8.3-3.7-8.3-8.3s3.7-8.3 8.3-8.3 8.3 3.7 8.3 8.3-3.7 8.3-8.3 8.3M4.5 15.4h36.4c.5 5.2 4.9 9.3 10.3 9.3 5.7 0 10.3-4.6 10.3-10.3S56.9 4.2 51.2 4.2c-5.4 0-9.8 4.1-10.3 9.3H4.5c-.6 0-1 .4-1 1s.4.9 1 .9m46.7-9.2c4.6 0 8.3 3.7 8.3 8.3s-3.7 8.3-8.3 8.3-8.3-3.7-8.3-8.3 3.7-8.3 8.3-8.3M60.5 31H35.8c-.5-5.2-4.9-9.3-10.3-9.3s-9.8 4.1-10.3 9.3H4.5c-.6 0-1 .4-1 1s.4 1 1 1h10.6c.5 5.2 4.9 9.3 10.3 9.3s9.8-4.1 10.3-9.3h24.7c.6 0 1-.4 1-1 .1-.6-.3-1-.9-1m-35 9.3c-4.6 0-8.3-3.7-8.3-8.3s3.7-8.3 8.3-8.3 8.3 3.7 8.3 8.3-3.7 8.3-8.3 8.3" />
  </svg>
);
export default IconFilterslider;
