import * as React from "react";
const IconLifeGuard = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.2 46.3C59.7 42 61 37 61 32s-1.3-10-3.8-14.3l.1-.1c3-3 3-7.9 0-10.9-1.5-1.5-3.4-2.3-5.4-2.3s-4 .8-5.4 2.3l-.1.1C42 4.3 37 3 32 3S22 4.3 17.7 6.8l-.1-.1c-1.5-1.5-3.4-2.3-5.4-2.3s-4 .8-5.4 2.3c-1.5 1.5-2.3 3.4-2.3 5.4 0 2.1.8 4 2.3 5.4l.1.1C4.3 22 3 27 3 32s1.3 10 3.8 14.3l-.1.1c-1.5 1.5-2.3 3.4-2.3 5.4s.8 4 2.3 5.4c1.5 1.5 3.4 2.3 5.4 2.3 2.1 0 4-.8 5.4-2.3l.1-.1C22 59.7 27 61 32 61s10-1.3 14.3-3.8l.1.1c1.5 1.5 3.4 2.3 5.4 2.3s4-.8 5.4-2.3c3.1-3 3.1-7.9 0-11M59 32c0 4.5-1.1 8.9-3.3 12.9L42.9 32.1l-.2.2v-.5l.2.2 12.8-12.8C57.9 23.1 59 27.5 59 32M51.8 6.5c1.5 0 2.9.6 4 1.7 2.2 2.2 2.2 5.8 0 8L42.5 29.6c-.4-1.9-1.4-3.7-2.9-5.2s-3.3-2.4-5.1-2.9L47.8 8.1c1.1-1 2.5-1.6 4-1.6M38.2 38.2c-3.4 3.4-9 3.4-12.4 0s-3.4-9 0-12.4c1.7-1.7 4-2.6 6.2-2.6 2.3 0 4.5.9 6.2 2.6 3.4 3.4 3.4 9 0 12.4M32 5c4.5 0 8.9 1.1 12.8 3.3L32.1 21.1l.1.1h-.4l.1-.1L19.2 8.3C23.1 6.1 27.5 5 32 5M6.5 12.2c0-1.5.6-2.9 1.7-4 2.2-2.2 5.8-2.2 8 0l13.3 13.3c-1.9.4-3.7 1.4-5.2 2.9s-2.4 3.3-2.9 5.2L8.1 16.2c-1-1.1-1.6-2.5-1.6-4M5 32c0-4.5 1.1-8.9 3.3-12.8L21.1 32l.1-.1v.4l-.1-.1L8.3 44.9C6.1 40.9 5 36.5 5 32m7.2 25.5c-1.5 0-2.9-.6-4-1.7s-1.7-2.5-1.7-4 .6-2.9 1.7-4l13.3-13.3c.4 1.9 1.4 3.7 2.9 5.1 1.5 1.5 3.3 2.4 5.2 2.9L16.2 55.9c-1.1 1-2.5 1.6-4 1.6M32 59c-4.5 0-8.9-1.1-12.9-3.3l12.8-12.8-.2-.2h.5l-.2.2 12.8 12.8C40.9 57.9 36.5 59 32 59m23.9-3.1c-1.1 1.1-2.5 1.7-4 1.7s-2.9-.6-4-1.7L34.4 42.5c1.9-.4 3.7-1.4 5.2-2.9s2.4-3.3 2.9-5.2l13.4 13.4c2.2 2.2 2.2 5.8 0 8.1" />
  </svg>
);
export default IconLifeGuard;
