import * as React from "react";
const IconCommentsReply = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56 7.8H8c-3.4 0-6.2 2.8-6.2 6.2v37.7c0 1.6.9 3.1 2.4 3.8.6.3 1.2.4 1.8.4 1 0 1.9-.3 2.7-1l8.5-7h5.1c1.2 0 2.3-1 2.3-2.3s-1-2.3-2.3-2.3h-5.9c-.5 0-1 .2-1.4.5l-8.6 7.1V14c-.1-1 .6-1.7 1.6-1.7h48c.9 0 1.7.8 1.7 1.7v31.7c0 1.2 1 2.3 2.3 2.3s2.3-1 2.3-2.3V14c0-3.4-2.8-6.2-6.3-6.2" />
    <path d="M53 43.6c-2.3-2.3-5.3-3.9-8.7-4.6v-2.2c0-1.3-.7-2.5-1.9-3-1.2-.6-2.5-.4-3.6.4l-10.1 8.1c-.8.6-1.3 1.6-1.3 2.6s.5 2 1.3 2.6l10.1 8.1c.6.5 1.4.7 2.1.7q.75 0 1.5-.3c1.2-.6 1.9-1.7 1.9-3v-1.2c2.1.5 4.5 1.6 7.1 3.2 1.1.7 2.4.7 3.5 0 1.1-.6 1.7-1.8 1.7-3.1 0-.8-.1-1.4-.2-1.8-.6-2.7-1.5-4.6-3.4-6.5M43.9 47c-1-.2-2 .1-2.8.8q-1.2 1.05-1.2 2.7v.1l-7.1-5.7 7.1-5.7v.7c0 1.7 1.2 3.1 2.9 3.4 2.8.5 5.2 1.7 7.1 3.5 1 1 1.5 1.8 1.9 3-2.4-1.2-5.1-2.3-7.9-2.8M9.6 24.9c0 3.4 2.7 6.1 6.1 6.1s6.1-2.7 6.1-6.1-2.7-6.1-6.1-6.1-6.1 2.7-6.1 6.1m7.8 0c0 .9-.7 1.6-1.6 1.6s-1.6-.7-1.6-1.6.7-1.6 1.6-1.6c.9-.1 1.6.7 1.6 1.6M32 31c3.4 0 6.1-2.7 6.1-6.1s-2.7-6.1-6.1-6.1-6.1 2.7-6.1 6.1S28.6 31 32 31m0-7.8c.9 0 1.6.7 1.6 1.6s-.7 1.6-1.6 1.6-1.6-.7-1.6-1.6c0-.8.7-1.6 1.6-1.6M48.2 31c3.4 0 6.1-2.7 6.1-6.1s-2.7-6.1-6.1-6.1-6.1 2.7-6.1 6.1 2.8 6.1 6.1 6.1m0-7.8c.9 0 1.6.7 1.6 1.6s-.7 1.6-1.6 1.6-1.6-.7-1.6-1.6c0-.8.7-1.6 1.6-1.6" />
  </svg>
);
export default IconCommentsReply;
