import * as React from "react";
const IconArrowsMergeAltV = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32.6 36.1c-.4-.3-.9-.3-1.3 0l-11.7 9.8c-.4.4-.5 1-.1 1.4s1 .5 1.4.1L31 39v20H20.7c-.6 0-1 .4-1 1s.4 1 1 1h22.5c.6 0 1-.4 1-1s-.4-1-1-1H33V39l10 8.4c.2.2.4.2.6.2.3 0 .6-.1.8-.4.4-.4.3-1.1-.1-1.4zM31.4 27.9c.2.2.4.2.6.2s.5-.1.6-.2l11.7-9.8c.4-.4.5-1 .1-1.4s-1-.5-1.4-.1L33 25V5h10.3c.6 0 1-.4 1-1s-.4-1-1-1H20.7c-.6 0-1 .4-1 1s.4 1 1 1H31v20l-10-8.4c-.4-.4-1.1-.3-1.4.1-.4.4-.3 1.1.1 1.4z" />
  </svg>
);
export default IconArrowsMergeAltV;
