import * as React from "react";
const IconTriangleAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.5 58.3h-55c-.4 0-.7-.2-.9-.5s-.2-.7 0-1L31.1 6.2c.4-.6 1.4-.6 1.8 0l27.5 50.6c.2.3.2.7 0 1s-.5.5-.9.5m-53.3-2h51.6L32 8.8z" />
  </svg>
);
export default IconTriangleAlt;
