import React, { useState, useRef } from 'react';
import { BringzzAccordion, BringzzButton, BringzzPromoBanner, BringzzText } from "@bringzz/components";
import { useNavigation } from 'context/NavigationContext';
import {
    BringzzPageContainer,
    BringzzIcon,
    BringzzBasketProduct,
    BringzzProgressBar
} from "@bringzz/components";
import BikeImage from 'assets/images/Bike.png'
import RocketImage from 'assets/images/Rocket.png'
import { BringzzOrderFlow } from "@bringzz/components";
// import { FLOW_TYPES, PERSPECTIVES } from "@bringzz/constants";

const OrderPlacePage = () => {
    const { backPress } = useNavigation();
    const [activeStep, setActiveStep] = useState(1)
    const orderFlowRef = useRef();
    const [currentScreen, setCurrentScreen] = useState('preparing');

    const [products, setProducts] = useState([
        {
            name: 'Product Name 1',
            manufacturer: 'Manufacturer',
            amount: '84 Stk | Kapseln',
            quantity: '10',
            imgUrl: '/product.png',
            price: 9,
            inhalt: ""
        },
        {
            name: 'Product Name 2',
            manufacturer: 'Manufacturer 2',
            amount: '12 Stk | Kapseln',
            quantity: '3',
            imgUrl: '/product.png',
            price: 8,
            inhalt: "Prescription attached"
        }
    ]);

    return (
        <>
            {/* <BringzzOrderFlow
                ref={orderFlowRef}
                flowType={FLOW_TYPES.SHOPPER}
                perspective={PERSPECTIVES.PHARMACY}
                currentScreen={currentScreen}
                onScreenChange={setCurrentScreen}
            /> */}
            <BringzzPageContainer
                className="bg-white pb-12"
                header={{
                    left: (
                        <BringzzIcon
                            size='22'
                            icon='IconClose'
                            folder='LineIcons'
                            className='cursor-pointer'
                            onClick={backPress}
                        />
                    ),
                    right: (
                        <BringzzButton size='sm' className='border border-black'>Help</BringzzButton>
                    )
                }}
                footer={
                    <div className='flex justify-center space-x-4 border-b py-2 bg-white'>
                        <BringzzButton size='lg' className="bg-magic-lilac" onClick={backPress}>Cancel reservation</BringzzButton>
                    </div>
                }
                footerHover={true}
                authenticated={true}
            >

                <div className='px-6'>
                    <div className='py-6'>
                        <BringzzText tag='h1' className='text-center'>Start preparing order...</BringzzText>
                        <BringzzText tag='h3' className='text-center font-normal'>Shopper arriving at XX:XX</BringzzText>
                    </div>

                    <BringzzProgressBar state={activeStep} mode={"custom"} segments={
                        [
                            {
                                content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
                                    <div className='flex justify-center'>
                                        <img className='h-24 w-24' src={RocketImage} alt='progress ing'></img>
                                    </div>
                                    <BringzzText tag='h4' className={"font-normal"}>Time to prepare Alexander’s order!</BringzzText>
                                    <BringzzText tag='h5' className={"font-normal"}>Lorem ipsum dolor sit amet est adiscibitur.</BringzzText>
                                    <BringzzButton className='bg-magic-lilac mt-5' onClick={() => setActiveStep(2)}>Process order</BringzzButton>
                                </div>
                            },
                            {
                                content: <div className='flex flex-col text-center items-center border border-black rounded-lg pb-4 my-6'>
                                    <div className='flex justify-center'>
                                        <img className='h-24 w-24' src={RocketImage} alt='progress ing'></img>
                                    </div>
                                    <BringzzText tag='h4' className={"font-normal"}>Time to prepare Alexander’s order!</BringzzText>
                                    <BringzzText tag='h5' className={"font-normal"}>Lorem ipsum dolor sit amet est adiscibitur.</BringzzText>
                                    <BringzzButton className='bg-magic-lilac mt-5' disabled={true}>In Progress</BringzzButton>
                                    <BringzzPromoBanner className='' header={"This order is currently being processed by Employee X and therefore locked."}></BringzzPromoBanner>
                                </div>
                            },
                            { content: <div>text2</div> },
                        ]
                    } />

                    <div className='flex space-x-4 border-t border-b py-2'>
                        <div>
                            <BringzzIcon
                                folder='CustomIcons'
                                icon='IconMountain'
                                size='22'
                                className='bg-slate-100 p-3 rounded-full' />
                        </div>
                        <div className='flex w-full justify-between'>
                            <div>
                                <BringzzText tag='h3'>Customer</BringzzText>
                                <BringzzText tag='h4' className={"font-normal"}>Joe Doe.</BringzzText>
                            </div>
                            <div className='flex items-center space-x-2'>
                                <BringzzIcon
                                    folder='LineIcons'
                                    icon='IconPhone'
                                    size='22' />
                                <BringzzIcon
                                    folder='LineIcons'
                                    icon='IconPopup'
                                    size='22' />
                            </div>
                        </div>
                    </div>
                    <div className='flex space-x-4 border-b py-2'>
                        <div>
                            <BringzzIcon
                                folder='LineIcons'
                                icon='IconBox9'
                                size='22'
                                className='p-3 rounded-full' />
                        </div>
                        <div className='flex w-full'>
                            <div>
                                <BringzzText tag='h3'>Order Type</BringzzText>
                                <BringzzText tag='h4' className={"font-normal"}>Bringzz Shopper</BringzzText>
                            </div>
                        </div>
                    </div>
                    <div className='flex space-x-4 border-b py-2'>
                        <div>
                            <BringzzIcon
                                folder='LineIcons'
                                icon='IconFileName'
                                size='22'
                                className='px-3 py-2' />
                        </div>
                        <div className='flex w-full justify-between'>
                            <div className='flex items-center'>
                                <BringzzText tag='h3'>Prescription</BringzzText>
                            </div>
                            <div className='flex items-center space-x-2'>
                                <BringzzIcon
                                    folder='LineIcons'
                                    icon='IconEye'
                                    size='22' />
                                <BringzzIcon
                                    folder='LineIcons'
                                    icon='IconBarcode'
                                    size='22' />
                            </div>
                        </div>
                    </div>
                    <BringzzAccordion
                        title={<div className='flex space-x-4'>
                            <div>
                                <BringzzIcon
                                    folder='LineIcons'
                                    icon='IconCheckBoxAlt'
                                    size='22'
                                    className='p-3 rounded-full' />
                            </div>
                            <div>
                                <BringzzText tag='h3'>Order Total</BringzzText>
                                <BringzzText tag='h4' className={"font-normal"}>€XX.XX | X Items</BringzzText></div>
                        </div>}
                    >
                        {products.map((product, index) => {
                            return (
                                <BringzzBasketProduct
                                    key={index + 'product'}
                                    product={product}
                                    mode={"view"}
                                />
                            );
                        })}
                        <div className='py-3'>
                            <div className='flex justify-between'>
                                <BringzzText tag='h4' className="font-normal">Subtotal</BringzzText>
                                <BringzzText tag='h4' className="font-normal">€XX.XX</BringzzText>
                            </div>
                            <div className='flex justify-between'>
                                <BringzzText tag='h4' className="font-normal">Delivery fee & handling</BringzzText>
                                <BringzzText tag='h4' className="font-normal">€XX.XX</BringzzText>
                            </div>
                            <div className='flex justify-between'>
                                <div className='flex space-x-1 items-center'>
                                    <BringzzText tag='h4'>Total</BringzzText>
                                    <BringzzIcon
                                        icon="IconQuestionCircle"
                                        folder="LineIcons"
                                        className="h-4 w-4 cursor-pointer text-magic-lilac"
                                        size="16"
                                    ></BringzzIcon>
                                </div>
                                <BringzzText tag='h4'>€XX.XX</BringzzText>
                            </div>
                        </div>
                    </BringzzAccordion>
                </div>
            </BringzzPageContainer >
        </>
    );
};

export default OrderPlacePage;
