import * as React from "react";
const IconSpeechBubble17 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M49.5 2h-35c-2.8 0-5.1 2.3-5.1 5.1V49c0 2.8 2.3 5.1 5.1 5.1h11.2l5.5 7.5c.2.3.5.4.8.4s.6-.1.8-.4l5.7-7.5h11.1c2.8 0 5.1-2.3 5.1-5.1V7.1c0-2.8-2.3-5.1-5.2-5.1m3.2 47c0 1.7-1.4 3.1-3.1 3.1H37.9c-.3 0-.6.1-.8.4L32 59.3l-5-6.8c-.2-.3-.5-.4-.8-.4H14.5c-1.7 0-3.1-1.4-3.1-3.1V7.1c0-1.7 1.4-3.1 3.1-3.1h35c1.7 0 3.1 1.4 3.1 3.1V49z" />
    <path d="M27.7 19.8h8.4c.6 0 1-.4 1-1s-.4-1-1-1h-8.4c-.6 0-1 .4-1 1s.5 1 1 1M40.5 27H23.3c-.6 0-1 .4-1 1s.4 1 1 1h17.2c.6 0 1-.4 1-1s-.4-1-1-1M36.1 36.2h-8.4c-.6 0-1 .4-1 1s.4 1 1 1h8.4c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconSpeechBubble17;
