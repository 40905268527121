import * as React from "react";
const IconExitCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M45.2 3H33.9c-2 0-3.5 1.6-3.5 3.5v6.9c0 .6.4 1 1 1s1-.4 1-1V6.5c0-.9.7-1.5 1.5-1.5h11.3c3.1 0 5.6 2.5 5.6 5.6v42.9c0 3.1-2.5 5.6-5.6 5.6H33.9c-.9 0-1.5-.7-1.5-1.5v-6.9c0-.6-.4-1-1-1s-1 .4-1 1v6.9c0 2 1.6 3.5 3.5 3.5h11.3c4.2 0 7.6-3.4 7.6-7.6V10.6C52.7 6.4 49.3 3 45.2 3" />
    <path d="M14.6 33h20.9c.6 0 1-.4 1-1s-.4-1-1-1H14.7l8.3-8.4c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-10 10.2c-.4.4-.4 1 0 1.4l10 10.2c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4z" />
  </svg>
);
export default IconExitCopy;
