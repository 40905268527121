import React, { useState, useRef, useEffect, useCallback } from "react";
import BringzzImage from "../BringzzImage";
import BringzzText from "../BringzzText"
import BringzzButton from "../BringzzButton"
import classNames from "classnames";

const BringzzOnboarding = ({
    images,
    autoSlide = false,
    interval = 3000,
    onNextSlide,
    topLeft,
    topRight,
    bottomLeft,
    bottomRight,
    topCenter,
    bottomCenter,
    onNextClick,
    nextButtonClasses
}) => {
    const [currentIndex, setCurrentIndex] = useState(1);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [currentTranslate, setCurrentTranslate] = useState(0);
    const [isPaused, setIsPaused] = useState(false);
    const sliderRef = useRef(null);

    const loopedImages = [images[images.length - 1], ...images, images[0]];

    const goToIndex = useCallback((index, isSilent = false) => {
        const newTranslate = -index * sliderRef.current.offsetWidth;
        if (isSilent) {
            setCurrentTranslate(newTranslate);
            requestAnimationFrame(() => {
                setCurrentIndex(index);
            });
        } else {
            setCurrentIndex(index);
            setCurrentTranslate(newTranslate);
        }
    }, []);

    useEffect(() => {
        goToIndex(currentIndex);
    }, [currentIndex, goToIndex]);

    useEffect(() => {
        if (autoSlide && !isPaused && !isDragging) {
            const timer = setTimeout(() => {
                const newIndex = currentIndex < images.length ? currentIndex + 1 : 1;
                goToIndex(newIndex);
            }, interval);
            return () => clearTimeout(timer);
        }
    }, [currentIndex, autoSlide, isPaused, isDragging, interval, images.length]);

    const handleDragStart = (e) => {
        setIsDragging(true);
        setStartX(e.type.includes("mouse") ? e.pageX : e.touches[0].clientX);
    };

    const handleDragMove = (e) => {
        if (isDragging) {
            const currentPosition = e.type.includes("mouse")
                ? e.pageX
                : e.touches[0].clientX;
            const newTranslate =
                currentPosition - startX - currentIndex * sliderRef.current.offsetWidth;
            setCurrentTranslate(newTranslate);
        }
    };

    const handleDragEnd = () => {
        setIsDragging(false);
        const movedBy =
            currentTranslate + currentIndex * sliderRef.current.offsetWidth;
        determineNextSlide(movedBy);
    };

    const determineNextSlide = (movedBy) => {
        let newIndex = currentIndex;
        if (movedBy < -100) {
            newIndex = currentIndex + 1;
        } else if (movedBy > 100) {
            newIndex = currentIndex - 1;
        }
        adjustForLooping(newIndex);
    };

    const adjustForLooping = (newIndex) => {
        if (newIndex >= loopedImages.length - 1) {
            goToIndex(newIndex, true);
            setTimeout(() => goToIndex(1, true), 300);
        } else if (newIndex <= 0) {
            goToIndex(newIndex, true);
            setTimeout(() => goToIndex(images.length, true), 300);
        } else {
            goToIndex(newIndex);
        }
    };

    const eventHandlers = {
        onMouseDown: handleDragStart,
        onMouseMove: handleDragMove,
        onMouseUp: handleDragEnd,
        onTouchStart: handleDragStart,
        onTouchMove: handleDragMove,
        onTouchEnd: handleDragEnd,
        onMouseEnter: () => setIsPaused(true),
        onMouseLeave: () => {
            setIsPaused(false);
            if (isDragging) handleDragEnd();
        },
    };

    const goToNextSlide = () => {
        const newIndex = currentIndex < images.length ? currentIndex + 1 : 1;
        if (onNextSlide) {
            onNextSlide(newIndex);
        }
        goToIndex(newIndex);
    };

    useEffect(() => {
        if (onNextSlide) {
            onNextSlide(goToNextSlide);
        }
    }, [onNextSlide, goToNextSlide]);

    const backgroundColorClass = [2, 4, 5].includes(currentIndex) ? "bg-magic-lilac" : "";

    const handleNextClick = () => {
        if (currentIndex !== images.length) {
            goToNextSlide()
        } else {
            onNextClick()
        }
    }

    return (
        <div className={`w-full overflow-hidden relative ${backgroundColorClass}`}>
            {topLeft && <div className="absolute top-2 left-2 z-10">{topLeft}</div>}
            {topCenter && (
                <div className="absolute top-2 z-10 left-1/2 transform -translate-x-1/2">
                    {topCenter}
                </div>
            )}
            {topRight && (
                <div className="absolute top-2 right-2 z-10">{topRight}</div>
            )}
            {bottomLeft && (
                <div className="absolute bottom-8 left-2 z-10">{bottomLeft}</div>
            )}
            {bottomCenter && (
                <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 z-10">
                    {bottomCenter}
                </div>
            )}
            {bottomRight && (
                <div className="absolute bottom-8 right-2 z-10">{bottomRight}</div>
            )}
            <div
                ref={sliderRef}
                className="w-full overflow-hidden relative"
                {...eventHandlers}
            >
                <div
                    className="flex relative"
                    style={{
                        transform: `translateX(${currentTranslate}px)`,
                        transition: isDragging ? "none" : "transform 0.5s ease-out",
                    }}
                >
                    {loopedImages.map((img, index) => (
                        <BringzzImage
                            key={index}
                            src={img.url}
                            alt={`Slide ${index}`}
                            className="w-full object-cover flex-shrink-0"
                            draggable="false"
                            onClick={() => {
                                if (loopedImages[index].onClick && !isDragging)
                                    loopedImages[index].onClick();
                            }}
                        />
                    ))}
                </div>
                <div className="w-full flex items-center justify-center mt-2 px-4">
                    {images.map((img, index) => (
                        <div
                            key={index}
                            className={`flex-1 hover:cursor-pointer h-0.5 ${index + 1 === currentIndex ?
                                (index + 1) % 2 === 0 ? "bg-midnight-blue" : "bg-magic-lilac"
                                : "bg-sand"
                                } `}
                            onClick={() => goToIndex(index + 1)}
                        ></div>
                    ))}
                </div>
            </div>
            <div className="text-center mt-10 px-6 space-y-6">
                <BringzzText tag="h1">
                    {images[(currentIndex - 1) % images.length].title}
                </BringzzText>
                <BringzzText tag="h4" className="font-normal text-justify">
                    {images[(currentIndex - 1) % images.length].subtitle}
                </BringzzText>
            </div>
            {onNextClick && <div className="flex justify-center">
                <BringzzButton className={
                    classNames(
                        currentIndex % 2 !== 0 ? "bg-magic-lilac" : "bg-midnight-blue text-white",
                        nextButtonClasses
                    )
                }
                    onClick={handleNextClick}>Continue</BringzzButton>
            </div>}
        </div>
    );
};

export default BringzzOnboarding;
