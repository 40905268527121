import * as React from "react";
const IconHeartCheck = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.7 60.3 42.8 41.4c-.1-.1-.1-.1-.2-.1 8.4-9.2 8.3-23.5-.6-32.4-9.1-9.1-24-9.1-33.1 0s-9.1 24 0 33.1c4.6 4.6 10.5 6.8 16.5 6.8 5.7 0 11.4-2.1 15.8-6.2 0 .1.1.1.1.2l18.9 18.9c.2.2.5.3.7.3s.5-.1.7-.3c.5-.4.5-1 .1-1.4M10.3 40.5C2 32.2 2 18.6 10.3 10.3 14.4 6.1 19.9 4 25.4 4s11 2.1 15.1 6.3c8.3 8.3 8.3 21.9 0 30.2s-21.9 8.3-30.2 0" />
    <path d="M30.1 15.8c-1.5 0-2.8.5-4 1.4-.3.2-.5.4-.8.7-.2-.2-.5-.5-.8-.7-1.2-.9-2.5-1.4-4-1.4-2 0-3.8.8-5.1 2.2s-2 3.2-2 5.3.8 3.9 2.4 5.9c1.4 1.6 3.3 3.3 5.4 5.1l.1.1c.7.6 1.6 1.3 2.4 2.1q.6.6 1.5.6c.5 0 1.1-.2 1.5-.6.9-.8 1.7-1.5 2.5-2.1 2.2-1.9 4.1-3.5 5.5-5.1 1.6-1.9 2.4-3.8 2.4-5.9 0-2-.7-3.9-2-5.3-1.2-1.5-3-2.3-5-2.3m3.2 12c-1.3 1.5-3.1 3.1-5.3 4.9-.7.6-1.6 1.4-2.5 2.1-.1.1-.3.1-.4 0-.9-.8-1.7-1.5-2.5-2.1-2.1-1.8-4-3.4-5.3-4.9-1.3-1.6-1.9-3-1.9-4.6 0-1.5.5-2.9 1.5-3.9.9-1 2.2-1.6 3.6-1.6 1 0 2 .3 2.8 1l.9.9c.5.6 1.6.6 2.1 0 .3-.3.6-.7.9-.9.8-.6 1.8-1 2.8-1 1.4 0 2.7.6 3.6 1.6s1.4 2.4 1.4 3.9c.2 1.6-.4 3.1-1.7 4.6" />
  </svg>
);
export default IconHeartCheck;
