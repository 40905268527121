import * as React from "react";
const IconTargetRevenueCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 31h-6.4C54.1 19.3 44.7 9.9 33 9.4V3c0-.6-.4-1-1-1s-1 .4-1 1v6.4C19.3 9.9 9.9 19.3 9.4 31H3c-.6 0-1 .4-1 1s.4 1 1 1h6.4c.5 11.7 9.9 21.1 21.6 21.6V61c0 .6.4 1 1 1s1-.4 1-1v-6.4c11.7-.5 21.1-9.9 21.6-21.6H61c.6 0 1-.4 1-1s-.4-1-1-1M33 52.6v-2.2c0-.6-.4-1-1-1s-1 .4-1 1v2.2c-10.6-.5-19.1-9-19.6-19.6h2.2c.6 0 1-.4 1-1s-.4-1-1-1h-2.2c.5-10.6 9-19.1 19.6-19.6v2.2c0 .6.4 1 1 1s1-.4 1-1v-2.2c10.6.5 19.1 9 19.6 19.6h-2.2c-.6 0-1 .4-1 1s.4 1 1 1h2.2c-.5 10.6-9 19.1-19.6 19.6" />
    <path d="M34.5 31h-5c-1.5 0-2.7-1.2-2.7-2.6s1.2-2.6 2.7-2.6h7.4c.6 0 1-.4 1-1s-.4-1-1-1h-3.2v-1.2c0-.6-.4-1-1-1s-1 .4-1 1v1.2h-2.2c-2.6 0-4.7 2.1-4.7 4.6s2.1 4.6 4.7 4.6h5c1.5 0 2.7 1.2 2.7 2.6s-1.2 2.6-2.7 2.6h-8.4c-.6 0-1 .4-1 1s.4 1 1 1h5.6v1.2c0 .6.4 1 1 1s1-.4 1-1v-1.2h.8c2.6 0 4.7-2.1 4.7-4.6S37.1 31 34.5 31" />
  </svg>
);
export default IconTargetRevenueCopy;
