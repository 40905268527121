import React from 'react';
import { BringzzText, BringzzIcon } from "@bringzz/components";

const ScanCTA = () => {
    return (
        <div className='mt-2 mx-2 flex space-x-4 p-4 items-center rounded-xl bg-lemon-yellow'>
            <BringzzIcon size="16" icon="IconScan" folder="LineIcons" />
            <BringzzText tag='h4'>Scan your Prescription</BringzzText>
        </div>
    );
};

export default ScanCTA;