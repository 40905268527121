import * as React from "react";
const IconShiftRight = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.9 1.8c-1.2 0-2.2 1-2.2 2.2v56c0 1.2 1 2.2 2.2 2.2s2.2-1 2.2-2.2V4c.1-1.2-.9-2.2-2.2-2.2M33.3 22c-.9-.9-2.3-.9-3.2 0s-.9 2.3 0 3.2l6.9 7H7c-1.2 0-2.2 1-2.2 2.2s1 2.2 2.2 2.2h30l-6.8 7c-.9.9-.9 2.3 0 3.2.4.4 1 .6 1.6.6s1.2-.2 1.6-.7L44 36c.9-.9.9-2.3 0-3.2z" />
  </svg>
);
export default IconShiftRight;
