import * as React from "react";
const IconBillboardAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 5.9H7c-2.8 0-5 2.2-5 5v22.5c0 2.8 2.2 5 5 5h4.3v18.7c0 .6.4 1 1 1s1-.4 1-1V38.4H31v18.7c0 .6.4 1 1 1s1-.4 1-1V38.4h17.7v18.7c0 .6.4 1 1 1s1-.4 1-1V38.4H57c2.8 0 5-2.2 5-5V10.9c0-2.8-2.2-5-5-5m3 27.5c0 1.7-1.3 3-3 3H7c-1.7 0-3-1.3-3-3V10.9c0-1.7 1.3-3 3-3h50c1.7 0 3 1.3 3 3z" />
    <path d="M24.2 13.9c-.2-.3-.5-.6-.9-.6q-.6 0-.9.6l-7 14.3c-.2.5 0 1.1.5 1.3.1.1.3.1.4.1q.6 0 .9-.6l6.1-12.4 6 12.4c.2.5.8.7 1.3.5s.7-.8.5-1.3zM41.7 13.1h-3.4c-.6 0-1 .4-1 1v14.6c0 .6.4 1 1 1h3.4c3.9 0 7-3.1 7-7v-2.6c0-3.8-3.2-7-7-7m5 9.7c0 2.8-2.3 5-5 5h-2.4V15.1h2.4c2.8 0 5 2.3 5 5z" />
  </svg>
);
export default IconBillboardAlt;
