import * as React from "react";
const IconLifering = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 62.2c-7.7 0-15.5-2.9-21.4-8.8C4.9 47.7 1.8 40.1 1.8 32s3.1-15.7 8.9-21.4C16.3 4.9 23.9 1.8 32 1.8s15.7 3.1 21.4 8.9c11.8 11.8 11.8 31 0 42.8-5.9 5.8-13.7 8.7-21.4 8.7m-10.8-6.8c6.8 3.1 14.7 3.1 21.6 0L32 44.6zm-10.3-8.6c.8 1.2 1.8 2.3 2.9 3.4s2.2 2 3.4 2.9l10.9-10.9c-1.4-.5-2.7-1.4-3.8-2.5s-1.9-2.4-2.5-3.8zm25-4.6 10.9 10.9c1.2-.8 2.3-1.8 3.4-2.9s2-2.2 2.9-3.4L42.2 35.9c-.5 1.4-1.4 2.7-2.5 3.8s-2.4 1.9-3.8 2.5m-27.3-21c-1.5 3.3-2.4 7-2.4 10.8s.8 7.4 2.4 10.8L19.4 32zm36 10.8 10.8 10.8c3.1-6.8 3.1-14.7 0-21.6zM32 25.6c-1.7 0-3.3.7-4.5 1.9s-1.9 2.8-1.9 4.5.7 3.3 1.9 4.5 2.8 1.9 4.5 1.9 3.3-.7 4.5-1.9 1.9-2.8 1.9-4.5-.7-3.3-1.9-4.5-2.8-1.9-4.5-1.9m-21.1-8.4 10.9 10.9c.5-1.4 1.4-2.7 2.5-3.8s2.4-1.9 3.8-2.5L17.2 10.9c-1.2.8-2.3 1.8-3.4 2.9s-2 2.2-2.9 3.4m28.8 7.1c1.1 1.1 1.9 2.4 2.5 3.8l10.9-10.9c-.8-1.2-1.8-2.3-2.9-3.4s-2.2-2-3.4-2.9L35.9 21.8c1.4.6 2.7 1.4 3.8 2.5M21.2 8.6 32 19.4 42.8 8.6c-3.3-1.5-7-2.4-10.8-2.4s-7.4.9-10.8 2.4" />
  </svg>
);
export default IconLifering;
