import * as React from "react";
const IconBoltAltCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53 17.9q-.75-2.1-4.2-2.1l-10.7-.1 1.7-7.6c.2-1.1.6-3.1-.6-4.6S36 2 34.8 2H20.7c-.9 0-2.4 0-3.5.9s-1.5 2.4-1.7 3.4l-4.4 21.2c-.2.8-.6 2.9.8 4.1.8.7 1.9.7 2.5.7h12.3l-5.9 25.2c-.2.8-.6 2.4.5 3.6.6.6 1.4 1 2.2 1h.1c.4 0 .7-.1 1.1-.3 1-.5 1.6-1.4 2-2.1L52 21.4c.6-.7 1.5-2.1 1-3.5m-2.6 2.4L25.1 58.5c-.4.5-.8 1.1-1.3 1.4-.1 0-.2.1-.3.1-.2 0-.5-.1-.7-.3-.3-.4-.2-1 0-1.8L29 31.4c.1-.3 0-.6-.2-.8s-.5-.4-.8-.4H14.4c-.5 0-1 0-1.3-.2-.3-.3-.4-.9-.1-2.1l4.4-21.2c.2-.9.4-1.8.9-2.2.6-.5 1.4-.5 2.3-.5h14.2c1.1 0 2.3.1 2.8.7.6.7.3 1.9.1 3l-1.9 8.8c-.1.3 0 .6.2.8s.5.4.8.4l11.9.1c.8 0 2.1.1 2.3.7.2.5-.2 1.2-.6 1.8" />
  </svg>
);
export default IconBoltAltCopy;
