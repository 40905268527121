import * as React from "react";
const IconPopupCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.2 4.8H8.8C5 4.8 2 7.9 2 11.6v30.7c0 3.7 3 6.8 6.8 6.8h13.6c.3 0 .5.1.7.3l5.9 7.2c.7.9 1.8 1.4 3 1.4s2.3-.5 3-1.4l5.9-7.2c.2-.2.4-.3.7-.3h13.6c3.7 0 6.8-3 6.8-6.8V11.6c0-3.7-3-6.8-6.8-6.8M60 42.3c0 2.6-2.1 4.8-4.8 4.8H41.6c-.8 0-1.6.3-2.1.9l-.1.1-5.9 7.3c-.7.9-2.2.9-2.9 0l-5.9-7.3s0-.1-.1-.1q-.9-.9-2.1-.9H8.8C6.1 47.1 4 45 4 42.3V11.6C4 9 6.1 6.8 8.8 6.8h46.5c2.6 0 4.8 2.1 4.8 4.8v30.7z" />
    <path d="M16.7 17.4h28.5c.6 0 1-.4 1-1s-.4-1-1-1H16.7c-.6 0-1 .4-1 1s.4 1 1 1M16.7 28h20.6c.6 0 1-.4 1-1s-.4-1-1-1H16.7c-.6 0-1 .4-1 1s.4 1 1 1M47.8 36.5H16.7c-.6 0-1 .4-1 1s.4 1 1 1h31.1c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconPopupCopy;
