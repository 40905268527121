import * as React from "react";
const IconMonitorMedical = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.9 3.4h-6.3c-.6 0-1 .4-1 1s.4 1 1 1h6.3c1.7 0 3.1 1.4 3.1 3.1v32.8c0 1.7-1.4 3.1-3.1 3.1H7.1C5.4 44.4 4 43 4 41.3V8.5c0-1.7 1.4-3.1 3.1-3.1H13c.6 0 1-.4 1-1s-.4-1-1-1H7.1C4.3 3.4 2 5.7 2 8.5v32.8c0 2.8 2.3 5.1 5.1 5.1h17.1v12.1h-4.8c-.6 0-1 .4-1 1s.4 1 1 1h25.2c.6 0 1-.4 1-1s-.4-1-1-1h-4.7v-12H57c2.8 0 5.1-2.3 5.1-5.1V8.5c-.1-2.8-2.4-5.1-5.2-5.1M37.8 58.6H26.2V46.5h11.6z" />
    <path d="M27 20.7h4v4c0 .6.4 1 1 1s1-.4 1-1v-4h4c.6 0 1-.4 1-1s-.4-1-1-1h-4v-4c0-.6-.4-1-1-1s-1 .4-1 1v4h-4c-.6 0-1 .4-1 1s.5 1 1 1" />
    <path d="M32 35.4c8.6 0 15.6-7 15.6-15.6S40.6 4.1 32 4.1s-15.6 7-15.6 15.6 7 15.7 15.6 15.7m0-29.3c7.5 0 13.6 6.1 13.6 13.6S39.5 33.4 32 33.4s-13.6-6.1-13.6-13.6S24.5 6.1 32 6.1" />
  </svg>
);
export default IconMonitorMedical;
