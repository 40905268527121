import * as React from "react";
const IconCapsuleAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.8 11.9c-2.1-3.4-5.3-5.7-9.2-6.6-3.8-.9-7.7-.2-11 1.9l-14.7 9.3c-.5.3-.6.9-.3 1.4l13.5 22-18.2-18.5c-.4-.4-1-.4-1.4 0L6.3 33.8C.6 39.6.6 49 6.3 54.8c2.8 2.8 6.5 4.4 10.4 4.4s7.6-1.5 10.4-4.4l12.2-12.4c.2-.2.3-.4.3-.7l.1.1h.2c.2 0 .4-.1.5-.2l14.7-9.3c6.9-4.3 9-13.4 4.7-20.4M25.6 53.3c-2.4 2.4-5.6 3.8-8.9 3.8-3.4 0-6.6-1.3-8.9-3.8-4.9-5-4.9-13.2 0-18.2l11.5-11.7 17.9 18.2zM54 30.7l-13.8 8.7-13.4-21.8 13.8-8.7c2.9-1.8 6.2-2.4 9.5-1.6 3.4.7 6.2 2.7 8 5.7 3.7 6 1.9 13.9-4.1 17.7" />
  </svg>
);
export default IconCapsuleAlt1;
