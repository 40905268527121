import * as React from "react";
const IconJuicer = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m55 56.2-.1-2.3c-.2-2.9-2.6-5.2-5.5-5.2h-14l-6.6-9.8 1.4-24.3h6.5c1.9 0 3.5 1.6 3.5 3.5v16.3c0 .6.4 1 1 1s1-.4 1-1V18.1c0-3.1-2.5-5.5-5.5-5.5h-6.4C30.3 6.7 25.5 2 19.7 2 13.8 2 9 6.8 9 12.7c0 .2 0 .3.1.4-.1.2-.1.4-.1.5l1.5 25.5-1.2 17c-.1 1.5.4 3 1.5 4.2s2.5 1.8 4 1.8h34.7c1.5 0 3-.6 4-1.7 1.1-1.2 1.6-2.7 1.5-4.2M12.4 38.1 11 14.6h17.3l-1.4 23.5zM19.7 4c4.8 0 8.6 3.8 8.7 8.6H11C11 7.8 14.9 4 19.7 4m32.4 54.9c-.7.7-1.6 1.1-2.6 1.1H14.8c-1 0-1.9-.4-2.6-1.1s-1-1.7-.9-2.7l1.1-16.1h14.9l6.8 10.1c.2.3.5.4.8.4h14.5c1.9 0 3.4 1.5 3.5 3.3l.1 2.3c.1 1.1-.3 2-.9 2.7" />
    <path d="M24.5 46.3c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8 1.8-.8 1.8-1.8-.8-1.8-1.8-1.8" />
  </svg>
);
export default IconJuicer;
