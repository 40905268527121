import React, { useState } from 'react';
import { BringzzText, BringzzIcon } from "@bringzz/components";
import useUser from 'hooks/useUser';

const BringzzChatRow = ({ onArchive, onDelete, chat, isNew = !chat.lastText, onClick }) => {
    const [isIconsVisible, setIsIconsVisible] = useState(false);
    const { getUser } = useUser();
    const user = getUser();

    const toggleIconsVisibility = () => {
        setIsIconsVisible(!isIconsVisible);
    };

    const deleteChat = async () => {

    }

    return (
        <div
            className="flex h-20 items-center p-4 relative overflow-hidden border-b cursor-pointer"
        >
            <div className={`flex w-full transition-transform duration-300 ${isIconsVisible && !isNew ? 'translate-x-[-130px]' : ''}`}>
                <div className="w-14 h-14 rounded-full bg-gray-200 mr-4 flex justify-center items-center text-2xl font-bold">
                    {isNew ? chat.initials : chat.recipient.initials}
                </div>
                <div className="flex-grow flex justify-center flex-col" onClick={onClick}>
                    <BringzzText tag='h4' className="font-bold">{isNew ? chat.fullName : chat.recipient.fullName}</BringzzText>
                    {chat.lastText && (
                        <>
                            <BringzzText tag='h5' className="font-normal italic truncate">
                                {user.id == chat.lastText.created_by_id && "You: "}{chat.lastText.text_content.substr(0, 50)}
                            </BringzzText>
                            <BringzzText tag='h5' className="font-normal italic text-midnight-blue/60">{chat.lastText.created}</BringzzText>
                        </>
                    )}
                </div>
                {!isNew && (
                    <div className="flex space-x-[1px] py-2 text-midnight-blue/60 cursor-pointer" onClick={toggleIconsVisibility}>
                        <span className="text-[5px]">&#9679;</span>
                        <span className="text-[5px]">&#9679;</span>
                        <span className="text-[5px]">&#9679;</span>
                    </div>
                )}
            </div>

            {isIconsVisible && !isNew && (
                <div className="absolute flex right-0 top-0 h-full transition-opacity duration-300" style={{ width: '130px' }}>
                    <div className="w-1/2 h-full flex justify-center items-center bg-magic-lilac/20">
                        <BringzzIcon
                            size="22"
                            icon="IconTrashCan"
                            folder="LineIcons"
                            className="cursor-pointer"
                            onClick={() => onDelete(chat.chatId)}
                        />
                    </div>
                    <div className="w-1/2 h-full flex justify-center items-center bg-magic-lilac/60">
                        <BringzzIcon
                            size="22"
                            icon="IconArchive"
                            folder="LineIcons"
                            className="cursor-pointer"
                            onClick={(event) => onArchive(chat.chatId)}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default BringzzChatRow;
