import * as React from "react";
const IconPulse = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M35.6 49.5c-2.2 0-3.9-1.3-4.6-3.3l-8.8-27.3-9.9 22.7c-.8 1.7-2.5 2.9-4.4 2.9H4c-1.2 0-2.2-1-2.2-2.2S2.8 40 4 40h3.9c.1 0 .2-.1.3-.2l9.9-22.6c.6-1.7 2.4-2.8 4.3-2.7 1.9 0 3.6 1.2 4.2 3l8.8 27.3c0 .1.1.2.3.2s.3-.1.3-.2l3.6-9.8c.7-1.5 2.1-2.6 3.8-2.8h.2c1.6-.1 3.2.7 4.2 2.2l.1.2 4.1 7.8c0 .1.2.2.3.2h8c1.2 0 2.2 1 2.2 2.2S61.2 47 60 47h-8c-1.8 0-3.4-1-4.3-2.6l-4-7.7s-.1 0-.1.1l-3.5 9.6c-.7 1.9-2.4 3.1-4.5 3.1M22.2 18.9q0 .15 0 0" />
  </svg>
);
export default IconPulse;
