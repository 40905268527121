import * as React from "react";
const IconYacht3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.1 40.9c-1-1.4-2.5-2.2-4.2-2.2H54l-3-7.2c-.8-1.9-2.7-3.2-4.7-3.2h-2.6l-3-7.2C40 19.3 38.1 18 36 18h-5.9v-3.6c0-.6-.4-1-1-1s-1 .4-1 1V18h-7.8v-6.7c0-.6-.4-1-1-1s-1 .4-1 1v6.8c-2.4.5-4.1 2.5-4.1 5v5.2c-2.3.1-4.4 1.6-5 3.9l-1.8 6.5h-.1c-1.5 0-3 .7-4 1.8-1 1.2-1.5 2.7-1.2 4.3l.7 4.4c.4 2.5 2.6 4.4 5.1 4.4h47.4c2.1 0 4.1-1.3 4.8-3.4l1.7-4.6c.5-1.5.2-3.3-.7-4.7M16.2 23.2c0-1.7 1.4-3.1 3.1-3.1H36c1.3 0 2.4.8 2.9 1.9l2.7 6.4H16.2zm-5.1 9.6c.4-1.4 1.7-2.4 3.2-2.4h32c1.3 0 2.4.8 2.9 1.9l2.7 6.4H9.5zM59.8 45l-1.7 4.6c-.5 1.2-1.6 2-2.9 2H7.8c-1.5 0-2.9-1.2-3.1-2.7L4 44.5c-.1-1 .1-1.9.8-2.7.6-.7 1.5-1.2 2.5-1.2h49.6c1 0 2 .5 2.6 1.3.5 1 .7 2.1.3 3.1" />
  </svg>
);
export default IconYacht3;
