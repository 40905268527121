import React, { useState } from 'react';
import { useNavigation } from 'context/NavigationContext';
import {
	BringzzPageContainer,
	BringzzButton,
	BringzzIcon,
	BringzzText,
	BringzzDate
} from '@bringzz/components';
import useUser from 'hooks/useUser';
import useRequest from 'hooks/useRequest';
import { format as formatDateFn } from 'date-fns';
const dateFormat = 'yyyy-MM-dd';
const EditPharmacyNightDuties = () => {
	const { backPress, navigate } = useNavigation();
	const { sendRequest, loading } = useRequest();
	const { getUser } = useUser();
	const user = getUser();
	const [firstDate, setFirstDate] = useState(
		user.pharmacy.nightDuties[0]
			? new Date(user.pharmacy.nightDuties[0])
			: new Date()
	);
	const [secondDate, setSecondDate] = useState(
		user.pharmacy.nightDuties[1]
			? new Date(user.pharmacy.nightDuties[1])
			: new Date()
	);
	const [thirdDate, setThirdDate] = useState(
		user.pharmacy.nightDuties[2]
			? new Date(user.pharmacy.nightDuties[2])
			: new Date()
	);

	return (
		<BringzzPageContainer
			header={{
				left: (
					<BringzzIcon
						size='22'
						icon='IconChevronLeft'
						folder='LineIcons'
						className='cursor-pointer'
						onClick={backPress}
					/>
				),
				title: <BringzzText tag='h2'>Night Duties</BringzzText>
			}}
			footer={
				<div className='pb-28 px-6'>
					<BringzzButton
						size='lg'
						className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
						onClick={async () => {
							await sendRequest({
								method: 'PATCH',
								url: `/pharmacy/info/duties`,
								data: {
									nightDuties: [
										formatDateFn(firstDate, dateFormat),
										formatDateFn(secondDate, dateFormat),
										formatDateFn(thirdDate, dateFormat)
									]
								}
							})
								.then(response => {
									if (response.status === 'success')
										navigate(`/settings/pharmacy/info`, false);
								})
								.catch(error => {
									console.log(error);
								});
						}}
						disabled={loading}
					>
						Save
					</BringzzButton>
				</div>
			}
		>
			<div className='p-6 space-y-4'>
				<BringzzDate
					format={dateFormat}
					mode={'day'}
					label='Next night duty'
					defaultValue={firstDate}
					onChange={newDate => setFirstDate(new Date(newDate))}
					maxDate={secondDate}
				></BringzzDate>
				<BringzzDate
					format={dateFormat}
					mode={'day'}
					label='Night duty after that'
					defaultValue={secondDate}
					onChange={newDate => setSecondDate(new Date(newDate))}
					minDate={firstDate}
					maxDate={thirdDate}
				></BringzzDate>
				<BringzzDate
					format={dateFormat}
					mode={'day'}
					label='Night duty after that'
					defaultValue={thirdDate}
					onChange={newDate => setThirdDate(new Date(newDate))}
					minDate={secondDate}
				></BringzzDate>

				<div className='p-4 border rounded-md mt-8'>
					<BringzzText tag='h4' className='font-regular text-midnight-blue/40'>
						Something about the calculated night duty interval of every{' '}
						<span className='font-bold'>X days</span> (displayed after 2
						inputs). You can change this later.
					</BringzzText>
				</div>
			</div>
		</BringzzPageContainer>
	);
};

export default EditPharmacyNightDuties;
