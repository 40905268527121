import * as React from "react";
const IconShipContainer = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 39H51V8.7c0-2.2-1.8-4-4-4H30.5c-2.2 0-4 1.8-4 4v4.4H7.2c-2.9 0-5.2 2.4-5.2 5.2v35.8c0 2.9 2.4 5.2 5.2 5.2h35.2c1.8 0 3.5-.9 4.4-2.5l10-15.8H61c.6 0 1-.4 1-1s-.4-1-1-1M28.5 23.8H49V39H28.5zm0-15.1c0-1.1.9-2 2-2H47c1.1 0 2 .9 2 2v13.2H28.5zM7.2 15.1h19.3V39H4V18.3c0-1.8 1.5-3.2 3.2-3.2m37.9 40.7c-.6 1-1.6 1.5-2.7 1.5H7.2c-1.8 0-3.2-1.5-3.2-3.2v-3.9h44.7zm4.8-7.6H4V41h50.4z" />
    <path d="M34.9 11.8c-.6 0-1 .4-1 1v2.9c0 .6.4 1 1 1s1-.4 1-1v-2.9c0-.5-.4-1-1-1M42.9 11.8c-.6 0-1 .4-1 1v2.9c0 .6.4 1 1 1s1-.4 1-1v-2.9c0-.5-.5-1-1-1M34.9 28.8c-.6 0-1 .4-1 1v2.9c0 .6.4 1 1 1s1-.4 1-1v-2.9c0-.6-.4-1-1-1M42.9 28.8c-.6 0-1 .4-1 1v2.9c0 .6.4 1 1 1s1-.4 1-1v-2.9c0-.6-.5-1-1-1M11.3 29.5c.6 0 1-.4 1-1v-2.9c0-.6-.4-1-1-1s-1 .4-1 1v2.9c0 .5.5 1 1 1M19.5 29.5c.6 0 1-.4 1-1v-2.9c0-.6-.4-1-1-1s-1 .4-1 1v2.9c0 .5.5 1 1 1" />
  </svg>
);
export default IconShipContainer;
