import * as React from "react";
const IconAr = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 513 342"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 0h512v342H0z" />
    <path  d="M0 0h512v114H0zm0 228h512v114H0z" />
    <circle
      cx={256.5}
      cy={171}
      r={40}
      
      
      strokeWidth={5}
    />
  </svg>
);
export default IconAr;
