import * as React from "react";
const IconArrowLeftCircle = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M43.3 29.8H26l6-6.1c.9-.9.9-2.3 0-3.2s-2.3-.9-3.2 0l-9.7 9.9c-.9.9-.9 2.3 0 3.2l9.7 9.9c.4.4 1 .7 1.6.7s1.1-.2 1.6-.6c.9-.9.9-2.3 0-3.2l-6-6.1h17.3c1.2 0 2.2-1 2.2-2.2.1-1.3-.9-2.3-2.2-2.3" />
    <path d="M32 1.8C15.3 1.8 1.7 15.3 1.7 32S15.3 62.2 32 62.2 62.3 48.7 62.3 32 48.7 1.8 32 1.8m0 56C17.8 57.8 6.2 46.2 6.2 32S17.8 6.2 32 6.2 57.8 17.8 57.8 32 46.2 57.8 32 57.8" />
  </svg>
);
export default IconArrowLeftCircle;
