import * as React from "react";
const IconWorld = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.4 17.1c-4.1-7.1-11-12.4-19-14.4-2.6-.6-5-.9-7.4-.9-2.5 0-5.1.3-7.6 1-7.9 2-14.7 7.2-18.7 14.4-2.6 4.6-3.9 9.6-3.9 14.8v.7c.1 5.5 1.8 10.9 4.7 15.6 4.8 7.6 12.7 12.6 21.5 13.6 1.2.2 2.5.3 4 .3 1.2 0 2.5-.1 3.7-.2 9-1.1 16.9-6.1 21.8-13.7 3-4.6 4.6-10 4.8-15.6V32c0-5-1.4-10.2-3.9-14.9m-7.1 13.5c-.3-3.3-1.1-6.5-2.4-9.5h6.4c1.4 3 2.2 6.3 2.4 9.5zm-45 0c.2-3.3 1-6.5 2.4-9.5h5.8c-1.3 3-2 6.2-2.3 9.5zm13.2-9.5h10.2v9.5H16.8c.3-3.4 1.2-6.6 2.7-9.5m21.7-4.5h-7V8.3c2.3 1.8 4.6 4.6 7 8.3M29.8 8.2v8.4h-7.5q3.75-5.7 7.5-8.4m0 26.9v9.5h-10c-1.6-2.9-2.6-6.2-2.9-9.5zm0 14V56c-2.5-1.7-5-4.1-7.2-6.9zm4.5 6.9v-7h7.6c-2.1 2.9-4.7 5.3-7.6 7m0-11.4v-9.5h12.6c-.2 3.3-1 6.5-2.4 9.5zm0-14v-9.5H44c1.5 2.9 2.5 6.2 2.8 9.5zm18.3-14h-6.1q-3.3-5.55-6.6-9c5.1 1.6 9.6 4.8 12.7 9M23.5 7.7c-2.2 2.3-4.4 5.3-6.6 8.8h-5.4c3-4 7.2-7.1 12-8.8M6.4 35.1h5.9c.3 3.3 1.1 6.5 2.4 9.5H9.5c-1.7-3-2.7-6.2-3.1-9.5m6.3 14h4.5c1.6 2.6 3.6 5 5.6 7-3.9-1.5-7.4-3.9-10.1-7m29.2 6.7c2-1.9 3.8-4.2 5.2-6.6 0 0 0-.1.1-.1h4.1c-2.6 2.9-5.8 5.2-9.4 6.7m12.6-11.2h-5.2c1.2-3 1.9-6.2 2-9.5h6.2c-.3 3.3-1.4 6.5-3 9.5" />
  </svg>
);
export default IconWorld;
