import * as React from "react";
const IconCloudSyncCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M37.8 47.1c-1.3 2-3.5 3.1-5.9 3.1-2.1 0-4.1-.9-5.5-2.6 0-.1-.1-.1-.2-.1h4.7c.6 0 1-.4 1-1s-.4-1-1-1h-6.3c-.8 0-1.5.7-1.5 1.5v5c0 .6.4 1 1 1s1-.4 1-1v-2.8c1.7 1.9 4.1 3 6.7 3 3.1 0 5.9-1.5 7.6-4 .3-.5.2-1.1-.3-1.4-.4-.2-1-.1-1.3.3M40.7 30.5c-.6 0-1 .4-1 1v3.2c-1.9-2-5.1-4-7.6-4-3.4 0-6.5 1.9-8.1 5-.3.5-.1 1.1.4 1.4s1.1.1 1.4-.4c1.3-2.4 3.7-3.9 6.4-3.9 2.6 0 6.2 2.9 7 4.5h-3.9c-.6 0-1 .4-1 1s.4 1 1 1h5c.8 0 1.5-.7 1.5-1.5v-6.3c-.1-.5-.5-1-1.1-1" />
    <path d="M57.2 24c-2.7-3-6.5-5-10.6-5.5-2.1-3.6-5.3-6.3-9.1-7.6-1.6-.6-3.5-1-5.5-1-9.4 0-17.1 7.7-17.1 17.1v.1C7.7 27.5 2 33.4 2 40.6 2 48 8 54 15.4 54c.6 0 1-.4 1-1s-.4-1-1-1C9 52 4 47 4 40.6s5-11.4 11.4-11.4h.5c.6 0 1-.4 1-1v-1.1C16.9 18.8 23.7 12 32 12c1.8 0 3.4.3 4.9.8 3.5 1.2 6.5 3.7 8.3 7.1.2.3.4.5.8.5 3.7.4 7.3 2.2 9.8 4.9 2.7 3 4.2 6.8 4.2 10.8C60 44.9 52.9 52 44.1 52c-.6 0-1 .4-1 1s.4 1 1 1C54 54 62 46 62 36.2c0-4.5-1.7-8.8-4.8-12.2" />
  </svg>
);
export default IconCloudSyncCopy;
