import * as React from "react";
const IconQuill = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M47.7 3.9c-.1-.7-.6-1.2-1.2-1.6-.6-.3-1.3-.4-2-.2-2.1.7-6.3 2.2-10.7 5-.8.5-1.2 1.9-1.3 4.2-1.6-.6-3.3-.9-4.2-.2-2.7 2.3-4.8 5-6.5 7.8-7.4 12.8-3 28.9-2.8 29.6 0 .2.1.3.2.4-1.1 2.7-1.9 5.2-2.8 8.1-.4 1.2-.8 2.4-1.2 3.7-.2.5.1 1.1.6 1.3.1 0 .2.1.3.1.4 0 .8-.3.9-.7.4-1.3.8-2.6 1.2-3.7 1-3.1 1.9-5.9 3.1-8.8 4-1.2 16.6-5.9 22.9-16.9 6.3-10.5 4.5-23.1 3.5-28.1m-4.9 27c-5.1 8.9-15.2 13.5-20.1 15.3 1.7-3.4 3.9-7.2 7.5-12.1.3-.4.2-1.1-.2-1.4s-1.1-.2-1.4.2c-3.9 5.3-6.2 9.3-8 12.9-.9-4.6-2.5-16.5 3-26.1q2.25-3.9 6-7.2c.5-.1 2.1.4 3.4 1 .3.1.7.1 1-.1s.5-.5.5-.8c0-1.8.2-3.6.5-4.1 4.2-2.6 8.2-4.1 10.2-4.7h.3c.1 0 .2.1.2.3.8 3.9 2.9 16.7-2.9 26.8" />
  </svg>
);
export default IconQuill;
