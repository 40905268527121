import React from 'react';
import BringzzChatRow from './BringzzChatRow';
import PropTypes from 'prop-types';

const BringzzChatList = ({ chats, new: isNew }) => {
    return (
        <div className="flex flex-col">
            {chats.map(chat => (
                <BringzzChatRow key={chat.id} chat={chat} new={isNew} />
            ))}
        </div>
    );
};

BringzzChatList.propTypes = {
    chats: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        datetime: PropTypes.string.isRequired
    })).isRequired
};

export default BringzzChatList;
