import * as React from "react";
const IconThoughtCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M36.7 37.2c2.3 1.4 4.8 2.2 7.4 2.2 7.6 0 13.8-6.2 13.8-13.8 0-5.9-3.6-11.1-9.1-13C47.3 6.4 41.8 2 35.5 2c-5.6 0-10.6 3.4-12.7 8.5-1-.2-1.9-.3-2.8-.3-7.6.1-13.8 6.3-13.8 13.9 0 5.3 3.1 10.2 7.9 12.5 2.5 3.9 6.9 6.3 11.5 6.3 4.4 0 8.5-2.2 11.1-5.7m-11.3 3.7c-4 0-7.8-2.2-10-5.6-.1-.2-.3-.3-.4-.4-4.2-1.9-7-6.2-7-10.8 0-6.5 5.3-11.9 11.9-11.9 1 0 2.1.2 3.2.5.5.1 1-.1 1.2-.6 1.6-4.8 6.1-8 11.2-8 5.6 0 10.4 4 11.5 9.5.1.4.3.7.7.8 4.9 1.5 8.3 6.1 8.3 11.3 0 6.5-5.3 11.8-11.8 11.8-2.5 0-5-.8-7.1-2.4-.2-.2-.5-.2-.8-.2-.3.1-.5.2-.7.5-2.2 3.4-6 5.5-10.2 5.5M40.7 44c-3 0-5.4 2.4-5.4 5.4s2.4 5.4 5.4 5.4 5.4-2.4 5.4-5.4-2.4-5.4-5.4-5.4m0 8.8c-1.9 0-3.4-1.5-3.4-3.4s1.5-3.4 3.4-3.4 3.4 1.5 3.4 3.4-1.5 3.4-3.4 3.4M52.6 53.8c-2.3 0-4.1 1.8-4.1 4.1s1.8 4.1 4.1 4.1c2.2 0 4-1.7 4.1-3.9V58c0-1.1-.4-2.2-1.1-2.9-.8-.9-1.8-1.3-3-1.3m2.1 4.1c0 1.2-.9 2.1-2.1 2.1s-2.1-.9-2.1-2.1.9-2.1 2.1-2.1q.9 0 1.5.6t.6 1.5q0-.15 0 0" />
  </svg>
);
export default IconThoughtCopy;
