import * as React from "react";
const IconDogecoin1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 65 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32.5 3c-16 0-29 13-29 29s13 29 29 29 29-13 29-29-13-29-29-29m0 56c-14.9 0-27-12.1-27-27s12.1-27 27-27 27 12.1 27 27-12.1 27-27 27" />
    <path d="M35.1 16.3h-14c-.6 0-1 .4-1 1s.4 1 1 1h4.8v27.3h-4.8c-.6 0-1 .4-1 1s.4 1 1 1h14c6 0 10.8-4.9 10.8-10.8v-9.7c0-5.9-4.9-10.8-10.8-10.8m8.8 20.5c0 4.9-4 8.8-8.8 8.8h-7.2V18.3h7.2c4.9 0 8.8 4 8.8 8.8z" />
  </svg>
);
export default IconDogecoin1;
