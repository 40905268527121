import * as React from "react";
const IconBasket2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 20.1H46.3l-10-10c.6-.8.9-1.8.9-2.9C37.2 4.3 34.9 2 32 2s-5.2 2.3-5.2 5.2c0 1.1.3 2.1.9 2.9l-10 10H6c-2.1 0-3.8 1.7-3.8 3.8v2.5c0 2.1 1.7 3.8 3.7 3.8l6.4 24.3c.1.4.5.7 1 .7H20c.6 0 1-.4 1-1s-.4-1-1-1h-5.9L8 30.2h48l-6.1 23h-3.6c-.6 0-1 .4-1 1s.4 1 1 1h4.4c.5 0 .9-.3 1-.7L58 30.1c2.1 0 3.7-1.7 3.7-3.8v-2.5c.1-2-1.6-3.7-3.7-3.7M32 4c1.8 0 3.2 1.4 3.2 3.2 0 .9-.4 1.7-.9 2.2-.6.6-1.4.9-2.2.9-.9 0-1.7-.4-2.2-.9-.6-.6-.9-1.4-.9-2.2-.2-1.8 1.2-3.2 3-3.2m-2.9 7.5c.8.6 1.8.9 2.9.9s2.1-.3 2.9-.9l8.6 8.6h-23zm30.7 14.8c0 1-.8 1.8-1.8 1.8H6c-1 0-1.8-.8-1.8-1.8v-2.5c0-1 .8-1.8 1.8-1.8h52c1 0 1.8.8 1.8 1.8z" />
    <path d="M30.4 43.5h7.1c.6 0 1-.4 1-1s-.4-1-1-1h-3.4v-2.3c0-.6-.4-1-1-1s-1 .4-1 1v2.3h-1.7c-2.7 0-4.8 2.2-4.8 4.8s2.2 4.8 4.8 4.8h3.3c1.6 0 2.8 1.3 2.8 2.8s-1.3 2.8-2.8 2.8h-7.1c-.6 0-1 .4-1 1s.4 1 1 1h5.6V61c0 .6.4 1 1 1s1-.4 1-1v-2.3c2.5-.2 4.4-2.3 4.4-4.8 0-2.7-2.2-4.8-4.8-4.8h-3.3c-1.6 0-2.8-1.3-2.8-2.8s1.1-2.8 2.7-2.8" />
  </svg>
);
export default IconBasket2;
