import * as React from "react";
const IconNu = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 85.333 512 341.333"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 85.334h512v341.337H0z" />
    <path  d="M0 85.334h256v170.663H0z" />
    <g >
      <path d="M144 85.33h-32v69.333H0v32h112v69.334h32v-69.334h112v-32H144z" />
      <path d="M0 85.329v15.083l57.377 38.251H80z" />
    </g>
    <path
      
      d="M0 107.951v30.712h46.069zm96-22.62v48.913L22.628 85.331z"
    />
    <path  d="M256 85.329v15.083l-57.377 38.251H176z" />
    <path
      
      d="M256 107.951v30.712h-46.069zm-96-22.62v48.913l73.372-48.913z"
    />
    <path  d="M0 85.329v15.083l57.377 38.251H80z" />
    <path
      
      d="M0 107.951v30.712h46.069zm96-22.62v48.913L22.628 85.331z"
    />
    <path  d="M256 85.329v15.083l-57.377 38.251H176z" />
    <path
      
      d="M256 107.951v30.712h-46.069zm-96-22.62v48.913l73.372-48.913z"
    />
    <path  d="M0 255.997v-15.082l57.377-38.252H80z" />
    <path
      
      d="M0 233.376v-30.713h46.069zm96 22.618v-48.912l-73.372 48.912z"
    />
    <path  d="M256 255.997v-15.082l-57.377-38.252H176z" />
    <g >
      <path d="M256 233.376v-30.713h-46.069zm-96 22.618v-48.912l73.372 48.912z" />
      <circle cx={128} cy={170.66} r={22.627} />
    </g>
    <path
      
      d="m128 154.663 3.97 12.222h12.854l-10.398 7.556 3.971 12.222L128 179.109l-10.398 7.554 3.972-12.222-10.398-7.556h12.853zm0 65.14 2.364 7.278h7.653l-6.19 4.498 2.364 7.278-6.191-4.498-6.191 4.498 2.364-7.278-6.19-4.498h7.652zm0-117.334 2.364 7.278h7.653l-6.19 4.498 2.364 7.278-6.191-4.498-6.191 4.498 2.364-7.278-6.19-4.498h7.652zm69.931 58.667 2.365 7.277h7.653l-6.192 4.499 2.365 7.279-6.191-4.498-6.191 4.498 2.365-7.279-6.191-4.499h7.652zm-139.862 0 2.364 7.277h7.653l-6.191 4.499 2.364 7.279-6.19-4.498-6.191 4.498 2.365-7.279-6.192-4.499h7.653z"
    />
  </svg>
);
export default IconNu;
