import * as React from "react";
const IconKoala = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62 16.7c0-6.9-5.6-12.5-12.5-12.5-5.6 0-10.5 3.8-12 9.1l-.1.5q-2.55-.6-5.4-.6t-5.4.6l-.1-.5c-1.5-5.3-6.4-9-12-9C7.6 4.3 2 9.9 2 16.7c0 5.3 3.3 9.9 8.2 11.7-.9 2.5-1.4 5.2-1.4 8 0 12.8 10.4 23.3 23.3 23.3 12.8 0 23.3-10.4 23.3-23.3 0-2.8-.5-5.5-1.4-8 4.7-1.7 8-6.4 8-11.7m-58 0C4 11 8.7 6.3 14.5 6.3c4.7 0 8.8 3.1 10.1 7.6l.1.5c-6.1 2-11 6.5-13.7 12.2-4.2-1.5-7-5.4-7-9.9m28 41c-11.7 0-21.3-9.5-21.3-21.3S20.3 15.2 32 15.2s21.3 9.5 21.3 21.3S43.7 57.7 32 57.7m21-31.1c-2.7-5.7-7.7-10.2-13.7-12.2l.1-.5c1.2-4.5 5.4-7.7 10.1-7.7C55.3 6.3 60 11 60 16.7c0 4.5-2.8 8.4-7 9.9" />
    <path d="M33.1 32.8h-2c-2.2 0-3.9 1.8-3.9 3.9v10.6c0 2.2 1.8 3.9 3.9 3.9h2c2.2 0 3.9-1.8 3.9-3.9V36.7c0-2.2-1.7-3.9-3.9-3.9M35 47.3c0 1.1-.9 1.9-1.9 1.9h-2c-1.1 0-1.9-.9-1.9-1.9V36.7c0-1.1.9-1.9 1.9-1.9h2c1.1 0 1.9.9 1.9 1.9z" />
    <circle cx={23.7} cy={28.3} r={2.7} />
    <circle cx={40.5} cy={28.3} r={2.7} />
  </svg>
);
export default IconKoala;
