import * as React from "react";
const IconLiferingCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.2 10.8C47.5 5.1 40 2 32 2s-15.5 3.1-21.2 8.8S2 24 2 32s3.1 15.5 8.8 21.2C16.6 59.1 24.3 62 32 62s15.4-2.9 21.2-8.8c11.7-11.7 11.7-30.7 0-42.4m-1.4 1.4c1.4 1.4 2.7 3 3.7 4.6L41.9 30.5c-.3-2.1-1.3-4-2.8-5.5s-3.5-2.5-5.5-2.8L47.2 8.5c1.6 1 3.2 2.3 4.6 3.7M40 32c0 2.1-.8 4.1-2.3 5.6S34.1 40 32 40s-4.1-.8-5.6-2.3C24.9 36.1 24 34.1 24 32s.8-4.1 2.3-5.6S29.9 24 32 24s4.1.8 5.6 2.3c1.5 1.6 2.4 3.6 2.4 5.7M32 4c4.8 0 9.3 1.2 13.4 3.4L32 20.8 18.6 7.4C22.7 5.2 27.2 4 32 4m-19.8 8.2c1.4-1.4 3-2.7 4.6-3.7l13.7 13.7c-2.1.3-4 1.3-5.5 2.8s-2.5 3.5-2.8 5.5L8.5 16.8c1-1.6 2.3-3.2 3.7-4.6M4 32c0-4.8 1.2-9.3 3.4-13.4L20.8 32 7.4 45.4C5.2 41.3 4 36.8 4 32m8.2 19.8c-1.4-1.4-2.7-3-3.7-4.6l13.7-13.7c.3 2.1 1.3 4 2.8 5.5s3.5 2.5 5.5 2.8L16.8 55.5c-1.6-1-3.2-2.3-4.6-3.7m6.4 4.8L32 43.2l13.4 13.4c-8.3 4.5-18.5 4.5-26.8 0m33.2-4.8c-1.4 1.4-3 2.7-4.6 3.7L33.5 41.9c2.1-.3 4-1.3 5.5-2.8s2.5-3.5 2.8-5.5l13.7 13.7c-1 1.5-2.3 3.1-3.7 4.5M43.2 32l13.4-13.4c4.5 8.3 4.5 18.5 0 26.8z" />
  </svg>
);
export default IconLiferingCopy;
