import * as React from "react";
const IconMoneyBagAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M30.3 33.3h7.2c.6 0 1-.4 1-1s-.4-1-1-1h-3.4V29c0-.6-.4-1-1-1s-1 .4-1 1v2.3h-1.8c-2.7 0-4.9 2.2-4.9 4.8 0 2.7 2.2 4.8 4.9 4.8h3.3c1.6 0 2.9 1.3 2.9 2.8s-1.3 2.8-2.9 2.8h-7.2c-.6 0-1 .4-1 1s.4 1 1 1H32V51c0 .6.4 1 1 1s1-.4 1-1v-2.3c2.5-.2 4.4-2.3 4.4-4.8 0-2.7-2.2-4.8-4.9-4.8h-3.3c-1.6 0-2.9-1.3-2.9-2.8s1.5-3 3-3" />
    <path d="M55 35.7c-1.6-3.6-3.7-7.1-6.4-10.3-2.9-3.5-5.7-5.9-7.5-7.3l5.7-10.7c.5-1 .3-2.2-.4-2.9C44.5 2.9 42.6 2 40.5 2c-2 0-3.7.7-5.1 1.3h-.1c-.9.4-1.8.8-2.5.8-.1 0-.2 0-.3-.1-3-1.1-5.3-1.8-7.6-1.8-3 0-5.5 1.2-8 3.7-.8.8-.9 2.1-.3 3.1l6.1 9.2c-1.8 1.4-4.5 3.8-7.3 7.1-2.7 3.2-4.9 6.7-6.4 10.3-2 4.5-3 9.3-3 14 0 6.7 5.5 12.2 12.2 12.2h27.4c6.7 0 12.2-5.5 12.2-12.2.1-4.6-.9-9.3-2.8-13.9M18.4 7.9c-.1-.2-.1-.4.1-.6 2.1-2.1 4.1-3.1 6.5-3.1 2 0 4.1.6 6.8 1.6 1.2.5 2.7-.1 4.3-.7h.1c1.2-.5 2.7-1.1 4.3-1.1s3.1.7 4.5 2c.1.1.2.3.1.5l-5.9 11H24.7zM45.7 60H18.3c-5.6 0-10.2-4.6-10.2-10.2 0-4.5.9-9 2.8-13.3 1.5-3.4 3.5-6.7 6.1-9.8 3-3.5 5.9-6 7.5-7.2h15c1.7 1.3 4.5 3.7 7.5 7.2 2.6 3.1 4.6 6.4 6.1 9.8 1.8 4.3 2.8 8.7 2.8 13.3 0 5.6-4.6 10.2-10.2 10.2" />
  </svg>
);
export default IconMoneyBagAlt;
