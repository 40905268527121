import * as React from "react";
const IconKnowledge = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M30.4 30.4c.5-.1 1-.3 1.6-.6.5.3 1.1.4 1.6.6.5.1.9.1 1.4.1 3.8 0 7-3.3 8.9-9.1 1.3-4.1 1-7.8-.9-10.2-1.4-1.8-3.7-2.7-6.2-2.5-1.2.1-2.4.4-3.5.8-.4-2.5-1.7-5-3.8-7.3-.5-.2-1.1-.2-1.5.1-.4.4-.4 1 0 1.4 1.8 1.9 2.8 3.9 3.2 5.9-1.3-.4-2.7-.7-3.8-.8-2.6-.2-4.8.7-6.2 2.5-1.9 2.4-2.2 6.1-.9 10.2 2 6.5 5.8 9.8 10.1 8.9m-7.8-17.9c1-1.3 2.6-1.9 4.5-1.8 1.4.1 3.6.7 4.7 1.2q.45.15.9 0l.9-.4c1-.5 2.1-.7 3.3-.8 1.9-.2 3.5.4 4.5 1.7 1.5 1.8 1.7 4.9.6 8.3-.7 2-3.2 8.6-7.9 7.6q-.75-.15-1.5-.6c-.2-.1-.3-.1-.5-.1s-.3 0-.5.1q-.9.45-1.5.6c-4.8 1-7.3-5.6-8-7.6-1.1-3.3-.9-6.3.5-8.2" />
    <path d="M61 45.3h-5.7V32.7H61c.6 0 1-.4 1-1s-.4-1-1-1H10.3C5.7 30.7 2 34.5 2 39c0 3.2 1.8 5.9 4.4 7.3C3.8 47.7 2 50.5 2 53.6c0 4.6 3.7 8.3 8.3 8.3h45.4c.6 0 1-.4 1-1s-.4-1-1-1h-6.8V47.3H61c.6 0 1-.4 1-1s-.4-1-1-1M4 39c0-3.5 2.8-6.3 6.3-6.3h43v12.6h-43C6.8 45.3 4 42.5 4 39m42.9 20.9H10.3c-3.5 0-6.3-2.8-6.3-6.3s2.8-6.3 6.3-6.3h36.6z" />
  </svg>
);
export default IconKnowledge;
