import * as React from "react";
const IconAmbulanceCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 10.3H25.6c-2.2 0-3.9 1.9-3.9 4.2v4h-6.2v-3.4c0-.6-.4-1-1-1s-1 .4-1 1v3.4H8.8c-.9 0-1.8.6-2.1 1.5L2.1 32.8l-.2.5.6.9v11.3c0 1.3 1.1 2.4 2.4 2.4h1.6c.5 3.3 3.3 5.9 6.8 5.9s6.2-2.5 6.8-5.9h23.8c.5 3.3 3.3 5.9 6.8 5.9s6.2-2.5 6.8-5.9h2.8c1 0 1.7-.8 1.7-1.8V14.5c0-2.3-1.8-4.2-4-4.2M8.6 20.7l.2-.2h12.9v11.7H4.4zm4.8 31c-2.7 0-4.9-2.2-4.9-4.9s2.2-4.9 4.9-4.9 4.9 2.2 4.9 4.9-2.3 4.9-4.9 4.9m6.8-5.8c-.4-3.4-3.3-6-6.8-6s-6.3 2.6-6.8 6H5c-.2 0-.4-.2-.4-.4V34.2h17.1v11.7zm30.5 5.8c-2.7 0-4.9-2.2-4.9-4.9s2.2-4.9 4.9-4.9c2.6 0 4.7 2.1 4.8 4.6v.6c-.1 2.6-2.2 4.6-4.8 4.6m9.3-5.8h-2.5c-.4-3.4-3.3-6-6.8-6s-6.3 2.6-6.8 6H23.7V14.5c0-1.2.9-2.2 1.9-2.2H58c1.1 0 2 1 2 2.2z" />
    <path d="M47.3 26.7h-4.5v-4.5c0-.6-.4-1-1-1s-1 .4-1 1v4.5h-4.5c-.6 0-1 .4-1 1s.4 1 1 1h4.5v4.5c0 .6.4 1 1 1s1-.4 1-1v-4.5h4.5c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconAmbulanceCopy;
