import * as React from "react";
const IconPatientAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M26.1 26.7c6.8 0 12.3-5.5 12.3-12.3S32.9 2 26.1 2 13.7 7.5 13.7 14.3s5.6 12.4 12.4 12.4m0-22.7c5.7 0 10.3 4.6 10.3 10.3s-4.6 10.3-10.3 10.3S15.7 20 15.7 14.3 20.4 4 26.1 4M13.7 36.4h21.7c.6 0 1-.4 1-1s-.4-1-1-1H13.7c-5 0-9.1 4.1-9.1 9.1s4.1 9.1 9.1 9.1h14.4c.6 0 1-.4 1-1s-.4-1-1-1H13.7c-3.9 0-7.1-3.2-7.1-7.1s3.2-7.1 7.1-7.1M47.6 38.4c-6.5 0-11.8 5.3-11.8 11.8S41.1 62 47.6 62s11.8-5.3 11.8-11.8-5.3-11.8-11.8-11.8m0 21.6c-5.4 0-9.8-4.4-9.8-9.8s4.4-9.8 9.8-9.8 9.8 4.4 9.8 9.8S53 60 47.6 60" />
    <path d="M51.1 49.2h-2.5v-2.5c0-.6-.4-1-1-1s-1 .4-1 1v2.5h-2.5c-.6 0-1 .4-1 1s.4 1 1 1h2.5v2.5c0 .6.4 1 1 1s1-.4 1-1v-2.5h2.5c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconPatientAlt1;
