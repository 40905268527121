import * as React from "react";
const IconHouseAlt3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.9 60h-6.3V35.2c0-2.4-1.6-4.5-4-5.1l-11.7-3V14.6c0-2.1-1.3-4-3.2-4.9L17.9 2.4c-1.6-.7-3.5-.5-5 .4-1.5 1-2.4 2.6-2.4 4.4V60H4.1c-.6 0-1 .4-1 1s.4 1 1 1h55.7c.6 0 1-.4 1-1s-.4-1-.9-1M12.5 7.3c0-1.1.5-2.1 1.5-2.8.9-.6 2.1-.7 3.1-.3l16.8 7.2c1.2.5 2 1.7 2 3v12l-3.1-.8c-1.6-.4-3.3-.1-4.6.9s-2.1 2.5-2.1 4.2V60H12.5zM36.8 60h-8.7V30.9c0-1 .5-2 1.3-2.6s1.9-.8 2.8-.6L49 32c1.5.4 2.5 1.7 2.5 3.2V60z" />
  </svg>
);
export default IconHouseAlt3;
