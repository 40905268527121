import * as React from "react";
const IconFriendlyCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
    <path d="M49.7 33.1c-.6 0-1 .4-1 1 0 8-6.5 14.6-14.6 14.6-.6 0-1 .4-1 1s.4 1 1 1c9.1 0 16.6-7.4 16.6-16.6 0-.6-.5-1-1-1" />
    <circle cx={20.1} cy={22.8} r={4} />
    <circle cx={43.9} cy={22.8} r={4} />
  </svg>
);
export default IconFriendlyCopy;
