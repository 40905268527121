import * as React from "react";
const IconArrowLongLeftR = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m60.7 30.3-5.8-5.8c-.8-.8-2-.8-2.8 0l-5.8 5.8c-.1.1-.2.3-.3.4H6.1l9.1-9.1c.4-.4.4-1 0-1.4s-1-.4-1.4 0L3 31c-.4.4-.4 1 0 1.4l11.4 11.4c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-9.7-9.7h39.8c.1.1.2.3.3.4l5.8 5.8c.4.4.9.6 1.4.6s1-.2 1.4-.6l5.8-5.8c.4-.4.6-.9.6-1.4.1-.6-.1-1.1-.5-1.4m-7.2 7.2-5.8-5.8 5.8-5.8 5.8 5.8z" />
  </svg>
);
export default IconArrowLongLeftR;
