import * as React from "react";
const IconAddAnchorPoint = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M5.3 11.4h6.4v6.4c0 .6.4 1 1 1s1-.4 1-1v-6.4h6.4c.6 0 1-.4 1-1s-.4-1-1-1h-6.4V3c0-.6-.4-1-1-1s-1 .4-1 1v6.4H5.3c-.6 0-1 .4-1 1s.4 1 1 1M58.9 21.5 44.5 7.1c-.8-.8-1.8-1.2-2.8-1.2s-2.1.4-2.8 1.2l-2.1 2.1c-1.6 1.6-1.6 4.1 0 5.7l1.7 1.7-23.2 10.7c-1.1.5-1.9 1.4-2.3 2.6L4.2 56.3c-.5 1.6-.1 3.3 1 4.5.9.8 2 1.2 3.1 1.2.5 0 .9-.1 1.4-.2L36.1 53c1.1-.4 2.1-1.2 2.6-2.3l10.8-23.1 1.7 1.7c.8.8 1.8 1.2 2.8 1.2s2.1-.4 2.8-1.2l2.1-2.1c.8-.8 1.2-1.8 1.2-2.8s-.5-2.2-1.2-2.9M36.8 49.8c-.3.6-.8 1-1.4 1.2L9.1 59.9c-.5.2-1 .1-1.5 0L22 45.5c1 .7 2.2 1.1 3.4 1.1 3.4 0 6.1-2.7 6.1-6.1s-2.7-6.1-6.1-6.1-6.1 2.7-6.1 6.1c0 1.4.5 2.6 1.2 3.6L6.1 58.5c-.2-.5-.2-1 0-1.6l8.8-26.4c.2-.6.7-1.1 1.2-1.4l23.9-11 8 8zm-15.5-9.4c0-2.3 1.8-4.1 4.1-4.1s4.1 1.8 4.1 4.1-1.8 4.1-4.1 4.1c-2.2 0-4.1-1.8-4.1-4.1m36.1-14.7-2.1 2.1c-.8.8-2.1.8-2.8 0L38.2 13.4c-.8-.8-.8-2 0-2.8l2.1-2.1c.4-.4.9-.6 1.4-.6s1 .2 1.4.6l14.4 14.4c.4.4.6.9.6 1.4s-.3 1-.7 1.4" />
  </svg>
);
export default IconAddAnchorPoint;
