import * as React from "react";
const IconBriefcase3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 13.9H43.9V6.7c0-2.2-1.8-4-4-4H24.1c-2.2 0-4 1.8-4 4v7.1H6c-2.2 0-4 1.8-4 4v39.4c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V17.9c0-2.2-1.8-4-4-4M22.1 6.7c0-1.1.9-2 2-2H40c1.1 0 2 .9 2 2v7.1H22.1zM6 15.9h52c1.1 0 2 .9 2 2v10.2c-.1 0-.2 0-.3.1l-14.6 7.6H33v-4.2c0-.6-.4-1-1-1s-1 .4-1 1v4.2H18.9L4.3 28.1c-.1 0-.2-.1-.3-.1V17.9c0-1.1.9-2 2-2m52 43.4H6c-1.1 0-2-.9-2-2v-27l14.2 7.4c.1.1.3.1.5.1H31v3.1c0 .6.4 1 1 1s1-.4 1-1v-3.1h12.4c.2 0 .3 0 .5-.1L60 30.3v27c0 1.1-.9 2-2 2" />
  </svg>
);
export default IconBriefcase3;
