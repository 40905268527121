import * as React from "react";
const IconBadge4 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.1 55.3 49.6 36c.7-.9 1.1-2 1.1-3.1l.1-2.8c0-.6.2-1.3.6-1.8l1.5-2.3c1.2-1.8 1.2-4.2 0-6.1l-1.5-2.3q-.6-.75-.6-1.8l-.1-2.8c-.1-2.2-1.5-4.1-3.6-4.9l-2.6-1c-.5 0-1.1-.4-1.5-.9L41.3 4c-1.4-1.7-3.6-2.5-5.8-1.9l-2.6.9c-.6.2-1.3.2-1.9 0l-2.6-.7c-2.1-.6-4.4.1-5.8 1.9l-1.6 2c-.4.5-.9.9-1.5 1.1l-2.6 1c-2.1.8-3.5 2.7-3.6 4.9l-.1 2.8c0 .6-.2 1.3-.6 1.8L11.1 20c-1.2 1.8-1.2 4.2 0 6.1l1.5 2.3q.6.75.6 1.8l.1 2.8c0 1.2.5 2.2 1.1 3.1L6.9 55.3c-.2.3-.1.7.1.9.2.3.5.4.8.4l7.7-.1 5.7 5.2c.2.2.4.3.7.3h.2c.3-.1.6-.3.7-.6l7-17.9 1.2-.3c.6-.2 1.3-.2 1.9 0l1.2.3 7 17.9c.1.3.4.5.7.6h.2c.2 0 .5-.1.7-.3l5.7-5.2 7.7.1c.3 0 .6-.2.8-.4.3-.2.4-.6.2-.9m-35.6 4-4.9-4.5c-.2-.2-.4-.2-.7-.3l-6.6.1L16 37.4c.3.1.5.3.8.4l2.6 1c.6.2 1.1.6 1.5 1.1l1.7 2.2c1.2 1.5 3 2.2 4.8 2zM32 41c-.5 0-1 .1-1.5.2l-2.6.8c-1.3.4-2.8-.1-3.6-1.2l-1.7-2.2c-.6-.8-1.5-1.4-2.4-1.7l-2.6-1c-.5-.2-.9-.4-1.2-.8-.6-.6-.9-1.4-1-2.3l-.1-2.8c0-1-.4-2-.9-2.8L12.7 25c-.8-1.2-.8-2.7 0-3.8l1.5-2.3c.6-.8.9-1.8.9-2.8l.1-2.8c.1-1.4.9-2.6 2.3-3.1l2.6-1c.9-.3 1.8-1 2.4-1.7l1.7-2.2c.9-1.1 2.3-1.6 3.6-1.2l2.6.7q1.5.45 3 0l2.6-.7c1.4-.4 2.8.1 3.6 1.2l1.7 2.2c.6.8 1.5 1.4 2.4 1.7l2.6 1c1.3.5 2.2 1.7 2.3 3.1l.1 2.8c0 1 .4 2 .9 2.8l1.5 2.3c.8 1.2.8 2.7 0 3.8l-1.5 2.3c-.6.8-.9 1.8-.9 2.8l-.1 2.8c0 .9-.4 1.7-1 2.3-.3.3-.8.6-1.2.8l-2.6 1c-.9.3-1.8 1-2.4 1.7l-1.7 2.2c-.9 1.1-2.3 1.6-3.6 1.2l-2.6-.7c-.5-.3-1-.4-1.5-.4m16.1 13.6c-.2 0-.5.1-.7.3l-4.9 4.5-6-15.2h.5c1.6 0 3.2-.7 4.3-2.1l1.7-2.2c.4-.5.9-.9 1.5-1.1l2.6-1c.3-.1.6-.2.8-.4l6.8 17.2z" />
    <path d="M39.9 16.1c-.4-.4-1-.4-1.4 0L28.9 26l-3.4-3.7c-.4-.4-1-.4-1.4-.1-.4.4-.4 1-.1 1.4l4.1 4.4c.2.2.4.3.7.3s.5-.1.7-.3l10.2-10.6c.6-.2.6-.9.2-1.3" />
  </svg>
);
export default IconBadge4;
