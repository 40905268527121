import * as React from "react";
const IconRestaurantCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.2 18.5 51 3.9C50.3 2.7 49.1 2 47.7 2H16.2c-1.4 0-2.6.7-3.3 1.9L4.8 18.4c-.1.1-.1.3-.1.4v39.3c0 2.1 1.7 3.8 3.8 3.8h47.1c2.1 0 3.8-1.7 3.8-3.8V19c-.1-.2-.1-.4-.2-.5M6.7 19.9h11.1v.3c0 3.1-2.5 5.6-5.6 5.6s-5.6-2.5-5.6-5.6v-.3zm13.1 0H31v.3c0 3.1-2.5 5.6-5.6 5.6s-5.6-2.5-5.6-5.6zm13.2 0h11.1v.3c0 3.1-2.5 5.6-5.6 5.6s-5.6-2.5-5.6-5.6c.1-.1.1-.2.1-.3m13.2 0h11.1v.3c0 3.1-2.5 5.6-5.6 5.6s-5.6-2.5-5.6-5.6c.1-.1.1-.2.1-.3m-31.5-15c.3-.6.9-.9 1.5-.9h31.5c.6 0 1.2.3 1.5.9l7.3 13H7.4zM17.2 60V42.7c0-1 .8-1.8 1.8-1.8h5c1 0 1.8.8 1.8 1.8V60zm40.1-1.8c0 1-.8 1.8-1.8 1.8H27.7V42.7c0-2.1-1.7-3.8-3.8-3.8h-5c-2.1 0-3.8 1.7-3.8 3.8V60H8.4c-1 0-1.8-.8-1.8-1.8V25.3c1.4 1.5 3.4 2.5 5.6 2.5 2.8 0 5.3-1.5 6.6-3.8 1.3 2.3 3.8 3.8 6.6 3.8s5.3-1.5 6.6-3.8c1.3 2.3 3.8 3.8 6.6 3.8s5.3-1.5 6.6-3.8c1.3 2.3 3.8 3.8 6.6 3.8 2.2 0 4.2-1 5.6-2.5v32.9z" />
    <path d="M46.4 38.9h-5.1c-1.3 0-2.4 1.1-2.4 2.4v5.1c0 1.3 1.1 2.4 2.4 2.4h5.1c1.3 0 2.4-1.1 2.4-2.4v-5.1c0-1.3-1.1-2.4-2.4-2.4m.4 7.6c0 .2-.2.4-.4.4h-5.1c-.2 0-.4-.2-.4-.4v-5.1c0-.2.2-.4.4-.4h5.1c.2 0 .4.2.4.4z" />
  </svg>
);
export default IconRestaurantCopy;
