import * as React from "react";
const IconSleep = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 36.3c-4.9 0-8.9 4-8.9 8.9s4 8.9 8.9 8.9 8.9-4 8.9-8.9-4-8.9-8.9-8.9m0 15.8c-3.8 0-6.9-3.1-6.9-6.9s3.1-6.9 6.9-6.9 6.9 3.1 6.9 6.9-3.1 6.9-6.9 6.9" />
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
    <path d="M43.8 17.9c-4.2 0-7.7 3.9-7.7 8.7 0 .6.4 1 1 1s1-.4 1-1c0-3.7 2.5-6.7 5.7-6.7 3.1 0 5.7 3 5.7 6.7 0 .6.4 1 1 1s1-.4 1-1c0-4.8-3.4-8.7-7.7-8.7M25.9 26.7c0 .6.4 1 1 1s1-.4 1-1c0-4.8-3.4-8.7-7.7-8.7s-7.7 3.9-7.7 8.7c0 .6.4 1 1 1s1-.4 1-1c0-3.7 2.5-6.7 5.7-6.7s5.7 2.9 5.7 6.7" />
  </svg>
);
export default IconSleep;
