import * as React from "react";
const IconFlickr = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M14.4 45.4c7.4 0 13.4-6 13.4-13.4s-6-13.4-13.4-13.4S1 24.6 1 32s6 13.4 13.4 13.4M49.6 45.4C57 45.4 63 39.4 63 32s-6-13.4-13.4-13.4-13.4 6-13.4 13.4 6 13.4 13.4 13.4" />
  </svg>
);
export default IconFlickr;
