import * as React from "react";
const IconCornerDoubleDownRight = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.7 44 47.3 30.7c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l11.7 11.7H23.2c-2.6 0-4.8-2.2-4.8-4.8V8.3L30 20c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4L18.1 5.2c-.4-.4-1-.4-1.4 0L3.3 18.6c-.4.4-.4 1 0 1.4s1 .4 1.4 0L16.4 8.3v30.6c0 3.7 3 6.8 6.8 6.8h34.4L45.9 57.4c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l13.4-13.4c.2-.2.3-.4.3-.7s-.1-.5-.3-.7" />
  </svg>
);
export default IconCornerDoubleDownRight;
