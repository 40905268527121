import * as React from "react";
const IconVolumeCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 51.1c-.7 0-1.3-.2-1.9-.6l-10.8-6.7c-.3-.2-.6-.2-.9-.2H5.6C3.6 43.6 2 42 2 40V24.1c0-2 1.6-3.6 3.6-3.6h12.8c.3 0 .6-.1.9-.2l10.8-6.7c1.1-.7 2.5-.7 3.7-.1 1.2.7 1.9 1.8 1.9 3.2v30.9c0 1.3-.7 2.5-1.9 3.2-.6.1-1.2.3-1.8.3M5.6 22.5c-.9 0-1.6.7-1.6 1.6v15.8c0 .9.7 1.6 1.6 1.6h12.8c.7 0 1.3.2 1.9.6l10.8 6.7c.5.3 1.1.3 1.7 0 .5-.3.8-.8.8-1.4V16.5c0-.6-.3-1.1-.8-1.4s-1.1-.3-1.7 0l-10.8 6.7c-.6.4-1.2.6-1.9.6H5.6z" />
  </svg>
);
export default IconVolumeCopy;
