import * as React from "react";
const IconQuestionTriangle = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M24 6a1 1 0 0 1 .894.553l17 34A1 1 0 0 1 41 42H7a1 1 0 0 1-.894-1.447l17-34A1 1 0 0 1 24 6M8.618 40h30.764L24 9.236z"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M25 35a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-3 1a3 3 0 1 1 6 0 3 3 0 0 1-6 0M20.497 21.285c1.209-.587 2.596-.821 3.881-.78 1.272.04 2.688.36 3.867 1.144 1.256.835 2.252 2.233 2.252 4.112 0 1.877-.774 3.359-2.1 4.273a5.2 5.2 0 0 1-.959.52A2.5 2.5 0 0 1 22.5 30v-1.441a2.5 2.5 0 0 1 2.333-2.495c.23-.015.41-.045.54-.079q.07-.018.113-.035.007-.046.01-.124l-.02-.014c-.196-.13-.63-.29-1.257-.31-.614-.02-1.175.105-1.541.282a1 1 0 0 0-.272.174 2.5 2.5 0 0 1-4.827-1.302c.441-1.718 1.715-2.788 2.918-3.371m3.818 1.218c-1.017-.032-2.074.159-2.946.581-.873.424-1.605 1.105-1.853 2.07a.5.5 0 1 0 .969.249c.15-.585.609-1.074 1.32-1.419.703-.34 1.595-.51 2.478-.481.886.028 1.712.252 2.3.644.259.171.503.393.676.691.178.309.238.627.238.923 0 .502-.095.874-.235 1.155-.14.278-.335.487-.567.648-.478.329-1.119.455-1.729.496a.5.5 0 0 0-.466.499V30a.5.5 0 1 0 1 0v-.176a1 1 0 0 1 .725-.962 3.5 3.5 0 0 0 1.037-.475c.736-.507 1.235-1.345 1.235-2.626 0-1.093-.556-1.912-1.36-2.447-.785-.522-1.808-.778-2.822-.81"
      clipRule="evenodd"
    />
  </svg>
);
export default IconQuestionTriangle;
