import * as React from "react";
const IconYenUp = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M38 12.2c-.5-.3-1.1-.1-1.4.3L32 20.2l-4.6-7.7c-.3-.5-.9-.6-1.4-.3s-.6.9-.3 1.4l5.1 8.5h-3.1c-.6 0-1 .4-1 1s.4 1 1 1H31v4.5h-3.4c-.6 0-1 .4-1 1s.4 1 1 1H31V34c0 .6.4 1 1 1s1-.4 1-1v-3.4h3.4c.6 0 1-.4 1-1s-.4-1-1-1H33V24h3.4c.6 0 1-.4 1-1s-.4-1-1-1h-3.1l5.1-8.5c.2-.4 0-1.1-.4-1.3" />
    <path d="M32 3c-11.3 0-20.4 9.1-20.4 20.4 0 6.2 2.8 11.9 7.6 15.8.2.1.4.2.6.2.3 0 .6-.1.8-.4.3-.4.3-1.1-.2-1.4-4.4-3.5-6.8-8.7-6.8-14.3C13.6 13.2 21.8 5 32 5s18.4 8.2 18.4 18.4c0 .6.4 1 1 1s1-.4 1-1C52.4 12.1 43.3 3 32 3M48.9 40.6h-9.7c-.6 0-1 .4-1 1s.4 1 1 1h7.5l-12.4 8.7c-.4.3-.8.4-1.3.4H21.1c-1.2 0-2.4.5-3.2 1.3l-6.1 6.3c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3l6.1-6.3c.4-.5 1-.7 1.7-.7h11.8c.9 0 1.7-.3 2.4-.8l12.6-8.8v7.2c0 .6.4 1 1 1s1-.4 1-1v-9.7c.1-.5-.3-1-.9-1" />
  </svg>
);
export default IconYenUp;
