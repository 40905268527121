import * as React from "react";
const IconBag = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.2 36.9c-.3-.4-.7-.7-1.1-.9l1.9-8c.4-1.7 0-3.5-1.1-4.9s-2.8-2.2-4.5-2.2h-9.9c.1-1 .2-2.1.2-3.2C41.6 8.9 37.3 2 31.8 2S22 8.9 22 17.7c0 1.1.1 2.1.2 3.2h-9.6c-1.8 0-3.4.8-4.5 2.2-1 1.4-1.5 3.2-1.1 4.9l1.8 7.9c-.4.3-.8.6-1.1.9-1.1 1.3-1.6 2.9-1.4 4.6l2 15.5c.4 2.9 2.8 5.1 5.8 5.1h35.8c2.9 0 5.4-2.2 5.8-5.1l2-15.5c.1-1.6-.4-3.3-1.5-4.5M24 17.7C24 10.3 27.6 4 31.8 4s7.8 6.3 7.8 13.7c0 1.1-.1 2.2-.2 3.2H24.3c-.2-1-.3-2.1-.3-3.2M9 27.6c-.3-1.1 0-2.3.7-3.2s1.8-1.4 3-1.4h38.6c1.2 0 2.2.5 3 1.4s1 2.1.7 3.2l-1.7 7.5c-.5-.1-.9-.2-1.4-.2H12.1c-.5 0-1 .1-1.4.2zm46.6 13.6-2 15.5c-.2 1.9-1.9 3.3-3.8 3.3H14.1c-1.9 0-3.5-1.4-3.8-3.3l-2-15.5c-.1-1.1.2-2.2.9-3s1.8-1.3 2.9-1.3h39.7c1.1 0 2.1.5 2.9 1.3.7.8 1.1 1.9.9 3" />
  </svg>
);
export default IconBag;
