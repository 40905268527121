import * as React from "react";
const IconCertificateAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.1 12.1 42.9 3.2c-.9-.8-2-1.2-3.1-1.2H14c-2.6 0-4.7 2.2-4.7 4.8v50.4c0 2.7 2.1 4.8 4.7 4.8h11.3c.6 0 1-.4 1-1s-.4-1-1-1H14c-1.5 0-2.7-1.3-2.7-2.8V6.8C11.3 5.3 12.5 4 14 4h25.7c.6 0 1.3.2 1.8.7l10.2 8.9c.6.5 1 1.3 1 2.1v41.5c0 1.5-1.2 2.8-2.7 2.8-.6 0-1 .4-1 1s.4 1 1 1c2.6 0 4.7-2.2 4.7-4.8V15.7c0-1.4-.6-2.7-1.6-3.6" />
    <path d="m46.2 49.9-5-.7-2.2-4.6c-.3-.7-1.5-.7-1.8 0L35 49.2l-5 .7c-.4.1-.7.3-.8.7s0 .8.2 1l3.6 3.6-.9 5.1c-.1.4.1.8.4 1s.7.2 1.1.1l4.5-2.4 4.5 2.4c.1.1.3.1.5.1s.4-.1.6-.2c.3-.2.5-.6.4-1l-.9-5.1 3.6-3.6c.3-.3.4-.7.2-1-.1-.3-.4-.6-.8-.7m-4.8 4.3c-.2.2-.3.6-.3.9l.6 3.6-3.1-1.7c-.1-.1-.3-.1-.5-.1s-.3 0-.5.1l-3.1 1.7.6-3.6c.1-.3-.1-.6-.3-.9l-2.6-2.6 3.5-.5c.3 0 .6-.3.8-.6l1.6-3.2 1.6 3.2c.1.3.4.5.8.6l3.5.5zM19.5 14.6H33c.6 0 1-.4 1-1s-.4-1-1-1H19.5c-.6 0-1 .4-1 1s.5 1 1 1M19.5 23.4h23.2c.6 0 1-.4 1-1s-.4-1-1-1H19.5c-.6 0-1 .4-1 1s.5 1 1 1M19.5 33.3h23.2c.6 0 1-.4 1-1s-.4-1-1-1H19.5c-.6 0-1 .4-1 1s.5 1 1 1" />
  </svg>
);
export default IconCertificateAlt1;
