import * as React from "react";
const IconShoppingBag = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M48.8 17.2h-9C39.3 8.3 36 2 32 2s-7.3 6.3-7.8 15.2h-9c-2.2 0-4 1.8-4 4V58c0 2.2 1.8 4 4 4h33.6c2.2 0 4-1.8 4-4V21.2c0-2.3-1.8-4-4-4M32 4c2.5 0 5.3 5.2 5.8 13.2H26.2C26.7 9.2 29.5 4 32 4m18.8 54c0 1.1-.9 2-2 2H15.2c-1.1 0-2-.9-2-2V21.2c0-1.1.9-2 2-2h33.6c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconShoppingBag;
