import * as React from "react";
const IconSavingPig = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.3 23.8c-.5-.2-1.1.2-1.2.7-1 3.4-2 3.9-2.1 3.9-.2.1-.4-.1-.5-.1-.4-.4-1-.4-1.4 0v.1C53.6 19 43.4 11.9 31.2 11.9c-2.4 0-4.8.3-7.1.8l-.9.3-.5-.7c-1.2-1.6-3-2.9-5.3-3.6-1.8-.6-6.5-.8-6.8-.8-.3-.1-.6.1-.8.3-.2.3-.2.6-.2.9l3 9.9-4.2 6-5.7 1.6c-.4.1-.7.5-.7 1v10c0 .4.3.8.7 1l5.6 1.6.3.5c1.5 3.1 3.9 5.8 6.9 7.9l.7.5-.8 5.9c0 .3 0 .6.2.8s.5.3.8.3h8.8c.3 0 .6-.2.8-.4l2-2.8 11.2-.9 2.4 3.7c.2.3.5.5.8.5h8.7c.3 0 .6-.1.8-.3s.3-.5.2-.8l-1.3-9.5.4-.5c3.4-3.6 5.2-7.9 5.2-12.5 0-.9-.1-1.7-.2-2.6.3.2.8.5 1.5.5.2 0 .4 0 .6-.1 1.5-.4 2.7-2.1 3.6-5.3.2-.5-.1-1.1-.6-1.3M49.9 43.7l-.7.8c-.2.2-.3.5-.3.8l1.2 8.9H43l-2.4-3.7c-.3-.4-.6-.5-1-.5l-12.3 1c-.3 0-.6.2-.7.4l-1.9 2.8h-7.1l.7-5.5c0-.4-.1-.7-.4-1l-1.2-.8c-2.7-1.9-4.9-4.4-6.2-7.1l-.5-1c-.1-.3-.4-.4-.6-.5L4 36.8v-8.4l5.3-1.5c.2-.1.4-.2.5-.4l4.7-6.7c.2-.3.2-.6.1-.9l-2.8-9c1.5.1 3.8.3 4.8.7 1.9.6 3.4 1.6 4.3 2.9l.9 1.3c.2.3.7.5 1.1.4l1.5-.4c2.1-.5 4.4-.8 6.6-.8 12.9 0 23.3 8.4 23.3 18.6.2 4-1.4 7.8-4.4 11.1" />
  </svg>
);
export default IconSavingPig;
