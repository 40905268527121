import * as React from "react";
const IconCementTruck = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.7 34.3-5.6-10.2c-.4-.6-1-1-1.8-1h-7.7c-1.3 0-2.3 1-2.3 2.3v1.1H34c.9-2.7.8-5.7-.2-8.4-1.3-3.2-3.8-5.7-7-6.9l-6.9-2.6c-6-2.3-12.6.4-15.4 6.1-2.8 5.8-.7 12.6 4.8 15.9l5.9 3.5H6.4c-1 0-2 .7-2.2 1.7l-2 7.5c-.2.7 0 1.5.5 2.1l3.8 4.5c.4.5 1.1.8 1.8.8h2.4c.5 3.1 3.2 5.5 6.4 5.5 2.4 0 4.5-1.3 5.6-3.2 1.1 1.9 3.2 3.2 5.6 3.2 3.2 0 5.9-2.4 6.4-5.5h6.9c.5 3.1 3.2 5.5 6.4 5.5s5.9-2.4 6.4-5.5h2.7c.6 0 1.2-.2 1.5-.6l2.6-2.3c.5-.4.8-1.1.8-1.7V35.4c0-.4-.1-.8-.3-1.1m-15-9.2h7.7l4.9 9H46.4v-8.7q0-.3.3-.3M6.2 15.6c1.8-3.7 5.4-5.8 9.2-5.8 1.2 0 2.5.2 3.7.7l6.9 2.6c2.7 1 4.8 3.1 5.9 5.8s1 5.7-.3 8.3l-.6 1.2c-1.2 2.6-3.5 4.5-6.3 5.4-2.7.8-5.7.5-8.2-1L10.1 29c-4.5-2.9-6.2-8.6-3.9-13.4m26.7 13.5.3-.7h11.2V34H28.5c1.9-1.1 3.4-2.8 4.4-4.9m-16 25.1c-2.5 0-4.5-2-4.5-4.5s2-4.5 4.5-4.5 4.5 2 4.5 4.5-2 4.5-4.5 4.5m11.2 0c-2.5 0-4.5-2-4.5-4.5s2-4.5 4.5-4.5 4.5 2 4.5 4.5-2 4.5-4.5 4.5m19.8 0c-2.5 0-4.5-2-4.5-4.5s2-4.5 4.5-4.5 4.5 2 4.5 4.5-2 4.5-4.5 4.5m12-7.9-2.6 2.3s-.1.1-.2.1h-2.7c-.5-3.1-3.2-5.5-6.4-5.5s-5.9 2.4-6.4 5.5h-6.9c-.5-3.1-3.2-5.5-6.4-5.5-2.4 0-4.5 1.3-5.6 3.2a6.47 6.47 0 0 0-5.6-3.2c-3.2 0-5.9 2.4-6.4 5.5H8.1c-.1 0-.2 0-.2-.1l-3.8-4.5c-.1-.1-.1-.2-.1-.3l2.1-7.5c0-.1.2-.2.3-.2H60v10c0 .1 0 .2-.1.2" />
  </svg>
);
export default IconCementTruck;
