import React, { useState, useEffect } from 'react';
import { useNavigation } from 'context/NavigationContext';
import { BringzzPageContainer, BringzzIcon, BringzzButton, BringzzText, BringzzTabs, BringzzTab, BringzzTabPanel, BringzzInput } from "@bringzz/components";
import BringzzChatRow from 'components/BringzzChatRow';
import useUser from 'hooks/useUser';
import useRequest from 'hooks/useRequest';
import { useSocket } from 'context/SocketContext';

const ChatsPage = () => {
    const { backPress, navigate } = useNavigation();

    const [searchQuery, setSearchQuery] = useState("");
    const { onRefetchData } = useSocket();
    const [listType, setListType] = useState("chats");

    const { getUser } = useUser();
    const user = getUser();
    const [chats, setChats] = useState([])
    const { sendRequest, data, error, loading } = useRequest();
    // Function to handle chat deletion
    const handleDeleteChat = async (chatId) => {
        try {
            const response = await sendRequest({
                method: 'DELETE',
                url: `/module/chat/deleteChat`,
                data: { chat_id: chatId, user_id: user.id },
            });

            if (response.status === 'success') {
                setChats(prevChats => prevChats.filter(chat => chat.chatId !== chatId));
            } else {
                console.error(response.message);
            }
        } catch (error) {
            console.error(error);
        }
    };
    // Function to toggle archive/unarchive chat
    const handleToggleArchive = async (chatId) => {
        try {
            const response = await sendRequest({
                method: 'POST',
                url: `/module/chat/toggleArchive`,
                data: { chatId, user_id: user.id },
            });

            if (response.status === 'success') {
                // Optionally refetch the chat list or update the chat's archive status locally
                const updatedChats = chats.map(chat =>
                    chat.chatId === chatId ? { ...chat, isArchived: !chat.isArchived } : chat
                );
                setChats(updatedChats);
            } else {
                console.error(response.message);
            }
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        const fetchChats = async (data) => {
            try {
                const response = await sendRequest({
                    method: 'POST',
                    url: `/module/chat/getChatList`,
                    data: { listType, userId: user.id },
                });

                if (response.status === "success") {
                    setChats(response.data.filter(item =>
                        item.recipient.fullName.toLowerCase().includes(searchQuery.toLowerCase())
                    ));
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchChats();

        const cleanupRefetchData = onRefetchData(fetchChats);

        return () => {
            if (cleanupRefetchData) {
                cleanupRefetchData();
            }
        };
    }, [searchQuery, onRefetchData, sendRequest, user.id, listType]);

    return (
        <BringzzPageContainer
            header={{
                left: <BringzzIcon
                    size="22"
                    icon="IconChevronLeft"
                    folder="LineIcons"
                    className="cursor-pointer"
                    onClick={backPress}
                />,
                title: <BringzzText tag='h2'> {listType.slice(0, 1).toLocaleUpperCase() + listType.slice(1)} </BringzzText>,
                right: <BringzzIcon
                    size="20"
                    icon="IconArchive"
                    folder="LineIcons"
                    className="cursor-pointer"
                    onClick={(event) => {
                        setListType(listType === "chats" ? "archived" : "chats")
                    }}
                />
            }}
            headerHover={true}
            footerHover={true}
            footer={
                <div className='p-4 flex space-x-2'>
                    <div className='w-full'>
                        <BringzzInput
                            className='shadow-xl border-slate-200 border bg-gray-100'
                            placeholder='Search...'
                            onChange={(e, newIsValid, newValue) => setSearchQuery(newValue)}
                            icon={{
                                left: <BringzzIcon
                                    size="20"
                                    icon="IconSearchAlt"
                                    folder="LineIcons"
                                    className="cursor-pointer"
                                />,
                            }}
                        />
                    </div>
                    {user.userRole !== 1 && <BringzzIcon
                        size="20"
                        icon="IconWrite"
                        folder="LineIcons"
                        className="shadow-xl cursor-pointer rounded-full text-slate-500 flex justify-center items-center px-3 border-slate-200 border bg-gray-100"
                        onClick={() => {
                            navigate('/settings/chats/new')
                        }}
                    />}
                </div>
            }
        >
            <div className='py-12'>
                {loading && <div className='h-[1px] bg-magic-lilac w-full'></div>}
                {chats.map((chat, index) => {
                    return (
                        <BringzzChatRow onArchive={handleToggleArchive} onDelete={handleDeleteChat} key={index} chat={chat} onClick={() => navigate(`/settings/chats/${chat.chatId}`, false)} />
                    )
                })}
                {chats.length == 0 &&
                    <BringzzText tag='h4' className='text-xs text-center w-full mt-4'>
                        Empty... Use the new chat buttom to start a conversation.
                    </BringzzText>
                }
            </div>
        </BringzzPageContainer>

    )
}

export default ChatsPage;