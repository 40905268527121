import * as React from "react";
const IconArrowsScrollH = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m41.6 32 19.1-19.4c.4-.4.4-1 0-1.4s-1-.4-1.4 0L39.5 31.3c-.4.4-.4 1 0 1.4l19.8 20.1c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4zM4.7 11.2c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4L22.4 32 3.3 51.4c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3l19.8-20.1c.4-.4.4-1 0-1.4z" />
  </svg>
);
export default IconArrowsScrollH;
