import * as React from "react";
const IconNail = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M48 16.4c-.2-.2-.3-.3-.5-.4v-1.7C47.5 7.5 42 2 35.3 2h-6.6C22 2 16.5 7.5 16.5 14.2V16C12 20.2 9.4 26.2 9.4 32.4V50c0 6.6 5.4 12 12 12h21.3c6.6 0 12-5.4 12-12V32.4c-.1-6-2.4-11.7-6.7-16m-29.5-2.2C18.5 8.6 23.1 4 28.7 4h6.6c5.6 0 10.2 4.6 10.2 10.2v29.5c0 1.1-.9 2-2 2h-23c-1.1 0-2-.9-2-2zM52.6 50c0 5.5-4.5 10-10 10H21.4c-5.5 0-10-4.5-10-10V32.4c0-5 1.8-9.8 5.1-13.6v24.9c0 2.2 1.8 4 4 4h23c2.2 0 4-1.8 4-4V18.8c3.3 3.8 5.1 8.5 5.1 13.5z" />
  </svg>
);
export default IconNail;
