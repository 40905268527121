import * as React from "react";
const IconSpeechBubble14 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 10.9C15.5 10.9 2 20.4 2 32c0 5.2 2.6 10.1 7.5 13.9l-6.6 5.4c-.3.3-.5.7-.3 1.1.1.4.5.7.9.7H32c16.5 0 30-9.4 30-21.1 0-11.6-13.5-21.1-30-21.1m0 40.2H6.3l5.4-4.4c.2-.2.4-.5.4-.8s-.2-.6-.4-.8C6.7 41.5 4 36.9 4 32c0-10.5 12.6-19.1 28-19.1S60 21.5 60 32 47.4 51.1 32 51.1" />
    <path d="M18.3 30.3h-.1c-1.1 0-2 .9-2 2s.9 2 2 2h.1c1.1 0 2-.9 2-2s-.9-2-2-2M32.1 30.3H32c-1.1 0-2 .9-2 2s.9 2 2 2h.1c1.1 0 2-.9 2-2s-.9-2-2-2M45.9 30.3h-.1c-1.1 0-2 .9-2 2s.9 2 2 2h.1c1.1 0 2-.9 2-2s-.9-2-2-2" />
  </svg>
);
export default IconSpeechBubble14;
