import * as React from "react";
const IconPicture = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51 9.7H33V3c0-.6-.4-1-1-1s-1 .4-1 1v6.7H13c-2.2 0-4 1.8-4 4V58c0 2.2 1.8 4 4 4h38c2.2 0 4-1.8 4-4V13.7c0-2.2-1.8-4-4-4M24.4 35.6c-.5-1.1-1.3-1.8-2.1-2.4-.2-.2-.4-.3-.5-.4.1-.1.3-.3.5-.4.7-.6 1.6-1.3 2.1-2.4s.4-2.2.4-3.2v-.7c.2 0 .4 0 .7.1 1 .1 2.2.2 3.4-.2 1.1-.4 1.9-1.3 2.6-2.1.1-.1.4-.4.6-.7l.7.7c.7.8 1.4 1.6 2.5 2s2.3.3 3.4.2c.3 0 .5 0 .7-.1v.7c-.1 1-.1 2.2.4 3.2.5 1.1 1.3 1.8 2.1 2.4.2.2.4.3.5.4-.1.1-.3.3-.6.5-.8.6-1.6 1.3-2.1 2.4s-.4 2.2-.4 3.3v.7c-.2 0-.4 0-.7-.1-1-.1-2.2-.2-3.4.2-1.1.4-1.9 1.3-2.6 2.1-.1.1-.4.4-.6.7l-.7-.7c-.7-.8-1.4-1.6-2.5-2q-.9-.3-1.8-.3c-.5 0-1 0-1.6.1-.3 0-.5 0-.7.1V39c.1-1.1.2-2.3-.3-3.4M53 58c0 1.1-.9 2-2 2H33V44.2c.3-.3.7-.7 1.1-1.2.6-.6 1.1-1.2 1.7-1.5.7-.3 1.6-.2 2.4-.1 1.5.2 2.1.1 2.5-.3l.1-.1c.3-.4.4-.9.3-2.4 0-.8-.1-1.6.2-2.3.3-.6.9-1.1 1.5-1.6 1.2-1 1.6-1.4 1.6-2s-.4-1-1.6-2c-.6-.5-1.3-1-1.5-1.7-.3-.7-.2-1.5-.2-2.4.1-1.3 0-1.9-.4-2.3s-1-.5-2.5-.3c-.9.1-1.8.2-2.5-.1-.6-.2-1.2-.8-1.8-1.5-1-1.2-1.5-1.6-2.1-1.6-.7 0-1.3.6-2.2 1.6-.6.6-1.1 1.2-1.7 1.5-.7.3-1.6.2-2.4.1-1.5-.2-2.1-.1-2.5.3l-.1.1c-.3.4-.4 1-.3 2.4 0 .8.1 1.6-.2 2.3-.3.6-.9 1.2-1.5 1.6-1.2 1-1.6 1.4-1.6 2s.4 1 1.6 2c.6.5 1.3 1 1.5 1.7.3.6.3 1.4.2 2.4-.1 1.3 0 1.9.4 2.3s1 .5 2.5.3c.4 0 .8-.1 1.3-.1.4 0 .8.1 1.2.2.6.2 1.2.8 1.8 1.5.5.5.8.9 1.1 1.1V60H13c-1.1 0-2-.9-2-2V13.7c0-1.1.9-2 2-2h38c1.1 0 2 .9 2 2z" />
    <path d="M32 36.9c2.3 0 4.1-1.8 4.1-4.1s-1.8-4.1-4.1-4.1-4.1 1.8-4.1 4.1 1.8 4.1 4.1 4.1m0-6.3c1.2 0 2.1.9 2.1 2.1s-.9 2.1-2.1 2.1-2.1-.9-2.1-2.1c0-1.1.9-2.1 2.1-2.1" />
  </svg>
);
export default IconPicture;
