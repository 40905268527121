import * as React from "react";
const IconTable3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.7 24.6 48.9 13.4c-.2-.2-.4-.2-.7-.2H15.8c-.2 0-.5.1-.7.2L2.3 24.6c-.3.3-.4.7-.3 1.1s.5.6.9.6h7.3v23.5c0 .6.4 1 1 1s1-.4 1-1V26.4h6.3v14c0 .6.4 1 1 1s1-.4 1-1v-14h22.8v14c0 .6.4 1 1 1s1-.4 1-1v-14h6.3v23.5c0 .6.4 1 1 1s1-.4 1-1V26.4H61c.4 0 .8-.3.9-.6s.1-.9-.2-1.2m-56-.2 10.5-9.3h31.6l10.5 9.3z" />
  </svg>
);
export default IconTable3;
