import * as React from "react";
const IconChampagne = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M45.6 52.3V34.5c0-.1.1-2.5 0-4.5v-.1c0-.1 0-.2-.1-.3 0-.4-.1-.8-.1-1.1-.4-2.1-.9-4-2.5-6.7-1.3-2.3-3.5-4.1-4.4-4.8v-3.5c1.7-1.3 1.6-3.4 1.6-3.4V6.4c-.3-3.4-3-4.3-4.4-4.3h-7.5c-.6 0-1.1.1-1.2.2-3.1 1-3.2 3.9-3.2 4s-.1 2.5 0 4.2c.1 1.6 1 2.6 1.6 3.1v3.5c-5.5 3.8-6.7 9.7-7 12.5-.1.1-.1.3-.1.4v1.3c0 .2-.1 18 0 26 0 2.7 2.2 4.9 4.9 4.9h17.5c2.7 0 4.9-2.2 4.9-4.9v-5M20.4 31.1v-.2h23.2c.1 1.7 0 3.5 0 3.5v16.9H20.4zm6.7-12.8c.3-.2.5-.5.5-.8V13c0-.4-.2-.7-.5-.9 0 0-1.1-.6-1.1-1.8-.1-1.7 0-4.1 0-4.1s0-1.6 1.7-2.1c0 0 .3-.1.6-.1h7.4c.4 0 2.2.2 2.4 2.4v3.7s0 1.3-1.1 2c-.3.2-.5.5-.5.9v4.5c0 .3.2.6.4.8 0 0 2.8 1.9 4.2 4.4 1.5 2.5 2 4.2 2.3 6.1v.1H20.6c.4-2.8 1.9-7.6 6.5-10.6M40.7 60H23.2c-1.6 0-2.9-1.3-2.9-2.9v-3.8h23.3v3.8c0 1.6-1.3 2.9-2.9 2.9" />
    <path d="M37.3 36c-1.4-1.4-3.6-1.7-5.3-.9-.6-.3-1.3-.5-2-.5-1.2 0-2.3.4-3.1 1.2-.9.8-1.4 2-1.4 3.2 0 1.3.5 2.6 1.4 3.6l3.8 3.8c.4.4.9.6 1.4.6s1-.2 1.4-.6l3.9-3.9c.9-.9 1.4-2 1.3-3.3-.1-1.1-.5-2.3-1.4-3.2m-1.4 5.2L32 45.1l-3.8-3.8c-.6-.6-.9-1.3-.8-2.1 0-.7.3-1.4.8-1.8s1.1-.7 1.7-.7c.5 0 1 .2 1.5.5.3.2.8.2 1.1 0 1-.7 2.4-.6 3.3.3.5.5.8 1.2.8 1.9 0 .6-.2 1.3-.7 1.8" />
  </svg>
);
export default IconChampagne;
