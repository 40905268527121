import * as React from "react";
const IconRestaurantMenu = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M48.1 25.9H46c-.5-6.1-5.4-11-11.5-11.5v-2.2c0-.6-.4-1-1-1s-1 .4-1 1v2.2c-6.1.5-11 5.4-11.5 11.5h-2.1c-.6 0-1 .4-1 1s.4 1 1 1h29.2c.6 0 1-.4 1-1s-.5-1-1-1m-14.6-9.6c5.5 0 10 4.2 10.5 9.5H23c.5-5.3 5-9.5 10.5-9.5" />
    <path d="M55 2H12C9.8 2 8 3.8 8 6v11.1H6c-.6 0-1 .4-1 1s.4 1 1 1h2V31H6c-.6 0-1 .4-1 1s.4 1 1 1h2v11.9H6c-.6 0-1 .4-1 1s.4 1 1 1h2V58c0 2.2 1.8 4 4 4h43c2.2 0 4-1.8 4-4V6c0-2.2-1.8-4-4-4m2 56c0 1.1-.9 2-2 2H12c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2h43c1.1 0 2 .9 2 2z" />
    <path d="M39.6 37.2H27.4c-.6 0-1 .4-1 1s.4 1 1 1h12.1c.6 0 1-.4 1-1s-.4-1-.9-1M39.6 45.6H27.4c-.6 0-1 .4-1 1s.4 1 1 1h12.1c.6 0 1-.4 1-1s-.4-1-.9-1" />
  </svg>
);
export default IconRestaurantMenu;
