import React, { useState } from 'react';
import { BringzzText, BringzzBottomDrawer, BringzzRadioList, BringzzRadio, BringzzIcon } from "@bringzz/components";
import { useTranslation } from 'react-i18next';
import { useLanguage } from 'context/LanguageContext';
import useRequest from 'hooks/useRequest';

let state = {
    checked: (
        <BringzzIcon
            icon="IconCheck"
            library="Custom"
            className="h-5 w-5 p-1 bg-magic-lilac text-light rounded-full"
            size="12"
        />
    ),
    unChecked: (
        <div></div>
    ),
};

export const LanguageSwitch = () => {
    const { t } = useTranslation();
    const { language, changeLanguage } = useLanguage();
    const { sendRequest, loading } = useRequest();

    const [languageDrawerOpened, setLanguageDrawerOpened] = useState(false)
    const [selectedPrefeerredLanguage, setSelectedPrefeerredLanguage] = useState(language == "en" ? "English" : "German")

    const handleChangeLanguage = (lng) => {
        changeLanguage(lng);
    };

    return (

        <div>
            <div className='py-2 cursor-pointer' onClick={() => setLanguageDrawerOpened(!languageDrawerOpened)}>
                <BringzzText tag='h4' className="font-bold">{t("app_settings_preferred_langauage_label")}</BringzzText>
                <BringzzText tag='h4' className="font-normal text-midnight-blue/60">{selectedPrefeerredLanguage}</BringzzText>
            </div>

            <BringzzBottomDrawer
                isOpen={languageDrawerOpened}
                close={() => setLanguageDrawerOpened(false)}
                title='Select Preferred language'
            >
                <div className='px-4 py-6'>
                    <BringzzRadioList
                        onChange={async (newValue) => {
                            setSelectedPrefeerredLanguage(newValue)
                            handleChangeLanguage(newValue == "German" ? "de" : "en")
                            await sendRequest({
                                method: 'POST',
                                url: `/settings/app/language`,
                                data: { preferredLanguage: newValue == "German" ? "de" : "en" }
                            }).then((response) => {
                                if (response.status === 'success') {
                                    // TODO: Toast
                                }
                            }).catch((error) => {
                                console.error(error);
                            })
                            setLanguageDrawerOpened(false)
                        }}
                        state={state}
                        className="rounded-xl w-full text-center"
                        defaultValue={selectedPrefeerredLanguage}
                    >
                        <BringzzRadio value="German"
                            className="p-2 py-4 hover:bg-slate- border-1 border-t border-gray-400 flex"
                        >
                            <div>
                                German
                            </div>
                        </BringzzRadio>
                        <BringzzRadio value="English"
                            className="p-2 py-4 hover:bg-slate- border-1 border-t border-gray-400">
                            <div>
                                English
                            </div>
                        </BringzzRadio>
                    </BringzzRadioList>
                </div>
            </BringzzBottomDrawer>
        </div>
    )

}