import * as React from "react";
const IconProfile = (props) => (
  <svg
    width={props.size || "48"}
    height={props.size || "48"}
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.5 19C17.5 17.6044 17.5 16.9067 17.3278 16.3389C16.94 15.0605 15.9395 14.06 14.6611 13.6722C14.0933 13.5 13.3956 13.5 12 13.5H7C5.60444 13.5 4.90665 13.5 4.33886 13.6722C3.06045 14.06 2.06004 15.0605 1.67224 16.3389C1.5 16.9067 1.5 17.6044 1.5 19M14 5.5C14 7.98528 11.9853 10 9.5 10C7.01472 10 5 7.98528 5 5.5C5 3.01472 7.01472 1 9.5 1C11.9853 1 14 3.01472 14 5.5Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default IconProfile;
