import * as React from "react";
const IconShip = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M11.9 42.7v4.5c0 1.2 1 2.2 2.2 2.2s2.2-1 2.2-2.2v-4.8c0-.2 0-.4-.1-.5l-4.1-16.6c-.1-.5 0-.9.2-1.3s.6-.7 1.1-.8L32 19.1l18.4 4.1c.5.1.9.4 1.1.8s.3.9.2 1.3l-4.1 16.6c0 .2-.1.4-.1.5v4.8c0 1.2 1 2.2 2.2 2.2s2.2-1 2.2-2.2v-4.5l4-16.3c.4-1.6.1-3.3-.8-4.8s-2.3-2.4-3.9-2.8l-1.9-.4V9.1c0-4-3.3-7.3-7.3-7.3H21.8c-4 0-7.3 3.3-7.3 7.3v9.3l-1.9.4c-1.7.4-3.1 1.4-3.9 2.8-.9 1.4-1.2 3.1-.8 4.8zM19 9.1c0-1.6 1.3-2.8 2.8-2.8h20.4c1.6 0 2.8 1.3 2.8 2.8v8.3l-12.5-2.8c-.3-.1-.7-.1-1 0L19 17.4zM56.2 52.7c-3.1 0-4.7 2-5.8 3.4-1 1.3-1.4 1.7-2.3 1.7s-1.3-.4-2.3-1.7c-1.1-1.4-2.7-3.4-5.8-3.4s-4.7 2-5.8 3.4c-1 1.3-1.4 1.7-2.3 1.7s-1.3-.4-2.3-1.7c-1.1-1.4-2.7-3.4-5.8-3.4s-4.7 2-5.8 3.4c-1 1.3-1.4 1.7-2.3 1.7-.8 0-1.3-.4-2.3-1.7-1.1-1.4-2.7-3.4-5.8-3.4-1.2 0-2.2 1-2.2 2.2s1 2.2 2.2 2.2c.8 0 1.3.4 2.3 1.7 1.1 1.4 2.7 3.4 5.8 3.4s4.7-2 5.8-3.4c1-1.3 1.4-1.7 2.3-1.7s1.3.4 2.3 1.7c1.1 1.4 2.7 3.4 5.8 3.4s4.7-2 5.8-3.4c1-1.3 1.4-1.7 2.3-1.7s1.3.4 2.3 1.7c1.1 1.4 2.7 3.4 5.8 3.4s4.7-2 5.8-3.4c1-1.3 1.4-1.7 2.3-1.7 1.2 0 2.2-1 2.2-2.2s-1-2.2-2.2-2.2" />
  </svg>
);
export default IconShip;
