import * as React from "react";
const IconShovelCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.7 15.4 48.4 2c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l5.9 6-4.8 4.8-1.5-1.5c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l1.5 1.5-18.9 19.2-5.7-5.8c-.5-.5-1.3-.5-1.8 0L5 44.4c-4 4.1-4 10.7 0 14.8 2 2 4.6 3.1 7.3 3.1 2.8 0 5.4-1.1 7.3-3.1L35 43.8c.5-.5.5-1.3 0-1.8l-5.7-5.8 18.9-19.1 1.5 1.5c.4.4 1 .4 1.4 0s.4-1 0-1.4l-1.5-1.5 4.8-4.8 5.9 6c.4.4 1 .4 1.4 0 .4-.5.4-1.1 0-1.5M33.1 42.9 18.3 57.8c-1.6 1.6-3.7 2.5-5.9 2.5s-4.3-.9-5.9-2.5c-3.3-3.3-3.3-8.7 0-12l14.8-14.9 5.2 5.3-1.5 1.5c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3l1.5-1.5z" />
  </svg>
);
export default IconShovelCopy;
