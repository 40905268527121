import * as React from "react";
const IconHierchyAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M12.8 52.2c3.4 0 6.1-2.8 6.1-6.1 0-3.4-2.7-6.1-6.1-6.1s-6.2 2.7-6.2 6c0 3.4 2.8 6.2 6.2 6.2m0-10.3c2.3 0 4.1 1.9 4.1 4.1s-1.9 4.1-4.1 4.1c-2.3 0-4.1-1.9-4.1-4.1s1.8-4.1 4.1-4.1M12.9 55.9c-4 0-7.9 1.6-10.6 4.4-.4.4-.4 1 0 1.4s1 .4 1.4 0c2.3-2.4 5.7-3.8 9.2-3.8s6.8 1.4 9.2 3.8c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4-2.7-2.8-6.6-4.4-10.6-4.4M44.9 46c0 3.4 2.8 6.1 6.1 6.1s6.1-2.8 6.1-6.1c0-3.4-2.8-6.1-6.1-6.1s-6.1 2.8-6.1 6.1m10.2 0c0 2.3-1.9 4.1-4.1 4.1s-4.1-1.9-4.1-4.1 1.9-4.1 4.1-4.1 4.1 1.9 4.1 4.1M61.7 60.3c-2.7-2.8-6.6-4.4-10.6-4.4s-7.9 1.6-10.6 4.4c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3c2.3-2.4 5.7-3.8 9.2-3.8s6.8 1.4 9.2 3.8c.4.4 1 .4 1.4 0s.4-1 0-1.4M12.8 35.5c.6 0 1-.4 1-1v-3.9h36.3v3.9c0 .6.4 1 1 1s1-.4 1-1v-4.9c0-.6-.4-1-1-1H33v-2.9c0-.6-.4-1-1-1s-1 .4-1 1v2.9H12.8c-.6 0-1 .4-1 1v4.9c0 .5.5 1 1 1M31.9 14.3c3.4 0 6.1-2.8 6.1-6.1S35.3 2 31.9 2s-6.1 2.8-6.1 6.1 2.7 6.2 6.1 6.2m0-10.3C34.2 4 36 5.9 36 8.1s-1.9 4.1-4.1 4.1c-2.3 0-4.1-1.9-4.1-4.1S29.6 4 31.9 4M22.8 23.8c2.3-2.4 5.7-3.8 9.2-3.8s6.8 1.4 9.2 3.8c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4C39.9 19.6 36 18 32 18s-7.9 1.6-10.6 4.4c-.4.4-.4 1 0 1.4s1.1.4 1.4 0" />
  </svg>
);
export default IconHierchyAlt;
