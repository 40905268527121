import * as React from "react";
const IconDna = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M42.3 2c-.6 0-1 .4-1 1v5.9H22.7V3c0-.6-.4-1-1-1s-1 .4-1 1v18.2c0 5.1 3.4 9.4 8.1 10.8-4.7 1.4-8.1 5.7-8.1 10.8V61c0 .6.4 1 1 1s1-.4 1-1v-3.9h18.6V61c0 .6.4 1 1 1s1-.4 1-1V42.8c0-5.1-3.4-9.4-8.1-10.8 4.7-1.4 8.1-5.7 8.1-10.8V3c0-.6-.4-1-1-1m-1 8.9v7.8H22.7v-7.8zM22.7 55.1v-7.8h18.6v7.8zm18.6-12.3v2.4H22.7v-2.4c0-5.1 4.2-9.3 9.3-9.3s9.3 4.2 9.3 9.3M32 30.5c-5.1 0-9.3-4.2-9.3-9.3v-.4h18.6v.4c0 5.1-4.2 9.3-9.3 9.3" />
  </svg>
);
export default IconDna;
