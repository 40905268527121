import * as React from "react";
const IconSpeechBubble34 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M45.5 35.4H15.2c-2.7 0-4.9 2.2-4.9 4.9v2.5l-7.8 3.9c-.3.1-.5.4-.5.8q0 .6.6.9l7.8 3.9v2.5c0 2.7 2.2 4.9 4.9 4.9h30.3c2.7 0 4.9-2.2 4.9-4.9V40.2c-.1-2.6-2.3-4.8-5-4.8m2.9 19.3c0 1.6-1.3 2.9-2.9 2.9H15.2c-1.6 0-2.9-1.3-2.9-2.9v-3.1q0-.6-.6-.9l-6.5-3.3 6.5-3.3c.3-.2.6-.5.6-.9v-3.1c0-1.6 1.3-2.9 2.9-2.9h30.3c1.6 0 2.9 1.3 2.9 2.9zM61.4 15.6l-7.8-3.9V9.3c0-2.7-2.2-4.9-4.9-4.9H18.5c-2.7 0-4.9 2.2-4.9 4.9v14.5c0 2.7 2.2 4.9 4.9 4.9h30.3c2.7 0 4.9-2.2 4.9-4.9v-2.5l7.8-3.9c.3-.2.6-.5.6-.9-.1-.4-.3-.7-.7-.9m-9.2 4.2c-.3.2-.6.5-.6.9v3.1c0 1.6-1.3 2.9-2.9 2.9H18.5c-1.6 0-2.9-1.3-2.9-2.9V9.3c0-1.6 1.3-2.9 2.9-2.9h30.3c1.6 0 2.9 1.3 2.9 2.9v3.1q0 .6.6.9l6.5 3.3z" />
    <path d="M21.2 45.5h-.5c-1.1 0-2 .9-2 2s.9 2 2 2h.5c1.1 0 2-.9 2-2s-.9-2-2-2M30.9 45.5h-.5c-1.1 0-2 .9-2 2s.9 2 2 2h.5c1.1 0 2-.9 2-2s-.9-2-2-2M40.6 45.5h-.5c-1.1 0-2 .9-2 2s.9 2 2 2h.5c1.1 0 2-.9 2-2s-.9-2-2-2M23.8 14.5h-.5c-1.1 0-2 .9-2 2s.9 2 2 2h.5c1.1 0 2-.9 2-2s-.9-2-2-2M33.5 14.5H33c-1.1 0-2 .9-2 2s.9 2 2 2h.5c1.1 0 2-.9 2-2s-.9-2-2-2M43.2 14.5h-.5c-1.1 0-2 .9-2 2s.9 2 2 2h.5c1.1 0 2-.9 2-2s-.9-2-2-2" />
  </svg>
);
export default IconSpeechBubble34;
