import * as React from "react";
const IconLuggage = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.7 16.5H41.2v-1.9c0-4.1-3.3-7.5-7.5-7.5h-3.4c-4.1 0-7.5 3.3-7.5 7.5v1.9H7.3c-2.6 0-4.8 2.2-4.8 4.8v30.8c0 2.6 2.2 4.8 4.8 4.8h49.4c2.6 0 4.8-2.2 4.8-4.8V21.3c0-2.6-2.2-4.8-4.8-4.8M4.5 29.3c5.7-.5 10.3-5 10.8-10.8h33.5c.4 5.8 5 10.4 10.8 10.9v14.7c-5.7.5-10.3 5-10.8 10.8H15.3c-.5-5.7-5-10.3-10.8-10.8zm55-8v6.1c-4.7-.5-8.4-4.2-8.8-8.9h6c1.5 0 2.8 1.3 2.8 2.8m-34.7-6.7c0-3 2.5-5.5 5.5-5.5h3.4c3 0 5.5 2.5 5.5 5.5v1.9H24.8zM7.3 18.5h6c-.5 4.6-4.1 8.3-8.8 8.8v-6c0-1.5 1.3-2.8 2.8-2.8m0 36.4c-1.5 0-2.8-1.3-2.8-2.8v-6c4.6.5 8.3 4.1 8.8 8.8zm49.4 0h-6c.5-4.6 4.1-8.3 8.8-8.8v6c0 1.5-1.3 2.8-2.8 2.8" />
    <path d="M30.3 46.2c.4.4 1 .7 1.6.7s1.2-.2 1.6-.7l5.4-5.4c1.1-1.1 1.7-2.6 1.7-4.2s-.6-3.1-1.7-4.2c-1.9-1.9-4.7-2.3-7-1-.9-.5-1.8-.7-2.8-.7-1.5 0-2.9.5-4 1.6-1.2 1.1-1.8 2.5-1.9 4.2 0 1.7.6 3.4 1.8 4.6zm-3.8-12.6c.7-.7 1.7-1 2.6-1 .8 0 1.6.2 2.2.7.3.2.8.2 1.1 0 1.6-1.1 3.7-.9 5 .5.8.8 1.2 1.7 1.2 2.8 0 1-.4 2-1.2 2.8L32 44.8c-.1.1-.3.1-.4 0l-5.2-5.2c-.8-.8-1.3-2-1.3-3.2.2-1.1.6-2.1 1.4-2.8" />
  </svg>
);
export default IconLuggage;
