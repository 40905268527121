import * as React from "react";
const IconLamp2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.4 44.6 41.8 23.3c-.2-.2-.5-.4-.8-.4h-8V3c0-.6-.4-1-1-1s-1 .4-1 1v19.9h-8c-.3 0-.6.1-.8.4L5.6 44.6c-.3.4-.5 1-.5 1.5v2.3c0 1.4 1.1 2.5 2.5 2.5h10.7C20.1 56.6 26.7 62 32 62s11.9-5.4 13.8-11.2h10.7c1.4 0 2.5-1.1 2.5-2.5V46c-.1-.4-.3-1-.6-1.4M32 60c-3.8 0-9.7-4.2-11.7-9.2h23.4c-2 5-7.9 9.2-11.7 9.2m24.9-11.6c0 .3-.2.5-.5.5H7.5c-.3 0-.5-.2-.5-.5v-2.3c0-.1 0-.2.1-.3l16.3-20.9h17l16.3 20.9c.1.1.1.2.1.3v2.3z" />
  </svg>
);
export default IconLamp2;
