import * as React from "react";
const IconMessagePeople = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M19.5 48.3c4.7 0 8.5-3.8 8.5-8.5s-3.8-8.5-8.5-8.5-8.5 3.8-8.5 8.5 3.8 8.5 8.5 8.5m0-15c3.6 0 6.5 2.9 6.5 6.5s-2.9 6.5-6.5 6.5-6.5-2.9-6.5-6.5 2.9-6.5 6.5-6.5M19.5 53.7c-6.3 0-12.4 2.2-17.2 6.3-.4.4-.5 1-.1 1.4s1 .5 1.4.1c4.4-3.7 10-5.8 15.9-5.8s11.5 2.1 15.9 5.8c.2.2.4.2.6.2.3 0 .6-.1.8-.4.4-.4.3-1.1-.1-1.4-4.7-4-10.8-6.2-17.2-6.2M59.1 2.3H34.5c-1.6 0-2.9 1.3-2.9 2.9v18c0 1 .5 1.9 1.4 2.5.5.3.9.4 1.4.4s1-.1 1.4-.4l5.8-3.3c.1-.1.3-.1.4-.1h17c1.6 0 2.9-1.3 2.9-2.9V5.1c.1-1.6-1.2-2.8-2.8-2.8m.9 17.1c0 .5-.4.9-.9.9h-17c-.5 0-1 .1-1.4.4L34.9 24c-.4.2-.7.1-.9 0-.1-.1-.4-.3-.4-.7v-18c0-.5.4-.9.9-.9h24.6c.5 0 .9.4.9.9z" />
  </svg>
);
export default IconMessagePeople;
