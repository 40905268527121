import * as React from "react";
const IconAirMail = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M26.5 62h-.7c-1.9-.2-3.5-1.3-4.5-2.9l-6.2-10.2-10.2-6.2c-1.6-1-2.7-2.6-2.9-4.5s.4-3.7 1.7-5c2-2 5.2-2.4 7.6-.8l5.7 3.7L29 24c.1-.1.1-.3.1-.4s0-.2-.2-.4L14.9 13c-1.4-1-2.3-2.7-2.5-4.4-.1-1.8.6-3.6 1.8-4.8 1.9-1.9 4.7-2.3 7.1-1.1l18.4 9.4c.2.1.4.1.6-.1l7.4-7.4c1.6-1.6 3.7-2.5 6-2.5 2.2 0 4.4.9 6 2.5s2.5 3.7 2.5 6-.9 4.4-2.5 6l-7.4 7.3c-.2.1-.2.4-.1.6l9.4 18.3c1.2 2.4.8 5.2-1.1 7.1-1.3 1.3-3 1.9-4.8 1.8s-3.4-1-4.4-2.5L40.8 35c-.1-.2-.3-.2-.4-.2s-.2 0-.4.1l-12.2 12 3.7 5.7c1.6 2.4 1.3 5.6-.8 7.6-1 1.2-2.6 1.8-4.2 1.8M8.1 33.4c-1.1 0-2.1.4-2.9 1.2-.9.9-1.3 2.1-1.2 3.4.2 1.3.9 2.3 1.9 3l10.4 6.3c.1.1.3.2.3.3L23 58c.7 1.1 1.7 1.8 3 1.9s2.5-.3 3.4-1.2c1.4-1.4 1.6-3.5.5-5.1l-4.2-6.4c-.3-.4-.2-.9.1-1.3l12.8-12.5c.5-.5 1.2-.8 1.9-.7s1.4.4 1.8 1l10.3 14c.7 1 1.8 1.6 3 1.7s2.4-.3 3.2-1.2c1.3-1.3 1.6-3.2.7-4.7l-9.4-18.3c-.5-1-.3-2.1.5-2.9L58 15c1.2-1.2 1.9-2.8 1.9-4.5s-.7-3.3-1.9-4.6C56.8 4.7 55.2 4 53.5 4s-3.3.7-4.5 1.9l-7.4 7.4c-.8.8-1.9 1-2.9.5L20.4 4.4c-1.6-.8-3.5-.5-4.7.7-.8.8-1.3 2-1.2 3.2s.7 2.3 1.7 3l14.1 10.3c.6.4 1 1.1 1 1.8.1.7-.2 1.4-.7 1.9L17.9 38.1c-.3.3-.9.4-1.3.1l-6.3-4.1c-.7-.5-1.4-.7-2.2-.7m50.7-17.6" />
  </svg>
);
export default IconAirMail;
