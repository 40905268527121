import * as React from "react";
const IconDatabase8 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 47.8h-2.8v-4.1c0-3.3-2.7-6-6-6s-6 2.7-6 6v4.1h-2.8c-.6 0-1 .4-1 1V59c0 .6.4 1 1 1H61c.6 0 1-.4 1-1V48.8c0-.6-.4-1-1-1m-12.7-4.1c0-2.2 1.8-4 4-4s4 1.8 4 4v4.1h-8zM60 58H44.5v-8.2H60zM35.3 40c-2.9.4-5.8.6-8.9.6C13.2 40.6 4 36.7 4 33.3v-5.5c4 3.6 13.4 5.5 22.4 5.5s18.4-1.9 22.4-5.5v4.5c0 .6.4 1 1 1s1-.4 1-1V13.4C50.8 7.3 38.2 4 26.4 4S2.1 7.3 2 13.3v29.400000000000002c0 5.3 10.7 9.4 24.4 9.4 2.1 0 4.3-.1 6.3-.3.5-.1.9-.5.9-1.1-.1-.5-.6-.9-1.1-.9-2 .2-4 .3-6.1.3C13.6 50.1 4 46.2 4 42.7v-5.5c4 3.6 13.4 5.5 22.4 5.5 3.1 0 6.2-.2 9.2-.7.5-.1.9-.6.8-1.1-.1-.6-.6-1-1.1-.9m-8.9-8.8C13.2 31.2 4 27.3 4 23.9v-6.6c4 3.6 13.4 5.5 22.4 5.5s18.4-1.9 22.4-5.5V24c-.1 3.4-9.2 7.2-22.4 7.2m0-25.2c13.2 0 22.4 3.9 22.4 7.3-.1 3.5-9.2 7.3-22.4 7.3C13.2 20.7 4 16.8 4 13.3S13.2 6 26.4 6" />
  </svg>
);
export default IconDatabase8;
