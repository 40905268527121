import * as React from "react";
const IconThunderbolt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M25.1 62c-.3 0-.5-.1-.8-.2-.9-.4-1.5-1.3-1.4-2.3l2.7-22.6c0-.1 0-.1-.1-.2 0 0-.1-.1-.2 0l-9.4 1.8c-.9.2-1.8-.2-2.3-1s-.5-1.7.1-2.5L37.1 2.9c.6-.8 1.6-1.1 2.6-.8.9.4 1.5 1.3 1.4 2.3l-3.2 23.5c0 .1 0 .1.1.2 0 0 .1.1.2 0l9.7-2.4c.9-.2 1.8.1 2.3.9s.5 1.8 0 2.5L27 61.1c-.5.6-1.2.9-1.9.9m.4-27.3c.5 0 1.1.2 1.5.6.5.5.8 1.2.7 1.9L25 59.8s0 .1.1.2c.1 0 .2 0 .2-.1l23.2-32s.1-.1 0-.2-.2-.1-.2-.1L38.6 30c-.7.2-1.5 0-2-.5s-.8-1.2-.7-1.9l3.2-23.5s0-.1-.1-.2-.2 0-.2.1L15.5 36.3s-.1.1 0 .2.2.1.2.1l9.4-1.8c.1 0 .2-.1.4-.1" />
  </svg>
);
export default IconThunderbolt;
