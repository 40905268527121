import * as React from "react";
const IconWorldCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.2 17.2C54.1 10.1 47.3 4.9 39.3 2.9c-2.6-.6-5-.9-7.3-.9-2.5 0-5 .3-7.5 1-7.8 2-14.6 7.2-18.6 14.3Q2 24.2 2 32v.7c.1 5.5 1.8 10.8 4.7 15.5 4.8 7.5 12.6 12.4 21.3 13.5 1.2.2 2.5.3 4 .3 1.2 0 2.5-.1 3.7-.2 8.9-1.1 16.8-6 21.6-13.6 2.9-4.6 4.6-9.9 4.7-15.4V32c0-5-1.3-10.1-3.8-14.8m-9.4 16.2c0 4.7-1.1 9.3-3.3 13.4H33V33h15.8zM4 31c.1-3.9 1.1-7.6 2.8-11.2h8.9C14 23.3 13 27.1 12.8 31zm14-11.2h13V31H14.9c.2-3.9 1.3-7.7 3.1-11.2m13-2H19.2c4-6.4 8-10.8 11.8-12.9zm2-12.9c3.6 2.1 7.4 6.4 11.3 12.9H33zM31 33v13.8H18.8c-2.5-4.2-3.9-8.9-3.9-13.8zm0 15.8v10.3c-3.9-2-7.9-5.7-11-10.3zm2 10.3V48.8h11.3c-2.8 4.5-6.7 8.1-11.3 10.3M33 31V19.8h12.4c1.8 3.5 2.9 7.3 3.2 11.2zm14.7-11.2h9.6C59 23.4 59.9 27.2 60 31h-9.3c-.3-3.9-1.4-7.7-3-11.2m8.5-2h-9.6c0-.1-.1-.1-.1-.2-3.7-6.3-7.3-10.8-10.8-13.4 1 .1 2.1.3 3.2.6 7.2 1.9 13.5 6.6 17.3 13M25 4.9c1-.3 2.1-.5 3.1-.6-3.7 2.6-7.4 7-11 13-.1.2-.2.3-.3.5H7.9c3.8-6.4 10-11 17.1-12.9M4 33h8.8c.1 4.9 1.3 9.6 3.6 13.8H8.3v.1C5.6 42.7 4.2 38 4 33m5.6 15.8h8.1c2.8 4.5 6.4 8.3 10.2 10.8-7.4-1-13.9-4.8-18.3-10.8m26.6 10.9c4.2-2.6 7.8-6.2 10.4-10.6.1-.1.1-.2.1-.3h7.7c-4.5 5.9-11 9.8-18.2 10.9m19.5-12.8h-8c2-4.2 3.1-8.8 3.1-13.4v-.4H60c-.2 4.9-1.7 9.6-4.3 13.8" />
  </svg>
);
export default IconWorldCopy;
