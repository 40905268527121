import * as React from "react";
const IconTrafficLight = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M39.5 18.1c0-4.1-3.4-7.5-7.5-7.5s-7.5 3.4-7.5 7.5c0 3.2 2 5.9 4.8 7-2.8 1.1-4.8 3.8-4.8 7s2 5.9 4.8 7c-2.8 1.1-4.8 3.8-4.8 7 0 4.1 3.4 7.5 7.5 7.5s7.5-3.4 7.5-7.5c0-3.2-2-5.9-4.8-7 2.8-1.1 4.8-3.8 4.8-7s-2-5.9-4.8-7c2.8-1.1 4.8-3.9 4.8-7m-2 27.8c0 3-2.5 5.5-5.5 5.5s-5.5-2.5-5.5-5.5 2.5-5.5 5.5-5.5c3 .1 5.5 2.5 5.5 5.5m0-13.9c0 3-2.5 5.5-5.5 5.5S26.5 35 26.5 32s2.5-5.5 5.5-5.5 5.5 2.5 5.5 5.5M32 23.5c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5 5.5 2.5 5.5 5.5-2.5 5.5-5.5 5.5" />
    <path d="M57.8 26.5c.6 0 1-.4 1-1s-.4-1-1-1h-5.5v-11h5.5c.6 0 1-.4 1-1s-.4-1-1-1h-5.5V6c0-2.2-1.8-4-4-4H15.7c-2.2 0-4 1.8-4 4v5.5H6.2c-.6 0-1 .4-1 1s.4 1 1 1h5.5v11H6.2c-.6 0-1 .4-1 1s.4 1 1 1h5.5v11H6.2c-.6 0-1 .4-1 1s.4 1 1 1h5.5V58c0 2.2 1.8 4 4 4h32.7c2.2 0 4-1.8 4-4V39.5h5.5c.6 0 1-.4 1-1s-.4-1-1-1h-5.5v-11zM50.3 58c0 1.1-.9 2-2 2H15.7c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2h32.7c1.1 0 2 .9 2 2v52z" />
  </svg>
);
export default IconTrafficLight;
