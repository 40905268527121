import * as React from "react";
const IconRocketLaunch6 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M18 41.9c.2.1.3.1.5.1s.3 0 .5-.1l5.2-2.8v1.5c0 .6.4 1 1 1h12.3c.6 0 1-.4 1-1v-1.5l5.2 2.8c.1.1.3.1.5.1s.4 0 .5-.1c.3-.2.5-.5.5-.9v-5.7c0-2.1-1.2-4.1-3-5.1l-3.7-2v-3.8C38.5 14 35.2 9.7 32 6.9c-.4-.3-.9-.3-1.3 0-3.2 2.8-6.5 7.1-6.5 17.5v3.8l-3.7 2c-1.9 1-3 3-3 5.1V41c0 .4.2.8.5.9m23.2-9.8c1.2.7 2 2 2 3.3v4l-4.7-2.5v-6.3zm-15-7.6c0-8.9 2.4-12.8 5.1-15.4 2.7 2.7 5.1 6.6 5.1 15.4v15.1H26.2V24.5m-6.7 10.9c0-1.4.8-2.7 2-3.3l2.7-1.5v6.3l-4.7 2.5z" />
    <path d="M61 55.3h-6.7c.3-.5.4-1.1.4-1.7V31.2c0-2.8-2.2-5-5-5h-1.5c-.6 0-1 .4-1 1s.4 1 1 1h1.5c1.7 0 3 1.3 3 3v22.5c0 .9-.8 1.7-1.7 1.7H11.6c-.9 0-1.7-.8-1.7-1.7V31.2c0-1.7 1.3-3 3-3h.8c.6 0 1-.4 1-1s-.4-1-1-1H13c-2.8 0-5 2.2-5 5v22.5c0 .6.2 1.2.4 1.7H3c-.6 0-1 .4-1 1s.4 1 1 1h58c.6 0 1-.4 1-1s-.4-1.1-1-1.1" />
  </svg>
);
export default IconRocketLaunch6;
