import * as React from "react";
const IconCoding = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.1 44.6c-.1-.9.2-1.9.9-2.6 6.9-7 8.7-16.7 4.8-25.4-3.3-7.3-10.2-12.5-18-13.4-11-1.3-21 5-24.4 15.5v.2l-1.1 7-5.8 10.3c-.4.7-.4 1.6 0 2.3s1.2 1.1 2 1.1h2.1l1.6 8.7c.4 2.4 2.5 4.1 4.9 4.1h6.6c1.4 0 2.6 1 2.9 2.3l.5 2.3c.5 2.3 2.5 3.8 4.9 3.8h15c1.5 0 2.9-.7 3.8-1.8 1-1.1 1.3-2.6 1.1-4.1zm-.7 13.3c-.6.7-1.4 1.1-2.3 1.1h-15c-1.4 0-2.6-1-2.9-2.3l-.5-2.3c-.5-2.3-2.5-3.9-4.9-3.9h-6.6c-1.4 0-2.7-1-3-2.5l-1.8-9.6c-.1-.5-.5-.8-1-.8h-3c-.2 0-.3-.1-.3-.2s-.1-.2 0-.3L14 26.6c.1-.1.1-.2.1-.3l1.2-7.1C18.5 9.7 27.6 4 37.6 5.2 44.7 6 51 10.7 54 17.4c3.5 7.9 1.8 16.8-4.4 23.2-1.1 1.2-1.7 2.8-1.4 4.4L50 55.5c.2.8 0 1.7-.6 2.4" />
    <path d="M27.4 19.8c-.4-.4-1-.4-1.4-.1l-5.6 5c-.2.2-.3.5-.3.7s.1.6.3.7l5.6 5c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.3-1-.1-1.4l-4.7-4.3 4.7-4.3c.5-.3.5-.9.1-1.3M50.2 25.5c0-.3-.1-.6-.3-.7l-5.6-5c-.4-.4-1-.3-1.4.1s-.3 1 .1 1.4l4.7 4.3-4.7 4.2c-.4.4-.4 1-.1 1.4.2.2.5.3.7.3s.5-.1.7-.3l5.6-5c.2-.2.3-.5.3-.7M37.5 16.5c-.5-.1-1.1.2-1.2.7l-4.4 16.2c-.1.5.2 1.1.7 1.2h.3c.4 0 .8-.3 1-.7l4.4-16.2c.1-.5-.3-1.1-.8-1.2" />
  </svg>
);
export default IconCoding;
