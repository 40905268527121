import * as React from "react";
const Icon0 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M13 18a9 9 0 0 1 9-9h4c2.303 0 4.41.87 6 2.292A8.99 8.99 0 0 1 35 18v12a9 9 0 0 1-9 9h-4a8.99 8.99 0 0 1-6.708-3A8.98 8.98 0 0 1 13 30zm9-7a7 7 0 0 0-7 7v12c0 1.792.675 3.43 1.783 4.667A6.99 6.99 0 0 0 22 37h4a7 7 0 0 0 7-7V18a6.99 6.99 0 0 0-2.333-5.217A6.98 6.98 0 0 0 26 11zm0 4a3 3 0 0 0-3 3v7.817L25.59 15zm-5 3a5 5 0 0 1 5-5h4a5 5 0 0 1 1.561.248 1 1 0 0 1 .543 1.47l-9.25 15.183a1 1 0 0 1-1.854-.52zm13.163-1.543a1 1 0 0 1 .788.84q.048.345.049.703v12a5 5 0 0 1-5 5h-4a5 5 0 0 1-2.318-.568 1 1 0 0 1-.39-1.406l9.815-16.11a1 1 0 0 1 1.056-.459m-8.502 16.524q.167.02.339.019h4a3 3 0 0 0 3-3v-9.065z"
      clipRule="evenodd"
    />
  </svg>
);
export default Icon0;
