import React from "react";
import BringzzText from "../BringzzText";
import BringzzBoxSlider from "../BringzzBoxSlider";
import classnames from "classnames";

const DEFAULT_CLASSNAMES = "rounded-xl";

const BringzzRecommendationItem = ({
  className,
  title,
  subTitle,
  image,
  children,
  ...props
}) => {
  return (
    <div className={classnames(DEFAULT_CLASSNAMES, className)}>
      <div className="spacey-y-2 px-3 pt-3 max-w-96">
        <div>
          <BringzzText
            tag="h2"
          >
            {title}
          </BringzzText>
        </div>
        <div>
          <BringzzText
            tag="h4"
            className={"font-normal"}
          >
            {subTitle}
          </BringzzText>
        </div>
      </div>
      {image && (
        <div className="flex justify-end">
          <img src={image} alt="Recommendation background" />
        </div>
      )}
      <div className="-mt-20">
        <BringzzBoxSlider className="p-2 space-x-4">
          {children}
        </BringzzBoxSlider>
      </div>
    </div>
  );
};

export default BringzzRecommendationItem;
