import * as React from "react";
const IconMessageEdit = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.4 39-4.8-4.8q-.6-.6-1.5-.6t-1.5.6L43.4 44.5c-.3.3-.5.6-.6 1l-1.3 6.1c-.1.7.1 1.4.6 1.9q.6.6 1.5.6h.4l6.1-1.3c.4-.1.7-.3 1-.6L61.4 42q.6-.6.6-1.5c0-.5-.2-1.1-.6-1.5M60 40.6 49.7 50.8l-6.1 1.3-.1-.1 1.4-6.2 10.2-10.2h.1l4.8 4.8z" />
    <path d="M33.7 48.2H7c-1.6 0-3-1.3-3-3V14.8c0-.4.1-.7.2-1l24.2 14.7c.8.5 1.8.8 2.7.8s1.9-.3 2.7-.8L58 13.8c.1.3.2.7.2 1v14c0 .6.4 1 1 1s1-.4 1-1v-14c0-2.7-2.2-5-5-5H7c-2.7 0-5 2.2-5 5v30.5c0 2.7 2.2 5 5 5h26.8c.6 0 1-.4 1-1s-.5-1.1-1.1-1.1m21.7-36.4c.5 0 1 .2 1.5.4l-24 14.5c-1 .6-2.3.6-3.4 0l-24-14.5c.4-.2.9-.4 1.5-.4z" />
  </svg>
);
export default IconMessageEdit;
