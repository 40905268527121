import * as React from "react";
const IconSliceCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.4 53.6 49 41.2 17.2 9.4c-2.7-2.7-7.1-2.7-9.7 0L4 12.9c-2.7 2.7-2.7 7.1 0 9.7l31.8 31.8c.2.2.5.3.7.3s.5-.1.7-.3l2.4-2.4 4.5 4.3c.2.2.4.3.7.3h15.3c.8 0 1.4-.5 1.7-1.2s.1-1.4-.4-1.8m-56-39.3 3.5-3.5c1-1 2.2-1.4 3.5-1.4s2.5.5 3.5 1.4l3.3 3.3L8.7 24.5l-3.3-3.3c-1.9-1.9-1.9-5 0-6.9m31.1 38L10.2 26l4.4-4.4 8.2 8.2c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4L16 20.1l4.5-4.5L46.9 42zm8.8 2.3-4.2-4 7.2-7.2 11.2 11.2z" />
  </svg>
);
export default IconSliceCopy;
