import * as React from "react";
const IconChatAlt4 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M31.8 56.3c-.3 0-.6-.1-.7-.3l-8.7-9.6H6.9c-2.7 0-4.9-2.2-4.9-4.9V12.6c0-2.7 2.2-4.9 4.9-4.9h50.2c2.7 0 4.9 2.2 4.9 4.9v28.9c0 2.7-2.2 4.9-4.9 4.9H41.4L32.6 56c-.2.2-.5.3-.8.3M6.9 9.7C5.3 9.7 4 11 4 12.6v28.9c0 1.6 1.3 2.9 2.9 2.9h16c.3 0 .6.1.7.3l8.2 9.1 8.4-9.1c.2-.2.5-.3.7-.3H57c1.6 0 2.9-1.3 2.9-2.9V12.6c0-1.6-1.3-2.9-2.9-2.9z" />
  </svg>
);
export default IconChatAlt4;
