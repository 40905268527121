import * as React from "react";
const IconArrowTopLeftCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M48.9 50c-.3 0-.5-.1-.7-.3L16 17.5v27c0 .6-.4 1-1 1s-1-.4-1-1V15c0-.6.4-1 1-1h29.5c.6 0 1 .4 1 1s-.4 1-1 1H17.4l32.3 32.3c.4.4.4 1 0 1.4-.3.2-.5.3-.8.3" />
  </svg>
);
export default IconArrowTopLeftCopy;
