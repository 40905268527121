import * as React from "react";
const IconInstagramOriginal = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62.9 19.2c-.1-3.2-.7-5.5-1.4-7.6S59.7 7.8 58 6.1s-3.4-2.7-5.4-3.5-4.2-1.3-7.6-1.4C41.5 1 40.5 1 32 1s-9.4 0-12.8.1-5.5.7-7.6 1.4-3.8 1.9-5.5 3.6-2.8 3.4-3.5 5.5c-.8 2-1.3 4.2-1.4 7.6S1 23.5 1 32s0 9.4.1 12.8.7 5.5 1.4 7.6 1.8 3.8 3.5 5.5 3.5 2.8 5.5 3.5 4.2 1.3 7.6 1.4c3.4.2 4.3.2 12.8.2s9.4 0 12.8-.1 5.5-.7 7.6-1.4 3.8-1.8 5.5-3.5 2.8-3.5 3.5-5.5 1.3-4.2 1.4-7.6c.1-3.2.1-4.2.1-12.7s.2-9.6.1-13m-5.6 25.3c-.1 3-.7 4.6-1.1 5.8-.6 1.4-1.3 2.5-2.4 3.5-1.1 1.1-2.1 1.7-3.5 2.4-1.1.4-2.7 1-5.8 1.1H32.1c-8.2 0-9.3 0-12.5-.1-3-.1-4.6-.7-5.8-1.1-1.4-.6-2.5-1.3-3.5-2.4-1.1-1.1-1.7-2.1-2.4-3.5-.4-1.1-1-2.7-1.1-5.8V32c0-8.3 0-9.3.1-12.5.1-3 .7-4.6 1.1-5.8.6-1.4 1.3-2.5 2.3-3.5 1.1-1.1 2.1-1.7 3.5-2.3 1.1-.4 2.7-1 5.8-1.1 3.2-.1 4.2-.1 12.5-.1s9.3 0 12.5.1c3 .1 4.6.7 5.8 1.1 1.4.6 2.5 1.3 3.5 2.3 1.1 1.1 1.7 2.1 2.4 3.5.4 1.1 1 2.7 1.1 5.8.1 3.2.1 4.2.1 12.5s-.1 9.3-.2 12.5" />
    <path d="M32 16.1c-8.9 0-15.9 7.2-15.9 15.9 0 8.9 7.2 15.9 15.9 15.9s16-7 16-15.9-7.1-15.9-16-15.9m0 26.3c-5.8 0-10.4-4.7-10.4-10.4S26.3 21.6 32 21.6c5.8 0 10.4 4.6 10.4 10.4S37.8 42.4 32 42.4" />
    <circle cx={48.7} cy={15.4} r={3.7} />
  </svg>
);
export default IconInstagramOriginal;
