import React from 'react';
import { BringzzText, BringzzIcon } from "@bringzz/components";

const GeneralOptions = () => {
    return (
        <div className='mt-2 py-2 px-6 mx-2 bg-white rounded-xl'>
            <div className='flex items-center h-12 border-b justify-between cursor-pointer'>
                <div className='flex space-x-3 items-center'>
                    <BringzzIcon size="16" icon="IconScan" folder="LineIcons" />
                    <BringzzText tag='h3' className='font-normal'>Products</BringzzText>
                </div>
                <BringzzIcon size="16" icon="IconChevronRight" folder="LineIcons" />
            </div>
            <div className='flex items-center h-12 justify-between cursor-pointer'>
                <div className='flex space-x-3 items-center'>
                    <BringzzIcon size="16" icon="IconScan" folder="LineIcons" />
                    <BringzzText tag='h3' className='font-normal'>Active Ingredient</BringzzText>
                </div>
                <BringzzIcon size="16" icon="IconChevronRight" folder="LineIcons" />
            </div>
        </div>
    );
};

export default GeneralOptions;