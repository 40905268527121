import * as React from "react";
const IconRoadAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M5.2 2c-.6 0-1 .4-1 1v58c0 .6.4 1 1 1s1-.4 1-1V3c0-.6-.4-1-1-1M58.8 2c-.6 0-1 .4-1 1v58c0 .6.4 1 1 1s1-.4 1-1V3c0-.6-.5-1-1-1M32 2c-.6 0-1 .4-1 1v3.5c0 .6.4 1 1 1s1-.4 1-1V3c0-.6-.4-1-1-1M32 27.4c-.6 0-1 .4-1 1v7.3c0 .6.4 1 1 1s1-.4 1-1v-7.3c0-.6-.4-1-1-1M32 41.9c-.6 0-1 .4-1 1v7.3c0 .6.4 1 1 1s1-.4 1-1v-7.3c0-.5-.4-1-1-1M32 12.8c-.6 0-1 .4-1 1v7.3c0 .6.4 1 1 1s1-.4 1-1v-7.3c0-.6-.4-1-1-1M32 56.5c-.6 0-1 .4-1 1V61c0 .6.4 1 1 1s1-.4 1-1v-3.5c0-.6-.4-1-1-1M21.7 31.9l-2.4 2.4V21.6c0-.6-.4-1-1-1s-1 .4-1 1v12.7l-2.4-2.4c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l4.2 4.2c.2.2.5.3.7.3s.5-.1.7-.3l4.1-4.1c.4-.4.4-1 0-1.4s-1.1-.5-1.5-.1M47.2 20.9c-.4-.4-1-.4-1.4 0L41.7 25c-.4.4-.4 1 0 1.4s1 .4 1.4 0l2.4-2.4v12.7c0 .6.4 1 1 1s1-.4 1-1V24l2.4 2.4c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4z" />
  </svg>
);
export default IconRoadAlt1;
