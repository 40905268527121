import * as React from "react";
const IconContactLenses = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M34.985 9.686a1.2 1.2 0 0 0 .012-.269Q35 9.309 35 9.2h-.04C34.482 7.407 29.757 6 24 6S13.518 7.407 13.04 9.2H13q0 .11.003.217a1.2 1.2 0 0 0 .012.27C13.326 14.605 18.33 18.2 24 18.2q.114 0 .229-.002a9 9 0 0 0-.294.04c-2.25.344-4.654 1.567-7.142 4.055l-11 11 1.414 1.414 11-11c2.263-2.262 4.296-3.227 6.03-3.492s3.281.15 4.606.855c1.334.71 2.418 1.701 3.176 2.533.269.296.494.567.67.793l-.122.123c-1.226-.59-2.61-.59-3.911-.243-1.697.453-3.36 1.514-4.676 2.83-1.312 1.311-2.361 2.962-2.717 4.666-.254 1.22-.148 2.47.462 3.589l-5.432 5.432 1.414 1.414L35.274 24.64l-.43-.677L34 24.5c.844-.537.843-.538.843-.538v-.001l-.003-.003-.006-.01-.02-.03-.07-.104c-.06-.088-.148-.213-.261-.365a16 16 0 0 0-.986-1.193c-.851-.934-2.111-2.098-3.715-2.951-1.272-.677-2.775-1.164-4.459-1.17 5.105-.508 9.375-3.916 9.662-8.449m-1.881-.574-.012.015zm-.544.388a8 8 0 0 0-1.388-.569C29.426 8.375 26.89 8 24 8s-5.426.375-7.172.931a8 8 0 0 0-1.388.569c.327.18.784.377 1.388.569C18.574 10.624 21.11 11 24 11s5.426-.376 7.172-.931A8 8 0 0 0 32.56 9.5m-17.664-.388.012.015q-.013-.015-.012-.015m17.462 2.663C30.341 12.526 27.345 13 24 13s-6.34-.475-8.358-1.225C16.936 14.293 20.072 16.2 24 16.2c3.927 0 7.064-1.907 8.358-4.424m-9.11 22.062 7.744-7.743c-.547-.102-1.158-.062-1.82.114-1.288.344-2.655 1.19-3.777 2.312-1.126 1.125-1.92 2.444-2.174 3.66-.122.585-.121 1.14.028 1.657"
      clipRule="evenodd"
    />
  </svg>
);
export default IconContactLenses;
