import * as React from "react";
const IconAlarmClockCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M43.8 36.3H33v-11c0-.6-.4-1-1-1s-1 .4-1 1v11h-1.6c-.6 0-1 .4-1 1s.4 1 1 1H31v1.6c0 .6.4 1 1 1s1-.4 1-1v-1.6h10.8c.6 0 1-.4 1-1s-.5-1-1-1" />
    <path d="M55.8 25.5c2.1-1.7 3.3-4.2 3.3-6.9 0-4.9-4-8.9-8.9-8.9-2.6 0-5.1 1.2-6.8 3.1q-4.8-2.4-10.5-2.7V4h4.3c.6 0 1-.4 1-1s-.4-1-1-1H26.7c-.6 0-1 .4-1 1s.4 1 1 1H31v6.2c-3.8.1-7.3 1.1-10.5 2.7-1.7-2-4.1-3.1-6.8-3.1-4.9 0-8.9 4-8.9 8.9 0 2.7 1.2 5.2 3.3 6.9 0 0 .1 0 .1.1C6.8 28.9 6 32.4 6 36.2 6.1 50.4 17.7 62 32 62s25.9-11.6 25.9-25.9c0-3.7-.8-7.3-2.2-10.5 0 0 .1 0 .1-.1m-5.5-13.8c3.8 0 6.9 3.1 6.9 6.9 0 2-.9 3.9-2.3 5.2-2.2-4.1-5.5-7.6-9.5-10 1.2-1.3 3-2.1 4.9-2.1M6.8 18.6c0-3.8 3.1-6.9 6.9-6.9 1.9 0 3.7.8 5 2.1-4 2.4-7.3 5.8-9.5 10-1.5-1.3-2.4-3.2-2.4-5.2M32 60C18.8 60 8.1 49.3 8.1 36.1s10.7-24 23.9-24S55.9 22.8 55.9 36 45.2 60 32 60" />
  </svg>
);
export default IconAlarmClockCopy;
