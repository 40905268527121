import * as React from "react";
const IconPpeFaceShieldAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M24.807 6.323c-9.003-1.812-18.034 4.219-18.76 13.373a1 1 0 0 0-.03.486q-.015.294-.016.593a14.95 14.95 0 0 0 1.266 6.153c.792 1.8 1.93 3.422 3.35 4.775V41a1 1 0 1 0 2 0v-9.735a1 1 0 0 0-.333-.744 12.9 12.9 0 0 1-3.187-4.399A12.95 12.95 0 0 1 8 21h14v5.215c0 3.116 1.2 6.11 3.347 8.323S30.413 38 33.463 38H42V19.163a7.26 7.26 0 0 0-2.033-5.054A6.9 6.9 0 0 0 35.02 12h-2.235c-.663-1.11-1.435-2.11-2.373-2.96-1.462-1.325-3.28-2.248-5.606-2.717m4.262 4.199q.721.655 1.322 1.478H11.717c3.138-3.118 7.879-4.686 12.695-3.716 2.006.404 3.487 1.176 4.657 2.238M8.14 19a12.3 12.3 0 0 1 1.932-5H22v5zm23.826-4.253a21 21 0 0 0-.36-.747H24v12.215c0 2.605 1.004 5.097 2.782 6.93S30.964 36 33.463 36H40V19.163a5.26 5.26 0 0 0-1.469-3.661A4.9 4.9 0 0 0 35.021 14h-1.2c.217.51.328 1.06.325 1.616v3.045l.257.488c.232.446.559 1.076.932 1.812.745 1.47 1.685 3.374 2.446 5.089a2.55 2.55 0 0 1-1.109 3.268 2.5 2.5 0 0 1-1.211.306h-1.303v2.093a1 1 0 0 1-2 0v-3.093a1 1 0 0 1 1-1h2.307a.53.53 0 0 0 .447-.244.55.55 0 0 0 .041-.52c-.74-1.668-1.662-3.536-2.402-4.994a171 171 0 0 0-1.191-2.305l-.072-.136-.024-.045a1 1 0 0 1-.118-.471v-3.302a2.1 2.1 0 0 0-.181-.86m0 0 .002.003.908-.417-.911.41z"
      clipRule="evenodd"
    />
    <path
      
      d="M28 38a1 1 0 0 1 1 1v2a1 1 0 1 1-2 0v-2a1 1 0 0 1 1-1"
    />
  </svg>
);
export default IconPpeFaceShieldAlt;
