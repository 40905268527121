import * as React from "react";
const IconShower = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.9 2.1h-36c-2.8 0-5.1 2.3-5.1 5.1v14.2H9.7c-4.3 0-7.7 3.5-7.7 7.7v3c0 .6.4 1 1 1h26.3c.6 0 1-.4 1-1v-3c0-4.3-3.5-7.7-7.7-7.7h-4.8V7.2c0-1.7 1.4-3.1 3.1-3.1h36c1.7 0 3.1 1.4 3.1 3.1v53.7c0 .6.4 1 1 1s1-.4 1-1V7.2c0-2.8-2.3-5.1-5.1-5.1m-28.6 27v2H4v-2c0-3.2 2.6-5.7 5.7-5.7h12.9c3.2 0 5.7 2.6 5.7 5.7M8.8 40.2c-.6 0-1 .4-1 1v5.1c0 .6.4 1 1 1s1-.4 1-1v-5.1c0-.5-.4-1-1-1M8.8 53.9c-.6 0-1 .4-1 1V60c0 .6.4 1 1 1s1-.4 1-1v-5.1c0-.5-.4-1-1-1" />
    <path d="M22.5 40.2c-.6 0-1 .4-1 1v5.1c0 .6.4 1 1 1s1-.4 1-1v-5.1c0-.5-.4-1-1-1M22.5 53.9c-.6 0-1 .4-1 1V60c0 .6.4 1 1 1s1-.4 1-1v-5.1c0-.5-.4-1-1-1" />
  </svg>
);
export default IconShower;
