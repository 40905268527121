import * as React from "react";
const IconSignalCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 23.6c-2.5 0-4.6 2-4.6 4.6 0 2.2 1.5 4 3.6 4.4v20.5c0 .6.4 1 1 1s1-.4 1-1V32.6c2.1-.5 3.6-2.3 3.6-4.4 0-2.5-2.1-4.6-4.6-4.6m0 7.1c-1.4 0-2.6-1.1-2.6-2.6s1.2-2.6 2.6-2.6 2.6 1.1 2.6 2.6-1.2 2.6-2.6 2.6M23.4 20.2c-.4-.4-1-.4-1.4 0-2 1.9-3.1 4.5-3.1 7.3s1.1 5.4 3.1 7.4c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4-1.6-1.6-2.5-3.7-2.5-6 0-2.2.9-4.3 2.5-5.9.4-.4.4-1 0-1.4M40.6 20.2c-.4.4-.4 1 0 1.4 1.6 1.6 2.5 3.7 2.5 5.9 0 2.3-.9 4.4-2.5 6-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3 2-2 3.1-4.6 3.1-7.4S44 22.1 42 20.2c-.4-.4-1-.4-1.4 0M17 15.2c-.4-.4-1-.4-1.4 0-3.3 3.3-5.2 7.7-5.2 12.3s1.8 9 5.2 12.3c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4-2.9-2.9-4.6-6.8-4.6-10.9s1.6-8 4.6-10.9c.4-.4.4-1 0-1.4M48.4 15.2c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4c2.9 2.9 4.6 6.8 4.6 10.9s-1.6 8-4.6 10.9c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3 3.3-3.3 5.2-7.7 5.2-12.3s-1.9-9-5.2-12.3" />
    <path d="M4 27.5c0-6 2.4-11.6 6.7-15.9.4-.4.4-1 0-1.4s-1-.4-1.4 0C4.6 14.8 2 21 2 27.5s2.6 12.7 7.2 17.3c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4C6.4 39.1 4 33.5 4 27.5M54.8 10.2c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4c4.3 4.2 6.7 9.9 6.7 15.9s-2.4 11.6-6.7 15.9c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3C59.4 40.2 62 34 62 27.5s-2.6-12.7-7.2-17.3" />
  </svg>
);
export default IconSignalCopy;
