import * as React from "react";
const IconApartmentsAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 2.7H7c-2.7 0-5 2.2-5 5v48.7c0 2.7 2.2 5 5 5h16.9c.6 0 1-.4 1-1V55c0-2 .8-4 2.3-5.5 1.4-1.3 3.2-2.1 5-2 1.8 0 3.6.8 4.9 2.1s2.1 3.1 2.1 5v5.7c0 .6.4 1 1 1H57c2.7 0 5-2.2 5-5V7.6c0-2.7-2.2-4.9-5-4.9m3 53.7c0 1.6-1.3 3-3 3H41.1v-4.7c0-2.4-.9-4.7-2.7-6.5-1.7-1.7-3.9-2.6-6.2-2.7-2.4-.1-4.7.9-6.4 2.6-1.9 1.8-2.9 4.3-2.9 6.9v4.3H7c-1.6 0-3-1.3-3-3V7.6c0-1.6 1.3-3 3-3h50c1.6 0 3 1.3 3 3z" />
    <path d="M21 13.8h-4.1c-.6 0-1 .4-1 1s.4 1 1 1H21c.6 0 1-.4 1-1s-.5-1-1-1M47.5 13.8h-4.1c-.6 0-1 .4-1 1s.4 1 1 1h4.1c.6 0 1-.4 1-1s-.4-1-1-1M21 25.3h-4.1c-.6 0-1 .4-1 1s.4 1 1 1H21c.6 0 1-.4 1-1s-.5-1-1-1M47.5 25.3h-4.1c-.6 0-1 .4-1 1s.4 1 1 1h4.1c.6 0 1-.4 1-1s-.4-1-1-1M21 36.8h-4.1c-.6 0-1 .4-1 1s.4 1 1 1H21c.6 0 1-.4 1-1s-.5-1-1-1M47.5 36.8h-4.1c-.6 0-1 .4-1 1s.4 1 1 1h4.1c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconApartmentsAlt1;
