import * as React from "react";
const IconSpeechBubble30 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M14.3 20.1h-.1c-1.1 0-2 .9-2 2s.9 2 2 2h.1c1.1 0 2-.9 2-2s-.9-2-2-2M24.4 24.1h.1c1.1 0 2-.9 2-2s-.9-2-2-2h-.1c-1.1 0-2 .9-2 2s.9 2 2 2M34.7 24.1h.1c1.1 0 2-.9 2-2s-.9-2-2-2h-.1c-1.1 0-2 .9-2 2s.8 2 2 2" />
    <path d="M57.1 50.1c3.1-2.5 4.9-5.7 4.9-9 0-6.6-6.8-12.2-16.3-13.7.8-1.6 1.3-3.2 1.3-5 0-8.2-10.1-14.9-22.5-14.9C12.1 7.4 2 14.1 2 22.4c0 3.5 1.8 6.9 5.2 9.6l-1.6 5.6c-.1.4 0 .8.3 1.1.2.1.4.2.6.2s.3 0 .5-.1l6.1-3.4q3.45 1.35 7.5 1.8c-.6 1.3-.9 2.6-.9 4 0 7.8 9.5 14.1 21.1 14.1 3.8 0 7.4-.7 10.6-1.9l5.7 3.2c.2.1.3.1.5.1s.4-.1.6-.2c.3-.3.5-.7.3-1.1zM12.6 33.3l-4.4 2.5 1.1-3.9c.1-.4 0-.8-.4-1.1C5.8 28.4 4 25.4 4 22.4c0-7.1 9.2-12.9 20.5-12.9S45 15.2 45 22.4c0 7.1-9.2 12.9-20.5 12.9-.7 0-1.4 0-2.2-.1h-.1c-3.1-.2-6.2-.9-8.8-2-.3-.1-.6-.1-.8.1m39.5 17.9c-.3-.1-.6-.2-.9-.1-3.1 1.3-6.7 1.9-10.3 1.9-10.6 0-19.1-5.4-19.1-12.1 0-1.3.4-2.6 1-3.8h1.8c8.7 0 16.2-3.3 20-8.1C53.4 30.3 60 35.3 60 41.1c0 2.9-1.6 5.6-4.6 7.8-.4.2-.5.7-.4 1.1l1 3.5z" />
  </svg>
);
export default IconSpeechBubble30;
