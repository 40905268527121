import * as React from "react";
const IconBadge9 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m57.1 55.3-7.8-19.8c0-.1-.1-.1-.1-.1 2.5-3.5 3.9-7.7 3.9-12.2C53.1 11.5 43.7 2 32 2s-21.1 9.5-21.1 21.1c0 4.6 1.5 8.8 3.9 12.2l-.1.1-7.8 19.9c-.1.3-.1.7.1 1s.5.4.8.4l7.7-.1 5.7 5.2c.2.2.4.3.7.3h.2c.3-.1.6-.3.7-.6l6.7-17.2c.8.1 1.6.1 2.4.1s1.6-.1 2.4-.1L41 61.5c.1.3.4.5.7.6h.2c.2 0 .5-.1.7-.3l5.7-5.2 7.7.1c.3 0 .6-.2.8-.4.4-.3.4-.7.3-1m-35.6 4-4.9-4.5c-.2-.2-.4-.3-.7-.3l-6.6.1 6.9-17.5c2.9 3.3 6.9 5.7 11.4 6.7zm-8.6-36.2C12.9 12.6 21.4 4 32 4s19.1 8.6 19.1 19.1S42.6 42.3 32 42.3s-19.1-8.6-19.1-19.2m35.2 31.5c-.3 0-.5.1-.7.3l-4.9 4.5-6.1-15.5c4.5-1 8.4-3.3 11.4-6.7l6.9 17.5z" />
    <path d="M41.2 17.9c-.2-.3-.5-.4-.8-.5l-4.6-.3-3-4.1c-.4-.5-1.3-.5-1.6 0l-2.9 4.1-4.6.3c-.3 0-.6.2-.8.5s-.2.6-.1.9l1.8 4.4-1.8 4.4c-.1.3-.1.6.1.9s.5.4.8.5l4.6.3 2.9 4.1c.2.3.5.4.8.4s.6-.2.8-.4l2.9-4.1 4.6-.3c.3 0 .6-.2.8-.5s.2-.6.1-.9l-1.8-4.4 1.8-4.4c.2-.3.1-.7 0-.9m-3.7 5.7 1.4 3.5-3.7.2c-.3 0-.6.2-.8.4L32 31.1l-2.4-3.3c-.2-.2-.5-.4-.8-.4l-3.7-.2 1.4-3.5c.1-.2.1-.5 0-.7l-1.4-3.5 3.7-.2c.3 0 .6-.2.8-.4l2.4-3.3 2.4 3.3c.2.2.5.4.8.4l3.7.2-1.4 3.5q-.15.15 0 .6" />
  </svg>
);
export default IconBadge9;
