import * as React from "react";
const IconBuildings = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.5 62h-53C3.6 62 2 60.4 2 58.4V21.5c0-.8.3-1.7.8-2.3l13.6-16Q17.45 2 19.1 2c1 0 2 .4 2.7 1.2l13.6 16c.5.6.8 1.4.8 2.3v7l8.3-10.2c.7-.8 1.7-1.3 2.8-1.3s2.1.5 2.7 1.4l11.1 14.5c.5.6.7 1.4.7 2.1v23.4c.2 2-1.4 3.6-3.3 3.6m-22.2-2h22.2c.8 0 1.5-.7 1.5-1.5V35c0-.3-.1-.7-.3-.9L48.6 19.6q-.45-.6-1.2-.6t-1.2.6l-9.9 12.1zM19.2 4.1c-.4 0-.9.2-1.2.5l-13.6 16c-.2.3-.4.6-.4 1v36.9c0 .8.7 1.5 1.5 1.5h28.8V21.5c0-.4-.1-.7-.4-1l-13.6-16c-.3-.3-.7-.4-1.1-.4" />
  </svg>
);
export default IconBuildings;
