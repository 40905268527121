import * as React from "react";
const IconWalkieTalkie = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M35.2 23.9h-6.3c-2.2 0-4 1.8-4 4v6.3c0 2.2 1.8 4 4 4h6.3c2.2 0 4-1.8 4-4v-6.3c0-2.2-1.8-4-4-4m2 10.3c0 1.1-.9 2-2 2h-6.3c-1.1 0-2-.9-2-2v-6.3c0-1.1.9-2 2-2h6.3c1.1 0 2 .9 2 2z" />
    <path d="M45.3 2c-.6 0-1 .4-1 1v13.5H21.7c-2.2 0-4 1.8-4 4V58c0 2.2 1.8 4 4 4h20.7c2.2 0 4-1.8 4-4V3c-.1-.6-.5-1-1.1-1m-1 56c0 1.1-.9 2-2 2H21.7c-1.1 0-2-.9-2-2V20.5c0-1.1.9-2 2-2h22.7V58z" />
  </svg>
);
export default IconWalkieTalkie;
