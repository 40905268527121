import * as React from "react";
const IconSlackLineCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M20.2 12.9h4.6c.6 0 1-.4 1-1V7.2c0-2.9-1.9-5.4-4.5-6-2-.5-4 .1-5.5 1.5s-2 3.5-1.6 5.6c.7 2.7 3.2 4.6 6 4.6m-2.9-8.7C18 3.5 19 3.1 20 3.1c.3 0 .6 0 .9.1 1.7.4 2.9 2.1 2.9 4.1v3.6h-3.6c-1.9 0-3.6-1.2-4-3-.3-1.4.1-2.7 1.1-3.7M9.7 31.9h11.2c4.2 0 7.7-3.4 7.7-7.7 0-4.2-3.5-7.7-7.7-7.7H9.7c-4.2 0-7.7 3.4-7.7 7.7s3.4 7.7 7.7 7.7m0-13.3h11.2c3.1 0 5.7 2.5 5.7 5.7 0 3.1-2.6 5.7-5.7 5.7H9.7C6.6 30 4 27.5 4 24.3c0-3.1 2.5-5.7 5.7-5.7M47.1 20V8.8c0-4.3-3.4-7.8-7.6-7.8s-7.6 3.5-7.6 7.8V20c0 4.3 3.4 7.8 7.6 7.8s7.6-3.5 7.6-7.8m-13.2 0V8.8c0-3.2 2.5-5.8 5.6-5.8s5.6 2.6 5.6 5.8V20c0 3.2-2.5 5.8-5.6 5.8s-5.6-2.6-5.6-5.8M53.6 31.5h-8.7c-4.2 0-7.5 3.4-7.5 7.5s3.4 7.5 7.5 7.5h8.7c4.2 0 7.5-3.4 7.5-7.5s-3.4-7.5-7.5-7.5m0 12.9h-8.7c-3.1 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5h8.7c3.1 0 5.5 2.5 5.5 5.5s-2.5 5.5-5.5 5.5M26.1 37.7c-4 0-7.2 3.3-7.2 7.4V54c0 4.1 3.2 7.4 7.2 7.4s7.2-3.3 7.2-7.4v-8.9c0-4.1-3.2-7.4-7.2-7.4M31.3 54c0 3-2.3 5.4-5.2 5.4S20.9 57 20.9 54v-8.9c0-3 2.3-5.4 5.2-5.4s5.2 2.4 5.2 5.4zM60.4 16.7c-1.4-1.4-3.3-1.9-5.2-1.5-2.5.6-4.3 3-4.3 5.8v4.4c0 .6.4 1 1 1h4.3c2.7 0 5.1-1.8 5.7-4.3.4-2-.1-4-1.5-5.4m-.5 4.9c-.4 1.6-1.9 2.8-3.8 2.8h-3.3V21c0-1.8 1.2-3.4 2.8-3.8 1.2-.3 2.5.1 3.4.9.9 1 1.2 2.3.9 3.5M12.7 36.8H8.4c-2.7 0-5.1 1.8-5.7 4.3-.4 1.9.1 3.9 1.5 5.3C5.3 47.5 6.7 48 8.1 48c.4 0 .9 0 1.3-.1 2.5-.6 4.3-3 4.3-5.8v-4.4c0-.4-.4-.9-1-.9m-1 5.4c0 1.8-1.2 3.4-2.8 3.8-1.2.3-2.5-.1-3.4-1s-1.3-2.2-1-3.5c.5-1.5 2-2.7 3.9-2.7h3.3zM43.9 51.7h-4.3c-.6 0-1 .4-1 1v4.4c0 2.8 1.8 5.2 4.3 5.8.4.1.9.1 1.3.1 1.5 0 2.9-.6 3.9-1.6 1.4-1.4 2-3.4 1.5-5.3-.6-2.6-3-4.4-5.7-4.4m2.7 8.3c-.9.9-2.1 1.2-3.4.9-1.6-.4-2.8-2-2.8-3.8v-3.4h3.3c1.8 0 3.4 1.2 3.8 2.8.4 1.3 0 2.6-.9 3.5" />
  </svg>
);
export default IconSlackLineCopy;
