import * as React from "react";
const IconThumbsDownAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M9.6 6.8H6.4C4 6.8 2 8.8 2 11.3v23c0 2.4 2 4.4 4.4 4.4h3.1c2.5 0 4.4-2 4.4-4.4v-23C14 8.8 12 6.8 9.6 6.8M12 34.3c0 1.3-1.1 2.4-2.4 2.4H6.4C5 36.7 4 35.6 4 34.3v-23C4 10 5.1 8.9 6.4 8.9h3.1c1.4 0 2.4 1.1 2.4 2.4v23zM61.8 31.5c-.4-2.3-2.9-8.9-7.5-19.7-.7-1.7-1.5-3.4-3.2-4.2-1.3-.7-2.7-.7-3.8-.7H27.4c-2.1 0-4.5.1-6.1 1.8-1.5 1.6-1.7 3.8-1.7 6v21.9c0 1 0 2.4.5 3.6.4 1 3.2 3.9 11.8 12.2 1 1 1.9 1.8 2.4 2.3.9.9 2 2 3.6 2.4.3.1.7.1 1 .1 2 0 3.9-1.4 4.5-3.3.4-1.4.1-2.8-.2-3.9l-1.9-8.8 12.7.1c1.3 0 3 0 4.5-.7 1.5-.8 2.7-2.3 3.2-4.1.1-.3.2-.7.2-1 .2-1.3.1-2.5-.1-4m-1.9 3.6c0 .3-.1.5-.2.8-.4 1.3-1.2 2.4-2.2 2.9s-2.4.5-3.6.5L40 39.2c-.3 0-.6.1-.8.4-.2.2-.2.5-.2.8l2.2 10c.2 1 .4 2 .2 2.9-.3 1.2-1.8 2.1-3.1 1.8-.9-.2-1.7-1-2.6-1.9-.5-.5-1.3-1.3-2.4-2.3-3.2-3.1-10.7-10.4-11.3-11.5-.3-.8-.3-1.8-.3-2.9V14.6c0-1.8.1-3.6 1.1-4.6 1-1.1 2.9-1.2 4.7-1.2h19.900000000000002c1 0 2.1 0 2.9.4 1.1.6 1.7 1.8 2.3 3.2 6 14 7.2 18.1 7.4 19.3 0 1.4.1 2.4-.1 3.4" />
  </svg>
);
export default IconThumbsDownAlt;
