import * as React from "react";
const IconEmergencyPost = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      d="M19.758 32.97a1 1 0 0 1-.728-1.212L19.72 29H17a1 1 0 1 1 0-2h3.22l.81-3.242a1 1 0 1 1 1.94.485L22.28 27H25a1 1 0 1 1 0 2h-3.22l-.81 3.243a1 1 0 0 1-1.212.727"
    />
    <path
      
      fillRule="evenodd"
      d="M9.014 20.138C9.402 18.869 10.59 18 11.938 18H36v-2h-7v-6h9v8.962l5.935 13.687a.995.995 0 0 1-.642 1.303l.001.004L33.5 37l1-2.5 7.092-2.122-4.607-10.25-4.803 13.734C31.795 37.131 30.607 38 29.26 38H7.054c-2.044 0-3.51-1.937-2.923-3.862zM6.036 34.749l4.88-13.99.011-.037A1.05 1.05 0 0 1 11.938 20h23.673l-5.33 15.24-.011.038a1.05 1.05 0 0 1-1.011.722H7.054c-.737 0-1.178-.663-1.018-1.25M36 12h-5v2h5z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconEmergencyPost;
