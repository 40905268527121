import * as React from "react";
const IconQuarterMoon1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m28 30.1L33 47.3V35.8l25-14.1c1.3 3.2 2 6.7 2 10.3zM33 4c6.3.2 12.1 2.5 16.7 6.3L33 19.7zm24.2 15.8L33 33.5V22l18.3-10.3c2.4 2.3 4.4 5.1 5.9 8.1M4 32C4 16.9 16 4.6 31 4v56C16 59.4 4 47.1 4 32m29 28V49.6l26.9-15.2C58.7 48.4 47.2 59.5 33 60" />
  </svg>
);
export default IconQuarterMoon1;
