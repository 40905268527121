import * as React from "react";
const IconTentAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 53h-2.4L33.3 19.2l6.4-8.6c.3-.4.2-1.1-.2-1.4s-1.1-.2-1.4.2L32 17.5l-6.1-8.1c-.3-.4-1-.5-1.4-.2s-.5 1-.2 1.4l6.4 8.6L5.4 53H3c-.6 0-1 .4-1 1s.4 1 1 1h58c.6 0 1-.4 1-1s-.4-1-1-1M32 20.8 56.1 53H38.5l-3.7-12.7c-.1-.4-.5-.7-1-.7h-3.6c-.4 0-.8.3-1 .7L25.5 53H7.9zM27.6 53 31 41.6h2L36.4 53z" />
  </svg>
);
export default IconTentAlt;
