import * as React from "react";
const IconColdChain = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M33.965 42.95a8 8 0 0 1-8.914-13.236A5 5 0 0 1 25 29V9a5 5 0 0 1 10 0v20q0 .364-.05.714A7.99 7.99 0 0 1 38 36a8 8 0 0 1-3.787 6.802l-.198.198zm-7.676-11.665.006-.005 8.425 8.425a6 6 0 0 1-.557.616l-8.484-8.484q.286-.297.61-.552m-1.755 2.235q-.188.415-.313.858l7.4 7.4q.444-.123.859-.313zm-.436 3.563a6.01 6.01 0 0 0 4.819 4.82zm11.607.78-8.7-8.702A3 3 0 0 1 27 29v-1.014l8.93 8.93q-.075.488-.225.946m-.16-4.161L27 25.157v-1.172l5.963 5.964a2 2 0 0 0 .748 1.336 6 6 0 0 1 1.834 2.417M30 6a3 3 0 0 0-3 3v12.018c.238.02.472.056.697.097a23 23 0 0 1 1.607.38l.35.091c1.09.277 2.203.521 3.346.5V20h-2a1 1 0 1 1 0-2h2v-2h-2a1 1 0 1 1 0-2h2v-2h-2a1 1 0 1 1 0-2h2V9a3 3 0 0 0-3-3m3 21.157-3.564-3.564c.464.116.963.23 1.491.32L33 25.985z"
      clipRule="evenodd"
    />
    <path
      
      d="M16.33 12a1 1 0 1 0-2 0v3.268l-2.83-1.634a1 1 0 1 0-1 1.732L13.33 17l-2.83 1.634a1 1 0 1 0 1 1.732l2.83-1.634V22a1 1 0 0 0 2 0v-3.268l2.83 1.634a1 1 0 1 0 1-1.732L17.33 17l2.83-1.634a1 1 0 1 0-1-1.732l-2.83 1.634z"
    />
  </svg>
);
export default IconColdChain;
