import * as React from "react";
const IconInvestMonitorCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M12.5 38H4.1C2.9 38 2 39.1 2 40.4v18.4c0 1.3.9 2.4 2.1 2.4h8.4c1.2 0 2.1-1.1 2.1-2.4V40.4c-.1-1.3-1-2.4-2.1-2.4m0 20.7c0 .2-.1.4-.1.4H4.1S4 59 4 58.7V40.4c0-.3.1-.4.1-.4h8.3s.1.2.1.4zM36.2 32.7h-8.4c-1.2 0-2.1 1-2.1 2.2v23.9c0 1.2.9 2.2 2.1 2.2h8.4c1.2 0 2.1-1 2.1-2.2V35c0-1.3-1-2.3-2.1-2.3m.1 26.1c0 .2-.1.2-.1.2h-8.3s-.1-.1-.1-.2V35c0-.2.1-.2.1-.2h8.4s.1.1.1.2v23.8zM59.9 40.6h-8.4c-1.2 0-2.1 1.1-2.1 2.4v15.7c0 1.3.9 2.4 2.1 2.4h8.4c1.2 0 2.1-1.1 2.1-2.4V43c0-1.3-.9-2.4-2.1-2.4m.1 18.1c0 .2-.1.4-.1.4h-8.3s-.1-.1-.1-.4V43c0-.2.1-.4.1-.4h8.3s.1.2.1.4zM34.7 12.9h-5.3c-1.7 0-3-1.3-3-2.8 0-1.6 1.3-2.8 3-2.8h7.9c.6 0 1-.4 1-1s-.4-1-1-1h-3.5V3.9c0-.6-.4-1-1-1s-1 .4-1 1v1.3h-2.4c-2.8 0-5 2.2-5 4.8s2.2 4.8 5 4.8h5.3c1.7 0 3 1.3 3 2.8 0 1.6-1.3 2.8-3 2.8h-9c-.6 0-1 .4-1 1s.4 1 1 1h6v1.3c0 .6.4 1 1 1s1-.4 1-1v-1.3h1c2.8 0 5-2.2 5-4.8s-2.3-4.7-5-4.7" />
  </svg>
);
export default IconInvestMonitorCopy;
