import * as React from "react";
const IconSheep = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M39.6 35.8c-1.8 0-2.7.8-3.5 1.4-.7.5-1.2.9-2.3.9-1.1.1-1.6-.3-2.3-.8-.8-.5-1.8-1.2-3.5-1.2-1.8 0-2.7.8-3.5 1.4-.7.5-1.2.9-2.3.9s-1.6-.3-2.3-.8c-.8-.5-1.7-1.2-3.5-1.2-.9 0-1.8.4-2.6.8s-1.6.7-2.1.5-.9-1-1.2-1.9c-.4-.8-.7-1.7-1.4-2.3-.6-.6-1.5-1-2.3-1.3-.8-.2-1.7-.6-1.9-1.1-.2-.4.1-1.3.5-2.1.3-.9.7-1.7.7-2.6s-.4-1.8-.7-2.6-.7-1.7-.5-2.1c.2-.5 1.1-.8 1.9-1.2.8-.3 1.7-.7 2.3-1.3s1-1.5 1.3-2.3c.4-.8.7-1.7 1.2-1.9s1.3.2 2.1.5c.8.4 1.7.7 2.6.8 1.8.1 2.7-.6 3.5-1.2.7-.5 1.2-.8 2.3-.8s1.6.4 2.3.9c.7.6 1.7 1.3 3.5 1.4 1.8 0 2.7-.6 3.5-1.2.7-.5 1.2-.8 2.3-.8s1.6.4 2.3.9c.7.6 1.7 1.3 3.5 1.4.5 0 1-.4 1-1s-.4-1-1-1c-1.1 0-1.6-.4-2.3-.9-.7-.6-1.7-1.3-3.5-1.4s-2.7.6-3.5 1.2c-.7.5-1.2.8-2.3.8s-1.6-.4-2.3-.9c-.7-.6-1.7-1.3-3.5-1.4-1.8 0-2.7.6-3.5 1.2-.7.5-1.2.8-2.3.8-.5 0-1.2-.3-1.9-.6-1.1-.5-2.4-1-3.6-.6-1.3.5-1.8 1.8-2.3 2.9-.3.6-.6 1.3-.9 1.6s-1 .6-1.7.9c-1.2.5-2.5 1-3 2.2s0 2.5.5 3.6l.3.9h-.8c-.6 0-1 .4-1 1s.4 1 1 1h.9l-.3.9c-.5 1.1-1 2.4-.5 3.6.5 1.3 1.8 1.8 3 2.2.6.3 1.3.5 1.7.9s.6 1 .9 1.6c.5 1.1 1.1 2.4 2.3 2.9.8.3 1.7.2 2.5-.1v8.3c0 1.7 1.3 3 3 3h3c1.7 0 3-1.3 3-3v-7.4c1.6-.1 2.5-.8 3.3-1.3.1-.1.3-.2.4-.3v9c0 1.7 1.3 3 3 3h3c1.7 0 3-1.3 3-3v-7.8c1-.3 1.6-.7 2.1-1.2.7-.5 1.2-.9 2.3-.9.6 0 1-.5 1-1s-.4-.8-.9-.8M19.5 48.7h-3c-.6 0-1-.4-1-1v-9c.4-.1.7-.2 1-.2 1.1 0 1.6.3 2.3.8.5.3 1 .7 1.7.9v7.6c0 .5-.5.9-1 .9m12.7 0h-3c-.6 0-1-.4-1-1v-9.6c1 0 1.5.3 2.2.8s1.5 1 2.9 1.1v7.7c-.1.6-.5 1-1.1 1" />
    <path d="m60.5 21.8-4-2.1c-1-2.9-3.7-4.9-6.9-4.9-3.1 0-5.8 2-6.9 4.8-.1 0-.3 0-.4.1l-4.1 2.2c-.5.3-.7.9-.4 1.4.2.3.5.5.9.5.2 0 .3 0 .5-.1l3-1.6v5.7c0 4 3.3 7.3 7.3 7.3s7.3-3.3 7.3-7.3v-5.5l2.6 1.4c.1.1.3.1.5.1.4 0 .7-.2.9-.5.3-.7.2-1.3-.3-1.5m-5.6 5.9c0 2.9-2.4 5.3-5.3 5.3s-5.3-2.4-5.3-5.3v-5.6c0-2.9 2.4-5.3 5.3-5.3s5.3 2.4 5.3 5.3z" />
  </svg>
);
export default IconSheep;
