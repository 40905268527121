import * as React from "react";
const IconYenCoin = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M41.3 16.2 32 28.8l-9.3-12.7c-.3-.4-1-.5-1.4-.2s-.5 1-.2 1.4l10 13.5v2.7h-6.6c-.6 0-1 .4-1 1s.4 1 1 1H31v5.6h-6.6c-.6 0-1 .4-1 1s.4 1 1 1H31v6.6c0 .6.4 1 1 1s1-.4 1-1v-6.6h6.6c.6 0 1-.4 1-1s-.4-1-1-1H33v-5.6h6.6c.6 0 1-.4 1-1s-.4-1-1-1H33v-2.7l10-13.5c.3-.4.2-1.1-.2-1.4-.5-.3-1.1-.2-1.5.3" />
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
  </svg>
);
export default IconYenCoin;
