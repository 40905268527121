import * as React from "react";
const IconPruningShears = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.2 47 43.4 31.2c-.2-.2-.5-.4-.7-.5 0-.1-.1-.3-.1-.4 3.9-.3 7.5-1.9 10.3-4.7 3.1-3.1 4.7-7.1 4.7-11.4S56 5.9 52.9 2.8c-1-1-2.9-1-3.9 0l-17 17-17-17c-1-1-2.9-1-3.9 0-6.3 6.3-6.3 16.6 0 22.9 2.8 2.8 6.4 4.4 10.3 4.7 0 .1-.1.3-.1.4-.3.1-.5.3-.7.5L4.8 47c-1 1-1.6 2.4-1.6 3.9s.6 2.9 1.6 3.9l5.6 5.6c1.1 1.1 2.5 1.6 3.9 1.6s2.8-.5 3.9-1.6L32 46.6l13.8 13.8c2.2 2.2 5.7 2.2 7.8 0l5.6-5.6c2.1-2.1 2.1-5.7 0-7.8M50.4 4.2c.3-.3.8-.3 1.1 0 2.7 2.7 4.2 6.2 4.2 10s-1.5 7.3-4.2 10c-2.6 2.6-6 4-9.6 4.1q-.75-1.65-2.1-3c-2-2-4.6-3.1-7.4-3.2zM25.6 39.6c-3.5-3.5-3.5-9.2 0-12.7 1.7-1.7 4-2.6 6.4-2.6s4.7.9 6.4 2.6 2.6 4 2.6 6.4-.9 4.7-2.6 6.4-4 2.6-6.4 2.6c-2.4-.1-4.7-1-6.4-2.7M12.5 24.3C7 18.7 7 9.8 12.5 4.2c.3-.3.8-.3 1.1 0l18 18c-2.8.1-5.4 1.2-7.4 3.2q-1.35 1.35-2.1 3c-3.6-.1-7-1.6-9.6-4.1M16.8 59c-1.4 1.4-3.6 1.4-5 0l-5.6-5.6c-.7-.7-1-1.6-1-2.5s.4-1.8 1-2.5L21 33.6c.1 2.7 1.2 5.3 3.2 7.4 2 2 4.6 3.1 7.4 3.2zm41-5.6L52.2 59c-1.4 1.4-3.6 1.4-5 0L32.4 44.2c2.8-.1 5.4-1.2 7.4-3.2s3.1-4.6 3.2-7.4l14.8 14.8c1.3 1.4 1.3 3.6 0 5" />
  </svg>
);
export default IconPruningShears;
