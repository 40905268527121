import * as React from "react";
const IconMusicNote = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.4 2H40.1c-3.7 0-6.7 2.8-6.7 6.3v38c-2.5-3.1-7.1-5.1-12.4-5.1-7.9 0-14.4 4.7-14.4 10.4S13.1 62 21 62s14.4-4.7 14.4-10.4V8.3c0-2.4 2.1-4.3 4.7-4.3h16.3c.6 0 1-.4 1-1s-.5-1-1-1M21 60c-6.8 0-12.4-3.8-12.4-8.4s5.5-8.4 12.4-8.4c6.8 0 12.4 3.8 12.4 8.4S27.8 60 21 60" />
  </svg>
);
export default IconMusicNote;
