import * as React from "react";
const IconHandbag = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.6 25.4c-.7-.6-1.6-.8-2.5-.5-4.7 1.5-9.1 2.5-13.2 3.1-.1-13-2.8-26-7.9-26s-7.9 13.1-8 26.1v.1c-6-.7-10.5-2.1-13-3-.9-.3-1.8-.2-2.6.4-.7.6-1.1 1.5-.9 2.4l5.1 29.3c.5 2.8 2.9 4.8 5.7 4.8h27.3c2.8 0 5.2-2 5.7-4.8l5.2-29.5c.1-.9-.2-1.8-.9-2.4M26 28.1C26.1 13.2 29.6 4 32 4s5.9 9.2 6 24.1v.1c-4.4.5-8.5.5-12.1.2.1-.1.1-.2.1-.3m28.5-.7-5.2 29.5c-.3 1.8-1.8 3.1-3.7 3.1H18.3c-1.8 0-3.4-1.3-3.7-3.1L9.5 27.6c-.1-.3.1-.5.2-.6.1 0 .2-.1.4-.1h.2c6 2.3 22.3 6.8 43.5-.2.3-.1.5 0 .5.1.1.1.3.3.2.6" />
  </svg>
);
export default IconHandbag;
