import * as React from "react";
const IconHalfAnHour = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M16.3 41.5 12.8 45c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l3.5-3.5c.4-.4.4-1 0-1.4s-1-.4-1.4 0M37.3 22.8c.3 0 .5-.1.7-.3l3.5-3.5c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-3.5 3.5c-.4.4-.4 1 0 1.4.2.2.5.3.7.3M46.5 31h-4.9c-.6 0-1 .4-1 1s.4 1 1 1h4.9c.6 0 1-.4 1-1s-.5-1-1-1M14.2 17.6c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l3.5 3.5c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4zM38 41.5c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l3.5 3.5c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4z" />
    <path d="M27.2 2c-.6 0-1 .4-1 1v58c0 .6.4 1 1 1 16.5 0 30-13.5 30-30S43.7 2 27.2 2m1 58V4c15 .5 27 12.9 27 28s-12.1 27.4-27 28M13.8 32c0-.6-.4-1-1-1h-5c-.6 0-1 .4-1 1s.4 1 1 1h4.9c.6 0 1.1-.5 1.1-1" />
  </svg>
);
export default IconHalfAnHour;
