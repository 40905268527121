import * as React from "react";
const Icon2ndPrize = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.4 15.1h-9.6v-4.5c0-2.2-1.8-4-4-4H19.2c-2.2 0-4 1.8-4 4v4.5H7c-2.7 0-5 2.2-5 5v.9c0 5.6 4.6 10.2 10.2 10.2h3c.4 6.8 6 12.2 12.9 12.2H31v11.4c0 .2.1.3.1.5h-6.3c-.6 0-1 .4-1 1s.4 1 1 1h14.4c.6 0 1-.4 1-1s-.4-1-1-1h-6.3c.1-.1.1-.3.1-.5V43.5h2.8c6.9 0 12.5-5.4 12.9-12.2h3C57.4 31.3 62 26.7 62 21v-2.3c0-1.9-1.6-3.6-3.6-3.6m-41.2-4.4c0-1.1.9-2 2-2h25.5c1.1 0 2 .9 2 2v4.5H17.2zM4 21v-.9c0-1.6 1.3-3 3-3h8.3v12.1h-3C7.7 29.3 4 25.6 4 21m31.8 20.5h-7.6c-6 0-10.9-4.9-10.9-10.9V17.1h29.5v13.4c0 6.1-4.9 11-11 11M60 21c0 4.5-3.7 8.2-8.2 8.2h-3V17.1h9.6c.9 0 1.6.7 1.6 1.6z" />
    <path d="M34.4 21.2h-5.2c-.6 0-1 .4-1 1s.4 1 1 1h4.2v3.5h-4.2c-.6 0-1 .4-1 1v5.8c0 .6.4 1 1 1h5.2c.6 0 1-.4 1-1s-.4-1-1-1h-4.2v-3.8h4.2c.6 0 1-.4 1-1v-5.5c0-.5-.4-1-1-1" />
  </svg>
);
export default Icon2ndPrize;
