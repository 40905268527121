import React, { useState, useEffect } from 'react';
import { BringzzText, BringzzButton } from "@bringzz/components"
import { useNavigation } from 'context/NavigationContext';
import backgroundImage from 'assets/images/onboarding.jpg'; // Adjust the path according to your project structure

const OnboardingPage = () => {
	const { navigate } = useNavigation();
	const slides = [
		{ title: '24/7 Delivery to your Doorsteps', body: 'Lorem ipsum dolor sit amet, consectetur adipis-cing elit. Vestibu-lum consequat nisl eget ultricies mollis. Praesent eu sodales libe-ro, sit amet com-modo ante. Fusce ut mauris urna. Vestibulum con', imagePath: 'path_to_image1.jpg' },
		{ title: 'Chat with your Pharmacy', body: 'Lorem ipsum dolor sit amet, consectetur adipis-cing elit. Vestibu-lum consequat nisl eget ultricies mollis. Praesent eu sodales libe-ro, sit amet com-modo ante. Fusce ut mauris urna. Vestibulum con', imagePath: 'path_to_image2.jpg' },
		{ title: 'Convenience at your finger tips', body: 'Lorem ipsum dolor sit amet, consectetur adipis-cing elit. Vestibu-lum consequat nisl eget ultricies mollis. Praesent eu sodales libe-ro, sit amet com-modo ante. Fusce ut mauris urna. Vestibulum con', imagePath: 'path_to_image3.jpg' },
	];

	const [currentSlide, setCurrentSlide] = useState(0);
	const [touchPosition, setTouchPosition] = useState(null);
	const [translateX, setTranslateX] = useState(0);
	const [autoPlay, setAutoPlay] = useState(true);

	// Auto slide to the next slide every 3 seconds
	useEffect(() => {
		const interval = autoPlay ? setInterval(() => {
			nextSlide();
		}, 3000) : null;

		return () => {
			if (interval) {
				clearInterval(interval);
			}
		};
	}, [currentSlide, autoPlay]);

	// Handle touch start (to stop auto-swipe when user interacts)
	const handleTouchStart = (e) => {
		const touchDown = e.touches[0].clientX;
		setTouchPosition(touchDown);
		setAutoPlay(false);  // Stop auto-swiping when the user starts swiping manually
	};

	// Handle touch move
	const handleTouchMove = (e) => {
		if (touchPosition === null) {
			return;
		}

		const currentTouch = e.touches[0].clientX;
		const diff = touchPosition - currentTouch;
		setTranslateX(-diff);
	};

	// Handle touch end (to possibly resume auto-swipe)
	const handleTouchEnd = () => {
		const threshold = window.innerWidth * 0.6;
		if (translateX > threshold) {
			prevSlide();
		} else if (translateX < -threshold) {
			nextSlide();
		} else {
			setTranslateX(0);
		}
		setAutoPlay(true);  // Resume auto-swiping after user interaction is complete
	};

	// Navigate to the next slide
	const nextSlide = () => {
		setCurrentSlide((prev) => (prev + 1) % slides.length);
		setTranslateX(0);
	};

	// Navigate to the previous slide
	const prevSlide = () => {
		setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
		setTranslateX(0);
	};

	return (
		<div className='relative flex justify-center bg-[#efe6fe]'>
			<div
				className='relative h-screen w-full bg-cover flex flex-col md:max-w-2xl bg-magic-lilac'
				style={{ backgroundImage: `url(${backgroundImage})` }}
			>
				{/* Progress Indicator */}
				<div className='absolute m-4 top-0 left-0 right-0 h-0.5 bg-gray-300'>
					<div
						className="absolute h-full bg-magic-lilac transition-transform duration-300 ease-out"
						style={{
							width: `${100 / slides.length}%`,
							transform: `translateX(${currentSlide * 100 - (translateX / window.innerWidth * 100 / slides.length)}%)`
						}}
					/>
				</div>

				<div className='absolute w-full h-2/3 bottom-0 bg-gradient-to-t from-[#05013B] to-transparent'></div>

				{/* Content */}
				<div className="flex-grow flex flex-col justify-between px-4 py-8">
					<div className="relative w-full h-full overflow-hidden"
						onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
						{slides.map((slide, index) => (
							<div
								key={index}
								className="absolute w-full h-full transition-transform duration-300 ease-out"
								style={{
									transform: `translateX(${(index - currentSlide) * 100 + (translateX / window.innerWidth * 100)}%)`,
									background: `url(${slide.imagePath}) no-repeat center center/cover`,
									backgroundSize: 'cover',
								}}
							>
								<div className="w-full h-full flex flex-col justify-end pb-8">
									<div className='px-4 py-5'>
										<BringzzText tag="h1" className="text-white">{slide.title}</BringzzText>
									</div>
									<BringzzText tag="h4" className="text-white font-normal">{slide.body}</BringzzText>
								</div>
							</div>
						))}
					</div>
					<div className='space-y-4 z-10'>
						<div className='flex justify-center space-x-6 px-4'>
							<BringzzButton
								size="lg"
								className="w-full flex justify-center bg-white text-midnight-blue"
								onClick={() => navigate('/auth')}
							>Join Us</BringzzButton>
							<BringzzButton
								size="lg"
								className="w-full flex justify-center bg-transparent text-white border-2 border-white"
								onClick={() => navigate('/auth')}
							>Sign In</BringzzButton>
						</div>
						<div className='flex items-center space-x-1 cursor-pointer' onClick={() => navigate("/business-auth")}>
							<div className='text-[7px] text-magic-lilac'>&#9679;</div>
							<BringzzText tag="h5" className="text-magic-lilac font-bold">Business Account?</BringzzText>
						</div>

					</div>
				</div>
			</div>
		</div>
	);
};

export default OnboardingPage;
