import * as React from "react";
const IconHospitalAlt5 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55 4.4H9c-3.8 0-7 3.1-7 7v41.2c0 3.8 3.1 7 7 7h46c3.8 0 7-3.1 7-7V11.4c0-3.9-3.1-7-7-7m-46 2h46c2.7 0 5 2.2 5 5v6.7H4v-6.7c0-2.8 2.2-5 5-5m46 51.2H9c-2.7 0-5-2.2-5-5V20.1h56v32.6c0 2.7-2.2 4.9-5 4.9" />
    <path d="M39.6 36.4H33v-6.6c0-.6-.4-1-1-1s-1 .4-1 1v6.6h-6.6c-.6 0-1 .4-1 1s.4 1 1 1H31V45c0 .6.4 1 1 1s1-.4 1-1v-6.6h6.6c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconHospitalAlt5;
