import * as React from "react";
const IconPlantPot = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.7 22.4c5.9-5.8 5.8-18.8 5.8-19.4 0-.5-.4-1-1-1-.5 0-13.8-.1-19.7 5.7-2.6 2.5-4 6.5-4.8 10.2-.8-3.7-2.2-7.6-4.8-10.2C21.3 1.9 8 2 7.4 2c-.5 0-1 .4-1 1s-.1 13.6 5.8 19.4c5 4.9 15.4 5.6 18.7 5.7v8.3H21c-1.5 0-3 .7-4 1.9s-1.4 2.7-1.2 4.3l2.5 15.1c.4 2.5 2.6 4.4 5.2 4.4h17.1c2.6 0 4.8-1.8 5.2-4.4l2.5-15.1c.3-1.5-.2-3.1-1.2-4.3s-2.5-1.9-4-1.9H33v-8.3c3.3-.1 13.7-.8 18.7-5.7m-6.2 17.2c.6.7.9 1.7.7 2.6l-2.5 15.1c-.3 1.6-1.6 2.7-3.2 2.7h-17c-1.6 0-3-1.1-3.2-2.7l-2.5-15.1c-.2-.9.1-1.9.7-2.6s1.5-1.2 2.5-1.2h22c1 0 1.9.4 2.5 1.2M38.2 9.1c4.4-4.4 14.2-5 17.3-5.1-.1 3.1-.8 12.6-5.2 16.9-4.4 4.4-14.2 5-17.3 5.1.1-3 .8-12.5 5.2-16.9M13.7 21C9.2 16.6 8.5 7.1 8.4 4c3.2.1 12.9.8 17.3 5.1s5.1 13.8 5.2 16.9c-3.1 0-12.8-.7-17.2-5" />
  </svg>
);
export default IconPlantPot;
