import * as React from "react";
const IconMusicCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.4 3.2c-.2-.2-.5-.2-.8-.2l-36.2 7.9c-.5.1-.8.5-.8 1v33.5c-1.8-2.3-4.6-3.8-7.7-3.8-5.4 0-9.7 4.4-9.7 9.7S8.5 61 13.9 61s9.7-4.4 9.7-9.7V28.1l34.2-7.4v18.6c-1.8-2.3-4.6-3.8-7.7-3.8-5.4 0-9.7 4.4-9.7 9.7s4.4 9.7 9.7 9.7 9.7-4.4 9.7-9.7V4c0-.3-.1-.6-.4-.8M13.9 59c-4.3 0-7.7-3.5-7.7-7.7s3.5-7.7 7.7-7.7 7.7 3.5 7.7 7.7-3.4 7.7-7.7 7.7m36.2-6.1c-4.3 0-7.7-3.5-7.7-7.7s3.5-7.7 7.7-7.7 7.7 3.5 7.7 7.7-3.5 7.7-7.7 7.7m7.7-34.3L23.6 26V12.7l34.2-7.4z" />
  </svg>
);
export default IconMusicCopy;
