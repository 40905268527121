import * as React from "react";
const IconBells = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m60.5 39.2-1.7-.9c-2.4-1.3-4.3-3.4-5.4-5.8l-2.1-6.1c-1-2.9-3-5.2-5.8-6.6-2.5-1.2-5.3-1.5-8-.8l-1.1-3.1c-.2-.5-.7-.8-1.3-.6-.5.2-.8.7-.6 1.3l1.1 3.1c-.9.4-1.7.9-2.5 1.5 0-.1-.1-.3-.2-.4-1.1-1.4-2.6-2.5-4.2-3.2l1.1-3.1c.2-.5-.1-1.1-.6-1.3s-1.1.1-1.3.6l-1.1 3.1c-2.7-.8-5.5-.6-8 .6-2.8 1.3-4.9 3.6-6 6.5l-2.2 5.9-.1.2c-1.1 2.4-3.1 4.5-5.5 5.7l-1.7.9c-.1 0-.3.3-.3.7s.3.7.7.9l14.2 5.2-1.5 4.2c-.2.5.1 1.1.6 1.3.1 0 .2.1.3.1.4 0 .8-.3.9-.7l1.5-4.2 10.8 3.9-.7 1.5c-.2.4-.1.8.2 1.1.2.2.5.3.7.3.1 0 .2 0 .3-.1l13.5-4.6 1.5 3.6q.3.6.9.6c.1 0 .3 0 .4-.1.5-.2.8-.8.5-1.3l-1.5-3.5 14-4.6c.4-.1.6-.5.7-.9 0-.3-.2-.7-.5-.9m-47.9-8.4 2.3-6.2c.9-2.4 2.6-4.3 4.9-5.3 2.3-1.1 4.9-1.2 7.2-.3l.3.1h.1c1.6.6 3 1.6 4 2.9.1.1.3.2.4.3-.8.8-1.5 1.8-2 2.9-1.4 2.7-1.6 5.9-.6 8.8l2 6v.2c.5 2.1.5 4.2-.1 6.3l-24.7-9c2.7-1.7 4.9-4 6.2-6.7m20.1 17.5c.2-.2.3-.4.3-.7v-.1c.9-2.6 1-5.4.4-8l-2.1-6.3c-.8-2.4-.6-5 .5-7.2 1.1-2.3 3-4 5.5-4.8l.3-.1c1-.3 2-.5 3-.5 1.4 0 2.9.3 4.2 1 2.3 1.1 4 3 4.8 5.4l2.1 6.1.1.2c1.2 2.8 3.3 5.1 5.9 6.6z" />
  </svg>
);
export default IconBells;
