import * as React from "react";
const IconSinusoid = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 31H50.5c.2-4.1 1.4-19.1 6.7-23.6 1.1-1 2.3-1.3 3.7-1.1.5.1 1.1-.3 1.1-.9.1-.5-.3-1.1-.9-1.1-1.9-.3-3.7.3-5.3 1.6-6 5.1-7.2 21-7.4 25.1h-33c.5-10.1 3-24.9 8-25q1.5 0 2.7 1.2c4.3 4.3 4.1 17.8 4.1 17.9 0 .6.4 1 1 1 .5 0 1-.4 1-1s.1-14.5-4.7-19.4C26.4 4.6 25 4 23.5 4c-8.1 0-9.7 21.6-10 27H3c-.6 0-1 .4-1 1s.4 1 1 1h28c.2 5.4 1.7 27 10.1 27 1.6 0 3.1-.6 4.3-1.9 4.9-5 4.5-18.7 4.5-19.3s-.5-1-1-1c-.6 0-1 .5-1 1 0 .1.3 13.4-3.9 17.9-.8.9-1.8 1.3-2.9 1.3-5.2 0-7.7-14.9-8.1-25h28c.6 0 1-.4 1-1s-.4-1-1-1" />
    <path d="M14.4 37.8c-.6 0-1 .4-1 1 0 .1-.1 12.7-5.2 17.3-1.4 1.3-3.1 1.8-5.1 1.6-.5-.1-1 .3-1.1.9-.1.5.3 1 .9 1.1.4 0 .7.1 1 .1 2.1 0 4-.7 5.6-2.2 5.8-5.2 5.9-18.2 5.9-18.8s-.4-1-1-1" />
  </svg>
);
export default IconSinusoid;
