import * as React from "react";
const IconDollarCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M39.7 31H24.3c-5.9 0-10.6-4.5-10.6-10.1s4.8-10.1 10.6-10.1h22.9c.6 0 1-.4 1-1s-.4-1-1-1H35.1V3c0-.6-.4-1-1-1s-1 .4-1 1v5.7h-8.8c-7 0-12.6 5.4-12.6 12.1s5.7 12.1 12.6 12.1h15.5c5.9 0 10.6 4.5 10.6 10.1s-4.8 10.1-10.6 10.1H13.6c-.6 0-1 .4-1 1s.4 1 1 1h19.5V61c0 .6.4 1 1 1s1-.4 1-1v-5.7h4.7c7 0 12.6-5.4 12.6-12.1S46.7 31 39.7 31" />
  </svg>
);
export default IconDollarCopy;
