import * as React from "react";
const IconChickenRoast = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 48.9h-8.6c.7-.9 1.1-2 1.1-3.2 0-5.8-2.2-11.2-6.3-15.3s-9.5-6.3-15.3-6.3q-2.55 0-4.8.6-1.35-.75-3-1.5l-.2-.2c-3.1-1.3-6.1-2.6-8-1.7 0 0-.3.1-.6.3L11 17.2l1.1-2.7c.2-.5 0-1.1-.5-1.3s-1.1 0-1.3.5l-1.3 3-2.9 1.2c-.5.2-.8.8-.5 1.3q.3.6.9.6c.1 0 .3 0 .4-.1l2.7-1.1L14 23c0 .1 0 .1-.1.2-.1.2-.2.4-.2.5-.6 1.5 1 6.3 1.8 8.2-3.2 3.9-5 8.7-5 13.8 0 1.2.4 2.3 1.1 3.2H3c-.6 0-1 .4-1 1s.4 1 1 1h58c.6 0 1-.4 1-1s-.4-1-1-1m-37.9-24 .3.1c2.4 1 4.8 2.5 5.9 3.7 1.1 1.1 1.7 2.6 1.7 4.2s-.6 3.1-1.7 4.2-2.6 1.7-4.2 1.7-3.1-.7-4.2-1.8c-1.2-1.2-2.6-3.5-3.7-5.9-1.3-2.9-2-6-1.8-6.6 0-.1.1-.2.2-.4s.1-.3.2-.4c.3-.1.7-.3.9-.4 1.2-.7 4.1.5 6.4 1.6M12.4 45.6c0-4.3 1.4-8.4 4-11.8 1 1.9 2.1 3.5 3.2 4.5 1.5 1.5 3.5 2.3 5.6 2.3s4.1-.8 5.6-2.3 2.3-3.5 2.3-5.6-.8-4.1-2.3-5.6c-.3-.3-.7-.7-1.2-1 .8-.1 1.6-.2 2.4-.2 5.2 0 10.2 2 13.9 5.7s5.7 8.6 5.7 13.9c0 .9-.3 1.7-1 2.3-.6.6-1.4.9-2.3.9H15.6c-1.8.2-3.2-1.3-3.2-3.1" />
  </svg>
);
export default IconChickenRoast;
