import * as React from "react";
const IconPlaceholderPound = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2C18.7 2 7.9 12.8 7.9 26.1c0 18.8 22.5 35.1 23.5 35.7.2.1.4.2.6.2s.4-.1.6-.2c1-.7 23.5-16.9 23.5-35.7C56.1 12.8 45.3 2 32 2m0 57.8C28.5 57.1 9.9 42.3 9.9 26.1 9.9 13.9 19.8 4 32 4s22.1 9.9 22.1 22.1c0 16.2-18.6 31-22.1 33.7" />
    <path d="M40.7 33.4H29.5c.9-1 1.4-2.3 1.4-3.8V27H39c.6 0 1-.4 1-1s-.4-1-1-1h-8.1v-3.4c0-2.5 2-4.5 4.5-4.5 1.2 0 2.2.4 3.1 1.2.4.4 1 .4 1.4 0s.4-1 0-1.4c-1.2-1.2-2.8-1.8-4.5-1.8-3.6 0-6.5 2.9-6.5 6.5V25h-4.8c-.6 0-1 .4-1 1s.4 1 1 1h4.8v2.6c0 2.1-1.7 3.8-3.8 3.8h-1.9c-.6 0-1 .4-1 1s.4 1 1 1h17.5c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconPlaceholderPound;
