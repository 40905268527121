import * as React from "react";
const IconDumbellAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M11.8 28.2c5.1 0 9.3-3.9 9.8-8.8h20.9c.5 4.9 4.7 8.8 9.8 8.8 5.4 0 9.8-4.4 9.8-9.8s-4.4-9.8-9.8-9.8c-5.1 0-9.3 3.9-9.8 8.8H21.6c-.5-4.9-4.7-8.8-9.8-8.8C6.4 8.5 2 12.9 2 18.3s4.4 9.9 9.8 9.9m40.4-17.7c4.3 0 7.8 3.5 7.8 7.8s-3.5 7.8-7.8 7.8-7.8-3.5-7.8-7.8 3.5-7.8 7.8-7.8m-40.4 0c4.3 0 7.8 3.5 7.8 7.8s-3.5 7.8-7.8 7.8S4 22.6 4 18.3s3.5-7.8 7.8-7.8M52.2 35.8c-5.1 0-9.3 3.9-9.8 8.8H21.6c-.5-4.9-4.7-8.8-9.8-8.8-5.4 0-9.8 4.4-9.8 9.8s4.4 9.8 9.8 9.8c5.1 0 9.3-3.9 9.8-8.8h20.9c.5 4.9 4.7 8.8 9.8 8.8 5.4 0 9.8-4.4 9.8-9.8-.1-5.3-4.5-9.8-9.9-9.8M11.8 53.5C7.5 53.5 4 50 4 45.7s3.5-7.8 7.8-7.8 7.8 3.5 7.8 7.8-3.5 7.8-7.8 7.8m40.4 0c-4.3 0-7.8-3.5-7.8-7.8s3.5-7.8 7.8-7.8 7.8 3.5 7.8 7.8-3.5 7.8-7.8 7.8" />
  </svg>
);
export default IconDumbellAlt;
