import * as React from "react";
const IconJoomla = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.8 43.7c.3-4.2-1.1-8.3-4-11.4 2.8-3.2 4.2-7.5 3.7-11.6 3.7-1.4 6.2-5.1 6.2-9.3 0-5.5-4.5-10-10-10-4.1 0-7.8 2.5-9.3 6.1-4.2-.4-8.6.8-11.9 3.7-3.1-2.8-7.2-4.2-11.3-3.8-1.6-3.5-5.1-6.1-9.3-6.1C5.5 1.1 1 5.7 1 11.2c0 4 2.3 7.3 5.6 9-.6 4.5.7 8.8 3.8 12.1-3 3.2-4.2 7.3-3.8 11.6C3.3 45.5 1 49 1 52.8c0 5.5 4.5 10 10 10 4 0 7.5-2.3 9.2-5.6 4.2.4 8.2-1 11.4-3.8 3.2 3 7.8 4.4 12.1 3.7 1.7 3.4 5.2 5.6 9.2 5.6 5.5 0 10-4.5 10-10 .2-3.8-2.3-7.5-6.1-9m-12.7-1.3c0 .6-.1 1.1-.7 1.6-.7.7-1.7.8-2.5.4l3-3c0 .3.2.7.2 1m-24.6-.9 3 3c-.7.3-1.7.1-2.4-.4-.4-.4-.7-1-.7-1.6 0-.4 0-.8.1-1m12.1-5.9-3.2-3.2 3.2-3.2 3.2 3.2zM19.4 22.3c0-.6.1-1.1.7-1.6.7-.7 1.7-.8 2.5-.4l-3 3c-.2-.4-.2-.7-.2-1m24.4.9-3-3c.7-.3 1.8-.1 2.5.4.4.4.7 1 .7 1.6.1.4-.1.7-.2 1m.7-10.6 2.7.7.4-2.8C48 8 50.3 6 52.7 6c3 0 5.2 2.3 5.2 5.1 0 2.7-2 4.8-4.5 5.1l-2.8.4.7 2.7c.8 3 .3 6.1-1.4 8.6l-2.1-2.3c.7-1.1 1-2.3 1-3.5q0-3-2.1-5.1c-2.8-2.8-7.2-2.8-10 0l-11 11-2.3-2.1 11-10.9c2.6-2.6 6.6-3.5 10.1-2.4M5.9 11.2c0-2.8 2.3-5.1 5.2-5.1 2.5 0 4.8 1.8 5.1 4.4l.4 2.5 2.5-.6c3-.7 5.8-.1 8.2 1.6L25 16.3c-2.7-1.7-6.4-1.3-8.6 1.1q-2.1 2.1-2.1 5.1t2.1 5.1l11 11-2.4 2-9.7-10-1.1-1.1c-2.7-2.7-3.5-6.4-2.7-10.2l.7-2.4-2.5-.6c-2.2-.5-3.8-2.7-3.8-5.1m13.3 41.1-2.4-.6-.6 2.4c-.4 2.4-2.7 4.1-5.1 4.1-3 0-5.2-2.3-5.2-5.1 0-2.4 1.7-4.5 4-5.1l2.4-.6-.6-2.7c-.7-3-.1-6.1 1.4-8.5l2.3 2.3c-.7 1.1-1 2.3-1 3.5q0 3 2.1 5.1c2.8 2.8 7.2 2.8 10 0l11-11 2.3 2.1-11 11c-2.5 2.8-6.1 3.8-9.6 3.1m33.9 5.8c-2.7-.1-4.8-1.8-5.4-4.2l-.4-2.7-2.5 1c-3.1.8-6.4.4-8.9-1.4l2.3-2.3c2.7 1.7 6.4 1.3 8.6-1.1q2.1-2.1 2.1-5.1t-2.1-5.1L35.9 26.4l2.3-2.3 11 11c2.5 2.5 3.5 6.2 2.7 9.7l-.6 2.7 2.7.5c2.5.4 4.5 2.7 4.5 5.1-.3 2.7-2.6 5-5.4 5" />
  </svg>
);
export default IconJoomla;
