import * as React from "react";
const IconWeddingSong = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62 9.5c0-1.4-.5-2.6-1.5-3.5s-2.3-1.4-3.6-1.3l-30 1.8c-2.6.2-4.6 2.3-4.6 4.9v29c-.2-.2-.5-.4-.8-.6q-2.1-1.35-4.2-.9c-1.9.4-3.3 1.9-4.1 3.1-.9-1.1-2.3-2.6-4.2-3.1q-2.1-.45-4.2.9C.7 42.5 2 47.9 3.2 50.4c.1.3 3.4 6.9 9.7 8.9h.6c6.3-2 9.4-8.7 9.5-8.9.5-1.1 1.1-2.7 1.2-4.4V19.1L60 16.9V30c-.2-.2-.5-.4-.8-.6q-2.1-1.35-4.2-.9c-1.9.4-3.3 1.9-4.2 3.1-.9-1.1-2.3-2.6-4.2-3.1q-2.1-.45-4.2.9c-4.1 2.8-2.8 8.3-1.6 10.7.1.3 3.4 6.9 9.7 8.9h.6c6.3-2 9.4-8.7 9.5-8.9.6-1.2 1.2-3.2 1.2-5.3V9.5zm-3 29.7c0 .1-2.7 5.9-8 7.8-5.3-1.9-8.2-7.7-8.2-7.8s-2.8-5.7.9-8.2c.7-.5 1.3-.7 1.9-.7.2 0 .4 0 .7.1 2.2.5 3.7 3.4 3.7 3.4.2.3.5.5.9.5s.7-.2.9-.5c.4-.8 1.9-3 3.7-3.4.9-.2 1.7 0 2.6.6 1.4.9 1.8 2.3 1.9 3.6v.2c.1 2.2-.9 4.2-1 4.4M13.2 57.3C7.9 55.4 5 49.6 5 49.5c-.1-.2-2.7-5.7.9-8.2.6-.5 1.3-.7 1.9-.7.2 0 .4 0 .6.1 2.2.5 3.7 3.4 3.7 3.4.3.6 1.4.6 1.8 0 0 0 1.6-2.9 3.7-3.4.9-.2 1.7 0 2.6.6 1.4.9 1.8 2.3 1.9 3.6v.8c-.1 2-.9 3.7-1 3.8.1.1-2.6 5.9-7.9 7.8m11-40.2v-5.8c0-1.5 1.2-2.8 2.7-2.9l30-1.8c.8 0 1.6.2 2.1.8s1 1.3 1 2.1v5.4z" />
  </svg>
);
export default IconWeddingSong;
