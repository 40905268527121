import * as React from "react";
const IconTools = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M36.1 38.6h-3.9V22h2.1c.5 0 .9-.4 1-.9l1.2-8.5c0-.3 0-.6-.2-.8s-.5-.3-.8-.3H27c-.3 0-.6.1-.8.3s-.3.5-.2.8l1.2 8.5c.1.5.5.9 1 .9h2.1v16.5h-3.9c-.6 0-1 .4-1 1v7.2c0 3.2 2.6 5.9 5.9 5.9 3.2 0 5.9-2.6 5.9-5.9v-7.2c-.1-.5-.5-.9-1.1-.9m-8-25.1h6.2l-.8 6.5H29zm7 33.3c0 2.1-1.7 3.9-3.9 3.9s-3.9-1.7-3.9-3.9v-6.2h2.9v1.3c0 .6.4 1 1 1s1-.4 1-1v-1.3h2.9zM17.1 12.2c-.4-.5-1.1-.9-1.7-.9H6.5c-2.5 0-4.5 2-4.5 4.5v2.4c0 2.5 2 4.5 4.5 4.5H9v27.5c0 .6.4 1 1 1s1-.4 1-1V22.8h4.4c.7 0 1.3-.3 1.7-.9l2.6-3.5c.6-.8.6-1.8 0-2.6zm1 5-2.6 3.5-.1.1H6.5c-1.4 0-2.5-1.1-2.5-2.5v-2.4c0-1.4 1.1-2.5 2.5-2.5h8.8c.1 0 .1 0 .1.1L18 17c.2 0 .2.1.1.2M58.6 37.1V26l2.9-5.1c.6-1 .6-2.3 0-3.4l-3.1-5.3c-.3-.5-.9-.6-1.4-.4-.5.3-.6.9-.4 1.4l3.1 5.3c.2.4.2.9 0 1.4l-3.1 5.3c-.1.2-.1.3-.1.5v11.6c0 .2 0 .3.1.5l3.1 5.3c.2.4.2.9 0 1.4l-2.4 4.1c-.2.4-.7.7-1.2.7h-4.8c-.5 0-.9-.3-1.2-.7l-2.4-4.1c-.2-.4-.2-.9 0-1.4l3.1-5.3c.1-.2.1-.3.1-.5V25.7c0-.2 0-.3-.1-.5l-3.1-5.3c-.2-.4-.2-.9 0-1.4l3.1-5.3c.3-.5.1-1.1-.4-1.4s-1.1-.1-1.4.4l-3.1 5.3c-.6 1-.6 2.3 0 3.4l2.9 5.1v11.1l-2.9 5.1c-.6 1-.6 2.3 0 3.4l2.4 4.1c.6 1 1.7 1.7 2.9 1.7H56c1.2 0 2.3-.6 2.9-1.7l2.4-4.1c.6-1 .6-2.3 0-3.4z" />
  </svg>
);
export default IconTools;
