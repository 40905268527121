import * as React from "react";
const IconThumbsUpAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.9 28.6c-.1-.4-.1-.7-.2-1-.5-1.9-1.7-3.4-3.2-4.1s-3.1-.7-4.5-.7l-12.7.1 1.9-8.8c.2-1.1.6-2.5.2-3.9-.6-2.3-3.2-3.8-5.5-3.2-1.5.4-2.7 1.5-3.6 2.4-.5.5-1.3 1.3-2.4 2.3-8.5 8.3-11.4 11.2-11.7 12.2-.5 1.2-.5 2.6-.5 3.6v21.9c0 2.2.1 4.4 1.7 6s4 1.8 6.1 1.8h19.900000000000002c1.1 0 2.6 0 3.8-.7 1.6-.9 2.4-2.5 3.2-4.2 4.6-10.8 7.2-17.5 7.5-19.7.1-1.6.2-2.8 0-4m-2.1 3.6c-.2 1.2-1.4 5.3-7.4 19.3-.6 1.4-1.2 2.7-2.3 3.2-.8.4-1.9.4-2.9.4H27.4c-1.8 0-3.7-.1-4.7-1.2-1-1-1.1-2.8-1.1-4.6V27.4c0-1.1 0-2.1.3-2.9.6-1.1 8.1-8.4 11.3-11.5 1-1 1.9-1.8 2.4-2.3.8-.8 1.6-1.6 2.6-1.9.2 0 .3-.1.5-.1 1.1 0 2.2.8 2.5 1.8.3.9 0 2-.2 2.9l-2.2 10c-.1.3 0 .6.2.8s.4.4.8.4l14.2.1c1.2 0 2.5 0 3.6.5 1 .5 1.8 1.6 2.2 2.9.1.3.1.5.2.8.1 1 0 2-.2 3.3M9.6 25.3H6.4c-2.5 0-4.4 2-4.4 4.4v23c0 2.4 2 4.4 4.4 4.4h3.1c2.5 0 4.4-2 4.4-4.4v-23c.1-2.4-1.9-4.4-4.3-4.4M12 52.7c0 1.3-1.1 2.4-2.4 2.4H6.4C5 55.1 4 54 4 52.7v-23c0-1.3 1.1-2.4 2.4-2.4h3.1c1.4 0 2.4 1.1 2.4 2.4v23z" />
  </svg>
);
export default IconThumbsUpAlt;
