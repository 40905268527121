import * as React from "react";
const IconArrowsExpandUpLeft = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.8 61H44.6c-2.2 0-4-1.8-4-4V44.8c0-2.2 1.8-4 4-4h12.2c2.2 0 4 1.8 4 4V57c0 2.2-1.8 4-4 4M44.6 42.8c-1.1 0-2 .9-2 2V57c0 1.1.9 2 2 2h12.2c1.1 0 2-.9 2-2V44.8c0-1.1-.9-2-2-2zM30.5 31.5c-.3 0-.5-.1-.7-.3L5.2 6.4v13.7c0 .6-.4 1-1 1s-1-.4-1-1V4c0-.1 0-.3.1-.4 0-.1.1-.2.2-.3s.2-.1.3-.2c.2-.1.3-.1.4-.1h16.2c.6 0 1 .4 1 1s-.4 1-1 1H6.6l24.5 24.8c.4.4.4 1 0 1.4-.1.2-.4.3-.6.3" />
  </svg>
);
export default IconArrowsExpandUpLeft;
