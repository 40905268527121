import React, { forwardRef, useImperativeHandle, useState, useEffect, useRef } from 'react';
import { BringzzButton, BringzzIcon, BringzzBottomDrawer, BringzzInput, BringzzOverlay, BringzzText } from '@bringzz/components';
import { useNavigation } from 'context/NavigationContext';
import { BrowserMultiFormatReader } from '@zxing/browser';
import { DecodeHintType, BarcodeFormat } from '@zxing/library';

// CameraStream Component (handles camera video stream, start, stop, and switching)
const CameraStream = forwardRef((props, ref) => {
    const videoRef = useRef(null);
    const { backPress } = useNavigation()
    const [availableCameras, setAvailableCameras] = useState([]);
    const [isUsingBackCamera, setIsUsingBackCamera] = useState(true);
    const [stream, setStream] = useState(null); // Store the stream in state
    const [isPermissionModalOpen, setIsPermissionModalOpen] = useState(false)
    const codeReader = useRef(new BrowserMultiFormatReader());  // zxing-js reader

    const togglePermissionModal = () => {
        setIsPermissionModalOpen(!isPermissionModalOpen)
    }

    useImperativeHandle(ref, () => ({
        stopCamera,
        switchCamera
    }));

    const handleBackPress = () => {
        console.log("Stopping the camera and navigating back...");

        // Stop all tracks in the video stream
        if (videoRef.current && videoRef.current.srcObject) {
            const tracks = videoRef.current.srcObject.getTracks();
            tracks.forEach(track => {
                console.log("Stopping track:", track);
                track.stop(); // Stop each track
            });

            // Clear the video element's source
            videoRef.current.srcObject = null;
            console.log("Video stream stopped and srcObject cleared.");
        } else {
            console.log("No video stream to stop.");
        }

        // Navigate back
        backPress(); // Assuming backPress is your navigation function
    };

    const requestCameraAccessAgain = async () => {
        try {
            // Try to access the camera again
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            console.log('Camera permission granted, stream started again.');

            if (videoRef.current) {
                videoRef.current.srcObject = stream; // Start showing the camera feed
            }
        } catch (err) {
            if (err.name === 'NotAllowedError') {
                // TODO: Identify fallbacks for different devices (iPhone, Android, etc..)
                console.log('Camera access still denied.');
                alert("TODO: Give instructions to enable if previously blocked.")
            } else {
                console.error('Error requesting camera access again:', err);
            }
        }
    };

    useEffect(() => {
        console.log("useEffect called, initializing camera stream.");

        const checkCameraPermission = async () => {
            try {
                // Directly request access to the camera
                console.log('Requesting camera access directly.');
                await navigator.mediaDevices.getUserMedia({ video: true });

                // If this succeeds, it means the user has granted permission
                console.log('Camera permission granted');
                // togglePermissionModal()
                // startCamera(); // Start the camera if permission is granted
            } catch (err) {
                // Handle the error if permission is denied or some other error occurs
                if (err.name === 'NotAllowedError') {
                    console.log('Camera permission denied');
                    togglePermissionModal()
                } else {
                    console.error('Error accessing the camera:', err);
                }
            }
        };

        const fetchDevicesAndStartCamera = async () => {
            console.log("Fetching available video input devices...");
            try {
                const devices = await navigator.mediaDevices.enumerateDevices();
                const videoDevices = devices.filter(device => device.kind === 'videoinput');
                console.log("Video devices found:", videoDevices);
                setAvailableCameras(videoDevices);
                if (videoDevices.length > 0) {
                    console.log("Starting camera with device ID:", videoDevices[0].deviceId);
                    startCamera(videoDevices[0].deviceId);
                } else {
                    alert('No camera found on this device.');
                }
            } catch (err) {
                console.error("Error enumerating devices:", err);
            }
        };

        checkCameraPermission()
        fetchDevicesAndStartCamera();

        // Cleanup on component unmount
        return () => {
            console.log("Cleanup called, stopping the camera stream...");

            stopCamera()
            stopReader()
            // Optionally clear the stream from state if you're storing it there
            setStream(null);  // Ensure the stream is cleared from state
        };
    }, []);

    const hints = new Map();
    const formats = [
        BarcodeFormat.QR_CODE,  // QR code
        BarcodeFormat.CODE_128, // Common barcodes
        BarcodeFormat.EAN_13,   // European Article Number
        BarcodeFormat.UPC_A,    // Universal Product Code
    ];
    hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);

    const stopReader = () => {
        if (codeReader.current.reset) {
            codeReader.current.reset();  // This will stop any decoding and release resources
        }
    };

    const startCamera = async (deviceId) => {
        try {
            console.log("Requesting camera access for device ID:", deviceId);
            const newStream = await navigator.mediaDevices.getUserMedia({
                video: { deviceId },
            });
            if (videoRef.current) {
                console.log("Camera stream started, setting srcObject on video element.");
                videoRef.current.srcObject = newStream;
                codeReader.current.decodeFromVideoDevice(deviceId, videoRef.current, (result, error) => {
                    if (result) {
                        props.setDetectedText(result)  // Handle QR/Barcode result
                    }
                    if (error) {
                        // console.log(error);
                        // Handle decoding error if necessary
                    }
                }, hints);
            } else {
                console.log("videoRef.current is not available when setting srcObject.");
            }
        } catch (err) {
            console.error('Error accessing the camera:', err);
        }
    };

    const stopCamera = () => {
        console.log("Stopping the camera stream...");

        // Stop all tracks in the video stream
        if (videoRef.current && videoRef.current.srcObject) {
            const tracks = videoRef.current.srcObject.getTracks();
            tracks.forEach(track => {
                console.log("Stopping track:", track);
                track.stop(); // Stop each track
            });
            videoRef.current.srcObject = null;  // Clear the video element's source
            console.log("Video stream stopped and srcObject cleared.");
        } else {
            console.log("No video stream to stop.");
        }
    };

    const switchCamera = () => {
        if (availableCameras.length > 1) {
            const newCamera = isUsingBackCamera ? availableCameras[1] : availableCameras[0];
            console.log("Switching camera to:", newCamera.deviceId);
            setIsUsingBackCamera(!isUsingBackCamera);
            startCamera(newCamera.deviceId);
        } else {
            console.log("Only one camera available, cannot switch.");
            alert('Only one camera exists on this device.');
        }
    };

    return (
        <>
            <video
                ref={videoRef}
                autoPlay
                className="w-full h-full object-cover"
            />
            <BringzzOverlay isOpen={isPermissionModalOpen} onClose={togglePermissionModal}>
                <div className=''>
                    <div className='flex flex-col text-center items-center space-y-2 px-12 py-4'>
                        <BringzzText tag="h2">Allow “bringzz” to use your camera?</BringzzText>
                        <BringzzText tag="h4" className="font-normal text-center">This must be approved to use the scanner</BringzzText>
                    </div>

                    <div className='divide-y'>
                        <div></div>
                        <BringzzText tag="h4" className="font-bold py-2 text-center text-magic-lilac cursor-pointer" onClick={requestCameraAccessAgain}>Yes, while using the app.</BringzzText>
                        <BringzzText tag="h4" className="font-bold py-2 text-center cursor-pointer text-red-400" onClick={handleBackPress}>No</BringzzText>
                    </div>
                </div>
            </BringzzOverlay>
        </>
    );
});

CameraStream.displayName = 'CameraStream';

export default CameraStream;
