import * as React from "react";
const IconBed = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.5 42.4v-.1l-8.6-18.6V7.3c0-2.9-2.4-5.3-5.3-5.3H19.4c-2.9 0-5.3 2.4-5.3 5.3v16.4L5.5 42.3c-.1.1-.1.3-.1.4V61c0 .6.4 1 1 1s1-.4 1-1v-6.1h49.2V61c0 .6.4 1 1 1s1-.4 1-1V42.7c0-.1-.1-.2-.1-.3M15.8 24.9h32.5L56 41.7H8zm.3-17.6c0-1.8 1.5-3.3 3.3-3.3h25.1c1.8 0 3.3 1.5 3.3 3.3v15.6H16.1zM7.4 52.9v-9.2h49.2v9.2z" />
  </svg>
);
export default IconBed;
