import * as React from "react";
const IconFastForward = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.4 28.9 39 13.2c-1.6-1.2-3.7-1.3-5.5-.4s-2.9 2.7-2.9 4.7V28L10.4 13.2c-1.6-1.2-3.7-1.4-5.5-.4-1.8.9-2.9 2.7-2.9 4.6v29.1c0 2 1.1 3.8 2.9 4.7q1.2.6 2.4.6c1.1 0 2.2-.3 3.1-1L30.6 36v10.5c0 2 1.1 3.8 2.9 4.7q1.2.6 2.4.6c1.1 0 2.2-.3 3.1-1l21.5-15.7c1-.7 1.6-1.9 1.6-3.1-.1-1.2-.7-2.4-1.7-3.1M9.2 49.2c-1 .7-2.3.8-3.4.3-1.1-.6-1.8-1.7-1.8-2.9V17.4c0-1.3.7-2.3 1.8-2.9s2.4-.5 3.4.3l21.4 15.6v3.1zm50-15.7L37.8 49.2c-1 .7-2.3.8-3.4.3-1.1-.6-1.8-1.7-1.8-2.9V17.5c0-1.3.7-2.3 1.8-2.9.5-.2 1-.4 1.5-.4.7 0 1.3.2 1.9.6l21.5 15.7c.5.4.8.9.8 1.5s-.4 1.1-.9 1.5" />
  </svg>
);
export default IconFastForward;
