import * as React from "react";
const IconGoldBar = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.7 43.3-3.2-8.1c-.6-1.5-2-2.4-3.6-2.4H49v-.1L43.4 18c-.6-1.5-2-2.5-3.6-2.5H24.1c-1.6 0-3 1-3.6 2.5L15 32.6v.1H9.1c-1.6 0-3 1-3.6 2.4l-3.2 8.1c-.5 1.2-.3 2.5.4 3.6s1.9 1.7 3.2 1.7h24c.3 0 .6-.2.8-.4s.2-.6.1-.9L25.6 34h12.9l-5.2 13.2c-.1.3-.1.7.1.9.2.3.5.4.8.4h24c1.3 0 2.5-.6 3.2-1.7.6-1 .8-2.3.3-3.5M22.4 18.6c.3-.7 1-1.2 1.7-1.2h15.7c.8 0 1.5.5 1.7 1.2l5 13.4H17.4zm-16.5 28c-.6 0-1.2-.3-1.5-.8q-.6-.9-.3-1.8l3.2-8.1c.3-.7 1-1.2 1.7-1.2h14.6l4.7 11.8H5.9zm53.8-.9c-.4.5-.9.8-1.5.8H35.6l4.7-11.8h14.6c.8 0 1.4.5 1.7 1.2l3.2 8.1c.3.6.2 1.2-.1 1.7" />
  </svg>
);
export default IconGoldBar;
