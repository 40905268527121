import * as React from "react";
const IconAxe = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m60.1 22.5-4.8-4.3c-.1-.2-.3-.4-.5-.5l-9.5-8.5-.9-4V5c0-.1-.1-.2-.2-.3l-.9-.9c-2.2-2.2-5.8-2.2-8 0L17.8 21.3c-1.1 1.1-1.6 2.5-1.6 4s.6 2.9 1.6 4l.9.9c.1.1.2.1.3.2h.2l4 .9.5.6L3.8 50.5C2.7 51.6 2 53 2 54.5s.6 3 1.6 4.1l1.6 1.6c1.1 1.1 2.5 1.6 4 1.6h.1c1.5 0 3-.7 4-1.8l18.2-19.5.2.2c.1.3.3.4.5.6l4.3 4.8c1.1 1.2 2.6 1.9 4.2 1.9.5 0 1.1-.1 1.6-.3 4.4-1.4 8.5-4 12-7.4 3.5-3.5 6-7.6 7.4-12 .7-2 .1-4.3-1.6-5.8m-40.2 6.1-.7-.7c-.7-.7-1.1-1.6-1.1-2.6s.4-1.9 1.1-2.6L36.7 5.2c1.4-1.4 3.7-1.4 5.1 0l.7.7.9 4c0 .2.2.4.3.5l9.5 8.6c-1.1 4.7-3.7 9.1-7.4 12.8s-8.2 6.3-12.8 7.4l-8.6-9.5c-.1-.2-.3-.3-.5-.3zm-8 30.1c-.7.7-1.6 1.1-2.6 1.2-1 0-1.9-.4-2.6-1.1l-1.6-1.6c-.7-.7-1.1-1.6-1.1-2.6s.4-1.9 1.2-2.6l19.9-18.6 5.1 5.7zm47.9-31c-1.3 4.1-3.7 7.9-7 11.2-3.2 3.2-7.1 5.6-11.2 7-1.3.4-2.7 0-3.7-1.1L34.6 41c4.7-1.3 9-4 12.7-7.7s6.3-8.1 7.7-12.7l3.8 3.4c1 1 1.5 2.4 1 3.7" />
  </svg>
);
export default IconAxe;
