import * as React from "react";
const IconDuck = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M29.5 58.4c-10.7 0-18.1-2.7-22.1-7.9-4.6-6-3.7-13.9-2.2-19.5.4-1.3 1.4-2.4 2.7-2.7s2.6 0 3.5.9c1.7 1.7 4.9 3.7 10.7 3.7 3.4 0 5.9-.3 7.3-.9.6-.3.8-.7.9-1 .1-.5-.1-1.1-.5-1.4-3.3-3.1-4.9-7.5-4.3-11.9.9-6.3 6-11.2 12.3-11.8 6.7-.7 12.9 3.6 14.7 10 1.8.2 3.4.2 5 0 .6 0 1.3.2 1.7.6.5.4.7 1 .8 1.6.1 1.9-.6 6.2-8.3 7.3-.6 1.2-1.4 2.4-2.3 3.5-.5.5-.6 1.1-.4 1.6 2.2 4.8 5.3 13.7 1 20.3-3.3 5-10.1 7.6-20.5 7.6M8.9 30.1c-.1 0-.3 0-.4.1-.6.2-1.1.6-1.3 1.3C5.7 36.6 4.9 43.9 9 49.2c3.6 4.7 10.5 7.1 20.5 7.1 9.6 0 15.9-2.3 18.8-6.8 3.7-5.8.9-14-1.1-18.4-.6-1.2-.3-2.8.7-3.9.9-1 1.7-2.2 2.2-3.4.1-.3.4-.6.8-.6 4.7-.6 7.3-2.4 7.1-5.3 0-.1-.1-.2-.1-.2s-.1-.1-.2-.1c-1.9.2-3.9.1-6.1-.1-.4-.1-.8-.4-.9-.8C49.4 11 44 7.1 38 7.7c-5.3.5-9.8 4.8-10.5 10.1-.5 3.8.8 7.6 3.7 10.2.9.9 1.3 2.1 1.1 3.3-.2 1.1-1 2-2.1 2.5-1.7.7-4.4 1.1-8.1 1.1-6.5 0-10.1-2.3-12.1-4.3-.3-.3-.7-.5-1.1-.5" />
  </svg>
);
export default IconDuck;
