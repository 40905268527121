import * as React from "react";
const IconWallDrawer = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 2.2H7c-2.8 0-5 2.2-5 5v49.6c0 2.8 2.2 5 5 5h50c2.8 0 5-2.2 5-5V7.2c0-2.8-2.2-5-5-5m3 5V31H33V4.2h24c1.7 0 3 1.3 3 3m-53-3h24V31H4V7.2c0-1.7 1.3-3 3-3M4 56.8V33h27v26.8H7c-1.7 0-3-1.3-3-3m53 3H33V33h27v23.8c0 1.7-1.3 3-3 3" />
    <path d="M42.2 21.6c.6 0 1-.4 1-1v-3.2c0-.6-.4-1-1-1s-1 .4-1 1v3.2c0 .6.4 1 1 1M21.8 21.6c.6 0 1-.4 1-1v-3.2c0-.6-.4-1-1-1s-1 .4-1 1v3.2c0 .6.5 1 1 1M42.2 42.3c-.6 0-1 .4-1 1v3.2c0 .6.4 1 1 1s1-.4 1-1v-3.2c0-.6-.5-1-1-1M21.8 42.3c-.6 0-1 .4-1 1v3.2c0 .6.4 1 1 1s1-.4 1-1v-3.2c0-.6-.4-1-1-1" />
  </svg>
);
export default IconWallDrawer;
