import * as React from "react";
const IconMushroomAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.5 22.7c-6.1-4.6-11.3-10.3-15.2-16.9l-.3-.4C36.7 3.3 34.4 2 32 2s-4.7 1.3-6 3.4l-.3.5c-4 6.6-9.1 12.3-15.2 16.9-1.1.8-1.8 2.2-1.8 3.5 0 1.8 1.1 3.4 2.8 4.1 3.1 1.2 8.8 3.2 15.3 3.9l-2.3 22c-.2 1.5.3 2.9 1.3 4s2.4 1.7 3.8 1.7h5c1.5 0 2.8-.6 3.8-1.7s1.5-2.5 1.3-4l-2.4-22c6.4-.8 12-2.6 15.1-3.9 1.7-.7 2.8-2.3 2.8-4.1 0-1.4-.6-2.7-1.7-3.6M37.7 56.5c.1.9-.2 1.8-.8 2.4-.6.7-1.4 1-2.3 1h-5c-.9 0-1.7-.4-2.3-1s-.9-1.5-.8-2.4l2.3-22.1c1.1.1 2.1.1 3.2.1s2.2 0 3.3-.1zm14-28C47.9 30 40.2 32.6 32 32.6S16.1 30 12.3 28.5c-.9-.4-1.5-1.3-1.5-2.3 0-.8.4-1.5 1-1.9 6.4-4.8 11.6-10.6 15.7-17.4l.3-.5C28.6 4.9 30.3 4 32 4s3.4.9 4.3 2.4l.3.5c4.1 6.8 9.4 12.7 15.7 17.4.6.5 1 1.2 1 1.9-.1 1.1-.7 1.9-1.6 2.3" />
  </svg>
);
export default IconMushroomAlt1;
