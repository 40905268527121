import * as React from "react";
const IconBowlAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.1 28c-.7-.7-1.6-1.1-2.6-1.1h-53c-1 0-1.9.4-2.6 1.1-.6.7-1 1.7-.9 2.6.9 9.4 6.3 17.8 14.5 22.5v1c0 2.6 2.1 4.7 4.7 4.7h21.6c2.6 0 4.7-2.1 4.7-4.7v-1c2.2-1.3 4.3-2.8 6.1-4.7 4.8-4.8 7.8-11.1 8.4-17.8.1-1-.2-1.9-.9-2.6m-8.9 19c-1.8 1.8-3.9 3.4-6.2 4.6-.3.2-.5.5-.5.9V54c0 1.5-1.2 2.7-2.7 2.7H21.2c-1.5 0-2.7-1.2-2.7-2.7v-1.5c0-.4-.2-.7-.5-.9-5.2-2.9-9.3-7.3-11.7-12.5h51.3c-1.3 2.9-3.1 5.6-5.4 7.9m6.3-9.9h-53C4.7 35 4.2 32.7 4 30.4c0-.4.1-.8.4-1.1s.7-.5 1.1-.5h53.1c.4 0 .8.2 1.1.5s.4.7.4 1.1c-.3 2.3-.8 4.5-1.6 6.7M17.2 19.2c.6 0 1-.4 1-1V6.3c0-.6-.4-1-1-1s-1 .4-1 1v11.9c0 .5.4 1 1 1M46.8 19.2c.6 0 1-.4 1-1V6.3c0-.6-.4-1-1-1s-1 .4-1 1v11.9c0 .5.5 1 1 1M32 19.2c.6 0 1-.4 1-1V6.3c0-.6-.4-1-1-1s-1 .4-1 1v11.9c0 .5.4 1 1 1" />
  </svg>
);
export default IconBowlAlt1;
