import { getDaysInMonth, monthsListShort } from "../utilities";
import BringzzIcon from "../../BringzzIcon";
import React from "react";
import BringzzText from "../../BringzzText";
const DayMonthPicker = ({
  closePicker,
  description,
  day,
  setDay,
  month,
  setMonth,
  selectedDate,
  onChange,
}) => {
  const daysInMonth = getDaysInMonth(month, new Date().getFullYear());
  const handleDayMonthSelect = (selectedDay, selectedMonth) => {
    setDay(selectedDay);
    setMonth(selectedMonth);
    const newDate = new Date(
      new Date().getFullYear(),
      selectedMonth,
      selectedDay,
      12
    ); // Fixed time to avoid timezone issues
    onChange(newDate);
  };
  return (
    <div>
      <div className="mb-4 px-2 flex justify-between">
      <BringzzText tag="h4"><span className="mr-1">&bull;</span>{description || "Select a date"}</BringzzText>

        <div>
          {" "}
          <BringzzIcon
            icon="IconClose"
            folder="LineIcons"
            size="18"
            className="hover:text-magic-lilac cursor-pointer"
            onClick={() => {
              closePicker();
            }}
          ></BringzzIcon>
        </div>
      </div>
      <div className="grid grid-cols-4 gap-2 mb-4">
        {monthsListShort.map((monthName, index) => (
          <button
            key={monthName}
            className={`p-2 rounded ${
              month === index ? "bg-magic-lilac text-white" : ""
            }`}
            onClick={() => handleDayMonthSelect(day, index)}
          >
            {monthName}
          </button>
        ))}
      </div>
      <div className="grid grid-cols-7 gap-0">
        {Array.from({ length: daysInMonth }, (_, i) => i + 1).map((dayNum) => (
          <button
            key={dayNum}
            className={`w-9 h-9 p-1 flex justify-center items-center rounded-full ${
              day === dayNum && month === selectedDate.getMonth()
                ? "bg-magic-lilac text-white"
                : ""
            }`}
            onClick={() => handleDayMonthSelect(dayNum, month)}
          >
            {dayNum}
          </button>
        ))}
      </div>
    </div>
  );
};
export default DayMonthPicker;
