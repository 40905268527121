import * as React from "react";
const IconMapAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M39.1 22.1c0-4.3-3.5-7.7-7.7-7.7-4.3 0-7.7 3.5-7.7 7.7 0 4.3 3.5 7.7 7.7 7.7 4.3 0 7.7-3.4 7.7-7.7m-13.4 0c0-3.2 2.6-5.7 5.7-5.7 3.2 0 5.7 2.6 5.7 5.7s-2.6 5.7-5.7 5.7c-3.2 0-5.7-2.5-5.7-5.7" />
    <path d="m61 59.2-4.1-18.5c-.1-.4-.4-.7-.8-.8l-14-2C46.2 32.4 50 26 50 21.5c0-9.9-8.3-17.9-18.6-17.9s-18.6 8.1-18.6 17.9c0 4.5 3.8 10.8 7.9 16.4l-14 2c-.4.1-.8.4-.8.8L3 59.3c0 .3.1.7.4.9s.6.3 1 .2L18 55.7l13.7 4.7q.3.15.6 0L46 55.7l13.7 4.6c.1 0 .2.1.3.1.2 0 .5-.1.7-.3s.4-.5.3-.9M31.4 5.6c9.1 0 16.6 7.2 16.6 15.9 0 7.4-12.1 21-15.8 25-.5.5-1.2.5-1.7 0-3.7-4-15.8-17.7-15.8-25 .1-8.8 7.6-15.9 16.7-15.9m14.9 48.1q-.3-.15-.6 0L32 58.4l-13.7-4.7q-.3-.15-.6 0L5.2 57.9l2.5-16.1 14.4-2.1c2.6 3.3 5.2 6.3 7 8.2.6.7 1.4 1 2.3 1s1.7-.4 2.3-1c1.8-1.9 4.4-4.8 7-8.2l14.4 2.1 3.5 16z" />
  </svg>
);
export default IconMapAlt;
