import * as React from "react";
const IconDollarEuroExchange = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M11 20.5H3.7c-.6 0-1 .4-1 1s.4 1 1 1h5.7v2.3c0 .6.4 1 1 1s1-.4 1-1v-2.4c2.5-.2 4.5-2.3 4.5-4.9 0-2.7-2.2-4.9-4.9-4.9H7.6c-1.6 0-2.9-1.3-2.9-2.9S6 6.8 7.6 6.8h7.3c.6 0 1-.4 1-1s-.4-1-1-1h-3.5V2.5c0-.6-.4-1-1-1s-1 .4-1 1v2.3H7.6C4.9 4.8 2.7 7 2.7 9.7s2.2 4.9 4.9 4.9H11c1.6 0 2.9 1.3 2.9 2.9s-1.3 3-2.9 3M61 60.5c-3.4 0-6.5-1.8-8.3-4.5h6c.6 0 1-.4 1-1s-.4-1-1-1h-7.1c-.5-1.1-.7-2.4-.7-3.6 0-.9.1-1.8.4-2.6h7.4c.6 0 1-.4 1-1s-.4-1-1-1h-6.6c1.7-3.2 5.1-5.5 8.9-5.5.6 0 1-.4 1-1s-.4-1-1-1c-4.9 0-9.3 3.1-11.2 7.5h-3.7c-.6 0-1 .4-1 1s.4 1 1 1h3c-.2.8-.3 1.7-.3 2.6q0 1.95.6 3.6h-3.3c-.6 0-1 .4-1 1s.4 1 1 1h4.2c2.1 3.8 6.1 6.5 10.7 6.5.6 0 1-.4 1-1s-.4-1-1-1M28.7 8.9h22.1c2 0 3.7 1.7 3.7 3.7V28l-3.8-3.8c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l5.5 5.5c.2.2.4.3.7.3s.5-.1.7-.3l5.4-5.4c.4-.4.4-1 0-1.4s-1-.4-1.4 0L56.5 28V12.6c0-3.1-2.6-5.7-5.7-5.7H28.7c-.6 0-1 .4-1 1s.5 1 1 1M35.3 58.4H13.2c-2 0-3.7-1.7-3.7-3.7V39.3l3.7 3.7c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-5.5-5.5c-.4-.4-1-.4-1.4 0l-5.4 5.4c-.4.4-.4 1 0 1.4s1 .4 1.4 0l3.7-3.7v15.4c0 3.1 2.6 5.7 5.7 5.7h22.1c.6 0 1-.4 1-1s-.4-.9-.9-.9" />
  </svg>
);
export default IconDollarEuroExchange;
