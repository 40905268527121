import * as React from "react";
const IconDropperAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m56.7 21.9 2.3-2.3c4-4 4-10.6 0-14.6S48.4 1 44.4 5l-2.3 2.3-4.2-4.2c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l4.2 4.2-25.8 25.8c-.1.1-.2.3-.3.5l-2.9 15.8-9.4 9.4c-.4.4-.4 1 0 1.4.2.3.4.4.7.4s.5-.1.7-.3l9.4-9.4 15.8-2.9c.2 0 .4-.1.5-.3l25.8-25.8 4.2 4.2c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4zM45.8 6.4c3.2-3.2 8.5-3.2 11.8 0 3.2 3.2 3.2 8.5 0 11.8l-2.3 2.3L43.5 8.8zM28.3 47.5l-14.4 2.6 2.6-14.4L42 10.2 53.8 22z" />
  </svg>
);
export default IconDropperAlt1;
