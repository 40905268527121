import * as React from "react";
const IconArrowDown = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.6 33.8c-.9-.9-2.3-.9-3.2 0L34.3 50.6V8c0-1.2-1-2.2-2.2-2.2s-2.2 1-2.2 2.2v42.7l-17.3-17c-.9-.9-2.3-.9-3.2 0s-.9 2.3 0 3.2l21 20.7c.4.4 1 .6 1.6.6s1.1-.2 1.6-.6l21-20.7c.9-.8.9-2.3 0-3.1" />
  </svg>
);
export default IconArrowDown;
