import * as React from "react";
const IconDiadem = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M38.4 18.6c-.5-.1-1 .4-1.1.9s.3 1 .9 1.1C50.8 21.8 60 26.5 60 31.9c0 5.8-11.1 10.8-24.8 11.5 2-1.1 3.4-3.3 3.4-5.7 0-3.3-5.2-10.1-5.7-10.9-.2-.2-.5-.4-.8-.4s-.6.1-.8.4c-.6.8-5.7 7.6-5.7 10.9 0 2.5 1.4 4.6 3.4 5.7-13.9-.8-25-5.7-25-11.5 0-5.3 9.2-10.1 21.8-11.3.5-.1 1-.5.9-1.1-.1-.5-.5-1-1.1-.9C11.7 19.9 2 25.4 2 31.9c0 7.6 13.2 13.5 30 13.5s30-6 30-13.5c0-6.5-9.7-12-23.6-13.3m-10.9 19c0-1.7 2.5-5.8 4.5-8.6 2 2.8 4.5 6.9 4.5 8.6 0 2.5-2 4.5-4.5 4.5s-4.5-2-4.5-4.5" />
  </svg>
);
export default IconDiadem;
