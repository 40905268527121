import * as React from "react";
const IconForrest = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.9 20.3c3 0 5.5-2.5 5.5-5.5S57 9.3 53.9 9.3c-3 0-5.5 2.5-5.5 5.5s2.5 5.5 5.5 5.5m0-9c1.9 0 3.5 1.6 3.5 3.5s-1.6 3.5-3.5 3.5-3.5-1.6-3.5-3.5 1.6-3.5 3.5-3.5M61 52.7h-3.9l-12-28.6c-.8-1.8-2.4-2.9-4.4-2.9-1.9 0-3.6 1.1-4.4 2.9L30 39.8l-5.3-12.6c-.8-1.8-2.4-2.9-4.4-2.9-1.9 0-3.6 1.1-4.4 2.9L5.6 52.7H3c-.6 0-1 .4-1 1s.4 1 1 1h58c.6 0 1-.4 1-1s-.4-1-1-1M38.3 24.9c.5-1.2 1.6-1.7 2.5-1.7s2 .4 2.5 1.7L55 52.7H42V49c1.9-.6 3.3-2.9 3.3-5.8 0-3.3-1.9-5.9-4.4-5.9s-4.4 2.6-4.4 5.9c0 2.9 1.4 5.2 3.4 5.8v3.6h-4.5l-4.3-10.3zm2.6 22.3c-1.3 0-2.4-1.8-2.4-3.9s1.1-3.9 2.4-3.9 2.4 1.8 2.4 3.9-1.1 3.9-2.4 3.9M17.9 28c.5-1.2 1.6-1.7 2.5-1.7s2 .4 2.5 1.7l10.3 24.7H7.8z" />
  </svg>
);
export default IconForrest;
