import * as React from "react";
const IconVideoCall2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 65 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.5 25.7c-.3-.2-.7-.2-1 0l-8.2 4.7v-2.1c0-2.8-2.2-5-5-5H19c-2.8 0-5 2.2-5 5v7.5c0 2.8 2.2 5 5 5h17.3c2.8 0 5-2.2 5-5v-2.2l8.2 4.7c.2.1.3.1.5.1s.3 0 .5-.1c.3-.2.5-.5.5-.9V26.6c0-.4-.2-.7-.5-.9M39.3 35.8c0 1.7-1.3 3-3 3H19c-1.7 0-3-1.3-3-3v-7.5c0-1.7 1.3-3 3-3h17.3c1.7 0 3 1.3 3 3zm9.7-.2L42.5 32l6.5-3.7z" />
    <path d="M32.5 3c-15.4 0-28 12.6-28 28 0 7.6 3.1 14.9 8.5 20.1l-2.4 8.6c-.1.4 0 .8.3 1 .2.1.4.2.6.2s.3 0 .5-.1l8.5-4.5c3.7 1.8 7.7 2.6 11.9 2.6 15.4 0 28-12.6 28-28S47.9 3 32.5 3m0 54c-4 0-7.9-.9-11.5-2.7q-.45-.15-.9 0L13.2 58l1.9-6.9c.1-.4 0-.7-.3-1C9.5 45.2 6.5 38.2 6.5 31c0-14.3 11.7-26 26-26s26 11.7 26 26-11.7 26-26 26" />
  </svg>
);
export default IconVideoCall2;
