import * as React from "react";
const IconHospital = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      d="M20 20h-4v3h4zM16 25h4v3h-4zM20 30h-4v3h4zM22 20h4v3h-4zM26 25h-4v3h4zM22 30h4v3h-4zM32 20h-4v3h4zM28 25h4v3h-4zM32 30h-4v3h4zM25 15v-3h3v-2h-3V7h-2v3h-3v2h3v3z"
    />
    <path
      
      fillRule="evenodd"
      d="M17 6a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2h8v2h-2v34h2a1 1 0 1 1 0 2H9a1 1 0 1 1 0-2h2V8h-1V6zm0 5h-4v31h4v-4h-1v-2h16v2h-1v4h4V11h-4v5a2 2 0 0 1-2 2H19a2 2 0 0 1-2-2zm0-2h-4V8h4zm2-3h10v10H19zm4 36h-4v-4h4zm6 0v-4h-4v4zm6-33V8h-4v1z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconHospital;
