import * as React from "react";
const IconLaptopStar = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 42.5h-4.2V15.3c0-3.7-3-6.6-6.6-6.6H13.8c-3.7 0-6.6 3-6.6 6.6v27.2H3c-.6 0-1 .4-1 1V48c0 4.1 3.3 7.4 7.4 7.4h45.2c4.1 0 7.4-3.3 7.4-7.4v-4.4c0-.6-.4-1.1-1-1.1M9.2 15.3c0-2.6 2.1-4.6 4.6-4.6h36.4c2.6 0 4.6 2.1 4.6 4.6v27.2H9.2zM60 48c0 3-2.4 5.4-5.4 5.4H9.4C6.4 53.4 4 51 4 48v-3.4h56z" />
    <path d="M41.2 23.6 36 21.9l-3.3-4.4c-.4-.5-1.2-.5-1.6 0l-3.3 4.4-5.2 1.7q-.45.15-.6.6c-.15.45 0 .6.1.9l3.2 4.4.1 5.5c0 .3.2.6.4.8.2.1.4.2.6.2h.3l5.2-1.6 5.2 1.6c.3.1.6 0 .9-.1.3-.2.4-.5.4-.8l.1-5.5 3.2-4.4c.2-.3.2-.6.1-.9s-.3-.6-.6-.7m-4.4 5.1c-.1.2-.2.4-.2.6v4.4l-4.2-1.3q-.3-.15-.6 0l-4.2 1.3v-4.4c0-.2-.1-.4-.2-.6l-2.6-3.6 4.2-1.4c.2-.1.4-.2.5-.4l2.6-3.6 2.6 3.6c.1.2.3.3.5.4l4.2 1.4z" />
  </svg>
);
export default IconLaptopStar;
