import * as React from "react";
const IconTicketAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m50.7 7.1-4.2-4.2c-1.1-1.1-3.1-1.1-4.2 0l-2 2.1-2.1-2.1c-1.1-1.1-3.1-1.1-4.2 0L32 5l-2.1-2.1c-1.1-1.1-3.1-1.1-4.2 0l-2 2.1-2.1-2.1c-1.1-1.1-3.1-1.1-4.2 0l-4.2 4.2q-.9.9-.9 2.1v45.7q0 1.2.9 2.1l4.1 4.2c1.1 1.1 3.1 1.1 4.2 0l2.1-2.1 2.1 2.1c1.1 1.1 3.1 1.1 4.2 0L32 59l2.1 2.1c1.1 1.1 3.1 1.1 4.2 0l2.1-2.1 2.1 2.1q.9.9 2.1.9c1.2 0 1.5-.3 2.1-.9l4.2-4.2q.9-.9.9-2.1V9.2c-.2-.8-.6-1.6-1.1-2.1m-1.1 47.7c0 .3-.1.5-.3.7l-4.2 4.2c-.4.4-1 .4-1.3 0L41 56.9c-.2-.2-.4-.3-.7-.3s-.5.1-.7.3l-2.8 2.8c-.4.4-1 .4-1.3 0l-2.8-2.8c-.4-.4-1-.4-1.4 0l-2.8 2.8c-.4.4-1 .4-1.3 0l-2.8-2.8c-.4-.4-1-.4-1.4 0l-2.8 2.8c-.4.4-1 .4-1.3 0l-4.1-4.2c-.2-.2-.3-.4-.3-.7V9.2c0-.3.1-.5.3-.7L19 4.3c.4-.4 1-.4 1.3 0L23 7.1c.4.4 1 .4 1.4 0l2.8-2.8c.4-.4 1-.4 1.3 0l2.8 2.8c.2.2.4.3.7.3s.5-.1.7-.3l2.8-2.8c.4-.4 1-.4 1.3 0l2.8 2.8c.4.4 1 .4 1.4 0l2.8-2.8c.4-.4 1-.4 1.3 0l4.2 4.2c.2.2.3.4.3.7z" />
    <path d="M40.2 39.4H23.8c-.6 0-1 .4-1 1s.4 1 1 1h16.3c.6 0 1-.4 1-1s-.4-1-.9-1M36.9 47h-9.8c-.6 0-1 .4-1 1s.4 1 1 1h9.8c.6 0 1-.4 1-1s-.5-1-1-1M30.5 23.7h3c1.1 0 1.9 1 1.9 2.2s-.9 2.2-1.9 2.2h-4.7c-.6 0-1 .4-1 1s.4 1 1 1H31v.6c0 .6.4 1 1 1s1-.4 1-1v-.6h.5c2.2 0 3.9-1.9 3.9-4.2s-1.8-4.2-3.9-4.2h-3c-1.1 0-1.9-1-1.9-2.2s.9-2.2 1.9-2.2h4.7c.6 0 1-.4 1-1s-.4-1-1-1H33v-.6c0-.6-.4-1-1-1s-1 .4-1 1v.6h-.5c-2.2 0-3.9 1.9-3.9 4.2s1.8 4.2 3.9 4.2" />
  </svg>
);
export default IconTicketAlt2;
