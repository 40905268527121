import * as React from "react";
const IconApartmentsAlt5 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M52.7 29.5H28.8V15.9c0-1.1-.3-2.1-.9-2.9l-6.2-8.8c-1-1.4-2.5-2.2-4.2-2.2s-3.2.8-4.2 2.2L7.1 13c-.6.9-.9 1.9-.9 2.9v41c0 2.8 2.3 5.1 5.1 5.1h42.3c2.3 0 4.2-1.9 4.2-4.2V34.6c0-2.8-2.3-5.1-5.1-5.1M18 34.6V60h-6.7c-1.7 0-3.1-1.4-3.1-3.1v-41c0-.6.2-1.3.6-1.8L15 5.3c.5-.8 1.5-1.3 2.5-1.3s2 .5 2.6 1.3l6.2 8.8c.4.5.6 1.2.6 1.8v13.6h-3.7c-2.9 0-5.2 2.3-5.2 5.1M33.9 60V49.8c0-1.1.9-2 2-2h4.4c1.1 0 2 .9 2 2V60zm21.9-2.2c0 1.2-1 2.2-2.2 2.2h-9.3V49.8c0-2.2-1.8-4-4-4h-4.4c-2.2 0-4 1.8-4 4V60H20V34.6c0-1.7 1.4-3.1 3.1-3.1h29.5c1.7 0 3.1 1.4 3.1 3.1v23.2z" />
  </svg>
);
export default IconApartmentsAlt5;
