import React, { useState } from 'react';
import { BringzzButton, BringzzText } from "@bringzz/components";
import { useNavigation } from 'context/NavigationContext';
import { BringzzPageContainer, BringzzInput, BringzzIcon } from "@bringzz/components";
import { useLocation } from 'react-router-dom';
import useRequest from 'hooks/useRequest';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}
const methodTitle = {
	phone: 'Phone number',
	email: 'Email address'
}

const RegisterPage = () => {
	const { backPress } = useNavigation();
	const [inputDisabled, setInputDisabled] = useState(true);
	const { sendRequest, data, error, loading } = useRequest();

	const { navigate } = useNavigation();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	let query = useQuery();
	let phoneNumber = query.get('number');
	let method = query.get('method');
	let methodValue = query.get('value');
	let name = query.get('name');
	const [show, setShow] = useState(false);
	const [inputValue, setInputValue] = useState('');
	const [isValidInput, setIsValidInput] = useState(false)

	const preUserCreation = async () => {
		const result = await sendRequest({
			method: 'POST',
			url: `/module/user/preUserCreation`,
			data: {
				login: methodValue,
				password: inputValue,
				logintype: 0,
				language: "en"
			}
		})
			.then(response => {
				return response
			})
			.catch(error => {
				console.error(error);
				return error;
			});

		console.log(result);
		if (result.status == "success") {
			const userId = result.data.preuser
			navigate(
				`/register/otp?method=${method}&userId=${userId}&value=${methodValue}&redirect=/register/consumer/profile`, false
			);
		} else {
			if (result.message.includes('(0x5)')) {
				alert("Already exists. Singup process still ongoing.")
			} else if (result.message.includes('(0x4)')) {
				alert("User already exists.")
			} else {
				alert(result.message)
			}
		}
	}

	return (
		<BringzzPageContainer
			className='bg-white'
			header={{
				left: (
					<BringzzIcon
						size='22'
						icon='IconChevronLeft'
						folder='LineIcons'
						className='cursor-pointer'
						onClick={backPress}
					/>
				),
				title: "Join Us"
			}}
			authenticated={false}
			footer={
				<div className='pb-8 px-6'>
					<BringzzText tag="h5" className="py-2 font-normal">By creating an account, I certify that I am 14 years of age or older, and agree to the <span className='font-bold'>Privacy Policy</span> and <span className='font-bold'>Terms of Use</span>.</BringzzText>
					<BringzzButton
						size='lg'
						className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
						onClick={() => {
							preUserCreation()
						}}
						disabled={!isValidInput || loading}
					>
						Create Account
					</BringzzButton>
				</div>
			}
		>
			<div className='relative flex justify-center bg-magic-lilac/20'>
				<div className='relative bg-white w-full bg-cover bg-center flex flex-col md:max-w-2xl '>
					{/* Content */}
					<div className='flex-grow flex flex-col justify-between py-12 px-4'>
						{/* Bottom part */}
						<div className='z-10 flex flex-col '>
							<div className='flex flex-col space-y-6 w-full'>
								<BringzzText tag='h1' className='pl-2 font-bold text-midnight-blue'>
									Welcome
								</BringzzText>
								<BringzzText tag='h3' className='font-normal text-midnight-blue'>
									To create an account, please think of a safe password and fill it in.
								</BringzzText>
							</div>
							<div className='mt-8'>
								<BringzzInput
									inputProps={{
										disabled: inputDisabled
									}}
									icon={{
										right: (
											<BringzzText
												className='font-thin text-black underline cursor-pointer'
												onClick={backPress}
											>
												edit
											</BringzzText>
										)
									}}
									type='text'
									className="!bg-white"
									defaultValue={(method == "phone" ? "+" : "") + methodValue}
									label={methodTitle[method]}
								/>

							</div>
							<div className='mt-6'>
								<BringzzInput
									type={show ? 'text' : 'password'}
									hint='At least 9 letters, containing a character and a number'
									label={'Password'}
									pattern='^(?=.*[A-Za-z])(?=.*\d)(?=.*[!?@#$%^&*()-_+=])[A-Za-z\d!?@#$%^&*()-_+=]{9,}$'
									icon={{
										right: (
											<BringzzIcon
												icon={show ? 'IconEye' : 'IconEyeSlash'}
												folder='LineIcons'
												className='cursor-pointer'
												size='16'
												onClick={() => setShow(!show)}
											/>
										)
									}}
									onChange={(e, isValid, newValue) => {
										setIsValidInput(isValid);
										setInputValue(newValue);
									}}
								></BringzzInput>
							</div>
						</div>
					</div>
				</div>
			</div>
		</BringzzPageContainer>
	);
};

export default RegisterPage;