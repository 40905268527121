import * as React from "react";
const IconBook = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path  d="M30 19h-5v-5h-2v5h-5v2h5v5h2v-5h5z" />
    <path
      
      fillRule="evenodd"
      d="M9 10a5 5 0 0 1 5-5h24a1 1 0 0 1 1 1v30a1 1 0 0 1-1 1H14a3 3 0 0 0-3 3v1h27v2H10a1 1 0 0 1-1-1zm5 25a4.98 4.98 0 0 0-3 1V10a3 3 0 0 1 3-3h23v28z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconBook;
