import * as React from "react";
const IconCloudSearch1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.2 24.3c-2.7-2.9-6.5-4.8-10.6-5.3-2.1-3.5-5.3-6.1-9.1-7.3-1.6-.6-3.5-.9-5.5-.9-9.4 0-17.1 7.4-17.1 16.6C7.7 27.6 2 33.3 2 40.3c0 7.1 6 12.9 13.3 13h28.8C54 53.3 62 45.5 62 36c0-4.3-1.7-8.5-4.8-11.7m-13.1 27H15.4C9 51.3 4 46.4 4 40.3s5-11 11.4-11h.5c.6 0 1-.4 1-1v-1c0-8 6.8-14.6 15.1-14.6 1.8 0 3.4.3 4.9.8 3.5 1.2 6.5 3.6 8.3 6.9.2.3.4.5.8.5 3.7.4 7.3 2.1 9.8 4.8C58.5 28.5 60 32.2 60 36c0 8.4-7.1 15.3-15.9 15.3" />
    <path d="M38.1 38.2c1.3-1.5 2.1-3.3 2.1-5.3 0-2.2-.9-4.2-2.5-5.8-3.3-3.2-8.7-3.2-12.1 0-1.6 1.5-2.5 3.6-2.5 5.8s.9 4.2 2.5 5.8c1.7 1.6 3.9 2.4 6 2.4 1.7 0 3.5-.5 5-1.5l5.8 5.5c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4zm-11.1-1c-1.2-1.2-1.9-2.7-1.9-4.3s.7-3.2 1.9-4.3c1.3-1.2 3-1.8 4.7-1.8s3.4.6 4.7 1.8c1.2 1.2 1.9 2.7 1.9 4.3s-.7 3.2-1.9 4.3c-2.7 2.5-6.9 2.5-9.4 0" />
  </svg>
);
export default IconCloudSearch1;
