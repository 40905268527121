import * as React from "react";
const IconNetworking = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M19.6 9.5c-1.1 0-2 .9-2 2v.1c0 1.1.9 2 2 2s2-.9 2-2v-.1c0-1.1-.9-2-2-2M46.2 10.6h-5.1c-.6 0-1 .4-1 1s.4 1 1 1h5.1c.6 0 1-.4 1-1s-.4-1-1-1" />
    <path d="M58 47.6h-1.9v-9.5c0-3-2.5-5.5-5.5-5.5H33V20.7h17.9c2.2 0 4-1.8 4-4v-10c0-2.2-1.8-4-4-4H13.1c-2.2 0-4 1.8-4 4v10c0 2.2 1.8 4 4 4H31v11.9H13.4c-3 0-5.5 2.5-5.5 5.5v9.5H6c-2.2 0-4 1.8-4 4v5.7c0 2.2 1.8 4 4 4h5.7c2.2 0 4-1.8 4-4v-5.7c0-2.2-1.8-4-4-4H9.9v-9.5c0-1.9 1.6-3.5 3.5-3.5H31v13h-1.9c-2.2 0-4 1.8-4 4v5.7c0 2.2 1.8 4 4 4h5.7c2.2 0 4-1.8 4-4v-5.7c0-2.2-1.8-4-4-4H33v-13h17.6c1.9 0 3.5 1.6 3.5 3.5v9.5h-1.9c-2.2 0-4 1.8-4 4v5.7c0 2.2 1.8 4 4 4H58c2.2 0 4-1.8 4-4v-5.7c0-2.2-1.8-4-4-4M11.1 16.7v-10c0-1.1.9-2 2-2H51c1.1 0 2 .9 2 2v10c0 1.1-.9 2-2 2H13.1c-1.1 0-2-.9-2-2m2.6 34.9v5.7c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2v-5.7c0-1.1.9-2 2-2h5.7c1.2 0 2 .9 2 2m23.2 0v5.7c0 1.1-.9 2-2 2h-5.7c-1.1 0-2-.9-2-2v-5.7c0-1.1.9-2 2-2h5.7c1.1 0 2 .9 2 2M60 57.3c0 1.1-.9 2-2 2h-5.7c-1.1 0-2-.9-2-2v-5.7c0-1.1.9-2 2-2H58c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconNetworking;
