import * as React from "react";
const IconChartLine = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M6 41a1 1 0 0 0 1 1h34v-2H8v-4.641l6.352-7.713c.502.227 1.06.354 1.648.354 1.388 0 2.61-.707 3.328-1.78l4.676 1.948a4 4 0 1 0 7.382-2.3l4.975-6.218A4 4 0 1 0 34.8 18.4l-4.875 6.093a4 4 0 0 0-5.489 1.689l-4.45-1.854q.015-.162.014-.328a4 4 0 1 0-7.206 2.392L8 32.212V7H6zm12-17a2 2 0 1 1-4 0 2 2 0 0 1 4 0m10 6a2 2 0 1 0 0-4 2 2 0 0 0 0 4m12-14a2 2 0 1 1-4 0 2 2 0 0 1 4 0"
      clipRule="evenodd"
    />
  </svg>
);
export default IconChartLine;
