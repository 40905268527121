import * as React from "react";
const IconPoligon = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M45.1 58.7H18.9c-1.4 0-2.8-.8-3.5-2L2.3 34c-.7-1.2-.7-2.8 0-4L15.4 7.3c.7-1.2 2-2 3.5-2h26.2c1.4 0 2.8.8 3.5 2L61.7 30c.7 1.2.7 2.8 0 4L48.6 56.7c-.7 1.3-2.1 2-3.5 2M18.9 7.3c-.7 0-1.4.4-1.7 1L4 31c-.4.6-.4 1.4 0 2l13.1 22.7c.4.6 1 1 1.7 1H45c.7 0 1.4-.4 1.7-1L60 33c.4-.6.4-1.4 0-2L46.9 8.3c-.4-.6-1-1-1.7-1z" />
  </svg>
);
export default IconPoligon;
