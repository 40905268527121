import * as React from "react";
const IconGridAltCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M21.7 4.7H9c-2.8 0-5 2.2-5 5v12.7c0 2.8 2.2 5 5 5h12.7c2.8 0 5-2.2 5-5V9.7c0-2.8-2.2-5-5-5m3 17.7c0 1.7-1.3 3-3 3H9c-1.7 0-3-1.3-3-3V9.7c0-1.7 1.3-3 3-3h12.7c1.7 0 3 1.3 3 3zM55 4.7H42.3c-2.8 0-5 2.2-5 5v12.7c0 2.8 2.2 5 5 5H55c2.8 0 5-2.2 5-5V9.7c0-2.8-2.2-5-5-5m3 17.7c0 1.7-1.3 3-3 3H42.3c-1.7 0-3-1.3-3-3V9.7c0-1.7 1.3-3 3-3H55c1.7 0 3 1.3 3 3zM21.7 36.6H9c-2.8 0-5 2.2-5 5v12.7c0 2.8 2.2 5 5 5h12.7c2.8 0 5-2.2 5-5V41.6c0-2.8-2.2-5-5-5m3 17.7c0 1.7-1.3 3-3 3H9c-1.7 0-3-1.3-3-3V41.6c0-1.7 1.3-3 3-3h12.7c1.7 0 3 1.3 3 3zM55 36.6H42.3c-2.8 0-5 2.2-5 5v12.7c0 2.8 2.2 5 5 5H55c2.8 0 5-2.2 5-5V41.6c0-2.8-2.2-5-5-5m3 17.7c0 1.7-1.3 3-3 3H42.3c-1.7 0-3-1.3-3-3V41.6c0-1.7 1.3-3 3-3H55c1.7 0 3 1.3 3 3z" />
  </svg>
);
export default IconGridAltCopy;
