import * as React from "react";
const IconPoundCard = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.3 54.9H45.2c.7-1 1.2-2.2 1.2-3.5v-1.9h4c.6 0 1-.4 1-1s-.4-1-1-1h-4v-3.7c0-2.3 1.6-4.2 3.6-4.2.9 0 1.8.4 2.4 1.1.4.4 1 .4 1.4.1.4-.4.4-1 .1-1.4-1-1.1-2.4-1.8-3.9-1.8-3.1 0-5.6 2.8-5.6 6.2v3.7h-1.5c-.6 0-1 .4-1 1s.4 1 1 1h1.5v1.9c0 2-1.3 3.5-3 3.5h-1c-.6 0-1 .4-1 1s.4 1 1 1h14.9c.6 0 1-.4 1-1s-.4-1-1-1" />
    <path d="M55.2 7.1H8.8C5.1 7.1 2 9.9 2 13.4v29c0 3.5 3.1 6.3 6.8 6.3h23.1c.6 0 1-.4 1-1s-.4-1-1-1H8.8c-2.6 0-4.8-1.9-4.8-4.3V28.3h56V37c0 .6.4 1 1 1s1-.4 1-1V13.4c0-3.5-3.1-6.3-6.8-6.3m-46.4 2h46.4c2.6 0 4.8 1.9 4.8 4.3v4.3H4v-4.3C4 11 6.2 9.1 8.8 9.1M4 26.3v-6.6h56v6.6z" />
  </svg>
);
export default IconPoundCard;
