import * as React from "react";
const IconRook = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.8 62H6.2c-2.1 0-3.8-1.7-3.8-3.8v-7c0-.6.4-1 1-1H7l.9-6c.1-.5.5-.9 1-.9h6.4V25.4L3.1 21.7c-.4-.1-.7-.5-.7-1V4.4C2.4 3.1 3.5 2 4.8 2h10c1.3 0 2.4 1.1 2.4 2.4v7.4H25V4.4C25 3.1 26.1 2 27.4 2h9.2C37.9 2 39 3.1 39 4.4v7.4h7.8V4.4c0-1.3 1.1-2.4 2.4-2.4h10c1.3 0 2.4 1.1 2.4 2.4v16.3c0 .4-.3.8-.7 1l-12.1 3.7v17.9h6.4c.5 0 .9.4 1 .9l.9 6h3.6c.6 0 1 .4 1 1v7c-.1 2.1-1.8 3.8-3.9 3.8M4.4 52.2v6c0 1 .8 1.8 1.8 1.8h51.6c1 0 1.8-.8 1.8-1.8v-6h-3.4c-.5 0-.9-.4-1-.9l-.9-6h-6.5c-.6 0-1-.4-1-1V24.6c0-.4.3-.8.7-1L59.6 20V4.4c0-.2-.2-.4-.4-.4h-10c-.2 0-.4.2-.4.4v8.4c0 .6-.4 1-1 1H38c-.6 0-1-.4-1-1V4.4c0-.2-.2-.4-.4-.4h-9.2c-.2 0-.4.2-.4.4v8.4c0 .6-.4 1-1 1h-9.8c-.6 0-1-.4-1-1V4.4c0-.2-.2-.4-.4-.4h-10c-.2 0-.4.2-.4.4V20l12.1 3.7c.4.1.7.5.7 1v19.7c0 .6-.4 1-1 1H9.7l-.9 6c-.1.5-.5.9-1 .9H4.4z" />
  </svg>
);
export default IconRook;
