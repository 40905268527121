import { useLocation } from 'react-router-dom';

/**
 * Custom hook to retrieve the state passed during navigation.
 * @returns {Object} The state object from the location or an empty object if no state is found.
 */
const usePageState = () => {
    const location = useLocation();
    return location.state || {};  // Return the state from the location, or an empty object if state is undefined
};

export default usePageState;
