import * as React from "react";
const IconGraduateAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.3 15.2-29-10.5c-.2-.1-.5-.1-.7 0l-29 10.5c-.3.2-.6.5-.6 1s.3.8.7.9l8.5 3.1v19c0 .4.3.8.7.9 8.5 2.9 15 4.1 20.9 4.1 3.3 0 6.4-.4 9.5-1.1v4.6c-2.8.5-4.9 2.9-4.9 5.8 0 3.2 2.6 5.9 5.9 5.9s5.9-2.6 5.9-5.9c0-2.9-2.1-5.3-4.9-5.8v-5c2.5-.7 5.2-1.5 8-2.5.4-.1.7-.5.7-.9v-19l8.5-3.1c.4-.1.7-.5.7-.9s-.5-.9-.9-1.1M47.1 53.4c0 2.1-1.7 3.9-3.9 3.9s-3.9-1.7-3.9-3.9 1.7-3.9 3.9-3.9 3.9 1.8 3.9 3.9m-34-15V20.9l18.5 6.7c.1 0 .2.1.3.1s.2 0 .3-.1l9.9-3.6v17c-8.4 2-16.4 1.6-29-2.6m37.8 0q-3.45 1.2-6.6 2.1V23.3l6.6-2.4zm-7.4-17-7.7-2.9c-.5-.2-1.1.1-1.3.6s.1 1.1.6 1.3l5.5 2.1-8.6 3.1-26.1-9.4L32 6.8l26.1 9.4z" />
  </svg>
);
export default IconGraduateAlt;
