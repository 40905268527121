import * as React from "react";
const IconShipCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M12.5 43v4.9c0 .6.4 1 1 1s1-.4 1-1v-5.2l-5-20.3L32 17.2l22.5 5.1-5 20.3v5.2c0 .6.4 1 1 1s1-.4 1-1V43l5.2-21.2c.1-.3 0-.5-.1-.8s-.4-.4-.6-.4L48.8 19V8.3C48.8 4.8 46 2 42.5 2h-21c-3.5 0-6.3 2.8-6.3 6.3V19l-7.1 1.6c-.3.1-.5.2-.6.4s-.2.5-.1.8zm4.8-34.7c0-2.4 1.9-4.3 4.3-4.3h21c2.4 0 4.3 1.9 4.3 4.3v10.2l-14.6-3.3h-.4l-14.6 3.3zM57 54.7c-2.6 0-3.9 1.7-5 3-1 1.4-1.8 2.3-3.3 2.3s-2.3-.9-3.4-2.3c-1.1-1.3-2.4-3-5-3s-3.9 1.7-5 3c-1 1.4-1.8 2.3-3.3 2.3s-2.3-.9-3.4-2.3c-1.1-1.3-2.4-3-5-3s-3.9 1.7-5 3c-1.1 1.4-1.9 2.3-3.4 2.3s-2.3-.9-3.4-2.3c-1.1-1.3-2.4-3-5-3-.6 0-1 .4-1 1s.4 1 1 1c1.5 0 2.3.9 3.4 2.3 1.1 1.3 2.4 3 5 3s3.9-1.7 5-3c1.1-1.4 1.9-2.3 3.4-2.3s2.3.9 3.4 2.3c1.1 1.3 2.4 3 5 3s3.9-1.7 5-3c1.1-1.4 1.9-2.3 3.4-2.3s2.3.9 3.4 2.3c1.1 1.3 2.4 3 5 3s3.9-1.7 5-3c1.1-1.4 1.9-2.3 3.4-2.3.6 0 1-.4 1-1s-.6-1-1.2-1" />
  </svg>
);
export default IconShipCopy;
