import * as React from "react";
const IconChestPress = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.4 19.8v-6.5c0-.6-.4-1-1-1H44.2V7.1c0-2.2-1.8-4-4-4H23.8c-2.2 0-4 1.8-4 4v5.2H6.6c-.6 0-1 .4-1 1v6.5C3.6 20 2 21.6 2 23.6v8.2c0 2.1 1.7 3.9 3.9 3.9h1.4c2.1 0 3.9-1.7 3.9-3.9v-8.2c0-2-1.6-3.7-3.6-3.8v-5.5h12.3V43H19c-2.2 0-4 1.8-4 4v3c0 2.2 1.8 4 4 4h5.8v5.9c0 .6.4 1 1 1s1-.4 1-1V54h10.4v5.9c0 .6.4 1 1 1s1-.4 1-1V54H45c2.2 0 4-1.8 4-4v-3c0-2.2-1.8-4-4-4h-.8V14.3h12.3v5.5c-2 .2-3.6 1.8-3.6 3.8v8.2c0 2.1 1.7 3.9 3.9 3.9h1.4c2.1 0 3.9-1.7 3.9-3.9v-8.2c-.1-2-1.7-3.6-3.7-3.8M9.1 23.6v8.2c0 1-.8 1.9-1.9 1.9H5.9c-1 0-1.9-.8-1.9-1.9v-8.2c0-1 .8-1.9 1.9-1.9h1.4c1 .1 1.8.9 1.8 1.9M21.8 7.1c0-1.1.9-2 2-2h16.3c1.1 0 2 .9 2 2v5.2H21.8zM47 47v3c0 1.1-.9 2-2 2H19c-1.1 0-2-.9-2-2v-3c0-1.1.9-2 2-2h26c1.1 0 2 .9 2 2m-4.8-4H21.8V14.3h20.3V43zM60 31.8c0 1-.8 1.9-1.9 1.9h-1.4c-1 0-1.9-.8-1.9-1.9v-8.2c0-1 .8-1.9 1.9-1.9h1.4c1 0 1.9.8 1.9 1.9z" />
  </svg>
);
export default IconChestPress;
