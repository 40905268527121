import * as React from "react";
const IconHat5 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.2 60h-1l-22-43.1c3.6-.6 6.3-3.7 6.3-7.4C39.5 5.4 36.1 2 32 2s-7.5 3.4-7.5 7.5c0 3.7 2.7 6.8 6.3 7.4L8.8 60h-1c-.6 0-1 .4-1 1s.4 1 1 1h48.5c.6 0 1-.4 1-1s-.5-1-1.1-1M26.5 9.5C26.5 6.5 29 4 32 4s5.5 2.5 5.5 5.5S35 15 32 15s-5.5-2.5-5.5-5.5M32 19l20.9 41H11.1z" />
  </svg>
);
export default IconHat5;
