import * as React from "react";
const IconSun = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 18.4c-7.5 0-13.6 6.1-13.6 13.6S24.5 45.6 32 45.6 45.6 39.5 45.6 32 39.5 18.4 32 18.4m0 22.7c-5 0-9.1-4.1-9.1-9.1s4.1-9.1 9.1-9.1 9.1 4.1 9.1 9.1-4.1 9.1-9.1 9.1M32 11.3c1.2 0 2.3-1 2.3-2.3V4c0-1.2-1-2.3-2.3-2.3-1.2 0-2.3 1-2.3 2.3v5.1c.1 1.2 1.1 2.2 2.3 2.2M32 52.7c-1.2 0-2.3 1-2.3 2.3v5c0 1.2 1 2.3 2.3 2.3 1.2 0 2.3-1 2.3-2.3v-5.1c0-1.2-1.1-2.2-2.3-2.2M48.3 18c.6 0 1.2-.2 1.6-.7l3.2-3.2c.9-.9.9-2.3 0-3.2s-2.3-.9-3.2 0l-3.2 3.2c-.9.9-.9 2.3 0 3.2.4.5 1 .7 1.6.7M14.2 46.7 11 49.8c-.9.9-.9 2.3 0 3.2.4.4 1 .7 1.6.7s1.2-.2 1.6-.7l3.2-3.2c.9-.9.9-2.3 0-3.2-.9-.8-2.4-.8-3.2.1M60 29.8h-5.1c-1.2 0-2.3 1-2.3 2.3 0 1.2 1 2.3 2.3 2.3H60c1.2 0 2.3-1 2.3-2.3s-1.1-2.3-2.3-2.3M11.3 32c0-1.2-1-2.3-2.3-2.3H4c-1.2 0-2.3 1-2.3 2.3 0 1.2 1 2.3 2.3 2.3h5.1c1.2 0 2.2-1.1 2.2-2.3M49.8 46.7c-.9-.9-2.3-.9-3.2 0s-.9 2.3 0 3.2l3.2 3.2c.4.4 1 .7 1.6.7s1.2-.2 1.6-.7c.9-.9.9-2.3 0-3.2zM14.2 11c-.9-.9-2.3-.9-3.2 0s-.9 2.3 0 3.2l3.2 3.2c.4.4 1 .7 1.6.7s1.2-.2 1.6-.7c.9-.9.9-2.3 0-3.2z" />
  </svg>
);
export default IconSun;
