import * as React from "react";
const IconSpeechBubble25 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.9 56.9-8.8-15.5c1.1-3 1.7-6.1 1.7-9.4C54.8 17.4 43 5.6 28.4 5.6S2 17.4 2 32s11.8 26.4 26.4 26.4H61c.4 0 .7-.2.9-.5.1-.3.1-.7 0-1m-33.5-.5C15 56.4 4 45.5 4 32S15 7.6 28.4 7.6c13.5 0 24.4 11 24.4 24.4 0 3.1-.6 6.2-1.8 9.1-.1.3-.1.6.1.9l8.2 14.5H28.4z" />
    <path d="M14.1 28.6H14c-1.1 0-2 .9-2 2s.9 2 2 2h.1c1.1 0 2-.9 2-2s-.9-2-2-2M27.9 28.6h-.1c-1.1 0-2 .9-2 2s.9 2 2 2h.1c1.1 0 2-.9 2-2s-.9-2-2-2M41.7 28.6h-.1c-1.1 0-2 .9-2 2s.9 2 2 2h.1c1.1 0 2-.9 2-2s-.9-2-2-2" />
  </svg>
);
export default IconSpeechBubble25;
