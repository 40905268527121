import * as React from "react";
const IconMonitorAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.1 13.1h-4.2c-.6 0-1 .4-1 1s.4 1 1 1h4.2c1.4 0 2.5 1.1 2.5 2.5v27.8c0 1.4-1.1 2.5-2.5 2.5H10.9c-1.4 0-2.5-1.1-2.5-2.5V17.6c0-1.4 1.1-2.5 2.5-2.5h4.9c.6 0 1-.4 1-1s-.4-1-1-1h-4.9c-2.5 0-4.5 2-4.5 4.5v27.8c0 2.5 2 4.5 4.5 4.5h13.4v10h-3c-.6 0-1 .4-1 1s.4 1 1 1h21.3c.6 0 1-.4 1-1s-.4-1-1-1h-3V50H53c2.5 0 4.5-2 4.5-4.5V17.6c.1-2.4-1.9-4.5-4.4-4.5M37.7 60H26.4V50h11.3z" />
    <path d="m30.6 20.6-1.2 1.2-.6.6-4.2 4.2c-.4.4-.4 1 0 1.4s1 .4 1.4 0l4.2-4.2.6-.6L32 22l1.2 1.2.8.8 4 4c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-4-4-.8-.8-1.2-1.2c1.4-1.4 2.8-3 4.1-4.6 1.9-2.6 2.7-5.4 2.2-7.8-.7-3.4-4-6.1-7.7-6.2-3.6.1-6.9 2.8-7.7 6.2-.5 2.4.3 5.2 2.2 7.8 1.3 1.7 2.7 3.2 4.1 4.6m-4.4-12c.6-2.6 3-4.5 5.7-4.6 2.7.1 5.2 2.1 5.8 4.6.5 2.5-1 4.9-1.9 6.2-1.2 1.6-2.5 3.1-3.9 4.4-1.3-1.4-2.7-2.8-3.9-4.4-.8-1.3-2.3-3.7-1.8-6.2" />
  </svg>
);
export default IconMonitorAlt2;
