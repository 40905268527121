import * as React from "react";
const IconFunnelAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M36.8 62c-.3 0-.6-.1-.9-.2l-9.7-3.5c-1-.4-1.7-1.3-1.7-2.4V35.6L7.7 14.7c-.9-1.1-1.3-2.4-1.3-3.8V4.5C6.4 3.1 7.5 2 8.9 2H55c1.4 0 2.5 1.1 2.5 2.5v6.4c0 1.4-.5 2.8-1.3 3.8L39.4 35.6v23.9c0 .8-.4 1.6-1.1 2.1-.5.2-1 .4-1.5.4M8.9 4c-.3 0-.5.2-.5.5v6.4c0 .9.3 1.9.9 2.6l17.1 21.2c.1.2.2.4.2.6V56c0 .2.1.4.4.5l9.7 3.5c.2.1.4 0 .5-.1s.2-.2.2-.4V35.3c0-.2.1-.4.2-.6l17.1-21.2c.6-.7.9-1.6.9-2.6V4.5c0-.3-.2-.5-.5-.5z" />
  </svg>
);
export default IconFunnelAlt;
