import * as React from "react";
const IconSofa3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.3 29.4 46.9 24v-9.5c0-2.7-2.2-4.8-4.8-4.8H22c-2.7 0-4.8 2.2-4.8 4.8V24L2.7 29.3c-.5.2-.8.8-.6 1.3.1.4.5.7.9.7.1 0 .2 0 .3-.1l4.5-1.7v23.8c0 .6.4 1 1 1s1-.4 1-1V39.6h6v3.8c0 .6.4 1 1 1h30.4c.6 0 1-.4 1-1v-3.8h6v13.8c0 .6.4 1 1 1s1-.4 1-1V29.6l4.5 1.7c.1 0 .2.1.3.1.4 0 .8-.2.9-.7.2-.6 0-1.1-.6-1.3M22 11.6h20c1.6 0 2.8 1.3 2.8 2.8v18H19.1V14.5c0-1.6 1.3-2.9 2.9-2.9m-12.2 26v-8.8l7.3-2.7v6.4h-.3c-.6 0-1 .4-1 1v4.1zm36.4 4.8H17.8v-7.9h28.4zm8-4.8h-6v-4.1c0-.6-.4-1-1-1h-.3v-6.3l7.3 2.7z" />
  </svg>
);
export default IconSofa3;
