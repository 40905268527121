import * as React from "react";
const IconHospitalAlt7 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 21.4H43.8v-9.8c0-2.6-2.1-4.8-4.8-4.8H25c-2.6 0-4.8 2.1-4.8 4.8v9.8H6c-2.2 0-4 1.8-4 4v27.8c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V25.4c0-2.2-1.8-4-4-4m-35.8-9.8c0-1.5 1.2-2.8 2.8-2.8h14c1.5 0 2.8 1.2 2.8 2.8v21.9H22.2V11.6M4 53.2V25.4c0-1.1.9-2 2-2h14.2v31.8H6c-1.1 0-2-.9-2-2m18.2-17.7h19.6v19.7h-4.3v-9.5c0-2.2-1.8-4-4-4h-3c-2.2 0-4 1.8-4 4v9.5h-4.3zm6.3 19.7v-9.5c0-1.1.9-2 2-2h3c1.1 0 2 .9 2 2v9.5zm31.5-2c0 1.1-.9 2-2 2H43.8V23.4H58c1.1 0 2 .9 2 2z" />
    <path d="M27.5 20.1H31v3.5c0 .6.4 1 1 1s1-.4 1-1v-3.5h3.5c.6 0 1-.4 1-1s-.4-1-1-1H33v-3.5c0-.6-.4-1-1-1s-1 .4-1 1v3.5h-3.5c-.6 0-1 .4-1 1s.5 1 1 1" />
  </svg>
);
export default IconHospitalAlt7;
