import * as React from "react";
const IconCloudIdea = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.5 22.2c-1.6 0-3.1.3-4.5.8.1-.7.2-1.5.2-2.2 0-7.2-6.4-13-14.2-13s-14.2 5.8-14.2 13c0 .8.1 1.5.2 2.2-1.4-.5-2.9-.8-4.5-.8C7.2 22.2 2 26.9 2 32.8c0 5.8 5.2 10.6 11.5 10.6h2.6c.6 0 1-.4 1-1s-.4-1-1-1h-2.6c-5.3 0-9.5-3.9-9.5-8.6s4.3-8.6 9.5-8.6c2 0 3.9.6 5.5 1.6.4.2.8.2 1.2-.1.3-.3.5-.7.3-1.1-.5-1.2-.7-2.5-.7-3.8 0-6.1 5.5-11 12.2-11s12.2 4.9 12.2 11c0 1.3-.3 2.6-.7 3.8-.2.4 0 .9.3 1.1.3.3.8.3 1.2.1 1.6-1.1 3.5-1.6 5.5-1.6 5.3 0 9.5 3.9 9.5 8.6s-4.3 8.6-9.5 8.6c-.6 0-1 .4-1 1s.4 1 1 1c6.4 0 11.5-4.8 11.5-10.6 0-5.9-5.2-10.6-11.5-10.6" />
    <path d="M33.2 28.5c-2.9-.1-5.7 1-7.7 3-2.1 2-3.2 4.7-3.2 7.7 0 3.7 1.9 7.1 5 9.1.6.4 1 1.1 1 1.9V52c0 2.3 1.9 4.2 4.2 4.2h1c2.3 0 4.2-1.9 4.2-4.2v-1.9c0-.4.1-.7.3-1s.4-.6.7-.8c3-1.9 4.9-5.2 5-8.8.1-2.8-1-5.5-3-7.6s-4.7-3.3-7.5-3.4M35.6 52c0 1.2-1 2.2-2.2 2.2h-1c-1.2 0-2.2-1-2.2-2.2v-2.3h5.4V52m2-5.4c-.4.3-.8.6-1.1 1h-7c-.3-.4-.7-.8-1.1-1-2.6-1.6-4.1-4.4-4.1-7.4 0-2.4.9-4.6 2.6-6.2 1.6-1.6 3.8-2.5 6.1-2.5h.2c2.3.1 4.4 1 6 2.7s2.5 3.9 2.4 6.2c0 3-1.6 5.6-4 7.2" />
  </svg>
);
export default IconCloudIdea;
