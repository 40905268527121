import * as React from "react";
const IconMackup2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m55.3 32 4.5-23.2c.3-1.7-.1-3.4-1.2-4.7S55.9 2 54.1 2H9.9c-1.8 0-3.4.8-4.4 2.1-1.1 1.3-1.5 3-1.2 4.7L8.7 32 4.3 55.2c-.3 1.7.1 3.4 1.2 4.7 1 1.3 2.6 2.1 4.4 2.1h44.3c1.7 0 3.3-.8 4.4-2.1s1.5-3 1.2-4.7zM7 5.3C7.7 4.5 8.7 4 9.9 4h44.3c1.1 0 2.1.5 2.9 1.3.7.9 1 2 .8 3.1L53.4 31H10.6L6.2 8.4c-.2-1.1.1-2.2.8-3.1m50 53.4c-.7.9-1.7 1.3-2.9 1.3H9.9c-1.1 0-2.1-.5-2.9-1.3-.7-.9-1-2-.8-3.1L10.6 33h42.9l4.3 22.6c.2 1.1-.1 2.2-.8 3.1" />
    <path d="M19 40.3c-1.3 0-2.4 1.1-2.4 2.4s1.1 2.4 2.4 2.4 2.4-1.1 2.4-2.4c.1-1.3-1-2.4-2.4-2.4M27.7 40.3c-1.3 0-2.4 1.1-2.4 2.4s1.1 2.4 2.4 2.4 2.4-1.1 2.4-2.4-1.1-2.4-2.4-2.4M36.3 40.3c-1.3 0-2.4 1.1-2.4 2.4s1.1 2.4 2.4 2.4 2.4-1.1 2.4-2.4c.1-1.3-1-2.4-2.4-2.4M45 40.3c-1.3 0-2.4 1.1-2.4 2.4s1.1 2.4 2.4 2.4 2.4-1.1 2.4-2.4-1.1-2.4-2.4-2.4M19 49.5c-1.3 0-2.4 1.1-2.4 2.4s1.1 2.4 2.4 2.4 2.4-1.1 2.4-2.4-1-2.4-2.4-2.4M27.7 49.5c-1.3 0-2.4 1.1-2.4 2.4s1.1 2.4 2.4 2.4 2.4-1.1 2.4-2.4-1.1-2.4-2.4-2.4M36.3 49.5c-1.3 0-2.4 1.1-2.4 2.4s1.1 2.4 2.4 2.4 2.4-1.1 2.4-2.4-1-2.4-2.4-2.4M45 49.5c-1.3 0-2.4 1.1-2.4 2.4s1.1 2.4 2.4 2.4 2.4-1.1 2.4-2.4-1.1-2.4-2.4-2.4" />
  </svg>
);
export default IconMackup2;
