import * as React from "react";
const IconBulbCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M46.3 8.1C42 3.8 36.5 1.7 30.7 2c-10.6.7-19.1 10.3-19.2 21.8-.1 7.7 3.3 14.7 9.3 18.8v11.9c0 4.1 3.1 7.5 7 7.5h8.4c3.9 0 7-3.3 7-7.5V42.7c5.9-4.1 9.4-11 9.4-18.6 0-6-2.2-11.7-6.3-16M36.2 60h-8.4c-2.8 0-5-2.5-5-5.5v-11h18.4v11c0 3-2.3 5.5-5 5.5m5.4-18.7-.4.3h-8.3V31.5l6.4-4.9c.4-.3.5-1 .2-1.4s-1-.5-1.4-.2l-5.2 4v-7.5c0-.6-.4-1-1-1s-1 .4-1 1v7.7L25 25c-.5-.3-1.1-.2-1.4.2-.3.5-.2 1.1.2 1.4l7.1 5v9.9h-8.3l-.4-.3c-5.6-3.7-8.9-10.2-8.8-17.4.2-10.4 7.8-19.2 17.5-19.8H32c4.8 0 9.4 1.9 12.9 5.5 3.8 3.9 5.8 9.1 5.7 14.6-.1 7.1-3.4 13.5-9 17.2" />
  </svg>
);
export default IconBulbCopy;
