import * as React from "react";
const IconPlantAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M45.5 36.7h-6.4c1.8-1 3.5-2.4 5.1-3.9 4.7-4.7 7.1-10.7 6.2-15.2-.1-.4-.4-.7-.8-.8-2.7-.6-6.1.1-9.4 1.8-.5-7.3-3.4-13.6-7.7-16.5-.3-.2-.8-.2-1.1 0-4.3 2.9-7.2 9.2-7.7 16.5-3.3-1.7-6.6-2.4-9.4-1.8-.4.1-.7.4-.8.8-.9 4.6 1.5 10.6 6.2 15.2 1.5 1.6 3.3 2.9 5.1 3.9h-6.4c-2.2 0-4 1.8-4 4v2.7c0 1.6.9 3 2.3 3.6L19 57.9c.5 2.4 2.6 4.1 5 4.1h16c2.4 0 4.6-1.7 5-4.1L47.2 47c1.3-.6 2.3-2 2.3-3.6v-2.7c0-2.2-1.8-4-4-4m3-18c.4 3.8-1.8 8.8-5.8 12.8-2.2 2.2-4.7 3.9-7.2 4.9 2.9-3.7 4.7-9.2 4.8-15.3 2.9-1.9 5.8-2.7 8.2-2.4M32 4.2c3.8 3 6.3 9.4 6.3 16.4 0 6.8-2.4 13.1-6 16.1h-.6c-3.7-3.1-6-9.3-6-16.1 0-7.1 2.4-13.4 6.3-16.4M15.5 18.7c2.4-.3 5.3.6 8.2 2.3.1 6.1 1.8 11.7 4.8 15.4-2.5-1-5.1-2.7-7.2-4.9-4-4-6.2-9-5.8-12.8m27.6 38.8c-.3 1.4-1.6 2.5-3.1 2.5H24c-1.5 0-2.8-1.1-3.1-2.5l-2-10h26.2zm4.4-14.1c0 1.1-.9 2-2 2h-27c-1.1 0-2-.9-2-2v-2.7c0-1.1.9-2 2-2h27c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconPlantAlt;
