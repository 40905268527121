import React from 'react';
import { BringzzTopBar } from "@bringzz/components";
import BringzzLogo from 'assets/BringzzLogo.png';
import { useNavigation } from '../../context/NavigationContext';

const MapZipcodesDemo = () => {
	const { navigate } = useNavigation();

	return (
		<div className='flex flex-col h-full'>
			<BringzzTopBar
				logo={BringzzLogo}
				title={'Select address'}
				sections={[
					{ name: 'logo', alignment: 'start', value: BringzzLogo },
					{ name: 'location', alignment: 'center', value: 'Wien' },
					{
						name: 'notification',
						alignment: 'end',
						value: true,
						onClick: () => navigate('/notifications')
					}
				]}
			/>
			<div className='grow relative'>
				{/* <BringzzMapDemo /> */}
			</div>
		</div>
	);
};

export default MapZipcodesDemo;
