import * as React from "react";
const IconEc = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 513 342"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 0h513v342H0z" />
    <path  d="M0 170.7h513V256H0z" />
    <path  d="M0 256h513v85.3H0z" />
    <circle cx={256} cy={171} r={60}  />
    <path
      
      d="M369.2 79.9s-27.2-13.8-33.9-16-49.1-13.2-49.1-7c0 10.3-13.5 12-15.5 12s-2-5.3-14.8-5.3-13.7 4.8-15.8 4.8c-2.2 0-14.5-1-14.5-11.5 0-5.2-42.9 4-49.1 7-6.1 3.1-33.9 16-33.9 16s45.8 2.7 53.9 6.2 43.8 16.5 43.8 16.5l-2.8 13.3h37.8l-3.8-13.3s35.1-12.7 43.8-16.5 53.9-6.2 53.9-6.2"
    />
    <path
      
      d="M217.7 171.7c0 21.1 17.2 38.3 38.3 38.3s38.3-17.2 38.3-38.3v-11.5h-76.5v11.5z"
    />
    <path
      
      d="M256 110.5c-21.1 0-38.3 17.2-38.3 38.3v11.5h76.5v-11.5c.1-21.1-17.1-38.3-38.2-38.3"
    />
  </svg>
);
export default IconEc;
