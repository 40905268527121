const Modes = {
  DAY: "day",
  MONTH: "month",
  YEAR: "year",
  MONTH_YEAR: "monthYear",
  DAY_MONTH: "dayMonth",
  TIME:"time"
};

export default Modes;
