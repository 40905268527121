import * as React from "react";
const IconScrew = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.2 16.2C55.2 9.9 44.3 2 32 2S8.8 9.9 8.8 16.2c0 4.5 5.9 8.3 14.4 9.8v26.4c0 1.8.8 3.5 2.1 4.7l4.1 3.9c.7.7 1.6 1 2.6 1s1.8-.3 2.6-1l4.1-3.9c1.3-1.2 2.1-2.9 2.1-4.7V26c8.5-1.5 14.4-5.3 14.4-9.8M25.3 39.5 38.8 35v8.2l-13.5 4.5zm13.5-6.6-13.5 4.5V26.8h13.5zm-1.5 22.8-4.1 3.9c-.7.6-1.7.6-2.4 0l-4.1-3.9c-.9-.8-1.4-2-1.4-3.3v-2.6l13.5-4.5v7.1c0 1.2-.6 2.4-1.5 3.3M32 24.8c-12.7 0-21.2-4.4-21.2-8.6C10.8 11.5 20.5 4 32 4s21.2 7.5 21.2 12.2c0 4.1-8.5 8.6-21.2 8.6" />
  </svg>
);
export default IconScrew;
