import * as React from "react";
const IconWebsiteCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m21.2 19.7-1.9 3.8-1.6-3.3c-.2-.4-.7-.7-1.1-.7s-.9.3-1.1.7l-1.6 3.3-1.9-3.8c-.2-.5-.8-.7-1.3-.4-.5.2-.7.8-.4 1.3l2.5 5.1c.2.4.7.7 1.1.7.5 0 .9-.3 1.1-.7l1.6-3.3 1.6 3.3c.2.4.7.7 1.1.7.5 0 .9-.3 1.1-.7l2.5-5.1c.2-.5 0-1.1-.4-1.3-.5-.3-1.1-.1-1.3.4M38 19.2c-.5-.2-1.1 0-1.3.4l-1.9 3.8-1.6-3.3c-.2-.4-.7-.7-1.1-.7-.5 0-.9.3-1.1.7l-1.6 3.3-1.9-3.8c-.2-.5-.8-.7-1.3-.4-.5.2-.7.8-.4 1.3l2.5 5.1c.2.4.7.7 1.1.7.5 0 .9-.3 1.1-.7l1.6-3.3 1.7 3.3c.2.4.7.7 1.1.7.5 0 .9-.3 1.1-.7l2.5-5.1c.2-.4 0-1-.5-1.3M45.9 25.7l1.6-3.3 1.6 3.3c.2.4.7.7 1.1.7s.9-.3 1.1-.7l2.5-5.1c.2-.5 0-1.1-.5-1.3s-1.1 0-1.3.4l-1.9 3.8-1.6-3.3c-.2-.4-.7-.7-1.1-.7-.5 0-.9.3-1.1.7l-1.6 3.3-1.9-3.8c-.2-.5-.8-.7-1.3-.4-.5.2-.7.8-.5 1.3l2.5 5.1c.2.4.7.7 1.1.7.6 0 1.1-.3 1.3-.7" />
    <path d="M58.4 8.5H5.6c-2 0-3.6 1.6-3.6 3.7v21.2c0 2 1.6 3.7 3.6 3.7h31.7c.6 0 1-.4 1-1s-.4-1-1-1H5.6C4.7 35 4 34.3 4 33.4V12.2c0-.9.7-1.7 1.6-1.7h52.9c.9 0 1.6.7 1.6 1.7v21.2c0 .9-.8 1.7-1.6 1.7-.6 0-1 .4-1 1s.4 1 1 1c2 0 3.6-1.6 3.6-3.7V12.2c-.1-2-1.7-3.7-3.7-3.7M60.8 45l-14.1-7.1c-.7-.4-1.5-.3-2 .2s-.6 1.3-.3 2l7.1 14.1c.4.7 1.1 1.2 1.8 1.2.6 0 1.2-.4 1.4-.9l1.9-4.3 4.3-1.9c.6-.3.9-.8.9-1.4.2-.8-.3-1.6-1-1.9m-5.6 3.7-2 4.5-6.6-13.1 13.1 6.6z" />
  </svg>
);
export default IconWebsiteCopy;
