import * as React from "react";
const IconTombstone = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M37.8 20.8H33v-4.3c0-.6-.4-1-1-1s-1 .4-1 1v4.3h-4.8c-.6 0-1 .4-1 1s.4 1 1 1H31v10.5c0 .6.4 1 1 1s1-.4 1-1V22.8h4.8c.6 0 1-.4 1-1s-.5-1-1-1" />
    <path d="M55.8 60h-4.6V11c0-5-4-9-9-9H21.8c-5 0-9 4-9 9v49H8.2c-.6 0-1 .4-1 1s.4 1 1 1h47.7c.6 0 1-.4 1-1s-.5-1-1.1-1m-41-49c0-3.9 3.1-7 7-7h20.4c3.9 0 7 3.1 7 7v49H14.8z" />
  </svg>
);
export default IconTombstone;
