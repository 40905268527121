import * as React from "react";
const IconMessageLock = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59 41.3h-2.2v-3.9c0-1.7-1.3-3-3-3h-3.9c-1.7 0-3 1.3-3 3v3.9h-2.2c-1.7 0-3 1.3-3 3v6.5c0 1.7 1.3 3 3 3H59c1.7 0 3-1.3 3-3v-6.5c0-1.6-1.3-3-3-3m-10.1-3.9c0-.6.4-1 1-1h3.9c.6 0 1 .4 1 1v3.9h-5.9zM60 50.8c0 .6-.4 1-1 1H44.7c-.6 0-1-.4-1-1v-6.5c0-.6.4-1 1-1H59c.6 0 1 .4 1 1z" />
    <path d="M34.5 49.5H7c-1.7 0-3-1.4-3-3V15.3c0-.4.1-.7.2-1.1l24.9 15.1c.9.5 1.8.8 2.8.8s1.9-.3 2.8-.8l24.9-15.1c.1.3.2.7.2 1.1v14.3c0 .6.4 1 1 1s1-.4 1-1V15.3c0-2.8-2.3-5-5-5H7.1c-2.8 0-5.1 2.3-5.1 5.1v31.2c0 2.8 2.3 5 5 5h27.4c.6 0 1-.4 1-1s-.4-1.1-.9-1.1m22.1-37.3c.6 0 1.1.2 1.5.4l-24.5 15c-1.1.7-2.4.6-3.5 0L5.5 12.7c.5-.3 1-.4 1.5-.4h49.6z" />
  </svg>
);
export default IconMessageLock;
