import * as React from "react";
const IconCastleAlt3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.1 61.3h-13c-2.7 0-4.9-2.2-4.9-4.9v-4.1c0-3.9-3.2-7.1-7.1-7.1S25 48.4 25 52.3v4.1c0 2.7-2.2 4.9-4.9 4.9h-13c-2.7 0-4.9-2.2-4.9-4.9V7.6c0-2.7 2.2-4.9 4.9-4.9h8.5c2.7 0 4.9 2.2 4.9 4.9V21c0 .2.1.2.2.3.1 0 .2.1.3 0l9.9-6.9c.8-.6 1.9-.5 2.6 0l9.9 6.9c.1.1.3.1.3 0 .1 0 .2-.1.2-.3V7.6c0-2.7 2.2-4.9 4.9-4.9h8.5c2.7 0 4.9 2.2 4.9 4.9v48.8c-.2 2.7-2.4 4.9-5.1 4.9M32 43.1c5 0 9.1 4.1 9.1 9.1v4.1c0 1.6 1.3 2.9 2.9 2.9h13c1.6 0 2.9-1.3 2.9-2.9V7.6c0-1.6-1.3-2.9-2.9-2.9h-8.5c-1.6 0-2.9 1.3-2.9 2.9V21c0 .9-.5 1.6-1.2 2-.8.4-1.7.3-2.4-.1L32.1 16c-.1-.1-.3-.1-.4 0l-9.9 6.9c-.7.5-1.6.6-2.4.2s-1.2-1.2-1.2-2V7.6c0-1.6-1.3-2.9-2.9-2.9H6.9C5.3 4.7 4 6 4 7.6v48.8c0 1.6 1.3 2.9 2.9 2.9h13c1.6 0 2.9-1.3 2.9-2.9v-4.1c.1-5.1 4.2-9.2 9.2-9.2" />
  </svg>
);
export default IconCastleAlt3;
