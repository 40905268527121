import * as React from "react";
const IconHashtag = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 65 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.7 20.5H47.1l4.1-16.3V4c0-.4-.3-.9-.8-1s-1.1.2-1.2.7L45 20.5H26.1l4.1-16.3V4c0-.4-.3-.9-.8-1s-1.1.2-1.2.7L24 20.5H11.7c-.6 0-1 .4-1 1s.4 1 1 1h11.8l-4.8 19h-14c-.6 0-1 .4-1 1s.4 1 1 1h13.6l-4.1 16.3v.2c0 .4.3.9.8 1s1.1-.2 1.2-.7l4.2-16.7h18.9l-4.1 16.3v.2c0 .4.3.9.8 1h.2c.4 0 .9-.3 1-.8l4.2-16.7h12.3c.6 0 1-.4 1-1s-.4-1-1-1H41.8l4.8-19h14.1c.6 0 1-.4 1-1s-.5-1.1-1-1.1m-20.9 21h-19l4.8-19h18.9z" />
  </svg>
);
export default IconHashtag;
