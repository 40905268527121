import * as React from "react";
const IconSawAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.4 28.4H27.7v-4.2c0-2-1.6-3.6-3.6-3.6H13.5c-2 0-3.6 1.6-3.6 3.6 0 2.3-1.9 4.2-4.3 4.2H3c-.6 0-1 .4-1 1v13c0 .6.4 1 1 1h53.4c3.1 0 5.6-2.5 5.6-5.6v-3.9c0-3-2.5-5.5-5.6-5.5M4 30.4h1.6c3.5 0 6.3-2.8 6.3-6.2 0-.9.7-1.6 1.6-1.6H24c.9 0 1.6.7 1.6 1.6v17.2H4zm56 7.5c0 2-1.6 3.6-3.6 3.6H27.7v-11h28.7c2 0 3.6 1.6 3.6 3.6z" />
  </svg>
);
export default IconSawAlt;
