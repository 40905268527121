import React from 'react';
import { useNavigation } from 'context/NavigationContext';
import { BringzzPageContainer, BringzzIcon, BringzzText, BringzzAccordion, BringzzButton } from "@bringzz/components";
import AvatarExample from 'assets/images/AvatarExample.png';
import PostExample1 from 'assets/images/PostExample1.png';

const FaqGeneralPage = () => {
    const { backPress, navigate } = useNavigation();

    return (
        <BringzzPageContainer
            header={{
                left: <BringzzIcon
                    size="22"
                    icon="IconChevronLeft"
                    folder="LineIcons"
                    className="cursor-pointer"
                    onClick={backPress}
                />,
                title: <BringzzText tag='h2'>FAQ & Support</BringzzText>
            }}>

            <div className='p-4 divide-y divide-solid'>
                <BringzzAccordion
                    title={<BringzzText tag="h4" className="font-bold">Q  •  Lorem congue gravida lorem ?</BringzzText>}
                    defaultValue={true}
                >
                    <BringzzText tag="h4" className="font-normal">A  •  Lorem ipsum dolor sit amet, consetetur sadipsc-ing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.</BringzzText>
                </BringzzAccordion>
                <BringzzAccordion
                    title={<BringzzText tag="h4" className="font-bold">Q  •  Lorem congue gravida lorem ?</BringzzText>}
                    defaultValue={false}
                >
                    <BringzzText tag="h4" className="font-normal">A  •  Lorem ipsum dolor sit amet, consetetur sadipsc-ing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.</BringzzText>
                </BringzzAccordion>
                <BringzzAccordion
                    title={<BringzzText tag="h4" className="font-bold">Q  •  Lorem congue gravida lorem ?</BringzzText>}
                    defaultValue={false}
                >
                    <BringzzText tag="h4" className="font-normal">A  •  Lorem ipsum dolor sit amet, consetetur sadipsc-ing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.</BringzzText>
                </BringzzAccordion>
                <BringzzAccordion
                    title={<BringzzText tag="h4" className="font-bold">Q  •  Lorem congue gravida lorem ?</BringzzText>}
                    defaultValue={false}
                >
                    <BringzzText tag="h4" className="font-normal">A  •  Lorem ipsum dolor sit amet, consetetur sadipsc-ing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.</BringzzText>
                </BringzzAccordion>
                <BringzzAccordion
                    title={<BringzzText tag="h4" className="font-bold">Q  •  Lorem congue gravida lorem ?</BringzzText>}
                    defaultValue={false}
                >
                    <BringzzText tag="h4" className="font-normal">A  •  Lorem ipsum dolor sit amet, consetetur sadipsc-ing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.</BringzzText>
                </BringzzAccordion>
                <BringzzAccordion
                    title={<BringzzText tag="h4" className="font-bold">Q  •  Lorem congue gravida lorem ?</BringzzText>}
                    defaultValue={false}
                >
                    <BringzzText tag="h4" className="font-normal">A  •  Lorem ipsum dolor sit amet, consetetur sadipsc-ing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.</BringzzText>
                </BringzzAccordion>
                <BringzzAccordion
                    title={<BringzzText tag="h4" className="font-bold">Q  •  Lorem congue gravida lorem Lorem congue gravida loremLorem congue grav Lorem congue gravida lorem?</BringzzText>}
                    defaultValue={false}
                >
                    <BringzzText tag="h4" className="font-normal">A  •  Lorem ipsum dolor sit amet, consetetur sadipsc-ing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.</BringzzText>
                </BringzzAccordion>
                <BringzzAccordion
                    title={<BringzzText tag="h4" className="font-bold">Q  •  Lorem congue gravida lorem Lorem congue gravida loremLorem congue grav Lorem congue gravida lorem?</BringzzText>}
                    defaultValue={true}
                >
                    <BringzzText tag="h4" className="font-normal">A  •  Lorem ipsum dolor sit amet, consetetur sadipsc-ing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.</BringzzText>
                </BringzzAccordion>

                <div className='flex flex-col items-center py-12 space-y-4'>
                    <BringzzText tag="h1">Contact Us</BringzzText>
                    <div className='flex space-x-4'>
                        <BringzzButton className="border border-black" icon={{ variant: "IconPhone", folder: "LineIcons", size: "16" }}>Call</BringzzButton>
                        <BringzzButton className="border border-black" icon={{ variant: "IconSpeechBubble16", folder: "LineIcons", size: "16" }}>Chat</BringzzButton>
                    </div>
                </div>
            </div>
        </BringzzPageContainer>

    )
}

export default FaqGeneralPage;