import * as React from "react";
const IconWithdrawMoney = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.9 2H8.1C5.4 2 3.3 4.2 3.3 6.9v20.8c0 2.7 2.2 4.9 4.9 4.9h8.3c-.5.9-1 1.7-1.6 2.6-3.2 4.5-4.9 9.9-4.9 15.5v6.6c0 2.7 2.2 4.9 4.9 4.9h16.7c2.7 0 4.9-2.2 4.9-4.9v-6.6c0-5.2 1.6-10.1 4.6-14.3.8-1.2 1.6-2.4 2.2-3.7h12.8c2.7 0 4.9-2.2 4.9-4.9V6.9C60.7 4.2 58.6 2 55.9 2M39.3 35c-3.2 4.5-4.9 9.9-4.9 15.5v6.6c0 1.6-1.3 2.9-2.9 2.9H14.7c-1.6 0-2.9-1.3-2.9-2.9v-6.6c0-5.2 1.6-10.1 4.6-14.3 1-1.4 1.8-2.8 2.5-4.3v-.1c1.2-2.7 2-5.5 2.3-8.4v-.1c.1-.8.1-1.7.1-2.5v-8.6h22.5v8.6c0 5.1-1.6 10-4.5 14.2m-20-12.7h-2.2c-1.6 0-2.9-1.3-2.9-2.9V15c0-1.6 1.3-2.9 2.9-2.9h2.2v8.6c.1.6 0 1.1 0 1.6m26.5-10.1h2.3c1.6 0 2.9 1.3 2.9 2.9v4.4c0 1.6-1.3 2.9-2.9 2.9h-2.4c0-.5.1-1 .1-1.6v-8.6m12.9 15.4c0 1.6-1.3 2.9-2.9 2.9H44c.8-2 1.3-4.1 1.6-6.2h2.6c2.7 0 4.9-2.2 4.9-4.9V15c0-2.7-2.2-4.9-4.9-4.9h-31c-2.7 0-4.9 2.2-4.9 4.9v4.4c0 2.7 2.2 4.9 4.9 4.9h1.9c-.3 2.1-.9 4.2-1.8 6.2H8.1c-1.6 0-2.9-1.3-2.9-2.9V6.9C5.3 5.3 6.5 4 8.1 4h47.8c1.6 0 2.9 1.3 2.9 2.9v20.7z" />
    <path d="M27.9 30.8c-2.5 0-4.6 2.6-4.6 5.8s2 5.8 4.6 5.8c2.5 0 4.6-2.6 4.6-5.8-.1-3.2-2.1-5.8-4.6-5.8m0 9.7c-1.4 0-2.6-1.8-2.6-3.8s1.2-3.8 2.6-3.8 2.6 1.8 2.6 3.8-1.3 3.8-2.6 3.8M26.8 21.8c.6 0 1-.4 1-1v-3.6c0-.6-.4-1-1-1s-1 .4-1 1v3.6c0 .6.5 1 1 1M28.1 49.7c-.6 0-1 .4-1 1v3.6c0 .6.4 1 1 1s1-.4 1-1v-3.6c0-.6-.5-1-1-1" />
  </svg>
);
export default IconWithdrawMoney;
