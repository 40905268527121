import * as React from "react";
const IconDiscountLabel = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M31.8 33.6c-.6 0-1 .4-1 1v11.6c0 .6.4 1 1 1s1-.4 1-1V34.6c0-.6-.5-1-1-1M24 39.1l-.4-.1c-.5-.1-1.1.3-1.2.8s.3 1.1.8 1.2l.4.1h.2c.5 0 .9-.3 1-.8s-.3-1.1-.8-1.2M40.7 39.4l-.4.1c-.5.1-.9.6-.8 1.2.1.5.5.8 1 .8h.2l.4-.1c.5-.1.9-.6.8-1.2-.1-.5-.6-.9-1.2-.8" />
    <path d="m52.5 37.6-18-18c-1.4-1.4-3.5-1.9-5.4-1.1l-10.8 4.2c-2.2-6.4-.7-13.8 4.4-18.9.4-.4.4-1 0-1.4s-1-.4-1.4 0c-5.7 5.7-7.3 13.9-4.8 21l-.5.1q-.45.15-.6.6l-5 13.1c-.7 1.9-.3 4 1.1 5.4l18 18c1 1 2.3 1.5 3.6 1.5s2.6-.5 3.6-1.5l15.8-15.8c1.9-2 1.9-5.2 0-7.2M51 43.3 35.3 59.1c-1.2 1.2-3.1 1.2-4.3 0l-18-18c-.9-.9-1.1-2.1-.7-3.3l4.9-12.6c1 2.1 2.3 4.1 4 5.8.1.1.1.1.2.1.2.1.3.2.5.2h.4s.1 0 .1-.1l.1-.1c.1-.1.2-.2.2-.3v-.5c0-.1 0-.2-.1-.3s-.1-.2-.2-.3c-1.5-1.5-2.7-3.3-3.6-5.2l10.8-4.2c1.1-.4 2.4-.2 3.3.7l18 18c1.3 1.2 1.3 3.2.1 4.3" />
  </svg>
);
export default IconDiscountLabel;
