import * as React from "react";
const IconDiscover = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M63 29.75v18.368H11.676C44.875 42.44 63 29.75 63 29.75M19.96 28.811v3.323l-2.413-2.5v2.316h-.406v-3.313s2.305 2.392 2.412 2.509V28.81zM21.973 29.198v.843h1.23v.378h-1.23v1.143h1.26v.387h-1.667V28.81h1.667v.388zM26.43 28.81v.388h-.756v2.751h-.416v-2.751h-.746v-.387zM31.93 28.81l-1.23 3.353s-.94-2.335-.997-2.47c-.058.135-1.008 2.47-1.008 2.47l-1.23-3.352h.445l.795 2.247.969-2.383.029-.077.998 2.46.794-2.247zM34.733 28.762c-.901 0-1.647.726-1.647 1.617 0 .892.746 1.618 1.647 1.618.9 0 1.647-.726 1.647-1.617 0-.892-.737-1.618-1.647-1.618m0 2.857a1.24 1.24 0 0 1 0-2.48c.678 0 1.24.562 1.24 1.24 0 .679-.552 1.24-1.24 1.24M38.704 30.554c.455-.058.746-.387.746-.862 0-.59-.378-.881-1.163-.881h-.474v3.138h.406v-1.346h.059l.9 1.327.456.02h.058s-.94-1.318-.988-1.396m-.485-.32v-1.036h.068c.397 0 .756.039.756.514 0 .474-.378.523-.746.523zM43.345 31.95h-.572s-1.21-1.347-1.25-1.396h-.01l-.038.039v1.356h-.416V28.81h.416v1.27l1.27-1.27h.551l-1.482 1.463c.039.049 1.53 1.676 1.53 1.676M59.192 17.254a.705.705 0 0 0-.708.707c0 .388.32.707.708.707s.707-.32.707-.707a.71.71 0 0 0-.707-.707m0 1.288a.58.58 0 0 1-.572-.58.567.567 0 1 1 1.133 0 .58.58 0 0 1-.561.58"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M59.288 18.037a.23.23 0 0 0 .174-.232c0-.155-.106-.242-.31-.242h-.261v.804h.193v-.31l.233.31h.242zm-.165-.116h-.039v-.213h.04c.096 0 .144.039.144.106 0 .068-.048.107-.145.107M56.084 22.436c1.356-.271 2.112-1.201 2.112-2.606 0-1.715-1.191-2.703-3.274-2.703h-2.684v9.174h1.812v-3.69h.232l2.5 3.69H59zm-1.511-1.085h-.523v-2.78h.552c1.124 0 1.734.464 1.734 1.356 0 .89-.61 1.424-1.763 1.424M47.732 18.677v2.034h3.187v1.56h-3.187v2.48h3.313v1.55H45.93v-9.174h5.115v1.55zM45.106 17.127l-3.991 9.407h-.969l-3.923-9.407h1.966l2.47 6.161 2.5-6.161z"
      clipRule="evenodd"
    />
    <path
      
      d="M34.967 25.213a4.815 4.815 0 1 0-6.808-6.809 4.815 4.815 0 0 0 6.808 6.81"
    />
    <path
      
      fillRule="evenodd"
      d="M3.654 17.127H1v9.174h2.635c1.405 0 2.412-.33 3.303-1.056a4.6 4.6 0 0 0 1.676-3.526c0-2.713-2.034-4.592-4.96-4.592m2.103 6.888c-.562.513-1.299.736-2.47.736h-.485v-6.074h.484c1.172 0 1.88.213 2.47.746.63.552.998 1.405.998 2.286 0 .882-.368 1.763-.997 2.306"
      clipRule="evenodd"
    />
    <path  d="M11.251 17.127H9.45v9.174h1.802z" />
    <path
      
      fillRule="evenodd"
      d="M18.322 23.512c0 1.773-1.308 3.012-3.188 3.012-1.375 0-2.373-.542-3.197-1.743l1.163-1.124c.417.804 1.104 1.23 1.967 1.23.804 0 1.404-.552 1.404-1.298 0-.387-.184-.707-.542-.95-.184-.106-.543-.27-1.25-.522-1.695-.6-2.277-1.25-2.277-2.519 0-1.492 1.25-2.625 2.887-2.625a4.13 4.13 0 0 1 2.723 1.017l-.95 1.22c-.465-.513-.91-.736-1.453-.736-.775 0-1.347.436-1.347 1.017 0 .494.32.756 1.405 1.153 2.054.746 2.655 1.405 2.655 2.868M20.755 21.71c0 1.85 1.288 3.157 3.11 3.157.813 0 1.452-.28 2.179-.997v2.102c-.823.387-1.492.533-2.267.533-2.742 0-4.882-2.083-4.882-4.786s2.189-4.805 4.92-4.805c.785 0 1.435.165 2.229.562v2.112c-.756-.727-1.405-1.037-2.267-1.037-1.695 0-3.023 1.395-3.023 3.158"
      clipRule="evenodd"
    />
  </svg>
);
export default IconDiscover;
