import * as React from "react";
const IconShipCargo = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M34.4 57.5c-.2-.2-.4-.3-.6-.4l3.9-13.8c.6-2.2-.5-4.6-2.7-5.5l-2.7-1.1v-4.1c0-.6-.4-1-1-1s-1 .4-1 1v3.3l-8.2-3.4c-1.1-.5-2.4-.5-3.5 0l-8.3 3.4v-6.2c0-1.4 1.2-2.6 2.6-2.6h11.7c.6 0 1-.4 1-1s-.4-1-1-1H14.5v-5c0-1.4 1.2-2.6 2.6-2.6H24c.6 0 1-.4 1-1s-.4-1-1-1h-2.8v-4.4c0-.6-.4-1-1-1s-1 .4-1 1v4.4h-2.1c-2.5 0-4.6 2.1-4.6 4.6v5c-2.4.2-4.3 2.1-4.3 4.6v7l-2.7 1.1c-2.2.9-3.3 3.3-2.7 5.5l4 13.7c-.2.1-.4.3-.6.4L3 60.2c-.4.4-.5 1-.1 1.4s1 .5 1.4.1L7.5 59c1-.8 2.4-.8 3.4 0l.8.7c1.7 1.5 4.3 1.5 6 0l.8-.7c1-.8 2.4-.8 3.4 0l.8.7c1.7 1.5 4.3 1.5 6 0l.8-.7c1-.8 2.4-.8 3.4 0l3.2 2.8c.2.2.4.2.7.2s.6-.1.8-.3c.4-.4.3-1-.1-1.4zm-6 0-.8.7c-1 .8-2.4.8-3.4 0l-.8-.7c-1.7-1.5-4.3-1.5-6 0l-.8.7c-1 .8-2.4.8-3.4 0l-.8-.7c-1-.9-2.3-1.2-3.5-1.1L4.8 42.7c-.4-1.3.3-2.6 1.5-3.1l3.3-1.4h.1l9.6-4c.6-.3 1.4-.3 2 0l9.6 4h.1l3.3 1.4c1.2.5 1.9 1.8 1.5 3.1l-3.9 13.7c-1.3-.1-2.5.2-3.5 1.1M45.9 2c-8.5 0-15.5 7-15.5 15.5S37.4 33 45.9 33s15.5-7 15.5-15.5S54.5 2 45.9 2m0 29c-7.4 0-13.5-6.1-13.5-13.5S38.5 4 45.9 4s13.5 6.1 13.5 13.5S53.3 31 45.9 31" />
    <path d="M43.5 11.5h-3.7c-.6 0-1 .4-1 1s.4 1 1 1h2.7v3.8h-2.7c-.6 0-1 .4-1 1v5.8c0 .6.4 1 1 1h3.7c.6 0 1-.4 1-1s-.4-1-1-1h-2.7v-3.8h2.7c.6 0 1-.4 1-1v-5.8c0-.6-.5-1-1-1M53.5 11.5c-.6 0-1 .4-1 1v4.8h-3.4v-4.8c0-.6-.4-1-1-1s-1 .4-1 1v5.8c0 .6.4 1 1 1h4.4v4.3c0 .6.4 1 1 1s1-.4 1-1V19c0-.1 0-.2-.1-.4 0-.1.1-.2.1-.4v-5.8c0-.5-.4-.9-1-.9" />
  </svg>
);
export default IconShipCargo;
