import * as React from "react";
const IconFolderAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.7 13.7H32.4l-2.8-8c-.7-2.1-2.7-3.6-5-3.6H7.3C4.4 2.1 2 4.5 2 7.4v49.2c0 2.9 2.4 5.3 5.3 5.3h49.4c2.9 0 5.3-2.4 5.3-5.3V18.9c0-2.9-2.4-5.2-5.3-5.2M7.3 4.1h17.3c1.4 0 2.6.9 3.1 2.2l3 8.7c.1.4.5.7.9.7h25.1c1.8 0 3.3 1.5 3.3 3.3v7.8H4V7.4c0-1.8 1.5-3.3 3.3-3.3m49.4 55.8H7.3c-1.8 0-3.3-1.5-3.3-3.3V28.8h56v27.8c0 1.8-1.5 3.3-3.3 3.3" />
  </svg>
);
export default IconFolderAlt1;
