import * as React from "react";
const IconLift = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54 11.1h-1.7v-.8C52.3 5.7 48.6 2 44 2H20.1c-4.6 0-8.3 3.7-8.3 8.3v.8H10c-2.2 0-4 1.8-4 4V58c0 2.2 1.8 4 4 4h44c2.2 0 4-1.8 4-4V15.1c0-2.2-1.8-4-4-4m-40.3-.8C13.7 6.8 16.5 4 20 4h23.8c3.5 0 6.3 2.8 6.3 6.3v.8H13.7zM18 60V26.5c0-1.1.9-2 2-2h11V60zm15 0V24.5h11c1.1 0 2 .9 2 2V60zm23-2c0 1.1-.9 2-2 2h-6V26.5c0-2.2-1.8-4-4-4H20c-2.2 0-4 1.8-4 4V60h-6c-1.1 0-2-.9-2-2V15.1c0-1.1.9-2 2-2h44c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconLift;
