import * as React from "react";
const IconLayersAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m59.7 39.3-4-2.5c-.5-.3-1.1-.2-1.4.3s-.2 1.1.3 1.4l4 2.5c.1.1.2.2.2.3s0 .2-.2.3L32.2 58.2c-.1.1-.3.1-.4 0L5.4 41.6c-.1-.1-.2-.2-.2-.3s0-.2.2-.3l4-2.5c.5-.3.6-.9.3-1.4s-.9-.6-1.4-.3l-4 2.5c-.7.4-1.1 1.2-1.1 2s.4 1.6 1.1 2l26.4 16.6c.4.2.8.4 1.3.4.4 0 .9-.1 1.3-.4l26.4-16.6c.7-.4 1.1-1.2 1.1-2s-.4-1.6-1.1-2" />
    <path d="m26.8 39.1 4.7 2.9c.2.1.3.2.5.2s.4-.1.5-.2l4.7-2.9c.5-.3.6-.9.3-1.4s-.9-.6-1.4-.3L32 40l-4.1-2.6c-.5-.3-1.1-.2-1.4.3s-.2 1.1.3 1.4M13.5 30.7l7 4.4c.2.1.3.2.5.2.3 0 .7-.2.8-.5.3-.5.2-1.1-.3-1.4l-7-4.4c-.5-.3-1.1-.2-1.4.3-.2.5-.1 1.2.4 1.4M7.7 26.9c.3 0 .7-.2.8-.5.3-.5.2-1.1-.3-1.4l-3.3-2 3.3-2.1c.5-.3.6-.9.3-1.4s-.9-.6-1.4-.3l-4.7 2.9c-.2.2-.4.5-.4.9s.2.7.5.8l4.7 2.9c.1.2.3.2.5.2M14 17c.2 0 .4 0 .5-.2l7-4.4c.5-.3.6-.9.3-1.4s-.9-.6-1.4-.3l-7 4.4c-.5.3-.6.9-.3 1.4.3.4.6.5.9.5M27.9 8.5 32 5.9l4.1 2.6c.2.1.3.2.5.2.3 0 .7-.2.8-.5.3-.5.2-1.1-.3-1.4l-4.7-2.9c-.3-.2-.7-.2-1.1 0l-4.7 2.9c-.5.3-.6.9-.3 1.4.5.5 1.1.6 1.6.3M42.5 12.5l7 4.4c.1.1.3.1.5.1.3 0 .7-.2.8-.5.3-.5.2-1.1-.3-1.4l-7-4.4c-.5-.3-1.1-.2-1.4.3-.2.6-.1 1.2.4 1.5M61.5 22.1l-4.7-2.9c-.5-.3-1.1-.2-1.4.3s-.2 1.1.3 1.4L59 23l-3.3 2.1c-.5.3-.6.9-.3 1.4.2.3.5.5.8.5.2 0 .4 0 .5-.2l4.7-2.9c.3-.2.5-.5.5-.8s-.1-.8-.4-1M43 35.3c.2 0 .4 0 .5-.2l7-4.4c.5-.3.6-.9.3-1.4s-.9-.6-1.4-.3l-7 4.4c-.5.3-.6.9-.3 1.4.3.3.6.5.9.5" />
  </svg>
);
export default IconLayersAlt1;
