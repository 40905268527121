import * as React from "react";
const IconAndroidCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <circle cx={26.6} cy={12.6} r={1.7} />
    <circle cx={36.9} cy={12.6} r={1.7} />
    <path d="M54.6 18.8c-1.3 0-2.5.5-3.5 1.2v-1.9c-1.3-4.1-3.8-7.5-7.1-9.9l5.4-5.6c.4-.4.4-1 0-1.4s-1-.4-1.4 0L42.3 7c-3-1.8-6.6-2.9-10.3-2.9-3.6 0-7.1 1-10.1 2.7l-5.5-5.7c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l5.2 5.4c-3.4 2.4-5.9 5.9-7.2 10.1 0 .2-.1.3 0 .5v1c-.9-.8-2.1-1.2-3.5-1.2-3 0-5.5 2.5-5.5 5.5v11.4c0 3 2.5 5.5 5.5 5.5 1.3 0 2.5-.5 3.5-1.2v8.7c0 .8.6 1.4 1.4 1.4H17v8c0 3 2.5 5.5 5.5 5.5s5.5-2.5 5.5-5.5v-8h7.7v8c0 3 2.5 5.5 5.5 5.5s5.5-2.5 5.5-5.5v-8h3.2c.8 0 1.4-.6 1.4-1.4V40c.9.8 2.1 1.2 3.5 1.2 3 0 5.5-2.5 5.5-5.5V24.3c-.3-3-2.7-5.5-5.7-5.5M22.1 9.2c.1 0 .3-.1.4-.2 2.8-1.7 6-2.7 9.5-2.7 7.4 0 13.9 4.5 16.7 11.2H15.3c1.4-3.5 3.8-6.3 6.8-8.3M9.4 38.7c-1.9 0-3.5-1.6-3.5-3.5V23.9c0-1.9 1.6-3.5 3.5-3.5s3.5 1.6 3.5 3.5v11.4c0 1.8-1.6 3.4-3.5 3.4m16.4 18.8c0 1.9-1.6 3.5-3.5 3.5s-3.5-1.6-3.5-3.5v-8h6.9v8zm18.6 0c0 1.9-1.6 3.5-3.5 3.5s-3.5-1.6-3.5-3.5v-8h6.9v8zm4.7-10H14.9v-28h34.2v28M58 35.7c0 1.9-1.6 3.5-3.5 3.5S51 37.6 51 35.7V24.3c0-1.9 1.6-3.5 3.5-3.5s3.5 1.6 3.5 3.5z" />
  </svg>
);
export default IconAndroidCopy;
