import * as React from "react";
const IconTextAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M52.8 2H11.2c-3 0-5.3 2.4-5.3 5.3V13c0 .6.4 1 1 1s1-.4 1-1V7.3C7.9 5.5 9.3 4 11.2 4h14.3v56h-7.3c-.6 0-1 .4-1 1s.4 1 1 1h27.7c.6 0 1-.4 1-1s-.4-1-1-1h-7.3V4h14.3c1.8 0 3.3 1.5 3.3 3.3V13c0 .6.4 1 1 1s1-.4 1-1V7.3C58.1 4.4 55.8 2 52.8 2M36.6 60h-9.1V4h9.1z" />
  </svg>
);
export default IconTextAlt;
