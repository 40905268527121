import * as React from "react";
const IconSmartphoneStat = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M40.3 37.4H24.7v-6.6l4.5-2 5.7 2.8c.1.1.3.1.4.1s.3 0 .4-.1l4.8-2.2c.5-.2.7-.8.5-1.3s-.8-.7-1.3-.5l-4.4 2-5.7-2.8c-.3-.1-.6-.1-.8 0l-4.1 1.8v-6.8c0-.6-.4-1-1-1s-1 .4-1 1v16.6c0 .6.4 1 1 1h16.5c.6 0 1-.4 1-1s-.4-1-.9-1" />
    <path d="M45.8 2H18.2c-2.2 0-4 1.8-4 4v52c0 2.2 1.8 4 4 4h27.7c2.2 0 4-1.8 4-4V6c-.1-2.2-1.8-4-4.1-4m2 56c0 1.1-.9 2-2 2H18.2c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2h27.7c1.1 0 2 .9 2 2v52z" />
    <path d="M36.3 52.7h-8.6c-.6 0-1 .4-1 1s.4 1 1 1h8.6c.6 0 1-.4 1-1s-.4-1-1-1M31.2 11.3h1.6c.6 0 1-.4 1-1s-.4-1-1-1h-1.6c-.6 0-1 .4-1 1s.4 1 1 1" />
  </svg>
);
export default IconSmartphoneStat;
