import React from 'react';
import { useNavigation } from '../../context/NavigationContext';
import { BringzzPageContainer, BringzzIcon, BringzzText, BringzzProductScan } from "@bringzz/components"
import ProductExample from 'assets/images/ProductExample.png';

const DeliveryHandoverShipping9 = () => {
    const { backPress } = useNavigation();

    return (
        <BringzzPageContainer
            header={{
                left: <BringzzIcon
                    size="22"
                    icon="IconChevronLeft"
                    folder="LineIcons"
                    className="cursor-pointer"
                    onClick={backPress}
                />,
                title: <BringzzText tag='h2'>Scan all products</BringzzText>,
            }}>

            <div className='flex flex-col items-center px-4 space-y-8 mt-4'>
                <BringzzProductScan image={ProductExample} manufacturer={"Manufacturer"} label={"Mucosolvan"} quantity={10} productQuantity={"12 Stk."} volume={"30mg"} productType={'Tabletten'} productId='4150005717482' />
                <BringzzProductScan image={ProductExample} manufacturer={"Manufacturer"} label={"Mucosolvan"} quantity={10} productQuantity={"12 Stk."} volume={"30mg"} productType={'Tabletten'} productId='4150005717482' />
                <BringzzProductScan image={ProductExample} manufacturer={"Manufacturer"} label={"Mucosolvan"} quantity={10} productQuantity={"12 Stk."} volume={"30mg"} productType={'Tabletten'} productId='4150005717482' />
            </div>
        </BringzzPageContainer>

    )
}

export default DeliveryHandoverShipping9;