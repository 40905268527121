import * as React from "react";
const IconChurchAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.2 37.1h-9.9V29c0-1.7-.8-3.2-2.2-4.1L33 17.2v-6.1h3.3c.6 0 1-.4 1-1s-.4-1-1-1H33V5.5c0-.6-.4-1-1-1s-1 .4-1 1v3.7h-3.3c-.6 0-1 .4-1 1s.4 1 1 1H31v6.1L18.9 25c-1.4.9-2.2 2.4-2.2 4.1v8.1H6.8C4.2 37.1 2 39.3 2 42v12.7c0 2.7 2.2 4.8 4.8 4.8h50.4c2.7 0 4.8-2.2 4.8-4.8V42c0-2.7-2.2-4.9-4.8-4.9M6.8 57.5c-1.6 0-2.8-1.3-2.8-2.8V42c0-1.6 1.3-2.8 2.8-2.8h9.9v18.3zm38.5 0H18.7V29c0-1 .5-1.9 1.3-2.4L32 19l12 7.6c.8.5 1.3 1.4 1.3 2.4zM60 54.6c0 1.6-1.3 2.8-2.8 2.8h-9.9V39.1h9.9c1.6 0 2.8 1.3 2.8 2.8z" />
  </svg>
);
export default IconChurchAlt;
