import * as React from "react";
const IconRing = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m37 15.7 3.3-5.7c.2-.3.2-.7 0-1l-3.7-6.5c-.2-.3-.5-.5-.9-.5h-7.5c-.4 0-.7.2-.9.5L23.7 9c-.2.3-.2.7 0 1l3.3 5.7C16.5 18 8.6 27.4 8.6 38.6 8.6 51.5 19.1 62 32 62s23.4-10.5 23.4-23.4c0-11.2-7.9-20.6-18.4-22.9M28.9 4h6.3l3.1 5.5-3.1 5.5h-6.3l-3.1-5.5zM32 60c-11.8 0-21.4-9.6-21.4-21.4S20.2 17.2 32 17.2s21.4 9.6 21.4 21.4S43.8 60 32 60" />
  </svg>
);
export default IconRing;
