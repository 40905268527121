import * as React from "react";
const IconSwatches = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M43.2 2H20.8c-2.8 0-5 2.2-5 5v50c0 2.8 2.2 5 5 5h22.5c2.8 0 5-2.2 5-5V7c-.1-2.8-2.3-5-5.1-5M20.8 4h22.5c1.7 0 3 1.3 3 3v7.8H17.8V7c0-1.7 1.3-3 3-3m-3 23.6V16.8h28.5v10.8zm28.4 2v10.8H17.8V29.6zm-3 30.4H20.8c-1.7 0-3-1.3-3-3V42.3h28.5V57c-.1 1.7-1.4 3-3.1 3" />
    <path d="M32 47c-2.4 0-4.3 1.9-4.3 4.3s1.9 4.3 4.3 4.3 4.3-1.9 4.3-4.3S34.4 47 32 47m0 6.6c-1.3 0-2.3-1-2.3-2.3s1-2.3 2.3-2.3 2.3 1 2.3 2.3c0 1.2-1 2.3-2.3 2.3" />
  </svg>
);
export default IconSwatches;
