import * as React from "react";
const IconBlockUser = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M28.4 26.5c7 0 12.7-5.5 12.7-12.3S35.4 2 28.4 2 15.7 7.5 15.7 14.3s5.7 12.2 12.7 12.2m0-22.5c5.9 0 10.7 4.6 10.7 10.3s-4.8 10.3-10.7 10.3S17.7 20 17.7 14.3 22.5 4 28.4 4M28.4 33.2C15.8 33.2 5.6 43.4 5.6 56v3c0 1.6 1.3 3 3 3h19c.6 0 1-.4 1-1s-.4-1-1-1h-19c-.5 0-1-.4-1-1v-3c0-11.5 9.3-20.8 20.8-20.8.6 0 1-.4 1-1s-.4-1-1-1M46.1 37.4c-6.8 0-12.3 5.5-12.3 12.3S39.3 62 46.1 62s12.3-5.5 12.3-12.3-5.5-12.3-12.3-12.3m0 2c2.7 0 5.2 1.1 7 2.8l-15 13.9c-1.4-1.8-2.3-4-2.3-6.4 0-5.7 4.6-10.3 10.3-10.3m0 20.6c-2.5 0-4.8-.9-6.6-2.4l15-13.9c1.2 1.7 1.9 3.8 1.9 6 0 5.7-4.6 10.3-10.3 10.3" />
  </svg>
);
export default IconBlockUser;
