import * as React from "react";
const IconFingerprint3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 65 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M20.8 33.5c-.5-.1-1 .3-1.1.9-.3 2.4-.8 4.7-1.5 7-1.2 3.7-2.9 7.3-5 10.6l-.2.3c-.3.5-.2 1.1.3 1.4.2.1.3.2.5.2.3 0 .7-.2.8-.5l.2-.3c2.3-3.4 4-7.2 5.3-11.1.8-2.4 1.3-4.9 1.6-7.4 0-.6-.4-1.1-.9-1.1M11.8 15.7c-.5-.3-1.1-.1-1.4.3-2.4 4-3.7 8.5-3.7 13.1s-1.1 9.1-3.2 13.2c-.3.5-.1 1.1.4 1.3.1.1.3.1.5.1.4 0 .7-.2.9-.5 2.3-4.4 3.4-9.2 3.4-14.1 0-4.2 1.2-8.4 3.4-12 .4-.5.2-1.1-.3-1.4M29.2 51.8c-.5-.2-1.1 0-1.3.5q-1.5 3.3-3.6 6.3c-.3.5-.2 1.1.3 1.4.2.1.4.2.6.2.3 0 .6-.2.8-.4 1.4-2.1 2.6-4.3 3.7-6.5.2-.6 0-1.2-.5-1.5M57.8 16c-2.4-4-5.9-7.3-10-9.5C43.7 4.2 38.9 3 34.1 3s-9.5 1.2-13.7 3.5c-.5.3-.7.9-.4 1.4s.9.7 1.4.4C25.3 6.1 29.7 5 34.1 5c4.5 0 8.8 1.1 12.7 3.2 3.8 2.1 7.1 5.2 9.3 8.8 2.2 3.7 3.4 7.8 3.4 12 0 7.5-1.1 14.9-3.2 22.1-.2.5.1 1.1.7 1.2h.3c.4 0 .8-.3 1-.7 2.2-7.3 3.3-15 3.3-22.7-.1-4.4-1.4-8.9-3.8-12.9" />
    <path d="M34.1 15.6c-3.8 0-7.3 1.4-10 4-1.2 1.1-2.1 2.4-2.8 3.8-.2.5 0 1.1.5 1.3s1.1 0 1.3-.5q.9-1.8 2.4-3.3c2.3-2.2 5.4-3.4 8.6-3.4s6.4 1.2 8.6 3.4c2.3 2.2 3.5 5.1 3.5 8.2s-.2 6.2-.7 9.3c-.1.5.3 1.1.8 1.1h.1c.5 0 .9-.4 1-.9.4-3.2.7-6.4.7-9.6 0-3.6-1.5-7.1-4.2-9.6-2.4-2.4-6-3.8-9.8-3.8" />
    <path d="M34.1 28.1c-.6 0-1 .4-1 1 0 4.2-.5 8.3-1.4 12.3-.1.5.2 1.1.7 1.2h.2c.5 0 .9-.3 1-.8 1-4.2 1.5-8.5 1.5-12.8 0-.4-.4-.9-1-.9M44.9 47c-.5-.2-1.1.1-1.2.7-1.2 4.1-2.8 8.1-4.8 11.9-.3.5-.1 1.1.4 1.4.1.1.3.1.5.1.4 0 .7-.2.9-.5 2.1-3.9 3.7-8 5-12.2 0-.7-.3-1.3-.8-1.4" />
  </svg>
);
export default IconFingerprint3;
