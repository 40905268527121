import * as React from "react";
const IconMoonAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M7.7 29.5H3c-.6 0-1 .4-1 1s.4 1 1 1h4.7c.6 0 1-.4 1-1s-.5-1-1-1M61 29.5H48.9c-.6 0-1 .4-1 1s.4 1 1 1H61c.6 0 1-.4 1-1s-.4-1-1-1M53.4 43.1H10.6c-.6 0-1 .4-1 1s.4 1 1 1h42.9c.6 0 1-.4 1-1s-.5-1-1.1-1M44.9 58.3H19.1c-.6 0-1 .4-1 1s.4 1 1 1h25.7c.6 0 1-.4 1-1s-.4-1-.9-1M29 33.8c1.3 0 2.6-.2 3.9-.5 2.2-.6 4.2-1.6 5.9-3.1.3-.3.4-.6.3-1s-.4-.7-.8-.7c-4.9-.9-8.9-4.7-10.2-9.5s.3-10 4.2-13.3c.3-.3.4-.6.3-1s-.4-.7-.8-.7q-3.3-.6-6.6.3c-8 2.1-12.8 10.4-10.7 18.4 1.8 6.7 7.9 11.1 14.5 11.1M25.7 6.2c1.1-.3 2.3-.4 3.4-.4h.4c-3.3 3.7-4.5 9-3.2 13.8s5 8.8 9.7 10.4c-1.1.7-2.2 1.1-3.5 1.5-7 1.9-14.1-2.3-16-9.3s2.2-14.2 9.2-16" />
  </svg>
);
export default IconMoonAlt;
