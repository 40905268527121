import * as React from "react";
const IconTrophy5 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M52.1 60h-1.4V47.1c0-.6-.4-1-1-1h-1.9V7.3c0-1.6-.7-3.1-2-4.1s-2.9-1.4-4.4-1L20.3 6.9c-2.4.5-4.1 2.7-4.1 5.1v34.1h-1.9c-.6 0-1 .4-1 1V60h-1.4c-.6 0-1 .4-1 1s.4 1 1 1h40.2c.6 0 1-.4 1-1s-.4-1-1-1M18.2 12c0-1.5 1-2.8 2.5-3.2L41.8 4c1-.2 2 0 2.8.6s1.2 1.5 1.2 2.5V46H18.2zm-2.9 48V48.1h33.4V60z" />
    <path d="m39 19.6-4-1-2.2-3.5c-.2-.3-.5-.5-.8-.5s-.7.2-.8.5L29 18.6l-4 1c-.3.1-.6.3-.7.7s0 .7.2 1l2.6 3.2-.3 4.1c0 .3.1.7.4.9.2.1.4.2.6.2.1 0 .3 0 .4-.1l3.8-1.5 3.8 1.5c.3.1.7.1 1-.1s.4-.5.4-.9l-.3-4.1 2.6-3.2c.2-.3.3-.6.2-1s-.3-.6-.7-.7m-3.9 3.8c-.2.2-.3.5-.2.7l.2 2.9-2.7-1.1c-.1 0-.2-.1-.4-.1s-.3 0-.4.1L28.9 27l.2-2.9c0-.3-.1-.5-.2-.7L27 21.2l2.8-.7c.3-.1.5-.2.6-.4l1.5-2.5 1.5 2.5c.1.2.4.4.6.4l2.8.7z" />
  </svg>
);
export default IconTrophy5;
