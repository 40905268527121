import * as React from "react";
const IconSteak = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M46.8 22.2h-4.2c-3.4 0-6.5-1.7-8.2-4.6-2.4-3.9-7-6.4-11.7-6.3C11.3 11.3 2 20.6 2 32c0 11.2 9.5 20.7 20.7 20.7h24.1c8.4 0 15.2-6.8 15.2-15.2s-6.8-15.3-15.2-15.3m0 2c2.2 0 4.3.6 6.1 1.5l-11.3 25H29.8l12.5-26.5h4.5M31 16.7l-6.2 12.7c-.3-3.4-3.2-6-6.6-6-.3 0-.5 0-.8.1l5.1-10h.4c3-.2 6 1.1 8.1 3.2m-12.8 8.6c2.6 0 4.7 2.1 4.7 4.7s-2.1 4.7-4.7 4.7-4.7-2.1-4.7-4.7 2.1-4.7 4.7-4.7m1.9-11.8-5.5 10.8c-1.9 1.2-3.1 3.3-3.1 5.6v.5L6.3 40.8C4.8 38.2 4 35.2 4 32c0-9.4 7-17.2 16.1-18.5M7.6 42.8l4.8-9.5c1.1 2 3.3 3.4 5.8 3.4 1.3 0 2.6-.4 3.6-1.1l-6.5 13.5c-3.1-1.4-5.8-3.6-7.7-6.3m9.5 7 15.3-31.4c.1.1.2.2.2.3 1.7 2.8 4.4 4.6 7.5 5.3L27.6 50.7h-4.9c-1.9 0-3.8-.3-5.6-.9m29.7.9h-3l10.9-23.8c3.3 2.4 5.4 6.3 5.4 10.6-.1 7.2-6 13.2-13.3 13.2" />
  </svg>
);
export default IconSteak;
