import * as React from "react";
const IconUnderwear = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.1 2H7.9c-3 0-5.5 2.5-5.5 5.5v49.1c0 3 2.5 5.5 5.5 5.5h15.7c2.7 0 4.9-1.9 5.4-4.6l3-18.3 3.1 18.3c.4 2.6 2.7 4.6 5.4 4.6h15.7c3 0 5.5-2.5 5.5-5.5V7.5c-.1-3-2.5-5.5-5.6-5.5M7.9 4h48.3c1.9 0 3.5 1.6 3.5 3.5v7.3H4.4V7.5C4.4 5.6 5.9 4 7.9 4m48.2 56H40.5c-1.7 0-3.1-1.2-3.4-2.9L33 33c-.1-.5-.5-.8-1-.8s-.9.4-1 .8l-4.1 24.1c-.3 1.7-1.7 2.9-3.4 2.9H7.9c-1.9 0-3.5-1.6-3.5-3.5V16.7h55.2v39.8c0 1.9-1.5 3.5-3.5 3.5" />
  </svg>
);
export default IconUnderwear;
