import * as React from "react";
const IconHelicopter = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60 22.1c-1.2 0-2.2 1-2.2 2.2v.3h-21v-2c0-1.7-1.4-3.1-3.1-3.1H24v-5.7h11.9c1.2 0 2.2-1 2.2-2.2s-1-2.2-2.2-2.2H7.5c-1.2 0-2.2 1-2.2 2.2s1 2.2 2.2 2.2h11.9v5.7H10c-4.5 0-8.2 3.7-8.2 8.2v13.6c0 1.7 1.4 3.1 3.1 3.1h4.6v5.7H7c-.1-1.1-1.1-2-2.2-2-1.2 0-2.2 1-2.2 2.2 0 2.4 2 4.3 4.5 4.3H32c1.2 0 2.2-1 2.2-2.2s-1-2.2-2.2-2.2h-5.4v-5.7h7.1c1.7 0 3.1-1.4 3.1-3.1v-2l21-2.3c0 1.2 1 2.2 2.2 2.2s2.2-1 2.2-2.2V24.4c0-1.3-1-2.3-2.2-2.3m-37.9 28h-8.2v-5.7h8.2zm10.2-10.2h-26V27.8c0-2.1 1.7-3.7 3.7-3.7h22.3zm4.5-5.1v-5.7h21v3.4z" />
  </svg>
);
export default IconHelicopter;
