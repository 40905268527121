import * as React from "react";
const IconPlantPotAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 31h-6.6V20.5c0-5.9-4.8-10.6-10.6-10.6h-.9V7.1c0-2.8-2.3-5.1-5.1-5.1h-3.3c-2.8 0-5.1 2.3-5.1 5.1v2.7h-.9c-5.9 0-10.6 4.8-10.6 10.6v22.2c-1.6-.2-3.1-.2-5-.2H7.2c-.6 0-1 .4-1 1v3.4c0 8.3 8.1 15 18 15h13.7C48.9 62 58 52.9 58 41.7V32c0-.5-.5-1-1-1M27.3 7.1c0-1.7 1.4-3.1 3.1-3.1h3.3c1.7 0 3.1 1.4 3.1 3.1v2.7h-9.5zM15.7 20.5c0-4.8 3.9-8.6 8.6-8.6h15.4c4.8 0 8.6 3.9 8.6 8.6V31h-.8c-11 0-20 8.8-20.3 19.7-2.1-3.5-5.9-6.2-10.5-7.5-.3-.1-.7-.2-1-.2zM8 46.9v-2.4h.7c3.1 0 4.9 0 7.5.7 6.6 1.8 11 6.8 11 12.4V60h-3.1C15.2 60 8 54.1 8 46.9m48-5.2C56 51.8 47.8 60 37.7 60h-8.5v-8.7c0-10.1 8.2-18.3 18.3-18.3H56z" />
  </svg>
);
export default IconPlantPotAlt;
