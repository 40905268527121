import * as React from "react";
const IconSpinner4 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2c-2.7 0-4.9 2.2-4.9 4.9s2.2 4.9 4.9 4.9 4.9-2.2 4.9-4.9S34.7 2 32 2m0 7.9c-1.6 0-2.9-1.3-2.9-2.9s1.3-3 2.9-3 2.9 1.3 2.9 2.9-1.3 3-2.9 3M32 52.1c-2.7 0-4.9 2.2-4.9 4.9s2.2 5 4.9 5 4.9-2.2 4.9-4.9-2.2-5-4.9-5m0 7.9c-1.6 0-2.9-1.3-2.9-2.9s1.3-2.9 2.9-2.9 2.9 1.3 2.9 2.9S33.6 60 32 60M57.1 27.1c-2.7 0-4.9 2.2-4.9 4.9s2.2 4.9 4.9 4.9S62 34.7 62 32s-2.2-4.9-4.9-4.9m0 7.8c-1.6 0-2.9-1.3-2.9-2.9s1.3-2.9 2.9-2.9S60 30.4 60 32s-1.3 2.9-2.9 2.9M11.9 32c0-2.7-2.2-4.9-4.9-4.9S2 29.3 2 32s2.2 4.9 4.9 4.9 5-2.2 5-4.9m-5 2.9C5.3 34.9 4 33.6 4 32s1.3-2.9 2.9-2.9 2.9 1.3 2.9 2.9c.1 1.6-1.3 2.9-2.9 2.9M49.7 19.2c1.3 0 2.6-.5 3.5-1.4s1.4-2.2 1.4-3.5-.5-2.6-1.4-3.5-2.2-1.4-3.5-1.4-2.6.5-3.5 1.4-1.4 2.2-1.4 3.5.5 2.6 1.4 3.5 2.2 1.4 3.5 1.4m-2-7q.9-.9 2.1-.9c1.2 0 1.5.3 2.1.9q.9.9.9 2.1t-.9 2.1c-1.1 1.1-3 1.1-4.1 0q-.9-.9-.9-2.1c-.1-.8.2-1.5.8-2.1M14.3 44.8c-1.3 0-2.6.5-3.5 1.4s-1.4 2.2-1.4 3.5.5 2.6 1.4 3.5 2.2 1.4 3.5 1.4 2.6-.5 3.5-1.4 1.4-2.2 1.4-3.5-.5-2.6-1.4-3.5-2.2-1.4-3.5-1.4m2 7c-1.1 1.1-3 1.1-4.1 0q-.9-.9-.9-2.1c0-1.2.3-1.5.9-2.1q.9-.9 2.1-.9t2.1.9.9 2.1c0 1.2-.4 1.5-1 2.1M49.7 44.8c-1.3 0-2.6.5-3.5 1.4s-1.4 2.2-1.4 3.5.5 2.6 1.4 3.5 2.2 1.4 3.5 1.4 2.6-.5 3.5-1.4 1.4-2.2 1.4-3.5-.5-2.6-1.4-3.5-2.2-1.4-3.5-1.4m2.1 7c-1.1 1.1-3 1.1-4.1 0q-.9-.9-.9-2.1c0-1.2.3-1.5.9-2.1s1.3-.9 2.1-.9 1.5.3 2.1.9.9 1.3.9 2.1-.4 1.5-1 2.1M14.3 9.3c-1.3 0-2.6.5-3.5 1.4s-1.4 2.2-1.4 3.5.5 2.6 1.4 3.5 2.2 1.4 3.5 1.4 2.6-.5 3.5-1.4 1.4-2.2 1.4-3.5-.5-2.6-1.4-3.5c-1-.8-2.2-1.4-3.5-1.4m2 7c-1.1 1.1-3 1.1-4.1 0q-.9-.9-.9-2.1c0-1.2.3-1.5.9-2.1s1.3-.9 2.1-.9 1.5.3 2.1.9.9 1.3.9 2.1-.4 1.6-1 2.1" />
  </svg>
);
export default IconSpinner4;
