import * as React from "react";
const IconSurfboardAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.4 4.4-.3-.9c-.1-.3-.4-.6-.7-.7l-.9-.3c-.2-.1-4.3-1.2-10.8 0-3.7.7-7.4 2.1-11 4.1-4.5 2.4-8.9 5.8-13.1 10l-.4.4C15.5 26 10 34.9 7 40.7c-3.3 6.5-4.5 10.9-4.6 11.1L2 53.4c-.1.5.1 1 .7 1.2l5.1 1.7 1.7 5.1c.1.3.5.6.9.6h.3l1.5-.4c.2 0 4.6-1.2 11.1-4.6 5.8-3 14.6-8.4 23.5-17.1.2-.2.4-.3.5-.5 4.2-4.2 7.6-8.6 10-13.1 2-3.6 3.3-7.4 4.1-11 1.3-6.6.1-10.7 0-10.9M34.3 34c-.3-2.3-2.1-4.1-4.4-4.4l-12.4-1.7q3.3-4.35 7.8-9l15.4 1.5c1.5.1 2.7 1.3 2.8 2.8L45 38.8q-4.65 4.5-9 7.8zM22.4 55.2c-6.3 3.3-10.5 4.4-10.7 4.4l-.7.2-1.5-4.6q-.15-.45-.6-.6L4.2 53l.2-.6c0-.2 1.2-4.4 4.4-10.7 1.6-3.2 4-7.3 7.3-11.9l13.4 1.9c1.4.2 2.5 1.3 2.7 2.7L34.1 48c-4.4 3.2-8.5 5.5-11.7 7.2m37.1-40.4c-.7 3.5-2 7-3.9 10.4-2.2 4-5.1 7.9-8.8 11.7L45.5 23c-.2-2.4-2.2-4.4-4.6-4.6l-13.7-1.3c3.8-3.6 7.7-6.6 11.7-8.7 3.5-1.9 7-3.2 10.4-3.9 4.4-.9 7.6-.5 9-.2l-4.4 4.4c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l4.4-4.4c.3 1.4.6 4.6-.2 9.1" />
  </svg>
);
export default IconSurfboardAlt;
