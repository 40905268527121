import * as React from "react";
const IconRemoveGroup = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.6 28.9c-1.5-1.8-3.4-3-5.5-3.6 2.1-1 3.6-3.2 3.6-5.7 0-3.5-2.8-6.3-6.3-6.3S44 16.1 44 19.6c0 2.5 1.5 4.7 3.6 5.7-2.1.6-4 1.8-5.5 3.6-.1.1-.2.2-.2.3-1.9-2.3-4.4-3.8-7.1-4.4 2.8-1.1 4.8-3.8 4.8-7 0-4.1-3.4-7.5-7.5-7.5s-7.5 3.4-7.5 7.5c0 3.2 2 5.9 4.8 7-2.7.6-5.2 2.1-7.1 4.4-.1-.1-.1-.2-.2-.3-1.5-1.8-3.4-3-5.5-3.6 2.1-1 3.6-3.2 3.6-5.7 0-3.5-2.8-6.3-6.3-6.3s-6.3 2.8-6.3 6.3c0 2.5 1.5 4.7 3.6 5.7-2.1.6-4 1.8-5.5 3.6-2.1 2.5-3.3 6-3.4 9.8 0 .4.2.7.5.9 1.3.7 6 2.8 11.1 2.8q2.4 0 4.8-.6s.1 0 .1.1c.6.3 1.8.9 3.5 1.5v.3c0 5.5 4.5 9.9 9.9 9.9 5.5 0 9.9-4.5 9.9-9.9v-.2c1.9-.7 3.1-1.4 3.5-1.6l.1-.1q2.55.6 4.8.6c5.5 0 9.9-2.2 11.1-2.8.3-.2.5-.5.5-.9-.1-3.8-1.4-7.3-3.5-9.8M46 19.6c0-2.4 1.9-4.3 4.3-4.3s4.3 1.9 4.3 4.3-1.9 4.3-4.3 4.3S46 22 46 19.6m-19.5-1.8c0-3 2.5-5.5 5.5-5.5s5.5 2.5 5.5 5.5-2.5 5.5-5.5 5.5-5.5-2.4-5.5-5.5M9.3 19.6c0-2.4 1.9-4.3 4.3-4.3s4.3 1.9 4.3 4.3-1.9 4.3-4.3 4.3-4.3-1.9-4.3-4.3M4 38.1c.2-3.1 1.2-5.9 2.9-7.9 1.8-2.1 4.2-3.3 6.7-3.3 2.6 0 5 1.2 6.8 3.3.2.2.4.4.5.7-1.7 2.6-2.6 5.7-2.8 9-1.5.3-3 .5-4.4.5-4.2 0-8.1-1.6-9.7-2.3m28 13.6c-4.4 0-7.9-3.6-7.9-7.9s3.6-7.9 7.9-7.9 7.9 3.6 7.9 7.9-3.5 7.9-7.9 7.9m0-17.9c-4.7 0-8.6 3.3-9.7 7.6-.9-.4-1.7-.7-2.3-1 .2-3.4 1.2-6.5 2.9-9 .2-.3.4-.6.7-.9 2.2-2.6 5.2-4.1 8.4-4.1s6.2 1.4 8.4 4.1c.2.3.5.6.7.9s.4.6.6 1c.1.1.1.3.2.4l.3.6c.1.2.2.4.2.5.1.2.2.3.2.5.1.2.2.4.2.7.1.2.1.3.2.5.1.3.1.5.2.8 0 .1.1.3.1.4.1.3.1.5.2.8 0 .1.1.3.1.4 0 .3.1.5.1.8 0 .2 0 .3.1.5 0 .2 0 .5.1.7v.5c-.5.2-1.3.6-2.3 1-.9-4.4-4.9-7.7-9.6-7.7m18.3 6.6c-1.4 0-2.9-.2-4.4-.5v-.4c0-.2 0-.4-.1-.5 0-.3-.1-.5-.1-.8 0-.2-.1-.4-.1-.5 0-.3-.1-.6-.2-.8 0-.2-.1-.3-.1-.5-.1-.4-.2-.7-.3-1.1 0-.1-.1-.2-.1-.3-.1-.4-.2-.7-.4-1-.1-.2-.1-.3-.2-.4-.1-.2-.2-.5-.3-.7s-.2-.3-.2-.5l-.3-.6c-.1-.1-.2-.3-.3-.4s-.1-.2-.2-.3c.2-.2.3-.5.5-.7 1.8-2.1 4.2-3.3 6.7-3.3 2.6 0 5 1.2 6.7 3.3 1.7 2 2.7 4.8 2.9 7.9-1.3.5-5 2.1-9.5 2.1" />
    <path d="M34.4 42.7h-4.8c-.6 0-1 .4-1 1s.4 1 1 1h4.8c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconRemoveGroup;
