import * as React from "react";
const IconCutCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M47.1 43.8c-1.6 0-3 .4-4.3 1.1l-9.6-14L51.9 3.6c.3-.5.2-1.1-.3-1.4s-1.1-.2-1.4.3L32 29.1 13.8 2.4c-.3-.5-.9-.6-1.4-.3s-.6.9-.3 1.4l18.7 27.3-9.6 14c-1.3-.7-2.7-1.1-4.3-1.1-5 0-9.1 4.1-9.1 9.1s4.1 9.1 9.1 9.1 9.1-4.1 9.1-9.1c0-2.8-1.2-5.2-3.2-6.9L32 32.6 41.1 46c-1.9 1.7-3.2 4.1-3.2 6.9 0 5 4.1 9.1 9.1 9.1s9.1-4.1 9.1-9.1-4-9.1-9-9.1M24 52.9c0 3.9-3.2 7.1-7.1 7.1s-7.1-3.2-7.1-7.1 3.2-7.1 7.1-7.1S24 49 24 52.9M47.1 60c-3.9 0-7.1-3.2-7.1-7.1s3.2-7.1 7.1-7.1 7.1 3.2 7.1 7.1S51 60 47.1 60" />
  </svg>
);
export default IconCutCopy;
