import * as React from "react";
const IconServer18 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M52.3 48.3c-1.7 0-3.1 1.4-3.1 3.1s1.4 3.1 3.1 3.1 3.1-1.4 3.1-3.1c.1-1.7-1.3-3.1-3.1-3.1m0 4.2c-.6 0-1.1-.5-1.1-1.1s.5-1.1 1.1-1.1 1.1.5 1.1 1.1c.1.6-.4 1.1-1.1 1.1" />
    <path d="m60.7 48.4-1.6-2.7c-.3-.5-.9-.7-1.5-.5l-1.3.8c-.3-.2-.5-.5-.9-.6v-.8c0-.9-.8-1.7-1.7-1.7H51c-.9 0-1.7.8-1.7 1.7v.8c-.4.2-.6.4-.9.7l-1.2-.7c-.7-.4-1.4-.1-1.7.4L44 48.3c-.2.3-.2.6-.1.9s.3.6.6.7l1.3.7V52l-1.3.7c-.5.3-.7.9-.5 1.5l1.6 2.7c.3.5.9.7 1.5.5l1.4-.8c.3.2.5.5.9.7v1c0 .9.8 1.7 1.7 1.7h2.7c.9 0 1.7-.8 1.7-1.7v-1c.4-.2.6-.4.9-.7l1.2.7c.8.4 1.4.1 1.7-.4l1.5-2.5c.2-.3.2-.6.1-.9s-.3-.6-.6-.7l-1.4-.8v-1.4l1.3-.7c.6-.3.8-.9.5-1.5m-3.8 2.2c0 .2.1.5.1.7s0 .5-.1.7c-.1.6.2 1.2.7 1.5l.8.5-.7 1.1-1-.5c-.5-.3-1.1-.2-1.6.1.1.2 0 .3-.1.3-.3.2-.4.4-.6.5-.6.3-1 .9-1 1.5v.9h-2.1v-.8c0-.6-.4-1.2-1-1.5-.2-.1-.4-.3-.7-.5l-.3-.3c-.4-.4-1.1-.4-1.6-.1l-1 .5-.7-1.1.9-.5c.5-.3.8-.9.7-1.5v-.8c0-.3 0-.5.1-.7.1-.6-.2-1.2-.7-1.5l-.9-.5.7-1.1 1 .5c.5.3 1.1.2 1.6-.1l.3-.3c.3-.2.4-.4.7-.5.6-.3 1-.9 1-1.5v-.7h2.1v.7c0 .6.4 1.2 1 1.5.2.1.4.2.6.5.1.1.2.2.4.3.4.4 1.1.4 1.6.1l1-.5.7 1.1-.9.5c-.8.3-1.1.9-1 1.5" />
    <path d="M62 24.1V10.9c0-2.8-2.2-5-5-5H7c-2.8 0-5 2.2-5 5v13.2c0 1.6.8 3.1 2 4-1.2.9-2 2.4-2 4v13.2c0 2.8 2.2 5 5 5h31.3c.6 0 1-.4 1-1s-.4-1-1-1H7c-1.7 0-3-1.3-3-3V32.1c0-1.7 1.3-3 3-3h50c1.7 0 3 1.3 3 3v9.6c0 .6.4 1 1 1s1-.4 1-1v-9.6c0-1.6-.8-3.1-2-4 1.2-.9 2-2.4 2-4m-2 0c0 1.7-1.3 3-3 3H7c-1.7 0-3-1.3-3-3V10.9c0-1.7 1.3-3 3-3h50c1.7 0 3 1.3 3 3z" />
    <path d="M15.6 16.3c-.6 0-1 .4-1 1v.2c0 .6.4 1 1 1s1-.4 1-1v-.2c0-.6-.5-1-1-1M50.8 16.4H44c-.6 0-1 .4-1 1s.4 1 1 1h6.7c.6 0 1-.4 1-1s-.4-1-.9-1M14.6 38.5v.2c0 .6.4 1 1 1s1-.4 1-1v-.2c0-.6-.4-1-1-1s-1 .4-1 1M50.8 39.5c.6 0 1-.4 1-1s-.4-1-1-1H44c-.6 0-1 .4-1 1s.4 1 1 1z" />
  </svg>
);
export default IconServer18;
