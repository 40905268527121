import * as React from "react";
const IconBlueprint = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M47.2 19.6H16.8c-2.6 0-4.6 2.1-4.6 4.6v15.6c0 2.6 2.1 4.6 4.6 4.6h3.9c.6 0 1-.4 1-1s-.4-1-1-1h-3.9c-1.5 0-2.6-1.2-2.6-2.6v-4.1h15.6c.6 0 1-.4 1-1s-.4-1-1-1H14.1v-9.5c0-1.5 1.2-2.6 2.6-2.6h8.9v7.3c0 .6.4 1 1 1s1-.4 1-1v-7.3h19.5c1.5 0 2.6 1.2 2.6 2.6v6H39.1c-.6 0-1 .4-1 1s.4 1 1 1h10.7v7.6c0 1.5-1.2 2.6-2.6 2.6H36.8v-5c0-.6-.4-1-1-1s-1 .4-1 1v5h-5.3c-.6 0-1 .4-1 1s.4 1 1 1h17.7c2.6 0 4.6-2.1 4.6-4.6V24.2c.1-2.6-2-4.6-4.6-4.6" />
    <path d="M58 10.6H6c-2.2 0-4 1.8-4 4v34.7c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V14.6c0-2.2-1.8-4-4-4m2 38.8c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V14.6c0-1.1.9-2 2-2h52c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconBlueprint;
