import * as React from "react";
const IconShoePrint = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 65 65"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M31.7 36.6c-.4-.3-1-.3-1.3.1-.5.4-.9.9-1.4 1.3-1.7 1.5-3.4 3.1-4.4 5.5-1.7 4-1.1 7.6 1.4 9.6q1.5 1.2 3.6 1.2c.4 0 .8 0 1.3-.1q1.95-.3 4.5-2.1c1.5-1 2.5-2.5 3.4-3.9.6-1.1 1.3-2.1 2.1-3.4.3-.4.2-1-.2-1.3zM37 47.1c-.7 1.2-1.6 2.5-2.8 3.3-1.4 1-2.6 1.6-3.6 1.7-1.5.2-2.4 0-3.3-.7-2.7-2.1-1.3-6.1-.8-7.3.9-2 2.4-3.3 3.9-4.8.3-.2.5-.5.8-.7l7.5 5.8c-.6 1-1.2 1.9-1.7 2.7M56 4.4c-1-.5-2.5-1-4.1-.8-2.6.3-5.4 2.2-7.1 3.9-4.1 4.4-6.6 8.1-7.3 13.7-.1.8-.2 1.5-.3 2.3-.4 3.5-.7 6.3-2.9 9.2l-.2.2-.1.1c-.3.4-.3 1.1.2 1.4l8.6 6.7c.2.1.4.2.6.2.3 0 .5-.1.7-.3l.2-.2.1-.1c.8-1.1 1.7-2 2.7-2.9l1.6-1.6c2.2-2.3 3.9-4.3 5.8-7.2 2.8-4.3 4.4-8.2 5.5-13.2.9-4.1.4-9.3-4-11.4m2.1 10.7c-1.1 4.7-2.6 8.5-5.3 12.6-1.8 2.8-3.5 4.7-5.6 6.9l-1.5 1.5c-.8.8-1.7 1.6-2.4 2.6l-7-5.4c2.2-3.2 2.5-6.3 2.9-9.7.1-.7.2-1.5.3-2.2.7-5.1 2.9-8.4 6.8-12.6 1.3-1.4 3.7-3.1 5.8-3.3 1.1-.1 2.3.3 3 .7 3.3 1.6 3.7 5.8 3 8.9M18.8 46.3 7.8 44c-.5-.1-1 .2-1.2.7v.1c-.4 1.2-.8 2.5-1.2 3.8-.5 1.7-1 3.4-.8 5.1.2 1.6.5 3.3 1.4 4.7 1.1 1.7 2.3 2.7 4 3 .4.1.9.1 1.3.1 3.2 0 5.6-2.6 6.9-5.2 1.2-2.4 1.2-4.8 1.3-7.1 0-.6 0-1.2.1-1.8 0-.5-.4-1-.8-1.1m-1.3 2.8c-.1 2.2-.1 4.2-1.1 6.2-1.2 2.3-3.3 4.6-6 4-1.1-.2-1.9-.8-2.7-2.1-.7-1.1-.9-2.8-1-3.9-.1-1.3.3-2.8.7-4.3l.9-3 9.3 2c-.1.5-.1.8-.1 1.1M27.5 31.7c4.1-4.6 4.9-9.3 5.6-14.5.3-2.3-.1-5.7-1.7-8-.7-1.1-1.9-2-3.3-2.5-4.5-1.9-8.7 1-11 4.1-3.1 4.1-5 7.9-6.4 12.8-.9 3.2-1.3 5.7-1.7 9.1-.1.7-.1 1.4-.2 2.1-.1 1.4-.2 2.7-.5 4.1v.1c0 .1-.1.2-.1.4 0 .5.3.9.8 1l10.6 2.3h.3c.2 0 .4-.1.6-.2.2-.2.4-.4.4-.8 0-.1 0-.2.1-.3.8-3.5 2.6-5.5 5-8 .4-.5 1-1.1 1.5-1.7m-8.4 8.9-8.7-1.9c.2-1.3.3-2.5.4-3.7 0-.7.1-1.4.2-2 .4-3.4.7-5.8 1.6-8.8C14 19.5 15.7 16 18.7 12c1.8-2.5 5.2-5 8.6-3.5 1 .4 1.9 1.1 2.4 1.8 1.3 1.9 1.6 4.7 1.3 6.6-.7 5.9-1.7 9.6-5.1 13.4-.5.6-1 1.2-1.5 1.7-2.2 2.5-4.3 4.8-5.3 8.6" />
  </svg>
);
export default IconShoePrint;
