import * as React from "react";
const IconVsCode = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      
      d="M62.845 10a3 3 0 0 0-.184-.514 3 3 0 0 0-.252-.495 3.6 3.6 0 0 0-.707-.823 3.6 3.6 0 0 0-.901-.61L48.033 1.374a3.93 3.93 0 0 0-2.325-.32 3.9 3.9 0 0 0-1.831.853 3 3 0 0 0-.252.223L23.029 25.028l-12.022-9.913-1.046-.872a2.55 2.55 0 0 0-1.279-.59c-.068-.01-.135-.02-.203-.02-.058-.01-.126-.01-.184-.01-.097 0-.184 0-.281.01-.087 0-.165.02-.242.039-.165.029-.33.077-.485.145L2.59 15.765a2.7 2.7 0 0 0-1.153.95c-.281.426-.436.93-.436 1.433v27.704c0 .504.155 1.007.436 1.434.28.416.688.746 1.153.95l4.698 1.976c.436.184.92.242 1.395.175.475-.078.91-.282 1.279-.592l1.046-.91 12.022-9.913 20.596 22.897q.104.102.223.203c.01.01.02.02.029.02a3.9 3.9 0 0 0 1.83.853c.785.135 1.6.019 2.326-.32L60.8 56.443c.165-.078.32-.165.475-.272.3-.203.58-.455.813-.717a3.907 3.907 0 0 0 .91-2.52V11.066c.001-.358-.047-.717-.154-1.066M8.75 40.91V23.09l8.012 8.915zm22.737-8.905L47.5 18.797v26.406z"
    />
  </svg>
);
export default IconVsCode;
