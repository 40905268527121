import * as React from "react";
const IconPineapple = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60 14.6c-3.4-1.7-6.5-2.2-9.4-1.4-2.7.8-4.9 2.5-7 4.4 1.7-5.5-.6-9.1-5-13.1-.4-.4-1-.3-1.4.1s-.3 1 .1 1.4c4.6 4.2 6.3 7.2 3.6 13.1-7.9-6-20.5-4.1-29.3 4.6C2.1 33.2.7 47.2 8.4 54.8c3.3 3.3 7.8 4.9 12.6 4.9 6.3 0 13.1-2.8 18.5-8.2 9.1-9.1 10.8-22.5 3.9-30.3 5.1-1.9 8-.2 11.9 4.1.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 .1-1.4-3.6-3.9-6.9-6.2-11.5-5.4 1.8-1.5 3.8-3 5.9-3.6 2.3-.7 5-.2 7.9 1.2.5.3 1.1.1 1.3-.4s.1-.9-.4-1.1M38.1 50.2c-8.7 8.7-21.4 10.1-28.3 3.2S4.3 33.8 13 25.2c5-5 11.3-7.6 17.1-7.6 4.3 0 8.3 1.4 11.2 4.4 6.9 6.9 5.5 19.5-3.2 28.2" />
    <path d="m31.8 27.9-4-.7-.7-3.6-2 .3.9 5 5.4 1zM32.5 37.1l-2 .4.9 4.9 5.4 1 .3-2-4-.7zM21.6 35.2l-4-.8-.7-3.6-1.9.4.9 5 5.3.9zM21.2 45.1l-2 .4.9 4.9 5.4 1 .3-2-4-.7z" />
  </svg>
);
export default IconPineapple;
