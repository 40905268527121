import * as React from "react";
const IconFlashDriveAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m60.5 38.8-28-28c-1.6-1.6-4.1-1.6-5.7 0l-.5.5L18 3c-1-1-2.4-1.6-3.8-1.6S11.4 2 10.4 3L3 10.4c-1 1-1.6 2.4-1.6 3.8S2 16.9 3 18l8.4 8.4-.5.5c-.8.8-1.2 1.8-1.2 2.8 0 1.1.4 2.1 1.2 2.8l28 28c1.4 1.4 3.2 2 4.9 2 1.8 0 3.6-.7 5-2l11.8-11.8c2.6-2.7 2.6-7.1-.1-9.9M4.4 16.5c-1.3-1.3-1.3-3.4 0-4.8l7.4-7.4c1.3-1.3 3.4-1.3 4.8 0l8.4 8.4-12.2 12.2zm54.7 30.8L47.3 59.1c-1.9 1.9-5.1 1.9-7.1 0l-28-28c-.4-.4-.6-.9-.6-1.4s.2-1 .6-1.4l16-16c.4-.4.9-.6 1.4-.6s1 .2 1.4.6l28 28c2.1 1.9 2.1 5.1.1 7" />
    <path d="M11.5 14.1c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l2 2c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4zM17.6 11.9l-2-2c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l2 2c.2.2.5.3.7.3s.5-.1.7-.3c.4-.3.4-1 0-1.4" />
  </svg>
);
export default IconFlashDriveAlt;
