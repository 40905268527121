import React, { useState, useEffect } from 'react';
import { useNavigation } from 'context/NavigationContext';
import { BringzzPageContainer, BringzzIcon, BringzzText, BringzzSwitch, BringzzBottomDrawer, BringzzRadioList, BringzzRadio } from "@bringzz/components";
import { LanguageSwitch } from './components/LanguageSwitch';
import { FaceIdSwitch } from './components/FaceIdSwitch';
import { NotificationsSwitch } from './components/NotificationsSwitch';
import { LocationSwitch } from './components/LocationSwitch';
import TrackingSwitch from './components/TrackingSwitch';

const AppSettingsPage = () => {
    const { backPress, navigate } = useNavigation();

    return (
        <BringzzPageContainer
            header={{
                left: <BringzzIcon
                    size="22"
                    icon="IconChevronLeft"
                    folder="LineIcons"
                    className="cursor-pointer"
                    onClick={backPress}
                />,
                title: <BringzzText tag='h2'>App Settings</BringzzText>
            }}>

            <div className='p-4 divide-y'>
                <LanguageSwitch />
                <div className='py-2'>
                    <BringzzText tag='h4' className="font-bold">Privacy & Notifications</BringzzText>
                </div>
                <FaceIdSwitch />
                <NotificationsSwitch />
                <LocationSwitch />
                <TrackingSwitch />
                <div className='flex py-2 justify-between items-center cursor-pointer' onClick={() => navigate('/settings/app/delete')}>
                    <BringzzText tag='h4' className="underline font-normal text-midnight-blue/60">Delete your data and account</BringzzText>
                </div>
            </div>
        </BringzzPageContainer>

    )
}

export default AppSettingsPage;