import * as React from "react";
const IconSpinner1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2c-.6 0-1 .4-1 1v16.2c0 .6.4 1 1 1s1-.4 1-1V3c0-.6-.4-1-1-1M32 43.8c-.6 0-1 .4-1 1V61c0 .6.4 1 1 1s1-.4 1-1V44.8c0-.5-.4-1-1-1M20.2 32c0-.6-.4-1-1-1H3c-.6 0-1 .4-1 1s.4 1 1 1h16.2c.5 0 1-.4 1-1M61 31H44.8c-.6 0-1 .4-1 1s.4 1 1 1H61c.6 0 1-.4 1-1s-.4-1-1-1M41.1 23.9c.3 0 .5-.1.7-.3l11.4-11.4c.4-.4.4-1 0-1.4s-1-.4-1.4 0L40.4 22.2c-.4.4-.4 1 0 1.4.2.2.4.3.7.3M22.2 40.4 10.8 51.8c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l11.4-11.4c.4-.4.4-1 0-1.4s-1-.4-1.4 0M41.8 40.4c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l11.4 11.4c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4zM12.2 10.8c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l11.4 11.4c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4z" />
  </svg>
);
export default IconSpinner1;
