import * as React from "react";
const IconRotateAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m33.7 46.4 8.9-8.9c.8-.8 1.2-1.8 1.2-2.8 0-1.1-.4-2.1-1.2-2.8L33.7 23c-1.5-1.5-4.1-1.5-5.7 0l-8.9 8.9c-.8.8-1.2 1.8-1.2 2.8s.4 2.1 1.2 2.8l8.9 8.9c.8.8 1.8 1.2 2.8 1.2s2.1-.4 2.9-1.2M20.5 36.1c-.4-.4-.6-.9-.6-1.4s.2-1 .6-1.4l8.9-8.9c.4-.4.9-.6 1.4-.6s1 .2 1.4.6l8.9 8.9c.4.4.6.9.6 1.4s-.2 1-.6 1.4L32.2 45c-.8.8-2 .8-2.8 0z" />
    <path d="M58.5 22.4C54.2 11 45.5 6.6 39 5c-9.7-2.4-19.2 0-23.2 3.6-.1.1-.2.2-.2.4l-1.9-6.2c-.1-.5-.5-.8-.9-.8s-.8.2-1 .6L4 20.6c-.2.4-.1.8.2 1.1.2.2.5.3.7.3.1 0 .3 0 .4-.1l18-7.8c.4-.2.6-.6.6-1s-.3-.8-.7-.9l-6.6-2c.2 0 .3-.1.4-.2 3.8-3.1 12.8-5.2 21.5-3 6 1.5 14.1 5.5 18.1 16.1 7.8 20.4-9.3 32.5-10.1 33-.5.3-.6.9-.3 1.4.2.3.5.4.8.4.2 0 .4-.1.6-.2.3-.1 19.3-13.4 10.9-35.3M6.9 19.1l5.7-13.2 1.6 5.2c.1.3.3.6.7.7l5.2 1.6zM10.8 50.5c-.4-.4-1-.5-1.4-.1s-.5 1-.1 1.4c1.4 1.6 2.9 3 4.4 4.3.2.1.4.2.6.2.3 0 .6-.1.8-.4.3-.4.3-1.1-.2-1.4-1.4-1.1-2.8-2.5-4.1-4M25.3 59.6c-1.2-.3-3.2-.8-5.4-1.9-.5-.2-1.1 0-1.3.5s0 1.1.5 1.3c1.9 1 3.9 1.7 5.9 2.1h.2c.5 0 .9-.3 1-.8 0-.6-.4-1.1-.9-1.2M5.3 40.5c-.1-.5-.7-.8-1.2-.7s-.8.7-.7 1.2c.6 2 1.3 4 2.3 5.8.2.3.5.5.9.5.2 0 .3 0 .5-.1.5-.3.7-.9.4-1.4-.9-1.7-1.7-3.4-2.2-5.3M44.2 57.4c-.5.2-1.1.5-1.7.7-.5.2-.8.8-.6 1.3.1.4.5.7.9.7.1 0 .2 0 .3-.1.6-.2 1.3-.5 1.8-.8.5-.2.7-.8.5-1.3-.1-.5-.7-.7-1.2-.5M36.9 59.5c-1.9.3-3.9.5-5.9.5-.6 0-1 .5-1 1s.5 1 1 1c2.1 0 4.2-.2 6.1-.5.5-.1.9-.6.8-1.1 0-.6-.5-1-1-.9" />
  </svg>
);
export default IconRotateAlt;
