import * as React from "react";
const IconBoxCart = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M36.3 62c-1.3 0-2.5-.3-3.7-.8-4.1-1.9-6-6.6-4.6-10.7L3.6 39.1c-.5-.2-.7-.8-.5-1.3s.8-.7 1.3-.5l24.4 11.4c2.1-3.5 6.4-5 10.3-3.7l12.4-26.6c.1-.2.3-.4.6-.5l7.7-2.8c.5-.2 1.1.1 1.3.6s-.1 1.1-.6 1.3l-7.3 2.6-12.3 26.2c3.7 2.3 5.3 7.1 3.4 11.2-1 2.1-2.7 3.7-4.9 4.5-1.1.3-2.1.5-3.1.5m0-15.5c-2.6 0-5 1.4-6.1 3.9-1.6 3.4-.1 7.4 3.3 9 1.6.8 3.5.8 5.2.2s3.1-1.9 3.8-3.5c1.6-3.4.1-7.4-3.3-9-1-.4-2-.6-2.9-.6m-7.8-6c-.1 0-.3 0-.4-.1L7.3 30.7c-.2-.1-.4-.3-.5-.6-.1-.2-.1-.5 0-.8l7.9-16.9-2.7-1.2c-.2-.1-.4-.3-.5-.6-.1-.2-.1-.5 0-.8l3.4-7.3c.2-.5.8-.7 1.3-.5l28 13.1c.2.1.4.3.5.6.1.2.1.5 0 .8l-3.4 7.3c-.2.5-.8.7-1.3.5L37.3 23l-7.9 16.9c-.2.3-.5.6-.9.6M9 29.3l19 8.8 7.5-16-19-8.8zm17.8-13.4L39.9 22l2.6-5.5L16.3 4.3l-2.6 5.5 2.7 1.3z" />
  </svg>
);
export default IconBoxCart;
