import * as React from "react";
const IconSilo = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M19.1 4.6C10.2 4.6 2.8 11.3 2 20.2v38.1c0 .6.4 1 1 1s1-.4 1-1V56h30.2v2.3c0 .6.4 1 1 1s1-.4 1-1V20.1C35.4 11.3 28 4.6 19.1 4.6m0 2c7.4 0 13.7 5.4 14.9 12.6H4.2c1.2-7.1 7.5-12.6 14.9-12.6m3.5 28.3h-7v-5.8h7zm-7-7.8v-5.8h7v5.8zM4 21.2h9.6v18.3c0 .6.4 1 1 1s1-.4 1-1v-2.6h7v2.6c0 .6.4 1 1 1s1-.4 1-1V21.2h9.6V54H4zM62 38.9c-.4-4.7-4.4-8.3-9.2-8.3s-8.7 3.6-9.2 8.4v19.3c0 .6.4 1 1 1s1-.4 1-1v-.7c.2 0 .3.1.5.1h13.3c.2 0 .3 0 .5-.1v.7c0 .6.4 1 1 1s1-.4 1-1zm-9.2-6.3c3.3 0 6.2 2.3 7 5.4H45.9c.8-3.1 3.6-5.4 6.9-5.4m6.7 23.1H46.2c-.3 0-.5-.2-.5-.5V40H60v15.2c0 .3-.2.5-.5.5" />
  </svg>
);
export default IconSilo;
