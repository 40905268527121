import * as React from "react";
const IconMeat = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M41.9 13.5c-4.7 0-8.6 3.9-8.6 8.6s3.9 8.6 8.6 8.6 8.6-3.9 8.6-8.6-3.8-8.6-8.6-8.6m0 15.1c-3.6 0-6.6-3-6.6-6.6s3-6.6 6.6-6.6 6.6 3 6.6 6.6-2.9 6.6-6.6 6.6" />
    <path d="M40.4 2.1c-10.8.6-20.8 7.1-28.2 18.1C5.9 29.5 2 41.4 2 51.1 2 57.1 6.9 62 12.9 62c4.6 0 8.8-2.9 10.3-7.3 1.3-3.6 4-6.7 7.8-8.9 3.6-2.1 8.2-3.4 13.3-3.8 9.6-.8 17.7-9.9 17.7-19.9 0-11.6-9.8-20.9-21.6-20M44.1 40c-5.3.4-10.2 1.8-14.1 4-4.2 2.4-7.2 5.9-8.7 10-1.3 3.6-4.6 6-8.4 6C8 60 4 56 4 51.1c0-9.3 3.8-20.7 9.8-29.7C20.9 10.8 30.4 4.7 40.5 4.1c.5-.1 1-.1 1.4-.1C51.9 4 60 12.1 60 22.1c0 9-7.3 17.2-15.9 17.9" />
  </svg>
);
export default IconMeat;
