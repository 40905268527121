import * as React from "react";
const IconSelectCursor = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m59.2 47.1-14.9-5.6c-1-.4-2.1-.1-2.8.6s-1 1.8-.6 2.8l.3.7c-.3-.1-.5-.2-.8-.2h-3c-1.2 0-2.2 1-2.2 2.2s1 2.2 2.2 2.2h3c.9 0 1.6-.5 2-1.2l4.2 11.1c.3.8 1 1.4 1.9 1.5h.2c.8 0 1.5-.4 1.9-1.1l2.2-3.7 2.5 2.5c.4.4 1 .7 1.6.7s1.2-.2 1.6-.7c.9-.9.9-2.3 0-3.2L56 53.2l3.7-2.1c.8-.4 1.2-1.3 1.1-2.1-.3-.9-.8-1.6-1.6-1.9m-8.9 4.5L49 53.8l-2.5-6.7 6.7 2.5-2.1 1.2c-.3.2-.6.5-.8.8M30 45.4h-4.8c-1.2 0-2.2 1-2.2 2.2s1 2.2 2.2 2.2H30c1.2 0 2.2-1 2.2-2.2s-.9-2.2-2.2-2.2M18 45.4h-3c-1.2 0-2.2 1-2.2 2.2s1 2.2 2.2 2.2h3c1.2 0 2.2-1 2.2-2.2s-1-2.2-2.2-2.2M8.3 45.4h-.4V45c0-1.2-1-2.2-2.2-2.2s-2.2 1-2.2 2.2v2.7c0 1.2 1 2.2 2.2 2.2h2.7c1.2 0 2.2-1 2.2-2.2s-1.1-2.3-2.3-2.3M8.3 2.8H5.6c-1.2 0-2.2 1-2.2 2.2v2.7c0 1.2 1 2.2 2.2 2.2s2.2-1 2.2-2.2v-.5h.4c1.2 0 2.2-1 2.2-2.2s-.9-2.2-2.1-2.2M45.6 7.2h.4v.4c0 1.2 1 2.2 2.2 2.2s2.2-1 2.2-2.2V5c0-1.2-1-2.2-2.2-2.2h-2.7c-1.2 0-2.2 1-2.2 2.2s1.1 2.2 2.3 2.2M37.3 7.2h3c1.2 0 2.2-1 2.2-2.2s-1-2.2-2.2-2.2h-3c-1.2 0-2.2 1-2.2 2.2s1 2.2 2.2 2.2M30 2.8h-4.8C24 2.8 23 3.8 23 5s1 2.2 2.2 2.2H30c1.2 0 2.2-1 2.2-2.2s-.9-2.2-2.2-2.2M18 2.8h-3c-1.2 0-2.2 1-2.2 2.2s1 2.2 2.2 2.2h3c1.2 0 2.2-1 2.2-2.2s-1-2.2-2.2-2.2M48.3 33.9c-1.2 0-2.2 1-2.2 2.2v3c0 1.2 1 2.2 2.2 2.2s2.2-1 2.2-2.2v-3c.1-1.2-1-2.2-2.2-2.2M50.6 24.1c0-1.2-1-2.2-2.2-2.2s-2.2 1-2.2 2.2v4.8c0 1.2 1 2.2 2.2 2.2s2.2-1 2.2-2.2zM50.6 13.8c0-1.2-1-2.2-2.2-2.2s-2.2 1-2.2 2.2v3c0 1.2 1 2.2 2.2 2.2s2.2-1 2.2-2.2zM5.6 40.8c1.2 0 2.2-1 2.2-2.2v-3c0-1.2-1-2.2-2.2-2.2s-2.2 1-2.2 2.2v3c0 1.2 1 2.2 2.2 2.2M5.6 30.5c1.2 0 2.2-1 2.2-2.2v-4.8c0-1.2-1-2.2-2.2-2.2s-2.2 1-2.2 2.2v4.8c0 1.2 1 2.2 2.2 2.2M5.6 18.4c1.2 0 2.2-1 2.2-2.2v-3c0-1.2-1-2.2-2.2-2.2s-2.2 1-2.2 2.2v3c0 1.2 1 2.2 2.2 2.2" />
  </svg>
);
export default IconSelectCursor;
