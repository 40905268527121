import * as React from "react";
const IconSpeechBubble20 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.5 2h-49C4.5 2 2 4.5 2 7.5v28.7c0 3 2.5 5.5 5.5 5.5h8.7l-.4 1.6c-.2.7-.1 1.4.3 2l3.7 6.5-2.2 6.7c-.4 1.1 0 2.3.9 3 .5.4 1.1.6 1.6.6s1-.2 1.5-.5l10.2-6.9c1-.7 1.4-2 1-3.1l-2.5-6.9 1.3-2.8h24.8c3 0 5.5-2.5 5.5-5.5V7.5c.1-3-2.4-5.5-5.4-5.5M60 36.2c0 1.9-1.6 3.5-3.5 3.5H31c-.4 0-.8.2-.9.6l-1.7 3.8c-.1.2-.1.5 0 .7l2.6 7.3c.1.3 0 .6-.3.8l-10.2 6.9c-.4.3-.7 0-.8 0-.1-.1-.4-.3-.2-.8l2.3-7.1c.1-.3.1-.6-.1-.8l-3.9-6.9c-.1-.2-.1-.3-.1-.5l.7-2.8c.1-.3 0-.6-.2-.9-.2-.2-.5-.4-.8-.4h-10c-1.9 0-3.5-1.6-3.5-3.5V7.5C4 5.6 5.6 4 7.5 4h48.9c2 0 3.6 1.6 3.6 3.5z" />
    <path d="M20.7 13.3H39c.6 0 1-.4 1-1s-.4-1-1-1H20.7c-.6 0-1 .4-1 1s.4 1 1 1M43.3 19.8H20.7c-.6 0-1 .4-1 1s.4 1 1 1h22.7c.6 0 1-.4 1-1s-.5-1-1.1-1M39 28.4H20.7c-.6 0-1 .4-1 1s.4 1 1 1H39c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconSpeechBubble20;
