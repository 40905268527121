import * as React from "react";
const IconVideoAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55 2H9C5.1 2 2 5.1 2 9v46c0 3.9 3.1 7 7 7h46c3.9 0 7-3.1 7-7V9c0-3.9-3.1-7-7-7M16.3 31H4V18.3h12.3zM4 33h12.3v12.7H4zM18.3 4h27.5v56H18.3zM60 31H47.7V18.3H60zm-12.3 2H60v12.7H47.7zM60 9v7.3H47.7V4H55c2.8 0 5 2.2 5 5M9 4h7.3v12.3H4V9c0-2.8 2.2-5 5-5M4 55v-7.3h12.3V60H9c-2.8 0-5-2.2-5-5m51 5h-7.3V47.7H60V55c0 2.8-2.2 5-5 5" />
  </svg>
);
export default IconVideoAlt;
