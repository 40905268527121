import * as React from "react";
const IconHairdryer = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.9 5.7c-3.9-3-8.8-4.1-13.6-2.9L16 9.4 3.3 6c-.3 0-.7 0-.9.2-.3.2-.4.5-.4.8v23.3c0 .3.2.6.4.8.3.2.6.2.9.1L16 26.9l20 5v.3l2.1 8.7h-4.3c-.6 0-1 .4-1 1s.4 1 1 1h4.8l3.6 14.6c.6 2.5 2.8 4.2 5.3 4.2 3 0 5.5-2.5 5.5-5.5V32.7c0-.1 0-.2-.1-.3 1-.5 2-1.1 2.9-1.8 3.9-3 6.1-7.6 6.1-12.5.1-4.8-2.1-9.4-6-12.4M4 8.3l11 2.9v13.9L4 28.9zm47.1 47.9c0 1.9-1.6 3.5-3.5 3.5-1.6 0-3-1.1-3.4-2.7l-6-24.6 4.2 1c1.3.3 2.6.5 3.9.5 1.7 0 3.3-.3 4.9-.8v23.1zm3.6-27.1c-3.4 2.6-7.7 3.5-11.9 2.5L17 25.1V11.3l25.8-6.5c4.2-1 8.5-.1 11.9 2.5S60 13.9 60 18.2c0 4.2-1.9 8.2-5.3 10.9" />
  </svg>
);
export default IconHairdryer;
