import * as React from "react";
const IconPencilCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.4 13.3C58 9.8 54.5 6.2 51 2.8c-.4-.5-1-.8-1.6-.8s-1.1.2-1.6.6l-39 38.8c-.4.4-.8 1-1 1.6L2.1 60.4c-.1.4-.1.8.1 1.1.2.2.5.5 1.1.5h.2L21 56.1c.6-.2 1.1-.5 1.6-1l38.8-38.8c.4-.4.6-1 .6-1.5s-.2-1.1-.6-1.5M21.1 53.7c-.2.2-.5.4-.8.5L4.5 59.6l5.3-15.9c.1-.3.3-.6.5-.8l29.4-29.3 10.9 10.8zM59.9 15l-8 8L41 12.2 49.2 4h.1s.1 0 .3.2c3.6 3.4 7 7 10.4 10.5l.1.1z" />
  </svg>
);
export default IconPencilCopy;
