import * as React from "react";
const IconComputerSpeaker = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M34.9 47.3h-7.2v-6.2h5c.6 0 1-.4 1-1s-.4-1-1-1H7.4c-1.2 0-2.1-.9-2.1-2.1V18.8c0-1.2.9-2.1 2.1-2.1h25.3c.6 0 1-.4 1-1s-.4-1-1-1H7.4c-2.3 0-4.1 1.8-4.1 4.1v18.3c0 2.3 1.8 4.1 4.1 4.1h18.3v6.2h-7.2c-.6 0-1 .4-1 1s.4 1 1 1h16.3c.6 0 1-.4 1-1s-.3-1.1-.9-1.1M55.7 14.7h-12c-2.8 0-5 2.2-5 5v24.6c0 2.8 2.2 5 5 5h12c2.8 0 5-2.2 5-5V19.7c0-2.8-2.3-5-5-5m3 29.6c0 1.7-1.3 3-3 3h-12c-1.7 0-3-1.3-3-3V19.7c0-1.7 1.3-3 3-3h12c1.7 0 3 1.3 3 3z" />
    <path d="M49.7 32.8c-2.7 0-4.9 2.2-4.9 4.9s2.2 4.9 4.9 4.9 4.9-2.2 4.9-4.9-2.2-4.9-4.9-4.9m0 7.8c-1.6 0-2.9-1.3-2.9-2.9s1.3-2.9 2.9-2.9 2.9 1.3 2.9 2.9-1.3 2.9-2.9 2.9M49.7 28.7c2.1 0 3.9-1.7 3.9-3.9S51.8 21 49.7 21s-3.9 1.7-3.9 3.9 1.8 3.8 3.9 3.8m0-5.7c1 0 1.9.8 1.9 1.9s-.8 1.9-1.9 1.9-1.9-.8-1.9-1.9.9-1.9 1.9-1.9" />
  </svg>
);
export default IconComputerSpeaker;
