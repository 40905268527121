import React, { useEffect, useState } from 'react';
import { BringzzAccordion, BringzzButton, BringzzText } from "@bringzz/components";
import { useNavigation } from 'context/NavigationContext';
import {
    BringzzPageContainer,
    BringzzIcon,
    BringzzBasketProduct
} from "@bringzz/components";
import { useParams } from 'react-router-dom';
import useRequest from 'hooks/useRequest';
import { useOrder } from 'context/OrderContext';

const OrderViewPage = () => {
    const { backPress, navigate } = useNavigation();
    const { id } = useParams();
    const { startMatchingProcess } = useOrder()
    const { sendRequest, data, error, loading } = useRequest();
    const [orderDetails, setOrderDetails] = useState()

    const fetchOrder = async () => {
        await sendRequest({
            method: 'POST',
            url: `/module/order/getOrder`,
            data: {
                "orderUUID": id
            }
        }).then((response) => {
            if (response.status === 'success') {
                setOrderDetails(response.data)
                console.log(response);
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    useEffect(() => {
        console.log(id);
        if (!id) {
            backPress()
        }
        else {
            fetchOrder()
        }
    }, [])

    const acceptOrder = async () => {
        await sendRequest({
            method: 'POST',
            url: `/module/order/acceptOrder`,
            data: {
                "orderId": orderDetails.id
            }
        }).then((response) => {
            if (response.status === 'success') {
                // setOrderDetails(response.data)
                startMatchingProcess(orderDetails, "package")
                console.log(response);
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    if (!orderDetails) {
        return (
            <BringzzPageContainer
                className="bg-white"
                header={{
                    left: (
                        <BringzzIcon
                            size='22'
                            icon='IconChevronLeft'
                            folder='LineIcons'
                            className='cursor-pointer'
                            onClick={backPress}
                        />
                    ),
                    title: "Loading Order..."
                }}
                authenticated={true}
            >
                <div className="text-center py-6">
                    <BringzzText tag="h3">Loading...</BringzzText>
                </div>
            </BringzzPageContainer>
        );
    }

    return (
        <BringzzPageContainer
            className="bg-white"
            header={{
                left: (
                    <BringzzIcon
                        size='22'
                        icon='IconChevronLeft'
                        folder='LineIcons'
                        className='cursor-pointer'
                        onClick={backPress}
                    />
                ),
                title: `Order #${orderDetails.id}`
            }}
            footerHover={true}
            footer={<div className=''>
                <div className='p-4 justify-center text-center'>
                    <BringzzText tag='h5'>By tapping the accept button, I accept the order from my client. I agree to the terms and conditions and privacy policy.</BringzzText>
                    <div className='flex space-x-2 justify-center mt-2'>
                        <BringzzButton
                            className="bg-white text-red-400 border-red-400 border-2"
                            onClick={() => navigate('/')}
                            disabled={loading}
                        >
                            Decline
                        </BringzzButton>
                        <BringzzButton className="bg-magic-lilac"
                            onClick={acceptOrder}
                            disabled={loading}
                        >Accept</BringzzButton>
                    </div>
                </div>

            </div>}
            authenticated={true}
        >
            {orderDetails && <div className='px-6'>
                <div className='text-center my-4'>
                    <BringzzText tag='h1'>€ {orderDetails.price.toFixed(2) || 0}</BringzzText>
                    <BringzzText tag='h3'>{orderDetails.products.length} Items</BringzzText>
                </div>
                <div className='flex space-x-4 border-t border-b py-2'>
                    <div>
                        <BringzzIcon
                            folder='CustomIcons'
                            icon='IconMountain'
                            size='22'
                            className='bg-slate-100 p-3 rounded-full' />
                    </div>
                    <div className='flex w-full justify-between'>
                        <div>
                            <BringzzText tag='h3'>Customer</BringzzText>
                            <BringzzText tag='h4' className={"font-normal"}>Joe Doe</BringzzText>
                        </div>
                        <div className='flex items-center space-x-2'>
                            <BringzzIcon
                                folder='LineIcons'
                                icon='IconPhone'
                                size='22' />
                            <BringzzIcon
                                folder='LineIcons'
                                icon='IconPopup'
                                size='22' />
                        </div>
                    </div>
                </div>
                <div className='flex space-x-4 border-b py-2'>
                    <div>
                        <BringzzIcon
                            folder='LineIcons'
                            icon='IconBox9'
                            size='22'
                            className='p-3 rounded-full' />
                    </div>
                    <div className='flex w-full'>
                        <div>
                            <BringzzText tag='h3'>Order Type</BringzzText>
                            <BringzzText tag='h4' className={"font-normal"}>Bringzz Shopper</BringzzText>
                        </div>
                    </div>
                </div>
                <div className='flex space-x-4 border-b py-2'>
                    <div>
                        <BringzzIcon
                            folder='LineIcons'
                            icon='IconFileName'
                            size='22'
                            className='px-3 py-2' />
                    </div>
                    <div className='flex w-full justify-between opacity-35'>
                        <div className='flex items-center'>
                            <BringzzText tag='h3'>Prescription</BringzzText>
                        </div>
                        <div className='flex items-center space-x-2'>
                            <BringzzIcon
                                folder='LineIcons'
                                icon='IconEye'
                                size='22' />
                            <BringzzIcon
                                folder='LineIcons'
                                icon='IconBarcode'
                                size='22' />
                        </div>
                    </div>
                </div>
                <BringzzAccordion
                    title={<div className='flex space-x-4'>
                        <div>
                            <BringzzIcon
                                folder='LineIcons'
                                icon='IconCheckBoxAlt'
                                size='22'
                                className='p-3 rounded-full' />
                        </div>
                        <div>
                            <BringzzText tag='h3'>Order Total</BringzzText>
                            <BringzzText tag='h4' className={"font-normal"}>€ {orderDetails.price.toFixed(2) || 0} | {orderDetails.products.length || 0} Items</BringzzText></div>
                    </div>}
                    defaultValue={true}
                >
                    {orderDetails.products.map((product, index) => {
                        return (
                            <BringzzBasketProduct
                                key={index + 'product'}
                                product={product}
                                mode={"view"}
                            />
                        );
                    })}
                    <div className='py-3'>
                        <div className='flex justify-between'>
                            <BringzzText tag='h4' className="font-normal">Subtotal</BringzzText>
                            <BringzzText tag='h4' className="font-normal">€ {orderDetails.price.toFixed(2) || 0}</BringzzText>
                        </div>
                        <div className='flex justify-between'>
                            <BringzzText tag='h4' className="font-normal">Delivery fee & handling</BringzzText>
                            <BringzzText tag='h4' className="font-normal">€ XX.XX</BringzzText>
                        </div>
                        <div className='flex justify-between'>
                            <div className='flex space-x-1 items-center'>
                                <BringzzText tag='h4'>Total</BringzzText>
                                <BringzzIcon
                                    icon="IconQuestionCircle"
                                    folder="LineIcons"
                                    className="h-4 w-4 cursor-pointer text-magic-lilac"
                                    size="16"
                                ></BringzzIcon>
                            </div>
                            <BringzzText tag='h4'>€ {orderDetails.price.toFixed(2) || 0}</BringzzText>
                        </div>
                    </div>
                </BringzzAccordion>

                <div className='flex space-x-4 border-b py-2'>
                    <div>
                        <BringzzIcon
                            folder='LineIcons'
                            icon='IconInvoice1'
                            size='22'
                            className='p-3 rounded-full' />
                    </div>
                    <div className='flex w-full justify-between'>
                        <div>
                            <BringzzText tag='h3'>Invoice</BringzzText>
                            <BringzzText tag='h4' className={"font-normal"}>Invoice #2023-123456</BringzzText>
                        </div>
                        <div className='flex items-center space-x-2'>
                            <BringzzButton size='sm' className="bg-magic-lilac">View</BringzzButton>
                        </div>
                    </div>
                </div>
                {/* <div className='flex space-x-4 border-b py-2'>
                    <BringzzButton size='sm' className="bg-magic-lilac">Problem with payment?</BringzzButton>
                </div> */}
            </div>}

        </BringzzPageContainer >
    );
};

export default OrderViewPage;
