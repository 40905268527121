import * as React from "react";
const IconPercentageTicket = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.8 7.9c0-3.2-2.6-5.9-5.9-5.9H14c-3.2 0-5.9 2.6-5.9 5.9v52c0 .4.2.7.5.9s.7.2 1 0l5.6-3.7c.2-.1.5-.1.7 0l7.3 4.8c.3.2.8.2 1.1 0l7.2-4.8c.2-.1.5-.1.7 0l7.3 4.8c.2.1.4.2.6.2s.4-.1.6-.2l7.2-4.8c.2-.1.5-.1.7 0l5.6 3.7c.3.2.7.2 1 0s.5-.5.5-.9zm-6 47.4c-.9-.6-2.1-.6-3 0l-6.7 4.5-6.7-4.5c-.4-.3-1-.4-1.5-.4s-1 .1-1.5.4l-6.7 4.4-6.7-4.4c-.9-.6-2.1-.6-3 0L10 58V7.9c.2-2.2 1.9-3.9 4-3.9h35.9c2.1 0 3.9 1.7 3.9 3.9V58z" />
    <path d="M36.9 24.9 24.5 37.3c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l12.4-12.4c.4-.4.4-1 0-1.4s-1-.4-1.4 0M23.6 21.9c-.3-.5-.9-.6-1.4-.3-.4.4-.5 1-.2 1.4l.4.5c.2.3.5.4.8.4.2 0 .4-.1.6-.2.5-.3.6-.9.3-1.4zM41.2 40.3c-.4-.3-1.1-.2-1.4.2s-.2 1.1.2 1.4l.5.4c.2.1.4.2.6.2.3 0 .6-.1.8-.4.3-.4.2-1.1-.2-1.4z" />
  </svg>
);
export default IconPercentageTicket;
