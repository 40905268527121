import * as React from "react";
const IconTruckAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.7 33.1-5.6-10.2c-.4-.6-1-1-1.8-1h-7.7c-1.3 0-2.3 1-2.3 2.3v8.7h-5.9c.3-.6.6-1.3.6-2v-6c0-2.2-1.8-4-4-4h-3.6v-8c0-2.2-1.8-4-4-4H15.2c-2.2 0-4 1.8-4 4v8H7.6c-2.2 0-4 1.8-4 4v6c0 1.1.4 2 1.1 2.7-.3.3-.4.6-.5 1l-2 7.5c-.2.7 0 1.5.5 2.1l3.8 4.5c.4.5 1.1.8 1.8.8h2.4c.5 3.1 3.2 5.5 6.4 5.5 2.4 0 4.5-1.3 5.6-3.2 1.1 1.9 3.2 3.2 5.6 3.2 3.2 0 5.9-2.4 6.4-5.5h6.9c.5 3.1 3.2 5.5 6.4 5.5s5.9-2.4 6.4-5.5h2.7c.6 0 1.2-.2 1.5-.6l2.6-2.3c.5-.4.8-1.1.8-1.7V34.2c0-.4-.1-.8-.3-1.1m-15-9.2h7.7l4.9 9H46.4v-8.7q0-.3.3-.3m-9.7 1v6c0 1.1-.9 2-2 2H22.3v-10H35c1.1 0 2 .9 2 2m-23.8-12c0-1.1.9-2 2-2h12.2c1.1 0 2 .9 2 2v8H13.2zm-7.6 12c0-1.1.9-2 2-2h12.7v10H7.5c-1.1 0-2-.9-2-2v-6zm11.3 28.2c-2.5 0-4.5-2-4.5-4.5s2-4.5 4.5-4.5 4.5 2 4.5 4.5-2 4.5-4.5 4.5m11.2 0c-2.5 0-4.5-2-4.5-4.5s2-4.5 4.5-4.5 4.5 2 4.5 4.5-2 4.5-4.5 4.5m19.8 0c-2.5 0-4.5-2-4.5-4.5s2-4.5 4.5-4.5 4.5 2 4.5 4.5-2 4.5-4.5 4.5m12-7.9-2.6 2.3s-.1.1-.2.1h-2.7c-.5-3.1-3.2-5.5-6.4-5.5s-5.9 2.4-6.4 5.5h-6.9c-.5-3.1-3.2-5.5-6.4-5.5-2.4 0-4.5 1.3-5.6 3.2a6.47 6.47 0 0 0-5.6-3.2c-3.2 0-5.9 2.4-6.4 5.5H8.1c-.1 0-.2 0-.2-.1L4.1 43c-.1-.2-.1-.3-.1-.4l2-7.5c0-.1.2-.2.3-.2H60v10c0 .1 0 .2-.1.3" />
  </svg>
);
export default IconTruckAlt;
