import * as React from "react";
const IconCalendarOutline = (props) => (
  <svg
    className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M18 4h-1V3a1 1 0 1 0-2 0v1H9V3a1 1 0 0 0-2 0v1H6a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3M6 6h1v1a1 1 0 0 0 2 0V6h6v1a1 1 0 1 0 2 0V6h1a1 1 0 0 1 1 1v4H5V7a1 1 0 0 1 1-1m0 14h12a1 1 0 0 0 1-1v-6H5v6a1 1 0 0 0 1 1"
      clipRule="evenodd"
    />
    <path d="M8 17a1 1 0 1 0 0-2 1 1 0 0 0 0 2M16 15h-4a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2" />
  </svg>
);
export default IconCalendarOutline;
