import * as React from "react";
const IconHospitalBedPatient = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 11.7c-.6 0-1 .4-1 1v7H35.5v-4.8c0-2.2-1.8-4-4-4H6c-2.2 0-4 1.8-4 4v10.2c0 3.5 2.9 6.4 6.4 6.4h11l10.8 6.8-9.2 5.8c-.9-.9-2.2-1.5-3.7-1.5-2.9 0-5.2 2.3-5.2 5.2s2.3 5.2 5.2 5.2 5.2-2.3 5.2-5.2c0-.7-.2-1.4-.4-2l10-6.3 10 6.3c-.3.6-.4 1.3-.4 2 0 2.9 2.3 5.2 5.2 5.2s5.2-2.3 5.2-5.2-2.3-5.2-5.2-5.2c-1.4 0-2.7.6-3.7 1.5l-9.3-5.8 10.8-6.8h10.9c3.5 0 6.4-2.9 6.4-6.4V12.7c0-.5-.4-1-1-1M17.3 51.1c-1.8 0-3.2-1.4-3.2-3.2s1.4-3.2 3.2-3.2 3.2 1.4 3.2 3.2-1.5 3.2-3.2 3.2m29.6-6.5c1.8 0 3.2 1.4 3.2 3.2S48.7 51 46.9 51s-3.2-1.4-3.2-3.2 1.4-3.2 3.2-3.2M33.5 14.9v4.8h-9.1v-6.8h7.1c1.1 0 2 .9 2 2m-18.9 4.8v-6.8h7.9v6.8zM4 14.9c0-1.1.9-2 2-2h6.6v6.8H4zm28.1 22.3-8.9-5.6h17.7zM60 25.1c0 2.4-2 4.4-4.4 4.4H8.4c-2.4 0-4.4-2-4.4-4.4v-3.4h56z" />
  </svg>
);
export default IconHospitalBedPatient;
