import * as React from "react";
const IconHdVideoAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 22.7c-.6-.4-1.5-.6-2.2-.3l-11.3 4.1v-4.9c0-2.2-1.8-4-4-4H6c-2.2 0-4 1.8-4 4v20.9c0 2.2 1.8 4 4 4h37.5c2.2 0 4-1.8 4-4v-4.9l11.3 4.1c.3.1.5.1.8.1.5 0 1-.1 1.4-.4.6-.4 1-1.2 1-2V24.6c0-.7-.4-1.5-1-1.9M43.5 44.4H6c-1.1 0-2-.9-2-2V21.6c0-1.1.9-2 2-2h37.5c1.1 0 2 .9 2 2v20.7c0 1.2-.9 2.1-2 2.1m16.5-5c0 .2-.1.3-.2.3s-.2.1-.4 0l-11.9-4.3v-6.9l11.9-4.3c.2-.1.3 0 .4 0s.2.1.2.3z" />
    <path d="M21.6 25.4c-.6 0-1 .4-1 1V31h-6.7v-4.6c0-.6-.4-1-1-1s-1 .4-1 1v11.2c0 .6.4 1 1 1s1-.4 1-1V33h6.7v4.6c0 .6.4 1 1 1s1-.4 1-1V26.4c0-.5-.5-1-1-1M34.7 25.4h-5.3c-.6 0-1 .4-1 1v11.2c0 .6.4 1 1 1h5.3c1.9 0 3.5-1.6 3.5-3.5v-6.2c0-1.9-1.6-3.5-3.5-3.5m1.5 9.7c0 .8-.7 1.5-1.5 1.5h-4.3v-9.2h4.3c.8 0 1.5.7 1.5 1.5z" />
  </svg>
);
export default IconHdVideoAlt;
