import * as React from "react";
const IconExpressShopping = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M47.5 4.7H40c-.3 0-.6.1-.7.3-.2.2-.3.5-.3.8l1.5 12.3H3c-.3 0-.6.2-.8.4s-.2.6-.1.9l7.3 19.9c.1.4.5.7.9.7H44c.3 0 .6-.1.7-.3.2-.2.3-.5.2-.8L41.2 6.7h6.3c.6 0 1-.4 1-1s-.5-1-1-1M42.9 38H11L4.4 20.1h36.3zM38.1 47.3H14.9c-2.8.3-4.7 3.3-4.3 6.6.4 3.1 2.7 5.4 5.2 5.4h.5c2.8-.3 4.7-3.3 4.3-6.6-.2-1.3-.7-2.5-1.4-3.4h15.4c-.9 1.2-1.3 2.9-1.1 4.6.4 3.1 2.7 5.4 5.2 5.4h.5c2.8-.3 4.7-3.3 4.3-6.6-.4-3.1-2.8-5.5-5.4-5.4m-19.5 5.6c.3 2.2-.9 4.2-2.5 4.4-1.7.2-3.3-1.4-3.5-3.6-.3-2.2.9-4.2 2.5-4.4h.30000000000000004c1.6 0 2.9 1.6 3.2 3.6M39 57.3c-1.7.2-3.3-1.4-3.5-3.6-.3-2.2.9-4.2 2.5-4.4h.3c1.6 0 3 1.6 3.2 3.6.3 2.2-.9 4.2-2.5 4.4M56.6 22.3c0-.6-.4-1-1-1h-5.5c-.6 0-1 .4-1 1s.4 1 1 1h5.5c.6 0 1-.4 1-1M61 29.4h-9.5c-.6 0-1 .4-1 1s.4 1 1 1H61c.6 0 1-.4 1-1s-.4-1-1-1M58.3 37.5h-6.8c-.6 0-1 .4-1 1s.4 1 1 1h6.8c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconExpressShopping;
