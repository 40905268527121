import * as React from "react";
const IconWell = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.3 16.7 53 4.2C52.6 2.9 51.5 2 50.1 2H13.9c-1.4 0-2.5.9-2.9 2.2L7.7 16.7c-.2.7 0 1.5.4 2.1.5.6 1.2.9 1.9.9h4.9v24.5h-.4c-2.9 0-5.2 2.3-5.2 5.2v7.3c0 2.9 2.3 5.2 5.2 5.2h35c2.9 0 5.2-2.3 5.2-5.2v-7.3c0-2.9-2.3-5.2-5.2-5.2h-.4V19.7H54c.8 0 1.5-.3 1.9-.9s.6-1.4.4-2.1m-3.6 32.8v7.3c0 1.8-1.4 3.2-3.2 3.2h-35c-1.8 0-3.2-1.4-3.2-3.2v-7.3c0-1.8 1.4-3.2 3.2-3.2h35c1.8 0 3.2 1.4 3.2 3.2m-5.5-5.2H16.8V19.7H31V27h-.5c-.6 0-1 .4-1 1v3c0 .6.4 1 1 1h3c.6 0 1-.4 1-1v-3c0-.6-.4-1-1-1H33v-7.3h14.2zM32.5 29.1v1h-1v-1zm21.8-11.5c0 .1-.1.2-.3.2H10c-.2 0-.3-.1-.3-.2s-.1-.2-.1-.4L13 4.7c.1-.4.5-.7 1-.7h36.2c.5 0 .8.3 1 .7l3.4 12.5c-.1.2-.2.3-.3.4" />
  </svg>
);
export default IconWell;
