import * as React from "react";
const IconMessageCross = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.9 40c-.4-.4-1-.4-1.4 0l-4.8 4.8-4.9-4.8c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l4.8 4.8-4.8 4.8c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l4.8-4.8 4.8 4.8c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4L55 46.3l4.8-4.8c.4-.4.4-1.1.1-1.5" />
    <path d="M56.9 11.2H7.1c-2.8 0-5.1 2.3-5.1 5.1v31.4c0 2.8 2.3 5.1 5.1 5.1h27.6c.6 0 1-.4 1-1s-.4-1-1-1H7.1c-1.7 0-3.1-1.4-3.1-3.1V16.3c0-.4.1-.8.2-1.1l25 15.2c.9.5 1.8.8 2.8.8s1.9-.3 2.8-.8l25-15.2c.1.3.2.7.2 1.1v14.4c0 .6.4 1 1 1s1-.4 1-1V16.3c0-2.8-2.3-5.1-5.1-5.1M33.8 28.7c-1.1.7-2.4.7-3.5 0l-24.7-15c.5-.3 1-.4 1.6-.4H57c.6 0 1.1.2 1.6.4z" />
  </svg>
);
export default IconMessageCross;
