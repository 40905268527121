import * as React from "react";
const IconKeyboardCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 12.5H7c-2.8 0-5 2.2-5 5v28.9c0 2.8 2.2 5 5 5h50c2.8 0 5-2.2 5-5V17.5c0-2.7-2.2-5-5-5m3 34c0 1.7-1.3 3-3 3H7c-1.7 0-3-1.3-3-3v-29c0-1.7 1.3-3 3-3h50c1.7 0 3 1.3 3 3z" />
    <path d="M24.1 25.7v-3.3c0-.6-.4-1-1-1h-3.3c-.6 0-1 .4-1 1v3.3c0 .6.4 1 1 1h3.3c.5 0 1-.4 1-1M24.1 33.6c0 .6.4 1 1 1h3.3c.6 0 1-.4 1-1v-3.3c0-.6-.4-1-1-1h-3.3c-.6 0-1 .4-1 1zM34.6 33.6c0 .6.4 1 1 1h3.3c.6 0 1-.4 1-1v-3.3c0-.6-.4-1-1-1h-3.3c-.6 0-1 .4-1 1zM30.4 26.7h3.3c.6 0 1-.4 1-1v-3.3c0-.6-.4-1-1-1h-3.3c-.6 0-1 .4-1 1v3.3c0 .6.4 1 1 1M44.2 21.5h-3.3c-.6 0-1 .4-1 1v3.3c0 .6.4 1 1 1h3.3c.6 0 1-.4 1-1v-3.3c0-.6-.5-1-1-1M42.5 40.5h-21c-.6 0-1 .4-1 1s.4 1 1 1h21.1c.6 0 1-.4 1-1s-.5-1-1.1-1" />
  </svg>
);
export default IconKeyboardCopy;
