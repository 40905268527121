import * as React from "react";
const IconMeasureTape = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M22.1 26.4c5.3 0 9.4-2.4 9.4-5.4s-4.1-5.4-9.4-5.4-9.4 2.4-9.4 5.4 4.1 5.4 9.4 5.4m0-8.9c4.4 0 7.4 1.8 7.4 3.4s-3 3.4-7.4 3.4-7.4-1.8-7.4-3.4 3-3.4 7.4-3.4" />
    <path d="M56.4 32.8h-24c5.8-2.4 9.8-6.8 9.8-11.9 0-7.7-9-13.9-20.1-13.9C11.3 7 2.5 13 2 20.4v13.9C2 46.8 12.2 57 24.7 57h31.7c3.1 0 5.6-2.5 5.6-5.6V38.5c0-3.1-2.5-5.7-5.6-5.7M22.1 9c10 0 18.1 5.3 18.1 11.9s-8.1 11.9-18.1 11.9S4 27.5 4 20.9 12.1 9 22.1 9M60 51.3c0 2-1.6 3.6-3.6 3.6h-5.2v-4.6c0-.6-.4-1-1-1s-1 .4-1 1V55H38.8v-4.6c0-.6-.4-1-1-1s-1 .4-1 1V55H26.4v-4.6c0-.6-.4-1-1-1s-1 .4-1 1V55C13.1 54.8 4 45.6 4 34.3v-7.4c3.2 4.7 10.1 7.9 18.1 7.9h34.3c2 0 3.6 1.6 3.6 3.6z" />
  </svg>
);
export default IconMeasureTape;
