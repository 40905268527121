import * as React from "react";
const IconBookshelf3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.7 2H8.3C5.5 2 3.2 4.3 3.2 7.2v49.6c0 2.9 2.3 5.2 5.2 5.2h47.3c2.9 0 5.2-2.3 5.2-5.2V7.2C60.8 4.3 58.5 2 55.7 2m.2 27.1c-.5-.3-1.1-.1-1.4.4l-5.6 10.4H40l5.1-9.4c.3-.5.1-1.1-.4-1.4s-1.1-.1-1.4.4l-5.6 10.4h-8.9l5.1-9.4c.3-.5.1-1.1-.4-1.4s-1.1-.1-1.4.4l-5.6 10.4H5.2V22.4h53.7v17.5h-7.6l5.1-9.4c.2-.5 0-1.1-.5-1.4M8.3 4h47.3c1.8 0 3.2 1.4 3.2 3.2v13.3H36.5l-5.6-10.4c-.3-.5-.9-.7-1.4-.4s-.7.9-.4 1.4l5.1 9.4h-8.9l-5.6-10.4c-.3-.5-.9-.7-1.4-.4s-.7.9-.4 1.4l5.1 9.4h-8.9L8.6 10.1c-.2-.5-.8-.7-1.3-.4-.5.2-.7.8-.4 1.3l5.1 9.4H5.2V7.2C5.2 5.4 6.6 4 8.3 4M5.2 56.8V41.9H31V60H8.3c-1.7 0-3.1-1.4-3.1-3.2M55.7 60H33V41.9h25.8v14.9c0 1.8-1.4 3.2-3.1 3.2" />
    <path d="M17.7 48.6c-.6 0-1 .4-1 1v3c0 .6.4 1 1 1s1-.4 1-1v-3c0-.5-.4-1-1-1M45.6 48.6c-.6 0-1 .4-1 1v3c0 .6.4 1 1 1s1-.4 1-1v-3c0-.5-.5-1-1-1" />
  </svg>
);
export default IconBookshelf3;
