import * as React from "react";
const IconCartFullCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M37.1 49c-3.6 0-6.5 2.9-6.5 6.5s2.9 6.5 6.5 6.5 6.5-2.9 6.5-6.5-2.9-6.5-6.5-6.5m0 11c-2.5 0-4.5-2-4.5-4.5s2-4.5 4.5-4.5 4.5 2 4.5 4.5-2 4.5-4.5 4.5M17.9 49c-3.6 0-6.5 2.9-6.5 6.5s2.9 6.5 6.5 6.5 6.5-2.9 6.5-6.5-2.9-6.5-6.5-6.5m0 11c-2.5 0-4.5-2-4.5-4.5s2-4.5 4.5-4.5 4.5 2 4.5 4.5-2 4.5-4.5 4.5M58.4 2H53c-1.8 0-3.4 1.4-3.6 3.1l-1.8 12.7H41V9.2c0-1.1-.9-1.9-1.9-1.9h-8.6v-.5c0-1.2-.8-2.1-1.9-2.1h-8.7c-1.1 0-1.9 1-1.9 2.1v3.1H9.2c-1.1 0-1.9.8-1.9 1.8v6.1h-.6c-.7 0-1.3.3-1.7.8s-.5 1.2-.4 1.9l6.2 18.8c.2.9 1.1 1.6 2.1 1.6h27.9c3.1 0 5.8-2.3 6.3-5.4l4.3-30.1c.1-.8.8-1.4 1.6-1.4h5.4c.6 0 1-.4 1-1s-.4-1-1-1M38.9 9.3v8.6h-8.6V9.3zM19.8 6.8v-.1h8.5v11.1h-8.6v-11zM9.2 11.9h8.6v5.9H9.2zm35.9 23.4c-.3 2.1-2.1 3.7-4.3 3.7H12.9c-.1 0-.1 0-.1-.1l-6.2-19h40.699999999999996z" />
  </svg>
);
export default IconCartFullCopy;
