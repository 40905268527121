import * as React from "react";
const IconPlaceholders = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M43 10.4c-2.2 0-4.3.4-6.2 1.1-3.6-2.8-8.1-4.5-13-4.5C12.3 7 3 16.3 3 27.8c0 11.3 19.3 28.2 20.2 29 .2.2.4.2.7.2s.5-.1.7-.2c.4-.4 5.6-4.9 10.5-10.7 3.8 4.2 7.3 7.2 7.3 7.3.2.2.4.2.7.2s.5-.1.7-.2c.6-.7 17.2-15.3 17.2-25 0-9.9-8.1-18-18-18M23.8 54.7C20.4 51.6 5 37.1 5 27.8 5 17.4 13.4 9 23.8 9s18.8 8.4 18.8 18.8c0 9.3-15.4 23.8-18.8 26.9M43 51.2c-1.2-1.1-3.9-3.6-6.7-6.7 4.4-5.4 8.3-11.6 8.3-16.7 0-5.8-2.3-11-6.1-14.7q2.25-.6 4.5-.6c8.8 0 16 7.2 16 16 0 7.7-12.9 19.9-16 22.7" />
    <path d="M23.8 18.5c-5 0-9.1 4.1-9.1 9.1s4.1 9.1 9.1 9.1 9.1-4.1 9.1-9.1-4.1-9.1-9.1-9.1m0 16.3c-3.9 0-7.1-3.2-7.1-7.1s3.2-7.1 7.1-7.1 7.1 3.2 7.1 7.1-3.2 7.1-7.1 7.1" />
  </svg>
);
export default IconPlaceholders;
