import * as React from "react";
const IconBlackboardCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.1 9.9H33V5.6c0-.6-.4-1-1-1s-1 .4-1 1v4.3H7.9C4.7 9.9 2 12.6 2 15.8V35c0 3.3 2.7 5.9 5.9 5.9h10.3l-9.5 17c-.3.4-.1 1.1.3 1.3.2.1.3.1.5.1.4 0 .7-.2.9-.5l10-17.9H31v17.5c0 .6.4 1 1 1s1-.4 1-1V40.9h10.7l10.1 17.9c.2.3.5.5.9.5.2 0 .3 0 .5-.1.5-.3.7-.9.4-1.4l-9.6-17h10.1c3.3 0 5.9-2.7 5.9-5.9V15.8c0-3.2-2.7-5.9-5.9-5.9M60 35c0 2.2-1.8 3.9-3.9 3.9H7.9C5.8 38.9 4 37.2 4 35V15.8c0-2.2 1.8-3.9 3.9-3.9h48.2c2.2 0 3.9 1.8 3.9 3.9z" />
  </svg>
);
export default IconBlackboardCopy;
