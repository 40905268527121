import * as React from "react";
const IconMackup3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 12.3H6c-2.2 0-4 1.8-4 4v31.5c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V16.3c0-2.2-1.8-4-4-4m2 35.4c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V16.3c0-1.1.9-2 2-2h52c1.1 0 2 .9 2 2z" />
    <path d="M46.1 40.8H18.5c-.6 0-1 .4-1 1s.4 1 1 1h27.6c.6 0 1-.4 1-1s-.5-1-1-1M18.7 25.5c1.4 0 2.5-1.1 2.5-2.5s-1.1-2.5-2.5-2.5-2.5 1.1-2.5 2.5 1.1 2.5 2.5 2.5M27.7 25.5c1.4 0 2.5-1.1 2.5-2.5s-1.1-2.5-2.5-2.5-2.5 1.1-2.5 2.5 1.1 2.5 2.5 2.5M36.6 25.5c1.4 0 2.5-1.1 2.5-2.5s-1.1-2.5-2.5-2.5-2.5 1.1-2.5 2.5 1.1 2.5 2.5 2.5M45.6 25.5c1.4 0 2.5-1.1 2.5-2.5s-1.1-2.5-2.5-2.5-2.5 1.1-2.5 2.5 1.1 2.5 2.5 2.5M21.5 32.6c0-1.4-1.1-2.5-2.5-2.5s-2.5 1.1-2.5 2.5 1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5M28 30c-1.4 0-2.5 1.1-2.5 2.5S26.6 35 28 35s2.5-1.1 2.5-2.5S29.4 30 28 30M37 30c-1.4 0-2.5 1.1-2.5 2.5S35.6 35 37 35s2.5-1.1 2.5-2.5S38.4 30 37 30M45.9 30c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5" />
  </svg>
);
export default IconMackup3;
