import * as React from "react";
const IconCloudNetworkCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.8 43.2c-3.1 0-5.7 2.2-6.1 5.1h-3.4v-8.9c5.5-1.7 9.5-6.7 9.5-12.7 0-3.3-1.3-6.6-3.6-9-2-2.2-4.8-3.7-7.8-4.1-1.6-2.6-4-4.6-6.8-5.6-1.2-.5-2.7-.7-4.2-.7-7.1 0-12.8 5.6-12.9 12.6-5.3.3-9.5 4.7-9.5 10 0 4.4 2.9 8.2 6.9 9.5v8.9h-3.4c-.5-2.9-3.1-5.1-6.1-5.1C4.8 43.2 2 46 2 49.4s2.8 6.1 6.2 6.1c3.1 0 5.7-2.2 6.1-5.1h3.8c.9 0 1.6-.7 1.6-1.6V40c.4 0 .8.1 1.2.1h10v4.5c-3 .5-5.2 3-5.2 6 0 3.4 2.8 6.1 6.2 6.1s6.2-2.8 6.2-6.1c0-3-2.3-5.6-5.2-6V40h9.3c.7 0 1.3-.1 1.9-.2v8.9c0 .9.7 1.6 1.6 1.6h3.8c.5 2.9 3.1 5.1 6.1 5.1 3.4 0 6.2-2.8 6.2-6.1s-2.6-6.1-6-6.1M8.2 53.5c-2.3 0-4.2-1.9-4.2-4.1s1.9-4.1 4.2-4.1 4.2 1.9 4.2 4.1-1.8 4.1-4.2 4.1m28-2.9c0 2.3-1.9 4.1-4.2 4.1s-4.2-1.9-4.2-4.1 1.9-4.1 4.2-4.1c2.3-.1 4.2 1.8 4.2 4.1M21.1 38c-4.6 0-8.2-3.5-8.2-8.1s3.6-8.1 8.2-8.1h.4c.6 0 1-.4 1-1V20c0-6 4.9-10.8 10.9-10.8 1.3 0 2.5.2 3.5.6 2.5.9 4.7 2.7 6 5.1.2.3.4.5.8.5 2.7.3 5.3 1.6 7.1 3.5 2 2.1 3 4.9 3 7.7 0 6.2-5.1 11.3-11.5 11.3H21.1zm34.7 15.5c-2.3 0-4.2-1.9-4.2-4.1s1.9-4.1 4.2-4.1 4.2 1.9 4.2 4.1-1.9 4.1-4.2 4.1" />
  </svg>
);
export default IconCloudNetworkCopy;
