import * as React from "react";
const IconToiletPaper = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M21.304 11.65c.012.037.088.221.542.453.505.258 1.273.447 2.176.447s1.67-.189 2.176-.447c.454-.232.53-.416.542-.453-.012-.036-.088-.22-.542-.453-.505-.258-1.273-.447-2.176-.447s-1.67.19-2.176.447c-.454.232-.53.417-.542.453m-.367-2.234c.84-.43 1.933-.666 3.085-.666s2.245.237 3.086.666c.787.402 1.635 1.138 1.635 2.234s-.848 1.832-1.635 2.234c-.841.43-1.934.666-3.086.666s-2.244-.236-3.085-.666c-.788-.402-1.636-1.137-1.636-2.234s.848-1.832 1.636-2.234"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M15.269 7.838C13.026 8.984 12 10.386 12 11.65c0 1.265 1.026 2.667 3.269 3.812 2.188 1.118 5.282 1.838 8.753 1.838s6.565-.72 8.753-1.838c2.243-1.145 3.27-2.547 3.27-3.812s-1.027-2.666-3.27-3.812C30.587 6.721 27.494 6 24.022 6c-3.471 0-6.564.72-8.753 1.838m-.91-1.78C16.884 4.767 20.302 4 24.022 4s7.139.768 9.663 2.057c2.47 1.262 4.36 3.185 4.36 5.593s-1.89 4.332-4.36 5.593c-2.525 1.29-5.942 2.057-9.663 2.057s-7.138-.768-9.663-2.057C11.89 15.982 10 14.058 10 11.65s1.889-4.331 4.36-5.593"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M12 36.351v-24.7h-2v24.7c0 2.408 1.889 4.331 4.36 5.593 2.524 1.29 5.942 2.057 9.662 2.057 2.546 0 4.951-.36 7.024-1h-7.024v-1c-3.471 0-6.564-.72-8.753-1.838C13.026 39.018 12 37.616 12 36.35m20.846 3.775.478.975-.64 1.307q.52-.218 1.001-.464c2.47-1.262 4.36-3.185 4.36-5.593v-24.7h-2v24.7c0 1.251-1.005 2.637-3.199 3.775"
      clipRule="evenodd"
    />
    <path
      
      d="M15.79 21.3a1 1 0 1 1-2 0 1 1 0 0 1 2 0M15.79 28.9a1 1 0 1 1-2 0 1 1 0 0 1 2 0M15.79 36.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0M15.79 25.1a1 1 0 1 1-2 0 1 1 0 0 1 2 0M15.79 32.7a1 1 0 1 1-2 0 1 1 0 0 1 2 0"
    />
    <path
      
      fillRule="evenodd"
      d="M23.022 18.3a1 1 0 0 1 1-1h9.302a1 1 0 0 1 .898 1.44l-.715 1.46.715 1.461a1 1 0 0 1 0 .88L33.507 24l.715 1.46a1 1 0 0 1 0 .88l-.715 1.46.715 1.46a1 1 0 0 1 0 .88l-.715 1.46.715 1.46a1 1 0 0 1 0 .88l-.715 1.46.715 1.46a1 1 0 0 1 0 .88l-.715 1.46.715 1.46a1 1 0 0 1 0 .88l-.93 1.9a1 1 0 0 1-.898.56h-8.372a1 1 0 0 1 0-2h7.748l.44-.9-.715-1.46a1 1 0 0 1 0-.88l.715-1.46-.715-1.46a1 1 0 0 1 0-.88l.715-1.46-.715-1.46a1 1 0 0 1 0-.88l.715-1.46-.715-1.46a1 1 0 0 1 0-.88l.715-1.46-.715-1.46a1 1 0 0 1 0-.88l.715-1.46-.715-1.46a1 1 0 0 1 0-.88l.226-.46h-7.699a1 1 0 0 1-1-1"
      clipRule="evenodd"
    />
  </svg>
);
export default IconToiletPaper;
