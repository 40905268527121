import React, { useState } from 'react';
import { BringzzButton, BringzzText } from "@bringzz/components";
import { useNavigation } from 'context/NavigationContext';
import {
    BringzzPageContainer,
    BringzzIcon,
    BringzzRadioList,
    BringzzCountryDropdown,
    BringzzInput
} from "@bringzz/components";
import { useLocation } from 'react-router-dom';

const ShopperCompletePage = () => {
    const { backPress } = useNavigation();

    const { navigate } = useNavigation();

    return (
        <BringzzPageContainer
            className="bg-white"
            header={{
                left: (
                    <BringzzIcon
                        size='22'
                        icon='IconChevronLeft'
                        folder='LineIcons'
                        className='cursor-pointer'
                        onClick={backPress}
                    />
                )
            }}
            footer={
                <div className='px-4 mb-4'>
                    <BringzzButton
                        size='lg'
                        className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
                        onClick={() => {
                            navigate('/home');
                        }}
                    >
                        Continue
                    </BringzzButton>
                </div>
            }
            authenticated={false}
        >
            <div className='relative flex justify-center bg-magic-lilac/20'>
                <div className='relative bg-white w-full bg-cover bg-center flex flex-col'>
                    {/* Content */}
                    <div className='flex-grow flex flex-col py-8 px-4'>
                        <div className='z-10 flex flex-col '>

                            <div className='flex flex-col text-center items-center space-y-12 mx-auto md:max-w-lg lg:max-w-xl xl:max-w-2xl'>
                                <BringzzText tag='h1' className='text-midnight-blue'>
                                    Onboarding complete!
                                </BringzzText>
                                <div className=''>
                                    <BringzzIcon
                                        icon="IconMountain"
                                        library="Custom"
                                        className="flex items-center justify-center h-36 w-36 bg-gray-100 rounded-full "
                                        size="90"
                                    />
                                </div>
                                <div className='space-y-4 px-6'>
                                    <BringzzText
                                        tag='h4'
                                        className='text-midnight-blue/25 font-normal'
                                    >
                                        Your account will be ready to receive the first order request within the next X hh(dd)s. We will send you an email to <span className='font-bold text-black'>name@company.com</span> and notification as soon as you are all set.
                                    </BringzzText>
                                    <BringzzText
                                        tag='h4'
                                        className='text-midnight-blue/25 font-normal'
                                    >
                                        In the meanwhile, you can start exploring the app and start by ordering your professional kit.
                                        Thanks for joining the Bringzz community!
                                    </BringzzText>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </BringzzPageContainer >
    );
};

export default ShopperCompletePage;
