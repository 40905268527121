import * as React from "react";
const IconHomeQuarantine = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M24 16a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-4 2a4 4 0 1 1 8 0 4 4 0 0 1-8 0M19.068 25.95A7.16 7.16 0 0 1 24 24c1.841 0 3.616.696 4.932 1.95C30.25 27.205 31 28.917 31 30.714v3.429a1 1 0 0 1-1 1h-1.56l-1.055 6.03A1 1 0 0 1 26.4 42h-4.8a1 1 0 0 1-.985-.828l-1.055-6.03H18a1 1 0 0 1-1-1v-3.428c0-1.797.75-3.51 2.068-4.764M24 26a5.16 5.16 0 0 0-3.553 1.398C19.514 28.286 19 29.48 19 30.714v2.429h1.4a1 1 0 0 1 .985.828L22.44 40h3.12l1.055-6.03a1 1 0 0 1 .985-.827H29v-2.429c0-1.234-.514-2.428-1.447-3.316A5.16 5.16 0 0 0 24 26"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M23.398 6.202a1 1 0 0 1 1.204 0l16.43 12.39q.042.032.079.066A2.8 2.8 0 0 1 42 20.6v18.925A2.48 2.48 0 0 1 39.525 42H8.475A2.48 2.48 0 0 1 6 39.525V20.6q0-.03.002-.059c.042-.719.36-1.393.887-1.883a1 1 0 0 1 .079-.066zM8.22 20.15a.8.8 0 0 0-.221.483V39.52a.48.48 0 0 0 .48.48h31.04a.484.484 0 0 0 .48-.48V20.635a.8.8 0 0 0-.22-.483L24 8.253z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconHomeQuarantine;
