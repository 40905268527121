import * as React from "react";
const IconBandageAdhesive = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M26.828 8.444a9 9 0 0 1 12.728 12.728L21.172 39.557A9 9 0 0 1 8.444 26.829zm11.314 1.414a7 7 0 0 0-9.9 0L9.859 28.243a7 7 0 1 0 9.9 9.9l18.384-18.385a7 7 0 0 0 0-9.9"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M24 16.929a2 2 0 0 1 2.828 0l4.243 4.242a2 2 0 0 1 0 2.829L24 31.07a2 2 0 0 1-2.828 0l-4.243-4.242a2 2 0 0 1 0-2.828zm5.657 5.657-4.243-4.243-7.071 7.071 4.243 4.243z"
      clipRule="evenodd"
    />
    <path
      
      d="M33.121 16.121a1 1 0 1 1-1.414-1.413 1 1 0 0 1 1.414 1.413M34.121 12.121a1 1 0 1 1-1.414-1.413 1 1 0 0 1 1.414 1.413M30.121 13.121a1 1 0 1 1-1.414-1.413 1 1 0 0 1 1.414 1.413M36.121 19.121a1 1 0 1 1-1.414-1.413 1 1 0 0 1 1.414 1.413M37.121 15.121a1 1 0 1 1-1.414-1.413 1 1 0 0 1 1.414 1.413M14.707 31.707a1 1 0 1 1 1.414 1.415 1 1 0 0 1-1.414-1.415M13.707 35.707a1 1 0 1 1 1.414 1.415 1 1 0 0 1-1.414-1.415M17.707 34.707a1 1 0 1 1 1.414 1.415 1 1 0 0 1-1.414-1.415M11.707 28.707a1 1 0 1 1 1.414 1.415 1 1 0 0 1-1.414-1.415M10.707 32.707a1 1 0 1 1 1.414 1.415 1 1 0 0 1-1.414-1.415"
    />
  </svg>
);
export default IconBandageAdhesive;
