import * as React from "react";
const IconBananaAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M34.7 46.8c-1.9 0-3.7-.2-5.2-.5C18.1 44.2 8.6 35.7 8 31.3 7 24 2.4 19 2.3 18.9c-.4-.4-.4-1 0-1.4s1-.4 1.4 0c.1.2 2.4 2.7 4.3 6.7 0 0 .1 0 .1.1 5.8 4.3 15.6 6.2 15.7 6.2 19.5 3.2 29.5-3.2 32.9-6.1 1.2-1 2.9-1 4.1 0s1.5 2.7.8 4c-8.3 15.1-19.1 18.4-26.9 18.4M9.1 27.4c.3 1.1.6 2.3.8 3.6.5 3.5 9 11.3 19.9 13.3 8.5 1.6 20.7-.1 30-16.9.3-.5.1-1.1-.3-1.5-.5-.4-1.1-.4-1.6 0-3.6 3.1-14.2 9.9-34.6 6.6-.3-.1-8.1-1.6-14.2-5.1" />
  </svg>
);
export default IconBananaAlt;
