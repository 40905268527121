import * as React from "react";
const IconCourt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 65 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.5 52.7H57V41c0-.6-.4-1-1-1h-7.5V29.3h5.9c.4 0 .8-.3.9-.7s0-.8-.3-1.1l-21.9-18c-.4-.3-.9-.3-1.3 0l-21.9 18c-.3.3-.4.7-.3 1.1s.5.7.9.7h5.9V40H9c-.6 0-1 .4-1 1v11.7H4.5c-.6 0-1 .4-1 1s.4 1 1 1h56c.6 0 1-.4 1-1s-.4-1-1-1M55 42v10.7h-6.5V42zm-8.5 10.7h-8V29.3h8zm-10-23.4v23.3h-8V29.3zm-4-17.7 19.1 15.7H13.4zm-6 17.7v23.3h-8V29.3zM10 42h6.5v10.7H10z" />
  </svg>
);
export default IconCourt;
