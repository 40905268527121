import * as React from "react";
const IconGarbageTruck = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.7 31.5-5.6-10.2c-.4-.6-1-1-1.8-1h-7.7c-1.3 0-2.3 1-2.3 2.3v1.1H33.5l2.1-11h4.9c.6 0 1-.4 1-1s-.4-1-1-1H9.7c-1.4 0-2.8.6-3.7 1.7s-1.4 2.5-1.2 3.9l2 15h-.4c-1 0-2 .7-2.2 1.7l-2 7.5c-.2.7 0 1.5.5 2.1L6.3 47c.4.5 1.1.8 1.8.8h2.4c.5 3.1 3.2 5.5 6.4 5.5 2.4 0 4.5-1.3 5.6-3.2 1.1 1.9 3.2 3.2 5.6 3.2 3.2 0 5.9-2.4 6.4-5.5h6.9c.5 3.1 3.2 5.5 6.4 5.5s5.9-2.4 6.4-5.5h2.7c.6 0 1.2-.2 1.5-.6l2.6-2.3c.5-.4.8-1.1.8-1.7V32.6c.2-.4.1-.8-.1-1.1m-15-9.3h7.7l4.9 9H46.4v-8.7q0-.3.3-.3m-2.3 3.4v5.6H32l1.1-5.6zM6.7 16c-.1-.8.1-1.7.7-2.3s1.4-1 2.2-1h23.8L30 31.2H8.7zm10.2 35.4c-2.5 0-4.5-2-4.5-4.5s2-4.5 4.5-4.5 4.5 2 4.5 4.5-2 4.5-4.5 4.5m11.2 0c-2.5 0-4.5-2-4.5-4.5s2-4.5 4.5-4.5 4.5 2 4.5 4.5-2 4.5-4.5 4.5m19.8 0c-2.5 0-4.5-2-4.5-4.5s2-4.5 4.5-4.5 4.5 2 4.5 4.5-2 4.5-4.5 4.5m12-7.9-2.6 2.3s-.1.1-.2.1h-2.7c-.5-3.1-3.2-5.5-6.4-5.5s-5.9 2.4-6.4 5.5h-6.9c-.5-3.1-3.2-5.5-6.4-5.5-2.4 0-4.5 1.3-5.6 3.2a6.47 6.47 0 0 0-5.6-3.2c-3.2 0-5.9 2.4-6.4 5.5H8.1c-.1 0-.2 0-.2-.1l-3.8-4.5c-.1-.1-.1-.2-.1-.3l2-7.5c0-.1.2-.2.3-.2H60v10c0 .1 0 .1-.1.2" />
  </svg>
);
export default IconGarbageTruck;
