import * as React from "react";
const IconArrowUpCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m53.7 28-21-20.7c-.4-.4-1-.4-1.4 0L10.3 28c-.4.4-.4 1 0 1.4s1 .4 1.4 0L31 10.5V56c0 .6.4 1 1 1s1-.4 1-1V10.3l19.4 19c.2.2.4.3.7.3s.5-.1.7-.3c.3-.3.3-1-.1-1.3" />
  </svg>
);
export default IconArrowUpCopy;
