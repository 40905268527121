import * as React from "react";
const IconDiagnosisAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M48 11.8h-3.7V9.6c0-1.1-.9-2-2-2H33V3c0-.6-.4-1-1-1s-1 .4-1 1v4.3c0 .1 0 .2.1.4h-9.3c-1.1 0-2 .9-2 2v2.1H16c-2.8 0-5.1 2.3-5.1 5.1v40c0 2.8 2.3 5.1 5.1 5.1h32c2.8 0 5.1-2.3 5.1-5.1v-40c0-2.8-2.3-5.1-5.1-5.1M21.8 9.6h20.5v6.3H21.8zm29.3 47.3c0 1.7-1.4 3.1-3.1 3.1H16c-1.7 0-3.1-1.4-3.1-3.1v-40c0-1.7 1.4-3.1 3.1-3.1h3.7v2.1c0 1.1.9 2 2 2h20.5c1.1 0 2-.9 2-2v-2.1H48c1.7 0 3.1 1.4 3.1 3.1z" />
    <path d="m39 42.3-8.8 8.8-3.3-3.3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l4 4c.2.2.4.3.7.3s.5-.1.7-.3l9.5-9.5c.4-.4.4-1 0-1.4s-1-.3-1.4 0M39.8 25.2H24.2c-.6 0-1 .4-1 1s.4 1 1 1h15.5c.6 0 1-.4 1-1s-.4-1-.9-1M39.8 34H24.2c-.6 0-1 .4-1 1s.4 1 1 1h15.5c.6 0 1-.4 1-1s-.4-1-.9-1" />
  </svg>
);
export default IconDiagnosisAlt;
