import * as React from "react";
const IconRuralPostAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      d="M25 9.548a1 1 0 1 0-2 0v2.5h-2.5a1 1 0 1 0 0 2H23v2.5a1 1 0 0 0 2 0v-2.5h2.5a1 1 0 0 0 0-2H25z"
    />
    <path
      
      fillRule="evenodd"
      d="M25 37v-8h11v8zm2-2v-4h7v4z"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M17 10.048a4 4 0 0 1 4-4h6a4 4 0 0 1 4 4v5h8.388c.706 0 1.318.487 1.477 1.175l1.11 4.822A4.9 4.9 0 0 1 40 26.17v15.877H8V26.171a4.9 4.9 0 0 1-1.976-5.126l1.111-4.822a1.515 1.515 0 0 1 1.477-1.175H17zm10-2a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2h-6a2 2 0 0 1-2-2v-6a2 2 0 0 1 2-2zm0 12a4 4 0 0 0 3.874-3h1.267v4.469a3.531 3.531 0 0 1-7.063 0v-1.47zm-9.874-3c.444 1.725 2.01 3 3.874 3h1.922v1.469a3.531 3.531 0 0 1-7.063 0v-4.47zm20.073 10q.411 0 .801-.065v13.065H22V29h-9v11.048h-3V26.983q.39.065.8.065c1.735 0 3.259-.9 4.13-2.26a5.52 5.52 0 0 0 4.46 2.26A5.53 5.53 0 0 0 24 24.575a5.53 5.53 0 0 0 4.61 2.473c1.83 0 3.453-.89 4.46-2.26a4.9 4.9 0 0 0 4.129 2.26m-28.202-10-1.024 4.446a2.902 2.902 0 1 0 5.73.652v-5.098zm31.03 4.446-1.025-4.446h-4.705v5.098a2.902 2.902 0 1 0 5.73-.652M20 31h-5v9h5z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconRuralPostAlt;
