import * as React from "react";
const IconHammer = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M10.8 28.2h.2l22-.7c.2.6.8 1.1 1.4 1.3l2.7.8c.1 3.3 2.9 6 6.2 6h4.9c3.4 0 6.2-2.8 6.2-6.2 0-4.9-.1-12.6-.1-12.6 0-.4-.1-10.9-11.1-14.9-.7-.2-1.5-.2-2.1.3-.6.4-1 1.1-1 1.8v8.3l-.7.8h-4.6c-1.2 0-2.2 1-2.2 2.2v.8l-21.8-.8c-1.7-.1-3.3.6-4.6 1.7-1.2 1.2-1.9 2.8-1.9 4.5v.4c0 1.7.7 3.3 1.9 4.5 1.5 1.1 3 1.8 4.6 1.8m26.6-10.6h3.4c.7 0 1.3-.3 1.7-.8l1.9-2.2q.6-.6.6-1.5V7.6c5 3.4 5.1 8.8 5.1 9.1 0 0 .1 7.6.1 12.6 0 1-.8 1.7-1.7 1.7h-4.9c-1 0-1.7-.8-1.7-1.7v-1.4c0-1-.7-1.9-1.6-2.2l-2.7-.8v-7.3zM9.1 21.5c0-.5.2-.9.5-1.3.3-.3.8-.5 1.3-.5l21.9.8v2.4l-22 .7c-.5 0-.9-.2-1.3-.5-.3-.3-.5-.8-.5-1.3v-.3zM57 47.3h-8.7v-2.8h3.3c1.2 0 2.2-1 2.2-2.2s-1-2.2-2.2-2.2H41.4c-1.2 0-2.2 1-2.2 2.2s1 2.2 2.2 2.2h2.3v2.8H20.1v-5.5H23c1.2 0 2.2-1 2.2-2.2s-1-2.2-2.2-2.2H12.9c-1.2 0-2.2 1-2.2 2.2s1 2.2 2.2 2.2h2.7v5.5H7c-2.9 0-5.2 2.4-5.2 5.2V57c0 2.9 2.4 5.2 5.2 5.2h50c2.9 0 5.2-2.4 5.2-5.2v-4.4c0-2.9-2.3-5.3-5.2-5.3m.7 9.7c0 .4-.3.8-.8.8H7c-.4 0-.8-.3-.8-.8v-4.4c0-.4.3-.8.8-.8h50c.4 0 .8.3.8.8V57z" />
  </svg>
);
export default IconHammer;
