import * as React from "react";
const IconDirectionAltCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M46.3 31.2H35.6v-8.7h5.8c.7 0 1.3-.3 1.8-.8l3.5-3.9c.8-.9.8-2.3 0-3.2l-3.5-3.9c-.5-.5-1.1-.8-1.8-.8h-5.8V3c0-.6-.4-1-1-1s-1 .4-1 1v6.9H17.7c-1.3 0-2.4 1.1-2.4 2.4v7.8c0 1.3 1.1 2.4 2.4 2.4h15.9v8.7h-11c-.7 0-1.3.3-1.8.8l-3.5 3.9c-.8.9-.8 2.3 0 3.2l3.5 3.9c.5.5 1.1.8 1.8.8h11.1c-.1.1-.1.3-.1.4V61c0 .6.4 1 1 1s1-.4 1-1V44.1c0-.1 0-.3-.1-.4h10.8c1.3 0 2.4-1.1 2.4-2.4v-7.8c0-1.3-1-2.3-2.4-2.3m-29-11.1v-7.8c0-.2.2-.4.4-.4h23.7c.1 0 .2 0 .3.1l3.5 3.9c.1.2.1.4 0 .5l-3.5 3.9c-.1.1-.2.1-.3.1H17.7c-.2.1-.4-.1-.4-.3m29.4 21.2c0 .2-.2.4-.4.4H22.6c-.1 0-.2 0-.3-.1l-3.5-3.9c-.1-.2-.1-.4 0-.5l3.5-3.9c.1-.1.2-.1.3-.1h23.7c.2 0 .4.2.4.4z" />
  </svg>
);
export default IconDirectionAltCopy;
