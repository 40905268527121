import * as React from "react";
const IconYcombinator = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M1 1v62h62V1zm33.1 35.2V49H30V36.2L19.2 16.1h4.9s7.9 16.1 8 16.3c.1-.7 8.2-16.2 8.2-16.2h4.5z" />
  </svg>
);
export default IconYcombinator;
