import * as React from "react";
const IconHappy2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M43.8 17.9c-4.2 0-7.7 3.9-7.7 8.7 0 .6.4 1 1 1s1-.4 1-1c0-3.7 2.5-6.7 5.7-6.7 3.1 0 5.7 3 5.7 6.7 0 .6.4 1 1 1s1-.4 1-1c0-4.8-3.4-8.7-7.7-8.7M25.9 26.7c0 .6.4 1 1 1s1-.4 1-1c0-4.8-3.4-8.7-7.7-8.7s-7.7 3.9-7.7 8.7c0 .6.4 1 1 1s1-.4 1-1c0-3.7 2.5-6.7 5.7-6.7s5.7 2.9 5.7 6.7" />
    <path d="M62 30.5C61.2 14.6 48 2 32 2S2.8 14.6 2 30.5V32c0 1.6.1 3.1.3 4.6C4.6 50.9 17 62 32 62s27.4-11.1 29.7-25.4c.2-1.5.3-3 .3-4.6zM32 60C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
    <path d="M44.4 44.5c-3.4 3.1-7.8 4.8-12.4 4.8s-9-1.7-12.4-4.8c-.4-.4-1-.4-1.4.1-.4.4-.4 1 .1 1.4 3.7 3.5 8.6 5.4 13.7 5.4s10-1.9 13.7-5.4c.4-.4.4-1 .1-1.4-.3-.5-1-.5-1.4-.1" />
  </svg>
);
export default IconHappy2;
