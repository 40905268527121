import * as React from "react";
const IconMobileRocket1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.4 10.2c0-.5-.4-.9-.9-.9-4.3-.2-9.7.6-17.1 8.2L36 24.2l-7.4.1c-.3 0-.5.1-.7.3l-4.4 4.6c-.4.4-.4 1 0 1.4l4.9 4.7c-.5.3-.9.6-1.3 1-1.8 1.8-2.6 8-2.7 9.2 0 .3.1.6.3.8s.4.3.7.3h.2c1.2-.2 7.3-1.2 9.1-3.1.4-.4.7-.9.9-1.4l4.9 4.7c.2.2.4.3.7.3s.5-.1.7-.3l4.4-4.6c.2-.2.3-.4.3-.7l-.1-7.4 6.4-6.6c7.2-7.5 7.8-12.9 7.5-17.3M33.1 42.1c-.8.8-4 1.7-6.6 2.2.4-2.6 1.2-5.8 2-6.7q.6-.6 1.5-.9l3.9 3.8c-.1.7-.4 1.2-.8 1.6m11.5-9c-.2.2-.3.4-.3.7l.1 7.4-3.4 3.5-15.4-14.9 3.4-3.5 7.4-.1c.3 0 .5-.1.7-.3l6.7-6.9c6.3-6.5 10.8-7.6 14.6-7.6.1 3.9-.9 8.4-7.1 14.9z" />
    <path d="M39.8 53.7c-.6 0-1 .4-1 1 0 2.9-2.4 5.3-5.3 5.3H10.8c-2.9 0-5.3-2.4-5.3-5.3V9.3C5.5 6.4 7.9 4 10.8 4h3.6v3.3c0 2.3 1.9 4.1 4.1 4.1h8c2.3 0 4.1-1.9 4.1-4.1V4h2.9c2.9 0 5.3 2.4 5.3 5.3v1.6c0 .6.4 1 1 1s1-.4 1-1V9.3c0-4-3.3-7.3-7.3-7.3H10.8c-4 0-7.3 3.3-7.3 7.3v45.5c0 4 3.3 7.3 7.3 7.3h22.7c4 0 7.3-3.3 7.3-7.3 0-.6-.4-1.1-1-1.1M28.6 7.3c0 1.2-1 2.1-2.1 2.1h-8c-1.2 0-2.1-1-2.1-2.1V4h12.2z" />
    <path d="M19.7 53.9h4.8c.6 0 1-.4 1-1s-.4-1-1-1h-4.8c-.6 0-1 .4-1 1s.5 1 1 1" />
  </svg>
);
export default IconMobileRocket1;
