import * as React from "react";
const IconFontAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M11.3 13.2c.6 0 1-.4 1-1V6.8c0-1.5 1.3-2.8 2.8-2.8h11.1v36.9h-5.9c-.6 0-1 .4-1 1s.4 1 1 1h23.4c.6 0 1-.4 1-1s-.4-1-1-1h-5.9V4h11.1c1.5 0 2.8 1.3 2.8 2.8v5.4c0 .6.4 1 1 1s1-.4 1-1V6.8c0-2.6-2.2-4.8-4.8-4.8H15.1c-2.6 0-4.8 2.2-4.8 4.8v5.4c0 .5.5 1 1 1m24.5 27.7h-7.6V4h7.6zM55.7 55.3 51 50.6c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l3.2 3.2H11.5l3.2-3.2c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-5 5c-.2.1-.3.4-.3.7s.1.5.3.7l4.7 4.7c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-3.2-3.2h41.2l-3.2 3.2c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l5-5c.5-.4.5-1 .1-1.4" />
  </svg>
);
export default IconFontAlt;
