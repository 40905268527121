import * as React from "react";
const IconChair1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.4 41.9 44.9 29V3c0-.6-.4-1-1-1s-1 .4-1 1v3.7H21.1V3c0-.6-.4-1-1-1s-1 .4-1 1v26l-5.5 12.9c-.1.1-.1.3-.1.4V61c0 .6.4 1 1 1s1-.4 1-1V43.3h7.3v11.6c0 .6.4 1 1 1s1-.4 1-1V43.3h12v11.6c0 .6.4 1 1 1s1-.4 1-1V43.3h9.6V61c0 .6.4 1 1 1s1-.4 1-1V41.9m-34.3-.6 4.7-11.1h22.4l4.7 11.1zm26.8-13.1H21.1V20h21.8zm0-19.5v9.2H21.1V8.7z" />
  </svg>
);
export default IconChair1;
