import * as React from "react";
const IconCartAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.2 11.9c-.9-1.2-2.3-1.8-3.8-1.8H17.5l-.3-5C17.1 3.9 16.1 3 15 3H6.7c-.6 0-1 .4-1 1s.4 1 1 1H15c.1 0 .2.1.2.2l2.1 36a3.4 3.4 0 0 0 3.4 3.2h32.8c.6 0 1-.4 1-1s-.4-1-1-1H20.7c-.7 0-1.4-.6-1.4-1.3l-.4-7.4h31.5c2.3 0 4.3-1.6 4.8-3.9l3-13.8c.3-1.4-.1-2.9-1-4.1m-1 3.7-3 13.8c-.3 1.3-1.5 2.3-2.8 2.3H18.7l-1.2-19.6h35.8c.9 0 1.7.4 2.2 1.1.7.7.9 1.5.7 2.4M46.5 49.3c-3.2 0-5.9 2.6-5.9 5.9s2.6 5.9 5.9 5.9 5.9-2.6 5.9-5.9-2.6-5.9-5.9-5.9m0 9.7c-2.1 0-3.9-1.7-3.9-3.9s1.7-3.9 3.9-3.9 3.9 1.7 3.9 3.9-1.7 3.9-3.9 3.9M24.5 49.3c-3.2 0-5.9 2.6-5.9 5.9s2.6 5.9 5.9 5.9c3.2 0 5.9-2.6 5.9-5.9s-2.7-5.9-5.9-5.9m0 9.7c-2.1 0-3.9-1.7-3.9-3.9s1.7-3.9 3.9-3.9 3.9 1.7 3.9 3.9-1.8 3.9-3.9 3.9" />
  </svg>
);
export default IconCartAlt;
