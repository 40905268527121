import * as React from "react";
const IconRdtResultPv = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      d="M10.85 11.105c.238-.105.495-.131.743-.079s.485.185.677.39a1 1 0 0 0 1.46-1.368 3.3 3.3 0 0 0-1.722-.978 3.2 3.2 0 0 0-1.966.206 3.4 3.4 0 0 0-1.495 1.304A3.6 3.6 0 0 0 8 12.5c0 .678.188 1.346.547 1.92s.877 1.031 1.495 1.304a3.2 3.2 0 0 0 1.966.206 3.3 3.3 0 0 0 1.722-.978 1 1 0 1 0-1.46-1.368c-.192.205-.43.337-.677.39a1.2 1.2 0 0 1-.742-.079 1.4 1.4 0 0 1-.609-.537A1.6 1.6 0 0 1 10 12.5c0-.31.087-.61.242-.858.156-.248.37-.432.609-.537M38 22a2 2 0 1 1 0 4 2 2 0 0 1 0-4M31 24a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0"
    />
    <path
      
      fillRule="evenodd"
      d="M26 21H11a3 3 0 1 0 0 6h15a3 3 0 1 0 0-6m-13 2h-2a1 1 0 1 0 0 2h2zm2 2h3v-2h-3zm11 0h-6v-2h6a1 1 0 1 1 0 2"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M4 24a7 7 0 0 0 7 7h26a7 7 0 1 0 0-14H11a7 7 0 0 0-7 7m33-5H11a5 5 0 0 0 0 10h26a5 5 0 0 0 0-10M13 33a1 1 0 0 1 1-1h2.5a2.5 2.5 0 0 1 0 5H15v1a1 1 0 1 1-2 0zm2 2h1.5a.5.5 0 0 0 0-1H15zM28 9a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h1.5a2.5 2.5 0 0 0 0-5zm2.5 3H29v-1h1.5a.5.5 0 0 1 0 1"
      clipRule="evenodd"
    />
    <path
      
      d="M22.5 39a1 1 0 0 0 .936-.649l1.5-4a1 1 0 1 0-1.872-.702l-.564 1.503-.564-1.503a1 1 0 0 0-1.872.702l1.5 4A1 1 0 0 0 22.5 39M35 16a1 1 0 0 0 1-1v-2a1 1 0 0 0 0-2 1 1 0 0 0 0-2c-.726 0-1.276.325-1.611.79A2.1 2.1 0 0 0 34 11a1 1 0 1 0 0 2v2a1 1 0 0 0 1 1"
    />
  </svg>
);
export default IconRdtResultPv;
