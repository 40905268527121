import * as React from "react";
const IconShipDeliveryBoxAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M39.1 57.2c-.2-.2-.5-.4-.7-.5l4.3-15.2c.7-2.4-.5-4.9-2.9-5.9l-3-1.3v-4.6c0-.6-.4-1-1-1s-1 .4-1 1v3.7l-9.1-3.8a5 5 0 0 0-3.8 0l-9.2 3.8v-6.9c0-1.6 1.3-2.9 2.9-2.9h12.8c.6 0 1-.4 1-1s-.4-1-1-1h-11V16c0-1.6 1.3-2.9 2.9-2.9h7.5c.6 0 1-.4 1-1s-.4-1-1-1h-3.2V6.3c0-.6-.4-1-1-1s-1 .4-1 1v4.9h-2.4c-2.7 0-4.9 2.2-4.9 4.9v5.6c-2.6.1-4.7 2.3-4.7 4.9v7.7l-3 1.3c-2.3 1-3.5 3.5-2.9 5.9L9 56.7c-.3.1-.5.3-.7.5l-3.5 3c-.4.4-.5 1-.1 1.4s1 .5 1.4.1l3.5-3c1.1-.9 2.7-.9 3.8 0l.9.8c1.8 1.6 4.6 1.6 6.5 0l.9-.8c1.1-.9 2.8-.9 3.8 0l.9.8c1.8 1.6 4.6 1.6 6.5 0l.9-.8c1.1-.9 2.8-.9 3.8 0l3.5 3c.2.2.4.2.7.2s.6-.1.8-.3c.4-.4.3-1-.1-1.4zm-6.4 0-.9.8c-1.1 1-2.8 1-3.9 0l-.9-.8c-1.8-1.6-4.6-1.6-6.5 0l-.9.8c-1.1.9-2.7.9-3.8 0l-.9-.8c-1.1-.9-2.5-1.3-3.8-1.1L6.7 41c-.4-1.4.3-2.9 1.7-3.5L12 36h.1l10.5-4.4c.7-.3 1.5-.3 2.3 0L35.3 36h.1l3.6 1.5c1.4.6 2.1 2.1 1.7 3.5l-4.3 15.1c-1.3-.2-2.7.2-3.7 1.1M59.4 9.9l-2.6-7.2c-.2-.4-.5-.7-.9-.7h-15c-.4 0-.8.3-.9.7l-2.6 7.2c0 .1-.1.2-.1.3v13.4c0 .6.4 1 1 1h20.2c.6 0 1-.4 1-1V10.2c0-.1 0-.2-.1-.3M41.6 4h13.5l2.3 6.4v.2H39.7c-.1 0-.3 0-.4.1v-.3zm-2.3 18.6v-10c.1 0 .2.1.4.1h17.8v10H39.3z" />
  </svg>
);
export default IconShipDeliveryBoxAlt;
