import * as React from "react";
const IconSoftwareDeployment = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60 55.1h-4.9V31.2c0-2.7-2.2-5-5-5h-4.9q.9-2.4.9-5.1c0-2.5-.7-5-2-7.2-2.5-4.3-7-6.9-12-7h-.5c-5 .1-9.5 2.7-12 7-1.3 2.2-2 4.7-2 7.2q0 2.7.9 5.1h-4.9c-2.7 0-5 2.2-5 5v23.9H4c-.6 0-1 .4-1 1s.4 1 1 1h56c.6 0 1-.4 1-1s-.4-1-1-1M42.5 15c1.1 1.9 1.7 4 1.7 6.2v.4l-5.8-4c-2.8-1.9-4.5-5.1-4.6-8.5 3.6.5 6.8 2.6 8.7 5.9m-21 0c2.2-3.7 6-5.9 10.3-6 .1 4.1 2.1 7.9 5.5 10.2l6.7 4.6c-.2 1-.6 2-1 2.9 0 .1-.1.1-.1.2l-.9 1.5c-.5.7-1.1 1.3-1.7 1.9l-1.5-1.8c-2.1-2.6-5.4-4.1-8.8-4-1.1 0-2.2-.1-3.3-.5l-6.8-2.4v-.4c-.1-2.2.5-4.4 1.6-6.2m-1.4 8.7 5.9 2.1c1.3.5 2.7.7 4.1.6 2.7-.1 5.4 1.1 7.1 3.2l1.4 1.8c-1.9 1.2-4.1 1.9-6.5 1.9h-.3c-3.9-.1-7.4-1.9-9.7-5-1-1.3-1.7-2.9-2-4.6m13 31.4V44.5l4.9 5c.4.4 1 .4 1.4 0s.4-1 0-1.4l-6.6-6.8c-.2-.2-.4-.3-.7-.3s-.5.1-.7.3l-6.8 6.8c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l5.1-5.1V55H10.9V31.2c0-1.6 1.3-3 3-3h5.8c.3.4.5.9.8 1.3 2.7 3.6 6.8 5.8 11.2 5.8h.5c4.5-.1 8.6-2.2 11.3-5.8.3-.4.6-.9.8-1.3h5.8c1.6 0 3 1.3 3 3v23.9z" />
  </svg>
);
export default IconSoftwareDeployment;
