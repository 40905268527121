import * as React from "react";
const IconIdeaDown = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M26.1 23.1c.6.4 1 1.2 1 2V27c0 2.4 1.9 4.3 4.3 4.3h1c2.4 0 4.3-1.9 4.3-4.3v-2c0-.4.1-.8.3-1.1s.4-.6.7-.8c3.2-2 5.1-5.4 5.2-9.1.1-2.9-1-5.7-3.1-7.9S35 2.7 32.1 2.6c-3-.1-5.9 1-8 3.1-2.2 2.1-3.3 4.9-3.3 7.9.1 3.9 2.1 7.4 5.3 9.5m8.7 3.9c0 1.3-1 2.3-2.3 2.3h-1c-1.3 0-2.3-1-2.3-2.3v-2.5H35V27zM25.6 7.1c1.7-1.7 4-2.6 6.4-2.6h.2c2.4.1 4.6 1.1 6.3 2.9s2.6 4.1 2.5 6.5c-.1 3-1.7 5.8-4.3 7.5-.5.3-.8.7-1.2 1.1h-7.3c-.3-.4-.7-.8-1.1-1.1-2.7-1.7-4.3-4.6-4.3-7.7.1-2.5 1.1-4.8 2.8-6.6M61.8 58.3c0-.1.1-.1.1-.2s0-.1.1-.2v-.4l-2.7-10.2c-.1-.5-.7-.8-1.2-.7s-.9.7-.7 1.2l2 7.5L38 40.1c-2.3-1.6-5.4-1.3-7.3.9l-7.1 8.2c-1.2 1.4-3.3 1.6-4.7.5L3.6 37.8c-.4-.3-1.1-.3-1.4.2-.3.4-.3 1.1.2 1.4l15.2 11.8c2.3 1.8 5.6 1.5 7.5-.7l7.1-8.2c1.2-1.3 3.2-1.6 4.6-.5l21.9 15.6-8.2 2.1c-.5.1-.9.7-.7 1.2.1.4.5.7 1 .7h.3l10.3-2.7s.1 0 .1-.1c.1 0 .1 0 .1-.1 0 0 .1 0 .2-.2q0 .15 0 0" />
  </svg>
);
export default IconIdeaDown;
