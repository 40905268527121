import * as React from "react";
const IconPpeGoggles = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M17 9C9.82 9 4 14.82 4 22a5 5 0 0 0 4 4.9V29a8 8 0 0 0 15.653 2.336.56.56 0 0 1 .17-.264A.27.27 0 0 1 24 31c.05 0 .11.017.177.072.07.058.135.15.17.264A8 8 0 0 0 40 29v-2.1a5 5 0 0 0 4-4.9c0-7.18-5.82-13-13-13zm22.872 15.871A3 3 0 0 0 42 22c0-6.075-4.925-11-11-11H17c-6.075 0-11 4.925-11 11 0 1.354.896 2.498 2.128 2.871A5 5 0 0 1 13 21h22a5 5 0 0 1 4.872 3.871M10 26a3 3 0 0 1 3-3h22a3 3 0 0 1 3 3v3a6 6 0 0 1-11.74 1.753C25.98 29.833 25.14 29 24 29s-1.98.834-2.26 1.753A6 6 0 0 1 10 29z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconPpeGoggles;
