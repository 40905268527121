import * as React from "react";
const IconBattery = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 15.2h-3.7v-4.9c0-1.7-1.3-3-3-3h-9.2c-1.7 0-3 1.3-3 3v4.9H24.9v-4.9c0-1.7-1.3-3-3-3h-9.2c-1.7 0-3 1.3-3 3v4.9H6c-2.2 0-4 1.8-4 4v33.5c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V19.2c0-2.2-1.8-4-4-4m-16.9-4.9c0-.6.4-1 1-1h9.2c.6 0 1 .4 1 1v4.9H41.1zm-29.4 0c0-.6.4-1 1-1h9.2c.6 0 1 .4 1 1v4.9H11.7zM60 52.7c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V19.2c0-1.1.9-2 2-2h52c1.1 0 2 .9 2 2z" />
    <path d="m29.2 32.6 7.9-5.9c.4-.3.5-1 .2-1.4s-1-.5-1.4-.2L27 31.8c-.3.2-.4.5-.4.8s.1.6.4.8l7.9 5.9-7.9 5.9c-.4.3-.5 1-.2 1.4.2.3.5.4.8.4.2 0 .4-.1.6-.2l8.9-6.7c.3-.2.4-.5.4-.8s-.1-.6-.4-.8z" />
  </svg>
);
export default IconBattery;
