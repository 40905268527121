import * as React from "react";
const IconVideoCall = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 65 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M39.6 33.9c0-4.3-3.5-7.7-7.7-7.7s-7.7 3.5-7.7 7.7c0 4.3 3.5 7.7 7.7 7.7s7.7-3.5 7.7-7.7m-13.4 0c0-3.1 2.6-5.7 5.7-5.7 3.2 0 5.7 2.6 5.7 5.7s-2.6 5.7-5.7 5.7c-3.2 0-5.7-2.6-5.7-5.7M31.9 44.9c-5.7 0-11.2 2-15.4 5.6-.4.4-.5 1-.1 1.4.2.2.5.4.8.4.2 0 .5-.1.6-.2 3.9-3.3 8.9-5.1 14.1-5.1s10.2 1.8 14.1 5.1c.4.4 1.1.3 1.4-.1.4-.4.3-1.1-.1-1.4-4.2-3.7-9.7-5.7-15.4-5.7" />
    <path d="M56.7 11.7H8.3c-2.7 0-4.8 2.2-4.8 4.8v22.7c0 .6.4 1 1 1s1-.4 1-1V16.5c0-1.6 1.3-2.8 2.8-2.8h48.3c1.6 0 2.8 1.3 2.8 2.8v22.7c0 .6.4 1 1 1s1-.4 1-1V16.5c.1-2.6-2.1-4.8-4.7-4.8" />
  </svg>
);
export default IconVideoCall;
