import * as React from "react";
const IconCourtHouse = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.5 19.2 34.4 2.6c-1.5-.9-3.3-.9-4.8 0L2.5 19.2c-.4.2-.6.7-.4 1.1.1.4.5.7.9.7h4v39H4.4c-.6 0-1 .4-1 1s.4 1 1 1h19.2c.6 0 1-.4 1-1s-.4-1-1-1H21V21h22v39h-2.9c-.6 0-1 .4-1 1s.4 1 1 1h19.2c.6 0 1-.4 1-1s-.4-1-1-1h-2.4V21H61c.4 0 .8-.3 1-.7.1-.4-.1-.9-.5-1.1M18.9 60H9V21h9.9zm35.9 0H45V21h9.9v39zM6.6 19 30.7 4.3c.8-.5 1.8-.5 2.7 0l24 14.7z" />
  </svg>
);
export default IconCourtHouse;
