import * as React from "react";
const IconDeskChair = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M22.1 22.2c.2 1.2 1.1 2.1 2.1 2.1h15.6c1 0 1.9-.9 2.1-2l2.2-10.7c.4-2.6-.2-5.1-1.8-6.9C41 2.9 38.9 2 36.5 2h-8.9c-2.4 0-4.5.9-5.9 2.6-1.5 1.8-2.2 4.3-1.8 6.9zm1-16.3C24.2 4.7 25.7 4 27.5 4h8.9c1.8 0 3.3.7 4.4 1.9 1.2 1.4 1.6 3.3 1.3 5.2L40 21.9c0 .3-.2.4-.1.4H24.3s-.2-.2-.2-.5l-2.2-10.6c-.4-2 .1-3.9 1.2-5.3M57.7 25.2c-.2-.2-.5-.4-.8-.4H52c-.6 0-1 .4-1 1s.4 1 1 1h3.6c-1.7 5.1-5 9.5-9.4 12.6-.1-2.1-.8-4.2-2.2-5.7-1.7-2-4.1-3-6.8-3H26.7c-2.7 0-5.1 1.1-6.8 3-1.3 1.6-2.1 3.6-2.2 5.7-4.4-3.1-7.7-7.5-9.4-12.6H12c.6 0 1-.4 1-1s-.4-1-1-1H7.1c-.3 0-.6.1-.8.4-.2.2-.3.6-.2.9 1.8 6.8 6.2 12.5 12.3 16.1 3.8 2.3 8.2 3.5 12.6 3.7V60h-7.1c-.6 0-1 .4-1 1s.4 1 1 1h16.2c.6 0 1-.4 1-1s-.4-1-1-1H33V45.9c4.4-.2 8.8-1.4 12.6-3.7 6.1-3.6 10.5-9.3 12.3-16.1.1-.3 0-.6-.2-.9M21.5 35c1.3-1.5 3.2-2.3 5.3-2.3h10.5c2.1 0 4 .8 5.3 2.3s1.9 3.6 1.6 5.7c-3.7 2.1-7.9 3.2-12.2 3.2s-8.5-1.1-12.2-3.2c-.2-2.1.4-4.2 1.7-5.7" />
  </svg>
);
export default IconDeskChair;
