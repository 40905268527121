import * as React from "react";
const IconTurtle = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M52.1 20.3c-2.6 0-4.7 2.1-4.7 4.7v9c0 1.7-1.4 3.2-3.2 3.2h-2.9v-8.6c0-9.1-7.4-16.5-16.5-16.5h-1.1c-9.1 0-16.5 7.4-16.5 16.5v8.6H4c-.6 0-1 .4-1 1s.4 1 1 1h4.6V49c0 1.7 1.3 3 3 3H16c1.7 0 3-1.3 3-3v-9.8h11.2V49c0 1.7 1.3 3 3 3h4.3c1.7 0 3-1.3 3-3v-9.8h3.8c2.9 0 5.2-2.3 5.2-5.2v-2h8.6c1.6 0 2.9-1.3 2.9-2.9 0-4.9-4-8.8-8.9-8.8M9.3 28.5c0-8 6.5-14.5 14.5-14.5h1.1c8 0 14.5 6.5 14.5 14.5v8.6H9.3zM17 48.9c0 .6-.4 1-1 1h-4.3c-.6 0-1-.4-1-1v-9.8H17zm21.5 0c0 .6-.4 1-1 1h-4.3c-.6 0-1-.4-1-1v-9.8h6.3zM58.1 30h-8.6v-5.1c0-1.5 1.2-2.7 2.7-2.7 3.8 0 6.9 3.1 6.9 6.9-.1.5-.5.9-1 .9" />
  </svg>
);
export default IconTurtle;
