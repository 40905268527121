import * as React from "react";
const IconCast = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M42 16c0-1.014-.378-1.94-1-2.646V7H28v6.354c-.622.705-1 1.632-1 2.646v10h-9.687l-4.696-3.25a2.948 2.948 0 0 0-3.688 4.581l-.778.13A2.574 2.574 0 0 0 6.953 32a2.572 2.572 0 0 0 .004 4.003A2.53 2.53 0 0 0 6 37.983c0 1.274.948 2.355 2.222 2.505 1.641.193 4.482.512 5.466.512v-.012Q13.843 41 14 41h24a4 4 0 0 0 4-4zM10.388 38.72a4 4 0 0 1-.382-1.492l-1.556.233a.53.53 0 0 0-.45.523c0 .268.198.487.456.517.57.068 1.25.146 1.932.22M8.48 33.434 10 33.18v1.639l-1.52-.254a.574.574 0 0 1 0-1.132m0-2.868 1.52.254V30q0-.43.087-.834l-1.607.268a.574.574 0 0 0 0 1.132m2.999-6.172 2.327 1.61a4 4 0 0 0-2.356.914L10.3 25.876a.948.948 0 0 1 1.178-1.482M21.054 39l-6.377-11H14a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2zM16.99 28l6.377 11h4.688l-6.377-11zm21.53 10.932A2 2 0 0 1 38 39h-7.634L23.99 28h3.597zm1.413-1.414L29 26.586v-2.8l10.942 3.647.058.017V37q-.001.27-.068.518M40 16.686l-11 3.188v1.803l11 3.667zm-.451-1.951L29 17.792V16a2 2 0 0 1 2-2h7c.624 0 1.182.286 1.549.735M39 9h-9v3h9z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconCast;
