import * as React from "react";
const IconYenNote1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 13.8H6c-2.2 0-4 1.8-4 4v28.4c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V17.8c0-2.2-1.8-4-4-4M4 25.4c.7.2 1.5.4 2.2.4 4 0 7.3-3.3 7.3-7.3 0-.9-.2-1.8-.5-2.7h38c-.3.9-.5 1.8-.5 2.7 0 4 3.3 7.3 7.3 7.3.8 0 1.5-.1 2.2-.4v13.2c-.7-.2-1.5-.4-2.2-.4-4 0-7.3 3.3-7.3 7.3 0 .9.2 1.8.5 2.7H13c.3-.9.5-1.8.5-2.7 0-4-3.3-7.3-7.3-7.3-.8 0-1.5.1-2.2.4zm56-7.6v5.5c-.7.3-1.4.5-2.2.5-2.9 0-5.3-2.4-5.3-5.3 0-1 .3-1.9.8-2.7H58c1.1 0 2 .9 2 2m-54-2h4.7c.5.8.8 1.7.8 2.7 0 2.9-2.4 5.3-5.3 5.3-.8 0-1.5-.2-2.2-.5v-5.5c0-1.1.9-2 2-2M4 46.2v-5.5c.7-.3 1.4-.5 2.2-.5 2.9 0 5.3 2.4 5.3 5.3 0 1-.3 1.9-.8 2.7H6c-1.1 0-2-.9-2-2m54 2h-4.7c-.5-.8-.8-1.7-.8-2.7 0-2.9 2.4-5.3 5.3-5.3.8 0 1.5.2 2.2.5v5.5c0 1.1-.9 2-2 2" />
    <path d="M24.6 38.7h6.3v3.5c0 .6.4 1 1 1s1-.4 1-1v-3.5h6.5c.6 0 1-.4 1-1s-.4-1-1-1h-6.5v-4.5H39c.6 0 1-.4 1-1s-.4-1-1-1h-6.1v-2.7l4.9-4.9c.4-.4.4-1 0-1.4s-1-.4-1.4 0L32 25.6l-4.4-4.4c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l4.9 4.9v2.7H25c-.6 0-1 .4-1 1s.4 1 1 1h5.9v4.5h-6.3c-.6 0-1 .4-1 1s.5 1 1 1" />
  </svg>
);
export default IconYenNote1;
