import * as React from "react";
const IconPhoneForward = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M37.7 12.8h21.5l-6.8 7.1c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3l7.1-7.4c1.1-1.2 1.1-3 0-4.1l-7.1-7.4c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l6.8 7.1H37.7c-.6 0-1 .4-1 1s.5.9 1 .9M60.4 45.9l-9.1-6.1c-1.6-1.1-3.7-.6-5 1.3L44 44.4c-.4.6-1.2.8-1.8.4-12.4-7.8-20.2-18.6-23-23-.4-.6-.2-1.5.4-1.9l3.7-2.6c1.5-1.1 1.9-3.2.9-4.7l-6.1-9.1c-1-1.5-3-2-4.6-1.1L4.8 7.7c-.1 0-.1.1-.2.2-6.5 6.5-.2 22.9 14.3 37.4C25.7 52 33.2 57.2 40.1 60c3.4 1.3 6.4 2 9.1 2 2.9 0 5.3-.8 7-2.5l.2-.2 5.1-8.7c.9-1.6.5-3.6-1.1-4.7m-.6 3.6-5.1 8.6c-2.6 2.5-7.6 2.5-13.8 0-6.7-2.7-14-7.8-20.6-14.4C7.1 30.6.6 14.9 5.9 9.3l8.6-5.1c.7-.3 1.5-.2 1.9.5l6.1 9.1c.4.6.3 1.5-.4 1.9l-3.7 2.6c-1.5 1-1.9 3.1-.9 4.6 2.8 4.4 10.8 15.6 23.6 23.6 1.5.9 3.5.5 4.5-1l2.3-3.3c.7-.9 1.5-1.3 2.2-.8l9.1 6.1c.8.5 1 1.4.6 2" />
  </svg>
);
export default IconPhoneForward;
