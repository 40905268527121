import * as React from "react";
const IconBugAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.5 35.9H48.3V25.8h8.6c1.8 0 3.3-1.5 3.3-3.3v-9.7c0-.6-.4-1-1-1s-1 .4-1 1v9.7c0 .7-.6 1.3-1.3 1.3h-8.6v-.4c0-4.4-2.8-8.1-6.8-9.4v-2.5c0-2.6-1-5-2.8-6.8C36.9 3 34.4 2 31.8 2c-5.4 0-9.7 4.3-9.7 9.6v2.5c-3.9 1.3-6.8 5-6.8 9.4v.4H6.8c-.7 0-1.3-.6-1.3-1.3v-9.7c0-.6-.4-1-1-1s-1 .4-1 1v9.7c0 1.8 1.5 3.3 3.3 3.3h8.6V36H3.5c-.6 0-1 .4-1 1s.4 1 1 1h11.8v8.7c0 .5 0 1 .1 1.4H6.8c-1.8 0-3.3 1.5-3.3 3.3V61c0 .6.4 1 1 1s1-.4 1-1v-9.7c0-.7.6-1.3 1.3-1.3h8.7c.1 0 .2 0 .3-.1 1.5 5 6.1 8.7 11.6 8.7h8.7c5.5 0 10.2-3.7 11.6-8.7.1 0 .2.1.3.1h8.7c.7 0 1.3.6 1.3 1.3V61c0 .6.4 1 1 1s1-.4 1-1v-9.7c0-1.8-1.5-3.3-3.3-3.3H48c.1-.5.1-.9.1-1.4v-8.7h12.2c.6 0 1-.4 1-1s-.3-1-.8-1M24.1 11.6c0-4.2 3.5-7.6 7.7-7.6 2.1 0 4 .8 5.5 2.2s2.3 3.3 2.3 5.4v2.1c-.4 0-.8-.1-1.1-.1H25.3c-.4 0-.8 0-1.1.1v-2.1zm22.2 35c0 5.6-4.5 10.1-10.1 10.1h-8.7c-5.6 0-10.1-4.5-10.1-10.1V23.5c0-4.4 3.5-7.9 7.9-7.9h13.1c4.4 0 7.9 3.5 7.9 7.9z" />
  </svg>
);
export default IconBugAlt;
