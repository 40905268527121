import * as React from "react";
const IconSmartwatch = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M36.4 21.8q-2.55 0-4.5 1.2c-1.1-.6-2.6-1.2-4.4-1.2-4.1 0-7.4 3.4-7.4 7.8-.2 4.7 3.5 9 10.5 12.3l.9.4c.1 0 .2.1.4.1.1 0 .3 0 .4-.1l.8-.4c6.8-3.3 10.6-7.6 10.6-12.2.1-4.4-3.1-7.9-7.3-7.9m-4 18.3-.4.2-.5-.2c-3.6-1.7-9.5-5.2-9.3-10.4 0-3.3 2.4-5.8 5.4-5.8 1.7 0 3 .7 3.9 1.3.4.2.8.2 1.2-.1 1-.8 2.4-1.2 3.8-1.2 3 0 5.4 2.6 5.4 5.8-.1 5.1-6 8.7-9.5 10.4" />
    <path d="m47.1 12-6-9.6c-.3-.2-.6-.4-.9-.4H23.9c-.3 0-.7.2-.8.5L16.9 12c-3 .8-5.2 3.5-5.2 6.8v26.4c0 3.3 2.2 6 5.3 6.8l6.1 9.6c.2.3.5.5.8.5h16.3c.3 0 .7-.2.8-.5l6.1-9.6c3-.8 5.2-3.5 5.2-6.8V18.8c0-3.3-2.2-6-5.2-6.8M24.4 4h15.2l4.9 7.8h-25zm15.2 56H24.4l-4.9-7.8h25.1zm10.7-14.8c0 2.8-2.2 5-5 5H18.7c-2.8 0-5-2.2-5-5V18.8c0-2.8 2.2-5 5-5h26.6c2.8 0 5 2.2 5 5z" />
  </svg>
);
export default IconSmartwatch;
