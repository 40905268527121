import * as React from "react";
const IconLolypop = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.6 21.6c0-8.2-5-15.2-12.1-18.1-.1 0-.1 0-.2-.1C37 2.5 34.6 2 32 2c-10.8 0-19.6 8.8-19.6 19.6 0 10.4 8.2 19 18.6 19.5V61c0 .6.4 1 1 1s1-.4 1-1V41.1c10.3-.5 18.6-9.1 18.6-19.5M44.9 9.7 17 30.7c-1.2-2-2.1-4.3-2.4-6.8L39 5.5c2.3 1 4.2 2.4 5.9 4.2M32 4c1.7 0 3.3.2 4.8.7L14.4 21.5C14.5 11.8 22.3 4 32 4M18.1 32.3l28-21.1c1.5 2 2.6 4.4 3.1 6.9l-25.3 19c-2.2-1.1-4.2-2.8-5.8-4.8M32 39.1c-2.1 0-4.1-.4-5.9-1.1l23.4-17.7c0 .4.1.8.1 1.2 0 9.7-7.9 17.6-17.6 17.6" />
  </svg>
);
export default IconLolypop;
