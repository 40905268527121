import * as React from "react";
const IconMegaphoneAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.2 3.5c-1.5-1.2-3.5-1.5-5.3-1l-25.8 7H6c-2.2 0-4 1.8-4 4v19.8c0 2.2 1.8 4 4 4h1.3l4.1 19.6c.6 2.8 3.1 4.8 6 4.8h.6c1.8 0 3.6-.8 4.7-2.2s1.6-3.3 1.2-5.1l-3.6-17.1h.1l24.3 6.9c.6.2 1.1.2 1.7.2 1.3 0 2.6-.4 3.7-1.2 1.5-1.2 2.4-2.9 2.4-4.9V8.4c.1-2-.8-3.7-2.3-4.9M6 35.3c-1.1 0-2-.9-2-2V13.5c0-1.1.9-2 2-2h12.7v23.8H6m16 19.5c.3 1.2-.1 2.5-.8 3.4-.8 1-1.9 1.5-3.2 1.5h-.7c-1.9 0-3.6-1.4-4-3.3l-4-19.2h9.1zm28.6-16.5c0 1.3-.6 2.5-1.6 3.3s-2.3 1-3.6.7l-24.6-6.9V11.2l24.7-6.8c1.2-.3 2.5-.1 3.5.7s1.6 2 1.6 3.3zM61 17c-.6 0-1 .4-1 1v9.9c0 .6.4 1 1 1s1-.4 1-1V18c0-.6-.4-1-1-1" />
  </svg>
);
export default IconMegaphoneAlt;
