import * as React from "react";
const IconTrampoline = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 7.6C15.5 7.6 2 16.7 2 28v16.2c0 .6.4 1 1 1s1-.4 1-1v-8.9C8.2 42.7 18.5 48 30.7 48.4v7c0 .6.4 1 1 1s1-.4 1-1v-7c12.4-.2 23-5.6 27.3-13.1v8.9c0 .6.4 1 1 1s1-.4 1-1V28C62 16.7 48.5 7.6 32 7.6m0 38.8C16.6 46.4 4 38.1 4 28 4 17.8 16.6 9.6 32 9.6S60 17.8 60 28c0 10.1-12.6 18.4-28 18.4" />
    <path d="M32 18.4c-7.6 0-13.8 4.3-13.8 9.6s6.2 9.6 13.8 9.6 13.8-4.3 13.8-9.6-6.2-9.6-13.8-9.6m0 17.1c-6.5 0-11.8-3.4-11.8-7.6s5.3-7.6 11.8-7.6 11.8 3.4 11.8 7.6c0 4.3-5.3 7.6-11.8 7.6" />
  </svg>
);
export default IconTrampoline;
