import * as React from "react";
const IconOnlineCheckup = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55 4.4H9c-3.9 0-7 3.1-7 7v41.2c0 3.9 3.1 7 7 7h46c3.9 0 7-3.1 7-7V11.4c0-3.8-3.1-7-7-7m-46 2h46c2.8 0 5 2.2 5 5V18H4v-6.6c0-2.7 2.2-5 5-5m46 51.2H9c-2.8 0-5-2.2-5-5V20.1h56v32.5c0 2.7-2.2 5-5 5" />
    <path d="M48.3 41.2h-6c-.1 0-.3-.1-.3-.2l-5.3-10.6c-.4-.8-1.3-1.3-2.2-1.3s-1.7.6-2.1 1.4l-4.9 10.9c-.1.2-.3.2-.3.2-.1 0-.3 0-.3-.2L24 36.1c-.4-.8-1.2-1.3-2.1-1.3h-6.1c-.6 0-1 .4-1 1s.4 1 1 1h6.1c.1 0 .3.1.3.2l2.7 5.4c.4.8 1.2 1.3 2.1 1.3s1.7-.6 2.1-1.4L34 31.4c.1-.2.3-.2.3-.2.1 0 .3 0 .3.2L39.9 42c.4.8 1.2 1.3 2.1 1.3h6c.6 0 1-.4 1-1s-.2-1.1-.7-1.1" />
  </svg>
);
export default IconOnlineCheckup;
