import * as React from "react";
const IconChickenLeg = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 31c-2.1-1.2-4.6-1.3-6.8-.2l-14.4 7.8 1.8-26c.2-2.7-.8-5.5-2.6-7.5-1.9-2-4.5-3.2-7.3-3.2H12.6C9.8 2 7.2 3.2 5.3 5.2S2.5 10 2.7 12.7l2.6 34.6c.5 5.3 3.7 9.8 8.6 12.4 5.1 2.7 11.1 2.8 16 .2l15.8-8.5c.8 2.5 2.2 4.8 4.1 6.6 2.7 2.6 6.1 4 9.6 4 1 0 1.9-.9 1.9-1.9V36.8c0-2.4-1.2-4.6-3.3-5.8m1.3 29c-3 0-5.9-1.3-8.2-3.5-2-1.9-3.3-4.3-3.9-6.9-.1-.3-.3-.6-.6-.7-.1-.1-.3-.1-.4-.1-.2 0-.3 0-.5.1L28.8 58c-4.3 2.3-9.5 2.2-14.1-.2-4.3-2.3-7.1-6.3-7.5-10.8L4.7 12.5c-.2-2.2.6-4.4 2.1-6S10.4 4 12.6 4h16.2c2.2 0 4.3.9 5.8 2.5s2.3 3.7 2.1 6l-1.9 27.3-4.9 2.7c-.5.3-.7.9-.4 1.4s.9.7 1.4.4l21.4-11.7c1.5-.8 3.3-.8 4.8.1s2.4 2.4 2.4 4.2V60z" />
    <path d="M14.5 13.9h-1.1c-.6 0-1 .4-1 1s.4 1 1 1h1.1c.6 0 1-.4 1-1s-.4-1-1-1M26.4 21.3h-1.1c-.6 0-1 .4-1 1s.4 1 1 1h1.1c.6 0 1-.4 1-1s-.4-1-1-1M19 34.7h-1.1c-.6 0-1 .4-1 1s.4 1 1 1H19c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconChickenLeg;
