import * as React from "react";
const IconDialHigh = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 14.9c-11.6 0-21.1 9.4-21.1 21.1 0 11.6 9.5 21 21.1 21s21.1-9.4 21.1-21.1c0-11.5-9.5-21-21.1-21M32 55c-10.5 0-19.1-8.5-19.1-19S21.5 16.9 32 16.9 51.1 25.5 51.1 36 42.5 55 32 55M31.9 9h.2c.6 0 1-.4 1-1s-.4-1-1-1h-.2c-.6 0-1 .4-1 1s.5 1 1 1M51.2 16.9c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-.1-.1c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4zM12.8 55c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l.1.1c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4zM60 34.9c-.6 0-1 .4-1 1v.2c0 .6.4 1 1 1s1-.4 1-1v-.2c0-.6-.4-1-1-1M4 34.9c-.6 0-1 .4-1 1v.2c0 .6.4 1 1 1s1-.4 1-1v-.2c0-.6-.4-1-1-1M51.1 55l-.1.1c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3l.1-.1c.4-.4.4-1 0-1.4-.3-.4-1-.4-1.4 0M12.1 17.2c.3 0 .5-.1.7-.3l.1-.1c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-.1.1c-.4.4-.4 1 0 1.4.2.2.5.3.7.3" />
    <path d="M43.3 35H31.9c-.6 0-1 .4-1 1s.4 1 1 1h11.4c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconDialHigh;
