import * as React from "react";
const IconDressingTable = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.5 42.1h-9V14.3C47.6 7.5 42.1 2 35.3 2h-7.6C21 2 15.5 7.5 15.5 14.3v27.8h-8c-.6 0-1 .4-1 1s.4 1 1 1h5V61c0 .6.4 1 1 1s1-.4 1-1v-3.7h35.2V61c0 .6.4 1 1 1s1-.4 1-1V44.1h5c.6 0 1-.4 1-1s-.6-1-1.2-1m-39-27.8C17.5 8.6 22.1 4 27.8 4h7.6C41 4 45.6 8.6 45.6 14.3v27.8H17.5zm-3.1 29.8H31v11.2H14.4zm35.2 11.2H33V44.1h16.6z" />
    <path d="M23.4 48.9h-.7c-.6 0-1 .4-1 1s.4 1 1 1h.7c.6 0 1-.4 1-1s-.5-1-1-1M41.9 48.9h-.7c-.6 0-1 .4-1 1s.4 1 1 1h.7c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconDressingTable;
