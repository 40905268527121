import * as React from "react";
const IconHospitalLocation = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 50.6c1.1 0 2.1-.5 2.8-1.2 5-5.6 16.6-19.4 16.6-27.8 0-10.7-8.7-19.3-19.3-19.3-10.7 0-19.3 8.7-19.3 19.3 0 8.3 11.6 22.2 16.6 27.8.5.8 1.5 1.2 2.6 1.2m0-46.3c9.6 0 17.3 7.8 17.3 17.3 0 7.9-12.3 22.2-16.1 26.4-.7.7-1.9.7-2.6 0-3.8-4.2-16.1-18.6-16.1-26.4C14.7 12 22.4 4.3 32 4.3" />
    <path d="M53.2 39.3c-.5-.2-1.1 0-1.3.5s0 1.1.5 1.3c4.8 2.1 7.6 4.8 7.6 7.5 0 6-12.8 11.1-28 11.1s-28-5-28-11.1c0-2.7 2.8-5.5 7.8-7.6.5-.2.7-.8.5-1.3s-.8-.7-1.3-.5c-5.8 2.4-9 5.8-9 9.4C2 56 15.2 61.7 32 61.7S62 56 62 48.6c0-3.5-3.1-6.9-8.8-9.3M31 27.1c0 .6.4 1 1 1s1-.4 1-1v-5.2h5.2c.6 0 1-.4 1-1s-.4-1-1-1H33v-5.2c0-.6-.4-1-1-1s-1 .4-1 1v5.2h-5.2c-.6 0-1 .4-1 1s.4 1 1 1H31z" />
  </svg>
);
export default IconHospitalLocation;
