import React, { useState, Fragment } from "react";

export default function AccordionList({
    className,
    component,
    children,
}) {
    const [expanedElement, setExpandedElement] = useState(null);
    return (
        <div
            id={`${component.anchor_ ? component.anchor_ : 'AccList-' + component.uuid}`}
            className={`overflow-hidden w-full flex flex-wrap ${className}`}
        >
            {
                React.Children.map(
                    children, (child) => React.cloneElement(child, {
                        component: {
                            ...child.props.component,
                            initialDefault: component.accList_accListInitialDisplay,
                            autocollapse: component.accList_autocollapse,
                            expandedElement: expanedElement,
                            handleExpandedElement: setExpandedElement,
                            iconPos: component.accList_iconPosition,
                            textColor: component.accListCollapsedText_color,
                            textColorExpanded: component.accListExpandedText_color,
                            backgroundColor: component.accListCollapsedBackground_color,
                            backgroundColorExpanded: component.accListExpandedBackground_color,
                            iconColor: component.accListCollapsedbtnText_color,
                            iconColorExpanded: component.accListExpandedbtnText_color,
                            border: component.accList_borderWidth,
                            borderRadius: component.accList_borderRadius,
                            borderColor: component.accListCollapsedBorder_color,
                            borderColorExpanded: component.accListExpandedBorder_color,
                            accordion_desktopPaddingTop: component.accordion_desktopPaddingTop,
                            accordion_desktopPaddingBottom: component.accordion_desktopPaddingBottom,
                            accordion_desktopPaddingRight: component.accordion_desktopPaddingRight,
                            accordion_desktopPaddingLeft: component.accordion_desktopPaddingLeft,
                            accordion_tabletPaddingTop: component.accordion_tabletPaddingTop,
                            accordion_tabletPaddingBottom: component.accordion_tabletPaddingBottom,
                            accordion_tabletPaddingRight: component.accordion_tabletPaddingRight,
                            accordion_tabletPaddingLeft: component.accordion_zabletPaddingLeft,
                            accordion_mobilePaddingTop: component.accordion_mobilePaddingTop,
                            accordion_mobilePaddingBottom: component.accordion_mobilePaddingBottom,
                            accordion_mobilePaddingRight: component.accordion_mobilePaddingRight,
                            accordion_mobilePaddingLeft: component.accordion_mobilePaddingLeft,
                            //Bringzz
                            accordionSeparated_: component.accordionSeparated_,
                            title_textTag: component.title_textTag,
                            title_textAlignment: component.title_textAlignment,
                            title_fontWeight: component.title_fontWeight,
                            titleCollapsed_colorClass: component.titleCollapsed_colorClass,
                            titleExpanded_colorClass: component.titleExpanded_colorClass,
                            subtitle_textTag: component.subtitle_textTag,
                            subtitleCollapsed_colorClass: component.subtitleCollapsed_colorClass,
                            subtitleExpanded_colorClass: component.subtitleExpanded_colorClass,
                            subtitle_textAlignment: component.subtitle_textAlignment,
                            subtitle_fontWeight: component.subtitle_fontWeight
                        }
                    }))
            }
        </div>

    )
}