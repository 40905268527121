import * as React from "react";
const IconBarChartCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 57.4h-6.9V35.6c0-2.5-2.1-4.6-4.6-4.6h-6c-2.5 0-4.6 2.1-4.6 4.6v21.8H30V20c0-2.5-2.1-4.6-4.6-4.6h-6c-2.5 0-4.6 2.1-4.6 4.6v37.4H6.6c-1.4 0-2.6-1.2-2.6-2.6V5.6c0-.6-.4-1-1-1s-1 .4-1 1v49.1c0 2.5 2.1 4.6 4.6 4.6H61c.6 0 1-.4 1-1s-.4-.9-1-.9m-44.2 0V20c0-1.4 1.2-2.6 2.6-2.6h6c1.4 0 2.6 1.2 2.6 2.6v37.4zm24.1 0V35.6c0-1.4 1.2-2.6 2.6-2.6h6c1.4 0 2.6 1.2 2.6 2.6v21.8z" />
  </svg>
);
export default IconBarChartCopy;
