import React, { useState } from 'react';
import { useNavigation } from 'context/NavigationContext';
import {
    BringzzPageContainer,
    BringzzBottomDrawer,
    BringzzRadio,
    BringzzRadioList,
    BringzzButton, BringzzIcon, BringzzText, BringzzInput, BringzzDate, Mode
} from "@bringzz/components";
import { useAuth } from 'context/AuthContext';
import useRequest from 'hooks/useRequest';
import { returnGenderLabel } from 'utils';

function formatDate(dateString) {
    const date = new Date(dateString)
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
}

function parseDate(dateStr) {
    const [day, month, year] = dateStr.split(".");
    return new Date(year, month - 1, day);
}

let state = {
    checked: (
        <BringzzIcon
            icon="IconCheck"
            library="Custom"
            className="h-5 w-5 p-1 bg-magic-lilac text-light rounded-full"
            size="12"
        />
    ),
    unChecked: (
        <div></div>
    ),
};

const EditNameView = () => {
    const { user, refreshUserData } = useAuth()
    const { backPress, navigate } = useNavigation();
    const [selectedBirthday, setSelectedBirthday] = useState(user.birthday)
    const [selectedGender, setSelectedGender] = useState(user.gender)
    const [genderDrawerOpened, setGenderDrawerOpened] = useState(false)

    const [firstName, setFirstName] = useState(user.firstName || "")
    const [lastName, setLastName] = useState(user.lastName || "")
    const [height, setHeight] = useState(user.height || "")
    const [weight, setWeight] = useState(user.weight || "")

    const { sendRequest, data, error, loading } = useRequest();

    const handleSave = async () => {
        const data = {
            "firstName": firstName,
            "lastName": lastName
        }

        if (user.userRole == 1 || user.userRole == 4) {
            data['birthday'] = selectedBirthday
        }

        if (user.userRole == 4) {
            data['gender'] = selectedGender
            data['height'] = height
            data['weight'] = weight
        }

        const result = await sendRequest({
            method: 'POST',
            url: `/module/profile/changeName`,
            data
        })
            .then(response => {
                if (response.status === 'success') {
                    return response
                };
            })
            .catch(error => {
                console.error(error);
                return false;
            });

        if (result) {
            refreshUserData()
            backPress()
        }
    }

    return (
        <BringzzPageContainer
            header={{
                left: <BringzzIcon
                    size="22"
                    icon="IconChevronLeft"
                    folder="LineIcons"
                    className="cursor-pointer"
                    onClick={backPress}
                />,
                title: <BringzzText tag='h2'>Name</BringzzText>,
            }}
            footer={
                <div className='pb-28 px-6'>
                    <BringzzButton
                        size='lg'
                        className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
                        onClick={handleSave}
                        disabled={loading}
                    >
                        Save
                    </BringzzButton>
                </div>
            }
        >
            <div className='p-6 space-y-4'>
                <BringzzInput id='firstname' label="First Name" containerClassname="w-full" placeholder="First Name"
                    defaultValue={user.firstName} onChange={(e, valid, value) => setFirstName(value)}
                />
                <BringzzInput id='lastname' label="Last Name" containerClassname="w-full" placeholder="Last Name"
                    defaultValue={user.lastName} onChange={(e, valid, value) => setLastName(value)}
                />
                {user && user.userRole !== 5 &&
                    <div className='space-y-1'>
                        <BringzzText tag="h4" className="font-bold">
                            Birthday
                        </BringzzText>
                        <BringzzDate
                            // placeholder={selectedBirthday ? selectedBirthday : user.birthday}
                            format="dd.MM.yyyy"
                            defaultValue={parseDate(user.birthday)}
                            onChange={(newDate) => setSelectedBirthday(newDate)}
                            mode={Mode.DAY}
                            maxDate={new Date()}
                        ></BringzzDate>
                    </div>}
                {user && user.userRole === 4 &&
                    <div className='space-y-4'>
                        <BringzzInput
                            label="Gender"
                            placeholder={selectedGender ? returnGenderLabel(selectedGender) : "Select Gender"}
                            type="text"
                            containerClassname='w-full'
                            icon={{
                                right: <BringzzIcon
                                    icon="IconChevronDown"
                                    library="Custom"
                                    className="h-5 w-5 p-1 text-midnight-blue rounded-full"
                                    size="12"
                                />
                            }}
                            readOnly={true}
                            onClick={() => setGenderDrawerOpened(true)} />
                        <BringzzInput
                            id='height'
                            label="Height (cm)"
                            containerClassname="w-full"
                            placeholder="cm"
                            defaultValue={user.height}
                            onChange={(e, valid, value) => setHeight(value)} />
                        <BringzzInput
                            id='weight'
                            label="Weight (kg)"
                            containerClassname="w-full"
                            placeholder="kg"
                            defaultValue={user.weight}
                            onChange={(e, valid, value) => setWeight(value)} />
                    </div>}
                <BringzzBottomDrawer
                    isOpen={genderDrawerOpened}
                    close={() => setGenderDrawerOpened(false)}
                    title='Select Gender'
                >
                    <div className='px-4 py-6'>
                        <BringzzRadioList
                            onChange={(newValue) => {
                                console.log(newValue);
                                setSelectedGender(newValue)
                            }}
                            state={state}
                            className="rounded-xl w-full text-center"
                            defaultValue={user.gender}
                        >
                            <BringzzRadio value="0"
                                className="p-2 py-4 hover:bg-slate- border-1 border-t border-gray-400 flex"
                            >
                                <div>
                                    Male
                                </div>
                            </BringzzRadio>
                            <BringzzRadio value="1"
                                className="p-2 py-4 hover:bg-slate- border-1 border-t border-gray-400">
                                <div>
                                    Female
                                </div>
                            </BringzzRadio>
                            <BringzzRadio className="p-2 py-4 hover:bg-slate- border-1 border-t border-gray-400" value="2">
                                <div>Diverse</div>
                            </BringzzRadio>
                        </BringzzRadioList>
                    </div>

                    <div className='text-center mb-12 px-4'>
                        <BringzzButton
                            className='bg-magic-lilac w-full flex justify-center'
                            onClick={() => {
                                console.log();
                                setGenderDrawerOpened(false)
                            }}
                        >
                            Confirm
                        </BringzzButton>
                    </div>
                </BringzzBottomDrawer>
            </div>
        </BringzzPageContainer>
    );
}

export default EditNameView;
