import * as React from "react";
const IconConeAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.6 48.3h-4.8l-1.6-6.2c-.5-1.8-2.1-3.1-3.9-3.1h-1.5L42 5.6C41.8 3.5 40 2 38 2H26c-2.1 0-3.8 1.6-4 3.6L18.3 39h-1.8c-1.8 0-3.4 1.2-3.9 3l-1.7 6.2H6.4c-2.2 0-4 1.8-4 4V58c0 2.2 1.8 4 4 4h51.1c2.2 0 4-1.8 4-4v-5.7c.1-2.3-1.7-4-3.9-4m-35.1-29 18.4-5.9.8 7.6-20.2 6.4zm1.4-13.5c.1-1 1-1.8 2-1.8h12c1.1 0 1.9.8 2 1.8l.6 5.5-18 5.7zm-2.6 23.8L41.9 23l1.8 16H20.3zm-6.8 13c.2-.9 1.1-1.5 2-1.5h30.8c.9 0 1.8.6 2 1.5l1.4 5.7H13zM59.6 58c0 1.1-.9 2-2 2H6.4c-1.1 0-2-.9-2-2v-5.7c0-1.1.9-2 2-2h51.1c1.1 0 2 .9 2 2V58z" />
  </svg>
);
export default IconConeAlt;
