import * as React from "react";
const IconWord = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M15.154 5c-1.196 0-2.228 1.032-2.228 2.23V29a1 1 0 1 1-2 0V7.23c0-2.301 1.927-4.23 4.228-4.23h16.28c1 0 2.08.329 2.847 1.089l17.991 16.927c.964.791 1.283 1.928 1.283 3.042V29a1 1 0 1 1-2 0v-4.942q0-.11-.005-.212H35.335c-2.302 0-4.095-1.794-4.095-4.096V5zm18.086.856V19.75c0 1.198.899 2.096 2.095 2.096h14.901zm-9.308 31.736a4.52 4.52 0 1 1 9.039 0v3.678a4.52 4.52 0 1 1-9.04 0zm4.52-2.522a2.52 2.52 0 0 0-2.52 2.522v3.678a2.52 2.52 0 1 0 5.039 0v-3.678a2.52 2.52 0 0 0-2.52-2.522m17.509-.922a1 1 0 0 1 1-1h4.04a4 4 0 0 1 3.999 4v4.717a4 4 0 0 1-3.998 4H46.96a1 1 0 0 1-1-1zm2 1v8.717h3.04a2 2 0 0 0 1.999-2v-4.717a2 2 0 0 0-1.998-2zm-9.994.066c-.579 0-.999.449-.999.943v1.807h3.997c.578 0 .998-.449.998-.942v-.865c0-.494-.42-.943-.998-.943zm-.999 4.75v4.828a1 1 0 0 1-2 0v-8.635c0-1.652 1.37-2.943 3-2.943h2.997c1.629 0 2.998 1.29 2.998 2.943v.865c0 1.651-1.37 2.942-2.998 2.942h-.026l2.48 4.33a1 1 0 1 1-1.736.994l-3.048-5.324zM9.747 33.436a1 1 0 0 1 1.22.715l1.917 7.323 1.812-5.086a1 1 0 0 1 1.896.036l1.516 4.827 1.859-7.1a1 1 0 0 1 1.934.506l-2.738 10.462a1 1 0 0 1-1.921.046l-1.664-5.294-1.898 5.33a1 1 0 0 1-1.91-.082L9.034 34.657a1 1 0 0 1 .714-1.22m2.179 16.151a1 1 0 0 1 1 1v6.182c0 1.199 1.032 2.231 2.228 2.231h34.403a2 2 0 0 0 1.998-2v-6.413a1 1 0 1 1 2 0V57a4 4 0 0 1-3.998 4H15.154c-2.301 0-4.228-1.929-4.228-4.23v-6.183a1 1 0 0 1 1-1"
      clipRule="evenodd"
    />
  </svg>
);
export default IconWord;
