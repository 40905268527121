import React, { useState } from 'react';
import { BringzzButton, BringzzText } from "@bringzz/components";
import { useNavigation } from 'context/NavigationContext';
import {
    BringzzPageContainer,
    BringzzIcon,
    BringzzRadioList,
    BringzzCountryDropdown,
    BringzzInput
} from "@bringzz/components";
import { useLocation } from 'react-router-dom';

const AllowVerificationPage = () => {
    const { backPress } = useNavigation();

    const { navigate } = useNavigation();

    return (
        <BringzzPageContainer
            className="bg-white"
            header={{
                left: (
                    <BringzzIcon
                        size='22'
                        icon='IconChevronLeft'
                        folder='LineIcons'
                        className='cursor-pointer'
                        onClick={backPress}
                    />
                )
            }}
            footer={
                <div className='px-4 mb-4'>
                    <BringzzButton
                        size='lg'
                        className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
                        onClick={() => {
                            navigate('/home');
                        }}
                    >
                        Start verification
                    </BringzzButton>
                </div>
            }
            authenticated={false}
        >
            <div className='relative flex justify-center bg-magic-lilac/20'>
                <div className='relative bg-white w-full bg-cover bg-center flex flex-col'>
                    {/* Content */}
                    <div className='flex-grow flex flex-col py-8 px-4'>
                        <div className='z-10 flex flex-col '>

                            <div className='flex flex-col text-center items-center space-y-4 mx-auto md:max-w-lg lg:max-w-xl xl:max-w-2xl'>
                                <BringzzText tag='h1' className='text-midnight-blue'>
                                    Verify your identity
                                </BringzzText>
                                <BringzzText
                                    tag='h4'
                                    className='text-midnight-blue/25 font-normal'
                                >
                                    To continue, we will need to verify your identity (using WebID). Please have your passport or ID card ready, as well as a working camera. This process will only require a few minutes of your time.
                                </BringzzText>
                                <div className='pt-44'>
                                    <BringzzIcon
                                        icon="IconMountain"
                                        library="Custom"
                                        className="flex items-center justify-center h-52 w-52 bg-gray-100 rounded-full "
                                        size="110"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </BringzzPageContainer >
    );
};

export default AllowVerificationPage;
