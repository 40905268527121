import * as React from "react";
const IconRoute = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.3 2c-4.5 0-8.2 3.4-8.7 7.8l-22.5 3.9c-.6-4.2-4.2-7.5-8.6-7.5-4.8 0-8.7 3.9-8.7 8.7 0 4.4 3.3 8.1 7.6 8.6L9.8 44.7c-4.4.4-7.8 4.1-7.8 8.6 0 4.8 3.9 8.7 8.7 8.7 4.5 0 8.3-3.5 8.7-7.9h17.7c.6 0 1-.4 1-1s-.4-1-1-1H19.3c-.5-3.9-3.6-6.9-7.5-7.4l2.6-21.1c4.1-.4 7.4-3.7 7.8-7.8l22.5-3.9c.6 4.2 4.2 7.5 8.6 7.5 4.8 0 8.7-3.9 8.7-8.7S58.1 2 53.3 2M17.4 53.3a6.7 6.7 0 1 1-13.4 0 6.7 6.7 0 0 1 13.4 0m-3.9-31.7a6.7 6.7 0 1 1 0-13.4 6.7 6.7 0 0 1 0 13.4m39.8-4.2a6.7 6.7 0 1 1 0-13.4 6.7 6.7 0 0 1 0 13.4M57 52.1h-3.1c-.6 0-1 .4-1 1s.4 1 1 1H57c.6 0 1-.4 1-1s-.5-1-1-1M47 52.1h-3c-.6 0-1 .4-1 1s.4 1 1 1h3c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconRoute;
