import * as React from "react";
const IconSignboardHospital = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55 2H30c-2.5 0-4.5 2-4.5 4.5v4.1h-9.2V7.2c0-2.5-2-4.5-4.5-4.5H9.1c-2.5 0-4.5 2-4.5 4.5v50.3c0 2.5 2 4.5 4.5 4.5h2.7c2.5 0 4.5-2 4.5-4.5V27.4h9.2v4.1c0 2.5 2 4.5 4.5 4.5h25c2.5 0 4.5-2 4.5-4.5v-25C59.5 4 57.5 2 55 2M14.3 57.5c0 1.4-1.1 2.5-2.5 2.5H9.1c-1.4 0-2.5-1.1-2.5-2.5V7.2c0-1.4 1.1-2.5 2.5-2.5h2.7c1.4 0 2.5 1.1 2.5 2.5zm2-32.1V12.6h9.2v12.8zm41.2 6.1c0 1.4-1.1 2.5-2.5 2.5H30c-1.4 0-2.5-1.1-2.5-2.5v-25C27.5 5.1 28.6 4 30 4h25c1.4 0 2.5 1.1 2.5 2.5z" />
    <path d="M47.4 12.1c-.6 0-1 .4-1 1V18h-7.8v-4.8c0-.6-.4-1-1-1s-1 .4-1 1v11.7c0 .6.4 1 1 1s1-.4 1-1V20h7.8v4.8c0 .6.4 1 1 1s1-.4 1-1V13.1c0-.5-.4-1-1-1" />
  </svg>
);
export default IconSignboardHospital;
