import React from 'react';
import { useNavigation } from '../../context/NavigationContext';
import { BringzzPageContainer, BringzzIcon, BringzzText, BringzzTabs, BringzzTab, BringzzTabPanel, BringzzImage, BringzzGallery } from "@bringzz/components";

const AccountPharmacyPost10 = () => {
    const { backPress } = useNavigation();

    return (
        <BringzzPageContainer
            header={{
                left: <BringzzIcon
                    size="22"
                    icon="IconChevronLeft"
                    folder="LineIcons"
                    className="cursor-pointer"
                    onClick={backPress}
                />,
                title: <BringzzText tag='h2'>Postings</BringzzText>,
            }}>

            <BringzzTabs defaultActiveTab="tab1">
                <BringzzTab id="tab1">
                    <BringzzText tag='h4' className="font-bold">Published</BringzzText>
                </BringzzTab>
                <BringzzTabPanel id="tab1">
                    <BringzzGallery>
                        <BringzzImage
                            src="/images/4.jpg"
                            alt="Image 1"
                        />
                        <BringzzImage
                            src="/images/5.jpg"
                            alt="Image 1"
                        />
                        <BringzzImage
                            src="/images/6.jpg"
                            alt="Image 1"
                        />
                        <BringzzImage
                            src="/images/7.jpg"
                            alt="Image 1"
                        />
                        <BringzzImage
                            src="/images/8.jpg"
                            alt="Image 1"
                        />
                        <BringzzImage
                            src="/images/4.jpg"
                            alt="Image 1"
                        />
                        <BringzzImage
                            src="/images/5.jpg"
                            alt="Image 1"
                        />
                        <BringzzImage
                            src="/images/6.jpg"
                            alt="Image 1"
                        />
                        <BringzzImage
                            src="/images/7.jpg"
                            alt="Image 1"
                        />
                        <BringzzImage
                            src="/images/8.jpg"
                            alt="Image 1"
                        />
                        <BringzzImage
                            src="/images/4.jpg"
                            alt="Image 1"
                        />
                        <BringzzImage
                            src="/images/5.jpg"
                            alt="Image 1"
                        />
                        <BringzzImage
                            src="/images/6.jpg"
                            alt="Image 1"
                        />
                        <BringzzImage
                            src="/images/7.jpg"
                            alt="Image 1"
                        />
                        <BringzzImage
                            src="/images/8.jpg"
                            alt="Image 1"
                        />
                        <BringzzImage
                            src="/images/4.jpg"
                            alt="Image 1"
                        />
                        <BringzzImage
                            src="/images/5.jpg"
                            alt="Image 1"
                        />
                        <BringzzImage
                            src="/images/6.jpg"
                            alt="Image 1"
                        />
                        <BringzzImage
                            src="/images/7.jpg"
                            alt="Image 1"
                        />
                        <BringzzImage
                            src="/images/8.jpg"
                            alt="Image 1"
                        />
                        <BringzzImage
                            src="/images/5.jpg"
                            alt="Image 1"
                        />
                    </BringzzGallery>
                </BringzzTabPanel>

                <BringzzTab id="tab2">
                    <BringzzText tag='h4' className="font-bold">Unpublished</BringzzText>
                </BringzzTab>
                <BringzzTabPanel id="tab2">
                    Content for Tab 2
                </BringzzTabPanel>
            </BringzzTabs>
        </BringzzPageContainer>

    )
}

export default AccountPharmacyPost10;