import * as React from "react";
const IconJury = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M38.7 39.1H25.3c-2.8 0-5.1 2.3-5.1 5.1v4.4c0 2.8 2.3 5.1 5.1 5.1h13.4c2.8 0 5.1-2.3 5.1-5.1v-4.4c0-2.8-2.3-5.1-5.1-5.1m3.1 9.5c0 1.7-1.4 3.1-3.1 3.1H25.3c-1.7 0-3.1-1.4-3.1-3.1v-4.4c0-1.7 1.4-3.1 3.1-3.1h13.4c1.7 0 3.1 1.4 3.1 3.1zM22.9 23.6c1.1.5 4.9 2.3 9.1 2.3 4.5 0 8.1-1.8 9.1-2.3.3-.2.5-.5.5-.9 0-3.1-1-6-2.8-8-1.1-1.4-2.6-2.3-4.1-2.8 1.5-.9 2.6-2.6 2.6-4.5 0-3-2.4-5.4-5.3-5.4s-5.3 2.4-5.3 5.3c0 1.9 1 3.6 2.6 4.5-1.6.5-3 1.5-4.1 2.8-1.7 2.1-2.7 4.9-2.8 8 0 .5.2.9.5 1M32 4c1.8 0 3.3 1.5 3.3 3.3s-1.5 3.3-3.3 3.3-3.3-1.5-3.3-3.3S30.2 4 32 4m-5.3 12c1.4-1.7 3.3-2.6 5.3-2.6s3.9.9 5.3 2.6c1.3 1.6 2.1 3.8 2.3 6.2-1.3.6-4.2 1.8-7.6 1.8-3.2 0-6.3-1.2-7.6-1.8.2-2.5 1-4.6 2.3-6.2" />
    <path d="M55.3 30.9H8.7c-1.3 0-2.4 1.1-2.4 2.4v5.4C6.4 40 7.4 41 8.7 41h2.5v18.6c0 1.3 1.1 2.4 2.4 2.4h36.8c1.3 0 2.4-1.1 2.4-2.4V41h2.5c1.3 0 2.4-1.1 2.4-2.4v-5.4c-.1-1.2-1.1-2.3-2.4-2.3m.3 7.8c0 .2-.2.4-.4.4h-3.5c-.6 0-1 .4-1 1v19.6c0 .2-.2.4-.4.4H13.6c-.2 0-.4-.2-.4-.4V40c0-.6-.4-1-1-1H8.7c-.2 0-.4-.2-.4-.4v-5.4c0-.2.2-.4.4-.4h46.5c.2 0 .4.2.4.4z" />
  </svg>
);
export default IconJury;
