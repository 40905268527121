import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { BringzzText } from "@bringzz/components";

function TextMessage(props) {
    const [showTime, setShowTime] = useState(false);
    const message = props.message;
    const isSender = props.isSender;
    const hasPrevious = props.hasPrevious;

    const background = isSender ?
        "bg-sand/50 rounded-tl-3xl rounded-tr-3xl rounded-bl-3xl"
        : "bg-magic-lilac/50 rounded-tl-3xl rounded-tr-3xl rounded-br-3xl";

    return (
        <div>
            <div className={isSender ? "flex flex-row-reverse items-end space-x-4 !space-x-reverse" : "flex items-end space-x-4"}>
                <div className={classNames(
                    "w-10 h-10 rounded-full flex justify-center items-center",
                    isSender ? "bg-sand/50" : "bg-magic-lilac/50",
                    hasPrevious && "hidden"
                )}>
                    <BringzzText tag="h2" className='text-midnight-blue'>AB</BringzzText>
                </div>
                <div
                    className={classNames(
                        "p-4 text-gray-50 max-w-xs lg:max-w-xl",
                        "cursor-pointer",
                        background
                    )}
                    onClick={() => setShowTime(!showTime)}
                >
                    <BringzzText tag="h4" className="font-normal min-w-4 text-sm whitespace-pre-line break-words text-midnight-blue">
                        {message.text}
                    </BringzzText>
                </div>

            </div>
            {showTime && (
                <BringzzText tag="h5" className="text-slate-500 font-normal italic m-1">
                    {message.date}
                </BringzzText>
            )}
        </div>
    );
}

export default TextMessage;
