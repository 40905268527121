import * as React from "react";
const IconMedal8 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M27.5 48.9c0 2.5 2 4.5 4.5 4.5s4.5-2 4.5-4.5-2-4.5-4.5-4.5c-2.5.1-4.5 2.1-4.5 4.5m7 0c0 1.4-1.1 2.5-2.5 2.5s-2.5-1.1-2.5-2.5 1.1-2.5 2.5-2.5 2.5 1.2 2.5 2.5" />
    <path d="M54.1 2H9.9c-.6 0-1 .4-1 1v11c0 .3.1.5.3.7L30.4 36c-6.5.8-11.5 6.3-11.5 13 0 7.1 5.9 13 13.1 13s13.1-5.9 13.1-13.1c0-6.7-5-12.2-11.5-13l21.3-21.3c.2-.2.3-.4.3-.7V3c-.1-.6-.5-1-1.1-1M31.7 24.6 18.5 11.3h26.3zM46.8 9.3H16.5L11.4 4H52zm-35.9-3L30.3 26l-3.4 3.5-16-15.9zm32.2 42.6C43.1 55 38.1 60 32 60s-11.1-5-11.1-11.1 5-11.1 11.1-11.1 11.1 5 11.1 11.1M32 34.7l-3.8-3.8 19.7-20 5.2-5.3v8z" />
  </svg>
);
export default IconMedal8;
