import * as React from "react";
const IconTargetAlt3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 65 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M52.8 18.3c-.5.3-.6.9-.3 1.4 6.4 10 5 23.4-3.4 31.8-10 10-26.2 10-36.1 0-10-10-10-26.2 0-36.1C21.4 7 34.8 5.5 44.8 12c.5.3 1.1.2 1.4-.3s.2-1.1-.3-1.4C35.1 3.4 20.6 4.9 11.6 14 .9 24.7.9 42.2 11.6 52.9 16.9 58.3 24 61 31 61s14.1-2.7 19.5-8.1c9.1-9.1 10.7-23.5 3.7-34.3-.3-.4-.9-.6-1.4-.3" />
    <path d="M39.3 18.5c.2-.5 0-1.1-.5-1.3-6.9-3.3-15.1-1.9-20.5 3.5-7 7-7 18.5 0 25.5 3.4 3.4 7.9 5.3 12.8 5.3 4.8 0 9.4-1.9 12.8-5.3 5.4-5.4 6.8-13.7 3.5-20.5-.2-.5-.8-.7-1.3-.5s-.7.8-.5 1.3c2.9 6.1 1.7 13.5-3.1 18.3-3 3-7.1 4.7-11.4 4.7s-8.3-1.7-11.4-4.7c-6.3-6.3-6.3-16.4 0-22.7C24.5 17.3 31.8 16 38 19c.4.2 1 0 1.3-.5" />
    <path d="M30.6 26.9c.6 0 1-.5.9-1.1 0-.6-.5-1-1.1-.9-2.1.1-4.1 1-5.5 2.5-1.6 1.6-2.5 3.8-2.5 6.1s.9 4.4 2.5 6.1 3.8 2.5 6.1 2.5 4.4-.9 6.1-2.5c1.5-1.5 2.4-3.4 2.5-5.5 0-.6-.4-1-.9-1.1-.5 0-1 .4-1.1.9-.1 1.6-.8 3.1-1.9 4.2-1.2 1.2-2.9 1.9-4.7 1.9s-3.4-.7-4.7-1.9c-1.2-1.2-1.9-2.9-1.9-4.7s.7-3.4 1.9-4.7c1.2-1 2.7-1.7 4.3-1.8" />
    <path d="M61.2 3.3c-.4-.4-1-.4-1.4 0L30.9 32.2c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3L61.2 4.7c.4-.4.4-1 0-1.4" />
  </svg>
);
export default IconTargetAlt3;
