import * as React from "react";
const IconRefreshMedical = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M4 32C4 16.6 16.6 4 32 4c8.5 0 16.5 3.9 21.8 10.5h-7.2c-.6 0-1 .4-1 1s.4 1 1 1H56c.5 0 1-.4 1-.9l.6-8c0-.6-.4-1-.9-1.1-.6 0-1 .4-1.1.9l-.4 5.6C49.5 6.1 41 2 32 2 15.5 2 2 15.5 2 32c0 5.3 1.4 10.5 4.1 15 .2.3.5.5.9.5.2 0 .3 0 .5-.1.5-.3.6-.9.4-1.4C5.3 41.8 4 36.9 4 32M60.4 22.3c-.2-.5-.7-.8-1.3-.6-.5.2-.8.7-.6 1.3 1 2.9 1.5 6 1.5 9.1 0 15.4-12.6 28-28 28-6.3 0-12.4-2.1-17.3-6h6.8c.6 0 1-.4 1-1s-.4-1-1-1h-9.4c-.5 0-1 .4-1 .9l-.6 8c0 .6.4 1 .9 1.1h.1c.5 0 1-.4 1-.9l.5-6c5.3 4.4 12 6.8 19 6.8 16.5 0 30-13.5 30-30 0-3.3-.5-6.6-1.6-9.7" />
    <path d="M32 42.2c.6 0 1-.4 1-1V33h8.2c.6 0 1-.4 1-1s-.4-1-1-1H33v-8.2c0-.6-.4-1-1-1s-1 .4-1 1V31h-8.2c-.6 0-1 .4-1 1s.4 1 1 1H31v8.2c0 .6.4 1 1 1" />
  </svg>
);
export default IconRefreshMedical;
