import * as React from "react";
const IconBoxPercentage = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.8 41.9c-.4-.4-1-.4-1.4 0l-6.3 6.3c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l6.3-6.3c.4-.3.4-1 0-1.4M43.6 40.8l-.2-.3c-.3-.5-.9-.6-1.4-.3s-.6.9-.3 1.4l.2.3c.2.3.5.4.8.4.2 0 .4-.1.6-.2.5-.3.6-.9.3-1.3M52.2 49.7c-.5-.3-1.1-.2-1.4.2s-.2 1.1.2 1.4l.3.2c.2.1.4.2.6.2.3 0 .6-.1.8-.4.3-.4.2-1.1-.2-1.4z" />
    <path d="M52.3 31.7V20.1c0-1.4-.5-2.8-1.5-3.8L40.7 5c-1.1-1.2-2.6-1.9-4.3-1.9H17.8c-1.6 0-3.2.7-4.3 1.9l-10 11.2C2.6 17.2 2 18.6 2 20v29.7c0 3.2 2.6 5.7 5.7 5.7h27.7c2.8 3.3 6.9 5.4 11.5 5.4C55.2 60.8 62 54 62 45.7c0-6.3-4.1-11.8-9.7-14m-13-25.3L49 17.3H28.1V5.1h8.4c1 0 2.1.5 2.8 1.3M15 6.4c.7-.8 1.7-1.2 2.8-1.2h8.4v12.1h-21zM7.7 53.5c-2.1 0-3.7-1.7-3.7-3.7V20.1c0-.3.1-.6.1-.9.1.1.3.1.4.1h45.1c.2 0 .3 0 .4-.1.1.3.1.6.1.9v11c-1.1-.2-2.2-.4-3.3-.4-8.3 0-15.1 6.8-15.1 15.1 0 2.8.8 5.4 2.1 7.7zm39.2 5.4c-7.2 0-13.1-5.9-13.1-13.1s5.9-13.1 13.1-13.1S60 38.6 60 45.8s-5.9 13.1-13.1 13.1" />
  </svg>
);
export default IconBoxPercentage;
