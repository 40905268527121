import * as React from "react";
const IconWristwatchAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M46.2 32c0-4-1.7-7.7-4.4-10.2v-15C41.8 4.2 39.6 2 37 2H27c-2.7 0-4.8 2.2-4.8 4.8v14.9c-2.7 2.6-4.4 6.2-4.4 10.2s1.7 7.7 4.4 10.2V57c0 2.7 2.2 4.8 4.8 4.8h10c2.7 0 4.8-2.2 4.8-4.8V42.2c2.7-2.5 4.4-6.2 4.4-10.2m-22-25.2C24.2 5.3 25.5 4 27 4h10c1.6 0 2.8 1.3 2.8 2.8v13.3c-2.2-1.5-4.9-2.3-7.8-2.3s-5.5.9-7.8 2.3zM19.8 32c0-6.7 5.5-12.2 12.2-12.2S44.2 25.3 44.2 32 38.7 44.2 32 44.2 19.8 38.7 19.8 32m20 25.2c0 1.6-1.3 2.8-2.8 2.8H27c-1.6 0-2.8-1.3-2.8-2.8V43.8c2.2 1.5 4.9 2.3 7.8 2.3s5.5-.9 7.8-2.3z" />
    <path d="M33.3 36.8c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-2.5-2.5v-4.2c0-.6-.4-1-1-1s-1 .4-1 1v4.6c0 .3.1.5.3.7z" />
  </svg>
);
export default IconWristwatchAlt;
