import * as React from "react";
const IconVercel = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="m32 6 30 52H2z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconVercel;
