import React from "react";
import Barcode from 'react-barcode';
import BringzzImage from "../BringzzImage";
import BringzzText from "../BringzzText";
import BringzzIcon from "../BringzzIcon";
import PropTypes from "prop-types";

export default function BringzzBarcode({ value, ...props }) {
    return (
        <div className="flex flex-col justify-center items-center">
            <Barcode value={value} height={68} lineColor="#05003b" width={2.4} displayValue={false} margin={2} />
            <div className="w-72">
                <div className="flex justify-between space-x-1">
                    {Array.from(value).map((char, index) => (
                        <BringzzText key={index} tag="h4" className="font-bold">{char}</BringzzText>
                    ))}
                </div>
            </div>
        </div>
    )
}

BringzzBarcode.propTypes = {
    value: PropTypes.string.isRequired
};