import React, { useState } from 'react';
import { useNavigation } from 'context/NavigationContext';
import {
    BringzzPageContainer,
    BringzzButton, BringzzIcon, BringzzText, BringzzInput
} from "@bringzz/components";
import useRequest from 'hooks/useRequest';
import { useAuth } from 'context/AuthContext';

const EditDoctorVerificationView = () => {
    const { user, refreshUserData } = useAuth()
    const { backPress, navigate } = useNavigation();
    const [doctorType, setDoctorType] = useState(user.doctorType || '')
    const [doctorId, setDoctorId] = useState(user.doctorId || '')
    const [doctorUid, setdoctorUid] = useState(user.doctorUid || '')
    const { sendRequest, loading } = useRequest();

    return (
        <BringzzPageContainer
            header={{
                left: <BringzzIcon
                    size="22"
                    icon="IconChevronLeft"
                    folder="LineIcons"
                    className="cursor-pointer"
                    onClick={backPress}
                />,
                title: <BringzzText tag='h2'>Doctor Verification</BringzzText>,
            }}
            footer={
                <div className='pb-28 px-6'>
                    <BringzzButton
                        size='lg'
                        className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
                        onClick={async () => {
                            await sendRequest({
                                method: 'POST',
                                url: `/module/profile/changeDoctor`,
                                data: {
                                    doctorType,
                                    doctorId,
                                    doctorUid
                                }
                            })
                                .then(response => {
                                    if (response.status === 'success') {
                                        refreshUserData()
                                        backPress()
                                    }
                                })
                                .catch(error => {
                                    console.log(error);
                                });
                        }}
                        disabled={loading || (!doctorType && !doctorId && !doctorUid)}
                    >
                        Save
                    </BringzzButton>
                </div>
            }
        >
            <div className='p-6 space-y-4'>
                <BringzzInput onChange={(e, v, value) => setDoctorType(value)} defaultValue={user.doctorType} id='doctorType' label="Doctor Type" containerClassname="w-full" placeholder="Doctor Type" />
                <BringzzInput onChange={(e, v, value) => setDoctorId(value)} defaultValue={user.doctorId} id='doctorId' label="Doctor ID" containerClassname="w-full" placeholder="Doctor ID" />
                <BringzzInput onChange={(e, v, value) => setdoctorUid(value)} defaultValue={user.doctorUid} id='doctorUid' label="Doctor UID" containerClassname="w-full" placeholder="Doctor UID" />
            </div>
        </BringzzPageContainer>
    );
}

export default EditDoctorVerificationView;
