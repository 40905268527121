import React from "react";
import classnames from "classnames";
import BringzzIcon from "../BringzzIcon";
import BringzzText from "../BringzzText"
import PropTypes from "prop-types";

const DEFAULT_CLASS =
  "flex rounded-full py-2 w-fit space-x-2 items-center cursor-pointer";

const DEFAULT_SIZE_SM = "py-1 px-4";
const DEFAULT_SIZE_MD = "py-2 px-6";
const DEFAULT_SIZE_LG = "py-3 px-9";

const DEFAULT_FONT_SM = 'text-xs'
const DEFAULT_FONT_MD = 'text-sm'
const DEFAULT_FONT_LG = ''

// TODO: Use namespaces Ex: BringzzButton.Primary 
/**
 * `BringzzButton` is a flexible component designed to render a button with customizable options including size, icon, and click event handling.
 * It integrates `BringzzIcon` and `BringzzText` for a coherent design system.
 * 
 * @component
 * @example
 * <BringzzButton
 *   className="bg-blue-500 hover:bg-blue-700 text-white"
 *   size="md"
 *   onClick={() => console.log('Button clicked')}
 *   icon={{ variant: "IconName", folder: "IconFolder", size: "24" }}
 * >
 *   Click Me
 * </BringzzButton>
 */
const BringzzButton = ({
  className = "",
  children,
  onClick,
  icon,
  disabled = false,
  size = "md",
  ...props
}) => {
  const sizeClasses = {
    sm: DEFAULT_SIZE_SM,
    md: DEFAULT_SIZE_MD,
    lg: DEFAULT_SIZE_LG,
  };

  const fontClasses = {
    sm: DEFAULT_FONT_SM,
    md: DEFAULT_FONT_MD,
    lg: DEFAULT_FONT_LG,
  };

  const textClasses = className
    .split(" ")
    .filter(cls => cls.includes("text-"))
    .join(" ");

  return (
    <div
      className={classnames(DEFAULT_CLASS, sizeClasses[size], className, disabled && "!cursor-default opacity-35")}
      onClick={!disabled ? onClick : () => { }}
    >
      {icon && (
        <BringzzIcon
          icon={icon.variant}
          folder={icon.folder}
          size={icon.size || "22"}
        />
      )}
      {children && <BringzzText className={classnames("font-bold", fontClasses[size], textClasses)}>{children}</BringzzText>}
    </div>
  );
};

BringzzButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  icon: PropTypes.shape({
    variant: PropTypes.string.isRequired,
    folder: PropTypes.string.isRequired,
    size: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
  }),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
};

export default BringzzButton;
