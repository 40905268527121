import * as React from "react";
const IconStarHalfCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m45.7 23.9-5.4-.2-1.8-4.8-1.9.8 2.4 6 6.6.2zM61.9 25.5c-.2-.6-.7-1-1.3-1l-6.1-.2-.1 2 4.4.2-6.8 5.1 1.2 1.6 7.1-5.4-1.1-1.4 1.1 1.4 1.2-.9c.4-.3.6-.9.4-1.4M45 44.4l2-.6-1.5-4.8 4.2-3.2-1.2-1.6-5.3 4zM49.4 52l-1.9.6 1.2 4.1-7.4-4.7-1.1 1.7 7.3 4.7 1.5.9c.2.2.5.2.8.2s.6-.1.8-.3c.5-.3.7-.9.5-1.5zM26.5 49.5l-11.1 7.1 5.4-18.4L5.1 26.4l19.9-.7 6-15.6 1-2.4 1.6 4.2 1.9-.7-2.2-5.6c-.2-.6-.7-1-1.3-1s-1.1.4-1.3.9L28.6 11l-4.9 12.7-20.4.8c-.6 0-1.1.4-1.3 1s0 1.2.5 1.5l15.9 12L13 57.6c-.2.6 0 1.1.5 1.5.3.2.5.3.8.3s.5-.1.8-.2L29 50.3l3-1.9 4.5 2.8 1.1-1.7L32 46z" />
  </svg>
);
export default IconStarHalfCopy;
