import * as React from "react";
const IconInjection = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.7 11.6-9-9c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4L55 7.7l-7 7-9.9-9.9c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l3.7 3.7L9.8 40.5c-2 2-2 5.3 0 7.2l2.4 2.4L2.3 60c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l9.9-9.9 2.7 2.7c1 1 2.3 1.5 3.6 1.5s2.6-.5 3.6-1.5l30.6-30.6 3.7 3.7c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-3.7-3.7-6.2-6.2 7-7 3.9 3.9c.2.2.5.3.7.3s.5-.1.7-.3c.5-.3.5-.9.1-1.3m-16.3 18-4.3-4.3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4L44 31l-5.7 5.7-6.7-6.7c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l6.7 6.7-6.1 6.1-4.3-4.2c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l4.3 4.3-7.2 7.2c-1.2 1.2-3.2 1.2-4.4 0l-6.5-6.5c-1.2-1.2-1.2-3.2 0-4.4l30.6-30.6 5.5 5.5 5.5 5.5z" />
  </svg>
);
export default IconInjection;
