import * as React from "react";
const IconIdeaSearch = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M26.9 13.2c-3-.1-5.8 1-8 3.1-2.1 2.1-3.3 4.9-3.3 7.9 0 3.8 1.9 7.3 5.2 9.4.6.4 1 1.2 1 2v1.9c0 2.4 1.9 4.3 4.3 4.3h1c2.4 0 4.3-1.9 4.3-4.3v-2c0-.4.1-.8.3-1.1s.4-.6.7-.8c3.1-2 5.1-5.3 5.2-9 .1-2.9-1-5.7-3.1-7.9-2-2.2-4.7-3.4-7.6-3.5m2.5 24.3c0 1.3-1 2.3-2.3 2.3h-1c-1.3 0-2.3-1-2.3-2.3V35h5.7v2.5zm2-5.6c-.5.3-.8.7-1.1 1.1H23c-.3-.4-.7-.8-1.1-1.1-2.7-1.7-4.2-4.5-4.2-7.7 0-2.5 1-4.8 2.7-6.5s3.9-2.6 6.3-2.6h.2c2.4.1 4.6 1.1 6.3 2.8 1.7 1.8 2.6 4.1 2.5 6.4-.1 3.2-1.7 6-4.3 7.6" />
    <path d="M61.7 60.3 45.4 44c9.1-9.8 8.8-25.2-.7-34.7C35-.4 19.1-.4 9.3 9.3s-9.8 25.6 0 35.4C14.2 49.6 20.6 52 27 52c6.1 0 12.2-2.2 17-6.6l16.3 16.3c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4m-51-17c-9-9-9-23.6 0-32.6C15.2 6.3 21.1 4 27 4s11.8 2.2 16.3 6.7c9 9 9 23.6 0 32.6s-23.6 9-32.6 0" />
  </svg>
);
export default IconIdeaSearch;
