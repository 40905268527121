import React from 'react';
import {
	BringzzTopBar,
	BringzzMap,
	BringzzImage,
	BringzzIcon,
	BringzzButton,
	BringzzText
} from "@bringzz/components";
import BringzzLogo from 'assets/BringzzLogo.png';
import { useNavigation } from '../../context/NavigationContext';

const OrderRequest2 = () => {
	const { navigate } = useNavigation();

	let zoneData = [
		{
			zipcode: 1100,
			count: 24
		},
		{
			zipcode: 1010,
			count: 2
		},
		{
			zipcode: 1040,
			count: 12
		}
	];

	function addOpacityAttribute() {
		// Determine opacity based on the count
		function determineOpacity(count) {
			if (count >= 1 && count <= 5) {
				return 0.2;
			} else if (count >= 6 && count <= 10) {
				return 0.4;
			} else if (count >= 11 && count <= 20) {
				return 0.6;
			} else if (count >= 21) {
				return 0.8;
			}
		}

		// Loop through each item in the overlay array and add an opacity attribute
		for (let index = 0; index < zoneData.length; index++) {
			zoneData[index].opacity = determineOpacity(zoneData[index].count);
		}
	}

	addOpacityAttribute();

	return (
		<div className='flex flex-col h-full'>
			<BringzzTopBar
				logo={BringzzLogo}
				title={'Select address'}
				sections={[
					{ name: 'logo', alignment: 'start', value: BringzzLogo },
					{ name: 'location', alignment: 'center', value: 'Wien' },
					{
						name: 'notification',
						alignment: 'end',
						value: true,
						onClick: () => navigate('/notifications')
					}
				]}
			/>
			<div className='grow relative'>
				<BringzzMap mode={2} overlayData={zoneData} />
				<div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center w-full p-4 md:p-8 lg:p-12'>
					<div className='bg-white px-4 py-12 rounded-lg w-full text-midnight-blue'>
						<div className='text-center'>
							<div className='font-medium mb-4'>
								<BringzzText>Order request</BringzzText>
							</div>
							<div className='text-3xl font-bold'>
								<BringzzText className='font-bold text-3xl'>
									€ 150.59
								</BringzzText>
							</div>
							<div>
								<BringzzText tag='h5' className='font-regular'>
									Estimated payment incl. tip
								</BringzzText>
							</div>
						</div>
						<div>
							<div className='flex items-center justify-between p-4 border-b'>
								<span>
									<BringzzText tag='h4' className='font-regular'>
										Max Mustermann
									</BringzzText>
								</span>
								<BringzzImage
									src='/images/1.jpg'
									width='36px'
									height='36px'
									className='rounded-full !h-[36px]'
								></BringzzImage>
							</div>
							<div className='flex items-center justify-between p-4 border-b'>
								<div className='items-center flex gap-x-6'>
									<BringzzIcon
										icon='IconMapMarker'
										folder='LineIcons'
										className='text-midnight-blue'
										size='24'
									></BringzzIcon>
									<span>
										<BringzzText tag='h4' className='font-regular'>
											Estimated total distance
										</BringzzText>
									</span>
								</div>

								<span className='font-bold'>
									<BringzzText>4.6 km</BringzzText>
								</span>
							</div>
							<div className='flex items-center justify-between  p-4 border-b'>
								<div className='items-center flex gap-x-6'>
									<BringzzIcon
										icon='IconMapMarker'
										folder='LineIcons'
										className='text-midnight-blue'
										size='24'
									></BringzzIcon>
									<span>
										<BringzzText tag='h4' className='font-regular'>
											Estimated time
										</BringzzText>
									</span>
								</div>
								<span className='font-bold'>20 min</span>
							</div>

							<div className=' p-4 mb-6'>
								<span className='font-bold'>View all details </span>
							</div>
							<div className='flex items-center gap-x-4 justify-center'>
								<BringzzButton className='bg-white border-midnight-blue border-2'>
									Decline
								</BringzzButton>
								<BringzzButton className='bg-magic-lilac'>Apply</BringzzButton>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OrderRequest2;
