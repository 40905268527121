import * as React from "react";
const IconLaptopCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.9 54.8-4.3-18.4v-.1c.2-.2.4-.5.4-.8v-24c0-2.8-2.2-5-5-5H11c-2.8 0-5 2.2-5 5v24c0 .3.1.5.3.7v.1L2 54.8c-.1.6.1 1.3.5 1.8.5.5 1.1.9 1.9.9h55.3c.8 0 1.4-.3 1.9-.9q.6-.9.3-1.8M8 11.5c0-1.7 1.3-3 3-3h42c1.7 0 3 1.3 3 3v23H8zm52 43.9s-.1.1-.3.1H4.4c-.2 0-.3-.1-.3-.1l-.1-.1 4.2-18.4c0-.1.2-.2.4-.2h46.7c.2 0 .4.2.4.3L60 55.3z" />
  </svg>
);
export default IconLaptopCopy;
