import * as React from "react";
const IconBricksCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 31h-4.6v-7.8c0-2.8-2.2-5-5-5H15.6c-2.8 0-5 2.2-5 5V31H6c-2.2 0-4 1.8-4 4v6.8c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V35c0-2.2-1.8-4-4-4m-6.6-7.8V31H33V20.2h15.4c1.7 0 3 1.4 3 3M41.5 33v10.8h-19V33zm-28.9-9.8c0-1.7 1.3-3 3-3H31V31H12.6zM4 41.8V35c0-1.1.9-2 2-2h14.5v10.8H6c-1.1 0-2-.9-2-2m56 0c0 1.1-.9 2-2 2H43.5V33H58c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconBricksCopy;
