import * as React from "react";
const IconIslamicFlag = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M39.3 26c-.7.1-1.5.2-2.3.2-7.1 0-12.8-5.7-12.8-12.8 0-.8.1-1.5.2-2.3.1-.4-.1-.8-.4-1s-.8-.2-1.1 0c-3.1 2.2-6.3 7-6.3 11.2 0 6.9 5.6 12.5 12.5 12.5 4.2 0 9-3.2 11.2-6.3.2-.3.2-.8 0-1.1s-.6-.5-1-.4m-10.2 5.8c-5.8 0-10.5-4.7-10.5-10.5 0-2.7 1.7-5.8 3.7-8 0 8.2 6.6 14.8 14.8 14.8-2.2 2-5.3 3.7-8 3.7" />
    <path d="M50.2 24.4c-.6-1.2-.7-2.5 0-3.7l5.5-10c1-1.8 1-4-.1-5.8S52.7 2 50.6 2H13.4c-3.2 0-5.8 2.6-5.8 5.8V61c0 .6.4 1 1 1s1-.4 1-1V41.2c1 .9 2.4 1.4 3.8 1.4h37c2.1 0 4-1.1 5-2.9s1.1-4 .1-5.8zm3.6 14.4c-.7 1.2-1.9 1.9-3.3 1.9h-37c-2.1 0-3.8-1.7-3.8-3.8v-29c0-2.1 1.7-3.8 3.8-3.8h37.1c1.4 0 2.6.7 3.3 1.9s.7 2.6.1 3.8l-5.5 10c-1 1.8-1 3.9 0 5.6l5.4 9.6c.6 1.2.6 2.6-.1 3.8" />
    <path d="M39.5 14.3h-2.7v-2.7c0-.6-.4-1-1-1s-1 .4-1 1v2.7h-2.7c-.6 0-1 .4-1 1s.4 1 1 1h2.7V19c0 .6.4 1 1 1s1-.4 1-1v-2.7h2.7c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconIslamicFlag;
