import * as React from "react";
const IconWebcamAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.2 27.2C57.2 13.3 45.9 2 32 2c-6.7 0-13.1 2.6-17.8 7.4-4.8 4.8-7.4 11.1-7.4 17.8S9.4 40.3 14.2 45q2.7 2.7 6 4.5l-3.1 8.1c-.7 1.4-.2 3.2 1.2 4 .6.3 1.4.4 2.1.3 7.7-1.7 15.5-1.7 23.2 0 .2 0 .4.1.6.1.6 0 1.1-.2 1.6-.5.7-.4 1.1-1.1 1.3-1.9.1-.7.1-1.4-.3-2l-3.1-8.1c8.1-4.2 13.5-12.6 13.5-22.3M45.1 58.4l.1.1c.1.2.1.5.1.7-.1.3-.2.5-.4.6-.3.2-.6.2-.9.2-8-1.7-16.1-1.7-24 0-.2.1-.5 0-.7-.1-.5-.3-.7-.9-.4-1.4l3.1-8.1c3.1 1.3 6.5 2.1 10 2.1s6.9-.7 10-2.1zM32 50.5c-6.2 0-12-2.4-16.4-6.8S8.8 33.5 8.8 27.3s2.4-12 6.8-16.4C20 6.4 25.8 4 32 4c12.8 0 23.2 10.4 23.2 23.2S44.8 50.4 32 50.5" />
    <path d="M32 17c-5.6 0-10.2 4.6-10.2 10.2S26.4 37.4 32 37.4s10.2-4.6 10.2-10.2S37.6 17 32 17m0 18.4c-4.5 0-8.2-3.7-8.2-8.2S27.5 19 32 19s8.2 3.7 8.2 8.2-3.7 8.2-8.2 8.2M30.1 12.3h3.7c.6 0 1-.4 1-1s-.4-1-1-1h-3.7c-.6 0-1 .4-1 1s.5 1 1 1" />
  </svg>
);
export default IconWebcamAlt;
