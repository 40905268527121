import * as React from "react";
const IconLabelNew = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m60.3 28.5-3.7-3c-.8-.6-1.1-1.6-.8-2.6l1.2-4.7c.4-1.3.1-2.7-.7-3.8s-2-1.8-3.4-1.9l-4.9-.2c-1-.1-1.9-.7-2.2-1.6l-1.7-4.5c-.5-1.3-1.5-2.3-2.8-2.7-1.3-.5-2.7-.3-3.9.5l-4 2.6c-.8.5-1.9.5-2.7 0L26.6 4c-1.2-.8-2.6-1-3.9-.5-1.3.4-2.3 1.4-2.8 2.7l-1.7 4.5c-.4.9-1.2 1.6-2.2 1.6l-4.8.3c-1.4.1-2.6.7-3.4 1.9-.8 1.1-1.1 2.5-.7 3.8L8.3 23c.3 1-.1 2-.8 2.6l-3.7 3c-1.2.8-1.8 2-1.8 3.4s.6 2.6 1.7 3.5l3.7 3c.8.6 1.1 1.6.8 2.6L7 45.8c-.4 1.3-.1 2.7.7 3.8s2 1.8 3.4 1.9l4.8.3c1 .1 1.9.7 2.2 1.6l1.7 4.5c.5 1.3 1.5 2.3 2.8 2.7.5.1.9.2 1.4.2.9 0 1.7-.2 2.5-.7l4.1-2.6c.8-.5 1.9-.5 2.7 0l4 2.6c1.1.7 2.5.9 3.9.5 1.3-.4 2.3-1.4 2.8-2.7l1.7-4.5c.4-.9 1.2-1.6 2.2-1.6l4.8-.3c1.4-.1 2.6-.8 3.4-1.9s1.1-2.5.7-3.8l-1.2-4.7c-.3-1 .1-2 .8-2.6l3.7-3c1.1-.9 1.7-2.1 1.7-3.5.2-1.4-.4-2.6-1.5-3.5M59.1 34l-3.7 3c-1.4 1.1-2 3-1.5 4.7l1.2 4.7c.2.7.1 1.5-.4 2.1-.4.6-1.1 1-1.9 1l-4.8.3c-1.8.1-3.3 1.2-4 2.9l-1.7 4.5c-.3.7-.8 1.3-1.6 1.5-.7.2-1.5.1-2.1-.3l-4-2.6c-1.5-1-3.4-1-4.9 0l-4.1 2.6c-.6.4-1.4.5-2.1.3s-1.3-.8-1.6-1.5L20 52.6c-.6-1.7-2.2-2.8-4-2.9l-4.8-.3c-.8 0-1.5-.4-1.9-1s-.6-1.4-.4-2.1l1.2-4.7c.5-1.7-.1-3.5-1.5-4.7l-3.7-3c-.6-.4-.9-1.1-.9-1.9s.3-1.5.9-2l3.7-3c1.4-1.1 2-3 1.5-4.7L9 17.7c-.2-.7-.1-1.5.4-2.1s1.1-1 1.9-1l4.8-.3c1.8-.1 3.3-1.2 4-2.9l1.7-4.5c.3-.7.8-1.3 1.6-1.5.3-.1.5-.1.8-.1.5 0 1 .1 1.4.4l4.1 2.6c1.5 1 3.4 1 4.9 0l4-2.6c.6-.4 1.4-.5 2.1-.3s1.3.8 1.6 1.5l1.7 4.5c.6 1.7 2.2 2.8 4 2.9l4.8.3c.8 0 1.5.4 1.9 1s.6 1.4.4 2.1l-1.2 4.7c-.5 1.7.1 3.5 1.5 4.7l3.7 3c.6.5.9 1.2.9 2s-.3 1.4-.9 1.9" />
    <path d="M21.4 25c-.6 0-1 .4-1 1v5.8l-3.3-6.3c-.2-.4-.7-.6-1.1-.5s-.8.5-.8 1v9.9c0 .6.4 1 1 1s1-.4 1-1v-5.8l3.3 6.3c.2.3.5.5.9.5h.2c.4-.1.8-.5.8-1V26c0-.5-.5-1-1-1M32.1 27c.6 0 1-.4 1-1s-.4-1-1-1h-3.9c-.6 0-1 .4-1 1v9.9c0 .6.4 1 1 1h3.9c.6 0 1-.4 1-1s-.4-1-1-1h-2.9V32h1c.6 0 1-.4 1-1s-.4-1-1-1h-1v-3zM48.1 25.1c-.5-.2-1.1.1-1.2.7L45 32l-1.4-6.2c-.1-.5-.6-.8-1.1-.8-.4 0-.7.3-.9.7L39.9 32l-1.4-6.2c-.1-.5-.7-.9-1.2-.7-.5.1-.9.7-.7 1.2l2.3 9.9c.1.4.5.8.9.8s.8-.3 1-.7l1.8-6.2 1.4 6.2c.1.4.5.8.9.8.5 0 .9-.3 1-.7l2.9-9.9c.1-.7-.2-1.3-.7-1.4" />
  </svg>
);
export default IconLabelNew;
