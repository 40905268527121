import * as React from "react";
const IconTent = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 53h-2.4L33.3 19.2l6.4-8.6c.3-.4.2-1.1-.2-1.4s-1.1-.2-1.4.2L32 17.5l-6.1-8.1c-.3-.4-1-.5-1.4-.2s-.5 1-.2 1.4l6.4 8.6L5.4 53H3c-.6 0-1 .4-1 1s.4 1 1 1h58c.6 0 1-.4 1-1s-.4-1-1-1M32 20.8 56.1 53H38.7v-8.3c0-2.8-2.3-5.1-5.1-5.1h-3.3c-2.8 0-5.1 2.3-5.1 5.1V53H7.9zM27.3 53v-8.3c0-1.7 1.4-3.1 3.1-3.1h3.3c1.7 0 3.1 1.4 3.1 3.1V53z" />
  </svg>
);
export default IconTent;
