import * as React from "react";
const IconBankAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.5 17.5 33.8 2.3c-1.1-.6-2.5-.6-3.7 0L2.5 17.5c-.4.2-.6.7-.5 1.1s.5.8 1 .8h4v30.4c-1.6.6-2.7 2-2.7 3.8v4.7c0 2.2 1.8 4 4 4h47.5c2.2 0 4-1.8 4-4v-4.7c0-1.7-1.1-3.2-2.7-3.8V19.3H61c.5 0 .9-.3 1-.8.1-.3-.1-.8-.5-1m-44.8 1.8v30.2H9V19.3zm2 30.2V19.3h25.9v30.2zm39.1 4v4.7c0 1.1-.9 2-2 2H8.3c-1.1 0-2-.9-2-2v-4.7c0-1.1.9-2 2-2h47.5c1.1 0 2 .9 2 2m-2.7-4h-8.4V19.3h8.4zM6.9 17.3 31.1 4c.5-.3 1.2-.3 1.7 0L57 17.3z" />
    <path d="M32.7 33.4h-2.8c-1.3 0-2.3-1-2.3-2.3s1-2.3 2.3-2.3H36c.6 0 1-.4 1-1s-.4-1-1-1h-2.8V25c0-.6-.4-1-1-1s-1 .4-1 1v1.8h-1.4c-2.4 0-4.3 1.9-4.3 4.3s1.9 4.3 4.3 4.3h2.8c1.3 0 2.3 1 2.3 2.3S34 40 32.7 40h-6.1c-.6 0-1 .4-1 1s.4 1 1 1h4.7v1.8c0 .6.4 1 1 1s1-.4 1-1v-1.9c2.1-.3 3.8-2 3.8-4.2-.1-2.3-2-4.3-4.4-4.3" />
  </svg>
);
export default IconBankAlt;
