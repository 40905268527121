import * as React from "react";
const IconBadgeDollar = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.8 36.2-1.1-2.6q-.6-1.65 0-3.3l1-2.5c.5-1.1.2-2.4-.6-3.3l-1.9-1.9c-.8-.8-1.3-1.9-1.3-3.1v-2.8c0-1.2-.7-2.3-1.9-2.8l-2.5-1c-1.1-.4-1.9-1.3-2.3-2.3l-1-2.5c-.5-1.2-1.6-1.9-2.8-1.9h-2.7c-1.2 0-2.2-.4-3.1-1.3l-2-2c-.6-.6-1.4-.9-2.2-.9-.4 0-.8.1-1.2.2l-2.6 1.1c-1 .4-2.2.4-3.3 0l-2.6-1.1c-1.2-.4-2.4-.2-3.3.7l-1.9 1.9c-.8.8-1.9 1.3-3.1 1.3h-2.8c-1.2 0-2.3.8-2.8 1.9l-1 2.5c-.4 1.1-1.3 1.9-2.3 2.3l-2.5 1c-1.2.5-1.9 1.6-1.9 2.8v2.8c0 1.2-.4 2.2-1.3 3.1l-1.9 1.9c-.9.9-1.1 2.2-.7 3.3l1.1 2.6q.6 1.65 0 3.3l-1 2.5c-.5 1.1-.2 2.4.6 3.3l1.9 1.9c.8.8 1.3 1.9 1.3 3.1v2.8c0 1.2.8 2.3 1.9 2.8l2.5 1c1.1.4 1.9 1.3 2.3 2.3l1 2.5c.5 1.2 1.6 1.9 2.8 1.9h2.8c1.2 0 2.2.4 3.1 1.3l2 2c.6.6 1.3.9 2.2.9.4 0 .8-.1 1.2-.2l2.6-1.1c1-.4 2.2-.4 3.3 0l2.6 1.1c.4.1.8.2 1.1.2.8 0 1.6-.3 2.2-.9l1.9-1.9c.8-.8 1.9-1.3 3.1-1.3h2.8c1.2 0 2.3-.8 2.8-1.9l1-2.5c.4-1.1 1.3-1.9 2.3-2.3l2.5-1c1.2-.5 1.9-1.6 1.9-2.8v-2.8c0-1.2.4-2.2 1.3-3.1l2-2c.7-.8.9-2.1.5-3.2m-2.1 1.9-2 2c-1.2 1.2-1.8 2.8-1.8 4.5v2.8c0 .4-.2.8-.6 1l-2.6 1.1c-1.6.6-2.8 1.9-3.4 3.4l-1 2.6c-.2.4-.5.6-1 .6h-2.8c-1.7 0-3.3.7-4.5 1.8l-2 2c-.3.3-.8.4-1.1.2l-2.5-1c-.8-.3-1.6-.5-2.4-.5s-1.7.2-2.4.5l-2.6.8c-.3.1-.8.1-1.1-.2l-2-2c-1.2-1.2-2.8-1.8-4.5-1.8h-2.8c-.4 0-.8-.2-1-.6l-1.1-2.6c-.6-1.6-1.9-2.8-3.4-3.4l-2.6-1c-.4-.2-.6-.5-.6-1v-2.8c0-1.7-.7-3.3-1.8-4.5l-2-2c-.1-.2-.2-.6 0-1l1-2.5c.6-1.6.6-3.3 0-4.8l-1-2.5c-.2-.4-.1-.8.2-1.1l2-2c1.2-1.2 1.8-2.8 1.8-4.5v-2.8c0-.4.2-.8.6-1l2.6-1.1c1.6-.6 2.8-1.9 3.4-3.4l1-2.6c.2-.4.5-.6 1-.6h2.8c1.7 0 3.3-.7 4.5-1.8l2-2c.3-.3.8-.4 1.1-.2l2.5 1c1.6.6 3.4.6 4.8 0l2.6-1c.3-.1.8-.1 1.1.2l2 2c1.2 1.2 2.8 1.8 4.5 1.8h2.7c.4 0 .8.2 1 .6l1.1 2.6c.6 1.6 1.9 2.8 3.4 3.4l2.6 1.1c.4.2.6.5.6 1v2.8c0 1.7.7 3.3 1.8 4.5l2 2c.3.3.4.7.2 1.1l-1 2.5c-.6 1.6-.6 3.3 0 4.8l1.1 2.6c0 .3-.1.7-.4 1" />
    <path d="M30.3 25.1h7.4c.6 0 1-.4 1-1s-.4-1-1-1h-3.5v-2.4c0-.6-.4-1-1-1s-1 .4-1 1v2.4h-1.8c-2.7 0-5 2.2-5 4.9s2.2 4.9 5 4.9h3.4c1.6 0 3 1.3 3 2.9s-1.3 2.9-3 2.9h-7.4c-.6 0-1 .4-1 1s.4 1 1 1h5.8v2.4c0 .6.4 1 1 1s1-.4 1-1v-2.4c2.5-.2 4.5-2.3 4.5-4.9 0-2.7-2.2-4.9-5-4.9h-3.4c-1.6 0-3-1.3-3-2.9s1.4-2.9 3-2.9" />
  </svg>
);
export default IconBadgeDollar;
