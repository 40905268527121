import * as React from "react";
const IconFrenchFries = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 21.5c-.9-1-2.3-1.6-3.7-1.6H48V6.8C48 4.1 45.8 2 43.2 2s-4.8 2.1-4.8 4.8V9c-.8-.6-1.7-.9-2.8-.9-2.3 0-4.2 1.6-4.7 3.8-.8-.6-1.8-1-2.9-1-1 0-2 .3-2.8.9v-2c0-2.6-2.1-4.8-4.8-4.8s-4.8 2.1-4.8 4.8v10.1h-5c-1.4 0-2.7.6-3.7 1.6-.9 1-1.4 2.4-1.3 3.8l3.2 32.2c.3 2.5 2.4 4.5 4.9 4.5h36.4c2.6 0 4.7-1.9 4.9-4.5l3.2-32.2c.2-1.4-.2-2.7-1.2-3.8M40.4 6.8c0-1.5 1.2-2.8 2.8-2.8S46 5.2 46 6.8v29c0 1.1-.9 2-2 2h-3.6v-31m-4.8 3.3c1.5 0 2.8 1.2 2.8 2.8v24.9h-5.6V12.9c0-1.5 1.3-2.8 2.8-2.8m-7.5 2.8c1.5 0 2.8 1.2 2.8 2.8v22.1h-5.6V15.6c0-1.5 1.2-2.7 2.8-2.7M17.7 9.8c0-1.5 1.2-2.8 2.8-2.8 1.5 0 2.8 1.2 2.8 2.8v27.9h-3.6c-1.1 0-2-.9-2-2zm35.4 47.5c-.1 1.6-1.4 2.7-2.9 2.7H13.8c-1.5 0-2.8-1.1-2.9-2.7L7.7 25.1c-.1-.8.2-1.6.8-2.3.6-.6 1.3-1 2.2-1h5v13.9c0 2.2 1.8 4 4 4H44c2.2 0 4-1.8 4-4V21.9h5.4c.8 0 1.6.3 2.2 1 .6.6.8 1.4.7 2.3z" />
  </svg>
);
export default IconFrenchFries;
