import * as React from "react";
const IconExitDownCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.5 30.4h-6.9c-.6 0-1 .4-1 1s.4 1 1 1h6.9c.9 0 1.5.7 1.5 1.5v11.3c0 3.1-2.5 5.6-5.6 5.6H10.6c-3.1 0-5.6-2.5-5.6-5.6V33.9c0-.9.7-1.5 1.5-1.5h6.9c.6 0 1-.4 1-1s-.4-1-1-1H6.5c-2 0-3.5 1.6-3.5 3.5v11.3c0 4.2 3.4 7.6 7.6 7.6h42.9c4.2 0 7.6-3.4 7.6-7.6V33.9c-.1-1.9-1.7-3.5-3.6-3.5" />
    <path d="M31.3 36.2c.2.2.4.3.7.3s.5-.1.7-.3l10.2-10c.4-.4.4-1 0-1.4s-1-.4-1.4 0L33 33.1V12.3c0-.6-.4-1-1-1s-1 .4-1 1v20.9l-8.5-8.4c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4z" />
  </svg>
);
export default IconExitDownCopy;
