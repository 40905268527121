import * as React from "react";
const IconBugCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.7 40.1H48.1c0-3.2-.5-6.2-1.4-8.9l8.1-7.1c.4-.4.5-1 .1-1.4s-1-.5-1.4-.1L46 29.2c-1.9-4.5-5.1-8.1-8.8-9.8h1.2c1.5 0 3-.7 4.1-1.9s1.6-2.8 1.4-4.4C43.2 6.8 38.1 2 31.9 2s-11.3 4.8-12 11.1c-.2 1.6.3 3.2 1.4 4.4s2.6 1.9 4.1 1.9h1.2c-3.7 1.7-6.7 5.2-8.7 9.6l-7.3-6.4c-.4-.4-1-.3-1.4.1s-.3 1 .1 1.4l7.9 6.9c-.9 2.8-1.5 5.9-1.5 9.1H5.3c-.6 0-1 .4-1 1s.4 1 1 1h10.4c.2 2.8.8 5.4 1.7 7.8l-8.1 7.2c-.4.4-.5 1-.1 1.4.2.2.5.3.8.3.2 0 .5-.1.7-.3l7.6-6.7C21.1 58 26.1 62 31.9 62s10.9-4.1 13.7-10.3l7.8 6.9c.2.2.4.3.7.3s.6-.1.7-.3c.4-.4.3-1-.1-1.4l-8.3-7.4c.8-2.3 1.4-4.9 1.6-7.6h10.7c.6 0 1-.4 1-1s-.5-1.1-1-1.1M22.8 16.2c-.7-.8-1-1.8-.9-2.9.6-5.3 4.9-9.3 10-9.3s9.4 4 9.9 9.3c.1 1.1-.2 2.1-.9 2.8q-1.2 1.2-2.7 1.2H25.5c-1 .1-2-.3-2.7-1.1M31.9 60c-7.8 0-14.2-8.9-14.2-19.9s6.4-19.9 14.2-19.9 14.2 8.9 14.2 19.9v.7c0 .1-.1.2-.1.3v.2C45.6 51.7 39.4 60 31.9 60" />
  </svg>
);
export default IconBugCopy;
