import * as React from "react";
const IconFactoryAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.7 22.7H24.5l-.4-8.3c-.1-2.6-2.2-4.6-4.8-4.6h-7.8c-2.6 0-4.7 2-4.8 4.6L4.5 57c-.1 1.3.4 2.6 1.3 3.5.9 1 2.2 1.5 3.5 1.5h45.5c2.6 0 4.8-2.2 4.8-4.8V27.5c-.1-2.7-2.3-4.8-4.9-4.8M21.2 60H9.3c-.8 0-1.5-.3-2-.9s-.8-1.3-.8-2.1l2.1-42.5c.1-1.5 1.3-2.7 2.8-2.7h7.8c1.5 0 2.7 1.2 2.8 2.7L24.4 60zm14.6 0V44.4c0-1.1.9-2 2-2h6c1.1 0 2 .9 2 2V60zm21.7-2.8c0 1.5-1.3 2.8-2.8 2.8h-7V44.4c0-2.2-1.8-4-4-4h-6c-2.2 0-4 1.8-4 4V60h-7.4l-1.8-35.3h30.1c1.5 0 2.8 1.3 2.8 2.8v29.7z" />
    <path d="M34.8 18.1c.6 0 1-.4 1-1v-2.5c0-1.5 1.2-2.8 2.8-2.8 2.6 0 4.8-2.1 4.8-4.8V3c0-.6-.4-1-1-1s-1 .4-1 1v4.1c0 1.5-1.2 2.8-2.8 2.8-2.6 0-4.8 2.1-4.8 4.8v2.5c0 .4.4.9 1 .9" />
  </svg>
);
export default IconFactoryAlt1;
