import * as React from "react";
const IconMonitor = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M36.8 20.9c-1.8 0-3.5.5-4.9 1.4-1.2-.7-2.8-1.4-4.8-1.4-4.5 0-7.9 3.7-7.9 8.4-.2 5 3.8 9.8 11.4 13.3l1.4.6 1.3-.6c4.3-2 11.4-6.5 11.4-13.2.1-4.8-3.4-8.5-7.9-8.5m-4.3 19.8-.5.3-.6-.2C27.5 38.9 21 35 21.2 29.3c0-3.6 2.6-6.4 5.9-6.4 1.8 0 3.2.8 4.3 1.4l.6.4.6-.4c1.1-.9 2.6-1.3 4.2-1.3 3.3 0 5.9 2.8 5.9 6.4.1 5.6-6.4 9.5-10.2 11.3" />
    <path d="M45 2H19c-5 0-9 4-9 9v42c0 5 4 9 9 9h26c5 0 9-4 9-9V11c0-5-4.1-9-9-9m7 51c0 3.9-3.1 7-7 7H19c-3.9 0-7-3.1-7-7V11c0-3.9 3.1-7 7-7h26c3.9 0 7 3.1 7 7z" />
    <path d="M35.5 51.8h-7.8c-.6 0-1 .4-1 1s.4 1 1 1h7.8c.6 0 1-.4 1-1s-.5-1-1-1M29.5 10.8h4.2c.6 0 1-.4 1-1s-.4-1-1-1h-4.2c-.6 0-1 .4-1 1s.4 1 1 1" />
  </svg>
);
export default IconMonitor;
