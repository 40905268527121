import * as React from "react";
const IconWire = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.5 62.3c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4 1.3-1.3 1.4-3.9.2-7.2s-3.6-6.9-6.7-10.1l-1 1c-1.6 1.6-4.1 1.6-5.7 0l-3-3-3.2 3.2c-1.5 1.5-4.1 1.5-5.7 0L12.6 28.2c-1.6-1.6-1.6-4.1 0-5.7l1.3-1.3-5.6-5.6c-1.6-1.6-1.6-4.1 0-5.7l7.1-7.1c1.6-1.6 4.1-1.6 5.7 0l5.6 5.6L28 7.2c.7-.8 1.7-1.2 2.8-1.2s2.1.4 2.8 1.2l16.2 16.2c.8.8 1.2 1.8 1.2 2.8s-.4 2.1-1.2 2.8l-3.2 3.2 3 3c1.6 1.6 1.6 4.1 0 5.7l-1 1c3.3 3.5 5.9 7.3 7.2 10.9 1.5 4.1 1.3 7.4-.6 9.3-.2.1-.4.2-.7.2M16 22l-2 2c-.8.8-.8 2 0 2.8L30.2 43c.8.8 2.1.8 2.8 0l15.4-15.4c.4-.4.6-.9.6-1.4s-.2-1-.6-1.4L32.2 8.6c-.8-.8-2.1-.8-2.8 0l-2 2zm23 17.8 3 3c.8.8 2 .8 2.8 0l3.4-3.4c.8-.8.8-2 0-2.8l-3-3zM18.3 3.7c-.5 0-1 .2-1.4.6l-7.1 7.1c-.8.8-.8 2 0 2.8l5.6 5.6 9.9-9.9-5.6-5.6c-.4-.4-.9-.6-1.4-.6m15.9 29.9c-.6 0-1-.4-1-1v-6.1h-5c-.6 0-1-.4-1-1V19c0-.6.4-1 1-1s1 .4 1 1v5.5h5c.6 0 1 .4 1 1v7.1c0 .5-.5 1-1 1" />
  </svg>
);
export default IconWire;
