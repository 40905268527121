import * as React from "react";
const IconBellAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M52.1 46.4c-1.7-4.1-1.9-8.8-1.9-8.8V27.2c0-9.7-7.6-17.6-17.2-18.1V3c0-.6-.4-1-1-1s-1 .4-1 1v6.1c-9.6.5-17.2 8.4-17.2 18.1v10.4s-.3 4.8-2 8.9c-.7 1.7-.5 3.7.5 5.2s2.8 2.5 4.6 2.5h6.8C24 58.5 27.6 62 32 62s8-3.5 8.2-7.9H47c1.9 0 3.6-.9 4.6-2.5 1-1.5 1.2-3.4.5-5.2M32 60c-3.3 0-6-2.6-6.2-5.9h12.3C38 57.4 35.3 60 32 60m17.9-9.5c-.7 1-1.8 1.6-3 1.6H17c-1.2 0-2.3-.6-3-1.6s-.8-2.2-.3-3.4c1.8-4.4 2.1-9.3 2.1-9.6V27.2C15.8 18.3 23.1 11 32 11s16.2 7.3 16.2 16.2v10.5c0 .2.3 5.1 2.1 9.5.4 1.1.3 2.3-.4 3.3" />
  </svg>
);
export default IconBellAlt1;
