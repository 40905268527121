import * as React from "react";
const IconInfrared = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 6.6H6c-2.2 0-4 1.8-4 4v15.6c0 2.2 1.8 4 4 4h16.1v10.2h-7.2c-2.2 0-4 1.8-4 4v9c0 2.2 1.8 4 4 4h34.3c2.2 0 4-1.8 4-4v-9c0-2.2-1.8-4-4-4h-7.7V30.2H58c2.2 0 4-1.8 4-4V10.6c0-2.2-1.8-4-4-4m-6.9 37.8v9c0 1.1-.9 2-2 2H14.9c-1.1 0-2-.9-2-2v-9c0-1.1.9-2 2-2h34.3c1 0 1.9.9 1.9 2m-11.6-4H24.1V30.2h15.4zM60 26.2c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V10.6c0-1.1.9-2 2-2h52c1.1 0 2 .9 2 2z" />
    <path d="M15.8 17.4h-3.4c-.6 0-1 .4-1 1s.4 1 1 1h3.4c.6 0 1-.4 1-1s-.4-1-1-1M27.4 17.4H24c-.6 0-1 .4-1 1s.4 1 1 1h3.4c.6 0 1-.4 1-1s-.4-1-1-1M47.7 17.4h-3.4c-.6 0-1 .4-1 1s.4 1 1 1h3.4c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconInfrared;
