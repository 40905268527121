import * as React from "react";
const Icon5gSim = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M47 2H26.9c-1.5 0-2.9.6-3.9 1.6L13 13.7c-1 1-1.6 2.4-1.6 3.8v38.9c0 3 2.5 5.5 5.5 5.5h30c3 0 5.5-2.5 5.5-5.5v-49C52.5 4.5 50 2 47 2m3.5 54.5c0 1.9-1.6 3.5-3.5 3.5H17c-1.9 0-3.5-1.6-3.5-3.5V17.6c0-.9.4-1.8 1-2.4L24.5 5c.7-.7 1.6-1 2.5-1h20c1.9 0 3.5 1.6 3.5 3.5z" />
    <path d="M29.1 32c.6 0 1-.4 1-1s-.4-1-1-1h-8.3c-.6 0-1 .4-1 1v7.8c0 .6.4 1 1 1h7.3v5.8h-7.3c-.6 0-1 .4-1 1s.4 1 1 1h7.3c1.1 0 2-.9 2-2v-5.8c0-1.1-.9-2-2-2h-6.3V32zM44.9 32c.6 0 1-.4 1-1s-.4-1-1-1h-7.3c-1.1 0-2 .9-2 2v13.6c0 1.1.9 2 2 2h6.3c1.1 0 2-.9 2-2v-4.8c0-1.1-.9-2-2-2h-.6c-.6 0-1 .4-1 1s.4 1 1 1h.6v4.8h-6.3V32z" />
  </svg>
);
export default Icon5gSim;
