import * as React from "react";
const IconConstructionRular = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.9 38.9h-37V8.6c0-2.2-1.8-4-4-4H6c-2.2 0-4 1.8-4 4v45.7c0 2.8 2.2 5 5 5h49.8c2.8 0 5.1-2.3 5.1-5.1V44c.1-2.8-2.2-5.1-5-5.1M13.5 57.4H7c-1.7 0-3-1.3-3-3V8.6c0-1.1.9-2 2-2h9.8c1.1 0 2 .9 2 2v48.8zM60 54.2c0 1.7-1.4 3.1-3.1 3.1h-5.5v-9.5c0-.6-.4-1-1-1s-1 .4-1 1v9.5H42v-5.5c0-.6-.4-1-1-1s-1 .4-1 1v5.5h-7.3v-9.5c0-.6-.4-1-1-1s-1 .4-1 1v9.5H19.8V40.9h37c1.7 0 3.1 1.4 3.1 3.1v10.2z" />
  </svg>
);
export default IconConstructionRular;
