import * as React from "react";
const IconCastle = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 58.1h-3.9V25.5h.5c.6 0 1-.4 1-1V13.6c0-1.2-.4-2.3-1.2-3.2l-4.5-5c-.9-1-2.2-1.5-3.5-1.5s-2.6.6-3.5 1.5l-4.5 5c-.8.9-1.2 2-1.2 3.2v10.9c0 .6.4 1 1 1h.5v13.7c-2.1 1-5.8 1.6-9.8 1.6-3.7 0-7.2-.5-9.3-1.4V25.5h.5c.6 0 1-.4 1-1V13.6c0-1.2-.4-2.3-1.2-3.2l-4.5-5c-.9-1-2.2-1.5-3.5-1.5s-2.6.6-3.5 1.5l-4.5 5c-.8.9-1.2 2-1.2 3.2v10.9c0 .6.4 1 1 1h.5v32.6H3c-.6 0-1 .4-1 1s.4 1 1 1h58c.6 0 1-.4 1-1s-.4-1-1-1M42.2 13.6c0-.7.2-1.3.7-1.8l4.5-5c.5-.6 1.2-.9 2-.9s1.5.3 2 .9l4.5 5c.5.5.7 1.1.7 1.8v9.9H42.2zM31.9 42.8c3.8 0 7.4-.5 9.8-1.5v16.8H22.6V41.5c2.4.8 5.7 1.3 9.3 1.3M7.7 13.6c0-.7.2-1.3.7-1.8l4.5-5c1-1.1 3-1.1 4 0l4.5 5c.4.5.7 1.1.7 1.8v9.9H7.7zm1.5 44.5V25.5h11.4v32.6zm34.5 0V25.5h11.4v32.6z" />
  </svg>
);
export default IconCastle;
