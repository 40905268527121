import * as React from "react";
const IconMobileApplicationAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M43.4 2H20.6c-4 0-7.3 3.3-7.3 7.3v45.5c0 4 3.3 7.3 7.3 7.3h22.7c4 0 7.3-3.3 7.3-7.3V9.3c0-4-3.2-7.3-7.2-7.3M20.6 4h22.7c2.9 0 5.3 2.4 5.3 5.3v41.1H15.3V9.3c.1-2.9 2.4-5.3 5.3-5.3m22.8 56H20.6c-2.9 0-5.3-2.4-5.3-5.3v-2.4h33.3v2.5c0 2.8-2.3 5.2-5.2 5.2" />
    <path d="M22.5 15.2h5.7c.6 0 1-.4 1-1s-.4-1-1-1h-5.7c-.6 0-1 .4-1 1s.5 1 1 1M35.8 15.2h5.7c.6 0 1-.4 1-1s-.4-1-1-1h-5.7c-.6 0-1 .4-1 1s.4 1 1 1M25 25.9c-.9 1-1.4 2.5-1.4 4.1 0 2 1.3 4.3 3.8 7.1 1.8 2 3.5 3.4 3.6 3.4l.4.3c.2.2.4.2.6.2s.5-.1.6-.2l.4-.3c.1-.1 1.8-1.5 3.6-3.4 2.6-2.8 3.8-5.2 3.8-7.1 0-1.7-.5-3.1-1.4-4.1s-2.2-1.6-3.6-1.6c-1.6 0-2.7.5-3.4 1.1-.7-.6-1.8-1.1-3.4-1.1-1.4 0-2.7.6-3.6 1.6m6.2 1.6c.2.3.5.4.8.4s.7-.2.9-.5c.5-.8 1.4-1.1 2.5-1.1.8 0 1.6.3 2.1.9.6.6.9 1.6.9 2.8 0 1-.6 2.8-3.3 5.8-1.3 1.4-2.6 2.6-3.1 3-.5-.5-1.8-1.6-3.1-3-2.7-3-3.3-4.8-3.3-5.8 0-1.2.3-2.2.9-2.8.5-.6 1.3-.9 2.1-.9 1.2 0 2 .4 2.6 1.2" />
  </svg>
);
export default IconMobileApplicationAlt;
