import * as React from "react";
const IconLockers = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54 2H39c-3 0-5.6 1.7-7 4.1C30.6 3.7 28 2 25 2H10c-4.4 0-8 3.6-8 8v44c0 4.4 3.6 8 8 8h15c3 0 5.6-1.7 7-4.1 1.4 2.5 4 4.1 7 4.1h15c4.4 0 8-3.6 8-8V10c0-4.4-3.6-8-8-8M25 60H10c-3.3 0-6-2.7-6-6V10c0-3.3 2.7-6 6-6h15c3.3 0 6 2.7 6 6v44c0 3.3-2.7 6-6 6m35-6c0 3.3-2.7 6-6 6H39c-3.3 0-6-2.7-6-6V10c0-3.3 2.7-6 6-6h15c3.3 0 6 2.7 6 6z" />
    <path d="M24.5 13.3H10.9c-.6 0-1 .4-1 1s.4 1 1 1h13.6c.6 0 1-.4 1-1s-.5-1-1-1M24.5 21.3H10.9c-.6 0-1 .4-1 1s.4 1 1 1h13.6c.6 0 1-.4 1-1s-.5-1-1-1M52.8 13.3H40.5c-.6 0-1 .4-1 1s.4 1 1 1h12.3c.6 0 1-.4 1-1s-.5-1-1-1M52.8 21.3H40.5c-.6 0-1 .4-1 1s.4 1 1 1h12.3c.6 0 1-.4 1-1s-.5-1-1-1M11.9 30.4c-.6 0-1 .4-1 1v11.4c0 .6.4 1 1 1s1-.4 1-1V31.4c0-.5-.4-1-1-1M41.2 31.2c-.6 0-1 .4-1 1v11.4c0 .6.4 1 1 1s1-.4 1-1V32.2c0-.5-.4-1-1-1" />
  </svg>
);
export default IconLockers;
