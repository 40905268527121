import * as React from "react";
const IconGs = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 513 342"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 0h513v342H0z" />
    <path
      
      d="M256 0v117.4h-46.1l46.1 30.7v22.6h-22.6L160 121.8v48.9H96v-48.9l-73.4 48.9H0v-22.6l46.1-30.7H0v-64h46.1L0 22.7V0h22.6L96 48.9V0h64v48.9L233.4 0z"
    />
    <path
      
      d="M144 0h-32v69.4H0v32h112v69.3h32v-69.3h112v-32H144z"
    />
    <path  d="M256 22.7v30.7h-46.1z" />
    <path
      
      d="M0 170.7v-11l62.5-42.3h22.6L7.3 170.7zM7.3.1l77.8 53.2H62.5L0 11.1V.1zM256 .1v11l-62.5 42.3h-22.6L248.7.1zm-7.3 170.6-77.8-53.3h22.6l62.5 42.3v11z"
    />
    <ellipse cx={443.4} cy={233.6}  rx={29} ry={43.5} />
    <ellipse cx={406.9} cy={317.7}  rx={22} ry={9.3} />
    <ellipse cx={364} cy={317.7}  rx={22} ry={9.3} />
    <path  d="m342 190.1 43.4 86.9 43.5-86.9z" />
    <ellipse cx={327.5} cy={233.6}  rx={29} ry={43.5} />
    <path
      
      d="m371 175.6-14.5 14.5h57.9l-14.5-14.5 14.5-43.5s-13-29-29-29-29 29-29 29z"
    />
    <circle cx={385.4} cy={67} r={29}  />
    <circle cx={386} cy={88.7} r={29}  />
    <circle cx={443.4} cy={117.7} r={29}  />
    <circle cx={342.2} cy={146.7} r={29}  />
    <circle cx={429.8} cy={146.7} r={29}  />
    <circle cx={386} cy={233.6} r={29}  />
    <circle cx={457.9} cy={190.1} r={29}  />
    <circle cx={385.4} cy={289.5} r={29}  />
    <path
      
      d="m371 135.7 14.5-3.5 14.5 3.5c0 7.5-14.5 11-14.5 11s-14.5-3.5-14.5-11"
    />
    <circle cx={327.5} cy={117.4} r={29}  />
  </svg>
);
export default IconGs;
