import * as React from "react";
const IconPharmacy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M20.2 16.113c-.04-1.153-.006-2.323.376-3.22.196-.46.473-.817.863-1.068.395-.253.983-.447 1.885-.447.988 0 1.818.359 2.418.74a5.3 5.3 0 0 1 .854.677l.036.037.005.005a1 1 0 0 0 1.493-1.33l-.749.663c.749-.663.748-.664.748-.664l-.002-.002-.002-.003-.008-.008-.023-.025-.073-.076a7.262 7.262 0 0 0-1.206-.962c-.811-.515-2.01-1.052-3.49-1.052-1.193 0-2.18.26-2.966.764-.79.507-1.3 1.211-1.623 1.967-.553 1.298-.578 2.829-.537 4.004h-3.236c-.552 0-1.008.451-.92.997.585 3.65 4.345 6.523 9.039 6.857V29h2v-5.045a12.2 12.2 0 0 0 2.909-.603q.009.103.009.218c0 .344-.305.883-1.165 1.408-.26.158-.543.297-.835.412v2.11a7.7 7.7 0 0 0 1.877-.815C28.92 26.048 30 24.985 30 23.57c0-.356-.04-.712-.132-1.057 2.202-1.25 3.734-3.182 4.09-5.403.087-.546-.368-.997-.92-.997zM24 22c-3.722 0-6.473-1.792-7.543-3.887h15.086C30.473 20.208 27.722 22 24 22"
      clipRule="evenodd"
    />
    <path
      
      d="M28.615 34.273c0 .784-.223 1.487-.636 2.066h1.757v2H18.264v-2h4.818V34h2v1.907c.416-.092.738-.243.97-.422.354-.274.563-.661.563-1.212 0-.552-.21-.938-.563-1.212-.383-.296-1.011-.516-1.898-.516-1.055 0-2.274-.08-3.257-.51-.509-.223-.997-.557-1.355-1.06-.364-.512-.542-1.127-.542-1.816s.178-1.304.542-1.815c.358-.504.846-.838 1.355-1.061.344-.151.718-.259 1.103-.335v2.057q-.162.05-.3.11c-.27.118-.432.252-.528.388-.09.127-.172.323-.172.656s.081.529.172.656c.096.136.257.27.527.388.575.252 1.433.342 2.455.342 1.19 0 2.292.292 3.121.934.858.663 1.34 1.64 1.34 2.794"
    />
    <path
      
      fillRule="evenodd"
      d="M6.5 9a3 3 0 0 1 3-3h30a3 3 0 0 1 3 3v30a3 3 0 0 1-3 3h-30a3 3 0 0 1-3-3zm2 0a1 1 0 0 1 1-1h30a1 1 0 0 1 1 1v30a1 1 0 0 1-1 1h-30a1 1 0 0 1-1-1z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconPharmacy;
