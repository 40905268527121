import * as React from "react";
const IconDeleteAnchorPoint = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M4.5 10.3h15.9c.6 0 1-.4 1-1s-.4-1-1-1H4.5c-.6 0-1 .4-1 1s.4 1 1 1M61.1 18.8 45.2 2.9c-1.5-1.5-4.1-1.5-5.7 0L37 5.5c-.8.8-1.2 1.8-1.2 2.8 0 1.1.4 2.1 1.2 2.8l2.1 2.1L14 24.9c-1.1.5-2 1.5-2.4 2.7L2 56.1c-.6 1.7-.1 3.5 1.1 4.7.9.9 2.1 1.4 3.3 1.4.5 0 1-.1 1.5-.2l28.5-9.6c1.2-.4 2.2-1.3 2.7-2.4l11.7-25.1 2.1 2.1c.8.8 1.8 1.2 2.8 1.2s2.1-.4 2.8-1.2l2.6-2.6c.8-.8 1.2-1.8 1.2-2.8s-.5-2.1-1.2-2.8M37.2 49.2c-.3.6-.9 1.2-1.5 1.4L7.2 60.1c-.6.2-1.2.2-1.8-.1l15.7-15.7c1.1.7 2.3 1.2 3.7 1.2 3.6 0 6.5-2.9 6.5-6.5s-2.9-6.5-6.5-6.5-6.5 2.9-6.5 6.5c0 1.5.5 2.8 1.3 3.9L4 58.6c-.3-.6-.3-1.2-.1-1.9l9.6-28.5c.2-.7.7-1.2 1.4-1.5l25.7-12 8.7 8.7zM20.3 39c0-2.5 2-4.5 4.5-4.5s4.5 2 4.5 4.5-2 4.5-4.5 4.5-4.5-2-4.5-4.5m39.4-16-2.6 2.6c-.8.8-2.1.8-2.8 0L38.4 9.8c-.4-.4-.6-.9-.6-1.4s.2-1 .6-1.4L41 4.3c.4-.4.9-.6 1.4-.6s1 .2 1.4.6l15.8 15.8c.4.4.6.9.6 1.4s-.2 1.1-.5 1.5" />
  </svg>
);
export default IconDeleteAnchorPoint;
