import * as React from "react";
const IconBridgeAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 39.4h-7.4c-10.1 0-19.7-20.7-20.6-22.8v-5.5c0-.6-.4-1-1-1s-1 .4-1 1v5.5c-.9 2.1-10.5 22.8-20.6 22.8H3c-.6 0-1 .4-1 1s.4 1 1 1h7.3v10.5H8.8c-.6 0-1 .4-1 1s.4 1 1 1h4.9c.6 0 1-.4 1-1s-.4-1-1-1h-1.5V41.4h39.4v10.5h-1.5c-.6 0-1 .4-1 1s.4 1 1 1H55c.6 0 1-.4 1-1s-.4-1-1-1h-1.5V41.4H61c.6 0 1-.4 1-1s-.4-1-1-1m-13.6 0H33V21.2c2.7 5.2 8 14.2 14.4 18.2M31 21.2v18.2H16.6c6.4-4 11.7-13 14.4-18.2" />
  </svg>
);
export default IconBridgeAlt1;
