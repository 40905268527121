import * as React from "react";
const IconCream2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.6 31.6c-.5-2.4-2.6-4.2-5.1-4.2H12.4c-2.4 0-4.6 1.7-5.1 4.1-1.6 7.8-1.6 18.4 0 26.2.6 2.6 2.7 4.3 5.2 4.3h39.1c2.5 0 4.6-1.7 5.1-4.2 1.5-7.9 1.5-18.2-.1-26.2m-1.9 25.8c-.3 1.5-1.7 2.6-3.2 2.6h-39c-1.5 0-2.8-1.1-3.1-2.6-1.5-7.6-1.5-17.9 0-25.4.3-1.5 1.6-2.5 3.1-2.5h39.1c1.5 0 2.8 1.1 3.1 2.5 1.5 7.6 1.5 17.8 0 25.4M10 19.1h44c1.1 0 2-.9 2-2V6c0-2.2-1.8-4-4-4H12C9.8 2 8 3.8 8 6v11.1c0 1.1.9 2 2 2M10 6c0-1.1.9-2 2-2h40c1.1 0 2 .9 2 2v11.1H10z" />
  </svg>
);
export default IconCream2;
