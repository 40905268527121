import * as React from "react";
const IconVg = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 85.333 512 341.333"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 85.334h512v341.337H0z" />
    <path
      
      d="M256 85.334V202.66h-46.069L256 233.38v22.617h-22.628L160 207.078v48.919H96v-48.919l-73.372 48.919H0V233.38l46.069-30.72H0v-64h46.069L0 107.951V85.334h22.628L96 134.241V85.334h64v48.907l73.372-48.907z"
    />
    <g >
      <path d="M144 85.33h-32v69.333H0v32h112v69.334h32v-69.334h112v-32H144z" />
      <path d="M0 85.329v15.083l57.377 38.251H80zm256 0v15.083l-57.377 38.251H176z" />
    </g>
    <path  d="M256 107.951v30.712h-46.069z" />
    <path
      
      d="M0 85.329v15.083l57.377 38.251H80zm256 0v15.083l-57.377 38.251H176z"
    />
    <path  d="M256 107.951v30.712h-46.069z" />
    <path
      
      d="M0 255.997v-15.082l57.377-38.252H80zm256 0v-15.082l-57.377-38.252H176z"
    />
    <path
      
      d="m384 259.706-46.129 46.129c8.645 16.675 26.051 28.074 46.129 28.074s37.484-11.4 46.129-28.074z"
    />
    <path
      
      d="M332.058 178.084v81.624c.001 39.759 51.942 51.941 51.942 51.941s51.941-12.182 51.942-51.942v-81.623z"
    />
    <path  d="M372.87 215.181h22.261v59.359H372.87z" />
    <circle cx={384} cy={215.181} r={11.13}  />
    <path
      
      d="M346.902 192.92h14.84v14.84h-14.84zm0 33.392h14.84v14.84h-14.84zm0 33.391h14.84v14.84h-14.84zm59.359-66.783h14.84v14.84h-14.84zm0 33.392h14.84v14.84h-14.84zm0 33.391h14.84v14.84h-14.84z"
    />
  </svg>
);
export default IconVg;
