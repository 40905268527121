import * as React from "react";
const IconYenCard = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m52.9 35.1-6.2 7.6-6.2-7.6c-.3-.4-1-.5-1.4-.1s-.5 1-.1 1.4l6.7 8.2v.6h-4.9c-.6 0-1 .4-1 1s.4 1 1 1h4.9v4.3h-4.9c-.6 0-1 .4-1 1s.4 1 1 1h4.9v3.2c0 .6.4 1 1 1s1-.4 1-1v-3.2h4.9c.6 0 1-.4 1-1s-.4-1-1-1h-4.9v-4.3h4.9c.6 0 1-.4 1-1s-.4-1-1-1h-4.9v-.5l6.8-8.3c.4-.4.3-1.1-.1-1.4-.5-.4-1.1-.3-1.5.1" />
    <path d="M55.2 6.4H8.8C5.1 6.4 2 9.2 2 12.7v29C2 45.2 5.1 48 8.8 48h23.1c.6 0 1-.4 1-1s-.4-1-1-1H8.8C6.2 45.9 4 44 4 41.7V27.6h56v8.7c0 .6.4 1 1 1s1-.4 1-1V12.7c0-3.5-3.1-6.3-6.8-6.3m-46.4 2h46.4c2.6 0 4.8 1.9 4.8 4.3V17H4v-4.3c0-2.4 2.2-4.3 4.8-4.3M4 25.6V19h56v6.6z" />
  </svg>
);
export default IconYenCard;
