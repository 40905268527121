import * as React from "react";
const IconBehanceOriginal = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M26.1 29.8c1.7-.7 3-1.8 3.8-3.1s1.3-3 1.3-4.8c0-1.7-.3-3.2-.8-4.5-.6-1.3-1.4-2.3-2.5-3s-2.3-1.3-3.8-1.6c-1.6-.3-3.2-.4-4.9-.4H1v38h18.5c1.7 0 3.4-.1 4.9-.7 1.7-.4 3.1-1.1 4.4-2 1.3-.8 2.3-2 3-3.5.7-1.4 1.1-3.1 1.1-5.1 0-2.4-.6-4.5-1.7-6.1-1.2-1.4-2.9-2.5-5.1-3.2M9.5 19h7.9c.7 0 1.4.1 2.1.1s1.4.4 1.8.7c.6.3 1 .7 1.3 1.4.3.6.4 1.3.4 2.3 0 1.5-.4 2.8-1.4 3.5-1 .6-2.3 1-3.7 1H9.5zm14.6 22.5c-.3.7-.7 1.1-1.4 1.5-.6.4-1.3.7-2 .7-.7.1-1.5.1-2.3.1h-9V33.5h9.2c1.8 0 3.2.4 4.4 1.3 1.1.8 1.7 2.3 1.7 4.2-.2 1-.2 1.8-.6 2.5M62.4 32.5c-.4-2-1.3-3.7-2.4-5.2s-2.5-2.7-4.2-3.7c-1.7-.8-3.8-1.4-6.1-1.4-2.1 0-4.1.4-5.6 1.1-1.7.7-3.2 1.7-4.5 3.1-1.3 1.3-2.3 2.8-3 4.6-.7 1.7-1.1 3.7-1.1 5.8s.3 4.1 1 5.9 1.7 3.2 2.8 4.6c1.3 1.3 2.7 2.3 4.5 3 1.7.7 3.7 1.1 5.9 1.1 3.1 0 5.8-.7 8-2.1 2.3-1.4 3.8-3.8 4.9-7.2H56c-.3.8-1 1.7-2 2.4s-2.4 1.1-3.9 1.1c-2.1 0-3.8-.6-4.9-1.7s-1.8-3.2-1.8-5.4H63c0-2.1-.1-4.2-.6-6M43 33.8c0-.7.3-1.4.5-2 .3-.7.6-1.4 1.1-1.9.6-.6 1.4-1.2 2.2-1.6s1.8-.4 2.7-.4c1.8 0 3.4.6 4.2 1.5.8 1 1.5 2.4 1.7 4.4z" />
  </svg>
);
export default IconBehanceOriginal;
