import * as React from "react";
const IconBeachBall = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2M4 32C4 17.7 14.8 5.9 28.6 4.2c-5.3 7.5-8.2 17.3-8.2 27.8s2.9 20.3 8.2 27.8C14.8 58.1 4 46.3 4 32m27.2 28c-5.6-7.3-8.8-17.4-8.8-28s3.2-20.7 8.8-28h1.6c5.6 7.3 8.8 17.4 8.8 28s-3.2 20.7-8.8 28h-1.6m4.2-.2c5.3-7.5 8.2-17.3 8.2-27.8s-2.9-20.3-8.2-27.8C49.2 5.9 60 17.7 60 32S49.2 58.1 35.4 59.8" />
  </svg>
);
export default IconBeachBall;
