import * as React from "react";
const IconAg = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 513 342"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M0 0h513v342H0z" />
    <path
      
      d="M88.8 136.5c-2.2 12.9-3.4 26.2-3.4 39.8s1.2 27 3.4 39.8L256 228.3l167.2-12.1c2.2-12.9 3.4-26.2 3.4-39.8s-1.2-27-3.4-39.8"
    />
    <path
      
      d="M423.2 219H88.8c15.8 69.8 84.7 122.3 167.2 122.3S407.4 288.8 423.2 219"
    />
    <path
      
      d="M365.9 136.5H146.1l44.9-21.1-23.9-43.5 48.8 9.4L222 32l34 36.2L290 32l6.1 49.3 48.8-9.4-23.9 43.5z"
    />
    <g >
      <path d="M256.5 342 0 0v342z" />
      <path d="M513 342V0L256 342z" />
    </g>
  </svg>
);
export default IconAg;
