import * as React from "react";
const IconLaptopReportMedical = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 50h-3.3V21.1c0-2.6-2.1-4.8-4.7-5-.5 0-1 .4-1.1.9 0 .6.4 1 .9 1.1 1.6.1 2.8 1.4 2.8 3V50H8.3V21.1c0-1.6 1.3-3 3-3 .6 0 1-.4 1-1s-.5-1-1-1c-2.8 0-5 2.2-5 5V50H3c-.6 0-1 .4-1 1s.4 1 1 1h58c.6 0 1-.4 1-1s-.4-1-1-1" />
    <path d="M26.4 28.3H31v4.6c0 .6.4 1 1 1s1-.4 1-1v-4.6h4.6c.6 0 1-.4 1-1s-.4-1-1-1H33v-4.6c0-.6-.4-1-1-1s-1 .4-1 1v4.6h-4.6c-.6 0-1 .4-1 1s.5 1 1 1" />
    <path d="M32 42.5c8.4 0 15.3-6.9 15.3-15.3S40.4 12 32 12s-15.3 6.9-15.3 15.3S23.6 42.5 32 42.5M32 14c7.3 0 13.3 6 13.3 13.3s-6 13.3-13.3 13.3-13.3-6-13.3-13.3c0-7.4 6-13.3 13.3-13.3" />
  </svg>
);
export default IconLaptopReportMedical;
