import * as React from "react";
const IconAnalyticsChat = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.9 7.5H7.1C4.3 7.5 2 9.8 2 12.6V40c0 2.8 2.3 5.1 5.1 5.1h2.3v9c0 1 .6 1.9 1.6 2.2.3.1.5.1.8.1.7 0 1.4-.3 1.9-.9L22 45.1h34.9c2.8 0 5.1-2.3 5.1-5.1V12.6c0-2.8-2.3-5.1-5.1-5.1M60 40c0 1.7-1.4 3.1-3.1 3.1H21.5c-.3 0-.6.1-.8.4l-8.6 10.8c-.1.2-.3.2-.4.1-.1 0-.3-.1-.3-.4V44c0-.6-.4-1-1-1H7.1c-1.7.1-3.1-1.3-3.1-3V12.6c0-1.7 1.4-3.1 3.1-3.1h49.7c1.7 0 3.1 1.4 3.1 3.1V40z" />
    <path d="M43.5 32.3h-2.8V17c0-.6-.4-1-1-1s-1 .4-1 1v15.2h-6.1V19.5c0-.6-.4-1-1-1s-1 .4-1 1v12.8h-6.1v-8.8c0-.6-.4-1-1-1s-1 .4-1 1v8.8h-2c-.6 0-1 .4-1 1s.4 1 1 1h23.1c.6 0 1-.4 1-1-.1-.6-.5-1-1.1-1" />
  </svg>
);
export default IconAnalyticsChat;
