import * as React from "react";
const IconLowVision = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M24 14c-8.093 0-14.159 4.548-17.375 8.188a2.71 2.71 0 0 0 0 3.624C9.841 29.452 15.907 34 24 34s14.159-4.548 17.375-8.188a2.71 2.71 0 0 0 0-3.624C38.159 18.548 32.093 14 24 14M5.126 20.864C8.566 16.97 15.134 12 24 12s15.434 4.97 18.874 8.864a4.71 4.71 0 0 1 0 6.272C39.434 31.03 32.866 36 24 36S8.566 31.03 5.126 27.136a4.71 4.71 0 0 1 0-6.272"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M31.05 16.907c-3.918-3.893-10.25-3.873-14.143.044l-1.419-1.41c4.672-4.7 12.27-4.724 16.97-.053 4.702 4.672 4.725 12.27.054 16.97l-1.419-1.409c3.893-3.917 3.873-10.249-.044-14.142"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M9.293 9.293a1 1 0 0 1 1.414 0l28 28a1 1 0 0 1-1.414 1.414l-28-28a1 1 0 0 1 0-1.414"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M29.415 21.413a1.99 1.99 0 0 1-2.002.5 4 4 0 0 1-.606 4.937l1.403 1.425a6 6 0 0 0 1.205-6.862m-2.828-2.829a6 6 0 0 0-6.797 1.141l1.403 1.425a4 4 0 0 1 4.894-.563 2 2 0 0 1 .5-2.003"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M28 19a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-3 1a3 3 0 1 1 6 0 3 3 0 0 1-6 0M12 16a1 1 0 0 1 1-1h3a1 1 0 0 1 0 2h-3a1 1 0 0 1-1-1M7 28a1 1 0 0 1 1-1h20a1 1 0 0 1 0 2H8a1 1 0 0 1-1-1M12 32a1 1 0 0 1 1-1h19a1 1 0 0 1 0 2H13a1 1 0 0 1-1-1M7 20a1 1 0 0 1 1-1h12a1 1 0 0 1 0 2H8a1 1 0 0 1-1-1M5 24a1 1 0 0 1 1-1h18a1 1 0 0 1 0 2H6a1 1 0 0 1-1-1"
      clipRule="evenodd"
    />
  </svg>
);
export default IconLowVision;
