import * as React from "react";
const IconAzure = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M34.8 7.3 16.5 23.1 1 51.2h14.1zm2.6 3.8-7.8 22 14.8 18.7-29 4.9H63z" />
  </svg>
);
export default IconAzure;
