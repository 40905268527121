import * as React from "react";
const IconHospitalBedAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56 25H14.7v-1.3c0-3.9-3.2-7.1-7.1-7.1H4V8.8c0-.6-.4-1-1-1s-1 .4-1 1v46.7c0 .6.4 1 1 1s1-.4 1-1v-6.6h56v6.6c0 .6.4 1 1 1s1-.4 1-1V31c0-3.3-2.7-6-6-6m4 6v6H4V27h52c2.2 0 4 1.8 4 4m-28.8 8v7.9H17.7V39zm2 0h12.1v7.9H33.2zM7.6 18.6c2.8 0 5.1 2.3 5.1 5.1V25H4v-6.4zM4 39h11.7v7.9H4zm43.3 7.9V39H60v7.9zM51.8 13.8h3.3v3.3c0 .6.4 1 1 1s1-.4 1-1v-3.3h3.3c.6 0 1-.4 1-1s-.4-1-1-1h-3.3V8.5c0-.6-.4-1-1-1s-1 .4-1 1v3.3h-3.3c-.6 0-1 .4-1 1s.4 1 1 1" />
  </svg>
);
export default IconHospitalBedAlt2;
