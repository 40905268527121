import * as React from "react";
const IconArrowMinimizeAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.9 28.8H35.7c-.6 0-1-.4-1-1V12.5c0-.6.4-1 1-1s1 .4 1 1v12.8l22.1-22c.4-.4 1-.4 1.4 0s.4 1 0 1.4l-22 22.1h13.7c.6 0 1 .4 1 1s-.4 1-1 1M4.4 61c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l22.1-22.1H12.1c-.6 0-1-.4-1-1s.4-1 1-1h16.2c.6 0 1 .4 1 1v15.3c0 .6-.4 1-1 1s-1-.4-1-1V38.6L5.2 60.7c-.2.2-.5.3-.8.3" />
  </svg>
);
export default IconArrowMinimizeAlt;
