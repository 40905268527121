import * as React from "react";
const IconPartyHorn = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M19.7 22.4c-.2-.2-.6-.3-.9-.3-.3.1-.6.3-.7.6L3.8 58.9c-.2.4-.1.8.2 1.1.2.2.4.3.7.3.1 0 .2 0 .4-.1L31 50l10.2-4c.3-.1.5-.4.6-.7s0-.7-.3-.9zm1.8 29.2-9.1-9.1 2.9-7.4 13.6 13.6zm-9.9-7 7.8 7.8-12.9 5.1zm19.3 3.3L16.1 33.1l3.2-8.2 19.8 19.8zM32.6 28.6c.2.2.5.3.7.3s.5-.1.7-.3c1.4-1.4 2.7-1.6 4.2-1.7 1.6-.1 3.5-.3 5.4-2.3 2-2 2.1-3.8 2.3-5.4.1-1.5.3-2.8 1.7-4.2s2.7-1.6 4.2-1.7c1.6-.1 3.5-.3 5.4-2.3.4-.4.4-1 0-1.4s-1-.4-1.4 0c-1.4 1.4-2.7 1.6-4.2 1.7-1.6.1-3.5.3-5.4 2.3-2 2-2.1 3.8-2.3 5.4-.1 1.5-.2 2.8-1.7 4.2-1.4 1.4-2.7 1.6-4.2 1.7-1.6.1-3.5.3-5.4 2.3-.4.4-.4 1 0 1.4M26.9 22.5c.2 0 .4-.1.5-.2s10.8-7.2 8.1-17.8c-.1-.5-.7-.9-1.2-.7-.5.1-.9.7-.7 1.2 2.4 9.3-7.1 15.6-7.2 15.6-.5.3-.6.9-.3 1.4.1.3.4.5.8.5M59.5 28.5c-10.7-2.7-17.7 8-17.8 8.1-.3.5-.2 1.1.3 1.4.2.1.4.2.5.2.3 0 .6-.2.8-.5.1-.1 6.4-9.6 15.6-7.2.5.1 1.1-.2 1.2-.7.3-.6 0-1.1-.6-1.3" />
  </svg>
);
export default IconPartyHorn;
