import * as React from "react";
const IconDatabase7 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54 56c-.3-.4-1-.5-1.4-.2l-3.1 2.3V53c0-2.6-2.1-4.7-4.7-4.7H33v-7c9.2-.2 18.4-3 18.4-7.6V10.6C51.4 5.7 41.4 3 32 3s-19.4 2.7-19.4 7.6v23.1c0 4.6 9.2 7.4 18.4 7.6v7H19.2c-2.6 0-4.7 2.1-4.7 4.7v5l-3.1-2.3c-.4-.3-1.1-.2-1.4.3-.3.4-.2 1.1.2 1.4l4.7 3.5c.2.1.4.2.6.2s.4-.1.6-.2l4.7-3.4c.4-.3.5-1 .2-1.4s-1-.5-1.4-.2l-3.1 2.3V53c0-1.5 1.2-2.7 2.7-2.7H31V58l-3.1-2.3c-.4-.3-1.1-.2-1.4.2s-.2 1.1.2 1.4l4.7 3.5c.2.1.4.2.6.2s.4-.1.6-.2l4.7-3.4c.4-.3.5-1 .2-1.4s-1-.5-1.4-.2L33 58.1v-7.8h11.8c1.5 0 2.7 1.2 2.7 2.7v5l-3.1-2.3c-.4-.3-1.1-.2-1.4.3-.3.4-.2 1.1.2 1.4l4.7 3.5c.2.1.4.2.6.2s.4-.1.6-.2l4.7-3.4c.4-.4.5-1 .2-1.5M32 5c10 0 17.4 2.9 17.4 5.6S42 16.2 32 16.2s-17.4-2.9-17.4-5.6S22 5 32 5m-17.4 9.1c3.4 2.7 10.6 4.1 17.4 4.1 6.9 0 14-1.4 17.4-4.1v4.8c0 2.6-7.5 5.6-17.4 5.6-10 0-17.4-2.9-17.4-5.6zm0 8.3C18 25 25.1 26.5 32 26.5s14-1.4 17.4-4.1v3.9c0 2.6-7.5 5.6-17.4 5.6-10 0-17.4-2.9-17.4-5.6zm0 11.3v-3.9c3.4 2.7 10.6 4.1 17.4 4.1 6.9 0 14-1.4 17.4-4.1v3.9c0 2.3-6.6 5.6-17.5 5.6-10.7 0-17.3-3.3-17.3-5.6" />
  </svg>
);
export default IconDatabase7;
