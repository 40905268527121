import * as React from "react";
const IconMessageAttachement = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.5 37.1-4.9-4.9c-.8-.8-2.1-.8-2.8 0l-4.9 4.9c-.8.8-.8 2 0 2.8l1.7 1.8-3.4 3.3-1.7-1.7c-.8-.8-2-.8-2.8 0l-4.9 4.9c-.8.8-.8 2 0 2.8l4.9 4.9c.4.4.9.6 1.4.6s1-.2 1.4-.6l4.9-4.9c.8-.8.8-2 0-2.8l-1.7-1.7 3.3-3.3 1.7 1.7c.4.4.9.6 1.4.6s1-.2 1.4-.6l4.9-4.9c.9-.8.9-2.1.1-2.9M44.1 54.5l-4.9-4.9 4.9-4.9 4.9 4.9zm11.1-11-4.9-4.9 4.9-4.9 4.9 4.9zM32.5 44.4H6.7c-1.5 0-2.8-1.3-2.8-2.8V12.3c0-.3.1-.6.2-.9l23.3 14.1c.8.5 1.7.7 2.6.7s1.8-.2 2.6-.7L56 11.4c.1.3.2.6.2.9v13.4c0 .6.4 1 1 1s1-.4 1-1V12.3c0-2.6-2.2-4.8-4.8-4.8H6.7c-2.7 0-4.8 2.2-4.8 4.8v29.3c0 2.6 2.2 4.8 4.8 4.8h25.8c.6 0 1-.4 1-1s-.5-1-1-1M53.3 9.5c.5 0 .9.1 1.3.3l-23 14c-1 .6-2.2.6-3.2 0l-23-14c.4-.2.8-.3 1.3-.3z" />
  </svg>
);
export default IconMessageAttachement;
