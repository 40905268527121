import * as React from "react";
const IconArrowTopRightCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M15 50c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4L46.6 16H19.5c-.6 0-1-.4-1-1s.4-1 1-1H49c.6 0 1 .4 1 1v29.5c0 .6-.4 1-1 1s-1-.4-1-1v-27L15.8 49.7c-.2.2-.5.3-.8.3" />
  </svg>
);
export default IconArrowTopRightCopy;
