import * as React from "react";
const IconDatabase1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.4 40.2h-8.8c-.7 0-1.2-.4-1.5-1L47 36.5c0-.1-.1-.1-.1-.2V12.6c0-5.6-11.6-8.7-22.5-8.7S2 6.9 1.9 12.5v27c0 5.5 11.4 8.7 22.5 8.7 2 0 3.9-.1 5.8-.3.5-.1.9-.5.9-1.1-.1-.5-.5-.9-1.1-.9-1.8.2-3.7.3-5.6.3C12.8 46.2 4 42.7 4 39.5v-4.9c3.8 3.2 12.3 4.9 20.5 4.9 2.3 0 4.6-.1 6.9-.4v17.4c0 2 1.6 3.6 3.6 3.6h23.4c2 0 3.6-1.6 3.6-3.6V43.8c0-2-1.6-3.6-3.6-3.6M4 16.3c3.8 3.2 12.3 4.9 20.5 4.9s16.7-1.7 20.5-4.9v5.9c-.1 3.1-8.5 6.6-20.5 6.6C12.4 28.9 4 25.4 4 22.2zM24.5 5.9c12 0 20.5 3.5 20.5 6.6-.1 3.1-8.5 6.6-20.5 6.6C12.4 19.2 4 15.7 4 12.6c0-3.2 8.4-6.7 20.5-6.7M4 30.9V26c3.8 3.2 12.3 4.9 20.5 4.9S41.2 29.2 45 26v8.5c-.4-.1-.8-.2-1.3-.2h-8.8c-1.7 0-3.2 1.2-3.5 2.9-2.2.3-4.6.4-6.9.4C12.4 37.5 4 34 4 30.9m56 25.6c0 .9-.7 1.6-1.6 1.6H34.9c-.9 0-1.6-.7-1.6-1.6V37.8c0-.9.7-1.6 1.6-1.6h8.8c.7 0 1.2.4 1.5 1l1.1 2.7c.6 1.4 1.9 2.3 3.3 2.3h8.8c.9 0 1.6.7 1.6 1.6z" />
  </svg>
);
export default IconDatabase1;
