import * as React from "react";
const IconFitbit = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 65 64"
    {...props}
  >
    <path
      
      d="M35.768 4.864c0 2.606-2.158 4.76-4.76 4.76-2.594 0-4.76-2.154-4.76-4.76 0-2.592 2.166-4.76 4.76-4.76 2.602 0 4.76 2.168 4.76 4.76m-4.76 8.646c-2.802 0-4.97 2.168-4.97 4.982 0 2.81 2.168 4.978 4.982 4.978 2.8 0 4.968-2.168 4.968-4.978 0-2.814-2.198-4.982-5-4.982zm0 13.418c-2.802 0-5.19 2.374-5.19 5.186s2.386 5.186 5.2 5.186c2.8 0 5.186-2.374 5.186-5.186s-2.386-5.186-5.186-5.186zm0 13.614c-2.802 0-4.97 2.168-4.97 4.968 0 2.814 2.168 4.982 4.982 4.982 2.8 0 4.968-2.168 4.968-4.982 0-2.582-2.198-4.968-5-4.968zm0 13.844c-2.594 0-4.76 2.154-4.76 4.76 0 2.582 2.166 4.75 4.76 4.75 2.602 0 4.76-2.158 4.76-4.76 0-2.594-2.158-4.75-4.76-4.75m13.198-41.522c-3.032 0-5.624 2.606-5.624 5.626 0 3.018 2.592 5.614 5.624 5.614 3.022 0 5.614-2.594 5.614-5.614 0-3.022-2.592-5.626-5.614-5.626m0 13.408a5.81 5.81 0 0 0-5.842 5.842 5.816 5.816 0 0 0 5.842 5.832 5.81 5.81 0 0 0 5.832-5.842 5.81 5.81 0 0 0-5.832-5.832m0 13.624c-3.032 0-5.624 2.606-5.624 5.614 0 3.032 2.592 5.626 5.624 5.626 3.022 0 5.614-2.606 5.614-5.626 0-3.018-2.592-5.614-5.614-5.614M57.82 25.624c-3.456 0-6.478 2.814-6.478 6.49 0 3.458 2.802 6.49 6.478 6.49 3.678 0 6.49-2.846 6.49-6.522-.218-3.688-3.032-6.478-6.49-6.478zM17.6 14.166c-2.374 0-4.312 1.95-4.312 4.326 0 2.374 1.938 4.322 4.312 4.322a4.33 4.33 0 0 0 4.322-4.322 4.323 4.323 0 0 0-4.322-4.314zm0 13.408c-2.592 0-4.542 1.936-4.542 4.542 0 2.594 1.928 4.542 4.522 4.542 2.602 0 4.52-1.946 4.52-4.542 0-2.606-1.958-4.542-4.552-4.542zm0 13.612c-2.374 0-4.312 1.95-4.312 4.322 0 2.386 1.938 4.326 4.312 4.326 2.386 0 4.322-1.96 4.322-4.354a4.31 4.31 0 0 0-4.322-4.322zM4.206 28.218A3.875 3.875 0 0 0 .31 32.114 3.873 3.873 0 0 0 4.206 36a3.87 3.87 0 0 0 3.886-3.886 3.873 3.873 0 0 0-3.886-3.896"
    />
  </svg>
);
export default IconFitbit;
