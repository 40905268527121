import * as React from "react";
const IconSatay = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M22.3 38.2h-5.4c-2.2 0-4 1.8-4 4v5.4c0 2.2 1.8 4 4 4h1.7V61c0 .6.4 1 1 1s1-.4 1-1v-9.4h1.8c2.2 0 4-1.8 4-4v-5.4c-.1-2.2-1.9-4-4.1-4m2 9.4c0 1.1-.9 2-2 2H17c-1.1 0-2-.9-2-2v-5.4c0-1.1.9-2 2-2h5.4c1.1 0 2 .9 2 2v5.4zM22.4 24.2c2.2-.1 3.9-1.8 3.9-4v-5.4c0-2.2-1.8-4-4-4h-1.8V3c0-.6-.4-1-1-1s-1 .4-1 1v7.8h-1.7c-2.2 0-4 1.8-4 4v5.4c0 2.2 1.7 3.9 3.9 4-2.6 1.1-4.4 3.7-4.4 6.6 0 4 3.2 7.2 7.2 7.2s7.2-3.2 7.2-7.2c.1-2.9-1.7-5.5-4.3-6.6m-7.5-4v-5.4c0-1.1.9-2 2-2h5.4c1.1 0 2 .9 2 2v5.4c0 1.1-.9 2-2 2h-5.4c-1.1 0-2-.9-2-2m4.7 15.9c-2.9 0-5.2-2.3-5.2-5.2s2.3-5.2 5.2-5.2 5.2 2.3 5.2 5.2-2.4 5.2-5.2 5.2M47.1 38.2h-5.4c-2.2 0-4 1.8-4 4v5.4c0 2.2 1.8 4 4 4h1.7V61c0 .6.4 1 1 1s1-.4 1-1v-9.4h1.8c2.2 0 4-1.8 4-4v-5.4c-.1-2.2-1.9-4-4.1-4m2 9.4c0 1.1-.9 2-2 2h-5.300000000000001c-1.1 0-2-.9-2-2v-5.4c0-1.1.9-2 2-2h5.4c1.1 0 2 .9 2 2v5.4zM37.2 30.9c0 4 3.2 7.2 7.2 7.2s7.2-3.2 7.2-7.2c0-3-1.8-5.5-4.4-6.6 2.2-.1 3.9-1.8 3.9-4v-5.4c0-2.2-1.8-4-4-4h-1.8V3c0-.6-.4-1-1-1s-1 .4-1 1v7.8h-1.7c-2.2 0-4 1.8-4 4v5.4c0 2.2 1.7 3.9 3.9 4-2.5 1.1-4.3 3.7-4.3 6.7m2.5-10.7v-5.4c0-1.1.9-2 2-2h5.4c1.1 0 2 .9 2 2v5.4c0 1.1-.9 2-2 2h-5.4c-1.1 0-2-.9-2-2m4.7 5.5c2.9 0 5.2 2.3 5.2 5.2s-2.3 5.2-5.2 5.2-5.2-2.3-5.2-5.2 2.4-5.2 5.2-5.2" />
  </svg>
);
export default IconSatay;
