import * as React from "react";
const IconFlats = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m58.7 23.7-2.8-3.8c-.9-1.2-2.4-2-3.9-2h-6.5V13c0-1-.3-2-.9-2.9L40 4c-.9-1.2-2.4-2-3.9-2H13.8c-1.5 0-3 .7-3.9 2l-4.5 6.2c-.7.8-1 1.8-1 2.9v44.1c0 2.7 2.2 4.9 4.9 4.9h45.6c2.7 0 4.9-2.2 4.9-4.9V26.6c-.2-1.1-.5-2.1-1.1-2.9M52 19.9c.9 0 1.8.4 2.3 1.2l2.4 3.2H45.5v-4.4zM11.5 5.2C12 4.4 12.9 4 13.8 4h22.3c.9 0 1.8.4 2.3 1.2l4.1 5.6H7.3zM30.3 60H9.2c-1.6 0-2.9-1.3-2.9-2.9V12.8h37.1v44.4c0 1.6-1.3 2.9-2.9 2.9H30.3zm27.3-2.9c0 1.6-1.3 2.9-2.9 2.9H44.5c.6-.8 1-1.8 1-2.9V26.3h12.2v30.8z" />
    <path d="M20 19.9h-3.2c-.6 0-1 .4-1 1v3.2c0 .6.4 1 1 1H20c.6 0 1-.4 1-1v-3.2c0-.5-.4-1-1-1M32.9 19.9h-3.2c-.6 0-1 .4-1 1v3.2c0 .6.4 1 1 1h3.2c.6 0 1-.4 1-1v-3.2c0-.5-.4-1-1-1M20 32.8h-3.2c-.6 0-1 .4-1 1V37c0 .6.4 1 1 1H20c.6 0 1-.4 1-1v-3.2c0-.5-.4-1-1-1M32.9 32.8h-3.2c-.6 0-1 .4-1 1V37c0 .6.4 1 1 1h3.2c.6 0 1-.4 1-1v-3.2c0-.5-.4-1-1-1M20 45.7h-3.2c-.6 0-1 .4-1 1v3.2c0 .6.4 1 1 1H20c.6 0 1-.4 1-1v-3.2c0-.5-.4-1-1-1M32.9 45.7h-3.2c-.6 0-1 .4-1 1v3.2c0 .6.4 1 1 1h3.2c.6 0 1-.4 1-1v-3.2c0-.5-.4-1-1-1M53.1 32.1H50c-.6 0-1 .4-1 1v3.2c0 .6.4 1 1 1h3.2c.6 0 1-.4 1-1v-3.2c-.1-.5-.5-1-1.1-1M53.1 45H50c-.6 0-1 .4-1 1v3.2c0 .6.4 1 1 1h3.2c.6 0 1-.4 1-1V46c-.1-.5-.5-1-1.1-1" />
  </svg>
);
export default IconFlats;
