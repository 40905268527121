import * as React from "react";
const IconBed7 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.5 42.4v-.1l-8.6-18.6v-3.8C49.9 10 41.9 2 32 2s-17.9 8-17.9 17.9v3.8L5.5 42.3c-.1.1-.1.3-.1.4V61c0 .6.4 1 1 1s1-.4 1-1v-6.1h49.2V61c0 .6.4 1 1 1s1-.4 1-1V42.7c0-.1-.1-.2-.1-.3M15.8 24.9h32.4L56 41.7H8zm9.5-3.9v-3.1c0-1.1.9-1.9 1.9-1.9h9.6c1.1 0 1.9.9 1.9 1.9V21c0 1.1-.9 1.9-1.9 1.9h-9.6c-1.1 0-1.9-.9-1.9-1.9m-9.2-1.1C16.1 11.1 23.2 4 32 4s15.9 7.1 15.9 15.9v3h-7.7c.3-.6.5-1.2.5-1.9v-3.1c0-2.2-1.8-3.9-3.9-3.9h-9.6c-2.2 0-3.9 1.8-3.9 3.9V21c0 .7.2 1.3.5 1.9h-7.7zm-8.7 33v-9.2h49.2v9.2z" />
  </svg>
);
export default IconBed7;
