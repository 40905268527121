import * as React from "react";
const IconArrowsVerticalCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M41.6 49.1 33 57.6V6.4l8.7 8.5c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4L32.7 3.3c-.4-.4-1-.4-1.4 0L21 13.4c-.4.4-.4 1 0 1.4s1 .4 1.4 0L31 6.4v51.2l-8.6-8.5c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l10.3 10.2c.2.2.4.3.7.3s.5-.1.7-.3L43 50.6c.4-.4.4-1 0-1.4-.3-.5-1-.5-1.4-.1" />
  </svg>
);
export default IconArrowsVerticalCopy;
