import * as React from "react";
const IconChestOfDrawers4 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 4.4H7c-2.8 0-5 2.2-5 5v40.2c0 2.8 2.2 5 5 5h.7v4.1c0 .6.4 1 1 1s1-.4 1-1v-4.1h43.1v4.1c0 .6.4 1 1 1s1-.4 1-1v-4.1H57c2.8 0 5-2.2 5-5V9.4c0-2.8-2.2-5-5-5M4 21.9h27.5v11.5H4zm29.5 0H60v11.5H33.5zM7 6.4h50c1.7 0 3 1.3 3 3V20H4V9.4c0-1.7 1.3-3 3-3M4 49.6V35.4h27.5v16.8c0 .1 0 .2.1.4H7c-1.7 0-3-1.4-3-3m53 3H33.4c0-.1.1-.2.1-.4V35.4H60v14.1c0 1.7-1.3 3.1-3 3.1" />
    <path d="M45.9 42.9c-.6 0-1 .4-1 1V46c0 .6.4 1 1 1s1-.4 1-1v-2.1c0-.6-.5-1-1-1M19 42.9c-.6 0-1 .4-1 1V46c0 .6.4 1 1 1s1-.4 1-1v-2.1c0-.6-.4-1-1-1" />
  </svg>
);
export default IconChestOfDrawers4;
