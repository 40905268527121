import * as React from "react";
const IconCute = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
    <path d="M21.5 26.3c1.9 0 3.5-1.6 3.5-3.5s-1.6-3.5-3.5-3.5-3.5 1.6-3.5 3.5 1.6 3.5 3.5 3.5M42.5 26.3c1.9 0 3.5-1.6 3.5-3.5s-1.6-3.5-3.5-3.5-3.5 1.6-3.5 3.5 1.6 3.5 3.5 3.5M45.2 44.2c-.6 0-1 .4-1 1 0 2.5-2.5 4.5-5.6 4.5-1.5 0-2.9-.5-4-1.4-1.5-1.2-3.7-1.2-5.1 0-1.1.9-2.5 1.4-4 1.4-3.1 0-5.6-2-5.6-4.5 0-.6-.4-1-1-1s-1 .4-1 1c0 3.6 3.4 6.5 7.6 6.5 2 0 3.9-.7 5.3-1.8.7-.6 1.9-.6 2.6 0 1.4 1.2 3.3 1.8 5.3 1.8 4.2 0 7.6-2.9 7.6-6.5-.1-.6-.6-1-1.1-1" />
  </svg>
);
export default IconCute;
