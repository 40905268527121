import * as React from "react";
const IconTargetAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 31h-7.3c-.6-11.2-9.6-20.1-20.7-20.6V3c0-.6-.4-1-1-1s-1 .4-1 1v7.4c-11.1.5-20.1 9.4-20.7 20.6H3c-.6 0-1 .4-1 1s.4 1 1 1h7.3c.4 11.3 9.5 20.4 20.7 20.9V61c0 .6.4 1 1 1s1-.4 1-1v-7.1c11.2-.5 20.2-9.6 20.7-20.9H61c.6 0 1-.4 1-1s-.4-1-1-1M33 51.9v-1.7c0-.6-.4-1-1-1s-1 .4-1 1v1.7c-10.1-.5-18.2-8.7-18.7-18.9h1.5c.6 0 1-.4 1-1s-.4-1-1-1h-1.4C12.9 21 21 12.9 31 12.4v1.4c0 .6.4 1 1 1s1-.4 1-1v-1.4C43 12.9 51.1 21 51.6 31h-1.4c-.6 0-1 .4-1 1s.4 1 1 1h1.5c-.5 10.2-8.6 18.4-18.7 18.9" />
    <path d="M32 24.6c-4.1 0-7.4 3.3-7.4 7.4s3.3 7.4 7.4 7.4 7.4-3.3 7.4-7.4-3.3-7.4-7.4-7.4m0 12.8c-3 0-5.4-2.4-5.4-5.4s2.4-5.4 5.4-5.4 5.4 2.4 5.4 5.4-2.4 5.4-5.4 5.4" />
  </svg>
);
export default IconTargetAlt;
