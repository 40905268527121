import React, { useState, useEffect } from "react";
import * as Icons from '../../Icons'



/**
 * Dynamic icon loader component for Bringzz with lazy loading.
 *
 * This component attempts to load an icon from a specified folder (defaulting to 'CustomIcons').
 * If the icon is not found, it prompts the developer to specify the folder option.
 *
 * @component
 * @param {Object} props
 * @param {string} [props.folder='CustomIcons'] - The folder name where the icon resides.
 * @param {string} props.icon - The name of the icon to load.
 * @param {string} [props.size] - The size of the icon.
 * @param {string} [props.className] - Additional CSS classes for the icon container.
 *
 * @returns {React.ReactElement | null} Renders the icon component or null if the icon cannot be loaded.
 */
const BringzzIcon = ({
  folder = "CustomIcons",
  icon,
  size,
  className,
  ...props
}) => {
  const [IconComponent, setIconComponent] = useState(null);

  useEffect(() => {
    const importIcon = async () => {
      try {
        if (Icons[folder][icon]) {
          setIconComponent(() => Icons[folder][icon]);
        }
      } catch (error) {
        console.error(`Error loading icon: ${error}`);
        setIconComponent(null);
      }
    };

    importIcon();
  }, [folder, icon]);

  return (
    <div className={className}>
        {IconComponent ? <IconComponent size={size} {...props} /> : <></>}
    </div>
  );
};

// BringzzIcon.propTypes = {
//   folder: PropTypes.oneOf([
//     "HealthIcons",
//     "LineIcons",
//     "CustomIcons",
//     "CountriesIcons",
//   ]),
//   icon: PropTypes.string,
//   size: PropTypes.string,
//   className: PropTypes.string,
// };

export default BringzzIcon;
