import * as React from "react";
const IconRulerPencilCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.5 1.9h-5.1c-3.6 0-6.6 3-6.6 6.6v40.1c0 .2 0 .4.1.5l7.3 12c.4.6 1 1 1.7 1s1.3-.4 1.7-1l7.3-12c.1-.2.1-.3.1-.5V8.5c0-3.6-2.9-6.6-6.5-6.6m-5.1 2h5.1C56 3.9 58 6 58 8.5v4.2H43.8V8.5c0-2.5 2.1-4.6 4.6-4.6m2.5 56.2-7.1-11.8V14.7H58v33.6zM22.5 2.1H9c-2.8 0-5 2.2-5 5V57c0 2.8 2.2 5 5 5h13.5c2.8 0 5-2.2 5-5V7.1c0-2.8-2.3-5-5-5m3 54.8c0 1.7-1.3 3-3 3H9c-1.7 0-3-1.3-3-3v-5.1h8.6c.6 0 1-.4 1-1s-.4-1-1-1H6V42h3.4c.6 0 1-.4 1-1s-.4-1-1-1H6v-7.8h8.6c.6 0 1-.4 1-1s-.4-1-1-1H6v-7.8h3.4c.6 0 1-.4 1-1s-.4-1-1-1H6v-7.8h8.6c.6 0 1-.4 1-1s-.4-1-1-1H6V7.1c0-1.7 1.3-3 3-3h13.5c1.7 0 3 1.3 3 3z" />
  </svg>
);
export default IconRulerPencilCopy;
