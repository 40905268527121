import * as React from "react";
const IconFlowerAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.7 23c-.2-.2-5.2-5.2-10.7-7.4 2.4-5.4 2.3-12.4 2.3-12.8 0-.5-.4-1-1-1-.5 0-11.5-.1-16.5 4.9-1.7 1.7-3 4.4-3.9 8-.8-3.6-2.1-6.2-3.9-8-4.9-5-16-4.9-16.4-4.9-.5 0-1 .4-1 1 0 .3-.1 7 2.2 12.4-5.5 2-10.4 6.9-10.6 7.2-.4.4-.4 1 0 1.4.2.2 5.2 5.2 10.8 7.4-2.4 5.4-2.3 12.4-2.3 12.8 0 .5.4 1 1 1h.1c1.3 0 11.6-.2 16.3-4.9 1.2-1.2 2.1-2.8 2.9-4.7v25.8c0 .6.4 1 1 1s1-.4 1-1V35.4c.8 2 1.7 3.5 2.9 4.7 4.7 4.8 15 4.9 16.3 4.9h.1c.5 0 1-.4 1-1 0-.3 0-7.1-2.2-12.4 5.5-2 10.4-6.9 10.6-7.2.4-.4.4-1 0-1.4M37.3 8.2c3.5-3.6 11.2-4.2 14-4.3-.1 2.2-.5 7.2-2.3 11.1-.9-.2-1.8-.3-2.6-.4-2.5 0-5.4.9-8.5 2.9-1.1-1.1-2.4-1.8-4-2.2.8-3.1 1.9-5.6 3.4-7.1M32 29.7c-3.5 0-6.3-2.8-6.3-6.3s2.8-6.3 6.3-6.3 6.3 2.8 6.3 6.3-2.8 6.3-6.3 6.3M26.7 8.2c1.5 1.5 2.6 3.9 3.4 7.1-3 .7-5.4 3.1-6.2 6.1-3.1-.7-5.5-1.9-6.9-3.4q-1.05-1.05-1.8-2.7c-2-3.9-2.4-9.3-2.4-11.5 2.7.2 10.4.8 13.9 4.4m-12.9 8.9c.5.9 1.1 1.8 1.8 2.4q2.7 2.7 8.1 3.9c0 1.6.5 3 1.2 4.3-2.8 1.7-5.2 2.5-7.3 2.5-.9 0-1.9-.2-2.9-.5-4.3-1.3-8.6-5-10.3-6.6 1.6-1.4 5.4-4.6 9.4-6m12.9 21.6c-3.5 3.6-11.2 4.2-14 4.3.1-2.1.5-7.2 2.2-11.1.9.2 1.8.3 2.6.4h.1c2.5 0 5.3-1 8.5-2.9 1.1 1 2.4 1.8 3.9 2.2-.7 3.1-1.8 5.6-3.3 7.1m10.6 0c-1.5-1.5-2.6-4-3.4-7.2 3-.7 5.4-3.1 6.2-6 3.1.8 5.5 1.9 6.9 3.4q1.05 1.05 1.8 2.7c1.9 3.9 2.4 9.3 2.4 11.5-2.7-.2-10.4-.8-13.9-4.4m12.9-8.9q-.75-1.35-1.8-2.4c-1.8-1.8-4.5-3.1-8.1-4 0-1.6-.4-3-1.2-4.3 2.7-1.7 5.2-2.5 7.3-2.5.9 0 1.8.2 2.9.5 4.3 1.3 8.6 5 10.3 6.6-1.6 1.5-5.4 4.7-9.4 6.1" />
  </svg>
);
export default IconFlowerAlt;
