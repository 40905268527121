import * as React from "react";
const IconCornerDownRight = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.5 42.5 42.7 25.7c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l15.1 15.1H12.5c-3.5 0-6.3-2.8-6.3-6.3V4c0-.6-.4-1-1-1s-1 .4-1 1v31.9c0 4.6 3.7 8.3 8.3 8.3h43.9L41.3 59.3c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l16.8-16.8c.4-.4.4-1 0-1.4" />
  </svg>
);
export default IconCornerDownRight;
