import * as React from "react";
const IconShieldAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.8 11.8c-5.8-1.7-15.6-6-20-9C33 2.2 32.5 2 32 2s-1 .2-1.8.8c-7.3 5-13.9 8.2-19.6 9.5-2.6.6-4.5 3-4.5 5.7v16.2c0 1.7.2 3.5.6 5.3 1 4.2 3.1 7.9 6.7 11.6 4.6 4.6 10.4 8.2 17.6 11h.3c1.6 0 8-3 8.4-3.2 5.9-3.1 10.3-6.8 13.5-11.1 2.8-3.8 4.3-7.6 4.4-11.7 0-.7.1-12.2.1-18.5.2-2.7-1.5-5-3.9-5.8m2 24.1c-.2 3.6-1.5 7.1-4.1 10.6-3 4.1-7.2 7.6-12.8 10.5-1.7.9-6.2 2.7-7.3 3-6.9-2.6-12.4-6-16.7-10.4-3.3-3.3-5.3-6.8-6.2-10.6-.4-1.6-.6-3.3-.6-4.9V17.9c0-1.8 1.3-3.4 3-3.7 5.9-1.3 12.8-4.6 20.3-9.8.3-.2.5-.3.6-.4.1.1.3.2.7.4 4.5 3.2 14.6 7.5 20.5 9.3 1.6.5 2.7 2 2.7 3.7 0 6.8-.1 17.9-.1 18.5" />
    <path d="M30.2 24.5h8c.6 0 1-.4 1-1s-.4-1-1-1h-3.9v-2.7c0-.6-.4-1-1-1s-1 .4-1 1v2.7h-2.1c-2.9 0-5.3 2.4-5.3 5.3s2.4 5.3 5.3 5.3h3.7c1.8 0 3.3 1.5 3.3 3.3s-1.5 3.3-3.3 3.3h-8c-.6 0-1 .4-1 1s.4 1 1 1h6.4v2.7c0 .6.4 1 1 1s1-.4 1-1v-2.7c2.7-.2 4.9-2.5 4.9-5.2 0-2.9-2.4-5.3-5.3-5.3h-3.7c-1.8 0-3.3-1.5-3.3-3.3 0-1.9 1.4-3.4 3.3-3.4" />
  </svg>
);
export default IconShieldAlt;
