import * as React from "react";
const IconCloset3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 7.6H6c-2.2 0-4 1.8-4 4v40.7c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V11.6c0-2.2-1.8-4-4-4M4 26.9h16.5v13.3H4zM22.5 9.6h18.3v44.7H22.5zM6 9.6h14.5v15.3H4V11.6c0-1.1.9-2 2-2M4 52.4V42.2h16.5v12.1H6c-1.1.1-2-.8-2-1.9m56 0c0 1.1-.9 2-2 2H42.8V9.6H58c1.1 0 2 .9 2 2z" />
    <path d="M36.4 29c0-.6-.4-1-1-1s-1 .5-1 1v2.1c0 .6.4 1 1 1 .5 0 1-.4 1-1zM48.2 28c-.6 0-1 .5-1 1v2.1c0 .6.4 1 1 1s1-.5 1-1V29c0-.6-.5-1-1-1" />
  </svg>
);
export default IconCloset3;
