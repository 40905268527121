import * as React from "react";
const IconDead = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M24 27.4c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-3.5-3.2 3.5-3.2c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-3.6 3.3-3.5-3.5c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l3.5 3.2-3.5 3.4c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l3.6-3.3zM48.5 18.1c-.4-.4-1-.4-1.4 0l-3.6 3.3L40 18c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l3.5 3.2-3.5 3.4c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l3.6-3.3 3.6 3.3c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4L45 22.7l3.5-3.2c.3-.4.4-1 0-1.4" />
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
    <path d="M32 41.9c-4.6 0-9 1.7-12.3 4.8-.4.4-.4 1-.1 1.4.4.4 1 .4 1.4 0 3-2.8 6.9-4.3 10.9-4.3 4.1 0 8 1.5 10.9 4.3.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4-3.2-3.1-7.6-4.8-12.2-4.8" />
  </svg>
);
export default IconDead;
