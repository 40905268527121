import * as React from "react";
const IconBrainCheck = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M37 21.7c.1-1.3-.4-2.5-1.2-3.5-.7-.9-1.7-1.4-2.9-1.6-.7-1.4-2-2.4-3.5-2.6-1.3-.2-2.6.2-3.6 1-1-.9-2.3-1.2-3.6-1-1.5.2-2.8 1.2-3.5 2.6-1.1.2-2.1.7-2.9 1.6-.8 1-1.2 2.2-1.2 3.5-1.2.9-1.9 2.4-1.9 4 0 1.9 1 3.5 2.5 4.4-.1 1.1.2 2.2.8 3.1s1.4 1.6 2.4 1.9c.5 1.7 1.8 3 3.5 3.4 1.4.3 2.9 0 4-1 .8.7 1.8 1.1 2.9 1.1.3 0 .7 0 1-.1 1.7-.4 3-1.7 3.5-3.4 1-.3 1.8-1 2.4-1.9s.9-2 .8-3.1c1.6-.9 2.6-2.6 2.5-4.6-.2-1.5-.9-2.9-2-3.8M22.3 36.5c-.9-.2-1.9-.9-2.1-2.4-.1-.4-.4-.7-.8-.8-.7-.1-1.3-.6-1.7-1.2-.5-.7-.6-1.6-.4-2.4.1-.5-.2-1-.7-1.2-1.1-.4-1.9-1.5-1.9-2.8 0-1.1.6-2.1 1.4-2.6.4-.2.6-.6.5-1-.2-.9.1-1.8.7-2.5.5-.6 1.2-.9 2-.9.4 0 .8-.3.9-.7.3-1.1 1.2-1.8 2.2-1.9.9-.1 1.7.2 2.3.9v18.7c-.5.7-1.5 1-2.4.8m12.7-8c-.5.2-.8.7-.7 1.2.2.8 0 1.7-.4 2.4-.4.6-1 1.1-1.7 1.2-.4.1-.7.4-.8.8-.2 1.4-1.2 2.2-2.1 2.4s-1.9-.1-2.5-.9V16.9c.6-.7 1.4-1.1 2.3-.9 1 .1 1.8.9 2.2 1.9.1.4.6.7 1 .7.8 0 1.5.3 2 .9.6.7.8 1.6.7 2.5-.1.4.1.8.5 1 .8.5 1.4 1.4 1.4 2.5 0 1.4-.8 2.6-1.9 3" />
    <path d="M61.7 60.3 43.9 42.4c8.7-9.5 8.5-24.2-.7-33.4C33.8-.4 18.5-.4 9.1 9s-9.4 24.7 0 34.1c4.7 4.7 10.9 7.1 17.1 7.1 5.9 0 11.7-2.1 16.3-6.4l17.8 17.8c.2.2.5.3.7.3s.5-.1.7-.3c.4-.3.4-.9 0-1.3M10.5 41.8c-8.6-8.6-8.6-22.7 0-31.3C14.8 6.2 20.5 4 26.1 4s11.3 2.2 15.6 6.5c8.6 8.6 8.6 22.7 0 31.3s-22.6 8.6-31.2 0" />
  </svg>
);
export default IconBrainCheck;
