import * as React from "react";
const IconMaleAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.8 2H38.3c-.6 0-1 .4-1 1s.4 1 1 1h19.5c.3 0 .5.1.8.2 0 0-.1 0-.1.1L38.2 23.8c-3.8-3.3-8.8-5.3-14.2-5.3-12 0-21.8 9.8-21.8 21.8S12 62 24 62s21.8-9.8 21.8-21.8c0-5.8-2.3-11.2-6.1-15.1L59.8 5.8v19.7c0 .6.4 1 1 1s1-.4 1-1V5.9c0-2.1-1.8-3.9-4-3.9M24 60C13.1 60 4.2 51.1 4.2 40.2S13.1 20.4 24 20.4s19.8 8.9 19.8 19.8C43.7 51.1 34.9 60 24 60" />
  </svg>
);
export default IconMaleAlt;
