import * as React from "react";
const IconMoneyStash = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.1 13.8h-17v-1.5c0-2.2-1.8-4-4-4h-8.2c-2.2 0-4 1.8-4 4v1.5h-17C4.2 13.8 2 16 2 18.6v26.7c0 2.7 2.2 4.9 4.9 4.9h17v1.5c0 2.2 1.8 4 4 4h8.2c2.2 0 4-1.8 4-4v-1.5h17c2.7 0 4.9-2.2 4.9-4.9V18.6c0-2.6-2.2-4.8-4.9-4.8m2.9 4.8v4.6c-.7.3-1.4.5-2.2.5-2.9 0-5.3-2.4-5.3-5.3 0-1 .3-1.9.8-2.7h3.9c1.5.1 2.8 1.4 2.8 2.9M6.9 15.8h3.9c.5.8.8 1.7.8 2.7 0 2.9-2.4 5.3-5.3 5.3-.8 0-1.5-.2-2.2-.5v-4.6c-.1-1.6 1.2-2.9 2.8-2.9M4 45.4v-4.6c.7-.3 1.4-.5 2.2-.5 2.9 0 5.3 2.4 5.3 5.3 0 1-.3 1.9-.8 2.7H6.9C5.3 48.2 4 46.9 4 45.4m9 2.8c.3-.9.5-1.8.5-2.7 0-4-3.3-7.3-7.3-7.3-.8 0-1.5.1-2.2.4V25.4c.7.2 1.5.4 2.2.4 4 0 7.3-3.3 7.3-7.3 0-.9-.2-1.8-.5-2.7h10.9v32.4zm25.1 3.5c0 1.1-.9 2-2 2h-8.2c-1.1 0-2-.9-2-2V12.3c0-1.1.9-2 2-2h8.2c1.1 0 2 .9 2 2zm2-35.9H51c-.3.9-.5 1.8-.5 2.7 0 4 3.3 7.3 7.3 7.3.8 0 1.5-.1 2.2-.4v13.2c-.7-.2-1.5-.4-2.2-.4-4 0-7.3 3.3-7.3 7.3 0 .9.2 1.8.5 2.7H40.1zm17 32.4h-3.9c-.5-.8-.8-1.7-.8-2.7 0-2.9 2.4-5.3 5.3-5.3.8 0 1.5.2 2.2.5v4.6c.1 1.6-1.2 2.9-2.8 2.9" />
  </svg>
);
export default IconMoneyStash;
