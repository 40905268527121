import * as React from "react";
const IconSwapArrow = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.7 48 47 36.4c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l9.9 9.9H21.4c-.6 0-1 .4-1 1s.4 1 1 1h34.2L46 59.3c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l11.2-11.2c.5-.4.5-1.1.1-1.5M8.5 16.2h34.2c.6 0 1-.4 1-1s-.4-1-1-1H8.5L18 4.7c.4-.4.4-1 0-1.4s-1-.4-1.4 0L5.3 14.5c-.4.4-.4 1 0 1.4L17 27.6c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4z" />
  </svg>
);
export default IconSwapArrow;
