import * as React from "react";
const IconIslamicBook = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M47.7 2H16.3c-3 0-5.4 2.4-5.4 5.4v49.2c0 3 2.4 5.4 5.4 5.4h31.3c3 0 5.4-2.4 5.4-5.4V7.4c.1-3-2.3-5.4-5.3-5.4m3.4 54.6c0 1.9-1.5 3.4-3.4 3.4H16.3c-1.9 0-3.4-1.5-3.4-3.4V7.4c0-1.9 1.5-3.4 3.4-3.4h31.3C49.5 4 51 5.5 51 7.4v49.2z" />
    <path d="M32.8 38c-6 0-10.9-4.9-10.9-10.9s4.9-10.9 10.9-10.9c.6 0 1-.4 1-1s-.4-1-1-1c-7.1 0-12.9 5.8-12.9 12.9S25.7 40 32.8 40c.6 0 1-.4 1-1s-.5-1-1-1" />
    <path d="m42.1 22.9-1.3-.2c-.2 0-.3-.1-.4-.3l-.6-1.2c-.4-.8-1.3-1.4-2.2-1.4s-1.8.5-2.2 1.4l-.6 1.2c-.1.1-.2.2-.4.3l-1.3.2c-.9.1-1.7.8-2 1.7s-.1 1.9.6 2.5l.9.9c.1.1.2.3.1.4l-.2 1.3c-.2.9.2 1.9 1 2.4.4.3.9.5 1.4.5q.6 0 1.2-.3l1.1-.6c.1-.1.3-.1.4 0l1.2.6c.8.4 1.8.4 2.6-.2s1.1-1.5 1-2.4l-.2-1.3c0-.2 0-.3.1-.4l.9-.9c.7-.7.9-1.6.6-2.5 0-.9-.8-1.6-1.7-1.7m0 2.8-.9.9c-.6.6-.8 1.4-.7 2.2l.2 1.3c0 .3-.1.4-.2.5s-.3.2-.5 0l-1.2-.6c-.7-.4-1.6-.4-2.3 0l-1.1.6c-.2.1-.4 0-.5 0-.1-.1-.2-.2-.2-.5l.2-1.3c.1-.8-.1-1.6-.7-2.2l-.9-.9c-.2-.2-.1-.4-.1-.5s.1-.3.4-.3l1.3-.2c.8-.1 1.5-.6 1.9-1.4l.6-1.2c.1-.2.3-.3.4-.3s.3 0 .4.3l.6 1.2c.4.7 1.1 1.2 1.9 1.3l1.3.2c.3 0 .4.2.4.3s-.1.4-.3.6" />
  </svg>
);
export default IconIslamicBook;
