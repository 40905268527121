import * as React from "react";
const IconSunriseAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 25.6c.6 0 1-.4 1-1V13.8c0-.6-.4-1-1-1s-1 .4-1 1v10.9c0 .5.4.9 1 .9M50.1 33.1c.3 0 .5-.1.7-.3l3.7-3.7c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-3.7 3.7c-.4.4-.4 1 0 1.4.2.2.4.3.7.3M61 49.2H47.7C47.2 41 40.3 34.5 32 34.5S16.8 41 16.3 49.2H3c-.6 0-1 .4-1 1s.4 1 1 1h58c.6 0 1-.4 1-1s-.4-1-1-1M32 36.5c7.2 0 13.2 5.6 13.7 12.7H18.3c.5-7.1 6.5-12.7 13.7-12.7M13.2 32.8c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4L11 27.8c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4z" />
  </svg>
);
export default IconSunriseAlt1;
