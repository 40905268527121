import * as React from "react";
const IconOnlineShopping = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 3.7H6c-2.2 0-4 1.8-4 4v34.1c0 2.2 1.8 4 4 4h25v12.5h-9.7c-.6 0-1 .4-1 1s.4 1 1 1h21.5c.6 0 1-.4 1-1s-.4-1-1-1H33V45.8h25c2.2 0 4-1.8 4-4V7.7c0-2.2-1.8-4-4-4m2 38.1c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V7.7c0-1.1.9-2 2-2h52c1.1 0 2 .9 2 2z" />
    <path d="M43.6 11.7h-3.2c-.5 0-.9.3-1 .8l-1.2 5H20.4c-.3 0-.6.1-.8.4-.2.2-.3.6-.2.8l2 8.6c.1.5.5.8 1 .8h14.5c.5 0 .9-.3 1-.8l3.3-13.5h2.4c.6 0 1-.4 1-1s-.5-1.1-1-1.1M36.1 26H23.2l-1.5-6.6h16zM24.7 31c-1.6 0-2.9 1.3-2.9 2.9s1.3 2.9 2.9 2.9 2.9-1.3 2.9-2.9-1.3-2.9-2.9-2.9M34.6 31c-1.6 0-2.9 1.3-2.9 2.9s1.3 2.9 2.9 2.9 2.9-1.3 2.9-2.9-1.3-2.9-2.9-2.9" />
  </svg>
);
export default IconOnlineShopping;
