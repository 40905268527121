import * as React from "react";
const IconWifiAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.4 4c0-.6-.4-1-1-1C27.5 3 3.2 27.4 3.2 57.3c0 .6.4 1 1 1s1-.4 1-1C5.2 28.5 28.6 5 57.4 5c.6 0 1-.4 1-1M58.4 26.5c0-.6-.4-1-1-1-17.5 0-31.8 14.3-31.8 31.8 0 .6.4 1 1 1s1-.4 1-1c0-16.4 13.4-29.8 29.8-29.8.6 0 1-.5 1-1M53.6 46.6c-4 0-7.2 3.2-7.2 7.2s3.2 7.2 7.2 7.2 7.2-3.2 7.2-7.2-3.2-7.2-7.2-7.2m0 12.4c-2.9 0-5.2-2.3-5.2-5.2s2.3-5.2 5.2-5.2 5.2 2.3 5.2 5.2c0 2.8-2.3 5.2-5.2 5.2" />
  </svg>
);
export default IconWifiAlt2;
