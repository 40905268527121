import * as React from "react";
const IconInhaler = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.2 41.4h-3.6c-.6-2.1-2.5-3.7-4.8-3.7H31.9L23.1 7.1c-.2-.6-.7-1.2-1.3-1.3-.6-.2-1.3-.1-1.9.4l-1.2 1-.4-1.5C17.7 3.5 15.7 2 13.5 2h-.1l-5.7.1c-1.6 0-3 .8-4 2.1C2.8 5.4 2.5 7 3 8.6L5.9 18l-1.2 1c-.6.5-.9 1.3-.6 2.1l11.5 37.5c.7 2.1 2.6 3.5 4.8 3.5h27.4c2.1 0 3.9-1.3 4.6-3.1h3.8c2.8 0 5-2.2 5-5v-7.4c0-2.9-2.2-5.2-5-5.2m-48.4-15h18.7l2.6 9.1H10.6zM4.9 8c-.3-.9-.1-1.9.5-2.7s1.4-1.2 2.4-1.2l5.7-.1c1.4 0 2.6.9 2.9 2.2l.7 2.3-9.5 8zm16.3-.4L26 24.4H7.2l-1.2-4zM20.4 60c-1.3 0-2.5-.9-2.9-2.1l-6.3-20.4h18.5L36.1 60zm30.4-3c0 1.7-1.4 3-3 3h-9.5l-5.8-20.2h15.3c1.7 0 3 1.4 3 3zm8.4-3.2c0 1.7-1.4 3-3 3h-3.4V43.4h3.4c1.7 0 3 1.4 3 3z" />
  </svg>
);
export default IconInhaler;
