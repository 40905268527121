import * as React from "react";
const IconChatAlt6 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M4.6 62.2c-.6 0-1.2-.2-1.7-.7-.8-.7-1.1-1.8-.7-2.8l4.5-11.6C3.2 41.5 1.8 35 2.5 28.3 3.9 14.5 15 3.4 28.9 1.9c9-.9 17.9 2.2 24.3 8.5s9.5 15.2 8.6 24.2C60.4 48.8 49.1 60 35 61.4c-6.8.6-13.3-.9-19-4.6L5.7 62c-.4.2-.8.2-1.1.2M32.1 3.8c-1 0-2 .1-3 .2-13 1.3-23.4 11.7-24.7 24.6-.7 6.4.8 12.6 4.2 17.9.2.3.2.6.1.9L4 59.5c-.1.3.1.5.1.6.1.1.3.2.6.1l10.8-5.4c.3-.2.7-.1 1 .1 5.4 3.6 11.7 5.2 18.2 4.5 13.2-1.2 23.7-11.7 25-24.9.8-8.4-2.1-16.7-8.1-22.6-5.1-5.3-12.1-8.1-19.5-8.1" />
  </svg>
);
export default IconChatAlt6;
