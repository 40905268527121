import * as React from "react";
const IconLinkAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2c-4.2 0-7.5 3.4-7.5 7.5v12.3c0 .6.4 1 1 1s1-.4 1-1V9.5c0-3 2.4-5.5 5.5-5.5s5.5 2.5 5.5 5.5v12.3c0 .6.4 1 1 1s1-.4 1-1V9.5C39.5 5.4 36.2 2 32 2M33 36.5v-9c0-.6-.4-1-1-1s-1 .4-1 1v9c0 .6.4 1 1 1s1-.4 1-1M38.5 41.2c-.6 0-1 .4-1 1v12.3c0 3.1-2.5 5.5-5.5 5.5s-5.5-2.5-5.5-5.5V42.2c0-.6-.4-1-1-1s-1 .4-1 1v12.3c0 4.2 3.4 7.5 7.5 7.5 4.2 0 7.5-3.4 7.5-7.5V42.2c0-.6-.4-1-1-1" />
  </svg>
);
export default IconLinkAlt1;
