import * as React from "react";
const IconArrowMinimize = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 27.4c-.3 0-.5-.1-.7-.3L20.2 15.7c-.4-.4-.4-1 0-1.4s1-.4 1.4 0L31 24V4.7c0-.6.4-1 1-1s1 .4 1 1v19.2l9.4-9.7c.4-.4 1-.4 1.4 0s.4 1 0 1.4L32.7 27.1c-.2.2-.4.3-.7.3M32 60.3c-.6 0-1-.4-1-1V40.1l-9.4 9.7c-.4.4-1 .4-1.4 0s-.4-1 0-1.4L31.3 37c.4-.4 1.1-.4 1.4 0l11.1 11.4c.4.4.4 1 0 1.4s-1 .4-1.4 0L33 40.1v19.2c0 .5-.4 1-1 1" />
  </svg>
);
export default IconArrowMinimize;
