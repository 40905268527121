import * as React from "react";
const IconPersonalData = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.7 13.7H32.4l-2.8-8c-.7-2.1-2.7-3.6-5-3.6H7.3C4.4 2.1 2 4.5 2 7.4v49.2c0 2.9 2.4 5.3 5.3 5.3h49.4c2.9 0 5.3-2.4 5.3-5.3V18.9c0-2.9-2.4-5.2-5.3-5.2M60 56.6c0 1.8-1.5 3.3-3.3 3.3H7.3c-1.8 0-3.3-1.5-3.3-3.3V7.4c0-1.8 1.5-3.3 3.3-3.3h17.3c1.4 0 2.6.9 3.1 2.2l3 8.7c.1.4.5.7.9.7h25.1c1.8 0 3.3 1.5 3.3 3.3z" />
    <path d="M35 34.4c1.7-1 2.8-2.7 2.8-4.8 0-3.1-2.5-5.5-5.5-5.5-3.1 0-5.5 2.5-5.5 5.5 0 2.1 1.1 3.8 2.8 4.8-1.7.5-3.2 1.6-4.4 3-1.8 2.2-2.9 5.2-2.9 8.4q0 .6.6.9c1.1.6 5.2 2.4 9.5 2.4 4.7 0 8.5-1.9 9.5-2.4.3-.2.5-.5.5-.9-.1-3.3-1.1-6.3-2.9-8.4q-1.95-2.1-4.5-3m-2.7-8.3c1.9 0 3.5 1.6 3.5 3.5s-1.6 3.5-3.5 3.5-3.5-1.6-3.5-3.5 1.5-3.5 3.5-3.5m0 21.1c-3.4 0-6.7-1.3-8-1.9.2-2.6 1-4.9 2.4-6.5 1.5-1.8 3.5-2.7 5.6-2.7s4.1 1 5.6 2.7c1.4 1.7 2.3 4 2.4 6.6-1.3.5-4.4 1.8-8 1.8" />
  </svg>
);
export default IconPersonalData;
