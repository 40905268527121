import * as React from "react";
const IconDots = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M45.1 17.5c0-7.2-5.9-13.1-13.1-13.1s-13.1 5.9-13.1 13.1S24.8 30.6 32 30.6s13.1-5.9 13.1-13.1M32 28.6c-6.1 0-11.1-5-11.1-11.1S25.9 6.4 32 6.4s11.1 5 11.1 11.1-5 11.1-11.1 11.1M48.9 33.4c-7.2 0-13.1 5.9-13.1 13.1s5.9 13.1 13.1 13.1S62 53.7 62 46.5s-5.9-13.1-13.1-13.1m0 24.2c-6.1 0-11.1-5-11.1-11.1s5-11.1 11.1-11.1S60 40.4 60 46.5s-5 11.1-11.1 11.1M15.1 33.4C7.9 33.4 2 39.3 2 46.5s5.9 13.1 13.1 13.1 13.1-5.9 13.1-13.1-5.9-13.1-13.1-13.1m0 24.2C9 57.6 4 52.6 4 46.5s5-11.1 11.1-11.1 11.1 5 11.1 11.1-5 11.1-11.1 11.1" />
  </svg>
);
export default IconDots;
