import * as React from "react";
const IconTrendingDown = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.5 22.9c-.6 0-1 .4-1 1v13.4L42.4 26.6c-1.7-1.1-3.9-1.1-5.5 0l-13 8.7c-1 .7-2.3.7-3.3 0L5.1 25c-.5-.3-1.1-.2-1.4.3s-.2 1.1.3 1.4L19.3 37c1.7 1.1 3.9 1.1 5.5 0l13-8.7c1-.7 2.3-.7 3.3 0l16.2 10.8H43.4c-.6 0-1 .4-1 1s.4 1 1 1h16.1c.6 0 1-.4 1-1V23.9c0-.5-.4-1-1-1" />
  </svg>
);
export default IconTrendingDown;
