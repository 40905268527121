import * as React from "react";
const IconPointerAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.8 60c-1.1 0-2.2-.2-3.3-.7L20.8 47.5c-2-.8-3.5-2.4-4.4-4.4L4.7 15.5C3.3 12.3 4 8.9 6.4 6.4s5.9-3.1 9.1-1.8l27.7 11.8c2 .8 3.5 2.4 4.4 4.4l11.8 27.7c1.3 3.2.7 6.6-1.8 9.1-1.6 1.6-3.7 2.4-5.8 2.4M20.4 45c.4.3.8.5 1.2.7l27.7 11.8c2.9 1.2 5.4.1 6.9-1.3 1.4-1.4 2.5-4 1.3-6.9L45.7 21.6c-.2-.4-.4-.8-.7-1.2zM12.2 6c-1.8 0-3.4.8-4.4 1.9-1.4 1.4-2.5 4-1.3 6.9l11.8 27.7c.2.4.4.8.7 1.2L43.6 19c-.4-.3-.8-.5-1.2-.7L14.7 6.5c-.8-.3-1.7-.5-2.5-.5" />
  </svg>
);
export default IconPointerAlt;
