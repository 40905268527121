import * as React from "react";
const IconPatent = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M19.2 11.3h8.1c.6 0 1-.4 1-1s-.4-1-1-1h-8.1c-.6 0-1 .4-1 1s.5 1 1 1M16.9 19.3h12.8c.6 0 1-.4 1-1s-.4-1-1-1H16.9c-.6 0-1 .4-1 1s.4 1 1 1M29.7 25.4H16.9c-.6 0-1 .4-1 1s.4 1 1 1h12.8c.6 0 1-.4 1-1s-.4-1-1-1" />
    <path d="m55.7 56-2.6-10.4c0-.1-.1-.1-.1-.2 1.5-1.9 2.3-4.3 2.3-6.9 0-6.4-5.2-11.6-11.6-11.6-1.9 0-3.7.5-5.3 1.3V6.7c0-2.6-2.1-4.7-4.7-4.7H12.9c-2.6 0-4.7 2.1-4.7 4.7v28.4c0 2.6 2.1 4.7 4.7 4.7h19.3c.2 2.1 1 4 2.2 5.6 0 .1-.1.1-.1.2L31.8 56c-.6 2.5.9 5.1 3.4 5.7l.7.2c.4.1.8.1 1.1.1 2.1 0 4-1.4 4.6-3.6l2.1-8.4 2.1 8.4c.5 2.1 2.5 3.6 4.6 3.6.4 0 .8 0 1.1-.1l.7-.2c1.2-.3 2.3-1.1 2.9-2.1.7-1.1.9-2.4.6-3.6m-2.4-17.6c0 5.3-4.3 9.6-9.6 9.6s-9.6-4.3-9.6-9.6 4.3-9.6 9.6-9.6 9.6 4.3 9.6 9.6m-43.1-3.3V6.7c0-1.5 1.2-2.7 2.7-2.7h20.8c1.5 0 2.7 1.2 2.7 2.7v22.8c-2.4 2-4 5-4.2 8.3H12.9c-1.5 0-2.7-1.2-2.7-2.7m29.5 22.8c-.4 1.5-1.8 2.3-3.3 2l-.7-.2c-1.5-.4-2.3-1.8-2-3.3L36 47c1.6 1.4 3.5 2.4 5.6 2.7zm13.7.6c-.4.6-1 1.1-1.7 1.2l-.7.2c-1.5.4-2.9-.5-3.3-2l-2-8.1c2.1-.4 4.1-1.4 5.6-2.7l2.3 9.4c.3.7.2 1.4-.2 2" />
    <path d="M42.5 42.4c.2.2.4.3.7.3s.5-.1.7-.3l4.9-5.4c.4-.4.3-1-.1-1.4s-1-.3-1.4.1l-4.2 4.7-2.7-2.6c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4z" />
  </svg>
);
export default IconPatent;
