import * as React from "react";
const IconPriceTag1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M30.9 39.5h5.3c.6 0 1-.4 1-1s-.4-1-1-1H34v-1.4c0-.6-.4-1-1-1s-1 .4-1 1v1.4h-1c-2.1 0-3.8 1.7-3.8 3.8s1.7 3.8 3.8 3.8h2.4c1 0 1.8.8 1.8 1.8s-.8 1.8-1.8 1.8h-5.3c-.6 0-1 .4-1 1s.4 1 1 1H32v1.4c0 .6.4 1 1 1s1-.4 1-1v-1.5c1.8-.3 3.2-1.9 3.2-3.8 0-2.1-1.7-3.8-3.8-3.8H31c-1 0-1.8-.8-1.8-1.8-.1-.9.7-1.7 1.7-1.7" />
    <path d="m45.3 26.5-10.5-8.2c-1.7-1.4-4.1-1.3-5.8 0l-.8.6c-.7-1.6-1.1-3.6-1.1-5.7 0-5 2.3-9.2 5.1-9.2 1.9 0 3.8 2.2 4.6 5.5.1.5.7.9 1.2.7.5-.1.9-.7.7-1.2-1.1-4.3-3.7-7-6.6-7-4 0-7.1 4.9-7.1 11.2 0 2.7.6 5.1 1.5 7l-7.9 6.3c-1.1.9-1.8 2.2-1.8 3.7V56c0 3.3 2.7 6 6 6h18.4c3.3 0 6-2.7 6-6V30.2c-.1-1.5-.7-2.8-1.9-3.7M45.1 56c0 2.2-1.8 4-4 4H22.8c-2.2 0-4-1.8-4-4V30.2c0-.8.4-1.6 1-2.1l7.7-6.1c1.2 1.6 2.8 2.6 4.5 2.6.6 0 1-.4 1-1s-.4-1-1-1c-1.1 0-2.1-.7-3-1.8l1.1-.8c.5-.4 1.1-.6 1.7-.6s1.2.2 1.7.6L44 28.2c.7.5 1 1.3 1 2.1V56z" />
  </svg>
);
export default IconPriceTag1;
