import * as React from "react";
const IconRulerAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M13 47.6c-1.9 0-3.5 1.6-3.5 3.5s1.6 3.5 3.5 3.5 3.5-1.6 3.5-3.5c-.1-2-1.6-3.5-3.5-3.5m0 4.9c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5-.7 1.5-1.5 1.5" />
    <path d="M58 42.4H21.6V6c0-2.2-1.8-4-4-4H6C3.8 2 2 3.8 2 6v52c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V46.4c0-2.2-1.8-4-4-4M6 4h11.6c1.1 0 2 .9 2 2v5.7h-5.2c-.6 0-1 .4-1 1s.4 1 1 1h5.2v5.4h-4.4c-.6 0-1 .4-1 1s.4 1 1 1h4.4v5.2h-5.2c-.6 0-1 .4-1 1s.4 1 1 1h5.2v5.2h-4.4c-.6 0-1 .4-1 1s.4 1 1 1h4.4v6.9H4V6c0-1.1.9-2 2-2m0 56c-1.1 0-2-.9-2-2V44.4h15.6V60zm54-2c0 1.1-.9 2-2 2h-5.7v-5.2c0-.6-.4-1-1-1s-1 .4-1 1V60h-5.4v-4.4c0-.6-.4-1-1-1s-1 .4-1 1V60h-5.2v-5.2c0-.6-.4-1-1-1s-1 .4-1 1V60h-5.2v-4.4c0-.6-.4-1-1-1s-1 .4-1 1V60h-6.9V44.4H58c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconRulerAlt1;
