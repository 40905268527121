import * as React from "react";
const IconCloudMoon = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.5 19.2c-.4-.4-1.1-.5-1.6-.2-4.7 2.2-10.3.7-13.1-3.6-1.9-2.9-2.1-6.4-.7-9.5.2-.5.2-1.1-.2-1.5s-1-.6-1.5-.4c-2.5.6-4.8 2-6.5 4-1.6 1.8-2.6 4-3.1 6.3-.2.9-.3 1.9-.3 2.8 0 .5.5 1 1 1 .6 0 1-.5 1-1 0-.8.1-1.6.2-2.4.4-2 1.3-3.9 2.6-5.4 1.3-1.4 2.8-2.5 4.5-3.2-1.2 3.4-.7 7.2 1.3 10.3 3.1 4.8 9.1 6.7 14.5 4.8-.9 2.3-2.5 4.2-4.7 5.5q-1.65 1.05-3.6 1.5c-.5.1-.9.7-.7 1.2.1.5.5.8 1 .8h.2c1.5-.4 2.9-.9 4.2-1.8 2.9-1.8 5-4.6 5.9-7.9.2-.4 0-1-.4-1.3M43 35c-.4 0-.7-.3-.9-.6-2.1-4.5-6.7-7.5-11.8-7.5-5.2 0-9.9 3.1-11.9 7.8-.1.4-.6.6-.9.5-2.7-.6-5.4-.2-8 .9-3.9 1.7-6.7 5.4-7.3 9.5-.6 3.7.4 7.3 2.8 10.1 2.5 2.9 6.1 4.5 9.9 4.5h26.2c6.8 0 12.7-5 13.3-11.4.7-6.9-4.3-13-11.4-13.8m9.4 13.7c-.5 5.4-5.5 9.6-11.3 9.6H14.9c-3.2 0-6.3-1.4-8.4-3.8-2-2.4-2.9-5.4-2.4-8.5.5-3.5 2.9-6.5 6.2-8 1.5-.7 3-1 4.6-1 .7 0 1.5.1 2.2.2 1.3.3 2.7-.4 3.2-1.7 1.7-4 5.6-6.6 10.1-6.6 4.3 0 8.2 2.5 10 6.3.5 1 1.4 1.7 2.5 1.8 5.9.7 10.1 5.8 9.5 11.7" />
  </svg>
);
export default IconCloudMoon;
