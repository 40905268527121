import * as React from "react";
const IconPageBreakCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="prefix__lni_lni-page-break"
    x={0}
    y={0}
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <style>
      {
        ".prefix__st0{fill:none;stroke:#000;stroke-width:2;stroke-miterlimit:10}"
      }
    </style>
    <path
      d="M47.5 24.1h-31c-1.7 0-3-1.3-3-3V6c0-1.7 1.3-3 3-3h30.9c1.7 0 3 1.3 3 3v15.1c.1 1.6-1.3 3-2.9 3zM47.5 61h-31c-1.7 0-3-1.3-3-3V42.9c0-1.7 1.3-3 3-3h30.9c1.7 0 3 1.3 3 3V58c.1 1.7-1.3 3-2.9 3z"
      className="prefix__st0"
    />
    <path d="M21.4 31h-5.3c-.6 0-1 .4-1 1s.4 1 1 1h5.3c.6 0 1-.4 1-1s-.4-1-1-1M34.6 31h-5.3c-.6 0-1 .4-1 1s.4 1 1 1h5.3c.6 0 1-.4 1-1s-.4-1-1-1M47.8 31h-5.3c-.6 0-1 .4-1 1s.4 1 1 1h5.3c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconPageBreakCopy;
