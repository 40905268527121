import * as React from "react";
const IconSpeechBubble19 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.3 15.5h-10V11c0-3.1-2.6-5.7-5.7-5.7h-33C4.6 5.3 2 7.9 2 11v21.2c0 3.1 2.6 5.7 5.7 5.7h1.6c-.7 2.6-2 7.2-3.3 9.1-.2.4-.2.9.1 1.2.2.2.5.4.8.4.1 0 .3 0 .4-.1 3.6-1.6 10.5-7 13.2-10.6H24v4.5c0 3.1 2.6 5.7 5.7 5.7h13.9c2.8 3.8 9.9 9.1 13.2 10.6.1.1.3.1.4.1.3 0 .6-.1.8-.4.3-.4.3-.9 0-1.2-1.4-1.7-2.1-5.4-2.6-8.2-.1-.4-.1-.7-.2-1h1.2c3.1 0 5.7-2.6 5.7-5.7v-21c-.1-3.3-2.7-5.8-5.8-5.8M19.9 35.9c-.3 0-.7.2-.8.5-1.7 2.6-6.3 6.5-9.9 8.8.9-2.4 1.8-5.4 2.5-8 .1-.3 0-.6-.2-.9-.2-.2-.5-.4-.8-.4h-3c-2 0-3.7-1.7-3.7-3.7V11c0-2 1.7-3.7 3.7-3.7h33c2 0 3.7 1.7 3.7 3.7v21.2c0 2-1.7 3.7-3.7 3.7zM60 42.2c0 2-1.7 3.7-3.7 3.7h-2.5c-.3 0-.6.1-.8.4s-.3.6-.2.9c.1.6.3 1.3.4 2.2.3 1.8.7 4 1.4 5.9-3.6-2.3-8.1-6.2-9.8-8.7-.2-.3-.5-.5-.8-.5H29.6c-2 0-3.7-1.7-3.7-3.7v-4.5h14.7c3.1 0 5.7-2.6 5.7-5.7V17.5h10c2 0 3.7 1.7 3.7 3.7z" />
    <path d="M12.7 19h-.1c-1.1 0-1.9.9-1.9 2s.9 2 2 2 2-.9 2-2-.9-2-2-2M23.8 19h-.1c-1.1 0-1.9.9-1.9 2s.9 2 2 2 2-.9 2-2-.9-2-2-2M34.8 19h-.1c-1.1 0-1.9.9-1.9 2s.9 2 2 2 2-.9 2-2-.9-2-2-2" />
  </svg>
);
export default IconSpeechBubble19;
