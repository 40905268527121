import * as React from "react";
const IconUserAlt4 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 26.5c-6.7 0-12.1-5.3-12.1-11.7S25.3 3 32 3s12.1 5.3 12.1 11.7S38.7 26.5 32 26.5M32 5c-5.6 0-10.1 4.4-10.1 9.7 0 5.4 4.5 9.7 10.1 9.7s10.1-4.4 10.1-9.7S37.6 5 32 5M55.7 61c-.6 0-1-.4-1-1v-8.3c0-8.9-7.3-16.2-16.2-16.2h-13c-8.9 0-16.2 7.3-16.2 16.2V60c0 .6-.4 1-1 1s-1-.4-1-1v-8.3c0-10 8.2-18.2 18.2-18.2h12.9c10 0 18.2 8.2 18.2 18.2V60c.1.6-.4 1-.9 1" />
  </svg>
);
export default IconUserAlt4;
