import * as React from "react";
const IconBabyKid = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M37.5 38.2c-.6 0-1 .4-1 1 0 2.5-2 4.5-4.5 4.5s-4.5-2-4.5-4.5c0-.6-.4-1-1-1s-1 .4-1 1c0 3.6 2.9 6.5 6.5 6.5s6.5-2.9 6.5-6.5c0-.5-.4-1-1-1" />
    <path d="M53.5 22.1c-.5 0-1.1.1-1.6.2C48.2 14.7 40.5 9.9 32 9.9s-16.2 4.8-19.9 12.4c-.5-.1-1.1-.2-1.6-.2-4.7 0-8.5 3.8-8.5 8.6s3.8 8.6 8.5 8.6h.6c3 8.8 11.5 14.9 20.9 14.9s17.9-6.1 20.9-14.9h.6c4.7 0 8.5-3.8 8.5-8.6s-3.8-8.6-8.5-8.6m0 15.1c-.3 0-.7 0-1.1-.1h-.3c-.1 0-.1 0-.2.1 0 0-.1 0-.1.1-.1 0-.1.1-.2.1l-.1.1c0 .1-.1.1-.1.2 0 0 0 .1-.1.1-2.6 8.4-10.5 14.3-19.3 14.3s-16.7-5.9-19.3-14.3c0 0 0-.1-.1-.1 0-.1-.1-.1-.1-.2l-.1-.1c-.1-.1-.1-.1-.2-.1 0 0-.1 0-.1-.1-.1 0-.1-.1-.2-.1h-.4c-.4.1-.7.1-1.1.1-3.6 0-6.5-2.9-6.5-6.6S6.8 24 10.4 24c.6 0 1.3.1 1.9.3h.5c.1 0 .1 0 .2-.1.1 0 .1-.1.2-.1s.1-.1.1-.1.1-.1.1-.2l.1-.1c3-6.7 9.4-11.2 16.6-11.9v9.6c0 2.7 2.2 4.9 4.9 4.9s4.9-2.2 4.9-4.9v-.7c0-.6-.4-1-1-1s-1 .4-1 1v.7c0 1.6-1.3 2.9-2.9 2.9s-2.9-1.3-2.9-2.9v-9.7c7.9.1 15 4.8 18.2 11.9 0 .1.1.1.1.2v.1c.1.1.2.2.3.2h.1c.1 0 .1.1.2.1h.5c.6-.2 1.2-.3 1.9-.3 3.6 0 6.5 2.9 6.5 6.6s-2.8 6.7-6.4 6.7" />
    <path d="M20.6 28.1h-.1c-.6 0-1 .4-1 1s.4 1 1 1h.1c.6 0 1-.4 1-1s-.5-1-1-1M46 28.1h-.1c-.6 0-1 .4-1 1s.4 1 1 1h.1c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconBabyKid;
