import * as React from "react";
const IconGlasses = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.4 17.9c-.5-.2-1.1 0-1.3.5l-6.1 14c-1.6-2.1-4-3.4-6.8-3.4-3.7 0-6.9 2.4-8.1 5.7l-3.4-1c-2.4-.7-5-.7-7.4 0l-3.4 1c-1.2-3.3-4.4-5.7-8.1-5.7-2.8 0-5.2 1.3-6.8 3.4l-6.1-14c-.2-.5-.8-.7-1.3-.5s-.7.8-.5 1.3l6.7 15.4c-.4.9-.6 2-.6 3 0 4.8 3.9 8.6 8.6 8.6s8.6-3.9 8.6-8.6c0-.3 0-.7-.1-1l3.5-1q3.15-.9 6.3 0l3.5 1c0 .3-.1.7-.1 1 0 4.8 3.9 8.6 8.6 8.6s8.6-3.9 8.6-8.6q0-1.65-.6-3l6.7-15.4c.3-.5.1-1.1-.4-1.3M16.8 44.2c-3.6 0-6.6-3-6.6-6.6 0-3.7 3-6.6 6.6-6.6s6.6 3 6.6 6.6-2.9 6.6-6.6 6.6m30.4 0c-3.6 0-6.6-3-6.6-6.6 0-3.7 3-6.6 6.6-6.6s6.6 3 6.6 6.6-3 6.6-6.6 6.6" />
  </svg>
);
export default IconGlasses;
