import * as React from "react";
const IconDisplayCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.5 9.9h-47C4.9 9.9 2 12.8 2 16.4V37c0 3.6 2.9 6.5 6.5 6.5H31V52h-9.5c-.6 0-1 .4-1 1s.4 1 1 1h21.1c.6 0 1-.4 1-1s-.4-1-1-1H33v-8.5h22.5c3.6 0 6.5-2.9 6.5-6.5V16.4c0-3.6-2.9-6.5-6.5-6.5M60 37c0 2.5-2 4.5-4.5 4.5h-47C6 41.5 4 39.5 4 37V16.4c0-2.5 2-4.5 4.5-4.5h47c2.5 0 4.5 2 4.5 4.5z" />
  </svg>
);
export default IconDisplayCopy;
