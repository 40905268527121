import * as React from "react";
const IconCornerUpLeft = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.5 19.8H7.6L22.7 4.7c.4-.4.4-1 0-1.4s-1-.4-1.4 0L4.5 20.1c-.4.4-.4 1 0 1.4l16.8 16.8c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4L7.6 21.8h43.9c3.5 0 6.3 2.8 6.3 6.3V60c0 .6.4 1 1 1s1-.4 1-1V28.1c0-4.6-3.7-8.3-8.3-8.3" />
  </svg>
);
export default IconCornerUpLeft;
