import * as React from "react";
const IconGoogleDrive = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M42.1 39.1 22.3 4.9h19.4l19.9 34.2zm-16.7 2.8-9.7 17.2h37.6L63 41.9zM19.7 9.1 1 41.9l9.7 17.2 18.9-32.8z" />
  </svg>
);
export default IconGoogleDrive;
