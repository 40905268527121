import * as React from "react";
const IconMobileChat = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.2 15.2H38.3V8.7c0-3.7-3-6.7-6.7-6.7H8.7C5 2 2 5 2 8.7v46.7C2 59 5 62 8.7 62h22.9c3.7 0 6.7-3 6.7-6.7V35.6h19.9c2.1 0 3.8-1.7 3.8-3.8V19c0-2.1-1.7-3.8-3.8-3.8M31.6 60H8.7C6.1 60 4 57.9 4 55.3V8.7C4 6.1 6.1 4 8.7 4h22.9c2.6 0 4.7 2.1 4.7 4.7v6.5h-6.7c-2.1 0-3.8 1.7-3.8 3.8v16.8c0 1.4.8 2.7 2 3.4.6.3 1.2.4 1.8.4.8 0 1.5-.2 2.2-.7l4.5-3.1v19.4c0 2.7-2.1 4.8-4.7 4.8M60 31.8c0 1-.8 1.8-1.8 1.8H36.4c-.2 0-.4.1-.6.2l-5.2 3.6c-.6.4-1.3.4-1.9.1s-1-.9-1-1.6V19c0-1 .8-1.8 1.8-1.8h28.6c1 0 1.8.8 1.8 1.8v12.8z" />
    <path d="M19.8 47.1c-2.5 0-4.5 2-4.5 4.5s2 4.5 4.5 4.5 4.5-2 4.5-4.5-2-4.5-4.5-4.5m0 7.1c-1.4 0-2.5-1.1-2.5-2.5s1.1-2.5 2.5-2.5 2.5 1.1 2.5 2.5-1.1 2.5-2.5 2.5" />
  </svg>
);
export default IconMobileChat;
