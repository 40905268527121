import * as React from "react";
const IconDownLeftArrowBox = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56 3H8C5.2 3 3 5.2 3 8v48c0 2.8 2.2 5 5 5h48c2.8 0 5-2.2 5-5V8c0-2.8-2.2-5-5-5m3 53c0 1.7-1.3 3-3 3H8c-1.7 0-3-1.3-3-3V8c0-1.7 1.3-3 3-3h48c1.7 0 3 1.3 3 3z" />
    <path d="M39.8 22.4 23.9 38.3V25c0-.6-.4-1-1-1s-1 .4-1 1v15.7c0 .1 0 .3.1.4 0 .1.1.2.2.3s.2.1.3.2.3.1.4.1h15.2c.6 0 1-.4 1-1s-.4-1-1-1H25.3l15.9-15.9c.4-.4.4-1 0-1.4s-1-.4-1.4 0" />
  </svg>
);
export default IconDownLeftArrowBox;
