import * as React from "react";
const IconMenuAlt4 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M46.9 31H17.1c-.6 0-1 .4-1 1s.4 1 1 1h29.7c.6 0 1-.4 1-1s-.4-1-.9-1M32.7 48.7H3c-.6 0-1 .4-1 1s.4 1 1 1h29.7c.6 0 1-.4 1-1s-.4-1-1-1M61 13.3H31.3c-.6 0-1 .4-1 1s.4 1 1 1H61c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconMenuAlt4;
