import * as React from "react";
const IconInvitation = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M40.2 28.6c-1.3-.9-2.7-1.2-4.1-.9-1.8.4-3.3 1.9-4.1 3-.8-1.1-2.3-2.6-4.1-3-1.4-.3-2.8 0-4.1.9-4 2.8-2.8 8.2-1.6 10.6.1.3 3.4 6.8 9.6 8.8h.6c6.2-2 9.3-8.6 9.4-8.8 1.2-2.4 2.5-7.8-1.6-10.6m-.2 9.8c0 .1-2.7 5.8-7.9 7.7-5.2-1.9-8.1-7.6-8.1-7.7s-2.7-5.6.9-8.1c.6-.4 1.3-.7 1.9-.7.2 0 .4 0 .6.1 2.1.5 3.7 3.3 3.7 3.4.2.3.5.5.9.5s.7-.2.9-.5c.4-.8 1.9-2.9 3.7-3.4.8-.2 1.7 0 2.5.6 3.6 2.5 1 7.8.9 8.1" />
    <path d="M48.5 10h-1.6V6.7c0-1.5-.7-2.8-1.8-3.7-1.2-.9-2.7-1.2-4.1-.8L11.5 10c-.1 0-.2.1-.3.2-.1 0-.1.1-.2.1-.2.2-.3.4-.3.7v46.3c0 2.6 2.1 4.7 4.7 4.7h33c2.6 0 4.7-2.1 4.7-4.7V14.7c.1-2.6-2-4.7-4.6-4.7m-7-5.9c.8-.2 1.7 0 2.3.5.7.5 1.1 1.3 1.1 2.1V10H19.4zm9.7 53.2c0 1.5-1.2 2.7-2.7 2.7h-33c-1.5 0-2.7-1.2-2.7-2.7V12h35.7c1.5 0 2.7 1.2 2.7 2.7z" />
  </svg>
);
export default IconInvitation;
