import * as React from "react";
const IconTrowelCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.3 50.3 48 38c-1-1-2.4-1.6-3.8-1.6s-2.8.6-3.8 1.6l-.6.6-9.5-9.6 8.9-8.8c.6-.6.8-1.4.7-2.2-.2-.8-.7-1.5-1.4-1.8l-33-14c-.9-.4-2-.2-2.7.5s-.9 1.8-.5 2.7l14 32.8c.3.7 1 1.3 1.8 1.4h.5c.6 0 1.3-.3 1.7-.7l8.6-8.5 9.6 9.5-.5.5c-2.1 2.1-2.1 5.5 0 7.6l12.3 12.3c1 1 2.4 1.6 3.8 1.6s2.8-.6 3.8-1.6l2.5-2.5c2-2 2-5.4-.1-7.5M18.9 37.5c-.1.1-.3.1-.4.1q-.15 0-.3-.3L4.1 4.6c-.1-.2 0-.4.1-.5.1 0 .2-.1.3-.1h.2l32.9 14c.2.1.2.2.3.3 0 .1 0 .3-.1.4zm40 19L56.4 59c-1.3 1.3-3.5 1.3-4.8 0L39.3 46.7c-1.3-1.3-1.3-3.5 0-4.8l2.5-2.5c.6-.6 1.5-1 2.4-1s1.8.4 2.4 1l12.3 12.3c1.3 1.3 1.3 3.5 0 4.8" />
  </svg>
);
export default IconTrowelCopy;
