import React, { useState } from 'react';
import BringzzText from "../../BringzzText";
import BringzzIcon from "../../BringzzIcon";
import PropTypes from 'prop-types';

const BringzzChatRow = ({ chat, new: isNew, onClick }) => {
    const [isIconsVisible, setIsIconsVisible] = useState(false);

    const toggleIconsVisibility = () => {
        setIsIconsVisible(!isIconsVisible);
    };

    return (
        <div
            className="flex h-20 items-center p-4 relative overflow-hidden border-b"
            onClick={onClick}
        >
            <div className={`flex w-full transition-transform duration-300 ${isIconsVisible && !isNew ? 'translate-x-[-130px]' : ''}`}>
                <div className="flex-none w-14 h-14 rounded-full bg-gray-200 mr-4"></div>
                <div className="flex-grow flex justify-center flex-col">
                    <BringzzText tag='h4' className="font-bold">{chat.name}</BringzzText>
                    {!isNew && (
                        <>
                            <BringzzText tag='h5' className="font-normal italic">{chat.label}</BringzzText>
                            <BringzzText tag='h5' className="font-normal italic text-midnight-blue/60">{chat.datetime}</BringzzText>
                        </>
                    )}
                </div>
                {!isNew && (
                    <div className="flex space-x-[1px] py-2 text-midnight-blue/60 cursor-pointer" onClick={toggleIconsVisibility}>
                        <span className="text-[5px]">&#9679;</span>
                        <span className="text-[5px]">&#9679;</span>
                        <span className="text-[5px]">&#9679;</span>
                    </div>
                )}
            </div>

            {isIconsVisible && !isNew && (
                <div className="absolute flex right-0 top-0 h-full transition-opacity duration-300" style={{ width: '130px' }}>
                    <div className="w-1/2 h-full flex justify-center items-center bg-magic-lilac/20">
                        <BringzzIcon
                            size="22"
                            icon="IconTrashCan"
                            folder="LineIcons"
                            className="cursor-pointer"
                            onClick={(event) => {

                            }}
                        />
                    </div>
                    <div className="w-1/2 h-full flex justify-center items-center bg-magic-lilac/60">
                        <BringzzIcon
                            size="22"
                            icon="IconArchive"
                            folder="LineIcons"
                            className="cursor-pointer"
                            onClick={(event) => {

                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

BringzzChatRow.propTypes = {
    chat: PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string,
        datetime: PropTypes.string
    }).isRequired,
    new: PropTypes.bool,
    onClick: PropTypes.func
};

BringzzChatRow.defaultProps = {
    new: false,
    onClick: () => { }
};

export default BringzzChatRow;
