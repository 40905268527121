import * as React from "react";
const IconRadio = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.2 23.2H18.7l32.7-11.9c.5-.2.8-.8.6-1.3s-.8-.8-1.3-.6L12.9 23.2H9.8c-2.8 0-5 2.2-5 5v21.5c0 2.8 2.2 5 5 5h44.3c2.8 0 5-2.2 5-5V28.2c.1-2.8-2.2-5-4.9-5m3 26.5c0 1.7-1.3 3-3 3H9.8c-1.7 0-3-1.3-3-3V28.2c0-1.7 1.3-3 3-3h44.4c1.7 0 3 1.3 3 3z" />
    <path d="M22.2 29.7c-5.1 0-9.2 4.1-9.2 9.2s4.1 9.2 9.2 9.2 9.2-4.1 9.2-9.2-4.1-9.2-9.2-9.2m0 16.5c-4 0-7.2-3.2-7.2-7.2s3.2-7.2 7.2-7.2 7.2 3.2 7.2 7.2c0 3.9-3.2 7.2-7.2 7.2M49.8 31.5h-9.2c-.6 0-1 .4-1 1s.4 1 1 1h9.2c.6 0 1-.4 1-1s-.4-1-1-1M49.8 37.9h-9.2c-.6 0-1 .4-1 1s.4 1 1 1h9.2c.6 0 1-.4 1-1s-.4-1-1-1M49.8 44.4h-9.2c-.6 0-1 .4-1 1s.4 1 1 1h9.2c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconRadio;
