import * as React from "react";
const IconBulbAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M35.3 18.1c-.4-.4-1-.4-1.4 0l-5.1 5.3c-.4.4-.4 1 0 1.4l4.5 4.6-4.5 4.6c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3l5.1-5.3c.4-.4.4-1 0-1.4l-4.5-4.6 4.5-4.6c.4-.4.4-1 0-1.4" />
    <path d="M54.6 24.6C54.6 12.1 44.5 2 32 2S9.4 12.1 9.4 24.6c0 9.1 5.4 17 13.2 20.6v12.7c0 2.3 1.8 4.1 4.1 4.1h11.1c2.3 0 4.1-1.8 4.1-4.1v-13c7.5-3.6 12.7-11.4 12.7-20.3M39.9 46.4v11.5c0 1.2-.9 2.1-2.1 2.1H26.7c-1.2 0-2.1-.9-2.1-2.1V46.4h1.5c1.9.6 3.9.8 5.9.8s4-.3 5.9-.8zM32 45.2c-11.4 0-20.6-9.2-20.6-20.6S20.6 4 32 4s20.6 9.2 20.6 20.6S43.4 45.2 32 45.2" />
  </svg>
);
export default IconBulbAlt;
