import * as React from "react";
const IconPaintBucket = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.9 38.3 33 12.9V8.8C33 5 29.6 2 25.4 2s-7.6 3-7.6 6.8l.1 15.3c0 .2 0 .3.1.4L5.4 36.8c-.5.4-.7 1-.7 1.6s.2 1.3.7 1.7L27 61.3c.5.4 1 .7 1.7.7.6 0 1.2-.2 1.7-.7l20-19.6 7.5-.6c.6-.1 1.2-.5 1.4-1.1s0-1.3-.4-1.7M19.7 8.8c0-2.6 2.6-4.8 5.6-4.8C28.4 4 31 6.2 31 8.8v3L19.8 22.7zm29.8 31L29 59.9c-.2.2-.5.2-.6 0L6.7 38.7c-.1-.1-.1-.2-.1-.2 0-.1 0-.1.1-.2l24.6-24c.1.1.3.2.5.2l8.7 8.5-6.9 6.8c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3l6.9-6.9 5.4 5.3-4.6 4.5c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3l4.6-4.5 8.2 8z" />
  </svg>
);
export default IconPaintBucket;
