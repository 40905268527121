import * as React from "react";
const IconHousePlanAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M24.6 22.8c-.9-.8-2.3-.8-3.2 0l-4.3 3.5c-.6.5-.9 1.2-.9 2v6.5c0 1.4 1.2 2.6 2.6 2.6h8.6c1.4 0 2.6-1.2 2.6-2.6v-6.5c0-.8-.3-1.5-.9-2zm3.3 12c0 .3-.3.6-.6.6h-8.6c-.3 0-.6-.3-.6-.6v-6.5c0-.2.1-.3.2-.4l4.3-3.5c.1-.1.2-.1.4-.1.1 0 .3 0 .4.1l4.3 3.5c.1.1.2.3.2.4z" />
    <path d="M56.4 16.9c0-.3-.1-.6-.3-.7-.1-.1-.1-.2-.2-.3l-13-13.3-.2-.2c-.3-.2-.5-.4-.9-.4h-29C9.9 2 7.6 4.3 7.6 7.1v49.7c0 2.8 2.3 5.1 5.1 5.1h38.5c2.8 0 5.1-2.3 5.1-5.1V17zM42.8 5.4 53 15.9H42.8zM16.9 60v-8.5c0-1.1.9-2 2-2H22v2.7c0 .6.4 1 1 1s1-.4 1-1v-2.7h7v4.9c0 .6.4 1 1 1s1-.4 1-1v-4.9h7v2.7c0 .6.4 1 1 1s1-.4 1-1v-2.7h3.3c1.1 0 2 .9 2 2V60zm34.3 0h-2v-8.5c0-2.2-1.8-4-4-4H18.9c-2.2 0-4 1.8-4 4V60h-2.1c-1.7 0-3.1-1.4-3.1-3.1V7.1C9.6 5.4 11 4 12.8 4h28.1v12.9c0 .6.4 1 1 1h12.5v39c0 1.7-1.4 3.1-3.2 3.1" />
  </svg>
);
export default IconHousePlanAlt;
