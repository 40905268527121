import * as React from "react";
const IconStopcock = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M18.7 5.6h13.4c.6 0 1-.4 1-1s-.4-1-1-1H18.7c-.6 0-1 .4-1 1s.4 1 1 1M61 38.9h-2.6v-8.2c0-7.7-6.3-14-14-14.1h-9.8l-4.4-4.4c-.2-.2-.4-.3-.7-.3H26V5.7h-2V12h-2.6c-.3 0-.5.1-.7.3l-4.4 4.4H7c-1.3 0-2.5.5-3.5 1.4-1 1-1.5 2.2-1.5 3.5v2c0 2.7 2.2 4.9 4.9 4.9h9.4l4.4 4.4c.2.2.4.3.7.3h8c.3 0 .5-.1.7-.3l4.4-4.4h8c1.6 0 2.9 1.3 2.9 2.9v7.4h-3c-.6 0-1 .4-1 1s.4 1 1 1H61c.6 0 1-.4 1-1s-.4-.9-1-.9M4 23.7v-2q0-1.2.9-2.1t2.1-.9h7.8v7.9H6.9c-1.6 0-2.9-1.3-2.9-2.9m30 2.5-5 5.1h-7.2l-5.1-5.1V19l5.1-5.1H29l5.1 5.1v7.2zm8.5.4H36v-7.9h8.3c6.6 0 12 5.4 12 12.1V39h-8.9v-7.4c.1-2.8-2.1-5-4.9-5M51.9 46.7c-.5.5-5 5.1-5 8 0 3.1 2.6 5.7 5.7 5.7s5.7-2.6 5.7-5.7c0-2.9-4.5-7.5-5-8-.3-.4-1-.4-1.4 0m.7 11.7c-2 0-3.7-1.7-3.7-3.7 0-1.4 2-4.1 3.7-5.9 1.7 1.9 3.7 4.5 3.7 5.9 0 2-1.6 3.7-3.7 3.7" />
  </svg>
);
export default IconStopcock;
