import * as React from "react";
const IconSpeechBubble43 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.2 6.7 7.7 2c-1.4-.1-2.8.4-3.9 1.3S2.1 5.7 2.1 7.1v42.1c0 1.5.6 2.9 1.7 3.9s2.6 1.4 4.1 1.2l27.2-3.6c1.3-.2 2.6.5 3.2 1.6l4.4 8.4c.4.8 1.2 1.3 2.1 1.3h.2c1-.1 1.7-.7 2.1-1.6l3.4-9.9c.4-1.1 1.4-1.9 2.6-2.1l4.4-.5c2.6-.3 4.5-2.5 4.5-5.1v-31c-.1-2.6-2.1-4.8-4.8-5.1m2.7 36.1c0 1.6-1.2 2.9-2.7 3.1l-4.4.5c-1.9.2-3.6 1.6-4.2 3.4l-3.4 9.9c-.1.2-.2.2-.3.3-.1 0-.3 0-.4-.2L40 51.4c-.9-1.7-2.7-2.7-4.5-2.7h-.7L7.6 52.3c-.9.1-1.8-.2-2.5-.7C4.4 51 4 50.1 4 49.2V7.1c0-.9.4-1.7 1-2.3s1.6-.9 2.5-.8L57 8.7c1.6.2 2.8 1.5 2.8 3.1v31z" />
    <path d="M42.9 16.1H18.7c-.6 0-1 .4-1 1s.4 1 1 1h24.2c.6 0 1-.4 1-1s-.5-1-1-1M42.9 25.8H18.7c-.6 0-1 .4-1 1s.4 1 1 1h24.2c.6 0 1-.4 1-1s-.5-1-1-1M42.9 36.8H18.7c-.6 0-1 .4-1 1s.4 1 1 1h24.2c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconSpeechBubble43;
