import React, { useState } from 'react';
import { BringzzButton, BringzzText } from "@bringzz/components";
import { useNavigation } from 'context/NavigationContext';
import {
    BringzzPageContainer,
    BringzzIcon,
    BringzzImageCheckbox
} from "@bringzz/components";
import { useLocation } from 'react-router-dom';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

// TODO: BringzzRadioList should accept multiple selections?
const ConsumerBrandsPage = () => {
    const { backPress } = useNavigation();

    const { navigate } = useNavigation();

    const [selected, setSelected] = useState(false)

    const handler = (value) => {
        if (value.length !== 0) {
            setSelected(true)
        } else {
            setSelected(false)
        }
    };

    const entities = [
        {
            id: "1",
            name: "Brand #1",
            type: "image",
            path: "/brands/brand1.png",
            alt: "Brand 1",
        },
        {
            id: "2",
            name: "Brand #2",
            type: "image",
            path: "/brands/brand2.png",
            alt: "Brand 2",
        },
        {
            id: "3",
            name: "Brand #3",
            type: "image",
            path: "/brands/brand3.png",
            alt: "Brand 3",
        },
        {
            id: "4",
            name: "Brand #4",
            type: "image",
            path: "/brands/brand4.png",
            alt: "Brand 4",
        },
        {
            id: "5",
            name: "Brand #5",
            type: "image",
            path: "/brands/brand5.png",
            alt: "Brand 5",
        },
        {
            id: "6",
            name: "Brand #6",
            type: "image",
            path: "/brands/brand6.png",
            alt: "Brand 6",
        },
    ];

    return (
        <BringzzPageContainer
            className="bg-white"
            steps={true}
            activeStep={11}
            header={{
                left: (
                    <BringzzIcon
                        size='22'
                        icon='IconChevronLeft'
                        folder='LineIcons'
                        className='cursor-pointer'
                        onClick={backPress}
                    />
                ),
                title: "Choose your favorites",
                right: <BringzzButton size='sm' className="border border-black" onClick={() => {
                    navigate('/register/consumer/success');
                }}>Skip</BringzzButton>
            }}
            footer={
                <div className='pb-8 px-6'>
                    <BringzzButton
                        size='lg'
                        className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
                        onClick={async () => {
                            navigate('/register/consumer/success')
                        }}
                        disabled={!selected}
                    >
                        Continue
                    </BringzzButton>
                </div>
            }
            authenticated={false}
        >
            <div className='relative flex justify-center bg-magic-lilac/20'>
                <div className='relative bg-white w-full bg-cover bg-center flex flex-col'>
                    {/* Content */}
                    <div className='flex-grow flex flex-col py-8 px-4'>
                        <div className='z-10 flex flex-col '>
                            <div className='pl-2 flex flex-col space-y-6 md:max-w-lg lg:max-w-xl xl:max-w-2xl'>
                                <BringzzText tag='h1' className='pl-2 text-midnight-blue font-bold'>
                                    Choose 3 or more brands you like
                                </BringzzText>
                                <BringzzText tag="h5" className="font-normal">Based on your selections, we will be able to personalise your content feed, newsletter and notifications. </BringzzText>
                            </div>
                            <div className='mt-6'>
                                <BringzzImageCheckbox
                                    onChange={handler}
                                    filter={(entity) => {
                                        return entity.name.toLowerCase().includes("brand");
                                    }}
                                    entities={entities}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BringzzPageContainer >
    );
};

export default ConsumerBrandsPage;
