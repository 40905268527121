import * as React from "react";
const IconShieldAlt3Check = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.4 12.4c-4.9-.8-8.7-4.6-9.5-9.5-.1-.5-.5-.8-1-.8H16.2c-.5 0-.9.4-1 .8-.8 4.8-4.7 8.8-9.5 9.5-.5.1-.8.5-.8 1v9.2c0 8.7 2.6 17 7.6 24.1C17.3 53.6 24 58.9 31.8 62c.1 0 .2.1.4.1.1 0 .2 0 .4-.1 7.8-3.1 14.5-8.4 19.3-15.3 4.9-7.1 7.6-15.5 7.6-24.1v-9.2c-.3-.5-.7-1-1.1-1m-1.2 10.1c0 8.3-2.5 16.2-7.2 23C45.5 52 39.3 57 32 59.9c-7.3-3-13.5-7.9-18-14.4-4.7-6.8-7.2-14.7-7.2-23v-8.3C11.9 13 15.9 9.1 17 4h30c1.1 5.1 5.1 9 10.2 10.2z" />
    <path d="M40.8 23 27.9 33.4l-4.6-6.3c-.3-.4-.9-.5-1.4-.2-.4.3-.5.9-.2 1.4l5.2 7.2c.2.2.4.4.7.4h.1c.2 0 .5-.1.6-.2L42 24.6c.4-.3.5-1 .1-1.4-.2-.5-.9-.6-1.3-.2" />
  </svg>
);
export default IconShieldAlt3Check;
