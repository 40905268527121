import * as React from "react";
const IconFountain = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.3 31.4c-4.2 0-7.6 3.3-7.7 7.4H33V25.1h4.5c2.2 0 4-1.8 4-4v-7.7c0-2.2 1.8-3.9 3.9-3.9s3.9 1.8 3.9 3.9v3.1c0 .6.4 1 1 1s1-.4 1-1v-3.1c0-3.3-2.7-5.9-5.9-5.9-3.1 0-5.6 2.4-5.9 5.4H33V3c0-.6-.4-1-1-1s-1 .4-1 1v9.9h-6.5c-.3-3-2.8-5.4-5.9-5.4-3.3 0-5.9 2.7-5.9 5.9v3.1c0 .6.4 1 1 1s1-.4 1-1v-3.1c0-2.2 1.8-3.9 3.9-3.9s3.9 1.8 3.9 3.9v7.7c0 2.2 1.8 4 4 4H31v13.7h-9.6c-.2-4.1-3.6-7.4-7.7-7.4-4.3 0-7.7 3.5-7.7 7.7v4.2c0 .6.4 1 1 1s1-.4 1-1v-4.2c0-3.2 2.6-5.7 5.7-5.7 3.2 0 5.7 2.6 5.7 5.7v11.6c0 2.2 1.8 4 4 4H31V60H13.6c-.6 0-1 .4-1 1s.4 1 1 1h36.8c.6 0 1-.4 1-1s-.4-1-1-1H33v-5.2h7.6c2.2 0 4-1.8 4-4V39.2c0-3.2 2.6-5.7 5.7-5.7s5.7 2.6 5.7 5.7v4.2c0 .6.4 1 1 1s1-.4 1-1v-4.2c.1-4.3-3.4-7.8-7.7-7.8M24.5 21.1v-6.2h14.9v6.2c0 1.1-.9 2-2 2H26.5c-1.1 0-2-.9-2-2m18.1 29.7c0 1.1-.9 2-2 2H23.4c-1.1 0-2-.9-2-2v-9.9h21.2v9.9" />
  </svg>
);
export default IconFountain;
