import * as React from "react";
const IconPepperHot = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 65 65"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M7.1 38.9c.2.2.4.3.7.3H8c.3-.1.6-.3.7-.6.6-1.4 1.5-2.9 2.6-4.2.9-1.2 2-2.3 3.2-3.2.6-.5 1.5-1 2.5-1 1.2.1 2 1.1 2.8 2.3l.2.3c.9 1.3 1.9 2.6 2.7 3.8.2.3.5.4.8.4s.6-.1.8-.4c2.4-2.9 3.9-6.4 4-9.7.5-8.9-4.1-16.7-13.2-22.7-.3-.2-.8-.2-1.1 0s-.5.7-.4 1.1c.1.5.3 1.1.5 1.7.7 2.1 1.4 4.5 1 6.9-.5 2.9-2.4 4.7-6.4 8.8l-2.5 2.5c-2.5 2.5-3 6.1-1.6 9.9.4 1.4 1.3 2.6 2.5 3.8m.5-12.2c.9-.9 1.7-1.8 2.5-2.5 4.1-4.3 6.2-6.4 6.9-9.9.5-2.4-.1-4.7-.7-6.7 5.3 4 10.5 10.3 10 19.2-.1 2.5-1.1 5.2-2.8 7.5-.6-.9-1.3-1.8-1.9-2.7l-.2-.3c-1-1.3-2.1-3-4.3-3.1-1.2 0-2.6.4-3.8 1.4-1.3 1-2.5 2.2-3.5 3.5-.8 1-1.6 2.2-2.2 3.3-.5-.6-.9-1.3-1.1-1.9-.8-1.7-1.5-5.1 1.1-7.8M61.5 24.7c.1-.5-.2-1.1-.8-1.2-.5-.1-1.1.2-1.2.8-.6 2.9-1.6 3.9-2.1 4.2-1.5-1.3-3.6-2-5.9-2-4.1 0-8.3 2.1-10.6 5.2-6 8.4-12.8 14.3-20.1 17.5-6.1 2.6-11.4 3-15.4 3h-.8c-.4 0-.8.3-.9.6-.1.4 0 .8.3 1.1 1 .9 2.9 2.3 6 3.6 4.6 2 10.2 3.1 15.6 3.1 8 0 19.8-2.3 30.6-13.3 2.3-2.3 3.9-5.8 4.3-9.5.4-3.1-.2-5.8-1.6-7.8.8-.7 1.9-2.2 2.6-5.3m-3.2 12.9c-.4 3.1-1.8 6.3-3.7 8.3C44.3 56.4 33 58.6 25.4 58.6c-5.2 0-10.4-1-14.8-2.9-1.2-.5-2.3-1.1-3.1-1.6 3.9-.1 8.7-.8 14-3.1 7.7-3.3 14.7-9.4 21-18.1 1.9-2.6 5.5-4.4 9-4.4 2 0 3.8.6 4.9 1.8 1.6 1.5 2.3 4.2 1.9 7.3" />
  </svg>
);
export default IconPepperHot;
