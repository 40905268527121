import * as React from "react";
const IconCloudAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.5 51.1h-37C7.2 51.1 2 46 2 39.7s5.2-11.3 11.5-11.3q2.4 0 4.5.9c-.1-.8-.2-1.6-.2-2.5 0-7.7 6.4-13.9 14.2-13.9s14.2 6.2 14.2 13.9c0 .8-.1 1.7-.2 2.5q2.1-.9 4.5-.9c6.4 0 11.5 5.1 11.5 11.3s-5.2 11.4-11.5 11.4m-37-20.7c-5.3 0-9.5 4.2-9.5 9.3S8.3 49 13.5 49h36.9c5.3 0 9.5-4.2 9.5-9.3s-4.3-9.3-9.5-9.3c-2 0-3.9.6-5.5 1.7-.4.3-.8.2-1.2 0-.3-.3-.5-.7-.3-1.1.5-1.3.8-2.7.8-4.1C44.2 20.3 38.7 15 32 15s-12.2 5.3-12.2 11.9c0 1.4.3 2.8.8 4.1.2.4 0 .9-.3 1.1-.3.3-.8.3-1.2 0-1.7-1.1-3.6-1.7-5.6-1.7" />
  </svg>
);
export default IconCloudAlt;
