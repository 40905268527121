import * as React from "react";

const IconShieldBlack = (props) => (
    <svg
        className="fill-current text-black"
        xmlns="http://www.w3.org/2000/svg"
        width={props.size || "30"}
        height={props.size || "32"}
        viewBox="0 0 30 32"
        {...props}
    >
        <path
            d="M0.334961 6.15438C0.334961 5.27134 0.904468 4.48736 1.74881 4.20813L13.9709 0.165948C14.64 -0.055316 15.3632 -0.055316 16.0324 0.165948L28.2544 4.20813C29.0987 4.48737 29.6683 5.27134 29.6683 6.15438V11.7573C29.6683 20.6477 24.1675 28.6261 15.8163 31.8483C15.2924 32.0506 14.7109 32.0506 14.187 31.8483C5.83581 28.6261 0.334961 20.6477 0.334961 11.7573V6.15438Z"
            fill="#05003B"
        />
    </svg>
);

export default IconShieldBlack;
