import * as React from "react";
const IconChatDelete = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.2 10.5C46.8 4.1 38 1 28.9 1.9 15 3.4 3.9 14.5 2.4 28.4c-.7 6.7.8 13.2 4.2 18.8L2.2 58.8c-.4 1-.1 2.1.7 2.8.5.4 1.1.7 1.7.7.4 0 .8-.1 1.1-.3L16 56.8c5.7 3.6 12.2 5.2 19 4.6C49.1 60 60.4 48.8 61.8 34.7c1-9-2.2-17.9-8.6-24.2m6.7 24c-1.3 13.2-11.8 23.6-25 24.9-6.5.6-12.8-1-18.2-4.5-.3-.2-.7-.2-1-.1L4.8 60.2c-.3.2-.5 0-.6-.1s-.3-.3-.2-.6l4.6-12c.1-.3.1-.6-.1-.9-3.3-5.4-4.8-11.6-4.1-18 1.3-13 11.7-23.4 24.7-24.7 1-.1 2-.2 3-.2 7.4 0 14.4 2.9 19.7 8.1 6 6 8.9 14.3 8.1 22.7" />
    <path d="M38.2 25.8c-.4-.4-1-.4-1.4 0L32 30.6l-4.8-4.8c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l4.8 4.8-4.8 4.8c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l4.8-4.8 4.8 4.8c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4L33.4 32l4.8-4.8c.4-.4.4-1 0-1.4" />
  </svg>
);
export default IconChatDelete;
