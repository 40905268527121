import * as React from "react";
const IconBadge1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m57.8 54 3.4-6.6c.2-.3.2-.7 0-1s-.5-.5-.9-.5H50.1v-2.8c0-.5-.4-.9-.9-1 4.2-4.4 6.6-10.3 6.6-16.4C55.9 12.7 45.2 2 32 2S8.1 12.7 8.1 25.9c0 6.2 2.4 12 6.6 16.4-.5.1-.9.5-.9 1V46H3.6c-.4 0-.7.2-.9.5s-.2.7 0 1L6.2 54l-3.4 6.5c-.2.3-.2.7 0 1 .1.3.5.5.8.5h11.2c.6 0 1-.4 1-1v-4.5h32.3V61c0 .6.4 1 1 1h11.2c.4 0 .7-.2.9-.5s.2-.7 0-1zM10.1 25.9C10.1 13.8 20 4 32 4s21.9 9.8 21.9 21.9c0 6.3-2.7 12.3-7.5 16.4H17.6c-4.7-4.2-7.5-10.1-7.5-16.4M13.9 60H5.3l2.9-5.5q.3-.45 0-.9L5.3 48h8.6v12m2-15.7h32.3v10.3H15.9zM50.1 60V47.9h8.6l-2.9 5.6q-.3.45 0 .9l2.9 5.5h-8.6z" />
    <path d="m26.5 25.8-.9 5.5c-.1.4.1.8.4 1 .2.1.4.2.6.2s.3 0 .5-.1l5-2.6 5 2.6c.3.2.7.1 1.1-.1.3-.2.5-.6.4-1l-.9-5.5 4-3.9c.3-.3.4-.7.3-1-.1-.4-.4-.6-.8-.7l-5.5-.8-2.5-5c-.3-.7-1.5-.7-1.8 0l-2.5 5-5.5.8c-.4.1-.7.3-.8.7s0 .8.3 1zm2.9-4.5c.3 0 .6-.3.8-.5l1.8-3.7 1.8 3.7c.1.3.4.5.8.5l4.1.6-2.9 2.9c-.2.2-.3.6-.3.9l.7 4-3.6-1.9c-.1-.1-.3-.1-.5-.1s-.3 0-.5.1L28 29.7l.7-4c.1-.3-.1-.7-.3-.9l-2.9-2.9z" />
  </svg>
);
export default IconBadge1;
