import * as React from "react";
const IconIExamMultipleChoice = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      d="M20 15a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2h-8a1 1 0 0 1-1-1M21 18a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2zM20 28a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2h-8a1 1 0 0 1-1-1M21 31a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2z"
    />
    <path
      
      fillRule="evenodd"
      d="M10 27a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1zm2 1v3h3v-3z"
      clipRule="evenodd"
    />
    <path
      
      d="M17.707 15.707a1 1 0 0 0-1.414-1.414L13 17.586l-1.293-1.293a1 1 0 0 0-1.414 1.414L13 20.414z"
    />
    <path
      
      fillRule="evenodd"
      d="M10 6a4 4 0 0 0-4 4v28a4 4 0 0 0 4 4h20a4 4 0 0 0 4-4V10a4 4 0 0 0-4-4zm-2 4a2 2 0 0 1 2-2h20a2 2 0 0 1 2 2v28a2 2 0 0 1-2 2H10a2 2 0 0 1-2-2zM36 16a3 3 0 1 1 6 0v20.303l-3 4.5-3-4.5zm3-1a1 1 0 0 0-1 1v2h2v-2a1 1 0 0 0-1-1m0 22.197-1-1.5V20h2v15.697z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconIExamMultipleChoice;
