import * as React from "react";
const IconGlobal = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62 32c0-5-1.3-10.2-3.8-14.8-4.1-7.1-11-12.3-18.9-14.3-2.6-.6-5-.9-7.4-.9-.6 0-1 .4-1 1v58c0 .6.4 1 1 1 1.2 0 2.5-.1 3.7-.2 8.9-1.1 16.8-6 21.7-13.6 3-4.6 4.6-9.9 4.7-15.4zm-11.2-.3c-.2-4.1-1.2-8.2-3-11.9h9.5c1.8 3.8 2.7 7.9 2.8 11.9zM32.9 59.3V48.8h11.4c-2.9 4.6-6.9 8.3-11.4 10.5m11.5-41.5H32.9v-13c3.7 2.1 7.5 6.4 11.5 13m-11.5 29V33.7h15.9c-.1 4.6-1.2 9.1-3.3 13.1zm0-15.1V19.8h12.6c1.9 3.7 3 7.7 3.3 11.9zm23.3-13.9h-9.5c0-.1-.1-.2-.1-.2-3.7-6.3-7.2-10.7-10.8-13.3q1.5.15 3 .6c7.3 1.8 13.6 6.5 17.4 12.9M36.5 59.6c4-2.6 7.5-6.2 10.1-10.6 0-.1.1-.1.1-.2h7.7c-4.4 5.8-10.8 9.7-17.9 10.8m19.2-12.8h-8c2-4.1 3-8.6 3.1-13.1h9.1c-.2 4.7-1.7 9.2-4.2 13.1M13.2 32.3c3.7 0 6.6-3 6.6-6.6s-3-6.6-6.6-6.6c-3.7 0-6.6 3-6.6 6.6s3 6.6 6.6 6.6m0-11.3c2.6 0 4.6 2.1 4.6 4.6s-2.1 4.6-4.6 4.6-4.6-2.1-4.6-4.6 2.1-4.6 4.6-4.6M13.2 38.5c-4.2 0-8.2 1.7-11 4.8-.4.4-.4 1 .1 1.4.4.4 1 .4 1.4-.1 2.4-2.6 5.9-4.1 9.5-4.1s7.1 1.5 9.5 4.1c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 .1-1.4-2.8-2.9-6.8-4.7-11-4.7" />
  </svg>
);
export default IconGlobal;
