import * as React from "react";
const IconKg = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 342"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <g >
      <path d="M0 0h513v342H0z" />
      <circle cx={256} cy={170.7} r={170.7} />
    </g>
    <path
      
      d="M382.4 170.7 330.8 195l27.5 50-56.1-10.7-7.1 56.6-39.1-41.7-39.1 41.7-7.1-56.6-56.1 10.7 27.5-50-51.6-24.3 51.6-24.3-27.5-50.1 56.1 10.8 7.1-56.7L256 92.1l39.1-41.7 7.1 56.7 56.1-10.8-27.5 50.1z"
    />
    <circle cx={257.4} cy={170.7} r={71.6}  />
    <path
      
      d="M214.2 170.7c-2.1 0-4.1.1-6.2.3.1 12 4.4 22.9 11.6 31.5 3.8-10.3 9.5-19.6 16.7-27.7-6.9-2.7-14.3-4.1-22.1-4.1m26.6 46.5c5.2 1.9 10.8 2.9 16.6 2.9s11.4-1 16.6-2.9c-2.8-11.1-8.7-21-16.6-28.8-7.9 7.8-13.8 17.7-16.6 28.8m59.4-71.2c-8.5-14.8-24.5-24.7-42.8-24.7s-34.2 9.9-42.8 24.7c15.6.1 30.2 4.3 42.8 11.6 12.6-7.3 27.2-11.6 42.8-11.6m-21.7 28.8c7.2 8 12.9 17.4 16.7 27.7 7.2-8.5 11.5-19.5 11.6-31.5-2-.2-4.1-.3-6.2-.3-7.7 0-15.2 1.4-22.1 4.1"
    />
  </svg>
);
export default IconKg;
