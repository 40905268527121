import * as React from "react";
const IconHospitalBedAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.2 42.7c-.5 0-.9.1-1.4.2l-17-8.9 18.5-9.7H61c.6 0 1-.4 1-1s-.4-1-1-1H11.6L3.8 10c-.2-.5-.9-.6-1.3-.3-.5.3-.6.9-.3 1.4l8.1 12.8c.2.3.5.5.8.5h4L33.5 34l-17.4 9.1c-.6-.2-1.3-.4-2.1-.4-3.2 0-5.8 2.6-5.8 5.9s2.6 5.9 5.8 5.9c2.6 0 4.7-1.7 5.5-4h31.1c.8 2.3 3 4 5.5 4 3.2 0 5.8-2.6 5.8-5.9s-2.5-5.9-5.7-5.9M19.4 24.4H52l-16.3 8.5zm16.3 10.7L52.6 44c-1.3 1.1-2.2 2.7-2.2 4.5H19.9c0-1.6-.7-3.1-1.8-4.1zM14.1 52.5c-2.1 0-3.8-1.7-3.8-3.9 0-2.1 1.7-3.9 3.8-3.9s3.8 1.7 3.8 3.9c0 2.1-1.7 3.9-3.8 3.9m42.1 0c-2.1 0-3.8-1.7-3.8-3.9 0-2.1 1.7-3.9 3.8-3.9s3.8 1.7 3.8 3.9c0 2.1-1.7 3.9-3.8 3.9" />
  </svg>
);
export default IconHospitalBedAlt;
