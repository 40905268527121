import * as React from "react";
const IconVih = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      d="M16.5 20.5a1 1 0 0 1 1 1V23h1v-1.5a1 1 0 1 1 2 0v5a1 1 0 1 1-2 0V25h-1v1.5a1 1 0 1 1-2 0v-5a1 1 0 0 1 1-1M22.5 20.5a1 1 0 1 0 0 2h.5v3h-.5a1 1 0 1 0 0 2h3a1 1 0 1 0 0-2H25v-3h.5a1 1 0 1 0 0-2zM30.5 27.5a1 1 0 0 0 .928-.629l2-5a1 1 0 0 0-1.856-.742L30.5 23.807l-1.072-2.678a1 1 0 0 0-1.857.742l2 5a1 1 0 0 0 .929.629"
    />
    <path
      
      fillRule="evenodd"
      d="M22 4a1 1 0 1 0 0 2h1v3.033a14.94 14.94 0 0 0-8.876 3.677l-2.145-2.145.707-.707a1 1 0 0 0-1.414-1.414l-2.828 2.828a1 1 0 0 0 1.414 1.414l.707-.707 2.145 2.145A14.94 14.94 0 0 0 9.033 23H6v-1a1 1 0 0 0-2 0v4a1 1 0 1 0 2 0v-1h3.033a14.94 14.94 0 0 0 3.677 8.876l-2.145 2.145-.707-.707a1 1 0 0 0-1.414 1.414l2.828 2.828a1 1 0 1 0 1.414-1.414l-.707-.707 2.145-2.145A14.94 14.94 0 0 0 23 38.967V42h-1a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2h-1v-3.033a14.94 14.94 0 0 0 8.876-3.677l2.145 2.145-.707.707a1 1 0 0 0 1.414 1.414l2.828-2.828a1 1 0 1 0-1.414-1.414l-.707.707-2.145-2.145A14.94 14.94 0 0 0 38.967 25H42v1a1 1 0 1 0 2 0v-4a1 1 0 0 0-2 0v1h-3.033a14.94 14.94 0 0 0-3.677-8.876l2.145-2.145.707.707a1 1 0 0 0 1.414-1.414l-2.828-2.828a1 1 0 1 0-1.414 1.414l.707.707-2.145 2.145A14.94 14.94 0 0 0 25 9.033V6h1a1 1 0 1 0 0-2zm-6.458 10.128A12.94 12.94 0 0 1 23 11.038V12a1 1 0 1 0 2 0v-.962a12.94 12.94 0 0 1 7.458 3.09l-.68.68a1 1 0 0 0 1.414 1.414l.68-.68A12.94 12.94 0 0 1 36.962 23H36a1 1 0 0 0 0 2h.962a12.94 12.94 0 0 1-3.09 7.458l-.68-.68a1 1 0 0 0-1.414 1.415l.68.68A12.94 12.94 0 0 1 25 36.963V36a1 1 0 1 0-2 0v.962a12.94 12.94 0 0 1-7.458-3.09l.68-.68a1 1 0 0 0-1.414-1.414l-.68.68A12.94 12.94 0 0 1 11.038 25H12a1 1 0 1 0 0-2h-.962a12.94 12.94 0 0 1 3.09-7.458l.68.68a1 1 0 0 0 1.414-1.414z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconVih;
