import * as React from "react";
const IconCamel = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.8 13.4c-.3-1-.9-1.7-1.7-1.9l-3.3-1.2c-.1 0-.2-.2-.4-.4-.5-.6-1.2-1.5-2.8-1.5h-2.2c-1.2 0-2.3.4-3.2 1.1s-1.6 1.7-1.9 2.8L45 17.8c-.2.6-.5 1.1-1 1.5-.9.7-2.2.7-3 .4-.7-.3-.8-.5-1.3-1.3l-1.8-3c-1.2-2-2.4-2-3.9-2h-.1c-.4 0-.8-.8-1.1-1.4l-.2-.4c-.6-1.1-1.3-2-2.1-2.6-.9-.7-1.9-1-3-1-3.5 0-4.8 2.4-5.9 4.6-.1-.2-.2-.4-.4-.6v-.1C20.3 10.3 19.1 8 16 8c-1.2 0-2.2.3-3.1.9s-1.6 1.5-2.3 2.7l-2.5 4.6-1.1 2c-1.6 1.6-2.9 3.4-3.7 5.3-.9 2-1.3 4.2-1.3 6.6v21.8c0 2.2 1.8 4 4 4s4-1.8 4-4v-9.2c0-2.6 1.3-5.3 2.5-8l.7-1.5c3.9 2.6 9 3.3 14.5 1.9.8-.2 1.8-.4 2.4-.5l.3.5c.5.9 1.2 2.2 1.2 2.9v13.8c0 2.2 1.8 4 4 4s4-1.8 4-4V38.7c0-.1 0-.6.4-1.8.3-.9.6-1.9.9-2.9.1-.4 2-.8 3.2-1.1 4.7-1 11.7-2.6 11.7-13.8l3.5-.3c.9-.1 1.7-.6 2.2-1.5.3-.5.5-1.2.6-1.9-.1-.7-.1-1.4-.3-2M60 15.2c0 .4-.2.8-.3 1.1-.3.5-.6.5-.7.5l-3.5.3c-1 .1-1.7.9-1.7 1.9-.1 9.7-5.8 11-10.3 12-2.2.5-4.2.9-4.7 2.4-.3 1-.7 2-1 2.9-.4 1.5-.5 2.1-.5 2.4v13.2c0 1.1-.9 2-2 2s-2-.9-2-2V38.1c0-1.2-.8-2.6-1.5-3.8l-.4-.8c-.6-1.1-2.1-.8-4.4-.2-5.1 1.2-9.8.6-13.2-1.9-.6-.4-1.2-.4-1.7-.1s-.7.7-.7.8l-.8 1.8C9.4 36.7 8 39.8 8 42.8V52c0 1.1-.9 2-2 2s-2-.9-2-2V30.1c0-2.1.4-4 1.1-5.8.8-1.7 1.9-3.3 3.3-4.7l1.5-2.3 2.5-4.7c.5-.9 1-1.6 1.6-2s1.2-.6 1.9-.6c1.9 0 2.5 1.1 3.4 2.8v.1c.4.7.7 1.3 1.1 1.7s.9.6 1.3.5c.5-.1.9-.4 1.1-.8l.2-.4c1.2-2.4 2-3.8 4.3-3.8.7 0 1.3.2 1.9.6s1.1 1.1 1.5 1.9l.2.3c.5 1 1.2 2.5 2.8 2.5 1.4 0 1.7 0 2.3 1.1l1.8 2.9c.5.8.9 1.6 2.3 2.1.6.2 1.2.3 1.9.3 1.1 0 2.1-.3 2.9-1s1.4-1.5 1.7-2.6l1.5-5.4c.2-.7.6-1.3 1.1-1.7.6-.4 1.2-.7 2-.7h2.2c.7 0 .8.2 1.2.7.2.3.6.8 1.3 1l3.3 1.2c.2.1.4.3.5.6.3.4.3.9.3 1.3" />
  </svg>
);
export default IconCamel;
