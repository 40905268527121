import * as React from "react";
const IconBarrier = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 20.8h-7.5v-4.1c2.8-.5 5-2.9 5-5.9 0-3.3-2.7-6-6-6s-6 2.7-6 6c0 3 2.2 5.4 5 5.9v4.1h-33v-4.1c2.8-.5 5-2.9 5-5.9 0-3.3-2.7-6-6-6s-6 2.7-6 6c0 3 2.2 5.4 5 5.9v4.1H6c-2.2 0-4 1.8-4 4v14.5c0 2.2 1.8 4 4 4h6.5v14c0 1.7 1.3 3 3 3h3c1.7 0 3-1.3 3-3v-14h17.4l.1.1.1-.1h3.5v14c0 1.7 1.3 3 3 3h3c1.7 0 3-1.3 3-3v-14H58c2.2 0 4-1.8 4-4V24.8c0-2.3-1.8-4-4-4m-12.5-10c0-2.2 1.8-4 4-4s4 1.8 4 4-1.8 4-4 4-4-1.8-4-4m-35 0c0-2.2 1.8-4 4-4s4 1.8 4 4-1.8 4-4 4-4-1.8-4-4m42.9 12L38.1 41.3H24.7L40 22.8zM4 39.3V24.8c0-1.1.9-2 2-2h15.5L6.1 41.3H6c-1.1 0-2-.9-2-2m15.5 18c0 .6-.4 1-1 1h-3c-.6 0-1-.4-1-1v-14h5zm0-16H8.7L24 22.8h13.4L22.1 41.3h-2.6m30 16c0 .6-.4 1-1 1h-3c-.6 0-1-.4-1-1v-14h5zm10.5-18c0 1.1-.9 2-2 2H40.7L56 22.8h2c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconBarrier;
