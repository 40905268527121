import * as React from "react";
const IconHat2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32.7 52.8H3c-.6 0-1-.4-1-1s.4-1 1-1h5.5c.4-4.7 3-25.1 15.8-33.4 6.4-4.2 14.3-4.6 23.6-1.3 1-2.8 3.7-4.9 6.8-4.9 4 0 7.2 3.2 7.2 7.2s-3.2 7.2-7.2 7.2-7.2-3.2-7.2-7.2v-.3c-4.5-.6-8.1.4-10.5 3-7 7.5-4.2 25.7-3.4 29.7h4.1c.6 0 1 .4 1 1s-.4 1-1 1zc.1 0 0 0 0 0m-22.1-2h21c-.9-4.9-3.5-23 4-31.1 1.6-1.7 3.5-2.8 5.8-3.4q-9.15-1.65-15.9 2.7C13.7 26.7 11 46.1 10.6 50.8m44.2-37.6c-2.9 0-5.2 2.3-5.2 5.2s2.3 5.2 5.2 5.2 5.2-2.3 5.2-5.2-2.3-5.2-5.2-5.2" />
  </svg>
);
export default IconHat2;
