import * as React from "react";
const IconCloudAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M44.1 53.5H15.3C8 53.5 2 47.6 2 40.4c0-7.1 5.7-12.9 12.9-13.1v-.1c0-9.3 7.7-16.8 17.1-16.8 2 0 3.9.3 5.5.9 3.8 1.3 7 3.9 9.1 7.4 4 .5 7.9 2.5 10.6 5.4C60.3 27.4 62 31.6 62 36c0 9.7-8 17.5-17.9 17.5m-28.7-2h28.7C52.9 51.5 60 44.6 60 36c0-3.9-1.5-7.6-4.2-10.5-2.5-2.7-6.1-4.4-9.8-4.8-.3 0-.6-.2-.8-.5-1.8-3.3-4.8-5.8-8.3-7-1.4-.5-3.1-.8-4.9-.8-8.3 0-15.1 6.6-15.1 14.8v1.1c0 .6-.4 1-1 1h-.5C9 29.3 4 34.2 4 40.4s5 11.1 11.4 11.1" />
  </svg>
);
export default IconCloudAlt1;
