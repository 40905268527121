import * as React from "react";
const IconMission = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.3 54.6 49 38c-.9-1.6-2.5-2.5-4.3-2.5s-3.4.9-4.3 2.5L37 44 25.3 23.3v-6.6h19.4c.4 0 .7-.2.9-.5s.1-.7-.1-1l-4.1-5.7 4.1-5.7c.2-.3.2-.7.1-1-.2-.6-.6-.8-.9-.8H28.3c-2.7 0-5 2.2-5 5v16.3L5.7 54.6c-.9 1.6-.9 3.4 0 4.9.9 1.6 2.5 2.5 4.3 2.5h44c1.8 0 3.4-.9 4.3-2.5.9-1.5.9-3.3 0-4.9m-34-29 6.5 11.5-3.6 3.6-2.6-2.6c-.4-.4-1-.4-1.4 0l-2.8 2.8-3-3zm1-18.6c0-1.6 1.3-3 3-3h14.5l-3.4 4.7c-.2.3-.2.8 0 1.2l3.4 4.7H25.3V7m3.2 53H10c-1.1 0-2-.5-2.6-1.5-.5-.9-.5-2 0-2.9l9-16 3.3 3.3c.2.2.4.3.7.3s.5-.1.7-.3l2.8-2.8 2.6 2.6c.4.4 1 .4 1.4 0l3.9-3.9L43.7 60zm28.1-1.5c-.6 1-1.5 1.5-2.6 1.5h-8l-7.9-14 4-7c.5-.9 1.5-1.5 2.6-1.5s2 .6 2.6 1.5l9.4 16.6c.4.9.4 2-.1 2.9" />
  </svg>
);
export default IconMission;
