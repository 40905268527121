import * as React from "react";
const IconWinterHat = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 52h-3.9c-.4-12.1-8.9-22.1-20-24.5 2.7-1.7 4.4-4.6 4.4-8 0-5.2-4.2-9.5-9.5-9.5-5.2 0-9.5 4.2-9.5 9.5 0 3.4 1.8 6.3 4.4 8C15.8 29.8 7.4 39.9 6.9 52H3c-.6 0-1 .4-1 1s.4 1 1 1h58c.6 0 1-.4 1-1s-.4-1-1-1M24.5 19.5c0-4.1 3.3-7.5 7.5-7.5s7.5 3.3 7.5 7.5S36.1 27 32 27s-7.5-3.4-7.5-7.5m1.6 10.2c-4 4.1-6.8 12.4-6.9 22.2H8.9c.5-10.6 7.6-19.5 17.2-22.2M21.2 52c.2-12.6 5.1-23 10.8-23s10.6 10.4 10.8 23zm23.6 0c-.2-9.8-2.9-18.1-7-22.2C47.4 32.4 54.6 41.3 55 52z" />
  </svg>
);
export default IconWinterHat;
