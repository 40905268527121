import * as React from "react";
const IconAmbulatoryClinic = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M14 24v8h8v-8zm6 6v-4h-4v4z"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M19 6a6 6 0 0 0-5.653 3.985H6v2.481l4 3.03V40H7a1 1 0 1 0 0 2h3v.015h28V42h3a1 1 0 1 0 0-2h-3V15.532l4.5-3.03V9.984H24.653A6 6 0 0 0 19 6m5.659 8A6 6 0 0 0 25 12v-.015h14.687L36.695 14zm-1.187 2A5.99 5.99 0 0 1 19 18a5.99 5.99 0 0 1-4.472-2H12v24h12V24h10v16h2V16zM13 12c0 .701.12 1.374.341 2h-2.005l-2.66-2.015H13zm7-1V9h-2v2h-2v2h2v2h2v-2h2v-2zm12 15v14h-6V26z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconAmbulatoryClinic;
