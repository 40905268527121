import * as React from "react";
const IconInvestmentCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32.2 44.1c.6 0 1-.4 1-1v-2h1.7c2.8 0 5.1-2.3 5.1-5s-2.3-5-5.1-5h-5.2c-1.7 0-3.1-1.4-3.1-3s1.4-3 3.1-3H38c.6 0 1-.4 1-1s-.4-1-1-1h-4.8v-2c0-.6-.4-1-1-1s-1 .4-1 1v2h-1.5c-2.8 0-5.1 2.3-5.1 5s2.3 5 5.1 5h5.2c1.7 0 3.1 1.4 3.1 3s-1.4 3-3.1 3h-8.6c-.6 0-1 .4-1 1s.4 1 1 1h4.8v2c.1.5.5 1 1.1 1" />
    <path d="m8.6 27.5 8-4.2c.5-.3.7-.9.4-1.4s-.9-.7-1.4-.4l-5.9 3.1C12.6 14.4 21.9 7.2 32.6 7.2c10.9 0 20.5 7.4 23.2 18.1.1.5.7.9 1.2.7.5-.1.9-.7.7-1.2C54.8 13.3 44.5 5.2 32.6 5.2 21.1 5.2 11 12.8 7.9 23.8l-2.9-6c-.2-.5-.8-.7-1.3-.5s-.7.8-.5 1.3L7.3 27c.1.2.3.4.6.5h.3c.1.1.3.1.4 0M61.7 47.2l-3.9-8.5c-.1-.2-.3-.4-.6-.5s-.5-.1-.8 0l-8.1 4c-.5.2-.7.8-.4 1.3.2.5.8.7 1.3.4L55 41c-3.4 9.4-12.3 15.6-22.4 15.6-10.3 0-19.4-6.6-22.6-16.3-.2-.5-.7-.8-1.3-.6-.4.3-.7.8-.5 1.4 3.5 10.6 13.3 17.7 24.5 17.7 10.9 0 20.6-6.8 24.3-17.1l2.9 6.4q.3.6.9.6c.1 0 .3 0 .4-.1.5-.3.7-.9.5-1.4" />
  </svg>
);
export default IconInvestmentCopy;
