import * as React from "react";
const IconBuildingsAlt4 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.2 2H41.9c-2.5 0-4.5 2-4.5 4.5v13.9H7.9c-3.1 0-5.5 2.5-5.5 5.5v30.6c0 3.1 2.5 5.5 5.5 5.5h49.3c2.5 0 4.5-2 4.5-4.5v-51c0-2.5-2-4.5-4.5-4.5M4.3 56.5V25.9c0-1.9 1.6-3.5 3.5-3.5h29.6V60h-6.5v-7.8c0-1.5-.7-2.9-1.8-3.8l-3.8-3.1c-1.8-1.5-4.4-1.5-6.2 0l-3.8 3.1c-1.1.9-1.8 2.3-1.8 3.8V60H7.9c-2 0-3.6-1.6-3.6-3.5M15.6 60v-7.8c0-.9.4-1.7 1.1-2.2l3.8-3.1c1.1-.9 2.6-.9 3.6 0l3.8 3.1c.7.6 1.1 1.4 1.1 2.2V60zm44.1-2.5c0 1.4-1.1 2.5-2.5 2.5H39.4V6.5c0-1.4 1.1-2.5 2.5-2.5h15.2c1.4 0 2.5 1.1 2.5 2.5v51z" />
  </svg>
);
export default IconBuildingsAlt4;
