import * as React from "react";
const IconSofa5 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.2 25.3h-4.1v-18c0-2.9-2.4-5.3-5.3-5.3H16.3c-3 0-5.4 2.4-5.4 5.3v17.9H6.8c-.6 0-1 .4-1 1v23.9c0 2.9 2.4 5.3 5.3 5.3V61c0 .6.4 1 1 1s1-.4 1-1v-5.5h37.7V61c0 .6.4 1 1 1s1-.4 1-1v-5.5c2.9 0 5.3-2.4 5.3-5.3V26.3c.1-.6-.4-1-.9-1m-44.3-18c0-1.8 1.5-3.3 3.4-3.3h31.5c1.8 0 3.3 1.5 3.3 3.3v17.9H47c-.6 0-1 .4-1 1v12.5H18V26.3c0-.6-.4-1-1-1h-4v-18zm43.3 42.9c0 1.8-1.5 3.4-3.3 3.4H11.2c-1.8 0-3.4-1.5-3.4-3.4V27.3H16v12.5c0 .6.4 1 1 1h30c.6 0 1-.4 1-1V27.3h8.1v22.9z" />
  </svg>
);
export default IconSofa5;
