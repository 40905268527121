import * as React from "react";
const IconCodeAltCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 7.9H7c-2.8 0-5 2.3-5 5v23.7c0 2.8 2.3 5 5 5h24v12.5h-8.2c-.6 0-1 .4-1 1s.4 1 1 1h18.4c.6 0 1-.4 1-1s-.4-1-1-1H33V41.6h24c2.8 0 5-2.3 5-5V12.9c0-2.8-2.3-5-5-5m3 28.7c0 1.7-1.4 3-3 3H7c-1.7 0-3-1.4-3-3V12.9c0-1.7 1.4-3 3-3h50c1.7 0 3 1.4 3 3z" />
    <path d="M44.7 18c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l4.8 4.8-4.8 4.8c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l5.2-5.2c.5-.5.5-1.4 0-1.9zM15.9 24.7l4.7-4.7c.4-.4.4-1 0-1.4s-1-.4-1.4 0L14 23.8c-.5.5-.5 1.4 0 1.9l5.3 5.3c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4zM34.7 17.8c-.5-.2-1.1 0-1.3.6l-4.7 12c-.2.5.1 1.1.6 1.3.1 0 .2.1.4.1.4 0 .8-.2.9-.6l4.7-12c.2-.6-.1-1.2-.6-1.4" />
  </svg>
);
export default IconCodeAltCopy;
