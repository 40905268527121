import * as React from "react";
const IconDropCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M47.9 25.6C45.1 18.4 40.1 10.2 34.3 3c-.5-.6-1.3-1-2.2-1s-1.7.4-2.2 1.1c-5.5 7-10.5 15.1-13.6 22.2-2.5 5.8-3.8 11.4-3.8 16.4v2c.2 10.1 9 18.3 19.6 18.3 10.7 0 19.4-8.1 19.5-18.1v-2c0-5-1.3-10.3-3.7-16.3m1.7 18.2c-.1 9-8 16.2-17.5 16.2s-17.4-7.3-17.6-16.3v-2c0-4.8 1.2-10 3.7-15.6 3-7 7.8-14.9 13.3-21.8q.15-.3.6-.3c.3 0 .5.1.7.3 5.7 7 10.5 15.1 13.3 22.1 2.4 5.8 3.5 10.8 3.5 15.5z" />
  </svg>
);
export default IconDropCopy;
