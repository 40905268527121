import * as React from "react";
const IconForward = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.1 4c0-1.2-1-2.3-2.3-2.3s-2.3 1-2.3 2.3v24.9L16.2 4.5c-2-1.3-4.6-1.4-6.8-.3C7.3 5.4 5.9 7.6 5.9 10v44c0 2.4 1.3 4.6 3.5 5.8 1 .5 2.1.8 3.2.8 1.3 0 2.5-.4 3.6-1.1l37.4-24.4V60c0 1.2 1 2.3 2.3 2.3s2.3-1 2.3-2.3V4zM13.7 55.7c-.7.4-1.5.5-2.2.1-.3-.2-1.1-.7-1.1-1.8V10c0-1.1.8-1.6 1.1-1.8s.7-.3 1-.3c.4 0 .8.1 1.2.4L50.1 32z" />
  </svg>
);
export default IconForward;
