import * as React from "react";
const IconFrog = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55 26.9c1.6-1.9 2.5-4.4 2.5-7 0-6.1-5-11.1-11.1-11.1s-11.1 5-11.1 11.1c0 .3 0 .7.1 1-2.1-.1-4.4-.1-6.5 0 0-.3.1-.7.1-1 0-6.1-5-11.1-11.1-11.1s-11.1 5-11.1 11.1c0 2.6.9 5 2.5 7C4.5 30 2 33.9 2 38c0 9.5 13.5 17.2 30 17.2S62 47.5 62 38c0-4.1-2.5-8-7-11.1m-8.7-16.2c5 0 9.1 4.1 9.1 9.1S51.3 29 46.3 29s-9.1-4.1-9.1-9.1 4.1-9.2 9.1-9.2m-28.6.1c5 0 9.1 4.1 9.1 9.1 0 .7-.1 1.3-.2 1.9C25.7 26 22 29 17.7 29c-2.4 0-4.7-.9-6.4-2.6-1.8-1.7-2.7-4-2.7-6.5 0-5.1 4-9.1 9.1-9.1M32 53.3C16.6 53.3 4 46.4 4 38c0-3.5 2.3-6.9 6.5-9.7 2 1.7 4.5 2.7 7.2 2.7 5 0 9.4-3.4 10.7-8.1 2.3-.2 4.9-.2 7.2 0C37 27.6 41.2 31 46.3 31c2.8 0 5.3-1 7.2-2.7 4.2 2.8 6.5 6.2 6.5 9.7 0 8.4-12.6 15.3-28 15.3" />
    <path d="M47.8 39.7c-4.4 2.2-10 3.4-15.9 3.4-6.1 0-11.9-1.3-16.3-3.6-.5-.3-1.1-.1-1.4.4s-.1 1.1.4 1.4c4.7 2.5 10.8 3.9 17.3 3.9 6.2 0 12.1-1.3 16.8-3.6.5-.2.7-.8.4-1.3-.2-.6-.8-.8-1.3-.6" />
  </svg>
);
export default IconFrog;
