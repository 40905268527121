import * as React from "react";
const IconListCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M15.8 12.9H61c.6 0 1-.4 1-1s-.4-1-1-1H15.8c-.6 0-1 .4-1 1s.5 1 1 1M61 31H15.8c-.6 0-1 .4-1 1s.4 1 1 1H61c.6 0 1-.4 1-1s-.4-1-1-1M61 51.1H15.8c-.6 0-1 .4-1 1s.4 1 1 1H61c.6 0 1-.4 1-1s-.4-1-1-1M5.8 9.1C4.2 9.1 3 10.4 3 11.9s1.2 2.8 2.8 2.8 2.8-1.2 2.8-2.8-1.3-2.8-2.8-2.8M5.8 29.2C4.2 29.2 3 30.5 3 32s1.2 2.8 2.8 2.8 2.8-1.2 2.8-2.8c0-1.5-1.3-2.8-2.8-2.8M5.8 49.3c-1.5 0-2.8 1.2-2.8 2.8s1.2 2.8 2.8 2.8 2.8-1.2 2.8-2.8-1.3-2.8-2.8-2.8" />
  </svg>
);
export default IconListCopy;
