import * as React from "react";
const IconControlPanelCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M49.1 2H14.9c-2.8 0-5 2.2-5 5v50c0 2.8 2.2 5 5 5h34.2c2.8 0 5-2.2 5-5V7c0-2.8-2.2-5-5-5m3 55c0 1.7-1.3 3-3 3H14.9c-1.7 0-3-1.3-3-3V7c0-1.7 1.3-3 3-3h34.2c1.7 0 3 1.3 3 3z" />
    <path d="M42.6 15.2H25.1v-1.6c0-.6-.4-1-1-1s-1 .4-1 1v1.6h-1.6c-.6 0-1 .4-1 1s.4 1 1 1h1.6v1.6c0 .6.4 1 1 1s1-.4 1-1v-1.6h17.5c.6 0 1-.4 1-1s-.5-1-1-1M42.6 31H41v-1.6c0-.6-.4-1-1-1s-1 .4-1 1V31H21.4c-.6 0-1 .4-1 1s.4 1 1 1h17.5v1.6c0 .6.4 1 1 1s1-.4 1-1V33h1.6c.6 0 1-.4 1-1s-.4-1-.9-1M42.6 46.8H25.1v-1.6c0-.6-.4-1-1-1s-1 .4-1 1v1.6h-1.6c-.6 0-1 .4-1 1s.4 1 1 1h1.6v1.6c0 .6.4 1 1 1s1-.4 1-1v-1.6h17.5c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconControlPanelCopy;
