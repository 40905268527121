import * as React from "react";
const IconIUtensils = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M13.5 11a3.5 3.5 0 0 1 3.5 3.5V40l-3.5 4-3.5-4V14.5a3.5 3.5 0 0 1 3.5-3.5m0 2a1.5 1.5 0 0 1 1.5 1.5V17h-3v-2.5a1.5 1.5 0 0 1 1.5-1.5m0 27.963L12 39.248V19h3v20.249zM33 28a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h3a2 2 0 0 0 2-2V30a2 2 0 0 0-2-2zm3 2h-3v5h3zm0 7h-3v5h3zM29 4H19v40h10zm-8 2h6v2h-4v2h4v3h-2v2h2v3h-2v2h2v3h-4v2h4v3h-2v2h2v3h-2v2h2v3h-4v2h4v2h-6z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconIUtensils;
