import * as React from "react";
const IconFish = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M36.9 46.2c-7.7 0-14.8-2.8-19.7-7.7L4.3 42.8c-.4.1-.8 0-1.1-.3s-.3-.8-.1-1.1L9 32l-5.8-9.3c-.2-.4-.2-.8.1-1.2.3-.3.7-.5 1.1-.3l12.9 4.4c4.9-4.9 12-7.7 19.7-7.7 10.7 0 20.1 5.4 24 13.7.1.3.1.6 0 .8-4 8.4-13.4 13.8-24.1 13.8m-19.4-9.9c.3 0 .5.1.7.3 4.5 4.8 11.4 7.5 18.7 7.5 9.6 0 18.4-4.9 22-12.2-3.6-7.3-12.4-12.1-22-12.1-7.4 0-14.2 2.7-18.7 7.5-.3.3-.7.4-1 .3L6.3 24l4.7 7.5c.2.3.2.7 0 1.1L6.3 40l10.8-3.7z" />
  </svg>
);
export default IconFish;
