import * as React from "react";
const IconStarLarge = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="m24 9.762-3.89 7.92a3 3 0 0 1-2.265 1.65l-8.717 1.273 6.314 6.185a3.02 3.02 0 0 1 .862 2.66l-1.489 8.722 7.782-4.111a3 3 0 0 1 2.806 0l7.782 4.11-1.489-8.72a3.02 3.02 0 0 1 .862-2.661l6.315-6.185-8.718-1.273a3 3 0 0 1-2.265-1.65zm.903-2.698a1.005 1.005 0 0 0-1.806 0L18.315 16.8c-.147.298-.43.505-.759.553L6.864 18.914c-.826.121-1.156 1.141-.558 1.726l7.738 7.579c.237.232.346.567.29.895l-1.827 10.7c-.141.827.722 1.458 1.461 1.068l9.564-5.053c.294-.155.644-.155.938 0l9.564 5.053c.739.39 1.602-.24 1.461-1.067l-1.827-10.7a1.01 1.01 0 0 1 .29-.896l7.738-7.579c.597-.585.268-1.605-.558-1.726l-10.693-1.56a1.01 1.01 0 0 1-.759-.554z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconStarLarge;
