import * as React from "react";
const IconSick1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M20.2 19.9c3.1 0 5.7 3 5.7 6.7 0 .6.4 1 1 1s1-.4 1-1c0-4.8-3.4-8.7-7.7-8.7s-7.7 3.9-7.7 8.7c0 .6.4 1 1 1s1-.4 1-1c0-3.7 2.6-6.7 5.7-6.7M43.8 17.9c-4.2 0-7.7 3.9-7.7 8.7 0 .6.4 1 1 1s1-.4 1-1c0-3.7 2.5-6.7 5.7-6.7 3.1 0 5.7 3 5.7 6.7 0 .6.4 1 1 1s1-.4 1-1c0-4.8-3.4-8.7-7.7-8.7" />
    <path d="M62 30.5C61.2 14.6 48 2 32 2S2.8 14.6 2 30.5V32c0 1.6.1 3.1.3 4.6C4.6 50.9 17 62 32 62s27.4-11.1 29.7-25.4c.2-1.5.3-3 .3-4.6zM32 60C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
    <path d="M44.1 36.3H19.9c-2.6 0-4.7 2.1-4.7 4.7v3.1c0 2.6 2.1 4.7 4.7 4.7H44c2.6 0 4.7-2.1 4.7-4.7V41c.1-2.6-2-4.7-4.6-4.7m2.7 7.8c0 1.5-1.2 2.7-2.7 2.7H19.9c-1.5 0-2.7-1.2-2.7-2.7V41c0-1.5 1.2-2.7 2.7-2.7H44c1.5 0 2.7 1.2 2.7 2.7v3.1z" />
  </svg>
);
export default IconSick1;
