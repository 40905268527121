import * as React from "react";
const IconSeo = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 6.6H7C3.6 6.6.7 9.4.7 12.9v26.4c0 3.4 2.8 6.3 6.3 6.3h22.8V53h-11c-1.2 0-2.3 1-2.3 2.3s1 2.3 2.3 2.3h26.4c1.2 0 2.3-1 2.3-2.3s-1-2.3-2.3-2.3H34.3v-7.4H57c3.4 0 6.3-2.8 6.3-6.3V12.8c0-3.4-2.9-6.2-6.3-6.2m1.8 32.6c0 1-.8 1.8-1.8 1.8H7c-1 0-1.8-.8-1.8-1.8V12.8c0-1 .8-1.8 1.8-1.8h50c1 0 1.8.8 1.8 1.8z" />
    <path d="M16.1 20.4h4c1.2 0 2.3-1 2.3-2.3s-1-2.3-2.3-2.3h-4c-3.4 0-6.2 2.8-6.2 6.2s2.8 6.2 6.2 6.2c.9 0 1.7.8 1.7 1.7s-.8 1.7-1.7 1.7h-4c-1.2 0-2.3 1-2.3 2.3 0 1.2 1 2.3 2.3 2.3h4c3.4 0 6.2-2.8 6.2-6.2s-2.8-6.2-6.2-6.2c-.9 0-1.7-.8-1.7-1.7s.8-1.7 1.7-1.7M31.9 20.4h4c1.2 0 2.3-1 2.3-2.3s-1-2.3-2.3-2.3h-4c-3.4 0-6.2 2.8-6.2 6.2v8c0 3.4 2.8 6.2 6.2 6.2h4c1.2 0 2.3-1 2.3-2.3 0-1.2-1-2.3-2.3-2.3h-4c-.9 0-1.7-.8-1.7-1.7v-1.7h5.7c1.2 0 2.3-1 2.3-2.3s-1-2.3-2.3-2.3h-5.7v-1.7c0-.8.8-1.5 1.7-1.5M48.9 15.9h-2.3c-2.8 0-5.1 2.3-5.1 5.1v10.2c0 2.8 2.3 5.1 5.1 5.1h2.3c2.8 0 5.1-2.3 5.1-5.1V20.9c-.1-2.8-2.3-5-5.1-5m.5 15.2c0 .3-.3.6-.6.6h-2.3c-.3 0-.6-.3-.6-.6V20.9c0-.3.3-.6.6-.6h2.3c.3 0 .6.3.6.6z" />
  </svg>
);
export default IconSeo;
