import * as React from "react";
const IconCoffeePot = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.2 27.6 58 7.5c.1-.3 0-.6-.2-.8s-.5-.4-.8-.4H8c-2.8 0-5 2.2-5 5v13.1c0 .6.4 1 1 1s1-.4 1-1V11.3c0-1.7 1.3-3 3-3h6.6l3.7 19.3c-4.4 4.3-6.8 10-6.8 16 0 3.9 1.1 7.8 3.1 11.2q.75 1.35 1.8 2.7c.2.2.5.4.8.4h38.2c.3 0 .6-.1.8-.4q1.05-1.35 1.8-2.7c2-3.4 3.1-7.3 3.1-11.2-.1-6.1-2.5-11.7-6.9-16m1.6-19.3-3.5 18.3H20.1L16.7 8.3zM20.1 28.6h32.3c4.3 4 6.6 9.3 6.6 14.9 0 1-.1 1.9-.2 2.9l-2.2-1.3c-4.2-2.5-9.4-3-13.9-1.2l-.2.1c-3.4 1.3-7.3 1.2-10.6-.3-3.8-1.7-7.9-1.8-11.8-.4l-6.5 2.4c-.1-.7-.1-1.4-.1-2.1 0-5.7 2.3-11 6.6-15m36.1 25.1c-.4.7-.9 1.4-1.4 2H17.6c-.5-.7-1-1.4-1.4-2.1-1.1-1.9-1.9-3.9-2.3-6l6.9-2.5c3.4-1.2 7-1.1 10.3.4 3.8 1.7 8.2 1.8 12.1.3l.2-.1c4-1.5 8.5-1.1 12.2 1.1l2.8 1.7c-.5 1.8-1.2 3.6-2.2 5.2" />
  </svg>
);
export default IconCoffeePot;
