import * as React from "react";
const IconPhoneRing = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m60.5 45.9-9.1-6.1c-1.6-1.1-3.7-.6-5 1.3L44 44.4c-.4.6-1.2.8-1.8.4-12.4-7.8-20.2-18.7-23-23-.4-.6-.2-1.5.4-1.9l3.7-2.6c1.5-1.1 1.9-3.2.9-4.7l-6.1-9.2c-1-1.5-3-2-4.6-1.1L4.7 7.6c-.1 0-.1.1-.2.2-6.5 6.5-.2 22.9 14.3 37.4 6.8 6.8 14.4 12 21.3 14.8 3.4 1.3 6.4 2 9.1 2 2.9 0 5.3-.8 7-2.5l.2-.2 5.2-8.7c.8-1.6.4-3.7-1.1-4.7m-.7 3.6-5.1 8.6c-2.6 2.5-7.6 2.5-13.9 0-6.7-2.7-14-7.8-20.6-14.4C7.1 30.6.5 14.8 5.9 9.3l8.6-5.1c.6-.4 1.5-.2 1.9.4l6.1 9.1c.4.6.3 1.5-.4 1.9l-3.7 2.6c-1.5 1-1.9 3.1-.9 4.6 2.8 4.4 10.8 15.6 23.6 23.6 1.5.9 3.5.5 4.5-1l2.3-3.3c.7-.9 1.5-1.3 2.2-.8l9.1 6.1c.8.6 1 1.5.6 2.1M37.4 5.3C48.5 6 57.7 15 58.6 26.1c0 .5.5.9 1 .9h.1c.6 0 1-.5.9-1.1C59.6 13.7 49.7 4 37.5 3.3c-.5 0-1 .4-1.1.9 0 .6.4 1.1 1 1.1M36.5 15.3c6.2.7 11.1 5.6 11.8 11.8.1.5.5.9 1 .9h.1c.5-.1.9-.6.9-1.1-.8-7.1-6.4-12.7-13.5-13.6-.6-.1-1 .3-1.1.9-.1.5.3 1 .8 1.1" />
  </svg>
);
export default IconPhoneRing;
