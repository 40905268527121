import * as React from "react";
const IconChestOfDrawers1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.5 2h-49C4.5 2 2 4.5 2 7.5V61c0 .6.4 1 1 1s1-.4 1-1v-8.7h56V61c0 .6.4 1 1 1s1-.4 1-1V7.5c0-3-2.5-5.5-5.5-5.5M60 16.1v10.3H33V16.1zM31 26.4H4V16.1h27zm-27 2h27v9.9H4zm29 0h27v9.9H33zM7.5 4h49C58.4 4 60 5.6 60 7.5v6.6H4V7.5C4 5.6 5.6 4 7.5 4M4 50.3v-9.9h56v9.9z" />
  </svg>
);
export default IconChestOfDrawers1;
