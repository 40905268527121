import * as React from "react";
const IconGhana = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M24 7a1 1 0 0 0-1 1v2.98l-.857.124a13 13 0 0 0-8.952 5.644 13 13 0 0 0 8.952 20.089l.857.124V40a1 1 0 1 0 2 0v-3.04l.857-.123q.34-.05.68-.116a13 13 0 0 0 6.655-3.558 1 1 0 0 0-1.414-1.414 11 11 0 0 1-5.578 3l-1.2.244V12.948l1.2.245a11 11 0 0 1 5.578 3 1 1 0 0 0 1.414-1.415 13 13 0 0 0-7.335-3.674L25 10.98V8a1 1 0 0 0-1-1m-3 1a3 3 0 1 1 6 0v1.273a15 15 0 0 1 7.607 4.09 3 3 0 1 1-4.243 4.243A9 9 0 0 0 27 15.486v16.97a9 9 0 0 0 3.364-2.122 3 3 0 0 1 4.243 4.243A15 15 0 0 1 27 38.667V40a3 3 0 1 1-6 0v-1.333a15 15 0 0 1-9.472-6.363A15 15 0 0 1 21 9.274zm2 4.948v22.045l-1.2-.245a11 11 0 0 1 0-21.555zm-2 2.537q-.224.08-.444.17a9 9 0 0 0 .444 16.8z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconGhana;
