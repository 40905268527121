import * as React from "react";
const IconLike2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.7 49.1h-3v-4.4c0-3.5-2.8-6.3-6.3-6.3s-6.3 2.8-6.3 6.3v4.4h-3c-.6 0-1 .4-1 1V61c0 .6.4 1 1 1h18.7c.6 0 1-.4 1-1V50.1c-.1-.5-.5-1-1.1-1m-13.6-4.4c0-2.4 1.9-4.3 4.3-4.3s4.3 1.9 4.3 4.3v4.4h-8.6zM56.7 60H40v-8.9h16.7z" />
    <path d="M38.9 20.4h11c1.4 0 2.8.5 3.9 1.5 1.6 1.4 2.4 3.6 2.1 5.8-.1.5.3 1.1.8 1.1h.2c.5 0 .9-.3 1-.8.5-2.9-.6-5.8-2.8-7.6-1.5-1.2-3.3-1.9-5.2-1.9h-10v-6.9c0-3.7-1.1-6.4-3.3-8-1.4-1-3.1-1.5-5-1.5s-3.3.5-3.3.5c-.9.3-1.6 1.2-1.6 2.2v8.9c0 5.1-5.1 7.5-7 8.3v-.9c0-.6-.4-1-1-1H9.3c-2.2 0-4 1.8-4 4v26c0 2.2 1.8 4 4 4h9.4c.6 0 1-.4 1-1V52q1.95 1.2 4.2 1.2h5.2c.6 0 1-.4 1-1s-.4-1-1-1h-5.2c-1.5 0-3-.6-4.2-1.7V23.9c1.3-.4 9-3.2 9-10.4V4.6c0-.1.1-.2.2-.2 0 0 1.2-.4 2.6-.4 1.5 0 2.8.4 3.9 1.1 1.7 1.2 2.5 3.4 2.5 6.4v7.9c0 .6.5 1 1 1M17.7 51.9H9.3c-1.1 0-2-.9-2-2v-26c0-1.1.9-2 2-2h8.4z" />
  </svg>
);
export default IconLike2;
