import * as React from "react";
const IconSofa9 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.7 25.3v-3.1c0-3.7-3-6.8-6.8-6.8H14c-3.7 0-6.8 3-6.8 6.8v3.1c-2.9 0-5.3 2.4-5.3 5.3v8.1c0 2.9 2.4 5.3 5.3 5.3h2.2c0 .1-.1.2-.1.3v3.1c0 .6.4 1 1 1s1-.4 1-1v-3.1c0-.1 0-.2-.1-.3h41.3c0 .1-.1.2-.1.3v3.1c0 .6.4 1 1 1s1-.4 1-1v-3.1c0-.1 0-.2-.1-.3h2.2c2.9 0 5.3-2.4 5.3-5.3v-8.1c.2-2.9-2.2-5.3-5.1-5.3m-47.4-.2v-2.9c0-2.6 2.1-4.8 4.8-4.8h36c2.6 0 4.8 2.1 4.8 4.8v3.1h-3c-.6 0-1 .4-1 1V32H13.2v-5.7c0-.6-.4-1-1-1h-3s.1-.1.1-.2M60 38.8c0 1.8-1.5 3.3-3.3 3.3H7.3c-1.8 0-3.3-1.5-3.3-3.3v-8.1c0-1.8 1.5-3.3 3.3-3.3h3.9v5.7c0 .6.4 1 1 1h39.6c.6 0 1-.4 1-1v-5.7h3.9c1.8 0 3.3 1.5 3.3 3.3z" />
  </svg>
);
export default IconSofa9;
