import * as React from "react";
const IconAirConditionerAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 17.7H6c-2.2 0-4 1.8-4 4v20.6c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V21.7c0-2.2-1.8-4-4-4m-52 2h52c1.1 0 2 .9 2 2v13H4v-13c0-1.1.9-2 2-2m25 17v7.6H18.5v-7.6zm2 0h12.5v7.6H33zM4 42.3v-5.6h12.5v7.6H6c-1.1 0-2-.9-2-2m54 2H47.5v-7.6H60v5.6c0 1.1-.9 2-2 2" />
    <path d="M45.7 28.2h6.4c.6 0 1-.4 1-1s-.4-1-1-1h-6.4c-.6 0-1 .4-1 1s.4 1 1 1" />
  </svg>
);
export default IconAirConditionerAlt;
