import * as React from "react";
const IconCropAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 53.1h-5.4V13.9c0-2.8-2.2-5-5-5H10.4V3c0-.6-.4-1-1-1s-1 .4-1 1v5.9H3c-.6 0-1 .4-1 1s.4 1 1 1h5.4V50c0 2.8 2.2 5 5 5h40.2v6c0 .6.4 1 1 1s1-.4 1-1v-5.9H61c.6 0 1-.4 1-1s-.4-1-1-1m-50.6-3V10.9h40.2c1.7 0 3 1.3 3 3V53H13.4c-1.7.1-3-1.3-3-2.9" />
  </svg>
);
export default IconCropAlt;
