import * as React from "react";
const IconBread = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.8 62H12.9c-3 0-5.4-2.4-5.4-5.3V36.8c0-2.5.8-5.1 2.3-7.5 1.3-2.1 2-4.5 2-7v-1.8c-3-1.8-4.8-5-4.8-8.5 0-2.7 1-5.2 2.9-7 1.9-2 4.4-3 7.1-3h29.6c2.8 0 5.5 1.2 7.5 3.2 1.9 1.9 2.9 4.4 2.8 7s-1.1 5-2.9 6.8c-.7.7-1.4 1.2-2.2 1.6v2.2c0 2.2.7 4.3 2 6.5 1.5 2.4 2.3 5.2 2.3 8v19.4c.1 2.9-2.3 5.3-5.3 5.3M17 4c-2.1 0-4.1.8-5.6 2.3s-2.3 3.5-2.3 5.6c0 3 1.6 5.7 4.3 7 .3.2.5.5.5.9v2.4c0 2.9-.8 5.6-2.3 8-1.3 2.1-2 4.3-2 6.5v19.9c0 1.8 1.5 3.3 3.4 3.3h37.9c1.8 0 3.4-1.5 3.4-3.3V37.3c0-2.5-.7-4.9-2-7-1.5-2.5-2.3-5-2.3-7.5V20q0-.6.6-.9c.8-.4 1.5-.9 2.2-1.5 1.5-1.4 2.3-3.4 2.3-5.4s-.8-4-2.3-5.6C51.1 4.9 48.9 4 46.6 4z" />
  </svg>
);
export default IconBread;
