import * as React from "react";
const IconPieChartDollar = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.3 31.1c0-.5-.5-.9-1-.9H33.9V4.8c0-.5-.4-1-1-1H32c-16.2 0-29.3 13-29.3 29.1C2.7 48.9 15.8 62 32 62s29.3-13.1 29.3-29.1zM31.9 5.8v24.9c-.1 0-.1.1-.2.1L10.1 49.1C6.7 44.6 4.7 39 4.7 32.9 4.7 18 16.9 5.8 31.9 5.8M32 60c-8.2 0-15.6-3.6-20.6-9.4L33 32.2l.1-.1h26.2v.8C59.3 47.8 47.1 60 32 60" />
    <path d="M53.4 19.9h-6.9c-.6 0-1 .4-1 1s.4 1 1 1h5.4v2.2c0 .6.4 1 1 1s1-.4 1-1v-2.2c2.4-.2 4.3-2.2 4.3-4.6 0-2.6-2.1-4.7-4.7-4.7h-3.2c-1.5 0-2.7-1.2-2.7-2.7s1.2-2.7 2.7-2.7h6.9c.6 0 1-.4 1-1s-.4-1-1-1h-3.3V3c0-.6-.4-1-1-1s-1 .4-1 1v2.2h-1.7c-2.6 0-4.7 2.1-4.7 4.7s2.1 4.7 4.7 4.7h3.2c1.5 0 2.7 1.2 2.7 2.7s-1.2 2.6-2.7 2.6" />
  </svg>
);
export default IconPieChartDollar;
