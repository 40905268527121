import * as React from "react";
const IconLinkAlt3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m59.2 11.6-6.8-6.8c-2.4-2.4-6.4-2.4-8.8 0l-9.4 9.4c-.4.4-.4 1 0 1.4s1 .4 1.4 0L45 6.2c.8-.8 1.9-1.2 3-1.2s2.2.4 3 1.2l6.8 6.8c1.6 1.6 1.6 4.3 0 6L38.3 38.4c-1.6 1.6-4.3 1.6-6 0l-3.5-3.5c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l3.5 3.5c1.2 1.2 2.8 1.8 4.4 1.8s3.2-.6 4.4-1.8l19.4-19.4c2.5-2.4 2.5-6.4.1-8.8" />
    <path d="M28.4 48.4 19 57.8c-1.6 1.6-4.3 1.6-6 0L6.2 51c-1.6-1.6-1.6-4.3 0-6l19.4-19.4c.8-.8 1.9-1.2 3-1.2s2.2.4 3 1.2l3.5 3.5c.4.4 1 .4 1.4 0s.4-1 0-1.4L33 24.2c-1.2-1.2-2.7-1.8-4.4-1.8s-3.2.6-4.4 1.8L4.8 43.6c-2.4 2.4-2.4 6.4 0 8.8l6.8 6.8c1.2 1.2 2.8 1.8 4.4 1.8s3.2-.6 4.4-1.8l9.4-9.4c.4-.4.4-1 0-1.4s-1-.4-1.4 0" />
  </svg>
);
export default IconLinkAlt3;
