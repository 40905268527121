import * as React from "react";
const IconMobileTarget = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M34.8 51.5c-.6 0-1 .4-1 1 0 2.6-2.1 4.7-4.7 4.7H8.7c-2.6 0-4.7-2.1-4.7-4.7v-41c0-2.6 2.1-4.7 4.7-4.7h20.5c2.6 0 4.7 2.1 4.7 4.7v1.4c0 .6.4 1 1 1s1-.4 1-1v-1.4c0-3.7-3-6.7-6.7-6.7H8.7C5 4.9 2 7.8 2 11.5v41c0 3.7 3 6.7 6.7 6.7h20.5c3.7 0 6.7-3 6.7-6.7-.1-.6-.5-1-1.1-1" />
    <path d="M20.3 13.7c.6 0 1-.4 1-1s-.4-1-1-1h-2.9c-.6 0-1 .4-1 1s.4 1 1 1zM15.7 50.8c0 .6.4 1 1 1H21c.6 0 1-.4 1-1s-.4-1-1-1h-4.3c-.6 0-1 .5-1 1M49.5 30.1c-.6 0-1 .4-1 1 0 5.5-4.5 10.1-10.1 10.1s-10.1-4.5-10.1-10.1c0-5.5 4.5-10.1 10.1-10.1 1 0 1.9.1 2.9.4.5.2 1.1-.1 1.2-.7.2-.5-.1-1.1-.7-1.2-1.1-.3-2.3-.5-3.4-.5-6.6 0-12.1 5.4-12.1 12.1s5.4 12.1 12.1 12.1c6.6 0 12.1-5.4 12.1-12.1 0-.6-.4-1-1-1" />
    <path d="M61 16.6h-6.1v-5.5c0-.6-.4-1-1-1s-1 .4-1 1V17L39 29.4c-.4.4-.5 1-.1 1.4.2.2.5.3.7.3s.5-.1.7-.3l13.9-12.3H61c.6 0 1-.4 1-1s-.4-.9-1-.9M35 31.1c0-.6-.4-1-1-1s-1 .4-1 1c0 3 2.4 5.4 5.4 5.4.6 0 1-.4 1-1s-.4-1-1-1c-1.8 0-3.4-1.5-3.4-3.4" />
  </svg>
);
export default IconMobileTarget;
