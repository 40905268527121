import * as React from "react";
const IconCalculatorAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.7 2H12.3c-2.8 0-5 2.2-5 5v50c0 2.8 2.2 5 5 5h39.5c2.8 0 5-2.2 5-5V7c-.1-2.8-2.3-5-5.1-5m3 55c0 1.7-1.3 3-3 3H12.3c-1.7 0-3-1.3-3-3V7c0-1.7 1.3-3 3-3h39.5c1.7 0 3 1.3 3 3v50z" />
    <path d="M19.5 24.1h25.1c1.7 0 3-1.3 3-3v-7.9c0-1.7-1.3-3-3-3H19.5c-1.7 0-3 1.3-3 3v7.9c0 1.7 1.3 3 3 3m-1-10.9c0-.6.4-1 1-1h25.1c.6 0 1 .4 1 1v7.9c0 .6-.4 1-1 1H19.5c-.6 0-1-.4-1-1zM19.2 29.4H18c-1.1 0-2.1.9-2.1 2.1v1.1c0 1.1.9 2.1 2.1 2.1h1.1c1.1 0 2.1-.9 2.1-2.1v-1.1c0-1.2-.9-2.1-2-2.1M19.2 39.9H18c-1.1 0-2.1.9-2.1 2.1v1.1c0 1.1.9 2.1 2.1 2.1h1.1c1.1 0 2.1-.9 2.1-2.1V42c0-1.2-.9-2.1-2-2.1M19.2 50.5H18c-1.1 0-2.1.9-2.1 2.1v1.1c0 1.1.9 2.1 2.1 2.1h1.1c1.1 0 2.1-.9 2.1-2.1v-1.1c0-1.2-.9-2.1-2-2.1M28.1 29.4H27c-1.1 0-2.1.9-2.1 2.1v1.1c0 1.1.9 2.1 2.1 2.1h1.1c1.1 0 2.1-.9 2.1-2.1v-1.1c0-1.2-1-2.1-2.1-2.1M28.1 39.9H27c-1.1 0-2.1.9-2.1 2.1v1.1c0 1.1.9 2.1 2.1 2.1h1.1c1.1 0 2.1-.9 2.1-2.1V42c0-1.2-1-2.1-2.1-2.1M28.1 50.5H27c-1.1 0-2.1.9-2.1 2.1v1.1c0 1.1.9 2.1 2.1 2.1h1.1c1.1 0 2.1-.9 2.1-2.1v-1.1c0-1.2-1-2.1-2.1-2.1M37 29.4h-1.1c-1.1 0-2.1.9-2.1 2.1v1.1c0 1.1.9 2.1 2.1 2.1H37c1.1 0 2.1-.9 2.1-2.1v-1.1c0-1.2-.9-2.1-2.1-2.1M37 39.9h-1.1c-1.1 0-2.1.9-2.1 2.1v1.1c0 1.1.9 2.1 2.1 2.1H37c1.1 0 2.1-.9 2.1-2.1V42c0-1.2-.9-2.1-2.1-2.1M37 50.5h-1.1c-1.1 0-2.1.9-2.1 2.1v1.1c0 1.1.9 2.1 2.1 2.1H37c1.1 0 2.1-.9 2.1-2.1v-1.1c0-1.2-.9-2.1-2.1-2.1M46 29.4h-1.1c-1.1 0-2.1.9-2.1 2.1v1.1c0 1.1.9 2.1 2.1 2.1H46c1.1 0 2.1-.9 2.1-2.1v-1.1c-.1-1.2-1-2.1-2.1-2.1M46 39.9h-1.1c-1.1 0-2.1.9-2.1 2.1v1.1c0 1.1.9 2.1 2.1 2.1H46c1.1 0 2.1-.9 2.1-2.1V42c-.1-1.2-1-2.1-2.1-2.1M46 50.5h-1.1c-1.1 0-2.1.9-2.1 2.1v1.1c0 1.1.9 2.1 2.1 2.1H46c1.1 0 2.1-.9 2.1-2.1v-1.1c-.1-1.2-1-2.1-2.1-2.1" />
  </svg>
);
export default IconCalculatorAlt2;
