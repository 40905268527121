import React from "react";
import PropTypes from "prop-types";
import BringzzButton from "../BringzzButton";
import BringzzText from "../BringzzText";

/**
 * @typedef BringzzCallToActionProps
 * @property {string} [classNames] - Additional CSS classes to apply to the component.
 * @property {string} [image] - URL of the image to be displayed in the component.
 * @property {React.ReactNode} text - Text content to be displayed as the main message of the call to action.
 * @property {{ className: string, onClick: Function, label: React.ReactNode }} [primaryButton] - Configuration for the primary button, including its CSS classes, click handler, and label.
 * @property {{ className: string, onClick: Function, label: React.ReactNode }} [secondaryButton] - Configuration for the secondary button, similar to the primary button.
 */

const BringzzCallToAction = ({
  classNames,
  image,
  text,
  primaryButton,
  secondaryButton,
  ...props
}) => {
  return (
    <div className="flex pl-7 h-64 xl:px-10 bg-magic-lilac relative">
      {/* Left Part */}
      <div className="w-1/2  sm:w-2/3  flex flex-col justify-center space-y-4">
        <div>
          <BringzzText tag="h1">{text}</BringzzText>
        </div>
        <div className="flex space-x-3">
          {primaryButton && (
            <BringzzButton
              className={primaryButton.className}
              onClick={primaryButton.onClick}
            >
              {primaryButton.label}
            </BringzzButton>
          )}
          {secondaryButton && (
            <BringzzButton
              className={secondaryButton.className}
              onClick={secondaryButton.onClick}
            >
              {secondaryButton.label}
            </BringzzButton>
          )}
        </div>
      </div>
      {image && (
        <img
          className="absolute right-0 bottom-0 m-auto h-full"
          src={image}
          alt="call to action img"
        />
      )}
    </div>
  );
};

BringzzCallToAction.propTypes = {
  classNames: PropTypes.string,
  image: PropTypes.string,
  text: PropTypes.node.isRequired,
  primaryButton: PropTypes.shape({
    className: PropTypes.string,
    onClick: PropTypes.func,
    label: PropTypes.node.isRequired,
  }),
  secondaryButton: PropTypes.shape({
    className: PropTypes.string,
    onClick: PropTypes.func,
    label: PropTypes.node.isRequired,
  }),
};

export default BringzzCallToAction;
