import * as React from "react";
const IconTargetAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M23 58.5C11.6 54.7 4 44 4 32 4 16.6 16.6 4 32 4c11.5 0 22 7.2 26.1 17.9.2.5.8.8 1.3.6s.8-.8.6-1.3C55.6 9.7 44.3 2 32 2 15.5 2 2 15.5 2 32c0 12.9 8.2 24.3 20.3 28.4.1 0 .2.1.3.1.4 0 .8-.3.9-.7.3-.5 0-1.1-.5-1.3" />
    <path d="M32 23.5q2.85 0 5.1 1.8c.4.3 1.1.3 1.4-.2.3-.4.3-1.1-.2-1.4-1.8-1.4-4-2.2-6.3-2.2-5.7 0-10.3 4.6-10.3 10.3 0 2.3.8 4.5 2.2 6.4.2.3.5.4.8.4.2 0 .4-.1.6-.2.4-.3.5-1 .2-1.4-1.2-1.5-1.8-3.3-1.8-5.1 0-4.7 3.7-8.4 8.3-8.4M45.8 29.5c-9 0-16.2 7.3-16.2 16.2S36.8 62 45.8 62 62 54.7 62 45.8s-7.3-16.3-16.2-16.3m0 30.5c-7.9 0-14.2-6.4-14.2-14.2S38 31.6 45.8 31.6 60 37.9 60 45.8 53.6 60 45.8 60" />
    <path d="M24.5 48.3c-6.5-2.9-10.6-9.4-10.6-16.5 0-10 8.2-18.2 18.2-18.2 7.1 0 13.6 4.2 16.6 10.7.2.5.8.7 1.3.5s.7-.8.5-1.3C47.2 16.3 39.9 11.6 32 11.6c-11.1 0-20.2 9-20.2 20.2 0 7.9 4.6 15.1 11.8 18.4.1.1.3.1.4.1q.6 0 .9-.6c.3-.5.1-1.1-.4-1.4M51.6 41.9l-7.4 7.4-3.3-3.3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l4 4c.2.2.5.3.7.3s.5-.1.7-.3l8.1-8.1c.4-.4.4-1 0-1.4s-1-.4-1.4 0" />
  </svg>
);
export default IconTargetAlt1;
