import * as React from "react";
const IconMobileAltRoundedBottom = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M44.2 2H19.8c-3.6 0-6.6 3-6.6 6.6v46.8c0 3.6 3 6.6 6.6 6.6h24.4c3.6 0 6.6-3 6.6-6.6V8.6c0-3.6-3-6.6-6.6-6.6M19.8 4h24.4c2.5 0 4.6 2.1 4.6 4.6v36.8c0 2.6-2.1 4.8-4.8 4.8H20c-2.6 0-4.8-2.1-4.8-4.8V8.6c0-2.5 2.1-4.6 4.6-4.6m24.4 56H19.8c-2.5 0-4.6-2.1-4.6-4.6v-5.2c1.2 1.2 2.9 2 4.8 2h24c1.9 0 3.5-.8 4.8-2v5.2c0 2.5-2.1 4.6-4.6 4.6" />
    <path d="M30.2 11.3h3.9c.6 0 1-.4 1-1s-.4-1-1-1h-3.9c-.6 0-1 .4-1 1s.4 1 1 1" />
  </svg>
);
export default IconMobileAltRoundedBottom;
