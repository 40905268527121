import * as React from "react";
const IconArrowLongDownL = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M43.8 47.5c-.4-.4-1-.4-1.4 0L33 57.2V5.4h4.4c.6 0 1-.4 1-1s-.4-1-1-1H26.6c-.6 0-1 .4-1 1s.4 1 1 1H31v51.8l-9.4-9.7c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l11.1 11.4c.2.2.4.3.7.3s.5-.1.7-.3l11.1-11.4c.4-.4.4-1 0-1.4" />
  </svg>
);
export default IconArrowLongDownL;
