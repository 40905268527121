import * as React from "react";
const IconNetworkAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.6 47.2c-.3 0-.6 0-.8.1l-17-32.2c1.6-1.4 2.7-3.4 2.7-5.7C39.4 5.3 36.1 2 32 2s-7.4 3.3-7.4 7.4c0 2.3 1 4.3 2.7 5.7l-17 32.2c-.3 0-.6-.1-.8-.1-4.1 0-7.4 3.3-7.4 7.4S5.3 62 9.4 62c3.6 0 6.6-2.6 7.3-6h30.7c.6 3.4 3.7 6 7.3 6 4.1 0 7.4-3.3 7.4-7.4s-3.4-7.4-7.5-7.4m-28-37.8C26.6 6.4 29 4 32 4s5.4 2.4 5.4 5.4-2.4 5.4-5.4 5.4-5.4-2.4-5.4-5.4m2.4 6.7c.9.4 1.9.7 3 .7q1.65 0 3-.6l16.8 31.7c-2.5 1-4.3 3.4-4.5 6.2H16.7c-.2-2.8-2-5.2-4.5-6.2zM9.4 60c-3 0-5.4-2.4-5.4-5.4s2.4-5.4 5.4-5.4 5.4 2.4 5.4 5.4S12.4 60 9.4 60m45.2 0c-3 0-5.4-2.4-5.4-5.4s2.4-5.4 5.4-5.4 5.4 2.4 5.4 5.4-2.4 5.4-5.4 5.4" />
  </svg>
);
export default IconNetworkAlt;
