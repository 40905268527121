import * as React from "react";
const IconWebhooks = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 65 64"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M30.719 27.2c-6.18-3.098-8.44-7.209-6.946-12.241 1.316-4.435 5.752-7.337 10.27-6.655 2.253.34 4.235 1.263 5.78 3.023 2.337 2.667 2.865 5.78 2.151 9.246l2.135.579 3.201.868c2.273-6.234-.393-13.518-6.214-17.258-6.061-3.893-13.914-3.071-19.062 2-2.687 2.649-4.158 5.88-4.5 9.62-.483 5.29 1.703 9.558 5.375 13.21l-5.84 9.793q-.283.02-.502.033c-.269.016-.48.029-.688.058-3.839.544-6.54 3.958-5.895 7.44.73 3.933 4.309 6.348 7.983 5.385 3.896-1.02 5.97-4.78 4.5-8.644-.532-1.398-.203-2.294.463-3.394 1.877-3.101 3.727-6.219 5.61-9.394zm13.222 4.686-5.647-9.96q.14-.349.272-.665v-.001c.185-.448.354-.858.495-1.277.747-2.21.296-4.228-1.122-6.02-1.736-2.194-4.764-2.991-7.345-2.004-2.605.997-4.272 3.554-4.158 6.383.115 2.86 2.034 5.414 5.008 5.929 1.78.308 2.652 1.154 3.442 2.61 1.68 3.1 3.42 6.165 5.162 9.233v.001q1.033 1.817 2.061 3.64c5.832-3.888 10.657-3.764 14.26.285 3.12 3.51 3.186 8.854.153 12.438-3.557 4.201-8.348 4.368-13.826.82l-4.352 3.642c5.546 5.536 13.463 6.272 19.723 1.963 6.099-4.199 8.222-12.258 5.116-19.063-2.57-5.633-9.737-10.895-19.242-7.954m-12.623 16.99H42.76q.238.321.455.63c.303.428.592.834.928 1.195 2.424 2.592 6.516 2.72 9.106.315 2.685-2.492 2.807-6.68.27-9.281-2.483-2.547-6.725-2.79-9.03-.094-1.4 1.639-2.835 1.831-4.694 1.802-3.397-.052-6.795-.042-10.193-.032q-2.045.007-4.088.008c.309 6.695-2.222 10.867-7.242 11.858-4.916.97-9.443-1.538-11.037-6.114-1.81-5.2.428-9.359 6.898-12.66-.487-1.763-.98-3.548-1.466-5.315C5.617 32.724.327 39.565.872 47.26c.483 6.793 5.963 12.827 12.665 13.907 3.64.588 7.06-.022 10.233-1.822 4.082-2.316 6.451-5.958 7.548-10.47"
      clipRule="evenodd"
    />
  </svg>
);
export default IconWebhooks;
