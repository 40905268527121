import * as React from "react";
const IconStove = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 2H6C3.8 2 2 3.8 2 6v52c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V6c0-2.2-1.8-4-4-4m2 56c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2h52c1.1 0 2 .9 2 2z" />
    <path d="M19.4 11.1c-3.8 0-6.9 3.1-6.9 6.9s3.1 6.9 6.9 6.9 6.9-3.1 6.9-6.9-3.1-6.9-6.9-6.9m0 11.9c-2.7 0-4.9-2.2-4.9-4.9s2.2-4.9 4.9-4.9 4.9 2.2 4.9 4.9-2.2 4.9-4.9 4.9M44.6 11.1c-3.8 0-6.9 3.1-6.9 6.9s3.1 6.9 6.9 6.9 6.9-3.1 6.9-6.9c.1-3.8-3-6.9-6.9-6.9m0 11.9c-2.7 0-4.9-2.2-4.9-4.9s2.2-4.9 4.9-4.9 4.9 2.2 4.9 4.9-2.1 4.9-4.9 4.9M19.4 33.4c-3.8 0-6.9 3.1-6.9 6.9s3.1 6.9 6.9 6.9 6.9-3.1 6.9-6.9-3.1-6.9-6.9-6.9m0 11.9c-2.7 0-4.9-2.2-4.9-4.9s2.2-4.9 4.9-4.9 4.9 2.2 4.9 4.9-2.2 4.9-4.9 4.9M44.6 33.4c-3.8 0-6.9 3.1-6.9 6.9s3.1 6.9 6.9 6.9 6.9-3.1 6.9-6.9-3-6.9-6.9-6.9m0 11.9c-2.7 0-4.9-2.2-4.9-4.9s2.2-4.9 4.9-4.9 4.9 2.2 4.9 4.9-2.1 4.9-4.9 4.9M39.7 52.7h-1.5c-.6 0-1 .4-1 1s.4 1 1 1h1.5c.6 0 1-.4 1-1s-.4-1-1-1M49 52.7h-1.5c-.6 0-1 .4-1 1s.4 1 1 1H49c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconStove;
