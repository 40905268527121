import * as React from "react";
const IconClean = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59 52.4h-1.9v-33c0-3-2.5-5.5-5.5-5.5H38.2c-1.9 0-3.5-1.6-3.5-3.5V8.1c0-3-2.5-5.5-5.5-5.5H13.6c-3 0-5.5 2.5-5.5 5.5v11.3h-.6c-3 0-5.5 2.5-5.5 5.5v21.8c0 .6.4 1 1 1h4c.7 4.3 4.4 7.6 8.8 7.6s8.2-3.3 8.8-7.6h15.6c3 0 5.5-2.5 5.5-5.5v-3.6c0-1.7-.8-3.3-2.1-4.3L25.8 20.6c-.9-.7-2.1-1.1-3.3-1.1H10.1V8.1c0-1.9 1.6-3.5 3.5-3.5h15.7c1.9 0 3.5 1.6 3.5 3.5v2.4c0 3 2.5 5.5 5.5 5.5h13.4c1.9 0 3.5 1.6 3.5 3.5v33h-1.9c-1.7 0-3 1.3-3 3v5c0 .6.4 1 1 1H61c.6 0 1-.4 1-1v-5c0-1.7-1.3-3.1-3-3.1m-43.1.9c-3.8 0-7-3.1-7-7 0-3.8 3.1-7 7-7s7 3.1 7 7c-.1 3.9-3.2 7-7 7m8.6-31.2 17.9 13.7c.9.7 1.4 1.7 1.4 2.8v3.6c0 1.9-1.6 3.5-3.5 3.5H24.8c-.3-4.6-4.2-8.3-8.9-8.3S7.3 41.1 7 45.7H4V24.9c0-1.9 1.6-3.5 3.5-3.5h14.9c.8 0 1.5.3 2.1.7M60 59.4h-7.8v-4c0-.6.4-1 1-1H59c.6 0 1 .4 1 1z" />
  </svg>
);
export default IconClean;
