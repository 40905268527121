import * as React from "react";
const IconBowAndArrow = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.7 3.3c-.2-.3-.6-.4-1-.3L47.1 6.3c-.3.1-.6.4-.7.7s0 .7.3 1l4 4-8.9 8.9c-9.2-8.7-22.6-11.5-34.5-7.4L4.8 11c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l2.9 2.9 20.5 20.5-6.7 6.7c-.1.1-.1.1-.1.2l-7.8.1c-.3 0-.5.1-.7.3l-7 7c-.3.3-.4.7-.2 1.1s.6.6 1 .6l7.5-.6-.4 7.8c0 .4.2.8.6 1 .1.1.3.1.4.1.3 0 .5-.1.7-.3l7-7c.2-.2.3-.5.3-.7l-.1-8c.1 0 .1-.1.2-.1l6.7-6.7 20.5 20.5 2.9 2.9c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-2.5-2.5c4.2-12 1.3-25.3-7.4-34.5l8.9-8.9 4 4c.2.2.4.3.7.3h.3c.3-.1.6-.4.7-.7L61 4.3c.1-.4 0-.8-.3-1M14.6 56.6l.3-6.4c0-.3-.1-.6-.3-.8s-.4-.3-.7-.3h-.1l-6 .5 4.8-4.8 6.7-.1.1 7zM8.8 15c11-3.5 23.1-.7 31.5 7.2L28.2 34.4zM49 55.2 29.6 35.8l12.2-12.2c7.9 8.5 10.7 20.6 7.2 31.6m7.2-40.5-6.9-6.9 9.3-2.4z" />
  </svg>
);
export default IconBowAndArrow;
