import * as React from "react";
const IconCupcakeAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.4 33.6c-.7-1-1.8-1.6-3.1-1.6H50c0-6.6-3.5-12.6-8.6-16 2.5-.6 4.3-2.8 4.3-5.4v-.5c.1 0 .2 0 .3-.1 1.6-.6 5.7-2.3 7.7-6.6.2-.5 0-1.1-.5-1.3s-1.1 0-1.3.5c-1.7 3.6-5.2 5.1-6.6 5.6-.1 0-.2.1-.2.1-.9-1.9-2.8-3.2-5.1-3.2-3.1 0-5.6 2.5-5.6 5.6 0 1 .3 2 .8 2.8-1.1-.2-2.2-.4-3.4-.4-9.8 0-18 8.7-18 19H9.6c-1.2 0-2.4.6-3.1 1.6S5.6 36 6 37.1l8.1 22.5c.5 1.5 1.9 2.5 3.5 2.5h28.7c1.6 0 3-1 3.5-2.5L57.9 37c.4-1.1.2-2.4-.5-3.4M40.2 7.1c2 0 3.6 1.6 3.6 3.6s-1.6 3.6-3.6 3.6-3.6-1.6-3.6-3.6 1.6-3.6 3.6-3.6m-8.2 8c8.5 0 16 7.9 16 17H16c0-9.1 7.5-17 16-17m1.5 19h11.4l-4 25.8v.2h-7.4zm-2 25.9h-7.4v-.2l-4-25.8h11.4zM16 58.9 8 36.4c-.2-.5-.1-1.1.2-1.6s.8-.7 1.4-.7h8.5v.1l4 25.8h-4.4c-.8 0-1.4-.5-1.7-1.1m40-22.5-8 22.5c-.2.7-.9 1.1-1.6 1.1H43l4-25.8v-.1h7.5c.6 0 1.1.3 1.4.7.2.5.3 1 .1 1.6" />
  </svg>
);
export default IconCupcakeAlt;
