import * as React from "react";
const IconArrowDoubleLeft = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M26.4 31H7.2l9.6-9.4c.4-.4.4-1 0-1.4s-1-.4-1.4 0L4 31.3c-.2.2-.3.4-.3.7s.1.5.3.7l11.4 11.1c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4L7.1 33h19.3c.6 0 1-.4 1-1s-.5-1-1-1M59.3 31H40l9.7-9.4c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-11.4 11c-.2.2-.3.4-.3.7s.1.5.3.7l11.4 11.2c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4L40.1 33h19.2c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconArrowDoubleLeft;
