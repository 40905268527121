import * as React from "react";
const IconCloudAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M46.8 51H16.7c-4.3 0-8.4-1.9-11.2-5.1s-3.9-7.3-3.3-11.5c.7-4.7 3.9-8.8 8.3-10.8 2.9-1.3 6.1-1.7 9.1-1 .5.1 1.1-.2 1.3-.6 2.3-5.4 7.6-8.9 13.5-8.9 5.8 0 11 3.3 13.4 8.5.2.4.6.7 1.1.8 8.1.9 13.8 7.8 13 15.7-.7 7.2-7.3 12.9-15.1 12.9M16.7 24.2c-1.8 0-3.6.4-5.3 1.2-3.8 1.7-6.6 5.2-7.2 9.3-.6 3.6.4 7.1 2.8 9.9s6 4.4 9.7 4.4h30.1c6.8 0 12.6-4.9 13.2-11.2.6-6.8-4.3-12.8-11.3-13.5-1.2-.1-2.2-.9-2.7-1.9-2-4.5-6.6-7.3-11.6-7.3-5.1 0-9.7 3-11.7 7.7-.6 1.4-2 2.1-3.5 1.8-.8-.3-1.7-.4-2.5-.4" />
  </svg>
);
export default IconCloudAlt2;
