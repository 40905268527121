import * as React from "react";
const IconHeartMonitorCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M42.5 17.8h-.9L38.7 4.9C38.4 3.2 36.9 2 35.1 2h-6.2c-1.8 0-3.3 1.2-3.7 2.9l-2.9 12.9h-.8c-2.8 0-5 2.2-5 5v18.4c0 2.8 2.2 5 5 5h.8l2.9 12.9c.4 1.7 1.9 2.9 3.7 2.9h6.2c1.8 0 3.3-1.2 3.7-2.9l2.9-12.9h.9c2.8 0 5-2.2 5-5V22.8c-.1-2.7-2.3-5-5.1-5M27.2 5.4c.1-.8.8-1.4 1.7-1.4h6.2c.8 0 1.5.6 1.7 1.4l2.8 12.5H24.4zm-5.7 14.4h21c1.7 0 3 1.3 3 3v11.1l-9.3-5.3c-.3-.2-.7-.2-1 0l-8.5 4.9-8.2-4.7v-5.9c0-1.7 1.3-3.1 3-3.1m15.3 38.8c-.2.8-.9 1.4-1.7 1.4h-6.2c-.8 0-1.5-.6-1.7-1.4l-2.8-12.5h15.2zm5.7-14.4h-21c-1.7 0-3-1.3-3-3V31l7.7 4.4c.3.2.7.2 1 0l8.5-4.9 9.8 5.6v5c0 1.7-1.3 3.1-3 3.1" />
  </svg>
);
export default IconHeartMonitorCopy;
