import * as React from "react";
const IconSmartwatchAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M44.6 13h-.8l-1.4-6.7C41.8 3.8 39.6 2 37 2H27c-2.5 0-4.8 1.8-5.3 4.3L20.3 13h-.8c-2.2 0-4 1.8-4 4v31c0 2.2 1.8 4 4 4h1l1.2 5.7c.5 2.5 2.8 4.3 5.3 4.3h10c2.6 0 4.8-1.8 5.3-4.3l1.2-5.7h1c2.2 0 4-1.8 4-4V17c.1-2.2-1.7-4-3.9-4m-21-6.3C24 5.1 25.4 4 27 4h10c1.6 0 3 1.1 3.4 2.7l1.3 6.3H22.3zm16.8 50.6C40 58.9 38.6 60 37 60H27c-1.6 0-3-1.1-3.4-2.7L22.5 52h19zm6.2-9.3c0 1.1-.9 2-2 2H19.4c-1.1 0-2-.9-2-2V17c0-1.1.9-2 2-2h25.1c1.1 0 2 .9 2 2v31z" />
    <path d="M33 32.1v-8.9c0-.6-.4-1-1-1s-1 .4-1 1v9.3c0 .3.1.5.3.7l5.8 5.8c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4z" />
  </svg>
);
export default IconSmartwatchAlt;
