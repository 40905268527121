import * as React from "react";
const IconBouquet = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.1 14.9c-.5-.4-1-.6-1.5-.7-.5-3.1-2.9-4.3-4.6-4.6-.2-1.4-.8-2.6-1.8-3.4-1.2-1-2.6-1.3-3.5-1.3C37.2 2.2 34.5 2 33.9 2c-.5-.1-1.4-.1-3.6 0-3 .2-4.4 2-5 3-3.6.3-4.9 2.8-5.3 4.7-3.1.5-4.3 2.8-4.6 4.6-.7.1-1.2.4-1.7.8-.8.9-.8 2-.7 2.1L14.7 41c.2 3.3 2 5.8 2.1 6 2.2 3 5.1 4.3 6.7 4.8l-3.4 8.9c-.1.3-.1.7.1.9.2.3.5.4.8.4h21.8c.3 0 .6-.2.8-.4s.2-.6.1-.9l-3.4-8.9c1.1-.3 2.9-1.1 5-2.8 3.5-3 3.8-7.8 3.8-8 .1-.9 1.7-21.7 1.7-23.9.2-1.2-.3-1.8-.7-2.2M21 11.6c.5 0 1-.5 1-1 0-.4.1-3.6 4-3.6.4 0 .8-.3.9-.7 0 0 .8-2.1 3.4-2.2 2.5-.1 3.1 0 3.2 0h.3c.1 0 2.4 0 3.3 2.3.2.4.6.6 1 .6 0 0 1.6-.1 2.7.9.7.6 1.1 1.6 1.2 2.8 0 .5.5.9 1 .9.3 0 2.8.1 3.5 2.6h-29c.3-1 1.1-2.5 3.5-2.6M22.5 60l3-8h12.8l3 8zm24.8-19.1s-.3 4.2-3.1 6.6c-2.9 2.4-5.1 2.6-5 2.6H25c-.5-.1-4.2-.9-6.5-4.2 0 0-1.5-2.2-1.7-4.9L15 17s0-.4.2-.6.6-.3 1.1-.2h31.5c.1 0 .7 0 1 .2.1 0 .2.2.2.7 0 2.1-1.7 23.6-1.7 23.8" />
  </svg>
);
export default IconBouquet;
