import * as React from "react";
const IconHourglassAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.4 60h-3.6V48.7c0-4.4-1.9-8.7-5.2-11.6l-4.3-3.9c-.5-.4-.7-1-.7-1.6s.2-1.2.7-1.6l4.3-3.9c3.3-3 5.2-7.2 5.2-11.6V4h3.6c.6 0 1-.4 1-1s-.4-1-1-1H9.6c-.6 0-1 .4-1 1s.4 1 1 1h3.6v10.5c0 4.4 1.9 8.7 5.2 11.6l4.3 3.9c.5.4.7 1 .7 1.6s-.2 1.2-.7 1.6l-4.3 3.9c-3.3 3-5.2 7.2-5.2 11.6V60H9.6c-.6 0-1 .4-1 1s.4 1 1 1h44.8c.6 0 1-.4 1-1s-.5-1-1-1M26.6 4h10.7v10.2q0 .9-.6 1.5L32 20.5l-4.7-4.7c-.4-.4-.6-1-.6-1.5V4zM15.2 48.7c0-3.9 1.6-7.5 4.5-10.1l4.3-3.9c.9-.8 1.4-1.9 1.4-3.1s-.5-2.3-1.4-3.1l-4.3-3.9c-2.9-2.6-4.5-6.3-4.5-10.1V4h9.4v10.2c0 1.1.4 2.2 1.2 3l5.4 5.4c.2.2.5.3.7.3s.5-.1.7-.3l5.4-5.4c.8-.8 1.2-1.9 1.2-3V4h9.4v10.5c0 3.9-1.6 7.5-4.5 10.1L40 28.5c-.9.8-1.4 1.9-1.4 3.1s.5 2.3 1.4 3.1l4.3 3.9c2.9 2.6 4.5 6.3 4.5 10.1V60H15.2z" />
  </svg>
);
export default IconHourglassAlt;
