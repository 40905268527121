import * as React from "react";
const IconSparkles = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M36.1 23.3c.3-.2.5-.5.5-.9s-.2-.7-.5-.9l-10-5.5-5.5-10c-.4-.6-1.4-.6-1.8 0l-5.5 10-10 5.5c-.1.2-.3.6-.3.9s.2.7.5.9l10 5.5 5.5 10c.2.3.5.5.9.5s.7-.2.9-.5l5.5-10zm-11.6 4.2-4.7 8.6-4.7-8.6c-.1-.2-.2-.3-.4-.4l-8.6-4.7 8.6-4.7c.2-.1.3-.2.4-.4l4.7-8.6 4.7 8.6c.1.2.2.3.4.4l8.6 4.7-8.6 4.7zM43.4 48.6 38.6 46l-2.7-4.9c-.2-.3-.5-.5-.9-.5s-.7.2-.9.5L31.5 46l-4.9 2.7c-.3.2-.5.5-.5.9s.2.7.5.9l4.9 2.7 2.7 4.9c.2.3.5.5.9.5s.7-.2.9-.5l2.7-4.9 4.9-2.7c.3-.2.5-.5.5-.9s-.3-.8-.7-1m-6 2.8c-.2.1-.3.2-.4.4l-2 3.5-1.9-3.5c-.1-.2-.2-.3-.4-.4l-3.5-1.9 3.5-1.9c.2-.1.3-.2.4-.4l1.9-3.5 1.9 3.5c.1.2.2.3.4.4l3.5 1.9zM60.5 13.6 55.6 11 53 6.1c-.3-.6-1.4-.6-1.8 0L48.6 11l-4.9 2.7c-.3.2-.5.5-.5.9s.2.7.5.9l4.9 2.7 2.7 4.9c.2.3.5.5.9.5s.7-.2.9-.5l2.7-4.9 4.9-2.7c.3-.2.5-.5.5-.9s-.4-.8-.7-1m-6.1 2.8c-.2.1-.3.2-.4.4l-1.9 3.5-1.9-3.5c-.1-.2-.2-.3-.4-.4l-3.5-1.9 3.5-1.9c.2-.1.3-.2.4-.4l1.9-3.5 1.9 3.5c.1.2.2.3.4.4l3.5 1.9z" />
  </svg>
);
export default IconSparkles;
