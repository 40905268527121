import * as React from "react";
const IconReplyCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.6 45.4c-1.1-5.5-2.9-9.1-6.8-13.1-5.8-5.8-13.7-9.5-22.2-10.6V10c0-.9-.5-1.8-1.4-2.2-.8-.4-1.8-.3-2.5.3L4 27.9c-1.2 1-2 2.5-2 4.1s.7 3.1 2 4.1L28.7 56c.4.4 1 .5 1.5.5.4 0 .7-.1 1-.2.8-.4 1.4-1.2 1.4-2.2V43.3c9.3.9 19 6.1 25.7 10.4.7.5 1.7.5 2.5.1s1.3-1.3 1.2-2.1c0-3-.2-5.1-.4-6.3M59.8 52c-.1 0-.2.1-.4 0-7.1-4.6-17.7-10.2-27.7-10.8-.3 0-.5.1-.7.3s-.3.5-.3.7V54c0 .2-.1.3-.2.4-.1 0-.3.1-.4-.1L5.2 34.5C4.4 33.9 4 33 4 32s.4-1.9 1.2-2.5L29.9 9.6c.1-.1.2-.1.2-.1h.2c.1 0 .2.1.2.4v12.7c0 .5.4.9.9 1 8.4.9 16.2 4.5 21.9 10.1 3.5 3.6 5.2 6.9 6.2 12 .2 1.1.3 3.1.4 5.9.1.3 0 .4-.1.4" />
  </svg>
);
export default IconReplyCopy;
