import * as React from "react";
const IconMosque = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 65"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.6 12.2v-4c0-.6-.4-1-1-1s-1 .4-1 1v4c-3.3 5.3-5.2 11.9-5.3 18.6h-6.9l1.2-2.8c1.8-4.2-.1-9.1-4.2-11.2-1.2-.6-2.2-1.5-2.9-2.7l-2.7-4.8c-.4-.6-1.4-.6-1.7 0l-2.7 4.8c-.7 1.2-1.7 2.1-2.9 2.7-4.1 2-5.9 6.9-4.2 11.2l1.2 2.8h-6.9c-.2-6.7-2-13.3-5.3-18.6v-4c0-.6-.4-1-1-1s-1 .4-1 1v4C4.9 17.7 3 24.7 3 31.8v25c0 .6.4 1 1 1h56c.6 0 1-.4 1-1v-25c0-7.1-1.9-14.1-5.4-19.6M40.1 55.4 32.9 41c-.2-.3-.5-.6-.9-.6q-.6 0-.9.6l-7.2 14.4c-.1.1-.1.3-.1.4h-8v-23h32.4v23h-8c0-.1 0-.3-.1-.4m-2 .4H25.9L32 43.7zM23.2 27.2c-1.4-3.3 0-7 3.2-8.6 1.6-.8 2.9-2 3.7-3.5l1.8-3.3 1.8 3.3c.9 1.5 2.2 2.7 3.7 3.5 3.2 1.6 4.6 5.3 3.2 8.6l-1.5 3.6H24.7zM5 31.8c0-6.2 1.5-12.4 4.4-17.4 2.8 5 4.4 11.2 4.4 17.4v24H5zm54 24h-8.7v-24c0-6.2 1.5-12.4 4.4-17.4 2.8 5 4.4 11.2 4.4 17.4v24z" />
  </svg>
);
export default IconMosque;
