import * as React from "react";
const IconBrocolli = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.6 18.9c0-5.5-4.4-9.9-9.7-9.9-1.6 0-3.2.4-4.6 1.2-.8-4.7-4.8-8.2-9.6-8.2-4.7 0-8.6 3.4-9.5 7.9-1.3-.6-2.7-.9-4.1-.9-5.4 0-9.7 4.4-9.7 9.9 0 3.8 2 7.1 5.3 8.8-.2.9-.3 1.7-.3 2.6 0 5.5 4.4 9.9 9.7 9.9 1.1 0 2.2-.2 3.2-.6l1.1 17.5c.2 2.8 2.5 4.8 5.2 4.8s5-2.1 5.2-4.9l.9-17c.6.1 1.1.2 1.7.2 5.4 0 9.7-4.4 9.7-9.9q0-1.2-.3-2.4c3.6-1.6 5.8-5.1 5.8-9M35.7 57c-.1 1.7-1.5 3-3.2 3s-3.1-1.3-3.2-3l-1.1-18.3c1.6-1 2.9-2.3 3.7-3.8 1 2.1 2.7 3.7 4.9 4.6zm12.7-30.6c-.3.1-.5.3-.6.5s-.1.5 0 .8c.3.8.4 1.7.4 2.6 0 4.4-3.5 7.9-7.7 7.9-.8 0-1.6-.1-2.3-.4-2.8-.9-4.7-3.3-5.1-6.3-.1-.5-.5-.9-1-.9s-.9.3-1 .8c-.5 2.4-2 4.5-4.4 5.8-1.1.6-2.4.9-3.6.9-4.3 0-7.7-3.5-7.7-7.9 0-1 .2-1.9.5-2.8.2-.5-.1-1.1-.6-1.3-2.9-1.2-4.9-4.1-4.9-7.3 0-4.4 3.5-7.9 7.7-7.9 1.6 0 3.1.5 4.3 1.4.3.2.7.2 1 .1q.45-.3.6-.9c.2-4.2 3.6-7.5 7.7-7.5 4.3 0 7.7 3.6 7.7 7.9q0 .6.6.9c.3.2.8.1 1.1-.1 1.4-1.1 3.1-1.7 4.8-1.7 4.3 0 7.7 3.6 7.7 7.9 0 3.4-2.1 6.4-5.2 7.5" />
  </svg>
);
export default IconBrocolli;
