import * as React from "react";
const IconMessageReply = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.7 37.1c-.1-.5-.6-.9-1.2-.8-.5.1-.9.6-.8 1.2l.9 4.4c-3-2.9-7.9-3-10.9 0-1.5 1.4-2.3 3.3-2.3 5.4 0 2 .8 3.9 2.3 5.4s3.5 2.2 5.5 2.2 4-.7 5.5-2.2c.4-.4.4-1 0-1.4s-1-.4-1.4 0c-2.2 2.2-5.9 2.2-8.1 0-1.1-1.1-1.7-2.5-1.7-3.9 0-1.5.6-2.9 1.7-3.9 2.2-2.2 5.9-2.2 8.1 0 0 0 .1.1.2.1l-4.1.8c-.5.1-.9.6-.8 1.2.1.5.5.8 1 .8h.2l5.2-1.1c1.3-.3 2.1-1.6 1.9-2.9zM56.9 9.2H7.1C4.3 9.2 2 11.5 2 14.3v31.4c0 2.8 2.3 5.1 5.1 5.1h27.6c.6 0 1-.4 1-1s-.4-1-1-1H7.1c-1.7 0-3.1-1.4-3.1-3.1V14.3c0-.4.1-.8.2-1.1l25 15.2c.9.5 1.8.8 2.8.8s1.9-.3 2.8-.8l25-15.2c.1.3.2.7.2 1.1v14.4c0 .6.4 1 1 1s1-.4 1-1V14.3c0-2.8-2.3-5.1-5.1-5.1M33.8 26.6c-1.1.7-2.4.7-3.5 0l-24.7-15c.5-.3 1-.4 1.6-.4H57c.6 0 1.1.2 1.6.4z" />
  </svg>
);
export default IconMessageReply;
