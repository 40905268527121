import React, { useState } from 'react';
import { useNavigation } from 'context/NavigationContext';
import { BringzzPageContainer, BringzzIcon, BringzzText, BringzzImage, BringzzButton, BringzzStatCard, BringzzBottomDrawer, BringzzRadioList, BringzzRadio, BringzzInput } from "@bringzz/components";

let state = {
    checked: (
        <BringzzIcon
            icon="IconCheck"
            library="Custom"
            className="h-5 w-5 p-1 bg-magic-lilac text-light rounded-full"
            size="12"
        />
    ),
    unChecked: (
        <div></div>
    ),
};

const ShopperOverviewPage = () => {
    const { backPress, navigate } = useNavigation();
    const [changeShopperDrawer, setChangeShopperDrawer] = useState(false)
    const [reasonShopperDrawer, setreasonShopperDrawer] = useState(false)
    const [selectedReason, setSelectedReason] = useState()

    const handleShopperDrawer = () => {
        setChangeShopperDrawer(!changeShopperDrawer)
    }

    const handleReasonShopperDrawer = () => {
        setreasonShopperDrawer(!reasonShopperDrawer)
    }

    return (
        <BringzzPageContainer
            header={{
                left: (
                    <BringzzIcon
                        size='22'
                        icon='IconChevronLeft'
                        folder='LineIcons'
                        className='cursor-pointer'
                        onClick={backPress}
                    />
                ),
                right: <>
                    <BringzzIcon
                        size="22"
                        icon="IconStar"
                        folder="LineIcons"
                        className="cursor-pointer"
                        onClick={(event) => {

                        }}
                    />
                    <BringzzIcon
                        size="22"
                        icon="IconUploadAlt"
                        folder="LineIcons"
                        className="cursor-pointer"
                        onClick={(event) => {

                        }}
                    /></>,
                background: <BringzzImage className="object-cover w-full h-full" src="/images/2.jpg" alt="react logo" />,
                avatar: <BringzzImage src="/logo192.png" alt="react logo" />
            }}
            subHeader={
                <div className='space-y-4'>
                    <BringzzText tag='h2' className='mt-6'>Dennis L.</BringzzText>
                    <div className='flex space-x-4'>
                        <BringzzButton className="border border-black" icon={{ variant: "IconSpeechBubble16", folder: "LineIcons", size: "18" }}>Chat</BringzzButton>
                        <BringzzButton className="border border-black" icon={{ variant: "IconPhone", folder: "LineIcons", size: "16" }}>Call</BringzzButton>
                    </div>
                    <div className='space-y-2'>
                        <BringzzText tag="h5" className="font-normal">Dui amet felis gravida convallis nisi suspendisse sed. Neque nam massa in odio dui amet felis gravida convallis nisi suspendisse sed. Neque nam massa in odio dui amet felis gravida...</BringzzText>
                        <BringzzText tag="h5" className="font-normal underline">Read more</BringzzText>
                    </div>
                    <div className='flex space-x-1 items-center'>
                        <BringzzText tag="h5" className="font-normal text-black/40">29 Posts</BringzzText>
                        <p className="text-[5px] text-black/40">&#9679;</p>
                        <BringzzText tag="h5" className="font-normal text-black/40">1,128 Followers</BringzzText>
                    </div>
                </div>
            }>

            <div className='px-4 grid gap-3'>
                <BringzzStatCard title='No. of Rides' value="47" />
                <div className='grid grid-cols-2 gap-2'>
                    <BringzzStatCard title='ranking regional riders' value="# 4" />
                    <BringzzStatCard title='ranking national' value="20" />
                </div>
                <div className='flex justify-center'>
                    <BringzzButton onClick={handleShopperDrawer} size='md' className='border border-red-600 text-red-600'>Change shopper</BringzzButton>
                </div>
            </div>

            <BringzzBottomDrawer
                title='Change shopper'
                isOpen={changeShopperDrawer}
                close={handleShopperDrawer}
            >
                <div className='px-6 pb-6 space-y-4'>
                    <div>
                        <BringzzText tag='h4' className='font-normal'>Are you sure you want to change shopper?</BringzzText>
                        <BringzzText tag='h4' className='font-normal'>This will delay your delivery process as we wait to match with another shopper.</BringzzText>
                    </div>
                    <div className='space-y-2'>
                        <BringzzButton onClick={handleShopperDrawer} size='md' className='bg-magic-lilac w-full flex justify-center'>Keep shopper</BringzzButton>
                        <BringzzButton
                            onClick={() => {
                                handleShopperDrawer()
                                handleReasonShopperDrawer()
                            }}
                            size='md' className='text-magic-lilac border border-magic-lilac w-full flex justify-center'>Change shopper</BringzzButton>
                    </div>
                </div>
            </BringzzBottomDrawer>

            <BringzzBottomDrawer
                title='Reason for changing shopper'
                isOpen={reasonShopperDrawer}
                close={handleReasonShopperDrawer}
            >
                <div className='px-6 pb-6 space-y-4'>
                    <div>
                        <BringzzText tag='h4' className='font-normal'>Let us know why you are reqeusting to change your shopper.</BringzzText>
                    </div>
                    <div className='space-y-2'>
                        <div className='px-4'>
                            <BringzzRadioList
                                onChange={(newValue) => {
                                    setSelectedReason(newValue)
                                }}
                                state={state}
                                className="rounded-xl w-full text-center space-y-2"

                            >
                                <BringzzRadio value="1"
                                    className="p-2 py-2 hover:bg-slate- border rounded-md border-gray-400 flex"
                                >
                                    <BringzzText tag='h4'>Reason 1</BringzzText>
                                </BringzzRadio>
                                <BringzzRadio value="2"
                                    className="p-2 py-2 hover:bg-slate- border rounded-md border-gray-400 flex"
                                >
                                    <BringzzText tag='h4'>Reason 2</BringzzText>
                                </BringzzRadio>
                            </BringzzRadioList>
                            <div className='mt-2'>
                                <BringzzInput placeholder={'Your custom reason'} className='border' onChange={(e, newIsValid, newValue) => {
                                    setSelectedReason(newValue)
                                }}></BringzzInput>
                            </div>
                        </div>
                        <BringzzButton onClick={() => navigate('/matchmaking/pickup/2')} size='md' disabled={!selectedReason} className='bg-magic-lilac w-full flex justify-center'>Confirm reason</BringzzButton>
                        <BringzzButton onClick={handleReasonShopperDrawer} size='md' className='text-magic-lilac border border-magic-lilac w-full flex justify-center'>Cancel</BringzzButton>
                    </div>
                </div>
            </BringzzBottomDrawer>
        </BringzzPageContainer>

    )
}

export default ShopperOverviewPage;