import * as React from "react";
const IconCheese = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M49.2 8.6c-1.6-1.3-3.9-1.5-5.7-.4L38.8 11l-.1.1c-.1 0-.1.1-.1.1s-.1.1-.1.2-.1.1-.1.2v.7c.2.4.2.9.2 1.4 0 2.2-1.8 4.1-4.1 4.1-1.2 0-2.3-.5-3.1-1.4 0 0-.1 0-.1-.1 0 0-.1-.1-.2-.1s-.1-.1-.2-.1-.1 0-.2-.1h-.4c-.1 0-.1 0-.2.1H30L5.5 30.5c-1.6.9-2.5 2.6-2.5 4.3v17.4c0 2.8 2.2 5 5 5h48c2.8 0 5-2.2 5-5V32.5c0-13-8.2-21-11.8-23.9m-8.6 5c0-.4-.1-.8-.1-1.3l4.1-2.4c1.1-.6 2.4-.5 3.4.3 4 3.2 10.6 10.2 11 21.3H48.8c-.5-2.9-3-5.1-6-5.1s-5.5 2.2-6 5.1H7.7l22.9-13.4c1.1 1 2.5 1.5 4 1.5 3.3.1 6-2.7 6-6m6.3 18.9c0 2.2-1.8 4.1-4.1 4.1s-4.1-1.8-4.1-4.1 1.8-4.1 4.1-4.1 4.1 1.9 4.1 4.1M56 55.2H8c-1.7 0-3-1.3-3-3V34.8c0-.5.1-.9.3-1.3h31.5c.5 2.9 3 5.1 6 5.1s5.5-2.2 6-5.1H59v18.7c0 1.7-1.3 3-3 3" />
    <path d="M18.4 39.9c-3.3 0-6.1 2.7-6.1 6.1s2.7 6.1 6.1 6.1 6.1-2.7 6.1-6.1-2.7-6.1-6.1-6.1m0 10.1c-2.2 0-4.1-1.8-4.1-4.1s1.8-4.1 4.1-4.1 4.1 1.8 4.1 4.1-1.8 4.1-4.1 4.1" />
  </svg>
);
export default IconCheese;
