import * as React from "react";
const IconBeachChair = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 48.1H27.7V30.6c0-.6-.4-1-1-1s-1 .4-1 1v17.5h-6.199999999999999L6.4 32.6c-.4-.4-1-.5-1.4-.1s-.5 1-.1 1.4l12 14.2H9.7c-1.3 0-2.4.6-3.1 1.7l-4.5 6.7c-.3.5-.2 1.1.3 1.4.2 0 .4.1.6.1.3 0 .6-.2.8-.4l4.5-6.7c.3-.5.9-.8 1.5-.8h16.1V57c0 .6.4 1 1 1s1-.4 1-1v-6.9h21.7c0 .2 0 .5.2.7l5.3 6.9c.2.3.5.4.8.4.2 0 .4-.1.6-.2.4-.3.5-1 .2-1.4l-4.9-6.4H61c.6 0 1-.4 1-1s-.4-1-1-1M7.8 26.2c.1.1.3.1.5.1.3 0 .7-.2.8-.4L12 23c1.4-1.4 3.4-1.4 4.8 0 2.2 2.1 5.4 2.1 7.6 0 1.4-1.4 3.4-1.4 4.7 0 2.2 2.1 5.4 2.1 7.5 0 1.4-1.3 3.4-1.3 4.7 0l3.1 3c.3.3.7.4 1.1.2q.6-.3.6-.9c0-10.7-8.7-19.4-19.4-19.4-10.6 0-19.3 8.6-19.4 19.2-.1.3 0 .7.3.9 0 .1.1.2.2.2M26.7 7.9c8.7 0 16 6.4 17.3 14.8l-1.2-1.2c-1.1-1-2.4-1.5-3.8-1.5-1.3 0-2.7.5-3.8 1.6-1.4 1.3-3.4 1.3-4.7 0-2.2-2.1-5.4-2.1-7.6 0-1.4 1.4-3.4 1.4-4.8 0-2.1-2.1-5.4-2.1-7.5 0l-1.2 1.2C10.8 14.4 18 7.9 26.7 7.9" />
  </svg>
);
export default IconBeachChair;
