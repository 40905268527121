import * as React from "react";
const IconLightUmbrella = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M36 6.1h-3V3c0-.6-.4-1-1-1s-1 .4-1 1v3.1h-3c-12 0-21.8 9.8-21.8 21.8v8.6c0 .9.5 1.7 1.3 2.1s1.7.4 2.5-.1l5.4-3.6c.1-.1.3-.1.4 0l6.7 4.5c.8.5 1.9.5 2.7 0l5.9-4V60h-6.9c-.6 0-1 .4-1 1s.4 1 1 1h15.9c.6 0 1-.4 1-1s-.4-1-1-1H33V35.5l6 4c.4.3.9.4 1.3.4.5 0 .9-.1 1.3-.4l6.7-4.5c.1-.1.3-.1.4 0l5.3 3.6c.7.5 1.7.5 2.5.1s1.3-1.2 1.3-2.1V28C57.9 15.9 48.1 6.1 36 6.1m19.9 30.4c0 .2-.1.3-.2.3s-.2.1-.4 0L50 33.2c-.8-.5-1.9-.5-2.7 0l-6.7 4.5c-.1.1-.3.1-.4 0l-7.5-5c-.3-.2-.8-.2-1.1 0l-7.5 5c-.1.1-.3.1-.4 0L17 33.2c-.4-.3-.9-.4-1.3-.4s-.9.1-1.3.4L9 36.8c-.2.1-.3.1-.4 0-.1 0-.2-.1-.2-.3v-8.6C8.1 17 17 8.1 28 8.1h8c11 0 19.9 8.9 19.9 19.8z" />
  </svg>
);
export default IconLightUmbrella;
