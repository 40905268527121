import * as React from "react";
const IconMute = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
    <circle cx={21.4} cy={22.8} r={3.5} />
    <circle cx={42.6} cy={22.8} r={3.5} />
    <path d="M38 36.5c-.4-.4-1-.4-1.4 0L32 41.1l-4.6-4.6c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l4.6 4.6-4.6 4.6c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3L32 44l4.6 4.6c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-4.6-4.6L38 38c.4-.4.4-1.1 0-1.5" />
  </svg>
);
export default IconMute;
