import * as React from "react";
const IconCheckAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.3 27.2H31.4c-.6 0-1 .4-1 1s.4 1 1 1h24.8c2.1 0 3.7 1.7 3.7 3.7v21.2c0 2.1-1.7 3.7-3.7 3.7H12.7c-2.1 0-3.7-1.7-3.7-3.7V41.6c0-.6-.4-1-1-1s-1 .4-1 1v12.6c0 3.2 2.6 5.7 5.7 5.7h43.5c3.2 0 5.7-2.6 5.7-5.7V32.9c.1-3.1-2.5-5.7-5.6-5.7" />
    <path d="m15.3 39.5 8.3 6.1c.2.1.4.2.6.2.1 0 .3 0 .4-.1.3-.2.6-.5.6-.8l.7-10.3c0-.2 0-.3-.1-.5L13.4 7.4C12 4.5 8.6 3.3 5.8 4.6l-.5.3c-2.9 1.3-4.1 4.7-2.8 7.6L15 39.2zm8.1 3.4L16.7 38 6.5 16.3l7.2-3.4 10.2 21.8zM4.2 8.8c.3-.9 1-1.7 1.9-2.1l.5-.2c1.9-.9 4.1-.1 4.9 1.8l1.3 2.9-7.2 3.4-1.3-2.9c-.4-1-.4-2-.1-2.9M51.3 40c.6 0 1-.4 1-1s-.4-1-1-1h-10c-.6 0-1 .4-1 1s.4 1 1 1zM32.5 48.4c0 .6.4 1 1 1h17.8c.6 0 1-.4 1-1s-.4-1-1-1H33.5c-.6 0-1 .5-1 1" />
  </svg>
);
export default IconCheckAlt;
