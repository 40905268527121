import * as React from "react";
const IconFullScreenCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60 20.3c.6 0 1-.4 1-1V4c0-.6-.4-1-1-1H44.7c-.6 0-1 .4-1 1s.4 1 1 1h12.9L32 30.6 6.4 5h12.9c.6 0 1-.4 1-1s-.4-1-1-1H4c-.6 0-1 .4-1 1v15.3c0 .6.4 1 1 1s1-.4 1-1V6.4L30.6 32 5 57.6V44.7c0-.6-.4-1-1-1s-1 .4-1 1V60c0 .6.4 1 1 1h15.3c.6 0 1-.4 1-1s-.4-1-1-1H6.4L32 33.4l25.5 25.5c.1.1.1.1.2.1h-13c-.6 0-1 .4-1 1s.4 1 1 1H60c.6 0 1-.4 1-1V44.7c0-.6-.4-1-1-1s-1 .4-1 1v12.9c0-.1-.1-.1-.1-.2L33.4 32 59 6.4v12.9c0 .5.4 1 1 1m-1 38.4v.3h-.3c.1 0 .1-.1.2-.1s.1-.2.1-.2" />
  </svg>
);
export default IconFullScreenCopy;
