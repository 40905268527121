import * as React from "react";
const IconRulerPencil = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.7 1.8h-4.9c-4.2 0-7.6 3.4-7.6 7.6V48q0 .6.3 1.2l7 11.6c.6 1 1.6 1.6 2.8 1.6s2.2-.6 2.8-1.6l7-11.6q.3-.6.3-1.2V9.4c-.1-4.2-3.5-7.6-7.7-7.6m-4.9 4.4h4.9c1.7 0 3.1 1.4 3.1 3.1V12H43.7V9.4c0-1.7 1.4-3.2 3.1-3.2m2.5 50.3-5.6-9.2V16.6h11.1v30.7zM23.6 1.9H10.9c-3.4 0-6.2 2.8-6.2 6.2v47.7c0 3.4 2.8 6.2 6.2 6.2h12.7c3.4 0 6.2-2.8 6.2-6.2V8.1c.1-3.4-2.7-6.2-6.2-6.2m1.8 54c0 1-.8 1.8-1.8 1.8H10.9c-1 0-1.8-.8-1.8-1.8v-3.5h7c1.2 0 2.2-1 2.2-2.2s-1-2.2-2.2-2.2h-7v-5h2c1.2 0 2.2-1 2.2-2.2s-1-2.2-2.2-2.2h-2v-4.9h7c1.2 0 2.2-1 2.2-2.2s-1-2.2-2.2-2.2h-7v-4.9h2c1.2 0 2.2-1 2.2-2.2s-1-2.2-2.2-2.2h-2v-4.9h7c1.2 0 2.2-1 2.2-2.2s-1-2.2-2.2-2.2h-7V8.1c0-1 .8-1.8 1.8-1.8h12.7c1 0 1.8.8 1.8 1.8z" />
  </svg>
);
export default IconRulerPencil;
