import * as React from "react";
const IconPostman = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      
      d="M57.326 12.442C46.526-1.545 26.43-4.128 12.443 6.674S-4.128 37.57 6.674 51.557c10.802 13.988 30.897 16.57 44.884 5.769l.008-.006c13.98-10.804 16.56-30.893 5.76-44.878m-8.19 7.984-2.498-2.458-.538-.538.15-.15 3.787-3.576a4.58 4.58 0 0 1 1.05 2.926 4.72 4.72 0 0 1-1.387 3.333 5 5 0 0 1-.564.463M15.034 47.162l.443.415 2.32 2.32-5.163-.336zm31.333-35.227a4.8 4.8 0 0 1 2.88.986l-4.16 4.187h-.001a.4.4 0 0 0-.114.14v.001a.4.4 0 0 0-.032.082.43.43 0 0 0 .146.445l3.146 3.146a4.67 4.67 0 0 1-5.171-.987 4.71 4.71 0 0 1-1.007-5.1 4.67 4.67 0 0 1 4.313-2.9M43.46 22.388c-.012.41-.197.797-.507 1.067L30.491 34.3l-.042.037-.455-.455-.198-.198-.097-.097-.278-.278-.107-.107-.213-.214 11.8-11.693a1.48 1.48 0 0 1 .966-.433 1.5 1.5 0 0 1 1.14.433q.063.058.118.123.155.23.266.48c.05.157.074.323.069.49M29.066 34.353a1 1 0 0 0 .021.063l.109.106.467.458-.173.021-2.587.317.318-.317 1.252-1.253zM41.644 19.96c-.516.068-1.014.3-1.411.696L28.474 32.363l-2.308-2.308c6.1-6.07 9.829-9.24 12.147-10.28a2.88 2.88 0 0 1 3.331.185M25.488 30.736l.048.048 1.903 1.903-4.965 1.062zM18.98 46.28a.744.744 0 0 0-.4.853l.532 2.32a.16.16 0 0 1 0 .187.16.16 0 0 1-.212 0l-3.146-3.173 9.694-9.839 5.02-.779 1.844 1.845a65.6 65.6 0 0 1-13.332 8.586M33.193 37l-1.947-1.945L43.5 24.31c-.87 3.457-5.49 8.14-10.307 12.69m16.88-20.958za.4.4 0 0 0-.507-.16.37.37 0 0 0-.16.507c.187.393.135.858-.133 1.2a.34.34 0 0 0-.1.253.38.38 0 0 0 .1.253.3.3 0 0 0 .213 0 .35.35 0 0 0 .347-.107 1.84 1.84 0 0 0 .24-1.947"
    />
  </svg>
);
export default IconPostman;
