import * as React from "react";
const IconCartCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M40.9 49c-3.5 0-6.4 2.9-6.4 6.5s2.9 6.5 6.4 6.5 6.4-2.9 6.4-6.5-2.8-6.5-6.4-6.5m0 11c-2.4 0-4.4-2-4.4-4.5s2-4.5 4.4-4.5 4.4 2 4.4 4.5-2 4.5-4.4 4.5M18.2 49c-3.5 0-6.4 2.9-6.4 6.5s2.9 6.5 6.4 6.5 6.4-2.9 6.4-6.5-2.8-6.5-6.4-6.5m0 11c-2.4 0-4.4-2-4.4-4.5s2-4.5 4.4-4.5 4.4 2 4.4 4.5-1.9 4.5-4.4 4.5M57.8 2h-6.4c-1.2 0-2.1.9-2.3 2l-1.9 13.8H7.3c-.7 0-1.3.3-1.7.8s-.5 1.2-.4 1.9l6.1 18.8c.2.9 1.1 1.6 2 1.6h29.5c1.8 0 3.4-1.3 3.6-3.1l4.7-33.5c0-.2.2-.3.3-.3h6.4c.6 0 1-.4 1-1s-.4-1-1-1M44.5 37.5c-.1.8-.8 1.4-1.6 1.4H13.4c-.1 0-.1 0-.1-.1L7.2 20c0-.1 0-.1.1-.1H47z" />
  </svg>
);
export default IconCartCopy;
