import * as React from "react";
const IconBabyFemale0306m = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M18 12a6 6 0 0 1-5 5.917V20h2a1 1 0 1 1 0 2h-2v3a1 1 0 1 1-2 0v-3H9a1 1 0 1 1 0-2h2v-2.083A6.002 6.002 0 0 1 12 6a6 6 0 0 1 6 6m-6 4a4 4 0 1 0 0-8 4 4 0 0 0 0 8M27.476 42a1 1 0 0 0 .11-.003c1.617-.048 2.896-.747 3.956-1.663.097.141.201.29.306.43.337.446.994 1.236 2.011 1.236h5C40.73 42 42 40.277 42 38.685c0-1.754-1.418-3.315-3.26-3.315h-2.648c-.814-.409-1.483-1.196-2.299-2.238l-.154-.197c-.488-.625-1.037-1.33-1.693-1.944l-.004-.055-.052.003a6.7 6.7 0 0 0-1.23-.91 5.9 5.9 0 0 0-2.942-.766h-5.271l.054 1.052c.262 5.029.393 7.71.459 9.135a160 160 0 0 1 .063 1.528l.001.028V42h4.452m2.535-10.017a3.926 3.926 0 0 0-1.975-.709l.42 8.577c.726-.214 1.383-.649 2.028-1.255zm2.451 6.23c.195.215.367.46.509.666l.134.197c.119.175.224.33.339.482.267.354.4.426.426.44l.005.002h4.985c.543 0 1.14-.592 1.14-1.315 0-.722-.594-1.315-1.26-1.315h-3.07l-.188-.08c-1.443-.613-2.442-1.877-3.264-2.925l-.034-.044zM26.46 40l-.426-8.737h-1.48A1479 1479 0 0 1 24.986 40zM14.2 32.4 12.25 35H9.5a3.5 3.5 0 1 0 0 7H14a3.5 3.5 0 0 0 2.8-1.4l3-4a3.5 3.5 0 1 0-5.6-4.2m1.6 1.2L13.25 37H9.5a1.5 1.5 0 0 0 0 3H14a1.5 1.5 0 0 0 1.2-.6l3-4a1.5 1.5 0 0 0-2.4-1.8M42 22.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0m-2 0a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0"
      clipRule="evenodd"
    />
  </svg>
);
export default IconBabyFemale0306m;
