import * as React from "react";
const IconLinkAltSplash = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M22.2 37.5H10.3c-2.9 0-5.3-2.4-5.3-5.3 0-1.4.6-2.8 1.6-3.8s2.4-1.6 3.8-1.6h4.2c.6 0 1-.4 1-1s-.4-1-1-1h-4.2c-1.9 0-3.8.8-5.2 2.1C3.8 28.4 3 30.2 3 32.1c0 4 3.3 7.3 7.3 7.3h11.9c.6 0 1-.4 1-1s-.5-.9-1-.9M41.8 26.8h11.9c2.9 0 5.3 2.4 5.3 5.3s-2.4 5.3-5.3 5.3h-5.9c-.6 0-1 .4-1 1s.4 1 1 1h5.9c4 0 7.3-3.3 7.3-7.3s-3.3-7.3-7.3-7.3H41.8c-.6 0-1 .4-1 1s.5 1 1 1M34 33.1h2.4c.6 0 1-.4 1-1s-.4-1-1-1h-4.6L4.7 6.7c-.4-.4-1-.3-1.4.1s-.3 1 .1 1.4l54.5 49.1c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.3-1-.1-1.4z" />
  </svg>
);
export default IconLinkAltSplash;
