import * as React from "react";
const IconHat1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M13.4 62c-.2 0-.4-.1-.5-.2-.2-.1-.4-.4-.4-.7L8.3 39.7C4.2 35.5 2 30 2 24.2c0-5.9 2.3-11.5 6.5-15.7C16.6.4 29.4-.2 38.2 7c.5.4 1 .8 1.5 1.3l21.5 4.2c.3.1.5.2.7.4.1.2.2.5.1.8-3 11.6-9 22.2-17.5 30.7C35.9 53 25.3 59 13.6 62zm-3-22.1 3.8 19.9C25.1 56.8 35 51 43 43s13.8-17.9 16.7-28.8l-19.8-3.9c-.1.3-.2.7-.4 1.2-.4 1.2-1.1 3-2 5.1-1.6 3.7-4.3 9-8.1 12.7-6.4 6.6-15.8 9.7-19 10.6M24.2 4C19 4 13.9 6 9.9 9.9 2.2 17.6 2 30.2 9.5 38.1c2.3-.6 12.1-3.6 18.5-10 6-6 9.3-16.1 10-18.5-.3-.3-.7-.6-1-.9-.1-.1-.2-.1-.2-.2C33.1 5.4 28.7 4 24.2 4m4.6 24.7" />
  </svg>
);
export default IconHat1;
