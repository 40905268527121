import * as React from "react";
const IconMoleculeAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62 12.6c-.2-1.6-1-3.1-2.2-4.1-1.3-1-2.9-1.5-4.5-1.4-1.6.2-3.1.9-4.2 2.2s-1.5 2.9-1.4 4.5c.1.6.2 1.3.5 1.8L39 22.1c-1.6-3.2-4.9-5.4-8.8-5.4-3.1 0-5.8 1.4-7.6 3.6l-9.5-8.6c.7-1 1.1-2.2 1.1-3.6 0-3.4-2.8-6.2-6.2-6.2S2 4.8 2 8.2s2.8 6.2 6.2 6.2q2.1 0 3.6-1.2l9.8 8.9c-.7 1.4-1.1 2.9-1.1 4.6 0 5.4 4.4 9.8 9.8 9.8.5 0 1 0 1.4-.1l2.7 9.7c-2.8 1.3-4.8 4.2-4.8 7.6 0 4.6 3.8 8.4 8.4 8.4s8.4-3.8 8.4-8.4-3.8-8.4-8.4-8.4c-.6 0-1.1.1-1.6.2l-2.6-9.6c3.8-1.4 6.5-5 6.5-9.2 0-.9-.1-1.8-.4-2.6l11.5-6.7c.2.2.4.5.7.7 1.1.9 2.5 1.4 3.9 1.4h.6c1.6-.2 3.1-.9 4.2-2.2.8-1.4 1.3-3 1.2-4.7m-53.8-.3C5.9 12.3 4 10.5 4 8.2S5.9 4 8.2 4s4.2 1.9 4.2 4.2-1.9 4.1-4.2 4.1m36.1 41.3c0 3.5-2.9 6.4-6.4 6.4s-6.4-2.9-6.4-6.4 2.9-6.4 6.4-6.4 6.4 2.9 6.4 6.4m-14-19.2c-4.3 0-7.8-3.5-7.8-7.8s3.5-7.8 7.8-7.8 7.8 3.5 7.8 7.8-3.5 7.8-7.8 7.8M59 15.9c-.7.9-1.7 1.4-2.8 1.5s-2.2-.2-3-.9c-.9-.7-1.4-1.7-1.5-2.8s.2-2.2.9-3c.7-.9 1.7-1.4 2.8-1.5h.4c1 0 1.9.3 2.6 1 .9.7 1.4 1.7 1.5 2.8s-.1 2-.9 2.9" />
  </svg>
);
export default IconMoleculeAlt;
