import * as React from "react";
const IconCone = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 47.4h-3.9L35.5 6.3C34.9 4.9 33.5 4 32 4s-2.9.9-3.5 2.3L9.9 47.4H6c-2.2 0-4 1.8-4 4V56c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4v-4.5c0-2.3-1.8-4.1-4-4.1M21.9 25.7h20.2l5.1 11.2H16.9zm8.4-18.6c.3-.7 1-1.1 1.7-1.1s1.4.4 1.7 1.1l7.5 16.6H22.8zM16 38.8h32l3.9 8.6H12.1zM60 56c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2v-4.5c0-1.1.9-2 2-2h52c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconCone;
