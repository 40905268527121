import * as React from "react";
const IconSick = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
    <circle cx={21.4} cy={22.8} r={3.5} />
    <circle cx={42.6} cy={22.8} r={3.5} />
    <path d="M44.7 38.3c.6 0 1-.4 1-1s-.4-1-1-1c-2.7 0-5 1.5-6.1 3.7-4.3-1.3-9-1.3-13.4 0-1-2.2-3.4-3.7-6.1-3.7-.6 0-1 .4-1 1s.4 1 1 1c2.2 0 4.1 1.4 4.5 3.3.1.3.1.7.1 1 0 2.4-2.1 4.3-4.7 4.3-.6 0-1 .4-1 1s.4 1 1 1c3.7 0 6.7-2.8 6.7-6.3V42c4-1.2 8.4-1.2 12.3 0v.7c0 3.5 3 6.3 6.7 6.3.6 0 1-.4 1-1s-.4-1-1-1c-2.6 0-4.7-1.9-4.7-4.3s2.1-4.4 4.7-4.4" />
  </svg>
);
export default IconSick;
