import * as React from "react";
const IconAiAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M41.9 4.4C39.1 2.8 35.8 2 32.3 2h-.1c-5 0-9.7 1.6-13.3 4.6-4.7 3.9-7.4 9.6-7.4 15.7v16.5c0 1.3 1 2.3 2.3 2.3h3c1 4.7 4.6 8.5 9.4 9.8l2 .5v8.2c0 1.3 1 2.3 2.3 2.3h19.8c1.3 0 2.3-1 2.3-2.3V22.3c0-7.5-4-14.2-10.7-17.9m8.7 55.3q0 .3-.3.3H30.4q-.3 0-.3-.3V50l-3.5-1c-4.2-1.1-7.4-4.6-8.1-8.9l-.1-1h-4.7q-.3 0-.3-.3V22.3c0-5.5 2.4-10.6 6.7-14.2C23.4 5.5 27.6 4 32.3 4c3.2 0 6.1.7 8.6 2.1 6.1 3.4 9.7 9.4 9.7 16.2z" />
    <path d="M42.2 21.6c.6 0 1-.4 1-1s-.4-1-1-1h-2.6v-.7c0-1.1-.9-2-2-2h-.7v-3.1c0-.6-.4-1-1-1s-1 .4-1 1v3.1h-5.7v-3.1c0-.6-.4-1-1-1s-1 .4-1 1v3.1h-.7c-1.1 0-2 .9-2 2v.7h-2.6c-.6 0-1 .4-1 1s.4 1 1 1h2.6v5.7h-2.6c-.6 0-1 .4-1 1s.4 1 1 1h2.6v.7c0 1.1.9 2 2 2h.7v2.5c0 .6.4 1 1 1s1-.4 1-1V32h5.7v2.5c0 .6.4 1 1 1s1-.4 1-1V32h.7c1.1 0 2-.9 2-2v-.7h2.6c.6 0 1-.4 1-1s-.4-1-1-1h-2.6v-5.7zM26.5 30V18.8h11.2V30z" />
  </svg>
);
export default IconAiAlt;
