import * as React from "react";
const IconHutAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.5 22.9 34.8 8c-1.6-.9-3.6-.9-5.2 0L2.5 22.9c-.5.3-.7.9-.4 1.4.2.3.5.5.9.5.2 0 .3 0 .5-.1l7.3-4c-.1.1-.1.3-.1.4v31.6c0 2.2 1.8 4 4 4h34.6c2.2 0 4-1.8 4-4V21c0-.2-.1-.3-.1-.5l7.4 4.1c.5.3 1.1.1 1.4-.4.1-.4 0-1-.5-1.3M12.7 52.6V22h8.9v32.6h-6.9c-1.1 0-2-.8-2-2M40.4 22v32.6H23.6V22zm10.9 30.6c0 1.1-.9 2-2 2h-6.9V22h8.9zM11.9 20 30.6 9.8c1-.6 2.2-.6 3.2 0L52.2 20z" />
  </svg>
);
export default IconHutAlt2;
