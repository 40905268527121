import * as React from "react";
const IconWheelchairCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M41.5 53.9C38 57.8 33 60 27.7 60 17.5 60 9.1 51.7 9.1 41.4c0-6 2.8-11.4 7.6-14.9.4-.3.5-1 .2-1.4s-1-.5-1.4-.2c-5.3 3.9-8.4 9.9-8.4 16.6C7.1 52.8 16.4 62 27.7 62c5.8 0 11.4-2.5 15.2-6.7.4-.4.3-1-.1-1.4-.3-.4-.9-.4-1.3 0" />
    <path d="m55.8 50.8-5.5.5-1.4-16.1c0-.9-.7-1.6-1.7-1.6H25.4l-.7-11.2h15.2c.6 0 1-.4 1-1s-.4-1-1-1H24.6l-.3-5.5h.2c3.6 0 6.5-2.9 6.5-6.5S28.1 2 24.5 2 18 4.9 18 8.5c0 2.8 1.8 5.1 4.2 6.1L23.4 34c0 .9.8 1.6 1.7 1.6H47l1.4 16.2v.1c.2.8.9 1.4 1.8 1.4l5.8-.5c.5 0 1-.5.9-1.1-.1-.5-.6-.9-1.1-.9M20 8.5C20 6 22 4 24.5 4S29 6 29 8.5 27 13 24.5 13 20 10.9 20 8.5" />
  </svg>
);
export default IconWheelchairCopy;
