import * as React from "react";
const IconBillboardAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.2 9.5H37.6V3c0-.6-.4-1-1-1h-9.2c-.6 0-1 .4-1 1v6.5H8.8c-2.2 0-4 1.8-4 4v27.2c0 2.2 1.8 4 4 4h17.6V61c0 .6.4 1 1 1h9.2c.6 0 1-.4 1-1V44.6h17.6c2.2 0 4-1.8 4-4V13.5c0-2.2-1.8-4-4-4M28.4 4h7.2v5.5h-7.2zm7.2 56h-7.2V44.6h7.2zm21.6-19.4c0 1.1-.9 2-2 2H8.8c-1.1 0-2-.9-2-2V13.5c0-1.1.9-2 2-2h46.4c1.1 0 2 .9 2 2z" />
    <path d="M21.9 20.7c-.4-.3-.9-.3-1.2 0l-7.8 6c-.4.3-.5 1-.2 1.4s1 .5 1.4.2l1.9-1.5V33c0 .6.4 1 1 1h8.7c.6 0 1-.4 1-1v-6.1l1.9 1.5c.2.1.4.2.6.2.3 0 .6-.1.8-.4.3-.4.3-1.1-.2-1.4zm2.8 4.9V32H18v-6.5l3.4-2.6 3.4 2.6c-.1 0-.1 0-.1.1M37 20.5h7.8c.6 0 1-.4 1-1s-.4-1-1-1H37c-.6 0-1 .4-1 1s.5 1 1 1M48.7 26.4H37c-.6 0-1 .4-1 1s.4 1 1 1h11.7c.6 0 1-.4 1-1s-.4-1-1-1M44.8 34.2H37c-.6 0-1 .4-1 1s.4 1 1 1h7.8c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconBillboardAlt2;
