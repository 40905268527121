import * as React from "react";
const IconMf = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 108 72"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 0h108v72H0z" />
    <path  d="M0 0h108L60 48v24H48V48z" />
    <circle cx={54} cy={30} r={8}  />
    <path  d="M44 30h20L54 48z" />
    <path  d="M54 48 38 33h32z" />
  </svg>
);
export default IconMf;
