import * as React from "react";
const IconGlobe = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M44 24c0 11.046-8.954 20-20 20S4 35.046 4 24 12.954 4 24 4s20 8.954 20 20m-3.024-6A18 18 0 0 1 42 24h-8.334a2.76 2.76 0 0 0-1.713.595 2.8 2.8 0 0 0-.983 1.526v.003l-.889 3.614a2.84 2.84 0 0 0 .503 2.397c.259.336.59.609.97.797s.799.287 1.223.287h.438l.99 5.611C31.304 40.83 27.788 42 24 42a17.9 17.9 0 0 1-10.105-3.102l1.53-8.585h1.008a3.55 3.55 0 0 0 2.812-1.378 3.59 3.59 0 0 0 .65-3.067l-1.283-5.163-.001-.003a3.58 3.58 0 0 0-1.267-1.948A3.55 3.55 0 0 0 15.149 18H7.024C9.495 11.009 16.163 6 24 6c1.344 0 2.654.147 3.915.427l-.433 1.417h-.19a2.293 2.293 0 0 0-2.224 2.844q.024.097.067.187l2.61 5.505A2.29 2.29 0 0 0 29.939 18zm-.847-2A18.06 18.06 0 0 0 29.84 6.969l-.662 2.167a1 1 0 0 1-.956.708h-.93a.294.294 0 0 0-.286.23.3.3 0 0 0-.006.067l2.588 5.457q.041.088.066.183a.29.29 0 0 0 .282.219zm-6.466 10h8.227a17.96 17.96 0 0 1-5.902 11.427l-.949-5.381a1 1 0 0 0-.984-.827h-1.278a.75.75 0 0 1-.334-.078.8.8 0 0 1-.273-.225.82.82 0 0 1-.146-.703l.887-3.609a.8.8 0 0 1 .282-.44.76.76 0 0 1 .47-.164M6.446 20A18 18 0 0 0 6 24a17.96 17.96 0 0 0 6.11 13.515l1.493-8.377a1 1 0 0 1 .985-.825h1.845a1.56 1.56 0 0 0 1.233-.605 1.59 1.59 0 0 0 .287-1.36l-1.282-5.157v-.002a1.58 1.58 0 0 0-.559-.859 1.55 1.55 0 0 0-.96-.33z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconGlobe;
