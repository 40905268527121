import * as React from "react";
const IconTreeAlt3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.6 31.5c-.9-1.1-2-2.1-3.3-2.8.3-1.1.4-2.3.4-3.4 0-3.5-1.2-6.8-3.5-9.5-1.8-2-4-3.6-6.6-4.4-.5-2.3-1.6-4.4-3.3-6.1C38.1 3.2 35.1 2 32 2s-6.1 1.2-8.3 3.4c-1.7 1.7-2.8 3.8-3.3 6.1-2.6.8-4.8 2.3-6.6 4.4-2.3 2.6-3.5 6-3.5 9.5 0 1.2.1 2.3.4 3.4-1.3.7-2.4 1.6-3.3 2.8-1.6 2-2.4 4.4-2.4 7 0 6.1 5 11.1 11.1 11.1H31V60h-7.6c-.6 0-1 .4-1 1s.4 1 1 1h17.2c.6 0 1-.4 1-1s-.4-1-1-1H33V49.6h14.9c6.1 0 11.1-5 11.1-11.1 0-2.6-.8-5-2.4-7m-8.7 16.1H33v-6.4l6.5-3.9c.5-.3.6-.9.3-1.4s-.9-.6-1.4-.3L33 38.9v-7.2l6.5-3.9c.5-.3.6-.9.3-1.4s-.9-.6-1.4-.3L33 29.3v-6.4c0-.6-.4-1-1-1s-1 .4-1 1v10l-7-2.4c-.5-.2-1.1.1-1.3.6s.1 1.1.6 1.3L31 35v12.6H16.1c-5 0-9.1-4.1-9.1-9.1 0-2.1.7-4.1 2-5.7.9-1.1 2-2 3.3-2.6.5-.2.7-.7.5-1.2-.4-1.2-.5-2.4-.5-3.6 0-3 1.1-5.9 3-8.2 1.6-1.9 3.8-3.3 6.2-3.9.4-.1.7-.4.7-.8.3-2.1 1.3-4.1 2.8-5.6C26.9 5 29.4 4 32 4s5.1 1 6.9 2.8c1.5 1.5 2.5 3.4 2.8 5.6.1.4.3.7.7.8 2.4.7 4.6 2 6.2 3.9 1.9 2.3 3 5.2 3 8.2 0 1.2-.2 2.5-.5 3.6-.1.5.1 1 .5 1.2 1.3.6 2.4 1.5 3.3 2.6 1.3 1.6 2 3.6 2 5.7.1 5.1-4 9.2-9 9.2" />
  </svg>
);
export default IconTreeAlt3;
