import * as React from "react";
const IconNetworkingStartup = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.7 6.7H7.4c-3 0-5.4 2.4-5.4 5.4v30.2c0 3 2.4 5.4 5.4 5.4h2.9v8.6c0 .4.2.7.5.9.1.1.3.1.5.1s.4-.1.6-.2L25 47.6h31.6c3 0 5.3-2.4 5.3-5.4V12.1c.1-3-2.3-5.4-5.2-5.4M60 42.3c0 1.8-1.5 3.4-3.3 3.4h-32c-.2 0-.4.1-.6.2l-11.9 8.5v-7.7c0-.6-.4-1-1-1H7.4c-1.8 0-3.4-1.5-3.4-3.4V12.1c0-1.8 1.5-3.4 3.4-3.4h49.3c1.8 0 3.3 1.5 3.3 3.4z" />
    <path d="M48.4 16.9c-2.8 0-5.1 2.3-5.1 5.1 0 .8.2 1.6.6 2.3l-5.6 4.4c-.6-.3-1.3-.4-2-.4-.5 0-.9.1-1.4.2l-3.8-3.6c.6-.8.9-1.8.9-2.9 0-2.8-2.3-5.1-5.1-5.1s-5.1 2.3-5.1 5.1c0 .7.1 1.3.4 1.8l-2.8 1.9c-.9-1-2.3-1.6-3.7-1.6-2.8 0-5.1 2.3-5.1 5.1s2.3 5.1 5.1 5.1 5.1-2.3 5.1-5.1q0-.9-.3-1.8l2.8-1.9c.9 1 2.2 1.6 3.7 1.6 1 0 2-.3 2.8-.9l3.3 3.2c-1.1.9-1.9 2.3-1.9 3.9 0 2.8 2.3 5.1 5.1 5.1s5.1-2.3 5.1-5.1c0-1.4-.5-2.6-1.4-3.5l5.1-4c.9.8 2.1 1.3 3.3 1.3 2.8 0 5.1-2.3 5.1-5.1s-2.2-5.1-5.1-5.1M15.6 32.4c-1.7 0-3.1-1.4-3.1-3.1s1.4-3.1 3.1-3.1 3.1 1.4 3.1 3.1-1.4 3.1-3.1 3.1M23.7 22c0-1.7 1.4-3.1 3.1-3.1S30 20.3 30 22s-1.4 3.1-3.1 3.1-3.2-1.4-3.2-3.1m12.6 14.5c-1.7 0-3.1-1.4-3.1-3.1s1.4-3.1 3.1-3.1 3.1 1.4 3.1 3.1-1.4 3.1-3.1 3.1m12.1-11.4c-1.7 0-3.1-1.4-3.1-3.1s1.4-3.1 3.1-3.1 3.1 1.4 3.1 3.1-1.3 3.1-3.1 3.1" />
  </svg>
);
export default IconNetworkingStartup;
