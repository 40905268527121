import React, { useState } from 'react';
import { useNavigation } from 'context/NavigationContext';
import {
	BringzzPageContainer,
	BringzzButton,
	BringzzIcon,
	BringzzText,
	BringzzInput
} from '@bringzz/components';
import useUser from 'hooks/useUser';
import useRequest from 'hooks/useRequest';

const ViewPharmacyInventory = () => {
	const { backPress, navigate } = useNavigation();
	const { sendRequest, loading } = useRequest();
	const { getUser } = useUser();
	const user = getUser();
	const [verified, setVerified] = useState(false);
    const [pharmacyApIp, setPharmacyApIp] = useState('');
    const [pharmacyApUser, setPharmacyApUser] = useState('');
    const [pharmacyApPass, setPharmacyApPass] = useState('');

	return (
		<BringzzPageContainer
			header={{
				left: (
					<BringzzIcon
						size='22'
						icon='IconChevronLeft'
						folder='LineIcons'
						className='cursor-pointer'
						onClick={backPress}
					/>
				),
				title: <BringzzText tag='h2'>Inventory Control</BringzzText>
			}}
			footer={
				<div className='pb-28 px-6'>
					<BringzzButton
						size='lg'
						className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
						onClick={async () => {
							await sendRequest({
								method: 'PATCH',
								url: `/pharmacy/inventory`,
								data: {
                                    pharmacyApIp,
                                    pharmacyApUser,
                                    pharmacyApPass
								}
							})
								.then(response => {
									if (response.status === 'success')
										navigate(`/settings/pharmacy`, false);
								})
								.catch(error => {
									console.log(error);
								});
						}}
						disabled={
							loading || !verified}
					>
						Save
					</BringzzButton>
				</div>
			}
		>
			<div className='p-6 space-y-4'>
            <div className='relative bg-white w-full bg-cover bg-center flex flex-col'>
					{/* Content */}
					<div className='flex-grow flex flex-col'>
						<div className='z-10 flex flex-col '>
							{!verified && (
								<div className='space-y-6'>
									<div className='space-y-4'>
										<BringzzInput label='IP Address' placeholder='Ip address' onChange={(e, isValid, newValue) => setPharmacyApIp(newValue)} />
										<BringzzInput
											label='User'
											placeholder='User'
											hint='We only allow Austrian and German bank accounts.'
                                            onChange={(e, isValid, newValue) => setPharmacyApUser(newValue)}
										/>
										<BringzzInput
											label='password'
											placeholder='password'
											type='password'
                                            onChange={(e, isValid, newValue) => setPharmacyApPass(newValue)}

										/>

										<div className='p-4 bg-magic-lilac/20'>
											<BringzzText
												tag='h4'
												className='font-light text-midnight-blue/40'
											>
												Not sure where to find this data?
												<span className='font-medium text-midnight-blue/40 underline'>
													Read our instructions/FAQ
												</span>
											</BringzzText>
										</div>

										<div className='px-4 mb-4'>
											<BringzzButton
												size='lg'
												className='bg-white border border-magic-lilac w-full flex justify-center text-midnight-blue'
												onClick={() => {
													setVerified(true);
												}}
												disable={!verified}
											>
												Verify
											</BringzzButton>
										</div>
									</div>
								</div>
							)}
							{verified && (
								<div className='bg-magic-lilac/20 p-4 mt-12'>
									<div className='flex items-center gap-x-4 pb-4 border-b border-b-midnight-blue/40'>
										<BringzzIcon
											icon='IconCheckmarkCircle'
											folder='LineIcons'
											size='20px'
											className='text-magic-lilac'
										/>{' '}
										<BringzzText tag='h3' className='text-magic-lilac'>
											Verification was successful
										</BringzzText>
									</div>
									<div className='mt-6'>
										<BringzzText
											tag='h3'
											className='text-midnight-blue font-light'
										>
											IP Address
										</BringzzText>
										<BringzzText
											tag='h3'
											className='text-midnight-blue font-light'
										>
											{pharmacyApIp}
										</BringzzText>
									</div>
									<div className='mt-6'>
										<BringzzText
											tag='h3'
											className='text-midnight-blue font-light'
										>
											User
										</BringzzText>
										<BringzzText
											tag='h3'
											className='text-midnight-blue font-light'
										>
											{pharmacyApUser}
										</BringzzText>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</BringzzPageContainer>
	);
};

export default ViewPharmacyInventory;
