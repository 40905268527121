import * as React from "react";
const IconFootball = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.1 21.7c-1.9-5-5.1-9.4-9.4-12.7l-.4-.3C44.3 5 38.3 3 32 3h-.4c-4.9.1-9.7 1.4-13.9 3.8-5.5 3.1-9.8 8-12.2 13.7l-.1.1v.1C3.9 24 3.1 27.7 3 31.5v.5c0 4.6 1.1 9.1 3.2 13.2.2.4.4.8.7 1.2 4.2 7.4 11.6 12.6 20 14.1 1.4.2 2.8.4 4.2.4h1c3.5 0 7-.6 10.3-1.9.3-.1.5-.2.8-.3.1 0 .1 0 .2-.1 9.6-4 16.3-12.8 17.5-23.1.2-1.2.2-2.4.2-3.6-.1-3.4-.7-6.9-2-10.2M59 32v.2l-4.6 1.1-5.8-5.8 2.1-7.9 4.4-1.3h.1c.8 1.3 1.5 2.7 2 4.2 1.2 3 1.8 6.2 1.8 9.5M31.7 5h.4c4 0 7.9.9 11.5 2.6 0 0 0 .1-.1.1l-.9 3.9-7.9 2.1-5.8-5.8.7-2.8c.7 0 1.4-.1 2.1-.1M16.6 9.8l1 1-2.1 7.9-7 1.9-.9-.3c2.1-4.1 5.1-7.8 9-10.5M5 33.5h.2c.2 0 .4-.1.6-.1l4.5 4.5-1.7 6.3-.6.4c0-.1-.1-.2-.2-.3-1.6-3.4-2.6-7-2.8-10.8m12 20.9.4-.6 7.4-2 5.8 5.8-.2 1.3c-1.1-.1-2.1-.2-3.2-.4-3.7-.6-7.1-2-10.2-4.1m26.1 2.2-1.6-1.9 2.1-7.9 8-2.1 2.8 2.7c-2.9 3.9-6.8 7.2-11.3 9.2m12.2-11-2.9-2.8c-.3-.2-.6-.3-1-.2l-9 2.4c-.3.1-.6.4-.7.7l-2.3 9c-.1.3 0 .7.2.9l1.5 1.8c-2.8 1-5.7 1.5-8.7 1.6l.3-1.5c.1-.3-.1-.6-.3-.9L25.8 50c-.3-.3-.6-.4-1-.3L16.5 52c-.2.1-.4.2-.6.4l-.6.9c-2.5-1.9-4.6-4.3-6.3-7l1-.6c.2-.1.4-.4.5-.6l1.9-7.2c.1-.3 0-.7-.3-1l-5.3-5.3c-.4-.3-.5-.3-1.9-.1.1-3.2.7-6.4 1.9-9.3l1.3.5c.1 0 .2.1.3.1h.3l7.8-2.1c.3-.1.6-.4.7-.7l2.4-9c.1-.3 0-.7-.3-1l-1.1-1.1c.1-.1.2-.1.3-.2 2.7-1.6 5.7-2.6 8.8-3.1l-.7 2.5c-.1.3 0 .7.3 1l6.6 6.6c.2.2.4.3.7.3h.3l9-2.4c.4-.1.6-.4.7-.7l.9-4.1c1 .6 2 1.2 2.9 1.9l.3.3c2.2 1.7 4 3.7 5.6 5.9l-4.5 1.3c-.3.1-.6.4-.7.7l-2.4 9c-.1.3 0 .7.3 1l6.6 6.6c.2.2.4.3.7.3h.2l4.5-1.1c0 .3-.1.7-.1 1-.2 3.3-1.4 6.8-3.2 9.9" />
    <path d="M32.6 23c-.3-.3-.6-.4-1-.3l-9 2.4c-.3.1-.6.4-.7.7l-2.4 9c-.1.3 0 .7.3 1l6.6 6.6c.2.2.4.3.7.3h.3l9-2.4c.3-.1.6-.4.7-.7l2.4-9c.1-.3 0-.7-.3-1zm2.6 15.5-7.9 2.1-5.8-5.8 2.1-7.9 7.9-2.1 5.8 5.8z" />
  </svg>
);
export default IconFootball;
