import React, { useState } from 'react';
import { BringzzButton, BringzzText } from "@bringzz/components";
import { useNavigation } from 'context/NavigationContext';
import {
    BringzzInput,
    BringzzDate,
    BringzzPageContainer,
    BringzzIcon,
    BringzzImageUpload,
    BringzzBottomDrawer
} from "@bringzz/components";
import { useLocation } from 'react-router-dom';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const ShopperProfilePage = () => {
    const { backPress } = useNavigation();

    const { navigate } = useNavigation();

    const [drawerOpened, setDrawerOpened] = useState(false)

    const [imageFile, setImageFile] = useState(null);
    const imageHandler = (file) => {
        setImageFile(file);
        console.log(file);
    };

    return (
        <BringzzPageContainer
            className="bg-white"
            header={{
                left: (
                    <BringzzIcon
                        size='22'
                        icon='IconChevronLeft'
                        folder='LineIcons'
                        className='cursor-pointer'
                        onClick={backPress}
                    />
                )
            }}
            footer={
                <div className='px-4 mb-4'>
                    <BringzzButton
                        size='lg'
                        className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
                        onClick={() => {
                            navigate('/home');
                        }}
                    >
                        Continue
                    </BringzzButton>
                </div>
            }
            authenticated={false}
        >
            <div className='relative flex justify-center bg-magic-lilac/20'>
                <div className='relative bg-white w-full bg-cover bg-center flex flex-col'>
                    {/* Content */}
                    <div className='flex-grow flex flex-col py-8 px-4'>
                        <div className='z-10 flex flex-col '>
                            <div className='flex flex-col text-center space-y-6 mx-auto md:max-w-lg lg:max-w-xl xl:max-w-2xl'>
                                <BringzzText tag='h1' className='text-midnight-blue'>
                                    Your profile
                                </BringzzText>
                                <BringzzText
                                    tag='h4'
                                    className='text-midnight-blue/25 font-normal'
                                >
                                    Tell us a little bit about yourself.
                                    Lorem ipsum dolor sit amet consectetur. Placerat.
                                </BringzzText>
                            </div>
                            <div className='space-y-6'>
                                <BringzzImageUpload
                                    banner={'/utils/imageBanner.png'}
                                    imageHandler={imageHandler}
                                ></BringzzImageUpload>
                                <div className='grow bg-midnight-blue/20 h-[1px]'></div>
                                <div className='space-y-4'>
                                    <BringzzInput label="First Name" placeholder="First Name" />
                                    <BringzzInput label="Last Name" placeholder="Last Name" />
                                    <BringzzDate
                                        format="dd/MM/yyyy"
                                        mode={"day"}
                                        label="Birthday"
                                        maxDate={new Date()}
                                    ></BringzzDate>
                                </div>
                            </div>
                        </div>
                    </div>
                    <BringzzBottomDrawer isOpen={drawerOpened} title={"Doctor verification"} close={() => setDrawerOpened(false)}>
                        <div className='p-4 space-y-4'>
                            <BringzzInput label="Medical specialisation" placeholder="Medical specialisation" />
                            <BringzzInput label="Doctor ID" placeholder="Doctor ID" />
                            <BringzzInput label="UID-Nummer (optional)" placeholder="ATU12345678" />
                            <div className='flex w-full text-center gap-2'>
                                <BringzzButton className="border border-black w-full flex justify-center" onClick={() => setDrawerOpened(false)}>Cancel</BringzzButton>
                                <BringzzButton className="bg-magic-lilac w-full flex justify-center">Confirm</BringzzButton>
                            </div>
                        </div>
                    </BringzzBottomDrawer>
                </div>
            </div>
        </BringzzPageContainer>
    );
};

export default ShopperProfilePage;
