import * as React from "react";
const IconThunderCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.2 20.1c-2.7-3-6.5-4.9-10.5-5.5-2.1-3.6-5.3-6.2-9.1-7.6-1.6-.6-3.5-1-5.5-1C22.7 6 15 13.7 15 23.1v.1c-7.4.3-13 6.1-13 13.4C2 44.1 7.9 50 15.3 50c.6 0 1-.4 1-1s-.4-1-1-1C9 48 4 43 4 36.6s5-11.4 11.4-11.4h.5c.6 0 1-.4 1-1v-1.1C16.9 14.8 23.7 8 32 8c1.8 0 3.4.3 4.8.8 3.5 1.2 6.5 3.8 8.3 7.1.2.3.4.5.8.5 3.7.4 7.3 2.2 9.8 4.9 2.7 3 4.2 6.8 4.2 10.8 0 6.6-4.2 12.6-10.4 14.8-.5.2-.8.8-.6 1.3s.8.8 1.3.6c7-2.6 11.7-9.3 11.7-16.7.1-4.4-1.6-8.7-4.7-12" />
    <path d="M46.1 31.5h-5.5l1.5-2.7c.6-1.2.6-2.6-.1-3.7-.7-1.2-1.9-1.8-3.2-1.8h-8c-1.5 0-2.8.9-3.4 2.2l-5.8 12.9c-.3.7-.3 1.6.2 2.3.4.7 1.2 1.1 2 1.1h6.5l-3.9 13.1c-.3 1.1.1 2.3 1.2 2.8.4.2.7.3 1.1.3.7 0 1.4-.3 1.8-.9L48 35.4c.6-.7.7-1.7.3-2.5-.4-.9-1.2-1.4-2.2-1.4m.3 2.6L28.9 55.8c-.2.2-.4.2-.5.1s-.3-.2-.2-.4l4.3-14.3c.1-.3 0-.6-.2-.9s-.5-.4-.8-.4h-7.8c-.2 0-.3-.1-.3-.2s-.1-.2 0-.4l5.8-12.9c.3-.6.9-1 1.6-1h8c.6 0 1.2.3 1.5.9.3.5.3 1.2 0 1.7l-2.2 4c-.2.3-.2.7 0 1s.5.5.9.5h7.2c.2 0 .3.1.3.2s.1.3-.1.4" />
  </svg>
);
export default IconThunderCopy;
