import * as React from "react";
const IconFuelTruckAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 34.7h-5.3c1.6-1.5 2.7-3.6 2.7-5.9v-4.2c0-4.4-3.6-7.9-7.9-7.9H28.9v-5.8c0-.6-.4-1-1-1h-7.8c-.6 0-1 .4-1 1v5.8h-5.6c-4.4 0-7.9 3.6-7.9 7.9v4.2c0 2.4 1 4.5 2.7 5.9H3c-.6 0-1 .4-1 1v14.1c0 .6.4 1 1 1h3.3c.6 2 2.5 3.4 4.6 3.4s4-1.4 4.6-3.4h3.2c.2 0 .3-.1.5-.1.6 2 2.5 3.5 4.7 3.5s4-1.4 4.6-3.4h6.7c.6 2 2.5 3.4 4.6 3.4 2.2 0 4.1-1.5 4.7-3.5.1.1.3.1.5.1h3.2c.6 2 2.5 3.4 4.6 3.4 2.2 0 4-1.4 4.6-3.4H61c.6 0 1-.4 1-1V35.7c0-.6-.4-1-1-1M21.1 11.9h5.8v4.8h-5.8zM7.6 28.7v-4.2c0-3.3 2.7-5.9 5.9-5.9h37c3.3 0 5.9 2.7 5.9 5.9v4.2c0 3.3-2.7 5.9-5.9 5.9h-37c-3.3.1-5.9-2.6-5.9-5.9M11 52.2c-1.6 0-2.9-1.3-2.9-2.9s1.3-2.9 2.9-2.9 2.9 1.3 2.9 2.9-1.3 2.9-2.9 2.9m13 0c-1.6 0-2.9-1.3-2.9-2.9s1.3-2.9 2.9-2.9 2.9 1.3 2.9 2.9-1.3 2.9-2.9 2.9m16 0c-1.6 0-2.9-1.3-2.9-2.9s1.3-2.9 2.9-2.9 2.9 1.3 2.9 2.9-1.3 2.9-2.9 2.9m13 0c-1.6 0-2.9-1.3-2.9-2.9s1.3-2.9 2.9-2.9 2.9 1.3 2.9 2.9-1.3 2.9-2.9 2.9m7-3.4h-2.1c-.3-2.5-2.3-4.4-4.9-4.4-2.5 0-4.6 1.9-4.9 4.4h-3c-.1 0-.2 0-.3.1-.2-2.5-2.3-4.5-4.9-4.5-2.5 0-4.6 1.9-4.9 4.4h-6.3c-.3-2.5-2.3-4.4-4.9-4.4s-4.6 2-4.9 4.5c-.1 0-.2-.1-.3-.1h-3c-.3-2.5-2.3-4.4-4.9-4.4s-4.6 1.9-4.9 4.4H4V36.7h56z" />
  </svg>
);
export default IconFuelTruckAlt;
