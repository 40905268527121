import React, { useState, useEffect } from 'react';
import { BringzzText, BringzzSwitch } from "@bringzz/components";
import { requestBiometricAuthentication, checkWebAuthnSupport, isBiometricAuthEnabled, disableBiometricAuthentication } from 'webauthn';
import useRequest from 'hooks/useRequest';
import useUser from 'hooks/useUser';

export const FaceIdSwitch = () => {
    const [isBiometricEnabled, setIsBiometricEnabled] = useState(false);
    const { sendRequest, loading } = useRequest();
    const { getUser } = useUser()
    const user = getUser();

    const handleEnableBiometricAuth = async () => {
        const result = await requestBiometricAuthentication(user.email, user.firstname);
        if (result) {
            await sendRequest({
                method: 'POST',
                url: `/settings/app/faceid`,
                data: { loginWithFaceId: true }
            }).then((response) => {
                if (response.status === 'success') {
                    // TODO: Toast
                }
            }).catch((error) => {
                console.error(error);
            })
        }
        return result;
    };

    const handleDisableBiometricAuth = async () => {
        const result = disableBiometricAuthentication();
        if (!result) {
            await sendRequest({
                method: 'POST',
                url: `/settings/app/faceid`,
                data: { loginWithFaceId: false }
            }).then((response) => {
                if (response.status === 'success') {
                    // TODO: Toast
                }
            }).catch((error) => {
                console.error(error);
            })
        }
        return result;
    };

    useEffect(() => {
        setIsBiometricEnabled(isBiometricAuthEnabled());
    }, []);

    return (
        <div>
            {checkWebAuthnSupport() && <div className='flex py-2 justify-between items-center'>
                <div>
                    <BringzzText tag='h4' className="font-bold">Login with Face ID</BringzzText>
                    <BringzzText tag='h4' className="font-normal text-midnight-blue/60">Access your account quickly and securely.</BringzzText>
                </div>
                <div>
                    <BringzzSwitch
                        defaultValue={isBiometricEnabled}
                        onEnable={handleEnableBiometricAuth}
                        onDisable={handleDisableBiometricAuth}
                    />
                </div>
            </div>}
        </div>
    )
}