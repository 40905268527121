import React, { useState, useEffect } from "react";
import PropTypes from "prop-types"; // Import PropTypes for type-checking
import BringzzIcon from "../BringzzIcon";
import BringzzImage from "../BringzzImage";
import BringzzText from "../BringzzText";
import BringzzInput from "../BringzzInput";
import { twMerge } from "tailwind-merge";

/**
 * BringzzImageCheckbox is a component that displays a list of entities as selectable
 * items with an image or icon. Users can filter through entities using a search bar.
 * Selected entities are marked with a checkmark.
 *
 * @param {Object} props Component props
 * @param {Function} props.onChange Callback function when the selection changes
 * @param {Array<string>} props.defaultValue Initial selected entity IDs
 * @param {Array<string>} props.value Controlled selected entity IDs
 * @param {Function} props.filter Function to filter entities based on a condition
 * @param {Array} props.entities List of entities to display
 */
const BringzzImageCheckbox = ({
    onChange,
    defaultValue,
    value,
    filter = () => true,
    entities,
}) => {
    const [internalValue, setInternalValue] = useState(defaultValue || []);
    const [filteredEntities, setFilteredEntities] = useState(entities);
    const [searchTerm, setSearchTerm] = useState("");

    // Update internal selection state when `value` prop changes
    useEffect(() => {
        if (value) {
            setInternalValue(value);
        }
    }, [value]);

    // Filter entities based on search term and selection status
    useEffect(() => {
        const filtered = entities.filter(
            (entity) =>
                internalValue.includes(entity.id) ||
                (filter(entity) &&
                    entity.name.toLowerCase().includes(searchTerm.toLowerCase()))
        );
        setFilteredEntities(filtered);
    }, [entities, searchTerm, internalValue]);

    // Handle selection changes for entities
    const handleSelectionChange = (selectedEntity) => {
        const updatedValue = internalValue.includes(selectedEntity)
            ? internalValue.filter((id) => id !== selectedEntity)
            : [...internalValue, selectedEntity];

        setInternalValue(updatedValue);
        onChange(updatedValue);
    };

    return (
        <div className="space-y-6">
            {/* <BringzzInput
                type="text"
                placeholder="Search..."
                value={searchTerm}
                icon={{
                    right: (
                        <BringzzIcon
                            icon="IconSearchAlt"
                            folder="LineIcons"
                            className="h-6 w-6"
                            size="24"
                        />
                    ),
                }}
                onChange={(e) => setSearchTerm(e.target.value)}
            /> */}
            {/* no search and filter prop result */}
            {filteredEntities.length === 0 ? (
                <div className="text-center p-2">
                    <BringzzText
                        type={{
                            tag: "p",
                            classes: "text-md font-bold text-gray-600 text-center",
                        }}
                    >
                        No results for selected filter and search.
                    </BringzzText>
                </div>
            ) : (
                <div className="flex justify-center">
                    <div className="grid grid-cols-2 gap-4 w-full">
                        {filteredEntities.map((entity) => (
                            <div
                                key={entity.id}
                                onClick={() => handleSelectionChange(entity.id)}
                                className="cursor-pointer w-full flex flex-col"
                            >
                                <div className={twMerge("relative border-midnight-blue border p-4 bg-gray-100 hover:bg-gray-200 rounded-lg flex justify-center items-center h-28 grow",
                                    internalValue.includes(entity.id) ? "border-2 border-magic-lilac" : "filter grayscale")}>
                                    {entity.type === "icon" ? (
                                        <BringzzIcon
                                            folder={entity.folder}
                                            icon={entity.icon}
                                            className="rounded-full p-1 flex justify-center items-center"
                                        />
                                    ) : (
                                        <BringzzImage src={entity.path} alt="" width={entity.id == 6 ? "60%" : "100%"} />
                                    )}

                                    {/* <div className="absolute top-2 right-2 z-10 border-2 border-gray-300 rounded-full flex justify-center items-center w-2 h-2">
                                        {internalValue.includes(entity.id) && (
                                            <BringzzIcon
                                                folder="LineIcons"
                                                icon="IconCheckmark"
                                                size="12px"
                                                className="bg-[#4ED483] text-white rounded-full p-1"
                                            />
                                        )}
                                    </div> */}
                                </div>
                                {/* <div className="p-2">
                                    <BringzzText
                                        type={{
                                            tag: "p",
                                            classes:
                                                "text-md font-medium text-gray-600 text-ellipsis truncate",
                                        }}
                                    >
                                        {entity.name}
                                    </BringzzText>
                                </div> */}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

// Define PropTypes for component props for validation
BringzzImageCheckbox.propTypes = {
    onChange: PropTypes.func.isRequired,
    defaultValue: PropTypes.arrayOf(PropTypes.string),
    value: PropTypes.arrayOf(PropTypes.string),
    filter: PropTypes.func.isRequired,
    entities: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            type: PropTypes.oneOf(["icon", "image"]).isRequired,
            folder: PropTypes.string,
            icon: PropTypes.string,
            path: PropTypes.string,
            name: PropTypes.string.isRequired,
        })
    ).isRequired,
};

// Export the component
export default BringzzImageCheckbox;
