import * as React from "react";
const IconReservoir = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m50.5 60.8-4.2-25.2V13.3c0-1.8-.9-3.5-2.4-4.5L35 2.9c-1.8-1.2-4.1-1.2-5.9 0l-8.9 5.9c-1.5 1-2.4 2.7-2.4 4.5v22.4l-4.2 25.2c-.1.4.1.8.5 1 .2.1.3.1.5.1s.5-.1.6-.2L32 47.9l16.9 13.9c.2.1.4.2.6.2s.3 0 .5-.1c.4-.2.6-.7.5-1.1M32 45.3 19.7 35.2v-.4h24.6v.4zm-12.3-32c0-1.1.6-2.2 1.5-2.8l8.9-5.9c1.1-.8 2.6-.8 3.7 0l8.9 5.9c1 .6 1.5 1.7 1.5 2.8v19.6H19.7zm-3.8 45.2 3.5-20.9 11 9zm17.7-11.9 11-9 3.5 20.9z" />
  </svg>
);
export default IconReservoir;
