import * as React from "react";
const IconCake = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.1 29.7h-1V11.4c0-4-3.3-7.3-7.3-7.3H14.1c-4 0-7.3 3.3-7.3 7.3v18.3h-1c-2.3 0-4.1 1.8-4.1 4.1v18.7c0 4 3.3 7.3 7.3 7.3h46c1.9 0 3.8-.8 5.1-2.1 1.4-1.4 2.1-3.2 2.1-5.2V33.8c.1-2.2-1.8-4.1-4.1-4.1m-46.8-9.4c.8.3 1.5.7 2.3 1.2 1.5 1 3.4 2.1 6.9 2.1s5.4-1.2 6.9-2.1c1.3-.8 2.3-1.5 4.5-1.5s3.2.6 4.5 1.5c1.5 1 3.4 2.1 6.9 2.1s5.4-1.2 6.9-2.1c.8-.5 1.4-.9 2.3-1.2v9.5H11.3zm2.8-11.6h35.8c1.5 0 2.8 1.2 2.8 2.8v4.2c-2.1.4-3.5 1.2-4.7 2-1.3.8-2.3 1.5-4.5 1.5s-3.2-.6-4.5-1.5c-1.5-1-3.4-2.1-6.9-2.1s-5.4 1.2-6.9 2.1c-1.3.8-2.3 1.5-4.5 1.5s-3.2-.6-4.5-1.5c-1.2-.7-2.5-1.6-4.7-2v-4.2c-.2-1.6 1.1-2.8 2.6-2.8M6.8 34.2h50.9v5.9c-2.7.4-4.4 1.4-5.9 2.4-1.6 1-3 1.8-5.8 1.8-2.9 0-4.2-.8-5.8-1.8-1.9-1.2-4-2.5-8.2-2.5s-6.3 1.3-8.2 2.5c-1.6 1-3 1.8-5.8 1.8s-4.2-.8-5.8-1.8c-1.5-1-3.2-2-5.9-2.4v-5.9zm50.1 20.3c-.5.5-1.2.8-2 .8H9c-1.5 0-2.8-1.3-2.8-2.8v-7.8c1.4.3 2.4.9 3.6 1.6 1.9 1.2 4 2.5 8.2 2.5s6.3-1.3 8.2-2.5c1.6-1 3-1.8 5.8-1.8 2.9 0 4.2.8 5.8 1.8 1.9 1.2 4 2.5 8.2 2.5 4.1 0 6.3-1.3 8.2-2.5 1.1-.7 2.1-1.3 3.6-1.6v7.8c0 .8-.3 1.5-.9 2" />
  </svg>
);
export default IconCake;
