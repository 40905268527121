import * as React from "react";
const IconSpinnerArrowCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.6 26c-.2-.5-.7-.8-1.3-.7-.5.2-.8.7-.7 1.3.8 2.5 1.2 5.1 1.2 7.7 0 3.9-.9 7.7-2.6 11.2C50.9 54.5 42 60 32 60 17.7 60 6.1 48.5 6.1 34.3S17.7 8.6 32 8.6c3.9 0 7.7.9 11.2 2.5v.1h-9.3c-.6 0-1 .4-1 1s.4 1 1 1h10.3c.6 0 1-.4 1-1V2c0-.6-.4-1-1-1s-1 .4-1 1v7C39.7 7.4 35.9 6.6 32 6.6 16.6 6.6 4.1 19 4.1 34.3S16.6 62 32 62c10.6 0 20.5-6.1 25.1-15.6 1.8-3.8 2.8-7.8 2.8-12.1 0-2.8-.5-5.6-1.3-8.3" />
  </svg>
);
export default IconSpinnerArrowCopy;
