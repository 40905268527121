import * as React from "react";
const IconCastleAlt7 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 4c-.6 0-1 .4-1 1v10.9c0 1.4-1.2 2.6-2.6 2.6H43.9V8.6c0-.6-.4-1-1-1s-1 .4-1 1v9.9H22.1V8.6c0-.6-.4-1-1-1s-1 .4-1 1v9.9H6.6c-1.4 0-2.6-1.2-2.6-2.6V5c0-.6-.4-1-1-1s-1 .5-1 1v10.9c0 2.5 2.1 4.6 4.6 4.6h2.8v34.9c0 2.5 2.1 4.6 4.6 4.6h36c2.5 0 4.6-2.1 4.6-4.6V20.5h2.8c2.5 0 4.6-2.1 4.6-4.6V5c0-.5-.4-1-1-1M38.8 58H25.2v-7.3c0-3.7 3-6.8 6.8-6.8s6.8 3 6.8 6.8zm13.8-2.6c0 1.4-1.2 2.6-2.6 2.6h-9.2v-7.3c0-4.8-3.9-8.8-8.8-8.8-4.8 0-8.8 3.9-8.8 8.8V58H14c-1.4 0-2.6-1.2-2.6-2.6V20.5h41.2z" />
  </svg>
);
export default IconCastleAlt7;
