import * as React from "react";
const IconSofa4 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.3 37.1c0-3.4-2.3-6.4-5.5-7.3.4-1.7.7-3.6.7-5.4C54.4 12.1 44.4 2 32 2S9.6 12.1 9.6 24.4c0 1.9.2 3.7.7 5.4-3.2.9-5.5 3.8-5.5 7.3 0 2.8 1.5 5.3 4 6.6-.3.6-.4 1.3-.4 2.1V56c0 .6.4 1 1 1h5v4c0 .6.4 1 1 1s1-.4 1-1v-4h31.3v4c0 .6.4 1 1 1s1-.4 1-1v-4h5c.6 0 1-.4 1-1V45.8c0-.7-.2-1.4-.4-2.1 2.5-1.2 4-3.8 4-6.6M32 4c11.3 0 20.5 9.2 20.5 20.4 0 1.8-.2 3.5-.7 5.1-4.2 0-7.5 3.4-7.5 7.5 0 .9.2 1.7.4 2.5L34 38.2c-1.4-.2-2.7-.2-4.1 0l-10.6 1.4c.3-.8.5-1.7.5-2.5 0-4.2-3.4-7.5-7.5-7.5-.4-1.7-.7-3.4-.7-5.1C11.6 13.2 20.7 4 32 4M6.7 37.1c0-2.8 2.1-5.2 4.9-5.5h.7c3.1 0 5.5 2.5 5.5 5.5 0 1-.3 1.9-.8 2.8l-4 .5c-1.3.2-2.4.8-3.2 1.7l-.1-.1c-1.8-.9-3-2.8-3-4.9m47 17.9H10.3v-9.2c0-1.7 1.3-3.2 3-3.4l4.5-.6 12.4-1.6q1.8-.3 3.6 0l16.9 2.2c1.7.2 3 1.7 3 3.4zm.6-13q-.15.15 0 0c-.9-.8-2-1.4-3.3-1.6l-4-.5c-.5-.9-.8-1.8-.8-2.8 0-3.1 2.5-5.5 5.5-5.5h.7c2.8.3 4.9 2.7 4.9 5.5 0 2.1-1.2 4-3 4.9" />
  </svg>
);
export default IconSofa4;
