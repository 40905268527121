import * as React from "react";
const IconSmartphoneWatchAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M26.3 10.7h-5.7c-.6 0-1 .4-1 1s.4 1 1 1h5.7c.6 0 1-.4 1-1s-.4-1-1-1M23.1 47.1c-2.1 0-3.8 1.7-3.8 3.8s1.7 3.8 3.8 3.8S27 53 27 50.9s-1.7-3.8-3.9-3.8m0 5.6c-1 0-1.8-.8-1.8-1.8s.8-1.8 1.8-1.8 1.8.8 1.8 1.8-.7 1.8-1.8 1.8" />
    <path d="M43.7 17.5c-.9 0-1.7.1-2.5.2V8.6C41.1 5 38.3 2 34.8 2H11.5C8 2 5.1 5 5.1 8.6v46.8C5.1 59 8 62 11.5 62h23.4c3.5 0 6.3-3 6.3-6.6v-7.8c.8.1 1.7.2 2.6.2C52.2 47.8 59 41 59 32.6s-6.9-15.1-15.3-15.1m-4.6 30.3v7.7c0 2.5-1.9 4.6-4.3 4.6H11.5c-2.4 0-4.3-2.1-4.3-4.6V8.6C7.1 6.1 9.1 4 11.5 4h23.4c2.4 0 4.3 2.1 4.3 4.6v9.6c-6.2 1.9-10.6 7.7-10.6 14.5s4.6 12.6 10.8 14.5q-.3.3-.3.6m4.6-1.9c-7.3 0-13.2-5.9-13.2-13.2s5.9-13.2 13.2-13.2 13.2 5.9 13.2 13.2-6 13.2-13.2 13.2" />
    <path d="M43.9 33.7v-4.6c0-.6-.4-1-1-1s-1 .4-1 1v5c0 .3.1.5.3.7l3 3c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4z" />
  </svg>
);
export default IconSmartphoneWatchAlt;
