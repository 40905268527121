import * as React from "react";
const IconHeadphonesAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.6 25.1h-.2v-3.7C51.3 10.7 42.7 2 32 2s-19.3 8.7-19.3 19.3V25h-.2c-4.3 0-7.8 3.5-7.8 7.8v5.1c0 4.3 3.5 7.8 7.8 7.8h3.7c2.2 0 4-1.8 4-4V29.1c0-2.2-1.8-4-4-4h-1.5v-3.7C14.7 11.8 22.4 4 32 4s17.3 7.8 17.3 17.3V25h-1.5c-2.2 0-4 1.8-4 4v12.6c0 2.2 1.8 4 4 4h1.5c-.2 6-4.8 10.4-11.2 11a3.4 3.4 0 0 0-3.4-3.2H28c-1.9 0-3.4 1.5-3.4 3.4v1.8c0 1.9 1.5 3.4 3.4 3.4h6.8c1.8 0 3.3-1.5 3.4-3.3 7.5-.6 13-5.9 13.2-13h.2c4.3 0 7.8-3.5 7.8-7.8v-5.1c-.1-4.3-3.6-7.7-7.8-7.7m-33.5 4v12.6c0 1.1-.9 2-2 2h-3.7c-3.2 0-5.8-2.6-5.8-5.8v-5.1c0-3.2 2.6-5.8 5.8-5.8h3.7c1.1.1 2 1 2 2.1m18.1 29.5c0 .8-.6 1.4-1.4 1.4H28c-.8 0-1.4-.6-1.4-1.4v-1.8c0-.8.6-1.4 1.4-1.4h6.8c.8 0 1.4.6 1.4 1.4zm21.1-20.7c0 3.2-2.6 5.8-5.8 5.8h-3.7c-1.1 0-2-.9-2-2V29.1c0-1.1.9-2 2-2h3.7c3.2 0 5.8 2.6 5.8 5.8z" />
  </svg>
);
export default IconHeadphonesAlt;
