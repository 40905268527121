import * as React from "react";
const IconStopwatch = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M33 12V4h5.3c.6 0 1-.4 1-1s-.4-1-1-1H25.7c-.6 0-1 .4-1 1s.4 1 1 1H31v8c-13.3.5-24 11.5-24 25 0 13.8 11.2 25 25 25s25-11.2 25-25c0-13.5-10.7-24.5-24-25m-1 48C19.3 60 9 49.7 9 37s10.3-23 23-23 23 10.3 23 23-10.3 23-23 23" />
    <path d="M33 31.8v-9.1c0-.6-.4-1-1-1s-1 .4-1 1v9.1c-2.5.5-4.4 2.7-4.4 5.3 0 3 2.4 5.4 5.4 5.4s5.4-2.4 5.4-5.4c0-2.6-1.9-4.8-4.4-5.3m-1 8.7c-1.9 0-3.4-1.5-3.4-3.4s1.5-3.4 3.4-3.4 3.4 1.5 3.4 3.4-1.5 3.4-3.4 3.4" />
  </svg>
);
export default IconStopwatch;
