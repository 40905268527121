import * as React from "react";
const IconTable2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M46.9 10.6H17.1C8.8 10.6 2 17.3 2 25.6v2.3c0 .6.4 1 1 1h7.3v23.5c0 .6.4 1 1 1s1-.4 1-1V28.9h6.3v14c0 .6.4 1 1 1s1-.4 1-1v-14h22.8v14c0 .6.4 1 1 1s1-.4 1-1v-14h6.3v23.5c0 .6.4 1 1 1s1-.4 1-1V28.9H61c.6 0 1-.4 1-1v-2.3c0-8.3-6.8-15-15.1-15M60 26.9H4v-1.3c0-7.2 5.9-13.1 13.1-13.1h29.8c7.2 0 13.1 5.9 13.1 13.1z" />
  </svg>
);
export default IconTable2;
