import React, { useState } from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";
import BringzzText from "../BringzzText";

/**
 * @typedef {object} BringzzCheckProps
 * @property {React.ReactNode} [left] - Optional left element, usually an icon.
 * @property {{ checked: React.ReactNode, unChecked: React.ReactNode }} state - State icons for checked/unchecked states.
 * @property {function} [onChange] - Function to call when the check value changes.
 * @property {boolean} [defaultChecked] - Whether the check is checked by default.
 * @property {string || object} [value] - The value of the check item.
 * @property {boolean} [controlled] - Whether the check item is controlled by parent.
 * @property {React.ReactNode} [children] - The children nodes of the check item.
 * @property {string} [mode] - Mode of the component, either "default" or "custom".
 */

/**
 * Custom check item component with optional icon and flexible content.
 * @param {BringzzCheckProps} props - The component props.
 * @returns {JSX.Element} - JSX for the custom check item component.
 */
export default function BringzzCheck({
    className,
    left,
    state,
    onChange,
    defaultChecked,
    value,
    controlled,
    children,
    labelClassname,
    mode,
    ...props
}) {
    const [isChecked, setIsChecked] = useState(defaultChecked || false);

    const handleOnChange = () => {
        if (!controlled) {
            setIsChecked(!isChecked);
        }
        if (onChange) {
            onChange(value);
        }
    };

    // Determine checked state based on whether it's controlled
    const checkedState = controlled ? props.checked : isChecked;

    return (
        <div
            className={twMerge(
                "flex items-center cursor-pointer",
                className,
                mode == "default" && "h-12"
            )}
            onClick={handleOnChange}
            {...props}
        >
            {left && <div className="flex-shrink-0">{left}</div>}
            <div className="flex-grow">
                {mode === "custom" ? (
                    children
                ) : (
                    <BringzzText tag="h4" className={twMerge("text-center", labelClassname)}>
                        {children}
                    </BringzzText>
                )}
            </div>
            <div className="flex-shrink-0">
                {checkedState ? state.checked : state.unChecked}
            </div>
            <input type="checkbox" className="hidden" checked={checkedState} readOnly />
        </div>
    );
}

BringzzCheck.propTypes = {
    left: PropTypes.node,
    state: PropTypes.shape({
        checked: PropTypes.node,
        unChecked: PropTypes.node,
    }).isRequired,
    onChange: PropTypes.func,
    defaultChecked: PropTypes.bool,
    labelClassname: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    controlled: PropTypes.bool,
    children: PropTypes.node,
    mode: PropTypes.string,
};

BringzzCheck.defaultProps = {
    controlled: false,
    mode: "default",
};
BringzzCheck.displayName = 'BringzzCheck';
