import * as React from "react";
const IconNoSmoking = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2M4 32c0-7.3 2.8-13.9 7.4-18.9L33.3 35H12.6c-.6 0-1 .4-1 1s.4 1 1 1h22.7l15.6 15.6C45.9 57.2 39.3 60 32 60 16.6 60 4 47.4 4 32m48.3 19.2L38.1 37h5.6c.6 0 1-.4 1-1s-.4-1-1-1h-7.6L12.8 11.7C17.8 6.9 24.6 4 32 4c15.4 0 28 12.6 28 28 0 7.4-2.9 14.2-7.7 19.2" />
    <path d="M48.4 15.6c-.6 0-1 .4-1 1v5.6c0 .1-.4.9-2.3 1.1-1.3.2-2.2 1.2-2.3 2.4-.1 4.1 0 4.6.1 4.8.1.5.5.8 1 .8h.2c.5-.1.9-.7.8-1.2 0-.1-.1-.7 0-4.3 0-.3.2-.5.5-.5 2.9-.3 3.8-1.7 4-2.5.1-.2.1-.4.1-.7v-5.5c-.1-.5-.6-1-1.1-1" />
  </svg>
);
export default IconNoSmoking;
