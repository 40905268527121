import * as React from "react";
const IconLeafCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.7 60.1 47.4 45.9c3.4-3.7 5.2-8.3 5.2-13.4 0-7-3.5-14.3-9.8-20.6C26.2-4.7 5.7 5.1 5.5 5.2l-.3.1-.2.3c-.1.2-9.9 20.5 6.6 37 6.5 6.5 14 9.8 20.9 9.8 4.9 0 9.6-1.7 13.4-5.2l14.3 14.3c.2.2.5.3.7.3.3 0 .5-.1.7-.3.5-.4.5-1 .1-1.4M13.1 41.3C-1.5 26.7 5.5 9.5 6.7 6.9c2.5-1.1 20.1-8.1 34.6 6.4 6 5.9 9.2 12.8 9.2 19.2 0 4.6-1.6 8.7-4.6 12l-9-8.9V21.9c0-.6-.4-1-1-1s-1 .4-1 1v11.7l-.8-.8-10-10c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l8.3 8.2H19.3c-.6 0-1 .4-1 1s.4 1 1 1h13.8l11.5 11.4c-8.4 7.5-21.2 5.7-31.5-4.5" />
  </svg>
);
export default IconLeafCopy;
