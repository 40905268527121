import * as React from "react";
const IconPrayer = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 65"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M28.4 23.4c-.6 0-1 .4-1.1.9l-1.4 16v.2c-.2 1.8-.4 5.1-5.6 5.7-3 .4-7.3-.1-9.6-2.4-.9-.8-1.5-1.8-2.2-2.9-.2-.4-.5-.8-.7-1.2L3.5 33c-.3-.5-.9-.6-1.4-.3s-.6.9-.3 1.4l4.3 6.7c.2.4.5.7.7 1.1.7 1.1 1.4 2.3 2.4 3.3 2.3 2.3 5.9 3.1 9.1 3.1.8 0 1.5 0 2.2-.1 6.8-.8 7.2-5.7 7.4-7.6l1.5-16.2c0-.5-.4-1-1-1" />
    <path d="M9.8 34.2h.1c.5 0 .9-.4 1-.9l1.3-12.7c.1-.5-.3-1-.9-1.1-.5-.1-1 .3-1.1.9L9 33.1c-.1.6.3 1.1.8 1.1M15.7 32.8h.1c.5 0 .9-.4 1-.9l1.4-13c.1-.5-.3-1-.9-1.1-.5-.1-1 .3-1.1.9l-1.4 13c0 .5.4 1 .9 1.1M21.1 33.6h.1c.5 0 .9-.4 1-.9l1.6-15c.1-.5-.3-1-.9-1.1s-1 .3-1.1.9l-1.6 15c0 .6.4 1.1.9 1.1M61.9 32.8c-.5-.3-1.1-.2-1.4.3l-4.3 6.7c-.2.4-.5.8-.7 1.2-.6 1.1-1.3 2.1-2.1 2.9-2.4 2.3-6.7 2.7-9.6 2.4-5.2-.6-5.5-4-5.6-5.7l-1.5-16.2c0-.6-.5-.9-1.1-.9-.5 0-1 .5-.9 1.1l1.4 16v.2c.2 1.8.6 6.7 7.4 7.6.7.1 1.4.1 2.2.1 3.2 0 6.7-.8 9.1-3.1 1-1 1.7-2.2 2.4-3.3.2-.4.4-.7.7-1.1l4.3-6.7c.3-.6.2-1.2-.3-1.5" />
    <path d="M54 34.2h.1c.5-.1.9-.5.9-1.1l-1.3-12.7c-.1-.5-.5-.9-1.1-.9-.5.1-.9.5-.9 1.1L53 33.3c.1.6.5.9 1 .9M48.2 32.8h.1c.5-.1.9-.6.9-1.1l-1.4-13c-.1-.5-.5-.9-1.1-.9-.5.1-.9.6-.9 1.1l1.4 13c0 .5.5.9 1 .9M42.8 33.6h.1c.5-.1.9-.6.9-1.1l-1.6-15c-.1-.5-.6-.9-1.1-.9-.5.1-.9.6-.9 1.1l1.6 15c0 .6.5.9 1 .9" />
  </svg>
);
export default IconPrayer;
