import * as React from "react";
const IconPipe = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 16.5h-4.5c-2.2 0-4 1.8-4 4v1.3h-5.9v-6.7c0-2.1-1.7-3.9-3.9-3.9h-4.8c-2.1 0-3.9 1.7-3.9 3.9v6.7H14.5v-1.3c0-2.2-1.8-4-4-4H6c-2.2 0-4 1.8-4 4v15.1c0 2.2 1.8 4 4 4h4.5c2.2 0 4-1.8 4-4v-1.3H31v14.6c0 2.1 1.7 3.9 3.9 3.9h4.8c2.1 0 3.9-1.7 3.9-3.9V34.3h5.9v1.3c0 2.2 1.8 4 4 4H58c2.2 0 4-1.8 4-4V20.5c0-2.2-1.8-4-4-4m-25-1.4c0-1 .8-1.9 1.9-1.9h4.8c1 0 1.9.8 1.9 1.9v6.7H33zM12.5 35.6c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V20.5c0-1.1.9-2 2-2h4.5c1.1 0 2 .9 2 2v15.1m29 13.3c0 1-.8 1.9-1.9 1.9h-4.8c-1 0-1.9-.8-1.9-1.9V34.3h8.5v14.6zm-27-16.6v-8.5h34.9v8.5zM60 35.6c0 1.1-.9 2-2 2h-4.5c-1.1 0-2-.9-2-2V20.5c0-1.1.9-2 2-2H58c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconPipe;
