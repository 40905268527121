import * as React from "react";
const IconAtmAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 3.2H6c-2.2 0-4 1.8-4 4v49.6c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V7.2c0-2.2-1.8-4-4-4M4 56.8V7.2c0-1.1.9-2 2-2h36.7v53.6H6c-1.1 0-2-.9-2-2m56 0c0 1.1-.9 2-2 2H44.7V5.2H58c1.1 0 2 .9 2 2z" />
    <path d="M32.2 11.7h-17c-2.2 0-4 1.8-4 4v17c0 2.2 1.8 4 4 4h17c2.2 0 4-1.8 4-4v-17c0-2.2-1.8-4-4-4m2 20.9c0 1.1-.9 2-2 2h-17c-1.1 0-2-.9-2-2v-17c0-1.1.9-2 2-2h17c1.1 0 2 .9 2 2zM14.4 41.4h-2.2c-.6 0-1 .4-1 1s.4 1 1 1h2.2c.6 0 1-.4 1-1s-.5-1-1-1M24.8 41.4h-2.2c-.6 0-1 .4-1 1s.4 1 1 1h2.2c.6 0 1-.4 1-1s-.5-1-1-1M14.4 49.6h-2.2c-.6 0-1 .4-1 1s.4 1 1 1h2.2c.6 0 1-.4 1-1s-.5-1-1-1M24.8 49.6h-2.2c-.6 0-1 .4-1 1s.4 1 1 1h2.2c.6 0 1-.4 1-1s-.5-1-1-1M35.1 41.4h-2.2c-.6 0-1 .4-1 1s.4 1 1 1h2.2c.6 0 1-.4 1-1s-.4-1-1-1M35.1 49.6h-2.2c-.6 0-1 .4-1 1s.4 1 1 1h2.2c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconAtmAlt;
