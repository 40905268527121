import * as React from "react";
const IconIdeaChat = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.7 4.9H7.4c-3 0-5.4 2.4-5.4 5.4v32.9c0 3 2.4 5.3 5.4 5.3h4.3v8.1c0 1 .6 1.8 1.4 2.2.3.1.7.2 1 .2.6 0 1.2-.2 1.6-.6l11.1-9.9h29.8c3 0 5.3-2.4 5.3-5.3V10.3c.1-3-2.3-5.4-5.2-5.4M60 43.2c0 1.8-1.5 3.3-3.3 3.3H26.5c-.2 0-.5.1-.7.3L14.4 56.9c-.2.2-.4.1-.5.1s-.3-.2-.3-.4v-9.1c0-.6-.4-1-1-1H7.4C5.5 46.5 4 45 4 43.2V10.3c0-1.8 1.5-3.4 3.4-3.4h49.3c1.8 0 3.3 1.5 3.3 3.4z" />
    <path d="M32.3 13c-2.8-.1-5.5 1-7.5 3s-3.1 4.6-3.1 7.5c0 3.6 1.8 6.9 4.9 8.8.6.4.9 1.1.9 1.8v1.8c0 2.3 1.8 4.1 4.1 4.1h1c2.3 0 4.1-1.8 4.1-4.1V34c0-.4.1-.7.3-1s.4-.6.7-.7c3-1.9 4.8-5.1 4.9-8.6.1-2.8-1-5.4-2.9-7.4-2.1-2-4.7-3.2-7.4-3.3m2.3 22.9c0 1.2-.9 2.1-2.1 2.1h-1c-1.2 0-2.1-.9-2.1-2.1v-2.3h5.2v2.3m1.9-5.3c-.4.3-.8.6-1.1 1h-6.8c-.3-.4-.6-.7-1.1-1-2.5-1.6-4-4.2-4-7.2 0-2.3.9-4.4 2.5-6 1.6-1.5 3.7-2.4 5.9-2.4h.2c2.2.1 4.3 1 5.9 2.7s2.4 3.8 2.3 6c0 2.8-1.4 5.4-3.8 6.9" />
  </svg>
);
export default IconIdeaChat;
