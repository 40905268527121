import * as React from "react";
const IconSofa7 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.7 21.3h-1.6c-1.2 0-2.4.5-3.3 1.2v-6.9c0-2.9-2.4-5.3-5.3-5.3H17.4c-2.9 0-5.3 2.4-5.3 5.3v6.9c-.9-.7-2-1.2-3.3-1.2H7.3c-2.9 0-5.3 2.4-5.3 5.3V40c0 3.7 3 6.7 6.6 6.7v6c0 .6.4 1 1 1s1-.4 1-1v-6h42.7v6c0 .6.4 1 1 1s1-.4 1-1v-6c3.7 0 6.6-3 6.6-6.7V26.5c.1-2.9-2.3-5.2-5.2-5.2m-39.3-9h29.1c1.8 0 3.3 1.5 3.3 3.3v16.2H14.2V15.6c0-1.9 1.4-3.3 3.2-3.3M60 40c0 2.6-2.1 4.7-4.7 4.7H8.7C6.1 44.7 4 42.6 4 40V26.5c0-1.8 1.5-3.3 3.3-3.3h1.6c1.8 0 3.3 1.4 3.3 3.2v6.2c0 .6.4 1 1 1h37.7c.6 0 1-.4 1-1v-6.2c0-1.8 1.5-3.3 3.3-3.3h1.6c1.8 0 3.3 1.5 3.3 3.3V40z" />
  </svg>
);
export default IconSofa7;
