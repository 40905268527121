import * as React from "react";
const Icon5gAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M14.6 31.4c.6 0 1-.4 1-1s-.4-1-1-1H3c-.6 0-1 .4-1 1v10.9c0 .6.4 1 1 1h10.2c.2 0 .4.2.4.4v8.1c0 .2-.2.4-.4.4H3c-.6 0-1 .4-1 1s.4 1 1 1h10.2c1.3 0 2.4-1.1 2.4-2.4v-8.1c0-1.3-1.1-2.4-2.4-2.4H4v-8.9zM26.8 31.4H37c.6 0 1-.4 1-1s-.4-1-1-1H26.8c-1.3 0-2.4 1.1-2.4 2.4v18.9c0 1.3 1.1 2.4 2.4 2.4h8.8c1.3 0 2.4-1.1 2.4-2.4V44c0-1.3-1.1-2.4-2.4-2.4h-3c-.6 0-1 .4-1 1s.4 1 1 1h3c.2 0 .4.2.4.4v6.7c0 .2-.2.4-.4.4h-8.8c-.2 0-.4-.2-.4-.4V31.8c0-.3.2-.4.4-.4M45.3 10.9c-.6 0-1 .4-1 1s.4 1 1 1c8.1 0 14.7 6.6 14.7 14.7 0 .6.4 1 1 1s1-.4 1-1c0-9.2-7.5-16.7-16.7-16.7M42.5 20.3c-.6 0-1 .4-1 1s.4 1 1 1c4.4 0 8.1 3.6 8.1 8.1 0 .6.4 1 1 1s1-.4 1-1c0-5.6-4.5-10.1-10.1-10.1" />
  </svg>
);
export default Icon5gAlt1;
