import * as React from "react";
const IconOven = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.6 2H7.4c-2.2 0-4 1.8-4 4v52c0 2.2 1.8 4 4 4h49.2c2.2 0 4-1.8 4-4V6c0-2.2-1.8-4-4-4m2 56c0 1.1-.9 2-2 2H7.4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2h49.2c1.1 0 2 .9 2 2z" />
    <path d="M21.2 14.1c0-2.8-2.3-5.1-5.1-5.1S11 11.3 11 14.1s2.3 5.1 5.1 5.1 5.1-2.3 5.1-5.1m-8.1 0c0-1.7 1.4-3.1 3.1-3.1s3.1 1.4 3.1 3.1-1.4 3.1-3.1 3.1-3.1-1.4-3.1-3.1M47.9 9c-2.8 0-5.1 2.3-5.1 5.1s2.3 5.1 5.1 5.1 5.1-2.3 5.1-5.1S50.7 9 47.9 9m0 8.1c-1.7 0-3.1-1.4-3.1-3.1s1.4-3.1 3.1-3.1S51 12.3 51 14s-1.4 3.1-3.1 3.1M24.9 24h14.2c.6 0 1-.4 1-1s-.4-1-1-1H24.9c-.6 0-1 .4-1 1s.5 1 1 1M46.8 30.9H17.2c-2.2 0-4 1.8-4 4v14.5c0 2.2 1.8 4 4 4h29.5c2.2 0 4-1.8 4-4V34.9c.1-2.2-1.7-4-3.9-4m2 18.5c0 1.1-.9 2-2 2H17.2c-1.1 0-2-.9-2-2V34.9c0-1.1.9-2 2-2h29.5c1.1 0 2 .9 2 2v14.5z" />
    <path d="m29.1 36.9-1.6 1.6c-1.1 1.1-1.1 2.9 0 4 .2.2.2.4.2.6s-.1.4-.2.6l-2 2c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l2-2c.5-.5.8-1.2.8-2s-.3-1.5-.8-2c-.3-.3-.3-.8 0-1.2l1.6-1.6c.4-.4.4-1 0-1.4s-1-.4-1.4 0M37.2 36.9l-1.6 1.6c-.5.5-.8 1.2-.8 2s.3 1.5.8 2c.2.2.2.4.2.6s-.1.4-.2.6l-2 2c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l2-2c.5-.5.8-1.2.8-2s-.3-1.5-.8-2c-.3-.3-.3-.8 0-1.2l1.6-1.6c.4-.4.4-1 0-1.4s-1-.4-1.4 0" />
  </svg>
);
export default IconOven;
