import * as React from "react";
const IconCms = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.9 6.4H7.1C4.3 6.4 2 8.8 2 11.6v29.5c0 2.8 2.3 5.1 5.1 5.1h19.1l-1.5 9.2v.2h-7.8c-.6 0-1 .4-1 1s.4 1 1 1h30.2c.6 0 1-.4 1-1s-.4-1-1-1h-7.8v-.2l-1.5-9.2h19.1c2.8 0 5.1-2.3 5.1-5.1V11.6c0-2.8-2.3-5.2-5.1-5.2m-42.7 37 3.6-2c.8-.4 1.2-1.3 1.1-2.2-.1-.8-.1-1.4-.1-2.1 0-.8.1-1.5.2-2.1.2-.9-.3-1.8-1.1-2.3l-3.6-2 3.2-5.5c.6.3 2.7 1.5 3.7 2.1.7.4 1.7.3 2.3-.2.3-.2.5-.4.8-.6.7-.6 1.3-1.1 2.1-1.5.9-.5 1.5-1.4 1.5-2.5v-2.6c0-.4.3-.7.7-.7h6.9c.4 0 .7.3.7.7v2.6c0 1 .6 2 1.5 2.5.7.3 1.3.8 2 1.4.3.2.6.5.9.7.6.5 1.6.6 2.3.2l3.7-2.1 3.2 5.5-3.6 2c-.8.4-1.2 1.4-1.1 2.3.1.7.2 1.4.2 2.1s-.1 1.4-.2 2.1c-.2.9.3 1.8 1.1 2.3l3.5 1.9-.5.9H14.7zm23.1 12.2H26.7l1.6-9.3h7.4zM60 41.1c0 1.7-1.4 3.1-3.1 3.1h-5.3l.2-.4c.2-.3.2-.7.1-1.1s-.3-.7-.7-.9l-4.1-2.2c-.1 0-.1-.1-.1-.2.1-.8.2-1.6.2-2.4 0-.9-.1-1.7-.2-2.4 0-.1 0-.2.1-.2l4.1-2.3c.6-.4.9-1.2.5-2L48 23.7c-.4-.7-1.2-.9-1.9-.6 0 0-.1 0-.1.1l-4.2 2.3c-.3-.2-.6-.5-.8-.7-.7-.6-1.4-1.2-2.4-1.7-.3-.1-.4-.4-.4-.7v-2.6c0-1.5-1.2-2.7-2.7-2.7h-6.9c-1.5 0-2.7 1.2-2.7 2.7v2.6c0 .3-.2.5-.4.7-1 .5-1.7 1.1-2.5 1.7-.2.2-.5.4-.8.6-1.2-.7-3.9-2.2-3.9-2.2-1.4-.8-2.1.2-2.3.4l-3.8 6.5c-.4.7-.1 1.6.6 2l4.1 2.2s.1.1.1.2c-.1.7-.2 1.6-.2 2.4s0 1.5.1 2.4c0 .1 0 .1-.1.2l-4.1 2.3c-.7.4-.9 1.2-.6 2l.2.3H7.1C5.4 44.1 4 42.7 4 41V11.6c0-1.7 1.4-3.1 3.1-3.1h49.7c1.7 0 3.1 1.4 3.1 3.1v29.5z" />
    <path d="M38.8 37.2a6.7 6.7 0 1 0-6.7 6.7c3.6.1 6.7-3 6.7-6.7m-11.5 0c0-2.6 2.1-4.7 4.7-4.7s4.7 2.1 4.7 4.7S34.6 42 32 42s-4.7-2.2-4.7-4.8" />
  </svg>
);
export default IconCms;
