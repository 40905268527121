import React, { useState, cloneElement } from "react";
import PropTypes from "prop-types";

/**
 * @typedef {object} BringzzMultiSelectProps
 * @property {React.ReactNode} children - The child BringzzCheck components.
 * @property {function} onChange - Function to call when the selected items change.
 * @property {string[]} defaultValues - The default values of the selected items.
 * @property {{ checked: React.ReactNode, unChecked: React.ReactNode }} state - State icons for checked/unchecked states.
 * @property {React.ReactNode} [left] - Optional left element, usually an icon, to be used as a default for all check items.
 */

/**
 * A list component for managing a group of BringzzCheck components.
 * @param {BringzzMultiSelectProps} props - The component props.
 * @returns {JSX.Element} - JSX for the multi-select list component.
 */
export default function BringzzMultiSelect({
    children,
    onChange,
    defaultValues = [],
    state,
    left,
    className,
    ...parentProps
}) {
    const [selectedValues, setSelectedValues] = useState(defaultValues);

    const handleOnChange = (newValue) => {
        let newSelectedValues;
        if (selectedValues.includes(newValue)) {
            newSelectedValues = selectedValues.filter((value) => value !== newValue);
        } else {
            newSelectedValues = [...selectedValues, newValue];
        }
        setSelectedValues(newSelectedValues);
        if (onChange) {
            onChange(newSelectedValues);
        }
    };

    const clonedChildren = React.Children.map(children, (child) => {
        if (React.isValidElement(child) && child.type.displayName === "BringzzCheck") {
            // Build props for the child
            const childProps = {
                ...parentProps,
                state: child.props.state || state,
                onChange: handleOnChange,
                checked: selectedValues.includes(child.props.value),
                controlled: true,
                value: child.props.value,
            };

            // If the child does not have its own icon, use the default icon
            if (!child.props.left) {
                childProps.left = left;
            }

            return cloneElement(child, childProps);
        }
        return child;
    });

    return <div className={className}>{clonedChildren}</div>;
}

BringzzMultiSelect.propTypes = {
    children: PropTypes.node.isRequired,
    onChange: PropTypes.func,
    defaultValues: PropTypes.arrayOf(PropTypes.string),
    state: PropTypes.shape({
        checked: PropTypes.node.isRequired,
        unChecked: PropTypes.node.isRequired,
    }).isRequired,
    left: PropTypes.node,
};
