import * as React from "react";
const IconNailAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M45.2 24.4c-.2-.2-.5-.4-.7-.7 0 0-.1 0-.1-.1C43.1 15.5 39 7.9 32.7 2.3c-.4-.3-1-.3-1.3 0C25 7.9 20.9 15.5 19.6 23.7c0 0-.1 0-.1.1-3.9 3.6-6.2 8.6-6.2 13.9V52c0 5.5 4.5 10 10 10h17.4c5.5 0 10-4.5 10-10V37.6c0-5-2-9.7-5.5-13.2M32 4.4c6.9 6.5 10.9 15.6 10.9 25.1v9.3c0 5.3-4.3 9.6-9.6 9.6h-2.6c-5.3 0-9.6-4.3-9.6-9.6v-9.3c0-9.5 4-18.6 10.9-25.1M48.7 52c0 4.4-3.6 8-8 8H23.3c-4.4 0-8-3.6-8-8V37.6c0-3.9 1.4-7.7 3.9-10.7-.1.8-.1 1.7-.1 2.6v9.3c0 6.4 5.2 11.6 11.6 11.6h2.6c6.4 0 11.6-5.2 11.6-11.6v-9.3c0-.9 0-1.7-.1-2.6 2.5 3 3.9 6.8 3.9 10.7z" />
  </svg>
);
export default IconNailAlt;
