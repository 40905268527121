import * as React from "react";
const IconSmartHomeControl = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M31.3 11.3h1.4c.6 0 1-.4 1-1s-.4-1-1-1h-1.4c-.6 0-1 .4-1 1s.4 1 1 1M34.2 52.8h-4.3c-.6 0-1 .4-1 1s.4 1 1 1h4.3c.6 0 1-.4 1-1s-.5-1-1-1" />
    <path d="M46.5 2h-29c-2.2 0-4 1.8-4 4v52c0 2.2 1.8 4 4 4h29c2.2 0 4-1.8 4-4V6c0-2.2-1.8-4-4-4m2 56c0 1.1-.9 2-2 2h-29c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2h29c1.1 0 2 .9 2 2z" />
    <path d="M32.6 24.9c-.4-.3-.9-.3-1.2 0l-8.7 6.6c-.4.3-.5 1-.2 1.4.2.3.5.4.8.4.2 0 .4-.1.6-.2l2.3-1.7v7c0 .6.4 1 1 1h9.6c.6 0 1-.4 1-1v-7l2.3 1.7c.4.3 1.1.3 1.4-.2.3-.4.3-1.1-.2-1.4zm3.2 5.3v7.1h-7.6v-7.1c0-.1 0-.2-.1-.3L32 27l3.9 2.9c-.1.1-.1.2-.1.3" />
  </svg>
);
export default IconSmartHomeControl;
