import * as React from "react";
const IconOffer = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m59.8 27-2.4-1.9c-.6-.5-.8-1.2-.7-1.9l.8-2.9c.5-1.8.1-3.7-1-5.3-1.2-1.6-3-2.6-5-2.7l-3.1-.2c-.8 0-1.6-.5-1.8-1.2l-1.1-2.7c-.7-1.7-2.2-3.1-4-3.7-1.9-.6-3.9-.4-5.6.6l-2.6 1.6c-.7.4-1.6.4-2.4 0l-2.6-1.6c-1.7-1-3.7-1.3-5.6-.6-1.8.6-3.3 1.9-4 3.7L17.4 11c-.3.7-1 1.2-1.8 1.2l-3.1.2c-2 .1-3.9 1.1-5 2.7-1.1 1.5-1.5 3.5-1 5.3l.8 2.9c.2.7-.1 1.4-.7 1.9L4.2 27c-1.6 1.2-2.4 3-2.4 5 0 1.9.9 3.7 2.4 5l2.4 1.9c.6.5.9 1.2.7 1.9l-.8 2.9c-.5 1.8-.1 3.7 1 5.3 1.2 1.6 3 2.6 5 2.7l3.1.2c.8 0 1.6.5 1.8 1.2l1.1 2.7c.7 1.7 2.2 3.1 4 3.7 1.9.6 3.9.4 5.6-.6l2.6-1.6c.7-.4 1.6-.4 2.4 0l2.6 1.6c1.1.6 2.3 1 3.5 1q1.05 0 2.1-.3c1.8-.6 3.3-1.9 4-3.7l1.1-2.7c.3-.7 1-1.2 1.8-1.2l3.1-.2c2-.1 3.9-1.1 5-2.7 1.1-1.5 1.5-3.5 1-5.3l-.8-2.9c-.2-.7.1-1.4.7-1.9l2.4-1.9c1.6-1.2 2.4-3 2.4-5 .3-2-.6-3.8-2.2-5.1M57 33.4l-2.4 1.9c-2 1.6-2.9 4.2-2.2 6.6l.8 2.9c.2.7-.1 1.2-.3 1.5-.4.5-1 .8-1.6.9l-3.1.2c-2.5.1-4.8 1.7-5.7 4L41.3 54c-.3.7-.9 1-1.3 1.1-.6.2-1.3.1-1.9-.2l-2.6-1.6c-1.1-.6-2.3-1-3.5-1s-2.4.3-3.5 1l-2.6 1.6c-.6.3-1.3.4-1.9.2-.3-.1-1-.4-1.3-1.1l-1.1-2.7c-.9-2.3-3.2-3.9-5.7-4l-3.1-.2c-.7 0-1.3-.4-1.6-.9-.2-.3-.5-.8-.3-1.5l.8-2.9c.7-2.4-.2-5-2.2-6.6L7 33.4c-.5-.4-.7-.9-.7-1.4 0-.6.3-1.1.7-1.4l2.4-1.9c2-1.6 2.9-4.2 2.2-6.6l-.8-2.9c-.2-.7.1-1.2.3-1.5.4-.5 1-.8 1.6-.9l3.1-.2c2.5-.1 4.8-1.7 5.7-4l1.1-2.7c.4-.6 1-.9 1.4-1 .6-.2 1.3-.1 1.9.2l2.6 1.6c2.1 1.3 4.9 1.3 7 0l2.6-1.6c.6-.3 1.3-.4 1.9-.2.3.1 1 .4 1.3 1.1l1.1 2.7c.9 2.3 3.2 3.9 5.7 4l3.1.2c.7 0 1.3.4 1.6.9.2.3.5.8.3 1.5l-.8 2.9c-.7 2.4.2 5 2.2 6.6l2.4 1.9c.5.4.7.9.7 1.4.2.5-.1 1-.6 1.3" />
    <path d="M41.2 22.8c-.9-.9-2.3-.9-3.2 0L22.8 38c-.9.9-.9 2.3 0 3.2.4.4 1 .7 1.6.7s1.2-.2 1.6-.7L41.2 26c.9-.9.9-2.4 0-3.2M29.2 29.1c2.4-2.4 2.4-6.2 0-8.6-1.1-1.1-2.7-1.8-4.3-1.8s-3.1.6-4.3 1.8c-2.4 2.4-2.4 6.2 0 8.6 1.1 1.1 2.7 1.8 4.3 1.8s3.1-.6 4.3-1.8m-5.4-5.3c.3-.3.7-.5 1.1-.5s.8.2 1.1.5c.6.6.6 1.6 0 2.2s-1.6.6-2.2 0-.6-1.6 0-2.2M34.5 34.5c-1.1 1.1-1.8 2.7-1.8 4.3s.6 3.1 1.8 4.3 2.7 1.8 4.3 1.8 3.1-.6 4.3-1.8c1.1-1.1 1.8-2.7 1.8-4.3s-.6-3.1-1.8-4.3c-2.4-2.4-6.2-2.4-8.6 0m5.4 5.4c-.6.6-1.6.6-2.2 0-.3-.3-.5-.7-.5-1.1s.2-.8.5-1.1.7-.5 1.1-.5.8.2 1.1.5.5.7.5 1.1-.2.8-.5 1.1" />
  </svg>
);
export default IconOffer;
