import * as React from "react";
const IconInternetAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.7 38.8c2.9-4 4.5-8.7 4.5-13.6 0-4.1-1.1-8.2-3.2-11.7C47.8 6.4 40.5 2.1 32.4 2h-.7c-8.1.1-15.5 4.4-19.6 11.4C10 16.9 8.9 21 8.9 25.1c0 4.9 1.5 9.7 4.5 13.7C17.5 44.6 24 48 31 48.2V52c-2 .4-3.6 2-4 4H16.1c-.6 0-1 .4-1 1s.4 1 1 1H27c.5 2.3 2.5 4.1 5 4.1s4.5-1.7 5-4.1h10.9c.6 0 1-.4 1-1s-.4-1-1-1H37c-.4-2-2-3.6-4-4v-3.7c7.1-.3 13.5-3.8 17.7-9.5m2.4-13.7c0 4.3-1.3 8.4-3.7 11.9-.1-.1-.2-.1-.3-.1-3.3-1.1-6.7-1.8-10.1-2.2q1.2-4.65 1.2-9.6c0-2.5-.2-4.9-.7-7.4 3.8-.5 7.4-1.4 11-2.6h.1c1.7 3.1 2.5 6.5 2.5 10M32 46.2c-2-3.1-3.5-6.4-4.5-9.8 3-.2 6.1-.2 9.1 0-1.1 3.4-2.6 6.7-4.6 9.8m0-42.1q3.6 5.7 5.1 12c-3.3.3-6.9.3-10.3 0 1.1-4.3 2.8-8.3 5.2-12M26.4 18c1.8.2 3.7.3 5.6.3s3.8-.1 5.6-.3c.4 2.3.7 4.7.7 7.1q0 4.8-1.2 9.3c-3.3-.3-6.8-.3-10.1 0-.8-3-1.2-6.2-1.2-9.3 0-2.3.2-4.7.6-7.1m23.2-4.6c-3.4 1.2-6.9 2-10.4 2.4-1-4.1-2.6-8-4.7-11.7 6.1.7 11.6 4.1 15.1 9.3m-20-9.2c-2.2 3.7-3.8 7.6-4.7 11.7-3.6-.5-7.1-1.3-10.4-2.4 3.4-5.3 8.9-8.6 15.1-9.3M10.9 25.1c0-3.5.9-6.9 2.5-10 3.6 1.3 7.3 2.1 11.1 2.6-.5 2.4-.7 4.9-.7 7.4 0 3.2.4 6.5 1.2 9.6-3.4.4-6.8 1.2-10.1 2.2-.1 0-.2.1-.3.1-2.4-3.5-3.7-7.6-3.7-11.9m4.9 13.6c3.1-1 6.4-1.7 9.6-2.1 1 3.3 2.4 6.5 4.2 9.5-5.4-.6-10.3-3.2-13.8-7.4m19.3 18.2c0 1.7-1.4 3.1-3.1 3.1s-3.1-1.4-3.1-3c0-1.7 1.4-3 3.1-3 1.7-.1 3.1 1.2 3.1 2.9m-.7-10.8c1.8-3 3.2-6.2 4.2-9.5q4.95.6 9.6 2.1c-3.5 4.2-8.4 6.8-13.8 7.4" />
  </svg>
);
export default IconInternetAlt;
