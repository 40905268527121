import * as React from "react";
const IconTv1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.8 14.8H34.3L41 3.6c.3-.5.1-1.1-.4-1.4s-1.1-.1-1.4.4L32 14.8h-2.1l-7.2-8.6c-.4-.4-1-.5-1.4-.1s-.5 1-.1 1.4l6.1 7.3h-19c-2.2 0-4 1.8-4 4v32.9c0 2.2 1.8 4 4 4h3.6V61c0 .6.4 1 1 1s1-.4 1-1v-5.4h36.2V61c0 .6.4 1 1 1s1-.4 1-1v-5.4h3.6c2.2 0 4-1.8 4-4V18.8c.1-2.3-1.7-4-3.9-4m2 36.8c0 1.1-.9 2-2 2H8.2c-1.1 0-2-.9-2-2V18.8c0-1.1.9-2 2-2h47.5c1.1 0 2 .9 2 2v32.8z" />
  </svg>
);
export default IconTv1;
