import * as React from "react";
const IconVrTech = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m53.6 59.9-5.7-6.3 3.6-2.1c1.2-.7 2-2 2-3.5V25.6c0-1.6-.9-3-2.3-3.6l-17.5-8.1c-.2-.1-.5-.2-.7-.2V2.9c0-.6-.4-1-1-1s-1 .4-1 1v10.8c-.2.1-.5.1-.7.2L12.8 22c-1.4.7-2.3 2.1-2.3 3.6V48c0 1.4.8 2.8 2 3.5l3.6 2.1-5.7 6.3c-.4.4-.3 1 .1 1.4.2.2.4.3.7.3s.5-.1.7-.3l6-6.6 12.1 7c.6.4 1.3.5 2 .5s1.4-.2 2-.5l12.1-7 6 6.6c.2.2.5.3.7.3s.5-.1.7-.3c.5-.4.5-1 .1-1.4M31 15.8V21c0 .6.4 1 1 1s1-.4 1-1v-5.2l17.2 8L32 32.1l-18.3-8.4zM19.2 53l3.9-4.3c.4-.4.3-1-.1-1.4s-1-.3-1.4.1L17.5 52l-4-2.3c-.6-.4-1-1-1-1.7V25.4L31 33.9v26zM33 59.8v-26l18.5-8.5V48c0 .7-.4 1.4-1 1.7l-4 2.3-4.2-4.6c-.4-.4-1-.4-1.4-.1-.4.4-.4 1-.1 1.4l3.9 4.3z" />
  </svg>
);
export default IconVrTech;
