import * as React from "react";
const IconVirus = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M22.5 22.4c-2.5 0-4.6 2.1-4.6 4.6s2.1 4.6 4.6 4.6 4.6-2.1 4.6-4.6-2.1-4.6-4.6-4.6m0 7.2c-1.4 0-2.6-1.2-2.6-2.6s1.2-2.6 2.6-2.6 2.6 1.2 2.6 2.6-1.2 2.6-2.6 2.6M35.2 32.6c-3.3 0-6 2.7-6 6s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6m0 9.9c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4c0 2.3-1.8 4-4 4M38.2 21.1c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4m0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-1 2-2 2" />
    <path d="M61 31h-6.1c-.1 0-.2 0-.3.1-.2-5.5-2.4-10.5-6-14.3.1 0 .2-.1.3-.2l4.3-4.3c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-4.3 4.3c-.1.1-.1.2-.2.3-3.8-3.5-8.8-5.7-14.3-6V3c0-.6-.4-1-1-1s-1 .4-1 1v6.1c0 .1 0 .2.1.3-5.5.2-10.5 2.4-14.3 6 0-.1-.1-.2-.2-.3l-4.3-4.3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l4.3 4.3c.1.1.2.1.3.2-3.5 3.8-5.7 8.8-6 14.3H3c-.6 0-1 .4-1 1s.4 1 1 1h6.1c.1 0 .2 0 .3-.1.2 5.5 2.4 10.5 6 14.3-.1 0-.2.1-.3.2l-4.3 4.3c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l4.3-4.3c.1-.1.1-.2.2-.3 3.8 3.5 8.8 5.7 14.3 6 0 .1-.1.2-.1.3V61c0 .6.4 1 1 1s1-.4 1-1v-6.1c0-.1 0-.2-.1-.3 5.5-.2 10.5-2.4 14.3-6 0 .1.1.2.2.3l4.3 4.3c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-4.3-4.3c-.1-.1-.2-.1-.3-.2 3.5-3.8 5.7-8.8 6-14.3.1 0 .2.1.3.1H61c.6 0 1-.4 1-1 0-.7-.4-1.1-1-1.1M32 52.6c-11.4 0-20.6-9.2-20.6-20.6S20.6 11.4 32 11.4 52.6 20.6 52.6 32 43.4 52.6 32 52.6" />
  </svg>
);
export default IconVirus;
