import * as React from "react";
const IconSchoolBus = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M14.2 37.3c-2.3 0-4.2 1.9-4.2 4.2s1.9 4.2 4.2 4.2 4.2-1.9 4.2-4.2-1.9-4.2-4.2-4.2m0 6.5c-1.2 0-2.2-1-2.2-2.2s1-2.2 2.2-2.2 2.2 1 2.2 2.2-1 2.2-2.2 2.2M45.6 41.6c0 2.3 1.9 4.2 4.2 4.2s4.2-1.9 4.2-4.2-1.9-4.2-4.2-4.2c-2.3-.1-4.2 1.8-4.2 4.2m6.4 0c0 1.2-1 2.2-2.2 2.2s-2.2-1-2.2-2.2 1-2.2 2.2-2.2 2.2.9 2.2 2.2" />
    <path d="M61 9.3c-.6 0-1 .4-1 1v8c0 1.2-.7 2.2-1.8 2.7L54 22.7V10.4c0-2-1.3-3.9-3.2-4.6C33.7-.6 18.7 3.7 13 5.8c-1.9.7-3.2 2.5-3.2 4.6v12.4l-4-1.8C4.7 20.5 4 19.5 4 18.3v-8c0-.6-.4-1-1-1s-1 .4-1 1v8c0 2 1.2 3.7 3 4.5l4.9 2V27c-2.7.6-4.7 3-4.7 5.8v23.8c0 2.7 2.2 4.9 4.9 4.9h5.4c2.7 0 4.9-2.2 4.9-4.9v-5.1h23.2v5.1c0 2.7 2.2 4.9 4.9 4.9h5.4c2.7 0 4.9-2.2 4.9-4.9V32.9c0-2.9-2-5.2-4.7-5.8V25l5-2.1c1.8-.8 3-2.5 3-4.5v-8c-.1-.6-.5-1.1-1.1-1.1M33 26.9V14.6h19v12.2H33zM11.9 14.6H31v12.2H11.9zm1.8-7c5.4-2 19.9-6.1 36.4 0 1.1.4 1.9 1.5 1.9 2.7v2.3H11.9v-2.3c0-1.2.7-2.2 1.8-2.7m4.7 49.1c0 1.6-1.3 2.9-2.9 2.9h-5.4c-1.6 0-2.9-1.3-2.9-2.9v-5.1h11.1v5.1zm21.9-7.1H23.7v-4.2c0-1.6 1.3-2.9 2.9-2.9h10.9c1.6 0 2.9 1.3 2.9 2.9v4.2zm16.4 7.1c0 1.6-1.3 2.9-2.9 2.9h-5.4c-1.6 0-2.9-1.3-2.9-2.9v-5.1h11.1v5.1zm0-23.8v16.7H42.3v-4.2c0-2.7-2.2-4.9-4.9-4.9H26.6c-2.7 0-4.9 2.2-4.9 4.9v4.2H7.2V32.9c0-2.2 1.8-4 4-4h41.5c2.2 0 4 1.8 4 4" />
  </svg>
);
export default IconSchoolBus;
