import * as React from "react";
const IconEyeAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 25.7c-3.5 0-6.3 2.8-6.3 6.3s2.8 6.3 6.3 6.3 6.3-2.8 6.3-6.3-2.8-6.3-6.3-6.3m0 10.6c-2.4 0-4.3-1.9-4.3-4.3s1.9-4.3 4.3-4.3 4.3 1.9 4.3 4.3-1.9 4.3-4.3 4.3" />
    <path d="M61.8 31.2C55.3 21.1 44.2 15 32 15S8.7 21.1 2.2 31.2c-.3.5-.3 1.1 0 1.5C8.7 42.9 19.8 49 32 49s23.3-6.1 29.8-16.3c.3-.4.3-1 0-1.5M32 47c-11.4 0-21.8-5.6-27.9-15 6.1-9.4 16.5-15 27.9-15s21.8 5.6 27.9 15C53.8 41.4 43.4 47 32 47m28.1-15.3" />
  </svg>
);
export default IconEyeAlt;
