import * as React from "react";
const IconWorld2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.9 16.8C52.5 7.7 43 2.1 32.5 2h-2.1c-10.1.6-19.1 6-24.3 14.8C3.4 21.4 2 26.6 2 32c0 6.4 2 12.5 5.8 17.7 5.4 7.4 13.6 11.8 22.6 12.2.1 0 .2.1.3.1h1.7c9.5-.1 18.2-4.6 23.8-12.3C60 44.5 62 38.4 62 32c0-5.4-1.4-10.6-4.1-15.2M60 32c0 5.7-1.7 11.2-5 15.9-4-1.2-9.6-2.6-14.8-3.2 1-4.1 1.6-8.4 1.6-12.7 0-3.3-.3-6.6-.9-9.8 5.2-.6 10.4-1.8 15.3-3.5.1 0 .2-.1.3-.2C58.8 22.6 60 27.3 60 32M31.6 59.8l-.1.2h-.3l-.1-.2c-2.7-4.2-4.7-8.6-6.1-13.2.2 0 .3 0 .5-.1 4-.4 8.2-.4 12.2-.1-1.4 4.7-3.4 9.2-6.1 13.4M22.8 32c0-3.3.3-6.5 1-9.7.3 0 .5.1.8.1 2.4.2 4.9.4 7.4.4 2.3 0 4.7-.1 6.9-.3q.9 4.8.9 9.6c0 4.2-.5 8.4-1.6 12.5-4.2-.4-8.7-.4-12.9 0-.3 0-.6.1-.9.1-1-4.2-1.6-8.4-1.6-12.7m8.4-28h.3l.1.2q4.8 7.65 6.9 16.2c-4.4.4-9.2.4-13.6-.1-.2 0-.4 0-.6-.1 1.3-5.6 3.6-11.1 6.9-16.1zm24.3 12.8c-4.8 1.7-9.9 2.8-15 3.4-1.3-5.7-3.5-11.1-6.6-16.1 8.8.5 16.7 5.2 21.6 12.7M28.7 4.2c-3 5-5.2 10.3-6.5 15.8-4.7-.6-9.3-1.7-13.7-3.3 4.6-7 11.9-11.5 20.2-12.5M7.5 18.5c4.6 1.7 9.4 2.8 14.3 3.5-.6 3.3-1 6.6-1 10 0 4.4.5 8.7 1.6 12.9-4.8.7-9.8 1.9-13.5 3.1C5.7 43.2 4 37.7 4 32c0-4.7 1.2-9.4 3.5-13.5m2.8 31.1c3.6-1.1 8.2-2.2 12.7-2.8 1.3 4.5 3.2 8.9 5.8 13-7.3-.8-13.9-4.4-18.5-10.2m23.5 10.3c2.6-4.2 4.6-8.7 5.9-13.3 4.8.5 10.1 1.8 14 3-4.9 6.1-12 9.8-19.9 10.3" />
  </svg>
);
export default IconWorld2;
