import * as React from "react";
const IconHandGrip = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m58.4 55.8-6.2-21.7c-.2-.5-.7-.8-1.2-.7l-3.6 1-7.2-24.9c-.1-4.3-3.6-7.8-8-7.8s-7.9 3.5-8 7.8l-7.1 25-4-1.2c-.5-.1-1.1.2-1.2.7L5.6 55.8c-.3 1-.2 2.1.3 3s1.4 1.6 2.4 1.9l4.6 1.3c.4.1.7.2 1.1.2 1.7 0 3.3-1.1 3.8-2.9L24 37.6c.1-.3 0-.5-.1-.8-.1-.2-.3-.4-.6-.5L19 35.1l6.2-21.6c1.3 2.5 4 4.2 7 4.2s5.7-1.7 7-4.2L45.4 35l-4.8 1.4c-.3.1-.5.2-.6.5-.1.2-.2.5-.1.8l6.2 21.7c.5 1.8 2.1 2.9 3.8 2.9.4 0 .7 0 1.1-.2l4.6-1.3c2.1-.6 3.4-2.9 2.8-5m-42.4 3c-.3 1.1-1.4 1.7-2.5 1.4l-4.6-1.3c-.5-.1-.9-.5-1.2-1s-.3-1-.2-1.5l5.9-20.8 4 1.2 4.4 1.2zm16.2-43.1c-3.3 0-6-2.7-6-6s2.7-6 6-6 6 2.7 6 6-2.7 6-6 6m22.9 43.1-4.6 1.3c-.5.2-1.1.1-1.5-.1-.5-.3-.8-.7-1-1.2L42.1 38l8.4-2.4 5.9 20.8c.4 1-.3 2.1-1.3 2.4" />
  </svg>
);
export default IconHandGrip;
