import * as React from "react";
const IconPi = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.4 46.7c-.5 0-1 .4-1 1 0 .1-.1 3.1-1.6 6.1-.9 1.8-2.1 3.3-3.6 4.3-1.9 1.3-4.2 2-6.8 2-2.8 0-5.1-1-6.7-2.8-1.5-1.7-2.4-4.1-2.7-7.1-.2-2.1 0-3.8 0-4 .1-.7 2.7-30.5 3-33.3h10.6c1.6 0 3.2-.8 4.2-2.1l5.4-7.2c.3-.4.2-1.1-.2-1.4s-1.1-.2-1.4.2l-5.4 7.2c-.6.8-1.6 1.3-2.6 1.3H17.1c-1.6 0-3.1.7-4.1 1.9L3.8 24c-.4.4-.3 1.1.1 1.4.2.2.4.2.6.2.3 0 .6-.1.8-.4L14.6 14c.6-.8 1.5-1.2 2.5-1.2h7.6c-.1.8-.1 1.7-.2 2.7-.5 7.8-1.1 18.6-3 27.6-1 5.1-2.3 8.9-3.9 11.7-2 3.5-4.5 5.3-7.4 5.3-.6 0-1 .4-1 1s.4 1 1 1c3.7 0 6.8-2.1 9.1-6.3 1.7-3 3-7 4.1-12.2 1.9-9.1 2.5-19.9 3-27.9.1-1 .1-1.9.2-2.8H38c-.3 3.8-2.9 32.4-3 33.1 0 .3-.2 2.1 0 4.4.3 3.5 1.4 6.2 3.2 8.3 2 2.3 4.8 3.5 8.2 3.5 3.1 0 5.7-.8 8-2.3 1.8-1.2 3.2-2.9 4.3-5.1 1.7-3.4 1.8-6.8 1.8-6.9-.1-.8-.5-1.2-1.1-1.2" />
  </svg>
);
export default IconPi;
