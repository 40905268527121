import * as React from "react";
const IconGraphCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M46.3 14.1c-2.9 0-5.3 2.4-5.3 5.3 0 .4.1.9.2 1.3L37.7 23q-.15.15-.3 0l-3.5-1.1h-.3c-.4-2.5-2.6-4.4-5.2-4.4-2.9 0-5.3 2.4-5.3 5.3 0 .8.2 1.5.5 2.2-.2 0-.3.1-.4.2-1.1.9-2.3 1.8-3.4 2.7-1-1-2.3-1.5-3.7-1.5-2.9 0-5.3 2.4-5.3 5.3s2.4 5.3 5.3 5.3 5.3-2.4 5.3-5.3c0-.7-.1-1.4-.4-2 1.1-.9 2.4-1.9 3.5-2.8.1-.1.2-.2.2-.3 1 1 2.3 1.6 3.7 1.6 2.5 0 4.6-1.7 5.1-4.1l3.2 1c.2.1.5.1.7.1.5 0 .9-.1 1.3-.4l3.2-2.1c1 1.3 2.5 2.1 4.2 2.1 2.9 0 5.3-2.4 5.3-5.3s-2.2-5.4-5.1-5.4M16.2 34.7c-1.8 0-3.3-1.5-3.3-3.3s1.5-3.3 3.3-3.3 3.3 1.5 3.3 3.3-1.5 3.3-3.3 3.3m12.3-8.8c-1.8 0-3.3-1.5-3.3-3.3s1.5-3.3 3.3-3.3 3.3 1.5 3.3 3.3-1.5 3.3-3.3 3.3m17.8-3.3c-1.8 0-3.3-1.5-3.3-3.3s1.5-3.3 3.3-3.3 3.3 1.5 3.3 3.3-1.5 3.3-3.3 3.3" />
    <path d="M55.4 7.1H8.6C5 7.1 2 10.1 2 13.7v23.2c0 3.6 3 6.6 6.6 6.6H31v11.3H18.8c-.6 0-1 .4-1 1s.4 1 1 1h26.4c.6 0 1-.4 1-1s-.4-1-1-1H33V43.5h22.4c3.6 0 6.6-3 6.6-6.6V13.7c0-3.6-3-6.6-6.6-6.6M60 36.9c0 2.5-2.1 4.6-4.6 4.6H8.6c-2.5 0-4.6-2.1-4.6-4.6V13.7c0-2.5 2.1-4.6 4.6-4.6h46.8c2.5 0 4.6 2.1 4.6 4.6z" />
  </svg>
);
export default IconGraphCopy;
