import * as React from "react";
const IconHat = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m44.6 31.7-1.5-12.8c-.3-2.4-2.3-4.2-4.7-4.2H25.6c-2.4 0-4.4 1.8-4.7 4.2l-1.5 12.8C8.5 33.2 2 36.3 2 40.1c0 6 15.5 9.3 30 9.3s30-3.2 30-9.3c0-3.8-6.5-6.9-17.4-8.4M32 47.4c-16 0-28-3.8-28-7.3 0-2.1 5.2-5 15.2-6.4l-.4 3.1c-.1.5.3 1 .9 1.1.5.1 1-.3 1.1-.9l2.1-18c.2-1.4 1.3-2.4 2.7-2.4h12.7c1.4 0 2.6 1 2.7 2.4l2.1 18c.1.5.5.9 1 .9h.1c.5-.1.9-.6.9-1.1l-.4-3.1C54.8 35.2 60 38 60 40.1c0 3.5-11.5 7.3-28 7.3" />
  </svg>
);
export default IconHat;
