import * as React from "react";
const IconDerricks = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 11.2h-3.8c-.3-2-1.9-3.5-3.9-3.5H45c-2 0-3.7 1.5-3.9 3.5h-12l-4.2-7.8c-.2-.3-.5-.5-.9-.5H13.3c-.6 0-1 .4-1 1v7.4H7c-2.8 0-5 2.3-5 5v2.6c0 2.8 2.3 5 5 5h6.5v26h-1.7c-.5 0-.9.3-1 .8l-1 4.5c-.3 1.5 0 3 1 4.2s2.4 1.9 3.9 1.9h12.4c1.5 0 2.9-.7 3.9-1.9s1.3-2.7 1-4.2l-1-4.5c-.1-.5-.5-.8-1-.8h-1.4v-26h12.2c.3 2 1.9 3.5 3.9 3.5H46v5.9h-6.2c-2.2 0-4 1.8-4 4v2.2c0 2.2 1.8 4 4 4h14.7c2.2 0 4-1.8 4-4v-2.2c0-2.2-1.8-4-4-4H48v-5.9h1.2c2 0 3.7-1.5 3.9-3.5H57c2.8 0 5-2.3 5-5v-2.6c0-2.8-2.3-5.1-5-5.1m-.5 26v2.2c0 1.1-.9 2-2 2H39.8c-1.1 0-2-.9-2-2v-2.2c0-1.1.9-2 2-2h14.7c1.1 0 2 .9 2 2M14.3 4.8h9l3.4 6.4H14.3zM4 18.8v-2.6c0-1.7 1.4-3 3-3h33.9v8.6H7c-1.6 0-3-1.3-3-3m26.1 36.7c.2.9 0 1.8-.6 2.5s-1.4 1.1-2.4 1.1H14.8c-.9 0-1.8-.4-2.4-1.1s-.8-1.6-.6-2.5l.8-3.7h16.6zm-14.6-5.7v-6.6L26.7 32v17.8zm11.2-20.6L15.5 40.4V23.8h11.1v5.4zm24.5-5.9c0 1.1-.9 2-2 2h-4.3c-1.1 0-2-.9-2-2V11.7c0-1.1.9-2 2-2h4.3c1.1 0 2 .9 2 2zm8.8-4.5c0 1.7-1.4 3-3 3h-3.8v-8.6H57c1.7 0 3 1.4 3 3z" />
  </svg>
);
export default IconDerricks;
