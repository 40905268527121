import * as React from "react";
const IconVolumeAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M6.3 38.6c.6 0 1-.4 1-1v-8.8c0-.6-.4-1-1-1s-1 .4-1 1v8.8c0 .5.4 1 1 1M19.1 38.6c.6 0 1-.4 1-1V22.7c0-.6-.4-1-1-1s-1 .4-1 1v14.8c0 .6.5 1.1 1 1.1M32 38.6c.6 0 1-.4 1-1V16.7c0-.6-.4-1-1-1s-1 .4-1 1v20.9c0 .5.4 1 1 1M44.9 38.6c.6 0 1-.4 1-1v-27c0-.6-.4-1-1-1s-1 .4-1 1v26.9c0 .6.4 1.1 1 1.1M57.7 38.6c.6 0 1-.4 1-1v-33c0-.6-.4-1-1-1s-1 .4-1 1v33c0 .5.5 1 1 1M61 52.6H32.6c-.5-3.3-3.3-5.8-6.8-5.8s-6.3 2.5-6.8 5.8H3c-.6 0-1 .4-1 1s.4 1 1 1h16.1c.5 3.3 3.3 5.9 6.8 5.9s6.3-2.5 6.8-5.9H61c.6 0 1-.4 1-1s-.4-1-1-1m-35.1 5.8c-2.7 0-4.9-2.2-4.9-4.9s2.2-4.9 4.9-4.9 4.9 2.2 4.9 4.9-2.3 4.9-4.9 4.9" />
  </svg>
);
export default IconVolumeAlt;
