import * as React from "react";
const IconSurfBoardCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M43 2.1q-.6-.3-1.2 0C38.1 4 34.7 7.2 32 11.5c-2.7-4.3-6.1-7.5-9.8-9.4-.4-.1-.9-.1-1.2 0C11.2 7 4.6 20.6 4.6 36c0 9.7 2.5 18.7 7.2 25.4.2.4.7.6 1.1.6h38.2c.4 0 .9-.2 1.1-.6 4.6-6.8 7.2-15.8 7.2-25.4 0-15.4-6.6-29-16.4-33.9m-21.4 2c3.7 1.9 7 5.3 9.6 9.9 1.1 2 2 4.1 2.8 6.3L7 29.9C8.4 18 13.9 8.1 21.6 4.1M6.6 36c0-1.3.1-2.6.2-3.9l27.8-9.9c.9 3.2 1.6 6.6 1.8 10.1L7.1 42.8c-.3-2.2-.5-4.5-.5-6.8m23.3 24H13.2c-2.9-4.3-4.8-9.5-5.8-15.2l29.1-10.4V36c0 8.1-1.9 15.9-5.4 22-.4.7-.8 1.4-1.2 2m20.9 0H32.3c.2-.3.4-.7.6-1 3.6-6.4 5.7-14.6 5.7-23 0-8.2-1.9-16.2-5.4-22.5 2.5-4.3 5.7-7.5 9.3-9.4 9 4.7 15 17.4 15 31.9-.1 9.1-2.5 17.6-6.7 24" />
  </svg>
);
export default IconSurfBoardCopy;
