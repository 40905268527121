import * as React from "react";
const IconKidneyAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.4 6.6c-1.6-1.2-3.5-1.9-5.4-1.9-5.2 0-9.4 4.6-9.5 10.4-.1 2.4.6 4.7 1.8 6.6-.8 1.3-1.4 2.8-1.7 4.4L32 33.3 25.3 26c-.3-1.5-.8-3-1.7-4.3 1.2-1.9 1.9-4.2 1.8-6.6-.1-5.8-4.3-10.4-9.5-10.4-1.9 0-3.8.6-5.4 1.9-6.3 4.8-9 16.7-8.5 26C2.6 41 5.6 47.2 10.4 50c1.5.9 3.3 1.4 5 1.4 5.5 0 9.8-4.3 10-10.1.1-2.5-.6-5-1.9-7 .3-.5.6-1 .9-1.6.6-1.1.9-2.5 1-3.7l5.7 6.1v23.2c0 .6.4 1 1 1s1-.4 1-1V35.1l5.7-6.1c.1 1.3.3 2.7 1 3.7.3.6.6 1.1.9 1.6-1.4 2-2 4.4-1.9 7 .2 5.7 4.5 10.1 10 10.1 1.7 0 3.4-.5 5-1.4 4.9-2.8 7.9-9 8.4-17.5.3-9.2-2.5-21.2-8.8-25.9m-30 34.6c-.2 4.6-3.6 8.2-8 8.2-1.4 0-2.7-.4-4-1.1-4.3-2.5-6.9-8.1-7.4-15.8-.5-8.8 2-20 7.7-24.3q1.95-1.5 4.2-1.5c4.1 0 7.4 3.7 7.5 8.4.1 2.2-.6 4.3-1.8 5.9q-.45.6 0 1.2c.9 1.2 1.5 2.5 1.7 4 .1 1.1.2 4.1-.6 5.5 0 0 0 .1-.1.1-.3.7-.7 1.3-1.1 1.9-.3.4-.3.9 0 1.2 1.3 1.8 2 4 1.9 6.3m36.5-8.7c-.5 7.7-3.1 13.3-7.4 15.8-1.2.7-2.6 1.1-4 1.1-4.4 0-7.8-3.5-8-8.2-.1-2.3.6-4.5 1.9-6.3.3-.4.3-.9 0-1.2-.4-.6-.8-1.2-1.1-1.9 0 0 0-.1-.1-.1-.9-1.5-.8-4.5-.7-5.5.2-1.5.8-2.9 1.7-4q.45-.6 0-1.2c-1.2-1.7-1.9-3.8-1.8-5.9.1-4.7 3.4-8.4 7.5-8.4q2.25 0 4.2 1.5c5.9 4.3 8.4 15.5 7.8 24.3" />
  </svg>
);
export default IconKidneyAlt;
