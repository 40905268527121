import * as React from "react";
const IconH2o = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M17 11.6c1.7 1.1 7.1 5.1 10.3 10.9.3.5.9.6 1.4.4.5-.3.6-.9.4-1.4-4.2-7.3-11.3-11.8-11.6-12s-.7-.2-1 0C15.9 9.8 2 17.8 2 31.7c0 7.8 5.1 14.6 12 16.1h.2c.5 0 .9-.3 1-.8s-.2-1.1-.8-1.2C8.4 44.5 4 38.6 4 31.7c0-11.3 10.5-18.6 13-20.1" />
    <path d="M30.2 27c-.6 0-1 .4-1 1v7.5h-7.3V28c0-.6-.4-1-1-1s-1 .4-1 1v17.1c0 .6.4 1 1 1s1-.4 1-1v-7.5h7.3v7.5c0 .6.4 1 1 1s1-.4 1-1V28c0-.6-.5-1-1-1M56.8 26.1h-2.7c-2.9 0-5.2 2.3-5.2 5.2v9.1c0 2.9 2.3 5.2 5.2 5.2h2.7c2.9 0 5.2-2.3 5.2-5.2v-9.1c0-2.9-2.3-5.2-5.2-5.2M60 40.3c0 1.8-1.4 3.2-3.2 3.2h-2.7c-1.8 0-3.2-1.4-3.2-3.2v-9.1c0-1.8 1.4-3.2 3.2-3.2h2.7c1.8 0 3.2 1.4 3.2 3.2zM44.6 44.3c0-2.5-2-4.5-4.5-4.5s-4.5 2-4.5 4.5v1.1c0 .6.4 1 1 1s1-.4 1-1v-1.1c0-1.4 1.1-2.5 2.5-2.5s2.5 1.1 2.5 2.5c0 1.1-.4 2.2-1.1 3.1L37 53c-.2.3-.3.7-.1 1.1.2.3.5.6.9.6h5.9c.6 0 1-.4 1-1s-.4-1-1-1h-3.8l3.2-3.9c1-1.3 1.5-2.9 1.5-4.5" />
  </svg>
);
export default IconH2o;
