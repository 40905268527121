export function checkLocationPermission() {
    if (!navigator.permissions) {
        return false;
    }

    return navigator.permissions.query({ name: 'geolocation' })
        .then(permissionStatus => {
            return permissionStatus.state === 'granted';
        })
        .catch(() => false);
}

export async function requestLocationPermission() {
    if (!navigator.geolocation) {
        console.error('Geolocation is not supported in this browser.');
        return false;
    }

    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
            () => {
                localStorage.setItem('locationTracking', 'true');
                resolve(true);
            },
            (error) => {
                console.error('Error requesting location permission:', error);
                localStorage.setItem('locationTracking', 'false');
                resolve(false);
            }
        );
    });
}

export function disableLocationTracking() {
    localStorage.setItem('locationTracking', 'false');
    return Promise.resolve(false);
}

export function isLocationTrackingEnabled() {
    return localStorage.getItem('locationTracking') === 'true';
}
