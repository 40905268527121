import * as React from "react";
const IconPoundDown = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M27.1 28.7h-.8c-.6 0-1 .4-1 1s.4 1 1 1h12.2c.6 0 1-.4 1-1s-.4-1-1-1h-8c.5-.8.8-1.7.8-2.7v-1.4h3.1c.6 0 1-.4 1-1s-.4-1-1-1h-3.1v-2.8c0-1.8 1.2-3.3 2.8-3.3.7 0 1.3.3 1.9.9.4.4 1 .4 1.4.1.4-.4.4-1 .1-1.4-.9-1-2.1-1.5-3.3-1.5-2.6 0-4.8 2.4-4.8 5.3v2.8h-1c-.6 0-1 .4-1 1s.4 1 1 1h1V26c0 1.5-1 2.7-2.3 2.7" />
    <path d="M12.6 24.4c.6 0 1-.4 1-1C13.6 13.2 21.8 5 32 5s18.4 8.2 18.4 18.4c0 5.6-2.5 10.8-6.8 14.3-.4.3-.5 1-.2 1.4.2.2.5.4.8.4.2 0 .4-.1.6-.2 4.8-3.9 7.6-9.7 7.6-15.8C52.4 12.1 43.3 3 32 3s-20.4 9.1-20.4 20.4c0 .5.4 1 1 1M48.9 49.3c-.6 0-1 .4-1 1v7.1l-12.6-8.7c-.7-.5-1.6-.7-2.4-.7H21.1c-.7 0-1.3-.3-1.7-.7L13.3 41c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l6 6.2c.8.9 2 1.4 3.2 1.4h11.8c.5 0 .9.1 1.3.4L46.7 59h-7.6c-.6 0-1 .4-1 1s.4 1 1 1h9.7c.6 0 1-.4 1-1v-9.7c.1-.6-.3-1-.9-1" />
  </svg>
);
export default IconPoundDown;
