import * as React from "react";
const IconEraserCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.2 60.1H28c.8-.5 1.6-1.1 2.3-1.8L60.6 28c1.9-1.9 1.9-5.1 0-7.1L43.1 3.4c-1.9-2-5.1-1.9-7.1 0L5.7 33.7c-2.4 2.4-3.8 5.7-3.8 9.2s1.3 6.7 3.8 9.2l6.2 6.2c2.5 2.5 5.8 3.8 9.2 3.8h39.1c.6 0 1-.4 1-1s-.4-1-1-1M37.5 4.8c.6-.6 1.4-.9 2.1-.9q1.2 0 2.1.9l17.5 17.5c1.2 1.2 1.2 3.1 0 4.2L35.7 50 14 28.3zM7.2 50.6C5.1 48.5 4 45.8 4 42.8s1.1-5.7 3.2-7.8l5.4-5.4 21.7 21.7-5.4 5.4c-4.3 4.3-11.2 4.3-15.5 0z" />
  </svg>
);
export default IconEraserCopy;
