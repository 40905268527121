import * as React from "react";
const Icon247Alt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m58.6 14.9-.8-8.6c0-.5-.5-1-1.1-.9-.5 0-1 .5-.9 1.1l.6 6.7C50.9 6.7 42.9 3 34.4 3c-16 0-29 13-29 29s13 29 29 29c9.4 0 18.2-4.6 23.7-12.3.3-.5.2-1.1-.2-1.4-.5-.3-1.1-.2-1.4.2C51.4 54.7 43.1 59 34.4 59c-14.9 0-27-12.1-27-27s12.1-27 27-27c7.8 0 15.2 3.4 20.3 9.2l-5.7.5c-.5 0-1 .5-.9 1.1 0 .5.5.9 1 .9h.1l8.6-.8c.4 0 .9-.5.8-1" />
    <path d="M20.9 34.6c.6 0 1-.4 1-1v-7c0-.6-.4-1-1-1H15c-.6 0-1 .4-1 1s.4 1 1 1h4.9v5H15c-.6 0-1 .4-1 1v5.6c0 .6.4 1 1 1h5.9c.6 0 1-.4 1-1s-.4-1-1-1H16v-3.6zM33.1 40.2c.6 0 1-.4 1-1V26.7c0-.6-.4-1-1-1s-1 .4-1 1v5.2h-4.5v-5.2c0-.6-.4-1-1-1s-1 .4-1 1v6.2c0 .6.4 1 1 1h5.5v5.3c0 .6.5 1 1 1M51.4 40.6h.3c.4 0 .8-.3 1-.7L56 27.6c.1-.3 0-.6-.2-.9-.2-.2-.5-.4-.8-.4h-6.5c-.6 0-1 .4-1 1s.4 1 1 1h5.2l-2.9 11.1c-.2.5.1 1 .6 1.2M41.7 23.2 38 42.7c-.1.5.3 1.1.8 1.2h.2c.5 0 .9-.3 1-.8l3.7-19.5c.1-.5-.3-1.1-.8-1.2-.6-.1-1.1.3-1.2.8" />
  </svg>
);
export default Icon247Alt;
