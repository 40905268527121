import * as React from "react";
const IconTornado = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M44.8 23.7c5.2-.4 17.2-2 17.2-6.3 0-2.5-4-4.5-11.9-5.7h-.4c0-.4.1-.8.1-1.2.1-1.4-.4-2.7-1.3-3.7-1-1-2.3-1.6-3.7-1.6H14.4c-1.4 0-2.7.5-3.6 1.5s-1.5 2.2-1.4 3.6c0 1.5.1 3.3.4 5.2H5.6c-.6 0-1 .4-1 1s.4 1 1 1h4.5c.4 2.3 1.1 4.7 2 7.1C5.4 25.8 2 27.7 2 30c0 4.8 14.5 6.2 19.4 6.6.3.2.7.5 1.1.7 4.7 2.9 7.4 6.3 8 10 .8 5.2-2.5 9.4-2.9 9.8-.3.4-.3.9 0 1.3.2.2.4.4.8.4.2 0 .3 0 .5-.1 1.1-.5 10.6-7.2 13.4-15.1h7.4c.6 0 1-.4 1-1s-.4-1-1-1h-6.9c.3-1.5.4-3.1 0-4.6-1.2-5.5-.9-9.9 1-13.2.4 0 .7 0 1-.1m4.7-10.2c.1.1.2.1.3.1 8.3 1.3 10.2 3.2 10.2 3.7 0 .9-3.7 3.2-14.5 4.3l.2-.2c2.2-2.2 3.3-5.2 3.8-7.9M4 30c0-.5 1.7-2.2 8.9-3.5 1.3 2.8 3.2 5.5 5.7 7.8C8.3 33.3 4 31.1 4 30m36.9 7.4c.3 1.4.2 2.8-.1 4.2h-2.9c-.6 0-1 .4-1 1s.4 1 1 1h2.3c-1.8 4.3-5.7 8.2-8.6 10.7.8-2 1.4-4.5.9-7.3-.6-3.8-3-7.3-7.2-10.2 1.4.1 2.9.1 4.4.1.6 0 1-.4 1-1s-.4-1-1-1c-2.6 0-5.1-.1-7.6-.3-3.6-2.7-6.1-6.1-7.7-9.7v-.1c-1.1-2.4-1.8-4.9-2.2-7.3h5.5c.6 0 1-.4 1-1s-.4-1-1-1h-5.8c-.3-2-.4-3.8-.4-5.3 0-.8.3-1.6.9-2.2s1.4-.9 2.2-.9H45c.9 0 1.7.3 2.2 1 .6.6.9 1.4.8 2.2-.2 2.9-1 7-3.6 9.7q-.9.9-1.5 1.8-4.05.3-8.4.3c-.6 0-1 .4-1 1s.4 1 1 1c2.5 0 4.9-.1 7.2-.2-1.8 3.6-2 8.1-.8 13.5" />
  </svg>
);
export default IconTornado;
