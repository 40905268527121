import * as React from "react";
const IconWheelchairAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M44.4 15.5c3.7 0 6.7-3 6.7-6.7S48.2 2 44.4 2s-6.7 3-6.7 6.7 3 6.8 6.7 6.8m0-11.5c2.6 0 4.7 2.1 4.7 4.7s-2.1 4.7-4.7 4.7-4.7-2.1-4.7-4.7S41.8 4 44.4 4M44.8 35.2l2.5-8.5c.5-1.8.2-3.6-.9-5.1s-2.8-2.3-4.6-2.3h-.9c-2.5 0-4.7 1.6-5.5 4l-1.7 5.3c-.5 1.6-1.9 2.6-3.6 2.6h-7c-2.4 0-4.6 1.5-5.4 3.8l-4.5 12.8c-.6 1.8-.4 3.7.7 5.2s2.8 2.4 4.7 2.4c1.8 0 3.4-.8 4.5-2.2 2.4 5.2 7.6 8.6 13.4 8.6 8.1 0 14.7-6.6 14.7-14.7 0-4.7-2.4-9.2-6.4-11.9M18.6 53.6c-1.2 0-2.4-.6-3.1-1.6s-.9-2.3-.5-3.4l4.5-12.8c.5-1.5 2-2.5 3.5-2.5h7c2.5 0 4.7-1.6 5.5-4l1.7-5.3c.5-1.6 1.9-2.6 3.6-2.6h.9c1.2 0 2.3.6 3 1.5.7 1 .9 2.2.6 3.3L42 37.7c-.5 1.6-2 2.7-3.6 2.7H30c-2.4 0-4.6 1.5-5.4 3.8L22.2 51c-.6 1.6-2 2.6-3.6 2.6M36.5 60c-5.6 0-10.6-3.7-12.2-9.1l2.1-6c.5-1.5 2-2.5 3.5-2.5h8.4c2.5 0 4.8-1.7 5.5-4.1l.3-1.1c3.1 2.4 5 6.1 5 10.1.1 7-5.6 12.7-12.6 12.7" />
  </svg>
);
export default IconWheelchairAlt;
