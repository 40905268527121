import * as React from "react";
const IconHandbag6 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m55.8 54.5-8.4-26.7c-.1-.4-.5-.7-1-.7H40C39.7 14.5 37 2 32 2s-7.7 12.5-8 25.1h-6.5c-.4 0-.8.3-1 .7L8.2 54.5c-.6 1.8-.2 3.6.8 5.1 1.1 1.5 2.8 2.3 4.6 2.3h36.7c1.8 0 3.5-.9 4.6-2.3 1.2-1.4 1.5-3.3.9-5.1M32 4c2.4 0 5.7 8.8 6 23.1H26C26.3 12.8 29.6 4 32 4m21.4 54.5c-.7 1-1.8 1.5-3 1.5H13.6c-1.2 0-2.3-.6-3-1.5-.7-1-.9-2.2-.6-3.3l8.2-26h27.5l8.2 26c.4 1.1.2 2.3-.5 3.3" />
  </svg>
);
export default IconHandbag6;
