import * as React from "react";
const IconSk = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 85.333 512 341.333"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 85.337h512v341.326H0z" />
    <path  d="M0 196.641h512v118.717H0z" />
    <path  d="M0 315.359h512v111.304H0z" />
    <path
      
      d="M129.468 181.799v85.136c0 48.429 63.267 63.267 63.267 63.267S256 315.362 256 266.935v-85.136z"
    />
    <path
      
      d="M146.126 184.294v81.941c0 5.472 1.215 10.64 3.623 15.485h85.97c2.408-4.844 3.623-10.012 3.623-15.485v-81.941z"
    />
    <path
      
      d="M221.301 241.427h-21.425v-14.283h14.284v-14.283h-14.284v-14.284h-14.283v14.284h-14.282v14.283h14.282v14.283h-21.426v14.284h21.426v14.283h14.283v-14.283h21.425z"
    />
    <path
      
      d="M169.232 301.658c9.204 5.783 18.66 9.143 23.502 10.636 4.842-1.494 14.298-4.852 23.502-10.636 9.282-5.833 15.79-12.506 19.484-19.939a24.88 24.88 0 0 0-14.418-4.583c-1.956 0-3.856.232-5.682.657-3.871-8.796-12.658-14.94-22.884-14.94s-19.013 6.144-22.884 14.94a25 25 0 0 0-5.682-.657 24.9 24.9 0 0 0-14.418 4.583c3.691 7.433 10.198 14.106 19.48 19.939"
    />
  </svg>
);
export default IconSk;
