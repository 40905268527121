import React from 'react';
import {
	BringzzPageContainer,
	BringzzIcon,
	BringzzText,
	BringzzImage,
	BringzzButton,
	BringzzTabs,
	BringzzTab,
	BringzzTabPanel,
	BringzzPostingCard,
	BringzzOrderCard
} from '@bringzz/components';
import AvatarExample from 'assets/images/AvatarExample.png';
import PostExample1 from 'assets/images/PostExample1.png';
import { useNavigation } from 'context/NavigationContext';
import useUser from 'hooks/useUser';
{
	/*             <button onClick={() => navigate('/profile/settings')}>Go to some path</button> */
}
const SimpleBarChart = ({ data }) => {
	return (
		<div className='w-full h-48 bg-white border border-gray-200 rounded-md p-4'>
			<div className='flex justify-between items-end h-full'>
				{data.map((value, index) => (
					<div
						key={index}
						className='bg-gray-200'
						style={{
							height: `${value}%`,
							width: `${100 / data.length - 2}%`, // Adjust width based on the number of bars with some margin
							transition: 'height 0.3s ease'
						}}
					></div>
				))}
			</div>
		</div>
	);
};
const ShopperCard = ({ name, rating = 4.2, reviewCount = 154 }) => {
	return (
		<div className='flex items-center justify-between border border-gray-300 rounded-md p-3 mt-8 bg-white w-full'>
			<div className='flex flex-col'>
				<BringzzText tag='h4' className='font-semibold'>
					{name}
				</BringzzText>
				<div className='flex items-center space-x-1 mt-1'>
					<BringzzIcon
						size='16'
						icon='IconStar'
						folder='LineIcons'
						className='text-magic-lilac'
					/>
					<BringzzText tag='span' className='text-magic-lilac'>
						{rating}
					</BringzzText>
					<BringzzText tag='span' className='text-magic-lilac'>
						• ({reviewCount})
					</BringzzText>
				</div>
			</div>
			<BringzzIcon
				size='24'
				icon='IconCog'
				folder='LineIcons'
				className='text-gray-500 cursor-pointer'
			/>
		</div>
	);
};
const DashboardComponent = ({ chartData, stats }) => {
	// Mock options for the dropdown
	const months = [
		{ label: 'January 2023', value: '2023-01' },
		{ label: 'February 2023', value: '2023-02' },
		{ label: 'March 2023', value: '2023-03' }
		// Add more months as needed
	];

	// Placeholder data for stats if not provided
	const defaultStats = [
		{ label: 'No. of Rides', value: stats.numberOfRides },
		{ label: 'Kilometers', value: stats.totalKilometer },
		{ label: 'per km', value: stats.averagePacePerKilometer },
		{ label: 'points earned', value: stats.earned },
		{ label: 'ranking regional riders', value: `#${stats.rankingRegional}` },
		{ label: 'ranking national', value: `#${stats.rankingNational}` },
		{ label: 'Star rating', value: stats.starRating },
		{ label: 'Average tip', value: `€${stats.averageTip}` },
		{ label: 'Earned so far', value: `€${stats.earned}`, fullWidth: true } // Adding a flag to take the whole row
	];

	return (
		<div className='p-4 space-y-4 bg-white'>
			{/* Date Selector */}
			{/* <BringzzS
          options={months}
          defaultValue={months[0].value}
          className="w-40 mb-4"
        /> */}

			{/* Chart */}
			<div className='mb-4'>
				<SimpleBarChart data={[30, 70, 50, 80, 60, 90, 40]} />

				{/* <BringzzChart
            data={chartData}
            type="bar" // You can change the chart type as needed: "line", "pie", etc.
            options={{
              responsive: true,
              plugins: {
                legend: {
                  display: false
                },
                title: {
                  display: false
                }
              }
            }}
          /> */}
			</div>

			{/* Stat Cards */}
			<div className='grid grid-cols-2 gap-4'>
				{defaultStats.map((stat, index) => (
					<div
						key={index}
						className={`flex flex-col items-center justify-center p-3 border border-gray-200 rounded-md ${stat.fullWidth ? 'col-span-2' : ''
							}`}
					>
						<BringzzText tag='h4' className='text-xl font-semibold'>
							{stat.value}
						</BringzzText>
						<BringzzText tag='span' className='text-sm text-gray-500'>
							{stat.label}
						</BringzzText>
					</div>
				))}
			</div>
		</div>
	);
};

const ShopperAccount = () => {
	const { backPress, navigate } = useNavigation();
	const { getUser } = useUser();
	const user = getUser();
	return (
		<BringzzPageContainer
			header={{
				left: (
					<BringzzIcon
						size='22'
						icon='IconChevronLeft'
						folder='LineIcons'
						className='cursor-pointer'
						onClick={backPress}
					/>
				),
				right: (
					<>
						<BringzzIcon
							size='22'
							icon='IconStar'
							folder='LineIcons'
							className='cursor-pointer'
							onClick={event => { }}
						/>
						<BringzzIcon
							size='22'
							icon='IconUploadAlt'
							folder='LineIcons'
							className='cursor-pointer'
							onClick={event => { }}
						/>
					</>
				),
				background: (
					<BringzzImage
						className='object-cover w-full h-full'
						src='/images/3.jpg'
						alt='react logo'
					/>
				),
				avatar: <BringzzImage src='/logo192.png' alt='react logo' />
			}}
			subHeader={
				<ShopperCard
					name={user.firstname + ' ' + user.lastname}
					rating={4.5}
					reviewCount={200}
				/>
			}
		>
			<div className='px-4 py-6'>
				<BringzzTabs defaultActiveTab='tab1'>
					<BringzzTab id='tab1'>
						<BringzzText tag='h5' className='font-normal'>
							Week
						</BringzzText>
					</BringzzTab>
					<BringzzTabPanel id='tab1'>
						<DashboardComponent stats={user.statistics['week']} />
					</BringzzTabPanel>

					<BringzzTab id='tab2'>
						<BringzzText tag='h5' className='font-normal'>
							Month
						</BringzzText>
					</BringzzTab>
					<BringzzTabPanel id='tab2'>
						<DashboardComponent stats={user.statistics['month']} />
					</BringzzTabPanel>

					<BringzzTab id='tab3'>
						<BringzzText tag='h5' className='font-normal'>
							Year
						</BringzzText>
					</BringzzTab>
					<BringzzTabPanel id='tab3'>
						{' '}
						<DashboardComponent stats={user.statistics['year']} />
					</BringzzTabPanel>

					<BringzzTab id='tab4'>
						<BringzzText tag='h5' className='font-normal'>
							All
						</BringzzText>
					</BringzzTab>
					<BringzzTabPanel id='tab4'>
						{' '}
						<DashboardComponent stats={user.statistics['all']} />
					</BringzzTabPanel>
				</BringzzTabs>
				<div className='flex justify-between items-center p-2'>
					<BringzzText tag='span' className='text-gray-500'>
						Latest deliveries
					</BringzzText>
					<BringzzText
						tag='a'
						className='text-magic-lilac cursor-pointer'
						onClick={() => navigate('/settings/account/shopper/deliveries')}
					>
						Show all
					</BringzzText>
				</div>
				<div className='p-1 flex flex-col gap-y-4'>
					{user.deliveries.splice(0, 2).map(delivery => (
						<>
							<BringzzOrderCard
								onClick={() =>
									navigate(
										`/settings/account/shopper/deliveries/detail?id=${delivery.uuid}`
									)
								}
								orderDetails={{
									name: delivery.customerFirstName,
									date: new Date(),
									status: 'completed',
									price: delivery.price
								}}
							></BringzzOrderCard>
						</>
					))}
				</div>
			</div>
		</BringzzPageContainer>
	);
};

export default ShopperAccount;
