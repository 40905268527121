import React, { useState } from 'react';
import classnames from 'classnames';

const BringzzTabList = ({ activeTab, onTabChange, children }) => {
    return (<div className={classnames("flex border-b", children.length !== 4 ? "justify-center space-x-4" : "justify-evenly")}>
        {React.Children.map(children, (child) =>
            child !== null && React.cloneElement(child, {
                isActive: child.props.id === activeTab,
                onActivate: () => onTabChange(child.props.id),
                nbrTabs: children.length / 2
            }),
        )}
    </div>
    )
};

export default BringzzTabList