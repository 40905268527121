import * as React from "react";
const IconBills = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M25.1 33.5h4.5c.6 0 1-.4 1-1s-.4-1-1-1h-1.8v-1.1c0-.6-.4-1-1-1s-1 .4-1 1v1.1h-.7c-1.9 0-3.4 1.5-3.4 3.4s1.5 3.4 3.4 3.4h2.1c.8 0 1.4.6 1.4 1.4s-.6 1.4-1.4 1.4h-4.5c-.6 0-1 .4-1 1s.4 1 1 1h3.1v1.1c0 .6.4 1 1 1s1-.4 1-1V43c1.6-.3 2.8-1.7 2.8-3.3 0-1.9-1.5-3.4-3.4-3.4h-2.1c-.8 0-1.4-.6-1.4-1.4s.6-1.4 1.4-1.4" />
    <path d="M57.5 11H18.3c-2.5 0-4.5 2-4.5 4.5v6.1H6c-2.2 0-4 1.8-4 4V49c0 2.2 1.8 4 4 4h40.2c2.2 0 4-1.8 4-4v-6.6h7.2c2.5 0 4.5-2 4.5-4.5V15.5C62 13 60 11 57.5 11m-9.3 38c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V25.6c0-1.1.9-2 2-2h40.2c1.1 0 2 .9 2 2zM60 37.9c0 1.4-1.1 2.5-2.5 2.5h-7.2V25.6c0-2.2-1.8-4-4-4H15.8v-6.1c0-1.4 1.1-2.5 2.5-2.5h39.2c1.4 0 2.5 1.1 2.5 2.5z" />
    <path d="M14.8 36.4h-3.4c-.6 0-1 .4-1 1s.4 1 1 1h3.4c.6 0 1-.4 1-1s-.5-1-1-1M41.8 36.4h-3.4c-.6 0-1 .4-1 1s.4 1 1 1h3.4c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconBills;
