import * as React from "react";
const IconTd = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 85.333 512 341.333"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 85.331h512v341.337H0z" />
    <path  d="M0 85.331h170.663v341.337H0z" />
    <path  d="M341.337 85.331H512v341.337H341.337z" />
  </svg>
);
export default IconTd;
