import * as React from "react";
const IconHandbag7 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.3 46.6 47.6 28.1c-.4-.6-1-1-1.7-1H40C39.7 14.5 37 2 32 2s-7.7 12.5-8 25.1h-5.9c-.7 0-1.4.4-1.7 1L5.7 46.6c-.4.7-.3 1.7.2 2.3l10.6 12.4c.4.4.9.7 1.5.7h28c.6 0 1.1-.3 1.5-.7l10.6-12.4c.6-.7.6-1.6.2-2.3M32 4c2.4 0 5.7 8.8 6 23.1H26C26.3 12.8 29.6 4 32 4m14 56H18L7.4 47.6l10.7-18.5h27.8l10.7 18.5z" />
  </svg>
);
export default IconHandbag7;
