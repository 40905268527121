import * as React from "react";
const IconKettleball = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M46.9 24 49 8.5c.2-1.6-.3-3.3-1.4-4.5s-2.7-2-4.3-2H20.7c-1.7 0-3.2.7-4.3 2-1.1 1.2-1.6 2.9-1.4 4.5L17.1 24C10.2 28.8 5.6 36.7 5.6 45.8c0 5.8 1.8 11.2 5.3 15.8.2.3.5.4.8.4h40.6c.3 0 .6-.1.8-.4 3.5-4.6 5.3-10.1 5.3-15.8 0-9.1-4.6-17-11.5-21.8M17 8.2q-.15-1.65.9-3c.7-.7 1.8-1.2 2.8-1.2h22.5c1.1 0 2.1.5 2.8 1.3s1 1.9.9 3l-2 14.6c-3.8-2.2-8.3-3.4-13-3.4s-9.2 1.3-13 3.4zM51.8 60H12.2c-3-4.2-4.6-9.1-4.6-14.2 0-13.4 10.9-24.4 24.4-24.4s24.4 10.9 24.4 24.4c0 5.1-1.6 10-4.6 14.2" />
  </svg>
);
export default IconKettleball;
