import * as React from "react";
const IconCiCd = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.6 39.3h-7.2c-6.3 0-12.1-3.1-15.5-8.4-3.8-5.8-10.2-9.3-17.1-9.3H9.6c-.6 0-1 .4-1 1s.4 1 1 1h7.2c6.3 0 12.1 3.1 15.5 8.4 3.8 5.8 10.2 9.3 17.1 9.3h7.2c.6 0 1-.4 1-1s-.5-1-1-1M23.5 43.6c-7.2 0-13.6-4.6-15.9-11.4 0-.1-.1-.1-.1-.2l4.6.8c.6.1 1.1-.3 1.2-.8s-.3-1.1-.8-1.2L6 29.7c-.4-.1-.9.1-1.1.5L2 35.8c-.3.5-.1 1.1.4 1.3.1.1.3.1.5.1.4 0 .7-.2.9-.5l2-3.7c2.6 7.5 9.7 12.6 17.7 12.6.6 0 1-.4 1-1s-.4-1-1-1M61.5 26.9c-.5-.3-1.1-.1-1.3.4l-2 3.7c-2.6-7.5-9.7-12.6-17.7-12.6-.6 0-1 .4-1 1s.4 1 1 1c7.2 0 13.6 4.6 15.9 11.4 0 .1.1.1.1.2l-4.6-.8c-.6-.1-1.1.3-1.2.8s.3 1.1.8 1.2l6.5 1.1h.2c.4 0 .7-.2.9-.5l2.9-5.6c.1-.5 0-1.1-.5-1.3" />
  </svg>
);
export default IconCiCd;
