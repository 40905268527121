import * as React from "react";
const IconBq = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 513 342"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 0h513v342H0z" />
    <path  d="M0 342h513V0z" />
    <path  d="M250.4 0H0v166.9z" />
    <path
      
      d="m140.4 118.3 12.2 21.2h24.5l-12.3 21.1 12.3 21.2h-24.5L140.4 203l-12.2-21.2h-24.5l12.3-21.2-12.3-21.1h24.5z"
    />
    <circle
      cx={140.4}
      cy={160.6}
      r={57.7}
      
      
      strokeMiterlimit={10}
      strokeWidth={9}
    />
  </svg>
);
export default IconBq;
