import * as React from "react";
const IconAo = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 513 342"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M0 171h513v171H0z" />
    <path  d="M0 0h513v171H0z" />
    <g >
      <path d="m332.7 135.6 17.5-8.6-7.4-19-18.7 6.2zm-45.6-56 5.5-18.8-19.1-7.2-8 18zm29.4 23.2 12.2-15.3-14.9-13.9-14.2 13.6zm19.4 68.9 19.5-.8v-21.3l-22.9.2zm-12.6 38.1 18.8 5.5 7.3-19-18-8zm-29 32.4 14.9 12.7 14.4-14.4-13.1-14.7zm-47-147.7 9.8 19.9 21.9 3.3-15.9 15.5 3.6 21.9-19.6-10.4-19.7 10.2 3.8-21.9-15.8-15.5 22-3.1z" />
      <path d="m250.8 61.3-2.7 18.3c43.1 3.2 77 39.2 77 83.1 0 46-37.3 82.9-83.4 83.4-29.2.3-51.3-14.8-67-33.7l-13.9 12.3c15 19 40.8 39.9 80.9 39.9 56.3 0 101.9-45.6 101.9-101.9.1-53.2-40.7-96.9-92.8-101.4" />
      <path
        
        d="m291.9 223.4-11.8 14s51.9 38.9 53.6 40.4 5.2 2 9.1-2.7c3.7-4.5 2.8-8.1.9-9.7-2-1.5-51.8-42-51.8-42"
      />
      <path d="M206.1 157.2c-7.7 10.3-7.5 23.1 2.8 30.9 0 0 135.2 101.5 136.9 103s5.2 2 9.1-2.7c3.7-4.5 2.8-8.1.9-9.7-1.9-1.5-149.7-121.5-149.7-121.5m47.7 99.1 7.3 18.2 19.5-6-4.8-19.1zm-40.4-3.7-2 19.5 20.1 3.6 4.5-19.1zm-35.7-21.2L167.4 248l16.4 12.1 12.5-15.2z" />
    </g>
  </svg>
);
export default IconAo;
