import * as React from "react";
const IconCloudServer = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 40.9v-6.2c0-2.8-2.2-5-5-5h-5.5c.6-1.3 1-2.6 1-4.1 0-5.1-4.2-9.3-9.5-9.3-1.2 0-2.3.2-3.4.6.1-.6.1-1.1.1-1.7 0-6.2-5.2-11.3-11.6-11.3S15.5 9 15.5 15.2c0 .6 0 1.1.1 1.7-1.1-.4-2.2-.6-3.4-.6-5.2 0-9.5 4.2-9.5 9.3s4.2 9.3 9.5 9.3h7.7v6c0 1.6.8 3.1 2 4-1.2.9-2 2.4-2 4v6.2c0 2.8 2.2 5 5 5H56c2.8 0 5-2.2 5-5v-6.2c0-1.6-.8-3.1-2-4 1.2-.9 2-2.4 2-4m-40.5-8h-8c-4.1 0-7.5-3.3-7.5-7.3s3.3-7.3 7.5-7.3c1.6 0 3.1.5 4.3 1.3q.6.3 1.2 0c.3-.3.5-.7.3-1.1-.4-1-.6-2.1-.6-3.2 0-5.1 4.3-9.3 9.6-9.3s9.6 4.2 9.6 9.3c0 1.1-.2 2.2-.6 3.2-.2.4 0 .9.3 1.1.3.3.8.3 1.2 0 1.3-.9 2.8-1.3 4.3-1.3 4.1 0 7.5 3.3 7.5 7.3 0 1.5-.5 2.9-1.3 4.1H25.1c-2.1 0-3.9 1.3-4.6 3.2m38.5 16v6.2c0 1.7-1.3 3-3 3H25.1c-1.7 0-3-1.3-3-3v-6.2c0-1.7 1.3-3 3-3H56c1.7 0 3 1.3 3 3m0-8c0 1.7-1.3 3-3 3H25.1c-1.7 0-3-1.3-3-3v-6.2c0-1.7 1.3-3 3-3H56c1.7 0 3 1.3 3 3z" />
    <path d="M29.5 36.6c-.6 0-1 .4-1 1v.1c0 .6.4 1 1 1s1-.4 1-1v-.1c0-.5-.4-1-1-1M53.2 36.7h-4.5c-.6 0-1 .4-1 1s.4 1 1 1h4.5c.6 0 1-.4 1-1s-.5-1-1-1M29.5 52.9c.6 0 1-.4 1-1v-.1c0-.6-.4-1-1-1s-1 .4-1 1v.1c0 .6.5 1 1 1M48.6 52.9h4.5c.6 0 1-.4 1-1s-.4-1-1-1h-4.5c-.6 0-1 .4-1 1s.5 1 1 1" />
  </svg>
);
export default IconCloudServer;
