import * as React from "react";
const IconWaiter = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.7 21.3h-2.1C52.4 10.4 43.1 2 32 2c-11 0-20.3 8.4-21.5 19.3H8.3c-1.1 0-2 .9-2 2v5.5c0 1.1.9 2 2 2h15.5c.8 2.9 3.5 7 9.4 6.9 2.3-.1 3.6-.1 4.2 0V42c-1.6.5-2.8 2-2.8 3.8V58c0 2.2 1.8 4 4 4h10.5c2.2 0 4-1.8 4-4V45.7c0-1.9-1.4-3.5-3.1-3.9v-11h5.7c1.1 0 2-.9 2-2v-5.5c0-1.1-.9-2-2-2M32 4c10 0 18.3 7.5 19.5 17.3h-39C13.7 11.5 22.1 4 32 4m19.2 41.7V58c0 1.1-.9 2-2 2H38.6c-1.1 0-2-.9-2-2V45.7c0-1.1.9-2 2-2h10.5c1.2 0 2.1.9 2.1 2m-3.2-4h-8.5v-5.5l-.3-.3c-.4-.3-.4-.4-6-.3H33c-4.5 0-6.3-3.1-7-4.9h22zm-39.7-13v-5.5h47.3v5.5z" />
  </svg>
);
export default IconWaiter;
