import * as React from "react";
const IconRoundedRectangle = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53 53.4H11c-5 0-9-4-9-9V19.6c0-5 4-9 9-9h42c5 0 9 4 9 9v24.8c0 5-4 9-9 9M11 12.6c-3.9 0-7 3.1-7 7v24.8c0 3.9 3.1 7 7 7h42c3.9 0 7-3.1 7-7V19.6c0-3.9-3.1-7-7-7z" />
  </svg>
);
export default IconRoundedRectangle;
