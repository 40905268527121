import * as React from "react";
const IconGraduate = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.3 22.4-29-10.5c-.2-.1-.5-.1-.7 0l-29 10.5c-.3.1-.6.5-.6.9s.3.8.7.9l12.1 4.4v6.3c0 9.5 7.7 17.2 17.2 17.2s17.2-7.7 17.2-17.2v-6.3l8.7-3.2v12.9c0 .6.4 1 1 1s1-.4 1-1V24.7l1.4-.5c.4-.1.7-.5.7-.9s-.3-.8-.7-.9M32 13.9l26.1 9.4L47.9 27 32 32.7 16.2 27 5.9 23.3zm15.2 21c0 8.4-6.8 15.2-15.2 15.2s-15.2-6.8-15.2-15.2v-5.6l14.9 5.4c.1 0 .2.1.3.1s.2 0 .3-.1l14.9-5.4z" />
  </svg>
);
export default IconGraduate;
