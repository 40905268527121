import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import BringzzImage from "../BringzzImage";
import BringzzText from "../BringzzText";
import BringzzIcon from "../BringzzIcon";
import BringzzButton from "../BringzzButton"; // Assuming this is your button component
import BringzzAnimatedContainer from "../BringzzAnimatedContainer";

const BringzzBasketDrawer = ({
  products,
  showSelect,
  basketContainerProps,
  isOpen,
  ...props
}) => {
  const { t } = useTranslation();

  const { className: basketContainerClassName, ...rest } = basketContainerProps;
  const [showNotification, setShowNotification] = useState(true);
  const [visibilityStyle, setVisibilityStyle] = useState({
    visibility: "hidden",
    opacity: 0,
  });
  useEffect(() => {
    setShowNotification(true);
    if (isOpen) {
      setVisibilityStyle({ visibility: "visible", opacity: 0 });

      requestAnimationFrame(() => {
        setVisibilityStyle({ visibility: "visible", opacity: 1 });
      });
    } else {
      setVisibilityStyle({ ...visibilityStyle, opacity: 0 });

      setTimeout(() => {
        setVisibilityStyle({ visibility: "hidden", opacity: 0 });
      }, 300);
    }
  }, [isOpen]);

  return (
    <>
      {/* Overlay covering the screen except the basket */}
      <div
        style={visibilityStyle}
        className="fixed inset-0 bg-black bg-opacity-50 z-40"
      ></div>

      {/* Basket container with white background styled as a notification box */}
      <BringzzAnimatedContainer
        {...rest}
        isOpen={isOpen}
        className={`fixed inset-x-0 bottom-0 bg-white shadow-md z-50 rounded-t-lg  ${basketContainerClassName} `}
      >
        {/* Notification header with success icon and close button */}
        {showNotification && (

          <div className="flex justify-between items-center bg-magic-lilac w-full h-[50px] px-6  ">

            <div className="flex items-center">
              <BringzzIcon
                icon="IconCheckmarkCircle"
                folder="LineIcons"
                size="24"
              // className="text-green-500"
              />
              <BringzzText tag="h5" className="font-bold ml-4">
                {t("basket_added_to_basket_label")}
              </BringzzText>
            </div>
            <BringzzButton
              size="sm"
              icon={{ variant: "IconClose", folder: "LineIcons", size: "14" }}
              onClick={() => setShowNotification(false)}
              className="!px-1"
            ></BringzzButton>


          </div>
        )}

        <div className="flex flex-col justify-between max-w-screen-md mx-auto h-full">
          {/* Product details */}
          {products.map((product, index) => {
            return (
              <div key={product.id || index} className="py-4 px-6 flex item-center justify-center gap-6 w-full ">
                <div className="flex items-center">
                  <BringzzImage src={product.imgUrl} className="w-[75px]"></BringzzImage>
                </div>
                <div className="flex-grow">
                  <div className="text-xs">
                    <BringzzText tag="h5" className="font-regular">
                      {product.manufacturer || "Manufacturer"}
                    </BringzzText>{" "}
                  </div>
                  <div className="">
                    <BringzzText tag="h4" className="font-regular">
                      {product.name || "Product Name"}
                    </BringzzText>{" "}
                  </div>
                  <div className="">
                    <BringzzText tag="h5" className="font-regular">
                      {product.amount || "84 Stk | Kapseln"}
                    </BringzzText>
                  </div>
                  <div className="flex justify-between mt-1">
                    <div className="flex items-center justify-start gap-2 ">
                      <BringzzText tag="h3">
                        € {(product.quantity * product.price).toFixed(2)}
                      </BringzzText>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          {/* Action buttons */}
          <div className="flex flex-col justify-between inset-x-0 py-4 px-4">
            <BringzzButton
              size="lg"
              className="bg-transparent hover:bg-magic-lilac font-semibold hover:text-white py-2 px-4 border border-magic-lilac hover:border-transparent rounded w-full flex justify-center text-midnight-blue"
              onClick={props.continueShoppingBtn}
            >
              {t("basket_continue_shopping_label")}
            </BringzzButton>
            <BringzzButton
              size="lg"
              className="bg-magic-lilac w-full flex justify-center text-midnight-blue mt-2"
              onClick={props.goToBasketBtn}
            >
              {t("basket_go_to_basket_label")}
            </BringzzButton>
          </div>
        </div>
      </BringzzAnimatedContainer>
    </>
  );
};

export default BringzzBasketDrawer;
