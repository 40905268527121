import * as React from "react";
const IconCapsuleAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2c-7.6 0-13.8 6.2-13.8 13.8v32.4C18.2 55.8 24.4 62 32 62s13.8-6.2 13.8-13.8V15.8C45.8 8.2 39.6 2 32 2m11.8 46.2C43.8 54.7 38.5 60 32 60s-11.8-5.3-11.8-11.8V33h23.6zM20.2 15.8C20.2 9.3 25.5 4 32 4s11.8 5.3 11.8 11.8V31H20.2z" />
    <path d="M28.7 17.3c-.6 0-1 .4-1 1v4c0 .6.4 1 1 1s1-.4 1-1v-4c0-.5-.4-1-1-1M28.7 46.6c.6 0 1-.4 1-1v-4c0-.6-.4-1-1-1s-1 .4-1 1v4c0 .6.5 1 1 1" />
  </svg>
);
export default IconCapsuleAlt;
