import * as React from "react";
const IconApple = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M40.1 16.7c-2.5 0-4.7.5-7 1.5v-7.5c1.5.6 3 .9 4.4.9 1.1 0 2.2-.2 3.2-.5 2.7-1 4.9-3.1 6.3-6.2.2-.5 0-1.1-.5-1.3-3.2-1.6-6.3-1.9-9.1-1-2.7 1-4.9 3.1-6.3 6.2-.1.1-.1.3-.1.4v9.1c-2.4-1-4.4-1.6-7.1-1.6-10.8 0-19.6 10.2-19.6 22.7C4.3 51.8 13.1 62 23.9 62c2.5 0 5-.6 7.4-1.7q.75-.45 1.5 0c2.4 1.1 4.8 1.7 7.4 1.7C51 62 59.7 51.8 59.7 39.3c0-12.4-8.8-22.6-19.6-22.6M38.2 4.4c2-.7 4.2-.5 6.6.4C43.6 7 41.9 8.5 40 9.2c-2 .7-4.2.5-6.6-.4 1.2-2.2 2.8-3.7 4.8-4.4m2 55.6c-2.2 0-4.4-.5-6.5-1.5-.5-.3-1.1-.4-1.6-.4s-1.1.1-1.6.4c-2.1 1-4.3 1.5-6.5 1.5-9.7 0-17.6-9.3-17.6-20.7S14.3 18.6 24 18.6c2.5 0 4.2.5 6.6 1.5q1.5.45 3 0c2.2-1 4.2-1.5 6.5-1.5 9.7 0 17.6 9.3 17.6 20.7S49.9 60 40.2 60" />
  </svg>
);
export default IconApple;
