import * as React from "react";
const IconArrowAlignH = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 19.6c-.6 0-1 .4-1 1v22.7c0 .6.4 1 1 1s1-.4 1-1V20.6c0-.5-.4-1-1-1M23.2 31H6.5l9.9-9.7c.4-.4.4-1 0-1.4s-1-.4-1.4 0L3.3 31.3c-.2.2-.3.5-.3.8s.1.5.3.7L15 44.1c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4L6.4 33h16.8c.6 0 1-.4 1-1s-.5-1-1-1M60.7 31.3 49 19.9c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l9.9 9.7H40.8c-.6 0-1 .4-1 1s.4 1 1 1h16.8l-10 9.7c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3l11.7-11.3c.2-.2.3-.4.3-.7s-.1-.6-.3-.8" />
  </svg>
);
export default IconArrowAlignH;
