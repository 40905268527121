import * as React from "react";
const IconTargetMail = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m1 58v-4.9c0-.6-.4-1-1-1s-1 .4-1 1V60C16.4 59.5 4.5 47.6 4 33h4.9c.6 0 1-.4 1-1s-.4-1-1-1H4C4.5 16.4 16.4 4.5 31 4v4.9c0 .6.4 1 1 1s1-.4 1-1V4c14.6.5 26.5 12.3 27 27h-4.9c-.6 0-1 .4-1 1s.4 1 1 1H60c-.5 14.6-12.4 26.5-27 27" />
    <path d="M41.8 23.6H22.2c-1.7 0-3 1.3-3 3v10.7c0 1.7 1.3 3 3 3h19.6c1.7 0 3-1.3 3-3V26.6c0-1.6-1.3-3-3-3m-.8 2-8.1 4.6c-.6.3-1.3.3-1.9 0l-8-4.6zm.8 12.8H22.2c-.6 0-1-.4-1-1V26.9l8.9 5.1c.6.3 1.3.5 1.9.5s1.3-.2 1.9-.5l8.9-5.1v10.5c0 .5-.5 1-1 1" />
  </svg>
);
export default IconTargetMail;
