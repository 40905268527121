import * as React from "react";
const IconFileName = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M18.8 27.9H38c.6 0 1-.4 1-1s-.4-1-1-1H18.8c-.6 0-1 .4-1 1s.4 1 1 1M18.8 38.5H38c.6 0 1-.4 1-1s-.4-1-1-1H18.8c-.6 0-1 .4-1 1s.4 1 1 1M45.2 47.1H18.8c-.6 0-1 .4-1 1s.4 1 1 1h26.5c.6 0 1-.4 1-1s-.5-1-1.1-1" />
    <path d="M58.1 13.7H32.4l-3.1-9c-.5-1.6-2-2.6-3.6-2.6H5.9C3.7 2.1 2 3.8 2 6v52c0 2.1 1.7 3.9 3.9 3.9h52.3c2.1 0 3.9-1.7 3.9-3.9V17.5c-.1-2.1-1.8-3.8-4-3.8M60 58c0 1-.8 1.9-1.9 1.9H5.9c-1 0-1.9-.8-1.9-1.9V6c0-1 .8-1.9 1.9-1.9h19.8c.8 0 1.5.5 1.8 1.2l3.3 9.6c.1.4.5.7.9.7h26.5c1 0 1.9.8 1.9 1.9V58z" />
  </svg>
);
export default IconFileName;
