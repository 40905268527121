import * as React from "react";
const IconStampCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M46.5 36.4h-9l1.3-26.7c.1-2.1-.7-4.1-2.1-5.6C35.5 2.7 33.8 2 32 2s-3.5.7-4.8 2.1c-1.4 1.5-2.2 3.6-2.1 5.7l1.3 26.7h-8.9c-5.6 0-10.2 5-10.2 11.2v4.8c0 .9.7 1.6 1.6 1.6h1v4.2c0 2 1.6 3.6 3.6 3.6h36.9c2 0 3.6-1.6 3.6-3.6v-4.2h1.1c.9 0 1.6-.7 1.6-1.6v-4.8c0-6.2-4.6-11.3-10.2-11.3M28.6 5.5c.9-1 2.1-1.5 3.4-1.5s2.4.5 3.4 1.5c1 1.1 1.6 2.6 1.5 4.1l-1.3 26.8h-7.1L27.1 9.7c-.1-1.6.5-3.1 1.5-4.2m23.5 52.9c0 .9-.7 1.6-1.6 1.6H13.6c-.9 0-1.6-.7-1.6-1.6v-4.2h40.2v4.2zm2.6-6.3H9.3v-4.5c0-5.1 3.7-9.2 8.2-9.2h29c4.5 0 8.2 4.1 8.2 9.2z" />
  </svg>
);
export default IconStampCopy;
