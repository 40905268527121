import * as React from "react";
const IconHighBars = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M32 9a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3v30a3 3 0 0 1-3 3h-4a3 3 0 0 1-3-3zm3-1a1 1 0 0 0-1 1v1.172L36.172 8zm4 0-5 5v2.172l6-6V9a1 1 0 0 0-1-1m1 4-6 6v2.172l6-6zm0 5-6 6v2.172l6-6zm0 5-6 6v2.172l6-6zm0 5-6 6v2.172l6-6zm0 5-6 6v1c0 .32.15.605.384.788L40 34.172zm0 5-3 3h2a1 1 0 0 0 1-1zM19 21a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3v18a3 3 0 0 1-3 3h-4a3 3 0 0 1-3-3zm5-1h2q.081 0 .159.013L21 25.172V23zm-3 10.172V28l6-6v2.172zm6-1V27l-6 6v2.172zM21 39v-1l6-6v2.172l-5.616 5.616A1 1 0 0 1 21 39m3 1 3-3v2a1 1 0 0 1-1 1zM9 30a3 3 0 0 0-3 3v6a3 3 0 0 0 3 3h4a3 3 0 0 0 3-3v-6a3 3 0 0 0-3-3zm-1 3a1 1 0 0 1 1-1h2.172L8 35.172zm0 5 5.707-5.707A1 1 0 0 1 14 33v1.172l-5.616 5.616A1 1 0 0 1 8 39zm3 2h2a1 1 0 0 0 1-1v-2z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconHighBars;
