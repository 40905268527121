import * as React from "react";
const IconEr = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 513 342"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 0h513v342H0z" />
    <path  d="M0 0h513v171H0z" />
    <path  d="m0 342 513-171L0 0z" />
    <g >
      <path d="M134.7 231.5c33.6 0 60.8-27.2 60.8-60.8s-27.2-60.8-60.8-60.8-60.8 27.2-60.8 60.8 27.2 60.8 60.8 60.8m0 24.3c-47 0-85.2-38.1-85.2-85.2s38.1-85.2 85.2-85.2 85.2 38.1 85.2 85.2-38.2 85.2-85.2 85.2" />
      <circle cx={119.5} cy={148.3} r={17.5} />
      <circle cx={148.9} cy={158.5} r={17.5} />
      <circle cx={134.7} cy={135.2} r={17.5} />
      <circle cx={119.5} cy={172.7} r={17.5} />
      <circle cx={149.9} cy={182.8} r={17.5} />
      <circle cx={122.5} cy={198} r={17.5} />
      <circle cx={145.9} cy={205.2} r={17.5} />
    </g>
  </svg>
);
export default IconEr;
