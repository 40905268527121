import * as React from "react";
const IconGiftCard = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56 21H43.7c1.1-.4 2-.9 2.6-1.5 3.1-3.1 3.1-8.1 0-11.1-3.1-3.1-8.1-3.1-11.1 0-1.8 1.8-2.7 6.1-3.1 9.4-.5-3.3-1.4-7.7-3.1-9.4-3.1-3.1-8.1-3.1-11.1 0-3.1 3.1-3.1 8.1 0 11.1.6.6 1.5 1.1 2.6 1.5H8c-2.8 0-5 2.2-5 5v27c0 2.8 2.2 5 5 5h48c2.8 0 5-2.2 5-5V26c0-2.8-2.2-5-5-5m3 5v5.8H33V23h23c1.7 0 3 1.3 3 3M36.5 9.7c2.3-2.3 6-2.3 8.3 0s2.3 6 0 8.3c-1.8 1.8-7.9 2.6-11.2 2.9.3-3.3 1.2-9.4 2.9-11.2m-17.4 0C20.3 8.6 21.8 8 23.3 8s3 .6 4.2 1.7c1.8 1.8 2.6 7.9 2.9 11.2-3.3-.3-9.4-1.1-11.2-2.9-2.4-2.3-2.4-6-.1-8.3M8 23h23v8.8H5V26c0-1.7 1.3-3 3-3M5 53V33.7h26V56H8c-1.7 0-3-1.3-3-3m51 3H33V33.7h26V53c0 1.7-1.3 3-3 3" />
  </svg>
);
export default IconGiftCard;
