import * as React from "react";
const IconPurseAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 12.7H6c-2.2 0-4 1.8-4 4v30.6c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V16.7c0-2.2-1.8-4-4-4m-52 2h52c1.1 0 2 .9 2 2v10.1l-27 8.5v-4.2c0-.6-.4-1-1-1s-1 .4-1 1v4.2L4 26.8V16.7c0-1.1.9-2 2-2m52 34.6H6c-1.1 0-2-.9-2-2V28.9l27.7 8.7h.6L60 28.9v18.4c0 1.1-.9 2-2 2" />
  </svg>
);
export default IconPurseAlt;
