import * as React from "react";
const IconMegaphone2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.3 3c0-.4-.3-.8-.7-.9s-.8 0-1.1.3c-6 7.3-17.2 11.7-25.1 13.2h-.5c-1.3.1-5.4.5-19.5 2-5.6.6-9.8 5.3-9.8 10.9s4.2 10.3 9.8 10.9c.9.1 1.8.2 2.6.3l3.8 18.2c.5 2.4 2.6 4.1 5 4.1h.9c1.6 0 3-.7 4-1.9s1.4-2.8 1-4.3l-3.1-14.9c3.2.3 4.6.5 5.3.5h.5c7.9 1.5 19.1 5.9 25.1 13.3.2.2.5.4.8.4.1 0 .2 0 .3-.1.4-.1.7-.5.7-.9zM28.2 58.8c-.6.7-1.5 1.2-2.4 1.2h-.9c-1.5 0-2.8-1.1-3.1-2.5l-3.5-16.8 7.4.7 3.1 14.8c.2.9 0 1.9-.6 2.6M5.7 28.6c0-4.6 3.4-8.4 8-8.9 11.6-1.2 16.5-1.7 18.5-1.9v21.6c-2.1-.2-7.1-.7-18.5-1.9-4.6-.5-8-4.4-8-8.9m28.5 11V17.5C41.6 16 51.9 12 58.3 5.6v45.9c-6.4-6.4-16.6-10.4-24.1-11.9" />
  </svg>
);
export default IconMegaphone2;
