import * as React from "react";
const IconCheckServer = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m58 46.6-6.7 5.3-1.9-2.7c-.3-.4-.9-.6-1.4-.2-.4.3-.6.9-.2 1.4l2.5 3.5c.2.2.4.4.7.4h.1c.2 0 .4-.1.6-.2l7.5-5.9c.4-.3.5-1 .2-1.4-.4-.5-1-.6-1.4-.2M62 23V9.8c0-2.8-2.2-5-5-5H7c-2.8 0-5 2.2-5 5V23c0 1.6.8 3.1 2 4-1.2.9-2 2.4-2 4v13.2c0 2.8 2.2 5 5 5h32.3c.6 0 1-.4 1-1s-.4-1-1-1H7c-1.7 0-3-1.3-3-3V31c0-1.7 1.3-3 3-3h50c1.7 0 3 1.3 3 3v6.6c0 .6.4 1 1 1s1-.4 1-1V31c0-1.6-.8-3.1-2-4 1.2-.9 2-2.4 2-4m-2 0c0 1.7-1.3 3-3 3H7c-1.7 0-3-1.3-3-3V9.8c0-1.7 1.3-3 3-3h50c1.7 0 3 1.3 3 3z" />
    <path d="M15.6 15.2c-.6 0-1 .4-1 1v.2c0 .6.4 1 1 1s1-.4 1-1v-.2c0-.6-.5-1-1-1M50.8 15.3H44c-.6 0-1 .4-1 1s.4 1 1 1h6.7c.6 0 1-.4 1-1s-.4-1-.9-1M14.6 37.4v.2c0 .6.4 1 1 1s1-.4 1-1v-.2c0-.6-.4-1-1-1s-1 .4-1 1M51.8 37.4c0-.6-.4-1-1-1H44c-.6 0-1 .4-1 1s.4 1 1 1h6.7c.6 0 1.1-.5 1.1-1" />
  </svg>
);
export default IconCheckServer;
