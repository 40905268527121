import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import BringzzText from "../BringzzText";

const BringzzHeader = ({
  background,
  headerHover = false,
  left,
  right,
  title,
  avatar,
}) => {
  return (
    <div
      className={classnames(
        !headerHover
          ? "relative flex"
          : "fixed h-14 z-50 w-full max-w-xl flex top-0 md:top-0 bg-white shadow-lg",
        background && "h-64 max-h-64"
      )}
    >
      <div
        className={classnames(
          "relative w-full h-10 z-20 flex justify-between items-center px-4 pt-4",
          background && "text-white"
        )}
      >
        <div className="flex space-x-1">{left}</div>
        <div className="absolute left-1/2 transform -translate-x-1/2">
          <BringzzText tag="h4" className={"font-bold"}>
            {title}
          </BringzzText>
        </div>
        <div className="flex space-x-3">{right}</div>
      </div>

      {background && (
        <>
          <div className="z-10 absolute top-0 w-full h-64 bg-gradient-to-b from-black/40 to-transparent"></div>
          <div className="absolute top-0 w-full h-64 max-h-64">
            {background}
          </div>
          {avatar && (
            <div className="absolute bottom-0 w-full flex justify-center">
              <div className="bg-white rounded-full h-20 w-20 translate-y-12 shadow-md">
                {avatar}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

BringzzHeader.propTypes = {
  left: PropTypes.node,
  title: PropTypes.node,
  right: PropTypes.node,
  background: PropTypes.node,
  avatar: PropTypes.node,
};

export default BringzzHeader;
