import * as React from "react";
const IconCottonBuds = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.8 4.2C56 .3 48.3 1.8 42.8 7.3c-5 5-6.6 17.1-6.9 19.4L2.3 60.3c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3l33.6-33.6c2.3-.3 14.4-1.9 19.4-6.9 5.5-5.5 7-13.2 3.1-17M38 26c.5-3.5 2.2-13.2 6.2-17.2C47.3 5.7 51 4 54.1 4c1.7 0 3.2.5 4.3 1.6 3 3 1.6 9.4-3.2 14.2-4 4-13.7 5.7-17.2 6.2" />
  </svg>
);
export default IconCottonBuds;
