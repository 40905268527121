import * as React from "react";
const IconMountains = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.5 43.4-6.4-14c-.9-2-2.8-3.2-5-3.2s-4.1 1.2-5 3.2l-.8 1.8-7-15.2c-.9-2-2.8-3.2-5-3.2s-4.1 1.2-5 3.2l-5 11-1.1-2.4c-.9-2-2.8-3.2-5-3.2s-4.1 1.2-5 3.2L2.5 43.4c-.8 1.7-.7 3.7.4 5.3s2.7 2.5 4.6 2.5h48.9c1.9 0 3.6-.9 4.6-2.5s1.3-3.6.5-5.3M4.6 47.6c-.6-1-.7-2.3-.2-3.4L13 25.4c.6-1.3 1.8-2 3.2-2s2.6.8 3.2 2l2 4.3L28 44.2c.5 1.1.4 2.3-.2 3.4-.6 1-1.7 1.6-2.9 1.6H7.5c-1.2 0-2.3-.6-2.9-1.6m32 1.6H29c.1-.2.3-.3.4-.5 1-1.6 1.2-3.6.4-5.3l-6.4-14 5.8-12.5c.6-1.3 1.8-2 3.2-2s2.6.8 3.2 2L43.4 34 48 44.2c.5 1.1.4 2.3-.2 3.4-.6 1-1.8 1.6-3 1.6zm22.8-1.6c-.6 1-1.8 1.6-3 1.6H49c.1-.2.3-.3.4-.5 1-1.6 1.2-3.6.4-5.3l-4.5-9.8 1.5-3.3c.6-1.3 1.8-2 3.2-2s2.6.8 3.2 2l6.4 14c.6 1 .5 2.2-.2 3.3" />
  </svg>
);
export default IconMountains;
