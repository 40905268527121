import * as React from "react";
const IconChurchAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.4 28.2 35.6 3.6c-1-1-2.2-1.6-3.6-1.6s-2.6.6-3.6 1.6L5.6 28.2c-.3.3-.3.7-.2 1.1q.3.6.9.6c2.3 0 4.1 1.9 4.1 4.1v27c0 .6.4 1 1 1h41c.6 0 1-.4 1-1V34.1c0-2.3 1.9-4.1 4.1-4.1.4 0 .8-.2.9-.6.3-.4.2-.9 0-1.2M38.2 60H25.8v-7.1c0-3.4 2.8-6.2 6.2-6.2s6.2 2.8 6.2 6.2zm13.3-25.9V60H40.2v-7.1c0-4.5-3.7-8.2-8.2-8.2s-8.2 3.7-8.2 8.2V60H12.5V34.1c0-2.7-1.7-5-4.2-5.8L29.9 4.9Q30.8 4 32 4t2.1.9l21.5 23.3a6.3 6.3 0 0 0-4.1 5.9" />
    <path d="M33 17.2c0-.6-.4-1-1-1s-1 .4-1 1v5.3h-5.3c-.6 0-1 .4-1 1s.4 1 1 1H31v9.2c0 .6.4 1 1 1s1-.4 1-1v-9.2h5.3c.6 0 1-.4 1-1s-.4-1-1-1H33z" />
  </svg>
);
export default IconChurchAlt1;
