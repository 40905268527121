import * as React from "react";
const IconMd = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 513 342"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 0h513v342H0z" />
    <path  d="M342 0h171v342H342z" />
    <path  d="M0 0h171v342H0z" />
    <path
      
      d="M206.2 129.1h33.2L256 79.3l16.6 49.8h33.2v99.6L256 262l-49.8-33.2zm33.2 33.3v49.8h33.2v-49.8z"
    />
  </svg>
);
export default IconMd;
