import * as React from "react";
const IconChatAlt8 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M39 6.9H25c-12.1 0-22 9.5-22 21.3s7.8 20.1 26.9 28.7c.3.1.6.2.9.2.4 0 .8-.1 1.2-.4.6-.4 1-1.1 1-1.9v-5.3c0-.1.1-.2.2-.2H39c12.1 0 22-9.5 22-21.3S51.1 6.9 39 6.9m0 40.5h-5.8c-1.2 0-2.2 1-2.2 2.2v5.3c0 .1-.1.1-.1.2 0 0-.1.1-.2 0C12.2 46.8 5 39.2 5 28.1 5 17.5 14 8.9 25 8.9h14c11 0 20 8.6 20 19.3s-9 19.2-20 19.2" />
    <path d="M17.7 25c-1.6 0-3 1.3-3 3s1.4 3 3 3c1.7 0 3-1.3 3-3s-1.3-3-3-3m.1 4c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.5 1-1 1M32 25c-1.6 0-3 1.3-3 3s1.4 3 3 3c1.7 0 3-1.3 3-3s-1.3-3-3-3m0 4c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1M46.2 25c-1.6 0-3 1.3-3 3s1.4 3 3 3c1.7 0 3-1.3 3-3s-1.3-3-3-3m.1 4c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.5 1-1 1" />
  </svg>
);
export default IconChatAlt8;
