import * as React from "react";
const IconBottleNipple = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 65 65"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.7 55.5H7.1c-2 0-3.7-1.6-3.8-3.5-.2-2.3-.2-5.9.5-9.7 1.4-7.4 4.9-13.3 10.3-17q1.5-1.05 2.1-2.7c1.4-3.7 5.9-12.4 16-12.4 10.2 0 14.6 8.7 16 12.4q.6 1.65 2.1 2.7c5.3 3.7 8.9 9.5 10.3 17 .6 3.7.6 7 .5 9.1-.1 2.5-1.9 4.1-4.4 4.1M5.8 42.7c-.7 3.6-.6 7-.5 9.2.1.9.9 1.6 1.8 1.6h49.6c1.5 0 2.4-.8 2.5-2.2.1-2 .1-5.2-.4-8.7-1.3-6.9-4.5-12.3-9.4-15.7-1.3-.9-2.3-2.1-2.9-3.6-1.3-3.4-5.2-11.2-14.2-11.2S19.4 20 18.1 23.4c-.6 1.5-1.5 2.7-2.9 3.6-4.9 3.4-8.1 8.8-9.4 15.7" />
  </svg>
);
export default IconBottleNipple;
