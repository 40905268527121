import * as React from "react";
const IconCottageAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m58.8 20.7-2.6-8.8c-.6-1.9-2.4-3.3-4.4-3.3h-9.1l-2.2-6c-.1-.3-.5-.6-.9-.6H24.4c-.4 0-.8.3-.9.7l-2.2 6h-9.1c-2 0-3.8 1.3-4.4 3.3l-2.6 8.8c-.4 1.4-.2 2.9.7 4 .9 1.2 2.2 1.8 3.7 1.8h2v30.8c0 2.5 2.1 4.6 4.6 4.6H48c2.5 0 4.6-2.1 4.6-4.6V26.6h2c1.5 0 2.8-.7 3.7-1.8.7-1.2.9-2.7.5-4.1M25.1 4h13.7l1.7 4.7h-17zm13.3 56H25.6V45.9c0-1.4 1.2-2.6 2.6-2.6h7.6c1.4 0 2.6 1.2 2.6 2.6zm12.1-2.6c0 1.4-1.2 2.6-2.6 2.6h-7.5V45.9c0-2.5-2.1-4.6-4.6-4.6h-7.6c-2.5 0-4.6 2.1-4.6 4.6V60h-7.5c-1.4 0-2.6-1.2-2.6-2.6V26.6h36.9v30.8zm6-33.8c-.5.7-1.2 1-2.1 1H9.6c-.8 0-1.6-.4-2.1-1-.5-.7-.6-1.5-.4-2.3l2.6-8.8c.3-1.1 1.3-1.8 2.5-1.8h39.5c1.1 0 2.1.8 2.5 1.8l2.6 8.8c.3.8.2 1.6-.3 2.3" />
  </svg>
);
export default IconCottageAlt2;
