import * as React from "react";
const IconHome7 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 45.9h-4V27.6c0-1.8-.9-3.4-2.5-4.2l-11.6-6.5a5.1 5.1 0 0 0-4.8 0l-13.6 7.5H12c-2.7 0-4.9 2.2-4.9 4.9V46H3c-.6 0-1 .4-1 1s.4 1 1 1h58c.6 0 1-.4 1-1s-.4-1.1-1-1.1M9.2 29.2c0-1.6 1.3-2.9 2.9-2.9h11.8v19.5H9.3V29.2zm16.6-3.3 13.3-7.4c.9-.5 1.9-.5 2.8 0L53.5 25c.9.5 1.5 1.5 1.5 2.5v18.3h-9.3V37c0-2.2-1.8-4-4-4h-2.5c-2.2 0-4 1.8-4 4v8.8h-9.4zm11.4 20v-8.8c0-1.1.9-2 2-2h2.5c1.1 0 2 .9 2 2v8.8z" />
  </svg>
);
export default IconHome7;
