import * as React from "react";
const IconBloodAbN = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M20.388 5.535a5.02 5.02 0 0 1 7.224 0l.528.548h5.19c2.573 0 4.67 2.074 4.67 4.646V15.5h-2v-4.771a2.66 2.66 0 0 0-2.67-2.646h-5.616a1 1 0 0 1-.72-.307l-.823-.855a3.02 3.02 0 0 0-4.342 0l-.823.855a1 1 0 0 1-.72.307H15c-1.663 0-3 1.338-3 2.974V28.97a69 69 0 0 1 3.7.06l.53.02c1.284.048 2.481.093 3.652.069 2.627-.056 5.042-.466 7.61-1.981 3.118-1.84 5.758-1.288 7.583-.226.338.197.647.41.925.626V22.5h2v10.526C38 35.78 35.755 38 33 38h-3v2h-5v4h-2v-4h-5v-2h-3c-2.755 0-5-2.22-5-4.974v-21.97c0-2.752 2.245-4.973 5-4.973h4.86zM15 36c-1.663 0-3-1.338-3-2.974v-2.055a66 66 0 0 1 3.627.058l.526.02c1.277.048 2.537.096 3.771.07 2.842-.06 5.627-.513 8.584-2.258 2.407-1.42 4.267-.972 5.56-.22A7 7 0 0 1 36 30.33v2.696C36 34.662 34.663 36 33 36z"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M19.416 14.6a1 1 0 0 0-1.832 0l-2.616 5.98-.018.04-.866 1.98a1 1 0 0 0 1.832.8l.613-1.4h3.942l.613 1.4a1 1 0 0 0 1.832-.8l-.866-1.98-.018-.04zm-.916 2.895L19.596 20h-2.192zM24 15a1 1 0 0 1 1-1h3.125C29.773 14 31 15.405 31 17a3.1 3.1 0 0 1-.732 2c.46.54.732 1.249.732 2 0 1.595-1.227 3-2.875 3H25a1 1 0 0 1-1-1zm4.125 3c.423 0 .875-.386.875-1s-.452-1-.875-1H26v2zM29 21c0 .614-.452 1-.875 1H26v-2h2.125c.423 0 .875.386.875 1"
      clipRule="evenodd"
    />
    <path  d="M34 18a1 1 0 1 0 0 2h3a1 1 0 0 0 0-2z" />
  </svg>
);
export default IconBloodAbN;
