import * as React from "react";
const IconCloudNetwork = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.9 41.6c-3.2 0-6 2.1-6.9 5h-1v-6.4c5.4-2 9.2-7.2 9.2-13.2 0-3.5-1.3-7-3.8-9.6-2-2.2-4.8-3.7-7.7-4.3-1.7-2.5-4.1-4.4-6.9-5.4-1.3-.5-2.9-.8-4.5-.8-7.1 0-13 5.4-13.7 12.3-5.2.9-9.3 5.4-9.3 10.9 0 4.5 2.7 8.4 6.7 10.1v6.4h-1c-1-2.9-3.7-5-6.9-5-4 0-7.3 3.2-7.3 7.2S5.1 56 9.1 56c3.2 0 6-2.1 6.9-5h2.7c1.6 0 2.8-1.3 2.8-2.8V41h8.2v2.1c-2.9.9-5.1 3.7-5.1 6.9 0 4 3.3 7.2 7.3 7.2s7.3-3.2 7.3-7.2c0-3.2-2.1-5.9-5.1-6.9V41h8.3v7.2c0 1.6 1.3 2.8 2.9 2.8H48c1 2.9 3.7 5 6.9 5 4 0 7.3-3.2 7.3-7.2.1-4-3.2-7.2-7.3-7.2M9.1 51.5c-1.5 0-2.8-1.2-2.8-2.7s1.3-2.7 2.8-2.7 2.8 1.2 2.8 2.7c0 1.4-1.3 2.7-2.8 2.7M32 52.6c-1.5 0-2.8-1.2-2.8-2.7s1.3-2.7 2.8-2.7 2.8 1.2 2.8 2.7-1.3 2.7-2.8 2.7M21.4 36.5c-3.7 0-6.6-2.9-6.6-6.5s2.9-6.5 6.6-6.5h.4c1.2 0 2.3-1 2.3-2.3v-.8c0-5 4.1-9.1 9.2-9.1 1.1 0 2.1.2 3 .5 2.1.7 3.9 2.3 5.1 4.3.4.6 1 1.1 1.7 1.2 2.3.2 4.5 1.3 6 3 1.7 1.8 2.6 4.1 2.6 6.6 0 5.3-4.4 9.6-9.8 9.6zm33.5 15c-1.5 0-2.8-1.2-2.8-2.7s1.3-2.7 2.8-2.7 2.8 1.2 2.8 2.7c.1 1.4-1.2 2.7-2.8 2.7" />
  </svg>
);
export default IconCloudNetwork;
