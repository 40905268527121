import * as React from "react";
const IconHospitalAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.3 17.1H47.7v-3.2c0-1.7-1.3-3-3-3h-.6V5c0-1.7-1.3-3-3-3H22.8c-1.7 0-3 1.3-3 3v5.8h-.6c-1.7 0-3 1.3-3 3V17H5.7C3.6 17 2 18.7 2 20.7V61c0 .6.4 1 1 1h58c.6 0 1-.4 1-1V20.8c0-2.1-1.7-3.7-3.7-3.7M21.8 5c0-.6.4-1 1-1h18.4c.6 0 1 .4 1 1v5.8H21.8zM4 20.8c0-1 .8-1.7 1.7-1.7h10.5V60H4zM33.4 60h-5V44.1h7.3V60zm4.3 0V43.1c0-.6-.4-1-1-1h-9.3c-.6 0-1 .4-1 1V60h-8.1V13.8c0-.6.4-1 1-1h25.6c.6 0 1 .4 1 1V60zM60 60H47.7V19.1h10.5c1 0 1.7.8 1.7 1.7V60z" />
    <path d="M36.6 24H33v-3.6c0-.6-.4-1-1-1s-1 .4-1 1V24h-3.6c-.6 0-1 .4-1 1s.4 1 1 1H31v3.6c0 .6.4 1 1 1s1-.4 1-1V26h3.6c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconHospitalAlt;
