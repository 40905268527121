import * as React from "react";
const IconYawn = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2C15.5 2 2 15.4 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.5 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
    <path d="M32 28.4c-6.4 0-11.5 5.2-11.5 11.6S25.7 51.6 32 51.6 43.5 46.4 43.5 40c0-6.5-5.1-11.6-11.5-11.6m0 21.1c-5.3 0-9.5-4.3-9.5-9.6s4.3-9.6 9.5-9.6c5.3 0 9.5 4.3 9.5 9.6s-4.2 9.6-9.5 9.6M24.5 18.2c-.3-.5-.9-.6-1.4-.2l-7.6 5.3c-.5.3-.6.9-.2 1.4.2.3.5.4.8.4.2 0 .4-.1.6-.2l7.6-5.3c.4-.3.6-1 .2-1.4M40.9 17.9c-.5-.3-1.1-.2-1.4.2-.3.5-.2 1.1.2 1.4l7.6 5.3c.2.1.4.2.6.2.3 0 .6-.1.8-.4.3-.5.2-1.1-.2-1.4z" />
  </svg>
);
export default IconYawn;
