import * as React from "react";
const IconHouseAlt6 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.6 19.8 35.2 2.7c-1.9-1.3-4.5-1.3-6.4 0L4.4 19.8c-1.5 1-2.4 2.8-2.4 4.6v32.3c0 3.1 2.5 5.6 5.6 5.6h48.8c3.1 0 5.6-2.5 5.6-5.6V24.4c0-1.8-.9-3.5-2.4-4.6M42.9 60.3H21.1V46.8c0-1.2.6-2.3 1.5-2.9l7.3-5.1c1.2-.9 2.9-.9 4.1 0l7.3 5.1c1 .7 1.5 1.8 1.5 2.9v13.5zM60 56.7c0 2-1.6 3.6-3.6 3.6H44.9V46.8c0-1.8-.9-3.5-2.4-4.6l-7.3-5.1c-1.9-1.3-4.5-1.3-6.4 0l-7.3 5.1c-1.5 1-2.4 2.8-2.4 4.6v13.5H7.6c-2 0-3.6-1.6-3.6-3.6V24.4c0-1.2.6-2.3 1.5-2.9L29.9 4.3q.9-.6 2.1-.6c1.2 0 1.4.2 2.1.6l24.4 17.1c1 .7 1.5 1.8 1.5 2.9z" />
  </svg>
);
export default IconHouseAlt6;
