import * as React from "react";
const IconRootSignAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M49.3 23.6H30.7c-.9 0-1.6.5-2 1.3L23 38.3s0 .1-.1.1-.1 0-.1-.1l-3.5-4.6c-.4-.5-1-.8-1.6-.8s-1.3.2-1.7.7l-2 2.2c-.4.4-.3 1 .1 1.4s1 .3 1.4-.1l1.9-2.2s.1 0 .1-.1c0 0 .1 0 .1.1l3.5 4.6c.4.5 1 .9 1.7.9h.3c.8-.1 1.4-.6 1.7-1.3l5.7-13.4c0-.1.1-.1.1-.1h18.6c.6 0 1-.4 1-1s-.4-1-.9-1" />
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
  </svg>
);
export default IconRootSignAlt1;
