import * as React from "react";
const IconLifting = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 6.4h-3.7c-2.2 0-4 1.8-4 4v4.3H13.7v-4.3c0-2.2-1.8-4-4-4H6c-2.2 0-4 1.8-4 4v9.3c0 2.2 1.8 4 4 4h3.7c2.2 0 4-1.8 4-4v-3h6.7c-.2.2-.4.5-.4.8v10.7c0 2.9 2.4 5.3 5.3 5.3h.1v13.3c-1.5 1.1-2.5 2.9-2.5 5v4.9c0 .6.4 1 1 1s1-.4 1-1v-4.9c0-2.3 1.9-4.2 4.2-4.2h5.4c2.3 0 4.2 1.9 4.2 4.2v4.9c0 .6.4 1 1 1s1-.4 1-1v-4.9c0-2-1-3.8-2.5-5V33.4h.4c2.8 0 5-2.3 5-5v-11c0-.3-.2-.6-.4-.8h7.1v3c0 2.2 1.8 4 4 4H58c2.2 0 4-1.8 4-4v-9.3c0-2.1-1.8-3.9-4-3.9M11.7 19.7c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2v-9.3c0-1.1.9-2 2-2h3.7c1.1 0 2 .9 2 2zm24.5 26.1c-.5-.2-1.1-.2-1.7-.2h-5.4c-.6 0-1.2.1-1.7.2V33.4h8.8zM29 27.5c0-1.6 1.3-2.9 2.9-2.9s2.9 1.3 2.9 2.9-1.3 2.9-2.9 2.9c-1.7 0-2.9-1.3-2.9-2.9m12.6-10v11c0 1.7-1.4 3-3 3h-3.8c1.2-.9 2-2.3 2-3.9 0-2.7-2.2-4.9-4.9-4.9S27 24.9 27 27.5c0 1.6.8 3 2 3.9h-3.5c-1.8 0-3.3-1.5-3.3-3.3V17.5c0-.3-.2-.6-.4-.8H42c-.2.2-.4.4-.4.8M60 19.7c0 1.1-.9 2-2 2h-3.7c-1.1 0-2-.9-2-2v-9.3c0-1.1.9-2 2-2H58c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconLifting;
