import * as React from "react";
const IconClose = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M35.2 32 59.6 7.6c.9-.9.9-2.3 0-3.2s-2.3-.9-3.2 0L32 28.8 7.6 4.4c-.9-.9-2.3-.9-3.2 0s-.9 2.3 0 3.2L28.8 32 4.4 56.4c-.9.9-.9 2.3 0 3.2.4.4 1 .7 1.6.7s1.2-.2 1.6-.7L32 35.2l24.4 24.4c.4.4 1 .7 1.6.7s1.2-.2 1.6-.7c.9-.9.9-2.3 0-3.2z" />
  </svg>
);
export default IconClose;
