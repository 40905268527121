import * as React from "react";
const IconMetaverse2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m40.8 19.1-8.3-5.3c-.3-.2-.8-.2-1.1 0l-8.3 5.3c-.3.2-.5.5-.5.8s.2.7.5.8l8.3 5.3c.2.1.4.2.5.2.2 0 .4-.1.5-.2l8.3-5.3c.3-.2.5-.5.5-.8s-.1-.7-.4-.8m-8.8 5-6.4-4.2 6.4-4.2 6.4 4.2zM21.1 31.2l-8.3-5.3c-.3-.2-.8-.2-1.1 0l-8.3 5.3c-.2.1-.4.5-.4.8s.2.7.5.8l8.3 5.3c.2.1.4.2.5.2s.4-.1.5-.2l8.3-5.3c.3-.2.5-.5.5-.8-.1-.3-.2-.7-.5-.8m-8.8 5L5.8 32l6.4-4.2 6.4 4.2zM60.5 31.2l-8.3-5.3c-.3-.2-.8-.2-1.1 0l-8.3 5.3c-.3.2-.5.5-.5.8s.2.7.5.8l8.3 5.3c.2.1.4.2.5.2s.4-.1.5-.2l8.3-5.3c.3-.2.5-.5.5-.8.1-.3-.1-.7-.4-.8m-8.8 5L45.3 32l6.4-4.2 6.4 4.2zM40.8 43.3 32.5 38c-.3-.2-.8-.2-1.1 0l-8.3 5.3c-.3.2-.5.5-.5.8s.2.7.5.8l8.3 5.3c.2.1.4.2.5.2.2 0 .4-.1.5-.2l8.3-5.3c.3-.2.5-.5.5-.8.1-.3-.1-.7-.4-.8M32 48.2 25.6 44l6.4-4.2 6.4 4.2z" />
  </svg>
);
export default IconMetaverse2;
