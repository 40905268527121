import * as React from "react";
const IconArrowsExpandDownLeft = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 23.4H44.8c-2.2 0-4-1.8-4-4V7.2c0-2.2 1.8-4 4-4H57c2.2 0 4 1.8 4 4v12.2c0 2.2-1.8 4-4 4M44.8 5.2c-1.1 0-2 .9-2 2v12.2c0 1.1.9 2 2 2H57c1.1 0 2-.9 2-2V7.2c0-1.1-.9-2-2-2zM20.2 60.8H4c-.1 0-.3 0-.4-.1-.1 0-.2-.1-.3-.2s-.2-.2-.2-.3c-.1-.2-.1-.3-.1-.4V43.6c0-.6.4-1 1-1s1 .4 1 1v13.8l24.8-24.5c.4-.4 1-.4 1.4 0s.4 1 0 1.4L6.4 58.8h13.7c.6 0 1 .4 1 1s-.4 1-.9 1" />
  </svg>
);
export default IconArrowsExpandDownLeft;
