import * as React from "react";
const IconRocketVerified = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M36.9 37.2v-.6l9.4-9.4C56.8 16.6 57.9 9 57.5 3c0-.5-.4-.9-.9-.9-6.1-.4-13.7.7-24.1 11.2L23 22.6H12.3c-.3 0-.5.1-.7.3l-6.4 6.4c-.2.2-.3.4-.3.7s.1.5.3.7l7.3 7.3q-1.35.45-2.4 1.5c-2.5 2.5-3.9 11.7-4 12.7 0 .3.1.6.3.8s.4.3.7.3h.1c1-.1 10.2-1.5 12.7-4q1.05-1.05 1.5-2.4l7.3 7.3c.2.2.5.3.7.3.3 0 .5-.1.7-.3l1.2-1.2c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-.5.5L7.3 30l5.4-5.4h10.7c.3 0 .5-.1.7-.3l9.7-9.7C43.2 5.2 49.9 3.8 55.5 4c.2 5.7-1.2 12.3-10.6 21.7l-9.7 9.7c-.2.2-.3.4-.3.7v1c0 .6.4 1 1 1s1-.4 1-.9M18.6 48c-1.4 1.4-6.5 2.6-10.3 3.2.6-3.7 1.9-8.9 3.2-10.3.7-.7 1.6-1.1 2.5-1.3l5.8 5.8c0 1-.5 1.9-1.2 2.6M47.8 39.5c-6.2 0-11.3 5.1-11.3 11.3S41.6 62 47.8 62s11.3-5.1 11.3-11.3S54 39.5 47.8 39.5m0 20.5c-5.1 0-9.3-4.2-9.3-9.3s4.2-9.3 9.3-9.3 9.3 4.2 9.3 9.3-4.2 9.3-9.3 9.3" />
    <path d="m50.1 48.3-3.4 3.2-1-1.1c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l1.7 1.8c.2.2.5.3.7.3s.5-.1.7-.3l4.2-3.9c.4-.4.4-1 0-1.4-.4-.3-1.1-.4-1.5 0" />
  </svg>
);
export default IconRocketVerified;
