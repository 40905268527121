import * as React from "react";
const IconMarketStall = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path  d="M22.5 28a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3" />
    <path
      
      fillRule="evenodd"
      d="M9.429 20q.292 0 .571-.048V28H7.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h33a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H38v-8.048q.28.048.571.048C40.473 20 42 18.448 42 16.551v-2.39a2.7 2.7 0 0 0-.163-.93l-2.18-6.01A1.85 1.85 0 0 0 37.917 6H10.082a1.85 1.85 0 0 0-1.738 1.222l-2.18 6.009a2.7 2.7 0 0 0-.164.93v2.39C6 18.448 7.527 20 9.429 20m-1.385-6.087L10.189 8H37.81l2.145 5.913q.044.12.044.248v2.39c0 .808-.648 1.449-1.429 1.449-.78 0-1.428-.64-1.428-1.449a1 1 0 0 0-2 0c0 .808-.648 1.449-1.429 1.449-.78 0-1.428-.64-1.428-1.448a1 1 0 0 0-2 0c0 .808-.648 1.448-1.429 1.448s-1.428-.64-1.428-1.449a1 1 0 1 0-2 0C25.429 17.359 24.78 18 24 18c-.78 0-1.428-.64-1.429-1.448a1 1 0 0 0-2 0c0 .808-.647 1.448-1.428 1.448s-1.429-.64-1.429-1.449a1 1 0 1 0-2 0c0 .808-.647 1.449-1.428 1.449s-1.429-.64-1.429-1.449a1 1 0 1 0-2 0c0 .808-.647 1.449-1.428 1.449C8.647 18 8 17.36 8 16.551v-2.39q0-.129.044-.248M36 19.122a3.4 3.4 0 0 1-2.286.878 3.4 3.4 0 0 1-2.428-1.014A3.4 3.4 0 0 1 28.857 20a3.4 3.4 0 0 1-2.428-1.014A3.4 3.4 0 0 1 24 20a3.4 3.4 0 0 1-2.429-1.014A3.4 3.4 0 0 1 19.143 20c-.951 0-1.81-.389-2.429-1.014A3.4 3.4 0 0 1 14.286 20c-.88 0-1.68-.333-2.286-.878V28h2.5a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v.5h1.5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H36zM8 33a1 1 0 0 1 1-1h30a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1zm2 7v-6h28v6z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconMarketStall;
