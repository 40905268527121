import * as React from "react";
const IconBandaged = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M40 24c0 8.837-7.163 16-16 16-7.03 0-13.002-4.535-15.15-10.838L7.5 30a.86.86 0 0 1-.428.134C9.581 37.055 16.213 42 24 42c9.941 0 18-8.059 18-18a17.94 17.94 0 0 0-5.107-12.56c-.023.222-.148.413-.393.56l-1.156.717A15.95 15.95 0 0 1 40 24M6.784 18.73c.339-.512.742-.934 1.216-1.23l1.975-1.207c2.652-4.816 7.713-8.12 13.56-8.286L26 6.5a2.2 2.2 0 0 1 .697-.3Q25.377 6.003 24 6C15.893 6 9.037 11.36 6.784 18.73"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M30.5 22a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1m-2.5.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0M16.171 24.618a2.5 2.5 0 0 0 3.817-2.369z"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="m26 6.5-18 11C4 20 5 31.5 7.5 30l29-18C39 10.5 29 4.5 26 6.5M7.592 27.589l26.702-16.574c-.474-.407-1.145-.876-1.96-1.333-1.16-.65-2.434-1.177-3.526-1.429-.545-.125-.983-.168-1.305-.154-.29.012-.395.067-.398.068l-.028.019L9.054 19.2c-.404.256-.89.899-1.258 2.103-.355 1.16-.512 2.566-.484 3.905.02.954.131 1.774.28 2.38M18.835 33.308q0 0-.003-.01zm-.001-.306a2.2 2.2 0 0 1 .238-.74c.834-1.279 2.626-2.262 4.82-2.262 2.113 0 3.858.913 4.728 2.127.072.1.11.147.133.178.024.032.035.045.054.078.034.06.094.204.137.403q.016.08.028.153c-2.123-.691-3.51-1.11-4.931-1.117-1.47-.009-2.918.42-5.207 1.18m10.139.338q0 0 .003-.01zm-12.008-1.164c.097-.355.245-.72.431-1.005C18.664 29.225 21.151 28 23.892 28c2.646 0 5.048 1.139 6.354 2.962l.017.023c.05.066.192.252.278.403.164.285.284.637.357.973.074.338.117.737.076 1.123-.038.356-.17.9-.64 1.268-.523.41-1.15.372-1.656.195-2.394-.782-3.53-1.12-4.648-1.126-1.115-.006-2.277.317-4.714 1.128l-.008.003-.008.002c-.56.176-1.221.216-1.771-.187-.528-.386-.669-.976-.7-1.377a3.7 3.7 0 0 1 .136-1.214"
      clipRule="evenodd"
    />
  </svg>
);
export default IconBandaged;
