import * as React from "react";
const IconLockAlt3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M31.3 37.5c-.6 0-1 .4-1 1v10.7c0 .6.4 1 1 1s1-.4 1-1V38.5c0-.6-.4-1-1-1" />
    <path d="M50.8 18.1h-5.3V10c0-4.4-3.6-8-8-8h-11c-4.4 0-8 3.6-8 8v8.2h-5.3c-2.2 0-4 1.8-4 4V58c0 2.2 1.8 4 4 4h37.6c2.2 0 4-1.8 4-4V22.1c0-2.2-1.8-4-4-4M20.5 10c0-3.3 2.7-6 6-6h11.1c3.3 0 6 2.7 6 6v8.2h-23V10zm32.3 48c0 1.1-.9 2-2 2H13.2c-1.1 0-2-.9-2-2V22.1c0-1.1.9-2 2-2h37.6c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconLockAlt3;
