import * as React from "react";
const IconRdtResultPfInvalid = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      d="M11.593 11.026a1.2 1.2 0 0 0-.742.079 1.4 1.4 0 0 0-.609.537A1.6 1.6 0 0 0 10 12.5c0 .31.087.61.242.858.156.248.37.432.609.537.237.105.494.131.742.079s.485-.185.677-.39a1 1 0 0 1 1.46 1.368 3.3 3.3 0 0 1-1.722.978 3.2 3.2 0 0 1-1.966-.206 3.4 3.4 0 0 1-1.495-1.304A3.6 3.6 0 0 1 8 12.5c0-.678.188-1.346.547-1.92a3.4 3.4 0 0 1 1.495-1.304 3.2 3.2 0 0 1 1.966-.206c.661.14 1.259.485 1.722.978a1 1 0 1 1-1.46 1.368 1.3 1.3 0 0 0-.677-.39M38 22a2 2 0 1 1 0 4 2 2 0 0 1 0-4M31 24a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0"
    />
    <path
      
      fillRule="evenodd"
      d="M8 24a3 3 0 0 0 3 3h15a3 3 0 1 0 0-6H11a3 3 0 0 0-3 3m14-1H11a1 1 0 1 0 0 2h11zm5 1a1 1 0 0 0-1-1h-2v2h2a1 1 0 0 0 1-1"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M4 24a7 7 0 0 0 7 7h26a7 7 0 1 0 0-14H11a7 7 0 0 0-7 7m33-5H11a5 5 0 0 0 0 10h26a5 5 0 0 0 0-10M14 32a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h1.5a2.5 2.5 0 0 0 0-5zm3 2.5a.5.5 0 0 1-.5.5H15v-1h1.5a.5.5 0 0 1 .5.5M27 10a1 1 0 0 1 1-1h2.5a2.5 2.5 0 0 1 0 5H29v1a1 1 0 1 1-2 0zm4 1.5a.5.5 0 0 0-.5-.5H29v1h1.5a.5.5 0 0 0 .5-.5"
      clipRule="evenodd"
    />
    <path
      
      d="M23.436 38.351a1 1 0 0 1-1.872 0l-1.5-4a1 1 0 1 1 1.872-.702l.564 1.503.564-1.503a1 1 0 1 1 1.872.702zM36 15a1 1 0 1 1-2 0v-2a1 1 0 1 1 0-2c0-.327.09-.794.389-1.21.335-.465.885-.79 1.611-.79a1 1 0 0 1 0 2 1 1 0 0 1 0 2z"
    />
  </svg>
);
export default IconRdtResultPfInvalid;
