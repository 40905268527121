import * as React from "react";
const IconCloset1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M48.4 2H15.6c-2.2 0-4 1.8-4 4v55c0 .6.4 1 1 1s1-.4 1-1v-7.3h36.7V61c0 .6.4 1 1 1s1-.4 1-1V6c.1-2.2-1.7-4-3.9-4m2 4v37.4H33V4h15.4c1.1 0 2 .9 2 2M15.6 4H31v39.4H13.6V6c0-1.1.9-2 2-2m-2 47.7v-6.2h36.7v6.2z" />
    <path d="M25.3 21.7c.6 0 1-.4 1-1V16c0-.6-.4-1-1-1s-1 .4-1 1v4.7c0 .6.5 1 1 1M38.7 21.7c.6 0 1-.4 1-1V16c0-.6-.4-1-1-1s-1 .4-1 1v4.7c0 .6.4 1 1 1" />
  </svg>
);
export default IconCloset1;
