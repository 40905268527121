import * as React from "react";
const IconInboxCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60 27.5c-.6 0-1 .4-1 1v18.8c0 4.5-3.5 8.2-7.8 8.2H12.8c-4.3 0-7.8-3.7-7.8-8.2V28.5c0-.6-.4-1-1-1s-1 .4-1 1v18.8c0 5.6 4.4 10.2 9.8 10.2h38.5c5.4 0 9.8-4.6 9.8-10.2V28.5c-.1-.6-.5-1-1.1-1" />
    <path d="M31.3 40.3c.2.2.5.3.7.3.3 0 .5-.1.7-.3l12.1-12.1c.4-.4.4-1 0-1.4s-1-.4-1.4 0L33 37.2V7.5c0-.6-.4-1-1-1s-1 .4-1 1v29.7L20.6 26.8c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4z" />
  </svg>
);
export default IconInboxCopy;
