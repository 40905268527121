import * as React from "react";
const IconEthereum2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 65 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m16.9 29.7 14.5 8.9c.2.1.3.1.5.1s.4 0 .5-.1L47 29.7c.5-.3.6-.9.3-1.4L32.8 3.2c-.4-.6-1.4-.6-1.7 0L16.5 28.3c-.2.5-.1 1.1.4 1.4m15-24 13.2 22.8-13.2 8.1-13.2-8.1z" />
    <path d="M47.2 37.7c-.3-.3-.8-.4-1.2-.2l-14 8.6-14-8.6c-.4-.2-.9-.2-1.2.2-.3.3-.4.9-.1 1.3l14.5 21.3c.2.3.5.4.8.4s.6-.2.8-.4L47.3 39c.2-.5.2-1-.1-1.3M31.9 57.9l-11-16.2 10.5 6.5c.3.2.7.2 1 0L43 41.7z" />
  </svg>
);
export default IconEthereum2;
