import * as React from "react";
const IconOld = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m52.3 47.5-.9-.9c-1.4-1.4-4.1-3-6-3.7l-6.5-2.2c4.5-2.4 7.6-7.2 7.6-12.7v-.5c4.3-1.5 7.1-5.1 7.1-9.1 0-3.5-2.2-6.8-5.7-8.5l-4.3-2.1C40.9 4.3 36.7 2 32 2s-8.9 2.3-11.5 5.8l-4.3 2.1c-3.5 1.7-5.7 5-5.7 8.5 0 4 2.8 7.6 7.1 9.1v.5c0 5.6 3.2 10.4 7.8 12.8l-6.3 1.9c-3.2 1-6 2.8-8 5.1-2.6 3.1-4 6.8-4 10.9v.8c0 1.4 1.1 2.5 2.5 2.5h44.9c1.4 0 2.5-1.1 2.5-2.5v-.8c-.1-4.2-1.7-8.2-4.7-11.2m-5.4-35.8c2.8 1.4 4.6 4 4.6 6.7 0 3-2 5.6-5.1 6.9v-9c0-1.9-.4-3.8-1.1-5.5zM32 4c6.8 0 12.4 5.6 12.4 12.4v11.7c0 1.2-.2 2.4-.5 3.5l-8.7-3.4c-2.3-.9-4.8-.9-7.1 0l-8 3.1c-.3-1-.4-2.1-.4-3.3V16.4C19.6 9.6 25.1 4 32 4M12.5 18.4c0-2.8 1.8-5.3 4.6-6.7l1.6-.8c-.7 1.7-1.1 3.6-1.1 5.5v9c-3.1-1.3-5.1-4-5.1-7m8.2 14.8 8.1-3.2c1.8-.7 3.8-.7 5.7 0l8.7 3.4c-2 4.2-6.3 7-11.2 7-5 .1-9.3-2.9-11.3-7.2m34.2 26.3c0 .3-.2.5-.5.5H9.5c-.3 0-.5-.2-.5-.5v-.8c0-3.5 1.2-6.9 3.6-9.6 1.7-2 4.2-3.6 7-4.5l8.7-2.7c1.1.3 2.3.5 3.6.5 1.4 0 2.8-.2 4.1-.6.1 0 .1.1.2.1l8.5 2.8c1.6.5 4 2 5.2 3.2l.9.9c2.6 2.6 4.1 6.2 4.1 9.9z" />
    <path d="M26.1 17.1h.1c.6 0 1-.4 1-1s-.4-1-1-1h-.1c-.6 0-1 .4-1 1s.4 1 1 1M37.8 17.1h.1c.6 0 1-.4 1-1s-.4-1-1-1h-.1c-.6 0-1 .4-1 1s.4 1 1 1" />
  </svg>
);
export default IconOld;
