import * as React from "react";
const IconBills1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M27 31.6h4.9c.6 0 1-.4 1-1s-.4-1-1-1h-2v-1.2c0-.6-.4-1-1-1s-1 .4-1 1v1.2H27c-2 0-3.6 1.6-3.6 3.6s1.6 3.6 3.6 3.6h2.3c.9 0 1.6.7 1.6 1.6s-.7 1.6-1.6 1.6h-4.9c-.6 0-1 .4-1 1s.4 1 1 1h3.5v1.2c0 .6.4 1 1 1s1-.4 1-1V42c1.7-.3 3-1.8 3-3.5 0-2-1.6-3.6-3.6-3.6H27c-.9 0-1.6-.7-1.6-1.6s.7-1.7 1.6-1.7" />
    <path d="M50.3 18.8H6c-2.2 0-4 1.8-4 4v26c0 2.2 1.8 4 4 4h44.3c2.2 0 4-1.8 4-4v-26c0-2.2-1.8-4-4-4m2 30c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2v-26c0-1.1.9-2 2-2h44.3c1.1 0 2 .9 2 2z" />
    <path d="M57.2 11.2H14.5c-.6 0-1 .4-1 1s.4 1 1 1h42.7c1.6 0 2.8 1.3 2.8 2.8v24.3c0 .6.4 1 1 1s1-.4 1-1V16c0-2.7-2.2-4.8-4.8-4.8M15.8 35h-3.7c-.6 0-1 .4-1 1s.4 1 1 1h3.7c.6 0 1-.4 1-1s-.5-1-1-1M45.2 35h-3.7c-.6 0-1 .4-1 1s.4 1 1 1h3.7c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconBills1;
