import * as React from "react";
const IconLeaflet = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 26 32"
    {...props}
  >
    <path
      
      d="m24.69 8.297-7.332-7.225c-.266-.319-.69-.478-1.116-.478H3.492A2.63 2.63 0 0 0 .836 3.25v25.5a2.63 2.63 0 0 0 2.656 2.656h18.966a2.63 2.63 0 0 0 2.656-2.656V9.413c.053-.425-.16-.85-.425-1.116m-1.223.265H17.2V2.4zm.638 20.188c0 .903-.69 1.594-1.594 1.594H3.492c-.903 0-1.594-.69-1.594-1.594V3.25c0-.903.691-1.594 1.594-1.594h12.644v7.438c0 .319.213.531.531.531h7.385V28.75z"
    />
  </svg>
);
export default IconLeaflet;
