import * as React from "react";
const IconBusCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M21.4 34.8c-2.7 0-4.9 2.2-4.9 4.9s2.2 4.9 4.9 4.9 4.9-2.2 4.9-4.9-2.2-4.9-4.9-4.9m0 7.7c-1.6 0-2.9-1.3-2.9-2.9s1.3-2.9 2.9-2.9 2.9 1.3 2.9 2.9-1.3 2.9-2.9 2.9M42.6 34.8c-2.7 0-4.9 2.2-4.9 4.9s2.2 4.9 4.9 4.9 4.9-2.2 4.9-4.9-2.2-4.9-4.9-4.9m0 7.7c-1.6 0-2.9-1.3-2.9-2.9s1.3-2.9 2.9-2.9 2.9 1.3 2.9 2.9-1.3 2.9-2.9 2.9" />
    <path d="M57.2 4.6H54C53.6 3.1 52.2 2 50.6 2H13.4c-1.6 0-3 1.1-3.4 2.6H6.8c-1.6 0-3 1.2-3.1 2.7v4.5c0 1.5 1.4 2.7 3.1 2.7h3.1v32.1c0 2.6 2.2 4.8 4.8 4.8h.5V57c0 2.8 2.2 5 5 5h2.5c2.8 0 5-2.2 5-5v-5.5h8.5V57c0 2.8 2.2 5 5 5h2.5c2.8 0 5-2.2 5-5v-5.5h.5c2.6 0 4.8-2.2 4.8-4.8V14.5h3.1c1.7 0 3.1-1.2 3.1-2.7V7.4c.1-1.5-1.3-2.8-3-2.8M13.4 4h37.1c.8 0 1.5.7 1.5 1.5v7H11.9v-7c0-.8.7-1.5 1.5-1.5m38.7 24.4H33V14.5h19.1zm-40.2 0V14.5H31v13.8H11.9zM6.8 12.5c-.6 0-1.1-.4-1.1-.7V7.4c0-.4.5-.8 1.1-.8h3.1v5.9zM25.7 57c0 1.7-1.3 3-3 3h-2.5c-1.7 0-3-1.3-3-3v-5.5h8.5zm21.1 0c0 1.7-1.3 3-3 3h-2.5c-1.7 0-3-1.3-3-3v-5.5h8.5zm2.5-7.5H14.7c-1.5 0-2.8-1.3-2.8-2.8V30.4h40.2v16.3c0 1.5-1.3 2.8-2.8 2.8m9-37.7c0 .3-.5.7-1.1.7h-3.1V6.6h3.1c.6 0 1.1.4 1.1.7z" />
  </svg>
);
export default IconBusCopy;
