import React, { useState } from 'react';
import { BringzzButton, BringzzText } from "@bringzz/components";
import { useNavigation } from 'context/NavigationContext';
import {
    BringzzPageContainer,
    BringzzIcon,
    BringzzRadioList,
    BringzzCountryDropdown,
    BringzzInput
} from "@bringzz/components";
import { useLocation } from 'react-router-dom';

const MatchmakingPickupCancelledPage = () => {
    const { backPress } = useNavigation();

    const { navigate } = useNavigation();

    return (
        <BringzzPageContainer
            className="bg-white"
            footer={
                <div className='px-4 mb-4'>
                    <BringzzButton
                        size='lg'
                        className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
                        onClick={() => {
                            navigate('/home');
                        }}
                    >
                        Continue
                    </BringzzButton>
                </div>
            }
            authenticated={false}
        >
            {/* Content */}
            <div className='flex-grow flex flex-col pt-44 px-4'>
                <div className='z-10 flex flex-col '>
                    <div className='flex flex-col text-center items-center space-y-4 mx-auto md:max-w-lg lg:max-w-xl xl:max-w-2xl'>
                        <div className=''>
                            <BringzzIcon
                                icon="IconMountain"
                                library="Custom"
                                className="flex items-center justify-center h-36 w-36 bg-gray-100 rounded-full "
                                size="90"
                            />
                        </div>
                        <BringzzText tag='h1' className='text-midnight-blue w-64'>
                            You order has been cancelled!
                        </BringzzText>
                        <div className='space-y-4 px-6'>
                            <BringzzText
                                tag='h4'
                                className='text-midnight-blue/25 font-normal'
                            >
                                We have sent your reservation request to the pharmacy and your shopper. Now waiting for the acceptance.
                            </BringzzText>
                        </div>
                    </div>
                </div>
            </div>
        </BringzzPageContainer >
    );
};

export default MatchmakingPickupCancelledPage;
