import * as React from "react";
const IconUsb = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M31.728 4.323a1 1 0 0 1 .87.506l4.878 8.59a1 1 0 0 1-.87 1.493h-3.878v18.525l7.05-3.444v-3.19h-.177c-1.667 0-2.991-1.37-2.991-3.025v-2.394c0-1.654 1.324-3.024 2.991-3.024h2.355c1.667 0 2.99 1.37 2.99 3.024v2.394c0 1.655-1.323 3.024-2.99 3.024h-.178v3.815a1 1 0 0 1-.56.899l-8.49 4.147v12.003c3.004.487 5.28 3.13 5.28 6.289 0 3.501-2.796 6.368-6.28 6.368s-6.28-2.867-6.28-6.368c0-3.16 2.276-5.802 5.28-6.289v-4.68l-8.052-3.934a1 1 0 0 1-.56-.898v-5.712c-1.829-.455-3.17-2.129-3.17-4.1 0-2.315 1.852-4.22 4.17-4.22s4.168 1.905 4.168 4.22c0 1.971-1.34 3.645-3.169 4.1v5.087l6.613 3.23V14.913H26.85a1 1 0 0 1-.87-1.494l4.878-8.59a1 1 0 0 1 .87-.506m-3.16 8.59h6.32l-3.16-5.565zM39.6 20.36c-.532 0-.991.443-.991 1.024v2.394c0 .581.46 1.024.991 1.024h2.355c.532 0 .99-.443.99-1.024v-2.394c0-.581-.458-1.024-.99-1.024zm-16.486 5.762c-1.182 0-2.168.978-2.168 2.22s.986 2.222 2.168 2.222 2.169-.98 2.169-2.221c0-1.243-.987-2.221-2.169-2.221m8.613 23.464c-2.349 0-4.28 1.94-4.28 4.369s1.931 4.368 4.28 4.368 4.28-1.94 4.28-4.368-1.931-4.369-4.28-4.369"
      clipRule="evenodd"
    />
  </svg>
);
export default IconUsb;
