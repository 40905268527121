import * as React from "react";
const IconCapsules = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M14.6 4.7C7.7 4.7 2 10.3 2 17.3v29.4c0 6.9 5.7 12.6 12.6 12.6s12.6-5.7 12.6-12.6V17.3c0-7-5.7-12.6-12.6-12.6m10.6 42c0 5.8-4.8 10.6-10.6 10.6S4 52.6 4 46.7V33h21.2zm0-15.7H4V17.3C4 11.4 8.8 6.7 14.6 6.7s10.6 4.8 10.6 10.6zM49.4 4.7c-6.9 0-12.6 5.7-12.6 12.6v29.4c0 6.9 5.7 12.6 12.6 12.6S62 53.7 62 46.7V17.3c0-7-5.7-12.6-12.6-12.6m10.6 42c0 5.8-4.8 10.6-10.6 10.6s-10.6-4.8-10.6-10.6V33H60zM38.8 17.3c0-5.8 4.8-10.6 10.6-10.6S60 11.5 60 17.3V31H38.8z" />
  </svg>
);
export default IconCapsules;
