import * as React from "react";
const IconLamp1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.8 60h-9.6V17.3C47.2 11 42.1 6 35.9 6H21.5l-3.7-3.7c-.4-.4-1-.4-1.4 0L10.7 8c-.4.4-.4 1 0 1.4l.5.5-3.5 3.5c-.9.9-1.5 2.2-1.5 3.5s.5 2.6 1.5 3.5l5.7 5.7c.9.9 2.2 1.5 3.5 1.5s2.6-.5 3.5-1.5l3.5-3.5.5.5c.2.2.5.3.7.3s.5-.1.7-.3l5.7-5.7c.4-.4.4-1 0-1.4l-8.2-8.2h12.4c5.2 0 9.3 4.2 9.3 9.3V60h-9.6c-.6 0-1 .4-1 1s.4 1 1 1h21.1c.6 0 1-.4 1-1s-.2-1-.7-1M19.1 24.9C18 26 16 26 14.9 24.9l-5.7-5.7q-.9-.9-.9-2.1c0-.8.3-1.6.9-2.1l3.5-3.5 10 10zm6.1-3.8-.5-.5L13.3 9.2l-.5-.5 4.3-4.3 12.4 12.4z" />
  </svg>
);
export default IconLamp1;
