import * as React from "react";
const IconCabinet = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.9 2H7.1c-.6 0-1 .4-1 1s.4 1 1 1h3.7v50.7c0 .6.4 1 1 1h1.4V61c0 .6.4 1 1 1s1-.4 1-1v-5.3h33.6V61c0 .6.4 1 1 1s1-.4 1-1v-5.3h1.4c.6 0 1-.4 1-1V4h3.7c.6 0 1-.4 1-1s-.5-1-1-1M12.8 53.7V30.3h38.4v23.4zm38.4-25.4H12.8V4h38.4z" />
    <path d="M27.4 16.6h9.2c.6 0 1-.4 1-1s-.4-1-1-1h-9.2c-.6 0-1 .4-1 1s.5 1 1 1M36.6 40.7h-9.2c-.6 0-1 .4-1 1s.4 1 1 1h9.2c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconCabinet;
