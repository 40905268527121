import * as React from "react";
const IconCottageAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.6 29s0-.1 0 0v-.1c0-.1 0-.2-.1-.3v-.1c0-.1-.1-.2-.2-.3L35.6 3.6c-1-1-2.2-1.6-3.6-1.6s-2.6.6-3.6 1.6L5.6 28.2c-.1.1-.1.2-.2.3v.1c0 .1 0 .2-.1.3v.1c0 .1 0 .2.1.3 0 .1.1.2.2.3l.1.1c.1.1.1.1.2.1H6c.1 0 .2.1.3.1 2.3 0 4.1 1.9 4.1 4.1v22c0 3.3 2.7 6 6 6h31c3.3 0 6-2.7 6-6V34.1c0-2.3 1.9-4.1 4.1-4.1.1 0 .2 0 .3-.1h.1c.1 0 .1-.1.2-.1 0 0 .1 0 .1-.1.1-.1.1-.2.2-.3.2-.2.2-.3.2-.4M29.9 4.9Q30.8 4 32 4t2.1.9l21.2 23H8.7zM38.2 60H25.8v-7.1c0-3.4 2.8-6.2 6.2-6.2s6.2 2.8 6.2 6.2zm13.3-25.9V56c0 2.2-1.8 4-4 4h-7.3v-7.1c0-4.5-3.7-8.2-8.2-8.2s-8.2 3.7-8.2 8.2V60h-7.3c-2.2 0-4-1.8-4-4V34.1c0-1.6-.6-3-1.6-4.1h42.2c-1 1-1.6 2.5-1.6 4.1" />
  </svg>
);
export default IconCottageAlt;
