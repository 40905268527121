import * as React from "react";
const IconService = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.7 39.7h-1.5c-1.5-12.5-11.1-22.2-22.9-23.3v-2.5h1.1c1.2 0 2.3-1 2.3-2.3s-1-2.3-2.3-2.3h-6.7c-1.2 0-2.3 1-2.3 2.3s1 2.3 2.3 2.3h1.1v2.5c-11.9 1.1-21.4 10.8-23 23.3H5.3c-2 0-3.6 1.6-3.6 3.6v5.2c0 3.4 2.7 6.1 6.1 6.1h48.3c3.4 0 6.1-2.7 6.1-6.1v-5.2c.1-2-1.6-3.6-3.5-3.6M32 20.7c10.4 0 19.1 8.1 20.7 18.9H11.3C12.9 28.9 21.6 20.7 32 20.7m25.8 27.8c0 .9-.7 1.6-1.6 1.6H7.9c-.9 0-1.6-.7-1.6-1.6v-4.3h51.5z" />
  </svg>
);
export default IconService;
