import * as React from "react";
const IconCoolKiss = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M39.9 42.5c0-2-1.9-3.6-4.2-3.6-.6 0-1 .4-1 1s.4 1 1 1c1.2 0 2.2.7 2.2 1.6s-1 1.6-2.2 1.6c-.6 0-1 .4-1 1s.4 1 1 1c1.2 0 2.2.7 2.2 1.6s-1 1.6-2.2 1.6c-.6 0-1 .4-1 1s.4 1 1 1c2.3 0 4.2-1.6 4.2-3.6 0-1-.5-2-1.3-2.6.8-.6 1.3-1.6 1.3-2.6" />
    <path d="M31.9 2c-16.5 0-30 13.5-30 30s13.5 30 30 30 30-13.5 30-30-13.5-30-30-30m0 58c-15.4 0-28-12.6-28-28s12.6-28 28-28 28 12.6 28 28-12.6 28-28 28" />
    <path d="M48.1 16.5H15.6c-.7 0-1.3.7-1.3 1.6V26c0 1.7 1.2 3.1 2.6 3.1h9.6c1.3 0 2.8-1.5 2.8-3.3v-4.3h5v4.3c0 1.8 1.1 3.3 2.4 3.3h10.1c1.4 0 2.6-1.4 2.6-3.1v-8c.1-.8-.5-1.5-1.3-1.5" />
  </svg>
);
export default IconCoolKiss;
