import * as React from "react";
const IconDeliveryTruck4 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.8 30.9 54.3 20c-.2-.3-.5-.4-.8-.4H40.9v-3.5c0-3-2.5-5.5-5.5-5.5H7.5c-3 0-5.5 2.5-5.5 5.5V44c0 3 2.5 5.5 5.5 5.5h8.4c.7 2.3 2.8 3.9 5.3 3.9s4.6-1.6 5.3-3.9h18c.7 2.3 2.8 3.9 5.3 3.9s4.6-1.6 5.3-3.9H61c.6 0 1-.4 1-1V31.4c0-.2-.1-.4-.2-.5M53 21.6l6.6 9.6H40.9v-9.6zM4 44V16.1c0-1.9 1.6-3.5 3.5-3.5h27.9c1.9 0 3.5 1.6 3.5 3.5v31.4H26.7c-.2-2.8-2.6-5.1-5.5-5.1s-5.3 2.2-5.5 5.1H7.5C5.6 47.5 4 45.9 4 44m17.2 7.4c-1.9 0-3.5-1.6-3.5-3.5s1.6-3.5 3.5-3.5 3.5 1.6 3.5 3.5-1.6 3.5-3.5 3.5m28.5 0c-1.9 0-3.5-1.6-3.5-3.5s1.6-3.5 3.5-3.5 3.5 1.6 3.5 3.5-1.6 3.5-3.5 3.5m5.5-3.9c-.2-2.8-2.6-5.1-5.5-5.1s-5.3 2.2-5.5 5.1h-3.3V33.2H60v14.3z" />
  </svg>
);
export default IconDeliveryTruck4;
