import * as React from "react";
const IconGooglePay = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M15.166 25.59a5.56 5.56 0 0 0-3.928-1.537c-2.67 0-4.937 1.8-5.748 4.227a6.14 6.14 0 0 0 0 3.919c.81 2.426 3.078 4.226 5.748 4.226 1.384 0 2.567-.355 3.486-.978v-.002a4.74 4.74 0 0 0 2.044-3.111h-5.53V28.39h9.656c.118.687.177 1.384.176 2.082 0 3.115-1.113 5.75-3.046 7.532h-.002l.002.001c-1.696 1.565-4.023 2.472-6.786 2.472a10.24 10.24 0 0 1 0-20.477 9.84 9.84 0 0 1 6.85 2.666zm15.156 6.438v7.217h-2.29V21.423h6.072a5.5 5.5 0 0 1 3.932 1.543 4.96 4.96 0 0 1 1.643 3.76 4.94 4.94 0 0 1-1.643 3.784q-1.592 1.518-3.932 1.517h-3.782zm0-8.411v6.221h3.84a3.03 3.03 0 0 0 2.265-.921 3.037 3.037 0 0 0 0-4.356 2.99 2.99 0 0 0-2.266-.945h-3.84m14.633 3.036q2.538 0 4.007 1.357 1.47 1.356 1.467 3.72v7.515h-2.19v-1.692h-.1q-1.42 2.09-3.782 2.09-2.015 0-3.371-1.194a3.82 3.82 0 0 1-1.357-2.987q0-1.892 1.43-3.01t3.82-1.12q2.04 0 3.359.746v-.523a2.6 2.6 0 0 0-.946-2.027 3.24 3.24 0 0 0-2.21-.834q-1.917 0-3.035 1.618l-2.017-1.27q1.664-2.39 4.925-2.39m-2.963 8.86a1.83 1.83 0 0 0 .76 1.492c.506.399 1.135.61 1.779.597a3.65 3.65 0 0 0 2.575-1.069q1.138-1.07 1.138-2.513-1.071-.852-2.987-.853-1.395 0-2.326.673c-.627.455-.939 1.008-.939 1.672m13.363 9.107L63 27.051h-2.493l-3.533 8.76h-.05l-3.634-8.76h-2.488l5.027 11.422-2.837 6.147z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconGooglePay;
