import React, { useEffect, useState } from 'react';
import {
	BringzzTopBar,
	BringzzPromoBanner,
	BringzzCategoryBox,
	BringzzBoxSlider,
	BringzzCallToAction,
	BringzzPromoCard,
	BringzzPostingCard,
	BringzzImage, BringzzText, BringzzArticle,
	BringzzBottomDrawer,
	BringzzBasketDrawer
} from "@bringzz/components";
import useRequest from 'hooks/useRequest';
import { useAuth } from 'context/AuthContext';

import BringzzLogo from 'assets/BringzzLogo.png';
import WomanCallToAction from 'assets/images/WomanCallToAction.png';
import ManCallToAction from 'assets/images/ManCallToAction.png';
import PromoCardBackground from 'assets/images/PromoCardBackground.jpeg';
import AvatarExample from 'assets/images/AvatarExample.png';
import AddressSelect from '../../components/AddressSelect';

import RecommendationBox from '../../components/RecommendationBox';

import { useNavigation } from '../../context/NavigationContext';
import { useOrder } from 'context/OrderContext';

const HomePage = () => {
	const { navigate } = useNavigation();

	const [showingSelect, setShowingSelect] = useState(false);
	const [showingAddressSelect, setShowingAddressSelect] = useState(false);
	const [showingDrawer, setShowingDrawer] = useState(false);
	const [userAddress, setUserAddress] = useState(null);
	const [showOpenBasketBottomSheet, setOpenBasketBottomSheet] = useState(false);
	const { sendRequest, loading } = useRequest();
	const { user } = useAuth();
	const { resetOrderContext } = useOrder()

	const closeAddressSelection = () => {
		setShowingDrawer(false);

		// wait for drawer animation animation to finish
		setTimeout(() => {
			setShowingAddressSelect(false);
		}, 500);
	};
	const onAddToCart = (productId, quantity) => {
		console.log('added to cart ', productId);
		setOpenBasketBottomSheet(true);
	};
	const [quantity, setQuantity] = useState(1);
	const [bookmarks, setBookmarks] = useState([])
	const [posts, setPosts] = useState([])

	const fetchPosts = async () => {
		await sendRequest({
			method: 'POST',
			url: `/module/post/getAllPosts`,
			data: {
				userId: user.id
			}
		}).then((response) => {
			if (response.status === 'success') {
				console.log(response.data);
				setPosts(response.data)
			}
		}).catch((error) => {
			console.error(error);
		})
	}

	const fetchCollections = async () => {
		await sendRequest({
			method: 'POST',
			url: `/module/bookmarkcollection/getAllCollections`,
			data: {
				userId: user.id
			}
		})
			.then(response => {
				if (response.status === 'success') {
					setBookmarks(response.data)
				}
			})
			.catch(error => {
				console.log(error);
			});
	}

	useEffect(() => {
		fetchPosts()
		// fetchCollections()
	}, [])

	return (
		<div>
			<button onClick={resetOrderContext} className='cursor-pointer mt-3'>Reset orderContext</button>
			<BringzzTopBar
				sections={[
					{ name: 'logo', alignment: 'start', value: BringzzLogo },
					{
						name: 'location',
						alignment: 'center',
						value: userAddress ? userAddress : 'Select address',
						onClick: () => setShowingAddressSelect(true)
					},
					{
						name: 'notification',
						alignment: 'end',
						value: true,
						onClick: () => navigate('/notifications')
					}
				]}
			/>

			{showingAddressSelect && (
				<BringzzBottomDrawer
					isOpen={true}
					close={closeAddressSelection}
					title='Select adress to continue'
				>
					<AddressSelect
						close={closeAddressSelection}
						setAddress={setUserAddress}
					></AddressSelect>
				</BringzzBottomDrawer>
			)}
			<BringzzPromoBanner
				header='Free delivery – today only!'
				subHeader={
					<>
						Lectus est mi augue amet fames ultrices.{' '}
						<span className='underline'>Promo Terms</span>
					</>
				}
			></BringzzPromoBanner>
			<div className=''>
				<BringzzBoxSlider
					behavior={{
						xl: 'left',
						m: 'left'
					}}
					className='h-[69px] space-x-2 px-2 items-center py-3'
					firstItemDivider={{
						className: 'border border-magic-lilac'
					}}
				>
					<BringzzCategoryBox
						left={{
							icon: 'IconMegaphone2',
							folder: 'LineIcons'
						}}
						className='bg-magic-lilac'
					></BringzzCategoryBox>
					<BringzzCategoryBox
						left={{
							icon: 'IconTowel',
							folder: 'LineIcons'
						}}
						label={'Margen & Darm'}
						className='bg-sand'
					></BringzzCategoryBox>
					<BringzzCategoryBox
						left={{
							icon: 'IconTowel',
							folder: 'LineIcons'
						}}
						label={'Margen & Darm'}
						className='bg-sand'
					></BringzzCategoryBox>
					<BringzzCategoryBox
						left={{
							icon: 'IconTowel',
							folder: 'LineIcons'
						}}
						label={'Margen & Darm'}
						className='bg-sand'
					></BringzzCategoryBox>
					<BringzzCategoryBox
						left={{
							icon: 'IconTowel',
							folder: 'LineIcons'
						}}
						label={'Margen & Darm'}
						className='bg-sand'
					></BringzzCategoryBox>

					<BringzzCategoryBox
						left={{
							icon: 'IconTemparatureMeasuring',
							folder: 'LineIcons'
						}}
						label={'Grippe'}
						className='bg-sand'
					></BringzzCategoryBox>
					<BringzzCategoryBox
						left={{
							icon: 'IconNose',
							folder: 'LineIcons'
						}}
						label={'Allergie'}
						className='bg-sand'
					></BringzzCategoryBox>
					<BringzzCategoryBox
						label={'Show All'}
						className='bg-lemon-yellow'
					></BringzzCategoryBox>
				</BringzzBoxSlider>
				<BringzzCallToAction
					text='Scan your prescription and get your delivery!'
					primaryButton={{
						label: 'Scan now',
						className: 'bg-lemon-yellow text-olive-green'
					}}
					image={WomanCallToAction}
				></BringzzCallToAction>
				<RecommendationBox addToCart={onAddToCart} />
				<BringzzCallToAction
					text='Need advice from a pharmacist near you?'
					primaryButton={{
						label: 'Chat',
						className: 'bg-lemon-yellow text-olive-green'
					}}
					secondaryButton={{
						label: 'Call',
						className: 'bg-lemon-yellow text-olive-green'
					}}
					image={ManCallToAction}
				></BringzzCallToAction>

				{posts && posts.map((post, indx) => {
					if (post.type == 1) {
						return <div className='' key={indx}>
							<BringzzArticle
								title={post.headline || ''}
								subTitle={post.description || ''}
								likesCount={100}
								commentsCount={10}
								interactionBar={false}
							/>

							<div className="flex items-center space-x-2 px-4">
								<img src={AvatarExample} className="h-8 w-8" alt="avatar" />
								<BringzzText
									tag="h4"
									className={"font-bold"}
								>
									{post.createdBy}
								</BringzzText>
								<div className='flex items-center space-x-1'>
									<span className="text-[5px]">&#9679;</span>
									<BringzzText
										tag="h4"
										className={'font-normal text-magic-lilac'}
									>
										Follow
									</BringzzText>
								</div>
							</div>

							<div className='mt-3'>
								<BringzzImage src={post.images[0].content} alt='article image' className='h-56 w-full' />
								<BringzzText className={'font-normal italic p-3'} tag='h5'>{post.imageCaption}</BringzzText>
							</div>
							{post.components && post.components.map((block, index) => {
								return <div key={index} className='px-3'>
									{block.component == "body_copy" && <BringzzText tag='h4'>{block.content}</BringzzText>}
									{block.component == "inline_heading" && <BringzzText tag='h2'>{block.content}</BringzzText>}
								</div>
							})}
						</div>

					} else if (post.type == 2) {
						return <BringzzPostingCard
							key={indx}
							avatar={AvatarExample}
							name={post.createdBy}
							header={
								<BringzzImage
									className='w-full h-48'
									src={post.images[0].content}
									alt='post image'
								></BringzzImage>
							}
							title={post.headline}
							description={post.description}
							action={{
								label: 'Read more',
								icon: 'IconEbook',
								iconFolder: 'LineIcons',
							}}
							bookmarks={post.bookmarks}
							handleBookmarkClick={() => {
								console.log("OK");
							}}
						></BringzzPostingCard>
					} else if (post.type == 3) {
						return <BringzzPostingCard
							key={indx}
							avatar={AvatarExample}
							name={post.createdBy}
							sponsored={true}
							header={
								<BringzzImage
									className='w-full h-auto'
									src={post.images[0].content}
									alt='post image'
								></BringzzImage>
							}
							title={post.headline || ''}
							description={post.description || ''}
							action={{
								label: 'View products',
								icon: 'IconEbook',
								iconFolder: 'LineIcons',
							}}
							bookmarks={post.bookmarks}
						></BringzzPostingCard>
					} else {
						return <div key={indx}></div>
					}
				})}

				<BringzzPromoCard
					title='Ad/Promo Card headline lorem ipsum dolor sit amet est'
					subTitle='Ultrices nunc dolor purus libero metus tincidunt iaculis. Neque
            ultrices.'
					backgroundImage={PromoCardBackground}
				></BringzzPromoCard>
			</div>
			<div className='h-60'>x</div>
			<BringzzBasketDrawer
				isOpen={showOpenBasketBottomSheet}
				continueShoppingBtn={() => setOpenBasketBottomSheet(false)}
				goToBasketBtn={() => navigate('/basket')}
				basketContainerProps={{ className: 'min-h-32' }}
				products={[
					{
						imgUrl: '/product.png'
						, price: 1.2, quantity: 1
					}
				]}
			/>
		</div >
	);
};

export default HomePage;
