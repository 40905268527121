import * as React from "react";
const IconAirtable = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 65 64"
    {...props}
  >
    <path
      
      d="M32.614 5.243c-1.158 0-2.32.229-3.414.685L5.38 15.778c-1.341.555-1.307 2.422.032 2.977l23.952 9.488a8.7 8.7 0 0 0 6.544 0l23.952-9.488c1.341-.523 1.341-2.422.032-2.976L36.006 5.928a8.7 8.7 0 0 0-3.392-.685m30.421 16.24a1.6 1.6 0 0 0-.592.12L35.744 31.94a1.63 1.63 0 0 0-1.013 1.504v23.723a1.6 1.6 0 0 0 2.19 1.472l26.7-10.373a1.555 1.555 0 0 0 1.014-1.47V23.075a1.6 1.6 0 0 0-1.6-1.59zm-60.597.104a1.72 1.72 0 0 0-1.28.506c-.294.296-.523.654-.523 1.08v22.28c0 1.179 1.373 1.966 2.421 1.44l16.72-8.016.819-.392 7.917-3.829c1.243-.587 1.147-2.421-.162-2.912L2.99 21.701a1.5 1.5 0 0 0-.552-.117z"
    />
  </svg>
);
export default IconAirtable;
