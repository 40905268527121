import * as React from "react";
const IconDropboxCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m62.2 31.6-11.8-7.8L62.3 16c.5-.3.8-.9.7-1.4 0-.6-.3-1.1-.8-1.4L46.8 3c-.5-.4-1.3-.4-1.9 0l-13 8.6L19 3c-.6-.5-1.4-.5-2 0L1.8 13.1c-.5.3-.8.9-.8 1.4 0 .6.3 1.1.8 1.4l11.8 7.8-11.8 7.9c-.5.3-.8.8-.8 1.4s.3 1.1.8 1.4l9.7 6.4v7c0 .6.3 1.1.8 1.5L31.2 61q.45.3.9.3t.9-.3l18.8-11.5c.5-.3.8-.9.8-1.5v-7.2l9.6-6.4c.5-.3.8-.8.8-1.4-.1-.6-.3-1.1-.8-1.4m-15.4-7.8L32 33.6l-14.7-9.9L32 14zm-.9-19 14.8 9.8-12.1 8-14.8-9.8zM3.2 14.5 18 4.7l12.1 8-14.7 9.8h-.2zM15.3 25l14.8 9.9-12.1 8L3.2 33zm-1.9 17.2 3.7 2.4q.45.3.9.3c.3 0 .7-.1.9-.3l12-7.9v21.8L13.4 47.7zm37.2 5.6L33 58.6V36.7l12 7.9q.45.3.9.3c.45 0 .7-.1.9-.3l3.7-2.4v5.6zm-4.6-5-12.1-7.9 5.6-3.7 9.2-6.1 12.1 8z" />
  </svg>
);
export default IconDropboxCopy;
