import * as React from "react";
const IconAi = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.3 11 39.2 2.7c-.7-.4-1.5-.7-2.4-.7H15.1c-2.3 0-4.2 1.9-4.2 4.2v51.6c0 2.3 1.9 4.2 4.2 4.2H49c2.3 0 4.2-1.9 4.2-4.2V14.5c0-1.4-.7-2.7-1.9-3.5m-.1 46.8c0 1.2-1 2.2-2.2 2.2H15.1c-1.2 0-2.2-1-2.2-2.2V6.2c0-1.2 1-2.2 2.2-2.2h21.8c.4 0 .9.1 1.2.4l12.1 8.3c.6.4 1 1.1 1 1.8z" />
    <path d="M28 29c-.4-.6-1.4-.6-1.7 0l-7.9 13.8c-.3.5-.1 1.1.4 1.4.2.1.3.1.5.1.3 0 .7-.2.9-.5l2-3.4h10.1l2 3.4c.3.5.9.6 1.4.4.5-.3.6-.9.4-1.4zm-4.8 9.4 3.9-6.8 3.9 6.8zM42.3 35.6c-.6 0-1 .4-1 1v6.7c0 .6.4 1 1 1s1-.4 1-1v-6.7c0-.6-.4-1-1-1" />
    <circle cx={42.3} cy={30.6} r={2.1} />
  </svg>
);
export default IconAi;
