import * as React from "react";
const IconLoveLetter = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 14H6c-2.2 0-4 1.8-4 4v28c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V18c0-2.2-1.8-4-4-4M25.8 24.8c.8-.8 1.9-1.2 3-1.2.9 0 1.8.3 2.6.8.3.2.8.2 1.1 0 1.8-1.2 4.2-1 5.8.5.9.9 1.3 2 1.3 3.2s-.5 2.3-1.3 3.2l-6 6q-.3.3-.6 0l-5.8-5.8c-.9-.9-1.5-2.3-1.4-3.6-.1-1.2.4-2.3 1.3-3.1m13.9-1.3c-2.1-2.1-5.2-2.5-7.7-1.1-1-.5-2-.8-3.1-.8-1.6 0-3.2.6-4.4 1.7-.5.5-1 1-1.3 1.7L6.4 16h51.2l-16.8 9c-.3-.5-.7-1-1.1-1.5M60 46c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V18c0-.3.1-.6.2-.9l18.3 9.8c-.1.3-.1.7-.1 1 0 1.9.7 3.7 2 5.1l5.8 5.8c.5.5 1.1.7 1.7.7s1.2-.2 1.7-.7l6-6c1.2-1.2 1.9-2.9 1.9-4.6 0-.4 0-.8-.1-1.2l18.3-9.8c.1.3.2.6.2.9V46z" />
  </svg>
);
export default IconLoveLetter;
