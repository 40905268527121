import * as React from "react";
const IconSunglass = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.3 15.2c-.5-.2-1.1.1-1.3.6l-5 15.3c-.2 0-.4-.1-.6-.1H43.9c-2.8 0-5 2.2-5 5v.3H25.1V36c0-2.8-2.2-5-5-5H9.6c-.2 0-.4 0-.6.1L3.9 15.9c-.2-.5-.7-.8-1.3-.6-.5.2-.8.7-.6 1.3l5.1 15.2c-1.5.9-2.5 2.5-2.5 4.3v7.8c0 2.8 2.2 5 5 5h10.5c2.8 0 5-2.2 5-5v-5.5h13.8v5.5c0 2.8 2.2 5 5 5h10.5c2.8 0 5-2.2 5-5V36c0-1.8-1-3.4-2.5-4.3L62 16.5c.1-.5-.2-1.1-.7-1.3M23.1 43.8c0 1.7-1.3 3-3 3H9.6c-1.7 0-3-1.3-3-3V36c0-1.7 1.3-3 3-3h10.5c1.7 0 3 1.3 3 3zm34.3 0c0 1.7-1.3 3-3 3H43.9c-1.7 0-3-1.3-3-3V36c0-1.7 1.3-3 3-3h10.5c1.7 0 3 1.3 3 3z" />
  </svg>
);
export default IconSunglass;
