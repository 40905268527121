import * as React from "react";
const IconIos = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      
      d="M62.72 8.52c-.6-1.58-2.04-3.8-4.08-5.38-1.2-.9-2.56-1.78-4.48-2.34C52.08.2 49.52 0 46.4 0H17.6c-3.14 0-5.68.2-7.74.82-1.92.58-3.34 1.44-4.5 2.34-2.04 1.56-3.48 3.8-4.1 5.38C.02 11.72 0 15.32 0 17.6v28.8c0 2.28.02 5.88 1.26 9.08.62 1.58 2.06 3.8 4.1 5.38 1.18.9 2.58 1.78 4.5 2.34 2.06.6 4.6.8 7.74.8h28.8c3.14 0 5.68-.2 7.74-.82 1.92-.58 3.34-1.44 4.5-2.34 2.04-1.56 3.48-3.8 4.1-5.38C64 52.28 64 48.66 64 46.38v-28.8c0-2.28-.02-5.88-1.26-9.08zM12.68 44H10.3V29.78h2.38zM11.5 27.38c-.8 0-1.48-.66-1.48-1.48s.66-1.48 1.48-1.48 1.5.66 1.5 1.48c-.02.82-.68 1.48-1.5 1.48m14.3 16.98c-6.28 0-10.24-4.48-10.24-11.62S19.52 21.1 25.8 21.1s10.24 4.5 10.24 11.64-3.96 11.62-10.24 11.62m20.72 0c-4.72 0-8.04-2.6-8.26-6.48h2.14c.24 2.7 2.78 4.54 6.32 4.54 3.38 0 5.8-1.84 5.8-4.38 0-2.1-1.46-3.36-4.8-4.2l-2.8-.72c-4.18-1.06-6.04-2.82-6.04-5.8 0-3.64 3.28-6.24 7.72-6.24 4.4 0 7.6 2.6 7.74 6.18H52.2c-.2-2.54-2.46-4.24-5.66-4.24-3.12 0-5.44 1.74-5.44 4.22 0 1.94 1.4 3.08 4.72 3.92l2.44.62c4.54 1.14 6.44 2.88 6.44 6.02 0 3.96-3.18 6.56-8.18 6.56M33.86 32.74c0 5.9-3.12 9.64-8.06 9.64s-8.04-3.74-8.04-9.64c0-5.92 3.1-9.66 8.04-9.66s8.06 3.74 8.06 9.66"
    />
  </svg>
);
export default IconIos;
