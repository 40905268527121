import * as React from "react";
const IconMetaverse7 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 65 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60 29.1c-.3-2.8-4.1-5.2-10.8-6.8-.5-.1-1.1.2-1.2.7s.2 1.1.7 1.2c6 1.4 8.6 3.3 9.2 4.6-2.8 2.1-7.5 4.3-12.7 4.4-.5 0-1 .5-1 1v13.9c-5.9-.9-14.3-1.6-23.5-.2V34.3c0-.5-.4-1-1-1-5.1-.1-9.7-2.3-12.6-4.4.6-1.4 3.4-3.4 9.8-4.8.5-.1.9-.7.8-1.2s-.7-.9-1.2-.8c-7.1 1.6-11.1 4-11.4 6.9v24.1c0 5 6.5 8.9 14.8 8.9.6 0 1-.4 1-1V50c9.2-1.4 17.6-.7 23.5.2V61c0 .6.4 1 1 1 8.3 0 14.8-3.9 14.8-8.9V29.3c-.2-.1-.2-.2-.2-.2M18.8 60C12.3 59.7 7 56.7 7 53.1V31.2c2.9 1.9 7.1 3.7 11.8 4zM58 53.1c0 3.5-5.3 6.6-11.8 6.9V35.3c4.7-.3 8.8-2.1 11.8-4z" />
    <path d="m22.5 20.3 9.5 5.9c.2.1.3.1.5.1s.4 0 .5-.1l9.5-5.9c.5-.3.6-.9.3-1.4L33.4 2.5c-.4-.6-1.4-.6-1.7 0l-9.5 16.4c-.3.5-.2 1.1.3 1.4M32.5 5l8.1 14.1-8.1 5-8.1-5z" />
    <path d="M31.7 40.7c.2.3.5.4.8.4s.6-.2.8-.4l9.5-13.9c.3-.4.2-.9-.1-1.3-.3-.3-.8-.4-1.2-.2l-9 5.5-9-5.5c-.4-.2-.9-.2-1.2.2-.3.3-.4.9-.1 1.3zm.3-7.9c.3.2.7.2 1 0l5.5-3.4-6 8.9-6-8.9z" />
  </svg>
);
export default IconMetaverse7;
