import * as React from "react";
const IconDashboardAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 10.8c-16 0-29 13-29 29 0 4.5 1 8.9 3 12.9.2.3.5.5.8.5H11c.6 0 1-.4 1-1s-.4-1-1-1H7.5C5.9 47.6 5 43.8 5 39.8c0-14.9 12.1-27 27-27s27 12.1 27 27c0 4-.9 7.8-2.5 11.4H53c-.6 0-1 .4-1 1s.4 1 1 1h4.1q.6 0 .9-.6c2-4 3-8.4 3-12.9 0-15.9-13-28.9-29-28.9" />
    <path d="m38.1 34.4-6.7 5c-1.2.2-2.3.9-3 1.9-.1.2-.2.3-.3.5l-.2.2h.1c-1.1 2.1-.5 4.7 1.5 6.2 2 1.4 4.7 1.2 6.3-.5h.1l.1-.2c.1-.1.3-.3.4-.5.8-1 1-2.3.9-3.5l2.6-8c.3-.9-.9-1.7-1.8-1.1M15.4 40h-4.1c-.6 0-1 .4-1 1s.4 1 1 1h4.1c.6 0 1-.4 1-1s-.4-1-1-1M53.6 41c0-.6-.4-1-1-1h-4.1c-.6 0-1 .4-1 1s.4 1 1 1h4.1c.6 0 1-.4 1-1M33 24.4v-4.1c0-.6-.4-1-1-1s-1 .4-1 1v4.1c0 .6.4 1 1 1s1-.4 1-1M47.3 25.7c-.4-.4-1-.4-1.4 0L43 28.6c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l2.9-2.9c.4-.4.4-1 0-1.4M18.1 25.7c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l2.9 2.9c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4z" />
  </svg>
);
export default IconDashboardAlt;
