import * as React from "react";
const IconWashingMachine = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.9 2H9.1c-2.2 0-4 1.8-4 4v52c0 2.2 1.8 4 4 4H55c2.2 0 4-1.8 4-4V6c-.1-2.2-1.9-4-4.1-4M9.1 4H55c1.1 0 2 .9 2 2v12.2H7.1V6c0-1.1.9-2 2-2m45.8 56H9.1c-1.1 0-2-.9-2-2V20.2H57V58c-.1 1.1-1 2-2.1 2" />
    <path d="M32 25.8c-7.6 0-13.8 6.2-13.8 13.8S24.4 53.3 32 53.3s13.8-6.2 13.8-13.8S39.6 25.8 32 25.8m0 25.5c-6.5 0-11.8-5.3-11.8-11.8S25.5 27.8 32 27.8s11.8 5.3 11.8 11.8S38.5 51.3 32 51.3M47.5 14.9c2 0 3.7-1.6 3.7-3.7s-1.6-3.7-3.7-3.7-3.7 1.6-3.7 3.7 1.6 3.7 3.7 3.7m0-5.4c.9 0 1.7.7 1.7 1.7s-.7 1.7-1.7 1.7-1.7-.7-1.7-1.7.7-1.7 1.7-1.7" />
    <path d="M32 34c-3 0-5.5 2.5-5.5 5.5S29 45 32 45s5.5-2.5 5.5-5.5S35 34 32 34m0 9.1c-1.9 0-3.5-1.6-3.5-3.5S30.1 36 32 36s3.5 1.6 3.5 3.5-1.6 3.6-3.5 3.6" />
  </svg>
);
export default IconWashingMachine;
