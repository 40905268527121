import * as React from "react";
const IconChilliSauce = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M42 22.4v-5.8c0-2.2-1.8-4-4-4h-.9l-3.5-10c-.1-.3-.5-.6-.9-.6h-1.2c-.4 0-.8.3-.9.7l-3.5 10h-1c-2.2 0-4 1.8-4 4v5.8c-1 .7-1.7 1.9-1.7 3.3V58c0 2.2 1.8 4 4 4h15.5c2.2 0 4-1.8 4-4V25.7c-.2-1.3-.8-2.5-1.9-3.3m-17.7 1.3h15.5c1.1 0 2 .9 2 2v26.6H22.3V25.7c0-1.1.9-2 2-2m7.8-19.4 2.9 8.4h-5.9zM24 21.7v-5.1c0-1.1.9-2 2-2h12c1.1 0 2 .9 2 2v5.1H24M39.7 60H24.3c-1.1 0-2-.9-2-2v-3.7h19.5V58c-.1 1.1-1 2-2.1 2" />
    <path d="M28.8 48.5h.3c4.1-1.2 7-5.1 7-9.4v-4.3c0-1.6-1.1-2.9-2.5-3.3v-1.3c0-.6-.4-1-1-1s-1 .4-1 1v1.4c-1.4.5-2.4 1.7-2.4 3.3v4.6c0 2.3-.4 4.6-1.1 6.9l-.3.9c-.1.4 0 .7.2 1 .3.1.6.2.8.2m2.5-9.1v-4.6c0-.8.6-1.4 1.4-1.4s1.4.6 1.4 1.4v4.3c0 2.7-1.5 5.2-3.7 6.6q.9-3.15.9-6.3" />
  </svg>
);
export default IconChilliSauce;
