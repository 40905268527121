import * as React from "react";
const IconBackPain = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M32 30.833a3 3 0 1 1 6 0V43a1 1 0 1 1-2 0V30.833a1 1 0 1 0-2 0v.223a1 1 0 1 1-2 0z"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M29.473 14c-3.01 0-5.168 1.782-6.537 4.217C21.59 20.608 21 23.627 21 26.282c0 .761.252 1.55.537 2.223.296.698.68 1.392 1.05 1.996q.128.21.255.406a1 1 0 0 1 .158.54v9.686c0 .415.382.867 1 .867s1-.452 1-.867v-9.191c0-2.97.974-5.947 1.791-8.447l.023-.069q.218-.663.415-1.284.316-.995.65-1.875a1 1 0 0 1 1.91.128l.716 3.078c.075.322.267.56.49.682l4.624 1.033a1 1 0 0 1 .18.059c.39.169.915 0 1.127-.575.208-.567-.04-1.141-.43-1.37l-3.7-.644a1 1 0 0 1-.802-.758l-1.658-7.12c-.12-.513-.512-.78-.863-.78m-8.28 3.236c1.601-2.848 4.33-5.236 8.28-5.236 1.385 0 2.505 1.013 2.811 2.327l1.508 6.475 3.188.555q.117.02.227.068c1.505.653 2.149 2.434 1.595 3.938-.553 1.504-2.176 2.357-3.702 1.758l-4.654-1.04a1 1 0 0 1-.18-.058 2.99 2.99 0 0 1-1.616-1.776c-.822 2.514-1.65 5.14-1.65 7.695v9.191C27 42.78 25.59 44 24 44s-3-1.22-3-2.867v-9.396q-.06-.094-.119-.192a17.5 17.5 0 0 1-1.185-2.26c-.323-.76-.696-1.843-.696-3.003 0-2.904.639-6.283 2.193-9.046M10.117 10.075a1 1 0 0 1 1.352-.413l5.578 2.965a1 1 0 0 1-.083 1.805l-1.71.716 3.126 1.725a1 1 0 0 1-.966 1.751L12.477 15.9a1 1 0 0 1 .097-1.798l1.67-.7-3.713-1.974a1 1 0 0 1-.414-1.353M9.832 19.978c.11-.56.623-.927 1.145-.819l3.666.76c.352.072.632.348.73.717.096.368-.007.767-.269 1.036l-.797.82 1.938.504c.515.134.824.692.689 1.247s-.663.896-1.179.762l-3.625-.944a.98.98 0 0 1-.69-.73c-.087-.363.018-.75.273-1.013l.724-.744-1.862-.385c-.521-.108-.854-.65-.743-1.21M33 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4m0 2a4 4 0 1 0 0-8 4 4 0 0 0 0 8"
      clipRule="evenodd"
    />
  </svg>
);
export default IconBackPain;
