import * as React from "react";
const IconHeartBaloon = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M48.6 5.6C46.5 3.3 43.5 2 40.3 2c-2.4 0-4.6.8-6.5 2.2-.6.5-1.2 1.1-1.8 1.7-.6-.6-1.2-1.2-1.8-1.7C28.3 2.8 26.1 2 23.7 2c-3.2 0-6.1 1.3-8.3 3.6-2.1 2.3-3.3 5.3-3.3 8.7s1.3 6.5 4 9.8c2.3 2.8 5.7 5.6 9.5 8.9 1.1.9 2.3 2 3.6 3l-3.9 1.8c-.5.2-.7.8-.5 1.3q.3.6.9.6c.1 0 .3 0 .4-.1l5-2.3c.1 0 .2.1.3.1l-.3.5a6.05 6.05 0 0 0 0 6.1c.7 1.2.7 2.8 0 4a6.05 6.05 0 0 0 0 6.1c.7 1.2.7 2.8 0 4l-1.5 2.5c-.3.5-.1 1.1.3 1.4.2.1.3.1.5.1.3 0 .7-.2.9-.5l1.5-2.5c1.1-1.9 1.1-4.2 0-6.1-.7-1.2-.7-2.8 0-4 1.1-1.9 1.1-4.2 0-6.1-.7-1.2-.7-2.8 0-4l1.1-1.8c.1.1.2.1.3.2l5 2.3c.1.1.3.1.4.1q.6 0 .9-.6c.2-.5 0-1.1-.5-1.3l-4.8-2.2c1.1-.9 2.2-1.8 3.1-2.7 3.8-3.2 7.1-6 9.5-8.8 2.7-3.2 4-6.4 4-9.8.1-3.4-1.1-6.5-3.2-8.7m-2.2 17.1c-2.2 2.7-5.5 5.4-9.2 8.6-1.3 1.1-2.8 2.4-4.3 3.7-.4.4-1.2.4-1.6 0-1.5-1.3-3-2.6-4.3-3.7-3.8-3.2-7-5.9-9.2-8.6-2.4-2.9-3.5-5.6-3.5-8.5 0-2.8 1-5.4 2.7-7.3C18.6 5 21 4 23.7 4c2 0 3.7.6 5.3 1.8.6.5 1.2 1.1 1.8 1.7.3.4.8.6 1.3.6s.9-.2 1.3-.6c.5-.6 1.1-1.2 1.8-1.7C36.6 4.6 38.4 4 40.3 4c2.6 0 5.1 1 6.8 2.9 1.8 1.9 2.7 4.5 2.7 7.3.1 3-1 5.7-3.4 8.5" />
  </svg>
);
export default IconHeartBaloon;
