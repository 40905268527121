import * as React from "react";
const IconBurgerSoda = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.9 39.7s0-.1 0 0c0-7.4-5.9-13.3-13.3-13.3H34.3l.5-5.8c.1-1.3-.3-2.5-1.2-3.5-.9-.9-2.1-1.5-3.4-1.5H19.8l1.4-6.3c.5-2.3 2.5-3.9 4.9-3.9h8.6c.6 0 1-.4 1-1s-.4-1-1-1h-8.6c-3.3 0-6.1 2.3-6.8 5.5l-1.5 6.7H7.6c-1.3 0-2.5.5-3.4 1.5-.8.9-1.3 2.2-1.2 3.5l3 35.8c.2 2.4 2.2 4.2 4.6 4.2h16.5c1.2 0 2.3-.5 3.1-1.2 1.4.5 3 .8 4.6.8h11.7c3.5 0 6.9-1.4 9.4-3.9s3.9-5.8 3.9-9.4v-.1c.7-.7 1.1-1.6 1.1-2.7v-1.8c.1-1-.3-1.9-1-2.6m-.9 4.5c0 1-.8 1.8-1.8 1.8h-4.9c-.3 0-.6.1-.8.4L47.6 51l-5.2-4.8c-.1-.1-.4-.2-.6-.2H24.2c-1 0-1.8-.8-1.8-1.8v-1.8c0-1 .8-1.7 1.7-1.8h33c1 0 1.7.8 1.7 1.8v1.8zM46.6 28.3c5.9 0 10.7 4.5 11.2 10.3H23.6c.2-2.6 1.4-5.1 3.3-7 2.1-2.1 5-3.3 8-3.3zM5.7 18.5c.5-.5 1.2-.8 1.9-.8h22.5c.7 0 1.4.3 1.9.8s.7 1.2.7 2l-.5 5.9H5.5l-.5-6c0-.7.2-1.4.7-1.9m21.4 40.1H10.6c-1.4 0-2.5-1-2.6-2.4L5.7 28.3h22.2c-.9.5-1.7 1.2-2.4 1.9-2.5 2.5-3.9 5.8-3.9 9.4v.1c-.7.7-1.1 1.6-1.1 2.7v1.8c0 1.1.4 2 1.1 2.7v.1c0 4.9 2.6 9.1 6.6 11.4-.4.1-.7.2-1.1.2M57.8 48c-.2 2.6-1.4 5.1-3.3 7-2.1 2.1-5 3.3-8 3.3H34.9c-5.9 0-10.7-4.5-11.2-10.3h17.8l5.7 5.2c.2.2.4.3.7.3h.1c.3 0 .5-.1.7-.4L53 48h4.8" />
  </svg>
);
export default IconBurgerSoda;
