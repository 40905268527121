import * as React from "react";
const IconTrademark = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 65 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M49.9 23.6c-.4-.1-.9 0-1.1.4L43 32.3 37.3 24c-.2-.4-.7-.5-1.1-.4s-.7.5-.7 1v14.9c0 .6.4 1 1 1s1-.4 1-1V27.8l4.7 6.8c.4.5 1.3.5 1.6 0l4.7-6.8v11.7c0 .6.4 1 1 1s1-.4 1-1V24.6c.1-.5-.2-.9-.6-1M30.3 23.6H15.4c-.6 0-1 .4-1 1s.4 1 1 1h6.4v13.9c0 .6.4 1 1 1s1-.4 1-1V25.6h6.4c.6 0 1-.4 1-1s-.4-1-.9-1" />
    <path d="M32.5 3c-16 0-29 13-29 29s13 29 29 29 29-13 29-29-13-29-29-29m0 56c-14.9 0-27-12.1-27-27s12.1-27 27-27 27 12.1 27 27-12.1 27-27 27" />
  </svg>
);
export default IconTrademark;
