import * as React from "react";
const IconMobileRocket3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M37.2 48.4c-.6 0-1 .4-1 1v.4H4V10c0-2.8 2.3-5.1 5.1-5.1h22c2.8 0 5.1 2.3 5.1 5.1v1.6c0 .6.4 1 1 1s1-.4 1-1V10c0-3.9-3.2-7.1-7.1-7.1h-22C5.2 2.9 2 6.1 2 10v44c0 3.9 3.2 7.1 7.1 7.1h22c3.9 0 7.1-3.2 7.1-7.1v-4.7c0-.5-.4-.9-1-.9m-6.1 10.7h-22C6.3 59.1 4 56.8 4 54v-2.3h32.2V54c0 2.8-2.3 5.1-5.1 5.1" />
    <path d="M62 8.7c0-.5-.4-.9-.9-.9-4.8-.3-10.8.5-19.2 8.8l-3.1 3.1-4.8-1.3c-2.3-.7-4.7-.1-6.4 1.6L23 24.6c-.2.2-.3.6-.3 1 .1.3.3.6.7.7l6.7 2-1.4 1.4c-.2.2-.3.4-.3.7s.1.5.3.7l9.9 9.9c.2.2.5.3.7.3s.5-.1.7-.3l1.4-1.4 2 6.7c.1.3.4.6.7.7h.2c.3 0 .5-.1.7-.3l4.6-4.6c1.7-1.7 2.3-4.1 1.6-6.4L50 31.1l3.1-3.1c8.3-8.4 9.1-14.4 8.9-19.3M29 21.4c1.2-1.2 2.9-1.6 4.4-1.1l3.6 1.1-5.3 5.3-6.1-1.8zm20.4 14.9c.5 1.6 0 3.3-1.1 4.4l-3.4 3.4-1.8-6.1 5.3-5.3zm-1.3-6.2L41.2 37l-1.9 1.9-8.5-8.5 12.3-12.3c7.3-7.3 12.4-8.4 16.8-8.3.1 4.4-1.1 9.5-8.3 16.8z" />
  </svg>
);
export default IconMobileRocket3;
