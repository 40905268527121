import * as React from "react";
const IconRadioButtonCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M8.3 4.6C4.8 4.6 2 7.5 2 10.9c0 3.5 2.8 6.3 6.3 6.3s6.3-2.8 6.3-6.3c-.1-3.4-2.9-6.3-6.3-6.3m0 10.6c-2.4 0-4.3-1.9-4.3-4.3s1.9-4.3 4.3-4.3 4.3 1.9 4.3 4.3-2 4.3-4.3 4.3M8.3 25.7C4.8 25.7 2 28.5 2 32s2.8 6.3 6.3 6.3 6.3-2.8 6.3-6.3-2.9-6.3-6.3-6.3m0 10.6C5.9 36.3 4 34.4 4 32s1.9-4.3 4.3-4.3 4.3 1.9 4.3 4.3c-.1 2.4-2 4.3-4.3 4.3M8.3 46.8c-3.5 0-6.3 2.8-6.3 6.3s2.8 6.3 6.3 6.3 6.3-2.8 6.3-6.3-2.9-6.3-6.3-6.3m0 10.6c-2.4 0-4.3-1.9-4.3-4.3s1.9-4.3 4.3-4.3 4.3 1.9 4.3 4.3-2 4.3-4.3 4.3M24.1 11.9H61c.6 0 1-.4 1-1s-.4-1-1-1H24.1c-.6 0-1 .4-1 1s.4 1 1 1M61 31H24.1c-.6 0-1 .4-1 1s.4 1 1 1H61c.6 0 1-.4 1-1s-.4-1-1-1M61 52.1H24.1c-.6 0-1 .4-1 1s.4 1 1 1H61c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconRadioButtonCopy;
