import * as React from "react";
const IconSpeechBubble10 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.3 17.1c-2.5-2.2-5.8-3.4-9.1-3.2C46 6.9 39.4 2 32 2S18 6.9 15.9 13.9c-3.3-.2-6.6 1-9.1 3.2C4 19.5 2.4 23 2.4 26.7c0 4.5 2.4 8.7 6.2 11C6 41 2.3 47.8 2.6 59.6c0 1.2.9 2.2 2 2.4h.5c1 0 1.9-.6 2.3-1.6 2.3-6 6.5-10.9 11.6-13.7 3.1 4 8 6.4 13.1 6.4 8.1 0 15-5.8 16.4-13.7 3.3.1 6.5-1.1 9-3.3 2.7-2.4 4.3-5.9 4.3-9.5-.2-3.6-1.8-7.1-4.5-9.5M56 34.7c-2.3 2-5.3 3-8.4 2.7-.6-.1-1 .3-1.1.9-.9 7.3-7.1 12.9-14.5 12.9-4.8 0-9.3-2.4-12-6.3-.3-.4-.8-.5-1.3-.3C12.9 47.4 8 53 5.4 59.7c-.1.3-.3.3-.5.3-.1 0-.4-.1-.4-.5-.2-12.4 4-18.8 6.5-21.5.2-.2.3-.6.2-.9s-.3-.6-.6-.7c-3.8-1.8-6.2-5.6-6.2-9.8 0-3.1 1.3-6 3.6-8 2-1.8 4.5-2.7 7.2-2.7.4 0 .8 0 1.3.1s1-.3 1.1-.7C19.2 8.6 25.2 4 32 4s12.8 4.6 14.4 11.2c.1.5.6.8 1.1.7 3-.4 6.1.6 8.4 2.7 2.3 2 3.6 5 3.6 8 .1 3.1-1.2 6-3.5 8.1" />
    <path d="M40.6 21.7H23.4c-.6 0-1 .4-1 1s.4 1 1 1h17.2c.6 0 1-.4 1-1s-.4-1-1-1M40.6 31H23.4c-.6 0-1 .4-1 1s.4 1 1 1h17.2c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconSpeechBubble10;
