import * as React from "react";
const IconMessageShield = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.9 7.3H7.1C4.3 7.3 2 9.6 2 12.4v31.4c0 2.8 2.3 5.1 5.1 5.1h29c.6 0 1-.4 1-1s-.4-1-1-1h-29c-1.7 0-3.1-1.4-3.1-3.1V12.4c0-.4.1-.8.2-1.1l25 15.2c.9.5 1.8.8 2.8.8s1.9-.3 2.8-.8l25-15.2c.1.3.2.7.2 1.1V24c0 .6.4 1 1 1s1-.4 1-1V12.4c0-2.8-2.3-5.1-5.1-5.1M33.8 24.8c-1.1.7-2.4.7-3.5 0L5.5 9.8c.5-.3 1-.4 1.6-.4h49.8c.6 0 1.1.2 1.6.4z" />
    <path d="m60 35.7-6-2q-.75-.3-1.5 0l-6 2c-1.1.4-1.7 1.4-1.6 2.5L46 51.5c.1.8.5 1.5 1.2 1.9l4.9 3q.6.3 1.2.3c.6 0 .9-.1 1.2-.3l4.9-3c.7-.4 1.1-1.1 1.2-1.9l1.1-13.3c0-1.1-.7-2.1-1.7-2.5m-1.5 15.6c0 .1-.1.3-.2.3l-4.9 3c-.1.1-.3.1-.4 0l-4.9-3c-.1-.1-.2-.2-.2-.3L46.8 38c0-.2.1-.4.3-.4l6-2h.2l6 2c.2.1.3.2.3.4z" />
  </svg>
);
export default IconMessageShield;
