import * as React from "react";
const IconGuitar = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m44.9 45.5-3.1-3.6c-.3-.3-.3-.7 0-1.1 1.5-2.1 2.3-4.6 2.3-7.2 0-6.4-4.9-11.6-11.1-12.1v-4.1h2.9c.6 0 1-.4 1-1s-.4-1-1-1H33V4h2.2c.6 0 1-.4 1-1s-.4-1-1-1h-6.4c-.6 0-1 .4-1 1s.4 1 1 1H31v6.8h-4.1c-.6 0-1 .4-1 1s.4 1 1 1H31v8.8c-6.2.5-11.1 5.7-11.1 12.1 0 2.6.8 5.1 2.3 7.2.2.3.2.8 0 1.1l-3.1 3.6c-1.6 1.8-2.4 4.1-2.4 6.5 0 5.5 4.5 10 10 10h10.7c5.5 0 10-4.5 10-10-.1-2.5-.9-4.8-2.5-6.6M37.4 60H26.6c-4.4 0-8-3.6-8-8 0-1.9.7-3.8 1.9-5.2l3.1-3.6c.9-1 .9-2.5.1-3.6-1.3-1.7-2-3.8-2-6 0-5.6 4.6-10.1 10.1-10.1s10.1 4.6 10.1 10.1c0 2.2-.7 4.2-2 6-.8 1.1-.7 2.5.1 3.6l3.1 3.6c1.3 1.4 1.9 3.3 1.9 5.2.3 4.4-3.2 8-7.6 8" />
    <path d="M32 37.9c2.4 0 4.4-2 4.4-4.4s-2-4.4-4.4-4.4-4.4 2-4.4 4.4 2 4.4 4.4 4.4m0-6.8c1.3 0 2.4 1.1 2.4 2.4s-1.1 2.4-2.4 2.4-2.4-1.1-2.4-2.4 1.1-2.4 2.4-2.4M36 49.7h-8c-.6 0-1 .4-1 1s.4 1 1 1h8c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconGuitar;
