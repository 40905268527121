import * as React from "react";
const IconCrownAlt3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.7 23.4c-.4-.2-.8-.2-1.2.1L47.1 34c-.2.2-.5.3-.7.2-.3 0-.5-.2-.7-.4L32.9 13.6c2.5-.4 4.5-2.6 4.5-5.2-.1-3-2.5-5.4-5.4-5.4s-5.3 2.4-5.3 5.3c0 2.6 1.9 4.8 4.5 5.2L18.3 33.8c-.1.2-.4.4-.6.4-.3 0-.5 0-.7-.2L4.4 23.5c-.3-.3-.8-.3-1.2-.1s-.6.6-.5 1.1l4.9 25.8c0 .2.2.4.3.6V60c0 .6.4 1 1 1H55c.6 0 1-.4 1-1v-9.2q.3-.15.3-.6l4.9-25.8c.1-.4-.1-.8-.5-1m-32-15.1C28.7 6.5 30.2 5 32 5s3.3 1.5 3.3 3.3-1.5 3.3-3.3 3.3-3.3-1.4-3.3-3.3M10 59v-8h44v8zm44.5-10h-45L5.3 26.8l10.3 8.7c.6.5 1.5.8 2.3.7s1.6-.6 2-1.3L32 16l12 18.9c.5.7 1.2 1.2 2 1.3s1.7-.1 2.3-.7l10.3-8.7z" />
    <path d="M32.2 31.5c0-.1-.1-.1-.2-.1s-.1 0-.2.1c-.9 1.4-1.9 3.1-2.8 4.4-.6 1-.6 2.2 0 3.2.9 1.4 1.9 3 2.8 4.4q.15.15.3 0c.9-1.4 1.9-3.1 2.8-4.4.6-1 .6-2.2 0-3.2-.8-1.4-1.8-3-2.7-4.4" />
  </svg>
);
export default IconCrownAlt3;
