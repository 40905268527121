import * as React from "react";
const IconEarbuds1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 65 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.6 22.1v-5.3c0-.8-.3-1.7-.8-2.3-2-2.5-6.3-4.2-11.5-4.6-3.6-.3-7.4 1.1-10.2 3.8-3 2.8-4.6 6.5-4.7 10.6v26.8c0 .8.3 1.6.9 2.1q.9.9 2.1.9h3c1.7 0 3-1.3 3-3V33c1.5.8 2.8 1.2 4.7 1.4 5.7.4 10.8-1.6 13.1-5.2.3-.5.5-1.2.5-1.9v-1.1c-.1-1.3-.1-2.5-.1-4.1m-2 4.1v1.1c0 .2 0 .6-.2.8-1.9 2.9-6.4 4.6-11.2 4.2-2.2-.1-3.4-.8-5.4-2-.3-.2-.7-.2-1 0s-.5.5-.5.9V51c0 .6-.4 1-1 1h-3c-.3 0-.5-.1-.7-.3s-.3-.4-.3-.7V24.2c0-3.5 1.5-6.7 4-9.1 2.3-2.1 5.2-3.3 8-3.3h.7c4.7.3 8.5 1.8 10.1 3.8.2.2.3.7.3 1V22c.2 1.7.2 2.9.2 4.2M25.9 13.7c-2.9-2.7-6.6-4-10.2-3.8-5.3.4-9.5 2-11.5 4.6-.5.6-.8 1.5-.8 2.3v10.6c0 .7.2 1.4.5 1.9 2.3 3.5 7.4 5.5 13.1 5.2 2-.1 3.3-.6 4.8-1.4v18.1c0 1.7 1.3 3 3 3h3c.8 0 1.6-.3 2.1-.9q.9-.9.9-2.1V24.4c-.2-4.2-1.9-7.9-4.9-10.7m2.5 38.1c-.2.2-.4.3-.7.3h-3c-.6 0-1-.4-1-1V31.3c0-.4-.2-.7-.5-.9-.2-.1-.3-.1-.5-.1s-.4 0-.5.1c-2 1.1-3.2 1.8-5.4 2-4.9.3-9.4-1.4-11.2-4.2-.2-.3-.2-.6-.2-.8V16.8c0-.3.2-.8.3-1 1.7-2.1 5.5-3.5 10.1-3.8 3.1-.2 6.3 1 8.8 3.2 2.6 2.4 4 5.6 4 9.1v26.8c0 .2-.1.5-.2.7" />
  </svg>
);
export default IconEarbuds1;
