import * as React from "react";
const IconWindRose = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m59.4 27.6-13.1-7.2 8.6-8.6c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-9 9-8.1-14.8C35.5 3 33.9 2 32 2s-3.5 1-4.4 2.6l-8.1 14.8-9-9c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l8.6 8.6-13.1 7.2C3 28.5 2 30.1 2 32s1 3.5 2.6 4.4l14.8 8.1-9 9c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l8.6-8.6 7.2 13.1C28.5 61 30.1 62 32 62s3.5-1 4.4-2.6l7.2-13.1 8.6 8.6c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-9-9 14.8-8.1C61 35.5 62 33.9 62 32s-1-3.5-2.6-4.4m-1 7.1L43.3 43c-.2.1-.3.2-.4.4l-8.3 15.1c-.5 1-1.5 1.6-2.7 1.6-1.1 0-2.1-.6-2.7-1.6l-8.3-15.1c-.1-.2-.2-.3-.4-.4L5.6 34.7C4.6 34.1 4 33.1 4 32s.6-2.1 1.6-2.7L20.7 21c.2-.1.3-.2.4-.4l8.3-15.1C29.9 4.6 30.9 4 32 4s2.1.6 2.7 1.6L43 20.7c.1.2.2.3.4.4l15.1 8.3c1 .5 1.6 1.5 1.6 2.7-.1 1-.7 2-1.7 2.6" />
  </svg>
);
export default IconWindRose;
