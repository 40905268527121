import * as React from "react";
const IconTravelAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M35.5 10a4 4 0 1 1-8 0 4 4 0 0 1 8 0m-2 0a2 2 0 1 1-4 0 2 2 0 0 1 4 0M11 28a2 2 0 0 1 2-2h3a2 2 0 0 1 2 2v2h3a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h3zM8 40v-8h2v8zm8-12v2h-3v-2zm1 4h-5v8h5zm2 0v8h2v-8z"
      clipRule="evenodd"
    />
    <path  d="M38 22a2 2 0 0 0-2-2v4a2 2 0 0 0 2-2" />
    <path
      
      fillRule="evenodd"
      d="M28 42a3 3 0 0 1-3-3V22h-5a3 3 0 1 1 0-6h17.016C38.129 16 42 17.364 42 21.571c0 3.339-2.437 5.427-4 6.147V39a3 3 0 1 1-6 0v-8h-1v8c0 .701-.24 1.346-.644 1.857A3 3 0 0 1 28 42m8-15.563 1.163-.536c1.144-.527 2.837-2.047 2.837-4.33 0-1.394-.605-2.238-1.308-2.789a4.3 4.3 0 0 0-1.126-.63 2.7 2.7 0 0 0-.543-.15q-.02-.002-.008-.002H20a1 1 0 1 0 0 2h7v19a1 1 0 1 0 2 0V29h5v10a1 1 0 1 0 2 0z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconTravelAlt;
