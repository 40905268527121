import * as React from "react";
const IconLoveNature = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.2 7.1C54.2 3.8 50 2 45.3 2c-7 0-11.1 2.9-13.3 5.7C29.8 4.9 25.7 2 18.7 2 14 2 9.8 3.8 6.8 7.1c-3.1 3.5-4.7 8.4-4.7 14.3 0 6.9 4.8 15.6 14.2 26 6.7 7.4 13.4 13 13.7 13.2l1.4 1.2c.2.2.4.2.6.2s.5-.1.6-.2l1.4-1.2c.3-.2 7-5.8 13.7-13.2 9.4-10.4 14.2-19.1 14.2-26 0-5.9-1.6-10.8-4.7-14.3M46.3 46c-6.6 7.3-13.2 12.8-13.5 13l-.8.7-.8-.7c-.3-.2-6.9-5.7-13.5-13-9-10-13.6-18.3-13.6-24.6 0-5.4 1.5-9.8 4.2-12.9C10.9 5.6 14.5 4 18.7 4c7 0 10.6 3.1 12.4 5.7l.1.2c.2.3.5.4.8.4s.6-.2.8-.5l.2-.1C34.8 7.1 38.4 4 45.3 4c4.1 0 7.8 1.6 10.4 4.4 2.8 3.1 4.2 7.5 4.2 12.9 0 6.4-4.6 14.7-13.6 24.7" />
    <path d="M38.2 34.9c1-1.3 1.6-2.9 1.6-4.6 0-2.6-1.3-5.3-3.6-7.6-6-6-13.5-2.4-13.6-2.4l-.3.1-.2.3c0 .1-3.6 7.5 2.4 13.5 2.4 2.4 5.1 3.6 7.7 3.6 1.7 0 3.2-.5 4.6-1.6l4.5 4.5c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4zm-12.3-2c-4.4-4.4-2.7-9.6-2.2-10.9 1.3-.5 6.6-2.2 11 2.2 1.9 1.9 3 4.1 3 6.2 0 1.2-.3 2.2-1 3.2l-2.6-2.5c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l2.5 2.5c-2.5 1.7-6.2 1-9.3-2.1" />
  </svg>
);
export default IconLoveNature;
