import * as React from "react";
const IconCloudSync = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M39.7 46c-.9-.6-2.2-.4-2.8.6-1.1 1.7-3 2.7-5.1 2.7-1 0-2-.3-2.9-.7h1.9c1.1 0 2-.9 2-2s-.9-2-2-2h-6.3c-1.3 0-2.5 1.1-2.5 2.5V52c0 1.1.9 2 2 2s2-.9 2-2v-.6c1.7 1.1 3.6 1.8 5.7 1.8 3.4 0 6.5-1.7 8.4-4.5.8-.8.5-2.1-.4-2.7M42.7 31.5c0-1.1-.9-2-2-2s-2 .9-2 2v1c-2-1.5-4.4-2.7-6.6-2.7-3.8 0-7.2 2.1-9 5.5-.5 1-.1 2.2.8 2.7 1 .5 2.2.1 2.7-.8 1.1-2.1 3.2-3.4 5.5-3.4 1.4 0 3.5 1.2 4.9 2.5h-1.8c-1.1 0-2 .9-2 2s.9 2 2 2h5c1.3 0 2.5-1.1 2.5-2.5z" />
    <path d="M58 23.4c-2.7-3-6.6-5.1-10.7-5.8-2.2-3.6-5.5-6.3-9.4-7.6-1.8-.7-3.7-1-5.9-1-9.7 0-17.7 7.7-18.1 17.3C6.7 27 1 33.2 1 40.6 1 48.5 7.5 55 15.4 55c1.1 0 2-.9 2-2s-.9-2-2-2C9.6 51 5 46.4 5 40.6s4.6-10.4 10.4-10.4h.5c1.1 0 2-.9 2-2v-1.1C17.9 19.3 24.2 13 32 13c1.7 0 3.2.3 4.5.8 3.3 1.1 6 3.5 7.8 6.7.3.6.9 1 1.5 1 3.5.4 6.8 2.1 9.2 4.6 2.6 2.8 4 6.4 4 10.1C59 44.4 52.3 51 44.1 51c-1.1 0-2 .9-2 2s.9 2 2 2C54.5 55 63 46.6 63 36.2c0-4.8-1.8-9.3-5-12.8" />
  </svg>
);
export default IconCloudSync;
