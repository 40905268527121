import * as React from "react";
const IconHouse = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.7 28c-.6 0-1 .4-1 1v27.3c0 1.8-1.4 3.2-3.2 3.2H12.6c-1.8 0-3.2-1.4-3.2-3.2V29c0-.6-.4-1-1-1s-1 .4-1 1v27.3c0 2.9 2.3 5.2 5.2 5.2h38.9c2.9 0 5.2-2.3 5.2-5.2V29c0-.5-.5-1-1-1M61.5 20.5 34.8 3.3c-1.7-1.1-3.9-1.1-5.7 0L2.5 20.5c-.5.3-.6.9-.3 1.4s.9.6 1.4.3L30.3 4.9c1.1-.7 2.4-.7 3.5 0l26.7 17.2c.2.1.4.2.5.2.3 0 .7-.2.8-.5.3-.4.2-1-.3-1.3" />
  </svg>
);
export default IconHouse;
