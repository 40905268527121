import * as React from "react";
const IconNg = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 85.5 513 342"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 85.5h513v342H0z" />
    <path  d="M0 85.5h171v342H0zm342 0h171v342H342z" />
  </svg>
);
export default IconNg;
