import * as React from "react";
const IconTrowelAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M41.2 2H22.8c-2.8 0-5 2.2-5 5v15.6c0 5.3 4.1 9.7 9.2 10.3-.4.4-.9.9-1.2 1.5-1.4 2.3-1.5 5.3-.2 7.7.8 1.5.9 3.3.3 4.9l-1.8 4.6c-1 2.4-.7 5.2.8 7.3 1.3 1.9 3.3 3 5.5 3h3.3c2.2 0 4.2-1.1 5.5-3 1.5-2.1 1.7-4.9.8-7.3L38.2 47c-.6-1.6-.5-3.4.3-4.9 1.3-2.4 1.2-5.4-.2-7.7q-.45-.9-1.2-1.5c5.2-.6 9.2-5 9.2-10.3V7c-.1-2.8-2.4-5-5.1-5m-4.6 39.2c-1.1 2-1.2 4.5-.3 6.6l1.8 4.6c.7 1.8.5 3.9-.6 5.5-.9 1.3-2.3 2.1-3.8 2.1h-3.3c-1.5 0-2.9-.8-3.8-2.1-1.1-1.6-1.3-3.6-.6-5.5l1.8-4.6c.8-2.1.7-4.6-.4-6.6-1-1.8-.9-4 .2-5.8.9-1.5 2.4-2.4 4-2.4h.8c1.6 0 3.1.9 4 2.4 1.1 1.8 1.1 4 .2 5.8m7.6-18.6c0 4.6-3.7 8.4-8.4 8.4h-7.6c-4.6 0-8.4-3.7-8.4-8.4V7c0-1.7 1.3-3 3-3h18.3c1.7 0 3 1.3 3 3v15.6z" />
  </svg>
);
export default IconTrowelAlt;
