import React, { useState, useEffect, useRef } from 'react';
import { useNavigation } from 'context/NavigationContext';
import { BringzzPageContainer, BringzzIcon, BringzzText } from "@bringzz/components";
import classNames from 'classnames';

const LocationCard = ({ className, onClick }) => {
    return (
        <div onClick={onClick} className={classNames('flex border rounded-lg px-4 py-4 bg-white shadow-lg', className)}>
            <div>
                <BringzzIcon
                    icon="IconMountain"
                    library="Custom"
                    className="flex items-center justify-center h-9 w-9 bg-gray-200 rounded-full "
                    size="22"
                />
            </div>
            <div className='flex flex-col flex-grow mx-4'>
                <BringzzText tag='h2'>Apotheke Mustermann</BringzzText>
                <div className='flex space-x-2 items-center'>
                    <BringzzText tag='h4' className='font-normal'>5.0 km away</BringzzText>
                    <div>&bull;</div>
                    <BringzzText tag='h4' className='font-normal'>Vienna</BringzzText>
                </div>
                <div className='flex space-x-2 items-center'>
                    <BringzzText tag='h4' className='font-normal text-green-600'>Open now</BringzzText>
                    <div>&bull;</div>
                    <BringzzText tag='h4' className='font-normal'>Closes at 17:00</BringzzText>
                </div>
            </div>
            <div><BringzzIcon
                icon="IconInformationAlt"
                folder="LineIcons"
                className="cursor-pointer"
                size="32"
                onClick={() => onClick}
            /></div>
        </div>)
}

export default LocationCard;
