import * as React from "react";
const IconSunRain = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M49.9 27.1c-.3 0-.5-.2-.6-.4-1.7-3.8-5.5-6.2-9.7-6.2-4.3 0-8.2 2.5-9.9 6.5q-.15.3-.6.3c-2.2-.5-4.5-.2-6.6.7-3.2 1.4-5.5 4.4-6.1 7.9-.5 3 .4 6.1 2.4 8.4 2 2.4 5 3.7 8.2 3.7h21.3c5.7 0 10.5-4.1 11-9.4.6-5.7-3.6-10.8-9.4-11.5m7.5 11.3c-.4 4.3-4.4 7.6-9 7.6H27c-2.6 0-5-1.1-6.7-3-1.6-1.9-2.3-4.3-1.9-6.8.4-2.8 2.3-5.2 4.9-6.3 1.2-.5 2.4-.8 3.7-.8.6 0 1.2.1 1.7.2 1.2.2 2.4-.4 2.8-1.5 1.3-3.2 4.5-5.2 8-5.2 3.4 0 6.5 2 7.9 5 .4.9 1.2 1.4 2.2 1.5 4.8.6 8.2 4.6 7.8 9.3M17.2 23.2c.5-.2.8-.7.6-1.3s-.3-1.3-.3-2c0-3.5 3-6.4 6.6-6.4 2.2 0 4.2 1 5.4 2.8.3.5.9.6 1.4.2s.6-.9.2-1.4c-1.6-2.3-4.2-3.6-7.1-3.6-4.7 0-8.6 3.8-8.6 8.4 0 .9.1 1.8.4 2.6.1.4.5.7 1 .7.2.1.3.1.4 0M25 5.5c.6 0 1-.4 1-1V3c0-.6-.4-1-1-1s-1 .4-1 1v1.5c0 .6.5 1 1 1M37.4 10.9c.3 0 .5-.1.7-.3l1.6-1.5c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-1.6 1.5c-.4.4-.4 1 0 1.4.2.2.5.3.7.3M7.2 21H5.6c-.6 0-1 .4-1 1s.4 1 1 1h1.6c.6 0 1-.4 1-1s-.5-1-1-1M11.8 7.6c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l1.6 1.5c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4zM26 55.5c-.5-.2-1.1.1-1.3.6l-1.5 4.5c-.2.5.1 1.1.6 1.3h.3c.4 0 .8-.3 1-.7l1.5-4.5c.2-.4 0-1-.6-1.2M37.2 55.5c-.5-.2-1.1.1-1.3.6l-1.5 4.5c-.2.5.1 1.1.6 1.3h.3c.4 0 .8-.3 1-.7l1.5-4.5c.3-.4 0-1-.6-1.2M48.4 55.5c-.5-.2-1.1.1-1.3.6l-1.5 4.5c-.2.5.1 1.1.6 1.3h.3c.4 0 .8-.3 1-.7l1.5-4.5c.3-.4 0-1-.6-1.2" />
  </svg>
);
export default IconSunRain;
