import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import BringzzIcon from "../BringzzIcon";
import BringzzText from "../BringzzText";
import { twMerge } from "tailwind-merge";

// Generate a unique id if none is specified
const generateUniqueId = () => {
  return `bringzz-input-${new Date().getTime()}-${Math.floor(
    Math.random() * 10000
  )}`;
};

/**
 * Custom input component with optional label, hint, and validation.
 * @param {object} props - The component props.
 * @returns {JSX.Element} - JSX for the custom input component.
 */
const BringzzInput = ({
  id,
  label,
  type = "text",
  hint,
  value,
  tooltip,
  onChange,
  required = false,
  pattern,
  placeholder,
  defaultValue,
  defaultRows = 1,
  className = "",
  readOnly,
  onClick,
  icon = null,
  inputProps,
  containerClassname,
  onEnterPress = null,
}) => {
  const [inputValue, setInputValue] = useState(defaultValue || "");
  const [touched, setTouched] = useState(false);

  // Generate an ID if not provided
  const inputId = id || generateUniqueId();

  const handleChange = (e) => {
    const newValue = e.target.value;

    if (!touched) setTouched(true);

    const newIsValid = pattern ? new RegExp(pattern).test(newValue) : true;

    if (onChange) {
      onChange(e, newIsValid, newValue);
    }

    if (value === undefined) {
      setInputValue(newValue);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && onEnterPress) {
      onEnterPress(inputValue);
    }
  };

  const inputClass = `p-3 outline-none text-midnight-blue ${touched && pattern && !new RegExp(pattern).test(inputValue)
    ? "!bg-[#E5B7CE] text-vivid-coral placeholder:text-vivid-coral"
    : ""
    }`;

  const renderInputField = () => {
    if (type === "textarea") {
      return (
        <textarea
          id={inputId}
          value={inputValue}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          required={required}
          className={twMerge(
            "rounded-md bg-[#F4F4F4] focus:bg-[#FEFEFE] focus:border focus:border-magic-lilac text-sm w-full",
            inputClass,
            className,

          )}
          placeholder={placeholder}
          readOnly={readOnly}
          onClick={onClick}
          rows={defaultRows}
          {...inputProps}
        />
      );
    } else {
      return (
        <input
          id={inputId}
          type={type}
          value={inputValue}
          readOnly={readOnly}
          onClick={onClick}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          required={required}
          className={twMerge(
            "rounded-md bg-[#F4F4F4] focus:bg-[#FEFEFE] focus:border focus:border-magic-lilac text-sm w-full h-10",
            inputClass,
            className,
            icon && icon.left ? "pl-12" : "",
            icon && icon.right ? "pr-12" : ""
          )}
          {...inputProps}
          placeholder={placeholder}
        />
      );
    }
  };

  useEffect(() => {
    if (defaultValue) {
      setInputValue(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (value !== undefined) {
      setInputValue(value);
    }
  }, [value]);

  return (
    <div className={twMerge("flex flex-col space-y-1", containerClassname)}>
      {label && (
        <div className="flex items-center">
          <label htmlFor={inputId}>
            <BringzzText tag="h4" className="font-bold">
              {label}
            </BringzzText>
          </label>
          {tooltip && (
            <div className="ml-1 relative group">
              <BringzzIcon
                icon="IconQuestionCircle"
                folder="LineIcons"
                className="h-4 w-4 cursor-pointer text-magic-lilac"
                size="16"
              ></BringzzIcon>
              <div className="absolute top-0 left-1/2 z-10 transform -translate-x-1/2 -translate-y-full text-xs bg-gray-600 text-white rounded p-1 opacity-0 group-hover:opacity-100 whitespace-nowrap">
                {tooltip}
              </div>
            </div>
          )}
        </div>
      )}
      <div className="relative">
        {icon && icon.left && (
          <div className="absolute inset-y-0 left-0 pl-4 flex items-center">
            {icon.left}
          </div>
        )}
        {renderInputField()}
        {icon && icon.right && (
          <div className="absolute inset-y-0 right-0 pr-4 flex items-center">
            {icon.right}
          </div>
        )}
      </div>
      {hint && (
        <div
          className={twMerge(
            "text-xs text-neutral-500",
            touched && pattern && !new RegExp(pattern).test(inputValue)
              ? "text-vivid-coral"
              : inputValue.length !== 0
                ? "text-success"
                : ""
          )}
        >
          {hint}
        </div>
      )}
    </div>
  );
};

BringzzInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.oneOf(["text", "textarea", "number", "password", "email"]),
  hint: PropTypes.string,
  tooltip: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  pattern: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  className: PropTypes.string,
  defaultRows: PropTypes.number,
  icon: PropTypes.shape({
    left: PropTypes.node,
    right: PropTypes.node,
  }),
  inputProps: PropTypes.object,
  onEnterPress: PropTypes.func,
};

export default BringzzInput;
