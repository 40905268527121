import * as React from "react";
const IconAgendaCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M47 2H17c-2.2 0-4 1.8-4 4v45.5c0 2.1 1.6 3.7 3.6 4V60c0 .9 1.1 1.4 1.8.8l.1-.1c.4-.3 1-.3 1.4 0l.1.1c.7.6 1.8.1 1.8-.8v-4.4H47c2.2 0 4-1.8 4-4V6c0-2.2-1.8-4-4-4m2 4v29.5h-9.4v-16H49v-2h-9.4V4H47c1.1 0 2 .9 2 2M37.7 35.5H15v-16h22.7zM17 4h20.7v13.4H15V6c0-1.1.9-2 2-2m-2 47.5v-14h22.7v16H17c-1.1 0-2-.9-2-2m32 2h-7.4v-16H49v14c0 1.1-.9 2-2 2" />
  </svg>
);
export default IconAgendaCopy;
