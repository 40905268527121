import * as React from "react";
const IconNose = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M48.1 36.1 43.9 12c-1-5.8-6-10-11.9-10S21.1 6.2 20.1 12l-4.2 24.1c-4.7 2.5-7.2 7.6-6.3 12.9.8 4.6 4.3 8.4 8.8 9.6 1.9.5 4 .6 5.9.1h.1c1.9 2 4.6 3.3 7.6 3.3s5.7-1.3 7.6-3.3h.1c.9.2 1.8.3 2.8.3 1.1 0 2.1-.1 3.2-.4 4.6-1.2 8-5 8.8-9.6.8-5.3-1.7-10.4-6.4-12.9m4.3 12.6c-.7 3.9-3.5 7-7.3 8-1.4.4-2.8.4-4.2.2.7-1.2 1.2-2.6 1.3-4.2.1-.5-.3-1-.9-1.1-.6 0-1 .3-1.1.9-.4 4.2-4 7.4-8.2 7.4-4.3 0-7.8-3.2-8.2-7.4-.1-.5-.5-.9-1.1-.9-.5.1-1 .5-.9 1.1.1 1.5.6 2.9 1.3 4.2-1.4.2-2.8.2-4.2-.2-3.8-1-6.7-4.2-7.4-8-.8-4.6 1.5-9 5.6-11l.5-.2L22 12.3C22.9 7.5 27.1 4 32 4s9.1 3.5 9.9 8.3l4.4 25.2.5.2c4.1 2 6.4 6.4 5.6 11" />
  </svg>
);
export default IconNose;
