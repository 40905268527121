import * as React from "react";
const IconArrowsExchange = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60 21.9H19.7c-.6 0-1-.4-1-1s.4-1 1-1h38.1l-6.3-7.5c-.4-.4-.3-1.1.1-1.4.4-.4 1.1-.3 1.4.1l7.7 9.1c.3.3.3.7.1 1.1s-.4.6-.8.6M11.7 53.3c-.3 0-.6-.1-.8-.4l-7.7-9.1c-.3-.3-.3-.7-.1-1.1q.3-.6.9-.6h40.3c.6 0 1 .4 1 1s-.4 1-1 1H6.1l6.3 7.5c.4.4.3 1.1-.1 1.4q-.3.3-.6.3" />
  </svg>
);
export default IconArrowsExchange;
