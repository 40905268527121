import * as React from "react";
const IconWeddingDress = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M41.2 23.2c1.2-1.7 1.9-3.1 1.9-3.1 1.4-2.7 2.7-8.7-1.7-11.8-.7-.5-1.4-.8-2.1-1V3c0-.6-.4-1-1-1s-1 .4-1 1v4.2c-.2 0-.3.1-.5.1-2.1.5-3.7 2.2-4.7 3.5-.9-1.2-2.5-3-4.6-3.5-.1 0-.2 0-.4-.1V3c0-.6-.4-1-1-1s-1 .4-1 1v4.3c-.7.2-1.5.5-2.2 1-4.5 3.1-3.1 9.1-1.7 11.8 0 .1.7 1.3 1.9 3-1.4 2.6-6.5 13.2-7.1 33.9 0 1.3.4 2.6 1.4 3.5.9 1 2.2 1.5 3.5 1.5h22.4c1.3 0 2.6-.5 3.5-1.5s1.4-2.2 1.4-3.5c-.7-20.7-5.6-31.3-7-33.8m4 35.9c-.5.6-1.2.9-2 .9h-1.3l-1-6.8c-.1-.5-.6-.9-1.1-.8s-.9.6-.8 1.1l1 6.6h-7v-6.7c0-.6-.4-1-1-1s-1 .4-1 1V60h-6.7l.6-6.6c.1-.5-.4-1-.9-1.1-.6-.1-1 .4-1.1.9l-.8 6.8h-1.3q-1.2 0-2.1-.9c-.5-.6-.8-1.3-.8-2.1.7-22.9 7.1-33.4 7.2-33.5.2-.4.2-.8-.1-1.1-1.3-1.7-2.1-3.2-2.1-3.2 0-.1-3.1-6.4 1.1-9.3.7-.5 1.5-.8 2.2-.8.3 0 .5 0 .8.1 2.5.6 4.2 3.8 4.2 3.8.2.3.5.5.9.5s.7-.2.9-.5c0 0 1.8-3.3 4.2-3.8 1-.2 2 0 2.9.7 4.2 2.9 1.2 9 1.1 9.3 0 0-.7 1.5-2.1 3.3-.2.3-.3.8-.1 1.1.1.1 6.2 10.5 6.9 33.4.2.8-.1 1.6-.7 2.1" />
  </svg>
);
export default IconWeddingDress;
