import * as React from "react";
const IconSeoConsulting = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M25.9 22.4c0 3.5 2.8 6.3 6.3 6.3s6.3-2.8 6.3-6.3-2.8-6.3-6.3-6.3c-3.4 0-6.3 2.9-6.3 6.3m10.6 0c0 2.4-1.9 4.3-4.3 4.3s-4.3-1.9-4.3-4.3 1.9-4.3 4.3-4.3 4.3 2 4.3 4.3M61.8 41.8l-4.7-4.7c-.3-.3-.7-.4-1.1-.2l-5.2 2.3c-.5.2-.7.8-.5 1.3s.8.7 1.3.5l3.6-1.6-.1.1c-2 6.1-6.3 10.9-11.7 13.8V42.1c0-4.6-3.4-8.4-7.6-8.4H28c-4.2 0-7.6 3.8-7.6 8.5v11c-5.2-2.9-9.2-7.6-11.2-13.5-.2-.5-.7-.8-1.3-.6-.5.2-.8.7-.6 1.3 3.5 10.7 13.5 18 24.8 18s21.3-7.2 24.9-18v-.2l3.2 3.2c.2.2.5.3.7.3s.5-.1.7-.3c.6-.6.6-1.2.2-1.6M22.5 54.1v-12c0-3.6 2.5-6.5 5.6-6.5h3.5l-2 5.5c-.2.6-.2 1.2 0 1.7l1.4 4.6c.3.9 1.5.9 1.8 0l1.4-4.6c.2-.6.2-1.2 0-1.7l-2-5.5h3.6c3.1 0 5.6 2.9 5.6 6.4v12.2c-2.9 1.2-6 1.9-9.3 1.9-3.3.1-6.6-.7-9.6-2" />
    <path d="m8.1 28.6 5.6-3c.5-.3.7-.9.4-1.4s-.9-.7-1.4-.4l-3.8 2.1c2.8-10.6 12.3-18 23.3-18 11.1 0 20.8 7.6 23.5 18.4.1.5.7.9 1.2.7.5-.1.9-.7.7-1.2-2.9-11.8-13.3-20-25.4-20-12 0-22.4 8.1-25.3 19.8l-3.3-3.3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l4.7 4.7c.2.2.4.3.7.3.2 0 .3 0 .5-.1" />
  </svg>
);
export default IconSeoConsulting;
