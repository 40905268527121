import * as React from "react";
const IconBox5 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M4.8 20.3h53.3c.1.4.2.7.2 1.1v11.3c0 .6.4 1 1 1s1-.4 1-1V21.4c0-1.6-.6-3.1-1.7-4.3L47 4.1C45.8 2.7 44 2 42.2 2H20.7c-1.8 0-3.6.8-4.8 2.1l-11.6 13c-1.1 1.2-1.6 2.7-1.6 4.3v34.2c0 3.5 2.9 6.4 6.4 6.4h16.3c.6 0 1-.4 1-1s-.4-1-1-1H9.1c-2.4 0-4.4-2-4.4-4.4V21.4c0-.4 0-.8.1-1.1M45.5 5.5 57 18.3H32.4V4h9.8c1.3 0 2.5.5 3.3 1.5m-28.2 0c.8-.9 2-1.5 3.3-1.5h9.8v14.3H5.9z" />
    <path d="M40.2 52h1.3c2.5 0 4.6-2.1 4.6-4.6s-2.1-4.6-4.6-4.6h-4.9c-.6 0-1 .4-1 1s.4 1 1 1h4.9c1.4 0 2.6 1.2 2.6 2.6S42.9 50 41.5 50h-1.3c-2.5 0-4.6 2.1-4.6 4.6s2.1 4.6 4.6 4.6h4.9c.6 0 1-.4 1-1s-.4-1-1-1h-4.9c-1.4 0-2.6-1.2-2.6-2.6s1.1-2.6 2.6-2.6M60.3 43.5c-.6 0-1 .4-1 1v4.3H53v-4.3c0-.6-.4-1-1-1s-1 .4-1 1v5.3c0 .6.4 1 1 1h7.3v7.4c0 .6.4 1 1 1s1-.4 1-1V44.5c0-.6-.4-1-1-1" />
  </svg>
);
export default IconBox5;
