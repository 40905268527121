import * as React from "react";
const IconMagnifier = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.4 50.3 48.7 38.7c-1.2-1.2-2.8-1.9-4.5-1.9q-1.8 0-3.3.9l-5.2-5.2c2.6-3.3 4.1-7.4 4.1-11.8 0-5.1-2-9.9-5.6-13.5s-8.4-5.6-13.5-5.6-9.9 2-13.5 5.6-5.6 8.4-5.6 13.5 2 9.9 5.6 13.5 8.4 5.6 13.5 5.6c4.3 0 8.4-1.4 11.8-4.1l5.2 5.2q-.9 1.5-.9 3.3c0 1.7.7 3.3 1.9 4.5l11.6 11.6c1.2 1.2 2.8 1.9 4.5 1.9s3.3-.7 4.5-1.9l1.1-1.1c1.2-1.2 1.9-2.8 1.9-4.5 0-1.6-.7-3.2-1.9-4.4M10.5 31.1c-2.7-2.7-4.3-6.4-4.3-10.3s1.5-7.5 4.3-10.3 6.4-4.3 10.3-4.3 7.5 1.5 10.3 4.3 4.3 6.4 4.3 10.3-1.5 7.5-4.3 10.3-6.4 4.3-10.3 4.3-7.5-1.6-10.3-4.3m46.7 25-1.1 1.1c-.7.7-1.9.7-2.7 0L41.8 45.6c-.7-.7-.7-1.9 0-2.7l1.1-1.1c.4-.4.8-.5 1.3-.5s1 .2 1.3.5l11.6 11.6c.4.4.5.8.5 1.3.2.6 0 1.1-.4 1.4" />
    <path d="M24.7 12.7c-.9.9-.9 2.3 0 3.2 2.4 2.4 2.4 6.4 0 8.8-.9.9-.9 2.3 0 3.2.4.4 1 .7 1.6.7s1.2-.2 1.6-.7c2-2 3.1-4.7 3.1-7.6s-1.1-5.5-3.1-7.6c-.9-.9-2.4-.9-3.2 0" />
  </svg>
);
export default IconMagnifier;
