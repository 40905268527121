import * as React from "react";
const IconBubbleCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M5.9 54.8c-.4 0-.9-.1-1.3-.3-1-.4-1.6-1.5-1.6-2.6V14.2c0-2.8 2.2-5 5-5h48c2.8 0 5 2.2 5 5v27.6c0 2.8-2.2 5-5 5H16.6l-8.8 7.3c-.5.5-1.2.7-1.9.7M8 11.2c-1.7 0-3 1.3-3 3v37.7c0 .5.4.8.5.8.1.1.6.2 1-.1l9.1-7.6c.2-.1.4-.2.6-.2H56c1.7 0 3-1.3 3-3V14.2c0-1.7-1.3-3-3-3z" />
  </svg>
);
export default IconBubbleCopy;
