import React, { useState } from 'react';
import { useNavigation } from 'context/NavigationContext';
import {
    BringzzPageContainer, BringzzIcon, BringzzBoxSlider, BringzzText, BringzzImage
} from "@bringzz/components";
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import ProductExample from 'assets/images/ProductExample.png';

const SearchPage = () => {
    const { backPress } = useNavigation();
    const { navigate } = useNavigation();
    const { '*': searchQuery } = useParams();

    const types = [
        "All",
        "Products",
        "Posts",
        "Categories",
        "Pages",
        "Orders"
    ]

    const initialProducts = [
        {
            img: ProductExample,
            companyName: "Opella Health Care",
            name: "Thomapyrin",
            marked: false
        },
        {
            img: ProductExample,
            companyName: "Opella Health Care",
            name: "Thomapyrin",
            marked: false
        },
        {
            img: ProductExample,
            companyName: "Opella Health Care",
            name: "Thomapyrin",
            marked: false
        },
        {
            img: ProductExample,
            companyName: "Opella Health Care",
            name: "Thomapyrin",
            marked: false
        },
    ];
    const [products, setProducts] = useState(initialProducts);

    const initialPages = [
        {
            img: ProductExample,
            companyName: "Opella Health Care",
            name: "Thomapyrin",
            marked: false
        },
        {
            img: ProductExample,
            companyName: "Opella Health Care",
            name: "Thomapyrin",
            marked: false
        },
        {
            img: ProductExample,
            companyName: "Opella Health Care",
            name: "Thomapyrin",
            marked: false
        },
        {
            img: ProductExample,
            companyName: "Opella Health Care",
            name: "Thomapyrin",
            marked: false
        },
    ];
    const [pages, setPages] = useState(initialPages);

    const initialOrders = [
        {
            img: ProductExample,
            date: "19/02/2024",
            invoice: "Order #123456",
            marked: false
        },
        {
            img: ProductExample,
            date: "19/02/2024",
            invoice: "Order #12345",
            marked: false
        },
        {
            img: ProductExample,
            date: "19/02/2024",
            invoice: "Order #1234",
            marked: false
        },
        {
            img: ProductExample,
            date: "19/02/2024",
            invoice: "Order #123",
            marked: false
        },
    ];
    const [orders, setOrders] = useState(initialOrders);

    const initialPosts = [
        {
            img: ProductExample,
            pharmacyName: "Apotheke Mustermann",
            title: "Post Title",
            marked: false
        },
        {
            img: ProductExample,
            pharmacyName: "Bayer Pharmaceutics",
            title: "Post Title",
            marked: false
        },
        {
            img: ProductExample,
            pharmacyName: "Med Pharma Service GmbH",
            title: "Post Title",
            marked: false
        },
        {
            img: ProductExample,
            pharmacyName: "Bort GmbH",
            title: "Post Title",
            marked: false
        },
    ];
    const [posts, setPosts] = useState(initialPosts);

    const categories = [
        {
            name: "Rx-medicine",
            shortName: "Rx-medicine",
            img: "/images/5.jpg",
            icon: <BringzzIcon
                size="25"
                icon="IconPrescriptionDocument"
                folder="HealthIcons"
            />
        },
        {
            name: "OTC-medicine",
            shortName: "OTC-medicine",
            img: "/images/7.jpg",
            icon: <BringzzIcon
                size="25"
                icon="IconTelemedicine"
                folder="HealthIcons"
            />
        },
        {
            name: "Medical devices & technology",
            shortName: "Medical devices",
            img: "/images/8.jpg",
            icon: <BringzzIcon
                size="25"
                icon="IconThermometer"
                folder="HealthIcons"
            />
        },
        {
            name: "Food supplements & vitamins",
            shortName: "Vitamins",
            img: "/images/4.jpg",
            icon: <BringzzIcon
                size="25"
                icon="IconBlisterPillsOvalX16"
                folder="HealthIcons"
            />
        }
    ]

    const toggleBookmark = (index, type) => {
        if (type == "products") {
            setProducts(prevProducts => prevProducts.map((product, i) =>
                i === index ? { ...product, marked: !product.marked } : product
            ));
        } else if (type == "posts") {
            setPosts(prevPosts => prevPosts.map((post, i) =>
                i === index ? { ...post, marked: !post.marked } : post
            ));
        } else if (type == "pages") {
            setPages(prevPages => prevPages.map((page, i) =>
                i === index ? { ...page, marked: !page.marked } : page
            ));
        } else if (type == "orders") {
            setOrders(prevOrders => prevOrders.map((order, i) =>
                i === index ? { ...order, marked: !order.marked } : order
            ));
        }

    };

    return (
        <BringzzPageContainer
            className="bg-white pb-32"
            authenticated={false}
            footerHover={true}
            header={{
                left: <BringzzIcon
                    size="22"
                    icon="IconChevronLeft"
                    folder="LineIcons"
                    className="cursor-pointer"
                    onClick={backPress}
                />,
                title: searchQuery
            }}
        >
            <div className='pt-2'>
                <div className='pl-6'>
                    <BringzzBoxSlider className='pr-4 space-x-2'>
                        {types.map((type, index) => (
                            <div key={index} className={classNames('bg-sand rounded-lg py-2 px-4 cursor-pointer', index === 0 && "!bg-magic-lilac")}>
                                <BringzzText tag='h4'>{type}</BringzzText>
                            </div>
                        ))}
                    </BringzzBoxSlider>
                </div>
                <div className='px-6 mt-1'>
                    <div className='flex justify-between items-center'>
                        <BringzzText tag='h2'>Products</BringzzText>
                        <BringzzText tag='h4' className='text-magic-lilac cursor-pointer'>Show All</BringzzText>
                    </div>
                    <div className='mt-1'>
                        {products.map((product, index) => (
                            <div key={index} className='flex items-center border-b py-2'>
                                <BringzzImage src={product.img} className='h-14 w-14 mr-2' />
                                <div className='flex flex-col flex-grow'>
                                    <BringzzText tag='h3'>{product.companyName}</BringzzText>
                                    <BringzzText tag='h3' className='font-normal'>{product.name}</BringzzText>
                                </div>
                                <BringzzIcon
                                    size="22"
                                    icon="IconBookmark"
                                    folder="LineIcons"
                                    className={classNames("cursor-pointer", product.marked && "text-magic-lilac")}
                                    onClick={() => toggleBookmark(index, "products")}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <div className='px-6 mt-4'>
                    <div className='flex justify-between items-center'>
                        <BringzzText tag='h2'>Posts</BringzzText>
                        <BringzzText tag='h4' className='text-magic-lilac cursor-pointer'>Show All</BringzzText>
                    </div>
                    <div className='mt-1'>
                        {posts.map((post, index) => (
                            <div key={index} className='flex items-center border-b py-2'>
                                <BringzzImage src={post.img} className='h-14 w-14 mr-2' />
                                <div className='flex flex-col flex-grow'>
                                    <BringzzText tag='h3'>{post.pharmacyName}</BringzzText>
                                    <BringzzText tag='h3' className='font-normal'>{post.title}</BringzzText>
                                </div>
                                <BringzzIcon
                                    size="22"
                                    icon="IconBookmark"
                                    folder="LineIcons"
                                    className={classNames("cursor-pointer", post.marked && "text-magic-lilac")}
                                    onClick={() => toggleBookmark(index, "posts")}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <div className='px-6 mt-4'>
                    <div className='flex justify-between items-center'>
                        <BringzzText tag='h2'>Categories</BringzzText>
                        <BringzzText tag='h4' className='text-magic-lilac cursor-pointer'>Show All</BringzzText>
                    </div>
                    <div className='mt-1'>
                        {categories.map((category, index) => {
                            return <div key={index} className='flex items-center h-12 border-b justify-between cursor-pointer'>
                                <div className='flex space-x-2 items-center'>
                                    {category.icon}
                                    <BringzzText tag='h3'>{category.shortName}</BringzzText>
                                </div>
                                <BringzzIcon
                                    size="16"
                                    icon="IconChevronRight"
                                    folder="LineIcons"
                                />
                            </div>
                        })}
                    </div>
                </div>
                <div className='px-6 mt-4'>
                    <div className='flex justify-between items-center'>
                        <BringzzText tag='h2'>Pages</BringzzText>
                        <BringzzText tag='h4' className='text-magic-lilac cursor-pointer'>Show All</BringzzText>
                    </div>
                    <div className='mt-1'>
                        {pages.map((page, index) => (
                            <div key={index} className='flex items-center border-b py-2'>
                                <BringzzImage src={page.img} className='h-14 w-14 mr-2' />
                                <div className='flex flex-col flex-grow'>
                                    <BringzzText tag='h3'>{page.companyName}</BringzzText>
                                    <BringzzText tag='h3' className='font-normal'>{page.name}</BringzzText>
                                </div>
                                <BringzzIcon
                                    size="22"
                                    icon="IconBookmark"
                                    folder="LineIcons"
                                    className={classNames("cursor-pointer", page.marked && "text-magic-lilac")}
                                    onClick={() => toggleBookmark(index, "pages")}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <div className='px-6 mt-4'>
                    <div className='flex justify-between items-center'>
                        <BringzzText tag='h2'>Orders</BringzzText>
                        <BringzzText tag='h4' className='text-magic-lilac cursor-pointer'>Show All</BringzzText>
                    </div>
                    <div className='mt-1'>
                        {orders.map((order, index) => {
                            return (<div key={index} className='flex items-center border-b py-2'>
                                <BringzzImage src={order.img} className='h-14 w-14 mr-2' />
                                <div className='flex flex-col flex-grow'>
                                    <BringzzText tag='h3'>{order.date}</BringzzText>
                                    <BringzzText tag='h3' className='font-normal'>{order.invoice}</BringzzText>
                                </div>
                                <BringzzIcon
                                    size="22"
                                    icon="IconBookmark"
                                    folder="LineIcons"
                                    className={classNames("cursor-pointer", order.marked && "text-magic-lilac")}
                                    onClick={() => toggleBookmark(index, "orders")}
                                />
                            </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </BringzzPageContainer>
    );
};

export default SearchPage;
