import * as React from "react";
const IconAlarmClockAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.6 7.7c.2.1.4.2.6.2.3 0 .6-.1.8-.4.3-.4.3-1.1-.2-1.4l-5-3.9c-.4-.3-1.1-.3-1.4.2-.3.4-.3 1.1.2 1.4zM9.8 7.9c.2 0 .4-.1.6-.2l5-3.9c.4-.3.5-1 .2-1.4s-1-.5-1.4-.2l-5 3.9c-.4.3-.5.9-.2 1.4.2.2.5.4.8.4M46.8 52.2C53 47.6 57 40.3 57 32c.1-13.8-11.2-25.1-25-25.1S6.9 18.2 6.9 32c0 8.3 4 15.6 10.2 20.2l-7.4 8.1c-.4.4-.3 1 .1 1.4.2.2.4.3.7.3s.5-.1.7-.3l7.6-8.3c3.8 2.4 8.3 3.8 13.2 3.8 4.8 0 9.3-1.4 13.2-3.8l7.6 8.3c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 .1-1.4zM8.9 32C8.9 19.3 19.3 8.9 32 8.9S55.1 19.3 55.1 32 44.7 55.1 32 55.1 8.9 44.7 8.9 32" />
    <path d="M44.4 31H33V19.9c0-.6-.4-1-1-1s-1 .4-1 1V32c0 .6.4 1 1 1h12.4c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconAlarmClockAlt1;
