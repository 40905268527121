import * as React from "react";
const IconCipher = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 65 65"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32.5 3.5c-16 0-29 13-29 29s13 29 29 29 29-13 29-29-13-29-29-29m0 56c-14.9 0-27-12.1-27-27s12.1-27 27-27 27 12.1 27 27-12.1 27-27 27" />
    <path d="M32 27.9c.2.2.4.3.7.3s.5-.1.7-.3l6.3-6.3c.4-.4.4-1 0-1.4l-6.3-6.3c-.2-.2-.4-.3-.7-.3s-.5.1-.7.3l-6.3 6.3c-.4.4-.4 1 0 1.4zm.7-12 4.9 4.9-4.9 4.9-4.9-4.9zM44.8 25.4c-.4-.4-1-.4-1.4 0L37 31.7c-.4.4-.4 1 0 1.4l6.3 6.3c.2.2.4.3.7.3s.5-.1.7-.3l6.3-6.3c.2-.2.3-.4.3-.7s-.1-.5-.3-.7zm-.8 12-4.9-4.9 4.9-4.9 4.9 4.9zM28 31.8l-6.3-6.3c-.4-.4-1-.4-1.4 0L14 31.8c-.4.4-.4 1 0 1.4l6.3 6.3c.2.2.5.3.7.3s.5-.1.7-.3l6.3-6.3c.4-.3.4-1 0-1.4m-7 5.7-5-4.9 4.9-4.9 4.9 4.9zM33 37.1c-.4-.4-1-.4-1.4 0l-6.3 6.3c-.4.4-.4 1 0 1.4l6.3 6.3c.2.2.4.3.7.3s.5-.1.7-.3l6.3-6.3c.2-.2.3-.4.3-.7s-.1-.5-.3-.7zm-.7 12-4.9-4.9 4.9-4.9 4.9 4.9z" />
  </svg>
);
export default IconCipher;
