import * as React from "react";
const IconMessageIncoming = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m59.5 40.6-9.1 8.8v-7c0-.6-.4-1-1-1s-1 .4-1 1V50c0 1.6 1.3 2.9 2.9 2.9h7.6c.6 0 1-.4 1-1s-.4-1-1-1h-7.2l9.2-8.9c.4-.4.4-1 0-1.4s-1-.4-1.4 0" />
    <path d="M56.9 11H7.1C4.3 11 2 13.3 2 16.1v31.4c0 2.8 2.3 5.1 5.1 5.1h27.6c.6 0 1-.4 1-1s-.4-1-1-1H7.1c-1.7 0-3.1-1.4-3.1-3.1V16.1c0-.4.1-.8.2-1.1l25 15.2c.9.5 1.8.8 2.8.8s1.9-.3 2.8-.8l25-15.2c.1.3.2.7.2 1.1v14.4c0 .6.4 1 1 1s1-.4 1-1V16.1c0-2.8-2.3-5.1-5.1-5.1M33.8 28.5c-1.1.7-2.4.7-3.5 0l-24.7-15c.4-.3.9-.5 1.5-.5h49.8c.6 0 1.1.2 1.6.4z" />
  </svg>
);
export default IconMessageIncoming;
