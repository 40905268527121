import * as React from "react";
const IconHepatology = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M39 8H9a1 1 0 0 0-1 1v30a1 1 0 0 0 1 1h30a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1M9 6a3 3 0 0 0-3 3v30a3 3 0 0 0 3 3h30a3 3 0 0 0 3-3V9a3 3 0 0 0-3-3z"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M23.062 13.003a9.5 9.5 0 0 0-.99 2.024c-.289.827-.427 1.57-.494 2.109a8 8 0 0 0-.06.895v.028l1-.019c.938-.019.996-.02 1-.019v-.024q0-.038.003-.122c.005-.112.016-.282.043-.495a8 8 0 0 1 .396-1.69 7.2 7.2 0 0 1 1.481-2.503c1.38.228 1.94.624 2.409.956.446.316.81.573 1.718.573.937 0 2.25-.329 3.562-.659 1.969-.494 3.937-.988 4.64-.37 1.172 1.03-2.343 8.582-5.859 8.582-1.809 0-2.894 1.526-3.947 3.008-.993 1.397-1.957 2.754-3.474 2.754-1.022 0-1.793.265-2.478.622V23.82c0-.24.118-.481.354-.687.255-.223.526-.3.633-.3v-2c-.688 0-1.41.322-1.95.794-.559.49-1.037 1.243-1.037 2.193v.583c-.916.369-2.316.546-3.841-.653-2.658-2.088-4.177.836-1.9 2.506 1.578 1.157 4.248.712 5.741.329v3.26c-.703.377-1.493.662-2.552.662-1.282 0-1.705 1.206-2.155 2.488C14.795 34.449 14.25 36 12.382 36c-3.515 0-2.648-12.732-.554-17.66C13.922 13.41 16.787 13 22.646 13q.215 0 .416.003"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M20.012 41.5V30h2v11.5z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconHepatology;
