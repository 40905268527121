import * as React from "react";
const IconReloadCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M8.4 27.8c.2 0 .4 0 .6-.1l11.1-3.9c.5-.2.8-.8.6-1.3s-.8-.8-1.3-.6l-9.3 3.3C12.9 14.7 22.5 7.5 33.5 7.5c11.1 0 20.7 7.4 23.4 17.9.1.5.7.9 1.2.7.5-.1.9-.7.7-1.2-3-11.5-13.4-19.5-25.4-19.5-11.9 0-22.2 7.8-25.2 19.1l-3.3-9.4c-.2-.5-.8-.8-1.3-.6s-.8.8-.6 1.3l3.8 10.9c.3.7 1 1.1 1.6 1.1M60.9 50.2l-4.1-10.7c-.2-.4-.5-.8-.9-.9-.4-.2-.8-.2-1.3 0l-11 4.2c-.5.2-.8.8-.6 1.3s.8.8 1.3.6l9.6-3.6c-3.4 9.4-12.4 15.6-22.6 15.6-10.4 0-19.6-6.5-22.9-16.2-.2-.5-.7-.8-1.3-.6-.5.2-.8.7-.6 1.3C10.1 51.5 20 58.5 31.3 58.5c10.9 0 20.5-6.6 24.3-16.5l3.4 8.9q.3.6.9.6c.1 0 .2 0 .4-.1.6-.1.8-.7.6-1.2" />
  </svg>
);
export default IconReloadCopy;
