import * as React from "react";
const IconCloudUploadCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M33.7 27.9c-.7-.7-1.9-.7-2.6 0L26.2 33c-.4.4-.4 1 0 1.4s1 .4 1.4 0l3.8-3.9v11.3c0 .6.4 1 1 1s1-.4 1-1V30.4l3.8 3.9c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4z" />
    <path d="M57.2 24.2c-2.7-2.9-6.5-4.8-10.6-5.4-2.1-3.5-5.3-6.1-9.1-7.4-1.6-.6-3.5-.9-5.5-.9-9.4 0-17.1 7.5-17.1 16.8v.1C7.7 27.6 2 33.3 2 40.4c0 7.2 6 13.1 13.3 13.1h28.8C54 53.5 62 45.7 62 36.1c0-4.4-1.7-8.6-4.8-11.9M44.1 51.5H15.4C9 51.5 4 46.6 4 40.4s5-11.1 11.4-11.1h.5c.6 0 1-.4 1-1v-1.1c0-8.1 6.8-14.8 15.1-14.8 1.8 0 3.4.3 4.9.8 3.5 1.2 6.5 3.7 8.3 7 .2.3.4.5.8.5 3.7.4 7.3 2.2 9.8 4.8C58.5 28.4 60 32.1 60 36c0 8.6-7.1 15.5-15.9 15.5" />
  </svg>
);
export default IconCloudUploadCopy;
