import * as React from "react";
const IconCrocodile = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M49.8 21.7C44.9 20 38.6 19 32.8 19c-2.4 0-4.5.2-6.2.5-.2 0-.3.1-.5.1l-.3-.3c-2.4-2.5-6.7-2.7-9.6-.5-.8.6-1.3 1.4-1.8 2.1-.3.5-.7 1-.9 1.1l-7.1 1.9c-2 .5-3.5 1.9-4.1 3.6s-.3 3.6.7 5.2c1.1 1.6 3 2.6 5 2.6h13.5v3.1c0 .6.4 1 1 1s1-.4 1-1v-3.1h12v3.1c0 .6.4 1 1 1s1-.4 1-1v-3.1h11.3c1.3 0 2.3 1 2.3 2.3 0 1-.4 2.1-1.1 2.8-.4.4-1 1-1.9 1-1.7.1-2.7 1.4-2.7 2.6 0 1.3.9 2.6 2.7 2.7 7 0 13.9-3.7 13.9-12.1 0-5.5-4.3-10.1-12.2-12.9m-1.5 23c-.7 0-.8-.5-.8-.7 0-.1.1-.6.7-.6 1.2 0 2.4-.6 3.4-1.7s1.6-2.7 1.6-4.1c0-2.4-1.9-4.3-4.3-4.3H8.1c-1.8 0-2.9-1-3.3-1.7-.8-1.1-.9-2.3-.5-3.4.4-1.2 1.4-2 2.7-2.4l7.1-1.9c1-.3 1.5-1.1 2-1.9.4-.6.8-1.2 1.3-1.6 2.1-1.6 5.2-1.5 7 .3.1.1.3.2.3.3.7.6 1 .7 1.9.5.1 0 .2-.1.4-.1 1.6-.3 3.6-.5 5.9-.5 5.5 0 11.6 1 16.3 2.6 5 1.7 10.9 5 10.9 11-.1 7.6-6.4 10.2-11.8 10.2" />
    <path d="M20.5 23.8h-.3c-.6 0-1 .4-1 1s.4 1 1 1h.3c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconCrocodile;
