import * as React from "react";
const IconFriendly = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 .8C14.8.8.8 14.8.8 32s14 31.3 31.3 31.3 31.3-14 31.3-31.3S49.2.8 32 .8m0 58c-14.8 0-26.8-12-26.8-26.8S17.3 5.3 32 5.3s26.8 12 26.8 26.8-12 26.7-26.8 26.7" />
    <circle cx={20} cy={22.8} r={4} />
    <circle cx={44} cy={22.8} r={4} />
    <path d="M48.2 31.8c-1.2 0-2.3 1-2.3 2.3C46 41.2 40.2 47 33.1 47c-1.2 0-2.3 1-2.3 2.3s1 2.3 2.3 2.3c9.6 0 17.4-7.8 17.4-17.4 0-1.3-1-2.4-2.3-2.4" />
  </svg>
);
export default IconFriendly;
