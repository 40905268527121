import * as React from "react";
const IconCleaning = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M22.062 25.602 11.33 5.416a1 1 0 1 1 1.766-.939l10.733 20.186 1.522-.81a4 4 0 0 1 5.41 1.655l.648 1.218 6.869 10.055-14.249 7.576-4.495-11.318-.647-1.218a4 4 0 0 1 1.654-5.41zm-.583 2.575 4.81-2.557a2 2 0 0 1 2.705.827l.648 1.217-8.343 4.436-.647-1.218a2 2 0 0 1 .827-2.705m.83 6.432 2.753 6.933 1.834-.975-2.165-4.215 1.78-.914 2.152 4.19 6.702-3.564-4.208-6.16z"
      clipRule="evenodd"
    />
    <path
      
      d="M16.36 35.231a1 1 0 0 1 1.28 1.537l-.001.001-.002.002-.003.002-.01.008-.03.025-.103.079q-.131.1-.367.26c-.315.21-.77.484-1.344.758A11.15 11.15 0 0 1 11 39a1 1 0 1 1 0-2 9.15 9.15 0 0 0 3.92-.903 9 9 0 0 0 1.094-.617 6 6 0 0 0 .337-.24l.01-.01zM19.555 41.832a1 1 0 0 0-1.11-1.664l-.002.002-.02.012-.086.055q-.119.075-.349.207a14 14 0 0 1-1.27.642C15.65 41.561 14.299 42 13 42a1 1 0 1 0 0 2c1.702 0 3.35-.561 4.531-1.086a16 16 0 0 0 1.863-.979l.114-.072.032-.021.01-.006z"
    />
  </svg>
);
export default IconCleaning;
