import * as React from "react";
const IconLightbulbExclamation = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.9 24.9C53.9 12.8 44.1 3 32 3s-21.9 9.8-21.9 21.9c0 8.4 4.8 16 12.3 19.7v11.5c0 2.7 2.2 4.9 4.9 4.9h9.5c2.7 0 4.9-2.2 4.9-4.9V44.6c7.4-3.6 12.2-11.3 12.2-19.7M39.6 56.1c0 1.6-1.3 2.9-2.9 2.9h-9.5c-1.6 0-2.9-1.3-2.9-2.9V45h15.2v11.1zm.8-13.1H23.6C16.6 39.7 12 32.7 12 24.9 12.1 13.9 21 5 32 5s19.9 8.9 19.9 19.9c0 7.8-4.5 14.9-11.5 18.1" />
    <path d="M32 11.6c-.6 0-1 .4-1 1v13.5c0 .6.4 1 1 1s1-.4 1-1V12.6c0-.5-.4-1-1-1M32 32.6c-.6 0-1 .4-1 1v.5c0 .6.4 1 1 1s1-.4 1-1v-.5c0-.5-.4-1-1-1" />
  </svg>
);
export default IconLightbulbExclamation;
