import * as React from "react";
const IconIdeaLocation = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32.3 15.4c-3.1-.1-6.1 1.1-8.3 3.3s-3.5 5.1-3.5 8.3c0 4 2 7.7 5.4 9.8.7.4 1.1 1.2 1.1 2.1v2c0 2.5 2 4.5 4.5 4.5h1.1c2.5 0 4.5-2 4.5-4.5v-2.1q0-.6.3-1.2c.2-.3.5-.6.8-.8 3.3-2.1 5.3-5.6 5.4-9.5.1-3-1.1-6-3.2-8.2-2.2-2.3-5.1-3.6-8.1-3.7M35 40.8c0 1.4-1.1 2.5-2.5 2.5h-1.1c-1.4 0-2.5-1.1-2.5-2.5v-2c0-.2 0-.5-.1-.7h6.1v2.7zm2-5.8c-.5.3-.9.7-1.2 1.1h-7.7c-.3-.5-.7-.8-1.2-1.1-2.8-1.8-4.5-4.8-4.5-8.1 0-2.6 1-5 2.9-6.8 1.8-1.7 4.1-2.7 6.6-2.7h.2c2.5.1 4.8 1.1 6.6 3s2.7 4.3 2.6 6.8c.1 3.2-1.5 6.1-4.3 7.8" />
    <path d="M34 2.1c-7-.6-13.7 1.7-18.8 6.5-5.1 4.7-8 11.3-8 18.2 0 16.4 15.1 29.4 21.6 34.3.9.7 2 1.1 3.1 1.1s2.1-.3 3-1c6.3-4.6 21.1-17.1 21.6-33.5.6-13.2-9.6-24.6-22.5-25.6m20.7 25.4C54.2 42.9 40 54.9 33.9 59.4c-1.1.8-2.7.8-3.8 0-6.3-4.7-20.8-17.2-20.8-32.7 0-6.3 2.7-12.4 7.3-16.7 4.2-3.9 9.6-6 15.4-6 .6 0 1.3 0 1.9.1C45.7 5 55 15.5 54.7 27.5" />
  </svg>
);
export default IconIdeaLocation;
