import * as React from "react";
const IconMuted2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
    <path d="M42.3 42.7h-2.4v-3.5c0-.6-.4-1-1-1s-1 .4-1 1v3.5H33v-3.5c0-.6-.4-1-1-1s-1 .4-1 1v3.5h-4.9v-3.5c0-.6-.4-1-1-1s-1 .4-1 1v3.5h-2.4c-.6 0-1 .4-1 1s.4 1 1 1h2.4v3.5c0 .6.4 1 1 1s1-.4 1-1v-3.5H31v3.5c0 .6.4 1 1 1s1-.4 1-1v-3.5h4.9v3.5c0 .6.4 1 1 1s1-.4 1-1v-3.5h2.4c.6 0 1-.4 1-1s-.4-1-1-1M28.9 21c0-.6-.4-1-1-1s-1 .4-1 1c0 2.9-2.3 5.2-5.2 5.2s-5.2-2.3-5.2-5.2c0-.6-.4-1-1-1s-1 .4-1 1c0 4 3.2 7.2 7.2 7.2s7.2-3.2 7.2-7.2M48.5 20c-.6 0-1 .4-1 1 0 2.9-2.3 5.2-5.2 5.2s-5.2-2.3-5.2-5.2c0-.6-.4-1-1-1s-1 .4-1 1c0 4 3.2 7.2 7.2 7.2s7.2-3.2 7.2-7.2c0-.5-.5-1-1-1" />
  </svg>
);
export default IconMuted2;
