import React, { useState } from 'react';
import { useNavigation } from 'context/NavigationContext';
import {
	BringzzPageContainer,
	BringzzButton,
	BringzzIcon,
	BringzzText,
	BringzzInput,
	BringzzDropdown
} from '@bringzz/components';
import useUser from 'hooks/useUser';
import useRequest from 'hooks/useRequest';
import { useLocation } from 'react-router-dom';
function useQuery() {
	return new URLSearchParams(useLocation().search);
}
const EditPharmacyEmployeeInfo = () => {
	const { backPress, navigate } = useNavigation();
	const { sendRequest, loading } = useRequest();
	const { getUser } = useUser();
	const user = getUser();
	let query = useQuery();
	let employeeId = query.get('employeeId');
	const [pharmacyEmployeeBio, setPharmacyEmployeeBio] = useState('');
	const [pharmacyEmployeeRole, setPharmacyEmployeeRole] = useState('');
	const [pharmacyEmployeeLanguages, setPharmacyEmployeeLanguages] = useState(
		[]
	);
	const [pharmacyEmployeeFirstName, setPharmacyEmployeeFirstName] =
		useState('');
	const [pharmacyEmployeeLastName, setPharmacyEmployeeLastName] = useState('');

	return (
		<BringzzPageContainer
			header={{
				left: (
					<BringzzIcon
						size='22'
						icon='IconChevronLeft'
						folder='LineIcons'
						className='cursor-pointer'
						onClick={backPress}
					/>
				),
				title: <BringzzText tag='h2'>Edit employee</BringzzText>
			}}
			footer={
				<div className='pb-28 px-6'>
					<BringzzButton
						size='lg'
						className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
						onClick={async () => {
							await sendRequest({
								method: 'PATCH',
								url: `/pharmacy/team/edit`,
								data: {
									firstname: pharmacyEmployeeFirstName,
									lastname: pharmacyEmployeeLastName,
									role: pharmacyEmployeeRole,
									languages: pharmacyEmployeeLanguages,
									bio: pharmacyEmployeeBio
								}
							})
								.then(response => {
									if (response.status === 'success')
										navigate(`/pharmacy/team`, false);
								})
								.catch(error => {
									console.log(error);
								});
						}}
						disabled={loading}
					>
						Save
					</BringzzButton>
				</div>
			}
		>
			<div className='p-6 space-y-4'>
				<div className='flex flex-1 gap-3'>
					<BringzzInput
						defaultValue={user.team[employeeId].firstname}
						id='firstname'
						label='First Name'
						containerClassname='w-full'
						onChange={(e, isValid, newValue) =>
							setPharmacyEmployeeFirstName(newValue)
						}
					/>
				</div>
				<div className='flex flex-1 gap-3'>
					<BringzzInput
						defaultValue={user.team[employeeId].lastname}
						id='lastname'
						label='Last Name'
						containerClassname='w-full'
						onChange={(e, isValid, newValue) =>
							setPharmacyEmployeeLastName(newValue)
						}
					/>
				</div>
				<div className='flex flex-1 gap-3'>
					<BringzzDropdown
						onChange={value => setPharmacyEmployeeRole(value)}
						label={'Role/Title'}
						options={[
							{
								value: user.team[employeeId].role,
								label: user.team[employeeId].role
							}
						]}
						defaultValue={user.team[employeeId].role}
					/>
				</div>
				<div className='flex flex-1 gap-3'>
					<BringzzInput
						defaultValue={user.team[employeeId].languages}
						id='languages'
						label='Languages'
						containerClassname='w-full'
					/>
				</div>

				<div className='flex flex-1 gap-3'>
					<BringzzInput
						onChange={(e, isValid, newValue) => {
							setPharmacyEmployeeBio(newValue);
						}}
						id='bio'
						type='textarea'
						label='Bio'
						className='h-40'
						containerClassname='w-full'
						placeholder='Pharmacy Bio'
						defaultValue={user.team[employeeId].bio}
					/>
				</div>
			</div>
		</BringzzPageContainer>
	);
};

export default EditPharmacyEmployeeInfo;
