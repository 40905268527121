import * as React from "react";
const IconHotAirBaloon = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M45.2 44.2h-6.9v-9.8c6.2-2.5 10.5-8.5 10.5-15.6C48.8 9.5 41.3 2 32 2S15.2 9.5 15.2 18.8c0 7.1 4.4 13.1 10.5 15.6v9.8h-6.9c-.6 0-1 .4-1 1V53c0 5 4 9 9 9h10.4c5 0 9-4 9-9v-7.8c0-.6-.5-1-1-1m1.6-25.4c0 7.7-5.9 14-13.3 14.7C37 29.7 39 24.4 39 18.8c0-5.5-2-10.8-5.5-14.7 7.5.7 13.3 7 13.3 14.7M31.6 5.1h.1c3.4 3.5 5.3 8.5 5.3 13.7S35.1 29 31.7 32.5l-.1.1c-3.4-3.5-5.3-8.5-5.3-13.7s2-10.3 5.3-13.8M17.2 18.8c0-7.4 5.5-13.5 12.6-14.6-3.5 3.9-5.4 9.1-5.4 14.6s2 10.8 5.5 14.6c-7.2-1-12.7-7.2-12.7-14.6m10.5 16.3c1.4.4 2.8.6 4.3.6s2.9-.2 4.3-.6v9.1h-8.5v-9.1zM44.2 53c0 3.9-3.1 7-7 7H26.8c-3.9 0-7-3.1-7-7v-6.8h24.4z" />
  </svg>
);
export default IconHotAirBaloon;
