import * as React from "react";
const IconShipStop = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.4 5.1q-.15 0 0 0C53 3.1 50 2 46.7 2 39.1 2 33 8.2 33 15.7c0 4.1 1.8 7.7 4.6 10.2 0 0 0 .1.1.1h.1c2.4 2.1 5.5 3.4 9 3.4 7.6 0 13.7-6.2 13.7-13.7-.1-4.3-2-8.1-5.1-10.6M35 15.7C35 9.3 40.2 4 46.7 4c2.4 0 4.7.7 6.6 2l-15 17.8c-2.1-2.1-3.3-4.9-3.3-8.1m11.7 11.7c-2.6 0-5-.9-7-2.3L54.8 7.3c2.2 2.1 3.6 5.1 3.6 8.4 0 6.5-5.2 11.7-11.7 11.7M37.5 57.3c-.2-.2-.5-.4-.7-.5L41 41.9c.7-2.4-.5-4.9-2.8-5.8l-3-1.2v-4.5c0-.6-.4-1-1-1s-1 .4-1 1V34l-8.9-3.7c-1.2-.5-2.6-.5-3.7 0l-9 3.7v-6.7c0-1.6 1.3-2.9 2.9-2.9h12.6c.6 0 1-.4 1-1s-.4-1-1-1H16.2V17c0-1.6 1.3-2.9 2.9-2.9h7.3999999999999995c.6 0 1-.4 1-1s-.4-1-1-1h-3.1V7.3c0-.6-.4-1-1-1s-1 .4-1 1v4.8h-2.3c-2.7 0-4.9 2.2-4.9 4.9v5.5c-2.6.1-4.6 2.2-4.6 4.8v7.6l-3 1.2c-2.3.9-3.5 3.4-2.8 5.8L8 56.8c-.2.1-.5.3-.7.5l-3.4 3c-.4.4-.5 1-.1 1.4.2.2.5.3.8.3.2 0 .5-.1.7-.2l3.4-3c1.1-.9 2.7-.9 3.7 0l.9.8c1.8 1.6 4.5 1.6 6.4 0l.9-.8c1.1-.9 2.7-.9 3.7 0l.9.8c1.8 1.6 4.6 1.6 6.4 0l.9-.8c1.1-.9 2.7-.9 3.7 0l3.4 3c.4.4 1 .3 1.4-.1s.3-1-.1-1.4zm-6.3 0-.9.8c-1.1.9-2.7.9-3.7 0l-.9-.8c-1.8-1.6-4.5-1.6-6.4 0l-.9.8c-1.1.9-2.7.9-3.7 0l-.9-.8c-1.1-.9-2.4-1.3-3.8-1.1L5.8 41.4c-.4-1.4.3-2.9 1.6-3.4l3.5-1.5h.1l10.3-4.3c.7-.3 1.5-.3 2.2 0l10.2 4.3h.1l3.5 1.5c1.3.6 2 2 1.7 3.4l-4.2 14.8c-1.2-.2-2.6.2-3.6 1.1" />
  </svg>
);
export default IconShipStop;
