import React, { useState, useRef, useEffect } from "react";
import countries from "./countries.json";
import BringzzRadioList from "../BringzzRadioList";
import BringzzRadio from "../BringzzRadio";
import BringzzIcon from "../BringzzIcon";
import BringzzText from "../BringzzText";
import { twMerge } from "tailwind-merge";
import PropTypes from "prop-types";
import BringzzInput from "../BringzzInput";
import BringzzButton from "../BringzzButton"

// TODO: change flags to use icons after fixing performance issues of importing (use toPascalCase utility)

/**
 * Component for selecting a country from a dropdown list or modal.
 * It supports three modes: "flag", "flag-name", and "modal" for different UI presentations.
 *
 * @param {Object} props Component props
 * @param {string} props.mode UI mode for displaying countries
 * @param {Function} props.onCountryChange Callback for when a country is selected
 * @returns {JSX.Element} Rendered component
 */
const BringzzCountryDropdown = ({
  mode = "flag",
  onCountryChange,
  label,
  className,
  ...props
}) => {
  // State hooks for managing dropdown/modal open state, selected country, and search term
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState({
    name: "Austria",
    dial_code: "+43",
    code: "AT",
  });
  const [confirm, setConfirm] = useState(false)
  const [searchTerm, setSearchTerm] = useState("");
  const [activeLetter, setActiveLetter] = useState("a");

  const countryRefs = useRef({});
  const listRef = useRef(null);

  // Function to handle the letter click
  const handleLetterClick = (letter) => {
    const countryKey = Object.keys(countryRefs.current).find((key) =>
      key.toLowerCase().startsWith(letter.toLowerCase())
    );

    if (countryKey && countryRefs.current[countryKey]) {
      const element = countryRefs.current[countryKey];
      if (listRef.current && element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }
    }
  };

  const alphabet = [
    "#",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
  ];

  const modalRef = useRef(null); // Ref for modal element to detect clicks outside

  // Effect hook for closing modal when clicking outside, re-runs if `isModalOpen` changes
  useEffect(() => {
    if (isModalOpen) {
      const handleClickOutsideModal = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
          setIsModalOpen(false);
          setSearchTerm("");
        }
      };
      document.addEventListener("mousedown", handleClickOutsideModal);
      return () =>
        document.removeEventListener("mousedown", handleClickOutsideModal);
    }
  }, [isModalOpen]);

  // Handles selection of a country, updating state, and invoking callback
  const handleSelect = (country) => {
    console.log("changed");
    setSelectedCountry(country);
    setConfirm(true)
    onCountryChange(country); // Callback invocation with selected country code
  };

  const confirmSelection = () => {
    setIsOpen(false); // Close dropdown
    // Close modal if in modal mode
    if (mode === "modal") {
      setIsModalOpen(false);
      setSearchTerm("");
    }
  }

  // Toggles modal open/close state
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  useEffect(() => {
    const handleScroll = () => {
      const visibleKeys = Object.keys(countryRefs.current).filter((key) => {
        const element = countryRefs.current[key];
        if (!element) {
          console.log(`Element for key ${key} not found`);
          return false;
        }

        const rect = element.getBoundingClientRect();
        const scrollableRect = listRef.current.getBoundingClientRect();

        return rect.top >= scrollableRect.top && rect.top <= scrollableRect.bottom;
      });


      if (visibleKeys.length > 0) {
        const firstVisibleCountry = visibleKeys[0];
        const firstLetter = firstVisibleCountry[0].toUpperCase();

        if (firstLetter !== activeLetter) {
          setActiveLetter(firstLetter);
        }
      }
    };

    const listElement = listRef.current;
    if (listElement) {
      console.log("Adding scroll event listener");
      listElement.addEventListener('scroll', handleScroll, true);
    }

    return () => {
      if (listElement) {
        console.log("Removing scroll event listener");
        listElement.removeEventListener('scroll', handleScroll, true);
      }
    };
  }, [listRef, isModalOpen, activeLetter]);

  return (
    <div className="relative h-full bg-inherit">
      {/* Button to open dropdown or modal */}
      {label && (
        <div className="flex items-center">
          <label htmlFor={"countryPicker"}>
            <BringzzText tag="h4" className="font-bold">
              {label}
            </BringzzText>
          </label>
        </div>
      )}
      <button
        className={twMerge(`w-full h-full py-1 rounded-md`, className)}
        onClick={() => (mode === "modal" ? toggleModal() : setIsOpen(!isOpen))}
      >
        {/* Display selected country info or prompt to select a country */}
        {selectedCountry ? (
          // Selected country display
          <div className="flex items-center">
            <img
              src={`/countries/${selectedCountry.code}.svg`}
              alt={selectedCountry.code}
              className="w-5 h-5 rounded-full border border-white"
            />
            <div className="flex justify-between grow items-center">
              {mode != "flag" && (
                <BringzzText
                  tag="h5"
                  className=""
                >
                  {mode == "flag-name"
                    && selectedCountry.name}
                </BringzzText>
              )}
              {/* Conditional rendering of icons based on mode */}
              {mode === "modal" ? (
                <BringzzIcon
                  icon="IconChevronDown"
                  folder="LineIcons"
                  className="ml-1 h-4 w-4 text-slate-700"
                  size="16"
                />
              ) : (
                <BringzzIcon
                  icon="IconChevronDown"
                  folder="LineIcons"
                  className={twMerge(
                    "h-4 w-4 text-slate-600 transition-transform duration-200",
                    isOpen ? "rotate-180" : " "
                  )}
                  size="16"
                />
              )}
            </div>
          </div>
        ) : (
          <div className="flex items-center">
            <div className="flex justify-between grow items-center">
              <BringzzText
                type={{
                  tag: "p",
                  classes: "text-md font-medium text-dark mx-4 text-center",
                }}
              >
                Select a country
              </BringzzText>
              {mode === "modal" ? (
                <BringzzIcon
                  icon="IconChevronDown"
                  folder="LineIcons"
                  className="mx-2 h-4 w-4 text-slate-700"
                  size="16"
                />
              ) : (
                <BringzzIcon
                  icon="IconChevronDown"
                  folder="LineIcons"
                  className={twMerge(
                    "h-4 w-4 text-slate-600 transition-transform duration-200",
                    isOpen ? "rotate-180" : " "
                  )}
                  size="16"
                />
              )}
            </div>
          </div>
        )}
      </button>

      {/* Dropdown list for country selection */}
      {isOpen && mode !== "modal" && (
        <div className="absolute auto h-80 overflow-y-auto mt-1 pr-2 bg-white border border-gray-300 rounded-md shadow-lg z-10">
          {/* Map through countries and render each as a selectable option */}
          {countries.map((country) => (
            <div
              key={country.code + "-select"}
              className="flex items-center p-2 hover:bg-gray-100 cursor-pointer"
              onClick={() => handleSelect(country)}
            >
              <img
                src={`/countries/${country.code}.svg`}
                alt={country.code}
                className="w-6 h-4 mr-2"
              />
              {mode === "flag-name" && (
                <BringzzText
                  type={{
                    tag: "p",
                    classes: "text-md font-medium text-dark mx-4 text-center",
                  }}
                >
                  {country.name}
                </BringzzText>
              )}
            </div>
          ))}
        </div>
      )}

      {/* Modal UI for country selection */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
          <div
            className="bg-white p-1 w-screen h-screen rounded-lg shadow-lg relative flex flex-col"
            ref={modalRef}
          >
            <div className="flex justify-center">
              <div className="bg-[#8B899B] h-2 w-16 rounded-lg"></div>
            </div>
            {/* Display search results or prompt for country selection */}
            <div className="w-full flex justify-between items-center px-4 py-2 mb-2 shrink-0 h-12">
              <div className="flex items-center">
                <div>&bull;</div>
                <BringzzText
                  className="font-bold text-xs"
                >
                  {countries.filter((country) =>
                    country.name.toLowerCase().includes(searchTerm.toLowerCase())
                  ).length === 0
                    ? "No result for your search"
                    : "Select a country "}
                </BringzzText>
              </div>

              <BringzzIcon
                size='16'
                icon='IconClose'
                folder='LineIcons'
                className='cursor-pointer'
                onClick={confirmSelection}
              />
            </div>

            {/* List of countries filtered by search term */}
            <div
              className="grow overflow-auto flex items-center"
              ref={listRef}
            >
              <BringzzRadioList
                onChange={(newValue) => handleSelect(newValue)}
                className="overflow-y-auto w-full h-full pl-4 pr-1"
                state={{
                  checked: (
                    <BringzzIcon
                      icon="IconCheck"
                      library="Custom"
                      className="h-5 w-5 p-1 bg-magic-lilac text-light rounded-full"
                      size="12"
                    />
                  ),
                  unChecked: (
                    <div></div>
                  ),
                }}
              >
                {countries
                  .filter((country) =>
                    country.name
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  )
                  .map((country) => (
                    <BringzzRadio
                      key={country.code + "-modal"}
                      value={country}
                      className="p-2 py-4 hover:bg-slate- border-1 border-t border-gray-400"
                      state={{
                        checked: (
                          <BringzzIcon
                            icon="IconCheck"
                            library="Custom"
                            className="h-5 w-5 p-1 bg-magic-lilac text-light rounded-full"
                            size="12"
                          />
                        ),
                        unChecked: (
                          <div></div>
                        ),
                      }}
                      left={
                        <img
                          src={`/countries/${country.code}.svg`}
                          alt={country.code}
                          className="w-10 h-10 rounded-full"
                        />
                      }
                    >
                      <div
                        ref={(el) => (countryRefs.current[country.name] = el)}
                        className="flex"
                      >
                        <BringzzText tag="h3" className="font-bold w-1/5">
                          {country.dial_code}
                        </BringzzText>
                        <BringzzText tag="h3" className="font-bold">
                          {country.name}
                        </BringzzText>
                      </div>
                    </BringzzRadio>
                  ))}
              </BringzzRadioList>
              <div className="flex flex-col">
                {alphabet &&
                  alphabet.map((letter, index) => {
                    const isActive = letter.toLowerCase() === activeLetter.toLowerCase();
                    return (
                      <div
                        key={index}
                        onClick={() => handleLetterClick(letter)}
                        className={twMerge("cursor-pointer text-center", isActive && "bg-magic-lilac")}
                      >
                        <BringzzText
                          tag="h5"
                          className="text-midnight-blue/60 font-normal"
                        >
                          {letter.toUpperCase()}
                        </BringzzText>
                      </div>
                    );
                  })}
              </div>

            </div>

            {/* Search input for filtering countries */}
            <div className="p-4 shrink-0 border-t space-y-4">
              <BringzzInput
                id="countryPicker"
                type="text"
                placeholder="e.g. Germany"
                icon={{
                  left: (
                    <BringzzIcon
                      icon="IconSearchAlt"
                      folder="LineIcons"
                      size="20"
                      className="text-slate-400"
                    />
                  ),
                }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className=" p-3 border rounded-full "
              />
              <BringzzButton
                className="w-full flex justify-center bg-magic-lilac"
                disabled={!confirm}
                onClick={() => confirmSelection()}
              >{!confirm ? "Select a country" : "Confirm"}</BringzzButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

// Prop types for validation
BringzzCountryDropdown.propTypes = {
  mode: PropTypes.oneOf(["flag", "flag-name", "modal"]),
  onCountryChange: PropTypes.func.isRequired,
};

export default BringzzCountryDropdown;
