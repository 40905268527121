import * as React from "react";
const IconHelpCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M44.4 13.6c-.6-5.9-5.5-10.7-11.5-11.5-5.5-.6-10.7 2.1-13.3 7-.3.5-.1 1.1.4 1.3.5.3 1.1.1 1.3-.4 2.2-4.1 6.6-6.5 11.3-6 5.1.7 9.2 4.8 9.7 9.7.5 4.7-2 9.2-6.2 11.2-3.6 1.7-5.9 5.5-5.9 9.5v13.8c0 .6.4 1 1 1s1-.4 1-1V34.5c0-3.3 1.9-6.4 4.7-7.7 5.2-2.4 8.1-7.7 7.5-13.2M31.3 55.9c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5c.1-1.3-1.1-2.5-2.5-2.5" />
  </svg>
);
export default IconHelpCopy;
