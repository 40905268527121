import React, { useState } from 'react';

const BringzzFeedback = ({ totalStars = 5 }) => {
    const [selectedStars, setSelectedStars] = useState(0);

    const toggleStar = (index) => {
        if (selectedStars === index) {
            setSelectedStars(0);
        } else {
            setSelectedStars(index);
        }
    };

    return (
        <div className="flex items-center">
            {[...Array(totalStars)].map((_, index) => (
                <svg
                    key={index}
                    onClick={() => toggleStar(index + 1)}
                    xmlns="http://www.w3.org/2000/svg"
                    fill={selectedStars >= index + 1 ? 'currentColor' : 'none'}
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className={`w-7 h-7 cursor-pointer ${selectedStars >= index + 1 ? 'text-blue-500' : 'text-gray-300'
                        }`}
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
                    />
                </svg>
            ))}
        </div>
    );
};

export default BringzzFeedback;
