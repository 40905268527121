import * as React from "react";
const IconBrainCheckAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.2 60.3 43.5 42c8.8-9.3 8.6-24-.5-33-4.5-4.5-10.4-7-16.8-7-6.3 0-12.3 2.5-16.7 7-4.5 4.5-7 10.4-7 16.8 0 6.3 2.5 12.3 7 16.8s10.4 7 16.8 7c5.9 0 11.4-2.1 15.8-6l17.7 18.3c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.6.4-1.2 0-1.6M10.9 41.1C6.8 37 4.5 31.5 4.5 25.7s2.3-11.3 6.4-15.4S20.4 4 26.2 4s11.3 2.3 15.4 6.4c8.5 8.5 8.5 22.3 0 30.7-4.1 4.1-9.6 6.4-15.4 6.4S15 45.2 10.9 41.1" />
    <path d="M39.6 23.6c0-.3.1-.6.1-.9 0-2.2-1.4-4.1-3.4-4.8-.7-2-2.5-3.4-4.6-3.4-.7 0-1.4.2-2 .4-1-1-2.3-1.5-3.6-1.5s-2.6.5-3.6 1.5c-.6-.3-1.3-.4-2-.4-2.1 0-4 1.4-4.6 3.4-2 .6-3.4 2.6-3.4 4.8 0 .4.1.8.2 1.3-1.5 1.8-1.7 4-.5 5.9 1 1.6 2.8 2.5 4.8 2.4.5 0 .9-.1 1.3-.1.5-.1 1 .1 1.3.6.9 1.2 2.3 1.9 3.9 1.9 1.7 0 3.4.4 5 1.1l1.2.5c1.6.7 3.3 1.2 4.9 1.2h.4c1.3 0 2.5-.5 3.5-1.4.9-.9 1.5-2.1 1.5-3.5 0-.8-.2-1.6-.5-2.3 1-.9 1.5-2.2 1.5-3.6-.2-1.1-.6-2.2-1.4-3.1m-1.9 5.6c-.6.5-.7 1.3-.3 1.9.3.5.4 1 .4 1.6 0 .8-.3 1.5-.9 2-.6.6-1.4.9-2.1.8h-.3c-1.3-.1-2.8-.4-4.2-1.1l-1.2-.5c-1.9-.8-3.8-1.3-5.9-1.3-.9 0-1.7-.4-2.3-1.1-.8-1-2-1.5-3.2-1.3-.3 0-.7.1-1.1.1-1.2.1-2.4-.5-3-1.5-.7-1.2-.5-2.6.5-3.7.3-.4.5-.9.3-1.4-.1-.3-.2-.7-.2-1 0-1.4.9-2.6 2.2-2.9.5-.1 1-.5 1.1-1.1.3-1.3 1.5-2.2 2.8-2.2.5 0 .9.1 1.4.4.6.3 1.4.2 1.8-.3.6-.7 1.5-1.1 2.4-1.1s1.8.4 2.4 1.1c.5.5 1.2.7 1.8.3.4-.3.9-.4 1.4-.4 1.3 0 2.4.9 2.8 2.2.1.5.6.9 1.1 1.1 1.3.3 2.2 1.5 2.2 2.9 0 .2 0 .5-.1.7-.1.5 0 1 .4 1.4.5.5.8 1.3.8 2.1.1.8-.3 1.7-1 2.3" />
  </svg>
);
export default IconBrainCheckAlt;
