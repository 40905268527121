import * as React from "react";
const IconRupeeDown = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M39 14.8c0-.6-.4-1-1-1H25.7c-.6 0-1 .4-1 1s.4 1 1 1h2.1c1.7 0 3.8 1.9 4.6 3.7h-6.6c-.6 0-1 .4-1 1s.4 1 1 1h6.7c-.7 1.6-3.4 3-5.4 3-.4 0-.8.2-.9.6-.2.4-.1.8.2 1.1l6.9 7.7c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 .1-1.4L29 26.3c2.5-.6 5.1-2.4 5.5-4.7H38c.6 0 1-.4 1-1s-.4-1-1-1h-3.6c-.3-1.4-1.2-2.7-2.3-3.7H38c.6-.1 1-.6 1-1.1" />
    <path d="M32 5c10.2 0 18.4 8.2 18.4 18.4 0 5.6-2.5 10.8-6.8 14.3-.4.3-.5 1-.2 1.4.2.2.5.4.8.4.2 0 .4-.1.6-.2 4.8-3.9 7.6-9.7 7.6-15.8C52.4 12.1 43.3 3 32 3s-20.4 9.1-20.4 20.4c0 .6.4 1 1 1s1-.4 1-1C13.6 13.2 21.8 5 32 5M51.4 49.3c-.6 0-1 .4-1 1v7.1l-12.6-8.7c-.7-.5-1.6-.7-2.4-.7H23.6c-.7 0-1.3-.3-1.7-.7L15.8 41c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l6 6.2c.8.9 2 1.4 3.2 1.4h11.8c.5 0 .9.1 1.3.4L49.3 59h-7.5c-.6 0-1 .4-1 1s.4 1 1 1h9.7c.6 0 1-.4 1-1v-9.7c-.1-.6-.5-1-1.1-1" />
  </svg>
);
export default IconRupeeDown;
