import * as React from "react";
const IconArrowDownCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.7 34.6c-.4-.4-1-.4-1.4 0l-19.2 19V8c0-.6-.4-1-1-1s-1 .4-1 1v45.7l-19.4-19c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l21 20.7c.2.2.4.3.7.3s.5-.1.7-.3l21-20.7c.4-.4.4-1.1 0-1.5" />
  </svg>
);
export default IconArrowDownCopy;
