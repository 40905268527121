import * as React from "react";
const IconApartmentsAlt9 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 10.4H47V5.9c0-2.2-1.8-4-4-4H21c-2.2 0-4 1.8-4 4v4.5H6c-2.2 0-4 1.8-4 4v35.1c0 2.2 1.8 4 4 4h11V58c0 2.2 1.8 4 4 4h22c2.2 0 4-1.8 4-4v-4.5h11c2.2 0 4-1.8 4-4V14.4c0-2.2-1.8-4-4-4M6 51.6c-1.1 0-2-.9-2-2V14.4c0-1.1.9-2 2-2h11v39.1H6zm39 6.5c0 1.1-.9 2-2 2H21c-1.1 0-2-.9-2-2V5.9c0-1.1.9-2 2-2h22c1.1 0 2 .9 2 2v52.2m15-8.5c0 1.1-.9 2-2 2H47V12.4h11c1.1 0 2 .9 2 2z" />
    <path d="M35.7 17.2h-7.4c-.6 0-1 .4-1 1s.4 1 1 1h7.4c.6 0 1-.4 1-1s-.5-1-1-1M35.7 30.4h-7.4c-.6 0-1 .4-1 1s.4 1 1 1h7.4c.6 0 1-.4 1-1s-.5-1-1-1M35.7 43.7h-7.4c-.6 0-1 .4-1 1s.4 1 1 1h7.4c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconApartmentsAlt9;
