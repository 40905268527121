import * as React from "react";
const IconMilitaryWorker = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      d="M23.827 9.125a.181.181 0 0 1 .345 0l.494 1.52a.18.18 0 0 0 .172.126h1.599c.175 0 .248.225.106.328l-1.293.94a.18.18 0 0 0-.066.202l.494 1.52a.181.181 0 0 1-.279.204l-1.293-.94a.18.18 0 0 0-.213 0l-1.294.94a.181.181 0 0 1-.279-.203l.494-1.52a.18.18 0 0 0-.065-.203l-1.294-.94a.181.181 0 0 1 .107-.328h1.598c.079 0 .148-.05.173-.125z"
    />
    <path
      
      fillRule="evenodd"
      d="M16.023 19.396q-.826.25-1.699.55a1 1 0 0 1-1.041-1.643l.007-.008a4 4 0 0 0 .202-.23c.137-.165.32-.4.502-.68.384-.592.673-1.249.673-1.814C14.667 10.312 18.819 6 24 6s9.333 4.312 9.333 9.571c0 .565.29 1.222.673 1.814a7 7 0 0 0 .663.866l.007.007q.022.026.034.037l.007.008a1 1 0 0 1-1.041 1.643q-.873-.3-1.699-.55.023.299.023.604a8 8 0 1 1-15.977-.604m.644-3.825C16.667 11.363 19.977 8 24 8c4.024 0 7.333 3.363 7.333 7.571 0 .587.142 1.15.34 1.653-2.377-.667-4.565-1.05-6.74-1.134a24 24 0 0 0-2.185.014c-2.07.109-4.16.486-6.422 1.12.199-.503.34-1.066.34-1.653m1.448 3.252a6 6 0 1 0 11.77 0c-1.61-.39-3.114-.626-4.588-.713a22 22 0 0 0-2.417-.01c-1.53.078-3.09.317-4.765.723M35 24l5-2.857V9h2v23l-7-3zm5-.554-3 1.715v2.52l3 1.286z"
      clipRule="evenodd"
    />
    <path
      
      d="M12.142 29.338a1 1 0 1 0-.895-1.788L7.552 29.4a1 1 0 0 0 .896 1.788zM12.106 35.553a1 1 0 0 1 1.341-.447l1.553.776 1.553-.776a1 1 0 1 1 .894 1.788l-2 1a1 1 0 0 1-.894 0l-2-1a1 1 0 0 1-.447-1.341"
    />
    <path
      
      fillRule="evenodd"
      d="M42 36.5c0-4.06-10.924-6.617-18.53-6.493C16.259 30.124 6 32.44 6 36.5V42h36zm-18.497-4.494c3.015-.049 6.6.35 9.72 1.105L31.156 40H8v-3.5c0-.088.048-.473.9-1.1.825-.606 2.1-1.2 3.736-1.719 3.25-1.032 7.42-1.618 10.867-1.675m11.648 1.637L33.244 40H40v-3.5c0-.042-.032-.44-.98-1.112-.873-.617-2.197-1.22-3.869-1.745"
      clipRule="evenodd"
    />
  </svg>
);
export default IconMilitaryWorker;
