import * as React from "react";
const IconCompassCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.9 60.6 44.3 36.8c3.5-2.4 6.2-5.7 7.8-9.7.2-.5-.1-1.1-.6-1.3s-1.1.1-1.3.6c-1.4 3.5-3.8 6.5-6.8 8.6l-5.5-11.4c1.9-1.6 3.2-4.1 3.2-6.8 0-4.6-3.5-8.4-8.1-8.9V3c0-.6-.4-1-1-1s-1 .4-1 1v4.9c-4.5.5-8 4.3-8 8.9 0 2.7 1.2 5.2 3.2 6.8L20.7 35c-3-2.1-5.4-5.1-6.8-8.6-.2-.5-.8-.8-1.3-.6s-.8.8-.6 1.3c1.6 4 4.3 7.4 7.8 9.7L8.1 60.6c-.2.5 0 1.1.5 1.3.1.1.3.1.4.1q.6 0 .9-.6l11.5-23.6c3.2 1.8 6.8 2.8 10.6 2.8s7.4-1 10.6-2.8l11.5 23.6q.3.6.9.6c.1 0 .3 0 .4-.1.5-.2.7-.8.5-1.3m-31-43.8c0-3.8 3.2-7 7.1-7s7 3.1 7 7-3.2 7-7 7-7.1-3.2-7.1-7M32 38.6c-3.5 0-6.8-.9-9.7-2.6l5.5-11.3c1.3.7 2.7 1 4.2 1s2.9-.4 4.2-1L41.7 36c-2.9 1.7-6.2 2.6-9.7 2.6" />
  </svg>
);
export default IconCompassCopy;
