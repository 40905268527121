import * as React from "react";
const IconNoChargeBattery = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M10.7 5.4c-.3-.5-.9-.7-1.3-.4-.5.2-.7.8-.5 1.3l5.6 10.3h-6c-3.6 0-6.5 2.9-6.5 6.5v17.7c0 3.6 2.9 6.5 6.5 6.5h22.7l6.1 11.3c.2.3.5.5.9.5.2 0 .3 0 .5-.1.5-.3.7-.9.4-1.4zm-2.2 40c-2.5 0-4.5-2-4.5-4.5V23.1c0-2.5 2-4.5 4.5-4.5h7.1l14.5 26.7H8.5zM58 25.3h-4.3v-2.2c0-3.6-2.9-6.5-6.5-6.5H27.9c-.6 0-1 .4-1 1s.4 1 1 1h19.4c2.5 0 4.5 2 4.5 4.5v17.7c0 2.5-2 4.5-4.5 4.5h-5.7c-.6 0-1 .4-1 1s.4 1 1 1h5.7c3.6 0 6.5-2.9 6.5-6.5v-2.2H58c2.2 0 4-1.8 4-4v-5.3c0-2.2-1.8-4-4-4m2 9.4c0 1.1-.9 2-2 2h-4.3v-9.3H58c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconNoChargeBattery;
