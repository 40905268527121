import * as React from "react";
const IconProfits = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M33.9 22.9h-8.1c-.6 0-1 .4-1 1s.4 1 1 1h6.4v2.7c0 .6.4 1 1 1s1-.4 1-1v-2.7c2.8-.2 5-2.5 5-5.3 0-2.9-2.4-5.3-5.3-5.3h-3.7c-1.8 0-3.3-1.5-3.3-3.3s1.5-3.3 3.3-3.3h8.1c.6 0 1-.4 1-1s-.4-1-1-1h-4V3c0-.6-.4-1-1-1s-1 .4-1 1v2.7h-2.1c-2.9 0-5.3 2.4-5.3 5.3s2.4 5.3 5.3 5.3h3.7c1.8 0 3.3 1.5 3.3 3.3s-1.5 3.3-3.3 3.3M54.8 60H33V39.6l6.9 6.9c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-8.6-8.6c-.4-.4-1-.4-1.4 0l-8.6 8.6c-.4.4-.4 1 0 1.4s1 .4 1.4 0l6.9-6.9V60H9.2c-.6 0-1 .4-1 1s.4 1 1 1h45.5c.6 0 1-.4 1-1s-.4-1-.9-1" />
  </svg>
);
export default IconProfits;
