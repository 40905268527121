import React from 'react';
import { useNavigation } from '../../context/NavigationContext';
import { BringzzPageContainer, BringzzIcon, BringzzText, BringzzImage, BringzzButton, BringzzStatCard } from "@bringzz/components";

const MatchingProcessChangeShopper2 = () => {
    const { backPress } = useNavigation();

    return (
        <BringzzPageContainer
            header={{
                left: <BringzzIcon
                    size="22"
                    icon="IconChevronLeft"
                    folder="LineIcons"
                    className="cursor-pointer"
                    onClick={backPress}
                />,
                right: <>
                    <BringzzIcon
                        size="22"
                        icon="IconStar"
                        folder="LineIcons"
                        className="cursor-pointer"
                        onClick={(event) => {

                        }}
                    />
                    <BringzzIcon
                        size="22"
                        icon="IconUploadAlt"
                        folder="LineIcons"
                        className="cursor-pointer"
                        onClick={(event) => {

                        }}
                    /></>,
                background: <BringzzImage className="object-cover w-full h-full" src="/images/2.jpg" alt="react logo" />,
                avatar: <BringzzImage src="/logo192.png" alt="react logo" />
            }}
            subHeader={
                <div className='space-y-6'>
                    <BringzzText tag='h4' className="font-bold">??????</BringzzText>
                    <BringzzText tag='h2'>Apotheke Mustermann</BringzzText>
                    <div className='flex space-x-4'>
                        <BringzzButton className="border border-black" icon={{ variant: "IconSpeechBubble16", folder: "LineIcons", size: "18" }}>Chat</BringzzButton>
                        <BringzzButton className="border border-black" icon={{ variant: "IconPhone", folder: "LineIcons", size: "16" }}>Call</BringzzButton>
                    </div>
                    <div className='space-y-2'>
                        <BringzzText tag="h5" className="font-normal">Dui amet felis gravida convallis nisi suspendisse sed. Neque nam massa in odio dui amet felis gravida convallis nisi suspendisse sed. Neque nam massa in odio dui amet felis gravida...</BringzzText>
                        <BringzzText tag="h5" className="font-normal underline">Read more</BringzzText>
                    </div>
                    <div className='flex space-x-1 items-center'>
                        <BringzzText tag="h5" className="font-normal text-black/40">29 Posts</BringzzText>
                        <p className="text-[5px] text-black/40">&#9679;</p>
                        <BringzzText tag="h5" className="font-normal text-black/40">1,128 Followers</BringzzText>
                    </div>
                </div>
            }>

            <div className='px-4 grid gap-3'>
                <BringzzStatCard title='No. of Rides' value="47" />
                <div className='grid grid-cols-2 gap-2'>
                    <BringzzStatCard title='ranking regional riders' value="# 4" />
                    <BringzzStatCard title='ranking national' value="20" />
                </div>
            </div>

        </BringzzPageContainer>

    )
}

export default MatchingProcessChangeShopper2;