import * as React from "react";
const IconClipboardCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M46 9.9h-2.9V7.5c0-1.6-1.3-2.9-2.8-2.9H33V3c0-.6-.4-1-1-1s-1 .4-1 1v1.6h-7.3c-1.6 0-2.8 1.3-2.8 2.9v2.4H18c-2.6 0-4.6 2.2-4.6 4.8v42.5c0 2.6 2.1 4.8 4.6 4.8h28c2.6 0 4.6-2.2 4.6-4.8V14.7c.1-2.6-2-4.8-4.6-4.8M22.9 7.5c0-.5.4-.9.8-.9h16.6c.4 0 .8.4.8.9v6.8c0 .5-.4.9-.8.9H23.7c-.4 0-.8-.4-.8-.9zm25.8 49.7c0 1.5-1.2 2.8-2.6 2.8H18c-1.5 0-2.6-1.3-2.6-2.8V14.7c0-1.5 1.2-2.8 2.6-2.8h2.9v2.4c0 1.6 1.3 2.9 2.8 2.9h16.6c1.6 0 2.8-1.3 2.8-2.9v-2.4h3c1.5 0 2.6 1.3 2.6 2.8z" />
  </svg>
);
export default IconClipboardCopy;
