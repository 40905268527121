import * as React from "react";
const IconProducthunt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M36.1 22.7h-8.9V32h8.9c2.5 0 4.7-2.1 4.7-4.6.1-2.7-2.2-4.7-4.7-4.7" />
    <path d="M32 1C14.8 1 1 14.8 1 32s13.8 31 31 31 31-13.8 31-31S49.2 1 32 1m4.1 37.2h-8.9v9.3H21v-31h15.1c6.1 0 10.9 4.8 10.9 10.9.1 6-4.9 10.8-10.9 10.8" />
  </svg>
);
export default IconProducthunt;
