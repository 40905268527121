import * as React from "react";
const IconGeneralSurgery = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M40 8H8v32h32zM8 6a2 2 0 0 0-2 2v32a2 2 0 0 0 2 2h32a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2z"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M10 34h10v2H10zM28 34h4v2h-4zM22 34h4v2h-4zM34 34h4v2h-4zM16.828 28h4.724a4 4 0 0 0 2.798-1.142L28 23.284v-.456A4 4 0 0 1 29.172 20l1.689-1.69-2.606-1.736zM12 30l16-16 6 4-3.414 3.414A2 2 0 0 0 30 22.828v.456a2 2 0 0 1-.6 1.43l-3.65 3.573A6 6 0 0 1 21.551 30z"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M26.707 21.293a1 1 0 0 1 0 1.414l-3 3a1 1 0 0 1-1.414-1.414l3-3a1 1 0 0 1 1.414 0M36.707 8.108l-7.6 7.6-1.414-1.415 7.6-7.6zM38.207 12.393a1 1 0 0 1 0 1.414l-5.5 5.5-1.414-1.415 5.5-5.5a1 1 0 0 1 1.414 0"
      clipRule="evenodd"
    />
  </svg>
);
export default IconGeneralSurgery;
