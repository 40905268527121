import * as React from "react";
const IconCoughingAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M17.72 29.894c.143-.042.233-.086.28-.117V25h2v4.777c.047.03.137.075.28.117a3 3 0 0 0 .613.101c-.011-1.61.074-4.346 2.355-4.346 3.29 0 5.64 11.483 4.23 12.654-1.41 1.172-4.23.469-5.64-.937-1.075-1.073-1.047-3.5-.98-5.37a5.2 5.2 0 0 1-1.138-.182 3.1 3.1 0 0 1-.72-.305 3.1 3.1 0 0 1-.72.305 4.8 4.8 0 0 1-1.238.186h-.004c.066 1.87.093 4.294-.981 5.366-1.41 1.406-4.23 2.109-5.64.937s.94-12.654 4.23-12.654c2.283 0 2.366 2.742 2.354 4.35h.043a3 3 0 0 0 .676-.105m5.446-2.182.006-.012c.149.132.36.381.61.795.53.877 1.028 2.155 1.413 3.568.382 1.4.619 2.807.682 3.887.023.385.022.693.007.924-.188.03-.464.038-.846-.036-.72-.14-1.401-.503-1.788-.888 0 0-.027-.027-.07-.111a2 2 0 0 1-.143-.39c-.1-.366-.162-.844-.19-1.42-.027-.57-.017-1.17.002-1.769l.017-.47c.016-.426.032-.862.032-1.22q0-.2-.002-.4c-.005-.732-.01-1.469.148-2.101.04-.162.084-.278.122-.357m-8.438 0-.006-.012c-.148.132-.36.381-.61.795-.53.877-1.027 2.155-1.412 3.568-.382 1.4-.619 2.807-.683 3.887a8 8 0 0 0-.006.924c.188.03.464.038.845-.036.721-.14 1.402-.503 1.789-.888 0 0 .027-.027.07-.111a2 2 0 0 0 .143-.39c.1-.366.162-.844.19-1.42.027-.57.017-1.17-.003-1.769l-.016-.47c-.016-.426-.032-.862-.032-1.22q0-.2.002-.4c.005-.732.01-1.469-.148-2.101a2 2 0 0 0-.123-.357"
      clipRule="evenodd"
    />
    <path
      
      d="M32 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0M34 17a1 1 0 1 1-2 0 1 1 0 0 1 2 0M36 20a1 1 0 1 1-2 0 1 1 0 0 1 2 0M42 19a1 1 0 1 1-2 0 1 1 0 0 1 2 0M42 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0M42 10a1 1 0 1 1-2 0 1 1 0 0 1 2 0M38 12a1 1 0 1 1-2 0 1 1 0 0 1 2 0M38 16a1 1 0 1 1-2 0 1 1 0 0 1 2 0M30 19a1 1 0 1 1-2 0 1 1 0 0 1 2 0M23 17a1 1 0 1 1-2 0 1 1 0 0 1 2 0M20 16a1 1 0 1 1-2 0 1 1 0 0 1 2 0"
    />
    <path
      
      fillRule="evenodd"
      d="M22.723 24.02a2 2 0 0 1-2-2v-.947a2 2 0 0 1 2-2h1.06a.78.78 0 0 0 .533-.2.5.5 0 0 0 .17-.36v-.008l.001-.237.005-1.085-.002-.182v-.024a2.04 2.04 0 0 1 .884-1.816c-.252-.59-.516-1.21-.766-1.848a2 2 0 0 1-.138-.725v-.014l-.003-.088a6 6 0 0 0-.024-.345 4.5 4.5 0 0 0-.169-.898 4.55 4.55 0 0 0-1.722-2.312A5.15 5.15 0 0 0 19.627 8a5.08 5.08 0 0 0-2.837.855 4.5 4.5 0 0 0-1.732 2.186 4.15 4.15 0 0 0-.062 2.665 4.47 4.47 0 0 0 1.623 2.25 2 2 0 0 1 .81 1.607v4.456a2 2 0 0 1-2 2h-2.83c-1.232-.003-2.396.452-3.25 1.239-.843.777-1.314 1.812-1.349 2.879V40h22.996V29.053l.001-.068a4.5 4.5 0 0 0-.322-1.83 4.8 4.8 0 0 0-1.061-1.597 5.2 5.2 0 0 0-1.667-1.113 5.5 5.5 0 0 0-2.005-.426zm3.998-1.947a7.5 7.5 0 0 1 2 .527c.869.365 1.653.89 2.307 1.544a6.8 6.8 0 0 1 1.503 2.268c.338.842.496 1.74.465 2.641V42H6V28.107c.044-1.632.76-3.182 1.994-4.32 1.234-1.137 2.888-1.772 4.61-1.768h2.825v-4.456a6.47 6.47 0 0 1-2.343-3.261 6.15 6.15 0 0 1 .093-3.945 6.5 6.5 0 0 1 2.493-3.16A7.08 7.08 0 0 1 19.636 6a7.15 7.15 0 0 1 4.072 1.299c1.176.833 2.042 2 2.47 3.332.292.907.292 1.951.292 1.951.245.627.507 1.238.768 1.85l.368.888c.138.241.231.502.277.772.01.18-.05.356-.17.496a.76.76 0 0 1-.475.261c-.013 0-.052-.006-.107-.015-.054-.008-.123-.02-.196-.029-.213-.028-.459-.045-.45.044.015.151 0 1.675 0 1.675a2.48 2.48 0 0 1-.518 1.495l-.004.005a2.6 2.6 0 0 1-.275.303 2.78 2.78 0 0 1-1.905.746h-1.06v.946h3.24q.381.007.758.054M24.49 16.96v.003z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconCoughingAlt;
