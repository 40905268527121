import * as React from "react";
const IconEuroCoin = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
    <path d="M39.1 18.5c.6 0 1-.4 1-1s-.4-1-1-1c-7.1 0-13.3 5.1-15 11.8H20c-.6 0-1 .4-1 1s.4 1 1 1h3.8c-.1.5-.1 1.1-.1 1.6 0 1.5.2 3 .7 4.5H20c-.6 0-1 .4-1 1s.4 1 1 1h5.2c2.5 5.3 7.9 9 14 9 .6 0 1-.4 1-1s-.4-1-1-1c-5 0-9.4-2.9-11.8-7h7.1c.6 0 1-.4 1-1s-.4-1-1-1h-8c-.5-1.4-.8-2.9-.8-4.5 0-.6 0-1.1.1-1.6h8.7c.6 0 1-.4 1-1s-.4-1-1-1h-8.3c1.6-5.5 6.8-9.8 12.9-9.8" />
  </svg>
);
export default IconEuroCoin;
