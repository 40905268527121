import * as React from "react";
const IconUpServer = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 23.6V11.2c0-2.8-2.2-5-5-5H8c-2.8 0-5 2.2-5 5v12.4c0 1.6.8 3 2 4-1.2.9-2 2.3-2 3.9v12.7C3 46.9 5.2 49 7.9 49h31.8c.6 0 1-.4 1-1s-.4-1-1-1H7.9C6.3 47 5 45.8 5 44.2V31.5c0-1.6 1.3-2.9 2.9-2.9h48.2c1.6 0 2.9 1.3 2.9 2.9v6.8c0 .6.4 1 1 1s1-.4 1-1v-6.8c0-1.6-.8-3-2-3.9 1.2-.9 2-2.4 2-4m-53 3c-1.7 0-3-1.3-3-3V11.2c0-1.7 1.3-3 3-3h48c1.7 0 3 1.3 3 3v12.4c0 1.7-1.3 3-3 3z" />
    <path d="M16.1 16.2c-.6 0-1 .4-1 1v.2c0 .6.4 1 1 1s1-.4 1-1v-.2c0-.6-.4-1-1-1M50.1 16.3h-6.5c-.6 0-1 .4-1 1s.4 1 1 1h6.5c.6 0 1-.4 1-1s-.4-1-1-1M15.1 37.6v.2c0 .6.4 1 1 1s1-.4 1-1v-.2c0-.6-.4-1-1-1s-1 .4-1 1M53.4 43.9c-.2-.2-.4-.3-.7-.3-.2 0-.5.1-.7.3l-5.4 5.4c-.4.4-.4 1 0 1.4s1 .4 1.4 0l3.6-3.7v9.8c0 .6.4 1 1 1s1-.4 1-1V47l3.6 3.7c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4z" />
  </svg>
);
export default IconUpServer;
