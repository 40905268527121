import * as React from "react";
const IconUpAlign = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 4.6H3c-.6 0-1 .4-1 1s.4 1 1 1h58c.6 0 1-.4 1-1s-.4-1-1-1M19.3 15.2h-2.1c-3.2 0-5.7 2.6-5.7 5.7v32.7c0 3.2 2.6 5.7 5.7 5.7h2.1c3.2 0 5.7-2.6 5.7-5.7V20.9c.1-3.1-2.5-5.7-5.7-5.7m3.8 38.4c0 2.1-1.7 3.7-3.7 3.7h-2.1c-2.1 0-3.7-1.7-3.7-3.7V20.9c0-2.1 1.7-3.7 3.7-3.7h2.1c2.1 0 3.7 1.7 3.7 3.7zM46.8 15.2h-2.1c-3.2 0-5.7 2.6-5.7 5.7v19.5c0 3.2 2.6 5.7 5.7 5.7h2.1c3.2 0 5.7-2.6 5.7-5.7V20.9c0-3.1-2.6-5.7-5.7-5.7m3.7 25.2c0 2.1-1.7 3.7-3.7 3.7h-2.1c-2.1 0-3.7-1.7-3.7-3.7V20.9c0-2.1 1.7-3.7 3.7-3.7h2.1c2.1 0 3.7 1.7 3.7 3.7z" />
  </svg>
);
export default IconUpAlign;
