import * as React from "react";
const IconLockCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.1 20.4h-7.6v-5.7C43.5 7.7 38.4 2 32 2S20.5 7.7 20.5 14.7v5.7h-7.6c-3.1 0-5.6 2.5-5.6 5.6v17.4C7.3 53.7 15.6 62 25.8 62h12.5c10.2 0 18.5-8.2 18.5-18.4V26.1c-.1-3.1-2.6-5.7-5.7-5.7m-28.7-5.7C22.4 8.8 26.7 4 32 4s9.5 4.8 9.5 10.7v5.7h-19s-.1 0-.1-.1zm32.3 28.9c0 9-7.4 16.4-16.5 16.4H25.8c-9.1 0-16.5-7.4-16.5-16.5V26.1c0-2 1.6-3.6 3.6-3.6h38.2c2 0 3.6 1.6 3.6 3.6z" />
    <path d="M36.3 41.5h-8.5c-1.1 0-2 .9-2 2V52c0 1.1.9 2 2 2h8.5c1.1 0 2-.9 2-2v-8.5c0-1.1-.9-2-2-2m-8.6 10.6v-8.5h8.5v8.5z" />
  </svg>
);
export default IconLockCopy;
