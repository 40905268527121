import * as React from "react";
const IconIdCard = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 14.5H33V6.6c0-.6-.4-1-1-1s-1 .4-1 1v7.8H6c-2.2 0-4 1.8-4 4v35.9c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V18.5c0-2.2-1.8-4-4-4m2 39.9c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V18.5c0-1.1.9-2 2-2h52c1.1 0 2 .9 2 2z" />
    <path d="M19.9 34.7c3.4 0 6.1-2.8 6.1-6.1s-2.8-6.1-6.1-6.1-6.1 2.8-6.1 6.1 2.7 6.1 6.1 6.1m0-10.2c2.3 0 4.1 1.9 4.1 4.1 0 2.3-1.9 4.1-4.1 4.1-2.3 0-4.1-1.9-4.1-4.1-.1-2.3 1.8-4.1 4.1-4.1M19.9 39.7c-4.4 0-7.9 3.6-7.9 7.9 0 .6.4 1 1 1s1-.4 1-1c0-3.3 2.7-5.9 5.9-5.9 3.3 0 5.9 2.7 5.9 5.9 0 .6.4 1 1 1s1-.4 1-1c0-4.4-3.6-7.9-7.9-7.9M49.3 26.1H36.5c-.6 0-1 .4-1 1s.4 1 1 1h12.7c.6 0 1-.4 1-1s-.4-1-.9-1M49.3 35.2H36.5c-.6 0-1 .4-1 1s.4 1 1 1h12.7c.6 0 1-.4 1-1s-.4-1-.9-1M44.8 46.1h-8.2c-.6 0-1 .4-1 1s.4 1 1 1h8.2c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconIdCard;
