import * as React from "react";
const IconFlags = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m50.7 32.1 3.4-6.6c.8-1.6.8-3.5-.2-5.1s-2.6-2.5-4.4-2.5H38.8v-5.2c0-2.2-1.8-4-4-4H11.4V4c0-.6-.4-1-1-1s-1 .4-1 1v56c0 .6.4 1 1 1s1-.4 1-1V38.1h19.2v3c0 2.9 2.3 5.2 5.2 5.2h13.6c1.8 0 3.5-.9 4.4-2.5 1-1.6 1-3.5.2-5.1zM11.4 10.7h23.4c1.1 0 2 .9 2 2v21.4c0 1.1-.9 2-2 2H11.4zm40.7 32.1c-.6 1-1.6 1.5-2.7 1.5H35.8c-1.8 0-3.2-1.4-3.2-3.2v-3h2.2c2.2 0 4-1.8 4-4V19.9h10.6c1.1 0 2.1.6 2.7 1.5.6 1 .6 2.1.1 3.1l-3.6 7.1q-.15.45 0 .9l3.6 7.1c.6 1.1.5 2.2-.1 3.2" />
  </svg>
);
export default IconFlags;
