import * as React from "react";
const IconClassroom = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.8 6.2H8.2C4.8 6.2 2 8.9 2 12.3v20.4c0 3.4 2.8 6.1 6.2 6.1h.7c.7 2.8 3.3 4.9 6.5 4.9s5.7-2.1 6.5-4.9h20.5c.7 2.8 3.3 4.9 6.5 4.9s5.7-2.1 6.5-4.9h.7c3.4 0 6.2-2.7 6.2-6.1V12.3c-.3-3.4-3.1-6.1-6.5-6.1M15.3 41.7c-2.6 0-4.6-2-4.6-4.4s2.1-4.4 4.6-4.4 4.6 2 4.6 4.4-2 4.4-4.6 4.4m33.4 0c-2.6 0-4.6-2-4.6-4.4s2.1-4.4 4.6-4.4 4.6 2 4.6 4.4-2 4.4-4.6 4.4m11.3-9c0 2.3-1.9 4.1-4.2 4.1h-.5c-.3-3.3-3.1-5.9-6.6-5.9s-6.4 2.6-6.6 5.9H21.9c-.3-3.3-3.1-5.9-6.6-5.9s-6.4 2.6-6.6 5.9h-.5C5.9 36.8 4 35 4 32.7V12.3C4 10 5.9 8.2 8.2 8.2h47.7c2.3 0 4.2 1.9 4.2 4.1v20.4zM15.3 49.2c-5.3 0-10.4 2.7-13.1 7.1-.3.5-.2 1.1.3 1.4s1.1.2 1.4-.3c2.4-3.8 6.8-6.1 11.4-6.1 4.7 0 9 2.3 11.4 6.1.2.3.5.5.8.5.2 0 .4 0 .5-.2.5-.3.6-.9.3-1.4-2.7-4.5-7.6-7.1-13-7.1M48.7 49.2c-5.3 0-10.4 2.7-13.1 7.1-.3.5-.2 1.1.3 1.4s1.1.2 1.4-.3c2.4-3.8 6.8-6.1 11.4-6.1 4.7 0 9 2.3 11.4 6.1.2.3.5.5.8.5.2 0 .4 0 .5-.2.5-.3.6-.9.3-1.4-2.7-4.5-7.6-7.1-13-7.1" />
  </svg>
);
export default IconClassroom;
