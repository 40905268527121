import * as React from "react";
const IconTableLamp1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M48.8 22.9 45.1 8.2C44.2 4.5 41 2 37.2 2H26.8c-3.7 0-7 2.5-7.9 6.2l-3.7 14.7c-.6 2.5-.1 5 1.5 7s3.9 3.1 6.4 3.1H31v27h-5.9c-.6 0-1 .4-1 1s.4 1 1 1h13.7c.6 0 1-.4 1-1s-.4-1-1-1H33V33h7.9c2.5 0 4.9-1.1 6.4-3.1 1.6-2 2.1-4.6 1.5-7m-3 5.7C44.6 30.1 42.9 31 41 31H23.1c-1.9 0-3.7-.9-4.8-2.4-1.2-1.5-1.6-3.4-1.1-5.3l3.7-14.7c.7-2.7 3.1-4.7 6-4.7h10.5c2.8 0 5.3 1.9 6 4.7l3.7 14.7c.2 1.9-.2 3.8-1.3 5.3" />
  </svg>
);
export default IconTableLamp1;
