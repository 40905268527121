import * as React from "react";
const IconBugAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m47.7 27.8 9.6-2c.4-.1.6-.3.8-.7l2-6.4c.2-.5-.1-1.1-.7-1.2s-1.1.1-1.2.7L56.3 24l-9.1 1.9c-.3.1-.5.2-.7.5-.6-2.3-1.8-4.4-3.5-6.1-1.3-1.3-2.7-2.4-4.4-3.1 0-.1.1-.2.1-.4v-4.2c0-1.7-.9-3.2-2.2-4.2L39.3 4c.3-.5.1-1.1-.4-1.4s-1.1-.1-1.4.4l-2.7 4.6c-.4-.1-.8-.1-1.2-.1h-3.4c-.4 0-.8.1-1.2.1L26.4 3c-.3-.5-.9-.6-1.4-.4-.5.3-.6.9-.4 1.4l2.6 4.4c-1.3.9-2.2 2.5-2.2 4.2v4.2c0 .1 0 .2.1.4-1.6.8-3.1 1.8-4.4 3.1-1.7 1.8-2.8 3.9-3.5 6.1-.1-.2-.4-.4-.7-.5L7.7 24l-1.8-5.9c-.2-.5-.7-.8-1.2-.7-.5.2-.8.7-.7 1.2L6 25c.1.3.4.6.8.7l9.6 2h.2c.2 0 .4-.1.6-.2-.2 1-.3 2.1-.2 3.2l.3 6.3H7.4c-.4 0-.8.3-1 .7l-2 6.4c-.2.5.1 1.1.7 1.2h.3c.4 0 .8-.3 1-.7l1.8-5.7H17c.1 0 .2 0 .3-.1l.3 6.2c.4 7.9 6.7 14 14.4 14s14-6 14.4-13.7l.3-6.2c.1 0 .2.1.3.1h8.9l1.8 5.7c.1.4.5.7 1 .7h.3c.5-.2.8-.7.7-1.2l-2-6.4c-.1-.4-.5-.7-1-.7h-9.9l.3-6.3c.1-1.1 0-2.2-.2-3.2.2.1.4.2.6.2 0-.1.1-.1.2-.2m-20.5-12v-3.2c0-1.7 1.4-3.2 3.2-3.2h3.4c1.7 0 3.2 1.4 3.2 3.2v3.2h-9.8m-7.6 29.3-.7-14.3c-.2-3.4 1-6.7 3.4-9.2 2.3-2.4 5.4-3.8 8.7-3.8v39.1c-6.2-.5-11.1-5.5-11.4-11.8m24.8 0c-.3 6.3-5.2 11.3-11.4 11.8V17.8c3.3.1 6.4 1.4 8.7 3.8 2.4 2.5 3.6 5.7 3.4 9.2z" />
    <path d="m17.6 50.2-6.7 3q-.6.3-.6.9v6.4c0 .6.4 1 1 1s1-.4 1-1v-5.8l6.1-2.7c.5-.2.7-.8.5-1.3s-.8-.7-1.3-.5M53.1 53.2l-6.7-3c-.5-.2-1.1 0-1.3.5s0 1.1.5 1.3l6.1 2.7v5.8c0 .6.4 1 1 1s1-.4 1-1v-6.4c0-.4-.3-.8-.6-.9" />
  </svg>
);
export default IconBugAlt1;
