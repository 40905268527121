import * as React from "react";
const IconDownAlign2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M49.9 59H14.1c-.6 0-1 .4-1 1s.4 1 1 1h35.8c.6 0 1-.4 1-1s-.5-1-1-1M22.6 51.4c2.9 0 5.3-2.4 5.3-5.3V20.4c0-3-2.4-5.4-5.3-5.4h-1.9c-3 0-5.4 2.4-5.4 5.4V46c0 3 2.4 5.3 5.4 5.3h1.9zM17.3 46V20.4c0-1.8 1.5-3.4 3.4-3.4h1.9c1.8 0 3.3 1.5 3.3 3.4V46c0 1.8-1.5 3.3-3.3 3.3h-1.9c-1.9.1-3.4-1.4-3.4-3.3M43.4 51.4c3 0 5.3-2.4 5.3-5.3v-18c0-3-2.4-5.4-5.3-5.4h-1.9c-3 0-5.3 2.4-5.3 5.4V46c0 3 2.4 5.3 5.3 5.3h1.9zM38.1 46V28.1c0-1.8 1.5-3.4 3.3-3.4h1.9c1.8 0 3.3 1.5 3.3 3.4V46c0 1.8-1.5 3.3-3.3 3.3h-1.9c-1.8.1-3.3-1.4-3.3-3.3" />
    <path d="M56.7 3H7.4C4.4 3 2 5.4 2 8.3V59c0 .6.4 1 1 1s1-.4 1-1V8.3C4 6.5 5.5 5 7.4 5h49.3C58.5 5 60 6.5 60 8.3V59c0 .6.4 1 1 1s1-.4 1-1V8.3C62 5.4 59.6 3 56.7 3" />
  </svg>
);
export default IconDownAlign2;
