import * as React from "react";
const IconMargin = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 2H7C4.2 2 2 4.2 2 7v50c0 2.8 2.2 5 5 5h50c2.8 0 5-2.2 5-5V7c0-2.8-2.2-5-5-5m3 5v8.3H45.9V4H57c1.7 0 3 1.3 3 3m0 39.7H45.9V17.3H60zM4 17.3h14.1v29.5H4zm16.1 0h23.8v29.5H20.1zM43.9 4v11.3H20.1V4zM7 4h11.1v11.3H4V7c0-1.7 1.3-3 3-3M4 57v-8.3h14.1V60H7c-1.7 0-3-1.3-3-3m16.1 3V48.7h23.8V60zM57 60H45.9V48.7H60V57c0 1.7-1.3 3-3 3" />
  </svg>
);
export default IconMargin;
