import * as React from "react";
const IconLockedWindow = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M39.4 38.2h-2.6v-3.9c0-3.2-2.6-5.8-5.8-5.8s-5.8 2.6-5.8 5.8v3.9h-2.6c-.6 0-1 .4-1 1V49c0 .6.4 1 1 1h16.9c.6 0 1-.4 1-1v-9.8c-.1-.6-.5-1-1.1-1m-12.2-3.9c0-2.1 1.7-3.8 3.8-3.8s3.8 1.7 3.8 3.8v3.9h-7.6zM38.4 48H23.5v-7.8h14.9z" />
    <path d="M58 5.5H6c-2.2 0-4 1.8-4 4v45c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4v-45c0-2.2-1.8-4-4-4m-52 2h52c1.1 0 2 .9 2 2v12H4v-12c0-1.1.9-2 2-2m52 49H6c-1.1 0-2-.9-2-2v-31h56v31c0 1.1-.9 2-2 2" />
    <path d="M12.1 16h.8c.6 0 1-.4 1-1s-.4-1-1-1h-.8c-.6 0-1 .4-1 1s.5 1 1 1M21.1 16h.8c.6 0 1-.4 1-1s-.4-1-1-1h-.8c-.6 0-1 .4-1 1s.5 1 1 1M30.1 16h.8c.6 0 1-.4 1-1s-.4-1-1-1h-.8c-.6 0-1 .4-1 1s.5 1 1 1" />
  </svg>
);
export default IconLockedWindow;
