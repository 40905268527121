import * as React from "react";
const IconTruckAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.7 36.8-5.6-10.2c-.4-.6-1-1-1.8-1h-7.7c-1.3 0-2.3 1-2.3 2.3V29H39v-2.1c0-1.3-.7-2.5-1.8-3.2L17.8 12.6c0-.3.1-.6.1-.9 0-3.6-2.9-6.5-6.5-6.5s-6.5 2.9-6.5 6.5c0 3.2 2.4 5.9 5.5 6.4v4.2c-1.9.5-3.3 2.2-3.3 4.2 0 2.4 1.9 4.3 4.3 4.3 1.3 0 2.5-.6 3.3-1.5.4-.4.3-1.1-.1-1.4-.4-.4-1.1-.3-1.4.1-.4.5-1.1.8-1.8.8-1.3 0-2.3-1-2.3-2.3s1-2.3 2.3-2.3c.6 0 1-.4 1-1v-5c2.1-.3 3.9-1.7 4.8-3.5l19.1 10.9c.5.3.8.8.8 1.4v2h-4.4c-1 0-1.9.4-2.6 1.1l-6.1 6c-.1.1-.2.3-.2.5H6.4c-1 0-2 .7-2.2 1.7l-2 7.5c-.2.7 0 1.5.5 2.1l3.8 4.5c.4.5 1.1.8 1.8.8h2.4c.5 3.1 3.2 5.5 6.4 5.5 2.4 0 4.5-1.3 5.6-3.2 1.1 1.9 3.2 3.2 5.6 3.2 3.2 0 5.9-2.4 6.4-5.5h6.9c.5 3.1 3.2 5.5 6.4 5.5s5.9-2.4 6.4-5.5h2.7c.6 0 1.2-.2 1.5-.6l2.6-2.3c.5-.4.8-1.1.8-1.7V37.9c0-.4-.1-.8-.3-1.1M11.4 16.2c-2.5 0-4.5-2-4.5-4.5s2-4.5 4.5-4.5 4.5 2 4.5 4.5-2.1 4.5-4.5 4.5m35.3 11.4h7.7l4.9 9H46.4v-8.7q0-.3.3-.3m-15.2 3.9c.3-.3.7-.5 1.2-.5h11.7v5.6h-18zM16.9 56.7c-2.5 0-4.5-2-4.5-4.5s2-4.5 4.5-4.5 4.5 2 4.5 4.5-2 4.5-4.5 4.5m11.2 0c-2.5 0-4.5-2-4.5-4.5s2-4.5 4.5-4.5 4.5 2 4.5 4.5-2 4.5-4.5 4.5m19.8 0c-2.5 0-4.5-2-4.5-4.5s2-4.5 4.5-4.5 4.5 2 4.5 4.5-2 4.5-4.5 4.5m12-7.9-2.6 2.3s-.1.1-.2.1h-2.7c-.5-3.1-3.2-5.5-6.4-5.5s-5.9 2.4-6.4 5.5h-6.9c-.5-3.1-3.2-5.5-6.4-5.5-2.4 0-4.5 1.3-5.6 3.2a6.47 6.47 0 0 0-5.6-3.2c-3.2 0-5.9 2.4-6.4 5.5H8.1c-.1 0-.2 0-.2-.1l-3.8-4.5c-.1-.1-.1-.2-.1-.3l2.1-7.5c0-.1.2-.2.3-.2H60v10c0 .1 0 .2-.1.2" />
  </svg>
);
export default IconTruckAlt1;
