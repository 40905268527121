import * as React from "react";
const IconWaveformLines = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M4 21.2c-.6 0-1 .4-1 1v19.7c0 .6.4 1 1 1s1-.4 1-1V22.2c0-.6-.4-1-1-1M15.2 15.8c-.6 0-1 .4-1 1v30.3c0 .6.4 1 1 1s1-.4 1-1V16.8c0-.5-.4-1-1-1M26.4 5.6c-.6 0-1 .4-1 1v50.9c0 .6.4 1 1 1s1-.4 1-1V6.6c0-.6-.4-1-1-1M37.6 21.8c-.6 0-1 .4-1 1v18.4c0 .6.4 1 1 1s1-.4 1-1V22.8c0-.5-.4-1-1-1M48.8 13.7c-.6 0-1 .4-1 1v34.6c0 .6.4 1 1 1s1-.4 1-1V14.7c0-.6-.4-1-1-1M60 25.6c-.6 0-1 .4-1 1v10.7c0 .6.4 1 1 1s1-.4 1-1V26.6c0-.5-.4-1-1-1" />
  </svg>
);
export default IconWaveformLines;
