import * as React from "react";
const IconSsd1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M6.172 6c0-2.768 2.258-5 5.028-5h41.945c2.77 0 5.027 2.232 5.027 5v52c0 2.768-2.257 5-5.027 5H11.2c-2.77 0-5.028-2.232-5.028-5zM11.2 3C9.521 3 8.172 4.35 8.172 6v52c0 1.65 1.35 3 3.028 3h41.945c1.678 0 3.027-1.35 3.027-3V6c0-1.65-1.349-3-3.027-3zm1.912 5.8a1 1 0 0 1 1-1h.1a1 1 0 1 1 0 2h-.1a1 1 0 0 1-1-1m36.25 0a1 1 0 0 1 1-1h.1a1 1 0 1 1 0 2h-.1a1 1 0 0 1-1-1m-32.72 20.504c0-2.047 1.67-3.695 3.714-3.695h3.618a1 1 0 1 1 0 2h-3.618c-.953 0-1.714.765-1.714 1.695S19.403 31 20.356 31h.995c1.995 0 3.624 1.608 3.624 3.606s-1.63 3.605-3.624 3.605h-3.709a1 1 0 1 1 0-2h3.71c.902 0 1.622-.725 1.622-1.605s-.72-1.606-1.623-1.606h-.995c-2.045 0-3.714-1.648-3.714-3.695m10.692 0c0-2.047 1.67-3.695 3.714-3.695h3.618a1 1 0 1 1 0 2h-3.618c-.953 0-1.714.765-1.714 1.695S30.094 31 31.048 31h.995c1.995 0 3.623 1.608 3.623 3.606s-1.628 3.605-3.623 3.605h-3.709a1 1 0 0 1 0-2h3.709c.903 0 1.623-.725 1.623-1.605s-.72-1.606-1.623-1.606h-.995c-2.045 0-3.714-1.648-3.714-3.695m10.692-1.695a2 2 0 0 1 2-2h3.407c2.351 0 4.27 1.895 4.27 4.247v4.288c0 2.352-1.919 4.247-4.27 4.247h-3.407a2 2 0 0 1-2-2zm5.407 0h-3.407v8.782h3.407c1.26 0 2.27-1.013 2.27-2.247v-4.288a2.26 2.26 0 0 0-2.27-2.247M13.113 55.8a1 1 0 0 1 1-1h.1a1 1 0 1 1 0 2h-.1a1 1 0 0 1-1-1m36.25 0a1 1 0 0 1 1-1h.1a1 1 0 1 1 0 2h-.1a1 1 0 0 1-1-1"
      clipRule="evenodd"
    />
  </svg>
);
export default IconSsd1;
