import React from 'react';
import { useNavigation } from '../../context/NavigationContext';
import { BringzzPageContainer, BringzzIcon, BringzzText, BringzzImage, BringzzButton, BringzzTabs, BringzzTab, BringzzTabPanel, BringzzPostingCard, BringzzImageSlider } from "@bringzz/components"
import AvatarExample from 'assets/images/AvatarExample.png';
import PostExample1 from 'assets/images/PostExample1.png';

const ProfilePharmacyViews5 = () => {
    const { backPress } = useNavigation();

    return (
        <BringzzPageContainer
            header={{
                left: <BringzzIcon
                    size="22"
                    icon="IconChevronLeft"
                    folder="LineIcons"
                    className="cursor-pointer"
                    onClick={backPress}
                />,
                title: <BringzzText tag='h2'>Apotheke Mustermann</BringzzText>,
                right: <>
                    <BringzzIcon
                        size="22"
                        icon="IconStar"
                        folder="LineIcons"
                        className="cursor-pointer"
                        onClick={(event) => {

                        }}
                    />
                    <BringzzIcon
                        size="22"
                        icon="IconUploadAlt"
                        folder="LineIcons"
                        className="cursor-pointer"
                        onClick={(event) => {

                        }}
                    /></>,
            }}>

            <BringzzTabs defaultActiveTab="tab1">
                <BringzzTab id="tab1">
                    <BringzzIcon
                        size="20"
                        icon="IconLayoutAlt1"
                        folder="LineIcons"
                        className="cursor-pointer"
                        onClick={(event) => {

                        }}
                    />
                </BringzzTab>
                <BringzzTabPanel id="tab1">Content for Tab 1</BringzzTabPanel>

                <BringzzTab id="tab2">
                    <BringzzIcon
                        size="20"
                        icon="IconLayoutAlt"
                        folder="LineIcons"
                        className="cursor-pointer"
                        onClick={(event) => {

                        }}
                    />
                </BringzzTab>
                <BringzzTabPanel id="tab2">
                    <BringzzText tag="h2" className="p-4">Posts</BringzzText>
                    <BringzzPostingCard
                        avatar={AvatarExample}
                        name='Apotheke Mustermann'
                        header={<BringzzImage className="w-full h-auto" src={PostExample1} alt="post image"></BringzzImage>}
                        likesCount={1200}
                        commentsCount={40}
                        title='Fall time is cold time: How to prepare for the cold season'
                        description="Turpis rutrum amet vitae ac integer et pharetra gravida. Praesent
					laoreet amet Dui amet felis gravida convallis nisi suspendisse sed."
                        action={{
                            label: 'Read more',
                            icon: 'IconEbook',
                            iconFolder: 'LineIcons',
                            onClick: () => console.log("text")
                        }}
                    ></BringzzPostingCard>
                    <BringzzPostingCard
                        avatar={AvatarExample}
                        name='Apotheke Mustermann'
                        header={<BringzzImage className="w-full h-auto" src={PostExample1} alt="post image"></BringzzImage>}
                        likesCount={1200}
                        title='Fall time is cold time: How to prepare for the cold season'
                        description="Turpis rutrum amet vitae ac integer et pharetra gravida. Praesent
					laoreet amet Dui amet felis gravida convallis nisi suspendisse sed."
                        commentsCount={40}
                        action={{
                            label: 'Read more',
                            icon: 'IconEbook',
                            iconFolder: 'LineIcons',
                            onClick: () => console.log("text")
                        }}
                    ></BringzzPostingCard>
                    <BringzzPostingCard
                        avatar={AvatarExample}
                        name='Apotheke Mustermann'
                        header={<BringzzImage className="w-full h-auto" src={PostExample1} alt="post image"></BringzzImage>}
                        likesCount={1200}
                        title='Fall time is cold time: How to prepare for the cold season'
                        description="Turpis rutrum amet vitae ac integer et pharetra gravida. Praesent
					laoreet amet Dui amet felis gravida convallis nisi suspendisse sed."
                        commentsCount={40}
                        action={{
                            label: 'Read more',
                            icon: 'IconEbook',
                            iconFolder: 'LineIcons',
                            onClick: () => console.log("text")
                        }}
                    ></BringzzPostingCard>
                </BringzzTabPanel>

                <BringzzTab id="tab3">
                    <BringzzIcon
                        size="20"
                        icon="IconUserAlt1"
                        folder="LineIcons"
                        className="cursor-pointer"
                        onClick={(event) => {

                        }}
                    />
                </BringzzTab>
                <BringzzTabPanel id="tab3">Content for Tab 3</BringzzTabPanel>

                <BringzzTab id="tab4">
                    <BringzzIcon
                        size="20"
                        icon="IconInfoCircle"
                        folder="LineIcons"
                        className="cursor-pointer"
                        onClick={(event) => {

                        }}
                    />
                </BringzzTab>
                <BringzzTabPanel id="tab4" className="p-4 space-y-4">
                    <BringzzText tag="h2">Information</BringzzText>
                    <BringzzImageSlider
                        images={[
                            {
                                url: "/images/1.jpg",
                            },
                            {
                                url: "/images/2.jpg",
                            },
                            {
                                url: "/images/3.jpg",
                            },
                        ]}
                        autoSlide="true"
                    ></BringzzImageSlider>
                </BringzzTabPanel>
            </BringzzTabs>
        </BringzzPageContainer>

    )
}

export default ProfilePharmacyViews5;