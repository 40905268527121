import * as React from "react";
const IconMedicalSearch1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M21 20.132a8.5 8.5 0 0 1 8.176 13.63l1.306 1.306a2 2 0 0 1 1.932.518l5 5a2 2 0 1 1-2.828 2.828l-5-5a2 2 0 0 1-.518-1.932l-1.306-1.306A8.46 8.46 0 0 1 22.5 37a8.6 8.6 0 0 1-1.5-.132V39a5.002 5.002 0 0 1-10 0V12.688a1.504 1.504 0 0 1 1-1.415V10h-1a1 1 0 0 1-1-1V5q0-.053.005-.102A1 1 0 0 1 11 4h10a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-1v1.273a1.5 1.5 0 0 1 1 1.415zM14 10h4v1.273a2 2 0 0 0 1 1.732V18h-6v-4.995a2 2 0 0 0 1-1.732zm-1 10v19a3.002 3.002 0 0 0 6 0v-2.752a8.5 8.5 0 0 1-5-7.748 8.5 8.5 0 0 1 5-7.748V20zM12 8h8V6h-8zm9 14.174a6.495 6.495 0 0 0-5 6.326 6.495 6.495 0 0 0 6.5 6.5 6.47 6.47 0 0 0 3.835-1.251 6.5 6.5 0 0 0 1.414-1.414A6.5 6.5 0 0 0 23 22.019V33.5h-2z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconMedicalSearch1;
