import * as React from "react";
const IconRecord = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
    <path d="M32 21c-6.1 0-11 5-11 11s5 11 11 11 11-5 11-11-4.9-11-11-11m0 20c-5 0-9-4.1-9-9s4.1-9 9-9 9 4.1 9 9-4 9-9 9" />
  </svg>
);
export default IconRecord;
