import axios from 'axios';

const axiosInstance = axios.create({
    headers: {
        'Content-Type': 'application/json'
    },
});

axiosInstance.defaults.baseURL = process.env.REACT_APP_USE_BACKEND === 'false'
    ? `/api`
    : process.env.REACT_APP_BACKEND_URL || '';

// Request interceptor - dynamically get the token from localStorage
axiosInstance.interceptors.request.use((req) => {
    console.log("INTERCEPT TTTTTTTTT NON");

    const token = localStorage.getItem('token');
    if (token) {
        console.log("INTERCEPT TTTTTTTTT");

        req.headers.Authorization = `Bearer ${token}`;
    }
    return req;
})

// Response interceptor
axiosInstance.interceptors.response.use(
    response => {
        if (response.data.status == 'success')
            return { status: 'success', data: response.data.data, message: response.data.message };
        else
            return Promise.reject({ status: 'error', message: response.data.message })
    },
    error => {
        return Promise.reject({ status: 'error', message: error.message });
    }
);

export default axiosInstance;
