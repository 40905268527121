import * as React from "react";
const IconUploadCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 44.1c-.6 0-1 .4-1 1v14.8c-.1 0-.2.1-.3.1H4.3c-.1 0-.3 0-.3-.1V45.1c0-.6-.4-1-1-1s-1 .4-1 1v15c0 1 1 1.9 2.3 1.9h55.3c1.3 0 2.3-.8 2.3-1.9v-15c.1-.5-.3-1-.9-1" />
    <path d="m23 12.5 7.8-7.9c.1-.1.2-.1.2-.2v40.7c0 .6.4 1 1 1s1-.4 1-1V4.3c.1.1.2.1.3.2l7.7 7.9c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-7.7-7.9C34 2.4 33.1 2 32 2c-1 0-2 .4-2.7 1.1L21.5 11c-.4.4-.4 1 0 1.4.5.5 1.1.5 1.5.1" />
  </svg>
);
export default IconUploadCopy;
