import * as React from "react";
const IconCircleAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
    <path d="M32 17.7c-7.9 0-14.3 6.4-14.3 14.3S24.1 46.3 32 46.3 46.3 39.9 46.3 32 39.9 17.7 32 17.7m0 26.6c-6.8 0-12.3-5.5-12.3-12.3S25.2 19.7 32 19.7 44.3 25.2 44.3 32 38.8 44.3 32 44.3" />
  </svg>
);
export default IconCircleAlt;
