import * as React from "react";
const IconDigitalCurrency = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M20.3 24.3C10.2 24.3 2 32.6 2 42.7S10.2 61 20.3 61s18.3-8.2 18.3-18.3-8.2-18.4-18.3-18.4m0 34.7C11.3 59 4 51.7 4 42.7s7.3-16.3 16.3-16.3 16.3 7.3 16.3 16.3S29.3 59 20.3 59M58.8 14.4c0-5.7-4.6-10.3-10.3-10.3S38.2 8.7 38.2 14.4s4.6 10.3 10.3 10.3 10.3-4.6 10.3-10.3m-18.5 0c0-4.6 3.7-8.3 8.3-8.3s8.3 3.7 8.3 8.3-3.7 8.3-8.3 8.3-8.3-3.7-8.3-8.3M4.1 23.1c.6 0 1-.4 1-1V7.5C5.1 6.1 6.2 5 7.6 5H23c.6 0 1-.4 1-1s-.4-1-1-1H7.6C5.1 3 3.1 5 3.1 7.5v14.6c0 .5.5 1 1 1M61 34.5c-.6 0-1 .4-1 1v15c0 1.4-1.1 2.5-2.5 2.5H43.3c-.6 0-1 .4-1 1s.4 1 1 1h14.3c2.5 0 4.5-2 4.5-4.5v-15c-.1-.6-.5-1-1.1-1" />
    <path d="M28 39v-.3c-.1-2.6-2.3-4.8-5-4.9v-1.5c0-.6-.4-1-1-1s-1 .4-1 1v1.5h-3.1v-1.5c0-.6-.4-1-1-1s-1 .4-1 1v1.5h-2.2c-.6 0-1 .4-1 1s.4 1 1 1h.3v13.8h-.3c-.6 0-1 .4-1 1s.4 1 1 1h2.2v1.6c0 .6.4 1 1 1s1-.4 1-1v-1.6H21v1.6c0 .6.4 1 1 1s1-.4 1-1v-1.6c2.7-.1 4.9-2.3 5-4.9v-.4c-.1-1.5-.8-2.8-2-3.6 1.2-.9 2-2.2 2-3.7m-2-.3v.2c0 1.5-1.4 2.7-3 2.7h-7v-5.9h6.8c1.7 0 3.2 1.3 3.2 3m-3 4.9c1.6 0 2.9 1.2 2.9 2.7v.2c-.1 1.7-1.5 3-3.3 3H16v-5.9z" />
  </svg>
);
export default IconDigitalCurrency;
