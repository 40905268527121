import * as React from "react";
const IconNecklace = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.6 36.6-5.9-4.3V21.1c0-.1 0-.2-.1-.3 4.6-7.9 5.3-16.3 5.3-16.5 0-.6-.4-1-.9-1.1-.5 0-1 .4-1.1.9-.1 1.1-2.2 28-26.9 28h-.4C20.5 32 14 26.7 10.2 20.7c-.1-.1-.1-.3-.2-.4C5.5 13 5.1 4.8 5 4.2c0-.6-.5-1-1-1-.6 0-1 .5-1 1 0 .2.5 8.9 5.3 16.9V33l-5.9 4.3c-.3.2-.5.7-.4 1.1l2.4 7.4c.1.4.5.7 1 .7h7.8c.4 0 .8-.3 1-.7l2.4-7.4c.1-.4 0-.9-.4-1.1L10.3 33v-8.9c4.1 5.3 10.6 9.7 20.7 10v11.7c-.5-.2-1.1-.3-1.7-.3-1.4 0-2.7.5-3.8 1.5s-1.7 2.4-1.8 3.9c0 1.6.6 3.2 1.7 4.4l4.9 4.9c.4.4 1 .6 1.6.6s1.1-.2 1.6-.6l5-5c1.1-1.1 1.6-2.5 1.6-4s-.6-2.9-1.6-4c-1.5-1.5-3.6-2-5.6-1.4V34.1c10.1-.3 16.6-4.9 20.7-10.4v8.7l-5.9 4.3c-.4.3-.5.7-.4 1.1l2.4 7.4c.1.4.5.7 1 .7h7.8c.4 0 .8-.3 1-.7l2.4-7.4c.2-.5 0-.9-.3-1.2m-49.1 7.9H6.1l-2-6 5.1-3.7 5.1 3.7zm24.6 4.1c.7.7 1.1 1.6 1.1 2.6s-.4 1.9-1.1 2.6l-5 5q-.15.15-.3 0l-4.8-5c-.8-.8-1.2-1.8-1.2-2.9 0-1 .4-1.9 1.1-2.5q1.05-.9 2.4-.9c.7 0 1.4.2 2 .6.3.2.8.2 1.1 0 1.6-1 3.5-.8 4.7.5m20.8-4.8h-6.3l-2-6 4.5-3.3c.2.1.4.2.6.2s.4-.1.6-.2l4.5 3.3z" />
  </svg>
);
export default IconNecklace;
