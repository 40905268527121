import * as React from "react";
const IconCustomerCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.2 47.2c-1.1-3.5-4.6-5.5-7.9-4.6-4.2 1.2-10.2 3-13.7 4 1.2-1.9 1.1-3.3.7-4.3-.7-1.8-2.8-2.6-4.6-2.6H24q-1.65 0-3.3-1.5c-.9-.8-2-1.2-3.2-1.2H9.7c-2.9 0-5.2 2.4-5.2 5.5v12.6c0 3.1 2.4 5.6 5.3 5.6h9.5c1.2 0 2.4-.4 3.3-1.2l.7.4c2.6 1.4 5.6 2.2 8.6 2.2 1.8 0 3.6-.3 5.3-.8l17.7-5.4c4.5-1.8 5.2-5.6 4.3-8.7m-38.6-6.4v17.6c-.4.2-.9.3-1.4.3H9.8c-1.8 0-3.3-1.6-3.3-3.6V42.5c0-1.9 1.4-3.5 3.2-3.5h7.9c.7 0 1.3.2 1.8.7.4.5.8.8 1.2 1.1m33.6 13.1-17.7 5.4c-1.5.5-3 .7-4.7.7-2.7 0-5.3-.7-7.6-2l-1.3-.7-.3-.1V41.6c.4.1.9.2 1.3.2h9.8c1.1 0 2.4.5 2.7 1.4.4 1.1-.4 2.7-2.4 4.6l-.6.5.4.7c.4.8.4.8 9.6-1.9 4.2-1.2 8.4-2.5 8.4-2.5 2.3-.6 4.7.8 5.4 3.2.5 1.3.9 4.6-3 6.1M50.5 14.6c3.5 0 6.3-2.8 6.3-6.3S54 2 50.5 2s-6.3 2.8-6.3 6.3 2.9 6.3 6.3 6.3m0-10.6c2.4 0 4.3 1.9 4.3 4.3s-1.9 4.3-4.3 4.3-4.3-1.9-4.3-4.3 2-4.3 4.3-4.3M48.2 36.4h3.3c3.4 0 6.2-2.8 6.2-6.2v-5.7c0-3.4-2.8-6.2-6.2-6.2h-3c-3.4 0-6.2 2.8-6.2 6.2v5.6c0 3.5 2.7 6.3 5.9 6.3m-4-12c0-2.3 1.9-4.2 4.2-4.2h3c2.3 0 4.2 1.9 4.2 4.2v5.7c0 2.3-1.9 4.2-4.2 4.2h-3.3c-2.1 0-3.9-1.9-3.9-4.3z" />
  </svg>
);
export default IconCustomerCopy;
