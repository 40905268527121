import React, { useState, useEffect } from 'react';
import { BringzzText, BringzzSwitch } from "@bringzz/components";
import { checkAppTrackingPermission, requestAppTrackingPermission, disableAppTracking } from 'tracking';
import useRequest from 'hooks/useRequest';

const TrackingSwitch = () => {
    const [isAppTrackingEnabled, setIsAppTrackingEnabled] = useState(false);
    const { sendRequest, loading } = useRequest();

    useEffect(() => {
        const checkPermission = async () => {
            const appTrackingEnabled = await checkAppTrackingPermission();
            setIsAppTrackingEnabled(appTrackingEnabled);
        };
        checkPermission();
    }, []);

    const handleEnableAppTracking = async () => {
        const result = await requestAppTrackingPermission();
        setIsAppTrackingEnabled(result);
        if (result) {
            await sendRequest({
                method: 'POST',
                url: `/settings/app/tracking`,
                data: { webAppTracking: true }
            }).then((response) => {
                if (response.status === 'success') {
                    // TODO: Toast
                }
            }).catch((error) => {
                console.error(error);
            })
        }
        return result;
    };

    const handleDisableAppTracking = async () => {
        const result = await disableAppTracking();
        setIsAppTrackingEnabled(!result);
        if (!result) {
            await sendRequest({
                method: 'POST',
                url: `/settings/app/tracking`,
                data: { webAppTracking: false }
            }).then((response) => {
                if (response.status === 'success') {
                    // TODO: Toast
                }
            }).catch((error) => {
                console.error(error);
            })
        }
        return result;
    };

    return (
        <div className='flex py-2 justify-between items-center'>
            <div>
                <BringzzText tag='h4' className="font-bold">App tracking</BringzzText>
                <BringzzText tag='h4' className="font-normal text-midnight-blue/60">This helps us personalise your experience.</BringzzText>
            </div>
            <div>
                <BringzzSwitch
                    defaultValue={isAppTrackingEnabled}
                    onEnable={handleEnableAppTracking}
                    onDisable={handleDisableAppTracking}
                />
            </div>
        </div>
    );
};

export default TrackingSwitch;
