import * as React from "react";
const IconStar = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 14 14"
    {...props}
  >
    <path
      
      d="m14 4.992-4.766-.678a.13.13 0 0 1-.102-.075L6.998 0l-2.13 4.24a.14.14 0 0 1-.102.074L0 4.992l3.45 3.3a.14.14 0 0 1 .04.118l-.819 4.657 4.266-2.202a.16.16 0 0 1 .126 0l4.265 2.198-.814-4.66a.13.13 0 0 1 .04-.118z"
    />
  </svg>
);
export default IconStar;
