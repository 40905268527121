import * as React from "react";
const IconBook2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M31.1 39.8c.6 0 1-.4 1-1V21.1c.1.1.3.2.4.4l4.9 7c.3.5.9.6 1.4.2.5-.3.6-.9.2-1.4l-4.9-7c-.7-1-1.8-1.6-3-1.6s-2.3.6-3 1.6l-4.9 7c-.3.5-.2 1.1.2 1.4.2.1.4.2.6.2.3 0 .6-.1.8-.4l4.9-7c.1-.1.2-.3.4-.4v17.7c0 .6.5 1 1 1M60.3 31.5c-.5-.3-1.1-.2-1.4.2l-4.9 7c-.1.1-.2.3-.4.4V21.3c0-.6-.4-1-1-1s-1 .4-1 1V39c-.1-.1-.3-.2-.4-.4l-4.9-7c-.3-.5-.9-.6-1.4-.2-.5.3-.6.9-.2 1.4l4.9 7c.7 1 1.8 1.6 3 1.6s2.3-.6 3-1.6l4.9-7c.4-.4.2-1-.2-1.3" />
    <path d="M8.8 52.9h44.1c.6 0 1-.4 1-1s-.4-1-1-1H15.4V10.8c0-.6-.4-1-1-1s-1 .4-1 1v40.1H8.8c-1.3 0-2.6.5-3.5 1.3v-46C5.3 5 6.3 4 7.5 4h42.1c1.2 0 2.2 1 2.2 2.2v4.3c0 .6.4 1 1 1s1-.4 1-1V6.2C53.9 3.9 52 2 49.7 2H7.5C5.2 2 3.3 3.9 3.3 6.2v50.6c.2 2.9 2.6 5.2 5.5 5.2h44.1c.6 0 1-.4 1-1s-.4-1-1-1H8.8c-2 0-3.5-1.6-3.5-3.5s1.6-3.6 3.5-3.6" />
  </svg>
);
export default IconBook2;
