import * as React from "react";
const IconGrowHelp1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.7 47.6c-1.1-3.4-4.5-5.4-7.7-4.4-4 1.2-9.8 2.9-13.2 3.9 1.1-1.8 1.1-3.2.7-4.1-.7-1.8-2.8-2.6-4.5-2.6h-9.5c-1.1 0-2.1-.5-3.1-1.4-.9-.8-2-1.2-3.1-1.2H7.6c-2.8 0-5.1 2.4-5.1 5.3v12.2c0 3 2.3 5.4 5.2 5.4h9.2c1.1 0 2.3-.4 3.2-1.2l.6.3c2.5 1.4 5.4 2.1 8.3 2.1 1.8 0 3.5-.3 5.1-.8L51.5 56c4.4-1.7 5-5.4 4.2-8.4m-48 11.1c-1.8 0-3.2-1.5-3.2-3.4V43.1c0-1.8 1.4-3.3 3.1-3.3h7.7c.7 0 1.3.2 1.7.7q.6.6 1.2.9v17c-.4.2-.9.3-1.3.3zm43.1-4.6-17.2 5.2c-1.4.5-3 .7-4.5.7-2.6 0-5.1-.7-7.4-1.9l-1.3-.7-.3-.1V42.2c.4.1.8.2 1.3.2H31c1 0 2.3.5 2.6 1.3.4 1-.4 2.6-2.3 4.4l-.6.5.4.7c.4.8.4.8 9.3-1.8 4.1-1.2 8.1-2.4 8.1-2.4 2.2-.6 4.5.7 5.2 3.1.5 1.3.9 4.5-2.9 5.9M61.4 2.8c-.1-.3-.5-.6-.8-.6-4.3-.4-9.2-.5-13.2 3.4-1.3 1.3-2.1 3.2-2.1 5.2 0 1.8.6 3.4 1.6 4.5h-.1c-4 2.9-6.7 6.7-8 11.3-1.2-4.2-3.7-7.8-7.2-10.6.2-.1.3-.3.4-.4 1.2-1.2 1.9-2.9 1.8-4.8 0-2-.8-4-2.1-5.2-4-3.8-8.9-3.7-13.2-3.4-.4 0-.7.3-.8.6s-.1.7.1 1c1.5 2.1 2 4.2 2.4 6.2s.8 3.9 2.2 5.3c1.3 1.3 3.2 2 5.2 2q1.05 0 2.1-.3c5.4 4 8.1 9.6 8.1 16.9 0 .6.4 1 1 1s1-.4 1-1c0-7.3 2.8-13 8.2-17 .2-.1.3-.3.3-.5 1 .6 2.1.8 3.2.8 1.9 0 3.8-.8 5.2-2 1.5-1.4 1.8-3.3 2.2-5.3.4-2.1.8-4.2 2.4-6.2.2-.2.3-.6.1-.9m-37.6 11c-1-1-1.3-2.4-1.7-4.2-.2-1.7-.6-3.6-1.7-5.6 3.4-.2 7.1.1 10 2.9.9.9 1.5 2.3 1.5 3.8 0 1.3-.4 2.5-1.3 3.4-1.9 1.9-5 1.4-6.8-.3M57 9.6c-.4 1.8-.6 3.3-1.6 4.2-1.7 1.7-4.9 2.2-6.8.3-.8-.8-1.3-2-1.3-3.4 0-1.5.6-2.9 1.5-3.8 2.5-2.4 5.5-3 8.5-3h1.4c-1 2.1-1.4 4-1.7 5.7" />
  </svg>
);
export default IconGrowHelp1;
