import * as React from "react";
const IconHome = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      d="M24.507 10.138a1 1 0 0 0-1.014 0L5.631 20.645l1.014 1.724L24 12.16 41.355 22.37l1.014-1.724L36 16.9V12a1 1 0 0 0-1-1h-3a1 1 0 0 0-1 1v1.957zM14 25h11v6H14z"
    />
    <path
      
      fillRule="evenodd"
      d="m24 14-14 8v14H5a1 1 0 1 0 0 2h36a1 1 0 1 0 0-2h-3V22zm0 2.303-12 6.858V36h16V25h6v11h2V23.16z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconHome;
