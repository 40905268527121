import * as React from "react";
const IconTeeth = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 17C12.5 17 3 21.6 3 31.2v4.1c0 2.7 1.5 5.2 3.8 6.6l.2.1h4.2v2c0 1.7 1.3 3 3 3h9.2c.8 0 1.5-.3 2-.8.5.5 1.2.8 2 .8h9.2c.8 0 1.5-.3 2-.8.5.5 1.2.8 2 .8h9.2c1.7 0 3-1.3 3-3v-2H57l.2-.1c2.3-1.4 3.8-3.9 3.8-6.6v-4.1C61 21.6 51.5 17 32 17m-8.6 28h-9.2c-.6 0-1-.4-1-1v-7.9c0-3.1 2.5-5.6 5.6-5.6s5.6 2.5 5.6 5.6V44c0 .6-.4 1-1 1m13.2 0h-9.2c-.6 0-1-.4-1-1v-7.9c0-3.1 2.5-5.6 5.6-5.6s5.6 2.5 5.6 5.6V44c0 .6-.5 1-1 1m14.2-1c0 .6-.4 1-1 1h-9.2c-.6 0-1-.4-1-1v-7.9c0-3.1 2.5-5.6 5.6-5.6s5.6 2.5 5.6 5.6zm8.2-8.7c0 1.9-1 3.7-2.6 4.7h-3.7v-3.9c0-4.2-3.4-7.6-7.6-7.6-2.8 0-5.3 1.5-6.6 3.8-1.3-2.3-3.8-3.8-6.6-3.8s-5.3 1.5-6.6 3.8c-1.3-2.3-3.8-3.8-6.6-3.8-4.2 0-7.6 3.4-7.6 7.6V40H7.6C6 39 5 37.2 5 35.3v-4.1C5 23.1 14.1 19 32 19s27 4.1 27 12.2z" />
  </svg>
);
export default IconTeeth;
