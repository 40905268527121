import * as React from "react";
const IconFlipFlop = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.4 36c-1.1-1.5-2.4-2.9-3.7-4.1-.5-.5-1.1-1.1-1.6-1.7-.1-.1-.2-.2-.3-.4l-.1-.1c-2.6-2.8-4.8-6-7-9-1.7-2.4-3.5-4.9-5.5-7.3C35.4 6.6 29.3 2.8 22.9 2.1 18.1 1.6 13.1 3 9.3 6c-.6.5-1.2 1-1.7 1.6-.5.5-1 1-1.6 1.7-3 3.8-4.4 8.8-3.9 13.7.7 6.4 4.5 12.5 11.4 18.2 2.3 1.9 4.8 3.7 7.3 5.5 3.1 2.2 6.3 4.5 9.1 7.1.1.1.3.2.4.4.6.5 1.1 1.1 1.7 1.6 1.3 1.3 2.6 2.6 4.1 3.7 2.5 1.8 5.4 2.6 8.2 2.6 4.7 0 9.4-2.1 12.6-5.2 4.8-5.2 7.4-14.2 2.5-20.9m-44.7 3.6c-6.5-5.4-10-11-10.7-16.9-.5-4.3.8-8.8 3.5-12.2.6-.6 1-1.1 1.5-1.5.5-.5 1-.9 1.5-1.4 3.4-2.7 7.9-4 12.2-3.5 5.8.6 11.5 4.2 16.9 10.7 1.9 2.3 3.7 4.8 5.4 7.2 1.1 1.6 2.3 3.2 3.5 4.8-8.3-4.4-17.3-6.3-25.8-5.4L20.4 19c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l2.3 2.3c-.9 8.4 1 17.4 5.3 25.7-1.6-1.2-3.2-2.4-4.8-3.5-2.3-1.6-4.8-3.4-7.1-5.3m40.7 15.8C50.9 59.7 43 62 37.2 57.8c-1.4-1-2.6-2.2-3.9-3.5-.6-.6-1.1-1.1-1.7-1.6-.1-.1-.2-.2-.4-.3-6.1-9.2-8.9-19.5-8-29 9.6-.9 19.9 1.9 29 8 .1.1.2.2.3.4.5.6 1.1 1.1 1.6 1.7 1.3 1.3 2.5 2.5 3.5 3.9 4.4 5.6 2.1 13.5-2.2 18" />
  </svg>
);
export default IconFlipFlop;
