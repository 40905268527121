import * as React from "react";
const IconTimeline = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m59.5 31.3-7.8-8.1c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l6.1 6.3h-17V20c2.4-.5 4.2-2.6 4.2-5.1 0-2.8-2.3-5.2-5.2-5.2s-5.2 2.3-5.2 5.2c0 2.5 1.8 4.6 4.2 5.1v11h-26V20c2.4-.5 4.2-2.6 4.2-5.1 0-2.8-2.3-5.2-5.2-5.2S5.2 12.1 5.2 15c0 2.5 1.8 4.6 4.2 5.1v11H5.2c-.6 0-1 .4-1 1s.4 1 1 1h18v11c-2.4.3-4.2 2.4-4.2 4.9 0 2.8 2.3 5.2 5.2 5.2s5.2-2.3 5.2-5.2c0-2.5-1.8-4.6-4.2-5.1V33h31.2l-6.2 6.3c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3l7.8-8c.5-.4.5-1 .1-1.4M35.2 15c0-1.7 1.4-3.2 3.2-3.2s3.2 1.4 3.2 3.2-1.4 3.2-3.2 3.2-3.2-1.5-3.2-3.2m-28 0c0-1.7 1.4-3.2 3.2-3.2 1.7 0 3.2 1.4 3.2 3.2s-1.4 3.2-3.2 3.2c-1.8-.1-3.2-1.5-3.2-3.2m20.1 34c0 1.7-1.4 3.2-3.2 3.2-1.7 0-3.2-1.4-3.2-3.2 0-1.7 1.4-3.2 3.2-3.2 1.8.1 3.2 1.5 3.2 3.2" />
  </svg>
);
export default IconTimeline;
