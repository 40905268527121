import * as React from "react";
const IconPersonalFile = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M34.7 39.6c1.6-.9 2.7-2.7 2.7-4.7 0-3-2.4-5.4-5.4-5.4s-5.4 2.4-5.4 5.4c0 2 1.1 3.7 2.7 4.7-1.6.5-3.1 1.5-4.3 2.9-1.8 2.1-2.8 5.1-2.9 8.3q0 .6.6.9c1.1.6 5.1 2.4 9.3 2.4 4.6 0 8.3-1.8 9.3-2.4.3-.2.5-.5.5-.9 0-3.2-1.1-6.1-2.9-8.3-1.1-1.4-2.5-2.4-4.2-2.9M32 31.5c1.9 0 3.4 1.5 3.4 3.4s-1.5 3.4-3.4 3.4-3.4-1.5-3.4-3.4 1.5-3.4 3.4-3.4m0 20.6c-3.3 0-6.5-1.3-7.8-1.9.2-2.5 1-4.7 2.4-6.4s3.4-2.7 5.5-2.7 4 .9 5.5 2.7c1.4 1.6 2.2 3.9 2.4 6.4-1.4.6-4.5 1.9-8 1.9" />
    <path d="M52.8 20.8 34.1 3.2c-.8-.8-1.9-1.2-3-1.2h-16c-2.9 0-5.2 2.3-5.2 5.2v49.6c0 2.9 2.3 5.2 5.2 5.2h33.8c2.9 0 5.2-2.3 5.2-5.2v-33c0-1.2-.5-2.3-1.3-3m-2.1.7H35.3c-1.3 0-2.3-1-2.3-2.2V4.9zm1.4 35.3c0 1.8-1.4 3.2-3.2 3.2H15.1c-1.8 0-3.2-1.4-3.2-3.2V7.2c0-1.8 1.4-3.2 3.2-3.2H31v15.4c0 2.3 1.9 4.2 4.3 4.2h16.8v33.2" />
  </svg>
);
export default IconPersonalFile;
