import * as React from "react";
const IconPilcrowCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.4 2H28.2c-8.6 0-15.5 7-15.5 15.5S19.7 33 28.2 33v-2c-7.5 0-13.5-6.1-13.5-13.5-.1-7.4 6-13.5 13.5-13.5v57c0 .6.4 1 1 1s1-.4 1-1V4h11.4v57c0 .6.4 1 1 1s1-.4 1-1V4h6.8c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconPilcrowCopy;
