import * as React from "react";
const IconRocketLaunch3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.6 21h-1.5c-.6 0-1 .4-1 1s.4 1 1 1h1.5c1.7 0 3.1 1.4 3.1 3.1v22.8c0 .9-.8 1.7-1.7 1.7H12c-.9 0-1.7-.8-1.7-1.7V26.1c0-1.7 1.4-3.1 3.1-3.1h.8c.6 0 1-.4 1-1s-.4-1-1-1h-.8c-2.8 0-5.1 2.3-5.1 5.1v22.8c0 2 1.7 3.7 3.7 3.7h15.3L26.2 60h-6c-.6 0-1 .4-1 1s.4 1 1 1h23.6c.6 0 1-.4 1-1s-.4-1-1-1h-6l-1.1-7.4H52c2 0 3.7-1.7 3.7-3.7V26.1c0-2.8-2.3-5.1-5.1-5.1M35.8 60h-7.5l1.1-7.4h5.4z" />
    <path d="M18.5 37.7c.2.1.3.1.5.1s.3 0 .5-.1l5.3-2.9v1.6c0 .6.4 1 1 1h12.4c.6 0 1-.4 1-1v-1.6l5.3 2.9c.1.1.3.1.5.1s.4 0 .5-.1c.3-.2.5-.5.5-.9V31c0-2.2-1.2-4.1-3.1-5.2l-3.7-2V20c0-10.5-3.3-14.9-6.5-17.7-.4-.3-.9-.3-1.3 0-3.2 2.8-6.5 7.2-6.6 17.7v3.9l-3.7 2c-1.9 1-3.1 3-3.1 5.2v5.8c0 .3.2.6.5.8m23.5-10c1.2.7 2 2 2 3.4v4.1l-4.8-2.6v-6.4zM26.8 20c0-9.1 2.4-13 5.2-15.6C34.8 7 37.2 11 37.2 20v15.5H26.8V20M20 31.1c0-1.4.8-2.7 2-3.4l2.8-1.5v6.4L20 35.1z" />
  </svg>
);
export default IconRocketLaunch3;
