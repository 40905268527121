import * as React from "react";
const IconFilesAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.3 7.3h-18l-2.9-3.8c-.7-.9-1.8-1.5-3-1.5h-21c-2.1 0-3.8 1.7-3.8 3.8v9.4H6.7c-2 0-3.7 1.7-3.7 3.8v39.2C3 60.3 4.7 62 6.7 62h50.5c2.1 0 3.8-1.7 3.8-3.8V11.1c0-2.1-1.7-3.8-3.7-3.8M10.6 5.8c0-1 .8-1.8 1.8-1.8h21c.6 0 1.1.3 1.4.7L38 8.9c.2.2.5.4.8.4h18.5c1 0 1.8.8 1.8 1.8v14.7H33.8l-3.5-8.2c-.6-1.4-2-2.3-3.5-2.3H10.6zM59 58.2c0 1-.8 1.8-1.8 1.8H6.7c-1 0-1.8-.8-1.8-1.8V19c0-1 .8-1.8 1.8-1.8h20c.7 0 1.4.4 1.6 1.1l3.8 8.8q.3.6.9.6h26v30.5" />
  </svg>
);
export default IconFilesAlt;
