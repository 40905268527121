import * as React from "react";
const IconUnsplash = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M43.6 28.5H63V63H1V28.5h19.4v17.2h23V28.5zM43.6 1H20.4v17.3h23V1z" />
  </svg>
);
export default IconUnsplash;
