import * as React from "react";
const IconDirectionRtlCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M52.2 3H21.6c-6.1 0-10.9 4.8-10.9 10.8s4.9 10.9 10.9 10.9H24v15.1c0 .6.4 1 1 1s1-.4 1-1V5h10v34.8c0 .6.4 1 1 1s1-.4 1-1V5h14.2c.6 0 1-.4 1-1s-.4-1-1-1M21.7 22.7c-4.9 0-8.9-4-8.9-8.9S16.7 5 21.7 5h2.4v17.6c-.5.1-1.3.1-2.4.1M51.7 52.2H16.1l4.9-5c.4-.4.4-1 0-1.4s-1-.4-1.4 0L13 52.6c-.4.4-.4 1 0 1.4l6.6 6.7c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-5-5.1h35.6c.6 0 1-.4 1-1s-.3-1-.9-1" />
  </svg>
);
export default IconDirectionRtlCopy;
