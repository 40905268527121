import * as React from "react";
const IconHutAlt4 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.3 21.3-13.8-4.9c0-.1.1-.2.1-.3V8.3c0-.6-.4-1-1-1s-1 .4-1 1v7.4L23.9 8c-1.5-.5-3.1-.3-4.4.7L2.4 21.4c-.4.3-.5 1-.2 1.4s1 .5 1.4.2L8 19.7V52c0 2.6 2.1 4.7 4.7 4.7h35.2c2.6 0 4.7-2.1 4.7-4.7V20.3l8 2.8c.1 0 .2.1.3.1.4 0 .8-.3.9-.7.3-.5.1-1-.5-1.2M50.6 52c0 1.5-1.2 2.7-2.7 2.7H12.7c-1.5 0-2.7-1.2-2.7-2.7V18.2l10.7-8c.7-.5 1.7-.7 2.5-.4l27.4 9.7z" />
    <path d="M16.7 27v6.6c0 .6.4 1 1 1h6.6c.6 0 1-.4 1-1V27c0-.6-.4-1-1-1h-6.6c-.6 0-1 .5-1 1m2 1h4.6v4.6h-4.6zM35 34.6h6.6c.6 0 1-.4 1-1V27c0-.6-.4-1-1-1H35c-.6 0-1 .4-1 1v6.6c0 .6.4 1 1 1m1-6.6h4.6v4.6H36zM24.3 41h-6.6c-.6 0-1 .4-1 1v6.6c0 .6.4 1 1 1h6.6c.6 0 1-.4 1-1V42c0-.6-.4-1-1-1m-1 6.6h-4.6V43h4.6zM35 49.6h6.6c.6 0 1-.4 1-1V42c0-.6-.4-1-1-1H35c-.6 0-1 .4-1 1v6.6c0 .5.4 1 1 1m1-6.6h4.6v4.6H36z" />
  </svg>
);
export default IconHutAlt4;
