import * as React from "react";
const IconLungs = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M44.6 33.8c-.5 0-1 0-1.5.1-.4 0-.8.3-.9.7-.4 1.4-.7 2.7-.9 4.1l-8.1-8.1V3c0-.6-.4-1-1-1s-1 .4-1 1v27.7l-8.5 8.5c-.3-1.5-.6-3-1-4.5-.1-.4-.5-.7-.9-.7-.5 0-1-.1-1.5-.1C10.8 33.9 4 40.2 4 48s6.9 14.1 15.3 14.1c.6 0 1.2 0 1.7-.1.4 0 .8-.3.9-.8 1.6-6.6 2-13.2 1.2-19.6l9.1-9.1 8.7 8.7c-.9 6.5-.5 13.2 1.1 20 .1.4.4.7.9.8h1.7C53.1 62 60 55.7 60 47.9c0-7.7-6.9-14.1-15.4-14.1M20.2 60h-.8C12 60 6.1 54.6 6.1 47.9s6-12.1 13.3-12.1h.6c2 7.9 2.1 16 .2 24.2m24.4 0h-.8c-1.9-8.2-1.8-16.3.2-24.2h.6C52 35.8 58 41.3 58 47.9 58 54.6 52 60 44.6 60" />
  </svg>
);
export default IconLungs;
