// More text styles will be defined once we get access to the figma board.
// TODO: Add black font-weight file and add black font-weight styles
export const TEXTSTYLES = {
  PAGETITLE: {
    tag: "h1",
    classes: "font-bold text-7xl",
  },
  SUBTITLE: {
    tag: "h2",
    classes: "font-bold text-3xl",
  },
  STARRATINGDOTSEPARATORBLUE: {
    tag: "span",
    classes:
      "font-bold text-4xl leading-4 align-middle mb-2 mx-2 text-secondary",
  },
  STARRATINGDOTSEPARATORBLACK: {
    tag: "span",
    classes: "font-bold text-4xl leading-4 align-middle mb-2 mx-2",
  },
  STARRATINGFULLSMALLRATING: {
    tag: "span",
    classes: "font-bold text-sm text-secondary",
  },
  STARRATINGFULLSMALLCOUNT: {
    tag: "span",
    classes: "font-bold text-sm text-secondary",
  },
  STARRATINGFULLBIGRATING: {
    tag: "span",
    classes: "font-bold text-xl",
  },
  STARRATINGFULLBIGCOUNT: {
    tag: "span",
    classes: "text-sm text-slate-500",
  },
  STARRATINGROUNDEDRATING: {
    tag: "span",
    classes: "font-bold text-sm text-secondary",
  },
  STARRATINGROUNDEDCOUNT: {
    tag: "span",
    classes: "text-sm font-medium text-secondary",
  },
  CATEGORYBOXLABEL: {
    tag: "p",
    classes: "font-bold text-sm",
  },
  COMMENTTITLE: {
    tag: "p",
    classes: "font-bold text-sm font-bold",
  },
  COMMENTINFO: {
    tag: "p",
    classes: "font-bold text-neutral-700 text-sm",
  },
  COMMENTTEXT: {
    tag: "p",
    classes: "text-neutral-700 text-sm font-light",
  },
  INFOBOXTITLE: {
    tag: "p",
    classes: "text-neutral-700 text-sm font-bold",
  },
  INFOBOXSUBTITLE: {
    tag: "p",
    classes: "text-neutral-700 text-sm",
  },
  PRODUCTBOXMANUFACTURER: {
    tag: "p",
    classes: "text-neutral-700 text-xs",
  },
  PRODUCTBOXLABEL: {
    tag: "p",
    classes: "text-black text-md",
  },
  PRODUCTBOXTEXT: {
    tag: "p",
    classes: "text-neutral-700 text-sm",
  },
  PRODUCTBOXPRICE: {
    tag: "p",
    classes: "text-black font-semibold text-md",
  },
  HEADLINE3REGULAR: {
    tag: "h3",
    classes: "text-dark font-normal text-[42px]",
  },
  HEADLINE3MEDIUM: {
    tag: "h3",
    classes: "text-dark font-medium text-[42px]",
  },
  HEADLINE4REGULAR: {
    tag: "h4",
    classes: "text-dark font-normal text-[32px]",
  },
  HEADLINE4MEDIUM: {
    tag: "h4",
    classes: "text-dark font-medium text-[32px]",
  },
  HEADLINE5REGULAR: {
    tag: "h5",
    classes: "text-dark font-normal text-[26px]",
  },
  HEADLINE5MEDIUM: {
    tag: "h5",
    classes: "text-dark font-medium text-[26px]",
  },
  HEADLINE5BOLD: {
    tag: "h5",
    classes: "text-dark font-bold text-[26px]",
  },
  HEADLINE5HEAVY: {
    tag: "h5",
    classes: "text-dark font-heavy text-[26px]",
  },
  HEADLINE6REGULAR: {
    tag: "h6",
    classes: "text-dark font-medium text-[20px]",
  },
  HEADLINE6MEDIUM: {
    tag: "h6",
    classes: "text-dark font-medium text-[20px]",
  },
  HEADLINE7SEMIBOLD: {
    tag: "h6",
    classes: "text-dark font-semibold text-[17px]",
  },
  SUBTITLE1REGULAR: {
    tag: "p",
    classes: "text-dark font-normal text-[16px]",
  },
  SUBTITLE1REGULAR: {
    tag: "p",
    classes: "text-dark font-normal text-[16px]",
  },
  SUBTITLE1MEDIUM: {
    tag: "p",
    classes: "text-dark font-medium text-[16px]",
  },
  SUBTITLE1BOLD: {
    tag: "p",
    classes: "text-dark font-bold text-[16px]",
  },
  SUBTITLE2MEDIUM: {
    tag: "p",
    classes: "text-dark font-medium text-[14px]",
  },
  BODY1REGULAR: {
    tag: "p",
    classes: "text-dark font-normal text-[17px]",
  },
  BODY2MEDIUM: {
    tag: "p",
    classes: "text-dark font-medium text-[15px]",
  },
  BODY3REGULAR: {
    tag: "p",
    classes: "text-dark font-normal text-[13px]",
  },
  CAPTION: {
    tag: "span",
    classes: "text-dark font-normal text-[10px]",
  },
  OVERLINE: {
    tag: "span",
    classes: "text-dark font-bold text-[12px]",
  },
  BUTTON1MEDIUM: {
    tag: "span",
    classes: "text-dark font-medium text-[17px]",
  },
  BUTTON1BOLD: {
    tag: "span",
    classes: "text-dark font-bold text-[17px]",
  },
  BUTTON1HEAVY: {
    tag: "span",
    classes: "text-dark font-heavy text-[17px]",
  },
  BUTTON2BOLD: {
    tag: "span",
    classes: "text-dark font-bold text-[16px]",
  },
  BUTTON3REGULAR: {
    tag: "span",
    classes: "text-dark font-normal text-[14px]",
  },
  BUTTON3MEDIUM: {
    tag: "span",
    classes: "text-dark font-medium text-[14px]",
  },
  BUTTON3SEMIBOLD: {
    tag: "span",
    classes: "text-dark font-semibold text-[14px]",
  },
  LINK1BOLD: {
    tag: "span",
    classes: "text-dark font-bold text-[17px]",
  },
  LINK1HEAVY: {
    tag: "span",
    classes: "text-dark font-heavy text-[14px]",
  },
  LINK2BOLD: {
    tag: "span",
    classes: "text-dark font-bold text-[15px]",
  },
  LINK2HEAVY: {
    tag: "span",
    classes: "text-dark font-heavy text-[15px]",
  },
  LINK4BOLD: {
    tag: "span",
    classes: "text-dark font-bold text-[13px]",
  },
  LINK4HEAVY: {
    tag: "span",
    classes: "text-dark font-heavy text-[13px]",
  },
};
