import * as React from "react";
const IconFacebookLineCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M36.2 63h-7.8c-1.6 0-2.8-1.4-2.8-3.1V35.5h-7.1c-1.6 0-2.8-1.4-2.8-3.1v-6.9c0-1.7 1.3-3.1 2.8-3.1h7v-7.1C25.4 6.8 30.5 1 38.1 1H44c1.6 0 2.8 1.4 2.8 3.1v8.1c0 1.7-1.3 3.1-2.8 3.1h-4.1c-.7 0-.9.1-.9.1v7.1h6.5c1 0 1.8.4 2.3 1.1.6.7.8 1.7.6 2.6l-1.2 6.7c-.2 1.4-1.3 2.5-2.8 2.5H39V60c0 1.6-1.3 3-2.8 3M18.4 24.5c-.4 0-.8.5-.8 1.1v6.9c0 .6.4 1.1.8 1.1h8.1c.6 0 1 .4 1 1v25.5c0 .6.4 1.1.8 1.1h7.8c.4 0 .8-.5.8-1.1V34.3c0-.6.4-1 1-1h6.3c.4 0 .8-.3.8-.8l1.2-6.8c.1-.4 0-.8-.2-1s-.5-.4-.8-.4h-7.4c-.6 0-1-.4-1-1v-7.1c0-2 .5-3.1 2.9-3.1H44c.4 0 .8-.5.8-1.1V4.1c0-.6-.3-1.1-.8-1.1h-5.9c-6.5 0-10.7 4.9-10.7 12.4v8.1c0 .6-.4 1-1 1z" />
  </svg>
);
export default IconFacebookLineCopy;
