import * as React from "react";
const IconEuroCoinStack = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 43.9c7.3 0 12.5-2.9 12.5-7 0-4-5.3-7-12.5-7s-12.5 2.9-12.5 7 5.2 7 12.5 7M32 32c5.9 0 10.5 2.2 10.5 5s-4.6 5-10.5 5-10.5-2.2-10.5-5 4.6-5 10.5-5M43.7 46.3c-.5-.2-1.1.1-1.3.6-.4 1.1-1.7 2.1-3.6 2.9-1.9.7-4.3 1.1-6.8 1.1-5 0-9.2-1.6-10.3-3.9-.2-.5-.8-.7-1.3-.5s-.7.8-.5 1.3c1.4 3 6.2 5 12.1 5 2.7 0 5.4-.5 7.5-1.3 2.5-1 4.1-2.4 4.8-4.1.2-.4-.1-1-.6-1.1M43.7 55.3c-.5-.2-1.1.1-1.3.6-.4 1.2-1.7 2.2-3.6 2.9-1.9.8-4.3 1.2-6.8 1.2-5 0-9.2-1.6-10.3-3.9-.2-.5-.8-.7-1.3-.5s-.7.8-.5 1.3c1.4 3 6.2 5 12.1 5 2.7 0 5.4-.5 7.5-1.3 2.5-1 4.1-2.4 4.8-4.1.2-.4-.1-1-.6-1.2M21.8 17.3c0 .6.4 1 1 1h2.4c1.8 4.3 6.1 7.4 11 7.4.6 0 1-.4 1-1s-.4-1-1-1c-3.8 0-7.1-2.2-8.8-5.4h5.3c.6 0 1-.4 1-1s-.4-1-1-1h-6.1q-.3-1.2-.3-2.4c0-1.2.1-1.6.3-2.4h6.1c.6 0 1-.4 1-1s-.4-1-1-1h-5.3C29 6.2 32.3 4 36.1 4c.6 0 1-.4 1-1s-.4-1-1-1c-4.9 0-9.2 3.1-11 7.4h-2.4c-.6 0-1 .4-1 1s.4 1 1 1h1.7q-.3 1.2-.3 2.4c0 1.2.1 1.6.3 2.4h-1.7c-.5.1-.9.5-.9 1.1" />
  </svg>
);
export default IconEuroCoinStack;
