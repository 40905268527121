import * as React from "react";
const IconCouch = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 37.5h-1.5v-8.6c0-6.1-4.9-11-11-11H18.1v-4.3c0-3-2.4-5.4-5.4-5.4H7.4c-3 0-5.4 2.4-5.4 5.4v35.3c0 .6.4 1 1 1h4.9v4.8c0 .6.4 1 1 1s1-.4 1-1v-4.8h44.7v4.8c0 .6.4 1 1 1s1-.4 1-1v-4.8H61c.6 0 1-.4 1-1V38.5c0-.5-.4-1-1-1M48.5 19.9c5 0 9 4 9 9v8.6H18.1V19.9zM4 13.7c0-1.9 1.5-3.4 3.4-3.4h5.3c1.9 0 3.4 1.5 3.4 3.4v23.9H4zm56 34.2H4v-8.4h56z" />
  </svg>
);
export default IconCouch;
