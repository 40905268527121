import * as React from "react";
const IconUserBlock = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.1 37.6c-2.5-2.3-5.8-3.7-9.4-3.7-.7 0-1.4.1-2 .2-.1-.1-.2-.3-.3-.4-4.2-5-10-7.8-16.2-7.8 6.6 0 11.9-5.4 11.9-12s-5.4-12-12-12-12 5.4-12 12 5.4 12 11.9 12c-6.2 0-12 2.8-16.2 7.8S4.2 45.6 4.1 53.2q0 .6.6.9c2.7 1.3 12.2 5.7 22.4 5.7 3.1 0 6.3-.4 9.6-1.1 2.4 2 5.6 3.3 9 3.3 7.7 0 14-6.3 14-14 0-4.1-1.7-7.8-4.6-10.4m-2.1.8L37.4 56.6c-2.3-2.2-3.7-5.3-3.7-8.7 0-6.6 5.4-12 12-12 2.8 0 5.3 1 7.3 2.5M17.2 14c0-5.5 4.5-10 10-10s10 4.5 10 10-4.5 10-10 10c-5.5-.1-10-4.5-10-10M6.3 52.6c.2-6.9 2.4-13.1 6.2-17.6 3.9-4.6 9.1-7.1 14.7-7.1 5.4 0 10.5 2.4 14.3 6.7-5.7 1.8-9.8 7.1-9.8 13.4 0 3.5 1.3 6.6 3.3 9.1-2.7.5-5.3.8-7.9.8-9-.1-17.6-3.8-20.8-5.3M45.7 60c-2.5 0-4.8-.8-6.8-2.1l15.6-18.1c2 2.1 3.2 5 3.2 8.2 0 6.6-5.4 12-12 12" />
  </svg>
);
export default IconUserBlock;
