import * as React from "react";
const IconShekel = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
    <path d="M43.9 17.9c-.6 0-1 .4-1 1v24.9q0 .3-.3.3H32c-1.6 0-3-1.3-3-3V30c0-.6-.4-1-1-1s-1 .4-1 1v11.1c0 2.7 2.2 5 5 5h10.6c1.3 0 2.3-1 2.3-2.3V18.9c0-.6-.4-1-1-1" />
    <path d="M35 22.9V34c0 .6.4 1 1 1s1-.4 1-1V22.9c0-2.7-2.2-5-5-5H21.4c-1.3 0-2.3 1-2.3 2.3v24.9c0 .6.4 1 1 1s1-.4 1-1V20.2q0-.3.3-.3H32c1.6 0 3 1.3 3 3" />
  </svg>
);
export default IconShekel;
