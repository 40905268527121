import * as React from "react";
const IconBehanceCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M21.5 31.9c3-1.4 5.4-4.1 6.1-7.1.7-3.2 0-6.5-2.1-9-2-2.6-5.1-4-8.3-4H5.9C3.2 11.8 1 14 1 16.7v30.6c0 2.7 2.2 4.9 4.9 4.9h10.9c5.6 0 10.3-4 11-9.4.4-3.1-.6-6-2.6-8.3-1-1.1-2.3-2-3.7-2.6M3 16.6c0-1.6 1.3-2.9 2.9-2.9h11.3c2.6 0 5.1 1.2 6.8 3.3s2.3 4.7 1.7 7.4c-.8 3.6-4.9 6.7-8.8 6.7H3zm22.9 26c-.5 4.3-4.4 7.6-9 7.6h-11c-1.6 0-2.9-1.3-2.9-2.9V33h14.3c2.5 0 4.9 1.1 6.5 2.9s2.4 4.2 2.1 6.7M39.6 14.5h21.1c.6 0 1-.4 1-1s-.4-1-1-1H39.6c-.6 0-1 .4-1 1s.4 1 1 1M49.4 25c-7.5 0-13.6 6.1-13.6 13.6s6.1 13.6 13.6 13.6c3.3 0 6.5-1.2 9-3.4.4-.4.5-1 .1-1.4s-1-.5-1.4-.1c-2.1 1.9-4.8 2.9-7.7 2.9-6.1 0-11.1-4.7-11.6-10.6H62c.6 0 1-.4 1-1C63 31.2 56.9 25 49.4 25m0 2c6.1 0 11.1 4.7 11.6 10.6H37.8c.5-5.9 5.5-10.6 11.6-10.6" />
  </svg>
);
export default IconBehanceCopy;
