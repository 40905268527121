import * as React from "react";
const IconSignLanguageAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M31.3 62c-3.8 0-7.4-1.5-10.1-4.2L9.9 46.6c-1.2-1.2-1.8-2.7-1.8-4.3s.6-3.2 1.8-4.3c1.2-1.2 2.8-1.8 4.3-1.8-1.4-2.4-1.1-5.4 1-7.4 2-2 5.1-2.3 7.4-1 0-1.6.6-3.1 1.8-4.3 1.1-1.1 2.6-1.7 4.1-1.8l-9.1-9.1c-2.4-2.4-2.4-6.3 0-8.7s6.3-2.4 8.7 0L32 7.7l.4-.9c.7-1.5 1.9-2.6 3.5-3.1 1.5-.5 3.2-.4 4.6.3 3 1.5 4.3 5.1 2.8 8.1L41 16.8l10.6 10.6c5.6 5.6 5.6 14.6 0 20.2L41.4 57.8c-2.7 2.7-6.3 4.2-10.1 4.2m-17-23.9c-1.1 0-2.1.4-2.9 1.2s-1.2 1.8-1.2 2.9.4 2.1 1.2 2.9l11.3 11.3c2.3 2.3 5.4 3.6 8.7 3.6s6.4-1.3 8.7-3.6l10.3-10.3c4.8-4.8 4.8-12.6 0-17.4L26.7 5.2c-1.6-1.6-4.2-1.6-5.9 0-1.6 1.6-1.6 4.2 0 5.9L33 23.3l11.3 11.3c.4.4.4 1 0 1.4l-.1.1c-.2.2-.5.2-.7.2q-.3 0-.6-.3L31.7 24.7c-1.6-1.6-4.2-1.6-5.8 0s-1.6 4.2 0 5.9l11.3 11.3c.2.2.3.5.3.7s-.1.5-.3.7c-.4.4-1 .4-1.4 0L22.5 30.1c-1.6-1.6-4.2-1.6-5.9 0-1.6 1.6-1.6 4.2 0 5.9l13.2 13.2c.2.2.3.5.3.7s-.1.5-.3.7c-.4.4-1 .4-1.4 0L17.2 39.3c-.8-.8-1.9-1.2-2.9-1.2M33.5 9.2l6.1 6.1 2-4.1c1-2 .1-4.5-1.9-5.4-1-.5-2.1-.5-3.1-.2-1 .4-1.9 1.1-2.3 2.1z" />
  </svg>
);
export default IconSignLanguageAlt;
