import * as React from "react";
const IconStethoscopeAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.6 38.6c0-4-3.2-7.2-7.2-7.2s-7.2 3.2-7.2 7.2c0 3.6 2.7 6.7 6.2 7.2v10.4c0 2.2-1.8 3.9-3.9 3.9h-3.3c-2.2 0-3.9-1.8-3.9-3.9v-5.4c0-3.3-2.7-5.9-5.9-5.9h-.2c-3.3 0-5.9 2.7-5.9 5.9v.3c0 2.2-1.8 3.9-3.9 3.9s-3.9-1.8-3.9-3.9V38.4c7.8-.5 14-7 14-15V9.5c0-2.4-2-4.4-4.4-4.4h-2.2V3c0-.6-.4-1-1-1s-1 .4-1 1v6.1c0 .6.4 1 1 1s1-.4 1-1v-2h2.2c1.3 0 2.4 1.1 2.4 2.4v13.9c0 7.2-5.8 13-13 13s-13-5.8-13-13V9.5c0-1.3 1.1-2.4 2.4-2.4h2v2.1c0 .6.4 1 1 1s1-.4 1-1V3c0-.6-.4-1-1-1s-1 .4-1 1v2.1h-2c-2.4 0-4.4 2-4.4 4.4v13.9c0 7.9 6.2 14.4 14 15V51c0 3.3 2.7 5.9 5.9 5.9 3.3 0 5.9-2.7 5.9-5.9v-.3c0-2.2 1.8-3.9 3.9-3.9h.2c2.2 0 3.9 1.8 3.9 3.9v5.4c0 3.3 2.7 5.9 5.9 5.9h3.3c3.3 0 5.9-2.7 5.9-5.9V45.7c3.5-.5 6.2-3.5 6.2-7.1m-7.2 5.2c-2.9 0-5.2-2.3-5.2-5.2s2.3-5.2 5.2-5.2 5.2 2.3 5.2 5.2-2.3 5.2-5.2 5.2" />
  </svg>
);
export default IconStethoscopeAlt;
