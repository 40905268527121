import * as React from "react";
const IconFi = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 513 342"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 0h513v342H0z" />
    <path
      
      d="M513 129.3V212H203.7v130H121V212H0v-82.7h121V0h82.7v129.3z"
    />
  </svg>
);
export default IconFi;
