import * as React from "react";
const IconText = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.3 2H8.7C5 2 2 5 2 8.7v2.5c0 .6.4 1 1 1s1-.4 1-1V8.7C4 6.1 6.1 4 8.7 4H31v56H19.2c-.6 0-1 .4-1 1s.4 1 1 1h25.6c.6 0 1-.4 1-1s-.4-1-1-1H33V4h22.3C57.9 4 60 6.1 60 8.7v2.5c0 .6.4 1 1 1s1-.4 1-1V8.7C62 5 59 2 55.3 2" />
  </svg>
);
export default IconText;
