import * as React from "react";
const IconAngleDoubleRightCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m37.5 31.3-22.6-23c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4L35.4 32 13.5 54.3c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3l22.6-23c.4-.4.4-1 0-1.4" />
    <path d="m50.5 31.3-22.6-23c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4L48.4 32 26.5 54.3c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3l22.6-23c.4-.4.4-1 0-1.4" />
  </svg>
);
export default IconAngleDoubleRightCopy;
