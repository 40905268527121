import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useNavigation } from '../context/NavigationContext';

export const useHandleBackButton = () => {
    const navigate = useNavigate();
    const { isRootPage } = useNavigation();

    useEffect(() => {
        const handleBackButton = (event) => {
            if (isRootPage()) {
                // Attempting to mimic app closing by preventing default back behavior
                // TODO: Close PWA
                event.preventDefault();
            } else {
                navigate(-1);
            }
        };

        window.addEventListener('popstate', handleBackButton);
        return () => window.removeEventListener('popstate', handleBackButton);
    }, [navigate, isRootPage]);
};
