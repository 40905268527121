import * as React from "react";
const IconMegaphone1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.6 4.4c0-1-.6-1.9-1.5-2.2-.9-.4-1.9-.2-2.6.5-6.6 6.1-16.5 9.8-23.7 11.2h-.3c-3 0-21.6.2-22.5.2-.5 0-1 .5-1 1v3.2H7.7c-2.9 0-5.3 2.4-5.3 5.3V31c0 2.9 2.4 5.3 5.3 5.3H10v3.2c0 .5.4 1 1 1 .3 0 2.3 0 5 .1l3.6 17.2c.5 2.4 2.7 4.2 5.2 4.2h.9c1.6 0 3.1-.7 4.1-2 1-1.2 1.4-2.9 1.1-4.4l-3.1-15c2.6 0 4.8 0 5.7.1h.3C41 42.1 50.9 45.8 57.5 52c.5.4 1 .7 1.6.7.3 0 .7-.1 1-.2.9-.4 1.5-1.3 1.5-2.2zM28.3 58.8c-.6.8-1.6 1.2-2.6 1.2h-.9c-1.6 0-2.9-1.1-3.2-2.6l-3.5-16.6h7.6L29 56c.2 1-.1 2-.7 2.8M11 34.3H7.7c-1.8 0-3.3-1.5-3.3-3.3v-7.3c0-1.8 1.5-3.3 3.3-3.3H11c.6 0 1-.4 1-1v-3.2c3.6 0 16.2-.2 20.4-.2v22.8c-4.2 0-16.8-.2-20.4-.2v-3.2c0-.6-.4-1.1-1-1.1m48.3 16.3c-.1 0-.3.1-.4-.1-6.8-6.4-17-10.2-24.4-11.7v-23c7.4-1.5 17.6-5.4 24.4-11.7.1-.1.3-.1.4-.1s.3.1.3.4v45.8c0 .3-.2.4-.3.4" />
  </svg>
);
export default IconMegaphone1;
