import * as React from "react";
const IconTreesAlt8 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62 32.4c.1-3.4-1.1-6.7-3.5-9.1-2.4-2.5-5.6-3.8-9-3.8-2.9 0-5.6 1-7.8 2.8v-6c0-5.4-4.4-9.7-9.7-9.7s-9.7 4.4-9.7 9.7v6c-2.5-2-5.6-3-8.9-2.6-5.8.5-10.5 5.1-11.2 11-.4 3.6.7 7.1 3.1 9.8 2.1 2.4 5.1 3.9 8.3 4.1v12.1c0 .6.4 1 1 1s1-.4 1-1V44.4c2.8-.2 5.3-1.3 7.4-3.2 1.3 3.3 4.4 5.8 8.1 6.2v9.1c0 .6.4 1 1 1s1-.4 1-1v-9.1c3.7-.4 6.8-2.9 8.1-6.3 2.1 1.9 4.7 3 7.5 3.3v12.1c0 .6.4 1 1 1s1-.4 1-1V44.4c6-.5 11.1-5.7 11.3-12m-46.5 10v-5.7c0-.6-.4-1-1-1s-1 .4-1 1v5.7c-2.6-.3-5.1-1.5-6.8-3.5-2-2.3-2.9-5.2-2.6-8.2.6-4.9 4.5-8.7 9.4-9.2 3.3-.3 6.5.9 8.7 3.4v12.8c0 .4 0 .8.1 1.2-1.8 2-4.2 3.2-6.8 3.5m17.5 3v-8.6c0-.6-.4-1-1-1s-1 .4-1 1v8.6c-3.8-.5-6.7-3.7-6.7-7.7V16.2c0-4.3 3.5-7.7 7.7-7.7 4.3 0 7.7 3.5 7.7 7.7v21.5c0 3.9-2.9 7.2-6.7 7.7m17.5-3v-5.7c0-.6-.4-1-1-1s-1 .4-1 1v5.7c-2.7-.2-5.1-1.5-6.9-3.6 0-.4.1-.8.1-1.2V25c2-2.2 4.8-3.5 7.8-3.5 2.9 0 5.5 1.1 7.5 3.2s3 4.8 2.9 7.7c-.1 5.2-4.3 9.5-9.4 10" />
  </svg>
);
export default IconTreesAlt8;
