import * as React from "react";
const IconHiking = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 65 65"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M29.2 14.8c3.4 0 6.1-2.8 6.1-6.1 0-3.4-2.8-6.1-6.1-6.1s-6.1 2.8-6.1 6.1 2.8 6.1 6.1 6.1m0-10.3c2.3 0 4.1 1.9 4.1 4.1s-1.9 4.1-4.1 4.1-4.1-1.9-4.1-4.1 1.9-4.1 4.1-4.1M62.1 49.6c-.1-.5-.6-.9-1.2-.8l-8 1.6V15.1c0-.6-.4-1-1-1s-1 .4-1 1v9.6h-6.5l-11-6.5c-.2-.1-.3-.1-.5-.1H24c-.6 0-1 .4-1 1v21.1L15.9 58 3.7 60.5c-.5.1-.9.6-.8 1.2.1.5.5.8 1 .8h.2l57.2-11.7c.5-.1.9-.7.8-1.2M45 44.2l-7.2-6.3v-6.2l3.6 2.1c.2.1.3.1.5.1H51v16.9l-7.7 1.6 2-7.2c.1-.3-.1-.7-.3-1m-20-3.5c0-.1.1-.2.1-.4V20.1h7.6l11 6.5c.2.1.3.1.5.1H51v5.2h-8.8l-8.3-4.8c-.5-.3-1.1-.1-1.4.4s-.1 1.1.4 1.4l3 1.7v7.8c0 .3.1.6.3.8l7.1 6.2-2.1 7.6-4.9 1 .7-6.3q0-.45-.3-.9L30.2 41c-.4-.4-1-.3-1.4.1s-.3 1 .1 1.4l2.8 2.5c-.3.1-.5.2-.6.5L25 56.3l-6.6 1.3zm7.8 5.3 2.1 1.8-.7 5.8c0 .2 0 .4.2.6l-6.9 1.4 5.3-9.3z" />
    <path d="M6.6 41.1H17c.6 0 1-.4 1-1V21.5c0-.6-.4-1-1-1h-6.3q-.6 0-.9.6l-4 8.6c-.1.1-.1.3-.1.4v10c-.1.5.4 1 .9 1m1-10.8 3.7-7.8H16v16.6H7.6zM9 16.1h10.2c.6 0 1-.4 1-1s-.4-1-1-1H9c-.6 0-1 .4-1 1s.4 1 1 1" />
  </svg>
);
export default IconHiking;
