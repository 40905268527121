import * as React from "react";
const IconPaypalCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M52.1 11.4C51.3 5.2 46.7 1 40.3 1H18.5c-.5 0-.9.3-1 .8L5.1 52.5c-.1.3 0 .6.2.9.2.2.5.4.8.4h7.8l-2 8c-.1.3 0 .6.2.9s.5.4.8.4h15.5c.5 0 .9-.3 1-.8l4.9-21h6c4.1 0 8.2-1.6 11.6-4.5 3.4-2.8 5.8-6.7 6.8-10.8 1.3-6.5-1.3-12.4-6.6-14.6M19.3 3h21c5.5 0 9.4 3.6 9.8 9.1.1 1.6 0 2.9-.4 4.2-.8 3.6-3.1 7.1-6.1 9.7s-6.7 4-10.3 4h-6.6c-.5 0-.9.3-1 .8l-5.1 21H7.3zm37.3 22.6c-.9 3.7-3 7.1-6.1 9.7-3 2.6-6.7 4-10.3 4h-6.8c-.5 0-.9.3-1 .8l-4.9 21H14.2l1.8-7.3h5.5c.5 0 .9-.3 1-.8l5.1-21h5.9c4.1 0 8.2-1.6 11.6-4.5s5.8-6.7 6.8-10.7c.2-1 .4-2 .4-3.1 3.6 2.1 5.5 6.7 4.3 11.9" />
  </svg>
);
export default IconPaypalCopy;
