import * as React from "react";
const IconCakeCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59 31h-2.3V10.7c0-3.4-2.8-6.2-6.2-6.2h-37c-3.4 0-6.2 2.8-6.2 6.2V31H5c-1.6 0-3 1.3-3 2.9v19.3c0 3.4 2.8 6.2 6.2 6.2l47.6.1c1.7 0 3.2-.6 4.4-1.8S62 55 62 53.3V33.9c0-1.6-1.3-2.9-3-2.9M13.5 6.5h37c2.3 0 4.2 1.9 4.2 4.2v5.5c-2.6.2-4.1 1.1-5.5 2-1.5.9-2.7 1.7-5.4 1.7s-3.9-.8-5.4-1.7-3.2-2-6.5-2-5 1.1-6.5 2-2.7 1.7-5.4 1.7-3.9-.8-5.4-1.7c-1.3-.8-2.8-1.8-5.5-2v-5.5c.2-2.3 2.1-4.2 4.4-4.2M9.3 18.3c2 .2 3.1.9 4.4 1.7 1.5.9 3.2 2 6.5 2s5-1.1 6.5-2 2.7-1.7 5.4-1.7 3.9.8 5.4 1.7 3.2 2 6.5 2 5-1.1 6.5-2c1.3-.8 2.4-1.5 4.4-1.7V31H9.3zM5 33h54c.5 0 1 .4 1 .9v7.8c-3.3.2-5.1 1.3-6.8 2.4s-3.4 2.1-6.7 2.1-5-1-6.7-2.1c-1.9-1.2-3.9-2.4-7.8-2.4s-5.9 1.2-7.8 2.4c-1.7 1.1-3.4 2.1-6.7 2.1s-5-1-6.7-2.1-3.5-2.2-6.8-2.4v-7.8c0-.5.4-.9 1-.9m53.8 23.3c-.8.8-1.9 1.2-3 1.2l-47.6-.1c-2.3 0-4.2-1.9-4.2-4.2v-9.6c2.7.2 4.2 1.1 5.7 2.1 1.9 1.2 3.9 2.4 7.8 2.4s5.9-1.2 7.8-2.4c1.7-1.1 3.4-2.1 6.7-2.1s5 1 6.7 2.1c1.9 1.2 3.9 2.4 7.8 2.4s5.9-1.2 7.8-2.4c1.6-1 3-1.9 5.7-2.1v9.7c0 1.1-.4 2.2-1.2 3" />
  </svg>
);
export default IconCakeCopy;
