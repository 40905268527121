import * as React from "react";
const IconCg = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 85.333 513 342"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 85.331h513v342H0z" />
    <path  d="M443.726 85.331 102.4 426.657H0V85.331z" />
    <path
      
      d="M500.124 85.331 158.798 426.657H11.876L353.202 85.331z"
    />
  </svg>
);
export default IconCg;
