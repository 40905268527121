import * as React from "react";
const IconUmbrellaAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M33 6.2V3c0-.6-.4-1-1-1s-1 .4-1 1v3.2c-14.8.5-26.8 12-28.2 27.2-.1.9.4 1.8 1.1 2.3.8.5 1.8.5 2.5 0l4.2-2.6c1.5-.9 3.4-.9 4.9 0l3.4 2.2c1.1.7 2.3 1 3.5 1s2.5-.3 3.5-1l3.4-2.1q.75-.45 1.5-.6V61c0 .6.4 1 1 1s1-.4 1-1V32.4q.75.15 1.5.6l3.4 2.1c2.1 1.3 4.9 1.3 7.1 0l3.4-2.1c1.5-.9 3.4-.9 4.9 0l4.2 2.6c.8.5 1.8.5 2.5 0 .8-.5 1.2-1.3 1.1-2.3C59.8 18.2 47.8 6.7 33 6.2m26 27.7c-.1 0-.2.1-.4 0l-4.2-2.6c-2.1-1.3-4.9-1.3-7.1 0l-3.4 2.1c-1.5.9-3.4.9-4.9 0l-3.4-2.2c-2.1-1.3-4.9-1.3-7.1 0L25 33.5c-1.5.9-3.4.9-4.9 0l-3.4-2.2c-1.1-.7-2.3-1-3.5-1s-2.5.3-3.5 1l-4.2 2.6c-.2.1-.4 0-.4 0-.1 0-.2-.2-.2-.4C6.1 19.1 17.8 8.1 32 8.1S57.9 19 59.2 33.5c0 .2-.1.4-.2.4" />
  </svg>
);
export default IconUmbrellaAlt;
