import * as React from "react";
const IconNext = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.7 2h-8.2c-2.2 0-4 1.8-4 4v24.3L8.4 5.1c-1.2-.8-2.8-.9-4-.2-1.3.7-2.1 2-2.1 3.5v47.3c0 1.5.8 2.8 2.1 3.5.6.3 1.2.5 1.8.5.8 0 1.5-.2 2.2-.7l37.2-25.2V58c0 2.2 1.8 4 4 4h8.2c2.2 0 4-1.8 4-4V6c-.1-2.2-1.9-4-4.1-4M7.3 57.2c-.6.4-1.4.4-2 .1s-1-1-1-1.7V8.4c0-.7.4-1.4 1-1.7.3-.2.6-.2.9-.2.4 0 .8.1 1.1.3L44.6 32zm52.4.8c0 1.1-.9 2-2 2h-8.2c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2h8.2c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconNext;
