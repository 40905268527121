import * as React from "react";
const IconInvoice1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.8 7.9c0-3.2-2.6-5.9-5.9-5.9H14c-3.2 0-5.9 2.6-5.9 5.9v52c0 .4.2.7.5.9s.7.2 1 0l5.6-3.7c.2-.1.5-.1.7 0l7.3 4.8c.3.2.8.2 1.1 0l7.2-4.8c.2-.1.5-.1.7 0l7.3 4.8c.2.1.4.2.6.2s.4-.1.6-.2L48 57c.2-.1.5-.1.7 0l5.6 3.7c.3.2.7.2 1 0s.5-.5.5-.9zm-6 47.4c-.9-.6-2.1-.6-3 0l-6.7 4.5-6.7-4.5c-.4-.3-1-.5-1.5-.5s-1 .1-1.5.4l-6.7 4.4-6.7-4.4c-.9-.6-2.1-.6-3 0l-4 2.7v-50c.2-2.2 1.9-3.9 4-3.9h35.9c2.1 0 3.9 1.7 3.9 3.9V58z" />
    <path d="M28.1 11.9h-8.9c-.6 0-1 .4-1 1s.4 1 1 1h8.9c.6 0 1-.4 1-1s-.5-1-1-1M44.3 11.9h-2.4c-.6 0-1 .4-1 1s.4 1 1 1h2.4c.6 0 1-.4 1-1s-.4-1-1-1M19.1 25.3h5.7c.6 0 1-.4 1-1s-.4-1-1-1h-5.7c-.6 0-1 .4-1 1s.5 1 1 1M44.3 23.3h-2.4c-.6 0-1 .4-1 1s.4 1 1 1h2.4c.6 0 1-.4 1-1s-.4-1-1-1M28.1 34.7h-8.9c-.6 0-1 .4-1 1s.4 1 1 1h8.9c.6 0 1-.4 1-1s-.5-1-1-1M44.3 34.7h-2.4c-.6 0-1 .4-1 1s.4 1 1 1h2.4c.6 0 1-.4 1-1s-.4-1-1-1M24.8 46h-5.7c-.6 0-1 .4-1 1s.4 1 1 1h5.7c.6 0 1-.4 1-1s-.4-1-1-1M44.3 46h-2.4c-.6 0-1 .4-1 1s.4 1 1 1h2.4c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconInvoice1;
