import * as React from "react";
const IconSignalSlash = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M7.9 46c-.6 0-1 .4-1 1v13c0 .6.4 1 1 1s1-.4 1-1V47c0-.6-.4-1-1-1M20.4 35.2c-.6 0-1 .4-1 1V60c0 .6.4 1 1 1s1-.4 1-1V36.2c0-.5-.4-1-1-1M32.9 42.5c-.6 0-1 .4-1 1V60c0 .6.4 1 1 1s1-.4 1-1V43.5c0-.5-.4-1-1-1M45.4 55.5c-.6 0-1 .4-1 1V60c0 .6.4 1 1 1s1-.4 1-1v-3.5c0-.6-.4-1-1-1M58.9 4c0-.6-.4-1-1-1s-1 .4-1 1v53.5L46.4 46.7v-32c0-.6-.4-1-1-1s-1 .4-1 1v29.9L33.9 33.8v-8.3c0-.6-.4-1-1-1s-1 .4-1 1v6.3L6.8 5.8c-.4-.4-1-.4-1.4 0s-.4 1-.1 1.4l51.9 53.5c.1.1.2.2.3.2.1.1.3.1.4.1s.3 0 .4-.1c.1 0 .1-.1.2-.1 0 0 .1 0 .1-.1.1-.1.2-.2.2-.3s.1-.2.1-.3z" />
  </svg>
);
export default IconSignalSlash;
