import * as React from "react";
const IconCalenderAlt3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.3 8.1h-5.7V4.6c0-.6-.4-1-1-1s-1 .4-1 1v3.5H16.4V4.6c0-.6-.4-1-1-1s-1 .4-1 1v3.5H8.7C5.5 8.1 3 10.7 3 13.8v27.8c0 3.1 2.5 5.7 5.7 5.7h31.4c-.2.8-.3 1.7-.3 2.6 0 5.9 4.8 10.6 10.6 10.6S61 55.7 61 49.8c0-1.4-.3-2.8-.8-4.1.5-.7.8-1.5.8-2.4V13.8c0-3.1-2.5-5.7-5.7-5.7m-46.6 2h5.7v2.3c0 .6.4 1 1 1s1-.4 1-1v-2.3h31.3v2.3c0 .6.4 1 1 1s1-.4 1-1v-2.3h5.7c2 0 3.7 1.6 3.7 3.7V21H5v-7.2c0-2 1.6-3.7 3.7-3.7m41.7 48.3c-4.7 0-8.6-3.9-8.6-8.6s3.9-8.6 8.6-8.6 8.6 3.9 8.6 8.6-3.9 8.6-8.6 8.6m0-19.2c-4.2 0-7.8 2.5-9.6 6H8.7c-2 0-3.7-1.6-3.7-3.7V23h54v20.6c-2-2.7-5.1-4.4-8.6-4.4" />
    <path d="M24.7 27.5h-2.1c-.6 0-1 .4-1 1v2.1c0 .6.4 1 1 1h2.1c.6 0 1-.4 1-1v-2.1c0-.5-.4-1-1-1M34 27.5h-2.1c-.6 0-1 .4-1 1v2.1c0 .6.4 1 1 1H34c.6 0 1-.4 1-1v-2.1c0-.5-.4-1-1-1M43.3 27.5h-2.1c-.6 0-1 .4-1 1v2.1c0 .6.4 1 1 1h2.1c.6 0 1-.4 1-1v-2.1c0-.5-.5-1-1-1M15.4 35.2h-2.1c-.6 0-1 .4-1 1v2.1c0 .6.4 1 1 1h2.1c.6 0 1-.4 1-1v-2.1c0-.6-.4-1-1-1M24.7 35.2h-2.1c-.6 0-1 .4-1 1v2.1c0 .6.4 1 1 1h2.1c.6 0 1-.4 1-1v-2.1c0-.6-.4-1-1-1M34 35.2h-2.1c-.6 0-1 .4-1 1v2.1c0 .6.4 1 1 1H34c.6 0 1-.4 1-1v-2.1c0-.6-.4-1-1-1M53.4 49.8h-2.5v-2.4c0-.6-.4-1-1-1s-1 .4-1 1v3.4c0 .6.4 1 1 1h3.5c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconCalenderAlt3;
