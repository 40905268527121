import * as React from "react";
const IconBadge10 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m58.4 46.8 3.5-6.7c.2-.3.2-.7 0-1s-.5-.5-.9-.5H50.6v-2.9q0-.6-.6-.9c3.3-9.9 3.3-18.3 3.3-18.6 0-.5-.3-.9-.8-1-8-1.6-19.7-10.1-19.8-10.2q-.6-.45-1.2 0c-.1.1-11.9 8.6-19.8 10.2-.5.1-.8.5-.8 1 0 .4 0 8.7 3.3 18.6-.3.2-.6.5-.6.9v2.9H3c-.4 0-.7.2-.9.5s-.2.7 0 1l3.5 6.7-3.5 6.7c-.2.3-.2.7 0 1s.5.5.9.5h11.5c.6 0 1-.4 1-1v-4.6h5.9c2.9 3.8 6.2 7.1 10.1 9.6.2.1.4.2.6.2s.4-.1.6-.2c3.8-2.5 7.2-5.8 10.1-9.6h5.9V54c0 .6.4 1 1 1H61c.4 0 .7-.2.9-.5s.2-.7 0-1zM32 7.1c2.5 1.7 11.9 8.2 19.2 9.9-.1 2.4-.5 9.6-3.3 17.7H16.1c-2.8-8.1-3.2-15.4-3.3-17.7 7.3-1.7 16.7-8.1 19.2-9.9M13.5 52.9H4.7l3-5.7q.3-.45 0-.9l-3-5.7h8.8v12.3m2-16.1h33v10.5h-33zM32 56.9c-3-2.1-5.7-4.6-8.1-7.6h16.2c-2.4 3-5.1 5.5-8.1 7.6m18.5-4V40.5h8.8l-3 5.7q-.3.45 0 .9l3 5.7h-8.8z" />
  </svg>
);
export default IconBadge10;
