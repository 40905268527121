import React, { useEffect, useState } from 'react';
import {
  BringzzPageContainer,
  BringzzIcon,
  BringzzText,
  BringzzInput,
  BringzzButton,
  BringzzCheckbox,
  BringzzBottomDrawer,
  BringzzRadioList,
  BringzzRadio,
  BringzzImage
} from '@bringzz/components';
import { useNavigation } from 'context/NavigationContext';
import { useLocation } from 'react-router-dom';
import useUser from 'hooks/useUser';
import { useAuth } from 'context/AuthContext';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

let state = {
  checked: <div></div>,
  unChecked: <div></div>
};

let products = [
  {
    id: 1,
    name: 'Product Name 1',
    manufacturer: 'Manufacturer',
    type: 'Pills',
    quantity: '64 St',
    dosage: '200mg',
    imgUrl: '/product.png',
    price: 9
  },
  {
    id: 2,
    name: 'Product Name 2',
    manufacturer: 'Manufacturer',
    type: 'Pills',
    quantity: '12 St',
    dosage: '200mg',
    imgUrl: '/product.png',
    price: 8
  },
  {
    id: 3,
    name: 'Product Name 3',
    manufacturer: 'Manufacturer',
    type: 'Pills',
    quantity: '12 St',
    dosage: '200mg',
    imgUrl: '/product.png',
    price: 8
  },
  {
    id: 4,
    name: 'Product Name 4',
    manufacturer: 'Manufacturer',
    type: 'Pills',
    quantity: '12 St',
    dosage: '200mg',
    imgUrl: '/product.png',
    price: 8
  }
];

const SettingsEditPost = () => {
  let query = useQuery();
  const { backPress } = useNavigation();
  const [isChecked1, setChecked1] = useState(false);
  const [isChecked2, setChecked2] = useState(false);
  const { getUser } = useAuth()
  let id = query.get('id');
  const [post, setPost] = useState(null);
  const [addPhotoDrawer, setAddPhotoDrawer] = useState(false);
  const [photo, setPhoto] = useState(null); // State to manage the photo
  const { navigate } = useNavigation();
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredProducts, setFilteredProducts] = useState(products);
  const [productDrawer, setAddProductDrawer] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]); // State for multiple selected products
  const [viewingProduct, setViewingProduct] = useState(null); // State for the product currently being viewed

  const posts = [
    {
      uuid: '1',
      type: 3,
      headline: 'In elit diam tellus neque diam mattis nam. Tristique eleitend euismod est.',
      description: 'nutun laue ous phareud ac nund ulancorper door vesticuun pellentesque. Inter dum lorem st ulamcorper dienissim consequatpharetra id cact lacus. Ad piscna.',
      content: 'string',
      release: new Date(),
      likesHidden: false,
      viewsHidden: false,
      commentsHidden: false,
      PostImg: '',
      PostImgAlt: '',
      validFrom: new Date(), // -> for type 1
      validTilpost: new Date(), // -> for type 1
      products: [], // -> for type 1
      link: 'string', // -> for type 2
      img_caption: 'string' // -> for type 3
    },
    {
      uuid: '2',
      type: 3,
      headline: 'In elit diam tellus neque diam mattis nam. Tristique eleitend euismod est.',
      description: 'nutun laue ous phareud ac nund ulancorper door vesticuun pellentesque. Inter dum lorem st ulamcorper dienissim consequatpharetra id cact lacus. Ad piscna.',
      content: 'string',
      release: new Date(),
      likesHidden: false,
      viewsHidden: false,
      commentsHidden: false,
      PostImg: '',
      PostImgAlt: '',
      validFrom: new Date(), // -> for type 1
      validTilpost: new Date(), // -> for type 1
      products: [], // -> for type 1
      link: 'string', // -> for type 2
      img_caption: 'string' // -> for type 3
    },
    {
      uuid: '3',
      type: 3,
      headline: 'In elit diam tellus neque diam mattis nam. Tristique eleitend euismod est.',
      description: 'nutun laue ous phareud ac nund ulancorper door vesticuun pellentesque. Inter dum lorem st ulamcorper dienissim consequatpharetra id cact lacus. Ad piscna.',
      content: 'string',
      release: new Date(),
      likesHidden: false,
      viewsHidden: false,
      commentsHidden: false,
      PostImg: '',
      PostImgAlt: '',
      validFrom: new Date(), // -> for type 1
      validTilpost: new Date(), // -> for type 1
      products: [], // -> for type 1
      link: 'string', // -> for type 2
      img_caption: 'string' // -> for type 3
    },
    {
      uuid: '4',
      type: 2,
      headline: 'In elit diam tellus neque diam mattis nam. Tristique eleitend euismod est.',
      description: 'nutun laue ous phareud ac nund ulancorper door vesticuun pellentesque. Inter dum lorem st ulamcorper dienissim consequatpharetra id cact lacus. Ad piscna.',
      content: 'string',
      release: new Date(),
      likesHidden: false,
      viewsHidden: false,
      commentsHidden: false,
      PostImg: '',
      PostImgAlt: '',
      validFrom: new Date(), // -> for type 1
      validTilpost: new Date(), // -> for type 1
      products: [], // -> for type 1
      link: 'string', // -> for type 2
      img_caption: 'string' // -> for type 3
    },
    {
      uuid: '5',
      type: 2,
      headline: 'In elit diam tellus neque diam mattis nam. Tristique eleitend euismod est.',
      description: 'nutun laue ous phareud ac nund ulancorper door vesticuun pellentesque. Inter dum lorem st ulamcorper dienissim consequatpharetra id cact lacus. Ad piscna.',
      content: 'string',
      release: new Date(),
      likesHidden: false,
      viewsHidden: false,
      commentsHidden: false,
      PostImg: '',
      PostImgAlt: '',
      validFrom: new Date(), // -> for type 1
      validTilpost: new Date(), // -> for type 1
      products: [], // -> for type 1
      link: 'string', // -> for type 2
      img_caption: 'string' // -> for type 3
    },
    {
      uuid: '6',
      type: 2,
      headline: 'In elit diam tellus neque diam mattis nam. Tristique eleitend euismod est.',
      description: 'nutun laue ous phareud ac nund ulancorper door vesticuun pellentesque. Inter dum lorem st ulamcorper dienissim consequatpharetra id cact lacus. Ad piscna.',
      content: 'string',
      release: new Date(),
      likesHidden: false,
      viewsHidden: false,
      commentsHidden: false,
      PostImg: '',
      PostImgAlt: '',
      validFrom: new Date(), // -> for type 1
      validTilpost: new Date(), // -> for type 1
      products: [], // -> for type 1
      link: 'string', // -> for type 2
      img_caption: 'string' // -> for type 3
    },
    {
      uuid: '7',
      type: 1,
      headline: 'In elit diam tellus neque diam mattis nam. Tristique eleitend euismod est.',
      description: 'nutun laue ous phareud ac nund ulancorper door vesticuun pellentesque. Inter dum lorem st ulamcorper dienissim consequatpharetra id cact lacus. Ad piscna.',
      content: 'string',
      release: new Date(),
      likesHidden: false,
      viewsHidden: false,
      commentsHidden: false,
      PostImg: '',
      PostImgAlt: '',
      validFrom: new Date(), // -> for type 1
      validTilpost: new Date(), // -> for type 1
      products: [], // -> for type 1
      link: 'string', // -> for type 2
      img_caption: 'string' // -> for type 3
    },
    {
      uuid: '8',
      type: 1,
      headline: 'In elit diam tellus neque diam mattis nam. Tristique eleitend euismod est.',
      description: 'nutun laue ous phareud ac nund ulancorper door vesticuun pellentesque. Inter dum lorem st ulamcorper dienissim consequatpharetra id cact lacus. Ad piscna.',
      content: 'string',
      release: new Date(),
      likesHidden: false,
      viewsHidden: false,
      commentsHidden: false,
      PostImg: '',
      PostImgAlt: '',
      validFrom: new Date(), // -> for type 1
      validTilpost: new Date(), // -> for type 1
      products: [], // -> for type 1
      link: 'string', // -> for type 2
      img_caption: 'string' // -> for type 3
    },
    {
      uuid: '9',
      type: 1,
      headline: 'In elit diam tellus neque diam mattis nam. Tristique eleitend euismod est.',
      description: 'nutun laue ous phareud ac nund ulancorper door vesticuun pellentesque. Inter dum lorem st ulamcorper dienissim consequatpharetra id cact lacus. Ad piscna.',
      content: 'string',
      release: new Date(),
      likesHidden: false,
      viewsHidden: false,
      commentsHidden: false,
      PostImg: '',
      PostImgAlt: '',
      validFrom: new Date(), // -> for type 1
      validTilpost: new Date(), // -> for type 1
      products: [], // -> for type 1
      link: 'string', // -> for type 2
      img_caption: 'string' // -> for type 3
    }
  ]

  useEffect(() => {
    if (id) {
      posts.forEach(post => {
        if (post.uuid === id) {
          setPost(post);
        }
      });
    }
  }, [id]);

  useEffect(() => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    setFilteredProducts(
      products.filter(product =>
        product.name.toLowerCase().includes(lowerCaseQuery) ||
        product.manufacturer.toLowerCase().includes(lowerCaseQuery)
      )
    );
  }, [searchQuery]);

  const handlePickerClick = async choice => {
    try {
      let base64Image = '';

      if (choice === 'camera') {
        // Handling camera input
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true
        });
        const video = document.createElement('video');
        video.srcObject = stream;
        video.play();

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        video.onloadedmetadata = () => {
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          context.drawImage(video, 0, 0, canvas.width, canvas.height);
          base64Image = canvas.toDataURL('image/jpeg');
          // Stop the video stream
          stream.getTracks().forEach(track => track.stop());
          setPhoto(base64Image);
          console.log(base64Image);
        };
      } else if (choice === 'photos') {
        // Handling gallery input
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.onchange = async event => {
          const file = event.target.files[0];
          const reader = new FileReader();
          reader.onloadend = () => {
            base64Image = reader.result;
            setPhoto(base64Image);
            console.log(base64Image);
          };
          reader.readAsDataURL(file);
        };
        input.click();
      }
    } catch (error) {
      console.error('Error handling picker choice:', error);
    }
  };

  const handleRemovePhoto = () => {
    setPhoto(null); // Only reset the photo state
  };

  const handleProductSelect = product => {
    setViewingProduct(product); // Set the product to be viewed in the drawer
  };

  const handleCancelSelection = () => {
    setViewingProduct(null); // Reset the viewing product
    setAddProductDrawer(false);
  };

  const handleApplyProduct = () => {
    if (viewingProduct) {
      setSelectedProducts([...selectedProducts, viewingProduct]); // Add the viewing product to the selected products
    }
    setViewingProduct(null); // Reset the viewing product
    setAddProductDrawer(false);
  };

  const handleRemoveProduct = (productId) => {
    setSelectedProducts(selectedProducts.filter(product => product.id !== productId)); // Remove the selected product by its ID
  };

  return (
    post && (
      <BringzzPageContainer
        header={{
          left: (
            <BringzzIcon
              size="22"
              icon="IconChevronLeft"
              folder="LineIcons"
              className="cursor-pointer"
              onClick={backPress}
            />
          ),
          title: <BringzzText tag="h2">Product Post</BringzzText>
        }}
        footer={
          <div className="pb-28 px-6 flex flex-row space-x-4">
            <BringzzButton
              onClick={() =>
                navigate(`/settings/posts/preview?id=${post.uuid}`)
              }
              size="lg"
              className="border w-full border-black justify-center"
            >
              Save
            </BringzzButton>
            <BringzzButton
              onClick={() =>
                navigate(`/settings/posts/preview?id=${post.uuid}`)
              }
              size="lg"
              className="bg-magic-lilac w-full flex justify-center text-midnight-blue"
            >
              Share
            </BringzzButton>
          </div>
        }
      >
        <div className="p-4">
          <div className="space-y-4">
            <BringzzInput
              label="Headline (max. XXX char)"
              defaultValue={post.headline}
              tooltip="something"
            />
            <BringzzInput
              label="Description (max. XXX char)"
              type="textarea"
              defaultValue={post.description}
              placeholder="Your posting description"
              tooltip="something"
            />
          </div>
          <div className="flex flex-col space-y-4 mt-4">
            <div className="w-full">
              <label className="block text-sm font-medium text-gray-700 mb-1">Post Image</label>
              <div
                className={`relative w-full ${photo ? 'h-48' : 'h-14'
                  } border border-gray-300 rounded-md flex items-center justify-center bg-white transition-all duration-300`}
              >
                {photo ? (
                  <div className="relative w-full h-full">
                    <img src={photo} alt="Post" className="w-full h-full object-cover rounded-md" />
                    <BringzzIcon
                      size="24"
                      icon="IconTrashCan"
                      folder="LineIcons"
                      className="absolute top-2 right-2 cursor-pointer text-gray-500"
                      onClick={handleRemovePhoto}
                    />
                  </div>
                ) : (
                  <div className="flex items-center justify-between w-full px-4 py-2">
                    <BringzzText tag="span" className="text-gray-500">Add image</BringzzText>
                    <BringzzIcon
                      size="24"
                      icon="IconCirclePlus"
                      folder="LineIcons"
                      className="cursor-pointer text-gray-500"
                      onClick={() => setAddPhotoDrawer(true)}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="w-full">
              <label className="block text-sm font-medium text-gray-700 mb-1">Products</label>
              {selectedProducts.map(product => (
                <div key={product.id} className="relative w-full border border-gray-300 rounded-md p-3 flex items-center justify-between bg-white mb-2">
                  <div className="flex items-center">
                    <div className="w-12 h-12 bg-gray-100 rounded-md mr-4">
                      <BringzzImage src={product.imgUrl} className="w-full h-full object-cover" />
                    </div>
                    <div>
                      <BringzzText tag="h4" className="font-semibold">{product.name}</BringzzText>
                      <BringzzText tag="span" className="text-gray-500">
                        {product.manufacturer} | {product.type} | {product.quantity} | {product.dosage}
                      </BringzzText>
                      <BringzzText tag="a" className="text-magic-lilac block mt-1 cursor-pointer">Add Discount</BringzzText>
                    </div>
                  </div>
                  <BringzzIcon
                    size="24"
                    icon="IconTrashCan"
                    folder="LineIcons"
                    className="cursor-pointer text-gray-500"
                    onClick={() => handleRemoveProduct(product.id)}
                  />
                </div>
              ))}
              <div className="relative w-full h-14 border border-gray-300 rounded-md flex items-center justify-center bg-white">
                <div className="flex items-center justify-between w-full px-4 py-2">
                  <BringzzText tag="span" className="text-gray-500">Add product</BringzzText>
                  <BringzzIcon
                    size="24"
                    icon="IconCirclePlus"
                    folder="LineIcons"
                    className="cursor-pointer text-gray-500"
                    onClick={() => setAddProductDrawer(true)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="space-y-2 pl-1 mt-4">
            <BringzzCheckbox
              id="example-checkbox1"
              label="Custom post validity"
              checked={isChecked1}
              onChange={setChecked1}
            />
            <BringzzCheckbox
              id="example-checkbox2"
              label="Hide likes & comments"
              checked={isChecked2}
              onChange={setChecked2}
            />
          </div>
        </div>
        <BringzzBottomDrawer
          isOpen={addPhotoDrawer}
          title={'Upload product photo'}
          close={() => setAddPhotoDrawer(false)}
        >
          <div className="px-4 py-6">
            <BringzzRadioList
              state={state}
              className="rounded-xl w-full text-center"
              onChange={choice => {
                handlePickerClick(choice);
                setAddPhotoDrawer(false);
              }}
            >
              <BringzzRadio
                value="camera"
                className="p-2 py-4 hover:bg-slate- border-1 border-t border-gray-400 flex"
              >
                <div>Camera</div>
              </BringzzRadio>
              <BringzzRadio
                value="photos"
                className="p-2 py-4 hover:bg-slate- border-1 border-t border-gray-400"
              >
                <div>Photos</div>
              </BringzzRadio>
            </BringzzRadioList>
          </div>
        </BringzzBottomDrawer>
        <BringzzBottomDrawer
          isOpen={productDrawer}
          title={viewingProduct ? 'Product Details' : 'Find Product'}
          close={() => setAddProductDrawer(false)}
        >
          <div className="px-4 py-6">
            {viewingProduct ? (
              <div className="space-y-4">
                <div className="flex items-center border border-gray-300 rounded-md p-3 space-x-4">
                  <div className="w-16 h-16 bg-gray-100 rounded-md">
                    <BringzzImage src={viewingProduct.imgUrl} className="w-full h-full object-cover" />
                  </div>
                  <div>
                    <BringzzText tag="h4" className="font-semibold">{viewingProduct.name}</BringzzText>
                    <BringzzText tag="h5" className="text-gray-500">{viewingProduct.manufacturer}</BringzzText>
                  </div>
                </div>
                <div className="flex justify-center py-6 items-center space-x-4">
                  <div className="flex w-full border border-gray-300 p-3 rounded-md flex-col items-center">
                    <div className="w-12 h-12 bg-gray-100 rounded-full"></div>
                    <BringzzText tag="span">{viewingProduct.type}</BringzzText>
                    <BringzzText tag="a" className="text-blue-500">Change</BringzzText>
                  </div>
                  <div className="flex w-full flex-col border border-gray-300 p-3 rounded-md items-center">
                    <div className="w-12 h-12 bg-gray-100 rounded-full"></div>
                    <BringzzText tag="span">{viewingProduct.dosage}</BringzzText>
                    <BringzzText tag="a" className="text-blue-500">Change</BringzzText>
                  </div>
                  <div className="flex w-full flex-col border border-gray-300 p-3 rounded-md items-center">
                    <div className="w-12 h-12 bg-gray-100 rounded-full"></div>
                    <BringzzText tag="span">{viewingProduct.quantity}</BringzzText>
                    <BringzzText tag="a" className="text-blue-500">Change</BringzzText>
                  </div>
                </div>
                <div className="flex justify-around space-x-4">
                  <BringzzButton
                    size="lg"
                    className="border w-full border-black justify-center"
                    onClick={handleCancelSelection}
                  >
                    Cancel
                  </BringzzButton>
                  <BringzzButton
                    size="lg"
                    className="bg-magic-lilac w-full flex justify-center text-midnight-blue"
                    onClick={handleApplyProduct}
                  >
                    Apply
                  </BringzzButton>
                </div>
              </div>
            ) : (
              <>
                <BringzzInput
                  icon={{
                    left: (
                      <BringzzIcon
                        icon="IconSearchAlt"
                        folder="LineIcons"
                        className="h-6 w-6"
                        size="24"
                      />
                    )
                  }}
                  placeholder="Search here..."
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                {filteredProducts &&
                  filteredProducts.length > 0 &&
                  filteredProducts.map((product, index) => (
                    <div
                      key={product.id || index}
                      className="py-4 px-6 flex items-center w-full border-b border-gray-200 cursor-pointer"
                      onClick={() => handleProductSelect(product)}
                    >
                      <div className="flex items-center justify-center w-12 h-12 bg-gray-100 rounded-md mr-4">
                        <BringzzImage src={product.imgUrl} className="w-full h-full object-cover" />
                      </div>
                      <div className="flex flex-col">
                        <BringzzText tag="h4" className="text-base font-semibold text-gray-800">
                          {product.name || 'Product Name'}
                        </BringzzText>
                        <BringzzText tag="h5" className="text-sm text-gray-500">
                          {product.manufacturer || 'Manufacturer'} / {product.amount}
                        </BringzzText>
                      </div>
                    </div>
                  ))}
              </>
            )}
          </div>
        </BringzzBottomDrawer>
      </BringzzPageContainer>
    )
  );
};

export default SettingsEditPost;
