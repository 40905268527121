import * as React from "react";
const IconStrawberry = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.7 19.2c.2-.2.4-.5.3-.8 0-.3-.2-.6-.4-.8-3.7-2.5-6.9-3.8-9.7-3.8-.4 0-.8.1-1.2.2 2-2.6 3.3-5.9 1.4-9.3-.3-.5-.9-.7-1.4-.4s-.7.9-.4 1.4c2.7 4.8-3.6 9.8-5.6 11.3-2.5-2.5-9.7-9-18-9-11.5 0-19 12.2-22.5 36.3-.6 4 .5 7.8 3.2 10.9 2.6 2.9 6.3 4.6 10.2 4.6.8 0 1.5-.1 2.2-.2 20.9-3.3 32.8-9.7 35.4-18.8 1.9-6.6-1.4-13.1-4.6-17.4.5.1.9.2 1.4.2 3.4-.1 7.3-2.4 9.7-4.4m-9.8-3.4c2.1 0 4.5.9 7.4 2.7-2.2 1.6-5 3-7.4 3-1.7 0-3-.9-4.1-2.7.4-.8 1.5-2.8 4.1-3m1.3 24.4c-2.4 8.4-13.7 14.2-33.8 17.4-.6.1-1.3.2-1.8.2-3.3 0-6.6-1.5-8.7-3.9-2.3-2.7-3.2-5.9-2.7-9.4 3.3-23 10.2-34.6 20.5-34.6 9.1 0 17.3 9.1 17.4 9.2l.1.1c1.1 1.2 11.8 11 9 21" />
    <path d="M22.5 20.7c.3 0 .5-.1.7-.3l1.8-1.8c.4-.4.4-1 0-1.4s-1-.4-1.4 0L21.8 19c-.4.4-.4 1 0 1.4.2.2.5.3.7.3M32 24.2c-.4-.4-1-.4-1.4 0L28.8 26c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l1.8-1.8c.4-.4.4-1 0-1.4M37.6 31.2 35.8 33c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l1.8-1.8c.4-.4.4-1 0-1.4s-1-.4-1.4 0M42.7 41.4c.2.2.5.3.7.3s.5-.1.7-.3l1.8-1.8c.4-.4.4-1 0-1.4s-1-.4-1.4 0L42.7 40c-.3.3-.3 1 0 1.4M21.2 27.4c-.4-.4-1-.4-1.4 0L18 29.2c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l1.8-1.8c.4-.4.4-1 0-1.4M26.8 34.4 25 36.2c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l1.8-1.8c.4-.4.4-1 0-1.4s-1-.4-1.4 0M33.8 41.3 32 43.1c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l1.8-1.8c.4-.4.4-1 0-1.4s-1-.3-1.4 0M16.3 37.9l-1.8 1.8c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l1.8-1.8c.4-.4.4-1 0-1.4s-1-.4-1.4 0M23.3 44.8l-1.8 1.8c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l1.8-1.8c.4-.4.4-1 0-1.4s-1-.4-1.4 0" />
  </svg>
);
export default IconStrawberry;
