import * as React from "react";
const IconChicken = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 26.1c-.4-.6-1-.9-1.6-.9H24.9c-.2 0-.3-.1-.4-.3l-3-12.7c-.3-1.4-1.2-2.5-2.5-3.2l3-3c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-3 3v-4c0-.6-.4-1-1-1s-1 .4-1 1v3.7l-2.7-2.7c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l3 3c-1.6.8-2.7 2.3-2.8 4.2l-.1 1.6H7.7c-.6 0-1 .4-1 1s.4 1 1 1h3.8l-.8 13.8V32c.2 7 5.3 12.9 12.4 13.9l4.9.9c.2 0 .3.2.3.4v2.2c0 1.8 1.4 3.2 3.2 3.2h.8v5.6h-4.1c-1.3 0-2.3 1-2.3 2.3 0 .6.4 1 1 1s1-.4 1-1q0-.3.3-.3h10.3q.3 0 .3.3c0 .6.4 1 1 1s1-.4 1-1c0-1.3-1-2.3-2.3-2.3h-4.2v-5.6h1.1c1.8 0 3.2-1.4 3.2-3.2v-1.7c0-.2.2-.4.4-.4 4.2 0 7.6-3.4 7.6-7.6v-2.4c0-.2.2-.4.4-.4h.3c3.5 0 6.7-2.1 8.4-5.6l1.4-3.3c.3-.7.2-1.4-.1-1.9m-3.1 4.3c-1.4 2.8-3.9 4.4-6.6 4.4H47c-1.3 0-2.4 1.1-2.4 2.4v2.4c0 3.1-2.5 5.6-5.6 5.6-1.3 0-2.4 1.1-2.4 2.4v1.7c0 .7-.5 1.2-1.2 1.2h-3.9c-.7 0-1.2-.5-1.2-1.2v-2.2c0-1.2-.9-2.2-2-2.4l-4.9-.9c-6.6-1.2-11.1-6.8-10.7-13.2l1.1-17.4c.1-1.6 1.4-2.8 3-2.8 1.4 0 2.6.9 2.9 2.3l3 12.4c-1 3-1.3 8.9 3.9 12.2.2.1.4.2.5.2.3 0 .7-.2.8-.5.3-.5.2-1.1-.3-1.4-3.8-2.4-3.7-6.4-3.4-8.6.3.1.5.1.8.1l30.4-.1z" />
  </svg>
);
export default IconChicken;
