import * as React from "react";
const IconGridAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.2 2H8.8C5.1 2 2 5.1 2 8.8v46.4C2 58.9 5.1 62 8.8 62h46.4c3.7 0 6.8-3.1 6.8-6.8V8.8C62 5.1 58.9 2 55.2 2M60 8.8V31H33V4h22.2C57.8 4 60 6.2 60 8.8M8.8 4H31v27H4V8.8C4 6.2 6.2 4 8.8 4M4 55.2V33h27v27H8.8C6.2 60 4 57.8 4 55.2M55.2 60H33V33h27v22.2c0 2.6-2.2 4.8-4.8 4.8" />
  </svg>
);
export default IconGridAlt1;
