import * as React from "react";
const IconCoffeeCupCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55 26.8c-2 0-3.9.9-5.2 2.4v-5.6c0-1-.7-1.7-1.7-1.7h-43c-1 0-1.7.7-1.7 1.7v13.1c0 11.4 9.2 20.6 20.6 20.6h5.1c9.6 0 17.7-6.6 20-15.5 1.3 1.9 3.4 3.2 5.8 3.2 1.9 0 3.8-.8 5.1-2.1s1.9-3 1.8-4.8v-4.3c.2-3.8-3-7-6.8-7m-7.1 9.9c0 10.2-8.4 18.6-18.7 18.6h-5.1C13.9 55.3 5.5 47 5.5 36.7V23.9h42.4zm10.8 4.8c-.9 1-2.3 1.5-3.7 1.5-2.7 0-5-2.3-5-5v-4.3c0-2.7 2.3-5 5-5s5 2.3 5 5V38c0 1.4-.4 2.6-1.3 3.5M50.4 58.6H3c-.6 0-1 .4-1 1s.4 1 1 1h47.4c.6 0 1-.4 1-1s-.4-1-1-1M13.1 14.6c.6 0 1-.4 1-1V4.4c0-.6-.4-1-1-1s-1 .4-1 1v9.2c0 .5.5 1 1 1M26.7 14.6c.6 0 1-.4 1-1V4.4c0-.6-.4-1-1-1s-1 .4-1 1v9.2c0 .5.4 1 1 1M40.3 14.6c.6 0 1-.4 1-1V4.4c0-.6-.4-1-1-1s-1 .4-1 1v9.2c0 .5.4 1 1 1" />
  </svg>
);
export default IconCoffeeCupCopy;
