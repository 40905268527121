import * as React from "react";
const IconUnlock = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.4 21.9h-6.1v-7.4c0-6.8-5.5-12.3-12.3-12.3S19.8 7.7 19.8 14.5c0 1.2 1 2.3 2.3 2.3s2.3-1 2.3-2.3c0-4.3 3.5-7.8 7.8-7.8s7.8 3.5 7.8 7.8v7.4H13.6c-3.8 0-6.9 3-6.9 6.6V43c0 10.3 8.9 18.7 19.8 18.7h11c10.9 0 19.7-8.5 19.7-19V28.5c.1-3.6-3-6.6-6.8-6.6m2.4 20.8c0 8-6.8 14.5-15.2 14.5h-11c-8.4 0-15.3-6.4-15.3-14.2V28.5c0-1.2 1.1-2.1 2.4-2.1h36.7c1.3 0 2.4.9 2.4 2.1z" />
    <path d="M36.1 39.4h-8.2c-1.8 0-3.3 1.5-3.3 3.3v8.2c0 1.8 1.5 3.3 3.3 3.3h8.2c1.8 0 3.3-1.5 3.3-3.3v-8.2c0-1.8-1.5-3.3-3.3-3.3m-1.2 10.2h-5.7v-5.7h5.7z" />
  </svg>
);
export default IconUnlock;
