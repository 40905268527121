import * as React from "react";
const IconDevops = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M36.9 32c0-2.7-2.2-4.9-4.9-4.9s-4.9 2.2-4.9 4.9 2.2 4.9 4.9 4.9 4.9-2.2 4.9-4.9m-7.8 0c0-1.6 1.3-2.9 2.9-2.9s2.9 1.3 2.9 2.9-1.3 2.9-2.9 2.9-2.9-1.3-2.9-2.9" />
    <path d="M34.5 46.7c1.2 0 2.2-1 2.2-2.2v-2.2c0-.1.1-.2.1-.2.7-.3 1.2-.8 1.8-1.2.1-.1.3-.2.4-.4l2.6 1.5c.9.5 1.7.2 2-.4l2.7-4.6c.2-.3.2-.7.1-1s-.3-.6-.6-.8L43 33.7c.1-.6.1-1.1.1-1.7s0-1.2-.1-1.7l2.8-1.6c.6-.3.8-1 .5-1.7 0 0 0-.1-.1-.1l-2.8-4.7c-.3-.5-1-.8-1.7-.5 0 0-.1 0-.1.1l-2.9 1.6c-.2-.1-.3-.3-.5-.4-.5-.5-1-.9-1.8-1.2-.1 0-.1-.1-.1-.2v-1.8c0-1.2-1-2.2-2.2-2.2h-5c-1.2 0-2.2 1-2.2 2.2v1.9c0 .1-.1.2-.1.2-.7.4-1.3.8-1.8 1.3-.1.1-.3.2-.4.3L22 22c-.9-.5-1.7-.2-2 .4L17.3 27c-.2.3-.2.7-.1 1s.3.6.6.8l2.8 1.5c-.1.5-.1 1.1-.1 1.7s0 1.1.1 1.6L18 35.2c-.6.3-.8 1-.5 1.7 0 0 0 .1.1.1l2.8 4.7c.3.6 1 .8 1.7.5 0 0 .1 0 .1-.1l2.9-1.6c.2.1.3.2.4.4.6.5 1.1.9 1.8 1.3.1 0 .1.1.1.2v2.1c0 1.2 1 2.2 2.2 2.2zm-5.2-2.3v-2.1c0-.9-.5-1.6-1.3-2-.5-.2-.9-.6-1.4-1-.2-.2-.4-.3-.6-.5-.3-.3-.7-.4-1.1-.4-.3 0-.6.1-.9.2L21.6 40l-2-3.5 2.4-1.3c.7-.4 1-1.1.9-1.8-.1-.5-.1-1-.1-1.5s0-1 .1-1.4c.1-.8-.2-1.6-.9-1.9l-2.3-1.3 2-3.5 2.4 1.4c.6.4 1.4.3 2-.2.2-.2.4-.3.6-.5.5-.4.9-.8 1.4-1 .8-.4 1.3-1.2 1.3-2v-1.9c0-.1.1-.2.2-.2h5c.1 0 .2.1.2.2v1.8c0 .9.5 1.6 1.3 2 .5.2.8.5 1.3 1 .2.2.4.4.6.5.6.4 1.3.5 2 .2l2.4-1.4 2 3.5-2.3 1.3c-.7.4-1 1.1-.9 1.9.1.4.1.9.1 1.5 0 .5 0 1-.1 1.5-.1.8.2 1.5.9 1.9l2.3 1.2-2 3.5-2.4-1.4c-.6-.4-1.4-.3-2 .2-.2.2-.4.4-.6.5-.5.4-.8.7-1.3 1-.8.4-1.3 1.2-1.3 2v2.2c0 .1-.1.2-.2.2h-5q-.3 0-.3-.3" />
    <path d="M22.4 52.8C14.3 49.1 9.1 40.9 9.1 32c0-12.6 10.1-22.8 22.7-22.9l-3.1 3.4c-.4.4-.3 1 .1 1.4.2.2.4.3.7.3s.5-.1.7-.3l4.7-5.1c.4-.4.4-1 0-1.4l-4.7-5.1c-.4-.4-1-.4-1.4-.1-.4.4-.4 1-.1 1.4L31.8 7C18.1 7.2 7.1 18.3 7.1 32c0 9.7 5.7 18.5 14.4 22.6.1.1.3.1.4.1q.6 0 .9-.6c.3-.5.1-1.1-.4-1.3M56.9 32c0-9.6-5.6-18.5-14.3-22.6-.5-.2-1.1 0-1.3.5s0 1.1.5 1.3C49.8 15 55 23.1 55 32c0 12.4-9.9 22.5-22.2 22.9l3.1-3.4c.4-.4.3-1-.1-1.4s-1-.3-1.4.1l-4.7 5.1c-.3.4-.3 1 0 1.3l4.7 5.1c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 .1-1.4l-3.1-3.4c13.3-.3 24.1-11.4 24.1-24.9" />
  </svg>
);
export default IconDevops;
