import * as React from "react";
const IconHome2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.6 34.9H33v-7.5h15.7c2.2 0 4-1.8 4-4V6c0-2.2-1.8-4-4-4H15.3c-2.2 0-4 1.8-4 4v17.3c0 2.2 1.8 4 4 4H31v7.5H10.4c-2.2 0-4 1.8-4 4V61c0 .6.4 1 1 1s1-.4 1-1V46.9h47.2V61c0 .6.4 1 1 1s1-.4 1-1V38.8c0-2.1-1.8-3.9-4-3.9M13.3 23.3V6c0-1.1.9-2 2-2h33.4c1.1 0 2 .9 2 2v17.3c0 1.1-.9 2-2 2H15.3c-1.1 0-2-.9-2-2M8.4 38.9c0-1.1.9-2 2-2h43.2c1.1 0 2 .9 2 2V45H8.4z" />
  </svg>
);
export default IconHome2;
