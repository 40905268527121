import { useState, useCallback } from 'react';
import axiosInstance from '../api/axios';

const useRequest = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const sendRequest = useCallback(async (config) => {
        setLoading(true);
        setError(null);
        try {
            const response = await axiosInstance(config);
            setData(response);
            return response; // This will be a {status, data, message} object
        } catch (err) {
            if (config.url) err["url"] = config.url // To differentiate which request is throwing the error 
            console.log(err);
            setError(err);
            return err; // This will be a {status, message} object
        } finally {
            setLoading(false);
        }
    }, []);

    return { sendRequest, data, error, loading };
};

export default useRequest;