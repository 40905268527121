import * as React from "react";
const IconLeafAlt3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.1 3.2c-.3-.2-.7-.3-1-.1-7 2.8-13.8 4.3-21.9 5.7-5 1.1-8.7 3.7-11.2 7.8-5.4 9.1-2.7 22.6-1.6 27-2.5 4.5-4.9 9.9-6.8 16.2-.2.5.1 1.1.7 1.2h.3c.4 0 .8-.3 1-.7 1.7-5.7 3.8-10.5 6.1-14.7h.2c.2 0 .4 0 .5-.1 3.6-2.2 7.7-3.7 11.7-5 5.8-2.1 11.4-4 15.3-8.2 5.8-6.1 6.3-14.4 6.9-23.2.1-1.7.2-3.3.4-5q-.3-.6-.6-.9m-2 5.8c-.6 8.4-1.1 16.3-6.4 22-3.6 3.8-8.9 5.7-14.5 7.6-3.3 1.2-6.8 2.4-10 4.1 1.8-3 3.7-5.6 5.6-7.8 2.8-1.4 6.9-2.4 10-2.4.6 0 1-.5 1-1 0-.6-.4-1-1-1-2.1 0-4.7.4-7.1 1.1 5.2-5.3 9.4-7.5 9.4-7.5.5-.2.7-.8.4-1.3-.2-.5-.8-.7-1.3-.4-.3.2-4.2 2.1-9 6.8v-3.6c0-1.9-.4-3.5-1.2-5.1-.2-.5-.8-.7-1.3-.4-.5.2-.7.8-.4 1.3.7 1.3 1 2.6 1 4.2l.1 5.5v.1c-2.4 2.6-5 5.8-7.5 9.8-1.1-5.4-2.5-16.2 1.8-23.5 2.1-3.6 5.5-5.9 9.8-6.8C38.2 9.4 44.7 8 51.3 5.5c-.1 1.2-.1 2.3-.2 3.5" />
  </svg>
);
export default IconLeafAlt3;
