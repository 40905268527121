import * as React from "react";
const IconHome3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.1 25.4 33.6 2.5c-.7-.6-1.8-.6-2.5 0L3.9 25.4c-.6.5-.9 1.4-.6 2.2s1 1.3 1.8 1.3H6V58c0 2.2 1.8 4 4 4h44c2.2 0 4-1.8 4-4V28.8h.9c.8 0 1.5-.5 1.8-1.3s.1-1.6-.6-2.1M56 58c0 1.1-.9 2-2 2H34.1c6.3-2.9 9.4-9.6 9.5-9.9 1.5-2.9 2.9-9.3-1.8-12.6-1.6-1.1-3.2-1.4-4.8-1-2.3.6-4 2.5-5 3.8-1-1.3-2.7-3.3-5-3.8-1.6-.4-3.3 0-4.8 1-4.8 3.3-3.3 9.7-1.8 12.6.1.3 3.4 6.9 9.8 9.9H10c-1.1 0-2-.9-2-2V28.8h48zm-23.9.7c-6.4-2.3-10-9.4-10-9.5-.1-.3-3.4-6.9 1.2-10.1.8-.6 1.6-.8 2.4-.8.3 0 .6 0 .8.1 2.7.6 4.6 4.1 4.6 4.2.3.6 1.4.6 1.8 0 0 0 1.9-3.5 4.6-4.2 1.1-.3 2.1 0 3.2.7 4.5 3.1 1.3 9.8 1.2 10.1 0 .1-3.4 7.2-9.8 9.5M57 26.8H5.3L32.3 4l26.4 22.8z" />
  </svg>
);
export default IconHome3;
