import * as React from "react";
const IconGymBag = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M46.4 25h-3.9v-9.2c0-4.5-3.7-8.2-8.2-8.2h-4.6c-4.5 0-8.2 3.7-8.2 8.2V25h-3.9C9 25 2 32 2 40.7s7 15.6 15.6 15.6h28.8c8.6 0 15.6-7 15.6-15.6S55 25 46.4 25m-22.8-9.1c0-3.4 2.8-6.2 6.2-6.2h4.6c3.4 0 6.2 2.8 6.2 6.2V25h-17zm-6 11.1h28.7C53.9 27 60 33.1 60 40.7c0 .8-.1 1.7-.2 2.5H4.2c-.1-.9-.2-1.7-.2-2.5C4 33.1 10.1 27 17.6 27m28.8 27.3H17.6c-5.9 0-11-3.8-12.9-9.1h54.5c-1.8 5.3-6.9 9.1-12.8 9.1" />
  </svg>
);
export default IconGymBag;
