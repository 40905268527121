import * as React from "react";
const IconLiveStream = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M24.5 32.8c-.6 0-1 .4-1 1v15.9c0 .6.4 1 1 1s1-.4 1-1V33.8c0-.5-.5-1-1-1M40.6 32.8c-.5 0-1 .4-1.1.9l-1.2 15h-4l-1.2-15c0-.6-.5-1-1.1-.9-.6 0-1 .5-.9 1.1l1.3 15.9c0 .5.5.9 1 .9h5.9c.5 0 1-.4 1-.9l1.3-15.9c0-.6-.4-1.1-1-1.1" />
    <path d="M58 24H33v-6.6c2.4-.5 4.2-2.6 4.2-5.1 0-3-2.3-5.3-5.2-5.3s-5.2 2.3-5.2 5.2c0 2.5 1.8 4.7 4.2 5.1V24H6c-2.2 0-4 1.8-4 4v27.6c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V28c0-2.2-1.8-4-4-4M28.8 12.2c0-1.8 1.4-3.2 3.2-3.2s3.2 1.4 3.2 3.2-1.4 3.2-3.2 3.2-3.2-1.4-3.2-3.2M60 55.6c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V28c0-1.1.9-2 2-2h52c1.1 0 2 .9 2 2z" />
    <path d="M45.3 17.3c.2.2.5.3.7.3s.5-.1.7-.3c2.9-2.9 2.9-7.7 0-10.6-.4-.4-1-.4-1.4 0s-.4 1 0 1.4c2.2 2.2 2.2 5.7 0 7.8-.4.4-.4 1 0 1.4M52.4 19.3c.2.2.5.3.7.3s.5-.1.7-.3c1.9-1.9 3-4.5 3-7.3s-1.1-5.3-3-7.3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4c1.6 1.6 2.4 3.6 2.4 5.9s-.9 4.3-2.4 5.9c-.4.4-.4 1 0 1.4M18 17.6c.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4-2.2-2.2-2.2-5.7 0-7.8.4-.4.4-1 0-1.4s-1-.4-1.4 0c-2.9 2.9-2.9 7.7 0 10.6.2.2.4.3.7.3M10.9 19.6c.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4-3.2-3.2-3.2-8.5 0-11.7.4-.4.4-1 0-1.4s-1-.4-1.4 0c-4 4-4 10.5 0 14.6.2.1.5.2.7.2M18.4 48.7h-6.5V33.8c0-.6-.4-1-1-1s-1 .4-1 1v14.9c0 1.1.9 2 2 2h6.4c.6 0 1-.4 1-1s-.3-1-.9-1M54.4 34.8c.6 0 1-.4 1-1s-.4-1-1-1h-5.9c-.6 0-1 .4-1 1v15.899999999999999c0 .6.4 1 1 1h5.9c.6 0 1-.4 1-1s-.4-1-1-1h-4.9v-6h4.9c.6 0 1-.4 1-1s-.4-1-1-1h-4.9v-6h4.9z" />
  </svg>
);
export default IconLiveStream;
