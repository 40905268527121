import * as React from "react";
const IconIslandAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54 7.6c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8m0 14c-3.3 0-6-2.7-6-6s2.7-6 6-6 6 2.7 6 6-2.7 6-6 6M54.3 55.3c-1.1 0-1.6-.5-2.5-1.4-.8-.9-1.9-2.1-3.9-2.1S44.8 53 44 53.9s-1.4 1.4-2.5 1.4-1.6-.5-2.5-1.4c-.4-.4-.8-.8-1.3-1.2-1-5.5-7.8-9.6-16.2-9.6l-.8-1.6c0-.1-3.4-7.4-2.3-13 .2-1.2.7-2.4 1.2-3.7.5-1.4 1-2.8 1.3-4.3v-.2l.3-.9c1.8.6 4.6 3 5.4 5.8.1.4.5.7 1 .7h.3c.5-.1.8-.7.7-1.2-.8-3-3.7-6-6.3-7 2.2-3 6.7-4.6 11.5-3.8.5.1 1.1-.3 1.2-.8s-.3-1.1-.8-1.2c-5.3-1-10.4.8-13.1 4.1-.4-2.4-1.4-4.6-2.5-6.2-.8-1.2-2.2-2.8-3.9-3.1-.5-.1-1.1.3-1.2.8s.3 1.1.8 1.2c.8.1 1.7 1 2.6 2.2 1 1.4 1.8 3.5 2.2 5.6-2.9-2-7-2.2-10.2-.2-.5.3-.6.9-.3 1.4s.9.6 1.4.3c3-1.9 7.1-1.3 9.3 1.3 0 .3 0 .5-.1.8-.3 1.4-.8 2.8-1.3 4.1-.5 1.4-1 2.7-1.3 4.1-1.1 6.2 2.3 13.8 2.5 14.2l.4.9c-8 .7-14.1 5.3-14.1 10.8v.1c-.8.5-1.4 1-2.4 1-.6 0-1 .4-1 1s.4 1 1 1c2 0 3.1-1.2 4-2.1.8-.8 1.3-1.4 2.5-1.4s1.7.6 2.5 1.4c.8.9 1.9 2.1 4 2.1 2 0 3.1-1.2 3.9-2.1s1.4-1.4 2.5-1.4 1.6.5 2.5 1.4c.8.9 1.9 2.1 3.9 2.1s3.1-1.2 3.9-2.1 1.4-1.4 2.5-1.4 1.6.5 2.5 1.4c.8.9 1.9 2.1 3.9 2.1s3.1-1.2 3.9-2.1 1.4-1.4 2.5-1.4 1.6.5 2.5 1.4c.8.9 1.9 2.1 3.9 2.1.6 0 1-.4 1-1s-.6-1-1.1-1m-23.2-1.4c-.8.9-1.4 1.4-2.5 1.4s-1.6-.5-2.5-1.4c-.8-.9-1.9-2.1-3.9-2.1s-3.1 1.2-3.9 2.1-1.4 1.4-2.5 1.4c-1.2 0-1.7-.6-2.5-1.4-.8-.9-1.9-2.1-4-2.1-.7 0-1.4.2-1.9.4 1.4-4 7.1-7.1 14-7.1 6.6 0 12.3 2.8 13.9 6.7H35c-2 0-3 1.2-3.9 2.1" />
  </svg>
);
export default IconIslandAlt;
