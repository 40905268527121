import * as React from "react";
const IconHop = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.8 26.3c-.2-.2-.5-.3-.7-.3-2.3.1-4.5.5-6.6 1.1-.3-5.4-1.9-10.8-2.8-13.6q-.15-.45-.6-.6c-.3-.1-.6-.1-.9.1-1.8 1.2-3.8 2.6-5.9 4.2-2.6-6-6.4-11.2-8.6-13.9-.4-.5-1.2-.5-1.6 0-2.1 2.7-6 7.9-8.6 13.9-2-1.6-4.1-3.1-5.9-4.2-.3-.2-.6-.2-.9-.1q-.45.15-.6.6c-.9 2.7-2.6 8.1-2.8 13.6-2.1-.6-4.3-1-6.6-1.1-.3 0-.5.1-.7.3 0 .1-.1.4-.1.7v5.9C4.5 47.4 17.2 60.5 32 61c14.8-.5 27.4-13.6 27.1-28v-6c0-.3-.1-.6-.3-.7M47.2 15.4c.9 2.8 2.1 7.3 2.3 11.8 0 .2.1.4.2.5C42 30.5 36 36.3 33 43.7v-9c0-1.5.3-3.2.9-4.9 2.4-6.3 8.5-11.1 13.3-14.4m-22.9 3.1c2.2-5.3 5.5-10 7.7-12.9 2.2 2.9 5.5 7.6 7.7 12.9v.1c-3.2 2.9-6.2 6.4-7.7 10.5v.1-.1c-1.6-4.2-4.5-7.6-7.7-10.6q0 .15 0 0m-9.8 8.7c.2-4.5 1.4-8.9 2.3-11.8 4.8 3.2 10.9 8 13.3 14.3.6 1.8.9 3.4.9 4.9v9c-3-7.4-9-13.2-16.7-16 .1 0 .2-.2.2-.4M6.9 33v-4.9C20.5 29.3 31 40.5 31 54v4.9C17.7 57.9 6.6 46.1 6.9 33m50.2 0c.3 13.1-10.9 25-24.1 26v-5c0-13.5 10.5-24.7 24.1-26z" />
  </svg>
);
export default IconHop;
