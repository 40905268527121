import * as React from "react";
const IconGoblet = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M52.8 4.2c-1-1.4-2.7-2.2-4.4-2.2H15.5c-1.7 0-3.3.8-4.4 2.2-1 1.3-1.3 3-.8 4.6 2.8 9.1 11.2 15.6 20.7 16v10.8c-2.6.5-4.6 2.8-4.6 5.5 0 1.8.9 3.4 2.2 4.4-.4 3.4-2 10.4-7.9 14.7-.4.3-.5.7-.4 1.1s.5.7 1 .7h21.4c.4 0 .8-.3 1-.7.1-.4 0-.9-.4-1.1-6.2-4.4-7.5-11.8-7.8-14.7 1.3-1 2.1-2.6 2.1-4.4 0-2.7-2-5-4.6-5.5V24.8c9.6-.4 17.7-6.7 20.5-16 .6-1.6.3-3.2-.7-4.6M40 60H24.1c4.4-4.3 5.9-10.1 6.4-13.5.5.1 1 .2 1.5.2s1.1-.1 1.6-.2c.5 3.1 1.9 9 6.4 13.5m-4.3-18.9c0 2-1.6 3.6-3.6 3.6s-3.6-1.6-3.6-3.6 1.6-3.6 3.6-3.6c1.9 0 3.6 1.7 3.6 3.6m16-32.9C49.1 17 41.1 22.8 32 22.8c-9 0-17.1-6-19.7-14.6-.3-1-.1-2 .5-2.8.6-.9 1.7-1.4 2.7-1.4h32.9c1.1 0 2.1.5 2.8 1.4.6.8.8 1.9.5 2.8" />
  </svg>
);
export default IconGoblet;
