import * as React from "react";
const IconTabletAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M46 2H18c-2.8 0-5 2.2-5 5v50c0 2.8 2.2 5 5 5h28c2.8 0 5-2.2 5-5V7c0-2.8-2.2-5-5-5m3 55c0 1.7-1.3 3-3 3H18c-1.7 0-3-1.3-3-3V7c0-1.7 1.3-3 3-3h28c1.7 0 3 1.3 3 3z" />
    <path d="M27 11.7h-5c-.6 0-1 .4-1 1s.4 1 1 1h5c.6 0 1-.4 1-1s-.5-1-1-1M42 11.7h-5c-.6 0-1 .4-1 1s.4 1 1 1h5c.6 0 1-.4 1-1s-.5-1-1-1M27 24.6h-5c-.6 0-1 .4-1 1s.4 1 1 1h5c.6 0 1-.4 1-1s-.5-1-1-1M42 24.6h-5c-.6 0-1 .4-1 1s.4 1 1 1h5c.6 0 1-.4 1-1s-.5-1-1-1M27 37.4h-5c-.6 0-1 .4-1 1s.4 1 1 1h5c.6 0 1-.4 1-1s-.5-1-1-1M42 37.4h-5c-.6 0-1 .4-1 1s.4 1 1 1h5c.6 0 1-.4 1-1s-.5-1-1-1M27 50.3h-5c-.6 0-1 .4-1 1s.4 1 1 1h5c.6 0 1-.4 1-1s-.5-1-1-1M42 50.3h-5c-.6 0-1 .4-1 1s.4 1 1 1h5c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconTabletAlt;
