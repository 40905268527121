import React from "react";
import BringzzImage from "../BringzzImage";
import BringzzText from "../BringzzText";
import BringzzIcon from "../BringzzIcon";

const BringzzCollectionItem = ({
    collection,
    items = 0,
    setOpenDrawer,
    setSelectedCollection,
    onClick
}) => (
    <div
        onClick={onClick}
        className='flex items-center space-x-4 px-4 p-2 border-1 border-b border-slate-300'
    >
        <BringzzImage className='w-16 h-16 cursor-pointer' src='/images/default-image.png' alt='Image 1' />
        <div className='flex-grow'>
            <BringzzText tag='h4' className='text-lg font-semibold cursor-pointer'>
                {collection.name}
            </BringzzText>
            <BringzzText className='text-sm text-gray-500'>{items} items</BringzzText>
        </div>
        <BringzzIcon
            size='22'
            icon='IconMore'
            className='rotate-90 cursor-pointer'
            folder='LineIcons'
            onClick={e => {
                e.stopPropagation();
                setOpenDrawer(true);
                setSelectedCollection(collection);
            }}
        />
    </div>
);

export default BringzzCollectionItem;
