import * as React from "react";
const IconRocketGrowth = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M36 34.1V33c0-.6-.4-1-1-1s-1 .4-1 1v1.5c0 .3.1.5.3.7l6.3 6.2v6.1H14.7v-6.1l6.3-6.2c.2-.2.3-.4.3-.7V23.1c0-10.9 3-15.6 6.4-18.8 2.4 2.3 4 4.8 5 8 .1.5.5.8 1 .8.6 0 1-.5 1-1v-.3c-1.2-3.9-3.2-6.9-6.3-9.6-.4-.3-.9-.3-1.3 0-3.8 3.3-7.7 8.4-7.7 20.9v11L13 40.3c-.2.2-.3.4-.3.7v7.5c0 .6.4 1 1 1H22c-.3.7-.4 1.4-.4 2.1 0 3 4.6 9.2 5.2 9.9.2.3.5.4.8.4s.6-.1.8-.4c.5-.7 5.2-6.9 5.2-9.9 0-.7-.1-1.4-.4-2.1h8.3c.6 0 1-.4 1-1V41c0-.3-.1-.5-.3-.7zm-4.4 17.6c0 1.5-2.2 5.1-4 7.6-1.8-2.5-4-6.1-4-7.6q0-1.2.6-2.1H31q.6.9.6 2.1" />
    <path d="M46.3 18.4H43c-1.6 0-2.9-1.3-2.9-2.9s1.3-2.9 2.9-2.9h7.3c.6 0 1-.4 1-1s-.4-1-1-1h-3.5V8.3c0-.6-.4-1-1-1s-1 .4-1 1v2.3H43c-2.7 0-4.9 2.2-4.9 4.9s2.2 4.9 4.9 4.9h3.4c1.6 0 2.9 1.3 2.9 2.9s-1.3 2.9-2.9 2.9h-7.3c-.6 0-1 .4-1 1s.4 1 1 1h5.7v2.3c0 .6.4 1 1 1s1-.4 1-1v-2.4c2.5-.2 4.5-2.3 4.5-4.8 0-2.7-2.3-4.9-5-4.9" />
  </svg>
);
export default IconRocketGrowth;
