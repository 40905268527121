import * as React from "react";
const IconWindCloud = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M48.9 12.3c-.5-.1-.9-.4-1.1-.8-2.4-5.3-7.6-8.7-13.4-8.7-6 0-11.3 3.6-13.6 9.1-.2.5-.7.8-1.3.7-3-.6-6.2-.3-9.1 1-4.4 2-7.6 6.2-8.3 11-.7 4.3.6 8.6 3.4 11.9 2.8 3.2 6.8 5.1 11.1 5.1.6 0 1-.4 1-1s-.4-1-1-1c-3.7 0-7.2-1.6-9.6-4.4s-3.5-6.5-2.9-10.3c.6-4.1 3.4-7.8 7.2-9.5 2.5-1.1 5.2-1.5 7.9-.9 1.5.3 2.9-.5 3.5-1.9 2-4.8 6.6-7.8 11.7-7.8 5 0 9.5 2.9 11.6 7.5.5 1.1 1.5 1.9 2.7 2 7 .8 11.9 6.9 11.2 13.9-.1 1-.3 2-.7 2.9-.2.5.1 1.1.6 1.3s1.1-.1 1.3-.6c.4-1.1.7-2.3.8-3.4.8-8.2-4.9-15.2-13-16.1" />
    <path d="M54.5 38.3c0-2.8-2.2-5-5-5-.6 0-1 .4-1 1s.4 1 1 1c1.7 0 3 1.4 3 3 0 1.7-1.4 3-3 3h-23c-.6 0-1 .4-1 1s.4 1 1 1h23c2.8 0 5-2.3 5-5M54.7 52.2h-23c-.6 0-1 .4-1 1s.4 1 1 1h23c1.7 0 3 1.4 3 3 0 .8-.4 1.6-1 2.2-.4.4-.4 1-.1 1.4.2.2.5.3.7.3s.5-.1.7-.3c1-1 1.6-2.3 1.6-3.7.1-2.6-2.2-4.9-4.9-4.9" />
  </svg>
);
export default IconWindCloud;
