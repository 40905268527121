import * as React from "react";
const IconSpeechBubble4 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <circle cx={7.4} cy={58.8} r={2.2} />
    <path d="M56 12.1c-2.3-2.1-5.4-3.3-8.5-3.2C45.3 4.7 40.6 2 35.4 2s-9.9 2.7-12.1 6.9c-3.1-.1-6.2 1.1-8.5 3.2-2.5 2.3-3.9 5.5-3.9 8.8 0 4.6 2.8 8.9 7 10.9-.1 4.1 2.1 7.7 5.7 9.6 1.6.8 3.3 1.2 5 1.2 2.2 0 4.3-.7 6.1-2 3.2 2.3 7.3 2.6 10.8.9 3.6-1.8 5.8-5.2 6-9.1 5-1.6 8.4-6.2 8.4-11.4-.1-3.4-1.5-6.6-3.9-8.9m-5.8 18.5c-.5.1-.8.5-.8 1 .1 3.4-1.7 6.5-4.8 8s-6.7 1.1-9.3-1.1c-.4-.3-.9-.3-1.3 0-2.7 2.3-6.4 2.7-9.5 1-3.1-1.6-4.9-4.8-4.6-8.3 0-.4-.2-.8-.6-1-3.8-1.5-6.4-5.2-6.4-9.3 0-2.8 1.1-5.4 3.2-7.3 1.9-1.7 4.3-2.7 6.9-2.7h.8c.4 0 .8-.2 1-.6C26.5 6.5 30.7 4 35.4 4s8.8 2.5 10.6 6.3c.2.4.6.6 1 .6 2.8-.2 5.6.7 7.7 2.7 2.1 1.9 3.2 4.5 3.2 7.3-.1 4.5-3.2 8.5-7.7 9.7M16.7 46.8c-2.5 0-4.6 2.1-4.6 4.6s2.1 4.6 4.6 4.6 4.6-2.1 4.6-4.6-2.1-4.6-4.6-4.6m0 7.2c-1.4 0-2.6-1.2-2.6-2.6s1.2-2.6 2.6-2.6 2.6 1.2 2.6 2.6-1.2 2.6-2.6 2.6" />
    <path d="M42 17.6H28.7c-.6 0-1 .4-1 1s.4 1 1 1H42c.6 0 1-.4 1-1s-.4-1-1-1M42 26.3H28.7c-.6 0-1 .4-1 1s.4 1 1 1H42c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconSpeechBubble4;
