import * as React from "react";
const IconHook = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M29.7 46.6c.7-.3 1.5-.4 2.3-.4.6 0 1-.4 1-1v-9.5h6.8c.4 0 .7-.2.9-.5l7.8-13.6c.2-.3.2-.7 0-1L40.7 7c-.2-.3-.5-.5-.9-.5H33V3c0-.6-.4-1-1-1s-1 .4-1 1v3.5h-6.8c-.4 0-.7.2-.9.5l-7.8 13.6c-.2.3-.2.7 0 1l7.8 13.6c.2.3.5.5.9.5H31v8.6c-.7.1-1.3.2-2 .5-3.5 1.3-5.9 4.6-5.9 8.4 0 4.9 4 8.9 8.9 8.9s8.9-4 8.9-8.9c0-.6-.4-1-1-1s-1 .4-1 1c0 3.8-3.1 6.9-6.9 6.9s-6.9-3.1-6.9-6.9c0-3 1.8-5.7 4.6-6.6M24.8 8.5h14.5l4.1 7H20.7zm-5.3 9.1h24.9l2 3.5-2 3.5H19.5l-2-3.5zm1.2 9h22.6l-4.1 7H24.8z" />
  </svg>
);
export default IconHook;
