import * as React from "react";
const IconDerrickAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.7 2H8.3c-2.2 0-4 1.8-4 4v4.7c0 2.2 1.8 4 4 4h5v34.5H10c-.4 0-.8.3-.9.6l-2 5.3c-.6 1.5-.4 3.3.6 4.7s2.5 2.2 4.1 2.2h15.6c1.7 0 3.3-.8 4.2-2.3.9-1.4 1.1-3.2.4-4.7l-2.2-5.2q-.3-.6-.9-.6h-2.6V14.7h16.9V39c0 .6.4 1 1 1H47v4.1c0 .5.4 1 1 1 2.1 0 3.9 1.8 3.9 3.9 0 2.2-1.8 3.9-3.9 3.9-1.8 0-3.4-1.2-3.8-3-.1-.5-.7-.9-1.2-.7-.5.1-.9.7-.7 1.2.6 2.7 3 4.6 5.8 4.6 3.3 0 5.9-2.7 5.9-5.9 0-2.9-2.1-5.3-4.9-5.8V40h2.5c.6 0 1-.4 1-1V14.7h3.1c2.2 0 4-1.8 4-4V6c0-2.2-1.8-4-4-4M24.3 32.3l-9 7.9V27.8l9-7.9zm5.8 23.5c.4 1 .3 2-.3 2.9s-1.5 1.4-2.5 1.4H11.8c-1 0-1.9-.5-2.5-1.3S8.6 56.9 9 56l1.8-4.7h17.4zm-14.8-6.6v-6.4l9-7.9v14.3zm9-32-9 7.9V14.7h9zM50.5 38h-5.3V14.7h5.3zm7.2-27.3c0 1.1-.9 2-2 2H8.3c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2h47.3c1.1 0 2 .9 2 2v4.7z" />
  </svg>
);
export default IconDerrickAlt2;
