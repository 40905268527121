import * as React from "react";
const IconSmartHome = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="prefix__Layer_1"
    x={0}
    y={0}
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <style>{".prefix__st0{fill-rule:evenodd;clip-rule:evenodd}"}</style>
    <path
      d="M33 46.3c0 .6-.5 1-1 1s-1-.4-1-1 .5-1 1-1c.6 0 1 .4 1 1M36.3 41.3c.4.3.6.9.2 1.4-.3.5-1 .6-1.4.2-.9-.7-2-1-3.1-1s-2.2.3-3.1 1c-.5.3-1.1.2-1.4-.2s-.2-1.1.2-1.4C29 40.4 30.5 40 32 40s3 .4 4.3 1.3M40.2 37.6c.4.3.5 1 .1 1.4s-1 .5-1.4.1c-1.9-1.6-4.3-2.5-6.8-2.5s-4.9.9-6.8 2.5c-.4.4-1.1.3-1.4-.1-.4-.4-.3-1.1.1-1.4 2.3-1.9 5.2-3 8.1-3s5.8 1.1 8.1 3"
      className="prefix__st0"
    />
    <path
      d="M44 35.2c-.4.4-1 .5-1.4.1-2.9-2.6-6.7-4-10.6-4s-7.6 1.4-10.6 4c-.4.4-1 .3-1.4-.1s-.3-1 .1-1.4c3.3-2.9 7.5-4.5 11.9-4.5s8.6 1.6 11.9 4.5c.4.4.4 1 .1 1.4M33 46.3c0 .6-.5 1-1 1s-1-.4-1-1 .5-1 1-1c.6 0 1 .4 1 1M36.3 41.3c.4.3.6.9.2 1.4-.3.5-1 .6-1.4.2-.9-.7-2-1-3.1-1s-2.2.3-3.1 1c-.5.3-1.1.2-1.4-.2s-.2-1.1.2-1.4C29 40.4 30.5 40 32 40s3 .4 4.3 1.3"
      className="prefix__st0"
    />
    <path
      d="M40.2 37.6c.4.3.5 1 .1 1.4s-1 .5-1.4.1c-1.9-1.6-4.3-2.5-6.8-2.5s-4.9.9-6.8 2.5c-.4.4-1.1.3-1.4-.1-.4-.4-.3-1.1.1-1.4 2.3-1.9 5.2-3 8.1-3s5.8 1.1 8.1 3"
      className="prefix__st0"
    />
    <path
      d="M32 29.3c4.4 0 8.6 1.6 11.9 4.5.4.4.5 1 .1 1.4s-1 .5-1.4.1c-2.9-2.6-6.7-4-10.6-4s-7.6 1.4-10.6 4c-.4.4-1 .3-1.4-.1s-.3-1 .1-1.4c3.3-2.9 7.5-4.5 11.9-4.5"
      className="prefix__st0"
    />
    <path
      d="M57.7 30.1 34 9.1c-1.1-1-2.8-1-4 0l-23.7 21c-.4.4-.5 1-.1 1.4s1 .5 1.4.1L9.5 30v22.7c0 1.7 1.3 3 3 3h39c1.7 0 3-1.3 3-3V30l1.9 1.7c.4.4 1 .3 1.4-.1.3-.5.3-1.1-.1-1.5m-5.2 22.6c0 .5-.4 1-1 1h-39c-.5 0-1-.4-1-1V28.2l19.8-17.6c.4-.3.9-.3 1.3 0l19.8 17.6v24.5z"
      className="prefix__st0"
    />
    <path
      d="M44 35.2c-.4.4-1 .5-1.4.1-2.9-2.6-6.7-4-10.6-4s-7.6 1.4-10.6 4c-.4.4-1 .3-1.4-.1s-.3-1 .1-1.4c3.3-2.9 7.5-4.5 11.9-4.5s8.6 1.6 11.9 4.5c.4.4.4 1 .1 1.4"
      className="prefix__st0"
    />
    <path
      d="M40.3 39c-.4.4-1 .5-1.4.1-1.9-1.6-4.3-2.5-6.8-2.5s-4.9.9-6.8 2.5c-.4.4-1.1.3-1.4-.1-.4-.4-.3-1.1.1-1.4 2.3-1.9 5.2-3 8.1-3 3 0 5.8 1 8.1 3 .4.3.4 1 .1 1.4M36.5 42.7c-.3.5-1 .6-1.4.2-.9-.7-2-1-3.1-1s-2.2.3-3.1 1c-.5.3-1.1.2-1.4-.2s-.2-1.1.2-1.4C29 40.4 30.5 40 32 40s3 .5 4.3 1.4c.4.3.5.9.2 1.3M33 46.3c0 .6-.5 1-1 1s-1-.4-1-1 .5-1 1-1c.6 0 1 .4 1 1"
      className="prefix__st0"
    />
  </svg>
);
export default IconSmartHome;
