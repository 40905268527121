import * as React from "react";
const IconSwimmingPool = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M14.2 14.1V41c-1.7.1-3.3.7-4.7 1.9l-4.5 4c-.4.4-.5 1-.1 1.4s1 .5 1.4.1l4.5-3.8c2.2-1.9 5.5-1.9 7.8 0 3 2.5 7.3 2.5 10.3 0 2.3-1.9 5.5-1.9 7.8 0 3 2.5 7.3 2.5 10.3 0 2.3-1.9 5.5-1.9 7.8 0l4.5 3.8c.2.2.4.2.6.2.3 0 .6-.1.8-.4.4-.4.3-1.1-.1-1.4L56.1 43c-3-2.5-7.3-2.5-10.3 0-2.2 1.9-5.5 1.9-7.8 0-.1-.1-.3-.2-.4-.3V16.4c0-8-6.5-14.4-14.4-14.4h-.5c-.6 0-1 .4-1 1s.4 1 1 1h.5C30 4 35.6 9.5 35.6 16.4v2.8H16.2v-5C16.2 7.4 10.7 2 4 2c-.5 0-1 .4-1 1s.4 1 1 1c5.6 0 10.2 4.5 10.2 10.1m13.4 29c-2.2 1.9-5.5 1.9-7.8 0-1.1-.9-2.3-1.5-3.6-1.7v-6.8h19.4v7.2c-2.7-1.1-5.7-.6-8 1.3m8-21.9v11.4H16.2V21.2zM56.1 56.4c-3-2.5-7.3-2.5-10.3 0-2.2 1.9-5.5 1.9-7.7 0-3-2.5-7.3-2.5-10.3 0-2.2 1.9-5.5 1.9-7.8 0-3-2.5-7.3-2.5-10.3 0L5 60.2c-.4.4-.5 1-.1 1.4s1 .5 1.4.1l4.5-3.8c2.2-1.9 5.5-1.9 7.8 0 3 2.5 7.3 2.5 10.3 0 2.3-1.9 5.5-1.9 7.8 0 3 2.5 7.3 2.5 10.3 0 2.2-1.9 5.5-1.9 7.7 0l4.5 3.8c.2.2.4.2.6.2.3 0 .6-.1.8-.4.4-.4.3-1.1-.1-1.4z" />
  </svg>
);
export default IconSwimmingPool;
