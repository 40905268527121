import * as React from "react";
const IconShoppingBag16 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m56 54.8-4.2-36.4c-.4-3.3-3.1-5.7-6.4-5.7h-3.6v-.9C41.8 6.4 37.4 2 32 2s-9.8 4.4-9.8 9.8v.9h-3.6c-3.3 0-6 2.5-6.4 5.7L8 54.8c-.2 1.8.4 3.7 1.6 5 1.2 1.4 3 2.2 4.8 2.2h35.1c1.8 0 3.6-.8 4.8-2.2 1.3-1.3 1.9-3.2 1.7-5m-31.8-43C24.2 7.5 27.7 4 32 4s7.8 3.5 7.8 7.8c0 .3 0 .6-.1.9H24.3c-.1-.3-.1-.6-.1-.9m-10 6.8c.3-2.3 2.2-4 4.4-4h26.7c2.3 0 4.2 1.7 4.4 4l3.1 27.3c-.1 0-.2-.1-.3-.1H11.1zm38.7 39.9c-.9 1-2.1 1.5-3.4 1.5h-35c-1.3 0-2.5-.5-3.3-1.5S9.9 56.3 10 55l.8-7.2h41.8c.2 0 .3-.1.5-.1L54 55c.1 1.3-.3 2.6-1.1 3.5" />
  </svg>
);
export default IconShoppingBag16;
