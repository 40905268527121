import * as React from "react";
const IconCoolCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 2c10.7 0 19.9 6 24.7 14.8H7.3C12.1 10 21.3 4 32 4m0 56C16.6 60 4 47.4 4 32c0-4 .9-7.8 2.4-11.3h12.1V27c0 1.2.9 2.1 2.1 2.1h7c1.2 0 2.1-.9 2.1-2.1v-6.3H34V27c0 1.2.9 2.1 2.1 2.1h7c1.2 0 2.1-.9 2.1-2.1v-6.3H57c.2 0 .4-.1.6-.2C59.1 24.1 60 27.9 60 32c0 15.4-12.6 28-28 28" />
    <path d="M44.4 39.2C41 42.3 36.6 44 32 44s-9-1.7-12.4-4.8c-.4-.4-1-.4-1.4.1-.4.4-.4 1 .1 1.4 3.7 3.5 8.6 5.4 13.7 5.4s10-1.9 13.7-5.4c.4-.4.4-1 .1-1.4s-1-.5-1.4-.1" />
  </svg>
);
export default IconCoolCopy;
