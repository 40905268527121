import * as React from "react";
const IconKeyCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.1 44.7 48.3 32.8c-2.6-2.5-6.4-2.5-8.9 0l-2.5 2.5L10.3 7.7c0-.1-.1-.1-.1-.2 0 0-.1 0-.1-.1L5.2 2.3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l4.4 4.6-5.8 6.1c-.4.4-.4 1 0 1.4.1.2.4.3.6.3.3 0 .5-.1.7-.3l5.8-6.1 7.7 8L9 26.3c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3l8.1-8.5 16.8 17.6-2.5 2.5c-2.5 2.6-2.5 6.4 0 8.9l11.8 11.9c1.3 1.2 2.9 1.9 4.5 1.9s3.2-.6 4.5-1.9l6.5-6.4c1.2-1.2 1.9-2.8 1.9-4.5s-.7-3.3-1.9-4.5m-1.4 7.6-6.5 6.4c-1.7 1.7-4.4 1.7-6.1 0L34.3 46.8c-1.7-1.7-1.7-4.3 0-6.1l3.1-3.1 3.2-3.2c.9-.9 1.9-1.3 3-1.3s2.2.4 3.1 1.3l11.8 11.9c.8.8 1.3 1.9 1.3 3.1s-.3 2.1-1.1 2.9" />
  </svg>
);
export default IconKeyCopy;
