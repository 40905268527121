import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import BringzzText from "../BringzzText"
import { twMerge } from 'tailwind-merge';
import BringzzHeader from "../BringzzHeader";

const BringzzPageContainer = ({
  header,
  subHeader,
  children,
  footer,
  className,
  steps,
  activeStep,
  authenticated = true,
  footerHover = false,
  headerHover = false,
}) => {
  return (
    <div
      className={classnames(
        !authenticated && "flex bg-magic-lilac/20 justify-center min-h-screen"
      )}
    >
      <div
        className={classnames(
          "flex flex-col min-h-screen justify-center md:max-w-2xl w-full",
          authenticated && "mb-28 lg:mb-0",
          className
        )}
      >
        {steps && <div className="w-full px-4 pt-2">
          <div className="flex h-1">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((indx) => {
              return <div className={twMerge(
                "flex flex-1",
                indx === activeStep ? "bg-magic-lilac" : "bg-sand"
              )}></div>
            })}
          </div>
        </div>}

        {header && <BringzzHeader {...header} headerHover={headerHover} />

          // <div
          //   className={classnames(
          //     !headerHover ? "relative flex" : "fixed h-14 flex top-0 left-0 right-0 md:top-0 bg-white shadow-lg",
          //     header.background && "h-64 max-h-64"
          //   )}
          // >
          //   <div
          //     className={classnames(
          //       "relative w-full h-10 flex justify-between items-center px-4 pt-4",
          //       header.background && "text-white"
          //     )}
          //   >
          //     <div className="flex space-x-1">{header.left}</div>
          //     <div className="absolute left-1/2 transform -translate-x-1/2">
          //       <BringzzText tag="h4" className={'font-bold'}>{header.title}</BringzzText>
          //     </div>
          //     <div className="flex space-x-3">{header.right}</div>
          //   </div>

          //   {header.background && (
          //     <>
          //       <div className="z-10 absolute top-0 w-full h-64 bg-gradient-to-b from-black/40 to-transparent"></div>
          //       <div className="absolute top-0 w-full h-64 max-h-64">
          //         {header.background}
          //       </div>
          //       {header.avatar && (
          //         <div className="absolute bottom-0 w-full flex justify-center">
          //           <div className="bg-white rounded-full h-20 w-20 translate-y-12 shadow-md">
          //             {header.avatar}
          //           </div>
          //         </div>
          //       )}
          //     </>
          //   )}
          // </div>

        }
        {subHeader && <div className="px-4 py-8">{subHeader}</div>}
        {children && (
          <div
            className={classnames(
              "flex flex-col justify-between grow",
              !subHeader && "pt-2"
            )}
          >
            {children}
            {footer && (
              <div className={classnames(
                "w-full",
                footerHover ? "fixed bottom-24 max-w-xl md:bottom-0" : "static"
              )}>
                {footer}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

BringzzPageContainer.propTypes = {
  header: PropTypes.shape({
    left: PropTypes.node,
    title: PropTypes.node,
    right: PropTypes.node,
    background: PropTypes.node,
    avatar: PropTypes.node,
  }),
  subHeader: PropTypes.node,
  footer: PropTypes.node,
  children: PropTypes.node.isRequired,
  footerHover: PropTypes.bool, // New PropType for footerHover
};

export default BringzzPageContainer;
