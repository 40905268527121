import * as React from "react";
const IconLeafAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.7 5.5c-.1-.3-.3-.6-.7-.7-.3-.1-.7 0-1 .2-2.8 2.6-8.9 7.3-18.7 9.7C26.3 18 26.1 30 26.1 30.2v.6c-4.4-1.4-9.4-2-13.5-2.5l-1.7-.3c-3.3-.4-5.7-1.2-7.3-2.3-.3-.2-.7-.2-1-.1-.4.3-.6.6-.6 1 0 7.9 1.4 26.8 14.5 31.2 3 1 5.7 1.5 8.2 1.5 2.2 0 4.3-.4 6.1-1.2 1.1-.4 2.6-1.7 2.8-1.8 2.3-2 3.7-4.9 4.2-8.3 2.5.6 4.9.9 7 .9 4.8 0 8.7-1.5 11.5-4.4 9.2-9.6 4.9-32.3 3.4-39M33.2 53.6l-.1-.1c-.1 0-6.9-2.6-10.8-8.5-.3-.5-.9-.6-1.4-.3s-.6.9-.3 1.4c3.6 5.3 9.1 8.1 11.1 9-.6.4-1.4 1-1.8 1.1-3.2 1.5-7.6 1.4-12.8-.3C6.1 52.3 4.2 36.5 4 28.3c1.7.8 3.9 1.4 6.6 1.7l1.8.2c8.5 1 21.3 2.5 23 11.4 1 4.8.2 9.1-2.2 12m21.5-10.5c-3.6 3.7-9.3 4.7-16.9 2.9 0-1.5-.1-3-.4-4.6-.3-1.5-.9-2.9-1.7-4.1 3.1-.3 9.3-1.8 12.2-8.1.2-.5 0-1.1-.5-1.3s-1.1 0-1.3.5c-3 6.7-10.5 7-11.9 7-1.6-1.6-3.7-2.9-6.1-3.8v-1.3c0-.4.2-10.6 11.7-13.5 8.9-2.2 14.9-6.3 18.3-9.1 1.6 7.8 4.6 27.2-3.4 35.4" />
  </svg>
);
export default IconLeafAlt2;
