import * as React from "react";
const IconParentheses = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M6.2 32c0-12.4 3.9-23.7 9.8-28.2.4-.3.5-1 .2-1.4s-1-.5-1.4-.2C8.4 7.1 4.2 18.8 4.2 32s4.1 24.9 10.6 29.8c.2.1.4.2.6.2.3 0 .6-.1.8-.4.3-.4.3-1.1-.2-1.4-5.9-4.5-9.8-15.8-9.8-28.2M49.2 2.2c-.4-.3-1.1-.3-1.4.2-.3.4-.3 1.1.2 1.4 5.8 4.5 9.8 15.8 9.8 28.2S53.9 55.7 48 60.2c-.4.3-.5 1-.2 1.4.2.3.5.4.8.4.2 0 .4-.1.6-.2 6.4-4.9 10.6-16.6 10.6-29.8S55.6 7.1 49.2 2.2" />
  </svg>
);
export default IconParentheses;
