import * as React from "react";
const IconBooksAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.4 40.7h-4.8V23.3h4.7c.6 0 1-.4 1-1s-.4-1-1-1h-7.1V4H53c.6 0 1-.4 1-1s-.4-1-1-1H18.2c-4.2 0-7.7 3.4-7.7 7.7v6c0 3.4 2.2 6.3 5.3 7.3-1.8 1.4-3 3.6-3 6v6c0 2.2 1 4.3 2.5 5.7H8.6c-.6 0-1 .4-1 1s.4 1 1 1h4.8V60H8.6c-.6 0-1 .4-1 1s.4 1 1 1h34.8c4.2 0 7.7-3.4 7.7-7.7v-6c0-2.2-1-4.3-2.5-5.7h6.7c.6 0 1-.4 1-1s-.4-.9-.9-.9M18.2 21.3c-3.1 0-5.7-2.5-5.7-5.7v-6c0-3.1 2.5-5.7 5.7-5.7h28v17.3h-28zm30.9 27v6c0 3.1-2.5 5.7-5.7 5.7h-28V42.7h28c3.2 0 5.7 2.5 5.7 5.6m-28.5-7.6c-3.1 0-5.7-2.5-5.7-5.7v-6c0-3.1 2.5-5.7 5.7-5.7h27.9v17.3H20.6z" />
  </svg>
);
export default IconBooksAlt;
