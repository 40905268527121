import * as React from "react";
const IconTypescript = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 65 64"
    {...props}
  >
    <path
      
      d="M58.55 0H7.05A6.25 6.25 0 0 0 .8 6.25v51.5A6.25 6.25 0 0 0 7.05 64h51.5a6.25 6.25 0 0 0 6.25-6.25V6.25A6.25 6.25 0 0 0 58.55 0M37.925 35.5h-8v22.875H23.55V35.5h-8v-5.125h22.375zM58.55 54.263c-.5 1.125-1.362 1.962-2.375 2.625-1 .612-2.125 1.087-3.5 1.375-1.375.287-2.75.425-4.25.425s-3-.126-4.375-.388-2.612-.65-3.625-1.175v-6.006q-.12-.093-.237-.189l.237-.055v.244a11.8 11.8 0 0 0 3.513 1.936c1.25.475 2.624.712 4 .712.874 0 1.537-.075 2.124-.212.613-.15 1.1-.35 1.5-.6q.601-.375.888-.9.286-.525.287-1.125c0-.575-.162-1.075-.474-1.5-.288-.425-.713-.825-1.25-1.187q-.75-.544-1.875-1.05c-.75-.338-1.513-.688-2.375-1.038-2.125-1-3.8-2-4.875-3.375-1.088-1.25-1.625-2.875-1.625-4.75 0-1.5.287-2.7.875-3.75.625-1.125 1.362-1.95 2.375-2.625 1-.687 2.125-1.187 3.5-1.5a19 19 0 0 1 4.25-.475c1.5 0 2.75.087 3.875.262s2.175.45 3.125.813v5.875a8.3 8.3 0 0 0-1.5-.863 14 14 0 0 0-3.5-.975c-.55-.078-1.1-.117-1.625-.117-.763 0-1.413.063-2 .2-.6.138-1.1.325-1.5.575s-.712.537-.938.887q-.338.525-.338 1.163c0 .462.125.875.375 1.25.25.362.6.712 1.063 1.05.413.325.975.65 1.625.975.75.325 1.425.65 2.25.988 1.125.474 2.125 1 3 1.5a10 10 0 0 1 2.25 1.75c.675.625 1.163 1.375 1.5 2.25.338.875.513 1.862.513 3 0 1.625-.388 2.875-.888 4"
    />
  </svg>
);
export default IconTypescript;
