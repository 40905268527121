import * as React from "react";
const IconPlayStoreAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M59.14 30.203a3.8 3.8 0 0 0-1.254-1.067c-.464-.246-3.485-1.974-6.309-3.613a330 330 0 0 1-3.8-2.211c-.514-.296-.928-.543-1.225-.711l-.464-.276-12.261-7.069-11.314-6.535c-5.745-3.308-11.649-6.723-12.3-7.099h-.01c-.484-.286-1.067-.523-1.64-.592a3.1 3.1 0 0 0-.858 0h-.03l-.03.02c-.019 0-.039.01-.049.02h-.059c-.335.049-.651.177-.918.404C5.612 2.047 5 3.172 5 4.377v55.58c0 .919.395 1.896 1.165 2.498.395.425.977.612 1.54.523h.118c.632 0 1.284-.148 1.847-.474s6.604-3.82 12.498-7.226l11.659-6.743 11.995-6.93s.059-.03.089-.05l.157-.078h.03l.05-.04h.069l.118-.118h.01c.8-.464 10.188-5.864 11.511-6.625.997-.582 1.876-1.57 1.856-2.843a2.84 2.84 0 0 0-.573-1.648M25.672 14.516l6.426 3.711 10.277 5.934-5.34 5.311-20.219-20.07c2.873 1.658 6.111 3.524 8.856 5.114M8.455 5.946l26.152 25.955L8.455 57.845zM32.1 45.564l-6.308 3.643c-2.744 1.58-6.002 3.455-8.954 5.163l20.198-20.09 5.38 5.35zm14.088-8.115c-.276.158-.513.296-.691.395L39.464 31.9l5.993-5.953c.76.444 2.28 1.322 4.393 2.547a670 670 0 0 0 5.913 3.396c-1.718.987-7.187 4.176-9.576 5.558"
      clipRule="evenodd"
    />
  </svg>
);
export default IconPlayStoreAlt;
