import * as React from "react";
const IconFlowerAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.2 30.7c-.1-.3-.3-.6-.7-.7-5.1-1.6-11.3.3-15.9 4.9-.3.3-.5.6-.8.8V27c7.2-2.9 10.6-10.6 10.8-10.9 1.4-3 2.8-9.7-2.1-13-1.6-1.1-3.3-1.4-5-1-2.4.6-4.2 2.7-5.1 4.1-1-1.4-2.9-3.3-5.3-3.9-1.7-.4-3.4 0-5 1.2-4.8 3.5-3.2 10.1-1.6 13.1.2.3 4.1 7.9 11.4 10.5v8.5c-.2-.2-.4-.5-.6-.7-4.5-4.5-10.8-6.4-15.9-4.9-.3.1-.6.3-.7.7-1.6 5.1.3 11.4 4.9 15.9C22.1 50 26.5 52 30.7 52h.2v9c0 .6.4 1 1 1s1-.4 1-1v-9h.3c4.2 0 8.7-2 12.1-5.4 4.6-4.5 6.5-10.7 4.9-15.9m-28.9-15c0-.1-3.7-7.1 1-10.5.9-.6 1.7-.9 2.6-.9.3 0 .5 0 .8.1 2.8.6 4.9 4.2 4.9 4.2.2.3.5.5.9.5s.7-.2.9-.5c.5-1 2.4-3.8 4.7-4.4 1.1-.3 2.2-.1 3.4.7C45.2 8 42 15 41.9 15.3c0 .1-3.3 7.5-10 10-6.8-2.2-10.6-9.5-10.6-9.6M20 45.2c-3.9-3.9-5.6-9-4.5-13.4 4.3-1.1 9.5.7 13.3 4.5.8.8 1.4 1.6 2 2.5V50c-3.7 0-7.7-1.7-10.8-4.8m24 0c-3.2 3.2-7.2 4.9-11.1 4.8V39.1c.6-.9 1.4-1.9 2.2-2.7 3.9-3.8 9-5.6 13.3-4.5 1.1 4.3-.6 9.4-4.4 13.3" />
  </svg>
);
export default IconFlowerAlt2;
