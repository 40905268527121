import * as React from "react";
const IconUserAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 26.5c7 0 12.7-5.5 12.7-12.3S39 2 32 2 19.3 7.5 19.3 14.3 25 26.5 32 26.5M32 4c5.9 0 10.7 4.6 10.7 10.3S37.9 24.5 32 24.5s-10.7-4.6-10.7-10.3S26.1 4 32 4M42.3 33.2H21.7c-6.9 0-12.5 5.6-12.5 12.5V59c0 1.7 1.3 3 3 3h39.7c1.7 0 3-1.3 3-3V45.7c-.1-6.9-5.7-12.5-12.6-12.5M52.8 59c0 .6-.4 1-1 1H12.2c-.6 0-1-.4-1-1V45.7c0-5.8 4.7-10.5 10.5-10.5h20.6c5.8 0 10.5 4.7 10.5 10.5z" />
  </svg>
);
export default IconUserAlt2;
