import * as React from "react";
const IconPw = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 85.333 512 341.333"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 85.331h512v341.337H0z" />
    <circle cx={218.902} cy={255.994} r={74.207}  />
  </svg>
);
export default IconPw;
