import * as React from "react";
const IconLove = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M21.5 31.2c.3 0 .5-.1.7-.3l6.5-6.5c2-2 2-5.1 0-7.1s-5.1-2-7.1 0l-.1.1-.1-.1c-2-2-5.1-2-7.1 0s-2 5.1 0 7.1l6.5 6.5c.2.2.4.3.7.3m-5.8-12.4c.6-.6 1.4-.9 2.1-.9s1.5.3 2.1.9l.8.8c.2.2.4.3.7.3s.5-.1.7-.3l.8-.8c1.2-1.2 3.1-1.2 4.3 0s1.2 3.1 0 4.3l-5.8 5.8-5.7-5.9c-1.2-1.1-1.2-3.1 0-4.2M46.1 15.9c-1.3 0-2.6.5-3.5 1.5l-.1.1-.1-.1c-.9-.9-2.2-1.5-3.5-1.5s-2.6.5-3.5 1.5c-2 2-2 5.1 0 7.1l6.5 6.5c.2.2.5.3.7.3s.5-.1.7-.3l6.5-6.5c2-2 2-5.1 0-7.1-1.1-1-2.3-1.5-3.7-1.5m2.2 7.1-5.8 5.8-5.8-5.8c-1.2-1.2-1.2-3.1 0-4.3q.9-.9 2.1-.9c.8 0 1.6.3 2.1.9l.8.8c.4.4 1 .4 1.4 0l.8-.8c1.1-1.1 3.1-1.1 4.3 0 1.3 1.2 1.3 3.2.1 4.3" />
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
    <path d="M44.5 38.9h-25c-.6 0-1.2.3-1.6.8s-.5 1.1-.4 1.7c1.3 7 7.5 12 14.6 12s13.2-5 14.6-12c.1-.6 0-1.2-.4-1.7-.6-.5-1.2-.8-1.8-.8M32 51.3c-6.1 0-11.4-4.4-12.6-10.4 0 0 0-.1.1-.1h25.1C43.4 47 38.1 51.3 32 51.3" />
  </svg>
);
export default IconLove;
