import * as React from "react";
const IconCornerDoubleDownLeft = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m60.6 16.6-14.2-12c-.4-.3-.9-.3-1.3 0l-14.2 12c-.4.4-.5 1-.1 1.4s1 .5 1.4.1L44.8 7.6v31.1c0 2.6-2.1 4.7-4.7 4.7h-34l10.6-12.6c.4-.4.3-1.1-.1-1.4-.4-.4-1.1-.3-1.4.1l-12 14.2c-.3.4-.3.9 0 1.3l12 14.2c.2.2.5.4.8.4.2 0 .5-.1.6-.2.4-.4.5-1 .1-1.4L6.1 45.3H40c3.7 0 6.7-3 6.7-6.7v-31l12.6 10.6c.2.2.4.2.6.2.3 0 .6-.1.8-.4.4-.4.4-1-.1-1.4" />
  </svg>
);
export default IconCornerDoubleDownLeft;
