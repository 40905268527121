import React, { useState } from "react";
import BringzzIcon from "../BringzzIcon";
import BringzzText from "../BringzzText";
import classnames from "classnames";

const DEFAULT_CARTSELECTOR_CLASSES = "bg-sand max-h-9";
const DEFAULT_CARTSELECTOR_MAXQUANTITY = 12;

//TODO: staarting quantity should be configurable

/**
 * @typedef {object} BringzzCartSelectorProps
 * @property {string} productId - The unique identifier for the product.
 * @property {function(string, number)} onCartClick - Function to execute when the cart icon is clicked and quantity > 0. Takes productId (string) and quantity (number) as arguments.
 * @property {number} [maxQuantity=DEFAULT_CARTSELECTOR_MAXQUANTITY] - The maximum quantity that can be selected. Defaults to 12.
 * @property {string} [className=DEFAULT_CARTSELECTOR_CLASSES] - Additional CSS classes to apply to the cart selector. Defaults to "bg-blue-100".
 * @property {object} [props] - Additional props to be spread into the component.
 */

/**
 * A component for selecting product quantity and adding to cart.
 * @param {BringzzCartSelectorProps} props - The props for the component.
 * @returns {JSX.Element} - The JSX code for cart selector component.
 */
export default function BringzzCartSelector({
  className = DEFAULT_CARTSELECTOR_CLASSES,
  productId,
  onCartClick,
  maxQuantity = DEFAULT_CARTSELECTOR_MAXQUANTITY,
  ...props
}) {
  const [quantity, setQuantity] = useState(0);

  const adjustQuantity = (event, newQuantity) => {
    event.stopPropagation();
    if (newQuantity < 0) {
      newQuantity = 0;
    } else if (newQuantity > maxQuantity) {
      newQuantity = maxQuantity;
    }
    setQuantity(newQuantity);
  };

  const handleCartClick = (event) => {
    event.stopPropagation();
    if (quantity > 0) {
      onCartClick && onCartClick(productId, quantity);
    }
  };

  return (
    <div {...props} className={classnames(className, "flex rounded-lg")}>
      <div className="w-2/3 flex justify-around items-center p-2">
        <BringzzIcon
          size="13"
          icon="IconMinus"
          folder="LineIcons"
          className="cursor-pointer"
          onClick={(event) => {
            adjustQuantity(event, quantity - 1);
          }}
        />
        <BringzzText tag="h3">{quantity}</BringzzText>
        <BringzzIcon
          size="13"
          icon="IconPlus"
          folder="LineIcons"
          className="cursor-pointer"
          onClick={(event) => {
            adjustQuantity(event, quantity + 1);
          }}
        />
      </div>
      <div className="w-1/3 flex justify-center items-center bg-magic-lilac rounded-r-lg">
        <BringzzIcon
          size="18"
          icon="IconShoppingBag14"
          folder="LineIcons"
          className="cursor-pointer"
          onClick={(event) => handleCartClick(event)}
        />
      </div>
    </div>
  );
}
