import * as React from "react";
const IconShieldAlt1Check = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m53.5 8.2-20-6q-1.5-.45-3 0l-20 6c-2.3.7-3.8 2.7-3.8 5.1v24.2c0 7.1 6.9 13.5 12.7 17.7 3.8 2.8 7.6 4.9 10.2 6.2q1.2.6 2.4.6c.8 0 1.7-.2 2.4-.6 2.5-1.3 6.4-3.5 10.2-6.2 5.8-4.2 12.7-10.7 12.7-17.7V13.3c0-2.4-1.6-4.4-3.8-5.1m1.8 29.3c0 6.2-6.4 12.2-11.8 16.1-3.7 2.7-7.4 4.8-9.9 6.1-.9.5-2.1.5-3 0-2.5-1.3-6.2-3.4-9.9-6.1-5.4-3.9-11.8-9.9-11.8-16.1V13.3c0-1.5.9-2.7 2.3-3.2l20-6c.2-.1.5-.1.8-.1s.6 0 .9.1l20 6c1.4.4 2.3 1.7 2.3 3.2v24.2z" />
    <path d="m42.9 24.3-13 10.5-4.6-6.4c-.3-.4-.9-.5-1.4-.2-.4.3-.5.9-.2 1.4l5.2 7.3c.2.2.4.4.7.4h.1c.2 0 .5-.1.6-.2l13.8-11.2c.4-.3.5-1 .1-1.4-.2-.5-.8-.6-1.3-.2" />
  </svg>
);
export default IconShieldAlt1Check;
