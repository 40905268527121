import * as React from "react";
const IconTicketAlt3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 14.2H7c-2.8 0-5 2.2-5 5v25.6c0 2.8 2.2 5 5 5h50c2.8 0 5-2.2 5-5V19.2c0-2.8-2.2-5-5-5m3 30.6c0 1.7-1.3 3-3 3h-7.8v-2c0-.6-.4-1-1-1s-1 .4-1 1v2H7c-1.7 0-3-1.3-3-3V19.2c0-1.7 1.3-3 3-3h40.2v3c0 .6.4 1 1 1s1-.4 1-1v-3H57c1.7 0 3 1.3 3 3z" />
    <path d="M48.2 34.2c-.6 0-1 .4-1 1v5.3c0 .6.4 1 1 1s1-.4 1-1v-5.3c0-.6-.4-1-1-1M48.2 23.5c-.6 0-1 .4-1 1v5.3c0 .6.4 1 1 1s1-.4 1-1v-5.3c0-.5-.4-1-1-1M22.1 30.1H19c-1.1 0-2-1.1-2-2.4s.9-2.4 2-2.4h4.9c.6 0 1-.4 1-1s-.4-1-1-1h-2.4v-.7c0-.6-.4-1-1-1s-1 .4-1 1v.7H19c-2.2 0-4 2-4 4.4s1.8 4.4 4 4.4h3.1c1.1 0 2 1.1 2 2.4s-.9 2.4-2 2.4h-4.9c-.6 0-1 .4-1 1s.4 1 1 1h2.4v.7c0 .6.4 1 1 1s1-.4 1-1v-.7h.5c2.2 0 4-2 4-4.4.1-2.4-1.8-4.4-4-4.4" />
  </svg>
);
export default IconTicketAlt3;
