import * as React from "react";
const IconSeal = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.4 52.7h-1.2c-4 0-7.9-.9-11.5-2.6-1.1-.5-1.6-.8-1.8-1H14.8c-5.3-.1-8.7-1.5-10.7-4.4C2.4 42.1 2 38.9 2 34.8v-.6c.2-1.5 1.4-2.6 3-2.5 1.3.1 2.6 1 2.7 2.9 0 1.2.7 2.3 1.9 2.8 1.4.6 3.4.6 5-.7 9-7.6 17.5-9.7 23.7-11.3l1.3-.3c.8-.2 1.4-.8 1.7-1.5l2.5-7.1c1-2.7 3.3-4.7 6.1-5.2s5.5.4 7.3 2.5l2.7.7c1.3.3 2.1 1.4 2.1 2.7v.8c0 2.7-1.4 5.2-3.7 6.5l-.4 10c-.1 3.4-1.4 6.6-3.6 9.2 1.3 1.3 2.7 2.4 4.1 3.4l.6.4c1 .7 1.4 2 1 3.1-.3 1.4-1.4 2.1-2.6 2.1m-14.3-5.6c.3 0 .5.1.8.3.2.1.5.4 1.7.9 3.3 1.6 6.9 2.4 10.6 2.4h1.2c.5 0 .7-.4.8-.6 0-.1.2-.6-.3-.9l-.6-.4c-1.7-1.3-3.5-2.6-5-4.4-.3-.4-.3-1 0-1.3 2.2-2.3 3.5-5.3 3.6-8.6l.4-10.5q0-.6.6-.9c1.9-.9 3.1-2.9 3.1-5v-.8c0-.4-.3-.7-.6-.8l-3-.8c-.2-.1-.4-.2-.5-.3-1.4-1.7-3.5-2.4-5.7-2.1-2.2.4-3.9 1.8-4.6 3.9L43 24.4c-.5 1.4-1.6 2.4-3.1 2.8l-1.3.3C32.4 29 24.5 31 15.9 38.3c-1.9 1.7-4.8 2.1-7.1 1-1.9-.9-3.1-2.6-3.1-4.6 0-.6-.3-1-.7-1-.3 0-.8.1-.9.7v.3c0 3.7.3 6.6 1.8 8.8 1.6 2.4 4.5 3.5 9 3.6zc-.1 0 0 0 0 0" />
  </svg>
);
export default IconSeal;
