import * as React from "react";
const IconTobPlant = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M45.8 29.2h-13v-8.5c.8.2 1.5.3 2.3.3 3.5 0 7.2-1.6 10.1-4.5 3.9-3.9 5.5-9.4 3.9-13.8l-.1-.4-.4-.2C44.1.5 38.7 2 34.8 6c-3.9 3.9-5.4 9.2-3.9 13.6v9.6H18.3c-2.8 0-5 2.2-5 5v6.1c0 2.7 2.1 4.9 4.8 5l1 12.7c.2 2.6 2.4 4.6 5 4.6h15.8c2.6 0 4.8-2 5-4.6l1-12.7c2.7-.1 4.8-2.3 4.8-5v-6.1c.1-2.7-2.2-5-4.9-5M36.2 7.4c3.2-3.2 7.7-4.6 11.2-3.5 1.1 3.6-.3 8-3.5 11.2s-7.7 4.6-11.2 3.5c-1.1-3.6.3-8 3.5-11.2m6.7 50.3c-.1 1.5-1.4 2.8-3 2.8H24.1c-1.5 0-2.9-1.2-3-2.8l-1-12.5H44zm5.9-17.4c0 1.7-1.3 3-3 3H18.3c-1.7 0-3-1.3-3-3v-6.1c0-1.7 1.3-3 3-3h27.5c1.7 0 3 1.3 3 3z" />
  </svg>
);
export default IconTobPlant;
