import * as React from "react";
const IconFlashIdea = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m41.9 28.3-5.9-.4.5-10.2c0-.4-.3-.8-.7-1-.4-.1-.9 0-1.1.4L20.9 35.8c-.2.3-.3.7-.1 1.1.2.3.6.5 1 .5l6.2-.6-.5 11.1c0 .4.3.8.7 1 .1 0 .2.1.3.1.3 0 .6-.2.8-.4L42.6 30c.2-.3.2-.7.1-1-.1-.5-.4-.7-.8-.7M29.7 44.5l.4-8.8c0-.3-.1-.6-.3-.8s-.4-.3-.7-.3H29l-5.2.5 10.5-14.3-.4 7.9c0 .5.4 1 .9 1l5.1.3z" />
    <path d="M62 30.2c-.1-3.4-1.9-6.6-4.6-8.4.3-3-.6-5.9-2.5-8.2-1.7-2.1-4.2-3.3-6.8-3.5-1.4-3.4-4.3-5.7-7.7-6.2-3.1-.4-6.2.7-8.4 3.1-2.2-2.4-5.3-3.6-8.5-3.1-3.5.5-6.4 2.9-7.7 6.2-2.6.2-5 1.4-6.8 3.5-1.9 2.3-2.8 5.2-2.5 8.2C3.7 23.7 2 27 2 30.6c0 4.3 2.4 8 6 9.7-.4 2.6.2 5.3 1.6 7.6 1.4 2.1 3.3 3.6 5.7 4.2.9 4 3.7 6.9 7.5 7.8 3.4.8 6.8-.3 9.2-2.9 1.9 2.1 4.4 3.2 7 3.2.7 0 1.4-.1 2.2-.2 3.8-.9 6.7-3.8 7.5-7.8 2.3-.6 4.3-2.1 5.7-4.2 1.4-2.2 2-4.9 1.6-7.6 3.7-1.8 6.2-5.8 6-10.2m-7.5 8.5c-.5.2-.8.7-.7 1.2.6 2.4.1 4.9-1.2 6.9-1.2 1.9-3 3.1-5 3.5-.4.1-.7.4-.8.8-.6 3.5-2.9 6.1-6.1 6.8-3.1.7-6-.5-7.9-3.2-.2-.3-.5-.4-.8-.4s-.6.2-.8.4c-1.9 2.6-4.9 3.9-7.9 3.2-3.2-.7-5.6-3.4-6.1-6.8-.1-.4-.4-.7-.8-.8-2-.4-3.8-1.7-5-3.5-1.3-2-1.7-4.5-1.2-6.9.1-.5-.2-1-.7-1.2C6.2 37.6 4 34.4 4 30.6c0-3.1 1.6-6 4.2-7.5.4-.2.6-.6.5-1-.4-2.6.3-5.2 1.9-7.2 1.5-1.8 3.6-2.8 5.8-2.8h.1c.4 0 .8-.3.9-.7 1-3 3.4-5.1 6.4-5.6 2.9-.4 5.6.8 7.4 3.3.2.3.5.4.8.4s.6-.2.8-.4c1.7-2.5 4.5-3.7 7.4-3.3 3 .4 5.3 2.5 6.4 5.6.1.4.6.7 1.1.7 2.2 0 4.3 1 5.8 2.8 1.7 2 2.4 4.6 1.9 7.2-.1.4.1.8.5 1 2.4 1.4 4 4.1 4.2 7.1.1 3.9-2.2 7.4-5.6 8.5" />
  </svg>
);
export default IconFlashIdea;
