import * as React from "react";
const IconForrest1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62 27.7c0-4.2-3.4-7.6-7.6-7.6s-7.6 3.4-7.6 7.6c0 3.8 2.9 7 6.6 7.5v8.7h-7.1l-6.8-12.6c-.9-1.6-2.5-2.6-4.3-2.6s-3.5 1-4.3 2.6l-.9 1.6-6.5-12.1c-.9-1.6-2.5-2.6-4.3-2.6s-3.5 1-4.3 2.6L2.1 44.4v.2c-.1 0-.1.1-.1.2s0 .2.1.3v.2c0 .1.1.1.1.2.1.1.2.1.3.2h.1c.1 0 .2.1.3.1h53.7c.6 0 1-.4 1-1s-.4-1-1-1h-1.3v-8.7c3.8-.4 6.7-3.6 6.7-7.4M4.7 43.9l11.9-22.2c.5-1 1.5-1.5 2.6-1.5s2.1.6 2.6 1.5l11.9 22.2zm31.6 0h-.4l-4.8-9c.1-.1.3-.2.3-.3l1.3-2.3c.5-1 1.5-1.5 2.6-1.5s2.1.6 2.6 1.5L44.2 44h-7.9zm12.6-16.2c0-3.1 2.5-5.6 5.6-5.6s5.6 2.5 5.6 5.6-2.5 5.6-5.6 5.6-5.6-2.5-5.6-5.6" />
  </svg>
);
export default IconForrest1;
