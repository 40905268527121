import * as React from "react";
const IconPageAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M52.8 20.8 34.1 3.2c-.8-.8-1.9-1.2-3-1.2h-17c-2.4 0-4.3 1.9-4.3 4.3v51.4c0 2.4 1.9 4.3 4.3 4.3h35.6c2.4 0 4.3-1.9 4.3-4.3V23.8c.1-1.2-.4-2.3-1.2-3m-2.1.7H35.3c-1.3 0-2.3-1-2.3-2.2V4.9zm1.4 36.2c0 1.3-1 2.3-2.3 2.3H14.2c-1.3 0-2.3-1-2.3-2.3V6.3c0-1.3 1-2.3 2.3-2.3H31v15.4c0 2.3 1.9 4.2 4.3 4.2h16.8v34.1" />
    <path d="M38.5 37.5h-1l.4-2c.1-.5-.3-1.1-.8-1.2s-1.1.3-1.2.8l-.4 2.3h-7.7l.4-2c.1-.5-.3-1.1-.8-1.2s-1.1.3-1.2.8l-.4 2.3h-1.1c-.6 0-1 .4-1 1s.4 1 1 1h.8l-1.2 6.4h-1.7c-.6 0-1 .4-1 1s.4 1 1 1h1.3l-.6 2.3c-.1.5.3 1.1.8 1.2h.2c.5 0 .9-.3 1-.8l.5-2.5h7.7l-.4 2.1c-.1.5.3 1.1.8 1.2h.2c.5 0 .9-.3 1-.8l.5-2.5h1c.6 0 1-.4 1-1s-.4-1-1-1H36l1.2-6.4h1.4c.6 0 1-.4 1-1s-.5-1-1.1-1m-4.6 8.4h-7.7l1.2-6.4h7.7z" />
  </svg>
);
export default IconPageAlt;
