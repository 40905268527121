import * as React from "react";
const IconBoltCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.4 22.9q-.3-.6-.9-.6H34.1V3c0-.4-.3-.8-.7-.9s-.9 0-1.1.4L6.7 36.6c-.2.3-.3.7-.1 1s.5.6.9.6h21.1l-4.2 22.6c-.1.4.1.9.6 1.1 0 .1.2.1.3.1.3 0 .6-.1.8-.4l31.2-37.7c.3-.3.3-.7.1-1M27 57.4l3.7-20c.1-.3 0-.6-.2-.8s-.5-.4-.8-.4H9.5L32.1 6v17.3c0 .6.4 1 1 1h21.4z" />
  </svg>
);
export default IconBoltCopy;
