import * as React from "react";
const Icon2g = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      d="M32.485 5.515a12 12 0 0 1 0 16.97L33.9 23.9a14 14 0 0 0 0-19.8zM18.75 31a1.25 1.25 0 0 1 .805 2.208l-5.25 5.073A1 1 0 0 0 15 40h6a1 1 0 1 0 0-2h-3.526l3.458-3.342A3.25 3.25 0 0 0 18.75 29h-1.5a3.25 3.25 0 0 0-3.065 2.167 1 1 0 1 0 1.886.666A1.25 1.25 0 0 1 17.25 31zM32.753 31.774a1 1 0 0 0-.08-1.412 5.503 5.503 0 1 0 0 8.204 1 1 0 0 0 .334-.745v-3.357a1 1 0 0 0-1-1h-3.002a1 1 0 0 0 0 2h2.002v1.875a3.502 3.502 0 0 1-5.506-2.875 3.503 3.503 0 0 1 5.84-2.611 1 1 0 0 0 1.412-.079"
    />
    <path
      
      fillRule="evenodd"
      d="M10 28a3 3 0 0 1 3-3h22a3 3 0 0 1 3 3v13a3 3 0 0 1-3 3H13a3 3 0 0 1-3-3zm2 0a1 1 0 0 1 1-1h22a1 1 0 0 1 1 1v13a1 1 0 0 1-1 1H13a1 1 0 0 1-1-1z"
      clipRule="evenodd"
    />
    <path
      
      d="M29.543 11.704a6 6 0 0 0-1.3-1.947l1.414-1.414a8 8 0 0 1 0 11.314l-1.414-1.414a6 6 0 0 0 1.3-6.539M18.457 11.704a6 6 0 0 1 1.3-1.947l-1.414-1.414a8 8 0 0 0 0 11.314l1.414-1.414a6 6 0 0 1-1.3-6.539"
    />
    <path
      
      d="M15.515 5.515a12 12 0 0 0 0 16.97L14.1 23.9a14 14 0 0 1 0-19.8zM24 16a2 2 0 1 0 0-4 2 2 0 0 0 0 4"
    />
  </svg>
);
export default Icon2g;
