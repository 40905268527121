import * as React from "react";
const IconRepairTools = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m59.1 44.8-6.2-6.2c-2-2-5.3-2-7.3 0l-2.5 2.5-7.2-7.2 6.3-6.3 10.4-2.8c1.8-.5 3.2-1.9 3.7-3.7l2.9-10.7c.1-.5-.2-1.1-.7-1.2s-1.1.2-1.2.7l-2.9 10.7c-.3 1.1-1.2 2-2.3 2.3l-10.7 2.9c-.2 0-.3.1-.4.3l-6.5 6.5-5.2-5.2 6.5-6.5c.1-.1.2-.3.3-.4L39 9.8c.3-1.1 1.2-2 2.3-2.3L52 4.6c.5-.1.8-.7.7-1.2s-.7-.9-1.2-.7L40.6 5.4c-1.8.5-3.2 1.9-3.7 3.7l-2.8 10.4-6.3 6.3-6.6-6.6c-.2-.2-.3-.2-.5-.3l.8-.8c1.8-1.8 2-4.7.5-6.8l-4.3-5.7c-.9-1.2-2.3-1.9-3.8-2s-3 .4-4 1.5L5.8 9.4c-1.1 1.1-1.6 2.5-1.5 4s.8 2.9 2 3.8l5.7 4.3c.9.7 2 1 3.1 1 1.3 0 2.7-.5 3.7-1.5l.8-.8c0 .2.1.4.3.5l6.6 6.6-7.9 7.9-10.5 2.7c-1.8.5-3.2 1.9-3.7 3.7l-2.2 8.3c-.5 1.8 0 3.7 1.4 5.1l3.1 3.1c1 1 2.3 1.5 3.7 1.5.5 0 .9-.1 1.4-.2l8.3-2.2c1.8-.5 3.2-1.9 3.7-3.7l2.8-10.4 7.9-7.9 7.2 7.2-2.5 2.5c-2 2-2 5.3 0 7.3l6.2 6.2c1.8 1.8 4.3 2.9 6.9 2.9s5.1-1 6.9-2.9c1.8-1.8 2.9-4.3 2.9-6.9s-1.1-4.8-3-6.7M17.3 19.6c-1.1 1.1-2.9 1.2-4.2.3l-5.7-4.3c-.7-.6-1.2-1.4-1.2-2.3-.1-.9.3-1.8.9-2.5l4.1-4.1c.6-.6 1.4-.9 2.2-.9h.2c.9.1 1.8.5 2.3 1.2l4.3 5.7c1 1.3.8 3-.3 4.2zm7.6 22.3c-.1.1-.2.3-.3.4L21.7 53c-.3 1.1-1.2 2-2.3 2.3l-8.3 2.2c-1.1.3-2.3 0-3.1-.8l-3-3.1c-.8-.8-1.1-2-.8-3.1l2.2-8.3c.3-1.1 1.2-2 2.3-2.3L19.3 37c.2 0 .3-.1.4-.3l8.1-8.1 5.2 5.2zm32.8 15.3c-1.5 1.5-3.4 2.3-5.5 2.3s-4-.8-5.5-2.3L40.5 51c-1.2-1.2-1.2-3.2 0-4.5L43 44l2.3 2.3c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-2.3-2.3L47 40c.6-.6 1.4-.9 2.2-.9s1.6.3 2.2.9l6.2 6.2c1.5 1.5 2.3 3.4 2.3 5.5s-.7 4-2.2 5.5" />
  </svg>
);
export default IconRepairTools;
