import * as React from "react";
const IconTextUnderlineAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.4 2H13.6c-2.8 0-5.1 2.3-5.1 5.1V13c0 .6.4 1 1 1s1-.4 1-1V7.1c0-1.7 1.4-3.1 3.1-3.1h12.2v40.4h-6.5c-.6 0-1 .4-1 1s.4 1 1 1h25.5c.6 0 1-.4 1-1s-.4-1-1-1h-6.5V4h12.2c1.7 0 3.1 1.4 3.1 3.1V13c0 .6.4 1 1 1s1-.4 1-1V7.1c0-2.8-2.3-5.1-5.2-5.1M36.2 44.4h-8.5V4h8.5zM53.5 60h-43c-.6 0-1 .4-1 1s.4 1 1 1h43.1c.6 0 1-.4 1-1s-.5-1-1.1-1" />
  </svg>
);
export default IconTextUnderlineAlt;
