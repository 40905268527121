import * as React from "react";
const IconBowtieAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m59.1 22.3-.6-2.2c-.1-.4-.5-.7-1-.7H57c-5.8.1-11.3 1.9-16 5.3l-.3.2c-.7-1.1-2-1.9-3.4-1.9H26.6c-1.4 0-2.7.8-3.4 1.9l-.6-.4c-4.8-3.3-10.4-5.1-16.2-5.1-.4 0-.8.3-1 .7l-.6 2.2c-1.8 6.3-1.8 13 0 19.3l.6 2.2c.1.4.5.7 1 .7h.5c5.8-.1 11.3-1.9 16-5.3l.3-.2c.7 1.1 2 1.9 3.4 1.9h10.7c1.4 0 2.7-.8 3.4-1.9l.6.4c4.8 3.3 10.4 5.1 16.2 5.1.4 0 .8-.3 1-.7l.6-2.2c1.9-6.3 1.9-12.9 0-19.3M21.8 37.7c-4.3 3.1-9.3 4.8-14.6 4.9l-.4-1.5c-1.7-6-1.7-12.3 0-18.2l.4-1.5c5.1.1 10.1 1.8 14.3 4.7l1.1.8v10.2zm17.6-.7c0 1.1-.9 2-2 2H26.6c-1.1 0-2-.9-2-2V27c0-1.1.9-2 2-2h10.7c1.1 0 2 .9 2 2v10zm17.8 4.1-.4 1.5c-5.1-.1-10.1-1.8-14.3-4.7l-1.1-.9V26.9l.9-.6c4.3-3.1 9.3-4.8 14.6-4.9l.4 1.5c1.6 5.9 1.6 12.3-.1 18.2" />
  </svg>
);
export default IconBowtieAlt1;
