import * as React from "react";
const IconRainCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.2 19c-2.7-3-6.5-4.9-10.5-5.5-2.1-3.6-5.3-6.3-9.1-7.6-1.6-.6-3.5-1-5.5-1C22.7 4.9 15 12.6 15 22v.1c-7.4.3-13 6.1-13 13.4 0 4.8 2.5 9.1 6.6 11.6.2.1.3.1.5.1.3 0 .7-.2.9-.5.3-.5.1-1.1-.4-1.4-3.5-2-5.6-5.7-5.6-9.8 0-6.4 5-11.4 11.4-11.4h.5c.6 0 1-.4 1-1V22c0-8.3 6.8-15 15.1-15 1.8 0 3.4.3 4.8.8 3.5 1.2 6.5 3.8 8.3 7.1.2.3.4.5.8.5 3.7.4 7.3 2.2 9.8 4.9 2.7 3 4.2 6.8 4.2 10.8 0 5.5-2.8 10.5-7.5 13.4-.5.3-.6.9-.3 1.4s.9.6 1.4.3C58.9 43 62 37.3 62 31.1c0-4.5-1.7-8.8-4.8-12.1" />
    <path d="M21.6 41.8c-.6 0-1 .4-1 1V58c0 .6.4 1 1 1s1-.4 1-1V42.8c0-.5-.5-1-1-1M32 36.8c-.6 0-1 .4-1 1V53c0 .6.4 1 1 1s1-.4 1-1V37.8c0-.6-.4-1-1-1M42.4 41.8c-.6 0-1 .4-1 1V58c0 .6.4 1 1 1s1-.4 1-1V42.8c0-.5-.4-1-1-1" />
  </svg>
);
export default IconRainCopy;
