import * as React from "react";
const IconTShirtCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.1 5.8H41c-1 0-1.9.7-1.9 1.7-.4 3.2-3.4 5.7-7.1 5.7-3.6 0-6.7-2.5-7.1-5.7v-.2c-.3-.9-1-1.4-1.9-1.4H3.9C2.8 5.8 2 6.6 2 7.8l.8 12.1c0 1 1 1.7 1.9 1.7H10L11.7 52c.2 3.5 3 6.2 6.5 6.2h27.4c3.5 0 6.3-2.7 6.5-6.2l1.6-30.4h5.5c1.1 0 1.9-.8 1.9-1.7L62 7.7c0-1.1-.8-1.9-1.9-1.9m-.9 13.8h-5.5c-1 0-2 .7-2 1.7l-1.6 30.5c-.1 2.4-2.1 4.3-4.5 4.3H18.2c-2.4 0-4.4-1.9-4.5-4.3l-1.6-30.5c0-1-.9-1.8-1.9-1.8H4.8L4 7.8h19c.5 4.1 4.5 7.3 9 7.3 4.6 0 8.5-3.2 9-7.4h18.9z" />
  </svg>
);
export default IconTShirtCopy;
