import * as React from "react";
const IconIc = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300 200"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 0h300v200H0z" />
    <path  d="M0 0h200v200H0z" />
    <path  d="M0 0h100v200H0z" />
  </svg>
);
export default IconIc;
