import * as React from "react";
const IconDerrick = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.2 16.2v-2.7h1.3c2.2 0 4-1.8 4-4V6c0-2.2-1.8-4-4-4H9.4c-2.2 0-4 1.8-4 4v3.4c0 2.2 1.8 4 4 4h3.4V60H6.5c-.6 0-1 .4-1 1s.4 1 1 1h48c2.2 0 4-1.8 4-4v-4c0-2.2-1.8-4-4-4h-14c-2.2 0-4 1.8-4 4v4c0 .7.2 1.4.6 2h-9.3V13.4h13.9v2.7c0 2.8 2.2 5 4.9 5.2v11h-6c-2.2 0-4 1.8-4 4v4c0 2.2 1.8 4 4 4h14c2.2 0 4-1.8 4-4v-4c0-2.2-1.8-4-4-4h-6v-11c2.6-.2 4.6-2.4 4.6-5.1m3.4 20.2v4c0 1.1-.9 2-2 2h-14c-1.1 0-2-.9-2-2v-4c0-1.1.9-2 2-2h14c1.1 0 2 .9 2 2M7.4 9.4V6c0-1.1.9-2 2-2h45.1c1.1 0 2 .9 2 2v3.4c0 1.1-.9 2-2 2H9.4c-1.1 0-2-.9-2-2M25.8 40l-10.9 6.4V34.3l10.9-6.4zm12.7 18v-4c0-1.1.9-2 2-2h14c1.1 0 2 .9 2 2v4c0 1.1-.9 2-2 2h-14c-1.1 0-2-.9-2-2m-23.7 2V48.7l10.9-6.4V60zm11-34.5-10.9 6.4V13.4h10.9zm17.9-9.3v-2.7h7.6v2.7c0 1.8-1.5 3.2-3.2 3.2H47c-1.9 0-3.3-1.4-3.3-3.2" />
  </svg>
);
export default IconDerrick;
