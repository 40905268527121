import React, { createContext, useContext, useState, useCallback } from 'react';

const OverlayContext = createContext();

export const useOverlay = () => useContext(OverlayContext);

export const OverlayProvider = ({ children }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [content, setContent] = useState(null);

    const showOverlayContent = useCallback((overlayContent) => {
        setContent(overlayContent);
        setIsVisible(true);
    }, []);

    const hideOverlay = useCallback(() => {
        setIsVisible(false);
        setContent(null);
    }, []);

    return (
        <OverlayContext.Provider value={{ isVisible, content, showOverlayContent, hideOverlay }}>
            {children}
            {isVisible && (
                <div className="fixed inset-0 bg-black/85 flex items-center justify-center z-50">
                    <div className="relative w-full h-full flex items-center justify-center">
                        {content}
                    </div>
                </div>
            )}
        </OverlayContext.Provider>
    );
};
