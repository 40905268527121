import * as React from "react";
const IconBracketsAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M20.8 41.2h-1.4v-6.4c0-.2-.1-.5-.3-.7l-2.3-2.5 2.3-2.5c.2-.2.3-.4.3-.7v-5.7h1.4c.6 0 1-.4 1-1s-.4-1-1-1h-2.4c-.6 0-1 .4-1 1V28l-2.6 3c-.3.4-.3 1 0 1.3l2.6 2.9v7c0 .6.4 1 1 1h2.4c.6 0 1-.4 1-1s-.4-1-1-1M46.6 28.1v-6.3c0-.6-.4-1-1-1h-2.4c-.6 0-1 .4-1 1s.4 1 1 1h1.4v5.7c0 .2.1.5.3.7l2.3 2.5-2.3 2.5c-.2.2-.3.4-.3.7v6.4h-1.4c-.6 0-1 .4-1 1s.4 1 1 1h2.4c.6 0 1-.4 1-1v-7l2.6-2.9c.3-.4.3-1 0-1.3z" />
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
  </svg>
);
export default IconBracketsAlt1;
