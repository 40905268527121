import * as React from "react";
const IconSpeechBubble18 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.6 52.3c-1.4-1.2-4.6-3.9-6.7-5.6 3.4-3.6 4.9-8.1 4.2-12.7-.8-5.3-4.6-10.1-10.2-12.9-3.4-6.7-11.8-11.2-21.2-11.2-12.6 0-22.8 8-22.8 17.9 0 3.9 1.5 7.5 4.4 10.6-2.2 1.8-5.8 4.6-6.9 5.5-.4.3-.5.7-.3 1.1.1.4.5.7.9.7h14.2c4.1 5.2 11.5 8.4 19.3 8.4H61c.4 0 .8-.3.9-.7.2-.4.1-.8-.3-1.1M11.4 39.2c.2-.2.4-.5.4-.7 0-.3-.1-.6-.3-.8-3-2.8-4.5-6.3-4.5-9.9 0-8.8 9.3-15.9 20.8-15.9 8.7 0 16.6 4.2 19.6 10.4 0 .1 4.1 8.5-5.9 16.6-4 3-10.3 4.7-17.3 4.7H5.9c1.9-1.4 4.3-3.3 5.5-4.4m25.1 12.9c-6.6 0-12.7-2.4-16.6-6.4h4.3c4.4 0 12.6-.7 18.5-5.1 7.8-6.3 7.9-13.1 7.2-16.6 4.1 2.6 6.7 6.3 7.3 10.4.6 4.2-.9 8.4-4.4 11.7-.2.2-.3.5-.3.8s.1.6.4.7c1.3 1.1 3.6 2.9 5.4 4.5z" />
    <path d="M18.9 27.9c0-1.1-.9-2-2-2h-.1c-1.1 0-2 .9-2 2s.9 2 2 2h.1c1.1 0 2-.9 2-2M27.8 29.9h.1c1.1 0 2-.9 2-2s-.9-2-2-2h-.1c-1.1 0-2 .9-2 2s.9 2 2 2M38.8 29.9h.2c1.1 0 2-.9 2-2s-.9-2-2-2h-.1c-1.1 0-2 .9-2 2s.8 2 1.9 2" />
  </svg>
);
export default IconSpeechBubble18;
