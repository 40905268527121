import * as React from "react";
const IconFilesCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.5 2H34.1C31.3 2 29 4.3 29 7.1v10H6.5c-2.8 0-5.1 2.3-5.1 5.1v22.3c0 1 .4 2 1.1 2.7L16 60.9c.7.7 1.7 1.1 2.7 1.1H30c2.8 0 5.1-2.3 5.1-5.1V37.2l8.5 8.5c.7.7 1.7 1.1 2.7 1.1h11.3c2.8 0 5.1-2.3 5.1-5.1V7.1c0-2.8-2.3-5.1-5.2-5.1M4.7 46.8h10c1 0 1.8.8 1.8 1.8v10zm28.4 10.1c0 1.7-1.4 3.1-3.1 3.1H18.5V48.6c0-2.1-1.7-3.8-3.8-3.8H3.3V22.3c0-1.7 1.4-3.1 3.1-3.1h24.9c1 0 1.8.8 1.8 1.8zm2-22.6v-2.7h7.3c1 0 1.8.8 1.8 1.8v10zm25.6 7.4c0 1.7-1.4 3.1-3.1 3.1H46.2V33.4c0-2.1-1.7-3.8-3.8-3.8h-7.3V21c0-2.1-1.7-3.8-3.8-3.8h-.4v-10c0-1.7 1.4-3.1 3.1-3.1h23.5c1.7 0 3.1 1.4 3.1 3.1v34.5z" />
  </svg>
);
export default IconFilesCopy;
