import * as React from "react";
const IconInteroperability = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M17.94 22.342a1 1 0 0 1-1.282.598l-5.5-2a1 1 0 1 1 .684-1.88l5.5 2a1 1 0 0 1 .598 1.282M37.429 19.629a1 1 0 0 1-.558 1.3l-5 2a1 1 0 0 1-.742-1.857l5-2a1 1 0 0 1 1.3.557M20.088 29.692a1 1 0 0 1 .22 1.396l-4 5.5a1 1 0 1 1-1.617-1.176l4-5.5a1 1 0 0 1 1.397-.22M33.088 36.809a1 1 0 0 1-1.397-.22l-4-5.5a1 1 0 1 1 1.618-1.177l4 5.5a1 1 0 0 1-.22 1.397M24 26a2 2 0 1 0 0-4 2 2 0 0 0 0 4m0 2a4 4 0 1 0 0-8 4 4 0 0 0 0 8M23.902 17.5a1 1 0 0 1-1-1V10a1 1 0 1 1 2 0v6.5a1 1 0 0 1-1 1"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M23 7.902a1 1 0 1 0 2 0 1 1 0 0 0-2 0m-2 0a3 3 0 1 0 6 0 3 3 0 0 0-6 0M39 20a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 2a3 3 0 1 0 0-6 3 3 0 0 0 0 6M14 39a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 2a3 3 0 1 0 0-6 3 3 0 0 0 0 6M34 39a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 2a3 3 0 1 0 0-6 3 3 0 0 0 0 6M9 20a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 2a3 3 0 1 0 0-6 3 3 0 0 0 0 6"
      clipRule="evenodd"
    />
  </svg>
);
export default IconInteroperability;
