import * as React from "react";
const IconEyeglass = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 65 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M46.7 42.5c-5.5 0-9.9-4.3-10.3-9.7v-.3c0-2.1-1.7-3.9-3.8-3.9s-3.8 1.7-3.8 3.9v.3c-.4 5.4-4.9 9.7-10.3 9.7C12.7 42.5 8.2 38 8 32.4H4.5c-.6 0-1-.4-1-1s.4-1 1-1h3.6c.7-5.1 5.1-8.9 10.3-8.9 4.5 0 8.4 3 9.8 7.1 1.1-1.2 2.6-2 4.4-2 1.7 0 3.3.8 4.4 2 1.4-4.1 5.3-7.1 9.8-7.1 5.2 0 9.5 3.9 10.3 8.9h3.6c.6 0 1 .4 1 1s-.4 1-1 1H57c-.2 5.6-4.7 10.1-10.3 10.1m0-19c-4.6 0-8.4 3.8-8.4 8.5s3.8 8.5 8.4 8.5 8.4-3.8 8.4-8.5-3.8-8.5-8.4-8.5m-28.4 0c-4.6 0-8.4 3.8-8.4 8.5s3.8 8.5 8.4 8.5 8.4-3.8 8.4-8.5-3.8-8.5-8.4-8.5" />
  </svg>
);
export default IconEyeglass;
