import * as React from "react";
const IconBadge5 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m38.7 20.6-4-1.1-.8-7.8c0-.4-.3-.8-.8-.9-.4-.1-.8.1-1.1.4L24.7 22c-.3.4-.2 1 .1 1.3l3.3 3.1-.1 8c0 .4.3.8.7 1 .1 0 .2.1.3.1.3 0 .6-.1.8-.4l9.4-12.9c.2-.3.2-.6.1-.9 0-.4-.3-.7-.6-.7m-8.6 10.7.1-5.3c0-.3-.1-.6-.3-.7l-3-2.8 5.3-7.9.6 5.7c0 .4.3.8.7.9l3.2.9z" />
    <path d="M53.8 23.8C53.8 11.8 44 2 32 2s-21.8 9.8-21.8 21.8c0 7.4 3.7 14 9.4 17.9V61c0 .3.2.6.4.8.3.2.6.2.9.1L32 58.4 43.1 62h.3c.2 0 .4-.1.6-.2.3-.2.4-.5.4-.8V41.7c5.7-3.9 9.4-10.5 9.4-17.9M42.4 59.6l-10.1-3.3q-.3-.15-.6 0l-10.1 3.3V43c3.1 1.7 6.7 2.7 10.4 2.7s7.3-1 10.4-2.7zM32 43.6c-10.9 0-19.8-8.9-19.8-19.8S21.1 4 32 4s19.8 8.9 19.8 19.8S42.9 43.6 32 43.6" />
  </svg>
);
export default IconBadge5;
