import * as React from "react";
const IconCapsuleCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.6 7.4c-7.2-7.2-19-7.2-26.2 0l-23 23c-7.2 7.2-7.2 19 0 26.2C11 60.2 15.8 62 20.5 62s9.5-1.8 13.1-5.4l23-23c7.2-7.2 7.2-19 0-26.2M32.2 55.2c-6.4 6.4-16.9 6.4-23.3 0s-6.4-16.9 0-23.3L19.6 21 43 44.4zm23-23L44.4 43 21 19.6 31.8 8.8c6.4-6.4 16.9-6.4 23.3 0 6.5 6.5 6.5 16.9.1 23.4" />
  </svg>
);
export default IconCapsuleCopy;
