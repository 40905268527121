import * as React from "react";
const IconZapier = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 65 64"
    {...props}
  >
    <path
      
      d="M40.143 32.015c0 2.258-.408 4.499-1.204 6.612a18.8 18.8 0 0 1-6.615 1.205h-.027c-2.26 0-4.5-.407-6.615-1.204a18.7 18.7 0 0 1-1.205-6.614v-.03c0-2.328.427-4.557 1.2-6.614a18.7 18.7 0 0 1 6.617-1.204h.026c2.328 0 4.557.427 6.614 1.204a18.7 18.7 0 0 1 1.204 6.614v.03h.005M63.2 26.778H44.917L57.844 13.85a31.5 31.5 0 0 0-7.384-7.382L37.53 19.396V1.111C35.811.82 34.07.675 32.327.675h-.033c-1.774 0-3.511.151-5.204.436v18.285L14.157 6.466a31 31 0 0 0-3.996 3.382l-.006.006a31.5 31.5 0 0 0-3.38 3.996L19.71 26.778H1.42S.985 30.214.985 31.99v.022c0 1.775.15 3.516.436 5.21H19.71L6.774 50.148a31.6 31.6 0 0 0 7.385 7.384l12.93-12.928V62.89c1.692.284 3.426.434 5.198.435h.044a31.6 31.6 0 0 0 5.197-.435V44.604l12.932 12.93a31.6 31.6 0 0 0 4-3.381h.002a31.6 31.6 0 0 0 3.379-4L44.914 37.223h18.288c.284-1.693.433-3.428.433-5.2v-.045c0-1.742-.146-3.48-.433-5.197z"
    />
  </svg>
);
export default IconZapier;
