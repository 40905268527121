import * as React from "react";
const IconPizzaAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.1 6.9C49.1 3.7 40.8 2 32 2S14.9 3.7 8.9 6.9C7 7.9 6.1 10.2 7 12.1l21.3 47.5C29 61.1 30.4 62 32 62s3-.9 3.7-2.4l12.6-28 4.9-11.1 3.8-8.4c.9-1.9 0-4.2-1.9-5.2m-8.6 23.9-12.6 28c-.3.8-1 1.2-1.9 1.2-.8 0-1.5-.5-1.9-1.2L13.4 21.5c9.2-2.3 22.6-3.9 37.2-.1zm8.7-19.5-3.7 8.1c-15.3-4-29.4-2.2-38.9.1l-3.7-8.3c-.4-1 0-2.2 1-2.7C15.5 5.7 23.6 4 32 4s16.5 1.7 22.2 4.7c1 .5 1.4 1.6 1 2.6" />
  </svg>
);
export default IconPizzaAlt;
