import { useState } from "react";
import BringzzIcon from "../../BringzzIcon";
import React from "react";
import BringzzText from "../../BringzzText";

const YearPicker = ({
  selectedDate,
  maxYear,
  minYear,
  onChange,
  closePicker,
  description,
}) => {
  // Initialize the year range to span 12 years
  const initialStartYear = selectedDate.getFullYear() - 6; // 6 years before the current year
  const [yearRange, setYearRange] = useState({
    start: initialStartYear,
    end: initialStartYear + 12, // 12-year range
  });
  const years = Array.from(
    { length: yearRange.end - yearRange.start },
    (_, i) => yearRange.start + i
  );

  const handleYearSelect = (selectedYear) => {
    const newDate = new Date(selectedYear, selectedDate.getMonth() + 1, 1, 12);
    onChange(newDate);
  };

  return (
    <div>
      <div className="mb-4 px-2 flex justify-between">
      <BringzzText tag="h4"><span className="mr-1">&bull;</span>{description || "Select a date"}</BringzzText>

        <div>
          {" "}
          <BringzzIcon
            icon="IconClose"
            folder="LineIcons"
            size="18"
            className="hover:text-magic-lilac cursor-pointer"
            onClick={() => {
              closePicker();
            }}
          ></BringzzIcon>
        </div>
      </div>
      <div className="flex justify-between mb-4">
        <BringzzIcon
          icon="IconChevronLeft"
          folder="LineIcons"
          className={`text-magic-lilac flex items-center ${
            yearRange.start - 1 >= minYear
              ? " cursor-pointer "
              : " cursor-not-allowed opacity-50 "
          }`}
          size="17"
          onClick={() => {
            // If at least one previous year is available
            if (yearRange.start - 1 >= minYear) {
              setYearRange((prev) => ({
                start: prev.start - 12,
                end: prev.end - 12,
              }));
            }
          }}
        ></BringzzIcon>
        <BringzzIcon
          icon="IconChevronLeft"
          folder="LineIcons"
          className={`rotate-180 text-magic-lilac flex items-center ${
            yearRange.end + 1 <= maxYear
              ? " cursor-pointer "
              : " cursor-not-allowed opacity-50 "
          }`}
          size="17"
          onClick={() => {
            if (yearRange.end + 1 <= maxYear) {
              setYearRange((prev) => ({
                start: prev.start + 12,
                end: prev.end + 12,
              }));
            }
          }}
        ></BringzzIcon>
      </div>
      <div className="grid grid-cols-4 gap-4">
        {years.map((yr) => (
          <button
            key={yr}
            className={`p-2 rounded ${
              selectedDate.getFullYear() === yr
                ? "bg-magic-lilac text-white"
                : "bg-gray-200"
            } ${
              yr <= maxYear && yr >= minYear
                ? " cursor-pointer "
                : "cursor-not-allowed opacity-50"
            } `}
            onClick={() => {
              if (yr <= maxYear && yr >= minYear) handleYearSelect(yr);
            }}
          >
            {yr}
          </button>
        ))}
      </div>
    </div>
  );
};
export default YearPicker;
