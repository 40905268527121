import * as React from "react";
const IconBrightness = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 16.1c-8.9 0-16.1 7.1-16.1 15.9S23.1 47.9 32 47.9 48.1 40.8 48.1 32 40.9 16.1 32 16.1m1 2.1c3.5.2 6.6 1.7 8.9 4L33 28.5zM33 31l10.3-7.3c1.2 1.6 2.1 3.4 2.5 5.4L33 37.7zm-15.1 1c0-7.3 5.8-13.3 13.1-13.8v27.6c-7.3-.5-13.1-6.5-13.1-13.8M33 45.8v-5.7l13.1-8.8v.7c0 7.3-5.8 13.3-13.1 13.8M32 10.3c.6 0 1-.4 1-1V3.5c0-.6-.4-1-1-1s-1 .4-1 1v5.8c0 .5.4 1 1 1M32 53.7c-.6 0-1 .4-1 1v5.8c0 .6.4 1 1 1s1-.4 1-1v-5.8c0-.5-.4-1-1-1M61 31h-5.9c-.6 0-1 .4-1 1s.4 1 1 1H61c.6 0 1-.4 1-1s-.4-1-1-1M8.9 31H3c-.6 0-1 .4-1 1s.4 1 1 1h5.9c.6 0 1-.4 1-1s-.4-1-1-1M49 47.4c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l4.2 4.1c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4zM15 16.6c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-4.2-4.1c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4zM51.8 11.1l-4.2 4.1c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3l4.2-4.1c.4-.4.4-1 0-1.4s-1-.4-1.4 0M15 47.4l-4.2 4.1c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3l4.2-4.1c.4-.4.4-1 0-1.4s-1-.4-1.4 0" />
  </svg>
);
export default IconBrightness;
