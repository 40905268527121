import React from 'react';
import classNames from 'classnames';
import { BringzzBoxSlider, BringzzText, BringzzIcon } from "@bringzz/components";
import { calculateFontSize } from './utils';
import { categories } from './constants';

const INITIAL_HEADER_HEIGHT = 200;
const MIN_HEADER_HEIGHT = 100;
const TITLE_FONT_MAX = 18;
const TITLE_FONT_MIN = 16;
const PARAGRAPH_FONT_MAX = 13;
const PARAGRAPH_FONT_MIN = 8;

const getClassNames = (headerHeight) => {
    const baseClasses = 'fixed w-full px-4 top-0 z-50 bg-white transition-shadow duration-300';
    const additionalClasses = headerHeight <= MIN_HEADER_HEIGHT
        ? 'flex flex-col items-center justify-center border-b shadow-md rounded-none space-y-4'
        : 'rounded-b-xl mx-2 flex flex-col py-4';
    const spaceBetweenY = headerHeight <= 120 ? 0 : headerHeight <= 140 ? 0 : headerHeight <= 160 ? 1 : headerHeight <= 180 ? 2 : 2;

    return `${baseClasses} ${additionalClasses} space-y-${spaceBetweenY}`;
};

const HeaderInput = ({ headerHeight, title, searchData, setSearchData, SET_MIN_HEADER_HEIGHT }) => {
    const titleFontSize = calculateFontSize(headerHeight, TITLE_FONT_MAX, TITLE_FONT_MIN, INITIAL_HEADER_HEIGHT, MIN_HEADER_HEIGHT);
    const paragraphFontSize = calculateFontSize(headerHeight, PARAGRAPH_FONT_MAX, PARAGRAPH_FONT_MIN, INITIAL_HEADER_HEIGHT, MIN_HEADER_HEIGHT);

    return (
        <div className={getClassNames(headerHeight)}>
            <div className={classNames(
                'flex space-x-1 transition-all duration-300 transform',
                'justify-between w-full items-center'
            )}>
                <div>
                    <BringzzIcon folder='LineIcons' icon='IconChevronLeft' size='22' onClick={() => {
                        SET_MIN_HEADER_HEIGHT(48)
                        setSearchData(null)
                    }} className='cursor-pointer' />
                </div>
                <div className='flex'>
                    <BringzzText tag='h2' style={{ fontSize: `${titleFontSize}px` }}>Browse</BringzzText>
                    <BringzzText tag='h2' className='text-magic-lilac' style={{ fontSize: `${titleFontSize}px` }}>&</BringzzText>
                    <BringzzText tag='h2' style={{ fontSize: `${titleFontSize}px` }}>Search</BringzzText>
                </div>
                <div></div>
            </div>
        </div>
    );
};

export default HeaderInput;
