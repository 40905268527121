import * as React from "react";
const IconSmartwatchAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M47.5 45.6c-.6 0-1 .4-1 1 0 1.7-1.4 3.1-3.1 3.1H14.5c-1.2 0-2.2-1-2.2-2.2V16.4c0-1.2 1-2.2 2.2-2.2h28.7c1.7 0 3.1 1.4 3.1 3.1 0 .6.4 1 1 1s1-.4 1-1c0-2.8-2.3-5.1-5.1-5.1h-1.6l-1.3-6.1C40 3.7 37.9 2 35.5 2H23.3c-2.4 0-4.5 1.7-5 4.1L17 12.2h-2.5c-2.3 0-4.2 1.9-4.2 4.2v31.2c0 2.3 1.9 4.2 4.2 4.2H17l1.3 6.1c.5 2.4 2.6 4.1 5 4.1h12.1c2.4 0 4.5-1.7 5-4.1l1.3-6.1h1.6c2.8 0 5.1-2.3 5.1-5.1.1-.6-.4-1.1-.9-1.1M20.3 6.5c.3-1.4 1.6-2.5 3-2.5h12.1c1.5 0 2.8 1.1 3.1 2.5l1.2 5.7H19.1zm18.2 51c-.3 1.4-1.5 2.5-3 2.5H23.3c-1.5 0-2.8-1.1-3.1-2.5L19 51.8h20.6z" />
    <path d="m50.4 38.2-.8-.8-.7-.7c1-1 2-2.1 2.9-3.4 1.5-2 2.1-4.2 1.7-6.1-.6-2.7-3.2-4.8-6.1-4.9-2.9.1-5.5 2.2-6 4.9-.4 1.9.2 4 1.7 6.1.9 1.2 1.9 2.3 2.9 3.4l-.7.7-.5.6-3.2 3.2c-.4.4-.4 1 0 1.4s1 .4 1.4 0c1.1-1 2.1-2.1 3.2-3.2l.5-.5.7-.7.7.7.7.7 2.9 2.9c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4-.8-.9-1.8-1.9-2.7-2.9m-2.9-2.9c-.9-1-1.9-2-2.7-3.1-.7-.9-1.7-2.7-1.4-4.4.4-1.8 2.2-3.2 4.1-3.3 1.9.1 3.8 1.5 4.1 3.3.4 1.8-.7 3.6-1.4 4.5-.8 1-1.8 2.1-2.7 3" />
  </svg>
);
export default IconSmartwatchAlt1;
