import * as React from "react";
const IconLightEmergencyOn = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M52.9 50.2h-4.7V32.5c0-8.9-7.3-16.2-16.2-16.2s-16.2 7.3-16.2 16.2v17.7h-4.7c-.6 0-1 .4-1 1s.4 1 1 1h41.8c.6 0 1-.4 1-1s-.4-1-1-1M17.8 32.5c0-7.8 6.4-14.2 14.2-14.2s14.2 6.4 14.2 14.2v17.7H17.8zM6.3 12.1c-.4-.4-1-.3-1.4.1s-.3 1 .1 1.4l3.4 3.1c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.3-1-.1-1.4zM55.7 16.6l3.4-3.1c.4-.4.4-1 .1-1.4-.4-.4-1-.4-1.4-.1l-3.4 3.1c-.4.4-.4 1-.1 1.4.2.2.5.3.7.3.2.1.5 0 .7-.2M8.4 29.4H4c-.6 0-1 .4-1 1s.4 1 1 1h4.4c.6 0 1-.4 1-1s-.4-1-1-1M60 29.4h-4.4c-.6 0-1 .4-1 1s.4 1 1 1H60c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconLightEmergencyOn;
