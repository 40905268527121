import * as React from "react";
const IconBathTub = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.4 32.1H8.7c.1-.1.1-.3.1-.4V8.1l10.1-1.3v1.4c0 1.7 1.3 3 3 3h3.3c1.7 0 3-1.3 3-3V5c0-1.7-1.3-3-3-3h-3.3c-1.6 0-2.9 1.3-3 2.9h-.1L7.6 6.3c-.5.1-.9.5-.9 1v24.5c0 .1 0 .3.1.4H3.6c-.6 0-1 .4-1 1v16.4c0 2.8 2.2 5 5 5h5.9l-2.7 6.1c-.2.5 0 1.1.5 1.3.1.1.3.1.4.1q.6 0 .9-.6l3.1-6.9h35.7l3.1 6.9q.3.6.9.6c.1 0 .3 0 .4-.1.5-.2.7-.8.5-1.3l-2.7-6.1h2.9c2.8 0 5-2.2 5-5V33.1c-.1-.5-.5-1-1.1-1M20.9 5c0-.6.4-1 1-1h3.3c.6 0 1 .4 1 1v3.3c0 .6-.4 1-1 1h-3.3c-.6 0-1-.4-1-1zm38.5 44.5c0 1.7-1.3 3-3 3H7.6c-1.7 0-3-1.3-3-3V34.1h54.9v15.4z" />
  </svg>
);
export default IconBathTub;
