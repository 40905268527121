import * as React from "react";
const IconVirusSanitizerSpray = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="m23.153 4.883 1.414 1.414a3 3 0 0 1 0 4.242l-2.536 2.536v3a3 3 0 0 1-.878 2.121l-5.779 5.778a5 5 0 0 1-7.07 0l-2.83-2.828a5 5 0 0 1 0-7.071l5.779-5.778a3 3 0 0 1 2.121-.879h3l2.536-2.535a3 3 0 0 1 4.243 0m-1.415 1.414a1 1 0 0 0-1.414 0l-2.121 2.121 2.828 2.829 2.122-2.122a1 1 0 0 0 0-1.414zm-5.364 3.121h-3a1 1 0 0 0-.707.293L6.89 15.489a3 3 0 0 0 0 4.243l2.829 2.828a3 3 0 0 0 4.242 0l5.778-5.778a1 1 0 0 0 .293-.707v-3z"
      clipRule="evenodd"
    />
    <path  d="m31.503 10.63-.118.12.576.348z" />
    <path
      
      d="m31.503 10.63.46.47.196.118-.03.05-4.25-2.641-1.036 1.71 4.28 2.592a1 1 0 0 0 1.388-.362q.16-.282.391-.508l.001-.001a2.39 2.39 0 0 1 3.294-.052 2.355 2.355 0 0 1 .2 3.259 1 1 0 0 0-.05 1.23 3.466 3.466 0 0 1-.387 4.5 3.518 3.518 0 0 1-4.538.33 1 1 0 0 0-1.224.033 2.39 2.39 0 0 1-1.855.518 2.4 2.4 0 0 1-1.633-1.014 2.355 2.355 0 0 1 .818-3.397 1 1 0 0 0 .377-1.389l-2.559-4.288-1.717 1.025 2.091 3.504a4.37 4.37 0 0 0-1.349 4.005 4.36 4.36 0 0 0 1.992 2.917 4.39 4.39 0 0 0 4.49.087 5.52 5.52 0 0 0 6.509-.905 5.463 5.463 0 0 0 .995-6.473 4.35 4.35 0 0 0-.805-5.413 4.39 4.39 0 0 0-6.049.095"
    />
    <path
      
      fillRule="evenodd"
      d="M6.685 36c.111-.483.302-.936.557-1.344L6.05 33.465a1 1 0 1 1 1.415-1.415l1.191 1.192A4.4 4.4 0 0 1 10 32.685V31a1 1 0 1 1 2 0v1.685c.483.111.936.302 1.344.557l1.192-1.192a1 1 0 1 1 1.414 1.415l-1.192 1.191c.255.408.446.861.557 1.344H17a1 1 0 1 1 0 2h-1.685a4.4 4.4 0 0 1-.557 1.344l1.192 1.191a1 1 0 0 1-1.414 1.415l-1.192-1.192c-.408.255-.861.446-1.344.557V43a1 1 0 0 1-2 0v-1.685a4.4 4.4 0 0 1-1.344-.557L7.465 41.95a1 1 0 0 1-1.415-1.415l1.192-1.191A4.4 4.4 0 0 1 6.685 38H5a1 1 0 0 1 0-2zm1.886 1c0-.668.27-1.272.706-1.711l.006-.006.006-.006A2.42 2.42 0 0 1 11 34.57c.668 0 1.272.27 1.711.706l.006.006.006.006A2.42 2.42 0 0 1 13.43 37a2.42 2.42 0 0 1-.705 1.71l-.007.007-.007.007a2.42 2.42 0 0 1-1.71.705 2.42 2.42 0 0 1-1.71-.705l-.007-.007-.007-.007A2.42 2.42 0 0 1 8.57 37"
      clipRule="evenodd"
    />
    <path
      
      d="m26.401 27.663.663-.749-1.497-1.326-.663.749A7.53 7.53 0 0 0 23.117 30H20v-.2a1 1 0 0 0-2 0v2.4a1 1 0 1 0 2 0V32h3.031a7.46 7.46 0 0 0 1.594 3.96L22.6 37.987l-.493-.493a1 1 0 0 0-1.414 1.414l2.4 2.4a1 1 0 0 0 1.414-1.414l-.493-.493 2.043-2.043A8.16 8.16 0 0 0 30 38.94V42h-.2a1 1 0 1 0 0 2h2.4a1 1 0 0 0 0-2H32v-3.06a8.16 8.16 0 0 0 3.943-1.583l1.843 1.843-.493.493a1 1 0 0 0 1.414 1.414l2.4-2.4a1 1 0 0 0-1.414-1.414l-.493.493-1.825-1.825A7.46 7.46 0 0 0 38.969 32H42v.2a1 1 0 1 0 2 0v-2.4a1 1 0 0 0-2 0v.2h-3.117a7.5 7.5 0 0 0-.9-2.44l-.145-.247q.105-.045.209-.094c1.416-.659 2.73-1.813 3.821-3.723a1 1 0 1 0-1.736-.992c-.909 1.59-1.928 2.436-2.929 2.902C36.184 25.88 35.101 26 34 26a1 1 0 1 0 0 2c.582 0 1.205-.03 1.848-.128l.407.697c.476.815.745 1.75.745 2.746C37 34.412 34.358 37 31 37s-6-2.588-6-5.685c0-1.386.523-2.66 1.401-3.652"
    />
  </svg>
);
export default IconVirusSanitizerSpray;
