import * as React from "react";
const IconTide = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m9.3 43.7 5.2-3.8c1.2-.9 2.7-.9 3.9 0l2.7 2c1.9 1.4 4.4 1.4 6.2 0l2.7-2c1.2-.9 2.7-.9 3.9 0l2.7 2c1.9 1.4 4.4 1.4 6.2 0l2.7-2c1.2-.8 2.7-.8 3.9 0l5.2 3.8c.2.1.4.2.6.2.3 0 .6-.1.8-.4.3-.4.2-1.1-.2-1.4l-5.2-3.8c-1.9-1.4-4.4-1.4-6.2 0l-2.7 2c-1.2.8-2.7.9-3.9 0l-2.7-2c-1.9-1.4-4.4-1.4-6.2 0l-2.7 2c-1.2.9-2.7.9-3.9 0l-2.7-2c-1.9-1.4-4.4-1.4-6.2 0l-5.2 3.8c-.4.3-.5 1-.2 1.4.3.5.9.5 1.3.2M50.6 55.4c-1.9-1.4-4.4-1.4-6.2 0l-2.7 2c-1.2.9-2.7.8-3.9 0l-2.7-2c-1.9-1.4-4.4-1.4-6.2 0l-2.7 2c-1.2.9-2.7.9-3.9 0l-2.7-2c-1.9-1.4-4.4-1.4-6.2 0l-5.2 3.8c-.4.3-.5 1-.2 1.4s1 .5 1.4.2l5.2-3.8c1.2-.9 2.7-.9 3.9 0l2.7 2c1.9 1.4 4.4 1.4 6.2 0l2.7-2c1.2-.9 2.7-.9 3.9 0l2.7 2c1.9 1.4 4.4 1.4 6.2 0l2.7-2c1.2-.8 2.7-.8 3.9 0l5.2 3.8c.2.1.4.2.6.2.3 0 .6-.1.8-.4.3-.4.2-1.1-.2-1.4zM31.3 24.7c.2.2.4.3.7.3s.5-.1.7-.3l8.2-8.3c.4-.4.4-1 0-1.4s-1-.4-1.4 0L33 21.5V4c0-.6-.4-1-1-1s-1 .4-1 1v17.5l-6.5-6.6c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4z" />
  </svg>
);
export default IconTide;
