import * as React from "react";
const IconDisinfectingWipes = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      d="M28 33.165V27c-3.205.61-4.969.63-8 0v6.165c3.094.9 4.881 1 8 0M27.885 8.577a1 1 0 0 1 .538 1.308l-2.5 6a1 1 0 0 1-1.846-.77l2.5-6a1 1 0 0 1 1.308-.538"
    />
    <path
      
      fillRule="evenodd"
      d="M33.414 5.09a1 1 0 0 1 .516 1.278l-3.838 9.687C31.282 16.58 32 17.258 32 18q0 .036-.002.072H32V41c0 1.657-3.582 3-8 3s-8-1.343-8-3V18.072h.002L16 18c0-.793.82-1.514 2.16-2.05L15.044 5.793a1 1 0 0 1 1.711-.949l.012.013q.02.022.071.073c.069.067.178.167.325.283.296.231.736.516 1.304.722 1.088.396 2.762.55 4.987-.773 2.072-1.46 4.638-1.41 6.529-1.085a16 16 0 0 1 3.35.977l.056.025.017.007.005.002zM23.94 21c-2.252-.006-4.283-.361-5.727-.928H18v20.637c.172.138.504.337 1.045.54 1.17.439 2.922.751 4.955.751s3.784-.312 4.955-.751c.541-.203.873-.402 1.045-.54V20.07h-.214c-1.444.568-3.475.923-5.727.929h-.119m5.702-14.952c-1.725-.297-3.64-.25-5.062.766l-.066.044c-2.76 1.655-5.074 1.56-6.732.957l-.03-.011L20.85 17.9h6.359l4.488-11.328a14 14 0 0 0-2.055-.525M17.898 40.615l-.003-.004v.001z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconDisinfectingWipes;
