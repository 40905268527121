import * as React from "react";
const IconRdtResultNegInvalidRectangular = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M6 19v10h36V19zM4 29a2 2 0 0 0 2 2h36a2 2 0 0 0 2-2V19a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2z"
      clipRule="evenodd"
    />
    <path
      
      d="M38 22a2 2 0 1 1 0 4 2 2 0 0 1 0-4M32.5 25.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3"
    />
    <path
      
      fillRule="evenodd"
      d="M26 23H11a1 1 0 1 0 0 2h15a1 1 0 1 0 0-2M8 24a3 3 0 0 0 3 3h15a3 3 0 1 0 0-6H11a3 3 0 0 0-3 3M11.593 11.026a1.2 1.2 0 0 0-.742.079 1.4 1.4 0 0 0-.609.537A1.6 1.6 0 0 0 10 12.5c0 .31.087.61.242.858.156.248.37.432.609.537.237.105.494.131.742.079s.485-.185.677-.39a1 1 0 0 1 1.46 1.368 3.3 3.3 0 0 1-1.722.978 3.2 3.2 0 0 1-1.966-.206 3.4 3.4 0 0 1-1.495-1.304A3.6 3.6 0 0 1 8 12.5c0-.678.188-1.346.547-1.92a3.4 3.4 0 0 1 1.495-1.304 3.2 3.2 0 0 1 1.966-.206c.661.14 1.259.485 1.722.978a1 1 0 1 1-1.46 1.368 1.3 1.3 0 0 0-.677-.39M13 33a1 1 0 0 1 1-1h2.5a2.5 2.5 0 0 1 0 5H15v1a1 1 0 1 1-2 0zm2 2h1.5a.5.5 0 0 0 0-1H15zM27 10a1 1 0 0 1 1-1h2.5a2.5 2.5 0 0 1 0 5H29v1a1 1 0 1 1-2 0zm2 2h1.5a.5.5 0 0 0 0-1H29zM22.5 39a1 1 0 0 0 .936-.649l1.5-4a1 1 0 1 0-1.872-.702l-.564 1.503-.564-1.503a1 1 0 0 0-1.872.702l1.5 4A1 1 0 0 0 22.5 39"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M36 13v2a1 1 0 1 1-2 0v-2a1 1 0 1 1 0-2c0-.327.09-.794.389-1.21.335-.465.885-.79 1.611-.79a1 1 0 0 1 0 2 1 1 0 0 1 0 2"
      clipRule="evenodd"
    />
  </svg>
);
export default IconRdtResultNegInvalidRectangular;
