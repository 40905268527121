import * as React from "react";
const IconHealthWorkerForm = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      d="M16 20a1 1 0 1 0 0 2h7a1 1 0 1 0 0-2zM15 16a1 1 0 0 1 1-1h15.5a1 1 0 1 1 0 2H16a1 1 0 0 1-1-1"
    />
    <path
      
      fillRule="evenodd"
      d="M15 28a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1zm2 3v-2h2v2zM28 31a3 3 0 1 0 0-6 3 3 0 1 0 0 6m0-2a1 1 0 1 0 .002-1.998A1 1 0 0 0 28 29M22 35.182C22 33.066 25.997 32 28 32s6 1.066 6 3.182V39H22zm2.055.04-.055.057V37h8v-1.72l-.055-.059c-.164-.16-.48-.372-.976-.583-1-.425-2.234-.638-2.969-.638s-1.969.213-2.969.638c-.496.21-.812.423-.976.583"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M17 7a3 3 0 0 1 3-3h8a3 3 0 0 1 3 3h4a3 3 0 0 1 3 3v31a3 3 0 0 1-3 3H13a3 3 0 0 1-3-3V10a3 3 0 0 1 3-3zm11 5a3 3 0 0 0 3-3h4a1 1 0 0 1 1 1v31a1 1 0 0 1-1 1H13a1 1 0 0 1-1-1V10a1 1 0 0 1 1-1h4a3 3 0 0 0 3 3zm-8-6a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconHealthWorkerForm;
