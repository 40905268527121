import * as React from "react";
const IconStatistics = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M26.5 43.8h11.2c.6 0 1-.4 1-1s-.4-1-1-1H26.5c-.6 0-1 .4-1 1s.5 1 1 1M45.7 50.8H26.5c-.6 0-1 .4-1 1s.4 1 1 1h19.2c.6 0 1-.4 1-1s-.5-1-1-1" />
    <path d="M54.4 2H19.7c-2.4 0-4.3 1.9-4.3 4.3v39.4h-10c-.6 0-1 .4-1 1V57c0 2.7 2.2 5 5 5h45.1c2.9 0 5.2-2.3 5.2-5.2V7.2C59.6 4.3 57.3 2 54.4 2M11.3 60h-2c-1.6 0-3-1.3-3-3v-9.4h9V57c0 1.6-1.3 3-3 3zm46.3-3.2c0 1.8-1.4 3.2-3.2 3.2h-38c.6-.8 1-1.8 1-3V6.3c0-1.3 1-2.3 2.3-2.3h34.7c1.8 0 3.2 1.4 3.2 3.2z" />
    <path d="m47.9 20.9-5.6 2.6L35 20c-.3-.1-.6-.1-.8 0l-6.6 3v-9.4c0-.6-.4-1-1-1s-1 .4-1 1v20.7c0 .6.4 1 1 1h20.6c.6 0 1-.4 1-1s-.4-1-1-1H27.5v-8.4c.2.1.5.1.7 0l6.3-2.9 7.3 3.5c.1.1.3.1.4.1s.3 0 .4-.1l6-2.8c.5-.2.7-.8.5-1.3-.1-.5-.7-.7-1.2-.5" />
  </svg>
);
export default IconStatistics;
