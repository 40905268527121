import * as React from "react";
const IconBowl = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.1 17.2c-.7-.7-1.6-1.1-2.6-1.1h-53c-1 0-1.9.4-2.6 1.1-.6.7-1 1.7-.9 2.6.9 9.4 6.3 17.8 14.5 22.5v2.2c0 1.9 1.5 3.4 3.4 3.4H44c1.9 0 3.4-1.5 3.4-3.4v-2.2c2.2-1.3 4.3-2.8 6.1-4.7 4.8-4.8 7.8-11.1 8.4-17.9.2-.9-.1-1.8-.8-2.5m-8.9 19c-1.8 1.8-3.9 3.4-6.2 4.6-.3.2-.5.5-.5.9v2.8c0 .8-.7 1.4-1.4 1.4H20c-.8 0-1.4-.7-1.4-1.4v-2.8c0-.4-.2-.7-.5-.9-5.3-2.8-9.4-7.2-11.8-12.5h51.3c-1.3 2.9-3.1 5.6-5.4 7.9m6.3-9.9h-53c-.8-2.1-1.3-4.4-1.5-6.7 0-.4.1-.8.4-1.1s.7-.5 1.1-.5h53.1c.4 0 .8.2 1.1.5s.4.7.4 1.1c-.3 2.3-.8 4.6-1.6 6.7" />
  </svg>
);
export default IconBowl;
