import * as React from "react";
const IconArrowLongLeftE = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.5 25.8H51c-1.1 0-2 .9-2 2V31H6l9.7-9.4c.4-.4.4-1 0-1.4s-1-.4-1.4 0L2.8 31.3c-.2.2-.3.4-.3.7s.1.5.3.7l11.4 11.1c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4L6 33h43v3.2c0 1.1.9 2 2 2h8.5c1.1 0 2-.9 2-2v-8.5c0-1-.9-1.9-2-1.9m0 10.4H51v-8.5h8.5z" />
  </svg>
);
export default IconArrowLongLeftE;
