import * as React from "react";
const IconScrollV = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 21.4c-5.9 0-10.6 4.8-10.6 10.6 0 5.9 4.8 10.6 10.6 10.6 5.9 0 10.6-4.8 10.6-10.6 0-5.9-4.7-10.6-10.6-10.6m0 19.2c-4.8 0-8.6-3.9-8.6-8.6s3.9-8.6 8.6-8.6 8.6 3.9 8.6 8.6-3.8 8.6-8.6 8.6M42 48.8l-10 9.8-10-9.8c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l10.7 10.5c.2.2.4.3.7.3s.5-.1.7-.3l10.7-10.5c.4-.4.4-1 0-1.4s-1-.4-1.4 0M22 15.2l10-9.8 10 9.8c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4L32.7 3.3c-.4-.4-1-.4-1.4 0L20.6 13.8c-.4.4-.4 1 0 1.4s1 .4 1.4 0" />
  </svg>
);
export default IconScrollV;
