import * as React from "react";
const IconAngleDoubleRight = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m38.4 30.4-22.6-23c-.9-.9-2.3-.9-3.2 0s-.9 2.3 0 3.2l21 21.4-21 21.4c-.9.9-.9 2.3 0 3.2.4.4 1 .6 1.6.6s1.2-.2 1.6-.7l22.6-23c.9-.8.9-2.2 0-3.1" />
    <path d="m51.4 30.4-22.6-23c-.9-.9-2.3-.9-3.2 0s-.9 2.3 0 3.2l21 21.4-21 21.4c-.9.9-.9 2.3 0 3.2.4.4 1 .6 1.6.6s1.2-.2 1.6-.7l22.6-23c.9-.8.9-2.2 0-3.1" />
  </svg>
);
export default IconAngleDoubleRight;
