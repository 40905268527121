import * as React from "react";
const IconMapMarkerCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2C18.5 2 7.5 12.6 7.5 25.7c0 10.4 15.2 28 21.7 35.1.8.8 1.8 1.3 2.8 1.3 1.1 0 2.1-.5 2.8-1.3 6.5-7.1 21.7-24.6 21.7-35.1C56.5 12.6 45.5 2 32 2m1.4 57.4c-.7.8-2 .8-2.7 0-5-5.4-21.2-23.7-21.2-33.7C9.5 13.7 19.6 4 32 4s22.5 9.7 22.5 21.7c0 10-16.2 28.3-21.1 33.7" />
    <path d="M32 16.4c-5.5 0-10 4.5-10 10s4.5 10 10 10 10-4.5 10-10-4.5-10-10-10m0 18c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8c0 4.5-3.6 8-8 8" />
  </svg>
);
export default IconMapMarkerCopy;
