import React, { Fragment } from "react";
import { generateCSSString, getPaddingStyles, getResponsiveStyles } from "../../../../CmsPage/helpers/style";

export default function Footer({
    component,
    children,
}) {

    const cssValue = [...getPaddingStyles('footer'),
    ...getResponsiveStyles('footer', 'FlexDirection'),
    ...getResponsiveStyles('footer', 'JustifyContent'),
    ...getResponsiveStyles('footer', 'AlignItems')];

    return (
        <div
            id={`'Footer-${component.uuid}`}
            className={`${generateCSSString(component, cssValue)} flex flex-wrap w-full`}
            style={{
                backgroundColor: `${component.footerBackground_color ? component.footerBackground_color : 'none'}`
            }}
        >
            {children}
        </div>
    )
}