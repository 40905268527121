import * as React from "react";
const IconPsychology = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M6 9a3 3 0 0 1 3-3h30a3 3 0 0 1 3 3v30a3 3 0 0 1-3 3H9a3 3 0 0 1-3-3zm3-1h30a1 1 0 0 1 1 1v30a1 1 0 0 1-1 1H25.014v-6s.833.273 2 .46c.293.046.607.088.934.118 2.717.25 6.34-.27 6.34-4.824 0 0 2.442-.373 2.69-1.727.247-1.353-1.629-3.04-1.629-3.04-.634-1.315-.617-2.427-.599-3.69.005-.357.01-.726.002-1.116a8.54 8.54 0 0 0-1.597-4.747 10.4 10.4 0 0 0-4.174-3.041A16.4 16.4 0 0 0 22.335 11c-3.006 0-5.89 1.137-8.015 3.16S11 18.928 11 21.79c0 3.443 2.033 6.099 4.67 8.07V40H9a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1m17.491 14.47c-.112.125-.171.19-.524.19a2.83 2.83 0 0 1-1.565-.47c-.324.334-.72.593-1.161.757-.399.191-.57.472-.783.817l-.119.191a2.68 2.68 0 0 0-.422 1.444v2.872h-2.973a6 6 0 0 0-.093-.568l-.005-.022a3.4 3.4 0 0 0-.457-1.14 4.2 4.2 0 0 0-1.337-1.296 2.8 2.8 0 0 1-1.17-.916 2.7 2.7 0 0 1-.513-1.372 2.66 2.66 0 0 1-.187-3.505 2.4 2.4 0 0 1-.098-.718c-.002-.58.19-1.146.544-1.614l1.424-.981a2.73 2.73 0 0 1 1.024-1.17 2.84 2.84 0 0 1 2.174-.362 2.82 2.82 0 0 1 1.667-.718l3.025.205c.668.16 1.252.554 1.64 1.107h.197l1.748.595a2.7 2.7 0 0 1 .978 1.53c.6.254 1.092.7 1.394 1.264a2.7 2.7 0 0 1 .266 1.837 2.76 2.76 0 0 1-.981 1.59l-1.79.633-.133-.001h-.014a2.84 2.84 0 0 1-1.438-.458c-.17.112-.253.205-.318.278m3.648 3.697a1.083 1.083 0 0 0 .308-2.122l-.012-.004a1.08 1.08 0 0 0-1.19.429l-.005.008a1.083 1.083 0 0 0 .898 1.69m-8.306 5.514h-2.888v-2h2.888zm-2.888 6.606h2.888v-2h-2.888zm2.888-3.328h-2.888v-2h2.888z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconPsychology;
