import * as React from "react";
const IconTrashCanAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M49.6 9.3h-9.4V6.1c0-2.3-1.8-4.1-4.1-4.1h-8.2c-2.3 0-4.1 1.8-4.1 4.1v3.2h-9.4c-2.2 0-4 1.8-4 4v3.3c0 1.9 1.4 3.6 3.2 3.9l1.7 35.1c.2 3.6 3.1 6.3 6.7 6.3h20.1c3.6 0 6.5-2.8 6.7-6.3l1.7-35.1c1.8-.4 3.2-2 3.2-3.9v-3.3c-.1-2.2-1.9-4-4.1-4M25.8 6.1c0-1.2.9-2.1 2.1-2.1h8.2c1.2 0 2.1.9 2.1 2.1v3.2H25.8zM42.1 60H21.9c-2.5 0-4.5-2-4.7-4.4l-1.7-35h32.8l-1.7 35c0 2.4-2 4.4-4.5 4.4m9.5-43.4c0 1.1-.9 2-2 2H14.4c-1.1 0-2-.9-2-2v-3.3c0-1.1.9-2 2-2h35.2c1.1 0 2 .9 2 2z" />
    <path d="M32 33.8c-.6 0-1 .4-1 1V48c0 .6.4 1 1 1s1-.4 1-1V34.8c0-.6-.4-1-1-1M40.1 35.8c-.5 0-1 .4-1.1.9l-.6 9.1c0 .6.4 1 .9 1.1h.1c.5 0 1-.4 1-.9l.6-9.1c.1-.6-.3-1.1-.9-1.1M23.7 35.8c-.6 0-1 .5-.9 1.1l.7 9.1c0 .5.5.9 1 .9h.1c.6 0 1-.5.9-1.1l-.7-9.1c0-.5-.5-.9-1.1-.9" />
  </svg>
);
export default IconTrashCanAlt2;
