/* eslint-disable no-undef */
import React, { createContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import qs from 'qs';
import useUser from 'hooks/useUser';
import useRequest from 'hooks/useRequest';
import { useOrder } from './OrderContext';
import { useToken } from 'hooks/useToken';

const AuthContext = createContext();

const deleteAllCookies = () => {
  const cookies = document.cookie.split(";");

  for (let cookie of cookies) {
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`;
  }
};

// Function to enhance user with fullname and initials
const enhanceUser = (user) => {
  if (user) {
    const fullName = `${user.firstName || ''} ${user.lastName || ''}`.trim(); // Handle missing names
    const initials = `${(user.firstName || '').charAt(0)}${(user.lastName || '').charAt(0)}`.toUpperCase(); // Get initials

    return {
      ...user,
      fullName,
      initials,
      getInitials: function () {
        return this.initials;
      }
    };
  }
  return user;
};

export const AuthProvider = ({ children }) => {
  const { getUser, setUser } = useUser();
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(localStorage.getItem('token'));
  const { sendRequest } = useRequest();

  const clearAuthState = () => {
    console.log("clearAuthState called");
    setUser(null);
    setRoles([]);
    setToken(null);
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('user');
    deleteAllCookies();
  };

  const continueSignup = async (token) => {
    try {
      setToken(token);
      localStorage.setItem('token', token);
      await fetchUser();
      return true;
    } catch (error) {
      console.error('Error during login:', error);
      clearAuthState();
      return false;
    }
  };

  const login = async (credentials) => {
    try {
      // Clear existing token to prevent conflicts
      setToken(null);
      localStorage.removeItem('token');

      const data = qs.stringify({
        'app_login': credentials.username,
        'app_password': credentials.password,
        'method': 'GET'
      });

      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BACKEND_URL}/module/logon/getToken?${data}`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      };

      const response = await axios.request(config);
      if (response.data.token) {
        setToken(response.data.token);
        localStorage.setItem('token', response.data.token);
        await fetchUser();
        return true;
      } else {
        clearAuthState();
        return false;
      }
    } catch (error) {
      console.error('Error during login:', error);
      clearAuthState();
      return false;
    }
  };

  const loginWithToken = async (token, user) => {
    try {
      setUser(enhanceUser(user));
      setRoles([user.userRole]);
      setToken(token);
      localStorage.setItem('token', token);
      localStorage.setItem('userId', user.id);
      localStorage.setItem('user', JSON.stringify(user));
      return true;
    } catch (error) {
      console.error('Error during login with token:', error);
      clearAuthState();
      return false;
    } finally {
      setLoading(false);
    }
  };

  const loginWithFacebook = () => {
    try {
      if (window.FB) {
        window.FB.login(function (response) {
          if (response.authResponse) {
            const accessToken = response.authResponse.accessToken;
            window.FB.api('/me', 'GET', { fields: 'id,name,email' }, async function (userResponse) {
              if (userResponse && !userResponse.error) {
                const { email } = userResponse;
                const response = await sendRequest({
                  method: 'POST',
                  url: `/module/user/facebookAuthCallback`,
                  data: { email }
                });

                if (response.data) {
                  loginWithToken(response.data.token, response.data);
                } else {
                  alert('must sign up first.');
                }
              } else {
                console.error('Error fetching user data:', userResponse.error);
              }
            });
          } else {
            console.log('User cancelled login or did not fully authorize.');
          }
        }, { scope: 'public_profile,email' });
      } else {
        console.error('Facebook SDK not loaded.');
      }
    } catch (error) {
      console.error('Error processing Facebook login:', error);
    }
  };

  const logout = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}/logout.php`);
    } catch (error) {
      console.error('Error during logout:', error);
    } finally {
      clearAuthState();
      // Force a page reload to ensure all old state is cleared
      window.location = "/"
      // window.location.reload();
    }
  };

  const fetchUser = async () => {
    try {
      const token = useToken();
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/module/user/fetchUser`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.data.status === 'success') {
        const userData = response.data.data;
        setUser(enhanceUser(userData));
        setRoles([userData.userRole]);
      } else {
        console.log("HNEEEEEEEEEE");

        clearAuthState();
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      clearAuthState();
    } finally {
      setLoading(false);
    }
  };

  const hasToken = useCallback(() => {
    return !!localStorage.getItem('token');
  }, []);

  const refreshUserData = async () => {
    if (token) {
      await fetchUser();
    }
  };

  useEffect(() => {
    if (token) {
      const savedUser = getUser();
      if (savedUser) {
        setRoles([savedUser.userRole]);
        setLoading(false);
      } else {
        const id = localStorage.getItem('userId');
        if (id) {
          fetchUser();
        } else {
          setLoading(false);
        }
      }
    } else {
      setLoading(false);
    }
  }, [token, getUser]);

  return (
    <AuthContext.Provider value={{ user: getUser(), setUser, roles, login, loginWithToken, continueSignup, loginWithFacebook, logout, loading, hasToken, refreshUserData }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);
