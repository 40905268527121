import * as React from "react";
const IconAir = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.8 11.8c0-4.9-4-8.9-8.8-8.9-.6 0-1 .4-1 1s.4 1 1 1c3.8 0 6.8 3.1 6.8 6.9s-3.1 6.9-6.8 6.9H3c-.6 0-1 .4-1 1s.4 1 1 1h44.9c4.9-.1 8.9-4 8.9-8.9M12.1 32c-.6 0-1 .4-1 1s.4 1 1 1H43c.6 0 1-.4 1-1s-.4-1-1-1zM53.2 45.4h-45c-.6 0-1 .4-1 1s.4 1 1 1h44.9c3.8 0 6.8 3.1 6.8 6.9 0 1.9-.8 3.8-2.2 5.1-.4.4-.4 1-.1 1.4.2.2.5.3.7.3s.5-.1.7-.3c1.8-1.7 2.9-4.1 2.9-6.5.1-4.9-3.9-8.9-8.7-8.9" />
  </svg>
);
export default IconAir;
