import * as React from "react";
const IconMegaphone = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.7 3.5c-1.7-1.3-3.9-1.8-6-1.3l-35.4 8.7h-7.1c-4.1 0-7.5 3.3-7.5 7.5v15.2c0 3.6 2.5 6.6 5.9 7.3L13 59.1c.4 1.7 2 2.9 3.7 2.9h1.9c1.2 0 2.3-.5 3-1.5.7-.9 1-2.1.7-3.3l-3.7-15.9 34 8.3c.6.1 1.1.2 1.7.2 1.6 0 3.1-.5 4.3-1.5 1.7-1.3 2.6-3.3 2.6-5.4v-34c.1-2.1-.8-4.1-2.5-5.4m-40.3 9.2 15-3.7v33.9l-15-3.7zM4.7 33.6V18.4c0-3 2.4-5.5 5.5-5.5h6.3V39H9.6c-2.8-.3-4.9-2.7-4.9-5.4m15.4 25.7c-.4.4-.9.7-1.4.7h-1.9c-.9 0-1.6-.6-1.8-1.4L10.6 41h5.9l3.9 16.7c.2.6.1 1.2-.3 1.6m39.2-16.2c0 1.5-.7 2.9-1.9 3.8-1.2 1-2.8 1.3-4.3.9l-17.8-4.4V8.5l17.8-4.4c1.5-.4 3.1 0 4.3.9s1.9 2.3 1.9 3.8z" />
  </svg>
);
export default IconMegaphone;
