import * as React from "react";
const IconSwift = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M63.1 18.2v-1.8c0-1.4-.1-2.7-.4-4.1-.1-1.4-.7-2.7-1.3-3.8-1.3-2.4-3.2-4.5-5.8-5.8-1.3-.6-2.5-1.1-3.9-1.3-1.4-.1-2.7-.4-4.1-.4H15.5c-1.1 0-2 .1-3.1.3-1 .1-2 .4-3 .8-.3.1-.7.3-1 .4-.9.6-1.7 1.2-2.5 1.9-.3.1-.5.4-.9.7-1 1-1.7 2-2.4 3.2-.6 1.3-1.1 2.5-1.3 3.9S1 14.9.9 16.3v31.2c0 1.4.1 2.7.4 4.1.1 1.4.7 2.7 1.3 3.9C3.9 58 5.8 60 8.4 61.3c1.3.6 2.5 1.1 3.9 1.3 1.4.1 2.7.4 4.1.4h31.2c1.4 0 2.7-.1 4.1-.4 1.4-.1 2.7-.7 3.9-1.3 2.4-1.3 4.5-3.2 5.8-5.8.6-1.3 1.1-2.5 1.3-3.9s.4-2.7.4-4.1V18.2m-9.7 33.3c-2.8-5.3-7.9-4.1-10.5-2.7-.1.1-.4.3-.7.4H42c-5.3 3-12.8 3.1-20.1-.1-6-2.7-11-7-14.5-12.5 1.7 1.3 3.5 2.4 5.5 3.2 7.9 3.7 15.6 3.4 21.2 0-7.9-6-14.3-13.9-19.5-20.2-1-1.1-1.8-2.2-2.7-3.5 6 5.5 15.6 12.5 19 14.3-7.3-7.6-13.6-17.2-13.4-16.7 11.4 11.5 21.9 18.1 21.9 18.1.4.1.7.4 1 .6.1-.6.4-1.1.6-1.7 1.8-6.6-.1-14.3-4.8-20.5 10.7 6.3 16.9 18.4 14.3 28.7-.1.1-.1.4-.1.7 5.1 6.1 3.7 13.1 3 11.9" />
  </svg>
);
export default IconSwift;
