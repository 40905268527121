import * as React from "react";
const IconFarenheitAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
    <path d="M24.6 17.6c-2.9 0-5.3 2.4-5.3 5.3s2.4 5.3 5.3 5.3 5.3-2.4 5.3-5.3-2.4-5.3-5.3-5.3m0 8.6c-1.8 0-3.3-1.5-3.3-3.3s1.5-3.3 3.3-3.3 3.3 1.5 3.3 3.3-1.5 3.3-3.3 3.3M43.1 31h-9.8c-.6 0-1 .4-1 1v16c0 .6.4 1 1 1s1-.4 1-1v-6.7h6c.6 0 1-.4 1-1s-.4-1-1-1h-6V33h8.8c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconFarenheitAlt;
