import * as React from "react";
const IconLocker = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.3 2H12.7C10 2 7.8 4.2 7.8 6.8V61c0 .6.4 1 1 1s1-.4 1-1V49.2h25V61c0 .6.4 1 1 1s1-.4 1-1v-3.9h17.3V61c0 .6.4 1 1 1s1-.4 1-1V6.8C56.2 4.2 54 2 51.3 2m2.9 4.8v23H36.8V4h14.5c1.6 0 2.9 1.3 2.9 2.8M12.7 4h22.2v16.3h-25V6.8C9.8 5.3 11.1 4 12.7 4M9.8 47.2V22.3h25v24.9zm27 7.9V31.8h17.3v23.3z" />
    <path d="M41.9 19.8c.6 0 1-.4 1-1v-.6c0-.6-.4-1-1-1s-1 .4-1 1v.6c0 .6.4 1 1 1M41.9 40.3c-.6 0-1 .4-1 1v.6c0 .6.4 1 1 1s1-.4 1-1v-.6c0-.6-.5-1-1-1" />
  </svg>
);
export default IconLocker;
