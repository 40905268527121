import * as React from "react";
const IconLactoseFree = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M24 42c9.941 0 18-8.059 18-18S33.941 6 24 6 6 14.059 6 24s8.059 18 18 18m0 2c11.046 0 20-8.954 20-20S35.046 4 24 4 4 12.954 4 24s8.954 20 20 20"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M27.667 13.044 30 22.174v13.391C30 36.91 28.955 38 27.667 38h-7.334C19.045 38 18 36.91 18 35.565V22.174l2.333-9.13s-1.166-.61-1.166-1.827S19.75 10 21.5 10h5c1.75 0 2.333 0 2.333 1.217s-1.166 1.826-1.166 1.826m-7.386 8.282 2.382-9.323-.006-.003h2.686l-.006.003L28 22.425v.373l-.053-.001a7.646 7.646 0 0 1-1.08-.12c-.733-.138-1.264-.363-1.502-.586-1.41-1.316-3.28-1.148-4.232-.97a9 9 0 0 0-.852.205m.059-9.292h.002zm7.32 0h-.002zM28 24.798V35a1 1 0 0 1-1 1h-6a1 1 0 0 1-1-1V23.553s2.667-1.245 4 0 4 1.245 4 1.245M10.293 9.793a1 1 0 0 1 1.414 0l5.5 5.5a1 1 0 0 1-1.414 1.414l-5.5-5.5a1 1 0 0 1 0-1.414"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="m37.293 38.707-9-9 1.414-1.414 9 9z"
      clipRule="evenodd"
    />
    <path  d="M21 11h6v2h-6z" />
  </svg>
);
export default IconLactoseFree;
