import * as React from "react";
const IconToriiGate = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.1 2.7c-.2-.5-.7-.8-1.2-.7-26.6 7.9-49.3 1.5-53.6.1-.1 0-.2-.1-.2-.1-.5-.1-1.1.2-1.2.8-.1.5.2 1.1.8 1.2 2.7.9 12.7 3.7 26.3 3.9v10.5H5.7c-.6 0-1 .4-1 1s.4 1 1 1H11v7.3H5.6c-.6 0-1 .4-1 1s.4 1 1 1H11V61c0 .6.4 1 1 1s1-.4 1-1V29.6h38V61c0 .6.4 1 1 1s1-.4 1-1V29.6h5.3c.6 0 1-.4 1-1s-.4-1-1-1H53v-7.3h5.4c.6 0 1-.4 1-1s-.4-1-1-1H33V7.9c7.9-.1 16.9-1.1 26.4-3.9.6-.2.9-.8.7-1.3M51 27.6H13v-7.3h38z" />
  </svg>
);
export default IconToriiGate;
