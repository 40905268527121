import * as React from "react";
const IconCupboard = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M48.6 2H15.4c-2.8 0-5 2.2-5 5v44.8c0 2.8 2.2 5 5 5h.9V61c0 .6.4 1 1 1s1-.4 1-1v-4.2h27.5V61c0 .6.4 1 1 1s1-.4 1-1v-4.2h.9c2.8 0 5-2.2 5-5V7c-.1-2.8-2.3-5-5.1-5M12.4 51.8V7c0-1.7 1.3-3 3-3H31v22.4h-1.7c-.6 0-1 .4-1 1s.4 1 1 1H31v26.4H15.4c-1.7 0-3-1.3-3-3m39.2 0c0 1.7-1.3 3-3 3H33V28.4h1.7c.6 0 1-.4 1-1s-.4-1-1-1H33V4h15.6c1.7 0 3 1.3 3 3z" />
  </svg>
);
export default IconCupboard;
