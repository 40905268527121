import * as React from "react";
const IconPlant = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.6 26.1c-.3-.3-7.7-7.8-14.2-7.8h-.1c-5 0-10.3 4.2-12.7 6.3-.3-4.2-1.3-12.5-5.8-16.9C19.9 1.9 6.6 2 6.1 2s-1 .4-1 1-.1 13.6 5.8 19.4c5 4.9 15.4 5.6 18.7 5.7v18.1h-1.3c-8.1 0-14.8 6.6-14.8 14.8 0 .6.4 1 1 1h32.2c.6 0 1-.4 1-1 0-8.1-6.6-14.8-14.8-14.8h-1.3V28.6c2.5 2.2 7.9 6.4 12.8 6.5h.1c6.5 0 13.7-7.3 14-7.6.5-.4.5-1 .1-1.4M45.7 60H15.6c.5-6.6 6-11.8 12.7-11.8H33c6.7 0 12.2 5.2 12.7 11.8M12.3 21C7.9 16.6 7.2 7.1 7.1 4c3.2.1 12.9.8 17.3 5.1s5.1 13.8 5.2 16.9c-3.1 0-12.8-.7-17.3-5m32.2 12.1c-4.6 0-10.1-4.7-12.1-6.5 1.9-1.8 7.3-6.3 11.9-6.3h.1c4.6 0 10.1 4.7 12.1 6.5-2 1.8-7.4 6.3-12 6.3" />
  </svg>
);
export default IconPlant;
