import * as React from "react";
const IconChevronUpCircle = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 1.8C15.3 1.8 1.8 15.3 1.8 32S15.3 62.3 32 62.3 62.3 48.7 62.3 32 48.7 1.8 32 1.8m0 56C17.8 57.8 6.3 46.2 6.3 32S17.8 6.3 32 6.3s25.8 11.6 25.8 25.8c0 14.1-11.6 25.7-25.8 25.7" />
    <path d="M33.6 23.8c-.9-.9-2.3-.9-3.2 0L16.9 37c-.9.9-.9 2.3 0 3.2.4.4 1 .7 1.6.7s1.1-.2 1.6-.6L32 28.5l11.9 11.7c.9.9 2.3.9 3.2 0s.9-2.3 0-3.2z" />
  </svg>
);
export default IconChevronUpCircle;
