import React from "react";
import BringzzImage from "../BringzzImage";
import BringzzText from "../BringzzText";
import BringzzBarcode from "../BringzzBarcode";
import PropTypes from "prop-types"; // Import PropTypes

export default function BringzzProductScan({
    productId,
    image,
    manufacturer,
    label,
    productType,
    volume,
    productQuantity,
    quantity,
    ...props }) {
    return (
        <div className="w-80">
            <div className="flex px-4 gap-2">
                <div className="">
                    <BringzzImage
                        src={image}
                        loading="auto"
                        className="h-16 w-20 rounded-md bg-gray-200"
                        alt="product img"
                    />
                </div>
                <div className="flex-grow">
                    <BringzzText className={"text-xs font-normal"}>{manufacturer}</BringzzText>
                    <div className="flex justify-between">
                        <BringzzText tag="h3" className={"font-normal"}>{label}</BringzzText>
                        <BringzzText tag="h3" className={"font-normal"}>Menge: {quantity}</BringzzText>
                    </div>
                    <BringzzText className={"text-xs font-normal"}>{productQuantity} | {volume} | {productType}</BringzzText>
                </div>
            </div>
            <BringzzBarcode value={productId} />
        </div>
    )
}

BringzzProductScan.propTypes = {
    //value: PropTypes.string.isRequired
};