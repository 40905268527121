import * as React from "react";
const IconLaptopTarget = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 55.8h-7c.3-.5.4-1.1.4-1.7V31.8c0-2.7-2.2-5-5-5H48c-.6 0-1 .4-1 1s.4 1 1 1h1.5c1.6 0 3 1.3 3 3v22.4c0 .9-.7 1.7-1.7 1.7H11.6c-.9 0-1.7-.7-1.7-1.7V31.8c0-1.6 1.3-3 3-3h.8c.6 0 1-.4 1-1s-.4-1-1-1h-.8c-2.7 0-5 2.2-5 5v22.4c0 .6.2 1.2.4 1.7H3c-.6 0-1 .4-1 1s.4 1 1 1h58c.6 0 1-.4 1-1s-.4-1.1-1-1.1" />
    <path d="M31.1 37.3c6.3 0 11.4-5.1 11.4-11.4 0-.6-.4-1-1-1s-1 .4-1 1c0 5.2-4.2 9.4-9.4 9.4s-9.4-4.2-9.4-9.4 4.2-9.4 9.4-9.4c.9 0 1.8.1 2.7.4.5.2 1.1-.1 1.2-.7.2-.5-.1-1.1-.7-1.2-1.1-.3-2.1-.5-3.3-.5-6.3 0-11.4 5.1-11.4 11.4.1 6.3 5.2 11.4 11.5 11.4" />
    <path d="M31.5 24.3c-.4.4-.5 1-.1 1.4.2.2.5.3.7.3s.5-.1.7-.3l13-11.5h6.4c.6 0 1-.4 1-1s-.4-1-1-1h-5.6v-5c0-.6-.4-1-1-1s-1 .4-1 1v5.5zM27.9 26c0-.6-.4-1-1-1s-1 .4-1 1c0 2.8 2.3 5.2 5.2 5.2.6 0 1-.4 1-1s-.4-1-1-1c-1.7-.1-3.2-1.5-3.2-3.2" />
  </svg>
);
export default IconLaptopTarget;
