import * as React from "react";
const IconZoomInCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 52.6 46.5 38.1c-1.2-1.2-3.4-1.2-4.6 0l-1.2 1.2-4.7-4.7c6.9-7.6 6.7-19.5-.7-26.8C31.6 4 26.7 2 21.5 2S11.4 4 7.7 7.7c-7.6 7.6-7.6 20 0 27.6C11.4 39 16.3 41 21.5 41c4.9 0 9.4-1.8 13-5l4.7 4.7-1.2 1.2c-1.3 1.3-1.3 3.3 0 4.6L52.6 61c.6.6 1.4 1 2.3 1s1.7-.3 2.3-1l3.8-3.8c1.3-1.2 1.3-3.3 0-4.6M21.5 39c-4.7 0-9.1-1.8-12.4-5.1-6.8-6.8-6.8-17.9.1-24.8C12.5 5.8 16.9 4 21.5 4c4.7 0 9.1 1.8 12.4 5.1 6.8 6.8 6.8 17.9 0 24.8-3.3 3.3-7.7 5.1-12.4 5.1m38.1 16.8-3.8 3.8c-.5.5-1.3.5-1.8 0L39.5 45.1c-.5-.5-.5-1.3 0-1.8l3.8-3.8c.2-.2.6-.4.9-.4s.7.1.9.4L59.6 54c.5.5.5 1.3 0 1.8" />
    <path d="M26.8 20.5h-4.3v-4.3c0-.6-.4-1-1-1s-1 .4-1 1v4.3h-4.3c-.6 0-1 .4-1 1s.4 1 1 1h4.3v4.3c0 .6.4 1 1 1s1-.4 1-1v-4.3h4.3c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconZoomInCopy;
