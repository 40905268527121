import * as React from "react";
const IconTrainAltCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.5 17.2C52.8 10.8 47.4 6 40.9 6h-2.1V4c0-1.1-.9-2-2-2h-9.6c-1.1 0-2 .9-2 2v2.1h-2.1c-6.4 0-11.8 4.8-12.6 11.2-.1.5-.1 1-.1 1.5v30.8c0 1.2 1 2.3 2.3 2.3h6.1l-5.7 8.7c-.3.5-.2 1.1.3 1.4.2.1.4.2.6.2.3 0 .6-.2.8-.4l6.5-9.8H43l6.1 9.8c.2.3.5.5.8.5.2 0 .4 0 .5-.2.5-.3.6-.9.3-1.4L45.2 52h5.9c1.2 0 2.3-1 2.3-2.3V18.6c.2-.4.1-.9.1-1.4M27.2 4h9.6v6.1h-9.6zm-4.1 4.1h2.1v2.1c0 1.1.9 2 2 2h9.6c1.1 0 2-.9 2-2V8.1h2.1c5.1 0 9.4 3.5 10.5 8.3H12.7c1.1-4.9 5.3-8.3 10.4-8.3m28.5 10.5v10.6H33V18.3h18.6zm-39.2-.3H31v10.9H12.4V18.3m38.9 31.5H12.7c-.1 0-.3-.1-.3-.3V31.2h39.2v18.3q0 .3-.3.3" />
    <path d="M26.3 38.9h-5.6c-.6 0-1 .4-1 1s.4 1 1 1h5.6c.6 0 1-.4 1-1s-.4-1-1-1M43.7 38.9h-5.6c-.6 0-1 .4-1 1s.4 1 1 1h5.6c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconTrainAltCopy;
