import * as React from "react";
const IconBot1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M16.1 36.4h-4.6c-.6 0-1 .4-1 1s.4 1 1 1h4.6c.6 0 1-.4 1-1s-.4-1-1-1M41.5 36.4h-4.6c-.6 0-1 .4-1 1s.4 1 1 1h4.6c.6 0 1-.4 1-1s-.4-1-1-1" />
    <path d="M57.2 33.6h-1v-16c2.5-.5 4.4-2.6 4.4-5.3 0-3-2.4-5.4-5.4-5.4s-5.4 2.4-5.4 5.4c0 2.6 1.9 4.8 4.4 5.3v16h-3.9v-2.3c0-3.3-2.7-6-6-6h-7.4v-3.6c0-2.7-2.2-4.8-4.8-4.8h-4.6V8.7c0-.6-.4-1-1-1s-1 .4-1 1v8.2h-4.6c-2.7 0-4.8 2.2-4.8 4.8v3.6H8c-3.3 0-6 2.7-6 6V51c0 3.3 2.7 6 6 6h36.4c3.3 0 6-2.7 6-6v-2.3h6.9c2.7 0 4.8-2.2 4.8-4.8v-5.5c-.1-2.6-2.3-4.8-4.9-4.8m-5.4-21.3c0-1.9 1.5-3.4 3.4-3.4s3.4 1.5 3.4 3.4-1.5 3.4-3.4 3.4-3.4-1.5-3.4-3.4m-33.7 9.4c0-1.6 1.3-2.8 2.8-2.8h11.2c1.6 0 2.8 1.3 2.8 2.8v3.6H18.1zM48.4 51c0 2.2-1.8 4-4 4H8c-2.2 0-4-1.8-4-4V31.3c0-2.2 1.8-4 4-4h36.4c2.2 0 4 1.8 4 4zM60 43.9c0 1.6-1.3 2.8-2.8 2.8h-6.9V35.6h6.9c1.6 0 2.8 1.3 2.8 2.8z" />
    <path d="M30.3 43.6c-.6 0-1 .4-1 1 0 1.5-1.3 2.8-2.8 2.8s-2.8-1.3-2.8-2.8c0-.6-.4-1-1-1s-1 .4-1 1c0 2.6 2.2 4.8 4.8 4.8s4.8-2.2 4.8-4.8c0-.5-.4-1-1-1" />
  </svg>
);
export default IconBot1;
