import * as React from "react";
const IconFlowChartAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62 31.1c0-3.8-2.8-6.9-6.5-7.4v-12c0-2.7-2.2-4.9-4.9-4.9h-6.9V5.5c0-2.2-1.8-4-4-4H23.9c-2.2 0-4 1.8-4 4V10c0 2.2 1.8 4 4 4h6.9v8.8c-.7.2-1.3.5-1.8 1l-4.3 4.3c-1.6 1.6-1.6 4.1 0 5.7l4.3 4.3c.5.5 1.1.9 1.8 1v10.7h-6.9c-2.2 0-4 1.8-4 4v1.3h-6.5c-1.6 0-2.9-1.3-2.9-2.9V38.4c3.6-.5 6.4-3.6 6.4-7.4 0-4.1-3.3-7.4-7.4-7.4S2 26.9 2 31.1c0 3.8 2.8 6.9 6.4 7.4v13.8c0 2.7 2.2 4.9 4.9 4.9h6.5v1.3c0 2.2 1.8 4 4 4h15.8c2.2 0 4-1.8 4-4v-1.3h6.9c2.7 0 4.9-2.2 4.9-4.9V38.4c3.8-.5 6.6-3.6 6.6-7.3m-58 0c0-3 2.4-5.4 5.4-5.4s5.4 2.4 5.4 5.4-2.4 5.4-5.4 5.4S4 34.1 4 31.1m17.9-21V5.5c0-1.1.9-2 2-2h15.8c1.1 0 2 .9 2 2V10c0 1.1-.9 2-2 2H23.9c-1.1.1-2-.8-2-1.9m4.2 22.4c-.8-.8-.8-2 0-2.8l4.3-4.3c.4-.4.9-.6 1.4-.6s1 .2 1.4.6l4.3 4.3c.8.8.8 2 0 2.8l-4.3 4.3c-.8.8-2.1.8-2.8 0zm15.6 26c0 1.1-.9 2-2 2H23.9c-1.1 0-2-.9-2-2V54c0-1.1.9-2 2-2h15.8c1.1 0 2 .9 2 2zm8.9-3.3h-6.9v-1.3c0-2.2-1.8-4-4-4h-6.9V39.2c.7-.2 1.3-.5 1.8-1l4.3-4.3c1.6-1.6 1.6-4.1 0-5.7l-4.3-4.3c-.5-.5-1.2-.9-1.8-1v-8.8h6.9c2.2 0 4-1.8 4-4V8.8h6.9c1.6 0 2.9 1.3 2.9 2.9v12c-3.6.5-6.4 3.6-6.4 7.4s2.8 6.9 6.4 7.4v13.8c.1 1.6-1.3 2.9-2.9 2.9m4-18.7c-3 0-5.4-2.4-5.4-5.4s2.4-5.4 5.4-5.4 5.4 2.4 5.4 5.4-2.4 5.4-5.4 5.4" />
  </svg>
);
export default IconFlowChartAlt;
