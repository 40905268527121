import * as React from "react";
const IconCheckmarkCircleCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
    <path d="M41.9 23.4 28.6 36.2 22 29.9c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l6.6 6.4c.4.4.8.5 1.3.5s1-.2 1.3-.5l13.3-13c.4-.4.4-1 0-1.4-.2-.3-.8-.3-1.2.1" />
  </svg>
);
export default IconCheckmarkCircleCopy;
