import * as React from "react";
const IconCrownAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M49.1 52.3H14.9c-3 0-5.5-1.9-6.4-4.7L2.1 26.7c-.3-.9 0-1.9.7-2.6.7-.6 1.8-.8 2.6-.3l9.1 4.4c.8.4 1.7.2 2.3-.5l13.3-15.2c.5-.5 1.1-.8 1.8-.8s1.4.3 1.8.8L47 27.7c.6.7 1.5.9 2.3.5l9.1-4.4c.9-.4 1.9-.3 2.6.3s1 1.6.7 2.6l-6.4 20.9c-.7 2.8-3.2 4.7-6.2 4.7M4.4 25.6c-.1 0-.2.1-.3.1 0 0-.1.2-.1.4L10.4 47c.6 2 2.4 3.3 4.5 3.3h34.2c2.1 0 3.9-1.3 4.5-3.3L60 26.1c.1-.2-.1-.4-.1-.4-.1-.1-.2-.2-.5-.1L50.3 30c-1.6.8-3.5.4-4.7-1L32.3 13.8c-.1-.1-.2-.1-.3-.1s-.2 0-.3.1L18.4 29c-1.2 1.3-3.1 1.7-4.7 1l-9.1-4.4z" />
  </svg>
);
export default IconCrownAlt;
