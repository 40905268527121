import * as React from "react";
const IconBluetoothOriginal = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m36.4 32.4 11.3-12.5L30 1v24.9L19.5 15.5l-3.8 3.9 13.1 13.1-13.1 13.2 3.8 3.8L30 39l.3 24 18-18zm5-12.4-6.1 6.1V14zm-6.2 18.9 6.1 6.1-6.1 6z" />
  </svg>
);
export default IconBluetoothOriginal;
