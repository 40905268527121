import * as React from "react";
const IconFactoryAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.7 2.9c-.8-.4-1.8-.3-2.5.2L33 22.2V5.1c0-.9-.5-1.7-1.3-2.1s-1.8-.3-2.5.2L4 22.2V3.3c0-.6-.4-1-1-1s-1 .4-1 1v53.2c0 2.8 2.3 5.1 5.1 5.1h49.8c2.8 0 5.1-2.3 5.1-5.1V5.1c0-.9-.5-1.8-1.3-2.2M14.1 59.7v-6.5c0-1.8 1.5-3.4 3.4-3.4s3.4 1.5 3.4 3.4v6.5zm16.9 0h-8.1v-6.5c0-3-2.4-5.4-5.4-5.4s-5.4 2.4-5.4 5.4v6.5h-5c-1.7 0-3.1-1.4-3.1-3.1V24.7l26.4-20c.2-.1.3-.1.4 0 .1 0 .2.1.2.3zm12.8 0v-6.5c0-1.8 1.5-3.4 3.4-3.4s3.4 1.5 3.4 3.4v6.5zM60 56.5c0 1.7-1.4 3.1-3.1 3.1h-4.4v-6.5c0-3-2.4-5.4-5.4-5.4s-5.4 2.4-5.4 5.4v6.5H33V24.7l26.4-20c.2-.1.3-.1.4 0 .1 0 .2.1.2.3z" />
  </svg>
);
export default IconFactoryAlt2;
