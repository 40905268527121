import * as React from "react";
const IconPalliativeCare = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M23.427 20.815c.996-1.346 2.473-2.315 4.256-2.315 1.837 0 3.249.958 4.25 2.303 1.002-1.345 2.414-2.303 4.25-2.303 1.056 0 1.99.288 2.782.8a5.6 5.6 0 0 1 1.535 1.503 1 1 0 1 1-1.638 1.148c-.724-1.033-1.644-1.451-2.679-1.451-1.379 0-2.54.947-3.342 2.684l-.908 1.965-.907-1.965c-.803-1.737-1.963-2.684-3.343-2.684-1 0-1.931.536-2.648 1.504-.72.973-1.168 2.324-1.168 3.749 0 .77.116 1.51.322 2.219q.153.52.367 1.02c1.011 2.367 3.233 4.332 5.058 5.752a1 1 0 0 1-1.228 1.578c-.323-.25-.67-.527-1.028-.827-1.68-1.407-3.626-3.342-4.641-5.717a11 11 0 0 1-.448-1.246 9.9 9.9 0 0 1-.402-2.78c0-1.82.567-3.594 1.56-4.937"
      clipRule="evenodd"
    />
    <path
      
      d="m22.194 13.8 1.673 3.2 1.939-3.2c1.48-3.108 3.613-4.8 6.163-4.8 1.853 0 3.567.962 4.882 2.688C38.174 13.425 39 15.838 39 18.379q0 .464-.035.921a5.6 5.6 0 0 1 1.535 1.503c.111.158.17.338.18.518.235-.975.32-1.793.32-2.942C41 12.476 37.189 7 31.969 7c-2.86 0-5.138 1.532-6.787 3.893A14.4 14.4 0 0 0 24 12.94a14.4 14.4 0 0 0-1.182-2.048C21.169 8.532 18.892 7 16.03 7 10.811 7 7 12.475 7 18.38c0 1.586.246 3.104.68 4.547.212.706.469 1.394.763 2.063C12.55 34.315 24 40 24 40s2.456-1.298 5.501-3.601a1 1 0 0 1-1.115-.077c-.323-.25-.67-.527-1.028-.827-.646.464-1.252.875-1.794 1.229-.631.411-1.173.743-1.59.99q-.349-.196-.803-.466a44 44 0 0 1-4.193-2.844c-3.267-2.503-6.85-6.007-8.705-10.22a16 16 0 0 1-.678-1.832A13.8 13.8 0 0 1 9 18.379c0-2.542.826-4.955 2.15-6.691C12.463 9.962 14.177 9 16.03 9c2.55 0 4.683 1.692 6.163 4.8M39.456 25.101a1.133 1.133 0 0 1 1.117 1.15c-.05 3.71-1.845 6.235-3.589 7.8a13 13 0 0 1-2.374 1.685 11 11 0 0 1-1.026.499q-.044.016-.069.028l-.021.008-.008.003-.003.001a1.134 1.134 0 0 1-.802-2.12l.005-.002.036-.015.164-.07a10.638 10.638 0 0 0 2.584-1.702c1.413-1.27 2.797-3.235 2.837-6.147a1.133 1.133 0 0 1 1.149-1.118"
    />
  </svg>
);
export default IconPalliativeCare;
