import * as React from "react";
const IconKp = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 513 342"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 0h513v342H0z" />
    <path  d="M0 0h513v342H0z" />
    <path  d="M0 0h513v57.188H0zm0 284.1h513v57.177H0z" />
    <path  d="M0 79.9h513v181.582H0z" />
    <circle cx={190.33} cy={171} r={65.668}  />
    <path
      
      d="m190.3 105 14.8 45.3h47.7l-38.6 28.1 14.8 45.5-38.7-28.2-38.6 28.1 14.8-45.4-38.6-28.1h47.7z"
    />
  </svg>
);
export default IconKp;
