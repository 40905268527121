import * as React from "react";
const IconShoe = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.6 23.7c-.2-.2-.5-.4-.8-.4s-.6.2-.8.4c-2.5 3.3-5.6 6.5-10.7 4.7-3.5-1.2-6.1-4.8-7.9-7.4-.3-.4-.9-.6-1.3-.3-1.1.6-8.7 4.9-17.3 9.7-4.8 2.7-10 5.6-14.5 8.1-1.1.6-1.6 1.8-1.3 2.9s1.3 2 2.5 2h52.2c2.9 0 5.2-2.3 5.2-5.3-.2-7.8-1.9-10.2-5.3-14.4m.2 17.7H4.6c-.4 0-.6-.3-.6-.5 0-.1-.1-.5.3-.7 3.8-2.1 8.9-5 13.9-7.8l2.8 2.2c.2.1.4.2.6.2.3 0 .6-.1.8-.4.3-.4.3-1.1-.2-1.4l-2.1-1.7c1.8-1 3.5-2 5.1-2.9l3 2.4c.2.1.4.2.6.2.3 0 .6-.1.8-.4.3-.4.3-1.1-.2-1.4L27 27.3c3.7-2.1 6.7-3.8 8.2-4.6 1.9 2.8 4.7 6.1 8.4 7.4 5.7 1.9 9.5-1.1 12.2-4.4 2.7 3.5 3.8 5.6 4.1 12.3.1 2-1.3 3.4-3.1 3.4" />
  </svg>
);
export default IconShoe;
