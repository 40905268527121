import * as React from "react";
const IconBracketsAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M14 49H9.1V37c0-.2-.1-.5-.3-.7l-4.5-4.9 4.5-4.9c.2-.2.3-.4.3-.7V15H14c.6 0 1-.4 1-1s-.4-1-1-1H8.1c-.6 0-1 .4-1 1v11.5l-4.8 5.3c-.3.4-.3 1 0 1.3l4.8 5.3V50c0 .6.4 1 1 1H14c.6 0 1-.4 1-1s-.5-1-1-1M61.7 30.7l-4.8-5.3V14c0-.6-.4-1-1-1H50c-.6 0-1 .4-1 1s.4 1 1 1h4.9v10.8c0 .2.1.5.3.7l4.5 4.9-4.5 4.9c-.2.2-.3.4-.3.7v12H50c-.6 0-1 .4-1 1s.4 1 1 1h5.9c.6 0 1-.4 1-1V37.4l4.8-5.3c.4-.4.4-1 0-1.4" />
  </svg>
);
export default IconBracketsAlt;
