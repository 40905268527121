import * as React from "react";
const IconFemaleSexWorker = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M19 9.75a4 4 0 1 1-8 0 4 4 0 0 1 8 0m-2 0a2 2 0 1 1-4 0 2 2 0 0 1 4 0M39 18a4 4 0 0 1-4 4h-1v2h-2v-2h-1a4 4 0 0 1-4-4h2a2 2 0 0 0 2 2h1v-4h-1a4 4 0 0 1 0-8h1V6h2v2h1a4 4 0 0 1 4 4h-2a2 2 0 0 0-2-2h-1v4h1a4 4 0 0 1 4 4m-2 0a2 2 0 0 1-2 2h-1v-4h1a2 2 0 0 1 2 2m-8-6a2 2 0 0 1 2-2h1v4h-1a2 2 0 0 1-2-2M26 32.17A3 3 0 0 0 24 35v7h2v-3h14v3h2v-7a3 3 0 0 0-2-2.83V26h-2v1H28v-1h-2zM32 29h2v3h-2zm-6 6a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v2H26zm10-3v-3h2v3zm-8-3h2v3h-2zM16.479 15.75c.43 0 1.18.048 1.967.444 1.33.67 2.09 1.767 2.541 2.736.432.927.68 1.954.865 2.808.175.811.18.832.28 1.344.095.49.281 1.45.81 4.077a3 3 0 0 1-3.124 3.586l.54 3.1c.149.86-.284 1.797-1.21 2.127-.908.324-2.553.778-4.648.778-2.096 0-3.74-.454-4.648-.778-.926-.33-1.36-1.268-1.21-2.126l.54-3.1A3 3 0 0 1 6.06 27.16c.527-2.628.713-3.587.809-4.077a46 46 0 0 1 .279-1.345c.185-.854.433-1.88.865-2.808.45-.969 1.21-2.066 2.541-2.736a4.35 4.35 0 0 1 1.967-.444h3.958m0 2H12.52c-.284 0-.678.034-1.068.23-.792.4-1.29 1.07-1.627 1.794-.33.71-.542 1.548-.723 2.387-.173.797-.175.81-.269 1.292l-.002.011c-.096.495-.284 1.457-.812 4.09a1 1 0 0 0 1.489 1.058l1.186-6.525 1.968.358-.627 3.445.024-.027-1.436 8.26c.773.266 2.143.627 3.876.627s3.103-.36 3.876-.627l-1.437-8.264.024.027-.626-3.441 1.968-.358 1.186 6.524a1 1 0 0 0 1.49-1.058c-.53-2.632-.716-3.594-.813-4.089l-.002-.011a44 44 0 0 0-.269-1.292c-.181-.84-.393-1.677-.723-2.387-.337-.724-.834-1.395-1.627-1.794a2.36 2.36 0 0 0-1.069-.23"
      clipRule="evenodd"
    />
    <path
      
      d="M11.285 41.663A1.5 1.5 0 0 1 10 40.18V37.75c1.306.18 2.507.29 3.682.335l-.744 2.518a1.5 1.5 0 0 1-1.653 1.06M16.07 40.634l-.805-2.53A35 35 0 0 0 19 37.842v2.337a1.5 1.5 0 0 1-2.93.455"
    />
  </svg>
);
export default IconFemaleSexWorker;
