import * as React from "react";
const IconConstructionHammerCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.7 2H16.1c-2.5 0-4.7 1.4-5.8 3.6l-7 14.1c-.6 1.2-.5 2.5.2 3.6s1.9 1.8 3.2 1.8h16.5V57c0 2.8 2.2 5 5 5H36c2.8 0 5-2.2 5-5V25.1h13.8c3.6 0 6.5-2.9 6.5-6.5V8.5C61.2 4.9 58.2 2 54.7 2M38.9 57c0 1.7-1.3 3-3 3h-7.8c-1.7 0-3-1.3-3-3V25.1h13.8zm20.3-38.4c0 2.5-2 4.5-4.5 4.5H6.6c-.6 0-1.2-.3-1.5-.8s-.4-1.1-.1-1.7l7-14.1C12.8 5 14.4 4 16.1 4h38.6c2.5 0 4.5 2 4.5 4.5z" />
  </svg>
);
export default IconConstructionHammerCopy;
