import * as React from "react";
const IconBlisterPillsRoundX16 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M32 6H16a2 2 0 0 0-2 2v32a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2M16 4a4 4 0 0 0-4 4v32a4 4 0 0 0 4 4h16a4 4 0 0 0 4-4V8a4 4 0 0 0-4-4z"
      clipRule="evenodd"
    />
    <path
      
      d="M15 24a1 1 0 1 1-2 0 1 1 0 0 1 2 0M19 24a1 1 0 1 1-2 0 1 1 0 0 1 2 0M23 24a1 1 0 1 1-2 0 1 1 0 0 1 2 0M27 24a1 1 0 1 1-2 0 1 1 0 0 1 2 0M31 24a1 1 0 1 1-2 0 1 1 0 0 1 2 0M35 24a1 1 0 1 1-2 0 1 1 0 0 1 2 0M18 10.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0M23 10.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0M28 10.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0M33 10.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0M18 15.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0M23 15.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0M28 15.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0M33 15.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0M18 31.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0M23 31.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0M28 31.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0M33 31.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0M18 36.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0M23 36.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0M28 36.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0M33 36.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"
    />
  </svg>
);
export default IconBlisterPillsRoundX16;
