import React, { useState } from 'react';
import { useNavigation } from '../../context/NavigationContext';
import { BringzzPageContainer, BringzzIcon, BringzzText, BringzzInput, BringzzButton, BringzzOverlay } from "@bringzz/components"

const PharmacyPostArticle1 = () => {
    const { backPress } = useNavigation();
    const [isOverlayOpen, setIsOverlayOpen] = useState(false);

    const openOverlay = () => setIsOverlayOpen(true);
    const closeOverlay = () => setIsOverlayOpen(false);

    return (
        <BringzzPageContainer
            header={{
                left: <BringzzIcon
                    size="22"
                    icon="IconChevronLeft"
                    folder="LineIcons"
                    className="cursor-pointer"
                    onClick={backPress}
                />,
                title: <BringzzText tag='h2'>Produkt Article</BringzzText>,
            }}>

            <div className='p-4'>
                <div className='space-y-4'>
                    <BringzzInput label="Headline (max. XXX char)" placeholder="Your posting headline" tooltip="something" />
                    <BringzzInput label="Description (max. XXX char)" type="textarea" placeholder="Your posting description" tooltip="something" />
                </div>
                <div className='flex p-3 space-x-3'>
                    <BringzzButton className="border border-black" icon={{ variant: "IconPlus", folder: "LineIcons", size: "16" }}>Add Main Image</BringzzButton>
                </div>
                {/* TODO: BringzzInput add checkbox? */}
                <div className='space-y-2'>
                    <BringzzInput label="Main Image Caption (max. XXX char)" type="textarea" placeholder="Your Image Caption" tooltip="something" />
                    <BringzzInput label="Tags" placeholder="Tag1, Tag 2, Tab 3" tooltip="something" />
                </div>

                <div className='flex justify-center mt-52 space-x-3'>
                    <BringzzButton size='lg' className="bg-magic-lilac w-32 justify-center" onClick={openOverlay}>Next</BringzzButton>
                </div>

                <BringzzOverlay isOpen={isOverlayOpen} onClose={closeOverlay}>

                    <div className=''>
                        <div className='flex flex-col items-center space-y-2 px-12 py-4'>
                            <BringzzText tag="h2">Änderung verwerfen?</BringzzText>
                            <BringzzText tag="h4" className="font-normal text-center">Wenn du “Verwerfen” auswählst, gehen deine Änderungen verloren”</BringzzText>
                        </div>

                        <div className='divide-y'>
                            <div></div>
                            <BringzzText tag="h4" className="font-bold py-2 text-center text-magic-lilac cursor-pointer" onClick={closeOverlay}>Verwerfen</BringzzText>
                            <BringzzText tag="h4" className="font-bold py-2 text-center cursor-pointer" onClick={closeOverlay}>Entwurf speichern</BringzzText>
                            <BringzzText tag="h4" className="font-bold py-2 text-center cursor-pointer" onClick={closeOverlay}>Bearbeitung fortsetzen</BringzzText>
                        </div>
                    </div>
                </BringzzOverlay>
            </div>
        </BringzzPageContainer>

    )
}

export default PharmacyPostArticle1;