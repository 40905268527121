import * as React from "react";
const IconFruits = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M18.88 7.566a1 1 0 0 1 1 1v6.6a1 1 0 1 1-2 0v-6.6a1 1 0 0 1 1-1"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M11.78 13.905c1.13-.27 2.283-.065 3.48.553.975.505 1.667.736 2.206.847.538.112.966.114 1.483.114v2h-.02c-.516 0-1.12 0-1.868-.155-.757-.157-1.622-.462-2.72-1.03-.878-.453-1.54-.517-2.096-.384-.584.14-1.201.53-1.912 1.264-1.632 1.688-2.139 3.426-2.316 4.762-.1 1.644.197 4.89 1.668 8.063.5 1.08 1.21 2.57 2.076 3.737.432.582.866 1.03 1.283 1.306.405.267.741.34 1.046.288 3.123-.538 3.71-.551 4.319-.551h1.037v2H18.38c-.422 0-.92 0-3.95.522-.94.162-1.787-.127-2.488-.59-.689-.455-1.284-1.106-1.787-1.783-1.005-1.353-1.791-3.024-2.284-4.088-1.638-3.532-1.972-7.137-1.848-9.064l.003-.032.004-.032c.212-1.644.844-3.839 2.866-5.928.845-.874 1.783-1.556 2.885-1.82"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M14.64 11.41c1.496 1.431 2.307 3.166 2.307 4.51a1 1 0 1 0 2 0c0-2.05-1.168-4.275-2.925-5.956C14.244 8.265 11.743 7 8.896 7a1 1 0 0 0 0 2c2.244 0 4.268.999 5.743 2.41"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M8.574 7.009a1 1 0 0 1 1.116.868c.492 3.93 3.945 6 6.734 7.115a1 1 0 0 1-.743 1.857c-2.869-1.147-7.335-3.604-7.975-8.724a1 1 0 0 1 .868-1.116M25.762 13.903c-1.152-.264-2.334-.066-3.57.548-1.02.506-1.747.74-2.317.853s-1.022.115-1.56.115a1 1 0 0 0 0 2h.019c.537 0 1.16 0 1.93-.153.781-.155 1.676-.458 2.816-1.024.924-.458 1.632-.528 2.236-.39.626.144 1.277.542 2.017 1.277 1.716 1.703 2.235 3.452 2.414 4.784a1 1 0 0 0 1.982-.266c-.222-1.653-.884-3.85-2.987-5.938-.881-.874-1.85-1.548-2.98-1.806m.945 20.377a1 1 0 0 0-1.414.027c-.757.786-1.393 1.05-1.931.962-3.252-.538-3.86-.55-4.485-.55a1 1 0 0 0 0 2h.028c.447 0 .967 0 4.13.523 1.522.252 2.785-.599 3.699-1.548a1 1 0 0 0-.027-1.415"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M32.65 16.103c-1.003 1.81-1.263 3.709-.864 4.992a1 1 0 1 1-1.91.594c-.609-1.959-.153-4.43 1.025-6.556 1.193-2.152 3.206-4.101 5.925-4.947a1 1 0 1 1 .594 1.91c-2.143.666-3.78 2.222-4.77 4.007"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M34.719 17.379c-1.168 1.71-2.748 2.793-4.073 3.013a1 1 0 1 0 .326 1.973c2.023-.335 4.027-1.851 5.398-3.858 1.388-2.032 2.227-4.706 1.762-7.515a1 1 0 1 0-1.974.326c.367 2.214-.288 4.375-1.44 6.06"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M31.78 23a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5m-4.5 2.5a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M37.845 18.09a4.5 4.5 0 0 1 2.716 5.755 1 1 0 1 1-1.883-.675 2.5 2.5 0 1 0-4.706-1.69 1 1 0 0 1-1.882-.675 4.5 4.5 0 0 1 5.755-2.715"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M36.253 23.176a4.501 4.501 0 0 1 3.822 8.014 1 1 0 1 1-1.144-1.64 2.5 2.5 0 1 0-3.008-3.99 1 1 0 1 1-1.262-1.552 4.5 4.5 0 0 1 1.592-.832M27.78 29a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5m-4.5 2.5a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M35.78 29a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5m-4.5 2.5a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M31.78 35a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5m-4.5 2.5a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M37.834 33.966a1 1 0 0 1 1.278-.606 4.5 4.5 0 1 1-4.675 7.44 1 1 0 1 1 1.405-1.423 2.5 2.5 0 1 0 2.598-4.133 1 1 0 0 1-.606-1.278"
      clipRule="evenodd"
    />
  </svg>
);
export default IconFruits;
