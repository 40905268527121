import * as React from "react";
const IconArrowLeftCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56 31.1H10.3l19-19.4c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-20.7 21c-.4.4-.4 1 0 1.4l20.7 21c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4L10.4 33.1H56c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconArrowLeftCopy;
