import * as React from "react";
const IconStatsDownCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60 59H5V4c0-.6-.4-1-1-1s-1 .4-1 1v56c0 .6.4 1 1 1h56c.6 0 1-.4 1-1s-.4-1-1-1" />
    <path d="M60 29.9c-.6 0-1 .4-1 1V41l-8.7-7.3c-.2-.1-.4-.2-.6-.2H36.2l-7.5-9.8c-.2-.2-.5-.4-.8-.4H15.3c-.6 0-1 .4-1 1s.4 1 1 1h12.1l7.5 9.8c.2.2.5.4.8.4h13.6l8.5 7.1H47.3c-.6 0-1 .4-1 1s.4 1 1 1H60c.6 0 1-.4 1-1V30.9c0-.6-.4-1-1-1" />
  </svg>
);
export default IconStatsDownCopy;
