import * as React from "react";
const IconTieAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M26.9 13.1H37c.5 0 .9-.3 1-.8l2-9.1c.1-.3 0-.6-.2-.8S39.3 2 39 2H25c-.3 0-.6.1-.8.4s-.2.5-.2.8l1.9 9.1c.2.5.6.8 1 .8M37.7 4l-1.5 7.1h-8.5L26.3 4zM38.1 19.8c0-.5-.5-.9-1-.9H26.9c-.5 0-1 .4-1 .9L24 52.6c0 .3.1.5.2.7l7 8.3c.2.2.5.4.8.4s.6-.1.8-.4l7-8.3c.2-.2.2-.4.2-.7zM32 59.4l-6-7.1 1.8-31.5H36l2 31.6z" />
  </svg>
);
export default IconTieAlt;
