import * as React from "react";
const IconApartmentCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 2H32.4c-2.2 0-4 1.8-4 4v6.5H6c-2.2 0-4 1.8-4 4V58c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V6c0-2.2-1.8-4-4-4M28.4 60H6c-1.1 0-2-.9-2-2V16.5c0-1.1.9-2 2-2h22.4zM60 58c0 1.1-.9 2-2 2H30.4V6c0-1.1.9-2 2-2H58c1.1 0 2 .9 2 2z" />
    <path d="M13.4 27.3h5.8c.6 0 1-.4 1-1s-.4-1-1-1h-5.8c-.6 0-1 .4-1 1s.4 1 1 1M13.4 38.3h5.8c.6 0 1-.4 1-1s-.4-1-1-1h-5.8c-.6 0-1 .4-1 1s.4 1 1 1M13.3 49.3H19c.6 0 1-.4 1-1s-.4-1-1-1h-5.8c-.6 0-1 .4-1 1s.5 1 1.1 1M48.1 15.4h-5.8c-.6 0-1 .4-1 1s.4 1 1 1h5.8c.6 0 1-.4 1-1s-.4-1-1-1M48.1 30.3h-5.8c-.6 0-1 .4-1 1s.4 1 1 1h5.8c.6 0 1-.4 1-1s-.4-1-1-1M48 45.3h-5.8c-.6 0-1 .4-1 1s.4 1 1 1H48c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconApartmentCopy;
