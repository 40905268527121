import * as React from "react";
const IconDollarConnect = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M17.6 42.8h4.8c.6 0 1-.4 1-1s-.4-1-1-1h-1.9v-1.2c0-.6-.4-1-1-1s-1 .4-1 1v1.2h-.8c-2 0-3.6 1.6-3.6 3.6s1.6 3.6 3.6 3.6h2.2c.9 0 1.6.7 1.6 1.6s-.8 1.4-1.7 1.4H15c-.6 0-1 .4-1 1s.4 1 1 1h3.4v1.2c0 .6.4 1 1 1s1-.4 1-1v-1.3c1.7-.3 2.9-1.7 2.9-3.5 0-2-1.6-3.6-3.6-3.6h-2.2c-.9 0-1.6-.7-1.6-1.6s.8-1.4 1.7-1.4" />
    <path d="M49.8 16.5c-3.3 0-6.2 1.5-8.1 3.8l-11-7.8c.2-.7.3-1.5.3-2.3 0-4.6-3.7-8.3-8.3-8.3s-8.3 3.7-8.3 8.3 3.7 8.3 8.3 8.3c3 0 5.7-1.7 7.1-4.1l10.6 7.5c-.9 1.5-1.4 3.3-1.4 5.2 0 1.8.4 3.4 1.2 4.9l-9.3 6.1c-2.7-3.9-7.3-6.5-12.4-6.5-8.3 0-15.1 6.8-15.1 15.1S10.3 62 18.7 62c8.3 0 15.1-6.8 15.1-15.1 0-2.5-.6-4.9-1.7-7l9.4-6.2c1.9 2.5 4.9 4 8.3 4 5.9 0 10.6-4.8 10.6-10.6s-4.7-10.6-10.6-10.6m-27 0c-3.5 0-6.3-2.8-6.3-6.3s2.8-6.3 6.3-6.3 6.3 2.8 6.3 6.3-2.8 6.3-6.3 6.3M18.7 60c-7.2 0-13.1-5.9-13.1-13.1s5.9-13.1 13.1-13.1 13.1 5.9 13.1 13.1S25.9 60 18.7 60m31.1-24.2c-4.8 0-8.6-3.9-8.6-8.6 0-4.8 3.9-8.6 8.6-8.6s8.6 3.9 8.6 8.6-3.8 8.6-8.6 8.6" />
  </svg>
);
export default IconDollarConnect;
