import * as React from "react";
const IconUserReload = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.8 55.7-3-4.6c-.5-.8-1.4-1.2-2.3-1.1l-6.1.6c-.5.1-1 .5-.9 1.1.1.5.5 1 1.1.9l4.9-.5c-1.7 3.9-5.6 6.4-9.9 6.4-4.7 0-8.8-2.9-10.3-7.3-.2-.5-.7-.8-1.3-.6-.5.2-.8.7-.6 1.3.5 1.6 1.4 3 2.4 4.2l-2.1.8c-3.2.7-6.3 1.1-9.3 1.1-8.8 0-17.3-3.6-20.4-5.1.2-6.7 2.4-12.8 6.1-17.2 3.8-4.5 8.9-6.9 14.3-6.9s10.6 2.5 14.3 6.9c.2.2.4.5.5.7 0 .1.1.1.2.2-2.4 1.6-4.2 4-5.1 6.9l-2.7-4.3c-.3-.5-.9-.6-1.4-.3s-.6.9-.3 1.4l2.9 4.7c.4.7 1.2 1.2 2.1 1.2h.2l6.1-.4c.6 0 1-.5.9-1.1 0-.5-.5-1-1.1-.9l-4.7.2c1.4-4.4 5.5-7.5 10.3-7.5 5 0 9.3 3.3 10.5 8 .1.5.7.9 1.2.7.5-.1.9-.7.7-1.2-1.5-5.6-6.6-9.5-12.5-9.5-2 0-3.8.4-5.5 1.2 0-.1-.1-.3-.2-.4-.2-.3-.4-.6-.7-.9-4-4.8-9.4-7.4-15.2-7.6 6.2-.3 11.1-5.4 11.1-11.7 0-6.5-5.3-11.7-11.7-11.7S12.7 8.7 12.7 15.2c0 6.2 4.9 11.4 11.1 11.7-5.8.1-11.2 2.8-15.2 7.6-4.1 4.9-6.4 11.6-6.6 19q0 .6.6.9C5.2 55.7 14.5 60 24.4 60c3.2 0 6.5-.4 9.8-1.1h.1l3.1-1.1c2.2 1.8 5.1 2.8 8.1 2.8 5.3 0 9.9-3.1 11.9-7.9l2.7 4.1c.2.3.5.5.8.5.2 0 .4-.1.5-.2.6-.3.7-.9.4-1.4M14.7 15.2c0-5.4 4.4-9.7 9.7-9.7s9.7 4.4 9.7 9.7-4.4 9.7-9.7 9.7-9.7-4.4-9.7-9.7" />
  </svg>
);
export default IconUserReload;
