import * as React from "react";
const IconHouseTree = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M29.9 32.2c-.6 0-1 .4-1 1v14c0 1-.8 1.8-1.8 1.8H9.8c-1 0-1.8-.8-1.8-1.8v-14c0-.6-.4-1-1-1s-1 .4-1 1v14C6 49.3 7.7 51 9.8 51h17.3c2.1 0 3.8-1.7 3.8-3.8v-14c0-.6-.4-1-1-1M34.4 26l-14-8.3c-1.2-.7-2.7-.7-3.9 0L2.5 26c-.5.3-.6.9-.4 1.4.2.3.5.5.9.5.2 0 .3 0 .5-.1l14-8.3c.6-.3 1.3-.3 1.8 0l14 8.3c.5.3 1.1.1 1.4-.4s.2-1.1-.3-1.4M62 25.6c0-6.9-3.9-12.6-8.7-12.6s-8.7 5.6-8.7 12.6c0 6.4 3.4 11.8 7.7 12.5v12c0 .6.4 1 1 1s1-.4 1-1v-12c4.3-.8 7.7-6.1 7.7-12.5m-8.7 10.5c-3.7 0-6.7-4.7-6.7-10.6s3-10.6 6.7-10.6C57 15 60 19.7 60 25.6s-3 10.5-6.7 10.5" />
  </svg>
);
export default IconHouseTree;
