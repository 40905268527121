import * as React from "react";
const IconCompress = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M20 21.6c-.4-.4-1-.5-1.4-.1s-.5 1-.1 1.4l6.8 8.1H4c-.6 0-1 .4-1 1s.4 1 1 1h21.2l-6.8 8.1c-.4.4-.3 1.1.1 1.4.2.2.4.2.6.2.3 0 .6-.1.8-.4l8.2-9.7c.3-.4.3-.9 0-1.3zM60 31H38.8l6.8-8.1c.4-.4.3-1.1-.1-1.4-.4-.4-1.1-.3-1.4.1l-8.2 9.7c-.3.4-.3.9 0 1.3l8.2 9.7c.2.2.5.4.8.4.2 0 .5-.1.6-.2.4-.4.5-1 .1-1.4L38.8 33H60c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconCompress;
