import * as React from "react";
const IconDesert1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62 54.9c-.1-.5-.7-.9-1.2-.7-2.7.6-7 .7-11.8.3v-11h3.2c2.8 0 5-2.2 5-5v-4.9c0-.6-.4-1-1-1s-1 .4-1 1v4.9c0 1.7-1.3 3-3 3H49V21.1c0-.6-.4-1-1-1s-1 .4-1 1v15h-6.1c-1.7 0-3-1.3-3-3v-4.9c0-.6-.4-1-1-1s-1 .4-1 1v4.9c0 2.8 2.2 5 5 5H47v16.2c-3-.3-6.1-.8-9.1-1.4 0-.1 0-.3-.1-.4l-5.6-11.4c-.8-1.7-2.6-2.8-4.5-2.8s-3.6 1.1-4.5 2.8l-1.8 3.7-3.3-6.6c-.8-1.7-2.6-2.8-4.5-2.8s-3.6 1.1-4.5 2.8L3.4 49.7c0 .1-.1.2-.1.3-.3.1-.5.1-.6.1-.5.1-.9.7-.7 1.2.1.5.7.9 1.2.7.1 0 15-3.5 24.2 0 7.3 2.8 18.8 4.6 27 4.6 2.7 0 5-.2 6.7-.6.7-.1 1-.6.9-1.1M5.7 49.6l5.2-10.5c.5-1.1 1.5-1.7 2.7-1.7s2.2.6 2.7 1.7l4.7 9.6c-5.8-.5-11.8.3-15.3.9m17.7-.6-.9-1.9L25 42c.5-1 1.5-1.7 2.7-1.7s2.2.6 2.7 1.7l5.1 10.4c-2.7-.6-5.2-1.3-7.3-2.1-1.5-.6-3.1-1-4.8-1.3M11.5 21c3.8 0 6.8-3.1 6.8-6.8s-3.1-6.8-6.8-6.8-6.8 3.1-6.8 6.8 3 6.8 6.8 6.8m0-11.7c2.7 0 4.8 2.2 4.8 4.8S14.2 19 11.5 19s-4.8-2.2-4.8-4.8 2.1-4.9 4.8-4.9" />
  </svg>
);
export default IconDesert1;
