import * as React from "react";
const IconWeightMachineAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 6.9H3c-.6 0-1 .4-1 1s.4 1 1 1h12.5v7.8c-4.1.4-7.4 3.8-7.8 8l-1.8 23c-.2 2.4.6 4.8 2.3 6.6 1.6 1.8 4 2.8 6.4 2.8h34.8c2.4 0 4.8-1 6.4-2.8s2.5-4.2 2.3-6.6l-1.8-23c-.3-4.2-3.7-7.5-7.8-8V8.9H61c.6 0 1-.4 1-1s-.4-1-1-1m-6.7 17.9 1.8 23c.2 1.9-.5 3.7-1.8 5.1s-3 2.2-4.9 2.2H14.6c-1.9 0-3.7-.8-4.9-2.2s-1.9-3.2-1.8-5.1l1.8-23c.3-3.5 3.2-6.2 6.7-6.2h31.1c3.5 0 6.5 2.8 6.8 6.2m-7.8-8.2h-29V8.9h28.9v7.7z" />
    <path d="M32 27.3c-4.8 0-8.7 3.9-8.7 8.7s3.9 8.7 8.7 8.7 8.7-3.9 8.7-8.7-3.9-8.7-8.7-8.7m0 15.5a6.7 6.7 0 1 1 0-13.4 6.7 6.7 0 0 1 0 13.4" />
  </svg>
);
export default IconWeightMachineAlt;
