import * as React from "react";
const IconRestaurantTable = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 7.2H3c-.6 0-1 .4-1 1C2 15.3 7.8 21 14.8 21H31v15.7h-8.9c-.6 0-1 .4-1 1s.4 1 1 1H31v7.2c0 .6-.3 8.9-8.9 8.9-.6 0-1 .4-1 1s.4 1 1 1c3.2 0 5.8-1 7.7-2.9 1-1 1.7-2.2 2.2-3.4.5 1.1 1.2 2.3 2.2 3.4 1.9 1.9 4.5 2.9 7.7 2.9.6 0 1-.4 1-1s-.4-1-1-1c-8.8 0-8.9-8.5-8.9-8.9v-7.2h8.9c.6 0 1-.4 1-1s-.4-1-1-1H33V21h16.2c7 0 12.8-5.7 12.8-12.8 0-.5-.4-1-1-1M49.2 19H14.8C9.2 19 4.6 14.7 4 9.2h56c-.6 5.5-5.2 9.8-10.8 9.8" />
  </svg>
);
export default IconRestaurantTable;
