import * as React from "react";
const IconForrestAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62 31.4c0-6.9-5.6-12.5-12.5-12.5-2.3 0-4.5.6-6.5 1.8C41.6 13.5 37.5 7 32 7s-9.6 6.5-11 13.7c-1.9-1.2-4.2-1.8-6.5-1.8C7.6 18.9 2 24.5 2 31.4 2 38 7.1 43.3 13.5 43.9V56c0 .6.4 1 1 1s1-.4 1-1V43.8c3.4-.3 6.5-1.9 8.6-4.5 0 0 0-.1.1-.1 1.8 2.5 4.2 4.2 6.8 4.6V56c0 .6.4 1 1 1s1-.4 1-1V43.8c2.6-.3 5-2 6.8-4.5 2.2 2.7 5.3 4.3 8.7 4.6V56c0 .6.4 1 1 1s1-.4 1-1V43.9C56.9 43.3 62 38 62 31.4m-39.4 6.7c-1.8 2.2-4.3 3.5-7.1 3.8v-5.7c0-.6-.4-1-1-1s-1 .4-1 1v5.7c-5.3-.6-9.5-5-9.5-10.5 0-5.8 4.7-10.5 10.5-10.5 2.2 0 4.4.7 6.1 2-.1 1.1-.2 2.2-.2 3.3 0 4.4 1.1 8.4 2.8 11.5-.2.1-.5.2-.6.4M33 41.8v-6.1c0-.6-.4-1-1-1s-1 .4-1 1v6.1c-4.8-.8-8.6-7.5-8.6-15.5C22.4 17.2 27 9 32 9s9.6 8.2 9.6 17.2c0 8.1-3.8 14.8-8.6 15.6m17.5.1v-5.7c0-.6-.4-1-1-1s-1 .4-1 1v5.7c-2.8-.3-5.4-1.6-7.2-3.9-.1-.2-.3-.3-.5-.3 1.7-3.1 2.8-7.1 2.8-11.4 0-1.1-.1-2.2-.2-3.3 1.8-1.3 3.9-2 6.1-2C55.3 21 60 25.7 60 31.5c0 5.4-4.2 9.8-9.5 10.4" />
  </svg>
);
export default IconForrestAlt2;
