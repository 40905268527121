import * as React from "react";
const IconCss3Copy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      
      d="M49.2 15.1c-.7-.8-1.7-1.2-2.8-1.2H15.6c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8h31v.1l-1.4 7.6H18.4c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8h26.3l-2.2 12.6-9.8 3.5-9.8-3.5-1.3-6.3c-.2-.9-1.1-1.6-2.1-1.4-.9.2-1.6 1.1-1.4 2.1l1.3 6.4c.2 1.1 1 2 2 2.3l10.1 3.6c.3.1.7.2 1.1.2s.7-.1 1.1-.2l10.1-3.6c1.1-.4 1.8-1.3 2-2.4l4.3-23.9c.2-1.1-.1-2.1-.9-3.1"
    />
    <path
      
      d="M59.9 2.8C58.5 1.2 56.4.2 54.2.2H9.8c-2.2 0-4.2.9-5.7 2.6a7.6 7.6 0 0 0-1.8 6l5.3 44.3c.4 3.2 2.7 5.8 5.8 6.5l16.9 4c.6.1 1.2.2 1.7.2s1.2-.1 1.7-.2l16.9-4c3.1-.7 5.4-3.3 5.8-6.5l5.3-44.3c.3-2.2-.4-4.4-1.8-6m-1.6 5.5L53 52.6c-.2 1.7-1.5 3.1-3.1 3.5l-16.9 4c-.6.1-1.3.1-1.9 0l-16.9-4c-1.7-.4-2.9-1.8-3.1-3.5L5.7 8.3C5.6 7.2 6 6 6.8 5.1s1.9-1.4 3.1-1.4h44.3c1.2 0 2.3.5 3.1 1.4.7.9 1.1 2.1 1 3.2"
    />
  </svg>
);
export default IconCss3Copy;
