import * as React from "react";
const IconBoxes = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M10.6 26.3h5c.6 0 1-.4 1-1s-.4-1-1-1h-5c-.6 0-1 .4-1 1s.4 1 1 1M15.5 48.1h-5c-.6 0-1 .4-1 1s.4 1 1 1h5c.6 0 1-.4 1-1s-.4-1-1-1" />
    <path d="M57.9 30.9H35.1c-1.3 0-2.4.6-3.1 1.5-.1-.1-.2-.3-.4-.4.8-.8 1.4-1.8 1.4-3.1V11.2c0-2.3-1.9-4.1-4.1-4.1H6.1C3.9 7.1 2 8.9 2 11.2v17.7c0 1.2.5 2.3 1.4 3.1-.9.8-1.4 1.8-1.4 3.1v17.7c0 2.3 1.9 4.1 4.1 4.1h22.7c1.3 0 2.4-.6 3.1-1.5.8.9 1.9 1.5 3.1 1.5h22.7c2.3 0 4.1-1.9 4.1-4.1V35.1c.2-2.3-1.7-4.2-3.9-4.2M4 28.9V11.2C4 10 5 9.1 6.1 9.1h10.4v5.5c0 .6.4 1 1 1s1-.4 1-1V9.1h10.4c1.2 0 2.1 1 2.1 2.1v17.7c0 1-.7 1.8-1.6 2.1-.2 0-.4-.1-.6-.1H6c-.2 0-.4 0-.6.1-.7-.3-1.4-1.1-1.4-2.1m24.9 26H6.1C5 54.9 4 54 4 52.8V35.1c0-1 .7-1.8 1.6-2.1.2 0 .4.1.6.1h10.4v5.4c0 .6.4 1 1 1s1-.4 1-1v-5.4H29c.2 0 .4 0 .6-.1.9.3 1.6 1.1 1.6 2.1v17.7c-.2 1.2-1.2 2.1-2.3 2.1M60 52.8c0 1.2-1 2.1-2.1 2.1H35.1c-1.2 0-2.1-1-2.1-2.1V35.1c0-1.2 1-2.1 2.1-2.1h10.4v5.5c0 .6.4 1 1 1s1-.4 1-1V33h10.4c1.2 0 2.1 1 2.1 2.1z" />
    <path d="M44.5 48.1h-5c-.6 0-1 .4-1 1s.4 1 1 1h5c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconBoxes;
