import * as React from "react";
const IconTie = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m37.5 12.1 2.3-6.9c.2-.7.1-1.6-.4-2.2s-1.2-1-2-1H26.6c-.8 0-1.5.4-2 1s-.6 1.5-.3 2.2l2.3 6.9-4.9 42.2c0 .4.1.7.4.9l8.5 6.3c.4.3.9.5 1.4.5s1-.2 1.5-.5l8.5-6.3c.3-.2.4-.6.4-.9zM26.2 4.2c0-.1.2-.2.4-.2h10.8c.2 0 .3.1.4.2 0 .1.1.2.1.4L35.7 11h-7.5l-2.1-6.4c0-.2.1-.3.1-.4m6.1 55.7c-.2.1-.4.1-.5 0l-8.1-6L28.4 13h7.1l4.8 40.9z" />
  </svg>
);
export default IconTie;
