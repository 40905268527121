import * as React from "react";
const IconTruckBlocked = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61 38.2-6.1-8.9c-.2-.3-.5-.4-.8-.4H43.9v-2.7c0-2.6-2.1-4.7-4.7-4.7h-6.8c-.6 0-1 .4-1 1s.4 1 1 1h6.8c1.5 0 2.7 1.2 2.7 2.7v25.5h-9.6c-.3-2.3-2.2-4.2-4.6-4.2s-4.4 1.8-4.6 4.2h-6.6c-1.5 0-2.7-1.2-2.7-2.7V37.3c0-.6-.4-1-1-1s-1 .4-1 1V49c0 2.6 2.1 4.7 4.7 4.7h6.8c.6 1.9 2.4 3.2 4.4 3.2 2.1 0 3.8-1.3 4.4-3.2h14.4c.6 1.9 2.4 3.2 4.4 3.2s3.8-1.3 4.4-3.2h4.8c.6 0 1-.4 1-1v-14c.1-.1.1-.3-.1-.5m-7.4-7.3 5.2 7.5H43.9v-7.5zm-25.9 24c-1.5 0-2.7-1.2-2.7-2.7s1.2-2.7 2.7-2.7 2.7 1.2 2.7 2.7-1.3 2.7-2.7 2.7m23.3 0c-1.5 0-2.7-1.2-2.7-2.7s1.2-2.7 2.7-2.7 2.7 1.2 2.7 2.7-1.3 2.7-2.7 2.7m4.6-3.2c-.3-2.3-2.2-4.2-4.6-4.2s-4.4 1.8-4.6 4.2H44V40.4h15.3v11.3zM14.3 30.2c6.4 0 11.6-5.2 11.6-11.6 0-3-1.1-5.7-3-7.7v-.1h-.1c-2.1-2.3-5.1-3.7-8.4-3.7C8 7.1 2.8 12.3 2.8 18.6c0 3.1 1.2 5.9 3.2 8v.1c2.2 2.2 5.1 3.5 8.3 3.5m0-2c-2.3 0-4.5-.8-6.1-2.2L22 13c1.2 1.6 1.9 3.5 1.9 5.7 0 5.2-4.3 9.5-9.6 9.5m0-19.1c2.4 0 4.6.9 6.3 2.4l-13.8 13c-1.3-1.6-2.1-3.7-2.1-5.9.1-5.2 4.4-9.5 9.6-9.5" />
  </svg>
);
export default IconTruckBlocked;
