import * as React from "react";
const IconVrGlasses = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.4 41.2h-2.6v-2.3c0-2.9-2.3-5.2-5.2-5.2H39c-2.9 0-5.2 2.3-5.2 5.2v2.3h-2.6c-2.9 0-5.2 2.3-5.2 5.2v10.5c0 2.9 2.3 5.2 5.2 5.2H39c.3 0 .5-.1.7-.3l4-4 4 4c.2.2.4.3.7.3h7.8c2.9 0 5.2-2.3 5.2-5.2V46.4c.2-2.9-2.2-5.2-5-5.2M39 35.7h9.6c1.8 0 3.2 1.4 3.2 3.2v2.3h-16v-2.3c0-1.8 1.5-3.2 3.2-3.2M56.4 60H49l-4.5-4.4c-.4-.4-1-.4-1.4 0L38.6 60h-7.4c-1.8 0-3.2-1.4-3.2-3.2v-5.4h4.7c.6 0 1-.4 1-1s-.4-1-1-1H28v-3.1c0-1.8 1.4-3.2 3.2-3.2h25.200000000000003c1.8 0 3.2 1.4 3.2 3.2v3.1h-4.7c-.6 0-1 .4-1 1s.4 1 1 1h4.7v5.4c0 1.8-1.5 3.2-3.2 3.2M27 21.9V8.8c0-.8-.5-1.5-1.2-1.8L15.6 2.2c-.5-.2-1.1-.2-1.7 0L3.6 6.9c-.7.4-1.2 1.1-1.2 1.9v13.1c0 .7.4 1.4 1 1.7l10.3 5.9c.3.2.7.3 1 .3s.7-.1 1-.3L26 23.6c.6-.4 1-1 1-1.7M14.7 4l8.9 4.1-8.9 4.1-9-4.1zM4.4 21.9V9.8l9.3 4.3v13.2zm11.3 5.3V14L25 9.7v12.1zM48.8 18.1c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l4.7 5c.2.2.5.3.7.3s.5-.1.7-.3l4.7-5c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-3.9 4.2z" />
    <path d="M36.8 10.6h11.8c1.8 0 3.2 1.4 3.2 3.2v6.6c0 .6.4 1 1 1s1-.4 1-1v-6.6c0-2.9-2.3-5.2-5.2-5.2H36.8c-.6 0-1 .4-1 1s.5 1 1 1" />
  </svg>
);
export default IconVrGlasses;
