import * as React from "react";
const IconUserEdit = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.2 32.7c-.5-.3-1.1-.2-1.4.3L46.4 51.2l-5 9.8 6.7-8.7s.1-.1.1-.2l11.4-18c.2-.5.1-1.1-.4-1.4M45 41.4c.5-.2.8-.7.6-1.3-1.1-3.2-2.6-6.1-4.7-8.5-3.7-4.4-8.6-6.9-13.9-7.3 5.7-.6 10-5.3 10-11.1C37 7 32 2 25.8 2S14.6 7 14.6 13.2c0 5.8 4.3 10.5 9.9 11.2-5.3.4-10.2 2.9-13.9 7.3-3.9 4.7-6.2 11.1-6.3 18.2q0 .6.6.9c2.5 1.2 11.4 5.3 20.9 5.3 3.5 0 7.1-.5 10.8-1.4.5-.1.9-.7.7-1.2-.1-.5-.7-.9-1.2-.7-3.5.9-6.9 1.4-10.3 1.4-8.4 0-16.5-3.4-19.5-4.8.2-6.4 2.3-12.2 5.8-16.4 3.6-4.3 8.4-6.6 13.7-6.6s10.1 2.3 13.7 6.6c1.9 2.2 3.3 4.9 4.3 7.8.1.5.7.8 1.2.6M16.6 13.2c0-5.1 4.1-9.2 9.2-9.2S35 8.1 35 13.2s-4.1 9.2-9.2 9.2-9.2-4.1-9.2-9.2" />
  </svg>
);
export default IconUserEdit;
