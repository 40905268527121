import * as React from "react";
const IconBloodAP = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M20.923 10.615a1 1 0 0 0-1.846 0l-5 12a1 1 0 0 0 1.846.77L16.917 21h6.166l.994 2.385a1 1 0 0 0 1.846-.77zM20 13.6l2.25 5.4h-4.5z"
      clipRule="evenodd"
    />
    <path
      
      d="M31 14a1 1 0 1 0-2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 1 0 2 0v-2h2a1 1 0 1 0 0-2h-2z"
    />
    <path
      
      fillRule="evenodd"
      d="M38 34.03c0 2.193-1.79 3.97-4 3.97h-4v2h-5v4h-2v-4h-5v-2h-4c-2.21 0-4-1.777-4-3.97V10.328c0-2.192 1.79-3.97 4-3.97h6l1.132-1.155a4.02 4.02 0 0 1 5.736 0L28 6.358h6c2.21 0 4 1.777 4 3.97zM26.571 7.759a2 2 0 0 0 1.429.6h6c1.12 0 2 .896 2 1.97v17.21a8.4 8.4 0 0 0-.925-.625c-1.825-1.062-4.465-1.614-7.583.226-2.568 1.515-4.983 1.925-7.61 1.98-1.171.025-2.368-.02-3.651-.069l-.53-.02A69 69 0 0 0 12 28.972V10.327c0-1.073.88-1.97 2-1.97h6a2 2 0 0 0 1.429-.6l1.132-1.155a2.02 2.02 0 0 1 2.878 0zM15.627 31.029A67 67 0 0 0 12 30.971v3.06c0 1.073.88 1.969 2 1.969h20c1.12 0 2-.896 2-1.97v-3.7a6.998 6.998 0 0 0-1.931-1.688c-1.294-.753-3.155-1.2-5.56.22-2.958 1.744-5.743 2.197-8.585 2.257-1.234.026-2.494-.021-3.77-.07z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconBloodAP;
