import * as React from "react";
const IconTShirtAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60 9.5 50 3.8c-.8-.5-1.7-.7-2.6-.7H16.6c-.9 0-1.8.2-2.6.7L4 9.5c-1.6.9-2.4 2.8-1.9 4.6L4 20.4c.6 2.1 2.7 3.3 4.8 2.8l3.7-.9c.3-.1.7 0 1 .2s.4.5.4 1l2.4 29.2c.4 4.6 4.3 8.3 9 8.3h13.4c4.7 0 8.6-3.6 9-8.3l2.4-29.3c0-.4.2-.7.4-.9.3-.2.6-.3 1-.2l3.7.9c2.1.5 4.2-.7 4.8-2.8l1.8-6.4c.5-1.7-.2-3.6-1.8-4.5M41.2 5.1c-1.3 3.9-5 6.6-9.2 6.6S24.2 9 22.8 5.1zm18.7 8.4-1.8 6.4c-.3 1-1.4 1.7-2.4 1.4l-3.7-1c-.9-.2-1.9 0-2.7.6s-1.2 1.5-1.2 2.4l-2.4 29.2c-.3 3.6-3.4 6.4-7 6.4H25.3c-3.6 0-6.7-2.8-7-6.4l-2.4-29.1c0-1-.4-1.9-1.2-2.5-.6-.4-1.3-.7-1.9-.7-.3 0-.5 0-.8.1l-3.7.9c-1.1.3-2.1-.3-2.4-1.4l-1.8-6.4c-.3-.9.1-1.8.9-2.3l10-5.7c.5-.3 1.1-.4 1.6-.4h4.1c1.4 5 6 8.6 11.3 8.6S41.9 10 43.3 5h4.1c.6 0 1.1.1 1.6.4l10 5.7c.8.6 1.2 1.5.9 2.4" />
  </svg>
);
export default IconTShirtAlt1;
