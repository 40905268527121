import * as React from "react";
const IconShortcodeCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M30.8 23.4h7.6c.6 0 1-.4 1-1s-.4-1-1-1h-7.6c-2.8 0-5.1 2.3-5.1 5.1v1.6c0 2.8 2.3 5.1 5.1 5.1h3.5c1.7 0 3.1 1.4 3.1 3.1V38c0 1.7-1.4 3.1-3.1 3.1h-7.1c-.6 0-1 .4-1 1s.4 1 1 1h7.1c2.8 0 5.1-2.3 5.1-5.1v-1.6c0-2.8-2.3-5.1-5.1-5.1h-3.5c-1.7 0-3.1-1.4-3.1-3.1v-1.6c0-1.8 1.4-3.2 3.1-3.2M16.5 21.3c-.3-.4-1-.5-1.4-.1L2.8 31c-.2.2-.4.5-.4.8s.1.6.4.8L15 42.5c.2.2.4.2.6.2.3 0 .6-.1.8-.4.3-.4.3-1.1-.1-1.4L5.1 31.8l11.3-9.1c.4-.4.4-1 .1-1.4M61.2 31.1l-12.3-9.9c-.4-.3-1.1-.3-1.4.2-.3.4-.3 1.1.2 1.4L59 31.9 47.7 41c-.4.3-.5 1-.2 1.4.2.2.5.4.8.4.2 0 .4-.1.6-.2l12.3-9.9c.2-.2.4-.5.4-.8s-.2-.6-.4-.8" />
  </svg>
);
export default IconShortcodeCopy;
