import * as React from "react";
const IconCodepen = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m62 20.4-28.5-19q-1.5-.9-3 0l-28.4 19c-.7.4-1.1 1.4-1.1 2.1v18.9c0 .8.4 1.7 1.1 2.3l28.4 18.9q1.5.9 3 0l28.4-18.9c.7-.4 1.1-1.4 1.1-2.3V22.5c.1-.8-.3-1.7-1-2.1M34.7 8.6l20.9 14-9.5 6.2L34.6 21c.1 0 .1-12.4.1-12.4m-5.3 0V21l-11.6 7.8-9.3-6.2zm-23 18.9L13 32l-6.6 4.5zm23 27.9-20.9-14 9.3-6.2L29.4 43zm2.7-17L22.6 32l9.5-6.4 9.5 6.4zm2.6 17V43l11.6-7.8 9.3 6.2zm23-18.9L51.1 32l6.6-4.5z" />
  </svg>
);
export default IconCodepen;
