import * as React from "react";
const IconLaptopThumbsDown = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.3 51.1H49v-8.6c0-.6-.4-1-1-1s-1 .4-1 1v8.6H9.3V30.3c0-1.7 1.4-3 3-3H25c.6 0 1-.4 1-1s-.4-1-1-1H12.3c-2.8 0-5 2.3-5 5v20.8H3c-.6 0-1 .4-1 1s.4 1 1 1h50.3c.6 0 1-.4 1-1s-.5-1-1-1M61 12.5c-.6 0-1 .4-1 1v10.3c0 .6.4 1 1 1s1-.4 1-1V13.5c0-.6-.4-1-1-1M50.4 10.9h-8.8c-.5 0-1.3 0-2 .4-1 .5-1.4 1.5-1.7 2.2-2.1 5-3.2 7.9-3.4 9-.1.7-.1 1.3 0 1.9 0 .2.1.4.1.5.3 1 .9 1.8 1.7 2.2s1.6.4 2.2.4h5l-.7 3.2c-.1.5-.3 1.3-.1 2 .3 1.1 1.4 1.9 2.5 1.9.2 0 .4 0 .6-.1.8-.2 1.4-.8 1.8-1.2l1-1c4.4-4.3 5.2-5.2 5.4-5.7q.3-.9.3-1.8V15c0-.8 0-2.1-.9-3.1-.8-1-2.2-1-3-1m2.1 13.8c0 .4 0 .8-.1 1-.5.7-3.6 3.7-5 5-.5.4-.8.8-1.1 1-.3.3-.6.6-.9.7s-.6-.1-.7-.4 0-.7.1-1l1-4.5c.1-.3 0-.6-.2-.8s-.5-.4-.8-.4h-6.2c-.5 0-1 0-1.4-.2-.3-.2-.6-.5-.7-.9 0-.1 0-.2-.1-.3-.1-.4 0-.8 0-1.3.1-.4.5-2 3.3-8.5.2-.5.5-1 .8-1.2.2-.1.7-.1 1-.1h8.8c.7 0 1.4 0 1.7.4.3.3.3 1 .3 1.7z" />
  </svg>
);
export default IconLaptopThumbsDown;
