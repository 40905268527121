import * as React from "react";
const IconLibraryCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.1 9.9H35.3c-1.3 0-2.5.6-3.3 1.6-.8-.9-2-1.6-3.3-1.6H5.9C3.7 9.9 2 11.6 2 13.8v33.8c0 2.1 1.7 3.9 3.9 3.9H31v1.6c0 .6.4 1 1 1s1-.4 1-1v-1.6h25.1c2.1 0 3.9-1.7 3.9-3.9V13.8c0-2.2-1.7-3.9-3.9-3.9M5.9 49.5c-1 0-1.9-.8-1.9-1.9V13.8c0-1 .8-1.9 1.9-1.9h22.9c1.2 0 2.3 1 2.3 2.3v35.3zM60 47.6c0 1-.8 1.9-1.9 1.9H33V14.2c0-1.3 1-2.3 2.3-2.3h22.8c1 0 1.9.8 1.9 1.9z" />
    <path d="M13.6 22.1h7.7c.6 0 1-.4 1-1s-.4-1-1-1h-7.7c-.6 0-1 .4-1 1s.5 1 1 1M42.4 22.1h7.7c.6 0 1-.4 1-1s-.4-1-1-1h-7.7c-.6 0-1 .4-1 1s.4 1 1 1M50.2 28.6h-7.7c-.6 0-1 .4-1 1s.4 1 1 1h7.7c.6 0 1-.4 1-1s-.4-1-1-1M21.5 28.6h-7.7c-.6 0-1 .4-1 1s.4 1 1 1h7.7c.6 0 1-.4 1-1s-.5-1-1-1M21.5 37.7h-7.7c-.6 0-1 .4-1 1s.4 1 1 1h7.7c.6 0 1-.4 1-1s-.5-1-1-1M50.2 37.7h-7.7c-.6 0-1 .4-1 1s.4 1 1 1h7.7c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconLibraryCopy;
