import * as React from "react";
const IconArrowExpand = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M20.9 17.1c.3 0 .5-.1.7-.3L31 7.1v19.3c0 .6.4 1 1 1s1-.4 1-1V7.2l9.4 9.6c.4.4 1 .4 1.4 0s.4-1 0-1.4L32.7 4c-.2-.2-.5-.3-.7-.3-.3 0-.5.1-.7.3L20.2 15.4c-.4.4-.4 1 0 1.4.2.3.4.3.7.3M42.4 47.2 33 56.8V37.6c0-.6-.4-1-1-1s-1 .4-1 1v19.3l-9.4-9.7c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l11 11.4c.2.2.4.3.7.3s.5-.1.7-.3l11.2-11.4c.4-.4.4-1 0-1.4s-1-.4-1.4 0" />
  </svg>
);
export default IconArrowExpand;
