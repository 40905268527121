import * as React from "react";
const IconCandelabra = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 12.5h-.3c1.4-.9 2.2-2.4 2.2-4.2 0-2.6-3.7-5.5-4.4-6.1q-.6-.45-1.2 0c-.7.6-4.4 3.5-4.4 6.1 0 1.7.9 3.3 2.2 4.2h-.3c-2.2 0-4 1.8-4 4v17.9c0 2.2 1.8 4 4 4h2.6l-.1.6c-.5 2-1.1 4-3.6 6.2-2.4 2-5.5 2.2-5.5 2.2H33.1v-8.9h2.1c2.2 0 4-1.8 4-4v-18c0-2.2-1.8-4-4-4h-.4c1.4-.9 2.2-2.4 2.2-4.2 0-2.6-3.7-5.5-4.4-6.1q-.6-.45-1.2 0c-.7.6-4.4 3.5-4.4 6.1 0 1.7.9 3.3 2.2 4.2h-.3c-2.2 0-4 1.8-4 4v17.9c0 2.2 1.8 4 4 4H31v8.9H19.9c-.1 0-3.1-.2-5.9-2.9-1.9-1.8-2.7-4.8-3-6h2.3c2.2 0 4-1.8 4-4V16.5c0-2.2-1.8-4-4-4H13c1.4-.9 2.2-2.4 2.2-4.2 0-2.6-3.7-5.5-4.4-6.1q-.6-.45-1.2 0c-.8.6-4.5 3.5-4.5 6.1 0 1.7.9 3.3 2.2 4.2H7c-2.2 0-4 1.8-4 4v17.9c0 2.2 1.8 4 4 4h1.9c.2 1 1.1 4.9 3.6 7.4 3.3 3.2 6.8 3.5 7.2 3.5h11.2V60H18.8c-.6 0-1 .4-1 1s.4 1 1 1h26.7c.6 0 1-.4 1-1s-.4-1-1-1H33.1V49.3h11.2c.2 0 3.8-.2 6.7-2.7s3.7-5 4.2-7.3l.2-1H57c2.2 0 4-1.8 4-4V16.5c0-2.2-1.8-4-4-4m-3.1-8.2c1.4 1.2 3.1 3 3.1 4 0 1.7-1.4 3.1-3.1 3.1s-3.1-1.4-3.1-3.1c0-1 1.6-2.8 3.1-4M32 4.3c1.4 1.2 3.1 3 3.1 4 0 1.7-1.4 3.1-3.1 3.1s-3.1-1.4-3.1-3.1c0-1 1.7-2.8 3.1-4m-5.1 30.1V16.5c0-1.1.9-2 2-2h6.3c1.1 0 2 .9 2 2v17.9c0 1.1-.9 2-2 2h-6.3c-1.1 0-2-.9-2-2M10.1 4.3c1.4 1.2 3.1 3 3.1 4 0 1.7-1.4 3.1-3.1 3.1S7 10 7 8.3c.1-1 1.7-2.8 3.1-4M5 34.4V16.5c0-1.1.9-2 2-2h6.3c1.1 0 2 .9 2 2v17.9c0 1.1-.9 2-2 2H7c-1.1 0-2-.9-2-2m54 0c0 1.1-.9 2-2 2h-6.3c-1.1 0-2-.9-2-2V16.5c0-1.1.9-2 2-2H57c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconCandelabra;
