import * as React from "react";
const IconSpeechBubble31 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32.3 2c-14.4 0-26 8.3-26 18.5S18 39 32.4 39s26.1-8.3 26.1-18.5S46.7 2 32.3 2m0 34.9c-13.3 0-24-7.4-24-16.4S19 4 32.3 4s24.1 7.4 24.1 16.5-10.8 16.4-24.1 16.4M23.5 43.4c-3.1 0-5.5 2.5-5.5 5.5s2.5 5.5 5.5 5.5c3.1 0 5.5-2.5 5.5-5.5s-2.4-5.5-5.5-5.5m0 9.1c-2 0-3.5-1.6-3.5-3.5s1.6-3.5 3.5-3.5c2 0 3.5 1.6 3.5 3.5s-1.5 3.5-3.5 3.5M10.3 52.6c-2.6 0-4.7 2.1-4.7 4.7S7.7 62 10.3 62s4.7-2.1 4.7-4.7-2.1-4.7-4.7-4.7m0 7.4c-1.5 0-2.7-1.2-2.7-2.7s1.2-2.7 2.7-2.7 2.7 1.2 2.7 2.7-1.2 2.7-2.7 2.7" />
    <path d="M21.6 17.8h-.1c-1.1 0-2 .9-2 2s.9 2 2 2h.1c1.1 0 2-.9 2-2s-.9-2-2-2M32.4 17.8h-.1c-1.1 0-2 .9-2 2s.9 2 2 2h.1c1.1 0 2-.9 2-2s-.9-2-2-2M43.2 17.8h-.1c-1.1 0-2 .9-2 2s.9 2 2 2h.1c1.1 0 2-.9 2-2s-.9-2-2-2" />
  </svg>
);
export default IconSpeechBubble31;
