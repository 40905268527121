import * as React from "react";
const IconSortAlphaAscCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m30 49.8-8.8 8.8V3c0-.6-.4-1-1-1s-1 .4-1 1v55.6l-8.8-8.8c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l10.6 10.5c.2.2.5.3.7.3s.5-.1.7-.3l10.6-10.5c.4-.4.4-1 0-1.4-.6-.4-1.2-.4-1.6 0M54.4 60h-6.8l6.4-8.8c.2-.3.3-.7.1-1s-.5-.5-.9-.5h-8.1c-.6 0-1 .4-1 1s.4 1 1 1h6.1l-6.4 8.8c-.2.3-.3.7-.1 1s.5.5.9.5h8.7c.6 0 1-.4 1-1s-.3-1-.9-1M51.8 14.5c.2 0 .4-.1.6-.2l1.1 2.3c.2.3.5.6.9.6.2 0 .3 0 .4-.1.5-.2.7-.8.4-1.3L48.7 2.6c-.3-.7-1.4-.7-1.8 0l-6.6 13.2c-.2.5 0 1.1.4 1.3.5.2 1.1 0 1.3-.4l1.2-2.3c.2.1.4.2.6.2h8zm-4-9.3 3.7 7.3h-7.3z" />
  </svg>
);
export default IconSortAlphaAscCopy;
