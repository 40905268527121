import * as React from "react";
const IconHouseAlt5 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 56.4h-2.9v-31c0-1.6-.8-3.1-2.1-4.1L34.8 6.5c-1.7-1.2-4-1.2-5.7 0L8 21.3c-1.3.9-2.1 2.4-2.1 4.1v31H3c-.6 0-1 .4-1 1s.4 1 1 1h58c.6 0 1-.4 1-1s-.4-1-1-1M30.3 8.2c1-.7 2.4-.7 3.4 0L54.9 23c.2.1.3.3.5.4H8.7c.1-.2.3-.3.4-.4zM7.9 56.4v-31h47.9c.1 0 .2 0 .3-.1v31.1z" />
  </svg>
);
export default IconHouseAlt5;
