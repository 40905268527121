import * as React from "react";
const IconEyeSlash = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.9 32.5q.15-.45 0-.9c-.1-.2-9.8-18.9-28.9-18.9-4.2 0-8.3.9-12.1 2.7-.7.3-1.4.7-2.1 1.1L10 8.7c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4L54 55.3c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4L48 46.5c.5-.3.9-.6 1.4-1 7.6-5.4 11.3-12.7 11.5-13M37 33.6c-.1.5-.4.9-.7 1.3l-7.2-7.2q.6-.45 1.2-.6h.1c.5-.2 1-.2 1.6-.2 2.9 0 5.2 2.3 5.2 5.2 0 .4-.1 1-.2 1.5m9.5 11.5-8.8-8.8c.5-.7.9-1.4 1.1-2.2q.3-1.05.3-2.1c0-4-3.2-7.2-7.2-7.2-.7 0-1.3.1-2 .3h-.2c-.8.2-1.5.6-2.2 1.1l-8.4-8.4c.5-.3 1-.5 1.4-.7 3.6-1.7 7.4-2.5 11.3-2.5C48 14.6 57.1 29.2 58.7 32c-.9 1.7-4.5 7.5-10.6 11.9-.4.5-1 .8-1.6 1.2M32 49.4C15.8 49.4 6.7 34.8 5.1 32c.8-1.3 3.2-5.5 7.4-9.3l-1.4-1.5c-5.3 4.9-8 10.1-8.1 10.3q-.15.45 0 .9c.1.2 9.8 18.9 28.9 18.9 2.6 0 5.1-.3 7.6-1l-.5-1.9c-2.2.7-4.6 1-7 1" />
  </svg>
);
export default IconEyeSlash;
