import * as React from "react";
const IconMuscles = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.5 62H5.3C3.4 62 2 60.6 2 58.7V45.3c0-.8.4-1.7 1-2.3.4-.4 10-9.9 20.1-9.9 3.1 0 6.7.9 9.4 2.2l-2.9-11.8-5.1 2.5s-.1 0-.1.1c-.3.1-.7.2-1.3.2H11.6c-3.9 0-7.3-2.3-8.8-5.9s-.7-7.6 2-10.4l7-7c.5-.7 1.4-1 2.3-1h22.2c1.3 0 2.4.7 3 1.8l21.5 43c1.6 3.3 1.4 7.1-.5 10.2-1.8 3.1-5.2 5-8.8 5M23.1 35.1c-9.3 0-18.3 8.9-18.7 9.3-.2.2-.4.6-.4.9v13.5c0 .7.5 1.2 1.3 1.2h46.2c3 0 5.7-1.5 7.2-4 1.6-2.5 1.7-5.6.4-8.2l-21.5-43c-.3-.5-.7-.8-1.2-.8H14.2c-.2 0-.6.1-.8.3 0 0 0 .1-.1.1l-7.1 7.1c-2.2 2.2-2.8 5.3-1.6 8.2s3.9 4.7 7 4.7h11.5c.2 0 .4-.1.6-.1l6.2-3.1q.45-.15.9 0c.3.1.5.4.6.7L35 37.1c.1.4-.1.8-.4 1.1-.3.2-.8.2-1.1 0-2.5-1.8-6.9-3.1-10.4-3.1" />
  </svg>
);
export default IconMuscles;
