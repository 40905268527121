import * as React from "react";
const IconBed2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 26.7H21.2c2-1.6 3.2-4.1 3.2-6.8 0-4.9-3.9-8.8-8.8-8.8S6.8 15 6.8 19.9c0 2.7 1.3 5.2 3.2 6.8H6c-2.2 0-4 1.8-4 4v13c0 .6.4 1 1 1h2.3v4.2c0 2.2 1.8 4 4 4h45.4c2.2 0 4-1.8 4-4v-4.2H61c.6 0 1-.4 1-1v-13c0-2.2-1.8-4-4-4M8.8 19.9c0-3.7 3-6.8 6.8-6.8 3.7 0 6.8 3 6.8 6.8 0 3.7-3 6.8-6.8 6.8-3.7 0-6.8-3.1-6.8-6.8m47.9 29c0 1.1-.9 2-2 2H9.3c-1.1 0-2-.9-2-2v-4.2h49.4zm3.3-6.2H4v-12c0-1.1.9-2 2-2h52c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconBed2;
