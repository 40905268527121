import * as React from "react";
const IconDerrickAlt3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m53.4 44.1-5-8.7c-.9-1.6-2.6-2.5-4.4-2.5h-3.4l-7-11.4v-3.9c3.8-.5 6.8-3.8 6.8-7.7 0-4.2-3.3-7.6-7.4-7.8H11c-.6 0-1 .4-1 1s.4 1 1 1h16.4c-1.6 1.4-2.6 3.5-2.6 5.8 0 4 3 7.2 6.8 7.7v3.9l-6.9 11.4h-3.6c-1.8 0-3.5 1-4.4 2.5l-5.3 9.3c-.9 1.5-.9 3.4-.1 4.9l5.4 9.8c.9 1.6 2.6 2.6 4.4 2.6h3.6c2.8 0 5-2.3 5-5v-4.5c0-.6-.4-1-1-1h-.6c-.9 0-1.7-.8-1.7-1.7v-4c0-1.7 1.4-3 3-3h6.3c1.7 0 3 1.4 3 3V50c0 .9-.8 1.7-1.7 1.7h-.9c-.6 0-1 .4-1 1V57c0 2.8 2.3 5 5 5h3c1.8 0 3.5-1 4.4-2.6l5.8-10.3c1-1.5 1-3.4.1-5M26.7 9.8c0-3.2 2.6-5.8 5.8-5.8s5.8 2.6 5.8 5.8-2.6 5.8-5.8 5.8-5.8-2.6-5.8-5.8m5.9 13.8 5.7 9.2H27zm19.1 24.5-5.8 10.3c-.5 1-1.6 1.6-2.7 1.6h-3c-1.7 0-3-1.4-3-3v-3.2c2 0 3.6-1.7 3.6-3.7v-4.3c0-2.8-2.3-5-5-5h-6.3c-2.8 0-5 2.3-5 5v4c0 1.9 1.5 3.5 3.3 3.7V57c0 1.7-1.4 3-3 3h-3.6c-1.1 0-2.1-.6-2.7-1.6l-5.4-9.8c-.5-.9-.5-2.1 0-3l5.3-9.3c.5-.9 1.6-1.5 2.6-1.5h23c1.1 0 2.1.6 2.6 1.5l5 8.7c.6 1 .6 2.2.1 3.1" />
  </svg>
);
export default IconDerrickAlt3;
