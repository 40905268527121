import * as React from "react";
const IconFunnelAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.5 8.1V8c0-.1 0-.2-.1-.3 0-.1 0-.1-.1-.2C58.9 3.2 41.4 2 32 2S5.1 3.2 3.6 7.6c0 .1 0 .1-.1.2 0 .1-.1.2-.1.3v.2c0 .2 0 .3.1.5.1 1.3.6 2.5 1.4 3.4l18.9 22.3v20.7c0 1.7 1.1 3.3 2.7 3.8l8.2 2.8c.4.2.9.2 1.4.2.8 0 1.6-.3 2.3-.8 1.1-.8 1.7-2 1.7-3.3V34.3L59 12.1c.8-1 1.3-2.2 1.4-3.4.1-.2.1-.4.1-.6 0 .1 0 0 0 0M32 4c16.9 0 26.3 2.7 26.5 4.2v.1c-.3 1.4-9.6 4.1-26.5 4.1S5.8 9.7 5.5 8.3v-.1C5.7 6.7 15.1 4 32 4m6.4 29.4c-.2.2-.2.4-.2.6v23.9c0 .7-.3 1.3-.9 1.7-.5.4-1.2.5-1.9.3L27.2 57c-.8-.3-1.4-1.1-1.4-1.9V34c0-.2-.1-.5-.2-.6L7 11.4c5.9 2.3 17.8 3 25 3s19.1-.7 25-3z" />
  </svg>
);
export default IconFunnelAlt1;
