import * as React from "react";
const IconChillsFever = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M38.5 6A2.5 2.5 0 0 0 36 8.5v4.55a3.5 3.5 0 1 0 5 0V8.5A2.5 2.5 0 0 0 38.5 6m-1.072 8.45.572-.583V12h1v1.867l.572.583a1.5 1.5 0 1 1-2.143 0M38.5 8a.5.5 0 0 0-.5.5V10h1V8.5a.5.5 0 0 0-.5-.5M14.215 18.886A3 3 0 0 1 17 17h9a3 3 0 0 1 2.786 1.886l2 5a3 3 0 0 1-1.671 3.9l-1.614.645v10.835999999999999c0 1.426-1.087 2.661-2.545 2.73-1.46.069-2.654-1.063-2.778-2.486L21.783 35h-.566l-.394 4.51c-.124 1.424-1.318 2.556-2.779 2.487-1.457-.069-2.544-1.304-2.544-2.73V28.505l-.252-.112-.033-.012v-.002l-1.433-.638a3 3 0 0 1-1.567-3.855zM17.5 29.733v-.626q.293.046.578.042c.344-.008.739-.08 1.1-.313.378-.242.632-.601.771-1.02.252-.753.51-1.715.09-2.66-.416-.937-1.364-1.563-2.667-2.084l-.928-.372-.743 1.857.928.372c1.197.478 1.5.852 1.583 1.04.079.177.089.459-.148 1.18h-.029c-.24.005-.631-.089-1.186-.295l-.846-.314-1.409-.626a1 1 0 0 1-.522-1.285l2-5A1 1 0 0 1 17 19h9a1 1 0 0 1 .929.629l2 5a1 1 0 0 1-.557 1.3l-2.243.897a1 1 0 0 0-.213.117l-.21.064a4 4 0 0 1-.52.125c-.115.018-.196.02-.248.016-.237-.72-.227-1.002-.148-1.18.083-.187.385-.56 1.582-1.04l.929-.37-.743-1.858-.928.372c-1.304.52-2.251 1.147-2.668 2.084-.42.945-.16 1.907.09 2.66.117.352.317.667.614.907.293.236.625.352.935.399.299.045.605.03.9-.014v10.158999999999999c0 .427-.316.717-.639.732-.32.015-.655-.24-.692-.663l-.474-5.423A1 1 0 0 0 22.7 33H20.3a1 1 0 0 0-.996.913l-.474 5.424c-.037.422-.372.677-.692.662-.323-.015-.639-.305-.639-.732zM26 11.5a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0m-2 0a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0"
      clipRule="evenodd"
    />
    <path
      
      d="M8.721 19.043C9.26 18.505 10.07 18 11 18h1v2h-1c-.213 0-.545.137-.864.457a1.9 1.9 0 0 0-.338.45 1 1 0 0 0-.076.19q.017.03.06.089c.11.146.27.308.496.535l.023.023c.198.198.456.455.66.727s.468.703.468 1.243c0 .466-.238.803-.431 1.002a2.7 2.7 0 0 1-.615.453c-.307.172-.698.34-1.04.488l-.228.098c-.433.19-.768.353-.99.515q-.045.033-.075.058c.11.1.28.2.583.372l.038.02c.258.146.637.359.942.644.356.334.673.823.673 1.493 0 1.067-.704 1.764-1.092 2.15l-.058.057c-.486.486-.565.63-.565.793 0 .117.108.39.612.706.479.3 1.011.437 1.246.437h1v2h-1c-.695 0-1.59-.292-2.308-.742-.693-.434-1.55-1.232-1.55-2.4 0-1.067.704-1.765 1.092-2.15l.058-.058c.477-.476.561-.623.565-.783a.4.4 0 0 0-.041-.044c-.11-.102-.28-.204-.592-.38l-.038-.021c-.259-.145-.637-.358-.942-.643-.356-.334-.673-.823-.673-1.493 0-.812.546-1.34.947-1.632.42-.306.943-.545 1.366-.73l.294-.128c.244-.105.45-.194.624-.28a10 10 0 0 0-.367-.38l-.022-.022a7 7 0 0 1-.66-.728c-.204-.272-.468-.703-.468-1.243 0-.805.488-1.58 1.007-2.1"
    />
    <path
      
      fillRule="evenodd"
      d="M33 18c.905 0 1.593.636 1.979 1.15.406.542.735 1.284.735 1.993 0 .46-.17.85-.329 1.133-.153.272-.348.532-.497.731l-.017.022a6 6 0 0 0-.364.523c.126.086.279.174.479.29l.232.134c.321.187.74.44 1.077.77.349.338.705.848.705 1.54 0 1.15-.804 1.749-1.205 2.046l-.052.04c-.459.343-.458.399-.457.475v.01c0 .27.106.452.443.9l.033.044c.31.413.81 1.076.81 2.056 0 1.07-.648 1.862-1.23 2.332a4.2 4.2 0 0 1-.934.572c-.282.124-.636.239-.98.239h-1v-2h.981l.005-.002c.02-.005.083-.021.187-.067.15-.066.323-.168.485-.298.347-.28.485-.56.485-.776 0-.27-.106-.451-.442-.9l-.034-.044c-.31-.413-.81-1.076-.81-2.056 0-1.151.805-1.749 1.206-2.047l.052-.039c.454-.34.458-.398.457-.473a.5.5 0 0 0-.099-.119c-.144-.14-.369-.288-.69-.476l-.162-.093c-.256-.147-.572-.328-.819-.513-.26-.196-.801-.647-.801-1.383 0-.46.17-.85.329-1.133.153-.272.348-.532.497-.731l.016-.021c.17-.227.288-.387.37-.534a.7.7 0 0 0 .073-.16c-.003-.153-.104-.476-.335-.785a1.3 1.3 0 0 0-.304-.304.4.4 0 0 0-.088-.046H32v-2zm1.332 5.411q-.001 0 .013.015zm.67 2.89.001.005v.003-.004z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconChillsFever;
