import * as React from "react";
const IconPaintRollerAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.8 9.9h-3V5c0-1.7-1.3-3-3-3H5C3.3 2 2 3.3 2 5v11.8c0 1.7 1.3 3 3 3h40.8c1.7 0 3-1.3 3-3v-4.9h3c4.5 0 8.2 3.7 8.2 8.2s-3.7 8.2-8.2 8.2H32c-2 0-3.6 1.6-3.6 3.6v6.9h-2.3c-1.7 0-3 1.3-3 3V59c0 1.7 1.3 3 3 3h6.5c1.7 0 3-1.3 3-3V41.9c0-1.7-1.3-3-3-3h-2.3V32c0-.9.7-1.6 1.6-1.6h19.8c5.6 0 10.2-4.6 10.2-10.2S57.4 9.9 51.8 9.9m-5 6.9c0 .6-.4 1-1 1H5c-.6 0-1-.4-1-1V5c0-.6.4-1 1-1h40.8c.6 0 1 .4 1 1zM33.6 41.9V59c0 .6-.4 1-1 1h-6.5c-.6 0-1-.4-1-1V41.9c0-.6.4-1 1-1h6.5c.6 0 1 .5 1 1" />
  </svg>
);
export default IconPaintRollerAlt;
