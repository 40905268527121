import * as React from "react";
const IconTrashCanCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.7 9.3H40.2V5c0-1.7-1.3-3-3-3H26.8c-1.7 0-3 1.3-3 3v4.3H13.3c-1.7 0-3 1.3-3 3v7.3c0 .6.4 1 1 1h2.2v34.7c0 3.7 3 6.7 6.7 6.7h23.5c3.7 0 6.8-3 6.8-6.7V20.6h2.2c.6 0 1-.4 1-1v-7.3c0-1.6-1.4-3-3-3M25.8 5c0-.6.4-1 1-1h10.4c.6 0 1 .4 1 1v4.3H25.8zm22.7 50.3c0 2.6-2.1 4.7-4.8 4.7H20.3c-2.6 0-4.7-2.1-4.7-4.7V20.6h33v34.7zm3.2-36.7H12.3v-6.3c0-.6.4-1 1-1h37.4c.6 0 1 .4 1 1z" />
    <path d="M32 47.6c.6 0 1-.4 1-1V33.4c0-.6-.4-1-1-1s-1 .4-1 1v13.2c0 .5.4 1 1 1M40.4 47.6c.6 0 1-.4 1-1V33.4c0-.6-.4-1-1-1s-1 .4-1 1v13.2c0 .5.5 1 1 1M23.6 47.6c.6 0 1-.4 1-1V33.4c0-.6-.4-1-1-1s-1 .4-1 1v13.2c0 .5.4 1 1 1" />
  </svg>
);
export default IconTrashCanCopy;
