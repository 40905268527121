import * as React from "react";
const IconTerminal = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M27.6 30.8 4.7 7.5c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l22.2 22.5L3.3 54c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3l22.8-23.2c.4-.4.4-1 .1-1.4M60 54.8H20.2c-.6 0-1 .4-1 1s.4 1 1 1H60c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconTerminal;
