import * as React from "react";
const IconFridge = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M46 7.8H18c-2.8 0-5 2.2-5 5v34.7c0 2.5 1.8 4.6 4.2 4.9v2.8c0 .6.4 1 1 1s1-.4 1-1v-2.8h24.4v2.8c0 .6.4 1 1 1s1-.4 1-1v-2.8h.4c2.8 0 5-2.2 5-5V12.8c0-2.8-2.3-5-5-5m3 39.7c0 1.7-1.3 3-3 3H18c-1.7 0-3-1.3-3-3V12.8c0-1.7 1.3-3 3-3h28c1.7 0 3 1.3 3 3z" />
    <path d="M41.8 20.3c-.6 0-1 .4-1 1v4.6c0 .6.4 1 1 1s1-.4 1-1v-4.6c0-.5-.5-1-1-1" />
  </svg>
);
export default IconFridge;
