import * as React from "react";
const IconChestOfDrawers3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.4 15.1V7c0-2.8-2.2-5-5-5H11.6c-2.8 0-5 2.2-5 5v8.1c0 1.6.8 3.1 2 4-1.2.9-2 2.4-2 4v8.1c0 1.7.9 3.2 2.2 4.1-1.2.9-1.9 2.3-1.9 3.9v12.1c0 2.4 1.8 4.5 4.1 4.9V61c0 .6.4 1 1 1s1-.4 1-1v-4.6h7c1.6 0 3.1-.8 4-2 .9 1.2 2.4 2 4 2h8.1c1.6 0 3.1-.8 4-2 .9 1.2 2.4 2 4 2h7V61c0 .6.4 1 1 1s1-.4 1-1v-4.7c2.3-.4 4.1-2.5 4.1-4.9V39.3c0-1.6-.8-3-1.9-3.9 1.3-.9 2.2-2.4 2.2-4.1v-8.1c0-1.6-.8-3.1-2-4 1.1-1 1.9-2.4 1.9-4.1M19.9 54.4h-8.1c-1.7 0-3-1.3-3-3V39.3c0-1.7 1.3-3 3-3h8.1c1.7 0 3 1.3 3 3v12.1c0 1.6-1.3 3-3 3m4-18.1c.1 0 0 0 0 0m12.2 18.1H28c-1.7 0-3-1.3-3-3V39.3c0-1.7 1.3-3 3-3h8.1c1.7 0 3 1.3 3 3v12.1c0 1.6-1.4 3-3 3M40 36.3q.15 0 0 0c.1 0 0 0 0 0m15.2 15.1c0 1.7-1.3 3-3 3h-8.1c-1.7 0-3-1.3-3-3V39.3c0-1.7 1.3-3 3-3h8.1c1.7 0 3 1.3 3 3zm.2-28.3v8.1c0 1.7-1.3 3-3 3H11.7c-1.7 0-3-1.3-3-3v-8.1c0-1.7 1.3-3 3-3h40.9c1.5 0 2.8 1.4 2.8 3m0-8c0 1.7-1.3 3-3 3H11.6c-1.7 0-3-1.3-3-3V7c0-1.7 1.3-3 3-3h40.9c1.7 0 3 1.3 3 3v8.1z" />
    <path d="M36.3 10.1h-8.6c-.6 0-1 .4-1 1s.4 1 1 1h8.6c.6 0 1-.4 1-1s-.5-1-1-1M27.7 27.5h8.6c.6 0 1-.4 1-1s-.4-1-1-1h-8.6c-.6 0-1 .4-1 1s.5 1 1 1" />
  </svg>
);
export default IconChestOfDrawers3;
