import * as React from "react";
const IconFileProtection = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m40.9 29.6-8.4-4.3q-.45-.15-.9 0l-8.4 4.3c-.3.2-.5.5-.5.9v4.8c0 5.5 2.6 10.6 7.1 13.7l1.8 1.2c.2.1.4.2.6.2s.4-.1.6-.2l1.8-1.2c4.4-3.1 7.1-8.2 7.1-13.7v-4.8c-.3-.4-.5-.7-.8-.9m-1.5 5.6c0 4.8-2.3 9.3-6.2 12l-1.2.9-1.2-.8c-3.9-2.7-6.2-7.2-6.2-12v-4.2l7.4-3.8 7.4 3.8z" />
    <path d="M58.1 13.7H32.4l-3.1-9c-.5-1.6-2-2.6-3.6-2.6H5.9C3.7 2.1 2 3.8 2 6v52c0 2.1 1.7 3.9 3.9 3.9h52.3c2.1 0 3.9-1.7 3.9-3.9V17.5c-.1-2.1-1.8-3.8-4-3.8M60 58c0 1-.8 1.9-1.9 1.9H5.9c-1 0-1.9-.8-1.9-1.9V6c0-1 .8-1.9 1.9-1.9h19.8c.8 0 1.5.5 1.8 1.2l3.3 9.6c.1.4.5.7.9.7h26.5c1 0 1.9.8 1.9 1.9V58z" />
  </svg>
);
export default IconFileProtection;
