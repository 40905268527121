import * as React from "react";
const IconCactus2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M49.9 8.1c-3.2 0-5.8 2.6-5.8 5.8v5.7q0 .3-.3.3h-5.2V8.6c0-3.7-3-6.6-6.6-6.6s-6.6 2.9-6.6 6.6v6.1h-5.2q-.3 0-.3-.3V8.6c0-3.2-2.6-5.8-5.8-5.8S8.3 5.4 8.3 8.6V16c0 5.7 4.6 10.3 10.3 10.3h6.8v11.3h-6.3c-2.2 0-4 1.8-4 4V44c0 1.6.9 2.9 2.2 3.5L19.4 58c.5 2.3 2.5 4 4.9 4h15.4c2.4 0 4.4-1.7 4.9-4l2.1-10.5c1.3-.7 2.2-2 2.2-3.5v-2.4c0-2.2-1.8-4-4-4h-6.3v-6h6.8c5.7 0 10.3-4.6 10.3-10.3v-7.4c0-3.2-2.6-5.8-5.8-5.8M18.6 24.3c-4.6 0-8.3-3.7-8.3-8.3V8.6c0-2.1 1.7-3.8 3.8-3.8s3.8 1.7 3.8 3.8v5.7c0 1.3 1 2.3 2.3 2.3h5.2v7.6h-6.8zm24 33.3c-.2 1.4-1.5 2.4-2.9 2.4H24.3c-1.4 0-2.7-1-2.9-2.4l-2-9.6h25.1zm4.3-16V44c0 1.1-.9 2-2 2H19.1c-1.1 0-2-.9-2-2v-2.4c0-1.1.9-2 2-2h25.7c1.2 0 2.1.9 2.1 2m-19.5-4v-29C27.4 6.1 29.5 4 32 4s4.6 2.1 4.6 4.6v29zm26.3-16.3c0 4.6-3.7 8.3-8.3 8.3h-6.8V22h5.2c1.3 0 2.3-1 2.3-2.3V14c0-2.1 1.7-3.8 3.8-3.8s3.8 1.7 3.8 3.8z" />
  </svg>
);
export default IconCactus2;
