import * as React from "react";
const IconRainbow = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.9 41.7C59.5 27 46.9 16.3 32 16.3S4.5 27 2.1 41.7c-.1.3-.1.6-.1.9q0 1.8 1.2 3.3c1 1.2 2.5 1.9 4 1.9h49.6c1.5 0 3-.7 4-1.9.8-1 1.2-2.2 1.2-3.4 0-.3 0-.6-.1-.8m-37.1 4c.5-3.5 3.5-6.3 7.2-6.3s6.7 2.7 7.2 6.3zm16.4 0c-.5-4.6-4.4-8.3-9.2-8.3s-8.7 3.6-9.2 8.3h-8.7c.5-9.4 8.4-17 17.9-17s17.4 7.5 17.9 17zm10.7 0c-.5-10.5-9.2-19-19.9-19s-19.4 8.4-19.9 19H7.2c-.9 0-1.8-.4-2.5-1.1-.2-.2-.3-.4-.4-.6-.3-.7-.4-1.3-.3-2 2.3-13.7 14-23.7 28-23.7 13.9 0 25.7 10 28 23.7.1.7 0 1.3-.3 1.9-.1.2-.2.4-.4.6-.6.7-1.5 1.1-2.5 1.1h-4.9z" />
  </svg>
);
export default IconRainbow;
