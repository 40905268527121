import * as React from "react";
const IconCornerDoubleUpLeft = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.3 22.2h-34l11.5-11.5c.4-.4.4-1 0-1.4s-1-.4-1.4 0L17.1 22.5c-.4.4-.4 1 0 1.4l13.2 13.2c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4L20.2 24.2h34c2.6 0 4.7 2.1 4.7 4.7V54c0 .6.4 1 1 1s1-.4 1-1V28.9c.1-3.7-2.9-6.7-6.6-6.7" />
    <path d="m5.4 23.2 12.5-12.5c.4-.4.4-1 0-1.4s-1-.4-1.4 0L3.3 22.5c-.4.4-.4 1 0 1.4l13.2 13.2c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4z" />
  </svg>
);
export default IconCornerDoubleUpLeft;
