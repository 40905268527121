import * as React from "react";
const IconCoffeeBean = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.9 38c-.7-7.4-9-12.8-18.5-11.9-2.5.2-4.8.9-6.9 1.8-.2-7.3-3.6-13.4-8.9-15.9-.6-.3-1.4-.6-2.1-.8C15.9 9 6.7 16.1 3.9 27.1c-2.6 9.8.9 19.7 8 23.1.7.3 1.4.6 2.1.8 1 .3 2.1.4 3.2.4 3.8 0 7.7-1.7 11-4.9 2.8 4.1 8.3 6.7 14.6 6.7.7 0 1.3 0 2-.1 4.6-.4 8.8-2.2 11.8-4.9 3.1-2.9 4.6-6.5 4.3-10.2m-18.4-9.9c8.5-.8 15.8 3.8 16.4 10.1v.4l-4.3-1c-3.9-.9-7.8-.5-11.5 1-3.3 1.4-6.8 1.7-10.3.9l-4.5-1.1c1.1-5.2 6.9-9.6 14.2-10.3m-36.7-.5c2.6-9.9 10.7-16.3 18.1-14.4.2.1.5.1.7.2L21.7 18c-2.5 3.9-3.7 8.5-3.4 13.2.3 4.2-.8 8.4-3.1 12L12 47.9c-5.8-3.3-8.4-11.8-6.2-20.3M14.5 49c-.2-.1-.5-.1-.7-.2l3-4.6c2.5-3.9 3.7-8.5 3.4-13.2-.3-4.2.8-8.4 3.1-12l3.1-4.8c4.4 2.5 7.1 8.1 7 14.7-4.7 2.8-7.6 7.3-7.2 12.2.1 1.2.4 2.4.9 3.5-3.7 3.8-8.3 5.5-12.6 4.4m40.7-2.4c-2.7 2.5-6.5 4.1-10.6 4.4-8.5.8-15.8-3.8-16.4-10.1v-.4l4.2 1c1.4.3 2.8.5 4.2.5 2.5 0 5-.5 7.3-1.5 3.3-1.4 6.8-1.7 10.3-.9l4.6 1.1c-.5 2.1-1.7 4.2-3.6 5.9" />
  </svg>
);
export default IconCoffeeBean;
