import * as React from "react";
const IconDnaAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.7 18.4c-.4-.3-1.1-.3-1.4.1-1 1.2-2.2 2.1-3.6 2.8l-13-12.9c.7-1.4 1.6-2.7 2.8-3.6.4-.3.5-1 .2-1.4s-1-.5-1.4-.1c-1.6 1.3-2.8 3-3.6 4.9 0 .1-.1.1-.1.2-.9 2.3-1.3 5.1-1.1 8.1 0 .5.5.9 1 .9h.1c.5 0 1-.5.9-1.1-.2-2.1 0-4.1.5-5.8l11.6 11.6c-2.9.8-6.3.7-10.1-.3l-3-.8c-7.2-1.9-13.2-.8-17.1 3-3.8 3.8-4.9 9.9-3 17.1l.7 2.7c1 3.7 1.1 7 .4 9.8l-11-11.1c1.6-.4 3.3-.5 5.2-.4.6 0 1-.4 1.1-.9 0-.6-.4-1-.9-1.1-2.8-.2-5.3.1-7.6.9-.1 0-.1 0-.2.1-1.9.7-3.6 1.8-4.9 3.3-.4.4-.3 1 .1 1.4.3.2.5.3.7.3.3 0 .5-.1.7-.3 1-1.1 2.2-1.9 3.6-2.5l12.5 12.5c-.6 1.4-1.5 2.6-2.6 3.6-.4.4-.4 1-.1 1.4.2.2.5.3.7.3s.5-.1.7-.3c1.5-1.3 2.5-3 3.3-4.9v-.1c1.3-3.5 1.4-7.8.1-12.5l-.7-2.7c-.9-3.5-1.1-6.6-.5-9.3l11 11c-1.5.3-3.2.4-5 .2-.6-.1-1 .4-1.1.9 0 .6.4 1 .9 1.1.6.1 1.3.1 1.9.1 2 0 3.8-.3 5.5-.9h.1c1.9-.7 3.6-1.7 4.9-3 2.9-2.9 4.3-7.2 3.8-12.4 0-.5-.5-1-1.1-.9-.6 0-1 .5-.9 1.1.2 2.1 0 4-.4 5.7L29.9 22.6c2.8-.8 6.3-.7 10.1.3l2.9.8c4.9 1.3 9.3 1.2 12.8-.2.1 0 .1-.1.2-.1 1.9-.8 3.6-2 4.9-3.6.4-.5.3-1.1-.1-1.4m-32.4 5.4 12.4 12.4q-.75 1.65-2.1 3c-1 1-2.3 1.8-3.7 2.4L22.8 29.4c-.1-.1-.2-.2-.3-.2.6-1.4 1.4-2.7 2.4-3.7.9-.9 2-1.6 3.2-2.2 0 .2 0 .4.2.5" />
  </svg>
);
export default IconDnaAlt;
