import * as React from "react";
const IconRoundBoxCross = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M41.8 22.2c-.4-.4-1-.4-1.4 0L32 30.6l-8.3-8.3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l8.3 8.3-8.3 8.3c-.4.4-.4 1 0 1.4.1.3.4.3.7.3s.5-.1.7-.3l8.3-8.3 8.3 8.3c.2.3.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4L33.4 32l8.3-8.3c.4-.4.4-1.1.1-1.5" />
    <path d="M57 2H7C4.2 2 2 4.2 2 7v50c0 2.8 2.2 5 5 5h50c2.8 0 5-2.2 5-5V7c0-2.8-2.2-5-5-5m3 55c0 1.7-1.3 3-3 3H7c-1.7 0-3-1.3-3-3V7c0-1.7 1.3-3 3-3h50c1.7 0 3 1.3 3 3z" />
  </svg>
);
export default IconRoundBoxCross;
