import * as React from "react";
const IconFolderAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.8 25.8c-.2-.3-.5-.4-.8-.4h-6.9v-7.6c0-2-1.6-3.7-3.5-3.7h-25l-2.5-6.2c-.5-1.4-1.8-2.3-3.2-2.3H5.5C3.6 5.6 2 7.3 2 9.3v45.4c0 2 1.6 3.7 3.5 3.7h44.9c.4 0 .8-.3.9-.7l10.6-31c.2-.3.1-.7-.1-.9M5.1 56.3c-.6-.2-1.1-.8-1.1-1.6V9.3c0-.9.7-1.7 1.5-1.7h14.3c.6 0 1.1.4 1.4 1l2.7 6.8q.3.6.9.6h25.7c.8 0 1.5.8 1.5 1.7v7.6H18.3c-.4 0-.8.2-.9.6zm44.6.1H7.3l11.7-29h40.6z" />
  </svg>
);
export default IconFolderAlt;
