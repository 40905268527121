import * as React from "react";
const IconTwitch = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M6.5 1 2.4 11.7v43.5h14.8V63h8.3l7.9-7.9h12l16.2-16.2V1zM56 36.1l-9.3 9.3H32l-7.9 7.9v-7.9H11.6V6.5H56zm-9.2-18.9v16.2h-5.5V17.2zm-14.8 0v16.2h-5.5V17.2z" />
  </svg>
);
export default IconTwitch;
