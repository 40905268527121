import * as React from "react";
const IconTruckSecure = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M10.7 28.9c.2.1.3.1.5.1s.3 0 .5-.1c2-1 8.7-5 8.7-11.8v-6.5c0-.4-.3-.8-.7-.9l-8.2-2.9c-.2-.1-.4-.1-.7 0L2.7 9.7c-.4.1-.7.5-.7.9v6.5c0 6.9 6.7 10.8 8.7 11.8M4 11.3l7.2-2.5 7.2 2.5v5.8c0 5.3-5 8.6-7.2 9.8C9 25.7 4 22.4 4 17.1z" />
    <path d="m61.8 35.2-7.3-10.6c-.2-.3-.5-.4-.8-.4H41.4v-3.4c0-3-2.4-5.4-5.4-5.4h-8.2c-.6 0-1 .4-1 1s.4 1 1 1H36c1.9 0 3.4 1.5 3.4 3.4v30.6H27.6c-.2-2.8-2.5-5-5.3-5s-5.1 2.2-5.3 5H9c-1.9 0-3.4-1.5-3.4-3.4V34c0-.6-.4-1-1-1s-1 .4-1 1v14c0 3 2.4 5.4 5.4 5.4h8.2c.7 2.2 2.7 3.8 5.1 3.8s4.4-1.6 5.1-3.8H45c.7 2.2 2.7 3.8 5.1 3.8s4.4-1.6 5.1-3.8H61c.6 0 1-.4 1-1V35.8c0-.2-.1-.4-.2-.6m-8.6-9 6.4 9.3H41.4v-9.3zm-31 29c-1.9 0-3.4-1.5-3.4-3.4s1.5-3.4 3.4-3.4 3.4 1.5 3.4 3.4-1.5 3.4-3.4 3.4m27.8 0c-1.9 0-3.4-1.5-3.4-3.4s1.5-3.4 3.4-3.4 3.4 1.5 3.4 3.4-1.6 3.4-3.4 3.4m5.3-3.8c-.2-2.8-2.5-5-5.3-5s-5.1 2.2-5.3 5h-3.2V37.5H60v13.9z" />
  </svg>
);
export default IconTruckSecure;
