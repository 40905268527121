import React, { useState } from 'react';
import { BringzzAccordion, BringzzButton, BringzzText } from "@bringzz/components";
import { useNavigation } from 'context/NavigationContext';
import {
    BringzzPageContainer,
    BringzzIcon,
    BringzzBasketProduct,
    BringzzBottomDrawer,
    BringzzCheck,
    BringzzMultiSelect
} from "@bringzz/components";

let itemState = {
    checked: (
        <BringzzIcon
            icon="IconCheck"
            library="Custom"
            className="h-5 w-5 p-1 bg-magic-lilac text-light rounded-full"
            size="12"
        />
    ),
    unChecked: (
        <div></div>
    ),
};

const ConsumerOrderViewPage = () => {
    const { backPress } = useNavigation();

    const [reorderDrawerOpen, setReorderDrawerOpen] = useState(false)
    const [selectedItems, setSelectedItems] = useState([])

    const [products, setProducts] = useState([
        {
            name: 'Product Name 1',
            manufacturer: 'Manufacturer',
            amount: '84 Stk | Kapseln',
            quantity: '10',
            imgUrl: '/product.png',
            price: 9,
            inhalt: ""
        },
        {
            name: 'Product Name 2',
            manufacturer: 'Manufacturer 2',
            amount: '12 Stk | Kapseln',
            quantity: '3',
            imgUrl: '/product.png',
            price: 8,
            inhalt: "Prescription attached"
        }
    ]);

    const handleMultiSelectChange = (selectedValues) =>
        setSelectedItems(selectedValues)

    return (
        <BringzzPageContainer
            className="bg-white pb-20"
            header={{
                left: (
                    <BringzzIcon
                        size='22'
                        icon='IconChevronLeft'
                        folder='LineIcons'
                        className='cursor-pointer'
                        onClick={backPress}
                    />
                ),
                title: "Order #1234"
            }}
            authenticated={true}
            footer={
                <div className='flex py-5 px-6 bg-white justify-center'>
                    <BringzzButton size='lg' className='bg-magic-lilac w-full flex justify-center' onClick={() => setReorderDrawerOpen(true)}>Reorder items</BringzzButton>
                </div>
            }
            footerHover={true}
        >

            <div className='px-6 pt-4'>
                <div className='py-2'>
                    <BringzzText tag='h2'>Apotheke Mustermann</BringzzText>
                    <BringzzText tag='h4' className='font-normal'>Delivered on DD/MM/YY, HH:MM</BringzzText>
                </div>
                <div className='flex space-x-4 border-b border-t py-2'>
                    <div>
                        <BringzzIcon
                            folder='LineIcons'
                            icon='IconPlaceholders'
                            size='22'
                            className='p-3 rounded-full' />
                    </div>
                    <div className='flex w-full'>
                        <div>
                            <BringzzText tag='h3'>Delivered to</BringzzText>
                            <BringzzText tag='h4' className={"font-normal"}>Musterstasse 20, 1040 Vienna</BringzzText>
                        </div>
                    </div>
                </div>
                <div className='flex space-x-4 border-b py-2'>
                    <div>
                        <BringzzIcon
                            folder='LineIcons'
                            icon='IconBox9'
                            size='22'
                            className='p-3 rounded-full' />
                    </div>
                    <div className='flex w-full'>
                        <div>
                            <BringzzText tag='h3'>Delivery Type</BringzzText>
                            <BringzzText tag='h4' className={"font-normal"}>Bringzz Pickup</BringzzText>
                        </div>
                    </div>
                </div>
                <div className='flex space-x-4 border-b py-2'>
                    <div>
                        <BringzzIcon
                            folder='LineIcons'
                            icon='IconUserAlt'
                            size='22'
                            className='p-3 rounded-full' />
                    </div>
                    <div className='flex w-full'>
                        <div>
                            <BringzzText tag='h3'>Shopper</BringzzText>
                            <div className='flex items-center space-x-1'>
                                <BringzzText tag='h4' className={"font-normal"}>Dennis L. </BringzzText>
                                <BringzzIcon
                                    folder='LineIcons'
                                    icon='IconStar'
                                    size='12'
                                    className='rounded-full' />
                                <BringzzText tag='h5' className={"font-normal"}>4.2 (54)</BringzzText>
                            </div>
                            <div>
                                <div className='flex items-center gap-x-3 mt-1'>
                                    <BringzzText tag='h4'>Add tip</BringzzText>
                                    <BringzzButton className='bg-sand text-olive-green rounded-lg !px-3 flex justify-center font-semibold  '>
                                        €1
                                    </BringzzButton>
                                    <BringzzButton className='bg-sand text-olive-green rounded-lg !px-3 flex justify-center font-semibold  '>
                                        €3
                                    </BringzzButton>
                                    <BringzzButton className='bg-sand text-olive-green rounded-lg !px-3 flex justify-center font-semibold  '>
                                        €5
                                    </BringzzButton>
                                    <BringzzButton
                                        className='bg-sand text-olive-green rounded-lg !px-3 flex justify-center font-semibold  '
                                        onClick={e => {
                                            e.stopPropagation();
                                        }}
                                    >
                                        Other
                                    </BringzzButton>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className='flex items-center space-x-4'>
                    <div>
                        <BringzzIcon
                            folder='LineIcons'
                            icon='IconCheckBoxAlt'
                            size='22'
                            className='p-3 rounded-full' />
                    </div>
                    <div>
                        <BringzzText tag='h3'>Your order</BringzzText>
                    </div>
                </div>
                <div>
                    <div className='-space-y-1'>
                        {products.map((product, index) => {
                            return (
                                <BringzzBasketProduct
                                    key={index + 'product'}
                                    product={product}
                                    mode={"view"}
                                />
                            );
                        })}
                    </div>
                    <div className='py-3'>
                        <div className='flex justify-between'>
                            <BringzzText tag='h4' className="font-normal">Subtotal</BringzzText>
                            <BringzzText tag='h4' className="font-normal">€XX.XX</BringzzText>
                        </div>
                        <div className='flex justify-between'>
                            <div className='flex space-x-1 items-center'>
                                <BringzzText tag='h4' className='font-normal'>Delivery fee & handling</BringzzText>
                                <BringzzIcon
                                    icon="IconQuestionCircle"
                                    folder="LineIcons"
                                    className="h-4 w-4 cursor-pointer text-magic-lilac"
                                    size="16"
                                ></BringzzIcon>
                            </div>
                            <BringzzText tag='h4' className="font-normal">€XX.XX</BringzzText>
                        </div>
                        <div className='flex justify-between'>
                            <BringzzText tag='h4'>Total</BringzzText>
                            <BringzzText tag='h4'>€XX.XX</BringzzText>
                        </div>
                    </div>
                </div>

                <div className='flex space-x-4 border-b border-t py-2'>
                    <div>
                        <BringzzIcon
                            folder='LineIcons'
                            icon='IconEuroCard'
                            size='22'
                            className='p-3 rounded-full' />
                    </div>
                    <div className='flex w-full'>
                        <div>
                            <BringzzText tag='h3'>Paid with</BringzzText>
                            <BringzzText tag='h4' className={"font-normal"}>Mastercard ***0904</BringzzText>
                        </div>
                    </div>
                </div>
                <div className='flex space-x-4 border-b py-2'>
                    <div>
                        <BringzzIcon
                            folder='LineIcons'
                            icon='IconSpeechBubble16'
                            size='22'
                            className='p-3 rounded-full' />
                    </div>
                    <div className='flex w-full'>
                        <div>
                            <BringzzText tag='h3'>Chat history</BringzzText>
                            <div className='flex space-x-2 py-1'>
                                <BringzzButton size='sm' className="bg-magic-lilac">Shopper</BringzzButton>
                                <BringzzButton size='sm' className="bg-magic-lilac">Pharmacy</BringzzButton>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex space-x-4 border-b py-2'>
                    <div>
                        <BringzzIcon
                            folder='LineIcons'
                            icon='IconInvoice1'
                            size='22'
                            className='p-3 rounded-full' />
                    </div>
                    <div className='flex w-full'>
                        <div>
                            <BringzzText tag='h3'>Invoice</BringzzText>
                            <BringzzText tag='h4' className={"font-normal"}>Invoice #2023-123456</BringzzText>
                            <BringzzButton size='sm' className="bg-magic-lilac mt-1">View invoice</BringzzButton>
                        </div>
                    </div>
                </div>
                <BringzzBottomDrawer
                    isOpen={reorderDrawerOpen}
                    close={() => setReorderDrawerOpen(false)}
                    title='Select items'
                >
                    <BringzzMultiSelect
                        onChange={handleMultiSelectChange}
                        state={{ checked: itemState.checked, unChecked: itemState.unChecked }}
                        className="divide-y px-6"
                    >
                        {products.map((product, index) => {
                            return <BringzzCheck mode='custom' key={index} value={product.name}>
                                <BringzzBasketProduct
                                    key={index + 'product'}
                                    product={product}
                                    mode="select"
                                />
                            </BringzzCheck>
                        })}
                    </BringzzMultiSelect>
                    <div className='w-full px-6 py-2'>
                        <BringzzButton disabled={selectedItems.length == 0} size='lg' className='w-full bg-magic-lilac flex justify-center' onClick={() => {
                            setReorderDrawerOpen(false)
                        }}>Add items to cart</BringzzButton>
                    </div>
                </BringzzBottomDrawer>
            </div>
        </BringzzPageContainer >
    );
};

export default ConsumerOrderViewPage;
