import * as React from "react";
const IconWhiteboardAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 23.2h-6.8l-14-15.4c.1-.4.2-.9.2-1.3C36.4 4 34.4 2 32 2s-4.4 2-4.4 4.4c0 .5.1.9.2 1.3l-14 15.4H7c-2.8 0-5 2.2-5 5V57c0 2.8 2.2 5 5 5h50c2.8 0 5-2.2 5-5V28.2c0-2.8-2.3-5-5-5M32 4c1.3 0 2.4 1.1 2.4 2.4S33.3 8.9 32 8.9s-2.4-1.1-2.4-2.4S30.7 4 32 4m-3.1 5.6c.8.8 1.9 1.3 3.1 1.3s2.3-.5 3.1-1.3l12.3 13.6h-31zM60 57c0 1.7-1.3 3-3 3H7c-1.7 0-3-1.3-3-3V28.2c0-1.7 1.3-3 3-3h50c1.7 0 3 1.3 3 3z" />
  </svg>
);
export default IconWhiteboardAlt2;
