import * as React from "react";
const IconQuoteAlt7 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M46.6 29h-.8c-.5 0-.7-.3-.8-.5s-.3-.5 0-.9l1.1-2.2c.5-.9.4-2-.1-2.9s-1.5-1.4-2.5-1.4H42c-1.1 0-2.2.6-2.7 1.6L36 29.2c-.2.4-.3.9-.3 1.3v9.4c0 1.6 1.3 3 3 3h7.9c1.6 0 3-1.3 3-3V32c0-1.6-1.4-3-3-3m1 10.9c0 .5-.4 1-1 1h-7.9c-.5 0-1-.4-1-1v-9.4c0-.1 0-.3.1-.4l3.2-6.4c.2-.3.5-.5.9-.5h1.5c.5 0 .7.3.8.5s.3.5 0 .9l-1.1 2.2c-.5.9-.4 2 .1 2.9s1.5 1.4 2.5 1.4h.8c.5 0 1 .4 1 1v7.8zM25.3 29h-.8c-.5 0-.7-.3-.8-.5s-.3-.5 0-.9l1.1-2.2c.5-.9.4-2-.1-2.9s-1.5-1.4-2.5-1.4h-1.5c-1.1 0-2.2.6-2.7 1.6l-3.2 6.4c-.2.4-.3.9-.3 1.3v9.4c0 1.6 1.3 3 3 3h7.9c1.6 0 3-1.3 3-3V32c-.1-1.6-1.5-3-3.1-3m1 10.9c0 .5-.4 1-1 1h-7.9c-.5 0-1-.4-1-1v-9.4c0-.1 0-.3.1-.4l3.2-6.4c.2-.3.5-.5.9-.5h1.5c.5 0 .7.3.8.5.1.1.3.5 0 .9l-1.1 2.2c-.5.9-.4 2 .1 2.9s1.5 1.4 2.5 1.4h.8c.5 0 1 .4 1 1v7.8z" />
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
  </svg>
);
export default IconQuoteAlt7;
