import * as React from "react";
const IconHome9 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 59.5h-2.7V24.4c0-3-2.4-5.4-5.4-5.4h-3.5c-1.9 0-3.4-1.5-3.4-3.4V7.9c0-1.5-.7-3-1.8-4-1.2-1-2.7-1.5-4.2-1.3L23.4 4.4c-2.7.3-4.8 2.6-4.8 5.4v5.9c0 1.9-1.5 3.4-3.4 3.4h-4.1c-3 0-5.4 2.4-5.4 5.4v35H3c-.6 0-1 .4-1 1s.4 1 1 1h15.4c3 0 5.4-2.4 5.4-5.4V42.4c0-1.9 1.5-3.4 3.4-3.4h9.1c1.9 0 3.4 1.5 3.4 3.4v13.7c0 3 2.4 5.4 5.4 5.4H61c.6 0 1-.4 1-1s-.4-1-1-1m-9.6 0h-6.2c-1.9 0-3.4-1.5-3.4-3.4V42.4c0-3-2.4-5.4-5.4-5.4h-9.1c-3 0-5.4 2.4-5.4 5.4v13.7c0 1.9-1.5 3.4-3.4 3.4H7.7v-35c0-1.9 1.5-3.4 3.4-3.4h4.1c3 0 5.4-2.4 5.4-5.4V9.8c0-1.7 1.3-3.2 3-3.4L40 4.5c1-.1 1.9.2 2.7.8.7.6 1.1 1.6 1.1 2.5v7.7c0 3 2.4 5.4 5.4 5.4h3.5c1.9 0 3.4 1.5 3.4 3.4v35.1h-4.7z" />
  </svg>
);
export default IconHome9;
