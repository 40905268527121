import * as React from "react";
const IconWristwatchAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M39.4 17.8H24.6c-2.2 0-4 1.8-4 4v20.5c0 2.2 1.8 4 4 4h14.8c2.2 0 4-1.8 4-4V21.8c0-2.2-1.8-4-4-4m2 24.4c0 1.1-.9 2-2 2H24.6c-1.1 0-2-.9-2-2V21.8c0-1.1.9-2 2-2h14.8c1.1 0 2 .9 2 2zM27.4 4h8.2c.6 0 1-.4 1-1s-.4-1-1-1h-8.2c-.6 0-1 .4-1 1s.4 1 1 1M24.7 12.1h13.6c.6 0 1-.4 1-1s-.4-1-1-1H24.7c-.6 0-1 .4-1 1s.4 1 1 1M35.6 60h-8.2c-.6 0-1 .4-1 1s.4 1 1 1h8.2c.6 0 1-.4 1-1s-.4-1-1-1M38.3 51.9H24.7c-.6 0-1 .4-1 1s.4 1 1 1h13.6c.6 0 1-.4 1-1s-.4-1-1-1" />
    <path d="M35.9 31.3h-3c-.2 0-.3-.2-.3-.3v-5c0-.6-.4-1-1-1s-1 .4-1 1v4.9c0 1.3 1.1 2.3 2.3 2.3h3c.6 0 1-.4 1-1 0-.5-.5-.9-1-.9" />
  </svg>
);
export default IconWristwatchAlt1;
