import * as React from "react";
const IconDinnerTable = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M18.1 34.1H4V12.9c0-.6-.4-1-1-1s-1 .4-1 1v38.2c0 .6.4 1 1 1s1-.4 1-1v-15h14.1c.5 0 .9.4.9.9v14.1c0 .6.4 1 1 1s1-.4 1-1V37c0-1.6-1.3-2.9-2.9-2.9M61 11.9c-.6 0-1 .4-1 1v21.2H45.9c-1.6 0-2.9 1.3-2.9 2.9v14.1c0 .6.4 1 1 1s1-.4 1-1V37c0-.5.4-.9.9-.9H60v15c0 .6.4 1 1 1s1-.4 1-1V12.9c0-.5-.4-1-1-1M35 50.1h-2V25.8h11c.6 0 1-.4 1-1s-.4-1-1-1H20c-.6 0-1 .4-1 1s.4 1 1 1h11v24.3h-2c-.6 0-1 .4-1 1s.4 1 1 1h6c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconDinnerTable;
