import * as React from "react";
const IconTakaUp = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M28.7 10.8h-.5c-1.8 0-3.2 1.4-3.2 3.2 0 .6.4 1 1 1s1-.4 1-1c0-.7.5-1.2 1.2-1.2h.5c.7 0 1.2.5 1.2 1.2v3.7H27c-.6 0-1 .4-1 1s.4 1 1 1h3v9.1c0 2.8 2.3 5.1 5.2 5.1s5.2-2.3 5.2-5.1c0-2.1-1.2-3.9-3.1-4.7-.5-.2-1.1 0-1.3.5s0 1.1.5 1.3c1.2.5 1.9 1.6 1.9 2.9 0 1.7-1.4 3.1-3.2 3.1S32 30.5 32 28.8v-9.1h6.9c.6 0 1-.4 1-1s-.4-1-1-1H32V14c0-1.8-1.5-3.2-3.3-3.2" />
    <path d="M32 3c-11.3 0-20.4 9.1-20.4 20.4 0 6.2 2.8 11.9 7.6 15.8.2.1.4.2.6.2.3 0 .6-.1.8-.4.3-.4.3-1.1-.2-1.4-4.4-3.5-6.8-8.7-6.8-14.3C13.6 13.2 21.8 5 32 5s18.4 8.2 18.4 18.4c0 .6.4 1 1 1s1-.4 1-1C52.4 12.1 43.3 3 32 3M48.9 40.6h-9.7c-.6 0-1 .4-1 1s.4 1 1 1h7.2l-12.2 8.7c-.4.3-.8.4-1.3.4H21.1c-1.2 0-2.4.5-3.2 1.3l-6.1 6.3c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3l6.1-6.3c.4-.5 1-.7 1.7-.7h11.8c.9 0 1.7-.3 2.5-.8L47.9 44v7.4c0 .6.4 1 1 1s1-.4 1-1v-9.7c0-.6-.4-1.1-1-1.1" />
  </svg>
);
export default IconTakaUp;
