import * as React from "react";
const IconRocketStar = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.5 30.6c0-.3-.1-.5-.3-.7l-6.5-6.5c-.2-.2-.4-.3-.7-.3H42l-9.6-9.6C21.7 2.8 13.9 1.7 7.8 2.1c-.5 0-1 .4-1 .9-.4 6.2.7 13.9 11.4 24.7l9.6 9.6v.7c0 .6.4 1 1 1s1-.4 1-1v-1.1c0-.3-.1-.5-.3-.7l-9.9-9.9C10 16.6 8.6 9.8 8.8 4c5.8-.2 12.6 1.2 22.2 10.9l9.9 9.9c.2.2.4.3.7.3h11l5.5 5.5-22.7 22.8-.6-.6c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l1.3 1.3c.2.2.5.3.7.3s.5-.1.7-.3l7.5-7.5c.3.9.9 1.7 1.6 2.4 2.6 2.6 12 3.9 13 4.1h.1c.3 0 .5-.1.7-.3s.3-.5.3-.8c-.1-1.1-1.5-10.5-4.1-13-.7-.7-1.5-1.2-2.4-1.6l7.5-7.5c.1-.2.2-.4.2-.7m-6.7 11.2c1.4 1.4 2.7 6.7 3.3 10.5-3.8-.6-9.1-1.9-10.5-3.3a5.1 5.1 0 0 1-1.4-2.6l6-6c.9.2 1.8.7 2.6 1.4" />
    <path d="m28.5 46.1-7-2.3-4.4-5.9c-.4-.5-1.2-.5-1.6 0l-4.4 5.9-7 2.3q-.45.15-.6.6c-.15.45 0 .6.1.9l4.3 6L8 61c0 .3.2.6.4.8s.6.3.9.2l7-2.2 7 2.2h.3c.2 0 .4-.1.6-.2.3-.2.4-.5.4-.8l.1-7.4 4.3-6c.2-.3.2-.6.1-.9s-.3-.5-.6-.6m-5.6 6.7c-.1.2-.2.4-.2.6l-.1 6.3-6-1.9q-.3-.15-.6 0l-6 1.9-.1-6.3c0-.2-.1-.4-.2-.6L6 47.6l6-2c.2-.1.4-.2.5-.4l3.8-5.1 3.8 5.1c.1.2.3.3.5.4l6 2z" />
  </svg>
);
export default IconRocketStar;
