import React, { useState } from 'react';
import { BringzzButton, BringzzText } from "@bringzz/components";
import { useNavigation } from 'context/NavigationContext';
import {
	BringzzInput,
	BringzzCountryDropdown,
	BringzzPageContainer,
	BringzzIcon,
	BringzzImage
} from "@bringzz/components";
import { useLocation } from 'react-router-dom';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const RoleSwitcherPage = () => {
	const { backPress } = useNavigation();

	const { navigate } = useNavigation();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	let query = useQuery();
	let phoneNumber = query.get('number');

	return (
		<BringzzPageContainer
			header={{
				left: (
					<BringzzIcon
						size='22'
						icon='IconClose'
						folder='LineIcons'
						className='cursor-pointer'
						onClick={backPress}
					/>
				)
			}}
			authenticated={false}
		>
			<div className='relative flex justify-center bg-magic-lilac/20'>
				<div className='relative bg-white h-screen w-full bg-cover bg-center flex flex-col md:max-w-2xl '>
					{/* Content */}
					<div className='flex-grow flex flex-col justify-between py-8 px-4'>
						{/* Bottom part */}
						<div className='z-10 flex flex-col '>
							<div className='flex flex-col text-center space-y-6 mx-auto md:max-w-lg lg:max-w-xl xl:max-w-2xl'>
								<BringzzText tag='h1' className='text-midnight-blue'>
									What kind of account would you like to open?
								</BringzzText>
								<BringzzText
									tag='h4'
									className='text-midnight-blue/25 font-normal'
								>
									Lorem ipsum dolor sit amet consectetur placerat.
								</BringzzText>
							</div>

							<div className=''>
								<div
									className=' border rounded-md p-4 mt-6 flex gap-x-4 hover:cursor-pointer'
									onClick={() => navigate('/register/pharmacy/')}
								>
									<div>
										<BringzzImage src='/images/default-image.png' />
									</div>
									<div className='grow flex items-center'>
										<div>
											<BringzzText tag='h2' className='font-bold'>
												Pharmacy
											</BringzzText>
											<BringzzText
												tag='h4'
												className='font-regular text-midnight-blue/40'
											>
												Lorem ipsum dolor sit amet consectetur. Tincidunt id.
											</BringzzText>
										</div>

										<div>
											<BringzzIcon
												folder='LineIcons'
												icon='IconArrowRight'
												className='text-midnight-blue'
												size='24'
											></BringzzIcon>
										</div>
									</div>
									<div></div>
								</div>
								<div
									className=' border rounded-md p-4 mt-6 flex gap-x-4 hover:cursor-pointer'
									onClick={() => navigate('/register/shopper/')}
								>
									<div>
										<BringzzImage src='/images/default-image.png' />
									</div>
									<div className='grow flex items-center'>
										<div>
											<BringzzText tag='h2' className='font-bold'>
												Shopper
											</BringzzText>
											<BringzzText
												tag='h4'
												className='font-regular text-midnight-blue/40'
											>
												Lorem ipsum dolor sit amet consectetur. Tincidunt id.
											</BringzzText>
										</div>

										<div>
											<BringzzIcon
												folder='LineIcons'
												icon='IconArrowRight'
												className='text-midnight-blue'
												size='24'
											></BringzzIcon>
										</div>
									</div>
									<div></div>
								</div>
								<div
									className=' border rounded-md p-4 mt-6 flex gap-x-4 hover:cursor-pointer'
									onClick={() => navigate('/register/industry/')}
								>
									<div>
										<BringzzImage src='/images/default-image.png' />
									</div>
									<div className='grow flex items-center'>
										<div>
											<BringzzText tag='h2' className='font-bold'>
												Industry
											</BringzzText>
											<BringzzText
												tag='h4'
												className='font-regular text-midnight-blue/40'
											>
												Lorem ipsum dolor sit amet consectetur. Tincidunt id.
											</BringzzText>
										</div>

										<div>
											<BringzzIcon
												folder='LineIcons'
												icon='IconArrowRight'
												className='text-midnight-blue'
												size='24'
											></BringzzIcon>
										</div>
									</div>
									<div></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</BringzzPageContainer>
	);
};

export default RoleSwitcherPage;
