import * as React from "react";
const IconJuiceCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.6 16.5c-.7-.8-1.8-1.3-2.8-1.3H33.3l4.3-10.8c.2-.2.4-.4.7-.4h9.5c.6 0 1-.4 1-1s-.4-1-1-1h-9.5c-1.1 0-2.1.7-2.4 1.7l-4.6 11.5H16.2c-1.1 0-2.1.5-2.8 1.3s-1.1 1.9-.9 3l4.1 36.7c.4 3.4 3.2 5.9 6.6 5.9h17.7c3.4 0 6.2-2.5 6.6-5.9l4.1-36.7c.1-1.1-.3-2.2-1-3m-5.2 39.4c-.3 2.3-2.2 4.1-4.6 4.1H23.2c-2.4 0-4.3-1.8-4.6-4.1l-2.3-20.6 4.1-2.1c2.2-1.2 4.9-1.2 7.1-.2l.6.3c2.6 1.3 5.6 1.3 8.3.1l1.2-.6c2.2-1 4.7-.9 6.9.2l3.4 1.8zm4.2-36.7L48 32.7l-2.7-1.4c-2.7-1.4-5.8-1.5-8.6-.3l-1.2.6c-2.1.9-4.5.9-6.6-.1l-.6-.3c-2.8-1.4-6.2-1.3-8.9.2L16 33.1l-1.6-13.9c-.1-.5.1-1 .4-1.4s.8-.6 1.4-.6h31.5c.5 0 1 .2 1.4.6s.5.9.5 1.4" />
  </svg>
);
export default IconJuiceCopy;
