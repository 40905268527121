import * as React from "react";
const IconFlute = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m32.4 30.2-.9.9c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l.9-.9c.4-.4.4-1 0-1.4s-1-.4-1.4 0M15 47.6l-2.7 2.7c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l2.7-2.7c.4-.4.4-1 0-1.4s-1-.4-1.4 0M37.8 24.8l-.9.9c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l.9-.9c.4-.4.4-1 0-1.4s-1-.4-1.4 0" />
    <path d="M58.9 5.1c-2.8-2.8-7.5-2.8-10.3 0L5.1 48.6c-2.8 2.8-2.8 7.5 0 10.3 1.4 1.4 3.3 2.1 5.2 2.1s3.7-.7 5.2-2.1l43.4-43.4c2.8-2.9 2.8-7.5 0-10.4M57.5 14 14 57.5c-2.1 2.1-5.4 2.1-7.5 0-1-1-1.5-2.3-1.5-3.7S5.5 51 6.5 50L50 6.5c1-1 2.3-1.5 3.7-1.5s2.7.5 3.7 1.5c2.1 2.1 2.1 5.5.1 7.5" />
    <path d="m48.7 13.9-.9.9c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l.9-.9c.4-.4.4-1 0-1.4s-1-.4-1.4 0M43.3 19.3l-.9.9c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l.9-.9c.4-.4.4-1 0-1.4s-1.1-.4-1.4 0" />
  </svg>
);
export default IconFlute;
