import * as React from "react";
const IconUserKey = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.3 32.5c-.5-.2-1.1 0-1.3.5l-1.1 2.2-3.4-1.6c-.5-.2-1.1 0-1.3.5s0 1.1.5 1.3l3.4 1.6-4.5 9.3c-1.6-.5-3.4-.5-5 0-2 .7-3.5 2.1-4.4 4s-1 4-.3 6 2.1 3.5 4 4.4c1.1.5 2.2.8 3.4.8.9 0 1.7-.1 2.6-.4 2-.7 3.5-2.1 4.4-4s1-4 .3-6c-.6-1.6-1.7-3-3.1-3.9l6.4-13.3c.1-.5-.1-1.1-.6-1.4m-4.7 19.2c.5 1.5.4 3-.2 4.4-.7 1.4-1.8 2.4-3.3 2.9s-3 .4-4.4-.2q-2.1-1.05-3-3.3c-.5-1.5-.4-3 .2-4.4.7-1.4 1.8-2.4 3.3-2.9.6-.2 1.3-.3 1.9-.3.9 0 1.7.2 2.5.6 1.5.6 2.5 1.8 3 3.2M32.8 59.7c-1.7.2-3.3.3-4.9.3-9.5 0-18.5-3.8-21.8-5.4.3-7.2 2.5-13.7 6.5-18.3 4-4.8 9.4-7.4 15.3-7.4 5.8 0 11.3 2.6 15.3 7.4.4.4 1 .5 1.4.1s.5-1 .1-1.4c-4.4-5.2-10.4-8.1-16.8-8.1S15.5 29.8 11.1 35s-6.8 12.3-7 20.2q0 .6.6.9C7.5 57.5 17.4 62 27.9 62q2.55 0 5.1-.3c.5-.1.9-.6.9-1.1 0-.6-.6-1-1.1-.9M27.9 26.8c6.8 0 12.4-5.6 12.4-12.4S34.7 2 27.9 2 15.5 7.6 15.5 14.4s5.6 12.4 12.4 12.4m0-22.8c5.7 0 10.4 4.7 10.4 10.4s-4.7 10.4-10.4 10.4-10.4-4.7-10.4-10.4S22.2 4 27.9 4" />
  </svg>
);
export default IconUserKey;
