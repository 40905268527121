import * as React from "react";
const IconShoppingBag13 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.7 2H9.3C5.3 2 2 5.3 2 9.3v37.1C2 55 9 62 17.6 62h28.7C55 62 62 55 62 46.4V9.3c0-4-3.3-7.3-7.3-7.3M60 46.4C60 53.9 53.9 60 46.4 60H17.6C10.1 60 4 53.9 4 46.4V9.3C4 6.4 6.4 4 9.3 4h45.5C57.6 4 60 6.4 60 9.3z" />
    <path d="M44.5 14c-.6 0-1 .4-1 1v10.4C43.5 31.8 38.4 37 32 37s-11.5-5.2-11.5-11.5V15c0-.6-.4-1-1-1s-1 .4-1 1v10.4c0 7.5 6 13.6 13.5 13.6s13.5-6.1 13.5-13.5V15c0-.6-.4-1-1-1" />
  </svg>
);
export default IconShoppingBag13;
