import * as React from "react";
const IconSuitcase = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 17.8H44.1c0-5.8-4.6-10.5-10.2-10.5H30c-5.6 0-10.1 4.7-10.2 10.5H7c-2.8 0-5 2.2-5 5v28.9c0 2.8 2.2 5 5 5h50c2.8 0 5-2.2 5-5V22.8c0-2.7-2.2-5-5-5m-32.3 2v34.9h-7.8V19.8zm2 0h10.4v34.9H26.7zm12.4 0H47v34.9h-7.9zm-9-10.5H34c4.5 0 8.1 3.8 8.2 8.5H21.9c0-4.7 3.7-8.5 8.2-8.5M4 51.7V22.8c0-1.7 1.3-3 3-3h7.9v34.9H7c-1.7 0-3-1.3-3-3m56 0c0 1.7-1.3 3-3 3h-7.9V19.8H57c1.7 0 3 1.3 3 3z" />
  </svg>
);
export default IconSuitcase;
