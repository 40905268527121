import * as React from "react";
const IconDna = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M35 9.23V6h-2v3H15V6h-2v3.23a16.91 16.91 0 0 0 6.804 13.558 19 19 0 0 1 1.95-1.077A14.9 14.9 0 0 1 17.185 17h13.63A14.9 14.9 0 0 1 24 22.917l-.016.007q-.177.075-.355.148-1.014.406-1.951.928a16.91 16.91 0 0 0-8.586 13H13v5h2v-3h18v3h2v-5h-.093a16.92 16.92 0 0 0-6.71-11.788 19 19 0 0 1-1.95 1.077A14.9 14.9 0 0 1 30.814 31h-13.63A14.9 14.9 0 0 1 24 25.083l.016-.007q.177-.075.355-.148A16.91 16.91 0 0 0 35 9.23M31.839 33H16.162a14.9 14.9 0 0 0-1.057 4h17.79a14.9 14.9 0 0 0-1.057-4M16.162 15h15.677c.53-1.264.89-2.608 1.056-4h-17.79a14.9 14.9 0 0 0 1.056 4"
      clipRule="evenodd"
    />
  </svg>
);
export default IconDna;
