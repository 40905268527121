import * as React from "react";
const IconSx = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 85.333 512 341.333"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 85.337h512v341.326H0z" />
    <path  d="M512 85.331v166.69L0 256.173V85.331z" />
    <path  d="M256 256.006 0 426.668V85.331z" />
    <g >
      <path d="M59.621 256a60 60 0 0 0-.193 4.57c0 32.821 26.607 59.429 59.429 59.429s59.429-26.607 59.429-59.429c0-1.539-.078-3.061-.193-4.57z" />
      <circle cx={118.862} cy={210.287} r={18.286} />
    </g>
    <path
      
      d="M77.715 205.714v59.429c0 31.494 41.144 41.143 41.144 41.143s41.144-9.649 41.144-41.143v-59.429z"
    />
    <path
      
      d="M118.877 287.148c-7.632-2.746-22.876-9.767-22.876-22.006v-41.144h45.715v41.144c-.001 12.28-15.244 19.283-22.839 22.006"
    />
    <path
      
      d="M128.001 246.856v-9.142l-9.144-4.571-9.142 4.571v9.142l-4.571 4.573v18.285h27.428v-18.285z"
    />
  </svg>
);
export default IconSx;
