import * as React from "react";
const IconServer6 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62 22.8V9.6c0-2.8-2.2-5-5-5H7c-2.8 0-5 2.2-5 5v13.2c0 1.6.8 3.1 2 4-1.2.9-2 2.4-2 4V44c0 2.8 2.2 5 5 5h35.3c.6 0 1-.4 1-1s-.4-1-1-1H7c-1.7 0-3-1.3-3-3V30.8c0-1.7 1.3-3 3-3h50c1.7 0 3 1.3 3 3v9.6c0 .6.4 1 1 1s1-.4 1-1v-9.6c0-1.6-.8-3.1-2-4 1.2-.9 2-2.4 2-4m-2 0c0 1.7-1.3 3-3 3H7c-1.7 0-3-1.3-3-3V9.6c0-1.7 1.3-3 3-3h50c1.7 0 3 1.3 3 3z" />
    <path d="M15.6 15c-.6 0-1 .4-1 1v.2c0 .6.4 1 1 1s1-.4 1-1V16c0-.6-.5-1-1-1M50.8 15.1H44c-.6 0-1 .4-1 1s.4 1 1 1h6.7c.6 0 1-.4 1-1s-.4-1-.9-1M14.6 37.1v.2c0 .6.4 1 1 1s1-.4 1-1v-.2c0-.6-.4-1-1-1s-1 .5-1 1M51.8 37.1c0-.6-.4-1-1-1H44c-.6 0-1 .4-1 1s.4 1 1 1h6.7c.6 0 1.1-.4 1.1-1M54.5 43.9c-4 0-7.3 3.3-7.3 7.3s3.3 7.3 7.3 7.3 7.3-3.3 7.3-7.3c0-4.1-3.2-7.3-7.3-7.3m0 12.6c-2.9 0-5.3-2.4-5.3-5.3s2.4-5.3 5.3-5.3 5.3 2.4 5.3 5.3-2.3 5.3-5.3 5.3" />
    <path d="M56 50.3h-2.6c-.6 0-1 .4-1 1s.4 1 1 1H56c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconServer6;
