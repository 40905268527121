import * as React from "react";
const IconBot = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M27 35.2h-5.6c-.6 0-1 .4-1 1s.4 1 1 1H27c.6 0 1-.4 1-1s-.5-1-1-1M42.6 35.2H37c-.6 0-1 .4-1 1s.4 1 1 1h5.6c.6 0 1-.4 1-1s-.5-1-1-1" />
    <path d="M56.5 29.2V14.7c.4-.1.8-.5.8-.9 0-.6-.4-1-1-1h-1.5c-.6 0-1 .4-1 1 0 .5.3.8.8.9V29H51c-.6-4.4-4.4-7.8-8.9-7.8H22.2c-4.6 0-8.3 3.4-8.9 7.8H9c-3.9 0-7 3.1-7 7v.4c0 3.9 3.1 7 7 7h4.2c.6 4.4 4.4 7.8 8.9 7.8H42c4.6 0 8.3-3.4 8.9-7.8H55c3.9 0 7-3.1 7-7V36c0-3.3-2.4-6.1-5.5-6.8M4 36.4V36c0-2.8 2.2-5 5-5h4.1v10.4H9c-2.8 0-5-2.2-5-5m45 5.9c0 3.9-3.1 7-7 7H22.2c-3.9 0-7-3.1-7-7V30.2c0-3.9 3.1-7 7-7H42c3.9 0 7 3.1 7 7zm11-5.9c0 2.8-2.2 5-5 5h-4V31h4c2.8 0 5 2.2 5 5z" />
  </svg>
);
export default IconBot;
