import * as React from "react";
const IconArrowExpandAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M35.7 28.8c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4L57.1 5H43.4c-.6 0-1-.4-1-1s.4-1 1-1h16.2c.6 0 1 .4 1 1v15.3c0 .6-.4 1-1 1s-1-.4-1-1V6.4L36.4 28.5c-.1.2-.4.3-.7.3M20.6 61H4.4c-.6 0-1-.4-1-1V44.7c0-.6.4-1 1-1s1 .4 1 1v12.8l22.1-22.1c.4-.4 1-.4 1.4 0s.4 1 0 1.4L6.9 59h13.7c.6 0 1 .4 1 1s-.4 1-1 1" />
  </svg>
);
export default IconArrowExpandAlt;
