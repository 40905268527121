import * as React from "react";
const IconHouseAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 51h-3.9V15.9c0-2.7-2.2-4.9-4.9-4.9H24.9c-2.7 0-4.9 2.2-4.9 4.9v10.7h-9c-2.2 0-4 1.8-4 4V51H3c-.6 0-1 .4-1 1s.4 1 1 1h58c.6 0 1-.4 1-1s-.4-1-1-1m-40 0H9V30.6c0-1.1.9-2 2-2h23.2c1.1 0 2 .9 2 2V51zm17.3 0V30.6c0-2.2-1.8-4-4-4H22V15.9c0-1.6 1.3-2.9 2.9-2.9h27.3c1.6 0 2.9 1.3 2.9 2.9V51z" />
  </svg>
);
export default IconHouseAlt2;
