import * as React from "react";
const IconMedicineBottole = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m48.6 22-6.9-7.6V6c0-2.2-1.8-4-4-4H26.3c-2.2 0-4 1.8-4 4v8.4L15.4 22c-2 2.2-3.1 5-3.1 8v28.3c0 2.1 1.7 3.7 3.7 3.7h32c2.1 0 3.7-1.7 3.7-3.7V30c0-3-1.1-5.8-3.1-8M24.3 6c0-1.1.9-2 2-2h11.4c1.1 0 2 .9 2 2v7.8H24.3zm-10 31.8H29c1.1 0 2 .9 2 2v10.9c0 1.1-.9 2-2 2H14.3zm35.4 20.5c0 1-.8 1.7-1.7 1.7H16c-1 0-1.7-.8-1.7-1.7v-3.5H29c2.2 0 4-1.8 4-4v-11c0-2.2-1.8-4-4-4H14.3V30c0-2.5.9-4.8 2.6-6.6l6.9-7.5h16.5l6.9 7.5c1.7 1.8 2.6 4.2 2.6 6.6v28.3z" />
  </svg>
);
export default IconMedicineBottole;
