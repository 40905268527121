import * as React from "react";
const IconVectorNode1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 27c-2.4 0-4.4 1.7-4.9 4H38.7l-6-6c-.4-.4-1-.4-1.4 0l-6 6H11.9c-.5-2.3-2.5-4-4.9-4-2.8 0-5 2.2-5 5s2.2 5 5 5c2.4 0 4.4-1.7 4.9-4h13.4l6 6c.2.2.5.3.7.3s.5-.1.7-.3l6-6h13.4c.5 2.3 2.5 4 4.9 4 2.8 0 5-2.2 5-5s-2.2-5-5-5M7 35c-1.6 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.4 3-3 3m25 1.9L27.1 32l4.9-4.9 4.9 4.9zM57 35c-1.6 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3" />
  </svg>
);
export default IconVectorNode1;
