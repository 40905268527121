import * as React from "react";
const IconMedicalSignAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M35.8 62h-7.6c-2.2 0-4-1.8-4-4v-8.9c0-.7-.4-1.4-1-1.8s-1.4-.4-2 0l-7.7 4.4c-1.9 1.1-4.4.4-5.5-1.5l-3.8-6.6c-.5-.9-.7-2-.4-3.1s.9-1.9 1.9-2.4l7.7-4.4c.6-.4 1-1 1-1.8 0-.7-.4-1.4-1-1.8l-7.7-4.4c-.9-.5-1.6-1.4-1.9-2.4s-.1-2.1.4-3.1L8 13.6c1.1-1.9 3.6-2.6 5.5-1.5l7.7 4.4c.6.4 1.4.4 2 0s1-1 1-1.8V6c0-2.2 1.8-4 4-4h7.6c2.2 0 4 1.8 4 4v8.9c0 .7.4 1.4 1 1.8s1.4.4 2 0l7.7-4.4c.9-.5 2-.7 3.1-.4 1 .3 1.9.9 2.4 1.9l3.8 6.6c.5.9.7 2 .4 3.1s-.9 1.9-1.9 2.4l-7.7 4.4c-.6.4-1 1-1 1.8 0 .7.4 1.4 1 1.8l7.7 4.4c.9.5 1.6 1.4 1.9 2.4s.1 2.1-.4 3.1L56 50.4c-.5.9-1.4 1.6-2.4 1.9s-2.1.1-3.1-.4l-7.7-4.4c-.6-.4-1.4-.4-2 0s-1 1-1 1.8V58c0 2.2-1.8 4-4 4M22.1 45.1c.7 0 1.4.2 2 .5 1.3.7 2 2 2 3.5V58c0 1.1.9 2 2 2h7.6c1.1 0 2-.9 2-2v-8.9c0-1.5.8-2.8 2-3.5 1.3-.7 2.8-.7 4 0l7.7 4.4c.5.3 1 .3 1.5.2s1-.5 1.2-.9l3.8-6.6c.3-.5.3-1 .2-1.5s-.5-1-.9-1.2l-7.7-4.4c-1.3-.7-2-2-2-3.5s.8-2.8 2-3.5l7.7-4.4q.75-.45.9-1.2c.1-.5.1-1.1-.2-1.5l-3.8-6.6q-.45-.75-1.2-.9c-.5-.1-1.1-.1-1.5.2l-7.7 4.4c-1.3.7-2.8.7-4 0-1.3-.7-2-2-2-3.5V6c0-1.1-.9-2-2-2h-7.6c-1.1 0-2 .9-2 2v8.9c0 1.5-.8 2.8-2 3.5-1.3.7-2.8.7-4 0L12.4 14c-1-.6-2.2-.2-2.8.7l-3.8 6.6c-.3.5-.3 1-.2 1.5s.5 1 .9 1.2l7.7 4.4c1.3.7 2 2 2 3.5s-.8 2.8-2 3.5l-7.7 4.4q-.75.45-.9 1.2c-.1.5-.1 1.1.2 1.5l3.8 6.6c.6 1 1.8 1.3 2.8.7l7.7-4.4c.6-.2 1.3-.3 2-.3" />
  </svg>
);
export default IconMedicalSignAlt;
