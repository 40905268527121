import * as React from "react";
const IconIdeaRise = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M26.1 26.8c.6.4 1 1.2 1 2v1.9c0 2.4 1.9 4.3 4.3 4.3h1c2.4 0 4.3-1.9 4.3-4.3v-2c0-.4.1-.8.3-1.1s.4-.6.7-.8c3.2-2 5.1-5.4 5.2-9.1.1-2.9-1-5.7-3.1-7.9S35 6.4 32.1 6.3c-3-.1-5.9 1-8 3.1-2.2 2.1-3.3 4.9-3.3 7.9.1 3.9 2.1 7.4 5.3 9.5m8.7 3.9c0 1.3-1 2.3-2.3 2.3h-1c-1.3 0-2.3-1-2.3-2.3v-2.5H35v2.5zm-9.2-19.8c1.7-1.7 4-2.6 6.4-2.6h.2c2.4.1 4.6 1.1 6.3 2.9s2.6 4.1 2.5 6.5c-.1 3-1.7 5.8-4.3 7.5-.5.3-.8.7-1.2 1.1h-7.3c-.3-.4-.7-.8-1.1-1.1-2.7-1.7-4.3-4.6-4.3-7.7.1-2.6 1.1-4.9 2.8-6.6M62 37.6v-.2c0-.1 0-.1-.1-.2 0-.1 0-.1-.1-.2l-.1-.1c0-.1-.1-.1-.2-.1s-.1-.1-.2-.1l-.1-.1-10.3-2.7c-.5-.1-1.1.2-1.2.7s.2 1.1.7 1.2l8.2 2.1-21.8 15.7c-1.5 1-3.4.8-4.6-.5l-7.1-8.2c-1.9-2.2-5.2-2.5-7.5-.7L2.4 55.9c-.4.3-.5 1-.2 1.4.2.3.5.4.8.4.2 0 .4-.1.6-.2l15.2-11.8c1.4-1.1 3.5-.9 4.7.5l7.1 8.2c1.9 2.1 5 2.5 7.3.9L59.3 40l-2 7.5c-.1.5.2 1.1.7 1.2h.3c.4 0 .8-.3 1-.7L62 37.8z" />
  </svg>
);
export default IconIdeaRise;
