import * as React from "react";
const IconDollarCoinStack = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 42.1c7.9 0 13.7-3.2 13.7-7.6s-5.8-7.6-13.7-7.6-13.7 3.2-13.7 7.6 5.8 7.6 13.7 7.6M32 29c6.6 0 11.7 2.4 11.7 5.6 0 3.1-5.1 5.6-11.7 5.6s-11.7-2.4-11.7-5.6S25.4 29 32 29M44.8 44.9c-.5-.2-1.1.1-1.3.6-.5 1.3-1.9 2.5-4 3.3S34.8 50 32 50c-5.5 0-10.2-1.8-11.4-4.3-.2-.5-.8-.7-1.3-.5s-.7.8-.5 1.3C20.3 49.9 25.5 52 32 52c3 0 5.9-.5 8.2-1.4 2.7-1.1 4.5-2.6 5.2-4.5.2-.5-.1-1-.6-1.2M44.8 54.8c-.5-.2-1.1.1-1.3.6-.5 1.3-1.9 2.5-4 3.3-2.1.9-4.7 1.3-7.5 1.3-5.5 0-10.2-1.8-11.4-4.3-.2-.5-.8-.7-1.3-.5s-.7.8-.5 1.3C20.3 59.9 25.5 62 32 62c3 0 5.9-.5 8.2-1.4 2.7-1.1 4.5-2.6 5.2-4.5.2-.5-.1-1.1-.6-1.3M33.5 18.6h-6.4c-.6 0-1 .4-1 1s.4 1 1 1H32v1.9c0 .6.4 1 1 1s1-.4 1-1v-2c2.2-.3 3.9-2.1 3.9-4.4 0-2.4-2-4.4-4.4-4.4h-2.9c-1.3 0-2.4-1.1-2.4-2.4s1.1-2.4 2.4-2.4H37c.6 0 1-.4 1-1s-.4-1-1-1h-3V3c0-.6-.4-1-1-1s-1 .4-1 1v1.9h-1.5c-2.4 0-4.4 2-4.4 4.4s2 4.4 4.4 4.4h2.9c1.3 0 2.4 1.1 2.4 2.4s-1 2.5-2.3 2.5" />
  </svg>
);
export default IconDollarCoinStack;
