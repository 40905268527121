import * as React from "react";
const IconGalleryCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M23.1 39.8c2.7 0 4.9-2.2 4.9-4.9S25.8 30 23.1 30s-4.9 2.2-4.9 4.9 2.2 4.9 4.9 4.9m0-7.8c1.6 0 2.9 1.3 2.9 2.9s-1.3 2.9-2.9 2.9-2.9-1.3-2.9-2.9 1.3-2.9 2.9-2.9M49.2 20.2c2.7 0 4.9-2.2 4.9-4.9s-2.2-4.9-4.9-4.9-4.9 2.2-4.9 4.9 2.2 4.9 4.9 4.9m0-7.8c1.6 0 2.9 1.3 2.9 2.9s-1.3 2.9-2.9 2.9-2.9-1.3-2.9-2.9 1.3-2.9 2.9-2.9" />
    <path d="M57 2.2H33.5c-2.8 0-5 2.2-5 5v15.6h-21c-2.8 0-5 2.2-5 5v29c0 2.8 2.2 5 5 5H31c2.8 0 5-2.2 5-5V41.2h21c2.8 0 5-2.2 5-5v-29c0-2.7-2.2-5-5-5m-26.5 5c0-1.7 1.3-3 3-3H57c1.7 0 3 1.3 3 3v25.6l-9.8-6.9c-.4-.3-1-.2-1.3.1l-5.1 5.6-7.6-5.6c-.2-.1-.4-.2-.6-.2-.8-1.7-2.5-3-4.6-3h-.5zm-23 17.6H31c1.7 0 3 1.3 3 3v22.6L26.2 45c-.4-.3-1-.2-1.3.1l-5.1 5.6-7.6-5.7c-.4-.3-.9-.3-1.2 0l-6.5 5.4V27.8c0-1.7 1.3-3 3-3m-3 32V53l7.1-6 16.9 12.7h-21c-1.7.1-3-1.3-3-2.9m27.2 2.9c-.1 0-.1-.1 0 0l-10.3-7.8 4.4-4.8 8.2 5.7v4c0 1.4-1 2.6-2.3 2.9M36 28.3l14.5 10.9H36zm21 10.9h-3.3l-8.4-6.3 4.4-4.8L60 35.2v1c0 1.7-1.3 3-3 3" />
  </svg>
);
export default IconGalleryCopy;
