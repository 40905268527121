import * as React from "react";
const IconWebcam = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.3 27.3C57.3 13.3 45.9 2 32 2S6.7 13.3 6.7 27.3c0 10.6 6.6 19.7 15.8 23.4-.1.1-.2.3-.3.5l-1.7 9.6c-.1.3 0 .6.2.8s.5.4.8.4H43c.3 0 .6-.1.8-.4.2-.2.3-.5.2-.8l-1.7-9.6c-.1-.3-.2-.5-.5-.7 9.1-3.8 15.5-12.8 15.5-23.2M32 4c12.5 0 22.7 9.9 23.2 22.3H43q-.6 0-.9.6c-.2-5.4-4.7-9.7-10.1-9.7-5.3 0-9.6 4-10.1 9.2-.1 0-.2-.1-.3-.1H8.8C9.3 13.9 19.5 4 32 4m8.1 23.3c0 4.5-3.6 8.1-8.1 8.1s-8.1-3.6-8.1-8.1 3.6-8.1 8.1-8.1c4.5-.1 8.1 3.6 8.1 8.1M41.8 60h-19l1.5-8.4v-.2c2.4.8 5 1.2 7.7 1.2 2.9 0 5.8-.5 8.4-1.4 0 .1-.1.3 0 .5zM32 50.5c-12.5 0-22.7-9.9-23.2-22.3h12.9c.1 0 .2 0 .3-.1.5 5.1 4.8 9.2 10.1 9.2 5.4 0 9.9-4.3 10.1-9.7.2.3.5.6.9.6h12.2C54.7 40.6 44.5 50.5 32 50.5" />
  </svg>
);
export default IconWebcam;
