import * as React from "react";
const IconFileDownload = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.1 12.3H32.3l-2.5-4.7C28.9 6 27.3 5 25.5 5H7.9C5.2 5 3 7.2 3 9.9v44.2C3 56.8 5.2 59 7.9 59h48.3c2.7 0 4.9-2.2 4.9-4.9V17.2c-.1-2.7-2.3-4.9-5-4.9M59 54.1c0 1.6-1.3 2.9-2.9 2.9H7.9C6.3 57 5 55.7 5 54.1V9.9C5 8.3 6.3 7 7.9 7h17.6c1.1 0 2 .6 2.5 1.5l2.8 5.2c.2.3.5.5.9.5h24.5c1.6 0 2.9 1.3 2.9 2.9v37z" />
    <path d="m40.1 36.7-7 6.9V26c0-.6-.4-1-1-1s-1 .4-1 1v17.6l-7.1-7c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l8.8 8.6c.2.2.4.3.7.3s.5-.1.7-.3l8.8-8.6c.4-.4.4-1 0-1.4-.5-.3-1.1-.3-1.5.1" />
  </svg>
);
export default IconFileDownload;
