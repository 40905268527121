import * as React from "react";
const IconDeathAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M17.382 6.001 13.07 17.184l4.574 24.8 12.673.014 4.614-24.796-4.294-11.19zm14.629-1.988L16.01 4 11 16.992l4.978 26.99 16 .018L37 17.013z"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M23 17.997V26h2v-8.003h3v-2h-3V13h-2v2.997h-3v2z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconDeathAlt2;
