import * as React from "react";
const IconCastleAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 19.6h-2.4v-8.2c0-1.5-.7-3-1.9-3.9l-3.9-3.2c-1.9-1.5-4.5-1.5-6.4 0l-3.9 3.2c-1.2 1-1.9 2.4-1.9 3.9v8.2H26.4v-8.2c0-1.5-.7-3-1.9-3.9l-3.9-3.2c-1.9-1.5-4.5-1.5-6.4 0l-3.9 3.2c-1.2 1-1.9 2.4-1.9 3.9v8.2H6c-2.2 0-4 1.8-4 4v33.2c0 2.2 1.8 4 4 4h14.3c2.2 0 4-1.8 4-4V46.1c0-3 2.4-5.4 5.4-5.4h4.6c3 0 5.4 2.4 5.4 5.4v10.8c0 2.2 1.8 4 4 4H58c2.2 0 4-1.8 4-4V23.6c0-2.2-1.8-4-4-4m-18.4-8.1c0-.9.4-1.8 1.1-2.4l3.9-3.2c1.1-.9 2.8-.9 3.9 0l3.9 3.2c.7.6 1.1 1.5 1.1 2.4v8.2h-14v-8.2zm-29.2 0c0-.9.4-1.8 1.1-2.4l3.9-3.2c1.1-.9 2.8-.9 3.9 0l3.9 3.2c.7.6 1.1 1.5 1.1 2.4v8.2h-14v-8.2zM60 56.8c0 1.1-.9 2-2 2H43.7c-1.1 0-2-.9-2-2V46.1c0-4.1-3.3-7.4-7.4-7.4h-4.6c-4.1 0-7.4 3.3-7.4 7.4v10.8c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V23.6c0-1.1.9-2 2-2h52c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconCastleAlt;
