import * as React from "react";
const IconPassport2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M25 30.4c4.9 0 8.9-4 8.9-8.9s-4-8.9-8.9-8.9-8.9 4-8.9 8.9 4 8.9 8.9 8.9m0-15.8c3.8 0 6.9 3.1 6.9 6.9s-3.1 6.9-6.9 6.9-6.9-3.1-6.9-6.9 3.1-6.9 6.9-6.9M30.2 46.8H19.7c-.6 0-1 .4-1 1s.4 1 1 1h10.5c.6 0 1-.4 1-1s-.4-1-1-1M32.9 39.2H17c-.6 0-1 .4-1 1s.4 1 1 1h15.8c.6 0 1-.4 1-1s-.4-1-.9-1" />
    <path d="M53.3 2H7.1c-1.7 0-3 1.3-3 3v54c0 1.7 1.3 3 3 3h46.1c3.7 0 6.6-3 6.6-6.6V8.6C59.9 5 56.9 2 53.3 2M36.1 60h-29c-.6 0-1-.4-1-1V5c0-.6.4-1 1-1h33.7c1.7 0 3 1.3 3 3v50c0 1.7-1.3 3-3 3zm21.8-4.6c0 2.6-2.1 4.6-4.6 4.6h-8.5c.6-.8 1-1.9 1-3V7c0-1.1-.4-2.2-1-3h8.5c2.6 0 4.6 2.1 4.6 4.6z" />
  </svg>
);
export default IconPassport2;
