import * as React from "react";
const IconArrowRightCircle = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M35.2 20.5c-.9-.9-2.3-.9-3.2 0s-.9 2.3 0 3.2l6 6.1H20.7c-1.2 0-2.2 1-2.2 2.2s1 2.2 2.2 2.2H38l-6 6.1c-.9.9-.9 2.3 0 3.2.4.4 1 .6 1.6.6s1.2-.2 1.6-.7l9.8-9.9c.9-.9.9-2.3 0-3.2z" />
    <path d="M32 1.8C15.3 1.8 1.7 15.3 1.7 32S15.3 62.2 32 62.2 62.3 48.7 62.3 32 48.7 1.8 32 1.8m0 56C17.8 57.8 6.2 46.2 6.2 32S17.8 6.2 32 6.2 57.8 17.8 57.8 32 46.2 57.8 32 57.8" />
  </svg>
);
export default IconArrowRightCircle;
