import * as React from "react";
const IconSpeechBubble42 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62 17.8c0-7.1-6.5-12.9-14.6-12.9-6.5 0-12.2 3.8-14 9.3-8.1-1.9-16.7-.2-23.1 4.6C5 22.9 2 28.6 2 34.8c0 5 2 9.8 5.7 13.7-.6 2-2.1 3.6-4 4.5-1 .4-1.6 1.4-1.6 2.5s.6 2 1.6 2.5q2.55 1.2 5.4 1.2c3.5 0 7-1.4 9.4-4.1 9.6 3 20.1.6 26.9-6.1 5.3-5.2 7.3-11.9 5.6-18.6 6.5-1.5 11-6.6 11-12.6M49.6 28.6c-.3 0-.5.2-.7.5s-.2.5-.1.8c2 6.3.2 12.7-4.8 17.7-6.4 6.3-16.4 8.4-25.5 5.4-.4-.1-.8 0-1.1.3-3.2 3.7-8.5 4.9-12.9 2.9-.4-.2-.4-.5-.4-.6s0-.5.4-.6c2.7-1.2 4.6-3.5 5.3-6.3.1-.3 0-.7-.3-1C6 44 4 39.5 4 34.8c0-5.6 2.7-10.7 7.5-14.4 4.3-3.2 9.8-5 15.3-5 2.3 0 4.7.3 7 .9.3.1.5 0 .8-.1.2-.1.4-.4.5-.6 1.2-5 6.4-8.7 12.3-8.7 6.9 0 12.6 4.9 12.6 10.9 0 5.3-4.4 9.8-10.4 10.8" />
    <path d="M16.1 32.1h-.4c-1.1 0-2 .9-2 2s.9 2 2 2h.4c1.1 0 2-.9 2-2s-.9-2-2-2M27.1 32.1h-.4c-1.1 0-2 .9-2 2s.9 2 2 2h.4c1.1 0 2-.9 2-2s-.9-2-2-2M38.2 32.1h-.4c-1.1 0-2 .9-2 2s.9 2 2 2h.4c1.1 0 2-.9 2-2s-.9-2-2-2" />
  </svg>
);
export default IconSpeechBubble42;
