import * as React from "react";
const IconBadge8 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m60.8 28-2.1-3.2q-.75-1.2-.9-2.7l-.2-3.8c-.1-2.9-2-5.5-4.7-6.5l-3.6-1.3c-.9-.3-1.7-.9-2.3-1.7l-2.4-3c-1.8-2.3-4.8-3.3-7.6-2.5l-3.7 1c-.9.3-1.9.3-2.8 0l-3.7-1c-2.8-.8-5.8.2-7.6 2.5l-2.4 3c-.6.8-1.4 1.3-2.3 1.7l-3.6 1.3c-2.7 1-4.6 3.5-4.7 6.5l-.1 3.8q0 1.5-.9 2.7l-2 3.2c-1.6 2.4-1.6 5.6 0 8l2.1 3.2q.75 1.2.9 2.7l.2 3.8c.1 2.9 2 5.5 4.7 6.5l3.6 1.3c.9.3 1.7.9 2.3 1.7l2.4 3c1.4 1.8 3.5 2.7 5.6 2.7.7 0 1.3-.1 2-.3l3.7-1c.9-.3 1.9-.3 2.8 0l3.7 1c2.8.8 5.8-.2 7.6-2.5l2.4-3c.6-.8 1.4-1.3 2.3-1.7l3.6-1.3c2.7-1 4.6-3.5 4.7-6.5l.2-3.8q0-1.5.9-2.7l2.1-3.2c1.4-2.3 1.4-5.5-.2-7.9m-1.7 6.9L57 38.1c-.7 1.1-1.2 2.4-1.2 3.7l-.2 3.8c-.1 2.1-1.4 3.9-3.4 4.7l-3.6 1.3c-1.2.5-2.3 1.3-3.2 2.3l-2.4 3c-1.3 1.7-3.5 2.4-5.5 1.8l-3.7-1q-1.95-.6-3.9 0l-3.7 1c-2 .6-4.2-.1-5.5-1.8l-2.4-3c-.8-1-1.9-1.8-3.2-2.3l-3.6-1.3c-2-.7-3.3-2.6-3.4-4.7l-.2-3.8c-.1-1.3-.5-2.6-1.2-3.7l-2.1-3.2c-1.2-1.8-1.2-4 0-5.8L7 25.9c.7-1.1 1.2-2.4 1.2-3.7l.1-3.8c.1-2.1 1.4-3.9 3.4-4.7l3.6-1.3c1.3-.5 2.3-1.3 3.2-2.3l2.4-3c1-1.3 2.5-2 4.1-2 .5 0 1 .1 1.4.2l3.7 1q1.95.6 3.9 0l3.7-1c2-.6 4.2.1 5.5 1.8l2.4 3c.8 1 1.9 1.8 3.2 2.3l3.6 1.3c2 .7 3.3 2.6 3.4 4.7l.2 3.8c.1 1.3.5 2.6 1.2 3.7l2.1 3.2c1 1.8 1 4-.2 5.8" />
    <path d="M44.6 24.7c-.2-.3-.5-.4-.8-.5l-6.7-.4-4.2-5.9c-.4-.5-1.3-.5-1.6 0l-4.2 5.9-6.7.4c-.3 0-.6.2-.8.5s-.2.6-.1.9l2.6 6.4-2.6 6.4c-.1.3-.1.6.1.9s.5.4.8.5l6.7.4 4.2 5.9c.2.3.5.4.8.4s.6-.2.8-.4l4.2-5.9 6.7-.4c.3 0 .6-.2.8-.5s.2-.6.1-.9L42.1 32l2.6-6.4c.1-.3 0-.6-.1-.9m-4.5 6.9c-.1.2-.1.5 0 .7l2.2 5.5-5.8.4c-.3 0-.6.2-.8.4L32 43.8l-3.7-5.2c-.2-.2-.5-.4-.8-.4l-5.8-.4 2.2-5.5c.1-.2.1-.5 0-.7l-2.2-5.5 5.8-.4c.3 0 .6-.2.8-.4l3.7-5.2 3.7 5.2c.2.2.5.4.8.4l5.8.4z" />
  </svg>
);
export default IconBadge8;
