import * as React from "react";
const IconUmbrellaRain = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M33 16.3v-1.4c0-.6-.4-1-1-1s-1 .4-1 1v1.4c-11.5.5-21.1 9.4-22.7 21.2-.1 1.1.2 2.2.9 3s1.8 1.3 2.9 1.3H31V61c0 .6.4 1 1 1s1-.4 1-1V41.8h18.9c1.1 0 2.1-.5 2.8-1.3s1.1-1.9.9-3C54.1 25.7 44.5 16.8 33 16.3m20.2 22.9c-.3.4-.8.6-1.3.6H12.1c-.5 0-1-.2-1.3-.6s-.5-.9-.4-1.4C11.8 26.6 21.1 18.2 32 18.2s20.2 8.4 21.7 19.5c.1.6-.1 1.1-.5 1.5M3.8 2c-.6 0-1 .4-1 1v4.9c0 .6.4 1 1 1s1-.4 1-1V3c0-.6-.4-1-1-1M17.9 8.9c.6 0 1-.4 1-1V3c0-.6-.4-1-1-1s-1 .4-1 1v4.9c0 .6.5 1 1 1M32 8.9c.6 0 1-.4 1-1V3c0-.6-.4-1-1-1s-1 .4-1 1v4.9c0 .6.4 1 1 1M46.1 8.9c.6 0 1-.4 1-1V3c0-.6-.4-1-1-1s-1 .4-1 1v4.9c0 .6.4 1 1 1M60.2 2c-.6 0-1 .4-1 1v4.9c0 .6.4 1 1 1s1-.4 1-1V3c0-.6-.5-1-1-1" />
  </svg>
);
export default IconUmbrellaRain;
