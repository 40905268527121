import * as React from "react";
const IconCannula = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.7 16.6 47.4 2.3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l2.4 2.4-8.8 8.8c-1.9.1-3.7.8-5.1 1.9L28 10.3c-.4-.4-1-.4-1.4 0l-5.2 5.2c-1.6 1.6-2.4 3.7-2.4 5.9s.9 4.3 2.4 5.9l4.1 4.1-8.1 8.1c-1.2 1.2-1.2 3.1 0 4.2l.7.7L2.3 60.3c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3l15.9-15.9.7.7c.6.6 1.4.9 2.1.9s1.5-.3 2.1-.9l8.1-8.1 4.7 4.7c1.6 1.6 3.8 2.4 5.9 2.4s4.3-.8 5.9-2.4l5.2-5.2c.4-.4.4-1 0-1.4L47 29.2c.8-1.1 1.4-2.3 1.7-3.7l9.6-9.6 2.1 2.1c.2.2.5.3.7.3s.5-.1.7-.3c.3-.3.3-1-.1-1.4M23.2 45.1c-.4.4-1 .4-1.4 0l-1.4-1.4-1.4-1.4c-.4-.4-.4-1 0-1.4l8-8 4.3 4.3zM40 30.6c-3.8 0-6.9-3.1-6.9-6.9s3.1-6.9 6.9-6.9 6.9 3.1 6.9 6.9c-.1 3.8-3.1 6.9-6.9 6.9m7.7 11.1c-2.5 2.5-6.5 2.5-8.9 0L22.9 25.8c-1.2-1.2-1.8-2.8-1.9-4.5 0-1.7.7-3.3 1.9-4.5l4.5-4.5 5.8 5.8c-1.2 1.5-1.9 3.4-1.9 5.5 0 4.9 4 8.9 8.9 8.9 2.1 0 4-.7 5.5-1.9l6.6 6.6zm1.1-19.1c-.5-3.6-3.1-6.6-6.6-7.5l7.6-7.6 7.1 7.1z" />
  </svg>
);
export default IconCannula;
