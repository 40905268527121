import * as React from "react";
const IconPlaylist = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M2.9 21.7c.2.1.3.1.5.1s.3 0 .5-.1l16.2-8.9c.3-.2.5-.5.5-.9s-.2-.7-.5-.9L3.9 2.1c-.3-.1-.6-.1-1 0-.3.2-.4.5-.4.9v17.9c0 .3.1.7.4.8m1.6-17L17.7 12 4.5 19.2zM60.9 17.1H30.7c-.6 0-1 .4-1 1s.4 1 1 1h30.2c.6 0 1-.4 1-1s-.5-1-1-1M30.7 4.8h30.2c.6 0 1-.4 1-1s-.4-1-1-1H30.7c-.6 0-1 .4-1 1s.4 1 1 1M60.7 31.4H3.1c-.6 0-1 .4-1 1s.4 1 1 1h57.6c.6 0 1-.4 1-1s-.4-1-1-1M60.7 45.7H3.1c-.6 0-1 .4-1 1s.4 1 1 1h57.6c.6 0 1-.4 1-1s-.4-1-1-1M60.7 60H3.1c-.6 0-1 .4-1 1s.4 1 1 1h57.6c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconPlaylist;
