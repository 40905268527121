import * as React from "react";
const IconHousePlan = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M48 29.4c-.1-.1-.2-.2-.4-.3l-6.9-4.8c-.8-.6-1.9-.6-2.7 0l-7.1 5c-.1 0-.1.1-.2.1l-2.1 1.5c-.5.3-.6.9-.2 1.4s.9.6 1.4.2l.7-.5v9.8c0 1.3 1.1 2.4 2.4 2.4H46c1.3 0 2.4-1.1 2.4-2.4V32l.7.5c.2.1.4.2.6.2.3 0 .6-.1.8-.4.3-.5.2-1.1-.2-1.4zm-1.8 12.5c0 .2-.2.4-.4.4h-13c-.2 0-.4-.2-.4-.4V30.6l6.7-4.7c.1-.1.3-.1.4 0l6.7 4.7z" />
    <path d="M55.8 13.2H19.4a9.04 9.04 0 0 0-8.3-5.4C6.1 7.9 2 11.9 2 17v30c0 5 4.1 9.1 9.1 9.1h44.7c3.4 0 6.2-2.9 6.2-6.4v-30c0-3.6-2.8-6.5-6.2-6.5M4 17c0-3.9 3.2-7.1 7.1-7.1s7.1 3.2 7.1 7.1v24.4C16.5 39.3 14 38 11.1 38S5.7 39.3 4 41.4zm0 30c0-3.9 3.2-7.1 7.1-7.1s7.1 3.2 7.1 7.1-3.2 7.1-7.1 7.1S4 51 4 47m56 2.7c0 2.5-1.9 4.4-4.2 4.4h-39c2.1-1.7 3.4-4.2 3.4-7.1V17c0-.6-.1-1.2-.2-1.7h35.8c2.3 0 4.2 2 4.2 4.4z" />
  </svg>
);
export default IconHousePlan;
