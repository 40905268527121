import * as React from "react";
const IconHelmetAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.2 41.5H58c0-1.5-.3-7.1-2.3-11.2-3.4-7.3-10-12.7-17.7-14.6-.6-1.8-2.4-3.2-4.5-3.2h-3.2c-2.1 0-3.8 1.3-4.4 3.1C15 18.1 6.1 29.6 5.8 41.5c-2.1 0-3.8 1.7-3.8 3.8v2.4c0 2.1 1.7 3.8 3.8 3.8h52.5c2.1 0 3.8-1.7 3.8-3.8v-2.4c-.1-2.1-1.8-3.8-3.9-3.8M25.7 17.8v6.3c0 .6.4 1 1 1s1-.4 1-1V17c0-1.4 1.2-2.5 2.7-2.5h3.2c1.5 0 2.7 1.1 2.7 2.5v7.1c0 .6.4 1 1 1s1-.4 1-1v-6.3c6.8 1.9 12.6 6.8 15.7 13.3 1.5 3.2 2 7.8 2.1 10.3H43.5V39c0-.6-.4-1-1-1s-1 .4-1 1v2.4H33v-5c0-.6-.4-1-1-1s-1 .4-1 1v5h-8.5V39c0-.6-.4-1-1-1s-1 .4-1 1v2.4H7.8c.3-10.7 8.1-21 17.9-23.6M60 47.7c0 1-.8 1.8-1.8 1.8H5.8c-1 0-1.8-.8-1.8-1.8v-2.4c0-1 .8-1.8 1.8-1.8h52.5c1 0 1.8.8 1.8 1.8v2.4z" />
  </svg>
);
export default IconHelmetAlt;
