import * as React from "react";
const IconHtml5Copy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M45.7 15.3H20.9c-.9 0-1.6.3-2.2 1s-.8 1.5-.6 2.3l1.5 8.6c.2 1.2 1.2 2 2.4 2h21.4c.2 0 .3.1.3.2s.1.2.1.4L41.6 41q0 .3-.3.3l-8.5 3.6h-.4l-8.6-3.4c-.1-.1-.3-.2-.3-.4l-1-5.4c-.1-.5-.6-.9-1.2-.8-.5.1-.9.6-.8 1.2l1 5.4c.1.8.7 1.5 1.5 1.9l8.6 3.4c.3.1.6.2.9.2s.6-.1 1-.2l8.5-3.6c.8-.3 1.3-1 1.5-1.8l2.3-11.3c.1-.7 0-1.5-.5-2-.5-.6-1.2-.9-1.9-.9H22c-.2 0-.4-.2-.4-.4L20 18.3c0-.2 0-.4.2-.7.2-.2.5-.3.7-.3h24.8c.6 0 1-.4 1-1s-.4-1-1-1" />
    <path d="M59.3 3.3C58 1.8 56.1 1 54.2 1H9.8C7.9 1 6 1.8 4.7 3.3S2.8 6.7 3 8.7L8.3 53c.3 2.9 2.4 5.2 5.2 5.9l16.9 4c.5.1 1 .2 1.6.2s1.1-.1 1.6-.2l16.9-4c2.8-.7 4.9-3 5.2-5.9L61 8.7c.2-2-.4-3.9-1.7-5.4M59 8.4l-5.3 44.3c-.2 2-1.7 3.7-3.7 4.2l-16.9 4c-.7.2-1.5.2-2.2 0l-16.9-4c-2-.5-3.5-2.1-3.7-4.2L5 8.4c-.2-1.3.3-2.7 1.2-3.8S8.4 3 9.8 3h44.3c1.4 0 2.7.6 3.6 1.6s1.5 2.5 1.3 3.8" />
  </svg>
);
export default IconHtml5Copy;
