import * as React from "react";
const IconDatabase6 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.1 19.1V9.6C54.1 4.7 42.7 2 32 2S9.9 4.7 9.9 9.6V19c0 1.6 1.2 3 3.3 4.1-2.6 1.5-3.3 3-3.3 4.1v9.4c0 1.6 1.2 3 3.3 4.1-2.6 1.5-3.2 3-3.3 4.1v9.4C9.9 59.1 21.1 62 32 62c10.7 0 22.1-2.7 22.1-7.7v-9.4c0-1.1-.6-2.7-3.2-4.1 2-1.1 3.2-2.5 3.2-4.1v-9.4c0-1.1-.6-2.7-3.2-4.1 2-1.2 3.2-2.5 3.2-4.1M32 4c12.4 0 20.1 3.3 20.1 5.6s-7.6 5.6-20.1 5.6c-12.4 0-20.1-3.3-20.1-5.6S19.6 4 32 4m0 56c-12.2 0-20.1-3.3-20.1-5.7v-6.1C15.6 51 24 52.5 32 52.5s16.4-1.5 20.1-4.3v6.1c0 2.4-7.9 5.7-20.1 5.7m20.1-15.1c0 2.3-7.6 5.6-20.1 5.6-12.4 0-20.1-3.3-20.1-5.6 0-1 1.4-2.1 3.6-3.1 4.3 1.6 10.4 2.5 16.4 2.5s12.2-.8 16.5-2.5c2.3 1 3.7 2.2 3.7 3.1M32 42.4c-12.2 0-20.1-3.3-20.1-5.7v-6.1c3.7 2.8 12.1 4.3 20.1 4.3s16.4-1.5 20.1-4.3v6.1c0 2.3-7.9 5.7-20.1 5.7m20.1-15.1c0 2.3-7.6 5.6-20.1 5.6-12.4 0-20.1-3.3-20.1-5.6 0-1 1.4-2.1 3.6-3 4.3 1.6 10.4 2.5 16.4 2.5s12.2-.8 16.5-2.5c2.3.9 3.7 2 3.7 3M32 24.7c-12.2 0-20.1-3.3-20.1-5.7v-6.1c3.7 2.8 12.1 4.3 20.1 4.3s16.3-1.5 20.1-4.3V19c0 2.4-7.9 5.7-20.1 5.7" />
  </svg>
);
export default IconDatabase6;
