import * as React from "react";
const IconRouteAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M47.8 44.7v-5.5c0-.6-.4-1-1-1s-1 .4-1 1v5.5c-4.3.5-7.7 4.2-7.7 8.6 0 4.8 3.9 8.7 8.7 8.7s8.7-3.9 8.7-8.7c0-4.4-3.4-8.1-7.7-8.6m-1 15.3a6.7 6.7 0 1 1 0-13.4 6.7 6.7 0 0 1 0 13.4M18 2c-5.2 0-9.5 4.2-9.5 9.5 0 5 7.9 12 8.8 12.8.2.2.4.2.7.2s.5-.1.7-.2c.9-.8 8.8-7.8 8.8-12.8C27.4 6.2 23.2 2 18 2m0 20.2c-2.6-2.4-7.5-7.5-7.5-10.7C10.5 7.3 13.8 4 18 4s7.5 3.3 7.5 7.5c-.1 3.1-5 8.3-7.5 10.7M31.1 21.7h-6c-.6 0-1 .4-1 1s.4 1 1 1h6c.6 0 1-.4 1-1s-.4-1-1-1M40.8 23.7h5v5c0 .6.4 1 1 1s1-.4 1-1v-6c0-.6-.4-1-1-1h-6c-.6 0-1 .4-1 1s.5 1 1 1" />
    <circle cx={46.8} cy={53.3} r={1.8} />
    <circle cx={18} cy={11.5} r={1.8} />
  </svg>
);
export default IconRouteAlt;
