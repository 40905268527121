import * as React from "react";
const IconEnvelopeCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 11.7H7c-2.8 0-5 2.2-5 5v30.7c0 2.8 2.2 5 5 5h50c2.8 0 5-2.2 5-5V16.7c0-2.8-2.2-5-5-5m-50 2h50c1 0 1.9.5 2.5 1.3L33.8 30.6c-1.1.7-2.5.7-3.5 0L4.5 15c.6-.8 1.5-1.3 2.5-1.3m50 36.6H7c-1.7 0-3-1.3-3-3V17l25.2 15.3c.9.5 1.8.8 2.8.8s1.9-.3 2.8-.8L60 17v30.4c0 1.6-1.3 2.9-3 2.9" />
  </svg>
);
export default IconEnvelopeCopy;
