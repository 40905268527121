import * as React from "react";
const IconLactose = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M28.393 19h-8.786a1 1 0 0 0-.998.933l-1.4 21A1 1 0 0 0 18.207 42h11.586a1 1 0 0 0 .998-1.066l-1.4-21a1 1 0 0 0-.998-.934m-8.786-2a3 3 0 0 0-2.994 2.8l-1.4 21a3 3 0 0 0 2.994 3.2h11.586a3 3 0 0 0 2.994-3.2l-1.4-21a3 3 0 0 0-2.994-2.8z"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M19 17h10a5 5 0 0 0-10 0m5-7a7 7 0 0 0-7 7v.714c0 .71.576 1.286 1.286 1.286h11.428c.71 0 1.286-.576 1.286-1.286V17a7 7 0 0 0-7-7M21 22a1 1 0 0 1 1 1v16a1 1 0 1 1-2 0V23a1 1 0 0 1 1-1M27 22a1 1 0 0 1 1 1v16a1 1 0 1 1-2 0V23a1 1 0 0 1 1-1"
      clipRule="evenodd"
    />
    <path
      
      d="M22 15a1 1 0 1 1-2 0 1 1 0 0 1 2 0M25 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0M31 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0M30 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0M27 7a1 1 0 1 1-2 0 1 1 0 0 1 2 0M28 15a1 1 0 1 1-2 0 1 1 0 0 1 2 0"
    />
  </svg>
);
export default IconLactose;
