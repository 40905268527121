import * as React from "react";
const IconRu = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 85.333 512 341.333"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 85.33v341.332h512V85.33z" />
    <path  d="M0 85.333h512V426.67H0z" />
    <path  d="M0 85.333h512v113.775H0z" />
    <path  d="M0 312.884h512v113.775H0z" />
  </svg>
);
export default IconRu;
