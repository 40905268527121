import * as React from "react";
const IconPortfolio = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.7 57.8H12.3c-3 0-5.2-2.4-5.2-5.8l1.1-14.6c-2.5-.5-4.5-2.7-4.6-5.4L3 18.6c-.1-1.6.5-3.2 1.6-4.4 1.1-1.1 2.5-1.8 4.1-1.8h15.4V12c0-3.2 2.6-5.8 5.7-5.8h4.4c3.1 0 5.7 2.6 5.7 5.8v.4h15.4c1.6 0 3 .6 4.1 1.8s1.7 2.7 1.6 4.4l-.7 13.3a5.8 5.8 0 0 1-4.1 5.3l1.1 14.7c.1 3.3-2.5 5.9-5.6 5.9M10.3 37.4 9.2 52c0 1.8 1 3.7 3.2 3.7h39.3c2 0 3.7-1.7 3.7-3.8l-1.1-14.5h-13c-.5 2.5-2.6 4.4-5.2 4.4h-8.2c-2.6 0-4.8-1.9-5.2-4.4zM27.9 33c-1.8 0-3.3 1.5-3.3 3.4s1.5 3.4 3.3 3.4h8.2c1.8 0 3.3-1.5 3.3-3.4S37.9 33 36.1 33zm13.4 2.4h13.3c2 0 3.6-1.6 3.7-3.6l.7-13.3c.1-1.1-.3-2.1-1.1-2.9-.7-.7-1.6-1.1-2.6-1.1H8.7c-1 0-1.9.4-2.6 1.1-.8.8-1.1 1.8-1.1 2.9l.7 13.3c.1 2 1.7 3.6 3.7 3.6h13.3c.5-2.5 2.6-4.4 5.2-4.4h8.2c2.6 0 4.8 1.9 5.2 4.4M26.1 12.5h11.8v-.4c0-2.1-1.7-3.8-3.7-3.8h-4.4c-2 0-3.7 1.7-3.7 3.8z" />
  </svg>
);
export default IconPortfolio;
