import * as React from "react";
const IconShirt4 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m52.4 27.1-9.6-12.5V3c0-.6-.4-1-1-1s-1 .4-1 1v11.7L32 26.1l-8.9-11.6V3c0-.6-.4-1-1-1s-1 .4-1 1v11.8l-9.5 12.3c-.1.2-.2.4-.2.6v32.5c0 .5.4 1 .9 1 5.8.5 12.6.8 19.7.8s13.9-.3 19.7-.7c.5 0 .9-.5.9-1V27.7c0-.2-.1-.4-.2-.6m-1.8 32.2c-5.5.5-11.9.7-18.6.7s-13.1-.2-18.6-.7V28.1l8.8-11.4 9 11.7c.4.5 1.2.5 1.6 0l9-11.7 8.8 11.4z" />
  </svg>
);
export default IconShirt4;
