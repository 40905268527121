import * as React from "react";
const IconArrowTarget = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M49.4 24.7H48c-.6 0-1 .4-1 1s.4 1 1 1h1.4c1.5 0 2.8 1.3 2.8 2.8v21.3c0 .8-.7 1.5-1.5 1.5H13.3c-.8 0-1.5-.7-1.5-1.5V29.6c0-1.5 1.3-2.8 2.8-2.8h.8c.6 0 1-.4 1-1s-.4-1-1-1h-.8c-2.6 0-4.8 2.2-4.8 4.8v21.3c0 2 1.6 3.5 3.5 3.5h14.2l-1.1 6.4c0 .3 0 .6.2.8s.5.4.8.4h9.2c.3 0 .6-.1.8-.4.2-.2.3-.5.2-.8l-1.1-6.4h14.2c2 0 3.5-1.6 3.5-3.5V29.6c0-2.7-2.1-4.9-4.8-4.9M35.4 60h-6.8l.9-5.6h5z" />
    <path d="M33 31.8c6 0 10.9-4.9 10.9-10.9 0-.6-.4-1-1-1s-1 .4-1 1c0 4.9-4 8.9-8.9 8.9s-8.9-4-8.9-8.9 4-8.9 8.9-8.9c.9 0 1.7.1 2.5.4.5.2 1.1-.1 1.2-.7.2-.5-.1-1.1-.7-1.2-1-.3-2.1-.5-3.1-.5-6 0-10.9 4.9-10.9 10.9s5 10.9 11 10.9" />
    <path d="M33.4 19.3c-.4.4-.5 1-.1 1.4.2.2.5.3.7.3s.5-.1.7-.3l12.4-11h6.1c.6 0 1-.4 1-1s-.4-1-1-1h-5.3V3c0-.6-.4-1-1-1s-1 .4-1 1v5.3zM30 20.9c0-.6-.4-1-1-1s-1 .4-1 1c0 2.7 2.2 5 5 5 .6 0 1-.4 1-1s-.4-1-1-1c-1.6 0-3-1.4-3-3" />
  </svg>
);
export default IconArrowTarget;
