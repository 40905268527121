import * as React from "react";
const IconLeaves = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.6 16.8c-.2-.6-.7-1.1-1.3-1.4-.6-.2-1.3-.2-1.9.1-2.5 1.1-8.6 4.2-13.7 8.4C34 12.5 16.6 4.4 11.3 2.2c-.6-.3-1.3-.3-1.9 0s-1 .7-1.3 1.4C6.1 8.9.1 27.2 5.3 39c6.1 13.7 29 22.6 30 23 .1 0 .2.1.4.1.1 0 .2 0 .4-.1.8-.3 18.8-7.4 23.2-18.2 3.7-8.9-.7-21.8-2.7-27M7.2 38.1C2.2 27 8.1 9.4 10 4.3l.2-.2h.4c5.2 2.2 22.6 10.2 27.6 21.4 5.1 11.4-1.3 29.9-3 34.3-4.4-1.9-22.9-10.2-28-21.7M57.5 43c-3.3 7.9-15.1 13.9-20 16.1 2.1-5.9 7.1-22 2.9-33.3 5-4.3 11.2-7.4 13.7-8.5.1-.1.3 0 .3 0 .1 0 .2.1.2.2 1.7 3.9 6.4 17.1 2.9 25.5" />
  </svg>
);
export default IconLeaves;
