import * as React from "react";
const IconMagicWand = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.6 57.2 22.9 24.8c-.1-.1-.2-.1-.3-.1l7.7-2.5c.4-.1.6-.4.7-.8s-.1-.7-.4-1l-7.3-5.2.2-9.1c0-.4-.2-.7-.5-.9s-.7-.2-1 0l-7.3 5.5-8.5-2.8c-.4-.1-.8 0-1 .2-.3.3-.4.7-.2 1l2.8 8.6-5.5 7.4c-.2.3-.3.7-.1 1.1.2.3.5.6.9.5l9-.2 5.1 7.4c.2.3.5.4.8.4h.1c.4-.1.7-.3.8-.7l2.4-7.7c.1.1.1.2.2.3l38.7 32.4c.2.2.4.2.6.2.3 0 .6-.1.8-.4.5-.2.5-.8 0-1.2M20 23.8l-2.3 7.3-4.3-6.1c-.2-.3-.5-.4-.8-.4l-7.6.2 4.6-6.2c.2-.3.2-.6.1-.9l-2.4-7.2 7.1 2.4c.3.1.7 0 .9-.2l6.1-4.6-.2 7.6c0 .3.1.6.4.8l6.1 4.3-7.3 2.4c-.1.1-.3.3-.4.6" />
  </svg>
);
export default IconMagicWand;
