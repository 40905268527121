import * as React from "react";
const IconPhoneSetCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.9 36.9c-.2-1.9-1.7-3.3-3.6-3.3H10.7c-1.9 0-3.4 1.4-3.6 3.3L4.9 58c-.1 1 .2 2 .9 2.8.8.8 1.7 1.2 2.8 1.2h46.9c1 0 2-.4 2.7-1.2s1-1.8.9-2.8zm-.3 22.6c-.3.3-.7.5-1.2.5H8.6c-.5 0-.9-.2-1.2-.5s-.5-.8-.4-1.3l2-21.1c.1-.8.8-1.5 1.6-1.5h42.7c.8 0 1.5.6 1.6 1.5L57 58.2c.1.5 0 .9-.4 1.3" />
    <path d="M32 41.6c-3.5 0-6.3 2.8-6.3 6.3s2.8 6.3 6.3 6.3 6.3-2.8 6.3-6.3-2.8-6.3-6.3-6.3m0 10.5c-2.4 0-4.3-1.9-4.3-4.3s1.9-4.3 4.3-4.3 4.3 1.9 4.3 4.3-1.9 4.3-4.3 4.3M7.3 19.5c.2 3.9 3.6 5.5 5.4 5.5h7c2.6-.2 5.2-1.9 5.2-5.5v-3.2h15.7v3.2c0 3.6 2.6 5.3 5.3 5.5h7c1.8 0 5.2-1.6 5.4-5.5v-4.1c-.2-2.6-1.2-4.8-2.8-6.5-2.4-2.2-5.3-3.4-7.5-4.3C41.4 2 33.2 2 32.9 2c-5.9.1-9.7.5-15.2 2.6-2.2.8-5.1 2.1-7.6 4.3-1.6 1.7-2.5 3.8-2.8 6.4v4.2m4.2-9.2c2.2-2 4.8-3.1 6.9-3.9 5.3-1.9 8.8-2.3 14.5-2.4.3 0 8.3.1 14.5 2.4 2.1.8 4.7 1.9 6.8 3.8 1.3 1.3 2 3.1 2.2 5.2v4c-.2 2.7-2.6 3.6-3.4 3.6h-6.9c-.8 0-3.4-.4-3.4-3.5v-4.1c0-.4-.3-.8-.7-1-.2-.1-4.7-.1-9.2-.1s-8.9 0-9.1.1c-.4.1-.7.5-.7 1v4.1c0 3-2.6 3.4-3.3 3.5h-7c-.8 0-3.2-.9-3.4-3.5v-4c.2-2.2 1-3.9 2.2-5.2" />
  </svg>
);
export default IconPhoneSetCopy;
