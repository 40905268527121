import * as React from "react";
const IconBv = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 33.333 1100 733.333"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 0h1100v800H0z" />
    <path  d="M300 0h200v800H300z" />
    <path  d="M0 300h1100v200H0z" />
    <path  d="M350 0h100v800H350z" />
    <path  d="M0 350h1100v100H0z" />
  </svg>
);
export default IconBv;
