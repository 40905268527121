import React from 'react';
import classnames from 'classnames';
import BringzzText from '../BringzzText';
import PropTypes from 'prop-types';

const BringzzConversation = ({ messages }) => {
    return (
        <div className="flex flex-col space-y-6 p-4">
            {messages.map((message, index) => (
                <div key={index} className="flex flex-col">
                    <div className={classnames("flex items-end", message.sender === 'sent' ? "justify-end" : "")}>
                        {message.sender === 'received' && (
                            <div className="flex-none w-9 h-9 rounded-full bg-gray-200 mr-2"></div>
                        )}
                        <div
                            className={classnames(
                                'p-3',
                                {
                                    'bg-sand/60 rounded-l-3xl rounded-tr-3xl': message.sender === 'sent',
                                    'bg-magic-lilac/40 rounded-r-3xl rounded-tl-3xl': message.sender === 'received',
                                },
                                {
                                    'max-w-[80%]': message.text.length > 50,
                                    'max-w-[60%]': message.text.length <= 50,
                                }
                            )}
                        >
                            <BringzzText tag='h4' className={'font-normal'}>{message.text}</BringzzText>
                        </div>
                        {message.sender === 'sent' && (
                            <div className="flex-none w-9 h-9 rounded-full bg-gray-200 ml-2"></div>
                        )}
                    </div>
                    <BringzzText
                        tag='h5'
                        className={classnames(
                            {
                                'mr-11': message.sender === 'sent',
                                'ml-11': message.sender === 'received'
                            },
                            "text-midnight-blue/60 italic font-normal text-gray-500 mt-1",
                            {
                                'text-right': message.sender === 'sent',
                                'text-left': message.sender === 'received'
                            }
                        )}>
                        {message.datetime}
                    </BringzzText>
                </div>
            ))}
        </div>
    );
};

BringzzConversation.propTypes = {
    messages: PropTypes.arrayOf(PropTypes.shape({
        sender: PropTypes.oneOf(['sent', 'received']).isRequired,
        text: PropTypes.string.isRequired,
        datetime: PropTypes.string.isRequired,
    })).isRequired,
};

export default BringzzConversation;
