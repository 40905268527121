import * as React from "react";
const IconStop = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56 62.3H8c-3.4 0-6.3-2.8-6.3-6.3V8c0-3.4 2.8-6.3 6.3-6.3h48c3.4 0 6.3 2.8 6.3 6.3v48c0 3.4-2.9 6.3-6.3 6.3M8 6.3C7 6.3 6.3 7 6.3 8v48c0 1 .8 1.8 1.8 1.8h48c1 0 1.8-.8 1.8-1.8V8c0-1-.8-1.8-1.8-1.8H8z" />
  </svg>
);
export default IconStop;
