import * as React from "react";
const IconOuterAlign = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.7 2H7.4C4.4 2 2 4.4 2 7.4v49.3c0 3 2.4 5.3 5.4 5.3h49.3c3 0 5.3-2.4 5.3-5.3V7.4c0-3-2.4-5.4-5.3-5.4m0 58H33v-9.9c0-.6-.4-1-1-1s-1 .4-1 1V60H7.4C5.5 60 4 58.5 4 56.7V33h9.9c.6 0 1-.4 1-1s-.4-1-1-1H4V7.4C4 5.5 5.5 4 7.4 4H31v9.9c0 .6.4 1 1 1s1-.4 1-1V4h23.7C58.5 4 60 5.5 60 7.4V31h-9.9c-.6 0-1 .4-1 1s.4 1 1 1H60v23.7c0 1.8-1.5 3.3-3.3 3.3" />
    <path d="M37.4 31.1H33v-4.9c0-.6-.4-1-1-1s-1 .4-1 1v4.9h-4.4c-.6 0-1 .4-1 1s.4 1 1 1H31V38c0 .6.4 1 1 1s1-.4 1-1v-4.9h4.4c.6 0 1-.4 1-1 0-.5-.4-1-1-1" />
  </svg>
);
export default IconOuterAlign;
