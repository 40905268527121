import * as React from "react";
const IconScaleMachine = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.8 45.7-7.5-26.8c-.6-2.3-2.8-3.9-5.1-3.9h-9.5V6.7h19c.6 0 1-.4 1-1s-.4-1-1-1H5.4c-.6 0-1 .4-1 1s.4 1 1 1h19V15h-9.5c-2.4 0-4.5 1.6-5.1 3.9L2.2 45.7c-.5 1.6-.1 3.3.9 4.7 1 1.3 2.5 2.1 4.1 2.1v5.8c0 .6.4 1 1 1s1-.4 1-1v-5.8h45.6v5.8c0 .6.4 1 1 1s1-.4 1-1v-5.8c1.6 0 3.1-.8 4.1-2.1s1.4-3 .9-4.7m-35.5-39h11.3V15H26.3zm33 42.5c-.6.8-1.6 1.3-2.7 1.3H7.3c-1.1 0-2-.5-2.7-1.3-.6-.8-.8-1.9-.6-2.9l7.5-26.8C12 18 13.3 17 14.8 17h34.4c1.5 0 2.8 1 3.2 2.4l7.5 26.8c.3 1.1.1 2.2-.6 3" />
    <path d="M32 23.2c-5.8 0-10.4 4.7-10.4 10.4S26.2 44 32 44s10.4-4.7 10.4-10.4S37.8 23.2 32 23.2M33 42v-5.7c0-.6-.4-1-1-1s-1 .4-1 1V42c-4.2-.5-7.4-4.1-7.4-8.4 0-4.6 3.8-8.4 8.4-8.4s8.4 3.8 8.4 8.4c0 4.3-3.2 7.9-7.4 8.4" />
  </svg>
);
export default IconScaleMachine;
