import * as React from "react";
const IconStop = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M21 11v.17A3 3 0 0 0 17 14v1.17A3 3 0 0 0 13 18v11.525C13 35.31 17.69 40 23.475 40c3.637 0 7.014-1.887 8.92-4.985l5.736-9.32a3.006 3.006 0 0 0-4.92-3.436L31 25.063V14a3 3 0 0 0-4-2.83V11a3 3 0 1 0-6 0m4 0a1 1 0 1 0-2 0v12h-2v-9a1 1 0 1 0-2 0v11h-2v-7a1 1 0 1 0-2 0v11.525a8.475 8.475 0 0 0 15.692 4.442l5.736-9.32a1.006 1.006 0 0 0-1.647-1.15l-2.21 2.804c-1.175 1.49-3.571.66-3.571-1.238V14a1 1 0 1 0-2 0v9h-2z"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M24 4c11.046 0 20 8.954 20 20s-8.954 20-20 20S4 35.046 4 24 12.954 4 24 4m18 20c0-9.941-8.059-18-18-18S6 14.059 6 24s8.059 18 18 18 18-8.059 18-18"
      clipRule="evenodd"
    />
  </svg>
);
export default IconStop;
