import * as React from "react";
const IconCar = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M17.4 30.1c-3.9 0-7.1 3.1-7.1 7s3.2 7 7.1 7 7.1-3.1 7.1-7-3.2-7-7.1-7m0 9.5c-1.4 0-2.6-1.1-2.6-2.5s1.2-2.5 2.6-2.5 2.6 1.1 2.6 2.5c0 1.3-1.1 2.5-2.6 2.5M46.6 29.9c-3.9 0-7.1 3.2-7.1 7.1s3.2 7.1 7.1 7.1 7.1-3.2 7.1-7.1-3.2-7.1-7.1-7.1m0 9.6c-1.4 0-2.6-1.1-2.6-2.6s1.2-2.6 2.6-2.6 2.6 1.1 2.6 2.6-1.2 2.6-2.6 2.6M34.4 34.7h-4.8c-1.2 0-2.3 1-2.3 2.3s1 2.3 2.3 2.3h4.8c1.2 0 2.3-1 2.3-2.3s-1.1-2.3-2.3-2.3" />
    <path d="m57.8 23.3-.9-9.9c-.5-5.6-5.6-10-11.6-10H18.7c-6 0-11.1 4.4-11.6 9.9l-.9 9.9c-2.6.8-4.4 3.2-4.4 6v15.5C1.8 48.2 4.6 51 8 51h.2v3.3c0 3.4 2.8 6.3 6.3 6.3H16c3.4 0 6.3-2.8 6.3-6.3V51h19.4v3.3c0 3.4 2.8 6.3 6.3 6.3h1.6c3.4 0 6.3-2.8 6.3-6.3V51h.1c3.4 0 6.3-2.8 6.3-6.3V29.2c0-2.8-1.9-5.1-4.5-5.9m-46.2-9.5C11.9 10.5 15 8 18.7 8h26.7c3.7 0 6.9 2.6 7.1 5.8l.8 9.2H10.7zm6.2 40.5c0 1-.8 1.8-1.8 1.8h-1.6c-1 0-1.8-.8-1.8-1.8V51h5.1v3.3zm33.5 0c0 1-.8 1.8-1.8 1.8H48c-1 0-1.8-.8-1.8-1.8V51h5.1zm6.5-9.5c0 1-.8 1.8-1.8 1.8H8c-1 0-1.8-.8-1.8-1.8V29.2c0-1 .8-1.8 1.8-1.8h48c1 0 1.8.8 1.8 1.8z" />
  </svg>
);
export default IconCar;
