import * as React from "react";
const IconMu = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 85.333 512 341.333"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 85.331h512v341.326H0z" />
    <path  d="M0 85.331h512v85.337H0z" />
    <path  d="M0 170.657h512v85.337H0z" />
    <path  d="M0 341.331h512v85.337H0z" />
  </svg>
);
export default IconMu;
