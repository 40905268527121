import * as React from "react";
const IconReservoir1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.9 59.2-9.1-21.8V20.5h6.1c.4 0 .8-.3 1-.7.1-.4-.1-.9-.4-1.1L37.8 5.1c-3.6-2.2-8.2-2.2-11.7 0L4.5 18.6c-.4.2-.6.7-.4 1.1.1.4.5.7 1 .7h6.1v17l-9 21.7c-.2.5 0 1.1.5 1.3.1.1.3.1.4.1.4 0 .8-.2.9-.6l7.6-18.2c1.8 9.6 10.3 16.9 20.5 16.9s18.7-7.3 20.5-16.9L60.1 60q.3.6.9.6c.1 0 .3 0 .4-.1.5-.2.7-.7.5-1.3M27.2 6.8c2.9-1.8 6.7-1.8 9.6 0l18.7 11.7h-47zm23.7 13.7v16.2H13.1V20.5zM32 56.7c-10.1 0-18.4-8-18.8-18h37.6c-.4 10-8.7 18-18.8 18" />
  </svg>
);
export default IconReservoir1;
