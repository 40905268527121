import * as React from "react";
const IconPieChartCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M37.3 2.5C21.1-.5 5.5 10.4 2.5 26.6c-1.4 7.9.3 15.8 4.9 22.4s11.4 11 19.2 12.5c1.8.3 3.6.5 5.4.5 12.1 0 23.1-7.4 27.7-18.5l.2.1.7-1.9-.2-.1c.5-1.4.8-2.8 1.1-4.2 2.9-16.3-8-32-24.2-34.9M31 4v27H4.1c.1-1.3.2-2.7.4-4C6.9 13.8 18.2 4.5 31 4m-4 55.5C19.7 58.2 13.3 54 9 47.9 5.9 43.4 4.3 38.3 4.1 33h27.7l26 9.8c-5 11.8-17.7 19.1-30.8 16.7m6-28.2V4c1.3 0 2.6.2 4 .4C52.1 7.2 62.2 21.8 59.5 37c-.2 1.3-.6 2.6-1 3.9z" />
  </svg>
);
export default IconPieChartCopy;
