import * as React from "react";
const IconRadioactive = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 65 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32.5 3c-16 0-29 13-29 29s13 29 29 29 29-13 29-29-13-29-29-29m0 56c-14.9 0-27-12.1-27-27s12.1-27 27-27 27 12.1 27 27-12.1 27-27 27" />
    <path d="M32.5 36.1c2.3 0 4.1-1.8 4.1-4.1s-1.8-4.1-4.1-4.1-4.1 1.8-4.1 4.1 1.8 4.1 4.1 4.1m0-6.2c1.2 0 2.1.9 2.1 2.1s-.9 2.1-2.1 2.1-2.1-.9-2.1-2.1.9-2.1 2.1-2.1M37.5 38.6c-.5-.8-1.5-1.2-2.4-.8-1.6.7-3.5.7-5.2 0-.9-.4-1.9-.1-2.4.8l-5.8 10c-.2.4-.3.9-.2 1.4s.5.9.9 1.1c3.1 1.7 6.6 2.5 10.1 2.5s6.9-.8 10.1-2.5c.4-.2.8-.6.9-1.1s.1-1-.2-1.4zm-14 10.9 5.6-9.8c2.1.9 4.5.9 6.7 0l5.6 9.8c-5.6 2.9-12.3 2.9-17.9 0M24.2 32.9c1 0 1.8-.7 1.9-1.7.2-1.8 1.1-3.4 2.6-4.5.8-.6 1-1.7.6-2.5l-5.8-10c-.2-.4-.7-.7-1.1-.9-.5-.1-1 0-1.4.2-6 3.8-9.8 10.4-10.1 17.5 0 .5.2 1 .5 1.3.3.4.8.6 1.3.6zm-11.4-2c.3-6.3 3.7-12.1 9-15.5l5.6 9.8c-1.9 1.4-3.1 3.5-3.3 5.8H12.8zM44.1 13.6c-.4-.3-.9-.3-1.4-.2s-.9.4-1.1.9l-5.8 10.1c-.5.8-.2 1.9.6 2.5 1.4 1 2.3 2.6 2.6 4.3.2 1.1 1.1 1.9 2.1 1.9h11.4c.5 0 1-.2 1.3-.6s.5-.8.5-1.3c-.4-7.3-4.2-13.8-10.2-17.6M41 30.9s-.1-.1-.1-.2c-.3-2.2-1.5-4.2-3.3-5.6l5.7-9.8c5.3 3.4 8.6 9.2 9 15.6z" />
  </svg>
);
export default IconRadioactive;
