import * as React from "react";
const IconNi = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 85.333 512 341.333"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 85.337h512v341.326H0z" />
    <path
      
      d="M0 85.337h512v113.775H0zm0 227.551h512v113.775H0z"
    />
    <path
      
      d="M256 214.447c-22.949 0-41.553 18.603-41.553 41.553S233.05 297.553 256 297.553 297.553 278.95 297.553 256 278.949 214.447 256 214.447m0 65.298c-13.114 0-23.745-10.631-23.745-23.745s10.631-23.745 23.745-23.745 23.745 10.631 23.745 23.745-10.631 23.745-23.745 23.745"
    />
    <path
      
      d="M276.563 261.936 256 256l-20.563 5.936-6.855 11.873h54.836z"
    />
    <path  d="m256 226.32-13.709 23.744L256 256l13.709-5.936z" />
    <path  d="M235.437 261.936h41.126l-6.854-11.872h-27.418z" />
  </svg>
);
export default IconNi;
