import * as React from "react";
const IconPatientBand = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M42 26.667a.5.5 0 0 1-.5.5H37v2h3.5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-8.447v2h5.314c-2.84 2.516-7.337 2.825-10.623.825l-5.224-3.18-1.04 1.709 5.224 3.18c4.008 2.44 9.496 2.09 13.028-1.072.479-.429.972-.906 1.49-1.462h.278a2.5 2.5 0 0 0 2.5-2.5v-1c0-.313-.057-.612-.162-.888A2.5 2.5 0 0 0 44 26.666v-1c0-.888-.464-1.669-1.162-2.112.105-.276.162-.575.162-.887v-1a2.5 2.5 0 0 0-2-2.45v-.811a3 3 0 0 0-2.767-2.991l-3.194-.248h-1.275a29.65 29.65 0 0 0-13.21 3.104l.892 1.79a27.65 27.65 0 0 1 12.318-2.895h1.197l3.117.243a1 1 0 0 1 .922.997v.76h-2v2h3.5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H37v2h4.5a.5.5 0 0 1 .5.5z"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M21 21.167a2 2 0 0 1-2-2v-8a1 1 0 1 0-2 0v8a2 2 0 0 1-2 2v9a2 2 0 0 1 2 2v1h2v-1a2 2 0 0 1 2-2zm0 11a2 2 0 0 0 2-2v-9a2 2 0 0 0-2-2v-8a3 3 0 1 0-6 0v8a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2v3h6z"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M19 22.167h-2v7h2zM20 18.167h-5v-2h5zM6 20h9v2H6zM6 29h9v2H6z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconPatientBand;
