import * as React from "react";
const IconTajMahal = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 55.3h-2.9V37.5c0-.6-.4-1-1-1s-1 .4-1 1v17.8h-5.3v-18c0-2.2-1.8-4-4-4h-1.2c.6-.8 1-1.8 1-2.9 0-4.1-2-8.1-5.3-10.6l-7.1-5.3c1.2-.7 2.1-2.1 2.1-3.6 0-2.3-1.9-4.2-4.2-4.2s-4.2 1.9-4.2 4.2c0 1.5.8 2.9 2.1 3.6l-7.1 5.3c-3.3 2.5-5.3 6.4-5.3 10.6 0 1.1.4 2.1 1 2.9h-1.2c-2.2 0-4 1.8-4 4v17.9H7.9V37.5c0-.6-.4-1-1-1s-1 .4-1 1v17.8H3c-.6 0-1 .4-1 1s.4 1 1 1h58c.6 0 1-.4 1-1s-.4-1-1-1m-12.2-18V41h-6.9v-3.7c0-1.1.9-2 2-2h2.9c1.1 0 2 .9 2 2m-14.9 18H30c0-.1.1-.2.1-.4v-.5c0-1.6.5-3.3 1.5-4.6l.3-.5.3.5c1 1.3 1.5 2.9 1.5 4.6v.5c.2.1.2.3.2.4m0-6.6-1.1-1.5c-.4-.5-1.2-.5-1.6 0l-1.1 1.5c-1.2 1.7-1.9 3.7-1.9 5.8v.5c0 .1 0 .2.1.4h-4.1V43.1H40v12.2h-4.1c0-.1.1-.2.1-.4v-.5c-.1-2-.8-4.1-2.1-5.7m-9.8-7.6v-3.7c0-.7-.2-1.4-.6-2h16.9c-.3.6-.6 1.3-.6 2v3.7zM32 8.7c1.2 0 2.2 1 2.2 2.2s-1 2.2-2.2 2.2-2.2-1-2.2-2.2 1-2.2 2.2-2.2m-8 12.8 8-6 8 6c2.8 2.1 4.5 5.5 4.5 9 0 1.6-1.3 2.9-2.9 2.9H22.3c-1.6 0-2.9-1.3-2.9-2.9.1-3.5 1.8-6.9 4.6-9m-6.8 13.8h2.9c1.1 0 2 .9 2 2V41h-6.9v-3.7c0-1.1.9-2 2-2m-2 20V43.1h6.9v12.2zm26.7 0V43.1h6.9v12.2z" />
  </svg>
);
export default IconTajMahal;
