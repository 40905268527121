import * as React from "react";
const IconMonitorDollarLock = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 34.7h-2v-3c0-2.7-2.2-4.9-4.9-4.9s-4.9 2.2-4.9 4.9v3h-2c-.6 0-1 .4-1 1v8c0 .6.4 1 1 1H61c.6 0 1-.4 1-1v-8c0-.5-.4-1-1-1m-9.8-3c0-1.6 1.3-2.9 2.9-2.9s2.9 1.3 2.9 2.9v3h-5.8zM60 42.8H48.2v-6H60zM26.7 20.7h6.2c.6 0 1-.4 1-1s-.4-1-1-1h-2.8v-1.8c0-.6-.4-1-1-1s-1 .4-1 1v1.8h-1.4c-2.4 0-4.3 1.9-4.3 4.3s1.9 4.3 4.3 4.3h2.9c1.3 0 2.3 1 2.3 2.3S30.8 32 29.5 32h-6.2c-.6 0-1 .4-1 1s.4 1 1 1H28v1.8c0 .6.4 1 1 1s1-.4 1-1v-1.9c2.1-.3 3.8-2.1 3.8-4.3 0-2.4-1.9-4.3-4.3-4.3h-2.9c-1.3 0-2.3-1-2.3-2.3s1.1-2.3 2.4-2.3" />
    <path d="M39.4 42.1H6.8c-1.5 0-2.8-1.2-2.8-2.8V12.1c0-1.5 1.2-2.8 2.8-2.8h42.7c1.5 0 2.8 1.2 2.8 2.8v9.2c0 .6.4 1 1 1s1-.4 1-1v-9.2c0-2.6-2.1-4.8-4.8-4.8H6.8C4.1 7.4 2 9.5 2 12.1v27.2c0 2.6 2.1 4.8 4.8 4.8h20.4v10.5h-8.3c-.6 0-1 .4-1 1s.4 1 1 1h18.6c.6 0 1-.4 1-1s-.4-1-1-1h-8.3V44.1h10.3c.6 0 1-.4 1-1s-.6-1-1.1-1" />
  </svg>
);
export default IconMonitorDollarLock;
