import * as React from "react";
const IconPallet = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m55.9 43.9-.3-.2c-.8-.5-1.7-.9-3.8-1.5h-.1c-.7-.1-1.5-.4-1.8-.6l-.1-.1c-.4-.4-.7-1.1-.7-1.9 0-.7.3-1.4.7-1.9l3.9-3.9c6.9-6.9 6.9-17.8 0-24.8-4.6-4.7-11-7.2-18.1-7.2-7.9 0-15.9 3.4-22 9.4C8 16.9 5 24.3 5 32.4S8.1 48 13.8 53.5c5.7 5.7 13.2 8.8 21.2 8.8 7.9 0 15.4-3.2 21.1-8.9 1.2-1.2 1.9-3 1.9-4.7 0-1.9-.8-3.7-2.1-4.8m-3 6.3c-4.8 4.9-11.2 7.6-17.9 7.6-6.9 0-13.1-2.6-18-7.5-4.8-4.7-7.5-11-7.5-17.9s2.6-13.1 7.4-18C22.2 9.2 29 6.2 35.8 6.2c5.9 0 11.1 2 14.9 5.9 5.2 5.3 5.2 13.3 0 18.5l-4 4c-1.2 1.3-1.9 3.1-1.9 5 0 2 .7 3.7 1.9 5s3.6 1.9 4.2 2c1.4.3 1.9.6 2.3.8.2.3.4.8.4 1.3-.1.5-.4 1.2-.7 1.5" />
    <path d="M36.8 36.7c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5M47.7 27.3c0-3.3-2.7-6.1-6.1-6.1s-6.1 2.7-6.1 6.1c0 3.3 2.7 6.1 6.1 6.1s6.1-2.7 6.1-6.1m-6.1 1.6c-.9 0-1.6-.7-1.6-1.6s.7-1.6 1.6-1.6 1.6.7 1.6 1.6-.7 1.6-1.6 1.6M37.3 16.7c0-4-3.3-7.3-7.3-7.3s-7.3 3.3-7.3 7.3S26 24 30 24s7.3-3.2 7.3-7.3M30 19.6c-1.6 0-2.8-1.3-2.8-2.8 0-1.6 1.3-2.8 2.8-2.8s2.8 1.3 2.8 2.8-1.2 2.8-2.8 2.8" />
  </svg>
);
export default IconPallet;
