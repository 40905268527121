import React from "react";
import BringzzText from "../BringzzText";
import BringzzImage from "../BringzzImage";
import classnames from "classnames";
import { TEXTSTYLES } from "../../utils/Textstyles";

export const REBATEPOSITION = {
  LEFT: "left-0",
  RIGHT: "right-0",
};

export const REBATETYPE = {
  CURRENCY: "€",
  PERCENT: "%",
};

const DEFAULT_PRODUCTBOX_CLASSNAMES =
  "flex flex-col min-w-40 max-w-40 bg-white rounded-lg";

/**
 * @typedef {object} BringzzProductBoxProps
 * @property {string} productId - The unique identifier for the product (required).
 * @property {string} image - The path to the product's image. (required)
 * @property {string} manufacturer - The manufacturer of the product (required).
 * @property {string} label - The label or name of the product (required).
 * @property {JSX.Element} [starRating] - JSX element representing the star rating.
 * @property {string} [text] - Additional text or description of the product.
 * @property {string} [price] - The price of the product.
 * @property {number} [rebateValue] - The value of the rebate, if applicable.
 * @property {('€'|'%')} [rebateType=REBATETYPE.CURRENCY] - The type of the rebate, either currency or percentage.
 * @property {string} [rebateLabel="off"] - Label for the rebate.
 * @property {('left-0'|'right-0')} [rebatePosition=REBATEPOSITION.LEFT] - Position of the rebate label on the product box.
 * @property {JSX.Element} [topLeftChildren] - JSX element(s) to be rendered in the top-left corner.
 * @property {JSX.Element} [topRightChildren] - JSX element(s) to be rendered in the top-right corner.
 * @property {function} [onClick] - Click handler function for the product box.
 * @property {string} [classname="flex flex-col min-h-72 max-h72 min-w-48 max-w-48"] - Additional CSS class names for styling.
 * @property {React.ReactNode} [children] - Child elements or components.
 */

/**
 * A component representing a product box with various information and actions.
 * @param {BringzzProductBoxProps} props - The props for the component.
 * @returns {JSX.Element} - The rendered product box component.
 */
export default function BringzzProductBox({
  productId,
  image,
  manufacturer,
  label,
  starRating,
  price,
  rebateValue,
  rebateType = REBATETYPE.CURRENCY,
  // TODO: Add multi language
  rebateLabel = "off",
  productType,
  productQuantity,
  rebatePosition = REBATEPOSITION.LEFT,
  topLeftChildren,
  topRightChildren,
  onClick,
  className,
  children,
  ...props
}) {
  const onClickHandler = () => {
    if (onClick) {
      onClick(productId);
    }
  };

  return (
    <div
      className={classnames(DEFAULT_PRODUCTBOX_CLASSNAMES, className)}
      onClick={onClickHandler}
      {...props}
    >
      <div className="w-40 h-40 min-h-40 max-h-40 w-full relative">
        <BringzzImage
          src={image}
          loading="auto"
          className="w-full h-full rounded-md bg-gray-200"
          alt="product img"
        />
        {topLeftChildren && (
          <div className="absolute top-0 left-0">{topLeftChildren}</div>
        )}
        {topRightChildren && (
          <div className="absolute top-0 right-0">{topRightChildren}</div>
        )}

        {rebateValue && (
          <div
            className={classnames(
              "absolute bottom-0 bg-lemon-yellow h-6 w-16 text-dark text-center flex items-center justify-center",
              rebatePosition === "right-0"
                ? "rounded-tl-lg rounded-br-lg"
                : "rounded-tr-lg rounded-bl-lg",
              rebatePosition
            )}
          >
            <BringzzText className={"font-normal text-xs"}>{`${rebateValue}${rebateType} ${rebateLabel}`}</BringzzText>

          </div>
        )}
      </div>

      <div className="p-1">
        <div className="px-1">
          <BringzzText
            className={"font-normal text-xs mt-2 mb-1"}
          >
            {manufacturer}
          </BringzzText>

          <BringzzText
            tag="h3"
            className={"my-0 font-normal cursor-pointer"}
          >
            {label}
          </BringzzText>
          {starRating && <div>{starRating}</div>}
          <div className="flex flex-col">
            <div>
              <BringzzText
                className={"font-normal text-xs"}
              >
                {productType}
              </BringzzText>
            </div>
            <div className="flex justify-between items-center">
              <BringzzText
                className={"font-normal text-xs"}
              >
                {productQuantity}
              </BringzzText>
              <BringzzText
                tag="h3"
                className={"font-normal"}
              >{`€ ${price}`}</BringzzText>
            </div>
          </div>
        </div>

        {children && <div className="h-9 mt-5">{children}</div>}
      </div>
    </div>
  );
}
