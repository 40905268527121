import * as React from "react";
const IconFamilyPlanning = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      d="M15 12a1 1 0 1 0 0-2 1 1 0 0 0 0 2M12 11a1 1 0 1 1-2 0 1 1 0 0 1 2 0M15 16a1 1 0 1 0 0-2 1 1 0 0 0 0 2M12 15a1 1 0 1 1-2 0 1 1 0 0 1 2 0M19 12a1 1 0 1 0 0-2 1 1 0 0 0 0 2M20 15a1 1 0 1 1-2 0 1 1 0 0 1 2 0M23 12a1 1 0 1 0 0-2 1 1 0 0 0 0 2M24 15a1 1 0 1 1-2 0 1 1 0 0 1 2 0"
    />
    <path
      
      fillRule="evenodd"
      d="M6 8a2 2 0 0 1 2-2h18a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2zm2 0h18v10H8zM28 22v18h-1a1 1 0 1 0 0 2h14a1 1 0 1 0 0-2h-1V22c0-2.22-1.207-4.16-3-5.197V16a3 3 0 1 0-6 0v.803A6 6 0 0 0 28 22m5-4.044-.998.578A4 4 0 0 0 30 22v18h8V22c0-1.478-.8-2.771-2.001-3.466L35 17.956V16a1 1 0 1 0-2 0zM13 27.778c0-.842.658-1.547 1.542-1.731l-.93-1.882L6.48 25.97 6 24.03l7.133-1.806a1.97 1.97 0 0 1 2.246 1.046L16 24.528l.621-1.258a1.97 1.97 0 0 1 2.246-1.046L26 24.03l-.48 1.94-7.132-1.805-.93 1.882c.884.184 1.542.89 1.542 1.73v10.445c0 .982-.895 1.778-2 1.778v2h-2v-2c-1.105 0-2-.796-2-1.778zm2 10.444V27.778h2v10.444z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconFamilyPlanning;
