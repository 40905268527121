import * as React from "react";
const IconDivisionAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 31H3c-.6 0-1 .4-1 1s.4 1 1 1h58c.6 0 1-.4 1-1s-.4-1-1-1M32 20.5c4.9 0 9-4 9-9s-4-9-9-9c-4.9 0-9 4-9 9s4.1 9 9 9m0-15.9c3.8 0 7 3.1 7 7 0 3.8-3.1 7-7 7-3.8 0-7-3.1-7-7s3.2-7 7-7M32 43.5c-4.9 0-9 4-9 9s4 9 9 9c4.9 0 9-4 9-9s-4.1-9-9-9m0 15.9c-3.8 0-7-3.1-7-7s3.1-7 7-7c3.8 0 7 3.1 7 7s-3.2 7-7 7" />
  </svg>
);
export default IconDivisionAlt;
