import * as React from "react";
const IconShirt3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M48.7 8.4h-1.5l-4.5-6c-.2-.2-.4-.4-.7-.4s-.5.1-.8.2L32 10.1l-9.3-7.8c-.2-.2-.5-.3-.7-.3-.3 0-.5.2-.7.4l-4.5 6h-1.5c-4.5 0-8.1 3.6-8.1 8.1v40.2c0 2.9 2.4 5.3 5.3 5.3h39.1c2.9 0 5.3-2.4 5.3-5.3V16.5c-.1-4.5-3.7-8.1-8.2-8.1m-7-4 3.8 5-7 12.4-5.2-10.3zm-19.4 0 8.5 7.2-5.2 10.3-7-12.4zm32.5 52.3c0 1.8-1.5 3.3-3.3 3.3H12.4c-1.8 0-3.3-1.5-3.3-3.3V16.5c0-3.4 2.7-6.1 6.1-6.1h1.4l8 14.1c.2.3.5.5.9.5s.7-.2.9-.5L32 13.6l5.6 10.9c.2.3.5.5.9.5s.7-.2.9-.5l8-14.1h1.4c3.4 0 6.1 2.7 6.1 6.1v40.2z" />
    <path d="M32 29.5c-2.1 0-3.8 1.7-3.8 3.8s1.7 3.8 3.8 3.8 3.8-1.7 3.8-3.8-1.7-3.8-3.8-3.8m0 5.7c-1 0-1.8-.8-1.8-1.8s.8-1.8 1.8-1.8 1.8.8 1.8 1.8-.8 1.8-1.8 1.8M32 44.7c-2.1 0-3.8 1.7-3.8 3.8s1.7 3.8 3.8 3.8 3.8-1.7 3.8-3.8-1.7-3.8-3.8-3.8m0 5.6c-1 0-1.8-.8-1.8-1.8s.8-1.8 1.8-1.8 1.8.8 1.8 1.8-.8 1.8-1.8 1.8" />
  </svg>
);
export default IconShirt3;
