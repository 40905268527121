import * as React from "react";
const IconGraphGrowth = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62 14.7v-.2c0-.1 0-.1-.1-.2 0 0 0-.1-.1-.1l-.1-.1-.1-.1c-.1 0-.1-.1-.2-.1l-.1-.1L50.9 11c-.5-.1-1.1.2-1.2.7s.2 1.1.7 1.2l8.2 2.1-10.5 7.5c-.7-3.9-2.6-7.7-5.6-10.7-3.9-3.9-9-6-14.4-6s-10.6 2.1-14.4 6-6 9-6 14.4c0 .8.1 1.6.2 2.4l-5.5 4.3c-.4.3-.5 1-.2 1.4.2.3.5.4.8.4.2 0 .4-.1.6-.2L8.3 31c.9 3.7 2.7 7 5.4 9.7 3.9 3.9 9 6 14.4 6 5.1 0 9.9-1.9 13.7-5.3l16.4 16.4c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4L43.2 40c3.9-4.3 5.6-9.7 5.2-15.1l10.9-7.8-2 7.5c-.1.5.2 1.1.7 1.2h.3c.4 0 .8-.3 1-.7L62 15zM9.8 26.3c0-4.9 1.9-9.5 5.4-13s8.1-5.4 13-5.4 9.5 1.9 13 5.4c3 3 4.7 6.7 5.2 10.6l-9.5 6.8c-1.5 1-3.4.8-4.6-.5L25.1 22c-1.9-2.2-5.2-2.5-7.5-.7l-7.8 6.1zm31.4 13s-.1 0 0 0q-.15 0 0 0c-3.5 3.5-8.1 5.4-13 5.4s-9.5-1.9-13-5.4c-2.7-2.7-4.4-6-5.1-9.7l8.8-6.8c1.4-1.1 3.5-.9 4.7.5l7.1 8.2c1.9 2.1 5 2.5 7.3.9l8.5-6.1c0 4.7-1.7 9.4-5.3 13" />
  </svg>
);
export default IconGraphGrowth;
