import * as React from "react";
const IconCamera3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.8 18v-2.6c0-2.2-1.8-4-4-4h-4.5c-2.2 0-4 1.8-4 4v2.5h-5.9v-1.6c0-3.4-2.8-6.2-6.2-6.2H21.6c-3.4 0-6.2 2.8-6.2 6.2v1.6H8.5c-3.4 0-6.2 2.8-6.2 6.2v23.6c0 3.4 2.8 6.2 6.2 6.2h47.2c3.4 0 6.3-2.8 6.3-6.2V24.1c0-3.1-2.3-5.7-5.2-6.1m-10.5-2.6c0-1.1.9-2 2-2h4.5c1.1 0 2 .9 2 2v2.5h-8.5zM8.5 19.9h7.9c.6 0 1-.4 1-1v-2.6c0-2.3 1.9-4.2 4.2-4.2h10.5c2.3 0 4.2 1.9 4.2 4.2v2.6c0 .6.4 1 1 1h18.4c2.3 0 4.3 1.9 4.3 4.2v5.7H37.7c-1.7-3.5-5.3-5.9-9.5-5.9s-7.7 2.4-9.5 5.9H4.3v-5.7c0-2.3 1.9-4.2 4.2-4.2m11.1 14.6c0-4.7 3.9-8.6 8.6-8.6s8.6 3.9 8.6 8.6-3.9 8.6-8.6 8.6-8.6-3.8-8.6-8.6m-.8 5H4.3v-7.7H18c-.2.9-.4 1.8-.4 2.7 0 1.8.4 3.5 1.2 5m19.6-7.7H60v7.7H37.6c.8-1.5 1.2-3.2 1.2-5 0-.9-.1-1.8-.4-2.7M55.8 52H8.5c-2.3 0-4.2-1.9-4.2-4.2v-6.3H20v-.2c1.9 2.4 4.9 3.9 8.2 3.9 3.2 0 6-1.4 8-3.6H60v6.3c0 2.2-1.9 4.1-4.2 4.1" />
  </svg>
);
export default IconCamera3;
