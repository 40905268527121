import * as React from "react";
const IconFactoryAlt5 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.1 22.5c-1.8-.9-3.9-.6-5.4.6l-9.2 7.3v-3.3c0-2-1.1-3.8-2.9-4.6-1.8-.9-3.9-.6-5.4.6L27 30.4V11.1c0-2.2-1.8-4-4-4H6c-2.2 0-4 1.8-4 4V53c0 2.2 1.8 4 4 4h50.9c2.8 0 5.1-2.3 5.1-5.1V27.1c0-2-1.1-3.8-2.9-4.6M4 52.9V11.1c0-1.1.9-2 2-2h16.9c1.1 0 2 .9 2 2V55H6c-1.1-.1-2-1-2-2.1m22.9-20 10.5-8.3c1-.8 2.2-.9 3.3-.4s1.8 1.6 1.8 2.8v27.8H26.9zM60 51.8c0 1.7-1.4 3.1-3.1 3.1H44.4v-22l10.5-8.3c1-.8 2.2-.9 3.3-.4S60 25.8 60 27z" />
  </svg>
);
export default IconFactoryAlt5;
