import * as React from "react";
const IconDiamondAltCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.7 24.4-9-20.6c-.3-.7-.9-1.3-1.6-1.6-.1-.1-.2-.1-.3-.2H14c-1.2 0-2.2.7-2.7 1.8l-9 20.6c-.4 1-.3 2.2.4 3.1l27 33.2c.5.9 1.3 1.3 2.3 1.3.9 0 1.8-.4 2.4-1.2l27.1-33.3c.6-.9.7-2.1.2-3.1m-1.8.8c.1.2.1.3.1.5H43.9l7-21zM32 57.6l-9.2-29.9h18.4zM42.4 24 33 4h16.1zM41 25.7H23l9-19.2zM31 4l-9.5 20.2L13.9 4H31M12.5 6.1 20 25.7H4c0-.2 0-.3.1-.5zM5.3 27.7h15.4l9.4 30.5zm28.6 30.5 9.4-30.5h15.3z" />
  </svg>
);
export default IconDiamondAltCopy;
