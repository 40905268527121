import * as React from "react";
const Icon2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M35 18a9 9 0 0 0-9-9h-4a9 9 0 0 0-8.487 6 3 3 0 1 0 5.657 2A3 3 0 0 1 22 15h4a3 3 0 0 1 2 5.237l-.085.078-14 13.528A3 3 0 0 0 16 39h16a3 3 0 1 0 0-6h-8.578l8.626-8.335A8.99 8.99 0 0 0 35 18m-9-7a7 7 0 0 1 4.67 12.216L20.252 33.281A1 1 0 0 0 20.948 35H32a1 1 0 1 1 0 2H16a1 1 0 0 1-.695-1.72l14-13.527-.695-.719.695.72.028-.027A5 5 0 0 0 26 13h-4a5 5 0 0 0-4.716 3.333 1 1 0 1 1-1.885-.666A7 7 0 0 1 22 11z"
      clipRule="evenodd"
    />
  </svg>
);
export default Icon2;
