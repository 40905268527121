import React from 'react';
import PropTypes from 'prop-types';
import BringzzListItem from './BringzzListItem';
import BringzzText from '../BringzzText';


const BringzzList = ({ items = [], type, fixed = true, handleBookmarkClick }) => {
    return (
        <div className="flex flex-col">
            {items.map(item => (
                <BringzzListItem type={type} key={item.id} item={item} handleBookmarkClick={handleBookmarkClick} />
            ))}
            {/* {type == "bookmarks" && !fixed &&
                <div className='flex h-20 items-center p-4 relative overflow-hidden border-b cursor-pointer'>
                    <div className={`flex w-full pr-2`}>
                        <div className="flex-none w-14 h-14 rounded-full bg-gray-200 mr-4"></div>
                        <div className="flex-grow flex flex-col justify-center">
                            <BringzzText
                                tag='h4'
                            >
                                Add new Collection
                            </BringzzText>
                        </div>
                    </div>
                </div>
            } */}
        </div>
    );
};

BringzzList.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        title: PropTypes.string.isRequired,
        body: PropTypes.string,
        subTitle: PropTypes.string,
    })).isRequired,
    type: PropTypes.oneOf(['notifications', 'bookmarks']).isRequired,
    fixed: PropTypes.bool
};

export default BringzzList;
