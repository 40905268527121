import * as React from "react";
const IconCheckmarkCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.7 13.3c-.4-.4-1-.4-1.4 0L22.8 49.6 3.7 31.1c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l19.1 18.6c.4.4.9.6 1.4.6s1-.2 1.4-.6l37.5-36.4c.4-.4.4-1.1 0-1.4" />
  </svg>
);
export default IconCheckmarkCopy;
