import * as React from "react";
const IconGrid1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.2 2.1H8.8C5.1 2.1 2 5.1 2 8.9v46.4c0 3.7 3.1 6.8 6.8 6.8h46.4c3.7 0 6.8-3.1 6.8-6.8V8.9c0-3.8-3.1-6.8-6.8-6.8M60 8.9v12.7H33V4.1h22.2c2.6 0 4.8 2.1 4.8 4.8M33 23.6h27v17H33zm-2 17H4v-17h27zM8.8 4.1H31v17.5H4V8.9c0-2.7 2.2-4.8 4.8-4.8M4 55.3V42.6h27v17.5H8.8c-2.6 0-4.8-2.2-4.8-4.8m51.2 4.8H33V42.6h27v12.7c0 2.6-2.2 4.8-4.8 4.8" />
  </svg>
);
export default IconGrid1;
