import * as React from "react";
const IconDollarBag1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 24.5h-4.8v-3.4c0-2.9-2.3-5.2-5.2-5.2h-1.4v-5.4c0-2.2-1.8-4-4-4H12.8c-2.4 0-4.4 2-4.4 4.4v15.9C4.5 30 2 34.8 2 40.2c0 9.5 7.7 17.3 17.3 17.3 7 0 13.1-4.2 15.8-10.3h16c2.9 0 5.2-2.3 5.2-5.2v-6.2H61c.6 0 1-.4 1-1v-9.4c0-.5-.4-.9-1-.9M10.4 10.9c0-1.3 1.1-2.4 2.4-2.4h32.8c1.1 0 2 .9 2 2v5.4H12.8c-1.3 0-2.4-1.1-2.4-2.4zm8.9 44.6C10.8 55.5 4 48.7 4 40.2 4 31.8 10.8 25 19.3 25s15.3 6.8 15.3 15.3c-.1 8.4-6.9 15.2-15.3 15.2m34.9-13.4c0 1.8-1.4 3.2-3.2 3.2H35.8c.5-1.6.7-3.3.7-5 0-9.5-7.7-17.3-17.3-17.3-3.2 0-6.3.9-8.8 2.5v-8.3c.7.4 1.5.7 2.4.7H51c1.8 0 3.2 1.4 3.2 3.2v3.4h-4.4c-2.4 0-4.4 2-4.4 4.4v2.7c0 2.4 2 4.4 4.4 4.4h4.4zm5.8-8.2H49.8c-1.3 0-2.4-1.1-2.4-2.4v-2.7c0-1.3 1.1-2.4 2.4-2.4H60z" />
    <path d="M18 35.4h5.5c.6 0 1-.4 1-1s-.4-1-1-1h-2.4v-1.5c0-.6-.4-1-1-1s-1 .4-1 1v1.5H18c-2.2 0-4 1.8-4 3.9s1.8 3.9 4 3.9h2.5c1.1 0 2 .9 2 1.9s-.9 1.9-2 1.9H15c-.6 0-1 .4-1 1s.4 1 1 1h4.1v1.5c0 .6.4 1 1 1s1-.4 1-1v-1.6c1.9-.3 3.4-1.9 3.4-3.9 0-2.2-1.8-3.9-4-3.9H18c-1.1 0-2-.9-2-1.9s.9-1.8 2-1.8" />
  </svg>
);
export default IconDollarBag1;
