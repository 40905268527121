import * as React from "react";
const IconFirecrackersAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M47.3 23.9c.6 0 1-.4 1-1v-7.7C48.3 7.9 42.4 2 35.1 2h-4.5c-7.3 0-13.2 5.9-13.2 13.1H9.6c-2.2 0-4 1.8-4 4V58c0 2.2 1.8 4 4 4h17.7c2.2 0 4-1.8 4-4V19.1c0-2.2-1.8-4-4-4h-7.9C19.5 9 24.5 4 30.7 4h4.5c6.2 0 11.2 5 11.2 11.2v7.7c-.1.6.4 1 .9 1m-18 26.2H7.6v-23h21.7zm-2 9.9H9.6c-1.1 0-2-.9-2-2v-5.9h21.7V58c0 1.1-.9 2-2 2m2-40.9V25H7.6v-5.9c0-1.1.9-2 2-2h17.7c1.1 0 2 .9 2 2M47.3 29c-.6 0-1 .4-1 1v3.6c0 .6.4 1 1 1s1-.4 1-1V30c0-.6-.4-1-1-1M57.9 30.7l-3.7-2.1c-.5-.3-1.1-.1-1.4.4s-.1 1.1.4 1.4l3.7 2.1c.2.1.3.1.5.1.3 0 .7-.2.9-.5.3-.5.1-1.1-.4-1.4" />
    <path d="m40.5 28.6-3.7 2.1c-.5.3-.7.9-.4 1.4.2.3.5.5.9.5.2 0 .3 0 .5-.1l3.7-2.1c.5-.3.7-.9.4-1.4s-.9-.6-1.4-.4" />
  </svg>
);
export default IconFirecrackersAlt1;
