import * as React from "react";
const IconSofa6 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.2 27.8v-2c0-3.5-2.8-6.3-6.3-6.3H16.1c-3.5 0-6.3 2.8-6.3 6.3v2c-2.5.6-4.4 2.9-4.4 5.6v7.8c0 4.9 2.5 9.2 6.2 11.8v8c0 .6.4 1 1 1s1-.4 1-1v-6.9c1.8.9 3.9 1.4 6.1 1.4h24.8c2.2 0 4.2-.5 6.1-1.4V61c0 .6.4 1 1 1s1-.4 1-1v-8c3.7-2.6 6.2-6.9 6.2-11.8v-7.8c-.1-2.8-2-5-4.6-5.6m-2-2v1.9c-2.8.4-5 2.8-5 5.7v6.3c0 2.4-1.9 4.3-4.3 4.3H33V21.5h14.9c2.4 0 4.3 1.9 4.3 4.3m-36.1-4.3H31V44h-9.9c-2.4 0-4.3-1.9-4.3-4.3v-6.3c0-2.9-2.2-5.3-5-5.7v-1.9c0-2.4 1.9-4.3 4.3-4.3m40.6 19.7c0 6.8-5.5 12.3-12.3 12.3H19.6c-6.8 0-12.3-5.5-12.3-12.3v-7.8c0-2.1 1.7-3.7 3.7-3.7 2.1 0 3.7 1.7 3.7 3.7v6.3c0 3.5 2.8 6.3 6.3 6.3h21.9c3.5 0 6.3-2.8 6.3-6.3v-6.3c0-2.1 1.7-3.7 3.7-3.7s3.7 1.7 3.7 3.7v7.8zM17.1 13.9h29.7c.6 0 1-.4 1-1V3c0-.6-.4-1-1-1H17.1c-.6 0-1 .4-1 1v9.9c0 .6.5 1 1 1m1-9.9h27.7v7.9H18.1z" />
  </svg>
);
export default IconSofa6;
