import * as React from "react";
const IconMobileRocket2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60 9.1c0-.5-.4-.9-.9-.9-4.2-.2-9.3.6-16.4 7.9l-6.1 6.4-7.1.1c-.3 0-.5.1-.7.3l-4.2 4.4c-.4.4-.4 1 0 1.4l4.7 4.5q-.75.3-1.2.9c-1.7 1.8-2.5 7.7-2.6 8.8 0 .3.1.6.3.8s.4.3.7.3h.2c1.2-.2 7-1.2 8.7-2.9.4-.4.7-.8.9-1.3l4.7 4.5c.2.2.4.3.7.3s.5-.1.7-.3l4.2-4.4c.2-.2.3-.4.3-.7l-.1-7.1 6.1-6.4c6.8-7.2 7.5-12.4 7.1-16.6M33.8 39.8c-.8.8-3.8 1.6-6.3 2.1.4-2.5 1.1-5.5 1.9-6.3.4-.4.8-.7 1.4-.8l3.8 3.6c-.2.5-.5 1-.8 1.4m11-8.7c-.2.2-.3.4-.3.7l.1 7.1-3.2 3.4L26.6 28l3.2-3.4 7.1-.1c.3 0 .5-.1.7-.3l6.4-6.7c6-6.2 10.3-7.3 14-7.3.1 3.7-.9 8.1-6.8 14.2z" />
    <path d="M40.2 48.9c-.6 0-1 .4-1 1v.4H5.9v-41C5.9 6.4 8.3 4 11.2 4h22.7c2.9 0 5.3 2.4 5.3 5.3v1.6c0 .6.4 1 1 1s1-.4 1-1V9.3c0-4-3.3-7.3-7.3-7.3H11.2c-4 0-7.3 3.3-7.3 7.3v45.5c0 4 3.3 7.3 7.3 7.3h22.7c4 0 7.3-3.3 7.3-7.3V50c0-.7-.5-1.1-1-1.1M33.9 60H11.2c-2.9 0-5.3-2.4-5.3-5.3v-2.4h33.3v2.5c0 2.8-2.4 5.2-5.3 5.2" />
  </svg>
);
export default IconMobileRocket2;
