import * as React from "react";
const IconSuitcaseMedical = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.5 14.7H42.7v-3.8c0-2.6-2.1-4.7-4.7-4.7H26c-2.6 0-4.7 2.1-4.7 4.7v3.8H9.5c-4.1 0-7.5 3.4-7.5 7.5v28c0 4.1 3.4 7.5 7.5 7.5h45c4.1 0 7.5-3.4 7.5-7.5v-28c0-4.1-3.4-7.5-7.5-7.5m-31.2-3.8c0-1.5 1.2-2.7 2.7-2.7h12c1.5 0 2.7 1.2 2.7 2.7v3.8H23.3zM60 50.2c0 3-2.5 5.5-5.5 5.5h-45c-3 0-5.5-2.5-5.5-5.5v-28c0-3 2.5-5.5 5.5-5.5h44.9c3 0 5.5 2.5 5.5 5.5v28z" />
    <path d="M46.9 41.2h-6.2l-6.3-12.6c-.3-.5-.8-.8-1.3-.8-.6 0-1 .3-1.3.8l-6.1 13.5-3.7-7.4h-7.6c-.6 0-1 .4-1 1s.4 1 1 1h6.4l3.7 7.5c.2.5.7.8 1.2.8s1-.3 1.2-.8L33 30.6l6.3 12.6h7.5c.6 0 1-.4 1-1s-.3-1-.9-1" />
  </svg>
);
export default IconSuitcaseMedical;
