import * as React from "react";
const IconTomato = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.5 20.9c-5.3-4.1-12-4.9-18.5-5l9-9c.4-.4.4-1 0-1.4s-1-.4-1.4 0L33 13V3c0-.6-.4-1-1-1s-1 .4-1 1v10l-7.6-7.6c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l9 9c-3.2 0-6.1.3-8.8.8-3.3.7-6.2 1.9-8.5 3.5-4.9 3.3-8 8.7-8.3 14.3v.9c0 8.9 4.2 16.9 11.6 22.1 3 2.1 6.6 3.6 10.2 4.2 1.7.3 3.3.4 4.9.4 7.1 0 13.8-2.7 18.9-7.8 5-5 7.8-11.8 7.8-18.9-.1-5.7-2.7-10.9-7.3-14.4m-2 31.9c-5.8 5.8-13.8 8.3-22 6.8-3.4-.6-6.6-1.9-9.4-3.9-6.9-4.8-10.8-12.2-10.8-20.4v-.8c.3-5 3-9.7 7.4-12.7 2.1-1.5 4.8-2.5 7.8-3.2 2.9-.6 6.1-.9 9.8-.8 6.4.1 13 .8 17.9 4.6 4.1 3.1 6.4 7.8 6.4 12.8.1 6.7-2.5 12.9-7.1 17.6" />
  </svg>
);
export default IconTomato;
