import * as React from "react";
const IconMoonStar = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.9 46.5C42.7 47.7 31.6 39.6 29 27.6c-1.8-8 .7-16.3 6.5-22.2.6-.6.8-1.4.5-2.1S35 2.1 34.3 2C28.2 1.6 22.4 3.1 17 6.3c-9.3 5.6-15 16.2-14.4 27 .5 8.9 4.8 17.1 11.8 22.5 5.3 4 11.7 6.2 18.2 6.2 2.1 0 4.3-.2 6.4-.7 7.2-1.6 13.6-5.7 17.9-11.7.4-.6.5-1.4.1-2.1-.6-.7-1.3-1.1-2.1-1M38.4 59.4c-8 1.8-16.4-.1-23-5.1-6.5-5-10.6-12.6-11-21-.5-10.1 4.7-20 13.5-25.2C22.4 5.4 27.2 4 32.3 4c.6 0 1.2 0 1.7.1-6.3 6.3-8.9 15.3-7 24C29.8 41 41.9 49.8 55.1 48.5c-4.1 5.5-10 9.4-16.7 10.9m16.7-10.9-.1-1zq0-.15 0 0M45.5 14.2l-.7 4.2c-.1.6.2 1.3.7 1.7s1.2.4 1.8.1l3.8-2 3.8 2c.3.1.5.2.8.2.4 0 .7-.1 1-.3.5-.4.8-1 .7-1.7l-.7-4.2 3.1-3c.5-.5.6-1.1.4-1.8s-.7-1.1-1.4-1.2l-4.2-.6-1.9-3.8c-.3-.6-.9-1-1.5-1s-1.2.4-1.5 1l-1.9 3.8-4.2.6c-.7.1-1.2.5-1.4 1.2s0 1.3.4 1.8zm2.6-4.6c.6-.1 1-.4 1.3-.9l1.7-3.4 1.7 3.4c.3.5.7.9 1.3.9l3.8.6-2.7 2.7c-.4.4-.6 1-.5 1.5l.6 3.8-3.4-1.8c-.5-.3-1.1-.3-1.6 0l-3.4 1.8.6-3.8c.1-.6-.1-1.1-.5-1.5l-2.8-2.7z" />
    <path d="M61.5 33.5c-.2-.6-.7-1.1-1.4-1.2l-2.5-.4-1.1-2.2c-.3-.6-.9-1-1.5-1s-1.2.4-1.5 1l-1.1 2.2-2.5.4c-.7.1-1.2.5-1.4 1.2-.2.6 0 1.3.4 1.8l1.8 1.7-.4 2.5c-.1.6.2 1.3.7 1.7.3.2.7.3 1 .3s.5-.1.8-.2l2.2-1.2 2.2 1.2c.6.3 1.3.3 1.8-.1s.8-1 .7-1.7l-.4-2.5 1.8-1.7c.4-.5.6-1.2.4-1.8m-3.7 2.1c-.4.4-.6 1-.5 1.5l.3 2-1.8-1c-.2 0-.5-.1-.8-.1s-.5.1-.8.2l-1.8 1 .3-2c.1-.6-.1-1.1-.5-1.5l-1.5-1.4 2-.3c.6-.1 1-.4 1.3-.9l.9-1.9.9 1.9c.3.5.7.9 1.3.9l2 .3z" />
  </svg>
);
export default IconMoonStar;
