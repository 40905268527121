import * as React from "react";
const IconProcessorAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M38.9 20h-9.4q-2.1 0-3.6 1.5L21.5 26Q20 27.5 20 29.6V39c0 2.8 2.3 5.1 5.1 5.1H39c2.8 0 5.1-2.3 5.1-5.1V25.1c-.1-2.8-2.3-5.1-5.2-5.1M42 38.9c0 1.7-1.4 3.1-3.1 3.1H25.1c-1.7 0-3.1-1.4-3.1-3.1v-9.4c0-.8.3-1.6.9-2.2l4.5-4.5c.6-.6 1.4-.9 2.2-.9H39c1.7 0 3.1 1.4 3.1 3.1v13.9z" />
    <path d="M61 33c.6 0 1-.4 1-1s-.4-1-1-1h-6.7V19.3H61c.6 0 1-.4 1-1s-.4-1-1-1h-6.7v-2.5c0-2.8-2.3-5.1-5.1-5.1h-2.5v-6c0-.6-.4-1-1-1s-1 .4-1 1v6H33v-6c0-.6-.4-1-1-1s-1 .4-1 1v6H19.3v-6c0-.6-.4-1-1-1s-1 .4-1 1v6h-2.5c-2.8 0-5.1 2.3-5.1 5.1v2.5H3c-.6 0-1 .4-1 1s.4 1 1 1h6.7V31H3c-.6 0-1 .4-1 1s.4 1 1 1h6.7v11.7H3c-.6 0-1 .4-1 1s.4 1 1 1h6.7v2.5c0 2.8 2.3 5.1 5.1 5.1h2.5v6c0 .6.4 1 1 1s1-.4 1-1v-6H31v6c0 .6.4 1 1 1s1-.4 1-1v-6h11.7v6c0 .6.4 1 1 1s1-.4 1-1v-6h2.5c2.8 0 5.1-2.3 5.1-5.1v-2.5H61c.6 0 1-.4 1-1s-.4-1-1-1h-6.7V33zm-8.7 16.2c0 1.7-1.4 3.1-3.1 3.1H14.8c-1.7 0-3.1-1.4-3.1-3.1V14.8c0-1.7 1.4-3.1 3.1-3.1h34.4c1.7 0 3.1 1.4 3.1 3.1z" />
  </svg>
);
export default IconProcessorAlt;
