import * as React from "react";
const IconCarCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M17.1 31.4c-3.3 0-5.9 2.6-5.9 5.8s2.7 5.8 5.9 5.8c3.3 0 5.9-2.6 5.9-5.8s-2.6-5.8-5.9-5.8m0 9.7c-2.2 0-3.9-1.7-3.9-3.8s1.8-3.8 3.9-3.8c2.2 0 3.9 1.7 3.9 3.8.1 2.1-1.7 3.8-3.9 3.8M46.8 31.3c-3.3 0-5.9 2.6-5.9 5.9s2.7 5.9 5.9 5.9c3.3 0 6-2.6 6-5.9s-2.7-5.9-6-5.9m0 9.7c-2.2 0-3.9-1.7-3.9-3.9s1.8-3.9 3.9-3.9 4 1.7 4 3.9-1.8 3.9-4 3.9M34.5 36.2h-5c-.6 0-1 .4-1 1s.4 1 1 1h5c.6 0 1-.4 1-1s-.5-1-1-1" />
    <path d="m57.5 24.1-1-11.2c-.4-5.1-5.1-9.1-10.7-9.1H18.2c-5.6 0-10.3 4-10.7 9.1l-1 11.2C4 24.3 2 26.4 2 29v16.4c0 2.8 2.2 5 5 5h1.7v5.9c0 2.2 1.8 4 4 4h3.9c2.2 0 4-1.8 4-4v-5.9h22.8v5.9c0 2.2 1.8 4 4 4h3.9c2.2 0 4-1.8 4-4v-5.9H57c2.8 0 5-2.2 5-5V29c0-2.6-2-4.7-4.5-4.9M9.5 13c.3-4.1 4.2-7.3 8.7-7.3h27.6c4.5 0 8.4 3.2 8.7 7.3l1 11h-47zm9.1 43.3c0 1.1-.9 2-2 2h-3.9c-1.1 0-2-.9-2-2v-5.9h7.9zm34.7 0c0 1.1-.9 2-2 2h-3.9c-1.1 0-2-.9-2-2v-5.9h7.9zM60 45.4c0 1.7-1.3 3-3 3H7c-1.7 0-3-1.3-3-3V29c0-1.7 1.3-3 3-3h50c1.7 0 3 1.3 3 3z" />
  </svg>
);
export default IconCarCopy;
