import * as React from "react";
const IconTimeFolder = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M49.3 34.9c-7 0-12.7 5.7-12.7 12.7s5.7 12.7 12.7 12.7S62 54.6 62 47.6s-5.7-12.7-12.7-12.7m0 23.3c-5.9 0-10.7-4.8-10.7-10.7s4.8-10.7 10.7-10.7S60 41.6 60 47.5s-4.8 10.7-10.7 10.7" />
    <path d="M49.7 48.3v-3.7c0-.6-.4-1-1-1s-1 .4-1 1v4.1c0 .3.1.5.3.7l2.4 2.4c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4zM7.8 5.8h15l5.5 5.7c.2.2.4.3.7.3h24.5c2.1 0 3.8 1.7 3.8 3.8v11.5c0 .6.4 1 1 1s1-.4 1-1V15.6c0-3.2-2.6-5.8-5.8-5.8H29.4l-5.5-5.7c-.2-.2-.4-.3-.7-.3H7.8C4.6 3.8 2 6.4 2 9.6v35c0 3.2 2.6 5.8 5.8 5.8h19.9c.6 0 1-.4 1-1s-.4-1-1-1H7.8c-2.1 0-3.8-1.7-3.8-3.8v-35c0-2.1 1.7-3.8 3.8-3.8" />
  </svg>
);
export default IconTimeFolder;
