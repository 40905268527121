import * as React from "react";
const IconMitten = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.1 62h-28c-3.5 0-6.3-2.8-6.3-6.3v-2.9L6.6 41.4c-3.6-3.1-4-8.5-.9-12.1 1.5-1.7 3.6-2.8 5.8-2.9 2.3-.2 4.5.6 6.2 2.1l2 1.7v-7.3c0-11 8.3-20.1 18.9-20.8 5.9-.4 11.6 1.8 15.7 5.9 3.8 3.8 5.9 8.9 5.9 14.4v33.4c.2 3.4-2.7 6.2-6.1 6.2M12.2 28.4h-.5c-1.7.1-3.3.9-4.5 2.2-2.4 2.7-2.1 6.9.7 9.2l13.5 11.7c.2.2.3.5.3.8v3.4c0 2.4 1.9 4.3 4.3 4.3h28c2.4 0 4.3-1.9 4.3-4.3V22.3c0-4.9-1.9-9.5-5.4-12.9-3.6-3.8-8.8-5.7-14.1-5.4-9.5.6-17 8.9-17 18.8v9.5q0 .6-.6.9c-.4.2-.8.1-1.1-.2l-3.6-3c-1.2-1.1-2.7-1.6-4.3-1.6" />
  </svg>
);
export default IconMitten;
