import * as React from "react";
const IconBed1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m53.3 45.3-6-24.8V6.6C47.3 4 45.2 2 42.7 2H21.4c-2.6 0-4.6 2.1-4.6 4.6v13.9l-6 24.8V61c0 .6.4 1 1 1s1-.4 1-1v-2.8c0-1.9 7.5-4.6 19.3-4.6s19.3 2.7 19.3 4.6V61c0 .6.4 1 1 1s1-.4 1-1V45.6c-.1-.1-.1-.2-.1-.3M18.5 21.6h26.9l5.6 23H13zm.3-15C18.8 5.1 20 4 21.4 4h21.2c1.5 0 2.6 1.2 2.6 2.6v13H18.8zm13.2 45c-7.8 0-15.7 1.2-19.3 3.6v-8.7h38.6v8.7c-3.6-2.4-11.5-3.6-19.3-3.6" />
  </svg>
);
export default IconBed1;
