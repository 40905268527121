import { BringzzButton, BringzzIcon, BringzzBottomDrawer, BringzzInput, BringzzText, BringzzQrCode } from '@bringzz/components';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigation } from 'context/NavigationContext';
import { useParams } from 'react-router-dom';

const CameraPage = () => {
    const videoRef = useRef(null);
    const { backPress, navigate } = useNavigation();
    const [failDrawerOpened, setFailDrawerOpened] = useState(false)
    const [idCode, setIdCode] = useState('')
    const { mode, type } = useParams(); // type: pickup/collect, mode: collect/confirm/handover

    useEffect(() => {
        // Get access to the camera
        const startCamera = async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: true });
                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                }
            } catch (err) {
                console.error("Error accessing the camera: ", err);
            }
        };

        startCamera();

        // Cleanup on component unmount
        return () => {
            if (videoRef.current && videoRef.current.srcObject) {
                let stream = videoRef.current.srcObject;
                let tracks = stream.getTracks();
                tracks.forEach((track) => track.stop());
                videoRef.current.srcObject = null; // Clear the video element source
            }
        };
    }, []);

    return (
        <div className="fixed inset-0">
            {mode !== "collect" && <video
                ref={videoRef}
                autoPlay
                className="w-full h-full object-cover"
            />}

            <div className='absolute top-32 text-center w-full px-8 '>
                {mode === "handover" &&
                    <BringzzText tag='h4' className='bg-black/40 p-2 text-white font-normal'>Scan the QR-Code on the shopper’s
                        phone and handover your prescription</BringzzText>
                }
                {mode === "confirm" && <div>
                    <BringzzText tag='h4' className='bg-black/40 p-2 text-white font-normal'>Scan the QR-Code on the shopper’s
                        bag to confirm you have received your order</BringzzText>
                    <BringzzText tag='h4' className='bg-black/50 p-2 text-white font-normal'>No. of bags left to scan: X</BringzzText>
                </div>
                }
                {mode === "collect" && <div>
                    <BringzzText tag='h4' className='bg-black/70 p-2 text-white font-normal'>
                        Show this code to the pharmacist to verify yourself and receive your order. Then click continue.
                    </BringzzText>
                </div>
                }

            </div>

            {/* Overlay square in the center */}
            {mode == "collect" && <div className='flex justify-center items-center w-full h-full'><BringzzQrCode value={"123456798"} /></div>}
            {mode !== "collect" && <div
                className="absolute top-1/2 left-1/2 w-64 h-64 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none grid grid-cols-3 grid-rows-3"
            >
                {/* Top-left corner */}
                <div className="border-t-2 border-l-2 border-white bg-opacity-50 col-start-1 row-start-1" />

                {/* Top-right corner */}
                <div className="border-t-2 border-r-2 border-white bg-opacity-50 col-start-3 row-start-1" />

                {/* Bottom-left corner */}
                <div className="border-b-2 border-l-2 border-white bg-opacity-50 col-start-1 row-start-3" />

                {/* Bottom-right corner */}
                <div className="border-b-2 border-r-2 border-white bg-opacity-50 col-start-3 row-start-3" />
            </div>}

            {/* Top-left content area */}
            <div className="absolute top-0 left-0 p-5">
                <BringzzIcon
                    size="16"
                    icon="IconClose"
                    folder="LineIcons"
                    className="cursor-pointer bg-white rounded-full p-3"
                    onClick={backPress}
                />
            </div>

            {/* Bottom-center footer area */}
            {mode !== "collect" && <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 mb-6">
                <BringzzButton onClick={() => setFailDrawerOpened(true)} size='md' className='text-white border border-white'>QR-Code not working?</BringzzButton>
            </div>}
            {mode === "collect" && <div className="absolute bottom-0 left-1/2 w-full px-6 transform -translate-x-1/2 mb-6">
                <BringzzButton size='lg' className='bg-magic-lilac flex w-full justify-center' onClick={() => navigate('/camera/collect/confirm')}>Continue</BringzzButton>
            </div>}

            <BringzzBottomDrawer
                isOpen={failDrawerOpened}
                close={() => setFailDrawerOpened(false)}
                title='Enter ID-Code'
            >
                <div className='px-4 py-6'>
                    <div>
                        <BringzzInput label='Id-Code' placeholder={'ID-Code'} onChange={(e, newIsValid, newValue) => {
                            setIdCode(newValue)
                        }}></BringzzInput>
                    </div>
                </div>

                <div className='text-center mb-12 px-4'>
                    <BringzzButton
                        className='bg-magic-lilac w-full flex justify-center'
                        onClick={() => {
                            setFailDrawerOpened(false)
                            if (mode == "handover") {
                                navigate(`/matchmaking/${type}/6`)
                            } else if (mode == "confirm" || mode == "collect") {
                                navigate(`/matchmaking/${type}/finished`)
                            } else {
                                backPress()
                            }
                        }}
                        disabled={idCode.length == 0}
                    >
                        Apply
                    </BringzzButton>
                </div>
            </BringzzBottomDrawer>
        </div>
    );
};

export default CameraPage;
