import * as React from "react";
const IconSchedule = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 13.3h-8v-7c0-.6-.4-1-1-1s-1 .4-1 1v6.9H16V6.3c0-.6-.4-1-1-1s-1 .4-1 1v6.9H6c-2.2 0-4 1.8-4 4v37.4c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V17.3c0-2.2-1.8-4-4-4m-52 2h52c1.1 0 2 .9 2 2v9H4v-9c0-1.1.9-2 2-2m52 41.4H6c-1.1 0-2-.9-2-2V28.3h56v26.4c0 1.1-.9 2-2 2" />
    <path d="M33 36.8c-.4-.2-.8-.1-1.1.2l-3.1 3.1c-.4.4-.4 1 0 1.4s1 .4 1.4 0l1.4-1.4v6.7c0 .6.4 1 1 1s1-.4 1-1v-9.1q0-.6-.6-.9" />
  </svg>
);
export default IconSchedule;
