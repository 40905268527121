import * as React from "react";
const IconSadCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
    <path d="M32 38.9c-5.1 0-10 1.9-13.7 5.4-.4.4-.4 1-.1 1.4.4.4 1 .4 1.4.1C23 42.7 27.4 41 32 41s9 1.7 12.4 4.8c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1-.1-1.4-3.7-3.5-8.6-5.5-13.7-5.5M24.1 22.8c0-2.2-1.8-4-4-4s-4 1.8-4 4 1.8 4 4 4 4-1.8 4-4M43.8 18.8c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4" />
  </svg>
);
export default IconSadCopy;
