import * as React from "react";
const IconCoffeeMachine = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.2 2H19.4c-2.9 0-5.3 2.4-5.3 5.3v4.8c0 2.6 1.9 4.8 4.4 5.2V22c0 .6.4 1 1 1s1-.4 1-1v-4.5h21.2c1.8 0 3.3 1.5 3.3 3.3v22.4c0 1.8-1.5 3.3-3.3 3.3H22.9c3.7-1.7 6.3-5.4 6.3-9.7 0-.6-.4-1-1-1H8.7c-.6 0-1 .4-1 1 0 4.3 2.6 8 6.3 9.7H8.8c-2.9 0-5.3 2.4-5.3 5.3v4.8c0 2.9 2.4 5.3 5.3 5.3h46.3c2.9 0 5.3-2.4 5.3-5.3V7.3c.1-2.9-2.3-5.3-5.2-5.3M9.7 37.8h17.4c-.5 4.4-4.2 7.7-8.7 7.7s-8.2-3.4-8.7-7.7m48.8 18.9c0 1.8-1.5 3.3-3.3 3.3H8.8c-1.8 0-3.3-1.5-3.3-3.3v-4.8c0-1.8 1.5-3.3 3.3-3.3h32.8c2.9 0 5.3-2.4 5.3-5.3V20.8c0-2.9-2.4-5.3-5.3-5.3H19.4c-1.8 0-3.3-1.5-3.3-3.3V7.3c0-1.8 1.5-3.3 3.3-3.3h35.7c1.8 0 3.3 1.5 3.3 3.3v49.4z" />
  </svg>
);
export default IconCoffeeMachine;
