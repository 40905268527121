import * as React from "react";
const IconBalance = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.9 33.1s0-.1 0 0V33l-8.8-17h5.6c.6 0 1-.4 1-1s-.4-1-1-1H33V7.3c0-.6-.4-1-1-1s-1 .4-1 1V14H5.3c-.6 0-1 .4-1 1s.4 1 1 1h5.6L2.1 33v.1c-.1.1-.1.2-.1.3C2 39.3 6.7 44 12.5 44S23 39.3 23 33.5c0-.1 0-.2-.1-.3v-.1l-8.8-17H31v39.4c0 .1 0 .2.1.3h-7.5c-.6 0-1 .4-1 1s.4 1 1 1h16.9c.6 0 1-.4 1-1s-.4-1-1-1H33c0-.1.1-.2.1-.3V16h16.8L41 33v.1c0 .1-.1.2-.1.3 0 5.8 4.7 10.5 10.5 10.5S62 39.3 62 33.4c0-.1 0-.2-.1-.3M12.5 17.2l7.9 15.3H4.6zm0 24.8c-4.4 0-8-3.3-8.5-7.5h17c-.5 4.2-4.1 7.5-8.5 7.5m46.9-9.6H43.5l7.9-15.3zM51.5 42c-4.4 0-8-3.3-8.5-7.5h17c-.6 4.2-4.2 7.5-8.5 7.5" />
  </svg>
);
export default IconBalance;
