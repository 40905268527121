import * as React from "react";
const IconIntCommercialMail = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 29.6H36.4c.3-1.2.4-2.5.4-3.7 0-3.1-.8-6.2-2.4-8.8-3.1-5.3-8.6-8.5-14.8-8.6h-1.3c-5.8.3-11 3.5-14 8.5C2.8 19.7 2 22.8 2 25.9c0 3.7 1.2 7.3 3.3 10.3 2.9 4 7.4 6.5 12.3 7v8.3c0 2.2 1.8 4 4 4H58c2.2 0 4-1.8 4-4V33.6c0-2.2-1.8-4-4-4m1.4 2.6L39.8 40l-19.6-7.8c.4-.4.9-.6 1.4-.6H58c.5 0 1 .3 1.4.6M19 10.8c1.6 2.5 2.7 5.3 3.5 8.2-2.3.2-4.7.2-7-.1.8-2.8 1.9-5.6 3.5-8.1m-3.8 10.1c1.4.1 2.8.2 4.2.2 1.2 0 2.4-.1 3.5-.2.3 1.6.5 3.3.5 5 0 1.3-.1 2.5-.3 3.7h-1.5c-1.8 0-3.3 1.2-3.8 2.8-.8 0-1.6.1-2.3.1-.5-2.2-.8-4.4-.8-6.7 0-1.6.1-3.3.5-4.9-.1 0 0 0 0 0m2.4 13.5v4.1c-.6-1.3-1.2-2.6-1.6-4 .6 0 1.1 0 1.6-.1m17.2-8.5c0 1.3-.2 2.5-.5 3.7h-9.2c.2-1.2.3-2.5.3-3.7 0-1.8-.2-3.5-.5-5.2 2.8-.4 5.5-1 8.2-1.9 1.1 2.2 1.7 4.6 1.7 7.1M32 17c-2.4.8-4.9 1.4-7.5 1.7-.7-2.8-1.8-5.6-3.3-8.1 4.4.5 8.3 2.8 10.8 6.4m-15.3-6.3c-1.4 2.5-2.5 5.2-3.2 7.9-2.2-.3-4.5-.8-6.7-1.6 2.4-3.3 5.9-5.6 9.9-6.3M4 25.9c0-2.5.6-4.9 1.7-7.1 2.4.8 4.9 1.4 7.4 1.8-.3 1.7-.5 3.5-.5 5.3 0 2.3.3 4.7.8 6.9-2.3.3-4.6.8-6.9 1.6C4.9 31.9 4 29 4 25.9m3.9 10.2c2-.6 4.1-1.1 6.1-1.3q1.05 3.3 2.7 6.3c-3.4-.6-6.5-2.4-8.8-5M58 53.5H21.6c-1.1 0-2-.9-2-2V34.1L39.5 42c.1 0 .2.1.4.1s.3 0 .4-.1L60 34.1v17.4c0 1.1-.9 2-2 2" />
  </svg>
);
export default IconIntCommercialMail;
