import * as React from "react";
const IconRings = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.5 2h-51c-2.2 0-4 1.8-4 4v5c0 2.2 1.8 4 4 4H16v16.3c-3.6 1-6.3 7.2-6.3 15.3C9.8 55.4 12.9 62 17 62s7.3-6.6 7.3-15.4c0-8.1-2.6-14.3-6.3-15.3V15h28v16.3c-3.6 1-6.3 7.2-6.3 15.3 0 8.8 3.1 15.4 7.3 15.4s7.3-6.6 7.3-15.4c0-8.1-2.6-14.3-6.3-15.3V15h9.5c2.2 0 4-1.8 4-4V6c0-2.2-1.8-4-4-4M22.3 46.6c0 7.9-2.8 13.4-5.3 13.4s-5.3-5.5-5.3-13.4 2.8-13.4 5.3-13.4 5.3 5.5 5.3 13.4m29.9 0c0 7.9-2.8 13.4-5.3 13.4s-5.3-5.5-5.3-13.4 2.8-13.4 5.3-13.4 5.3 5.5 5.3 13.4M59.5 11c0 1.1-.9 2-2 2h-51c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2h51c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconRings;
