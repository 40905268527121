import React, { useState, useEffect } from 'react';
import { BringzzText, BringzzSwitch } from "@bringzz/components";
import { requestLocationPermission, disableLocationTracking, isLocationTrackingEnabled } from 'location';
import useRequest from 'hooks/useRequest';

export const LocationSwitch = () => {
    const [isLocationEnabled, setIsLocationEnabled] = useState(false);
    const { sendRequest, loading } = useRequest();

    useEffect(() => {
        const checkPermission = async () => {
            const enabled = await isLocationTrackingEnabled();
            setIsLocationEnabled(enabled);
        };
        checkPermission();
    }, []);

    const handleEnableLocation = async () => {
        const result = await requestLocationPermission();
        if (result) {
            await sendRequest({
                method: 'POST',
                url: `/settings/app/location`,
                data: { locationTracking: true }
            }).then((response) => {
                if (response.status === 'success') {
                    // TODO: Toast
                }
            }).catch((error) => {
                console.error(error);
            })
        }
        return result;
    };

    const handleDisableLocation = async () => {
        const result = await disableLocationTracking();
        if (!result) {
            await sendRequest({
                method: 'POST',
                url: `/settings/app/location`,
                data: { locationTracking: false }
            }).then((response) => {
                if (response.status === 'success') {
                    // TODO: Toast
                }
            }).catch((error) => {
                console.error(error);
            })
        }
        return result;
    };

    return (
        <div className='flex py-2 justify-between items-center'>
            <div>
                <BringzzText tag='h4' className="font-bold">Location tracking</BringzzText>
                <BringzzText tag='h4' className="font-normal text-midnight-blue/60">Helps you get the best delivery experience.</BringzzText>
            </div>
            <div>
                <BringzzSwitch
                    defaultValue={isLocationEnabled}
                    onEnable={handleEnableLocation}
                    onDisable={handleDisableLocation}
                />
            </div>
        </div>
    );
};
