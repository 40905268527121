import * as React from "react";
const IconBeverage = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m53.5 23.2-5.6-9.8c-.2-.3-.5-.5-.9-.5h-6.6L46 3.5c.3-.5.1-1.1-.3-1.4-.5-.3-1.1-.1-1.4.3l-6.2 10.4H17c-.4 0-.7.2-.9.5l-5.6 9.8c-.2.3-.2.7 0 1s.5.5.9.5h4L18.1 61c0 .5.5.9 1 .9h25.8c.5 0 1-.4 1-.9l2.7-36.4h4c.4 0 .7-.2.9-.5.2-.2.2-.6 0-.9M44 60H20l-2.7-35.3h29.2zm3.7-37.3H13.1l4.5-7.8h28.9l4.5 7.8z" />
  </svg>
);
export default IconBeverage;
