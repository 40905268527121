import * as React from "react";
const IconNorth = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M42.8 34.7c-.6 0-1 .4-1 1v21.6L22 35.1c-.3-.3-.7-.4-1.1-.3s-.6.5-.6.9V60c0 .6.4 1 1 1s1-.4 1-1V38.4L42 60.7c.2.2.5.3.7.3.1 0 .2 0 .4-.1.4-.1.6-.5.6-.9V35.7c.1-.5-.4-1-.9-1M47.5 17.8 32.7 3.3c-.4-.4-1-.4-1.4 0L16.5 17.8c-.4.4-.4 1 0 1.4s1 .4 1.4 0L32 5.4l14.1 13.8c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4" />
  </svg>
);
export default IconNorth;
