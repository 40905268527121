import * as React from "react";
const IconAngleDoubleDownCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M31.3 37.5c.2.2.4.3.7.3s.5-.1.7-.3l23-22.6c.4-.4.4-1 0-1.4s-1-.4-1.4 0L32 35.4 9.7 13.5c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4z" />
    <path d="M54.3 26.5 32 48.4 9.7 26.5c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l23 22.6c.2.2.4.3.7.3s.5-.1.7-.3l23-22.6c.4-.4.4-1 0-1.4s-1-.4-1.4 0" />
  </svg>
);
export default IconAngleDoubleDownCopy;
