import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

const BringzzRotatingSelect = ({ options, onSelect, defaultOption }) => {
  const getCurrentTimestamp = () => new Date().toISOString();

  const initialIndex = defaultOption !== undefined
    ? options.findIndex((option) => option.value === defaultOption)
    : 0;

  const [selectedIndex, setSelectedIndex] = useState(initialIndex);
  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const containerRef = useRef(null);
  const [scrollStartY, setScrollStartY] = useState(0);
  const [isReadyToScroll, setIsReadyToScroll] = useState(false);

  const handleDragStart = (event) => {
    event.stopPropagation();
    setIsDragging(true);
    setStartY(event.clientY);
    setScrollStartY(containerRef.current.children[0].scrollTop);

  };

  const handleScrolling = () => {
    if (!isReadyToScroll) return;
    if (containerRef.current) {
      const ulElement = containerRef.current.querySelector("ul");
      const listItems = Array.from(ulElement.children);
      const containerRect = containerRef.current.getBoundingClientRect();
      const containerCenterX = containerRect.left + containerRect.width / 2;
      const containerCenterY = containerRect.top + containerRect.height / 2;

      let closestDistance = Infinity;
      let closestIndex = selectedIndex;

      listItems.forEach((item, index) => {
        const itemRect = item.getBoundingClientRect();
        const itemCenterX = itemRect.left + itemRect.width / 2;
        const itemCenterY = itemRect.top + itemRect.height / 2;

        const distance = Math.sqrt(
          Math.pow(itemCenterX - containerCenterX, 2) +
          Math.pow(itemCenterY - containerCenterY, 2)
        );

        if (distance < closestDistance) {
          closestDistance = distance;
          closestIndex = index;
        }
      });

      setSelectedIndex(closestIndex);
      onSelect(options[closestIndex]);

      scrollToCenter(closestIndex);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsReadyToScroll(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  const scrollToCenter = (index) => {
    if (isDragging) return;
    if (containerRef.current) {
      const ulRef = containerRef.current.querySelector("ul");
      const liElement = ulRef.childNodes[index];
      if (!liElement) return; // Safeguard against undefined element

      const ulRect = ulRef.getBoundingClientRect();
      const liRect = liElement.getBoundingClientRect();

      const ulScrollTop = ulRef.scrollTop;
      const centerPosition = ulRect.height / 2 - liRect.height / 2;
      const scrollTo = ulScrollTop + liRect.top - ulRect.top - centerPosition;



      ulRef.scrollTo({ top: scrollTo, behavior: "smooth" });
    }
  };

  useEffect(() => {
    const handleDragMove = (event) => {
      if (!isDragging) return;
      const currentY = event.clientY;
      const deltaY = currentY - startY;
      const newScrollPosition = scrollStartY - deltaY; // Invert deltaY if you want opposite scroll direction
      containerRef.current.children[0].scrollTop = newScrollPosition;

    };

    const handleDragEnd = () => {
      setIsDragging(false);

    };

    if (isDragging) {
      window.addEventListener("mousemove", handleDragMove);
      window.addEventListener("mouseup", handleDragEnd);
    }

    return () => {
      window.removeEventListener("mousemove", handleDragMove);
      window.removeEventListener("mouseup", handleDragEnd);
    };
  }, [isDragging, startY, scrollStartY]);

  useEffect(() => {
    if (defaultOption !== undefined) {
      const index = options.findIndex((option) => option.value === defaultOption);
      if (index !== -1) {
        setSelectedIndex(index);

        setTimeout(() => {
          scrollToCenter(index);

        }, 0); // Ensuring it executes after the state update
      }
    }
  }, [defaultOption, options]);

  useEffect(() => {
    setTimeout(() => {
      scrollToCenter(selectedIndex);

    }, 0);
  }, []);

  return (
    <div className="bg-white py-4 min-w-">
      <div
        ref={containerRef}
        className="flex items-center justify-center w-full h-52 relative"
      >
        <ul
          className="w-full py-20 h-[160px] overflow-auto no-scrollbar"
          onScroll={(e) => {
            handleScrolling(e);

          }}
          onWheel={(event) => {
            const ulElement = containerRef.current.querySelector("ul");
            const isScrollingDown = event.deltaY > 0;
            const isAtBottom =
              ulElement.scrollHeight - ulElement.scrollTop ===
              ulElement.clientHeight;

            const isAtTop = ulElement.scrollTop === 0;

            // if user is trying to overscroll down, stop them and center the last element in the list
            if (isScrollingDown && isAtBottom) {
              // Prevent scrolling past the bottom
              event.preventDefault();
              scrollToCenter(options.length - 1);

            }

            // if user is trying to overscroll up, stop them and center the first element in the list
            if (!isScrollingDown && isAtTop) {
              // Prevent scrolling past the bottom
              event.preventDefault();
              scrollToCenter(0);

            }
          }}
          onMouseDown={handleDragStart}
        >
          {options.map((option, index) => (
            <li
              key={option.value}
              className={`select-none flex items-center justify-center w-full leading-none ${selectedIndex === index ? "opacity-100" : "opacity-50"
                }`}
              onMouseDown={() => {
                if (!isDragging) scrollToCenter(index);
              }}
            >
              <span
                className={`w-full text-center p-2 ${selectedIndex === index
                  ? "text-xl text-midnight-blue"
                  : "text-md"
                  }`}
                style={{
                  scale: `${Math.pow(0.8, Math.abs(selectedIndex - index))}`,
                  opacity: `${selectedIndex === index
                    ? "1"
                    : "0." + (80 - Math.abs(selectedIndex - index) * 20).toFixed(0)
                    }`,
                }}
              >
                {option.label}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

BringzzRotatingSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  defaultOption: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default BringzzRotatingSelect;
