import * as React from "react";
const IconPaperPlane = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62 11.2c0-.1 0-.2-.1-.2 0-.1-.1-.1-.1-.2l-.1-.1-.1-.1c-.1-.1-.2-.1-.3-.1h-.7l-58 15.4c-.3.1-.6.5-.6.9s.3.8.7 1l15.5 5.3 5.3 19.7c0 .1 0 .2.1.2 0 .1.1.2.2.2 0 0 .1.1.2.1.1.1.2.1.3.1h.5c.1 0 .2 0 .3-.1.1 0 .1-.1.2-.1l12.3-10.7 10 4.7c.3.1.5.1.8 0q.45-.15.6-.6l13.1-34.9v-.5c-.1.1-.1.1-.1 0M6.5 27l45.2-12L19 31.2zm18 21.9-4.3-16.1 33.1-16.4-24.7 20-.1.1-.1.1c0 .1-.1.1-.1.2v.1zm2 .6 3.3-10.8 5.8 3zM47.4 45l-9.6-4.5L31 37l27.8-22.5z" />
  </svg>
);
export default IconPaperPlane;
