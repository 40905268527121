import * as React from "react";
const IconSonogram = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M8.368 29.95c-.404 1.05.139 2.232 1.215 2.562a49 49 0 0 0 14.416 2.16 49 49 0 0 0 14.418-2.16c1.076-.33 1.618-1.512 1.215-2.563l-6.409-16.687c-.371-.968-1.419-1.484-2.43-1.258a31.2 31.2 0 0 1-6.794.745c-2.331 0-4.604-.257-6.792-.745-1.012-.226-2.06.29-2.43 1.258zm25.838-7.796c0 2.48-2.067 4.491-4.617 4.491q-.292 0-.573-.034c-.174.234-.36.552-.573.918-.92 1.575-2.357 4.038-5.577 4.498-2.514.363-4.252-1.755-5.407-4.01-.145-.283-.286-.557-.418-.81l-.607.272c-.529.237-1.048.47-1.294.566-1.053.41-2.247-.089-2.667-1.113s.091-2.185 1.144-2.595c.15-.058.472-.203.894-.393.285-.128.616-.277.97-.433.402-.177.82-.358 1.161-.492.165-.065.354-.135.53-.189a3 3 0 0 1 .372-.09c.077-.013.205-.032.362-.034a2.2 2.2 0 0 1 .836.15 2.1 2.1 0 0 1 .801.563c.08.09.14.172.171.217.069.097.13.197.179.277.1.167.219.38.343.608.15.276.595 1.166.81 1.597a.184.184 0 0 0 .34-.135l-.655-2.159a240 240 0 0 0-.495-1.635 43 43 0 0 0-.25-.782c-.426-1.022.08-2.186 1.13-2.6 1.051-.415 2.248.078 2.673 1.1l.993 3.37c.024.08.102.131.185.12a.16.16 0 0 0 .13-.197 4.4 4.4 0 0 1-.125-1.046c0-1.928.885-3.219 2.758-3.973 1.192-.48 2.56-.644 3.74-.131 1.612.7 2.736 2.274 2.736 4.104M41 11.5v2a1 1 0 1 0 2 0V11a1.5 1.5 0 0 0-1.5-1.5H39a1 1 0 1 0 0 2zM7 11.5h2a1 1 0 1 0 0-2H6.5A1.5 1.5 0 0 0 5 11v2.5a1 1 0 1 0 2 0zM41 36.5v-2a1 1 0 1 1 2 0V37a1.5 1.5 0 0 1-1.5 1.5H39a1 1 0 1 1 0-2zM7 36.5h2a1 1 0 1 1 0 2H6.5A1.5 1.5 0 0 1 5 37v-2.5a1 1 0 1 1 2 0z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconSonogram;
