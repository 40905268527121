import React from 'react';
import Sidebar from '../components/common/Sidebar';
import { Outlet } from 'react-router-dom';
import ProgressOverlay from 'components/ProgressOverlay';
import CameraOverlay from 'components/CameraOverlay';
import { useOrder } from 'context/OrderContext';

const DesktopLayout = () => {
	const { orderDetails } = useOrder();

	return (
		<div className='flex'>
			<Sidebar />
			<main className='relative max-w-4xl xl:max-w-2xl md:max-w-lg lg:max-w-xl mx-auto bg-gray-50 grow'>
				<Outlet />
				{orderDetails && <ProgressOverlay />}
				<CameraOverlay />
			</main>
		</div>
	);
};

export default DesktopLayout;
