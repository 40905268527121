import * as React from "react";
const IconVrMonitor2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M20.4 29.1h7.1c.3 0 .5-.1.7-.3L32 25l3.8 3.8c.2.2.4.3.7.3h7.1c3 0 5.4-2.4 5.4-5.4v-9.3c0-3-2.4-5.4-5.4-5.4h-2.1V7.4c0-3-2.4-5.4-5.4-5.4h-8.4c-3 0-5.4 2.4-5.4 5.4v1.8h-2.1c-3 0-5.4 2.4-5.4 5.4v9.3c.2 2.8 2.6 5.2 5.6 5.2M27.8 4h8.4c1.9 0 3.4 1.5 3.4 3.4v1.8H24.4V7.4c0-1.9 1.5-3.4 3.4-3.4m-7.4 7.1h23.3c1.9 0 3.4 1.5 3.4 3.4V17h-4.4c-.6 0-1 .4-1 1s.4 1 1 1H47v4.7c0 1.9-1.5 3.4-3.4 3.4h-6.7l-4.2-4.2c-.4-.4-1-.4-1.4 0l-4.2 4.2h-6.7c-1.9 0-3.4-1.5-3.4-3.4V19h4.4c.6 0 1-.4 1-1s-.4-1-1-1H17v-2.5c0-1.9 1.5-3.4 3.4-3.4" />
    <path d="M54.9 20.6c-.6 0-1 .4-1 1v25.7c0 1.7-1.4 3.1-3.1 3.1H13.2c-1.7 0-3.1-1.4-3.1-3.1V21.6c0-.6-.4-1-1-1s-1 .4-1 1v25.7c0 2.8 2.3 5.1 5.1 5.1h14.1L26.2 60h-6.1c-.6 0-1 .4-1 1s.4 1 1 1h23.8c.6 0 1-.4 1-1s-.4-1-1-1h-6.1l-1.1-7.5h14.1c2.8 0 5.1-2.3 5.1-5.1V21.6c0-.5-.5-1-1-1M35.8 60h-7.6l1.1-7.5h5.4z" />
  </svg>
);
export default IconVrMonitor2;
