import * as React from "react";
const IconCloudCheckAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.5 28.4q-2.4 0-4.5.9c.1-.8.2-1.6.2-2.5C46.2 19.2 39.8 13 32 13s-14.2 6.2-14.2 13.8c0 .8.1 1.7.2 2.5q-2.1-.9-4.5-.9C7.2 28.4 2 33.5 2 39.7S7.2 51 13.5 51h36.9C56.8 51 62 45.9 62 39.7s-5.2-11.3-11.5-11.3m0 20.6h-37C8.3 49 4 44.8 4 39.7s4.3-9.3 9.5-9.3c2 0 3.9.6 5.5 1.7.4.3.8.2 1.2 0 .3-.3.5-.7.3-1.1-.5-1.3-.8-2.7-.8-4.1C19.8 20.3 25.3 15 32 15s12.2 5.3 12.2 11.8c0 1.4-.3 2.8-.8 4.1-.2.4 0 .9.3 1.1.3.3.8.3 1.2 0 1.6-1.1 3.5-1.7 5.5-1.7 5.3 0 9.5 4.2 9.5 9.3S55.7 49 50.5 49" />
    <path d="m36.9 34.6-6.8 6.6-3-2.9c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l3.7 3.6c.2.2.4.3.7.3s.5-.1.7-.3l7.5-7.3c.4-.4.4-1 0-1.4s-1-.4-1.4 0" />
  </svg>
);
export default IconCloudCheckAlt;
