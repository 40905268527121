import * as React from "react";
const IconPaperIdea = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32.3 8.6c-2.8-.1-5.6 1-7.6 3s-3.2 4.7-3.2 7.5c0 3.7 1.8 7 4.9 8.9.6.4.9 1.1.9 1.8v1.8c0 2.3 1.9 4.1 4.1 4.1h1c2.3 0 4.1-1.9 4.1-4.1v-1.9c0-.7.4-1.4.9-1.8 3-1.9 4.8-5.1 4.9-8.6.1-2.8-1-5.4-2.9-7.5-1.8-2-4.4-3.2-7.1-3.2m2.3 23.1c0 1.2-1 2.1-2.1 2.1h-1c-1.2 0-2.1-1-2.1-2.1v-2.3h5.3v2.3zm1.9-5.4c-.4.3-.8.6-1.1 1h-6.9c-.3-.4-.7-.8-1.1-1-2.5-1.6-4-4.3-4-7.2 0-2.3.9-4.5 2.6-6.1 1.6-1.6 3.7-2.4 5.9-2.4h.2c2.2.1 4.3 1 5.9 2.7s2.4 3.8 2.4 6.1c0 2.8-1.5 5.4-3.9 6.9" />
    <path d="M47.5 2h-31c-3.6 0-6.6 3-6.6 6.6v31.6c0 1.1.5 2.3 1.3 3l18.7 17.6c.8.8 1.8 1.2 2.9 1.2h14.6c3.6 0 6.6-3 6.6-6.6V8.6C54.1 5 51.1 2 47.5 2M13.3 42.5h15.4c1.3 0 2.3 1 2.3 2.2v14.5zm38.8 12.9c0 2.5-2.1 4.6-4.6 4.6H33V44.6c0-2.3-1.9-4.2-4.3-4.2H11.9V8.6C11.9 6.1 14 4 16.5 4h31c2.5 0 4.6 2.1 4.6 4.6z" />
  </svg>
);
export default IconPaperIdea;
