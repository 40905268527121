import * as React from "react";
const IconCricketBat = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.7 4.3c-.4-.4-1-.4-1.4 0l-15 14.9-5.8-5.8c-.4-.4-1-.4-1.4 0L4.9 44.7c-.8.8-1.1 1.9-.8 2.9 1.5 6.1 6.2 10.8 12.3 12.3.2.1.5.1.7.1.8 0 1.6-.3 2.2-.9l31.2-31.2c.4-.4.4-1 0-1.4l-5.8-5.8 15-15c.4-.4.4-1 0-1.4M17.9 57.7c-.3.3-.7.4-1 .3-5.4-1.4-9.6-5.5-10.9-10.9-.1-.4 0-.8.3-1l30.5-30.5 11.6 11.6z" />
  </svg>
);
export default IconCricketBat;
