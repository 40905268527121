import * as React from "react";
const IconSpleen = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      d="M15.224 7.635c-1.826 1.123-2.664 2.205-3.008 3.104-.332.87-.307 1.844.15 2.996.78 1.963 2.691 4.105 5.25 6.141a15 15 0 0 0-.693.224 5 5 0 0 0-.2.058q-.257.079-.487.2-.399.16-.792.34c-5.479-4.693-8.307-10.426-1.238-14.774C30.024-3.803 45.11 25.832 34.397 39.29c-1.242 1.559-6.038 6.898-12.141 3.718-6.987-3.64-5.062-7.907-3.29-11.835.404-.896.8-1.775 1.082-2.625a4.5 4.5 0 0 0 1.866.674c-.159.473-.34.931-.522 1.364-.192.456-.398.912-.594 1.348l-.018.04c-.193.428-.378.837-.552 1.246-.747 1.754-1.094 3.06-.859 4.183.208.992.993 2.344 3.822 3.818 2.35 1.224 4.372.829 6.047-.067 1.757-.94 3.059-2.411 3.613-3.108 2.296-2.882 3.288-6.736 3.145-10.914-.143-4.176-1.421-8.53-3.482-12.21-2.07-3.698-4.837-6.556-7.832-7.954-2.902-1.355-6.104-1.394-9.457.668"
    />
    <path
      
      d="M23.19 21.429c.541.074.928.583.864 1.138a.975.975 0 0 1-1.095.872 11 11 0 0 0-2.325-.086c.132.165.25.326.357.473q.077.107.148.204c.285.398.497.691.734.912.232.217.39.267.572.25.541-.05 1.026.362 1.082.92s-.337 1.05-.878 1.1c-.896.083-1.582-.304-2.088-.776-.4-.372-.747-.858-1.02-1.24l-.017-.023-.108-.15c-.334-.461-.575-.748-.815-.907-.171-.114-.356-.177-.697-.072l-.044.012a10 10 0 0 0-.433.206c-1.691.855-3.383 2.309-5.498 4.464a.967.967 0 0 1-1.394-.017 1.034 1.034 0 0 1-.003-1.434c2.14-2.181 4.017-3.83 6.005-4.836 2.03-1.025 4.124-1.354 6.653-1.01"
    />
    <path
      
      d="M18.34 11.752c4.021 3.526 6.868 7.324 8.05 11.4 1.17 4.04.739 8.466-2.01 13.358l1.743.98c2.98-5.304 3.524-10.282 2.188-14.895-1.327-4.577-4.47-8.68-8.652-12.347z"
    />
  </svg>
);
export default IconSpleen;
