import * as React from "react";
const IconLike = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.8 23.4c-1.7-1.4-3.7-2.2-5.8-2.2H41.4v-8.1c0-4.2-1.3-7.2-3.7-9C36.2 3 34.3 2.4 32 2.4c-2.1 0-3.7.5-3.7.5-1 .3-1.7 1.3-1.7 2.3v10.2c0 6.1-6.3 8.9-8.3 9.7V24c0-.6-.4-1-1-1H6c-2.2 0-4 1.8-4 4v30.6c0 2.2 1.8 4 4 4h11.2c.6 0 1-.4 1-1v-1.2c1.5.9 3.2 1.5 4.9 1.5H48c6 0 9.9-3.3 10.6-8.8l3.2-20c.6-3.3-.6-6.6-3-8.7M16.2 59.5H6c-1.1 0-2-.9-2-2V27c0-1.1.9-2 2-2h10.2zm43.7-27.8-3.2 20c-.6 4.5-3.6 7-8.6 7h-25c-1.8 0-3.6-.7-4.9-2V27.3c1-.3 10.3-3.5 10.3-11.8V5.3c0-.2.1-.4.3-.4 0 0 1.4-.4 3.1-.4 1.8 0 3.3.4 4.5 1.3 1.9 1.4 2.9 3.9 2.9 7.4v9.1c0 .6.4 1 1 1h12.6c1.6 0 3.2.6 4.5 1.7 2 1.6 2.9 4.2 2.5 6.7" />
  </svg>
);
export default IconLike;
