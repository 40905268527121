import React from 'react';
import { useNavigation } from '../../context/NavigationContext';
import { BringzzPageContainer, BringzzIcon, BringzzText, BringzzTabs, BringzzTab, BringzzTabPanel, BringzzChatList } from "@bringzz/components";

const AccountPharmacyChat5 = () => {
    const { backPress } = useNavigation();

    const chats = [
        { id: 1, name: 'Apotheke zum Goldenen...', label: 'Lorem ipsum dolor', datetime: '28.02.2024, 14:45' },
        { id: 2, name: 'Apotheke zum Goldenen...', label: 'Lorem ipsum dolor', datetime: '28.02.2024, 14:45' },
        { id: 3, name: 'Apotheke zum Goldenen...', label: 'Lorem ipsum dolor', datetime: '28.02.2024, 14:45' },
    ];

    return (
        <BringzzPageContainer
            header={{
                left: <BringzzIcon
                    size="22"
                    icon="IconChevronLeft"
                    folder="LineIcons"
                    className="cursor-pointer"
                    onClick={backPress}
                />,
                title: <BringzzText tag='h2'>Chats</BringzzText>,
            }}>

            <BringzzTabs defaultActiveTab="tab1">
                <BringzzTab id="tab1">
                    <BringzzIcon
                        size="20"
                        icon="IconSpeechBubble16"
                        folder="LineIcons"
                        className="cursor-pointer"
                        onClick={(event) => {

                        }}
                    />
                </BringzzTab>
                <BringzzTabPanel id="tab1">
                    <BringzzChatList chats={chats}></BringzzChatList>
                </BringzzTabPanel>

                <BringzzTab id="tab2">
                    <BringzzIcon
                        size="20"
                        icon="IconArchive"
                        folder="LineIcons"
                        className="cursor-pointer"
                        onClick={(event) => {

                        }}
                    />
                </BringzzTab>
                <BringzzTabPanel id="tab2">
                    Content for Tab 2
                </BringzzTabPanel>
            </BringzzTabs>
        </BringzzPageContainer>

    )
}

export default AccountPharmacyChat5;