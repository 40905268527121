import * as React from "react";
const IconHandshake = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 35.52h-2.34c-.79 0-1.46-.92-1.46-2V18.4c0-1.11.72-2.08 1.54-2.08H61a2 2 0 0 0 0-4h-2.26a5.44 5.44 0 0 0-4.74 2.9H40.91L39 13.55a14.12 14.12 0 0 0-12.7-3.22c-5.6 1.42-9.3 5.93-10.59 7.79h-2a5.72 5.72 0 0 0-5.45-5.8H3a2 2 0 0 0 0 4h5.24c.79 0 1.46.91 1.46 2v15.11c0 1.11-.72 2.09-1.54 2.09H3a2 2 0 0 0 0 4h5.16a5.46 5.46 0 0 0 4.71-2.9h.7a4.45 4.45 0 0 1 2.17.57 2 2 0 0 0 .36 1.56l2.31 3.09a2.07 2.07 0 0 0 1.08.73l3.06.82 1.37 2.92a2 2 0 0 0 1.28 1.08l3.22.88 2.09 3.32a2 2 0 0 0 1.15.86L37.23 54a2 2 0 0 0 .54.08 2 2 0 0 0 1.66-.88l10.45-15.52a1 1 0 0 1 .83-.44h3.67a5.31 5.31 0 0 0 4.28 2.28H61a2 2 0 0 0 0-4M18.94 20.44c1.07-1.51 4-5.14 8.34-6.24a10.15 10.15 0 0 1 9.09 2.36l4.09 3.56a2.4 2.4 0 0 1 .92 1.56 1.14 1.14 0 0 1-.32.85l-1.7 1.82a2.56 2.56 0 0 1-3.43.3l-.4-.3a6.42 6.42 0 0 0-8.42.59l-8.83 9.13a8.4 8.4 0 0 0-4.58-1.45v-10.5h2a4 4 0 0 0 3.24-1.68m27.63 15-9.65 14.32-3.44-1-2.09-3.32a2 2 0 0 0-1.16-.86l-3.08-.84-1.38-2.93a2 2 0 0 0-1.28-1.08l-3.29-.88-.88-1.17 9.62-10a2.44 2.44 0 0 1 3.18-.21l.4.3a6.57 6.57 0 0 0 8.77-.77L44 25.26a5.14 5.14 0 0 0 1.39-3.79 5.45 5.45 0 0 0-.62-2.25h8.43v14h-2.49a5 5 0 0 0-4.14 2.22" />
  </svg>
);
export default IconHandshake;
