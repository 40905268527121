import * as React from "react";
const IconShipSecure = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M41.9 57c-.3-.2-.5-.4-.8-.6l4.5-16.1c.7-2.5-.6-5.2-3-6.2l-3.3-1.4v-4.9c0-.6-.4-1-1-1s-1 .4-1 1v4l-9.7-4.1c-1.3-.5-2.7-.5-4 0L13.8 32v-7.4c0-1.8 1.4-3.2 3.2-3.2h13.6c.6 0 1-.4 1-1s-.4-1-1-1H18.8v-6c0-1.8 1.4-3.2 3.2-3.2h8c.6 0 1-.4 1-1s-.4-1-1-1h-3.4V3c0-.6-.4-1-1-1s-1 .4-1 1v5.3H22c-2.9 0-5.2 2.3-5.2 5.2v6c-2.8.1-5 2.4-5 5.2v8.2l-3.2 1.3c-2.4 1-3.7 3.7-3 6.2l4.5 16.1c-.3.2-.5.3-.8.6l-3.7 3.2c-.4.4-.5 1-.1 1.4.2.2.5.3.8.3.2 0 .5-.1.7-.2l3.7-3.2c1.2-1 3-1 4.2 0l1 .8c1.9 1.7 4.8 1.7 6.8 0l1-.8c1.2-1 3-1 4.2 0l1 .8c1.9 1.7 4.8 1.7 6.8 0l1-.8c1.2-1 3-1 4.2 0l3.7 3.2c.4.4 1 .3 1.4-.1s.3-1-.1-1.4zm-6.8 0-1 .8c-1.2 1-3 1-4.2 0L29 57c-1.9-1.7-4.8-1.7-6.8 0l-1 .8c-1.2 1-3 1-4.2 0l-1-.8c-1.1-1-2.6-1.4-4.1-1.2l-4.5-16c-.4-1.5.4-3.2 1.8-3.8l3.8-1.6h.1l11.1-4.6c.8-.3 1.7-.3 2.4 0l11.1 4.6h.1l3.8 1.6c1.5.6 2.3 2.2 1.8 3.8l-4.5 16c-1.2-.1-2.7.2-3.8 1.2M58.1 6l-7.8-2.7c-.2-.1-.4-.1-.7 0L41.8 6c-.4.1-.7.5-.7.9v6.2c0 6.6 6.4 10.3 8.3 11.3.1.1.3.1.5.1s.3 0 .5-.1c2-1 8.3-4.8 8.3-11.3V7c0-.5-.2-.9-.6-1m-1.4 7.2c0 5-5 8.3-6.8 9.3-2.1-1.1-6.8-4.3-6.8-9.3V7.7l6.8-2.4 6.8 2.4z" />
  </svg>
);
export default IconShipSecure;
