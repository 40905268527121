import React, { useState } from 'react';
import { useNavigation } from 'context/NavigationContext';
import {
    BringzzPageContainer, BringzzText, BringzzImageSlider, BringzzImage, BringzzTab, BringzzTabPanel, BringzzTabs, BringzzIcon,
    BringzzProductBox, BringzzBoxSlider, BringzzCartSelector, BringzzCallToAction, BringzzInput
} from "@bringzz/components";
import RocketImage from 'assets/images/Rocket.png'
import BikeImage from 'assets/images/Bike.png'
import ProductExample from 'assets/images/ProductExample.png';
import ManCallToAction from 'assets/images/ManCallToAction.png';
import { useParams } from 'react-router-dom';

const SearchCategoryPage = () => {
    const { backPress } = useNavigation();
    const { navigate } = useNavigation();
    const { '*': searchQuery } = useParams();

    const products = [
        {
            name: "Rx-medicine product name",
            icon: <BringzzIcon
                size="25"
                icon="IconPrescriptionDocument"
                folder="HealthIcons"
            />
        },
        {
            name: "Rx-medicine product name",
            icon: <BringzzIcon
                size="25"
                icon="IconPrescriptionDocument"
                folder="HealthIcons"
            />
        },
        {
            name: "Rx-medicine product name",
            icon: <BringzzIcon
                size="25"
                icon="IconPrescriptionDocument"
                folder="HealthIcons"
            />
        },
        {
            name: "Rx-medicine product name",
            icon: <BringzzIcon
                size="25"
                icon="IconPrescriptionDocument"
                folder="HealthIcons"
            />
        },
        {
            name: "Rx-medicine product name",
            icon: <BringzzIcon
                size="25"
                icon="IconPrescriptionDocument"
                folder="HealthIcons"
            />
        },
        {
            name: "Rx-medicine product name",
            icon: <BringzzIcon
                size="25"
                icon="IconPrescriptionDocument"
                folder="HealthIcons"
            />
        },
        {
            name: "Rx-medicine product name",
            icon: <BringzzIcon
                size="25"
                icon="IconPrescriptionDocument"
                folder="HealthIcons"
            />
        },
        {
            name: "Rx-medicine product name",
            icon: <BringzzIcon
                size="25"
                icon="IconPrescriptionDocument"
                folder="HealthIcons"
            />
        },
        {
            name: "Rx-medicine product name",
            icon: <BringzzIcon
                size="25"
                icon="IconPrescriptionDocument"
                folder="HealthIcons"
            />
        },
    ]

    return (
        <BringzzPageContainer
            className="bg-white pb-32"
            authenticated={false}
            header={{
                title: searchQuery,
                left: <BringzzIcon
                    size="22"
                    icon="IconChevronLeft"
                    folder="LineIcons"
                    className="cursor-pointer"
                    onClick={backPress}
                />,
                right: <BringzzIcon
                    size="22"
                    icon="IconFilterslider"
                    folder="LineIcons"
                    className="cursor-pointer"
                    onClick={() => navigate("/search/filter/")}
                />,
            }}
        >
            <div className=''>
                <div className='space-y-4 px-6 pt-2'>
                    <div className={'bg-sand rounded-lg py-2 px-4 cursor-pointer w-fit'}>
                        <BringzzText tag='h4'>By type</BringzzText>
                    </div>
                    <div>
                        <BringzzText tag='h4'>{searchQuery} description</BringzzText>
                        <BringzzText tag='h4' className='font-normal'>Terdum quis nibh sit sed amet tortor faucibus lorem. In morbi tellus laoreet risus. Tempus orci et.</BringzzText></div>
                    <BringzzImageSlider
                        sections={[
                            <div key="1" className='relative h-44 bg-magic-lilac rounded-xl'>
                                <BringzzImage src='/images/mask.png' className='w-full h-full absolute top-0 left-0 right-0 bottom-0 object-cover rounded-xl' />
                                <div className='absolute inset-0 flex items-center justify-center'>
                                    <div className='flex flex-col items-center'>
                                        <img className='h-24 w-24' src={RocketImage} alt='progressing' />
                                        <BringzzText tag='h2'>How can we help you</BringzzText>
                                    </div>
                                </div>
                            </div>
                            ,
                            <div key="2" className='relative h-44 bg-magic-lilac rounded-xl'>
                                <BringzzImage src='/images/mask.png' className='w-full h-full absolute top-0 left-0 right-0 bottom-0 object-cover rounded-xl' />
                                <div className='absolute inset-0 flex items-center justify-center'>
                                    <div className='flex flex-col items-center'>
                                        <img className='h-24 w-24' src={BikeImage} alt='progressing' />
                                        <BringzzText tag='h2'>How can we help you now</BringzzText>
                                    </div>
                                </div>
                            </div>,
                            <div key="3" className='relative h-44 bg-magic-lilac rounded-xl'>
                                <BringzzImage src='/images/mask.png' className='w-full h-full absolute top-0 left-0 right-0 bottom-0 object-cover rounded-xl' />
                                <div className='absolute inset-0 flex items-center justify-center'>
                                    <div className='flex flex-col items-center'>
                                        <img className='h-24 w-24' src={RocketImage} alt='progressing' />
                                        <BringzzText tag='h2'>How can we help you today</BringzzText>
                                    </div>
                                </div>
                            </div>,
                        ]}
                        mode='custom'
                        className='rounded-lg'
                        autoSlide={true}
                    ></BringzzImageSlider>
                </div>

                <div className='p-6'>
                    {products.map((product, index) => {
                        return <div key={index} className='flex items-center h-12 border-b justify-between cursor-pointer'>
                            <div className='flex space-x-2 items-center'>
                                {product.icon}
                                <BringzzText tag='h3'>{`${searchQuery} product name`}</BringzzText>
                            </div>
                            <BringzzIcon
                                size="16"
                                icon="IconChevronRight"
                                folder="LineIcons"
                            />
                        </div>
                    })}
                </div>
                <BringzzCallToAction
                    text='Need advice from a pharmacist near you?'
                    primaryButton={{
                        label: 'Chat',
                        className: 'bg-lemon-yellow text-olive-green'
                    }}
                    secondaryButton={{
                        label: 'Call',
                        className: 'bg-lemon-yellow text-olive-green'
                    }}
                    image={ManCallToAction}
                ></BringzzCallToAction>
            </div>
        </BringzzPageContainer >
    );
};

export default SearchCategoryPage;
