import * as React from "react";
const IconTreesAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M25.7 24.4h1.4c.7 0 1.4-.4 1.7-1.1s.3-1.4-.2-2L19.4 8.6c-.7-1-2.4-1-3.1 0L7 21.2c-.4.6-.5 1.4-.2 2 .3.7 1 1.1 1.7 1.1h2.2L2.3 37.8c-.4.6-.4 1.4-.1 2s1 1 1.7 1h12.9V55c0 .6.4 1 1 1s1-.4 1-1V40.8h13c.7 0 1.3-.4 1.7-1s.4-1.3 0-2zM4 38.9l8.5-13.5c.4-.6.4-1.4.1-2s-1-1-1.7-1l-2.3.1 9.2-12.6 9.3 12.5h-1.4c-.7 0-1.3.4-1.7 1s-.4 1.3 0 2l7.8 13.5zM61.7 42.7l-5-8.4c.7 0 1.4-.4 1.7-1.1s.3-1.4-.2-2l-5.8-7.9c-.4-.5-.9-.8-1.6-.8-.6 0-1.2.3-1.6.8l-5.8 7.9c-.4.6-.5 1.4-.2 2 .3.7 1 1.1 1.7 1.1L40 42.7c-.4.6-.4 1.3 0 2 .4.6 1 1 1.7 1H50v9.5c0 .6.4 1 1 1s1-.4 1-1v-9.5h8c.7 0 1.3-.4 1.7-1s.4-1.4 0-2m-10.2 1c-.2-.1-.3-.2-.5-.2s-.4.1-.5.2H42l4.9-8.5c.4-.6.4-1.3 0-2-.4-.6-1-1-1.7-.9l5.8-7.9 5.9 7.8c-.7 0-1.3.4-1.7 1s-.4 1.3 0 2l5 8.4z" />
  </svg>
);
export default IconTreesAlt;
