import * as React from "react";
const IconHen = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 26.2c-3.5 0-6.4 2.9-6.4 6.4v1h-6.7v-1.8c0-6.9 5.6-12.6 12.6-12.6.6 0 1-.4 1-1s-.4-1-1-1c-8 0-14.6 6.5-14.6 14.6v1.8h-19V22.5c0-4.2-2.9-7.8-6.9-8.8l5.6-5.8c.4-.4.4-1 0-1.4s-1-.4-1.4 0L18.9 12V4.9c0-.6-.4-1-1-1s-1 .4-1 1V12l-5.3-5.5c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l5.6 5.8C12 14.6 9.1 18 8.9 22H3c-.6 0-1 .4-1 1s.4 1 1 1h5.9v12.2c0 13.2 10.7 23.9 23.9 23.9s23.9-10.7 23.9-23.9v-3.5c0-2.4 2-4.4 4.4-4.4.6 0 1-.4 1-1s-.5-1.1-1.1-1.1m-6.4 10c0 12.1-9.8 21.9-21.9 21.9s-21.9-9.8-21.9-21.9V22.5c0-3.9 3.1-7 7-7s7 3.1 7 7v12.1c0 .3.1.5.3.7s.4.3.7.3h28.7v.6z" />
  </svg>
);
export default IconHen;
