import * as React from "react";
const IconHouseAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.6 13.7 59.8 9c-.8-2.1-2.8-3.5-5-3.5H9.2C7 5.5 5 6.9 4.2 9l-1.8 4.7c-.6 1.7-.4 3.5.6 5 .8 1.2 2 2 3.4 2.2v33.5c0 2.2 1.8 4 4 4h43.3c2.2 0 4-1.8 4-4V21c1.4-.3 2.6-1.1 3.4-2.2 1-1.5 1.2-3.4.5-5.1m-5.9 40.8c0 1.1-.9 2-2 2H10.3c-1.1 0-2-.9-2-2V21.1h47.3v33.4zm3.7-36.9c-.6.9-1.7 1.5-2.8 1.5H7.4c-1.1 0-2.2-.5-2.8-1.5s-.8-2.1-.4-3.2L6 9.7c.5-1.3 1.8-2.2 3.2-2.2h45.5c1.4 0 2.7.9 3.2 2.2l1.8 4.7c.5 1.1.3 2.3-.3 3.2" />
    <path d="M28.3 46.1h7.5c2.2 0 4-1.8 4-4v-7.5c0-2.2-1.8-4-4-4h-7.5c-2.2 0-4 1.8-4 4v7.5c0 2.2 1.8 4 4 4m-2-11.5c0-1.1.9-2 2-2h7.5c1.1 0 2 .9 2 2v7.5c0 1.1-.9 2-2 2h-7.5c-1.1 0-2-.9-2-2z" />
  </svg>
);
export default IconHouseAlt;
