import * as React from "react";
const IconCloset4 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M48.4 2H15.6c-2.2 0-4 1.8-4 4v55c0 .6.4 1 1 1s1-.4 1-1v-7.3h36.7V61c0 .6.4 1 1 1s1-.4 1-1V6c.1-2.2-1.7-4-3.9-4M33 19h17.4v14.7H33zM50.4 6v11H33V4h15.4c1.1 0 2 .9 2 2M13.6 51.3V6c0-1.1.9-2 2-2H31v47.7H13.6zm19.4.4v-16h17.4v16z" />
  </svg>
);
export default IconCloset4;
