import React from 'react';
import { BringzzText } from "@bringzz/components";

const SearchFooter = () => {
    return (
        <div className='bg-white m-2 px-4 pt-8 rounded-xl'>
            <BringzzText tag='h5' className='font-normal'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum consequat nisl eget ultricies mollis. Praesent eu sodales libero, sit amet commodo ante. Fusce ut mauris urna.</BringzzText>
            <div className='text-center py-4'>
                <BringzzText tag='h1'>Bringzz</BringzzText>
                <BringzzText tag='h4' className='font-normal'>since 2023 &copy; </BringzzText>
            </div>
        </div>
    );
};

export default SearchFooter;