import * as React from "react";
const IconTruckMonster = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M23.6 41.2h-.2c0-.1-.1-.3-.1-.5l.1-.1q.6-.6.6-1.5c0-.9-.2-1.1-.6-1.5l-1.3-1.3q-.6-.6-1.5-.6c-.6-.1-1.1.2-1.5.6l-.1.1h-.1c-.1-.1-.3-.1-.4-.2l-.1-.1V36c0-1.2-.9-2.1-2.1-2.1h-1.8c-1.2 0-2.1.9-2.1 2.1v.1s0 .1-.1.1l-.2.1H12l-.1-.1q-.6-.6-1.5-.6t-1.5.6l-1.3 1.3Q7 38.1 7 39t.6 1.5l.1.2c-.1.1-.1.2-.2.4h-.2q-.9 0-1.5.6t-.6 1.5V45c0 1.2.9 2.1 2.1 2.1h.2c0 .1.1.3.1.5l-.1.1c-.2.2-.5.7-.5 1.3s.2 1.1.6 1.5l1.3 1.3q.6.6 1.5.6c.5 0 1.1-.2 1.5-.6l.1-.1h.1c.1.1.3.1.4.2l.1.1v.1c0 1.2.9 2.1 2.1 2.1h1.8c1.2 0 2.1-.9 2.1-2.1V52s0-.1.1-.1l.2-.1h.1v.2q.6.6 1.5.6c.5 0 1.1-.2 1.5-.6l1.3-1.3q.6-.6.6-1.5t-.6-1.5l-.1-.2c.1-.1.1-.2.2-.4h.2c1.1 0 2.1-.9 2.1-2.1v-1.8c0-1-.9-1.9-2.1-2m.1 4s-.1.1 0 0c-.1.1-.2.1-.2.1-.9 0-1.7.5-2 1.3 0 .1-.1.2-.1.3-.4.8-.2 1.7.4 2.3l.1.1v.1l-1.3 1.3h-.1l-.1-.1c-.6-.6-1.5-.8-2.3-.5l-.1.1c-.8.3-1.3 1.1-1.3 1.9v.1s0 .1-.1.1h-1.8s-.1 0-.1-.1V52c0-.9-.5-1.6-1.4-2h-.4c-.3-.1-.6-.2-.9-.2-.5 0-1 .2-1.4.6l-.1.1h-.1L9 49.1V49l.1-.1c.6-.6.8-1.5.5-2.3 0-.1-.1-.2-.1-.3-.3-.8-1.1-1.3-1.9-1.3h-.1s-.1 0-.1-.1v-1.8s0-.1.1-.1h.1c.9 0 1.7-.5 2-1.3 0-.1.1-.2.1-.3.4-.8.2-1.7-.4-2.3l-.1-.1v-.1l1.3-1.3-.7-.7.8.7.1.1c.6.6 1.5.8 2.3.5l.1-.1c.8-.3 1.3-1.1 1.3-1.9v-.1s0-.1.1-.1h1.8s.1 0 .1.1v.1c0 .9.5 1.6 1.4 2 .1 0 .2.1.3.1.8.4 1.7.2 2.3-.4l.1-.1h.1l1.3 1.3v.1l-.1.1c-.6.6-.8 1.5-.5 2.3 0 .1.1.2.1.3.3.8 1.1 1.3 1.9 1.4h.1s.1 0 .1.1z" />
    <path d="M15.5 40.9c-1.7 0-3.2 1.4-3.2 3.2s1.4 3.2 3.2 3.2 3.2-1.4 3.2-3.2-1.4-3.2-3.2-3.2m0 4.4c-.6 0-1.2-.5-1.2-1.2s.5-1.2 1.2-1.2c.6 0 1.2.5 1.2 1.2s-.5 1.2-1.2 1.2M56.3 42.7h-.2c0-.1-.1-.3-.1-.5l.1-.1c.8-.8.9-2.1.1-3l-1.3-1.3c-.8-.8-2.1-.8-3-.1l-.1.1h-.1c-.1-.1-.3-.1-.4-.2 0 0-.1 0-.1-.1v-.1c0-1.2-.9-2.1-2.1-2.1h-1.8c-1.2 0-2.1.9-2.1 2.1v.1s0 .1-.1.1l-.1.2h-.1l-.1-.1q-.6-.6-1.5-.6t-1.5.6L40.5 39c-.8.8-.9 2.1-.1 3l.1.2c-.1.1-.1.2-.2.4h-.2c-1.1 0-2.1.9-2.1 2.1v1.8c0 1.2.9 2.1 2.1 2.1h.2c0 .1.1.3.1.5l-.1-.1q-.6.6-.6 1.5t.6 1.5l1.3 1.3c.8.8 2.1.9 3 .1l.1-.1h.1c.1.1.3.1.4.2 0 0 .1 0 .1.1v.1c0 1.2.9 2.1 2.1 2.1h1.8c1.2 0 2.1-.9 2.1-2.1v-.1s0-.1.1-.1l.2-.1h.1l.1.1q.6.6 1.5.6c.5 0 1.1-.2 1.5-.6l1.3-1.3q.6-.6.6-1.5t-.6-1.5L56 49c.1-.1.1-.3.2-.4h.2c1.1 0 2.1-.9 2.1-2.1v-1.8c-.1-1.1-1-2-2.2-2m.1 3.9s-.1.1 0 0c-.1.1-.2.1-.2.1-.9 0-1.7.5-2 1.3 0 .1-.1.2-.1.3-.4.8-.2 1.7.4 2.3l.1.1v.1L53.2 52h-.1l-.1-.1c-.6-.6-1.5-.8-2.3-.5h-.1c-.8.3-1.3 1.1-1.3 2v.1s0 .1-.1.1h-1.8s-.1 0-.1-.1v-.1c0-.9-.5-1.6-1.3-2-.1 0-.2-.1-.3-.1-.3-.1-.6-.2-.9-.2-.5 0-1 .2-1.4.6l-.1.1h-.1l-1.3-1.3v-.1l.1-.1c.6-.6.8-1.5.5-2.3 0-.1-.1-.2-.1-.3-.3-.8-1.1-1.3-1.9-1.4h-.1s-.1 0-.1-.1v-1.8s0-.1.1-.1h.1c.9 0 1.7-.5 2-1.3 0-.1.1-.2.1-.3.4-.8.2-1.7-.4-2.3l-.1-.1v-.1l1.3-1.3h.1l.1.1c.6.6 1.5.8 2.3.5h.1c.8-.3 1.3-1.1 1.3-2v-.1s0-.1.1-.1h1.8s.1 0 .1.1v.1c0 .9.5 1.6 1.3 2 .1 0 .2.1.3.1.8.4 1.7.2 2.3-.4l.1-.1h.1l1.3 1.3v.1l-.1.1c-.6.6-.8 1.5-.5 2.3 0 .1.1.2.1.3.3.8 1.1 1.3 1.9 1.4h.1s.1 0 .1.1z" />
    <path d="M48.2 42.4c-1.7 0-3.2 1.4-3.2 3.2s1.4 3.2 3.2 3.2 3.2-1.4 3.2-3.2-1.4-3.2-3.2-3.2m0 4.3c-.6 0-1.2-.5-1.2-1.2s.5-1.2 1.2-1.2 1.2.5 1.2 1.2-.5 1.2-1.2 1.2M60.7 33.8v-7.3c0-3.5-2.8-6.3-6.3-6.3h-7.3L40.5 8.8c-.2-.3-.5-.5-.9-.5H27.2c-.6 0-1 .4-1 1v10.9h-17c-3.5 0-6.3 2.8-6.3 6.3v7.9c-.6 1.1-.9 2.4-.9 3.7 0 .6.4 1 1 1s1-.4 1-1c0-4.7 5.3-8.4 11.8-8.4s11.8 3.8 11.8 8.4c0 .6.4 1 1 1h6.9c.6 0 1-.4 1-1 0-4.7 5.3-8.4 11.8-8.4S60 33.5 60 38.1c0 .6.4 1 1 1s1-.4 1-1c0-1.5-.5-3-1.3-4.3M28.2 10.3H39l5.8 9.9H28.2zm20 17.4c-7.1 0-13 4.2-13.7 9.4h-5c-.7-5.3-6.6-9.4-13.7-9.4-4.4 0-8.4 1.6-10.9 4.1v-5.2c0-2.4 1.9-4.3 4.3-4.3h45.2c2.4 0 4.3 1.9 4.3 4.3v4.9c-2.5-2.4-6.3-3.8-10.5-3.8" />
  </svg>
);
export default IconTruckMonster;
