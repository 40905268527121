import * as React from "react";
const IconMoveCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m60.8 29.2-7.3-7.3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l7.3 7.3.3.3H33V4.3l.3.3 7.3 7.3c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-7.3-7.3C34 2.4 33 2 32 2s-2 .4-2.8 1.2L22 10.5c-.4.4-.4 1 0 1.4s1 .4 1.4 0l7.3-7.3c.1-.1.2-.2.4-.3V31H4.8c-.2 0-.3 0-.4.1.1-.2.2-.3.3-.4l7.3-7.3c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-7.3 7.3c-1.5 1.5-1.5 4 0 5.5l7.3 7.3c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-7.3-7.3c-.1-.1-.3-.3-.3-.5 0 0 .2.1.4.1H31v26.7c-.1-.1-.3-.2-.4-.3l-7.3-7.3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l7.3 7.3C30 61.6 31 62 32 62s2-.4 2.8-1.2l7.3-7.3c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-7.3 7.3-.3.3V33h26.6c-.1.1-.2.3-.3.4l-7.3 7.3c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3l7.3-7.3c1.5-1.6 1.5-4 0-5.6" />
  </svg>
);
export default IconMoveCopy;
