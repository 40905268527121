import * as React from "react";
const Icon500px = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M35.6 7.8c-3.7 0-7.5.8-10.4 2-.3.1-.4.4-.4.7s.1.7.3 1.3c.1.4.7 1.7 1.6 1.3 3-1.1 6.1-1.7 9-1.7 3.2 0 6.5.7 9.6 2 2.4 1.1 4.7 2.5 7.2 4.7.1.1.4.1.6.1.4 0 1-.4 1.4-.8.7-.8 1.1-1.4.4-2-2.4-2.3-4.9-3.9-8.2-5.2-3.6-1.7-7.3-2.4-11.1-2.4" />
    <path d="M16.3 41.9s.3 1 .4 1.4c1.1 2.4 2.5 4.5 4.4 6.5 1.8 1.8 4.1 3.2 6.5 4.4 2.5 1.1 5.1 1.5 7.9 1.5s5.4-.6 7.9-1.5c2.4-1.1 4.5-2.5 6.5-4.4 1.8-1.8 3.2-4.1 4.4-6.5 1.1-2.5 1.5-5.1 1.5-7.9s-.6-5.5-1.5-7.9c-1.1-2.4-2.5-4.5-4.4-6.5-1.8-1.8-4.1-3.2-6.5-4.4-2.5-1.1-5.1-1.6-7.9-1.6s-5.5.6-8 1.6c-2 .8-5.4 3-7.3 5.1V4.9h28c1.1 0 1.1-1.4 1.1-2s0-2-1.1-2l-30.3.2c-.8 0-1.4.7-1.4 1.4V26c0 .7 1 1.3 1.8 1.4 1.7.4 2.1-.1 2.5-.7l.1-.1c.7-1 2.7-3 2.7-3 3.1-3.1 7.3-4.8 11.7-4.8s8.6 1.7 11.7 4.8 4.8 7.3 4.8 11.7S50.1 44 47 47.1s-7.3 4.8-11.8 4.8c-3 0-5.9-.7-8.3-2.4V35.2c0-2 .8-3.9 2.3-5.5 1.6-1.7 3.8-2.8 6.1-2.8s4.5.8 6.1 2.4c1.5 1.6 2.4 3.7 2.4 5.9 0 4.8-3.8 8.5-8.5 8.5-1 0-2.7-.4-2.7-.4-1-.3-1.4 1.1-1.5 1.4-.4 1.7.3 2 .4 2.1 1.6.4 2.5.6 3.9.6 6.8 0 12.3-5.5 12.3-12.3 0-6.6-5.5-12.3-12.3-12.3-3.2 0-6.3 1.3-8.7 3.5-2.3 2.3-3.5 5.1-3.5 8v11.8c-1.3-1.4-2.5-3.5-3.4-5.8-.3-.8-1.1-.7-2-.4-.5.6-1.8 1-1.5 2" />
    <path d="M55.2 52.6c-.4-.4-.7-.7-1.1-.7-.3-.1-.6 0-.8.1l-.1.1c-2.3 2.3-4.8 4.1-7.8 5.2-3 1.3-6.2 2-9.6 2-3.2 0-6.5-.7-9.6-2-3-1.3-5.5-3-7.8-5.2-2.3-2.4-4.1-4.9-5.2-7.8-1.1-2.7-1.4-4.8-1.6-5.5v-.1c-.1-.7-.8-.8-2-.7-.3.2-1.6.3-1.6 1.3.4 2.3 1 4.5 1.8 6.5 1.4 3.4 3.5 6.3 6.1 8.9 2.7 2.7 5.6 4.6 8.9 6.1 3.5 1.4 7.2 2.3 11 2.3s7.5-.7 11-2.3c3.4-1.4 6.3-3.5 8.9-6.1l.1-.1c.2-.3.5-.8-.6-2M30.7 38.5c0 .4.4.7.6 1l.1.1c.4.4.7.6 1.1.6s.4-.1.4-.1l2.3-2.3 2.1 2.1c.1.1.4.3.7.4.4 0 .7-.1 1.1-.6 1-1 .4-1.4.1-1.7l-2.1-2.1 2.3-2.4c.4-.6.1-1.1-.4-1.5-.7-.7-1.3-.7-1.7-.4l-2.3 2-2.1-2.3c-.1-.1-.3-.1-.4-.1-.3 0-.7.1-1.1.6-.7.7-.8 1.1-.4 1.6l2.3 2.3-2.1 2.4c-.4 0-.5.2-.5.4" />
  </svg>
);
export default Icon500px;
