import * as React from "react";
const IconMobileSignalOut = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M38.3 46.3c-.6 0-1 .4-1 1V54c0 2.8-2.3 5.1-5.1 5.1H10.3c-2.8 0-5.1-2.3-5.1-5.1V10.1C5.2 7.3 7.5 5 10.3 5h21.9c2.8 0 5.1 2.3 5.1 5.1v2.5c0 .6.4 1 1 1s1-.4 1-1v-2.5c0-3.9-3.2-7.1-7.1-7.1H10.3c-3.9 0-7.1 3.2-7.1 7.1V54c0 3.9 3.2 7.1 7.1 7.1h21.9c3.9 0 7.1-3.2 7.1-7.1v-6.7c0-.6-.4-1-1-1" />
    <path d="M19.7 12.3h3.1c.6 0 1-.4 1-1s-.4-1-1-1h-3.1c-.6 0-1 .4-1 1s.4 1 1 1M17.9 52.2c0 .6.4 1 1 1h4.6c.6 0 1-.4 1-1s-.4-1-1-1h-4.6c-.5 0-1 .4-1 1M38.1 24.2c-.6 0-1 .4-1 1s.4 1 1 1c7.8 0 14.2 6.4 14.2 14.2 0 .6.4 1 1 1s1-.4 1-1c0-8.9-7.3-16.2-16.2-16.2" />
    <path d="M38.1 30.6c-.6 0-1 .4-1 1s.4 1 1 1c4.3 0 7.8 3.5 7.8 7.8 0 .6.4 1 1 1s1-.4 1-1c0-5.4-4.4-9.8-9.8-9.8M38.1 17.7c-.6 0-1 .4-1 1s.4 1 1 1c11.4 0 20.7 9.3 20.7 20.7 0 .6.4 1 1 1s1-.4 1-1c0-12.5-10.2-22.7-22.7-22.7M39.3 36.4c-1.9 0-3.4 1.5-3.4 3.4s1.5 3.4 3.4 3.4 3.4-1.5 3.4-3.4-1.6-3.4-3.4-3.4m0 4.7c-.8 0-1.4-.6-1.4-1.4s.6-1.4 1.4-1.4 1.4.6 1.4 1.4-.7 1.4-1.4 1.4" />
  </svg>
);
export default IconMobileSignalOut;
