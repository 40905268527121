import * as React from "react";
const IconShipFavourite = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.6 4.2c-1.9 0-3.7.7-5.1 2-1.4-1.3-3.1-2-5.1-2-4.1 0-7.4 3.3-7.4 7.4 0 6.4 9.9 14 11.8 15.5.2.1.4.2.6.2s.5-.1.6-.2c2-1.5 11.8-9.1 11.8-15.5.2-4-3.1-7.4-7.2-7.4m-5.1 20.9C45.4 21.9 39 16 39 11.6c0-3 2.4-5.4 5.4-5.4 1.7 0 3.2.8 4.3 2.1.4.5 1.2.5 1.6 0 1-1.3 2.6-2.1 4.3-2.1 3 0 5.4 2.4 5.4 5.4 0 5.1-8.1 11.6-10.5 13.5M38 56.6c-.2-.2-.5-.4-.8-.6l4.5-15.8c.7-2.5-.6-5.1-2.9-6.1l-3.2-1.3V28c0-.6-.4-1-1-1s-1 .4-1 1v3.9L24 28c-1.2-.5-2.7-.5-3.9 0l-9.6 4v-7.2c0-1.7 1.4-3.1 3.1-3.1H27c.6 0 1-.4 1-1s-.4-1-1-1H15.3v-5.8c0-1.7 1.4-3.1 3.1-3.1h7.8c.6 0 1-.4 1-1s-.4-1-1-1H23V3.5c0-.6-.4-1-1-1s-1 .4-1 1v5.1h-2.6c-2.8 0-5.1 2.3-5.1 5.1v5.9c-2.7.1-4.9 2.3-4.9 5.1v8.1l-3.2 1.3c-2.4 1-3.7 3.6-3 6.1L6.8 56c-.3.2-.5.3-.8.5l-3.6 3.2c-.4.4-.5 1-.1 1.4s1 .5 1.4.1L7.3 58c1.2-1 2.9-1 4.1 0l1 .8c1.9 1.6 4.8 1.6 6.7 0l1-.8c1.2-1 2.9-1 4.1 0l1 .8c1.9 1.6 4.8 1.6 6.7 0l.9-.8c1.2-1 2.9-1 4.1 0l3.6 3.1c.2.2.4.2.7.2s.6-.1.8-.3c.4-.4.3-1-.1-1.4zm-6.7 0-.9.8c-1.2 1-2.9 1-4.1 0l-1-.8c-1.9-1.6-4.8-1.6-6.7 0l-1 .8c-1.2 1-2.9 1-4.1 0l-1-.8c-1.1-1-2.6-1.4-4-1.2L4.2 39.7c-.4-1.5.4-3.1 1.8-3.7l3.7-1.6h.1l10.9-4.5q1.2-.45 2.4 0L34 34.4h.1l3.7 1.6c1.4.6 2.2 2.2 1.8 3.7l-4.4 15.7c-1.3-.2-2.8.2-3.9 1.2" />
  </svg>
);
export default IconShipFavourite;
