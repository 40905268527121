import * as React from "react";
const IconHourglassDollar = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M46.3 37h4.6c.6 0 1-.4 1-1s-.4-1-1-1h-1.8v-1.1c0-.6-.4-1-1-1s-1 .4-1 1V35h-.8c-1.9 0-3.5 1.6-3.5 3.5s1.6 3.5 3.5 3.5h2.1c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5h-4.6c-.6 0-1 .4-1 1s.4 1 1 1h3.3v1c0 .6.4 1 1 1s1-.4 1-1v-1.2c1.6-.3 2.8-1.7 2.8-3.4 0-1.9-1.6-3.5-3.5-3.5h-2.1c-.8 0-1.5-.7-1.5-1.5s.7-1.4 1.5-1.4" />
    <path d="M47.4 26.3c-8.1 0-14.6 6.6-14.6 14.6s6.6 14.6 14.6 14.6S62 49 62 40.9s-6.6-14.6-14.6-14.6m0 27.3c-7 0-12.6-5.7-12.6-12.6s5.7-12.6 12.6-12.6c7 0 12.6 5.7 12.6 12.6s-5.7 12.6-12.6 12.6" />
    <path d="M36.8 15.9V9.7h1.9c.6 0 1-.4 1-1s-.4-1-1-1H3c-.6 0-1 .4-1 1s.4 1 1 1h.8v6.1c0 4.9 1.9 9.6 5.4 13l3.1 3.1L9.2 35c-3.4 3.4-5.4 8.2-5.4 13v6.1H3c-.6 0-1 .4-1 1s.4 1 1 1h25c.6 0 1-.4 1-1s-.4-1-1-1H5.8V48c0-4.3 1.8-8.6 4.8-11.6l3.8-3.8c.4-.4.4-1 0-1.4l-3.8-3.8c-3.1-3.1-4.8-7.2-4.8-11.6V9.7h29v6.1c0 4.3-1.8 8.6-4.8 11.6l-3.8 3.8c-.2.2-.3.4-.3.7s.1.5.3.7l1.7 1.7c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-1-1 3.1-3.1c3.5-3.3 5.4-8.1 5.4-12.9" />
  </svg>
);
export default IconHourglassDollar;
