import * as React from "react";
const IconPurse1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.8 50.4-5.3-19.6L60 16.3c.1-.3 0-.6-.2-.9s-.5-.4-.8-.4H43.6L40 6.1c-.2-.5-.8-.8-1.3-.6-.5.4-.7.9-.5 1.5l3.3 8.2h-19L26 7c.2-.5 0-1.1-.5-1.3s-1.1 0-1.3.5l-3.8 8.9H5c-.3 0-.6.1-.8.4-.2.2-.3.6-.2.8l3.5 14.4-5.3 19.7c-.5 1.9-.1 4 1.1 5.5 1.2 1.6 3.1 2.5 5.1 2.5h47.2c2 0 3.9-.9 5.1-2.5s1.6-3.6 1.1-5.5M42.9 17.1h14.8l-3.1 12.6H9.3L6.2 17.1zm16.2 37.6c-.8 1.1-2.1 1.7-3.5 1.7H8.4c-1.4 0-2.6-.6-3.5-1.7-.8-1.1-1.1-2.5-.8-3.8l5.2-19.2h45.4l5.2 19.2c.3 1.3 0 2.7-.8 3.8" />
  </svg>
);
export default IconPurse1;
