import React from 'react';
import {
	BringzzRecommendationItem,
	BringzzProductBox,
	BringzzCartSelector,
	BringzzIcon,
	BringzzText,
	BringzzBoxSlider
} from "@bringzz/components";
import RecommendationItemBackground from 'assets/images/RecommendationItemBackground.png';
import ProductExample from 'assets/images/ProductExample.png';

const RecommendationBox = ({ className, ...props }) => {
	const productId = '12345';

	const addToCart = (productId, quantity) => {
		props.addToCart(productId, quantity);
		console.log(`${productId} added ${quantity} times to cart`);
	};

	const clickOnProduct = productId => {
		console.log(`Product ${productId} clicked`);
	};

	const doFav = (event, productId) => {
		event.stopPropagation();
		console.log(`${productId} bookmarked`);
	};
	return (
		<div className='px-4 bg-sand pt-14 pb-6'>
			<BringzzText
				tag="h2"
				className="uppercase"
			>
				RECOMMENDED FOR YOU
			</BringzzText>
			<BringzzBoxSlider className='space-x-4 mt-1 mb-8 py-1'>
				{[1, 2, 3, 4, 5, 6].map((product, index) => {
					return <BringzzProductBox
						key={index}
						className='shadow-md'
						productId={productId}
						image={ProductExample}
						manufacturer={'Manufacturer'}
						label={'One Multi Vitamines'}
						productType='Lotion'
						productQuantity='100 ml'
						price='18,50'
						rebateValue={5}
						rebateType={'€'}
						rebateLabel={'off'}
						rebatePosition='right-0'
						topRightChildren={
							<BringzzIcon
								icon='IconBookmark'
								folder='LineIcons'
								className='text-midnight-blue p-2'
								size='20'
								onClick={event => doFav(event, productId)}
							></BringzzIcon>
						}
						onClick={productId => clickOnProduct(productId)}>
						<BringzzCartSelector
							productId={productId}
							maxQuantity={3}
							onCartClick={(productId, quantity) =>
								addToCart(productId, quantity)
							}
						></BringzzCartSelector>
					</BringzzProductBox>
				})}
			</BringzzBoxSlider>
			<BringzzRecommendationItem
				title='Flu & Cold Remedies'
				subTitle='Turpis rutrum amet vitae ac integer et pharetra gravida. Praesent laoreet amet...'
				className='bg-electric-blue'
				image={RecommendationItemBackground}
			>
				{[1, 2, 3, 4, 5].map((product, index) => {
					return <BringzzProductBox
						key={index}
						productId={productId}
						image={ProductExample}
						manufacturer={'Manufacturer'}
						label={'One Multi Vitamines'}
						productType='Lotion'
						productQuantity='100 ml'
						price='18,50'
						rebateValue={5}
						rebateType={'€'}
						rebateLabel={'off'}
						rebatePosition='right-0'
						topRightChildren={
							<BringzzIcon
								icon='IconBookmark'
								folder='LineIcons'
								className='text-midnight-blue p-3'
								size='24'
								onClick={event => doFav(event, productId)}
							></BringzzIcon>
						}
						onClick={productId => clickOnProduct(productId)}
					>
						<BringzzCartSelector
							productId={productId}
							maxQuantity={3}
							onCartClick={(productId, quantity) =>
								addToCart(productId, quantity)
							}
						></BringzzCartSelector>
					</BringzzProductBox>
				})}
			</BringzzRecommendationItem>
		</div>
	);
};

export default RecommendationBox;
