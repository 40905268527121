import * as React from "react";
const IconSproutAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.6 3c0-.5-.4-1-1-1s-13.9-.1-19.8 5.7c-2.6 2.5-4 6.5-4.8 10.2-.8-3.7-2.2-7.6-4.8-10.2C21.3 1.9 8 2 7.4 2c-.5 0-1 .4-1 1s-.1 13.6 5.8 19.4c5 4.9 15.4 5.6 18.7 5.7V61c0 .6.4 1 1 1s1-.4 1-1V28.1c3.3-.1 13.7-.8 18.7-5.7 6.1-5.8 6-18.9 6-19.4M13.7 21C9.2 16.6 8.5 7.1 8.4 4c3.2.1 12.9.8 17.3 5.1s5.1 13.8 5.2 16.9c-3.1 0-12.8-.7-17.2-5M33 26.1c.1-3.1.8-12.6 5.2-16.9 4.4-4.4 14.2-5 17.3-5.1-.1 3.1-.8 12.6-5.2 16.9S36.2 26 33 26.1" />
  </svg>
);
export default IconSproutAlt1;
