import * as React from "react";
const IconGrass = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.1 30c-5.1 0-9.8 2.5-13.6 7.2.9-5.4 2.1-10.4 3.7-14.8 3.4-9.7 7.4-14.1 9.9-14.1 1.5 0 2.7-1.2 2.7-2.7s-1.2-2.7-2.7-2.7c-5.4 0-10.6 1.6-15.5 4.7-2.6 1.7-5.1 3.7-7.4 6.2q-3.45-4.05-7.5-6.9c-5.2-3.6-10.8-5.4-16.6-5.4-1.5 0-2.7 1.2-2.7 2.7s1.2 2.7 2.7 2.7c2.5 0 6.5 4.3 9.9 14.1 1.2 3.5 2.2 7.4 3.1 11.5-1.2 3.3-2.3 6.8-3 10.4-.6-1.3-1.3-2.6-2.1-3.7-4-5.9-9.3-9.2-15.1-9.2-1.5 0-2.7 1.2-2.7 2.7s1.2 2.7 2.7 2.7c.3 0 2 1.3 3.7 6.1 1.7 5 2.7 11.5 2.7 18.3 0 1.5 1.2 2.7 2.7 2.7h36.1c1.5 0 2.7-1.2 2.7-2.7 0-6.8.9-13.3 2.7-18.3 1.7-4.9 3.4-6.1 3.7-6.1 1.5 0 2.7-1.2 2.7-2.7S60.6 30 59.1 30m-35.2-9.7c-3.3-9.7-7.7-15.4-11.8-15.4-.4 0-.7-.3-.7-.7s.3-.7.7-.7c5.4 0 10.6 1.7 15.5 5.1 2.6 1.8 5.1 4.1 7.3 6.7-1.2 1.5-2.4 3-3.6 4.7-1.9 2.8-3.5 5.9-4.9 9.1-.7-3.1-1.6-6.1-2.5-8.8m35.2 13.1c-1.8 0-4 2.9-5.6 7.5-1.8 5.2-2.8 11.9-2.8 18.9 0 .4-.3.7-.7.7H13.9c-.4 0-.7-.3-.7-.7 0-7-1-13.7-2.8-18.9-1.6-4.6-3.7-7.5-5.6-7.5-.4 0-.7-.3-.7-.7s.4-.7.8-.7c5.1 0 9.8 2.9 13.3 8.2 1.3 1.9 2.3 4.1 3.2 6.4.2.4.6.7 1 .7.5 0 .8-.4.9-.8 1.6-9.6 4.9-18.3 9.6-25.4 3.4-5.1 7.3-9 11.7-11.8C49.2 6.4 54 4.9 59 4.9c.4 0 .7.3.7.7s-.3.7-.7.7c-4 0-8.4 5.8-11.8 15.4-2 5.7-3.5 12.3-4.4 19.5-.1.5.2.9.7 1.1s1 0 1.2-.5c.3-.6.6-1.1 1-1.6C49.3 35 54 32 59.1 32c.4 0 .7.3.7.7s-.3.7-.7.7" />
  </svg>
);
export default IconGrass;
