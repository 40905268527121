import * as React from "react";
const IconMa = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 513 342"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 .3V342h513V.3z" />
    <path
      
      d="M359.8 148.9h-73.3l-22.7-69.7-22.7 69.7h-73.3l59.3 43.1-22.7 69.7 59.3-43.1 59.3 43.1-22.5-69.7zm-116.1 37.7 7.7-23.6h24.8l7.7 23.6-20.1 14.6zm27.9-37.7H256l7.8-24zm24.3 29-4.8-14.9h25.3zM236.6 163l-4.8 14.9-20.5-14.9zm-5.3 61.8 7.8-24 12.6 9.2zm44.5-14.9 12.6-9.2 7.8 24z"
    />
  </svg>
);
export default IconMa;
