import * as React from "react";
const IconHormonalRing = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M24 42c9.941 0 18-8.059 18-18S33.941 6 24 6 6 14.059 6 24s8.059 18 18 18m0 2c11.046 0 20-8.954 20-20S35.046 4 24 4 4 12.954 4 24s8.954 20 20 20"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M24 37c7.18 0 13-5.82 13-13s-5.82-13-13-13-13 5.82-13 13 5.82 13 13 13m0 2c8.284 0 15-6.716 15-15S32.284 9 24 9 9 15.716 9 24s6.716 15 15 15"
      clipRule="evenodd"
    />
  </svg>
);
export default IconHormonalRing;
