import * as React from "react";
const IconMinus = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59 34.3H5c-1.2 0-2.3-1-2.3-2.3 0-1.2 1-2.3 2.3-2.3h54c1.2 0 2.3 1 2.3 2.3 0 1.2-1.1 2.3-2.3 2.3" />
  </svg>
);
export default IconMinus;
