import * as React from "react";
const IconTargetCustomer = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60 29.8h-5.9C53 19.3 44.7 11 34.3 9.9V4c0-1.2-1-2.3-2.3-2.3-1.2 0-2.3 1-2.3 2.3v5.9C19.3 11 11 19.3 9.9 29.8H4c-1.2 0-2.3 1-2.3 2.3 0 1.2 1 2.3 2.3 2.3h5.9c1 10.6 9.4 19 19.9 20.1V60c0 1.2 1 2.3 2.3 2.3 1.2 0 2.3-1 2.3-2.3v-5.6c10.5-1.1 18.9-9.5 19.9-20.1H60c1.2 0 2.3-1 2.3-2.3 0-1.2-1.1-2.2-2.3-2.2m-25.8 20c0-1.2-1-2.2-2.2-2.2s-2.2 1-2.2 2.2c-8-1-14.4-7.5-15.3-15.6h2.3c1.2 0 2.3-1 2.3-2.3 0-1.2-1-2.3-2.3-2.3h-2.3c1.1-8 7.4-14.3 15.3-15.3.1 1.1 1.1 2 2.2 2s2.1-.9 2.2-2c8 1 14.3 7.3 15.3 15.3-1.1.1-2 1.1-2 2.2 0 1.2.9 2.1 2 2.2-.8 8.3-7.2 14.8-15.3 15.8" />
    <path d="M38.1 36.1c1.3-1.4 2.1-3.3 2.1-5.4 0-4.5-3.7-8.2-8.2-8.2s-8.2 3.7-8.2 8.2c0 2.1.8 4 2.1 5.4-1.6.7-3 1.6-4.3 2.7-.9.8-1 2.2-.2 3.2.4.5 1.1.8 1.7.8.5 0 1.1-.2 1.5-.6 2.1-1.8 4.7-2.9 7.4-2.9 2.8 0 5.4 1 7.4 2.8.9.8 2.3.8 3.2-.2.8-.9.8-2.3-.2-3.2-1.3-1-2.7-1.9-4.3-2.6M32 27c2 0 3.7 1.7 3.7 3.7S34 34.4 32 34.4s-3.7-1.7-3.7-3.7S30 27 32 27" />
  </svg>
);
export default IconTargetCustomer;
