import * as React from "react";
const IconMask = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.7 4.6H34.6c-3.5 0-6.3 2.8-6.3 6.3v1.6H5c-1.7 0-3 1.3-3 3v27c0 9.3 7.5 16.8 16.8 16.8 7.4 0 13.7-4.8 16-11.5 2.8 2.3 6.3 3.5 10 3.6h.3c4.2 0 8.3-1.7 11.5-4.8 3.4-3.4 5.4-8 5.4-12.7v-23c0-3.4-2.8-6.3-6.3-6.3M18.8 57.4C10.6 57.4 4 50.7 4 42.5v-27c0-.6.4-1 1-1h27.6c.6 0 1 .4 1 1v27c0 8.2-6.6 14.9-14.8 14.9M60 33.9c0 4.2-1.7 8.3-4.8 11.3-2.9 2.8-6.5 4.3-10.4 4.2-3.6-.1-6.9-1.4-9.5-3.8.2-1 .3-2.1.3-3.1V18.2c.5.4 1 .6 1.6.6 1.5 0 2.6-1.2 2.6-2.6 0-1.5-1.2-2.6-2.6-2.6-.7 0-1.4.3-1.9.8-.5-1.1-1.5-1.8-2.7-1.8h-2.3V11c0-2.4 1.9-4.3 4.3-4.3h21.1c2.4 0 4.3 1.9 4.3 4.3z" />
    <path d="M13.5 26.7c0-1.5-1.2-2.6-2.6-2.6-1.5 0-2.6 1.2-2.6 2.6 0 1.5 1.2 2.6 2.6 2.6 1.5.1 2.6-1.1 2.6-2.6M26.7 24.1c-1.5 0-2.6 1.2-2.6 2.6 0 1.5 1.2 2.6 2.6 2.6 1.5 0 2.6-1.2 2.6-2.6.1-1.4-1.1-2.6-2.6-2.6M53.1 13.5c-1.5 0-2.6 1.2-2.6 2.6 0 1.5 1.2 2.6 2.6 2.6 1.5 0 2.6-1.2 2.6-2.6s-1.2-2.6-2.6-2.6M26.6 41.6c-.5-.2-1.1.1-1.3.6-1 2.8-3.6 4.6-6.5 4.6s-5.5-1.9-6.5-4.6c-.2-.5-.8-.8-1.3-.6s-.8.8-.6 1.3c1.3 3.6 4.6 5.9 8.4 5.9s7.1-2.4 8.4-5.9c.2-.5-.1-1.1-.6-1.3M45.2 31c-3.8 0-7.1 2.4-8.4 5.9-.2.5.1 1.1.6 1.3s1.1-.1 1.3-.6c1-2.8 3.6-4.6 6.5-4.6s5.5 1.9 6.5 4.6c.1.4.5.7.9.7.1 0 .2 0 .3-.1.5-.2.8-.8.6-1.3-1.2-3.5-4.5-5.9-8.3-5.9" />
  </svg>
);
export default IconMask;
