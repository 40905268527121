import * as React from "react";
const IconGrow = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59 45.9a7.63 7.63 0 0 0-9.31-5.29c-3.53 1-8.37 2.48-12 3.53a4.8 4.8 0 0 0-.11-3.21c-.92-2.29-3.47-3.31-5.6-3.31H22a4 4 0 0 1-2.68-1.25 5.77 5.77 0 0 0-3.9-1.52H7.31A6.45 6.45 0 0 0 1 41.42v12.86A6.57 6.57 0 0 0 7.43 61h9.74a6.34 6.34 0 0 0 3.51-1.08l.12.06a19.2 19.2 0 0 0 9.26 2.39 18.6 18.6 0 0 0 5.68-.86L53.84 56h.12a7.89 7.89 0 0 0 5.09-10ZM7.43 57A2.57 2.57 0 0 1 5 54.28V41.42a2.46 2.46 0 0 1 2.31-2.57h8.1a1.74 1.74 0 0 1 1.2.48 12 12 0 0 0 1 .78v16.81a2 2 0 0 1-.42 0Zm45.18-4.82-18.06 5.48a14.8 14.8 0 0 1-4.49.68 15.2 15.2 0 0 1-7.35-1.91l-1.12-.59V41.6H32a2.16 2.16 0 0 1 1.89.8c.16.41-.12 1.63-2.22 3.63l-1.15 1.09.79 1.36C32.17 50 32.17 50 42.27 47c4.29-1.25 8.57-2.53 8.57-2.53a3.66 3.66 0 0 1 4.37 2.62 3.9 3.9 0 0 1-2.6 5.06ZM62.92 10.24A3.22 3.22 0 0 0 60 7.3c-2.49-.19-7.15 0-10.41 3.21a10 10 0 0 0-1.44 1.81 12.4 12.4 0 0 0-2.81-6.65c-3-3.38-8-3.95-10.66-4a3.22 3.22 0 0 0-3.18 3c-.21 2.86-.11 8.17 2.87 11.51 2.47 2.77 6.27 3.33 9 3.33a18 18 0 0 0 2-.1v14.68a2 2 0 0 0 4 0v-9c2.84-.05 7.58-.61 10.53-3.54 3.18-3.26 3.26-8.49 3.02-11.31m-18.67 5.28c-2.09.13-5.28-.12-6.94-2-1.86-2.07-2-5.93-1.91-7.83 1.91.12 5.13.63 6.91 2.62s2.02 5.33 1.94 7.21M57 18.75c-1.93 1.92-5.47 2.32-7.59 2.38.27-2.16 1-5.8 3-7.79 1.78-1.77 4.48-2.09 6.22-2.09H59c.06 1.81 0 5.51-2 7.5" />
  </svg>
);
export default IconGrow;
