import * as React from "react";
const IconSpeechBubble38 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M10.9 10.8c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8 1.8-.8 1.8-1.8-.9-1.8-1.8-1.8M16.8 10.8c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8 1.8-.8 1.8-1.8-.8-1.8-1.8-1.8M22.8 10.8c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8 1.8-.8 1.8-1.8-.9-1.8-1.8-1.8M41.3 28.9c-1 0-1.8.8-1.8 1.8s.8 1.7 1.8 1.7 1.8-.8 1.8-1.7-.8-1.8-1.8-1.8M47.2 28.9c-1 0-1.8.8-1.8 1.8s.8 1.7 1.8 1.7 1.8-.8 1.8-1.7-.8-1.8-1.8-1.8M53.2 28.9c-1 0-1.8.8-1.8 1.8s.8 1.7 1.8 1.7 1.8-.8 1.8-1.7-.9-1.8-1.8-1.8M10.9 44.9c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8 1.8-.8 1.8-1.8-.9-1.8-1.8-1.8M16.8 44.9c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8 1.8-.8 1.8-1.8-.8-1.8-1.8-1.8M22.8 44.9c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8 1.8-.8 1.8-1.8-.9-1.8-1.8-1.8" />
    <path d="M47.2 20.4c-4.4 0-8.4 1.4-11.1 3.6l-6.9-5.4c1.5-1.6 2.3-3.6 2.3-5.7 0-5.8-6.6-10.6-14.8-10.6C8.6 2.4 2 7.1 2 13s6.6 10.6 14.8 10.6c4.4 0 8.3-1.4 11-3.5l6.9 5.4c-1.4 1.6-2.3 3.6-2.3 5.6 0 2.3 1.1 4.5 2.8 6.2L29 41.2c-2.7-2.8-7.2-4.7-12.3-4.7C8.6 36.4 2 41.2 2 47s6.6 10.6 14.8 10.6c1.5 0 3-.2 4.4-.5l9 4.4c.1.1.3.1.4.1.2 0 .5-.1.6-.2.3-.3.4-.7.3-1l-2.2-7.7c1.5-1.7 2.2-3.6 2.2-5.7 0-1.5-.5-3-1.3-4.3l6.5-4.1c2.7 1.9 6.3 3 10.4 3C55.4 41.7 62 36.9 62 31c0-5.8-6.6-10.6-14.8-10.6M27.5 51.7c-.2.3-.3.6-.2 1l1.7 6.1-7.3-3.6c-.1-.1-.3-.1-.4-.1h-.2c-1.4.3-2.8.5-4.3.5C9.7 55.6 4 51.7 4 47s5.7-8.6 12.8-8.6c7 0 12.8 3.9 12.8 8.6 0 1.7-.7 3.3-2.1 4.7M4 13c0-4.7 5.7-8.6 12.8-8.6S29.6 8.2 29.6 13s-5.7 8.6-12.8 8.6S4 17.7 4 13m43.2 26.7c-7.1 0-12.8-3.9-12.8-8.6s5.7-8.6 12.8-8.6S60 26.3 60 31c0 4.8-5.7 8.7-12.8 8.7" />
  </svg>
);
export default IconSpeechBubble38;
