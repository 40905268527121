import * as React from "react";
const IconSlideshow = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 28.9H7c-2.8 0-5 2.2-5 5V57c0 2.8 2.2 5 5 5h50c2.8 0 5-2.2 5-5V33.9c0-2.8-2.2-5-5-5M60 57c0 1.7-1.3 3-3 3H7c-1.7 0-3-1.3-3-3V33.9c0-1.7 1.3-3 3-3h50c1.7 0 3 1.3 3 3zM9.3 21.6h45.4c.6 0 1-.4 1-1s-.4-1-1-1H9.3c-.6 0-1 .4-1 1s.4 1 1 1M14.3 12.8h35.4c.6 0 1-.4 1-1s-.4-1-1-1H14.3c-.6 0-1 .4-1 1s.5 1 1 1M19.2 4h25.6c.6 0 1-.4 1-1s-.4-1-1-1H19.2c-.6 0-1 .4-1 1s.5 1 1 1" />
  </svg>
);
export default IconSlideshow;
