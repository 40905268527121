import * as React from "react";
const IconSortAmountAscCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m23.4 44.6-8.8 8.8V8.3c0-.6-.4-1-1-1s-1 .4-1 1v45.1l-8.8-8.8c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4L13 56.5c.2.2.5.3.7.3.3 0 .5-.1.7-.3L24.8 46c.4-.4.4-1 0-1.4s-1.1-.4-1.4 0M34.9 9.3l5.1-.1c.6 0 1-.5 1-1s-.5-1-1-1l-5 .1c-.6 0-1 .5-1 1-.1.5.3 1 .9 1M34.9 25.1l13-.1c.6 0 1-.5 1-1 0-.6-.4-1-1-1l-13 .1c-.6 0-1 .5-1 1 0 .6.4 1 1 1M34.9 40.9l18.2-.1c.6 0 1-.5 1-1 0-.6-.4-1-1-1l-18.2.1c-.6 0-1 .5-1 1 0 .6.4 1 1 1M61 54.7l-26.1.1c-.6 0-1 .4-1 1s.4 1 1 1l26.1-.1c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconSortAmountAscCopy;
