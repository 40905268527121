import React from 'react';
import { useNavigation } from '../../context/NavigationContext';
import { BringzzPageContainer, BringzzIcon, BringzzText, BringzzImage, BringzzButton, BringzzTabs, BringzzTab, BringzzTabPanel, BringzzPostingCard } from "@bringzz/components"
import AvatarExample from 'assets/images/AvatarExample.png';
import PostExample1 from 'assets/images/PostExample1.png';
{/*             <button onClick={() => navigate('/profile/settings')}>Go to some path</button> */ }

const ProfilePharmacy2 = () => {
    const { backPress } = useNavigation();

    return (
        <BringzzPageContainer
            header={{
                left: <BringzzIcon
                    size="22"
                    icon="IconChevronLeft"
                    folder="LineIcons"
                    className="cursor-pointer"
                    onClick={backPress}
                />,
                right: <>
                    <BringzzIcon
                        size="22"
                        icon="IconStar"
                        folder="LineIcons"
                        className="cursor-pointer"
                        onClick={(event) => {

                        }}
                    />
                    <BringzzIcon
                        size="22"
                        icon="IconUploadAlt"
                        folder="LineIcons"
                        className="cursor-pointer"
                        onClick={(event) => {

                        }}
                    /></>,
                background: <BringzzImage className="object-cover w-full h-full" src="/images/3.jpg" alt="react logo" />,
                avatar: <BringzzImage src="/logo192.png" alt="react logo" />
            }}
            subHeader={
                <div className='space-y-6'>
                    <BringzzText tag='h4' className="font-bold">Vienna</BringzzText>
                    <BringzzText tag='h2'>Apotheke Mustermann</BringzzText>
                    <div className='flex justify-between'>
                        <BringzzButton className="bg-magic-lilac" icon={{ variant: "IconPlus", folder: "LineIcons", size: "16" }}>Follow</BringzzButton>
                        <BringzzButton className="border border-black" icon={{ variant: "IconSpeechBubble16", folder: "LineIcons", size: "18" }}>Chat</BringzzButton>
                        <BringzzButton className="border border-black" icon={{ variant: "IconPhone", folder: "LineIcons", size: "16" }}>Call</BringzzButton>
                    </div>
                    <div className='space-y-2'>
                        <BringzzText tag="h5" className="font-normal">Dui amet felis gravida convallis nisi suspendisse sed. Neque nam massa in odio dui amet felis gravida convallis nisi suspendisse sed. Neque nam massa in odio dui amet felis gravida...</BringzzText>
                        <BringzzText tag="h5" className="font-normal underline">Read more</BringzzText>
                    </div>
                    <div className='flex space-x-1 items-center'>
                        <BringzzText tag="h5" className="font-normal text-black/40">29 Posts</BringzzText>
                        <p className="text-[5px] text-black/40">&#9679;</p>
                        <BringzzText tag="h5" className="font-normal text-black/40">1,128 Followers</BringzzText>
                    </div>
                </div>
            }>

            <BringzzTabs defaultActiveTab="tab1">
                <BringzzTab id="tab1">
                    <BringzzIcon
                        size="20"
                        icon="IconLayoutAlt1"
                        folder="LineIcons"
                        className="cursor-pointer"
                        onClick={(event) => {

                        }}
                    />
                </BringzzTab>
                <BringzzTabPanel id="tab1">Content for Tab 1</BringzzTabPanel>

                <BringzzTab id="tab2">
                    <BringzzIcon
                        size="20"
                        icon="IconLayoutAlt"
                        folder="LineIcons"
                        className="cursor-pointer"
                        onClick={(event) => {

                        }}
                    />
                </BringzzTab>
                <BringzzTabPanel id="tab2">
                    <BringzzText tag="h2" className="p-4">Posts</BringzzText>
                    {[1, 2, 3].map((index) => {
                        return <BringzzPostingCard
                            key={index}
                            avatar={AvatarExample}
                            name='Apotheke Mustermann'
                            header={<BringzzImage className="w-full h-auto" src={PostExample1} alt="post image"></BringzzImage>}
                            likesCount={1200}
                            commentsCount={40}
                            title='Fall time is cold time: How to prepare for the cold season'
                            description="Turpis rutrum amet vitae ac integer et pharetra gravida. Praesent
					laoreet amet Dui amet felis gravida convallis nisi suspendisse sed."
                            action={{
                                label: 'Read more',
                                icon: 'IconEbook',
                                iconFolder: 'LineIcons',
                                onClick: () => console.log("text")
                            }}
                        ></BringzzPostingCard>
                    })}
                </BringzzTabPanel>

                <BringzzTab id="tab3">
                    <BringzzIcon
                        size="20"
                        icon="IconUserAlt1"
                        folder="LineIcons"
                        className="cursor-pointer"
                        onClick={(event) => {

                        }}
                    />
                </BringzzTab>
                <BringzzTabPanel id="tab3">Content for Tab 3</BringzzTabPanel>

                <BringzzTab id="tab4">
                    <BringzzIcon
                        size="20"
                        icon="IconInfoCircle"
                        folder="LineIcons"
                        className="cursor-pointer"
                        onClick={(event) => {

                        }}
                    />
                </BringzzTab>
                <BringzzTabPanel id="tab4">Content for Tab 4</BringzzTabPanel>
            </BringzzTabs>
        </BringzzPageContainer>

    )
}

export default ProfilePharmacy2;