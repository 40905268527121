import * as React from "react";
const IconShipReload = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M38 57.3c-.2-.2-.5-.4-.7-.5L41.4 42c.7-2.4-.5-4.8-2.8-5.8L35.7 35v-4.4c0-.6-.4-1-1-1s-1 .4-1 1v3.6l-8.9-3.7c-1.2-.5-2.5-.5-3.7 0l-8.9 3.7v-6.7c0-1.6 1.3-2.8 2.8-2.8h12.5c.6 0 1-.4 1-1s-.4-1-1-1H16.7v-5.4c0-1.6 1.3-2.8 2.8-2.8h7.3c.6 0 1-.4 1-1s-.4-1-1-1h-3.1V7.6c0-.6-.4-1-1-1s-1 .4-1 1v4.8h-2.3c-2.7 0-4.8 2.2-4.8 4.8v5.4c-2.5.1-4.6 2.2-4.6 4.8V35l-2.9 1.2c-2.1 1-3.3 3.5-2.7 5.8l4.2 14.8c-.2.1-.5.3-.7.5l-3.4 3c-.4.4-.5 1-.1 1.4s1 .5 1.4.1l3.4-3c1.1-.9 2.7-.9 3.7 0l.9.8c1.8 1.6 4.5 1.6 6.3 0l.9-.8c1.1-.9 2.7-.9 3.7 0l.9.8c1.8 1.6 4.5 1.6 6.3 0l.9-.8c1.1-.9 2.7-.9 3.7 0l3.4 2.9c.2.2.4.2.7.2s.6-.1.8-.3c.4-.4.3-1-.1-1.4zm-6.4 0-.9.8c-1.1.9-2.7.9-3.7 0l-.9-.8c-1.8-1.6-4.5-1.6-6.3 0l-.9.8c-1.1.9-2.7.9-3.7 0l-.9-.8c-1.1-.9-2.4-1.3-3.7-1.1L6.4 41.5c-.4-1.4.3-2.9 1.6-3.4l3.5-1.5h.1l10.3-4.3c.7-.3 1.5-.3 2.2 0l10.2 4.3h.1l3.5 1.5c1.3.6 2 2 1.6 3.4l-4.1 14.7c-1.4-.2-2.7.2-3.8 1.1M59.1 12.5c-.2-.5-.8-.8-1.3-.6s-.8.8-.6 1.3q.6 1.5.6 3.3c0 4.9-4 8.9-8.9 8.9s-8.9-4-8.9-8.9 4-8.9 8.9-8.9c.6 0 1-.4 1-1s-.4-1-1-1c-6 0-10.9 4.9-10.9 10.9s4.9 10.9 10.9 10.9 10.9-4.9 10.9-10.9c.1-1.4-.2-2.7-.7-4" />
    <path d="m51.7 6.6-2.9 2.9c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l3.6-3.6c.4-.4.4-1 0-1.4l-3.6-3.6c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4z" />
  </svg>
);
export default IconShipReload;
