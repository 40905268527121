import * as React from "react";
const IconConstructionCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.4 22.5c2 0 3.6-1.6 3.6-3.6v-3.3c0-1.6-1-3-2.6-3.5l-32-9.9c-.3-.1-.7-.2-1.1-.2H13.5c-2 0-3.6 1.6-3.6 3.6v13.2c0 2 1.6 3.6 3.6 3.6h4.3v21.7H6c-2.2 0-4 1.8-4 4V58c0 2.2 1.8 4 4 4h25.6c2.2 0 4-1.8 4-4v-9.8c0-2.2-1.8-4-4-4H19.8V22.5h32.3v12.6c0 1.6 1.1 3 2.7 3.4 3.5.9 5.7 4.3 5 7.9-.5 2.8-2.8 5.1-5.5 5.5-2.1.4-4.1-.2-5.7-1.5s-2.5-3.2-2.5-5.3c0-.8.1-1.6.4-2.3.2-.5-.1-1.1-.6-1.3s-1.1.1-1.3.6c-.3 1-.5 2-.5 3 0 2.6 1.2 5.1 3.2 6.8 1.6 1.4 3.6 2.1 5.7 2.1.5 0 1.1 0 1.6-.1 3.6-.6 6.5-3.6 7.2-7.2.8-4.7-2-9-6.5-10.2-.6-.2-1.3-.7-1.3-1.5V22.5zM33.6 48.2V58c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2v-9.8c0-1.1.9-2 2-2h25.6c1.1 0 2 .9 2 2M60 15.5v3.3c0 .9-.7 1.6-1.6 1.6H27.7v-16L58.8 14c.7.1 1.2.8 1.2 1.5m-48.1 3.3V5.6c0-.9.7-1.6 1.6-1.6h12.2v16.5H13.5c-.9 0-1.6-.8-1.6-1.7" />
  </svg>
);
export default IconConstructionCopy;
