import * as React from "react";
const IconCloud = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M43.7 54.1H15.9c-7.8 0-14.1-6.3-14.1-14 0-7.1 5.5-13 12.5-13.9.5-9.1 8.3-16.3 17.7-16.3 2.1 0 4.1.3 5.8 1 3.7 1.3 6.9 3.8 9.1 7.2 4 .7 7.7 2.6 10.3 5.5 3.2 3.4 4.9 7.8 4.9 12.4.2 10-8.2 18.1-18.4 18.1M16 49.6h27.7c7.8 0 14.1-6.1 14.1-13.7 0-3.4-1.3-6.7-3.7-9.3-2.2-2.4-5.4-3.9-8.7-4.3-.7-.1-1.4-.5-1.7-1.1-1.6-2.9-4.2-5.1-7.3-6.1-1.3-.5-2.7-.7-4.3-.7-7.3 0-13.3 5.8-13.3 13v1c0 1.2-1 2.3-2.3 2.3H16c-5.5 0-9.7 4.2-9.7 9.5s4.2 9.4 9.7 9.4" />
  </svg>
);
export default IconCloud;
