import * as React from "react";
const IconCloudSearch2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.5 24.7c-1.6 0-3.1.3-4.5.8.1-.7.2-1.5.2-2.2 0-7.2-6.4-13-14.2-13s-14.2 5.8-14.2 13c0 .8.1 1.5.2 2.2-1.4-.5-2.9-.8-4.5-.8C7.2 24.7 2 29.5 2 35.3s5.2 10.6 11.5 10.6h2.6c.6 0 1-.4 1-1s-.4-1-1-1h-2.6C8.2 43.9 4 40 4 35.3s4.3-8.6 9.5-8.6c2 0 3.9.6 5.5 1.6.4.2.8.2 1.2-.1.3-.3.5-.7.3-1.1-.5-1.2-.7-2.5-.7-3.8 0-6.1 5.5-11 12.2-11s12.2 4.9 12.2 11c0 1.3-.3 2.6-.7 3.8-.2.4 0 .9.3 1.1.3.3.8.3 1.2.1 1.6-1.1 3.5-1.6 5.5-1.6 5.3 0 9.5 3.9 9.5 8.6s-4.3 8.6-9.5 8.6c-.6 0-1 .4-1 1s.4 1 1 1c6.4 0 11.5-4.8 11.5-10.6s-5.2-10.6-11.5-10.6" />
    <path d="M39.5 45.9c1.5-1.6 2.3-3.7 2.3-5.9 0-2.4-1-4.7-2.8-6.3-3.6-3.5-9.6-3.5-13.2 0C24 35.4 23 37.6 23 40s1 4.7 2.8 6.3c1.8 1.7 4.2 2.6 6.6 2.6 1.9 0 3.9-.6 5.5-1.7l6.5 6.1c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4zm-12.3-1c-1.4-1.3-2.1-3-2.1-4.9 0-1.8.8-3.6 2.1-4.9 1.4-1.4 3.3-2.1 5.2-2.1s3.8.7 5.2 2.1c1.4 1.3 2.1 3 2.1 4.9 0 1.8-.8 3.6-2.1 4.9-2.8 2.8-7.5 2.8-10.4 0" />
  </svg>
);
export default IconCloudSearch2;
