import * as React from "react";
const IconBeta = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M49.1 17.3v-5.7c0-5.3-4.3-9.5-9.5-9.5H28.3c-2.2 0-4 1.8-4 4v50.2c0 2-1.7 3.7-3.7 3.7s-3.7-1.7-3.7-3.7v-6c0-.6-.4-1-1-1s-1 .4-1 1v6c0 3.2 2.6 5.7 5.7 5.7s5.7-2.6 5.7-5.7v-6.7h13.3c5.3 0 9.5-4.3 9.5-9.5v-5.7c0-3.7-2.2-7-5.3-8.5 3.1-1.7 5.3-4.9 5.3-8.6m-2 17V40c0 4.2-3.4 7.5-7.5 7.5H26.3V26.8h13.3c4.1 0 7.5 3.4 7.5 7.5m-7.5-9.5H26.3V6c0-1.1.9-2 2-2h11.3c4.2 0 7.5 3.4 7.5 7.5v5.7c0 4.2-3.4 7.6-7.5 7.6" />
  </svg>
);
export default IconBeta;
