import * as React from "react";
const IconFilePen = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M23.1 59H10.3c-1.7 0-3-1.3-3-3V8c0-1.7 1.3-3 3-3h23.8v14c0 .6.4 1 1 1H49v8c0 .6.4 1 1 1s1-.4 1-1v-8.4c0-.8-.3-1.6-.9-2.1L36.3 3.9c-.5-.6-1.3-.9-2.1-.9h-24c-2.8 0-5 2.2-5 5v48c0 2.8 2.2 5 5 5h12.9c.6 0 1-.4 1-1s-.4-1-1-1m24.7-41H36.1V6.4zM58.3 38.5l-2.4-2.4c-.7-.8-1.5-1.5-2.2-2.2-.2-.3-.6-.5-1-.6s-.9.1-1.2.4L34.2 50.8c-.3.3-.6.7-.7 1.1L31 59.6l-.2.6.3.4c.1.2.4.4.9.4h.2l7.8-2.6c.4-.1.8-.4 1.1-.7l17.2-17.1c.3-.3.4-.7.4-1.1s-.1-.8-.4-1M39.7 56.3c-.1.1-.2.2-.3.2l-5.9 2 1.9-5.9c0-.1.1-.2.2-.3l12.6-12.6 4.1 4.1zm14-13.9-4.1-4.1 2.9-2.9 2 2 2.1 2.1z" />
  </svg>
);
export default IconFilePen;
