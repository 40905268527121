import * as React from "react";
const IconPizzaCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.8 36.8c-2.2-6.9-7.1-14.4-13.7-21S34 4.3 27.1 2.1c-2.2-.7-4.5.4-5.3 2.5L2.3 56.2c-.6 1.6-.2 3.3 1 4.5.8.8 1.9 1.2 3 1.2q.75 0 1.5-.3L38 50.1h.3l3.6-1.4L59.4 42c2-.7 3.1-3 2.4-5.2M7 59.8c-.8.3-1.7.1-2.4-.5-.6-.6-.8-1.5-.4-2.3l5.1-13.4v.1c0 3 2.5 5.5 5.5 5.5s5.5-2.5 5.5-5.5-2.5-5.5-5.5-5.5c-1.7 0-3.2.8-4.2 1.9l9.1-23.8c1.8 1.1 3.7 2.3 5.7 3.7-1.9.9-3.2 2.8-3.2 5 0 3 2.5 5.5 5.5 5.5 2.6 0 4.8-1.9 5.4-4.4C38.2 31 43.4 37 47.8 44.3L43.4 46c.1-.4.1-.8.1-1.2 0-3-2.5-5.5-5.5-5.5s-5.5 2.5-5.5 5.5c0 1.8.9 3.4 2.2 4.4zm4.2-16.1c0-1.9 1.6-3.5 3.5-3.5s3.5 1.6 3.5 3.5-1.6 3.5-3.5 3.5-3.5-1.6-3.5-3.5m19.7-19.2c0 .2.1.4.1.6 0 1.9-1.6 3.5-3.5 3.5S24 27 24 25.1s1.5-3.4 3.3-3.5c1.3.9 2.4 1.9 3.6 2.9m6.7 23.7h-.2c-1.6-.3-2.9-1.7-2.9-3.4 0-1.9 1.6-3.5 3.5-3.5s3.5 1.6 3.5 3.5c0 .8-.3 1.6-.8 2.2zm21-8-8.9 3.4c-6.1-10.5-14.1-18.3-21.4-23.8-2.8-2.1-5.5-3.9-7.9-5.3l3.4-9c.3-.9 1.1-1.5 2.1-1.5.2 0 .5 0 .7.1 6.5 2.1 13.8 6.9 20.1 13.2s11.1 13.6 13.2 20.2c.4 1.1-.2 2.3-1.3 2.7" />
  </svg>
);
export default IconPizzaCopy;
