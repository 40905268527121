import * as React from "react";
const IconAvocado = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.1 26.9c-1.2-1.9-1.8-4-1.8-6.8 0-6.5-3.5-12.5-9.1-15.8-5.2-3-11.3-3.1-16.6-.1-2.8 1.6-5.1 3.9-6.7 6.8-1.6 2.8-2.4 6-2.4 9.1 0 3-.5 5-1.7 6.8-2.3 3.6-3.5 7.7-3.6 11.9-.1 6.1 2.2 11.9 6.6 16.3C20.1 59.6 25.9 62 32 62s11.8-2.4 16.1-6.7 6.7-10 6.7-16.1c0-4.4-1.3-8.6-3.7-12.3m-4.4 27C42.8 57.8 37.5 60 32 60s-10.8-2.2-14.7-6.2c-4-4-6.1-9.3-6-14.8.1-3.9 1.2-7.6 3.3-10.9 1.7-2.7 2-5.5 2-7.9 0-2.8.7-5.6 2.2-8.2 1.4-2.6 3.5-4.7 5.9-6 4.7-2.6 10.1-2.6 14.7.1 5 2.9 8.1 8.3 8.1 14 0 3.2.7 5.7 2.1 7.8 2.2 3.4 3.3 7.2 3.3 11.3-.1 5.6-2.3 10.8-6.2 14.7" />
    <path d="M32 30.5c-4.7 0-8.5 3.8-8.5 8.5s3.8 8.5 8.5 8.5 8.5-3.8 8.5-8.5-3.8-8.5-8.5-8.5m0 14.9c-3.6 0-6.5-2.9-6.5-6.5s2.9-6.5 6.5-6.5 6.5 2.9 6.5 6.5-2.9 6.5-6.5 6.5" />
  </svg>
);
export default IconAvocado;
