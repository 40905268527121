import * as React from "react";
const IconArrowsScrollV = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M31.3 24.5c.2.2.4.3.7.3s.5-.1.7-.3L52.8 4.7c.4-.4.4-1 0-1.4s-1-.4-1.4 0L32 22.4 12.6 3.3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4zM32.7 39.5c-.4-.4-1-.4-1.4 0L11.2 59.3c-.4.4-.4 1 0 1.4s1 .4 1.4 0L32 41.6l19.4 19.1c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4z" />
  </svg>
);
export default IconArrowsScrollV;
