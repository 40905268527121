import * as React from "react";
const IconTreesAlt10 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M23.9 25.2H25c.7 0 1.4-.4 1.7-1s.3-1.4-.2-2L18 10.7c-.7-1-2.3-1-3 0L6.5 22.2c-.4.6-.5 1.3-.2 2 .3.6 1 1 1.7 1h1.8L2.3 37.3c-.4.6-.4 1.3-.1 1.9s1 1 1.6 1h11.6V53c0 .6.4 1 1 1s1-.4 1-1V40.2h11.7c.7 0 1.3-.3 1.6-.9s.3-1.3 0-1.9zm-19.8 13L11.7 26c.4-.6.4-1.3.1-1.9s-1-1-1.6-1H8.3L16.5 12l8.2 11.1h-1.1c-.7 0-1.3.3-1.6.9s-.3 1.3 0 1.9l7 12.3zM62 24.3c0-7.8-6.3-14.1-14.1-14.1s-14.1 6.3-14.1 14.1c0 7.4 5.8 13.5 13.1 14v14.4c0 .6.4 1 1 1s1-.4 1-1V38.3c7.3-.5 13.1-6.6 13.1-14m-13.1 12V24.9c0-.6-.4-1-1-1s-1 .4-1 1v5L44 26.4c-.3-.4-1-.5-1.4-.2s-.5 1-.2 1.4l4.4 5.5v3.2c-6.2-.5-11.1-5.7-11.1-12 0-6.7 5.4-12.1 12.1-12.1S60 17.6 60 24.3c0 6.3-4.9 11.5-11.1 12" />
  </svg>
);
export default IconTreesAlt10;
