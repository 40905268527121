import * as React from "react";
const IconMetaverse3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 65"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.9 24c-.5-3.6-6.6-6.6-16.6-8v-5c0-.1 0-.1-.1-.2 0 0 0-.1-.1-.1 0-.1-.1-.1-.1-.1l-.1-.1c-.1 0-.1-.1-.2-.1L32.4 6.2c-.2-.1-.5-.1-.7 0l-11.3 4.2c-.1 0-.1.1-.2.1l-.1.1-.1.1s0 .1-.1.1c0 .1-.1.1-.1.2v4.9c-10 1.4-16.2 4.4-16.7 8-.1.2-.1.3-.1.4v25.2c0 5.2 6.8 9.3 15.5 9.3.6 0 1-.4 1-1V46.2c9.7-1.5 18.7-.8 24.9.3v11.4c0 .6.4 1 1 1 8.7 0 15.5-4.1 15.5-9.3V24.3q.15-.15 0-.3M21.8 12.8l9.3 3.5v7.2l-9.3-3.9zm11.3 3.5 9.3-3.5v6.8l-9.3 3.9zm-1-8.1 8.4 3.2-8.4 3.2-8.4-3.2zM17.5 56.8C10.6 56.5 5 53.3 5 49.5V26.2c3.1 2.1 7.6 4 12.5 4.3zm2-12.6V29.6c0-.5-.4-1-1-1-5.5-.1-10.4-2.5-13.4-4.7.7-2 5.4-4.6 14.7-5.9v2.4c0 .4.2.8.6.9L31.7 26c.1 0 .2.1.3.1s.2 0 .3-.1l11.3-4.7q.6-.3.6-.9V18c9.1 1.3 13.7 3.8 14.5 5.9-3 2.3-7.9 4.6-13.4 4.7-.5 0-1 .5-1 1v14.8c-6.1-1-15.1-1.7-24.8-.2M59 49.5c0 3.8-5.6 7-12.5 7.3V30.6c5-.3 9.5-2.3 12.5-4.3z" />
  </svg>
);
export default IconMetaverse3;
