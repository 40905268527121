import * as React from "react";
const IconPushLeft = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60 31H17l8.8-9c.4-.4.4-1 0-1.4s-1-.4-1.4 0L13.9 31.3c-.4.4-.4 1 0 1.4l10.5 10.7c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4L17 33h43c.6 0 1-.4 1-1s-.4-1-1-1M4 17.9c-.6 0-1 .4-1 1v26.2c0 .6.4 1 1 1s1-.4 1-1V18.9c0-.5-.4-1-1-1" />
  </svg>
);
export default IconPushLeft;
