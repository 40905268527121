import * as React from "react";
const IconCommentsReplyCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56 9H8c-2.7 0-5 2.2-5 5v37.7c0 1.1.6 2.2 1.7 2.7.4.2.8.3 1.3.3.7 0 1.3-.2 1.9-.7l8.8-7.3h5.5c.6 0 1-.4 1-1s-.4-1-1-1h-5.9c-.2 0-.5.1-.6.2l-9.1 7.6c-.4.3-.9.2-1 .1-.2-.1-.6-.3-.6-.9V14c0-1.6 1.3-3 3-3h48c1.6 0 3 1.3 3 3v31.7c0 .6.4 1 1 1s1-.4 1-1V14c0-2.7-2.2-5-5-5" />
    <path d="M52.1 44.5c-2.4-2.3-5.4-3.9-8.8-4.5-.1 0-.2-.1-.2-.2v-3.1c0-.8-.5-1.5-1.2-1.9s-1.6-.3-2.2.3l-10.1 8.1c-.5.4-.8 1-.8 1.6s.3 1.2.8 1.6l10.1 8.1c.3.3.8.5 1.3.5.3 0 .6-.1.9-.2.7-.4 1.2-1.1 1.2-1.9v-2.4c0-.1.1-.2.1-.2s.1-.1.2-.1c2.6.4 5.6 1.7 8.7 3.6.7.4 1.5.4 2.2 0s1.1-1.1 1-1.9c0-.7-.1-1.3-.2-1.7-.5-2.4-1.3-4-3-5.7m1.1 7.6h-.1c-2.3-1.4-5.9-3.3-9.4-3.9-.6-.1-1.3.1-1.8.5s-.8 1.1-.8 1.7v2.4s0 .1-.1.1h-.1l-10.1-8.1-.6.8.6-1 10.1-8.1h.1c.1 0 .1.1.1.1v3.1c0 1.1.8 2 1.9 2.2 3 .5 5.7 1.9 7.7 3.9 1.4 1.4 2 2.7 2.4 4.7 0 .2.1.6.1 1.4.1.1.1.2 0 .2M10.9 24.9c0 2.7 2.2 4.9 4.9 4.9s4.9-2.2 4.9-4.9-2.2-4.9-4.9-4.9-4.9 2.2-4.9 4.9m7.7 0c0 1.6-1.3 2.9-2.9 2.9s-2.9-1.3-2.9-2.9 1.3-2.9 2.9-2.9 2.9 1.3 2.9 2.9M36.9 24.9c0-2.7-2.2-4.9-4.9-4.9s-4.9 2.2-4.9 4.9 2.2 4.9 4.9 4.9c2.7-.1 4.9-2.3 4.9-4.9m-7.8 0c0-1.6 1.3-2.9 2.9-2.9s2.9 1.3 2.9 2.9-1.3 2.9-2.9 2.9-2.9-1.4-2.9-2.9M53.1 24.9c0-2.7-2.2-4.9-4.9-4.9s-4.9 2.2-4.9 4.9 2.2 4.9 4.9 4.9 4.9-2.3 4.9-4.9m-7.7 0c0-1.6 1.3-2.9 2.9-2.9s2.9 1.3 2.9 2.9-1.3 2.9-2.9 2.9-2.9-1.4-2.9-2.9" />
  </svg>
);
export default IconCommentsReplyCopy;
