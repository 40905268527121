import * as React from "react";
const IconOfficePlan = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.7 44.4h-2.1V29.5c0-3.6-2.9-6.5-6.5-6.5h-5c-3.6 0-6.5 2.9-6.5 6.5v14.9h-1.7c-.6 0-1 .4-1 1s.4 1 1 1h21.8c.6 0 1-.4 1-1s-.4-1-1-1M33.6 29.5c0-2.5 2-4.5 4.5-4.5h5c2.5 0 4.5 2 4.5 4.5v14.9h-14z" />
    <path d="M55.8 13.2H19.4a9.04 9.04 0 0 0-8.3-5.4C6.1 7.9 2 11.9 2 17v30c0 5 4.1 9.1 9.1 9.1h44.7c3.4 0 6.2-2.9 6.2-6.4v-30c0-3.6-2.8-6.5-6.2-6.5M4 17c0-3.9 3.2-7.1 7.1-7.1s7.1 3.2 7.1 7.1v24.4C16.5 39.3 14 38 11.1 38S5.7 39.3 4 41.4zm0 30c0-3.9 3.2-7.1 7.1-7.1s7.1 3.2 7.1 7.1-3.2 7.1-7.1 7.1S4 51 4 47m56 2.7c0 2.5-1.9 4.4-4.2 4.4h-39c2.1-1.7 3.4-4.2 3.4-7.1V17c0-.6-.1-1.2-.2-1.7h35.8c2.3 0 4.2 2 4.2 4.4z" />
  </svg>
);
export default IconOfficePlan;
