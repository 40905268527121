import * as React from "react";
const IconUserSettings = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M45.2 40.1c-2.7 0-4.9 2.2-4.9 4.9s2.2 4.9 4.9 4.9 4.9-2.2 4.9-4.9-2.2-4.9-4.9-4.9m0 7.8c-1.6 0-2.9-1.3-2.9-2.9s1.3-2.9 2.9-2.9 2.9 1.3 2.9 2.9-1.3 2.9-2.9 2.9" />
    <path d="M61.3 48.7 58 46.9c.1-.7.2-1.4.2-2 0-.8-.1-1.4-.2-2l3.4-1.9c.6-.3.8-1.1.6-1.7 0 0 0-.1-.1-.1l-3.2-5.4c-.4-.6-1.1-.8-1.7-.5 0 0-.1 0-.1.1l-3.4 1.9c-.2-.2-.4-.4-.6-.5-.6-.5-1.2-1-2-1.4-.2-.1-.2-.2-.2-.4v-2.1c0-1.3-1.1-2.4-2.4-2.4h-5.7c-1.3 0-2.4 1.1-2.4 2.4v2.2c0 .2-.1.3-.3.4-.1 0-.2.1-.2.1 0-.3 0-.5-.2-.8-.2-.2-.3-.4-.5-.6l-.2-.2c-3.7-4.3-8.5-6.9-13.8-7.2 5.6-.7 9.9-5.4 9.9-11.1 0-6.2-5-11.2-11.2-11.2s-11.2 5-11.2 11.2c0 5.7 4.3 10.5 9.9 11.1-5.3.4-10.2 2.9-13.8 7.2C4.3 36.4 2.1 42.9 2 50q0 .6.6.9c2.5 1.2 11.4 5.3 20.9 5.3 2.6 0 5.2-.3 7.9-.8l.4.7c.4.6 1.1.8 1.7.5 0 0 .1 0 .1-.1l3.4-1.9c.2.2.4.3.6.5.6.5 1.2 1 2.1 1.4.2.1.3.2.3.4v2.4c0 1.3 1.1 2.4 2.4 2.4h5.7c1.3 0 2.4-1.1 2.4-2.4v-2.5c0-.2.1-.3.3-.4.8-.4 1.4-.9 2-1.4.2-.2.4-.3.6-.5l3.2 1.8c1 .6 1.8.2 2.1-.4l3.2-5.4c.2-.3.2-.7.1-1.1-.2-.2-.4-.5-.7-.7M14.2 13.4c0-5.1 4.1-9.2 9.2-9.2s9.2 4.1 9.2 9.2-4.1 9.2-9.2 9.2-9.2-4.1-9.2-9.2m9.2 40.8C15 54.2 7 50.8 4 49.4 4.2 43 6.3 37.2 9.8 33c3.6-4.2 8.4-6.6 13.6-6.6s10 2.3 13.6 6.6l.1.2c.1.2.3.3.4.5.2.2.4.4.6.4-.2.2-.4.3-.6.5-.2.1-.4.3-.6.5l-3.2-1.8c-1.1-.6-1.8-.2-2.1.4l-3.1 5.4c-.2.3-.2.7-.1 1.1s.3.7.7.8l3.4 1.8c-.1.6-.2 1.3-.2 2s0 1.3.1 2l-3.4 2c-.6.4-.8 1.1-.6 1.7 0 0 0 .1.1.1l1.7 2.9c-2.3.5-4.6.7-6.8.7m33.8.2-3-1.6c-.7-.4-1.5-.3-2.1.2-.3.2-.5.4-.7.6-.6.5-1 .9-1.6 1.1-.8.4-1.4 1.3-1.4 2.2v2.5c0 .2-.2.4-.4.4h-5.7c-.2 0-.4-.2-.4-.4V57c0-.9-.5-1.8-1.4-2.2-.6-.3-1.1-.7-1.6-1.2-.2-.2-.4-.4-.7-.6-.3-.3-.7-.4-1.2-.4-.3 0-.6.1-.9.2l-3 1.6-2.5-4.3 2.9-1.6c.7-.4 1.1-1.2 1-2-.1-.6-.1-1.1-.1-1.7s0-1.2.1-1.7c.1-.8-.2-1.7-1-2.1l-2.8-1.6 2.5-4.3 3 1.6c.7.4 1.5.3 2.1-.2.2-.2.5-.4.7-.6.6-.5 1-.9 1.6-1.2.8-.4 1.4-1.3 1.4-2.2v-2.2c0-.2.2-.4.4-.4h5.7c.2 0 .4.2.4.4v2.1c0 .9.5 1.8 1.4 2.2.6.3 1 .7 1.6 1.1.2.2.5.4.7.6.6.5 1.4.5 2.1.2l3-1.6 2.5 4.3-2.8 1.6c-.7.4-1.1 1.2-1 2.1.1.5.1 1.1.1 1.7s0 1.1-.1 1.7c-.1.8.3 1.7 1 2.1l2.8 1.5z" />
  </svg>
);
export default IconUserSettings;
