import * as React from "react";
const IconMobilePhonesMedical = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M46.2 50.7c-.6 0-1 .4-1 1v5.1c0 1.7-1.4 3.1-3.1 3.1H10.6c-1.7 0-3.1-1.4-3.1-3.1V7.1C7.4 5.4 8.8 4 10.6 4h7c.6 0 1.1.5 1.1 1.1v2.2c0 2.3 1.9 4.2 4.2 4.2h7.5c2.3 0 4.2-1.9 4.2-4.2V5.1c0-.6.5-1.1 1.1-1.1h6.4c1.7 0 3.1 1.4 3.1 3.1v4c0 .6.4 1 1 1s1-.4 1-1v-4c0-2.8-2.3-5.1-5.1-5.1H10.7c-3 0-5.3 2.3-5.3 5.1v49.7c0 2.8 2.3 5.1 5.1 5.1H42c2.8 0 5.1-2.3 5.1-5.1v-5.1c.1-.5-.3-1-.9-1M32.6 5.1v2.2c0 1.2-1 2.2-2.2 2.2h-7.5c-1.2 0-2.2-1-2.2-2.2V5.1c0-.4-.1-.7-.2-1.1h12.3c-.1.3-.2.7-.2 1.1" />
    <path d="M50.4 30h-4v-4c0-.6-.4-1-1-1s-1 .4-1 1v4h-4c-.6 0-1 .4-1 1s.4 1 1 1h4v4c0 .6.4 1 1 1s1-.4 1-1v-4h4c.6 0 1-.4 1-1s-.4-1-1-1" />
    <path d="M45.5 17.9c-7.2 0-13.1 5.9-13.1 13.1s5.9 13.1 13.1 13.1S58.6 38.2 58.6 31s-5.9-13.1-13.1-13.1m0 24.2c-6.1 0-11.1-5-11.1-11.1s5-11.1 11.1-11.1 11.1 5 11.1 11.1-5 11.1-11.1 11.1" />
  </svg>
);
export default IconMobilePhonesMedical;
