import * as React from "react";
const IconLaptopPhoneCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.2 23.1h-8.8V6.8c0-1.2-1-2.2-2.2-2.2H9.4c-1.2 0-2.1 1-2.1 2.2v21.7c-.7.3-1.3.9-1.5 1.7L2 48.8c-.1.7.1 1.4.5 1.9s1 .8 1.7.8h34.7v6.2c0 1 .8 1.7 1.8 1.7h19.6c1 0 1.8-.8 1.8-1.7V24.8c-.1-.9-.9-1.7-1.9-1.7m-56 26.4s-.1 0-.2-.1 0-.2 0-.2l3.8-18.5c0-.1.1-.3.2-.3h30.9v19.1zm34.7-24.7v3.5H9.4c-.1 0-.1-.1-.1-.1V6.9c0-.1 0-.2.1-.2h39.8c.1 0 .2.1.2.2v16.2h-8.8c-.9 0-1.7.8-1.7 1.7M60 57.4H40.9V25.1H60z" />
    <path d="M50.5 50.1c-1.3 0-2.3 1-2.3 2.3s1 2.3 2.3 2.3 2.3-1 2.3-2.3-1.1-2.3-2.3-2.3M48.2 32.7h5.7c.6 0 1-.4 1-1s-.4-1-1-1h-5.7c-.6 0-1 .4-1 1s.4 1 1 1" />
  </svg>
);
export default IconLaptopPhoneCopy;
