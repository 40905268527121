import * as React from "react";
const IconKelvinAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M13.2 2c-4.6 0-8.4 3.8-8.4 8.4s3.8 8.4 8.4 8.4 8.4-3.8 8.4-8.4S17.9 2 13.2 2m0 14.8c-3.5 0-6.4-2.9-6.4-6.4S9.7 4 13.2 4s6.4 2.9 6.4 6.4-2.8 6.4-6.4 6.4M58.6 53.7 35.8 40.5c-.1 0-.1 0-.2-.1V40h.2l22.9-11.3c.5-.2.7-.8.5-1.3s-.8-.7-1.3-.5L35.5 37.8v-15c0-.6-.4-1-1-1s-1 .4-1 1V61c0 .6.4 1 1 1s1-.4 1-1V42.7l22.1 12.7c.2.1.3.1.5.1.3 0 .7-.2.9-.5.3-.5.1-1.1-.4-1.3" />
  </svg>
);
export default IconKelvinAlt;
