import * as React from "react";
const IconPointerCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.3 30.4 6.8 2.4C5.5 1.7 4 1.9 3 3c-1 1-1.2 2.5-.6 3.8l28 53.5c.6 1.1 1.7 1.7 2.9 1.7h.2c1.3-.1 2.4-.9 2.8-2.1l6.6-17 17-6.6c1.2-.5 2-1.6 2.1-2.8.1-1.3-.6-2.5-1.7-3.1m-1.1 4-17.4 6.8q-.45.15-.6.6l-6.8 17.4c-.2.5-.6.8-1.1.8s-.9-.2-1.2-.7L4.2 5.8c-.4-.7 0-1.3.2-1.4.2-.2.5-.4.8-.4.2 0 .4 0 .6.1l53.5 28c.4.2.7.7.7 1.2s-.3.9-.8 1.1" />
  </svg>
);
export default IconPointerCopy;
