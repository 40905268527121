import * as React from "react";
const IconCandleAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.6 60H43.3V37.1c0-2.2-1.8-4-4-4H33v-6.5c4-.5 7.2-4 7.6-8.6.6-7.2-5.5-13.8-7.3-15.6-.3-.3-.7-.4-1.1-.2-.4.1-.6.5-.6.9-.1 3.6-2.3 5.7-4.2 7.6-2.1 2.1-3.9 3.9-3.9 7.4 0 4.4 3.3 8 7.6 8.5v6.5h-6.3c-2.2 0-4 1.8-4 4V60H8.4c-.6 0-1 .4-1 1s.4 1 1 1h47.4c.6 0 1-.4 1-1s-.6-1-1.2-1M25.4 18c0-2.7 1.3-4 3.3-6 1.7-1.7 3.8-3.7 4.5-6.8 3 3.5 5.7 8.2 5.4 12.6-.4 4-3.1 6.9-6.6 6.9-3.7-.1-6.6-3-6.6-6.7m-2.7 19.1c0-1.1.9-2 2-2h3.2v5.7c0 .9-.7 1.6-1.6 1.6h-2c-.9 0-1.6-.7-1.6-1.6zm0 22.9V43.9c.5.2 1 .4 1.6.4h2c2 0 3.6-1.6 3.6-3.6V35h9.4c1.1 0 2 .9 2 2v23z" />
  </svg>
);
export default IconCandleAlt1;
