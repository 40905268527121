import * as React from "react";
const IconCropCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.7 51.6h-6.4V11.7L56 6.4c.5-.4.4-1.1 0-1.4-.4-.4-1-.3-1.4.1l-4.7 5.3H14.6V4c0-.6-.4-1-1-1s-1 .4-1 1v6.4H6.3c-.6 0-1 .4-1 1s.4 1 1 1h6.4v39.3H6.3c-.6 0-1 .4-1 1s.4 1 1 1h6.4V60c0 .6.4 1 1 1s1-.4 1-1v-6.4h34.7V60c0 .6.4 1 1 1s1-.4 1-1v-6.4h6.4c.6 0 1-.4 1-1s-.5-1-1.1-1M14.6 12.4h33.5L14.6 50zm1.3 39.2L49.4 14v37.7H15.9z" />
  </svg>
);
export default IconCropCopy;
