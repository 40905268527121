import * as React from "react";
const IconEllipse = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 52C15.5 52 2 43 2 32c0-11.1 13.5-20 30-20s30 9 30 20c0 11.1-13.5 20-30 20m0-38C16.6 14 4 22.1 4 32s12.6 18 28 18 28-8.1 28-18-12.6-18-28-18" />
  </svg>
);
export default IconEllipse;
