import * as React from "react";
const IconQuoraCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.3 55.3c-1.6-.6-4.1-1.9-5.3-3.1l.6-.6c6.2-5.3 9-11.8 9-21.1C61.6 14.3 48.3 1 32 1S2.4 14.3 2.4 30.6 15.7 60.2 32 60.2c3.7 0 7.3-.6 10.6-1.9 1.7 2.2 4.6 4.7 10 4.7.9 0 4.3-.3 4.5-.3.5 0 .9-.5.9-1v-5.5c-.1-.4-.3-.7-.7-.9m-1.4 5.5c-1.2.1-2.9.2-3.4.2-4.9 0-7.3-2.4-8.8-4.5-.2-.3-.5-.4-.8-.4-.1 0-.3 0-.4.1q-4.95 2.1-10.5 2.1c-15.2 0-27.6-12.4-27.6-27.6C4.4 15.4 16.8 3 32 3s27.6 12.4 27.6 27.6c0 8.6-2.6 14.7-8.3 19.6-1.5 1.3-1.7 1.7-1.6 2.2 0 .2.1.3.2.4 1.2 1.8 4.6 3.5 6.1 4.1v3.9z" />
    <path d="M32.3 9C25 9 19.1 14.9 19.1 22.1V39c0 7.3 5.9 13.2 13.2 13.2 2.8 0 5.5-.9 7.8-2.6.2-.2.4-.5.4-.8s-.1-.6-.3-.8c-1.6-1.4-5.4-3.4-6.8-4.2v-4.3c3 .6 8.5 1.8 10.1 3.1.3.2.6.3 1 .2.3-.1.6-.4.6-.7.2-1 .3-15.6.4-20 0-3.5-1.4-6.8-3.8-9.3-2.5-2.4-5.9-3.8-9.4-3.8m10.9 31.2c-3.4-1.5-9.8-2.7-10.6-2.8-.3-.1-.6 0-.8.2s-.4.5-.4.8v6.1q0 .6.6.9c1.2.6 4.1 2.1 5.9 3.3-1.7 1-3.6 1.5-5.5 1.5-6.2 0-11.2-5-11.2-11.2V22.1c0-6.2 5-11.2 11.2-11.2 3 0 5.8 1.2 7.9 3.3s3.3 4.9 3.3 7.8c-.2 5.9-.3 14.7-.4 18.2" />
  </svg>
);
export default IconQuoraCopy;
