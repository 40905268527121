import * as React from "react";
const IconTreeCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M46.1 25.6C46.1 14.4 40.3 2 32 2S17.9 14.4 17.9 25.6c0 11.4 5.8 20.7 13.1 21.5V61c0 .6.4 1 1 1s1-.4 1-1V47.1c7.3-.8 13.1-10.1 13.1-21.5M33 45.1v-7.8c0-.6-.4-1-1-1s-1 .4-1 1v7.8c-6.2-.8-11.1-9.3-11.1-19.5C19.9 14.3 25.7 4 32 4s12.1 10.3 12.1 21.6c0 10.2-4.9 18.7-11.1 19.5" />
  </svg>
);
export default IconTreeCopy;
