import * as React from "react";
const IconBrickWallAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 34.7c2.2 0 4-1.8 4-4V20.6c0-2.2-1.8-4-4-4H34.7V7.9c0-2.2-1.8-4-4-4H15.3c-2.2 0-4 1.8-4 4v8.7H6c-2.2 0-4 1.8-4 4v10.1c0 2.2 1.8 4 4 4h6.8V42H6c-2.2 0-4 1.8-4 4v10.1c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V46c0-2.2-1.8-4-4-4h-6.8v-7.3zm2-14.1v10.1c0 1.1-.9 2-2 2H42.7V18.6H58c1.1 0 2 .9 2 2m-19.3-2v14.1H23.9V18.6h16.8M13.3 7.9c0-1.1.9-2 2-2h15.4c1.1 0 2 .9 2 2v8.7H13.3zM4 30.7V20.6c0-1.1.9-2 2-2h15.9v14.1H6c-1.1 0-2-.9-2-2m10.8 4H31V42H14.8zM40.7 44v14.1H23.9V44zM4 56.1V46c0-1.1.9-2 2-2h15.9v14.1H6c-1.1 0-2-.9-2-2M60 46v10.1c0 1.1-.9 2-2 2H42.7V44H58c1.1 0 2 .9 2 2m-10.8-4H33v-7.3h16.2z" />
  </svg>
);
export default IconBrickWallAlt;
