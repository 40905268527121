import * as React from "react";
const IconIdeaShield = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.5 9.6 33.9 2.3c-1.2-.4-2.5-.4-3.7 0L10.5 9.6c-2.1.7-3.5 2.8-3.5 5v17.8C7 52 24.8 59.8 30.2 61.7q.9.3 1.8.3c.9 0 1.2-.1 1.8-.3C39.2 59.8 57 52.1 57 32.4V14.6c0-2.2-1.4-4.3-3.5-5M55 32.4C55 50.7 38.3 58 33.1 59.8c-.7.3-1.5.3-2.3 0C25.7 58 9 50.7 9 32.4V14.6c0-1.4.9-2.7 2.2-3.2l19.6-7.2c.8-.3 1.6-.3 2.3 0l19.6 7.2c1.3.5 2.2 1.8 2.2 3.2v17.8z" />
    <path d="M32.3 16.3c-3-.1-5.8 1-7.9 3.1s-3.3 4.9-3.3 7.8c0 3.8 1.9 7.3 5.1 9.3.6.4 1 1.1 1 2v1.9c0 2.4 1.9 4.3 4.3 4.3h1c2.4 0 4.3-1.9 4.3-4.3v-2c0-.4.1-.8.3-1.1s.4-.6.7-.8c3.1-1.9 5-5.3 5.1-9 .1-2.9-1-5.7-3-7.8s-4.7-3.3-7.6-3.4m2.5 24.1c0 1.3-1 2.3-2.3 2.3h-1c-1.3 0-2.3-1-2.3-2.3v-2.5h5.6v2.5m1.9-5.6c-.4.3-.8.6-1.1 1.1h-7.2c-.3-.4-.7-.8-1.1-1.1-2.6-1.6-4.2-4.5-4.2-7.6 0-2.4 1-4.7 2.7-6.4 1.7-1.6 3.9-2.5 6.2-2.5h.2c2.3.1 4.5 1.1 6.2 2.8 1.7 1.8 2.5 4 2.5 6.4-.1 3-1.6 5.8-4.2 7.3" />
  </svg>
);
export default IconIdeaShield;
