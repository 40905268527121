import * as React from "react";
const IconCheck = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 12 9"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M10.304.264a.75.75 0 0 1 1.142.972l-6.375 7.5a.75.75 0 0 1-1.07.075l-3.374-3a.75.75 0 1 1 .996-1.122L4.425 7.18z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconCheck;
