import * as React from "react";
const IconCherry = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.2 6.9c-21-4.2-40 13.2-44.9 24.2C8.5 31.1 3 36.6 3 43.4s5.5 12.3 12.3 12.3 12.3-5.5 12.3-12.3c0-6.1-4.4-11.1-10.2-12.1C22.1 21.6 38.8 6.6 57 8.5c-6.1 4.2-15.3 14.6-15.5 24.6h-.4c-6.8 0-12.3 5.5-12.3 12.3s5.5 12.3 12.3 12.3 12.3-5.5 12.3-12.3c0-6-4.3-11-9.9-12.1 0-10.6 11.8-22.3 16.9-24.4.4-.2.7-.6.6-1 0-.5-.4-.9-.8-1M25.6 43.4c0 5.7-4.6 10.3-10.3 10.3S5 49.1 5 43.4s4.6-10.3 10.3-10.3 10.3 4.6 10.3 10.3m25.8 2c0 5.7-4.6 10.3-10.3 10.3s-10.3-4.6-10.3-10.3 4.6-10.3 10.3-10.3 10.3 4.6 10.3 10.3" />
    <path d="M10.8 43.3c0 .6.4 1 1 1s1-.4 1-1c0-1.3 1.1-2.4 2.4-2.4.6 0 1-.4 1-1s-.4-1-1-1c-2.4 0-4.4 1.9-4.4 4.4M36.3 45c0 .6.4 1 1 1s1-.4 1-1c0-1.3 1.1-2.4 2.4-2.4.6 0 1-.4 1-1s-.4-1-1-1c-2.4 0-4.4 2-4.4 4.4" />
  </svg>
);
export default IconCherry;
