import * as React from "react";
const IconChair3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.3 35.6h-.6V7.8c0-3.2-2.6-5.8-5.8-5.8H16.1c-3.2 0-5.8 2.6-5.8 5.8v27.8h-.6c-.6 0-1 .4-1 1V48c0 .6.4 1 1 1h3v12c0 .6.4 1 1 1s1-.4 1-1V49.1h34.6V61c0 .6.4 1 1 1s1-.4 1-1V49.1h3c.6 0 1-.4 1-1V36.6c0-.5-.4-1-1-1M51.7 7.8v27.8h-9.5V4h5.7c2.1 0 3.8 1.7 3.8 3.8M23.8 35.6V4h16.3v31.6zM12.3 7.8C12.3 5.7 14 4 16.1 4h5.7v31.6h-9.5zm41 39.3H10.7v-9.4h42.7v9.4z" />
  </svg>
);
export default IconChair3;
