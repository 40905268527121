import * as React from "react";
const IconServer2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 42.2v-8.1c0-2.2-1.8-4-4-4H43.8c.5-1.8.8-3.7.8-5.6 0-3.7-1-7.4-2.9-10.6C38 7.7 31.4 3.8 24.2 3.7h-1.5C15.6 4.2 9.4 8 5.9 14 4 17.2 3 20.8 3 24.5c0 4.5 1.4 8.7 4 12.3 3.5 4.8 8.8 7.8 14.7 8.4-.8.7-1.3 1.8-1.3 3v8.1c0 2.2 1.8 4 4 4H57c2.2 0 4-1.8 4-4v-8.1c0-1.2-.5-2.3-1.4-3 .9-.8 1.4-1.8 1.4-3M42.6 24.5c0 1.9-.3 3.8-.9 5.6H30.4c.3-1.8.4-3.7.4-5.6 0-2.1-.2-4.3-.6-6.4q5.25-.6 10.2-2.4c1.4 2.7 2.2 5.7 2.2 8.8M20.4 37.3c-.3-.8-.6-1.7-.9-2.6.3 0 .6 0 .9-.1zm.2-4.6c-.5 0-.9 0-1.4.1h-.3c-.7-2.7-1-5.4-1-8.3 0-2.1.2-4.3.6-6.3h.3q2.4.3 5.1.3c1.5 0 3-.1 4.4-.2.4 2.1.6 4.2.6 6.2 0 1.9-.2 3.7-.5 5.6h-4c-1.8 0-3.2 1.1-3.8 2.6m2.7-26.8q3.15 4.95 4.5 10.5c-2.9.2-6 .2-8.9-.1.9-3.7 2.4-7.2 4.4-10.4m16.1 8c-3.1 1-6.3 1.8-9.5 2.2-.9-3.6-2.3-7.1-4.2-10.3 5.5.6 10.5 3.5 13.7 8.1M21 6c-1.9 3.2-3.2 6.6-4.1 10-3-.4-5.8-1.1-8.6-2.1C11.2 9.6 15.8 6.7 21 6M5 24.5c0-3.1.8-6.1 2.2-8.8 3 1.1 6.1 1.8 9.3 2.3q-.6 3.15-.6 6.6c0 2.9.4 5.8 1 8.5-2.9.4-5.7 1-8.5 1.9l.2.7C6.2 32.4 5 28.6 5 24.5m4.5 12.2c2.6-.8 5.2-1.4 7.9-1.7.7 2.4 1.7 4.8 2.9 7v.2c0 .3 0 .6.1.8-4.2-.7-8.1-3-10.9-6.3M59 48.2v8.1c0 1.1-.9 2-2 2H24.4c-1.1 0-2-.9-2-2v-8.1c0-1.1.9-2 2-2H57c1.1 0 2 .9 2 2m-34.6-4c-1.1 0-2-.9-2-2v-8.1c0-1.1.9-2 2-2H57c1.1 0 2 .9 2 2v8.1c0 1.1-.9 2-2 2z" />
    <path d="M29.7 37c-.6 0-1 .4-1 1v.1c0 .6.4 1 1 1s1-.4 1-1V38c0-.6-.4-1-1-1M53.2 37h-4.5c-.6 0-1 .4-1 1s.4 1 1 1h4.5c.6 0 1-.4 1-1s-.4-1-1-1M29.7 53.2c.6 0 1-.4 1-1v-.1c0-.6-.4-1-1-1s-1 .4-1 1v.1c0 .6.5 1 1 1M48.7 53.1h4.5c.6 0 1-.4 1-1s-.4-1-1-1h-4.5c-.6 0-1 .4-1 1s.5 1 1 1" />
  </svg>
);
export default IconServer2;
