import * as React from "react";
const IconBedAltPatient = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.6 22.5H8.4c-3.5 0-6.4 2.9-6.4 6.4v3.5c0 .6.4 1 1 1h8v15.3c-2.2.5-3.9 2.5-3.9 4.9 0 2.8 2.3 5 5 5s5-2.3 5-5c0-2.5-1.8-4.5-4.2-4.9V43h40.2v5.6c-2.3.5-4 2.5-4 4.9 0 2.8 2.3 5 5 5s5-2.3 5-5c0-2.4-1.7-4.5-4-4.9V33.4H61c.6 0 1-.4 1-1v-3.5c0-3.5-2.9-6.4-6.4-6.4M15.1 53.6c0 1.7-1.4 3-3 3-1.7 0-3-1.4-3-3s1.3-2.9 2.9-3h.2c1.6-.1 2.9 1.3 2.9 3m42.1 0c0 1.7-1.4 3-3 3s-3-1.4-3-3c0-1.7 1.4-3 3-3 1.7 0 3 1.3 3 3m-4-12.6H13v-7.6h40.2zm6.8-9.6H4v-2.5c0-2.4 2-4.4 4.4-4.4h47.1c2.4 0 4.4 2 4.4 4.4v2.5zM23.8 17.1h31.4c1.3 0 2.4-1.1 2.4-2.4v-2.3c0-3.8-3.1-6.9-6.9-6.9H23.8c-1.2 0-2.2 1-2.2 2.2V15c0 1.1 1 2.1 2.2 2.1m-.2-9.4c0-.1.1-.2.2-.2h26.9c2.7 0 4.9 2.2 4.9 4.9v2.3c0 .2-.2.4-.4.4H23.8c-.1 0-.2-.1-.2-.2zM11.9 16.8c3 0 5.5-2.5 5.5-5.5s-2.5-5.5-5.5-5.5-5.5 2.5-5.5 5.5 2.5 5.5 5.5 5.5m0-9c1.9 0 3.5 1.6 3.5 3.5s-1.6 3.5-3.5 3.5-3.5-1.6-3.5-3.5 1.6-3.5 3.5-3.5" />
  </svg>
);
export default IconBedAltPatient;
