import * as React from "react";
const IconStapler = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60 43H17.3c1-1.3 1.6-3 1.6-4.8v-1.3h32.4c.6 0 1-.4 1-1V24.2l4.8-1.8q.45-.15.6-.6c.1-.3 0-.6-.1-.9l-3.8-5.5c-3.4-5-10-6.7-15.4-4.1l-34.8 17q-.6.3-.6.9V53c0 .6.4 1 1 1h56c.6 0 1-.4 1-1v-9c0-.6-.4-1-1-1m-9.7-8.1H23.5L50.4 25v9.9zM5 29.8l34.3-16.7c4.5-2.2 10.1-.7 12.9 3.4l3 4.5-37.7 14h-.1c-.1 0-.2.1-.2.2l-.1.1c-.1.1-.1.1-.1.2v.1c0 .1-.1.2-.1.4v2.3c0 3.3-2.7 5.9-5.9 5.9s-6-2.7-6-6zm54 22.1H5v-8.5c1.5 1.6 3.6 2.7 5.9 2.7 1.5 0 2.9-.4 4.1-1.2h44z" />
    <path d="M10.8 39.9c1.6 0 2.9-1.3 2.9-2.9s-1.3-3-2.9-3-2.9 1.3-2.9 2.9 1.3 3 2.9 3m0-3.9c.5 0 .9.4.9.9s-.4.9-.9.9-.9-.4-.9-.9.4-.9.9-.9" />
  </svg>
);
export default IconStapler;
