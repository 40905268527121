import React, { useState } from 'react';
import { useNavigation } from 'context/NavigationContext';
import {
	BringzzPageContainer,
	BringzzButton,
	BringzzIcon,
	BringzzText,
	BringzzInput,
	BringzzDropdown
} from '@bringzz/components';
import useUser from 'hooks/useUser';
import useRequest from 'hooks/useRequest';
import { useLocation } from 'react-router-dom';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const roles = [
	{ value: 'PKA', label: 'PKA' },
	{ value: 'PTA', label: 'PTA' }
	// Add more roles as needed
];

const AddPharmacyEmployee = () => {
	const { backPress, navigate } = useNavigation();
	const { sendRequest, loading } = useRequest();
	const { getUser } = useUser();
	const [employees, setEmployees] = useState([
		{ firstName: '', lastName: '', role: '' }
	]);
	const [showShareLogin, setShowShareLogin] = useState(false);

	const handleAddEmployee = () => {
		setEmployees([...employees, { firstName: '', lastName: '', role: '' }]);
	};

	const handleInputChange = (index, field, value) => {
		const newEmployees = [...employees];
		newEmployees[index][field] = value;
		setEmployees(newEmployees);
	};

	const handleDropdownChange = (index, value) => {
		const newEmployees = [...employees];
		newEmployees[index].role = value;
		setEmployees(newEmployees);
	};

	const handleRemoveEmployee = index => {
		const newEmployees = employees.filter((_, i) => i !== index);
		setEmployees(newEmployees);
	};

	return (
		<BringzzPageContainer
			header={{
				left: (
					<BringzzIcon
						size='22'
						icon={showShareLogin ? 'IconClose' : 'IconChevronLeft'}
						folder='LineIcons'
						className='cursor-pointer'
						onClick={
							showShareLogin ? () => setShowShareLogin(false) : backPress
						}
					/>
				),
				title: (
					<BringzzText tag='h2'>
						{showShareLogin ? 'Share login QR-Codes' : 'Add employee(s)'}
					</BringzzText>
				)
			}}
			footer={
				<div className='pb-28 px-6'>
					<BringzzButton
						size='lg'
						className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
						onClick={
							showShareLogin
								? async () => {
										await sendRequest({
											method: 'PATCH',
											url: `/pharmacy/team/add`,
											data: {
												employees
											}
										})
											.then(response => {
												if (response.status === 'success')
													navigate(`/settings/pharmacy/team`, false);
											})
											.catch(error => {
												console.log(error);
											});
									}
								: () => setShowShareLogin(true)
						}
						disabled={loading}
					>
						{showShareLogin ? 'Done' : 'Continue'}
					</BringzzButton>
				</div>
			}
		>
			<div className='p-6 space-y-4'>
				{showShareLogin ? (
					<ShareEmployeeList employees={employees} />
				) : (
					<>
						{employees.map((employee, index) => (
							<div key={index} className='mb-4 relative pr-6'>
								<BringzzText tag='h4' className='text-lg font-semibold mb-2'>
									Employee {index + 1}
								</BringzzText>
								<div className='flex justify-between space-x-2 mb-2'>
									<BringzzInput
										className='w-full flex-2'
										placeholder='First Name'
										value={employee.firstName}
										onChange={e =>
											handleInputChange(index, 'firstName', e.target.value)
										}
									/>
									<BringzzInput
										className='w-full flex-2'
										placeholder='Last Name'
										value={employee.lastName}
										onChange={e =>
											handleInputChange(index, 'lastName', e.target.value)
										}
									/>
									<BringzzButton
										type='button'
										className='absolute right-[-24px]'
										onClick={() => handleRemoveEmployee(index)}
									>
										<BringzzIcon
											icon='IconCircleMinus'
											folder='LineIcons'
											size='20'
										/>
									</BringzzButton>
								</div>
								<BringzzText
									tag='label'
									className='block text-sm font-medium text-gray-700 mb-1'
								>
									Role/Title
								</BringzzText>
								<BringzzDropdown
									options={roles}
									defaultValue={employee.role}
									onChange={value => handleDropdownChange(index, value)}
									className='w-full mb-2'
								/>
								{index < employees.length - 1 && <hr className='mt-4' />}
							</div>
						))}
						<div
							className='flex items-center w-full space-x-1'
							onClick={handleAddEmployee}
						>
							<BringzzIcon icon='IconCirclePlus' folder='LineIcons' size='20' />
							<BringzzText className='text-sm'>Add employee</BringzzText>
						</div>
					</>
				)}
			</div>
		</BringzzPageContainer>
	);
};

const ShareEmployeeList = ({ employees }) => {
	return (
		<div className='space-y-4'>
			{employees.map((employee, index) => (
				<div key={index} className='h-24 rounded-lg'>
					<div
						key={index}
						className='flex items-center justify-between py-4 px-2 cursor-pointer'
					>
						<div className='flex flex-col'>
							<BringzzText tag='h3'>Employee {index + 1}</BringzzText>
							<BringzzText tag='h3'>
								{employee.firstName + ' ' + employee.lastName}
							</BringzzText>
							<div className='w-64'>
								<BringzzText
									tag='h3'
									className='text-normal text-black/50 whitespace-pre-line truncate'
								>
									{employee.role}
								</BringzzText>
							</div>
						</div>
						<div className='flex items-center justify-center space-x-2'>
							<div className='flex items-center justify-center border border-magic-lilac w-full rounded'>
								<BringzzIcon
									icon='IconEye'
									folder='LineIcons'
									size='20'
									className='p-2'
								/>
							</div>
							<div className='flex items-center justify-center border border-magic-lilac w-full rounded'>
								<BringzzIcon
									icon='IconUpload'
									folder='LineIcons'
									size='20'
									className='p-2'
								/>
							</div>
						</div>
					</div>
					{index < employees.length - 1 && <hr className='mt-4' />}
				</div>
			))}
			<BringzzButton className='bg-magic-lilac w-full flex justify-center text-midnight-blue'>
				<div className='flex items-center w-full space-x-1'>
					<BringzzIcon
						icon='IconUpload'
						folder='LineIcons'
						size='20'
						className='mr-2'
					/>
					<BringzzText className='text-sm'>Share all</BringzzText>
				</div>
			</BringzzButton>
		</div>
	);
};

export default AddPharmacyEmployee;
