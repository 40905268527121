import * as React from "react";
const IconBoiler = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56 8.5a47.4 47.4 0 0 0-48 0c-.3.2-.5.5-.5.9v47.5c0 2.8 2.3 5.1 5.1 5.1h38.8c2.8 0 5.1-2.3 5.1-5.1V9.4c0-.4-.2-.7-.5-.9M39.8 50.4H24.2v-7.6h15.5v7.6zm-15.6 2h15.5V60H24.2zm30.3 4.5c0 1.7-1.4 3.1-3.1 3.1h-9.6V35.2c0-.6-.4-1-1-1s-1 .4-1 1v5.5H24.2v-5.5c0-.6-.4-1-1-1s-1 .4-1 1V60h-9.6c-1.7 0-3.1-1.4-3.1-3.1V10c13.9-8 31.1-8 45 0z" />
  </svg>
);
export default IconBoiler;
