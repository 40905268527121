import React, { useState } from 'react';
import { useNavigation } from 'context/NavigationContext';
import {
    BringzzPageContainer, BringzzButton, BringzzIcon, BringzzText, BringzzPinCode
} from "@bringzz/components";
import useUser from 'hooks/useUser';
import useRequest from 'hooks/useRequest';
import { useLocation } from 'react-router-dom';
import { useAuth } from 'context/AuthContext';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

let state = {
    checked: (
        <BringzzIcon
            icon="IconCheck"
            library="Custom"
            className="h-5 w-5 p-1 bg-magic-lilac text-light rounded-full"
            size="12"
        />
    ),
    unChecked: (
        <div></div>
    ),
};

const VerifyMailView = () => {
    const { backPress, navigate } = useNavigation();
    const [isValidInput, setIsValidInput] = useState(false)
    const [isValid, setIsValid] = useState(false)
    const { refreshUserData } = useAuth()

    // TODO: add params to navigation context + hook to set and fetch
    let query = useQuery();
    let emailValue = query.get('value');

    const { sendRequest, data, error, loading } = useRequest();

    const { getUser } = useUser();
    const user = getUser();

    const validatePin = async pin => {
        const response = await sendRequest({
            method: 'POST',
            url: `/module/profile/verifyEmail`,
            data: { pin }
        })

        return response.status === 'success'
    };

    return (
        <BringzzPageContainer
            header={{
                left: <BringzzIcon
                    size="22"
                    icon="IconChevronLeft"
                    folder="LineIcons"
                    className="cursor-pointer"
                    onClick={backPress}
                />,
                title: <BringzzText tag='h2'>Verify Email</BringzzText>,
            }}
            footer={
                <div className='pb-28 px-6'>
                    <BringzzButton
                        size='lg'
                        className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
                        onClick={async () => {
                            refreshUserData()
                            navigate("/settings/account")
                        }}
                        disabled={!isValidInput || loading}
                    >
                        Save
                    </BringzzButton>
                </div>
            }
        >
            <div className='p-6 space-y-4'>
                <div>
                    <BringzzText
                        tag='h4'
                        className='text-midnight-blue/50 font-normal text-center'
                    >
                        {"Enter the security code we've sent to"}
                    </BringzzText>
                    <BringzzText
                        tag='h4'
                        className='text-center'
                    >
                        {emailValue}
                    </BringzzText>
                </div>

                <BringzzPinCode
                    length={4}
                    onChange={pin => console.log('pin change: ', pin)}
                    onValidate={async pin => {
                        return validatePin(pin)
                    }}
                    onSuccess={() => {
                        setIsValidInput(true)
                    }}
                    onError={() => {
                        console.log('Error');
                    }}
                    lowerChildContent={
                        <div className='flex flex-col justify-center align-center mt-6 space-x-2'>
                            <BringzzText
                                tag='h4'
                                className='text-midnight-blue/50 font-normal text-center'
                            >
                                {"Code expires in [00:56]"}
                            </BringzzText>
                            <BringzzText
                                tag='h4'
                                className='text-midnight-blue/50 font-normal text-center'
                            >
                                {"Didn't receive code?"} <span className='magic-lilac underline cursor-pointer' onClick={backPress}>Resend code</span>
                            </BringzzText>
                        </div>
                    }
                    spacing='space-x-2'
                    disabled={loading}
                    className={loading ? 'opacity-25' : ''}
                ></BringzzPinCode>
            </div>
        </BringzzPageContainer>
    );
}

export default VerifyMailView;
