import React from 'react';
import {
    BringzzTopBar,
    BringzzMap,
    BringzzImage,
    BringzzIcon,
    BringzzButton,
    BringzzText,
} from '@bringzz/components';
import { useOrder } from 'context/OrderContext'; // Use useOrder instead of useOverlay
import { useNavigation } from 'context/NavigationContext';
import useRequest from 'hooks/useRequest';

const OrderRequest = ({
    id,
    uuid,
    type,
    total,
    consumer,
    items
}) => {
    const { hideOverlayContent, startMatchingProcess } = useOrder(); // Use hideOverlayContent from useOrder
    const { navigate } = useNavigation()
    const { sendRequest, data, error, loading } = useRequest();

    const acceptOrder = async () => {
        const orderDetails = await sendRequest({
            method: 'POST',
            url: `/module/order/getOrder`,
            data: {
                "orderUUID": uuid
            }
        }).then((response) => {
            if (response.status === 'success') {
                return response.data
                // setOrderDetails(response.data)
            }
        }).catch((error) => {
            console.error(error);
            return;
        })

        if (orderDetails) {
            await sendRequest({
                method: 'POST',
                url: `/module/order/acceptOrder`,
                data: {
                    "orderId": id
                }
            }).then((response) => {
                if (response.status === 'success') {
                    // setOrderDetails(response.data)
                    startMatchingProcess(orderDetails, "package")
                    console.log(response);
                }
            }).catch((error) => {
                console.error(error);
            })
        }
    }

    return (
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center w-full p-4 md:p-8 lg:p-12">
            <div className="bg-white px-4 py-12 rounded-lg w-full text-midnight-blue">
                <div className="text-center">
                    <div className="font-medium mb-4">
                        <BringzzText>Order request</BringzzText>
                    </div>
                    <div className="text-3xl font-bold">
                        <BringzzText className="font-bold text-3xl">€ {total || 0}</BringzzText>
                    </div>
                    <div>
                        <BringzzText tag="h5" className="font-regular">
                            {items.length} Items
                        </BringzzText>
                    </div>
                    <div>
                        <BringzzText tag="h5" className="font-regular">
                            Estimated payment incl. tip
                        </BringzzText>
                    </div>
                </div>
                <div>
                    <div className="flex items-center justify-between p-4 border-b">
                        <span>
                            <BringzzText tag="h4" className="font-regular">
                                {consumer || "consumer_name"}
                            </BringzzText>
                        </span>
                        <BringzzImage
                            src="/images/1.jpg"
                            width="36px"
                            height="36px"
                            className="rounded-full !h-[36px]"
                        ></BringzzImage>
                    </div>
                    <div className="flex items-center justify-between p-3 border-b">
                        <div className="items-center flex gap-x-6">
                            <BringzzIcon
                                icon="IconMapMarker"
                                folder="LineIcons"
                                className="text-midnight-blue"
                                size="24"
                            ></BringzzIcon>
                            <span>
                                <BringzzText tag="h4" className="font-regular">
                                    Order Type
                                </BringzzText>
                            </span>
                        </div>

                        <span className="font-bold">
                            <BringzzText>{"Bringzz Pickup"}</BringzzText>
                        </span>
                    </div>
                    <div className="flex items-center justify-between p-3 border-b">
                        <div className="items-center flex gap-x-6">
                            <BringzzIcon
                                icon="IconMapMarker"
                                folder="LineIcons"
                                className="text-midnight-blue"
                                size="24"
                            ></BringzzIcon>
                            <span>
                                <BringzzText tag="h4" className="font-regular">
                                    Order Total
                                </BringzzText>
                            </span>
                        </div>
                        <span className="font-bold">€ {total || 0}</span>
                    </div>

                    <div className="flex p-4 mb-6 justify-between items-center cursor-pointer" onClick={() => {
                        hideOverlayContent()
                        navigate(`/settings/pharmacy/orders/view/${uuid}`)
                    }}>
                        <BringzzText tag="h4">View all details</BringzzText>
                        <BringzzIcon
                            icon="IconChevronDown"
                            folder="LineIcons"
                            size="12"
                            className="text-black"
                        />
                    </div>
                    <div className="flex items-center gap-x-4 justify-center">
                        <BringzzButton
                            className="bg-white border-midnight-blue border-2"
                            onClick={hideOverlayContent}
                            disabled={loading}
                        >
                            Decline
                        </BringzzButton>
                        <BringzzButton className="bg-magic-lilac" onClick={acceptOrder} disabled={loading}>Accept</BringzzButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderRequest;
