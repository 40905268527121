import * as React from "react";
const IconInfoCircle = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 66 66"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M33.1 29.2c-.6 0-1 .4-1 1v15.4c0 .6.4 1 1 1s1-.4 1-1V30.2c0-.6-.4-1-1-1" />
    <path d="M33 4C17 4 4 17 4 33s13 29 29 29 29-13 29-29S49 4 33 4m0 56C18.1 60 6 47.9 6 33S18.1 6 33 6s27 12.1 27 27-12.1 27-27 27" />
    <path d="M33.1 19.5c-1.7 0-3.1 1.4-3.1 3.1s1.4 3.1 3.1 3.1 3.1-1.4 3.1-3.1-1.3-3.1-3.1-3.1m0 4.3c-.6 0-1.1-.5-1.1-1.1s.5-1.1 1.1-1.1 1.1.5 1.1 1.1-.5 1.1-1.1 1.1" />
  </svg>
);
export default IconInfoCircle;
