import React, { useState } from 'react';
import { BringzzButton, BringzzText } from "@bringzz/components";
import { useNavigation } from 'context/NavigationContext';
import { BringzzPageContainer, BringzzPinCode, BringzzIcon } from "@bringzz/components";
import { useLocation } from 'react-router-dom';
import useRequest from 'hooks/useRequest';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}
const methodTitle = {
	phone: 'Phone number',
	email: 'Email address'
}

const RegisterOTPpage = () => {
	const { backPress } = useNavigation();
	const { sendRequest, data, error, loading } = useRequest();
	const { navigate } = useNavigation();

	const [isValid, setIsValid] = useState(false)

	let query = useQuery();
	let redirect = query.get('redirect');
	let method = query.get('method');
	let methodValue = query.get('value');
	const userId = query.get('userId');

	const validatePin = async (pin) => {
		return await sendRequest({
			method: 'POST',
			url: `/module/user/validateRegistrationToken`,
			data: {
				userId,
				token: pin,
			}
		})
			.then(response => {
				if (response.status === 'success') {
					return true
				};
			})
			.catch(error => {
				console.error(error);
				return false;
			});
	};

	const resendCode = async () => {
		return await sendRequest({
			method: 'POST',
			url: `/module/token/recreateValidation`,
			data: {
				userId,
				// loginType: 0,
				language: "en",
				// login: methodValue
			}
		})
			.then(response => {
				if (response.status === 'success') {
					return true
				};
			})
			.catch(error => {
				console.error(error);
				return false;
			});
	}

	const title = <div className='flex flex-col'><div>Verify your {methodTitle[method].split(' ')[0]}</div><div>{methodTitle[method].split(' ')[1]}</div></div>

	return (
		<BringzzPageContainer
			className='bg-white'

			header={{
				left: (
					<BringzzIcon
						size='22'
						icon='IconClose'
						folder='LineIcons'
						className='cursor-pointer'
						onClick={backPress}
					/>
				),
				title: "Confirmation"
			}}
			authenticated={false}
			footer={
				<div className='pb-8 px-6'>
					<BringzzButton
						size='lg'
						className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
						onClick={() => {
							navigate(`${redirect}?method=${method}&userId=${userId}&value=${methodValue}`);
						}}
						disabled={!isValid}
					>
						Confirm
					</BringzzButton>
				</div>
			}
		>
			<div className='relative flex justify-center bg-magic-lilac/20'>
				<div className='relative bg-white w-full bg-cover bg-center flex flex-col md:max-w-2xl '>
					{/* Content */}
					<div className='flex-grow flex flex-col justify-between py-12 px-4'>
						{/* Bottom part */}
						<div className='z-10 flex flex-col '>
							<div className='flex flex-col space-y-6 mx-2 md:max-w-lg lg:max-w-xl xl:max-w-2xl'>
								<BringzzText tag='h1' className='pl-2 text-midnight-blue font-bold'>
									{title}
								</BringzzText>
								<BringzzText tag="h3" className="font-normal">As part of the registration process we have sent you {method == "email" ? "an email" : "a text message"}.</BringzzText>
							</div>
							<div className='flex justify-center items-center mt-6'>
								<BringzzPinCode
									length={4}
									onChange={pin => console.log('pin change: ', pin)}
									onValidate={async pin => {
										return validatePin(pin);
									}}
									onSuccess={() => {
										setIsValid(true)
									}}
									onError={() => {
										alert("Wrong pin")
										console.log('Error');
									}}
									upperChildContent={
										<div className='text-center py-4'>
											<BringzzText tag="h3" className="font-normal" >
												Enter the one-time code we sent to <br />
											</BringzzText>
											<BringzzText ag="h3" className="font-bold">
												{
													method == "email" ? (
														methodValue
													) : (
														<>
															+***-***-
															{methodValue.substring(
																methodValue.length - 3,
																methodValue.length
															)}
														</>
													)
												}
											</BringzzText>
										</div>
									}
									lowerChildContent={
										<div>
											{!isValid && <div className='flex justify-center align-center mt-6 space-x-2'>
												<BringzzText tag="h3" className="font-normal">{"Didn't receive code? "}</BringzzText>
												<div>&bull;</div>
												<div onClick={resendCode}>
													<BringzzText tag="h3" className="cursor-pointer underline font-bold text-midnight-blue">{"Resend Code"}</BringzzText>
												</div>
											</div>}
										</div>
									}
									spacing='space-x-2'
									disabled={loading || isValid}
									className={loading ? 'opacity-25' : ''}
								></BringzzPinCode>
							</div>
						</div>
					</div>
				</div>
			</div>
		</BringzzPageContainer>
	);
};

export default RegisterOTPpage;
