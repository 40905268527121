import * as React from "react";
const IconAppleAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55 21.7c-2.9-3.6-7.4-5.4-12.6-5-3 .3-5.8 1-8.3 2.1l-.6.3c-.8-9-5-13.5-8-16.8-.4-.4-1-.4-1.4 0s-.4 1 0 1.4c2.9 3.1 6.7 7.2 7.5 15.4-2.9-1.1-6.9-2.1-9.8-2.4-5.3-.4-9.7 1.4-12.6 5-4.1 5-4.8 12.7-2 21.3 4.4 13.6 12.3 20.6 21.1 18.7 1.2-.3 2.4-.7 3.7-1.4 1.2.6 2.5 1.1 3.7 1.4q1.35.3 2.7.3c7.7 0 14.4-6.9 18.4-19 2.8-8.6 2.1-16.4-1.8-21.3m0 20.7c-4 12.5-11.1 19-18.8 17.4-1.2-.3-2.4-.7-3.7-1.4-.2-.1-.3-.1-.5-.1s-.3 0-.5.1c-1.4.7-2.5 1.2-3.7 1.4-7.7 1.6-14.7-4.9-18.8-17.4-2.5-7.9-1.9-15 1.5-19.4 2.5-3.2 6.3-4.6 10.9-4.3 3.2.2 8.1 1.5 10.6 2.8q.45.15.9 0l1.9-.9c2.4-1 4.9-1.7 7.7-1.9 4.6-.4 8.4 1.1 10.9 4.2 3.5 4.4 4.1 11.5 1.6 19.5" />
    <path d="M37.9 10.4c1.5.8 3.6 1.6 5.9 1.6.6 0 1.2-.1 1.8-.2 2.2-.4 4.4-1.8 6.4-4.2.6-.6.8-1.4.6-2.2-.1-.8-.6-1.4-1.4-1.8-1.9-1-4.7-2-7.7-1.4-3.3.6-5.7 3.2-6.5 4.2-.5.6-.7 1.4-.6 2.2q.3 1.2 1.5 1.8m.7-2.7c.7-.9 2.7-3 5.4-3.5 2.4-.5 4.8.4 6.4 1.2.2.1.3.3.4.4 0 .2 0 .3-.2.5q-2.7 3-5.4 3.6c-2.4.5-4.7-.4-6.3-1.2-.2-.1-.3-.3-.4-.4-.1-.3 0-.4.1-.6" />
  </svg>
);
export default IconAppleAlt;
