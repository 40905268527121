import * as React from "react";
const IconCarrot = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m60 12.7-7.2-.3 3.5-3.5c.4-.4.4-1 0-1.4s-1-.4-1.4 0L51 11.4l-.3-7.2c0-.6-.5-1-1-1-.6 0-1 .5-1 1l.3 7.6c-3.1-2.3-7.2-3.3-11.7-2.7-5.2.7-10.3 3.3-14.4 7.4-6.6 6.7-16 30.5-19.7 40.4-.4 1.1-.1 2.3.7 3.1q.9.9 2.1.9c.3 0 .7-.1 1-.2C19.2 56 40.8 47.2 47.2 40.9c4.1-4.1 6.8-9.2 7.4-14.4.6-4.7-.5-9-3.1-12.2l8.4.4c.5 0 1-.4 1-1 .1-.5-.3-1-.9-1m-7.4 13.5c-.6 4.7-3 9.4-6.8 13.2l-5.7-5.7c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l5.6 5.6c-7.9 6.2-28.9 14.5-38 17.9-.3.1-.7 0-.9-.2-.3-.3-.3-.6-.2-.9 1.7-4.4 5.7-14.8 10-24l4.4 4.4c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-4.7-4.7-.2-.2c2.9-5.9 5.8-11.2 8.3-13.6q2.1-2.1 4.5-3.6l4.7 4.7c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-4.4-4.4c2.2-1.1 4.6-1.9 6.9-2.2 4.6-.6 8.8.6 11.6 3.5 2.9 2.9 4.1 7 3.5 11.6" />
  </svg>
);
export default IconCarrot;
