import * as React from "react";
const IconMinistryOfHealth = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path  d="M25 16v-2h2v-2h-2v-2h-2v2h-2v2h2v2z" />
    <path
      
      fillRule="evenodd"
      d="M24.625 5.22a1 1 0 0 0-1.25 0L8.65 17H7a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h1v10H7a1 1 0 0 0-1 1v3H5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h38a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-1v-3a1 1 0 0 0-1-1h-1V23h1a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-1.65zM36.149 17 24 7.28 11.85 17zM8 19v2h32v-2zm30 4h-2v10h2zm-4 10V23H14v10h1a1 1 0 0 1 1 1v3h4v-8a4 4 0 0 1 8 0v8h4v-3a1 1 0 0 1 1-1zm-12-4v8h4v-8a2 2 0 1 0-4 0m20 10v2H6v-2zm-8-4v2h6v-2zm-24-2V23h2v10zm-2 2h6v2H8z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconMinistryOfHealth;
