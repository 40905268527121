import * as React from "react";
const IconCouponScissors = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 54c-.6 0-1 .4-1 1v5h-5c-.6 0-1 .4-1 1s.4 1 1 1h6c.6 0 1-.4 1-1v-6c0-.6-.4-1-1-1M39.7 60H24.3c-.6 0-1 .4-1 1s.4 1 1 1h15.3c.6 0 1-.4 1-1s-.4-1-.9-1M9 60H4v-5c0-.6-.4-1-1-1s-1 .4-1 1v6c0 .6.4 1 1 1h6c.6 0 1-.4 1-1s-.4-1-1-1M3 40.7c.6 0 1-.4 1-1V24.3c0-.6-.4-1-1-1s-1 .4-1 1v15.3c0 .6.4 1.1 1 1.1M9 2H3c-.6 0-1 .4-1 1v6c0 .6.4 1 1 1s1-.4 1-1V4h5c.6 0 1-.4 1-1s-.4-1-1-1M24.3 4h15.3c.6 0 1-.4 1-1s-.4-1-1-1H24.3c-.6 0-1 .4-1 1s.5 1 1 1M61 2h-6c-.6 0-1 .4-1 1s.4 1 1 1h5v5c0 .6.4 1 1 1s1-.4 1-1V3c0-.6-.4-1-1-1M61 23.3c-.6 0-1 .4-1 1v15.3c0 .6.4 1 1 1s1-.4 1-1V24.3c0-.5-.4-1-1-1M38.4 33.4l-5-5 10-10c.4-.4.4-1 0-1.4s-1-.4-1.4 0L32 27 22 17c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l10 10-5 5c-3-2.4-7.5-2.1-10.2.7-1.5 1.5-2.3 3.4-2.3 5.5s.8 4 2.3 5.5 3.4 2.3 5.5 2.3 4-.8 5.5-2.3 2.3-3.4 2.3-5.5c0-1.7-.6-3.4-1.6-4.7l5-5 5 5c-1 1.4-1.6 3-1.6 4.7 0 2.1.8 4 2.3 5.5s3.4 2.3 5.5 2.3 4-.8 5.5-2.3c3-3 3-8 0-11-2.9-2.9-7.3-3.1-10.4-.7M24.9 43.6c-1.1 1.1-2.5 1.7-4.1 1.7-1.5 0-3-.6-4.1-1.7S15 41.1 15 39.5s.6-3 1.7-4.1 2.6-1.7 4.1-1.7 3 .6 4.1 1.7 1.7 2.5 1.7 4.1-.6 3-1.7 4.1m22.4 0c-1.1 1.1-2.5 1.7-4.1 1.7s-3-.6-4.1-1.7-1.7-2.5-1.7-4.1.6-3 1.7-4.1 2.6-1.7 4.1-1.7 3 .6 4.1 1.7c2.2 2.3 2.2 6 0 8.2" />
  </svg>
);
export default IconCouponScissors;
