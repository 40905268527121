import * as React from "react";
const IconFuel = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 65"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M37.4 13.8H17.8c-.6 0-1 .4-1 1v13.6c0 .6.4 1 1 1h19.6c.6 0 1-.4 1-1V14.8c0-.6-.5-1-1-1m-1 13.6H18.8V15.8h17.6z" />
    <path d="m61.9 20.7-7.6-7.6c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l3.5 3.5v7.5c0 .6.4 1 1 1h2.8v17.6h-7.4V30.8c0-.6-.4-1-1-1h-6.7V12c0-2.8-2.2-5-5-5h-25c-2.8 0-5 2.2-5 5v44.5H5.6c-.6 0-1 .4-1 1s.4 1 1 1h43.7c.6 0 1-.4 1-1s-.4-1-1-1H45V31.8h5.7v13.4c0 .6.4 1 1 1h9.4c.6 0 1-.4 1-1V21.4c.1-.3 0-.5-.2-.7M12.1 56.5V12c0-1.7 1.3-3 3-3h25c1.7 0 3 1.3 3 3v44.5zm48.1-32h-1.8V20l1.8 1.8z" />
  </svg>
);
export default IconFuel;
