import * as React from "react";
const IconYacht = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.7 60.1 57 58.4c1.3-1.9 2-4.2 2-6.5v-2.1c0-1.3-1.1-2.3-2.3-2.3H30.8v-7.2h18.5c.4 0 .7-.2.9-.5s.2-.7 0-1L30.7 2.5c-.2-.4-.7-.6-1.1-.5s-.8.5-.8 1v7.8l-15 27.9c-.2.3-.2.7 0 1s.5.5.9.5h14.2v7.2H7.4c-1.3 0-2.3 1.1-2.3 2.3v2.1c0 2.3.7 4.6 2 6.5L3.4 60c-.5.2-.7.8-.5 1.3s.8.7 1.3.5L9 59.6h.1l.6-.3c.8-.4 1.7-.4 2.5 0l3.7 1.7c1.3.6 2.9.6 4.2 0l3.7-1.7c.8-.4 1.7-.4 2.5 0L30 61c1.3.6 2.9.6 4.2 0l3.7-1.7c.8-.4 1.7-.4 2.5 0l3.7 1.7c1.3.6 2.9.6 4.2 0l3.7-1.7c.8-.4 1.7-.4 2.5 0l5.4 2.5c.1.2.3.2.4.2q.6 0 .9-.6c.2-.5 0-1.1-.5-1.3M30.8 7l16.8 31.2H30.8V7m-2 8v23.1H16.3zm22.3 42.6-3.7 1.7c-.8.4-1.7.4-2.5 0l-3.7-1.7c-1.3-.6-2.9-.6-4.2 0l-3.7 1.7c-.8.4-1.7.4-2.5 0L27 57.6c-1.3-.6-2.9-.6-4.2 0l-3.7 1.7c-.8.4-1.7.4-2.5 0l-3.7-1.7c-1.3-.6-2.7-.6-4-.1C7.7 55.9 7 53.9 7 51.8v-2.1c0-.2.2-.3.3-.3h49.3c.2 0 .3.2.3.3v2.1c0 2.1-.7 4.1-1.9 5.7-1.2-.5-2.6-.4-3.9.1" />
  </svg>
);
export default IconYacht;
