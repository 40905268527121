import * as React from "react";
const IconGuava = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.5 19.4c-1.6-1.6-4.2-3.4-8.3-3.4-2.1 0-4.2.5-6.4 1.4.7-1.5 1.1-3.1 1.1-4.7.1-3.1-1.1-5.9-3.2-8-.4-.4-1-.4-1.4 0s-.4 1 0 1.4c3.4 3.4 3.4 8.9.3 12.7-6.5-5.2-23-5.6-32.5 3.8q-6.15 6.15-6.9 13.2c-.5 4.3.7 8.7 3.3 12.8.1.1.1.2.1.3.2 1.1-.1 2.3-.8 3.2-.2.2-.2.5-.2.8.1.3.3.5.5.7.1.1.3.2.3.2.2.1.3.2.3.2.1.2.1.5 0 .7-.1.4 0 .8.2 1.1.3.3.7.4 1.1.2.2-.1.5-.1.7 0s.3.3.4.5c.1.3.4.5.7.6s.6 0 .8-.2c.9-.7 2.1-1 3.2-.8.1 0 .2.1.3.1 3.5 2.2 7.2 3.4 10.9 3.4.6 0 1.2 0 1.8-.1 4.7-.5 9.2-2.8 13.2-6.9 4.6-4.6 7.4-11.1 7.8-18.1.2-4.4-.6-8.6-2.2-11.8 2.2.9 4.4 1.4 6.4 1.4 4 0 6.7-1.8 8.3-3.4.6-.3.6-.9.2-1.3M46 34.5c-.3 6.5-2.8 12.5-7.1 16.8-3.7 3.7-7.8 5.8-12 6.3-3.9.4-7.9-.6-11.5-3-.3-.2-.7-.4-1.1-.4-1.3-.2-2.7 0-3.9.6-.2-.2-.4-.3-.6-.5-.3-.2-.6-.3-1-.3 0-.3-.2-.7-.3-1-.1-.2-.3-.4-.5-.6q.9-1.8.6-3.9c-.1-.4-.2-.8-.4-1.1-2.3-3.6-3.3-7.5-2.9-11.4q.75-6.45 6.3-12c9.1-9.1 25.2-8.4 30.4-3.1 2.8 2.7 4.3 7.9 4 13.6m6.3-12.3c-2.3 0-4.9-.7-7.5-2.1 2.6-1.4 5.2-2.1 7.5-2.1 2.8 0 4.8 1 6.1 2.1-1.4 1.1-3.4 2.1-6.1 2.1" />
  </svg>
);
export default IconGuava;
