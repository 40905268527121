import * as React from "react";
const IconMedicalSign = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M38.4 62H25.6c-2.2 0-3.9-1.8-3.9-3.9V42.3H5.9c-2.2 0-3.9-1.8-3.9-3.9V25.6c0-2.2 1.8-3.9 3.9-3.9h15.7V5.9c0-2.2 1.8-3.9 3.9-3.9h12.8c2.2 0 3.9 1.8 3.9 3.9v15.7h15.7c2.2 0 3.9 1.8 3.9 3.9v12.8c0 2.2-1.8 3.9-3.9 3.9H42.3v15.7c0 2.3-1.7 4.1-3.9 4.1M5.9 23.7c-1.1 0-1.9.9-1.9 1.9v12.8c0 1.1.9 1.9 1.9 1.9h16.7c.6 0 1 .4 1 1V58c0 1.1.9 1.9 1.9 1.9h12.8c1.1 0 1.9-.9 1.9-1.9V41.3c0-.6.4-1 1-1h16.7c1.1 0 1.9-.9 1.9-1.9V25.6c0-1.1-.9-1.9-1.9-1.9H41.3c-.6 0-1-.4-1-1V5.9c0-1.1-.9-1.9-1.9-1.9H25.6c-1.1 0-1.9.9-1.9 1.9v16.7c0 .6-.4 1-1 1H5.9z" />
  </svg>
);
export default IconMedicalSign;
