import * as React from "react";
const IconHandChart = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.7 45.4c-1.2-3.7-4.9-5.8-8.4-4.8-4.7 1.4-11.2 3.3-14.9 4.4 1.4-2.1 1.3-3.6.9-4.7-.8-1.9-3-2.8-4.8-2.8H22.9c-1.2 0-2.4-.5-3.5-1.6-.9-.8-2.1-1.3-3.4-1.3H7.5c-3 0-5.5 2.6-5.5 5.8v13.5c0 3.3 2.5 5.9 5.6 5.9h10.2c1.3 0 2.5-.5 3.6-1.3l.8.4c2.8 1.5 6 2.4 9.2 2.4 2 0 3.9-.3 5.6-.9l19-5.8c4.8-1.9 5.6-6 4.7-9.2M7.6 57.7c-2 0-3.6-1.8-3.6-3.9V40.3c0-2.1 1.6-3.8 3.5-3.8H16c.7 0 1.4.3 2 .8.4.4.9.8 1.4 1v19c-.5.2-1 .4-1.5.4zm47.8-5.1-19 5.8c-1.6.5-3.3.8-5 .8-2.9 0-5.7-.7-8.2-2.1l-1.4-.7-.3-.1V39.2c.5.1 1 .2 1.5.2h10.5c1.2 0 2.6.5 3 1.5.5 1.2-.5 3-2.6 5l-.6.5.4.7c.4.8.4.8 10.2-2.1 4.5-1.3 9-2.7 9-2.7 2.5-.7 5.1.8 5.9 3.5.4 1.6.8 5.2-3.4 6.8M61 23.6h-3.9V3.8c0-.6-.4-1-1-1s-1 .4-1 1v19.8h-8.4V6.9c0-.6-.4-1-1-1s-1 .4-1 1v16.6h-8.4V12.1c0-.6-.4-1-1-1s-1 .4-1 1v11.5h-2.9c-.6 0-1 .4-1 1s.4 1 1 1H61c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconHandChart;
