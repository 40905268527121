import * as React from "react";
const IconBag1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.6 33.8c-.5-.5-1.2-.9-1.9-.9h-9.1V15.2C49.6 7.9 43.7 2 36.4 2h-8.7c-7.3 0-13.2 5.9-13.2 13.2V33H5.3c-.7 0-1.4.3-1.9.9-.5.5-.7 1.2-.6 2 1.1 8.6 5.1 19.8 11.7 25.6.5.4 1.1.6 1.7.6h31.7c.6 0 1.2-.2 1.7-.6 6.6-5.8 10.6-17 11.7-25.6 0-.8-.2-1.5-.7-2.1M16.4 15.2C16.4 9 21.4 4 27.6 4h8.7c6.2 0 11.2 5 11.2 11.2V33H16.4zm31.8 44.7c-.1.1-.2.1-.4.1H16.2c-.1 0-.3 0-.4-.1-5.4-4.7-9.8-14.5-11-24.3 0-.2.1-.3.1-.4.1-.2.3-.2.4-.2h9.1v6.4c0 .6.4 1 1 1s1-.4 1-1V35h31.2v6.4c0 .6.4 1 1 1s1-.4 1-1V35h9.1c.2 0 .3.1.4.2s.1.2.1.4c-1.2 9.8-5.6 19.6-11 24.3" />
  </svg>
);
export default IconBag1;
