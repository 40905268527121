import * as React from "react";
const IconServer7 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.3 17.5V7c0-2.8-2.2-5-5-5H10.7c-2.8 0-5 2.2-5 5v10.5c0 1.6.8 3.1 2 4-1.2.9-2 2.4-2 4V36c0 2.8 2.2 5 5 5H31v7c-3.1.4-5.5 2.9-6 6H8.4c-.6 0-1 .4-1 1s.4 1 1 1H25c.5 3.4 3.4 6 7 6 3.5 0 6.5-2.6 7-6h16.6c.6 0 1-.4 1-1s-.4-1-1-1H39c-.4-3.1-2.9-5.5-6-6v-7h20.3c2.8 0 5-2.2 5-5V25.5c0-1.6-.8-3.1-2-4 1.2-.9 2-2.4 2-4M37.1 54.9s-.1 0 0 0c-.1.1 0 .1 0 0 0 2.8-2.3 5.1-5.1 5.1s-5-2.2-5.1-5c0-2.8 2.3-5 5.1-5 2.8-.1 5 2.1 5.1 4.9m19.2-29.4V36c0 1.7-1.3 3-3 3H10.7c-1.7 0-3-1.3-3-3V25.5c0-1.7 1.3-3 3-3h42.6c1.7 0 3 1.3 3 3m0-8c0 1.7-1.3 3-3 3H10.7c-1.7 0-3-1.3-3-3V7c0-1.7 1.3-3 3-3h42.6c1.7 0 3 1.3 3 3z" />
    <path d="M17.7 11c-.6 0-1 .4-1 1v.2c0 .6.4 1 1 1s1-.4 1-1V12c0-.5-.5-1-1-1M48.4 11.1h-5.9c-.6 0-1 .4-1 1s.4 1 1 1h5.9c.6 0 1-.4 1-1s-.4-1-1-1M17.7 31.7c.6 0 1-.4 1-1v-.2c0-.6-.4-1-1-1s-1 .4-1 1v.2c0 .5.4 1 1 1M42.5 31.6h5.9c.6 0 1-.4 1-1s-.4-1-1-1h-5.9c-.6 0-1 .4-1 1s.5 1 1 1" />
  </svg>
);
export default IconServer7;
