import * as React from "react";
const IconBox3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.3 28.1v-8.7c0-1.4-.5-2.7-1.4-3.7L39.3 4.9c-1-1.2-2.5-1.8-4.1-1.8h-18c-1.6 0-3.1.7-4.1 1.8L3.4 15.7C2.5 16.7 2 18 2 19.4v28.5c0 3.1 2.5 5.5 5.5 5.5h23.7c3 4.5 8.2 7.5 14 7.5 9.3 0 16.8-7.6 16.8-16.8 0-7.5-4.9-13.8-11.7-16M37.8 6.3l9.3 10.4H27.2V5.1h8c1 0 1.9.4 2.6 1.2m-23.3 0c.7-.7 1.6-1.2 2.6-1.2h8v11.6H5.2zm-7 45.1c-1.9 0-3.5-1.6-3.5-3.5V19.4c0-.2 0-.5.1-.7h44.2c.1.2.1.5.1.7v8.2c-1-.2-2.1-.3-3.2-.3-9.3 0-16.8 7.6-16.8 16.8 0 2.6.6 5.1 1.7 7.4H7.5zm37.7 7.5c-8.2 0-14.8-6.7-14.8-14.8S37 29.3 45.2 29.3 60 35.9 60 44.1s-6.7 14.8-14.8 14.8" />
    <path d="M43.9 39.1h5.6c.6 0 1-.4 1-1s-.4-1-1-1H47v-1.6c0-.6-.4-1-1-1s-1 .4-1 1v1.6h-1.2c-2.2 0-4 1.8-4 4s1.8 4 4 4h2.6c1.1 0 2 .9 2 2s-.9 2-2 2h-5.6c-.6 0-1 .4-1 1s.4 1 1 1H45v1.6c0 .6.4 1 1 1s1-.4 1-1V51c1.9-.3 3.4-1.9 3.4-3.9 0-2.2-1.8-4-4-4h-2.6c-1.1 0-2-.9-2-2s1-2 2.1-2" />
  </svg>
);
export default IconBox3;
