import * as React from "react";
const IconArrowsExchangeAltV1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M43.1 61c-.1 0-.3 0-.4-.1q-.6-.3-.6-.9V19.7c0-.6.4-1 1-1s1 .4 1 1v38.1l7.5-6.3c.4-.4 1.1-.3 1.4.1.4.4.3 1.1-.1 1.4l-9.1 7.7c-.2.2-.4.3-.7.3M20.9 45.3c-.6 0-1-.4-1-1V6.1l-7.5 6.3c-.4.4-1.1.3-1.4-.1-.4-.4-.3-1.1.1-1.4l9.1-7.7c.3-.3.7-.3 1.1-.1q.6.3.6.9v40.3c0 .5-.5 1-1 1" />
  </svg>
);
export default IconArrowsExchangeAltV1;
