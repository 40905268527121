import * as React from "react";
const IconCupCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.5 9.9H46.2v-6c0-1-.8-1.9-1.9-1.9H19.7c-1 0-1.9.8-1.9 1.9V10H6.5c-1.1 0-1.9.8-1.9 1.9v.9c0 7.4 5.8 13.4 13.2 13.9V28c0 7.4 5.8 13.5 13.2 14v18h-9.5c-.6 0-1 .4-1 1s.4 1 1 1h21.1c.6 0 1-.4 1-1s-.4-1-1-1H33V42c3.5-.2 6.7-1.7 9.2-4.1 2.6-2.6 4-6.1 4-9.8v-1.5c7.3-.6 13.2-6.6 13.2-14v-.9c0-1-.8-1.8-1.9-1.8M6.6 12.6v-.7h11.2v12.6C11.6 24 6.6 18.9 6.6 12.6M44.2 28c0 3.2-1.2 6.2-3.4 8.4-2.3 2.3-5.4 3.6-8.8 3.6-6.7 0-12.2-5.4-12.2-12V4h24.4v24m13.2-15.4c0 6.3-4.9 11.4-11.2 11.9V11.9h11.2z" />
  </svg>
);
export default IconCupCopy;
