import * as React from "react";
const IconCastleAlt5 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 5.6c-.6 0-1 .4-1 1v6.8H44.4V6.6c0-.6-.4-1-1-1s-1 .4-1 1V28c-3.3-1.2-7.1-1.8-11-1.8q-5.25 0-9.9 1.5V6.6c0-.6-.4-1-1-1s-1 .4-1 1v6.8H4V6.6c0-.6-.4-1-1-1s-1 .4-1 1v47.8c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V6.6c0-.6-.4-1-1-1M31.4 28.1c4 0 7.8.7 11 2v26.3H21.6V29.7c2.9-1 6.3-1.6 9.8-1.6M4 54.4v-39h15.6v41H6c-1.1 0-2-.9-2-2m56 0c0 1.1-.9 2-2 2H44.4v-41H60z" />
  </svg>
);
export default IconCastleAlt5;
