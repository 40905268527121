import * as React from "react";
const IconLeafAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M33.4 2.6c-.9-.9-2.4-.9-3.3 0-4.3 4-18.3 17.9-18.2 31.2.1 15.3 9.1 27.9 20 28.2h.4c4.2 0 8.2-1.8 11.5-5.1 5.2-5.2 8.3-13.8 8.2-22.8C52 20.8 37.7 6.7 33.4 2.6m9 52.8c-2.8 2.8-6 4.3-9.4 4.5v-9l5.7-5.7c.4-.4.4-1 0-1.4s-1-.4-1.4 0L33 48.1V37l5.7-5.7c.4-.4.4-1 0-1.4s-1-.4-1.4 0L32 35.1l-5.3-5.3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4L31 37v11.2l-4.3-4.3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4L31 51v8.9c-9.4-1-16.9-12.3-17-26.1-.2-12.4 13.3-25.9 17.5-29.7 0-.1.1-.1.2-.1s.2 0 .3.1C36.2 8 50 21.6 50.1 34c0 8.6-2.8 16.6-7.7 21.4" />
  </svg>
);
export default IconLeafAlt1;
