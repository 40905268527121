import * as React from "react";
const IconFreshJuice = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.9 32.4c-2-.4-4 .1-5.7 1.4.9-1.8 2.5-4 3.8-5.4.8-.9.8-2.3-.1-3.2-.9-.8-2.3-.8-3.2.1s-4.5 5.1-5.5 8.9c-.3-.2-.7-.3-1-.6-1.7-1.2-3.7-1.6-5.5-1.3-4.1.7-6.9 4.5-6.3 8.7 1.4 9.7 4.3 13.5 6.5 14.9q1.65 1.2 3.6 1.2c.4 0 .7 0 1.1-.1h.2c1.1-.2 1.8-.6 2.5-1.3.6-.6 1.5-.6 2.1 0 .3.3 1.2 1.1 2.6 1.3 1.8.3 3.3-.1 4.8-1.1 2.1-1.5 4.9-5.4 6.4-14.9.6-4-2.2-7.9-6.3-8.6m1.8 8c-1.4 9-3.9 11.4-4.6 11.9-.4.3-.7.4-1.5.3h-.1c-1.1-1.1-2.7-1.7-4.2-1.7s-3 .6-4.2 1.7c0 0-.1 0-.2.1-.7.1-1 0-1.4-.3-.7-.5-3.2-2.9-4.6-11.9-.3-1.8.8-3.3 2.6-3.6.9-.2 1.8.3 2.2.5 1.7 1.2 3.6 1.9 5.6 1.9h.5c2-.1 3.7-.8 5.2-1.9.6-.5 1.4-.7 2.2-.5 1.6.2 2.8 1.9 2.5 3.5M29.5 6.8H21c-2.6 0-4.9 1.8-5.5 4.4L14.3 17H7c-1.5 0-2.9.6-3.9 1.7s-1.5 2.5-1.4 4l2.5 29.7c.2 2.7 2.5 4.8 5.2 4.8h13.7c2.7 0 5-2.1 5.2-4.8l2.5-29.7c.1-1.5-.4-2.9-1.4-4S27 17 25.5 17h-6.8l1.1-4.8c.1-.5.6-.9 1.1-.9h8.4c1.2 0 2.3-1 2.3-2.3s-.9-2.2-2.1-2.2m-6.3 45.9H9.5c-.4 0-.7-.3-.7-.7L6.9 30.4h18.8L23.9 52c0 .4-.3.7-.7.7m3-31c.1.1.2.3.2.5l-.3 3.6H6.6l-.3-3.6c0-.3.1-.5.2-.5.1-.1.3-.2.5-.2h18.7c.3 0 .5.1.5.2" />
  </svg>
);
export default IconFreshJuice;
