import * as React from "react";
const IconEs = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22.5 15"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 0h22.5v15H0z" />
    <path  d="M0 0h22.5v4H0zm0 11h22.5v4H0z" />
    <path  d="M0 4h22.5v7H0z" />
    <path  d="M7.8 7h1v.5h-1z" />
    <path
      
      d="M7.2 8.5c0 .3.3.5.6.5s.6-.2.6-.5L8.5 7H7.1zM6.6 7c0-.3.2-.5.4-.5h1.5c.3 0 .5.2.5.4V7l-.1 1.5c-.1.6-.5 1-1.1 1s-1-.4-1.1-1z"
    />
    <path
      
      d="M6.8 7.5h2V8h-.5l-.5 1-.5-1h-.5zM5.3 6h1v3.5h-1zm4 0h1v3.5h-1zm-2.5-.5c0-.3.2-.5.5-.5h1c.3 0 .5.2.5.5v.2q0 .3-.3.3H7c-.1 0-.2-.1-.2-.2z"
    />
  </svg>
);
export default IconEs;
