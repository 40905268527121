import * as React from "react";
const IconSpeechBubble16 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 11.1C15.5 11.1 2 20 2 31c0 4.7 2.5 9.2 7.1 12.8l-2.3 7.8c-.1.4 0 .8.3 1.1.2.1.4.2.6.2s.3 0 .5-.1l8.4-4.7c4.6 1.8 9.9 2.8 15.4 2.8 16.5 0 30-8.9 30-19.8 0-11.1-13.5-20-30-20m0 37.7c-5.4 0-10.6-1-15.1-2.8-.3-.1-.6-.1-.9.1l-6.7 3.8 1.8-6.1c.1-.4 0-.8-.4-1.1C6.4 39.4 4 35.2 4 31c0-9.8 12.6-17.8 28-17.8s28 8 28 17.8-12.6 17.8-28 17.8" />
    <path d="M18.3 28.6h-.1c-1.1 0-2 .9-2 2s.9 2 2 2h.1c1.1 0 2-.9 2-2s-.9-2-2-2M32.1 28.6H32c-1.1 0-2 .9-2 2s.9 2 2 2h.1c1.1 0 2-.9 2-2s-.9-2-2-2M45.9 28.6h-.1c-1.1 0-2 .9-2 2s.9 2 2 2h.1c1.1 0 2-.9 2-2s-.9-2-2-2" />
  </svg>
);
export default IconSpeechBubble16;
