import * as React from "react";
const IconChevronRightCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M20.7 56c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4L41.9 32 20 9.7c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l22.6 23c.4.4.4 1 0 1.4l-22.6 23c-.2.2-.4.3-.7.3" />
  </svg>
);
export default IconChevronRightCopy;
