import * as React from "react";
const IconSuitcaseAltMedical = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.4 10.4H42.6V6.7c0-2.6-2.1-4.7-4.7-4.7H26.1c-2.6 0-4.7 2.1-4.7 4.7v3.7H9.6c-4 0-7.3 3.3-7.3 7.3v28.1c0 4 3.3 7.3 7.3 7.3h3.7c.6 0 1-.4 1-1s-.4-1-1-1H9.6c-2.9 0-5.3-2.4-5.3-5.3V17.6c0-2.9 2.4-5.3 5.3-5.3h44.8c2.9 0 5.3 2.4 5.3 5.3v28.1c0 2.9-2.4 5.3-5.3 5.3h-2.6c-.6 0-1 .4-1 1s.4 1 1 1h2.6c4 0 7.3-3.3 7.3-7.3V17.6c0-4-3.3-7.2-7.3-7.2m-31-3.7c0-1.5 1.2-2.7 2.7-2.7h11.7c1.5 0 2.7 1.2 2.7 2.7v3.7H23.4z" />
    <path d="M39.8 41.7c-.8-3.5-4.1-6.1-7.8-6.2-3.7.1-7 2.8-7.8 6.2-.5 2.4.3 5.2 2.2 7.8 1.3 1.7 2.7 3.3 4.1 4.7l-1.2 1.2-.6.6-4.2 4.2c-.4.4-.4 1 0 1.4s1 .4 1.4 0l4.2-4.2.6-.6 1.2-1.2 1.2 1.2.6.6 4.2 4.2c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4L35.1 56l-.6-.6-1.2-1.2c1.4-1.4 2.8-3 4.1-4.7 2.1-2.6 2.9-5.4 2.4-7.8M32 52.8q-2.1-2.1-3.9-4.5c-.9-1.3-2.4-3.7-1.9-6.2.6-2.6 3.1-4.6 5.8-4.7 2.8.1 5.3 2.1 5.9 4.7.5 2.5-1 5-1.9 6.2-1.3 1.6-2.6 3.1-4 4.5" />
  </svg>
);
export default IconSuitcaseAltMedical;
