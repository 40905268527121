import * as React from "react";
const IconReloadAltBox = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56 3H8C5.2 3 3 5.2 3 8v48c0 2.8 2.2 5 5 5h48c2.8 0 5-2.2 5-5V8c0-2.8-2.2-5-5-5m3 53c0 1.7-1.3 3-3 3H8c-1.7 0-3-1.3-3-3V8c0-1.7 1.3-3 3-3h48c1.7 0 3 1.3 3 3z" />
    <path d="M46.4 19.8H20l7-7.1c.4-.4.4-1 0-1.4s-1-.4-1.4 0L17 20.1c-.4.4-.4 1 0 1.4l8.6 8.8c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-6.9-7h25.4v5c0 .6.4 1 1 1s1-.4 1-1v-6c-.1-.7-.5-1.1-1.1-1.1M38.5 34.3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l6.9 7H18.6v-5c0-.6-.4-1-1-1s-1 .4-1 1v6c0 .6.4 1 1 1H44l-7 7.1c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3l8.6-8.8c.4-.4.4-1 0-1.4z" />
  </svg>
);
export default IconReloadAltBox;
