import * as React from "react";
const IconGoFrame = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.8 2c-.6 0-1 .4-1 1v6.4H9.2V3c0-.6-.4-1-1-1s-1 .4-1 1v58c0 .6.4 1 1 1s1-.4 1-1V42.4h45.6V61c0 .6.4 1 1 1s1-.4 1-1V3c0-.6-.4-1-1-1M9.2 40.4v-29h45.6v29z" />
    <path d="M23 18.5h6c.6 0 1-.4 1-1s-.4-1-1-1h-6c-2.8 0-5.2 2.3-5.2 5.2v8c0 2.8 2.3 5.2 5.2 5.2h1.8c2.8 0 5.2-2.3 5.2-5.2v-4.1c0-.6-.4-1-1-1h-3c-.6 0-1 .4-1 1s.4 1 1 1h2v3.1c0 1.7-1.4 3.2-3.2 3.2H23c-1.7 0-3.2-1.4-3.2-3.2v-8c0-1.8 1.4-3.2 3.2-3.2M41 16.5h-.4c-2.8 0-5.2 2.3-5.2 5.2v8c0 2.8 2.3 5.2 5.2 5.2h.4c2.8 0 5.2-2.3 5.2-5.2v-8c0-2.9-2.3-5.2-5.2-5.2m3.2 13.1c0 1.7-1.4 3.2-3.2 3.2h-.4c-1.7 0-3.2-1.4-3.2-3.2v-8c0-1.7 1.4-3.2 3.2-3.2h.4c1.7 0 3.2 1.4 3.2 3.2z" />
  </svg>
);
export default IconGoFrame;
