import * as React from "react";
const IconNightThunder = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.4 15c-.4-.3-1-.4-1.5-.2-3.8 1.8-8.3.5-10.5-3-1.5-2.4-1.7-5.3-.5-7.9.2-.5.1-1.1-.2-1.5s-.9-.6-1.4-.4c-2.2.7-4 1.9-5.5 3.6-1.3 1.5-2.2 3.4-2.6 5.4-.2.8-.2 1.6-.2 2.4 0 .6.4 1 1 1s1-.5 1-1c0-.7 0-1.3.2-2 .3-1.7 1.1-3.2 2.1-4.5.9-1.1 2-1.9 3.3-2.5-.9 2.8-.4 5.9 1.2 8.5 2.5 4 7.4 5.6 11.8 4.2-.8 1.7-2 3.2-3.6 4.2-.9.6-1.9 1-2.9 1.2-.5.1-.9.7-.7 1.2.1.5.5.8 1 .8h.2c1.2-.3 2.4-.8 3.5-1.5 2.4-1.5 4.2-3.9 5-6.7-.1-.4-.3-1-.7-1.3M41.1 28.3c-.3 0-.5-.2-.6-.4-1.7-3.9-5.6-6.4-9.9-6.4-4.4 0-8.3 2.6-10 6.7-.1.2-.3.4-.6.3-2.2-.5-4.6-.2-6.7.8-3.3 1.5-5.6 4.6-6.1 8.1-.5 3.2.4 6.3 2.5 8.7s5 3.8 8.2 3.8c.6 0 1-.4 1-1s-.4-1-1-1c-2.6 0-5-1.1-6.7-3.1s-2.4-4.5-2-7.1c.4-2.9 2.4-5.4 5-6.6 1.8-.8 3.7-1 5.5-.6 1.2.2 2.4-.4 2.8-1.5 1.4-3.3 4.6-5.4 8.1-5.4s6.6 2 8 5.2c.4.9 1.2 1.5 2.2 1.6 4.8.5 8.3 4.8 7.8 9.6-.4 4-3.6 7.2-7.8 7.8-.5.1-.9.6-.8 1.1s.6.9 1.1.8c5.1-.8 9-4.7 9.4-9.6.7-5.9-3.5-11.1-9.4-11.8" />
    <path d="M31.4 51.1h-3.1c-.1 0-.2-.1-.2-.1 0-.1-.1-.2 0-.3l4-5.5c.3-.4.2-1.1-.2-1.4s-1.1-.2-1.4.2l-4 5.5c-.5.7-.6 1.6-.2 2.3.4.8 1.2 1.2 2 1.2h3.1c.1 0 .2.1.2.1 0 .1.1.2 0 .3l-4.8 7c-.3.5-.2 1.1.3 1.4.2.1.4.2.6.2.3 0 .6-.2.8-.4l4.8-7c.5-.7.5-1.6.1-2.3s-1.2-1.2-2-1.2" />
  </svg>
);
export default IconNightThunder;
