import * as React from "react";
const IconHighlightAltCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m60.1 10.3-5.5-5.5c-2.6-2.6-6.7-2.6-9.3 0L13.9 36.2c-.3.3-.4.6-.4 1s.1.7.4 1l1.2 1.2-6 6c-.8.8-1.2 2-1.2 3.1l-5.4 5.4c-.4.4-.6 1-.5 1.6.1.5.4 1 1 1.3l7.6 4.1c.3.2.6.2 1 .2.5 0 1.1-.2 1.5-.6l3.5-3.5-.1-.1h.2c1.1 0 2.1-.4 2.9-1.2l6-6 1.2 1.2c.3.3.6.4 1 .4s.8-.2 1-.4l31.4-31.4c2.4-2.5 2.4-6.6-.1-9.2M11.5 59.1l-7.4-4 4.5-4.5c.2.2.3.5.5.7l4.5 4.5c.2.2.5.4.7.6zm6.6-4.8c-.8.8-2.2.8-3 0l-4.5-4.5c-.8-.8-.8-2.2 0-3l6-6 7.5 7.5zm40.6-36.1-31 31-11.9-12 31-31c1.8-1.8 4.7-1.8 6.4 0l5.5 5.5c1.7 1.8 1.7 4.7 0 6.5" />
  </svg>
);
export default IconHighlightAltCopy;
