import * as React from "react";
const IconVmware = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 65 64"
    {...props}
  >
    <path
      
      d="M34.79 26.152q2.415.753 3.161 3.233v9.245h7.154v-.01h1.101v-.019h.103V22.918q-1.232-4.094-5.214-5.343H9.073Q5.091 18.82 3.86 22.918v32.97q1.232 4.092 5.326 5.18h31.798q4.095-1.088 5.326-5.18v-8.596H37.95v2.07q-.746 2.48-3.227 3.139h-19.27q-2.482-.658-3.228-3.139V29.386q.747-2.48 3.162-3.235z"
    />
    <path
      
      d="M61.86 8.275q-1.232-4.094-5.215-5.343h-32.02q-3.984 1.25-5.215 5.343v8.385h8.357v-1.917q.747-2.481 3.163-3.234h19.4q2.416.753 3.163 3.234v19.976q-.747 2.48-3.228 3.139h-19.27q-2.481-.658-3.228-3.139v-9.482H19.41v16.008q1.23 4.092 5.326 5.18h31.797q4.096-1.088 5.326-5.18z"
    />
  </svg>
);
export default IconVmware;
