import * as React from "react";
const IconWebsite = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m47 26.3.5-1.1.5 1.1c.4.9 1.3 1.4 2.3 1.4s1.8-.5 2.3-1.4l2.5-5.1c.6-1.1.1-2.5-1-3s-2.5-.1-3 1l-.8 1.5-.5-1.1c-.4-.9-1.3-1.4-2.3-1.4s-1.8.5-2.3 1.4l-.5 1.1-.7-1.6c-.6-1.1-1.9-1.6-3-1-.6.3-1 .9-1.2 1.5-.1-.6-.6-1.2-1.2-1.5-1.1-.6-2.5-.1-3 1l-.8 1.5-.5-1.1c-.4-.9-1.3-1.4-2.3-1.4s-1.8.5-2.3 1.4l-.5 1.1-.8-1.5c-.6-1.1-1.9-1.6-3-1-.6.3-1 .9-1.2 1.5-.1-.6-.6-1.2-1.2-1.5-1.1-.6-2.5-.1-3 1l-.8 1.5-.5-1.1c-.4-.9-1.3-1.4-2.3-1.4s-1.8.5-2.3 1.4l-.5 1.1-.8-1.5c-.6-1.1-1.9-1.6-3-1s-1.6 1.9-1 3l2.5 5.1c.4.9 1.3 1.4 2.3 1.4s1.8-.5 2.3-1.4l.5-1.1.5 1.1c.4.9 1.3 1.4 2.3 1.4s1.8-.5 2.3-1.4l2.5-5.1c.1-.2.1-.3.2-.5 0 .2.1.3.2.5l2.5 5.1c.4.9 1.3 1.4 2.3 1.4s1.8-.5 2.3-1.4l.5-1.1.5 1.1c.4.9 1.3 1.4 2.3 1.4s1.8-.5 2.3-1.4l2.5-5.1c.1-.2.1-.3.2-.5 0 .2.1.3.2.5l2.5 5.1c.4.9 1.3 1.4 2.3 1.4.9.1 1.8-.5 2.2-1.3" />
    <path d="M58.4 7.3H5.6C2.9 7.3.8 9.5.8 12.2v21.2c0 2.7 2.2 4.9 4.8 4.9h31.7c1.2 0 2.3-1 2.3-2.3s-1-2.3-2.3-2.3H5.6c-.2 0-.3-.2-.3-.4V12.2c0-.2.1-.4.3-.4h52.9c.2 0 .3.2.3.4v21.2c0 .2-.2.4-.4.4-1.2 0-2.3 1-2.3 2.3s1 2.3 2.3 2.3c2.7 0 4.9-2.2 4.9-4.9V12.2c0-2.7-2.2-4.9-4.9-4.9" />
    <path d="m61.3 43.9-14.1-7.1c-1.2-.6-2.6-.4-3.4.5-.9.9-1.1 2.3-.5 3.5l7.1 14.1c.6 1.1 1.7 1.9 3 1.9h.1c1.1 0 2.1-.7 2.5-1.7l1.7-3.9 3.9-1.7c1-.4 1.6-1.4 1.7-2.5-.1-1.4-.8-2.6-2-3.1m-7.1 3.8-1.1 2.5-3.7-7.3 7.3 3.7z" />
  </svg>
);
export default IconWebsite;
