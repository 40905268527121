import * as React from "react";
const IconMicroscopeCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M47.9 60H35.7v-8.3c.7 0 1.3-.1 1.9-.1C47 50.5 54 42 54 31.8c0-5.7-2.2-11-6.2-14.8-.6-.6-1.3-1.2-2.1-1.7l2.4-3.6c.7-1.1 1-2.5.7-3.8s-1-2.4-2.2-3.2l-1.8-1.2c-1.1-.7-2.5-1-3.8-.7s-2.4 1-3.2 2.2L26.6 22.5c-.7 1.1-1 2.5-.7 3.8s1 2.4 2.2 3.2l1.8 1.2c.8.5 1.8.8 2.7.8 1.6 0 3.2-.8 4.2-2.3L44.7 17c.6.5 1.3.9 1.8 1.5 3.6 3.4 5.6 8.1 5.6 13.3 0 9.1-6.3 16.8-14.7 17.8-.6.1-1.3.1-2 .1-.9 0-1.6-.1-2.5-.2-5-.8-9.3-4-11.9-8.6h9.6c.6 0 1-.4 1-1s-.4-1-1-1H10.9c-.6 0-1 .4-1 1s.4 1 1 1h7.9c2.7 5.7 7.9 9.6 13.8 10.6.4.1.7.1 1 .1V60H21.5c-.6 0-1 .4-1 1s.4 1 1 1h26.4c.6 0 1-.4 1-1s-.5-1-1-1M35.1 28c-.9 1.4-2.8 1.8-4.2.9l-1.8-1.2c-.7-.4-1.1-1.1-1.3-1.9s0-1.6.4-2.3L39.6 6.1c.4-.7 1.1-1.1 1.9-1.3.2 0 .4-.1.6-.1.6 0 1.1.2 1.6.5l1.8 1.2c.7.4 1.1 1.1 1.3 1.9s0 1.6-.4 2.3z" />
  </svg>
);
export default IconMicroscopeCopy;
