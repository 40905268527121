import * as React from "react";
const IconStarHalf = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M45.2 27.4c1.2 0 2.2-1 2.2-2.2s-.9-2.3-2.2-2.3l-4.3-.2-1.5-3.8c-.4-1.2-1.7-1.7-2.9-1.3s-1.7 1.8-1.3 2.9l2 5.2c.3.8 1.1 1.4 2 1.4zc-.1 0 0 0 0 0M62.1 25.3c-.3-1.1-1.3-1.8-2.4-1.8l-5.9-.2c-1.2-.1-2.3.9-2.3 2.2 0 1.2.9 2.3 2.2 2.3h.7l-3 2.3c-1 .7-1.2 2.2-.4 3.2.4.6 1.1.9 1.8.9.5 0 .9-.1 1.4-.5l7.2-5.4c.7-.8 1.1-2 .7-3M46.1 33.9l-3.5 2.6c-.7.6-1.1 1.5-.8 2.4l1.2 4.2c.3 1 1.2 1.6 2.2 1.6.2 0 .4 0 .6-.1 1.2-.4 1.9-1.6 1.5-2.8l-.8-2.7 2.3-1.7c1-.7 1.2-2.2.4-3.2-.7-.8-2.1-1-3.1-.3M50 50.9c-.3-1.2-1.6-1.9-2.8-1.5s-1.9 1.6-1.5 2.8l.2.7-2.8-1.9c-1-.7-2.4-.4-3.1.7-.7 1-.4 2.4.7 3.1l7 4.5c.4.3.9.4 1.4.4.6 0 1.1-.2 1.6-.5.9-.7 1.3-1.8.9-2.8z" />
    <path d="m38 47.8-4.8-3.1h-.1c-.1-.1-.3-.2-.5-.2h-.1c-.2 0-.4-.1-.6-.1h-.1c-.2 0-.3 0-.5.1h-.1c-.2.1-.4.1-.5.2L18 52.8 22.2 39c.3-.9-.1-1.9-.8-2.4L9.7 27.8l15-.6c.9 0 1.7-.6 2-1.4L32 12.1l.5 1.4c.3.9 1.2 1.4 2.1 1.4.3 0 .5 0 .8-.2 1.2-.4 1.7-1.8 1.3-2.9L34.5 6c-.4-1-1.4-1.7-2.5-1.7S29.9 5 29.5 6L23 22.8l-18.7.7c-1.1 0-2.1.8-2.4 1.8s0 2.2.9 2.9l14.7 11.1-5 17.1c-.3 1.1.1 2.2.9 2.8.5.4 1 .5 1.6.5.5 0 1-.1 1.4-.4l15.6-10 3.6 2.3c.4.2.8.4 1.2.4.7 0 1.5-.4 1.9-1 .7-1.1.4-2.5-.7-3.2" />
  </svg>
);
export default IconStarHalf;
