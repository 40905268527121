import * as React from "react";
const IconTeabag = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M15.5 31.7h5.1c1.2 0 2.3-1 2.3-2.3s-1-2.3-2.3-2.3h-5.1c-1.2 0-2.3 1-2.3 2.3s1 2.3 2.3 2.3" />
    <path d="m59.7 42.7-3.8-1.9V22.2c0-11.3-9-20.5-20.1-20.5-11 0-18.6 6-19.9 15.3H8.3c-1.7 0-3.1 1.2-3.5 2.9l-1.6 6.9c-.2.5-.2.9-.2 1.1v28.2c0 1.6.6 3.2 1.8 4.3s2.7 1.8 4.3 1.8l17.7-.1c3.4 0 6.1-2.8 6.1-6.1V27.8c0-.2 0-.4-.1-.5L31 19.9c-.3-1.7-1.8-2.9-3.5-2.9h-7.2c1.4-7.9 8.8-10.8 15.3-10.8 8.6 0 15.6 7.2 15.6 16v18.6l-3.8 1.9c-.8.4-1.2 1.2-1.2 2V58c0 2.3 1.9 4.2 4.2 4.2h6.3c2.3 0 4.2-1.9 4.2-4.2V44.7c.1-.8-.4-1.6-1.2-2M28.4 27.9V56c0 .9-.7 1.6-1.6 1.6l-17.7.1c-.4 0-.8-.2-1.1-.5s-.5-.7-.5-1.1v-28L9 21.5h17.8zm28.1 29.9h-5.7V46.1l2.8-1.4 2.8 1.4v11.7z" />
  </svg>
);
export default IconTeabag;
