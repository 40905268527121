import * as React from "react";
const IconCommand = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 65 65"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M52 42.1h-7.5l.1-20.2H52c5.2 0 9.5-4.2 9.5-9.5S57.3 3 52 3c-5.2 0-9.5 4.2-9.5 9.5V20H22.4v-7.5C22.4 7.2 18.2 3 13 3s-9.5 4.2-9.5 9.5S7.7 22 13 22h7.5v20.2H13c-5.2 0-9.5 4.2-9.5 9.5S7.7 61 13 61s9.5-4.2 9.5-9.5V44h20.1v7.5c0 5.2 4.2 9.5 9.5 9.5 5.2 0 9.5-4.2 9.5-9.5s-4.4-9.4-9.6-9.4m-7.4-29.6C44.6 8.3 47.9 5 52 5s7.5 3.3 7.5 7.5S56.2 20 52 20h-7.5v-7.5zm-24.2 39c0 4.1-3.3 7.5-7.5 7.5s-7.5-3.3-7.5-7.5S8.7 44 12.9 44h7.5zm0-31.6H13c-4.1 0-7.5-3.3-7.5-7.5S8.8 5 13 5s7.5 3.3 7.5 7.5v7.4zm22.1 22.2H22.4V21.9h20.2zM52 59c-4.1 0-7.5-3.3-7.5-7.5V44H52c4.1 0 7.5 3.3 7.5 7.5S56.1 59 52 59" />
  </svg>
);
export default IconCommand;
