import * as React from "react";
const IconStroopwafel = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 59.6c-1.6 0-3.1-.7-4-2.1L10 32.9c-.2-.3-.3-.7-.1-1s.5-.5.9-.5h1.9c-.7-2.1-1.1-4.3-1.1-6.5 0-8.7 5.5-16.2 13.2-19.1.1-.1.2-.1.4-.1 2.1-.8 4.4-1.2 6.8-1.2s4.7.4 6.8 1.2c.1 0 .3.1.4.1 7.6 2.9 13 10.2 13.2 18.7v.3c0 2.2-.4 4.4-1.1 6.5h1.9c.4 0 .7.2.9.5s.1.7-.1 1L36 57.6c-.9 1.3-2.4 2-4 2M12.7 33.3l16.9 23.1c.6.8 1.5 1.2 2.4 1.2s1.9-.4 2.4-1.2l16.9-23.1zm26.9-2h9.6c0-.1 0-.3.1-.4.6-1.7.9-3.5 1-5.4H39.6zm-13.2 0h11.3v-5.8H26.4zm-11.7 0h9.6v-5.8H13.7c.1 1.8.4 3.7 1 5.4zm24.9-7.8h10.7c-.2-2.2-.7-4.3-1.6-6.3h-9.1zm-13.2 0h11.3v-6.3H26.4zm-12.7 0h10.7v-6.3h-9.1c-.9 1.9-1.5 4.1-1.6 6.3m25.9-8.3h8c-1.9-3.1-4.7-5.6-8-7.2zm-13.2 0h11.3V7.3c-3.6-1.2-7.7-1.2-11.3 0zm-10.1 0h8V8.1c-3.3 1.5-6.1 4-8 7.1" />
  </svg>
);
export default IconStroopwafel;
