import * as React from "react";
const IconFemale = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M33 26.2V12.4h10.6c.6 0 1-.4 1-1s-.4-1-1-1H33V3c0-.6-.4-1-1-1s-1 .4-1 1v7.4H20.4c-.6 0-1 .4-1 1s.4 1 1 1H31v13.8c-9.4.5-16.9 8.3-16.9 17.9C14.1 54 22.1 62 32 62s17.9-8 17.9-17.9c0-9.6-7.5-17.4-16.9-17.9M32 60c-8.8 0-15.9-7.1-15.9-15.9S23.2 28.2 32 28.2s15.9 7.1 15.9 15.9S40.8 60 32 60" />
  </svg>
);
export default IconFemale;
