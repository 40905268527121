import * as React from "react";
const IconTextFormatCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M5.2 46.5h5.5c.9 0 2-.7 2.2-1.4l2.6-6.5H35l2.3 6.5c.2.9 1.3 1.4 2.2 1.4h5.9c1.8-.2 2.9-1.8 2.2-3.2L31.7 5.6c-.4-1-1.1-1.3-2-1.3h-8.3c-.9 0-1.8.4-2 1.3L3.2 43.5c-.7 1.5.4 3 2 3m20.1-30.7 5.6 14.9H19.6zM47.8 58.7H3c-.6 0-1 .4-1 1s.4 1 1 1h44.8c.6 0 1-.4 1-1s-.4-1-1-1M61 3.3c-.6 0-1 .4-1 1v42.2c0 .6.4 1 1 1s1-.4 1-1V4.3c0-.5-.4-1-1-1" />
  </svg>
);
export default IconTextFormatCopy;
