import React, { useState } from 'react';
import { BringzzButton, BringzzText } from "@bringzz/components";
import { useNavigation } from 'context/NavigationContext';
import {
    BringzzPageContainer,
    BringzzIcon,
    BringzzRadioList,
    BringzzDate,
    BringzzInput
} from "@bringzz/components";
import { useLocation } from 'react-router-dom';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const ConsumerCardPage = () => {
    const { backPress } = useNavigation();

    const { navigate } = useNavigation();

    const [cardNumber, setCardNumber] = useState("")
    const [cardCVC, setCardCVC] = useState("")
    const [cardExpDate, setCardExpDate] = useState("")
    const [cardHolder, setCardHolder] = useState("")

    let icon = (
        <BringzzIcon
            icon="IconMountain"
            library="Custom"
            className="flex items-center justify-center h-10 w-10 bg-gray-100 rounded-full"
            size="28"
        />
    );

    let state = {
        checked: (
            <BringzzIcon
                icon="IconCheck"
                library="Custom"
                className="h-6 w-6 p-1 bg-green-500 flex justify-center items-center text-light rounded-full"
                size="14"
            />
        ),
        unChecked: (
            <div className="h-6 w-6 rounded-full border border-neutral-400"></div>
        ),
    };

    const validateCard = () => {
        return !cardNumber || !cardExpDate || !cardCVC || !cardHolder
    }

    return (
        <BringzzPageContainer
            className="bg-white"
            header={{
                left: (
                    <BringzzIcon
                        size='22'
                        icon='IconClose'
                        folder='LineIcons'
                        className='cursor-pointer'
                        onClick={backPress}
                    />
                ),
                title: "Payment Method"
            }}
            footer={
                <div className='pb-8 px-6'>
                    <BringzzButton
                        size='lg'
                        className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
                        onClick={() => {
                            navigate('/register/consumer/address');
                        }}
                        disabled={validateCard()}
                    >
                        Save
                    </BringzzButton>
                </div>
            }
            authenticated={false}
        >
            <div className='relative flex justify-center bg-magic-lilac/20'>
                <div className='relative bg-white w-full bg-cover bg-center flex flex-col'>
                    {/* Content */}
                    <div className='flex-grow flex flex-col py-8 px-4'>
                        <div className='z-10 flex flex-col '>
                            <div className='pl-2 flex flex-col space-y-6 md:max-w-lg lg:max-w-xl xl:max-w-2xl'>
                                <BringzzText tag='h1' className='pl-2 text-midnight-blue font-bold'>
                                    Add Credit Card
                                </BringzzText>
                                <BringzzText tag="h3" className="font-normal">Please enter your credit card information</BringzzText>
                            </div>
                            <div className='mt-6 space-y-6'>
                                <BringzzInput
                                    label="Credit Card"
                                    tooltip='Something'
                                    placeholder="XXXX XXXX XXXX XXXX"
                                    pattern='^[1-9]{4} [1-9]{4} [1-9]{4} [1-9]{4}$'
                                    defaultValue={cardNumber}
                                    icon={{
                                        left: (
                                            <BringzzIcon
                                                icon="IconCreditCard"
                                                folder="LineIcons"
                                                className="h-6 w-6"
                                                size="24"
                                            />
                                        ),
                                        right: (
                                            <BringzzIcon
                                                icon="IconCameraAlt1"
                                                folder="LineIcons"
                                                className="h-6 w-6"
                                                size="24"
                                            />
                                        ),
                                    }}
                                    onChange={(e, isValid, newValue) => {
                                        if (isValid) setCardNumber(newValue)
                                    }} />
                                <div className='flex gap-2'>
                                    <BringzzInput label="Exp. Date" placeholder="MM/YY" tooltip='Something' onChange={(e, isValid, newValue) => setCardExpDate(newValue)} />
                                    <BringzzInput label="CVC" placeholder="123" tooltip='Something' onChange={(e, isValid, newValue) => setCardCVC(newValue)} />
                                </div>
                                <BringzzInput label="Card holder" tooltip='Something' right={
                                    <BringzzIcon
                                        icon="IconQuestionCircle"
                                        folder="LineIcons"
                                        className="h-6 w-6"
                                        size="24"
                                    />
                                }
                                    onChange={(e, isValid, newValue) => setCardHolder(newValue)} />
                            </div>
                            <div className='flex w-full p-6 mt-4 h-60'>
                                <div className='flex flex-col justify-between bg-magic-lilac w-3/4 rounded-l-2xl p-2'>
                                    <BringzzText tag='h2' className="font-normal text-white p-2">Untitled.</BringzzText>
                                    <div className='flex flex-col justify-end'>
                                        <BringzzText tag='h4' className="font-normal text-white">{cardHolder}</BringzzText>
                                        <BringzzText tag='h2' className="font-normal text-white">{cardNumber ? cardNumber : "XXXX XXXX XXXX XXXX"}</BringzzText>
                                        <BringzzText tag='h3' className="pl-2 font-normal text-white">{cardExpDate}</BringzzText>
                                    </div>
                                </div>
                                <div className='flex justify-center pt-4 bg-slate-200 w-1/4 rounded-r-2xl bg-gradient-to-b from-sand via-slate-200 to-sand bg-[length:20%_60%_20%] bg-no-repeat'>
                                    <BringzzIcon
                                        icon='IconSignal'
                                        folder='CustomIcons'
                                        className='text-slate-100'
                                        size='28' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BringzzPageContainer>
    );
};

export default ConsumerCardPage;
