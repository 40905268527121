import * as React from "react";
const IconOilTanker = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.3 21.6H40.8c-.6 0-1 .4-1 1V29H28.7c1.1-1.2 1.8-2.7 1.8-4.4v-5.8c0-3.5-2.9-6.4-6.4-6.4H8.4c-3.5 0-6.4 2.9-6.4 6.4v27.9c0 .6.4 1 1 1h4.1c.7 2.3 2.8 4 5.4 4s4.7-1.7 5.4-4h3.9c.3 0 .5-.1.7-.3.6 2.5 2.8 4.3 5.4 4.3 2.5 0 4.7-1.7 5.4-4h12.3c.7 2.3 2.8 4 5.4 4s4.7-1.7 5.4-4H61c.6 0 1-.4 1-1V30.3c0-4.8-3.9-8.7-8.7-8.7M4 18.7c0-2.4 2-4.4 4.4-4.4H24c2.4 0 4.4 2 4.4 4.4v5.8c0 2.4-2 4.4-4.4 4.4H8.4C6 29 4 27 4 24.6zm8.4 31c-2 0-3.6-1.6-3.6-3.6s1.6-3.6 3.6-3.6 3.6 1.6 3.6 3.6c.1 2-1.6 3.6-3.6 3.6m9.8-3.9c-.2-.1-.3-.2-.5-.2H18c-.2-2.9-2.6-5.2-5.6-5.2S7 42.8 6.8 45.7H4V29.2C5.2 30.3 6.7 31 8.4 31h31.4v14.7h-6.4c-.2-2.9-2.6-5.2-5.6-5.2-3-.1-5.4 2.3-5.6 5.3m5.6 3.9c-2 0-3.6-1.6-3.6-3.6s1.6-3.6 3.6-3.6 3.6 1.6 3.6 3.6-1.6 3.6-3.6 3.6m23.1 0c-2 0-3.6-1.6-3.6-3.6s1.6-3.6 3.6-3.6 3.6 1.6 3.6 3.6-1.6 3.6-3.6 3.6m9.1-4h-3.5c-.2-2.9-2.6-5.2-5.6-5.2s-5.4 2.3-5.6 5.2h-3.5v-22h11.5c3.7 0 6.7 3 6.7 6.7z" />
  </svg>
);
export default IconOilTanker;
