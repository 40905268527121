import * as React from "react";
const IconWifiError = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M39.8 25.1c-.5-.1-1.1.2-1.2.7s.2 1.1.7 1.2c4.4 1.2 8.4 3.6 11.7 6.8.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4-3.6-3.5-7.9-6-12.6-7.3M25.5 25.8c-.1-.5-.7-.8-1.2-.7-4.7 1.3-9.1 3.8-12.6 7.4-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3 3.3-3.3 7.3-5.6 11.7-6.8.5-.2.8-.8.7-1.3M24 37.7c-1.5.8-2.9 1.8-4.1 3-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3q1.65-1.65 3.6-2.7c.5-.3.7-.9.4-1.3-.2-.5-.8-.7-1.3-.4M40 37.7c-.5-.3-1.1-.1-1.3.4-.3.5-.1 1.1.4 1.3 1.3.7 2.6 1.6 3.6 2.7.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4-1.2-1.2-2.6-2.2-4.1-3M25.5 13.7c-.1-.5-.6-.9-1.2-.8-8 1.5-15.2 5.4-21 11.2-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3 5.5-5.5 12.4-9.2 20-10.6.5-.1.9-.6.8-1.2M60.7 24.1c-5.8-5.8-13-9.6-21-11.2-.5-.1-1.1.3-1.2.8s.3 1.1.8 1.2c7.6 1.5 14.5 5.1 20 10.6.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4M33 45.8V8.6c0-.6-.4-1-1-1s-1 .4-1 1v37.2c-1 .2-2 .7-2.8 1.5-1.7 1.7-2.1 4.4-.8 6.4.2.4.5.8.8 1.1 1 1 2.3 1.6 3.8 1.6s2.8-.6 3.8-1.6c.3-.3.6-.7.8-1.1 1.2-2.1.9-4.7-.8-6.4-.8-.8-1.8-1.3-2.8-1.5m1.9 6.9c-.1.2-.3.5-.5.7-1.2 1.3-3.4 1.3-4.7 0-.2-.2-.4-.4-.5-.7-.8-1.3-.5-2.9.5-4 .6-.6 1.5-1 2.3-1s1.7.3 2.3 1c1.1 1.1 1.3 2.7.6 4" />
  </svg>
);
export default IconWifiError;
