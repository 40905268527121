import * as React from "react";
const IconCandyAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m59.9 7-3-3c-1.5-1.5-3.6-2.3-5.7-2-2.1.2-4 1.4-5.2 3.2l-4 6.1c-6.3-2.3-13.6-.9-18.7 4.1l-7.9 7.9c-3.4 3.4-5.2 7.8-5.2 12.6 0 2.1.4 4.2 1.1 6.2l-6 3.9c-1.8 1.2-3 3.1-3.2 5.2-.2 2.2.5 4.3 2 5.8l3 3c1.3 1.3 3.1 2.1 5 2.1h.7c2.1-.2 4-1.4 5.2-3.2l4.1-6.2c1.9.7 4 1 6 1 4.6 0 9.1-1.7 12.6-5.2l7.9-7.9c3.4-3.4 5.2-7.8 5.2-12.6 0-2.2-.4-4.3-1.1-6.2l6-3.8c1.8-1.2 3-3.1 3.3-5.2.2-2.2-.5-4.3-2.1-5.8M16.4 57.7c-.8 1.3-2.2 2.1-3.7 2.3s-3-.4-4.1-1.5l-3-3c-1.1-1.1-1.6-2.6-1.5-4.2.2-1.5 1-2.9 2.3-3.7l5.8-3.7q1.2 2.55 3.3 4.5c1.4 1.4 3 2.5 4.8 3.4zm.5-10.6c-3-3-4.6-6.9-4.6-11.1s1.6-8.2 4.6-11.1l3.4-3.4 11.1 30c-5.1.9-10.6-.5-14.5-4.4m16.4 3.7-11.5-31 2.9-2.9q1.2-1.2 2.7-2.1l11.8 32c0 .1.1.1.1.2l-.2.2c-1.6 1.6-3.6 2.8-5.8 3.6m13.9-11.6L41 45.4 29.2 13.8c5.9-2.8 13.1-1.7 17.9 3.1 3 3 4.6 6.9 4.6 11.1.1 4.2-1.5 8.2-4.5 11.2M60 12.6c-.2 1.5-1 2.9-2.3 3.7l-5.8 3.6q-1.2-2.4-3.3-4.5c-1.4-1.4-3-2.5-4.7-3.3l3.8-5.8c.8-1.3 2.2-2.1 3.7-2.3s3 .4 4.1 1.5l3 3c1.1 1 1.6 2.5 1.5 4.1" />
  </svg>
);
export default IconCandyAlt;
