import * as React from "react";
const IconRoundBoxMinus = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M43.2 31H20.8c-.6 0-1 .4-1 1s.4 1 1 1h22.3c.6 0 1-.4 1-1s-.4-1-.9-1" />
    <path d="M57 2H7C4.2 2 2 4.2 2 7v50c0 2.8 2.2 5 5 5h50c2.8 0 5-2.2 5-5V7c0-2.8-2.2-5-5-5m3 55c0 1.7-1.3 3-3 3H7c-1.7 0-3-1.3-3-3V7c0-1.7 1.3-3 3-3h50c1.7 0 3 1.3 3 3z" />
  </svg>
);
export default IconRoundBoxMinus;
