import * as React from "react";
const IconDashboard = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 14.5c-16.7 0-30.3 14.7-30.3 32.8 0 1.2 1 2.3 2.3 2.3h56c1.2 0 2.3-1 2.3-2.3 0-18.1-13.6-32.8-30.3-32.8M31 45l1-3.1 1 3.1zm6.7 0L35 36.6c-.4-1.3-1.6-2.2-3-2.2s-2.6.9-3 2.2L26.3 45h-20C7.4 30.5 18.5 19 32 19s24.6 11.5 25.7 26z" />
    <path d="M32 24.9c-1.2 0-2.3 1-2.3 2.3v2.5c0 1.2 1 2.3 2.3 2.3 1.2 0 2.3-1 2.3-2.3v-2.5c0-1.3-1.1-2.3-2.3-2.3M46.4 32.3l-1.6 1.6c-.9.9-.9 2.3 0 3.2.4.4 1 .6 1.6.6s1.2-.2 1.6-.7l1.6-1.6c.9-.9.9-2.3 0-3.2s-2.3-.8-3.2.1M17.7 32.3c-.8-.9-2.3-1-3.2-.2s-1 2.3-.2 3.2l1.5 1.6c.4.5 1.1.7 1.7.7.5 0 1.1-.2 1.5-.6.9-.8 1-2.3.2-3.2z" />
  </svg>
);
export default IconDashboard;
