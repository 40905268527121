import * as React from "react";
const IconCodeCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M18.1 15.5c.4-.4.3-1-.1-1.4s-1-.3-1.4.1L3 29.4c-1.3 1.4-1.3 3.7 0 5.1l13.6 15.3c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 .1-1.4L4.4 33.2c-.6-.7-.6-1.8 0-2.5zM61 29.4 47.4 14.1c-.4-.4-1-.4-1.4-.1-.4.4-.4 1-.1 1.4l13.6 15.3c.6.7.6 1.8 0 2.5L45.9 48.5c-.4.4-.3 1 .1 1.4.2.2.4.3.7.3s.5-.1.7-.3L61 34.6c1.3-1.4 1.3-3.8 0-5.2M37.8 14.7c-.5-.2-1.1.1-1.3.6L25.6 48c-.2.5.1 1.1.6 1.3.1 0 .2.1.3.1.4 0 .8-.3.9-.7l11-32.7c.2-.5-.1-1.1-.6-1.3" />
  </svg>
);
export default IconCodeCopy;
