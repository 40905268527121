import * as React from "react";
const IconSpinner3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2c-.6 0-1 .4-1 1v17.7c0 .6.4 1 1 1s1-.4 1-1V3c0-.6-.4-1-1-1M32 45.3c-.6 0-1 .4-1 1V61c0 .6.4 1 1 1s1-.4 1-1V46.3c0-.6-.4-1-1-1M21.7 32c0-.6-.4-1-1-1H3c-.6 0-1 .4-1 1s.4 1 1 1h17.7c.5 0 1-.4 1-1M61 31H43.3c-.6 0-1 .4-1 1s.4 1 1 1H61c.6 0 1-.4 1-1s-.4-1-1-1M43.6 21.8 56.1 9.3c.4-.4.4-1 0-1.4s-1-.4-1.4 0L42.2 20.4c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3M20.4 42.1 7.9 54.7c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l12.5-12.5c.4-.4.4-1 0-1.4s-1-.5-1.4-.1M9.4 7.9c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l12.5 12.5c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4z" />
  </svg>
);
export default IconSpinner3;
