import * as React from "react";
const IconMouseAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M37.8 20c-.6 0-1 .5-.9 1.1 0 .6.5 1 1.1.9 1.1-.1 2.2.4 3 1.2s1.2 1.9 1.2 3c0 .6.4 1 .9 1.1h.1c.5 0 1-.4 1-.9.1-1.7-.5-3.3-1.7-4.5-1.4-1.4-3.1-2-4.7-1.9M37.4 26.8q-.15 0 0 0l-.1-.1c-5.4-5.4-14.5-4.9-20.4 1l-8.1 8.1c-3 2.8-4.6 6.5-4.8 10.4-.2 3.8 1.1 7.4 3.7 10 2.5 2.5 5.8 3.8 9.4 3.8h.1c4 0 8-1.8 11-4.8l8.1-8.1c2.8-2.8 4.5-6.5 4.7-10.3s-1.1-7.3-3.6-10c0 .1 0 0 0 0m-19.2 2.3c4.9-4.9 12.3-5.5 17-1.5L25 37.8l-7.7-7.7zm8.6 24.8c-2.6 2.7-6.1 4.2-9.6 4.2-3.1 0-5.9-1.1-8-3.2C7 52.7 5.9 49.6 6 46.3c.2-3.3 1.6-6.5 4.1-9l5.7-5.7 16.6 16.6zM39 36.7c-.2 3.3-1.6 6.5-4.1 9l-1 1-7.5-7.5L36.6 29c1.7 2.1 2.5 4.8 2.4 7.7M53.6 10.4C49.3 6.1 43.5 3.8 37.3 4c-.6 0-1 .5-1 1 0 .6.5 1 1 1 5.6-.2 10.8 1.9 14.8 5.8 3.9 3.9 6 9.2 5.8 14.8 0 .6.4 1 1 1 .5 0 1-.4 1-1 .3-6-2-11.9-6.3-16.2" />
    <path d="M37.5 12c-.6 0-1 .5-1 1 0 .6.5 1 1 1 3.3-.1 6.6 1.1 8.9 3.5 2.4 2.4 3.6 5.6 3.5 8.9 0 .6.4 1 1 1 .5 0 1-.4 1-1 .2-3.9-1.3-7.6-4.1-10.4-2.6-2.7-6.4-4.2-10.3-4" />
  </svg>
);
export default IconMouseAlt;
