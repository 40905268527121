import * as React from "react";
const IconSpinner8 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <circle cx={32} cy={7.1} r={4.1} />
    <circle cx={32} cy={56.9} r={4.1} />
    <circle cx={49.6} cy={14.4} r={4.1} />
    <circle cx={14.4} cy={49.6} r={4.1} />
    <circle cx={56.9} cy={32} r={4.1} />
    <circle cx={7.1} cy={32} r={4.1} />
    <circle cx={49.6} cy={49.6} r={4.1} />
    <circle cx={14.4} cy={14.4} r={4.1} />
  </svg>
);
export default IconSpinner8;
