import * as React from "react";
const IconMale = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M33 25.9v-21l5.4 5.4c.4.4 1 .4 1.4 0s.4-1 0-1.4l-6.1-6.1c-.9-.9-2.5-.9-3.4 0l-6.1 6.1c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3L31 4.8v21c-9.5.5-17.1 8.4-17.1 18C13.9 53.9 22 62 32 62s18.1-8.1 18.1-18.1c0-9.6-7.6-17.5-17.1-18M32 60c-8.9 0-16.1-7.2-16.1-16.1S23.1 27.8 32 27.8 48.1 35 48.1 43.9 40.9 60 32 60" />
  </svg>
);
export default IconMale;
