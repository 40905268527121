import * as React from "react";
const IconCastleAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.5 2c-.6 0-1 .4-1 1v8.1h-17V3c0-.6-.4-1-1-1s-1 .4-1 1v8.1h-17V3c0-.6-.4-1-1-1s-1 .4-1 1v8.1h-17V3c0-.6-.4-1-1-1s-1 .4-1 1v54c0 2.8 2.3 5.1 5.1 5.1h16c.6 0 1-.4 1-1v-8.3c0-7.7 2.2-15.2 6.2-21.7l.9-1.5c.1-.1.2-.2.3-.2s.2 0 .3.2l.9 1.5c4.1 6.5 6.2 14 6.2 21.7V61c0 .6.4 1 1 1h16c2.8 0 5.1-2.3 5.1-5.1V3c0-.6-.4-1-1-1m-1 54.9c0 1.7-1.4 3.1-3.1 3.1h-15v-7.3c0-8.1-2.3-16-6.5-22.8l-.9-1.6c-.4-.7-1.2-1.1-2-1.1s-1.6.4-2 1.1l-.9 1.5c-4.3 6.8-6.5 14.7-6.5 22.8V60h-15c-1.7 0-3.1-1.4-3.1-3.1V13.1h55.1v43.8z" />
  </svg>
);
export default IconCastleAlt1;
