import * as React from "react";
const IconIdeaAgenda = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32.3 26.9c-2.8-.1-5.5 1-7.5 3s-3.1 4.6-3.1 7.5c0 3.6 1.8 6.9 4.9 8.8.6.4.9 1.1.9 1.8v1.8c0 2.3 1.8 4.1 4.1 4.1h1c2.3 0 4.1-1.8 4.1-4.1v-1.9c0-.7.4-1.4.9-1.8 3-1.9 4.8-5.1 4.9-8.6.1-2.8-1-5.4-2.9-7.4-2-2-4.6-3.1-7.3-3.2m2.3 22.9c0 1.2-.9 2.1-2.1 2.1h-1c-1.2 0-2.1-.9-2.1-2.1v-2.3h5.2v2.3m1.9-5.3c-.4.3-.8.6-1.1 1h-6.8c-.3-.4-.6-.7-1.1-1-2.5-1.6-4-4.2-4-7.2 0-2.3.9-4.4 2.5-6 1.6-1.5 3.7-2.4 5.9-2.4h.2c2.2.1 4.3 1 5.9 2.7s2.4 3.8 2.3 6c0 2.8-1.4 5.4-3.8 6.9" />
    <path d="M58 8.2H47.5V4.1c0-.6-.4-1-1-1s-1 .4-1 1v4.1h-27V4.1c0-.6-.4-1-1-1s-1 .4-1 1v4.1H6c-2.2 0-4 1.8-4 4v44.7c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V12.2c0-2.2-1.8-4-4-4m-52 2h10.5v2.2c0 .6.4 1 1 1s1-.4 1-1v-2.2h27v2.2c0 .6.4 1 1 1s1-.4 1-1v-2.2H58c1.1 0 2 .9 2 2v8.1H4v-8.1c0-1.1.9-2 2-2m52 48.7H6c-1.1 0-2-.9-2-2V22.3h56V57c0 1-.9 1.9-2 1.9" />
  </svg>
);
export default IconIdeaAgenda;
