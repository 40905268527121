import * as React from "react";
const IconTakaCoin = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 3C16 3 3 16 3 32s13 29 29 29 29-13 29-29S48 3 32 3m0 56C17.1 59 5 46.9 5 32S17.1 5 32 5s27 12.1 27 27-12.1 27-27 27" />
    <path d="M38 34.8c-.5-.2-1.1 0-1.3.5s0 1.1.5 1.3c1.8.7 2.9 2.4 2.9 4.3 0 2.6-2.1 4.7-4.7 4.7s-4.7-2.1-4.7-4.7V28h9.8c.6 0 1-.4 1-1s-.4-1-1-1h-9.8v-5.5c0-2.2-1.8-4-4-4H26c-2.2 0-4 1.8-4 4 0 .6.4 1 1 1s1-.4 1-1c0-1.1.9-2 2-2h.7c1.1 0 2 .9 2 2V26h-4.4c-.6 0-1 .4-1 1s.4 1 1 1h4.4v13a6.7 6.7 0 0 0 13.4 0c0-2.8-1.6-5.2-4.1-6.2" />
  </svg>
);
export default IconTakaCoin;
