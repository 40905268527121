import * as React from "react";
const IconLockAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M43.1 21.5V13c0-3-1.2-5.7-3.2-7.8C37.8 3.2 35 2 32.1 2 26 2 21 7 21 13.1v8.4c-6.4 3.8-10.8 10.8-10.8 18.8C10.2 52.2 20 62 32 62s21.8-9.8 21.8-21.8c0-7.9-4.3-14.9-10.7-18.7M23 13.1c0-5 4.1-9.1 9.1-9.1 2.4 0 4.7.9 6.4 2.7 1.7 1.7 2.7 4 2.7 6.4v7.4c-2.8-1.3-5.9-2-9.1-2s-6.2.7-9 2v-7.4zM32 60c-10.9 0-19.8-8.9-19.8-19.8S21.1 20.4 32 20.4s19.8 8.9 19.8 19.8S42.9 60 32 60" />
    <path d="M32 41.2c-.6 0-1 .4-1 1v8.7c0 .6.4 1 1 1s1-.4 1-1v-8.7c0-.5-.4-1-1-1" />
  </svg>
);
export default IconLockAlt2;
