import * as React from "react";
const IconPyramidIncrease = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M40.5 37.8c-.3-.5-.9-.6-1.4-.2-.5.3-.6.9-.2 1.4l2.1 3H7.2l6.2-8.7h13.3c.6 0 1-.4 1-1s-.4-1-1-1H14.8l9.3-13.1 1.6 2.2c.3.5.9.6 1.4.2.5-.3.6-.9.2-1.4l-2.4-3.4c-.2-.3-.5-.4-.8-.4s-.6.2-.8.4L3 44.5c-1.2 1.7-1.4 3.9-.4 5.8s2.9 3 5 3h33.1c2.1 0 4-1.2 5-3s.8-4.1-.4-5.8zm3.4 11.6c-.6 1.2-1.8 1.9-3.2 1.9H7.6c-1.4 0-2.6-.7-3.2-1.9s-.5-2.6.3-3.7L5.8 44h36.6l1.1 1.6c.9 1.2 1 2.6.4 3.8M49.3 10.7c-7 0-12.7 5.4-12.7 12.1s5.7 12.1 12.7 12.1S62 29.5 62 22.8s-5.7-12.1-12.7-12.1m0 22.2c-5.9 0-10.7-4.5-10.7-10.1s4.8-10.1 10.7-10.1S60 17.2 60 22.8s-4.8 10.1-10.7 10.1" />
    <path d="M50 20.3c-.4-.4-1-.4-1.4 0L45 23.8c-.4.4-.4 1 0 1.4s1 .4 1.4 0l3-2.8 3 2.8c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4z" />
  </svg>
);
export default IconPyramidIncrease;
