import * as React from "react";
const IconMeta = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      
      d="M44.626 12.729c-4.756 0-8.473 3.582-11.839 8.132-4.624-5.889-8.492-8.132-13.12-8.132C10.23 12.729 3 25.008 3 38.007c0 8.134 3.935 13.264 10.526 13.264 4.744 0 8.156-2.236 14.221-12.839 0 0 2.528-4.464 4.268-7.54q.914 1.476 1.925 3.181l2.845 4.785c5.54 9.271 8.627 12.413 14.22 12.413 6.422 0 9.995-5.2 9.995-13.503 0-13.61-7.393-25.04-16.374-25.04M23.122 35.561c-4.918 7.708-6.619 9.436-9.357 9.436-2.817 0-4.492-2.474-4.492-6.884 0-9.437 4.705-19.086 10.314-19.086 3.037 0 5.575 1.754 9.463 7.32-3.692 5.662-5.928 9.214-5.928 9.214m18.56-.97L38.28 28.92a118 118 0 0 0-2.654-4.133c3.065-4.73 5.593-7.088 8.6-7.088 6.247 0 11.244 9.197 11.244 20.494 0 4.306-1.41 6.805-4.333 6.805-2.8 0-4.139-1.85-9.457-10.407"
    />
  </svg>
);
export default IconMeta;
