import * as React from "react";
const IconTimeSettings = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path
      d="M256.6 124.9h.2z"
      style={{
        fill: "none",
        stroke: "#000",
        strokeMiterlimit: 10,
      }}
    />
    <path d="m58.4 37.6-4.1-2.4c-.4-.2-.6-.6-.5-1 .1-.7.1-1.4.1-2.2s0-1.5-.1-2.2c0-.4.2-.8.5-1l4.1-2.4c2.1-1.2 2.8-3.8 1.6-5.9l-4-7c-.6-1-1.5-1.7-2.6-2s-2.3-.2-3.3.4L46 14.3c-.4.2-.8.2-1.2-.1-1.1-.8-2.4-1.6-3.7-2.1-.4-.2-.6-.6-.6-1V6.3c0-2.4-1.9-4.3-4.3-4.3h-8.1c-2.4 0-4.3 1.9-4.3 4.3v4.8c0 .4-.3.8-.6 1-1.4.6-2.6 1.3-3.8 2.2-.3.3-.8.3-1.2.1L14 11.9c-1-.6-2.2-.7-3.3-.4s-2 1-2.6 2l-4.1 7c-1.2 2.1-.5 4.7 1.6 5.9l4.1 2.4c.4.2.6.6.5 1-.1.6-.1 1.3-.1 2.2 0 .8 0 1.5.1 2.2 0 .4-.2.8-.5 1l-4.1 2.4c-2.1 1.2-2.8 3.8-1.6 5.9l4.1 7c.6 1 1.5 1.7 2.6 2s2.3.1 3.3-.4l4.1-2.4c.3-.2.8-.2 1.1.1 1.1.8 2.4 1.6 3.7 2.1.4.2.6.6.6 1v4.8c0 2.4 1.9 4.3 4.3 4.3h8.1c2.4 0 4.3-1.9 4.3-4.3v-4.8c0-.4.3-.8.6-1 1.3-.6 2.6-1.3 3.7-2.2.3-.3.8-.3 1.2-.1l4.1 2.4c1 .6 2.2.7 3.3.4s2-1 2.6-2l4.1-7c1.4-2 .7-4.6-1.4-5.8m-.1 4.9-4.1 7c-.3.5-.8.9-1.4 1.1s-1.2.1-1.8-.2L46.9 48c-1.1-.6-2.4-.5-3.4.2s-2.2 1.4-3.4 1.9c-1.1.5-1.8 1.6-1.8 2.8v4.8c0 1.3-1 2.3-2.3 2.3h-8.1c-1.3 0-2.3-1-2.3-2.3v-4.8c0-1.2-.7-2.3-1.8-2.8-1.2-.5-2.3-1.2-3.4-1.9-.5-.4-1.2-.6-1.8-.6s-1.1.1-1.5.4L13 50.4c-.5.3-1.2.4-1.8.2s-1.1-.5-1.4-1.1l-4.1-7c-.6-1.1-.3-2.5.8-3.2l4.1-2.4c1-.6 1.6-1.8 1.5-3-.1-.6-.1-1.2-.1-1.9 0-.8 0-1.4.1-1.9.1-1.2-.5-2.4-1.5-3l-4.1-2.4c-1.1-.6-1.5-2.1-.9-3.2l4.1-7c.3-.5.8-.9 1.4-1.1s1.2-.1 1.8.2L17 16c1.1.6 2.4.6 3.4-.2 1-.7 2.1-1.4 3.4-1.9 1.1-.5 1.8-1.6 1.8-2.8V6.3c0-1.3 1-2.3 2.3-2.3H36c1.3 0 2.3 1 2.3 2.3v4.8c0 1.2.7 2.3 1.8 2.8 1.2.5 2.3 1.2 3.4 1.9 1 .7 2.3.8 3.4.2l4.1-2.4c.5-.3 1.2-.4 1.8-.2s1.1.5 1.4 1.1l4 7c.6 1.1.3 2.5-.8 3.2l-4.1 2.4c-1 .6-1.6 1.8-1.5 3 .1.6.1 1.2.1 1.9s0 1.4-.1 1.9c-.1 1.2.5 2.4 1.5 3l4.1 2.4c1.1.6 1.5 2.1.9 3.2" />
    <path d="M32 20c-6.6 0-12 5.4-12 12s5.4 12 12 12 12-5.4 12-12-5.4-12-12-12m0 22c-5.5 0-10-4.5-10-10s4.5-10 10-10 10 4.5 10 10-4.5 10-10 10" />
    <path d="M32.4 32.7v-3.5c0-.6-.4-1-1-1s-1 .4-1 1v3.9c0 .3.1.5.3.7l2.3 2.3c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4z" />
  </svg>
);
export default IconTimeSettings;
