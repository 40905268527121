import * as React from "react";
const IconBusinessRocket = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M10.4 55.9c-.6 0-1 .4-1 1V61c0 .6.4 1 1 1s1-.4 1-1v-4.1c0-.5-.4-1-1-1M22.1 52.4c-.6 0-1 .4-1 1V61c0 .6.4 1 1 1s1-.4 1-1v-7.6c0-.5-.5-1-1-1M33.7 47.8c-.6 0-1 .4-1 1V61c0 .6.4 1 1 1s1-.4 1-1V48.8c0-.6-.4-1-1-1M45.4 43.1c-.6 0-1 .4-1 1V61c0 .6.4 1 1 1s1-.4 1-1V44.1c0-.6-.5-1-1-1M55.6 24.2l-3.4-1.9v-3.6c0-9.8-3.1-13.9-6.1-16.5-.4-.3-.9-.3-1.3 0-3 2.6-6.1 6.7-6.1 16.5v3.6l-3.4 1.9c-1.8 1-2.9 2.8-2.9 4.9v5.3c0 .4.2.7.5.9.2.1.3.1.5.1s.3 0 .5-.1l4.8-2.6V34c0 .6.4 1 1 1h11.6c.6 0 1-.4 1-1v-1.3l4.8 2.6c.1.1.3.1.5.1s.4 0 .5-.1c.3-.2.5-.5.5-.9v-5.3c-.1-2.1-1.2-3.9-3-4.9m-21.3 8.5V29c0-1.3.7-2.5 1.8-3.1l2.5-1.3v5.8zm15.9.3h-9.6V18.8c0-8.3 2.2-12 4.8-14.4 2.6 2.5 4.8 6.2 4.8 14.4V33m6.3-.3-4.3-2.3v-5.8l2.5 1.3c1.1.6 1.8 1.8 1.8 3.1zM26.3 36.6l-7.4-2.7c-.5-.2-1.1.1-1.3.6s.1 1.1.6 1.3l5.5 2-17.5 6.6c-.5.2-.8.8-.6 1.3q.3.6.9.6c.1 0 .2 0 .4-.1l17.2-6.5-1.7 4.6c-.2.5.1 1.1.6 1.3.1 0 .2.1.3.1.4 0 .8-.3.9-.7l2.7-7.2c.1-.2.1-.5 0-.8-.1-.1-.3-.3-.6-.4" />
  </svg>
);
export default IconBusinessRocket;
