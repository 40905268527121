import * as React from "react";
const IconCity = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      d="M22 13h-2v2h2zM24 13h2v2h-2zM30 13h-2v2h2zM20 17h2v2h-2zM26 17h-2v2h2zM28 17h2v2h-2zM22 21h-2v2h2zM24 21h2v2h-2zM30 21h-2v2h2zM20 25h2v2h-2zM26 25h-2v2h2zM20 29h2v2h-2zM26 29h-2v2h2zM20 33h2v2h-2zM26 33h-2v2h2zM20 37h2v2h-2zM26 37h-2v2h2zM31 30h3v-2h-3zM34 34h-3v-2h3zM31 38h3v-2h-3z"
    />
    <path
      
      fillRule="evenodd"
      d="m17 4 16 6v14h4a1 1 0 0 1 1 1v17h1a1 1 0 1 1 0 2H9a1 1 0 1 1 0-2h1V21a1 1 0 0 1 1-1h2v-7h2v7h2zm2 2.886 12 4.5V24h-3a1 1 0 0 0-1 1v17h-8zM12 22v20h5V22zm24 20h-2v-2h-3v2h-2V26h7z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconCity;
