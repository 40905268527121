import * as React from "react";
const IconHalfTime = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
    <path d="M32 15.8c-8.9 0-16.2 7.3-16.2 16.2S23.1 48.2 32 48.2c.6 0 1-.4 1-1V16.8c0-.6-.4-1-1-1m-1 30.4c-7.4-.5-13.2-6.7-13.2-14.2S23.6 18.3 31 17.8z" />
  </svg>
);
export default IconHalfTime;
