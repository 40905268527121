import * as React from "react";
const IconPlaceholderDollar = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2C18.7 2 7.9 12.8 7.9 26.1c0 18.8 22.5 35.1 23.5 35.7.2.1.4.2.6.2s.4-.1.6-.2c1-.7 23.5-16.9 23.5-35.7C56.1 12.8 45.3 2 32 2m0 57.8C28.5 57.1 9.9 42.3 9.9 26.1 9.9 13.9 19.8 4 32 4s22.1 9.9 22.1 22.1c0 16.2-18.6 31-22.1 33.7" />
    <path d="M30.2 20.4H38c.6 0 1-.4 1-1s-.4-1-1-1h-3.8v-2.6c0-.6-.4-1-1-1s-1 .4-1 1v2.6h-2c-2.9 0-5.2 2.3-5.2 5.2s2.3 5.2 5.2 5.2h3.6c1.8 0 3.2 1.4 3.2 3.2s-1.4 3.2-3.2 3.2H26c-.6 0-1 .4-1 1s.4 1 1 1h6.2v2.6c0 .6.4 1 1 1s1-.4 1-1v-2.6c2.7-.2 4.8-2.4 4.8-5.1 0-2.9-2.3-5.2-5.2-5.2h-3.6c-1.8 0-3.2-1.4-3.2-3.2s1.4-3.3 3.2-3.3" />
  </svg>
);
export default IconPlaceholderDollar;
