import * as React from "react";
const IconNewMoon = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.9 43.1C61.2 39.6 62 35.9 62 32c0-9.7-4.6-18.3-11.7-23.8l-.2-.2c-5-3.7-11.3-6-18.1-6C15.5 2 2 15.5 2 32c0 10 4.9 18.8 12.4 24.3.1.1.1.1.2.1C19.5 59.9 25.5 62 32 62c12.6 0 23.4-7.8 27.8-18.8zM4.2 35.2l52.9-15.5c1.5 3 2.5 6.4 2.8 9.9L7.2 45c-1.6-3-2.6-6.3-3-9.8m51.9-17.4L4 33.1V32c0-3.2.5-6.2 1.5-9.1l43.9-12.8c2.7 2.1 5 4.8 6.7 7.7M32 4c5.7 0 10.9 1.7 15.3 4.6l-40.9 12C10.8 10.8 20.6 4 32 4M8.3 46.8 60 31.6v.4q0 5.25-1.8 9.9L15.3 54.4c-2.8-2-5.2-4.6-7-7.6M32 60c-5.3 0-10.3-1.5-14.6-4.1l39.8-11.7C52.6 53.6 43.1 60 32 60" />
  </svg>
);
export default IconNewMoon;
