import * as React from "react";
const IconListAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M13.8 9.1H5c-1.7 0-3 1.3-3 3v8.8c0 1.7 1.3 3 3 3h8.8c1.7 0 3-1.3 3-3v-8.8c0-1.7-1.4-3-3-3m1 11.7c0 .6-.4 1-1 1H5c-.6 0-1-.4-1-1V12c0-.6.4-1 1-1h8.8c.6 0 1 .4 1 1zM13.8 40.2H5c-1.7 0-3 1.3-3 3V52c0 1.7 1.3 3 3 3h8.8c1.7 0 3-1.3 3-3v-8.8c0-1.7-1.4-3-3-3m1 11.7c0 .6-.4 1-1 1H5c-.6 0-1-.4-1-1v-8.8c0-.6.4-1 1-1h8.8c.6 0 1 .4 1 1zM28.6 13H61c.6 0 1-.4 1-1s-.4-1-1-1H28.6c-.6 0-1 .4-1 1s.4 1 1 1M61 19.9H28.6c-.6 0-1 .4-1 1s.4 1 1 1H61c.6 0 1-.4 1-1s-.4-1-1-1M61 42.1H28.6c-.6 0-1 .4-1 1s.4 1 1 1H61c.6 0 1-.4 1-1s-.4-1-1-1M61 51H28.6c-.6 0-1 .4-1 1s.4 1 1 1H61c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconListAlt1;
