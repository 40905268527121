import * as React from "react";
const IconBrainAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62 34.7c0-2.4-.9-4.6-2.5-6.4.1-.7.2-1.3.2-2 0-4.3-2.9-7.9-7.1-8.9-1.1-4-4.7-6.8-8.9-6.8-1.6 0-3.1.4-4.4 1.2-1.9-2.1-4.6-3.4-7.4-3.4s-5.5 1.2-7.3 3.3c-1.3-.8-2.9-1.1-4.4-1.1-4.2 0-7.8 2.8-8.9 6.8-4.1 1-7 4.6-7 8.9 0 .9.2 1.9.5 2.9-3.1 3.4-3.6 7.7-1.4 11.3 1.9 3 5.4 4.7 9.1 4.4 1-.1 1.9-.2 2.6-.3 1.5-.2 3 .4 3.9 1.6 1.8 2.2 4.4 3.5 7.3 3.5 3.8 0 7.6.8 11.1 2.3l2.5 1.1q5.1 2.25 9.9 2.4h.8c2.5 0 4.9-1 6.6-2.7s2.7-3.9 2.7-6.2c0-1.7-.5-3.4-1.4-4.8 2.2-1.7 3.5-4.3 3.5-7.1m-6.1 16.8c-1.4 1.4-3.4 2.1-5.4 2.1h-.6c-3-.1-6.1-.9-9.2-2.3l-2.5-1.1c-3.8-1.7-7.8-2.5-12-2.5-2.2 0-4.3-1-5.6-2.8-1.3-1.7-3.6-2.6-5.8-2.3q-1.05.15-2.4.3c-3 .2-5.8-1.2-7.3-3.5-1.8-2.8-1.3-6.1 1.2-8.9.5-.5.6-1.2.4-1.9-.2-.8-.4-1.6-.4-2.3 0-3.3 2.3-6.2 5.5-7 .7-.2 1.3-.7 1.5-1.4.8-3.1 3.7-5.3 7-5.3 1.2 0 2.4.3 3.4.9.8.4 1.8.3 2.4-.4 1.5-1.7 3.6-2.6 5.9-2.6 2.2 0 4.4 1 5.9 2.7.6.7 1.6.9 2.4.4 1.1-.6 2.3-.9 3.5-.9 3.3 0 6.1 2.2 7 5.3.2.7.8 1.3 1.5 1.5 3.2.8 5.5 3.6 5.5 7 0 .5-.1 1.1-.2 1.6-.1.7 0 1.4.5 1.8 1.3 1.3 2 3.1 2 4.9 0 2.2-1 4.2-2.7 5.6-.8.6-1 1.7-.4 2.6.7 1.1 1.1 2.4 1.1 3.8-.2 1.7-.9 3.4-2.2 4.7" />
    <path d="M32.2 19c-.3-.1-3.3-.8-4.7.8-.7.8-.9 2-.3 3.4.1.4.5.7.9.7.1 0 .2 0 .3-.1.5-.2.8-.8.6-1.3-.2-.7-.3-1.2-.1-1.4.4-.5 1.9-.4 2.8-.2.5.1 1.1-.2 1.2-.7.2-.5-.1-1-.7-1.2M46.5 29.6c.7-.2 1.2-.3 1.4-.1.5.4.4 1.9.2 2.8-.1.5.2 1.1.7 1.2h.2c.5 0 .9-.3 1-.8.1-.3.8-3.3-.8-4.7-.8-.7-2-.9-3.4-.4-.5.2-.8.8-.6 1.3.2.6.8.8 1.3.7M49.2 42.5c-.5.2-.8.8-.6 1.3.2.7.3 1.2.1 1.4-.4.5-1.9.4-2.8.2-.5-.1-1.1.2-1.2.7s.2 1.1.7 1.2c.2 0 .9.2 1.9.2s2.1-.2 2.9-1c.7-.8.9-2 .3-3.4-.2-.5-.8-.8-1.3-.6M16.7 31.7c-.3.2-2.9 1.8-2.8 3.9.1 1.1.8 2 2.2 2.7.1.1.3.1.4.1q.6 0 .9-.6c.2-.5 0-1.1-.5-1.3-.7-.3-1-.7-1.1-1 0-.6 1-1.6 1.8-2.1.5-.3.6-.9.3-1.4-.1-.5-.8-.6-1.2-.3" />
  </svg>
);
export default IconBrainAlt;
