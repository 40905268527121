import * as React from "react";
const IconBuyHouse = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 54.8h-3.9v-8l1.2 1c.4.4 1.1.3 1.4-.1.4-.4.3-1.1-.1-1.4L49.8 38c-.4-.3-.9-.3-1.3 0l-7 5.9-2.8 2.3c-.4.4-.5 1-.1 1.4.2.2.5.4.8.4.2 0 .5-.1.6-.2l1.2-1v8H22.9v-8l1.2 1c.2.2.4.2.6.2.3 0 .6-.1.8-.4.4-.4.3-1.1-.1-1.4L15.5 38c-.4-.3-.9-.3-1.3 0l-7 5.9-2.8 2.3c-.4.4-.5 1-.1 1.4s1 .5 1.4.1l1.2-1v8H3c-.6 0-1 .4-1 1s.4 1 1 1h57.99999999999999c.6 0 1-.4 1-1s-.4-.9-1-.9m-52.1 0v-9.7l6-5 6 5v9.7zm34.2 0v-9.7l6-5 6 5v9.7zM35.1 25.6H28c-.6 0-1 .4-1 1s.4 1 1 1h5.5v2.2c0 .6.4 1 1 1s1-.4 1-1v-2.3c2.4-.2 4.4-2.3 4.4-4.7 0-2.6-2.2-4.8-4.8-4.8h-3.3c-1.5 0-2.8-1.2-2.8-2.8s1.3-2.8 2.8-2.8h7.1c.6 0 1-.4 1-1s-.4-1-1-1h-3.4V8.2c0-.6-.4-1-1-1s-1 .4-1 1v2.2h-1.7c-2.7 0-4.8 2.1-4.8 4.8s2.2 4.8 4.8 4.8h3.3c1.5 0 2.8 1.2 2.8 2.8 0 1.5-1.3 2.8-2.8 2.8" />
  </svg>
);
export default IconBuyHouse;
