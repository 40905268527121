import React, { useState } from 'react';
import { useNavigation } from 'context/NavigationContext';
import {
    BringzzPageContainer,
    BringzzButton, BringzzIcon, BringzzText, BringzzInput
} from "@bringzz/components";
import useUser from 'hooks/useUser';
import useRequest from 'hooks/useRequest';
import { useAuth } from 'context/AuthContext';

const EditPharmacyProfile = () => {
    const { backPress, navigate } = useNavigation();
    const { sendRequest, loading } = useRequest();
    const { user } = useAuth()
    const [pharmacyNameValue, setPharmacyNameVAlue] = useState('');
    const [pharmacyAtuValue, setPharmacyAtuValue] = useState('');
    const [pharmacyBioValue, setPharmacyBioValue] = useState('');

    return (
        <BringzzPageContainer
            header={{
                left: <BringzzIcon
                    size="22"
                    icon="IconChevronLeft"
                    folder="LineIcons"
                    className="cursor-pointer"
                    onClick={backPress}
                />,
                title: <BringzzText tag='h2'>Pharmacy Profile</BringzzText>,
            }}
            footer={
                <div className='pb-28 px-6'>
                    <BringzzButton
                        size='lg'
                        className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
                        onClick={async () => {
                            await sendRequest({
                                method: 'PATCH',
                                url: `/pharmacy/info/profile`,
                                data: {
                                    pharmacyname: pharmacyNameValue,
                                    atu: pharmacyAtuValue,
                                    pharmacyBio: pharmacyBioValue
                                }
                            }).then((response) => {
                                if (response.status === 'success')
                                    navigate(`/settings/pharmacy/info`, false);
                            }).catch((error) => {
                                console.log(error);
                            })
                        }}
                        disabled={loading || (!pharmacyNameValue && !pharmacyAtuValue && !pharmacyBioValue)}
                    >
                        Save
                    </BringzzButton>
                </div>
            }
        >
            <div className='p-6 space-y-4'>
                <BringzzInput id='pharmacyname' label="Pharmacy Name" containerClassname="w-full" placeholder="Pharmacy Name"
                    defaultValue={user.pharmacy.pharmacyname}
                    onChange={(e, isValid, newValue) => {
                        setPharmacyNameVAlue(newValue);
                    }}
                />
                <BringzzInput id='atu' label="Tax ID / ATU" containerClassname="w-full" placeholder="Tax ID / ATU"
                    defaultValue={user.pharmacy.atu}
                    onChange={(e, isValid, newValue) => {
                        setPharmacyAtuValue(newValue);
                    }}
                />
                <BringzzInput onChange={(e, isValid, newValue) => {
                    setPharmacyBioValue(newValue);
                }} id='bio' type='textarea' label="Pharmacy Bio" className='h-72' containerClassname="w-full" placeholder='Pharmacy Bio' defaultValue={user.pharmacy.pharmacyBio} />
            </div>
        </BringzzPageContainer>
    );
}

export default EditPharmacyProfile;
