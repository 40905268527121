import * as React from "react";
const IconTravelCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62 44.3c0-5-3.9-8.8-8.8-8.8s-8.9 4-8.9 8.8c0 3.7 4.7 11 7 14.4H18.1c-1.8 0-3.5-.7-4.8-2-1.7-1.7-2.3-4-1.9-6.4.6-3.1 3.6-5.4 7-5.4h10.2c2.4 0 4.6-.9 6.3-2.6 2.5-2.5 3.2-6 2.1-9.3-1.2-3.5-4.6-5.9-8.6-5.9H12.8c2.2-3.4 7-11.2 7-14.9 0-5-3.9-8.8-8.8-8.8-5-.1-9 3.9-9 8.7 0 4.5 7.3 15.2 8.1 16.4l.1.1c.1.1.2.1.3.2h.1c.1 0 .2.1.3.1h17.6c3 0 5.8 1.9 6.7 4.5s.3 5.4-1.6 7.3c-1.3 1.3-3 2-4.8 2H18.5c-4.4 0-8.2 3-9 7.1-.6 3 .3 6 2.4 8.2 1.7 1.7 3.9 2.6 6.3 2.6h35c.1 0 .2 0 .3-.1h.1c.1 0 .2-.1.3-.2v-.1l.1-.1c.8-1 8-11.2 8-15.8m-51.1-39c3.8 0 6.8 3 6.8 6.8 0 2.9-4.4 10.3-6.9 14.1C8.4 22.4 4 15.1 4 12.1c0-3.7 3.1-6.8 6.9-6.8m42.3 32.2c3.8 0 6.8 3 6.8 6.8 0 2.9-4.4 10-6.9 13.6-2.5-3.6-6.9-10.7-6.9-13.6 0-3.7 3.1-6.8 7-6.8" />
    <path d="M55.7 43.6c0-1.5-1.2-2.7-2.6-2.7-1.5 0-2.6 1.2-2.6 2.7s1.2 2.7 2.6 2.7c1.5-.1 2.6-1.3 2.6-2.7M13.5 11.4c0-1.5-1.2-2.7-2.6-2.7s-2.6 1.2-2.6 2.7S9.4 14 10.9 14s2.6-1.2 2.6-2.6" />
  </svg>
);
export default IconTravelCopy;
