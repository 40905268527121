import * as React from "react";
const IconBoombox = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.1 22.2V16c0-3.9-3.1-7-7-7H14.9c-3.9 0-7 3.1-7 7v6.3c-2.7.1-4.9 2.3-4.9 5V50c0 2.8 2.2 5 5 5h48c2.8 0 5-2.2 5-5V27.2c0-2.7-2.2-4.9-4.9-5M14.9 11h34.3c2.8 0 5 2.2 5 5v6.3h-8.3v-2.7c0-.6-.4-1-1-1s-1 .4-1 1v2.7H33v-2.7c0-.6-.4-1-1-1s-1 .4-1 1v2.7H20.2v-2.7c0-.6-.4-1-1-1s-1 .4-1 1v2.7H9.9V16c0-2.8 2.2-5 5-5M59 50c0 1.7-1.3 3-3 3H8c-1.7 0-3-1.3-3-3V27.2c0-1.7 1.3-3 3-3h48c1.7 0 3 1.3 3 3z" />
    <path d="M18.9 31c-4.1 0-7.5 3.4-7.5 7.5s3.4 7.5 7.5 7.5 7.5-3.4 7.5-7.5-3.3-7.5-7.5-7.5m0 13c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5 5.5 2.5 5.5 5.5S22 44 18.9 44M45.1 31c-4.1 0-7.5 3.4-7.5 7.5S41 46 45.1 46s7.5-3.4 7.5-7.5-3.4-7.5-7.5-7.5m0 13c-3 0-5.5-2.5-5.5-5.5S42 33 45.1 33s5.5 2.5 5.5 5.5-2.5 5.5-5.5 5.5" />
  </svg>
);
export default IconBoombox;
