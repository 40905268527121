import * as React from "react";
const IconCornerLeftUp = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60 57.8H28.1c-3.5 0-6.3-2.8-6.3-6.3V7.6l15.1 15.1c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4L21.5 4.5c-.4-.4-1-.4-1.4 0L3.3 21.3c-.4.4-.4 1 0 1.4s1 .4 1.4 0L19.8 7.6v43.9c0 4.6 3.7 8.3 8.3 8.3H60c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconCornerLeftUp;
