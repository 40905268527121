import * as React from "react";
const IconSecureTime = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.4 9.6 33.7 2.3c-1.1-.4-2.4-.4-3.5 0L10.6 9.6c-2 .7-3.3 2.6-3.3 4.7v18c0 20.9 20.3 28.3 24.4 29.6h.6c4.1-1.2 24.4-8.5 24.4-29.6v-18c0-2.1-1.3-4-3.3-4.7m1.3 22.7C54.7 51.6 36.4 58.5 32 60c-4.4-1.4-22.7-8.6-22.7-27.6v-18c0-1.3.8-2.4 2-2.8l19.6-7.3c.4-.2.7-.3 1.1-.3s.7.1 1.1.2l19.6 7.3c1.2.4 2 1.6 2 2.8z" />
    <path d="M32 16.9c-8.2 0-14.9 6.7-14.9 14.9s6.7 15 14.9 15 14.9-6.7 14.9-14.9-6.7-15-14.9-15m0 27.9c-7.1 0-12.9-5.8-12.9-12.9S24.9 19 32 19s12.9 5.8 12.9 12.9S39.1 44.8 32 44.8" />
    <path d="M32.3 32.9v-4.5c0-.6-.4-1-1-1s-1 .4-1 1v4.9c0 .3.1.5.3.7l2.9 2.9c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4z" />
  </svg>
);
export default IconSecureTime;
