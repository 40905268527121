import React from 'react';
import BringzzText from "../../BringzzText";
import BringzzIcon from "../../BringzzIcon";
import classnames from 'classnames';
import PropTypes from 'prop-types';

const BringzzListItem = ({ item, type, handleBookmarkClick }) => {
    return (
        <div className={classnames("flex h-20 items-center p-4 relative overflow-hidden border-b cursor-pointer")}>
            <div className={`flex w-full pr-2`}>
                <div className="flex-none w-14 h-14 rounded-full bg-gray-200 mr-4"></div>
                <div className="flex-grow flex flex-col justify-center">
                    <BringzzText
                        tag='h4'
                        className={classnames("font-bold", {
                            'line-clamp-1': item.body,
                            'line-clamp-2': !item.body,
                        })}
                    >
                        {item.title || item.name}
                    </BringzzText>
                    {item.body && (
                        <BringzzText tag='h5' className="font-normal italic line-clamp-1">{item.body}</BringzzText>
                    )}
                    {item.subTitle && <BringzzText tag='h5' className="font-normal text-midnight-blue/60">{item.subTitle}</BringzzText>}
                </div>
            </div>
            {type == "notifications" && item.enabled && (
                <div className="absolute right-4 w-2 h-2 bg-magic-lilac rounded-full"></div>
            )}
            {type == "bookmarks" &&
                <div onClick={handleBookmarkClick}>
                    {item.enabled ?
                        <BringzzIcon
                            icon='IconBookmark'
                            folder='LineIcons'
                            className='text-magic-lilac'
                            size='24'
                        ></BringzzIcon>
                        :
                        <BringzzIcon
                            icon='IconBookmark'
                            folder='LineIcons'
                            className='text-magic-lilac'
                            size='24'
                        ></BringzzIcon>}
                </div>
            }
        </div>
    );
};

BringzzListItem.propTypes = {
    item: PropTypes.shape({
        title: PropTypes.string.isRequired,
        body: PropTypes.string,
        subTitle: PropTypes.string,
        enabled: PropTypes.bool.isRequired,
        onClick: PropTypes.func
    }).isRequired,
    type: PropTypes.oneOf(['notifications', 'bookmarks']).isRequired,
};

export default BringzzListItem;
