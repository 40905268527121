import * as React from "react";
const IconSaleSign = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M15.2 26.8c.6 0 1-.4 1-1s-.4-1-1-1H9.3c-.6 0-1 .4-1 1v6.6c0 .6.4 1 1 1h4.9v3.9H9.3c-.6 0-1 .4-1 1s.4 1 1 1h5.9c.6 0 1-.4 1-1v-5.9c0-.6-.4-1-1-1h-4.9v-4.6z" />
    <path d="M57.3 13.9H6.7C4.1 13.9 2 16 2 18.6v26.7C2 47.9 4.1 50 6.7 50h50.6c2.6 0 4.7-2.1 4.7-4.7V18.6c0-2.6-2.1-4.7-4.7-4.7M60 45.4c0 1.5-1.2 2.7-2.7 2.7H6.7c-1.5 0-2.7-1.2-2.7-2.7V18.6c0-1.5 1.2-2.7 2.7-2.7h50.6c1.5 0 2.7 1.2 2.7 2.7z" />
    <path d="M42.5 37.8h-5.7v-12c0-.6-.4-1-1-1s-1 .4-1 1v13c0 .6.4 1 1 1h6.7c.6 0 1-.4 1-1s-.4-1-1-1M53.1 26.8c.6 0 1-.4 1-1s-.4-1-1-1h-5c-.6 0-1 .4-1 1v12.6c0 .6.4 1 1 1h5c.6 0 1-.4 1-1s-.4-1-1-1h-4v-4.3h1.5c.6 0 1-.4 1-1s-.4-1-1-1h-1.5v-4.3zM29.1 24.8h-6.7c-.6 0-1 .4-1 1v13c0 .6.4 1 1 1s1-.4 1-1v-6.1h4.7v6.1c0 .6.4 1 1 1s1-.4 1-1V25.9c0-.6-.4-1.1-1-1.1m-5.7 2h4.7v3.8h-4.7z" />
  </svg>
);
export default IconSaleSign;
