import * as React from "react";
const IconArrowsMergeAltH = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M18.1 19.7c-.4-.4-1-.5-1.4-.1s-.5 1-.1 1.4L25 31H5V20.7c0-.6-.4-1-1-1s-1 .4-1 1v22.5c0 .6.4 1 1 1s1-.4 1-1V33h20l-8.4 10c-.4.4-.3 1.1.1 1.4.2.2.4.2.6.2.3 0 .6-.1.8-.4l9.8-11.7c.3-.4.3-.9 0-1.3zM60 19.7c-.6 0-1 .4-1 1V31H39l8.4-10c.4-.4.3-1.1-.1-1.4s-1.1-.3-1.4.1l-9.8 11.7c-.3.4-.3.9 0 1.3l9.8 11.7c.2.2.5.4.8.4.2 0 .5-.1.6-.2.4-.4.5-1 .1-1.4L39 33h20v10.3c0 .6.4 1 1 1s1-.4 1-1V20.7c0-.5-.4-1-1-1" />
  </svg>
);
export default IconArrowsMergeAltH;
