import * as React from "react";
const IconBirthdayHat = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.6 56.4 47.2 34 35.7 13.3c1.5-1.1 2.6-3 2.6-5C38.3 4.8 35.5 2 32 2s-6.3 2.8-6.3 6.3c0 2.1 1 3.9 2.6 5L4.4 56.4c-.6 1.2-.6 2.6 0 3.7S6.3 62 7.7 62h48.6c1.3 0 2.5-.7 3.2-1.8.7-1.2.8-2.6.1-3.8M20 32.4l19.8-7.6 5 9-32.7 12.9zM32 4c2.4 0 4.3 1.9 4.3 4.3s-1.9 4.3-4.3 4.3-4.3-1.9-4.3-4.3S29.6 4 32 4m0 10.5c.7 0 1.3-.1 1.9-.3l4.9 8.8-17.3 6.6 8.6-15.5c.6.3 1.2.4 1.9.4M6.2 59.1c-.3-.5-.3-1.2 0-1.7l4.4-8 35.2-13.8 4.8 8.6L12.2 60H7.7q-1.05 0-1.5-.9m51.6 0q-.45.9-1.5.9H17.5l34-14 6.3 11.4c.4.6.4 1.2 0 1.7" />
  </svg>
);
export default IconBirthdayHat;
