import * as React from "react";
const IconHairCurler = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.4 48.4 24.5 22.8c-.2-1.8-.9-3.6-2.3-5-2.8-2.8-7.2-3.1-10.4-.9-.6-1.6-1.4-4.6-.2-7.3C12.7 6.9 15.6 5 20 4c.5-.1.9-.7.7-1.2-.1-.5-.7-.9-1.2-.7-5.1 1.2-8.4 3.4-9.9 6.7-1.7 3.9-.3 8 .4 9.6-2.7 3.2-2.5 8 .5 10.9 1.4 1.4 3.2 2.2 5.1 2.3l26.4 30c.2.2.5.3.8.3.2 0 .5-.1.7-.2.4-.4.5-1 .1-1.4L18.2 31.4c1.4-.3 2.8-1 3.9-2.2 1.1-1.1 1.9-2.5 2.2-4l29.8 24.6c.2.2.4.2.6.2.3 0 .6-.1.8-.4.4-.3.3-.9-.1-1.2M20.7 27.9c-1.2 1.2-2.7 1.8-4.3 1.8H16c-1.4-.1-2.9-.7-4-1.8-2.4-2.4-2.4-6.3 0-8.7 1.2-1.2 2.8-1.8 4.3-1.8s3.1.6 4.3 1.8c1.1 1.1 1.7 2.5 1.8 4v.4c.1 1.5-.5 3.1-1.7 4.3" />
  </svg>
);
export default IconHairCurler;
