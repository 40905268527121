import * as React from "react";
const IconTruckBox1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m16.3 32.4 9.5-4.8c1.1-.6 1.8-1.7 1.8-2.9v-11c0-1.3-.8-2.5-1.9-3l-9.5-4.4c-.9-.4-1.9-.4-2.7 0L4 10.7c-.6.3-1.1.7-1.4 1.3-.4.5-.6 1-.6 1.7v11c0 1.3.7 2.4 1.8 2.9l9.5 4.8q.75.3 1.5.3c.75 0 1 0 1.5-.3M9.2 10.5l9.3 4.6-3.7 1.8-9.5-4.6zm15.7 15.4-9.1 4.6V18.6l3.9-1.9v3.6c0 .6.4 1 1 1s1-.4 1-1v-4.6l3.9-1.9v10.9c0 .5-.2.9-.7 1.2M14.3 8.1c.2 0 .3-.1.5-.1s.4 0 .5.1l8.9 4.1-3.4 1.8-9.2-4.6zM4.7 25.9c-.4-.3-.7-.7-.7-1.2V13.8l9.8 4.8v11.8zM61.8 39.7l-6-8.6c-.2-.3-.5-.4-.8-.4h-9.9V28c0-2.5-2.1-4.6-4.6-4.6h-6.7c-.6 0-1 .4-1 1s.4 1 1 1h6.7c1.4 0 2.6 1.2 2.6 2.6v24.8h-9.3c-.3-2.3-2.2-4.1-4.5-4.1s-4.3 1.8-4.5 4.1h-6.4c-1.4 0-2.6-1.2-2.6-2.6V38.8c0-.6-.4-1-1-1s-1 .4-1 1v11.5c0 2.5 2.1 4.6 4.6 4.6H25c.6 1.8 2.3 3.1 4.3 3.1s3.7-1.3 4.3-3.1h14.1C48.3 56.7 50 58 52 58s3.7-1.3 4.3-3.1H61c.6 0 1-.4 1-1V40.3c0-.2-.1-.4-.2-.6m-7.3-7.1 5 7.2H45.2v-7.2zM29.3 56c-1.4 0-2.6-1.2-2.6-2.6s1.2-2.6 2.6-2.6 2.6 1.2 2.6 2.6-1.2 2.6-2.6 2.6M52 56c-1.4 0-2.6-1.2-2.6-2.6s1.2-2.6 2.6-2.6 2.6 1.2 2.6 2.6S53.4 56 52 56m4.5-3.1c-.3-2.3-2.2-4.1-4.5-4.1s-4.3 1.8-4.5 4.1h-2.3v-11H60v11z" />
  </svg>
);
export default IconTruckBox1;
