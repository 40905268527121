import * as React from "react";
const IconSelectArrow = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M12.6 24.5 32 5.4l19.4 19.1c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4L32.7 3.3c-.4-.4-1-.4-1.4 0L11.2 23.1c-.4.4-.4 1 0 1.4.3.4 1 .4 1.4 0M51.4 39.5 32 58.6 12.6 39.5c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l20.1 19.8c.2.2.4.3.7.3s.5-.1.7-.3l20.1-19.8c.4-.4.4-1 0-1.4-.3-.4-1-.4-1.4 0" />
  </svg>
);
export default IconSelectArrow;
