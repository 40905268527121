import * as React from "react";
const IconChristmasStar = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.1 31.5c-.6-1.8-2.1-3.1-4-3.5l-8.9-1.7c-1.5-.3-2.7-1.2-3.4-2.5l-4.3-7.9c-.4-.7-.9-1.3-1.6-1.8 2.1-1.1 3.5-3.2 3.5-5.7C38.4 4.9 35.5 2 32 2s-6.4 2.9-6.4 6.4c0 2.5 1.4 4.6 3.5 5.7-.6.4-1.2 1-1.6 1.8l-4.3 7.9c-.7 1.3-1.9 2.2-3.4 2.5L10.9 28c-1.9.4-3.4 1.7-4 3.5s-.2 3.8 1.2 5.2l6.2 6.6c1 1.1 1.5 2.5 1.3 4l-1.2 9c-.2 1.9.5 3.7 2.1 4.9.9.7 2 1 3 1 .8 0 1.5-.2 2.2-.5l8.2-3.9c1.3-.6 2.9-.6 4.2 0l8.2 3.9c1.7.8 3.7.6 5.3-.5s2.3-3 2.1-4.9l-1.2-9c-.2-1.5.3-2.9 1.3-4l6.2-6.6c1.3-1.5 1.7-3.4 1.1-5.2M27.6 8.4C27.6 6 29.6 4 32 4s4.4 2 4.4 4.4-2 4.4-4.4 4.4c-2.4.1-4.4-1.9-4.4-4.4m26.9 26.9-6.2 6.6c-1.4 1.5-2.1 3.6-1.8 5.6l1.2 9c.2 1.2-.3 2.3-1.3 3s-2.2.8-3.2.3l-8.2-4c-1.9-.9-4-.9-5.9 0l-8.2 3.9c-1.1.5-2.3.4-3.2-.3-1-.7-1.4-1.8-1.3-3l1.2-9c.3-2.1-.4-4.1-1.8-5.6l-6.2-6.6c-.8-.9-1.1-2-.7-3.2.4-1.1 1.3-1.9 2.4-2.1l8.9-1.7c2.1-.4 3.8-1.7 4.8-3.5l4.3-7.9c.6-1 1.6-1.7 2.8-1.7s2.2.6 2.8 1.7l4.3 7.9c1 1.8 2.7 3.1 4.8 3.5l8.9 1.7c1.2.2 2.1 1 2.4 2.1.3 1.2 0 2.4-.8 3.3" />
  </svg>
);
export default IconChristmasStar;
