import * as React from "react";
const IconBuildingsAlt5 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.9 23.4H47.4v-5.7l1.8-9.9c.3-1.4-.1-2.9-1.1-4S45.8 2 44.3 2H26.9c-1.5 0-2.8.6-3.8 1.8-.9 1.1-1.3 2.6-1.1 4l1.8 9.9v14.8H6.1c-2.2 0-4 1.8-4 4V58c0 2.2 1.8 4 4 4h51.7c2.2 0 4-1.8 4-4V27.4c.1-2.2-1.7-4-3.9-4M24.7 5c.6-.7 1.4-1 2.2-1h17.4c.9 0 1.7.4 2.2 1q.9 1.05.6 2.4l-1.6 9.2H25.7L24 7.4c-.1-.8.1-1.7.7-2.4M4.1 58V36.5c0-1.1.9-2 2-2h17.7V60H6.1c-1.1 0-2-.9-2-2m21.7-24.5V18.6h19.5V60H25.8zM59.9 58c0 1.1-.9 2-2 2H47.4V25.4h10.5c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconBuildingsAlt5;
