import * as React from "react";
const IconArrowDoubleDown = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M42.4 47.2 33 56.9V37.6c0-.6-.4-1-1-1s-1 .4-1 1v19.2l-9.4-9.6c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4L31.3 60c.2.2.4.3.7.3s.5-.1.7-.3l11.1-11.4c.4-.4.4-1 0-1.4s-1-.4-1.4 0M31.2 27.1c.2.2.4.3.7.3s.5-.1.7-.3l11.2-11.4c.4-.4.4-1 0-1.4s-1-.4-1.4 0L33 23.9V4.7c0-.6-.4-1-1-1s-1 .4-1 1V24l-9.4-9.7c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4z" />
  </svg>
);
export default IconArrowDoubleDown;
