import * as React from "react";
const IconWorldAltCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.6 19.2c-1.3-3.6-3.9-6.4-7.3-8-3.3-1.5-7-1.7-10.4-.6l-2.5-6.8c-.3-.7-.8-1.3-1.5-1.6q-1.05-.45-2.1 0C15.3 7.4 9.7 20.9 14 32.8c3.2 8.7 10.9 14.6 19.6 15.8V60h-6.9c-.6 0-1 .4-1 1s.4 1 1 1h15.7c.6 0 1-.4 1-1s-.4-1-1-1h-6.8V48.8h1.2c2.1 0 4.3-.3 6.5-.9.7-.2 1.3-.7 1.6-1.3.3-.7.4-1.5.1-2.2l-2.6-7.2c7.2-2.8 10.8-10.8 8.2-18m-7.4 26.5c0 .1-.2.3-.4.3-11.1 3.1-22.9-3-26.9-13.9S17.1 8.8 27.6 4c.2-.1.4 0 .5 0 .2.1.3.2.4.4l2.5 6.9c-2.9 1.4-5.2 3.8-6.6 6.7-1.6 3.4-1.8 7.3-.5 10.8 1.3 3.6 3.9 6.4 7.3 8 1.9.9 3.9 1.3 6 1.3 1.1 0 2.2-.1 3.3-.4l2.6 7.2c.2.4.2.6.1.8m-1.8-10.2c-3.1 1.1-6.4 1-9.3-.4s-5.2-3.8-6.3-6.9-1-6.4.4-9.3 3.8-5.2 6.9-6.3c1.4-.5 2.8-.7 4.2-.7 1.8 0 3.5.4 5.1 1.1 2.9 1.4 5.2 3.8 6.3 6.9 2.3 6.3-1 13.3-7.3 15.6" />
  </svg>
);
export default IconWorldAltCopy;
