import * as React from "react";
const IconMedicalCall = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.3 45.5 47 39.9c-1.5-1-3.5-.5-4.7 1.2l-2.1 3c-.3.5-1 .7-1.5.3-11.3-7.1-18.3-16.9-20.8-20.8-.3-.5-.2-1.2.3-1.6l3.4-2.3c1.4-1 1.8-3 .8-4.4L16.8 7c-.9-1.4-2.8-1.8-4.3-1l-7.9 4.7c-.1 0-.1.1-.2.2-5.9 5.9-.2 20.9 13 34.1 6.2 6.2 13.1 11 19.4 13.5 3.1 1.2 5.9 1.8 8.3 1.8 2.7 0 4.9-.8 6.4-2.3l.2-.2 4.7-7.9c.7-1.6.3-3.5-1.1-4.4m-.8 3.3-4.6 7.8c-2.3 2.2-6.9 2.2-12.5 0-6-2.4-12.7-7-18.7-13C6.8 31.6.9 17.4 5.7 12.3l7.8-4.6c.6-.3 1.3-.2 1.6.4l5.6 8.3c.4.5.2 1.3-.3 1.6L17 20.4c-1.4 1-1.8 2.9-.9 4.3 2.6 4 9.8 14.2 21.5 21.5 1.4.9 3.3.5 4.2-.9l2.1-3c.6-.8 1.3-1.1 1.9-.7l8.3 5.6c.6.3.8 1 .4 1.6M61 15.3H50.5V4.7c0-.6-.4-1-1-1s-1 .4-1 1v10.5H37.9c-.6 0-1 .4-1 1s.4 1 1 1h10.5v10.5c0 .6.4 1 1 1s1-.4 1-1V17.3H61c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconMedicalCall;
