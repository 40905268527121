import * as React from "react";
const IconCalm = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M30.368 33.494a2.9 2.9 0 0 0 .472-.878c.105-.308.189-.703.15-1.107-.04-.416-.23-.956-.779-1.277-.508-.297-1.096-.268-1.616-.138l-.014.004c-2.396.636-3.569.899-4.704.894-1.138-.005-2.285-.28-4.639-.893-.454-.128-.995-.159-1.476.13-.497.3-.682.788-.738 1.155-.055.364-.008.727.067 1.02.076.296.201.602.371.855a3.4 3.4 0 0 0 .372.439C19.157 35.146 21.496 36 24.014 36c2.622 0 5.067-.93 6.354-2.506m-6.5-.502c1.406.006 2.797-.319 5.035-.91l-.03.06a1 1 0 0 1-.054.087c-.786.963-2.56 1.771-4.806 1.771-2.142 0-3.868-.738-4.703-1.651l-.084-.09-.002-.002-.102-.113-.002-.004-.004-.006c2.066.536 3.395.852 4.752.858"
      clipRule="evenodd"
    />
    <path
      
      d="M13.743 24.35c-.12.738.381 1.445 1.064 1.883.714.457 1.731.707 2.93.53a3.8 3.8 0 0 0 2.653-1.665c.505-.764.712-1.693.48-2.382a.5.5 0 0 0-.818-.203c-1.795 1.704-3.823 2.123-5.642 1.448a.5.5 0 0 0-.667.39M33.819 24.35c.119.738-.382 1.445-1.065 1.883-.714.457-1.731.707-2.93.53a3.8 3.8 0 0 1-2.653-1.665c-.505-.764-.712-1.693-.48-2.382a.5.5 0 0 1 .818-.203c1.796 1.704 3.824 2.123 5.642 1.448a.5.5 0 0 1 .667.39"
    />
    <path
      
      fillRule="evenodd"
      d="M42 24c0 9.941-8.059 18-18 18S6 33.941 6 24 14.059 6 24 6s18 8.059 18 18m-2 0c0 8.837-7.163 16-16 16S8 32.837 8 24 15.163 8 24 8s16 7.163 16 16"
      clipRule="evenodd"
    />
  </svg>
);
export default IconCalm;
