import * as React from "react";
const IconClipboardHeart = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M31.9 29.7c-1.2-.7-2.5-1-3.9-1-1.9 0-3.8.7-5.3 2-1.5 1.4-2.4 3.3-2.4 5.5-.1 2.3.8 4.5 2.4 6.1l7.2 7.2c.5.5 1.2.8 2 .8.7 0 1.4-.3 2-.8l7.4-7.4c1.5-1.5 2.3-3.4 2.3-5.5s-.8-4-2.3-5.5c-2.5-2.7-6.3-3.1-9.4-1.4m8 10.9L32.5 48c-.3.3-.8.3-1.1 0l-7.2-7.2c-1.2-1.2-1.9-2.9-1.8-4.6 0-1.6.7-3 1.8-4q1.65-1.5 3.9-1.5c1.2 0 2.3.3 3.3 1 .3.2.8.2 1.1 0 2.3-1.6 5.4-1.3 7.4.7 1.1 1.1 1.7 2.6 1.7 4.1s-.6 3-1.7 4.1" />
    <path d="M49.3 12.2h-1.7v-1c0-1.1-.9-2-2-2h-8.1V6.6c0-2.5-2.1-4.6-4.6-4.6h-1.7c-2.5 0-4.6 2.1-4.6 4.6v2.6h-8.1c-1.1 0-2 .9-2 2v1h-1.7c-2.6 0-4.7 2.1-4.7 4.7v40.4c0 2.6 2.1 4.7 4.7 4.7h34.6c2.6 0 4.7-2.1 4.7-4.7V16.9c-.1-2.6-2.2-4.7-4.8-4.7M28.6 6.6c0-1.4 1.2-2.6 2.6-2.6h1.7c1.4 0 2.6 1.2 2.6 2.6v2.6h-6.9zm-10.2 4.6h27.1V16H18.4zM52 57.3c0 1.5-1.2 2.7-2.7 2.7H14.7c-1.5 0-2.7-1.2-2.7-2.7V16.9c0-1.5 1.2-2.7 2.7-2.7h1.7V16c0 1.1.9 2 2 2h27.1c1.1 0 2-.9 2-2v-1.8h1.7c1.5 0 2.7 1.2 2.7 2.7v40.4z" />
  </svg>
);
export default IconClipboardHeart;
