import React, { useState, useEffect } from 'react';
import classnames from "classnames"

const BringzzSwitch = ({ className, defaultValue = false, onEnable, onDisable, ...props }) => {
    const [isOn, setIsOn] = useState(defaultValue);

    useEffect(() => {
        setIsOn(defaultValue);
    }, [defaultValue]);

    const toggleSwitch = () => {
        if (isOn) {
            if (onDisable) {
                onDisable().then((result) => {
                    setIsOn(result);
                });
            }
        } else {
            if (onEnable) {
                onEnable().then((result) => {
                    setIsOn(result);
                });
            }
        }
    };

    return (
        <div
            className={
                classnames('relative cursor-pointer rounded-full inline-block w-14 h-8 align-middle select-none transition duration-200 ease-in', isOn ? 'bg-magic-lilac' : 'bg-gray-300',
                    className
                )}
            onClick={toggleSwitch}
            {...props}
        >
            <div
                className={`absolute left-1 top-1 bg-white w-6 h-6 rounded-full shadow transform transition duration-200 ease-in ${isOn ? 'translate-x-6' : ''
                    }`}
            ></div>
        </div>
    );
};

export default BringzzSwitch;
