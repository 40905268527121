import * as React from "react";
const IconSurfboardAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 56h-8.8c1.5-4.9 3.3-12.5 3.4-22v-.6c0-4.6-.6-8.9-1.7-12.7-.9-3.1-2.2-5.9-3.8-8.3-2.9-4.2-5.8-5.9-6-6l-.6-.3c-.3-.2-.7-.2-1 0l-.6.3c-.1.1-3.1 1.7-6 6-1.6 2.4-2.9 5.2-3.8 8.3-.3.9-.5 1.8-.7 2.8-.4-1.1-.9-2.1-1.4-3.1-1.5-2.9-3.2-5.4-5.2-7.5-3.5-3.6-6.6-4.7-6.9-4.8l-.7-.2c-.3-.1-.7 0-1 .2l-.5.4s-2.7 2.1-4.9 6.9C9.8 18 9 21 8.6 24.2c-.4 3.9-.3 8.2.6 12.8 1.4 8.1 3.9 14.6 6 19H3c-.6 0-1 .4-1 1s.4 1 1 1h58c.6 0 1-.4 1-1s-.4-1-1-1m-8-12.8-6.8-5.1c-1.9-1.4-4.6-1.4-6.5 0L33 43.2c-.3-2.7-.6-5.6-.6-8.7l8.4-6.9c1.2-1 3-1 4.3 0l8.5 7c0 3.1-.3 5.9-.6 8.6M35.8 55.7c-.8-2.6-1.8-6.1-2.4-10.3l7.6-5.7c1.2-.9 2.9-.9 4.1 0l7.7 5.8c-.7 4.2-1.6 7.6-2.4 10.2v.3H35.9c0-.1 0-.2-.1-.3m1.8-42.2c2.6-3.8 5.2-5.3 5.3-5.3l.1-.1.2.1s2.7 1.5 5.3 5.3c1.5 2.2 2.7 4.9 3.6 7.8 1 3.2 1.5 6.8 1.6 10.7l-7.3-6c-2-1.6-4.8-1.6-6.8 0l-7.1 5.8c.1-3.8.6-7.4 1.6-10.6.8-2.8 2-5.4 3.5-7.7M11.1 36.7c-.8-4.4-.9-8.6-.5-12.3.3-3 1.1-5.8 2.2-8.3 1.9-4.3 4.2-6.1 4.3-6.1l.1-.1.2.1c.1 0 2.9 1 6.1 4.3 1.9 1.9 3.5 4.3 4.9 7 1 1.9 1.8 4.1 2.5 6.5-.2 1.8-.3 3.7-.3 5.6 0 9.7 1.8 17.6 3.4 22.6h-7.5l-4.2-23.5c-.1-.5-.6-.9-1.2-.8-.5.1-.9.6-.8 1.2l4 23.1h-6.9c-2.1-4.2-4.8-10.9-6.3-19.3" />
  </svg>
);
export default IconSurfboardAlt1;
