import * as React from "react";
const IconShieldAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 62c-.2 0-.4 0-.5-.1C27.6 59.5 8.2 47.3 8.2 33V6.6c0-.4.2-.7.5-.9s.7-.2 1 0c2.6 1.7 5.7 2.6 8.9 2.6 4.9 0 9.5-2.2 12.7-5.9.4-.5 1.2-.5 1.5 0 3.1 3.8 7.8 5.9 12.7 5.9 3.1 0 6.2-.9 8.9-2.6.3-.2.7-.2 1 0s.5.5.5.9V33c0 14.3-19.4 26.6-23.3 28.9-.2.1-.4.1-.6.1M10.2 8.3V33c0 12.7 17.2 24 21.8 26.8C36.7 57 53.8 45.7 53.8 33V8.3c-2.6 1.3-5.5 2-8.4 2-5.1 0-10-2.1-13.4-5.8-3.5 3.7-8.3 5.8-13.4 5.8-3 0-5.8-.7-8.4-2" />
  </svg>
);
export default IconShieldAlt2;
