import * as React from "react";
const IconAirConditioner = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 10.2H6c-2.2 0-4 1.8-4 4v18.1c0 3.3 2.7 6 6 6h48c3.3 0 6-2.7 6-6V14.2c0-2.2-1.8-4-4-4m-52 2h52c1.1 0 2 .9 2 2v13.2H4V14.2c0-1.1.9-2 2-2m50 24.1H8c-2.2 0-4-1.8-4-4v-2.9h56v2.9c0 2.2-1.8 4-4 4M12.7 44.2c-.6 0-1 .4-1 1v3.9c0 1.5-1.2 2.7-2.7 2.7H5.1c-.6 0-1 .4-1 1s.4 1 1 1H9c2.6 0 4.7-2.1 4.7-4.7v-3.9c0-.6-.4-1-1-1M25.2 44.2c-.6 0-1 .4-1 1v3.9c0 1.5-1.2 2.7-2.7 2.7h-3.9c-.6 0-1 .4-1 1s.4 1 1 1h3.9c2.6 0 4.7-2.1 4.7-4.7v-3.9c0-.6-.4-1-1-1M58.9 51.8H55c-1.5 0-2.7-1.2-2.7-2.7v-3.9c0-.6-.4-1-1-1s-1 .4-1 1v3.9c0 2.6 2.1 4.7 4.7 4.7h3.9c.6 0 1-.4 1-1s-.5-1-1-1M46.4 51.8h-3.9c-1.5 0-2.7-1.2-2.7-2.7v-3.9c0-.6-.4-1-1-1s-1 .4-1 1v3.9c0 2.6 2.1 4.7 4.7 4.7h3.9c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconAirConditioner;
