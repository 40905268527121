import * as React from "react";
const IconBanana = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60.6 22.6c-1.7-4.4-2.1-9-1.2-13.3l.1-.6-.1-.3c-1.2-2.8-4-3.5-6.1-2.8-1.5.5-2.3 1.5-2.1 2.7l.1 5.6v.5c-.8 1.6-1.8 3-3 4.3-9.1 9.8-24.1 10.7-36.6 2.1-2-1.2-3.5-1.5-4.7-1.1-.9.4-1.4 1.1-1.7 2.3-.3 1.4-.1 2.8.6 4.3 2.8 5.6 6.4 9.9 10.7 13q-4.05-.75-8.1-2.4c-2.4-.8-4.1-.9-5.2-.1-.8.7-1.3 1.7-1.3 2.9 0 1.5.5 3.1 1.6 4.5 8.4 10.5 18.1 14.3 27 14.3 6 0 11.5-1.7 16-4.2 10.6-6.1 18.8-18.9 14-31.7M54.4 7.5c1-.1 2.4.2 3.1 1.5-.5 2.2-.6 4.5-.4 6.8h-3.8V14l-.1-5.7v-.2c0-.1.4-.5 1.2-.6m-46.7 18c-.4-.7-.7-1.8-.5-2.9.1-.5.3-.8.5-.9s1-.2 2.8.9c13.3 9.1 29.4 8 39.2-2.5.4-.4.8-.9 1.2-1.4-.5 2.7-1.4 5.3-2.8 7.6-5.6 9.3-15.7 14.1-26.7 13.7-5.7-2.9-10.3-7.7-13.7-14.5m37.9 27c-10.9 6.2-27.3 7-40.4-9.6C4.4 42 4 40.8 4 39.8c0-.6.2-1 .4-1.2.1-.1.4-.2.9-.2s1.3.1 2.5.5c16.5 6.8 33.7 2.1 42-11.5q2.55-4.35 3.3-9.6h4.2c.3 1.9.8 3.7 1.5 5.6 4.4 11.7-3.3 23.5-13.2 29.1M53.3 14" />
  </svg>
);
export default IconBanana;
