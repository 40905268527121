import * as React from "react";
const IconChristianCandle = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32.4 31.2c0-2.9-2.4-5.3-5.3-5.3h-.2v-3.3c3.2-.6 5.6-4.1 5.6-8.3 0-4.4-5.6-11.6-5.8-11.9-.3-.2-.5-.4-.8-.4s-.5 0-.7.2-6 4.9-6 12c0 4.2 2.4 7.7 5.6 8.3v3.3h-.2c-2.9 0-5.3 2.4-5.3 5.3v25.5c0 2.9 2.4 5.3 5.3 5.3h3.9c.6 0 1-.4 1-1s-.4-1-1-1h-3.9c-1.8 0-3.3-1.5-3.3-3.3V31.2c0-1.8 1.5-3.3 3.3-3.3H27c1.8 0 3.3 1.5 3.3 3.3v8.4c0 .6.4 1 1 1s1-.4 1-1v-8.4zM21.2 14.3c0-4.7 3-8.3 4.4-9.8 1.7 2.4 4.7 7.1 4.7 9.8 0 3.5-2.1 6.4-4.6 6.4s-4.5-2.9-4.5-6.4M43.8 47.8h-4.4v-3.9c0-.6-.4-1-1-1s-1 .4-1 1v3.9H33c-.6 0-1 .4-1 1s.4 1 1 1h4.4v9.7c0 .6.4 1 1 1s1-.4 1-1v-9.7h4.4c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconChristianCandle;
