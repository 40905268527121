import * as React from "react";
const IconPhoneMisscall = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.7 4.1c-.4-.4-1-.4-1.4 0L47.1 16.5l-6.4-6H46c.6 0 1-.4 1-1s-.4-1-1-1h-7.5c-.6 0-1 .4-1 1V17c0 .6.4 1 1 1s1-.4 1-1v-4.9l6.9 6.5c.2.2.4.3.7.3s.5-.1.7-.3L61.7 5.5c.4-.4.4-1 0-1.4M59.9 45.8l-9.1-6.1c-1.6-1.1-3.7-.6-5 1.3l-2.3 3.3c-.4.6-1.2.8-1.8.4C29.4 37 21.7 26.2 19 22c-.4-.6-.2-1.4.4-1.9l3.7-2.5c1.5-1 1.9-3.1.9-4.7l-6.1-9.1c-1-1.5-3-2-4.6-1L4.7 7.9c-.1 0-.1.1-.2.1-6.4 6.4-.2 22.7 14.2 37 6.7 6.7 14.2 12 21.1 14.7 3.4 1.3 6.4 2 9 2 2.9 0 5.3-.8 7-2.5l.2-.2 5.1-8.6c.7-1.6.3-3.6-1.2-4.6m-.7 3.5-5 8.5c-2.6 2.4-7.5 2.4-13.7 0-6.6-2.6-13.8-7.7-20.4-14.2C7.1 30.6.6 15 5.8 9.5l8.5-5c.6-.4 1.5-.2 1.9.4l6.1 9.1c.4.6.3 1.5-.4 1.9l-3.7 2.5c-1.5 1-1.9 3-.9 4.6 2.8 4.4 10.7 15.4 23.4 23.3 1.5.9 3.5.5 4.5-.9l2.3-3.3c.6-.9 1.5-1.2 2.2-.8l9.1 6.1c.6.5.8 1.3.4 1.9" />
  </svg>
);
export default IconPhoneMisscall;
