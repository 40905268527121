import * as React from "react";
const IconDj = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 513 342"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 0h513v342H0z" />
    <path  d="M513 0v166.7L0 170.8V0z" />
    <path  d="M256 170.7 0 342V0z" />
    <path
      
      d="m89.8 92.5 17 52.4H162l-44.6 32.5 17 52.4-44.6-32.4-44.6 32.4 17-52.4-44.6-32.5h55.2z"
    />
  </svg>
);
export default IconDj;
