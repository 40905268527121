import * as React from "react";
const IconUiSecure = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path  d="M15 26h18v-2H15zM33 31H15v-2h18zM15 36h18v-2H15z" />
    <path
      
      fillRule="evenodd"
      d="M39 20a2 2 0 0 0-2-2h-2v-1c0-6.075-4.925-11-11-11s-11 4.925-11 11v1h-2a2 2 0 0 0-2 2v20a2 2 0 0 0 2 2h26a2 2 0 0 0 2-2zm-24-3a9 9 0 1 1 18 0v1h-2v-1a7 7 0 1 0-14 0v3h20v20H11V20h4zm9-5a5 5 0 0 1 5 5v1H19v-1a5 5 0 0 1 5-5"
      clipRule="evenodd"
    />
  </svg>
);
export default IconUiSecure;
