import * as React from "react";
const IconRocketSlim = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m46.6 31.7-2.3-2.3 9.7-2.9c.3-.1.6-.4.7-.7s0-.7-.3-1l-6.2-6.2c-2.2-2.2-5.4-3-8.4-2.1l-6.5 1.9-4.4-4.4C17.7 2.8 9.5 1.7 3 2.1c-.5 0-.9.4-.9.9-.5 6.4.6 14.5 11.9 25.9l4.4 4.4-1.9 6.5c-.9 3-.1 6.2 2.1 8.4l6.2 6.2c.2.2.4.3.7.3h.2c.3-.1.6-.3.7-.7l2.9-9.7 2.3 2.3c.2.2.4.3.7.3s.5-.1.7-.3l13.4-13.4c.6-.4.6-1.1.2-1.5m-6.2-13.3c2.3-.7 4.7-.1 6.4 1.6l5 5-9.1 2.7L35 20zM25.1 51.8l-5-5c-1.7-1.7-2.3-4.1-1.6-6.4l1.6-5.5 7.7 7.7zm7.4-7.3-17-17C5.2 17.2 3.8 10.1 4 4c6.2-.2 13.3 1.3 23.4 11.4l4.9 4.9 9.4 9.4 2.8 2.8zM57.7 47.2c-2.9-2.9-7.6-2.9-10.5 0s-2.9 7.6 0 10.5c2.7 2.7 12.5 4.1 13.6 4.3h.1c.3 0 .5-.1.7-.3s.3-.5.3-.8c-.1-1.2-1.5-11-4.2-13.7m-9.1 9.1c-2.1-2.1-2.1-5.6 0-7.7 1.1-1.1 2.4-1.6 3.8-1.6s2.8.5 3.8 1.6c1.6 1.6 2.9 7.7 3.5 11.2-3.3-.6-9.4-1.9-11.1-3.5" />
  </svg>
);
export default IconRocketSlim;
