import React, { useState } from 'react';
import { useNavigation } from 'context/NavigationContext';
import {
	BringzzPageContainer,
	BringzzButton,
	BringzzIcon,
	BringzzText,
	BringzzInput
} from '@bringzz/components';
import useUser from 'hooks/useUser';
import useRequest from 'hooks/useRequest';
import { useAuth } from 'context/AuthContext';

const EditPrivateInsuranceView = () => {
	const { user, refreshUserData } = useAuth()
	const { backPress, navigate } = useNavigation();
	const { sendRequest, loading } = useRequest();
	const { getUser } = useUser();
	const [companyNameInput, setCompanyNameInput] = useState(user.privateInsuranceCompanyname || '');
	const [idInput, setIdInput] = useState(user.privateInsuranceId || '');

	return (
		<BringzzPageContainer
			header={{
				left: (
					<BringzzIcon
						size='22'
						icon='IconChevronLeft'
						folder='LineIcons'
						className='cursor-pointer'
						onClick={backPress}
					/>
				),
				title: <BringzzText tag='h2'>Private Insurance</BringzzText>
			}}
			footer={
				<div className='pb-28 px-6'>
					<BringzzButton
						size='lg'
						className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
						onClick={async () => {
							await sendRequest({
								method: 'POST',
								url: `/module/profile/changeInsurance`,
								data: {
									privateInsuranceId: idInput,
									privateInsuranceCompanyname: companyNameInput
								}
							})
								.then(response => {
									if (response.status === 'success') {
										refreshUserData()
										backPress()
									}
								})
								.catch(error => {
									console.log(error);
								});
						}}
						disabled={loading || (!companyNameInput && !idInput)}
					>
						Save
					</BringzzButton>
				</div>
			}
		>
			<div className='p-6 space-y-4'>
				<BringzzInput
					onChange={(e, isValid, newValue) => {
						setIdInput(newValue);
					}}
					defaultValue={user.privateInsuranceId}
					id='id'
					label='Id'
					containerClassname='w-full'
					placeholder='Id'
				/>
				<BringzzInput
					onChange={(e, isValid, newValue) => {
						setCompanyNameInput(newValue);
					}}
					defaultValue={user.privateInsuranceCompanyname}
					id='companyname'
					label='Company Name'
					containerClassname='w-full'
					placeholder='Company name'
				/>
			</div>
		</BringzzPageContainer>
	);
};

export default EditPrivateInsuranceView;
