import * as React from "react";
const IconHt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 513 342"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 0h513v172H0z" />
    <path  d="M0 172h513v172H0z" />
    <path  d="m381.4 251.5-110.7-13.8-110.8 13.8V85.4h221.5z" />
    <circle cx={270.7} cy={182.3} r={55.4}  />
    <circle cx={270.7} cy={182.3} r={27.7}  />
    <path  d="M229.1 113.1h83.1l-41.5 41.5z" />
    <path  d="M256.8 140.8h27.7v83h-27.7z" />
    <path  d="M314.9 215.5h-88.5l-66.5 36h221.5z" />
  </svg>
);
export default IconHt;
