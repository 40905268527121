import * as React from "react";
const IconRocket = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.4 19.5-8.2-8.2-.3-.3q-.9-.6-1.8-.6l-12.9 1.1C28.5 3.4 16.3-.1 6 2.7 4.4 3.1 3.1 4.4 2.7 6c-2.8 10.2.7 22.5 8.9 32.1l-1.1 12.7v.2c0 .6.2 1.3.6 1.8l8.5 8.6q.9.9 2.1.9c.3 0 .6 0 .8-.1 1-.3 1.8-1.2 2.1-2.2l1.2-10.7q2.1 1.05 4.2 1.8.75.3 1.5.3c1.3 0 2.5-.5 3.4-1.4l14.9-14.9c1.3-1.3 1.8-3.3 1.2-5q-.75-2.1-1.8-4.2l10.5-1.2h.2c1.1-.2 1.9-1 2.2-2.1.3-1.2.1-2.3-.7-3.1M20.4 56 15 50.5l.7-8.1c1.8 1.6 3.7 3.1 5.7 4.4zm26.2-24.2L31.8 46.6c-.1.1-.2.1-.3.1-5.2-1.8-10.2-5-14.3-9.1C8.4 28.7 4.4 16.8 7 7.2c.1-.1.1-.2.2-.2 2-.5 4-.8 6.1-.8 8.3 0 17.3 3.9 24.2 10.9 4.1 4.1 7.3 9.1 9.1 14.3.1.2.1.3 0 .4m.2-10.3c-1.3-2-2.8-4-4.4-5.8l8.1-.7 5.4 5.4zM55.3 40.2c-.3-.2-1.4-.7-2.6.3-1 .8-5.7 5.5-6.6 6.4-6.4 6.4-6.4 6.4-6 7.8.1.4.3.8.6 1 3.2 3.2 8.1 3.9 11.8 3.9 2.8 0 4.9-.4 5.1-.5.9-.2 1.6-.9 1.8-1.8.1-.5 2.2-11.2-3.5-16.9-.3 0-.5-.1-.6-.2m-.2 14.9c-2.6.3-6.6.3-9.6-1.2 2.2-2.2 6.1-6.2 8.3-8.3 1.5 2.8 1.5 6.9 1.3 9.5" />
    <path d="M26.1 17.8c-4.6 0-8.3 3.7-8.3 8.3s3.7 8.3 8.3 8.3 8.3-3.7 8.3-8.3-3.7-8.3-8.3-8.3m0 12.2c-2.1 0-3.8-1.7-3.8-3.8s1.7-3.8 3.8-3.8S30 24 30 26.1 28.2 30 26.1 30" />
  </svg>
);
export default IconRocket;
