import * as React from "react";
const IconUterus = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.8 19c.1-.2.2-.3.2-.5V16c0-3-1.3-6-3.4-8.1-2.1-2-4.8-3.1-7.6-3-2.7.1-5.3 1.2-7.2 3.1-2 2-3.1 4.7-3.1 7.5V20c-1.9.4-4.5.7-7.2.7s-5.3-.2-7.2-.7v-4.1c0-5.4-3.7-9.9-8.8-10.8-3.5-.6-7 .5-9.4 3-2 2-3.1 4.7-3.1 7.5v4.6c-.7 1-1 2.1-1 3.4 0 3.3 2.7 6 6 6s6-2.7 6-6-2.7-6-6-6c-1.1 0-2.2.3-3.1.8v-2.8c0-2.3.9-4.5 2.5-6.1 2-2 4.8-2.9 7.6-2.4 4.1.7 7.1 4.5 7.1 8.8v11.9c0 1.1.3 2.3.8 3.3 1.4 2.5 4.5 8.1 6.2 11.2.3.6.8.9 1.4 1.1-.2.2-.3.4-.3.7v14c0 .6.4 1 1 1s1-.4 1-1V44c0-.3-.1-.5-.3-.7.6-.2 1.1-.6 1.4-1.1L39.8 31q.9-1.5.9-3.3V15.6c0-2.3.9-4.5 2.5-6.1S46.8 7 49 7c2.2-.1 4.4.8 6.1 2.4 1.8 1.7 2.8 4.2 2.8 6.6v1.9c-.6-.2-1.3-.4-2-.4-3.3 0-6 2.7-6 6s2.7 6 6 6 6-2.7 6-6c.1-1.8-.8-3.4-2.1-4.5M8 19.6c2.2 0 4 1.8 4 4s-1.8 4-4 4-4-1.8-4-4 1.8-4 4-4M38.1 30l-6.2 11.2c-.1.2-.3.2-.4.2s-.3 0-.4-.2c-1.7-3.1-4.8-8.7-6.2-11.2-.4-.7-.6-1.5-.6-2.3V22c2 .4 4.5.7 7.2.7s5.2-.2 7.2-.7v5.7c0 .8-.2 1.6-.6 2.3M56 27.7c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4" />
  </svg>
);
export default IconUterus;
