import * as React from "react";
const IconHangerAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m40.7 29-8.1 6.1V22.6l9.3-7c.4-.3.5-1 .2-1.4s-1-.5-1.4-.2l-8.1 6.1V3c0-.6-.4-1-1-1s-1 .4-1 1v9.1l-7.4-5.7c-.4-.3-1.1-.2-1.4.2s-.2 1.1.2 1.4l8.6 6.6v12.6l-7.4-5.7c-.4-.3-1.1-.2-1.4.2s-.3 1.1.2 1.4l8.6 6.6V60h-6.9c-.6 0-1 .4-1 1s.4 1 1 1h16.4c.6 0 1-.4 1-1s-.4-1-1-1h-7.5V37.6l9.3-7c.4-.3.5-1 .2-1.4-.3-.5-.9-.5-1.4-.2" />
  </svg>
);
export default IconHangerAlt1;
