import * as React from "react";
const IconCloseSign = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.6 10.5H7.3c-2.9 0-5.3 2.4-5.3 5.4v32.2c0 3 2.4 5.3 5.3 5.3h49.3c3 0 5.4-2.4 5.4-5.3V15.9c0-3-2.4-5.4-5.4-5.4M60 48.1c0 1.8-1.5 3.3-3.4 3.3H7.3C5.5 51.5 4 50 4 48.1V15.9c0-1.8 1.5-3.4 3.3-3.4h49.3c1.8 0 3.4 1.5 3.4 3.4z" />
    <path d="M40.1 23.9c-.4-.4-1-.4-1.4 0L32 30.6l-6.7-6.7c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l6.7 6.7-6.7 6.7c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l6.7-6.7 6.7 6.7c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4L33.4 32l6.7-6.7c.4-.4.4-1 0-1.4M18 31h-6.8c-.6 0-1 .4-1 1s.4 1 1 1H18c.6 0 1-.4 1-1s-.5-1-1-1M52.8 31H46c-.6 0-1 .4-1 1s.4 1 1 1h6.8c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconCloseSign;
