import * as React from "react";
const IconShieldAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 62c-.8 0-1.7-.2-2.4-.6-2.5-1.3-6.4-3.5-10.2-6.2C13.6 51 6.7 44.5 6.7 37.5V13.3c0-2.4 1.5-4.4 3.8-5.1l20-6q1.5-.45 3 0l20 6c2.3.7 3.8 2.7 3.8 5.1v24.2c0 7.1-6.9 13.5-12.7 17.7-3.8 2.7-7.6 4.9-10.2 6.2-.7.4-1.6.6-2.4.6m0-58c-.3 0-.6 0-.9.1l-20 6c-1.4.4-2.3 1.7-2.3 3.2v24.2c0 6.2 6.4 12.2 11.8 16.1 3.7 2.7 7.4 4.8 9.9 6.1.9.5 2.1.5 3 0 2.5-1.3 6.2-3.4 9.9-6.1 5.4-3.9 11.8-9.9 11.8-16.1V13.3c0-1.5-.9-2.7-2.3-3.2l-20-6c-.3-.1-.6-.1-.9-.1" />
  </svg>
);
export default IconShieldAlt1;
