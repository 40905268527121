import * as React from "react";
const IconDead6 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
    <path d="M25.6 18.9c-.4-.4-1-.4-1.4 0L21.1 22 18 18.9c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l3.1 3.1-3.1 3.1c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l3.1-3.1 3.1 3.1c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-3.1-3.1 3.1-3.1c.4-.3.4-1 0-1.4M47.5 18.9c-.4-.4-1-.4-1.4 0l-3 3.1-3.1-3.1c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l3.1 3.1-3.1 3.1c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l3.1-3.1 3.1 3.1c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-3.1-3.1 3.1-3.1c.3-.3.3-1-.1-1.4M37.9 42.3c-.4-.4-1-.4-1.4 0l-3.1 3.1-3.1-3.1c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l3.1 3.1-3.1 3.1c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l3.1-3.1 3.1 3.1c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-3.1-3.1 3.1-3.1c.3-.4.3-1 0-1.4" />
  </svg>
);
export default IconDead6;
