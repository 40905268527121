import * as React from "react";
const IconCargoMover = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 37.6h-9v-8.1c0-2.1-1.7-3.8-3.8-4V13c0-2.2-1.8-4-4-4H33.4c-2.2 0-4 1.8-4 4v8.6H17.9v-6c0-2.2-1.8-4-4-4H6c-2.2 0-4 1.8-4 4v23c0 .1 0 .2.1.3l4.4 12.5c.8 2.2 2.9 3.7 5.2 3.7h30.8c1.7 0 3.4-.8 4.4-2.2l9.8-13.3H61c.6 0 1-.4 1-1s-.4-1-1-1m-11-8.1v8.1H31.4V27.5H48c1.1 0 2 .8 2 2M31.4 13c0-1.1.9-2 2-2h10.9c1.1 0 2 .9 2 2v12.5H31.4V13m-2 10.6v14H17.9v-14zM4 27.5h11.8v10.1H4zm0-11.9c0-1.1.9-2 2-2h7.9c1.1 0 2 .9 2 2v9.9H4zm41.3 36c-.7.9-1.7 1.4-2.8 1.4H11.6c-1.5 0-2.8-.9-3.3-2.3L4.4 39.6h49.8z" />
  </svg>
);
export default IconCargoMover;
