import * as React from "react";
const IconGym = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 58.2h-4.6V5.8H61c.6 0 1-.4 1-1s-.4-1-1-1H3c-.6 0-1 .4-1 1s.4 1 1 1h4.5v52.3H3c-.6 0-1 .4-1 1s.4 1 1 1h58c.6 0 1-.4 1-1s-.4-.9-1-.9m-6.6-28.7H40.7V5.8h13.7zM38.7 5.8v23.6H25.3V5.8zm-15.4 0v23.6H9.5V5.8zm1.9 52.4V45.4h13.7v12.8zm15.6 0V44.4c0-.6-.4-1-1-1H24.2c-.6 0-1 .4-1 1v13.8H9.5V31.5h44.9v26.7z" />
  </svg>
);
export default IconGym;
