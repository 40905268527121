import * as React from "react";
const IconInstagramCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 18c-7.7 0-14 6.3-14 14s6.3 14 14 14 13.9-6.3 13.9-14S39.7 18 32 18m0 26c-6.6 0-12-5.4-12-12s5.4-12 12-12 11.9 5.4 11.9 12S38.6 44 32 44M48.1 10.1c-2.1 0-3.8 1.7-3.8 3.8s1.7 3.8 3.8 3.8 3.8-1.7 3.8-3.8-1.7-3.8-3.8-3.8" />
    <path d="M48 1H16C7.7 1 1 7.7 1 16v32c0 8.2 6.7 15 15 15h32c8.2 0 15-6.7 15-15V16c0-8.3-6.7-15-15-15m13 47c0 7.2-5.8 13-13 13H16C8.8 61 3 55.2 3 48V16C3 8.8 8.8 3 16 3h32c7.2 0 13 5.8 13 13z" />
  </svg>
);
export default IconInstagramCopy;
