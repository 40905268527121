import * as React from "react";
const IconVolkswagen = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 65 64"
    {...props}
  >
    <path
      
      d="M39.122 8.797c-1.957-.53-4.03-.783-6.126-.783a24.6 24.6 0 0 0-6.01.76l6.08 16.766zM56.97 32.011c0-2.993-.506-5.85-1.52-8.498l-11.4 29.755c7.647-4.007 12.92-12.022 12.92-21.257"
    />
    <path
      
      d="M45.064 11.262 38.04 30.607h-9.903L21.09 11.169a24 24 0 0 0-6.77 5.804l10.017 25.978L28 33.462h9.627l3.661 9.489 10.11-26.3c-1.773-2.165-3.938-3.985-6.356-5.412"
    />
    <path
      
      d="M32.996 0C15.38 0 .985 14.37.985 32.011S15.379 64 32.996 64s31.989-14.394 31.989-31.989S50.637 0 32.996 0m0 59.924c-15.36 0-27.935-12.529-27.935-27.89 0-15.36 12.551-27.958 27.935-27.958s27.936 12.552 27.936 27.959S48.38 59.924 32.996 59.924"
    />
    <path
      
      d="m32.79 38.575-6.196 16.052v.506c2.027.576 4.169.853 6.403.853 2.095 0 4.122-.254 6.056-.76l-.069-.6zM9.022 32.011c0 9.028 5.02 16.927 12.482 21.004L10.381 24.043c-.898 2.487-1.359 5.159-1.359 7.968"
    />
  </svg>
);
export default IconVolkswagen;
