import * as React from "react";
const IconNc = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 640 426.7"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 293h640v133.7H0z" />
    <path  d="M0 132h640v161.3H0z" />
    <path  d="M0 0h640v133.3H0z" />
    <circle
      cx={240}
      cy={213.3}
      r={157.3}
      
      
      strokeWidth={5.3}
    />
    <path d="m307.9 354.5-31.8 12-36.1 4.2-37.8-2.4-35.9-15.5s28.6-29.3 40.7-33.5c8.6-3 22.4-3.7 22.4-3.7V122l-.3-66H246l-.5 67.5v191.7s15.3 1.2 22.1 4.1c11.3 4.9 40.3 35.2 40.3 35.2" />
    <path d="m212.727 102.375 42.955-21.418 5.577 11.187-42.955 21.417z" />
    <ellipse cx={237.8} cy={280.5} rx={16.8} ry={26.5} />
    <circle cx={237.4} cy={143.4} r={20.9} />
    <ellipse cx={238.4} cy={211.1} rx={19.9} ry={12.1} />
    <path d="M213.8 177h47v10h-47zm0 57h47v10h-47z" />
  </svg>
);
export default IconNc;
