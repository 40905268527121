import * as React from "react";
const IconPoundSterling = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M40.2 39.5H29.8c.8-.9 1.2-2.2 1.2-3.5v-2.4h7.5c.6 0 1-.4 1-1s-.4-1-1-1H31v-3.1c0-2.3 1.8-4.1 4.1-4.1 1.1 0 2.1.4 2.8 1.1.4.4 1 .4 1.4 0s.4-1 0-1.4c-1.1-1.1-2.6-1.7-4.2-1.7-3.4 0-6.1 2.8-6.1 6.1v3.1h-4.4c-.6 0-1 .4-1 1s.4 1 1 1H29V36c0 1.9-1.5 3.5-3.4 3.5h-1.8c-.6 0-1 .4-1 1s.4 1 1 1h16.3c.6 0 1-.4 1-1s-.4-1-.9-1" />
    <path d="M58 13.8H6c-2.2 0-4 1.8-4 4v28.4c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V17.8c0-2.2-1.8-4-4-4m2 32.4c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V17.8c0-1.1.9-2 2-2h52c1.1 0 2 .9 2 2z" />
    <path d="M15.1 21.4h-3.5c-.6 0-1 .4-1 1s.4 1 1 1h3.5c.6 0 1-.4 1-1s-.5-1-1-1M52.5 40.7H49c-.6 0-1 .4-1 1s.4 1 1 1h3.5c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconPoundSterling;
