import * as React from "react";
const IconRedo = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.1 20 47.7 9.2c-.7-.5-1.6-.6-2.4-.2-.8.3-1.3 1.1-1.3 2v9.8H18.4C9.4 20.8 2 28.6 2 38.1s7.4 17.2 16.4 17.2h14c.6 0 1-.4 1-1s-.4-1-1-1h-14C10.5 53.3 4 46.5 4 38.1s6.5-15.2 14.4-15.2H44v9.8c0 .9.5 1.7 1.3 2.1.3.2.7.2 1 .2.5 0 1-.2 1.5-.5l13.4-10.8c.5-.4.9-1.1.9-1.8-.1-.8-.4-1.4-1-1.9m-1.2 2.1L46.5 32.9c-.1.1-.3.1-.3 0-.1 0-.2-.1-.2-.3V11c0-.2.1-.3.2-.3h.1c.1 0 .1 0 .2.1l13.4 10.8c.1.1.1.2.1.2 0 .1 0 .2-.1.3" />
  </svg>
);
export default IconRedo;
