import * as React from "react";
const IconStarEmptyCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m32 7.5.5.9 1.8-.9-1.4-2.6c-.2-.3-.5-.5-.9-.5s-.7.2-.9.5l-1.4 2.6 1.8.9zM40.5 19.8l-1.8.9 1.4 2.6c.2.3.4.5.8.5l3 .4.3-2-2.5-.2zM62 25.4c-.1-.4-.4-.6-.8-.7l-3-.5-.3 2 1 .1-.8.7 1.4 1.5 2.2-2c.3-.3.4-.7.3-1.1M47.6 39.8l1.8-1.8-1.4-1.4-2.2 2.1c-.2.2-.3.6-.3.9l.5 2.9 2-.3zM50.4 55.6l-2 .4.2.9-.9-.5-.9 1.8 2.7 1.4c.1.1.3.1.5.1s.4-.1.6-.2c.3-.2.5-.6.4-1zM31.5 48.7l-2.7 1.4.9 1.8 2.2-1.1 2.3 1.2.9-1.8-2.7-1.4c-.2-.3-.6-.2-.9-.1M15.4 56.9l.2-.9-2-.3-.5 2.9c-.1.4.1.8.4 1 .2.1.4.2.6.2s.3 0 .5-.1l2.7-1.4-.9-1.8zM18.2 38.7 16 36.6 14.7 38l1.8 1.7-.5 2.4 2 .4.5-2.9c.1-.3 0-.7-.3-.9M6.2 26.2l-.3-2-3.1.5c-.4.1-.7.3-.8.7s0 .8.3 1l2.3 2L5.9 27l-.7-.6zM23.2 23.9c.3 0 .6-.2.8-.5l1.4-2.6-1.8-.9-1.2 2.1-2.6.3.3 2z" />
  </svg>
);
export default IconStarEmptyCopy;
