import * as React from "react";
const IconCupcake = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.3 27.4c-.7-1-1.9-1.6-3.2-1.6h-5.4C52.4 13.9 42.9 3.7 32 3.7S11.6 14 11.3 25.8H5.9c-1.3 0-2.4.6-3.2 1.6s-.9 2.4-.5 3.6l9.6 26.8c.6 1.5 2 2.6 3.7 2.6h33.1c1.6 0 3.1-1 3.7-2.6L61.8 31c.4-1.2.2-2.6-.5-3.6M32 5.7c9.6 0 18.4 9.5 18.7 20.1H13.3C13.6 15.2 22.4 5.7 32 5.7m-.4 52.6h-8.8V58l-4.7-30.2h13.4v30.4c.1 0 .1.1.1.1m1.9-30.5H47L42.3 58v.3h-8.8V27.9c.1 0 .1-.1 0-.1M13.7 57.1 4.1 30.3c-.2-.6-.1-1.2.2-1.7.4-.5.9-.8 1.5-.8h10.3v.3l4.7 30.2h-5.4c-.7 0-1.5-.5-1.7-1.2m46.2-26.8-9.6 26.8c-.3.7-1 1.3-1.8 1.3h-4.2L49 28.1v-.3h9.2c.6 0 1.2.3 1.5.8s.4 1.1.2 1.7" />
  </svg>
);
export default IconCupcake;
