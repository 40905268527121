import * as React from "react";
const IconSort = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m23.6 49.7-8.4 8V4c0-.6-.4-1-1-1s-1 .4-1 1v53.7l-8.4-8c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l10.1 9.6c.2.2.4.3.7.3s.5-.1.7-.3L25 51.1c.4-.4.4-1 0-1.4s-1-.4-1.4 0M60.6 12.9 50.5 3.3c-.4-.4-1-.4-1.4 0L39 12.9c-.4.4-.4 1 0 1.4s1 .4 1.4 0l8.4-8V60c0 .6.4 1 1 1s1-.4 1-1V6.3l8.4 8c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1.1 0-1.4" />
  </svg>
);
export default IconSort;
