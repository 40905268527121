import * as React from "react";
const IconArrowLongDownR = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M43.8 48.1c-.4-.4-1-.4-1.4 0L33 57.8V18.1c.1-.1.3-.2.4-.3l5.8-5.8c.8-.8.8-2 0-2.8l-5.8-5.8c-.8-.8-2-.8-2.8 0l-5.8 5.8c-.8.8-.8 2 0 2.8l5.8 5.8c.1.1.3.2.4.3v39.7l-9.4-9.7c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4L31.3 61c.2.2.4.3.7.3s.5-.1.7-.3l11.1-11.4c.4-.5.4-1.1 0-1.5M26.2 10.5 32 4.7l5.8 5.8-5.8 5.9z" />
  </svg>
);
export default IconArrowLongDownR;
