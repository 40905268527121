import React from 'react';
import { BringzzTopBar, BringzzMap } from '@bringzz/components';
import BringzzLogo from 'assets/BringzzLogo.png';
import { useNavigation } from '../../context/NavigationContext';

const AddLocation = ({ center, zoom, close }) => {
	const { navigate } = useNavigation();

	const selectLocation = position => {
		console.log(position);
		close(position);
	};
	return (
		<div className='h-full max-h-screen absolute top-0 w-full z-20'>
			<div className='flex flex-col h-full'>
				<BringzzTopBar
					logo={BringzzLogo}
					title={'Select address'}
					sections={[
						{ name: 'logo', alignment: 'start', value: BringzzLogo },
						{ name: 'location', alignment: 'center', value: 'Wien' },
						{
							name: 'notification',
							alignment: 'end',
							value: true,
							onClick: () => navigate('/notifications')
						}
					]}
				/>
				<div className='grow'>
					<BringzzMap
						mode={1}
						center={center}
						zoom={zoom}
						onSelect={selectLocation}
					/>
				</div>
			</div>
		</div>
	);
};

export default AddLocation;
