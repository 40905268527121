import * as React from "react";
const IconTowel = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.1 2.6H10c-4.4 0-8 3.6-8 8v31.1c0 2.2 1.8 4 4 4h15.8V56c0 2.9 2.4 5.3 5.3 5.3h29.6c2.9 0 5.3-2.4 5.3-5.3V13.6c0-6.1-4.9-11-10.9-11m0 2c4.9 0 8.9 4 8.9 8.9v35H23.8V10.6c0-2.4-1.1-4.5-2.7-6zM6 43.8c-1.1 0-2-.9-2-2V10.6c0-3.3 2.7-6 6-6h5.8c3.3 0 6 2.7 6 6v33.1H6zm50.7 15.6H27.1c-1.8 0-3.3-1.5-3.3-3.3v-5.6H60v5.6c0 1.8-1.5 3.3-3.3 3.3" />
  </svg>
);
export default IconTowel;
