import * as React from "react";
const IconChatAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M16.7 25c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3m0 4c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1M31 25c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3m0 4c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1M45.3 25c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3m0 4c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1" />
    <path d="M42.2 9.2H8c-2.8 0-5 2.2-5 5v37.7c0 1.1.6 2.2 1.7 2.7.4.2.8.3 1.3.3.7 0 1.3-.2 1.9-.7l8.8-7.3h25.5C52.6 46.8 61 38.4 61 28S52.6 9.2 42.2 9.2m0 35.6H16.3c-.2 0-.5.1-.6.2l-9.1 7.6c-.4.3-.9.2-1 .1-.2 0-.6-.3-.6-.8V14.2c0-1.7 1.3-3 3-3h34.2C51.5 11.2 59 18.7 59 28s-7.5 16.8-16.8 16.8" />
  </svg>
);
export default IconChatAlt1;
