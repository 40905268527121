import * as React from "react";
const IconTf = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22.5 15"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 0h21v15H0z" />
    <path  d="M0 0h22.5v15H0z" />
    <path  d="M0 0h11.3v7.5H0z" />
    <path  d="M7.1 0h3.6v7H7.1z" />
    <path  d="M0 0h3.6v7H0z" />
    <path
      
      d="M3.6 0h3.6v7H3.6zm10.9 6h5L19 7h-1.5v.5h1l-.5 1h-.5V10h-1V7H15zm4 2.5 1 1.5h-2zm-3 0 1 1.5h-2zm1.5 3L16 10h2zM20 8c-.3 0-.5-.2-.5-.5s.2-.5.5-.5.5.2.5.5-.2.5-.5.5m-6 0c-.3 0-.5-.2-.5-.5s.2-.5.5-.5.5.2.5.5-.2.5-.5.5m1 4c-.3 0-.5-.2-.5-.5s.2-.5.5-.5.5.2.5.5-.2.5-.5.5m4 0c-.3 0-.5-.2-.5-.5s.2-.5.5-.5.5.2.5.5-.2.5-.5.5m-2 1c-.3 0-.5-.2-.5-.5s.2-.5.5-.5.5.2.5.5-.2.5-.5.5"
    />
  </svg>
);
export default IconTf;
