import * as React from "react";
const IconMap = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m57.4 17.8-14.8-3.4c0-.3.1-.5.1-.8 0-5-4-9-8.9-9-5 0-9 4-9 9 0 1 .3 2.3.9 3.8l-2.9.6-13.1-3.7c-1.9-.5-3.9-.2-5.5 1s-2.5 3-2.5 5v28.6c0 2.8 1.9 5.3 4.6 6l15.8 4.5h.1c.2 0 .4.1.6.1h.5l18.2-3.8 13.2 3.1c.5.1.9.2 1.4.2 1.4 0 2.8-.5 3.9-1.4 1.5-1.2 2.4-3 2.4-4.9V23.9c-.1-2.9-2.1-5.4-5-6.1M33.7 9.1c2.5 0 4.4 2 4.4 4.5 0 1.1-1.4 4.4-4.5 9.2-3.1-4.8-4.5-8-4.5-9.2.1-2.5 2.1-4.5 4.6-4.5m-6.1 12.4c1.1 2 2.4 4 3.6 5.7.5.8 1.5 1.3 2.4 1.3 1 0 1.9-.5 2.4-1.3 1-1.5 2.1-3.1 3.1-4.8v29l-14.1 3V22.1zM6.3 48.8V20.3c0-.6.3-1.1.7-1.4s1-.4 1.5-.3l12 3.4v32.2l-13-3.7c-.7-.2-1.2-.9-1.2-1.7m51.5 3.7c0 .7-.4 1.2-.7 1.4-.2.2-.8.5-1.5.3l-12-2.8V19.2l12.8 3c.8.2 1.4.9 1.4 1.7z" />
    <path d="M34.1 15.5c.1 0 .3-.1.4-.1s.3-.1.4-.2l.3-.3c.4-.4.7-1 .7-1.6s-.2-1.2-.7-1.6l-.3-.3c-.1-.1-.3-.2-.4-.2-.1-.1-.3-.1-.4-.1-.7-.1-1.5.1-2 .6-.4.4-.7 1-.7 1.6s.2 1.2.7 1.6c.4.4 1 .7 1.6.7.1-.1.3-.1.4-.1" />
  </svg>
);
export default IconMap;
