import * as React from "react";
const IconThreeQuerters = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M47.1 33H34.3c-1.1 0-2-.9-2-2V16.5c0-.6-.4-1-1-1s-1 .4-1 1V31c0 2.2 1.8 4 4 4h12.9c.6 0 1-.4 1-1s-.5-1-1.1-1" />
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
  </svg>
);
export default IconThreeQuerters;
