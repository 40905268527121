import * as React from "react";
const IconTempleAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M24 5.048a6 6 0 0 0-5.917 5H13a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h1v2h-2a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1v2h-2a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h1V36H8a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h12v.048h8V42h12a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-3v-3.952h1a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-2v-2a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-2v-2h1a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-5.083a6 6 0 0 0-5.917-5m3.874 5a4.002 4.002 0 0 0-7.748 0H21V10h6v.048zM15 32.048V36h-2v-3.952zm10-20v2h-2v-2zm-4 0h-7v2h7zm0 4h-5v2h5zm0 4h-8v2h8zm2 2v-2h2v2zm-2 2h-7v2h7zm2 2v-2h2v2zm-12 2v2h10v-2zm26 2v-2H27v2zm-12-2V30h-2v-1.952zm2-2h7v-2h-7zm0-4h8v-2h-8zm0-4h5v-2h-5zm-4-2v2h2v-2zm4-2h7v-2h-7zM35 36v-3.952h-2V36zm-4 0v-3.952H17V36h3v-1.952h8V36zm-3 2v2h11v-2zm-2-1.952h-4v4h4zM20 38v2H9v-2z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconTempleAlt;
