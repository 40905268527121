import * as React from "react";
const IconHome = (props) => (
  <svg
    width={props.size || "48"}
    height={props.size || "48"}
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.5 19.999v-7.4c0-.56 0-.84.109-1.054a1 1 0 0 1 .437-.437c.214-.11.494-.11 1.054-.11h2.8c.56 0 .84 0 1.054.11a1 1 0 0 1 .437.437c.109.214.109.494.109 1.054v7.4M9.518 1.763 2.735 7.038c-.453.353-.68.529-.843.75a2 2 0 0 0-.318.65c-.074.264-.074.551-.074 1.126v7.235c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874c.428.218.988.218 2.108.218h11.6c1.12 0 1.68 0 2.108-.218a2 2 0 0 0 .874-.874c.218-.428.218-.988.218-2.108V9.564c0-.575 0-.862-.074-1.126a2.002 2.002 0 0 0-.318-.65c-.163-.221-.39-.397-.843-.75l-6.783-5.275c-.351-.273-.527-.41-.72-.463a1 1 0 0 0-.523 0c-.194.053-.37.19-.721.463Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default IconHome;
