import * as React from "react";
const IconWaterBottle = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M46.3 27.3V14.2c0-2.2-1.8-4-4-4h-5V4h3.2c.6 0 1-.4 1-1s-.4-1-1-1h-17c-.6 0-1 .4-1 1s.4 1 1 1h3.2v6.2h-5c-2.2 0-4 1.8-4 4v13.1c0 2.1 1.6 3.7 3.6 4V41c-2 .2-3.6 1.9-3.6 4v13c0 2.2 1.8 4 4 4h20.7c2.2 0 4-1.8 4-4V44.9c0-2.1-1.6-3.7-3.6-4v-9.7c2-.2 3.5-1.9 3.5-3.9M28.7 4h6.6v6.2h-6.6zm-9 23.3V14.2c0-1.1.9-2 2-2h20.7c1.1 0 2 .9 2 2v13.1c0 1.1-.9 2-2 2H21.7c-1.1 0-2-.9-2-2m3.5 13.6v-9.6h17.5v9.6zm21.1 4V58c0 1.1-.9 2-2 2H21.7c-1.1 0-2-.9-2-2V44.9c0-1.1.9-2 2-2h20.7c1 0 1.9.9 1.9 2" />
  </svg>
);
export default IconWaterBottle;
