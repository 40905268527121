import * as React from "react";
const IconHumidityAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M35.2 3.1c-1.9-1.5-4.5-1.5-6.4 0-6.7 5.3-22.3 19-22.3 33.3C6.4 50.5 17.9 62 32 62s25.6-11.5 25.6-25.6c0-14.3-15.7-28-22.4-33.3M30 4.7c.6-.5 1.3-.7 2-.7s1.4.2 2 .7C40.5 9.8 55.6 23 55.6 36.4v.7L49 33.3c-1.6-1-3.6-1-5.3 0l-5.3 3.1c-1 .6-2.3.6-3.3 0l-5.3-3.1c-1.6-1-3.7-1-5.3 0l-5.3 3.1c-1 .6-2.3.6-3.3 0l-7.1-4.2C11.5 20.3 24.2 9.2 30 4.7M32 60C19 60 8.4 49.4 8.4 36.4c0-.7 0-1.4.1-2.1l6.4 3.8c1.6 1 3.7 1 5.3 0l5.3-3.1c1-.6 2.3-.6 3.3 0l5.3 3.1c1.6 1 3.7 1 5.3 0l5.3-3.1c1-.6 2.3-.6 3.3 0l7.4 4.3C53.9 51 44 60 32 60" />
  </svg>
);
export default IconHumidityAlt;
