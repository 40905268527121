import * as React from "react";
const IconParcelAir = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2c-11.6 0-21.1 9.5-21.1 21.1 0 .6.4 1 1 1h2.5l6.1 13.3-.5.3c-.6.3-1.2.8-1.5 1.4-.3.5-.5 1.2-.5 1.9v12.2c0 1.3.7 2.6 1.9 3.2l10.6 5.3c.5.3 1 .4 1.6.4s1.1-.1 1.6-.4l10.6-5.3c1.2-.6 1.9-1.8 1.9-3.2V40.9c0-1.4-.8-2.6-2.1-3.2l-.6-.3 6.1-13.3h2.5c.6 0 1-.4 1-1C53.1 11.5 43.6 2 32 2M16.6 24.1H24v.1l4.4 9.6-6 2.8zm26.2 15.2-4.2 2-10.5-5.2 3.2-1.5c.2-.1.4-.1.6-.1s.4 0 .6.1zM32 44.6l-10.8-5.3 4.5-2.1 10.5 5.2zm1.5-11.8c-.9-.4-2-.4-2.9 0l-.4.2-4.1-8.9h11.7l-4 8.9zM20.7 54.5c-.5-.3-.8-.8-.8-1.4V40.9L31 46.3v13.4zm23.4-1.4c0 .6-.3 1.1-.8 1.4L33 59.7V46.3l4.5-2.2v4.2c0 .6.4 1 1 1s1-.4 1-1v-5.1l4.6-2.2zm-2.5-16.5-6-2.8 4.4-9.6v-.1h7.3zM49 22.1H13C13.5 12 21.8 4 32 4s18.5 8 19 18.1z" />
  </svg>
);
export default IconParcelAir;
