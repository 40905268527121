import * as React from "react";
const IconSz = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 900 600"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 0h900v600H0z" />
    <path  d="M0 100h900v400H0z" />
    <path  d="M0 150h900v300H0z" />
    <path
      
      d="M450 171.4v257.2c114.3 0 171.4-85.7 214.3-128.6-42.9-42.9-100-128.6-214.3-128.6"
    />
    <path d="M450 171.4c-100 0-171.4 85.7-214.3 128.6C278.6 342.9 350 428.6 450 428.6z" />
    <path  d="M346.3 254.3h21v91.3h-21zm51.9 0h21v91.3h-21z" />
    <path d="M477.8 254.3h21v91.3h-21zm51.9 0h21v91.3h-21z" />
  </svg>
);
export default IconSz;
