import * as React from "react";
const IconShirt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.6 16.3 48 5.7c-.2-.1-.4-.2-.6-.2h-7.5c-.6 0-1 .4-1 1v1.3c0 3.5-2.9 6.4-6.4 6.4s-6.4-2.9-6.4-6.4V6.4c0-.6-.4-1-1-1h-8.3c-.2 0-.4.1-.6.2L2.4 16.3c-.4.3-.5.8-.3 1.2l4.2 9.4c.2.5.8.7 1.3.5l7.5-3.2v33.4c0 .3.1.5.3.7s.4.3.7.3h31.7c.6 0 1-.4 1-1V24.2l7.5 3.2c.5.2 1.1 0 1.3-.5l4.2-9.4c.3-.4.2-.9-.2-1.2M17.2 56.6v-12L46.9 36v20.6zm39.1-31.5-8-3.4c-.3-.1-.7-.1-.9.1-.3.2-.4.5-.4.8v11.2l-29.7 8.6V22.7c0-.3-.2-.6-.4-.8-.3-.2-.6-.2-.9-.1l-8 3.4-3.5-7.8L17 7.4h7v.3c0 4.6 3.8 8.4 8.4 8.4 4.7 0 8.4-3.8 8.4-8.4v-.3H47l12.8 9.9z" />
  </svg>
);
export default IconShirt1;
