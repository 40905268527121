import * as React from "react";
const IconPackageBox = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 9.5H6c-2.2 0-4 1.8-4 4v5.8c0 2 1.5 3.7 3.4 3.9v27.4c0 2.2 1.8 4 4 4h45.2c2.2 0 4-1.8 4-4V23.1c1.9-.3 3.4-1.9 3.4-3.9v-5.8c0-2.2-1.8-3.9-4-3.9m-1.4 41c0 1.1-.9 2-2 2H9.4c-1.1 0-2-.9-2-2V23.2h49.2zM60 19.2c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2v-5.8c0-1.1.9-2 2-2h52c1.1 0 2 .9 2 2z" />
    <path d="M14 48.6h5.4c1.1 0 2-.9 2-2v-5.4c0-1.1-.9-2-2-2H14c-1.1 0-2 .9-2 2v5.4c0 1.1.9 2 2 2m0-7.4h5.4v5.4H14z" />
  </svg>
);
export default IconPackageBox;
