import * as React from "react";
const IconAddNotification = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M47.9 3.5c-6.8 0-12.5 4.9-13.8 11.3-2.8-1.7-6-2.6-9.4-2.8V7.1c0-.6-.4-1-1-1s-1 .4-1 1V12c-.5 0-.9 0-1.4.1-9.6 1.1-16.8 8.8-16.8 18V48c-.1.9-.3 1.5-.6 2l-1.7 2.8q-.6.9 0 1.8c.3.5.9.8 1.6.8h18.9v4.1c0 .6.4 1 1 1s1-.4 1-1v-4.1h19.1c.6 0 1.2-.3 1.5-.8.3-.4.4-1.2 0-1.8L43.5 50q-.6-1.05-.6-2.1V30.8c1.6.6 3.2.9 5 .9 7.8 0 14.1-6.3 14.1-14.1S55.7 3.5 47.9 3.5m-6.1 47.6 1.5 2.3H4.2l1.5-2.5c.4-.8.7-1.7.8-2.8V30c0-8.2 6.5-15 15-16 4.4-.5 8.7.6 12.2 2.9v.6c0 5.2 2.9 9.8 7.1 12.2v18.1c.1 1.2.4 2.3 1 3.3m6.1-21.4c-6.7 0-12.1-5.4-12.1-12.1S41.2 5.5 47.9 5.5 60 10.9 60 17.6s-5.4 12.1-12.1 12.1" />
    <path d="M51.6 16.6h-2.7v-2.7c0-.6-.4-1-1-1s-1 .4-1 1v2.7h-2.7c-.6 0-1 .4-1 1s.4 1 1 1h2.7v2.7c0 .6.4 1 1 1s1-.4 1-1v-2.7h2.7c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconAddNotification;
