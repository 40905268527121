import * as React from "react";
const IconThumbsDownCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.5 8.3c-4.4-2.9-10.9-4.6-17.6-4.6-1.2 0-2.2 0-3.5.1-10.1 1-14.4 2.6-16.3 3.8l-.8.6H5.7c-2 0-3.7 1.6-3.7 3.7v23.7c0 2 1.6 3.6 3.6 3.6h12.3c.5 0 .9.2 1.2.5C21.3 42 27 48.2 33 57.5c1.1 1.8 2.9 2.8 5 2.8h.5c2.2-.2 4.1-1.4 5-3.3 1.1-2.2.4-9.3-.6-14.8l-.1-.4h1.6c5.1.1 13.7.4 16-4.8 3.6-8.2 1.1-23.4-6.9-28.7M4 35.6V11.9c0-.9.8-1.7 1.7-1.7h6.9v27h-7c-.9 0-1.6-.7-1.6-1.6m54.5.6c-1.8 4-9.5 3.8-14.1 3.6h-2.9c-.3 0-.6.1-.8.4s-.3.5-.2.8l.4 1.6c1.4 7.7 1.3 12.5.8 13.5-.6 1.3-1.8 2.1-3.4 2.2-1.5.1-2.8-.5-3.6-1.8-6.1-9.5-11.9-15.9-14.1-18.2-.7-.7-1.6-1.1-2.6-1.1h-3.4v-27h1.1c.2 0 .4-.1.6-.2l1.1-.8c1.3-.9 5.1-2.5 15.3-3.4 1.1-.1 2.1-.1 3.2-.1 6.4 0 12.4 1.6 16.5 4.3 7.1 4.7 9.4 18.8 6.1 26.2" />
  </svg>
);
export default IconThumbsDownCopy;
