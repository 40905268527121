import React, { useState } from 'react';
import { BringzzPageContainer, BringzzButton, BringzzText, BringzzImage } from '@bringzz/components';
import { useNavigation } from 'context/NavigationContext';

const InstructionPage = () => {
  const { backPress, navigate } = useNavigation();
  const [currentStep, setCurrentStep] = useState(0)

  const onStart = () => {
    setCurrentStep(currentStep + 1)
  }
  const totalSteps = [1, 2, 3]

  const title = "someinthg"
  const description = 'aachour'
  const onSkip = true

  return (
    <BringzzPageContainer
      className="h-full flex flex-col"
      header={{
        right: onSkip && (
          <BringzzButton
            variant="text"
            className="text-gray-600"
            onClick={onSkip}
          >
            Skip
          </BringzzButton>
        )
      }}
      footer={
        <div className="px-4">
          <BringzzButton
            size="lg"
            className="bg-magic-lilac w-full flex justify-center text-midnight-blue"
            onClick={onStart}
          >
            {"Continue"}
          </BringzzButton>
        </div>
      }
    >
      <div className="flex-1 flex flex-col items-center justify-center px-6 text-center">
        <div className="mb-6">
          <BringzzImage
            src="/thumbs-up-illustration.svg"
            alt="Instruction illustration"
            className="w-32 h-32"
          />
        </div>

        {/* Progress dots */}
        <div className="flex justify-center space-x-1 mb-8">
          {[...Array(totalSteps)].map((_, index) => (
            <div
              key={index}
              className={`${index + 1 === currentStep
                ? "w-8 h-1 bg-black"
                : "w-1 h-1 bg-gray-300"
                } rounded-full`}
            />
          ))}
        </div>

        <BringzzText
          tag="h1"
          className="text-2xl font-bold mb-4"
        >
          {title}
        </BringzzText>

        <BringzzText
          tag="h4"
          className="text-midnight-blue/25 font-normal"
        >
          {description}
        </BringzzText>
      </div>
    </BringzzPageContainer>
  );
};

export default InstructionPage; 