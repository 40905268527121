import * as React from "react";
const IconNailPolish1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M48.2 28.9c-1.2-1.4-3-2.3-4.9-2.3h-4.8v-8.1c0-.2-.1-.3-.1-.5 1-1.3 1.5-2.9 1.5-4.6 0-4.1-6.4-10.5-7.1-11.2-.4-.4-1-.4-1.4 0-.7.7-7.1 7.1-7.1 11.2 0 1.7.5 3.3 1.5 4.6-.1.1-.1.3-.1.5v8.1h-4.8c-1.9 0-3.7.8-4.9 2.3s-1.7 3.3-1.4 5.2L18 56.6c.5 3.1 3.2 5.4 6.3 5.4h15.3c3.2 0 5.8-2.3 6.3-5.4l3.7-22.5c.3-1.8-.2-3.7-1.4-5.2M32 4.4c2.1 2.2 5.8 6.6 5.8 9.1 0 1.5-.6 3-1.7 4.1h-8.3c-1.1-1.1-1.7-2.5-1.7-4.1.1-2.4 3.8-6.9 5.9-9.1m-4.5 15.2h9v7.1h-9zm20.2 14.2L44 56.3c-.3 2.1-2.2 3.7-4.4 3.7H24.4c-2.2 0-4-1.6-4.4-3.7l-3.7-22.5c-.2-1.3.2-2.6 1-3.6s2.1-1.6 3.4-1.6h22.6c1.3 0 2.5.6 3.4 1.6.8 1 1.2 2.3 1 3.6" />
  </svg>
);
export default IconNailPolish1;
