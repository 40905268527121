import * as React from "react";
const IconPercentageAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M34.3 4.7c-7.6 0-14.9 3.2-20.1 8.8-.4.4-.3 1 .1 1.4s1 .3 1.4-.1c4.8-5.2 11.6-8.2 18.6-8.2 14 0 25.3 11.4 25.3 25.3 0 14-11.4 25.3-25.3 25.3-11 0-20.7-7-24.1-17.5-.2-.4-.8-.7-1.3-.6-.5.2-.8.7-.6 1.3 3.7 11.3 14.1 18.9 26 18.9C49.4 59.3 61.6 47 61.6 32S49.3 4.7 34.3 4.7" />
    <path d="m9.8 38.2 7.1 2.9c.5.2 1.1 0 1.3-.5s0-1.1-.5-1.3l-7.1-2.9q-1.5-.6-3 0c-1 .4-1.7 1.2-2.1 2.1l-2.9 7.1c-.2.5 0 1.1.5 1.3.1.1.2.1.3.1.4 0 .8-.2.9-.6l2.9-7.1c.2-.5.6-.9 1-1.1.6-.2 1.1-.2 1.6 0M29.9 38.4c.3 0 .5-.1.7-.3l11.2-11.2c.4-.4.4-1 0-1.4s-1-.4-1.4 0L29.1 36.7c-.4.4-.4 1 0 1.4.2.2.5.3.8.3M28.5 22.8c-.3-.5-.9-.6-1.4-.3s-.6.9-.3 1.4l.3.5c.2.3.5.4.8.4.2 0 .4-.1.6-.2.5-.3.6-.9.3-1.4zM44.3 41.6c.3 0 .6-.1.8-.4.3-.4.2-1.1-.2-1.4l-.5-.3c-.4-.3-1.1-.2-1.4.2s-.2 1.1.2 1.4l.5.3c.2.2.4.2.6.2" />
  </svg>
);
export default IconPercentageAlt;
