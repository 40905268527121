import React from 'react';
import {
    BringzzPageContainer,
    BringzzIcon,
    BringzzText,
    BringzzImage,
    BringzzButton
} from '@bringzz/components';
import { useNavigation } from 'context/NavigationContext';
import { useParams } from 'react-router-dom';

const ResultArticlePage = () => {
    const { navigate } = useNavigation()
    const { result } = useParams();

    return (
        <BringzzPageContainer
            header={{
                left: (
                    <BringzzIcon
                        size='22'
                        icon='IconClose'
                        folder='LineIcons'
                        className='cursor-pointer'
                        onClick={() => navigate('/')}
                    />
                ),
            }}
            footer={<div className='pb-28 px-6'>
                <BringzzButton size='lg' className='bg-magic-lilac w-full flex justify-center' onClick={() => navigate('/')}>Close</BringzzButton>
            </div>}
        >
            <div className='p-4 flex flex-col space-y-4 h-full justify-center items-center'>
                <div className='flex-2 w-2/4 h-full' >
                    <BringzzImage className='w-full' src='/images/default-image.png' alt='Image 1' />

                </div>
                <div className='flex-1 px-6 h-full justify-center items-center' >
                    <BringzzText tag='h1' className='text-center'>
                        Your article has been {result}!
                    </BringzzText>
                </div>
                <div className='flex-1 px-6 h-full justify-center items-center' >
                    <BringzzText tag='h4' className='text-center font-thin'>
                        Convallis malesuada arcu urna morbi at lorem pellentesque vitae eu. Arcu egestas consectetur mattis sit neque faucibus pharetra. Nullam amet.
                    </BringzzText>

                </div>
            </div>
        </BringzzPageContainer>
    );
};

export default ResultArticlePage;
