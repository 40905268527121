import React, { useEffect, useState } from 'react';
import {
	BringzzPageContainer,
	BringzzIcon,
	BringzzText,
	BringzzImage,
	BringzzPostingCard,
	BringzzButton,
	BringzzModal
} from '@bringzz/components';
import { useNavigation } from 'context/NavigationContext';
import { useLocation } from 'react-router-dom';
import useRequest from 'hooks/useRequest';
import AvatarExample from 'assets/images/AvatarExample.png';
import PostExample1 from 'assets/images/PostExample1.png';

import useUser from 'hooks/useUser';
function useQuery() {
	return new URLSearchParams(useLocation().search);
}
let state = {
	checked: <div></div>,
	unChecked: <div></div>
};
const PostSuccess = () => {
	const { backPress } = useNavigation();
	let query = useQuery();
	const { sendRequest } = useRequest();
	const { getUser } = useUser();
	const { navigate } = useNavigation();
	let id = query.get('id');
	const [post, setPost] = useState(null);
	const [modalOpen, setModalOpen] = useState(false);
	const postsArray = [
		{
			uuid: '1',
			type: 3,
			headline: 'In elit diam tellus neque diam mattis nam. Tristique eleitend euismod est.',
			description: 'nutun laue ous phareud ac nund ulancorper door vesticuun pellentesque. Inter dum lorem st ulamcorper dienissim consequatpharetra id cact lacus. Ad piscna.',
			content: 'string',
			release: new Date(),
			likesHidden: false,
			viewsHidden: false,
			commentsHidden: false,
			PostImg: '',
			PostImgAlt: '',
			validFrom: new Date(), // -> for type 1
			validTilpost: new Date(), // -> for type 1
			products: [], // -> for type 1
			link: 'string', // -> for type 2
			img_caption: 'string' // -> for type 3
		},
		{
			uuid: '2',
			type: 3,
			headline: 'In elit diam tellus neque diam mattis nam. Tristique eleitend euismod est.',
			description: 'nutun laue ous phareud ac nund ulancorper door vesticuun pellentesque. Inter dum lorem st ulamcorper dienissim consequatpharetra id cact lacus. Ad piscna.',
			content: 'string',
			release: new Date(),
			likesHidden: false,
			viewsHidden: false,
			commentsHidden: false,
			PostImg: '',
			PostImgAlt: '',
			validFrom: new Date(), // -> for type 1
			validTilpost: new Date(), // -> for type 1
			products: [], // -> for type 1
			link: 'string', // -> for type 2
			img_caption: 'string' // -> for type 3
		},
		{
			uuid: '3',
			type: 3,
			headline: 'In elit diam tellus neque diam mattis nam. Tristique eleitend euismod est.',
			description: 'nutun laue ous phareud ac nund ulancorper door vesticuun pellentesque. Inter dum lorem st ulamcorper dienissim consequatpharetra id cact lacus. Ad piscna.',
			content: 'string',
			release: new Date(),
			likesHidden: false,
			viewsHidden: false,
			commentsHidden: false,
			PostImg: '',
			PostImgAlt: '',
			validFrom: new Date(), // -> for type 1
			validTilpost: new Date(), // -> for type 1
			products: [], // -> for type 1
			link: 'string', // -> for type 2
			img_caption: 'string' // -> for type 3
		},
		{
			uuid: '4',
			type: 2,
			headline: 'In elit diam tellus neque diam mattis nam. Tristique eleitend euismod est.',
			description: 'nutun laue ous phareud ac nund ulancorper door vesticuun pellentesque. Inter dum lorem st ulamcorper dienissim consequatpharetra id cact lacus. Ad piscna.',
			content: 'string',
			release: new Date(),
			likesHidden: false,
			viewsHidden: false,
			commentsHidden: false,
			PostImg: '',
			PostImgAlt: '',
			validFrom: new Date(), // -> for type 1
			validTilpost: new Date(), // -> for type 1
			products: [], // -> for type 1
			link: 'string', // -> for type 2
			img_caption: 'string' // -> for type 3
		},
		{
			uuid: '5',
			type: 2,
			headline: 'In elit diam tellus neque diam mattis nam. Tristique eleitend euismod est.',
			description: 'nutun laue ous phareud ac nund ulancorper door vesticuun pellentesque. Inter dum lorem st ulamcorper dienissim consequatpharetra id cact lacus. Ad piscna.',
			content: 'string',
			release: new Date(),
			likesHidden: false,
			viewsHidden: false,
			commentsHidden: false,
			PostImg: '',
			PostImgAlt: '',
			validFrom: new Date(), // -> for type 1
			validTilpost: new Date(), // -> for type 1
			products: [], // -> for type 1
			link: 'string', // -> for type 2
			img_caption: 'string' // -> for type 3
		},
		{
			uuid: '6',
			type: 2,
			headline: 'In elit diam tellus neque diam mattis nam. Tristique eleitend euismod est.',
			description: 'nutun laue ous phareud ac nund ulancorper door vesticuun pellentesque. Inter dum lorem st ulamcorper dienissim consequatpharetra id cact lacus. Ad piscna.',
			content: 'string',
			release: new Date(),
			likesHidden: false,
			viewsHidden: false,
			commentsHidden: false,
			PostImg: '',
			PostImgAlt: '',
			validFrom: new Date(), // -> for type 1
			validTilpost: new Date(), // -> for type 1
			products: [], // -> for type 1
			link: 'string', // -> for type 2
			img_caption: 'string' // -> for type 3
		},
		{
			uuid: '7',
			type: 1,
			headline: 'In elit diam tellus neque diam mattis nam. Tristique eleitend euismod est.',
			description: 'nutun laue ous phareud ac nund ulancorper door vesticuun pellentesque. Inter dum lorem st ulamcorper dienissim consequatpharetra id cact lacus. Ad piscna.',
			content: 'string',
			release: new Date(),
			likesHidden: false,
			viewsHidden: false,
			commentsHidden: false,
			PostImg: '',
			PostImgAlt: '',
			validFrom: new Date(), // -> for type 1
			validTilpost: new Date(), // -> for type 1
			products: [], // -> for type 1
			link: 'string', // -> for type 2
			img_caption: 'string' // -> for type 3
		},
		{
			uuid: '8',
			type: 1,
			headline: 'In elit diam tellus neque diam mattis nam. Tristique eleitend euismod est.',
			description: 'nutun laue ous phareud ac nund ulancorper door vesticuun pellentesque. Inter dum lorem st ulamcorper dienissim consequatpharetra id cact lacus. Ad piscna.',
			content: 'string',
			release: new Date(),
			likesHidden: false,
			viewsHidden: false,
			commentsHidden: false,
			PostImg: '',
			PostImgAlt: '',
			validFrom: new Date(), // -> for type 1
			validTilpost: new Date(), // -> for type 1
			products: [], // -> for type 1
			link: 'string', // -> for type 2
			img_caption: 'string' // -> for type 3
		},
		{
			uuid: '9',
			type: 1,
			headline: 'In elit diam tellus neque diam mattis nam. Tristique eleitend euismod est.',
			description: 'nutun laue ous phareud ac nund ulancorper door vesticuun pellentesque. Inter dum lorem st ulamcorper dienissim consequatpharetra id cact lacus. Ad piscna.',
			content: 'string',
			release: new Date(),
			likesHidden: false,
			viewsHidden: false,
			commentsHidden: false,
			PostImg: '',
			PostImgAlt: '',
			validFrom: new Date(), // -> for type 1
			validTilpost: new Date(), // -> for type 1
			products: [], // -> for type 1
			link: 'string', // -> for type 2
			img_caption: 'string' // -> for type 3
		}
	]
	useEffect(() => {
		if (id) {
			postsArray.map((post) => {
				if (post.uuid == id) {
					setPost(post);
				}
			})
		}

	}, [id])
	return post && (
		<BringzzPageContainer
			header={{
				left: (
					<BringzzIcon
						size='22'
						icon='IconClose'
						folder='LineIcons'
						className='cursor-pointer'
						onClick={() => navigate('/')}
					/>
				),
			}}
		>
			<div className='p-4 flex flex-col space-y-4 h-full justify-center items-center'>
				<div className='flex-2 w-2/3 h-full' >
					<BringzzImage className='w-full' src='/images/default-image.png' alt='Image 1' />

				</div>
				<div className='flex-1 w-full h-full  justify-center items-center' >
					<BringzzText tag='h1' className='text-center'>
						Your post has been {post.type == 3 ? 'scheduled' : 'published'}!
					</BringzzText>

				</div>
				<div className='flex-1 w-full h-full  justify-center items-center' >
					<BringzzText tag='h4' className='text-center font-thin'>
						Convallis malesuada arcu urna morbi at lorem pellentesque vitae eu. Arcu egestas consectetur mattis sit neque faucibus pharetra. Nullam amet.
					</BringzzText>

				</div>
			</div>
		</BringzzPageContainer>
	);
};

export default PostSuccess;
