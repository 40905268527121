import * as React from "react";
const IconDollarMonitor = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M30.3 18.8h7.2c.6 0 1-.4 1-1s-.4-1-1-1h-3.4v-2.3c0-.6-.4-1-1-1s-1 .4-1 1v2.3h-1.8c-2.7 0-4.9 2.2-4.9 4.8s2.2 4.8 4.9 4.8h3.3c1.6 0 2.9 1.3 2.9 2.8 0 1.6-1.3 2.8-2.9 2.8h-7.2c-.6 0-1 .4-1 1s.4 1 1 1H32v2.3c0 .6.4 1 1 1s1-.4 1-1V34c2.5-.2 4.4-2.3 4.4-4.8 0-2.7-2.2-4.8-4.9-4.8h-3.3c-1.6 0-2.9-1.3-2.9-2.8s1.5-2.8 3-2.8" />
    <path d="M58 3.7H6c-2.2 0-4 1.8-4 4v34.1c0 2.2 1.8 4 4 4h25v12.5h-9.7c-.6 0-1 .4-1 1s.4 1 1 1h21.5c.6 0 1-.4 1-1s-.4-1-1-1H33V45.8h25c2.2 0 4-1.8 4-4V7.7c0-2.2-1.8-4-4-4m2 38.1c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V7.7c0-1.1.9-2 2-2h52c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconDollarMonitor;
