import * as React from "react";
const IconDress2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.4 50.9 46.6 27.4l4.5-24.2c.1-.3 0-.6-.2-.8-.2-.3-.4-.4-.7-.4h-12c-.6 0-1 .4-1 1v3.7c0 2.9-2.3 5.2-5.2 5.2s-5.2-2.3-5.2-5.2V3c0-.6-.4-1-1-1H13.9c-.3 0-.6.1-.8.4-.2.2-.3.5-.2.8l4.5 24L4.6 50.8c-.2.4-.1.9.2 1.2l4.9 4.2c1.8 1.6 4.2 2.5 6.6 2.5h4.6c1.2 0 2.3.3 3.4.7l3.5 1.6c1.3.6 2.8.9 4.2.9s2.9-.3 4.2-.9l3.5-1.6c1.1-.5 2.2-.7 3.4-.7h4.6c2.4 0 4.7-.9 6.6-2.4l4.9-4.2c.3-.3.4-.8.2-1.2M15.1 4h9.6v2.7c0 4 3.2 7.2 7.2 7.2s7.2-3.2 7.2-7.2V4H49l-4.2 22.4H19.3zM53 54.8c-1.5 1.3-3.4 1.9-5.3 2h-4.6c-1.4 0-2.9.3-4.2.9l-3.5 1.6c-2.2 1-4.6 1-6.8 0l-3.5-1.6q-1.95-.9-4.2-.9h-4.6c-2 0-3.8-.7-5.3-2l-4.3-3.7 12.4-22.7h25.8l12.4 22.8z" />
  </svg>
);
export default IconDress2;
