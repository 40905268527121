import * as React from "react";
const IconChestOfDrawers2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.8 2H9.2c-2.8 0-5 2.2-5 5v45.3c0 2.8 2.2 5 5 5h.3V61c0 .6.4 1 1 1s1-.4 1-1v-3.7h39.7V61c0 .6.4 1 1 1s1-.4 1-1v-3.7h1.7c2.8 0 5-2.2 5-5V7c-.1-2.8-2.3-5-5.1-5M6.2 22.1h25.2v15.5H6.2zm27.2 0h24.4v15.5H33.4zM57.8 7v13.1H33.4V4h21.4c1.7 0 3 1.3 3 3M9.2 4h22.2v16.1H6.2V7c0-1.7 1.3-3 3-3m-3 48.3V39.6h25.2v15.5c0 .1 0 .2.1.2H9.2c-1.7 0-3-1.3-3-3m48.6 3H33.4c0-.1.1-.2.1-.2V39.6h24.4v12.7c-.1 1.7-1.4 3-3.1 3" />
    <path d="M44.8 46.4c-.6 0-1 .4-1 1v2c0 .6.4 1 1 1s1-.4 1-1v-2c0-.6-.4-1-1-1M20 46.4c-.6 0-1 .4-1 1v2c0 .6.4 1 1 1s1-.4 1-1v-2c0-.6-.4-1-1-1M44.8 28.9c-.6 0-1 .4-1 1v2c0 .6.4 1 1 1s1-.4 1-1v-2c0-.6-.4-1-1-1M20 28.9c-.6 0-1 .4-1 1v2c0 .6.4 1 1 1s1-.4 1-1v-2c0-.6-.4-1-1-1M44.8 14.1c.6 0 1-.4 1-1v-2c0-.6-.4-1-1-1s-1 .4-1 1v2c0 .6.5 1 1 1M20 14.1c.6 0 1-.4 1-1v-2c0-.6-.4-1-1-1s-1 .4-1 1v2c0 .6.4 1 1 1" />
  </svg>
);
export default IconChestOfDrawers2;
