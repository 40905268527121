import * as React from "react";
const IconMergeHorizontal = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M48 31H16c-.6 0-1 .4-1 1s.4 1 1 1h32c.6 0 1-.4 1-1s-.5-1-1-1M31.4 17.1c.2.2.4.2.6.2s.5-.1.6-.2l14.3-12c.4-.4.5-1 .1-1.4s-1-.5-1.4-.1L32 15 18.4 3.5c-.4-.4-1.1-.3-1.4.1-.4.4-.3 1.1.1 1.4zM32.6 46.9c-.4-.3-.9-.3-1.3 0L17 58.9c-.4.4-.5 1-.1 1.4s1 .5 1.4.1L32 49l13.6 11.5c.2.2.4.2.6.2.3 0 .6-.1.8-.4.4-.4.3-1.1-.1-1.4z" />
  </svg>
);
export default IconMergeHorizontal;
