import * as React from "react";
const IconShareCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M29.5 41.3c.6 0 1-.4 1-1v-15c0-6.1 4.9-11 11-11h15l-8 7.6c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l10-9.4c.2-.2.3-.5.3-.7 0-.3-.1-.5-.3-.7l-10-9.2c-.4-.4-1-.4-1.4.1-.4.4-.4 1 .1 1.4l8.2 7.6H41.6c-7.2 0-13 5.8-13 13v15c-.1.5.4.9.9.9" />
    <path d="M59 39.3c-.6 0-1 .4-1 1v14.5c0 2.3-1.9 4.2-4.2 4.2H10.2C7.8 59 6 57.1 6 54.8V40.3c0-.6-.4-1-1-1s-1 .4-1 1v14.5c0 3.4 2.8 6.2 6.2 6.2h43.7c3.4 0 6.2-2.8 6.2-6.2V40.3c-.1-.5-.5-1-1.1-1" />
  </svg>
);
export default IconShareCopy;
