import * as React from "react";
const IconLaptopAltKeyboard = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62 52.4v-.5l-5-13.2c0-.1-.1-.2-.2-.3V10c0-2.5-2-4.5-4.4-4.5H11.6c-2.4 0-4.4 2-4.4 4.5v28.4c-.1.1-.2.2-.2.3L2.1 51.9c-.1.1-.1.3-.1.5v5.2c0 .6.4 1 1 1h58c.6 0 1-.4 1-1zM9.2 10c0-1.4 1.1-2.5 2.4-2.5h40.8c1.3 0 2.4 1.1 2.4 2.5v28.3H9.2zM60 56.6H4v-4.1l4.5-12.1h46.9l4.6 12z" />
    <path d="M34 13.8h-4c-.6 0-1 .4-1 1s.4 1 1 1h4c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconLaptopAltKeyboard;
