import * as React from "react";
const IconTweezers = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 49.8 35.9 24.7c-1.9-1.9-4.9-1.9-6.8 0l-.6.6-10.7-10.8c-1-1-1.6-2.4-1.6-3.8 0-4.1-3.3-7.4-7.4-7.4H2.6c-.6 0-1 .4-1 1s.4 1 1 1h6.2c3 0 5.4 2.4 5.4 5.4 0 1.9.8 3.9 2.2 5.2l10.8 10.8-.7.7c-1.9 1.9-1.9 4.9 0 6.8l25.1 25.1c.9.9 2.2 1.4 3.4 1.4s2.5-.5 3.4-1.4l2.7-2.7c1.8-1.9 1.8-4.9-.1-6.8m-1.4 5.4-2.7 2.7c-1.1 1.1-2.9 1.1-4 0l-25-25.1c-1.1-1.1-1.1-2.9 0-4l1.4-1.4h.1c.1 0 0 0 0-.1l1.2-1.2c.5-.5 1.3-.8 2-.8s1.4.3 2 .8l25.1 25.1c1 1.1 1 2.9-.1 4" />
  </svg>
);
export default IconTweezers;
