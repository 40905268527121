import * as React from "react";
const IconCent = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
    <path d="M40.3 25.8c.3.4 1 .5 1.4.2s.5-1 .2-1.4c-1.4-1.8-3.1-3.1-5.1-4l1.5-5.5c.1-.5-.2-1.1-.7-1.2s-1.1.2-1.2.7L34.9 20c-.9-.2-1.9-.3-2.9-.3-3.9 0-7.5 1.8-9.9 4.9-1.6 2.1-2.5 4.7-2.5 7.5s.9 5.3 2.5 7.4c1.6 2.2 3.8 3.7 6.3 4.4L27.3 48c-.1.5.2 1.1.7 1.2h.3c.4 0 .8-.3 1-.7l1.1-4.2c.5.1 1.1.1 1.6.1 3.9 0 7.5-1.8 9.9-4.9.3-.4.2-1.1-.2-1.4s-1.1-.2-1.4.2c-2 2.6-5 4.1-8.3 4.1-.4 0-.7 0-1.1-.1l5.3-19.8c1.6.7 3 1.8 4.1 3.3M29 41.9c-2.1-.6-3.9-1.9-5.3-3.7s-2.1-4-2.1-6.2c0-2.3.7-4.4 2.1-6.2 2-2.6 5-4.1 8.3-4.1.8 0 1.6.1 2.3.3z" />
  </svg>
);
export default IconCent;
