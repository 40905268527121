import * as React from "react";
const IconUploadAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60 42.8c-.6 0-1 .4-1 1v8.9c0 1.6-1.3 2.9-2.9 2.9H7.9c-1.6 0-2.9-1.3-2.9-2.9v-8.9c0-.6-.4-1-1-1s-1 .4-1 1v8.9c0 2.7 2.2 4.9 4.9 4.9h48.3c2.7 0 4.9-2.2 4.9-4.9v-8.9c-.1-.6-.5-1-1.1-1" />
    <path d="M17.7 22.4c.3 0 .5-.1.7-.3L31 9.9v32.4c0 .6.4 1 1 1s1-.4 1-1V9.8l12.6 12.3c.4.4 1 .4 1.4 0s.4-1 0-1.4L32.7 6.8c-.4-.4-1-.4-1.4 0L17 20.7c-.4.4-.4 1 0 1.4.1.2.4.3.7.3" />
  </svg>
);
export default IconUploadAlt;
