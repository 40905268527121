import * as React from "react";
const IconChatAlt3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M30.7 58c-.3 0-.6-.1-.9-.2C10.1 48.9 2 40.2 2 28 2 15.9 12.2 6 24.8 6h14.5C51.8 6 62 15.9 62 28S51.8 50 39.3 50h-6c-.1 0-.3.1-.3.3v5.5c0 .8-.4 1.5-1 1.9-.4.2-.8.3-1.3.3M24.8 8C13.3 8 4 17 4 28c0 11.5 7.5 19.3 26.6 28h.2s.1-.1.1-.2v-5.5c0-1.2 1-2.3 2.3-2.3h6C50.7 48 60 39 60 28S50.7 8 39.2 8z" />
  </svg>
);
export default IconChatAlt3;
