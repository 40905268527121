import * as React from "react";
const IconClockDollar = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M39.3 37.1h6.2c.6 0 1-.4 1-1s-.4-1-1-1h-2.8v-1.8c0-.6-.4-1-1-1s-1 .4-1 1v1.8h-1.4c-2.4 0-4.3 1.9-4.3 4.3s1.9 4.3 4.3 4.3h2.9c1.3 0 2.3 1 2.3 2.3s-1 2.3-2.3 2.3H36c-.6 0-1 .4-1 1s.4 1 1 1h4.7v1.8c0 .6.4 1 1 1s1-.4 1-1v-1.9c2.1-.3 3.8-2.1 3.8-4.3 0-2.4-1.9-4.3-4.3-4.3h-2.9c-1.3 0-2.3-1-2.3-2.3s1-2.2 2.3-2.2" />
    <path d="M41.9 23.5c.1-.8.2-1.6.2-2.4C42.1 10.6 33.6 2 23 2 12.5 2 4 10.6 4 21.1c0 10 7.8 18.3 17.7 19-.1.9-.2 1.7-.2 2.6 0 10.6 8.7 19.3 19.3 19.3S60 53.3 60 42.7c0-10.2-8-18.6-18.1-19.2M6 21.1C6 11.7 13.6 4 23 4s17.1 7.7 17.1 17.1c0 .8-.1 1.6-.2 2.4-8.7.3-15.9 6.4-17.9 14.6-8.9-.5-16-8-16-17M40.7 60c-9.5 0-17.3-7.8-17.3-17.3s7.8-17.3 17.3-17.3S58 33.2 58 42.7 50.3 60 40.7 60" />
    <path d="M29.3 22.2c.5 0 1-.4 1-1s-.4-1-1-1l-5.9-.1v-8.3c0-.6-.4-1-1-1s-1 .4-1 1v9.3c0 .5.4 1 1 1z" />
  </svg>
);
export default IconClockDollar;
