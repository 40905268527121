import * as React from "react";
const IconShoppingBag2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M30.1 31.7h8.2c.6 0 1-.4 1-1s-.4-1-1-1h-4.1v-2.8c0-.6-.4-1-1-1s-1 .4-1 1v2.8H30c-3 0-5.4 2.4-5.4 5.4s2.4 5.4 5.4 5.4h3.8c1.9 0 3.4 1.5 3.4 3.4s-1.5 3.4-3.4 3.4h-8.2c-.6 0-1 .4-1 1s.4 1 1 1h6.6V52c0 .6.4 1 1 1s1-.4 1-1v-2.8c2.8-.2 5.1-2.5 5.1-5.4 0-3-2.4-5.4-5.4-5.4h-3.8c-1.9 0-3.4-1.5-3.4-3.4 0-1.8 1.5-3.3 3.4-3.3" />
    <path d="M50 14h-7.1v-1.1C42.9 6.9 38 2 32 2S21.1 6.9 21.1 12.9V14H14c-3.3 0-6 2.7-6 6v29.4c0 7 5.7 12.6 12.6 12.6h22.8C50.3 62 56 56.4 56 49.4V20c0-3.3-2.7-6-6-6m-26.9-1.1C23.1 8 27.1 4 32 4s8.9 4 8.9 8.9V14H23.1zM54 49.4C54 55.2 49.2 60 43.4 60H20.6C14.8 60 10 55.2 10 49.4V20c0-2.2 1.8-4 4-4h7.1v5.2c0 .6.4 1 1 1s1-.4 1-1V16H41v5.2c0 .6.4 1 1 1s1-.4 1-1V16h7c2.2 0 4 1.8 4 4z" />
  </svg>
);
export default IconShoppingBag2;
