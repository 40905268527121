import * as React from "react";
const IconAddServer = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 65 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M45.3 26.6H45V11.2C45 5.9 34.1 3 23.8 3S2.5 5.9 2.5 11.2v25.4c0 5.2 10.8 8.3 21.2 8.3 1.5 0 3-.1 4.4-.2.5 9.1 8 16.3 17.2 16.3 9.5 0 17.2-7.7 17.2-17.2s-7.7-17.2-17.2-17.2M4.5 14.9c3.6 3 11.6 4.6 19.3 4.6 7.6 0 15.6-1.6 19.2-4.6v5.5c-.1 2.9-7.9 6.2-19.2 6.2S4.5 23.3 4.5 20.4zM23.8 5C35.1 5 43 8.3 43 11.2c-.1 2.9-7.9 6.2-19.2 6.2S4.5 14.1 4.5 11.2 12.4 5 23.8 5M4.5 24c3.6 3 11.6 4.6 19.3 4.6 7.6 0 15.6-1.6 19.2-4.6v2.8c-5 .7-9.3 3.5-12 7.5-2.3.3-4.8.5-7.3.5-11.3 0-19.3-3.3-19.3-6.2V24zm0 12.6v-4.5c3.6 3 11.6 4.6 19.3 4.6 2 0 4.1-.1 6-.3-.9 1.9-1.5 4.1-1.6 6.3-1.4.1-2.9.2-4.4.2-11.1 0-19.3-3.3-19.3-6.3M45.3 59c-8.4 0-15.2-6.8-15.2-15.2s6.8-15.2 15.2-15.2 15.2 6.8 15.2 15.2S53.7 59 45.3 59" />
    <path d="M49.7 42.8h-3.4v-3.4c0-.6-.4-1-1-1s-1 .4-1 1v3.4h-3.4c-.6 0-1 .4-1 1s.4 1 1 1h3.4v3.4c0 .6.4 1 1 1s1-.4 1-1v-3.4h3.4c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconAddServer;
