import React, { useState, useRef, useEffect, useCallback } from "react";
import BringzzImage from "../BringzzImage";

/**
 * Carousel component for image sliding.
 *
 * @component
 * @param {Object} props
 * @param {Array} props.images - Array of image URLs and clickhandlers for the carousel.
 * @param {Array} [props.sections] - Array of custom sections for the carousel (used in custom mode).
 * @param {boolean} [props.autoSlide=false] - Enable automatic sliding.
 * @param {number} [props.interval=3000] - Time interval in ms for auto sliding.
 * @param {React.ReactNode} [props.topLeft] - React Node to be rendered at the top left corner.
 * @param {React.ReactNode} [props.topRight] - React Node to be rendered at the top right corner.
 * @param {React.ReactNode} [props.bottomLeft] - React Node to be rendered at the bottom left corner.
 * @param {React.ReactNode} [props.bottomRight] - React Node to be rendered at the bottom right corner.
 * @param {React.ReactNode} [props.topCenter] - React Node to be rendered at the top center.
 * @param {React.ReactNode} [props.bottomCenter] - React Node to be rendered at the bottom center.
 * @param {string} [props.mode="default"] - Mode of the carousel, either "default" or "custom".
 *
 * @returns {React.ReactElement} Rendered BringzzImageSlider component.
 */
const BringzzImageSlider = ({
  images,
  sections,
  autoSlide = false,
  interval = 3000,
  topLeft,
  topRight,
  bottomLeft,
  bottomRight,
  topCenter,
  bottomCenter,
  mode = "default",
}) => {
  // States for carousel functionality
  const [currentIndex, setCurrentIndex] = useState(1); // Start from second item (first real item)
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [currentTranslate, setCurrentTranslate] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const sliderRef = useRef(null);

  // Select content based on mode
  const content = mode === "custom" ? sections : images;

  // Add first and last item to the start and end for looping
  const loopedContent = [content[content.length - 1], ...content, content[0]];

  // Navigate to a specific index in the carousel
  const goToIndex = useCallback((index, isSilent = false) => {
    const newTranslate = -index * sliderRef.current.offsetWidth;

    if (isSilent) {
      // Silent transition for looping
      setCurrentTranslate(newTranslate);
      requestAnimationFrame(() => {
        setCurrentIndex(index);
      });
    } else {
      setCurrentIndex(index);
      setCurrentTranslate(newTranslate);
    }
  }, []);

  // Adjust carousel position on window resize
  useEffect(() => {
    goToIndex(currentIndex);
  }, [currentIndex, goToIndex]);

  // Handle auto-sliding feature
  useEffect(() => {
    if (autoSlide && !isPaused && !isDragging) {
      const timer = setTimeout(() => {
        const newIndex = currentIndex < content.length ? currentIndex + 1 : 1;
        goToIndex(newIndex);
      }, interval);
      return () => clearTimeout(timer);
    }
  }, [currentIndex, autoSlide, isPaused, isDragging, interval, content.length]);

  // Start dragging
  const handleDragStart = (e) => {
    setIsDragging(true);
    setStartX(e.type.includes("mouse") ? e.pageX : e.touches[0].clientX);
  };

  // Handle dragging motion
  const handleDragMove = (e) => {
    if (isDragging) {
      const currentPosition = e.type.includes("mouse")
        ? e.pageX
        : e.touches[0].clientX;
      const newTranslate =
        currentPosition - startX - currentIndex * sliderRef.current.offsetWidth;
      setCurrentTranslate(newTranslate);
    }
  };

  // Finish dragging
  const handleDragEnd = () => {
    setIsDragging(false);
    const movedBy =
      currentTranslate + currentIndex * sliderRef.current.offsetWidth;
    determineNextSlide(movedBy);
  };

  // Determine which slide to navigate to after dragging
  const determineNextSlide = (movedBy) => {
    let newIndex = currentIndex;

    if (movedBy < -100) {
      newIndex = currentIndex + 1;
    } else if (movedBy > 100) {
      newIndex = currentIndex - 1;
    }

    adjustForLooping(newIndex);
  };

  // Adjust index for looping effect
  const adjustForLooping = (newIndex) => {
    if (newIndex >= loopedContent.length - 1) {
      // Loop to the first real item
      goToIndex(newIndex, true);
      setTimeout(() => goToIndex(1, true), 300);
    } else if (newIndex <= 0) {
      // Loop to the last real item
      goToIndex(newIndex, true);
      setTimeout(() => goToIndex(content.length, true), 300);
    } else {
      goToIndex(newIndex);
    }
  };

  // Event handlers for mouse and touch
  const eventHandlers = {
    onMouseDown: handleDragStart,
    onMouseMove: handleDragMove,
    onMouseUp: handleDragEnd,
    onTouchStart: handleDragStart,
    onTouchMove: handleDragMove,
    onTouchEnd: handleDragEnd,
    onMouseEnter: () => setIsPaused(true),
    onMouseLeave: () => {
      setIsPaused(false);
      if (isDragging) handleDragEnd();
    },
  };

  return (
    <div
      ref={sliderRef}
      className="w-full overflow-hidden relative"
      {...eventHandlers}
    >
      {topLeft && <div className="absolute top-2 left-2 z-10">{topLeft}</div>}
      {topCenter && (
        <div className="absolute top-2 z-10 left-1/2 transform -translate-x-1/2">
          {topCenter}
        </div>
      )}
      {topRight && (
        <div className="absolute top-2 right-2 z-10">{topRight}</div>
      )}
      {bottomLeft && (
        <div className="absolute bottom-8 left-2 z-10">{bottomLeft}</div>
      )}
      {bottomCenter && (
        <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 z-10">
          {bottomCenter}
        </div>
      )}
      {bottomRight && (
        <div className="absolute bottom-8 right-2 z-10">{bottomRight}</div>
      )}
      <div
        className="flex relative"
        style={{
          transform: `translateX(${currentTranslate}px)`,
          transition: isDragging ? "none" : "transform 0.5s ease-out",
        }}
      >
        {loopedContent.map((item, index) =>
          mode === "custom" ? (
            <div
              key={index}
              className="w-full flex-shrink-0"
              onClick={() => {
                if (item.onClick && !isDragging) item.onClick();
              }}
            >
              {item}
            </div>
          ) : (
            <BringzzImage
              key={index}
              src={item.url}
              alt={`Slide ${index}`}
              className="w-full object-cover flex-shrink-0"
              draggable="false"
              onClick={() => {
                if (loopedContent[index].onClick && !isDragging)
                  loopedContent[index].onClick();
              }}
            />
          )
        )}
      </div>

      {/* Carousel Indicators */}
      <div className="w-full flex items-center justify-center gap-1 mt-2">
        {content.map((_, index) => (
          <div
            key={index}
            className={`bg-magic-lilac flex-1 hover:cursor-pointer h-1 ${index + 1 === currentIndex ? "bg-opacity-100" : "bg-opacity-50"
              } `}
            onClick={() => goToIndex(index + 1)}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default BringzzImageSlider;
