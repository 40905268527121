import * as React from "react";
const IconAirport = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.5 14.7h-10V4h5.3c.6 0 1-.4 1-1s-.4-1-1-1H10.2c-.6 0-1 .4-1 1s.4 1 1 1h5.3v10.7h-10c-2.2 0-4 1.8-4 4V58c0 2.2 1.8 4 4 4h53.1c2.2 0 4-1.8 4-4V18.7c-.1-2.2-1.9-4-4.1-4M17.4 4h29.1v10.7H17.4zm43.1 54c0 1.1-.9 2-2 2h-53c-1.1 0-2-.9-2-2V18.7c0-1.1.9-2 2-2h53.1c1.1 0 2 .9 2 2V58z" />
    <path d="M46.1 36.1h-5.6l-5-9.6c-.3-.5-.9-.7-1.3-.4-.5.3-.7.9-.4 1.3l4.5 8.7H24.8l-3-5.9c-.3-.5-.9-.7-1.3-.4-.5.3-.7.9-.4 1.3l3.1 6-3.1 6c-.3.5-.1 1.1.4 1.3.1.1.3.1.5.1.4 0 .7-.2.9-.5l3-5.9h13.6L34 46.8c-.3.5-.1 1.1.4 1.3.1.1.3.1.5.1.4 0 .7-.2.9-.5l5-9.6h5.6c.6 0 1-.4 1-1s-.7-1-1.3-1" />
  </svg>
);
export default IconAirport;
