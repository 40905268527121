import * as React from "react";
const IconWhiteboardAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.2 2H7.8c-2.8 0-5 2.2-5 5v20.5c0 2.8 2.2 5 5 5h23.7v9.9c-.1.1-.2.1-.3.2L14.1 60.3c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3l16-16.6V59c0 .6.4 1 1 1s1-.4 1-1V45.2l15 16.5c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 .1-1.4L33.8 42.6c-.1-.1-.2-.2-.4-.2v-9.9h22.7c2.8 0 5-2.2 5-5V7c.1-2.8-2.1-5-4.9-5m3 25.5c0 1.7-1.3 3-3 3H7.8c-1.7 0-3-1.3-3-3V7c0-1.7 1.3-3 3-3h48.4c1.7 0 3 1.3 3 3z" />
  </svg>
);
export default IconWhiteboardAlt;
