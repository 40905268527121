import * as React from "react";
const IconChefHatAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.6 15.7c-2 0-4 .5-5.7 1.5V17c0-7.3-5.8-13.2-13-13.2s-13 5.9-13 13.2c-1.7-.9-3.5-1.4-5.4-1.4C7.1 15.7 2 20.9 2 27.3c0 6.1 4.6 11 10.4 11.6V55c0 2.8 2.3 5.1 5.1 5.1h28.9c2.8 0 5.1-2.3 5.1-5.1V38.8c5.9-.5 10.5-5.5 10.5-11.5 0-6.4-5.1-11.6-11.4-11.6m-4.2 42.5H17.6c-1.7 0-3.1-1.4-3.1-3.1v-5h35.1v5c0 1.7-1.4 3.1-3.2 3.1m4.2-21.3c-.6 0-1 .4-1 1V48h-9.8v-7.2c0-.6-.4-1-1-1s-1 .4-1 1V48H26.3v-7.2c0-.6-.4-1-1-1s-1 .4-1 1V48h-9.8V37.9c0-.6-.4-1-1-1-5.2 0-9.4-4.3-9.4-9.6s4.2-9.6 9.4-9.6c2.2 0 4.2.7 5.9 2.1.3.3.8.3 1.1.1.4-.2.6-.6.5-1-.1-.6-.2-1.2-.2-1.9 0-6.2 4.9-11.2 11-11.2s11 5 11 11.2c0 .7-.1 1.5-.2 2.1-.1.4.1.9.5 1.1s.8.2 1.2-.1c1.7-1.5 3.9-2.4 6.3-2.4 5.2 0 9.4 4.3 9.4 9.6s-4.2 9.6-9.4 9.6" />
  </svg>
);
export default IconChefHatAlt;
