import * as React from "react";
const IconPyramidsCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.8 51.5H7.2c-1.5 0-2.8-.8-3.5-2s-.7-2.8 0-4l13.4-22.7c.4-.7 1.2-1.2 2.1-1.2q1.35 0 2.1 1.2l4.9 8.2c.1.2.3.2.4.2s.3 0 .4-.2l10-17.3c.4-.7 1.2-1.2 2.1-1.2q1.35 0 2.1 1.2l19 31.7c.8 1.3.8 2.8.1 4-.7 1.3-2 2.1-3.5 2.1M19.2 23.6c-.1 0-.3 0-.4.2L5.5 46.4c-.4.6-.4 1.4 0 2s1 1 1.7 1h49.6c.7 0 1.4-.4 1.7-1 .4-.6.3-1.4 0-2l-19-31.7c-.1-.2-.3-.2-.4-.2s-.3 0-.4.2L28.6 31.9c-.4.7-1.2 1.2-2.1 1.2q-1.35 0-2.1-1.2l-4.9-8.2c-.1-.1-.2-.1-.3-.1" />
  </svg>
);
export default IconPyramidsCopy;
