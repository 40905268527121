import * as React from "react";
const IconMoon = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M34.7 62c-6.5 0-12.9-2.1-18.3-6.2-7-5.4-11.3-13.5-11.8-22.5C4 22.5 9.7 11.9 19 6.3 24.4 3.1 30.2 1.6 36.3 2c.8 0 1.5.5 1.8 1.3.3.7.1 1.6-.5 2.1-5.8 5.8-8.3 14.1-6.5 22.2 2.6 11.9 13.8 20 26 18.8.8-.1 1.6.3 2 1s.3 1.5-.1 2.1c-4.3 6-10.7 10.2-17.9 11.7-2.1.6-4.3.8-6.4.8m-.2-58c-5.1 0-9.9 1.4-14.4 4.1-8.7 5.2-14 15.1-13.5 25.2.4 8.3 4.4 16 11 21s15 6.9 23 5.1c6.7-1.5 12.7-5.4 16.7-11v.1c-13.1 1.3-25.2-7.5-28-20.4-1.9-8.7.7-17.7 7-24-.6-.1-1.2-.1-1.8-.1" />
  </svg>
);
export default IconMoon;
