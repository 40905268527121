import React, { useState } from 'react';
import { useNavigation } from 'context/NavigationContext';
import {
    BringzzPageContainer, BringzzButton, BringzzIcon, BringzzText, BringzzPinCode
} from "@bringzz/components";
import useUser from 'hooks/useUser';
import useRequest from 'hooks/useRequest';
import { useLocation } from 'react-router-dom';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const VerifyAccountDeletionPage = () => {
    const { backPress, navigate } = useNavigation();
    const [isValidInput, setIsValidInput] = useState(false)
    const [isValid, setIsValid] = useState(false)

    const { sendRequest, data, error, loading } = useRequest();

    const { getUser } = useUser();
    const user = getUser();

    const validatePin = async pin => {
        const response = await sendRequest({
            method: 'POST',
            url: `/settings/app/delete/verify`,
            data: { pin }
        })

        return response.status === 'success'
    };

    return (
        <BringzzPageContainer
            header={{
                left: <BringzzIcon
                    size="22"
                    icon="IconChevronLeft"
                    folder="LineIcons"
                    className="cursor-pointer"
                    onClick={backPress}
                />,
                title: <BringzzText tag='h2'>Confirm delete account</BringzzText>,
            }}
            footer={
                <div className='pb-28 px-6 space-y-2'>
                    <BringzzButton
                        size='lg'
                        className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
                        onClick={async () => {
                            navigate("/onboarding")
                        }}
                        disabled={!isValidInput || loading}
                    >
                        Verify account deletion
                    </BringzzButton>
                    <BringzzButton
                        size='lg'
                        className='border border-black w-full flex justify-center text-midnight-blue'
                        onClick={() => {
                            backPress()
                        }}
                    >
                        Cancel
                    </BringzzButton>
                </div>
            }
        >
            <div className='p-6 space-y-4'>
                <BringzzText tag='h2'>Please verify your number</BringzzText>
                <BringzzText tag='h4' className='font-normal'>Before we delete your account we need to confirm your identity. Enter the security code we sent to {`+${user.phone.countrypart} ${user.phone.mobilenumber}`}</BringzzText>

                <BringzzPinCode
                    length={4}
                    onChange={pin => console.log('pin change: ', pin)}
                    onValidate={async pin => {
                        return validatePin(pin)
                    }}
                    onSuccess={() => {
                        setIsValidInput(true)
                    }}
                    onError={() => {
                        console.log('Error');
                    }}
                    lowerChildContent={
                        <div className='flex flex-col justify-center align-center mt-6 space-x-2'>
                            <BringzzText
                                tag='h4'
                                className='text-midnight-blue/50 font-normal text-center'
                            >
                                {"Code expires in [00:56]"}
                            </BringzzText>
                            <BringzzText
                                tag='h4'
                                className='text-midnight-blue/50 font-normal text-center'
                            >
                                {"Didn't receive code?"} <span className='magic-lilac underline cursor-pointer' onClick={backPress}>Resend code</span>
                            </BringzzText>
                        </div>
                    }
                    spacing='space-x-2'
                    disabled={loading}
                    className={loading ? 'opacity-25' : ''}
                ></BringzzPinCode>
            </div>
        </BringzzPageContainer>
    );
}

export default VerifyAccountDeletionPage;
