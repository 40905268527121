import * as React from "react";
const IconMuslimSymbol = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m60.5 28.4-4.8-4.9c-.6-.6-.9-1.4-.9-2.2v-7.1c0-2.8-2.3-5.1-5.1-5.1h-7.1c-.8 0-1.6-.3-2.2-.9l-4.9-4.8c-2-2-5.2-2-7.2 0l-4.9 4.8c-.6.6-1.4.9-2.2.9h-7.1c-2.8 0-5.1 2.3-5.1 5.1v7.1c0 .8-.3 1.6-.9 2.2l-4.8 4.9c-2 2-2 5.2 0 7.2l4.8 4.9c.6.6.9 1.4.9 2.2v7.1c0 2.8 2.3 5.1 5.1 5.1h7.1c.8 0 1.6.3 2.2.9l4.9 4.8c1 1 2.3 1.5 3.6 1.5s2.6-.5 3.6-1.5l4.9-4.9c.6-.6 1.4-.9 2.2-.9h7.1c2.8 0 5.1-2.3 5.1-5.1v-7.1c0-.8.3-1.6.9-2.2l4.9-4.9c1.9-1.9 1.9-5.1-.1-7.1m-1.4 5.8L54.3 39q-1.5 1.5-1.5 3.6v7.1c0 1.7-1.4 3.1-3.1 3.1h-7.1q-2.1 0-3.6 1.5l-4.9 4.8c-1.2 1.2-3.2 1.2-4.4 0L25 54.3q-1.5-1.5-3.6-1.5h-7.1c-1.7 0-3.1-1.4-3.1-3.1v-7.1q0-2.1-1.5-3.6l-4.8-4.9c-1.2-1.2-1.2-3.2 0-4.4L9.7 25q1.5-1.5 1.5-3.6v-7.1c0-1.7 1.4-3.1 3.1-3.1h7.1q2.1 0 3.6-1.5l4.9-4.8c1.2-1.2 3.2-1.2 4.4 0L39 9.7q1.5 1.5 3.6 1.5h7.1c1.7 0 3.1 1.4 3.1 3.1v7.1q0 2.1 1.5 3.6l4.8 4.9c1.2 1.1 1.2 3.1 0 4.3" />
    <path d="M41.1 35.3c-.6.1-1.3.2-1.9.2-5.9 0-10.7-4.8-10.7-10.7 0-.6.1-1.3.2-1.9.1-.4-.1-.8-.4-1s-.8-.2-1.1 0c-2.7 1.9-5.4 6-5.4 9.6 0 5.9 4.8 10.7 10.7 10.7 3.6 0 7.7-2.7 9.6-5.4.2-.3.2-.8 0-1.1s-.6-.5-1-.4m-8.6 5c-4.8 0-8.7-3.9-8.7-8.7 0-2.1 1.3-4.6 2.8-6.4.2 6.7 5.6 12.1 12.3 12.3-1.8 1.5-4.3 2.8-6.4 2.8M41.3 25.5h-2.1v-2.1c0-.6-.4-1-1-1s-1 .4-1 1v2.1H35c-.6 0-1 .4-1 1s.4 1 1 1h2.1v2.1c0 .6.4 1 1 1s1-.4 1-1v-2.1h2.1c.6 0 1-.4 1-1s-.4-1-.9-1" />
  </svg>
);
export default IconMuslimSymbol;
