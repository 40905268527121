import * as React from "react";
const IconLetterblocks = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 31H47.5V6c0-2.2-1.8-4-4-4h-23c-2.2 0-4 1.8-4 4v25H6c-2.2 0-4 1.8-4 4v23c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V35c0-2.2-1.8-4-4-4M18.5 6c0-1.1.9-2 2-2h23c1.1 0 2 .9 2 2v25h-27zM4 58V35c0-1.1.9-2 2-2h25v27H6c-1.1 0-2-.9-2-2m56 0c0 1.1-.9 2-2 2H33V33h25c1.1 0 2 .9 2 2z" />
    <path d="M37.9 23.2c.3.5.9.6 1.4.3s.6-.9.3-1.4l-6.8-11.4c-.4-.6-1.4-.6-1.7 0l-6.8 11.4c-.3.5-.1 1.1.3 1.4.2.1.3.1.5.1.3 0 .7-.2.9-.5l5.9-10zM18.5 37.8h-6.2c-.6 0-1 .4-1 1v15.4c0 .6.4 1 1 1h6.2c2.1 0 3.8-1.7 3.8-3.8v-2.1c0-1.1-.5-2.1-1.3-2.8.8-.7 1.3-1.7 1.3-2.8v-2.1c0-2.1-1.7-3.8-3.8-3.8m1.8 11.5v2.1c0 1-.8 1.8-1.8 1.8h-5.2v-5.7h5.2c1 0 1.8.8 1.8 1.8m-7.1-3.8v-5.7h5.2c1 0 1.8.8 1.8 1.8v2.1c0 1-.8 1.8-1.8 1.8zM51.2 50.5c-1.1 1.1-2.5 1.7-4 1.7-3.1 0-5.6-2.5-5.6-5.6s2.5-5.6 5.6-5.6c1.3 0 2.5.4 3.5 1.2.4.3 1.1.3 1.4-.2.3-.4.3-1.1-.2-1.4-1.3-1.1-3-1.7-4.8-1.7-4.2 0-7.6 3.4-7.6 7.6s3.4 7.6 7.6 7.6c2 0 4-.8 5.4-2.2.4-.4.4-1 0-1.4s-.9-.4-1.3 0" />
  </svg>
);
export default IconLetterblocks;
