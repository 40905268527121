import * as React from "react";
const IconDownloadAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60 45.3c-.6 0-1 .4-1 1v8.9c0 1.6-1.3 2.9-2.9 2.9H7.9C6.3 58 5 56.7 5 55.1v-8.9c0-.6-.4-1-1-1s-1 .4-1 1v8.9C3 57.8 5.2 60 7.9 60h48.3c2.7 0 4.9-2.2 4.9-4.9v-8.9c-.1-.5-.5-.9-1.1-.9" />
    <path d="M31.3 45.6c.2.2.4.3.7.3s.5-.1.7-.3l14.5-14.1c.4-.4.4-1 0-1.4s-1-.4-1.4 0L33 42.5V5c0-.6-.4-1-1-1s-1 .4-1 1v37.5L18.2 30.1c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4z" />
  </svg>
);
export default IconDownloadAlt;
