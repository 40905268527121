import * as React from "react";
const IconHeadphoneCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.4 18.4c-5.8-5.7-13.6-8.8-21.9-8.6C15.2 10 2 23.2 2 39.2v12c0 1.7 1.3 3 3 3h6.5c1.7 0 3-1.3 3-3V42c0-1.7-1.3-3-3-3H5c-.4 0-.7.1-1 .2C4 24.3 16.3 12 31.5 11.8c7.8-.2 15.1 2.7 20.5 8.1 5.2 5.1 8 11.8 8 18.8v.5c-.3-.2-.6-.2-1-.2h-6.5c-1.7 0-3 1.3-3 3v9.2c0 1.7 1.3 3 3 3H59c1.7 0 3-1.3 3-3V38.7c0-7.6-3.1-14.8-8.6-20.3M5 41h6.5c.6 0 1 .4 1 1v9.2c0 .6-.4 1-1 1H5c-.6 0-1-.4-1-1V42c0-.6.4-1 1-1m55 10.2c0 .6-.4 1-1 1h-6.5c-.6 0-1-.4-1-1V42c0-.6.4-1 1-1H59c.6 0 1 .4 1 1v9.2" />
  </svg>
);
export default IconHeadphoneCopy;
