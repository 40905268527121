import * as React from "react";
const IconTrendingUp = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.5 22.9H43.4c-.6 0-1 .4-1 1s.4 1 1 1h14.1L41.2 35.7c-1 .7-2.3.7-3.3 0l-13-8.7c-1.7-1.1-3.9-1.1-5.6 0L3.9 37.3c-.5.3-.6.9-.3 1.4.2.3.5.4.8.4.2 0 .4-.1.6-.2l15.4-10.3c1-.7 2.3-.7 3.3 0l13 8.7c1.7 1.1 3.9 1.1 5.5 0l16.1-10.8v13.4c0 .6.4 1 1 1s1-.4 1-1v-16c.2-.5-.2-1-.8-1" />
  </svg>
);
export default IconTrendingUp;
