import * as React from "react";
const IconSkullMask = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 4.9C15.5 4.9 2 15.8 2 29.1c0 7.4 4.3 14.6 11.6 19.1V54c0 2.8 2.3 5.1 5.1 5.1h26.5c2.8 0 5.1-2.3 5.1-5.1v-5.7C57.7 43.7 62 36.5 62 29.1 62 15.8 48.5 4.9 32 4.9m16.9 41.9c-.3.2-.5.5-.5.9V54c0 1.7-1.4 3.1-3.1 3.1h-4.7v-5.4c0-.6-.4-1-1-1s-1 .4-1 1v5.4H33v-5.4c0-.6-.4-1-1-1s-1 .4-1 1v5.4h-5.6v-5.4c0-.6-.4-1-1-1s-1 .4-1 1v5.4h-4.7c-1.7 0-3.1-1.4-3.1-3.1v-6.3c0-.4-.2-.7-.5-.9C8.2 42.6 4 36 4 29.1 4 16.9 16.6 6.9 32 6.9s28 10 28 22.2c0 6.9-4.2 13.5-11.1 17.7" />
    <path d="M44.6 18.4c-3.7 0-6.8 3.1-6.8 6.8s3.1 6.8 6.8 6.8 6.8-3.1 6.8-6.8-3.1-6.8-6.8-6.8m0 11.6c-2.6 0-4.8-2.2-4.8-4.8s2.2-4.8 4.8-4.8 4.8 2.2 4.8 4.8c0 2.7-2.2 4.8-4.8 4.8M19.4 18.4c-3.7 0-6.8 3.1-6.8 6.8s3.1 6.8 6.8 6.8 6.8-3.1 6.8-6.8-3-6.8-6.8-6.8m0 11.6c-2.6 0-4.8-2.2-4.8-4.8s2.2-4.8 4.8-4.8 4.8 2.2 4.8 4.8c0 2.7-2.1 4.8-4.8 4.8M32.2 35c-.6 0-1 .4-1 1v4.6c0 .6.4 1 1 1s1-.4 1-1V36c0-.5-.4-1-1-1" />
  </svg>
);
export default IconSkullMask;
