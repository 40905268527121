import React from 'react';
import { useNavigation } from '../../context/NavigationContext';
import { BringzzPageContainer, BringzzIcon, BringzzArticle, BringzzPostingCard, BringzzImage } from "@bringzz/components";
import AvatarExample from 'assets/images/AvatarExample.png';
import PostExample1 from 'assets/images/PostExample1.png';

const Article = () => {
    const { backPress } = useNavigation();

    return (
        <BringzzPageContainer
            header={{
                left: <BringzzIcon
                    size="22"
                    icon="IconChevronLeft"
                    folder="LineIcons"
                    className="cursor-pointer"
                    onClick={backPress}
                />
            }}>

            <BringzzArticle
                title="The ABC of Allergies: Summer hayfever"
                subTitle="Cras nunc purus amet eget. In elementum molestie tellus tellus diam. Sed metus integer auctor nunc. Euismod sodales netus scelerisque auctor"
                likesCount={100}
                commentsCount={10}
            >
                <BringzzPostingCard
                    avatar={AvatarExample}
                    name='Apotheke Mustermann'
                    header={<BringzzImage className="w-full h-auto" src={PostExample1} alt="post image"></BringzzImage>}
                    likesCount={1200}
                    commentsCount={40}
                    title='Fall time is cold time: How to prepare for the cold season'
                    description="Turpis rutrum amet vitae ac integer et pharetra gravida. Praesent
					laoreet amet Dui amet felis gravida convallis nisi suspendisse sed."
                    action={{
                        label: 'Read more',
                        icon: 'IconEbook',
                        iconFolder: 'LineIcons',
                        onClick: () => console.log("text")
                    }}
                ></BringzzPostingCard>
                <BringzzPostingCard
                    avatar={AvatarExample}
                    name='Apotheke Mustermann'
                    header={<BringzzImage className="w-full h-auto" src={PostExample1} alt="post image"></BringzzImage>}
                    likesCount={1200}
                    commentsCount={40}
                    title='Fall time is cold time: How to prepare for the cold season'
                    description="Turpis rutrum amet vitae ac integer et pharetra gravida. Praesent
					laoreet amet Dui amet felis gravida convallis nisi suspendisse sed."
                    action={{
                        label: 'Read more',
                        icon: 'IconEbook',
                        iconFolder: 'LineIcons',
                        onClick: () => console.log("text")
                    }}
                ></BringzzPostingCard>
            </BringzzArticle>
        </BringzzPageContainer>

    )
}

export default Article;