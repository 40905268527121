import * as React from "react";
const IconHelmetCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 39.7h-1.1v-1.4c0-14.2-11.2-25.8-25-25.8s-25 11.6-25 25.8v1.4H6c-2.2 0-4 1.8-4 4v3.9c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4v-3.9c0-2.3-1.8-4-4-4M9 38.3c0-9.2 5.1-17.2 12.4-21.1v6.3c0 .6.4 1 1 1s1-.4 1-1v-7.2c2.3-1 4.9-1.6 7.5-1.7v11.3c0 .6.4 1 1 1s1-.4 1-1V14.5c2.7.1 5.2.7 7.5 1.7v7.2c0 .6.4 1 1 1s1-.4 1-1v-6.3c7.4 4 12.4 11.9 12.4 21.1v1.4H9.1c0-.4-.1-.9-.1-1.3m51 9.2c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2v-3.9c0-1.1.9-2 2-2h52c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconHelmetCopy;
