import * as React from "react";
const IconHour = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
    <path d="M41.6 23.4c.3 0 .5-.1.7-.3l3.3-3.3c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-3.3 3.3c-.4.4-.4 1 0 1.4.2.2.5.3.7.3M21.7 40.9l-3.3 3.3c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l3.3-3.3c.4-.4.4-1 0-1.4s-1-.4-1.4 0M50.3 31h-4.7c-.6 0-1 .4-1 1s.4 1 1 1h4.7c.6 0 1-.4 1-1s-.5-1-1-1M19.4 32c0-.6-.4-1-1-1h-4.7c-.6 0-1 .4-1 1s.4 1 1 1h4.7c.5 0 1-.4 1-1M42.3 40.9c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l3.3 3.3c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4zM19.8 18.4c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l3.3 3.3c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4zM32 44.6c-.6 0-1 .4-1 1v4.7c0 .6.4 1 1 1s1-.4 1-1v-4.7c0-.5-.4-1-1-1M32 12.7c-.6 0-1 .4-1 1v4.7c0 .6.4 1 1 1s1-.4 1-1v-4.7c0-.5-.4-1-1-1" />
  </svg>
);
export default IconHour;
