import * as React from "react";
const IconChevronDownCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 44.3c-.3 0-.5-.1-.7-.3l-23-22.6c-.4-.4-.4-1 0-1.4s1-.4 1.4 0L32 41.9 54.3 20c.4-.4 1-.4 1.4 0s.4 1 0 1.4L32.7 44c-.2.2-.4.3-.7.3" />
  </svg>
);
export default IconChevronDownCopy;
