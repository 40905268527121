import * as React from "react";
const IconIGroupsPerspectiveCrowd = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M28 16c0 2.21-1.79 4-4 4s-4-1.79-4-4 1.79-4 4-4 4 1.79 4 4m-2 0a1.999 1.999 0 1 1-4 0 1.999 1.999 0 1 1 4 0M18 17.5c0 2.486-2.014 4.5-4.5 4.5A4.5 4.5 0 0 1 9 17.5c0-2.486 2.014-4.5 4.5-4.5s4.5 2.014 4.5 4.5m-2 0c0 1.382-1.118 2.5-2.5 2.5a2.5 2.5 0 0 1-2.5-2.5c0-1.382 1.118-2.5 2.5-2.5s2.5 1.118 2.5 2.5M4 29.455C4 25.827 10.663 24 14 24c.801 0 1.794.105 2.84.316C18.133 23.31 20.673 22 24 22s5.867 1.31 7.16 2.316C32.206 24.106 33.199 24 34 24c3.337 0 10 1.827 10 5.454V36H4zm2 0c0-.237.094-.552.512-.974.437-.44 1.128-.884 2.037-1.281C10.38 26.4 12.605 26 14 26s3.622.4 5.45 1.2c.91.397 1.601.842 2.038 1.281.418.422.512.737.512.973V34H6zm22.55-4.41A10.3 10.3 0 0 0 24 24c-1.875 0-3.432.495-4.55 1.045 2.44.897 4.55 2.368 4.55 4.41 0-2.042 2.11-3.513 4.55-4.41m-2.038 3.436c-.418.422-.512.737-.512.973V34h16v-4.546c0-.236-.094-.551-.512-.973-.437-.44-1.128-.884-2.037-1.281C37.62 26.4 35.395 26 34 26s-3.622.4-5.45 1.2c-.91.397-1.601.842-2.038 1.281M34.5 22c2.486 0 4.5-2.014 4.5-4.5S36.986 13 34.5 13a4.5 4.5 0 0 0-4.5 4.5c0 2.486 2.014 4.5 4.5 4.5m0-2c1.382 0 2.5-1.118 2.5-2.5S35.882 15 34.5 15a2.5 2.5 0 0 0-2.5 2.5c0 1.382 1.118 2.5 2.5 2.5"
      clipRule="evenodd"
    />
  </svg>
);
export default IconIGroupsPerspectiveCrowd;
