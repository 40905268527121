import * as React from "react";
const IconTicketAltCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M47.6 2H16.4c-3.7 0-6.7 3-6.7 6.7v51c0 .8.5 1.4 1.3 1.6s1.5-.1 1.9-.8l4.7-7.8 5.8 8.5c.3.5.8.7 1.4.7s1.1-.3 1.4-.7l5.8-8.6 5.8 8.6c.3.5.8.7 1.4.7s1.1-.3 1.4-.8l5.7-8.5 4.7 7.9c.4.7 1.2 1 1.9.8.8-.2 1.3-.9 1.3-1.7V8.7C54.3 5 51.3 2 47.6 2m4.7 56.8-4.4-7.4c-.3-.5-.8-.8-1.4-.8s-1.1.3-1.5.8l-5.8 8.5-5.8-8.6c-.6-.9-2.2-.9-2.8 0l-5.8 8.6-5.8-8.5c-.3-.5-.8-.7-1.4-.7h-.1c-.6 0-1.1.3-1.4.8l-4.4 7.3v-50c0-2.6 2.1-4.7 4.7-4.7h31.2c2.6 0 4.7 2.1 4.7 4.7z" />
    <path d="M42.6 20.6H21.4c-.6 0-1 .4-1 1s.4 1 1 1h21.3c.6 0 1-.4 1-1s-.5-1-1.1-1M42.6 31.3H21.4c-.6 0-1 .4-1 1s.4 1 1 1h21.3c.6 0 1-.4 1-1-.1-.6-.5-1-1.1-1" />
  </svg>
);
export default IconTicketAltCopy;
