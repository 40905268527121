import * as React from "react";
const IconTreesCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M13.5 15.1C6.7 15.1 2 25.1 2 34c0 9.1 4.7 16.6 10.6 17.3V61c0 .6.4 1 1 1s1-.4 1-1v-9.7c5.8-.8 10.4-8.2 10.4-17.2s-4.7-19-11.5-19m1 34.2v-6.8c0-.6-.4-1-1-1s-1 .4-1 1v6.8c-4.8-.7-8.6-7.3-8.6-15.2 0-8.9 4.5-16.9 9.5-16.9s9.6 8 9.6 16.9c0 7.8-3.7 14.4-8.5 15.2M62.1 26.4C62.1 14.9 56.2 2 47.9 2S33.7 14.9 33.7 26.4c0 11.8 5.8 21.4 13.2 22.3V61c0 .6.4 1 1 1s1-.4 1-1V48.7c7.3-.8 13.2-10.5 13.2-22.3M48.8 46.7v-9.4c0-.6-.4-1-1-1s-1 .4-1 1v9.4c-6.2-.9-11.2-9.6-11.2-20.3C35.7 14.7 41.5 4 47.9 4s12.2 10.7 12.2 22.4c0 10.7-5 19.4-11.3 20.3" />
  </svg>
);
export default IconTreesCopy;
