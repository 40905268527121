import * as React from "react";
const IconGraduationCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.6 35-4.7-4.8v-5.1c.6-.5 1-1.2 1-2 0-1-.6-1.9-1.5-2.3L31.1 9.3c-.6-.3-1.4-.3-2 0L3.5 20.6C2.6 21 2 21.9 2 22.9s.6 1.9 1.5 2.3l9.5 4.3v13.1C13 49.4 18.5 55 25.3 55h9.4C41.5 55 47 49.4 47 42.6V29.7l5.8-2.6 2.2-1v4.3L50.3 35c-.6.6-.6 1.6 0 2.2l4.5 4.6c.3.3.7.5 1.1.5s.8-.2 1.1-.5l4.5-4.6c.6-.6.6-1.6.1-2.2m-16.7 7.5c0 5.7-4.6 10.4-10.3 10.4h-9.4c-5.7 0-10.3-4.7-10.3-10.4V30.4l14.2 6.4c.3.1.7.2 1 .2s.7-.1 1-.2l13.7-6.2v11.9zm7-17.2-21.5 9.6c-.1.1-.3.1-.4 0L4.3 23.4c-.3-.1-.3-.4-.3-.5s0-.4.3-.5l25.5-11.3h.4l25.3 11.4c.3.1.3.4.3.5s0 .4-.3.5zm4 14.8-3.9-4 3.9-4 3.9 4z" />
  </svg>
);
export default IconGraduationCopy;
