import * as React from "react";
const IconCardiology = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.1 10.7c-3.2-3.4-7.6-5.3-12.3-5.3-3.6 0-6.8 1.1-9.7 3.3-1.1.9-2.2 1.9-3 3-.9-1.1-2-2.2-3.1-3-2.9-2.2-6.1-3.3-9.7-3.3-4.8 0-9.1 1.9-12.3 5.3-3.1 3.4-4.9 8-4.9 13 0 5.1 1.9 9.8 6 14.7 3.6 4.3 8.6 8.6 14.5 13.5 2 1.7 4.2 3.6 6.6 5.6.8.7 1.8 1.1 2.9 1.1s2.1-.4 2.9-1.1c2.3-2 4.6-3.9 6.6-5.6 5.9-5 10.9-9.3 14.5-13.5 4.1-4.9 6-9.5 6-14.7-.1-5-1.8-9.6-5-13M8.3 12.1c2.8-3 6.7-4.7 10.9-4.7 3.1 0 6 1 8.5 2.9 1 .8 1.9 1.7 2.8 2.7.4.5.9.7 1.5.7s1.1-.3 1.5-.7c.8-1 1.8-1.9 2.8-2.7 2.5-1.9 5.4-2.9 8.5-2.9 4.2 0 8.1 1.7 10.9 4.7S60 19.2 60 23.7c0 1-.1 2.1-.3 3.1H40.3c-.9 0-1.7.4-2.3 1.1l-5.8 7.2c-.2.2-.5.4-.8.4s-.6-.2-.8-.4l-11-16c-.5-.7-1.3-1.2-2.2-1.3q-1.35-.15-2.4.9L4.8 29c-.5-1.8-.8-3.5-.8-5.3 0-4.5 1.5-8.6 4.3-11.6m46.2 25C51 41.2 46 45.4 40.2 50.4c-2 1.7-4.3 3.6-6.6 5.6-.9.8-2.3.8-3.2 0-2.3-2-4.6-4-6.6-5.6C18 45.5 13 41.2 9.5 37.1 7.8 35 6.5 33 5.6 31l10.9-10.9c.3-.3.6-.3.8-.3s.5.1.7.4l11 16c.5.8 1.4 1.2 2.3 1.3h.1c.9 0 1.7-.4 2.3-1.1l5.8-7.2c.2-.2.5-.4.8-.4h18.9c-.8 2.7-2.4 5.5-4.7 8.3" />
  </svg>
);
export default IconCardiology;
