import * as React from "react";
const IconUserAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 26.5c7 0 12.7-5.5 12.7-12.3S39 2 32 2 19.3 7.5 19.3 14.3 25 26.5 32 26.5M32 4c5.9 0 10.7 4.6 10.7 10.3S37.9 24.5 32 24.5s-10.7-4.6-10.7-10.3S26.1 4 32 4M32 35.2C19.4 35.2 9.2 45.4 9.2 58v3c0 .6.4 1 1 1s1-.4 1-1v-3c0-11.5 9.3-20.8 20.8-20.8S52.8 46.5 52.8 58v3c0 .6.4 1 1 1s1-.4 1-1v-3c0-12.6-10.2-22.8-22.8-22.8" />
  </svg>
);
export default IconUserAlt1;
