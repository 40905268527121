import * as React from "react";
const IconBathTub1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 30H12.2c0-.1.1-.2.1-.4V10.1c0-1.7 1.4-3.1 3.1-3.1h8.5v4.1c0 .1 0 .2.1.3h-.2c-2 0-3.6 1.6-3.6 3.6v4.4c0 .6.4 1 1 1h7.1c.6 0 1-.4 1-1V15c0-1.9-1.5-3.4-3.3-3.6 0-.1.1-.2.1-.3V6c0-.6-.4-1-1-1h-9.5c-2.8 0-5.1 2.3-5.1 5.1v19.6c0 .1 0 .2.1.4H3c-.6 0-1 .4-1 1s.4 1 1 1h3.2v8.7c0 5.9 4.7 10.7 10.5 11l-2.6 5.8c-.2.5 0 1.1.5 1.3.1.1.3.1.4.1q.6 0 .9-.6l3-6.6h28c.4 0 .8 0 1.1-.1l3 6.7q.3.6.9.6c.1 0 .3 0 .4-.1.5-.2.7-.8.5-1.3L50 51.3c4.5-1.4 7.9-5.6 7.9-10.5V32H61c.6 0 1-.4 1-1s-.4-1-1-1M27.1 15v3.4H22V15c0-.9.7-1.6 1.6-1.6h1.8c.9 0 1.7.7 1.7 1.6m28.7 25.8c0 5-4 9-9 9H17.2c-5 0-9-4-9-9V32h47.7v8.8z" />
  </svg>
);
export default IconBathTub1;
