import * as React from "react";
const IconHdVideo = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 10.3H6c-2.2 0-4 1.8-4 4v35.4c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V14.3c0-2.2-1.8-4-4-4m2 39.4c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V14.3c0-1.1.9-2 2-2h52c1.1 0 2 .9 2 2z" />
    <path d="M27.5 23.7c-.6 0-1 .4-1 1V31h-9.4v-6.3c0-.6-.4-1-1-1s-1 .4-1 1v14.6c0 .6.4 1 1 1s1-.4 1-1V33h9.4v6.3c0 .6.4 1 1 1s1-.4 1-1V24.7c0-.5-.5-1-1-1M44.6 23.7h-7c-.6 0-1 .4-1 1v14.6c0 .6.4 1 1 1h7c2.3 0 4.2-1.9 4.2-4.2V28c.1-2.4-1.8-4.3-4.2-4.3m2.3 12.4c0 1.2-1 2.2-2.2 2.2h-6V25.7h6c1.2 0 2.2 1 2.2 2.2z" />
  </svg>
);
export default IconHdVideo;
