import * as React from "react";
const IconShipAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M52 60.2 48.3 57c-.3-.2-.5-.4-.8-.6L52 40.3c.7-2.5-.6-5.2-3-6.2l-3.3-1.4v-8.2c0-2.8-2.2-5-5-5.2v-7.2c0-2.2-1.8-4-4-4H33V3c0-.6-.4-1-1-1s-1 .4-1 1v5.3h-3.8c-2.2 0-4 1.8-4 4v7.2c-2.8.1-5 2.4-5 5.2v8.2L15 34.2c-2.4 1-3.7 3.7-3 6.2l4.5 16.1c-.3.2-.5.3-.8.6L12 60.2c-.4.4-.5 1-.1 1.4.2.2.5.3.8.3.2 0 .5-.1.7-.2l3.7-3.2c1.2-1 3-1 4.2 0l1 .8c1.9 1.7 4.8 1.7 6.8 0l1-.8c1.2-1 3-1 4.2 0l1 .8c1.9 1.7 4.8 1.7 6.8 0l1-.8c1.2-1 3-1 4.2 0l3.7 3.2c.4.4 1 .3 1.4-.1s0-1-.4-1.4M25.2 12.3c0-1.1.9-2 2-2h9.6c1.1 0 2 .9 2 2v7.1H25.2zm-5 12.3c0-1.8 1.4-3.2 3.2-3.2h17.2c1.8 0 3.2 1.4 3.2 3.2V32L34 27.9c-1.3-.5-2.7-.5-4 0L20.2 32zM41.5 57l-1 .8c-1.2 1-3 1-4.2 0l-1-.8c-1.9-1.7-4.8-1.7-6.8 0l-1 .8c-1.2 1-3 1-4.2 0l-1-.8c-1.1-1-2.6-1.4-4.1-1.2l-4.5-16c-.4-1.5.4-3.2 1.8-3.8l3.8-1.6h.1l11.1-4.6c.8-.3 1.7-.3 2.4 0L44 34.4h.1l3.8 1.6c1.5.6 2.3 2.2 1.8 3.8l-4.5 16c-1.1-.1-2.6.2-3.7 1.2" />
  </svg>
);
export default IconShipAlt;
