import * as React from "react";
const IconStarOfDavid = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m52.8 32.1 4.8-8.2c1-1.7 1-3.7 0-5.4s-2.7-2.7-4.7-2.7h-9.8L36.6 4.7C35.6 3 33.8 2 31.9 2s-3.7 1-4.7 2.7l-6.5 11h-9.6c-2 0-3.7 1-4.7 2.7s-1 3.8 0 5.5l4.7 8-4.8 8.2c-1 1.7-1 3.7 0 5.4S9 48.2 11 48.2h9.8l6.5 11.1C28.3 61 30 62 32 62c1.9 0 3.7-1 4.7-2.7l6.5-11h9.6c2 0 3.7-1 4.7-2.7s1-3.8 0-5.5zm.1-14.4c1.2 0 2.4.6 3 1.7s.6 2.4 0 3.4L51.7 30l-7.3-12.4h8.5zm-2.5 14.4-8.3 14.2H22L13.5 32l8.3-14.2H42zM28.9 5.7c.6-1.1 1.7-1.7 3-1.7 1.2 0 2.3.6 3 1.7l5.9 10H23.1zM8.2 22.9c-.6-1.1-.6-2.4 0-3.4.6-1.1 1.7-1.7 3-1.7h8.4L12.4 30zm2.9 23.4c-1.2 0-2.4-.6-3-1.7s-.6-2.4 0-3.4l4.2-7.2 7.3 12.4h-8.5zm24 12c-.6 1.1-1.7 1.7-3 1.7-1.2 0-2.3-.6-3-1.7l-5.9-10h17.7zm20.7-13.8c-.6 1.1-1.7 1.7-3 1.7h-8.4L51.6 34l4.2 7.1c.6 1.1.6 2.4 0 3.4" />
  </svg>
);
export default IconStarOfDavid;
