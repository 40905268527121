import * as React from "react";
const IconPrescriptionDocument = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M10 4a1 1 0 0 0-1 1v38a1 1 0 0 0 1 1h28a1 1 0 0 0 1-1V15a1 1 0 0 0-.293-.707l-10-10A1 1 0 0 0 28 4zm1 38V6h16v9a1 1 0 0 0 1 1h9v26zm24.586-28L29 7.414V14zM17 33h2v-7h1.586l5 5-3.293 3.293 1.414 1.414L27 32.414l3.293 3.293 1.414-1.414L28.414 31l3.293-3.293-1.414-1.414L27 29.586l-3.605-3.605A4 4 0 0 0 23 18h-5a1 1 0 0 0-1 1zm6-9h-4v-4h4a2 2 0 1 1 0 4"
      clipRule="evenodd"
    />
  </svg>
);
export default IconPrescriptionDocument;
