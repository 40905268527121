import * as React from "react";
const IconRemoveBasket = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.6 19.2h-11l-9.5-9.5C36.7 9 37 8 37 7c0-2.8-2.2-5-5-5s-5 2.2-5 5c0 1 .3 2 .8 2.8l-9.5 9.5h-11c-2.1 0-3.8 1.7-3.8 3.8v2.1c0 2 1.6 3.6 3.6 3.8l6.2 23c.1.4.5.7 1 .7h5.4C21.2 58.1 26.1 62 32 62s10.8-3.9 12.4-9.3h5.4c.5 0 .9-.3 1-.7l6.1-23.1c2-.1 3.6-1.8 3.6-3.8V23c-.1-2.1-1.8-3.8-3.9-3.8M32 4c1.7 0 3 1.4 3 3 0 .8-.3 1.6-.9 2.1-.5.6-1.3.9-2.1.9s-1.6-.3-2.1-.9c-.6-.5-.9-1.3-.9-2.1 0-1.6 1.3-3 3-3m-2.8 7.2c.8.5 1.8.8 2.8.8s2-.3 2.8-.8l8.1 8.1H21.2zM32 60c-6 0-10.9-4.9-10.9-10.9S26 38.2 32 38.2s10.9 4.9 10.9 10.9S38 60 32 60m17-9.3h-4.2c.1-.5.1-1.1.1-1.6 0-7.1-5.8-12.9-12.9-12.9S19.1 42 19.1 49.1c0 .6 0 1.1.1 1.6H15L9.2 28.9h45.5zm9.4-25.6c0 1-.8 1.8-1.8 1.8H7.4c-1 0-1.8-.8-1.8-1.8V23c0-1 .8-1.8 1.8-1.8h49.2c1 0 1.8.8 1.8 1.8z" />
    <path d="M36 48.1h-8c-.6 0-1 .4-1 1s.4 1 1 1h8c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconRemoveBasket;
