import React, { useState } from 'react';
import { useNavigation } from 'context/NavigationContext';
import {
    BringzzPageContainer, BringzzIcon, BringzzRangePicker, BringzzAccordion, BringzzRadioList, BringzzRadio, BringzzText, BringzzCheckbox
} from "@bringzz/components";

let state = {
    checked: (
        <BringzzIcon
            icon="IconCheck"
            library="Custom"
            className="h-5 w-5 p-1 bg-magic-lilac text-light rounded-full"
            size="12"
        />
    ),
    unChecked: (
        <div></div>
    ),
};

const SearchFilterPage = () => {
    const { backPress } = useNavigation();
    const { navigate } = useNavigation();
    const [priceRange, setPriceRange] = useState([0, 100]);

    const handlePriceRangeChange = (range) => {
        setPriceRange(range);
    };

    return (
        <BringzzPageContainer
            className="bg-white pb-32"
            authenticated={false}
            header={{
                title: "Filter & Sort",
                right: <BringzzIcon
                    size="22"
                    icon="IconClose"
                    folder="LineIcons"
                    className="cursor-pointer"
                    onClick={backPress}
                />,
            }}
        >
            <div className='px-6'>

                <div>
                    <BringzzAccordion title="Sort by" defaultValue={true}>
                        <BringzzRadioList state={state}>
                            <BringzzRadio value={"recommended"}><BringzzText tag='h4' className='font-normal'>Recommended</BringzzText></BringzzRadio>
                            <BringzzRadio value={"newest"}><BringzzText tag='h4' className='font-normal'>Newest</BringzzText></BringzzRadio>
                            <BringzzRadio value={"lowest"}><BringzzText tag='h4' className='font-normal'>Lowest Price</BringzzText></BringzzRadio>
                            <BringzzRadio value={"highest"}><BringzzText tag='h4' className='font-normal'>Highest Price</BringzzText></BringzzRadio>
                        </BringzzRadioList>
                    </BringzzAccordion>
                </div>
                <div>
                    <BringzzAccordion title="Availability" defaultValue={true}>
                        <BringzzCheckbox label='Ready to Order' />
                        <BringzzCheckbox label='Available' />
                        <BringzzCheckbox label='Not Available' />
                    </BringzzAccordion>
                </div>
                <div>
                    <BringzzAccordion title="Sales & Offers" defaultValue={true}>
                        <BringzzCheckbox label='Ready to Order' />
                        <BringzzCheckbox label='Available' />
                    </BringzzAccordion>
                </div>
                <div>
                    <BringzzAccordion title="Price" defaultValue={true}>
                        <BringzzRangePicker
                            min={0}
                            max={100}
                            step={1}
                            onChange={handlePriceRangeChange}
                        />
                        <BringzzCheckbox label='€0 - €25' />
                        <BringzzCheckbox label='€25 - €50' />
                        <BringzzCheckbox label='€50 - €100' />
                        <BringzzCheckbox label='€100+' />
                    </BringzzAccordion>
                </div>
                <div>
                    <BringzzAccordion title="Application form" defaultValue={true}>
                        <BringzzCheckbox label='Tablets (XX)' />
                        <BringzzCheckbox label='Dragess (XX)' />
                        <BringzzCheckbox label='Capsules (XX)' />
                        <BringzzCheckbox label='Soluble tablet (XX)' />
                        <BringzzCheckbox label='Chewable tablet (XX)' />
                    </BringzzAccordion>
                </div>
                <div>
                    <BringzzAccordion title="Pack sizes" defaultValue={true}>
                        <BringzzCheckbox label='100g (XX)' />
                        <BringzzCheckbox label='200g (XX)' />
                        <BringzzCheckbox label='300g (XX)' />
                        <BringzzCheckbox label='400g (XX)' />
                        <BringzzCheckbox label='500g (XX)' />
                    </BringzzAccordion>
                </div>
                <div>
                    <BringzzAccordion title="Active ingredients" defaultValue={true}>
                        <BringzzCheckbox label='Active ingredient 1 (XX)' />
                        <BringzzCheckbox label='Active ingredient 2 (XX)' />
                        <BringzzCheckbox label='Active ingredient 3 (XX)' />
                        <BringzzCheckbox label='Active ingredient 4 (XX)' />
                        <BringzzCheckbox label='Active ingredient 5 (XX)' />
                    </BringzzAccordion>
                </div>
                <div>
                    <BringzzAccordion title="Manufacturer" defaultValue={true}>
                        <BringzzCheckbox label='Manufacturer 1 (XX)' />
                        <BringzzCheckbox label='Manufacturer 2 (XX)' />
                        <BringzzCheckbox label='Manufacturer 3 (XX)' />
                        <BringzzCheckbox label='Manufacturer 4 (XX)' />
                        <BringzzCheckbox label='Manufacturer 5 (XX)' />
                    </BringzzAccordion>
                </div>
                <div>
                    <BringzzAccordion title="Brands" defaultValue={true}>
                        <BringzzCheckbox label='Brand 1 (XX)' />
                        <BringzzCheckbox label='Brand 2 (XX)' />
                        <BringzzCheckbox label='Brand 3 (XX)' />
                        <BringzzCheckbox label='Brand 4 (XX)' />
                        <BringzzCheckbox label='Brand 5 (XX)' />
                    </BringzzAccordion>
                </div>
            </div>
        </BringzzPageContainer >
    );
};

export default SearchFilterPage;
