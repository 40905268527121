import * as React from "react";
const IconMoneyProtectionAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M45.1-55.7c-.9-.8-2.3-.6-3.1.3l-6.9 8.7-2.7-4.1c-.7-1-2.1-1.3-3.1-.6s-1.3 2-.6 3.1l4.4 6.6c.4.6 1 .9 1.7 1h.1c.7 0 1.3-.3 1.7-.8l8.8-11c.8-1.1.7-2.4-.3-3.2M57.1 18H44.9v-.5c0-1.7-1-3.3-2.6-4l-8.6-3.7c-1.1-.5-2.3-.5-3.4 0l-8.6 3.7c-1.6.7-2.6 2.2-2.6 4v.5H6.9C4.2 18 2 20.2 2 22.9v26.7c0 2.7 2.2 4.9 4.9 4.9h50.3c2.7 0 4.9-2.2 4.9-4.9V22.9c-.1-2.7-2.3-4.9-5-4.9m2.9 4.9v4.6c-.7.3-1.4.5-2.2.5-2.9 0-5.3-2.4-5.3-5.3 0-1 .3-1.9.8-2.7h3.9c1.5 0 2.8 1.3 2.8 2.9m-38.9-5.3c0-.9.6-1.8 1.4-2.1l8.6-3.7c.3-.1.6-.2.9-.2s.6.1.9.2l8.6 3.7c.9.4 1.4 1.2 1.4 2.1V22c0 8.3-2.5 12.9-9.6 17.6-.8.5-1.8.5-2.6 0-7.1-4.7-9.6-9.3-9.6-17.6zm8.5 23.6c.7.5 1.6.7 2.4.7s1.7-.2 2.4-.7c7.7-5 10.5-10.2 10.5-19.2v-2H51c-.3.9-.5 1.8-.5 2.7 0 4 3.3 7.3 7.3 7.3.8 0 1.5-.1 2.2-.4v13.2c-.7-.2-1.5-.4-2.2-.4-4 0-7.3 3.3-7.3 7.3 0 .9.2 1.8.5 2.7H13c.3-.9.5-1.8.5-2.7 0-4-3.3-7.3-7.3-7.3-.8 0-1.5.1-2.2.4V29.6c.7.3 1.5.4 2.2.4 4 0 7.3-3.3 7.3-7.3 0-.9-.2-1.8-.5-2.7h6.1v2c0 8.9 2.8 14.2 10.5 19.2M6.9 20h3.9c.5.8.8 1.7.8 2.7 0 2.9-2.4 5.3-5.3 5.3-.8 0-1.5-.2-2.2-.5v-4.6C4 21.3 5.3 20 6.9 20M4 49.6V45c.7-.3 1.4-.5 2.2-.5 2.9 0 5.3 2.4 5.3 5.3 0 1-.3 1.9-.8 2.7H6.9c-1.6 0-2.9-1.3-2.9-2.9m53.1 2.9h-3.9c-.5-.8-.8-1.7-.8-2.7 0-2.9 2.4-5.3 5.3-5.3.8 0 1.5.2 2.2.5v4.6c.1 1.6-1.2 2.9-2.8 2.9" />
    <path d="M29.4 28.9c.2.2.5.3.7.3s.4-.1.6-.2l6.3-5.1c.4-.3.5-1 .2-1.4s-1-.5-1.4-.2l-5.6 4.5-1.6-1.8c-.4-.4-1-.4-1.4-.1-.4.4-.4 1-.1 1.4z" />
  </svg>
);
export default IconMoneyProtectionAlt;
