import * as React from "react";
const IconAlarm3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m57.7 55.7-2.1-3.3c-.5-.9-.8-1.7-.8-2.7V28c0-5.8-2.5-11.2-7-15.2-4-3.6-9.3-5.7-14.9-5.9V3c0-.6-.4-1-1-1s-1 .4-1 1v3.9c-.6 0-1.2.1-1.8.1-11.4 1.3-20 10.4-20 21.4v21.5c-.1 1.1-.3 1.8-.7 2.5l-2 3.4c-.4.6-.4 1.4 0 1.9.4.6 1 .9 1.7.9H31V61c0 .6.4 1 1 1s1-.4 1-1v-2.4h23c.7 0 1.3-.3 1.7-.9.4-.7.3-1.4 0-2M29.3 9c.9-.1 1.7-.1 2.6-.1 5.4 0 10.6 1.9 14.6 5.4 4.1 3.6 6.3 8.5 6.3 13.7v17.7H11.1V28.4c0-9.9 7.8-18.2 18.2-19.4M8.2 56.6l2-3.3c.5-.9.8-2 1-3.3v-2.3h41.7v2.1c0 1.3.4 2.5 1.1 3.7l2 3.1z" />
  </svg>
);
export default IconAlarm3;
