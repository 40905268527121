import React from 'react';
import { BringzzButton, BringzzText } from "@bringzz/components";
import { useNavigation } from 'context/NavigationContext';
import { BringzzPageContainer, BringzzIcon, BringzzImage } from "@bringzz/components";

const ResetPasswordSuccessPage = () => {
	const { backPress } = useNavigation();
	const { navigate } = useNavigation();

	return (
		<BringzzPageContainer
			className='bg-white'
			header={{
				left: (
					<BringzzIcon
						size='22'
						icon='IconClose'
						folder='LineIcons'
						className='cursor-pointer'
						onClick={backPress}
					/>
				)
			}}
			footer={
				<div className='pb-8 px-6'>
					<BringzzButton
						size='lg'
						className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
						onClick={() => {
							navigate('/auth');
						}}
					>
						Login
					</BringzzButton>
				</div>
			}
			authenticated={false}
		>
			<div className='relative flex justify-center bg-magic-lilac/20'>
				<div className='relative bg-white w-full bg-cover bg-center flex flex-col md:max-w-2xl '>
					{/* Content */}
					<div className='flex-grow flex flex-col justify-between py-8 px-4'>
						{/* Top bar */}

						{/* Bottom part */}
						<div className='z-10 flex flex-col '>
							<div className='flex text-center flex-col space-y-6 mx-auto md:max-w-lg lg:max-w-xl xl:max-w-2xl'>
								<BringzzText tag='h1' className='text-midnight-blue'>
									Update Password Completed
								</BringzzText>
								<BringzzText
									tag='h4'
									className='text-midnight-blue font-normal text-start'
								>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibu-lum consequat nisl eget ultricies mollis. Praesent eu sodales libe-ro, sit amet commodo ante. Fusce ut mauris urna.
								</BringzzText>
							</div>
							<div className='mt-6 flex items-center justify-center h-full'>
								<BringzzImage className="max-w-[240px] md:max-w-[360px] h-auto" src="/images/SuccessThumbsUp.png" alt="post image"></BringzzImage>

							</div>
						</div>

					</div>
				</div>
			</div>
		</BringzzPageContainer>
	);
};

export default ResetPasswordSuccessPage;
