import * as React from "react";
const IconMicrophoneAlt2 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62 19.9C62 10 54 2 44.2 2c-9.7 0-17.6 7.8-17.8 17.4L4 44.5c-.8.9-.8 2.3 0 3.2l.1.1q.15.3 0 .6l-.7.6C2.5 50 2 51.2 2 52.5s.5 2.6 1.5 3.5L8 60.6c1 1 2.2 1.4 3.5 1.4s2.5-.5 3.5-1.4l.6-.6c.2-.2.3-.2.5 0l.5.5.1-.1c.9.5 2 .4 2.8-.3l25.1-22.4c9.6-.2 17.4-8.1 17.4-17.8m-2 0c0 2.5-.6 4.9-1.7 7.1l-21-21.4c2.1-1 4.4-1.6 6.8-1.6C52.9 4 60 11.1 60 19.9m-30-7.1 20.9 21.3c-2.1 1-4.3 1.5-6.8 1.5-8.7 0-15.8-7.1-15.8-15.8 0-2.5.6-4.9 1.7-7M14.2 58.6l-.6.6c-1.1 1.1-3 1.1-4.2 0l-4.6-4.5c-.5-.6-.8-1.4-.8-2.2s.3-1.5.9-2.1l.7-.7.3-.3 8.7 8.8zm3.9 0q-.3.15-.6 0h-.1l-12-12.2c-.1-.2-.1-.4 0-.5l21-23.5c1.1 7.9 7.3 14.2 15.2 15.2zm34.6-25.4L31 11c1.2-1.8 2.7-3.3 4.5-4.4l21.7 22.1c-1.1 1.8-2.7 3.3-4.5 4.5" />
  </svg>
);
export default IconMicrophoneAlt2;
