import * as React from "react";
const IconFirecrackersAlt4 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.9 34.5 52.4 27c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l7.5 7.5c1 1 1.5 2.3 1.5 3.6 0 1.4-.5 2.7-1.5 3.6s-2.3 1.5-3.6 1.5c-1.4 0-2.7-.5-3.6-1.5l-3.7-3.7c.4-.7.6-1.5.6-2.3 0-1.3-.5-2.6-1.4-3.5L23.2 10.3l3.9-3.9c.3-.3.4-.7.2-1.1s-.6-.6-1-.6L4.4 6.2c-.5 0-.9.4-.9.9L2 29c0 .4.2.8.6 1 .1.1.3.1.4.1.3 0 .5-.1.7-.3l4.7-4.7v.1l-.8.8 23.5 23.4c1 1 2.2 1.4 3.5 1.4.4 0 .8-.1 1.2-.2l8.3 8.3c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-7.9-7.9c.1-.1.3-.2.4-.3l8.3-8.3 3.5 3.5c1.3 1.3 3.1 2.1 5 2.1s3.7-.7 5-2.1 2.1-3.1 2.1-5c.1-1.8-.6-3.6-2-5M5.4 8.1l18.4-1.3L4.2 26.5zm16.4 3.6 23.5 23.5c.5.5.8 1.2.8 1.9l-37-12.7zM36.6 48c-1.1 1.1-3 1.1-4.1 0L12.1 27.6 45.5 39c-.1.1-.2.2-.2.3z" />
  </svg>
);
export default IconFirecrackersAlt4;
