import React, { useState } from 'react';
import {
    BringzzBoxSlider, BringzzCheckoutCard
} from "@bringzz/components";
import classNames from 'classnames';
import { useBasket } from 'context/BasketContext';

const BringzzCheckoutPicker = ({ onCardSelect }) => {
    const [selectedCard, setSelectedCard] = useState(1);
    const { getDeliveryCost } = useBasket()

    const handleCardClick = (cardId) => {
        setSelectedCard(cardId);
        onCardSelect(cardId);
    };

    return (
        <BringzzBoxSlider
            behavior={{
                xl: 'left',
                m: 'left'
            }}
            className='space-x-2 px-2 items-center py-3 w-full mb-4'
        >
            <BringzzCheckoutCard
                imageUrl='/basket-service-1.png'
                imageSize='65px'
                title='Bringzz Pickup'
                description='Delivered within X min'
                price={getDeliveryCost()}
                className={classNames(
                    'cursor-pointer',
                    { 'bg-magic-lilac': selectedCard === 1, 'bg-sand': selectedCard !== 1 }
                )}
                onClick={() => handleCardClick(1)}
            />
            <BringzzCheckoutCard
                imageUrl='/basket-service-2.png'
                imageSize='43px'
                title='Click & collect'
                description='Picked within X min'
                price={"18,00"}
                className={classNames(
                    'cursor-pointer',
                    { 'bg-magic-lilac': selectedCard === 2, 'bg-sand': selectedCard !== 2 }
                )}
                onClick={() => handleCardClick(2)}
            />
            <BringzzCheckoutCard
                imageUrl='/basket-service-2.png'
                imageSize='43px'
                title='Mail delivery'
                description='Delivered within X days'
                price={"18,00"}
                className={classNames(
                    'cursor-pointer',
                    { 'bg-magic-lilac': selectedCard === 3, 'bg-sand': selectedCard !== 3 }
                )}
                onClick={() => handleCardClick(3)}
            />
        </BringzzBoxSlider>
    );
};

export default BringzzCheckoutPicker;
