import React, { useState, useEffect } from "react";

export default function Carousel({
    className = '',
    component,
    children,
    backend = false,
    screens = {sm: 576, md: 768, lg: 992, xl: 1200}
}) {

    const mobileSize = screens.sm;
    const tabletSize = screens.lg;

    const [device, setDevice] = useState(window.innerWidth > mobileSize ? (window.innerWidth > tabletSize ? 'desktop' : 'tablet') : 'mobile');
    const [activeIndex, setActiveIndex] = useState(0);

    const cols = Number(component['carousel_' + device + 'ItemsPerSlide']);
    const selectedBtn = (activeIndex + cols >= component.children.length) ? Math.ceil(activeIndex / cols) * cols : Math.floor(activeIndex / cols) * cols;

    useEffect(() => {

        const handleResize = () => {

            if (window.innerWidth <= mobileSize) return setDevice('mobile');
            if (window.innerWidth <= tabletSize) return setDevice('tablet');
            return setDevice('desktop');
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }

    }, [])

    useEffect(() => {
        if ((activeIndex + cols) > component.children.length)
            setActiveIndex(component.children.length - cols)
    }, [device])

    const nextSlide = () => {
        let nextIndex = activeIndex + 1;
        if (!backend && nextIndex + cols <= component.children.length)
            setActiveIndex(nextIndex);
    }

    const prevSlide = () => {
        let prevIndex = activeIndex - 1;
        if (!backend && prevIndex >= 0)
            setActiveIndex(prevIndex);
    }

    return (
        <div className={className}>

            <div id={`${component.anchor_ ? component.anchor_ : 'Carousel-' + component.uuid}`}
                className={'flex justify-center items-center relative'}
            >

                <div className={`absolute left-0 round-chevron ${!backend && activeIndex > 0 ? '' : 'round-chevron-disabled'}`}
                    style={{ backgroundColor: component.carouselBtnBackground_color, marginRight: '-15px' }}
                    onClick={(e) => prevSlide()}
                >
                    <span className="material-icons" style={{ color: component.carouselbtnText_color }}>chevron_left</span>
                </div>

                <div className={`carousel ${backend ? 'grid grid-cols-' + cols : 'flex'}`}>
                    {
                        backend ?
                            children
                            :
                            React.Children.map(
                                children, (child) => {
                                    if (child.props.component.hidden_ != 1)
                                        return (
                                            <div style={{ width: `${100 / cols}%`, minWidth: `${100 / cols}%`, transform: `translateX(-${100 / cols * activeIndex * cols}%)`, transition: 'transform 1s ease' }}>
                                                {child}
                                            </div>
                                        )
                                }
                            )
                    }
                </div>

                <div className={`absolute right-0 round-chevron ${!backend && (activeIndex + cols) < component.children.length ? '' : 'round-chevron-disabled'}`}
                    style={{ backgroundColor: component.carouselBtnBackground_color, marginLeft: '15px' }}
                    onClick={(e) => nextSlide()}
                >
                    <span className="material-icons" style={{ color: component.carouselbtnText_color }}>chevron_right</span>
                </div>

            </div>

            <div className='flex justify-center items-center'>
                {
                    component.children && component.children.map((child, i) => {
                        if (i % cols === 0) {
                            return (
                                <div className={`btn-slider-container`}
                                    key={component.component.uuid}
                                    style={selectedBtn === i ? { border: `2px solid ${component.carouselPaginationBorder_color}` } : {}}
                                    onClick={(e) => setActiveIndex((i + cols) > component.children.length ? component.children.length - cols : i)}>

                                    <div className='btn-slider'
                                        style={selectedBtn === i ?
                                            { backgroundColor: component.carouselPaginationBackground_color, border: `1px solid white` } :
                                            { border: `2px solid ${component.carouselPaginationBackground_color}` }
                                        }
                                    />

                                </div>
                            )
                        }
                    })
                }
            </div>

        </div>
    )
}