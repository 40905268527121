import * as React from "react";
const IconShieldAlt3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 62c-.1 0-.2 0-.4-.1-7.8-3.1-14.5-8.4-19.3-15.3-4.9-7.1-7.6-15.5-7.6-24.1v-9.2c0-.5.4-.9.8-1 4.8-.8 8.8-4.7 9.5-9.5.1-.5.5-.8 1-.8h31.7c.5 0 .9.4 1 .8.8 4.9 4.6 8.7 9.5 9.5.5.1.8.5.8 1v9.2c0 8.7-2.6 17-7.6 24.1a42.67 42.67 0 0 1-19.3 15.3c.1.1 0 .1-.1.1M6.8 14.2v8.3c0 8.3 2.5 16.2 7.2 23C18.5 52 24.7 57 32 59.9c7.3-3 13.5-7.9 18-14.4 4.7-6.8 7.2-14.7 7.2-23v-8.3C52.1 13 48.1 9.1 47 4H17c-1.1 5.1-5.1 9-10.2 10.2" />
  </svg>
);
export default IconShieldAlt3;
