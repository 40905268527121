import * as React from "react";
const IconPage = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M51.6 2H12.4c-2.8 0-5 2.2-5 5v50c0 2.8 2.2 5 5 5h39.2c2.8 0 5-2.2 5-5V7c0-2.8-2.3-5-5-5m3 55c0 1.7-1.3 3-3 3H12.4c-1.7 0-3-1.3-3-3V7c0-1.7 1.3-3 3-3h39.2c1.7 0 3 1.3 3 3z" />
    <path d="M46 9.8H34.6c-1.1 0-2 .9-2 2v13.8c0 1.1.9 2 2 2H46c1.1 0 2-.9 2-2V11.8c0-1.1-.9-2-2-2M34.6 25.6V11.8H46v13.8zM17.2 13.6h7.6c.6 0 1-.4 1-1s-.4-1-1-1h-7.6c-.6 0-1 .4-1 1s.4 1 1 1M17.2 26.7h7.6c.6 0 1-.4 1-1s-.4-1-1-1h-7.6c-.6 0-1 .4-1 1s.4 1 1 1M47 37.9H17.2c-.6 0-1 .4-1 1s.4 1 1 1H47c.6 0 1-.4 1-1s-.5-1-1-1M47 51.1H17.2c-.6 0-1 .4-1 1s.4 1 1 1H47c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconPage;
