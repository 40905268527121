import * as React from "react";
const IconClassroomAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54 32.6H34.5c-2.8 0-5.1 2.3-5.1 5.1v3.4H16.5V18.4l11 6.7c.8.5 1.7.8 2.7.8h9.6c.6 0 1-.4 1-1s-.4-1-1-1h-9.6c-.6 0-1.1-.2-1.6-.5L17 16.3c3.4-.6 6-3.5 6-7.1 0-4-3.2-7.2-7.2-7.2S8.5 5.2 8.5 9.2c0 3.5 2.6 6.5 5.9 7.1v24.9H6.9V20.5c0-.6-.4-1-1-1s-1 .4-1 1V61c0 .6.4 1 1 1s1-.4 1-1V43.1h12.6V61c0 .6.4 1 1 1s1-.4 1-1V43.1h8v13.7c0 2.8 2.3 5.1 5.1 5.1H54c2.8 0 5.1-2.3 5.1-5.1V37.7c0-2.8-2.3-5.1-5.1-5.1M10.5 9.2c0-2.9 2.3-5.2 5.2-5.2S21 6.3 21 9.2s-2.3 5.2-5.2 5.2-5.3-2.3-5.3-5.2m46.6 47.7c0 1.7-1.4 3.1-3.1 3.1H34.5c-1.7 0-3.1-1.4-3.1-3.1V37.7c0-1.7 1.4-3.1 3.1-3.1H54c1.7 0 3.1 1.4 3.1 3.1z" />
  </svg>
);
export default IconClassroomAlt;
