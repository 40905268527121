import * as React from "react";
const IconBlade = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M49 8.4h-7.2V3c0-.6-.4-1-1-1H23.2c-.6 0-1 .4-1 1v5.4H15c-.6 0-1 .4-1 1v45.1c0 .6.4 1 1 1h7.2V61c0 .6.4 1 1 1h17.6c.6 0 1-.4 1-1v-5.4H49c.6 0 1-.4 1-1V9.4c0-.5-.4-1-1-1m-1 45.2h-7.2c-.6 0-1 .4-1 1V60H24.2v-5.4c0-.6-.4-1-1-1H16V10.4h7.2c.6 0 1-.4 1-1V4h15.6v5.4c0 .6.4 1 1 1H48z" />
    <path d="M35 21.1c.6 0 1-.4 1-1s-.4-1-1-1h-6c-.6 0-1 .4-1 1s.4 1 1 1h2v9.3h-2c-.6 0-1 .4-1 1s.4 1 1 1h2v10.4h-2c-.6 0-1 .4-1 1s.4 1 1 1h6c.6 0 1-.4 1-1s-.4-1-1-1h-2V32.4h2c.6 0 1-.4 1-1s-.4-1-1-1h-2v-9.3z" />
  </svg>
);
export default IconBlade;
