import * as React from "react";
const IconVideoCameraAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 7.1c-.6-.4-1.5-.6-2.2-.3l-11.3 4.1V7c0-2.8-2.2-5-5-5H7C4.2 2 2 4.2 2 7v18.9c0 2.8 2.2 5 5 5h15.8l-17 29.6c-.3.5-.1 1.1.4 1.4.1.1.3.1.5.1.3 0 .7-.2.9-.5L23.9 33l16.4 28.5c.2.3.5.5.9.5.2 0 .3 0 .5-.1.5-.3.6-.9.4-1.4L25 30.9h17.5c2.8 0 5-2.2 5-5V22l11.3 4.1c.3.1.5.1.8.1.5 0 1-.1 1.4-.4.6-.4 1-1.2 1-2V9.1c0-.8-.4-1.5-1-2M42.5 28.9H7c-1.7 0-3-1.3-3-3V7c0-1.7 1.3-3 3-3h35.5c1.7 0 3 1.3 3 3v18.9c0 1.7-1.3 3-3 3M60 23.8c0 .2-.1.3-.2.3s-.2.1-.4 0l-11.9-4.3V13l11.9-4.3c.2-.1.3 0 .4 0s.2.1.2.3z" />
  </svg>
);
export default IconVideoCameraAlt1;
