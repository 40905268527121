import * as React from "react";
const IconStrikethroughAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M8 24.7c.1 0 .3 0 .4-.1.5-.2.7-.8.5-1.3-.9-1.8-1.3-3.8-1.3-5.8 0-3.6 1.4-7 4-9.5 2.6-2.6 5.9-4 9.5-4h36.3c.6 0 1-.4 1-1s-.4-1-1-1H21.1c-4.1 0-8 1.6-11 4.5-2.9 2.9-4.5 6.8-4.5 11q0 3.45 1.5 6.6c.1.4.5.6.9.6M55.6 39.4c-.5.2-.7.8-.5 1.3.9 1.8 1.3 3.8 1.3 5.8 0 7.4-6.1 13.5-13.5 13.5H6.6c-.6 0-1 .4-1 1s.4 1 1 1h36.3c8.5 0 15.5-7 15.5-15.5q0-3.45-1.5-6.6c-.2-.5-.8-.7-1.3-.5M59 31H5c-.6 0-1 .4-1 1s.4 1 1 1h54c.6 0 1-.4 1-1s-.5-1-1-1" />
  </svg>
);
export default IconStrikethroughAlt;
