import * as React from "react";
const IconVolumeMuteCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M44.7 13.8c-.4-.4-1-.4-1.4 0L36.1 21v-4.1c0-1.3-.7-2.5-1.9-3.2s-2.6-.6-3.7.1l-10.8 6.7c-.3.2-.6.2-.9.2H6.1c-2 0-3.6 1.6-3.6 3.6v15.8c0 2 1.6 3.6 3.6 3.6h7.1l-4.8 4.8c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l34.9-35c.4-.1.4-.7 0-1.1M14.9 41.9H6.1c-.9 0-1.6-.7-1.6-1.6V24.5c0-.9.7-1.6 1.6-1.6h12.8c.7 0 1.3-.2 1.9-.6l10.8-6.7c.5-.3 1.1-.3 1.7 0 .5.3.8.8.8 1.4v5.4c0 .2.1.3.1.5L15.1 42s-.1-.1-.2-.1M35.1 32.4c-.6 0-1 .4-1 1v14.5c0 .6-.3 1.1-.8 1.4s-1.1.3-1.7 0l-8.7-5.4c-.5-.3-1.1-.2-1.4.3s-.2 1.1.3 1.4l8.7 5.4c.6.4 1.3.6 1.9.6s1.2-.2 1.8-.5c1.2-.7 1.9-1.8 1.9-3.2V33.4c0-.6-.4-1-1-1" />
  </svg>
);
export default IconVolumeMuteCopy;
