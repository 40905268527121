import * as React from "react";
const IconRootSignAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M49.3 23.6H30.7c-.9 0-1.6.5-2 1.3L23 38.3s0 .1-.1.1-.1 0-.1-.1l-3.5-4.6c-.4-.5-1-.8-1.6-.8s-1.3.2-1.7.7l-2 2.2c-.4.4-.3 1 .1 1.4s1 .3 1.4-.1l1.9-2.2s.1 0 .1-.1c0 0 .1 0 .1.1l3.5 4.6c.4.5 1 .9 1.7.9h.3c.8-.1 1.4-.6 1.7-1.3l5.7-13.4c0-.1.1-.1.1-.1h18.6c.6 0 1-.4 1-1s-.4-1-.9-1" />
    <path d="M58 2H6C3.8 2 2 3.8 2 6v52c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V6c0-2.2-1.8-4-4-4m2 56c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2h52c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconRootSignAlt;
