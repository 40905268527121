import * as React from "react";
const IconHighlightCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M35.4 2h-6.9c-3.2 0-5.9 2.6-5.9 5.9v35.5c0 .8.6 1.4 1.4 1.4h1.4v6.1c0 1.1.4 2 1.1 2.7v6.7c0 .5.3 1.1.7 1.4.3.2.7.3 1 .3.2 0 .3 0 .5-.1l7.3-2.2c.8-.2 1.4-1 1.4-1.8v-4.3h-.1c.7-.7 1.1-1.6 1.1-2.7v-6.1h1.4c.8 0 1.4-.6 1.4-1.4V7.9c.1-3.3-2.5-5.9-5.8-5.9m.1 55.8-7 2.1v-5.3c.2 0 .4.1.6.1h5.6c.3 0 .5 0 .7-.1zm1.1-6.9c0 1-.8 1.8-1.8 1.8h-5.6c-1 0-1.8-.8-1.8-1.8v-6.1h9.1v6.1zm2.7-8.2H24.6V7.9c0-2.1 1.7-3.9 3.9-3.9h6.9c2.1 0 3.9 1.7 3.9 3.9z" />
  </svg>
);
export default IconHighlightCopy;
