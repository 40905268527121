import * as React from "react";
const IconFirstAidCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M37.3 28.4H33v-4.3c0-.6-.4-1-1-1s-1 .4-1 1v4.3h-4.3c-.6 0-1 .4-1 1s.4 1 1 1H31v4.3c0 .6.4 1 1 1s1-.4 1-1v-4.3h4.3c.6 0 1-.4 1-1s-.5-1-1-1" />
    <path d="M57.1 15.2H40.9v-4.3c0-3.5-2.8-6.3-6.3-6.3h-5.3c-3.5 0-6.3 2.8-6.3 6.3v4.3H6.9c-2.7 0-4.9 2.2-4.9 4.9v12.6c0 1.9 1 3.5 2.6 4.3v17.9c0 2.5 2 4.6 4.4 4.6h45.9c2.4 0 4.4-2 4.4-4.6V37c1.6-.8 2.6-2.5 2.6-4.3V20.1c.1-2.7-2.1-4.9-4.8-4.9m-32-4.3c0-2.4 1.9-4.3 4.3-4.3h5.3c2.4 0 4.3 1.9 4.3 4.3v4.3H25.1zm29.8 46.5H9.1c-1.3 0-2.4-1.2-2.4-2.6V37.5c.5.1.9.2 1.3.3l15.3 4.3c2.8.8 5.8 1.2 8.7 1.2q4.35 0 8.7-1.2L56 37.8q.6-.15 1.5-.3v17.3c-.1 1.4-1.2 2.6-2.6 2.6M60 32.7c0 1.3-1 2.5-2.2 2.8-1 .1-1.7.3-2.4.5l-15.3 4.3c-5.3 1.5-11 1.5-16.3 0L8.5 35.9c-.7-.2-1.3-.3-2-.4h-.2C4.9 35.2 4 34 4 32.7V20.1c0-1.6 1.3-2.9 2.9-2.9h50.2c1.6 0 2.9 1.3 2.9 2.9z" />
  </svg>
);
export default IconFirstAidCopy;
