import * as React from "react";
const IconMedal7 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.6 4.6C57.7 3 56 2 54.1 2h-6.9c-1.8 0-3.4.9-4.4 2.4L31.5 21.7 21.3 4.6C20.3 3 18.6 2 16.8 2H9.9C8 2 6.3 3 5.4 4.6s-.9 3.6 0 5.3l16.8 28.3c-2.1 2.5-3.4 5.8-3.4 9.3 0 8 6.5 14.5 14.5 14.5s14.5-6.5 14.5-14.5c0-4.4-2-8.4-5.1-11.1L58.6 9.9c.9-1.6.9-3.6 0-5.3m-51.5 1C7.7 4.6 8.8 4 9.9 4h.5L18 17.5c.2.3.5.5.9.5.2 0 .3 0 .5-.1.5-.3.6-.9.4-1.4L12.7 4h4c1.1 0 2.2.6 2.8 1.6L36 33.2c-.9-.2-1.7-.2-2.6-.2-3.7 0-7.1 1.4-9.7 3.7L7.2 8.9c-.6-1-.6-2.2-.1-3.3m38.8 41.9c0 6.9-5.6 12.5-12.5 12.5s-12.5-5.6-12.5-12.5S26.5 35 33.4 35s12.5 5.6 12.5 12.5M56.8 8.9 41.2 35.2q-1.2-.75-2.4-1.2l-6.2-10.5.3-.3L44.4 5.5C45 4.5 46 4 47.1 4h4.3l-8 12.2c-.3.5-.2 1.1.3 1.4.2.1.4.2.5.2.3 0 .6-.2.8-.5L53.8 4h.2c1.2 0 2.2.6 2.8 1.6s.6 2.3 0 3.3" />
    <path d="M34.2 41.8c-.4-.2-.8-.1-1.1.1l-3.5 3c-.4.4-.5 1-.1 1.4s1 .5 1.4.1l1.9-1.6v7.7c0 .6.4 1 1 1s1-.4 1-1v-9.9c0-.3-.3-.7-.6-.8" />
  </svg>
);
export default IconMedal7;
