import * as React from "react";
const IconArrowsExpandLeftAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M21 22.4c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4L6.9 5.5h8.6c.6 0 1-.4 1-1s-.4-1-1-1h-11c-.6 0-1 .4-1 1v11c0 .6.4 1 1 1s1-.4 1-1V6.9zM59.5 47.5c-.6 0-1 .4-1 1v8.6L43 41.6c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l15.5 15.5h-8.6c-.6 0-1 .4-1 1s.4 1 1 1h11c.6 0 1-.4 1-1v-11c0-.5-.4-1-1-1M42.8 21.2c-.4-.4-1-.4-1.4 0L21.2 41.4c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l20.3-20.3c.3-.3.3-.9-.1-1.3" />
  </svg>
);
export default IconArrowsExpandLeftAlt;
