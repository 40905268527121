import * as React from "react";
const IconIn = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 513 342"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M17.3 0h478.4v342H17.3z" />
    <path  d="M0 0h513v114H0z" />
    <path  d="M0 228h513v114H0z" />
    <path  d="M0 114h513v114H0z" />
    <circle cx={256.5} cy={171} r={34.2}  />
    <path
      
      d="M256.5 216.6c-25.1 0-45.6-20.5-45.6-45.6s20.5-45.6 45.6-45.6 45.6 20.5 45.6 45.6-20.5 45.6-45.6 45.6m0-11.4c18.2 0 34.2-16 34.2-34.2s-15.9-34.2-34.2-34.2-34.2 16-34.2 34.2 16 34.2 34.2 34.2"
    />
    <circle cx={256.5} cy={171} r={22.8}  />
  </svg>
);
export default IconIn;
