import * as React from "react";
const IconShipDeliveryBoxAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M36.9 57.2c-.2-.2-.5-.4-.7-.5l4.2-15.1c.7-2.4-.5-4.9-2.8-5.9l-3-1.3V30c0-.6-.4-1-1-1s-1 .4-1 1v3.7l-9-3.8a5 5 0 0 0-3.8 0l-9.1 3.8v-6.9c0-1.6 1.3-2.9 2.9-2.9h12.8c.6 0 1-.4 1-1s-.4-1-1-1h-11v-5.5c0-1.6 1.3-2.9 2.9-2.9h7.5c.6 0 1-.4 1-1s-.4-1-1-1h-3.1V6.6c0-.6-.4-1-1-1s-1 .4-1 1v4.9h-2.4c-2.7 0-4.9 2.2-4.9 4.9V22c-2.6.1-4.7 2.3-4.7 4.9v7.7l-3 1.2c-2.3 1-3.5 3.5-2.8 5.9l4.1 15c-.2.1-.5.3-.7.5l-3.5 3c-.4.4-.5 1-.1 1.4.2.3.5.4.8.4.2 0 .5-.1.7-.2l3.5-3c1.1-.9 2.7-.9 3.8 0l.9.8c1.8 1.6 4.6 1.6 6.4 0l.9-.8c1.1-.9 2.7-.9 3.8 0l.9.8c1.8 1.6 4.6 1.6 6.4 0l.9-.8c1.1-.9 2.7-.9 3.8 0l3.5 3c.4.4 1 .3 1.4-.1s.3-1-.1-1.4zm-6.5 0-.9.8c-1.1.9-2.7.9-3.8 0l-.9-.8c-1.8-1.6-4.6-1.6-6.4 0l-1 .8c-1.1.9-2.7.9-3.8 0l-.9-.8c-1.1-.9-2.5-1.3-3.8-1.1l-4.2-15c-.4-1.4.3-2.9 1.7-3.5l3.6-1.5h.1l10.4-4.3c.7-.3 1.5-.3 2.2 0l10.4 4.3h.1l3.6 1.5c1.4.6 2.1 2.1 1.7 3.5l-4.2 15c-1.4-.2-2.8.2-3.9 1.1M59.5 7.1 49.1 2.3c-.9-.4-2-.4-2.9 0L35.8 7.1c-.6.3-1.2.8-1.5 1.3s-.5 1.2-.5 1.8v12.1c0 1.3.7 2.5 1.9 3.1l10.4 5.2c.5.2 1 .4 1.6.4.5 0 1.1-.1 1.6-.4l10.4-5.2c1.2-.6 1.9-1.8 1.9-3.1v-12c-.1-1.4-.9-2.6-2.1-3.2M47 4.1c.2-.1.4-.1.6-.1s.4 0 .6.1l9.9 4.6-4.1 2-10.2-5.1zm.6 9.8L37.1 8.7l4.4-2 10.4 5.1zm-11.1 9.8c-.5-.3-.8-.8-.8-1.3V10.3l10.9 5.3v13.1zm23-1.4c0 .6-.3 1.1-.8 1.3l-10.1 5.1V15.6l4.4-2.2v4.1c0 .6.4 1 1 1s1-.4 1-1v-5.1l4.5-2.2z" />
  </svg>
);
export default IconShipDeliveryBoxAlt1;
