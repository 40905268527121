import * as React from "react";
const IconRupeeUp = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M39 14.8c0-.6-.4-1-1-1H25.7c-.6 0-1 .4-1 1s.4 1 1 1h2.1c1.7 0 3.8 1.9 4.6 3.7h-6.6c-.6 0-1 .4-1 1s.4 1 1 1h6.7c-.7 1.6-3.4 3-5.4 3-.4 0-.8.2-.9.6-.2.4-.1.8.2 1.1l6.9 7.7c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 .1-1.4L29 26.3c2.5-.6 5.1-2.4 5.5-4.7H38c.6 0 1-.4 1-1s-.4-1-1-1h-3.6c-.3-1.4-1.2-2.7-2.3-3.7H38c.6-.1 1-.6 1-1.1" />
    <path d="M19.2 39.2c.2.1.4.2.6.2.3 0 .6-.1.8-.4.3-.4.3-1.1-.2-1.4-4.4-3.5-6.8-8.7-6.8-14.3C13.6 13.2 21.8 5 32 5s18.4 8.2 18.4 18.4c0 .6.4 1 1 1s1-.4 1-1C52.4 12.1 43.3 3 32 3s-20.4 9.1-20.4 20.4c0 6.1 2.7 11.9 7.6 15.8M51.4 40.6h-9.7c-.6 0-1 .4-1 1s.4 1 1 1h7.2l-12.2 8.7c-.4.3-.8.4-1.3.4H23.6c-1.2 0-2.4.5-3.2 1.3l-6.1 6.3c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3l6.1-6.3c.4-.5 1-.7 1.7-.7h11.8c.9 0 1.7-.3 2.5-.8L50.4 44v7.3c0 .6.4 1 1 1s1-.4 1-1v-9.7c0-.5-.4-1-1-1" />
  </svg>
);
export default IconRupeeUp;
