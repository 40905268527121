import * as React from "react";
const IconMountain = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m61.9 56.5-29-50.1c-.4-.6-1.4-.6-1.7 0l-29 50.1c-.3.5-.2 1.1.3 1.4s1.1.1 1.4-.4L22.1 26l4.3 5.2c.2.2.5.4.8.4s.6-.1.8-.4l4.1-4.9 4.1 4.9c.2.2.5.4.8.4s.6-.1.8-.4l4.3-5.1 18.3 31.5c.2.3.5.5.9.5.2 0 .3 0 .5-.1.2-.4.3-1 .1-1.5m-25-27.6L32.8 24c-.2-.2-.5-.4-.8-.4s-.6.1-.8.4l-4.1 4.9-4-4.8L32 8.9l8.8 15.2z" />
  </svg>
);
export default IconMountain;
