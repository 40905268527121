import * as React from "react";
const IconBadge7 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M53.8 23.8C53.8 11.8 44 2 32 2s-21.8 9.8-21.8 21.8c0 7.4 3.7 14 9.4 17.9V61c0 .3.2.6.4.8.3.2.6.2.9.1L32 58.4 43.1 62h.3c.2 0 .4-.1.6-.2.3-.2.4-.5.4-.8V41.7c5.7-3.9 9.4-10.5 9.4-17.9M42.4 59.6l-10.1-3.3q-.3-.15-.6 0l-10.1 3.3V43c3.1 1.7 6.7 2.7 10.4 2.7s7.3-1 10.4-2.7zM32 43.6c-10.9 0-19.8-8.9-19.8-19.8S21.1 4 32 4s19.8 8.9 19.8 19.8S42.9 43.6 32 43.6" />
    <path d="M40.9 17.8c-2.5-2.2-6.2-2.2-8.9-.1-1.2-1-2.8-1.6-4.4-1.6-2 0-3.9.9-5.2 2.4-2.4 2.7-2.2 6.9.5 9.6l7.2 7.2c.5.5 1.2.8 1.9.8s1.4-.3 1.9-.8l7.4-7.3c1.3-1.3 2-3 2-4.9 0-2.1-.9-4-2.4-5.3m-1.1 8.7-7.4 7.3c-.3.3-.7.3-1 0l-7.2-7.2c-1.9-1.9-2.1-4.9-.4-6.8.9-1.1 2.3-1.7 3.7-1.7 1.3 0 2.6.5 3.5 1.4l.2.2c.2.2.5.3.7.3.3 0 .5-.1.7-.3 1.9-1.9 4.9-2.1 6.8-.4 1.1.9 1.7 2.3 1.7 3.7.2 1.3-.3 2.6-1.3 3.5" />
  </svg>
);
export default IconBadge7;
