import * as React from "react";
const IconAsterisk = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61 31H34.4l18.8-18.8c.4-.4.4-1 0-1.4s-1-.4-1.4 0L33 29.6V3c0-.6-.4-1-1-1s-1 .4-1 1v26.6L12.2 10.8c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4L29.6 31H3c-.6 0-1 .4-1 1s.4 1 1 1h26.6L10.8 51.8c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3L31 34.4V61c0 .6.4 1 1 1s1-.4 1-1V34.4l18.8 18.8c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4L34.4 33H61c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconAsterisk;
