import React from 'react';
import {
    BringzzButton, BringzzText, BringzzRecommendationItem,
    BringzzProductBox,
    BringzzIcon, BringzzCartSelector
} from "@bringzz/components";
import ProductExample from 'assets/images/ProductExample.png';
import RecommendationItemBackground from 'assets/images/RecommendationItemBackground.png';

const BasketRecommendationSection = () => {
    const productId = '12345';
    const addToCart = (productId, quantity) => {
        console.log(`${productId} added ${quantity} times to cart`);
    };

    const doFav = (event, productId) => {
        event.stopPropagation();
        console.log(`${productId} bookmarked`);
    };

    const clickOnProduct = productId => {
        console.log(`Product ${productId} clicked`);
    };

    return (
        <div>
            <div className='p-4 text-center'>
                <BringzzText className='font-bold text-2xl'>
                    Need advice from a
                </BringzzText>
                <BringzzText className='font-bold text-2xl'>
                    Pharmacist near you?
                </BringzzText>
            </div>

            <div className='flex items-center justify-center gap-8'>
                <BringzzButton
                    className='border border-black'
                    icon={{ variant: 'IconPhone', folder: 'LineIcons', size: '16' }}
                >
                    Call
                </BringzzButton>
                <BringzzButton
                    className='border border-black'
                    icon={{
                        variant: 'IconChatAlt10',
                        folder: 'LineIcons',
                        size: '16'
                    }}
                >
                    Chat
                </BringzzButton>
            </div>
            <div className='p-4 mb-20'>
                <BringzzRecommendationItem
                    title='Flu & Cold Remedies'
                    subTitle='Turpis rutrum amet vitae ac integer et pharetra gravida. Praesent laoreet amet...'
                    className='bg-electric-blue'
                    image={RecommendationItemBackground}
                >
                    <BringzzProductBox
                        productId={productId}
                        image={ProductExample}
                        manufacturer={'Manufacturer'}
                        label={'One Multi Vitamines'}
                        productType='Lotion'
                        productQuantity='100 ml'
                        price='18,50'
                        rebateValue={5}
                        rebateType={'€'}
                        rebateLabel={'off'}
                        rebatePosition='right-0'
                        topRightChildren={
                            <BringzzIcon
                                icon='IconBookmark'
                                folder='LineIcons'
                                className='text-midnight-blue p-3'
                                size='24'
                                onClick={event => doFav(event, productId)}
                            ></BringzzIcon>
                        }
                        onClick={productId => clickOnProduct(productId)}
                    >
                        <BringzzCartSelector
                            productId={productId}
                            maxQuantity={3}
                            onCartClick={(productId, quantity) =>
                                addToCart(productId, quantity)
                            }
                        ></BringzzCartSelector>
                    </BringzzProductBox>
                    <BringzzProductBox
                        productId={productId}
                        image={ProductExample}
                        manufacturer={'Manufacturer'}
                        label={'One Multi Vitamines'}
                        productType='Lotion'
                        productQuantity='100 ml'
                        price='18,50'
                        rebateValue={5}
                        rebateType={'€'}
                        rebateLabel={'off'}
                        rebatePosition='right-0'
                        topRightChildren={
                            <BringzzIcon
                                icon='IconBookmark'
                                folder='LineIcons'
                                className='text-midnight-blue p-3'
                                size='24'
                                onClick={event => doFav(event, productId)}
                            ></BringzzIcon>
                        }
                        onClick={productId => clickOnProduct(productId)}
                    >
                        <BringzzCartSelector
                            productId={productId}
                            maxQuantity={3}
                            onCartClick={(productId, quantity) =>
                                addToCart(productId, quantity)
                            }
                        ></BringzzCartSelector>
                    </BringzzProductBox>
                    <BringzzProductBox
                        productId={productId}
                        image={ProductExample}
                        manufacturer={'Manufacturer'}
                        label={'One Multi Vitamines'}
                        productType='Lotion'
                        productQuantity='100 ml'
                        price='18,50'
                        rebateValue={5}
                        rebateType={'€'}
                        rebateLabel={'off'}
                        rebatePosition='right-0'
                        topRightChildren={
                            <BringzzIcon
                                icon='IconBookmark'
                                folder='LineIcons'
                                className='text-midnight-blue p-3'
                                size='24'
                                onClick={event => doFav(event, productId)}
                            ></BringzzIcon>
                        }
                        onClick={productId => clickOnProduct(productId)}
                    >
                        <BringzzCartSelector
                            productId={productId}
                            maxQuantity={3}
                            onCartClick={(productId, quantity) =>
                                addToCart(productId, quantity)
                            }
                        ></BringzzCartSelector>
                    </BringzzProductBox>
                    <BringzzProductBox
                        productId={productId}
                        image={ProductExample}
                        manufacturer={'Manufacturer'}
                        label={'One Multi Vitamines'}
                        productType='Lotion'
                        productQuantity='100 ml'
                        price='18,50'
                        rebateValue={5}
                        rebateType={'€'}
                        rebateLabel={'off'}
                        rebatePosition='right-0'
                        topRightChildren={
                            <BringzzIcon
                                icon='IconBookmark'
                                folder='LineIcons'
                                className='text-midnight-blue p-3'
                                size='24'
                                onClick={event => doFav(event, productId)}
                            ></BringzzIcon>
                        }
                        onClick={productId => clickOnProduct(productId)}
                    >
                        <BringzzCartSelector
                            productId={productId}
                            maxQuantity={3}
                            onCartClick={(productId, quantity) =>
                                addToCart(productId, quantity)
                            }
                        ></BringzzCartSelector>
                    </BringzzProductBox>
                </BringzzRecommendationItem>
            </div>
        </div>
    )
}

export default BasketRecommendationSection