import * as React from "react";
const IconTimerDesktopAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32.9 27.8c7.1 0 12.9-5.8 12.9-12.9S40 2 32.9 2 20 7.8 20 14.9s5.8 12.9 12.9 12.9m0-23.8c6 0 10.9 4.9 10.9 10.9s-4.9 10.9-10.9 10.9S22 20.9 22 14.9 26.9 4 32.9 4" />
    <path d="M34.1 19.3c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-2.2-2.2v-3.8c0-.6-.4-1-1-1s-1 .4-1 1v4.2c0 .3.1.5.3.7z" />
    <path d="M55.1 10.6h-2.3c-.6 0-1 .4-1 1s.4 1 1 1h2.3c1.8 0 3.2 1.4 3.2 3.2v29.7c0 1.8-1.4 3.2-3.2 3.2H8.9c-1.8 0-3.2-1.4-3.2-3.2V15.8c0-1.8 1.4-3.2 3.2-3.2h2.5c.6 0 1-.4 1-1s-.4-1-1-1H8.9c-2.9 0-5.2 2.3-5.2 5.2v29.7c0 2.9 2.3 5.2 5.2 5.2h22.2V60H25c-.6 0-1 .4-1 1s.4 1 1 1h14c.6 0 1-.4 1-1s-.4-1-1-1h-5.9v-9.3h22c2.9 0 5.2-2.3 5.2-5.2V15.8c0-2.8-2.3-5.2-5.2-5.2" />
  </svg>
);
export default IconTimerDesktopAlt;
