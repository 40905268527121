import * as React from "react";
const IconBaloonAlt3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.9 20.9C50.9 10.5 42.4 2 32 2s-18.9 8.5-18.9 18.9c0 9.8 7.4 17.8 16.9 18.8l-3.3 2.1c-.5.3-.6.9-.3 1.4.2.3.5.5.8.5.2 0 .4-.1.5-.2l3.3-2.1v4.1c0 2.8 2.3 5.1 5.1 5.1h1.7c2.5 0 4.6 2.1 4.6 4.6s-2.1 4.6-4.6 4.6H24c-.6 0-1 .4-1 1s.4 1 1 1h13.8c3.7 0 6.6-3 6.6-6.6s-3-6.6-6.6-6.6h-1.7c-1.7 0-3.1-1.4-3.1-3.1V41c.1.1.1.1.2.1l3.8 2.4c.2.1.4.2.5.2.3 0 .7-.2.8-.5.3-.5.2-1.1-.3-1.4l-3.5-2.2c9.3-1.2 16.4-9.1 16.4-18.7M32 37.8c-9.3 0-16.9-7.6-16.9-16.9S22.7 4 32 4s16.9 7.6 16.9 16.9S41.3 37.8 32 37.8" />
    <path d="M32 11.9c-5 0-9 4.1-9 9 0 .6.4 1 1 1s1-.4 1-1c0-3.9 3.2-7 7-7 .6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconBaloonAlt3;
