import * as React from "react";
const IconPassport3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M18.3 20c.1 0 .2 0 .3-.1l5.3-1.8V25l-3.8 2c-.5.2-.7.8-.4 1.3q.3.6.9.6c.1 0 .3 0 .4-.1l4-2 4 2c.1.1.3.1.4.1q.6 0 .9-.6c.2-.5 0-1.1-.4-1.3L26 25v-6.8l5.3 1.8c.1 0 .2.1.3.1.4 0 .8-.3.9-.7.2-.5-.1-1.1-.6-1.3l-5.9-2v-3.7c0-.6-.4-1-1-1s-1 .4-1 1v3.7l-5.9 2c-.5.2-.8.7-.6 1.3 0 .4.4.6.8.6M30.2 46.8H19.7c-.6 0-1 .4-1 1s.4 1 1 1h10.5c.6 0 1-.4 1-1s-.4-1-1-1M32.9 39.2H17c-.6 0-1 .4-1 1s.4 1 1 1h15.8c.6 0 1-.4 1-1s-.4-1-.9-1" />
    <path d="M53.3 2H7.1c-1.7 0-3 1.3-3 3v54c0 1.7 1.3 3 3 3h46.1c3.7 0 6.6-3 6.6-6.6V8.6C59.9 5 56.9 2 53.3 2M36.1 60h-29c-.6 0-1-.4-1-1V5c0-.6.4-1 1-1h33.7c1.7 0 3 1.3 3 3v50c0 1.7-1.3 3-3 3zm21.8-4.6c0 2.6-2.1 4.6-4.6 4.6h-8.5c.6-.8 1-1.9 1-3V7c0-1.1-.4-2.2-1-3h8.5c2.6 0 4.6 2.1 4.6 4.6z" />
  </svg>
);
export default IconPassport3;
