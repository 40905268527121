import * as React from "react";
const IconSignalAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M7 46c-.6 0-1 .4-1 1v13c0 .6.4 1 1 1s1-.4 1-1V47c0-.6-.5-1-1-1M19.5 35.2c-.6 0-1 .4-1 1V60c0 .6.4 1 1 1s1-.4 1-1V36.2c0-.5-.5-1-1-1M32 24.5c-.6 0-1 .4-1 1V60c0 .6.4 1 1 1s1-.4 1-1V25.5c0-.6-.4-1-1-1M44.5 13.7c-.6 0-1 .4-1 1V60c0 .6.4 1 1 1s1-.4 1-1V14.7c0-.5-.4-1-1-1M57 3c-.6 0-1 .4-1 1v56c0 .6.4 1 1 1s1-.4 1-1V4c0-.6-.4-1-1-1" />
  </svg>
);
export default IconSignalAlt;
