import * as React from "react";
const IconFeather = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.6 7.4C53.7 4.6 49.9 3 45.8 3c-4 0-7.9 1.6-10.7 4.4L22.5 20c-6.4 6.4-9.5 15.2-8.6 24.1l.4 4.1-11 11.1c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3l11.1-11.1 4.1.4c1 .1 2.1.2 3.1.2 7.9 0 15.4-3.1 21-8.7l12.6-12.6C59.4 26 61 22.2 61 18.2c0-4.1-1.6-7.9-4.4-10.8M20 48.1l-2.4-.3 7.8-7.8 16.5.7c-5.8 5.5-13.8 8.2-21.9 7.4m13.8-16.5 16.5.7-6.4 6.4-16.5-.7zm21.3-4.1-2.9 2.9-16.5-.7 6.9-6.9c.4-.4.4-1 0-1.4s-1-.4-1.4 0L24.4 38.2l-.1.1-8.1 8.1-.3-2.4c-.9-8.4 2.1-16.6 8-22.5L36.5 8.9C39 6.4 42.3 5 45.8 5s6.8 1.4 9.3 3.9 3.9 5.8 3.9 9.3-1.4 6.8-3.9 9.3" />
  </svg>
);
export default IconFeather;
