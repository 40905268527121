import * as React from "react";
const IconDisplayAltCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57 4.1H7c-2.8 0-5 2.2-5 5v31.6c0 2.8 2.2 5 5 5h18.1l-3.3 12.2h-5.6c-.6 0-1 .4-1 1s.4 1 1 1h31.6c.6 0 1-.4 1-1s-.4-1-1-1h-5.6l-3.3-12.2H57c2.8 0 5-2.2 5-5V9.1c0-2.7-2.2-5-5-5M40.1 57.9H23.9l3.3-12.2h9.6zM60 40.7c0 1.7-1.3 3-3 3H7c-1.7 0-3-1.3-3-3V9.1c0-1.7 1.3-3 3-3h50c1.7 0 3 1.3 3 3z" />
  </svg>
);
export default IconDisplayAltCopy;
