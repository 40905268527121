import * as React from "react";
const IconCrownCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.4 9.6c-1.6-1-3.6-1-5.2 0l-7 4.2c-1.1.6-2.4.6-3.4-.2l-8.7-6.9c-1.8-1.5-4.5-1.4-6.3 0l-8.7 7.1c-1 .8-2.4.9-3.4.3L9.8 10c-1.7-1-3.7-1-5.3 0S2 12.8 2.2 14.7l3.3 37.6c.3 3.5 3.2 6.1 6.6 6.1h39.7c3.5 0 6.3-2.6 6.6-6.1l3.4-38.1c.2-1.8-.7-3.6-2.4-4.6m-7.6 46.8H12.1c-2.4 0-4.4-1.8-4.6-4.3l-1-11.9h51l-1.1 11.9c-.2 2.5-2.2 4.3-4.6 4.3m8-42.3-2.1 24.1H6.3L4.2 14.5c-.1-1.1.4-2.2 1.4-2.8.5-.3 1-.5 1.6-.5.5 0 1.1.1 1.5.4l6.9 4.1c1.8 1.1 4.1.9 5.7-.4L30 8.2c1.1-.9 2.7-.9 3.8 0l8.7 6.9c1.6 1.3 3.9 1.4 5.7.4l7-4.2c1-.6 2.2-.6 3.1 0 1.1.6 1.6 1.7 1.5 2.8" />
    <path d="M20.7 44.9c-1.6 0-2.8 1.3-2.8 2.8s1.3 2.8 2.8 2.8c1.6 0 2.8-1.3 2.8-2.8s-1.2-2.8-2.8-2.8" />
    <circle cx={32} cy={47.7} r={2.8} />
    <circle cx={43.3} cy={47.7} r={2.8} />
  </svg>
);
export default IconCrownCopy;
