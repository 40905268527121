import * as React from "react";
const IconBootstrap5 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 65 64"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M13.353 7C9.7 7 6.999 10.135 7.12 13.534 7.236 16.8 7.085 21.03 6 24.479 4.91 27.94 3.067 30.13.06 30.412v3.176c3.008.282 4.85 2.473 5.94 5.933 1.085 3.45 1.236 7.679 1.12 10.945C6.999 53.865 9.7 57 13.353 57h37.418c3.652 0 6.354-3.135 6.233-6.534-.116-3.266.035-7.496 1.12-10.945 1.09-3.46 2.927-5.652 5.936-5.933v-3.176c-3.009-.282-4.846-2.473-5.936-5.933-1.086-3.449-1.236-7.679-1.12-10.945C57.124 10.135 54.424 7 50.77 7zm30.096 30.778c0 4.681-3.56 7.52-9.468 7.52H23.924c-.288 0-.564-.112-.767-.312s-.318-.47-.318-.752V19.766c0-.282.114-.553.318-.752.203-.2.48-.312.767-.312h10c4.926 0 8.16 2.617 8.16 6.635 0 2.82-2.176 5.345-4.946 5.788v.147c3.772.405 6.311 2.968 6.311 6.506M32.885 22.075H27.15v7.943h4.83c3.734 0 5.792-1.474 5.792-4.11 0-2.47-1.77-3.834-4.887-3.834M27.15 33.17v8.755h5.946c3.887 0 5.946-1.53 5.946-4.405s-2.117-4.35-6.196-4.35z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconBootstrap5;
