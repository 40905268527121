import * as React from "react";
const IconReloadAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M56.9 15.1H9.4L19.7 4.7c.4-.4.4-1 0-1.4s-1-.4-1.4 0L6.4 15.4c-.4.4-.4 1 0 1.4l11.9 12.1c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4L9.5 17.1h46.4v7.3c0 .6.4 1 1 1s1-.4 1-1v-8.3c0-.5-.4-1-1-1M45.7 35.1c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l10.2 10.4H8.1v-7.3c0-.6-.4-1-1-1s-1 .4-1 1v8.3c0 .6.4 1 1 1h47.5L44.3 59.3c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3l11.9-12.1c.4-.4.4-1 0-1.4z" />
  </svg>
);
export default IconReloadAlt;
