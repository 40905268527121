import * as React from "react";
const IconDairy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M21.675 6 18 21.623v18.899c0 .913.68 1.478 1.306 1.478h10.388c.627 0 1.306-.565 1.306-1.478V21.623L27.325 6zm-2.37 1.34s-.53.044-.99-.234c-.352-.212-.662-.613-.662-1.367C17.653 4 18.479 4 20.958 4h7.084c2.479 0 3.305 0 3.305 1.74 0 .753-.31 1.154-.662 1.367-.46.277-.99.232-.99.232L33 21.391v19.13C33 42.444 31.52 44 29.694 44H19.306C17.48 44 16 42.443 16 40.522v-19.13z"
      clipRule="evenodd"
    />
    <path
      
      fillRule="evenodd"
      d="M17.406 23.837 17 22.923c-.407-.913-.406-.914-.406-.914h.002l.005-.003.014-.006.048-.02.171-.07a13.733 13.733 0 0 1 2.615-.75c1.51-.27 3.681-.35 5.216 1.016.952.848 2.472 1.328 3.885 1.58a17 17 0 0 0 2.412.244H31v2h-.068l-.168-.005a18.595 18.595 0 0 1-2.564-.27c-1.503-.268-3.484-.826-4.865-2.055-.799-.71-2.128-.792-3.535-.541a11.7 11.7 0 0 0-2.357.692l-.031.013z"
      clipRule="evenodd"
    />
    <path  d="M19 5h11l-1 2h-9z" />
  </svg>
);
export default IconDairy;
