import * as React from "react";
const IconIntEcommerce = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.3 9.4h-7.5c-.5 0-.9.3-1 .8L47.7 23h-5c.4-1.4.5-2.8.5-4.2 0-3-.8-5.9-2.3-8.5-3-5-8.2-8.1-14.1-8.2h-1.1c-5.6.2-10.7 3.3-13.5 8.1-1.5 2.6-2.3 5.5-2.3 8.5 0 1.4.2 2.9.5 4.2H4.7c-.3 0-.6.1-.8.4-.2.2-.3.6-.2.8l4.7 20.2c.1.5.5.8 1 .8h34.2c.5 0 .9-.3 1-.8l8-33h6.7c.6 0 1-.4 1-1s-.4-.9-1-.9m-18.1 9.3c0 1.4-.2 2.9-.6 4.2H32q.3-2.1.3-4.2c0-1.7-.2-3.3-.4-4.9 2.6-.3 5.2-.9 7.7-1.8 1.1 2 1.6 4.4 1.6 6.7m-18.8 4.2q-.3-2.1-.3-4.2c0-1.6.2-3.2.4-4.8 1.3.1 2.7.2 4 .2 1.1 0 2.3-.1 3.3-.1.3 1.5.4 3.1.4 4.7q0 2.25-.3 4.2zm3.8-18.6c1.5 2.4 2.6 5 3.3 7.7-2.1.2-4.4.1-6.5-.1.6-2.6 1.7-5.2 3.2-7.6m12.3 5.9c-2.3.8-4.6 1.3-7 1.6-.7-2.7-1.7-5.3-3.1-7.7 4.1.5 7.7 2.7 10.1 6.1M23.9 4.3c-1.3 2.4-2.3 4.9-3 7.4-2.2-.3-4.3-.8-6.4-1.5 2.3-3.1 5.6-5.2 9.4-5.9M11.8 18.7c0-2.4.6-4.7 1.6-6.8 2.3.8 4.6 1.4 7 1.7-.3 1.6-.4 3.3-.4 5q0 2.1.3 4.2h-7.9c-.3-1.2-.6-2.6-.6-4.1m31 24.4H10.2L6 24.9h41.3zM38.2 51.8H14.7c-2.8 0-5.1 2.3-5.1 5.1s2.3 5.1 5.1 5.1 5.1-2.3 5.1-5.1c0-1.2-.4-2.2-1.1-3.1h15.4c-.7.9-1.1 1.9-1.1 3.1 0 2.8 2.3 5.1 5.1 5.1s5.1-2.3 5.1-5.1c.1-2.8-2.2-5.1-5-5.1m-20.4 5.1c0 1.7-1.4 3.1-3.1 3.1s-3.1-1.4-3.1-3.1 1.4-3.1 3.1-3.1 3.1 1.4 3.1 3.1M38.2 60c-1.7 0-3.1-1.4-3.1-3.1s1.4-3.1 3.1-3.1 3.1 1.4 3.1 3.1-1.4 3.1-3.1 3.1" />
  </svg>
);
export default IconIntEcommerce;
