import * as React from "react";
const IconSr = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 85.333 512 341.333"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path  d="M0 85.331h512v341.337H0z" />
    <path  d="M0 196.636h512v118.728H0z" />
    <path  d="M0 352.462h512v74.207H0zM0 85.331h512v74.207H0z" />
    <path
      
      d="m256.742 218.003 9.43 29.021h30.518L272 264.963l9.431 29.023-24.689-17.937-24.689 17.937 9.431-29.023-24.69-17.939h30.518z"
    />
  </svg>
);
export default IconSr;
