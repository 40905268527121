import * as React from "react";
const IconChest = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M50.8 2H13.3c-2.2 0-4 1.8-4 4v52c0 2.2 1.8 4 4 4h37.5c2.2 0 4-1.8 4-4V6c0-2.2-1.8-4-4-4m2 56c0 1.1-.9 2-2 2H13.3c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2h37.5c1.1 0 2 .9 2 2z" />
    <path d="M46.2 29.1c-.1 0-7.1 2.9-13.1-5.8v-7.8c3.6 4.1 7.4 5 10.2 5 2.2 0 3.7-.6 3.7-.6.5-.2.7-.8.5-1.3s-.8-.7-1.3-.5c-.4-.1-7.2 2.7-13.2-6v-1.5c0-.6-.4-1-1-1s-1 .4-1 1v1.9c-5.9 8.1-12.6 5.5-12.9 5.4-.5-.2-1.1 0-1.3.5s0 1.1.5 1.3c0 0 1.5.6 3.8.6 2.7 0 6.4-.9 9.9-4.7v8.1c-5.9 8-12.6 5.4-12.9 5.3-.5-.2-1.1 0-1.3.5s0 1.1.5 1.3c0 0 1.5.6 3.8.6 2.7 0 6.3-.9 9.9-4.6v8.5c0 .6.4 1 1 1s1-.4 1-1v-9c3.6 4.2 7.5 5.1 10.2 5.1 2.2 0 3.7-.6 3.7-.6.5-.2.7-.8.5-1.3-.1-.4-.7-.6-1.2-.4M40.6 41.2c-3.3-.9-7.2 1.5-8.7 2.5-1.5-1-5.6-3.4-9-2.5-1.6.4-2.8 1.6-3.6 3.3-1.3 3.1-.4 5.3.6 6.5 2.5 3.1 7.8 3.9 11.7 3.4h.3c.8.1 1.6.2 2.4.2 3.5 0 7.3-1 9.2-3.5 1-1.3 1.9-3.4.6-6.5-.7-1.8-1.9-2.9-3.5-3.4m1.4 8.7c-1.7 2.1-5.8 3.2-9.9 2.6h-.7999999999999999c-4 .6-8.2-.5-9.9-2.6-1-1.2-1.1-2.8-.3-4.5.5-1.2 1.2-1.9 2.2-2.2 2.6-.7 6.5 1.6 7.8 2.6l.6.5.6-.5c1.2-1 5-3.3 7.6-2.6 1 .3 1.7 1 2.2 2.2 1 1.8.9 3.3-.1 4.5" />
  </svg>
);
export default IconChest;
