import * as React from "react";
const IconUnderweight = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "48"}
    height={props.size || "48"}
    
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      
      fillRule="evenodd"
      d="M20 8.5a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0M24.5 6a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5m5.448 11.338c-4.262-1.148-7.642-1.098-11.895.036a.5.5 0 0 0-.219.124c-.387.374-.679 1.001-.912 1.667-.242.69-.449 1.507-.627 2.337a73 73 0 0 0-.606 3.337l-.001.008c-.068.412-.129.78-.18 1.064a.5.5 0 1 0 .984.178q.094-.522.204-1.208l.002-.01c.152-.928.336-2.043.575-3.159.174-.812.37-1.584.592-2.216q.159-.453.324-.777l.207-.404.441-.11q.623-.156 1.224-.277l.906-.183 2.096 7.334-2.563 5.638V41.5a.5.5 0 0 0 1 0v-12h5v12a.5.5 0 0 0 1 0V30.717l-2.563-5.638 2.105-7.365.903.18q.675.134 1.376.313l.345.088.212.286c.152.207.314.5.473.913.238.622.417 1.385.56 2.195.142.808.244 1.645.334 2.413l.028.236c.079.672.151 1.29.235 1.751a.5.5 0 0 0 .984-.178c-.078-.431-.146-1.008-.224-1.674l-.03-.251c-.09-.769-.195-1.63-.342-2.47-.147-.837-.34-1.67-.612-2.38-.266-.696-.635-1.343-1.179-1.725a.5.5 0 0 0-.157-.073m-12.41-1.896c4.573-1.22 8.31-1.28 12.93-.035.281.075.547.2.785.367 1.036.726 1.585 1.828 1.9 2.647.337.882.556 1.86.713 2.749.158.901.269 1.814.36 2.583l.025.218v.009c.083.703.143 1.213.209 1.573a2.5 2.5 0 1 1-4.92.894c-.096-.532-.176-1.21-.25-1.853l-.03-.259a41 41 0 0 0-.319-2.3c-.133-.758-.286-1.377-.458-1.826l-.043-.108-1.378 4.82 2.438 5.362V41.5a2.5 2.5 0 1 1-5 0v-10h-1v10a2.5 2.5 0 1 1-5 0V30.283l2.437-5.362-1.279-4.478a19 19 0 0 0-.43 1.688 64 64 0 0 0-.558 3.074v.006c-.075.449-.144.87-.21 1.236a2.5 2.5 0 1 1-4.92-.894c.048-.265.107-.617.176-1.04l.001-.008c.158-.96.369-2.239.623-3.422.185-.865.412-1.776.694-2.58.246-.7.662-1.72 1.41-2.443a2.5 2.5 0 0 1 1.094-.618"
      clipRule="evenodd"
    />
  </svg>
);
export default IconUnderweight;
