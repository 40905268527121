import * as React from "react";
const IconRootSign = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M16.6 45.5c-.9 0-1.8-.4-2.3-1.2l-5.9-7.8c-.2-.2-.4-.4-.7-.4s-.5.1-.7.3l-3.2 3.7c-.4.4-1 .5-1.4.1s-.5-1-.1-1.4l3.2-3.7c.6-.7 1.4-1 2.3-1s1.7.5 2.2 1.2l5.9 7.8c.3.4.7.4.9.4s.6-.1.7-.6l9.6-22.6c.5-1.1 1.5-1.8 2.7-1.8H61c.6 0 1 .4 1 1s-.4 1-1 1H29.8q-.6 0-.9.6l-9.6 22.6c-.4 1-1.3 1.6-2.3 1.8z" />
  </svg>
);
export default IconRootSign;
