import * as React from "react";
const IconUpAlign3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M32 41.6c-.6 0-1 .4-1 1v18.2c0 .6.4 1 1 1s1-.4 1-1V42.6c0-.5-.4-1-1-1M13.3 59.6H7.6c-1.8 0-3.3-1.5-3.3-3.3v-5.7c0-.6-.4-1-1-1s-1 .4-1 1v5.7c0 2.9 2.4 5.3 5.3 5.3h5.7c.6 0 1-.4 1-1s-.4-1-1-1M60.7 49.5c-.6 0-1 .4-1 1v5.7c0 1.8-1.5 3.3-3.3 3.3h-5.7c-.6 0-1 .4-1 1s.4 1 1 1h5.7c2.9 0 5.3-2.4 5.3-5.3v-5.7c0-.5-.4-1-1-1M56.4 4.1c1.8 0 3.3 1.5 3.3 3.3v5.7c0 .6.4 1 1 1s1-.4 1-1V7.5c0-2.9-2.4-5.3-5.3-5.3H7.6c-2.9 0-5.3 2.4-5.3 5.3v5.7c0 .6.4 1 1 1s1-.4 1-1V7.5c0-1.8 1.5-3.3 3.3-3.3H31v16.900000000000002c0 .6.4 1 1 1s1-.4 1-1V4.2h23.4zM43.3 59.6h-1.4c-.6 0-1 .4-1 1s.4 1 1 1h1.4c.6 0 1-.4 1-1s-.4-1-1-1M21.8 59.6h-1.4c-.6 0-1 .4-1 1s.4 1 1 1h1.4c.6 0 1-.4 1-1s-.5-1-1-1M3.1 44.3c.6 0 1-.4 1-1v-1.4c0-.6-.4-1-1-1s-1 .4-1 1v1.4c0 .6.5 1 1 1M3.1 22.8c.6 0 1-.4 1-1v-1.4c0-.6-.4-1-1-1s-1 .4-1 1v1.4c0 .6.5 1 1 1M59.5 41.9v1.4c0 .6.4 1 1 1s1-.4 1-1v-1.4c0-.6-.4-1-1-1s-1 .5-1 1M61.5 21.8v-1.4c0-.6-.4-1-1-1s-1 .4-1 1v1.4c0 .6.4 1 1 1s1-.4 1-1M3 32.9h18.2c.6 0 1-.4 1-1s-.4-1-1-1H3c-.6 0-1 .4-1 1 0 .5.4 1 1 1M62 31.9c0-.6-.4-1-1-1H42.8c-.6 0-1 .4-1 1s.4 1 1 1H61c.6 0 1-.5 1-1" />
  </svg>
);
export default IconUpAlign3;
