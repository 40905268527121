import * as React from "react";
const IconThunderAltCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.2 24c-2.7-3-6.5-4.9-10.5-5.5-2.1-3.6-5.3-6.3-9.1-7.6-1.6-.6-3.5-1-5.5-1C22.7 9.9 15 17.6 15 27v.1c-7.4.4-13 6.2-13 13.5C2 48.1 7.9 54 15.3 54h28.8C54 54 62 46 62 36.2c0-4.5-1.7-8.8-4.8-12.2M44.1 52H15.3C9 52 4 47 4 40.6s5-11.4 11.4-11.4h.5c.6 0 1-.4 1-1v-1.1C16.9 18.8 23.7 12 32 12c1.8 0 3.4.3 4.8.8 3.5 1.2 6.5 3.8 8.3 7.1.2.3.4.5.8.5 3.7.4 7.3 2.2 9.8 4.9 2.7 3 4.2 6.8 4.2 10.8.1 8.8-7 15.9-15.8 15.9" />
    <path d="M36.9 32.7h-6.7l6.6-8.6c.3-.4.3-1.1-.2-1.4-.4-.3-1.1-.3-1.4.2l-7.5 9.6c-.3.4-.4 1-.1 1.4.2.5.7.8 1.2.8h6.7l-7.2 8.5c-.4.4-.3 1.1.1 1.4.2.2.4.2.6.2.3 0 .6-.1.8-.4l8-9.6c.3-.4.4-1 .2-1.4-.1-.4-.6-.7-1.1-.7" />
  </svg>
);
export default IconThunderAltCopy;
