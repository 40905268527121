import * as React from "react";
const IconYogaBall = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M59.6 20.3c-1.7-4-4.4-7.7-7.6-10.6C46.5 4.7 39.4 2 32 2S17.5 4.7 12 9.7c-3.3 2.9-5.9 6.6-7.6 10.6Q2 25.85 2 32c0 1.5.1 3.1.3 4.6C4.6 51.1 17.3 62 32 62s27.4-10.9 29.7-25.4c.2-1.5.3-3.1.3-4.6q0-6.15-2.4-11.7m-10-10.1C44.9 12.8 38 15.1 32 15.1s-12.9-2.4-17.6-4.9C19.4 6.2 25.6 4 32 4s12.6 2.2 17.6 6.2m-36.8 1.4c5 2.8 12.6 5.5 19.2 5.5s14.2-2.7 19.2-5.5c2.7 2.5 4.8 5.5 6.3 8.8-5.9 6.5-15.3 10.3-25.5 10.3S12.4 26.9 6.5 20.5c1.5-3.3 3.7-6.4 6.3-8.9M5.7 22.5c6.3 6.4 16 10.2 26.3 10.2 10.4 0 20.1-3.8 26.4-10.2 1.1 3.1 1.7 6.2 1.7 9.5 0 1.3-.1 2.7-.3 4C53 42.7 43 46.6 32 46.6S11 42.7 4.3 36c-.2-1.3-.3-2.7-.3-4 0-3.3.6-6.5 1.7-9.5M32 60C19.3 60 8.2 51.3 5 39.3c7 5.9 16.6 9.2 27 9.2s20.1-3.3 27-9.2C55.8 51.3 44.6 60 32 60" />
  </svg>
);
export default IconYogaBall;
