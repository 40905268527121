import * as React from "react";
const IconSpeechBubble39 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.3 46.8c-2.1-.9-3.6-2.7-4.1-4.9 5-3.2 7.9-7.8 7.8-12.7 0-5.1-3.1-9.9-8.6-13.1-11-6.5-31.8-6.5-42.8 0C5.1 19.3 2 24 2 29.1c0 4.9 2.8 9.5 7.8 12.7-.6 2.2-2.1 4-4.1 4.9-1 .4-1.6 1.4-1.6 2.5s.6 2 1.6 2.5q2.7 1.2 5.4 1.2c4.5 0 8.8-2.4 11.2-6.5 6.1 1.1 13.3 1.1 19.4 0 3.3 5.8 10.5 8.1 16.6 5.3 1-.4 1.6-1.4 1.6-2.5s-.6-2-1.6-2.4m-.8 3c-5.4 2.5-11.7.3-14.3-5-.2-.4-.6-.6-1.1-.5-6.2 1.2-13.9 1.2-20.1 0-.4-.1-.9.1-1.1.5-2.7 5.3-8.9 7.5-14.3 5-.4-.2-.4-.5-.4-.6s0-.5.4-.6c2.9-1.3 4.9-3.9 5.4-7 .1-.4-.1-.8-.5-1C6.7 37.7 4 33.5 4 29.2c0-4.4 2.8-8.5 7.6-11.4 5.2-3.1 12.8-4.6 20.4-4.6s15.1 1.5 20.4 4.6c4.8 2.8 7.6 7 7.6 11.4s-2.7 8.5-7.5 11.4c-.4.2-.5.6-.5 1 .5 3.1 2.6 5.7 5.4 7 .4.2.4.5.4.6.1.1 0 .5-.3.6" />
    <path d="M44.8 24.6H19.2c-.6 0-1 .4-1 1s.4 1 1 1h25.5c.6 0 1-.4 1-1s-.4-1-.9-1M44.8 32.9H19.2c-.6 0-1 .4-1 1s.4 1 1 1h25.5c.6 0 1-.4 1-1s-.4-1-.9-1" />
  </svg>
);
export default IconSpeechBubble39;
