import * as React from "react";
const IconElephant = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 65 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.6 57.3h-7c-1.7 0-3.2-1.4-3.2-3.1v-8.6c0-.6-.5-1.1-1.2-1.1h-8.7c-.6 0-1.2.5-1.2 1.1v8.6c0 1.7-1.4 3.1-3.2 3.1h-7c-1.7 0-3.2-1.4-3.2-3.1V32.9l-6.4 4v13.3c0 2.3-1.9 4.2-4.2 4.2H7.7c-2.3 0-4.2-1.9-4.2-4.2V14.1c0-4.6 3.8-8.3 8.5-8.3h16c3.2 0 6.1 1.8 7.5 4.4h13.4c6.9 0 12.6 5.6 12.6 12.4v13.1c0 .2 0 .3-.1.5l-3.6 6.5v11.5c-.1 1.7-1.5 3.1-3.2 3.1M34.7 42.5h8.7c1.7 0 3.2 1.4 3.2 3.1v8.6c0 .6.5 1.1 1.2 1.1h7c.6 0 1.2-.5 1.2-1.1V42.4c0-.2 0-.3.1-.5l3.6-6.5V22.6c0-5.7-4.7-10.4-10.6-10.4H36.3c.1.6.2 1.3.2 1.9V23c0 4.6-3.8 8.3-8.5 8.3h-5.3l-.5.3v22.6c0 .6.5 1.1 1.2 1.1h7c.6 0 1.2-.5 1.2-1.1v-8.6c-.1-1.7 1.3-3.1 3.1-3.1M12 7.8c-3.6 0-6.5 2.8-6.5 6.3v36.1c0 1.2 1 2.2 2.2 2.2h1.9c1.2 0 2.2-1 2.2-2.2V36.4c0-.3.2-.7.5-.8l9.6-6c.2-.1.3-.2.5-.2H28c3.6 0 6.5-2.8 6.5-6.3v-8.9c0-3.5-2.9-6.3-6.5-6.3z" />
  </svg>
);
export default IconElephant;
