import * as React from "react";
const Icon5gAlt = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M27.9 36.2c.6 0 1-.4 1-1s-.4-1-1-1H19c-.6 0-1 .4-1 1v8.3c0 .6.4 1 1 1l7.8.1-.1 6.2H19c-.6 0-1 .4-1 1s.4 1 1 1h7.8c1.1 0 2.1-.9 2.1-2.1v-6.2c0-1.1-.9-2.1-2.1-2.1H20v-6.3h7.9zM45 36.2c.6 0 1-.4 1-1s-.4-1-1-1h-7.8c-1.1 0-2.1.9-2.1 2.1v14.4c0 1.1.9 2.1 2.1 2.1h6.7c1.1 0 2.1-.9 2.1-2.1v-5.1c0-1.1-.9-2.1-2.1-2.1h-2.3c-.6 0-1 .4-1 1s.4 1 1 1l2.3.1-.1 5.2-6.8-.1.1-14.5z" />
    <path d="M57.8 25.1H33.9v-7c2.5-.5 4.4-2.7 4.4-5.3 0-3-2.4-5.4-5.4-5.4s-5.4 2.4-5.4 5.4c0 2.6 1.9 4.8 4.4 5.3v7H6.2c-2.2 0-4 1.8-4 4V58c0 2.2 1.8 4 4 4h51.6c2.2 0 4-1.8 4-4V29.1c0-2.2-1.8-4-4-4M29.5 12.8c0-1.9 1.5-3.4 3.4-3.4s3.4 1.5 3.4 3.4c0 1.8-1.4 3.3-3.2 3.4h-.4a3.4 3.4 0 0 1-3.2-3.4M59.8 58c0 1.1-.9 2-2 2H6.2c-1.1 0-2-.9-2-2V29.1c0-1.1.9-2 2-2h51.6c1.1 0 2 .9 2 2z" />
    <path d="M45.9 15.3c.2.2.5.3.7.3s.5-.1.7-.3c3-3 3-8 0-11-.4-.4-1-.4-1.4 0s-.4 1 0 1.4c2.3 2.3 2.3 5.9 0 8.2-.4.4-.4 1.1 0 1.4M53.2 17.4c.2.2.5.3.7.3s.5-.1.7-.3c2-2 3.1-4.7 3.1-7.5s-1.1-5.5-3.1-7.5c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4c1.6 1.6 2.5 3.8 2.5 6.1s-.9 4.5-2.5 6.1c-.4.4-.4 1 0 1.4M17.4 15.6c.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4-2.3-2.3-2.3-5.9 0-8.2.4-.4.4-1 0-1.4s-1-.4-1.4 0c-3 3-3 8 0 11 .2.2.5.3.7.3M10.1 17.7c.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4-3.4-3.4-3.4-8.9 0-12.3.4-.4.4-1 0-1.4s-1-.4-1.4 0c-4.2 4.2-4.2 10.9 0 15.1.1.2.4.3.7.3" />
  </svg>
);
export default Icon5gAlt;
