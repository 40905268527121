import * as React from "react";
const IconTimerChat = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M57.1 7.7H6.9C4.2 7.7 2 9.9 2 12.6v28.9c0 2.7 2.2 4.9 4.9 4.9h15.5l8.7 9.6c.2.2.5.3.7.3.3 0 .5-.1.7-.3l8.9-9.6H57c2.7 0 4.9-2.2 4.9-4.9V12.6c.1-2.7-2.1-4.9-4.8-4.9M60 41.5c0 1.6-1.3 2.9-2.9 2.9H41c-.3 0-.5.1-.7.3l-8.4 9.1-8.2-9.1c-.2-.2-.5-.3-.7-.3H7c-1.6 0-2.9-1.3-2.9-2.9V12.6C4 11 5.3 9.7 6.9 9.7h50.2c1.6 0 2.9 1.3 2.9 2.9z" />
    <path d="M32 16c-6.7 0-12.1 5.4-12.1 12.1S25.3 40.2 32 40.2s12.1-5.4 12.1-12.1S38.7 16 32 16m0 22.1c-5.6 0-10.1-4.5-10.1-10.1S26.4 18 32 18s10.1 4.5 10.1 10S37.6 38.1 32 38.1" />
    <path d="M32.4 28.8v-3.5c0-.6-.4-1-1-1s-1 .4-1 1v3.9c0 .3.1.5.3.7l2.3 2.3c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4z" />
  </svg>
);
export default IconTimerChat;
