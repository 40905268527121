import React, { useState } from 'react';
import {
	BringzzPageContainer,
	BringzzIcon,
	BringzzText,
    BringzzModal,
	BringzzButton
} from '@bringzz/components';
import useRequest from 'hooks/useRequest';
import useUser from 'hooks/useUser';
import { useNavigation } from 'context/NavigationContext';

function formatDate(dateString) {
	const date = new Date(dateString);
	const day = String(date.getDate()).padStart(2, '0');
	const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
	const year = date.getFullYear();

	return `${day}.${month}.${year}`;
}

const PharmacyTeamView = () => {
	const { backPress, navigate } = useNavigation();
	const { getUser } = useUser();
	const user = getUser();
	const { sendRequest, data, error, loading } = useRequest();
    const [selectedMember, setSelectedMember] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

	return (
		<BringzzPageContainer
			header={{
				left: (
					<BringzzIcon
						size='22'
						icon='IconChevronLeft'
						folder='LineIcons'
						className='cursor-pointer'
						onClick={backPress}
					/>
				),
				title: <BringzzText tag='h2'>Team</BringzzText>
				
			}}
			footer={
				<div className='pb-28 px-6'>
					<BringzzButton
						size='lg'
						className='bg-magic-lilac w-full flex justify-center text-midnight-blue'
						onClick={() => navigate('/settings/pharmacy/team/add')}
						disabled={
							loading }
					>
						Add employee(s)
					</BringzzButton>
				</div>
			}
		>
			<div className='p-4 divide-y divide-solid'>
				{user.team.map((member, index) => (
					<div
						key={index}
						className='flex items-center justify-between py-4 px-2 cursor-pointer'
					>
						<div className='flex flex-col'>
							<BringzzText tag='h3'>{member.firstname +" "+ member.lastname}</BringzzText>
							<div className='w-64'>
								<BringzzText
									tag='h3'
									className='text-normal text-black/50 whitespace-pre-line truncate'
								>
									{member.role}
								</BringzzText>
							</div>
						</div>
						<BringzzIcon size='18' onClick={()=>navigate(`settings/pharmacy/team/edit?employeeId=${index}`)} icon={'IconPencil'} folder={'LineIcons'} />
                        <BringzzIcon size='18' onClick={()=>navigate(`settings/pharmacy/team/qr?employeeId=${index}`)} icon={'IconBarcode'} folder={'LineIcons'} />
						<BringzzIcon size='18' onClick={()=>{
                            setSelectedMember({name:member.firstname +" "+ member.lastname,id:index})
                            setModalOpen(true)
                            }} icon={'IconTrashCan'} folder={'LineIcons'} />
					</div>
				))}
                {
                    modalOpen && (
                        <BringzzModal
                        title={`Are you sure you want to remove ${selectedMember.name}?`}
                        description="Et parturient venenatis blandit etiam ornare congue sed massa nec. Vivamus."
                        onCancel={()=> setModalOpen(false)}
                        onConfirm={async()=>{
                           
                                await sendRequest({
                                    method: 'DELETE',
                                    url: `/pharmacy/team/delete`,
                                    data: {
                                        id:selectedMember.id
                                    }
                                })
                                    .then(response => {
                                        if (response.status === 'success')
                                            setModalOpen(false)
                                    })
                                    .catch(error => {
                                        console.log(error);
                                    });
                            
                        } }
                        confirmButtonText="Delete"
                      />
                    )

                }
              
			</div>
		</BringzzPageContainer>
	);
};

export default PharmacyTeamView;
