import * as React from "react";
const IconShipSearch = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M42.3 57c-.3-.2-.5-.4-.8-.6L46 40.3c.7-2.5-.6-5.2-3-6.2l-3.3-1.4v-4.9c0-.6-.4-1-1-1s-1 .4-1 1v4L28 27.9c-1.3-.5-2.7-.5-4 0L14.2 32v-7.4c0-1.8 1.4-3.2 3.2-3.2H31c.6 0 1-.4 1-1s-.4-1-1-1H19.2v-6c0-1.8 1.4-3.2 3.2-3.2h8c.6 0 1-.4 1-1s-.4-1-1-1H27V3c0-.6-.4-1-1-1s-1 .4-1 1v5.3h-2.6c-2.9 0-5.2 2.3-5.2 5.2v6c-2.8.1-5 2.4-5 5.2v8.2L9 34.2c-2.4 1-3.7 3.7-3 6.2l4.5 16.1c-.3.2-.5.3-.8.6L6 60.2c-.4.4-.5 1-.1 1.4.2.3.5.4.8.4.2 0 .5-.1.7-.2l3.7-3.2c1.2-1 3-1 4.2 0l1 .8c1.9 1.7 4.8 1.7 6.8 0l1-.8c1.2-1 3-1 4.2 0l1 .8c1.9 1.7 4.8 1.7 6.8 0l1-.8c1.2-1 3-1 4.2 0l3.7 3.2c.4.4 1 .3 1.4-.1s.3-1-.1-1.4zm-6.8 0-1 .8c-1.2 1-3 1-4.2 0l-1-.8c-1.9-1.7-4.8-1.7-6.8 0l-1 .8c-1.2 1-3 1-4.2 0l-1-.8c-1.1-1-2.6-1.4-4.1-1.2l-4.5-16c-.4-1.5.4-3.2 1.8-3.8l3.8-1.6h.1l11.1-4.6c.8-.3 1.7-.3 2.4 0L38 34.4h.1l3.8 1.6c1.5.6 2.3 2.2 1.8 3.8l-4.5 16c-1.1-.1-2.6.2-3.7 1.2M58 19.3l-4-4c-.2-.2-.3-.2-.5-.3 1-1.2 1.5-2.7 1.5-4.2 0-1.8-.7-3.6-2-4.8-2.7-2.7-7-2.7-9.7 0-1.3 1.3-2 3-2 4.8s.7 3.6 2 4.8c1.3 1.3 3 2 4.8 2q2.4 0 4.2-1.5c0 .2.1.4.3.5l4 4c.2.2.5.3.7.3s.5-.1.7-.3c.4-.3.4-.9 0-1.3m-13.3-5.1c-.9-.9-1.4-2.1-1.4-3.4s.5-2.5 1.4-3.4S46.9 6 48.1 6s2.5.5 3.4 1.4 1.4 2.1 1.4 3.4-.5 2.5-1.4 3.4c-1.9 1.9-5 1.9-6.8 0" />
  </svg>
);
export default IconShipSearch;
