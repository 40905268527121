import * as React from "react";
const IconPhoneSet = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m60.2 55.2-1.8-18.1c-.3-3.3-3.1-5.8-6.4-5.8H12c-3.3 0-6 2.5-6.4 5.8L3.8 55.2c-.2 1.8.4 3.6 1.6 4.9s2.9 2.1 4.7 2.1h43.6c1.8 0 3.5-.8 4.7-2.1 1.4-1.3 1.9-3.1 1.8-4.9m-5 1.9c-.4.4-.9.6-1.4.6H10.2c-.5 0-1-.2-1.4-.6s-.5-.9-.5-1.5l1.8-18.1c.1-1 .9-1.7 1.9-1.7h40c1 0 1.8.7 1.9 1.7l1.8 18.1c0 .6-.1 1.1-.5 1.5" />
    <path d="M32 39.3c-4.1 0-7.5 3.4-7.5 7.5 0 4.2 3.4 7.5 7.5 7.5 4.2 0 7.5-3.4 7.5-7.5s-3.3-7.5-7.5-7.5m0 10.5c-1.7 0-3-1.4-3-3 0-1.7 1.4-3 3-3 1.7 0 3 1.4 3 3 0 1.7-1.3 3-3 3M6.1 20.6c.2 4.4 4 6.7 6.6 6.7h7.1c3.2-.2 6.4-2.3 6.4-6.7v-1.9h13.2v1.9c0 4.4 3.2 6.5 6.4 6.7h7.1c2.7 0 6.4-2.3 6.6-6.7v-4.3999999999999995c-.3-2.9-1.3-5.3-3.1-7.2l-.1-.1c-2.6-2.4-5.6-3.7-7.9-4.5-6.8-2.6-15.3-2.6-15.7-2.6-6 .1-9.8.6-15.6 2.6-2.4.9-5.4 2.2-8 4.5L9 9c-1.8 1.9-2.8 4.3-3.1 7.2v.3c.1.2.2 3.7.2 4.1m6.2-8.4c2-1.8 4.4-2.8 6.4-3.6 5.2-1.9 8.5-2.2 14.1-2.4.5 0 8.1.1 14.1 2.4 2.1.8 4.5 1.8 6.4 3.6 1 1.1 1.6 2.6 1.8 4.3v3.9c-.1 2.1-2.1 2.4-2.1 2.4h-6.9c-1-.1-2.1-.5-2.1-2.2v-4.1c0-1-.6-1.9-1.6-2.1-.7-.4-18.4-.4-19.1-.1-.9.3-1.6 1.2-1.6 2.1v4.2c0 1.7-1.1 2.1-2.1 2.2h-6.9s-2-.3-2.1-2.3v-3.9c.1-1.9.7-3.3 1.7-4.4" />
  </svg>
);
export default IconPhoneSet;
