import * as React from "react";
const IconGiftBag = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m58.4 55.3-2.5-34.6c-.2-3.3-3-5.8-6.3-5.8h-3.8V10c0-4.4-3.6-8-8-8H26.1c-4.4 0-8 3.6-8 8v4.9h-3.8c-3.3 0-6 2.6-6.3 5.8L5.6 55.3c-.1 1.7.5 3.4 1.7 4.7s2.9 2 4.6 2h40.2c1.7 0 3.4-.7 4.6-2s1.8-3 1.7-4.7M20.1 10c0-3.3 2.7-6 6-6h11.8c3.3 0 6 2.7 6 6v4.9H20.1zm35.1 48.6c-.8.9-1.9 1.4-3.1 1.4H11.9c-1.2 0-2.3-.5-3.1-1.4s-1.2-2-1.1-3.2l2.5-34.6c.2-2.2 2-4 4.3-4h35.3c2.2 0 4.1 1.7 4.3 4l2.5 34.6c-.1 1.2-.5 2.4-1.4 3.2" />
    <path d="M39.8 35.8h-3.6c-.3 0-.5-.2-.6-.5l-1-3.4c-.3-1.1-1.3-1.9-2.5-1.9s-2.2.7-2.5 1.9l-1.1 3.4c-.1.3-.3.5-.6.5h-3.6c-1.2 0-2.1.7-2.5 1.8s0 2.2.9 2.9l3 2.3c.2.2.3.4.2.7l-1.1 3.6c-.3 1.1.1 2.3 1 2.9.5.3 1 .5 1.5.5.6 0 1.1-.2 1.6-.6l2.7-2.1c.2-.2.6-.2.8 0l2.7 2.1c.9.7 2.2.7 3.1.1 1-.7 1.4-1.8 1-2.9l-1.1-3.6c-.1-.3 0-.5.2-.7l3-2.3c.9-.7 1.3-1.9.9-2.9s-1.3-1.8-2.4-1.8m.4 3.2-3 2.3c-.9.7-1.2 1.8-.9 2.9l1.1 3.6c.1.4-.1.6-.2.7s-.4.2-.8 0l-2.7-2.1c-.5-.4-1-.5-1.6-.5s-1.1.2-1.6.5l-2.7 2.1c-.3.3-.6.1-.8 0-.1-.1-.4-.3-.2-.7l1.1-3.6c.3-1.1 0-2.2-.9-2.9L24 39c-.3-.3-.3-.6-.2-.7 0-.1.2-.4.6-.4H28c1.2 0 2.2-.7 2.5-1.9l1.1-3.4c.1-.4.5-.5.6-.5s.5 0 .6.5l1.1 3.4c.3 1.1 1.4 1.9 2.5 1.9H40c.4 0 .6.3.6.4s-.1.4-.4.7" />
  </svg>
);
export default IconGiftBag;
