import * as React from "react";
const IconCableCar = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m60.8 2-58 9.6c-.5.1-.9.6-.8 1.1s.5.8 1 .8h.2L31 9v11.5H16.6c-6.6 0-12 5.4-12 12V50c0 6.6 5.4 12 12 12h30.7c6.6 0 12-5.4 12-12V32.4c0-6.6-5.4-12-12-12H33V8.7L61.2 4c.5-.1.9-.6.8-1.1s-.6-1-1.2-.9M47.4 60H16.6c-5.5 0-10-4.5-10-10v-6.5h50.7V50c.1 5.5-4.4 10-9.9 10m10-27.6v9.1H6.6v-9.1c0-5.5 4.5-10 10-10h30.7c5.6 0 10.1 4.5 10.1 10" />
  </svg>
);
export default IconCableCar;
