import * as React from "react";
const IconArrowsShrinkH = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M44.4 20.6c-.4-.4-1-.5-1.4-.1s-.5 1-.1 1.4l7.7 9.2H13.4l7.7-9.2c.4-.4.3-1.1-.1-1.4-.4-.4-1.1-.3-1.4.1l-9.1 10.8c-.3.4-.3.9 0 1.3l9.1 10.8c.2.2.5.4.8.4.2 0 .5-.1.6-.2.4-.4.5-1 .1-1.4L13.4 33h37.2l-7.7 9.2c-.4.4-.3 1.1.1 1.4.2.2.4.2.6.2.3 0 .6-.1.8-.4l9.1-10.8c.3-.4.3-.9 0-1.3zM60 19.1c-.6 0-1 .4-1 1v23.7c0 .6.4 1 1 1s1-.4 1-1V20.1c0-.5-.4-1-1-1M4 19.1c-.6 0-1 .4-1 1v23.7c0 .6.4 1 1 1s1-.4 1-1V20.1c0-.5-.4-1-1-1" />
  </svg>
);
export default IconArrowsShrinkH;
