import * as React from "react";
const IconColorPalette = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.5 41.3H34.4l20.5-20.5c1.2-1.2 1.9-2.9 1.9-4.6s-.7-3.4-1.9-4.6l-5.4-5.4c-2.5-2.5-6.7-2.5-9.2 0L22.7 23.8V7c0-2.8-2.2-5-5-5H7C4.2 2 2 4.2 2 7v50c0 2.8 2.2 5 5 5h48.5c3.6 0 6.5-2.9 6.5-6.5v-7.7c0-3.6-2.9-6.5-6.5-6.5M41.7 7.6c1.8-1.8 4.6-1.8 6.4 0l5.4 5.4c.8.8 1.3 2 1.3 3.2s-.5 2.3-1.3 3.2L31.6 41.3h-8.9V26.7zM4 57V7c0-1.7 1.3-3 3-3h10.7c1.7 0 3 1.3 3 3v53H7c-1.7 0-3-1.3-3-3m56-1.5c0 2.5-2 4.5-4.5 4.5H22.7V43.3h32.8c2.5 0 4.5 2 4.5 4.5z" />
  </svg>
);
export default IconColorPalette;
