import * as React from "react";
const IconSketchbook = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58 14.2H11.3c-2.2 0-4 1.8-4 4v4.3H3c-.6 0-1 .4-1 1s.4 1 1 1h4.3v6.9H3c-.6 0-1 .4-1 1s.4 1 1 1h4.3v6.9H3c-.6 0-1 .4-1 1s.4 1 1 1h4.3v3.4c0 2.2 1.8 4 4 4H58c2.2 0 4-1.8 4-4V18.2c0-2.2-1.8-4-4-4m2 31.6c0 1.1-.9 2-2 2H11.3c-1.1 0-2-.9-2-2v-3.4h2.6c.6 0 1-.4 1-1s-.4-1-1-1H9.3v-6.9h2.6c.6 0 1-.4 1-1s-.4-1-1-1H9.3v-6.9h2.6c.6 0 1-.4 1-1s-.4-1-1-1H9.3v-4.3c0-1.1.9-2 2-2H58c1.1 0 2 .9 2 2z" />
  </svg>
);
export default IconSketchbook;
