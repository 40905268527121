import * as React from "react";
const IconParenthesesAlt1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M19 32c0-6.4 2-12.1 4.9-14.4.4-.3.5-1 .2-1.4s-1-.5-1.4-.2c-3.5 2.6-5.7 8.9-5.7 16 0 7 2.2 13.3 5.7 16 .2.1.4.2.6.2.3 0 .6-.1.8-.4.3-.4.3-1.1-.2-1.4C21 44.1 19 38.4 19 32M41.3 16c-.4-.3-1.1-.3-1.4.2-.3.4-.3 1.1.2 1.4C43 19.8 45 25.6 45 32s-2 12.1-4.9 14.4c-.4.3-.5 1-.2 1.4.2.3.5.4.8.4.2 0 .4-.1.6-.2 3.5-2.7 5.7-8.9 5.7-16s-2.2-13.3-5.7-16" />
    <path d="M32 2C15.5 2 2 15.5 2 32s13.5 30 30 30 30-13.5 30-30S48.5 2 32 2m0 58C16.6 60 4 47.4 4 32S16.6 4 32 4s28 12.6 28 28-12.6 28-28 28" />
  </svg>
);
export default IconParenthesesAlt1;
