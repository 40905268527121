import * as React from "react";
const IconVrDesign = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M46.5 38.1V24.7c0-1.6-.9-3-2.3-3.6l-10.5-4.8c-1.1-.5-2.3-.5-3.4 0l-10.5 4.9c-1.4.7-2.3 2.1-2.3 3.6v13.4c0 1.4.8 2.8 2 3.5l10.5 6c.6.4 1.3.5 2 .5s1.4-.2 2-.5l10.5-6c1.2-.8 2-2.1 2-3.6m-15.3-20c.3-.1.6-.2.8-.2s.6.1.8.2l10.3 4.8-11.1 5-11.2-5.1zM20.5 39.9c-.6-.4-1-1-1-1.7V24.4L31 29.7v16.2zm24-1.8c0 .7-.4 1.4-1 1.7l-10.5 6V29.7l11.4-5.3v13.700000000000001zM4.4 18.1c.6 0 1-.4 1-1V7.2c0-1.7 1.4-3.1 3.1-3.1h10c.6 0 1-.4 1-1s-.4-1-1-1h-10c-2.8 0-5.1 2.3-5.1 5.1v9.9c0 .5.4 1 1 1M59.6 45.9c-.6 0-1 .4-1 1v9.9c0 1.7-1.4 3.1-3.1 3.1h-10c-.6 0-1 .4-1 1s.4 1 1 1h10c2.8 0 5.1-2.3 5.1-5.1v-9.9c0-.5-.4-1-1-1M45.6 4h9.9c1.7 0 3.1 1.4 3.1 3.1v10c0 .6.4 1 1 1s1-.4 1-1v-10c0-2.8-2.3-5.1-5.1-5.1h-9.9c-.6 0-1 .4-1 1s.4 1 1 1M18.4 60H8.5c-1.7 0-3.1-1.4-3.1-3.1v-10c0-.6-.4-1-1-1s-1 .4-1 1v10c0 2.8 2.3 5.1 5.1 5.1h9.9c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconVrDesign;
