import * as React from "react";
const IconBrickWall = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M54.9 32.2H29.6v-8c0-2.2-1.8-4-4-4H11c-2.2 0-4 1.8-4 4v8H5.8c-2.2 0-4 1.8-4 4v9.3c0 2.2 1.8 4 4 4h6.3v8.4H2.8c-.6 0-1 .4-1 1s.4 1 1 1h55.1c.6 0 1-.4 1-1s-.4-1-1-1h-9.3v-8.4h6.3c2.2 0 4-1.8 4-4v-9.3c0-2.2-1.8-4-4-4M9 24.1c0-1.1.9-2 2-2h14.6c1.1 0 2 .9 2 2v8H9zm5.1 25.4h15.3v8.4H14.1zm32.6 8.4H31.4v-8.4h15.3zm10.2-12.4c0 1.1-.9 2-2 2H5.8c-1.1 0-2-.9-2-2v-9.3c0-1.1.9-2 2-2H55c1.1 0 2 .9 2 2v9.3z" />
    <path d="m61.2 6.2-1.1-1.1c-1.3-1.3-3.5-1.3-4.8 0L52 8.3c-1.3 1.3-1.3 3.4-.1 4.8l-3.3 3.3-.5-.5c-.8-.8-1.9-1.2-3-1.1s-2.1.7-2.7 1.6l-2.6 3.8c-1 1.5-.8 3.5.4 4.7l1.3 1.3c.7.7 1.7 1.1 2.6 1.1.7 0 1.5-.2 2.1-.7L50 24c.9-.6 1.5-1.6 1.6-2.7s-.3-2.2-1.1-3l-.5-.5 3.3-3.3c.6.5 1.4.7 2.1.7.9 0 1.8-.3 2.4-1L61 11c.6-.6 1-1.5 1-2.4.2-.9-.2-1.8-.8-2.4M49 22.3l-3.8 2.6c-.7.5-1.6.4-2.2-.2l-1.3-1.3c-.6-.6-.7-1.5-.2-2.2l2.6-3.8c.3-.4.8-.7 1.3-.7h.2c.5 0 .9.2 1.2.5l2.4 2.4c.4.4.5.9.5 1.4-.1.6-.3 1.1-.7 1.3M59.7 9.6l-3.2 3.2c-.5.5-1.5.5-2 0l-1.1-1.1c-.6-.6-.6-1.5 0-2l3.2-3.2c.3-.3.6-.4 1-.4s.7.1 1 .4l1.1 1.1c.3.3.4.6.4 1 .1.4-.1.7-.4 1" />
  </svg>
);
export default IconBrickWall;
