import * as React from "react";
const IconMagnifierCopy = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="m60.5 51.9-12-12c-2-2-5.2-2-7.3-.1l-7.1-7.1c3-3.4 4.7-7.7 4.7-12.3 0-4.9-1.9-9.5-5.4-13-7.2-7.2-18.8-7.2-26 0s-7.2 18.8 0 26c3.5 3.5 8.1 5.4 13 5.4 4.6 0 8.9-1.7 12.3-4.7l7.1 7.1c-1.9 2.1-1.9 5.3.1 7.3l12 12c1 1 2.3 1.5 3.7 1.5s2.7-.5 3.7-1.5l1.1-1.1c1-1 1.5-2.3 1.5-3.7.1-1.5-.4-2.8-1.4-3.8M8.8 32c-6.4-6.4-6.4-16.8 0-23.2C12 5.6 16.2 4 20.4 4S28.8 5.6 32 8.8c3.1 3.1 4.8 7.2 4.8 11.6S35.1 28.9 32 32s-7.2 4.8-11.6 4.8-8.5-1.7-11.6-4.8M59 57.9 57.9 59c-1.3 1.3-3.4 1.3-4.6 0l-12-12c-1.3-1.3-1.3-3.4 0-4.6l1.1-1.1c.6-.6 1.5-1 2.3-1s1.7.3 2.3 1l12 12c1.3 1.3 1.3 3.4 0 4.6" />
    <path d="M25.3 13c-.4.4-.4 1 0 1.4 3 3 3 7.9 0 11-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3c3.8-3.8 3.8-10 0-13.8-.3-.4-1-.4-1.4 0" />
  </svg>
);
export default IconMagnifierCopy;
