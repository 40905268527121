import * as React from "react";
const IconEuroCard = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M55.2 4.8H8.8C5.1 4.8 2 7.6 2 11.1v29c0 3.5 3.1 6.3 6.8 6.3h23.1c.6 0 1-.4 1-1s-.4-1-1-1H8.8c-2.6 0-4.8-1.9-4.8-4.3V26h56v8.7c0 .6.4 1 1 1s1-.4 1-1V11.1c0-3.5-3.1-6.3-6.8-6.3m-46.4 2h46.4c2.6 0 4.8 1.9 4.8 4.3v4.3H4v-4.3c0-2.4 2.2-4.3 4.8-4.3M4 24v-6.6h56V24z" />
    <path d="M53.3 39.2c.6 0 1-.4 1-1s-.4-1-1-1c-4.5 0-8.5 2.9-10.2 6.8H40c-.6 0-1 .4-1 1s.4 1 1 1h2.5c-.2.7-.2 1.4-.2 2.2 0 1 .1 1.9.4 2.8H40c-.6 0-1 .4-1 1s.4 1 1 1h3.4c1.8 3.7 5.6 6.2 9.9 6.2.6 0 1-.4 1-1s-.4-1-1-1c-3.1 0-6-1.7-7.6-4.2H50c.6 0 1-.4 1-1s-.4-1-1-1h-5.3c-.3-.9-.5-1.8-.5-2.8 0-.8.1-1.5.3-2.2H50c.6 0 1-.4 1-1s-.4-1-1-1h-4.7c1.5-2.9 4.6-4.8 8-4.8" />
  </svg>
);
export default IconEuroCard;
