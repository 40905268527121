import * as React from "react";
const IconHospitalAlt3 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M58.1 19.4 44 13.1V8c0-.4-.2-.8-.6-.9l-11-4.9c-.3-.1-.6-.1-.8 0l-11 4.9q-.6.3-.6.9v5.1L5.9 19.4c-2.4 1-3.9 3.4-3.9 6v31.2c0 2.9 2.3 5.2 5.2 5.2h49.6c2.9 0 5.2-2.3 5.2-5.2V25.4c0-2.6-1.5-5-3.9-6M4 56.7V25.4c0-1.8 1.1-3.5 2.7-4.2L20 15.3v44.6H7.2c-1.8 0-3.2-1.5-3.2-3.2m35 3.2H22V8.7l10-4.5 10 4.5v51.2zm21-3.2c0 1.8-1.4 3.2-3.2 3.2H44V15.3l13.3 5.9c1.7.7 2.7 2.4 2.7 4.2z" />
    <path d="M36 16.9h-3v-3c0-.6-.4-1-1-1s-1 .4-1 1v3h-3c-.6 0-1 .4-1 1s.4 1 1 1h3v3c0 .6.4 1 1 1s1-.4 1-1v-3h3c.6 0 1-.4 1-1s-.4-1-1-1" />
  </svg>
);
export default IconHospitalAlt3;
