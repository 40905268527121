import * as React from "react";
const IconArrowTarget1 = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M49.2 24.5h-1.4c-.6 0-1 .4-1 1s.4 1 1 1h1.4c1.5 0 2.8 1.2 2.8 2.8V43H12V29.2c0-1.5 1.2-2.8 2.8-2.8h.8c.6 0 1-.4 1-1s-.4-1-1-1h-.8c-2.6 0-4.8 2.1-4.8 4.8v21.1c0 1.9 1.6 3.5 3.5 3.5h14l-.9 6.2h-5.5c-.6 0-1 .4-1 1s.4 1 1 1h21.8c.6 0 1-.4 1-1s-.4-1-1-1h-5.5l-.9-6.2h14c1.9 0 3.5-1.6 3.5-3.5V29.2c0-2.6-2.2-4.7-4.8-4.7M35.4 60h-6.8l.9-6.2h4.9zm15.1-8.2h-37c-.8 0-1.5-.7-1.5-1.5V45h40v5.3c0 .8-.7 1.5-1.5 1.5" />
    <path d="M33 31.5c6 0 10.8-4.8 10.8-10.8 0-.6-.4-1-1-1s-1 .4-1 1c0 4.9-3.9 8.8-8.8 8.8s-8.8-3.9-8.8-8.8 3.9-8.8 8.8-8.8c.9 0 1.7.1 2.5.4.5.2 1.1-.1 1.2-.7.2-.5-.1-1.1-.7-1.2-1-.3-2-.4-3.1-.4-6 0-10.8 4.8-10.8 10.8s5 10.7 10.9 10.7" />
    <path d="M33.4 19.1c-.4.4-.5 1-.1 1.4.2.2.5.3.7.3s.5-.1.7-.3L47 9.7h6c.6 0 1-.4 1-1s-.4-1-1-1h-5.3V3c0-.6-.4-1-1-1s-1 .4-1 1v5.2zM30.1 20.7c0-.6-.4-1-1-1s-1 .4-1 1c0 2.7 2.2 4.9 4.9 4.9.6 0 1-.4 1-1s-.4-1-1-1c-1.6 0-2.9-1.3-2.9-2.9" />
  </svg>
);
export default IconArrowTarget1;
