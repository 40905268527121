import * as React from "react";
const IconStatsDown = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M60 57.8H6.3V4c0-1.2-1-2.3-2.3-2.3S1.8 2.8 1.8 4v56c0 1.2 1 2.3 2.3 2.3h56c1.2 0 2.3-1 2.3-2.3s-1.2-2.2-2.4-2.2" />
    <path d="M60 28.6c-1.2 0-2.3 1-2.3 2.3v7.5L51 32.8c-.4-.3-.9-.5-1.4-.5H36.8L29.7 23c-.4-.6-1.1-.9-1.8-.9H15.3c-1.2 0-2.3 1-2.3 2.3s1 2.3 2.3 2.3h11.5l7.1 9.3c.4.6 1.1.9 1.8.9h13.2l5.5 4.6h-7c-1.2 0-2.3 1-2.3 2.3s1 2.3 2.3 2.3H60c1.2 0 2.3-1 2.3-2.3V30.9c0-1.3-1.1-2.3-2.3-2.3" />
  </svg>
);
export default IconStatsDown;
