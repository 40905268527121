import * as React from "react";
const IconStar = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M61.8 24.6c-.4-1.2-1.4-2.1-2.6-2.3l-16.7-2.6L35 3.9C34.4 2.7 33.3 2 32 2s-2.4.7-2.9 1.9l-7.5 15.9-16.8 2.7c-1.2.2-2.2 1.1-2.6 2.3s-.1 2.6.8 3.5l12.1 12.4L12.3 58c-.2 1.3.3 2.6 1.4 3.4q.9.6 1.8.6c.5 0 1.1-.1 1.5-.4l15-8.2 14.9 8.1c1.1.6 2.4.5 3.4-.2 1.1-.8 1.6-2.1 1.4-3.4l-2.8-17.4L61 28.1c.9-.9 1.2-2.3.8-3.5m-2.3 2.1-12 12.4c-.4.4-.6 1.1-.5 1.7l2.8 17.4c.1.7-.2 1.2-.6 1.4-.2.1-.7.4-1.3.1l-15-8.1q-.9-.45-1.8 0l-15 8.2c-.6.3-1.1.1-1.3-.1-.3-.2-.7-.8-.6-1.4L17.1 41c.1-.6-.1-1.3-.5-1.7L4.5 26.9c-.5-.5-.5-1.1-.4-1.5.2-.5.5-.8 1-.9l16.8-2.7c.6-.1 1.2-.5 1.4-1.1l7.5-15.9c.3-.5.7-.8 1.2-.8s.9.3 1.1.8l7.5 15.9c.3.6.8 1 1.4 1.1l16.7 2.6c.5.1.9.4 1 .9.3.3.3 1-.2 1.4" />
  </svg>
);
export default IconStar;
