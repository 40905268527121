import * as React from "react";
const IconHotdog = (props) => (
  <svg className={"fill-current"}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 64 64"
    width={props.size || "48"}
    height={props.size || "48"}
    {...props}
  >
    <path d="M62 32c0-3.3-1.7-6.2-4.3-7.8-1.2-5.7-6.3-9.8-12.2-9.8h-27c-5.9 0-10.9 4.1-12.1 9.8C3.7 25.8 2 28.7 2 32s1.7 6.2 4.3 7.8c1.2 5.7 6.3 9.8 12.1 9.8h27.1c5.9 0 10.9-4.1 12.1-9.8 2.7-1.6 4.4-4.5 4.4-7.8M18.5 16.4h27.1c4.4 0 8.2 2.7 9.8 6.7-.8-.2-1.7-.4-2.6-.4H11.3c-.9 0-1.7.1-2.6.4 1.5-4 5.4-6.7 9.8-6.7m27 31.2h-27c-4.4 0-8.2-2.7-9.7-6.7.8.2 1.7.4 2.5.4h41.5c.9 0 1.7-.1 2.5-.4-1.5 4-5.4 6.7-9.8 6.7m7.2-8.3H11.3C7.3 39.3 4 36 4 32s3.3-7.3 7.3-7.3h41.5c4 0 7.3 3.3 7.3 7.3s-3.4 7.3-7.4 7.3" />
    <path d="m48.7 32.4-6.6-2.5c-.2-.1-.5-.1-.7 0l-6.2 2.4-6.2-2.4c-.2-.1-.5-.1-.7 0l-6.2 2.4-6.1-2.4c-.5-.2-1.1.1-1.3.6s.1 1.1.6 1.3l6.5 2.5c.2.1.5.1.7 0l6.2-2.4 6.2 2.4c.2.1.5.1.7 0l6.2-2.4 6.2 2.4c.1 0 .2.1.4.1.4 0 .8-.2.9-.6.2-.7-.1-1.2-.6-1.4" />
  </svg>
);
export default IconHotdog;
